import React, {Component} from 'react';
import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

const referIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-blue.png';

class RenderReferrals extends Component {
    constructor(props) {
      super(props)

      this.state = {
        favorites: []
      }

      this.segueToProfile = this.segueToProfile.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderReferrals', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.profileData !== prevProps.profileData || this.props.favorites !== prevProps.favorites) {
        this.retrieveData()
      } else if (this.props.sortCriteriaArray !== prevProps.sortCriteriaArray || this.props.filterCriteriaArray !== prevProps.filterCriteriaArray) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in RenderReferrals')

      const activeOrg = this.props.activeOrg
      const favorites = this.props.favorites
      const orgName = this.props.orgName
      const orgContactEmail = this.props.orgContactEmail

      let emailId = localStorage.getItem('email');

      this.setState({ activeOrg, favorites, emailId, orgName, orgContactEmail })

    }

    segueToProfile(e, referral) {
      console.log('segueToProfile called')

      e.stopPropagation()
      e.preventDefault()

      if (window.location.pathname.startsWith('/organizations')) {
        window.open('/organizations/' + this.props.activeOrg + '/members/' + referral.creatorUsername)
      } else if (window.location.pathname.includes('/advisor')) {
        window.open('/advisor/members/' + referral.creatorUsername)
      } else if (this.props.pageSource === 'landingPage') {
        window.open('/' + referral.creatorUsername + '/profile')
      } else if (window.location.pathname.startsWith('/app/')) {
        this.props.navigate('/app/profile/' + referral.creatorUsername)
      }
    }

    itemClicked(e, type, value) {
      console.log('itemClicked called', e, type, value)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ modalIsOpen: true, showHelpOutWidget: true, selectedReferral: value })
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showReferralDetails: false,
        selectedReferral: null
      });

    }

    render() {

      return (
        <div>
          {(this.props.filteredReferrals && this.props.filteredReferrals.length > 0) ? (
            <div>
              {this.props.filteredReferrals.map((value, index) =>
                <div key={index}>
                  <div className="relative-column-33 bottom-margin-20">
                    <div>
                      <img src={referIconBlue} className="image-auto-50 center-horizontally bottom-margin-negative-35 relative-position z-index-1 padding-10 error-border circle-radius white-background" alt="GC" />
                    </div>

                    <button onClick={() => this.setState({ modalIsOpen: true, showReferralDetails: true, selectedReferral: value })} className="background-button full-space">
                      <div className="elevated-box white-background" >

                        <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <div className="horizontal-padding">
                          <p className="heading-text-5 full-width center-text">{value.opportunityTitle} @ {value.opportunityEmployerName}</p>

                          <div className="top-padding-20">
                            <p className="description-text-3 full-width center-text"><Link to={(value.referrerLinkedInURL) ? value.referrerLinkedInURL : '/'} className={(value.referrerLinkedInURL) ? "background-button" : "invisible-button"} disabled={(value.referrerLinkedInURL) ? false : true}>{value.referrerFirstName} {value.referrerLastName}{(value.referrerTitle) && ",  " + value.referrerTitle + ' @ ' + value.referrerEmployerName}</Link> refers <Link to={(value.recipientUsername) ? "/" + value.recipientUsername+ "/profile" : '/'} className={(value.recipientUsername) ? "background-button" : "invisible-button"} disabled={(value.recipientUsername) ? false : true}>{value.recipientFirstName} {value.recipientLastName}</Link></p>
                          </div>

                          <div className="top-padding-5">
                            <p className="description-text-3 full-width center-text">[{value.relationship}]</p>
                          </div>

                          <div className="top-padding-20">
                            <p className="description-text-3 full-width center-text">Date: {convertDateToString(new Date(value.createdAt),"date-2")}</p>
                          </div>

                          <div className="top-padding-20">
                            <p className="description-text-3 full-width center-text italicize-text">"{value.reason}"</p>
                          </div>

                          <div className="top-padding-20">
                            <p className="description-text-3 full-width center-text">Status: <label className="cta-color">{value.status}</label></p>
                          </div>

                          {(value.attachEndorsement && value.recipientUsername) && (
                            <div className="top-padding-20">
                              <p className="description-text-3 full-width center-text"><Link to={"/" + value.recipientUsername + "/" + value.endorsementId} target="_target">Endorsement Attached</Link></p>
                            </div>
                          )}
                          {/*
                          <div className="spacer" /><div className="spacer" />

                          <hr />

                          <div className="clear" />

                          <div className="top-padding-20">
                            <button className="btn btn-profile full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.itemClicked(e,'helpOut', value)}>Send to Employer</button>
                          </div>*/}

                          {(this.props.sortCriteriaArray && this.props.sortCriteriaArray[index] && this.props.sortCriteriaArray[index].name) && (
                            <div className="top-padding">
                              <div className="half-spacer" />
                              <p className="error-color description-text-2">{this.props.sortCriteriaArray[index].name}: {this.props.sortCriteriaArray[index].criteria}</p>
                            </div>
                          )}
                          {(this.props.filterCriteriaArray && this.props.filterCriteriaArray[index] && this.props.filterCriteriaArray[index].name) && (
                            <div className="top-padding">
                              <div className="half-spacer" />
                              <p className="error-color description-text-2">{this.props.filterCriteriaArray[index].name}: {this.props.filterCriteriaArray[index].criteria}</p>
                            </div>
                          )}

                          <div className="spacer" />
                        </div>
                      </div>
                    </button>
                  </div>

                  {(index % 3 === 2) && (
                    <div className="clear" />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="error-color">No referrals have been added yet</p>
            </div>
          )}

          {(this.state.showReferralDetails) && (
            <div>
              <SubReferralDetails history={this.props.navigate} selectedReferral={this.state.selectedReferral} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showReferralDetails={this.state.showReferralDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.props.profileData}
                orgCode={this.state.activeOrg} orgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail}
              />
           </div>
          )}
        </div>
      )
    }
}

export default withRouter(RenderReferrals);
