import React, {Component} from 'react';
import { Link } from 'react-router-dom';

const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";
const testimonialBackground1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-1.png";
const quote1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-1.png";
const testimonialBackground2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-2.png";
const quote2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-2.png";
const narcissistMirror = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/narcissist-mirror.png";

class CareerSeekerBenefits extends Component {
    constructor(props) {
      super(props)
      this.state = {
        panelSelected: 1,
        showCareerSeekerTestimonials: false
      }
    }

    componentDidMount() {
      console.log('componentDidMount called')

      const panel1Points = [
        { title: 'Explore Transparent Career Pathways', subtitle: 'Programs and employers define their ideal candidates for pathways and opportunities, giving you a clear roadmaps for learning, and earning.'},
        { title: 'Take Career Assessments', subtitle: 'Take interest, value, personality, and skill assessments to help you build self-awareness, explore paths, and match to opportunities.'},
        { title: 'Get Competency Endorsements', subtitle: 'Get reusuble pathway-focused letters of recommendation on your relevant skills and knowledge from teachers, supervisors, and other others.'},
        { title: 'Track Your Favorite Recommendations', subtitle: 'Get career, employer, course, event, project, and work recommendations based on your profile. Favorite them to keep track of what you like.'}
      ]

      const panel2Points = [
        { title: 'Set S.M.A.R.T. Goals', subtitle: 'Set Specific, Measurable, Achievable, Realistic, and Time Bound (SMART) goals, crowdsource help, and update supporters on progress!'},
        { title: 'Organize Courses', subtitle: 'Favorite recommended courses based on your goal / skill gaps. Courses are pulled from Udemy and Coursera based on your skills / knowledge.'},
        { title: 'Use The Financial Planner', subtitle: 'Compare specific pathways and add custom entries to visually see how your income, expenses, and net worth changes over time.'},
        { title: 'Use The Career Planner', subtitle: 'The Career Plan Builder allows you to import your profile (i.e., goals, favorited items, top skills, knowledge), edit, and export to PDF.'}
      ]

      const panel3Points = [
        { title: 'Build a Team', subtitle: 'Click the match icon in the community tab to see which profiles are most similar, create accountability groups, and recruit project teammates.'},
        { title: 'Connect with Professionals', subtitle: 'Connect with alumni, solve problems via projects for professionals, crowdsource help for your goals, and get feedback on your profile.'},
        { title: 'Get Referred for Opportunities', subtitle: 'Get endorsements on your skills and have staff or professionals refer you for work opportunities.'},
        { title: 'Connect with Employers', subtitle: 'Explore employer profiles and attend job shadows, workshops, and other opportunities that allow you to connect with employers.'}
      ]

      const panel4Points = [
        { title: 'Match to Opportunities', subtitle: 'Not sure which projects/jobs are for you? Click the match button to calculate the top matches based on your current profile.'},
        { title: 'Apply to Work Opportunities', subtitle: 'Apply to opportunities by importing your profile, and then customizing for the opportunity. Your profile includes many components that we help you build over time.'},
        { title: 'Get Referred for Work Opportunities', subtitle: 'Applications often go to worforce staff who advocate for you and refer you for opportunities.'},
        { title: 'Submit Projects to Employers', subtitle: 'Submit projects to programs and employers to train for work opportunities, for prize money, or to work as a freelancer.'},
      ]

      this.setState({ panel1Points, panel2Points, panel3Points, panel4Points })

    }

    render() {

      let panel1ClassName = "white-background standard-border padding-40 center-text full-width"
      let panel2ClassName = "white-background standard-border padding-40 center-text full-width"
      let panel3ClassName = "white-background standard-border padding-40 center-text full-width"
      let panel4ClassName = "white-background standard-border padding-40 center-text full-width"

      if (window.innerWidth > 768) {
        if (this.state.panelSelected === 1) {
          panel1ClassName = "septary-background standard-border padding-40 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-40 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-40 center-text full-width"
        }
        if (this.state.panelSelected === 4) {
          panel4ClassName = "primary-background standard-border padding-40 center-text full-width"
        }
      } else {
        // redefine for mobile

        panel1ClassName = "white-background standard-border padding-20 center-text full-width"
        panel2ClassName = "white-background standard-border padding-20 center-text full-width"
        panel3ClassName = "white-background standard-border padding-20 center-text full-width"
        panel4ClassName = "white-background standard-border padding-20 center-text full-width"
        if (this.state.panelSelected === 1) {
          panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 4) {
          panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
        }
      }

      return (
        <div>
          <section className="section-features white-background horizontal-padding-3">
              <div className="row">
                <h2>How Guided Compass Helps</h2>
              </div>
              <div>
                {(window.innerWidth > 768) && (
                  <div className="relative-column-40 padding-20 top-margin">
                    <img src={narcissistMirror} alt="GC" className="image-full-auto" />
                  </div>
                )}

                <div className={(window.innerWidth > 768) ? "relative-column-60" : "full-width"}>
                  <div className="row-10">
                    <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 1 })}>
                        <div className={panel1ClassName}>
                          <p className="heading-text-2">1</p>
                          <p className="heading-text-3">Explore Careers</p>
                        </div>
                      </button>
                    </div>

                    <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 2 })}>
                        <div className={panel2ClassName}>
                          <p className="heading-text-2">2</p>
                          <p className="heading-text-3">Plan Ahead</p>
                        </div>
                      </button>
                    </div>

                    <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 3 })}>
                        <div className={panel3ClassName}>
                          <p className="heading-text-2">3</p>
                          <p className="heading-text-3">Gain Advocates</p>
                        </div>
                      </button>
                    </div>

                    <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 4 })}>
                        <div className={panel4ClassName}>
                          <p className="heading-text-2">4</p>
                          <p className="heading-text-3">Gain Experience</p>
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.panelSelected === 1 && this.state.panel1Points) && (
                    <div className="bottom-padding">
                      {this.state.panel1Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={profileIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 2 && this.state.panel2Points) && (
                    <div className="bottom-padding">
                      {this.state.panel2Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={profileIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 3 && this.state.panel3Points) && (
                    <div className="bottom-padding">
                      {this.state.panel3Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={profileIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 4 && this.state.panel4Points) && (
                    <div className="bottom-padding">
                      {this.state.panel4Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={profileIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="clear" />

              </div>

          </section>

          <section className="section-workforce">
            <div className="container-left">
              <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>Not sure where to start?</p>
            </div>
            <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
              <Link to={"/help"} state={{ selectedFilters: ["Employer"] }} className="btn white-background heading-text-5">View Help Articles</Link>
            </div>
            <div className="clear" />
          </section>

          {(this.state.showCareerSeekerTestimonials) && (
            <section className="section-features orange-background">
              <div className="row">
                <h2>Hear From Our Members</h2>
              </div>

              <div className="row top-margin-20">
                <div className="container-left">
                  <div className="white-background relative-position">
                    <div className="padding-20">
                      <img src={quote1} alt="GC" className="image-auto-30" />
                    </div>

                    <div className="bottom-padding-20 horizontal-padding-5">
                      <p className="heading-text-5">"Guided Compass really helped me discover a ton of career paths I didn't event know existed, identify which ones I was interested in, and keep track of my progress as I worked toward them. My parents were able to view my progress and more proactively help too. I really feel like Guided Compass helped me discover who I am and which path was right for me."</p>
                    </div>

                    <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                      <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/bianca_esquivias.jpeg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                      <div className="white-text bottom-padding-10-percent">
                        <p className="top-padding">Bianca Esquivias</p>
                        <p className="description-text-2">Student</p>

                        {(this.state.showHighSchool) ? (
                          <p className="description-text-2">Da Vinci Design High School '18</p>
                        ) : (
                          <p className="description-text-2">University of California, Santa Cruz '22</p>
                        )}

                      </div>
                    </div>

                    <img src={testimonialBackground1} alt="GC" className="image-full-auto" />
                  </div>
                </div>
                <div className="container-right">
                  <div className="white-background relative-position">
                    <div className="padding-20">
                      <img src={quote2} alt="GC" className="image-auto-30" />
                    </div>

                    <div className="bottom-padding horizontal-padding-5">
                      <p className="heading-text-5">"Guided Compass helped me my secure best internship to date. I've worked at a few places, but they were just to pay the bills. I didn't feel like I had a connection to what I was doing and that I could build a career. Thanks to Guided Compass, I not only discovered a role I was interested in, I was able to put my best foot forward and secure it!"</p>
                    </div>

                    <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                      <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/roberto_parra.jpeg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                      <div className="white-text bottom-padding-10-percent">
                        <p className="top-padding">Roberto Parra</p>
                        <p className="description-text-2">Student</p>

                        <p className="description-text-2">Cal State Northridge '21</p>

                      </div>
                    </div>

                    <img src={testimonialBackground2} alt="GC" className="image-full-auto" />
                  </div>
                </div>
                <div className="clear" />
              </div>

            </section>
          )}
        </div>
      )
    }
}

export default CareerSeekerBenefits
