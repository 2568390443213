import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import {subExportFile} from '../Functions/ExportFile';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';
import { Line } from 'react-chartjs-2';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Chart as ChartJS, LineElement, PointElement, ArcElement, LinearScale, CategoryScale, Title } from 'chart.js';
ChartJS.register(LineElement, PointElement, ArcElement, LinearScale, CategoryScale, Title);
import {subFavoriteItem} from '../Functions/FavoriteItem';

import SubRenderFinancialPlan from '../Common/RenderFinancialPlan';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const expandIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/expand-icon.png';
const minimizeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/minimize-icon.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const layoutIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/layout-icon-dark.png';
const newDocumentIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/new-document-icon.png';
const openIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/open-icon-dark.png';
const openIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/open-icon-blue.png';
const saveIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/save-icon-dark.png';
const saveIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/save-icon-blue.png';
const exportIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/export-icon-dark.png';

class Financials extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showDoc: true,
          opacity: 0,
          transform: 'translate(90%)',

          showSnapshot: false,
          entryMode: 'Ballpark Mode',

          inflation: 1,
          chartType: 'Income',
          scenario: 'Base',
          duration: '10',
          retirementYear: 2066,
          careerStage: 'Early',
          highPerformanceMultiplier: 1.3,
          lowPerformanceMultiplier: 0.7,
          luxuryLifestyleMultiplier: 1.3,
          modestLifestyleMultiplier: 0.7,

          earlyStageLuxuryFloor: 40000,
          earlyStageModestCeiling: 30000,
          midStageLuxuryFloor: 80000,
          midStageModestCeiling: 60000,
          lateStageLuxuryFloor: 120000,
          lateStageModestCeiling: 90000,
          dependentAnnualCost: 10000,

          dependentCount: 0,
          careerPerformance: 'Average',
          lifestyle: 'Average',
          zipCode: 90062,
          birthdate: '04/13/2001',

          // snapshot
          startingIncome: 50000,
          startingExpenses: 30000,
          startingNetIncome: 20000,

          startingAssets: 50000,
          startingLiabilities: 30000,
          startingNetAssets: 20000,

          endingIncome: 50000,
          endingExpenses: 30000,
          endingNetIncome: 20000,

          endingAssets: 50000,
          endingLiabilities: 30000,
          endingNetAssets: 20000,

          // net income
          earlyCareerPay: 45000,
          earlyCareerPayGrowth: 3,
          midCareerPay: 80000,
          midCareerPayGrowth: 5,
          lateCareerPay: 110000,
          lateCareerPayGrowth: 1,
          retirementCareerPayGrowth: 0.1,
          payVariation: 20,
          unemploymentRisk: 10,
          unemploymentPay: 10000,

          earlyCareerAvgTaxRate: 20,
          earlyCareerPercentOfIncome: 70,
          earlyCareerPaydownRate: 0,
          earlyCareerInvestmentAllocation: 10,

          midCareerAvgTaxRate: 22.5,
          midCareerPercentOfIncome: 60,
          midCareerPaydownRate: 5,
          midCareerInvestmentAllocation: 20,

          lateCareerAvgTaxRate: 25,
          lateCareerPercentOfIncome: 45,
          lateCareerPaydownRate: 30,
          lateCareerInvestmentAllocation: 25,

          earlyCareerLength: 10,
          midCareerLength: 10,
          lateCareerLength: 20,
          retirementCareerLength: 25,

          // net worth
          startingCash: 1000,
          startingInvestments: 100,
          investmentAvgReturn: 4,
          realEstateAssets: 0,
          realEstateAvgReturn: 4,
          otherAssets: 10000,

          startingCCDebt: 1000,
          earlyCareerCCSpending: 1,
          earlyCareerCCInterestRate: 20,
          midCareerCCSpending: 1,
          midCareerCCInterestRate: 12,
          lateCareerCCSpending: 1,
          lateCareerCCInterestRate: 10,

          startingSLDebt: 0,
          earlyCareerSLSpending: 1,
          earlyCareerSLInterestRate: 7,
          midCareerSLSpending: 0,
          midCareerSLInterestRate: 7,
          lateCareerSLSpending: 0,
          lateCareerSLInterestRate: 7,

          startingMGDebt: 0,
          earlyCareerMGSpending: 10,
          earlyCareerMGInterestRate: 6,
          midCareerMGSpending: 0,
          midCareerMGInterestRate: 5,
          lateCareerMGSpending: 0,
          lateCareerMGInterestRate: 4,

          startingODDebt: 0,
          earlyCareerODSpending: 0,
          earlyCareerODInterestRate: 7,
          midCareerODSpending: 1,
          midCareerODInterestRate: 6,
          lateCareerODSpending: 1,
          lateCareerODInterestRate: 5,

          unready: true,
          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          favoritedCareers: [],
          favoritedCareerDetails: [],
          selectedCareer: '',

          editSections: ['','Personal Info','Financial Assumptions'],
          entryTypes: ['','Income','Expense','Asset','Liability'],
          yearOptions: [],
          modelYearOptions: [],
          milesPerGallonOptions: [],
          homeTypeOptions: [],
          entryModeOptions: ['','Ballpark Mode','Custom Entries Mode'],
          counterArray: [],

          entryCategories: [],
          assetCategories: [],
          liabilityCategories: [],
          incomeCategories: [],
          expenseCategories: [],

          entries: [],
          assetEntries: [],
          liabilityEntries: [],
          incomeEntries: [],
          expenseEntries: [],

          financialPlanFileNames: [],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullCustomEntries = this.pullCustomEntries.bind(this)
        this.configureChartData = this.configureChartData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchCareers = this.searchCareers.bind(this)
        this.careerClicked = this.careerClicked.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.addEntry = this.addEntry.bind(this)
        this.beginEditingEntry = this.beginEditingEntry.bind(this)
        this.deleteEntry = this.deleteEntry.bind(this)

        this.renderCareerSelectModule = this.renderCareerSelectModule.bind(this)
        this.renderPersonalInfo = this.renderPersonalInfo.bind(this)
        this.renderFinancialAssumptions = this.renderFinancialAssumptions.bind(this)
        this.pullCareerAssumptions = this.pullCareerAssumptions.bind(this)
        this.renderChart = this.renderChart.bind(this)
        this.renderCustomEntries = this.renderCustomEntries.bind(this)

        this.createNewDoc = this.createNewDoc.bind(this)
        this.toggleToolbar = this.toggleToolbar.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)

        this.saveFinancialPlan = this.saveFinancialPlan.bind(this)
        this.exportFile = this.exportFile.bind(this)
        this.loadFinancialPlan = this.loadFinancialPlan.bind(this)
        this.deleteFinancialPlan = this.deleteFinancialPlan.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within SubFinancials ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      } else if (this.props.passedDocFile !== prevProps.passedDocFile) {
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen && this.props.modalIsOpen === true && this.props.selectedFile) {
        this.setState({ modalIsOpen: true, showFile: true, selectedFile: this.props.selectedFile, financialPlanFileName: this.props.selectedFile.fileName })
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubFinancials', this.props)

      let org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pictureURL = localStorage.getItem('pictureURL');
      let activeOrg = localStorage.getItem('activeOrg');

      if (!org) {
        org = activeOrg
      }
      const incomeCategories = [
        { name: '' },
        { name: 'Earned Income' },
        { name: 'Interest & Dividend Income' },
        { name: 'Rental Income' },
        { name: 'Capital Gains Income' },
        { name: 'Royalty Income' },
      ]

      const expenseCategories = [
        { name: '' },
        { name: 'Home', object: { include: true, require: true, type: 'Asset' }, subcategories: ['','Mortgage / Rent Expense','Furnishings','Home Improvement','Home Insurance','Home Services','Home Supplies','Lawn & Garden']},
        { name: 'Car', object: { include: true, require: true, type: 'Asset' }, subcategories: ['','Gas Expense','Maintenance','Auto Insurance Expense','Auto Loan Expense']},
        { name: 'Food', subcategories: ['Groceries Expense','Coffee Shops','Fast Food','Restaurants']},
        { name: 'Education', object: { include: true, require: false, type: 'Asset' }, subcategories: ['','Books & Supplies','Courses','Student Loan Payment']},
        { name: 'Bills & Utilities', subcategories: ['','Mobile Phone','Internet','Utilities']},
        { name: 'Health', subcategories: ['','Health Insurance','Gym Fees','Exercise Classes / Programs','Diet & Nutrition','Surgeries & Disease Treatment','Doctor Visit','Dentist','Eyecare','Pharmacy','Sports']},
        { name: 'Entertainment', subcategories: ['','Movies & Television Shows','Music & Concerts','Travel (e.g., Air Travel, Hotel, Rental Car, Taxi)','Parties & Special Occassions','Alcohol','Bars','Clubs & Lounges','Other']},
        { name: 'Travel', subcategories: ['','Air Travel','Hotel','Rental Car & Taxi','Vacation']},
        { name: 'Gifts & Charity', subcategories: ['','Gifts','Donations']},
        { name: 'Kids', object: { include: true, require: true, type: 'Asset' }, subcategories: ['','Allowance','Baby Supplies','Babysitter & Daycare','Child Support','Kids Activities','Toys / Entertainment','Food','Education']},
        { name: 'Pets', object: { include: true, require: true, type: 'Liability' }, subcategories: ['','Pet Food','Pet Health','Pet Entertainment','Other']},
        { name: 'Personal Care', subcategories: ['','Costmetics','Laundry','Spa & Massage']},
        { name: 'Shopping', subcategories: ['','Books','Clothing','Electronics & Software','Hobbies','Sporting Goods','Other']},
        { name: 'Taxes', subcategories: ['','Federal Tax','Local Tax','Property Tax','Sales Tax','State Tax']},
        { name: 'Saving', subcategories: ['','401k','403b','Roth IRA']},
      ]

      const assetCategories = [
        { name: '' },
        { name: 'Cash' },
        { name: 'Home' },
        { name: 'Car' },
        { name: 'Investments' },
        { name: 'Property' },
        { name: 'Kids' },
      ]

      const liabilityCategories = [
        { name: '' },
        { name: 'Student Loans' },
        { name: 'Credit Card Debt' },
        { name: 'Mortgage' },
        { name: 'Auto Loan' },
        { name: 'Pets' },
      ]

      let yearOptions = ['']
      let modelYearOptions = ['']
      let milesPerGallonOptions = []

      let currentYear = new Date().getFullYear()
      for (let i = 1; i <= 60; i++) {
        yearOptions.push(currentYear + i - 1)
        modelYearOptions.push(currentYear - 59 + i - 1)
        milesPerGallonOptions.push(i - 1)
      }

      const homeTypeOptions = ['','Rented','Bought']

      let entryMode = this.state.entryMode
      if (this.props.source === 'Builders') {
        entryMode = 'Custom Entries Mode'
      }

      let counterArray = []
      for (let i = 1; i <= 10; i++) {
        counterArray.push(i - 1)
      }

      this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus, pictureURL,
        yearOptions, incomeCategories, expenseCategories, assetCategories, liabilityCategories, modelYearOptions,
        milesPerGallonOptions, homeTypeOptions, entryMode, counterArray
      })

      this.configureChartData('Income', this.state.scenario, this.state.duration)

      if (this.props.pageSource !== 'landingPage') {
          // pull favorites
          Axios.get('/api/favorites', { params: { emailId: email } })
         .then((response) => {
           console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             const favorites = response.data.favorites

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             if (favorites && favorites.length > 0) {
               Axios.get('/api/careers/byid', { params: { ids: favorites } })
              .then((response) => {
                console.log('Career details query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data.careers && response.data.careers.length > 0) {
                    const favoritedCareerDetails = response.data.careers
                    let favoritedCareers = []
                    for (let i = 1; i <= favoritedCareerDetails.length; i++) {
                      favoritedCareers.push(favoritedCareerDetails[i - 1].name)
                    }

                    this.setState({ favorites, newFavorites, favoritedCareers, favoritedCareerDetails })

                    if (!this.props.passedDocFile) {
                      if (this.props.source === 'Builders') {
                        this.createNewDoc(cuFirstName, cuLastName)
                      } else {
                        this.pullCustomEntries(email, favoritedCareerDetails)
                      }
                    }
                  }

                } else {
                  console.log('no careers found', response.data.message)

                  if (!this.props.passedDocFile) {
                    if (this.props.source === 'Builders') {
                      this.createNewDoc(cuFirstName, cuLastName)
                    } else {
                      this.pullCustomEntries(email, null)
                    }
                  }
                }

              }).catch((error) => {
                  console.log('Favorites query did not work', error);
              });
             }

           } else {
             console.log('no favorites data found', response.data.message)
             this.pullCustomEntries(email, null)
           }

         }).catch((error) => {
             console.log('Favorites query did not work', error);
         });

         if (this.props.passedDocFile) {

           // this.loadFinancialPlan(this.props.passedDocFile.fileName)

           const selectedFinancialPlan = this.props.passedDocFile
           const financialPlanFileName = selectedFinancialPlan.fileName

           let entries = []
           if (selectedFinancialPlan.docContent && selectedFinancialPlan.docContent.entries) {
             entries = selectedFinancialPlan.docContent.entries
           } else if (selectedFinancialPlan.financialPlanContent && selectedFinancialPlan.financialPlanContent.entries) {
             entries = selectedFinancialPlan.financialPlanContent.entries
           }

           const financialPlans = [selectedFinancialPlan]
           const financialPlanFileNames = [selectedFinancialPlan.fileName]

           this.setState({
             financialPlanFileName, entries, financialPlans, financialPlanFileNames,
             isLoading: false, successMessage: 'Successfully loaded financial plan!'
           })

           this.configureChartData('Income', this.state.scenario, this.state.duration)

         } else {

           let queryLink = '/api/financial-plans'
           if (this.state.showDoc) {
             queryLink = '/api/documents'
           }

           Axios.get(queryLink, { params: { emailId: email } })
          .then((response) => {
            console.log('Financial plan query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved financial plans')

              let financialPlans = response.data.financialPlans
              if (this.state.showDoc) {
                financialPlans = response.data.documents
              }

              let financialPlanFileNames = ['']
              if (financialPlans) {
                for (let i = 0; i < financialPlans.length; i++) {
                  financialPlanFileNames.push(financialPlans[i].fileName)
                }
              }

              this.setState({ financialPlans, financialPlanFileNames })

              if (this.props.passData) {
                this.props.passData({ savedItems: financialPlans })
              }

            } else {
              console.log('no financial plans data found', response.data.message)
              if (this.props.passData) {
                this.props.passData({ savedItems: null })
              }
            }

          }).catch((error) => {
              console.log('Career plan query did not work', error);
          });
         }

        Axios.get('/api/logs/goals', { params: { emailId: email } })
        .then((response) => {
          if (response.data.success) {
            console.log('Goals received query worked', response.data);

            let goals = response.data.goals

            let docContent = {}
            docContent['docType'] = 'Financial Plan'
            docContent['goals'] = goals

            if (this.props.passData) {
              this.props.passData({ docContent })
            }

          } else {
            console.log('no goal data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({ opacity: 1,transform: 'translate(2%)'}));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({opacity: 1,transform: 'translate(2%)'}));
          }, 0.3)
      }
    }

    pullCustomEntries(email, favoritedCareers) {
      console.log('pullCustomEntries called', email, favoritedCareers)

      Axios.get('/api/financials/entries', { params: { emailId: email } })
     .then((response) => {
       console.log('Financial planning entries query attempted', response.data);

       if (response.data.success) {
         console.log('successfully retrieved entries')

         const entries = response.data.entries
         let entryMode = 'Ballpark Mode'
         if (this.props.source === 'Builders') {
           entryMode = 'Custom Entries Mode'
         }

         let assetEntries = []
         let liabilityEntries = []
         let incomeEntries = []
         let expenseEntries = []

         let careerId = null
         for (let i = 1; i <= entries.length; i++) {
           if (entries[i - 1].entryType === 'Income') {
             incomeEntries.push(entries[i - 1])
           } else if (entries[i - 1].entryType === 'Expense') {
             expenseEntries.push(entries[i - 1])
           } else if (entries[i - 1].entryType === 'Asset') {
             assetEntries.push(entries[i - 1])
           } else if (entries[i - 1].entryType === 'Liability') {
             liabilityEntries.push(entries[i - 1])
           }

           if (entries[i - 1].careerId) {
             careerId = entries[i - 1].careerId
           }
         }

         this.setState({ entries, assetEntries, liabilityEntries, incomeEntries, expenseEntries, entryMode })

         if (favoritedCareers) {
           let selectedCareer = null
           for (let i = 1; i <= favoritedCareers.length; i++) {
             if (favoritedCareers[i - 1]._id === careerId) {
               selectedCareer = favoritedCareers[i - 1]
             }
           }

           if (selectedCareer) {
             this.pullCareerAssumptions(selectedCareer)
           }
         }

         this.configureChartData('Income', this.state.scenario, this.state.duration)
         // this.props.passData({ savedItems: null })

       } else {
         console.log('no entries data found', response.data.message)
       }

     }).catch((error) => {
         console.log('Entries query did not work', error);
     });
    }

    configureChartData(chartType, scenario, duration) {
      console.log('configureChartData called', chartType, scenario, duration, this.state.entryMode)

      let label1 = 'Income'
      let label2 = 'Expenses'

      let years = []
      let dataArray1 = []
      let dataArray2 = []

      const currentYear = new Date().getFullYear()

      if (this.state.entryMode === 'Custom Entries Mode') {

        let startingIncome = 0
        let startingExpenses = 0
        let startingNetIncome = 0
        let endingIncome = 0
        let endingExpenses = 0
        let endingNetIncome = 0
        let startingAssets = 0
        let startingLiabilities = 0
        let startingNetAssets = 0
        let endingAssets = 0
        let endingLiabilities = 0
        let endingNetAssets = 0

        if (chartType === 'Assets') {
          label1 = 'Net Worth'
          label2 = 'Liabilities'
        }

        if (duration === 'Retirement') {
          duration = this.state.retirementYear - new Date().getFullYear()
        }

        let calculateYears = false
        let incomeArray = []
        let expenseArray = []
        let assetArray = []
        let liabilityArray = []

        // compile entries
        if (this.state.entries) {
          calculateYears = true
          // console.log('in entries')
          for (let i = 1; i <= this.state.entries.length; i++) {
            console.log('in entries', this.state.entries[i - 1].entryType)
            if (this.state.entries[i - 1].entryType === 'Income') {
              console.log('in income')
              for (let j = 1; j <= Number(duration); j++) {
                console.log('looping income: ', this.state.entries[i - 1].startYear, this.state.entries[i - 1].endYear)

                // let tempStartingAssets = 0
                // if (assetArray[j - 1]) {
                //   tempStartingAssets = assetArray[j - 1]
                // } else if (assetArray[j - 2]) {
                //   tempStartingAssets = assetArray[j - 2]
                // }

                if (this.state.entries[i - 1].startYear <= (currentYear + j - 1) && this.state.entries[i - 1].endYear >= (currentYear + j - 1)) {
                  console.log('within bounds: ', this.state.entries[i - 1].endYear, currentYear + 1 - 1, incomeArray[j - 1])

                  let scenarioFactor = 1
                  let payVariation = this.state.payVariation
                  if (this.state.entries[i - 1].payVariation) {
                    payVariation = this.state.entries[i - 1].payVariation
                  }
                  let unemploymentRisk = this.state.unemploymentRisk
                  if (this.state.entries[i - 1].unemploymentRisk) {
                    unemploymentRisk = this.state.entries[i - 1].unemploymentRisk
                  }

                  if (scenario === 'Bad') {
                    scenarioFactor = 1 - ((payVariation / 100) * 2)
                    if (scenarioFactor + (1 - (unemploymentRisk / 100)) < 1) {
                      scenarioFactor = scenarioFactor + (1 - (unemploymentRisk / 100))
                    }
                  } else if (scenario === 'Great') {
                    scenarioFactor = 1 + ((payVariation / 100) * 2)
                  }

                  let performanceFactor = 0.3
                  if (this.state.entries[i - 1].performanceMultiplier) {
                    performanceFactor = (this.state.entries[i - 1].performanceMultiplier / 100)
                  }

                  let performanceMultiplier = 1
                  if (this.state.careerPerformance === 'High') {
                    performanceMultiplier = 1 + performanceFactor
                  } else if (this.state.careerPerformance === 'Low') {
                    performanceMultiplier = 1 - performanceFactor
                  }

                  let amount = Number(this.state.entries[i - 1].amount)
                  let growthRate = Number(this.state.entries[i - 1].annualGrowthRate) * scenarioFactor * performanceMultiplier
                  let multiplierBase = (1 + (growthRate / 100))
                  let multiplier = Math.pow(multiplierBase, j)
                  console.log('adding income: ', j - 1, amount, growthRate, multiplierBase, multiplier, amount * multiplier)

                  if (incomeArray[j - 1]) {
                    incomeArray[j - 1] = Number(incomeArray[j - 1]) + Number((amount * multiplier).toFixed())
                  } else {
                    incomeArray[j - 1] = Number(amount * multiplier).toFixed()
                  }

                  // // retained earnings
                  // if (tempStartingAssets) {
                  //   assetArray[j - 1] = Number(tempStartingAssets) + Number((amount * multiplier).toFixed())
                  // } else {
                  //   assetArray[j - 1] = Number((amount * multiplier).toFixed())
                  //   console.log('adding assets: ', j, assetArray[j - 1])
                  // }
                } else {

                  // if (tempStartingAssets) {
                  //   assetArray[j - 1] = tempStartingAssets
                  // }
                }
              }
            } else if (this.state.entries[i - 1].entryType === 'Expense') {
              console.log('in expense')
              for (let j = 1; j <= Number(duration); j++) {
                console.log('looping expenses: ', this.state.entries[i - 1].startYear, this.state.entries[i - 1].endYear)

                // let tempStartingAssets = 0
                // if (assetArray[j - 1]) {
                //   tempStartingAssets = assetArray[j - 1]
                // } else if (assetArray[j - 2]) {
                //   tempStartingAssets = assetArray[j - 2]
                // }

                if (this.state.entries[i - 1].startYear <= (currentYear + j - 1) && this.state.entries[i - 1].endYear >= (currentYear + j - 1)) {
                  console.log('within bounds: ', this.state.entries[i - 1].endYear, currentYear + 1 - 1, expenseArray[j - 1])

                  let amount = Number(this.state.entries[i - 1].amount)
                  let growthRate = Number(this.state.entries[i - 1].annualGrowthRate)
                  let multiplierBase = (1 + (growthRate / 100))
                  let multiplier = Math.pow(multiplierBase, j)
                  console.log('adding expense: ', j - 1, amount, growthRate, multiplierBase, multiplier, amount * multiplier)

                  if (expenseArray[j - 1]) {
                    expenseArray[j - 1] = Number(expenseArray[j - 1]) + Number((amount * multiplier).toFixed())
                  } else {
                    expenseArray[j - 1] = Number(amount * multiplier).toFixed()
                  }

                  // // retained earnings
                  // console.log('adding assets: ', j, assetArray[j - 1], assetArray[j - 2])
                  // if (tempStartingAssets) {
                  //   console.log('is starting assets')
                  //   assetArray[j - 1] = Number(tempStartingAssets) - Number((amount * multiplier).toFixed())
                  // } else {
                  //   console.log('no starting assets')
                  //   assetArray[j - 1] = 0 - Number((amount * multiplier).toFixed())
                  //
                  // }
                } else {
                  // if (tempStartingAssets) {
                  //   assetArray[j - 1] = tempStartingAssets
                  // }
                }
              }
            } else if (this.state.entries[i - 1].entryType === 'Asset') {
              console.log('in asset')
              for (let j = 1; j <= Number(duration); j++) {
                console.log('looping assets: ', this.state.entries[i - 1].startYear)

                let tempStartingAssets = 0
                if (assetArray[j - 1]) {
                  tempStartingAssets = assetArray[j - 1]
                } else if (assetArray[j - 2]) {
                  tempStartingAssets = assetArray[j - 2]
                }

                if (this.state.entries[i - 1].startYear <= (currentYear + j - 1)) {
                  console.log('within bounds: ', currentYear + 1 - 1)

                  let amount = Number(this.state.entries[i - 1].amount)
                  let growthRate = Number(this.state.entries[i - 1].annualGrowthRate)
                  let multiplierBase = (1 + (growthRate / 100))
                  let multiplier = Math.pow(multiplierBase, j)
                  console.log('adding asset: ', j - 1, amount, growthRate, multiplierBase, multiplier, amount * multiplier)

                  if (tempStartingAssets) {
                    assetArray[j - 1] = Number(tempStartingAssets) + Number((amount * multiplier).toFixed())
                  } else {
                    console.log('adding assets: ', j, assetArray[j - 1])
                    assetArray[j - 1] = Number((amount * multiplier).toFixed())
                  }
                }
              }
            } else if (this.state.entries[i - 1].entryType === 'Liability') {
              console.log('in liability')
              for (let j = 1; j <= Number(duration); j++) {
                console.log('looping liabilities: ', this.state.entries[i - 1].startYear)

                let tempStartingLiabilities = 0
                if (liabilityArray[j - 1] || liabilityArray[j - 1] === 0) {
                  tempStartingLiabilities = liabilityArray[j - 1]
                } else if (liabilityArray[j - 2]) {
                  tempStartingLiabilities = liabilityArray[j - 2]
                }

                if (this.state.entries[i - 1].startYear <= (currentYear + j - 1)) {
                  console.log('within bounds: ', currentYear + 1 - 1)

                  let amount = Number(this.state.entries[i - 1].amount)
                  if (j > 1) {
                    if (liabilityArray[j - 2]) {
                      amount = liabilityArray[j - 2]
                    } else {
                      amount = 0
                    }
                  }

                  if (tempStartingLiabilities) {
                    liabilityArray[j - 1] = Number(tempStartingLiabilities)
                    tempStartingLiabilities = liabilityArray[j - 1]
                  } else {
                    liabilityArray[j - 1] = Number((amount).toFixed())
                    tempStartingLiabilities = liabilityArray[j - 1]
                  }

                  let interestRate = Number(this.state.entries[i - 1].interestRate)
                  let interestGrowsLiablity = false

                  if (interestGrowsLiablity) {
                    let multiplierBase = (1 + (interestRate / 100))
                    let multiplier = Math.pow(multiplierBase, j)
                    console.log('adding liability: ', j - 1, amount, interestRate, multiplierBase, multiplier, amount * multiplier)

                    if (tempStartingLiabilities) {
                      liabilityArray[j - 1] = Number(tempStartingLiabilities) + Number((amount * multiplier).toFixed())
                    } else {
                      console.log('adding liabilities: ', j, liabilityArray[j - 1])
                      liabilityArray[j - 1] = Number((amount * multiplier).toFixed())
                    }
                  } else {

                    // add interest expense
                    const interestExpense = (interestRate / 100) * Number(amount.toFixed())

                    if (expenseArray[j - 1]) {
                      expenseArray[j - 1] = Number(expenseArray[j - 1]) + interestExpense
                    } else {
                      expenseArray[j - 1] = interestExpense
                    }

                    // paydown debt
                    if (this.state.entries[i - 1].entryPaydownRate) {
                      const paydownRate = this.state.entries[i - 1].entryPaydownRate
                      let paydownExpense = (paydownRate / 100) * Number(amount.toFixed())

                      if (tempStartingLiabilities) {
                        console.log('adding liabilities 1: ', j, liabilityArray[j - 1])
                        if (Number(tempStartingLiabilities) <= paydownExpense) {
                          console.log('adding liabilities 4: ', j, liabilityArray[j - 1], tempStartingLiabilities, paydownExpense)
                          liabilityArray[j - 1] = 0
                          paydownExpense = Number(tempStartingLiabilities)
                        } else {
                          console.log('adding liabilities 3: ', j, liabilityArray[j - 1], tempStartingLiabilities, paydownExpense)
                          liabilityArray[j - 1] = Number(tempStartingLiabilities) - paydownExpense
                        }
                      } else {
                        console.log('adding liabilities 2: ', j, liabilityArray[j - 1])
                        // liabilityArray[j - 1] = Number(amount.toFixed())
                        paydownExpense = 0

                      }

                      if (expenseArray[j - 1]) {
                        expenseArray[j - 1] = Number(expenseArray[j - 1]) + paydownExpense
                      } else {
                        expenseArray[j - 1] = paydownExpense
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (calculateYears) {

          if (incomeArray[0]) {
            startingIncome = incomeArray[0]
          }

          if (expenseArray[0]) {
            startingExpenses = expenseArray[0]
          }
          if (incomeArray[0] || expenseArray[0]) {
            let tempIncome = 0
            if (incomeArray[0]) {
              tempIncome = incomeArray[0]
            }
            let tempExpenses = 0
            if (expenseArray[0]) {
              tempExpenses = expenseArray[0]
            }
            startingNetIncome = tempIncome - tempExpenses
          }

          if (assetArray[0]) {
            startingAssets = assetArray[0]
          }
          if (liabilityArray[0]) {
            startingLiabilities = liabilityArray[0]
          }
          if (assetArray[0] || liabilityArray[0]) {
            let tempAssets = 0
            if (assetArray[0]) {
              tempAssets = assetArray[0]
            }
            let tempLiabilities = 0
            if (liabilityArray[0]) {
              tempLiabilities = liabilityArray[0]
            }
            startingNetAssets = tempAssets - tempLiabilities
          }

          for (let i = 1; i <= Number(duration); i++) {
            years.push(currentYear + i - 1)

            let incomeObject = 0
            if (incomeArray[i - 1]) {
              incomeObject = Number(incomeArray[i - 1]).toFixed()
            }

            let expenseObject = 0
            if (expenseArray[i - 1]) {
              expenseObject = Number(expenseArray[i - 1]).toFixed()
            }

            const netIncomeObject = incomeObject - expenseObject
            if (netIncomeObject > 0) {
              // retained earnings
              if (assetArray[i - 1]) {
                assetArray[i - 1] = assetArray[i - 1] + netIncomeObject
              } else if (assetArray[i - 2]) {
                assetArray[i - 1] = assetArray[i - 2] + netIncomeObject
              } else {
                assetArray[i - 1] = netIncomeObject
              }
            } else {
              // increased liabilities
              if (liabilityArray[i - 1]) {
                liabilityArray[i - 1] = liabilityArray[i - 1] - netIncomeObject
              } else if (assetArray[i - 2]) {
                liabilityArray[i - 1] = liabilityArray[i - 2] - netIncomeObject
              } else {
                liabilityArray[i - 1] = 0 - netIncomeObject
              }
            }

            let assetObject = 0
            if (assetArray[i - 1]) {
              assetObject = Number(assetArray[i - 1]).toFixed()
            } else if (assetArray[i - 2]) {
              assetObject = Number(assetArray[i - 2]).toFixed()
            }

            let liabilityObject = 0
            if (liabilityArray[i - 1]) {
              liabilityObject = Number(liabilityArray[i - 1]).toFixed()
            } else if (liabilityArray[i - 2]) {
              liabilityObject = Number(liabilityArray[i - 2]).toFixed()
            }

            console.log('show account values: ', i, incomeObject, expenseObject, assetObject, liabilityObject)

            // adjust chart data
            if (chartType === 'Income') {
              dataArray1.push(incomeObject)
              dataArray2.push(expenseObject)
            } else if (chartType === 'Assets') {
              console.log('l1: ', liabilityArray)

              dataArray1.push(assetObject - liabilityObject)
              dataArray2.push(liabilityObject)

            }
          }

          if (incomeArray[Number(duration) - 1]) {
            endingIncome = incomeArray[Number(duration) - 1]
          }

          if (expenseArray[Number(duration) - 1]) {
            endingExpenses = expenseArray[Number(duration) - 1]
          }

          if (endingIncome || endingExpenses) {
            let tempEndingIncome = 0
            if (endingIncome) {
              tempEndingIncome = endingIncome
            }

            let tempEndingExpenses = 0
            if (endingExpenses) {
              tempEndingExpenses = endingExpenses
            }

            endingNetIncome = tempEndingIncome - tempEndingExpenses
          }

          if (assetArray[Number(duration) - 1]) {
            endingAssets = assetArray[Number(duration) - 1]
          }

          if (liabilityArray[Number(duration) - 1]) {
            endingLiabilities = liabilityArray[Number(duration) - 1]
          }

          if (endingAssets || endingLiabilities) {
            let tempEndingAssets = 0
            if (endingAssets) {
              tempEndingAssets = endingAssets
            }

            let tempEndingLiabilities = 0
            if (endingLiabilities) {
              tempEndingLiabilities = endingLiabilities
            }

            endingNetAssets = tempEndingAssets - tempEndingLiabilities
          }
        }

        const chartData = {
          labels: years,
          datasets: [
            {
              label: label1,
              data: dataArray1,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            },
            {
              label: label2,
              data: dataArray2,
              fill: false,
              borderColor: "#742774"
            }
          ]
        };
        console.log('show the arrays', incomeArray, expenseArray, assetArray, liabilityArray)
        this.setState({
          startingIncome, startingExpenses, startingNetIncome,
          endingIncome, endingExpenses, endingNetIncome,
          startingAssets, startingLiabilities, startingNetAssets,
          endingAssets, endingLiabilities, endingNetAssets,
          chartData, chartType, scenario, duration,
          incomeArray, expenseArray, assetArray, liabilityArray
        })

      } else {
        let startingIncome = this.state.earlyCareerPay

        if (this.state.careerStage === 'Mid') {
          startingIncome = this.state.midCareerPay
        } else if (this.state.careerStage === 'Late') {
          startingIncome = this.state.lateCareerPay
        }

        let startingExpenses = ((this.state.earlyCareerPercentOfIncome / 100) * ((1 - (this.state.earlyCareerAvgTaxRate / 100)) * startingIncome)) + ((this.state.earlyCareerAvgTaxRate / 100) * startingIncome) + ((this.state.earlyCareerCCInterestRate / 100) * this.state.startingCCDebt) + ((this.state.earlyCareerSLInterestRate / 100) * this.state.startingSLDebt) + ((this.state.earlyCareerMGInterestRate / 100) * this.state.startingMGDebt) + ((this.state.earlyCareerODInterestRate / 100) * this.state.startingODDebt)
        if (this.state.careerStage === 'Mid') {
          startingExpenses = ((this.state.midCareerPercentOfIncome / 100) * startingIncome - ((1 - (this.state.midCareerAvgTaxRate / 100)) * startingIncome)) + ((this.state.midCareerAvgTaxRate / 100) * startingIncome) + ((this.state.midCareerInterest / 100) * this.state.startingCCDebt) + ((this.state.midCareerCCInterestRate / 100) * this.state.startingCCDebt) + ((this.state.midCareerMGInterestRate / 100) * this.state.startingMGDebt) + ((this.state.midCareerSLInterestRate / 100) * this.state.startingSLDebt) + (this.state.midCareerODInterestRate * this.state.startingODDebt)
        } else if (this.state.careerStage === 'Late') {
          startingExpenses = ((this.state.lateCareerPercentOfIncome / 100) * startingIncome - ((1 - (this.state.lateCareerAvgTaxRate / 100)) * startingIncome)) + ((this.state.lateCareerAvgTaxRate / 100) * startingIncome) + ((this.state.lateCareerInterest / 100) * this.state.startingCCDebt) + ((this.state.lateCareerCCInterestRate / 100) * this.state.startingCCDebt) + ((this.state.lateCareerMGInterestRate / 100) * this.state.startingMGDebt) + ((this.state.lateCareerSLInterestRate / 100) * this.state.startingSLDebt) + ((this.state.lateCareerODInterestRate / 100) * this.state.startingODDebt)
        }

        let startingNetIncome = startingIncome - startingExpenses

        if (chartType === 'Assets') {
          label1 = 'Net Worth'
          label2 = 'Liabilities'
        }

        if (duration === 'Retirement') {
          duration = this.state.retirementYear - new Date().getFullYear()
        }

        let scenarioFactor = 1
        if (scenario === 'Bad') {
          scenarioFactor = 1 - ((this.state.payVariation / 100) * 2)
          if (scenarioFactor + (1 - (this.state.unemploymentRisk / 100)) < 1) {
            scenarioFactor = scenarioFactor + (1 - (this.state.unemploymentRisk / 100))
          }
        } else if (scenario === 'Great') {
          scenarioFactor = 1 + ((this.state.payVariation / 100) * 2)
        }

        let performanceMultiplier = 1
        if (this.state.careerPerformance === 'High') {
          performanceMultiplier = this.state.highPerformanceMultiplier
        } else if (this.state.careerPerformance === 'Low') {
          performanceMultiplier = this.state.lowPerformanceMultiplier
        }

        let lifestyleMultiplier = 1
        if (this.state.lifestyle === 'Luxury') {
          lifestyleMultiplier = this.state.luxuryLifestyleMultiplier
        } else if (this.state.lifestyle === 'Modest') {
          lifestyleMultiplier = this.state.modestLifestyleMultiplier
        }

        let endingIncome = startingIncome * scenarioFactor
        let endingExpenses = 0
        let endingNetIncome = endingIncome - endingExpenses

        let ccDebt = Number(this.state.startingCCDebt)
        let slDebt = Number(this.state.startingSLDebt)
        let mgDebt = Number(this.state.startingMGDebt)
        let odDebt = Number(this.state.startingODDebt)

        let startingCash = Number(this.state.startingCash)
        let startingInvestments = Number(this.state.startingInvestments)
        let startingRealEstate = Number(this.state.realEstateAssets)
        let otherAssets = Number(this.state.otherAssets)

        let startingAssets = startingCash + startingInvestments + startingRealEstate + otherAssets
        let startingLiabilities = ccDebt + slDebt + mgDebt + odDebt
        let startingNetAssets = startingAssets - startingLiabilities
        // console.log('show startingAssets: ', startingAssets, this.state.startingCash, this.state.startingInvestments, this.state.startingRealEstate, this.state.otherAssets)

        let endingAssets = 0
        let endingLiabilities = 0
        let endingNetAssets = endingAssets - endingLiabilities

        // console.log('show startingIncome: ', startingIncome, endingIncome)

        for (let i = 1; i <= Number(duration); i++) {
          years.push(currentYear + i - 1)

          let discretionarySpending = null
          let taxExpense = null
          let ccInterestExpense = null
          let slInterestExpense = null
          let mgInterestExpense = null
          let odInterestExpense = null
          let paydownDebt = 0

          if (this.state.careerStage === 'Early' && i < this.state.earlyCareerLength) {

            endingIncome = (endingIncome * (1 + ((this.state.earlyCareerPayGrowth / 100) * scenarioFactor * performanceMultiplier))).toFixed()
            console.log('in early career length', i, endingIncome)
            if (Number(endingIncome) < 10000) {
              endingIncome = 10000
            }

            taxExpense = (this.state.earlyCareerAvgTaxRate / 100) * endingIncome
            discretionarySpending = ((this.state.earlyCareerPercentOfIncome / 100) * lifestyleMultiplier) * (endingIncome - taxExpense)
            if (this.state.dependentCount > 0) {
              discretionarySpending = discretionarySpending + (this.state.dependentCount * this.state.dependentAnnualCost)
            }
            if (this.state.lifestyle === 'Luxury' && discretionarySpending < this.state.earlyStageLuxuryFloor) {
              discretionarySpending = this.state.earlyStageLuxuryFloor
            } else if (this.state.lifestyle === 'Modest' && discretionarySpending > this.state.earlyStageModestCeiling) {
              discretionarySpending = this.state.earlyStageModestCeiling
            }

            ccDebt = ccDebt + (discretionarySpending * (this.state.earlyCareerCCSpending / 100))
            ccInterestExpense = ccDebt * (this.state.earlyCareerCCInterestRate / 100)
            slDebt = slDebt + (discretionarySpending * (this.state.earlyCareerSLSpending / 100))
            slInterestExpense = slDebt * (this.state.earlyCareerSLInterestRate / 100)
            mgDebt = mgDebt + (discretionarySpending * (this.state.earlyCareerMGSpending / 100))
            mgInterestExpense = mgDebt * (this.state.earlyCareerMGInterestRate / 100)
            odDebt = odDebt + (discretionarySpending * (this.state.earlyCareerODSpending / 100))
            odInterestExpense = odDebt * (this.state.earlyCareerODInterestRate / 100)
            paydownDebt = (this.state.earlyCareerPaydownRate / 100) * (endingIncome - taxExpense)

            if (ccDebt > paydownDebt) {
              ccDebt = ccDebt - paydownDebt
            } else if (ccDebt === paydownDebt) {
              ccDebt = 0
            } else {
              // ccDebt < paydownDebt
              ccDebt = 0
              if (slDebt > paydownDebt) {
                slDebt = slDebt - paydownDebt
              } else if (slDebt === paydownDebt) {
                slDebt = 0
              } else {
                 // slDebt < paydownDebt
                 slDebt = 0
                 if (mgDebt > paydownDebt) {
                   mgDebt = mgDebt - paydownDebt
                 } else if (mgDebt === paydownDebt) {
                   mgDebt = 0
                 } else {
                    // mgDebt < paydownDebt
                    mgDebt = 0

                    if (odDebt > paydownDebt) {
                      odDebt = odDebt - paydownDebt
                    } else if (odDebt === paydownDebt) {
                      odDebt = 0
                    } else {
                       // odDebt < paydownDebt
                       odDebt = 0

                       // the rest in investments
                       startingInvestments = paydownDebt
                       paydownDebt = 0

                    }

                 }
              }
            }

            endingExpenses = (discretionarySpending + taxExpense + (ccInterestExpense + slInterestExpense + mgInterestExpense + odInterestExpense) + paydownDebt).toFixed()
          }

          let isMidCareer = false
          if (this.state.careerStage === 'Mid' && i < this.state.midCareerLength) {
            isMidCareer = true
          } else if (this.state.careerStage === 'Early' && i > this.state.earlyCareerLength && i < (this.state.earlyCareerLength + this.state.midCareerLength)) {
            isMidCareer = true
          }

          if (isMidCareer) {
            console.log('in mid career length', i)

            endingIncome = (endingIncome * (1 + ((this.state.midCareerPayGrowth / 100) * scenarioFactor * performanceMultiplier))).toFixed()
            if ((this.state.midCareerPay * scenarioFactor * performanceMultiplier) > endingIncome) {
              endingIncome = this.state.midCareerPay * scenarioFactor * performanceMultiplier
            }
            if (Number(endingIncome) < 10000) {
              endingIncome = 10000
            }

            taxExpense = (this.state.midCareerAvgTaxRate / 100) * endingIncome
            discretionarySpending = ((this.state.midCareerPercentOfIncome / 100) * lifestyleMultiplier) * (endingIncome - taxExpense)
            if (this.state.dependentCount > 0) {
              discretionarySpending = discretionarySpending + (this.state.dependentCount * this.state.dependentAnnualCost)
            }
            if (this.state.lifestyle === 'Luxury' && discretionarySpending < this.state.midStageLuxuryFloor) {
              discretionarySpending = this.state.midStageLuxuryFloor
            } else if (this.state.lifestyle === 'Modest' && discretionarySpending > this.state.midStageModestCeiling) {
              discretionarySpending = this.state.midStageModestCeiling
            }

            taxExpense = (this.state.midCareerAvgTaxRate / 100) * endingIncome
            ccDebt = ccDebt + (discretionarySpending * (this.state.midCareerCCSpending / 100))
            ccInterestExpense = ccDebt * (this.state.midCareerCCInterestRate / 100)
            slDebt = slDebt + (discretionarySpending * (this.state.midCareerSLSpending / 100))
            slInterestExpense = slDebt * (this.state.midCareerSLInterestRate / 100)
            mgDebt = mgDebt + (discretionarySpending * (this.state.midCareerMGSpending / 100))
            mgInterestExpense = mgDebt * (this.state.midCareerMGInterestRate / 100)
            odDebt = odDebt + (discretionarySpending * (this.state.midCareerODSpending / 100))
            odInterestExpense = odDebt * (this.state.midCareerODInterestRate / 100)
            paydownDebt = (this.state.midCareerPaydownRate / 100) * (endingIncome - taxExpense)
            if (ccDebt > paydownDebt) {
              ccDebt = ccDebt - paydownDebt
            } else if (ccDebt === paydownDebt) {
              ccDebt = 0
            } else {
              // ccDebt < paydownDebt
              ccDebt = 0
              if (slDebt > paydownDebt) {
                slDebt = slDebt - paydownDebt
              } else if (slDebt === paydownDebt) {
                slDebt = 0
              } else {
                 // slDebt < paydownDebt
                 slDebt = 0
                 if (mgDebt > paydownDebt) {
                   mgDebt = mgDebt - paydownDebt
                 } else if (mgDebt === paydownDebt) {
                   mgDebt = 0
                 } else {
                    // odDebt < paydownDebt
                    mgDebt = 0

                    if (odDebt > paydownDebt) {
                      odDebt = odDebt - paydownDebt
                    } else if (odDebt === paydownDebt) {
                      odDebt = 0
                    } else {
                       // odDebt < paydownDebt
                       odDebt = 0

                       // the rest in investments
                       startingInvestments = paydownDebt
                       paydownDebt = 0

                    }

                 }
              }
            }

            endingExpenses = (discretionarySpending + taxExpense + (ccInterestExpense + slInterestExpense + mgInterestExpense + odInterestExpense) + paydownDebt).toFixed()
          }

          let isLateCareer = false
          if (this.state.careerStage === 'Late' && i <= this.state.lateCareerLength) {
            isLateCareer = true
          } else if (this.state.careerStage === 'Early' && i > (this.state.earlyCareerLength + this.state.midCareerLength) && i < (this.state.earlyCareerLength + this.state.midCareerLength + this.state.lateCareerLength)) {
            isLateCareer = true
          } else if (this.state.careerStage === 'Mid' && i > this.state.midCareerLength && i < (this.state.midCareerLength + this.state.lateCareerLength)) {
            isLateCareer = true
          }
          // console.log('is late? ', i)
          if (isLateCareer) {
            console.log('in late career length', i, this.state.lateCareerPay)

            endingIncome = (endingIncome * (1 + ((this.state.lateCareerPayGrowth / 100) * scenarioFactor * performanceMultiplier))).toFixed()
            if ((this.state.lateCareerPay * scenarioFactor * performanceMultiplier) > endingIncome) {
              endingIncome = this.state.lateCareerPay * scenarioFactor * performanceMultiplier
            }
            if (Number(endingIncome) < 10000) {
              endingIncome = 10000
            }

            taxExpense = (this.state.lateCareerAvgTaxRate / 100) * endingIncome
            discretionarySpending = ((this.state.lateCareerPercentOfIncome / 100) * lifestyleMultiplier) * (endingIncome - taxExpense)

            if (this.state.dependentCount > 0) {
              discretionarySpending = discretionarySpending + (this.state.dependentCount * this.state.dependentAnnualCost)
            }

            if (this.state.lifestyle === 'Luxury' && discretionarySpending < this.state.lateStageLuxuryFloor) {
              discretionarySpending = this.state.lateStageLuxuryFloor
            } else if (this.state.lifestyle === 'Modest' && discretionarySpending > this.state.lateStageModestCeiling) {
              discretionarySpending = this.state.lateStageModestCeiling
            }

            taxExpense = (this.state.lateCareerAvgTaxRate / 100) * endingIncome
            ccDebt = ccDebt + (discretionarySpending * (this.state.lateCareerCCSpending / 100))
            ccInterestExpense = ccDebt * (this.state.lateCareerCCInterestRate / 100)
            slDebt = slDebt + (discretionarySpending * (this.state.lateCareerSLSpending / 100))
            slInterestExpense = slDebt * (this.state.lateCareerSLInterestRate / 100)
            mgDebt = mgDebt + (discretionarySpending * (this.state.lateCareerMGSpending / 100))
            mgInterestExpense = mgDebt * (this.state.lateCareerMGInterestRate / 100)
            odDebt = odDebt + (discretionarySpending * (this.state.lateCareerODSpending / 100))
            odInterestExpense = odDebt * (this.state.lateCareerODInterestRate / 100)
            paydownDebt = (this.state.lateCareerPaydownRate / 100) * (endingIncome - taxExpense)
            if (ccDebt > paydownDebt) {
              ccDebt = ccDebt - paydownDebt
            } else if (ccDebt === paydownDebt) {
              ccDebt = 0
            } else {
              // ccDebt < paydownDebt
              ccDebt = 0
              if (slDebt > paydownDebt) {
                slDebt = slDebt - paydownDebt
              } else if (slDebt === paydownDebt) {
                slDebt = 0
              } else {
                 // slDebt < paydownDebt
                 slDebt = 0
                 if (mgDebt > paydownDebt) {
                   mgDebt = mgDebt - paydownDebt
                 } else if (mgDebt === paydownDebt) {
                   mgDebt = 0
                 } else {
                    // mgDebt < paydownDebt
                    mgDebt = 0

                    if (odDebt > paydownDebt) {
                      odDebt = odDebt - paydownDebt
                    } else if (odDebt === paydownDebt) {
                      odDebt = 0
                    } else {
                       // odDebt < paydownDebt
                       odDebt = 0

                       // the rest in investments
                       startingInvestments = paydownDebt
                       paydownDebt = 0

                    }

                 }
              }
            }
            endingExpenses = (discretionarySpending + taxExpense + (this.state.lateCareerAvgTaxRate / 100) * endingIncome + (ccInterestExpense + slInterestExpense + mgInterestExpense + odInterestExpense) + paydownDebt).toFixed()
          }

          let isRetirementCareer = false
          if (this.state.careerStage === 'Late' && i > this.state.lateCareerLength) {
            isRetirementCareer = true
          } else if (this.state.careerStage === 'Early' && i > (this.state.earlyCareerLength + this.state.midCareerLength + this.state.lateCareerLength)) {
            isRetirementCareer = true
          } else if (this.state.careerStage === 'Mid' && i > (this.state.midCareerLength + this.state.lateCareerLength)) {
            isRetirementCareer = true
          }
          // console.log('show retirement: ', i, this.state.earlyCareerLength + this.state.midCareerLength + this.state.lateCareerLength, isRetirementCareer)

          if (isRetirementCareer) {
            console.log('in retirement career length', i, endingIncome, (1 + ((this.state.retirementCareerPayGrowth / 100) * scenarioFactor * performanceMultiplier)))

            endingIncome = (endingIncome * (1 + ((this.state.retirementCareerPayGrowth / 100) * scenarioFactor * performanceMultiplier))).toFixed()
            if (Number(endingIncome) < 10000) {
              endingIncome = 10000
            }

            taxExpense = (this.state.lateCareerAvgTaxRate / 100) * endingIncome
            discretionarySpending = ((this.state.lateCareerPercentOfIncome / 100) * lifestyleMultiplier) * (endingIncome - taxExpense)
            if (this.state.lifestyle === 'Luxury' && discretionarySpending < this.state.lateStageLuxuryFloor) {
              discretionarySpending = this.state.lateStageLuxuryFloor
            } else if (this.state.lifestyle === 'Modest' && discretionarySpending > this.state.lateStageModestCeiling) {
              discretionarySpending = this.state.lateStageModestCeiling
            }

            taxExpense = (this.state.lateCareerAvgTaxRate / 100) * endingIncome
            ccDebt = ccDebt + (discretionarySpending * (this.state.lateCareerCCSpending / 100))
            ccInterestExpense = ccDebt * (this.state.lateCareerCCInterestRate / 100)
            slDebt = slDebt + (discretionarySpending * (this.state.lateCareerSLSpending / 100))
            slInterestExpense = slDebt * (this.state.lateCareerSLInterestRate / 100)
            mgDebt = mgDebt + (discretionarySpending * (this.state.lateCareerMGSpending / 100))
            mgInterestExpense = mgDebt * (this.state.lateCareerMGInterestRate / 100)
            odDebt = odDebt + (discretionarySpending * (this.state.lateCareerODSpending / 100))
            odInterestExpense = odDebt * (this.state.lateCareerODInterestRate / 100)
            paydownDebt = (this.state.lateCareerPaydownRate / 100) * (endingIncome - taxExpense)
            if (ccDebt > paydownDebt) {
              ccDebt = ccDebt - paydownDebt
            } else if (ccDebt === paydownDebt) {
              ccDebt = 0
            } else {
              // ccDebt < paydownDebt
              ccDebt = 0
              if (slDebt > paydownDebt) {
                slDebt = slDebt - paydownDebt
              } else if (slDebt === paydownDebt) {
                slDebt = 0
              } else {
                 // slDebt < paydownDebt
                 slDebt = 0
                 if (mgDebt > paydownDebt) {
                   mgDebt = mgDebt - paydownDebt
                 } else if (mgDebt === paydownDebt) {
                   mgDebt = 0
                 } else {
                    // mgDebt < paydownDebt
                    mgDebt = 0

                    if (odDebt > paydownDebt) {
                      odDebt = odDebt - paydownDebt
                    } else if (odDebt === paydownDebt) {
                      odDebt = 0
                    } else {
                       // odDebt < paydownDebt
                       odDebt = 0

                       // the rest in investments
                       startingInvestments = paydownDebt
                       paydownDebt = 0

                    }

                 }
              }
            }
            endingExpenses = (discretionarySpending + taxExpense + (this.state.lateCareerAvgTaxRate / 100) * endingIncome + (ccInterestExpense + slInterestExpense + mgInterestExpense + odInterestExpense) + paydownDebt).toFixed()
          }

          startingInvestments = startingInvestments * (1 + (this.state.investmentAvgReturn / 100))
          startingRealEstate = startingRealEstate * (1 + (this.state.realEstateAvgReturn / 100))
          // console.log('show the key values: ', startingCash, startingInvestments, otherAssets)
          endingAssets = (startingCash + startingInvestments + startingRealEstate + otherAssets).toFixed()
          endingLiabilities = (ccDebt + slDebt + mgDebt + odDebt).toFixed()
          // if (Number(endingAssets) < Number(endingLiabilities)) {
          //   console.log('what is happening 1: ', Number(endingAssets), Number(endingLiabilities))
          //   const difference = Number(endingLiabilities) - Number(endingAssets)
          //   startingCash = Number(startingCash) + difference
          //   endingAssets = Number(endingAssets) + difference
          //
          //   console.log('what is happening 2: ', difference, endingAssets, endingLiabilities)
          // }

          endingNetAssets = endingAssets - endingLiabilities

          console.log('show assets: ', endingAssets, endingLiabilities)

          if (chartType === 'Income') {

            // console.log('show endingData: ', slDebt, discretionarySpending, taxExpense, ccInterestExpense, slInterestExpense, odInterestExpense)
            dataArray1.push(Number(endingIncome).toFixed())
            dataArray2.push(Number(endingExpenses).toFixed())

          } else if (chartType === 'Assets') {

            dataArray1.push(Number(endingNetAssets).toFixed())
            dataArray2.push(Number(endingLiabilities).toFixed())
            // startingInvestments = startingInvestments
            // let otherAssets = this.state.otherAssets
          }
        }

        const chartData = {
          labels: years,
          datasets: [
            {
              label: label1,
              data: dataArray1,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)"
            },
            {
              label: label2,
              data: dataArray2,
              fill: false,
              borderColor: "#742774"
            }
          ]
        };

        this.setState({
          startingIncome, startingExpenses, startingNetIncome,
          endingIncome, endingExpenses, endingNetIncome,
          startingAssets, startingLiabilities, startingNetAssets,
          endingAssets, endingLiabilities, endingNetAssets,
          chartData, chartType, scenario, duration
        })
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchCareers') {
        console.log('show it: ', event.target.value)
        this.searchCareers(event.target.value)
      } else if (event.target.name === 'editSection') {
        this.setState({ editSection: event.target.value })
      } else if (event.target.name === 'careerPerformance') {
        this.setState({ careerPerformance: event.target.value })
      } else if (event.target.name === 'careerStage') {
        this.setState({ careerStage: event.target.value })
      } else if (event.target.name === 'zipcode') {
        this.setState({ zipcode: event.target.value })
      } else if (event.target.name === 'lifestyle') {
        this.setState({ lifestyle: event.target.value })
      } else if (event.target.name === 'birthdate') {
        this.setState({ birthdate: event.target.value })
      } else if (event.target.name === 'retirementYear') {
        this.setState({ retirementYear: event.target.value })
      } else if (event.target.name === 'dependentCount') {
        this.setState({ dependentCount: event.target.value })
      } else if (event.target.name === 'earlyCareerPay') {
        this.setState({ earlyCareerPay: event.target.value })
      } else if (event.target.name === 'earlyCareerPayGrowth') {
        this.setState({ earlyCareerPayGrowth: event.target.value })
      } else if (event.target.name === 'midCareerPay') {
        this.setState({ midCareerPay: event.target.value })
      } else if (event.target.name === 'midCareerPayGrowth') {
        this.setState({ midCareerPayGrowth: event.target.value })
      } else if (event.target.name === 'lateCareerPay') {
        this.setState({ lateCareerPay: event.target.value })
      } else if (event.target.name === 'lateCareerPayGrowth') {
        this.setState({ lateCareerPayGrowth: event.target.value })
      } else if (event.target.name === 'payVariation') {
        this.setState({ payVariation: event.target.value })
      } else if (event.target.name === 'unemploymentRisk') {
        this.setState({ unemploymentRisk: event.target.value })
      } else if (event.target.name === 'unemploymentPay') {
        this.setState({ unemploymentPay: event.target.value })
      } else if (event.target.name === 'earlyCareerAvgTaxRate') {
        this.setState({ earlyCareerAvgTaxRate: event.target.value })
      } else if (event.target.name === 'earlyCareerPercentOfIncome') {
        this.setState({ earlyCareerPercentOfIncome: event.target.value })
      } else if (event.target.name === 'earlyCareerPaydownRate') {
        this.setState({ earlyCareerPaydownRate: event.target.value })
      } else if (event.target.name === 'earlyCareerInvestmentAllocation') {
        this.setState({ earlyCareerInvestmentAllocation: event.target.value })
      } else if (event.target.name === 'earlyCareerCCSpending') {
        this.setState({ earlyCareerCCSpending: event.target.value })
      } else if (event.target.name === 'earlyCareerSLSpending') {
        this.setState({ earlyCareerSLSpending: event.target.value })
      } else if (event.target.name === 'earlyCareerMGSpending') {
        this.setState({ earlyCareerMGSpending: event.target.value })
      } else if (event.target.name === 'earlyCareerODSpending') {
        this.setState({ earlyCareerODSpending: event.target.value })
      } else if (event.target.name === 'midCareerAvgTaxRate') {
        this.setState({ midCareerAvgTaxRate: event.target.value })
      } else if (event.target.name === 'midCareerPercentOfIncome') {
        this.setState({ midCareerPercentOfIncome: event.target.value })
      } else if (event.target.name === 'midCareerPaydownRate') {
        this.setState({ midCareerPaydownRate: event.target.value })
      } else if (event.target.name === 'midCareerInvestmentAllocation') {
        this.setState({ midCareerInvestmentAllocation: event.target.value })
      } else if (event.target.name === 'midCareerCCSpending') {
        this.setState({ midCareerCCSpending: event.target.value })
      } else if (event.target.name === 'midCareerSLSpending') {
        this.setState({ midCareerSLSpending: event.target.value })
      } else if (event.target.name === 'midCareerMGSpending') {
        this.setState({ midCareerMGSpending: event.target.value })
      } else if (event.target.name === 'midCareerODSpending') {
        this.setState({ midCareerODSpending: event.target.value })
      } else if (event.target.name === 'lateCareerAvgTaxRate') {
        this.setState({ lateCareerAvgTaxRate: event.target.value })
      } else if (event.target.name === 'lateCareerPercentOfIncome') {
        this.setState({ lateCareerPercentOfIncome: event.target.value })
      } else if (event.target.name === 'lateCareerPaydownRate') {
        this.setState({ lateCareerPaydownRate: event.target.value })
      } else if (event.target.name === 'lateCareerInvestmentAllocation') {
        this.setState({ lateCareerInvestmentAllocation: event.target.value })
      } else if (event.target.name === 'lateCareerCCSpending') {
        this.setState({ lateCareerCCSpending: event.target.value })
      } else if (event.target.name === 'lateCareerSLSpending') {
        this.setState({ lateCareerSLSpending: event.target.value })
      } else if (event.target.name === 'lateCareerMGSpending') {
        this.setState({ lateCareerMGSpending: event.target.value })
      } else if (event.target.name === 'lateCareerODSpending') {
        this.setState({ lateCareerODSpending: event.target.value })
      } else if (event.target.name === 'startingCash') {
        this.setState({ startingCash: event.target.value })
      } else if (event.target.name === 'startingInvestments') {
        this.setState({ startingInvestments: event.target.value })
      } else if (event.target.name === 'investmentAvgReturn') {
        this.setState({ investmentAvgReturn: event.target.value })
      } else if (event.target.name === 'realEstateAssets') {
        this.setState({ realEstateAssets: event.target.value })
      } else if (event.target.name === 'realEstateAvgReturn') {
        this.setState({ realEstateAvgReturn: event.target.value })
      } else if (event.target.name === 'otherAssets') {
        this.setState({ otherAssets: event.target.value })
      } else if (event.target.name === 'startingCCDebt') {
        this.setState({ startingCCDebt: event.target.value })
      } else if (event.target.name === 'earlyCareerCCInterestRate') {
        this.setState({ earlyCareerCCInterestRate: event.target.value })
      } else if (event.target.name === 'midCareerCCInterestRate') {
        this.setState({ midCareerCCInterestRate: event.target.value })
      } else if (event.target.name === 'lateCareerCCInterestRate') {
        this.setState({ lateCareerCCInterestRate: event.target.value })
      } else if (event.target.name === 'startingSLDebt') {
        this.setState({ startingSLDebt: event.target.value })
      } else if (event.target.name === 'earlyCareerSLInterestRate') {
        this.setState({ earlyCareerSLInterestRate: event.target.value })
      } else if (event.target.name === 'midCareerSLInterestRate') {
        this.setState({ midCareerSLInterestRate: event.target.value })
      } else if (event.target.name === 'lateCareerSLInterestRate') {
        this.setState({ lateCareerSLInterestRate: event.target.value })
      } else if (event.target.name === 'startingMGDebt') {
        this.setState({ startingMGDebt: event.target.value })
      } else if (event.target.name === 'earlyCareerMGInterestRate') {
        this.setState({ earlyCareerMGInterestRate: event.target.value })
      } else if (event.target.name === 'midCareerMGInterestRate') {
        this.setState({ midCareerMGInterestRate: event.target.value })
      } else if (event.target.name === 'lateCareerMGInterestRate') {
        this.setState({ lateCareerMGInterestRate: event.target.value })
      } else if (event.target.name === 'startingODDebt') {
        this.setState({ startingODDebt: event.target.value })
      } else if (event.target.name === 'earlyCareerODInterestRate') {
        this.setState({ earlyCareerODInterestRate: event.target.value })
      } else if (event.target.name === 'midCareerODInterestRate') {
        this.setState({ midCareerODInterestRate: event.target.value })
      } else if (event.target.name === 'lateCareerODInterestRate') {
        this.setState({ lateCareerODInterestRate: event.target.value })
      } else if (event.target.name === 'entryType') {

        let entryCategories = []
        if (event.target.value === 'Asset') {
          entryCategories = this.state.assetCategories
        } else if (event.target.value === 'Liability') {
          entryCategories = this.state.liabilityCategories
        } else if (event.target.value === 'Income') {
          entryCategories = this.state.incomeCategories
        } else if (event.target.value === 'Expense') {
          entryCategories = this.state.expenseCategories
        }
        this.setState({ entryType: event.target.value, entryCategories })
      } else if (event.target.name === 'entryMode') {
        this.setState({ entryMode: event.target.value })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchCareers(searchString) {
      console.log('searchCareers called', searchString)

      if (!searchString || searchString === '') {
        this.setState({ searchString, animating: true, careerOptions: null })
      } else {
        this.setState({ searchString, animating: true })

        const search = true

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')

          const excludeMissingOutlookData = true
          const excludeMissingJobZone = true

          Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
          .then((response) => {
            console.log('Careers query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved careers')

                if (response.data) {

                  let careerOptions = []
                  if (response.data.careers && response.data.careers.length > 0) {
                    careerOptions = response.data.careers
                  }

                  self.setState({ careerOptions, animating: false })
                }

              } else {
                console.log('no career data found', response.data.message)
                self.setState({ animating: false })
              }

          }).catch((error) => {
              console.log('Career query did not work', error);
              self.setState({ animating: false })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      }
    }

    careerClicked(passedCareer) {
      console.log('careerClicked called', passedCareer)

      const searchObject = passedCareer
      const searchString = passedCareer.name
      const unready = false
      const careerOptions = null

      this.setState({ searchObject, searchString, unready, careerOptions })

    }

    optionClicked() {
      console.log('optionClicked called')

      if (this.state.favoritedCareers.includes(this.state.searchString)) {
        this.setState({ errorMessage: 'You have already added this career' })
      } else {

        const searchString = ''
        const searchObject = null
        const unready = true

        let favoritedCareers = this.state.favoritedCareers
        favoritedCareers.unshift(this.state.searchString)

        let favoritedCareerDetails = this.state.favoritedCareerDetails
        favoritedCareerDetails.unshift(this.state.searchObject)

        // const selectedCareer = this.state.searchString

        this.setState({ searchString, searchObject, unready, favoritedCareers, errorMessage: null })

        // favorite item
        this.favoriteItem(this.state.searchObject)

        // populate chart
        this.pullCareerAssumptions(this.state.searchObject)

      }
    }

    async favoriteItem(item) {
      console.log('favoriteItem called', item)

      this.setState({ errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'career')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites
        if (favoritesArray.includes(itemId)){

          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              this.setState({ successMessage: 'Removed from saved favorites' })
            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites' })
          });

        } else {

          console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({ successMessage: 'Saved as a favorite!' })
            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites'})
          });
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })

      }
    }

    renderTags() {
      console.log('renderTags ')

      const favoritedCareers = this.state.favoritedCareers
      if (favoritedCareers && favoritedCareers.length > 0) {
        return (
          <div key={"favoritedCareers"}>
            <div className="spacer" />
            {favoritedCareers.map((value, optionIndex) =>
              <div key={"career|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <button className="background-button float-left right-padding-5" onClick={() => this.pullCareerAssumptions(this.state.favoritedCareerDetails[optionIndex])}>
                  <div className="half-spacer" />
                  <div className={(this.state.selectedCareer === value) ? "tag-container-active" : "tag-container-inactive"}>
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </button>

              </div>
            )}
          </div>
        )
      }
    }

    removeTag(index) {
      console.log('removeTag called', index)

      let favoritedCareers = this.state.favoritedCareers
      favoritedCareers.splice(index, 1)

      let favoritedCareerDetails = this.state.favoritedCareerDetails
      const careerToRemove = favoritedCareerDetails[index]
      favoritedCareerDetails.splice(index, 1)
      this.setState({ favoritedCareers, favoritedCareerDetails })

      // remove as favorite
      this.favoriteItem(careerToRemove)

    }

    pullCareerAssumptions(passedCareer) {
      console.log('pullCareerAssumptions called', passedCareer)

      const career = passedCareer
      const selectedCareer = career.name

      let marketPay = 100 // due to lack of market data
      if (career.marketData && career.marketData.pay) {
        if (career.marketData.pay.includes("+")) {
          marketPay = Number(career.marketData.pay.split("+")[0])
        } else {
          marketPay = career.marketData.pay
        }
        // console.log('show marketPay: ', marketPay, career.marketData.pay)
      } else if (career.outlookData && career.outlookData.salaryMedian) {
        marketPay = career.outlookData.salaryMedian
      } else {
        console.log('something went very wrong')
      }

      let earlyCareerPay = marketPay
      let midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
      let lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()

      let jobZone = 0
      if (career.educationData && career.educationData.jobZone) {
        jobZone = Number(career.educationData.jobZone)
        if (jobZone === 1) {
          earlyCareerPay = marketPay
          midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
          lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
        } else if (jobZone === 2) {
          earlyCareerPay = earlyCareerPay * 0.8
          midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
          lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
        } else if (jobZone === 3) {
          earlyCareerPay = marketPay * 0.7
          midCareerPay = marketPay
          lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
        } else if (jobZone === 4) {
          earlyCareerPay = marketPay * 0.6
          midCareerPay = marketPay * 0.8
          lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
        } else if (jobZone === 5) {
          console.log('in last job zone')
          earlyCareerPay = marketPay * 0.4
          midCareerPay = marketPay * 0.7
          lateCareerPay = marketPay
        }
      }

      let earlyCareerPayGrowth = this.state.inflation + 1 // one point for experience
      // if (career.payThreeYearsAgo) {
      //   let threeYearGrowthRate = (((Number(marketPay) - Number(career.payThreeYearsAgo)) / Number(career.payThreeYearsAgo) / 3) * 100).toFixed()
      //   earlyCareerPayGrowth = threeYearGrowthRate + 1 // for experience gained
      //   console.log('compare pay: ', marketPay, career.payThreeYearsAgo, threeYearGrowthRate)
      // } else {
      //   console.log('dont have data three years ago')
      //   earlyCareerPayGrowth = this.state.inflation + 1 // for experience gained
      // }
      // console.log('what 1???', earlyCareerPayGrowth, earlyCareerPayGrowth * (5 / 3), (earlyCareerPayGrowth * (5 / 3)).toFixed(), typeof (earlyCareerPayGrowth * (5 / 3)).toFixed())

      let unemploymentRisk = 10

      let marketGrowth = '1% to 2%'
      if (career.marketData && career.marketData.growth) {
        marketGrowth = career.marketData.growth
      }
      if (marketGrowth) {
        if (marketGrowth === '8% or higher') {
          earlyCareerPayGrowth = earlyCareerPayGrowth + 3
          unemploymentRisk = 7
        } else if (marketGrowth === '5% to 7%') {
          earlyCareerPayGrowth = earlyCareerPayGrowth + 2
          unemploymentRisk = 8
        } else if (marketGrowth === '3% to 4%') {
          earlyCareerPayGrowth = earlyCareerPayGrowth + 1
          unemploymentRisk = 9
        } else if (marketGrowth === '1% to 2%') {
          earlyCareerPayGrowth = earlyCareerPayGrowth + 0
          unemploymentRisk = 10
        } else if (marketGrowth === '-1% or lower') {
          earlyCareerPayGrowth = earlyCareerPayGrowth * 0.9
          unemploymentRisk = 1
        } else if (marketGrowth === '10% to 14%') { // old category
          earlyCareerPayGrowth = earlyCareerPayGrowth + 3.5
          unemploymentRisk = 6.5
        } else if (marketGrowth === '15% or higher') { // old category
          earlyCareerPayGrowth = earlyCareerPayGrowth + 4
          unemploymentRisk = 6
        } else {
          earlyCareerPayGrowth = earlyCareerPayGrowth + 0
          unemploymentRisk = 10
        }
      }

      let midCareerPayGrowth = (earlyCareerPayGrowth * (5 / 3)).toFixed()
      let lateCareerPayGrowth = (midCareerPayGrowth * (1 / 5)).toFixed()
      console.log('what 2???', earlyCareerPayGrowth, earlyCareerPayGrowth * (5 / 3), (earlyCareerPayGrowth * (5 / 3)).toFixed(), typeof (earlyCareerPayGrowth * (5 / 3)).toFixed())

      let payVariation = 20
      if (career.outlookData.salaryMedian && career.outlookData.salary10thPercentile && career.outlookData.salary90thPercentile) {
        let diff1 = (career.outlookData.salaryMedian - career.outlookData.salary10thPercentile)
        let diff2 = (career.outlookData.salary90thPercentile - career.outlookData.salaryMedian)
        payVariation = (((diff1 + diff2) / 2) / career.outlookData.salaryMedian * 100).toFixed()
        // halving this for 1 STD
        payVariation = Number(payVariation) / 2
      }

      // if salary is high, lower growth rate
      // if (earlyCareerPay > 100000) {
      //
      // } else if (earlyCareerPay > 200000) {
      //
      // }

      console.log('show the assumptions: ', marketPay, jobZone, earlyCareerPayGrowth, unemploymentRisk, payVariation)
      console.log('pay stages: ', earlyCareerPay, midCareerPay, lateCareerPay)
      console.log('growth stages: ', earlyCareerPayGrowth, midCareerPayGrowth, lateCareerPayGrowth)

      // investment analysts
      // marketPay: 81,590
      // jobZone: 0,
      // earlyCareerPayGrowth: 6
      // unemploymentRisk: 8
      // payVariation: 67

      const startYear = new Date().getFullYear()
      const endYear = new Date().getFullYear() + this.state.earlyCareerLength
      const amount = earlyCareerPay
      const annualGrowthRate = earlyCareerPayGrowth

      this.setState({
        animating: false, selectedCareer, selectedCareerObject: career,
        earlyCareerPay, earlyCareerPayGrowth, midCareerPay, midCareerPayGrowth, lateCareerPay, lateCareerPayGrowth,
        payVariation, entryPayVariation: payVariation, unemploymentRisk,
        startYear, endYear, amount, annualGrowthRate
      }, () => {
          this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)
      });

      // this.setState({ selectedCareer, animating: false,
      //   earlyCareerPay, earlyCareerPayGrowth, midCareerPay, midCareerPayGrowth, lateCareerPay, lateCareerPayGrowth,
      //   payVariation, unemploymentRisk
      // })

      // this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)


      // this.setState({ animating: true, errorMessage: null })
      //
      // Axios.get('/api/careers/detail', { params: { name: selectedCareer }})
      // .then((response) => {
      //   console.log('Career query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('successfully retrieved career')
      //
      //       // chief executives have a negative growth rate, yet salary is increasing
      //       // use job zone to determine what stage of career is viable, and add proper disclosures
      //
      //       const career = response.data.career
      //       console.log('show career: ', career)
      //       let earlyCareerPay = career.marketData.pay
      //       let midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
      //       let lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
      //
      //       if (career.educationData && career.educationData.jobZone) {
      //         const jobZone = Number(career.educationData.jobZone)
      //         if (jobZone === 1) {
      //           earlyCareerPay = career.marketData.pay
      //           midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
      //           lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
      //         } else if (jobZone === 2) {
      //           earlyCareerPay = career.marketData.pay * 0.8
      //           midCareerPay = (earlyCareerPay * (80000 / 45000)).toFixed()
      //           lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
      //         } else if (jobZone === 3) {
      //           earlyCareerPay = career.marketData.pay * 0.7
      //           midCareerPay = career.marketData.pay
      //           lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
      //         } else if (jobZone === 4) {
      //           earlyCareerPay = career.marketData.pay * 0.6
      //           midCareerPay = career.marketData.pay * 0.8
      //           lateCareerPay = (midCareerPay * (110000 / 80000)).toFixed()
      //         } else if (jobZone === 5) {
      //           console.log('in last job zone')
      //           earlyCareerPay = career.marketData.pay * 0.4
      //           midCareerPay = career.marketData.pay * 0.7
      //           lateCareerPay = career.marketData.pay
      //         }
      //       }
      //
      //       let earlyCareerPayGrowth = 0
      //       let midCareerPayGrowth = (earlyCareerPayGrowth * (5 / 3)).toFixed()
      //       let lateCareerPayGrowth = (midCareerPayGrowth * (1 / 5)).toFixed()
      //
      //       let unemploymentRisk = 10
      //       if (career.marketData.growth) {
      //         if (career.marketData.growth === '8% or higher') {
      //           earlyCareerPayGrowth = 10
      //           unemploymentRisk = 7
      //         } else if (career.marketData.growth === '5% to 7%') {
      //           earlyCareerPayGrowth = 6
      //           unemploymentRisk = 8
      //         } else if (career.marketData.growth === '3% to 4%') {
      //           earlyCareerPayGrowth = 3.5
      //           unemploymentRisk = 9
      //         } else if (career.marketData.growth === '1% to 2%') {
      //           earlyCareerPayGrowth = 1.5
      //           unemploymentRisk = 10
      //         } else if (career.marketData.growth === '-1% or lower') {
      //           earlyCareerPayGrowth = -2
      //           unemploymentRisk = 11
      //         } else {
      //           earlyCareerPayGrowth = 0.5
      //           unemploymentRisk = 10
      //         }
      //       }
      //
      //       let payVariation = 20
      //       if (career.outlookData.salaryMedian && career.outlookData.salary10thPercentile && career.outlookData.salary90thPercentile) {
      //         let diff1 = (career.outlookData.salaryMedian - career.outlookData.salary10thPercentile)
      //         let diff2 = (career.outlookData.salary90thPercentile - career.outlookData.salaryMedian)
      //         payVariation = (((diff1 + diff2) / 2) / career.outlookData.salaryMedian * 100).toFixed()
      //       }
      //
      //       this.setState({ selectedCareer, animating: false,
      //         earlyCareerPay, earlyCareerPayGrowth, midCareerPay, midCareerPayGrowth, lateCareerPay, lateCareerPayGrowth,
      //         payVariation, unemploymentRisk
      //       })
      //
      //       this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)
      //
      //     } else {
      //       console.log('no career data found', response.data.message)
      //       this.setState({ errorMessage: 'we could not retrieve career assumptions / details', animating: false })
      //     }
      //
      // }).catch((error) => {
      //     console.log('Career query did not work', error);
      //     this.setState({ errorMessage: 'we could not retrieve career assumptions / details', animating: false })
      // });

    }

    addEntry(passedEntries, passedIndex) {
      console.log('addEntry called: ', passedEntries, passedIndex, this.state.entries)

      this.setState({ errorMessage: null, successMessage: null })

      if (!this.state.entryType || this.state.entryType === '') {
        this.setState({ errorMessage: 'Please add a type / account' })
      } else if (!this.state.entryCategory || this.state.entryCategory === '') {
        this.setState({ errorMessage: 'Please add a category' })
      } else if (!this.state.amount || this.state.amount === '') {
        this.setState({ errorMessage: 'Please add a dollar amount' })
      } else if ((this.state.entryType === 'Asset' || this.state.entryType === 'Liability') && (!this.state.entryName || this.state.entryName === '')) {
        this.setState({ errorMessage: 'Please name your ' + this.state.entryType })
      } else {

        const emailId = this.state.emailId

        let entries = this.state.entries
        let assetEntries = this.state.assetEntries
        let liabilityEntries = this.state.liabilityEntries
        let incomeEntries = this.state.incomeEntries
        let expenseEntries = this.state.expenseEntries

        let careerId = null
        let careerName = null
        if (this.state.entryType === 'Income' && this.state.entryCategory === 'Earned Income' && this.state.selectedCareerObject) {
          careerId = this.state.selectedCareerObject._id
          careerName = this.state.selectedCareerObject.name
        }

        let entry = {}
        let entryTwo = null

        if (this.state.selectedIndex || this.state.selectedIndex === 0) {
          entries[this.state.selectedIndex]['entryType'] = this.state.entryType
          entries[this.state.selectedIndex]['entryCategory'] = this.state.entryCategory
          entries[this.state.selectedIndex]['entrySubCategory'] = this.state.entrySubCategory
          entries[this.state.selectedIndex]['entryName'] = this.state.entryName
          entries[this.state.selectedIndex]['assetEntry'] = this.state.assetEntry
          entries[this.state.selectedIndex]['liabilityEntry'] = this.state.liabilityEntry
          entries[this.state.selectedIndex]['startYear'] = this.state.startYear
          entries[this.state.selectedIndex]['endYear'] = this.state.endYear
          entries[this.state.selectedIndex]['amount'] = this.state.amount
          entries[this.state.selectedIndex]['annualGrowthRate'] = this.state.annualGrowthRate
          entries[this.state.selectedIndex]['entryName'] = this.state.entryName
          entries[this.state.selectedIndex]['subEntryName'] = this.state.subEntryName
          entries[this.state.selectedIndex]['includesLoan'] = this.state.includesLoan
          entries[this.state.selectedIndex]['entryPaydownRate'] = this.state.entryPaydownRate
          entries[this.state.selectedIndex]['entryPerformanceMultiplier'] = this.state.entryPerformanceMultiplier
          entries[this.state.selectedIndex]['entryPayVariation'] = this.state.entryPayVariation
          entries[this.state.selectedIndex]['interestRate'] = this.state.interestRate
          entry = entries[this.state.selectedIndex]
        } else {

          entry = {
            emailId,
            entryType: this.state.entryType,
            entryCategory: this.state.entryCategory,
            entrySubCategory: this.state.entrySubCategory,
            entryName: this.state.entryName,
            assetEntry: this.state.assetEntry,
            liabilityEntry: this.state.liabilityEntry,
            startYear: this.state.startYear,
            endYear: this.state.endYear,
            amount: this.state.amount,
            annualGrowthRate: this.state.annualGrowthRate,
            subEntryName: this.state.subEntryName,
            includesLoan: this.state.includeLoan,
            entryPaydownRate: this.state.entryPaydownRate,
            entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
            entryPayVariation: this.state.entryPayVariation,
            interestRate: this.state.interestRate,
            careerId, careerName,
            createdAt: new Date(), updatedAt: new Date()
          }

          entries.push(entry)
        }

        if (this.state.entryType === 'Asset') {
          // console.log('show entryName: ', this.state.entryType, this.state.subEntryName)
          if (this.state.selectedIndex || this.state.selectedIndex === 0) {
            const index = assetEntries.findIndex(ec => ec.entryName === this.state.entryName)
            assetEntries[index] = entry
          } else {
            assetEntries.push(entry)
            if (this.state.subEntryName) {
              entryTwo = {
                emailId,
                assetEntry: this.state.assetEntry,
                liabilityEntry: this.state.liabilityEntry,
                entryName: this.state.subEntryName,
                entryType: 'Liability',
                superEntryName: this.state.entryName,
                interestRate: this.state.interestRate,
                amount: this.state.loanAmount,
                modelYear: this.state.modelYear,
                milesPerGallon: this.state.milesPerGallon,
                homeType: this.state.homeType,
                entryCategory: this.state.entryCategory,
                entrySubCategory: this.state.entrySubCategory,
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                entryPaydownRate: this.state.entryPaydownRate,
                entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
                entryPayVariation: this.state.entryPayVariation,
                createdAt: new Date(), updatedAt: new Date()
              }

              liabilityEntries.push(entryTwo)
              entries.push(entryTwo)
            }
          }

        } else if (this.state.entryType === 'Liability') {
          if (this.state.selectedIndex || this.state.selectedIndex === 0) {
            const index = liabilityEntries.findIndex(ec => ec.entryName === this.state.entryName)
            liabilityEntries[index] = entry
          } else {
            liabilityEntries.push(entry)
          }
        } else if (this.state.entryType === 'Income') {
          console.log('no selectedIndex:', this.state.selectedIndex)
          if (this.state.selectedIndex || this.state.selectedIndex === 0) {
            console.log('edting entry')
            const index = incomeEntries.findIndex(ec => ec.entryName === this.state.entryName)
            incomeEntries[index] = entry
          } else {
            console.log('adding entry')
            incomeEntries.push(entry)
            if (this.state.assetEntry) {
              entryTwo = {
                emailId,
                assetEntry: this.state.assetEntry,
                liabilityEntry: this.state.liabilityEntry,
                entryName: this.state.subEntryName,
                entryType: 'Asset',
                superEntryName: this.state.entryName,
                interestRate: this.state.interestRate,
                amount: this.state.loanAmount,
                modelYear: this.state.modelYear,
                milesPerGallon: this.state.milesPerGallon,
                homeType: this.state.homeType,
                entryCategory: this.state.entryCategory,
                entrySubCategory: this.state.entrySubCategory,
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                entryPaydownRate: this.state.entryPaydownRate,
                entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
                entryPayVariation: this.state.entryPayVariation,
                createdAt: new Date(), updatedAt: new Date()
              }

              assetEntries.push(entryTwo)
              entries.push(entryTwo)
            } else if (this.state.liabilityEntry) {
              entryTwo = {
                emailId,
                assetEntry: this.state.assetEntry,
                liabilityEntry: this.state.liabilityEntry,
                entryName: this.state.subEntryName,
                entryType: 'Liability',
                superEntryName: this.state.entryName,
                interestRate: this.state.interestRate,
                amount: this.state.loanAmount,
                modelYear: this.state.modelYear,
                milesPerGallon: this.state.milesPerGallon,
                homeType: this.state.homeType,
                entryCategory: this.state.entryCategory,
                entrySubCategory: this.state.entrySubCategory,
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                entryPaydownRate: this.state.entryPaydownRate,
                entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
                entryPayVariation: this.state.entryPayVariation,
                createdAt: new Date(), updatedAt: new Date()
              }

              liabilityEntries.push(entryTwo)
              entries.push(entryTwo)
            }
          }

        } else if (this.state.entryType === 'Expense') {
          if (this.state.selectedIndex || this.state.selectedIndex === 0) {
            const index = expenseEntries.findIndex(ec => ec.entryName === this.state.entryName)
            expenseEntries[index] = entry
          } else {
            expenseEntries.push(entry)
            if (this.state.assetEntry) {
              entryTwo = {
                emailId,
                assetEntry: this.state.assetEntry,
                liabilityEntry: this.state.liabilityEntry,
                entryName: this.state.subEntryName,
                entryType: 'Asset',
                superEntryName: this.state.entryName,
                interestRate: this.state.interestRate,
                amount: this.state.loanAmount,
                modelYear: this.state.modelYear,
                milesPerGallon: this.state.milesPerGallon,
                homeType: this.state.homeType,
                entryCategory: this.state.entryCategory,
                entrySubCategory: this.state.entrySubCategory,
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                entryPaydownRate: this.state.entryPaydownRate,
                entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
                entryPayVariation: this.state.entryPayVariation,
                createdAt: new Date(), updatedAt: new Date()
              }

              assetEntries.push(entryTwo)
              entries.push(entryTwo)
            } else if (this.state.liabilityEntry) {
              entryTwo = {
                emailId,
                assetEntry: this.state.assetEntry,
                liabilityEntry: this.state.liabilityEntry,
                entryName: this.state.subEntryName,
                entryType: 'Liability',
                superEntryName: this.state.entryName,
                interestRate: this.state.interestRate,
                amount: this.state.loanAmount,
                modelYear: this.state.modelYear,
                milesPerGallon: this.state.milesPerGallon,
                homeType: this.state.homeType,
                entryCategory: this.state.entryCategory,
                entrySubCategory: this.state.entrySubCategory,
                startYear: this.state.startYear,
                endYear: this.state.endYear,
                entryPaydownRate: this.state.entryPaydownRate,
                entryPerformanceMultiplier: this.state.entryPerformanceMultiplier,
                entryPayVariation: this.state.entryPayVariation,
                createdAt: new Date(), updatedAt: new Date()
              }

              liabilityEntries.push(entryTwo)
              entries.push(entryTwo)
            }
          }
        }

        let entryType = ''
        let entryCategory = ''
        let entrySubCategory = ''
        let entryName = ''
        let subEntryName: ''
        let assetEntry = null
        let liabilityEntry = null
        let startYear = ''
        let endYear = ''
        let amount = ''
        let annualGrowthRate = ''

        let includesLoan = ''
        let interestRate = ''
        let loanAmount = ''
        let modelYear = ''
        let milesPerGallon = ''
        let homeType = ''

        let entryPaydownRate = ''
        let entryPerformanceMultiplier = ''
        let entryPayVariation = ''

        if (this.state.emailId) {

          let entriesToSave = [entry]
          if (entryTwo) {
            entriesToSave.push(entryTwo)
          }

          let saveObject = { emailId: this.state.emailId, entriesToSave }
          if (this.state.selectedIndex || this.state.selectedIndex === 0) {
            saveObject = entry
          }
          console.log('show saveObject: ', saveObject)

          Axios.post('/api/financials/entries', saveObject)
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)

              if (this.state.selectedIndex || this.state.selectedIndex === 0) {
                this.setState({ entries, assetEntries, liabilityEntries, incomeEntries, expenseEntries,
                  successMessage: 'Successfully edited entry!'
                });

                this.closeModal(true)

              } else {
                this.setState({ entries,
                  entryType, entryCategory, entrySubCategory, assetEntry, liabilityEntry, startYear, endYear, amount, annualGrowthRate,
                  entryName, subEntryName, includesLoan, interestRate, loanAmount, modelYear, milesPerGallon, homeType,
                  entryPaydownRate, entryPerformanceMultiplier, entryPayVariation,
                  assetEntries, liabilityEntries, incomeEntries, expenseEntries,
                  successMessage: 'Successfully added entry!'
                });

                this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)
              }

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites' })
          });

        } else {

          this.setState({ entries,
            entryType, entryCategory, entrySubCategory, assetEntry, liabilityEntry, startYear, endYear, amount, annualGrowthRate,
            entryName, subEntryName, includesLoan, interestRate, loanAmount, modelYear, milesPerGallon, homeType,
            entryPaydownRate, entryPerformanceMultiplier, entryPayVariation,
            assetEntries, liabilityEntries, incomeEntries, expenseEntries,
            successMessage: 'Successfully added entry!'
          });

          this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)
        }
      }
    }

    beginEditingEntry(item, index) {
      console.log('beginEditingEntry called', item, index)

      const entryType = item.entryType
      const entryCategory = item.entryCategory
      const entrySubCategory = item.entrySubCategory
      const assetEntry = item.assetEntry
      const liabilityEntry = item.liabilityEntry
      const startYear = item.startYear
      const endYear = item.endYear
      const amount = item.amount
      const annualGrowthRate = item.annualGrowthRate
      const entryName = item.entryName
      const subEntryName = item.subEntryName
      const includesLoan = item.includesLoan
      const interestRate = item.interestRate
      const loanAmount = item.loanAmount
      const modelYear = item.modelYear
      const milesPerGallon = item.milesPerGallon
      const homeType = item.homeType
      const entryPaydownRate = item.entryPaydownRate
      const entryPerformanceMultiplier = item.entryPerformanceMultiplier
      const entryPayVariation = item.entryPayVariation

      let entryCategories = []
      if (item.entryType === 'Asset') {
        entryCategories = this.state.assetCategories
      } else if (item.entryType === 'Liability') {
        entryCategories = this.state.liabilityCategories
      } else if (item.entryType === 'Income') {
        entryCategories = this.state.incomeCategories
      } else if (item.entryType === 'Expense') {
        entryCategories = this.state.expenseCategories
      }

      this.setState({
        entryType, entryCategory, entrySubCategory, assetEntry, liabilityEntry, startYear, endYear, amount, annualGrowthRate,
        entryName, subEntryName, includesLoan, interestRate, loanAmount, modelYear, milesPerGallon, homeType,
        entryPaydownRate, entryPerformanceMultiplier, entryPayVariation, entryCategories,
        modalIsOpen: true, editSection: 'Custom Entries Mode', showEditInfo: true, selectedIndex: index, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false
      })
    }

    deleteEntry() {
      console.log('deleteEntry called')

      let entries = this.state.entries
      const selectedEntry = this.state.entries[this.state.selectedIndex]
      entries.splice(this.state.selectedIndex,1)

      let assetEntries = this.state.assetEntries
      let liabilityEntries = this.state.liabilityEntries
      let incomeEntries = this.state.incomeEntries
      let expenseEntries = this.state.expenseEntries

      if (selectedEntry.entryType === 'Asset') {
        const index = assetEntries.findIndex(ec => ec.entryName === selectedEntry.entryName)
        assetEntries.splice(index, 1)
      } else if (selectedEntry.entryType === 'Liability') {
        const index = liabilityEntries.findIndex(ec => ec.entryName === selectedEntry.entryName)
        liabilityEntries.splice(index, 1)
      } else if (selectedEntry.entryType === 'Income') {
        const index = incomeEntries.findIndex(ec => ec.entryName === selectedEntry.entryName)
        incomeEntries.splice(index, 1)
      } else if (selectedEntry.entryType === 'Expense') {
        const index = expenseEntries.findIndex(ec => ec.entryName === selectedEntry.entryName)
        expenseEntries.splice(index, 1)
      }

      let entryType = ''
      let entryCategory = ''
      let entrySubCategory = ''
      let entryName = ''
      let subEntryName: ''
      let assetEntry = null
      let liabilityEntry = null
      let startYear = ''
      let endYear = ''
      let amount = ''
      let annualGrowthRate = ''

      let includesLoan = ''
      let interestRate = ''
      let loanAmount = ''
      let modelYear = ''
      let milesPerGallon = ''
      let homeType = ''

      let entryPaydownRate = ''
      let entryPerformanceMultiplier = ''
      let entryPayVariation = ''

      if (this.state.emailId) {
        Axios.delete('/api/financials/entries/' + selectedEntry._id,)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Delete worked');

            this.setState({
              entries, assetEntries, liabilityEntries, incomeEntries, expenseEntries, selectedIndex: null,
              entryType, entryCategory, entrySubCategory, assetEntry, liabilityEntry, startYear, endYear, amount, annualGrowthRate,
              entryName, subEntryName, includesLoan, interestRate, loanAmount, modelYear, milesPerGallon, homeType,
              entryPaydownRate, entryPerformanceMultiplier, entryPayVariation,
            })

            this.closeModal(true)

          } else {
            console.error('there was an error deleting the entry');
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: 'there was an error deleting' })
        });
      } else {
        this.setState({
          entries, assetEntries, liabilityEntries, incomeEntries, expenseEntries, selectedIndex: null,
          entryType, entryCategory, entrySubCategory, assetEntry, liabilityEntry, startYear, endYear, amount, annualGrowthRate,
          entryName, subEntryName, includesLoan, interestRate, loanAmount, modelYear, milesPerGallon, homeType,
          entryPaydownRate, entryPerformanceMultiplier, entryPayVariation,
        })

        this.closeModal(true)
      }
    }

    closeModal(configureChart) {

      if (configureChart) {

        this.setState({ modalIsOpen: false, showEditInfo: false, showSnapshotInfo: false,
          showPersonalInfo: false, showFinancialAssumptionsInfo: false, showCustomEntries: false,
          enlargedChart: false, showConfirmExport: false, showFile: false
        });
        this.configureChartData(this.state.chartType, this.state.scenario, this.state.duration)
      } else {
        this.setState({ modalIsOpen: false, showEditInfo: false, showSnapshotInfo: false,
          showPersonalInfo: false, showFinancialAssumptionsInfo: false, showCustomEntries: false,
          enlargedChart: false, showConfirmExport: false, showFile: false
        });
      }

      if (this.props.closeModal) {
        this.props.closeModal()
      }
    }

    renderCareerSelectModule(inModal) {
      console.log('renderCareerSelectModule called', inModal)

      return (
        <div key="careerSelect">
          <div className={(!inModal) && "container-left"}>
            <div className="half-spacer" />
            <div className="calc-column-offset-70">
              <input type="text" className="text-field" placeholder="Search 1,000+ career paths..." name="searchCareers" value={this.state.searchString} onChange={this.formChangeHandler}></input>
            </div>
            <div className="fixed-column-70 left-padding">
              <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.optionClicked()}>Add</button>
            </div>
            <div className="clear" />
          </div>
          <div className="clear" />

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

          {(this.state.animating) ? (
            <div className="flex-container flex-center full-space">
              <div>
                <div className="super-spacer" />

                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <p className="center-text cta-color bold-text">Calculating results...</p>

              </div>
            </div>
          ) : (
            <div>
              <div>
                {(this.state.careerOptions) && (
                  <div className="card top-margin">
                    {this.state.careerOptions.map((value, optionIndex) =>
                      <div key={value._id} className="left-text bottom-margin-5 full-width">
                        <button className="background-button full-width row-5 left-text" onClick={() => this.careerClicked(value)}>
                          <div className="full-width">
                            <div className="fixed-column-40">
                              <div className="mini-spacer" />
                              <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                            </div>
                            <div className="calc-column-offset-40">
                              <p className="cta-color">{value.name}</p>
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div>
                {this.renderTags()}
                <div className="clear" />
              </div>

            </div>
          )}
        </div>
      )
    }


    renderPersonalInfo(isEditing) {
      console.log('renderPersonalInfo called', isEditing)

      return (
        <div key="personalInfo" className="row-10">
          <div className="row-10">
            <div className="row-10">
              <div className="description-text-2">
                <div className="container-left row-5">
                  <p>Target Occupation</p>
                </div>
                <div className="container-right row-5">
                  {(isEditing) ? (
                    <div>
                      {this.renderCareerSelectModule(true)}
                    </div>
                  ) : (
                    <p className="full-width right-text">{this.state.selectedCareer}</p>
                  )}

                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Career Performance Assumption</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <select name="careerPerformance" className="dropdown" value={this.state.careerPerformance} onChange={this.formChangeHandler}>
                        {['High','Average','Low'].map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  ) : (
                    <p className="full-width right-text">{this.state.careerPerformance}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Career Stage</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <select name="careerStage" className="dropdown" value={this.state.careerStage} onChange={this.formChangeHandler}>
                        {['Early','Mid','Late'].map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  ) : (
                    <p className="full-width right-text">{this.state.careerStage}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Zip Code (Region)</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <input type="number" className="number-field cta-color height-30" placeholder="0" name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler}></input>
                  ) : (
                    <p className="full-width right-text">{this.state.zipCode}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Lifestyle</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <select name="lifestyle" className="dropdown" value={this.state.lifestyle} onChange={this.formChangeHandler}>
                        {['Modest','Average','Luxury'].map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  ) : (
                    <p className="full-width right-text">{this.state.lifestyle}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Birth Date</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <input type="date" className="date-field cta-color height-30" placeholder="0" name="birthdate" value={this.state.birthdate} onChange={this.formChangeHandler}></input>
                  ) : (
                    <p className="full-width right-text">{this.state.birthdate}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Retirement Year (65 Years Old)</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <input type="number" className="number-field cta-color height-30" placeholder="0" name="retirementYear" value={this.state.retirementYear} onChange={this.formChangeHandler}></input>
                  ) : (
                    <p className="full-width right-text">{this.state.retirementYear}</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2">
                <div className="container-left-static row-5">
                  <p>Dependents (e.g., Children)</p>
                </div>
                <div className="container-right-static row-5">
                  {(isEditing) ? (
                    <input type="number" className="number-field cta-color height-30" placeholder="0" name="dependentCount" value={this.state.dependentCount} onChange={this.formChangeHandler}></input>
                  ) : (
                    <p className="full-width right-text">{this.state.dependentCount}</p>
                  )}
                </div>
                <div className="clear" />
              </div>
            </div>
          </div>

          {(isEditing) && (
            <hr />
          )}
        </div>
      )
    }

    renderFinancialAssumptions(isEditing) {
      console.log('renderFinancialAssumptions called', isEditing)

      return (
        <div key="financialAssumptions">
          <div className="row-20">
            <p className="heading-text-3">Net Income</p>
          </div>

          <div className="row-10">
            <p className="heading-text-5">Income</p>

            <div className="row-10">
              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Annual Pay</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerPay" value={this.state.earlyCareerPay} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.earlyCareerPay).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Growth Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerPayGrowth" value={Number(this.state.earlyCareerPayGrowth)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerPayGrowth).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Annual Pay</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerPay" value={this.state.midCareerPay} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${this.state.midCareerPay.toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Growth Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerPayGrowth" value={Number(this.state.midCareerPayGrowth)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerPayGrowth).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Pay</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerPay" value={this.state.lateCareerPay} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${this.state.lateCareerPay.toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Growth Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerPayGrowth" value={Number(this.state.lateCareerPayGrowth)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerPayGrowth).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Avg Variation in Pay</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="payVariation" value={Number(this.state.payVariation)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.payVariation).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Unemployment Risk</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="unemploymentRisk" value={Number(this.state.unemploymentRisk)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.unemploymentRisk).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Unemployment Annual Pay</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="unemploymentPay" value={this.state.unemploymentPay} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${this.state.unemploymentPay.toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>

          </div>

          <div className="row-10">
            <div>
              <div className="calc-column-offset-60">
                <p className="heading-text-5">Expenses</p>
              </div>
              {/*
              {(isEditing) && (
                <div className="fixed-column-60">
                  <Switch
                    onChange={(change) => this.setState({ flatExpenses: change, formHasChanged: true })}
                    checked={this.state.flatExpenses}
                    id="normal-switch"
                  />
                </div>
              )}*/}

              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Avg Tax Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerAvgTaxRate" value={Number(this.state.earlyCareerAvgTaxRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerAvgTaxRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Spending as % of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerPercentOfIncome" value={Number(this.state.earlyCareerPercentOfIncome)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerPercentOfIncome).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Principal Pay Down Rate (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerPaydownRate" value={Number(this.state.earlyCareerPaydownRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerPaydownRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
                {/*
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career Income Placed in Investments (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerInvestmentAllocation" value={Number(this.state.earlyCareerInvestmentAllocation)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerInvestmentAllocation).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>*/}

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career New Credit Card (CC) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerCCSpending" value={Number(this.state.earlyCareerCCSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerCCSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career New Student Loan (SL) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerSLSpending" value={Number(this.state.earlyCareerSLSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerSLSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career New Mortgage (MG) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerMGSpending" value={Number(this.state.earlyCareerMGSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerMGSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career New Other Debt (OD) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerODSpending" value={Number(this.state.earlyCareerODSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerODSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Avg Tax Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerAvgTaxRate" value={Number(this.state.midCareerAvgTaxRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerAvgTaxRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Spending (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerPercentOfIncome" value={Number(this.state.midCareerPercentOfIncome)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerPercentOfIncome).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Principal Pay Down Rate (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerPaydownRate" value={Number(this.state.midCareerPaydownRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerPaydownRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {/*
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career Income Placed in Investments (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerInvestmentAllocation" value={Number(this.state.midCareerInvestmentAllocation)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerInvestmentAllocation).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>*/}

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career New Credit Card (CC) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerCCSpending" value={Number(this.state.midCareerCCSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerCCSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career New Student Loan (SL) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerSLSpending" value={Number(this.state.midCareerSLSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerSLSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career New Mortgage (MG) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerMGSpending" value={Number(this.state.midCareerMGSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerMGSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career New Other Debt (OD) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerODSpending" value={Number(this.state.midCareerODSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerODSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Avg Tax Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerAvgTaxRate" value={Number(this.state.lateCareerAvgTaxRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerAvgTaxRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Spending (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerPercentOfIncome" value={Number(this.state.lateCareerPercentOfIncome)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerPercentOfIncome).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Principal Pay Down Rate (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerPaydownRate" value={Number(this.state.lateCareerPaydownRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerPaydownRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
                {/*
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career Income Placed in Investments (% of Income)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerInvestmentAllocation" value={Number(this.state.lateCareerInvestmentAllocation)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerInvestmentAllocation).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>*/}

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career New Credit Card (CC) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerCCSpending" value={Number(this.state.lateCareerCCSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerCCSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career New Student Loan (SL) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerSLSpending" value={Number(this.state.lateCareerSLSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerSLSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career New Mortgage (MG) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerMGSpending" value={Number(this.state.lateCareerMGSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerMGSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career New Other Debt (OD) Debt (% of Spending)</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerODSpending" value={Number(this.state.earlyCareerODSpending)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerODSpending).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

            </div>
          </div>

          <hr />

          <div className="row-20">
            <p className="heading-text-3">Net Worth</p>
          </div>

          <div className="row-10">
            <p className="heading-text-5">Assets</p>

            <div className="row-10">
              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Cash</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingCash" value={this.state.startingCash} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingCash).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Investments</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingInvestments" value={this.state.startingInvestments} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingInvestments).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Avg Investment Annual Return</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="investmentAvgReturn" value={Number(this.state.investmentAvgReturn)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.investmentAvgReturn).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Real Estate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="realEstateAssets" value={this.state.realEstateAssets} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.realEstateAssets).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Real Estate Annual Return</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="realEstateAvgReturn" value={Number(this.state.realEstateAvgReturn)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.realEstateAvgReturn).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Other Assets</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="otherAssets" value={this.state.otherAssets} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.otherAssets).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>

          <div className="row-10">
            <p className="heading-text-5">Liabilities</p>

            <div className="row-10">
              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Credit Card (CC) Debt</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingCCDebt" value={this.state.startingCCDebt} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingCCDebt).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career CC Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerCCInterestRate" value={Number(this.state.earlyCareerCCInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerCCInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career CC Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerCCInterestRate" value={Number(this.state.midCareerCCInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerCCInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career CC Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerCCInterestRate" value={Number(this.state.lateCareerCCInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerCCInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Student Loan (SL) Debt</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingSLDebt" value={this.state.startingSLDebt} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingSLDebt).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career SL Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerSLInterestRate" value={Number(this.state.earlyCareerSLInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerSLInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career SL Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerSLInterestRate" value={Number(this.state.midCareerSLInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerSLInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career SL Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerSLInterestRate" value={Number(this.state.lateCareerSLInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerSLInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Mortgage (MG) Debt</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingMGDebt" value={this.state.startingMGDebt} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingMGDebt).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career MG Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerMGInterestRate" value={Number(this.state.earlyCareerMGInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerMGInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career MG Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerMGInterestRate" value={Number(this.state.midCareerMGInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerMGInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career MG Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerMGInterestRate" value={Number(this.state.lateCareerMGInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerMGInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="row-10">
                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Starting Other Debt (OD) Debt</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="width-85 float-right">
                          <input type="number" className="number-field cta-color float-right right-text height-30" placeholder="0" name="startingODDebt" value={this.state.startingODDebt} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>

                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">${Number(this.state.startingODDebt).toLocaleString()}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Early-Career OD Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerODInterestRate" value={Number(this.state.earlyCareerODInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.earlyCareerODInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Mid-Career OD Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerODInterestRate" value={Number(this.state.midCareerODInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.midCareerODInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                <div className="description-text-2">
                  <div className="container-left-static row-5">
                    <p>Late-Career OD Interest Rate</p>
                  </div>
                  <div className="container-right-static row-5">
                    {isEditing ? (
                      <div>
                        <div className="float-right standard-border light-background height-30 width-22 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">%</p>
                        </div>
                        <div className="width-85 float-right">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerODInterestRate" value={Number(this.state.lateCareerODInterestRate)} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <p className="full-width right-text">{Number(this.state.lateCareerODInterestRate).toFixed()}%</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>

          {(isEditing) && (
            <div>
              <hr />

              <div className="row-20">
                <p className="heading-text-3">General Assumptions</p>
              </div>

              <div className="description-text-2 bottom-padding">
                <div className="container-left-static row-5">
                  <p>Early-Career Length (Years)</p>
                </div>
                <div>
                  <div className="float-right standard-border light-background height-30 width-40 center-text">
                    <div className="half-spacer"/>
                    <p className="cta-color bold-text full-width">Yrs</p>
                  </div>
                  <div className="width-67 float-right">
                    <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="earlyCareerLength" value={this.state.earlyCareerLength} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2 bottom-padding">
                <div className="container-left-static row-5">
                  <p>Mid-Career Length (Years)</p>
                </div>
                <div>
                  <div className="float-right standard-border light-background height-30 width-40 center-text">
                    <div className="half-spacer"/>
                    <p className="cta-color bold-text full-width">Yrs</p>
                  </div>
                  <div className="width-67 float-right">
                    <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="midCareerLength" value={this.state.midCareerLength} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2 bottom-padding">
                <div className="container-left-static row-5">
                  <p>Late-Career Length (Years)</p>
                </div>
                <div>
                  <div className="float-right standard-border light-background height-30 width-40 center-text">
                    <div className="half-spacer"/>
                    <p className="cta-color bold-text full-width">Yrs</p>
                  </div>
                  <div className="width-67 float-right">
                    <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="lateCareerLength" value={this.state.lateCareerLength} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>

              <div className="description-text-2 bottom-padding">
                <div className="container-left-static row-5">
                  <p>Retirement-Career Length (Years)</p>
                </div>
                <div>
                  <div className="float-right standard-border light-background height-30 width-40 center-text">
                    <div className="half-spacer"/>
                    <p className="cta-color bold-text full-width">Yrs</p>
                  </div>
                  <div className="width-67 float-right">
                    <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-30" placeholder="0" name="retirementCareerLength" value={this.state.retirementCareerLength} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>

              <div className="spacer" />

              <hr />
            </div>
          )}
        </div>
      )
    }

    renderCustomEntries(isEditing) {
      console.log('renderCustomEntries called', isEditing)

      return (
        <div key="customEntries">

          {(isEditing) ? (
            <div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Entry Account<label className="error-color bold-text">*</label></label>
                  <select name="entryType" className="dropdown" value={this.state.entryType} onChange={this.formChangeHandler}>
                      {this.state.entryTypes.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Category<label className="error-color bold-text">*</label></label>
                  <select name="entryCategory" className="dropdown" value={this.state.entryCategory} onChange={this.formChangeHandler}>
                      {this.state.entryCategories.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                  </select>
                </div>
                {(this.state.entryCategory && this.state.entryCategory !== '' && this.state.entryType === 'Income') && (
                  <div>
                    {(this.state.entryCategory === 'Earned Income') ? (
                      <div className="container-right">
                        <label className="profile-label">Closest Career Path<label className="error-color bold-text">*</label></label>
                        {this.renderCareerSelectModule(true)}
                      </div>
                    ) : (
                      <div className="container-right">
                        <label className="profile-label">Attach an Asset Entry<label className="error-color bold-text">*</label></label>
                        <select name="assetEntry" className="dropdown" value={this.state.assetEntry} onChange={this.formChangeHandler}>
                            {[{ entryName: '' }].concat(this.state.assetEntries).map(value => <option key={value.entryName} value={value.entryName}>{value.entryName}</option>)}
                        </select>
                      </div>
                    )}
                  </div>
                )}
                {(this.state.entryCategory && this.state.entryCategory !== '' && this.state.entryType === 'Expense' && this.state.entryCategories[this.state.entryCategories.findIndex(ec => ec.name === this.state.entryCategory)]) && (
                  <div className="container-right">
                    <label className="profile-label">Subcategory<label className="error-color bold-text">*</label></label>
                    <select name="entrySubCategory" className="dropdown" value={this.state.entrySubCategory} onChange={this.formChangeHandler}>
                        {this.state.entryCategories[this.state.entryCategories.findIndex(ec => ec.name === this.state.entryCategory)].subcategories.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>
                )}
                {(this.state.entryCategory && this.state.entryCategory !== '' && (this.state.entryType === 'Asset' || this.state.entryType === 'Liability')) && (
                  <div className="container-right">
                    <label className="profile-label">Name<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder={"Name this " + this.state.entryType + "..."} name="entryName" value={this.state.entryName} onChange={this.formChangeHandler}></input>
                  </div>
                )}
                <div className="clear" />
              </div>

              {(this.state.entryType === 'Income' && this.state.entryCategory === 'Earned Income') && (
                <div>
                  <div className="container-left">
                    <label className="profile-label">Performance Multiplier<label className="error-color bold-text">*</label></label>
                    <div>
                      <div className="calc-column-offset-30">
                        <input type="number" min="-100" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="entryPerformanceMultiplier" value={this.state.entryPerformanceMultiplier} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-30 standard-border light-background height-40 center-text">
                        <div className="mini-spacer"/><div className="mini-spacer"/>
                        <p className="heading-text-5 cta-color bold-text full-width">%</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Pay Variation<label className="error-color bold-text">*</label></label>
                    <div>
                      <div className="calc-column-offset-30">
                        <input type="number" min="-100" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="entryPayVariation" value={this.state.entryPayVariation} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-30 standard-border light-background height-40 center-text">
                        <div className="mini-spacer"/><div className="mini-spacer"/>
                        <p className="heading-text-5 cta-color bold-text full-width">%</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              )}

              {(this.state.entryCategory && this.state.entryCategory !== '' && this.state.entryCategories[this.state.entryCategories.findIndex(ec => ec.name === this.state.entryCategory)] && this.state.entryCategories[this.state.entryCategories.findIndex(ec => ec.name === this.state.entryCategory)].object) && (
                <div className="row-10">
                  <label className="profile-label">Select an {this.state.entryCategories[this.state.entryCategories.findIndex(ec => ec.name === this.state.entryCategory)].object.type}<label className="error-color bold-text">*</label></label>
                  <select name="assetEntry" className="dropdown" value={this.state.assetEntry} onChange={this.formChangeHandler}>
                      {[{ entryName: ''}].concat(this.state.assetEntries).map(value => <option key={value.entryName} value={value.entryName}>{value.entryName}</option>)}
                  </select>
                </div>
              )}

              {(this.state.entryCategory && this.state.entryCategory !== '' && this.state.entryType === 'Asset') && (
                <div>
                  {(this.state.entryCategory === 'Home') && (
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Home Type<label className="error-color bold-text">*</label></label>
                        <select name="homeType" className="dropdown" value={this.state.homeType} onChange={this.formChangeHandler}>
                            {this.state.homeTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>
                  )}

                  {(this.state.entryCategory === 'Car' || this.state.entryCategory === 'Home') && (
                    <div>
                      {(this.state.entryCategory === 'Car') && (
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Model Year<label className="error-color bold-text">*</label></label>
                            <select name="modelYear" className="dropdown" value={this.state.modelYear} onChange={this.formChangeHandler}>
                                {this.state.modelYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Miles Per Gallon<label className="error-color bold-text">*</label></label>
                            <select name="milesPerGallon" className="dropdown" value={this.state.milesPerGallon} onChange={this.formChangeHandler}>
                                {this.state.milesPerGallonOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      {(this.state.entryCategory === 'Home' && this.state.homeType === 'Bought') && (
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Did You Get {(this.state.entryCategory === 'Car') ? "an Auto Loan?" : "a Mortgage?"}<label className="error-color bold-text">*</label></label>
                            <select name="includesLoan" className="dropdown" value={this.state.includesLoan} onChange={this.formChangeHandler}>
                                {['','Yes','No'].map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Select the {(this.state.entryCategory === 'Car') ? "Loan" : "Mortgage"}<label className="error-color bold-text">*</label></label>
                            <select name="liabilityEntry" className="dropdown" value={this.state.liabilityEntry} onChange={this.formChangeHandler}>
                              {[{ name: ''}].concat(this.state.liabilityEntries,[{ name: 'Add a Loan' }]).map(value => <option key={value.entryName} value={value.entryName}>{value.entryName}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>
                  )}
                </div>
              )}

              {(this.state.liabilityEntry && this.state.liabilityEntry === 'Add a Loan') && (
                <div>
                  <div className="spacer" />

                  <hr />

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Loan Name<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Add a unique name..." name="subEntryName" value={this.state.subEntryName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Total Loan Amount<label className="error-color bold-text">*</label></label>
                      <div>
                        <div className="fixed-column-30 standard-border light-background height-40 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-5 cta-color bold-text full-width">$</p>
                        </div>
                        <div className="calc-column-offset-30">
                          <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="loanAmount" value={this.state.loanAmount} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="clear" />
                      </div>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Annual Interest Rate<label className="error-color bold-text">*</label></label>
                      <div>
                        <div className="calc-column-offset-30">
                          <input type="number" min="-100" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="interestRate" value={this.state.interestRate} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="fixed-column-30 standard-border light-background height-40 center-text">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-5 cta-color bold-text full-width">%</p>
                        </div>

                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>

                  <hr />

                  <div className="spacer" />
                </div>
              )}

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Start Year<label className="error-color bold-text">*</label></label>
                  <select name="startYear" className="dropdown" value={this.state.startYear} onChange={this.formChangeHandler}>
                      {this.state.yearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                {(this.state.entryType === 'Income' || this.state.entryType === 'Expense') && (
                  <div className="container-right">
                    <label className="profile-label">End Year<label className="error-color bold-text">*</label></label>
                    <select name="endYear" className="dropdown" value={this.state.endYear} onChange={this.formChangeHandler}>
                        {this.state.yearOptions.concat(['In Perpetuity']).map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>
                )}

                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Dollar Amount<label className="error-color bold-text">*</label></label>
                  <div>
                    <div className="fixed-column-30 standard-border light-background height-40 center-text">
                      <div className="mini-spacer"/><div className="mini-spacer"/>
                      <p className="heading-text-5 cta-color bold-text full-width">$</p>
                    </div>
                    <div className="calc-column-offset-30">
                      <input type="number" min="0" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="amount" value={this.state.amount} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />
                  </div>
                </div>

                <div className="container-right">
                  <label className="profile-label">Annual {(this.state.entryType === 'Liability') ? "Interest Rate" : "Growth Rate"}<label className="error-color bold-text">*</label></label>
                  <div>
                    <div className="calc-column-offset-30">
                      <input type="number" min="-100" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name={(this.state.entryType === 'Liability') ? "interestRate" : "annualGrowthRate"} value={(this.state.entryType === 'Liability') ? this.state.interestRate : this.state.annualGrowthRate} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-30 standard-border light-background height-40 center-text">
                      <div className="mini-spacer"/><div className="mini-spacer"/>
                      <p className="heading-text-5 cta-color bold-text full-width">%</p>
                    </div>

                    <div className="clear" />
                  </div>
                </div>
                <div className="clear" />
              </div>

              {(this.state.entryType === 'Liability') && (
                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Paydown Rate (% of Income)<label className="error-color bold-text">*</label></label>
                    <div>
                      <div className="calc-column-offset-30">
                        <input type="number" min="-100" max="100" className="number-field cta-color float-right right-text height-40" placeholder="0" name="entryPaydownRate" value={this.state.entryPaydownRate} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-30 standard-border light-background height-40 center-text">
                        <div className="mini-spacer"/><div className="mini-spacer"/>
                        <p className="heading-text-5 cta-color bold-text full-width">%</p>
                      </div>

                      <div className="clear" />
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="spacer" />
              {this.state.entries.map((value, optionIndex) =>
                <div key={"entry|" + optionIndex}>
                  <button className="background-button full-width left-text" onClick={() => this.beginEditingEntry(value, optionIndex)}>
                    <div className="row-5">
                      <div className="fixed-column-20 description-text-2 top-padding-5">
                        <p>{optionIndex + 1}.</p>
                      </div>
                      <div className="calc-column-offset-100-static">
                        <div>
                          <div className="float-left">
                            <p className="bold-text heading-text-6 cta-color clear-padding">${Number(value.amount).toLocaleString()}</p>
                          </div>
                          <div className="float-left left-padding top-padding-5">
                            {(value.entryType === 'Income') && (
                              <p className="cta-border circle-radius description-text-3 cta-color width-20 height-20 center-text bold-text">I</p>
                            )}
                            {(value.entryType === 'Expense') && (
                              <p className="cta-border circle-radius description-text-3 cta-color width-20 height-20 center-text bold-text">E</p>
                            )}
                            {(value.entryType === 'Asset') && (
                              <p className="cta-border circle-radius description-text-3 cta-color width-20 height-20 center-text bold-text">A</p>
                            )}
                            {(value.entryType === 'Liability') && (
                              <p className="cta-border circle-radius description-text-3 cta-color width-20 height-20 center-text bold-text">L</p>
                            )}
                          </div>
                          <div className="clear" />

                        </div>

                        {(value.entryName) && (
                          <label className="description-text-3 clear-padding">{value.entryName} | </label>
                        )}
                        <label className="description-text-3 clear-padding">{value.entryType} | {value.entryCategory} </label>
                        {(value.entrySubCategory) && (
                          <label className="description-text-3 clear-padding">| {value.entrySubCategory}</label>
                        )}
                      </div>
                      <div className="fixed-column-80 description-text-3">
                        {(value.endYear) ? (
                          <p className="full-width right-text bold-text">{value.startYear} - {value.endYear}</p>
                        ) : (
                          <p className="full-width right-text bold-text">{value.startYear}</p>
                        )}
                        <p className="full-width right-text">{value.annualGrowthRate}% Y/Y</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </button>
                </div>
              )}
            </div>
          )}

        </div>
      )
    }

    renderChart() {
      console.log('renderChart called')

      return (
        <div key="chart">
          <div className="row-10">
            <div className="flex-container">
              <div className="flex-20">
                <div className="description-text-2">
                  <button className={(this.state.chartType === 'Income') ? "background-button padding-10 cta-border cta-color bold-text clear-margin" : "background-button padding-10 standard-border"} onClick={() => this.configureChartData('Income', this.state.scenario, this.state.duration)}><div>Income</div></button>
                  <button className={(this.state.chartType === 'Assets') ? "background-button padding-10 cta-border cta-color bold-text clear-margin" : "background-button padding-10 clear-margin standard-border"} onClick={() => this.configureChartData('Assets', this.state.scenario, this.state.duration)}><div>Assets</div></button>
                </div>
              </div>
              <div className="flex-60">
                <p className="heading-text-2 full-width center-text">${(this.state.chartType === 'Income') ? Number(this.state.endingIncome).toLocaleString() : Number(this.state.endingNetAssets).toLocaleString()}</p>
                <p className="full-width center-text">Projected {(this.state.chartType === 'Income') ? "Gross Earnings" : "Net Worth"}</p>
                <p className="description-text-2 cta-color bold-text full-width center-text">On Track</p>
              </div>
              <div className="flex-20">
                {/*
                <button className="background-button float-right horizontal-padding-4" onClick={() => this.setState({ modalIsOpen: true, editSection: '', showEditInfo: true, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                  <div className="row-10">
                    <p className="description-text-5 cta-color bold-text">EDIT</p>
                  </div>
                </button>*/}
                <button className="background-button float-right horizontal-padding-4" onClick={(this.state.modalIsOpen && this.state.enlargedChart) ? () => this.closeModal(false) : () => this.setState({ modalIsOpen: true, editSection: '', showEditInfo: false, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false, enlargedChart: true })}>
                  <div className="row-10">
                    <img src={(this.state.modalIsOpen && this.state.enlargedChart) ? minimizeIcon : expandIcon} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                  </div>
                </button>

                <div className="clear" />
              </div>
              <div className="clear" />
            </div>

            <ul className="profile-links description-text-4 half-bold-text">
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '1')}><div className={(this.state.duration === '1') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>1Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '3')}><div className={(this.state.duration === '3') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>3Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '5')}><div className={(this.state.duration === '5') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>5Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '10')}><div className={(this.state.duration === '10') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>10Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '15')}><div className={(this.state.duration === '15') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>15Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, '20')}><div className={(this.state.duration === '20') ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>20Y</div></button></li>
              <li><button className="background-button clear-padding" onClick={() => this.configureChartData(this.state.chartType, this.state.scenario, 'Retirement')}><div className={(this.state.retirementYear - new Date().getFullYear() === Number(this.state.duration)) ? "cta-color bold-text cta-border horizontal-padding-7 top-margin" : "horizontal-padding-7 top-margin"}>Retirement</div></button></li>
            </ul>
            {console.log('compare the two: ', this.state.retirementYear - new Date().getFullYear(), this.state.duration)}
          </div>

          {(this.state.chartData) && (
            <div className="row-10">
              <Line
                data={this.state.chartData}
              />
            </div>
          )}

          <div className="row-10">
            <ul className="profile-links">
              <li><button className="background-button" onClick={() => this.configureChartData(this.state.chartType, 'Bad', this.state.duration)}><div className={(this.state.scenario === 'Bad') ? "cta-color bold-text cta-border horizontal-padding row-5 description-text-1" : "horizontal-padding row-5 description-text-1"}>Bad Scenario (5%)</div></button></li>
              <li><button className="background-button" onClick={() => this.configureChartData(this.state.chartType, 'Base', this.state.duration)}><div className={(this.state.scenario === 'Base') ? "cta-color bold-text cta-border horizontal-padding row-5 description-text-1" : "horizontal-padding row-5 description-text-1"}>Base Scenario (50%)</div></button></li>
              <li><button className="background-button" onClick={() => this.configureChartData(this.state.chartType, 'Great', this.state.duration)}><div className={(this.state.scenario === 'Great') ? "cta-color bold-text cta-border horizontal-padding row-5 description-text-1" : "horizontal-padding row-5 description-text-1"}>Great Scenario (95%)</div></button></li>
            </ul>
          </div>
        </div>
      )
    }

    createNewDoc(creatorFirstName, creatorLastName) {
      console.log('createNewDoc called')

      const entries = []

      this.setState({ entries, successMessage: 'Successfully cleared the document!'})
      this.configureChartData('Income', this.state.scenario, this.state.duration)
    }

    toggleToolbar(type) {
      console.log('toggleToolbar called', type)

      if (type === 'save') {
        if (this.state.showSaveToolbar) {
          this.setState({ showSaveToolbar: false })
        } else {
          this.setState({ showSaveToolbar: true, showCareerToolbar: false, showOpportunityToolbar: false, showTutorialToolbar: false, showOpenToolbar: false })
        }
      } else if (type === 'open') {
        if (this.state.showOpenToolbar) {
          this.setState({ showOpenToolbar: false })
        } else {
          this.setState({ showOpenToolbar: true, showCareerToolbar: false, showOpportunityToolbar: false, showSaveToolbar: false, showTutorialToolbar: false })
        }
      }
    }

    saveFinancialPlan() {
      console.log('saveFinancialPlan called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.saveType || this.state.saveType === '') {
        this.setState({ errorMessage: 'Please add whether you would like to save as a new file or existing file' })
      } else if (!this.state.financialPlanFileName || this.state.financialPlanFileName === '') {
        this.setState({ errorMessage: 'Please add a financial plan file name' })
      } else {

        const email = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const username = this.state.username
        const pictureURL = this.state.pictureURL

        const orgCode = this.state.org

        const fileName = this.state.financialPlanFileName

        let fileId = this.state.fileId
        if (this.state.financialPlans && this.state.financialPlans.length > 0) {
          for (let i = 1; i <= this.state.financialPlans.length; i++) {
            if (this.state.financialPlans[i - 1].fileName === this.state.financialPlanFileName) {
              fileId = this.state.financialPlans[i - 1]._id
            }
          }
        }

        const financialPlanContent = {
          entries: this.state.entries, counterArray: this.state.counterArray,
          incomeArray: this.state.incomeArray, expenseArray: this.state.expenseArray,
          assetArray: this.state.assetArray, liabilityArray: this.state.liabilityArray
        }

        const docType = 'Financial Plan'
        const slug = 'financial-plans'

        const createdAt = new Date()
        const updatedAt = new Date()

        let financialPlanSaveObject = {
          email, firstName, lastName, username, pictureURL, orgCode, fileName, fileId,
          financialPlanContent, docContent: financialPlanContent, docType, slug,
          createdAt, updatedAt
        }

        let postLink = '/api/financial-plans/save'
        if (this.state.showDoc) {
          postLink = '/api/documents/save'
        }

        Axios.post(postLink, financialPlanSaveObject)
        .then((response) => {
          console.log('attempting to save financial plan')
          if (response.data.success) {
            console.log('saved financial plan', response.data)

            this.setState({ successMessage: response.data.message, isSaving: false })

            if (!fileId) {
              financialPlanSaveObject['_id'] = response.data._id

              let financialPlans = this.state.financialPlans
              if (financialPlans) {
                financialPlans.push(financialPlanSaveObject)
              } else {
                financialPlans = [financialPlanSaveObject]
              }
              if (this.props.passData) {
                this.props.passData({ savedItems: financialPlans })
              }
            }

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving financial plan', isSaving: false })
        });

      }
    }

    exportFile() {
      console.log('exportFile called')

      const financialPlanProfile = { firstName: this.state.cuFirstName, lastName: this.state.cuLastName }

      let returnedValue = subExportFile('financial_plan',this.state.disableExport, this.state.financialPlanFileName, financialPlanProfile, this.state.pageCount)

      if (returnedValue && returnedValue.passedData) {
        this.setState(returnedValue.passedData)
      }

      this.closeModal()
    }

    loadFinancialPlan(financialPlanFileName) {
      console.log('loadFinancialPlan called')

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      if (!financialPlanFileName) {
        financialPlanFileName = this.state.financialPlanFileName
      }

      if (!financialPlanFileName || financialPlanFileName === '') {
        this.setState({ isLoading: false, errorMessage: 'Please select a file'})
      } else if (!this.state.financialPlans || this.state.financialPlans.length === 0) {
        this.setState({ isLoading: false, errorMessage: 'No financial plan files found'})
      } else {

        let selectedFinancialPlan = null
        for (let i = 1; i <= this.state.financialPlans.length; i++) {
          if (this.state.financialPlans[i - 1].fileName === financialPlanFileName) {
            selectedFinancialPlan = this.state.financialPlans[i - 1]
          }
        }

        if (!selectedFinancialPlan) {
          this.setState({ isLoading: false, errorMessage: 'Financial plan file not found for some reason'})
        } else if (selectedFinancialPlan.docType !== 'Financial Plan') {
          this.setState({ isLoading: false, errorMessage: 'Document file type does not match the document loader. Please navigate to the ' + selectedFinancialPlan.docType + ' loader section.'})

          // if (this.props.passData) {
          //
          //   const subNavSelected = selectedDoc.docType
          //   // let fileTypeSingular = 'Resume'
          //   // let fileTypePlural = 'Resumes'
          //
          //   function configureFileTypes() {
          //     let fileTypeSingular = 'Resume'
          //     let fileTypePlural = 'Resumes'
          //     if (subNavSelected === 'Career Plan') {
          //       fileTypeSingular = 'Career Plan'
          //       fileTypePlural = 'Career Plans'
          //     } else if (subNavSelected === 'Financial Plan') {
          //       fileTypeSingular = 'Financial Plan'
          //       fileTypePlural = 'Financial Plans'
          //     } else if (subNavSelected === 'Cover Letter') {
          //       fileTypeSingular = 'Cover Letter'
          //       fileTypePlural = 'Cover Letters'
          //     } else if (subNavSelected === 'Business Plan') {
          //       fileTypeSingular = 'Business Plan'
          //       fileTypePlural = 'Business Plans'
          //     } else if (subNavSelected === 'Mock Interview') {
          //       fileTypeSingular = 'Mock Interview'
          //       fileTypePlural = 'Mock Interviews'
          //     }
          //
          //     return { fileTypeSingular, fileTypePlural }
          //   }
          //
          //   const fileTypesObject = configureFileTypes()
          //
          //   this.props.passData({ subNavSelected, fileTypeSingular: fileTypesObject.fileTypeSingular, fileTypePlural: fileTypesObject.fileTypePlural, id: selectedDoc._id })
          //
          // }
        } else {

          let entries = []
          if (selectedFinancialPlan.docContent && selectedFinancialPlan.docContent.entries) {
            entries = selectedFinancialPlan.docContent.entries
          } else if (selectedFinancialPlan.financialPlanContent && selectedFinancialPlan.financialPlanContent.entries) {
            entries = selectedFinancialPlan.financialPlanContent.entries
          }

          this.setState({
            financialPlanFileName, entries,
            isLoading: false, successMessage: 'Successfully loaded financial plan!'
          })

          this.configureChartData('Income', this.state.scenario, this.state.duration)
          this.closeModal()
        }
      }
    }

    deleteFinancialPlan() {
      console.log('deleteFinancialPlan called')

      let _id = null
      let financialPlanFileNames = this.state.financialPlanFileNames
      let financialPlans = this.state.financialPlans
      for (let i = 0; i < this.state.financialPlans.length; i++) {
        if (this.state.financialPlans[i].fileName === this.state.financialPlanFileName) {
          _id = this.state.financialPlans[i]._id
          financialPlanFileNames.splice(i + 1,1)
          financialPlans.splice(i,1)
        }
      }

      if (!_id) {
        console.log('something went wrong')
        this.setState({ errorMessage: 'Error: something went wrong'})
      } else {

        let postLink = '/api/financial-plans/' + _id
        if (this.state.showDoc) {
          postLink = '/api/documents/' + _id
        }

        Axios.delete(postLink)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Financial plan delete worked');

            let financialPlanFileName = ''
            this.setState({ financialPlans, financialPlanFileNames, financialPlanFileName, successMessage: "Financial Plan was successfully deleted!", confirmDelete: false })

            if (this.props.passData) {
              this.props.passData({ savedItems: financialPlans })
            }
            this.closeModal()

          } else {
            console.error('there was an error deleting the financial plan');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
          <div>
            {(window.innerWidth < 768) ? (
              <div className="flex-container flex-center full-space">
                <div className="horizontal-padding">
                  <div className="super-spacer" />

                  <img src={layoutIconDark} alt="GC" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text bold-text">Financial plan builder has not been optimized for mobile. Please use a laptop / desktop sized screen.</p>

                </div>
              </div>
            ) : (
              <div>
                <div className="bottom-margin-20">
                  {(this.props.source !== 'Builders') && (
                    <div>
                      <div className="bottom-padding">
                        <div className="container-left">
                          <select name="entryMode" className="dropdown" value={this.state.entryMode} onChange={this.formChangeHandler}>
                            {this.state.entryModeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-20">
                        <p className="heading-text-2">{this.state.entryMode}</p>
                        {(this.state.entryMode === 'Ballpark Mode') && (
                          <p className="profile-descriptor top-padding-5"><label className="bold-text">Ballpark Mode</label> allows you estimate your future income, expenses, assets, and liabilities based on your career selection and broad assumptions</p>
                        )}
                        {(this.state.entryMode === 'Custom Entries Mode') && (
                          <p className="profile-descriptor top-padding-5"><label className="bold-text">Custom Entries Mode</label> allows you add specific income, expenses, assets, and liabilities amounts to get a more detailed estimate of your financial profile.</p>
                        )}
                        <div className="spacer" />
                      </div>
                    </div>
                  )}

                  {(this.state.entryMode === 'Custom Entries Mode') && (
                    <div className="bottom-padding-20 top-padding">

                      {(this.props.source === 'Builders') && (
                        <div>
                          <div className="top-padding">
                            <div className="top-margin-2-percent center-horizontally full-width">
                              <div>
                                <div className="fixed-column-200">
                                  <button className="btn background-button right-margin-20" onClick={() => this.createNewDoc(this.state.cuFirstName, this.state.cuLastName)}><img src={newDocumentIcon} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create New Document"/></button>
                                </div>

                                <div className="calc-column-offset-300 center-text">
                                  <div className="width-30 height-40" />
                                </div>

                                <div className="fixed-column-100">
                                  <div className="float-right">
                                    {(this.props.pageSource !== 'landingPage') && (
                                      <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('save')}><img src={(this.state.showSaveToolbar) ? saveIconBlue : saveIconDark} alt="GC" className="image-auto-16" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Save Financial Plan"/></button>
                                    )}
                                    {(this.props.pageSource !== 'landingPage') && (
                                      <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('open')}><img src={(this.state.showOpenToolbar) ? openIconBlue : openIconDark} alt="GC" className="image-auto-16" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Open Saved Financial Plan"/></button>
                                    )}
                                    <button className="btn background-button" onClick={() => this.setState({ modalIsOpen: true, showConfirmExport: true })}><img src={exportIconDark} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Export Financial Plan"/></button>
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>

                          <ReactTooltip id="tooltip-placeholder-id" />

                          {(this.state.showSaveToolbar) && (
                            <div className="top-margin-2-percent center-horizontally full-width bottom-margin-20">
                              <p className="heading-text-4">How would you like to save?</p>
                              <div className="row-10">
                                <div className="container-left">
                                  <select name={"saveType"} className="dropdown" value={this.state.saveType} onChange={this.formChangeHandler}>
                                      {['','Save New','Update Financial Plan File'].map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                </div>
                                {(this.state.saveType && this.state.saveType !== '') && (
                                  <div className="container-right">
                                    {(this.state.saveType === 'Save New') ? (
                                      <input className="text-field" type="text" placeholder="Add a file name" name="financialPlanFileName" value={this.state.financialPlanFileName} onChange={this.formChangeHandler} />
                                    ) : (
                                      <select name={"financialPlanFileName"} className="dropdown" value={this.state.financialPlanFileName} onChange={this.formChangeHandler}>
                                          {this.state.financialPlanFileNames.map(value => <option key={value} value={value}>{value}</option>)}
                                      </select>
                                    )}

                                  </div>
                                )}
                                <div className="clear" />
                                <div className="spacer" />
                              </div>

                              <div>
                                <div className="float-left">
                                  <button className="btn btn-squarish" onClick={() => this.saveFinancialPlan()}>Save Financial Plan</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          {(this.state.showOpenToolbar) && (
                            <div className="top-margin-2-percent center-horizontally full-width bottom-margin-20">
                              <p className="heading-text-4">What would you like to open?</p>
                              <div className="row-10">
                                <div className="container-left">
                                  <select name={"financialPlanFileName"} className="dropdown" value={this.state.financialPlanFileName} onChange={this.formChangeHandler}>
                                      {this.state.financialPlanFileNames.map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                </div>
                                <div className="container-right">
                                  <button className="btn btn-squarish" onClick={() => this.loadFinancialPlan()}>Open</button>
                                  <button className="btn btn-squarish error-background-color error-border left-margin" onClick={() => this.setState({ confirmDelete: true })}>Delete</button>
                                </div>
                                <div className="clear" />
                              </div>

                              {(this.state.confirmDelete) && (
                                <div className="row-10">
                                  <p className="error-color description-text-2 bottom-margin">Are you sure?</p>

                                  <button className="btn btn-squarish error-background-color error-border" onClick={() => this.deleteFinancialPlan()}>Delete</button>
                                  <button className="btn btn-squarish-white left-margin" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="card">
                        {(this.props.source !== 'Builders') && (
                          <div>
                          </div>
                        )}

                        <div>
                          <div className="calc-column-offset-30">
                            <div className="float-left right-padding">
                              <p className={(this.props.source === 'Builders') ? "heading-text-3" : "heading-text-2"}>Custom Entries</p>
                            </div>
                            <div className={(this.props.source === 'Builders') ? "float-left" : "float-left top-padding-5"}>
                              <div className="float-left left-margin noti-bubble-info-7-9">
                                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showEditInfo: false, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false, showCustomEntries: true })}>
                                  <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                </a>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                          <button className="background-button fixed-column-30" onClick={() => this.setState({ modalIsOpen: true, editSection: 'Custom Entries Mode', showEditInfo: true, selectedIndex: null, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                            <div className={(this.props.source === 'Builders') ? "bottom-padding-20" : "row-10"}>
                              <img src={addIconBlue} alt="GC" className="image-auto-20 pin-right" />
                            </div>
                          </button>
                          <div className="clear" />
                        </div>
                        {this.renderCustomEntries()}
                      </div>
                    </div>
                  )}

                  {(this.state.entryMode !== 'Custom Entries Mode') && (
                    <div className="bottom-padding">
                      {this.renderCareerSelectModule(false)}
                    </div>
                  )}

                  {(this.props.source === 'Builders') && (
                    <div className="bottom-padding-20">
                      <SubRenderFinancialPlan
                        financialPlanContent={(this.state.docContent) ? this.state.docContent : this.state.financialPlanContent}
                        counterArray={(this.state.counterArray) ? this.state.counterArray : []}
                        incomeArray={(this.state.incomeArray) ? this.state.incomeArray : []}
                        expenseArray={(this.state.expenseArray) ? this.state.expenseArray : []}
                        assetArray={(this.state.assetArray) ? this.state.assetArray : []}
                        liabilityArray={(this.state.liabilityArray) ? this.state.liabilityArray : []}
                      />
                    </div>
                  )}

                  <div className="card">
                    {this.renderChart()}
                  </div>

                  {(this.state.showSnapshot) && (
                    <div className="card top-margin">
                      <div>
                        <div className="calc-column-offset-50">
                          <div className="float-left right-padding">
                            <p className="heading-text-2">Snapshot</p>
                          </div>
                          <div className="float-left top-padding-5">
                            <div className="float-left left-margin noti-bubble-info-7-9">
                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showEditInfo: false, showSnapshotInfo: true, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                              </a>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>
                        <button className="background-button fixed-column-50" onClick={() => this.setState({ modalIsOpen: true, editSection: '', showEditInfo: true, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                          <div className="row-10">
                            <p className="description-text-5 cta-color bold-text">EDIT</p>
                          </div>
                        </button>

                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Income</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingIncome).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Expenses</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingExpenses).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Net Income</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingNetIncome).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>

                      <div className="row-10">
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Assets</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingAssets).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Liabilities</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingLiabilities).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear()} Net Worth</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.startingNetAssets).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>

                      <div className="row-10">
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Income</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingIncome).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Expenses</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingExpenses).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Net Income</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingNetIncome).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>

                      <div className="row-10">
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Assets</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingAssets).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Liabilities</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingLiabilities).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="description-text-2">
                          <div className="container-left-static row-5">
                            <p className="heading-text-color">{new Date().getFullYear() + Number(this.state.duration)} Net Worth</p>
                          </div>
                          <div className="container-right-static row-5">
                            <p className="full-width right-text">${Number(this.state.endingNetAssets).toLocaleString()}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.state.entryMode === 'Ballpark Mode') && (
                    <div>
                      <div className="card top-margin">
                        <div>
                          <div className="calc-column-offset-50">
                            <div className="float-left right-padding">
                              <p className="heading-text-2">Personal Info</p>
                            </div>
                            <div className="float-left top-padding-5">
                              <div className="float-left left-margin noti-bubble-info-7-9">
                                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showEditInfo: false, showSnapshotInfo: false, showPersonalInfo: true, showFinancialAssumptionsInfo: false })}>
                                  <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                </a>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                          <button className="background-button fixed-column-50" onClick={() => this.setState({ modalIsOpen: true, editSection: 'Personal Info', showEditInfo: true, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                            <div className="row-10">
                              <p className="description-text-5 cta-color bold-text">EDIT</p>
                            </div>
                          </button>
                          <div className="clear" />
                        </div>

                        {this.renderPersonalInfo()}

                      </div>

                      <div className="card top-margin">
                        <div>
                          <div className="calc-column-offset-50">
                            <div className="float-left right-padding">
                              <p className="heading-text-2">Financial Assumptions</p>
                            </div>
                            <div className="float-left top-padding-5">
                              <div className="float-left left-margin noti-bubble-info-7-9">
                                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showEditInfo: false, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: true })}>
                                  <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                </a>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                          <button className="background-button fixed-column-50" onClick={() => this.setState({ modalIsOpen: true, editSection: 'Financial Assumptions', showEditInfo: true, showSnapshotInfo: false, showPersonalInfo: false, showFinancialAssumptionsInfo: false })}>
                            <div className="row-10">
                              <p className="description-text-5 cta-color bold-text">EDIT</p>
                            </div>
                          </button>
                          <div className="clear" />
                        </div>
                        {this.renderFinancialAssumptions()}
                      </div>
                    </div>
                  )}

                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                   ariaHideApp={false}
                 >

                    <div className="full-width padding-20">
                      {(this.state.showEditInfo) && (
                        <div>
                          {(this.state.editSection === 'Personal Info' || this.state.editSection === 'Financial Assumptions') && (
                            <div className="bottom-padding-20">
                              <label className="profile-label">Select a Section to Edit</label>
                              <select name="editSection" className="dropdown" value={this.state.editSection} onChange={this.formChangeHandler}>
                                  {this.state.editSections.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                          )}

                          {(this.state.editSection === 'Personal Info') && (
                            <div>
                              <p className="heading-text-2">Edit Personal Info</p>
                              <div className="spacer" />

                              {this.renderPersonalInfo(true)}
                            </div>
                          )}

                          {(this.state.editSection === 'Financial Assumptions') && (
                            <div>
                              <p className="heading-text-2">Edit Financial Assumptions</p>
                              <div className="spacer" />

                              {this.renderFinancialAssumptions(true)}
                            </div>
                          )}

                          {(this.state.editSection === 'Custom Entries Mode') && (
                            <div>
                              <p className="heading-text-2">{(this.state.selectedIndex || this.state.selectedIndex === 0) ? "Edit" : "Add"} Custom Entry</p>
                              <div className="spacer" />
                              {this.renderCustomEntries(true)}

                            </div>
                          )}

                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}

                          <div className="row-20">
                            {(this.state.editSection === 'Custom Entries Mode') ? (
                              <button className="btn btn-primary right-margin" onClick={() => this.addEntry(this.state.entries, this.state.selectedIndex)}>{(this.state.selectedIndex || this.state.selectedIndex === 0) ? "Edit" : "Add"} Entry</button>
                            ) : (
                              <button className="btn btn-primary right-margin" onClick={() => this.closeModal(true)}>Apply Changes</button>
                            )}
                            <button className="btn btn-secondary" onClick={() => this.closeModal(false)}>Cancel</button>
                            {(this.state.editSection === 'Custom Entries Mode' && (this.state.selectedIndex || this.state.selectedIndex === 0)) && (
                              <button className="btn btn-quaternary margin-left" onClick={() => this.deleteEntry()}>Delete</button>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.showSnapshotInfo) && (
                        <div>
                          <p className="heading-text-2">Info about Snapshot Section</p>

                          <div className="row-10">
                            <p>This section shows income and net worth from the starting period (e.g., {new Date().getFullYear()}) to ending period (e.g., {new Date().getFullYear() + 10}).</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Net Income</label> is always equal to income minus expenses. This income assumes all all income is due to your pay from your career (and that all investment income is capital gains).</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Net Worth</label> is always equal to assets minus liabilities. Assets and liabilities are categorized and simplified to focus more on the career income portion of how you gain wealth.</p>
                          </div>

                        </div>
                      )}

                      {(this.state.showPersonalInfo) && (
                        <div>
                          <p className="heading-text-2">Info about Personal Info Section</p>

                          <div className="row-10">
                            <p>This section allows you to change details of your situation to affect the underlying financial assumptions.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Target Occupation</label> allows you to search from 1,000+ career paths. Selecting a career path imports the career information into career pay, career growth, pay variation, and unemployment risk.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Career Performance</label> refers to your dedication / intensity / performance in your career. This is different than the "scenario" toggle, which focuses on probability of circumstances on average using both historical and forecasted data.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Career Stage</label> refers to the level of career you are at. Age is a rough proxy, but it is not necessarily a indicative of career stage. If you would be mid-level employee at a large company (both subordinates and superiors), then you can be categorized as mid career. That said, we have assumed the early career is 10 years, mid career is 15 years, and late career is 20 years.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Zip Code</label> allows the model to adjust for regional salaries as well as regional cost of living.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Lifestyle</label> refers to the level of your expenses. By default, this is calculated as a percentage of expenses. That said, "luxury" lifestyles have discretionaty spending floors at each career stage and "modest" lifestyles have spending ceilings at each career stage.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Birth date</label> is helpful for determining career stage and retirement year.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Retirement</label> is 65 years old by default, but can be changed to a different year.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Dependents</label> is assumed to be children. Each dependent is assumed to add $10K to your expenses.</p>
                          </div>
                        </div>
                      )}

                      {(this.state.showFinancialAssumptionsInfo) && (
                        <div>
                          <p className="heading-text-2">Info about Financial Assumptions Seection</p>

                          <div className="row-10">
                            <p>This section allows you to change most of the underlying financial asssumptions of the model.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Income</label> includes annual pay, growth rates, variation, and unemployment risks.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Expenses</label> includes the average tax rate, spending as a percentage of after-tax incoome, paydown rate (how much after-tax income you use to pay down debt), and percentage of spending financed by debt.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Assets</label> includes cash, investments, real estate, and other assets (e.g., car). Some of these assets are appreciating assets, meaning they increase in value. This increase in value is treated is reflected in assets, and is assumed not to be income.</p>
                          </div>

                          <div className="row-10">
                            <p><label className="cta-color bold-text">Liabilities</label> includes credit card, student loan, mortgage, and other debt. Some debt (e.g., credit cards) have a higher interest rate than others.</p>
                          </div>
                        </div>
                      )}

                      {(this.state.showCustomEntries) && (
                        <div>
                          <p className="heading-text-2">Info about Custom Entries</p>

                          <div className="row-10">
                            <p>Add income, expenses, assets, and liabilities by clicking the "+" button on the "Custom Entries" card. As you populate, the chart will auto-update. Please email questions to help@guidedcompass.com.</p>
                          </div>
                        </div>
                      )}

                      {(this.state.enlargedChart) && (
                        <div>
                          {this.renderChart()}
                        </div>
                      )}

                      {(this.state.showConfirmExport) && (
                        <div>
                          <div className="padding-40">
                            <div className="calc-column-offset-40">
                              <div>
                                <p className="heading-text-2">Export File to PDF</p>
                              </div>
                            </div>
                            <div className="fixed-columnd-40">
                              <button className="background-button float-right" onClick={() => this.closeModal()}>
                                <img src={deniedIcon} alt="GC" className="image-auto-25" />
                              </button>
                              <div className="clear" />
                            </div>
                            <div className="clear" />

                            <div className="spacer" /><div className="spacer" />

                            <div className="row-20">
                              <p>Are you sure that you want to export this file to PDF?</p>
                              <p className="error-color row-5 top-margin-15">Note: PDF files are currently limited to one page.</p>
                            </div>

                            <div className="spacer" /><div className="spacer" />

                            <button className="btn btn-primary right-margin" onClick={() => this.exportFile()}>Export to PDF</button>
                            <button className="btn btn-secondary right-margin" onClick={() => this.closeModal()}>Close View</button>
                          </div>
                        </div>
                      )}

                      {(this.state.showFile) && (
                        <div>
                          <div className="padding-40">
                            <div className="calc-column-offset-40">
                              {(this.state.selectedFile) ? (
                                <div>
                                  <p className="heading-text-2">{this.state.selectedFile.fileName}</p>
                                  {(this.state.selectedFile.updatedAt) && (
                                    <div className="top-margin-5">
                                      <p>Last Updated: {convertDateToString(new Date(this.state.selectedFile.updatedAt),'date-2')}</p>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p className="heading-text-2">FinancialPlan File Not Found</p>
                                </div>
                              )}
                            </div>
                            <div className="fixed-columnd-40 top-margin">
                              <button className="background-button float-right" onClick={() => this.closeModal()}>
                                <img src={deniedIcon} alt="GC" className="image-auto-25" />
                              </button>
                              <div className="clear" />
                            </div>
                            <div className="clear" />

                            <div className="spacer" /><div className="spacer" />

                            {(this.state.selectedFile) && (
                              <div className="row-20">
                                <p>What would you like to do with {this.state.selectedFile.fileName}?</p>
                              </div>
                            )}

                            <div className="spacer" /><div className="spacer" />

                            {(this.state.confirmDelete) ? (
                              <div className="row-10">
                                <p className="error-color description-text-2 bottom-margin">Are you sure?</p>

                                <button className="btn btn-squarish error-background-color error-border" onClick={() => this.deleteFinancialPlan()}>Delete</button>
                                <button className="btn btn-squarish-white left-margin" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                              </div>
                            ) : (
                              <div>
                                <button className="btn btn-primary right-margin" onClick={() => this.loadFinancialPlan()}>Open</button>
                                {(!this.state.remoteAuth) && (
                                  <button className="btn btn-primary senary-background senary-border right-margin" onClick={(this.state.showDoc) ? () => window.open('/documents/preview/' + this.state.selectedFile._id) : () => window.open('/financial-plans/preview/' + this.state.selectedFile._id)}>Preview</button>
                                )}

                                {/*<button className="btn btn-primary right-margin" onClick={() => console.log('')}>Share</button>*/}
                                <button className="btn btn-primary error-background-color error-border right-margin" onClick={() => this.setState({ confirmDelete: true })}>Delete</button>
                              </div>
                            )}

                          </div>
                        </div>
                      )}

                    </div>
                 </Modal>

                </div>
              </div>
            )}
          </div>
      )
    }
}


export default withRouter(Financials);
