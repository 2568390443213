import React, {Component} from 'react';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

class LogDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {

          logDetails: null,
          logDetailPath: '',
          directLink: false,

          member: { firstName: '', lastName: ''}

        }

        this.retrieveData = this.retrieveData.bind(this);
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubLogDetails ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subLogDetails')

      const org = this.props.activeOrg
      const logDetails = this.props.logDetails
      const member = this.props.member

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');

      let newMember = { firstName: '', lastName: ''}
      if (member) {
        newMember = member
      } else if (logDetails) {

        Axios.get('/api/users/profile/details/' + logDetails.creatorEmail)
        .then((response) => {
          console.log('User profile query attempted', response.data);

          if (response.data.success) {
            console.log('user query worked')

            let member = response.data.user

            this.setState({ member });

          } else {
            console.log('user profile info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('User profile query did not work for some reason', error);
        });

      }

      this.setState({ org, email, username, orgFocus, logDetails, member: newMember })

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(40%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      }
    }

    render() {


      return (
          <div>
            { this.state.logDetails && (
              <div className="career-details-container">
                  <br/>

                  {(this.state.logDetails.logType === 'Session' || this.state.logDetails.logType === 'Meeting') && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Check In</h2>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Advisee</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.adviseeFirstName} {this.state.logDetails.adviseeLastName}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Mentor Coach</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.advisorFirstName} {this.state.logDetails.advisorLastName}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Session Date</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{convertDateToString(new Date(this.state.logDetails.sessionDate),"datetime-2")}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Session Method</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.sessionMethod}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Category</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.category}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Notes</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.notes}</p>
                        </div>
                      </div>

                    </div>
                  )}
                  {this.state.logDetails.logType === 'Goal' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Goal</h2>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Description</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.description}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Log Date</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.createdAt}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Deadline</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.deadline}</p>
                        </div>
                      </div>

                      {(this.state.logDetails.tasks && this.state.logDetails.tasks.length > 0) && (
                        <div className="row">
                          <div className="col span-1-of-6 box details-category-title">
                              <p>Tasks</p>
                          </div>
                          <div className="col span-5-of-6 box">
                            <p>Name: {this.state.logDetails.tasks[0].name} | Deadline: {this.state.logDetails.tasks[0].deadline} | Status: {this.state.logDetails.tasks[0].status}</p>
                            <p></p>
                            <p></p>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Notes</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.notes}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Status</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.status}</p>
                        </div>
                      </div>

                    </div>
                  )}

                  {this.state.logDetails.logType === 'Application' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Application</h2>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Position</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.positionTitle} @ {this.state.logDetails.employerName}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Position URL</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.positionLink}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Job Function</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.jobFunction}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Work Type</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.workType}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Employer URL</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.employerURL}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Employer Type</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.employerType}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Employer Industry</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.employerIndustry}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Employer Size</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.employerSize}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Application Deadline</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.applicationDeadline}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Time Frame</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.timeframe}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>MC Reviewed Materials</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.reviewedMaterials}</p>
                        </div>
                      </div>

                    </div>
                  )}

                  {this.state.logDetails.logType === 'Interview' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Interview</h2>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Position</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.associatedApplicationPositionTitle} | {this.state.logDetails.associatedApplicationEmployerName}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Interview Date</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.interviewDate}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Number of Interviews</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.numberOfInterviews}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Interviews</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.interviews[0].name} {this.state.logDetails.interviews[0].rating}</p>
                        </div>
                      </div>

                      {this.state.logDetails.questionsAsked[0] && (
                        <div className="row">
                          <div className="col span-1-of-6 box details-category-title">
                              <p>Questions Asked</p>
                          </div>
                          <div className="col span-5-of-6 box">
                              <p>{this.state.logDetails.questionsAsked[0]}</p>
                          </div>
                        </div>
                      )}

                      {this.state.logDetails.questionsAnswered[0] && (
                        <div className="row">
                          <div className="col span-1-of-6 box details-category-title">
                              <p>Questions Answered</p>
                          </div>
                          <div className="col span-5-of-6 box">
                              <p>{this.state.logDetails.questionsAnswered[0]}</p>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Unrelated Topics Covered (Tags)</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.unrelatedTopics}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Practiced with Mentor Coach</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.mcPracticedInterviewing}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Was Prepared?</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.wasPrepared}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Position</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>Rating : {this.state.logDetails.positionRating}</p>
                            <p>{this.state.logDetails.thoughsOnPosition}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Company</p>
                        </div>
                        <div className="col span-5-of-6 box">
                          <p>{this.state.logDetails.companyRating}</p>
                          <p>{this.state.logDetails.thoughtsOnCompany}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Fit</p>
                        </div>
                        <div className="col span-5-of-6 box">
                          <p>{this.state.logDetails.fitRating}</p>
                          <p>{this.state.logDetails.thoughtsOnFit}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Employer Feedback</p>
                        </div>
                        <div className="col span-5-of-6 box">
                          <p>{this.state.logDetails.employerFeedback}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Approved for Next Round?</p>
                        </div>
                        <div className="col span-5-of-6 box">
                          <p>{this.state.logDetails.passed}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.logDetails.logType === 'Offer' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Offer</h2>
                    </div>
                  )}
                  {this.state.logDetails.logType === 'Project' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Project</h2>
                    </div>
                  )}

                  {this.state.logDetails.logType === 'Passion' && (
                    <div>
                      <h2>{this.state.member.firstName} {this.state.member.lastName} Passion</h2>
                      <p className="full-width center-text">Created on {this.state.logDetails.createdAt}</p>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Title</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.passionTitle}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col span-1-of-6 box details-category-title">
                            <p>Reason</p>
                        </div>
                        <div className="col span-5-of-6 box">
                            <p>{this.state.logDetails.passionReason}</p>
                        </div>
                      </div>
                    </div>
                  )}

              </div>
            )}
          </div>

      )
    }
}


export default withRouter(LogDetails);
