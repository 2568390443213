import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubGoalDetails from '../Common/GoalDetails';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';

class RenderGoals extends Component {
    constructor(props) {
      super(props)

      this.state = {
        favorites: [],
        newFavorites: [],
        showSubFunction: true,
      }

      this.segueToProfile = this.segueToProfile.bind(this)
      this.renderTags = this.renderTags.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.favoriteItem = this.favoriteItem.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderGoals', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.profileData !== prevProps.profileData || this.props.favorites !== prevProps.favorites) {
        this.retrieveData()
      } else if (this.props.sortCriteriaArray !== prevProps.sortCriteriaArray || this.props.filterCriteriaArray !== prevProps.filterCriteriaArray) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderGoals')

      const activeOrg = this.props.activeOrg
      const favorites = this.props.favorites
      const newFavorites = this.props.newFavorites
      const orgName = this.props.orgName
      const orgContactEmail = this.props.orgContactEmail

      let emailId = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      this.setState({ activeOrg, favorites, newFavorites, emailId, roleName, orgName, orgContactEmail })

    }

    segueToProfile(e, goal) {
      console.log('segueToProfile called')

      e.stopPropagation()
      e.preventDefault()

      if (window.location.pathname.startsWith('/organizations')) {
        window.open('/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername)
        // this.props.navigate('/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername)
        // return '/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername
      } else if (window.location.pathname.includes('/advisor') && this.state.roleName !== 'Mentor') {
        window.open('/advisor/members/' + goal.creatorUsername)
        // this.props.navigate('/advisor/members/' + goal.creatorUsername)
        // return '/advisor/members/' + goal.creatorUsername
      } else if (window.location.pathname.includes('/advisor') && this.state.roleName === 'Mentor') {
        this.props.navigate('/advisor/profile/' + goal.creatorUsername)
      } else if (window.location.pathname.startsWith('/goals')) {
        window.open('/' + goal.creatorUsername + '/profile')
        // this.props.navigate('/' + goal.creatorUsername + '/profile')
        // return '/' + goal.creatorUsername + '/profile'
      } else if (this.props.pageSource === 'landingPage') {
        window.open('/' + goal.creatorUsername + '/profile')
        // this.props.navigate('/' + goal.creatorUsername + '/profile')
        // return '/' + goal.creatorUsername + '/profile')
      } else if (window.location.pathname.startsWith('/app/')) {
        this.props.navigate('/app/profile/' + goal.creatorUsername)
      }
    }

    renderTags(passedArray, type) {
      // console.log('renderTags  called', passedArray, type, editMode)

      if (passedArray && passedArray.length > 0) {
        let backgroundColorClass = ''
        if (type === 'careers' || type === 'functions' || type === 'industries') {
          backgroundColorClass = 'primary-background-light'
        } else if (type === 'opportunities') {
          backgroundColorClass = 'secondary-background-light'
        } else if (type === 'competencies') {
          backgroundColorClass = 'tertiary-background-light'
        } else if (type === 'hours') {
          backgroundColorClass = 'quaternary-background-light'
        } else if (type === 'payRanges') {
          backgroundColorClass = 'quinary-background-light'
        } else if (type === 'schools') {
          backgroundColorClass = 'senary-background-light'
        } else if (type === 'majors') {
          backgroundColorClass = 'septary-background-light'
        }

        return (
          <div key={type + "|0"}>
            <div className="top-margin">
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  {(optionIndex < 3) && (
                    <div>

                      <div className="float-left right-padding-5">
                        <div className="half-spacer" />
                        <div className={"rounded-corners row-7 horizontal-padding-5 " + backgroundColorClass}>
                          {(typeof value === 'object') ? (
                            <div>
                              {(value.title) && (
                                <p className="description-text-2">{value.title}</p>
                              )}
                              {(value.name) && (
                                <p className="description-text-2">{value.name}</p>
                              )}
                            </div>
                          ) : (
                            <p className="description-text-2">{value}</p>
                          )}
                        </div>
                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
    }

    itemClicked(e, type, value) {
      console.log('itemClicked called', e, type, value)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ modalIsOpen: true, showHelpOutWidget: true, selectedGoal: value })
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showGoalDetails: false, showHelpOutWidget: false,
        selectedGoal: null
      });

    }

    async favoriteItem(e, item) {
      console.log('favoriteItem called', e, item)

      e.stopPropagation()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'goal')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){
          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
        } else {
          // console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
        }

        console.log('favorites here', favoritesArray, this.state.emailId)
        this.setState({ favorites: favoritesArray })

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({ successMessage: 'Favorite saved!', isSaving: false })
          } else {
            console.log('did not save successfully', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }

    }

    render() {

      return (
        <div>
          {(this.props.filteredGoals && this.props.filteredGoals.length > 0) ? (
            <div>
              {this.props.filteredGoals.map((value, index) =>
                <div key={index}>
                  <div className="relative-column-33 bottom-margin-20">
                    <div>
                      <img src={targetIconOrange} className="image-auto-50 center-horizontally bottom-margin-negative-35 relative-position z-index-1 padding-10 error-border circle-radius white-background" alt="GC" />
                    </div>

                    <button onClick={() => this.setState({ modalIsOpen: true, showGoalDetails: true, selectedGoal: value })} className="background-button full-space">
                      <div className="elevated-box white-background" >

                        <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <div className="horizontal-padding">
                          <p className="heading-text-5 full-width center-text">{value.title}</p>

                          {(value.goalType) && (
                            <div className="top-padding-20">
                              <p className="description-text-3 full-width center-text">[{value.goalType.description}]</p>
                            </div>
                          )}

                          <div className="spacer" /><div className="spacer" />

                          <hr />

                          <div className="top-padding-20">
                            <p className="description-text-3 full-width center-text">by <button target="_blank" className="background-button cta-color" onClick={(e) => this.segueToProfile(e,value)}>{value.creatorFirstName} {value.creatorLastName}</button></p>
                          </div>

                          <div className="top-padding-20">
                            {(value.startDate) ? (
                              <p className="description-text-3 full-width center-text">{convertDateToString(new Date(value.startDate),"date-2")} - {convertDateToString(new Date(value.deadline),"date-2")}</p>
                            ) : (
                              <p className="description-text-3 full-width center-text">Deadline: {convertDateToString(new Date(value.deadline),"date-2")}</p>
                            )}
                          </div>

                          <div className="top-padding">
                            {this.renderTags(value.selectedCareers, 'careers')}
                            {this.renderTags(value.selectedOpportunities, 'opportunities')}
                            {this.renderTags(value.competencies, 'competencies')}
                            {this.renderTags(value.selectedFunctions, 'functions')}
                            {this.renderTags(value.selectedIndustries, 'industries')}
                            {this.renderTags(value.selectedHours, 'hours')}
                            {this.renderTags(value.selectedPayRanges, 'payRanges')}
                            {this.renderTags(value.selectedSchools, 'schools')}
                            {this.renderTags(value.selectedMajors, 'majors')}
                            <div className="clear" />

                            {(value.description) && (
                              <p className="description-text-3 description-text-color top-margin full-width center-text">{value.description}</p>
                            )}

                            {(value.goalType && value.goalType.name === 'Alternatives') && (
                              <div>
                                {(value.pollQuestion) && (
                                  <p className="heading-text-5 top-margin-20">{value.pollQuestion}</p>
                                )}
                                <div className="top-margin-20">
                                  <div className="calc-column-offset-30-of-50">
                                    <p className="">{value.aName}</p>
                                  </div>
                                  <div className="fixed-column-60">
                                    <p className="full-width center-text heading-text-4">VS</p>
                                  </div>
                                  <div className="calc-column-offset-30-of-50">
                                    <p className="full-width right-text">{value.bName}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="clear" />

                          <div className="top-padding-20">
                            {(window.location.pathname.includes('/app/community')) ? (
                              <button className={(this.state.favorites.includes(value._id)) ? "btn btn-profile full-width medium-background" : "btn btn-profile full-width"} disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.favoriteItem(e,value,'helpOut')}>{(this.state.favorites.includes(value._id)) ? "Following" : "Follow"}</button>
                            ) : (
                              <button className="btn btn-profile full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.itemClicked(e,'helpOut', value)}>Help Out</button>
                            )}
                          </div>

                          {(this.props.sortCriteriaArray && this.props.sortCriteriaArray[index] && this.props.sortCriteriaArray[index].name) && (
                            <div className="top-padding">
                              <div className="half-spacer" />
                              <p className="error-color description-text-2">{this.props.sortCriteriaArray[index].name}: {this.props.sortCriteriaArray[index].criteria}</p>
                            </div>
                          )}
                          {(this.props.filterCriteriaArray && this.props.filterCriteriaArray[index] && this.props.filterCriteriaArray[index].name) && (
                            <div className="top-padding">
                              <div className="half-spacer" />
                              <p className="error-color description-text-2">{this.props.filterCriteriaArray[index].name}: {this.props.filterCriteriaArray[index].criteria}</p>
                            </div>
                          )}

                          <div className="spacer" />
                        </div>
                      </div>
                    </button>
                  </div>

                  {(index % 3 === 2) && (
                    <div className="clear" />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="error-color">Either goals are set to private or no goals have been added yet</p>
            </div>
          )}

          {(this.state.showGoalDetails || this.state.showHelpOutWidget) && (
            <div>
              <SubGoalDetails history={this.props.navigate} selectedGoal={this.state.selectedGoal} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showGoalDetails={this.state.showGoalDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.props.profileData}
                orgCode={this.state.activeOrg} orgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail}
              />
           </div>
          )}
        </div>
      )
    }
}

export default withRouter(RenderGoals);
