import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditStudent from '../Subcomponents/EditStudent';
import withRouter from '../Functions/WithRouter';

class OrgStudentEvaluation extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, userId } = this.props.params

      let student = null
      if (this.props.location && this.props.location.state) {
        student = this.props.location.state.student
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ emailId: email, orgLogo, org, student, profileUserId: userId });
      }
    }

    render() {

        console.log('render was called')
        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus}/>

              <SubEditStudent org={this.state.org} profileUserId={this.state.profileUserId} student={this.state.student} history={this.props.navigate} />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(OrgStudentEvaluation)
