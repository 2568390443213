import React, {Component} from 'react';

import { Link } from 'react-router-dom';
import Axios from 'axios';

import SubLogInFields from '../Common/LogInFields';
import {signOut} from '../Services/AuthRoutes';
import PreCheckoutIdea from '../Common/PreCheckoutIdea';
import SubIdeaSummary from '../Common/IdeaSummary';

const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class PurchaseIdea extends Component {
    constructor(props) {
        super(props)

        this.state = {
          quantityOptions: ['1','2','3','4','5','6','7','8','9','10'],
          recipientTypeOptions: ['','Me',"Someone Else"],
          profileItems: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.passData = this.passData.bind(this)
        this.logout = this.logout.bind(this)
        this.switchPreference = this.switchPreference.bind(this)
        this.pullProfileInfo = this.pullProfileInfo.bind(this)
        this.passProductData = this.passProductData.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in ConfigureCartItem', this.props, prevProps)

      if (this.props.selectedIdea !== prevProps.selectedIdea) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in ConfigureCartItem', this.props)

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let pictureURL = localStorage.getItem('pictureURL');

      if (!activeOrg) {
        activeOrg = 'guidedcompass'
      }

      const selectedIdea = this.props.selectedIdea
      let price = 0
      let total = 0
      if (selectedIdea) {
        price = selectedIdea.price
        total = price
      }

      let importedEmailId = false
      if (email) {
        importedEmailId = true
      }

      let profileItems = [
        { name: 'LinkedIn Profile', include: true },
        { name: 'Resume', include: true },
        { name: 'SMART Goals', include: true },
        { name: 'Career Plan', include: true },
        { name: 'Career Assessments', include: true },
        { name: 'Endorsements', include: false },
        { name: 'Favorited Items', include: false },
        { name: 'Prior Advising Sessions', include: false },
        { name: 'Passions', include: false },
      ]

      this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus, pictureURL, importedEmailId,
        selectedIdea, price, total, profileItems
      });

      this.pullProfileInfo(email, activeOrg, orgFocus)
    }

    pullProfileInfo(emailId, orgCode, orgFocus) {
      console.log('pullProfileInfo called', emailId, orgCode, orgFocus)

      Axios.get('/api/projects', { params: { emailId, includeCollaborations: true } })
      .then((response) => {
        console.log('Projects query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved projects')

            if (response.data.projects) {

              const projects = response.data.projects
              this.setState({ projects })

            }

          } else {
            console.log('no project data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Project query did not work', error);
      });

      Axios.get('/api/logs/goals', { params: { emailId } })
      .then((response) => {

          if (response.data.success) {
            console.log('Goals received query worked', response.data);

            const goals = response.data.goals
            this.setState({ goals })

          } else {
            console.log('no goal data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Goal query did not work', error);
      });

      Axios.get('/api/counseling/session', { params: { emailId, type: 'Advisee', hiddenToCareerSeeker: false } })
      .then((response) => {

          if (response.data.success) {
            console.log('Session query worked', response.data);

            const advisingSessions = response.data.sessions
            this.setState({ advisingSessions })

          } else {
            console.log('no session data found', response.data.message)
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }

      }).catch((error) => {
          console.log('Resource query did not work', error);
      });

      Axios.get('/api/logs/passions', { params: { emailId } })
      .then((response) => {

          if (response.data.success) {
            console.log('Passions received query worked', response.data);

            const passions = response.data.passions
            this.setState({ passions })

          } else {
            console.log('no passion data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Passion query did not work', error);
      });

      Axios.get('/api/career-plans', { params: { emailId } })
     .then((response) => {
       console.log('Career plan query attempted', response.data);

       if (response.data.success) {
         console.log('successfully retrieved career plans')

         const careerPlans = response.data.careerPlans
         let careerPlanFileNames = []
         if (careerPlans) {
           for (let i = 0; i < careerPlans.length; i++) {
             careerPlanFileNames.push(careerPlans[i].fileName)
           }
         }

         let careerPlanFileName = null
         if (careerPlanFileNames && careerPlanFileNames.length > 0) {
           careerPlanFileName = careerPlanFileNames[0]
         }
         this.setState({ careerPlans, careerPlanFileNames, careerPlanFileName })

       } else {
         console.log('no career plans data found', response.data.message)
       }

     }).catch((error) => {
         console.log('Career plan query did not work', error);
     });

     //  Axios.get('/api/assessment/results', { params: { emailId } })
     //   .then((response) => {
     //     console.log('query for assessment results worked');
     //
     //     if (response.data.success) {
     //
     //       console.log('actual assessment results', response.data)
     //
     //       if (response.data.results) {
     //
     //         let assessments = []
     //         const updatedAt = response.data.results.updatedAt
     //         if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
     //           const description = 'My specific preferences on where, how, and when I work'
     //           profileData['workPreferences'] = response.data.results.workPreferenceAnswers
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Work Preferences')) {
     //               assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
     //             }
     //           }
     //         }
     //         if (response.data.results.interestScores && response.data.results.interestScores.length > 0) {
     //           const description = 'My strong interest inventory assessment on what type of work I may like'
     //           profileData['interests'] = response.data.results.interestScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Interests')) {
     //               assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.skillsScores && response.data.results.skillsScores.length > 0) {
     //           const description = "Skills I think I'm good at and need work at based on my pathways of interest"
     //           profileData['skills'] = response.data.results.skillsScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Skills')) {
     //               assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.personalityScores) {
     //           const description = "Five factor personality assessment"
     //           profileData['personality'] = response.data.results.personalityScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Personality')) {
     //               assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0 && response.data.results.topEmployerValues && response.data.results.topEmployerValues.length > 0) {
     //           const description = 'The people and employers I gravitate towards'
     //           profileData['values'] = { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Values')) {
     //               assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
     //             }
     //           }
     //         }
     //
     //         this.setState({ assessments });
     //       }
     //
     //     } else {
     //       console.log('error response', response.data)
     //
     //       this.setState({ resultsErrorMessage: response.data.message })
     //     }
     //
     // }).catch((error) => {
     //     console.log('query for assessment results did not work', error);
     // })

      Axios.get('/api/story', { params: { emailId } })
      .then((response) => {
          console.log('Story query worked', response.data);

          if (response.data.success) {

            const endorsements = response.data.stories
            this.setState({ endorsements })

          }

      }).catch((error) => {
          console.log('Story query did not work', error);
      });

      Axios.get('/api/users/profile/details', { params: { email: emailId } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           const zipcode = response.data.user.zipcode
           const pictureURL = response.data.user.pictureURL
           const headline = response.data.user.headline
           const linkedInURL = response.data.user.linkedInURL
           const jobTitle = response.data.user.jobTitle
           const employerName = response.data.user.employerName
           const education = response.data.user.education
           const resumeURL = response.data.user.resumeURL

           this.setState({ zipcode, pictureURL, headline, linkedInURL, jobTitle, employerName,
             education, resumeURL
           });

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      Axios.get('/api/assessment/results', { params: { emailId } })
       .then((response2) => {
         console.log('query for assessment results worked');

         if (response2.data.success) {

           console.log('actual assessment results', response2.data)

           // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
           const workPreferences = response2.data.results.workPreferenceAnswers
           const interests = response2.data.results.interestScores
           const personality = response2.data.results.personalityScores
           const skills = response2.data.results.newSkillAnswers
           const topGravitateValues = response2.data.results.topGravitateValues
           const topEmployerValues = response2.data.results.topEmployerValues

           let careerAssessments = []
           if (workPreferences && workPreferences.length > 0) {
             careerAssessments.push('Work Preferences')
           }
           if (interests && interests.length > 0) {
             careerAssessments.push('Interests')
           }
           if (personality) {
             careerAssessments.push('Personality')
           }
           if (skills && skills.length > 0) {
             careerAssessments.push('Skills')
           }
           if (topGravitateValues && topGravitateValues.length > 0) {
             careerAssessments.push('Values')
           }

           this.setState({ workPreferences, interests, personality, skills, topGravitateValues, topEmployerValues, careerAssessments })

         } else {
           console.log('no assessment results', response2.data)
         }

     }).catch((error) => {
       console.log('query for assessment results did not work', error);
     })

      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved favorites')

          Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
          .then((response2) => {
            console.log('Favorites detail query attempted', response2.data);

            if (response2.data.success) {
              console.log('successfully retrieved favorites detail', response2.data.favorites)

              const favorites = response2.data.favorites
              const types = response2.data.types
              this.setState({ favorites, types })

            } else {
              console.log('no favorites detail data found', response2.data.message)
            }

          }).catch((error) => {
              console.log('Favorites detail query did not work', error);
          });

        } else {
          console.log('no favorites data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      this.setState({ [event.target.name]: event.target.value })

    }

    passData(data) {
      console.log('passData called', data)

      if (data) {
        this.setState(data)
        this.pullProfileInfo(data.emailId,this.state.activeOrg,this.state.orgFocus)
      }

    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        let importedEmailId = null
        let cuFirstName = ''
        let cuLastName = ''
        let emailId = ''
        let password = ''
        let roleName = null
        let roleNameLinked = null
        const otherRoleName = null

        // let phoneNumber = null
        // let accountCode = null
        let loginType = "SignUp"
        let loggedIn = false

        this.setState({ isSavingModal: false, modalIsOpen: false, successMessage: returnedValue.message,
          importedEmailId, cuFirstName, cuLastName, emailId, password, roleName, roleNameLinked, otherRoleName,
          loginType, loggedIn
        })

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    switchPreference(type, name, index, value) {
      console.log('switchPreference called', type, name, index, value)

      let profileItems = this.state.profileItems
      profileItems[index][name] = value
      this.setState({ profileItems, profileItemsChanged: !this.state.profileItemsChanged })

    }

    passProductData(passedData) {
      console.log('passProductData called in superview', passedData)

      if (passedData) {
        const purchased = passedData.purchased
        const success = passedData.success
        const paymentIntent = passedData.paymentIntent
        const successMessage = passedData.successMessage

        this.setState({ purchased, success, paymentIntent, successMessage })

        if (this.props.passProductData) {
          this.props.passProductData()
        }
      }

      // this.setState({ product, recipientType: null })
      // this.props.closeModal()

    }

    render() {

      return (
          <div>
            {(this.state.selectedIdea) && (
              <div>
                <div>
                  <div className="fixed-column-30 height-40" />
                  <div className="calc-column-offset-60">
                    <p className="heading-text-2 full-width center-text top-margin-20">{this.state.selectedIdea.name}</p>
                  </div>
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.props.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <p className="description-text-1 top-margin center-text">{this.state.selectedIdea.stage}</p>

                <div className="spacer" /><div className="half-spacer" />

                {(this.state.purchased) ? (
                  <div className="top-margin-30">
                    <p className="cta-color full-width center-text">You've successfully purchased this idea! Check your email for a confirmation of your purchase!</p>
                    <p className="full-width center-text top-margin-20">To view all of your ideas, both purchased and sold, visit the "my ideas "section of your profile <Link to={"/app/business-ideas"} target="_blank">here</Link>.</p>
                  </div>
                ) : (
                  <div>
                    <div className="row-10">

                      {(this.state.importedEmailId) ? (
                        <div>
                          <div className="row-10">
                            <div className="fixed-column-30">
                              <img src={(this.state.pictureURL) ? this.state.pictureURL : checkmarkIcon} alt="GC" className="profile-thumbnail-20" />
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-3 top-padding-3">You're logged in as {this.state.emailId}. If this isn't you, <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal}>log out</button>.</p>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          <hr />

                          <div className="spacer" />

                          <SubIdeaSummary selectedIdea={this.state.selectedIdea} includedItems={this.props.includedItems} history={this.props.navigate} />

                        </div>
                      ) : (
                        <div>
                          {(this.state.loginType === 'SignUp') ? (
                            <div>
                              <div className="row-10">
                                <p className="description-text-1 top-padding-5">Sign Up to Purchase</p>
                              </div>

                            </div>
                          ) : (
                            <div>
                              <div className="row-10">
                                <p className="description-text-1 top-padding-5">Login to Purchase</p>
                              </div>
                            </div>
                          )}

                          <SubLogInFields history={this.props.navigate}
                            formChangeHandler={this.formChangeHandler} passData={this.passData}
                            onlyFields={true} logout={this.logout} type={this.state.loginType}
                            loggedIn={this.state.loggedIn} emailId={this.state.emailId}
                            orgCode={this.state.activeOrg} closeModal={this.props.closeModal}
                            skipCloseModal={true}
                          />

                          {(this.state.loginType === 'SignUp') ? (
                            <div className="row-10">
                              <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >Log In</button> to import your information</p>
                            </div>
                          ) : (
                            <div className="row-10">
                              <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                            </div>
                          )}

                        </div>
                      )}

                      <div className="row-10">
                        <hr />
                      </div>

                      {(this.state.emailId) && (
                        <div>
                          <div className="top-padding-20">
                            <PreCheckoutIdea
                              history={this.props.navigate} passProductData={this.passProductData} pageSource="landingPage"
                              price={this.state.selectedIdea.price} productName={this.state.selectedIdea.name}
                              product={this.state.selectedIdea} orgCode={this.state.activeOrg}
                              firstName={this.state.cuFirstName} lastName={this.state.cuLastName}
                              email={this.state.emailId} username={this.state.username} pictureURL={this.state.pictureURL}
                              orgName={this.state.orgName}
                              accountCode={this.state.accountCode} employerName={this.state.employerName}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              </div>
            )}
            {/*
            <div className="top-margin-20">
              <button className={((this.state.recipientType === 'Me' && this.state.emailId) || this.state.recipientType === 'Someone Else') ? "btn btn-primary right-margin-5" : "btn btn-primary medium-background standard-border right-margin-5"} disabled={((this.state.recipientType === 'Me' && this.state.emailId) || this.state.recipientType === 'Someone Else') ? false : true} onClick={() => console.log('')}>Book</button>
              <button className="btn btn-secondary left-margin-5" onClick={() => this.props.closeModal()}>Close View</button>
            </div>*/}
          </div>

      )
    }
}

export default PurchaseIdea;
