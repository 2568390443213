import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';
import SubRenderPosts from '../Common/RenderPosts';
import SubCreatePost from '../Common/CreatePost';
import SubTakeBasicAssessment from '../Common/TakeBasicAssessment';
import SubChangePassword from '../Common/ChangePassword';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {requestConnection} from '../Services/FriendRoutes';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const assigneeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignee-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-dark.png';
const assignmentIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png';
const problemIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-dark.png';
const challengeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const targetIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';
const dropdownArrow = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const arrowIndicatorIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon-blue.png";
// const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const appleStoreBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/AppleStoreBadge.png';
const googlePlayBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/GooglePlayBadge.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class NewsFeed extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showCorrentTimeAdjustment: true,

          posts: [],
          tags: [],
          favoriteIds: [],
          newFavorites: [],
          showSubFunction: true,

          checklistTracker: [],
          profileChecklist: [],
          fewPeopleFollowed: true,
          fewProjectsFollowed: true,
          fewGroupsJoined: true,
          fewEmployersFollowed: true,
          postFilterValue: 'Everyone'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.followPerson = this.followPerson.bind(this)

        this.filterPosts = this.filterPosts.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.pullCourses = this.pullCourses.bind(this)
        this.closeHeader = this.closeHeader.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const userId = localStorage.getItem('email');
      const category = localStorage.getItem('activeOrg');
      const action = 'opened_newsfeed'

      if (userId && category) {
        // ReactGA.initialize('UA-67259774-5', { debug: true, titleCase: false, gaOptions: { userId }});
        // ReactGA.event({ category, action, label: action });

        ReactGA.initialize([
          {
            trackingId: "376007762",
            gaOptions: { userId }, // optional
            // gtagOptions: {...}, // optional
          },
          // {
          //   trackingId: "your second GA measurement id",
          // },
        ]);

        ReactGA.event({
          category,
          action,
          label: action , // optional
          // value: 99, // optional, must be a number
          // nonInteraction: true, // optional, true/false
          // transport: "xhr", // optional, beacon/xhr/image
        });
      }

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subNewsFeed', this.props)

      if (this.props.postId !== prevProps.postId) {
        this.retrieveData()
      }

      // if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
      //   console.log('t0 will update')
      //   this.retrieveData()
      // // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      // //   this.retrieveData()
      // }
    }

    retrieveData() {
      console.log('retrieveData called in subNewsFeed')
      // localStorage.setItem('remoteAuth', 'true')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('accountCode');
      let studentAliasPlural = localStorage.getItem('studentAliasPlural');
      let mentorAliasPlural = localStorage.getItem('mentorAliasPlural');
      const temporaryPassword = localStorage.getItem('temporaryPassword');
      // console.log('show orgName --: ', orgName, roleName, accountCode)

      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      let surveyReady = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        surveyReady = true
        // accountCode = null
      } else {
        accountCode = null
      }

      const passedPostId = this.props.postId

      let modalIsOpen = null
      let showChangePassword = null
      if (temporaryPassword) {
        modalIsOpen = true
        showChangePassword = true
      }

      const okrs = [
        { _id: '123', objective: { title: "Grow Revenues 2x", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Sales','Marketing','Social Media']},
        { _id: '123', objective: { title: "Expand Into New Markets", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Business Development','Mergers & Acquisitions','Corporate Development','Strategy','Finance']},
      ]

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      }

      const profileChecklist = [
        { name: 'Saved Basic Info', type: 'link', url: prefix + '/edit-profile/basics', passedState: {}},
        { name: 'Uploaded Resume', type: 'link', url: prefix + '/edit-profile/basics', passedState: {}},
        { name: 'Taken a Career Assessment', type: 'link', url: prefix + '/assessments', passedState: {}},
        { name: 'Received a Competency Endorsement', type: 'link', url: prefix + '/endorsements', passedState: {}},
        { name: 'Added a Project', type: 'link', url: prefix + '/edit-profile/profile-details', passedState: {}},
        { name: 'Added a Goal', type: 'link', url: prefix + '/edit-goals/new', passedState: { editExisting: false }},
        { name: 'Connected with 3+ People', type: 'link', url: prefix + '/community', passedState: {}},
        { name: 'Favorited 3+ Items', type: 'link', url: prefix + '/community', passedState: {}},
        { name: 'Applied to an Opportunity', type: 'link', url: prefix + '/opportunities', passedState: {}},
        { name: 'Posted an Update', type: 'button', url: prefix + '/edit-profile/basics', passedState: { modalIsOpen: true, showPost: true }},
      ]

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, orgName, roleName,
        passedPostId, modalIsOpen, showChangePassword,
        accountCode, employerAccount, surveyReady, okrs, prefix, profileChecklist,
        studentAliasPlural, mentorAliasPlural
      })

      Axios.get('/api/partners', { params: { partnerEmail: emailId, active: true } })
      .then((response) => {
        console.log('Partner query attempted', response.data);

        if (response.data.success) {
          console.log('partner query worked')

          if (response.data.partners && response.data.partners.length !== 0) {
            let pairings = []
            let pairingEmails = []

            // assume one mentor
            if (response.data.partners && response.data.partners[0]) {
              for (let i = 1; i <= response.data.partners.length; i++) {
                // pairings.push(response.data.partners[i - 1])
                if (window.location.pathname.startsWith('/advisor')) {
                  pairingEmails.push(response.data.partners[i - 1].adviseeEmail)
                } else {
                  pairingEmails.push(response.data.partners[i - 1].advisorEmail)
                }
              }

              for (let i = 1; i <= pairingEmails.length; i++) {

                // const index = i - 1

                Axios.get('/api/users/profile/details', { params: { email: pairingEmails[i - 1] } })
                .then((response) => {
                  console.log('Profile query attempted', response.data);

                  if (response.data.success) {
                    console.log('profile query worked')

                    // let pairing = pairings[index]
                    // // console.log('show data: ', partner, response.data)
                    //
                    // pairing['firstName'] = response.data.user.pictureURL
                    // pairing['lastName'] = response.data.user.pictureURL
                    // pairing['email'] = response.data.user.pictureURL
                    // pairing['pictureURL'] = response.data.user.pictureURL
                    // pairing['username'] = response.data.user.username
                    // pairing['school'] = response.data.user.school
                    // pairing['gradYear'] = response.data.user.gradYear
                    // pairing['education'] = response.data.user.education
                    //
                    // pairings[index] = pairing
                    pairings.push(response.data.user)
                    this.setState({ pairings })

                  } else {
                    console.log('query for profile query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Profile query did not work for some reason', error);
                });

              }
            }
          }

        } else {
          console.log('query for partners query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Partners query did not work for some reason', error);
      });

      const resLimit = 4
      const self = this
      let orgCode = activeOrg
      if (employerAccount) {
        orgCode = null
      }

      function pullOpps(postTypes, placementPartners, postType, pathway, accountCode, recent, active, pullPartnerPosts) {
        console.log('pullOpps called', postTypes, placementPartners)

        Axios.get('/api/postings/user', { params: { orgCode, placementPartners, postType, postTypes, pathway, accountCode, recent, active, excludeUnlisted: true, pullPartnerPosts, resLimit, csWorkflow: true } })
        .then((response) => {
          console.log('Posted problems query attempted within subcomponent', response.data);

          if (response.data.success) {
            console.log('posted postings query worked')

            if (postTypes && postTypes.includes('Work')) {
              let workOpportunities = response.data.postings
              self.setState({ workOpportunities })
            } else {
              let projectOpportunities = response.data.postings
              self.setState({ projectOpportunities })
            }

            if (!postTypes.includes('Work')) {
              postTypes = ['Internship','Work']
              pullOpps(postTypes, placementPartners, postType, pathway, accountCode, recent, active, pullPartnerPosts)
            }

          } else {
            console.log('posted postings query did not work', response.data.message)

            if (!postTypes.includes('Work')) {
              postTypes = ['Internship','Work']
              pullOpps(postTypes, placementPartners, postType, pathway, accountCode, recent, active, pullPartnerPosts)
            }
          }

        }).catch((error) => {
            console.log('Posted postings query did not work for some reason', error);
        });
      }

      function pullAdditionalInfo(friendIds, activeFriendIds, groupIds) {
        console.log('pullAdditionalInfo called', friendIds, activeFriendIds, groupIds)

        Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
         .then((response) => {
           console.log('query for profile data worked in newsfeed');

           if (response.data.success) {

             console.log('profile data received', response.data)

             const pictureURL = response.data.user.pictureURL
             const headline = response.data.user.headline
             const postPreferences = response.data.user.postPreferences
             const postReports = response.data.user.postReports

             let checklistTracker = self.state.checklistTracker
             if (response.data.user.school || (response.data.user.education && response.data.user.education.length > 0)) {
               checklistTracker.push('Saved Basic Info')
             }
             if (response.data.user.resumeURL && response.data.user.resumeURL !== '') {
               checklistTracker.push('Uploaded Resume')
             }

             if (response.data.user.favoritesArray && response.data.user.favoritesArray.length > 2) {
               checklistTracker.push('Favorited 3+ Items')
             }

             let excludePostIds = []
             if (postPreferences) {
               for (let i = 1; i <= postPreferences.length; i++) {
                 excludePostIds.push(postPreferences[i - 1].postId)
               }
             }
             if (postReports) {
               for (let i = 1; i <= postReports.length; i++) {
                 excludePostIds.push(postReports[i - 1].postId)
               }
             }

             const authType = response.data.user.authType
             const oauthUid = response.data.user.oauthUid

             self.setState({ pictureURL, headline, postPreferences, postReports, checklistTracker, excludePostIds, activeFriendIds })

             // const groupIds = ['']

             let postQueryParams = { groupId: null, orgCode, accountCode, excludePostIds, friendIds: activeFriendIds, emailId, authType, oauthUid, groupIds }
             if (window.location.pathname.includes('/my-social-posts')) {
               postQueryParams = { groupId: null, orgCode, accountCode, onlyCUPosts: true, emailId }
             }

             Axios.get('/api/group-posts', { params: postQueryParams })
             .then((response) => {
                console.log('Group posts query attempted in newsfeed', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved group posts in newsfeed')

                  let posts = []
                  if (response.data.groupPosts) {
                    posts = response.data.groupPosts

                    const pinnedIndex = posts.findIndex(x => x.pinned === true);
                    if (pinnedIndex > -1) {
                      const pinnedPost = posts[pinnedIndex]
                      posts.splice(pinnedIndex,1)
                      posts.unshift(pinnedPost)
                    }

                    // console.log('show pinnedPost: ', pinnedPost)
                  }
                  console.log('got the posts? ')
                  self.setState({ posts })

                  if (passedPostId) {

                    Axios.get('/api/group-posts/byid', { params: { _id: passedPostId } })
                    .then((response) => {
                       console.log('Group post query attempted', response.data);

                       if (response.data.success) {
                         console.log('successfully retrieved group post')

                         if (response.data.groupPost) {
                           self.setState({ passedGroupPost: response.data.groupPost })
                         }
                       }
                    }).catch((error) => {
                        console.log('Group post query did not work', error);
                    });

                    // Axios.get('/api/comments', { params: { parentPostId: passedPostId } })
                    // .then((response) => {
                    //   console.log('Comments query attempted', response.data);
                    //
                    //    if (response.data.success) {
                    //      console.log('successfully retrieved comments')
                    //
                    //      const comments = response.data.comments
                    //      self.setState({ comments })
                    //
                    //    } else {
                    //      console.log('no comments data found', response.data.message)
                    //      self.setState({ comments: [] })
                    //    }
                    // }).catch((error) => {
                    //    console.log('Comments query did not work', error);
                    //    self.setState({ comments: [] })
                    // });
                  }

                }
             }).catch((error) => {
                 console.log('Group posts query did not work', error);
             });

           } else {
             console.log('error response', response.data)
           }

         }).catch((error) => {
             console.log('query for profile info did not work', error);
         })

         let roleNames = ['Student','Career-Seeker']
         if (employerAccount) {
           roleNames = ['Employee']
         }

        Axios.get('/api/suggested-people', { params: { orgCode, accountCode, resLimit, emailId, roleNames, friendIds } })
        .then((response) => {
          console.log('Suggested people query attempted', response.data);

            if (response.data.success) {
              console.log('suggested people query worked')

              const suggestedPeople = response.data.users
              self.setState({ suggestedPeople })

            } else {
              console.log('suggested people query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Suggested people query did not work for some reason', error);
        });
      }

      function pullFriends(groupIds) {
        console.log('pullFriends called', groupIds)

        Axios.get('/api/friends', { params: { orgCode, accountCode, emailId } })
        .then((response) => {
          console.log('Friends query attempted', response.data);

            if (response.data.success) {
              console.log('friends query worked')

               const friends = response.data.friends
               self.setState({ friends })

               let friendIds = []
               let activeFriendIds = []
               for (let i = 1; i <= friends.length; i++) {
                 let friendEmail = null
                 if (friends[i - 1].friend1Email === emailId) {
                     friendEmail = friends[i - 1].friend2Email
                 } else {
                   friendEmail = friends[i - 1].friend1Email
                 }

                 friendIds.push(friendEmail)
                 if (friends[i - 1].active) {
                   activeFriendIds.push(friendEmail)
                 }
               }

               let postFilterValue = self.state.postFilterValue
               if (activeFriendIds.length > 1) {
                 postFilterValue = 'Connections'
                 self.setState({ postFilterValue })
               }

               let checklistTracker = self.state.checklistTracker
               if (activeFriendIds.length > 2) {
                 checklistTracker.push('Connected with 3+ People')
                 self.setState({ checklistTracker })
               }

               pullAdditionalInfo(friendIds, activeFriendIds, groupIds)

            } else {
              console.log('friends query did not work', response.data.message)
              pullAdditionalInfo(null, null, groupIds)
            }

        }).catch((error) => {
            console.log('Friends query did not work for some reason', error);
        })
      }

      Axios.get('/api/groups', { params: { orgCode, accountCode, resLimit, emailId, type: 'myGroups' }})
      .then((response) => {
        console.log('Groups query for which user is a member', response.data);

        if (response.data.success) {

          let groupIds = ['']
          for (let i = 1; i <= response.data.groups.length; i++) {
            groupIds.push(response.data.groups[i - 1]._id)
          }

          pullFriends(groupIds)

        } else {
          console.log('no groups data found for which user is a member', response.data.message)
          pullFriends([''])
        }

      }).catch((error) => {
          console.log('Groups query did not work for which user is a member', error);
      });

      Axios.get('/api/groups', { params: { orgCode, accountCode, resLimit, excludeCurrentUser: true, emailId }})
      .then((response) => {
        console.log('Groups query worked for which user is not a member', response.data);

        if (response.data.success) {

          const groups = response.data.groups
          this.setState({ groups })

        } else {
          console.log('no groups data found for which user is not a member', response.data.message)
        }

      }).catch((error) => {
          console.log('Groups query did not work for which user is not a member', error);
      });


      function pullUnfavoritedItems(favoriteIds) {
        console.log('unfavoritedItems called')

        if (employerAccount) {

          const postType = "Event"
          let postTypes = ['Event']
          const pathway = null
          const recent = true
          const active = true

          // pulling everything to get upcoming events
          Axios.get('/api/postings/user', { params: { postType, postTypes, pathway, accountCode, recent, active } })
          .then((response) => {
            console.log('Posted events query attempted within subcomponent as employer', response.data);

            if (response.data.success) {
              console.log('posted postings query worked')

              let events = []
              if (response.data.postings) {
                for (let i = 1; i <= response.data.postings.length; i++) {

                  if (response.data.postings[i - 1].startDate && new Date(response.data.postings[i - 1].startDate)) {

                    let dateToTest = null
                    if (response.data.postings[i - 1].endDate && new Date(response.data.postings[i - 1].endDate)) {
                      dateToTest = response.data.postings[i - 1].endDate
                    } else if (response.data.postings[i - 1].startDate && new Date(response.data.postings[i - 1].startDate)) {
                      dateToTest = response.data.postings[i - 1].startDate
                    }

                    if (new Date().getTime() < new Date(dateToTest).getTime()) {
                      events.push(response.data.postings[i - 1])
                    }
                    // if ((new Date() - new Date(response.data.postings[i - 1].startDate)) > 0) {
                    //   // this event has passed
                    // } else {
                    //   // event is upcoming
                    //   events.push(response.data.postings[i - 1])
                    // }
                  }
                }
              }

              if (events.length > 0) {
                events.sort(function(a,b) {
                  return new Date(a.startDate) - new Date(b.startDate);
                })

                // console.log('show first event: ', events.length, events[0])
                // events.splice(0,3)
              }
              // console.log('?events?', events)
              self.setState({ events })

            } else {
              console.log('posted postings query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Posted postings query did not work for some reason', error);
          });

          Axios.get('/api/customassessments/byid', { params: { _id: '635086da2291589901cab798' } })
          .then((response) => {
            console.log('Query custom assessment', response.data);

              if (response.data.success) {
                console.log('successfully retrieved custom assessment')

                const customAssessment = response.data.assessment
                const surveyQuestions = customAssessment.questions

                self.setState({ customAssessment, surveyQuestions })

              } else {
                console.log('no assessment data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Assessment query did not work', error);
          });

          self.pullCourses("Marketing", self.state.priceValue, self.state.durationValue, self.state.difficultyLevelValue, null, null, null, true)

        } else {
          Axios.get('/api/projects', { params: { orgCode, resLimit, emailId, favoriteIds } })
          .then((response) => {
            console.log('Projects query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved projects')

                if (response.data.projects) {

                  const projects = response.data.projects
                  self.setState({ projects })
                }

              } else {
                console.log('no project data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Project query did not work', error);
          });

          Axios.get('/api/org', { params: { orgCode } })
          .then((response) => {
            console.log('Org info query attempted for orgFocus on login', response.data);

              if (response.data.success) {
                console.log('org info query worked for orgFocus')

                const orgContactEmail = response.data.orgInfo.contactEmail
                const orgLogo = response.data.orgInfo.webLogoURIColor
                const orgName = response.data.orgInfo.orgName
                const orgMission = response.data.orgInfo.orgMission
                const hideNotificationHeader = response.data.orgInfo.hideMobileAppPrompt
                const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts

                self.setState({ orgContactEmail, orgLogo, orgName, orgMission, hideNotificationHeader,
                  disableChatbotPrompts
                })

                const placementPartners = response.data.orgInfo.placementPartners
                const postType = null
                let postTypes = ['Event']
                const pathway = null
                const accountCode = null
                const recent = true
                const active = true
                const pullPartnerPosts = true

                // pulling everything to get upcoming events
                Axios.get('/api/postings/user', { params: { orgCode, placementPartners, postType, postTypes, pathway, accountCode, recent, active, pullPartnerPosts, csWorkflow: true } })
                .then((response) => {
                  console.log('Posted events query attempted within subcomponent', response.data);

                  if (response.data.success) {
                    console.log('posted postings query worked')

                    let events = []
                    if (response.data.postings) {
                      for (let i = 1; i <= response.data.postings.length; i++) {
                        if (response.data.postings[i - 1].startDate && new Date(response.data.postings[i - 1].startDate)) {

                          if (self.state.showCorrentTimeAdjustment) {

                            let dateToTest = null
                            if (response.data.postings[i - 1].endDate && new Date(response.data.postings[i - 1].endDate)) {
                              dateToTest = response.data.postings[i - 1].endDate
                            } else if (response.data.postings[i - 1].startDate && new Date(response.data.postings[i - 1].startDate)) {
                              dateToTest = response.data.postings[i - 1].startDate
                            }

                            if (new Date().getTime() < new Date(dateToTest).getTime()) {
                              events.push(response.data.postings[i - 1])
                            }
                          } else {
                            const startDateDate = new Date(response.data.postings[i - 1].startDate)
                            const timeDifferenceUnadjusted = new Date().getTime() - startDateDate.getTime()
                            const timeZoneDifferenceMiliseconds = (startDateDate.getTimezoneOffset()) * 60000
                            const timeDifference = timeDifferenceUnadjusted - timeZoneDifferenceMiliseconds

                           if (timeDifference < 0) {
                             events.push(response.data.postings[i - 1])
                           }
                          }
                          // if ((new Date() - new Date(response.data.postings[i - 1].startDate)) > 0) {
                          //   // this event has passed
                          // } else {
                          //   // event is upcoming
                          //   events.push(response.data.postings[i - 1])
                          // }
                        }
                      }
                    }

                    if (events.length > 0) {
                      events.sort(function(a,b) {
                        return new Date(a.startDate) - new Date(b.startDate);
                      })

                      // console.log('show first event: ', events.length, events[0])
                      // events.splice(0,3)
                    }

                    self.setState({ events })

                    postTypes = ['Project','Assignment','Problem','Challenge']
                    pullOpps(postTypes, placementPartners, postType, pathway, accountCode, recent, active, pullPartnerPosts)

                  } else {
                    console.log('posted postings query did not work', response.data.message)
                    postTypes = ['Project','Assignment','Problem','Challenge']
                    pullOpps(postTypes, placementPartners, postType, pathway, accountCode, recent, active, pullPartnerPosts)
                  }

                }).catch((error) => {
                    console.log('Posted postings query did not work for some reason', error);
                });

                const employerQueryParams = { params: { orgCode, placementPartners, resLimit, favoriteIds }}
                console.log('show employerQueryParams: ', employerQueryParams)
                Axios.get('/api/employers', employerQueryParams)
                .then((response) => {
                  console.log('Employers query tried', response.data);

                  if (response.data.success) {

                    const employers = response.data.employers
                    self.setState({ employers })

                  } else {
                    console.log('no employer data found', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Employer query did not work', error);
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          const excludeMissingOutlookData = true
          const excludeMissingJobZone = true

          Axios.get('/api/careers', { params: { excludeMissingOutlookData, excludeMissingJobZone, resLimit, favoriteIds } })
          .then((response) => {
            console.log('Careers query worked', response.data);

            if (response.data.success) {

              const careers = response.data.careers
              self.setState({ careers })

            } else {
              console.log('no careers data found', response.data.message)
              self.setState({ animating: false })
            }

          }).catch((error) => {
              console.log('Careers query did not work', error);
              self.setState({ animating: false })
          });
        }

        Axios.get('/api/pathways', { params: { orgCode, accountCode, isActive: true } })
        .then((response) => {
          console.log('Pathways query attempted no 1', response.data);

          if (response.data.success) {
            console.log('pathway query worked no 1')

            if (response.data.pathways.length !== 0) {

              const pathways = response.data.pathways
              self.setState({ pathways });
            }

          } else {
            console.log('pathway query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathway query did not work for some reason', error);
        });
      }

      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved favorites', response.data.favorites.length)

            if (response.data.favorites.length > 0) {
              console.log('the array is greater than 0', response.data.favorites.length)

              const favoriteIds = response.data.favorites

              let newFavorites = []
              if (response.data.newFavorites) {
                newFavorites = response.data.newFavorites
              }

              //query info on those favorites
              Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode: activeOrg, orgFocus } })
              .then((response2) => {
                console.log('Favorites detail query attempted', response2.data);

                if (response2.data.success) {
                  console.log('successfully retrieved favorites detail', response2.data.favorites)

                  //query info on those favorites
                  this.setState({
                    types: response2.data.types,
                    filteredTypes: response2.data.types,
                    favorites: response2.data.favorites,
                    filteredFavorites: response2.data.favorites,
                    favoriteIds, newFavorites
                  })

                } else {
                  console.log('no favorites detail data found', response2.data.message)
                }

              }).catch((error) => {
                  console.log('Favorites detail query did not work', error);
              });

              pullUnfavoritedItems(favoriteIds)
            } else {
              pullUnfavoritedItems()
            }

          } else {
            console.log('no favorites data found', response.data.message)
            pullUnfavoritedItems()
          }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

      // pull for checklist
      Axios.get('/api/assessment/results', { params: { emailId } })
       .then((response) => {
         console.log('query for assessment results worked');

         if (response.data.success) {

           console.log('actual assessment results', response.data)

           let checklistTracker = this.state.checklistTracker
           if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
             checklistTracker.push('Taken a Career Assessment')
           } else if (response.data.results.interestScores && response.data.results.interestScores.length !== 0) {
             checklistTracker.push('Taken a Career Assessment')
           } else if (response.data.results.skillsScores && response.data.results.skillsScores.length !== 0) {
             checklistTracker.push('Taken a Career Assessment')
           } else if (response.data.results.personalityScores) {
             checklistTracker.push('Taken a Career Assessment')
           } else if (response.data.results.topGravitateValues && response.data.results.topEmployerValues) {
             checklistTracker.push('Taken a Career Assessment')
           } else if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0){
             checklistTracker.push('Taken a Career Assessment')
           }

           this.setState({ checklistTracker })

         }

     }).catch((error) => {
         console.log('query for assessment results did not work', error);
     })

     if (!employerAccount) {
       // pull for checklist
       Axios.get('/api/story', { params: { emailId } })
       .then((response) => {
           console.log('Endorsement query worked', response.data);

           if (response.data.success) {

             if (response.data.stories && response.data.stories.length > 0) {
               let checklistTracker = this.state.checklistTracker
               checklistTracker.push('Received a Competency Endorsement')
               this.setState({ checklistTracker })
             }
           }

       }).catch((error) => {
           console.log('Story query did not work', error);
       });

       // pull for checklist
       Axios.get('/api/projects', { params: { emailId, includeCollaborations: true } })
       .then((response) => {
         console.log('Projects query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved projects')

             if (response.data.projects && response.data.projects.length > 0) {

               let checklistTracker = this.state.checklistTracker
               checklistTracker.push('Added a Project')
               this.setState({ checklistTracker })

             }

           } else {
             console.log('no project data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Project query did not work', error);
       });
     }

     // pull for checklist and goals
     Axios.get('/api/logs/goals', { params: { emailId } })
     .then((response) => {

         if (response.data.success) {
           console.log('Goals received query worked', response.data);

           if (response.data.goals && response.data.goals.length > 0) {

             const goals = response.data.goals

             let mostActiveGoal = null
             if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
               for (let i = 1; i <= goals.length; i++) {
                 // most recent active goal
                 console.log('did we???? 1', mostActiveGoal)
                 if (goals[i - 1].createdAt && goals[i - 1].deadline && goals[i - 1].title && goals[i - 1].goalType) {
                   console.log('--1vars: ', goals[i - 1].deadline, typeof goals[i - 1].createdAt)
                   if (goals[i - 1].title.length > 3 && !goals[i - 1].title.toLowerCase().includes('test') && !goals[i - 1].title.toLowerCase().includes('example')) {
                     console.log('--vars: ', goals[i - 1].deadline, typeof goals[i - 1].createdAt)
                     if (new Date(goals[i - 1].deadline) > new Date()) {
                       mostActiveGoal = goals[i - 1]
                     }
                   }
                 }
               }
             }



             let checklistTracker = this.state.checklistTracker
             checklistTracker.push('Added a Goal')
             this.setState({ goals, checklistTracker, mostActiveGoal })

           }

         } else {
           console.log('no goal data found', response.data.message)
         }

     }).catch((error) => {
         console.log('Goal query did not work', error);
     });

     // pull for checklist
     Axios.get('/api/applications', { params: { emailId } })
     .then((response) => {
       console.log('Applications query attempted', response.data);

       if (response.data.success) {
         console.log('applications query worked')

         if (response.data.applications.length !== 0) {
           let checklistTracker = this.state.checklistTracker
           checklistTracker.push('Applied to an Opportunity')
           this.setState({ checklistTracker })
         }

       } else {
         console.log('query for applications query did not work', response.data.message)
       }

     }).catch((error) => {
         console.log('Applications query did not work for some reason', error);
     });

     // pull for checklist
     Axios.get('/api/group-posts', { params: { groupId: null, orgCode: null, onlyCUPosts: true, emailId } })
     .then((response) => {
        console.log('Group posts query attempted for CU', response.data);

        if (response.data.success) {
          console.log('successfully retrieved group posts')

          if (response.data.groupPosts && response.data.groupPosts.length > 0) {
            let checklistTracker = this.state.checklistTracker
            checklistTracker.push('Posted an Update')
            this.setState({ checklistTracker })
          }

        }
     }).catch((error) => {
         console.log('Group posts query did not work', error);
     });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      let excludeOrgCourses = false
      if (sources && !sources.includes('OrgCourses')) {
        orgCode = null
        excludeOrgCourses = true
      }

      if (sources && sources.length === 1 && sources[0] === 'Udemy') {
        queryUdemyCourses = true
        if (!searchValue) {
          searchValue = "Marketing"
        }
      } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
        queryUdemyCourses = false
      }
      const courseQueryObject = { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 }
      // console.log('show courses queryObject: ', courseQueryObject)
      Axios.get('/api/courses/search', { params: courseQueryObject })
      .then((response) => {
        console.log('Search courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              const filteredCourses = courses
              this.setState({ courses, filteredCourses, sources, queryUdemyCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ sources, queryUdemyCourses, animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ sources, animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showShareButtons: false, showPost: false, showComments: false,
        sharePosting: false, showUpvotes: false, passedGroupPost: null, adjustFeedPreferences: false,
        reportPostView: false, showDeletePost: false, showReports: false, originalPost: null,
        showSignUpFields: false, showSurvey: false, showChangePassword: false
      })
    }

    followPerson(person) {
      console.log('followPerson called', person)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      let recipientHeadline = person.headline
      if (!person.headline) {
        if (person.education && person.education.length > 0) {
          recipientHeadline = person.education[0].name
        }
      }
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      let friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName }

      // Axios.post('/api/friend/request', friend)
      // .then((response) => {
      //
      //   if (response.data.success) {
      //
      //     friend['active'] = false
      //     friend['friend2Email'] = recipientEmail
      //
      //     let friends = this.state.friends
      //     friends.push(friend)
      //     console.log('show friends: ', friends)
      //     this.setState({ successMessage: response.data.message, friends })
      //
      //   } else {
      //
      //     this.setState({ errorMessage: response.data.message })
      //   }
      // }).catch((error) => {
      //     console.log('Advisee request send did not work', error);
      // });

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          if (friends) {
            friends.push(friend)
          } else {
            friends = [friend]
          }

          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type, this.state.favoriteIds.length)

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        type = type.toLowerCase()

        const returnedObject = await subFavoriteItem(item, this.state.favoriteIds, this.state.emailId, this.state.newFavorites,type)

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favoriteIds: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id
        let favoriteIds = this.state.favoriteIds

        if (favoriteIds.includes(itemId)){
          console.log('its included')
          let index = favoriteIds.indexOf(itemId)

          if (index > -1) {
            favoriteIds.splice(index, 1);
          }
        } else {
          favoriteIds.push(itemId)
        }

        console.log('show favorites: ', favoriteIds.length)

        Axios.post('/api/favorites/save', {
          favoritesArray: favoriteIds, emailId: this.state.emailId
        }).then((response) => {
          console.log('attempting to remove favorites')
          if (response.data.success) {
            console.log('saved removal from favorites', response.data)
            //clear values
            this.setState({ favoriteIds, successMessage: response.data.message, isSaving: false, tempId: itemId })
          } else {
            console.log('did not save successfully')
            this.setState({ favoriteIds, errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ favoriteIds, errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }
    }

    filterPosts(postFilterValue, groupId, orgCode, excludePostIds, friendIds, emailId) {
      console.log('filterPosts called', postFilterValue)

      this.setState({ postsAreLoading: true })
      let postQueryParams = { }
      if (postFilterValue === 'Everyone') {
        postQueryParams = { groupId: null, orgCode, excludePostIds, emailId }
      } else if (postFilterValue === 'Connections') {
        postQueryParams = { groupId: null, orgCode, excludePostIds, friendIds, emailId }
      } else if (postFilterValue === 'Admin') {
        postQueryParams = { groupId: null, orgCode, excludePostIds, friendIds, emailId, adminOnly: true }
      }

      Axios.get('/api/group-posts', { params: postQueryParams })
      .then((response) => {
         console.log('Group posts query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved group posts')

           let posts = []
           if (response.data.groupPosts) {
             posts = response.data.groupPosts
             const pinnedIndex = posts.findIndex(x => x.pinned === true);
             const pinnedPost = posts[pinnedIndex]
             posts.splice(pinnedIndex,1)
             posts.unshift(pinnedPost)
             // console.log('show pinnedPost: ', pinnedPost)
           }

           this.setState({ posts, postsAreLoading: false, showPostFilterMenu: false, postFilterValue })

         } else {
           this.setState({ postsAreLoading: false, showPostFilterMenu: false, postFilterValue })
         }
      }).catch((error) => {
          console.log('Group posts query did not work', error);
          this.setState({ postsAreLoading: false, showPostFilterMenu: false, postFilterValue })
      });
    }

    passPosts(posts) {
      console.log('passPosts called')
      this.setState({ posts })
    }

    closeHeader() {
      console.log('closeHeader called')
      this.setState({ surveyReady: false, hideNotificationHeader: true })
    }

    commandClicked(command) {
      console.log('commandClicked in newsfeed', command)

      this.props.commandClicked(command)

    }

    render() {

      return (
          <div>
            {(this.state.employerAccount && this.state.surveyReady) && (
              <div className="washed-error-background-color row-30">
                <div className="container-left top-margin-5">
                  <p className="full-width right-text description-text-3">New Engagement Survey Ready</p>
                  <p className="full-width right-text">How You Doin?</p>
                </div>
                <div className="container-left top-margin-5">
                  <div className="calc-column-offset-40 left-padding-30">
                    <button className="btn btn-squarish-dark" onClick={() => this.setState({ modalIsOpen: true, showSurvey: true })}>Take It Now</button>
                  </div>
                  <div className="fixed-column-40 top-margin">
                    <a onClick={() => this.closeHeader()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </a>
                  </div>
                  <div className="clear"/>
                </div>
                <div className="clear" />
              </div>
            )}
            {(window.innerWidth < 768 && window.location.pathname.includes('/app') && toggleVars.showMobileApps && !this.state.hideNotificationHeader) && (
              <div className="washed-error-background-color row-30">
                <div className="top-margin-5">
                  <div className="fixed-column-30 height-30">
                  </div>
                  <div className="calc-column-offset-60 horizontal-padding">
                    <p className="full-width center-text heading-text-5">Consider Downloading the iOS or Android Mobile App</p>
                    <p className="full-width center-text description-text-1 top-padding">[Sign in with the same username / password]</p>
                  </div>
                  <div className="fixed-column-30 right-padding">
                    <a onClick={() => this.closeHeader()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </a>
                  </div>
                  <div className="clear" />
                </div>
                <div className="row top-margin-20">
                  <ul className="store-list">
                    <div className="store-list-item"><li>
                      <a className="background-button-white" href="https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8" target="_blank" rel="noopener noreferrer">
                        <img src={appleStoreBadge} alt="Compass apple store badge icon" className="apple-store-badge" />
                      </a>
                    </li></div>
                    <div className="store-list-item"><li>
                      <a className="background-button-white" href="https://play.google.com/store/apps/details?id=com.guidedcompass" target="_blank" rel="noopener noreferrer">
                        <img src={googlePlayBadge} alt="Compass google play badge icon" className="google-play-badge" />
                      </a>
                    </li></div>
                  </ul>
                </div>
                <div className="clear" />
              </div>
            )}
            <div className="top-margin-20">
              {(window.innerWidth > 768 && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor/news-feed')) && (
                <div className="relative-column-30 horizontal-padding">
                  {(window.location.pathname.includes('/my-social-posts')) ? (
                    <div className="width-30 height-30">
                    </div>
                  ) : (
                    <div className="right-padding-8-percent">

                      <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                        <div className="full-width height-5 primary-background" />

                        <Link to={this.state.prefix + '/edit-profile'} className="background-button full-width standard-color">
                          <div className="top-padding-20">
                            <div className="row-10">
                              <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} className="profile-thumbnail-6 center-horizontally" alt="GC" />
                            </div>
                          </div>
                          <div className="row-5 center-text">
                            <p className="heading-text-6">{this.state.cuFirstName} {this.state.cuLastName}</p>
                          </div>
                        </Link>

                        {(!this.state.employerAccount) && (
                          <div>
                            <div className="row-10 horizontal-padding profile-container-right">
                              <Link to={this.state.prefix + '/edit-profile'} className="background-button standard-color full-width">
                                <div className="progress-bar-thin" >
                                  <div className="filler" style={{ width: Math.min(100,Math.round((this.state.checklistTracker.length / this.state.profileChecklist.length) * 100)) + '%' }} />
                                </div>
                                <p className="description-text-4 description-text-color top-padding-5">Profile is {Math.min(100, (this.state.checklistTracker.length / this.state.profileChecklist.length) * 100)}% complete</p>
                              </Link>
                            </div>

                            <div className="profile-modal-right">
                              <p className="heading-text-5">Profile is {(this.state.checklistTracker.length / this.state.profileChecklist.length) * 100}% Complete</p>

                              <div className="spacer" />

                              {this.state.profileChecklist.map((value, index) =>
                                <div key={value}>
                                  {(this.state.profileChecklist[index].type === 'link') ? (
                                    <Link to={this.state.profileChecklist[index].url} state={this.state.profileChecklist[index].passedState} className="background-button standard-color full-width left-text">
                                      <div className="description-text-2 row-10">
                                        <div className="fixed-column-20">
                                          <p>{index + 1}.</p>
                                        </div>
                                        <div className="calc-column-offset-50">
                                          <p>{value.name}</p>
                                        </div>
                                        {(this.state.checklistTracker.includes(value.name)) && (
                                          <div className="fixed-column-30 left-padding">
                                            <div className="mini-spacer" /><div className="mini-spacer" />
                                            <img src={checkmarkIcon} alt="GC" className="image-auto-13" />
                                          </div>
                                        )}

                                        <div className="clear" />
                                      </div>
                                    </Link>
                                  ) : (
                                    <button onClick={() => this.setState(this.state.profileChecklist[index].passedState)} className="background-button full-width left-text">
                                      <div className="description-text-2 row-5">
                                        <div className="fixed-column-20">
                                          <p>{index + 1}.</p>
                                        </div>
                                        <div className="calc-column-offset-45">
                                          <p>{value.name}</p>
                                        </div>
                                        {(this.state.checklistTracker.includes(value.name)) && (
                                          <div className="fixed-column-25 left-padding">
                                            <div className="mini-spacer" /><div className="mini-spacer" />
                                            <img src={checkmarkIcon} alt="GC" className="image-auto-13" />
                                          </div>
                                        )}
                                        <div className="clear" />
                                      </div>
                                    </button>
                                  )}

                                </div>
                              )}

                            </div>
                          </div>
                        )}

                        <div className="row-10 horizontal-padding">
                          <hr className="cta-border" />
                        </div>

                        {(this.state.goals && this.state.goals.length > 0) ? (
                          <div className="row-10 horizontal-padding">
                            {this.state.goals.map((value, index) =>
                              <div key={value}>
                                {(index < 3) && (
                                  <div className="row-10">
                                    <Link className="background-button full-width standard-color profile-container-right" to={(window.location.pathname.includes('/advisor')) ? this.state.prefix + '/edit-logs/' + value._id : this.state.prefix + '/logs/' + value._id} state={{ editExisting: true, log: value }}>
                                      <div className="fixed-column-35 height-50">
                                        <div className="mini-spacer" /><div className="mini-spacer" />
                                        <img src={targetIconOrange} className="image-auto-18" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-2 curtail-text">{value.title}</p>
                                        <p className="description-text-4 top-padding-5">Due {convertDateToString(new Date(value.deadline),'datetime-2')}</p>
                                      </div>
                                      <div className="clear" />
                                    </Link>

                                    <div className="profile-modal-right">
                                      <div>

                                        <div className="bottom-padding">
                                          <img src={targetIconOrange} className="image-half-auto center-horizontally" alt="GC" />
                                        </div>
                                        <div className="row-10">
                                          <p className="heading-text-6 center-text full-width">{value.title}</p>
                                          <p className="description-text-2 center-text full-width">Due: {convertDateToString(new Date(value.deadline),'datetime-2')}</p>

                                          {(value.goalType) && (
                                            <p className="description-text-3 row-5">Goal Type: {value.goalType.description}</p>
                                          )}

                                          {(value.createdAt) && (
                                            <p className="description-text-3 row-5">Created on: {convertDateToString(new Date(value.createdAt),'datetime-2')}</p>
                                          )}

                                          {(value.intensity) && (
                                            <p className="description-text-3 row-5">Intensity: {value.intensity}</p>
                                          )}

                                          {(value.selectedCareers && value.selectedCareers.length > 0) && (
                                            <div className="row-5">
                                              {value.selectedCareers.map((value2, index2) =>
                                                <div key={value2}>
                                                  <p className="description-text-3 row-5">Career {index2 + 1}: {value2.name}</p>
                                                </div>
                                              )}

                                            </div>
                                          )}
                                        </div>

                                      </div>

                                      <div className="top-padding-20">
                                        <Link to={(window.location.pathname.includes('/advisor')) ? this.state.prefix + '/edit-logs/' + value._id : this.state.prefix + '/logs/' + value._id} state={{ editExisting: true, log: value }} className="background-button full-width"><button className="btn btn-squarish full-width">View Details</button></Link>
                                      </div>

                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <div>
                              <hr className="cta-border" />
                              <div className="spacer" />

                              <Link className="background-button center-text full-width row-10" to={this.state.prefix + '/edit-goals/new'} state={{ editExisting: false }}>
                                <p className="description-text-2">Add a Goal</p>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="row-10">
                            <img src={targetIconOrange} alt="GC" className="image-auto-38 center-horizontally" />

                            <div className="row-10 center-text horizontal-padding">
                              <p className="description-text-2">You're Goalless!</p>
                              <p className="description-text-4 row-5">Get your life together and aim for a brighter future!</p>
                            </div>

                            <div className="row-10 full-width description-text-2 center-text">
                              <Link to={this.state.prefix + "/edit-goals/new"} state={{ editExisting: false, logs: null }} className="background-button"><button className="btn btn-small center-horizontally"  onClick={() => console.log('')}>Add a Goal</button></Link>
                            </div>
                          </div>
                        )}

                        <div className="spacer" />

                      </div>

                      {(this.state.pairings && this.state.pairings.length > 0) && (
                        <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                          <div className="full-width height-5 primary-background" />
                          <div className="padding-20">

                            <p>Your Paired {(window.location.pathname.startsWith('/advisor')) ? (this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Mentees" : (this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "Mentors"}</p>
                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.pairings.map((value, index) =>
                              <div key={value}>
                                {(index < 3) && (
                                  <div className="row-10">
                                    <Link className="background-button full-width standard-color profile-container-right" to={(window.location.pathname.includes('/advisor')) ? this.state.prefix + '/profile/' + value.username : this.state.prefix + '/mentors/' + value.username}>
                                      <div className="fixed-column-35">
                                        <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <p className="description-text-2">{value.firstName} {value.lastName}</p>

                                      </div>
                                      <div className="clear" />
                                    </Link>

                                    <div className="profile-modal-right">
                                      <div>
                                        <div className="fixed-column-55">
                                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                                        </div>
                                        <div className="calc-column-offset-105">
                                          <p className="description-text-1 bold-text">{value.firstName} {value.lastName}</p>

                                          {(value.headline && value.headline !== '') ? (
                                            <div>
                                              <p className="description-text-3 description-text-color">{value.headline}</p>
                                            </div>
                                          ) : (
                                            <div>

                                              {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                                                <div>

                                                  <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                                                </div>
                                              ) : (
                                                <div>
                                                  <div>
                                                    <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="fixed-column-50">
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="top-padding-20">
                                        <Link to={this.state.prefix + '/messaging'} state={{ recipient: value }} className="background-button full-width"><button className="btn btn-squarish full-width">Message</button></Link>
                                      </div>

                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.fewEmployersFollowed) ? (
                        <div>
                          {(this.state.employers && this.state.employers.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Employers to Follow</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.employers.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="row-10">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/employers/' + value._id}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={(value.employerLogoURI) ? value.employerLogoURI : industryIconDark} className="profile-thumbnail-25" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-2">{value.employerName}</p>
                                            <p className="description-text-4 top-padding">{value.employerType}{(value.employerIndustry) && " | " + value.employerIndustry}</p>

                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div className="bottom-padding-5">
                                            <div className="fixed-column-35 height-50">
                                              <img src={(value.employerLogoURI) ? value.employerLogoURI : industryIconDark} className="profile-thumbnail-25" alt="GC" />
                                            </div>
                                            <div className="calc-column-offset-35">
                                              <p className="description-text-1 bold">{value.employerName}</p>
                                              <p className="description-text-2">{value.employerType}{(value.employerIndustry) && " | " + value.employerIndustry}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />

                                          {(value.description) && (
                                            <div className="row-5">
                                              <p className="description-text-3 max-text-lines max-height-30">Description: <label className="description-text-color">{value.description}</label></p>
                                              <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/employers/' + value._id}>See more</Link></p>
                                            </div>
                                          )}

                                          <div className="top-padding-20">
                                            <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} disabled={(this.state.isSaving) ? true : false} onClick={() => this.favoriteItem(value,'Employer')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Following" : "Follow"}</button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.employers.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-employers'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {(this.state.employers && this.state.employers.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Employers You Follow</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.employers.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="row-10">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/employers/' + value._id}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={(value.employerLogoURI) ? value.employerLogoURI : industryIconDark} className="profile-thumbnail-25" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-2">{value.employerName}</p>
                                            <p className="description-text-4 top-padding">{value.employerType}{(value.employerIndustry) && " | " + value.employerIndustry}</p>

                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div className="bottom-padding-5">
                                            <div className="fixed-column-35 height-50">
                                              <img src={(value.employerLogoURI) ? value.employerLogoURI : industryIconDark} className="profile-thumbnail-25" alt="GC" />
                                            </div>
                                            <div className="calc-column-offset-35">
                                              <p className="description-text-1">{value.employerName}</p>
                                              <p className="description-text-2">{value.employerType}{(value.employerIndustry) && " | " + value.employerIndustry}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />

                                          {(value.description) && (
                                            <p className="description-text-3 description-text-color">{value.description}</p>
                                          )}

                                          <div className="top-padding-20">
                                            <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} disabled={(this.state.isSaving) ? true : false} onClick={() => this.favoriteItem(value, 'Employer')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Following" : "Follow"}</button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.employers.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-employers'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.fewProjectsFollowed) ? (
                        <div>
                          {(this.state.projects && this.state.projects.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Projects To Follow</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.projects.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="row-10">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/projects/' + value._id}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={(value.imageURL) ? value.imageURL : projectsIconDark} className="image-25-auto" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-2 curtail-text">{value.name}</p>
                                            <p className="description-text-4 top-padding">{value.userFirstName} {value.userLastName}</p>

                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div>
                                            <div className="bottom-padding">
                                              <img src={(value.imageURL) ? value.imageURL : projectsIconDark} className="image-full-auto" alt="GC" />
                                            </div>
                                            <div className="row-10">
                                              <p className="heading-text-6 center-text full-width">{value.name}</p>
                                              <p className="description-text-1 center-text full-width">{value.userFirstName} {value.userLastName}</p>

                                              {(value.category) && (
                                                <p className="description-text-3 row-5">Category: {value.category}</p>
                                              )}

                                              {(value.hours) && (
                                                <p className="description-text-3 row-5">Hours: {value.hours}</p>
                                              )}

                                              {(value.skillTags) && (
                                                <p className="description-text-3 row-5">Skill Tags: {value.skillTags}</p>
                                              )}

                                              {(value.description) && (
                                                <div className="row-5">
                                                  <p className="description-text-3 max-text-lines max-height-30">Description: <label className="description-text-color">{value.description}</label></p>
                                                  <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/projects/' + value._id}>See more</Link></p>
                                                </div>
                                              )}
                                            </div>

                                          </div>

                                          <div className="top-padding-20">
                                            <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} disabled={(this.state.isSaving) ? true : false} onClick={() => this.favoriteItem(value,'Project')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Following" : "Follow"}</button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.projects.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-projects'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {(this.state.projects && this.state.projects.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Projects You Follow</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.fewPeopleFollowed) ? (
                        <div>
                          {(this.state.suggestedPeople && this.state.suggestedPeople.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>{(this.state.employerAccount) ? "Teammates" : "People to Follow"}</p>
                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.suggestedPeople.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="row-10">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/profile/' + value.username}>
                                          <div className="fixed-column-35">
                                            <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-25" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                            <p className="description-text-2">{value.firstName} {value.lastName}</p>
                                            {(value.headline) && (
                                              <p className="description-text-4 description-text-color curtail-text top-padding">{value.headline}</p>
                                            )}
                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div>
                                            <div className="fixed-column-55">
                                              <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                                            </div>
                                            <div className="calc-column-offset-55">
                                              <p className="description-text-1 bold-text">{value.firstName} {value.lastName}</p>

                                              {(value.headline && value.headline !== '') ? (
                                                <div>
                                                  <p className="description-text-3 description-text-color">{value.headline}</p>
                                                </div>
                                              ) : (
                                                <div>

                                                  {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                                                    <div>

                                                      <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <div>
                                                        <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          {(!this.state.employerAccount) && (
                                            <div className="top-padding-20">
                                              <button className="btn btn-squarish full-width" disabled={(this.state.isSaving) ? true : false} onClick={() => this.followPerson(value)}>Connect</button>
                                            </div>
                                          )}

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.suggestedPeople.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-profiles'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                            <div className="full-width height-5 primary-background" />
                            <div className="padding-20">
                              <p>People You Follow</p>
                              <div className="spacer" /><div className="half-spacer" />

                              {this.state.peopleYouFollow.map((value, index) =>
                                <div key={value}>
                                  {(index < 3) && (
                                    <div className="row-10">
                                      <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/profile/' + value.username}>
                                        <div className="fixed-column-35">
                                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-25" alt="GC" />
                                        </div>
                                        <div className="calc-column-offset-35">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <p className="description-text-2">{value.firstName} {value.lastName}</p>

                                        </div>
                                        <div className="clear" />
                                      </Link>

                                      <div className="profile-modal-right">
                                        <div>
                                          <div className="fixed-column-55">
                                            <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-105">
                                            <p className="description-text-1 bold-text">{value.firstName} {value.lastName}</p>

                                            {(value.headline && value.headline !== '') ? (
                                              <div>
                                                <p className="description-text-3 description-text-color">{value.headline}</p>
                                              </div>
                                            ) : (
                                              <div>

                                                {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                                                  <div>

                                                    <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <div>
                                                      <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )}

                                            <p className="description-text-3 description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                          </div>
                                          <div className="fixed-column-50">
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="top-padding-20">
                                          <Link to={this.state.prefix + '/messaging'} state={{ recipient: value }} className="background-button full-width"><button className="btn btn-squarish full-width">Message</button></Link>
                                        </div>

                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              {(this.state.peopleYouFollow.length > 3) && (
                                <div className="row-5">
                                  <Link className="background-button float-right" to={this.state.prefix + '/browse-profiles'}>
                                    <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                    <p className="description-text-3 float-right right-padding">See More</p>
                                  </Link>
                                  <div className="clear" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {(this.state.fewGroupsJoined) ? (
                        <div>
                          {(this.state.groups && this.state.groups.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Groups to Join</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.groups.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="row-10">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/groups/' + value._id}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={(value.pictureURL && value.pictureURL !== 'undefined') ? value.pictureURL : socialIconDark} className="image-25-auto top-margin-5" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-2">{value.name}</p>
                                            <p className="description-text-4 top-padding">{value.category}</p>

                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div>
                                            <div className="bottom-padding">
                                              <img src={(value.pictureURL && value.pictureURL !== 'undefined') ? value.pictureURL : socialIconDark} className="image-full-auto" alt="GC" />
                                            </div>
                                            <div className="row-10">
                                              <p className="center-text full-width">{value.name}</p>
                                              <p className="description-text-1 center-text full-width">{value.category}</p>

                                            </div>

                                          </div>

                                          <div className="top-padding-20">
                                            <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} onClick={() => this.favoriteItem(value,'Group')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Joined" : "Join"}</button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.groups.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-groups'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {(this.state.groups && this.state.groups.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />
                              <div className="padding-20">
                                <p>Your Groups</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.groups.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/groups/' + value._id}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={value.pictureURL} className="image-25-auto" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-1">{value.name}</p>
                                            <p className="description-text-2">{value.category}</p>

                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-right">
                                          <div>
                                            <div className="bottom-padding">
                                              <img src={value.pictureURL} className="image-full-auto" alt="GC" />
                                            </div>
                                            <div className="row-10">
                                              <p className="center-text full-width">{value.name}</p>
                                              <p className="description-text-1 center-text full-width">{value.category}</p>

                                            </div>

                                          </div>

                                          <div className="top-padding-20">
                                            <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} onClick={() => this.favoriteItem(value,'Group')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Joined" : "Join"}</button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.groups.length > 3) && (
                                  <div className="row-5">
                                    <Link className="background-button float-right" to={this.state.prefix + '/browse-groups'}>
                                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                      <p className="description-text-3 float-right right-padding">See More</p>
                                    </Link>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  )}

                </div>
              )}

              <div className={(window.innerWidth > 768 && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor/news-feed')) ? "relative-column-40" : 'full-width'}>

                {(window.location.pathname.includes('/my-social-posts')) && (
                  <div className="card-clear-padding bottom-margin-20">
                    <div className="full-width height-5 senary-background" />
                    <div className="padding-20 center-text">
                      <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} className="profile-thumbnail-6 center-horizontally" alt="GC" />
                      <p className="heading-text-4">{this.state.cuFirstName} {this.state.cuLastName}</p>
                      <p className="description-text-color">posts</p>
                    </div>

                  </div>
                )}
                <div className="card-clear-padding padding-20">
                  <div>
                    <div className="fixed-column-50">
                      {(window.location.pathname.includes('/organizations')) ? (
                        <img src={(this.state.orgLogo) ? this.state.orgLogo : profileIconDark} className="image-38-fit" alt="GC" />
                      ) : (
                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} className="profile-thumbnail-38" alt="GC" />
                      )}
                    </div>
                    <div className="calc-column-offset-50">
                      <div className="light-background slightly-rounded-corners">
                        <button className="background-button full-space left-text row-10 description-text-2 left-padding" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>
                          <p className="description-text-1 description-text-color">Share an update with your community...</p>
                        </button>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                {(this.state.posts && this.state.posts.length > 0) && (
                  <div>

                    {(!window.location.pathname.includes('/my-social-posts')) && (
                      <div>
                        <div className="top-padding-20">
                          <div className="calc-column-offset-175 top-padding-5">
                            <hr />
                          </div>
                          <div className="fixed-column-160 description-text-4 curtail-text">
                            <button className="background-button full-width clear-margin clear-padding top-margin-negative-10" onClick={(this.state.showPostFilterMenu) ? () => this.setState({ showPostFilterMenu: false }) : () => this.setState({ showPostFilerMenu: true })}>
                              <p className="full-width right-text">Filter: Posts from <label className="bold-text">{(this.state.postFilterValue === 'Admin') ? this.state.orgName : this.state.postFilterValue}</label></p>
                            </button>
                          </div>
                          <div className="fixed-column-15 top-margin-negative-5">
                            <button className="background-button full-width" onClick={(this.state.showPostFilterMenu) ? () => this.setState({ showPostFilterMenu: false }) : () => this.setState({ showPostFilterMenu: true })}>
                              <img src={dropdownArrow} alt="GC" className="image-auto-8 pin-right" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.showPostFilterMenu) && (
                          <div className="menu-bottom-2 description-text-3">
                            <div>
                              <button className="background-button full-width left-text" onClick={() => this.filterPosts('Everyone', null, this.state.activeOrg, this.state.excludePostIds, this.state.activeFriendIds, this.state.emailId)}>
                                <div className="row-5">
                                  <div className="fixed-column-25">
                                    <img src={socialIconDark} alt="GC" className="image-auto-15" />
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p>Everyone</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                              <button className="background-button full-width left-text" onClick={() => this.filterPosts('Connections', null, this.state.activeOrg, this.state.excludePostIds, this.state.activeFriendIds, this.state.emailId)}>
                                <div className="row-5">
                                  <div className="fixed-column-25">
                                    <img src={assigneeIconDark} alt="GC" className="image-auto-15" />
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p>Connections</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                              <button className="background-button full-width left-text" onClick={() => this.filterPosts('Admin', null, this.state.activeOrg, this.state.excludePostIds, this.state.activeFriendIds, this.state.emailId)}>
                                <div className="row-5">
                                  <div className="fixed-column-25">
                                    <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className="image-15-fit" />
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p>{this.state.orgName}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.postsAreLoading) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <div className="super-spacer" />

                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Searching...</p>

                        </div>
                      </div>
                    ) : (
                      <div>
                        <SubRenderPosts posts={this.state.posts} passedGroupPost={this.state.passedGroupPost} mostActiveGoal={this.state.mostActiveGoal} commandClicked={this.commandClicked} disableChatbotPrompts={this.state.disableChatbotPrompts} />
                      </div>
                    )}
                  </div>
                )}

              </div>

              {(window.innerWidth > 768 && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor/news-feed') && !window.location.pathname.includes('/my-social-posts')) && (
                <div className="relative-column-30 horizontal-padding">
                  <div className="left-padding-8-percent">

                    {(this.state.employerAccount && this.state.okrs && this.state.okrs.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">OKRs</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.okrs.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="bottom-padding">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/okrs'}>
                                    <div className="fixed-column-35 height-50">
                                      <img src={targetIconDark} className="image-25-fit" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">
                                      <p className="description-text-1">{value.objective.title}</p>
                                      <p className="description-text-3">{value.objective.endDate}</p>
                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="full-width">
                                        <p className="description-text-1">{value.objective.title}</p>
                                        <p className="description-text-4 description-text-color">{value.objective.startDate} - {value.objective.endDate}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    <div className="spacer" />

                                    {value.results.map((value2, index2) =>
                                      <div key={value2} className="top-margin">
                                        <p className="description-text-3">{index2 + 1}. {value2.title}</p>
                                        <p className="description-text-5 description-text-color top-padding-5">{value2.priority} Priority | {value2.startDate} - {value2.endDate} | {value2.teams.toString()}</p>
                                        <p className="description-text-5 top-padding-5">Status: {value2.status}</p>
                                      </div>
                                    )}

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.okrs.length > 0) && (
                            <div>
                              <Link className="background-button" to={this.state.prefix + '/okrs'} state={{ subNavSelected: 'Work' }}>
                                <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.workOpportunities && this.state.workOpportunities.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>Work Opportunities</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.workOpportunities.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/opportunities/' + value._id}>
                                    <div className="fixed-column-35 height-50">
                                      <img src={(value.imageURL) ? value.imageURL : opportunitiesIconDark} className="image-25-fit" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">
                                      <p className="description-text-2">{value.title}</p>
                                      <p className="description-text-4 top-padding-5 curtail-text">{value.employerName}</p>
                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        <img src={(value.logoURL) ? value.logoURL : opportunitiesIconDark} className="image-auto-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{value.title}</p>
                                        <p className="description-text-3">{value.employerName}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    {(value.postType) && (
                                      <p className="description-text-3 row-5">Type: {value.postType}</p>
                                    )}

                                    {(value.payRange) && (
                                      <p className="description-text-3 row-5">Pay Range: {value.payRange}</p>
                                    )}

                                    {(value.field) && (
                                      <p className="description-text-3 row-5">Field: {value.field}</p>
                                    )}

                                    {(value.startDate) && (
                                      <p className="description-text-3 row-5">Starts on {convertDateToString(value.startDate,"date")}</p>
                                    )}
                                    {(value.submissionDeadline) && (
                                      <p className="description-text-3 row-5">Submit by {convertDateToString(value.submissionDeadline,"date")}</p>
                                    )}

                                    {(value.description) && (
                                      <div className="row-5">
                                        <p className="description-text-3 max-text-lines max-height-30">Description: <label className="description-text-color">{value.description}</label></p>
                                        <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/opportunities/' + value._id}>See more</Link></p>
                                      </div>
                                    )}

                                    <div className="top-padding-20">
                                      <Link className="background-button full-width standard-color" to={this.state.prefix + '/opportunities/' + value._id}><button className="btn btn-squarish full-width">Apply</button></Link>
                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.workOpportunities.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={this.state.prefix + '/opportunities'} state={{ subNavSelected: 'Work' }}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.projectOpportunities && this.state.projectOpportunities.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>Project Opportunities</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.projectOpportunities.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/opportunities/' + value._id}>
                                    <div className="fixed-column-35 height-50">
                                      {(value.postType === 'Assignment' || value.projectPromptType === 'Assignment') && (
                                        <img src={(value.imageURL) ? value.imageURL : assignmentIconDark} className="image-25-fit" alt="GC" />
                                      )}
                                      {(value.postType === 'Problem' || value.projectPromptType === 'Problem') && (
                                        <img src={(value.imageURL) ? value.imageURL : problemIconDark} className="image-25-fit" alt="GC" />
                                      )}
                                      {(value.postType === 'Challenge' || value.projectPromptType === 'Challenge') && (
                                        <img src={(value.imageURL) ? value.imageURL : challengeIconDark} className="image-25-fit" alt="GC" />
                                      )}

                                    </div>
                                    <div className="calc-column-offset-35">

                                      <p className="description-text-2">{value.name}</p>
                                      <p className="description-text-4 top-padding">{value.employerName}</p>

                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        {(value.postType === 'Assignment' || value.projectPromptType === 'Assignment') && (
                                          <img src={(value.logoURL) ? value.logoURL : assignmentIconDark} className="image-auto-25" alt="GC" />
                                        )}
                                        {(value.postType === 'Problem' || value.projectPromptType === 'Problem') && (
                                          <img src={(value.logoURL) ? value.logoURL : problemIconDark} className="image-auto-25" alt="GC" />
                                        )}
                                        {(value.postType === 'Challenge' || value.projectPromptType === 'Challenge') && (
                                          <img src={(value.logoURL) ? value.logoURL : challengeIconDark} className="image-auto-25" alt="GC" />
                                        )}
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{value.name}</p>
                                        <p className="description-text-3">{value.employerName}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    {(value.postType) && (
                                      <p className="description-text-3 row-5">Type: {value.postType}</p>
                                    )}

                                    {(value.tags) && (
                                      <p className="description-text-3 row-5">Tags: {value.tags}</p>
                                    )}

                                    {(value.submissionDeadline) && (
                                      <p className="description-text-3 row-5">Submit by {convertDateToString(value.submissionDeadline,"date")}</p>
                                    )}

                                    {(value.description) && (
                                      <div className="row-5">
                                        <p className="description-text-3 max-text-lines max-height-30">Description: <label className="description-text-color">{value.description}</label></p>
                                        <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/opportunities/' + value._id}>See more</Link></p>
                                      </div>
                                    )}

                                    <div className="top-padding-20">
                                      <Link className="background-button full-width standard-color" to={this.state.prefix + '/opportunities/' + value._id}><button className="btn btn-squarish full-width">Submit</button></Link>
                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.projectOpportunities.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={this.state.prefix + '/opportunities'} state={{ subNavSelected: 'Projects' }}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(this.state.events && this.state.events.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>Events</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.events.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/opportunities/' + value._id}>
                                    <div className="fixed-column-35 height-50">
                                      <img src={(value.imageURL) ? value.imageURL : eventIconDark} className="image-25-fit" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">

                                      <p className="description-text-2">{value.title}</p>
                                      {(value.employerName && value.employerName !== 'No Employers Saved') ? (
                                        <p className="description-text-4 top-padding">{value.employerName}</p>
                                      ) : (
                                        <p className="description-text-4 top-padding">{value.orgName}</p>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        <img src={(value.logoURL) ? value.logoURL : eventIconDark} className="image-auto-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{value.title}</p>
                                        <p className="description-text-3">{value.employerName}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    {(value.eventType) && (
                                      <p className="description-text-3 row-5">Type: {value.eventType}</p>
                                    )}

                                    {(value.startDate) && (
                                      <p className="description-text-3 row-5">Starts at: {convertDateToString(value.startDate,"date")}</p>
                                    )}

                                    {(value.location) && (
                                      <p className="description-text-3 row-5">Tags: {value.location}</p>
                                    )}

                                    {(value.tags) && (
                                      <p className="description-text-3 row-5">Tags: {value.tags}</p>
                                    )}

                                    {(value.submissionDeadline) && (
                                      <p className="description-text-3 row-5">Submit by {convertDateToString(value.submissionDeadline,"date")}</p>
                                    )}

                                    {(value.summary) && (
                                      <div className="row-5">
                                        <p className="description-text-3 max-text-lines max-height-30">Summary: <label className="description-text-color">{value.summary}</label></p>
                                        <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/opportunities/' + value._id}>See more</Link></p>
                                      </div>
                                    )}

                                    <div className="top-padding-20">
                                      <Link className="background-button full-width standard-color" to={this.state.prefix + '/opportunities/' + value._id}><button className="btn btn-squarish full-width">Apply</button></Link>
                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.events.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={(this.state.employerAccount) ? this.state.prefix + '/community' : this.state.prefix + '/opportunities'} state={{ subNavSelected: 'Events' }}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.employerAccount && this.state.courses && this.state.courses.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>Upskilling Opportunities</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.courses.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10 full-width">
                                  <a href={(value.url) ? 'https://www.udemy.com' + value.url : value.programURL} className="background-button full-width" target="_blank">
                                    <div className="fixed-column-35 height-50">
                                      <img src={(value.imageURL) ? value.imageURL : (value.image_125_H) ? value.image_125_H : courseIconDark} className="image-25-fit" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">

                                      <p className="description-text-2">{(value.name) ? value.name : value.title}</p>
                                      <p className="description-text-4 description-text-color">{(value.description) ? value.description : value.headline}</p>

                                    </div>
                                    <div className="clear" />
                                  </a>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        <img src={(value.imageURL) ? value.imageURL : (value.image_125_H) ? value.image_125_H : courseIconDark} className="image-auto-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{(value.name) ? value.name : value.title}</p>
                                        <p className="description-text-3 description-text-color">{(value.description) ? value.description : value.headline}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    <div className="top-padding-20">
                                      <Link className="background-button full-width standard-color" to={this.state.prefix + '/opportunities/' + value._id}><button className="btn btn-squarish full-width">Apply</button></Link>
                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.courses.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={this.state.prefix + '/learning'} state={{ subNavSelected: 'Events' }}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.pathways && this.state.pathways.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>{this.state.orgName} Career Paths</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.pathways.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/pathways/' + value._id} state={{ selectedPathway: value }}>
                                    <div className="fixed-column-35 height-50">
                                      <img src={(value.logoURL) ? value.logoURL : careerMatchesIconDark} className="image-auto-25" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">

                                      <p className="description-text-2">{value.title}</p>
                                      <p className="description-text-4 top-padding">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}</p>

                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        <img src={(value.imageURL) ? value.imageURL : careerMatchesIconDark} className="image-auto-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{value.title}</p>
                                        <p className="description-text-3">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    <div className="top-padding-20">
                                      <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish standard-border medium-background full-width" : "btn btn-squarish full-width"} onClick={() => this.favoriteItem(value,'Pathway')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Favorited" : "Favorite"}</button>
                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.pathways.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={this.state.prefix + '/exploration'}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.careers && this.state.careers.length > 0) && (
                      <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p>{(this.state.pathways && this.state.pathways.length > 0) && "General "}Career Paths</p>

                          <div className="spacer" /><div className="half-spacer" />

                          {this.state.careers.map((value, index) =>
                            <div key={value}>
                              {(index < 3) && (
                                <div className="row-10">
                                  <Link className="background-button full-width standard-color profile-container-left" to={this.state.prefix + '/careers/' + value.name} state={{ careerSelected: value }}>
                                    <div className="fixed-column-35 height-50">
                                      <img src={(value.logoURL) ? value.logoURL : careerMatchesIconDark} className="image-auto-25" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-35">

                                      <p className="description-text-2">{value.name}</p>
                                      <p className="description-text-4 top-padding">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}{(!value.jobFunction && value.industry) && value.industry}{(value.jobFamily) && ' | ' + value.jobFamily}</p>

                                    </div>
                                    <div className="clear" />
                                  </Link>

                                  <div className="profile-modal-left">
                                    <div className="bottom-padding-5">
                                      <div className="fixed-column-35 height-50">
                                        <img src={(value.logoURL) ? value.logoURL : careerMatchesIconDark} className="image-auto-25" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1">{value.name}</p>
                                        <p className="description-text-3">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}{(!value.jobFunction && value.industry) && value.industry}{(value.jobFamily) && ' | ' + value.jobFamily}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />

                                    {(value.marketData && value.marketData.pay) && (
                                      <p className="description-text-3 row-5">${Number(value.marketData.pay).toLocaleString()} avg</p>
                                    )}

                                    {(value.marketData && value.marketData.growth) && (
                                      <p className="description-text-3 row-5">{value.marketData.growth} growth</p>
                                    )}

                                    {(value.marketData && value.marketData.totalEmployment) && (
                                      <p className="description-text-3 row-5">{value.marketData.totalEmployment} Employment</p>
                                    )}

                                    {(value.overview && value.overview.summary) && (
                                      <p className="description-text-3 description-text-color row-5">{value.overview.summary}</p>
                                    )}
                                    {(value.overview && value.overview.summary) && (
                                      <div className="row-5">
                                        <p className="description-text-3 max-text-lines max-height-30">Summary: <label className="description-text-color">{value.overview.summary}</label></p>
                                        <p className="description-text-3"><Link className="background-button cta-color" to={this.state.prefix + '/careers/' + value.name} state={{ careerSelected: value }}>See more</Link></p>
                                      </div>
                                    )}

                                    <div className="top-padding-20">
                                      <button className={(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "btn btn-squarish medium-backround standard-border full-width" : "btn btn-squarish full-width"} onClick={() => this.favoriteItem(value,'Career')}>{(this.state.favoriteIds && this.state.favoriteIds.includes(value._id)) ? "Favorited" : "Favorite"}</button>

                                    </div>

                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.careers.length > 0) && (
                            <div className="row-5">
                              <Link className="background-button float-right" to={this.state.prefix + '/exploration'}>
                                <img src={arrowIndicatorIconBlue} alt="GC" className="image-10-fit top-margin-3 float-right" />
                                <p className="description-text-3 float-right right-padding">See More</p>
                              </Link>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              )}

              <div className="clear" />
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

             {(this.state.showPost || this.state.sharePosting) && (
               <div key="showPost" className="full-width padding-20">
                  <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost} posts={this.state.posts} passPosts={this.passPosts} closeModal={this.closeModal} pictureURL={this.state.pictureURL} accountCode={this.state.accountCode} />
                </div>
             )}
             {(this.state.showSurvey) && (
               <div key="showPost" className="full-width padding-20">
                  <SubTakeBasicAssessment history={this.props.navigate} questions={this.state.surveyQuestions} selectedSurvey={this.state.customAssessment} submissions={null} orgCode={this.state.activeOrg} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
                </div>
             )}
             {(this.state.showChangePassword) && (
               <div key="showChangePassword" className="full-width padding-20">
                  <SubChangePassword history={this.props.navigate} email={this.state.emailId}
                    modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}
                  />
                </div>
             )}
             {/*
             {(this.state.updateApplications) && (
               <div className="flex-container flex-center padding-60">
                 {(this.state.hasUpdated) ? (
                   <div className="center-text">
                     <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                     <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-100 vertical-margin-20 center-horizontally"/>
                     <div className="spacer" /><div className="spacer" />
                     <p className="heading-text-2 cta-color">All Applications Have Been Successfully Updated!</p>
                     <div className="spacer" /><div className="spacer" />
                     <p className="description-text-color">You have updated your profile information, career assessments, and endorsements for all of your outstanding applications.</p>
                     <div className="spacer" /><div className="spacer" /><div className="spacer" />
                     <button onClick={() => this.setState({ modalIsOpen: false })} className="btn btn-secondary left-margin">Return to Dashboard</button>
                     <div className="spacer"/><div className="spacer"/>
                   </div>
                 ) : (
                   <div className="center-text">
                     <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                     <img src={questionMarkBlue} alt="Compass checkmark icon" className="image-auto-100 center-horizontally vertical-margin-20"/>
                     <div className="spacer" /><div className="spacer" />
                     <p className="heading-text-2 cta-color">Are you sure you want to update all applications?</p>
                     <div className="spacer" /><div className="spacer" />
                     <p className="description-text-color">You will update your profile information, career assessments, and endorsements for all of your outstanding applications.</p>
                     <div className="spacer" /><div className="spacer" /><div className="spacer" />
                     <div className="row-direction">
                       <button onClick={() => this.updateApplications()} className="btn btn-primary right-margin">Yes, Update All Applications</button>
                       <button onClick={() => this.setState({ modalIsOpen: false })} className="btn btn-secondary left-margin">No, Nevermind</button>
                     </div>
                     {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                     <div className="spacer"/><div className="spacer"/>
                   </div>
                 )}
               </div>
             )}*/}

             {/*
             {(this.state.passedGroupPost) && (
               <div key="passedGroupPost" className="full-width">
                <SubRenderPosts posts={this.state.posts} passedGroupPost={this.state.passedGroupPost} />
               </div>
             )}*/}

           </Modal>

          </div>

      )
    }
}

export default withRouter(NewsFeed);
