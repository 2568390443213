import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import { CircularProgressbar } from 'react-circular-progressbar';;
import Switch from 'react-switch';
import Modal from 'react-modal';
import { Doughnut } from 'react-chartjs-2';
import SubSideNav from './Common/SideNav';
import SubEditPlacement from './Subcomponents/EditPlacement';
import SubTimesheet from './Subcomponents/Timesheet';
import SubContact from './Subcomponents/Contact';
import DeactivatedModal from './Common/DeactivatedModal';
import SubEditGroup from './Common/EditGroup';
import SubCreatePost from './Common/CreatePost';
import SubEditPipeline from './Common/EditPipeline';
import SubRenderPosts from './Common/RenderPosts';
import SubChangePassword from './Common/ChangePassword';
import {convertDateToString} from './Functions/ConvertDateToString';
import withRouter from './Functions/WithRouter';

const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const dropdownArrow = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const shareIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const socialIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-grey.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const socialIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-blue.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const tagIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon-blue.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';

// const styles = {
//     transition: 'all 0.5s ease-out',
//     position: 'relative',
//     transform: 'translate(90%)'
// };

class EmpDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            emp: '',

            showModule: false,
            orgMode: true,
            org: '',

            programName: '',
            orgName: '',
            contactTitle: '',
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',

            postings: [],
            applications: [],
            topCandidates: [],
            benchmarks: [],
            kpis: [],
            csActivity: [],
            employerActivity: [],
            competencyNeeds: [],

            checklistTracker: [],
            profileChecklist: []

        }

        this.calculateMatches = this.calculateMatches.bind(this)
        this.renderPostings = this.renderPostings.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleRoundReadiness = this.toggleRoundReadiness.bind(this)
        this.changeItem = this.changeItem.bind(this)
        this.shareProfile = this.shareProfile.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this)

        this.orgClicked = this.orgClicked.bind(this)
        this.removeOrg = this.removeOrg.bind(this)
        this.pullCourses = this.pullCourses.bind(this)
        this.calculateDashboard = this.calculateDashboard.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()

    }

    retrieveData() {
      console.log('retrieveData called')

      let email = localStorage.getItem('email');
      let { emp } = this.props.params

      //obtain email from localStorage
      // let email = localStorage.getItem('email');
      // let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg')
      let cuFirstName = localStorage.getItem('firstName')
      let cuLastName = localStorage.getItem('lastName')
      let internalToolEnabled = localStorage.getItem('internalToolEnabled')
      const orgLogo = localStorage.getItem('orgLogo');
      let temporaryPassword = localStorage.getItem('temporaryPassword');
      // console.log('internalToolEnabled----------!----', temporaryPassword)

      const okrs = [
        { _id: '123', objective: { title: "Grow Revenues 2x", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Sales','Marketing','Social Media']},
        { _id: '123', objective: { title: "Expand Into New Markets", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Business Development','Mergers & Acquisitions','Corporate Development','Strategy','Finance']},
      ]

      const surveys = [
        { _id: '123', title: "How You Doin", startDate: new Date(), endDate: new Date()},
      ]

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        let kpis = [
          { name: 'Quality of Hire', score: 'N/A%'},
          { name: 'Time to Fill', score: 'N/A%'},
          { name: 'Retention Rate', score: 'N/A%'},
          { name: 'Diverse Hire Rate', score: 'N/A%'},
          { name: 'Diverse Employee Retention', score: 'N/A%'},
        ]

        let csActivity = [
          { name: 'Followers', score: '0'},
          { name: 'RSVPs', score: '0'},
          { name: 'Project Submissions', score: '0'},
          { name: 'Applications', score: '0'},
          { name: 'Interviews', score: '0'},
          { name: 'Hires', score: '0'},
        ]

        let employerActivity = [
          { name: 'Event Postings', score: '0'},
          { name: 'Project Postings', score: '0'},
          { name: 'Work Postings', score: '0'},
          { name: 'Benchmarks', score: '0'},
          { name: 'Talent Pipelines', score: '0'},
          { name: 'Talent Communities', score: '0'},
          { name: 'Social Posts', score: '0'},
          { name: 'Training Partners', score: '0'},
        ]

        const shareURL = window.location.protocol + "//" + window.location.host + "/employers/pages/" + emp

        const profileChecklist = [
          { name: 'Added Basic Profile Info', type: 'link', url: '/employers/' + emp + '/edit-employer-profile', passedState: {}},
          { name: 'Added a Benchmark', type: 'link', url: '/employers/' + emp + '/benchmarks/add', passedState: {}},
          { name: 'Added a Talent Pipeline', type: 'button', url: '/employers/' + emp + '/pipelines', passedState: { modalIsOpen: true, showAddPipeline: true }},
          { name: 'Created a Talent Community', type: 'button', url: '/employers/' + emp + '/groups', passedState: { modalIsOpen: true, showAddGroup: true }},
          { name: 'Posted an Opportunity', type: 'link', url: '/employers/' + emp + '/postings', passedState: {}},
          { name: 'Created a Social Post', type: 'button', url: '/employers/' + emp + '/social-posts', passedState: { modalIsOpen: true, showPost: true }},
          { name: 'Hired Someone', type: 'link', url: '/employers/' + emp + '/hires', passedState: {}},
          { name: 'Retained a Hire 1 Year ', type: 'link', url: '/employers/' + emp + '/hires', passedState: {}},
        ]

        const competencyNeeds = [
          { name: "Sales" },{ name: "Marketing" },{ name: "Ability to Scale Operations" },{ name: "Ability to Manage People Effectively" },
          { name: "Intrapreneurship" },{ name: "Business Development" },
          { name: "Recruit Talent" },{ name: "Strategic Thinking" },{ name: "Javascript" },
        ]

        let modalIsOpen = null
        let showChangePassword = null
        if (temporaryPassword) {
          modalIsOpen = true
          showChangePassword = true
        }

        this.setState({ emp, orgMode, activeOrg, emailId: email,
          cuFirstName, cuLastName, orgLogo, kpis, csActivity, employerActivity,
          shareURL, profileChecklist, internalToolEnabled, okrs, surveys, competencyNeeds,
          modalIsOpen, showChangePassword
        })

        if (internalToolEnabled) {
          this.calculateDashboard(activeOrg, null, null, 'all time')
        }

        if (activeOrg !== '') {
          console.log('show stuff: ', orgMode, activeOrg)

          Axios.get('/api/account', { params: { accountCode: emp } })
          .then((response) => {
            console.log('Account info query attempted in employer dashboard', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              const employerName = response.data.accountInfo.employerName
              const employerLogo = response.data.accountInfo.employerLogoURI
              const accountCode = response.data.accountInfo.accountCode
              const rating = response.data.accountInfo.rating
              const departments = response.data.accountInfo.departments
              let sharePartners = response.data.accountInfo.sharePartners
              const topPipelineCandidates = response.data.accountInfo.topPipelineCandidates
              const tempJobFunction = 'Marketing'

              let checklistTracker = this.state.checklistTracker
              if (employerName && employerLogo) {
                checklistTracker.push(profileChecklist[0].name)
              }

              this.setState({ employerName, employerLogo, accountCode, jobFunction: tempJobFunction, rating,
                departments, sharePartners, topPipelineCandidates, checklistTracker
              });

              Axios.get('/api/get-followers', { params: { _id: response.data.accountInfo._id } })
              .then((response) => {
                console.log('Followers query attempted', response.data);

                if (response.data.success) {
                  console.log('followers query worked in sub settings')

                  const followers = response.data.followers
                  for (let i = 1; i <= csActivity.length; i++) {
                    if (csActivity[i  - 1].name === 'Followers') {
                      csActivity[i - 1]['score'] = followers.length
                    }
                  }

                  this.setState({ followers, csActivity })
                }

              }).catch((error) => {
                console.log('Followers query did not work for some reason', error);
              });

              if (sharePartners && sharePartners.includes('guidedcompass')) {
                const gcIndex = sharePartners.indexOf('guidedcompass')
                sharePartners.splice(gcIndex,1)
                // console.log('show sharePartners: ', sharePartners)
              }

              if (sharePartners.length > 0) {
                Axios.get('/api/orgs', { params: { orgCodes: response.data.accountInfo.sharePartners } })
                .then((response) => {
                  console.log('Org training providers query attempted', response.data);

                  if (response.data.success) {
                    console.log('org training providers query worked in sub settings')

                    const trainingProviders = response.data.orgs
                    this.setState({ trainingProviders })

                  }

                }).catch((error) => {
                  console.log('Org training providers query did not work for some reason', error);
                });
              }
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });

          Axios.get('/api/users/profile/details/' + email, { params: { emailId: email } })
           .then((response) => {
             console.log('query for profile data worked in newsfeed');

             if (response.data.success) {

               console.log('profile data received', response.data)

               const pictureURL = response.data.user.pictureURL
               const jobTitle = response.data.user.jobTitle
               const location = response.data.user.location

               this.setState({ pictureURL, jobTitle, location })


             } else {
               console.log('error response', response.data)
             }

         }).catch((error) => {
             console.log('query for profile info did not work', error);
         })

         Axios.get('/api/pipelines', { params: { accountCode: emp }})
         .then((response) => {
           console.log('Pipelines query within sub attempted', response.data);

           if (response.data.success) {
             console.log('pipeline query worked')

             if (response.data.pipelines.length > 0) {
               //jobs = response.data.postings
               const pipelines = response.data.pipelines

               let employerActivity = this.state.employerActivity
               for (let i = 1; i <= employerActivity.length; i++) {
                 if (employerActivity[i  - 1].name === 'Talent Pipelines') {
                   employerActivity[i - 1]['score'] = response.data.pipelines.length
                 }
               }

               let checklistTracker = this.state.checklistTracker
               checklistTracker.push(profileChecklist[2].name)
               this.setState({ pipelines, employerActivity, checklistTracker });
             }
           }

         }).catch((error) => {
             console.log('Pipeline query did not work for some reason', error);
             this.setState({ isLoading: false })
         });

         if (activeOrg === 'guidedcompass') {
           Axios.get('/api/groups', { params: { resLimit: 3, accountCode: emp }})
           .then((response) => {
             console.log('Groups query worked', response.data);

             if (response.data.success && response.data.groups &&  response.data.groups.length > 0) {

               const communities = response.data.groups

               let employerActivity = this.state.employerActivity
               for (let i = 1; i <= employerActivity.length; i++) {
                 if (employerActivity[i  - 1].name === 'Talent Communities') {
                   employerActivity[i - 1]['score'] = response.data.groups.length
                 }
               }

               let checklistTracker = this.state.checklistTracker
               checklistTracker.push(profileChecklist[3].name)
               this.setState({ communities, employerActivity, checklistTracker })

             } else {
               console.log('no groups data found', response.data.message)
             }

           }).catch((error) => {
               console.log('Groups query did not work', error);
           });

           Axios.get('/api/group-posts', { params: { groupId: null, accountCode: emp } })
           .then((response) => {
              console.log('Group posts query attempted in newsfeed', response.data);

              if (response.data.success) {
                console.log('successfully retrieved group posts in newsfeed')

                if (response.data.groupPosts && response.data.groupPosts.length > 0) {
                  let socialPosts = response.data.groupPosts

                  let employerActivity = this.state.employerActivity
                  for (let i = 1; i <= employerActivity.length; i++) {
                    if (employerActivity[i  - 1].name === 'Social Posts') {
                      employerActivity[i - 1]['score'] = response.data.groupPosts.length
                    }
                  }

                  let checklistTracker = this.state.checklistTracker
                  checklistTracker.push(profileChecklist[5].name)
                  this.setState({ socialPosts, employerActivity, checklistTracker })
                }

              }
           }).catch((error) => {
               console.log('Group posts query did not work', error);
           });

           Axios.get('/api/benchmarks', { params: { accountCode: emp } })
           .then((response) => {
             console.log('Benchmarks query within sub attempted', response.data);

             if (response.data.success) {
               console.log('benchmark query worked')

               if (response.data.benchmarks.length !== 0) {
                 //jobs = response.data.postings
                 console.log('set the benchmark to state')

                 let employerActivity = this.state.employerActivity
                 for (let i = 1; i <= employerActivity.length; i++) {
                   if (employerActivity[i  - 1].name === 'Benchmarks') {
                     employerActivity[i - 1]['score'] = response.data.benchmarks.length
                   }
                 }

                 let checklistTracker = this.state.checklistTracker
                 checklistTracker.push(profileChecklist[1].name)

                 this.setState({ employerActivity, checklistTracker })
               } else {

               }

             } else {
               console.log('benchmark query did not work', response.data.message)

             }

           }).catch((error) => {
               console.log('Benchmark query did not work for some reason', error);
           });
         }

          Axios.get('/api/work', { params: { accountCode: emp, orgCode: activeOrg } })
          .then((response) => {
            console.log('Work query attempted within employer dashboard', response.data);

            if (response.data.success && response.data.workArray && response.data.workArray.length > 0) {
              console.log('account info query worked in sub settings')

              const workArray = response.data.workArray
              let checklistTracker = this.state.checklistTracker
              checklistTracker.push(profileChecklist[6].name)
              this.setState({ workArray, checklistTracker });

              // add workers
              // Axios.get('/api/workers', { params: { workId: emp } })
              // .then((response) => {
              //   console.log('Work query attempted within employer dashboard', response.data);
              //
              //   if (response.data.success) {
              //     console.log('account info query worked in sub settings')
              //
              //     const workArray = response.data.workArray
              //     this.setState({ workArray });
              //
              //     // add workers
              //
              //   }
              //
              // }).catch((error) => {
              //   console.log('Account info query did not work for some reason', error);
              // });
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });

          if (emp) {
            Axios.get('/api/timesheets', { params: { accountCode: emp, isSubmitted: true } })
            .then((response) => {
              console.log('Timesheets query attempted within employer dashboard', response.data);

              if (response.data.success) {
                console.log('Timesheets query worked in sub settings')

                const timesheets = response.data.timesheets
                if (timesheets) {
                  this.setState({ timesheets });
                }
              }

            }).catch((error) => {
              console.log('Timesheets query did not work for some reason', error);
            });
          }

          Axios.get('/api/assignments', { params: { accountCode: emp, postType: 'Assignment', assigneeGroup: 'Private' } })
          .then((response) => {
            console.log('Assignments query attempted', response.data);

            if (response.data.success) {
              console.log('assignments query worked')

              const assignments = response.data.assignments
              if (response.data.assignments) {
                this.setState({ assignments })
              }

            } else {
              console.log('found no assignments', response.data.message)

            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const employerDashboardMessage = response.data.orgInfo.employerDashboardMessage
              const internshipEmptyStateMessage = response.data.orgInfo.internshipEmptyStateMessage

              const deactivated = response.data.orgInfo.deactivated
              let redirectLink = response.data.orgInfo.employerRedirectLink
              if (redirectLink && !redirectLink.startsWith('http')) {
                redirectLink = 'http://' + redirectLink
              }

              // let modalIsOpen = false
              if (deactivated) {
                modalIsOpen = true
                showChangePassword = false
              }

              let renderSideNav = false

              this.setState({

                  orgName: response.data.orgInfo.orgName,
                  programName: response.data.orgInfo.orgProgramName,

                  contactFirstName: response.data.orgInfo.contactFirstName,
                  contactLastName: response.data.orgInfo.contactLastName,
                  contactTitle: response.data.orgInfo.contactTitle,
                  contactEmail: response.data.orgInfo.contactEmail,
                  employerDashboardMessage, internshipEmptyStateMessage,
                  deactivated, redirectLink, modalIsOpen, renderSideNav
              });

            } else {
              console.log('org info query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        Axios.get('/api/postings/user', { params: { accountCode: emp, orgCode: activeOrg } })
        .then((response) => {
          console.log('Posted jobs query attempted', response.data);

            if (response.data.success) {
              console.log('posted jobs query worked')

              if (response.data.postings.length !== 0) {
                let postings = response.data.postings
                let hasWorkPostings = false
                let postingIds = []
                let eventIds = []
                let upcomingEvents = []
                let projectIds = []
                let submissions = []

                for (let i = 1; i <= postings.length; i++) {
                  //console.log(i)

                  if (postings[i - 1].postType === 'Internship' || postings[i - 1].postType === 'Work' || postings[i - 1].postType === 'Individual') {
                    hasWorkPostings = true
                    postingIds.push(postings[i - 1]._id)

                    // let postings = this.state.postings
                    // postings.push(jobs[i - 1])

                    let topCandidates = this.state.topCandidates
                    topCandidates.push([])

                    let applications = this.state.applications
                    applications.push([])

                    this.setState({ hasWorkPostings, postings, topCandidates, applications })

                    const index = i - 1
                    const benchmarkId = postings[i - 1].benchmarkId

                    if (!orgMode) {

                      let ids = []
                      for (let i = 1; i <= postings.length; i++) {
                        // console.log('show me ids: ', postings[i - 1], )
                        if (postings[i - 1].benchmarkId) {
                          ids.push(postings[i - 1].benchmarkId)
                        }

                      }

                      if (ids.length > 0) {

                        let checklistTracker = this.state.checklistTracker
                        if (!checklistTracker.includes(profileChecklist[4].name)) {
                          checklistTracker.push(profileChecklist[4].name)
                        }

                        this.setState({ checklistTracker })

                        Axios.get('/api/benchmarks/byid', { params: { ids } })
                        .then((response) => {
                          console.log('Benchmarks query attempted 1', response.data);

                          if (response.data.success && response.data.benchmarks) {
                            console.log('benchmark query worked')

                            let benchmarkOptions = response.data.benchmarks
                            let benchmarks = benchmarkOptions

                            // console.log('show benchmarks list: ', benchmarks)
                            // console.log('show benchmarkOptions: ', benchmarkOptions)

                            this.setState({ benchmarks, benchmarkOptions })
                            // console.log('-- d', jobs[index].postType)
                            // if (jobs[index].postType === 'Work' || jobs[index].postType === 'Internship' || jobs[index].postType === 'Individual') {

                            const benchmarkIndex = index

                            Axios.get('/api/applications/bypost', { params: { postingId: postings[index]._id } })
                            .then((response) => {
                              console.log('Applications query attempted 1', response.data);

                              if (response.data.success) {
                                console.log('applications query worked')

                                if (response.data.applications.length !== 0) {
                                  console.log('show indv: ', benchmarks, benchmarkIndex)

                                  if (activeOrg === 'guidedcompass') {
                                    for (let j = 1; j <= csActivity.length; j++) {
                                      if (csActivity[j  - 1].name === 'Applications') {
                                        csActivity[j - 1]['score'] = Number(csActivity[j - 1].score) + response.data.applications.length
                                      }
                                    }
                                    this.setState({ csActivity })
                                  }


                                  let benchmark = benchmarks[benchmarkIndex]
                                  this.calculateMatches(index, response.data.applications, postings[index], benchmark, benchmarkOptions, activeOrg)

                                }

                              } else {
                                console.log('applications query did not work', response.data.message)
                              }
                            }).catch((error) => {
                                console.log('Application query did not work for some reason', error);
                            });

                          } else {
                            console.log('benchmark query did not work', response.data.message)
                          }

                        }).catch((error) => {
                            console.log('Benchmark query did not work for some reason', error);
                        });
                      }

                    } else {

                      let orgCodes = [activeOrg,'general']
                      if (emp === 'guidedcompass') {
                        // for demo purposes
                        orgCodes.push('bixel')
                      }

                      Axios.get('/api/benchmarks', { params: { orgCodes } })
                      .then((response) => {
                        console.log('Benchmarks query attempted', response.data);

                        if (response.data.success) {
                          console.log('benchmark query worked')

                          if (response.data.benchmarks.length !== 0) {

                            const benchmarkOptions = response.data.benchmarks
                            let benchmarks = this.state.benchmarks
                            let selectedBenchmark = null

                            for (let j = 1; j <= response.data.benchmarks.length; j++) {
                              console.log(j);
                              console.log('compare ids: ', response.data.benchmarks[j - 1]._id, benchmarkId)
                              if (response.data.benchmarks[j - 1]._id === benchmarkId) {
                                benchmarks.push(response.data.benchmarks[j - 1])
                                selectedBenchmark = response.data.benchmarks[j - 1]

                              }
                            }

                            this.setState({ benchmarks, benchmarkOptions: response.data.benchmarks })
                            // console.log('-- d', jobs[index].postType)
                            // if (jobs[index].postType === 'Work' || jobs[index].postType === 'Internship' || jobs[index].postType === 'Individual') {
                            Axios.get('/api/applications/bypost', { params: { postingId: postings[index]._id } })
                            .then((response) => {
                              console.log('Applications query attempted', response.data);

                              if (response.data.success) {
                                console.log('applications query worked')

                                if (response.data.applications.length !== 0) {

                                  this.calculateMatches(index, response.data.applications, postings[index], selectedBenchmark, benchmarkOptions, activeOrg)

                                }

                              } else {
                                console.log('applications query did not work', response.data.message)
                              }
                            }).catch((error) => {
                                console.log('Application query did not work for some reason', error);
                            });
                            // }
                          }

                        } else {
                          console.log('benchmark query did not work', response.data.message)
                        }

                      }).catch((error) => {
                          console.log('Benchmark query did not work for some reason', error);
                      });
                    }
                  } else if (postings[i - 1].postType === 'Event') {
                    eventIds.push(postings[i - 1]._id)
                    if (postings[i - 1].startDate && new Date(postings[i - 1].startDate)) {
                      if ((new Date() - new Date(postings[i - 1].startDate)) > 0) {
                        // pastEvents.push(postings[i - 1])
                      } else {

                        if (upcomingEvents.length < 3) {
                          upcomingEvents.push(postings[i - 1])
                          const tempIndex = i - 1

                          // pull RSVPs
                          Axios.get('/api/rsvps/bypost', { params: { postingId: postings[i - 1]._id } })
                          .then((response) => {
                            console.log('Rsvp query attempted', response.data);

                            if (response.data.success) {
                              console.log('rsvp query worked')

                              upcomingEvents[tempIndex]['rsvps'] = response.data.rsvps

                            } else {
                              console.log('rsvp query did not work', response.data.message)
                              //there is no rsvp data
                            }

                          }).catch((error) => {
                              console.log('Rsvp query did not work for some reason', error);
                          });
                        }
                      }
                    }
                  } else if (postings[i - 1].postType === 'Assignment' || postings[i - 1].postType === 'Problem' || postings[i - 1].postType === 'Challenge') {
                    projectIds.push(postings[i - 1]._id)
                    if (submissions.length < 3) {
                      submissions.push(postings[i - 1].submissions)
                    }
                  }
                }

                if (hasWorkPostings) {
                  let employerActivity = this.state.employerActivity
                  for (let i = 1; i <= employerActivity.length; i++) {
                    if (employerActivity[i  - 1].name === 'Work Postings') {
                      employerActivity[i - 1]['score'] = postingIds.length
                    }
                  }
                  this.setState({ employerActivity })
                  // retrieve offers by posting
                  Axios.get('/api/offers/bypostings', { params: { postingIds } })
                  .then((response) => {
                    console.log('offers by postings query attempted', response.data);

                    if (response.data.success) {
                      console.log('offers by postings query worked')
                      const offers = response.data.offers
                      let mappedOffers = []
                      if (offers && offers.length > 0) {
                        for (let i = 1; i <= postings.length; i++) {
                          let tempOffers = []
                          for (let j = 1; j <= offers.length; j++) {
                            if (postings[i - 1]._id === offers[j - 1].postingId && !offers[j - 1].decision) {
                              tempOffers.push(offers[j - 1])
                            }
                          }
                          mappedOffers.push(tempOffers)
                        }
                      }

                      this.setState({ offers, mappedOffers })

                    } else {

                    }
                  }).catch((error) => {
                      console.log('Offers by postings query did not work for some reason', error);
                  });
                }

                if (eventIds.length > 0) {
                  let employerActivity = this.state.employerActivity
                  for (let i = 1; i <= employerActivity.length; i++) {
                    if (employerActivity[i  - 1].name === 'Event Postings') {
                      employerActivity[i - 1]['score'] = eventIds.length
                    }
                  }
                  this.setState({ employerActivity })

                  if (upcomingEvents.length > 0) {
                    this.setState({ upcomingEvents })
                  }
                }

                if (projectIds.length > 0) {
                  let employerActivity = this.state.employerActivity
                  for (let i = 1; i <= employerActivity.length; i++) {
                    if (employerActivity[i  - 1].name === 'Project Postings') {
                      employerActivity[i - 1]['score'] = eventIds.length
                    }
                  }
                  this.setState({ employerActivity })

                  if (submissions.length > 0) {
                    submissions.sort(function(a,b) {
                      return b.upvotes.length - a.upvotes.length;
                    })
                    this.setState({ submissions })
                  }
                }
              }

            } else {
              console.log('postings query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Postings query did not work for some reason', error);
        });

        this.pullCourses("Marketing", this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, null, null, null, true)
      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      let excludeOrgCourses = false
      if (sources && !sources.includes('OrgCourses')) {
        orgCode = null
        excludeOrgCourses = true
      }

      if (sources && sources.length === 1 && sources[0] === 'Udemy') {
        queryUdemyCourses = true
        if (!searchValue) {
          searchValue = "Marketing"
        }
      } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
        queryUdemyCourses = false
      }
      const courseQueryObject = { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 }
      // console.log('show courses queryObject: ', courseQueryObject)
      Axios.get('/api/courses/search', { params: courseQueryObject })
      .then((response) => {
        console.log('Search courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              const filteredCourses = courses
              this.setState({ courses, filteredCourses, sources, queryUdemyCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ sources, queryUdemyCourses, animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ sources, animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    calculateDashboard(org, stageOptions, interviewRequested, timeframe) {

      if (this.state.showModule) {
        this.setState({ isLoading: true, timeframe })

        // const chartOptions = {
        //    tooltips: {
        //       callbacks: {
        //          label: function(tooltipItem, data) {
        //             var dataset = data.datasets[tooltipItem.datasetIndex];
        //             var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        //             var total = meta.total;
        //             var currentValue = dataset.data[tooltipItem.index];
        //             var percentage = parseFloat((currentValue / total * 100).toFixed(1));
        //             return currentValue + ' (' + percentage + '%)';
        //          },
        //          title: function(tooltipItem, data) {
        //             return data.labels[tooltipItem[0].index];
        //          }
        //       }
        //    }
        // }

        Axios.get('/api/assessment/insights', { params: { orgCode: org, timeframe } })
        .then((response) => {
          if (response.data.success) {
            console.log('Career assessment insights query worked', response.data);

            if (response.data.assessmentInsights) {
              console.log('a0')
              let skillsData = null
              let interestsData = null
              let personalityData = null
              let valuesData = null
              let functionsOfInterestData = null
              let industriesOfInterestData = null

              if (response.data.assessmentInsights.skills && response.data.assessmentInsights.skills.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.skills.length; i++) {
                  if (response.data.assessmentInsights.skills[i - 1] && response.data.assessmentInsights.skills[i - 1].name) {
                    data.push(response.data.assessmentInsights.skills[i - 1].weightedScore)
                    labels.push(response.data.assessmentInsights.skills[i - 1].name)
                  }
                }
                console.log('data and label: ', data, labels)
                skillsData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              if (response.data.assessmentInsights.interests && response.data.assessmentInsights.interests.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.interests.length; i++) {
                  if (response.data.assessmentInsights.interests[i - 1] && response.data.assessmentInsights.interests[i - 1].name) {
                    data.push(response.data.assessmentInsights.interests[i - 1].score)
                    labels.push(response.data.assessmentInsights.interests[i - 1].name)
                  }
                }
                // console.log('data and label: ', data, labels)
                interestsData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              if (response.data.assessmentInsights.traits && response.data.assessmentInsights.traits.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.traits.length; i++) {
                  if (response.data.assessmentInsights.traits[i - 1] && response.data.assessmentInsights.traits[i - 1].name) {
                    data.push(response.data.assessmentInsights.traits[i - 1].score)
                    labels.push(response.data.assessmentInsights.traits[i - 1].name)
                  }
                }
                // console.log('data and label pers: ', data, labels)
                personalityData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              if (response.data.assessmentInsights.values && response.data.assessmentInsights.values.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.values.length; i++) {
                  if (response.data.assessmentInsights.values[i - 1] && response.data.assessmentInsights.values[i - 1].name) {
                    data.push(response.data.assessmentInsights.values[i - 1].eCount)
                    labels.push(response.data.assessmentInsights.values[i - 1].name)
                  }
                }
                // console.log('data and label: ', data, labels)
                valuesData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              if (response.data.assessmentInsights.functionsOfInterest && response.data.assessmentInsights.functionsOfInterest.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.functionsOfInterest.length; i++) {
                  if (response.data.assessmentInsights.functionsOfInterest[i - 1] && response.data.assessmentInsights.functionsOfInterest[i - 1].name) {
                    data.push(response.data.assessmentInsights.functionsOfInterest[i - 1].eCount)
                    labels.push(response.data.assessmentInsights.functionsOfInterest[i - 1].name)
                  }
                }
                // console.log('data and label: ', data, labels)
                functionsOfInterestData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              if (response.data.assessmentInsights.industriesOfInterest && response.data.assessmentInsights.industriesOfInterest.length > 0) {

                let data = []
                let labels = []
                for (let i = 1; i <= response.data.assessmentInsights.industriesOfInterest.length; i++) {
                  if (response.data.assessmentInsights.industriesOfInterest[i - 1] && response.data.assessmentInsights.industriesOfInterest[i - 1].name) {
                    data.push(response.data.assessmentInsights.industriesOfInterest[i - 1].eCount)
                    labels.push(response.data.assessmentInsights.industriesOfInterest[i - 1].name)
                  }
                }
                // console.log('data and label: ', data, labels)
                industriesOfInterestData = {
                  datasets: [{
                    data,
                    backgroundColor: [
                      "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                      "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                    ]
                  }],
                  labels
                }
              }

              const dummyData = [1,2,3]
              const bColor = [
                "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
              ]

              const clarityLabels = ['Super Clear on Responsibilities','Somewhat Clear on Responsibilities','Not Clear on Responsibilities']
              const valuedLabels = ['Feels Super Valued at Work','Feels Somewhat Valued at Work',"Doesn't Feel Valued at Work"]
              const payLabels = ['Thinks Their Pay is More Than Fair','Thinks Their Pay is Fair','Thinks They Are Underpaid']
              const upsideParticipationLabels = ['Likes Upside Compensation','Thinks Upside Compensation is Adequate','Thinks Upside Compensation is Inadequate']
              const challengedLabels = ['Feels Challenged at Work','Feels Somewhat Challenged At Work',"Doesn't Feel Challenged at Work"]
              const workStyleLabels = ["Gels with Team Work Styles & Expections",'Somewhat gels with Team Work Styles & Expections',"Doesn't Gel with Team Work Styles & Expections"]
              const managementLabels = ['Loves Management Team','Likes Management Team','Takes Issue with Management Team']
              const cohesionLabels = ['Has Bonded With Most Team Members','Has Bonded With A Few Team Members','Has Not Bonded With Team Members']

              const clarityData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: clarityLabels }
              const valuedData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: valuedLabels }
              const payData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: payLabels }
              const upsideParticipationData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: upsideParticipationLabels }
              const challengedData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: challengedLabels }
              const workStyleData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: workStyleLabels }
              const managementData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: managementLabels }
              const cohesionData = { datasets: [{ data: dummyData, backgroundColor: bColor }], labels: cohesionLabels }

              this.setState({ skillsData, interestsData, personalityData, valuesData,
                functionsOfInterestData, industriesOfInterestData,
                clarityData, valuedData, payData, upsideParticipationData, challengedData, workStyleData, managementData, cohesionData
              })
            }

          } else {
            console.log('no career assessment insights info found', response.data.message)
            this.setState({ isLoading: false })
          }

        }).catch((error) => {
            console.log('Career assessment insights query did not work', error);

        });

        // Axios.get('/api/funnel/career-seeker', { params: { orgCode: org, timeframe } })
        // .then((response) => {
        //
        //     if (response.data.success) {
        //       console.log('Stage query worked', response.data);
        //
        //       let signupsCount = 0
        //       let aResumeCount = 0
        //       let aProjectCount = 0
        //       let anAssessmentCount = 0
        //       let aGoalCount = 0
        //       let anEventCount = 0
        //       let anApplicationCount = 0
        //       let aReferralCount = 0
        //       let aConnectionCount = 0
        //       let aFeedbackCount = 0
        //       let hiredCount = 0
        //
        //       if (response.data.funnel) {
        //         signupsCount = response.data.funnel.signupsCount
        //         aResumeCount = response.data.funnel.aResumeCount
        //         aProjectCount = response.data.funnel.aProjectCount
        //         anAssessmentCount = response.data.funnel.anAssessmentCount
        //         aGoalCount = response.data.funnel.aGoalCount
        //         anEventCount = response.data.funnel.anEventCount
        //         anApplicationCount = response.data.funnel.anApplicationCount
        //         aReferralCount = response.data.funnel.aReferralCount
        //         aConnectionCount = response.data.funnel.aConnectionCount
        //         aFeedbackCount = response.data.funnel.aFeedbackCount
        //         hiredCount = response.data.funnel.hiredCount
        //
        //       }
        //
        //       let ethnicityData = null
        //       let opportunityYouthData = null
        //       let genderData = null
        //       let ageData = null
        //       let educationData = null
        //       let majorData = null
        //       let sourceData = null
        //       let topReferrers = null
        //
        //       if (response.data.demographicData) {
        //
        //         if (response.data.demographicData.ethnicity && response.data.demographicData.ethnicity.length > 0) {
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.ethnicity.length; i++) {
        //             if (response.data.demographicData.ethnicity[i - 1] && response.data.demographicData.ethnicity[i - 1].name) {
        //               data.push(response.data.demographicData.ethnicity[i - 1].eCount)
        //               labels.push(response.data.demographicData.ethnicity[i - 1].name)
        //             }
        //           }
        //           console.log('data and label: ', data, labels)
        //           ethnicityData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.opportunityYouth && response.data.demographicData.opportunityYouth.yesCount) {
        //           console.log('t5')
        //           let yesCount = 0
        //           if (response.data.demographicData.opportunityYouth.yesCount) {
        //             yesCount = response.data.demographicData.opportunityYouth.yesCount
        //           }
        //           let noCount = 0
        //           if (response.data.demographicData.opportunityYouth.noCount) {
        //             noCount = response.data.demographicData.opportunityYouth.noCount
        //           }
        //
        //           let data = [yesCount, noCount]
        //           let labels = ['Opportunity Youth','Not Opportunity Youth']
        //           opportunityYouthData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.gender && response.data.demographicData.gender.length > 0) {
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.gender.length; i++) {
        //             if (response.data.demographicData.gender[i - 1] && response.data.demographicData.gender[i - 1].name) {
        //               data.push(response.data.demographicData.gender[i - 1].eCount)
        //               labels.push(response.data.demographicData.gender[i - 1].name)
        //             }
        //           }
        //           console.log('data and label: ', data, labels)
        //           genderData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.age && response.data.demographicData.age.length > 0) {
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.age.length; i++) {
        //             if (response.data.demographicData.age[i - 1] && response.data.demographicData.age[i - 1].name) {
        //               data.push(response.data.demographicData.age[i - 1].eCount)
        //               labels.push(response.data.demographicData.age[i - 1].name)
        //             }
        //           }
        //           // console.log('data and label: ', data, labels)
        //           ageData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.education && response.data.demographicData.education.length > 0) {
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.education.length; i++) {
        //             if (response.data.demographicData.education[i - 1] && response.data.demographicData.education[i - 1].name) {
        //               data.push(response.data.demographicData.education[i - 1].eCount)
        //               labels.push(response.data.demographicData.education[i - 1].name)
        //             }
        //           }
        //           // console.log('data and label: ', data, labels)
        //           educationData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.majors && response.data.demographicData.majors.length > 0) {
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.majors.length; i++) {
        //             if (response.data.demographicData.majors[i - 1] && response.data.demographicData.majors[i - 1].name) {
        //               data.push(response.data.demographicData.majors[i - 1].eCount)
        //               labels.push(response.data.demographicData.majors[i - 1].name)
        //             }
        //           }
        //           // console.log('data and label: ', data, labels)
        //           majorData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //
        //         if (response.data.demographicData.referrerOrgs && response.data.demographicData.referrerOrgs.length > 0) {
        //           topReferrers = response.data.demographicData.referrerOrgs
        //
        //           let data = []
        //           let labels = []
        //           for (let i = 1; i <= response.data.demographicData.referrerOrgs.length; i++) {
        //             if (response.data.demographicData.referrerOrgs[i - 1] && response.data.demographicData.referrerOrgs[i - 1].name) {
        //               data.push(response.data.demographicData.referrerOrgs[i - 1].eCount)
        //               labels.push(response.data.demographicData.referrerOrgs[i - 1].name)
        //             }
        //           }
        //           // console.log('data and label: ', data, labels)
        //           sourceData = {
        //             datasets: [{
        //               data,
        //               backgroundColor: [
        //                 "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                 "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //               ]
        //             }],
        //             labels
        //           }
        //         }
        //       }
        //
        //       this.setState({ isLoading: false, hasCalculated: true,
        //         signupsCount, aResumeCount, aProjectCount, anAssessmentCount, aGoalCount, anEventCount,
        //         anApplicationCount, aReferralCount, aConnectionCount, aFeedbackCount, hiredCount,
        //         ethnicityData, opportunityYouthData, genderData, ageData, educationData, majorData,
        //         topReferrers, sourceData, chartOptions
        //       })
        //
        //       Axios.get('/api/assessment/insights', { params: { orgCode: org, timeframe } })
        //       .then((response) => {
        //
        //           if (response.data.success) {
        //             console.log('Career assessment insights query worked', response.data);
        //
        //             if (response.data.assessmentInsights) {
        //               console.log('a0')
        //               let skillsData = null
        //               let interestsData = null
        //               let personalityData = null
        //               let valuesData = null
        //               let functionsOfInterestData = null
        //               let industriesOfInterestData = null
        //
        //               if (response.data.assessmentInsights.skills && response.data.assessmentInsights.skills.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.skills.length; i++) {
        //                   if (response.data.assessmentInsights.skills[i - 1] && response.data.assessmentInsights.skills[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.skills[i - 1].weightedScore)
        //                     labels.push(response.data.assessmentInsights.skills[i - 1].name)
        //                   }
        //                 }
        //                 console.log('data and label: ', data, labels)
        //                 skillsData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               if (response.data.assessmentInsights.interests && response.data.assessmentInsights.interests.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.interests.length; i++) {
        //                   if (response.data.assessmentInsights.interests[i - 1] && response.data.assessmentInsights.interests[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.interests[i - 1].score)
        //                     labels.push(response.data.assessmentInsights.interests[i - 1].name)
        //                   }
        //                 }
        //                 // console.log('data and label: ', data, labels)
        //                 interestsData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               if (response.data.assessmentInsights.traits && response.data.assessmentInsights.traits.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.traits.length; i++) {
        //                   if (response.data.assessmentInsights.traits[i - 1] && response.data.assessmentInsights.traits[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.traits[i - 1].score)
        //                     labels.push(response.data.assessmentInsights.traits[i - 1].name)
        //                   }
        //                 }
        //                 // console.log('data and label: ', data, labels)
        //                 personalityData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               if (response.data.assessmentInsights.values && response.data.assessmentInsights.values.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.values.length; i++) {
        //                   if (response.data.assessmentInsights.values[i - 1] && response.data.assessmentInsights.values[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.values[i - 1].eCount)
        //                     labels.push(response.data.assessmentInsights.values[i - 1].name)
        //                   }
        //                 }
        //                 // console.log('data and label: ', data, labels)
        //                 valuesData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               if (response.data.assessmentInsights.functionsOfInterest && response.data.assessmentInsights.functionsOfInterest.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.functionsOfInterest.length; i++) {
        //                   if (response.data.assessmentInsights.functionsOfInterest[i - 1] && response.data.assessmentInsights.functionsOfInterest[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.functionsOfInterest[i - 1].eCount)
        //                     labels.push(response.data.assessmentInsights.functionsOfInterest[i - 1].name)
        //                   }
        //                 }
        //                 // console.log('data and label: ', data, labels)
        //                 functionsOfInterestData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               if (response.data.assessmentInsights.industriesOfInterest && response.data.assessmentInsights.industriesOfInterest.length > 0) {
        //
        //                 let data = []
        //                 let labels = []
        //                 for (let i = 1; i <= response.data.assessmentInsights.industriesOfInterest.length; i++) {
        //                   if (response.data.assessmentInsights.industriesOfInterest[i - 1] && response.data.assessmentInsights.industriesOfInterest[i - 1].name) {
        //                     data.push(response.data.assessmentInsights.industriesOfInterest[i - 1].eCount)
        //                     labels.push(response.data.assessmentInsights.industriesOfInterest[i - 1].name)
        //                   }
        //                 }
        //                 // console.log('data and label: ', data, labels)
        //                 industriesOfInterestData = {
        //                   datasets: [{
        //                     data,
        //                     backgroundColor: [
        //                       "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
        //                       "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
        //                     ]
        //                   }],
        //                   labels
        //                 }
        //               }
        //
        //               this.setState({ skillsData, interestsData, personalityData, valuesData, functionsOfInterestData, industriesOfInterestData })
        //             }
        //
        //           } else {
        //             console.log('no career assessment insights info found', response.data.message)
        //             this.setState({ isLoading: false })
        //           }
        //
        //       }).catch((error) => {
        //           console.log('Career assessment insights query did not work', error);
        //
        //       });
        //
        //     } else {
        //       console.log('no stage info found', response.data.message)
        //       this.setState({ isLoading: false })
        //     }
        //
        // }).catch((error) => {
        //     console.log('Stage info query did not work', error);
        //     this.setState({ isLoading: false })
        // });

      } else {
        this.setState({ isLoading: true })
        Axios.get('/api/stages', { params: { orgCode: org } })
        .then((response) => {

            if (response.data.success) {
              console.log('Stage query worked', response.data);

              const users = response.data.users
              const students = users
              const submittedProjectStage = response.data.submittedProjectStage
              const applicationStage = response.data.applicationStage
              const approvedStage = response.data.approvedStage
              const referredStage = response.data.referredStage
              const connectedStage = response.data.connectedStage
              const hiredStage = response.data.hiredStage

              stageOptions = [
                { name: 'Sign Ups', description: 'The sign ups stage encompasses all career seekers who have added first name, last name, email, and password.', count: students.length },
                { name: 'Submitted Project', description: 'The submitted project stage encompasses all career seekers who have submitted a project to a prompt (i.e. assignment, problem, challenge).', count: submittedProjectStage.length },
                { name: 'Applied', description: 'The applied stage encompasses all career seekers that have applied to at least one internship / work posting.', count: applicationStage.length },
                { name: 'Referred', description: 'The referred stage encompasses all career seekers who have been referred to at least one role.', count: referredStage.length },
                { name: 'Connected', description: 'The connected stage encompasses all career seekers who have been connected to at least one employer for an interview.', count: connectedStage.length },
                { name: 'Hired', description: 'The hired stage encompasses all career seekers who have been hired.', count: hiredStage.length }
              ]

              if (interviewRequested) {
                stageOptions[4] = { name: 'Interview Requested', description: 'The interview requested stage encompasses all career seekers who have requested to interview by at least one employer for an interview.', count: connectedStage.length }
              }

              if (org === 'unite-la') {
                stageOptions.splice(3, 0, { name: 'Approved', description: 'The approved stage encompasses all career seekers who have been interviewed and approved for at least one career track', count: approvedStage.length })
              }

              this.setState({ stageOptions, users, students, submittedProjectStage, applicationStage, approvedStage, referredStage, connectedStage, hiredStage, isLoading: false, hasCalculated: true })

            } else {
              console.log('no stage info found', response.data.message)
              this.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('Stage info query did not work', error);
            this.setState({ isLoading: false })
        });
      }
    }

    closeModal(passedSelectedPlacement) {
      console.log('closeModal called: ', passedSelectedPlacement)

      let workArray = this.state.workArray
      if (passedSelectedPlacement && passedSelectedPlacement.title) {
        workArray[this.state.selectedIndex] = passedSelectedPlacement
      }

      this.setState({ modalIsOpen: false, workArray, showAddGroup: false, showPost: false, showAddPipeline: false,
        showShareButtons: false, showQuestion: false, showOrgDetails: false, selectedOrg: null,
        selectedItem: null, selectedType: null, showTempScreen: false, showChangePassword: false
      });

    }

    calculateMatches(index, applications, selectedJob, benchmark, benchmarkOptions, orgCode) {
      console.log('calculateMatches called: ', index, applications, selectedJob, benchmark, benchmarkOptions, orgCode)

      for (let i = 1; i <= applications.length; i++) {
        console.log(i, 'calculating matches', applications[i - 1].email);

        const application = applications[i - 1]
        const interviewScore = null
        const applicationCount = i

        Axios.post('/api/applications/matches', {
          application, selectedJob, benchmark, interviewScore })
        .then((response) => {
          console.log('application matches query attempted')
          if (response.data.success) {
            //save values
            console.log('Application matches save worked: ', response.data);

            applications[applicationCount - 1] = response.data.application

            if (applications.length === applicationCount) {
              console.log('equated')
              applications.sort(function(a,b) {
                return a.match - b.match;
              })

              let applicationsArray = this.state.applications
              applicationsArray[index] = applications

              let topCandidatesArray = []
              for (let j = 1; j <= applications.length; j++) {
                //console.log(i)

                if (!orgCode || orgCode === '' || orgCode === 'guidedcompass') {
                  console.log('t0', selectedJob, applications[j - 1].partnerEvaluations)

                  // sent by placement partners
                  if (applications[j - 1].partnerEvaluations && applications[j - 1].partnerEvaluations.length > 0) {
                    console.log('in this org 2')
                    for (let k = 1; k <= applications[j - 1].partnerEvaluations.length; k++) {
                      if (applications[i - 1].partnerEvaluations[k - 1].rank) {
                        applications['referrerOrg'] = applications[i - 1].orgCode
                        topCandidatesArray.push(applications[j - 1])
                      }
                    }
                  } else {
                    // recommended by Guided Compass?
                    console.log('add rank')
                    if (applications[j - 1].rank) {
                      topCandidatesArray.push(applications[j - 1])
                    }
                  }

                } else {
                  if (applications[j - 1].rank) {
                    topCandidatesArray.push(applications[j - 1])
                  }
                }
              }

              topCandidatesArray.sort(function(a,b) {
                return a.rank - b.rank;
              })

              let topCandidates = this.state.topCandidates
              topCandidates[index] = topCandidatesArray
              console.log('show values--', index, applicationsArray, topCandidates, this.state.applications)
              this.setState({ applications: applicationsArray, topCandidates })

            }

          } else {
            console.log('application matches did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Application matches did not work for some reason', error);
        });

      }
    }

    renderPostings() {
      console.log('renderPostings')

      let rows = []
      let workCount = 0
      for (let i = 1; i <= this.state.postings.length; i++) {

        const index = i - 1

        if (this.state.postings[i - 1].postType === 'Work' || this.state.postings[i - 1].postType === 'Internship' || this.state.postings[i - 1].postType === 'Individual') {
          workCount = workCount + 1

          rows.push(
            <div key={i}>
              <div className="spacer"/>
              <div>
                <div className="calc-column-offset-80">
                  <Link to={"/employers/" + this.state.accountCode + "/postings/" + this.state.postings[index]._id} state={{ selectedOpportunity: this.state.postings[index]}} className="standard-color">
                    <p className="heading-text-4">{this.state.postings[i - 1].title}</p>
                  </Link>
                </div>
                {(this.state.topCandidates && this.state.topCandidates[index] && this.state.topCandidates[index].length > 0) ? (
                  <div className="float-left">
                    <div className="fixed-column-60">
                      <Switch
                        onChange={(change) => this.toggleRoundReadiness(index, change)}
                        checked={this.state.postings[index].readyForNewRound}
                        disabled={this.state.isSaving}
                        id="normal-switch"
                      />
                    </div>
                    <div className="fixed-column-20 top-margin-negative-6 left-padding-5">
                      <label className="heading-text-2 bold-text cta-color"><a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: true, editWork: false, timesheetInfo: false, approveTimesheet: false, editAssignment: false, viewWorkers: false })}>?</a></label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p> </p>
                  </div>
                )}
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}

              </div>

              {(this.state.mappedOffers && this.state.mappedOffers[i - 1] && this.state.mappedOffers[i - 1].length > 0) && (
                <div className="row-5">
                  <label className="error-color">{this.state.mappedOffers[i - 1].length} Outstanding Offers:</label>

                  {this.state.mappedOffers[i - 1].map((value, index) =>
                    <span>
                      {((index + 1) === this.state.mappedOffers[i - 1].length) ? (
                        <label className="error-color"> {value.firstName} {value.lastName}</label>
                      ) : (
                        <label className="error-color"> {value.firstName} {value.lastName},</label>
                      )}
                    </span>
                  )}
                </div>
              )}

              {this.state.postings[i - 1].placed && (
                <div>
                  <label className="error-color">You have successfully hired for this role. Contact {this.state.contactEmail} if you would like to re-open this posting.</label>
                </div>
              )}

              <div className="spacer"/>
              {this.renderTopCandidates(index, workCount)}
              <div className="clear" />

              {(!this.state.orgMode) && (
                <div>

                  {(this.state.applications[index] && this.state.applications[index].length > 0) && (
                    <div>
                      <div className="spacer" /><div className="spacer" />
                      <Link className="secret-link" to={'/employers/' + this.state.emp + '/postings/' + this.state.postings[index]._id + '/applications'} state={{ selectedJob: this.state.postings[index], applications: this.state.applications[index], topCandidates: this.state.topCandidates[index], benchmark: this.state.benchmarks[workCount - 1] }}>
                        <div className="benchmark-container-1">
                          <p className="description-text-2 bold-text cta-color">View All{(this.state.applications[index].length !== 1) && " " + this.state.applications[index].length} Applicants ></p>
                        </div>
                      </Link>
                      <div className="clear"/>
                      <div className="spacer"/>
                    </div>
                  )}

                </div>
              )}

              <div className="spacer" /><div className="spacer" />

              {(this.state.postings[index + 1]) && (
                <div>
                  <hr className="clear-margin"/>
                  <div className="spacer"/>
                </div>
              )}

            </div>
          )
        }
      }

      return rows
    }

    toggleRoundReadiness(index, change) {
      console.log('toggleRoundReadiness called', index, change)

      let postings = this.state.postings
      const placementPartners = postings[index].placementPartners
      const _id = postings[index]._id
      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      Axios.post('/api/postings/new-round', {
        _id, change, placementPartners })
      .then((response) => {
        console.log('posting round query attempted')
        if (response.data.success) {
          //save values
          console.log('Posting round save worked', response.data);
          postings[index] = response.data.posting

          let successMessage = 'We sent an email notification to placement partner(s).'
          if (this.state.orgMode) {
            successMessage = 'An email notification has been sent to ' + this.state.orgName + ' that you are ready for new candidates.'
          }

          if (!change) {
            successMessage = 'Your changes have been saved'
          }

          this.setState({ postings, isSaving: false, successMessage })


        } else {
          console.log('posting round did not work', response.data.message)
          this.setState({ isSaving: false, errorMessage: 'There was an error: ' + response.data.message })
        }
      }).catch((error) => {
          console.log('Posting round did not work for some reason', error);
          this.setState({ isSaving: false, errorMessage: 'There was an error: ' + error.toString() })
      });
    }

    changeItem(name, index, value) {
      console.log('changeItem called', name, index, value)

      const _id = this.state.workArray[index]._id

      Axios.post('/api/work/toggle-timesheet-enabled', { _id, timesheetEnabled: value })
      .then((response) => {
        console.log('toggle timesheet enabled attempted')
        if (response.data.success) {
          //save values
          console.log('Toggle timesheet enabled save worked', response.data);

          let workArray = this.state.workArray
          workArray[index]['timesheetEnabled'] = value
          this.setState({ workArray })

        } else {
          console.log('toggle timesheet enabled did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Toggle timesheet enabled did not work for some reason', error);
      });
    }

    renderTopCandidates(index, workCount) {
      console.log('renderTopCandidates called', index, workCount, this.state.topCandidates, this.state.benchmarks)

      let rows = []

      if (this.state.topCandidates && this.state.topCandidates[index] && this.state.topCandidates[index].length !== 0) {
        console.log('selBen: ', this.state.benchmarks[index], this.state.benchmarks[workCount - 1], this.state.benchmarks[0][workCount - 1])

        rows.push(
          <div key={"topcandidates" + 0}>
            <div className="spacer" /><div className="spacer" />
            <div className="benchmark-container-1">
              <p className="heading-text-6">Referred Candidates</p>
            </div>
            <Link className="secret-link" to={'/employers/' + this.state.emp + '/postings/' + this.state.postings[index]._id + '/top-candidates'} state={{ selectedJob: this.state.postings[index], applications: this.state.applications[index], topCandidates: this.state.topCandidates[index], selectedBenchmark: this.state.benchmarks[workCount - 1] }}>
              <div className="benchmark-container-4">
                <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                <label>See All ></label>
              </div>
            </Link>
            <div className="clear"/>
            <div className="spacer"/>
          </div>
        )

        for (let i = 1; i <= this.state.topCandidates[index].length; i++) {

          const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
          // console.log('show matchScore -----: ', primaryColor)

          rows.push(
            <div key={"topcandidates" + i}>
              <div className="relative-column-33">
                <Link className="secret-link" to={'/employers/' + this.state.emp + '/postings/' + this.state.postings[index]._id + '/top-candidates'} state={{ selectedJob: this.state.postings[index], applications: this.state.applications[index], topCandidates: this.state.topCandidates[index], selectedBenchmark: this.state.benchmarks[workCount - 1] }}>
                  <div className="width-80-percent center-item">

                    <div className="width-150 center-item padding-10">
                      <CircularProgressbar
                        value={this.state.topCandidates[index][i - 1].match}
                        text={`${this.state.topCandidates[index][i - 1].match}%`}
                        styles={{
                          path: { stroke: `${primaryColor}, ${this.state.topCandidates[index][i - 1].match / 100}` },
                          text: { fill: primaryColor, fontSize: '24px' },
                          trail: { stroke: 'transparent' }
                        }}
                      />
                    </div>

                  </div>
                  <p className="heading-text-5 center-text">{this.state.topCandidates[index][i - 1].firstName} {this.state.topCandidates[index][i - 1].lastName}</p>
                  <div className="half-spacer"/>
                  <p className="description-text-2 center-text">View Application</p>
                  <div className="spacer"/>
                </Link>
              </div>
            </div>
          )
        }
      } else {
        rows.push(
          <div key={"nocandidates"}>
            <div className="spacer"/>
            {(this.state.postings[index].isActive === false) ? (
              <div>
                <p className="description-text-2 description-text-color">This posting is hidden / inactive.</p>
              </div>
            ) : (
              <div>
                {this.state.activeOrg === 'c2c' ? (
                  <p className="description-text-2 description-text-color">{this.state.internshipEmptyStateMessage}</p>
                ) : (
                  <p className="description-text-2 description-text-color">{this.state.orgName} is qualifying candidates to send your way.  Send questions to {this.state.contactTitle}, {this.state.contactFirstName} {this.state.contactLastName} ({this.state.contactEmail}). Stay Tuned!</p>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    passStatus = (action) => {
      console.log('passStatus called', action)
      let timesheets = this.state.timesheets

      timesheets.splice(this.state.selectedIndex,1)

      this.setState({ timesheets })
    }

    shareProfile(e) {
      console.log('shareProfile called')

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      this.setState({ modalIsOpen: true, showShareButtons: true })
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      const shareURL = this.state.shareURL
      const shareTitle = 'Check Out Our Profile on Guided Compass'
      const shareBody = "Guided Compass is a career planning, matching, and community platform that helps career-seekers reach their purpose-driven careers and helps employers build their talent pipelines."

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={shareURL} subject={shareTitle} body={shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={shareURL} title={shareTitle} summary={shareBody} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={shareURL} description={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    passPosts(socialPosts) {
      console.log('passPosts called')

      this.setState({ socialPosts })
    }

    itemClicked(e, passedState) {
      console.log('itemClicked called')

      e.preventDefault()

      this.setState(passedState)
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      // this.setState({ org })
      this.retrieveData()
    }

    orgClicked(index, type) {
      console.log('orgClicked clicked', index, type, this.state.roleName)

      if (this.state.activeOrg !== this.state.trainingProviders[index].orgCode) {
        let activeOrg = this.state.trainingProviders[index].orgCode
        // if (this.state.activeOrg !== this.state.sharePartners[index]) {
        //   activeOrg = this.state.sharePartners[index]
        // }

        this.setState({ activeOrg })
        localStorage.setItem('activeOrg', activeOrg)

        const accountCode = this.state.accountCode
        const updatedAt = new Date()

        Axios.post('/api/account/update', {
          accountCode, activeOrg, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Account update worked', response.data);
            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Account information saved successfully!', activeOrg, modalIsOpen: false
            })

            this.retrieveData()

          } else {
            console.error('there was an error updating the account info');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Account info save did not work', error);
        });
      }
    }

    removeOrg(index,type) {
      console.log('removeOrg called', this.state.selectedOrg, index, type)

      if (this.state.trainingProviders.length > 0) {
        // this.setState({ activeOrg })
        // localStorage.setItem('activeOrg', activeOrg)

        const accountCode = this.state.accountCode
        const updatedAt = new Date()

        Axios.post('/api/account/update', {
          accountCode, activeOrg: this.state.trainingProviders[index].orgCode, removeOrg: true, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Account update worked', response.data);

            let changeActiveOrg = false
            if (this.state.trainingProviders[index].orgCode === this.state.activeOrg) {
              changeActiveOrg = true
            }

            let sharePartners = this.state.sharePartners
            let removeIndex = null
            for (let i = 1; i <= this.state.sharePartners.length; i++) {
              if (this.state.sharePartners[i - 1] === this.state.trainingProviders[index].orgCode) {
                removeIndex = i - 1
              }
            }
            sharePartners.splice(removeIndex,1)
            localStorage.setItem('sharePartners', JSON.stringify(sharePartners))

            let trainingProviders = this.state.trainingProviders
            trainingProviders.splice(index,1)

            const serverPostSuccess = true
            const serverSuccessMessage = 'Org successfully removed!'

            if (changeActiveOrg) {
              let activeOrg = sharePartners[0]
              localStorage.setItem('activeOrg', activeOrg)
              this.setState({ activeOrg, sharePartners, trainingProviders, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
              this.props.passOrgs(activeOrg)
            } else {
              this.setState({ sharePartners, trainingProviders, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
            }

          } else {
            console.error('there was an error updating the account info');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Account info save did not work', error);
        });
      } else {
        this.setState({
          serverPostSuccess: false,
          serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
        })
      }
    }

    render() {

      return (
          <div>
              <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

              {(this.state.deactivated) ? (
                <div>
                  <DeactivatedModal orgName={this.state.orgName} accountCode={this.state.emp} roleName={this.state.roleName} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} redirectLink={this.state.redirectLink} />
                </div>
              ) : (
                <div>
                  {(this.state.internalToolEnabled) ? (
                    <div>
                      <div className="top-margin-30">
                        <div className="relative-column-70 horizontal-padding">
                          <div className="right-padding-20">

                            <div className="card-clear-padding min-width-100 max-width-1400 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />

                              <div className="padding-40">
                                <p className="heading-text-2">Key Performance Indicators</p>

                                <div className="spacer" />

                                <div className="full-width row-30">
                                  <div className="relative-column-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Number of employees signed up">
                                    <p className="full-width heading-text-2 cta-color center-text">31</p>
                                    <p className="full-width description-text-2 description-text-color center-text top-margin-5">Employees</p>
                                  </div>
                                  <div className="relative-column-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Percentage of employees happy with their work at " + this.state.employerName}>
                                    <p className="full-width heading-text-2 cta-color center-text">40%</p>
                                    <p className="full-width description-text-2 description-text-color center-text top-margin-5">Satisfaction Rate</p>
                                  </div>
                                  <div className="relative-column-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Annual percentage of employees retained at " + this.state.employerName}>
                                    <p className="full-width heading-text-2 cta-color center-text">40%</p>
                                    <p className="full-width description-text-2 description-text-color center-text top-margin-5">Retention Rate</p>
                                  </div>
                                  <div className="relative-column-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Annual percentage of employees who has referred at least one person for a job opening"}>
                                    <p className="full-width heading-text-2 cta-color center-text">40%</p>
                                    <p className="full-width description-text-2 description-text-color center-text top-margin-5">Referral Rate</p>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {/*
                                <hr />

                                <div className="full-width top-margin-30">
                                  <div className="relative-column-25">
                                    <p className="full-width top-margin-5"><label className="cta-color bold-text">40%</label> Clarity Score</p>
                                    <p className="full-width top-margin-20"><label className="cta-color bold-text">40%</label> Work Style Score</p>
                                  </div>
                                  <div className="relative-column-25">
                                    <p className="full-width top-margin-5"><label className="cta-color bold-text">40%</label> Valued Score</p>
                                    <p className="full-width top-margin-20"><label className="cta-color bold-text">40%</label> Management Score</p>
                                  </div>
                                  <div className="relative-column-25">

                                    <p className="full-width top-margin-5"><label className="cta-color bold-text">40%</label> Pay Score</p>
                                    <p className="full-width top-margin-20"><label className="cta-color bold-text">40%</label> Cohesion Score</p>
                                  </div>
                                  <div className="relative-column-25">
                                    <p className="full-width top-margin-5"><label className="cta-color bold-text">40%</label> Growth Score</p>
                                    <p className="full-width top-margin-20"><label className="cta-color bold-text">40%</label> Referral Score</p>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <p># of employees, satisfaction rate, retention rate, referral rate</p>
                                <p>clarity score, valued score, pay score, growth score, work style score, management score, cohesion score, referrals</p>*/}
                              </div>

                            </div>

                            <div className="card-clear-padding min-width-100 max-width-1400 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />

                              <div className="padding-40">
                                <p className="heading-text-2">Top Competency Needs</p>
                                <div className="spacer" /><div className="spacer" />

                                {(this.state.competencyNeeds) && (
                                  <div>
                                    {this.state.competencyNeeds.map((value, index) =>
                                      <div key={value} className="full-width bottom-margin">
                                        <div className="calc-column-offset-120">
                                          <p>{index + 1}. {value.name}</p>
                                        </div>
                                        <div className="fixed-column-40">
                                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Employee Matches" })}>
                                            <img src={socialIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View Top Employee Matches" />
                                          </button>
                                        </div>
                                        <div className="fixed-column-40">
                                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Course Matches" })}>
                                            <img src={courseIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View Top Course Matches" />
                                          </button>
                                        </div>
                                        <div className="fixed-column-40">
                                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Goal Matches" })}>
                                            <img src={tagIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View Top Employees' Goals Matches" />
                                          </button>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                            </div>

                            <div className="card-clear-padding min-width-100 max-width-1400 pin-right bottom-margin-20">
                              <div className="full-width height-5 primary-background" />

                              <div className="padding-40">
                                <p className="heading-text-2">Employee Retention Factor Profile</p>
                                <div className="spacer" />

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Skills - Highest Avg Score</p>
                                      <div className="spacer" />
                                      {(this.state.skillsData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.skillsData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Top Personalities</p>
                                      <div className="spacer" />
                                      {(this.state.personalityData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.personalityData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Interests - Highest Scores</p>
                                      <div className="spacer" />
                                      {(this.state.interestsData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.interestsData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Top Values</p>
                                      <div className="spacer" />
                                      {(this.state.valuesData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.valuesData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Top Functions of Interests</p>
                                      <div className="spacer" />
                                      {(this.state.functionsOfInterestData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.functionsOfInterestData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Top Industries of Interest</p>
                                      <div className="spacer" />
                                      {(this.state.industriesOfInterestData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.industriesOfInterestData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-20">
                                  <hr />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Clarity Score</p>
                                      <div className="spacer" />
                                      {(this.state.clarityData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.clarityData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Valued Score</p>
                                      <div className="spacer" />
                                      {(this.state.valuedData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.valuedData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Pay Score</p>
                                      <div className="spacer" />
                                      {(this.state.payData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.payData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Upside Participation Score</p>
                                      <div className="spacer" />
                                      {(this.state.upsideParticipationData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.upsideParticipationData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Challenged Score</p>
                                      <div className="spacer" />
                                      {(this.state.challengedData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.challengedData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Work Style Score</p>
                                      <div className="spacer" />
                                      {(this.state.workStyleData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.workStyleData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="card">
                                      <p className="heading-text-4">Management Score</p>
                                      <div className="spacer" />
                                      {(this.state.managementData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.managementData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="card">
                                      <p className="heading-text-4">Cohesion Score</p>
                                      <div className="spacer" />
                                      {(this.state.cohesionData) && (
                                        <div className="full-width">
                                          <Doughnut
                                            data={this.state.cohesionData}
                                            options={this.state.chartOptions}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                        <div className="relative-column-30 horizontal-padding">
                          <div className="right-padding-20">

                            <div className="card-clear-padding min-width-100 max-width-260 pin-left bottom-margin-20">
                              <div className="full-width height-5 senary-background" />

                              <div className="padding-30">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4">OKRs</p>
                                  </div>
                                  <div className="fixed-column-30">
                                    <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Add an OKR" })}>
                                      <img src={addIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a OKR" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>


                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.okrs.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <Link className="background-button full-width standard-color profile-container-left" to={'/employers/' + this.state.accountCode + '/okrs'}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={targetIconOrange} className="image-25-fit" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-1">{value.objective.title}</p>
                                            <p className="description-text-3">{value.objective.endDate}</p>
                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-left">
                                          <div className="bottom-padding-5">
                                            <div className="full-width">
                                              <p className="description-text-1">{value.objective.title}</p>
                                              <p className="description-text-4 description-text-color">{value.objective.startDate} - {value.objective.endDate}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />

                                          <div className="spacer" />

                                          {value.results.map((value2, index2) =>
                                            <div key={value2} className="top-margin">
                                              <p className="description-text-3">{index2 + 1}. {value2.title}</p>
                                              <p className="description-text-5 description-text-color top-padding-5">{value2.priority} Priority | {value2.startDate} - {value2.endDate} | {value2.teams.toString()}</p>
                                              <p className="description-text-5 top-padding-5">Status: {value2.status}</p>
                                            </div>
                                          )}

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.okrs.length > 0) && (
                                  <div>
                                    <Link className="background-button" to={'/employers/' + this.state.emp + '/okrs'} state={{ subNavSelected: 'Work' }}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>

                            </div>

                            <div className="card-clear-padding min-width-100 max-width-260 pin-left bottom-margin-20">
                              <div className="full-width height-5 senary-background" />

                              <div className="padding-30">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4">Surveys</p>
                                  </div>
                                  <div className="fixed-column-30">
                                    <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Add a Survey" })}>
                                      <img src={addIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a Survey" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.surveys.map((value, index) =>
                                  <div key={value}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <Link className="background-button full-width standard-color profile-container-left" to={'/employers/' + this.state.accountCode + '/surveys'}>
                                          <div className="fixed-column-35 height-50">
                                            <img src={targetIconOrange} className="image-25-fit" alt="GC" />
                                          </div>
                                          <div className="calc-column-offset-35">
                                            <p className="description-text-1">{value.title}</p>
                                            <p className="description-text-3">{convertDateToString(new Date(value.startDate),'datetime-2')} {convertDateToString(new Date(value.endDate),'datetime-2')}</p>
                                          </div>
                                          <div className="clear" />
                                        </Link>

                                        <div className="profile-modal-left">
                                          <div className="bottom-padding-5">
                                            <div className="full-width">
                                              <p className="description-text-1">{value.title}</p>
                                              <p className="description-text-4 description-text-color">{convertDateToString(new Date(value.startDate),'datetime-2')} - {convertDateToString(new Date(value.endDate),'datetime-2')}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />

                                          <div className="spacer" />

                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.surveys.length > 0) && (
                                  <div>
                                    <Link className="background-button" to={'/employers/' + this.state.emp + '/surveys'} state={{ subNavSelected: 'Work' }}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/*
                            <div className="card-clear-padding min-width-100 max-width-260 pin-left bottom-margin-20">
                              <div className="full-width height-5 senary-background" />

                              <div className="padding-30">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4">Performance Reviews</p>
                                  </div>
                                  <div className="fixed-column-30">
                                    <button className="background-button" onClick={() => console.log('')}>
                                      <img src={addIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a Survey" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <p>survey schedule, survey results</p>
                                <p>red flags, green flags, competency needs</p>
                              </div>
                            </div>*/}

                            <div className="card-clear-padding min-width-100 max-width-260 pin-left bottom-margin-20">
                              <div className="full-width height-5 senary-background" />

                              <div className="padding-30">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4">Upskilling Resources</p>
                                  </div>
                                  <div className="fixed-column-30">
                                    <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showTempScreen: true, tempScreenName: "Recommend an Upskilling Course/Resource" })}>
                                      <img src={addIconBlue} alt="GC" className="image-20-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Recommend a Course" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="spacer" /><div className="half-spacer" />

                                {(this.state.courses && this.state.courses.length > 0) ? (
                                  <div>
                                    {this.state.courses.map((value, index) =>
                                      <div key={value}>
                                        {(index < 3) && (
                                          <div className="bottom-padding full-width">
                                            <a href={(value.url) ? 'https://www.udemy.com' + value.url : value.programURL} className="background-button full-width" target="_blank">
                                              <div className="fixed-column-35 height-50">
                                                <img src={(value.imageURL) ? value.imageURL : (value.image_125_H) ? value.image_125_H : courseIconDark} className="image-25-fit" alt="GC" />
                                              </div>
                                              <div className="calc-column-offset-35">

                                                <p className="description-text-1">{(value.name) ? value.name : value.title}</p>
                                                <p className="description-text-3 description-text-color">{(value.description) ? value.description : value.headline}</p>

                                              </div>
                                              <div className="clear" />
                                            </a>

                                            <div className="profile-modal-left">
                                              <div className="bottom-padding-5">
                                                <div className="fixed-column-35 height-50">
                                                  <img src={(value.imageURL) ? value.imageURL : (value.image_125_H) ? value.image_125_H : courseIconDark} className="image-auto-25" alt="GC" />
                                                </div>
                                                <div className="calc-column-offset-35">
                                                  <p className="description-text-1">{(value.name) ? value.name : value.title}</p>
                                                  <p className="description-text-3 description-text-color">{(value.description) ? value.description : value.headline}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                              <div className="clear" />

                                              <div className="top-padding-20">
                                                <Link className="background-button full-width standard-color" to={'/app/opportunities/' + value._id}><button className="btn btn-squarish full-width">Apply</button></Link>
                                              </div>

                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    {(this.state.courses.length > 0) && (
                                      <div>
                                        <Link className="background-button" to={'/employers/' + this.state.accountCode + '/courses'} state={{ subNavSelected: 'Events' }}>
                                          <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                  </div>
                                )}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clear" />
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {(this.state.renderSideNav) && (
                        <SubSideNav selectedComponent={"EmpDashboard"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} accountCode={this.state.emp} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />
                      )}

                      <div className="top-margin-30">
                        {(!this.state.orgMode || this.state.showModule) &&  (
                          <div className="relative-column-30 horizontal-padding">
                            <div className="right-padding-20">

                              <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                                <div className="full-width height-5 primary-background" />

                                <Link to={'/employers/' + this.state.accountCode + '/edit-employer-profile'} className="background-button full-width standard-color">
                                  <div className="top-padding-2">
                                    <div className="full-width top-padding right-text">
                                      <button className="background-button right-margin" onClick={(e) => this.shareProfile(e)}>
                                        <img src={shareIconDark} alt="GC" className="image-auto-13" />
                                      </button>
                                    </div>
                                    <div className="bottom-padding">
                                      <img src={(this.state.employerLogo) ? this.state.employerLogo : industryIconDark} className="profile-thumbnail-6 center-horizontally" alt="GC" />
                                    </div>
                                  </div>
                                  <div className="row-5 center-text">
                                    <p className="heading-text-4">{this.state.employerName}</p>
                                  </div>

                                  <div className="bottom-padding horizontal-padding full-width">
                                    <p className="full-width center-text description-text-color description-text-3">{(this.state.followers && this.state.followers.length > 0) ? this.state.followers.length : "0"} Followers</p>
                                  </div>

                                  <div className="row-10 horizontal-padding full-width profile-container-right">
                                    <div className="progress-bar-thin" >
                                      <div className="filler" style={{ width: Math.round((this.state.checklistTracker.length / this.state.profileChecklist.length) * 100) + '%' }} />
                                    </div>
                                    <p className="description-text-4 description-text-color top-padding-5">Profile is {(this.state.checklistTracker.length / this.state.profileChecklist.length) * 100}% complete</p>
                                  </div>

                                  <div className="profile-modal-right">
                                    <p className="heading-text-5">Profile is {(this.state.checklistTracker.length / this.state.profileChecklist.length) * 100}% Complete</p>

                                    <div className="spacer" />

                                    {this.state.profileChecklist.map((value, index) =>
                                      <div key={value}>
                                        {(value.type === 'link') ? (
                                          <Link to={value.url} state={value.passedState} className="background-button standard-color full-width left-text">
                                            <div className="description-text-2 row-10">
                                              <div className="fixed-column-20">
                                                <p>{index + 1}.</p>
                                              </div>
                                              <div className="calc-column-offset-50 left-text">
                                                <p>{value.name}</p>
                                              </div>
                                              {(this.state.checklistTracker.includes(value.name)) && (
                                                <div className="fixed-column-30 left-padding">
                                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                                  <img src={checkmarkIcon} alt="GC" className="image-auto-13" />
                                                </div>
                                              )}

                                              <div className="clear" />
                                            </div>
                                          </Link>
                                        ) : (
                                          <button onClick={(e) => this.itemClicked(e, value.passedState)} className="background-button left-text full-width">
                                            <div className="description-text-2 row-5">
                                              <div className="fixed-column-20">
                                                <p>{index + 1}.</p>
                                              </div>
                                              <div className="calc-column-offset-45">
                                                <p>{value.name}</p>
                                              </div>
                                              {(this.state.checklistTracker.includes(value.name)) && (
                                                <div className="fixed-column-25 left-padding">
                                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                                  <img src={checkmarkIcon} alt="GC" className="image-auto-13" />
                                                </div>
                                              )}
                                              <div className="clear" />
                                            </div>
                                          </button>
                                        )}

                                      </div>
                                    )}

                                  </div>
                                </Link>

                                <div className="horizontal-padding bottom-padding-15">
                                  <hr />
                                </div>

                                <Link to={'/employers/' + this.state.accountCode + '/edit-profile'} className="background-button full-width standard-color">
                                  <div className="spacer" />
                                  <div className="row-3 center-text">
                                    <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} className="profile-thumbnail-2 center-horizontally" alt="GC" />
                                  </div>
                                  <div className="row-3 center-text">
                                    <p>{this.state.cuFirstName} {this.state.cuLastName}</p>
                                  </div>
                                  {(this.state.jobTitle && this.state.location) && (
                                    <div className="row-3 center-text">
                                      <p className="description-text-2">{this.state.jobTitle} in {this.state.location}</p>
                                    </div>
                                  )}

                                </Link>

                                <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

                              </div>

                              {(this.state.pipelines && this.state.pipelines.length > 0) ? (
                                <div>
                                  <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                                    <div className="full-width height-5 primary-background" />

                                    <div className="padding-20">
                                      <p className="heading-text-6">{this.state.employerName} Talent Pipeline</p>
                                      <p className="description-text-2 top-padding-5 description-text-color">Top candidates across pathways</p>
                                      <div className="spacer" /><div className="spacer" />

                                      {(this.state.topPipelineCandidates && this.state.topPipelineCandidates.length > 0) ? (
                                        <div>
                                          {this.state.topPipelineCandidates.map((value, index) =>
                                            <div key={value.email}>
                                              <Link to={"/employers/" + this.state.accountCode + "/career-seekers/" + value.email}>
                                                <div className="row-10">
                                                  <div className="fixed-column-40">
                                                    <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                                  </div>
                                                  <div className="calc-column-offset-90">
                                                    <p className="description-text-1">{value.firstName} {value.lastName}</p>
                                                    {(value.education && value.education.length > 0) && (
                                                      <p className="description-text-4">{value.education[0].name + ' ' + value.education[0].endDate}</p>
                                                    )}
                                                    <p className="description-text-4">{value.pathway}</p>
                                                  </div>
                                                  <div className="fixed-column-50">
                                                    <p className="heading-text-6 cta-color full-width right-text">{value.match}%</p>
                                                  </div>
                                                  <div className="clear" />
                                                </div>
                                              </Link>
                                            </div>
                                          )}

                                          <div className="spacer" /><div className="spacer" />

                                          <hr />

                                          <div className="spacer" />

                                          <Link to={"/employers/" + this.state.accountCode + "/pipelines"}>
                                            <p className="full-width center-text description-text-1">View All</p>
                                          </Link>

                                          <div className="spacer" />
                                        </div>
                                      ) : (
                                        <div className="bottom-padding-20">
                                          <p className="description-text-2">Candidates for one or more pipelines have not been created yet. Please click on a pipeline <Link to={"/employers/" + this.state.accountCode + "/pipelines"}>here</Link> to view top matches.</p>
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>

                                  <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                                    <div className="full-width height-5 error-background-color" />
                                    <div className="padding-30">
                                      <div className="full-width">
                                        <div className="relative-column-10">
                                          <div className="width-30 height-40" />
                                        </div>
                                        <div className="relative-column-80">
                                          <img src={socialIconGrey} alt="GC" className="image-70-fit center-horizontally" />
                                        </div>
                                        <div className="relative-column-10">
                                          <p className="error-color description-text-3">NEW</p>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                      <p className="heading-text-5 full-width center-text top-padding-20">Create a Talent Retention Community</p>

                                      <p className="top-padding-20 full-width center-text description-text-2">Create internal career pathways, integrate 360 performance reviews, upskill, and engage talent on scheduled cadences so that they stay!</p>

                                      <div className="row-10 center-text">
                                        <button className="btn btn-squarish error-background-color full-width top-margin-20 error-border" onClick={() => this.setState({ modalIsOpen: true, showQuestion: true })}>
                                          <p>Learn More</p>
                                        </button>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                                    <div className="full-width height-5 primary-background" />

                                    <div className="padding-20">
                                      <p className="heading-text-6">Add a Talent Pipeline</p>
                                      <div className="spacer" /><div className="spacer" />

                                      <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showAddPipeline: true })}>
                                        <div className="wash-out-2">
                                          <div className="row-10">
                                            <div className="fixed-column-40">
                                              <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/bianca_esquivias.jpeg'} alt="GC" className="profile-thumbnail-30" />
                                            </div>
                                            <div className="calc-column-offset-90">
                                              <p className="description-text-1">Bianca Esquivias</p>
                                              <p className="description-text-4">UC Santa Cruz '22</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="heading-text-6 cta-color full-width right-text">98%</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="row-10">
                                            <div className="fixed-column-40">
                                              <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/roberto_parra.jpeg'} alt="GC" className="profile-thumbnail-30" />
                                            </div>
                                            <div className="calc-column-offset-90">
                                              <p className="description-text-1">Roberto Parra</p>
                                              <p className="description-text-4">Cal State Northridge'22</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="heading-text-6 cta-color full-width right-text">96%</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="row-10">
                                            <div className="fixed-column-40">
                                              <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/gabriela_rosillo.jpeg'} alt="GC" className="profile-thumbnail-30" />
                                            </div>
                                            <div className="calc-column-offset-90">
                                              <p className="description-text-1">Gabby Rosillo</p>
                                              <p className="description-text-4">Cal State LA '05</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="heading-text-6 cta-color full-width right-text">94%</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>

                                        <div className="spacer" /><div className="spacer" />

                                        <hr />

                                        <div className="spacer" />

                                        <p className="full-width center-text description-text-1">Get Started</p>
                                        <div className="spacer" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className={(this.state.orgMode && !this.state.showModule) ? "width-70-percent max-width-1400 center-horizontally" : "relative-column-40"}>
                          <div className="card-clear-padding padding-20 bottom-margin-40">

                            {(this.state.hasWorkPostings) ? (
                              <div>
                                {(this.state.orgMode === true) ? (
                                  <div className="padding-20">
                                    <div>
                                      <p className="heading-text-3">Top Candidates for Your Work Postings</p>

                                      <div>
                                        <p className="description-text-2 top-padding">{this.state.orgName} has posted your available position(s) and is sharing them with qualified candidates within {this.state.orgName}. If you have any questions, please contact {this.state.contactEmail}</p>

                                        <div className="spacer"/><div className="spacer"/>
                                      </div>

                                      {this.renderPostings()}
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="heading-text-3">Top Candidates for Your Work Postings</p>
                                    <div className="spacer"/><div className="spacer"/>
                                    <div className="description-text-2">
                                      {this.renderPostings()}
                                    </div>

                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <p className="heading-text-3">Top Candidates for Your Work Postings</p>
                                <p className="description-text-color description-text-2 row-20">No work postings yet. Create <Link to={"/employers/" + this.state.accountCode + "/postings"}>here</Link>.</p>
                              </div>
                            )}

                          </div>
                          <div className="card-clear-padding padding-20 bottom-margin-40">
                            <p className="heading-text-3">Manage Hires</p>

                            {(this.state.workArray && this.state.workArray.length > 0) ? (
                              <div>
                                <div>
                                  <div>
                                    {this.state.workArray.map((value, index) =>
                                      <div>
                                        <div className="col span-1-of-3 box">
                                          <div className="float-left">
                                            <p className="heading-text-5">Positions</p>
                                          </div>

                                          <div className="clear" />

                                          <div className="top-padding-5">
                                            <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, editWork: true, selectedIndex: index, timesheetInfo: false, approveTimesheet: false, editAssignment: false })}>
                                              <div className="top-padding-15 right-padding-15">
                                                <p className="cta-color half-bold-text">{value.title}</p>

                                                <div className="description-text-2 top-padding-5">
                                                  <label className="right-padding">{value.startDate.substring(0,10)} - {value.isPerpetual ? "Continual" : value.endDate.substring(0,10)}</label>
                                                  <label className="right-padding">|</label>
                                                  <label className="right-padding">{value.hoursPerWeek} Per Week</label>

                                                  <div className="clear" />

                                                  <div className="half-spacer"/>

                                                  <div className="clear" />
                                                </div>
                                              </div>
                                            </a>
                                            <a className="background-button right-padding-5 bottom-padding-5 description-text-2 cta-color" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, editWork: false, timesheetInfo: false, approveTimesheet: false, editAssignment: false, viewWorkers: true, selectedIndex: index })}>
                                              <div className="calc-column-offset-22">
                                                <label className="right-padding">{(value.workers) ? value.workers.length : "0"} workers</label>
                                              </div>

                                              <div className="fixed-column-22">
                                                <div className="cta-border circle-corners padding-5">
                                                  <img src={addIconBlue} alt="GC" className="image-auto-8 center-item"/>
                                                </div>
                                              </div>
                                              <div className="clear" />
                                            </a>
                                          </div>

                                        </div>
                                        <div className="col span-1-of-3 box">
                                          <div>
                                            <div className="float-left">
                                              <p className="heading-text-5">Timesheets</p>
                                            </div>
                                            <div className="float-left left-padding">
                                              <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                              <a className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, editWork: false, timesheetInfo: true, approveTimesheet: false, editAssignment: false, viewWorkers: false })}>
                                                <div className="float-left noti-bubble-info-7-9">
                                                  <div>
                                                    <img src={questionMarkBlue} alt="GC" className="image-auto-15 center-item"/>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          <div>
                                            {(value.timesheetEnabled) ? (
                                              <div>
                                                {(this.state.timesheets && this.state.timesheets.length > 0) ? (
                                                  <div>
                                                    {this.state.timesheets.map((ts, tsIndex) =>
                                                      <div>
                                                        <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, editWork: false, timesheetInfo: false, approveTimesheet: true, editAssignment: false, viewWorkers: false, selectedIndex: tsIndex })}>
                                                          {(ts.startDate && ts.endDate) && (
                                                            <div className="top-margin-15 horizontal-padding-15">
                                                              <p className="cta-color half-bold-text">{ts.startDate.substring(5,10).replace(/-/g,"/") + '/' + ts.startDate.substring(0,4).replace(/-/g,"/")} - {ts.isPerpetual ? "Continual" : ts.endDate.substring(5,10).replace(/-/g,"/") + '/' + ts.endDate.substring(0,4).replace(/-/g,"/")}</p>

                                                              <div className="description-text-2 top-padding-5">
                                                                <label className="right-padding">{ts.workerFirstName} {ts.workerLastName}</label>

                                                                <div className="clear" />

                                                                {(ts.isApproved === true) ? (
                                                                  <p className="success-message right-padding">Approved</p>
                                                                ) : (
                                                                  <p className="error-message right-padding">Not Approved</p>
                                                                )}

                                                                <div className="clear" />
                                                              </div>
                                                            </div>
                                                          )}
                                                        </a>
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="description-text-1 top-margin">
                                                    <p className="error-message">No outstanding timesheets have been submitted for approval.</p>

                                                    <div className="row-10">
                                                      <div className="spacer" />
                                                      <p className="description-text-2 error-color half-bold-text">Disable timesheets for {value.title}:</p>
                                                    </div>

                                                    <div className="row-10">
                                                      <Switch
                                                        onChange={(change) => this.changeItem('timesheetEnabled', index, change)}
                                                        checked={value.timesheetEnabled}
                                                        disabled={this.state.isSaving}
                                                        id="normal-switch"
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="top-margin">
                                                <p className="description-text-2 error-color half-bold-text">Turn on timesheets for {value.title} to approve at the end of each week:</p>

                                                <div className="row-10">
                                                  <Switch
                                                    onChange={(change) => this.changeItem('timesheetEnabled', index, change)}
                                                    checked={value.timesheetEnabled}
                                                    disabled={this.state.isSaving}
                                                    id="normal-switch"
                                                  />
                                                </div>

                                              </div>
                                            )}

                                          </div>

                                        </div>
                                        <div className="col span-1-of-3 box">
                                          <div>
                                            <div className="float-left">
                                              <p className="heading-text-5">Assignments</p>
                                            </div>
                                            <div className="float-left left-padding">
                                              <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                              <Link classNamee="btn btn-primary" to={'/employers/' + this.state.emp + '/postings/add'} state={{ postType: 'Assignment' }}>
                                                <div className="cta-border circle-corners padding-5">
                                                  <img src={addIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                                </div>
                                              </Link>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          {(this.state.assignments && this.state.assignments.length > 0) ? (
                                            <div className="top-margin-5">
                                              {this.state.assignments.map((value, index) =>
                                                <div key={"assignment|" + index}>
                                                  {(value.name) && (
                                                    <Link to={'/employers/' + this.state.emp + '/postings/' + value._id} state={{ selectedOpportunity: value, source: 'Student' }} className="background-link">
                                                      <div className="top-margin-15 horizontal-padding-15">
                                                        <p className="cta-color half-bold-text">{value.name}</p>

                                                        <div className="description-text-2 top-padding-5">
                                                          <label className="right-padding">{value.employerName}</label>

                                                          <label className="right-padding">|</label>
                                                          {value.submissionDeadline && (
                                                            <span>
                                                              <label className="right-padding">Deadline: {value.submissionDeadline.substring(0,10)}</label>
                                                              <label className="right-padding">|</label>
                                                            </span>
                                                          )}

                                                          <label className="right-padding">{value.duration} Estimated Hours</label>
                                                          <div className="clear" />
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="description-text-1 top-margin">
                                              <p className="error-message">There are no outstanding assignments.</p>
                                            </div>
                                          )}
                                        </div>

                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                </div>

                              </div>
                            ) : (
                              <p className="description-text-color description-text-2 row-20">No active hires. When you do, you can use this space to provide assignments and approve timesheets.</p>
                            )}
                          </div>

                          {(!this.state.orgMode || this.state.showModule) && (
                            <div>
                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <div>
                                  <div className="calc-column-offset-80">
                                    <p className="heading-text-3">Upcoming Events</p>
                                  </div>
                                  {(this.state.upcomingEvents && this.state.upcomingEvents.length > 0) && (
                                    <div className="fixed-column-80 top-padding-5">
                                      <Link to={"/employers/" + this.state.accountCode + "/postings"} className="standard-color">
                                        <div className="float-left">
                                          <p>See all</p>
                                        </div>
                                        <div className="float-left left-padding top-padding">
                                          <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                        </div>
                                        <div className="clear" />
                                      </Link>
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>

                                {(this.state.upcomingEvents && this.state.upcomingEvents.length > 0) ? (
                                  <div className="row-20">
                                    {this.state.upcomingEvents.map((item, index) =>
                                      <div key={"kpis" + index}>
                                        <div className="relative-column-33">
                                          <Link to={"/employers/" + this.state.accountCode + "/postings/" + item._id}>

                                            <p className="heading-text-5 center-text">{item.title}</p>
                                            {(item.startDate && item.endDate) && (
                                              <p className="description-text-4">{convertDateToString(new Date(item.startDate),'datetime-2')} {convertDateToString(new Date(item.endDate),'datetime-2')}</p>
                                            )}
                                            {(item.rsvps && item.rsvps.length > 0) && (
                                              <p className="description-text-4">{item.rsvps.length}</p>
                                            )}

                                            <div className="spacer"/>
                                          </Link>
                                        </div>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No upcoming events. Create <Link to={"/employers/" + this.state.accountCode + "/postings"}>here</Link>.</p>
                                )}

                              </div>
                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <p className="heading-text-3">Project Submissions</p>

                                {(this.state.submissions && this.state.submissions.length > 0) ? (
                                  <div className="row-20">
                                    {this.state.submissions.map((item, index) =>
                                      <div key={"kpis" + index}>
                                        <div className="relative-column-33">
                                          <Link to={"/employers/" + this.state.accountCode + "/postings/" + item._id}>

                                            <p className="heading-text-5 center-text">{item.name}</p>
                                            <p className="description-text-4">{item.firstName} {item.lastName}</p>
                                            {(item.upvotes && item.upvotes.length > 0) && (
                                              <p className="description-text-4">{item.upvotes.length}</p>
                                            )}

                                            <div className="spacer"/>
                                          </Link>
                                        </div>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No project submissions on your active project postings.</p>
                                )}

                              </div>
                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <p className="heading-text-3">Latest Followers</p>

                                {(this.state.followers && this.state.followers.length > 0) ? (
                                  <div>
                                    <div className="row-20">
                                      {this.state.followers.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          {(index < 3) && (
                                            <div className="relative-column-33">
                                              <Link to={"/employers/" + this.state.accountCode + "/career-seekers/" + item.email} className="standard-color">
                                                <div className="width-80-percent center-item">
                                                  <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="image-half-auto center-horizontally circle-radius" />
                                                </div>
                                                <p className="heading-text-6 center-text">{item.firstName} {item.lastName}</p>
                                                {(item.education && item.education.length > 0) && (
                                                  <p className="description-text-2 full-width center-text">{item.education[0].name + ' ' + item.education[0].endDate}</p>
                                                )}
                                                <div className="spacer"/>
                                              </Link>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className="clear" />
                                    </div>
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No followers yet. Invite people <a className="cta-color cta-underline" onClick={() => this.shareProfile()}>here</a>.</p>
                                )}

                              </div>
                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <div>
                                  <div className="calc-column-offset-80">
                                    <p className="heading-text-3">{(this.state.topPipelineCandidates && this.state.topPipelineCandidates.length > 0) ? "Top Talent Pipeline Candidates" : "Talent Pipelines"}</p>
                                  </div>
                                  {(this.state.pipelines && this.state.pipelines.length > 0) && (
                                    <div className="fixed-column-80 top-padding-5">
                                      <Link to={"/employers/" + this.state.accountCode + "/pipelines"} className="standard-color">
                                        <div className="float-left">
                                          <p>See all</p>
                                        </div>
                                        <div className="float-left left-padding top-padding">
                                          <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                        </div>
                                        <div className="clear" />
                                      </Link>
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>

                                {(this.state.pipelines && this.state.pipelines.length > 0) ? (
                                  <div>
                                    {(this.state.topPipelineCandidates && this.state.topPipelineCandidates.length > 0) ? (
                                      <div className="row-20">
                                        {this.state.topPipelineCandidates.map((item, index) =>
                                          <div key={"kpis" + index}>
                                            <div className="relative-column-33">
                                              <Link to={"/employers/" + this.state.accountCode + "/career-seekers/" + item.email} className="standard-color">
                                                <div className="width-80-percent center-item">

                                                  <div className="width-150 center-item padding-10">
                                                    <CircularProgressbar
                                                      value={item.match}
                                                      text={`${item.match}%`}
                                                      styles={{
                                                        path: { stroke: `${ getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${item.match / 100}` },
                                                        text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '24px' },
                                                        trail: { stroke: 'transparent' }
                                                      }}
                                                    />
                                                  </div>

                                                </div>
                                                <p className="heading-text-6 center-text full-width">{item.firstName} {item.lastName}</p>
                                                {(item.education && item.education.length > 0) && (
                                                  <p className="description-text-2 full-width center-text">{item.education[0].name + ' ' + item.education[0].endDate}</p>
                                                )}
                                                <p className="description-text-2 full-width center-text">{item.pathway}</p>
                                                <div className="spacer"/>
                                              </Link>
                                            </div>
                                          </div>
                                        )}
                                        <div className="clear" />
                                      </div>
                                    ) : (
                                      <div className="row-20">
                                        <div>
                                          <p className="description-text-2">Candidates for one or more pipelines have not been created yet. Please click on a pipeline <Link to={"/employers/" + this.state.accountCode + "/pipelines"}>here</Link> to view top matches.</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>

                                    <p className="description-text-color description-text-2 row-20">No talent pipelines created. Create <a className="cta-color cta-underline" onClick={() => this.setState({ modalIsOpen: true, showAddPipeline: true })}>here</a>.</p>
                                  </div>
                                )}

                              </div>
                              <div className="card-clear-padding padding-20 bottom-margin-40">

                                <div>
                                  <div className="calc-column-offset-80">
                                    <p className="heading-text-3">Talent Communities</p>
                                  </div>
                                  {(this.state.communities && this.state.communities.length > 0) && (
                                    <div className="fixed-column-80 top-padding-5">
                                      <Link to={"/employers/" + this.state.accountCode + "/communities"} className="standard-color">
                                        <div className="float-left">
                                          <p>See all</p>
                                        </div>
                                        <div className="float-left left-padding top-padding">
                                          <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                        </div>
                                        <div className="clear" />
                                      </Link>
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>

                                {(this.state.communities && this.state.communities.length > 0) ? (
                                  <div>
                                    <div className="row-20">
                                      {this.state.communities.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          <div className="relative-column-33">
                                            <Link to={"/employers/" + this.state.accountCode + "/groups/" + item._id} className="standard-color">
                                              <div className="width-80-percent center-item">
                                                <img src={(item.pictureURL) ? item.pictureURL : socialIconDark} alt="GC" className="image-half-auto center-horizontally" />
                                              </div>
                                              <p className="heading-text-6 center-text full-width top-padding">{item.name}</p>
                                              {(item.category) && (
                                                <p className="description-text-2 center-text full-width">{item.category}</p>
                                              )}
                                              {(item.members && item.members.length > 0) && (
                                                <p className="description-text-2 center-text full-width">{item.members.length} Members</p>
                                              )}

                                              <div className="spacer"/>
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                      <div className="clear" />
                                    </div>
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No talent communities yet. Create <a className="cta-color cta-underline" onClick={() => this.setState({ modalIsOpen: true, showAddGroup: true })}>here</a>.</p>
                                )}

                              </div>

                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <div className="top-padding-5">
                                  <div className="calc-column-offset-80">
                                    <p className="heading-text-3">Social Posts</p>
                                  </div>
                                  {(this.state.socialPosts && this.state.socialPosts.length > 0) && (
                                    <div className="fixed-column-80 top-padding-5">
                                      <Link to={"/employers/" + this.state.accountCode + "/social-posts"} className="standard-color">
                                        <div className="float-left">
                                          <p>See all</p>
                                        </div>
                                        <div className="float-left left-padding top-padding">
                                          <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                        </div>
                                        <div className="clear" />
                                      </Link>
                                    </div>
                                  )}

                                  <div className="clear" />
                                </div>

                                {(this.state.socialPosts && this.state.socialPosts.length > 0) ? (
                                  <div>
                                    <div className="row-20">
                                      <SubRenderPosts posts={this.state.socialPosts} passedGroupPost={null} accountCode={this.state.accountCode} employerLogo={this.state.employerLogo} employerName={this.state.employerName} jobTitle={this.state.jobTitle} />
                                      {/*
                                      {this.state.socialPosts.map((value, index) =>
                                        <div key={"kpis" + index}>
                                          <div className="relative-column-33">
                                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>
                                              <div className="width-80-percent center-item">
                                                {(value.roleName === 'Admin') ? (
                                                  <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="image-half-auto center-horizontally" alt="GC" />
                                                ) : (
                                                  <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="image-half-auto center-horizontally circle-radius" alt="GC" />
                                                )}
                                              </div>

                                              <div className="full-width">
                                                <p className="heading-text-5 full-width center-text">{value.firstName} {value.lastName}</p>
                                                <div className="clear" />

                                                <div className="mini-spacer" /><div className="mini-spacer" />

                                                {(value.headline && value.headline !== '') ? (
                                                  <div>
                                                    <p className="description-text-3 full-width center-text">{value.headline}</p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                                                      <div>
                                                        {console.log('show edu: ', value.education)}
                                                        <p className="description-text-3 description-text-color full-width center-text">Student @ {value.education[0].name}</p>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <div>
                                                          <p className="description-text-3 description-text-color full-width center-text">{this.state.orgName} Member</p>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                                <p className="description-text-4 description-text-color full-width center-text">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                              </div>
                                              <div className="clear" />

                                            </button>
                                          </div>
                                        </div>
                                      )}*/}
                                      <div className="clear" />
                                    </div>
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No social posts yet. Create <a className="cta-color cta-underline" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>here</a>.</p>
                                )}

                              </div>

                              <div className="card-clear-padding padding-20 bottom-margin-40">
                                <div>
                                  <div className="calc-column-offset-90">
                                    <p className="heading-text-3">Training/Referral Partners</p>
                                  </div>
                                  {(this.state.trainingProviders && this.state.trainingProviders.length > 0) && (
                                    <div className="fixed-column-90 top-padding-5">
                                      <Link to={"/employers/" + this.state.accountCode + "/add-orgs"} className="standard-color">
                                        <div className="float-left">
                                          <p>Browse</p>
                                        </div>
                                        <div className="float-left left-padding top-padding">
                                          <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                        </div>
                                        <div className="clear" />
                                      </Link>
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <p className="description-text-color description-text-2">Opportunities and communities are automatically shared with added training partners. And orgs can refer 3-5 candidates for postings.</p>
                                </div>

                                {(this.state.trainingProviders && this.state.trainingProviders.length > 0) ? (
                                  <div>
                                    <div className="row-20">
                                      {this.state.trainingProviders.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          {(item.orgName !== 'Sandbox') && (
                                            <div className="relative-column-33">
                                              <Link to={"/employers/" + this.state.accountCode + "/orgs/" + item.orgCode} className="standard-color">
                                                <div className="width-80-percent center-item">
                                                  <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="image-half-auto center-horizontally" />
                                                </div>
                                                <p className="heading-text-6 center-text full-width top-padding">{item.orgName}</p>
                                                <p className="description-text-3 full-width center-text top-padding-5">{item.orgDescription}</p>

                                                <div className="spacer"/>
                                              </Link>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      <div className="clear" />
                                    </div>
                                  </div>
                                ) : (
                                  <p className="description-text-color description-text-2 row-20">No training providers yet. Add them <Link to={"/employers/" + this.state.accountCode + "/add-orgs"}>here</Link>.</p>
                                )}

                              </div>
                            </div>
                          )}

                        </div>

                        {(!this.state.orgMode || this.state.showModule) && (
                          <div>
                            {(!this.state.orgMode) && (
                              <div className="relative-column-30 horizontal-padding">
                                <div className="left-padding-20">
                                  <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                                    <div className="full-width height-5 senary-background" />
                                    <div className="padding-20">
                                      <p className="heading-text-5">Training/Referral Partners</p>
                                      <div className="spacer" />

                                      {(this.state.trainingProviders && this.state.trainingProviders.length > 0) ? (
                                        <div>
                                          <div className="row-5">
                                            {this.state.trainingProviders.map((item, index) =>
                                              <div key={"kpis" + index}>
                                                {(item.orgName !== 'Sandbox') && (
                                                  <div className="full-width">
                                                    <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                                      <div className="row-3">
                                                        <div className="fixed-column-50">
                                                          <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="image-40-fit" />
                                                        </div>
                                                        <div className="calc-column-offset-70 top-padding">
                                                          <p className="description-text-2">{item.orgName}</p>
                                                        </div>
                                                        <div className="fixed-column-20 top-padding right-text">
                                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right" />
                                                        </div>
                                                        <div className="clear" />
                                                      </div>

                                                      <div className="spacer"/>
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      ) : (
                                        <p className="description-text-color description-text-2 row-20">No training providers yet. Create <Link to={"/employers/" + this.state.accountCode + "/add-orgs"}>here</Link>.</p>
                                      )}

                                      {(this.state.trainingProviders && this.state.trainingProviders.length > 0) && (
                                        <div className="full-width right-text">
                                          <Link to={"/employers/" + this.state.accountCode + "/add-orgs"} className="standard-color full-width">
                                            <div className="float-right left-padding top-padding-5">
                                              <img src={dropdownArrow} alt="GC" className="image-auto-8 rotate-270" />
                                            </div>
                                            <div className="float-right">
                                              <p className="description-text-1">Browse</p>
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                        </div>
                                      )}
                                      <div className="clear" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="relative-column-30 horizontal-padding">
                              <div className="left-padding-20">
                                <div className="card-clear-padding min-width-100 max-width-260 bottom-margin-20">
                                  <div className="full-width height-5 senary-background" />
                                  <div className="padding-20">
                                    <p className="heading-text-5">Key Metrics</p>
                                    <div className="spacer" />

                                    <div className="row-15">
                                      <p className="top-padding">KPIs</p>
                                      <div className="half-spacer" />
                                      {this.state.kpis.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          <div className="row-3">
                                            <div className="calc-column-offset-50">
                                              <p className="description-text-2">{item.name}</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="cta-color full-width right-text description-text-1">{item.score}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row-15">
                                      <p className="top-padding">Career-Seeker Activity</p>
                                      <div className="half-spacer" />
                                      {this.state.csActivity.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          <div className="row-3">
                                            <div className="calc-column-offset-50">
                                              <p className="description-text-2">{item.name}</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="cta-color full-width right-text description-text-1">{item.score}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row-15">
                                      <p className="top-padding">Your Activity</p>
                                      <div className="half-spacer" />
                                      {this.state.employerActivity.map((item, index) =>
                                        <div key={"kpis" + index}>
                                          <div className="row-3">
                                            <div className="calc-column-offset-50">
                                              <p className="description-text-2">{item.name}</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="cta-color full-width right-text description-text-1">{item.score}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  )}

                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                 >
                  {(this.state.readyInfo) && (
                    <div className="full-width padding-30">
                       <p className="heading-text-2">Ready for New Candidates?</p>
                       <div className="spacer"/>
                       <p className="row-10">Don't like the candidates referred so far? Or not enough applicants?</p>
                       <p className="row-10">Toggle the switch to indicate that you would like to be referred a new round of candidates (e.g., 3 - 5). This will send an email notification to {(this.state.orgMode) ? this.state.orgName : "our placement partners"} and CC the main contact for your organization.</p>
                    </div>
                  )}

                  {(this.state.editWork) && (
                    <div className="full-width padding-30">
                      {(this.state.selectedIndex || this.state.selectedIndex === 0) ? (
                        <div>
                          {/*<SubEditWork work={this.state.workArray[this.state.selectedIndex]} />*/}
                          <SubEditPlacement selectedPlacement={this.state.workArray[this.state.selectedIndex]} activeOrg={this.state.activeOrg} accountCode={this.state.emp} history={this.props.navigate} inModal={true} closeModal={this.closeModal} />
                        </div>
                      ) : (
                        <div>
                          <p className="error-message">There was an error determining which position was selected.</p>
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.timesheetInfo) && (
                    <div className="full-width padding-30">
                      <div>
                        <div className="calc-column-offset-30">
                          <p className="heading-text-2">Timesheet Info</p>
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button top-margin" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                       <div className="spacer"/>
                       <p>If you have timesheets enabled, those who hold positions will submit weekly timesheets that will appear here for approval. They will get automatic reminders on Sunday to submit their timesheets. To view all timesheets submitted, click the button below.</p>
                       <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>

                       <div className="spacer"/>
                    </div>
                  )}

                  {(this.state.approveTimesheet) && (
                    <div className="full-width padding-30">
                      <SubTimesheet selectedTimesheet={this.state.timesheets[this.state.selectedIndex]} roleName="Employer" passStatus={this.passStatus} closeModal={this.closeModal}/>
                    </div>
                  )}

                  {(this.state.editAssignment) && (
                    <div className="full-width padding-30">
                       <p className="heading-text-2">Edit Assignment</p>
                       <div className="spacer"/>
                       <p>T.</p>
                    </div>
                  )}

                  {(this.state.viewWorkers) && (
                    <div className="full-width padding-30">

                       <p className="heading-text-3">Invite Workers Via This Link:</p>
                       <a target="_blank" href={window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.activeOrg + "/work/" + this.state.workArray[this.state.selectedIndex]._id + "/worker/join"} >{window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.activeOrg + "/work/" + this.state.workArray[this.state.selectedIndex]._id + "/worker/join"}</a>
                       <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                       <hr />
                       <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                       <p className="heading-text-3">{(this.state.workArray[this.state.selectedIndex].workers) ? this.state.workArray[this.state.selectedIndex].workers.length : "0"} Workers Have Joined {this.state.workArray[this.state.selectedIndex].employerName}</p>
                       <div className="spacer" /><div className="spacer" />
                       {(this.state.workArray[this.state.selectedIndex].workers) && (
                         <div>
                          {this.state.workArray[this.state.selectedIndex].workers.map((value, index) =>
                            <div key={"worker|" + index}>
                              <div className="fixed-column-45">
                                <img src={profileIconGrey} alt="GC" className="image-auto-30"/>
                              </div>
                              <div className="calc-column-offset-45">
                                <p>{value.firstName} {value.lastName}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                         </div>
                       )}

                    </div>
                  )}

                  {(this.state.showAddGroup) && (
                    <div key="showAddGroup" className="full-width padding-20">
                       <SubEditGroup selectedGroup={null} history={this.props.navigate} closeModal={this.closeModal}
                         accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                         employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                         pathway={this.state.pathway}
                       />
                     </div>
                  )}
                  {(this.state.showPost) && (
                    <div key="showPost" className="full-width padding-20">
                      <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost}  posts={this.state.socialPosts} passPosts={this.passPosts} closeModal={this.closeModal} pictureURL={this.state.employerLogo} accountCode={this.state.accountCode} jobTitle={this.state.jobTitle} />
                     </div>
                  )}
                  {(this.state.showAddPipeline) && (
                    <div key="showAddPipeline" className="full-width padding-20">
                       <SubEditPipeline selectedPipeline={null} history={this.props.navigate} closeModal={this.closeModal}
                         accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                         employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                         pathway={this.state.pathway} departmentOptions={this.state.departments}
                       />
                     </div>
                  )}
                  {(this.state.showShareButtons) && (
                     <div className="full-width padding-20 center-text">
                       <p className="heading-text-2">Share The {this.state.employerName} Profile</p>

                       <div className="top-padding-20">
                         <p>Share this link:</p>
                         <a href={this.state.shareURL} target="_blank" rel="noopener noreferrer">{this.state.shareURL}</a>
                       </div>

                       <div className="spacer" />

                       <div className="top-padding-20">
                         {this.renderShareButtons()}
                       </div>
                     </div>
                  )}

                  {(this.state.showQuestion) && (
                    <div className="full-width padding-20">
                      <h2>Request a Talent Retention Community</h2>
                      <SubContact reason={"Guided Compass a Talent Retention Community"} reasonOptions={null} history={this.props.navigate} inModal={true} closeModal={this.closeModal} />
                    </div>
                  )}

                  {(this.state.showOrgDetails) && (
                    <div className="full-width padding-20 center-text">
                      {(this.state.selectedOrg.webLogoURI) ? (
                        <div className="full-width center-text bottom-margin-20">
                          <div className="fixed-column-30 height-30">
                            <p> </p>
                          </div>
                          <div className="calc-column-offset-60">
                            <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.selectedOrg.webLogoURIColor} />
                          </div>
                          <div className="fixed-column-30">
                            <button className="background-button" onClick={() => this.closeModal()}>
                              <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>
                      ) : (
                        <div className="full-width center-text">
                          <div className="fixed-column-30 height-30">
                            <p> </p>
                          </div>
                          <div className="calc-column-offset-60 height-30">
                            <p> </p>
                          </div>
                          <div className="fixed-column-30">
                            <button className="background-button" onClick={() => this.closeModal()}>
                              <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      <p className="heading-text-2">{this.state.selectedOrg.orgName}</p>
                      <a href={this.state.selectedOrg.orgURL} className="full-width center-text top-padding">{this.state.selectedOrg.orgURL}</a>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <p className="description-text-1">{this.state.selectedOrg.orgDescription}</p>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      {(this.state.showConfirmDelete) ? (
                        <div className="full-width center-text">
                          <hr />
                          <div className="spacer" /><div className="spacer" />
                          <p className="heading-text-5">Are you sure you want to remove the {this.state.selectedOrg.orgName} community?</p>

                          <p className="description-text-2 row-20">Removing the community means that you will no longer receive notifications and invited to opportunities specific to this community. If you continue to receive unwanted notifications, please email help@guidedcompass.com.</p>

                          <div className="spacer" />

                          <div className="display-inline left-padding right-padding top-margin-20">
                            <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.removeOrg(this.state.selectedIndex,this.state.selectedType)}>Yes, Remove {this.state.selectedOrg.orgName}</button>
                          </div>
                          <div className="display-inline left-padding right-padding top-margin-20">
                            <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ showConfirmDelete: false })}>Cancel</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="full-width center-text">
                           <div className="display-inline left-padding right-padding top-margin-20">
                             <button className="btn btn-squarish" onClick={() => this.orgClicked(this.state.selectedIndex, this.state.selectedType)}>Toggle to This Community</button>
                           </div>
                           <div className="display-inline left-padding right-padding top-margin-20">
                             <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.setState({ showConfirmDelete: true })}>Remove from Your Workspaces/Communities</button>
                           </div>
                           <div className="display-inline left-padding right-padding top-margin-20">
                             <Link to={(window.location.pathname.includes('/employers')) ? "/employers/" + this.state.accountCode + "/orgs/" + this.state.selectedOrg.orgCode : "/app/orgs/" + this.state.selectedOrg.orgCode} className="background-button"><button className="btn btn-squarish denary-background clear-border">View Review Page</button></Link>
                           </div>
                           <div className="clear"/>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.showTempScreen) && (
                    <div className="full-width padding-20 center-text">
                      <h2>{this.state.tempScreenName}</h2>
                      <div className="spacer" />

                      <p className="description-text-color">No data found</p>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <button className="btn btn-secondary" onClick={() => this.closeModal()}>
                        Close View
                      </button>
                    </div>
                  )}

                  {(this.state.showChangePassword) && (
                    <div key="showChangePassword" className="full-width padding-20">
                       <SubChangePassword history={this.props.navigate} email={this.state.emailId}
                         modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}
                       />
                     </div>
                  )}
                 </Modal>

                </div>
              )}

              <div className="clear"/>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}

          </div>

      )
    }
}

export default withRouter(EmpDashboard)
