import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubConfigureCartItem from '../Common/ConfigureCartItem';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const searchIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png'

class AdvisingServices extends Component {
    constructor(props) {
        super(props)

        this.state = {
          items: [],
          filteredItems: [],
          orgSessionsFree: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in org / accountCode')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props.activeOrg)

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      // let remoteAuth = localStorage.getItem('remoteAuth');

      this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus })

      let orgCode = 'guidedcompass'

      Axios.get('/api/advising-services', { params: { orgCode, isActive: true } })
      .then((response) => {
        console.log('Advising services query attempted', response.data);

        if (response.data.success) {
          console.log('advising services query worked')

          const items = response.data.advisingServices
          const filteredItems = items
          this.setState({ items, filteredItems })

        } else {
          console.log('something went wrong: ', response.data.message)
        }

      }).catch((error) => {
        console.log('Advising services query did not work for some reason', error);
      });

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.name, event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(name,searchString) {
      console.log('searchItems called ', name, searchString)

      const orgCode = this.state.org
      const queryParams = { searchString, orgCode, isActive: true }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/advising-services/search', { params: queryParams })
        .then((response) => {
          console.log('Advising services search query attempted', response.data);

            if (response.data.success) {
              console.log('advising services search query worked')

              const filteredItems = response.data.advisingServices
              self.setState({ filteredItems, animating: false });

            } else {
              console.log('pathway search query did not work', response.data.message)
              self.setState({ filteredItems: this.state.items, animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark search query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showRegistration: false, showAddToCart: false });
    }

    itemClicked(item, type) {
      console.log('itemClicked called ', item, type)

      if (type === 'Book') {
        this.setState({ modalIsOpen: true, showAddToCart: true, itemSelected: item })
      } else if (type === 'Buy') {
        this.props.navigate("/marketplace/" + item.slug)
      } else if (type === 'Sell') {
        this.props.navigate("/marketplace/" + item.slug)
      } else if (type === 'Browse') {
        this.props.navigate("/marketplace/" + item.slug)
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = []
        if (this.state.subNavSelected === 'Events') {
          // events
          filters = this.state.eventFilters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          filters = this.state.projectFilters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          filters = this.state.workFilters
        } else if (this.state.subNavSelected === 'All') {
          filters = this.state.allFilters
        }

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = []

        if (this.state.subNavSelected === 'Events') {
          // events
          sorters = this.state.eventSorters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          sorters = this.state.projectSorters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          sorters = this.state.workSorters
        } else if (this.state.subNavSelected === 'All') {
          sorters = this.state.allSorters
        }

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    render() {

      let mainClass = "card-clear-padding width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"
      let searchClass = "width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"
      if (window.innerWidth < 768) {
        mainClass = "card-clear-padding bottom-margin-20"
        searchClass = "bottom-margin-20"
      } else if (window.location.pathname.includes('/app/')) {
        mainClass = "card-clear-padding bottom-margin-20"
        searchClass = "bottom-margin-20"
      }

      return (
          <div>
            <div className={mainClass}>
              <div className="full-width height-5 primary-background" />

              <div className="padding-20">
                <div className="calc-column-offset-50">
                  <p className="heading-text-2">{this.state.filteredItems.length} Advising Services</p>
                </div>
                <div className="fixed-column-50">
                </div>
                <div className="clear" />

              </div>
            </div>

            <div className={searchClass}>
              <div className="filter-field-search full-width white-background medium-shadow">
                <div className="search-icon-container">
                  <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                </div>
                <div className="filter-search-container calc-column-offset-100-static">
                  <input type="text" className="text-field clear-border" placeholder={"Search Advising Services..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                </div>
                {/*
                <div className="search-icon-container top-margin-negative-3">
                  <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                    <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                  </button>
                </div>*/}
              </div>
              <div className="clear" />
            </div>

            {(this.state.showingSearchBar) && (
              <div className={(window.innerWidth < 768) ? "card bottom-margin-20" : "card width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"}>
                <div>
                  <p>Filter</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('filter'))}

                  <div className="clear" />
                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Sort</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('sort'))}
                  <div className="clear" />
                </div>
              </div>
            )}

            <div className={searchClass}>
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space white-background">
                  <div>
                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Calculating results...</p>

                  </div>
                </div>
              ) : (
                <div>
                  {this.state.filteredItems.map((value, optionIndex) =>
                    <div key={value.name}>
                      {((this.state.filterCategory && this.state.filterCategory === value.category) || (!this.state.filterCategory || this.state.filterCategory === 'All')) && (
                        <div>
                          <div className={(window.innerWidth < 768) ? "full-width bottom-margin-30" : "relative-column-32 top-margin-30"}>
                            <div className="card">
                              <Link to={(window.location.pathname.includes("/app/")) ? "/app/advising-services/" + value.slug : "/marketplace/" + value.slug} className="background-button standard-color">
                                <img className="image-full-auto contain-object-fit" alt="GC" src={(value.imageURL) ? value.imageURL : profileIconDark} />
                                <p className="full-width description-text-1 top-padding-20 bold-text">{value.name}</p>
                                <p className="full-width description-text-4 top-padding-5 max-text-lines">{value.description}</p>

                                {(value.averageRating) && (
                                  <div className="top-margin">
                                    <div className="float-left">
                                    <ul className="horizontal-list center-text">
                                      <li><img src={(value.averageRating >= 1) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 2) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 3) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 4) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    </ul>

                                    </div>
                                    <div className="float-left">
                                      <p className="description-text-3 left-margin-5 cta-color bold-text">({value.reviewCount})</p>
                                    </div>

                                    <div className="clear"/>
                                  </div>
                                )}

                                <p className="full-width heading-text-5 cta-color top-padding">{(this.state.activeOrg && this.state.activeOrg !== 'guidedcompass' && this.state.orgSessionsFree) ? "FREE" : value.price}</p>
                              </Link>

                              {(value.cta === 'Buy') ? (
                                <div className="full-width top-margin-20">
                                  <div className="relative-column-50 right-padding-5">
                                    <button className="btn btn-squarish full-width" onClick={() => this.itemClicked(value,value.cta)}>{value.cta}</button>
                                  </div>
                                  <div className="relative-column-50 left-padding-5">
                                    <button className="btn btn-squarish-white full-width" onClick={() => this.itemClicked(value,'Sell')}>{value.cta2}</button>
                                  </div>
                                  <div className="clear" />
                                </div>
                              ) : (
                                <div>
                                  <button className="btn btn-squarish full-width top-margin-20" onClick={() => this.itemClicked(value,value.cta)}>{value.cta}</button>
                                </div>
                              )}

                            </div>
                          </div>

                          {(optionIndex % 3 !== 2 && window.innerWidth > 768) && (
                            <div>
                              <div className="relative-column-2 height-40" />
                            </div>
                          )}

                          {(optionIndex % 3 === 2 && window.innerWidth > 768) && (
                            <div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
            {(this.state.showAddToCart) && (
              <div key="showAddToCart" className="full-width padding-40">
                <SubConfigureCartItem history={this.props.navigate} itemSelected={this.state.itemSelected} closeModal={this.closeModal} />
              </div>
            )}

           </Modal>
          </div>
      )
    }
}


export default withRouter(AdvisingServices);
