import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
// import './CardSectionStyles.css'
import '../../css/style.css';

// import stripeIcon from '../../images/stripe-icon.png';
// import creditCardIcons from '../../images/credit-card-icons.png';

const stripeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stripe-icon.png';
const creditCardIcons = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/credit-card-icons.png';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label>
      <div className="row-10">
        <div className="float-left">
          <label className="description-text-2 bold-text">Enter Card Details</label>
        </div>
        <div className="float-left left-padding top-padding-5">
          <img src={stripeIcon} alt="GC" className="image-auto-20" />
        </div>
        <div className="float-left left-padding top-padding-5">
          <img src={creditCardIcons} alt="GC" className="image-auto-20"/>
        </div>
        <div className="clear" />
      </div>

      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
};

export default CardSection;
