import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';

const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class DeactivatedModal extends Component {
    constructor(props) {
      super(props)
      this.state = {
        modalIsOpen: true
      }

      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen || this.props.redirectLink !== prevProps.redirectLink || this.props.orgName !== prevProps.orgName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within sideNav')

      const orgName = this.props.orgName
      const accountCode = this.props.accountCode
      const modalIsOpen = this.props.modalIsOpen
      const redirectLink = this.props.redirectLink
      const isLandingPage = this.props.isLandingPage

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let isAdvisor = localStorage.getItem('isAdvisor');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');

      this.setState({
        emailId: email, username, isAdvisor, activeOrg, orgFocus, roleName,
        orgName, accountCode, modalIsOpen, redirectLink, isLandingPage
      });


    }

    closeModal() {
      console.log('closeModal called')
      this.props.closeModal()
    }


    render() {

      return (
        <Modal
         isOpen={this.state.modalIsOpen}
         onAfterOpen={this.afterOpenModal}
         onRequestClose={this.closeModal}
         className="modal"
         overlayClassName="modal-overlay"
         contentLabel="Example Modal"
       >
         <div key="newPost" className="full-width">

           <div className="spacer" />

           <a onClick={() => this.closeModal()}>
             <img src={closeIcon} alt="GC" className="image-auto-15" />
           </a>

           <div className="padding-40">
              <h2>{this.state.orgName} is No Longer a Guided Compass Partner</h2>

              {(window.location.pathname.includes('/employers')) ? (
                <div className="row-20 full-width center-text">
                  <p>If you have already submitted your internship postings to {this.state.orgName}, you are all set! The {this.state.orgName} team will reach out when they find interns that are a fit. If you would like to post an internship opportunity, please fill out the interest form.</p>
                </div>
              ) : (
                <div className="row-20 full-width center-text">
                  <p>If you have already applied to {this.state.orgName}, you are all set! The {this.state.orgName} team will reach out when something is a fit. If you have not applied, please fill out the interest form.</p>
                </div>
              )}

              <div className="row-20 center-text flex-container full-width">
                <div className="flex-50 right-padding">
                  <a href={this.state.redirectLink} target="_blank" className="btn btn-primary cta-background-color white-text">Navigate to {this.state.orgName}</a>
                </div>
                <div className="flex-50 left-padding">
                  {(this.state.isLandingPage) ? (
                    <Link to={'/contact'} state={{ reason: "Other" }} className="btn btn-primary">Have Questions?</Link>
                  ) : (
                    <Link to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/settings' : '/app/settings'} className="btn btn-primary">Navigate to Another Partner Portal</Link>
                  )}
                </div>
                <div className="clear" />
              </div>
           </div>
          </div>

        </Modal>
      )
    }
}

export default DeactivatedModal;
