import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditProfileDetails from '../Subcomponents/EditProfileDetails';
import withRouter from '../Functions/WithRouter';

class OrgEditProfileDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName, org, activeOrg: org, orgLogo })

      }
    }

    render() {
      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <div className="standard-container-2">
              <SubEditProfileDetails activeOrg={this.state.activeOrg} history={this.props.navigate} location={this.props.location} />
            </div>

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgEditProfileDetails)
