import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditPlacement from './Subcomponents/EditPlacement';
import withRouter from './Functions/WithRouter';

class EmpEditHire extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params
      // const { selectedPlacement } = this.props.location.state;

      let selectedPlacement = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedPlacement = this.props.location.state.selectedPlacement;
        duplicate = this.props.location.state.duplicate;
      }
      console.log('show selectedPlacement: ', selectedPlacement)
      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      const orgMode = true

      this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg,
        selectedPlacement, emp, orgMode, orgLogo, duplicate
      })

    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
          <SubEditPlacement selectedPlacement={this.state.selectedPlacement} activeOrg={this.state.activeOrg} accountCode={this.state.emp} history={this.props.navigate} duplicate={this.state.duplicate} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(EmpEditHire)
