import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif'

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Jobs extends Component {
    constructor(props) {
        super(props)

        this.state = {
          emailId: null,
          username: '',
          org: '',
          jobs: [],
          filteredJobs: [],
          timerId: 0,

          showProjectDetail: false,

          selectedIndex1: 0,
          selectedIndex2: 0,

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          memberFilterOptions: [],
          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],
          gradeOptions: [],
          pathwayOptions: [{ name: '' }],
          radiusOptions: [],

          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],
          jobOptions: [],

          location: 'US',
          radius: '10',

          showFilters: true,

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullJobs = this.pullJobs.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.markCompleted = this.markCompleted.bind(this)

        this.renderItems = this.renderItems.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within jobs ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in jobs', this.props.activeOrg)

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('cuFirstName');
      let cuLastName = localStorage.getItem('cuLastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');

      if (email && org) {

        const jobOptions = []
        const pathwayOptions = [{ name: '' }]

        const radiusOptions = ['','10','50']

        this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus,
          jobOptions, pathwayOptions, radiusOptions
        })

        let orgCode = 'general'
        if (org === 'c2c' || org === 'dpscd') {
          orgCode = org
        }

        Axios.get('/api/benchmarks', { params: { orgCode } })
        .then((response) => {

          if (response.data.success) {
            console.log('Benchmark query worked', response.data);

            let pathwayOptions = [{name: '', skills: []}]
            let names = []

            for (let i = 1; i <= response.data.benchmarks.length; i++) {

              if (org === 'dpscd') {
                if (response.data.benchmarks[i - 1].pathway === pathway) {

                  // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                  let skills = response.data.benchmarks[i - 1].skills

                  let skillsObject = []

                  for (let j = 1; j <= skills.length; j++) {
                    if (skills[j - 1].title && skills[j - 1].skillType) {
                      const name = skills[j - 1].title
                      const skillType = skills[j - 1].skillType
                      skillsObject.push({ name, skillType })
                    } else {
                      // not adding traits for now
                      // const name = skills[j - 1].title
                      // const skillType = 'Trait'
                      // console.log('no good: ', name)
                      // skillsObject.push({ name, skillType})
                    }
                  }

                  // this.setState({ skills: skillsObject })
                }
              } else {

                let name = response.data.benchmarks[i - 1].jobFunction
                console.log('show the value 1: ', name)
                if (!names.includes(name)) {
                  names.push(name)
                  console.log('show the value 2: ', name)
                  // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                  let skills = response.data.benchmarks[i - 1].skills

                  let skillsObject = []

                  for (let j = 1; j <= skills.length; j++) {
                    if (skills[j - 1].title && skills[j - 1].skillType) {
                      const name = skills[j - 1].title
                      const skillType = skills[j - 1].skillType
                      skillsObject.push({ name, skillType })
                    } else {
                      // not adding traits for now
                      // const name = skills[j - 1].title
                      // const skillType = 'Trait'
                      // console.log('no good: ', name)
                      // skillsObject.push({ name, skillType})
                    }
                  }

                  let jobOptions = []
                  if ( name === 'Research & Development') {
                    jobOptions.push(name, 'Research','R&D')
                  } else if (name === 'Human Resources') {
                    jobOptions.push(name, 'HR')
                  } else if (name === 'Design') {
                    jobOptions.push(name, 'Designer','UX','UI')
                  } else if (name === 'Finance & Accounting') {
                    jobOptions.push(name, 'Finance','Accounting','Financial')
                  } else if (name === 'Data Science') {
                    jobOptions.push(name, 'Data','Business Intelligence')
                  } else if (name === 'Product Management') {
                    jobOptions.push(name, 'Product Manager')
                  } else if (name === 'Engineering') {
                    jobOptions.push(name, 'Engineer')
                  } else if (name === 'Strategy') {
                    jobOptions.push(name, 'Strategist')
                  } else if (name === 'Operations') {
                    jobOptions.push(name)
                  } else if (name === 'Partnerships') {
                    jobOptions.push(name)
                  } else if (name === 'Marketing') {
                    jobOptions.push(name, 'Social Media')
                  } else if (name === 'Sales') {
                    jobOptions.push(name, 'Business Development')
                  } else if (name === 'IT Support') {
                    jobOptions.push(name)
                  } else if (name === 'Customer Service') {
                    jobOptions.push(name)
                  }

                  pathwayOptions.push({ name, jobOptions })
                } else {


                }
                console.log('show pathwayOptions: ', pathwayOptions)

              }
            }

            this.setState({ pathwayOptions })
          } else {
            console.log('no benchmarks found', response.data.message)

          }

        }).catch((error) => {
            console.log('Benchmark query did not work', error);
        });

        this.pullJobs('Product Management',this.state.location, this.state.radius)

      }
    }

    pullJobs(searchValue, location, radius) {
      console.log('pullJobs called', searchValue, location, radius)

      this.setState({ animating: true })
      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedJob
      }
      if (!location) {
        location = this.state.location
      }
      if (!radius) {
        radius = this.state.radius
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/external-jobs/search', { params: { searchValue, location, radius } })
        .then((response) => {
          console.log('Jobs query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved jobs')

              if (response.data.postings) {

                const jobs = response.data.postings
                const filteredJobs = jobs
                self.setState({ jobs, filteredJobs, animating: false })
              }

            } else {
              console.log('no job data found', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Job query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        this.setState({ searchString })
        this.pullJobs(event.target.value, this.state.location, this.state.radius)
      } else if (event.target.name === 'location') {
        this.setState({ [event.target.name]: event.target.value })

        let searchString = null
        if (this.state.searchString && this.state.searchString !== '') {
          searchString = this.state.searchString
        }

        this.pullJobs(searchString, event.target.value, this.state.radius)

      } else if (event.target.name === 'radius') {
        this.setState({ [event.target.name]: event.target.value })

        let searchString = null
        if (this.state.searchString && this.state.searchString !== '') {
          searchString = this.state.searchString
        }

        this.pullJobs(searchString, this.state.location, event.target.value)
      } else {
        // console.log('on location?', event.target.name, event.target.value)
        this.setState({ [event.target.name]: event.target.value })

      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const defaultFilterOption = this.state.defaultFilterOption
      // const projects = this.state.projects
      // const type = this.props.type
      // const emailId = this.state.emailId
      //
      // const self = this
      // function officiallyFilter() {
      //   console.log('officiallyFilter called')
      //
      //   Axios.put('/api/projects/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, projects, type, emailId })
      //   .then((response) => {
      //     console.log('Project filter query attempted', response.data);
      //
      //       if (response.data.success) {
      //         console.log('project filter query worked')
      //
      //         let filteredProjects = response.data.projects
      //         const filterCriteriaArray = response.data.filterCriteriaArray
      //         const sortCriteriaArray = null
      //
      //         self.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })
      //
      //       } else {
      //         console.log('project filter query did not work', response.data.message)
      //         self.setState({ animating: false })
      //       }
      //
      //   }).catch((error) => {
      //       console.log('Project filter query did not work for some reason', error);
      //       self.setState({ animating: false })
      //   });
      // }
      //
      // const delayFilter = () => {
      //   console.log('delayFilter called: ')
      //   clearTimeout(self.state.timerId)
      //   self.state.timerId = setTimeout(officiallyFilter, 1000)
      // }
      //
      // delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      // let projects = this.state.projects
      // let filteredProjects = []
      //
      // Axios.put('/api/projects/sort', { sortString, projects, sortName })
      // .then((response) => {
      //   console.log('Project sort query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('project sort query worked')
      //
      //       let filteredProjects = response.data.projects
      //       const filterCriteriaArray = null
      //       const sortCriteriaArray = response.data.sortCriteriaArray
      //
      //       this.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })
      //
      //     } else {
      //       console.log('project sort query did not work', response.data.message)
      //       this.setState({ animating: false })
      //     }
      //
      // }).catch((error) => {
      //     console.log('Project sort query did not work for some reason', error);
      //     this.setState({ animating: false })
      // });
    }

    markCompleted(index) {
      console.log('markCompleted called', index)

      let filteredJobs = this.state.filteredJobs
      if (filteredJobs[index].completed) {
        filteredJobs[index]['completed'] = false
        this.setState({ filteredJobs })
      } else {
        filteredJobs[index]['completed'] = true
        this.setState({ filteredJobs })
      }
    }

    favoriteItem(item) {
      console.log('favoriteItem called', item)

      let itemId = item._id

      let favoritesArray = this.state.favorites

      if (favoritesArray.includes(itemId)){

        let index = favoritesArray.indexOf(itemId)
        if (index > -1) {
          favoritesArray.splice(index, 1);
        }

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({
              serverSuccessPlan: true,
              serverSuccessMessagePlan: 'Favorite saved!'
            })
          } else {
            console.log('did not save successfully')
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'error:' + response.data.message
            })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'there was an error saving favorites'
            })
        });

      } else {

        console.log('adding item: ', favoritesArray, itemId)
        favoritesArray.push(itemId)
        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({
              serverSuccessPlan: true,
              serverSuccessMessagePlan: 'Favorite saved!'
            })
          } else {
            console.log('did not save successfully')
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'error:' + response.data.message
            })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'there was an error saving favorites'
            })
        });
      }

      console.log('favorites', favoritesArray)
      this.setState({ favorites: favoritesArray })
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderItems() {
      console.log('renderItems called', this.state.filteredJobs)

      let rows = []
      for (let i = 1; i <= this.state.filteredJobs.length; i++) {

        const index = i - 1

        let url = ''
        if (this.state.filteredJobs[index].URL) {
          url = this.state.filteredJobs[index].URL
        }
        // JvId, JobTitle, Company, AcquisitionDate, URL, Location, Fc

        rows.push(
          <div key={i}>
            <div>
              <div className="spacer"/><div className="spacer"/>

              <a href={url} className="background-button full-width standard-color" target="_blank">
                <span className="fixed-column-50">
                  <div className="half-spacer"/>
                  <img src={opportunitiesIconDark} alt="Guided Compass project icon" className="image-40-auto"/>
                </span>
                <span className="calc-column-offset-80 left-padding-20">
                  <p className="heading-text-6">{this.state.filteredJobs[index].JobTitle} @ {this.state.filteredJobs[index].Company}</p>
                  <p className="description-text-2 top-padding-5">{this.state.filteredJobs[index].Location} | Posted on {this.state.filteredJobs[index].AccquisitionDate}</p>
                </span>
                <div className="fixed-column-30 left-padding">
                  <span className="float-left">
                    <div className="spacer"/><div className="half-spacer"/>
                    <Link to={''} className="background-link">
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                    </Link>
                  </span>
                </div>
                <div className="clear" />
                <div className="half-spacer" />

              </a>
            </div>
            <div className="clear" />

            <div className="left-padding-70">
              {(this.state.searchString && this.state.searchString !== '') && (
                <label className="description-text-2 error-color right-padding-5">Search Term: {this.state.searchString};</label>
              )}
              {(this.state.location && this.state.location !== '') && (
                <label className="description-text-2 error-color right-padding-5">Location: {this.state.location};</label>
              )}
              {(this.state.radius && this.state.radius !== '') && (
                <label className="description-text-2 error-color">Radius: {this.state.radius} Miles;</label>
              )}
              <div className="clear" />
            </div>

            <hr className="cell-separator-advisor"/>
          </div>
        )
      }

      return rows
    }

    closeModal() {
      console.log('closeModal in projects: ', this.state.showProjectDetail)

      this.setState({ modalIsOpen: false });

    }

    itemClicked(index) {
      console.log('itemClicked called', index)

      const selectedJob = this.state.jobOptions[index]
      this.setState({ selectedJob })

      this.pullJobs(selectedJob,this.state.location, this.state.radius)
    }

    calculateMatches() {
      console.log('calculateMatches called')

      if (this.state.matchingView) {
        let searchString = null
        this.setState({ searchString, matchingView: false })
        this.pullJobs(searchString, this.state.location, this.state.radius)
      } else {
        let searchString = 'Marketing'
        this.setState({ searchString, matchingView: true })
        this.pullJobs(searchString, this.state.location, this.state.radius)
      }
    }

    render() {

      let mainClass = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform }
      let matchButtonClass = "search-icon-container top-margin-negative-3"
      if (this.state.activeOrg !== 'unite-la') {
        mainClass = 'standard-container-2'
        mainStyle = {}
        matchButtonClass = "search-icon-container top-margin"
      }

      if (this.props.pageSource === 'Goal') {
        mainClass = ''
        mainStyle = {}
      }

      return (
          <div>
              <div>
                <div>
                  <div className={(!window.location.pathname.includes('/problem-platform') && this.state.pageSource !== 'landingPage') && mainClass + " row-10 horizontal-padding"} style={(!window.location.pathname.includes('/problem-platform') && this.state.pageSource !== 'landingPage') ? mainStyle : {}}>
                    {(window.location.pathname.includes('/problem-platform') || this.state.pageSource === 'landingPage') ? (
                      <div className="row">

                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                        <div className="filter-field-search full-width white-background">
                          <div className="search-icon-container">
                            <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                          </div>
                          <div className="filter-search-container calc-column-offset-100-static">
                            {(this.props.passedType) ? (
                              <input type="text" className="text-field clear-border" placeholder={"Search " + this.props.passedType.toLowerCase() + "s..."} name="search" value={this.state.searchString} onChange={this.handleChange}/>
                            ) : (
                              <input type="text" className="text-field clear-border" placeholder={"Search..."} name="search" value={this.state.searchString} onChange={this.handleChange}/>
                            )}

                          </div>
                          <div className="search-icon-container top-margin-negative-3">
                            <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                              <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={(this.state.matchingView) ? "search-icon-container top-margin-negative-3 full-width" : matchButtonClass} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                          <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button"} onClick={(this.state.matchingView) ? () => this.calculateMatches(false, false, false) : () => this.calculateMatches(true, true, false)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches">
                            {(this.state.matchingView) ? <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" /> : <img src={matchIcon} alt="GC" className="image-auto-30 right-margin" />}
                          </button>
                        </div>
                        {(this.state.matchingView) && (
                          <div className="full-width">
                            {/*
                            <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                              <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                                <p>Adjust</p>
                              </div>
                            </button>*/}
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                                <p>Close</p>
                              </div>
                            </button>

                            <div className="clear" />

                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          </div>
                        )}
                        {(!this.state.matchingView) && (
                          <div>
                            <div className="filter-field-search calc-column-offset-100-static">
                              <div className="search-icon-container">
                                <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                              </div>
                              <div className="filter-search-container calc-column-offset-100-static">
                                <input type="text" className="text-field clear-border" placeholder={"Search jobs..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                            </div>

                            <div className={matchButtonClass}>
                              <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results">
                                {(this.state.showingSearchBar) ? <img src={filterIconSelected} alt="GC" className="image-auto-25" /> : <img src={filterIcon} alt="GC" className="image-auto-25" />}
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="clear" />
                        <ReactTooltip id="tooltip-placeholder-id" />

                      </div>
                    )}

                  </div>
                </div>

                <div>

                  {(this.state.showingSearchBar) && (
                    <div className="standard-container-2">
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Location</label>
                          <input type="text" className="text-field" placeholder={"US, City, or Zip Code"} name="location" value={this.state.location} onChange={this.formChangeHandler}/>
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Max Distance</label>
                          <select name="radius" value={this.state.radius} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.radiusOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                    </div>
                  )}

                  {(this.state.animating) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Calculating results...</p>

                      </div>
                    </div>
                  ) : (
                    <div className="standard-container-2">
                      {this.renderItems()}
                    </div>
                  )}
                </div>

              </div>

          </div>
      )
    }
}


export default withRouter(Jobs);
