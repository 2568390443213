import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Axios from 'axios';
import withRouter from '../components/Functions/WithRouter';
import SubPathways from '../components/Subcomponents/Pathways';

class PathwaysPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('pathways page just mounted')
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#fff";

      let { orgCode, accountCode, id } = this.props.params

      let noOrgCode = false
      if (!orgCode) {
        noOrgCode = true
      }
      this.setState({ orgCode, noOrgCode, accountCode, id, ready: true })

      if (orgCode) {
        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const orgLogo = response.data.orgInfo.webLogoURIColor
              this.setState({ orgName, orgLogo });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

      if (accountCode) {
        Axios.get('/api/account', { params: { accountCode } })
        .then((response) => {
          console.log('Account info query attempted within sub settings', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employerName = response.data.accountInfo.employerName
            const employerLogoPath = response.data.accountInfo.employerLogoURI

            this.setState({ employerName, employerLogoPath });
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }
    }

    componentWillUnmount(){
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"pathwaysPage"} darkBackground={true} activeOrg={this.state.orgCode} />

              <section className="section-features">
                <div className="row">
                  <SubPathways history={this.props.navigate} activeOrg={this.state.orgCode} noOrgCode={this.state.noOrgCode} passedId={this.state.id} pageSource="landingPage" />
                </div>
              </section>

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(PathwaysPage);
