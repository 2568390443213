import React, { Component} from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';

import withRouter from '../Functions/WithRouter';
import {toggleVars} from '../Functions/ToggleVars';
import {signUp, signIn} from '../Services/AuthRoutes';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const appleStoreBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/AppleStoreBadge.png';
const googlePlayBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/GooglePlayBadge.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class LogInWidget extends Component {
    constructor(props) {
        super(props)
        this.state = {
          vanillaSignInRedirects: true,

          maxCharLength: '60',
          skipWalkthrough: false,
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          password: '',
          gradYear: '',
          pathway: '',
          jobTitle: '',
          employerName: '',

          subscribed: true,
          mfaEnabled: false,
          showEmployerWalkthrough: false,

          employerOptions: [],

          toggleLink: '/',

          gradYearOptions: [],
          pathwayOptions: [],
          roleNameOptions: [],
          acceptableRoles: ['','Student','Career-Seeker','School Support','Worker','Teacher','Counselor','WBLC','Mentor','Employer','Admin'],

          animate: false,
          opacity: 1,
          isWaiting: false,
          error: {
              message: ''
          }
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.signUpUser = this.signUpUser.bind(this)
        this.signInUser = this.signInUser.bind(this)

        this.validateRoleName = this.validateRoleName.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.submitCode = this.submitCode.bind(this)
        this.resendCode = this.resendCode.bind(this)
        this.renderResponse = this.renderResponse.bind(this)

    }

    componentDidMount() {
        console.log('login form page is mounting')

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within login form ', this.props, prevProps)

      if (this.props.roleName !== prevProps.roleName || this.props.orgCode !== prevProps.orgCode || this.props.courseId !== prevProps.courseId || this.props.workId !== prevProps.workId || this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.opportunityId !== prevProps.opportunityId) {
        this.retrieveData()
      } else if (this.props.signInPage !== prevProps.signInPage) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in LogInWidget', this.props)

      let verifyCode = localStorage.getItem('verifyCode');
      if (verifyCode && verifyCode === 'true') {
        let email = localStorage.getItem('email');
        let firstName = localStorage.getItem('firstName');
        let lastName = localStorage.getItem('lastName');
        let username = localStorage.getItem('username');
        // let activeOrg = localStorage.getItem('activeOrg');

        const showVerifyCode = true
        // console.log('show me defaults: ', email, activeOrg)
        this.setState({ email, firstName, lastName, username, showVerifyCode })
      }

      let signInPage = this.props.signInPage

      let orgCode = this.props.orgCode
      const courseId = this.props.courseId
      const workId = this.props.workId
      const accountCode = this.props.accountCode
      let roleName = this.props.roleName
      if (this.props.passedRoleName === 'Employer') {
        roleName = this.props.passedRoleName
      }
      const opportunityId = this.props.opportunityId
      const path = window.location.pathname

      const category = 'Acquisition';
      let action = 'signUpScreenVisits'

      if (roleName === 'Admin') {
        action = 'signUpScreenVisits'
      } else {
        action = 'participantSignUpScreenVisits'
      }

      ReactGA.initialize([{ trackingId: "376007762" }]);
      ReactGA.event({ category, action, label: action });

      let toggleLink = ''
      if (path) {
        if (path.includes("join") || path.includes("signup")) {
          toggleLink = path.replace("join","signin").replace("signup","signin")
        } else if (path.includes("signin")){
          toggleLink = path.replace("signin","signup")
        }
      }

      let mobileView = false
      if (window.innerWidth <= 768) {
        mobileView = true
      }

      Axios.get('/api/emails', { params: { orgCode } })
      .then((response) => {
        console.log('Emails query attempted', response.data);

          if (response.data.success) {
            console.log('emails query worked')

            const studentBenefits = response.data.benefits[0].studentBenefits
            const careerSeekerBenefits = response.data.benefits[0].studentBenefits
            const teacherBenefits = response.data.benefits[0].teacherBenefits
            const mentorBenefits = response.data.benefits[0].mentorBenefits
            const employerBenefits = response.data.benefits[0].employerBenefits

            this.setState({ studentBenefits, careerSeekerBenefits, teacherBenefits, mentorBenefits, employerBenefits });

          } else {
            console.log('emails query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Emails query did not work for some reason', error);
      });
      // console.log('---!!!', roleName, orgCode, courseId)
      if (roleName && orgCode) {
        if (roleName.toLowerCase() === 'career-seeker') {
          roleName = 'Career-Seeker'
        } else {
          roleName = roleName.charAt(0).toUpperCase() + roleName.slice(1)
        }

        let isValid = false
        if (this.state.acceptableRoles.includes(roleName)) {
          if (orgCode !== '') {
            isValid = true
          }
        }

        let gradYearOptions = ['']
        const currentYear = new Date().getFullYear()
        for (let i = 1; i <= 5; i++) {

          gradYearOptions.push(currentYear + i - 1)
        }

        console.log('oppId 2: ', opportunityId, courseId)

        this.setState({ roleName, originalRoleName: true, orgCode, path, isValid, courseId, workId, opportunityId,
          accountCode, toggleLink, mobileView, gradYearOptions, signInPage })

        Axios.get('/api/org', { params: { orgCode} })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const school = response.data.orgInfo.orgName
              const schoolDistrict = response.data.orgInfo.district
              const orgName = response.data.orgInfo.orgName
              const orgLogoURI = response.data.orgInfo.webLogoURI
              const headerImageURL = response.data.orgInfo.headerImageURL
              const orgFocus = response.data.orgInfo.orgFocus
              const publicOrg = response.data.orgInfo.isPublic
              const placementPartners = response.data.orgInfo.placementPartners

              let pathwayOptions = ['']
              let includePathway = false
              // if (response.data.orgInfo.pathways && response.data.orgInfo.pathways.length > 0 && orgCode === 'dpscd') {
              //   pathwayOptions = pathwayOptions.concat(response.data.orgInfo.pathways)
              //   includePathway = true
              // }

              const studentAliasSingular = response.data.orgInfo.studentAliasSingular
              const studentAliasPlural = response.data.orgInfo.studentAliasPlural
              const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
              const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

              let roleNameTitle = null
              if ((roleName === 'Student' || roleName === 'Career-Seeker') && studentAliasSingular) {
                roleNameTitle = studentAliasSingular
              } else if (roleName === 'Mentor' && mentorAliasSingular) {
                roleNameTitle = mentorAliasSingular
              }

              this.setState({
                school, schoolDistrict, orgName, orgLogoURI, headerImageURL, orgFocus, pathwayOptions, includePathway,
                publicOrg, placementPartners,
                studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural,
                roleNameTitle
              });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        if (courseId && courseId !== orgCode) {
          Axios.get('/api/courses', { params: { courseIds: [courseId]} })
          .then((response) => {
            console.log('Course info query attempted', response.data);

              if (response.data.success) {
                console.log('course info query worked')

                if (response.data.courses && response.data.courses.length > 0) {
                  const courseName = response.data.courses[0].name
                  this.setState({ courseName })
                }

                // const school = response.data.orgInfo.orgName
                // const schoolDistrict = response.data.orgInfo.district
                // const orgName = response.data.orgInfo.orgName
                // const orgLogoURI = response.data.orgInfo.webLogoURI
                //
                // this.setState({ school, schoolDistrict, orgName, orgLogoURI });

              } else {
                console.log('course info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Course info query did not work for some reason', error);
          });
        }

      } else if (roleName) {
        // no orgCode
        console.log('show toggle 3: ', toggleLink )
        this.setState({ roleName, originalRoleName: true, path, accountCode, toggleLink, mobileView, orgCode, opportunityId, courseId })
      } else {

        // no orgCode or roleName
        const roleNameOptions = ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Mentor','Employer Representative','Other']
        this.setState({ path, accountCode, toggleLink, mobileView, orgCode, opportunityId, courseId, roleNameOptions })
      }

      if (accountCode) {
        Axios.get('/api/account', { params: { accountCode } })
        .then((response) => {
          console.log('Account info query attempted', response.data);

          if (response.data.success) {
            console.log('account info query worked')

            const employerName = response.data.accountInfo.employerName
            const employerLogoURI = response.data.accountInfo.employerLogoURI
            const employerLogoWhiteURI = response.data.accountInfo.employerLogoWhiteURI
            const internalToolEnabled = response.data.accountInfo.internalToolEnabled

            this.setState({ employerName, employerLogoURI, employerLogoWhiteURI, internalToolEnabled })

          } else {
            this.setState({
                error: { message: response.data.message },
                isWaiting: false
            })
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
          this.setState({
              error: { message: error },
              isWaiting: false
          })
        });
      }
    }

    formChangeHandler(event) {
      //validate strings
      if (event.target.name === 'firstName') {
        let capitalizedFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ firstName: capitalizedFirstName })
      } else if (event.target.name === 'lastName') {
        let capitalizedLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ lastName: capitalizedLastName})
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name === 'gradYear') {
        this.setState({ gradYear: event.target.value })
      } else if (event.target.name === 'pathway') {
        this.setState({ pathway: event.target.value })
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value })
      } else if (event.target.name === 'employerName') {
        const employerName = event.target.value
        const accountCode = null
        this.setState({ employerName, accountCode, isLoading: true })

        this.searchEmployers(employerName)
      } else if (event.target.name === 'orgCode') {
        let orgCode = event.target.value
        if (orgCode && orgCode !== '') {
          orgCode.toLowerCase()
        }
        this.setState({ orgCode })
      } else if (event.target.name === 'roleName') {
        const roleName = event.target.value

        this.setState({ roleName })
      } else if (event.target.name === 'school') {
        this.setState({ school: event.target.value })
      } else if (event.target.name === 'otherRoleName') {
        this.setState({ otherRoleName: event.target.value })
      } else if (event.target.name === 'subscribed') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ subscribed: value, formHasChanged: true })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/account/search', { params: { employerName } })
        .then((response) => {
          console.log('Employer search query attempted', response.data);

            if (response.data.success) {
              console.log('employer search query worked')

              const employerOptions = response.data.employers
              // if (employerOptions && employerOptions.length > 0) {
              //   console.log('we in')
              //   self.setState({ employerOptions, isLoading: false });
              // }
              self.setState({ employerOptions, isLoading: false });

            } else {
              console.log('employer search query did not work', response.data.message)
              this.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('Employer search query did not work for some reason', error);
            this.setState({ isLoading: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      this.setState({ employerName: employer.employerName, employerOptions: [], accountCode: employer.accountCode })

    }

    async signUpUser() {
      console.log('async signUpUser called')

      this.setState({ isSaving: true, isWaiting: true, errorMessage: null,
        error: { message: null }, successMessage: null
      })

      let firstName = ReactDOM.findDOMNode(this.refs.firstName).value
      let lastName = ReactDOM.findDOMNode(this.refs.lastName).value
      let email = ReactDOM.findDOMNode(this.refs.email).value
      const password = ReactDOM.findDOMNode(this.refs.password).value
      // const orgCode = ReactDOM.findDOMNode(this.refs.orgCode).value
      const gradYear = this.state.gradYear
      const pathway = this.state.pathway
      let jobTitle = this.state.jobTitle
      let employerName = this.state.employerName
      let schoolName = this.state.school
      let activeOrg = 'guidedcompass'
      if (this.state.orgCode && this.state.orgCode !== '') {
        activeOrg = this.state.orgCode.toLowerCase()
      }

      let roleName = this.state.roleName
      let otherRoleName = this.state.otherRoleName

      // let workMode = false
      if (this.state.roleName.toLowerCase() === 'worker') {
        roleName = 'Student'
        // workMode = true
      } else if (roleName === 'Student / Career Seeker' || roleName.toLowerCase() === 'career-seeker') {
        roleName = 'Student'
      } else if (this.state.roleName === 'Work-Based Learning Coordinator') {
        roleName = 'WBLC'
      } else if (this.state.roleName === 'Employer Representative') {
        roleName = 'Employer'
      }

      if ((roleName === 'Teacher' || roleName === 'WBLC' || roleName === 'Admin') && activeOrg === 'guidedcompass') {
        activeOrg = 'defaultsandbox'
        if (roleName === 'WBLC') {
          roleName = 'Admin'
        }
      }

      const studentBenefits = this.state.studentBenefits
      const teacherBenefits = this.state.teacherBenefits
      const mentorBenefits = this.state.mentorBenefits
      const employerBenefits = this.state.employerBenefits

      let accountCode = ''

      if (roleName && roleName.toLowerCase() === 'Student') {
        // do stuff
      } else if (roleName && roleName.toLowerCase() === 'career-seeker') {
        // do stuff
      } else if (roleName && roleName.toLowerCase() === 'Teacher') {
        // do stuff
      } else if (roleName === 'Admin' || roleName === 'admin' || roleName === 'WBLC') {
        // do stuff
      } else if (roleName && roleName.toLowerCase() === 'Mentor') {
        if (this.state.accountCode) {
          accountCode = this.state.accountCode
        } else {
          accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }
      } else if (roleName && roleName.toLowerCase() === 'employer') {
        if (this.props.accountCode) {
          accountCode = this.props.accountCode
        } else if (this.state.accountCode) {
          accountCode = this.state.accountCode
        } else {
          accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }
      }

      const mfaEnabled = this.state.mfaEnabled

      let requestAccess = false
      if (!this.state.originalRoleName) {
        // requestAccess = true
        if (roleName === 'Employer' || roleName === 'Other') {
          requestAccess = true
        }
      }

      let createdAt = new Date();
      let updatedAt = new Date();

      const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, activeOrg, roleName, otherRoleName, null, null, schoolName, null, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, mfaEnabled, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,requestAccess,pathway)

      if (returnedValue && returnedValue.success) {
        console.log('sign up was successful: ', returnedValue)

        const category = 'Acquisition';
        let action = 'signUps'

        if (returnedValue.user.roleName === 'Admin') {
          action = 'signUps'
        } else {
          action = 'participantSignUps'
        }

        ReactGA.initialize([{ trackingId: "376007762" }]);
        ReactGA.event({ category, action, label: action });

        if (this.state.mfaEnabled) {
          localStorage.setItem('verifyCode', 'true')
        }
        // let dataToPass = {
        //   emailId: returnedValue.user.email, cuFirstName: returnedValue.user.firstName,
        //   cuLastName: returnedValue.user.lastName, pictureURL: returnedValue.user.pictureURL,
        //   jobTitle: returnedValue.user.jobTitle, employerName: returnedValue.user.employerName,
        //   loggedIn: true
        // }
        //
        // this.props.passData(dataToPass)
        // this.props.closeModal()
        const skipWalkthrough = returnedValue.skipWalkthrough
        this.setState({ skipWalkthrough })

        if (roleName === 'Student') {
          if (this.props.modalIsOpen) {
            this.props.passData({ success: true, message: returnedValue.message })
          } else {
            if (this.state.mfaEnabled) {
              this.setState({ showVerifyCode: true, isSaving: false, isWaiting: false })
            } else {

              if (this.state.roleName.toLowerCase() === 'worker') {
                // console.log('going to work mode immediately')
                this.props.navigate('/app')
              } else {
                if (skipWalkthrough) {
                  if (this.state.opportunityId) {
                    this.props.navigate('/app/opportunities/' + this.state.opportunityId)
                  } else if (this.state.courseId) {
                    this.props.navigate('/app/courses/' + this.state.courseId + '/take')
                  } else {
                    this.props.navigate('/app')
                  }
                } else {
                  this.props.navigate('/app/walkthrough', { state: { opportunityId: this.state.opportunityId, courseId: this.state.courseId }})
                }
              }
            }
          }

        } else if (roleName === 'Admin' || roleName === 'admin' || roleName === 'WBLC') {
          if (this.props.modalIsOpen) {
            this.props.passData({ success: true, message: returnedValue.message })
          } else {

            if (requestAccess) {
              this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps. We may need to admit you to an existing organization or create a new organization for you.' })
            } else {
              if (this.state.mfaEnabled) {
                this.setState({ showVerifyCode: true, isSaving: false, isWaiting: false })
              } else {
                if (this.state.courseId) {
                  this.props.navigate('/organizations/' + activeOrg + '/courses/' + this.state.courseId + '/take')
                } else {
                  this.props.navigate('/organizations/' + activeOrg + '/dashboard')
                }
              }
            }
          }

        } else if (roleName === 'Employer') {
          console.log('the role name is employer', accountCode)

          if (this.props.modalIsOpen) {

            const sharePartners = [activeOrg]
            const contactFirstName = firstName
            const contactLastName = lastName
            const contactEmail = email
            const contactTitle = jobTitle
            const orgContacts = [{ contactFirstName, contactLastName, contactEmail, contactTitle }]

            const accountBody = {
              accountCode, employerName, orgContacts,
              contactFirstName, contactLastName, contactTitle, contactEmail,
              sharePartners, activeOrg, createdAt, updatedAt
            }

            Axios.post('/api/account/create', accountBody)
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Account update save worked', response.data);

                this.props.passData({ success: true, message: returnedValue.message })

              } else {
                console.error('there was an error saving the employer ', response.data.message);
                this.props.passData({ success: true, message: returnedValue.message })
              }
            }).catch((error) => {
                console.log('The employer save did not work', error);
                this.props.passData({ success: true, message: returnedValue.message })
            });

          } else {
            // console.log('v2---signed up new employer user-----', returnedValue, requestAccess, this.state.mfaEnabled)
            if (requestAccess) {
              this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps. We may need to admit you to an existing employer account or create a new employer account for you. In the meantime, you can always post at:',  successLink: 'https://www.guidedcompass.com/employers/post' })
            } else {
              console.log('v3')
              if (this.state.mfaEnabled) {
                this.setState({ showVerifyCode: true, isSaving: false, isWaiting: false })
              } else {

                if (this.state.showEmployerWalkthrough) {
                  this.props.navigate('/employers/' + accountCode + '/walkthrough')
                } else {
                  this.props.navigate('/employers/' + accountCode + '/dashboard')
                }
              }
            }
          }

        } else {

          if (this.props.modalIsOpen) {
            this.props.passData({ success: true, message: returnedValue.message })
          } else {
            if (requestAccess && roleName === 'Other') {
              this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps.' })
            } else {
              if (this.state.mfaEnabled) {
                this.setState({ showVerifyCode: true, isSaving: false, isWaiting: false })
              } else {
                if (this.state.courseId) {
                  this.props.navigate('/advisor/courses/' + this.state.courseId + '/take')
                } else {
                  this.props.navigate('/advisor')
                }
              }
            }
          }
        }

      } else if (returnedValue && returnedValue.message) {
        console.log('sign up error: ', returnedValue)
        this.setState({ isSaving: false, isWaiting: false, errorMessage: returnedValue.message,
          error: { message: returnedValue.message }
        })
      } else {

        this.setState({ isSaving: false, isWaiting: false, errorMessage: returnedValue.error.message,
          error: { message: returnedValue.error.message }
        })
      }
    }

    async signInUser() {
      console.log('async signInUser called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const email = ReactDOM.findDOMNode(this.refs.email).value
      const password = ReactDOM.findDOMNode(this.refs.password).value
      const orgFocus = this.state.orgFocus
      const orgCode = this.state.orgCode

      const returnedValue = await signIn(email, password, orgFocus, orgCode)
      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        const orgToJoin = returnedValue.orgToJoin

        if (this.state.mfaEnabled) {

          const emailId = this.state.email
          const verificationCode = Math.floor(Math.random() * 100000)

          const postBody = { emailId, verificationCode }

          Axios.post('/api/send-verification-code', postBody)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('New verification code sent', response.data);
              this.setState({ showVerifyCode: true })

            } else {
              console.error('there was an error sending the verification code ', response.data.message);
              this.setState({ errorMessage: response.data.message })

            }
          }).catch((error) => {
              console.log('The new verification code send did not work', error);
              this.setState({ errorMessage: error.toString() })
          });
        } else {

          if ((!this.state.vanillaSignInRedirects && this.state.path === '/signin') || this.state.path.includes('student') || this.state.path.includes('career-seeker') || (this.props.modalIsOpen && (returnedValue.user.roleName === 'Student' || returnedValue.user.roleName === 'Career-Seeker' || returnedValue.user.roleName === 'Employee')) || (this.state.vanillaSignInRedirects && window.location.pathname === '/signin' && (returnedValue.user.roleName === 'Student' || returnedValue.user.roleName === 'Career-Seeker' || returnedValue.user.roleName === 'Employee'))) {

            if (this.props.modalIsOpen) {
              this.props.passData({ success: true, message: returnedValue.message })
            } else {

              if (orgToJoin) {
                this.props.navigate('/app/orgs/' + orgToJoin, { state: { errorMessage: 'Click above to join this community' }})
              } else {
                if (returnedValue.redirectToOnboarding) {
                  this.props.navigate('/app/walkthrough')
                } else {
                  if (this.state.opportunityId) {
                    if (returnedValue.user && returnedValue.user.myOrgs && !returnedValue.user.myOrgs.includes('unite-la') && this.props.opportunityOrg === 'unite-la') {
                      this.props.navigate('/app/add-workspaces/'+ this.props.opportunityOrg + '/' + this.state.opportunityId)
                    } else {
                      this.props.navigate('/app/opportunities/' + this.state.opportunityId)
                    }
                  } else if (this.state.courseId) {
                    this.props.navigate('/app/courses/' + this.state.courseId + '/take')
                  } else {
                    this.props.navigate('/app')
                  }
                }
              }
            }

          } else if (this.state.path === '/organizations/' + returnedValue.user.activeOrg + '/signin' || this.state.path.includes('admin') || this.state.path.includes('wblc') || (this.props.modalIsOpen && returnedValue.user.roleName === 'Admin') || (this.state.vanillaSignInRedirects && window.location.pathname === '/signin' && (returnedValue.user.roleName === 'Admin' || returnedValue.user.roleName === 'WBLC' || returnedValue.user.roleName === 'Work-Based Learning Coordinator'))) {
            if (returnedValue.user.roleName === 'Admin' || returnedValue.user.roleName === 'admin' || returnedValue.user.roleName === 'wblc') {
              if (this.props.modalIsOpen) {
                this.props.passData({ success: true, message: returnedValue.message })
              } else if (this.state.courseId) {
                this.props.navigate('/organizations/' + returnedValue.user.activeOrg + '/courses/' + this.state.courseId + '/take')
              } else {
                this.props.navigate('/organizations/' + returnedValue.user.activeOrg + '/dashboard')
              }
            } else {
              this.setState({ error: { message: 'You do not have admin permissions'}})
            }
          } else if ((this.state.path && this.state.path.includes('/employers')) || (this.props.modalIsOpen && returnedValue.user.roleName === 'Employer') || (this.state.vanillaSignInRedirects && window.location.pathname === '/signin' && returnedValue.user.roleName === 'Employer')) {
            const accountCode = returnedValue.user.accountCode
            if (accountCode !== '') {
              Axios.get('/api/account', { params: { accountCode } })
              .then((response) => {
                console.log('Account info query attempted', response.data);

                if (response.data.success) {
                  console.log('account info query worked')

                  // localStorage.setItem('email', email)
                  // localStorage.setItem('username', returnedValue.user.username)
                  // localStorage.setItem('firstName', returnedValue.user.firstName)
                  // localStorage.setItem('lastName', returnedValue.user.lastName)
                  // localStorage.setItem('roleName', returnedValue.user.roleName)
                  // localStorage.setItem('jobTitle', returnedValue.user.jobTitle)
                  // localStorage.setItem('isEmployer', 'true')
                  // localStorage.setItem('emp', accountCode)
                  // localStorage.setItem('employerName', response.data.accountInfo.employerName)
                  // localStorage.setItem('activeOrg', response.data.accountInfo.activeOrg)
                  // localStorage.setItem('employerLogoURL', response.data.accountInfo.employerLogoURI)
                  // localStorage.setItem('sharePartners', JSON.stringify(response.data.accountInfo.sharePartners))
                  // localStorage.setItem('orgFocus', orgFocus)
                  if (this.state.placementPartners) {
                    localStorage.setItem('placementPartners', JSON.stringify(this.state.placementPartners))
                  }

                  let publicOrg = this.state.publicOrg
                  if (response.data.accountInfo.activeOrg === 'guidedcompass') {
                    publicOrg = true
                  }
                  localStorage.setItem('publicOrg', publicOrg)

                  this.setState({ isWaiting: false })

                  if (this.props.modalIsOpen) {
                    this.props.passData({ success: true, message: returnedValue.message })
                  } else {
                    this.props.navigate('/employers/' + accountCode + '/dashboard')
                  }

                } else {
                  this.setState({
                      error: { message: response.data.message },
                      isWaiting: false
                  })
                }

              }).catch((error) => {
                console.log('Account info query did not work for some reason', error);
                this.setState({
                    error: { message: error },
                    isWaiting: false
                })
              });
            } else {
              this.setState({ error: { message: 'No account code found'}})
            }

          } else if (this.state.path.includes('/advisor') || this.state.path.includes('/teacher') || this.state.path.includes('/mentor') || (this.props.modalIsOpen) || (this.state.vanillaSignInRedirects && window.location.pathname === '/signin' && (returnedValue.user.roleName === 'Teacher' || returnedValue.user.roleName === 'Counselor' || returnedValue.user.roleName === 'School Support' || returnedValue.user.roleName === 'Mentor'))) {
            // mentor or teacher

            if (returnedValue.user.roleName !== 'Student') {
              if (this.props.modalIsOpen) {
                this.props.passData({ success: true, message: returnedValue.message })
              } else {
                if (this.state.courseId) {
                  this.props.navigate('/advisor/courses/' + this.state.courseId + '/take')
                } else {
                  this.props.navigate('/advisor')
                }
              }
            } else {
              // error - students can't view
              this.setState({ error: { message: 'Error, you dont have permission to view this portal'}})
            }

          } else {
            console.log('show crucial values: ', returnedValue.user.activeOrg, returnedValue.user.roleName, window.location.pathname)
            this.setState({ error: { message: 'Something went wrong identifying your role when attemtping to log in'}})
          }
        }

      } else if (returnedValue && returnedValue.message) {
        console.log('show returnedValue 3: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message, error: { message: returnedValue.message }})
      } else if (returnedValue && returnedValue.error) {
        console.log('show returnedValue 4: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.error.message, error: { message: returnedValue.error.message }})
      } else {
        console.log('show returnedValue 5: ', returnedValue)
        // this.setState({ isSaving: false, errorMessage: returnedValue.message })
        this.setState({ isSaving: false, errorMessage: 'an unknown error has occurred', error: { message: 'an unknown error has occurred' }})
      }
    }

    validateRoleName(roleName) {
      console.log('validateRoleName called', roleName)

      const acceptableRoles = this.state.acceptableRoles.concat(this.state.roleNameOptions)

      let returnValue = false
      for (let i = 1; i <= acceptableRoles.length; i++) {

        if (acceptableRoles[i - 1].toLowerCase() === this.state.roleName.toLowerCase()) {
          returnValue = true
        }
      }
      console.log('show returnValue: ', returnValue)

      return returnValue
    }

    itemClicked(value, type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        let roleName = value
        this.setState({ roleName })
      }
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false })
    }

    submitCode() {
      console.log('submitCode called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.email
      const verificationCode = this.state.verificationCode

      const postBody = { emailId, verificationCode }

      Axios.post('/api/verify-code', postBody)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Code successfully verified ', response.data);
          // this.setState({ successMessage: response.data.message })

          localStorage.removeItem('verifyCode')

          const skipWalkthrough = this.state.skipWalkthrough
          const requestAccess = this.state.requestAccess

          const activeOrg = response.data.user.activeOrg
          const firstName = response.data.user.firstName
          const lastName = response.data.user.lastName
          const email = response.data.user.email
          const roleName = response.data.user.roleName
          const otherRoleName = response.data.user.otherRoleName
          const accountCode = response.data.user.accountCode
          const jobTitle = response.data.user.jobTitle
          const employerName = response.data.user.employerName
          // const workMode = response.data.user.workMode

          const createdAt = new Date()
          const updatedAt = new Date()

          if ((!this.props.modalIsOpen && this.props.type && this.props.type === 'SignUp') || (this.props.modalIsOpen && !this.state.signInPage)) {
            // sign up
            if (roleName === 'Student') {
              if (this.props.modalIsOpen) {
                this.props.passData({ success: true, message: response.data.message })
              } else {
                if (roleName.toLowerCase() === 'worker') {
                  console.log('going to work mode immediately')
                  this.props.navigate('/app')
                } else {
                  if (skipWalkthrough) {
                    if (this.state.opportunityId) {
                      this.props.navigate('/app/opportunities/' + this.state.opportunityId)
                    } else if (this.state.courseId) {
                      this.props.navigate('/app/courses/' + this.state.courseId + '/take')
                    } else {
                      this.props.navigate('/app')
                    }
                  } else {
                    this.props.navigate('/app/walkthrough', { state: { opportunityId: this.state.opportunityId, courseId: this.state.courseId }})
                  }
                }
              }

            } else if (roleName === 'Admin' || roleName === 'admin' || roleName === 'WBLC') {
              if (requestAccess) {
                this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps. We may need to admit you to an existing organization or create a new organization for you.' })
              } else {
                if (this.state.mfaEnabled) {
                  this.setState({ showVerifyCode: true })
                } else {
                  this.props.navigate('/organizations/' + activeOrg + '/dashboard')
                }
              }

            } else if (roleName === 'Employer') {
              console.log('the role name is employer', accountCode)
              if (this.props.modalIsOpen) {

                const sharePartners = [activeOrg]
                const contactFirstName = firstName
                const contactLastName = lastName
                const contactEmail = email
                const contactTitle = jobTitle
                const orgContacts = [{ contactFirstName, contactLastName, contactEmail, contactTitle }]

                const accountBody = {
                  accountCode, employerName, orgContacts,
                  contactFirstName, contactLastName, contactTitle, contactEmail,
                  sharePartners, activeOrg, createdAt, updatedAt
                }

                Axios.post('/api/account/create', accountBody)
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Account update save worked', response.data);

                    this.props.passData({ success: true, message: response.data.message })

                  } else {
                    console.error('there was an error saving the employer ', response.data.message);
                    this.props.passData({ success: true, message: response.data.message })
                  }
                }).catch((error) => {
                    console.log('The employer save did not work', error);
                    this.props.passData({ success: true, message: response.data.message })
                });

              } else {
                if (requestAccess) {
                  this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps. We may need to admit you to an existing employer account or create a new employer account for you. In the meantime, you can always post at:',  successLink: 'https://www.guidedcompass.com/employers/post' })
                } else {
                  if (this.state.showEmployerWalkthrough) {
                    this.props.navigate('/employers/' + accountCode + '/walkthrough')
                  } else {
                    this.props.navigate('/employers/' + accountCode + '/dashboard')
                  }
                }
              }

            } else {

              if (this.props.modalIsOpen) {
                this.props.passData({ success: true, message: response.data.message })
              } else {
                if (requestAccess && roleName === 'Other') {
                  this.setState({ successMessage: 'Your registration has been recorded. Please allow 1-2 business days for our team to determine next steps.' })
                } else {
                  this.props.navigate('/advisor')
                }
              }
            }
          } else {

            // sign in
            if (this.state.path === '/signin' || this.state.path.includes('student') || this.state.path.includes('career-seeker') || (this.props.modalIsOpen && (response.data.user.roleName === 'Student' || response.data.user.roleName === 'Career-Seeker' || response.data.user.roleName === 'Employee'))) {

              if (this.props.modalIsOpen) {
                this.props.passData({ success: true, message: response.data.message })
              } else {
                if (this.state.opportunityId) {
                  if (response.data.user && response.data.user.myOrgs && !response.data.user.myOrgs.includes('unite-la') && this.props.opportunityOrg === 'unite-la') {
                    this.props.navigate('/app/add-workspaces/'+ this.props.opportunityOrg + '/' + this.state.opportunityId)
                  } else {
                    this.props.navigate('/app/opportunities/' + this.state.opportunityId)
                  }
                } else if (this.state.courseId) {
                  this.props.navigate('/app/courses/' + this.state.courseId + '/take')
                } else {
                  this.props.navigate('/app')
                }
              }

            } else if (this.state.path === '/organizations/' + response.data.user.activeOrg + '/signin' || this.state.path.includes('admin') || this.state.path.includes('wblc') || (this.props.modalIsOpen && response.data.user.roleName === 'Admin')) {
              if (response.data.user.roleName === 'Admin' || response.data.user.roleName === 'admin' || response.data.user.roleName === 'wblc') {

                if (this.props.modalIsOpen) {
                  this.props.passData({ success: true, message: response.data.message })
                } else {
                  this.props.navigate('/organizations/' + response.data.user.activeOrg + '/dashboard')
                }
              } else {
                this.setState({ error: { message: 'You do not have admin permissions'}})
              }
            } else if ((this.state.path && this.state.path.includes('/employers')) || (this.props.modalIsOpen && response.data.user.roleName === 'Employer')) {
              const accountCode = response.data.user.accountCode
              if (accountCode !== '') {
                Axios.get('/api/account', { params: { accountCode } })
                .then((response) => {
                  console.log('Account info query attempted', response.data);

                  if (response.data.success) {
                    console.log('account info query worked')

                    localStorage.setItem('email', email)
                    localStorage.setItem('username', response.data.user.username)
                    localStorage.setItem('firstName', response.data.user.firstName)
                    localStorage.setItem('lastName', response.data.user.lastName)
                    localStorage.setItem('roleName', response.data.user.roleName)
                    localStorage.setItem('jobTitle', response.data.user.jobTitle)
                    localStorage.setItem('isEmployer', 'true')
                    localStorage.setItem('emp', accountCode)
                    localStorage.setItem('employerName', response.data.accountInfo.employerName)
                    localStorage.setItem('activeOrg', response.data.accountInfo.activeOrg)
                    localStorage.setItem('employerLogoURL', response.data.accountInfo.employerLogoURI)
                    localStorage.setItem('sharePartners', JSON.stringify(response.data.accountInfo.sharePartners))
                    localStorage.setItem('orgFocus', this.state.orgFocus)

                    if (this.state.placementPartners) {
                      localStorage.setItem('placementPartners', JSON.stringify(this.state.placementPartners))
                    }

                    let publicOrg = this.state.publicOrg
                    if (response.data.accountInfo.activeOrg === 'guidedcompass') {
                      publicOrg = true
                    }
                    localStorage.setItem('publicOrg', publicOrg)

                    this.setState({ isWaiting: false })

                    if (this.props.modalIsOpen) {
                      this.props.passData({ success: true, message: response.data.message })
                    } else {
                      this.props.navigate('/employers/' + accountCode + '/dashboard')
                    }

                  } else {
                    this.setState({
                        error: { message: response.data.message },
                        isWaiting: false
                    })
                  }

                }).catch((error) => {
                  console.log('Account info query did not work for some reason', error);
                  this.setState({
                      error: { message: error },
                      isWaiting: false
                  })
                });
              } else {
                this.setState({ error: { message: 'No account code found'}})
              }

            } else if (this.state.path.includes('/advisor') || this.state.path.includes('/teacher') || this.state.path.includes('/mentor') || (this.props.modalIsOpen)) {
              // mentor or teacher

              if (response.data.user.roleName !== 'Student') {
                if (this.props.modalIsOpen) {
                  this.props.passData({ success: true, message: response.data.message })
                } else {
                  this.props.navigate('/advisor')
                }
              } else {
                // error - students can't view
                this.setState({ error: { message: 'Error, you dont have permission to view this portal'}})
              }

            } else {
              console.log('show crucial values: ', response.data.user.activeOrg, this.state.path, window.location.pathname)

              let errorMessage = "Something went wrong identifying your role during login"
              if (otherRoleName) {
                errorMessage = "Something went wrong identifying your role. " + otherRoleName + " is not a valid role. Email help@guidedcompass.com with this error message for assistance."
              }
              this.setState({ error: { message: errorMessage }})
            }
          }

        } else {
          console.error('there was an error verifying the verification code ', response.data.message);

          this.setState({ isSaving: false, errorMessage: response.data.message, error: { message: response.data.message }})

        }
      }).catch((error) => {
          console.log('The verification code verification did not work', error);
          this.setState({ isSaving: false, errorMessage: error.toString() })
      });
    }

    resendCode() {
      console.log('resendCode called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.email
      const verificationCode = Math.floor(Math.random() * 100000)

      const postBody = { emailId, verificationCode }

      Axios.post('/api/send-verification-code', postBody)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('New verification code sent', response.data);
          this.setState({ successMessage: response.data.message })

        } else {
          console.error('there was an error sending the verification code ', response.data.message);
          this.setState({ errorMessage: response.data.message })

        }
      }).catch((error) => {
          console.log('The new verification code send did not work', error);
          this.setState({ errorMessage: error.toString() })

      });
    }

    renderResponse(type) {
      console.log('renderResponse callled', type)

      return (
        <div key="renderResponse">
          <div className="bottom-padding-10-percent top-margin-negative-10-percent">
            <h3 className="full-width center-text error-color">Invalid Role Name</h3>

            <p className="top-margin-20 full-width center-text">It seems you may have an invalid link. If you already have an account, you can <Link to="/signin">Sign In Here</Link>.</p>

            <p className="top-margin-20 full-width center-text">If you don't, please contact the source of your link or send a message to <Link to="/contact">Our Contact Page</Link> to help troubleshoot the issue.</p>
            <div className="clear" />
          </div>
        </div>
      )
    }

    render() {

      return (
        <div>
          <header className={(this.props.modalIsOpen) ? "" : "full-page-background-image"}>
            {(this.state.showVerifyCode) ? (
              <div>
                {(window.innerWidth <= 768) ? (
                  <div>
                    <div className="relative-column-20">
                      <div className="width-50 height-40" />
                    </div>
                    <div className="relative-column-60">
                      <div className="flex-container row-direction flex-center">
                        <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                        {(this.state.orgCode && this.state.orgLogoURI) && (
                          <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                        )}
                        {(this.state.orgCode && this.state.orgLogoURI) && (
                          <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                        )}
                        {/*
                        {(this.state.orgCode && this.state.orgLogoURI) && (
                          <div style={{ backgroundColor: 'purple'}}>
                            <Link to={'/'}>
                              <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                            </Link>
                            <Link to={'/'}>
                              <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                            </Link>

                            <Link to={'/'}>
                              <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                              <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                              <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                              <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                              <div className="clear" />
                            </Link>
                          </div>
                        )}*/}
                      </div>
                    </div>
                    <div className="relative-column-20">
                      {/*
                      {(this.state.courseName) && (
                        <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                          <div className="spacer" /><div className="half-spacer" />
                          <label className="description-text-2 right-text white-text">Course:</label>
                          <div className="clear" />
                          <label className="right-text white-text">{this.state.courseName}</label>
                        </div>
                      )}*/}
                    </div>
                    <div className="clear" />
                    {/*
                    <div className="row-10 full-width left-padding-30">
                      <Link to={'/'}><img src={logoImg} alt="Compass logo" className="logo float-left"/></Link>
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <div>
                          <Link to={'/'}>
                            <div className="vertical-spacer" />
                            <img src={addIcon} alt="Compass logo" className="image-auto-14 float-left row-15" />
                            <div className="vertical-spacer" />
                            <img src={this.state.orgLogoURI} alt="Partner logo" className="logo float-left" />
                          </Link>
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                    <div className="row-10 full-width left-padding-30">
                      {(this.state.courseName) && (
                        <div className="float-left full-width">
                          <label className="description-text-2 white-text">Course:</label>
                          <div className="clear" />
                          <label className="white-text">{this.state.courseName}</label>
                        </div>
                      )}
                      <div className="clear" />
                    </div>*/}
                  </div>
                ) : (
                  <div className="row full-width">
                    <div className="relative-column-20">
                      <div className="width-50 height-40" />
                    </div>
                    <div className="relative-column-60">
                      <div className="flex-container row-direction flex-center">
                        <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                        {(this.state.orgCode && this.state.orgLogoURI) && (
                          <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                        )}
                        {(this.state.orgCode && this.state.orgLogoURI) && (
                          <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25"/></Link>
                        )}
                      </div>
                    </div>
                    <div className="relative-column-20">
                      {/*
                      {(this.state.courseName) && (
                        <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                          <div className="spacer" /><div className="half-spacer" />
                          <label className="description-text-2 right-text white-text">Course:</label>
                          <div className="clear" />
                          <label className="right-text white-text">{this.state.courseName}</label>
                        </div>
                      )}*/}
                    </div>

                    <div className="clear" />
                  </div>
                )}

                <div className="login-container standard-border" style={{ height: 'auto', paddingBottom: '50px' }}>
                  <h2 className="login-title normal-weight">Verify Your Identity</h2>

                  <div className="padding-40">
                    <div className="row-10">
                      <p>A verification code has been sent to your email. Enter the code to continue.</p>
                    </div>

                    <div className="row-10">
                      <input className="number-field" type="number" placeholder="e.g. 123..." name="verificationCode" value={this.state.verificationCode} onChange={this.formChangeHandler}/>
                    </div>

                    <div className="row-15">
                      <button className={(this.state.verificationCode) ? "btn btn-squarish" : "btn btn-squarish wash-out-2"} disabled={(this.state.verificationCode) ? false : true} onClick={() => this.submitCode()}>Continue</button>
                    </div>

                    <div className="row-5">
                      <button className="background-button cta-color bold-text" onClick={() => this.resendCode()}>Resend code ></button>
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                    <div className="top-padding-20">
                      <p>Need help? <Link to="/contact" target="_blank">Contact us</Link></p>
                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <div>
                {((!this.props.modalIsOpen && this.props.type && this.props.type === 'SignUp') || (this.props.modalIsOpen && !this.state.signInPage)) ? (
                  <div>
                    {(!this.props.modalIsOpen) && (
                      <div>
                        {(this.state.mobileView) ? (
                          <div>
                            <div className="relative-column-20">
                              <div className="width-50 height-40" />
                            </div>
                            <div className="relative-column-60">
                              <div className="flex-container row-direction flex-center">
                                <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                                )}
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                )}
                                {/*
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <div style={{ backgroundColor: 'purple'}}>
                                    <Link to={'/'}>
                                      <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                                    </Link>
                                    <Link to={'/'}>
                                      <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                                    </Link>

                                    <Link to={'/'}>
                                      <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                                      <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                                      <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                                      <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                                      <div className="clear" />
                                    </Link>
                                  </div>
                                )}*/}
                              </div>
                            </div>
                            <div className="relative-column-20">
                              {/*
                              {(this.state.courseName) && (
                                <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                                  <div className="spacer" /><div className="half-spacer" />
                                  <label className="description-text-2 right-text white-text">Course:</label>
                                  <div className="clear" />
                                  <label className="right-text white-text">{this.state.courseName}</label>
                                </div>
                              )}*/}
                            </div>
                            <div className="clear" />
                            {/*
                            <div className="row-10 full-width left-padding-30">
                              <Link to={'/'}><img src={logoImg} alt="Compass logo" className="logo float-left"/></Link>
                              {(this.state.orgCode && this.state.orgLogoURI) && (
                                <div>
                                  <Link to={'/'}>
                                    <div className="vertical-spacer" />
                                    <img src={addIcon} alt="Compass logo" className="image-auto-14 float-left row-15" />
                                    <div className="vertical-spacer" />
                                    <img src={this.state.orgLogoURI} alt="Partner logo" className="logo float-left" />
                                  </Link>
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                            <div className="row-10 full-width left-padding-30">
                              {(this.state.courseName) && (
                                <div className="float-left full-width">
                                  <label className="description-text-2 white-text">Course:</label>
                                  <div className="clear" />
                                  <label className="white-text">{this.state.courseName}</label>
                                </div>
                              )}
                              <div className="clear" />
                            </div>*/}
                          </div>
                        ) : (
                          <div className="row full-width">
                            <div className="relative-column-20">
                              <div className="width-50 height-40" />
                            </div>
                            <div className="relative-column-60">
                              <div className="flex-container row-direction flex-center">
                                <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                                )}
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25"/></Link>
                                )}
                              </div>
                            </div>
                            <div className="relative-column-20">
                              {/*
                              {(this.state.courseName) && (
                                <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                                  <div className="spacer" /><div className="half-spacer" />
                                  <label className="description-text-2 right-text white-text">Course:</label>
                                  <div className="clear" />
                                  <label className="right-text white-text">{this.state.courseName}</label>
                                </div>
                              )}*/}
                            </div>

                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    <div className={(this.props.modalIsOpen) ? "" : "login-container standard-border horizontal-padding-6"} style={(this.props.modalIsOpen) ? {} : { height: 'auto', paddingBottom: '50px' }}>
                      {((!this.state.originalRoleName) || (this.state.originalRoleName && this.state.roleName && this.validateRoleName(this.state.roleName))) ? (
                        <div>
                          {(this.state.accountCode && this.state.employerName && window.location.pathname.includes('/employers/')) ? (
                            <div>
                              <h2 className="login-title normal-weight">{this.state.employerName} Sign Up</h2>
                            </div>
                          ) : (
                            <div>
                              {(this.state.orgName) ? (
                                <div>
                                  <h2 className="login-title normal-weight">{this.state.orgName} {(this.state.roleNameTitle) ? this.state.roleNameTitle : this.state.roleName} Sign Up</h2>
                                </div>
                              ) : (
                                <div>
                                  {(this.state.roleName) ? (
                                    <div>
                                      <h2 className="login-title normal-weight">{(this.state.roleNameTitle) ? this.state.roleNameTitle : this.state.roleName} Sign Up</h2>
                                    </div>
                                  ) : (
                                    <h2 className="login-title normal-weight">Join Guided Compass</h2>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.successMessage) ? (
                            <div className="horizontal-padding-3">
                              <p className="success-message">{this.state.successMessage}</p>
                              {(this.state.successLink) && (
                                <div className="top-padding-20">
                                  <a href={this.state.successLink} target="_blank">{this.state.successLink}</a>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {(!this.state.originalRoleName) && (
                                <div className="horizontal-padding-3">
                                  <div className="row-10">
                                    <div>
                                      <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-45"}>
                                        <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                                      </div>
                                      <div className={(window.innerWidth > 768) ? "float-left left-padding" : "fixed-column-45 left-padding"}>
                                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <div className="noti-bubble-info-7-9">
                                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showRoleDefinitions: true })}>
                                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    {this.state.roleNameOptions.map((value, index) =>
                                      <div key={value} className="float-left">
                                        <div className="float-left row-5 right-padding">
                                          <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                            {(this.state.roleName === value) ? (
                                              <div className="tag-container-4">
                                                <p className="description-text-2 white-text half-bold-text">{value}</p>
                                              </div>
                                            ) : (
                                              <div className="tag-container-inactive-2">
                                                <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                              </div>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>

                                  {(this.state.roleName === 'Other') && (
                                    <div className="row-10">
                                      <label className="profile-label">You selected "other". Please write your role:<label className="error-color">*</label></label>
                                      <input className="text-field" type="text" placeholder="Other Role Name..." name="otherRoleName" value={this.state.otherRoleName} onChange={this.formChangeHandler} />
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="center-text center-horizontally top-padding width-80-percent">
                                <div className="row-15">
                                  <div className="container-left">
                                    <div className="unselected-border">
                                      <input className="text-field clear-border height-40" type="text" name="firstName"
                                      ref="firstName" placeholder="First Name" value={this.state.firstName} maxLength={this.state.maxCharLength}
                                      onChange={this.formChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="unselected-border">
                                      <input className="text-field clear-border height-40" type="text" name="lastName"
                                        ref="lastName" placeholder="Last Name" value={this.state.lastName} maxLength={this.state.maxCharLength}
                                        onChange={this.formChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="row-15">
                                  <div className="container-left">
                                    <div className="unselected-border">
                                      <input className="text-field clear-border height-40" type="email" name="email"
                                        ref="email" placeholder="Email" value={this.state.email} maxLength={this.state.maxCharLength}
                                        onChange={this.formChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="container-right">
                                    <div className="unselected-border">
                                      <input className="text-field clear-border height-40" type="Password" name="password"
                                        ref="password" placeholder="Password" value={this.state.password}
                                        onChange={this.formChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {(window.location.pathname.includes('/employers') && !this.props.orgCode) && (
                                  <input className="login-orgCode" type="text" name="orgCode"
                                    ref="orgCode" placeholder="Org Code" value={this.state.orgCode}
                                    onChange={this.formChangeHandler}
                                  />
                                )}

                                {(this.state.roleName && this.state.roleName.toLowerCase() === 'student') && (
                                  <div>
                                    {/*
                                    <div className="row-5">
                                      <label className="profile-label left-text">Expected Graduation Year</label>
                                      <select name="gradYear" className="dropdown" value={this.state.gradYear} onChange={this.formChangeHandler}>
                                          {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                      </select>
                                    </div>*/}
                                    {(this.state.includePathway) && (
                                      <div className="row-5">
                                        <label className="profile-label left-text">Pathway</label>
                                        <select name="pathway" className="dropdown" value={this.state.pathway} onChange={this.formChangeHandler}>
                                            {this.state.pathwayOptions.map(value => <option key={value.title} value={(value.title) ? value.title + ' | ' + value.school : ""}>{(value.title) ? value.title + " | " + value.school : ""}</option>)}
                                        </select>
                                      </div>
                                    )}

                                  </div>
                                )}

                                {((this.state.roleName && this.state.roleName.toLowerCase() === 'mentor') || (this.state.roleName && this.state.roleName.toLowerCase() === 'employer representative') || (this.state.roleName && this.state.roleName.toLowerCase() === 'employer' && !this.props.accountCode)) && (
                                  <div className="row-15">
                                    <div className="container-left">
                                      <div className="unselected-border">
                                        <input className="text-field clear-border height-40" type="text" name="jobTitle"
                                        ref="jobTitle" placeholder="Job Title" value={this.state.jobTitle}
                                        onChange={this.formChangeHandler}
                                        />
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="unselected-border">
                                        <input className="text-field clear-border height-40" type="text" name="employerName"
                                          ref="employerName" placeholder="Employer Name" value={this.state.employerName}
                                          onChange={this.formChangeHandler}
                                        />
                                      </div>

                                      {(this.state.isLoading) ? (
                                        <div className="flex-container flex-center row-10">
                                          <div>
                                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                            <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                                            <p className="center-text cta-color bold-text">Searching...</p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.employerOptions.length > 0 && this.state.employerName) && (
                                            <div>
                                              <div className="spacer" />

                                              {this.state.employerOptions.map(value =>
                                                <div key={value._id} className="left-text row-5">
                                                  <button className="background-button full-width left-text" onClick={() => this.employerClicked(value)}>
                                                    <div>
                                                      <div className="fixed-column-30">
                                                        <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                                                      </div>
                                                      <div className="calc-column-offset-30">
                                                        <p className="cta-color">{value.employerName}</p>
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>)}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                    </div>

                                    <div className="clear" />

                                  </div>
                                )}

                                {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'Work-Based Learning Coordinator') && (
                                  <div className="row-15">
                                    <div className="container-left">
                                      <div className="unselected-border">
                                        <input className="text-field clear-border height-40" type="text" name="school"
                                        ref="schoolName" placeholder={(this.state.roleName === 'Work-Based Learning Coordinator') ? "Program Name" : "School Name"} value={this.state.school}
                                        onChange={this.formChangeHandler}
                                        />
                                      </div>
                                    </div>

                                    <div className="clear" />
                                  </div>
                                )}
                              </div>

                              {(this.state.roleName === 'Student / Career Seeker') && (
                                <div className="top-padding horizontal-padding-3">
                                  <div className="fixed-column-20">
                                    <input type="checkbox" name="subscribed" checked={this.state.subscribed} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="calc-column-offset-20 top-padding-5">
                                    <div className="mini-spacer" /><div className="mini-spacer" />
                                    <p className="description-text-3">Receive weekly emails of event, project, and work opportunities based on your interests.</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}

                              <div className="login-other-elements">
                                  <button
                                      className="btn btn-primary login-button"
                                      disabled={this.state.isSaving}
                                      onClick={() => this.signUpUser()}
                                      >
                                      Sign Up</button>
                                  <p className="login-error-message">{this.state.error.message}</p>

                                  {(window.location.pathname === '/employers/' + this.state.accountCode + '/organizations/' + this.state.orgCode + '/join') ? (
                                    <p className="login-switch">Already have an account? {(this.props.modalIsOpen) ? <button className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: true })}>Sign in instead</button> : <Link to={'/employers/signin'} state={{ orgCode: this.state.orgCode, workId: this.state.workId, roleName: this.state.roleName, courseId: this.state.courseId, opportunityId: this.state.opportunityId, opportunityOrg: this.props.opportunityOrg }}>Sign in instead</Link>}</p>
                                  ) : (
                                    <p className="login-switch">Already have an account? {(this.props.modalIsOpen) ? <button className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: true })}>Sign in instead</button> : <Link to={this.state.toggleLink} state={{ orgCode: this.state.orgCode, workId: this.state.workId, roleName: this.state.roleName, courseId: this.state.courseId, opportunityId: this.state.opportunityId, opportunityOrg: this.props.opportunityOrg }}>Sign in instead</Link>}</p>
                                  )}


                                  {(!this.state.orgCode || this.state.orgCode === '') && (
                                    <div>
                                      {(!this.props.modalIsOpen) && (
                                        <p className="login-switch">Joining the portal of a partner organization? You can join their portal directly <Link to={'/partners'} state={{ reason: "I Don't Know the Org Code of My Organization" }}>here</Link>.</p>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.renderResponse('invalidRole')}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {(!this.props.modalIsOpen) && (
                      <div>
                        {(this.state.mobileView) ? (
                          <div className="row full-width">
                            <div className="relative-column-20">
                              <div className="width-50 height-40" />
                            </div>
                            <div className="relative-column-60">
                              <div className="flex-container row-direction flex-center">
                                <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                                )}
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25"/></Link>
                                )}
                              </div>
                            </div>
                            <div className="relative-column-20">
                              {/*
                              {(this.state.courseName) && (
                                <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                                  <div className="spacer" /><div className="half-spacer" />
                                  <label className="description-text-2 right-text white-text">Course:</label>
                                  <div className="clear" />
                                  <label className="right-text white-text">{this.state.courseName}</label>
                                </div>
                              )}*/}
                            </div>

                            <div className="clear" />
                          </div>
                        ) : (
                          <div className="row full-width">
                            <div className="relative-column-20">
                              <div className="width-50 height-40" />
                            </div>
                            <div className="relative-column-60">
                              <div className="flex-container row-direction flex-center">
                                <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                                )}
                                {(this.state.orgCode && this.state.orgLogoURI) && (
                                  <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25"/></Link>
                                )}
                              </div>
                            </div>
                            <div className="relative-column-20">
                              {/*
                              {(this.state.courseName) && (
                                <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                                  <div className="spacer" /><div className="half-spacer" />
                                  <label className="description-text-2 right-text white-text">Course:</label>
                                  <div className="clear" />
                                  <label className="right-text white-text">{this.state.courseName}</label>
                                </div>
                              )}*/}
                            </div>

                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    <div className={(this.props.modalIsOpen) ? "" : "login-container horizontal-padding-6"}>
                      {((!this.state.originalRoleName) || (this.state.originalRoleName && this.state.roleName && this.validateRoleName(this.state.roleName))) ? (
                        <div>
                          {(this.state.orgName) ? (
                            <div>
                              <h2 className="login-title normal-weight">{this.state.orgName} {(this.state.roleNameTitle) ? this.state.roleNameTitle : this.state.roleName} Sign In</h2>
                            </div>
                          ) : (
                            <div>
                              {(this.state.roleName) ? (
                                <div>
                                  <h2 className="login-title normal-weight">{this.state.roleName} Sign In</h2>
                                </div>
                              ) : (
                                <h2 className="login-title normal-weight">Sign In</h2>
                              )}
                            </div>
                          )}

                          <div className="row-15 center-horizontally width-80-percent">
                            <div className="row-15">
                              <div className="unselected-border">
                                <input
                                  className="text-field clear-border height-40"
                                  type="email"
                                  ref="email"
                                  placeholder="email"
                                  onChange={event => this.setState({email: event.target.value})}
                                  onKeyPress={(e) => (e.key === 'Enter') ? this.signInUser() : console.log('key clicked')}
                                />
                              </div>
                            </div>
                            <div className="row-15">
                              <div className="unselected-border">
                                <input
                                  className="text-field clear-border height-40"
                                  type="password"
                                  ref="password"
                                  placeholder="password"
                                  onChange={event => this.setState({password: event.target.value})}
                                  onKeyPress={(e) => (e.key === 'Enter') ? this.signInUser() : console.log('key clicked')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="login-other-elements">
                              <button
                                  className="btn btn-primary login-button"
                                  onClick={() => this.signInUser()}
                                  >Sign In</button>
                              <p className="login-error-message">{this.state.error.message}</p>
                              {(this.state.path !== '/employers/signin') && (
                                <div>
                                  <p className="login-switch">Haven't created an account? {(this.props.modalIsOpen) ? <button className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: false })}>Sign up instead</button> : <Link to={this.state.toggleLink} state={{ orgCode: this.state.orgCode, workId: this.state.workId, roleName: this.state.roleName, courseId: this.state.courseId, opportunityId: this.state.opportunityId }}>Sign up instead</Link>} </p>
                                </div>
                              )}

                              <p className="login-switch">Forgot your password? <Link to={'/reset-password'} target="_blank">Reset password</Link></p>

                              <div className="spacer"/><div className="spacer"/>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {this.renderResponse('invalidRole')}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(toggleVars.showMobileApps && this.state.roleName !== 'Employer') && (
                  <div>
                    <div className="clear" />
                    <div className="row top-margin-100">
                      <ul className="store-list">
                        <div className="store-list-item"><li>
                          <a className="background-button-white" href="https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8" target="_blank" rel="noopener noreferrer">
                            <img src={appleStoreBadge} alt="Compass apple store badge icon" className="apple-store-badge" />
                          </a>
                        </li></div>
                        <div className="store-list-item"><li>
                          <a className="background-button-white" href="https://play.google.com/store/apps/details?id=com.guidedcompass" target="_blank" rel="noopener noreferrer">
                            <img src={googlePlayBadge} alt="Compass google play badge icon" className="google-play-badge" />
                          </a>
                        </li></div>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
          </header>

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
          >

            {(this.state.showRoleDefinitions) && (
              <div key="showRoleDefinitions" className="full-width padding-20">
                <p className="heading-text-2">Role Definitions for Guided Compass (GC)</p>

                <div className="row-10 description-text-1">
                  <div className="row-10">
                    <p>1) <label className="half-bold-text cta-color">Student / Career Seeker</label> refers to people in school to learn career-related skills, people seeking to start their careers, or people seeking to switch their careers. Use GC to explore careers, manage courses, RSVP to events, submit projects, and apply to work opportunities.</p>
                  </div>

                  <div className="row-10">
                    <p>2) <label className="half-bold-text cta-color">Teacher</label> refers to a teachers, instructors, professors, and trainers that teach career-releated skills. Use GC to track student progress, endorse student competencies, and oversee student projects.</p>
                  </div>

                  <div className="row-10">
                    <p>3) <label className="half-bold-text cta-color">Work-Based Learning Coordinator</label> refers to coordinators and administrators of work-based learning programs. These people act as intermediaries between career-seekers and employers / employment. Use GC to manage work-based learning postings, refer students, track placements, manage all members, and export reports.</p>
                  </div>

                  <div className="row-10">
                    <p>4) <label className="half-bold-text cta-color">Counselor</label> refers to people who coach or counsel career-seekers on career paths. Use GC to explore career paths, guide students, and oversee progress.</p>
                  </div>

                  <div className="row-10">
                    <p>5) <label className="half-bold-text cta-color">Mentor</label> refers to professionals that would like to be more active in helping career-seekers, including hosting events, providing project feedback, and conducting mock interviews.</p>
                  </div>

                  <div className="row-10">
                    <p>6) <label className="half-bold-text cta-color">Employer Representative</label> refers to HR or hiring managers interested in hands-off activities that directly benefit the employer (e.g., hiring  talent for project or full-time work).</p>
                  </div>

                  <div className="row-10">
                    <p>7) <label className="half-bold-text cta-color">Other</label> refers to anyone that does not fit neatly into the aforementioned categories.</p>
                  </div>
                </div>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
              </div>
            )}

         </Modal>
        </div>

      )
    }
}

export default withRouter(LogInWidget);
