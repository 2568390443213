import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import withRouter from './Functions/WithRouter';

class AdvEditMentor extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { mentorId } = this.props.params
      const { selectedMentor, source, org } = this.props.location.state;

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ emailId: email, username, id: mentorId, selectedMentor, activeOrg, org, orgFocus, roleName, orgLogo, source });
      }
    }


    render() {

      return (
        <div>
          {this.state.source === 'WBLC' && (
            <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          )}
          {this.state.source === 'Teacher' && (
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
          )}

          {/*<SubEditMentor id={this.state.id} activeOrg={this.state.org} selectedMentor={this.state.selectedMentor} source={this.state.source} history={this.props.navigate} />*/}

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvEditMentor);
