import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import EditCourse from './EditCourse';
import withRouter from '../Functions/WithRouter';

const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class EditMembers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emails: '',

            emailId: '',
            username: '',
            cuFirstName: '',
            cuLastName: '',

            requests: [],
            unreadNotificationsCount: 0,
            org: '',

            courseOptions: [],
            gradYearOptions: [],
            categoryOptions: [],
            roleNameOptions: ['','Teacher','School Support','Counselor','Work-Based Learning Coordinator'],

            employers: [],
            selectedCourse: { name: ''},
            memberRoleName: 'Teacher',
            selectedCourses: [],

            recipientErrorMessage: '',
            serverPostSuccess: false,
            serverSuccessMessage: '',
            serverErrorMessage: ''
        }

        this.retrieveData = this.retrieveData.bind(this);
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.closeModal = this.closeModal.bind(this)

        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)

        this.addNewCourse = this.addNewCourse.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editMembers')

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      const path = window.location.pathname

      // const categoryOptions = ['Builder','Founder']

      this.setState({ emailId: email, username, cuFirstName, cuLastName, path, org, roleName, orgFocus })

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','High School Graduate', 'All Grade Levels']
          const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

          let functionOptions = response.data.workOptions[0].functionOptions
          let industryOptions = response.data.workOptions[0].industryOptions

          this.setState({ gradeLevelOptions, knowledgeLevelOptions, functionOptions, industryOptions })
        }
      });

      if (this.props.userId) {

        const userId = this.props.userId

        Axios.get('/api/users/profile/details', { params: { userId } })
        .then((response) => {
          console.log('User profile query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved profile', this.props.type)

            if (this.props.type === 'Educators') {
              const memberFirstName = response.data.user.firstName
              const memberLastName = response.data.user.lastName
              const memberEmail = response.data.user.email
              const memberRoleName = response.data.user.roleName
              const schoolName = response.data.user.school
              const courseIds = response.data.user.courseIds

              this.setState({ memberFirstName, memberLastName, memberEmail, memberRoleName, schoolName })

              //need to pull course details
              if (courseIds && courseIds.length > 0) {
                Axios.get('/api/courses', { params: { courseIds } })
                .then((response) => {

                    if (response.data.success) {
                      console.log('Course details query worked', response.data);

                      if (response.data.courses) {
                        let selectedCourses = response.data.courses
                        this.setState({ selectedCourses })
                      }
                    } else {
                      console.log('no courses found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Course query did not work', error);
                });
              }
            }

          } else {
            console.log('user profile data found', response.data.message)
          }

        }).catch((error) => {
            console.log('User profile query did not work', error);
        });
      }

      // Axios.get('/api/org', { params: { orgCode: org } })
      // .then((response) => {
      //   console.log('Org info query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('org info query worked')
      //
      //       const orgName = response.data.orgInfo.orgName
      //       const orgContactFirstName = response.data.orgInfo.contactFirstName
      //       const orgContactLastName = response.data.orgInfo.contactLastName
      //       const orgContactEmail = response.data.orgInfo.contactEmail
      //       const headerImageURL = response.data.orgInfo.headerImageURL
      //
      //       this.setState({ orgName, orgContactFirstName, orgContactLastName, orgContactEmail, headerImageURL });
      //
      //     } else {
      //       console.log('org info query did not work', response.data.message)
      //     }
      //
      // }).catch((error) => {
      //     console.log('Org info query did not work for some reason', error);
      // });
      //
      // Axios.get('/api/emails', { params: { orgCode: org } })
      // .then((response) => {
      //   console.log('Emails query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('emails query worked')
      //
      //       const studentBenefits = response.data.benefits[0].studentBenefits
      //       const mentorBenefits = response.data.benefits[0].mentorBenefits
      //       const employerBenefits = response.data.benefits[0].employerBenefits
      //
      //       this.setState({ studentBenefits, mentorBenefits, employerBenefits });
      //
      //     } else {
      //       console.log('emails query did not work', response.data.message)
      //     }
      //
      // }).catch((error) => {
      //     console.log('Emails query did not work for some reason', error);
      // });

      if (this.props.type === 'Educators') {

        Axios.get('/api/courses', { params: { orgCode: org } })
        .then((response) => {

            if (response.data.success) {
              console.log('Course details query worked', response.data);

              if (response.data.courses) {
                let courseOptions = response.data.courses
                courseOptions.unshift({ name: ''})
                courseOptions.push({ name: 'Add New Course'})
                this.setState({ courseOptions })
              }
            } else {
              console.log('no courses found', response.data.message)
              let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
              this.setState({ courseOptions })
            }

        }).catch((error) => {
            console.log('Course query did not work', error);
            let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
            this.setState({ courseOptions })
        });
      }
      // } else {
      //   if (roleName === 'Teacher' || roleName === 'School Support' ) {
      //     // pull students within courses where user is a teacher
      //
      //     Axios.get('/api/users/profile/details', { params: { email } })
      //     .then((response) => {
      //
      //         if (response.data.success) {
      //           console.log('User profile query worked', response.data);
      //
      //           const courseIds = response.data.user.courseIds
      //
      //           if (courseIds && courseIds.length > 0) {
      //             console.log('there are course on the profile')
      //
      //             //need to pull course details
      //             Axios.get('/api/courses', { params: { courseIds } })
      //             .then((response) => {
      //
      //                 if (response.data.success) {
      //                   console.log('Course details query worked', response.data);
      //
      //                   if (response.data.courses) {
      //                     let courseOptions = response.data.courses
      //                     courseOptions.unshift({ name: ''})
      //                     this.setState({ courseIds, courseOptions })
      //                   }
      //                 } else {
      //                   console.log('no courses found', response.data.message)
      //                 }
      //
      //             }).catch((error) => {
      //                 console.log('Course query did not work', error);
      //             });
      //
      //           }
      //
      //         } else {
      //           console.log('no user details found', response.data.message)
      //
      //         }
      //
      //     }).catch((error) => {
      //         console.log('User profile query did not work', error);
      //     });
      //
      //   }
      // }
    }

    formChangeHandler(event) {

      if (event.target.name === 'emails') {

        this.setState({ emails: event.target.value })
      } else if (event.target.name === 'courseId') {

        let selectedIndex = null
        for (let i = 1; i <= this.state.courseOptions.length; i++) {
          if (event.target.value === this.state.courseOptions[i - 1].name) {
            selectedIndex = i - 1
          }
        }
        const selectedCourseId = event.target.value
        this.setState({ selectedCourseId, selectedIndex })
      } else if (event.target.name === 'course') {

        let selectedCourse = null
        for (let i = 1; i <= this.state.courseOptions.length; i++) {
          if (event.target.value === this.state.courseOptions[i - 1].name) {
            selectedCourse = this.state.courseOptions[i - 1]
          }
        }

        this.setState({ selectedCourse })

      } else if (event.target.name === 'memberRoleName') {
        this.setState({ memberRoleName: event.target.value })
      } else if (event.target.name === 'schoolName') {
        this.setState({ schoolName: event.target.value })
      }
    }

    handleSubmit(event) {

      console.log('handleSubmit called', this.state.emails)

      if (!this.state.memberFirstName || this.state.memberFirstName === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's first name"})
      } else if (!this.state.memberLastName || this.state.memberLastName === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's last name"})
      } else if (!this.state.memberEmail || this.state.memberEmail === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's email"})
      } else if (!this.state.memberEmail.includes('@')) {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add a valid email"})
      } else if (this.state.memberRoleName === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the the role name"})
      } else if (!this.state.schoolName || this.state.schoolName === '') {
        this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the the school name"})
      } else {
        console.log('add this mentor with a temporary password')

        const emailId = this.state.memberEmail
        const school = this.state.schoolName

        let courseIds = []
        if (this.state.selectedCourses && this.state.selectedCourses.length > 0) {
          console.log('in first', this.state.selectedCourses)
          for (let i = 1; i <= this.state.selectedCourses.length; i++) {
            console.log('in second', this.state.selectedCourses[i - 1]._id)
            courseIds.push(this.state.selectedCourses[i - 1]._id)
            console.log('in third', this.state.selectedCourses[i - 1]._id)
          }
        }

        const updatedAt = new Date()

        Axios.post('/api/users/profile/details', {
          emailId, school, courseIds, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //clear values
            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Member was edited successfully!'
            })
          } else {

            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Admin invite did not work', error);
        });
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    addItem() {
      console.log('addItem called')

      let alreadyAdded = false
      for (let i = 1; i <= this.state.selectedCourses.length; i++) {
        if (this.state.selectedCourses[i - 1]._id === this.state.selectedCourse._id) {
          alreadyAdded = true
        }
      }

      if (this.state.selectedCourse && this.state.selectedCourse.name === 'Add New Course') {
        console.log('add new course')

        this.setState({ modalIsOpen: true, showEditCourse: true, associationInfo: false, courseInfo: false })

      } else if (alreadyAdded === false && this.state.selectedCourse._id) {
        let selectedCourses = this.state.selectedCourses
        let selectedCourse = this.state.selectedCourse
        selectedCourses.push(selectedCourse)
        selectedCourse = { name: ''}
        this.setState({ selectedCourses, selectedCourse, formHasChanged: true })
      } else {
        console.log('already added')
      }
    }

    removeItem(index) {
      console.log('removeItem called', index)

      let selectedCourses = this.state.selectedCourses
      selectedCourses.splice(index, 1)
      this.setState({ selectedCourses, formHasChanged: true })
    }

    addNewCourse(courseObject) {
      console.log('addNewCourse called', courseObject)

      const course = courseObject

      let courseOptions = this.state.courseOptions
      courseOptions.splice(courseOptions.length - 2,0,courseObject)

      console.log('compare course: ', course, courseOptions)
      this.setState({ courseOptions, course })
    }

    render() {

      let memberRoleName = ''
      let educatorOnboardingLink = ''
      if (this.state.memberRoleName) {
        memberRoleName = this.state.memberRoleName.toLowerCase().replace(/ /g, "")
        educatorOnboardingLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/" + memberRoleName + "/join"
      }

      // let courseLink = ''
      if (this.state.selectedCourseId && this.state.selectedIndex) {
        // courseLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/courses/" + this.state.courseOptions[this.state.selectedIndex]._id + "/student/join"
      } else if (this.props.type === 'Educators' && this.state.selectedCourse._id) {
        if (this.state.memberRoleName) {
          memberRoleName = this.state.memberRoleName.toLowerCase().replace(/ /g, "")
        }

        // courseLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/courses/" + this.state.selectedCourse._id + "/" + memberRoleName + "/join"
      }

      let pageTitle = ''
      if (this.props.type && this.props.type === 'All') {
        pageTitle = 'Invite People to ' + this.state.orgName
      } else if (this.props.type && this.props.type === 'Mentors') {
        pageTitle = 'Add Mentors'
      } else if (this.props.type && this.props.type === 'Students') {
        pageTitle = 'Add Students'
      }

      return (
          <div>
              <div className="standard-container">

              <div>
                <p className="heading-text-2">{pageTitle}</p>
              </div>

              {(this.props.type && this.props.type === 'Educators') && (
                <div>
                  <div>
                    {(this.state.org) ? (
                      <div className="edit-profile-row">
                        <label className="profile-label">Share this link with educators for them to sign up:</label>
                        <a href={educatorOnboardingLink} target="_blank">{educatorOnboardingLink}</a>

                        <div className="spacer" />

                        <div className="clear" />
                      </div>
                    ) :(
                      <div className="edit-profile-row">
                        <label className="profile-label">An org code required to invite educators has not been created yet for your organization. You must have at least one student license to have a reserved org code. Please contact help@guidedcompass.com to purchase student licenses.</label>
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="spacer" /><div className="spacer" />
                  <hr className="clear-margin clear-padding"/>
                  <div className="clear" />

                  <div className="spacer" /><div className="spacer" />

                  <div className="edit-profile-row">
                    <p className="heading-text-5">Edit Educator Info</p>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
                      <p>{this.state.memberFirstName}</p>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
                      <p>{this.state.memberLastName}</p>
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Email<label className="error-color bold-text">*</label></label>
                      <p>{this.state.memberEmail}</p>
                    </div>

                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Role Name<label className="error-color bold-text">*</label></label>
                      <p>{this.state.memberRoleName}</p>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">School Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" name="schoolName"
                        placeholder="School name..." value={this.state.schoolName}
                        onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="container-left">
                      <div className="float-left">
                        <label className="profile-label">Associated Courses (Optional)</label>
                      </div>

                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, associationInfo: false, courseInfo: true, showEditCourse: false })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />
                    </div>
                    <div className="clear"/>

                    <div className="container-left">
                      <select name="course" className="dropdown" value={this.state.selectedCourse.name} onChange={this.formChangeHandler}>
                          {this.state.courseOptions.map(value => <option key={value._id} value={value.name}>{value.name}</option>)}
                      </select>
                    </div>
                    <div className="container-right">
                      <button className="btn btn-squarish" onClick={() => this.addItem() }>Add</button>
                    </div>
                    <div className="clear"/>

                    {this.state.selectedCourses.map((value, optionIndex) =>
                      <div key={value.name + "|" + optionIndex} className="float-left">
                        <div className="close-button-container-1">
                          <button className="background-button" onClick={() => this.removeItem(value, optionIndex)}>
                            <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                          </button>
                        </div>
                        <div className="float-left row-5 right-padding" >
                          <div className="tag-container-inactive">
                            <p className="description-text-2">{value.name}</p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="clear" />

                  </div>*

                  <div>
                    <p className="description-text-1 description-text-color">Note: this user will receive an automatated email confirmation with a link and a temporary password for if they choose to log in.</p>
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  <button className="btn btn-primary" onClick={this.handleSubmit}>Edit User</button>
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}
                </div>
              )}

              </div>

              {(this.state.showEditCourse) ? (
                <div>
                  <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedCourse={null} orgCode={this.state.org} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions} knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions} functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse}/>
                </div>
              ) : (
                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
               >
                 {(this.state.associationInfo) && (
                   <div key="submissionDetail" className="full-width">
                      <p className="heading-text-2">Association Info</p>
                      <p>Were you affiliated with a mentoring group or association? If so, please indicate the name of the group for our records.</p>
                   </div>
                 )}

                 {(this.state.courseInfo) && (
                   <div key="submissionDetail" className="full-width">
                      <p className="heading-text-2">Course Info</p>
                      <p>Does this educator teach or counsel in any specific courses that would you like to source project opportunities for? If so, please add!</p>
                   </div>
                 )}

                 <div className="row-20">
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>

                </Modal>
              )}
          </div>

      )
    }
}


export default withRouter(EditMembers);
