import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubChangePassword from '../Subcomponents/ChangePassword';
import withRouter from '../Functions/WithRouter';

class OrgChangePassword extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ cuFirstName, cuLastName, username, emailId: email, org, orgFocus, orgLogo })
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubChangePassword email={this.state.emailId} accountCode={this.state.emp} activeOrg={this.state.org} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(OrgChangePassword)
