import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import AddMembers from './Subcomponents/AddMembers';
import withRouter from './Functions/WithRouter';

class AdvAddStudents extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName,
           activeOrg, orgFocus, roleName, orgLogo
         });
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
            <AddMembers activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} roleName={this.state.roleName} path={this.state.path} type="Students" />
            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(AdvAddStudents);
