// import { io } from 'socket.io-client';
import * as io from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3000';

let URL = 'https://guidedcompass.com'
if (window.location.hostname === 'localhost') {
  URL = 'http://localhost:443'
}

// const socketURL = window.location.hostname === 'localhost' ? 'http://localhost:443' : 'https://guidedcompass.com';
// const socket = io.connect(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });
// const socket = io(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });

// export const socket = io(URL);
// export const socket = io(URL, { transports: ['websocket', 'polling', 'flashsocket'] });
export const socket = io.connect(URL, { transports: ['websocket', 'polling', 'flashsocket'], rejectUnauthorized: false });

// const socket = require('socket.io-client')('https://192.168.0.31', { transports: ['websocket'], rejectUnauthorized: false })

// const socket = io.connect(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });

// import io from 'socket.io-client';
// import * as io from 'socket.io-client'
// import socketio from "socket.io-client";
// const SocketContext = React.createContext()


// const socket = io("http://localhost:4000");
// const socket = socketio.connect('http://localhost:4000');
// const socket = io.connect('http://localhost:443');

// const socketURL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';
// const socketURL = window.location.hostname === 'localhost' ? 'http://localhost:443' : 'https://guidedcompass.com';
