import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubNotifications from './Subcomponents/Notifications';
import withRouter from './Functions/WithRouter';

class Notifications extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ username, activeOrg, orgFocus, orgLogo })

    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />
          <div className="standard-container-2">
            <SubNotifications activeOrg={this.state.activeOrg} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(Notifications)
