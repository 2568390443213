import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import EditPosting from '../Subcomponents/EditPosting';
import withRouter from '../Functions/WithRouter';

class OrgEditJob extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      // console.log('loading OrgEditJob')

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let selectedOpportunity = null
      let jobs = null
      let objectId = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedOpportunity = this.props.location.state.selectedOpportunity
        jobs = this.props.location.state.jobs
        objectId = this.props.location.state.objectId
        duplicate = this.props.location.state.duplicate
      }

      console.log('show me selectedOpportunity', this.props.location.state)

      // let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      const path = window.location.pathname

      let postingId = null
      if (objectId) {
        postingId = objectId
      }

      if (id) {
        postingId = id
      }

      this.setState({ emailId: email, username, cuFirstName, cuLastName, selectedOpportunity, postingId,
        org, orgFocus, path, jobs, duplicate
      })
    }

    retrieveData(selectedOpportunity, org, jobs, duplicate) {
      console.log('retrieveData called', selectedOpportunity, org, jobs, duplicate)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      const path = window.location.pathname

      this.setState({ emailId: email, username, cuFirstName, cuLastName,
        selectedOpportunity, org, orgFocus, orgLogo, path, jobs, duplicate
      })
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <div>
            <EditPosting org={this.state.org} selectedOpportunity={this.state.selectedOpportunity} postingId={this.state.postingId} editMode={true} jobs={this.state.jobs} roleName="Admin" path={this.state.path} history={this.props.navigate} duplicate={this.state.duplicate} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditJob)
