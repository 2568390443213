import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubTrends from '../Subcomponents/Trends';
import withRouter from '../Functions/WithRouter';

class OrgTrends extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ orgCode: org, org, email, username, orgFocus, roleName, orgLogo })
    }

    render() {

        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate} />

              <div className="width-70-percent center-horizontally padding-30 max-width-1400">
                <SubTrends history={this.props.navigate} orgCode={this.state.orgCode}/>
              </div>

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(OrgTrends)
