import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubMessaging from '../Subcomponents/Messaging';
import withRouter from '../Functions/WithRouter';

class OrgMessaging extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let loadPage = true
        let newMessage = false
        if (this.props.location && this.props.location.state) {

          const { threadId, groupPost, generalPost, recipient } = this.props.location.state;

          if (groupPost || generalPost) {
            newMessage = true
          }
          this.setState({ threadId, username, org, orgFocus, orgLogo, roleName, groupPost, generalPost, recipient, loadPage, newMessage })

        } else if (this.props.params) {

          const { threadId } = this.props.params
          this.setState({ threadId, username, org, orgFocus, orgLogo, loadPage, roleName })

        } else {
          this.setState({ username, org, orgFocus, orgLogo, roleName, loadPage })
        }
      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubMessaging history={this.props.navigate} groupPost={this.state.groupPost} generalPost={this.state.generalPost} newMessage={this.state.newMessage} recipient={this.state.recipient} activeOrg={this.state.org}/>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(OrgMessaging)
