import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import ProfileCard from '../Subcomponents/ProfileCard';
import Footer from '../../landingPages/Footer';
import TopNavigation from '../../landingPages/TopNavigation';
import withRouter from '../Functions/WithRouter';

// const dropdownArrow = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class OrgApplicationExternalScores extends Component {
  constructor(props) {
      super(props)

      this.state = {
          isExternal: true,
          allowAccess: false,

          selectedApplication: null,

          stage: '',
          notes: '',
          feedback: '',
          employerMessage: '',
          newApprovedTracks: [],
          wpQuestions: [],
          suppScoreObjects: [],
          overallScore: '',

          endorsements: [],

          benchmark: null,

          candidateType: '',
          trackOptions: [],
          scoreOptions: ['','0','1','2','3','4','5'],
          filterOptions: ['','Highest Rating','Lowest Rating'],
          relationshipOptions: ['','Classmate','Mentor','Teacher'],
          pathwayOptions: [''],

          trackSelected: '',
          interview: { interviewerName: '', date: '', score: ''},
          interviews: [],
          matchIndex: 0,
          eIndex: 0,

          notifyApproval: false,
          notifyOverallFeedback: false,

          customAssessment: null,
          showCustomAssessment: false,

          skillScores: '',
          interestResults: '',
          personalityResults: '',

          paidExperienceCount: 0,
          volunteerExperienceCount: 0,

          caRotation: 0,
          wpRotation: 0,

          applicationFormHasChanged: false,

          checklistErrorMessage: '',
          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: '',
      }

      this.configureScores = this.configureScores.bind(this)
      this.retrieveData = this.retrieveData.bind(this)

      this.renderQandA = this.renderQandA.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveApplication = this.saveApplication.bind(this)
      this.checkForApps = this.checkForApps.bind(this)
      this.selectApp = this.selectApp.bind(this)

      this.renderInterviews = this.renderInterviews.bind(this)
      this.calculateInitialMatch = this.calculateInitialMatch.bind(this)
      this.calculateMatches = this.calculateMatches.bind(this)
      this.toggleView = this.toggleView.bind(this)
      this.renderScorerInfo = this.renderScorerInfo.bind(this)

  }

  componentDidMount(){

    const { org, postingId, applicationId } = this.props.params

    let email = localStorage.getItem('email');
    let roleName = localStorage.getItem('roleName');
    let activeOrg = localStorage.getItem('activeOrg');
    let isExternal = this.state.isExternal

    let maskMatch = false
    if (org === 'exp') {
      maskMatch = true
    }

    let scoreOptions = ['','0','1','2','3','4','5']
    let suppScoreObjects = []
    let includeOverallScore = true
    if (org === 'unite-la') {
      includeOverallScore = true
    } else if (org === 'exp') {

      let scoreMaxTen = true
      if (scoreMaxTen) {
        scoreOptions = ['','0','1','2','3','4','5','6','7','8','9','10']
      }
    }

    this.setState({ isExternal, email, postingId, applicationId, includeOverallScore, suppScoreObjects, maskMatch, scoreOptions, orgCode: org })
  }

  configureScores(postType) {
    console.log('configureScores called', postType)

    let suppScoreObjects = []

    if (postType === 'Scholarship') {
      suppScoreObjects = [{
        category: 'Letter of Recommendation (15%)', score: '', instructions: 'Letter of recommendation is titled LOR under the "key links" section of the profile card above.', rubric: [
          { score: '10', description: 'Very familiar and knowledgeable of student and their potential' },
          { score: '9-8', description: 'Familiarity of student and their potential' },
          { score: '7-6', description: 'Familiary; Some good knowledge of student' },
          { score: '5-3', description: 'Some familiarity; Some good knowledge of the student;' },
          { score: '2-1', description: 'Standard; No familiarity with the student;' },
        ]
      },{
        category: 'Essay #1 (30%)', score: '', instructions: "Essay #1 can be found above in the \"application question answers\" section. We're only evaluation traditional scholarship applicants.", rubric: [
          { score: '10', description: 'Outstanding; Excellent direction and goals; Excellent grrammar and sentence structure; Answers questions fully and creatively.' },
          { score: '9-8', description: 'Very good; Detailed goals; Great grammar and sentence structure; Answers questions fully.' },
          { score: '7-8', description: 'Satisfactory; General goals; Good grammar and sentence structure; Answers questions somewhat.' },
          { score: '5-3', description: 'Poor; Lacks focus and goals; Ok grammar and sentence structure; Answers questions poorly;' },
          { score: '2-1', description: 'Poor; Rambling text; Poor grammar and sentence structure; Does not answer questions;' },
        ]
      },{
        category: 'Essay #2 (30%)', score: '', instructions: "Essay #2 can be found above in the \"application question answers\" section. We're only evaluation traditional scholarship applicants.", rubric: [
          { score: '10', description: 'Outstanding; Excellent direction and goals; Excellent grrammar and sentence structure; Answers questions fully and creatively.' },
          { score: '9-8', description: 'Very good; Detailed goals; Great grammar and sentence structure; Answers questions fully.' },
          { score: '7-8', description: 'Satisfactory; General goals; Good grammar and sentence structure; Answers questions somewhat.' },
          { score: '5-3', description: 'Poor; Lacks focus and goals; Ok grammar and sentence structure; Answers questions poorly;' },
          { score: '2-1', description: 'Poor; Rambling text; Poor grammar and sentence structure; Does not answer questions;' },
        ]
      },{
        category: 'Volunteer / Community Involvement (15%)', instructions: 'Volunteer experience can be reviewed by clicking the volunteer section of the profile card above.', score: '', rubric: [
          { score: '10', description: 'Outstanding; Excellent variety of participation in community school;' },
          { score: '9-8', description: 'Very good; Very good variety of participation in community and/or school.' },
          { score: '7-8', description: 'Satisfactory; Some participation in community and/or school.' },
          { score: '5-3', description: 'Poor; Lacks focus and goals; Ok grammar and sentence structure; Answers questions poorly;' },
          { score: '2-1', description: 'Poor; Rambling text; Poor grammar and sentence structure; Does not answer questions;' },
        ]
      }]
    } else {
      suppScoreObjects.push({
        category: 'Knowledge', score: '', questions:[
          { question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.'},
          { question: 'Tell me about the best piece of work or project you’ve ever done. Explain your process getting to the completed project or piece.', helper: 'What to look for: Pride in work, ability to explain their design and process, confidence in skills.'},
        ]
      })
      suppScoreObjects.push({
        category: 'Communication', score: '', questions:[
          { question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.'},
          { question: 'Tell me about a recent group project you worked on. What was your role? How did you work with other team members?', helper: 'What to look for: teamwork, role defining, accountability, time management.'},
        ]
      })
      suppScoreObjects.push({
        category: 'Passion', score: '', questions:[
          { question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.'},
          { question: 'Which is your least favorite tech company and why?', helper: 'What to look for: Willingness to answer, reaction, communication skills.'},
        ]
      })
    }

    return suppScoreObjects
  }

  retrieveData(selectedApplication, selectedJob, benchmark, benchmarkOptions, org) {
    console.log('retrieveData called ', selectedApplication.email, selectedJob)

    if (selectedApplication && selectedJob) {
      // let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');

      let candidateType = ''
      if (selectedJob.title) {
        candidateType = selectedJob.title
      }

      let skillScores = ''
      let interestResults = ''
      let personalityResults = ''

      // let feedback = ''
      // if (selectedApplication.feedback) {
      //   feedback = selectedApplication.feedback
      // }

      let isSubsidyEligible = false
      if (selectedApplication.isSubsidyEligible) {
        isSubsidyEligible = selectedApplication.isSubsidyEligible
      }

      let isWorkforceReady = false
      if (selectedApplication.isWorkforceReady) {
        isWorkforceReady = selectedApplication.isWorkforceReady
      }

      console.log('let me see stage and notes', selectedApplication.stage, selectedApplication.notes)

      let stage = 'Applied'
      if (selectedApplication.stage) {
        stage = selectedApplication.stage
      }

      // let notes = ''
      // if (selectedApplication.notes) {
      //   notes = selectedApplication.notes
      // }

      let employerMessage = ''
      if (selectedApplication.employerMessage) {
        employerMessage = selectedApplication.employerMessage
      }

      let trackOptions = []
      let interview = { interviewerName: '', date: '', score: ''}
      let interviews = []

      let totalHours = 0
      let experienceHours = 0
      let projectHours = 0
      let volunteerHours = 0
      let paidExperienceCount = 0
      let volunteerExperienceCount = 0

      if (selectedJob.postType === "Track" || selectedJob.subPostType === 'Track') {

        trackOptions = selectedJob.tracks
        if (selectedApplication.interviews) {

          for (let i = 1; i <= selectedJob.tracks.length; i++) {
            if (selectedApplication.interviews[i - 1]) {
              const interviewerName = selectedApplication.interviews[i - 1].interviewerName
              const date = selectedApplication.interviews[i - 1].date
              const score = selectedApplication.interviews[i - 1].score
              interviews.push({interviewerName, date, score })
            } else {
              interviews.push({interviewerName: '', date: '', score: '0'})
            }
          }
          // interviews = selectedApplication.interviews
          // if (selectedApplication.interviews.length === selectedJob.tracks.length) {
          //   interviews = selectedApplication.interviews
          // } else {
          //   for (let i = 1; i <= selectedJob.tracks.length; i++) {
          //     interviews.push({interviewerName: '', date: '', score: '0'})
          //   }
          // }
        } else {
          for (let i = 1; i <= selectedJob.tracks.length; i++) {
            interviews.push({interviewerName: '', date: '', score: '0'})
          }
        }

      } else {

        if (selectedApplication.interview) {
          interview = selectedApplication.interview
        }

        if (selectedApplication.projects) {
          for (let i = 1; i <= selectedApplication.projects.length; i++) {
            console.log('see project ', selectedApplication.projects[i - 1].hours)
            const hourRange = selectedApplication.projects[i - 1].hours
            console.log('see project 1 ', hourRange.split(" - "))
            // const hourOptions = [{value: ''}, {value: '< 10'},{value: '10 - 50'}, {value: '50 - 100'}, {value: '100 - 500'}, {value: '500 - 1000'}, {value: '1000 - 5000'}, {value: '5000 - 10000'}, {value: '10000+'}]
            if (hourRange === '< 10') {
              projectHours = projectHours + 10
            } else if (hourRange === '10000+') {
              projectHours = projectHours + 10000
            } else {
              const hourArray = hourRange.split(" - ")
              const firstHour = Number(hourArray[0])
              const secondHour = Number(hourArray[1])
              const average = (firstHour + secondHour) / 2

              projectHours = projectHours + average
              totalHours = totalHours + average
            }
          }
        }

        if (selectedApplication.experience) {
          for (let i = 1; i <= selectedApplication.experience.length; i++) {

            const startDateString = selectedApplication.experience[i - 1].startDate
            const endDateString = selectedApplication.experience[i - 1].endDate
            const hoursPerWeek = selectedApplication.experience[i - 1].hoursPerWeek

            if (startDateString && endDateString && hoursPerWeek) {

              function convertMonth(longMonth) {
                console.log('convertMonth was called ', longMonth)

                let shortMonth = '01'
                if (longMonth === 'February') {
                  shortMonth = '02'
                } else if (longMonth === 'March') {
                  shortMonth = '03'
                } else if (longMonth === 'April') {
                  shortMonth = '04'
                } else if (longMonth === 'May') {
                  shortMonth = '05'
                } else if (longMonth === 'June') {
                  shortMonth = '06'
                } else if (longMonth === 'July') {
                  shortMonth = '07'
                } else if (longMonth === 'August') {
                  shortMonth = '08'
                } else if (longMonth === 'September') {
                  shortMonth = '09'
                } else if (longMonth === 'October') {
                  shortMonth = '10'
                } else if (longMonth === 'November') {
                  shortMonth = '11'
                } else if (longMonth === 'December') {
                  shortMonth = '12'
                }

                return shortMonth
              }

              const startDateArray = startDateString.split(" ")
              const startDateMonth = convertMonth(startDateArray[0])
              const startDate = new Date(startDateArray[1], startDateMonth, '01')

              const endDateArray = endDateString.split(" ")
              const endDateMonth = convertMonth(endDateArray[0])
              const endDate = new Date(endDateArray[1], endDateMonth, '01')

              const dateDifference = ((((endDate - startDate) / 1000) / 60) / 60) / 24

              console.log('show all date values: ', startDateString, startDateMonth, startDate, endDateString, endDateMonth, endDate, dateDifference, hoursPerWeek)

              const hoursPerWeekArray = hoursPerWeek.split(" ")
              const hpd = Number(hoursPerWeekArray[1]) / 7

              if (selectedApplication.experience[i - 1].wasPaid) {
                experienceHours = Math.round(experienceHours + (hpd * dateDifference))
                totalHours = Math.round(totalHours + (hpd * dateDifference))
                paidExperienceCount = paidExperienceCount + 1
              } else {
                volunteerHours = Math.round(volunteerHours + (hpd * dateDifference))
                totalHours = Math.round(totalHours + (hpd * dateDifference))
                volunteerExperienceCount = volunteerExperienceCount + 1
              }
            }
          }
        }

        console.log('show project and experience hours ', projectHours, experienceHours, totalHours )
      }

      const showApplication = true

      this.setState({
        showApplication, isLoading: false,
        totalHours, projectHours, experienceHours, volunteerHours, paidExperienceCount, volunteerExperienceCount
      })

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked')

          const orgName = response.data.orgInfo.orgName
          const orgURL = response.data.orgInfo.orgURL
          const orgMission = response.data.orgInfo.orgMission

          const orgContactFirstName = response.data.orgInfo.contactFirstName
          const orgContactLastName = response.data.orgInfo.contactLastName
          const orgContactEmail = response.data.orgInfo.contactEmail
          const orgContactTitle = response.data.orgInfo.contactTitle
          const orgProgramName = response.data.orgInfo.orgProgramName

          this.setState({

              org, orgName, orgURL, orgMission, orgProgramName,

              orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail,

          });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      Axios.get('/api/assessment/questions')
      .then((response) => {
          //console.log('O*Net worked', response.data.body, this);
          console.log('show me response', response.data)
          if ( response.data.success === true ) {
            console.log('o-net query worked')

            let wpQuestions = []
            for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
              wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
            }

            this.setState({ wpQuestions })

          } else {
            console.log('Assessment question query failed', response.data.message)
          }


      }).catch((error) => {
          console.log('O*NET did not work', error);
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          this.setState({
            pathwayOptions: response.data.workOptions[0].functionOptions
          })
        }
      }).catch((error) => {
          console.log('O*NET did not work', error);
      });

      let trackBenchmark = null
      if (selectedJob.tracks && selectedJob.tracks.length > 0) {
        for (let k = 1; k <= benchmarkOptions.length; k++) {

          if (selectedJob.tracks[0].benchmark._id === benchmarkOptions[k - 1]._id) {
            trackBenchmark = benchmarkOptions[k - 1]
          }
        }
      }

      let overallScore = ''
      let suppScoreObjects = this.configureScores(selectedJob.postType)
      let feedback = ''
      let notes = ''
      let firstName = ''
      let lastName = ''
      let jobTitle = ''
      let employerName = ''

      if (selectedApplication.scores) {
        for (let k = 1; k <= selectedApplication.scores.length; k++) {
          if (selectedApplication.scores[k - 1].scorer.email === this.state.email) {
            if (selectedApplication.scores[k - 1].overallScore) {
              overallScore = selectedApplication.scores[k - 1].overallScore
            }
            if (selectedApplication.scores[k - 1].suppScores && selectedApplication.scores[k - 1].suppScores.length > 0) {
              suppScoreObjects = selectedApplication.scores[k - 1].suppScores
            }
            if (selectedApplication.scores[k - 1].feedback) {
              feedback = selectedApplication.scores[k - 1].feedback
            }
            if (selectedApplication.scores[k - 1].notes) {
              notes = selectedApplication.scores[k - 1].notes
            }
            if (selectedApplication.scores[k - 1].scorer.firstName) {
              firstName = selectedApplication.scores[k - 1].scorer.firstName
            }
            if (selectedApplication.scores[k - 1].scorer.lastName) {
              lastName = selectedApplication.scores[k - 1].scorer.lastName
            }
            if (selectedApplication.scores[k - 1].scorer.jobTitle) {
              jobTitle = selectedApplication.scores[k - 1].scorer.jobTitle
            }
            if (selectedApplication.scores[k - 1].scorer.employerName) {
              employerName = selectedApplication.scores[k - 1].scorer.employerName
            }
          }
        }
      }

      this.setState({
          username, cuFirstName, cuLastName, org, orgLogo,

          selectedJob, selectedApplication, benchmark,

          trackOptions, benchmarkOptions, trackBenchmark,

          stage, interview, interviews, employerMessage,
          isSubsidyEligible, isWorkforceReady,

          candidateType, skillScores,
          interestResults, personalityResults,

          firstName, lastName, jobTitle, employerName, overallScore, suppScoreObjects, feedback, notes

      });

      console.log('b1')
      if (selectedJob.customAssessmentId) {
        console.log('b2')
        if (selectedJob.customAssessmentId !== '') {
          console.log('b3', selectedJob.customAssessmentId)
          Axios.get('/api/customassessments/byid', { params: { _id: selectedJob.customAssessmentId } })
          .then((response) => {
            console.log('Query custom assessment', response.data);

              if (response.data.success) {
                console.log('successfully retrieved custom assessment')

                this.setState({ customAssessment: response.data.assessment })

              } else {
                console.log('no assessment data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Assessment query did not work', error);
          });
        }
      } else if (selectedJob.isChild) {
        console.log('look at parent')

        // the questions should be included with the application
        let parentPostId = ''
        if (org === 'bixel') {
          parentPostId = '5c703cc7c29ab2400a036875'
        } else if (org === 'unite-la') {
          parentPostId = '5ee171c929bc432630ac7a95'
        }

        Axios.get('/api/postings/byid', { params: { _id: parentPostId } })
        .then((response) => {
          console.log('posting by id query attempted to get custom assessment', response.data);

          if (response.data.success) {
            console.log('posting by id query worked')

            const customAssessmentId = response.data.posting.customAssessmentId

            Axios.get('/api/customassessments/byid', { params: { _id: customAssessmentId } })
            .then((response) => {
              console.log('Query custom assessment', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved custom assessment')

                  this.setState({ customAssessment: response.data.assessment })

                } else {
                  console.log('no assessment data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Assessment query did not work', error);
            });

          } else {
            console.log('posting by id query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posting by post query did not work for some reason', error);
        });
      }
    }
  }

  calculateInitialMatch(application, selectedJob, benchmark, benchmarkOptions, activeOrg) {
    console.log('calculateInitialMatch called', application.email)

    if (selectedJob.postType === 'Track' || selectedJob.subPostType === 'Track') {

      Axios.post('/api/applications/matches', {
        application, selectedJob, benchmarkOptions, activeOrg })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {

          this.setState({ application, benchmark, benchmarkOptions, animating: false });
          this.retrieveData(response.data.application, selectedJob, benchmark, benchmarkOptions, activeOrg)

        } else {
          console.log('application matches did not work', response.data.message)
          this.setState({ serverPostSuccess: false, serverErrorMessage: 'there was an error retrieving applications ' + response.data.message, animating: false })

        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
          this.setState({ serverPostSuccess: false, serverErrorMessage: 'there was an error retrieving applications ' + error, animating: false })
      });
    } else if (selectedJob.postType === 'Individual' || selectedJob.subPostType === 'Individual') {

      Axios.post('/api/applications/matches', {
        application, selectedJob, benchmark, activeOrg })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Application matches save worked', response.data);

          this.retrieveData(response.data.application, selectedJob, benchmark, benchmarkOptions, activeOrg)

        } else {
          console.log('application matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
      });
    } else if (selectedJob.postType === 'Scholarship') {

      Axios.post('/api/applications/matches', {
        application, selectedJob, benchmark, activeOrg })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Application matches save worked', response.data);

          this.retrieveData(response.data.application, selectedJob, benchmark, benchmarkOptions, activeOrg)

        } else {
          console.log('application matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
      });
    } else {
      console.log('we already calculated matches')
      this.setState({ application, benchmark, benchmarkOptions });
    }


  }

  calculateMatches(interviewIndex, interviewScore) {
    console.log('calculateMatches', interviewIndex, interviewScore)

    let application = this.state.selectedApplication
    const selectedJob = this.state.selectedJob

    let subPostType = selectedJob.postType
    if (selectedJob.subPostType) {
      subPostType = selectedJob.subPostType
    }

    if (subPostType === 'Track' && interviewScore) {

      const benchmarkOptions = this.state.benchmarkOptions
      console.log('show payload: ', application, selectedJob, benchmarkOptions, interviewIndex, interviewScore )

      Axios.post('/api/applications/matches', {
        application, selectedJob, benchmarkOptions, interviewIndex, interviewScore })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Application matches save worked', response.data);

          const selectedApplication = response.data.application
          this.setState({ selectedApplication })

        } else {
          console.log('application matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
      });
    } else if (subPostType === 'Individual' && interviewScore) {

      const benchmark = this.state.benchmark

      Axios.post('/api/applications/matches', {
        application, selectedJob, benchmark, interviewScore })
      .then((response) => {
        console.log('application matches query attempted')
        if (response.data.success) {
          //save values
          console.log('Application matches save worked', response.data);

          const selectedApplication = response.data.application
          this.setState({ selectedApplication })

        } else {
          console.log('application matches did not work', response.data.message)
        }
      }).catch((error) => {
          console.log('Application matches did not work for some reason', error);
      });
    } else {
      console.log('there was an error calculating matches')
      // this.setState({ applications, benchmark, benchmarkOptions });
    }
  }

  formChangeHandler(event) {
    console.log('formChangeHandler called')

    if (event.target.name === 'notes') {
      this.setState({ notes: event.target.value, applicationFormHasChanged: true })
    } else if (event.target.name === 'employerMessage') {
      this.setState({ employerMessage: event.target.value, applicationFormHasChanged: true })
    } else if (event.target.name.includes('interview')) {

      if (this.state.selectedJob.postType === 'Individual' || this.state.selectedJob.subPostType === 'Individual') {
        let nameArray = event.target.name.split("|")

        let interview = this.state.interview
        if (nameArray[1] === 'name') {
          interview = {
            interviewerName: event.target.value,
            date: interview.date,
            score: interview.score
          }
        } else if (nameArray[1] === 'date') {

          interview = {
            interviewerName: interview.interviewerName,
            date: event.target.value,
            score: interview.score
          }
        } else if (nameArray[1] === 'score') {
          interview = {
            interviewerName: interview.interviewerName,
            date: interview.date,
            score: event.target.value
          }

          let nameIndex = 0
          this.calculateMatches(nameIndex, event.target.value)
        }
        this.setState({ interview, applicationFormHasChanged: true })
      } else {
        let nameArray = event.target.name.split("|")

        let nameIndex = Number(nameArray[2]) - 1
        let interviews = this.state.interviews
        if (nameArray[1] === 'name') {
          interviews[nameIndex] = {
            interviewerName: event.target.value,
            date: interviews[nameIndex].date,
            score: interviews[nameIndex].score
          }
        } else if (nameArray[1] === 'date') {

          interviews[nameIndex] = {
            interviewerName: interviews[nameIndex].interviewerName,
            date: event.target.value,
            score: interviews[nameIndex].score
          }
        } else if (nameArray[1] === 'score') {
          interviews[nameIndex] = {
            interviewerName: interviews[nameIndex].interviewerName,
            date: interviews[nameIndex].date,
            score: event.target.value
          }
          this.calculateMatches(nameIndex, event.target.value)
        }
        this.setState({ interviews, applicationFormHasChanged: true })

      }

    } else if (event.target.name === 'feedback') {

      let notifyOverallFeedback = this.state.notifyOverallFeedback
      if (event.target.value === '') {
        notifyOverallFeedback = false
      } else {
        notifyOverallFeedback = true
      }
      this.setState({ feedback: event.target.value, notifyOverallFeedback, applicationFormHasChanged: true })

    } else if (event.target.name === 'track') {

      let trackSelected = event.target.value
      let matchIndex = 0
      for (let i = 1; i <= this.state.trackOptions.length; i++) {
        console.log(i);
        if (trackSelected === this.state.trackOptions[i - 1].name) {
          matchIndex = i - 1
        }
      }

      let trackBenchmark = null
      for (let k = 1; k <= this.state.benchmarkOptions.length; k++) {

        if (this.state.selectedJob.tracks[matchIndex].benchmark._id === this.state.benchmarkOptions[k - 1]._id) {
          trackBenchmark = this.state.benchmarkOptions[k - 1]
        }
      }

      this.setState({ trackSelected, matchIndex, trackBenchmark })
    } else if (event.target.name === 'stage') {
      this.setState({ stage: event.target.value, applicationFormHasChanged: true })
    } else if (event.target.name === 'firstName') {
      this.setState({ firstName: event.target.value })
    } else if (event.target.name === 'lastName') {
      this.setState({ lastName: event.target.value })
    } else if (event.target.name === 'email') {
      this.setState({ email: event.target.value })
    } else if (event.target.name === 'jobTitle') {
      this.setState({ jobTitle: event.target.value })
    } else if (event.target.name === 'employerName') {
      this.setState({ employerName: event.target.value })
    } else if (event.target.name === 'accessCode') {
      this.setState({ accessCode: event.target.value })
    } else if (event.target.name === 'overallScore') {
      this.setState({ overallScore: event.target.value })
    } else if (event.target.name.includes('suppScore')) {
      const nameArray = event.target.name.split('|')
      const index = nameArray[1]

      let suppScoreObjects = this.state.suppScoreObjects
      suppScoreObjects[index]['score'] = event.target.value
      this.setState({ suppScoreObjects })

    }
  }

  saveApplication() {
    console.log('saveApplication called')

    this.setState({ errorMessage: null, successMessage: null })

    if (this.state.includeOverallScore && this.state.overallScore === '') {
      this.setState({ errorMessage: 'Please add an overall score' })
    } else if (!this.state.firstName || this.state.firstName === '') {
      this.setState({ errorMessage: 'Please add your first name' })
    } else if (!this.state.lastName || this.state.lastName === '') {
      this.setState({ errorMessage: 'Please add your last name' })
    } else if (!this.state.email || this.state.email === '') {
      this.setState({ errorMessage: 'Please add your email' })
    } else if (!this.state.email.includes('@')) {
      this.setState({ errorMessage: 'Please adjust your email to a valid email' })
    } else {

      // require all scores if no overall score
      if (!this.state.includeOverallScore && this.state.suppScoreObjects) {
        for (let i = 1; i <= this.state.suppScoreObjects.length; i++) {
          if (this.state.suppScoreObjects[i - 1].score === '') {
            this.setState({ errorMessage: 'Please add your scores'})
            return
          }
        }
      }

      const _id = this.state.selectedApplication._id
      const postingId = this.state.selectedJob._id
      const overallScore = this.state.overallScore
      const suppScoreObjects = this.state.suppScoreObjects

      const feedback = this.state.feedback
      const notes = this.state.notes

      const firstName = this.state.firstName
      const lastName = this.state.lastName
      const email = this.state.email
      const jobTitle = this.state.jobTitle
      const employerName = this.state.employerName

      const appFirstName = this.state.selectedApplication.firstName
      const appLastName = this.state.selectedApplication.lastName
      const appEmail = this.state.selectedApplication.email
      const orgContactEmail = this.state.selectedJob.orgContactEmail

      const updatedAt = new Date()
      console.log('about to save')
      Axios.post('/api/applications/score', { //sessions: sessions
        _id, postingId, overallScore, suppScoreObjects,
        feedback, notes,
        firstName, lastName, email, jobTitle, employerName,
        appFirstName, appLastName, appEmail, orgContactEmail,
        updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);
          this.setState({ successMessage: 'Application updated successfully!' })

        } else {
          console.error('there was an error updating the application');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
          this.setState({ errorMessage: error })
      });
    }
  }

  renderInterviews() {
    console.log('renderInterviews called', this.state.interviews)

    let rows = []

    for (let i = 1; i <= this.state.selectedJob.tracks.length; i++) {

      const trackIndex = i - 1

      let nameName = "interview|name|" + i
      let dateName = "interview|date|" + i
      let scoreName = "interview|score|" + i

      let interviewerName = ''
      let interviewDate = ''

      let interviewScore = ''
      if (this.state.interviews[i - 1]) {
        if (this.state.interviews[i - 1].interviewerName) {
          interviewerName = this.state.interviews[i - 1].interviewerName
        }
        if (this.state.interviews[i - 1].date) {
          interviewDate = this.state.interviews[i - 1].date
        }
        if (this.state.interviews[i - 1].score) {
          interviewScore = this.state.interviews[i - 1].score
        }
      }

      rows.push(
        <div key={i}>
          <p className="profile-descriptor half-bold-text">#{i} {this.state.selectedJob.tracks[i - 1].name}</p>
          <div className="relative-column-60">
            <p className="profile-descriptor">Interviewer Name</p>
            <input type="text" className="text-field" placeholder="Add the name..." name={nameName} value={interviewerName} onChange={this.formChangeHandler}></input>
          </div>
          <div className="relative-column-20">
            <p className="profile-descriptor">Interview Date</p>
            <input type="date" className="date-field half-bold-text" placeholder="Add a date..." name={dateName} value={interviewDate} onChange={this.formChangeHandler}></input>
          </div>
          <div className="relative-column-20">
            <p className="profile-descriptor">Interview Score</p>
            <select name={scoreName} value={interviewScore} onChange={this.formChangeHandler} className="dropdown">
              {this.state.scoreOptions.map(value =>
                <option key={value.key} value={value}>{value}</option>
              )}
            </select>
          </div>
          <div className="clear"/>
          <div className="row-10 float-right">
            <Link className="secret-link" to={'/organizations/' + this.state.org + '/postings/' + this.state.selectedApplication._id + '/applications/' + this.state.selectedApplication._id + '/interview'} state={{ selectedApplication: this.state.selectedApplication, selectedTrack: this.state.selectedJob.tracks[trackIndex], interview: this.state.interviews[trackIndex], selectedJob: this.state.selectedJob, trackIndex }}>
              <div className="float-right horizontal-padding-7">
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12"/>
              </div>
              <div className="float-right horizontal-padding-7">
                <label className="description-text-2 description-text-color half-bold-text">Edit Details</label>
                <div className="half-spacer" />
              </div>
            </Link>
          </div>
          <div className="clear"/>
          <div className="half-spacer" />
        </div>
      )
    }

    return rows
  }

  renderQandA(source) {
    console.log('renderQandA called', source)

    let rows = []

    if (source === 'ca') {
      if (this.state.selectedApplication && this.state.selectedApplication.newCustomAssessmentResults && this.state.selectedApplication.newCustomAssessmentResults.length > 0) {
        console.log('in newCustomAssessmentResults', )
        if (this.state.showCustomAssessment) {
          console.log('testing 1')
          for (let i = 1; i <= this.state.selectedApplication.newCustomAssessmentResults.length; i++) {
            console.log('count custom assessment resullts', i, this.state.selectedApplication.newCustomAssessmentResults[i - 1])

            if (this.state.selectedApplication.newCustomAssessmentResults[i - 1]) {
              rows.push(
                <div key={i.toString() + "customAssessment"}>
                  <div className="row-5 horizontal-padding-9">
                      <label className="profile-descriptor">{this.state.selectedApplication.newCustomAssessmentResults[i - 1].question}</label>
                      <p className="profile-label">{this.state.selectedApplication.newCustomAssessmentResults[i - 1].answer}</p>
                  </div>
                </div>
              )
            }
          }
        }
      } else if (this.state.selectedApplication && this.state.selectedApplication.customAssessmentResults) {
        if (this.state.customAssessment) {
          let questions = this.state.customAssessment.questions
          let results = this.state.selectedApplication.customAssessmentResults

          let loopLength = 0
          if (questions <= results) {
            loopLength = questions.length
          } else {
            loopLength = results.length
          }
          console.log('show length: ', questions.length, results.length)

          if (this.state.showCustomAssessment) {
            console.log('testing 1')
            for (let i = 1; i <= loopLength; i++) {
              console.log('count custom assessment resullts', i)

              if (questions[i - 1]) {
                rows.push(
                  <div key={i.toString() + "customAssessment"}>
                    <div className="row-5 horizontal-padding-9">
                        <label className="profile-descriptor">{questions[i - 1].name}</label>
                        <p className="profile-label">{results[i - 1]}</p>
                    </div>
                  </div>
                )
              }
            }
          }
        }
      }
    } else if (source === 'wp') {

      let questions = this.state.wpQuestions
      let results = this.state.selectedApplication.workPreferenceResults

      let loopLength = 0
      if (questions <= results) {
        loopLength = questions.length
      } else {
        loopLength = results.length
      }

      if (this.state.showWorkPreferences) {
        console.log('testing 2')
        for (let i = 1; i <= loopLength; i++) {
          console.log('count work preference resullts', i)

          rows.push(
            <div key={i.toString() + "customAssessment"}>
              <div className="row-5 horizontal-padding-9">
                  <label className="profile-descriptor">{questions[i - 1].name}</label>
                  <p className="profile-label">{results[i - 1]}</p>
              </div>
            </div>
          )
        }
      }
    } else if (source === 'diversity') {


      if (this.state.selectedApplication.race) {
        rows.push(
          <div key={source}>
            <div className="row-5 horizontal-padding-9">
                <label className="profile-descriptor">Race</label>
                <p className="profile-label">{this.state.selectedApplication.race}</p>
            </div>
          </div>
        )
      }
    }

    // if (this.state.customAssessment) {
    //   let questions = this.state.customAssessment.questions
    //   let results = this.state.selectedApplication.customAssessmentResults
    //   if (source === 'ca') {
    //     //initiating values
    //   } else if (source === 'wp') {
    //     questions = this.state.wpQuestions
    //     results = this.state.selectedApplication.workPreferenceResults
    //   }
    //
    //   let loopLength = 0
    //   if (questions <= results) {
    //     loopLength = questions.length
    //   } else {
    //     loopLength = results.length
    //   }
    //
    //   if (source === 'ca' && this.state.showCustomAssessment) {
    //     console.log('testing 1')
    //     for (let i = 1; i <= loopLength; i++) {
    //       console.log('count custom assessment resullts', i)
    //
    //       rows.push(
    //         <div key={i.toString() + "customAssessment"}>
    //           <div className="row-5 horizontal-padding-9">
    //               <label className="profile-descriptor">{questions[i - 1].name}</label>
    //               <p className="profile-label">{results[i - 1]}</p>
    //           </div>
    //         </div>
    //       )
    //     }
    //   } else if (source === 'wp' && this.state.showWorkPreferences) {
    //     console.log('testing 2')
    //     for (let i = 1; i <= loopLength; i++) {
    //       console.log('count work preference resullts', i)
    //
    //       rows.push(
    //         <div key={i.toString() + "customAssessment"}>
    //           <div className="row-5 horizontal-padding-9">
    //               <label className="profile-descriptor">{questions[i - 1].name}</label>
    //               <p className="profile-label">{results[i - 1]}</p>
    //           </div>
    //         </div>
    //       )
    //     }
    //   } else {
    //     console.log('there was an error ', source, this.state.showCustomAssessment, this.state.showWorkPreferences)
    //   }
    // }

    return rows
  }

  toggleView(source) {
    console.log('toggleView called', source)

    if (source === 'ca') {
      if (this.state.showCustomAssessment) {
        this.setState({ showCustomAssessment: false, caRotation: 0 })
      } else {
        this.setState({ showCustomAssessment: true, caRotation: 90 })
      }
    } else if (source === 'wp') {
      if (this.state.showWorkPreferences) {
        this.setState({ showWorkPreferences: false, wpRotation: 0 })
      } else {
        this.setState({ showWorkPreferences: true, wpRotation: 90 })
      }
    } else if (source === 'diversity') {
      if (this.state.showWorkPreferences) {
        this.setState({ showDiversityInfo: false, wpRotation: 0 })
      } else {
        this.setState({ showDiversityInfo: true, wpRotation: 90 })
      }
    }
  }

  selectApp(selectedApplication) {
    console.log('selectApp called', selectedApplication.email)

    this.setState({ isLoading: true })

    Axios.get('/api/postings/byid', { params: { _id: this.state.postingId } })
    .then((response) => {
      console.log('posting by id query attempted', response.data);

      if (response.data.success) {
        console.log('posting by id query worked')

        const selectedJob = response.data.posting
        const suppScoreObjects = this.configureScores(selectedJob.postType)

        let includeOverallScore = this.state.includeOverallScore
        if (selectedJob.postType === 'Scholarship' && this.state.orgCode === 'exp') {
          includeOverallScore = false
        }

        this.setState({ selectedJob, includeOverallScore, suppScoreObjects })

        let orgCodes = [this.state.orgCode, 'general']
        if (this.state.orgCode === 'lausd') {
          orgCodes.push('bixel')
        }

        Axios.get('/api/benchmarks', { params: { orgCodes } })
        .then((response) => {
          console.log('Benchmarks query attempted', response.data);

          if (response.data.success) {
            console.log('benchmark query worked', selectedJob.benchmarkId)

            if (response.data.benchmarks.length !== 0) {

              let benchmarkOptions = response.data.benchmarks
              benchmarkOptions.unshift({ title: 'No Benchmark Added'})

              let benchmark = { title: 'No Benchmark Added' }

              for (let i = 1; i <= response.data.benchmarks.length; i++) {
                console.log(i);

                if (response.data.benchmarks[i - 1]._id === selectedJob.benchmarkId) {

                  benchmark = response.data.benchmarks[i - 1]

                }
              }
              console.log('selected benchmark: ', benchmark)
              // this.retrieveData(selectedApplication, selectedJob, benchmark, benchmarkOptions, org)
              this.calculateInitialMatch(selectedApplication, selectedJob, benchmark, benchmarkOptions, this.state.orgCode)

            }

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });

      } else {
        console.log('posting by id query did not work', response.data.message)
      }

    }).catch((error) => {
        console.log('Posting by post query did not work for some reason', error);
    });
  }

  checkForApps() {
    console.log('checkForApps called')

    this.setState({ errorMessage: null })

    Axios.get('/api/applications/scorer', { params: { email: this.state.email, orgCode: this.state.orgCode } })
    .then((response) => {
      console.log('Applications by scorer query attempted', response.data);

      if (response.data.success) {
        console.log('applications by scorer query worked')

        const applications = response.data.applications
        if (applications && applications.length > 0) {
          this.setState({ allowAccess: true, applications })
        } else {
          this.setState({ errorMessage: 'Did not find any application assigned to this account' })
        }


      } else {
        console.log('Application by scorer query did not work', response.data.message)
        this.setState({ errorMessage: response.data.message })
      }

    }).catch((error) => {
        console.log('Application by scorer query did not work for some reason', error);
        this.setState({ errorMessage: error })
    });
  }

  renderScorerInfo() {
    console.log('renderScorerInfo called')

    return (
      <div key="scorerInfo">
        <div className="edit-profile-row">
          <div className="container-left">
            <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
          </div>
          <div className="container-right">
            <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler} />
          </div>
          <div className="clear"/>
        </div>

        <div className="edit-profile-row">
          <div className="container-left">
            <label className="profile-label">Email<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
          </div>
          <div className="container-right">
            <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
          </div>
          <div className="clear"/>
        </div>

        <div className="edit-profile-row">
          <div className="container-left">
            <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
          </div>
          {/*
          <div className="container-right">
            <label className="profile-label">Access Code<label className="error-color bold-text">*</label></label>
            <input className="text-field" type="text" placeholder="Access Code" name="accessCode" value={this.state.accessCode} onChange={this.formChangeHandler} />
          </div>*/}
          <div className="clear"/>
        </div>
      </div>
    )
  }

  render() {

    let subPostType = ''
    if (this.state.selectedJob) {
      subPostType = this.state.selectedJob.postType
      console.log('2er', subPostType, this.state.selectedJob.postType)
      if (this.state.selectedJob.subPostType) {
        console.log('3er')
        subPostType = this.state.selectedJob.subPostType
      }
    }

    return (
        <div>
          {(this.state.isExternal) ? (
            <div className="contact-top-nav">
                <TopNavigation currentPage={"OrgApplicationExternalScores"}/>
                <br/>
            </div>
          ) : (
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          )}

          {(this.state.isLoading) ? (
            <div className="full-space center-text flex-container flex-center">
              <div className="row-50">
                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                <p className="center-text cta-color bold-text" >Loading app...</p>
              </div>
            </div>
          ) : (
            <div>
              <div className="candidate-container horizontal-padding row-30">
                <div className="spacer"/><div className="spacer"/>

                {(this.state.selectedJob) ? (
                  <h2>{this.state.selectedJob.title} Scoring</h2>
                ) : (
                  <h2>Scoring</h2>
                )}

                <p>To protect the privacy of candidates, please identify yourself and add the access code to review candidate information and add your assessment of the candidate.</p>

                <div className="edit-profile-row">
                  <label className="profile-label">Email<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                </div>

                {(this.state.allowAccess) ? (
                  <div>
                    <div className="row-5">
                      <p className="description-text-2 cta-color">Applications found!</p>
                    </div>

                    <div className="row-20">
                      {(this.state.applications) && (
                        <div>
                          {this.state.applications.map((value, index) =>
                            <button type="button" className="background-button full-width" onClick={() => this.selectApp(value)}>
                              {(this.state.selectedApplication && this.state.selectedApplication._id === value._id) ? (
                                <div className="row-10 horizontal-padding-4 cta-border cta-background-color white-text">
                                  <p>{value.firstName} {value.lastName}</p>
                                </div>
                              ) : (
                                <div className="row-10 horizontal-padding-4 cta-border">
                                  <p>{value.firstName} {value.lastName}</p>
                                </div>
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                  </div>
                ) : (
                  <div className="row-20">
                    <button className="btn btn-primary" onClick={() => this.checkForApps()}>Access Application(s)</button>
                  </div>
                )}

                { (this.state.errorMessage && this.state.errorMessage!== '') && <p className="error-message">{this.state.errorMessage}</p> }
                { (this.state.serverPostSuccess) ? (
                  <div className="left-margin-30 row-20">
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  </div>
                ) : (
                  <div className="left-margin-30 row-10">
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  </div>
                )}
              </div>

              {(this.state.showApplication) && (
                <div>
                  {/*
                  { (this.state.selectedJob && subPostType === "Track") && (
                    <div className="width-70-percent vertical-margin-2-percent center-horizontally">
                      <div className="float-left right-padding white-background standard-border medium-shadow">
                        <div className="float-left right-padding-5 left-padding nowrap">
                          <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="description-text-color half-bold-text">Track</p>
                        </div>
                        <div className="float-left">
                          <select name="track" value={this.state.trackSelected} onChange={this.formChangeHandler} className="filter-select">
                            {this.state.trackOptions.map(value =>
                              <option key={value.key} value={value.name}>{value.name}</option>
                            )}
                          </select>
                        </div>
                        <div className="dropdown-arrow-container">
                          <img src={dropdownArrow} alt="Compass dropdown arrow icon"/>
                        </div>
                      </div>
                      <div className="clear"/>
                    </div>
                  )}*/}
                  <div className="candidate-container">
                      <div>
                        {(this.state.selectedApplication) ? (
                          <div>
                            {console.log('show appers: ', this.state.selectedApplication.email)}
                            <ProfileCard selectedApplication={this.state.selectedApplication} selectedJob={this.state.selectedJob} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} wpQuestions={this.state.wpQuestions} source="Interviewer" trackBenchmark={this.state.trackBenchmark} matchIndex={this.state.matchIndex} activeOrg={this.state.org} maskMatch={this.state.maskMatch} />

                            <div className="horizontal-padding row-30">
                              {(this.state.customAssessment) && (
                                <div>
                                  <div className="row-10">
                                    <label className="heading-text-3">Application Question Answers</label>
                                  </div>
                                  <div className="float-left row-10">
                                    <a className="background-button" onClick={() => this.toggleView('ca')}>
                                      <div className="float-left row-5 horizontal-padding-7">
                                        <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className={(this.state.caRotation === 90) ? "image-auto-16 rotate-90" : "image-auto-16"} />
                                      </div>
                                      <div className="float-left right-padding">
                                        <label>{this.state.customAssessment.questions.length} Answers</label>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="clear" />
                                  {this.renderQandA('ca')}
                                </div>
                              )}

                              {(this.state.scoringRubric) && (
                                <div>
                                  <hr className="clear-padding clear-margin" />
                                  <div className="spacer"/>

                                  <div className="row-10">
                                      <label className="heading-text-3">Scoring Rubric</label>
                                  </div>
                                </div>
                              )}

                              {(this.state.selectedJob.postType === "Track" || this.state.selectedJob.subPostType === 'Track') ? (
                                <div>

                                  <div className="clear"/>
                                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                                  <hr className="clear-margin clear-padding" />
                                  <div className="spacer"/>

                                  <div className="row-10">

                                    {(this.state.includeOverallScore) && (
                                      <div>
                                        <label className="heading-text-3">Your Scores</label>
                                        <div className="spacer" />

                                        <div className="row-10">
                                          <p>{(this.state.trackSelected) ? this.state.trackSelected : this.state.trackOptions[0].name}</p>
                                        </div>

                                        <div className="row-10">
                                          <div className="standard-border padding-20 vertical-margin">
                                            <div>
                                              <div className="calc-column-offset-100">
                                                <p className="profile-descriptor">Category</p>
                                                <p>Overall</p>
                                                <p className="description-text-3">Your overall score for the candidate</p>
                                              </div>
                                              <div className="fixed-column-100">
                                                <p className="profile-descriptor">Score</p>
                                                <select name="overallScore" value={this.state.overallScore} onChange={this.formChangeHandler} className="dropdown">
                                                  {this.state.scoreOptions.map(value =>
                                                    <option key={value.key} value={value}>{value}</option>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="clear"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.suppScoreObjects && this.state.suppScoreObjects.length > 0) && (
                                      <div>
                                        <div className="row-10">
                                          {(this.state.includeOverallScore) ? (
                                            <p>Supplementary Scores</p>
                                          ) : (
                                            <p className="heading-text-3">Your Scores</p>
                                          )}
                                        </div>

                                        <div className="row-10">
                                          {this.state.suppScoreObjects.map((value, index) =>
                                            <div className="standard-border padding-20 vertical-margin light-background">
                                              <div>
                                                <div className="calc-column-offset-100">
                                                  <p className="description-text-3">Category Name</p>
                                                  <p className="bottom-margin">#{index + 1}: {value.category}</p>
                                                  {value.questions.map((value2, index) =>
                                                    <div className="row-5">
                                                      <p className="description-text-3"><label className="half-bold-text">Example Question {index + 1}:</label> {value2.question}</p>
                                                      <p className="description-text-4 row-5"><label className="half-bold-text">Helper:</label> {value2.question}</p>
                                                    </div>
                                                  )}

                                                </div>
                                                <div className="fixed-column-100">
                                                  <p className="description-text-3">Score</p>
                                                  <select name={"suppScores|" + index} value={value.score} onChange={this.formChangeHandler} className="dropdown">
                                                    {this.state.scoreOptions.map(value2 =>
                                                      <option key={value2.key} value={value2}>{value2}</option>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className="clear"/>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {/*
                                    {this.renderInterviews()}

                                    <select name="interviewScore" value={this.state.interviewScore} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.scoreOptions.map(value =>
                                        <option key={value.key} value={value}>{value}</option>
                                      )}
                                    </select>*/}
                                  </div>
                                  <div className="clear"/>

                                </div>
                              ) : (
                                <div>

                                  <div className="clear"/>
                                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                                  <hr className="clear-margin clear-padding" />
                                  <div className="spacer"/>

                                  <div className="row-10">
                                    {(this.state.includeOverallScore) && (
                                      <div>
                                        <label className="heading-text-3">Your Scores</label>
                                        <div className="spacer" />

                                        <div className="row-10">
                                          <div className="standard-border padding-20 vertical-margin">
                                            <div>
                                              <div className="calc-column-offset-100">
                                                <p className="profile-descriptor">Category</p>
                                                <p>Overall</p>
                                                <p className="description-text-3">Your overall score for the candidate</p>
                                              </div>
                                              <div className="fixed-column-100">
                                                <p className="profile-descriptor">Score</p>
                                                <select name="overallScore" value={this.state.overallScore} onChange={this.formChangeHandler} className="dropdown">
                                                  {this.state.scoreOptions.map(value =>
                                                    <option key={value.key} value={value}>{value}</option>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="clear"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}


                                    {(this.state.suppScoreObjects && this.state.suppScoreObjects.length > 0) && (
                                      <div>

                                        <div className="row-10">
                                          {(this.state.includeOverallScore) ? (
                                            <p>Supplementary Scores</p>
                                          ) : (
                                            <p className="heading-text-3">Your Scores</p>
                                          )}
                                        </div>

                                        <div className="row-10">
                                          {this.state.suppScoreObjects.map((value, index) =>
                                            <div className="standard-border padding-20 vertical-margin light-background">
                                              <div>
                                                <div className="calc-column-offset-100">
                                                  <p className="description-text-3">Category Name</p>
                                                  <p className="bottom-margin">#{index + 1}: {value.category}</p>

                                                  {(value.instructions && value.instructions !== '') && (
                                                    <div className="row-5">
                                                      <p className="description-text-3 error-color"><label className="half-bold-text">Instructions:</label> {value.instructions}</p>
                                                    </div>
                                                  )}
                                                  {(value.questions && value.questions.length > 0) && (
                                                    <div>
                                                      {value.questions.map((value2, index) =>
                                                        <div className="row-5">
                                                          <p className="description-text-3"><label className="half-bold-text">Example Question {index + 1}:</label> {value2.question}</p>
                                                          <p className="description-text-4 row-5"><label className="half-bold-text">Helper:</label> {value2.question}</p>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                  {(value.rubric && value.rubric.length > 0) && (
                                                    <div className="row-5">
                                                      {value.rubric.map((value2, index) =>
                                                        <div>
                                                          <label className="description-text-3"><label className="half-bold-text">Score:</label> {value2.score}</label>
                                                          <label className="description-text-4 row-5 left-padding"><label className="half-bold-text">Rubric:</label> {value2.description}</label>
                                                          <div className="half-spacer" />
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="fixed-column-100">
                                                  <p className="description-text-3">Score</p>
                                                  <select name={"suppScore|" + index} value={value.score} onChange={this.formChangeHandler} className="dropdown">
                                                    {this.state.scoreOptions.map(value2 =>
                                                      <option key={value2.key} value={value2}>{value2}</option>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className="clear"/>
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                      </div>
                                    )}

                                  </div>
                                  <div className="clear"/>

                                </div>
                              )}

                              <hr className="clear-padding clear-margin" />
                              <div className="spacer"/>

                              <div className="row-10">
                                  <label className="heading-text-3">Feedback</label>
                              </div>

                              <div className="row-10">
                                <label className="profile-label">Internal Feedback for the Team (Confidential)</label>
                                <textarea type="text" className="notes" placeholder="Add notes on this candidate..." name="notes" value={this.state.notes} onChange={(this.formChangeHandler)}></textarea>
                              </div>

                              <div className="row-10">
                                <label className="profile-label">Overall Feedback to be Shared with Candidate</label>
                                <textarea type="text" className="feedback" placeholder="Add feedback for this candidate..." name="feedback" value={this.state.feedback} onChange={(this.formChangeHandler)}></textarea>
                              </div>

                              <div className="spacer"/>
                              <hr className="clear-margin clear-padding" />
                              <div className="spacer"/>

                              <div>
                                <div className="row-10">
                                  <p className="heading-text-3">Your Information</p>
                                </div>

                                {this.renderScorerInfo()}

                              </div>

                              <div className="spacer"/>
                              <hr className="clear-margin clear-padding" />
                              <div className="spacer"/>

                              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}
                              {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color">{this.state.successMessage}</p>}

                              <div className="row-20">
                                <button className="btn btn-primary" onClick={() => this.saveApplication()}>Save & Send</button>
                                {/*<button className="btn btn-secondary" onClick={this.createAndDownloadPdf}>Download PDF</button>*/}
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div>
                          </div>
                        )}
                      </div>
                  </div>

                  {(this.state.isExternal) && (
                    <div>
                      {Footer()}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}

        </div>

    )
  }
}

export default withRouter(OrgApplicationExternalScores)
