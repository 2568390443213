import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAssessmentDetails from './Subcomponents/AssessmentDetails';
import withRouter from './Functions/WithRouter';

class AssessmentDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true
        }

        this.getInitialData = this.getInitialData.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount assDetailsParent', this.props)

      this.getInitialData(this.props.location.state)
    }

    getInitialData(passedState) {

      let { assessments, index, assessment, resultsData } = passedState

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show assessment name ', assessments, index, assessment, resultsData, orgFocus)

      if (assessment) {

        let assessmentTitle = assessment.title
        let assessmentDescription = ''
        // let interestsData = null
        // let skillsData = null
        // let personalityData = null

        if (assessmentTitle === 'Work Preferences') {
          assessmentDescription = 'Self-assesses your fit to current and similar work'
        } else if (assessmentTitle === 'Interest Assessment') {
          assessmentDescription = 'Assesses what category of work you may be most interested in'
        } else if (assessmentTitle === 'Skill Assessment') {
          assessmentDescription = 'Self-assessment of hard and soft skills associated with different career paths'
        } else if (assessmentTitle === 'Personality Assessment') {
          assessmentDescription = 'Assesses your personality type along axis relevant to different career paths'
        } else if (assessmentTitle === 'Values Assessment') {
          assessmentDescription = 'Assesses your values and the values of your ideal employer'
        }

        let nextIndex = index + 1
        if (nextIndex > assessments.length - 1) {
          nextIndex = 0
        }

        if (resultsData && resultsData[index] && resultsData[index].length !== undefined && resultsData[index].length === 0) {
          resultsData[index] = null
        }

        this.setState({ emailId: email, username, cuFirstName, cuLastName,
          activeOrg, orgFocus, orgLogo, assessmentTitle, assessmentDescription,
        resultsData, assessments, index, nextIndex, assessment })

      } else {
        console.log('user navigated directly to this screen')
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {
      console.log('show history 1: ', this.props.navigate)

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
            <SubAssessmentDetails history={this.props.navigate} assessments={this.state.assessments} index={this.state.index} assessment={this.state.assessment} resultsData={this.state.resultsData} commandClicked={this.commandClicked} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo, this.state.passedCommand)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(AssessmentDetails)
