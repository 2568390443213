import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCareerDetails from './Subcomponents/CareerDetails';
import withRouter from './Functions/WithRouter';

class CareerDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { careerSelected } = this.props.params
      console.log('careerSelected:', careerSelected);

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const orgLogo = localStorage.getItem('orgLogo');
      const activeOrg = localStorage.getItem('activeOrg');

      this.setState({ emailId: email, username, orgLogo, activeOrg, careerSelected });

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.location && this.props.location.state && (!prevProps.location || !prevProps.location.state || this.props.location.state.careerSelected !== prevProps.location.state.careerSelected)) {
        console.log('new career selected in parent')
        this.setState({ careerSelected: this.props.location.state.careerSelected.name })
      } else if (this.props.params && this.props.params.careerSelected !== prevProps.params.careerSelected) {
        console.log('diff params: ', this.props.params)
        this.setState({ careerSelected: this.props.params.careerSelected })
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} fromCareerDetailsSelf={true} history={this.props.navigate}/>
              <div>
                <SubCareerDetails careerSelected={this.state.careerSelected} history={this.props.navigate} commandClicked={this.commandClicked}/>
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(CareerDetails);
/*
1) Include two APIs. One where the searchTerm is a directLink, and search is skipped. Query should be on Id. The other should be a search based on the URL parameter.
2) Include the abilty to click on tags and sub-tags to search for skillsets, and browse other jobs.
3) Increase accuracy of dashboard by pulling more data and creating algorithms
*/
