import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";

class EditScheduledMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          resLimit: 250,

          messageTypeOptions: ['','Text','Email'],
          hourOptions: ['','01','02','03','04','05','06','07','08','09','10','11','12'],
          minuteOptions: ['','00','30'],
          meridiemOptions: ['','AM','PM'],
          recipientTypeOptions: ['',"Individuals","Event RSVPs"],
          postingOptions: [],
          roleNameOptions: ['','Career-Seeker','Mentor','Admin','Educator','Employer'],
          staffMemberOptions: [],
          senderOptions: [],
          enrollmentStatusOptions: [],
          repeatsOptions: ['','Never','Every Week','Every 2 Weeks'],
          endRepeatsOptions: ['','Never','On Date'],
          cohortOptions: [],

          selectedPosting: { title: '' },
          selectedMembers: [],
          selectedMemberDetails: [],
          selectedRoleNames: [],
          selectedStaffMembers: [],
          selectedStaffMemberDetails: [],
          selectedEnrollmentStatuses: []

        }

        this.retrieveData = this.retrieveData.bind(this);
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.pullRecipients = this.pullRecipients.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.filterUnsubscribedMembers = this.filterUnsubscribedMembers.bind(this)

    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in EditScheduledMessage', this.props)

      const org = this.props.org

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      // let activeOrg = localStorage.getItem('activeOrg');
      // let orgFocus = localStorage.getItem('orgFocus');
      // let pathway = localStorage.getItem('pathway');

      let _id = null
      let recipientType = this.state.recipientType
      let recipients = this.state.recipients
      let selectedMembers = this.state.selectedMembers
      let selectedMemberDetails = this.state.selectedMemberDetails
      let messageType = this.state.messageType
      let messageDate = this.state.messageDate
      let messageHour = this.state.messageHour
      let messageMinutes = this.state.messageMinutes
      let messageMeridiem = this.state.messageMeridiem
      let repeats = this.state.repeats
      let endRepeats = this.state.endRepeats
      let endDate = this.state.endDate
      let messageDates = null

      let messageTitle = this.state.messageTitle
      let messageText = this.state.messageText
      let postType = this.state.postType
      let postingId = this.state.postingId
      let sender = this.state.sender
      let selectedRoleNames = this.state.selectedRoleNames
      let selectedStaffMemberDetails = this.state.selectedStaffMemberDetails
      let selectedEnrollmentStatuses = this.state.selectedEnrollmentStatuses
      let beforeDate = this.state.beforeDate
      let afterDate = this.state.afterDate
      let cohort = this.state.cohort

      if (this.props.selectedScheduledMessage) {
        const ssm = this.props.selectedScheduledMessage
        _id = ssm._id
        recipientType = ssm.recipientType
        recipients = ssm.recipients
        selectedMemberDetails  = recipients
        if (selectedMemberDetails && selectedMemberDetails.length > 0) {
          for (let i = 1; i <= selectedMemberDetails.length; i++) {
            selectedMembers.push(selectedMemberDetails[i - 1].firstName + ' ' + selectedMemberDetails[i - 1].lastName)
          }
        }

        messageType = ssm.messageType
        if (ssm.messageDate) {
          console.log('what type is this: ', ssm.messageDate, typeof ssm.messageDate, new Date(ssm.messageDate))
          // march 31, 2022 2:30pm

          messageDate = convertDateToString(new Date(ssm.messageDate),"rawDateForInput")
          console.log('show new date: ', messageDate)

          if (new Date(ssm.messageDate).getHours() === 12) {
            messageMeridiem = 'PM'
            messageHour = '12'
          } else if (new Date(ssm.messageDate).getHours() > 12) {
            messageMeridiem = 'PM'
            if ((new Date(ssm.messageDate).getHours() - 12) < 10) {
              messageHour = '0' + (new Date(ssm.messageDate).getHours() - 12)
            } else {
              messageHour = new Date(ssm.messageDate).getHours() - 12
            }
            // 22, 23, 24
          } else {
            messageMeridiem = 'AM'
            if (new Date(ssm.messageDate).getHours() < 10) {
              messageHour = '0' + (new Date(ssm.messageDate).getHours())
            } else {
              messageHour = new Date(ssm.messageDate).getHours()
            }
          }

          if (new Date(ssm.messageDate).getMinutes() === 0) {
            messageMinutes = '00'
          } else if (new Date(ssm.messageDate).getMinutes() === 30) {
            messageMinutes = '30'
          }

          console.log('show hours: ', messageHour, new Date(ssm.messageDate).getHours())

        }

        repeats = ssm.repeats
        endRepeats = ssm.endRepeats
        if (ssm.endDate) {
          endDate = convertDateToString(new Date(ssm.endDate), "rawDateForInput")
        }
        messageDates = ssm.messageDates

        messageTitle = ssm.messageTitle
        messageText = ssm.messageText

        if (ssm.sender) {
          sender = ssm.sender.firstName + ' ' + ssm.sender.lastName
        }

        if (ssm.selectedRoleNames) {
          selectedRoleNames = ssm.selectedRoleNames
        }

        if (ssm.selectedEnrollmentStatuses) {
          selectedEnrollmentStatuses = ssm.selectedEnrollmentStatuses
        }

        if (ssm.selectedStaffMemberDetails) {
          selectedStaffMemberDetails = ssm.selectedStaffMemberDetails
        }
        if (ssm.beforeDate) {
          beforeDate = convertDateToString(new Date(ssm.beforeDate), "rawDateForInput")
        }
        // beforeDate = ssm.beforeDate

        if (ssm.afterDate) {
          afterDate = convertDateToString(new Date(ssm.afterDate), "rawDateForInput")
        }
        // afterDate = ssm.afterDate
        // console.log('whats beforedate: ', beforeDate)
        if (ssm.cohort) {
          cohort = ssm.cohort
        }

        postType = ssm.postType
        postingId = ssm.postingId
        if (ssm.postingId) {
          Axios.get('/api/postings/byid', { params: { _id: ssm.postingId } })
          .then((response) => {
            console.log('Posting query by id', response.data);

            if (response.data.success) {
              console.log('posting query worked')

              const selectedPosting = response.data.posting
              this.setState({ selectedPosting });

            } else {
              console.log('posting query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Posting query did not work for some reason', error);
          });
        }
      }

      let recipientTypeOptions = ['',"Individuals","Event RSVPs"]
      if (this.state.showModule) {
        recipientTypeOptions = ['',"Individuals","Groups","Event RSVPs"]
      }

      this.setState({ email, emailId: email, username, cuFirstName, cuLastName, org,
        _id, recipientType, recipients, selectedMembers, selectedMemberDetails,
        messageType, messageDate, messageHour, messageMinutes, messageMeridiem,
        messageDates, repeats, endRepeats, endDate, messageTitle, messageText,
        sender, selectedRoleNames, selectedStaffMemberDetails, selectedEnrollmentStatuses, beforeDate, afterDate, cohort,
        postType, postingId, recipientTypeOptions
      })

      if (org) {
        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted one', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgContactEmail = response.data.orgInfo.contactEmail
            let cohortOptions = null
            if (response.data.orgInfo.cohorts && response.data.orgInfo.cohorts.length > 0) {
              cohortOptions = [''].concat(response.data.orgInfo.cohorts)
            }

            this.setState({ orgName, orgContactEmail, cohortOptions });

            const roleNames = ['Admin']
            Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
            .then((response) => {

              if (response.data.success) {
                console.log('Member query worked', response.data);

                if (response.data.members && response.data.members.length > 0) {
                  let staffMemberOptions = [{}]
                  let senderOptions = [orgName]
                  for (let i = 1; i <= response.data.members.length; i++) {
                    if (response.data.members[i - 1] && !staffMemberOptions.some(o => o.email === response.data.members[i - 1].email)) {

                      staffMemberOptions.push(response.data.members[i - 1])
                      senderOptions.push(response.data.members[i - 1].firstName + ' ' + response.data.members[i - 1].lastName)
                    }
                  }
                  // let staffMemberOptions = response.data.members
                  // if (staffMemberOptions) {
                  //   staffMemberOptions.unshift({})
                  //   this.setState({ staffMemberOptions })
                  // }

                  this.setState({ staffMemberOptions, senderOptions })
                }

              } else {
                console.log('no members found', response.data.message)

              }

            }).catch((error) => {
                console.log('Members query did not work', error);
            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/postings/user', { params: { orgCode: org, postType: 'Event' } })
        .then((response) => {
          console.log('Posting query attempted one', response.data);

          if (response.data.success && response.data.postings) {
            console.log('posting query worked')

            const postingOptions = [{ title: '' }].concat(response.data.postings)
            this.setState({ postingOptions });

          } else {
            console.log('posting query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posting query did not work for some reason', error);
        });
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const pathwayOptions = response.data.workOptions[0].functionOptions
          const stageOptions = [''].concat(response.data.workOptions[0].careerSeekerStageOptions)
          const enrollmentStatusOptions = [''].concat(response.data.workOptions[0].enrollmentStatusOptions)
          // exploringCareerPaths, definingGoals, activelyLookingForWork, lookingToChangeEmployers, happilyEmployed

          this.setState({ pathwayOptions, stageOptions, enrollmentStatusOptions })
        }
      }).catch((error) => {
          console.log('O*NET did not work', error);
      });
    }

    formChangeHandler(event) {

      if (event.target.name === 'selectedPosting') {
        let selectedPosting = this.state.selectedPosting
        for (let i = 1; i <= this.state.postingOptions.length; i++) {
          if (this.state.postingOptions[i - 1].title === event.target.value) {
            selectedPosting = this.state.postingOptions[i - 1]
          }
        }
        this.setState({ selectedPosting })
      } else if (event.target.name === 'searchMembers') {
        this.searchItems(event.target.value, 'member')
      } else if (event.target.name === 'beforeDate' || event.target.name === 'afterDate') {
        this.setState({ [event.target.name]: event.target.value })
        this.pullRecipients(event.target.name,event.target.value)
      } else if (event.target.name === 'selectedStaffMember') {

        let searchObject = this.state.searchObject
        for (let i = 1; i <= this.state.staffMemberOptions.length; i++) {
          const fullName = this.state.staffMemberOptions[i - 1].firstName + ' ' + this.state.staffMemberOptions[i - 1].lastName
          if (event.target.value === fullName) {
            searchObject = this.state.staffMemberOptions[i - 1]
          }
        }

        this.setState({ [event.target.name]: event.target.value, searchObject })
      } else if (event.target.name === 'cohort') {
        this.setState({ [event.target.name]: event.target.value })
        this.pullRecipients(event.target.name,event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'member') {
        if (!searchString || searchString === '') {
          this.setState({ searchStringMembers: searchString, searchIsAnimatingMembers: false, memberOptions: null })
        } else {
          this.setState({ searchStringMembers: searchString, searchIsAnimatingMembers: true })

          let roleNames = null
          if (type === 'advisor') {
            roleNames = ['Teacher','Counselor','Support Staff','Mentor','Admin']
          }

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called', searchString)

            const orgCode = self.state.org

            //school district orgs
            Axios.get('/api/members/search', { params: { roleNames, searchString, orgCode } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Member query worked', response.data);

                  self.setState({ memberOptions: response.data.members, searchIsAnimatingMembers: false })

                } else {
                  console.log('no members found', response.data.message)
                }

            }).catch((error) => {
                console.log('Members query did not work', error);
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();

        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type.includes('career')) {

        let searchObject = null
        let searchString = null
        let careerOptions = null
        let searchObjectA = null
        let searchStringA = null
        let careerOptionsA = null
        let searchObjectB = null
        let searchStringB = null
        let careerOptionsB = null
        if (type === 'career') {
          searchObject = passedItem
          searchString = passedItem.name
          careerOptions = null
        } else if (type === 'careerA') {
          searchObjectA = passedItem
          searchStringA = passedItem.name
          careerOptionsA = null
        } else if (type === 'careerB') {
          searchObjectB = passedItem
          searchStringB = passedItem.name
          careerOptionsB = null
        }

        this.setState({ searchObject, searchString, careerOptions, searchObjectA, searchStringA, careerOptionsA,
          searchObjectB, searchStringB, careerOptionsB
        })

      } else if (type.includes('opportunity')) {
        let searchObject = null
        let searchStringOpportunities = null
        let opportunityOptions = null
        let searchObjectA = null
        let searchStringOpportunitiesA = null
        let opportunityOptionsA = null
        let searchObjectB = null
        let searchStringOpportunitiesB = null
        let opportunityOptionsB = null
        if (type === 'opportunity') {
          searchObject = passedItem
          searchStringOpportunities = passedItem.name
          if (passedItem.title) {
            searchStringOpportunities = passedItem.title
          }
          opportunityOptions = null
        } else if (type === 'opportunityA') {
          searchObjectA = passedItem
          searchStringOpportunitiesA = passedItem.name
          if (passedItem.title) {
            searchStringOpportunitiesA = passedItem.title
          }
          opportunityOptionsA = null
        } else if (type === 'opportunityB') {
          searchObjectB = passedItem
          searchStringOpportunitiesB = passedItem.name
          if (passedItem.title) {
            searchStringOpportunitiesB = passedItem.title
          }
          opportunityOptionsB = null
        }

        this.setState({ searchObject, searchStringOpportunities, opportunityOptions,
          searchObjectA, searchStringOpportunitiesA, opportunityOptionsA,
          searchObjectB, searchStringOpportunitiesB, opportunityOptionsB,
        })
      } else if (type.includes('competency')) {
        let searchObject = null
        let searchStringCompetencies = null
        let competencyOptions = null
        let searchObjectA = null
        let searchStringCompetenciesA = null
        let competencyOptionsA = null
        let searchObjectB = null
        let searchStringCompetenciesB = null
        let competencyOptionsB = null
        if (type === 'competency') {
          searchObject = passedItem
          searchStringCompetencies = passedItem.name
          competencyOptions = null
        } else if (type === 'competencyA') {
          searchObjectA = passedItem
          searchStringCompetenciesA = passedItem.name
          competencyOptionsA = null
        } else if (type === 'competencyB') {
          searchObjectB = passedItem
          searchStringCompetenciesB = passedItem.name
          competencyOptionsB = null
        }

        this.setState({
          searchObject, searchStringCompetencies, competencyOptions,
          searchObjectA, searchStringCompetenciesA, competencyOptionsA,
          searchObjectB, searchStringCompetenciesB, competencyOptionsB,
        })
      } else if (type === 'member') {
        const searchObject = passedItem
        let searchStringMembers = passedItem.firstName + ' ' + passedItem.lastName

        const memberOptions = null

        this.setState({ searchObject, searchStringMembers, memberOptions })

      }
    }

    pullRecipients(name, value) {
      console.log('pullRecipients called', name, value)

      let roleNames = this.state.selectedRoleNames
      let assignedStaffMembers = this.state.selectedStaffMemberDetails
      let enrollmentStatuses = this.state.selectedEnrollmentStatuses
      let cohort = this.state.cohort
      let beforeDate = this.state.beforeDate
      let afterDate = this.state.afterDate

      if (name === 'roleName') {
        roleNames = value
      } else if (name === 'staffMember') {
        assignedStaffMembers = value
      } else if (name === 'enrollmentStatus') {
        enrollmentStatuses = value
      } else if (name === 'cohort') {
        cohort = value
      } else if (name === 'beforeDate') {
        beforeDate = value
      } else if (name === 'afterDate') {
        afterDate = value
      }

      // if (roleNames && roleNames.includes('Career-Seeker')) {
      //   roleNames.push('Student')
      // }

      if (assignedStaffMembers && assignedStaffMembers.length > 0) {
        for (let i = 1; i <= assignedStaffMembers.length; i++) {
          if (assignedStaffMembers[i - 1] && assignedStaffMembers[i - 1].email) {
            assignedStaffMembers[i - 1] = assignedStaffMembers[i - 1].email
          } else {
            console.log('what happened with staff? ', assignedStaffMembers[i - 1])
          }
        }
      } else {
        assignedStaffMembers = null
      }

      let orgCode = this.state.org
      let limit = this.state.resLimit

      const queryObject = { roleNames, assignedStaffMembers, enrollmentStatuses, cohort,
        beforeDate, afterDate, orgCode, limit
      }

      Axios.get('/api/org/members', { params: queryObject })
      .then((response) => {
        console.log('Pulling org members', response.data);

        if (response.data.success) {
          console.log('member query worked')

          let selectedMemberDetails = response.data.members

          const adjustedMembers = this.filterUnsubscribedMembers(selectedMemberDetails)
          selectedMemberDetails = adjustedMembers.selectedMemberDetails
          const unsubscribedMembers = adjustedMembers.unsubscribedMembers

          this.setState({ selectedMemberDetails, unsubscribedMembers });

        } else {
          console.log('member query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Member query did not work for some reason', error);
      });

    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.competencies)

      if (type.includes('career')) {
        if (type === 'career') {
          const selectedCareers = this.state.selectedCareers
          if (selectedCareers && selectedCareers.length > 0) {

            return (
              <div key={"selectedCareers"}>
                <div className="spacer" />
                {selectedCareers.map((value, optionIndex) =>
                  <div key={"career|" + optionIndex} className="float-left">

                    <div className="close-button-container-1" >
                      <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                        <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                      </button>
                    </div>

                    <div className="float-left right-padding-5">
                      <div className="half-spacer" />
                      <div className="tag-container-inactive">
                        <p className="description-text-2">{value}</p>
                      </div>
                      <div className="half-spacer" />
                    </div>

                  </div>
                )}
              </div>
            )
          }
        } else {
          let item = null
          if (type === 'careerA') {
            item = this.state.aItem
          } else if (type === 'careerB') {
            item = this.state.bItem
          }

          if (item) {
            return (
              <div key={"selectedCareer"}>
                <div className="spacer" />
                <div key={"career"} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(null,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{(item.name) ? item.name : item.title}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
                <div className="clear" />
              </div>
            )
          }
        }

      } else if (type.includes('opportunity')) {
        if (type === 'opportunity') {
          const selectedOpportunities = this.state.selectedOpportunities
          if (selectedOpportunities && selectedOpportunities.length > 0) {
            // console.log('about to in', selectedOpportunities)
            return (
              <div key={"selectedOpportunities"}>
                <div className="spacer" />
                {selectedOpportunities.map((value, optionIndex) =>
                  <div key={"career|" + optionIndex} className="float-left">

                    <div className="close-button-container-1" >
                      <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                        <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="float-left right-padding-5">
                      <div className="half-spacer" />
                      <div className="tag-container-inactive">
                        <p className="description-text-2">{value}</p>
                      </div>
                      <div className="half-spacer" />
                    </div>

                  </div>
                )}
              </div>
            )
          }
        } else {
          let item = null
          if (type === 'opportunityA') {
            item = this.state.aItem
          } else if (type === 'opportunityB') {
            item = this.state.bItem
          }

          if (item) {
            return (
              <div key={"selectedOpportunity"}>
                <div className="spacer" />
                <div key={"career"} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(null,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{(item.name) ? item.name : item.title}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
                <div className="clear" />
              </div>
            )
          }
        }

      } else if (type === 'learningObjective') {
        // console.log('in learningObjective', this.state.learningObjectives)
        // abilities, knowledge, , soft skill, hard skil, tech skill
        const competencies = this.state.competencies

        if (competencies && competencies.length > 0) {
          console.log('compare 0')
          return (
            <div key={"competency"}>
              <div className="spacer" />
              {competencies.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">
                  {(value) && (
                    <div>
                      <div className="close-button-container-1" >
                        <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                          <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                        </button>
                      </div>
                      <div>
                        <div className="half-spacer" />
                        {(value.category === 'Ability') && (
                          <div className="tag-container-8 primary-background primary-border white-text">
                            <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                          </div>
                        )}
                        {(value.category === 'Knowledge') && (
                          <div className="tag-container-8 secondary-background secondary-border white-text">
                            <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                          </div>
                        )}
                        {(value.category === 'Soft Skill') && (
                          <div className="tag-container-8 tertiary-background tertiary-border white-text">
                            <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                          </div>
                        )}
                        {(value.category === 'General Hard Skill') && (
                          <div className="tag-container-8 quaternary-background quaternary-border white-text">
                            <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                          </div>
                        )}
                        {(value.category === 'Tech Skill') && (
                          <div className="tag-container-8 quinary-background quinary-border white-text">
                            <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                          </div>
                        )}

                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}

                </div>
              )}
            </div>
          )

        }
      } else if (type.includes('skill')) {
        if (type === 'skill') {
          const selectedSkills = this.state.selectedSkills
          if (selectedSkills && selectedSkills.length > 0) {

            return (
              <div key={"selectedSkills"}>
                <div className="spacer" />
                {selectedSkills.map((value, optionIndex) =>
                  <div key={"career|" + optionIndex} className="float-left">

                    <div className="close-button-container-1" >
                      <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                        <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="float-left right-padding-5">
                      <div className="half-spacer" />
                      <div className="tag-container-inactive">
                        <p className="description-text-2">{value}</p>
                      </div>
                      <div className="half-spacer" />
                    </div>

                  </div>
                )}
              </div>
            )
          }
        } else {
          let item = null
          if (type === 'skillA') {
            item = this.state.aItem
          } else if (type === 'skillB') {
            item = this.state.bItem
          }
          // console.log('show item: ', item, type)
          if (item) {
            return (
              <div key={"selectedSkill"}>
                <div className="spacer" />
                <div key={"career"} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(null,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{(item.name) ? item.name : item.title}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
                <div className="clear" />
              </div>
            )
          }
        }

      } else if (type === 'member') {
        const selectedMembers = this.state.selectedMembers
        if (selectedMembers && selectedMembers.length > 0) {
          console.log('show selectedMembers: ', selectedMembers)
          return (
            <div key={"selectedMembers"}>
              <div className="spacer" />
              {selectedMembers.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else {

        let tags = []
        if (type === 'function') {
          tags = this.state.selectedFunctions
        } else if (type === 'industry') {
          tags = this.state.selectedIndustries
        } else if (type === 'hoursPerWeek') {
          tags = this.state.selectedHours
        } else if (type === 'payRange') {
          tags = this.state.selectedPayRanges
        } else if (type === 'optimize') {
          tags = this.state.selectedOptimizeOptions
        } else if (type === 'school') {
          tags = this.state.selectedSchools
        } else if (type === 'major') {
          tags = this.state.selectedMajors
        } else if (type === 'roleName') {
          tags = this.state.selectedRoleNames
        } else if (type === 'staffMember') {
          tags = this.state.selectedStaffMembers
        } else if (type === 'enrollmentStatus') {
          tags = this.state.selectedEnrollmentStatuses
        }

        if (tags && tags.length > 0) {
          console.log('about to in', tags)
          return (
            <div key={type}>
              <div className="spacer" />
              {tags.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type.includes('career')) {
        if (type === 'career') {
          let selectedCareers = this.state.selectedCareers
          selectedCareers.splice(index, 1)

          let selectedCareerDetails = this.state.selectedCareerDetails
          const careerToRemove = selectedCareerDetails[index]
          selectedCareerDetails.splice(index, 1)
          this.setState({ selectedCareers, selectedCareerDetails })
          this.adjustCoreCompetencies(careerToRemove,type, null, false)
        } else if (type === 'careerA') {
          this.setState({ aItem: null })
        } else if (type === 'careerB') {
          this.setState({ bItem: null })
        }
      } else if (type.includes('opportunity')) {
        if (type === 'opportunity') {
          let selectedOpportunities = this.state.selectedOpportunities
          selectedOpportunities.splice(index, 1)

          let selectedOpportunityDetails = this.state.selectedOpportunityDetails
          const opportunityToRemove = selectedOpportunityDetails[index]
          selectedOpportunityDetails.splice(index, 1)
          this.setState({ selectedOpportunities, selectedOpportunityDetails })
          this.adjustCoreCompetencies(opportunityToRemove,type, null, false)
        } else if (type === 'opportunityA') {
          this.setState({ aItem: null })
        } else if (type === 'opportunityB') {
          this.setState({ bItem: null })
        }
      } else if (type === 'function') {
        let selectedFunctions = this.state.selectedFunctions
        selectedFunctions.splice(index, 1)
        this.setState({ selectedFunctions })
      } else if (type === 'industry') {
        let selectedIndustries = this.state.selectedIndustries
        selectedIndustries.splice(index, 1)
        this.setState({ selectedIndustries })
      } else if (type === 'hoursPerWeek') {
        let selectedHours = this.state.selectedHours
        selectedHours.splice(index, 1)
        this.setState({ selectedHours })
      } else if (type === 'payRange') {
        let selectedPayRanges = this.state.selectedPayRanges
        selectedPayRanges.splice(index, 1)
        this.setState({ selectedPayRanges })
      } else if (type === 'optimize') {
        let selectedOptimizeOptions = this.state.selectedOptimizeOptions
        selectedOptimizeOptions.splice(index, 1)
        this.setState({ selectedOptimizeOptions })

      } else if (type === 'learningObjective') {
        let competencies = this.state.competencies
        competencies.splice(index, 1)
        this.setState({ competencies })
      } else if (type === 'member') {
        let selectedMembers = this.state.selectedMembers
        selectedMembers.splice(index, 1)

        let selectedMemberDetails = this.state.selectedMemberDetails
        // const memberToRemove = selectedMemberDetails[index]
        selectedMemberDetails.splice(index, 1)
        this.setState({ selectedMembers, selectedMemberDetails })
      } else if (type.includes('skill')) {
        if (type === 'skill') {
          let selectedSkills = this.state.selectedSkills
          selectedSkills.splice(index, 1)
          this.setState({ selectedSkills })
        } else if (type === 'skillA') {
          this.setState({ aItem: null })
        } else if (type === 'skillB') {
          this.setState({ bItem: null })
        }
      } else if (type === 'school') {
        let selectedSchools = this.state.selectedSchools
        selectedSchools.splice(index, 1)
        this.setState({ selectedSchools })
      } else if (type === 'major') {
        let selectedMajors = this.state.selectedMajors
        selectedMajors.splice(index, 1)
        this.setState({ selectedMajors })
      } else if (type === 'roleName') {
        let selectedRoleNames = this.state.selectedRoleNames
        selectedRoleNames.splice(index, 1)
        this.setState({ selectedRoleNames })
        this.pullRecipients('roleName',selectedRoleNames)
      } else if (type === 'staffMember') {
        let selectedStaffMembers = this.state.selectedStaffMembers
        selectedStaffMembers.splice(index, 1)

        let selectedStaffMemberDetails = this.state.selectedStaffMemberDetails
        selectedStaffMemberDetails.splice(index, 1)
        this.setState({ selectedStaffMembers, selectedStaffMemberDetails })
        this.pullRecipients('staffMember',selectedStaffMemberDetails)
      } else if (type === 'enrollmentStatus') {
        let selectedEnrollmentStatuses = this.state.selectedEnrollmentStatuses
        selectedEnrollmentStatuses.splice(index, 1)
        this.setState({ selectedEnrollmentStatuses })
        this.pullRecipients('enrollmentStatus',selectedEnrollmentStatuses)
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'member' || type === 'advisor') {
        if (this.state.selectedMembers.includes(this.state.searchStringMembers)) {
          this.setState({ errorMessage: 'You have already added this member' })
        } else if (!this.state.searchObject) {
          this.setState({ errorMessage: 'Please select a member from search suggestions' })
        } else {

          const searchStringMembers = ''
          const searchObject = null

          let selectedMembers = this.state.selectedMembers
          selectedMembers.unshift(this.state.searchStringMembers)

          let selectedMemberDetails = this.state.selectedMemberDetails
          selectedMemberDetails.unshift(this.state.searchObject)

          if (type === 'advisor') {
            selectedMembers = [this.state.searchStringMembers]
            selectedMemberDetails = [this.state.searchObject]
          }

          this.setState({ searchStringMembers, searchObject, selectedMembers, selectedMemberDetails, errorMessage: null, memberOptions: [] })

        }
      } else if (type === 'roleName') {

        if (this.state.selectedRoleNames.includes(this.state.selectedRoleName)) {
          this.setState({ errorMessage: 'You have already added this role name' })
        } else {

          const selectedRoleName = ''

          let selectedRoleNames = this.state.selectedRoleNames
          selectedRoleNames.unshift(this.state.selectedRoleName)

          this.setState({ selectedRoleName, selectedRoleNames, errorMessage: null })
          this.pullRecipients(type,selectedRoleNames)

        }
      } else if (type === 'staffMember') {

        if (this.state.selectedStaffMembers.includes(this.state.selectedStaffMember)) {
          this.setState({ errorMessage: 'You have already added this staff member' })
        } else {

          const selectedStaffMember = ''

          let selectedStaffMembers = this.state.selectedStaffMembers
          selectedStaffMembers.unshift(this.state.selectedStaffMember)

          let selectedStaffMemberDetails = this.state.selectedStaffMemberDetails
          selectedStaffMemberDetails.unshift(this.state.searchObject)

          this.setState({ selectedStaffMember, selectedStaffMembers, selectedStaffMemberDetails, errorMessage: null })
          this.pullRecipients(type,selectedStaffMemberDetails)

        }
      } else if (type === 'enrollmentStatus') {

        if (this.state.selectedEnrollmentStatuses.includes(this.state.enrollmentStatus)) {
          this.setState({ errorMessage: 'You have already added this enrollment status' })
        } else {

          const enrollmentStatus = ''

          let selectedEnrollmentStatuses = this.state.selectedEnrollmentStatuses
          selectedEnrollmentStatuses.unshift(this.state.enrollmentStatus)

          this.setState({ enrollmentStatus, selectedEnrollmentStatuses, errorMessage: null })
          this.pullRecipients(type,selectedEnrollmentStatuses)

        }
      }
    }

    handleSubmit(event) {
      console.log('handleSubmit called', this.state.recipientType, this.state.selectedPosting)

      this.setState({ successMessage: null, errorMessage: null, isSaving: true })

      if (!this.state.recipientType || this.state.recipientType === '') {
        this.setState({ errorMessage: "please add a recipient type", isSaving: false })
      } else if (this.state.recipientType === 'Individuals' && this.state.selectedMemberDetails.length === 0) {
        this.setState({ errorMessage: "please add at least one recipient", isSaving: false })
      } else if (this.state.recipientType === 'Event RSVPs' && this.state.selectedPosting.title === '') {
        this.setState({ errorMessage: "please add a posting", isSaving: false })
      } else if (!this.state.messageType || this.state.messageType === '') {
        this.setState({ errorMessage: "please add a message type", isSaving: false })
      } else if (!this.state.messageDate || this.state.messageDate === '') {
        this.setState({ errorMessage: "please add a message date", isSaving: false })
      } else if (!this.state.messageHour || this.state.messageHour === '') {
        this.setState({ errorMessage: "please add a message hour", isSaving: false })
      } else if (!this.state.messageMinutes || this.state.messageMinutes === '') {
        this.setState({ errorMessage: "please add message minutes", isSaving: false })
      } else if (!this.state.messageMeridiem || this.state.messageMeridiem === '') {
        this.setState({ errorMessage: "please add a message meridium", isSaving: false })
      } else if (!this.state.messageText || this.state.messageText === '') {
        this.setState({ errorMessage: "please add a message", isSaving: false })
      } else {

        // console.log('do it')
        const _id = this.state._id
        const emailId = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const orgCode = this.state.org
        const orgName = this.state.orgName
        const contactEmail = this.state.orgContactEmail
        let postingId = this.state.postingId
        let postType = this.state.postType
        let postingTitle = this.state.postingTitle
        if (this.state.selectedPosting && this.state.selectedPosting.title !== '') {
          postingId = this.state.selectedPosting._id
          postType = this.state.selectedPosting.postType
          postingTitle = this.state.selectedPosting.title
          if (!this.state.selectedPosting.title && this.state.selectedposting.name) {
            postingTitle = this.state.selectedPosting.name
          }
        }

        const recipientType = this.state.recipientType
        const recipients = this.state.selectedMemberDetails

        const messageType = this.state.messageType
        const messageTitle = this.state.messageTitle
        const messageText = this.state.messageText
        console.log('messageDate type: ', typeof this.state.messageDate)
        const year = Number(this.state.messageDate.substring(0,4))
        const month = Number(this.state.messageDate.substring(5,7)) - 1
        const day = Number(this.state.messageDate.substring(8,10))

        // adjust by time zone
        let hour = Number(this.state.messageHour)
        if (this.state.messageMeridiem === 'PM' && hour !== 12) {
          hour = hour + 12
        }
        const minutes = Number(this.state.messageMinutes)
        const messageDate = new Date(year, month, day, hour, minutes, 0);

        const repeats = this.state.repeats
        const endRepeats = this.state.endRepeats
        const endDate = this.state.endDate
        let messageDates = null
        if (repeats && repeats.startsWith('Every')) {
          const rYear = messageDate.getFullYear()
          const rMonth = messageDate.getMonth()
          const rDay = messageDate.getDate()
          const rHours = messageDate.getHours()
          const rMinutes = messageDate.getMinutes()

          let maxLoops = 20
          messageDates = [messageDate]
          for (let i = 1; i <= maxLoops; i++) {
            console.log('compare dates: ', new Date(), new Date(endDate))

            if (repeats === 'Every Week') {
              const tempDate = new Date(rYear, rMonth, rDay + (i * 7), rHours, rMinutes, 0, 0)
              if ((!endDate) || (endDate && tempDate < new Date(endDate))) {
                messageDates.push(tempDate)
              }
            } else if (repeats === 'Every 2 Weeks') {
              const tempDate = new Date(rYear, rMonth, rDay + (i * 14), rHours, rMinutes, 0, 0)
              if ((!endDate) || (endDate && tempDate < new Date(endDate))) {
                messageDates.push(tempDate)
              }
            }
          }
          // const gteDate = new Date(year, month, day + 9, hours, minutes, 0, 0)
          // const lteDate = new Date(year, month, day + 18, hours, minutes, 0, 0)
        }

        let sender = null
        if (this.state.sender) {
          for (let i = 1; i <= this.state.staffMemberOptions.length; i++) {
            const fullName = this.state.staffMemberOptions[i - 1].firstName + ' ' + this.state.staffMemberOptions[i - 1].lastName
            if (this.state.sender === fullName) {
              sender = this.state.staffMemberOptions[i - 1]
            }
          }
        }

        const selectedRoleNames = this.state.selectedRoleNames
        const selectedStaffMemberDetails = this.state.selectedStaffMemberDetails
        const selectedEnrollmentStatuses = this.state.selectedEnrollmentStatuses
        const beforeDate = this.state.beforeDate
        const afterDate = this.state.afterDate
        const cohort = this.state.cohort

        const createdAt = new Date()
        const updatedAt = new Date()

        const scheduledMessageObject = {
          _id, emailId, firstName, lastName, orgCode, orgName, contactEmail,
          postingId, postType, postingTitle, recipientType, recipients,
          messageType, messageDate, repeats, endRepeats, endDate, messageDates,
          messageTitle, messageText, sender,
          selectedRoleNames, selectedStaffMemberDetails, selectedEnrollmentStatuses, beforeDate, afterDate, cohort,
          createdAt, updatedAt
        }

        console.log('show message date: ', repeats, endRepeats, endDate, messageDates, typeof messageDate, messageDate)
        console.log('postingTitle: ', postingTitle)

        Axios.post('/api/scheduled-messages', scheduledMessageObject)
        .then((response) => {
          console.log('scheduled messages post attempted')
          if (response.data.success) {
            //save values
            console.log('Scheduled message post worked');

            if (_id) {
              this.setState({ successMessage: 'Successfully saved!', isSaving: false })
            } else {
              this.setState({ successMessage: 'Successfully saved!', isSaving: false,
                messageType: '', messageDate: '', messageHour: '', messageMinutes: '', messageMeridiem: '',
                messageText: '', postingId: '', postType: '', postingTitle: '',
                recipientType: '', selectedPosting: { title: '' }
              })
            }

          } else {
            console.log('scheduled message post did not work', response.data.message)
            this.setState({ errorMessage: response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('Scheduled message post did not work for some reason', error);
            this.setState({ errorMessage: error.toString(), isSaving: false })
        });
      }
    }

    deleteItem() {
      console.log('deletePost')

      const _id = this.state._id
      const self = this
      function deleteMessage() {
        console.log('deleteMessage called')

        Axios.delete('/api/scheduled-messages/' + _id)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Message delete worked');

            // self.props.history.goBack()
            self.props.navigate(-1)

          } else {
            console.error('there was an error deleting the posting');
            self.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            self.setState({ errorMessage: error.toString() })
        });
      }

      if (this.state.postingId) {

        const sendReminderText = false
        const updatedAt = new Date()

        Axios.post('/api/postings', {
          _id: this.state.postingId, sendReminderText, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Post save worked', response.data);

            deleteMessage()

          } else {
            console.error('there was an error posting the event');
            deleteMessage()
          }
        }).catch((error) => {
            console.log('The talk post did not work', error);
        });

      } else {
        deleteMessage()
      }
    }

    closeModal() {
      console.log('closeModal in EditScheduledMessage: ')

      this.setState({ modalIsOpen: false, showRecipients: false, showUnsubscribes: false });

    }

    filterUnsubscribedMembers(selectedMemberDetails) {
      console.log('filterUnsubscribedMembers called')

      const selectedMemberDetailsCount = selectedMemberDetails.length
      console.log('a0')
      let unsubscribedMembers = []
      let uIndices = []
      for (let i = 1; i <= selectedMemberDetailsCount; i++) {
        console.log('a1')
        const index = i - 1
        // const index = i - 1 - unsubscribedMembers.length
        if (selectedMemberDetails[index] && selectedMemberDetails[index].notificationPreferences && selectedMemberDetails[index].notificationPreferences.length > 0) {
          console.log('a2')
          for (let j = 1; j <= selectedMemberDetails[index].notificationPreferences.length; j++) {
            console.log('a3')
            if (selectedMemberDetails[index].notificationPreferences[j - 1].slug === 'announcements') {
              console.log('a4', unsubscribedMembers, index, selectedMemberDetails)
              unsubscribedMembers.push(selectedMemberDetails[index])
              console.log('a5', selectedMemberDetails)
              // selectedMemberDetails.splice(index, 1)
              uIndices.push(index)
              console.log('a6')
            }
          }
        }
      }

      for (let i = 1; i <= uIndices.length; i++) {
        selectedMemberDetails.splice(uIndices[i - 1], 1)
      }
      console.log('a7')
      return { selectedMemberDetails, unsubscribedMembers }

    }

    render() {

        return (
            <div className="standard-container-2">
              <div className="row-20">
                <p className="heading-text-2">Message Blast</p>
                <p className="description-text-color top-padding-5">Send scheduled texts/emails to members of {this.state.orgName}</p>
              </div>

              <div>
                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Recipients</label>
                    <select name="recipientType" value={this.state.recipientType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.recipientTypeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  {(this.state.recipientType === 'Individuals') && (
                    <div className="container-right">
                      <label className="profile-label">Add members</label>
                      <div>
                        <div className="calc-column-offset-70">
                          <input type="text" className="text-field" placeholder="Search members..." name="searchMembers" value={this.state.searchStringMembers} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className={(!this.state.searchStringMembers || this.state.searchStringMembers === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.searchStringMembers || this.state.searchStringMembers === '') ? true : false} onClick={() => this.addItem('member')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.searchIsAnimatingMembers) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <div className="super-spacer" />

                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Searching...</p>

                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            {(this.state.memberOptions && this.state.memberOptions.length > 0) && (
                              <div className="card top-margin">
                                {this.state.memberOptions.map((value, optionIndex) =>
                                  <div key={value._id} className="left-text bottom-margin-5 full-width">
                                    <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'member')}>
                                      <div className="full-width">
                                        <div className="fixed-column-40">
                                          <div className="mini-spacer" />
                                          <img src={profileIconDark} alt="GC" className="image-auto-22" />
                                        </div>
                                        <div className="calc-column-offset-40">
                                          <p className="cta-color">{value.firstName} {value.lastName}</p>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div>

                            {this.renderTags('member')}
                            <div className="clear" />

                          </div>

                        </div>
                      )}
                    </div>
                  )}
                  {(this.state.recipientType === 'Groups') && (
                    <div className="container-right">
                      <label className="profile-label">Role Name Tags</label>
                      <div>
                        <div className="calc-column-offset-70">
                          <select name={"selectedRoleName"} className="dropdown" value={this.state.selectedRoleName} onChange={this.formChangeHandler}>
                            {this.state.roleNameOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className={(!this.state.selectedRoleName || this.state.selectedRoleName === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.selectedRoleName || this.state.selectedRoleName === '') ? true : false} onClick={() => this.addItem('roleName')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div>
                        {this.renderTags('roleName')}
                        <div className="clear" />
                      </div>
                    </div>
                  )}
                  {(this.state.recipientType === 'Event RSVPs') && (
                    <div className="container-right">
                      <label className="profile-label">Select an Event</label>
                      <select name="selectedPosting" value={this.state.selectedPosting.title} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.postingOptions.map(value =>
                          <option key={value.title} value={value.title}>{value.title}</option>
                        )}
                      </select>
                    </div>
                  )}
                  <div className="clear" />
                </div>

                {(this.state.recipientType === 'Groups') && (
                  <div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Assigned Staff Members</label>
                        <div>
                          <div className="calc-column-offset-70">
                            <select name={"selectedStaffMember"} className="dropdown" value={this.state.selectedStaffMember} onChange={this.formChangeHandler}>
                              {this.state.staffMemberOptions.map(value => <option key={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""} value={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}>{(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.selectedStaffMember || this.state.selectedStaffMember === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.selectedStaffMember || this.state.selectedStaffMember === '') ? true : false} onClick={() => this.addItem('staffMember')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          {this.renderTags('staffMember')}
                          <div className="clear" />
                        </div>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Enrollment Status</label>
                        <div>
                          <div className="calc-column-offset-70">
                            <select name={"enrollmentStatus"} className="dropdown" value={this.state.enrollmentStatus} onChange={this.formChangeHandler}>
                              {this.state.enrollmentStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.enrollmentStatus || this.state.enrollmentStatus === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.enrollmentStatus || this.state.enrollmentStatus === '') ? true : false} onClick={() => this.addItem('enrollmentStatus')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          {this.renderTags('enrollmentStatus')}
                          <div className="clear" />
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Created Before</label>
                        <input type="date" className="date-picker" name="beforeDate" value={this.state.beforeDate} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Created After</label>
                        <input type="date" className="date-picker" name="afterDate" value={this.state.afterDate} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.cohortOptions && this.state.cohortOptions.length > 0) && (
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Cohort</label>
                          <select name={"cohort"} className="dropdown" value={this.state.cohort} onChange={this.formChangeHandler}>
                            {this.state.cohortOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>
                    )}

                    <div className="row-10 top-margin">
                      <div className="container-left">
                        <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showRecipients: true })}>View {this.state.selectedMemberDetails.length} selected recipients</button>
                        <p className="description-text-3">Maximum recipients = {this.state.resLimit}</p>
                      </div>
                      <div className="clear" />
                    </div>
                    {(this.state.unsubscribedMembers && this.state.unsubscribedMembers.length > 0) && (
                      <div className="row-10 top-margin">
                        <div className="container-left">
                          <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showUnsubscribes: true })}>View {this.state.unsubscribedMembers.length} removed unsubscribed recipient(s)</button>
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </div>
                )}

                <div className="row-10 top-margin-20">
                  <hr />
                </div>

                <div className="row-10">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Message Type</label>
                      <select name="messageType" value={this.state.messageType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.messageTypeOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Time to Send</label>
                      <div className="calc-column-offset-200 right-padding">
                        <input type="date" className="date-field full-width" min={convertDateToString(new Date(),"rawDateForInput")} name="messageDate" value={this.state.messageDate} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-60">
                        <select name="messageHour" value={this.state.messageHour} onChange={this.formChangeHandler} className="dropdown right-text">
                          {this.state.hourOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="fixed-column-10 top-padding-5">
                        <p className="full-width center-text">:</p>
                      </div>
                      <div className="fixed-column-60">
                        <select name="messageMinutes" value={this.state.messageMinutes} onChange={this.formChangeHandler} className="dropdown right-text">
                          {this.state.minuteOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="fixed-column-70">
                        <select name="messageMeridiem" value={this.state.messageMeridiem} onChange={this.formChangeHandler} className="dropdown right-text">
                          {this.state.meridiemOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.recipientType === 'Groups') && (
                    <div>
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Repeats</label>
                          <select name="repeats" value={this.state.repeats} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.repeatsOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {(this.state.repeats && this.state.repeats.startsWith('Every')) && (
                          <div className="container-right">
                            <label className="profile-label">End Repeats</label>
                            <select name="endRepeats" value={this.state.endRepeats} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.endRepeatsOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      {(this.state.repeats && this.state.repeats.startsWith('Every') && this.state.endRepeats === 'On Date') && (
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">End Date</label>
                            <input type="date" className="date-field full-width" min={convertDateToString(new Date(),"rawDateForInput")} name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.messageType === 'Email') && (
                    <div className="row-10">
                      <label className="profile-label">Message Title</label>
                      <input type="text" className="text-field" placeholder="Write your title..." name="messageTitle" value={this.state.messageTitle} onChange={this.formChangeHandler}></input>
                    </div>
                  )}

                  <div className="row-10">
                    <label className="profile-label">Message Body</label>
                    <textarea type="text" className="text-field" placeholder="Write your message..." name="messageText" value={this.state.messageText} onChange={this.formChangeHandler}></textarea>
                  </div>

                  <div className="row-10">
                    <label className="profile-label">Sender</label>
                    <select name={"sender"} className="dropdown" value={this.state.sender} onChange={this.formChangeHandler}>
                      {this.state.senderOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>

                  { (this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5 description-text-2">{this.state.errorMessage}</p> }
                  { (this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5 description-text-2">{this.state.successMessage}</p> }

                  {(this.state.confirmDelete === true) ? (
                    <div>
                      <div>
                        <p className="error-message">Are you sure you want to delete this message?</p>
                      </div>
                      <div className="clear" />
                      <div className="float-left right-padding-20">
                        <button className="btn btn-quaternary" onClick={() => this.deleteItem()}>Yes, Delete</button>
                      </div>
                      <div className="float-left right-padding-20">
                        <button className="btn btn-secondary" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                      </div>
                      <div className="clear" />
                    </div>
                  ) : (
                    <div className="row-10">
                      <div className="float-left right-padding-20">
                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.handleSubmit()}>Save Changes</button>
                      </div>
                      {(this.state._id) && (
                        <div className="float-left right-padding-20">
                          <button className="btn btn-quaternary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Scheduled Message</button>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  )}

                </div>
              </div>

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

              {(this.state.showRecipients) && (
                <div key="gradeProject" className="full-width padding-20">
                  <div className="edit-profile-row">
                    <p className="heading-text-4">Retrieved Recipients</p>
                    <div className="half-spacer" />
                  </div>

                  {(this.state.selectedMemberDetails && this.state.selectedMemberDetails.length > 0) ? (
                    <div>
                      {this.state.selectedMemberDetails.map((value, optionIndex) =>
                        <div key={"career|" + optionIndex} className="top-margin">
                          <p>{optionIndex + 1}. {value.firstName} {value.lastName} ({value.roleName})</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p>There are no recipients.</p>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-20">
                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}
              {(this.state.showUnsubscribes) && (
                <div key="gradeProject" className="full-width padding-20">
                  <div className="edit-profile-row">
                    <p className="heading-text-4">Retrieved Unsubscribed Recipients</p>
                    <div className="half-spacer" />
                  </div>

                  {(this.state.unsubscribedMembers && this.state.unsubscribedMembers.length > 0) ? (
                    <div>
                      {this.state.unsubscribedMembers.map((value, optionIndex) =>
                        <div key={"career|" + optionIndex} className="top-margin">
                          <p>{optionIndex + 1}. {value.firstName} {value.lastName} ({value.roleName})</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p>There are no unsubscribed recipients.</p>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-20">
                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}

             </Modal>
            </div>

        )
    }
}

export default withRouter(EditScheduledMessage);
// disallow duplicates
