import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import EditBenchmark from './Subcomponents/EditBenchmark';
import withRouter from './Functions/WithRouter';

class EmpAddBenchmark extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ emailId: email, username, cuFirstName, cuLastName, emp,
          activeOrg, orgMode, orgLogo
        });

      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
          <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace}/>

            <EditBenchmark source="Employer" activeOrg={this.state.activeOrg} accountCode={this.state.emp} selectedTemplate={this.state.selectedTemplate} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(EmpAddBenchmark)
