import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import withRouter from '../Functions/WithRouter';

// import loadingGIF from '../../images/loading-gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

export default function CheckoutForm(passedValues) {
  console.log('did history work 1: ', passedValues.history, passedValues.testValue)
  const stripe = useStripe();
  const elements = useElements();
  const [isSaving, setIsSaving ] = useState(false)

  let successMessage = null

  function onSubscriptionComplete(paymentMethodId, priceId, subscription) {
    console.log('onSubscriptionComplete called', subscription)

    if (window.location.pathname.includes('/problem-platform')) {
      let pathname = '/problem-platform/profile'
      if (passedValues.sourceLink) {
        pathname = passedValues.sourceLink
      }
      passedValues.navigate({ pathname, state: { newUser: true }})
    } else {
      passedValues.passSubscriptionData(subscription)
      // passedValues.navigate({ pathname, state: { newUser: true }})
    }
  }

  function onPurchaseComplete(paymentMethodId, priceId, item) {
    console.log('onPurchaseComplete called', item)

    if (passedValues.passProductData) {
      passedValues.passProductData(item)
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription, invoice, priceId, paymentMethodId, isRetry, productPurchase, product
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
    console.log('handlePaymentThatRequiresCustomerAction called', invoice, subscription, productPurchase)

    if (productPurchase) {
      // No customer action needed.
      console.log('no action needed')
      return { subscription, priceId, paymentMethodId };
    } else {
      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      // let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
      let paymentIntent = undefined
      if (invoice) {
        console.log('were pulling from invoice')
        paymentIntent = invoice.payment_intent
      } else {
        console.log('were pulling from subscription', subscription.subscription)
        paymentIntent = subscription.subscription.latest_invoice.payment_intent
      }

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        console.log('action is necessary')
        return stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              console.log('there was an action error: ', result.error)
              throw result;
            } else {
              console.log('we won on the actions: ', result)
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          })
          .catch((error) => {
            console.log('there was an error handling payment: ', error.error)
            alert(error.error.message);
          });
      } else {
        // No customer action needed.
        console.log('no action needed')
        return { subscription, priceId, paymentMethodId };
      }
    }
  }

  function handleRequiresPaymentMethod({
    subscription, paymentMethodId, priceId, productPurchase, product
  }) {
    console.log('show subscription object: ', subscription, productPurchase, passedValues)
    if (!subscription) {
      console.log('what happened to the subscription object?', subscription)
      throw { error: { message: 'Something went wrong with the charge. Lost the data.' } };
    } else if (subscription && subscription.subscription && subscription.subscription.status === 'active') {
      // subscription is active, no customer actions required.
      console.log('no customer action required')
      return { subscription, priceId, paymentMethodId };
    } else if (subscription && subscription.subscription && subscription.subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.subscription.latest_invoice.payment_intent.status
      );

      console.log('the card was declined')
      alert('Your card was declined')
      throw { error: { message: 'Your card was declined.' } };

    } else {
      console.log('not sure what is happening here. all other things failed.', subscription, product)
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId
  }) {
    return (
      fetch('/retry-invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          console.log('there was an error retrying the invoice: ', error.error)
          alert(error.error.message);
        })
    );
  }

  const handleSubmit = async (event) => {
    console.log('stripeHandleSubmit called', event.target)

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsSaving(true)

    if (!stripe || !elements) {
      console.log('missing stuff: ', stripe, elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsSaving(false)
      return;
    }

    const card = elements.getElement(CardElement)

    const email = passedValues.email
    const firstName = passedValues.firstName
    const lastName = passedValues.lastName
    const orgName = passedValues.orgName
    const employerName = passedValues.employerName
    const subscriptionType = passedValues.subscriptionType
    const customerId = passedValues.customerId
    const price = passedValues.price
    const quantity = passedValues.quantity
    const productPurchase = passedValues.productPurchase
    console.log('trying function 2 ', email, customerId, productPurchase)
    // Set up payment method for recurring usage

    const accountCode = passedValues.accountCode
    const orgCode = passedValues.orgCode
    const name = passedValues.name
    const description = passedValues.description
    const discount = passedValues.discount
    const promoCode = passedValues.promoCode
    const promoDiscount = passedValues.promoDiscount
    const bookingDate = passedValues.bookingDate
    const profileItems = passedValues.profileItems
    const recipientType = passedValues.recipientType
    const recipientFirstName = passedValues.recipientFirstName
    const recipientLastName = passedValues.recipientLastName
    const recipientEmail = passedValues.recipientEmail
    const slug = passedValues.slug
    const category = passedValues.category
    const imageURL = passedValues.imageURL
    const advisorFirstName = passedValues.advisorFirstName
    const advisorLastName = passedValues.advisorLastName
    const advisorEmail = passedValues.advisorEmail
    const advisorBio = passedValues.advisorBio
    const advisorPic = passedValues.advisorPic
    const advisorProfileLink = passedValues.advisorProfileLink

    // const customerId = 'cus_IiZG2ivp02PP9P'

    let billingName = firstName + ' ' + lastName
    if (subscriptionType === 'Organization') {
      billingName = orgName
    }
    let priceId = passedValues.priceId

    stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: billingName,
        },
      }).then((result) => {
        if (result.error) {
          console.log('there was a stripe createPaymentMethod error: ', result.error)

          if (passedValues.passProductData) {
            passedValues.passProductData({ success: false, errorMessage: result.error.message})
          }

          setIsSaving(false)
        } else {
          console.log('created a payment method!', result.paymentMethod)

          if (productPurchase) {
            const paymentMethodId = result.paymentMethod.id
            console.log('got the pm id: ', customerId, paymentMethodId, priceId)

            fetch('/api/payments/purchase', {
              method: 'post',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify({
                customerId, paymentMethodId, priceId,
                email, firstName, lastName, orgName, employerName, price, quantity,
                accountCode, orgCode,
                name, description,
                promoCode, promoDiscount, bookingDate, profileItems,
                recipientType, recipientFirstName, recipientLastName, recipientEmail,
                slug, category, imageURL, advisorFirstName, advisorLastName, advisorEmail,
                advisorBio, advisorPic, advisorProfileLink
              }),
            })
              .then((response) => {
                console.log('response 1', response)
                return response.json();
              })
              // If the card is declined, display an error to the user.
              .then((result) => {
                if (result.error) {
                  // The card had an error when trying to attach it to a customer.
                  console.log('response 2', result)
                  setIsSaving(false)

                  passedValues.passProductData({ success: false, errorMessage: result.error.message})
                  // errorMessage = result.error.message
                  throw result;
                } else {
                  console.log('no error! ')
                  setIsSaving(false)

                }
                return result;
              })
              // Normalize the result to contain the object returned by Stripe.
              // Add the additional details we need.
              .then((result) => {
                console.log('response 3', result)
                if (result.success) {
                  // isSaving = false
                  setIsSaving(false)
                  successMessage = 'Thank you for your payment!'
                  console.log('did history work 2: ', passedValues.history, passedValues.testValue)

                  return {
                    paymentMethodId: paymentMethodId,
                    priceId: priceId, productPurchase, product: result,
                    subscription: result, success: true
                  };
                }

              })
              // Some payment methods require a customer to be on session
              // to complete the payment process. Check the status of the
              // payment intent to handle these actions.
              .then(handlePaymentThatRequiresCustomerAction)
              // If attaching this card to a Customer object succeeds,
              // but attempts to charge the customer fail, you
              // get a requires_payment_method error.
              .then(handleRequiresPaymentMethod)
              // No more actions required. Provision your service for the user.
              .then(onPurchaseComplete)
              .catch((error) => {
                console.log('response error', error)
                // alert(error.message)
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                // showCardError(error);
            })
          } else {
            // createSubscription({
            //   customerId: customerId,
            //   paymentMethodId: result.paymentMethod.id,
            //   priceId: priceId,
            // });
            const paymentMethodId = result.paymentMethod.id
            console.log('got the pm id: ', customerId, paymentMethodId, priceId)

            fetch('/api/payments/create-subscription', {
              method: 'post',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify({
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                priceId: priceId,
                email, firstName, lastName, orgName, employerName, subscriptionType, price, quantity,
                discount, promoCode, promoDiscount,
                payingSubscription: true
              }),
            })
              .then((response) => {
                console.log('response 1', response)
                return response.json();
              })
              // If the card is declined, display an error to the user.
              .then((result) => {
                if (result.error) {
                  // The card had an error when trying to attach it to a customer.
                  console.log('response 2', result)
                  setIsSaving(false)
                  throw result;
                } else {
                  console.log('no error! ')
                  setIsSaving(false)

                }
                return result;
              })
              // Normalize the result to contain the object returned by Stripe.
              // Add the additional details we need.
              .then((result) => {
                console.log('response 3', result)
                if (result.success) {
                  // isSaving = false
                  setIsSaving(false)
                  successMessage = 'Thank you for your subscription!'
                  console.log('did history work 2: ', passedValues.history, passedValues.testValue)

                  return {
                    paymentMethodId: paymentMethodId,
                    priceId: priceId,
                    subscription: result, success: true
                  };
                }

              })
              // Some payment methods require a customer to be on session
              // to complete the payment process. Check the status of the
              // payment intent to handle these actions.
              .then(handlePaymentThatRequiresCustomerAction)
              // If attaching this card to a Customer object succeeds,
              // but attempts to charge the customer fail, you
              // get a requires_payment_method error.
              .then(handleRequiresPaymentMethod)
              // No more actions required. Provision your service for the user.
              .then(onSubscriptionComplete)
              .catch((error) => {
                console.log('response error', error)

            })
          }
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {(isSaving) && (
        <div>
          <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
        </div>
      )}

      <CardSection />

      <div className="top-padding-40">
        <button type="submit" disabled={isSaving} className="btn btn-squarish">Confirm Order</button>
      </div>
    </form>
  );
}
