import React, {Component} from 'react';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import SubEditProfile from '../components/Subcomponents/EditProfile';
import SubSettings from '../components/Subcomponents/Settings';
import withRouter from '../components/Functions/WithRouter';

class EditProfilePage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveFile = this.saveFile.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        //see if user is logged in
        console.log('problemPlatformProfilePage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        let emailId = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');

        console.log('show rolename: ', roleName, activeOrg)

        this.setState({ emailId, activeOrg, orgFocus, roleName })

        if (this.props.location && this.props.location.state) {
          // show welcome message
          const { newUser } = this.props.location.state;
          console.log('just signed up: ', newUser)
          this.setState({ newUser })
        }
    }

    formChangeHandler = event => {

      console.log('show data: ', event.target.name)

      if (event.target.name === 'roleName') {
        this.setState({ roleName: event.target.value })
      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      const orgCode = this.state.activeOrg
      const fileName = passedFile.name
      let originalName = category + '|' + orgCode + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'orgLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoFile: passedFile })
          } else if (category === 'orgLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoWhiteFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'orgLogo') {
              existingFilePath = self.state.orgLogoPath
            } else if (category === 'orgLogoWhite') {
              existingFilePath = self.state.orgLogoWhite
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'orgLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoPath: newFilePath
                    })
                  } else if (category === 'orgLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoWhitePath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessOrgLogo: false,
              serverErrorMessageOrgLogo: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessOrgLogo: false,
            serverErrorMessageOrgLogo: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    closeModal() {

      this.setState({ modalIsOpen: false });
    }



    render() {

      return (
        <div>
          <TopNavigation currentPage={"editProfilePage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

          {(this.state.roleName === 'Employer' || this.state.roleName === 'Admin' || this.state.roleName === 'Work-Based Learning Coordinator') ? (
            <div className="standard-container-2">
              <SubSettings email={this.state.emailId} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} accountCode={this.state.emp}  history={this.props.navigate}  />
            </div>
          ) : (
            <div className="standard-container-2">
              <SubEditProfile activeOrg={this.state.activeOrg} history={this.props.navigate} />
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(EditProfilePage);
