import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import Benchmarks from './Subcomponents/Benchmarks';
import withRouter from './Functions/WithRouter';

class EmpBenchmarks extends Component {
    constructor(props) {
      super(props)

      this.state = {
          renderSideNav: false,
          templates: [],
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ username, emp, orgMode, activeOrg, orgLogo })

      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg}  history={this.props.navigate} loadWorkspace={this.loadWorkspace}/>

            {(this.state.renderSideNav) && (
              <SubSideNav selectedComponent={"EmpBenchmarks"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} accountCode={this.state.emp} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />
            )}

            {(this.state.emp) && (
              <div>
                <Benchmarks source="Employer" activeOrg={this.state.activeOrg} accountCode={this.state.emp} renderSideNav={this.state.renderSideNav} history={this.props.navigate} />
              </div>
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}

        </div>
      )
    }
}

export default withRouter(EmpBenchmarks);
