import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubTrends from './Subcomponents/Trends';
import withRouter from './Functions/WithRouter';

class AdvTrends extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      // document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount parent trends')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
          orgLogo
        })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

              <div className="width-70-percent center-horizontally padding-30 max-width-1400">
                <SubTrends org={this.state.activeOrg} history={this.props.navigate} />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(AdvTrends)
