import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';

// import {convertDateToString} from '../Functions/ConvertDateToString';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class ConfigureEndorsement extends Component {
    constructor(props) {
        super(props)

        this.state = {
          competencyTypeOptions: [],

          competencies: [],
          skillTraits: [],
          examples: [],
          checked: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.renderCompetencies = this.renderCompetencies.bind(this)
        this.renderExamples = this.renderExamples.bind(this)
        this.addCompetency = this.addCompetency.bind(this)
        this.addExample = this.addExample.bind(this)
        this.removeItem = this.removeItem.bind(this)

        this.searchCompetencies = this.searchCompetencies.bind(this)
        this.competencyClicked = this.competencyClicked.bind(this)

    }

    componentDidMount() {
      console.log('ConfigureEndorsement just mounted')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubConfigureEndorsement', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark !== prevProps.selectedBenchmark) {
        this.retrieveData()
      } else if (this.props.selectedBenchmark && prevProps.selectedBenchmark) {
        console.log('both are here')
        if (this.props.selectedBenchmark.title !== prevProps.selectedBenchmark.title) {
          this.retrieveData()
        }
      } else if (this.props.requestedCompetencies !== prevProps.requestedCompetencies) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubConfigureEndorsement')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let pictureURL = localStorage.getItem('pictureURL');

      let skillTypeOptions = ['','Hard Skill','Soft Skill']
      let competencyTypeOptions = ['','Hard Skill','Soft Skill','General Skill','Ability','Knowledge','Work Style']

      let competencies = []
      if (this.props.requestedCompetencies) {
        competencies = this.props.requestedCompetencies
      } else if (this.props.selectedBenchmark) {
        console.log('there are competencies: ', this.props.selectedBenchmark)
        const highPriorityHardSkills = this.props.selectedBenchmark.highPriorityHardSkills
        const lowPriorityHardSkills = this.props.selectedBenchmark.lowPriorityHardSkills
        const highPrioritySoftSkills = this.props.selectedBenchmark.highPrioritySoftSkills
        const lowPrioritySoftSkills = this.props.selectedBenchmark.lowPrioritySoftSkills
        const knowledge = this.props.selectedBenchmark.knowledge
        // const traits = this.props.selectedBenchmark.traits
        const abilities = this.props.selectedBenchmark.abilities
        // lowPriorityHardSkills, highPriorityHardSkills, lowPrioritySoftSkills, highPriorityHardSkills, knowledge, traits
        if (highPriorityHardSkills) {
          for (let i = 1; i <= highPriorityHardSkills.length; i++) {
            competencies.push({ name: highPriorityHardSkills[i - 1], category: 'Hard Skill' })
          }
        }
        if (lowPriorityHardSkills) {
          for (let i = 1; i <= lowPriorityHardSkills.length; i++) {
            competencies.push({ name: lowPriorityHardSkills[i - 1], category: 'Hard Skill' })
          }
        }
        if (highPrioritySoftSkills) {
          for (let i = 1; i <= highPrioritySoftSkills.length; i++) {
            competencies.push({ name: highPrioritySoftSkills[i - 1], category: 'Soft Skill' })
          }
        }
        if (lowPriorityHardSkills) {
          for (let i = 1; i <= lowPrioritySoftSkills.length; i++) {
            competencies.push({ name: lowPrioritySoftSkills[i - 1], category: 'Soft Skill' })
          }
        }
        if (knowledge) {
          for (let i = 1; i <= knowledge.length; i++) {
            competencies.push({ name: knowledge[i - 1].title, category: 'Knowledge' })
          }
        }
        // if (traits) {
        //   for (let i = 1; i <= traits.length; i++) {
        //     competencies.push({ name: traits[i - 1].title, category: 'Traits' })
        //   }
        // }
        if (abilities) {
          for (let i = 1; i <= abilities.length; i++) {
            competencies.push({ name: abilities[i - 1].title, category: 'Ability' })
          }
        }
      }

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, username, pictureURL,
        skillTypeOptions, competencyTypeOptions, competencies
      })

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called ', event.target.name, event.target.value)

      if (event.target.name.includes('skillName')) {
        console.log('show me what were working with', this.state.skillTraits)
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1

        const name = event.target.value
        const skillType = skillTraits[index].skillType
        const rating = skillTraits[index].rating
        skillTraits[index] = { name, skillType, rating }
        this.setState({ skillTraits })
        this.props.passState({skillTraits: skillTraits })
        if (skillType !== 'Trait') {
          this.searchCompetencies(event.target.value, skillTraits[index].skillType, index)
        }

      } else if (event.target.name.includes('skillType')) {
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        skillTraits[index] = { name: skillTraits[index].name, skillType: event.target.value, rating: skillTraits[index].rating }
        this.setState({ skillTraits })
        this.props.passState({skillTraits: skillTraits })
      } else if (event.target.name.includes('skillRating')) {
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        skillTraits[index]['rating'] = event.target.value
        this.setState({ skillTraits })
        this.props.passState({skillTraits: skillTraits })
      } else if (event.target.name.includes('exampleSkillTrait')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        examples[index] = { skillTrait: event.target.value, example: examples[index].example }
        this.setState({ examples })
        this.props.passState({examples: examples })
      } else if (event.target.name.includes('competencyName')) {

        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1

        const name = event.target.value
        const category = competencies[index].category
        const rating = competencies[index].rating
        competencies[index] = { name, category, rating }
        this.setState({competencies })
        this.props.passState({ competencies: competencies })
        this.searchCompetencies(event.target.value, competencies[index].category, index)

      } else if (event.target.name.includes('competencyType')) {

        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        competencies[index] = { name: competencies[index].name, category: event.target.value, rating: competencies[index].rating }
        console.log('show competencies: ', event.target.name, index, competencies.length, competencies)
        this.setState({ competencies })
        this.props.passState({ competencies: competencies })
      } else if (event.target.name.includes('competencyRating')) {
        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        competencies[index]['rating'] = event.target.value
        this.setState({ competencies })
        this.props.passState({ competencies: competencies })
      } else if (event.target.name.includes('exampleCompetency')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        examples[index] = { competency: event.target.value, example: examples[index].example }
        this.setState({ examples })
        this.props.passState({examples: examples })
      } else if (event.target.name.includes('exampleExample')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        if (examples[index].competency) {
          examples[index] = { competency: examples[index].competency, example: event.target.value }
        } else {
          examples[index] = { skillTrait: examples[index].skillTrait, example: event.target.value }
        }

        this.setState({ examples })
        this.props.passState({examples: examples })
      } else {
        this.setState({ [event.target.name]: event.target.value })
        this.props.passState({ [event.target.name]: event.target.value })
      }
    }

    searchCompetencies(competency, type, index) {
      console.log('searchCompetencies ', competency, type, index)

      if (competency === '') {
        const skillOptions = []
        const competencyOptions = []
        this.setState({ skillOptions, competencyOptions })
        this.props.passState({skillOptions: skillOptions, competencyOptions: competencyOptions})
      } else {
        Axios.get('/api/competency/search', { params: { searchString: competency, type } })
        .then((response) => {
          console.log('Competency search query attempted', response.data);

            if (response.data.success) {
              console.log('competency search query worked')

              const skillOptions = response.data.skills
              const competencyOptions = response.data.competencies
              const selectedIndex = index
              this.setState({ skillOptions, competencyOptions, selectedIndex });
              this.props.passState({ skillOptions: skillOptions, competencyOptions: skillOptions, selectedIndex: selectedIndex })
            } else {
              console.log('competency search query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Competency search query did not work for some reason', error);
        });
      }
    }

    renderCompetencies() {
      console.log('renderCompetencies called', this.state.competencies)

      let rows = []

      let competencies = []
      let competencyOptions = []

      if (this.state.competencies) {
        competencies = this.state.competencies
        competencyOptions = this.state.competencyOptions
        // console.log('show competencyOptions 1: ', competencyOptions)
      } else {
        competencies = this.state.skillTraits
        competencyOptions = this.state.skillOptions
        // console.log('show competencyOptions 2: ', competencyOptions)
      }

      for (let i = 1; i <= competencies.length; i++) {

        if ((competencies[i - 1].skillType && competencies[i - 1].skillType.includes('Skill')) || (competencies[i - 1].category)) {
          console.log('in hereee')
          const index = i - 1

          let nameName = "skillName|" + i.toString()
          let typeName = "skillType|" + i.toString()
          let ratingName = "skillRating|" + i.toString()
          if (this.state.competencies) {
            nameName = "competencyName|" + i.toString()
            typeName = "competencyType|" + i.toString()
            ratingName = "competencyRating|" + i.toString()
          }

          rows.push(
            <div key={i} className="bottom-margin-15">
              <div className="fixed-column-30">
                <button className="background-button" onClick={() => this.removeItem('competency',index)}>
                  <img src={deniedIcon} alt="GC" className="image-auto-20 top-margin" />
                </button>
              </div>
              <div className="calc-column-offset-30-of-50 right-padding-5">
                <input type="text" className="text-field" placeholder="Skill Name" name={nameName} value={competencies[i - 1].name} onChange={this.formChangeHandler} />
              </div>

              <div className="relative-column-25 right-padding-5">
               {(this.state.competencies && this.state.competencies.length > 0) ? (
                 <select name={typeName} value={this.state.competencies[i - 1].category} className="dropdown" onChange={this.formChangeHandler}>
                   {this.state.competencyTypeOptions.map(value =>
                     <option key={value} value={value}>{value}</option>
                   )}
                 </select>
               ) : (
                 <select name={typeName} value={this.state.skillTraits[i - 1].skillType} className="dropdown" onChange={this.formChangeHandler}>
                   {this.state.skillTypeOptions.map(value =>
                     <option key={value.key} value={value}>{value}</option>
                   )}
                 </select>
               )}
              </div>

              <div className="relative-column-25">
                <select name={ratingName} value={competencies[i - 1].rating} className="dropdown" onChange={this.formChangeHandler}>
                    <option />
                    <option value="5">Exceptional</option>
                    <option value="4">Great</option>
                    <option value="3">Average</option>
                    <option value="2">Needs Improvement</option>
                    <option value="1">Needs Significant Improvement</option>
                    <option value="I'm Not Sure">I'm Not Sure</option>
                </select>
              </div>
              <div className="clear" />
              {(this.state.onMobile) && (
                <div className="spacer" />
              )}

              {(this.state.selectedIndex === index && competencyOptions.length > 0) && (
                <div className="full-width">
                  <div className="spacer" />
                  {competencyOptions.map((value, optionIndex) =>
                    <div key={value._id}>
                      <button className="background-button" onClick={() => this.competencyClicked(index, optionIndex, 'skill')}>
                        <div className="left-padding">
                           <div className="float-left right-padding">
                            <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-20" />
                          </div>
                          <div className="float-left">
                            <p>{value.name}</p>
                          </div>
                        </div>
                      </button>
                      <div className="clear" />
                      <div className="half-spacer" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }

      return rows
    }

    addCompetency(type) {
      console.log('add SkillTrait called')

      let competencies = this.state.competencies
      competencies.push({ name: '', category: 'Hard Skill'})

      let checked = this.state.checked
      checked.push(false)
      this.setState({ competencies, checked })
      this.props.passState({ competencies: competencies, checked: checked })
    }

    competencyClicked(index, optionIndex, type) {
      console.log('competencyClicked', index, optionIndex, type, this.state.skillOptions)

      if (type === 'trait') {
        console.log('this is an error. This is now a dropdown.')
      } else if (this.state.competencyOptions && this.state.competencyOptions.length > 0) {
        const name = this.state.competencyOptions[optionIndex].name
        let category = this.state.competencyOptions[optionIndex].type
        // category types in database: Ability, Activity, General Skill, Hard Skill, Knowledge, Soft Skill, Tech Skill, Tools Used, Work Style
        if (category === 'Tools Used' || category === 'Tech Skill' || category === 'Activity') {
          category = 'Hard Skill'
        }

        const rating = ''

        const competencyOptions = []
        let competencies = this.state.competencies
        competencies[index] = { name, category, rating }
        this.setState({ competencies, competencyOptions })
        this.props.passState({ competencies: competencies, competencyOptions: competencyOptions })

      } else {
        const name = this.state.skillOptions[optionIndex].name
        const skillType = this.state.skillOptions[optionIndex].type
        const rating = ''
        const skillOptions = []
        let skillTraits = this.state.skillTraits
        skillTraits[index] = { name, skillType, rating }
        this.setState({ skillTraits, skillOptions })
        this.props.passState({ skillTraits: skillTraits, skillOptions: skillOptions })
      }
    }

    renderExamples() {
      console.log('renderExamples called')

      let rows = []

      let competencyOptions = []
      competencyOptions.push("Select a Skill")
      if (this.state.competencies && this.state.competencies.length > 0) {
        for (let i = 1; i <= this.state.competencies.length; i++) {
          competencyOptions.push(this.state.competencies[i - 1].name)
        }
      } else {
        for (let i = 1; i <= this.state.skillTraits.length; i++) {
          competencyOptions.push(this.state.skillTraits[i - 1].name)
        }
      }

      for (let i = 1; i <= this.state.examples.length; i++) {
        const index = i - 1

        let exampleSkillTrait = "exampleSkillTrait|" + i.toString()
        if (this.state.competencies && this.state.competencies.length > 0) {
          exampleSkillTrait = "exampleCompetency|" + i.toString()
        }
        const exampleExample = "exampleExample|" + i.toString()
        let exampleCompetency = this.state.examples[i - 1].skillTrait
        if (this.state.competencies) {
          exampleCompetency = this.state.examples[i - 1].competency
        }

        rows.push(
          <div key={i}>
            <div className="fixed-column-30">
              <button className="background-button" onClick={() => this.removeItem('example',index)}>
                <img src={deniedIcon} alt="GC" className="image-auto-20 top-margin" />
              </button>
            </div>
            <div className="calc-column-offset-30">
              <select name={exampleSkillTrait} value={exampleCompetency} className="dropdown" onChange={this.formChangeHandler}>
                {competencyOptions.map(value =>
                  <option key={value.key} value={value}>{value}</option>
                )}
              </select>
              <textarea type="text" className="text-field" placeholder="Share an example where the endorsee exceptionally demonstrated this competency…." name={exampleExample}value={this.state.examples[i - 1].example} onChange={this.formChangeHandler}></textarea>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows
    }

    addExample() {
      console.log('addExample called')

      let examples = this.state.examples
      if (this.state.competencies) {
        examples.push({ skillTrait: 'Select a Competency', example: '' })
      } else {
        examples.push({ skillTrait: 'Select a Skill', example: '' })
      }

      let checked = this.state.checked
      checked.push(false)
      this.setState({ examples, checked })
      this.props.passState({ examples: examples, checked: checked })
    }

    removeItem(type,index) {
      console.log('removeItem called')

      if (type === 'competency') {
        let competencies = this.state.competencies
        competencies.splice(index,1)
        this.setState({ competencies })
      } else if (type === 'example') {
        let examples = this.state.examples
        examples.splice(index,1)
        this.setState({ examples })
      }
    }

    render() {

      return (
        <div>
          <div className="row-15">
            <div className="row-20">
              <p>Competencies</p>
            </div>

            {this.renderCompetencies()}
            <div className="clear" />

            <div className="add-item-line-button">
              <hr />
              <a className="background-button" onClick={() => this.addCompetency('Skill')}>
                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
              </a>
            </div>
          </div>

          <div className="row-15">
            <div className="row-20">
              <p className="row-10">Examples</p>
              <p className="profile-descriptor">Please provide at least one supporting story / example</p>
            </div>
            
            {this.renderExamples()}
            <div className="clear" />

            <div className="add-item-line-button">
              <hr />
              <a className="background-button" onClick={() => this.addExample()}>
                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
              </a>
            </div>
          </div>

        </div>
      )
    }
}

export default ConfigureEndorsement;
