import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import SubContact from '../../components/Subcomponents/Contact';
import withRouter from '../../components/Functions/WithRouter';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const askQuestionIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/askQuestionIconDark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Help extends Component {
    constructor(props) {
        super(props)

        this.state = {
          defaultFilters: [],
          selectedFilters: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.craftQuestion = this.craftQuestion.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.selectedFilters !== prevProps.selectedFilters) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      let email = localStorage.getItem('email');

      const defaultFilters = [
        'Career-Seeker','Educator','Workforce','Mentor','Employer'
      ]

      let selectedFilters = this.state.selectedFilters
      if (this.props.selectedFilters) {
        selectedFilters = this.props.selectedFilters
      }
      console.log('show selectedFilters: ', selectedFilters)

      this.setState({ defaultFilters, selectedFilters, emailId: email })

      let filters = null
      if (selectedFilters && selectedFilters.length > 0) {
        filters = selectedFilters
      }

      Axios.get('/api/help-articles', { params: { filters, resLimit: 100 } })
      .then((response) => {
        console.log('Articles', response.data);

          if (response.data.success) {
            console.log('article query worked')

            const articles = response.data.articles
            this.setState({ articles })

          } else {
            console.log('article did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Articles did not work for some reason', error);
      });
    }

    formChangeHandler = event => {
      console.log('formChangeHandler called: ', event.target.name, event.target.value)

      this.setState({ [event.target.name]: event.target.value })

      if (event.target.name === 'searchString') {
        this.filterResults(event.target.value, this.state.selectedFilters)
      } else if (event.target.name.includes('filter|')) {

        // let itemFilters = this.state.itemFilters
        //
        // const nameArray = event.target.name.split("|")
        // const field = nameArray[1]
        //
        // let index = 0
        // for (let i = 1; i <= itemFilters.length; i++) {
        //   if (itemFilters[i - 1].name === field) {
        //     itemFilters[i - 1]['value'] = event.target.value
        //     index = i - 1
        //   }
        // }
        //
        // //reset everything
        // let searchString = ''
        // for (let i = 1; i <= itemFilters.length; i++) {
        //   if (itemFilters[i - 1].name !== field) {
        //     itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        //   }
        // }
        //
        // let itemSorters = this.state.itemSorters
        // for (let i = 1; i <= itemSorters.length; i++) {
        //   itemSorters[i - 1]['value'] = this.state.defaultSortOption
        // }

        // this.setState({ animating: true, searchString, itemFilters, itemSorters })

      }
    }

    filterResults(searchString, filters) {
      console.log('searchString called', searchString, filters)

      this.setState({ animating: true })

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/help-articles', {  params: {searchString, filters }})
        .then((response) => {
          console.log('Help articles query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved careers')

              if (response.data) {

                const articles = response.data.articles

                // THIS IS SUPER UNNECESSARY
                const filterCriteriaArray = response.data.filterCriteriaArray
                const sortCriteriaArray = null

                self.setState({ articles, animating: false, filterCriteriaArray, sortCriteriaArray })
              }

            } else {
              console.log('no article data found', response.data.message)
              self.setState({ animating: false, articles: [] })
            }

        }).catch((error) => {
            console.log('Article query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    optionClicked(value, index) {
      console.log('optionClicked called')

      let selectedFilters = this.state.selectedFilters
      if (selectedFilters && selectedFilters.length > 0) {

        if (selectedFilters.includes(value)) {

          const removeIndex = selectedFilters.indexOf(value)
          selectedFilters.splice(removeIndex, 1)

        } else {
          selectedFilters.push(value)
        }
      } else {
        selectedFilters.push(value)
      }

      this.setState({ selectedFilters })
      this.filterResults(this.state.searchString, selectedFilters)
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    craftQuestion(e, questionAbout) {

      e.stopPropagation()

      this.setState({ modalIsOpen: true, questionAbout })

    }

    render() {
      console.log('testing 6')

      let containerClass = "contact-container"
      if (this.props.pageSource === 'landingPage') {
        containerClass = ""
      }

      return (
          <div>
            <div className={containerClass}>

              {(this.props.pageSource !== 'landingPage') && (
                <div>
                  <br/>
                  <div>
                    <div className="flex-container row-direction">
                      <div className="flex-20">
                      </div>
                      <div className="flex-60">
                        <h2>Help Page</h2>
                      </div>
                      <div className="flex-20">
                        <button className="background-button float-right" onClick={(e) => this.craftQuestion(e, 'General Question About Help Articles')}>
                          <img src={askQuestionIconDark} className="image-auto-30 pin-right" alt="GC" />
                        </button>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>

                  <p className="full-width center-text bottom-padding-20">Help articles and tutorials from the Guided Compass team.</p>

                  <div className="filter-field-search full-width white-background">
                    <div className="float-left top-padding-5 left-padding-3">
                      <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                    </div>
                    <div className="filter-search-container calc-column-offset-100-static">
                      <input type="text" className="text-field clear-border" placeholder="Search articles..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                    </div>
                  </div>
                  <div className="clear" />

                  <div className="row-20">
                    <p className="description-text-4 top-padding">FILTER BY PORTAL</p>

                    {this.state.defaultFilters.map((value, optionIndex) =>
                      <div key={optionIndex}>
                        <div className="float-left row-10 right-padding">
                          {(this.state.selectedFilters.includes(value)) ? (
                            <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text">{value}</p>
                                </div>
                              </div>
                            </button>
                          ) : (
                            <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2">{value}</p>
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="clear"/>
                  </div>
                </div>
              )}

              <div>
                {(this.state.articles) && (
                  <div>

                    {(this.state.animating) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Pulling articles...</p>

                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.articles.map((value, optionIndex) =>
                          <div key={optionIndex} className="bottom-margin-20">
                            <button onClick={() => this.props.navigate("/help/" + value.slug)} className="background-button full-width standard-color medium-shadow left-text">
                              <div className="padding-20 standard-border">


                                <div className="flex-container row-direction">
                                  <div className="flex-80">
                                    <p className="heading-text-3 cta-color bold-text top-padding">{optionIndex + 1}. {value.title}</p>
                                  </div>
                                  <div className="flex-20">
                                    <button className="background-button float-right" onClick={(e) => this.craftQuestion(e, 'Question About ' + value.title + ' Article')}>
                                      <img src={askQuestionIconDark} className="image-auto-30 pin-right" alt="GC" />
                                    </button>
                                    <div className="clear" />
                                  </div>
                                </div>

                                <p className="description-text-1 top-padding">{value.description}</p>

                                {(value.portals) && (
                                  <div className="row-10">
                                    {value.portals.map((value2, optionIndex2) =>
                                      <div key={value.title + '|' + value2} className="float-left top-margin-5 left-margin-5 unclickable-tags">
                                        <p className="description-text-4">{value2}</p>
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}

                                <div className="spacer" />
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

              </div>

            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
             <div key="askQuestion" className="full-width">
                <h2>Ask A Question</h2>
                <SubContact reason={this.state.questionAbout} reasonOptions={null} history={this.props.navigate} inModal={true} closeModal={this.closeModal} />
             </div>

           </Modal>

          </div>

      )
    }
}

export default withRouter(Help)
