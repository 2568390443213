import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {requestAccessToWorkspace} from '../Services/ProfileRoutes';
import SubContact from '../../components/Subcomponents/Contact';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
// const dropdownArrow = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const askQuestionIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/askQuestionIconDark.png";
const featuredIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/featured-icon-blue.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class AddWorkspaces extends Component {
    constructor(props) {
        super(props)

        this.state = {
          defaultFilterOption: 'All',
          education: []
        }

        this.retrieveData = this.retrieveData.bind(this)

        this.submitRequest = this.submitRequest.bind(this)
        this.submitSignUpFields = this.submitSignUpFields.bind(this)

        this.openSignUpFieldsModal = this.openSignUpFieldsModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderSignUpFields = this.renderSignUpFields.bind(this)
        this.renderSignUpField = this.renderSignUpField.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)

        this.orgClicked = this.orgClicked.bind(this)
        this.removeOrg = this.removeOrg.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in addWorkspace ')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData(this.props.assessment, this.props.index)
      }
    }

    retrieveData() {
      console.log('retrieveData called within addWorkspace', this.props)

      let accountCode = this.props.accountCode
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      let orgTypeOptions = ['Workforce','Education']
      // staffing, employer communities?

      let itemFilters = [
        { name: 'Featured', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['Yes','No']) },
        { name: 'Org Type', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(orgTypeOptions) },
        { name: 'Number of Members', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['< 30','30 - 100','101 - 500','501 - 1,000','1,001 - 5,000','5,001+']) },
        { name: 'Diversity-Focused', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['Yes','No']) },
        { name: 'Low-Income Focused', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['Yes','No']) },
        { name: 'Avg Rating', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['< 2 Stars', '2 - 3 Stars','3 - 4 Stars','4 - 5 Stars']) },
        { name: 'Review', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['< 5 Reviews','5 - 10 Reviews','11 - 25 Reviews','24+ Reviews'])},
      ]

      if (window.location.pathname.includes('/employers')) {
        itemFilters.push({ name: 'Added', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(['Yes','No']) },)
      }

      if (activeOrg || accountCode) {
        this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus, accountCode, itemFilters, isAnimating: true })

        let workspaceParams = { isActive: true, sortByPublic: true, resLimit: 50 }
        // if (window.location.pathname.includes('/employers/') && accountCode) {
        //   workspaceParams = { isActive: true, isPublic: false }
        // } else if (window.location.pathname.includes('/app/')) {
        //   workspaceParams = { isActive: true, isPublic: true }
        // }

        if (workspaceParams.isActive) {
          Axios.get('/api/org/all', { params: workspaceParams })
          .then((response) => {
             console.log('All orgs query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved orgs')

               let orgs = response.data.orgs
               let orgSelected = null

               if (orgs) {
                 for (let i = 1; i <= orgs.length; i++) {
                   let cta = 'Request Access'
                   if (orgs[i - 1].waitlist) {
                     cta = 'Join Waitlist'
                   } else if (orgs[i - 1].isOpen) {
                     cta = 'Join Workspace'
                   }
                   orgs[i - 1]['cta'] = cta

                   if (this.props.orgCode === orgs[i - 1].orgCode) {
                     orgSelected = orgs[i - 1]
                   }
                 }
               }

               const filteredOrgs = orgs
               if (this.props.orgCode && orgSelected.signUpFieldsRequired && orgSelected.signUpFieldsRequired.length > 0) {

                 this.setState({ orgs, filteredOrgs, isAnimating: false })
                 this.openSignUpFieldsModal(orgSelected)
               } else {
                  this.setState({ orgs, filteredOrgs, isAnimating: false })
               }

               Axios.get('/api/orgs', { params: { isFeatured: true }})
               .then((response) => {
                  console.log('Featured orgs query attempted', response.data);

                  if (response.data.success) {
                    console.log('successfully retrieved featured orgs')

                    let featuredOrgs = response.data.orgs
                    this.setState({ featuredOrgs })

                  } else {
                    console.log('no featured orgs found', response.data.message)
                  }

               }).catch((error) => {
                    console.log('Featured orgs query did not work', error);
               });

             } else {
               console.log('no orgs found', response.data.message)
             }

          }).catch((error) => {
               console.log('Orgs query did not work', error);
          });

          if (accountCode) {
            Axios.get('/api/account', { params: { accountCode } })
            .then((response) => {
              console.log('Account info query attempted in employer dashboard', response.data);

              if (response.data.success) {
                console.log('account info query worked in sub settings')

                const employerName = response.data.accountInfo.employerName
                const employerLogo = response.data.accountInfo.employerLogoURI
                const sharePartners = response.data.accountInfo.sharePartners

                this.setState({ employerName, employerLogo, sharePartners });

                Axios.get('/api/orgs', { params: { orgCodes: sharePartners } })
                .then((response) => {
                  console.log('Org info query attempted', response.data);

                  if (response.data.success) {
                    console.log('org info query worked')

                    const myOrgObjects = response.data.orgs
                    this.setState({ myOrgObjects })

                  } else {
                    console.log('org info query did not work', response.data.message)

                  }

                }).catch((error) => {
                    console.log('Org info query did not work for some reason', error);
                });

              }

            }).catch((error) => {
              console.log('Account info query did not work for some reason', error);
            });
          }
        }

        const fetchDetailsURL = '/api/users/profile/details/' + email
        Axios.get(fetchDetailsURL)
        .then((response) => {
          if (response.data) {

            let myOrgs = response.data.user.myOrgs
            const joinRequests = response.data.user.joinRequests

            const workAuthorization = response.data.user.workAuthorization
            const zipcode = response.data.user.zipcode
            const dateOfBirth = response.data.user.dateOfBirth
            const gender = response.data.user.gender
            const race = response.data.user.race
            const races = response.data.user.races
            const selfDescribedRace = response.data.user.selfDescribedRace
            const address = response.data.user.address
            const phoneNumber = response.data.user.phoneNumber
            const alternativePhoneNumber = response.data.user.alternativePhoneNumber
            const alternativeEmail = response.data.user.alternativeEmail
            const numberOfMembers = response.data.user.numberOfMembers
            const householdIncome = response.data.user.householdIncome
            const fosterYouth = response.data.user.fosterYouth
            const homeless = response.data.user.homeless
            const incarcerated = response.data.user.incarcerated
            const adversityList = response.data.user.adversityList

            const pictureURL = response.data.user.pictureURL
            const education = response.data.user.education
            const educationStatus = response.data.user.educationStatus

            const referrerName = response.data.user.referrerName
            const referrerEmail = response.data.user.referrerEmail
            const referrerOrg = response.data.user.referrerOrg

            this.setState({ myOrgs, joinRequests, workAuthorization, zipcode, dateOfBirth, gender, race, races, selfDescribedRace,
              address, phoneNumber, alternativePhoneNumber, alternativeEmail, numberOfMembers,
              householdIncome, fosterYouth, homeless, incarcerated, adversityList, pictureURL, education, educationStatus,
              referrerName, referrerEmail, referrerOrg
            })

            if (!window.location.pathname.includes('/employers')) {
              Axios.get('/api/orgs', { params: { orgCodes: myOrgs } })
              .then((response) => {
                console.log('Org info query attempted', response.data);

                if (response.data.success) {
                  console.log('org info query worked')

                  const myOrgObjects = response.data.orgs
                  this.setState({ myOrgObjects })

                } else {
                  console.log('org info query did not work', response.data.message)

                }

              }).catch((error) => {
                  console.log('Org info query did not work for some reason', error);
              });
            }

          }
        }).catch((error) => {
            console.log('Profile pic fetch did not work', error);
        });
      }
    }

    submitSignUpFields() {
      console.log('submitSignUpFields called')

      if (this.state.orgSelected.signUpFieldsRequired && this.state.orgSelected.signUpFieldsRequired.length > 0) {
        for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
          console.log('l1')
          const item = this.state.orgSelected.signUpFieldsRequired[i - 1]

          if (item.required) {
            if (item.questionType === 'Multiple Answer' && (!this.state[item.shorthand] || this.state[item.shorthand].length === 0)) {
              return this.setState({ errorMessage: 'Please add answer(s) for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
            } else if (!item.shorthand.includes("|") && (!this.state[item.shorthand] || !this.state[item.shorthand] === '')) {
              return this.setState({ errorMessage: 'Please add an answer for ' + item.name })
            } else if (item.shorthand.includes("|") && (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0)) {
              return this.setState({ errorMessage: 'Please add at least one educational experience' })
            } else if (item.shorthand.includes("|")) {
              console.log('l3')
              if (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0) {
                return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
              } else if (!this.state[item.shorthand.split("|")[0]][0][item.shorthand.split("|")[1]]) {
                return this.setState({ errorMessage: 'Please add answer(s) for each of the education fields' })
              }
              console.log('l4')
            }
          }
        }
      }

      const dateOfBirth = this.state.dateOfBirth
      const gender = this.state.gender
      const race = this.state.race
      const races = this.state.races
      const selfDescribedRace = this.state.selfDescribedRace
      const address = this.state.address
      const zipcode = this.state.zipcode
      const phoneNumber = this.state.phoneNumber
      const alternativePhoneNumber = this.state.alternativePhoneNumber
      const alternativeEmail = this.state.alternativeEmail
      const numberOfMembers = this.state.numberOfMembers
      const householdIncome = this.state.householdIncome
      const fosterYouth = this.state.fosterYouth
      const homeless = this.state.homeless
      const incarcerated = this.state.incarcerated
      const workAuthorization = this.state.workAuthorization
      const adversityList = this.state.adversityList
      const educationStatus = this.state.educationStatus

      let education = this.state.education
      // if (this.state.education && this.state.education[0]) {
      //   education[0]['name'] = this.state.education.name
      //   education[0]['endDate'] = this.state.education.endDate
      //   education[0]['major'] = this.state.education.major
      // }
      const referrerName = this.state.referrerName
      const referrerEmail = this.state.referrerEmail
      const referrerOrg = this.state.referrerOrg

      const signUpFields = {
        dateOfBirth, gender, race, races, selfDescribedRace, address, zipcode, phoneNumber, alternativePhoneNumber,
        alternativeEmail, numberOfMembers, householdIncome, workAuthorization,
        fosterYouth, homeless, incarcerated, adversityList, education, educationStatus,
        referrerName, referrerEmail, referrerOrg
      }

      console.log('request submitted', education)
      this.submitRequest(null, this.state.orgSelected, signUpFields, false)
    }

    async submitRequest(e, value, passedSignUpFields, fromButton) {
      console.log('submitRequest called')

      if (fromButton) {
        e.stopPropagation()
        e.preventDefault()
      }

      let cta = 'Join Workspace'
      if (!value.isOpen) {
        cta = 'Request Access'
      }

      if (window.location.pathname.includes('/employers')) {

        if (this.props.addOrgToPosting) {
          this.props.addOrgToPosting(value)
        } else {
          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields, this.state.accountCode, this.state.employerName, this.state.sharePartners)
          // console.log('returnedValue: ', returnedValue)
          if (returnedValue.successs) {
            let sharePartners = this.state.sharePartners
            if (returnedValue.success) {
              if (sharePartners) {
                sharePartners.push(value.orgCode)
              } else {
                sharePartners = [value.orgCode]
              }
            }

            // localStorage.setItem('activeOrg', value.orgCode)
            localStorage.setItem('sharePartners', JSON.stringify(sharePartners))

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, sharePartners })
            this.closeModal()

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }

      } else if (cta === 'Join Workspace') {
        // console.log('82', value.signUpFields)
        if (value.signUpFieldsRequired && value.signUpFieldsRequired.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let myOrgs = this.state.myOrgs
              if (returnedValue.success) {
                if (myOrgs) {
                  myOrgs.push(value.orgCode)
                } else {
                  myOrgs = [value.orgCode]
                }
              }

              localStorage.setItem('activeOrg', value.orgCode)
              localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
              localStorage.setItem('orgName', value.orgName)
              localStorage.setItem('orgLogo', value.webLogoURIColor)

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs, modalIsOpen: false, showSignUpFields: false })
              this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)

              // orgFocus, orgName, orgLogo

              if (this.props.opportunityId) {
                this.props.navigate('/app/opportunities/' + this.props.opportunityId)
              }
            } else {
              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }
              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            // this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: true, showOrgDetails: false })

            this.openSignUpFieldsModal(value)
          }

        } else {
          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue.success) {
            let myOrgs = this.state.myOrgs
            if (returnedValue.success) {
              if (myOrgs) {
                myOrgs.push(value.orgCode)
              } else {
                myOrgs = [value.orgCode]
              }
            }

            localStorage.setItem('activeOrg', value.orgCode)
            localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
            localStorage.setItem('orgName', value.orgName)
            localStorage.setItem('orgLogo', value.webLogoURIColor)

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs })
            this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }

      } else {
        console.log('request access')

        if (value.signUpFields && value.signUpFields.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let joinRequests = this.state.joinRequests
              if (returnedValue.success) {
                if (joinRequests) {
                  joinRequests.push(value.orgCode)
                } else {
                  joinRequests = [value.orgCode]
                }
              }

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests, modalIsOpen: false, showSignUpFields: false })
            } else {
              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }
              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: false })
          }

        } else {

          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null, null, null, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue.success) {
            let joinRequests = this.state.joinRequests
            if (returnedValue.success) {
              if (joinRequests) {
                joinRequests.push(value.orgCode)
              } else {
                joinRequests = [value.orgCode]
              }
            }

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests })

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showOrgDetails: false, showSignUpFields: false, orgSelected: null, showQuestion: false })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value, this.state.education)

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, isAnimating: true })
        this.searchOrgs(event.target.value)
      } else if (event.target.name.includes('filter|')) {

        let itemFilters = this.state.itemFilters
        let index = 0

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name === field) {
            itemFilters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        //reset everything
        let searchString = ''
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name !== field) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }

        this.setState({ itemFilters, isAnimating: true, searchString })
        this.filterResults(this.state.searchString, event.target.value, itemFilters, index, false, null)
      } else if (event.target.name.includes('education|')) {
        const name = event.target.name.split("|")[1]

        let education = this.state.education
        if (education && education[0]) {
          education[0][name] = event.target.value
        } else {
          education = [{}]
          education[0][name] = event.target.value
        }

        this.setState({ education })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchOrgs(orgName) {
      console.log('searchOrgs ', orgName)

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/org/search', { params: { orgName, resLimit: 50 } })
        .then((response) => {
          console.log('Org search query attempted', response.data);

          if (response.data.success) {
            console.log('org search query worked')

            const filteredOrgs = response.data.organizations
            self.setState({ filteredOrgs, isAnimating: false });

          } else {
            console.log('organization search query did not work', response.data.message)
            self.setState({ isAnimating: false, filteredOrgs: self.state.orgs })
          }

        }).catch((error) => {
            console.log('Organization search query did not work for some reason', error);
            self.setState({ isAnimating: false, filteredOrgs: self.state.orgs })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    filterResults(searchString, filterString, filters, index, search, type) {
      console.log('filterResults called', searchString, filterString, filters, index, search, type)

      const defaultFilterOption = this.state.defaultFilterOption
      const orgs = this.state.orgs
      const sharePartners = this.state.sharePartners

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        // console.log('first matchScore: ', postings[0].matchScore, postings[0].title, postings[0].name)

        Axios.put('/api/orgs/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, orgs, sharePartners })
        .then((response) => {
          console.log('Org filter query attempted', response.data);

          if (response.data.success) {
            console.log('org filter query worked')

            const filterCriteriaArray = response.data.filterCriteriaArray

            const filteredOrgs = response.data.orgs
            self.setState({ filteredOrgs, isAnimating: false, filterCriteriaArray })

          } else {
            console.log('org filter query did not work', response.data.message)
            self.setState({ isAnimating: false })
          }

        }).catch((error) => {
            console.log('Org filter query did not work for some reason', error);
            self.setState({ isAnimating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    renderSignUpFields() {
      console.log('renderSignUpFields called')

      let rows = []
      let spaceCounter = 0
      for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
        const value = this.state.orgSelected.signUpFieldsRequired[i - 1]
        const index = i - 1

        let showQuestion = true
        if (value.questionType !== 'Multiple Answer') {
          // console.log('in it: ', value.name, spaceCounter)
          if (value.rule) {
            if (value.rule.name === 'races' && this.state.races && this.state.races.includes(value.rule.value)) {
              spaceCounter = spaceCounter + 1
            } else {
              showQuestion = false
            }
          } else {
            spaceCounter = spaceCounter + 1
          }
        }

        rows.push(
          <div key={'renderSignUpFields' + index}>
            {(showQuestion) && (
              <div>
                <div className={(value.questionType === 'Multiple Answer') ? "full-width" : "container-left"}>
                  {this.renderSignUpField(value, index)}
                </div>
                {(value.questionType === 'Multiple Answer' || spaceCounter % 2 === 0) && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
                {(this.state.orgSelected.signUpFieldsRequired[i] && this.state.orgSelected.signUpFieldsRequired[i].questionType === 'Multiple Answer') && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}

                {/*
                {(index % 2 === 0) ? (
                  <div className="container-left">
                    {this.renderSignUpField(value, index)}
                  </div>
                ) : (
                  <div className="container-right">
                    {this.renderSignUpField(value, index)}
                  </div>
                )}

                {(index % 2 === 1) && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}*/}
              </div>
            )}
          </div>
        )
      }

      return rows
    }

    renderSignUpField(value, index) {
      console.log('renderSignUpField called')

      return (
        <div key="signUpField">
          <label className="profile-label">{value.name}{(value.required) && <label className="error-color bold-text">*</label>}</label>
          {(value.questionType === 'Date') && (
            <input type="date" className="date-picker white-background" name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Short Answer') && (
            <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Multiple Choice') && (
            <select name={value.shorthand} className="dropdown" value={this.state[value.shorthand]} onChange={this.formChangeHandler}>
                {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
            </select>
          )}
          {(value.questionType === 'Multiple Answer') && (
            <div>
              {value.answerChoices.map((value2, optionIndex) =>
                <div key={value2 + optionIndex}>
                  <div className="top-margin-5 right-padding">
                    {(this.state[value.shorthand] && this.state[value.shorthand].includes(value2)) ? (
                      <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2 white-text">{value2}</p>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2">{value2}</p>
                          </div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      )
    }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let items = this.state[value.shorthand]
      if (items) {
        if (items.includes(value2)) {
          const index = items.indexOf(value2)
          items.splice(index, 1)
        } else {
          items.push(value2)
        }

      } else {
        items = [value2]
      }

      this.setState({ [value.shorthand]: items })

    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        // let sorters = []
        //
        // if (this.state.showV2App) {
        //   if (this.state.subNavSelected === 'Events') {
        //     // events
        //     sorters = this.state.eventSorters
        //   } else if (this.state.subNavSelected === 'Projects') {
        //     // projects
        //     sorters = this.state.projectSorters
        //   } else if (this.state.subNavSelected === 'Work') {
        //     // work
        //     sorters = this.state.workSorters
        //   } else if (this.state.subNavSelected === 'All') {
        //     sorters = this.state.allSorters
        //   }
        // } else {
        //   if (this.state.viewIndex === 1) {
        //     // events
        //     sorters = this.state.eventSorters
        //   } else if (this.state.viewIndex === 5) {
        //     // projects
        //     sorters = this.state.projectSorters
        //   } else if (this.state.viewIndex === 7) {
        //     // work
        //     sorters = this.state.workSorters
        //   } else if (this.state.viewIndex === 8) {
        //     // work
        //     sorters = this.state.allSorters
        //   }
        // }
        //
        // if (sorters) {
        //
        //   let rows = []
        //   for (let i = 1; i <= sorters.length; i++) {
        //     rows.push(
        //       <div key={sorters[i - 1] + i.toString()}>
        //         <div>
        //           <div className="float-left row-10 right-padding-20">
        //             <div className="float-left light-border">
        //               <div className="float-left right-padding-5 left-padding nowrap top-margin-negative-2">
        //                 <div className="spacer" />
        //                 <p className="standard-color">{sorters[i - 1].name}</p>
        //               </div>
        //               <div className="float-left">
        //                 <select name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} onChange={this.formChangeHandler} className="filter-select">
        //                   {sorters[i - 1].options.map(value =>
        //                     <option key={value} value={value}>{value}</option>
        //                   )}
        //                 </select>
        //               </div>
        //               <div className="dropdown-arrow-container">
        //                 <img src={dropdownArrow} alt="Compass dropdown arrow icon"/>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     )
        //   }
        //
        //   return rows
        //
        // }
      }
    }

    openSignUpFieldsModal(orgSelected) {
      console.log('openSignUpFieldsModal called')

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const degreeOptions = response.data.workOptions[0].degreeOptions.slice(1,response.data.workOptions[0].degreeOptions.lengh)

          const educationStatusOptions = degreeOptions.concat(['Not currently enrolled in school'])

          let educationDateOptions = []

          const currentMonth = new Date().getMonth()
          const currentYear = new Date().getFullYear()

          let numberOfYears = 25
          let educationBump = 5
          let month = ''
          let year = currentYear - numberOfYears

          // console.log('show me current stuff', currentMonth, currentYear)
          for (let i = 1; i <= ((numberOfYears + educationBump) * 12); i++) {
            // console.log('show me stuff', i, (i + currentMonth + 1) % 12)
            if ((i + currentMonth + 1) % 12 === 2) {
              month = 'January'
            } else if ((i + currentMonth + 1) % 12 === 3) {
              month = 'February'
            } else if ((i + currentMonth + 1) % 12 === 4) {
              month = 'March'
            } else if ((i + currentMonth + 1) % 12 === 5) {
              month = 'April'
            } else if ((i + currentMonth + 1) % 12 === 6) {
              month = 'May'
            } else if ((i + currentMonth + 1) % 12 === 7) {
              month = 'June'
            } else if ((i + currentMonth + 1) % 12 === 8) {
              month = 'July'
            } else if ((i + currentMonth + 1) % 12 === 9) {
              month = 'August'
            } else if ((i + currentMonth + 1) % 12 === 10) {
              month = 'September'
            } else if ((i + currentMonth + 1) % 12 === 11) {
              month = 'October'
            } else if ((i + currentMonth + 1) % 12 === 0) {
              month = 'November'
            } else if ((i + currentMonth + 1) % 12 === 1) {
              month = 'December'
            }

            if (month === 'January') {
              year = year + 1
            }

            // dateOptions.push({ value: month + ' ' + year})
            if (i <= (numberOfYears * 12)) {
              // dateOptions.push({ value: month + ' ' + year})
            }
            educationDateOptions.push(month + ' ' + year)

          }

          const raceOptions = response.data.workOptions[0].raceOptions
          const genderOptions = response.data.workOptions[0].genderOptions

          const householdIncomeOptions = response.data.workOptions[0].lowIncomeOptions
          const adversityListOptions = response.data.workOptions[0].adversityListOptions
          const numberOfMembersOptions = response.data.workOptions[0].numberOfMembersOptions
          const workAuthorizationOptions = response.data.workOptions[0].workAuthorizationOptions
          // console.log('show educationDateOptions: ', educationDateOptions)
          const workOptions = {
            raceOptions, genderOptions, degreeOptions, householdIncomeOptions, adversityListOptions,
            numberOfMembersOptions, workAuthorizationOptions,
            educationStatusOptions, educationDateOptions
          }

          if (orgSelected.signUpFieldsRequired && orgSelected.signUpFieldsRequired.length > 0) {
            let signUpFieldsRequired = orgSelected.signUpFieldsRequired
            for (let i = 1; i <= signUpFieldsRequired.length; i++) {
              if (signUpFieldsRequired[i - 1].answerChoices && signUpFieldsRequired[i - 1].answerChoices.length === 1) {
                signUpFieldsRequired[i - 1].answerChoices = workOptions[signUpFieldsRequired[i - 1].answerChoices[0]]
              }
            }
          }

          this.setState({ modalIsOpen: true, orgSelected, showSignUpFields: true, showOrgDetails: false })

        } else {
          console.log('no workOptions found')

        }
      }).catch((error) => {
          console.log('query for work options did not work', error);
      })
    }

    orgClicked(index, type) {
      console.log('orgClicked clicked', index, type, this.state.roleName)

      let isEmployer = false
      if (this.state.roleName === 'Employer' || this.state.roleName === 'Employer Representative') {
        isEmployer = true
      }
      if (window.location.pathname.includes('/employers') || isEmployer) {
        // console.log('in employers: ', this.state.activeOrg, this.state.sharePartners[index])
        if (this.state.activeOrg !== this.state.myOrgObjects[index].orgCode) {
          let activeOrg = this.state.myOrgObjects[index].orgCode
          // if (this.state.activeOrg !== this.state.sharePartners[index]) {
          //   activeOrg = this.state.sharePartners[index]
          // }

          this.setState({ activeOrg })

          let orgName = this.state.myOrgObjects[index].orgName
          let orgLogo = this.state.myOrgObjects[index].webLogoURIColor

          localStorage.setItem('activeOrg', activeOrg)
          localStorage.setItem('orgName', orgName)
          localStorage.setItem('orgLogo', orgLogo)

          const accountCode = this.state.accountCode
          const updatedAt = new Date()

          Axios.post('/api/account/update', {
            accountCode, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Account update worked', response.data);
              this.setState({

                serverPostSuccess: true,
                serverSuccessMessage: 'Account information saved successfully!', modalIsOpen: false
              })

              this.props.passOrgs(activeOrg)

            } else {
              console.error('there was an error updating the account info');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Account info save did not work', error);
          });
        }

      } else {

        // let orgOptions = this.state.myOrgs
        // if (type === 'all') {
        //   orgOptions = this.state.myOrgs
        // } else if (type === 'academies') {
        //   orgOptions = this.state.academyCodes
        // }
        // console.log('compare the two: ', this.state.activeOrg, orgOptions, index, orgOptions[index])

        let activeOrg = ''
        if (this.state.activeOrg !== this.state.myOrgObjects[index].orgCode) {
          activeOrg = this.state.myOrgObjects[index].orgCode

          this.setState({ activeOrg })

          const orgName = this.state.myOrgObjects[index].orgName
          const orgLogo = this.state.myOrgObjects[index].webLogoURIColor

          localStorage.setItem('activeOrg', activeOrg)
          localStorage.setItem('orgName', orgName)
          localStorage.setItem('orgLogo', orgLogo)

          const emailId = this.state.emailId
          const updatedAt = new Date()

          console.log('we rollin', emailId, activeOrg)

          Axios.post('/api/users/profile/details', {
            emailId, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('User update worked', response.data);

              //update the orgFocus
              Axios.get('/api/org', { params: { orgCode: activeOrg } })
              .then((response) => {
                console.log('Org query attempted', response.data);

                 if (response.data.success) {
                   console.log('successfully retrieved org')

                   const orgFocus = response.data.orgInfo.orgFocus
                   const studentAliasSingular = response.data.orgInfo.studentAliasSingular
                   const studentAliasPlural = response.data.orgInfo.studentAliasPlural
                   const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
                   const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

                   localStorage.setItem('orgFocus', orgFocus)
                   localStorage.setItem('studentAliasSingular', studentAliasSingular)
                   localStorage.setItem('studentAliasPlural', studentAliasPlural)
                   localStorage.setItem('mentorAliasSingular', mentorAliasSingular)
                   localStorage.setItem('mentorAliasPlural', mentorAliasPlural)

                   const serverPostSuccess = true
                   const serverSuccessMessage = 'Active org successfully changed and saved!'

                   this.setState({ orgFocus, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                   this.props.passOrgs(activeOrg)

                 } else {
                   console.log('no org data found', response.data.message)
                 }

              }).catch((error) => {
                 console.log('Org query did not work', error);
                 this.setState({ serverErrorMessage: 'No org found'})
              });

            } else {
              console.error('there was an error saving the new active org');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Saving the active org did not work', error);
          });
        }
      }
    }

    removeOrg(index,type) {
      console.log('removeOrg called', this.state.selectedOrg, index, type)

      let isEmployer = false
      if (this.state.roleName === 'Employer' || this.state.roleName === 'Employer Representative') {
        isEmployer = true
      }
      if (window.location.pathname.includes('/employers') || isEmployer) {
        // console.log('in employers: ', this.state.activeOrg, this.state.sharePartners[index])
        // let activeOrg = this.state.sharePartners[index]

        if (this.state.sharePartners.length > 1) {
          // this.setState({ activeOrg })
          // localStorage.setItem('activeOrg', activeOrg)

          const accountCode = this.state.accountCode
          const updatedAt = new Date()

          Axios.post('/api/account/update', {
            accountCode, activeOrg: this.state.myOrgObjects[index].orgCode, removeOrg: true, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Account update worked', response.data);

              let changeActiveOrg = false
              if (this.state.myOrgObjects[index].orgCode === this.state.activeOrg) {
                changeActiveOrg = true
              }

              let sharePartners = this.state.sharePartners
              let removeIndex = null
              for (let i = 1; i <= this.state.sharePartners.length; i++) {
                if (this.state.sharePartners[i - 1] === this.state.myOrgObjects[index].orgCode) {
                  removeIndex = i - 1
                }
              }

              sharePartners.splice(removeIndex,1)
              localStorage.setItem('sharePartners', JSON.stringify(sharePartners))

              let myOrgObjects = this.state.myOrgObjects
              myOrgObjects.splice(index,1)

              const serverPostSuccess = true
              const serverSuccessMessage = 'Org successfully removed!'

              if (changeActiveOrg) {
                let activeOrg = sharePartners[0]
                localStorage.setItem('activeOrg', activeOrg)
                localStorage.setItem('orgName', myOrgObjects[0].orgName)
                localStorage.setItem('orgLogo', myOrgObjects[0].webLogoURIColor)

                this.setState({ activeOrg, sharePartners, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                this.props.passOrgs(activeOrg)
              } else {
                this.setState({ sharePartners, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
              }

            } else {
              console.error('there was an error updating the account info');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Account info save did not work', error);
          });
        } else {
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
          })
        }

      } else {

        // let orgOptions = []
        // if (type === 'all') {
        //   orgOptions = this.state.myOrgs
        // } else if (type === 'academies') {
        //   orgOptions = this.state.academyCodes
        // }

        const selectedOrg = this.state.myOrgObjects[index].orgCode

        if (this.state.myOrgObjects.length > 1) {

          const emailId = this.state.emailId
          const updatedAt = new Date()

          Axios.post('/api/users/profile/details', {
            emailId, activeOrg: selectedOrg, removeOrg: true, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('User update worked', response.data);

              if (type === 'all') {

                let changeActiveOrg = false
                if (selectedOrg === this.state.activeOrg) {
                  changeActiveOrg = true
                }

                let myOrgs = this.state.myOrgs
                let removeIndex = null
                for (let i = 1; i <= this.state.myOrgs.length; i++) {
                  if (this.state.myOrgs[i - 1] === selectedOrg) {
                    removeIndex = i - 1
                  }
                }
                myOrgs.splice(removeIndex,1)
                localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

                let myOrgObjects = this.state.myOrgObjects
                myOrgObjects.splice(index,1)

                const serverPostSuccess = true
                const serverSuccessMessage = 'Org successfully removed!'

                if (changeActiveOrg) {
                  const activeOrg = myOrgs[0]
                  this.setState({ activeOrg, myOrgs, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                  localStorage.setItem('activeOrg', activeOrg)
                  localStorage.setItem('orgName', myOrgObjects[0].orgName)
                  localStorage.setItem('orgLogo', myOrgObjects[0].webLogoURIColor)

                  localStorage.removeItem('orgFocus')

                  localStorage.removeItem('studentAliasSingular')
                  localStorage.removeItem('studentAliasPlural')
                  localStorage.removeItem('mentorAliasSingular')
                  localStorage.removeItem('mentorAliasPlural')

                  this.props.passOrgs(activeOrg)
                } else {
                  this.setState({ myOrgs, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                }

              } else if (type === 'academies') {

                let changeActiveOrg = false
                if (this.state.academyCodes[index] === this.state.activeOrg) {
                  changeActiveOrg = true
                }

                let academyCodes = this.state.academyCodes
                academyCodes.splice(index,1)

                const serverPostSuccess = true
                const serverSuccessMessage = 'Org successfully removed!'

                if (changeActiveOrg) {
                  const activeOrg = academyCodes[0]
                  this.setState({ activeOrg, academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                  localStorage.setItem('activeOrg', activeOrg)
                  localStorage.removeItem('orgFocus')

                  localStorage.removeItem('studentAliasSingular')
                  localStorage.removeItem('studentAliasPlural')
                  localStorage.removeItem('mentorAliasSingular')
                  localStorage.removeItem('mentorAliasPlural')

                  this.props.passOrgs(activeOrg)
                } else {
                  this.setState({ academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                }
              }

            } else {
              console.error('there was an error saving the new active org');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Saving the active org did not work', error);
          });
        } else {
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
          })
        }
      }
    }

    render() {

      let cardClass = "relative-column-70 padding-five-percent-left"
      if (window.location.pathname.includes('/walkthrough') || this.props.modalIsOpen) {
        cardClass = ""
      }

      return (
          <div>
            <div className={(window.innerWidth > 768) ? cardClass : "full-width"}>
              <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                <div className="full-width height-5 primary-background" />
                <div className="padding-40">
                  <div className="row-10">
                    <div>
                      <div className="fixed-column-40 height-40">
                      </div>
                      <div className="calc-column-offset-80">
                        <h2>{(window.location.pathname.includes('/employers')) ? "Manage Training Partners" : "Add & Manage Communities"}</h2>
                      </div>
                      <div className="fixed-column-40">
                        <button className="background-button float-right" onClick={(e) => this.setState({ modalIsOpen: true, showQuestion: true })}>
                          <img src={askQuestionIconDark} className="image-auto-30 pin-right" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Don't see the provider you prefer? Send them to us!" />
                        </button>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>
                      <div className="clear" />
                    </div>

                    {(window.location.pathname.includes('/employers')) ? (
                      <p className="subtitle-container">
                          Enable training partners below so that they can recruit, engage, train, and refer candidates for you.
                      </p>
                    ) : (
                      <div>
                        <p className="width-70-percent center-text center-horizontally">Communities are dedicated portals or workspaces of educational, workforce, staffing, and other career development institutions. Your core profile transfers, but events, projects, work opportunities, pathways, and members will differ.</p>

                        <p className="width-70-percent center-text center-horizontally top-margin-20">Once you join a workspace, their logo will appear on the top-left of your screen.</p>

                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={(window.location.pathname.includes('/walkthrough')) ? "full-width" : "center-horizontally max-width-1400 top-margin-20"}>
                <div className="top-margin-5 bottom-margin-15 row-5 horizontal-padding-4 display-inline full-width white-background clear-margin medium-shadow">
                  <div className="display-inline float-left row-5 horizontal-padding-7">
                    <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                  </div>
                  <div className="filter-search-container calc-column-offset-100-static">
                    <input type="text" className="text-field clear-border" placeholder={"Search 1,000+ organizations..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="search-icon-container top-margin-negative-3">
                    <button type="button" className="background-button" onClick={(this.state.showingSearchBar) ? () => this.setState({ showingSearchBar: false }) : () => this.setState({ showingSearchBar: true })}>
                      <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                    </button>
                  </div>
                </div>
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color full-width center-text row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color full-width center-text row-5">{this.state.successMessage}</p>}

                {(this.state.showingSearchBar) && (
                  <div className="row box-container-1 white-background medium-shadow">
                    <div>
                      <p className="heading-text-4">Filter Results</p>
                      <div className="half-spacer" />

                      {(this.renderManipulators('filter'))}

                      <div className="clear" />
                      <div className="spacer" />
                    </div>
                  </div>
                )}
              </div>

              <div className={(window.location.pathname.includes('/walkthrough')) ? "full-width row-20" : "center-horizontally max-width-1400 row-20"}>
                <div>
                  {(this.state.isAnimating) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <div className="super-spacer" />

                        <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Loading...</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      {this.state.filteredOrgs && this.state.filteredOrgs.map((value, index) =>
                        <div key={index}>
                          <div className="relative-column-32 center-text bottom-margin">
                            <div className="card-clear-padding">
                              <Link className="background-button full-space standard-color" to={(window.location.pathname.includes('/employers')) ? "/employers/" + this.state.accountCode + '/orgs/' + value.orgCode : "/app/orgs/" + value.orgCode}>
                                <div className="padding-15">
                                  <div className="bottom-padding">
                                    <div>
                                      {(value.isFeatured) ? (
                                        <div className="fixed-column-40">
                                          <img src={featuredIconBlue} alt="GC" className="image-30-auto center-horizontally" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This org is featured"/>
                                        </div>
                                      ) : (
                                        <div className="fixed-column-40 height-40">
                                        </div>
                                      )}
                                      <div className="calc-column-offset-80">
                                        <img src={(value.webLogoURIColor) ? value.webLogoURIColor : industryIconDark} alt="GC" className="image-90-auto center-horizontally"/>
                                      </div>
                                      <div className="fixed-column-40 height-40">
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <ReactTooltip id="tooltip-placeholder-id" />
                                  </div>
                                  <h4>{value.orgName}</h4>
                                  <p className="limited-description description-text-2 top-padding">{value.orgDescription}</p>
                                  <p className="description-text-2 top-padding description-text-color">{value.region}</p>
                                  <p className="description-text-2 top-padding description-text-color">{value.thirdTitle}</p>

                                  {(window.location.pathname.includes('/employers')) ? (
                                    <div className="bottom-margin-20 full-width center-text">
                                      <ul className="horizontal-list center-text flex-center">
                                        <li><img src={(value.employerRating && value.employerRating.avgRating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.employerRating && value.employerRating.avgRating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.employerRating && value.employerRating.avgRating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.employerRating && value.employerRating.avgRating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.employerRating && value.employerRating.avgRating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      </ul>

                                      <div className="top-margin-5">
                                        <p className="full-width description-text-3 center-text"><label className="bold-text">{(value.employerRating && value.employerRating.ratingCount) ? value.employerRating.ratingCount : 0}</label> ratings</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="bottom-margin-20 full-width center-text">
                                      <ul className="horizontal-list center-text flex-center">
                                        <li><img src={(value.careerSeekerRating && value.careerSeekerRating.avgRating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.careerSeekerRating && value.careerSeekerRating.avgRating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.careerSeekerRating && value.careerSeekerRating.avgRating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.careerSeekerRating && value.careerSeekerRating.avgRating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value.careerSeekerRating && value.careerSeekerRating.avgRating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      </ul>

                                      <div className="top-margin-5">
                                        <p className="full-width description-text-3 center-text"><label className="bold-text">{(value.careerSeekerRating && value.careerSeekerRating.ratingCount) ? value.careerSeekerRating.ratingCount : 0}</label> ratings</p>
                                      </div>
                                    </div>
                                  )}

                                  {(window.location.pathname.includes('/employers') && this.state.sharePartners && this.state.sharePartners.includes(value.orgCode)) ? (
                                    <div className="bottom-padding-10">
                                      <button className={"btn btn-squarish-white"} disabled={true} onClick={(e) => this.submitRequest(e, value, null, true)}>
                                        <div>
                                          <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                            <img src={checkmarkIcon} alt="GC" className="image-auto-12 center-horizontally"/>
                                          </div>
                                          <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                            <p>Added</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="bottom-padding-10">
                                      {(this.state.myOrgs && this.state.myOrgs.includes(value.orgCode)) ? (
                                        <div>
                                          <button className={"btn btn-squarish-white"} disabled={true} onClick={(e) => this.submitRequest(e, value, null, true)}>
                                            <div>
                                              <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                                <img src={checkmarkIcon} alt="GC" className="image-auto-12 center-horizontally"/>
                                              </div>
                                              <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                                <p>Joined</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          </button>
                                        </div>
                                      ) : (
                                        <div>
                                          {(value.isOpen || window.location.pathname.includes('/employers')) ? (
                                            <div className="bottom-padding-10">
                                              {(window.location.pathname.includes('/employers')) ? (
                                                <button className={"btn btn-squarish"} disabled={false} onClick={(e) => this.submitRequest(e, value, null, true)}>
                                                  <div>
                                                    <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                                      <img src={addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                                    </div>
                                                    <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                                      <p>Add</p>
                                                    </div>
                                                    <div className="clear" />
                                                  </div>
                                                </button>
                                              ) : (
                                                <button className={"btn btn-squarish"} disabled={false} onClick={(e) => this.submitRequest(e, value, null, true)}>
                                                  <div>
                                                    <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                                      <img src={addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                                    </div>
                                                    <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                                      <p>Add</p>
                                                    </div>
                                                    <div className="clear" />
                                                  </div>
                                                </button>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="bottom-padding-10">
                                              <button className={(this.state.joinRequests && this.state.joinRequests.includes(value.orgCode)) ? "btn btn-squarish-white" : "btn btn-squarish"} disabled={(this.state.joinRequests && this.state.joinRequests.includes(value.orgCode)) ? true : false} onClick={(e) => this.submitRequest(e, value, null, true)}>
                                                <div>
                                                  <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                                    <img src={(this.state.joinRequests && this.state.joinRequests.includes(value.orgCode)) ? timeIconBlue : addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                                  </div>
                                                  <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                                    {(this.state.joinRequests && this.state.joinRequests.includes(value.orgCode)) ? "Requested Access" : 'Request Access' }
                                                  </div>
                                                  <div className="clear" />
                                                </div>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[index] && this.state.filterCriteriaArray[index].name) && (
                                    <div className="full-width row-10">
                                      <div className="half-spacer" />
                                      <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[index].name}: {this.state.filterCriteriaArray[index].criteria}</p>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </div>
                          </div>

                          {(index % 3 < 2) && (
                            <div className="relative-column-2 height-40" />
                          )}

                          {(index % 3 === 2) && (
                            <div className="clear" />
                          )}
                        </div>
                      )}

                      <div className="clear" />

                    </div>
                  )}

                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color full-width center-text row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color full-width center-text row-5">{this.state.successMessage}</p>}

              </div>
            </div>

            {(window.innerWidth > 768 && !window.location.pathname.includes('/walkthrough') && !this.props.modalIsOpen) && (
              <div className="relative-column-30 horizontal-padding top-margin-40">
                <div className="left-padding-20">
                  {/*
                  {(showApply && !alreadyRSVPd) && (
                    <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                      <div className="full-width height-5 senary-background" />
                      <div className="padding-20">
                        <p className="heading-text-5">Actions</p>

                        <div className="spacer" /><div className="spacer" />

                        <div className="row-10">
                          {(applyButton) ? (
                            <button onClick={() => this.setState(applyButton)} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={resumeIconDark} alt="GC" className="image-auto-25" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>{applyText}</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <Link to={'/opportunities/organizations/'} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </Link>
                                </span>
                              </div>
                            </button>
                          ) : (
                            <div>
                              <Link to={applyLink} className="background-link">
                                <div className="fixed-column-35">
                                  <img src={resumeIconDark} alt="GC" className="image-auto-25" />
                                </div>
                                <div className="calc-column-offset-70 left-padding-5">
                                  <p>{applyText}</p>
                                </div>
                              </Link>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <Link to={'/opportunities/organizations/'} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="clear"/>

                          <div className="spacer" /><div className="spacer" />
                          <hr />
                          <div className="clear"/>
                          <div className="spacer" />
                        </div>

                        {(showApplyTwo) && (
                          <div className="bottom-padding">
                            <div className="row-10">
                              {(applyButton2) ? (
                                <button onClick={() => this.setState(applyButton2)} className="background-button full-width left-text cta-color">
                                  <div className="fixed-column-35">
                                    <img src={sendIcon} alt="GC" className="image-auto-25" />
                                  </div>
                                  <div className="calc-column-offset-70 left-padding-5">
                                    <p>{applyText2}</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <Link to={'/opportunities/organizations/'} className="background-link">
                                        <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                      </Link>
                                    </span>
                                  </div>
                                </button>
                              ) : (
                                <div>
                                  <a href={applyLink2} className="background-link" target="_blank" rel="noopener noreferrer">
                                    <div className="fixed-column-35">
                                      <img src={websiteIconDark} alt="GC" className="image-auto-25" />
                                    </div>
                                    <div className="calc-column-offset-70 left-padding-5">
                                      <p>{applyText2}</p>
                                    </div>
                                  </a>
                                  <div className="float-left left-padding">
                                    <span className="float-right top-margin-5">
                                      <a href={applyLink2} to={'/opportunities/organizations/'} className="background-link" target="_blank" rel="noopener noreferrer">
                                        <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="clear"/>

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="clear"/>
                            <div className="spacer" />
                          </div>
                        )}

                        {(this.props.pageSource !== 'landingPage' && this.state.selectedOpportunity && this.state.selectedOpportunity.benchmarkId && window.location.pathname.includes('/app/opportunities')) && (
                          <div className="bottom-padding">
                            <button className="background-button clear-margin full-width left-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showMatch: true })}>
                              <div className="fixed-column-35">
                                <img src={benchmarksIconDark} alt="GC" className="image-auto-25 standard-border" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>View Match</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <img src={modalIconDark} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                </span>
                              </div>
                            </button>

                            <div className="clear"/>

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="clear"/>
                            <div className="spacer" />
                          </div>
                        )}

                        {(this.state.showAbilityToRefer && !this.state.remoteAuth && this.state.selectedOpportunity && (this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Internship')) && (
                          <div className="bottom-padding">
                            <button onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={referIconDark} alt="GC" className="image-auto-25" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>Refer Someone</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <img src={modalIconDark} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                </span>
                              </div>
                            </button>
                            <div className="clear"/>
                          </div>
                        )}

                        <div className="spacer" />
                      </div>
                    </div>
                  )}*/}

                  {(this.state.myOrgObjects && this.state.myOrgObjects.length > 0) && (
                    <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                      <div className="full-width height-5 senary-background" />
                      <div className="padding-20">
                        <p className="heading-text-5">{(window.location.pathname.includes('/employers')) ? this.state.employerName + " Training Partners" : "Your Communities"}</p>

                        <div className="spacer" /><div className="half-spacer" />

                        {this.state.myOrgObjects.map((item, index) =>
                          <div key={item}>
                            <div className="bottom-padding">
                              <div className="spacer" />
                              <button className="background-button calc-column-offset-50 left-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                <div className="fixed-column-50">
                                  <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="profile-thumbnail-1" />
                                </div>
                                <div className="calc-column-offset-50 left-padding-5">
                                  <p>{item.orgName}</p>
                                </div>
                              </button>
                              {(this.state.activeOrg === item.orgCode) ? (
                                <div className="fixed-column-20">
                                  <span className="float-right">
                                    <div className="spacer"/>
                                    <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                      <img src={checkmarkIcon} alt="GC" className="image-auto-15 pin-right"/>
                                    </button>
                                  </span>
                                </div>
                              ) : (
                                <div className="fixed-column-20 height-30" />
                              )}
                              <div className="fixed-column-30 left-text">
                                <span className="float-right">
                                  <div className="spacer"/>
                                  <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                                  </button>
                                </span>
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          </div>
                        )}
                        {/*
                        {(this.state.myOrgObjects.length > 0) && (
                          <div>
                            <Link className="background-button full-width right-text" to={'/'}>
                              <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                            </Link>
                          </div>
                        )}*/}
                      </div>
                    </div>
                  )}

                  {(this.state.featuredOrgs && this.state.featuredOrgs.length > 0) && (
                    <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                      <div className="full-width height-5 senary-background" />
                      <div className="padding-20">
                        <p className="heading-text-5">Featured Communities</p>

                        <div className="spacer" /><div className="half-spacer" />

                        {this.state.featuredOrgs.map((item, index) =>
                          <div key={item}>
                            <div className="bottom-padding">
                              <div className="spacer" />
                              <Link to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/orgs/' + item.orgCode : '/app/orgs/' + item.orgCode} className="background-button calc-column-offset-50 left-text">
                                <div className="fixed-column-50">
                                  <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="profile-thumbnail-1" />
                                </div>
                                <div className="calc-column-offset-50 left-padding-5">
                                  <p>{item.orgName}</p>
                                </div>
                              </Link>
                              {(this.state.activeOrg === item.orgCode) ? (
                                <div className="fixed-column-20">
                                  <span className="float-right">
                                    <div className="spacer"/>
                                    <Link to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/orgs/' + item.orgCode : '/app/orgs/' + item.orgCode} className="background-button full-width right-text">
                                      <img src={checkmarkIcon} alt="GC" className="image-auto-15 pin-right"/>
                                    </Link>
                                  </span>
                                </div>
                              ) : (
                                <div className="fixed-column-20 height-30" />
                              )}
                              <div className="fixed-column-30 left-text">
                                <span className="float-right">
                                  <div className="spacer"/>
                                  <Link to={(window.location.pathname.includes('/employers')) ? '/employers/' + this.state.accountCode + '/orgs/' + item.orgCode : '/app/orgs/' + item.orgCode} className="background-button full-width right-text">
                                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                                  </Link>
                                </span>
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}


                </div>
              </div>
            )}
            <div className="clear" />

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

             <div key="skillAssessment" className="full-width padding-20">
              {(this.state.showSignUpFields && this.state.orgSelected) && (
                <div>
                  <p className="heading-text-2">Sign Up Fields for {this.state.orgSelected.orgName}</p>

                  <p className="row-10">{this.state.orgSelected.orgName} requires the following fields before joining their workspace</p>

                  {(this.props.opportunityId) && (
                    <p className="row-10">After you sign up to the {this.state.orgSelected.orgName} workspace, you will be able to access this opportunity.</p>
                  )}
                  <div className="spacer" />

                  {this.renderSignUpFields()}

                  <div className="clear" />

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color">{this.state.successMessage}</p>}

                  <div className="top-padding-20">
                    <button className="btn btn-primary" onClick={() => this.submitSignUpFields()}>Submit</button>
                  </div>
                </div>
              )}

              {(this.state.showQuestion) && (
                <div>
                  <h2>Request to Join a Community</h2>
                  <SubContact reason={"Guided Compass to Work with My Org / School"} reasonOptions={null} history={this.props.navigate} inModal={true} closeModal={this.closeModal} />
                </div>
              )}

              {(this.state.showOrgDetails) && (
                <div className="center-text">
                  {(this.state.selectedOrg.webLogoURI) ? (
                    <div className="full-width center-text bottom-margin-20">
                      <div className="fixed-column-30 height-30">
                        <p> </p>
                      </div>
                      <div className="calc-column-offset-60">
                        <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.selectedOrg.webLogoURIColor} />
                      </div>
                      <div className="fixed-column-30">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>
                  ) : (
                    <div className="full-width center-text">
                      <div className="fixed-column-30 height-30">
                        <p> </p>
                      </div>
                      <div className="calc-column-offset-60 height-30">
                        <p> </p>
                      </div>
                      <div className="fixed-column-30">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>
                  )}

                  <p className="heading-text-2">{this.state.selectedOrg.orgName}</p>
                  <a href={this.state.selectedOrg.orgURL} className="full-width center-text top-padding">{this.state.selectedOrg.orgURL}</a>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <p className="description-text-1">{this.state.selectedOrg.orgDescription}</p>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  {(this.state.showConfirmDelete) ? (
                    <div className="full-width center-text">
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p className="heading-text-5">Are you sure you want to remove the {this.state.selectedOrg.orgName} community?</p>

                      <p className="description-text-2 row-20">Removing the community means that you will no longer receive notifications and invited to opportunities specific to this community. If you continue to receive unwanted notifications, please email help@guidedcompass.com.</p>

                      <div className="spacer" />

                      <div className="display-inline left-padding right-padding top-margin-20">
                        <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.removeOrg(this.state.selectedIndex,this.state.selectedType)}>Yes, Remove {this.state.selectedOrg.orgName}</button>
                      </div>
                      <div className="display-inline left-padding right-padding top-margin-20">
                        <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ showConfirmDelete: false })}>Cancel</button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="full-width center-text">
                       <div className="display-inline left-padding right-padding top-margin-20">
                         <button className="btn btn-squarish" onClick={() => this.orgClicked(this.state.selectedIndex, this.state.selectedType)}>Toggle to This Community</button>
                       </div>
                       <div className="display-inline left-padding right-padding top-margin-20">
                         <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.setState({ showConfirmDelete: true })}>Remove from Your Workspaces/Communities</button>
                       </div>
                       <div className="display-inline left-padding right-padding top-margin-20">
                         <Link to={(window.location.pathname.includes('/employers')) ? "/employers/" + this.state.accountCode + "/orgs/" + this.state.selectedOrg.orgCode : "/app/orgs/" + this.state.selectedOrg.orgCode} className="background-button"><button className="btn btn-squarish denary-background clear-border">View Review Page</button></Link>
                       </div>
                       <div className="clear"/>
                      </div>
                    </div>
                  )}
                </div>
              )}
             </div>
           </Modal>
          </div>

      )
    }
}


export default withRouter(AddWorkspaces);
