import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCustomDropdown from '../Common/CustomDropdown';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import {subExportCSV} from '../Functions/ExportCSV';
import withRouter from '../Functions/WithRouter';

const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const archiveIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/archive-icon-grey.png"
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png"
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png"
const xIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png"
const downloadIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-dark.png"
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Members extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          sortProperly: false,

          emailId: null,
          username: '',
          org: '',
          members: [],
          filteredMembers: [],
          timerId: 0,

          pageNumber: 1,
          resLimit: 50,
          minResLimit: 10,
          maxResLimit: 10000,
          filterResLimit: 1000,
          pageCountStart: 0,
          pageCountEnd: 50,

          defaultFilterOption: 'Everyone',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          memberFilterOptions: [],
          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],
          mentorMatchingAlgorithmOptions: [],

          mentorMatchingAlgorithm: { name: 'Default' },

          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullMembers = this.pullMembers.bind(this)
        this.pullMemberCount = this.pullMemberCount.bind(this)
        this.pullSupplementaryData = this.pullSupplementaryData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.renderManipulators = this.renderManipulators.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.resetFilters = this.resetFilters.bind(this)

        this.renderMembers = this.renderMembers.bind(this)
        this.confirmArchive = this.confirmArchive.bind(this)

        this.movePage = this.movePage.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.bulkMatch = this.bulkMatch.bind(this)
        this.translateText = this.translateText.bind(this)
        this.sortValues = this.sortValues.bind(this)
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)
        this.exportInfo = this.exportInfo.bind(this)
        this.calculateAge = this.calculateAge.bind(this)
        this.reloadMembers = this.reloadMembers.bind(this)

    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
      console.log('componentDidMount in subMembers', this.props)
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subMembers', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('sm1')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subMembers', this.props)

      let org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgName = localStorage.getItem('orgName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (!org) {
        org = activeOrg
      }

      const studentAliasSingular = localStorage.getItem('studentAliasSingular');
      const studentAliasPlural = localStorage.getItem('studentAliasPlural');
      const mentorAliasSingular = localStorage.getItem('mentorAliasSingular');
      const mentorAliasPlural = localStorage.getItem('mentorAliasPlural');

      if (email && org) {

        //filters
        const defaultFilterOption = this.state.defaultFilterOption
        const defaultSortOption = this.state.defaultSortOption

        let memberFilterOptions = [defaultFilterOption,'Students','Mentors','Employers']

        let applicationFilterOptions = []
        let interviewFilterOptions = []
        let offerFilterOptions = []
        let assessmentFilterOptions = []
        let projectFilterOptions = []
        let favoriteFilterOptions = []
        let sessionFilterOptions = []
        let goalFilterOptions = []
        let passionFilterOptions = []
        let endorsementFilterOptions = []
        let functionFilterOptions = []
        let industryFilterOptions = []
        let raceFilterOptions = []
        let genderFilterOptions = []
        let fosterYouthFilterOptions = []
        let lowIncomeFilterOptions = []
        let mentorFilterOptions = []

        let politicalAlignmentFilterOptions = []
        let homeStateFilterOptions = []

        let activeFilterOptions = []
        let workReadyCertifiedFilterOptions = []
        let alumniFilterOptions = []
        let postFilterOptions = []
        let careerTrackFilterOptions = []
        let menteeFilterOptions = []

        //sort options
        let skillSortOptions = []
        let interestSortOptions = []
        let personalitySortOptions = []
        let endorsementSortOptions = []
        let benchmarkSortOptions = []
        let postSortOptions = []
        // let eventSortOptions = []

        let memberFilters = [{ name: 'Members', value: '', options: memberFilterOptions }]
        let memberSorters = []
        let studentFilters = []
        let studentSorters = []
        let educatorFilters = []
        let educatorSorters = []
        let mentorFilters = []
        let mentorSorters = []
        // let employerFilters = []
        // let employerSorters = []

        if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {

          applicationFilterOptions = [defaultFilterOption,'Has None','At Least One']
          interviewFilterOptions = [defaultFilterOption,'Has None','At Least One']
          offerFilterOptions = [defaultFilterOption,'Has None','At Least One']
          assessmentFilterOptions = [defaultFilterOption,'Has None','At Least One','Has All']
          projectFilterOptions = [defaultFilterOption,'Has None','At Least One']
          favoriteFilterOptions = [defaultFilterOption,'Has None','At Least One']
          sessionFilterOptions = [defaultFilterOption,'Has None','At Least One']
          goalFilterOptions = [defaultFilterOption,'Has None','At Least One']
          passionFilterOptions = [defaultFilterOption,'Has None','At Least One']
          endorsementFilterOptions = [defaultFilterOption,'Has None','At Least One']

          functionFilterOptions = [defaultFilterOption,'Not Specified']
          industryFilterOptions = [defaultFilterOption,'Not Specified']
          raceFilterOptions = [defaultFilterOption]
          genderFilterOptions = [defaultFilterOption]
          fosterYouthFilterOptions = [defaultFilterOption]
          lowIncomeFilterOptions = [defaultFilterOption,"Grew Up Low Income",'Did Not Grew Up Low Income']
          mentorFilterOptions = [defaultFilterOption,'Has One','Does Not Have One']

          // schoolNameFilterOptions = [defaultFilterOption]
          // pathwayFilterOptions = [defaultFilterOption]

          politicalAlignmentFilterOptions = [defaultFilterOption]
          homeStateFilterOptions = [defaultFilterOption]
          activeFilterOptions = [defaultFilterOption,'Is Active','Is Not Active']
          workReadyCertifiedFilterOptions = [defaultFilterOption,'Is Work Ready Certified','Is Not Work Ready Certified']

          studentFilters = [
            { name: 'Applications', value: defaultFilterOption, options: applicationFilterOptions},
            { name: 'Interviews', value: defaultFilterOption, options: interviewFilterOptions},
            { name: 'Offers', value: defaultFilterOption, options: offerFilterOptions },
            { name: 'Assessments', value: defaultFilterOption, options: assessmentFilterOptions },
            { name: 'Projects', value: defaultFilterOption, options: projectFilterOptions },
            { name: 'Favorites', value: defaultFilterOption, options: favoriteFilterOptions },
            { name: 'Sessions', value: defaultFilterOption, options: sessionFilterOptions },
            { name: 'Goals', value: defaultFilterOption, options: goalFilterOptions },
            { name: 'Passions', value: defaultFilterOption, options: passionFilterOptions },
            { name: 'Endorsements', value: defaultFilterOption, options: endorsementFilterOptions },
            { name: 'Race', value: defaultFilterOption, options: raceFilterOptions },
            { name: 'Gender', value: defaultFilterOption, options: genderFilterOptions },
            { name: 'Has Mentor', value: defaultFilterOption, options: mentorFilterOptions },
            { name: 'Is Active', value: defaultFilterOption, options: activeFilterOptions },
            { name: 'Is Work Ready Certified', value: defaultFilterOption, options: workReadyCertifiedFilterOptions },
          ]

          studentFilters.push({ name: 'Foster Youth', value: defaultFilterOption, options: fosterYouthFilterOptions })
          studentFilters.push({ name: 'Low Income', value: defaultFilterOption, options: lowIncomeFilterOptions })
          studentFilters.push({ name: 'Enrollment Status', value: defaultFilterOption, options: [defaultFilterOption] })
          studentFilters.push({ name: 'Functions of Interest', value: defaultFilterOption, options: functionFilterOptions })
          studentFilters.push({ name: 'Industries of Interest', value: defaultFilterOption, options: industryFilterOptions })

          if (org === 'fosternation') {
            let cohortFilterOptions = [defaultFilterOption]
            for (let i = 1; i <= 10; i++) {
              cohortFilterOptions.push(i.toString())
            }
            studentFilters.push({ name: 'Cohort', value: defaultFilterOption, options: cohortFilterOptions })
          }

          let tempSkills = ['Software Development', 'UX/UI Design']
          skillSortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempSkills.length; i++) {
            skillSortOptions.push('Highly Skilled in ' + tempSkills[i - 1])
          }

          let tempInterests = ['Realistic', 'Investigative','Artistic','Social','Enterprising','Conventional']
          interestSortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempInterests.length; i++) {
            interestSortOptions.push('Highly Interested in ' + tempInterests[i - 1] + ' Careers')
          }
          for (let i = 1; i <= tempInterests.length; i++) {
            interestSortOptions.push('Uninterested in ' + tempInterests[i - 1] + ' Careers')
          }

          let tempTraits = ['Openness', 'Conscientiousness','Extraversion','Agreeableness','Neuroticism']
          personalitySortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempTraits.length; i++) {
            personalitySortOptions.push('High in ' + tempTraits[i - 1])
          }
          for (let i = 1; i <= tempTraits.length; i++) {
            personalitySortOptions.push('Low in ' + tempTraits[i - 1])
          }

          endorsementSortOptions = [defaultSortOption,'Best Endorsements', 'Most Endorsements']
          benchmarkSortOptions = [defaultSortOption]

          studentSorters = [
            // { name: 'Benchmark Match', value: defaultSortOption, options: benchmarkSortOptions},
            { name: 'Interests', value: defaultSortOption, options: interestSortOptions},
            { name: 'Personality', value: defaultSortOption, options: personalitySortOptions},
          ]

          let hideForNow = true
          if (!hideForNow) {
            studentSorters.push({ name: 'Skills', value: defaultSortOption, options: skillSortOptions})
            studentSorters.push({ name: 'Endorsements', value: defaultSortOption, options: endorsementSortOptions})
          }

          if (org) {
            Axios.get('/api/pathways', { params: { orgCode: org } })
            .then((response) => {
              console.log('Pathways query attempted two oh', response.data);

              if (response.data.success) {
                console.log('pathways query worked')

                if (response.data.pathways && response.data.pathways.length > 0) {
                  let pathwayOptions = []
                  for (let i = 1; i <= response.data.pathways.length; i++) {
                    pathwayOptions.push(response.data.pathways[i - 1].title)
                  }
                  studentFilters.push(
                    { name: 'Pathway', value: defaultFilterOption, options: [defaultFilterOption].concat(pathwayOptions) }
                  )
                  this.setState({ studentFilters })
                }

                const roleNames = ['Admin']
                Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
                .then((response) => {

                  if (response.data.success) {
                    console.log('Member query worked', response.data);

                    if (response.data.members && response.data.members.length > 0) {


                      let staffMemberOptions = []
                      for (let i = 1; i <= response.data.members.length; i++) {
                        staffMemberOptions.push(response.data.members[i - 1].firstName + ' ' + response.data.members[i - 1].lastName)
                      }
                      studentFilters.push(
                        { name: 'Assigned Staff', value: defaultFilterOption, options: [defaultFilterOption].concat(staffMemberOptions) }
                      )
                      this.setState({ studentFilters })
                    }

                  } else {
                    console.log('no members found', response.data.message)

                  }

                }).catch((error) => {
                    console.log('Members query did not work', error);
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });
          }

        } else if (this.props.type === 'Educators') {

          //educationfilters
          // schoolNameFilterOptions = []
          // pathwayFilterOptions = []
          // educatorFilters = [
          //   { name: 'School Name', value: defaultFilterOption, options: schoolNameFilterOptions},
          //   { name: 'Pathway', value: defaultFilterOption, options: pathwayFilterOptions},
          // ]

          //sort
          endorsementSortOptions = [defaultSortOption,'Best Endorsements', 'Most Endorsements']
          postSortOptions = [defaultSortOption,'Most Posts']

          educatorSorters = [
            { name: 'Endorsements', value: defaultSortOption, options: endorsementSortOptions},
            { name: 'Posts', value: defaultSortOption, options: postSortOptions}
          ]

        } else if (this.props.type === 'Mentors') {

          //mentorFilters
          careerTrackFilterOptions = [defaultFilterOption]
          // workFunctionFilterOptions = [defaultFilterOption]
          // industryFilterOptions = [defaultFilterOption]
          menteeFilterOptions = [defaultFilterOption,'Has One','Does Not Have One']
          postFilterOptions = [defaultFilterOption,'Has None','At Least One']
          alumniFilterOptions = [defaultFilterOption,'Is One']
          sessionFilterOptions = [defaultFilterOption,'Has None','At Least One']
          endorsementFilterOptions = [defaultFilterOption,'Has Given None','Has Given At Least One']
          // employerNameFilterOptions = [defaultFilterOption]
          politicalAlignmentFilterOptions = [defaultFilterOption]
          homeStateFilterOptions = [defaultFilterOption]
          // locationFilterOptions = [defaultFilterOption]


          //educationfilters
          mentorFilters = [
            { name: 'Career Track', value: defaultFilterOption, options: careerTrackFilterOptions},
            { name: 'Has Mentee', value: defaultFilterOption, options: menteeFilterOptions },
            { name: 'Posts', value: defaultFilterOption, options: postFilterOptions},
            { name: 'Alumni', value: defaultFilterOption, options: alumniFilterOptions},
            { name: 'Sessions', value: defaultFilterOption, options: sessionFilterOptions},
            { name: 'Endorsements', value: defaultFilterOption, options: endorsementFilterOptions},
          ]

          if (org === 'c2c') {
            mentorFilters.push({ name: 'Political Alignment', value: defaultFilterOption, options: politicalAlignmentFilterOptions})
            mentorFilters.push({ name: 'Home State', value: defaultFilterOption, options: homeStateFilterOptions})
          }
          console.log('show mentorSorters 1: ', mentorFilters, mentorSorters)
          //sort
          // eventSortOptions = [defaultSortOption,'Most Events']
          // sessionSortOptions = [defaultSortOption,'Most Sessions']
          // endorsementSortOptions = [defaultSortOption,'Best Endorsements', 'Most Endorsements']
          // let tempSkills = ['Software Development', 'UX/UI Design']
          // skillSortOptions.push(defaultSortOption)
          // for (let i = 1; i <= tempSkills.length; i++) {
          //   skillSortOptions.push('Highly Skilled in ' + tempSkills[i - 1])
          // }

          let tempInterests = ['Realistic', 'Investigative','Artistic','Social','Enterprising','Conventional']
          interestSortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempInterests.length; i++) {
            interestSortOptions.push('Highly Interested in ' + tempInterests[i - 1] + ' Careers')
          }

          let tempTraits = ['Openness', 'Conscientiousness','Extraversion','Agreeableness','Emotional Stability']
          personalitySortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempTraits.length; i++) {
            personalitySortOptions.push('High in ' + tempTraits[i - 1])
          }

          mentorSorters = [
            { name: 'Interests', value: defaultSortOption, options: interestSortOptions},
            { name: 'Personality', value: defaultSortOption, options: personalitySortOptions},
          ]
          console.log('show mentorSorters 2: ', mentorFilters, mentorSorters)

          Axios.get('/api/algorithms', { params: { orgCode: org } })
          .then((response) => {
            console.log('Algorithms query attempted', response.data);

              if (response.data.success) {
                console.log('Algorithms query worked')

                const algorithms = response.data.algorithms
                mentorMatchingAlgorithmOptions = algorithms.concat(mentorMatchingAlgorithmOptions)

                if (algorithms[0]) {
                  mentorMatchingAlgorithm = algorithms[0]
                }

                this.setState({ mentorMatchingAlgorithmOptions, mentorMatchingAlgorithm });

              } else {
                console.log('Algorithms query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Algorithms query did not work for some reason', error);
          });
        } else if (this.props.type === 'All') {

          applicationFilterOptions = [defaultFilterOption,'Has None','At Least One']
          interviewFilterOptions = [defaultFilterOption,'Has None','At Least One']
          offerFilterOptions = [defaultFilterOption,'Has None','At Least One']
          assessmentFilterOptions = [defaultFilterOption,'Has None','At Least One','Has All']
          projectFilterOptions = [defaultFilterOption,'Has None','At Least One']
          favoriteFilterOptions = [defaultFilterOption,'Has None','At Least One']
          sessionFilterOptions = [defaultFilterOption,'Has None','At Least One']
          goalFilterOptions = [defaultFilterOption,'Has None','At Least One']
          passionFilterOptions = [defaultFilterOption,'Has None','At Least One']
          endorsementFilterOptions = [defaultFilterOption,'Has None','At Least One']

          functionFilterOptions = [defaultFilterOption,'Not Specified']
          industryFilterOptions = [defaultFilterOption,'Not Specified']
          raceFilterOptions = [defaultFilterOption]
          genderFilterOptions = [defaultFilterOption]
          fosterYouthFilterOptions = [defaultFilterOption]
          lowIncomeFilterOptions = [defaultFilterOption,"Grew Up Low Income",'Did Not Grew Up Low Income']
          mentorFilterOptions = [defaultFilterOption,'Has One','Does Not Have One']

          // schoolNameFilterOptions = [defaultFilterOption]
          // pathwayFilterOptions = [defaultFilterOption]

          politicalAlignmentFilterOptions = [defaultFilterOption]
          homeStateFilterOptions = [defaultFilterOption]
          activeFilterOptions = [defaultFilterOption,'Is Active','Is Not Active']

          memberFilters = [
            { name: 'Applications', value: defaultFilterOption, options: applicationFilterOptions},
            { name: 'Interviews', value: defaultFilterOption, options: interviewFilterOptions},
            { name: 'Offers', value: defaultFilterOption, options: offerFilterOptions },
            { name: 'Assessments', value: defaultFilterOption, options: assessmentFilterOptions },
            { name: 'Projects', value: defaultFilterOption, options: projectFilterOptions },
            { name: 'Favorites', value: defaultFilterOption, options: favoriteFilterOptions },
            { name: 'Sessions', value: defaultFilterOption, options: sessionFilterOptions },
            { name: 'Goals', value: defaultFilterOption, options: goalFilterOptions },
            { name: 'Passions', value: defaultFilterOption, options: passionFilterOptions },
            { name: 'Endorsements', value: defaultFilterOption, options: endorsementFilterOptions },
            { name: 'Race', value: defaultFilterOption, options: raceFilterOptions },
            { name: 'Gender', value: defaultFilterOption, options: genderFilterOptions },
            { name: 'Has Mentor', value: defaultFilterOption, options: mentorFilterOptions },
            { name: 'Is Active', value: defaultFilterOption, options: activeFilterOptions },
          ]

          memberFilters.push({ name: 'Foster Youth', value: defaultFilterOption, options: fosterYouthFilterOptions })
          memberFilters.push({ name: 'Low Income', value: defaultFilterOption, options: lowIncomeFilterOptions })
          memberFilters.push({ name: 'Enrollment Status', value: defaultFilterOption, options: [defaultFilterOption] })

          if (org === 'c2c') {
            memberFilters.push({ name: 'Political Alignment', value: defaultFilterOption, options: politicalAlignmentFilterOptions })
            memberFilters.push({ name: 'Home State', value: defaultFilterOption, options: homeStateFilterOptions })
          } else {
            memberFilters.push({ name: 'Functions of Interest', value: defaultFilterOption, options: functionFilterOptions })
            memberFilters.push({ name: 'Industries of Interest', value: defaultFilterOption, options: industryFilterOptions })
          }

          if (org === 'fosternation') {
            let cohortFilterOptions = [defaultFilterOption]
            for (let i = 1; i <= 10; i++) {
              cohortFilterOptions.push(i.toString())
            }
            memberFilters.push({ name: 'Cohort', value: defaultFilterOption, options: cohortFilterOptions })
          }

          let tempSkills = ['Software Development', 'UX/UI Design']
          skillSortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempSkills.length; i++) {
            skillSortOptions.push('Highly Skilled in ' + tempSkills[i - 1])
          }

          let tempInterests = ['Realistic', 'Investigative','Artistic','Social','Enterprising','Conventional']
          interestSortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempInterests.length; i++) {
            interestSortOptions.push('Highly Interested in ' + tempInterests[i - 1] + ' Careers')
          }
          for (let i = 1; i <= tempInterests.length; i++) {
            interestSortOptions.push('Uninterested in ' + tempInterests[i - 1] + ' Careers')
          }

          let tempTraits = ['Openness', 'Conscientiousness','Extraversion','Agreeableness','Neuroticism']
          personalitySortOptions.push(defaultSortOption)
          for (let i = 1; i <= tempTraits.length; i++) {
            personalitySortOptions.push('High in ' + tempTraits[i - 1])
          }
          for (let i = 1; i <= tempTraits.length; i++) {
            personalitySortOptions.push('Low in ' + tempTraits[i - 1])
          }

          endorsementSortOptions = [defaultSortOption,'Best Endorsements', 'Most Endorsements']
          benchmarkSortOptions = [defaultSortOption]

          memberSorters = [
            { name: 'Benchmark Match', value: defaultSortOption, options: benchmarkSortOptions},
            { name: 'Interests', value: defaultSortOption, options: interestSortOptions},
            { name: 'Personality', value: defaultSortOption, options: personalitySortOptions},
          ]

          let hideForNow = true
          if (!hideForNow) {
            memberSorters.push({ name: 'Skills', value: defaultSortOption, options: skillSortOptions})
            memberSorters.push({ name: 'Endorsements', value: defaultSortOption, options: endorsementSortOptions})
          }

          if (org) {
            Axios.get('/api/pathways', { params: { orgCode: org } })
            .then((response) => {
              console.log('Pathways query attempted two oh', response.data);

              if (response.data.success) {
                console.log('pathways query worked')

                if (response.data.pathways && response.data.pathways.length > 0) {
                  let pathwayOptions = []
                  for (let i = 1; i <= response.data.pathways.length; i++) {
                    pathwayOptions.push(response.data.pathways[i - 1].title)
                  }
                  memberFilters.push(
                    { name: 'Pathway', value: defaultFilterOption, options: [defaultFilterOption].concat(pathwayOptions) }
                  )
                  this.setState({ memberFilters })
                }

                const roleNames = ['Admin']
                Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
                .then((response) => {

                  if (response.data.success) {
                    console.log('Member query worked', response.data);

                    if (response.data.members && response.data.members.length > 0) {


                      let staffMemberOptions = []
                      for (let i = 1; i <= response.data.members.length; i++) {
                        staffMemberOptions.push(response.data.members[i - 1].firstName + ' ' + response.data.members[i - 1].lastName)
                      }
                      memberFilters.push(
                        { name: 'Assigned Staff', value: defaultFilterOption, options: [defaultFilterOption].concat(staffMemberOptions) }
                      )
                      this.setState({ memberFilters })
                    }

                  } else {
                    console.log('no members found', response.data.message)

                  }

                }).catch((error) => {
                    console.log('Members query did not work', error);
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });
          }
        }

        let pageTitle = this.props.type
        if ((this.props.type === 'Students' || this.props.type === 'Career-Seekers') && studentAliasPlural) {
          pageTitle = studentAliasPlural
        } else if (this.props.type === 'Mentors' && mentorAliasPlural) {
          pageTitle = mentorAliasPlural
        }

        let mentorMatchingAlgorithm = { name: 'Default', orgCode: 'guidedcompass', sections: [
          { name: 'Work Preferences', careerSeekerField: 'Work Preferences', operator: 'Equal To', mentorField: 'Work Preferences' },
          { name: 'Interests', careerSeekerField: 'Interests', operator: 'Equal To', mentorField: 'Interests' },
          { name: 'Personality', careerSeekerField: 'Personality', operator: 'Equal To', mentorField: 'Personality' },
          { name: 'Skills', careerSeekerField: 'Skills', operator: 'Equal To', mentorField: 'Skills' },
        ]}
        // let mentorMatchingAlgorithmOptions = [mentorMatchingAlgorithm,{ name: 'Add New'}]
        let mentorMatchingAlgorithmOptions = [mentorMatchingAlgorithm]
        // console.log('???a1!', memberFilters, memberSorters)

        let pathPrefix = '/organizations/' + org
        if (window.location.pathname.startsWith('/advisor')) {
          pathPrefix = '/advisor'
        }

        this.setState({ emailId: email, username, org, orgName, roleName, orgFocus, pathPrefix,
          memberFilters, studentFilters, educatorFilters, mentorFilters,
          memberSorters, studentSorters, educatorSorters, mentorSorters,
          studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural, pageTitle, mentorMatchingAlgorithm,
          mentorMatchingAlgorithmOptions,
          animating: true
        });

        if (this.props.type === 'Students' || this.props.type === 'Career-Seekers' || this.props.type === 'Mentors') {
          Axios.get('/api/workoptions')
          .then((response) => {
            console.log('Work options query tried', response.data);

            if (response.data.success) {
              console.log('Work options query succeeded')

              let functionOptions = response.data.workOptions[0].functionOptions
              functionOptions.shift()
              let industryOptions = response.data.workOptions[0].industryOptions
              industryOptions.shift()

              const genderOptions = response.data.workOptions[0].genderOptions
              const raceOptions = response.data.workOptions[0].raceOptions
              const fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
              // const lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions

              const politicalAlignmentOptions = response.data.workOptions[0].individualPoliticalAlignmentOptions
              const unitedStateOptions = response.data.workOptions[0].unitedStateOptions

              if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
                for (let i = 1; i <= studentFilters.length; i++) {
                  const name = studentFilters[i - 1].name
                  if (name === 'Functions of Interest') {
                    functionFilterOptions = functionFilterOptions.concat(functionOptions)
                    studentFilters[i - 1]['options'] = functionFilterOptions
                  } else if (name === 'Industries of Interest') {
                    industryFilterOptions = industryFilterOptions.concat(industryOptions)
                    studentFilters[i - 1]['options'] = industryFilterOptions
                  } else if (name === 'Gender') {
                    genderFilterOptions = genderFilterOptions.concat(genderOptions)
                    studentFilters[i - 1]['options'] = genderFilterOptions
                  } else if (name === 'Race') {
                    raceFilterOptions = raceFilterOptions.concat(raceOptions)
                    studentFilters[i - 1]['options'] = raceFilterOptions
                  } else if (name === 'Foster Youth') {
                    fosterYouthFilterOptions = fosterYouthFilterOptions.concat(fosterYouthOptions)
                    studentFilters[i - 1]['options'] = fosterYouthFilterOptions
                  // } else if (name === 'Low Income') {
                  //   lowIncomeFilterOptions = fosterYouthFilterOptions.concat(lowIncomeOptions)
                  //   studentFilters[i - 1]['options'] = lowIncomeFilterOptions
                  } else if (name === 'Political Alignment') {
                    politicalAlignmentFilterOptions = politicalAlignmentFilterOptions.concat(politicalAlignmentOptions)
                    studentFilters[i - 1]['options'] = politicalAlignmentFilterOptions
                  } else if (name === 'Home State') {
                    homeStateFilterOptions = homeStateFilterOptions.concat(unitedStateOptions)
                    studentFilters[i - 1]['options'] = homeStateFilterOptions
                  } else if (name === 'Enrollment Status') {
                    studentFilters[i - 1]['options'] = [defaultFilterOption].concat(response.data.workOptions[0].enrollmentStatusOptions)
                  }
                }

                this.setState({ studentFilters })

              } else if (this.props.type === 'Mentors') {

                for (let i = 1; i <= mentorFilters.length; i++) {
                  const name = mentorFilters[i - 1].name
                  if (name === 'Work Function') {
                    functionFilterOptions = functionFilterOptions.concat(functionOptions)
                    mentorFilters[i - 1]['options'] = functionFilterOptions
                  } else if (name === 'Industry') {
                    industryFilterOptions = industryFilterOptions.concat(industryOptions)
                    mentorFilters[i - 1]['options'] = industryFilterOptions
                  } else if (name === 'Political Alignment') {
                    politicalAlignmentFilterOptions = politicalAlignmentFilterOptions.concat(politicalAlignmentOptions)
                    mentorFilters[i - 1]['options'] = politicalAlignmentFilterOptions
                  } else if (name === 'Home State') {
                    homeStateFilterOptions = homeStateFilterOptions.concat(unitedStateOptions)
                    mentorFilters[i - 1]['options'] = homeStateFilterOptions
                  }
                }

                this.setState({ mentorFilters })
              } else {
                console.log('there was an error')
              }
            }
          });
        }

        if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          const orgCodes = [org, 'general']
          Axios.get('/api/benchmarks', { params: { orgCodes } })
          .then((response) => {
            console.log('Benchmarks query attempted', response.data);

            if (response.data.success) {
              console.log('benchmark query worked')

              if (response.data.benchmarks.length !== 0) {
                //jobs = response.data.postings
                const benchmarks = response.data.benchmarks
                const benchmarkOptions = [defaultSortOption]
                for (let i = 1; i <= benchmarks.length; i++) {
                  benchmarkOptions.push(benchmarks[i - 1].title)
                }
                studentSorters[0]['options'] = benchmarkOptions
                this.setState({ benchmarks, studentSorters})

              }

            } else {
              console.log('benchmark query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Benchmark query did not work for some reason', error);
          });
        }

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             let favorites = []
             if (response.data.favorites) {
               favorites = response.data.favorites
             }

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })

           } else {
             console.log('no favorites data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgFocus = response.data.orgInfo.orgFocus
            const orgName = response.data.orgInfo.orgName
            const dashboardLayout = response.data.orgInfo.dashboardLayout

            this.setState({ orgFocus, orgName, dashboardLayout });

            if (dashboardLayout === 'Mentor') {
              // console.log('in mentor!!!')

              let dashFilters = [
                { name: 'Approved', checked: false },
                { name: 'Active', checked: false },
                { name: 'Inactive', checked: false },
                { name: 'Alumni', checked: false },
                { name: 'Paired', checked: false },
                { name: 'Unpaired', checked: false }
              ]
              if (studentAliasSingular) {
                dashFilters.push({ name: studentAliasSingular, checked: false })
              } else {
                dashFilters.push({ name: 'Career-Seeker', checked: false })
              }
              if (mentorAliasSingular) {
                dashFilters.push({ name: mentorAliasSingular, checked: false })
              } else {
                dashFilters.push({ name: 'Mentor', checked: false })
              }

              this.setState({ dashFilters })

              // if (calculateImmediately) {
              //   this.calculateDashboard(dashboardLayout, org, null, null, 'all time')
              // }
            }

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        let roleNames = null
        if (this.props.type === 'All') {
          roleNames = null
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          roleNames = ['Student','Career-Seeker']
        } else if (this.props.type === 'Educators') {
          roleNames = ['Teacher','Counselor','School Support']
        } else if (this.props.type === 'Mentors') {
          roleNames = ['Mentor']
        }

        this.setState({ roleNames })

        let limit = this.state.resLimit
        let skip = (this.state.pageNumber - 1) * this.state.limit

        this.pullMemberCount(org, roleNames)

        if (roleName === 'Teacher' && window.location.pathname.includes('/advisor') ) {
          // pull students within courses where user is a teacher

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {

              if (response.data.success) {
                console.log('User profile query worked', response.data);

                const courseIds = response.data.user.courseIds

                if (courseIds && courseIds.length > 0 && courseIds[0] !== null) {
                  // pull from courses

                  Axios.get('/api/courses/enrollments', { params: { courseIds } })
                  .then((response) => {

                      if (response.data.success) {
                        console.log('Enrollments query worked', response.data);

                        const members = response.data.enrollments
                        const filteredMembers = members

                        let memberCount = members.length
                        limit = members.length
                        skip = members.length

                        this.setState({ members, filteredMembers, courseIds,
                          memberCount, limit, skip,
                          animating: false
                        })

                      } else {
                        console.log('no enrolled users details found', response.data.message)
                        this.setState({ courseIds, animating: false })
                      }

                  }).catch((error) => {
                      console.log('Enrollment query did not work', error);
                      this.setState({ courseIds, animating: false })
                  });

                } else {
                  // pull everyone at the organization
                  this.pullMembers(org,roleNames,this.state.pageNumber)

                }

              } else {
                console.log('no user details found', response.data.message)
                this.setState({ animating: false })
              }

          }).catch((error) => {
              console.log('User profile query did not work', error);
          });

        } else {
          // pull all students within the organization

          this.pullMembers(org,roleNames,this.state.pageNumber)

        }
      }
    }

    movePage(forward) {
      console.log('movePage called')

      let pageNumber = this.state.pageNumber
      if (forward) {
        pageNumber = pageNumber + 1
      } else {
        pageNumber = pageNumber - 1
      }

      this.pullMembers(this.state.org, this.state.roleNames, pageNumber)

    }

    pullMemberCount(orgCode, roleNames) {
      console.log('pullMemberCount called', roleNames)

      Axios.get('/api/org/members/count', { params: { orgCode, roleNames } })
      .then((response) => {

        if (response.data.success) {
          console.log('Member count query worked', response.data);

          this.setState({ memberCount: response.data.memberCount });

        } else {
          console.log('no members found', response.data.message)
        }

      }).catch((error) => {
          console.log('Members count query did not work', error);
      });
    }

    reloadMembers(orgCode, roleNames, pageNumber, dashboardLayout) {
      console.log('reloadMembers called')

      this.closeModal()
      this.setState({ members: [], filteredMembers: [], skip: 0, animating: true })
      this.pullMembers(orgCode, roleNames, pageNumber, dashboardLayout)

    }

    pullMembers(orgCode, roleNames, pageNumber, dashboardLayout) {
      console.log('pullMembers called', orgCode, roleNames, pageNumber, dashboardLayout)

      const limit = this.state.resLimit
      const skip = (pageNumber - 1) * this.state.resLimit

      Axios.get('/api/org/members', { params: { orgCode, roleNames, limit, skip } })
      .then((response) => {

        if (response.data.success) {
          console.log('Member query worked in submembers', response.data);

          if (response.data.members && response.data.members.length > 0) {

            let members = response.data.members
            let filteredMembers = members
            // console.log('show me the goods: ', pageNumber, members.length, limit, skip)
            // server-side query org members api called { orgCode: 'fosternation', limit: '100', skip: '300' }
            let pageCountStart = ((pageNumber - 1) * members.length) + 1
            if (members.length < limit && skip > 0) {
              pageCountStart = ((pageNumber - 1) * limit) + 1
            }

            let pageCountEnd = members.length
            if (pageNumber > 1) {
              pageCountEnd = ((pageNumber - 1) * limit) + members.length
            }

            console.log('wtf is happening 1', dashboardLayout, this.props.dashboardLayout)
            if (!dashboardLayout) {
              if (this.state.dashboardLayout) {
                dashboardLayout = this.state.dashboardLayout
              } else if (this.props.dashboardLayout) {
                dashboardLayout = this.props.dashboardLayout
              }
            }

            let animating = false
            if (dashboardLayout === 'Mentor') {
              animating = true
            }
            // console.log('page counts: ', pageCountStart, pageCountEnd)
            this.setState({ members, filteredMembers, pageNumber, pageCountStart, pageCountEnd, animating });

            console.log('wtf is happening 2', dashboardLayout)
            if (dashboardLayout === 'Mentor') {
              this.pullSupplementaryData(orgCode, members)
            }

          } else {
            this.setState({ animating: false, errorMessage: response.data.message })
          }

        } else {
          console.log('no members found', response.data.message)
          this.setState({ animating: false, errorMessage: response.data.message })
        }

      }).catch((error) => {
          console.log('Members query did not work', error);
          this.setState({ animating: false, errorMessage: error.toString() })
      });
    }

    pullSupplementaryData(orgCode, members) {
      console.log('pullSupplementaryData called')

      const self = this

      function synthesizeData(partners, submissions) {
        console.log('synthesizeData called', partners, submissions)

        for (let i = 1; i <= members.length; i++) {
          const member = members[i - 1]
          if (partners) {
            for (let j = 1; j <= partners.length; j++) {
              const partner = partners[j - 1]
              if (partner.advisorEmail === member.email && partner.active) {
                members[i - 1]['pair'] = {
                  firstName: partner.adviseeFirstName,
                  lastName: partner.adviseeLastName,
                  email: partner.adviseeEmail,
                  username: partner.adviseeUsername,
                  pictureURL: partner.adviseePictureURL,
                  headline: partner.adviseeHeadline,
                  roleName: "Career-Seeker",
                }
              }
              if (partner.adviseeEmail === member.email && partner.active) {
                members[i - 1]['pair'] = {
                  firstName: partner.advisorFirstName,
                  lastName: partner.advisorLastName,
                  email: partner.advisorEmail,
                  username: partner.advisorUsername,
                  pictureURL: partner.advisorPictureURL,
                  headline: partner.advisorHeadline,
                  roleName: "Mentor",
                }
              }
            }
          }
          let submissionCount = 0
          let avgRating = null
          if (submissions) {
            let memberSubs = []
            for (let j = 1; j <= submissions.length; j++) {
              const sub = submissions[j - 1]
              if (sub.emailId === member.email) {
                memberSubs.push(sub)
                submissionCount = submissionCount + 1

                if (sub.answers && sub.answers.length > 0) {
                  for (let k = 1; k <= sub.answers.length; k++) {
                    const answer = sub.answers[k - 1]
                    if (answer && answer.question && answer.question.startsWith('How effective')) {
                      let rating = 0
                      if (answer.answer && answer.answer.trim() === 'Very Effective') {
                        rating = 5
                      } else if (answer.answer && answer.answer.trim() === 'Moderately Effective') {
                        rating = 4
                      } else if (answer.answer && answer.answer.trim() === 'Somewhat Effective') {
                        rating = 3
                      } else if (answer.answer && answer.answer.trim() === 'Moderately Ineffective') {
                        rating = 2
                      } else if (answer.answer && answer.answer.trim() === 'Very Ineffective') {
                        rating = 1
                      }

                      if (avgRating === 'N/A') {
                        avgRating = rating
                      } else {
                        avgRating = (((submissionCount - 1) * avgRating) + (rating)) / submissionCount
                      }
                    }
                  }
                }
              }
            }

            members[i - 1]['submissions'] = memberSubs
          }

          // console.log('gimme vaalues', member.email, submissionCount, avgRating)
          members[i - 1]['submissionCount'] = submissionCount
          if (avgRating) {
            members[i - 1]['avgRating'] = avgRating.toFixed(2)
          }
        }

        let filteredMembers = members
        self.setState({ members, filteredMembers, animating: false })

      }

      function pullSubmissions(partners) {
        console.log('pullSubmissions called', partners)

        Axios.get('/api/submissions', { params: { orgCode, sortDescending: true }})
        .then((response) => {
          if (response.data.success) {
            console.log('Submissions query worked', response.data);

            const submissions = response.data.submissions
            synthesizeData(partners, submissions)

          } else {
            console.log('no submissions found', response.data.message)
            synthesizeData(partners, null)
          }

        }).catch((error) => {
            console.log('Submissions query did not work', error);
            synthesizeData(partners, null)
        });
      }

      // partners, submissions
      Axios.get('/api/partners', { params: { orgCode }})
      .then((response) => {
        if (response.data.success) {
          console.log('Partners query worked', response.data);

          const partners = response.data.partners
          pullSubmissions(partners)

        } else {
          console.log('no partners found', response.data.message)
          pullSubmissions()
        }

      }).catch((error) => {
          console.log('Partners query did not work', error);
          pullSubmissions()
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        if (this.props.type === 'All') {
          let memberFilters = this.state.memberFilters
          for (let i = 1; i <= memberFilters.length; i++) {
            memberFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          let memberSorters = this.state.memberSorters
          for (let i = 1; i <= memberSorters.length; i++) {
            memberSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, memberFilters, memberSorters, animating: true, errorMessage: null })

        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          let studentFilters = this.state.studentFilters
          for (let i = 1; i <= studentFilters.length; i++) {
            studentFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          let studentSorters = this.state.studentSorters
          for (let i = 1; i <= studentSorters.length; i++) {
            studentSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, studentFilters, studentSorters, animating: true, errorMessage: null })

        } else if (this.props.type === 'Educators') {
          let educatorFilters = this.state.educatorFilters
          for (let i = 1; i <= educatorFilters.length; i++) {
            educatorFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          let educatorSorters = this.state.educatorSorters
          for (let i = 1; i <= educatorSorters.length; i++) {
            educatorSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, educatorFilters, educatorSorters, animating: true, errorMessage: null })

        } else if (this.props.type === 'Mentors') {
          let mentorFilters = this.state.mentorFilters
          for (let i = 1; i <= mentorFilters.length; i++) {
            mentorFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          let mentorSorters = this.state.mentorSorters
          for (let i = 1; i <= mentorSorters.length; i++) {
            mentorSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, mentorFilters, mentorSorters, animating: true, errorMessage: null })

        }

        // this.setState({ searchString: event.target.value, animating: true })
        // this.resetFilters(event.target.name, event.target.value)
        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        let filters = []
        if (this.props.type === 'All') {
          filters = this.state.memberFilters
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          filters = this.state.studentFilters
        } else if (this.props.type === 'Educators') {
          filters = this.state.educatorFilters
        } else if (this.props.type === 'Mentors') {
          filters = this.state.mentorFilters
        }

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].name === field) {
            filters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        if (this.props.type === 'All') {
          let memberFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= memberFilters.length; i++) {
            if (memberFilters[i - 1].name !== field) {
              memberFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let memberSorters = this.state.memberSorters
          for (let i = 1; i <= memberSorters.length; i++) {
            memberSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, memberFilters, animating: true, errorMessage: null, searchString, memberSorters })
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          let studentFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= studentFilters.length; i++) {
            if (studentFilters[i - 1].name !== field) {
              studentFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let studentSorters = this.state.studentSorters
          for (let i = 1; i <= studentSorters.length; i++) {
            studentSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, studentFilters, animating: true, errorMessage: null, searchString, studentSorters })

        } else if (this.props.type === 'Educators') {
          let educatorFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= educatorFilters.length; i++) {
            if (educatorFilters[i - 1].name !== field) {
              educatorFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let educatorSorters = this.state.educatorSorters
          for (let i = 1; i <= educatorSorters.length; i++) {
            educatorSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, educatorFilters, animating: true, errorMessage: null, searchString, educatorSorters })

        } else if (this.props.type === 'Mentors') {
          let mentorFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= mentorFilters.length; i++) {
            if (mentorFilters[i - 1].name !== field) {
              mentorFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let mentorSorters = this.state.mentorSorters
          for (let i = 1; i <= mentorSorters.length; i++) {
            mentorSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, mentorFilters, animating: true, errorMessage: null, searchString, mentorSorters })

        }

        // this.resetFilters(event.target.name, event.target.value)
        this.filterResults(this.state.searchString, event.target.value, filters, index, false)
      } else if (event.target.name.includes('sort|')) {

        let sorters = []
        if (this.props.type === 'All') {
          sorters = this.state.memberSorters
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          sorters = this.state.studentSorters
        } else if (this.props.type === 'Educators') {
          sorters = this.state.educatorSorters
        } else if (this.props.type === 'Mentors') {
          sorters = this.state.mentorSorters
        }

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        for (let i = 1; i <= sorters.length; i++) {
          if (sorters[i - 1].name === field) {
            sorters[i - 1]['value'] = event.target.value
          }
        }

        if (this.props.type === 'All') {
          // let memberSorters = sorters
          // this.setState({ sorters, memberSorters, animating: true })

          let memberSorters = sorters

          //reset everything
          let searchString = ''
          let memberFilters = this.state.memberFilters
          for (let i = 1; i <= memberFilters.length; i++) {
            memberFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          // let memberSorters = this.state.memberSorters
          for (let i = 1; i <= memberSorters.length; i++) {
            if (memberSorters[i - 1].name !== field) {
              memberSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, memberFilters, memberSorters, animating: true })
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          let studentSorters = sorters

          //reset everything
          let searchString = ''
          let studentFilters = this.state.studentFilters
          for (let i = 1; i <= studentFilters.length; i++) {
            studentFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
          // let studentSorters = this.state.studentSorters
          for (let i = 1; i <= studentSorters.length; i++) {
            if (studentSorters[i - 1].name !== field) {
              studentSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, studentFilters, studentSorters, animating: true })

        } else if (this.props.type === 'Educators') {
          // let educatorSorters = sorters
          // this.setState({ sorters, educatorSorters, animating: true })
          let educatorSorters = sorters

          //reset everything
          let searchString = ''
          let educatorFilters = this.state.educatorFilters
          for (let i = 1; i <= educatorFilters.length; i++) {
            educatorFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= educatorSorters.length; i++) {
            if (educatorSorters[i - 1].name !== field) {
              educatorSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, educatorFilters, educatorSorters, animating: true })
        } else if (this.props.type === 'Mentors') {
          // let mentorSorters = sorters
          // this.setState({ sorters, mentorSorters, animating: true })

          let mentorSorters = sorters

          //reset everything
          let searchString = ''
          let mentorFilters = this.state.mentorFilters
          for (let i = 1; i <= mentorFilters.length; i++) {
            mentorFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= mentorSorters.length; i++) {
            if (mentorSorters[i - 1].name !== field) {
              mentorSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, mentorFilters, mentorSorters, animating: true })

        }

        // this.resetFilters(event.target.name, event.target.value)
        this.sortResults(event.target.value, field)

      } else if (event.target.name === 'mentorMatchingAlgorithm') {
        let mentorMatchingAlgorithm = null
        for (let i = 1; i <= this.state.mentorMatchingAlgorithmOptions.length; i++) {
          if (this.state.mentorMatchingAlgorithmOptions[i - 1].name === event.target.value) {
            mentorMatchingAlgorithm = this.state.mentorMatchingAlgorithmOptions[i - 1]
          }
        }
        if (mentorMatchingAlgorithm && mentorMatchingAlgorithm.name === 'Add New') {
          this.setState({ mentorMatchingAlgorithm, modalIsOpen: true, showMentorMatchingAlgorithm: true })
        } else {
          this.setState({ mentorMatchingAlgorithm })
        }
      } else if (event.target.name === 'resLimit') {
        let resLimit = event.target.value
        if (resLimit < this.state.minResLimit) {
          resLimit = this.state.minResLimit
        } else if (resLimit > this.state.maxResLimit) {
          resLimit = this.state.maxResLimit
        }
        this.setState({ resLimit })
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption
      let members = this.state.members
      if (members.length > 0) {
        members = null
      }

      const type = this.props.type
      let roleNames = null
      if (this.props.type === 'All') {
        roleNames = null
      } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
        roleNames = ['Student','Career-Seeker']
      } else if (this.props.type === 'Educators') {
        roleNames = ['Teacher','Counselor','School Support']
      } else if (this.props.type === 'Mentors') {
        roleNames = ['Mentor']
      }

      const orgCode = this.state.org

      let pageNumber = this.state.pageNumber
      const limit = this.state.filterResLimit
      const skip = (pageNumber - 1) * this.state.resLimit

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        if (search) {
          Axios.get('/api/members/search', { params: { searchString, filterString, filters, defaultFilterOption, index, search, type, roleNames, orgCode, limit, skip }})
          // Axios.put('/api/members/filter', { params: { emailId, orgCode, searchString, filterString, filters, defaultFilterOption, members } })
          .then((response) => {
            console.log('Member filter query attempted', response.data);

            if (response.data.success) {
              console.log('member filter query worked')

              const members = response.data.members
              // const memberCount = members.length
              let filteredMembers = []
              if (response.data.members) {
                filteredMembers = response.data.members
              }

              let pageCountStart = ((pageNumber - 1) * members.length) + 1

              let pageCountEnd = members.length
              if (pageNumber > 1) {
                pageCountEnd = ((pageNumber - 1) * limit) + members.length
              }

              pageNumber = 1

              let animating = false
              if (window.location.pathname.startsWith('/organizations') && self.state.dashboardLayout === "Mentor") {
                animating = true
              }

              let errorMessage = null
              if (members.length === limit) {
                errorMessage = "You have hit the filter response limit of " + limit.toString() + " results."
               }

              self.setState({ members, filteredMembers, pageNumber, pageCountStart, pageCountEnd, animating, errorMessage })

              // pull other data
              if (window.location.pathname.startsWith('/organizations') && self.state.dashboardLayout === "Mentor") {
                self.pullSupplementaryData(orgCode, members)
              }

            } else {
              console.log('member filter query did not work', response.data.message)
              self.setState({ animating: false })
            }

          }).catch((error) => {
              console.log('Member filter query did not work for some reason', error);
              self.setState({ animating: false })
          });

        } else {

          Axios.put('/api/members/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, members, type, roleNames, orgCode, limit, skip })
          // Axios.put('/api/members/filter', { params: { emailId, orgCode, searchString, filterString, filters, defaultFilterOption, members } })
          .then((response) => {
            console.log('Member filter query attempted', response.data);

              if (response.data.success) {
                console.log('member filter query worked')

                const members = response.data.members
                // const memberCount = members.length
                let filteredMembers = response.data.members
                const filterCriteriaArray = response.data.filterCriteriaArray
                const sortCriteriaArray = []

                let pageCountStart = ((pageNumber - 1) * members.length) + 1

                let pageCountEnd = members.length
                if (pageNumber > 1) {
                  pageCountEnd = ((pageNumber - 1) * limit) + members.length
                }

                pageNumber = 1

                let animating = false
                if (window.location.pathname.startsWith('/organizations') && self.state.dashboardLayout === "Mentor") {
                  animating = true
                }

                let errorMessage = null
                if (members.length === limit) {
                  errorMessage = "You have hit the filter response limit of " + limit.toString() + " results"
                 }

                self.setState({ members, filteredMembers, pageNumber, pageCountStart, pageCountEnd,
                  filterCriteriaArray, sortCriteriaArray, animating, errorMessage
                })

                // pull other data
                if (window.location.pathname.startsWith('/organizations') && self.state.dashboardLayout === "Mentor") {
                  self.pullSupplementaryData(orgCode, members)
                }

              } else {
                console.log('member filter query did not work', response.data.message)
                self.setState({ animating: false })
              }

          }).catch((error) => {
              console.log('Member filter query did not work for some reason', error);
              self.setState({ animating: false })
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

      // Axios.put('/api/members/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, members, type })
      // // Axios.put('/api/members/filter', { params: { emailId, orgCode, searchString, filterString, filters, defaultFilterOption, members } })
      // .then((response) => {
      //   console.log('Member filter query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('member filter query worked')
      //
      //       let filteredMembers = response.data.members
      //
      //       this.setState({ filteredMembers, animating: false })
      //
      //     } else {
      //       console.log('member filter query did not work', response.data.message)
      //       this.setState({ animating: false })
      //     }
      //
      // }).catch((error) => {
      //     console.log('Member filter query did not work for some reason', error);
      //     this.setState({ animating: false })
      // });
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let members = this.state.members
      let filteredMembers = []

      if (sortName === 'Benchmark Match') {
        console.log('in benchmark match')

        const appEnd = members.length - 1

        for (let i = 1; i <= members.length; i++) {
          const index = i - 1

          if (sortString === 'No Sort Applied') {
            filteredMembers = members
            const filterCriteriaArray = null
            const sortCriteriaArray = null

            this.setState({ filteredMembers, animating: false, filterCriteriaArray, sortCriteriaArray });

          } else {

            Axios.get('/api/assessment/results', { params: { emailId: members[index].email } })
            .then((response) => {
                console.log('query for assessment results worked');

                if (response.data.success) {

                  console.log('actual assessment results', response.data)

                  let wpData = null
                  if (response.data.results.workPreferenceAnswers) {
                    wpData = response.data.results.workPreferenceAnswers
                  }

                  let interestResults = null
                  if (response.data.results.interestScores) {
                    interestResults = response.data.results.interestScores
                  }

                  let personalityResults = null
                  if (response.data.results.personalityScores) {
                    personalityResults = response.data.results.personalityScores
                  }

                  const skillAnswers = null

                  let application = members[index]
                  application['workPreferenceResults'] = wpData
                  application['interestResults'] = interestResults
                  application['personalityResults'] = personalityResults
                  application['skillAnswers'] = skillAnswers

                  const selectedJob = { title: sortString, postType: 'Individual' }
                  console.log('about to add benchmark')
                  let benchmark = null
                  for (let j = 1; j <= this.state.benchmarks.length; j++) {
                    console.log('compare benchmarks: ',this.state.benchmarks[j - 1].title, sortString)
                    if (this.state.benchmarks[j - 1].title === sortString) {
                      benchmark = this.state.benchmarks[j - 1]
                      console.log('got it')
                    }
                  }
                  console.log('show benchmark: ', benchmark)

                  let endLoop = false
                  if (index === appEnd) {
                    endLoop = true
                  }

                  console.log('compare index and endLoop: ', index, appEnd, endLoop )
                  const activeOrg = this.state.org

                  if (benchmark) {

                    Axios.post('/api/applications/matches', {
                      application, selectedJob, benchmark, activeOrg })
                    .then((response) => {
                      console.log('application matches query attempted within members')
                      if (response.data.success) {
                        //save values
                        console.log('Application matches save worked', response.data);

                        filteredMembers.push(response.data.application)
                        console.log('at the end? 1', index, appEnd, endLoop, filteredMembers.length)

                        if (endLoop) {
                          filteredMembers.sort(function(a,b) {
                            return b.match - a.match;
                          })

                          let filterCriteriaArray = []
                          let sortCriteriaArray = null
                          for (let k = 1; k <= filteredMembers.length; k++) {
                            if (filteredMembers[k - 1].match) {
                              filterCriteriaArray.push({ name: 'Benchmark Match: ', criteria: filteredMembers[k - 1].match })
                            }
                          }

                          this.setState({ filteredMembers, animating: false, filterCriteriaArray, sortCriteriaArray });
                        }

                      } else {
                        console.log('application matches did not work', response.data.message)
                        console.log('at the end? 2', index, appEnd, endLoop)
                        if (index === appEnd) {
                          filteredMembers.sort(function(a,b) {
                            return b.match - a.match;
                          })
                          console.log('got in')

                          let filterCriteriaArray = []
                          let sortCriteriaArray = null
                          for (let k = 1; k <= filteredMembers.length; k++) {
                            if (filteredMembers[k - 1].match) {
                              filterCriteriaArray.push({ name: 'Benchmark Match: ', criteria: filteredMembers[k - 1].match })
                            }
                          }

                          this.setState({ filteredMembers, animating: false, filterCriteriaArray, sortCriteriaArray });
                        }
                      }
                    }).catch((error) => {
                        console.log('Application matches did not work for some reason', error);
                        console.log('at the end? 3', index, appEnd, endLoop)
                        if (index === appEnd) {
                          filteredMembers.sort(function(a,b) {
                            return b.match - a.match;
                          })
                          console.log('got in')

                          let filterCriteriaArray = []
                          let sortCriteriaArray = null
                          for (let k = 1; k <= filteredMembers.length; k++) {
                            if (filteredMembers[k - 1].match) {
                              filterCriteriaArray.push({ name: 'Benchmark Match: ', criteria: filteredMembers[k - 1].match })
                            }
                          }

                          this.setState({ filteredMembers, animating: false, filterCriteriaArray, sortCriteriaArray });
                        }
                    });
                  }
                } else {
                  console.log('error response for assessments', response.data)
                }
            }).catch((error) => {
                console.log('query for assessment results did not work', error);
            })

          }
        }

      } else {
        Axios.put('/api/members/sort', { sortString, members, sortName })
        .then((response) => {
          console.log('Member sort query attempted', response.data);

            if (response.data.success) {
              console.log('member sort query worked')

              let filteredMembers = response.data.members
              const filterCriteriaArray = []
              const sortCriteriaArray = response.data.sortCriteriaArray

              this.setState({ filteredMembers, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('member sort query did not work', response.data.message)
              this.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Member sort query did not work for some reason', error);
            this.setState({ animating: false })
        });
      }
    }

    resetFilters(selectedFilter, selectedValue) {
      console.log('resetFilters called', selectedFilter, selectedValue)
      //reset all filters except the one currently selected
      //cant figure out how to reset back only one step when filters selected to be "Everyone" again

      let searchString = ''
      let filters = []
      let sorters = []
      if (this.props.type === 'All') {
        filters = this.state.memberFilters
        sorters = this.state.memberFilters
      } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
        filters = this.state.studentFilters
        sorters = this.state.studentFilters
      } else if (this.props.type === 'Educators') {
        filters = this.state.educatorFilters
        sorters = this.state.educatorFilters
      } else if (this.props.type === 'Mentors') {
        filters = this.state.mentorFilters
        sorters = this.state.mentorFilters
      }

      if (selectedFilter === 'search') {
        searchString = selectedValue
      }

      let nameArray = selectedFilter.split("|")
      let field = selectedFilter
      if (nameArray.includes('filter|') || nameArray.includes('sort|')) {
        field = nameArray[1]
      }
      console.log('show correct field: ', field)
      for (let i = 1; i <= filters.length; i++) {
        if (selectedFilter.includes('filter|')) {
          console.log('this is included')
          if (field !== filters[i - 1].name) {
            filters[i - 1]['value'] = [this.state.defaultFilterOption]
            console.log('t1', field, filters[i - 1].name)
          } else {
            console.log('t2', field, filters[i - 1].name)
          }
        } else {
          filters[i - 1]['value'] = [this.state.defaultFilterOption]
          console.log('t3', field, filters[i - 1].name)
        }
      }
      for (let i = 1; i <= sorters.length; i++) {
        if (nameArray.includes('sort|')) {
          if (field !== sorters[i - 1].name) {
            sorters[i - 1]['value'] = [this.state.defaultSortOption]
          }
        } else {
          sorters[i - 1]['value'] = [this.state.defaultSortOption]
        }
      }

      if (this.props.type === 'All') {
        let memberFilters = filters
        let memberSorters = sorters
        this.setState({ searchString, memberFilters, memberSorters})
      } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
        let studentFilters = filters
        let studentSorters = sorters
        this.setState({ searchString, studentFilters, studentSorters})
      } else if (this.props.type === 'Educators') {
        let educatorFilters = filters
        let educatorSorters = sorters
        this.setState({ searchString, educatorFilters, educatorSorters})
      } else if (this.props.type === 'Mentors') {
        let educatorFilters = filters
        let educatorSorters = sorters
        this.setState({ searchString, educatorFilters, educatorSorters})
      }
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = []
        if (this.props.type === 'All') {
          filters = this.state.memberFilters
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          filters = this.state.studentFilters
        } else if (this.props.type === 'Educators') {
          filters = this.state.educatorFilters
        } else if (this.props.type === 'Mentors') {
          filters = this.state.mentorFilters
        }
        console.log('show filters: ', filters)

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = []
        if (this.props.type === 'All') {
          sorters = this.state.memberSorters
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          sorters = this.state.studentSorters
        } else if (this.props.type === 'Educators') {
          sorters = this.state.educatorSorters
        } else if (this.props.type === 'Mentors') {
          sorters = this.state.mentorSorters
        }
        console.log('show sorters: ', sorters)

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderMembers() {
      console.log('renderMembers called', this.state.filteredMembers)
      console.log('show path: ', this.props.path)

      let canArchive = true
      if (this.props.roleName !== 'Admin') {
        canArchive = false
      }

      let rows = []
      for (let i = 1; i <= this.state.filteredMembers.length; i++) {

        const index = i - 1

        let subtitle = ''
        let pathname = '/organizations/' + this.state.org + '/members/' + this.state.filteredMembers[index].username
        let passedState = { member: this.state.filteredMembers[index] }
        if (this.props.roleName === 'Admin') {
          pathname = '/organizations/' + this.state.org + '/members/' + this.state.filteredMembers[index].username
        } else if (this.props.path && this.props.path.includes('/advisor')) {
          pathname = '/advisor/advisees/' + this.state.filteredMembers[index].email
          passedState = { advisee: this.state.filteredMembers[index], favorites: this.state.favorites }
        }

        let updatedAtString = ''
        if (this.state.filteredMembers[i - 1].updatedAt) {
          // const year = new Date(this.state.filteredMembers[i - 1].updatedAt).getFullYear()
          // const month = new Date(this.state.filteredMembers[i - 1].updatedAt).getMonth()
          // const day = new Date(this.state.filteredMembers[i - 1].updatedAt).getDate()
          // const formattedMonth = convertDateToString(month,'formatMonth')
          // updatedAtString = formattedMonth + ' ' + day + ', ' + year
          updatedAtString = convertDateToString(new Date(this.state.filteredMembers[i - 1].updatedAt),'date-2')

        }

        if (this.props.type === 'All') {
          subtitle = this.state.filteredMembers[index].roleName
        } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {
          if (this.state.filteredMembers[index].gradYear) {
            subtitle = subtitle + this.state.filteredMembers[index].gradYear + ' Grad Year'
          }

          if (this.state.filteredMembers[index].assessmentCount) {
            if (subtitle === '') {
              subtitle = subtitle + 'Student'
            } else {
              subtitle = subtitle + ' | Student'
            }
          } else {
            if (subtitle === '') {
              subtitle = subtitle + 'Student'
            } else {
              subtitle = subtitle + ' | Student'
            }
          }

          if (updatedAtString) {
            subtitle = subtitle + ' | Last Update: ' + updatedAtString
          }
        } else if (this.props.type === 'Educators') {

          subtitle = this.state.filteredMembers[i - 1].roleName
          if (this.state.filteredMembers[i - 1].school) {
            subtitle = subtitle + ' | ' + this.state.filteredMembers[i - 1].school
          }

          pathname = this.props.path + '/' + this.state.filteredMembers[index]._id
          passedState = { selectedMentor: this.state.filteredMembers[index], members: this.state.members, source: 'Admin', org: this.state.org }

        } else if (this.props.type === 'Mentors') {

          if (this.state.filteredMembers[i - 1].targetJobTitle && this.state.filteredMembers[i - 1].targetEmployerName) {
            subtitle = this.state.filteredMembers[i - 1].targetJobTitle + " | " + this.state.filteredMembers[i - 1].targetEmployerName
          } else if (this.state.filteredMembers[i - 1].jobTitle && this.state.filteredMembers[i - 1].employerName) {
            subtitle = this.state.filteredMembers[i - 1].jobTitle + " @ " + this.state.filteredMembers[i - 1].employerName + ' | Last Update: ' + updatedAtString
          } else {
            subtitle = 'Last Update: ' + updatedAtString
          }
          // console.log('show path props: ', this.props.path)
          // pathname = this.props.path + '/' + this.state.filteredMembers[index].username
          if (window.location.pathname.startsWith('/advisor/')) {
            pathname = '/advisor/members/' + this.state.filteredMembers[index].username
          } else if (window.location.pathname.startsWith('/organizations/')) {
            pathname = '/organizations/' + this.state.org + '/members/' + this.state.filteredMembers[index].username
          }

          passedState = { selectedMentor: this.state.filteredMembers[index], member: this.state.filteredMembers[index], mentors: this.state.members, source: 'WBLC', org: this.state.org }

        }

        rows.push(
          <div key={i}>
            <div className="row-10 left-padding">
              <Link to={pathname} state={passedState} className={(this.props.roleName === 'Mentor') ? "background-link calc-column-offset-30 clear-border" : "background-link calc-column-offset-140-static clear-border"} >
                <span className="fixed-column-50">
                  <img src={this.state.filteredMembers[index].pictureURL ? this.state.filteredMembers[index].pictureURL : profileIconGrey} alt="GC" className="profile-thumbnail-2"/>
                </span>
                <span className="calc-column-offset-50 left-padding-20">
                  <div className="heading-text-5 full-width">
                    <p>{this.state.filteredMembers[index].firstName} {this.state.filteredMembers[index].lastName}</p>
                  </div>
                  <div className="half-spacer" />
                  <p className="description-text-2 description-text-color">{subtitle}</p>
                </span>
                <div className="clear" />
              </Link>

              {(canArchive) && (
                <div className="float-left">
                  {(this.state.filteredMembers[index].confirmArchive) ? (
                    <div className="fixed-column-110 right-padding">
                      <div className="mini-spacer" />
                      <p className="description-text-2 right-text">Are you sure?</p>
                      <div className="half-spacer" />
                      <button className="btn btn-small-error pin-right" disabled={this.state.isSaving} onClick={() => this.archiveMember(index, true)}>Archive</button>
                      <div className="mini-spacer" /><div className="mini-spacer" />
                      <button className="btn btn-small-secondary pin-right" onClick={() => this.archiveMember(index, false)}>Cancel</button>
                    </div>
                  ) : (
                    <div className="fixed-column-110 right-padding">
                      <div className="spacer" /><div className="half-spacer" /><div className="mini-spacer" />
                      <a className="background-link" onClick={() => this.confirmArchive(index)}>
                        <img src={archiveIconGrey} alt="Guided Compass dashboard icon" className="image-auto-20 pin-right" />
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className="fixed-column-30 left-padding">
                <span className="float-left">
                  <div className="spacer"/><div className="half-spacer"/>
                  <Link to={pathname} state={passedState} className="background-link">
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                  </Link>
                </span>
              </div>
              <div className="clear" />
            </div>

            <div className="clear" />
            {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[i - 1] && this.state.sortCriteriaArray[i - 1].name) && (
              <div className="left-padding-80 top-margin-negative-5 full-width">
                <p className="description-text-2 error-color">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
              </div>
            )}
            {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[i - 1] && this.state.filterCriteriaArray[i - 1].name) && (
              <div className="left-padding-80 top-margin-negative-5 full-width">
                <p className="description-text-2 error-color">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
              </div>
            )}
            <hr className="cell-separator-advisor"/>
          </div>
        )
      }

      return rows
    }

    confirmArchive(index) {
      console.log('confirmArchive called', index)

      let filteredMembers = this.state.filteredMembers
      filteredMembers[index]['confirmArchive'] = true
      this.setState({ filteredMembers })
    }

    archiveMember(index, actuallyArchive) {
      console.log('archiveMember called', index, actuallyArchive)

      this.setState({ serverSuccessMessage: '' })

      if (!actuallyArchive) {
        // the user cancelled
        let filteredMembers = this.state.filteredMembers
        filteredMembers[index]['confirmArchive'] = false
        this.setState({ filteredMembers })

      } else {
        // the user would like to archive this user

        const emails = [this.state.filteredMembers[index].email]

        Axios.put('/api/users/archive', { emails })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User archive worked ', response.data);

            let filteredMembers = this.state.filteredMembers
            let archivedName = filteredMembers[index].firstName + ' ' + filteredMembers[index].lastName
            filteredMembers.splice(index, 1)

            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Successfully archived ' + archivedName,
              filteredMembers
            })

          } else {
            console.log('there was an error archiving the user')
            this.setState({
              serverSuccessText: false,
              serverErrorMessageText: response.data.message
            })
          }

        }).catch((error) => {
            console.log('User archive save did not work', error);
        });
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showBulkMatch: false, showAddStudentInstrux: false,
        showSubmissions: false, selectedMember: null, showChangeResultsWidget: false
      })
    }

    bulkMatch() {
      console.log('bulkMatch called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const orgCode = this.state.org
      const orgName = this.state.orgName

      let customAlgo = false
      if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.orgCode !== 'guidedcompass') {
        customAlgo = true
      }

      let algoSections = null
      if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.sections) {
        algoSections = this.state.mentorMatchingAlgorithm.sections
      }

      const emailId = this.state.emailId

      const postObject = {
        orgCode, orgName, algoSections, customAlgo, emailId
      }

      const self = this
      function officiallyBulkMatch() {
        console.log('officiallyBulkMatch called')

        Axios.post('/api/partners/bulk-match', postObject).then((response) => {
          console.log('attempting to bulk match')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            self.setState({ successMessage: 'Bulk matched!', isSaving: false })
          } else {
            console.log('did not save successfully')
            self.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            self.setState({ errorMessage: 'there was an error bulk matching', isSaving: false })
        });
      }

      let bulkDeleteMatches = false
      if (bulkDeleteMatches) {
        // bulk delete, then bulk match
        Axios.post('/api/partners/bulk-delete', { orgCode }).then((response) => {
          console.log('attempting to bulk match', response.data)

        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error bulk matching', isSaving: false })
        });
      } else {
        officiallyBulkMatch()
      }
    }

    translateText(type,roleName) {
      // console.log('translateText called', type, roleName)

      let returnedText = ''

      if (type === 'roleName') {
        if ((roleName === 'Student' || roleName === 'Career-Seeker') && this.state.studentAliasSingular) {
          returnedText = this.state.studentAliasSingular
        } else if (roleName === 'Mentor' && this.state.mentorAliasSingular) {
          returnedText = this.state.mentorAliasSingular
        } else {
          returnedText = roleName
        }
      }
      return returnedText
    }

    sortValues(type, values) {
      console.log('sortValues called', type, values)

      if (this.state.sortProperly) {
        this.sortResults(null, type)
      } else {
        let sortValue = type

        let members = values
        let sortDescending = this.state.sortDescending

        if (type === 'pair') {

          if (sortDescending) {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.pair) {
                textA = a.pair
              }
              if (b.pair) {
                textB = b.pair
              }
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
          } else {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.pair) {
                textA = a.pair
              }
              if (b.pair) {
                textB = b.pair
              }
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        } else if (type === 'enrollmentStatus') {
          if (sortDescending) {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.enrollmentStatus) {
                textA = a.enrollmentStatus
              }
              if (b.enrollmentStatus) {
                textB = b.enrollmentStatus
              }
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
          } else {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.enrollmentStatus) {
                textA = a.enrollmentStatus
              }
              if (b.enrollmentStatus) {
                textB = b.enrollmentStatus
              }
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        } else if (type === 'phoneNumber') {
          if (sortDescending) {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.phoneNumber) {
                textA = a.phoneNumber
              }
              if (b.phoneNumber) {
                textB = b.phoneNumber
              }
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
          } else {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.phoneNumber) {
                textA = a.phoneNumber
              }
              if (b.phoneNumber) {
                textB = b.phoneNumber
              }
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        } else if (type === 'numeric') {
          if (sortDescending) {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.createdAt) {
                textA = a.createdAt
              }
              if (b.createdAt) {
                textB = b.createdAt
              }
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
          } else {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.createdAt) {
                textA = a.createdAt
              }
              if (b.createdAt) {
                textB = b.createdAt
              }
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        } else if (type === 'cohort') {
          if (sortDescending) {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.cohort) {
                textA = a.cohort
              }
              if (b.cohort) {
                textB = b.cohort
              }
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
          } else {
            members.sort(function(a, b) {
              let textA = ''
              let textB = ''
              if (a.cohort) {
                textA = a.cohort
              }
              if (b.cohort) {
                textB = b.cohort
              }
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        } else {
          console.log('sort it yo')
          if (sortDescending) {
            members.sort(function(a, b){
                if(a[sortValue] > b[sortValue]) { return -1; }
                if(a[sortValue] < b[sortValue]) { return 1; }
                return 0;
            })
          } else {
            members.sort(function(a, b){
                if(a[sortValue] < b[sortValue]) { return -1; }
                if(a[sortValue] > b[sortValue]) { return 1; }
                return 0;
            })
            console.log('?!')
          }
        }

        sortDescending = !sortDescending
        this.setState({ members, sortDescending })
      }
    }

    toggleConfirmDelete(index,deleteItem) {
      console.log('toggleConfirmDelete called', index, deleteItem)

      let selectedMember = this.state.selectedMember

      if (deleteItem) {

        Axios.delete('/api/submissions/' + selectedMember['submissions'][index]._id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Submission delete worked');

            selectedMember['submissions'].splice(index,1)
            this.setState({ selectedMember })

          } else {
            console.error('there was an error deleting the submission');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      } else {
        selectedMember['submissions'][index]['confirmDelete'] = !selectedMember.submissions[index].confirmDelete
        this.setState({ selectedMember })
      }
    }

    exportInfo() {
      console.log('exportInfo called')

      if (this.state.members && this.state.members.length > 0) {

        let csvData = []

        csvData.push(
          [ "First Name", "Last Name","Email","Role Name","Resume","Picture URL","Birth Date","Age","Education Status","School","Degree","Grad Year","Major","Pathway","Job Title","Employer Name", "LinkedIn","Portfolio","Instagram URL", "Facebook URL", "Twitter URL","Education","Badges & Certificates","Alternative Email","Phone Number","Alternative Number","Address","City","State","Zip Code","Country","Language","Race","Races","Self Described Race","Gender","Preferred Pronoun","Veteran Status","Work Authorization","Number of Members in Household","Household Income","Foster Youth","Homeless","Incarcerated","Referrer Name","Referrer Email","Referrer Org","Auto Enroll", "Primary Career Interest","Secondary Career Interest","Tertiary Career Interest","Dream Career","Dream Company", "Career Status", "Five-Year Goal","Industry","Unsubscribed","Unsub Reason","Unsub Other Explanation","Unsub Placed Job Title","Unsub Placed Employer Name", "Public Profile","Headline","Matching Preferences","Matching Use Cases","Interviews","Notes","Feedback","Subsidy Eligible","Passed Workforce Ready Courses","Is WorkReady Certified","Has Work Permit","Is High Potential/Priority","Is Vaccinated","Pathway","Created At","Updated At","Adversity List"]
        )

        if (this.state.adversityListOptions && this.state.adversityListOptions.length > 0) {

          csvData[0] = csvData[0].concat(this.state.adversityListOptions)
        }

        // adversityListOptions

        if (this.state.org === 'c2c') {
          let tempData = csvData[0]
          tempData = tempData.concat(["Political Alignment","State Registration","Current Congressional District","Hometown","Home Congressional District","Career Track","DACA Status"])
          csvData[0] = tempData
        }

        const members = this.state.members
        if (members && members.length > 0) {

          let commaStartingValue = /,/g
          let commaReplacementValue = ";"

          for (let i = 1; i <= members.length; i++) {
            console.log(i, ' show signup ', members[i - 1].gradYear)

            let firstName = members[i - 1].firstName
            let lastName = members[i - 1].lastName
            let email = members[i - 1].email
            const roleName = members[i - 1].roleName
            const resumeURL = members[i - 1].resumeURL
            const pictureURL = members[i - 1].pictureURL

            const dateOfBirth = members[i - 1].dateOfBirth
            let age = ''
            if (dateOfBirth) {
              age = this.calculateAge(dateOfBirth)
            }

            let educationStatus = ''
            if (members[i - 1].educationStatus) {
              educationStatus = members[i - 1].educationStatus.replace(/,/g,'')
            }

            let school = ''
            if (members[i - 1].school) {
              school = members[i - 1].school.replace(/,/g,'').replace(/#/g,"")
            }
            // console.log('in school?!', school)

            let degree = ''
            if (members[i - 1].degree) {
              degree = members[i - 1].degree.replace(/,/g,'').replace(/#/g,"")
            }

            let gradYear = ''
            if (members[i - 1].gradYear) {
              gradYear = members[i - 1].gradYear.replace(/,/g,'').replace(/#/g,"")
            }

            let major = ''
            if (members[i - 1].major) {
              major = members[i - 1].major.replace(/,/g,'').replace(/#/g,"")
            }

            let pathway = ''
            if (members[i - 1].pathway) {
              pathway = members[i - 1].pathway.replace(/,/g,'').replace(/#/g,"")
            }

            let jobTitle = ''
            if (members[i - 1].jobTitle) {
              jobTitle = members[i - 1].jobTitle.replace(/,/g,'').replace(/#/g,"")
            }

            let employerName = ''
            if (members[i - 1].employerName) {
              employerName = members[i - 1].employerName.replace(/,/g,'').replace(/#/g,"")
            }

            let linkedInURL = ''
            if (members[i - 1].linkedInURL) {
              linkedInURL = members[i - 1].linkedInURL.replace(/,/g,'').replace(/#/g,"")
            }

            let customWebsiteURL = ''
            if (members[i - 1].customWebsiteURL) {
              customWebsiteURL = members[i - 1].customWebsiteURL.replace(/,/g,'').replace(/#/g,"")
            }

            let instagramURL = ''
            if (members[i - 1].instagramURL) {
              instagramURL = members[i - 1].instagramURL.replace(/,/g,'').replace(/#/g,"")
            }

            let facebookURL = ''
            if (members[i - 1].facebookURL) {
              facebookURL = members[i - 1].facebookURL.replace(/,/g,'').replace(/#/g,"")
            }

            let twitterURL = ''
            if (members[i - 1].twitterURL) {
              twitterURL = members[i - 1].twitterURL.replace(/,/g,'').replace(/#/g,"")
            }

            let education = 'bbb'
            if (members[i - 1].education && members[i - 1].education.length > 0) {
              education = JSON.stringify(members[i - 1].education).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")

              // school, degree, gradYear, major, pathway
              for (let j = 1; j <= members[i - 1].education.length; j++) {
                const edObject = members[i - 1].education[j - 1]

                // console.log('show me gradYeaar: ', gradYear, major, response.data.user.education)
                if (edObject) {
                  let tempGradYear = null
                  if (edObject.endDate && edObject.endDate.split("|")) {
                    tempGradYear = edObject.endDate.split(" ")[1]
                  }

                  if (edObject.isContinual) {
                    if (edObject.name) {
                      school = edObject.name.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (tempGradYear) {
                      gradYear = tempGradYear.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (edObject.degree) {
                      degree = edObject.degree.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (edObject.major) {
                      major = edObject.major.replace(/,/g,'').replace(/#/g,"")
                    }

                    // break;
                  } else if ((!tempGradYear || tempGradYear && gradYear && tempGradYear > gradYear) || (tempGradYear && !gradYear)) {
                    if (edObject.name) {
                      school = edObject.name.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (tempGradYear) {
                      gradYear = tempGradYear.replace(/,/g,'').replace(/#/g,"")
                    } else if (edObject.endDate){
                      gradYear = edObject.endDate.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (edObject.degree) {
                      degree = edObject.degree.replace(/,/g,'').replace(/#/g,"")
                    }
                    if (edObject.major) {
                      major = edObject.major.replace(/,/g,'').replace(/#/g,"")
                    }
                  } else {
                    console.log('something else?!?!?!', members[i - 1].email, edObject)
                  }
                }
              }
            }

            let certificates = 'aaa'
            if (members[i - 1].certificates && members[i - 1].certificates.length > 0) {
              certificates = JSON.stringify(members[i - 1].certificates).replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
            }

            let alternativeEmail = ''
            if (members[i - 1].alternativeEmail) {
              alternativeEmail = members[i - 1].alternativeEmail.replace(/,/g,'')
            }

            let phoneNumber = ''
            if (members[i - 1].phoneNumber) {
              phoneNumber = members[i - 1].phoneNumber.replace(/,/g,'')
            }

            let homeNumber = ''
            if (members[i - 1].alternativePhoneNumber) {
              homeNumber = members[i - 1].alternativePhoneNumber.replace(/,/g,'')
            } else if (members[i - 1].homeNumber) {
              homeNumber = members[i - 1].homeNumber.replace(/,/g,'')
            }

            let address = ''
            if (members[i - 1].address) {
              address = members[i - 1].address.replace(/,/g,'').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
            }

            let city = ''
            if (members[i - 1].city) {
              city = members[i - 1].city.replace(/,/g,';').replace(/#/g,"")
            }

            let state = ''
            if (members[i - 1].state) {
              state = members[i - 1].state.replace(/,/g,';').replace(/#/g,"")
            }

            let zipcode = ''
            if (members[i - 1].zipcode) {
              zipcode = members[i - 1].zipcode.replace(/,/g,';').replace(/#/g,"")
            }

            let country = ''
            if (members[i - 1].country) {
              country = members[i - 1].country.replace(/,/g,';').replace(/#/g,"")
            }

            let language = ''
            if (members[i - 1].language) {
              language = members[i - 1].language.replace(/,/g,';').replace(/#/g,"")
            }

            let race = ''
            if (members[i - 1].race) {
              race = members[i - 1].race.replace(/,/g,';').replace(/#/g,"").replace(String.fromCharCode(92),String.fromCharCode(92,92));
            }

            let races = ''
            if (members[i - 1].races && members[i - 1].races.length > 0) {
              races = members[i - 1].races.join(',').replace(/,/g,';')
            }

            let selfDescribedRace = ''
            if (members[i - 1].selfDescribedRace) {
              selfDescribedRace = members[i - 1].selfDescribedRace.replace(/,/g,';')
            }

            const gender = members[i - 1].gender

            let preferredPronoun = ''
            if (members[i - 1].preferredPronoun) {
              preferredPronoun = members[i - 1].preferredPronoun.replace(/,/g,';')
            }

            const veteranStatus = members[i - 1].veteranStatus
            const workAuthorization = members[i - 1].workAuthorization

            let numberOfMembers = ''
            if (members[i - 1].numberOfMembers) {
              numberOfMembers = members[i - 1].numberOfMembers.replace(/,/g,';')
            }

            let householdIncome = ''
            if (members[i - 1].householdIncome) {
              householdIncome = members[i - 1].householdIncome.replace(/,/g,';')
            }

            let fosterYouth = ''
            if (members[i - 1].fosterYouth) {
              fosterYouth = members[i - 1].fosterYouth.replace(/,/g,';')
            }

            let homeless = ''
            if (members[i - 1].homeless) {
              homeless = members[i - 1].homeless.replace(/,/g,';')
            }

            let incarcerated = ''
            if (members[i - 1].incarcerated) {
              incarcerated = members[i - 1].incarcerated.replace(/,/g,';')
            }

            let referrerName = ''
            if (members[i - 1].referrerName) {
              referrerName = members[i - 1].referrerName.replace(/,/g,';')
            }

            let referrerEmail = ''
            if (members[i - 1].referrerEmail) {
              referrerEmail = members[i - 1].referrerEmail.replace(/,/g,';')
            }

            let referrerOrg = ''
            if (members[i - 1].referrerOrg) {
              referrerOrg = members[i - 1].referrerOrg.replace(/,/g,';').replace(/#/g,"")
            }

            let autoEnrollInProgram = ''
            if (members[i - 1].autoEnrollInProgram) {
              autoEnrollInProgram = 'TRUE'
            }

            let primaryCareerInterest = ''
            if (members[i - 1].primaryCareerInterest) {
              primaryCareerInterest = members[i - 1].primaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
            }

            let secondaryCareerInterest = ''
            if (members[i - 1].secondaryCareerInterest) {
              secondaryCareerInterest = members[i - 1].secondaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
            }

            let tertiaryCareerInterest = ''
            if (members[i - 1].tertiaryCareerInterest) {
              tertiaryCareerInterest = members[i - 1].tertiaryCareerInterest.replace(/,/g,';').replace(/#/g,"")
            }

            let dreamCareer = ''
            if (members[i - 1].dreamCareer) {
              dreamCareer = members[i - 1].dreamCareer.replace(/,/g,';').replace(/#/g,"")
            }

            let dreamCompany = ''
            if (members[i - 1].dreamCompany) {
              dreamCompany = members[i - 1].dreamCompany.replace(/,/g,';').replace(/#/g,"")
            }

            let careerStatus = ''
            if (members[i - 1].careerStatus) {
              careerStatus = members[i - 1].careerStatus.replace(/,/g,';').replace(/#/g,"")
            }

            let fiveYearGoal = ''
            if (members[i - 1].fiveYearGoal) {
              fiveYearGoal = members[i - 1].fiveYearGoal.replace(/,/g,';').replace(/#/g,"")
            }

            let industry = ''
            if (members[i - 1].industry) {
              industry = members[i - 1].industry.replace(/,/g,';').replace(/#/g,"")
            }

            let unsubscribed = ''
            if (members[i - 1].unsubscribed) {
              unsubscribed = 'YES'
            }

            let unsubscribeReason = ''
            if (members[i - 1].unsubscribeReason) {
              unsubscribeReason = members[i - 1].unsubscribeReason.replace(/,/g,';')
            }

            let otherExplanation = ''
            if (members[i - 1].otherExplanation) {
              otherExplanation = members[i - 1].otherExplanation.replace(/,/g,';')
            }

            let placedJobTitle = ''
            if (members[i - 1].placedJobTitle) {
              placedJobTitle = members[i - 1].placedJobTitle.replace(/,/g,';')
            }

            let placedEmployerName = ''
            if (members[i - 1].placedEmployerName) {
              placedEmployerName = members[i - 1].placedEmployerName.replace(/,/g,';')
            }

            let publicProfile = ''
            if (members[i - 1].publicProfile) {
              publicProfile = 'https://www.guidedcompass.com/' + members[i - 1].username + '/profile'
              // console.log('we got a pp 1: ', publicProfile)
            }

            let headline = ''
            if (members[i - 1].headline) {
              headline = members[i - 1].headline.replace(/,/g,';')
            }

            let matchingPreferences = ''
            if (members[i - 1].matchingPreferences && members[i - 1].matchingPreferences.length > 0) {
              for (let j = 1; j <= members[i - 1].matchingPreferences.length; j++) {
                if (members[i - 1].matchingPreferences[j - 1].name && members[i - 1].matchingPreferences[j - 1].value) {
                  matchingPreferences = matchingPreferences + '; Name: ' + members[i - 1].matchingPreferences[j - 1].name.replace(/,/g,';') + '; Value: ' + members[i - 1].matchingPreferences[j - 1].value
                }
              }
            }

            let matchingUseCases = ''
            if (members[i - 1].matchingUseCases && members[i - 1].matchingUseCases.length > 0) {
              for (let j = 1; j <= members[i - 1].matchingUseCases.length; j++) {
                if (members[i - 1].matchingUseCases[j - 1].name && members[i - 1].matchingUseCases[j - 1].selected) {
                  matchingUseCases = matchingUseCases + '; Name: ' + members[i - 1].matchingUseCases[j - 1].name.replace(/,/g,';') + '; Selected: ' + members[i - 1].matchingUseCases[j - 1].selected
                }
              }
            }

            let interviews = ''
            let notes = ''
            let feedback = ''
            let isSubsidyEligible = ''
            let isWorkforceReady = ''
            let isWorkReadyCertified = ''

            if (members[i - 1].evaluations) {
              for (let j = 1; j <= members[i - 1].evaluations.length; j++) {
                if (members[i - 1].evaluations[j - 1].orgCode === this.state.org) {
                  if (members[i - 1].evaluations[j - 1].interviews) {
                    for (let k = 1; k <= members[i - 1].evaluations[j - 1].interviews.length; k++) {
                      const iName = 'Name: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].interviewerName
                      const iDate = 'Date: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].date
                      const iScore = 'Score: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].score
                      const iNotes = 'Notes: ' + members[i - 1].evaluations[j - 1].interviews[k - 1].notes
                      interviews = interviews + iName + '; ' + iDate + '; ' + iScore + '; ' + iNotes + '; '
                    }
                  }

                  if (members[i - 1].evaluations[j - 1].notes) {
                    notes = members[i - 1].evaluations[j - 1].notes
                  }

                  if (members[i - 1].evaluations[j - 1].feedback) {
                    feedback = members[i - 1].evaluations[j - 1].feedback
                  }

                  if (members[i - 1].evaluations[j - 1].isSubsidyEligible) {
                    isSubsidyEligible = members[i - 1].evaluations[j - 1].isSubsidyEligible
                  }

                  if (members[i - 1].evaluations[j - 1].isWorkforceReady) {
                    isWorkforceReady = members[i - 1].evaluations[j - 1].isWorkforceReady
                  }

                  if (members[i - 1].evaluations[j - 1].isWorkReadyCertified) {
                    isWorkReadyCertified = members[i - 1].evaluations[j - 1].isWorkReadyCertified
                  }
                }
              }
            }

            let hasWorkPermit = members[i - 1].hasWorkPermit
            const isHighPotential = members[i - 1].isHighPotential

            let isVaccinated = ''
            if (members[i - 1].isVaccinated) {
              isVaccinated = members[i - 1].isVaccinated
            }

            let pathways = ''
            if (members[i - 1].pathways && members[i - 1].pathways.length > 0) {
              for (let j = 1; j <= members[i - 1].pathways.length; j++) {
                if (members[i - 1].pathways[j - 1].title) {
                  if (pathways !== '') {
                    pathways = pathways + '; ' + members[i - 1].pathways[j - 1].title.replace(/,/g,';')
                  } else {
                    pathways = pathways + members[i - 1].pathways[j - 1].title.replace(/,/g,';')
                  }
                }
              }
            }

            let createdAt = members[i - 1].createdAt
            if (!createdAt) {
              createdAt = ''
            }

            let updatedAt = members[i - 1].updatedAt
            if (!updatedAt) {
              updatedAt = ''
            }

            console.log('show the address and number: ', address, phoneNumber)

            let adversityList = ''
            if (this.state.adversityListOptions && this.state.adversityListOptions.length > 0) {
              if (members[i - 1].adversityList && members[i - 1].adversityList.length > 0) {
                adversityList = members[i - 1].adversityList.join(',').replace(/,/g,';')
              }

              let dataArray = [
                firstName, lastName, email, roleName, resumeURL, pictureURL, dateOfBirth, age,
                educationStatus, school, degree, gradYear, major, pathway,
                jobTitle, employerName, linkedInURL, customWebsiteURL,
                instagramURL, facebookURL, twitterURL, education, certificates,
                alternativeEmail, phoneNumber, homeNumber,
                address, city, state, zipcode, country, language,
                race, races, selfDescribedRace,
                gender, preferredPronoun, veteranStatus, workAuthorization,
                numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated,
                referrerName, referrerEmail, referrerOrg, autoEnrollInProgram,
                primaryCareerInterest, secondaryCareerInterest, tertiaryCareerInterest,
                dreamCareer, dreamCompany, careerStatus, fiveYearGoal, industry,
                unsubscribed, unsubscribeReason, otherExplanation, placedJobTitle, placedEmployerName,
                publicProfile, headline, matchingPreferences, matchingUseCases,
                interviews, notes, feedback, isSubsidyEligible, isWorkforceReady,
                isWorkReadyCertified, hasWorkPermit, isHighPotential, isVaccinated,
                pathways, createdAt, updatedAt, adversityList
              ]
              for (let j = 1; j <= this.state.adversityListOptions.length; j++) {
                if (members[i - 1].adversityList && members[i - 1].adversityList.includes(this.state.adversityListOptions[j - 1])) {
                  dataArray.push("TRUE")
                } else {
                  dataArray.push("FALSE")
                }
              }
              csvData.push(dataArray)

            } else {
              if (members[i - 1].adversityList && members[i - 1].adversityList.length > 0) {
                adversityList = members[i - 1].adversityList.join(',').replace(/,/g,';')
              }

              csvData.push([
                firstName, lastName, email, roleName, resumeURL, pictureURL, dateOfBirth, age,
                educationStatus, school, degree, gradYear, major, pathway,
                jobTitle, employerName, linkedInURL, customWebsiteURL,
                instagramURL, facebookURL, twitterURL, education, certificates,
                alternativeEmail, phoneNumber, homeNumber,
                address, city, state, zipcode, country, language,
                race, races, selfDescribedRace,
                gender, preferredPronoun, veteranStatus, workAuthorization,
                numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated,
                referrerName, referrerEmail, referrerOrg, autoEnrollInProgram,
                primaryCareerInterest, secondaryCareerInterest, tertiaryCareerInterest,
                dreamCareer, dreamCompany, careerStatus, fiveYearGoal, industry,
                unsubscribed, unsubscribeReason, otherExplanation, placedJobTitle, placedEmployerName,
                publicProfile, headline, matchingPreferences, matchingUseCases,
                interviews, notes, feedback, isSubsidyEligible, isWorkforceReady,
                isWorkReadyCertified, hasWorkPermit, isHighPotential, isVaccinated,
                pathways, createdAt, updatedAt, adversityList
              ])
            }

            if (this.state.org === 'c2c') {

              let politicalAlignment = ''
              if (members[i - 1].politicalAlignment) {
                politicalAlignment = members[i - 1].politicalAlignment.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              let stateRegistration = ''
              if (members[i - 1].stateRegistration) {
                stateRegistration = members[i - 1].stateRegistration.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/,/g,"")
              }

              let currentCongressionalDistrict = ''
              if (members[i - 1].currentCongressionalDistrict) {
                currentCongressionalDistrict = members[i - 1].currentCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              let hometown = ''
              if (members[i - 1].hometown) {
                hometown = members[i - 1].hometown.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              let homeCongressionalDistrict = ''
              if (members[i - 1].homeCongressionalDistrict) {
                homeCongressionalDistrict = members[i - 1].homeCongressionalDistrict.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              let careerTrack = ''
              if (members[i - 1].careerTrack) {
                careerTrack = members[i - 1].careerTrack.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              let dacaStatus = ''
              if (members[i - 1].dacaStatus) {
                dacaStatus = members[i - 1].dacaStatus.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
              }

              // .concat([appRequirements,applicationMethod,jobLink,subjectLine])
              let tempData2 = csvData[csvData.length - 1]
              tempData2[csvData[0].length - 7] = politicalAlignment
              tempData2[csvData[0].length - 6] = stateRegistration
              tempData2[csvData[0].length - 5] = currentCongressionalDistrict
              tempData2[csvData[0].length - 4] = hometown
              tempData2[csvData[0].length - 3] = homeCongressionalDistrict
              tempData2[csvData[0].length - 2] = careerTrack
              tempData2[csvData[0].length - 1] = dacaStatus
              csvData[csvData.length - 1] = tempData2
            }

          }
        }

        // csvData.push(
        //   [ "First Name","Last Name","Email","Course","Report Type","Title","Assignment", "Grade","Grade Explanation","Message Type","Message","Created At", "Updated At" ]
        // )
        //
        // let commaStartingValue = /,/g
        // let commaReplacementValue = ";"
        //
        // if (this.state.submissions && this.state.submissions.length > 0) {
        //   for (let i = 1; i <= this.state.submissions.length; i++) {
        //     const item = this.state.submissions[i - 1]
        //     if (this.state.selectedMember.email === item.emailId) {
        //       const firstName = selectedMember.firstName
        //       const lastName = selectedMember.lastName
        //       let email = selectedMember.email
        //
        //       let course = 'N/A'
        //       if (item.courseName) {
        //         course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //       }
        //
        //       const reportType = 'Submission'
        //
        //       let title = ''
        //       if (item.title) {
        //         title = item.title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //       }
        //
        //       let assignment = ''
        //       if (item.assessmentName) {
        //         assignment = item.assessmentName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //       } else if (grades[i - 1].lessonName) {
        //         assignment = item.lessonName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //       }
        //
        //       let grade = ''
        //       if (item.grade) {
        //         grade = item.grade
        //       }
        //
        //       let gradeExplanation = ''
        //       if (item.gradeExplanation) {
        //         gradeExplanation = item.gradeExplanation.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //       }
        //
        //       const direction = ''
        //       const message = ''
        //
        //       const createdAt = item.createdAt
        //       const updatedAt = item.updatedAt
        //
        //       csvData.push([
        //         firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
        //         direction, message, createdAt, updatedAt
        //       ])
        //     }
        //   }
        // }
        //
        // if (this.state.messages && this.state.messages.length > 0) {
        //   for (let i = 1; i <= this.state.messages.length; i++) {
        //     const item = this.state.messages[i - 1]
        //
        //     const firstName = selectedMember.firstName
        //     const lastName = selectedMember.lastName
        //     let email = selectedMember.email
        //
        //     let course = 'N/A'
        //     if (item.courseName) {
        //       course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //     }
        //
        //     const reportType = 'A.I. Conversation'
        //
        //     let title = ''
        //     let assignment = ''
        //     let grade = ''
        //     let gradeExplanation = ''
        //
        //     let direction = 'Received'
        //     if (item.senderEmail === email) {
        //       direction = 'Sent'
        //     }
        //
        //     let message = ''
        //     if (item.message) {
        //       message = item.message.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
        //     }
        //
        //     const createdAt = item.createdAt
        //     const updatedAt = item.updatedAt
        //
        //     csvData.push([
        //       firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
        //       direction, message, createdAt, updatedAt
        //     ])
        //   }
        // }

        const returnedData = subExportCSV("Members_Report",csvData)
        // console.log('returnedData response: ', returnedData)

      }
    }

    calculateAge(dateOfBirth) {
      console.log('calculateAge called ', dateOfBirth)

      let age = ''
      if (new Date(dateOfBirth)) {
        // difference between dates rounded to the nearest year

        let diff = new Date().getTime() - new Date(dateOfBirth).getTime()
        console.log('diff1: ', diff)
        diff = diff / 1000
        console.log('diff2: ', diff)
        diff = diff / (60 * 60 * 24)
        console.log('diff3: ', diff)
        diff = diff / (365.25)
        console.log('diff4: ', diff)
        age = Math.floor(diff)
      }

      return age
    }

    render() {

      let headerClassName = 'full-width'
      let passedClassName = 'full-width'
      let passedStyle = { ...styles, opacity: this.state.opacity, transform: this.state.transform, maxWidth: '1400px', marginLeft: 'auto', marginRight: 'auto', float: 'none', padding: '20px' }
      let widthClass1 = 'fixed-column-135'
      let widthClass2 = 'calc-column-offset-135'

      let paginationClassName = "floating-container"

      let passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, margin: '20px auto 0px auto', float: 'none', width: '94%', maxWidth: '1400px', padding: '20px', height: 'auto'}
      let passedPlaceholder = 'Search members by name or email...'
      let pathname = '/organizations/' + this.state.org + '/members/invite'
      let showAddButton = true
      let pageDescription = null
      let searchClassName = "floating-container card top-margin-2-percent right-margin-2-percent"
      let memberClass = "card-clear-padding top-margin-40 bottom-margin-40"
      let headerFontSize = "heading-text-2"
      let actionItemTopMargin = "top-margin-8"

      if (window.location.pathname.endsWith('/dashboard')) {

        headerClassName = 'card-clear-padding top-margin-40 bottom-margin-40'
        passedStyle = {}

        passedClassName = 'card top-margin-40 bottom-margin-40'
        pageDescription = "All members of " + this.state.orgName
        searchClassName = "card top-margin-40 bottom-margin-40"
        passedStyle2 = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        memberClass = "card-clear-padding top-margin-40 bottom-margin-40"
        headerFontSize = "heading-text-3"
        actionItemTopMargin = "top-margin-3"

        widthClass1 = 'fixed-column-140'
        widthClass2 = 'calc-column-offset-140-static'

      } else if (this.props.type === 'Students' || this.props.type === 'Career-Seekers') {

        headerClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
        widthClass1 = 'fixed-column-45'
        widthClass2 = 'calc-column-offset-45'
        passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
        passedPlaceholder = 'Search ' + this.props.type.toLowerCase() + ' by name or email...'
        showAddButton = false
        if (this.state.studentAliasPlural) {
          pageDescription = "All " + this.state.studentAliasPlural + " in " + this.state.orgName
        } else {
          pageDescription = "All career-seekers in " + this.state.orgName
        }

        paginationClassName = "floating-container"
        if (window.location.pathname.includes('/organizations') || window.location.pathname.startsWith('/advisor')) {
          if (this.state.courseIds || window.location.pathname.includes('/organizations')) {
            widthClass1 = 'fixed-column-90'
            widthClass2 = 'calc-column-offset-90'
            if (this.props.path && this.props.path.includes('/advisor/')) {
              pathname = '/advisor/students/add'
            } else {
              pathname = this.props.path + '/add'
            }

            showAddButton = true
          }
        }

        memberClass = "floating-container white-background medium-shadow top-margin-15 bottom-margin-40"

      } else if (this.props.type === 'Educators') {
        headerClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
        passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
        passedPlaceholder = 'Search ' + this.props.type.toLowerCase() + ' by name or email...'
        pathname = '/organizations/' + this.state.org + '/educators/add'
        showAddButton = true
        pageDescription = "All educators in " + this.state.orgName
        paginationClassName = "floating-container"
        memberClass = "floating-container white-background medium-shadow top-margin-15 bottom-margin-40"
      } else if (this.props.type === 'Mentors') {
        headerClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
        passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
        passedPlaceholder = 'Search ' + this.props.type.toLowerCase() + ' by name or email...'
        pathname = '/organizations/' + this.state.org + '/mentors/add'
        showAddButton = true
        if (this.state.mentorAliasPlural) {
          pageDescription = "All " + this.state.mentorAliasPlural + " in " + this.state.orgName
        } else {
          pageDescription = "All mentors in " + this.state.orgName
        }

        paginationClassName = "floating-container"
        if (this.props.path && this.props.path.includes('/advisor')) {
          pathname = this.props.path + '/add'
        }
        memberClass = "floating-container white-background medium-shadow top-margin-15 bottom-margin-40"
      } else if (this.props.type === 'All') {
        headerClassName = 'standard-container-2'
        passedClassName = 'standard-container-2'
        pageDescription = "All members of " + this.state.orgName
        searchClassName = "card width-70-percent max-width-1400 center-horizontally top-margin-2-percent"
        passedStyle2 = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        memberClass = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally white-background medium-shadow bottom-margin-40"
      }

      if (toggleVars.hideSideNav && window.location.pathname.includes('/advisor')) {
        headerClassName = 'standard-container-2'
        passedClassName = 'standard-container-2'
        pageDescription = "All members of " + this.state.orgName
        searchClassName = "card width-70-percent max-width-1400 center-horizontally top-margin-2-percent"
        passedStyle2 = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        memberClass = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally white-background medium-shadow bottom-margin-40"
      } else if (toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations') && !window.location.pathname.endsWith('/dashboard')) {
        headerClassName = 'width-70-percent top-margin-2-percent max-width-1400 center-horizontally card'
        passedClassName = 'width-70-percent top-margin-2-percent max-width-1400 center-horizontally card'
        // pageDescription = "All members of " + this.state.orgName
        searchClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card"
        passedStyle2 = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        memberClass = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally white-background medium-shadow bottom-margin-40"

        // widthClass1 = 'fixed-column-140'
        // widthClass2 = 'calc-column-offset-140'
      }

      return (
          <div>
              <div>
                <div className={headerClassName} style={passedStyle}>
                  {(window.innerWidth > 768 && window.location.pathname.endsWith('/dashboard')) && (
                    <div className="full-width height-5 dark-background" />
                  )}

                  <div className={(window.location.pathname.endsWith('/dashboard')) ? "padding-20" : ""}>
                    <div className={widthClass2}>
                      <div className="spacer" />
                      {(this.props.type === 'All') ? (
                        <p className={headerFontSize}>{(this.state.memberCount) ? Number(this.state.memberCount).toLocaleString() : "0"} Members</p>
                      ) : (
                        <p className={headerFontSize}>{(this.state.memberCount) ? Number(this.state.memberCount).toLocaleString() : "0"} {this.state.pageTitle}</p>
                      )}
                      {/*
                      {(pageDescription) && (
                        <p className="description-text-2 row-5 standard-color">{pageDescription}</p>
                      )}*/}
                    </div>
                    <div className={widthClass1}>
                      {(showAddButton) && (
                        <div className={"float-right " + actionItemTopMargin}>
                          <div className="half-spacer" />
                          <button className="background-button float-left" to={pathname} onClick={() => this.setState({ modalIsOpen: true, showAddStudentInstrux: true })}>
                            <img src={addIcon} alt="GC" className="image-25-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add members" />
                          </button>
                        </div>
                      )}
                      <div className={"float-right right-padding-20 " + actionItemTopMargin}>
                        <div className="half-spacer" />
                        <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                          <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="GC" className="image-25-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Search and filter members" />
                        </button>
                      </div>
                      {(window.location.pathname.endsWith('/dashboard')) && (
                        <div className={"float-right right-padding-20 " + actionItemTopMargin}>
                          <div className="half-spacer" />
                          <button onClick={() => this.exportInfo()} className="background-button">
                            <img src={downloadIconDark} alt="GC" className="image-25-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Export members" />
                          </button>
                        </div>
                      )}
                      {(this.props.type === 'Mentors' && this.state.org === 'fosternation') && (
                        <div className="float-right top-margin right-padding-20">
                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showBulkMatch: true })}>
                            <img className="image-auto-25" alt="GC" src={matchIcon} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Bulk match mentee-mentors" />
                          </button>
                        </div>
                      )}
                    </div>
                    <ReactTooltip id="tooltip-placeholder-id" />

                    <div className="clear" />
                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.showingSearchBar) && (
                  <div className={searchClassName} style={passedStyle2}>
                    <div>
                      <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width">
                        <div className="float-left display-line top-padding-8 left-padding-3">
                          <img src={searchIcon} alt="GC" className="image-auto-20"/>
                        </div>
                        <div className="calc-column-offset-23">
                          <input type="text" className="text-field clear-border" placeholder={passedPlaceholder} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                      </div>
                      <div className="clear" />
                      <div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Filter</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('filter'))}

                      <div className="clear" />
                      <div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Sort</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('sort'))}
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.animating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      {(this.state.orgFocus === 'Placement') ? (
                        <p className="center-text cta-color bold-text">Calculating results...</p>
                      ) : (
                        <p className="center-text cta-color bold-text">Calculating results and work matches...</p>
                      )}

                    </div>
                  </div>
                ) : (
                  <div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5 center-text">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5 center-text">{this.state.successMessage}</p>}

                    {(this.state.members.length > 0) && (
                      <div className={paginationClassName}>
                        <div className="top-margin-2-percent">
                          <div className="relative-column-20">
                            <button className={(this.state.pageNumber === 1) ? "background-button full-width left-text wash-out-2" : "background-button full-width left-text"} disabled={(this.state.pageNumber === 1) ? true : false} onClick={() => this.movePage(false)}>
                              <div className="fixed-column-20">
                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 rotate-180" />
                              </div>
                              <div className="calc-column-offset-20">
                                <p className="full-width left-text">Previous</p>
                              </div>
                              <div className="clear" />
                            </button>
                          </div>
                          <div className="relative-column-60">
                            <p className="full-width center-text">{this.state.pageCountStart} - {(this.state.pageCountEnd < this.state.memberCount) ? this.state.pageCountEnd : this.state.memberCount} of {(this.state.memberCount) ? Number(this.state.memberCount).toLocaleString() : "0"}</p>
                            <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showChangeResultsWidget: true })}><p className="full-width center-text cta-color bold-text description-text-3 top-margin-7">Change results per page</p></button>
                          </div>
                          <div className="relative-column-20">
                            <button className={(this.state.pageCountEnd === this.state.memberCount) ? "background-button full-width right-text wash-out-2" : "background-button full-width right-text"} disabled={(this.state.pageCountEnd === this.state.memberCount) ? true : false} onClick={() => this.movePage(true)}>
                              <div className="calc-column-offset-20">
                                <p className="full-width right-text">Next</p>
                              </div>
                              <div className="fixed-column-20 right-text">
                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 pin-right" />
                              </div>
                              <div className="clear" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    {(this.state.showModule) ? (
                      <div>
                        {/*
                        <div className="row-10 relative-position">
                          <div className="calc-column-offset-90">
                            <p className="heading-text-2">{(this.state.filteredMembers) ? this.state.filteredMembers.length : "0"} Members</p>
                          </div>

                          <div className="fixed-column-90 flex-container align-end">
                            <button className={(this.state.showFilters) ? "btn btn-small medium-shadow pin-right" : "btn btn-small white-background cta-color medium-shadow pin-right"} onClick={(this.state.showFilters) ? () => this.setState({ showFilters: false }) : () => this.setState({ showFilters: true })}>Filter</button>
                          </div>
                          <div className="clear" />

                          {(this.state.showFilters) && (
                            <div className="absolute-position right-0 top-0 card-clear-padding padding-10 top-margin-50 description-text-3">
                              {(this.state.dashFilters) && (
                                <div>
                                  <button className="background-button full-width right-text row-5 cta-color" onClick={() => this.filterValues("Clear")}>
                                    <p>Clear</p>
                                  </button>

                                  {this.state.dashFilters.map((value, index) =>
                                    <div key={"filter" + index} className="row-5">
                                      <div className="calc-column-offset-30">
                                        <p>{value.name}</p>
                                      </div>
                                      <div className="fixed-column-30">
                                        <button className="background-button float-right" onClick={() => this.filterValues(value.name)}>
                                          <img className="image-auto-15" alt="GC" src={(value.checked) ? checkboxChecked : checkboxEmptyIcon} />
                                        </button>
                                        <div className="clear" />
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>*/}

                        <div className={memberClass}>
                          {(window.innerWidth > 768) ? (
                            <div className="selected-background-light description-text-3 bold-text horizontal-padding-4">
                              <div className="fixed-column-40">
                                <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('numeric',this.state.members)}>
                                  <p>#</p>
                                </button>
                              </div>
                              <div className="fixed-column-35">
                                <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('pic',this.state.members)}>
                                  <p>Pic</p>
                                </button>
                              </div>
                              <div className={(this.state.org === 'fosternation') ? "calc-column-offset-295" : "calc-column-offset-240"}>
                                <div className="relative-column-25">
                                  <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('firstName',this.state.members)}>
                                    <p>Name</p>
                                  </button>
                                </div>
                                <div className="relative-column-30">
                                  <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('email',this.state.members)}>
                                    <p>Email</p>
                                  </button>
                                </div>
                                <div className="relative-column-20">
                                  <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('roleName',this.state.members)}>
                                    <p>Role Name</p>
                                  </button>
                                </div>
                                <div className="relative-column-25">
                                  {(this.state.dashboardLayout === "Mentor") ? (
                                    <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('pair',this.state.members)}>
                                      <p>Pair</p>
                                    </button>
                                  ) : (
                                    <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('phoneNumber',this.state.members)}>
                                      <p>Phone Number</p>
                                    </button>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                              <div className={(this.state.org === 'fosternation') ? "fixed-column-220" : "fixed-column-165"}>
                                {(this.state.org === 'fosternation') && (
                                  <div className="fixed-column-55">
                                    <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('cohort',this.state.members)}>
                                      <p>Cohort</p>
                                    </button>
                                  </div>
                                )}
                                <div className="fixed-column-55">
                                  <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('enrollmentStatus',this.state.members)}>
                                    <p>Status</p>
                                  </button>
                                </div>
                                {(this.state.dashboardLayout === "Mentor") ? (
                                  <span>
                                    <div className="fixed-column-55">
                                      <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('submissionCount',this.state.members)}>
                                        <p># Sub</p>
                                      </button>
                                    </div>
                                    <div className="fixed-column-55">
                                      <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('avgRating',this.state.members)}>
                                        <p>Rating</p>
                                      </button>
                                    </div>
                                  </span>
                                ): (
                                  <div className="fixed-column-110">
                                    <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('createdAt',this.state.members)}>
                                      <p>Joined</p>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>
                          ) : (
                            <div className="selected-background-light description-text-3 bold-text horizontal-padding-4">
                              <div className="fixed-column-40">
                                <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('numeric',this.state.members)}>
                                  <p>#</p>
                                </button>
                              </div>
                              <div className="fixed-column-35">
                                <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('pic',this.state.members)}>
                                  <p>Pic</p>
                                </button>
                              </div>
                              <div className="calc-column-offset-75">
                                <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('firstName',this.state.members)}>
                                  <p>Name</p>
                                </button>
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          <hr />

                          {(this.state.filteredMembers) && (
                            <div>
                              {this.state.filteredMembers.map((value, index) =>
                                <div key={value.email}>
                                  {(window.innerWidth > 768) ? (
                                    <div className="padding-10 description-text-3">
                                      <div className="fixed-column-45">
                                        <button className="background-button full-width left-text" onClick={() => this.sortValues('firstName',this.state.members)}>
                                          <p>{this.state.pageCountStart + index}.</p>
                                        </button>
                                      </div>
                                      <div className="fixed-column-40">
                                        <button className="background-button full-width left-text" onClick={() => this.sortValues('firstName',this.state.members)}>
                                          {(value.pictureURL) ? <a href={value.pictureURL} target="_blank" rel="noopener noreferrer">Link</a> : <p>-</p>}
                                        </button>
                                      </div>
                                      <div className={(this.state.org === 'fosternation') ? "calc-column-offset-305" : "calc-column-offset-250"}>
                                        <div className="relative-column-25">
                                          <Link to={this.state.pathPrefix + '/members/' + value.username} className="background-button">
                                            <p>{value.firstName} {value.lastName}</p>
                                          </Link>
                                        </div>
                                        <div className="relative-column-30">
                                          <p>{value.email}</p>
                                        </div>
                                        <div className="relative-column-20">
                                          <p>{this.translateText('roleName',value.roleName)}</p>
                                        </div>
                                        <div className="relative-column-25">
                                          {(this.state.dashboardLayout === "Mentor") ? (
                                            <div>
                                              {(value.pair) ? (
                                                <Link to={this.state.pathPrefix + '/members/' + value.pair.username} className="background-button">
                                                  <p>{value.pair.firstName + " " + value.pair.lastName}</p>
                                                </Link>
                                              ) : (
                                                <p>-</p>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {(value.phoneNumber) ? (
                                                <Link to={this.state.pathPrefix + '/members/' + value.username} className="background-button">
                                                  <p>{value.phoneNumber}</p>
                                                </Link>
                                              ) : (
                                                <p>-</p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="clear" />
                                      </div>
                                      <div className={(this.state.org === 'fosternation') ? "fixed-column-220" : "fixed-column-165"}>
                                        {(this.state.org === 'fosternation') && (
                                          <div className="fixed-column-55">
                                            <p>{(value.cohort) ? value.cohort : "-"}</p>
                                          </div>
                                        )}
                                        <div className="fixed-column-55">
                                          <p className="curtail-text">{(value.enrollmentStatus) ? value.enrollmentStatus : "-"}</p>
                                        </div>
                                        {(this.state.dashboardLayout === "Mentor") ? (
                                          <span>
                                            <div className="fixed-column-55">
                                              {(value.submissionCount) ? (
                                                <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showSubmissions: true, selectedMember: value })}>
                                                  <p>{value.submissionCount}</p>
                                                </button>
                                              ) : (
                                                <p>-</p>
                                              )}
                                            </div>
                                            <div className="fixed-column-55">
                                              {(value.avgRating) ? (
                                                <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showSubmissions: true, selectedMember: value })}>
                                                  <p>{value.avgRating}</p>
                                                </button>
                                              ) : (
                                                <p>-</p>
                                              )}
                                            </div>
                                          </span>
                                        ): (
                                          <div className="fixed-column-110">
                                            {(value.createdAt) ? (
                                              <p>{convertDateToString(new Date(value.createdAt),'date-2')}</p>
                                            ) : (
                                              <p>-</p>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  ) : (
                                    <div className="padding-10 description-text-3">
                                      <div className="fixed-column-45">
                                        <button className="background-button full-width left-text" onClick={() => this.sortValues('firstName',this.state.members)}>
                                          <p>{this.state.pageCountStart + index}.</p>
                                        </button>
                                      </div>
                                      <div className="fixed-column-40">
                                        <button className="background-button full-width left-text" onClick={() => this.sortValues('firstName',this.state.members)}>
                                          {(value.pictureURL) ? <a href={value.pictureURL} target="_blank" rel="noopener noreferrer">Link</a> : <p>-</p>}
                                        </button>
                                      </div>
                                      <div className="calc-column-offset-85">
                                        <Link to={this.state.pathPrefix + '/members/' + value.username} className="background-button">
                                          <p>{value.firstName} {value.lastName}</p>
                                        </Link>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={passedClassName} style={passedStyle}>
                        {(this.state.serverSuccessMessage && this.state.serverSuccessMessage !== '') && <p className='success-message row-10'>{this.state.serverSuccessMessage}</p>}
                        {this.renderMembers()}

                        <div className="spacer" />

                      </div>
                    )}
                  </div>
                )}

              </div>

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               >
                {(this.state.showBulkMatch) && (
                  <div key="showBulkMatch" className="full-width padding-20">
                    {/*<SubEditLesson history={this.props.navigate} passLesson={this.passLesson} passCurriculumItem={this.passCurriculumItem} closeModal={this.closeModal} selectedIndex={this.state.selectedIndex} selectedCurriculumItem={null} editLesson={true} />*/}
                    <p className="heading-text-2">Bulk-Match Mentors to Mentees</p>
                    <div className="spacer" /><div className="spacer" />

                    {(this.state.isSaving) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Bulk matching...</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="row-10">
                          <p>This will automatically match each mentor with one mentee based on the selected algorithm</p>
                        </div>

                        <div className="row-10">
                          <p className="error-color">This will also automatically delete ALL existing mentor-mentee pairings, so make sure you want to do this!</p>
                        </div>

                        <div className="row-30">
                          <select name={"mentorMatchingAlgorithm"} className="dropdown" value={this.state.mentorMatchingAlgorithm.name} onChange={this.formChangeHandler}>
                            {this.state.mentorMatchingAlgorithmOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                          </select>
                        </div>

                        <div className="spacer" /><div className="spacer" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.bulkMatch()}>Bulk Match</button>
                        <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                      </div>
                    )}
                  </div>
                )}

                {(this.state.showAddStudentInstrux) && (
                  <div key="showStudentInstrux" className="full-width padding-20">
                    <div>
                      <div className="calc-column-offset-30">
                        <p className="heading-text-2">How to Add Members</p>
                      </div>
                      <div className="fixed-column-30 top-margin">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img className="image-auto-20" alt="GC" src={closeIcon} />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    {(this.state.authType) ? (
                      <div>
                        {(this.state.authType === "Clever") ? (
                          <div className="description-text-1">
                            <p className="full-width">Clever should automatically make an account available for your students after you install the app. The Clever website provides more details <a href="https://support.clever.com/hc/s/articles/360013488672?language=en_US#Header4" target="_blank" rel="noopener noreferrer">here</a>.</p>
                          </div>
                        ) : (
                          <div className="description-text-1">
                            <p className="full-width">When you installed the app from the Schoology LMS App Center, make sure you clicked the courses and groups that represent the students who you want to access. When the students sign in and go to the course or group within Schoology, they should be able to access the app.</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="row-20">
                        <div className="description-text-1">
                          <p className="full-width">Invite members to join <Link to={"/partners/" + this.state.org} target="_blank" rel="noopener noreferrer">here</Link>.</p>
                        </div>
                      </div>
                    )}

                    <div className="row-20">
                      <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                    </div>

                  </div>
                )}

                {(this.state.showSubmissions) && (
                  <div>
                    <div className="calc-column-offset-30">
                      <p className="heading-text-2">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName} Submissions</p>
                    </div>
                    <div className="fixed-column-30 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />

                   <div className="spacer" /><div className="spacer" />

                   {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                   {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                   {this.state.selectedMember.submissions.map((value, index) =>
                     <div key={value.name} className="row-3">
                       <div className="card standard-border">
                         <div className="row-10">
                           <div className="calc-column-offset-30">
                             <p className="heading-text-5">{index + 1}. {(value.assessmentName) ? value.assessmentName : "Submission"}</p>
                             <p className="description-text-2 top-margin">Submitted {convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                           </div>
                           <div className="fixed-column-30 top-margin-5">
                             <button className="background-button" onClick={() => this.toggleConfirmDelete(index, false)}>
                               <img src={xIcon} alt="GC" className="image-auto-20" />
                             </button>
                           </div>
                           <div className="clear" />
                         </div>

                         {(value.confirmDelete) && (
                           <div className="row-10">
                             <p className='error-color'>Are you sure you want to delete?</p>
                             <div className="row-5">
                               <button className="btn btn-squarish error-background-color error-border-color right-margin" onClick={() => this.toggleConfirmDelete(index, true)}>Delete Permanently</button>
                               <button className="btn btn-squarish white-background cta-color" onClick={() => this.toggleConfirmDelete(index, false)}>Cancel</button>
                             </div>
                           </div>
                         )}

                         {(value.answers) && (
                           <div>
                             {value.answers.map((value2, index2) =>
                               <div key={value2.question} className="top-margin">
                                 <div className="row-5">
                                   <p className="description-text-3 description-text-color bold-text">{index2 + 1}. {value2.question}</p>
                                 </div>
                                 <div className="row-5">
                                   <p className="description-text-1">{value2.answer}</p>
                                 </div>
                               </div>
                             )}
                           </div>
                         )}
                       </div>

                       <div className="spacer" /><div className="spacer" />
                       {/*
                       <hr />*/}
                       <div className="spacer" />
                     </div>
                   )}
                  </div>
                )}

                {(this.state.showChangeResultsWidget) && (
                  <div key="showChangeResultsWidget" className="full-width padding-20">
                    <div className="row-15">
                      <div className="calc-column-offset-30">
                        <p className="heading-text-2">Change the Number of Results per Page</p>
                        {/*<p className="heading-text-5 top-margin">This will change the number of members shown on each page.</p>*/}
                      </div>
                      <div className="fixed-column-30 top-margin">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img className="image-auto-20" alt="GC" src={closeIcon} />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-30">
                      <label className="profile-label">Results per Page</label>
                      <input className="number-field" type="number" placeholder={this.state.minResLimit} name="resLimit" min={this.state.minResLimit} max={this.state.maxResLimit} value={this.state.resLimit} onChange={this.formChangeHandler}/>
                      <label className="profile-descriptor top-margin-5">Results must be between {this.state.minResLimit} and {this.state.maxResLimit}</label>
                    </div>

                    <div className="row-15 top-margin-20">
                      <button className="btn btn-primary" onClick={() => this.reloadMembers(this.state.org, null, 1, 'Mentor')}>Pull Results</button>
                    </div>

                  </div>
                )}

              </Modal>
          </div>
      )
    }
}


export default withRouter(Members);
