import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAdminApplicationDetail from './Subcomponents/AdminApplicationDetail';
import withRouter from './Functions/WithRouter';

class EmpApplicationDetail extends Component {
  constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)

  }

  componentDidMount(){
    document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

    const { emp, id, candidateId } = this.props.params

    let selectedApplication = null
    let selectedJob = null
    let benchmark = null
    let benchmarkOptions = null
    let noBenchmark = true
    if (this.props.location && this.props.location.state) {
      selectedApplication = this.props.location.state.selectedApplication
      selectedJob = this.props.location.state.selectedJob
      benchmark = this.props.location.state.benchmark
      benchmarkOptions = this.props.location.state.benchmarkOptions

      if (benchmark && (benchmark.orgCode || benchmark.accountCode)) {
        noBenchmark = false
      }
      // let { selectedApplication, selectedJob, benchmark, benchmarkOptions } = this.props.location.state;
      // console.log('show me all details ', selectedApplication, selectedJob, benchmark)
    }

    //obtain email id from localStorage
    let email = localStorage.getItem('email');
    let username = localStorage.getItem('username');
    let cuFirstName = localStorage.getItem('firstName');
    let cuLastName = localStorage.getItem('lastName');
    let activeOrg = localStorage.getItem('activeOrg')
    const orgLogo = localStorage.getItem('orgLogo');

    let orgMode = true
    if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
      orgMode = false
    }

    if (email) {

      this.setState({
          emailId: email, username, cuFirstName, cuLastName, emp, activeOrg, orgMode, orgLogo,
          selectedJob, selectedApplication, benchmark, benchmarkOptions, noBenchmark, id, candidateId
      });
    }
  }

  loadWorkspace(org) {
    console.log('loadWorkspace called', org)
    this.setState({ org })
  }

  render() {

    return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
          <SubAdminApplicationDetail accountCode={this.state.emp} org={this.state.activeOrg} id={this.state.id} selectedJob={this.state.selectedJob} selectedApplication={this.state.selectedApplication} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} noBenchmark={this.state.noBenchmark} history={this.props.navigate} candidateId={this.state.candidateId} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>

    )
  }
}

export default withRouter(EmpApplicationDetail)
