import React, {Component} from 'react';

// const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';

class ExperienceDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonGoalDetails', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedExperience !== prevProps.selectedExperience) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within commonGoalDetails', this.props)

      const email = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      // const accountCode = localStorage.getItem('accountCode');

      // const subscriptionType = null
      const selectedExperience = this.props.selectedExperience

      // jobTitle, employerName, startDate, endDate, isContinual,

      // jobFunction, industry, wasPaid, hoursPerWeek,
      // skillTags, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorEmail,
      // createdAt, updatedAt

      // workInterest, workSkill, teamInterest, employerInterest, payInterest, overallFit,
      // description,

      let fields = []
      if (selectedExperience) {
        if (selectedExperience.jobFunction) {
          fields.push({ name: "Work Function", value: selectedExperience.jobFunction })
        }
        if (selectedExperience.industry) {
          fields.push({ name: "Industry", value: selectedExperience.industry })
        }
        if (selectedExperience.wasPaid) {
          fields.push({ name: "Paid Experience", value: selectedExperience.industry })
        } else {
          fields.push({ name: "Volunteer", value: selectedExperience.industry })
        }
        if (selectedExperience.hoursPerWeek) {
          fields.push({ name: "Hours per Week", value: selectedExperience.hoursPerWeek })
        }
        if (selectedExperience.skillTags) {
          fields.push({ name: "Skill Tags", value: selectedExperience.skillTags.toString().replace(/,/g,", ") })
        }
        if (selectedExperience.supervisorFirstName) {
          fields.push({ name: "Supervisor First Name", value: selectedExperience.supervisorFirstName })
        }
        if (selectedExperience.supervisorEmail) {
          fields.push({ name: "Supervisor Email", value: selectedExperience.supervisorEmail })
        }
        if (selectedExperience.supervisorLastName) {
          fields.push({ name: "Supervisor Last Name", value: selectedExperience.supervisorLastName })
        }
        if (selectedExperience.updatedAt) {
          fields.push({ name: "Last Updated", value: selectedExperience.updatedAt })
        }
        if (selectedExperience.overallFit) {
          fields.push({ name: "Overall Fit (Scale 1-5)", value: selectedExperience.overallFit })
        }
        if (selectedExperience.workInterest) {
          fields.push({ name: "Work Fit (Scale 1-5)", value: selectedExperience.workInterest   })
        }
        if (selectedExperience.workSkill) {
          fields.push({ name: "Skill Fit (Scale 1-5)", value: selectedExperience.workSkill })
        }
        if (selectedExperience.teamInterest) {
          fields.push({ name: "Team Fit (Scale 1-5)", value: selectedExperience.teamInterest })
        }
        if (selectedExperience.employerInterest) {
          fields.push({ name: "Employer Fit (Scale 1-5)", value: selectedExperience.employerInterest   })
        }
        if (selectedExperience.payInterest) {
          fields.push({ name: "Pay Fit (Scale 1-5)", value: selectedExperience.payInterest   })
        }
      }

      this.setState({
        emailId: email, email, cuFirstName, cuLastName, orgFocus, roleName, activeOrg,
        selectedExperience, fields
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      this.setState({ [event.target.name]: event.target.value })
    }

    render() {

      return (
        <div>
          {(this.state.selectedExperience) && (
            <div>
              <div className="row-10 full-width">
                <p className="heading-text-2 center-text">{this.state.selectedExperience.jobTitle} @ {this.state.selectedExperience.employerName}</p>
                <p className="top-padding-5 description-text-color center-text">{this.state.selectedExperience.startDate} - {(this.state.selectedExperience.isContinual) ? "Present" : this.state.selectedExperience.endDate}</p>
                <div className="spacer" />

                <p className="row-10 description-text-color center-text">{this.state.selectedExperience.description}</p>

                <div className="spacer" />

                {(this.state.fields) && (
                  <div>
                    {this.state.fields.map((item,index) =>
                      <div key={item} className="top-margin-20 bottom-margin-20">
                        <div className="relative-column-33">
                          <label className="description-text-2 description-text-color row-10">{item.name}</label>
                          <p>{item.value}</p>
                        </div>
                        {(index % 3 === 2) && (
                          <div className="clear" />
                        )}
                      </div>
                    )}
                  </div>
                )}

              </div>

              <div className="row-10">
              </div>
            </div>
          )}
        </div>
      )
    }
}

export default ExperienceDetails;
