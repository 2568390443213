import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCoursesTwo from '../Subcomponents/CoursesTwo';
import SubRenderProfiles from '../Common/RenderProfiles';
import SubComments from '../Common/Comments';
import {saveCourseChanges} from '../Services/CourseRoutes';
import {subExportCSV} from '../Functions/ExportCSV';
import {toggleVars} from '../Functions/ToggleVars';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const importIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/import-icon-blue.png";
const udemyLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udemy-logo.png";
const courseraLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/coursera-logo.png";
const udacityLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udacity-logo.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const infoIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png";
const courseIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png";
const targetIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png";
const socialIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png";
const assignmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png";
const syllabusIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/syllabus-icon-dark.png";
const messagesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/messages-icon-dark.png";
const assessmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-dark.png";
const gradesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/grades-icon-dark.png";
const chatbubblesIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbubbles-icon.png";
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const invisibleIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon-blue.png";
const visibleIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/unhide-icon-blue.png";
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const deleteIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png";
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";
const benchmarksIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png";
const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const downloadIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-blue.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditCourse extends Component {
    constructor(props) {
        super(props)

        this.state = {
          availableToEveryone: true,
          category: 'Course',

          modalIsOpen: false,

          subNavCategories: ['Details','Current Students','Alumni'],
          subNavSelected: 'Details',

          gradeLevelOptions: [],
          knowledgeLevelOptions: [],
          functionOptions: [],
          industryOptions: [],
          degreeOptions: [],
          programMethodOptions: ['','Online','In-Person','Both Online and In-Person'],
          durationOptions: [],
          courseOptions: [],
          pathwayOptions: [],
          moveInstruxOptions: ['','To the Top','After','To the Bottom'],
          memberDetailCategories: ['Progress','A.I. Conversations','Submissions','Grades'],

          memberDetailCategorySelected: 'Progress',

          functions: [],
          pathways: [],
          courses: [],
          courseMembers: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.addItem = this.addItem.bind(this)
        this.removeTag = this.removeTag.bind(this)

        this.editCourse = this.editCourse.bind(this)
        this.importCourse = this.importCourse.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.toggleShowImportWidget = this.toggleShowImportWidget.bind(this)
        this.renderDetails = this.renderDetails.bind(this)
        this.moduleClicked = this.moduleClicked.bind(this)
        this.menuClicked = this.menuClicked.bind(this)
        this.moveItems = this.moveItems.bind(this)

        this.saveLearningModules = this.saveLearningModules.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)

        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.openCurriculumExchange = this.openCurriculumExchange.bind(this)
        this.renderMessage = this.renderMessage.bind(this)
        this.pullMessages = this.pullMessages.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.exportInfo = this.exportInfo.bind(this)
        this.attachmentClicked = this.attachmentClicked.bind(this)

    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in courseDetails', this.props, prevProps)

      if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        this.retrieveData()
      } else if (this.props.selectedCourse && !prevProps.selectedCourse) {
        this.retrieveData()
      } else if (this.props.selectedCourse && this.props.selectedCourse._id && prevProps.selectedCourse && this.props.selectedCourse._id !== prevProps.selectedCourse._id) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode || this.props.courseId !== prevProps.courseId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editCourse:  ', this.props)

      const modalIsOpen = this.props.modalIsOpen
      const courseId  = this.props.courseId
      let selectedCourse = this.props.selectedCourse
      const orgCode = this.props.orgCode
      // const orgName = this.props.orgName
      // console.log('what is happening: ', orgCode)
      // pull work options
      let gradeLevelOptions = []
      if (this.props.gradeLevelOptions && this.props.gradeLevelOptions.length > 0) {
        gradeLevelOptions = this.props.gradeLevelOptions
      }
      let knowledgeLevelOptions = []
      if (this.props.knowledgeLevelOptions && this.props.knowledgeLevelOptions.length > 0) {
        knowledgeLevelOptions = this.props.knowledgeLevelOptions
      }
      let industryOptions = []
      if (this.props.industryOptions && this.props.industryOptions.length > 0) {
        industryOptions = this.props.industryOptions
      }
      let functionOptions = []
      if (this.props.functionOptions && this.props.functionOptions.length > 0) {
        functionOptions = this.props.functionOptions
      }

      let courseOptions = []
      if (this.props.courseOptions && this.props.courseOptions.length > 0) {
        courseOptions = [''].concat(this.props.courseOptions)
      }

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgName = localStorage.getItem('orgName');
      let orgContactEmail = localStorage.getItem('orgContactEmail');
      let pictureURL = localStorage.getItem('pictureURL');
      let editCourseNavSelected = localStorage.getItem('editCourseNavSelected')

      // calendar, pages, badges/certificates, analytics

      const courseMenu = [
        { icon: infoIconDark, name: 'Basic Info' },
        { icon: courseIconDark, name: 'Learning Modules' },
        { icon: targetIcon, name: 'Learning Objectives' },
        { icon: syllabusIconDark, name: 'Syllabus' },
        { icon: messagesIconDark, name: 'Announcements' },
        { icon: chatbubblesIcon, name: 'Discussions' },
        { icon: socialIconDark, name: 'Members' },
        { icon: assignmentsIconDark, name: 'Submissions' },
        { icon: gradesIconDark, name: 'Grades' },
      ]

      let learningModules = [
        { moduleId: new Date().toString() + emailId + '1', name: 'My First Section', isVisible: false, lessons: [], rank: 1 },
        { moduleId: new Date().toString() + emailId + '2', name: 'My Second Section', isVisible: false, lessons: [], rank: 2 },
        { moduleId: new Date().toString() + emailId + '3', name: 'My Third Section', isVisible: false, lessons: [], rank: 3 }
      ]

      const learningModuleMenuOptions = [ 'Edit Name','Move','Delete','Duplicate']
      // 'Share to Exchange'

      let prefix = '/advisor'
      if (window.location.pathname.startsWith('/organizations')) {
        prefix = '/organizations/' + orgCode
      }

      this.setState({
        modalIsOpen, orgCode, orgName, orgContactEmail,
        gradeLevelOptions, knowledgeLevelOptions, industryOptions, functionOptions, courseOptions,
        emailId, cuFirstName, cuLastName, pictureURL, courseMenu, learningModules, learningModuleMenuOptions,
        prefix
      })

      Axios.get('/api/favorites', { params: { emailId } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites

           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           this.setState({ favorites, newFavorites })

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

      const self = this
      function populateCourse(passedCourse) {
        console.log('popularCourse called')

        let _id = null
        let imageURL = null
        let courseName = null
        let schoolName = null
        let startDate = null
        let endDate = null
        let isPerpetual = null
        let gradeLevel = null
        let knowledgeLevel = null
        let industry = null
        let functions = []
        let description = null
        let createdAt = null
        let category = null
        let programURL = null
        let schoolURL = null
        let degreeType = null
        let programMethod = null
        let location = null
        let estimatedHours = null
        let minutes = null
        let pathways = []
        let sampleJobTitles = null
        let outcomes = null
        let courses = []
        let price = null
        let mainContactFirstName = null
        let mainContactLastName = null
        let mainContactEmail = null
        let mainContactTitle = null
        let curriculumItemId = null

        let pointValue = null
        let availability = null
        let mpn = null
        let tags = null
        let source = null

        let isActive = true
        let learningObjectives = null
        let syllabus = null
        let announcements = null
        let availableToEveryone = self.state.availableToEveryone

        if (passedCourse && passedCourse._id) {
          _id = passedCourse._id
          imageURL = passedCourse.imageURL
          courseName = passedCourse.name
          schoolName = passedCourse.schoolName
          startDate = passedCourse.startDate
          if (passedCourse.startDate) {
            startDate = passedCourse.startDate.substring(0,10)
          }
          endDate = passedCourse.endDate
          if (passedCourse.endDate) {
            endDate = passedCourse.endDate.substring(0,10)
          }
          isPerpetual = passedCourse.isPerpetual
          gradeLevel = passedCourse.gradeLevel
          knowledgeLevel = passedCourse.knowledgeLevel
          industry = passedCourse.industry
          functions = passedCourse.functions
          description = passedCourse.description
          createdAt = passedCourse.createdAt

          category = passedCourse.category
          programURL = passedCourse.programURL
          schoolURL = passedCourse.schoolURL
          degreeType = passedCourse.degreeType
          programMethod = passedCourse.programMethod
          location = passedCourse.location
          estimatedHours = passedCourse.estimatedHours
          minutes = passedCourse.minutes
          pathways = passedCourse.pathways
          sampleJobTitles = passedCourse.sampleJobTitles
          outcomes = passedCourse.outcomes
          courses = passedCourse.courses
          price = passedCourse.price
          mainContactFirstName = passedCourse.mainContactFirstName
          mainContactLastName = passedCourse.mainContactLastName
          mainContactEmail = passedCourse.mainContactEmail
          mainContactTitle = passedCourse.mainContactTitle
          curriculumItemId = passedCourse.curriculumItemId

          pointValue = passedCourse.pointValue
          availability = passedCourse.availability
          mpn = passedCourse.mpn
          tags = passedCourse.tags
          source = passedCourse.source

          isActive = passedCourse.isActive
          learningModules = passedCourse.learningModules
          learningObjectives = passedCourse.learningObjectives
          syllabus = passedCourse.syllabus
          announcements = passedCourse.announcements
          availableToEveryone = passedCourse.availableToEveryone
        }

        let subNavSelected = 'Details'
        if (passedCourse && passedCourse._id) {
          // subNavSelected = 'Basic Info'
          subNavSelected = 'Basic Info'
        }

        if (editCourseNavSelected && !self.props.fromModal) {
          subNavSelected = editCourseNavSelected
        }

        // self.setState({ _id, imageURL, courseName, schoolName, startDate, endDate, isPerpetual, gradeLevel, knowledgeLevel,
        //   industry, functions, description, createdAt,
        //   category, programURL, schoolURL, degreeType, programMethod, location, estimatedHours, minutes,
        //   pathways, sampleJobTitles, outcomes, courses, price,
        //   mainContactFirstName, mainContactLastName, mainContactEmail, mainContactTitle, curriculumItemId,
        //   pointValue, availability, mpn, tags, source,
        //   isActive, subNavSelected, learningModules, learningObjectives, syllabus, announcements,
        //   availableToEveryone, selectedCourse: passedCourse
        // })

        self.setState({
          _id, imageURL, courseName, schoolName, startDate, endDate, isPerpetual, gradeLevel, knowledgeLevel,
          industry, functions, description, createdAt,
          category, programURL, schoolURL, degreeType, programMethod, location, estimatedHours, minutes,
          pathways, sampleJobTitles, outcomes, courses, price,
          mainContactFirstName, mainContactLastName, mainContactEmail, mainContactTitle, curriculumItemId,
          pointValue, availability, mpn, tags, source,
          isActive, subNavSelected, learningModules, learningObjectives, syllabus, announcements,
          availableToEveryone, selectedCourse: passedCourse
        });

        if (_id) {
          Axios.get('/api/comments', { params: { parentPostId: _id, resLimit: 25 } })
          .then((response) => {
            console.log('Comments query attempted', response.data);

             if (response.data.success && response.data.comments && response.data.comments.length > 0) {
               console.log('successfully retrieved comments')

               self.setState({ comments: response.data.comments })

             } else {
               console.log('no comments data found', response.data.message)
             }

          }).catch((error) => {
             console.log('Comments query did not work', error);
          });

          Axios.get('/api/courses/enrollments', { params: { courseId: _id, resLimit: 250 } })
         .then((response) => {
           console.log('Enrollments query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved enrollments')

             let courseMembers = []
             let students = []
             let alumni = []
             for (let i = 1; i <= response.data.enrollments.length; i++) {
               courseMembers.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 alumni.push(response.data.enrollments[i - 1])
               } else {
                 students.push(response.data.enrollments[i - 1])
               }
             }
             // console.log('show me alumni: ', alumni.length)

             self.setState({ courseMembers, students, alumni })

           } else {
             console.log('no enrollments data found', response.data.message)
           }

         }).catch((error) => {
             console.log('Enrollments query did not work', error);
         });

          let courseId = _id
          let subParams = { courseId }
          Axios.get('/api/submissions', { params: subParams })
          .then((response) => {
            console.log('Submissions query attempted', response.data);

            if (response.data.success) {
              console.log('submissions query worked')

              const submissions = response.data.submissions
              self.setState({ submissions })

              Axios.get('/api/submissions', { params: { courseId, includesGrade: true }})
              .then((response2) => {
                console.log('Grades query attempted', response2.data);

                if (response2.data.success) {
                  console.log('grades query worked')

                  const grades = response2.data.submissions
                  self.setState({ grades })

                } else {
                  console.log('grades query did not work', response.data.message)
                }

              }).catch((error) => {
                  console.log('grades query did not work for some reason', error);
              });

            } else {
              console.log('submissions query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('submissions query did not work for some reason', error);
          });

          self.pullMessages()

        }
      }

      if (!selectedCourse && courseId) {
        Axios.get('/api/courses/byid', { params: { _id: courseId }})
        .then((response) => {
          console.log('Couse by id query attempted no 1', response.data);
          if (response.data.success) {
            console.log('pathway query worked no 1')

            selectedCourse = response.data.course
            populateCourse(selectedCourse)

          } else {
            console.log('pathway query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathway query did not work for some reason', error);
        });
      } else {
        populateCourse(selectedCourse)
      }

      Axios.get('/api/pathways', { params: { orgCode }})
      .then((response) => {
        console.log('Pathways query attempted no 1', response.data);

        if (response.data.success) {
          console.log('pathway query worked no 1')

          if (response.data.pathways.length !== 0) {

            const pathwayOptions = [{ title: '' }].concat(response.data.pathways)
            this.setState({ pathwayOptions });

          }

        } else {
          console.log('pathway query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Pathway query did not work for some reason', error);
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let degreeOptions = response.data.workOptions[0].degreeOptions.concat(['Certificate','Badge','Certification','None'])
          let durationOptions = response.data.workOptions[0].durationOptions
          // console.log('up in it: ', degreeOptions)
          this.setState({ degreeOptions, durationOptions })
        }
      });
    }

    pullMessages(selectedMember) {
      console.log('pullMessages called')

      const courseId = this.state._id

      let emailId = null
      if (selectedMember) {
        emailId = selectedMember.email
      }

      Axios.get('/api/messages', { params: { courseId, emailId, resLimit: 250 }})
      .then((response) => {
        console.log('Messages query attempted', response.data);

         if (response.data.success && response.data.messages.length > 0) {
           console.log('successfully retrieved messages')

           if (selectedMember) {
             const messages = response.data.messages
             this.setState({  messages })
           } else {
             const courseMessages = response.data.messages
             this.setState({ courseMessages })
           }

         } else {
           console.log('no messages found', response.data.message)
           // this.setState({ errorMessage: response.data.message })
         }

      }).catch((error) => {
         console.log('Message query did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      // event.stopPropagation()

      if (event.target.name.startsWith('learningModules|')) {
        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]

        let learningModules = this.state.learningModules

        if (event.target.name.includes("lessons|")) {
          const index2 = Number(event.target.name.split("|")[3])
          const name2 = event.target.name.split("|")[4]
          learningModules[index]['lessons'][index2][name2] = event.target.value
        } else {

          learningModules[index][name] = event.target.value

        }

        this.setState({ learningModules })
      } else if (event.target.name.startsWith("learningObjective|")) {
        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]
        let learningObjectives = this.state.learningObjectives
        learningObjectives[index][name] = event.target.value

        this.setState({ learningObjectives })
      } else if (event.target.name.startsWith("submission|")) {
        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]
        let submissions = this.state.submissions
        submissions[index][name] = event.target.value

        this.setState({ submissions })
      } else if (event.target.name.startsWith("syllabus|")) {

        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]
        let syllabus = this.state.syllabus

        if (syllabus && syllabus.length > 0) {
          syllabus[index][name] = event.target.value
        } else {
          syllabus = [{ [name]: event.target.value }]
        }

        this.setState({ syllabus })

      } else if (event.target.name.startsWith("announcement|")) {

        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]
        let announcements = this.state.announcements

        if (announcements && announcements.length > 0) {
          announcements[index][name] = event.target.value
        } else {
          announcements = [{ [name]: event.target.value }]
        }

        this.setState({ announcements })

      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'searchMembers') {
        this.searchItems(event.target.value,'member')
      } else if (event.target.name === 'lessonMinutes') {
        // let selectedCourse = this.state.selectedCourse
        // selectedCourse.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2]['minutes'] = event.target.value
        // this.setState({ selectedCourse })
        this.setState({ [event.target.name]: event.target.value, formHasChanged: true })
      } else {
        this.setState({ [event.target.name]: event.target.value, formHasChanged: true })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'member') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, searchIsAnimating: false, careerOptions: null })
        } else {
          this.setState({ searchString, searchIsAnimating: true })

          const excludeCurrentUser = true
          const emailId = this.state.emailId
          const orgCode = this.state.orgCode
          let roleNames = ['Student','Career-Seeker','Teacher','Counselor','School Support']

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            Axios.get('/api/members/search', {  params: {searchString, excludeCurrentUser, emailId, roleNames, orgCode }})
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let memberOptions = []
                    if (response.data.members && response.data.members.length > 0) {
                      memberOptions = response.data.members
                    }

                    self.setState({ memberOptions, searchIsAnimating: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  self.setState({ searchIsAnimating: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'member') {

        const searchObject = passedItem
        const searchString = passedItem.firstName + ' ' + passedItem.lastName
        const unready = false
        const memberOptions = null

        this.setState({ searchObject, searchString, unready, memberOptions })

      }
    }

    closeModal() {
      console.log('show closeModal in editCourse')

      this.setState({ modalIsOpen: false, showImportWidget: false, selectedSource: null,
        showMoveMenu: false, selectedIndex: null, selectedIndex2: null,
        showEditLearningObjective: false, showSyllabus: false, showAddMembers: false,
        showMemberDetails: false, selectedMember: null, showEditMins: false, lessonMinutes: null,
        showConfirmDelete: false
      })

      if (this.props.fromModal) {
        this.props.closeModal()
      }
    }

    editCourse() {
      console.log('editCourse called')

      this.setState({ serverErrorMessage: '', serverSuccessMessage: '', isSaving: true })

      if (!this.state.courseName || this.state.courseName === '') {
        this.setState({ serverErrorMessage: 'please add the course name', isSaving: false })
      } else if ((!this.state.schoolName || this.state.schoolName === '') && !toggleVars.showLMSConfiguration) {
        this.setState({ serverErrorMessage: 'please add the school name', isSaving: false })
      } else if ((!this.state.gradeLevel || this.state.gradeLevel === '') && !toggleVars.showLMSConfiguration) {
        this.setState({ serverErrorMessage: 'please add your grade level', isSaving: false })
      } else if ((!this.state.knowledgeLevel || this.state.knowledgeLevel === '') && !toggleVars.showLMSConfiguration) {
        this.setState({ serverErrorMessage: 'please add your knowledge level', isSaving: false })
      } else if (!this.state.minutes || this.state.minutes === '') {
        this.setState({ serverErrorMessage: 'please add the estimated minutes to complete the course/training program', isSaving: false })
      } else if (!this.state.isPerpetual && !this.state.startDate) {
        this.setState({ serverErrorMessage: 'please add the course start date', isSaving: false })
      } else if (!this.state.isPerpetual && !this.state.endDate) {
        this.setState({ serverErrorMessage: 'please add the course end date', isSaving: false })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ serverErrorMessage: 'please add a description', isSaving: false })
      } else {

        const _id = this.state._id

        const imageURL = this.state.imageURL
        const name = this.state.courseName
        const schoolName = this.state.schoolName
        const gradeLevel = this.state.gradeLevel
        const knowledgeLevel = this.state.knowledgeLevel
        const industry = this.state.industry
        const functions = this.state.functions

        const startDate = this.state.startDate
        const endDate = this.state.endDate

        const isPerpetual = this.state.isPerpetual
        const description = this.state.description

        const category = this.state.category
        const programURL = this.state.programURL
        const schoolURL = this.state.schoolURL
        const degreeType = this.state.degreeType
        const programMethod = this.state.programMethod
        const location = this.state.location
        const estimatedHours = this.state.estimatedHours
        const minutes = this.state.minutes
        const pathways = this.state.pathways
        const sampleJobTitles = this.state.sampleJobTitles
        const outcomes = this.state.outcomes
        const courses = this.state.courses
        const price = this.state.price

        const orgCode = this.state.orgCode
        const orgName = this.state.orgName

        const mainContactFirstName = this.state.mainContactFirstName
        const mainContactLastName = this.state.mainContactLastName
        const mainContactEmail = this.state.mainContactEmail
        const mainContactTitle = this.state.mainContactTitle

        const pointValue = this.state.pointValue
        const availability = this.state.available
        const mpn = this.state.mpn
        const tags = this.state.tags

        const isActive = this.state.isActive
        const availableToEveryone = this.state.availableToEveryone
        const source = this.state.source

        let createdAt = new Date()
        if (this.state.createdAt) {
          createdAt = this.state.createdAt
        }
        const updatedAt = new Date()

        let courseObject = {
          _id, imageURL, name, schoolName, gradeLevel, knowledgeLevel, industry, functions, startDate, endDate,
          isPerpetual, description, orgCode, orgName,
          category, programURL, schoolURL, degreeType, programMethod, location, estimatedHours, minutes,
          pathways, sampleJobTitles, outcomes, courses, price,
          mainContactFirstName, mainContactLastName, mainContactEmail, mainContactTitle,
          pointValue, availability, mpn, tags, source, isActive, availableToEveryone,
          createdAt, updatedAt
        }

        Axios.post('/api/courses', courseObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Course save worked', response.data);

            const serverPostSuccess = true
            let serverSuccessMessage = 'Course saved successfully!'

            if (!courseObject._id) {
              // id and course
              console.log('this is a new project')
              courseObject['_id'] = response.data._id
              this.setState({ serverPostSuccess, serverSuccessMessage, isSaving: false, modalIsOpen: false })
              if (this.props.addNewCourse) {
                this.props.addNewCourse(courseObject)
                if (this.props.fromModal) {
                  this.closeModal()
                }
              }
            } else {
              //existing course - update values
              console.log('this is an existing project')
              courseObject['updatedAt'] = courseObject.updatedAt.toString()
              this.setState({ serverPostSuccess, serverSuccessMessage, isSaving: false, modalIsOpen: false })
              if (this.props.updateCourse) {
                this.props.updateCourse(courseObject)
              }
            }

          } else {
            console.error('there was an error posting the course');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message, isSaving: false
            })
          }
        }).catch((error) => {
            console.log('The course post did not work', error);
            this.setState({ isSaving: false })
        });
      }
    }

    deleteItem(type, index, e) {
      console.log('deleteItem called', type, index, e)

      if (e) {
        e.stopPropagation()
      }

      if (type === 'course') {
        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const _id = this.state._id

        Axios.delete('/api/courses/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Course delete worked');

            this.setState({ isSaving: false, successMessage: 'Successfully saved the course', confirmDelete: false })

            if (this.props.fromModal) {
              this.props.updateCourse(courseObject, true)
              this.closeModal()
            } else {
              this.props.navigate(-1)
            }

          } else {
            console.error('an error: ', response.data.message);
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      } else if (type === 'learningObjective') {
        let learningObjectives = this.state.learningObjectives
        learningObjectives.splice(index,1)
        this.setState({ learningObjectives })
        this.saveChanges(type)
      } else if (type === 'syllabus') {
        let syllabus = this.state.syllabus
        syllabus.splice(index,1)
        this.setState({ syllabus })
        this.saveChanges(type)
      } else if (type === 'announcement') {
        let announcements = this.state.announcements
        announcements.splice(index,1)
        this.setState({ announcements })
        this.saveChanges(type)
      } else if (type === 'member') {
        this.saveChanges('member','remove',this.state.courseMembers[index])
        let courseMembers = this.state.courseMembers
        courseMembers.splice(index, 1)
        this.setState({ courseMembers })
      } else if (type === 'learningModule') {
        let learningModules = this.state.learningModules
        if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
          learningModules[this.state.selectedIndex]['lessons'].splice(this.state.selectedIndex2,1)
        } else {
          learningModules.splice(this.state.selectedIndex,1)
        }
        this.setState({ learningModules })
        this.saveChanges(type, null, learningModules)
      }
    }

    renderTags(type) {
      console.log('renderTags called', type)

      let rows = []

      let items = []
      if (type === 'function') {
        items = this.state.functions
      } else if (type === 'pathway') {
        items = this.state.pathways
      } else if (type === 'course') {
        items = this.state.courses
      } else if (type === 'member') {
        items = this.state.courseMembers
      }

      for (let i = 1; i <= items.length; i++) {

        const value = items[i - 1]
        const optionIndex = i - 1

        // let tagClass = "selected-tag-container-2"

        rows.push(
          <div key={i} className="float-left right-padding-5">
            <div className="close-button-container-1" >
              <button className="background-button" onClick={() => this.removeTag(type, optionIndex)}>
                <img src={deniedIcon} alt="GC" className="image-auto-20" />
              </button>
            </div>
            <div className="float-left right-padding-5">
              <div className="half-spacer" />
              <div className="tag-container-inactive">
                {(type === 'member') ? (
                  <div>
                    <p className="description-text-2">{value.firstName} {value.lastName} [{value.roleName}]</p>
                    <p className="description-text-2">({value.email})</p>
                  </div>
                ) : (
                  <p className="description-text-2">{(value.title) ? value.title : value}</p>
                )}
              </div>
              <div className="half-spacer" />
            </div>
          </div>
        )
      }

      return rows
    }

    removeTag(type, index, parentIndex) {
      console.log('removeTag called', type, index, parentIndex)

      if (type === 'function') {
        let functions = this.state.functions
        functions.splice(index, 1)
        this.setState({ functions })
      } else if (type === 'pathway') {
        let pathways = this.state.pathways
        pathways.splice(index, 1)
        this.setState({ pathways })
      } else if (type === 'course') {
        let courses = this.state.courses
        courses.splice(index, 1)
        this.setState({ courses })
      } else if (type === 'member') {
        let courseMembers = this.state.courseMembers
        courseMembers.splice(index, 1)
        this.setState({ courseMembers })
      }
    }

    addItem(type, value, index) {
      console.log('addItem called', type, value, index)

      if (type === 'function') {
        let functions = this.state.functions
        functions.push(value)
        this.setState({ functions, function: '' })
      } else if (type === 'pathway') {
        if (this.state.pathways.some(sc => sc.title === value)) {
          this.setState({ errorMessage: 'You have already added this pathway'})
        } else {
          const addIndex = this.state.pathwayOptions.findIndex(sc => sc.title === value)
          const pathwayObject = {
            sourceId: this.state.pathwayOptions[addIndex]._id,
            title: this.state.pathwayOptions[addIndex].title,
          }
          let pathways = this.state.pathways
          // pathways.push(value)
          pathways.push(pathwayObject)
          this.setState({ pathways, pathway: '' })
        }
      } else if (type === 'course') {
        let courses = this.state.courses
        courses.push(value)
        this.setState({ courses, course: '' })
      } else if (type === 'member') {
        if (!this.state.searchObject) {
          this.setState({ errorMessage: 'Please search for and add a member'})
        } else if (this.state.courseMembers.some(member => member.email === this.state.searchObject.email)) {
          this.setState({ errorMessage: 'You have already added this member'})
        } else {

          const searchString = ''
          const searchObject = null
          const unready = true

          let courseMembers = this.state.courseMembers
          courseMembers.unshift(this.state.searchObject)
          const tempObject = this.state.searchObject

          this.setState({ searchString, searchObject, unready, courseMembers, errorMessage: null })
          this.saveChanges('member','add', tempObject)

        }
      } else {
        if (type === 'Learning Modules') {
          let learningModules = this.state.learningModules
          learningModules.push({ moduleId: new Date().toString() + this.state.emailId, name: 'My New Section', isVisible: false, lessons: [], rank: learningModules.length + 1 })
          this.setState({ learningModules })
        } else if (type === 'Learning Objectives') {
          let learningObjectives = this.state.learningObjectives
          if (learningObjectives) {
            learningObjectives.push({ name: 'My Learning Objective' })
          } else {
            learningObjectives = [{ name: 'My Learning Objective' }]
          }

          this.setState({ learningObjectives })
        } else if (type === 'Announcements') {
          // console.log('5d', index, this.state.announcements)
          let selectedIndex = 0
          if (index) {
            selectedIndex = index
          }

          let announcements = this.state.announcements
          if ((!index && index !== 0) && this.state.announcements && this.state.announcements.length > 0) {
            selectedIndex = this.state.announcements.length
            announcements[selectedIndex] = { title: '', createdAt: new Date(), updatedAt: new Date() }
          }
          // console.log('5d111', selectedIndex, index, this.state.announcements)
          this.setState({ modalIsOpen: true, showAnnouncement: true, selectedIndex, announcements })
        } else if (type === 'Members') {
          this.setState({ modalIsOpen: true, showAddMembers: true })
        }
      }
    }

    importCourse(course,type) {
      console.log('importCourse called', course,type)

      let category = "Course"
      // if (type === 'Udemy') {
      //   category = "Course"
      // }

      let courseName = course.title
      if (course.name) {
        courseName = course.name
      }

      let imageURL = null
      if (course.image_480x270) {
        imageURL = course.image_480x270
      } else if (course.image_125_H) {
        imageURL = course.image_125_H
      } else if (course.imageURL) {
        imageURL = course.imageURL
      }

      let programURL = course.programURL
      if (type === 'Udemy' && !course.programURL) {
        programURL = 'https://www.udemy.com' + course.url
      }
      let price = course.price
      if (course.price === 'Free') {
        price = 0
      }

      let mainContactFirstName = null
      let mainContactLastName = null
      let mainContactEmail = null
      let mainContactTitle = null
      let schoolName = type
      if (course.schoolName) {
        schoolName = course.schoolName
      }
      let schoolURL = null
      if (course.schoolURL) {
        schoolURL = course.schoolURL
      }
      if (course.visible_instructors && course.visible_instructors.length > 0) {

        mainContactFirstName = course.visible_instructors[0].display_name.split(" ")[0]
        mainContactLastName = course.visible_instructors[0].display_name.split(" ")[1]
        mainContactEmail = ''
        mainContactTitle = course.visible_instructors[0].job_title
        schoolName = course.visible_instructors[0].display_name
        schoolURL = 'https://www.udemy.com' + course.visible_instructors[0].url

      } else {

        mainContactFirstName = this.state.cuFirstName
        mainContactLastName = this.state.cuLastName
        mainContactEmail = this.state.emailId
        mainContactTitle = this.state.jobTitle
        // if (type === 'Udemy') {
        //   schoolName = 'Udemy'
        //   schoolURL = 'https://www.udemy.com'
        // }
      }

      let degreeType = 'None'
      if (course.degreeType) {
        degreeType = course.degreeType
      }
      let programMethod = 'Online'
      if (course.estimatedHours) {
        programMethod = course.programMethod
      }
      const location = 'N/A'
      let estimatedHours = null
      if (course.estimatedHours) {
        estimatedHours = course.estimatedHours
      }
      const gradeLevel = "All Grade Levels"
      let knowledgeLevel = "Beginner"
      if (course.knowledgeLevel) {
        knowledgeLevel = course.knowledgeLevel
      }

      const industry = "Other"
      const functions = []
      const pathways = []

      const isPerpetual = true
      let description = course.description
      if (!course.description) {
        description = course.headline
      }
      const sampleJobTitles = null
      const outcomesData = null

      let startDate = null
      if (course.startDate) {
        startDate = course.startDate
      }

      let pointValue = null
      if (course.pointValue) {
        pointValue = course.pointValue
      }

      let availability = null
      if (course.availability) {
        availability = course.availability
      }

      let mpn = null
      if (course.mpn) {
        mpn = course.mpn
      }

      let tags = null
      if (course.tags) {
        tags = course.tags
      }

      let source = null
      if (type) {
        source = type
      }

      this.setState({ category, imageURL, courseName, programURL, price, mainContactFirstName, mainContactLastName,
        mainContactEmail, mainContactTitle, schoolName, schoolURL, degreeType, programMethod,
        location, estimatedHours, gradeLevel, knowledgeLevel, industry, functions, pathways,
        isPerpetual, description, sampleJobTitles, outcomesData, source,
        startDate, pointValue, availability, mpn, tags
      })
    }

    toggleShowImportWidget(value) {
      console.log('toggleShowImportWidget called', value)

      let showImportWidget = value
      this.setState({ showImportWidget })
    }

    subNavClicked(subNavSelected) {
      console.log('subNavClicked called: ', subNavSelected, this.state._id)

      this.setState({ subNavSelected })
      localStorage.setItem('editCourseNavSelected', subNavSelected)

      if (subNavSelected === 'Preview / Take') {
       console.log('did we do it')
       this.props.navigate(this.state.prefix + '/courses/' + this.state._id + '/take')
      }
    }

    renderDetails() {
      console.log('renderDetails called')

      return (
        <div key="renderDetails">
          {(window.innerWidth > 768 && !this.props.fromModal) && (
            <div className="relative-column-30 horizontal-padding top-margin-40">
              <div className="left-padding-20">
                <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                  <div className="full-width height-5 senary-background" />
                  <div className="padding-20">
                    {(this.state.courseMenu) && (
                      <div>
                        {this.state.courseMenu.map((value, index) =>
                          <div key={value.name}>
                            {(value.name === this.state.subNavSelected) ? (
                              <div className="cta-bottom-border primary-background-extra-light padding-15 full-width">
                                <div className="fixed-column-40 top-margin-3">
                                  <img src={value.icon} alt="GC" className="image-23-fit" />
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="heading-text-6">{value.name}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            ) : (
                              <button className="background-button clear-margin padding-15 full-width left-text" onClick={() => this.subNavClicked(value.name)}>
                                <div>
                                  <div className="fixed-column-40">
                                    <img src={value.icon} alt="GC" className="image-23-fit" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="heading-text-6">{value.name}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            )}
                          </div>
                        )}

                        {(this.state._id && this.state.learningModules && this.state.learningModules.length > 0) && (
                          <div>
                            <hr />

                            <button className="background-button clear-margin padding-15 full-width left-text" onClick={() => this.subNavClicked('Preview / Take')}>
                              <div>
                                <div className="fixed-column-40">
                                  <img src={courseIconDark} alt="GC" className="image-23-fit" />
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="heading-text-6">Preview / Take</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={(window.innerWidth > 768 && !this.props.fromModal) && "relative-column-70 padding-five-percent-right"}>
            <div className={(!this.props.fromModal) && "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-left"}>
              {(window.innerWidth > 768 && !this.props.fromModal) && (
                <div className="full-width height-5 dark-background" />
              )}

              <div className={(window.innerWidth > 768 && !this.props.fromModal) ? "padding-40" : ""}>
                <div className="bottom-margin">
                 <div className="calc-column-offset-40">
                   <p className="heading-text-2">{(this.state._id) ? "Edit " + this.state.courseName : "Add Course"}</p>
                   {(toggleVars.showLMSConfiguration && this.state._id) && (
                     <div className="top-margin">
                        <p>{this.state.subNavSelected}</p>
                     </div>
                   )}
                 </div>
                 {(this.props.fromModal) ? (
                   <div className="fixed-column-40 top-margin-5">
                     <button className="background-button" onClick={() => this.closeModal()}>
                       <img src={closeIcon} alt="GC" className="image-auto-18" />
                     </button>
                   </div>
                 ) : (
                   <div className="fixed-column-40">
                    {(this.state.subNavSelected !== 'Basic Info' && this.state.subNavSelected !== 'Discussions' && this.state.subNavSelected !== 'Submissions' && this.state.subNavSelected !== 'Grades' && this.state.subNavSelected !== 'Syllabus') && (
                      <button className="background-button full-width top-margin left-text" onClick={() => this.addItem(this.state.subNavSelected)}>
                        <div className="cta-border width-30 height-30 circle-radius flex-container flex-center">
                          <img src={addIconBlue} alt="GC" className="image-auto-15" />
                        </div>
                      </button>
                    )}
                   </div>
                 )}

                 <div className="clear" />
                </div>

                <div className="spacer" />

                {(this.state._id) ? (
                  <div>
                    {(!toggleVars.showLMSConfiguration) && (
                      <div className="row-10">
                        <div className="carousel-3 light-background" onScroll={this.handleScroll}>
                          {this.state.subNavCategories.map((value, index) =>
                            <div className="display-inline">
                              {(value === this.state.subNavSelected) ? (
                                <div className="selected-carousel-item">
                                  <label key={value}>{value}</label>
                                </div>
                              ) : (
                                <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                  <label key={value}>{value}</label>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                   {(toggleVars.showLMSConfiguration) ? (
                     <div>
                       <hr />

                       <div className="row-20">
                        <div className="row-10">
                          <div className="fixed-column-30 top-margin-3">
                            <img src={benchmarksIconDark} alt="GC" className="image-15-fit" />
                          </div>
                          <div className="calc-column-offset-30">
                            <p className="description-text-2">Sync course and other content with your LMS. Email <label className="cta-color">help@guidedcompass.com</label> to integrate.</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <button className="background-button left-text row-10 full-width" onClick={() => this.openCurriculumExchange('Course')}>
                          <div className="fixed-column-30 top-margin-3">
                            <img src={importIconBlue} alt="GC" className="image-15-fit" />
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="display-inline">
                              <p className="description-text-2 cta-color float-left right-margin">Import existing courses from curriculum exchange.</p>
                              <img src={arrowIcon} alt="GC" className="image-12-fit float-left top-margin-3" />
                            </div>
                          </div>
                          <div className="clear" />
                        </button>
                       </div>

                       <hr />

                     </div>
                   ) : (
                     <div className="row-10">
                       <button className="btn btn-squarish-white right-margin top-margin" onClick={() => this.props.navigate(this.state.prefix + '/bulk-uploader')}>
                        <div className="fixed-column-40">
                          <img src={importIconBlue} alt="GC" className="image-25-fit" />
                        </div>
                        <div className="float-left top-padding-5">
                          <p>Bulk Upload</p>
                        </div>
                        <div className="clear" />
                       </button>
                       <button className="btn btn-squarish-white right-margin top-margin" onClick={() => this.setState({ showImportWidget: true, selectedSource: 'Udemy' })}>
                        <div className="fixed-column-40">
                          <img src={udemyLogo} alt="GC" className="image-25-fit" />
                        </div>
                        <div className="float-left top-padding-5">
                          <p>Udemy Import</p>
                        </div>
                        <div className="clear" />
                       </button>
                       <button className="btn btn-squarish-white right-margin top-margin" onClick={() => this.setState({ showImportWidget: true, selectedSource: 'Coursera' })}>
                        <div className="fixed-column-40">
                          <img src={courseraLogo} alt="GC" className="image-25-fit" />
                        </div>
                        <div className="float-left top-padding-5">
                          <p>Coursera Import</p>
                        </div>
                        <div className="clear" />
                       </button>
                       <button className="btn btn-squarish-white right-margin top-margin" onClick={() => this.setState({ showImportWidget: true, selectedSource: 'Udacity' })}>
                        <div className="fixed-column-40">
                          <img src={udacityLogo} alt="GC" className="image-25-fit" />
                        </div>
                        <div className="float-left top-padding-5">
                          <p>Udacity Import</p>
                        </div>
                        <div className="clear" />
                       </button>
                     </div>
                   )}
                  </div>
                )}

                {(this.state.subNavSelected === 'Details' || this.state.subNavSelected === 'Basic Info') && (
                  <div>
                   {(this.state._id && !toggleVars.showLMSConfiguration) && (
                     <div className="edit-profile-row top-margin">
                       <label className="profile-label">Course Unique Id</label>
                       <p>{this.state._id}</p>
                     </div>
                   )}

                    {(this.state.imageURL) && (
                      <div className="edit-profile-row top-margin">
                        <label className="profile-label">Course Image</label>
                        <img src={this.state.imageURL} alt="GC" className="image-full-auto" />
                      </div>
                    )}

                    {(!toggleVars.showLMSConfiguration) && (
                      <div className="edit-profile-row">
                        <label className="profile-label">Program or Course <label className="error-color bold-text">*</label></label>
                        <select name="category" value={this.state.category} onChange={this.formChangeHandler} className="dropdown">
                          {['','Training Program','Course'].map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                    )}

                    <div className="edit-profile-row">
                     <div className="container-left">
                       <label className="profile-label">{(this.state.category === 'Training Program') ? "Training Program" : "Course"} Name<label className="error-color bold-text">*</label></label>
                       <input type="text" className="text-field" placeholder="(e.g., Accounting 101)..." name="courseName" value={this.state.courseName} onChange={this.formChangeHandler}></input>
                     </div>
                     {(!toggleVars.showLMSConfiguration) ? (
                       <div className="container-right">
                         <label className="profile-label">{(this.state.category === 'Training Program') ? "Training Program" : "Course"} Link<label className="error-color bold-text">*</label></label>
                         <input type="text" className="text-field" placeholder="(e.g., https://)..." name="programURL" value={this.state.programURL} onChange={this.formChangeHandler}></input>
                         {(this.state.programURL && !this.state.programURL.startsWith('http')) && (
                           <div>
                             <p className="error-message">Please start your link with http</p>
                           </div>
                         )}
                       </div>
                     ) : (
                       <div className="container-right">
                         <label className="profile-label">Estimated Minutes to Complete <label className="error-color bold-text">*</label></label>
                         <input type="number" className="number-field" placeholder="10" name="minutes" value={this.state.minutes} onChange={this.formChangeHandler}></input>
                       </div>
                     )}

                     <div className="clear" />
                    </div>

                    {(!toggleVars.showLMSConfiguration) && (
                      <div>
                        {(this.state.category === 'Training Program') ? (
                          <div className="edit-profile-row">
                           <label className="profile-label">Courses</label>
                           <div className="calc-column-offset-70">
                             <select name="course" value={this.state.course} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.courseOptions.map(value =>
                                  <option key={value.name} value={value.name}>{value.name}</option>
                                )}
                              </select>
                           </div>
                           <div className="fixed-column-70 left-padding">
                             <button className="btn btn-squarish pin-right" onClick={() => this.addItem('course',this.state.course)}>Add</button>
                           </div>
                           <div className="clear" />

                           {(this.state.courses && this.state.courses.length > 0) && (
                             <div className="row-5">
                               {this.renderTags('course')}
                               <div className="clear" />
                             </div>
                            )}
                          </div>
                        ) : (
                          <div className="edit-profile-row">
                            <label className="profile-label">Course Price</label>
                            <input type="number" className="number-field" placeholder="(e.g., $5)..." name="price" value={this.state.price} onChange={this.formChangeHandler}></input>
                          </div>
                        )}

                        <div className="edit-profile-row">
                         <div className="container-left">
                           <label className="profile-label">Main Contact First Name</label>
                           <input type="text" className="text-field" placeholder="(e.g., Michael)..." name="mainContactFirstName" value={this.state.mainContactFirstName} onChange={this.formChangeHandler}></input>
                         </div>
                         <div className="container-right">
                           <label className="profile-label">Main Contact Last Name</label>
                           <input type="text" className="text-field" placeholder="(e.g., Jones)..." name="mainContactLastName" value={this.state.mainContactLastName} onChange={this.formChangeHandler}></input>
                         </div>
                         <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                         <div className="container-left">
                           <label className="profile-label">Main Contact Email</label>
                           <input type="text" className="text-field" placeholder="(e.g., michaeljones@gmail.com)..." name="mainContactEmail" value={this.state.mainContactEmail} onChange={this.formChangeHandler}></input>
                         </div>
                         <div className="container-right">
                           <label className="profile-label">Main Contact Title</label>
                           <input type="text" className="text-field" placeholder="(e.g., Lead Instructor)..." name="mainContactTitle" value={this.state.mainContactTitle} onChange={this.formChangeHandler}></input>
                         </div>
                         <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                          <div className="container-left">
                            <label className="profile-label">School Name<label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">School Link<label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="(e.g., https://)..." name="schoolURL" value={this.state.schoolURL} onChange={this.formChangeHandler}></input>
                            {(this.state.schoolURL && !this.state.schoolURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                          <div className="container-left">
                            <label className="profile-label">Degree Type<label className="error-color bold-text">*</label></label>
                            <select name="degreeType" value={this.state.degreeType} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.degreeOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Virtual vs. In Person<label className="error-color bold-text">*</label></label>
                            <select name="programMethod" value={this.state.programMethod} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.programMethodOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                          <div className="container-left">
                            <label className="profile-label">School Location</label>
                            <input type="text" className="text-field" placeholder="(e.g., 999 First Dr., Los Angeles, CA 90062)..." name="location" value={this.state.location} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Estimated Hours to Complete <label className="error-color bold-text">*</label></label>
                            <input type="number" className="number-field" placeholder="10" name="estimatedHours" value={this.state.estimatedHours} onChange={this.formChangeHandler}></input>
                            {/*
                            <select name="estimatedHours" value={this.state.estimatedHours} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.durationOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>*/}
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                          <div className="container-left">
                            <label className="profile-label">Grade Level<label className="error-color bold-text">*</label></label>
                            <select name="gradeLevel" value={this.state.gradeLevel} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.gradeLevelOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Domain Knowledge Level<label className="error-color bold-text">*</label></label>
                            <select name="knowledgeLevel" value={this.state.knowledgeLevel} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.knowledgeLevelOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <label className="profile-label">Primary Industry Focus</label>
                          <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.industryOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Top Occupational Areas (i.e., Function) This Applies To</label>

                          <div className="calc-column-offset-70">
                            <select name="function" value={this.state.function} onChange={this.formChangeHandler} className="dropdown">
                               {this.state.functionOptions.map(value =>
                                 <option key={value} value={value}>{value}</option>
                               )}
                             </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className="btn btn-squarish pin-right" onClick={() => this.addItem('function',this.state.function)}>Add</button>
                          </div>
                          <div className="clear" />
                          {(this.state.functions && this.state.functions.length > 0) && (
                            <div className="row-5">
                              {this.renderTags('function')}
                              <div className="clear" />
                            </div>
                           )}
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Pathways</label>

                          <div className="calc-column-offset-70">
                            <select name="pathway" value={this.state.pathway} onChange={this.formChangeHandler} className="dropdown">
                               {this.state.pathwayOptions.map(value =>
                                 <option key={value.title} value={value.title}>{value.title}</option>
                               )}
                             </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className="btn btn-squarish pin-right" onClick={() => this.addItem('pathway',this.state.pathway)}>Add</button>
                          </div>
                          <div className="clear" />
                          {(this.state.pathways && this.state.pathways.length > 0) && (
                            <div className="row-5">
                              {this.renderTags('pathway')}
                              <div className="clear" />
                            </div>
                           )}
                        </div>
                      </div>
                    )}

                    <div className="edit-profile-row">
                       <div>
                         <div className="anonymous-checkmark">
                           <input type="checkbox" name="isPerpetual" checked={this.state.isPerpetual} onChange={this.formChangeHandler} />
                         </div>
                         <div className="anonymous-descriptor">
                           <label className="description-text-1">This course is continual</label>
                         </div>
                         <div className="clear"/>
                         <div className="half-spacer" />
                       </div>

                       <div>
                         <div className="container-left">
                           <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                           <input type="date" className="date-picker" placeholder="Add the start date..." name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                         </div>

                         {(this.state.isPerpetual) ? (
                           <div>
                             <div className="container-right">
                               <div>
                                 <label className="profile-label">Ideal End Date<label className="error-color bold-text">*</label></label>
                                 <p className="heading-text-5">Continual</p>
                               </div>
                             </div>
                           </div>
                         ) : (
                           <div>
                             <div className="container-right">
                               <div>
                                 <label className="profile-label">Ideal End Date<label className="error-color bold-text">*</label></label>
                                 <input type="date" className="date-picker" placeholder="Add the end date..." name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                               </div>
                             </div>
                           </div>
                         )}
                         <div className="clear"/>
                       </div>
                    </div>

                    <div className="edit-profile-row">
                      <label className="profile-label">Course Description<label className="error-color bold-text">*</label></label>
                      <textarea type="text" className="text-field" placeholder="Add a general description of the course..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
                    </div>

                    {(!toggleVars.showLMSConfiguration) && (
                      <div>
                        <div className="edit-profile-row">
                          <label className="profile-label">Sample Job Titles</label>
                          <input type="text" className="text-field" placeholder="(e.g., Marketing Manager, Marketing Association, SEO Manager)..." name="sampleJobTitles" value={this.state.sampleJobTitles} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Outcomes Data</label>
                          <textarea type="text" className="text-field" placeholder="Any data on the outcomes/career progressions of people who completed the program..." name="outcomes" value={this.state.outcomes} onChange={this.formChangeHandler}></textarea>
                        </div>
                      </div>
                    )}

                    <div className="row-10">
                     <div className="container-left">
                       <label className="profile-label">Is Visible</label>
                       <Switch
                         onChange={(change) => this.setState({ isActive: change, formHasChanged: true })}
                         checked={this.state.isActive}
                         id="normal-switch"
                       />
                     </div>
                     <div className="container-right">
                       <label className="profile-label">Is Available to Everyone</label>
                       <Switch
                         onChange={(change) => this.setState({ availableToEveryone: change, formHasChanged: true })}
                         checked={this.state.availableToEveryone}
                         id="normal-switch"
                       />
                     </div>
                     <div className="clear" />
                    </div>

                    <div className="row-10 bottom-margin">
                     <hr />
                    </div>

                    { (this.state.serverSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.serverSuccessMessage}</p> }
                    { (this.state.serverErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.serverErrorMessage}</p> }
                    { (this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }
                    { (this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }

                    {(this.state.confirmDelete) ? (
                      <div>
                        <div className="row-5">
                          <p className="description-text-3">Are you sure? All enrollees should be notified that this course has been deleted</p>
                        </div>
                        <div className="row-5">
                         <div className="float-left">
                           <button className="btn btn-squarish error-background-color error-border" disabled={this.state.isSaving} onClick={() => this.deleteItem('course')}>Delete Permanently</button>
                         </div>
                         <div className="float-left left-padding">
                           <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                         </div>
                         <div className="clear"/>
                         </div>

                      </div>
                    ) : (
                      <div>
                        <div>
                         <div className="float-left right-margin">
                           <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.editCourse()}>{(this.state._id) ? "Edit" : "Add"} {(this.state.category) ? this.state.category : "Course"}</button>
                         </div>

                         {(this.state._id && this.state.learningModules && this.state.learningModules.length > 0) && (
                           <div className="float-left right-margin">
                             <button className="btn btn-primary senary-background-dark clear-border" disabled={this.state.isSaving} onClick={() => this.subNavClicked('Preview / Take')}>Take Course</button>
                           </div>
                         )}
                         {(this.state._id && this.state.learningModules && this.state.learningModules.length > 0) && (
                           <div className="float-left right-margin">
                             <button className="btn btn-primary septary-background-dark clear-border" disabled={this.state.isSaving} onClick={() => this.exportInfo()}>Export to CSV</button>
                           </div>
                         )}
                         {(this.state._id) && (
                           <div className="float-left right-margin">
                             <button className="btn btn-quaternary" disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete {(this.state.category) ? this.state.category : "Course"}</button>
                           </div>
                         )}
                         {(this.props.fromModal) && (
                           <div className="float-left left-padding">
                             <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                           </div>
                         )}
                         <div className="clear"/>
                        </div>
                      </div>
                    )}

                    {(this.state.showImportWidget) && (
                      <div>
                       <SubCoursesTwo history={this.props.navigate} modalIsOpen={this.state.modalIsOpen} showBrowseCourses={true} source={this.state.selectedSource} importCourse={this.importCourse} activeOrg={this.state.orgCode} showImportWidget={this.state.showImportWidget} toggleShowImportWidget={this.toggleShowImportWidget}/>
                      </div>
                    )}
                  </div>
                )}

                {(this.state.subNavSelected === 'Current Students') && (
                  <div>
                   {(this.state.students) ? (
                     <SubRenderProfiles
                       favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                       members={this.state.students} friends={null}
                       pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                     />
                   ) : (
                     <div>
                       <p className="error-color">No students have enrolled</p>
                     </div>
                   )}
                  </div>
                )}

                {(this.state.subNavSelected === 'Alumni') && (
                  <div>
                    {(this.state.alumni) ? (
                      <SubRenderProfiles
                        favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                        members={this.state.alumni} friends={null}
                        pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                      />
                    ) : (
                      <div>
                        <p className="error-color">No students have enrolled</p>
                      </div>
                    )}
                  </div>
                )}

                {(this.state.subNavSelected === 'Learning Modules') && (
                  <div className="top-margin">
                    {(this.state.learningModules && this.state.learningModules.length > 0) ? (
                      <div>
                        {this.state.learningModules.map((value, index) =>
                          <div key={"learningModules|" + index} className="relative-position">
                            <div className="full-width light-background padding-10 standard-border top-margin-20">
                              <div className="fixed-column-25">
                                <button className="background-button full-width left-text top-margin-5" onClick={() => this.moduleClicked(index,'expand')}>
                                  <img src={arrowIndicatorIcon} alt="GC" className={(value.isExpanded) ? "image-15-fit rotate-90" : "image-15-fit"} />
                                </button>
                              </div>
                              <div className="calc-column-offset-145">
                                {/*
                                <button className="background-button full-width left-text" onClick={() => this.moduleClicked(index,'expand')}>

                                </button>*/}
                                {(value.editMode) ? (
                                  <div>
                                    <div className="calc-column-offset-40">
                                      <input type="text" className="text-field" placeholder="(e.g., Add a name..." name={"learningModules|" + index + "|name"} value={value.name} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="fixed-column-40 left-padding-5">
                                      <button className="background-button full-width left-text top-margin-7" onClick={(e) => this.menuClicked(index,'Edit Name',null,e)}>
                                        <img src={deniedIcon} alt="GC" className="image-20-fit" />
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <p className="heading-text-5">{index + 1}. {value.name}</p>
                                )}
                              </div>
                              <div className="fixed-column-40">
                                <button className="background-button full-width left-text top-margin-3" onClick={() => this.moduleClicked(index,'visibility')}>
                                  <img src={(value.isVisible) ? visibleIconBlue : invisibleIconBlue} alt="GC" className="image-20-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(value.isVisible) ? "Hide" : "Make Visible"} />
                                </button>
                              </div>
                              <div className="fixed-column-40">
                                <button className="background-button full-width left-text top-margin-3" onClick={() => this.moduleClicked(index,'add')}>
                                  <img src={addIconBlue} alt="GC" className="image-20-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Add Lesson"} />
                                </button>
                              </div>
                              <div className="fixed-column-40">
                                <button className="background-button full-width left-text top-margin-3" onClick={() => this.moduleClicked(index,'menu')}>
                                  <img src={menuIconDark} alt="GC" className="image-20-fit pin-right" />
                                </button>
                              </div>
                              <div className="clear" />
                            </div>

                            <ReactTooltip id="tooltip-placeholder-id" />

                            {(value.showMenu) && (
                              <div className="menu-bottom-right white-background description-text-2">
                                {this.state.learningModuleMenuOptions.map((value2, index2) =>
                                  <div key={value2 + index2}>
                                    <button className="background-button full-width left-text" onClick={() => this.menuClicked(index,value2,null)}>
                                      <div className="row-7">
                                        <p>{value2}</p>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}

                            {(value.isExpanded && value.lessons && value.lessons.length > 0) && (
                              <div>
                                {value.lessons.map((value2, index2) =>
                                  <div key={'learningModules|' + index + "|lessons|" + index2} className="standard-border padding-10 left-padding-50">
                                    <div className="calc-column-offset-130">
                                      <div>
                                        {(value2.editMode) ? (
                                          <div>
                                            <div className="calc-column-offset-40">
                                              <input type="text" className="text-field" placeholder="(e.g., Add a name..." name={"learningModules|" + index + "|lessons|" + index2 + "|name"} value={value2.name} onChange={this.formChangeHandler}></input>
                                            </div>
                                            <div className="fixed-column-40 left-padding-5">
                                              <button className="background-button full-width left-text top-margin-7" onClick={(e) => this.menuClicked(index,'Edit Name',index2,e)}>
                                                <img src={deniedIcon} alt="GC" className="image-20-fit" />
                                              </button>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        ) : (
                                          <button className="background-button full-width left-text" onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + this.state._id + '/lesson', { state: { courseId: this.state._id, learningModules: this.state.learningModules, selectedIndex: index, selectedIndex2: index2 }})}>
                                            <label>{index + 1}.{index2 + 1}. {value2.name}</label>

                                            {(value2.content && value2.content.length > 0) && (
                                              <span>
                                                <label> - </label>
                                                {value2.content.map((value3, index3) =>
                                                  <span key={'learningModules|' + index + "|lessons|" + index2 + "|content|" + value3}>
                                                    {(value3.category) && (
                                                      <span className="left-padding-5">
                                                        <label className="description-text-2">[{value3.category}]</label>
                                                      </span>
                                                    )}
                                                  </span>
                                                )}
                                              </span>
                                            )}


                                            <div className="clear" />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <div className="fixed-column-70">
                                      <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, showEditMins: true, selectedIndex: index, selectedIndex2: index2, lessonMinutes: this.state.selectedCourse.learningModules[index].lessons[index2].minutes })}>
                                        <p className="description-text-2 cta-color">{(value2.minutes) ? value2.minutes + " mins" : "Add mins"}</p>
                                      </button>
                                    </div>
                                    <div className="fixed-column-30">
                                      <button className="background-button full-width left-text top-margin-3" onClick={() => this.moduleClicked(index,'lessonVisibility', index2)}>
                                        <img src={(value2.isVisible) ? visibleIconBlue : invisibleIconBlue} alt="GC" className="image-15-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(value.isVisible) ? "Hide" : "Make Visible"} />
                                      </button>
                                    </div>
                                    <div className="fixed-column-30">
                                      <button className="background-button full-width left-text top-margin-3" onClick={() => this.moduleClicked(index,'lessonMenu', index2)}>
                                        <img src={menuIconDark} alt="GC" className="image-15-fit pin-right" />
                                      </button>
                                    </div>
                                    <div className="clear" />

                                    {(value2.showMenu) && (
                                      <div className="menu-bottom-right white-background description-text-2">
                                        {this.state.learningModuleMenuOptions.map((value3, index3) =>
                                          <div key={value3 + index3}>
                                            <button className="background-button full-width left-text" onClick={() => this.menuClicked(index,value3,index2)}>
                                              <div className="row-7">
                                                <p>{value3}</p>
                                              </div>
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <p>There are not currently any learning modules.</p>
                      </div>
                    )}

                    <button className="background-button full-width top-margin-20 left-text" onClick={() => this.addItem(this.state.subNavSelected)}>
                      <div className="padding-20 cta-border-dashed">
                        <div className="fixed-column-40">
                          <div className="cta-border width-30 height-30 circle-radius flex-container flex-center">
                            <img src={addIconBlue} alt="GC" className="image-auto-15" />
                          </div>
                        </div>
                        <div className="calc-column-offset-40">
                          <p className="heading-text-5">Add New Section</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </button>
                  </div>
                )}
                {(this.state.subNavSelected === 'Learning Objectives') && (
                  <div>
                    {(this.state.learningObjectives && this.state.learningObjectives.length > 0) ? (
                      <div className="top-margin-30">
                        {this.state.learningObjectives.map((value, index) =>
                          <div key={"learningObjectives|" + index}>
                            <div className="top-margin-20">
                              <button className="background-button calc-column-offset-40 left-text" onClick={() => this.setState({ modalIsOpen: true, showEditLearningObjective: true, selectedIndex: index })}>
                                <div className="fixed-column-40 top-margin-3">
                                  <img src={targetIcon} alt="GC" className="image-23-fit" />
                                </div>

                                <div className="calc-column-offset-40">
                                  <p className="heading-text-6">{index + 1}. {value.name}</p>
                                  <p className="description-text-1 description-text-color top-margin-5">{value.description}</p>
                                </div>
                              </button>
                              <button className="background-button fixed-column-40 right-text" onClick={() => this.deleteItem('learningObjective',index)}>
                                <img src={deleteIconDark} alt="GC" className="image-23-fit pin-right" />
                              </button>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="full-width flex-container flex-center padding-40 wash-out-2 top-margin-30">
                          <div>
                            <img src={targetIcon} alt="GC" className="image-auto-80 center-horizontally" />
                            <p className="heading-text-5 center-text top-margin-30">No learning objectives created yet</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(this.state.subNavSelected === 'Syllabus') && (
                  <div>
                    {(this.state.syllabus && this.state.syllabus.length > 0) ? (
                      <button className="background-button full-width left-text standard-border padding-40 top-margin-30" onClick={() => this.setState({ modalIsOpen: true, showSyllabus: true })}>
                        <div className="calc-column-offset-40">
                          <p className="keep-line-breaks">{this.state.syllabus[0].body}</p>
                        </div>
                        <button className="background-button fixed-column-40" onClick={(e) => this.deleteItem('syllabus', 0, e)}>
                          <img src={deleteIconDark} alt="GC" className="image-23-fit pin-right" />
                        </button>
                        <div className="clear" />
                      </button>
                    ) : (
                      <div>
                        <div className="full-width flex-container flex-center padding-40 top-margin-30">
                          <div>
                            <img src={syllabusIconDark} alt="GC" className="image-auto-80 center-horizontally" />
                            <p className="heading-text-5 center-text top-margin-30">No syllabus added yet</p>
                            <button className="btn btn-primary top-margin-30" onClick={() => this.setState({ modalIsOpen: true, showSyllabus: true })}>Add a Syllabus</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(this.state.subNavSelected === 'Announcements') && (
                  <div>
                    {(this.state.announcements && this.state.announcements.length > 0) ? (
                      <div className="top-margin-20">
                        {this.state.announcements.map((value, index) =>
                          <button key={"announcement|" + index} className="background-button full-width left-text standard-border padding-30" onClick={() => this.addItem(this.state.subNavSelected, null, index)}>
                            <div className="calc-column-offset-130">
                              <p className="keep-line-breaks heading-text-5">{value.title}</p>
                              <p className="keep-line-breaks top-margin-5">{value.body}</p>
                            </div>
                            {(value.createdAt) ? (
                              <div className="fixed-column-90">
                                <p className="description-text-2 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                              </div>
                            ) : (
                              <div className="fixed-column-90 height-30" />
                            )}
                            <button className="background-button fixed-column-40" onClick={(e) => this.deleteItem('announcement', index, e)}>
                              <img src={deleteIconDark} alt="GC" className="image-23-fit pin-right" />
                            </button>
                            <div className="clear" />
                          </button>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="full-width flex-container flex-center padding-40 top-margin-30">
                          <div>
                            <img src={messagesIconDark} alt="GC" className="image-auto-80 center-horizontally" />
                            <p className="heading-text-5 center-text top-margin-30">No announcements added yet</p>
                            <button className="btn btn-primary top-margin-30" onClick={() => this.addItem(this.state.subNavSelected)}>Add an Announcement</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(this.state.subNavSelected === 'Discussions') && (
                  <div>
                    <SubComments selectedCourse={this.state.selectedCourse} activeOrg={this.state.orgCode} accountCode={this.state.accountCode} comments={this.state.comments} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} pageSource={this.state.pageSource}/>
                  </div>
                )}
                {(this.state.subNavSelected === 'Members') && (
                  <div>
                    {/*
                    <div className="cta-border padding-15">
                      <label className="profile-label">Make Available to Everyone in the {this.state.orgName} Community?  <label className="error-color bold-text">*</label></label>
                      <Switch
                        onChange={(change) => this.saveChanges('availableToEveryone',null,change) }
                        checked={this.state.availableToEveryone}
                        id="normal-switch"
                      />
                    </div>*/}

                    <div>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                      {(this.state.courseMembers && this.state.courseMembers.length > 0) ? (
                        <div className="top-margin-20">
                          {this.state.courseMembers.map((value, index) =>
                            <button key={"member|" + index} className="background-button full-width left-text standard-border padding-15" onClick={() => { this.setState({ modalIsOpen: true, showMemberDetails: true, selectedMember: value }); this.pullMessages(value)}}>
                              <div className="fixed-column-40">
                                <p>{index + 1}.</p>
                              </div>
                              <div className="fixed-column-40">
                                <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                              </div>
                              <div className="calc-column-offset-120">
                                <p className="">{value.firstName} {value.lastName}</p>
                                <p className="description-text-2 description-text-color top-margin-5">[{value.roleName}] ({value.email})</p>
                              </div>
                              <button className="background-button fixed-column-40" onClick={(e) => this.deleteItem('member', index, e)}>
                                <img src={deleteIconDark} alt="GC" className="image-23-fit pin-right" />
                              </button>
                              <div className="clear" />
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="full-width flex-container flex-center padding-40">
                            <div className="center-text">
                              <img src={socialIconDark} alt="GC" className="image-auto-80 center-horizontally" />
                              <p className="heading-text-5 center-text top-margin-30">No members added yet</p>
                              <p className="center-text top-margin-30">Integrate Guided Compass with your primary Learning Management System (LMS) to sync members. Request integration at help@guidedcompass.com.</p>
                              <button className="btn btn-primary top-margin-30" onClick={() => this.addItem(this.state.subNavSelected)}>Add Members</button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(this.state.subNavSelected === 'Submissions') && (
                  <div>
                    {(this.state.submissions) && (
                      <div>
                        {this.renderItems('submission',this.state.submissions, null)}
                        {/*
                        {this.state.submissions.map((value, index) =>
                          <div key={"submissions|" + index} className="top-margin-5">
                            <button className="background-button full-width left-text row-10" onClick={() => this.setState({ modalIsOpen: true, showSubmission: true, selectedIndex: index })}>
                              <div className="fixed-column-40 top-margin-3">
                                <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                              </div>
                              <div className="calc-column-offset-160">
                                <p>{value.firstName} {value.lastName}</p>
                                <p className="description-text-2 description-text-color top-margin-5">Submitted to {value.assessmentName}</p>
                              </div>
                              {(value.grade) ? (
                                <div className="fixed-column-40">
                                  <p className="heading-text-5 cta-color full-width right-text">{value.grade}%</p>
                                </div>
                              ) : (
                                <div className="fixed-column-40 height-30" />
                              )}
                              <div className="fixed-column-80">
                                <p className="description-text-3 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                              </div>
                              <div className="clear" />
                            </button>

                            <div className="spacer" />
                            <hr />
                            <div className="half-spacer" />

                          </div>
                        )}*/}
                      </div>
                    )}
                  </div>
                )}
                {(this.state.subNavSelected === 'Grades') && (
                  <div>
                    {(this.state.grades) && (
                      <div>
                        {this.renderItems('grade',this.state.grades, null)}
                        {/*
                        {this.state.grades.map((value, index) =>
                          <div key={"submissions|" + index} className="top-margin-5">
                            <button className="background-button full-width left-text row-10" onClick={() => this.setState({ modalIsOpen: true, showSubmission: true, selectedIndex: index })}>
                              <div className="fixed-column-40 top-margin-3">
                                <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                              </div>
                              <div className="calc-column-offset-160">
                                <p>{value.firstName} {value.lastName}</p>
                                <p className="description-text-2 description-text-color top-margin-5">Submitted to {value.assessmentName}</p>
                              </div>
                              {(value.grade) ? (
                                <div className="fixed-column-40">
                                  <p className="heading-text-5 cta-color full-width right-text">{value.grade}%</p>
                                </div>
                              ) : (
                                <div className="fixed-column-40 height-30" />
                              )}
                              <div className="fixed-column-80">
                                <p className="description-text-3 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                              </div>
                              <div className="clear" />
                            </button>

                            <div className="spacer" />
                            <hr />
                            <div className="half-spacer" />

                          </div>
                        )}*/}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="clear" />
        </div>
      )
    }

    moduleClicked(index,type,index2) {
      console.log('moduleClicked called', index, type, index2, this.state.learningModules)
      let learningModules = this.state.learningModules
      let saveChanges = false
      if (type === 'add') {
        learningModules[index]['lessons'].push({ lessonId: new Date().toString() + this.state.emailId, name: 'Lesson ' + (learningModules[index].lessons.length + 1), rank: learningModules[index].lessons.length + 1 })
        learningModules[index]['isExpanded'] = true
        saveChanges = true
      } else if (type === 'expand') {
        learningModules[index]['isExpanded'] = !learningModules[index].isExpanded
      } else if (type === 'visibility') {
        learningModules[index]['isVisible'] = !learningModules[index].isVisible
        if (learningModules[index].lessons && learningModules[index].lessons.length > 0) {
          for (let i = 1; i <= learningModules[index].lessons.length; i++) {
            learningModules[index].lessons[i - 1]['isVisible'] = learningModules[index].isVisible
          }
        }
        saveChanges = true
      } else if (type === 'lessonVisibility') {
        learningModules[index]['lessons'][index2]['isVisible'] = !learningModules[index].lessons[index2].isVisible
        saveChanges = true
      } else if (type === 'menu') {
        learningModules[index]['showMenu'] = !learningModules[index].showMenu
      } else if (type === 'lessonMenu') {
        learningModules[index]['lessons'][index2]['showMenu'] = !learningModules[index].lessons[index2].showMenu
      }

      this.setState({ learningModules })
      if (saveChanges) {
        this.saveLearningModules(learningModules)
      }
    }

    menuClicked(index,type,index2,e) {
      console.log('menuClicked called', index,type,index2)

      if (e) {
        e.stopPropagation()
      }

      let learningModules = this.state.learningModules
      let saveChanges = false

      if (index2 || index2 === 0) {
        console.log('?!=====sdf', learningModules)
        learningModules[index]['lessons'][index2]['showMenu'] = false
      } else {
        learningModules[index]['showMenu'] = false
      }

      if (type === 'Edit Name') {
        if (index2 || index2 === 0) {

          learningModules[index]['lessons'][index2]['editMode'] = !learningModules[index].lessons[index2].editMode
        } else {
          learningModules[index]['editMode'] = !learningModules[index].editMode
        }
        saveChanges = true
      } else if (type === 'Move') {
        this.setState({ modalIsOpen: true, showMoveMenu: true, selectedIndex: index, selectedIndex2: index2 })
      } else if (type === 'Delete') {
        this.setState({ modalIsOpen: true, showConfirmDelete: true, selectedIndex: index, selectedIndex2: index2 })
        // if (index2 || index2 === 0) {
        //   learningModules[index]['lessons'].splice(index2,1)
        // } else {
        //   learningModules.splice(index,1)
        // }
        // saveChanges = true
      } else if (type === 'Duplicate') {
        if (index2 || index2 === 0) {
          const duplicateEntry = {
            name: learningModules[index].lessons[index2].name + ' (Duplicate)',
            isVisible: learningModules[index].lessons[index2].isVisible + ' (Duplicate)',
          }
          learningModules[index]['lessons'].push(duplicateEntry)
        } else {

          const duplicateEntry = {
            name: learningModules[index].name + ' (Duplicate)',
            isVisible: learningModules[index].isVisible,
            lessons: [...learningModules[index].lessons]
          }

          learningModules.push(duplicateEntry)
        }
      } else if (type === 'Share to Exchange') {

      }

      this.setState({ learningModules })
      if (saveChanges) {
        this.saveLearningModules()
      }
    }

    moveItems() {
      console.log('moveItems called')

      if (!this.state.moveInstrux) {
        this.setState({ errorMessage: 'Please add details on where to move this item'})
      } else {
        let learningModules = this.state.learningModules
        let selectedElement = learningModules[this.state.selectedIndex]
        let lessons = this.state.learningModules[this.state.selectedIndex].lessons

        if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
          console.log('what is happening 0', this.state.moveInstrux, lessons)
          selectedElement = lessons[this.state.selectedIndex2]
          lessons.splice(this.state.selectedIndex2,1)
          console.log('what is happening 1', this.state.moveInstrux, lessons)
        } else {
          learningModules.splice(this.state.selectedIndex,1)
          console.log('what is happening 2', this.state.moveInstrux,  lessons)
        }

        if (this.state.moveInstrux === 'To the Top') {

          if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
            lessons.unshift(selectedElement)
            learningModules[this.state.selectedIndex]['lessons'] = lessons
          } else {
            learningModules.unshift(selectedElement)
          }
        } else if (this.state.moveInstrux === 'To the Bottom') {
          if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
            lessons.push(selectedElement)
            learningModules[this.state.selectedIndex]['lessons'] = lessons
          } else {
            learningModules.push(selectedElement)
          }

        } else if (this.state.moveInstrux === 'Before') {
          // for (let i = 1; i <= learningModules.length; i++) {
          //   if (learningModules[i - 1].name === this.state.moveInstrux2) {
          //     learningModules.splice(i - 1,0,selectedElement)
          //   }
          // }
          // for (let i = 1; i <= learningModules.length; i++) {
          //   if (learningModules[i - 1].name === this.state.moveInstrux2) {
          //     learningModules.splice(i - 1,0,selectedElement)
          //   }
          // }
        } else if (this.state.moveInstrux === 'After') {
          console.log('t1')
          if (this.state.selectedIndex2 || this.state.selectedIndex2 === 0) {
            console.log('t2', lessons)
            for (let i = 1; i <= lessons.length; i++) {
              if (lessons[i - 1].name === this.state.moveInstrux2) {
                lessons.splice(i,0,selectedElement)
              }
            }
            learningModules[this.state.selectedIndex]['lessons'] = lessons
          } else {
            console.log('t3', lessons)
            for (let i = 1; i <= learningModules.length; i++) {
              if (learningModules[i - 1].name === this.state.moveInstrux2) {
                learningModules.splice(i,0,selectedElement)
              }
            }
          }
        }
        console.log('t4', lessons)
        //moveInstrux, moveInstrux2, learningModules

        this.setState({ learningModules, errorMessage: null })
        this.closeModal()
        this.saveLearningModules(learningModules)
      }
    }

    async saveChanges(type, instrux, passedValue) {
      console.log('saveChanges called', type, instrux, passedValue)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (type === 'learningObjective') {

        const learningObjectives = this.state.learningObjectives
        const moduleObject = { _id: this.state._id, learningObjectives, updatedAt: new Date() }
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false, successMessage: 'Saved successfully!' })
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
        this.closeModal()
      } else if (type === 'grade') {
        console.log('grade called')

        const _id = this.state.submissions[this.state.selectedIndex]._id
        const grade = this.state.submissions[this.state.selectedIndex].grade
        const gradeExplanation = this.state.submissions[this.state.selectedIndex].gradeExplanation
        const updatedAt = new Date()

        const submission = { _id, grade, gradeExplanation, updatedAt }

        Axios.post('/api/submissions', submission)
        .then((response) => {
          console.log('attempting to save submission')
          if (response.data.success) {
            console.log('saved submission', response.data)

            this.setState({ isSaving: false, successMessage: 'Submitted successfully!' })
            this.closeModal()

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            this.closeModal()
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving submission', isSaving: false })
            this.closeModal()
        });
      } else if (type === 'syllabus') {
        const syllabus = this.state.syllabus
        const moduleObject = { _id: this.state._id, syllabus, updatedAt: new Date() }
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false, successMessage: 'Saved successfully!' })
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
        this.closeModal()
      } else if (type === 'announcement') {
        const announcements = this.state.announcements
        const moduleObject = { _id: this.state._id, announcements, updatedAt: new Date() }
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false,  successMessage: 'Saved successfully!' })
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
        this.closeModal()
      } else if (type === 'member') {
        console.log('in member')

        if (instrux === 'add') {
          const courseId = this.state._id

          const firstName = passedValue.firstName
          const lastName = passedValue.lastName
          const email = passedValue.email
          const username = passedValue.username
          const pictureURL = passedValue.pictureURL
          const roleName = passedValue.roleName

          const courseName = this.state.courseName
          const courseImageURL = this.state.imageURL
          const courseDescription = this.state.description
          const courseEstimatedHours = this.state.estimatedHours
          const courseMinutes = this.state.minutes
          const courseDifficultyLevel = this.state.difficultyLevel
          const courseSchoolName = this.state.orgName
          const courseCurriculumItemId = this.state.curriculumItemId
          const cohort = this.state.cohort
          const isCompleted = false
          const orgCode = this.state.orgCode
          const orgName = this.state.orgName

          const createdAt = new Date()
          const updatedAt = new Date()

          const saveObject = {
            firstName, lastName, email, username, pictureURL, roleName,
            courseId, courseName, courseImageURL, courseDescription, courseEstimatedHours, courseEstimatedMinutes,
            courseDifficultyLevel, courseSchoolName, courseCurriculumItemId, cohort, isCompleted, orgCode, orgName,
            createdAt, updatedAt
          }

          Axios.post('/api/courses/enrollments', saveObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Course enrollment save worked', response.data);

              this.setState({ isSaving: false, successMessage: "New course enrollment saved" })

            } else {
              console.error('there was an error posting the course enrollment');
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('The course enrollment post did not work', error);
              this.setState({ isSaving: false, errorMessage: error.toString() })
          });
        } else {
          // delete enrollment

          const enrollmentId = passedValue._id

          Axios.delete('/api/courses/enrollments/' + enrollmentId)
          .then((response) => {
            console.log('tried to delete enrollment', response.data)
            if (response.data.success) {
              //save values
              console.log('Enrollment delete worked');

              // let enrollments = this.state.enrollments
              // enrollments.splice(index,1)
              this.setState({ isSaving: false, successMessage: response.data.message })

            } else {
              console.error('there was an error deleting the posting');
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('The deleting did not work', error);
              this.setState({ isSaving: false, errorMessage: error.toString() })
          });
        }
      } else if (type === 'availableToEveryone') {

        // saveChanges
        const availableToEveryone = passedValue
        this.setState({ availableToEveryone })

        const moduleObject = { _id: this.state._id, availableToEveryone, updatedAt: new Date() }
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false,  successMessage: 'Saved successfully!' })
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
      } else if (type === 'lessonMinutes') {

        let selectedCourse = this.state.selectedCourse
        selectedCourse['learningModules'][this.state.selectedIndex].lessons[this.state.selectedIndex2]['minutes'] = this.state.lessonMinutes
        // this.setState({ selectedCourse })

        const learningModules = selectedCourse.learningModules
        const moduleObject = { _id: this.state._id, learningModules, updatedAt: new Date() }
        // console.log('lms: ', learningModules)
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false,  successMessage: 'Saved successfully!', selectedCourse, learningModules: selectedCourse.learningModules })
          this.closeModal()
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
      } else if (type === 'learningModule') {

        // let selectedCourse = this.state.selectedCourse
        // selectedCourse['learningModules'][this.state.selectedIndex].lessons[this.state.selectedIndex2]['minutes'] = this.state.lessonMinutes
        // this.setState({ selectedCourse })

        const learningModules = passedValue
        const moduleObject = { _id: this.state._id, learningModules, updatedAt: new Date() }
        // console.log('got the learningModules? ', learningModules)
        // console.log('lms: ', learningModules)
        const returnedValue = await saveCourseChanges(moduleObject)

        if (returnedValue && returnedValue.success) {
          console.log('save was successful: ', returnedValue)
          this.setState({ isSaving: false,  successMessage: 'Saved successfully!', learningModules })
          this.closeModal()
        } else if (returnedValue && returnedValue.message) {
          console.log('sign up error: ', returnedValue)
          this.setState({ isSaving: false, errorMessage: returnedValue.message })
        } else {
          this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
        }
      }
    }

    async saveLearningModules(learningModules) {
      console.log('saveLearningModules called')

      if (!learningModules) {
        learningModules = this.state.learningModules
      }

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const moduleObject = { _id: this.state._id, learningModules, updatedAt: new Date() }
      const returnedValue = await saveCourseChanges(moduleObject)

      if (returnedValue && returnedValue.success) {
        console.log('save was successful: ', returnedValue)
        this.setState({ isSaving: false, successMessage: returnedValue.message })
      } else if (returnedValue && returnedValue.message) {
        console.log('sign up error: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message })
      } else {
        this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
      }
    }

    openCurriculumExchange(type) {
      console.log('openCurriculumExchange called')

      this.props.closeModal()
      this.props.openCurriculumExchange(type)

    }

    renderMessage(message, cu) {
      // console.log('renderMessage called')

      if (message && message.length > 0) {
        if (message.startsWith('<img src=')) {
          // let styleObject = ""
          if (!cu) {
            message = message.replace(/margin-left: auto;/g,"margin-right: auto;")
          }
        } else {
          let styleObject = "font-size: 70%; white-space: pre-wrap;"
          if (cu) {
            styleObject = styleObject + "text-align: right;"
          }

          const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
          message = message.replace(Rexp,"<a href='$1' target='_blank'>$1</a>");
          message = '<div style="' + styleObject + '">' + message + '</div>'
        }
      }

      return message

    }

    renderItems(type, items, memberEmail, expandedVersion) {
      console.log('renderItems called', type, items, memberEmail, expandedVersion)

      let rows = []

      for (let i = 1; i <= items.length; i++) {

        const value = items[i - 1]
        const index = i - 1

        if (!memberEmail || memberEmail === value.emailId) {
          rows.push(
            <div key={(memberEmail) ? type + "|" + index + "|" + memberEmail : type + "|" + index}>
              <div className="top-margin-5">
                {(!expandedVersion) ? (
                  <div>
                    <button className={"background-button full-width left-text row-10"} onClick={() => this.setState({ modalIsOpen: true, showSubmission: true, selectedIndex: index })}>
                      <div className="fixed-column-40 top-margin-3">
                        <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                      </div>
                      <div className="calc-column-offset-160">
                        <p>{value.firstName} {value.lastName}</p>
                        <p className="description-text-2 description-text-color top-margin-5">Submitted to {value.assessmentName}{(value.subType) && " [" + value.subType + "]"}</p>
                      </div>
                      {(value.grade) ? (
                        <div className="fixed-column-40">
                          <p className="heading-text-5 cta-color full-width right-text">{value.grade}%</p>
                        </div>
                      ) : (
                        <div className="fixed-column-40 height-30" />
                      )}
                      <div className="fixed-column-80">
                        <p className="description-text-3 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                      </div>
                      <div className="clear" />
                    </button>

                    <div className="spacer" />
                    <hr />
                    <div className="half-spacer" />
                  </div>
                ) : (
                  <div className="standard-border top-margin-20 padding-20">
                    <div className="fixed-column-40 top-margin-3">
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                    </div>
                    <div className="calc-column-offset-160">
                      <p>{value.firstName} {value.lastName}</p>
                      <p className="description-text-2 description-text-color top-margin-5">Submitted to {value.assessmentName}{(value.subType) && "(" + value.subType + ")"}</p>
                    </div>
                    {(value.grade) ? (
                      <div className="fixed-column-40">
                        <p className="heading-text-5 cta-color full-width right-text">{value.grade}%</p>
                      </div>
                    ) : (
                      <div className="fixed-column-40 height-30" />
                    )}
                    <div className="fixed-column-80">
                      <p className="description-text-3 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                    </div>
                    <div className="clear" />

                    {(value.answers && value.answers.length > 0) && (
                      <div className="top-margin">
                        {value.answers.map((value2, index2) =>
                          <div key={"submission|" + index + "|answer|" + index} className="top-margin-15">
                            <p className="description-text-2 description-text-2">{value2.question}</p>
                            <p className="top-margin-5">{value2.answer}</p>
                          </div>
                        )}
                      </div>
                    )}

                    {(value.title && value.details) && (
                      <div className="top-margin-20 cta-border padding-20">
                        <div className="row-5">
                          <p className="heading-text-4">{value.title}</p>
                        </div>

                        <div className="row-5">
                          <p className="keep-line-breaks">{value.details}</p>
                        </div>

                        {(value.externalURL) && (
                          <div className="row-5">
                            <a href={value.externalURL} target="_blank" rel="noopener noreferrer">{value.externalURL}</a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }
      }

      return rows
    }

    exportInfo(selectedMember) {
      console.log('exportInfo called')

      if (selectedMember) {

        let csvData = []
        csvData.push(
          [ "First Name","Last Name","Email","Course","Report Type","Title","Assignment", "Grade","Grade Explanation","Message Type","Message","Created At", "Updated At" ]
        )

        let commaStartingValue = /,/g
        let commaReplacementValue = ";"

        if (this.state.submissions && this.state.submissions.length > 0) {
          for (let i = 1; i <= this.state.submissions.length; i++) {
            const item = this.state.submissions[i - 1]
            if (this.state.selectedMember.email === item.emailId) {
              const firstName = selectedMember.firstName
              const lastName = selectedMember.lastName
              let email = selectedMember.email

              let course = 'N/A'
              if (item.courseName) {
                course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              }

              const reportType = 'Submission'

              let title = ''
              if (item.title) {
                title = item.title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              }

              let assignment = ''
              if (item.assessmentName) {
                assignment = item.assessmentName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              } else if (item.lessonName) {
                assignment = item.lessonName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              }

              let grade = ''
              if (item.grade) {
                grade = item.grade
              }

              let gradeExplanation = ''
              if (item.gradeExplanation) {
                gradeExplanation = item.gradeExplanation.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              }

              const direction = ''
              const message = ''

              const createdAt = item.createdAt
              const updatedAt = item.updatedAt

              csvData.push([
                firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
                direction, message, createdAt, updatedAt
              ])
            }
          }
        }

        if (this.state.messages && this.state.messages.length > 0) {
          for (let i = 1; i <= this.state.messages.length; i++) {
            const item = this.state.messages[i - 1]

            const firstName = selectedMember.firstName
            const lastName = selectedMember.lastName
            let email = selectedMember.email

            let course = 'N/A'
            if (item.courseName) {
              course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
            }

            const reportType = 'A.I. Conversation'

            let title = ''
            let assignment = ''
            let grade = ''
            let gradeExplanation = ''

            let direction = 'Received'
            if (item.senderEmail === email) {
              direction = 'Sent'
            }

            let message = ''
            if (item.message) {
              message = item.message.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
            }

            const createdAt = item.createdAt
            const updatedAt = item.updatedAt

            csvData.push([
              firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
              direction, message, createdAt, updatedAt
            ])
          }
        }

        const returnedData = subExportCSV(selectedMember.firstName + "_" + selectedMember.lastName + "_" + this.state.selectedCourse.name,csvData)
        // console.log('returnedData response: ', returnedData)

      } else {
        // export entire course

        let csvData = []
        csvData.push(
          [ "Course Name","Data Type","Minutes","Member Count","Description","Module Name","Lesson Name","Lesson Type","Learning Objective","Syllabus","First Name","Last Name","Email","Title","Assignment", "Grade","Grade Explanation","Message Type","Message", "Created At", "Updated At" ]
        )
        // syllabus, members
        let commaStartingValue = /,/g
        let commaReplacementValue = ";"

        let courseName = ""
        if (this.state.courseName) {
          courseName = this.state.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        }

        let dataType = "Basic Info"

        const minutes = this.state.selectedCourse.minutes

        let courseDescription = ""
        if (this.state.description) {
          courseDescription = this.state.description.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        }

        let memberCount = 0
        if (this.state.courseMembers && this.state.courseMembers.length > 0) {
          // const dataType = "Member Count"

          memberCount = this.state.courseMembers.length
        }

        // row: courseBasicInfo
        csvData.push([
          courseName, dataType, minutes, memberCount, courseDescription
        ])

        if (this.state.learningModules && this.state.learningModules.length > 0) {
          for (let i = 1; i <= this.state.learningModules.length; i++) {
            const item = this.state.learningModules[i - 1]

            const dataType = 'Learning Module'

            if (item.lessons && item.lessons.length > 0) {
              for (let j = 1; j <= item.lessons.length; j++) {
                const item2 = item.lessons[j - 1]

                let moduleName = ''
                if (item.name) {
                  moduleName = item.name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                }

                let lessonName = ''
                if (item2.name) {
                  lessonName = item2.name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
                }

                let lessonType = ''
                if (item2.content && item2.content.length > 0) {
                  lessonType = item2.content[0].category
                }

                // const createdAt = item.createdAt
                // const updatedAt = item.updatedAt

                csvData.push([
                  courseName, dataType, null, null, null,
                  moduleName, lessonName, lessonType, null, null,
                  null, null, null, null, null, null, null,
                  null, null, null, null
                ])
              }
            }
          }
        }

        if (this.state.learningObjectives && this.state.learningObjectives.length > 0) {
          for (let i = 1; i <= this.state.learningObjectives.length; i++) {
            const item = this.state.learningObjectives[i - 1]

            const dataType = 'Learning Objective'

            if (item) {
              let objective = ''
              if (item.name) {
                objective = item.name.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
              }

              csvData.push([
                courseName, dataType, null, null, null,
                null, null, null, objective, null,
                null, null, null, null, null, null, null,
                null, null, null, null
              ])
            }
          }
        }

        if (this.state.syllabus) {
          const dataType = "Syllabus"

          let syllabus = ''
          if (this.state.syllabus && this.state.syllabus.length > 0) {
            syllabus = this.state.syllabus[0].body.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
          }

          csvData.push([
            courseName, dataType, null, null, null,
            null, null, null, null, syllabus,
            null, null, null, null, null, null, null,
            null, null, null, null
          ])
        }

        if (this.state.submissions && this.state.submissions.length > 0) {
          for (let i = 1; i <= this.state.submissions.length; i++) {
            const item = this.state.submissions[i - 1]

            const dataType = 'Submission'

            const firstName = item.firstName
            const lastName = item.lastName
            let email = item.emailId

            let title = ''
            if (item.title) {
              title = item.title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
            }

            let assignment = ''
            if (item.assessmentName) {
              assignment = item.assessmentName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
            } else if (item.lessonName) {
              assignment = item.lessonName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
            }

            let grade = ''
            if (item.grade) {
              grade = item.grade
            }

            let gradeExplanation = ''
            if (item.gradeExplanation) {
              gradeExplanation = item.gradeExplanation.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
            }

            const direction = ''
            const message = ''

            const createdAt = item.createdAt
            const updatedAt = item.updatedAt

            csvData.push([
              courseName, dataType, null, null, null,
              null, null, null, null, null,
              firstName, lastName, email, title, assignment, grade, gradeExplanation,
              direction, message, createdAt, updatedAt
            ])
          }
        }

        if (this.state.courseMessages && this.state.courseMessages.length > 0) {
          for (let i = 1; i <= this.state.courseMessages.length; i++) {
            const item = this.state.courseMessages[i - 1]

            const dataType = 'A.I. Conversation'

            let firstName = item.senderFirstName
            let lastName = item.senderLastName
            let email = item.senderEmail
            if (item.senderFirstName === 'Compass' || item.senderFirstName === 'Assistant') {
              if (item.recipients) {
                firstName = item.recipients[0].firstName
                firstName = item.recipients[0].lastName
                firstName = item.recipients[0].email
              }
            }

            let title = ''
            let assignment = ''
            let grade = ''
            let gradeExplanation = ''

            let direction = 'Received'
            if (item.senderEmail === email) {
              direction = 'Sent'
            }

            let message = ''
            if (item.message) {
              message = item.message.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
            }

            const createdAt = item.createdAt
            const updatedAt = item.updatedAt

            csvData.push([
              courseName, dataType, null, null, null,
              null, null, null, null, null,
              firstName, lastName, email, title, assignment, grade, gradeExplanation,
              direction, message, createdAt, updatedAt
            ])
          }
        }

        const returnedData = subExportCSV(this.state.selectedCourse.name + "_" + new Date().toString(),csvData)

      }
    }

    attachmentClicked(item) {
      console.log('attachmentClicked called', item)

      if (item.url) {
        // this.props.navigate(item.url)
        window.open(item.url)
      } else {
        // this.props.navigate(this.state.prefix + '/')
        window.open(this.state.prefix + '/members/' + this.state.submissions[this.state.selectedIndex].username)
      }
    }

    render() {

      return (
          <div>
            <div>
              {(!this.props.fromModal) && (
                <div>
                  {this.renderDetails()}
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >
              {console.log('are we from modal? ', this.props.fromModal)}
              {(this.props.fromModal) && (
                <div key="fromModalRender" className="full-width padding-20">
                 {this.renderDetails()}
                </div>
              )}
              {(this.state.showMoveMenu) && (
                <div key="showMoveMenu" className="full-width padding-20">
                  {(this.state.learningModules && (this.state.selectedIndex || this.state.selectedIndex === 0)) && (
                    <p className="heading-text-4">Move ""{(this.state.selectedIndex2 || this.state.selectedIndex2 === 0) ? this.state.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].name : this.state.learningModules[this.state.selectedIndex].name}""</p>
                  )}

                 <div className="spacer" /><div className="spacer" />

                 <div className="row-15">
                   <select name="moveInstrux" value={this.state.moveInstrux} onChange={this.formChangeHandler} className="dropdown">
                     {this.state.moveInstruxOptions.map(value =>
                       <option key={value.key} value={value}>{value}</option>
                     )}
                   </select>
                 </div>

                 {(this.state.moveInstrux === 'Before' || this.state.moveInstrux === 'After') && (
                   <div className="row-15">
                     {(this.state.selectedIndex2 || this.state.selectedIndex2 === 0) ? (
                       <select name="moveInstrux2" value={this.state.moveInstrux2} onChange={this.formChangeHandler} className="dropdown">
                         {[{ name: '' }].concat(this.state.learningModules[this.state.selectedIndex].lessons).map(value =>
                           <option key={value.name} value={value.name}>{value.name}</option>
                         )}
                       </select>
                     ) : (
                       <select name="moveInstrux2" value={this.state.moveInstrux2} onChange={this.formChangeHandler} className="dropdown">
                         {[{ name: '' }].concat(this.state.learningModules).map(value =>
                           <option key={value.name} value={value.name}>{value.name}</option>
                         )}
                       </select>
                     )}
                   </div>
                 )}

                 <div className="spacer" /><div className="spacer" />

                 {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                 {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                 <button className="btn btn-primary" onClick={() => this.moveItems()}>Move</button>
                 <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                </div>
              )}

              {(this.state.showEditLearningObjective) && (
                <div>
                  <div className="spacer" />
                  <p className="heading-text-3">Edit {((this.state.selectedIndex || this.state.selectedIndex === 0) && this.state.learningObjectives[this.state.selectedIndex].name) ? this.state.learningObjectives[this.state.selectedIndex].name : "Learning Objective"}</p>
                  <p className="top-margin-5">Learning Objective</p>
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <label className="profile-label">Name</label>
                    <input type="text" className="text-field" placeholder="(e.g., Name of learning objective..." name={"learningObjective|" + this.state.selectedIndex + "|name"} value={this.state.learningObjectives[this.state.selectedIndex].name} onChange={this.formChangeHandler}></input>
                  </div>

                  <div className="row-10">
                    <label className="profile-label">Description</label>
                    <textarea type="text" className="text-field" placeholder="(e.g., Description of learning objective..." name={"learningObjective|" + this.state.selectedIndex + "|description"} value={this.state.learningObjectives[this.state.selectedIndex].description} onChange={this.formChangeHandler}></textarea>
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  <button className="btn btn-primary" onClick={() => this.saveChanges('learningObjective')}>Edit</button>
                  <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                </div>
              )}

              {(this.state.showSubmission) && (
                <div key="showMoveMenu" className="full-width padding-20">
                  {(this.state.selectedIndex || this.state.selectedIndex === 0) && (
                    <div>
                      <div className="spacer" />
                      <p className="heading-text-2">{this.state.submissions[this.state.selectedIndex].firstName} {this.state.submissions[this.state.selectedIndex].lastName}'s Submission for {this.state.submissions[this.state.selectedIndex].assessmentName}</p>

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.submissions[this.state.selectedIndex].answers && this.state.submissions[this.state.selectedIndex].answers.length > 0) && (
                        <div>
                          <hr />

                          <div className="spacer" />

                          {this.state.submissions[this.state.selectedIndex].answers.map((value, index) =>
                            <div key={"submission|" + this.state.selectedIndex + "|answer|" + index} className="top-margin-15">
                              <p className="description-text-2 description-text-2">{value.question}</p>
                              <p className="top-margin-5">{value.answer}</p>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.submissions[this.state.selectedIndex].title && this.state.submissions[this.state.selectedIndex].details) && (
                        <div className="top-margin-20 standard-border padding-20">
                          <div className="row-10">
                            <p className="description-text-2 description-text-2">Name</p>
                            <p className="top-margin-5">{this.state.submissions[this.state.selectedIndex].title}</p>
                          </div>

                          <div className="row-10">
                            <p className="description-text-2 description-text-2">Details</p>
                            <p className="keep-line-breaks">{this.state.submissions[this.state.selectedIndex].details}</p>
                          </div>

                          {(this.state.submissions[this.state.selectedIndex].externalURL) && (
                            <div className="row-10">
                              <p className="description-text-2 description-text-2">Link</p>
                              <a href={this.state.submissions[this.state.selectedIndex].externalURL} target="_blank" rel="noopener noreferrer">{this.state.submissions[this.state.selectedIndex].externalURL}</a>
                            </div>
                          )}

                          {(this.state.submissions[this.state.selectedIndex].attachments) && (
                            <div className="row-10">
                              <p className="description-text-2 description-text-2">Attachments</p>
                              {this.state.submissions[this.state.selectedIndex].attachments.map((value, optionIndex) =>
                                <div key={value.id} className="left-text top-margin full-width">
                                  <button className="background-button full-width left-text cta-border padding-20" onClick={() => this.attachmentClicked(value)}>
                                    <div className="fixed-column-40">
                                      <p>{optionIndex + 1}</p>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p className="cta-color">{value.name}</p>
                                      <p className="description-text-3">({value.type})</p>
                                    </div>
                                    <div className="clear" />
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <hr />

                      <div className="row-20">
                        <div className="container-left">
                          <label className="profile-label">Your Grade (0% - 100%)</label>
                          <div className="calc-column-offset-30">
                            <input type="number" className="number-field" min="0" max="100" placeholder="(e.g., 0" name={"submission|" + this.state.selectedIndex + "|grade"} value={this.state.submissions[this.state.selectedIndex].grade} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="fixed-column-30 light-background standard-border">
                            <div className="flex-container flex-center height-38">
                              <p className="heading-text-3 center-text">%</p>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />

                        {(this.state.submissions[this.state.selectedIndex].grade) && (
                          <div className="row-10">
                            <label className="profile-label">Your Grade Explanation (Optional)</label>
                            <textarea type="text" className="text-field" placeholder="(e.g., reason for your grade" name={"submission|" + this.state.selectedIndex + "|gradeExplanation"} value={this.state.submissions[this.state.selectedIndex].gradeExplanation} onChange={this.formChangeHandler}></textarea>
                          </div>
                        )}
                      </div>

                      <div className="spacer" />

                      <hr />

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <button className="btn btn-primary" onClick={() => this.saveChanges('grade')}>Save Changes</button>
                      <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                    </div>
                  )}
                </div>
              )}

              {(this.state.showSyllabus) && (
                <div key="showSyllabus" className="full-width padding-20">
                  <div>
                    <div className="spacer" />
                    <p className="heading-text-3">Syllabus for {this.state.selectedCourse.name}</p>

                    <div className="spacer" /><div className="spacer" />

                    <div className="row-30">
                      <textarea type="text" className="text-field" placeholder="(e.g., syllabus description)" name={"syllabus|0|body"} value={(this.state.syllabus && this.state.syllabus.length > 0) ? this.state.syllabus[0].body : ""} onChange={this.formChangeHandler}></textarea>
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <button className="btn btn-primary" onClick={() => this.saveChanges('syllabus')}>Save Changes</button>
                    <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                </div>
              )}
              {(this.state.showAnnouncement) && (
                <div key="showAnnouncement" className="full-width padding-20">
                  <div>
                    <div className="spacer" />
                    <p className="heading-text-3">Announcement for {this.state.selectedCourse.name}</p>

                    <div className="row-30">
                      <div className="row-10">
                        <label className="profile-label">Title</label>
                        <input type="text" className="text-field" placeholder="(e.g., announcement title)" name={"announcement|" + this.state.selectedIndex + "|title"} value={(this.state.announcements[this.state.selectedIndex]) ? this.state.announcements[this.state.selectedIndex].title : ""} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="row-10">
                        <label className="profile-label">Body</label>
                        <textarea type="text" className="text-field" placeholder="(e.g., announcement details)" name={"announcement|" + this.state.selectedIndex + "|body"} value={(this.state.announcements[this.state.selectedIndex]) ? this.state.announcements[this.state.selectedIndex].body : ""} onChange={this.formChangeHandler}></textarea>
                      </div>
                    </div>

                    <div className="spacer" />

                    <button className="btn btn-primary" onClick={() => this.saveChanges('announcement')}>Save Changes</button>
                    <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                </div>
              )}
              {(this.state.showAddMembers) && (
                <div key="showAddMembers" className="full-width padding-20">
                  <div>
                    <div className="spacer" />
                    <div>
                      <div className="calc-column-offset-30">
                        <p className="heading-text-3">Members in {this.state.selectedCourse.name}</p>
                      </div>
                      <div className="fixed-column-30">
                        <button className="background-button full-width" onClick={() => this.closeModal()}>
                          <img src={deniedIcon} alt="GC" className="image-auto-20 pin-right" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                    <div className="row-20">
                      <div className="row-10">
                        <label className="profile-label">Search Members</label>

                        <div>
                          <div>
                           <div className="calc-column-offset-90">
                             <input type="text" className="text-field" placeholder="Search a name, email, or id..." name="searchMembers" value={this.state.searchString} onChange={this.formChangeHandler}></input>
                           </div>
                           <div className="fixed-column-90 left-padding">
                             <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready}  onClick={() => this.addItem('member')}>Add</button>
                           </div>
                           <div className="clear" />
                          </div>
                          {/*
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}*/}

                          {(this.state.searchIsAnimating) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <div className="super-spacer" />

                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Searching...</p>

                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                {(this.state.memberOptions) && (
                                  <div className="card top-margin">
                                    {this.state.memberOptions.map((value, optionIndex) =>
                                      <div key={value._id} className="left-text bottom-margin-5 full-width">
                                        <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value,'member')}>
                                          <div className="full-width">
                                            <div className="fixed-column-40">
                                             <div className="half-spacer" />
                                              <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-25" />
                                            </div>
                                            <div className="calc-column-offset-40">
                                              <p className="cta-color">{value.firstName} {value.lastName} [{value.roleName}]</p>
                                              <p className="description-text-3">({value.email})</p>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                            </div>
                          )}
                        </div>

                        <div className="top-margin">
                          {this.renderTags('member')}
                          <div className="clear" />
                        </div>

                      </div>
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }
                  </div>
                </div>
              )}
              {(this.state.showMemberDetails) && (
                <div key="showMemberDetails" className="full-width padding-20">
                  <div>
                    <div className="spacer" />
                    <div>
                      <div className="calc-column-offset-30">
                        <button className="background-button full-width row-5 left-text" onClick={() => this.props.navigate(this.state.prefix + '/members/' + this.state.selectedMember.email)}>
                          <div className="full-width">
                            <div className="fixed-column-70">
                             <div className="half-spacer" />
                              <img src={(this.state.selectedMember.pictureURL) ? this.state.selectedMember.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-2" />
                            </div>
                            <div className="calc-column-offset-70">
                              <p className="cta-color heading-text-3">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName}</p>
                              <p className="description-text-2 top-padding-5">[{this.state.selectedMember.roleName}] ({this.state.selectedMember.email})</p>
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="fixed-column-30">
                        <button className="background-button full-width" onClick={() => this.closeModal()} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Close this modal view"}>
                          <img src={closeIcon} alt="GC" className="image-auto-18 pin-right" />
                        </button>

                        <button className="background-button full-width top-margin-15" onClick={() => this.exportInfo(this.state.selectedMember)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Export information to CSV file"}>
                          <img src={downloadIconBlue} alt="GC" className="image-auto-18 pin-right" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }

                    <div className="row-25">
                      <div className="carousel-3" onScroll={this.handleScroll}>
                        {this.state.memberDetailCategories.map((value, index) =>
                          <div className="display-inline">
                            {(value === this.state.memberDetailCategorySelected) ? (
                              <div className="selected-carousel-item">
                                <label key={value}>{value}</label>
                              </div>
                            ) : (
                              <button className="menu-button" onClick={() => this.setState({ memberDetailCategorySelected: value })}>
                                <label key={value}>{value}</label>
                              </button>
                            )}
                          </div>
                        )}
                        <hr />
                      </div>

                      {(this.state.memberDetailCategorySelected === 'Progress') && (
                        <div>
                          {(this.state.courseMembers) && (
                            <div>
                              {this.state.courseMembers.map((value, index) =>
                                <div key={"enrollments|" + value.email}>
                                  {(value.email === this.state.selectedMember.email) && (
                                    <div className="top-margin-20">
                                      {(value.progress && value.progress.length > 0) ? (
                                        <div>
                                          {value.progress.map((value2, index2) =>
                                            <div key={"progress|" + value2.moduleId} className="standard-border top-margin-20 padding-20">
                                              <p className="row-5"><label className="bold-text">{index2 + 1}. {value2.name}</label>: {(value2.isCompleted) ? <label className="cta-color bold-text">Completed</label> : <label className="error-color bold-text">In Progress</label>}</p>

                                              {(value2.lessons && value2.lessons.length > 0) && (
                                                <div>
                                                  {value2.lessons.map((value3, index3) =>
                                                    <div key={"progress|lesson|" + value3.lessonId}>
                                                      <p className="left-padding-20 description-text-2 row-5"><label className="bold-text">{index2 + 1}.{index3 + 1}. {value3.name}</label>: {(value3.isCompleted) ? <label className="cta-color bold-text">Completed</label> : <label className="error-color bold-text">In Progress</label>}</p>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          <p className="error-color">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName} has not made progress on this course.</p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.memberDetailCategorySelected === 'A.I. Conversations') && (
                        <div>
                          <div className="dark-border top-margin-20 full-width calc-height-half-view padding-20 overflow-y-auto">
                            {(this.state.messages && this.state.messages.length > 0) ? (
                              <div>
                                {this.state.messages.map((value, index) =>
                                  <div key={"messages|" + index}>
                                    {(value.senderEmail === this.state.selectedMember.email) ? (
                                      <div className="left-padding full-width top-padding">
                                        <div className="fixed-column-50">
                                          <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                        </div>

                                        <div className="calc-column-offset-90">
                                          <Link to={(value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className="background-button full-width standard-color">
                                            <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
                                          </Link>

                                          <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>

                                        </div>
                                        <div className="fixed-column-40">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <Link to={'/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
                                            <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
                                          </Link>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    ) : (
                                      <div className="left-padding full-width top-padding">
                                        <div className="fixed-column-40">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                            <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                          </Link>
                                        </div>
                                        <div className="calc-column-offset-40">
                                          <div>
                                            <div className="calc-column-offset-50">
                                              <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                                <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
                                              </Link>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>

                                          <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>

                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="flex-container flex-center">
                                <div className="wash-out-slight center-text padding-ten-percent">
                                  <img src={chatbubblesIcon} alt="GC" className="image-50-fit center-horizontally" />
                                  <p className="heading-text-6 top-margin-20">No conversations yet</p>
                                  <p className="description-text-1 top-margin-20">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName} has not progressed through an A.I. chatbot script associated with this course yet.</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.memberDetailCategorySelected === 'Submissions') && (
                        <div>
                          {(this.state.submissions && this.state.submissions.length > 0) ? (
                            <div>
                              {this.renderItems('submission',this.state.submissions, this.state.selectedMember.email, true)}
                            </div>
                          ) : (
                            <div className="flex-container flex-center">
                              <div className="wash-out-slight center-text padding-ten-percent">
                                <img src={assignmentsIconDark} alt="GC" className="image-50-fit center-horizontally" />
                                <p className="heading-text-6 top-margin-20">No submissions yet</p>
                                <p className="description-text-1 top-margin-20">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName} has not submitted to assignments, quizzes, surveys or other associated learning assignments for this course yet.</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.memberDetailCategorySelected === 'Grades') && (
                        <div>
                          {(this.state.grades && this.state.grades.length > 0) ? (
                            <div>
                              {this.renderItems('grade',this.state.grades, this.state.selectedMember.email, true)}
                            </div>
                          ) : (
                            <div className="flex-container flex-center">
                              <div className="wash-out-slight center-text padding-ten-percent">
                                <img src={gradesIconDark} alt="GC" className="image-50-fit center-horizontally" />
                                <p className="heading-text-6 top-margin-20">No grades yet</p>
                                <p className="description-text-1 top-margin-20">You have not given grades to {this.state.selectedMember.firstName} {this.state.selectedMember.lastName} for any submissions for this course yet.</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p> }
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p> }
                  </div>
                </div>
              )}

              {(this.state.showEditMins) && (
                <div key="showSyllabus" className="full-width padding-20">
                  <div>
                    <div className="row-10">
                      <div className="calc-column-offset-20 right-padding">
                        <p className="heading-text-2">Edit the Estimated Duration for {(this.state.selectedCourse.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].name) ? this.state.selectedCourse.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].name : ""}</p>
                      </div>
                      <div className="fixed-column-20 top-margin-15">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" />

                    <div className="row-30">
                      <div className="container-left">
                        <label className="profile-label">Minutes (Estimated)</label>
                        <input type="number" className="number-field" placeholder="(e.g., 30)" name="lessonMinutes" value={this.state.lessonMinutes} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <button className="btn btn-primary" onClick={() => this.saveChanges('lessonMinutes')}>Save Changes</button>
                    <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                  </div>
                </div>
              )}

              {(this.state.showConfirmDelete) && (
                <div key="showConfirmDelete" className="full-width padding-20">
                  <div>
                    <div className="row-10">
                      <div className="calc-column-offset-20 right-padding">
                        {(this.state.selectedIndex2 || this.state.selectedIndex2 === 0) ? (
                          <p className="heading-text-2">Confirm Deletion of {(this.state.selectedCourse.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2]) ? this.state.selectedCourse.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].name : ""}</p>
                        ) : (
                          <p className="heading-text-2">Confirm Deletion of {(this.state.selectedCourse.learningModules[this.state.selectedIndex]) ? this.state.selectedCourse.learningModules[this.state.selectedIndex].name : ""}</p>
                        )}
                      </div>
                      <div className="fixed-column-20 top-margin-15">
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" />

                    <div className="row-20">
                      <p className="row-10">Are you sure you want to delete?</p>
                      <button className="btn btn-squarish error-background-color error-border" onClick={() => this.deleteItem('learningModule')}>Save Changes</button>
                      <button className="btn btn-squarish-white left-margin" onClick={() => this.closeModal()}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
             </Modal>
            </div>
          </div>
      )
    }
}


export default withRouter(EditCourse);
