import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCustomDropdown from '../Common/CustomDropdown';
import SubSendMessage from '../../components/Common/SendMessage';
import SubRenderProfiles from '../../components/Common/RenderProfiles';
import withRouter from '../Functions/WithRouter';

const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const socialIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png";

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class PeopleMatching extends Component {
    constructor(props) {
        super(props)

        this.state = {
          members: [],
          favorites: [],
          newFavorites: [],
          friends: [],

          genderOptions: [],
          raceOptions: [],
          ageRangeOptions: [],
          proximityOptions: [],

          disableCustomMatches: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.orgCode !== prevProps.orgCode || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.userType !== prevProps.userType) {
        this.retrieveData()
      } else if (this.props.selectedGoal !== prevProps.selectedGoal) {
        this.retrieveData()
      } else if (this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const userType = this.props.userType

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      let accountCode = localStorage.getItem('accountCode');
      // console.log('show orgName --: ', orgName, roleName, accountCode)
      let roleName = localStorage.getItem('roleName');
      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else {
        accountCode = null
      }

      let similarityCriteria = [
        { name: 'Overall', description: 'Returns people who are overall most similar to your profile', selected: true, disabled: this.state.disableCustomMatches },
        { name: 'Career Goals', description: 'Returns people who share your career goals', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Interests', description: 'Returns people who share your interests', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Personality', description: 'Returns people who share your personality', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Values', description: 'Returns people who share your values', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Skills', description: 'Returns people who share your skills', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Functional Area', description: 'Returns people who share your interest for functional areas', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Industry', description: 'Returns people who share your interest for industries', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Cohort', description: 'Returns people who are similar in age and who are close by', selected: false, disabled: this.state.disableCustomMatches },
        { name: 'Teammates', description: 'Similar age, goals, values, and industries; Different interests, functional areas, personality, and skills.', selected: false, disabled: this.state.disableCustomMatches },
      ]

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, similarityCriteria, userType })

      // let roleNames = ['Student','Career-Seeker']
      let roleNames = ['Student','Career-Seeker','Mentor']
      if (userType === 'Mentors') {
        roleNames = ['Mentor']
      } else if (!userType) {
        // roleNames = ['Student','Career-Seeker']
        roleNames = ['Student','Career-Seeker','Mentor']
      }

      let orgCode = activeOrg
      if (this.props.orgCode) {
        orgCode = this.props.orgCode
      }
      const onlyPics = false
      const prioritizePics = true
      const limit = 200

      // console.log('org: ', orgCode)

      if (this.props.pageSource !== 'Goal') {
        if (orgCode || this.props.noOrgCode) {
          let queryParams = { orgCode, roleNames, emailId, onlyPics, prioritizePics, limit }
          if (this.props.pageSource === 'landingPage') {
            queryParams['publicExtentArray'] = ['Public']
          } else if (employerAccount) {
            queryParams = { accountCode, emailId, limit }
          }

          Axios.get('/api/org/members', { params: queryParams })
          .then((response) => {
            console.log('Members query attempted', response.data);

              if (response.data.success) {
                console.log('members query worked')

                let members = response.data.members
                this.setState({ members })

              } else {
                console.log('members query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Members query did not work for some reason', error);
          });
        }
      }

      if (activeOrg) {
        Axios.get('/api/friends', { params: { orgCode: activeOrg, emailId } })
        .then((response) => {
          console.log('Friends query attempted', response.data);

            if (response.data.success) {
              console.log('friends query worked')

              const friends = response.data.friends
              this.setState({ friends })

            } else {
              console.log('friends query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Friends query did not work for some reason', error);
        })
      }

      if (emailId) {
        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
             profile['zipcode'] = response.data.user.zipcode

             const pictureURL = response.data.user.pictureURL
             const headline = response.data.user.headline

             this.setState({ profile, pictureURL, headline });

             Axios.get('/api/assessment/results', { params: { emailId } })
              .then((response2) => {
                console.log('query for assessment results worked');

                if (response2.data.success) {

                  console.log('actual assessment results', response2.data)

                  // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
                  profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                  profile['interests'] = response2.data.results.interestScores
                  profile['personality'] = response2.data.results.personalityScores
                  profile['skills'] = response2.data.results.newSkillAnswers
                  profile['gravitateValues'] = response2.data.results.topGravitateValues
                  profile['employerValues'] = response2.data.results.topEmployerValues
                  profile['selectedGoal'] = this.props.selectedGoal

                  this.setState({ profile })

                  console.log('show selectedGoal: ', this.props.selectedGoal)
                  if (this.props.pageSource === 'Goal' && this.props.selectedGoal) {
                    this.calculateMatches(true, true, false, [this.props.pageSource], 500)
                  }

                } else {
                  console.log('no assessment results', response2.data)
                }

            }).catch((error) => {
              console.log('query for assessment results did not work', error);
            })

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });

         Axios.get('/api/favorites', { params: { emailId } })
        .then((response) => {
          console.log('Favorites query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved favorites')

            const favorites = response.data.favorites

            let newFavorites = []
            if (response.data.newFavorites) {
              newFavorites = response.data.newFavorites
            }

            this.setState({ favorites, newFavorites })

          } else {
            console.log('no favorites data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Favorites query did not work', error);
        });
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let genderOptions = []
          for (let i = 1; i <= response.data.workOptions[0].genderOptions.length; i++) {
            if (response.data.workOptions[0].genderOptions[i - 1] !== 'Do not disclose') {
              genderOptions.push(response.data.workOptions[0].genderOptions[i - 1])
            }
          }
          genderOptions.unshift('Any Gender')

          let raceOptions = []
          for (let i = 1; i <= response.data.workOptions[0].raceOptions.length; i++) {
            if (response.data.workOptions[0].raceOptions[i - 1] !== 'Do not disclose') {
              raceOptions.push(response.data.workOptions[0].raceOptions[i - 1])
            }
          }
          raceOptions.unshift('Any Race')

          let ageRangeOptions = response.data.workOptions[0].ageRangeOptions
          ageRangeOptions.unshift('Any Age')
          let proximityOptions = response.data.workOptions[0].proximityOptions
          proximityOptions.unshift('Any Distance')

          this.setState({ genderOptions, raceOptions, ageRangeOptions, proximityOptions })

        }
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, null, null, true)

      } else if (event.target.name.includes('filter|')) {

        const nameArray = event.target.name.split("|")
        this.setState({ [nameArray[1]]: event.target.value, animating: true })
        this.filterResults(this.state.searchString, nameArray[1], event.target.value, false)

      } else if (event.target.name.includes('sort|')) {

        if (this.state.showFilters) {

        } else {
          let sorters = this.state.itemSorters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          for (let i = 1; i <= sorters.length; i++) {
            if (sorters[i - 1].name === field) {
              sorters[i - 1]['value'] = event.target.value
            }
          }

          let itemSorters = sorters

          //reset everything
          let searchString = ''
          let itemFilters = this.state.itemFilters
          for (let i = 1; i <= itemFilters.length; i++) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= itemSorters.length; i++) {
            if (itemSorters[i - 1].name !== field) {
              itemSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, itemFilters, itemSorters, animating: true })

          this.sortResults(event.target.value, field)
        }

      }
    }

    filterResults(searchString, filterName, filterValue, search) {
      console.log('filterResults called', searchString, filterName, filterValue, search)

      // let roleNames = ['Student','Career-Seeker']
      let roleNames = ['Student','Career-Seeker','Mentor']
      if (this.state.userType === 'Mentors') {
        roleNames = ['Mentor']
      } else if (!this.state.userType) {
        // roleNames = ['Student','Career-Seeker']
        roleNames = ['Student','Career-Seeker','Mentor']
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        let orgCode = self.state.activeOrg
        if (self.props.orgCode) {
          orgCode = self.props.orgCode
        }
        const excludeCurrentUser = true
        const emailId = self.state.emailId

        if (search) {
          Axios.get('/api/members/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId } })
          .then((response) => {
            console.log('Member search query attempted', response.data);

              if (response.data.success) {
                console.log('member search query worked')

                let members = response.data.members
                self.setState({ members, filteredResults: true, animating: false })

              } else {
                console.log('member search query did not work', response.data.message)

                let members = []
                self.setState({ members, animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });
        } else {
          console.log('filter results: ', searchString, orgCode, filterName, filterValue)

          const onlyPics = false
          const profile = self.state.profile
          let users = self.state.members
          // if (self.state.members && self.state.members[0] && self.state.members[0].matchScore) {
          //   users = self.state.members
          // }

          // eventually query members from back-end
          Axios.put('/api/members/filter', { searchString, orgCode, filterName, filterValue, roleNames, onlyPics, profile, users })
          .then((response) => {
            console.log('Opportunity search query attempted', response.data);

              if (response.data.success) {
                console.log('opportunity search query worked')

                let members = response.data.members
                const filterCriteriaArray = response.data.filterCriteriaArray
                self.setState({ members, filterCriteriaArray, filteredResults: true, animating: false })

              } else {
                console.log('opportunity search query did not work', response.data.message)

                self.setState({ animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });

        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 500)
      }

      delayFilter();
    }

    calculateMatches(matchingView, calcMatches, newPreferences, specificCriteria, resLimit) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences, specificCriteria, resLimit)

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (!this.state.profile) {
          this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
        } else {
          this.setState({ animating: true, modalIsOpen: false })

          const profile = this.state.profile
          const matchingCriteria = this.state.matchingCriteria
          const similarityCriteria = this.state.similarityCriteria
          // let roleNames = ['Student','Career-Seeker']
          let roleNames = ['Student','Career-Seeker','Mentor']
          if (this.state.userType === 'Mentors') {
            roleNames = ['Mentor']
          }
          const orgCode = this.state.activeOrg
          const onlyPics = false

          // console.log('show our profile: ', this.state.profile)

          const self = this

          function officiallyCalculate() {
            console.log('officiallyCalculate called')

            // query postings on back-end
            Axios.put('/api/members/matches', { profile, matchingCriteria, similarityCriteria, roleNames, orgCode, onlyPics, specificCriteria, resLimit })
            .then((response) => {
              console.log('Member matches attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity match query worked')

                  let members = response.data.members
                  // let matchScores = response.data.matchScores
                  self.setState({ animating: false, matchingView: true, members })

                } else {
                  console.log('Career match did not work', response.data.message)
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                }

            }).catch((error) => {
                console.log('Career match did not work for some reason', error);
                self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
            });
          }

          if (newPreferences) {
            console.log('new preferences stated')

            if (this.state.disableCustomMatches) {
              this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'Ability to customize your matches is coming soon!' })
            } else {
              const emailId = this.state.emailId

              let savePrefences = false
              if (!savePrefences) {
                officiallyCalculate()
              } else {

                const matchingPreferences = matchingCriteria
                const similarityCriteria = this.state.similarityCriteria
                const updatedAt = new Date()

                Axios.post('/api/users/profile/details', {
                  emailId, matchingPreferences, similarityCriteria, updatedAt })
                .then((response) => {

                  if (response.data.success) {
                    console.log('successfully saved new preferences')
                    officiallyCalculate()

                  } else {
                    console.log('request was not successful')
                    officiallyCalculate()
                  }
                }).catch((error) => {
                    console.log('Saving the info did not work', error);
                    officiallyCalculate()
                });
              }
            }

          } else {
            officiallyCalculate()
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showMessageWidget: false, showMatchingCriteria: false })
    }

    itemClicked(name, value) {
      console.log('itemClicked called', name, value)

      const nameArray = name.split("|")
      const index = Number(nameArray[1].trim())

      let similarityCriteria = this.state.similarityCriteria
      // similarityCriteria[index]["value"] = event.target.value
      for (let i = 1; i <= similarityCriteria.length; i++) {
        console.log('compare indices: ', i - 1, index)
        if (i - 1 === index) {
          similarityCriteria[i - 1]["selected"] = true
        } else {
          similarityCriteria[i - 1]["selected"] = false
        }
      }

      this.setState({ similarityCriteria })
    }

    render() {

      return (
        <div className="top-margin-20">

          {(this.props.pageSource !== 'Goal') && (
            <div>
              {(this.state.matchingView) ? (
                <div>
                  <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                    <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                      <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                    </button>
                  </div>
                  <div className="full-width">
                    <button type="button" className="background-button float-left right-margin" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                      <div className="float-left slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                        <p>Adjust</p>
                      </div>
                    </button>
                    <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                      <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                        <p>Close</p>
                      </div>
                    </button>

                    <div className="clear" />

                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="full-width row-5 white-background standard-border medium-shadow">
                    {(this.props.pageSource === 'landingPage') ? (
                      <div className="float-left" />
                    ) : (
                      <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                        <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} onClick={() => this.calculateMatches(true, true, false)}>
                          <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches"/>
                        </button>
                      </div>
                    )}

                    <div className={(this.props.pageSource === 'landingPage') ? "filter-field-search clear-margin calc-column-offset-50 white-background clear-border" : "filter-field-search clear-margin calc-column-offset-100-static white-background clear-border"}>
                      <div className="search-icon-container">
                        <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                      </div>
                      <div className="filter-search-container calc-column-offset-100-static">
                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <input type="text" className="text-field clear-border" placeholder={(this.state.userType === 'Peers') ? "Search community members..." : "Search..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                      </div>
                    </div>
                    <div className="fixed-column-50 center-item">
                      <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                        <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                      </button>
                    </div>
                    <div className="clear" />
                    <ReactTooltip id="tooltip-placeholder-id" />
                  </div>
                </div>
              )}

              {(this.state.showingSearchBar) && (
                <div className={"box-container-1 white-background"}>
                  <div>
                    <div className="row-10">
                      <label className="profile-label">Filter Options</label>

                      <SubCustomDropdown dropdownTitle="Age Range" name="filter|ageRange" value={this.state.ageRange} options={this.state.ageRangeOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                      <SubCustomDropdown dropdownTitle="Maximum Distance" name="filter|proximity" value={this.state.proximity} options={this.state.proximityOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                      <div className="clear" />
                    </div>
                  </div>
                </div>
              )}

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color">{this.state.errorMessage}</p>}
              <div className="spacer" /><div className="spacer" />
            </div>
          )}

          {(this.state.animating) ? (
            <div className="flex-container flex-center full-space">
              <div>
                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <p className="center-text cta-color bold-text">Pulling results...</p>

              </div>
            </div>
          ) : (
            <div className="top-padding-20">

              {(this.props.pageSource !== 'Goal') && (
                <div>
                  <div className="spacer" /><div className="spacer" />
                </div>
              )}

              {(this.state.members && this.state.members.length > 0) ? (
                <div>
                  <SubRenderProfiles
                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                    members={this.state.members} friends={this.state.friends}
                    pageSource={this.props.pageSource} history={this.props.navigate} userType={this.state.userType}
                    filterCriteriaArray={this.state.filterCriteriaArray}
                  />
                </div>
              ) : (
                <div>
                  <div className="card top">
                    <div className="full-space row-20">
                      <img src={socialIconDark} alt="GC" className="image-auto-100 center-horizontally" />

                      <div className="row-20">
                        <p className="center-text heading-text-2">{(this.state.userType === 'Mentors') ? "Mentors" : "Community Members"}</p>
                        {(this.state.userType === 'Mentors') ? (
                          <div>
                            <p className="center-text top-padding-20 horizontal-padding">This page allows you to browse, search, filter, and match to mentors based on your profile information.</p>
                          </div>
                        ) : (
                          <div>
                            <p className="center-text top-padding-20 horizontal-padding">This page allows you to browse, search, filter, and match to community members based on your profile information. This is a great way to form teams for projects and challenges</p>
                          </div>
                        )}
                      </div>
                    </div>

                    {(this.state.inBeta) && (
                      <div className="bottom-padding">
                        <p className="error-color full-width center-text">This feature is coming soon. It is currently being developed.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

            </div>
          )}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
         >

            {(this.state.showMatchingCriteria) && (
              <div key="showMatchingCriteria" className="full-width padding-20">
                {(this.state.showMatchingCriteria) && (
                  <div key="showMatchingCriteria" className="full-width padding-20">
                    <p className="heading-text-2">Adjust Matching Criteria</p>
                    <div className="spacer" />

                    <div className="row-10">
                      <div className="flex-container">
                        <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                          <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                            Adjust by Similarity
                          </div>
                        </button>
                        <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                          <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                            {(this.state.userType === 'Peers') ? "Design Your Teammate" : "Design Your Mentor"}
                          </div>
                        </button>

                      </div>
                      <div className="spacer" />
                    </div>

                    {(this.state.customAdjustment) ? (
                      <div>
                        {(this.state.disableCustomMatches) && (
                          <div className="bottom-padding">
                            <p className="error-color">Ability to customize your matches is coming soon</p>
                          </div>
                        )}

                        {(this.state.matchingCriteria) && (
                          <div>
                            {this.state.matchingCriteria.map((value ,index) =>
                              <div key={"c" + index} className={(value.name === 'Location') && "wash-out-2"}>
                                <div className="calc-column-offset-100-static">
                                  <p className="half-bold-text">{index + 1}. {value.name}</p>
                                  <div className="half-spacer" />
                                  <p className="description-text-3">{value.description}</p>
                                </div>
                                <div className="fixed-column-100 right-text">
                                  <div className="fixed-column-70">
                                    <input disabled={(value.name === 'Location') ? true : false} type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="fixed-column-30">
                                    <div className="mini-spacer"/><div className="mini-spacer"/>
                                    <p className="heading-text-2 cta-color bold-text">%</p>
                                  </div>
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="half-spacer" />

                              </div>
                            )}

                            <div>
                              <hr className="cta-border-color"/>
                              <div className="spacer" />
                              <div className="float-left full-width right-text">
                                <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                              </div>
                              <div className="clear" />
                              {(this.state.totalPercent !== 100) && (
                                <div className="full-width">
                                  <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {(this.state.similarityCriteria) && (
                          <div>
                            {(this.state.disableCustomMatches) && (
                              <div className="bottom-padding">
                                <p className="error-color">Ability to customize your matches is coming soon</p>
                              </div>
                            )}

                            {this.state.similarityCriteria.map((value ,index) =>
                              <div key={"u" + index} className={(value.disabled) && "wash-out-2"}>
                                <div className="calc-column-offset-50">
                                  <p className="half-bold-text">{index + 1}. {value.name}</p>
                                  <div className="half-spacer" />
                                  <p className="description-text-3">{value.description}</p>
                                </div>
                                <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                                  <button disabled={value.disabled} onClick={() => this.itemClicked('similarityCriteria|' + index, true)} type="button" className="background-button">
                                    {(value.selected) ? (
                                      <div className="circle-option-container-2 cta-border center-text" >
                                        <div className="circle-selected-2"/>
                                      </div>
                                    ) : (
                                      <div className="circle-option-container-2 standard-border center-text" />
                                    )}
                                  </button>
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="half-spacer" />

                              </div>
                            )}

                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

                  </div>
                )}

                <div className="row-20 center-text">
                  <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              </div>
            )}

           {(this.state.showMessageWidget) && (
             <div key="showServiceDefinitions" className="full-width padding-20">
                <SubSendMessage
                  profileData={{ firstName: this.state.members[this.state.selectedIndex].firstName, lastName: this.state.members[this.state.selectedIndex].lastName, email: this.state.members[this.state.selectedIndex].email}}
                  history={this.props.navigate} closeModal={this.closeModal} source={"peopleMatching"}
                  />
              </div>
           )}

         </Modal>


        </div>
      )
    }
}

export default withRouter(PeopleMatching);
