import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import NotificationPreferences from '../components/Subcomponents/NotificationPreferences';
import withRouter from '../components/Functions/WithRouter';

class NotificationPreferencesPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      //componentDidMount
      const { orgCode, emailId, notificationType, slug } = this.props.params
      console.log('componentDidMount notificationPreferences: ', orgCode, emailId, notificationType)

      this.setState({ orgCode, emailId, notificationType, slug })
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"notificationPreferencesPage"} darkBackground={true} />
            <div className="contact-container">
              <NotificationPreferences orgCode={this.state.orgCode} emailId={this.state.emailId} notificationType={this.state.notificationType} slug={this.state.slug} />
            </div>

            <Footer history={this.props.navigate} />
          </div>
      )
    }
}

export default withRouter(NotificationPreferencesPage);
