import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCareerDetails from './Subcomponents/CareerDetails';
import withRouter from './Functions/WithRouter';

class AdvCareerDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { careerSelected } = this.props.params
      console.log('careerSelected:', careerSelected);

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');
      let remoteAuth = localStorage.getItem('remoteAuth');
      let authType = localStorage.getItem('authType');

      this.setState({ activeOrg, emailId: email, username, careerSelected, orgFocus,
        cuFirstName, cuLastName, roleName, orgLogo,
        remoteAuth, authType
      })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.location && this.props.location.state && this.props.location.state.careerSelected !== prevProps.location.state.careerSelected) {
        console.log('new career selected in parent')
        this.setState({ careerSelected: this.props.location.state.careerSelected.name })
      } else if (this.props.params && this.props.params.careerSelected !== prevProps.params.careerSelected) {
        this.setState({ careerSelected: this.props.params.careerSelected })
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {
        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />

              <div>
                <SubCareerDetails careerSelected={this.state.careerSelected} history={this.props.navigate} commandClicked={this.commandClicked} />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(AdvCareerDetails);
