import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubEditGroup from '../../components/Common/EditGroup';
import withRouter from '../Functions/WithRouter';

const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
const tagIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon-dark.png';
const tagIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon-blue.png';
const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const favoriteIconUnselected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoriteIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const messageIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/message-icon-white.png';
const opportunitiesIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-white.png';
const socialIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-white.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const industryIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon.png';
const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const benchmarksIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const socialIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-blue.png';
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class PipelineDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          subNavCategories: [],

          favoriteIds: [],
          laterStages: [],
          individualFilters: [],
          appsToDelete: [],
          checkCount: 0,
          selectedEntities: [],
          selectedEntityDetails: [],
          selectedOpportunities: [],
          selectedOpportunityDetails: [],
          selectedCommunities: [],
          selectedCommunityDetails: [],
          selectedBenchmarks: [],
          selectedBenchmarkDetails: [],

          communityOptions: [],
          benchmarkOptions: []
        }

        this.retrieveData = this.retrieveData.bind(this)

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)

        this.calculateMatches = this.calculateMatches.bind(this)
        this.renderApplications = this.renderApplications.bind(this)
        this.checkApplication = this.checkApplication.bind(this)
        this.checkAllApplications = this.checkAllApplications.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.addItem = this.addItem.bind(this)
        this.configureMessageWidget = this.configureMessageWidget.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in pipelineDetails')

      const accountCode = this.props.accountCode

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let org = localStorage.getItem('activeOrg');

      let subNavCategories = [
        { name: 'All', count: 1000},
        { name: 'Favorited', count: 80},
        { name: 'Attended Event', count: 0},
        { name: 'Submitted Project', count: 0},
        { name: 'Applied', count: 0},
        { name: 'Interviewed', count: 0},
        { name: 'Hired', count: 0},
      ]
      const subNavSelected = subNavCategories[0]
      const isLoading = true

      this.setState({ orgCode: org, accountCode, emailId: email, email, username, cuFirstName, cuLastName, subNavCategories, subNavSelected, isLoading })

      if (this.props.pipelineId) {
        Axios.get('/api/pipelines/byid', { params: { _id: this.props.pipelineId } })
        .then((response) => {
          console.log('Pipline by id query attempted', response.data);

          if (response.data.success) {
            console.log('pipeline query worked')

            const selectedPipeline = response.data.pipeline
            this.setState({ selectedPipeline })

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedPipeline.benchmark._id } })
            .then((response) => {
              console.log('Benchmark by id', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                const selectedBenchmark = response.data.benchmark
                this.setState({ selectedBenchmark})

                Axios.get('/api/benchmarks', { params: { accountCode, pathwayLevel: true } })
                .then((response) => {
                  console.log('Benchmarks query within employerDetails attempted', response.data);

                  if (response.data.success) {
                    console.log('benchmark query worked')

                    if (response.data.benchmarks.length !== 0) {
                      //jobs = response.data.postings
                      console.log('set the benchmark to state')

                      let benchmarks = []
                      let benchmarkOptions = [{ title: ''}]
                      let selectedBenchmarks = [selectedBenchmark.title]
                      let selectedBenchmarkDetails = [selectedBenchmark]
                      if (response.data.benchmarks && response.data.benchmarks.length > 0) {
                        benchmarkOptions = [{ title: ''}].concat(response.data.benchmarks)
                        for (let i = 1; i <= response.data.benchmarks.length; i++) {
                          if (response.data.benchmarks[i - 1].title !== selectedBenchmark.title) {
                            benchmarks.push(response.data.benchmarks[i - 1])
                          }
                        }
                      }

                      this.setState({ benchmarks, benchmarkOptions, selectedBenchmarks, selectedBenchmarkDetails });
                    }

                  }

                }).catch((error) => {
                    console.log('Benchmark query did not work for some reason', error);
                });

                Axios.get('/api/applications', { params: { allDistinct: true, resLimit: 500 } })
                .then((response) => {
                  console.log('Applications query attempted', response.data);

                  if (response.data.success) {
                    console.log('applications query worked')

                    if (response.data.applications.length !== 0) {

                      let applications = []
                      let applicationEmails = []
                      for (let i = 1; i <= response.data.applications.length; i++) {
                        if (!applicationEmails.includes(response.data.applications[i - 1].email)) {
                          applicationEmails.push(response.data.applications[i - 1].email)
                          applications.push(response.data.applications[i - 1])
                        }
                      }

                      this.setState({ applications })
                      this.calculateMatches(applications, { postType: 'Work' }, selectedBenchmark, null, org)

                    }

                  } else {
                    console.log('applications query did not work', response.data.message)
                    this.setState({ isLoading: false });
                  }
                }).catch((error) => {
                    console.log('Application query did not work for some reason', error);

                    this.setState({ isLoading: false });
                });

                Axios.get('/api/groups', { params: { pathway: selectedBenchmark.pathway, accountCode } })
                .then((response) => {
                   console.log('Group detail query attempted', response.data);

                   if (response.data.success && response.data.groups && response.data.groups.length > 0) {
                     console.log('successfully retrieved group')

                     const selectedGroup = response.data.groups[0]
                     this.setState({ selectedGroup })

                   }
                }).catch((error) => {
                    console.log('Group query did not work', error);
                });

              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
            });

            Axios.get('/api/account', { params: { accountCode } })
            .then((response) => {
              console.log('Account info query attempted within sub settings', response.data);

              if (response.data.success) {
                console.log('account info query worked in sub settings')

                const employerId = response.data.accountInfo._id
                const employerName = response.data.accountInfo.employerName
                const employerLogoURI = response.data.accountInfo.employerLogoURI
                const topPipelineCandidates = response.data.accountInfo.topPipelineCandidates

                this.setState({ employerId, employerName, employerLogoURI, topPipelineCandidates });

                Axios.get('/api/pipeline/funnel', { params: { accountCode, _id: employerId } })
                .then((response) => {
                  console.log('Account pipeline funnel query attempted', response.data);

                  if (response.data.success) {
                    console.log('account pipeline query worked in sub settings')

                    subNavCategories[0]['count'] = response.data.allCount
                    subNavCategories[1]['count'] = response.data.favoriteCount
                    subNavCategories[2]['count'] = response.data.rsvpCount
                    subNavCategories[3]['count'] = response.data.submissionCount
                    subNavCategories[4]['count'] = response.data.applicationCount
                    subNavCategories[5]['count'] = response.data.interviewedCount
                    subNavCategories[6]['count'] = response.data.hiredCount
                    this.setState({ subNavCategories })

                  }

                }).catch((error) => {
                  console.log('Account pipeline query did not work for some reason', error);
                });

              }

            }).catch((error) => {
              console.log('Account info query did not work for some reason', error);
            });

            Axios.get('/api/groups', { params: { accountCode }})
            .then((response) => {
              console.log('Groups query worked', response.data);

              if (response.data.success && response.data.groups && response.data.groups.length > 0) {

                const communityOptions = [{ name: '' }].concat(response.data.groups)
                this.setState({ communityOptions })

              } else {
                console.log('no groups data found', response.data.message)
              }

            }).catch((error) => {
                console.log('Groups query did not work', error);
            });

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });

        // EVENTUALLY PULL FAVORITES FROM ACCOUNT MODEL
        Axios.get('/api/favorites', { params: { emailId: email } })
        .then((response) => {
          console.log('Favorites query attempted', response.data);

            if (response.data.success && response.data.favorites && response.data.favorites.length > 0) {
              console.log('successfully retrieved favorites', response.data.favorites.length)

              const favoriteIds = response.data.favorites
              this.setState({ favoriteIds })

            } else {
              console.log('no favorites data found', response.data.message)

            }

        }).catch((error) => {
            console.log('Favorites query did not work', error);
        });
      }
    }

    calculateMatches(applications, selectedOpportunity, benchmark, benchmarkOptions, activeOrg, source) {
      console.log('calculateMatches: ', applications, benchmark, benchmarkOptions, activeOrg, source)

      let newApplications = []
      for (let i = 1; i <= applications.length; i++) {
        console.log(i, 'calculating matches');

        const index = i - 1
        let appEnd = applications.length - 1

        Axios.post('/api/applications/matches', {
          application: applications[i - 1], selectedJob: selectedOpportunity, benchmark, activeOrg, saveChanges: false })
        .then((response) => {
          console.log('application matches query attempted in individual')
          if (response.data.success) {
            //save values
            console.log('Application matches save worked in individual', index, appEnd);

            newApplications.push(response.data.application)

            if (index === appEnd) {
              // console.log('last one ', newApplications)
              newApplications.sort(function(a,b) {
                return b.match - a.match;
              })

              this.setState({ filteredApplications: newApplications, benchmark, isLoading: false });

              if (newApplications.length > 2) {
                // save top pipeline candidates
                const accountCode = this.state.accountCode
                // let topPipelineCandidates = newApplications.splice(0,3)
                let topPipelineCandidates = []
                for (let j = 1; j <= 3; j++) {
                  let candidate = newApplications[j - 1]
                  candidate['pathway'] = this.state.selectedPipeline.pathway
                  if (this.state.topPipelineCandidates && this.state.topPipelineCandidates.length > 0) {
                    topPipelineCandidates = this.state.topPipelineCandidates

                    if (candidate.match > this.state.topPipelineCandidates[j - 1].match) {
                      topPipelineCandidates[j - 1] = candidate
                    }
                  } else {
                    topPipelineCandidates.push(candidate)
                  }
                }

                Axios.post('/api/account/update', { accountCode, topPipelineCandidates })
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Account update worked', response.data);
                    this.setState({

                      serverPostSuccess: true,
                      serverSuccessMessage: 'Account information saved successfully!'
                    })

                  } else {
                    console.error('there was an error updating the account info');
                    this.setState({
                      serverPostSuccess: false,
                      serverErrorMessage: response.data.message,
                    })
                  }
                }).catch((error) => {
                    console.log('Account info save did not work', error);
                });
              }
            }

          } else {
            console.log('application matches did not work', response.data.message)

            if (index === appEnd) {
              this.setState({ isLoading: false })
            }
          }
        }).catch((error) => {
            console.log('Application matches did not work for some reason', error);
            if (index === appEnd) {
              this.setState({ isLoading: false })
            }
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchEntities' || event.target.name === 'searchOpportunities') {
        this.searchItems(event.target.value, event.target.name)
      } else {
        this.setState({ [event.target.name] : event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', type)

      if (type === 'searchEntities') {
        if (!searchString || searchString === '') {
          this.setState({ searchEntities: searchString, searchIsAnimating: false, entityOptions: null })
        } else {
          this.setState({ searchEntities: searchString, searchIsAnimating: true })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const emailId = self.state.emailId
            const excludeCurrentUser = true
            const orgCode = self.state.orgCode

            Axios.get('/api/entities/search', {  params: { searchString, search, emailId, excludeCurrentUser, orgCode }})
            .then((response) => {
              console.log('Entities query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved entities')

                  if (response.data) {

                    let entityOptions = []
                    if (response.data.entities && response.data.entities.length > 0) {
                      entityOptions = response.data.entities
                    }

                    self.setState({ entityOptions, searchIsAnimating: false })
                  }

                } else {
                  console.log('no entity data found', response.data.message)
                  self.setState({ searchIsAnimating: false })
                }

            }).catch((error) => {
                console.log('Entity query did not work', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'searchOpportunities') {
        if (!searchString || searchString === '') {
          this.setState({ searchOpportunities: searchString, searchIsAnimatingOpportunities: false, careerOptions: null })
        } else {
          this.setState({ searchOpportunities: searchString, searchIsAnimatingOpportunities: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            // const orgCode = self.state.orgCode
            // const placementPartners = self.state.placementPartners
            const accountCode = self.state.accountCode
            const search = true
            const postTypes = ['Internship','Work','Assignment','Problem','Challenge','Event']

            Axios.get('/api/postings/search', { params: { searchString, accountCode, search, postTypes } })
            .then((response) => {
              console.log('Opportunity search query attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity search query worked')

                  let opportunityOptions = response.data.postings
                  self.setState({ opportunityOptions, searchIsAnimatingOpportunities: false })

                } else {
                  console.log('opportunity search query did not work', response.data.message)

                  let opportunityOptions = []
                  self.setState({ opportunityOptions, searchIsAnimatingOpportunities: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimatingOpportunities: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'entity') {
        const searchObject = passedItem
        let searchEntities = passedItem.firstName + ' ' + passedItem.lastName
        // if (passedItem.name) {
        //   searchEntities = passedItem.name
        // } else if (passedItem.employerName) {
        //   searchEntities = passedItem.employerName
        // }
        const unready = false
        const entityOptions = null
        console.log('show entity values: ', searchEntities, passedItem)

        this.setState({ searchObject, searchEntities, unready, entityOptions })
      } else if (type === 'opportunity') {
        const searchObject = passedItem
        let searchOpportunities = passedItem.name
        if (passedItem.title) {
          searchOpportunities = passedItem.title
        }
        const unready = false
        const opportunityOptions = null

        this.setState({ searchObject, searchOpportunities, unready, opportunityOptions })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'opportunity') {
        if (!this.state.searchOpportunities || this.state.searchOpportunities === '') {
          this.setState({ errorMessage: 'Please add an opportunity'})
        } else {
          const searchOpportunities = ''
          const searchObject = null
          const unready = true

          let selectedOpportunities = this.state.selectedOpportunities
          selectedOpportunities.unshift(this.state.searchOpportunities)

          let selectedOpportunityDetails = this.state.selectedOpportunityDetails
          selectedOpportunityDetails.unshift(this.state.searchObject)

          this.setState({ searchOpportunities, searchObject, unready, selectedOpportunities, errorMessage: null })
        }

      } else if (type === 'entity') {
        if (!this.state.searchEntities || this.state.searchEntities === '') {
          this.setState({ errorMessage: 'Please add the name of a person'})
        } else {
          const searchEntities = ''
          const searchObject = null
          const unready = true

          let selectedEntities = this.state.selectedEntities
          selectedEntities.unshift(this.state.searchEntities)

          let selectedEntityDetails = this.state.selectedEntityDetails
          selectedEntityDetails.unshift(this.state.searchObject)

          this.setState({ searchEntities, searchObject, unready, selectedEntities, errorMessage: null })
        }

      } else if (type === 'community') {
        if (!this.state.community || this.state.community === '') {
          this.setState({ errorMessage: 'Please add a community'})
        } else {
          let selectedCommunities = this.state.selectedCommunities
          selectedCommunities.push(this.state.community)

          let selectedCommunityDetails = []
          for (let i = 1; i <= this.state.communityOptions.length; i++) {
            if (this.state.communityOptions[i - 1].name === this.state.community) {
              selectedCommunityDetails.push(this.state.communityOptions[i - 1])
            }
          }

          let community = ''
          this.setState({ selectedCommunities, selectedCommunityDetails, community })
        }

      } else if (type === 'benchmark') {
        if (!this.state.tempBenchmark || this.state.tempBenchmark === '') {
          this.setState({ errorMessage: 'Please add a benchmark'})
        } else {
          let selectedBenchmarks = this.state.selectedBenchmarks
          selectedBenchmarks.push(this.state.tempBenchmark)

          let selectedBenchmarkDetails = []
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            if (this.state.benchmarkOptions[i - 1].title === this.state.tempBenchmark) {
              selectedBenchmarkDetails.push(this.state.benchmarkOptions[i - 1])
            }
          }
          let tempBenchmark = ''
          this.setState({ selectedBenchmarks, selectedBenchmarkDetails, tempBenchmark })
        }
      }
    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.selectedCareer)

      if (type === 'entity') {
        const selectedEntities = this.state.selectedEntities
        if (selectedEntities && selectedEntities.length > 0) {

          return (
            <div key={"selectedEntities"}>
              <div className="spacer" />
              {selectedEntities.map((value, optionIndex) =>
                <div key={"entity|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className={(this.state.selectedEntity === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'opportunity') {
        const selectedOpportunities = this.state.selectedOpportunities
        if (selectedOpportunities && selectedOpportunities.length > 0) {

          return (
            <div key={"selectedOpportunities"}>
              <div className="spacer" />
              {selectedOpportunities.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className={(this.state.selectedOpportunity === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'community') {
        const selectedCommunities = this.state.selectedCommunities
        if (selectedCommunities && selectedCommunities.length > 0) {

          return (
            <div key={"selectedOpportunities"}>
              <div className="spacer" />
              {selectedCommunities.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'benchmark') {
        const selectedBenchmarks = this.state.selectedBenchmarks
        if (selectedBenchmarks && selectedBenchmarks.length > 0) {

          return (
            <div key={"selectedOpportunities"}>
              <div className="spacer" />
              {selectedBenchmarks.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'entity') {
        let selectedEntities = this.state.selectedEntities
        selectedEntities.splice(index, 1)

        let selectedEntityDetails = this.state.selectedEntityDetails
        // const entityToRemove = selectedEntityDetails[index]
        selectedEntityDetails.splice(index, 1)
        this.setState({ selectedEntities, selectedEntityDetails })
      } else if (type === 'opportunity') {
        let selectedOpportunities = this.state.selectedOpportunities
        selectedOpportunities.splice(index, 1)

        let selectedOpportunityDetails = this.state.selectedOpportunityDetails
        // const opportunityToRemove = selectedOpportunityDetails[index]
        selectedOpportunityDetails.splice(index, 1)
        this.setState({ selectedOpportunities, selectedOpportunityDetails })
      } else if (type === 'community') {
        let selectedCommunities = this.state.selectedCommunities
        selectedCommunities.splice(index, 1)

        let selectedCommunityDetails = this.state.selectedCommunityDetails
        // const opportunityToRemove = selectedOpportunityDetails[index]
        selectedCommunityDetails.splice(index, 1)
        this.setState({ selectedCommunities, selectedCommunityDetails })
      } else if (type === 'benchmark') {
        let selectedBenchmarks = this.state.selectedBenchmarks
        selectedBenchmarks.splice(index, 1)

        let selectedBenchmarkDetails = this.state.selectedBenchmarkDetails
        // const opportunityToRemove = selectedOpportunityDetails[index]
        selectedBenchmarkDetails.splice(index, 1)
        this.setState({ selectedBenchmarks, selectedBenchmarkDetails })
      }
    }

    favoriteApplicant(item,) {
      console.log('favoriteApplicant called', item)

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      let itemId = item._id
      let favoriteIds = this.state.favoriteIds

      if (favoriteIds.includes(itemId)){
        console.log('its included')
        let index = favoriteIds.indexOf(itemId)

        if (index > -1) {
          favoriteIds.splice(index, 1);
        }
      } else {
        favoriteIds.push(itemId)
      }

      console.log('show favorites: ', favoriteIds.length)

      Axios.post('/api/favorites/save', {
        favoritesArray: favoriteIds, emailId: this.state.emailId
      }).then((response) => {
        console.log('attempting to remove favorites')
        if (response.data.success) {
          console.log('saved removal from favorites', response.data)
          //clear values
          this.setState({ favoriteIds, successMessage: response.data.message, isSaving: false, tempId: itemId })
        } else {
          console.log('did not save successfully')
          this.setState({ favoriteIds, errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ favoriteIds, errorMessage: 'there was an error saving favorites', isSaving: false })
      });
    }

    renderApplications() {
      console.log('renderApplications called', this.state.isLoading)

      if (this.state.isLoading) {
        return (
          <div key="loadingScreen">
            <div className="flex-container flex-center full-space padding-40">
              <div>
                <div className="super-spacer" />

                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <p className="center-text cta-color bold-text">Loading...</p>

              </div>
            </div>
          </div>
        )
      } else {
        let rows = [];
        if (this.state.filteredApplications) {

          rows.push(
            <div key="0">
              {(this.state.checkCount > 0) && (
                <div className="description-text-3 dark-background half-bold-text white-text height-60 horizontal-padding">
                  <div className="float-left row-10 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.configureMessageWidget(false,false,false,false)}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={messageIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Send Message</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-10 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.configureMessageWidget(true,false,false,false)}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={opportunitiesIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Share Posting</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-10 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.configureMessageWidget(false,true,false,false)}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={socialIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Share Group</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-10 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.configureMessageWidget(false,false,true,false)}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={industryIconDark} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin white-image" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Share Profile</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-10 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.configureMessageWidget(false,false,false,true)}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={benchmarksIconDark} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin white-image" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Share Benchmark</p>
                        </div>
                      </div>
                    </a>
                  </div>

                </div>
              )}

              <div className="description-text-3 light-background height-40 half-bold-text horizontal-padding-40 standard-border">
                <div className="calc-column-offset-50">
                  <div className="fixed-column-40">
                    <div className="spacer" />
                    <a className="background-link" onClick={() => this.checkAllApplications()}>
                      <img src={(this.state.allChecked) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-18" />
                    </a>
                  </div>
                  <div className="fixed-column-50 top-padding">
                    <p>Pic</p>
                  </div>
                  <div className="fixed-column-150 top-padding">
                    <p>Name</p>
                  </div>
                  <div className="fixed-column-100 top-padding">
                    <p>Education</p>
                  </div>
                  <div className="fixed-column-70 top-padding">
                    <p>Match</p>
                  </div>
                  <div className="fixed-column-100 top-padding">
                    <p>Zip Code</p>
                  </div>
                  <div className="fixed-column-80 top-padding">
                    <p>Hours</p>
                  </div>
                  <div className="fixed-column-60 top-padding">
                    <p>Diverse</p>
                  </div>
                  <div className="fixed-column-70 top-padding">
                    <p>Updated</p>
                  </div>
                </div>

                <div className="clear" />
                <div className="spacer"/><div className="half-spacer"/>
              </div>
            </div>
          )

          for (let i = 1; i <= this.state.filteredApplications.length; i++) {
            console.log('show fa: ', i, this.state.filteredApplications[i - 1].email, this.state.filteredApplications[i - 1].rank);

            const index = i - 1

            // let interviewed = false
            // if (this.state.filteredApplications[i - 1].interviews) {
            //   for (let j = 1; j <= this.state.filteredApplications[i - 1].interviews.length; j++) {
            //     if(this.state.filteredApplications[i - 1].interviews[j - 1]) {
            //       if (Number(this.state.filteredApplications[i - 1].interviews[j - 1].score) > 0) {
            //         interviewed = true
            //       }
            //     } else {
            //       console.log('filteredApp: ', this.state.filteredApplications[i - 1])
            //     }
            //   }
            // } else {
            //   // console.log('filteredApp: ', this.state.filteredApplications[i - 1])
            // }

            let match = ''
            if (this.state.filteredApplications[i - 1].match) {
              match = this.state.filteredApplications[i - 1].match + '%'
            }

            // let createdAt = ''
            // if (this.state.filteredApplications[i - 1].createdAt) {
            //   createdAt = 'Applied: ' + this.state.filteredApplications[i - 1].createdAt.substring(0,10) + ' | '
            // }

            rows.push(
              <div key={i}>
                <div className="clear" />

                <div className="row-5 horizontal-padding-40">
                  <div className="fixed-column-40 row-10 right-padding-20">
                    <a className="background-link" onClick={() => this.checkApplication(index)}>
                      <img src={(this.state.filteredApplications[index].isChecked) ? checkboxChecked : checkboxEmpty} alt="Guided Compass dashboard icon" className="image-auto-20" />
                    </a>
                  </div>
                  <Link className="background-button calc-column-offset-130 description-text-2" to={"/employers/" + this.state.accountCode + "/career-seekers/" + this.state.filteredApplications[i - 1].email}>
                    <div className="fixed-column-50">
                      <div className="half-spacer" />
                      <img src={(this.state.filteredApplications[i - 1].pictureURL) ? this.state.filteredApplications[i - 1].pictureURL : profileIconGrey} alt="GC icon" className="profile-thumbnail-1" />
                    </div>
                    <div className="fixed-column-150 left-padding top-padding">
                      <p>{this.state.filteredApplications[i - 1].firstName} {this.state.filteredApplications[i - 1].lastName}</p>
                    </div>
                    <div className="fixed-column-100 left-padding top-padding">
                      <p>{this.state.filteredApplications[i - 1].degree}</p>
                    </div>
                    <div className="fixed-column-70 left-padding top-margin-negative-5 top-padding">
                      <p className="heading-text-3 cta-color">{match}</p>
                    </div>
                    <div className="fixed-column-100 left-padding top-padding">
                      <p>{this.state.filteredApplications[i - 1].zipcode}</p>
                    </div>
                    <div className="fixed-column-80 left-padding top-padding">
                      <p>{this.state.filteredApplications[i - 1].totalHours}</p>
                    </div>
                    <div className="fixed-column-60 left-padding top-padding">
                      <p>Yes</p>
                    </div>
                    <div className="fixed-column-90 left-padding top-padding">
                      <p>{(this.state.filteredApplications[i - 1].updatedAt) && this.state.filteredApplications[i - 1].updatedAt.substring(0,10)}</p>
                    </div>
                  </Link>

                  <div className="float-right top-margin">
                    <Link className="secret-link" to={"/employers/" + this.state.accountCode + "/career-seekers/" + this.state.filteredApplications[i - 1].email}>
                      <div className="float-right right-margin">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                      </div>
                    </Link>

                    <button className="background-button" onClick={() => this.favoriteApplicant(this.state.filteredApplications[i - 1])}>
                      <div className="float-right width-40">
                        <img src={(this.state.favoriteIds.includes(this.state.filteredApplications[i - 1]._id)) ? favoriteIconSelected : favoriteIconUnselected} alt="Compass arrow indicator icon" className={(this.state.filteredApplications[i - 1].liked) ? "image-auto-20 top-margin-negative-4" : "image-auto-20 top-margin-negative-10 right-margin"} />
                      </div>
                    </button>
                  </div>
                </div>
                <div className="clear" />

                {(this.state.laterStages.includes(this.state.filteredApplications[i - 1].stage)) && (
                  <div className="left-padding-50">
                    <p className="error-color description-text-2 clear-margin clear-padding">Application Stage: {this.state.filteredApplications[i - 1].stage}</p>
                  </div>
                )}

                <div className="left-padding-50">
                  {this.state.individualFilters.map((value, optionIndex) =>
                    <span>
                      {(value.value !== 'All') && (
                        <label key={optionIndex} className="description-text-3 error-color right-padding">{value.name}: {value.value}</label>
                      )}
                    </span>
                  )}
                </div>

                <hr className="cell-separator-advisor"/>
              </div>
            )
          }
        }

        return rows;
      }
    }

    configureMessageWidget(showOpportunities, showCommunities, showEmployerProfile, showBenchmarks) {
      console.log('configureMessageWidget called')

      let selectedEntities = []
      let selectedEntityDetails = []
      for (let i = 1; i <= this.state.filteredApplications.length; i++) {
        if (this.state.filteredApplications[i - 1].isChecked) {
          selectedEntities.push(this.state.filteredApplications[i - 1].firstName + ' ' + this.state.filteredApplications[i - 1].lastName)
          selectedEntityDetails.push(this.state.filteredApplications[i - 1])
        }
      }

      this.setState({ modalIsOpen: true, showMessageWidget: true, showOpportunities, showCommunities, showEmployerProfile, showBenchmarks, selectedEntities, selectedEntityDetails  })

    }

    checkApplication(index) {
      console.log('checkApplication called')

      let filteredApplications = this.state.filteredApplications
      let appsToDelete = this.state.appsToDelete

      if (filteredApplications[index].isChecked) {
        filteredApplications[index]['isChecked'] = false
        let deleteIndex = appsToDelete.indexOf(filteredApplications[index]._id)
        appsToDelete.splice(deleteIndex, 1)
        const checkCount = this.state.checkCount - 1
        this.setState({ filteredApplications, appsToDelete, checkCount })
      } else {
        filteredApplications[index]['isChecked'] = true
        appsToDelete.push(filteredApplications[index]._id)
        const checkCount = this.state.checkCount + 1
        this.setState({ filteredApplications, appsToDelete, checkCount })
      }
    }

    checkAllApplications() {
      console.log('checkAllApplications called')

      let filteredApplications = this.state.filteredApplications
      let appsToDelete = []
      let allChecked = this.state.allChecked
      let checkCount = filteredApplications.length
      if (!allChecked) {
        allChecked = true
        for (let i = 1; i <= this.state.filteredApplications.length; i++) {
          filteredApplications[i - 1]['isChecked'] = true
          appsToDelete.push(filteredApplications[i - 1]._id)
        }
      } else {
        allChecked = false
        checkCount = 0
        for (let i = 1; i <= this.state.filteredApplications.length; i++) {
          filteredApplications[i - 1]['isChecked'] = false
        }
      }

      this.setState({ allChecked, filteredApplications, appsToDelete, checkCount })
    }

    changeSubNavSelected(value) {
      console.log('changeSubNavSelected called', value)

      if (value.name === 'All') {
        this.setState({ subNavSelected: value })
        this.calculateMatches(this.state.applications, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
      } else if (value.name === 'Favorited'){
        this.setState({ isLoading: true })
        Axios.get('/api/get-followers', { params: { _id: this.state.employerId } })
        .then((response) => {
          console.log('Followers query attempted', response.data);

          if (response.data.success) {
            console.log('followers query worked in sub settings')

            if (response.data.followers && response.data.followers.length > 0) {
              const filteredApplications = response.data.followers
              this.setState({ subNavSelected: value, isLoading: false })
              this.calculateMatches(filteredApplications, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
            } else {
              this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
            }

          } else {
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
          }

        }).catch((error) => {
          console.log('Followers query did not work for some reason', error);
          this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
        });

      } else if (value.name === 'Attended Event') {
        this.setState({ isLoading: true })
        const accountCode = this.state.accountCode

        Axios.get('/api/employers/funnel/event', { params: { accountCode } })
        .then((response) => {
          console.log('Event funnel query attempted', response.data);

          if (response.data.success) {
            console.log('event funnel query worked')

            if (response.data.users && response.data.users.length > 0) {
              this.setState({ subNavSelected: value, isLoading: false })
              this.calculateMatches(response.data.users, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
            } else {
              this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
            }

          } else {
            console.log('event funnel query did not work', response.data.message)
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
          }
        }).catch((error) => {
            console.log('Event funnel query did not work for some reason', error);
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
        });
      } else if (value.name === 'Submitted Project') {
        console.log('in submitted project')
        this.setState({ isLoading: true })
        const accountCode = this.state.accountCode
        Axios.get('/api/employers/funnel/submissions', { params: { accountCode } })
        .then((response) => {
          console.log('Submissions funnel query attempted', response.data);

          if (response.data.success) {
            console.log('submissions funnel query worked')

            if (response.data.users && response.data.users.length > 0) {
              this.setState({ subNavSelected: value, isLoading: false })
              this.calculateMatches(response.data.users, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
            } else {
              this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
            }

          } else {
            console.log('submissions funnel query did not work', response.data.message)
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
          }
        }).catch((error) => {
            console.log('Submissions funnel query did not work for some reason', error);
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
        });
      } else if (value.name === 'Applied' || value.name === 'Interviewed') {
        this.setState({ isLoading: true })
        const accountCode = this.state.accountCode
        let stages = null
        if (value.name === 'Interviewed') {
          stages = ['Connected','Interview']
        }
        Axios.get('/api/applications', { params: { accountCode, stages } })
        .then((response) => {
          console.log('Applications query attempted', response.data);

          if (response.data.success) {
            console.log('applications query worked')

            if (response.data.applications && response.data.applications.length > 0) {
              let applications = []
              let applicationEmails = []
              for (let i = 1; i <= response.data.applications.length; i++) {
                if (!applicationEmails.includes(response.data.applications[i - 1].email)) {
                  applicationEmails.push(response.data.applications[i - 1].email)
                  applications.push(response.data.applications[i - 1])
                }
              }

              this.setState({ subNavSelected: value, isLoading: false })
              this.calculateMatches(applications, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
            } else {
              this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
            }

          } else {
            console.log('applications query did not work', response.data.message)
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
          }
        }).catch((error) => {
            console.log('Application query did not work for some reason', error);
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
        });

      } else if (value.name === 'Hired') {
        const accountCode = this.state.accountCode
        Axios.get('/api/employers/funnel/hired', { params: { accountCode } })
        .then((response) => {
          console.log('Hired query attempted', response.data);

          if (response.data.success) {
            console.log('hired query worked')

            if (response.data.applications && response.data.applications.length > 0) {
              this.setState({ subNavSelected: value, isLoading: false })
              this.calculateMatches(response.data.applications, { postType: 'Work' }, this.state.selectedBenchmark, null, this.state.org)
            } else {
              this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
            }

          } else {
            console.log('hired query did not work', response.data.message)
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
          }
        }).catch((error) => {
            console.log('Hired query did not work for some reason', error);
            this.setState({ subNavSelected: value, isLoading: false, filteredApplications: [] })
        });
      } else {
        console.log('value?', value)
      }

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showMessageWidget: false, showAddGroup: false,
        showOpportunities: false, showCommunities: false, showEmployerProfile: false, showBenchmarks: false,
        selectedEntities: [], selectedEntityDetails: []
      })
    }

    sendMessage() {
      console.log('sendMessage called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (!this.state.selectedEntities || this.state.selectedEntities.length === 0) {
        this.setState({ errorMessage: 'Please add at least one recipient', isSaving: false })
      } else if (!this.state.message || this.state.message === '') {
        this.setState({ errorMessage: 'Please add a message', isSaving: false })
      } else {

        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId

        let recipientFirstNames = []
        let recipientLastNames = []
        let recipientEmails = []
        for (let i = 1; i <= this.state.selectedEntityDetails.length; i++) {
          recipientFirstNames.push(this.state.selectedEntityDetails[i - 1].firstName)
          recipientLastNames.push(this.state.selectedEntityDetails[i - 1].lastName)
          recipientEmails.push(this.state.selectedEntityDetails[i - 1].email)
        }

        const recipientType = 'Pipeline Candidate'
        const isRead = false
        const message = this.state.message
        const type = 'Message'
        const relationship = 'Employer'
        const isApproved = false
        const isDecided = false

        const orgCode = this.state.org
        const orgName = this.state.orgName
        const accountCode = this.state.accountCode
        const employerName = this.state.employerName

        const selectedOpportunityDetails = this.state.selectedOpportunityDetails
        const selectedCommunityDetails = this.state.selectedCommunityDetails
        let employerProfile = null
        if (this.state.showEmployerProfile) {
          employerProfile = window.location.protocol + "//" + window.location.host + "/employers/pages/" + this.state.accountCode
        }
        const selectedBenchmarkDetails = this.state.selectedBenchmarkDetails

        Axios.post('/api/notifications/message', {
          senderFirstName, senderLastName, senderEmail, recipientFirstNames, recipientLastNames, recipientEmails,
          recipientType, isRead, message, type, relationship, isApproved, isDecided,
          orgCode, orgName, accountCode, employerName,
          selectedOpportunityDetails, selectedCommunityDetails, employerProfile, selectedBenchmarkDetails,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message send worked', response.data);

            this.setState({ isSaving: false, successMessage: 'Message sent successfully!', message: '',
              searchEntities: '', selectedEntities: [], selectedEntityDetails: [],
              selectedCommunities: [], selectedCommunityDetails: [],
              selectedBenchmarks: [], selectedBenchmarkDetails: [],
              showOpportunities: false, showCommunities: false, showEmployerProfile: false, showBenchmarks: false
            })
          } else {
            console.error('there was an error sending the message');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
        });
      }

    }

    render() {

      return (
          <div>
            {(this.state.selectedBenchmark) && (
              <div>
                <div>
                  <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                    <div>
                      <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                        <div className="full-width height-5 primary-background" />
                        <div className="padding-40">
                          <div className="row-10">
                            <div className="fixed-column-90">
                              <Link to={(window.location.pathname.includes('/app/')) ? '/app/employers/' + this.state.selectedBenchmark.accountCode : '/employers/' + this.state.selectedBenchmark.accountCode} className="background-link">
                                <img src={(this.state.employerLogoURI) ? this.state.employerLogoURI : pipelineIconDark} alt="GC" className="image-auto-80" />
                              </Link>
                            </div>
                            <div className="calc-column-offset-90 left-padding top-padding-5">
                              <p className="heading-text-2">{this.state.selectedBenchmark.title.replace(/benchmark/i,"")}</p>
                              <p className="top-padding">{this.state.selectedBenchmark.jobFunction} talent pipeline at {this.state.employerName}.</p>
                            </div>

                            <div className="clear" />
                          </div>

                        </div>
                      </div>

                      <div>
                        <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 medium-shadow top-margin-2-percent pin-right" : "medium-shadow slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40 "}>
                          <div className="carousel-3 white-background" onScroll={this.handleScroll}>
                            {this.state.subNavCategories.map((value, index) =>
                              <div className="display-inline right-padding-20">
                                {(value === this.state.subNavSelected) ? (
                                  <button className="background-button full-space" disabled={true} onClick={() => this.setState({ subNavSelected: value })}>
                                    <div className="selected-carousel-item">
                                      <label>{value.count}</label>
                                      <div className="clear" />
                                      <label className="description-text-3">{value.name}</label>
                                    </div>
                                  </button>
                                ) : (
                                  <button className="menu-button clear-margin" onClick={() => this.changeSubNavSelected(value)}>
                                    <label>{value.count}</label>
                                    <div className="clear" />
                                    <label className="description-text-3">{value.name}</label>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        {(this.state.subNavSelected.name === 'All') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Favorited') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Attended Event') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Submitted Project') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Applied') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Interviewed') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        {(this.state.subNavSelected.name === 'Hired') && (
                          <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                            {this.renderApplications()}
                          </div>
                        )}

                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                      </div>
                    </div>
                  </div>

                  {(window.innerWidth > 768) && (
                    <div className="relative-column-30 horizontal-padding top-margin-40">
                      <div className="left-padding-20">
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-40">
                          <div className="full-width height-5 senary-background" />

                          {(this.state.selectedGroup && this.state.selectedGroup.pathway === this.state.selectedBenchmark.pathway) ? (
                            <div className="padding-20">
                              <p className="heading-text-5">Manage {this.state.selectedGroup.name}</p>

                              <div className="spacer" /><div className="spacer" />

                              <div className="row-5">
                                <Link className="background-button" to={"/employers/" + this.state.accountCode + "/groups/" + this.state.selectedGroup._id}>
                                  <div className="fixed-column-60">
                                    <img src={(this.state.selectedGroup.pictureURL) ? this.state.selectedGroup.pictureURL : pipelineIconDark} alt="GC" className="image-50-fit"/>
                                  </div>
                                  <div className="calc-column-offset-60">
                                    <p>{this.state.selectedGroup.name}</p>
                                    <div className="half-spacer" />
                                  </div>
                                  <div className="clear"/>
                                </Link>
                              </div>

                              <div className="spacer" /><div className="spacer" />
                            </div>
                          ) : (
                            <div className="padding-20">
                              <p className="heading-text-5">Create a Talent Community</p>

                              <div className="spacer" /><div className="half-spacer" /><div className="half-spacer" />

                              <p className="description-text-color description-text-2">Nurture future and previous {this.state.selectedBenchmark.jobFunction} candidates with a dedicated talent community.</p>

                              <div className="spacer" /><div className="half-spacer" /><div className="spacer" /><div className="half-spacer" />

                              <button className="btn btn-primary description-text-2" onClick={() => this.setState({ modalIsOpen: true, showAddGroup: true })}>Get Started</button>

                              <div className="spacer" /><div className="half-spacer" />
                            </div>
                          )}

                        </div>

                        {(this.state.benchmarks && this.state.benchmarks.length > 0) && (
                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <p className="heading-text-5">Other {this.state.employerName} Pipelines</p>

                              <div className="spacer" /><div className="half-spacer" />

                              {this.state.benchmarks.map((item, index) =>
                                <div key={item}>
                                  {(index < 3) && (
                                    <div className="bottom-padding">
                                      <div className="spacer" />
                                      <Link to={'/employers/' + this.state.selectedBenchmark.accountCode + '/pipelines/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                        <div className="fixed-column-50">
                                          <img src={(this.state.employerLogoURI) ? this.state.employerLogoURI : pipelineIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                        </div>
                                        <div className="calc-column-offset-90 left-padding-5">
                                          <p>{item.title.replace(/benchmark/i,"")}</p>
                                          <p className="description-text-2 standard-color">{item.jobFunction} | {item.jobType}</p>
                                        </div>
                                      </Link>
                                      <div className="float-left left-padding">
                                        <span className="float-right">
                                          <div className="spacer"/>
                                          <Link to={'/employers/' + this.state.selectedBenchmark.accountCode + '/pipelines/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                          </Link>
                                        </span>
                                      </div>
                                      <div className="clear"/>

                                      <div className="spacer" /><div className="spacer" />
                                      <hr />
                                      <div className="clear"/>
                                      <div className="spacer" />
                                    </div>
                                  )}
                                </div>
                              )}

                              {(this.state.benchmarks.length > 0) && (
                                <div>
                                  <Link className="background-button full-width right-text" to={'/employers/' + this.state.selectedBenchmark.accountCode + '/pipelines'} state={{ subNavSelected: 'Benchmarks' }}>
                                    <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="clear" />
                </div>

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

              {(this.state.showMessageWidget) && (
                <div key="showMatchingCriteria" className="full-width padding-20">
                  <p className="heading-text-2">Send a Message</p>

                  <div className="spacer" />

                  <div className="row-10">
                    <div className="calc-column-offset-70">
                      <input type="text" className="text-field" placeholder="Search a person..." name="searchEntities" value={this.state.searchEntities} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className="btn btn-squarish" onClick={() => this.addItem('entity')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                  {(this.state.searchIsAnimating) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <div className="super-spacer" />

                        <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Searching...</p>

                      </div>
                    </div>
                  ) : (
                    <div>

                      <div>
                        {(this.state.entityOptions && this.state.entityOptions.length > 0) && (
                          <div className="card top-margin">
                            {this.state.entityOptions.map((value, optionIndex) =>
                              <div key={value._id} className="left-text bottom-margin-5 full-width">
                                <button className="background-button full-width row-7 left-text" onClick={() => this.searchItemClicked(value, 'entity')}>
                                  <div className="full-width">
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" />
                                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-25" />
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p className="cta-color heading-text-6">{value.firstName} {value.lastName}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div>

                        {this.renderTags('entity')}
                        <div className="clear" />

                      </div>

                    </div>
                  )}

                  <div className="row-10">
                    <textarea type="text" className="text-field" placeholder="Write your message..." name="message" value={this.state.message} onChange={this.formChangeHandler}/>
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <button className="background-button" onClick={(this.state.showOpportunities) ? () => this.setState({ showOpportunities: false }) : () => this.setState({ showOpportunities: true })}>
                        <img src={(this.state.showOpportunities) ? opportunitiesIconBlue : opportunitiesIconDark} className="image-auto-20 float-left" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Tag a " + this.state.employerName + " opportunity"} />
                        <img src={(this.state.showOpportunities) ? tagIconBlue : tagIconDark} className="image-auto-9 float-left right-margin-negative-24 right-margin-2" alt="GC" />
                        <div className="clear" />
                      </button>
                    </div>

                    <div className="float-left left-padding-20">
                      <button className="background-button" onClick={(this.state.showCommunities) ? () => this.setState({ showCommunities: false }) : () => this.setState({ showCommunities: true })}>
                        <img src={(this.state.showCommunities) ? socialIconBlue : socialIconDark} className="image-auto-20 float-left" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Tag a " + this.state.employerName + " community"} />
                        <img src={(this.state.showCommunities) ? tagIconBlue : tagIconDark} className="image-auto-9 float-left right-margin-negative-24 right-margin-2" alt="GC" />
                        <div className="clear" />
                      </button>
                    </div>
                    <div className="float-left left-padding-20">
                      <button className="background-button" onClick={(this.state.showEmployerProfile) ? () => this.setState({ showEmployerProfile: false }) : () => this.setState({ showEmployerProfile: true })}>
                        <img src={(this.state.showEmployerProfile) ? industryIconBlue : industryIconDark} className="image-auto-20 float-left" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Add a link to the " + this.state.employerName + " profile"}/>
                        <img src={(this.state.showEmployerProfile) ? tagIconBlue : tagIconDark} className="image-auto-9 float-left right-margin-negative-24 right-margin-2" alt="GC" />
                        <div className="clear" />
                      </button>
                    </div>
                    <div className="float-left left-padding-20">
                      <button className="background-button" onClick={(this.state.showBenchmarks) ? () => this.setState({ showBenchmarks: false }) : () => this.setState({ showBenchmarks: true })}>
                        <img src={(this.state.showBenchmarks) ? benchmarksIconBlue : benchmarksIconDark} className="image-auto-20 float-left" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Add a " + this.state.employerName + " benchmark"} />
                        <img src={(this.state.showBenchmarks) ? tagIconBlue : tagIconDark} className="image-auto-9 float-left right-margin-negative-24 right-margin-2" alt="GC" />
                        <div className="clear" />
                      </button>
                    </div>

                    <div className="clear" />
                    <ReactTooltip id="tooltip-placeholder-id" />
                  </div>

                  {(this.state.showOpportunities) && (
                    <div className="row-10">
                      <p className="description-text-3">Tag an Opportunity (Optional)</p>
                      <div className="half-spacer" />

                      <div>
                        <div className="calc-column-offset-70">
                          <input type="text" className="text-field" placeholder="Search event, project, and work opportunities..." name="searchOpportunities" value={this.state.searchOpportunities} onChange={this.formChangeHandler}/>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.addItem('opportunity')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="spacer" />

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                      {(this.state.searchIsAnimatingOpportunities) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <div className="super-spacer" />

                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Searching...</p>

                          </div>
                        </div>
                      ) : (
                        <div>

                          <div>
                            {(this.state.opportunityOptions && this.state.opportunityOptions.length > 0) && (
                              <div className="card top-margin">
                                {this.state.opportunityOptions.map((value, optionIndex) =>
                                  <div key={value._id} className="left-text bottom-margin-5 full-width">
                                    <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'opportunity')}>
                                      <div className="full-width">
                                        <div className="fixed-column-40">
                                          <div className="mini-spacer" />
                                          <img src={opportunitiesIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                        </div>
                                        <div className="calc-column-offset-40">
                                          <p className="cta-color">{(value.title) ? value.title : value.name}{value.employerName && " | " + value.employerName}</p>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div>

                            {this.renderTags('opportunity')}
                            <div className="clear" />

                          </div>

                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.showCommunities) && (
                    <div className="row-10">
                      <p className="description-text-3">Tag Communities (Optional)</p>
                      <div className="half-spacer" />

                      <div>
                        <div className="calc-column-offset-70">
                          <select name="community" value={this.state.community} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.communityOptions.map(value =>
                              <option key={value.name} value={value.name}>{value.name}</option>
                            )}
                          </select>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.addItem('community')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div>

                        {this.renderTags('community')}
                        <div className="clear" />

                      </div>
                    </div>
                  )}

                  {(this.state.showEmployerProfile) && (
                    <div className="row-10">
                      <a href={window.location.protocol + "//" + window.location.host + "/employers/pages/" + this.state.accountCode} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/employers/pages/" + this.state.accountCode}</a>
                    </div>
                  )}

                  {(this.state.showBenchmarks) && (
                    <div className="row-10">
                      <p className="description-text-3">Tag Benchmarks (Optional)</p>
                      <div className="half-spacer" />

                      <div>
                        <div className="calc-column-offset-70">
                          <select name="tempBenchmark" value={this.state.tempBenchmark} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.benchmarkOptions.map(value =>
                              <option key={value.title} value={value.title}>{value.title}</option>
                            )}
                          </select>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.addItem('benchmark')}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div>
                        {this.renderTags('benchmark')}
                        <div className="clear" />

                      </div>
                    </div>
                  )}

                  <div className="row-10">
                    <button className="btn btn-primary" onClick={() => this.sendMessage()}>Send Message</button>
                  </div>

                </div>
              )}

              {(this.state.showAddGroup) && (
                <div key="showAddGroup" className="full-width padding-20">
                   <SubEditGroup selectedGroup={null} history={this.props.navigate} closeModal={this.closeModal}
                     accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                     employerName={this.state.employerName} jobFunction={this.state.selectedBenchmark.jobFunction}
                     pathway={this.state.selectedBenchmark.pathway}
                   />
                 </div>
              )}

           </Modal>
          </div>

      )
    }
}

export default withRouter(PipelineDetails);
