import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubLogInFields from '../Common/LogInFields';
import {signOut} from '../Services/AuthRoutes';
import withRouter from '../Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const questionMarkBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png";
const infoIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png";
const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const checkboxEmpty = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png";
const checkboxChecked = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png";

class EditBusinessIdea extends Component {
    constructor(props) {
        super(props)

        this.state = {
          price: 25,
          stageLevelPrice: 25,
          executionalAssetPrice: 50,

          fundingTypeOptions: ['','Grant','Debt','Equity','Other'],
          stageOptions: ['','Idea','Pre-Launch Tested','Launched Product/Service','Revenue-Generating','Other'],
          categoryOptions: ['','Web App','Mobile App','Brick & Mortar','Hardware'],
          applicableTechOptions: ['Website','Web App','Mobile App','AR/VR','AI','Blockchain'],
          metricsTypeOptions: ['','Monthly Active Users','Daily Active Users','Total Revenue','Monthl Recurring Revenue','Revenue Churn Rate','Customer Acquisition Cost','Customer Lifetime Value','Average Revenue per User','Other'],
          industryOptions: [],
          orientationOptions: ['','B2C','B2B','B2B2C','Other'],
          benefitsOptions: ['Unpaid advisory board seat','Paid board seat','Equity in the company','Credit in the about page'],
          raceOptions: [],
          genderOptions: [],
          veteranOptions: [],
          executionalAssets: ['interviews','demoURL','pitchDeckURL','githubURL','promoVideo']

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.addItem = this.addItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.toggleItem = this.toggleItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.calculatePrice = this.calculatePrice.bind(this)

        this.passData = this.passData.bind(this)
        this.logout = this.logout.bind(this)

    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in courseDetails', this.props, prevProps)

      if (this.props.id !== prevProps.id) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editCourse:  ', this.props.selectedCourse)

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let pictureURL = localStorage.getItem('pictureURL');
      let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');

      let subjectLine = "Thank You for Purchasing {{ Idea Name }}, {{ First Name }}!"
      let confirmationMessage = "Hi {{ First Name }},\n\nThank you for purchasing {{ Idea Name }}. I spent a lot of time working on this, but I don't have the time nor the passion to see it to fruition. I entrust this idea to you. Please don't hesitate to reach out to me with questions at wowyes20002000@yahoo.com."

      let importedEmailId = null
      if (emailId) {
        importedEmailId = emailId
      }
      this.setState({
        emailId, cuFirstName, cuLastName, username, pictureURL, activeOrg, roleName, importedEmailId,
        subjectLine, confirmationMessage
      })

      // const _id = '6306b18f677b04ce9205d771'
      const _id = this.props.id

      if (_id) {

        Axios.get('/api/business-ideas/byid', { params: { _id }})
        .then((response) => {
          console.log('Business idea query attempted', response.data);

          if (response.data.success) {
            console.log('business idea query worked')

            // const businessIdea = response.data.businessIdea

            const name = response.data.businessIdea.name
            const teaser = response.data.businessIdea.teaser
            const problem = response.data.businessIdea.problem
            const solution = response.data.businessIdea.solution
            const story = response.data.businessIdea.story
            const marketSize = response.data.businessIdea.marketSize
            const businessModel = response.data.businessIdea.businessModel
            const personas = response.data.businessIdea.personas
            const acquisitionStrategy = response.data.businessIdea.acquisitionStrategy
            const competitors = response.data.businessIdea.competitors
            const competitiveAdvantage = response.data.businessIdea.competitiveAdvantage
            const progress = response.data.businessIdea.progress
            const team = response.data.businessIdea.team
            const interviews = response.data.businessIdea.interviews
            const funding = response.data.businessIdea.funding
            const demoURL = response.data.businessIdea.demoURL
            const pitchDeckURL = response.data.businessIdea.pitchDeckURL
            const promoURL = response.data.businessIdea.promoURL
            const stage = response.data.businessIdea.stage
            const applicableTech = response.data.businessIdea.applicableTech
            const metrics = response.data.businessIdea.metrics
            const industry = response.data.businessIdea.industry
            const orientation = response.data.businessIdea.orientation
            const price = response.data.businessIdea.price
            const showContactInfo = response.data.businessIdea.showContactInfo
            const benefits = response.data.businessIdea.benefits
            const linkedInURL = response.data.businessIdea.linkedInURL
            const yearsOfWorkExperience = response.data.businessIdea.yearsOfWorkExperience
            const race = response.data.businessIdea.race
            const gender = response.data.businessIdea.gender
            const veteran = response.data.businessIdea.veteran
            const zipcode = response.data.businessIdea.zipcode
            const subjectLine = response.data.businessIdea.subjectLine
            const confirmationMessage = response.data.businessIdea.confirmationMessage
            const isActive = response.data.businessIdea.isActive
            const status = response.data.businessIdea.status
            const collaborators = response.data.businessIdea.collaborators
            const consultingRate = response.data.businessIdea.consultingRate

            const certified = true

            this.setState({ _id, name, teaser, problem, solution, story, marketSize, businessModel, personas,
              acquisitionStrategy, competitors,
              competitiveAdvantage, progress, team, interviews, funding, demoURL, pitchDeckURL, promoURL, stage,
              applicableTech, metrics, industry, orientation, price, showContactInfo, benefits, linkedInURL,
              yearsOfWorkExperience, race, gender, veteran, zipcode, subjectLine, confirmationMessage, isActive, status,
              collaborators, consultingRate, certified
            })
          } else {
            console.log('custom assessment query did not work', response.data.message)
          }
        }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
        });
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let industryOptions = response.data.workOptions[0].industryOptions
          let raceOptions = [''].concat(response.data.workOptions[0].raceOptions)
          let genderOptions = [''].concat(response.data.workOptions[0].genderOptions)
          let veteranOptions = [''].concat(response.data.workOptions[0].veteranOptions)
          this.setState({ industryOptions, raceOptions, genderOptions, veteranOptions })

        }
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.value)

      if (event.target.name.includes('competitors|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let competitors = this.state.competitors
        competitors[index][name] = event.target.value
        this.setState({ competitors })
      } else if (event.target.name.includes('interviews|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let interviews = this.state.interviews
        interviews[index][name] = event.target.value
        this.setState({ interviews })

      } else if (event.target.name.includes('funding|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let funding = this.state.funding
        funding[index][name] = event.target.value
        this.setState({ funding })
      } else if (event.target.name.includes('metrics|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let metrics = this.state.metrics
        metrics[index][name] = event.target.value
        this.setState({ metrics })
      } else if (event.target.name.includes('collaborators|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let collaborators = this.state.collaborators
        collaborators[index][name] = event.target.value
        this.setState({ collaborators })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }

      this.calculatePrice(event.target.name,event.target.value)

    }

    calculatePrice(name,value) {
      console.log('calculatePrice called', name,value)

      let price = 50

      // add stage points
      let stage = this.state.stage
      if (name === 'stage') {
        stage = value
      }

      for (let i = 1; i <= this.state.stageOptions.length; i++) {
        if (this.state.stageOptions[i - 1] === stage && this.state.stageOptions[i - 1] !== 'Other') {
          price = this.state.stageLevelPrice * (i - 1)
        }
      }

      for (let i = 1; i <= this.state.executionalAssets.length; i++) {
        // console.log('found assets: ', typeof this.state[this.state.executionalAssets[i - 1]], this.state[this.state.executionalAssets[i - 1]])
        let assetValue = this.state[this.state.executionalAssets[i - 1]]
        if (name === this.state.executionalAssets[i - 1]) {
          assetValue = value
        }
        if (assetValue) {
          if (typeof assetValue === 'object' && assetValue.length > 2) {
            price = price + this.state.executionalAssetPrice
          } else if (typeof assetValue === 'string' && assetValue) {
            price = price + this.state.executionalAssetPrice
          }
        }
      }

      // if (this.state.executionalAssets.includes(name)) {
      //   price = price + 200
      // }

      // return price
      this.setState({ price })

    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'competitors') {
        let competitors = this.state.competitors
        if (competitors) {
          competitors.push({ name: '', url: '' })
        } else {
          competitors = [{ name: '', url: '' }]
        }
        this.setState({ competitors })
      } else if (type === 'interviews') {
        let interviews = this.state.interviews
        if (interviews) {
          interviews.push({ interviewee: '', insights: '' })
        } else {
          interviews = [{ interviewee: '', insights: '' }]
        }
        this.setState({ interviews })
      } else if (type === 'funding') {
        let funding = this.state.funding
        if (funding) {
          funding.push({ amount: '', type: '', fundingDate: '' })
        } else {
          funding = [{ amount: '', type: '', fundingDate: '' }]
        }
        this.setState({ funding })
      } else if (type === 'metrics') {
        let metrics = this.state.metrics
        if (metrics) {
          metrics.push({ name: '', type: '', value: '' })
        } else {
          metrics = [{ name: '', type: '', value: '' }]
        }
        this.setState({ metrics })
      } else if (type === 'collaborators') {
        let collaborators = this.state.collaborators
        if (collaborators) {
          collaborators.push({ name: '', email: '' })
        } else {
          collaborators = [{ name: '', email: '' }]
        }
        this.setState({ collaborators })
      }
    }

    deleteItem(type,index) {
      console.log('deleteItem called', type, index)

      if (type === 'competitors') {
        let competitors = this.state.competitors
        competitors.splice(index,1)
        this.setState({ competitors })
      } else if (type === 'interviews') {
        let interviews = this.state.interviews
        interviews.splice(index,1)
        this.setState({ interviews })
      } else if (type === 'funding') {
        let funding = this.state.funding
        funding.splice(index,1)
        this.setState({ funding })
      } else if (type === 'metrics') {
        let metrics = this.state.metrics
        metrics.splice(index,1)
        this.setState({ metrics })
      } else if (type === 'collaborators') {
        let collaborators = this.state.collaborators
        collaborators.splice(index,1)
        this.setState({ collaborators })
      }
    }

    toggleItem(type,index) {
      console.log('toggleItem called')

      if (type === 'applicableTech') {
        let applicableTech = this.state.applicableTech
        if (applicableTech) {
          if (applicableTech.includes(this.state.applicableTechOptions[index])) {
            const removeIndex = applicableTech.indexOf(this.state.applicableTechOptions[index])
            applicableTech.splice(removeIndex,1)
          } else {
            applicableTech.push(this.state.applicableTechOptions[index])
          }
        } else {
          applicableTech = [this.state.applicableTechOptions[index]]
        }

        this.setState({ applicableTech })
      } else if (type === 'benefits') {
        let benefits = this.state.benefits
        if (benefits) {
          if (benefits.includes(this.state.benefitsOptions[index])) {
            const removeIndex = benefits.indexOf(this.state.benefitsOptions[index])
            benefits.splice(removeIndex,1)
          } else {
            benefits.push(this.state.benefitsOptions[index])
          }
        } else {
          benefits = [this.state.benefitsOptions[index]]
        }

        this.setState({ benefits })
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showHandlebarsInfo: false, showMonetizeDescription: false, showPriceCalculation: false })
    }

    handleSubmit() {
      console.log('handleSubmit called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.name || this.state.name === '') {
        this.setState({ isSaving: false, errorMessage: 'Please name your idea' })
      } else if (!this.state.teaser || this.state.teaser === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a teaser for your idea' })
      } else if (!this.state.problem || this.state.problem === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the problem this solution solves' })
      } else if (!this.state.solution || this.state.solution === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a detailed description of the solution' })
      } else if (!this.state.marketSize || this.state.marketSize === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a market size and growth rate for the idea' })
      } else if (!this.state.acquisitionStrategy || this.state.acquisitionStrategy === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a customer acquisition strategy for the idea' })
      } else if (!this.state.competitors || this.state.competitors.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'Please add competitors for the idea' })
      } else if (!this.state.competitiveAdvantage || this.state.competitiveAdvantage === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the competitive advantage for the idea' })
      } else if (!this.state.stage || this.state.stage === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the stage the idea is in' })
      } else if (!this.state.industry || this.state.industry === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the industry the idea is in' })
      } else if (!this.state.orientation || this.state.orientation === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the orientation the idea is in' })
      } else if (!this.state.orientation || this.state.orientation === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the orientation the idea is in' })
      } else if (!this.state.price || this.state.price === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add the price to purchase this idea' })
      } else if (!this.state.showContactInfo || this.state.showContactInfo === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add whether you would like to show your contact information' })
      } else if (!this.state.linkedInURL || this.state.linkedInURL === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your linkedin profile link' })
      } else if (!this.state.yearsOfWorkExperience || this.state.yearsOfWorkExperience === '') {
        this.setState({ isSaving: false, errorMessage: "Please add the number of years you've worked" })
      } else if (!this.state.certified) {
        this.setState({ isSaving: false, errorMessage: "You must certify that what you've added is truthful, and this is indeed your idea." })
      } else {

        const _id = this.state._id
        const name = this.state.name
        const teaser = this.state.teaser
        const problem = this.state.problem
        const solution = this.state.solution
        const story = this.state.story
        const marketSize = this.state.marketSize
        const businessModel = this.state.businessModel
        const personas = this.state.personas
        const acquisitionStrategy = this.state.acquisitionStrategy
        const competitors = this.state.competitors
        const competitiveAdvantage = this.state.competitiveAdvantage
        const progress = this.state.progress
        const team = this.state.team
        const interviews = this.state.interviews
        const funding = this.state.funding
        const demoURL = this.state.demoURL
        const pitchDeckURL = this.state.pitchDeckURL
        const githubURL = this.state.githubURL
        const promoURL = this.state.promoURL
        const stage = this.state.stage
        const applicableTech = this.state.applicableTech
        const metrics = this.state.metrics
        const industry = this.state.industry
        const orientation = this.state.orientation
        const price = this.state.price
        const showContactInfo = this.state.showContactInfo
        const benefits = this.state.benefits
        const linkedInURL = this.state.linkedInURL
        const yearsOfWorkExperience = this.state.yearsOfWorkExperience
        const race = this.state.race
        const gender = this.state.gender
        const veteran = this.state.veteran
        const zipcode = this.state.zipcode
        const subjectLine = this.state.subjectLine
        const confirmationMessage = this.state.confirmationMessage
        const collaborators = this.state.collaborators

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        const orgCode = this.state.activeOrg
        let isActive = true
        let status = "Pending"
        if (_id) {
          isActive = this.state.isActive
          status = this.state.status
        }

        const consultingRate = this.state.consultingRate

        const createdAt = new Date()
        const updatedAt = new Date()

        const postObject = {
          _id, name, teaser, problem, solution, story, marketSize, businessModel, personas, acquisitionStrategy, competitors,
          competitiveAdvantage, progress, team, interviews, funding, demoURL, pitchDeckURL, githubURL, promoURL, stage,
          applicableTech, metrics, industry, orientation, price, showContactInfo, benefits, linkedInURL,
          yearsOfWorkExperience, race, gender, veteran, zipcode, subjectLine, confirmationMessage, collaborators,
          firstName, lastName, email, username, pictureURL, roleName, orgCode, isActive, status, consultingRate,
          createdAt, updatedAt
        }

        Axios.post('/api/business-ideas', postObject)
        .then((response) => {
          console.log('attempting to save business idea')
          if (response.data.success) {
            console.log('saved business idea', response.data)

            let successMessage = 'Your business idea has been submitted!'
            if (_id) {
              successMessage = 'Your business idea has been updated!'
            }
            this.setState({ successMessage, isSaving: false })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });

      }
    }

    passData(data) {
      console.log('passData called', data)

      if (data) {
        this.setState(data)
        // this.pullProfileInfo(data.emailId,this.state.activeOrg,this.state.orgFocus)

        const emailId = data.emailId
        const cuFirstName = data.cuFirstName
        const cuLastName = data.cuLastName
        const username = data.username
        const pictureURL = data.pictureURL
        const activeOrg = data.activeOrg
        const roleName = data.roleName

        this.setState({ emailId, cuFirstName, cuLastName, username, pictureURL, activeOrg, roleName })
      }

    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        let importedEmailId = null
        let cuFirstName = ''
        let cuLastName = ''
        let emailId = ''
        let password = ''
        let roleName = null
        let roleNameLinked = null
        const otherRoleName = null

        // let phoneNumber = null
        // let accountCode = null
        let loginType = "SignUp"
        let loggedIn = false

        this.setState({ isSavingModal: false, modalIsOpen: false, successMessage: returnedValue.message,
          importedEmailId, cuFirstName, cuLastName, emailId, password, roleName, roleNameLinked, otherRoleName,
          loginType, loggedIn
        })

        if (this.state._id || window.location.pathname.includes('/app')) {
          this.props.navigate('/business-ideas')
        }

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    render() {

      return (
          <div>
            <div className="spacer" />
            <div className="calc-column-offset-30">
              {(this.state._id) ? (
                <p className="heading-text-2 full-width">{(this.state.name) ? this.state.name : "Your Business Idea"}</p>
              ) : (
                <p className="heading-text-2 full-width">Monetize {(this.state.name) ? this.state.name : "Your Business Idea"}</p>
              )}
              <p className="top-margin">80% of the purchase price goes to you and you retain 3% of the company. <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showMonetizeDescription: true })}>Learn more</button>.</p>
            </div>
            <div className="fixed-column-30 top-margin">
              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showMonetizeDescription: true })}>
                <img src={infoIconDark} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Learn how you can monetize your idea on Guided Compass"/>
              </button>
              <ReactTooltip id="tooltip-placeholder-id" />
            </div>
            <div className="clear" />

            <div className="spacer" /><div className="spacer" />

            {(this.state.importedEmailId) ? (
              <div>
                <div className="row-10">
                  <div className="fixed-column-30">
                    <img src={(this.state.pictureURL) ? this.state.pictureURL : checkmarkIcon} alt="GC" className="profile-thumbnail-20" />
                  </div>
                  <div className="calc-column-offset-30">
                    <p className="description-text-3 top-padding-3">You're logged in as {this.state.emailId}. If this isn't you, <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal}>log out</button>.</p>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" /><div className="spacer" />

                <hr />

                <div className="spacer" />

                <div className="row-10">

                  <div className="container-left">
                    <label className="profile-label">Name of Idea <label className="error-color">*</label></label>
                    <input className="text-field" maxlength="40" type="text" placeholder="(e.g., Yam Ripener)" name={"name"} value={this.state.name} onChange={this.formChangeHandler} />
                    <label className="profile-descriptor">40 character max</label>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Stage <label className="error-color">*</label></label>
                    <select name="stage" value={this.state.stage} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.stageOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <label className="profile-label">Teaser (i.e., summary)<label className="error-color">*</label></label>
                  <textarea className="text-field" maxlength="120" type="text" placeholder="Add a teaser" name={"teaser"} value={this.state.teaser} onChange={this.formChangeHandler} />
                  <label className="profile-descriptor">120 character max</label>
                </div>

                <div className="row-10">
                  <label className="profile-label">Problem It Solves <label className="error-color">*</label></label>
                  <textarea className="text-field" type="text" placeholder="(e.g., the problem is that 10% of americans...)" name={"problem"} value={this.state.problem} onChange={this.formChangeHandler} />
                  <label className="profile-descriptor">Be very detailed; share data and examples.</label>
                </div>
                <div className="row-10">
                  <label className="profile-label">Solution Detailed Description <label className="error-color">*</label></label>
                  <textarea className="text-field" type="text" placeholder="(e.g., we make yams ripe faster through...)" name={"solution"} value={this.state.solution} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Market Size and Growth <label className="error-color">*</label></label>
                  <textarea className="text-field" type="text" placeholder="(e.g., yam ripining solutions is an $50MM market growing at 7% annually)" name={"marketSize"} value={this.state.marketSize} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Customer Acquisition Strategy <label className="error-color">*</label></label>
                  <textarea className="text-field" type="text" placeholder="(e.g., we have relationships with all the top distributors through my uncle's business...)" name={"acquisitionStrategy"} value={this.state.acquisitionStrategy} onChange={this.formChangeHandler} />
                </div>
                <div className="row-10">
                  <div>
                    <div className="float-left right-padding">
                      <label className="profile-label">Competitors <label className="error-color">*</label></label>
                    </div>
                    <div className="float-left top-margin">
                      <button className="background-button" onClick={() => this.addItem('competitors')}>
                        <div className="cta-border padding-5 circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-12" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.competitors && this.state.competitors.length > 0) && (
                    <div>
                      {this.state.competitors.map((value, optionIndex) =>
                        <div key={value} className="row-5">
                          <div className="fixed-column-30 top-margin-50">
                            <button className="background-button" onClick={() => this.deleteItem('competitors',optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="container-left">
                              <label className="profile-label">Name</label>
                              <input type="text" className="text-field" placeholder="Name of competitor..." name={"competitors|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Link to Website</label>
                              <input type="text" className="text-field" placeholder="Link to competitor's website..." name={"competitors|url|" + optionIndex} value={value.url} onChange={this.formChangeHandler}></input>
                              {(value.url && !value.url.startsWith('http')) && (
                                <div>
                                  <p className="error-color top-margin-5">Please start the link to the website with http</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="row-10">
                  <label className="profile-label">Competitive Advantage <label className="error-color">*</label></label>
                  <textarea className="text-field" type="text" placeholder="(e.g., it's that distributor relationship. no other competitors have the relationship that that gets us insight on the market and access to most influential customers...)" name={"competitiveAdvantage"} value={this.state.competitiveAdvantage} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Business Model</label>
                  <textarea className="text-field" type="text" placeholder="(i.e., how do you make money? Who do you charge? And how much?" name={"businessModel"} value={this.state.businessModel} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Customer Persona(s)</label>
                  <textarea className="text-field" type="text" placeholder="(e.g., we have three customer personas, each have very different diets...)" name={"personas"} value={this.state.personas} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Story Behind Why You Were Drawn To This Solution</label>
                  <textarea className="text-field" type="text" placeholder="(e.g., once upon a time, I loved yams...)" name={"story"} value={this.state.story} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <label className="profile-label">Validation/Progress</label>
                  <textarea className="text-field" type="text" placeholder="Describe the validation of the hypothesis and other progress made..." name={"progress"} value={this.state.progress} onChange={this.formChangeHandler} />
                </div>
                <div className="row-10">
                  <label className="profile-label">Why should we trust you?</label>
                  <textarea className="text-field" type="text" placeholder="Describe why you and/or your team have sound judgement in this particular entrepreneurial space. Did you previously work in this space?..." name={"team"} value={this.state.team} onChange={this.formChangeHandler} />
                </div>

                <div className="row-10">
                  <div>
                    <div className="float-left right-padding">
                      <label className="profile-label">Funding</label>
                    </div>
                    <div className="float-left top-margin">
                      <button className="background-button" onClick={() => this.addItem('funding')}>
                        <div className="cta-border padding-5 circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-12" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.funding && this.state.funding.length > 0) && (
                    <div>
                      {this.state.funding.map((value, optionIndex) =>
                        <div key={value} className="row-5">
                          <div className="fixed-column-30 top-margin-50">
                            <button className="background-button" onClick={() => this.deleteItem('funding',optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="fixed-column-90 right-padding">
                              <label className="profile-label">Amount</label>
                              <input type="number" className="number-field" placeholder="0" name={"funding|amount|" + optionIndex} value={value.amount} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="calc-column-offset-250">
                              <label className="profile-label">Type of Funding</label>
                              <select name={"funding|type|" + optionIndex} value={value.type} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.fundingTypeOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>

                            <div className="fixed-column-160 left-padding">
                              <label className="profile-label">Date of Funding</label>
                              <input type="date" className="date-picker" placeholder="Date..." name={"funding|fundingDate|" + optionIndex} value={value.fundingDate} onChange={this.formChangeHandler}></input>
                            </div>

                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/*
                <div className="row-10">
                  <label className="profile-label">Category</label>
                  <select name="category" value={this.state.category} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.categoryOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>*/}

                <div className="row-10">
                  <label className="profile-label">Applicable Tech (Select All That Applies)</label>
                  {this.state.applicableTechOptions.map((value, optionIndex) =>
                    <div key={value} className="float-left top-margin right-margin">
                      <button className="background-button" onClick={() => this.toggleItem('applicableTech',optionIndex)}>
                        <div className={(this.state.applicableTech && this.state.applicableTech.includes(value)) ? "tag-container-6 cta-background-color cta-border white-text" : "tag-container-6 cta-border cta-color"}>
                          <p>{value}</p>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div>
                    <div className="float-left right-padding">
                      <label className="profile-label">Add Up to 5 Key Performance Metric(s) by Order of Importance</label>
                    </div>
                    <div className="float-left top-margin">
                      <button className="background-button" onClick={() => this.addItem('metrics')}>
                        <div className="cta-border padding-5 circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-12" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.metrics && this.state.metrics.length > 0) && (
                    <div>
                      {this.state.metrics.map((value, optionIndex) =>
                        <div key={value} className="row-5">
                          <div className="fixed-column-30 top-margin-50">
                            <button className="background-button" onClick={() => this.deleteItem('metrics',optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="calc-column-offset-80 right-padding">
                              <div className="container-left">
                                <label className="profile-label">Type</label>
                                <select name={"metrics|type|" + optionIndex} value={value.type} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.metricsTypeOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="container-right">
                                <label className="profile-label">Name</label>
                                <input type="text" className="text-field" placeholder="Name, if different from type..." name={"metrics|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="fixed-column-80">
                              <label className="profile-label">Value</label>
                              <input type="number" className="number-field" placeholder="0" name={"metrics|value|" + optionIndex} value={value.amount} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Industry <label className="error-color">*</label></label>
                    <select name={"industry"} value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.industryOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Customer Orientation <label className="error-color">*</label></label>
                    <select name={"orientation"} value={this.state.orientation} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.orientationOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />

                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Consulting Rate (Hourly)</label>
                    <input type="number" className="number-field" placeholder="0" name={"consultingRate"} value={this.state.consultingRate} max="1000" onChange={this.formChangeHandler}></input>
                    <label className="profile-descriptor">What hourly rate will you charge to consult on this project?</label>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Will You Allow Customers to See Your Email? <label className="error-color">*</label></label>
                    <select name={"showContactInfo"} value={this.state.showContactInfo} onChange={this.formChangeHandler} className="dropdown">
                      {['','Yes','No'].map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />

                </div>

                <div className="row-10">
                  <label className="profile-label">What else do you want from individual(s) who adopt your idea?</label>
                  {this.state.benefitsOptions.map((value, optionIndex) =>
                    <div key={value} className="float-left top-margin right-margin">
                      <button className="background-button" onClick={() => this.toggleItem('benefits',optionIndex)}>
                        <div className={(this.state.benefits && this.state.benefits.includes(value)) ? "tag-container-6 cta-background-color cta-border white-text" : "tag-container-6 cta-border cta-color"}>
                          <p>{value}</p>
                        </div>
                      </button>
                    </div>
                  )}
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Your LinkedIn Profile <label className="error-color">*</label></label>
                    <input type="text" className="text-field" placeholder="(e.g., https://linkedin.com/in/..." name={"linkedInURL"} value={this.state.linkedInURL} onChange={this.formChangeHandler}></input>
                    {(this.state.linkedInURL && !this.state.linkedInURL.startsWith('http')) && (
                      <div>
                        <p className="error-color top-margin-5">Please start the link with http</p>
                      </div>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Number of Years of Work Experience <label className="error-color">*</label></label>
                    <input type="number" className="number-field" placeholder="0" name={"yearsOfWorkExperience"} value={this.state.yearsOfWorkExperience} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Race</label>
                    <select name={"race"} value={this.state.race} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.raceOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Gender</label>
                    <select name={"gender"} value={this.state.gender} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.genderOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Veteran</label>
                    <select name={"veteran"} value={this.state.veteran} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.veteranOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Location (Zip Code)</label>
                    <input type="number" className="number-field" placeholder="0" name={"zipcode"} value={this.state.zipcode} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-15">
                  <div>
                    <div className="float-left right-padding">
                      <label className="profile-label">Tag Collaborators on this Idea (If Applicable)</label>
                    </div>
                    <div className="float-left top-margin">
                      <button className="background-button" onClick={() => this.addItem('collaborators')}>
                        <div className="cta-border padding-5 circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-12" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.collaborators && this.state.collaborators.length > 0) && (
                    <div>
                      {this.state.collaborators.map((value, optionIndex) =>
                        <div key={value} className="row-5">
                          <div className="fixed-column-30 top-margin-50">
                            <button className="background-button" onClick={() => this.deleteItem('collaborators',optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="container-left">
                              <label className="profile-label">Name</label>
                              <input type="text" className="text-field" placeholder="Jon Schmoe" name={"collaborators|name|" + optionIndex} value={value.name} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Email</label>
                              <input type="text" className="text-field" placeholder="jonschmoe@gmail.com" name={"collaborators|email|" + optionIndex} value={value.email} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row-15">
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.setState({ certified: !this.state.certified })}>
                      <img src={(this.state.certified) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="calc-column-offset-30">
                    <p>I certify that this was my idea as well as any of the above additional tagged persons.</p>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" /><div className="spacer" />

                <hr />

                <div className="row-10">
                  <div className="row-10">
                    <div>
                      <div className="float-left">
                        <p className="heading-text-4">Executional Assets</p>
                      </div>
                      {/*
                      <div>
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showHandlebarsInfo: true })}>
                            <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>*/}
                    </div>

                    <div className="clear" />
                    <p className="description-text-2 standard-color bottom-margin">Assets that get the ball rolling for the purchaser.</p>

                    <div className="row-15">
                      <div>
                        <div className="float-left right-padding">
                          <label className="profile-label">Customer Interviews</label>
                        </div>
                        <div className="float-left top-margin">
                          <button className="background-button" onClick={() => this.addItem('interviews')}>
                            <div className="cta-border padding-5 circle-radius">
                              <img src={addIconBlue} alt="GC" className="image-auto-12" />
                            </div>
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.interviews && this.state.interviews.length > 0) && (
                        <div>
                          {this.state.interviews.map((value, optionIndex) =>
                            <div key={value} className="row-5">
                              <div className="fixed-column-30 top-margin-15">
                                <button className="background-button" onClick={() => this.deleteItem('interviews',optionIndex)}>
                                  <img src={deniedIcon} alt="GC" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="calc-column-offset-30">
                                <label className="profile-label">Who did you interview?</label>
                                <textarea type="text" className="text-field" placeholder="(e.g., Who are they? Where are they? What do they do?)..." name={"interviews|interviewee|" + optionIndex} value={value.interviewee} onChange={this.formChangeHandler} />

                                <div className="top-margin-20">
                                  <label className="profile-label">What were the primary insights? Pain points? Please provide quotes.</label>
                                  <textarea className="text-field" type="text" placeholder="(e.g., Tommy spends 12+ hours manually checking on yams and he hates it with a passion)" name={"interviews|insights|" + optionIndex} value={value.insights} onChange={this.formChangeHandler} />
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Link to Demo Video(s)</label>
                        <input type="text" className="text-field" placeholder="Link to product demo..." name={"demoURL"} value={this.state.demoURL} onChange={this.formChangeHandler}></input>
                        {(this.state.demoURL && !this.state.demoURL.startsWith('http')) && (
                          <div>
                            <p className="error-color top-margin-5">Please start the link with http</p>
                          </div>
                        )}
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Link to Pitch Deck</label>
                        <input type="text" className="text-field" placeholder="Link to pitch deck..." name={"pitchDeckURL"} value={this.state.pitchDeckURL} onChange={this.formChangeHandler}></input>
                        {(this.state.pitchDeckURL && !this.state.pitchDeckURL.startsWith('http')) && (
                          <div>
                            <p className="error-color top-margin-5">Please start the link with http</p>
                          </div>
                        )}
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Link to GitHub (Or other code repository)</label>
                        <input type="text" className="text-field" placeholder="Link to code repository..." name={"githubURL"} value={this.state.githubURL} onChange={this.formChangeHandler}></input>
                        {(this.state.githubURL && !this.state.githubURL.startsWith('http')) && (
                          <div>
                            <p className="error-color top-margin-5">Please start the link with http</p>
                          </div>
                        )}
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Link to Promo Video(s)</label>
                        <input type="text" className="text-field" placeholder="Link to promotional video..." name={"promoURL"} value={this.state.promoURL} onChange={this.formChangeHandler}></input>
                        {(this.state.promoURL && !this.state.promoURL.startsWith('http')) && (
                          <div>
                            <p className="error-color top-margin-5">Please start the link with http</p>
                          </div>
                        )}
                      </div>
                      <div className="clear" />
                    </div>

                  </div>
                </div>

                <div className="spacer" />

                <hr />

                <div className="row-10">
                  <div className="row-10">
                    <div>
                      <div className="float-left">
                        <p className="heading-text-4">Price to Access <label className="error-color">*</label></p>
                      </div>
                      {/*
                      <div>
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showHandlebarsInfo: true })}>
                            <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>*/}
                    </div>

                    <div className="clear" />
                    <p className="description-text-2 standard-color bottom-margin">The price to access is calculated based on idea stage and presence of executional assets. View the price chart <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showPriceCalculation: true })}>here</button>.</p>

                    <div className="row-10">
                      <p className="heading-text-2 cta-color">${this.state.price}</p>
                    </div>

                    {/*<p className="profile-descriptor"><label className="bold-text">$50</label> [($50 x stage level) + ($50 + qualifying executional asset count)]</p>*/}
                  </div>
                </div>

                <hr />

                <div className="row-10">
                  <div className="row-10">
                    <div>
                      <div className="float-left">
                        <p className="heading-text-4">Customize Confirmation Message <label className="error-color">*</label></p>
                      </div>
                      <div>
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showHandlebarsInfo: true })}>
                            <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="clear" />
                    <p className="description-text-2 standard-color bottom-margin">This message will go out to those purchase your idea.</p>

                    <div className="row-10">
                      <label className="profile-label">Subject Line</label>
                      <input type="text" className="text-field" placeholder={"Thank you for purchasing my business idea"} name="subjectLine" value={this.state.subjectLine} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="row-10">
                      <label className="profile-label">Message Body</label>
                      <textarea type="text" className="text-field" placeholder={"Thank you for purchasing my business idea...."} name="confirmationMessage" value={this.state.confirmationMessage} onChange={this.formChangeHandler}></textarea>
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                    <div className="row-10">
                      <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.handleSubmit()}>{(this.state._id) ? "Update" : "Submit"}</button>
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              <div className="top-margin-20">
                {(this.state.loginType === 'SignUp') ? (
                  <div>
                    <div className="row-10">
                      <p className="top-padding-5">Sign Up to Submit Your Business Idea</p>
                    </div>

                  </div>
                ) : (
                  <div>
                    <div className="row-10">
                      <p className="top-padding-5">Login to Submit Your Business Idea</p>
                    </div>
                  </div>
                )}

                <SubLogInFields history={this.props.navigate}
                  formChangeHandler={this.formChangeHandler} passData={this.passData}
                  onlyFields={true} logout={this.logout} type={this.state.loginType}
                  loggedIn={this.state.loggedIn} emailId={this.state.emailId}
                  orgCode={this.state.activeOrg} closeModal={this.props.closeModal}
                  skipCloseModal={true}
                />

                {(this.state.loginType === 'SignUp') ? (
                  <div className="row-10">
                    <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >Log In</button> to import your information</p>
                  </div>
                ) : (
                  <div className="row-10">
                    <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                  </div>
                )}

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
            >
              {(this.state.showHandlebarsInfo) && (
                <div className="padding-40">
                  <p className="heading-text-2">Confirmation Messages and Handlebars Explained</p>
                  <div className="spacer" /><div className="spacer" />
                  <p>You can tailor the confirmation messages career-seekers receive after they RSVP, submit a project, or submit an application. You can also set variables in the message using the Handlebars (i.e., {"{{ Variable Name }}"}) methodology. Below is a list of available variables:</p>

                  <div className="spacer" /><div className="spacer" />

                  <p className="top-padding"><label className="bold-text">{"{{ First Name }}"}</label> -> <label className="left-padding">[assigned to purchaser]</label></p>
                  <p className="top-padding"><label className="bold-text">{"{{ Idea Name }}"}</label> -> <label className="left-padding">[i.e., {this.state.name}]</label></p>

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}
              {(this.state.showMonetizeDescription) && (
                <div className="padding-40">
                  <p className="heading-text-2">How it Works</p>
                  <div className="spacer" /><div className="spacer" />
                  <p>You receive 80% of all revenue from individuals or institutions that purchase your idea. Institutions (e.g., entrepreneurship training programs) can bulk-purchase for multiple people. So, if you price your idea at $100, and 1,000 access instances were purchased, you would make $75,000[$100 x 1,000 x 75%] plus any benefits you require. Benefits can include a seat on the board, credit on the about page, and more!</p>

                  <p>In addition, once someone purchase your idea, they will sign agreement that gives you 3% of the business should they choose to pursue it as well as signing a non-disclosure agreement.</p>

                  <p className="top-margin-30">In order to be listed, fill out the form and submit your request. A member of the Guided Compass team will review your submission, and either accept it or reject it with comments. You can resubmit if your idea was rejected. Once accepted, your idea is listed in the marketplace for people to discover and purchase.</p>

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}
              {(this.state.showPriceCalculation) && (
                <div className="padding-40">
                  <p className="heading-text-2">Price Calculation</p>
                  <div className="spacer" /><div className="spacer" />
                  <p>In order to give cusotmers a streamlined experience, prices are currently fixed to a formula. Our current formula is the following:</p>

                  <p className="top-margin-30">${this.state.stageLevelPrice} for each progression in stage (excluding other) and $50 for each executional asset shared. There must be at least three customer interviews for it to be counted as an executional asset. So, for example, if the stage is "Revenue-Generating" and there is a viable demo and project, then the price would be $200 [(4 x ${this.state.stageLevelPrice}) + (2 x ${this.state.executionalAssetPrice})].</p>

                  <p className="top-margin-30">If you think this formula is incorrect or should be abolished, you may <Link to="/contact">contact us</Link> to suggest a change!</p>

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}

            </Modal>

          </div>
      )
    }
}


export default withRouter(EditBusinessIdea);
