import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

const purposeDiagram = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/purpose-diagram.png";
const creightonTaylor = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/creighton_taylor.png';
const paulDawson = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paul_dawson.png";
const sebastianDeVivo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/sebastian_de_vivo.png";
const staceyGordon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stacey_gordon.png";
const anitaDeFrantz = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/anita_defrantz.jpg";
const larryTaylor = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/larry_taylor.jpg";
const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";

class AboutPage extends Component {
    constructor(props) {
      super(props)

      this.state = {

        leaders: [],
        posts: [],
        filteredPosts: [],

        selectedIndex: 0

      }
    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

      const leaders = [
        { pic: creightonTaylor, name: 'Creighton Taylor, CFA', title: 'Chief Executive Officer', headline: 'Harvard MBA Grad, Emory BBA, CFA Holder', background: 'Product manager for various startups, prior investment professional, launched 10+ apps, founder of four workforce development organizations.'},
        { pic: paulDawson, name: 'Paul Dawson', title: 'Chief Technology Officer', headline: 'MIT Engineering Grad, Full-Stack Developer', background: 'Worked as software engineer for large companies and startups, including Cox Automotive and RentPath.'},
        { pic: sebastianDeVivo, name: 'Sebastian De Vivo, PhD', title: 'Product Advisor', headline: 'Stanford PhD, Future of Work Specialist', background: 'Procured $30MM in Future of Work grants with DOL and local agencies, Advisor at Bixel Exchange and StartOut.'},
        { pic: staceyGordon, name: 'Stacey Gordon', title: 'HR & Recruiting Advisor', headline: 'Pepperdine MBA, Forbes Coaching Council', background: 'Recruiting courses have received 1MM+ views, named 40 under 40, UCLA Coding Bootcamp advisor.'},
        { pic: anitaDeFrantz, name: 'Anita DeFrantz', title: 'Legal Advisor', headline: 'UPenn Law Grad, IOC Member', background: 'Vice President of International Olympic Committee, Former CEO of LA84 Foundation, Two-Time Olympic Medal Winner. Named one of the "The 100 Most Powerful Women in the World.'},
        { pic: larryTaylor, name: 'Larry Taylor, PhD', title: 'Strategy Advisor', headline: 'Claremont PhD, MBA from Pepperdine, Corporate Director', background: 'Former productivity consulting practice leader for EY & Deloitte. Former CFO of OBN Holdings (OBNI:OTC). Former professor of California State University, LA, CEO of National Association of Corporate Directors - Pacific Southwest.'}
      ]

      this.setState({ leaders })

      Axios.get('/api/blog-posts')
      .then((response) => {
        console.log('Blog post query attempted', response.data);

        if (response.data.success) {
          console.log('blog post query worked')

          if (response.data.posts.length !== 0) {
            let posts = response.data.posts
            // for (let i = 1; i <= response.data.posts.length; i++) {
            //   if (response.data.posts[i - 1].slug === 'how-we-complement-portfolium' || response.data.posts[i - 1].slug === 'why-found-guided-compass?' || response.data.posts[i - 1].slug === 'what-are-guided-compass-assessments?') {
            //     posts.push(response.data.posts[i - 1])
            //     console.log('testing 1', posts[i - 1])
            //   }
            // }

            const filteredPosts = posts
            console.log('show filteredPosts: ', filteredPosts)
            this.setState({ posts, filteredPosts});
          }

        } else {
          console.log('Blog posts query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Blog posts query did not work for some reason', error);
      });

    }

    renderSection(sectionName) {
      console.log('renderSection called', sectionName)

      if (sectionName === 'whatIsGuidedCompass') {
        return (
          <div>

            <div className="row">
              <h2>What is Guided Compass</h2>
            </div>

            <div className="row-10">

              <div className="top-padding-15 bottom-padding-5">
                <iframe
                  title="videoLink"
                  className="video-iframe-4"
                  src={'https://www.youtube.com/embed/YUG5AaPA308'}
                  frameBorder="0"
                />
              </div>

              <div className="spacer" />

              <p className="top-padding">Guided Compass is a lifelong career development platform that helps with clarifying and coordinating career pathways, career planning, making progress toward individuals' goals, and gaining social capital/advocates for opportunities (i.e., referrals). Specifically, for intermediaries (e.g., schools, workforce, staffing) and employers, we make advising people, aligning stakeholders, and hiring people far easier and more effective. We integrate with many external systems (e.g., LMS, Course Platforms, ATSs, Labor Market Data providers, etc...) to make this process seamless.</p>

              <div className="spacer" />

              <img src={purposeDiagram} alt="GC" className="image-half-auto center-horizontally" />

              <div className="spacer" />

              <p className="top-padding">We help career-seekers track what they love, what they’re good at, what the world needs, and what they can be paid for! Our goal is to recommend opportunities for progress based on these quadrants. Career-seekers can share this data with intermediaries so that they can better help them and recommend opportunities.</p>

              <p className="top-padding-20">To get started, we recommend on the role that best suits you: <Link to="/career-seekers">career-seeker</Link>, <Link to="/educators">educator</Link>, <Link to="/workforce">workforce</Link>, <Link to="/mentors">mentor</Link>, or <Link to="/employers">employer</Link>.</p>

              <div className="spacer" />

            </div>
          </div>
        )
      } else if (sectionName === 'howItWorks') {
        return (
          <div>
            <div className="row">
              <h2>How Guided Compass Works</h2>
            </div>

            <div className="row-10">

              <p className="top-padding-20">Guided Compass has five user portals that interrelate: 1) the career-seeker (includes students and employees) portal; 2) the educator/counseling portal, 3) the workforce administrator portal, 4) the mentor (e.g., parents) portal; and 5) employer (e.g., HR) portal. We’ve launched a <Link className="bold-text" to={'/help'}>Help Page</Link> to provide articles / video demos of core product features. Employers and workforce organizations can design the profile of their ideal candidates (i.e., benchmark) by pathway/department. Our algorithms tie to this benchmark, allowing career-seekers to see how they can improve their candidacy for opportunities, and allowing advocates to guide and recommend career-seekers when appropriate.</p>

              <div>
                <div className="top-padding-30">
                  <p><label className="bold-text">For the career-seeker</label>, you can use the Guided Compass platform to explore career paths, set goals, collaborate on projects, make progress toward your goals, and get referred for relevant opportunities. Guided Compass uses its proprietary algorithms to provide recommendations for career paths, courses, potential teammates/advocates, events, work opportunities, and more. You can use the platform features in any order; use all or some of the features.  You can store that career-related information about yourself, continuously update it for the rest of your life, import it into applications for opportunities, and share it with select individuals you trust.</p>
                </div>

                <div className="top-padding-30">
                  <label className="bold-text">For the educators</label>, there are three primary benefits of Guided Compass – it can help increase enrollment, it makes your life easier, and it improves outcomes. For enrollment, Guided Compass works with employers, workforce boards, and other organizations to hone career pathways. This allows you to align courses to the core competencies listed. You can essentially advertise your program/course to those who have explicitly set a goal to gain the competencies you teach.  In terms of making your life easier, Guided Compass comes with 700+ hours of pre-packaged lesson plans that you can assign to students for career awareness, career exploration, career preparation, project-based learning, and work-based learning. In addition, Guided Compass endorsements act as reusable letters of recommendation that are imported to applications for projects, internships, and job opportunities. Lastly, Guided Compass improves outcomes by giving students motivation to study, allowing them to get feedback on their projects from industry professionals, and helping them land work opportunities.
                </div>

                <div className="top-padding-30">
                  <label className="bold-text">For the workforce development professionals</label>, there are two primary benefits of Guided Compass – efficiency enhancement and improving the outcomes of the career-seekers they advise. For efficiency, Guided Compass automates communications necessary across different user types (e.g., career-seekers and employers), provides tools to all parties, and provides comprehensive reporting. It saves numerous hours of administration and documentation time. For outcomes, benchmarks and the associated algorithm give scores to career-seekers for pathways and job opportunities so that candidates can more easily improve their candidacy and be referred, and so that employers get “better-fit” candidates. We also offer a variety of tools like our resume builder, financial planner, course finder, and communities to engage and better prepare candidates.
                </div>

                <div className="top-padding-30">
                  <label className="bold-text">For the employer</label>, Guided Compass is used to engage and upskill both internal and external talent. The platform allows employers to build custom talent pipelines with benchmarks, clarifying the career pathways for both internal and external candidates.  Employers can post employment opportunities on the Guided Compass platform, design their ideal candidate profiles, and engage their talent pipeline by inviting them to events and to solve internal problems, manage employee performance, more effectively partner with local workforce/staffing organizations, and more. Used effectively, Guided Compass improves employee retention and satisfaction.
                </div>
              </div>
            </div>
          </div>
        )
      } else if (sectionName === 'testimonials') {
        return (
          <div>
            <div className="row">
              <h2>Testimonials</h2>
            </div>

            <div className="top-padding-30">
              <p>Below are some testimonials from a workforce development organization and educational institution, respectively:</p>
            </div>

            <div className="top-padding-30">
              <div className="relative-column-50 padding-20 standard-border">
                <p className="italicize-text bold-text description-text-2">“I’ve met with Creighton weekly over the last few years to create a custom work-based learning portal for both Bixel Exchange and UNITE-LA. We’ve missed few meetings because we are both passionate about the space. I’m consistently impressed with the speed at which new, quality features are added to help us and the opportunity youth we serve. Many of the new features were conceived by him, going over and above our contract. From big-picture ideas to improving processes to fixing bugs, Creighton and the Guided Compass team have truly been a partner in our mission. I would highly recommend Guided Compass for any career development or work-based learning program.”</p>
                <div className="spacer" />
                <div className="description-text-4 bold-text">
                  <p>Jaideep Upadhyay</p>
                  <p>Program Manager</p>
                  <p>UNITE-LA</p>
                  <p>jupadhyay@unitela.com</p>
                </div>
              </div>
              <div className="relative-column-50 padding-20 standard-border">
                <p className="italicize-text bold-text description-text-2">“Guided Compass was very helpful during our career exploration workshops. It led to a more cohesive experience, and the real-world projects and work opportunities nicely tied the lessons together. The founder was also very high-touch, answering questions and quickly implementing recommended changes. He has become a valued member of our advisory board and has provided useful ideas and feedback to continue improving our curriculum. It's impressive that he was able to get Guided Compass to pass through LAUSD's stringent UDIPP process. Few technology products make it through this process due to the stringent privacy and security measures for the safety of student data.”</p>
                <div className="spacer" />
                <div className="description-text-4 bold-text">
                  <p>Gabriela Rosillo</p>
                  <p>CTE Arts and Design Academy Lead & Teacher<label className="error-color">*</label></p>
                  <p>Grover Cleveland High School</p>
                  <p>rosillo.theaoat@gmail.com</p>
                </div>
              </div>
              <div className="clear" />
            </div>

            <div className="top-padding-30">
              <div className="float-right description-text-3">
                <p><label className="error-color">*</label> Distinguished 2021 California Partnership Academy Winner</p>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (sectionName === 'foundersStory') {
        return (
          <div>
            <div className="row">
              <h2>History</h2>
            </div>

            <div className="bottom-padding">
              {/*
              <div className="top-padding-15 bottom-padding-5">
                <iframe
                  title="videoLink"
                  className="video-iframe-4"
                  src={'https://www.youtube.com/embed/7D9qsY6JG_k'}
                  frameBorder="0"
                />
              </div>*/}

              <div className="clear" />

              <p className="top-padding">The founder of the Guided Compass technology platform is Creighton Taylor. After four years in the investment industry and after attending Harvard Business School, where 30%+ students were identified as “career-switchers”, Creighton realized that there was a real need for a sophisticated tool to help solve this issue. Creighton experienced a career switch from finance to information technology during a period of increasing concerns about artificial intelligence, which threatened to change the future labor markets. Due to this insight that there is a need to bring personalized insights for the 99% looking to optimizing earned income, Creighton started Guided Compass.</p>

              <p className="top-padding-30">Guided Compass started in 2018, piloting with a few programs. The most notable program was a 2-year pilot with the LA Area Chamber of Commerce's Bixel Exchange LA Tech Talent Pipeline program. The below shows the learning from this pilot, which became the foundation of Guided Compass today:</p>

              <div className="standard-border padding-40 top-margin-30">
                <p className="top-padding-30 bold-text heading-text-4">The Customer</p>
                <p>The LA Tech Talent Pipeline (LATTP) was launched in partnership with Mayor Garcetti to engage thousands of high school and community college students in work-based learning and paid tech internships. LATTP partnered with 60 Los Angeles-based technology companies to place interns from 19 community colleges, 30 high schools, and 30 community-based organizations. It had thousands of students sign up with little-to-no experience that they needed to place in “best-fit” opportunities. The majority of students were placed into design, marketing, or software development career tracks for work-based learning opportunities.</p>

                <p className="top-padding-30 bold-text heading-text-4">The Problem</p>
                <p className="top-padding">LATTP had a multitude of problems that we addressed:</p>
                <ul className="left-padding-25">
                  <li>LATTP had thousands of entry-level applicants without a reliable screening / matching mechanism for recommending applicants.</li>
                  <li>LATTP has a lean staff with a multi-step career pathway approval process and many repetitive tasks within that process.</li>
                  <li>LATTP needed to make the case to employers that non-traditional candidates are worth the time and monetary investment.</li>
                </ul>

                <p className="top-padding-30">Their initial process looked like the following:</p>
                <ul className="left-padding-25">
                  <li>LATTP would collect student and employer information via Google Forms</li>
                  <li>LATTP would evaluate candidates based on resume and adversity information manually within Excel</li>
                  <li>LATTP would recommend 3-5 candidates to each internship posting</li>
                  <li>LATTP would follow up with employer for feedback and outcomes data</li>
                </ul>

                <p className="top-padding-30">While this process was initially sustainable for a team of 3 people, as the program grew and thousands of people applied, it became difficult to refer the right candidates and coordinate with all parties throughout the process in a timely manner. Because Bixel Exchange was allocated the ability to offer subsidized internships to startups, the problem accelerated because the number of employers interested in internships skyrocketed. Bixel Exchange needed to keep all parties informed and happy while maximizing successful outcomes.</p>

                <p className="top-padding-30 bold-text heading-text-4">The Solution</p>
                <p className="top-padding">We designed a youth Applicant Tracking System (ATS) for LATTP that not only automated some of the repetitive tasks like messaging, but also introduced five innovative elements:</p>
                <ol className="left-padding-25">
                  <li>Guided Compass Benchmarks</li>
                  <li>Guided Compass Profile Cards</li>
                  <li>Incorporating Career Assessments</li>
                  <li>Guided Compass Endorsements</li>
                </ol>

                <p className="top-padding-30 bold-text heading-text-6">1. Guided Compass Benchmarks</p>
                <p className="top-padding">We devised and implemented Guided Compass Benchmarks, which allowed LATTP and their employer partners define the ideal candidate profile at the pathway and opportunity levels. It expanded the ideal candidates beyond competencies into interests, personality, interviews / intangibles, projects, adversity score, employer interactions, and more. This was crucial for candidates with little experience and non-traditional backgrounds.</p>

                <p className="top-padding-30 bold-text heading-text-6">2. Guided Compass Profile Cards</p>
                <p className="top-padding">We designed profile cards to summarize and detail data that is not easily accessible on the typical entry-level resume. If anyone has reviewed an entry-level resume, you will find that it’s difficult to assess projects, intangibles, and skills. Our profile card designs provide employers and LATTP staff with a match score, summaries, and clickable sections to go into more detail:</p>
                <img src={exampleInternMatch} alt="GC" className="image-full-auto" />

                <p className="top-padding-30 bold-text heading-text-6">3. Incorporating Career Assessments</p>
                <p className="top-padding">We incorporated work preference, interest, personality, skill, and values assessments directly in the app so that candidates can be matched (using GC Benchmarks) to career pathways and internships they are likely to sustainably love. Each posted opportunity has the ideal candidates’ scores with these assessments, allowing us to match not only to career paths, but also to specific event, project, and work opportunities.</p>

                <p className="top-padding-30 bold-text heading-text-6">4. Guided Compass Endorsements</p>
                <p className="top-padding">We created and incorporated Guided Compass Endorsements, which are essentially reusable letters of recommendations from teachers and mentors for specific career pathways. Here’s the process:</p>
                <ul className="left-padding-25">
                  <li>Candidates can select a career pathway that uses GC Benchmarks to automatically populate ideal competencies</li>
                  <li>Candidates can customize which skills and knowledge they would like to be endorsed on</li>
                  <li>Endorsers can open the endorsement with everything pre-filled, rate the candidate on the specific competencies, provide examples of how the candidate demonstrated those competencies, and have the option to make the endorsement transparent or confidential.</li>
                  <li>Endorsements are sent to the candidate’s profile and imported to all internship applications for LATTP staff and employers to review.</li>
                </ul>

                <p className="top-padding-30 bold-text heading-text-4">The Results</p>
                <p className="top-padding">LATTP used Guided Compass over a 2-year period: 2,000+ students signed up, 800+ submitted their full application to the program, 600+ received actionable feedback from employers or LATTP staff, and 216 were placed into internships. 40% of those interns converted to part-time or full-time employment. 95% of those placed were considered “low-income”.</p>
              </div>

              <p className="top-padding-30">Today, Guided Compass has expanded upon these solutions substantially. We have standardized benchmarks so that career-seekers no longer necessarily need to be in a program to gain clarity on career pathways and get opportunities.</p>

            </div>
          </div>
        )
      } else if (sectionName === 'leadership') {
        return (
          <div>
            <div className="row">
              <h2>Leadership</h2>
            </div>

            <div className="row center-text">
              {this.state.leaders.map((leader, index) =>
                <div>
                  <div className="col span-1-of-2 box">
                    <img src={leader.pic} alt="Compass save time and money icon" className="profile-image-large center-horizontally" />
                    <div className="grid-container">
                      <h3 className="clear-padding">{leader.name}</h3>
                      <p className="subtitle-1">{leader.title}</p>

                      <div>
                        <div className="separating-container">
                          <p className="description-text-1">{leader.headline}</p>
                        </div>

                        <div className="separating-container">
                          <p className="description-text-1">{leader.background}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  {(index % 2 === 1) && (
                    <div className="clear"/>
                  )}
                </div>
              )}
              <div className="clear-float"/>
            </div>
          </div>
        )
      }
    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"aboutPage"} darkBackground={true} />

              <section className="section-features half-bold-text">
                <div className="row horizontal-padding-4">
                  <div className={(window.innerWidth > 768) ? "fixed-column-250 description-text-2" : "full-width description-text-2"}>
                    {(window.innerWidth > 768) ? (
                      <div>
                        <button className="background-button" onClick={() => this.setState({ selectedIndex: 0 })}><p className={(this.state.selectedIndex === 0) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>What is Guided Compass?</p></button>
                        <div className="clear" />
                        <button className="background-button" onClick={() => this.setState({ selectedIndex: 1 })}><p className={(this.state.selectedIndex === 1) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>How It Works</p></button>
                        <div className="clear" />
                        {/*
                        <button className="background-button" onClick={() => this.setState({ selectedIndex: 2 })}><p className={(this.state.selectedIndex === 2) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>Testimonials</p></button>
                        <div className="clear" />*/}
                        <button className="background-button" onClick={() => this.setState({ selectedIndex: 3 })}><p className={(this.state.selectedIndex === 3) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>History</p></button>
                        <div className="clear" />
                        <button className="background-button" onClick={() => this.setState({ selectedIndex: 4 })}><p className={(this.state.selectedIndex === 4) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>Leadership</p></button>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div>
                        <div className="full-width white-background bottom-padding-20">
                          <div className="center-horizontally clear-float width-70-percent">
                            <div className="carousel-3" onScroll={this.handleScroll}>
                              <div className="carousel-item-container">
                                <button className="background-button" onClick={() => this.setState({ selectedIndex: 0 })}><p className={(this.state.selectedIndex === 0) ? "selected-carousel-item" : "menu-button"}>What is Guided Compass?</p></button>
                                <button className="background-button" onClick={() => this.setState({ selectedIndex: 1 })}><p className={(this.state.selectedIndex === 1) ? "selected-carousel-item" : "menu-button"}>How it Works</p></button>
                                {/*<button className="background-button" onClick={() => this.setState({ selectedIndex: 2 })}><p className={(this.state.selectedIndex === 2) ? "selected-carousel-item" : "menu-button"}>Testimonials</p></button>*/}
                                <button className="background-button" onClick={() => this.setState({ selectedIndex: 3 })}><p className={(this.state.selectedIndex === 3) ? "selected-carousel-item" : "menu-button"}>History</p></button>
                                <button className="background-button" onClick={() => this.setState({ selectedIndex: 4 })}><p className={(this.state.selectedIndex === 4) ? "selected-carousel-item" : "menu-button"}>Leadership</p></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*
                        <div className="full-width white-background">
                          <div className="center-horizontally clear-float width-70-percent">
                            <div className="carousel-3" onScroll={this.handleScroll}>
                              {this.state.subNavCategories.map((value, index) =>
                                <div className="carousel-item-container">
                                  {(this.state.subNavCategories[index] === 'Work') ? (
                                    <div className="selected-carousel-item">
                                      <label key={value}>{value}</label>
                                    </div>
                                  ) : (
                                    <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                                      <label key={value}>{value}</label>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>*/}
                        <div className="clear" />
                      </div>
                    )}

                  </div>
                  <div className={(window.innerWidth > 768) ? "calc-column-offset-250" : "full-width calc-column-offset-250"}>
                    {(this.state.selectedIndex === 0) && (
                      <div>
                        {this.renderSection('whatIsGuidedCompass')}
                      </div>
                    )}
                    {(this.state.selectedIndex === 1) && (
                      <div>
                        {this.renderSection('howItWorks')}
                      </div>
                    )}
                    {(this.state.selectedIndex === 2) && (
                      <div>
                        {this.renderSection('testimonials')}
                      </div>
                    )}
                    {(this.state.selectedIndex === 3) && (
                      <div>
                        {this.renderSection('foundersStory')}
                      </div>
                    )}
                    {(this.state.selectedIndex === 4) && (
                      <div>
                        {this.renderSection('leadership')}
                      </div>
                    )}

                  </div>
                  <div className="clear" />
                </div>
              </section>
              {/*
              <section className="section-features half-bold-text">
                <div className="row horizontal-padding-3">
                  <h2>About Page</h2>

                  <div className="spacer" />

                  {this.renderSection('whatIsGuidedCompass')}


                  <div className="row-10">
                    <h4>How Guided Compass Works</h4>
                    <p className="top-padding-20">Guided Compass has five user portals that interrelate: 1) the career-seeker (students) portal; 2) the educator (e.g., counselors) portal, 3) the workforce administrator portal, 4) the mentor (e.g., parents) portal; and 5) employer (e.g., HR) portal. We’ve launched a <Link className="bold-text" to={'/help'}>Help Page</Link> and short video to explain the product. Each of these four user types has its own portal on the Guided Compass platform.  And each portal is integrated with the other types of portals to allow all Guided Compass users to support one another.  Employers can design the profile of their ideal candidates, and ultimately benefit when the candidates are authentically a strong fit for the role.</p>

                    <div>
                      <div className="top-padding-30">
                        <p><label className="bold-text">For the career seeker</label>, there are multiple ways to use the Guided Compass platform. Use the platform to select your purpose-driven career by first establishing a detailed profile of your attributes and wishes. Use the platform’s financial goal setting module to explore the financial rewards of various career options. Then Guided Compass uses its proprietary AI-based algorithms to provide recommendations for courses, project work, labor markets and training programs to prepare you for your selected career path.  You can use the platform features in any order; use all or some of the features.  You can store that career-related information about yourself and continuously update it for the rest of your life.  When you update any information, you will receive further recommendations to support your next career move.</p>

                        <p className="top-padding-20">The platform guides career seekers from career exploration through life-long development. So, whether you are planning your career while still in high school; or expanding your career options while in college or vocational schools; or about to execute your next career move as a seasoned workforce participant, Guided Compass helps you choose the best paths forward. It helps you establish and maintain your career path be it for social purpose, income stability, interest, recognition, or prestige. We help you and we help the educators, workforce professionals, mentors, and employers help you.</p>
                      </div>

                      <div className="top-padding-30">
                        <label className="bold-text">For the educators</label>, there are two primary benefits of Guided Compass – it makes your life easier, and it improves outcomes.  In terms of making your life easier, Guided Compass comes with 700+ hours of pre-packaged lesson plans that you can assign to students for career awareness, career exploration, career preparation, project-based learning, and work-based learning. Students can watch the video tutorials and answer questions as they progress through the lessons. In addition, Guided Compass endorsements act as reusable letters of recommendation that are imported to applications for projects, internships, and job opportunities. Guided Compass also improves outcomes by giving students motivation to study, allowing them to get feedback on their projects from industry professionals, and helping them land internships.
                      </div>

                      <div className="top-padding-30">
                        <label className="bold-text">For the workforce development professionals</label>, there are two primary benefits of Guided Compass – efficiency enhancement and improving the outcomes of the career seekers they advise. For efficiency, Guided Compass automates communications necessary across different user types (e.g., career seekers and employers), provides tools to all parties, and provides comprehensive reporting. It saves numerous hours of administration and documentation time. For outcomes, benchmarks and algorithm give scores to career-seekers for pathways and job opportunities so that candidates can be more easily developed and referred, and so that employers get “better-fit” candidates. We also offer a variety of tools like our resume builder, financial planner, course finder, and communities to engage and better prepare candidates.
                      </div>

                      <div className="top-padding-30">
                        <label className="bold-text">For the employer or entrepreneur</label>, Guided Compass is used to improve the human resources and talent management function of companies.  The platform allows employers to build custom talent pipelines and enhances the process when seeking to hire, train, or transfer an employee. Employers can post employment opportunities on the Guided Compass platform, design their ideal candidate profiles, and engage their talent pipeline by inviting them to events and to solve internal problems. Used effectively, Guided Compass improves employee retention and satisfaction.
                      </div>

                      <div className="top-padding-30">
                        <p>Below are some testimonials from a workforce development organization and educational institution, respectively:</p>
                      </div>

                      <div className="top-padding-30">
                        <div className="relative-column-50 padding-20 standard-border">
                          <p className="italicize-text bold-text description-text-2">“I’ve met with Creighton weekly over the last few years to create a custom work-based learning portal for both Bixel Exchange and UNITE-LA. We’ve missed few meetings because we are both passionate about the space. I’m consistently impressed with the speed at which new, quality features are added to help us and the opportunity youth we serve. Many of the new features were conceived by him, going over and above our contract. From big-picture ideas to improving processes to fixing bugs, Creighton and the Guided Compass team have truly been a partner in our mission. I would highly recommend Guided Compass for any career development or work-based learning program.”</p>
                          <div className="spacer" />
                          <div className="description-text-4 bold-text">
                            <p>Jaideep Upadhyay</p>
                            <p>Program Manager</p>
                            <p>UNITE-LA</p>
                            <p>jupadhyay@unitela.com</p>
                          </div>
                        </div>
                        <div className="relative-column-50 padding-20 standard-border">
                          <p className="italicize-text bold-text description-text-2">“Guided Compass was very helpful during our career exploration workshops. It led to a more cohesive experience, and the real-world projects and work opportunities nicely tied the lessons together. The founder was also very high-touch, answering questions and quickly implementing recommended changes. He has become a valued member of our advisory board and has provided useful ideas and feedback to continue improving our curriculum. It's impressive that he was able to get Guided Compass to pass through LAUSD's stringent UDIPP process. Few technology products make it through this process due to the stringent privacy and security measures for the safety of student data.”</p>
                          <div className="spacer" />
                          <div className="description-text-4 bold-text">
                            <p>Gabriela Rosillo</p>
                            <p>CTE Arts and Design Academy Lead & Teacher<label className="error-color">*</label></p>
                            <p>Grover Cleveland High School</p>
                            <p>rosillo.theaoat@gmail.com</p>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="top-padding-30">
                        <div className="float-right description-text-3">
                          <p><label className="error-color">*</label> Distinguished 2021 California Partnership Academy Winner</p>
                        </div>
                        <div className="clear" />
                      </div>

                    </div>
                  </div>

                  <div className="row-10">
                    <h4>Founder's Story</h4>

                    <div className="spacer" />

                    <div className="top-padding-15 bottom-padding-5">
                      <iframe
                        title="videoLink"
                        className="video-iframe-4"
                        src={'https://www.youtube.com/embed/7D9qsY6JG_k'}
                        frameBorder="0"
                      />
                    </div>

                    <div className="clear" />
                    <div className="spacer" />

                    <p className="top-padding-20">The founder and technology creator of the Guided Compass technology platform is Creighton Taylor.  For four years, Creighton worked as an analyst in an investment firm to make rich people richer. These are people with $2MM+ to invest, on average. At that level of wealth, it starts to make more sense to focus on investment income than earned income (e.g., a 5% return on $2MM gives $100K annually). To invest this money, investment managers use sophisticated tools to track and make bets on the future value of companies, governments, and countries. Yet, Creighton struggled to find equally sophisticated tools for the 99% of income earners so that they can maximize their earned income?</p>
                    <p className="top-padding-20">It was this question that inspired the genesis of Guided Compass. After four years in the investment industry and after attending Harvard Business School where 30%+ students were identified as “career-switchers”, Creighton realized that there was a real need for such a sophisticated tool. These students suffered with analysis paralysis, as they tried to identify which career opportunities out of thousands of options from which to choose. Creighton experienced a career switch from finance to information technology during a period of increasing concerns about artificial intelligence, which threatened to change the future labor markets. So, the question was expanded to how we can build a sophisticated career planning tool that accounts for the labor market changes, but also incorporates individual interests and skills which is critical for purpose driven careers.</p>
                    <p className="top-padding-20">Toward the later part of my time in business school, I realized there was a third level to the problem. Creighton began to advise family in high school and realized that it was difficult to help them without knowing their continually evolving interests, skills, and needs. After a 3 months, interests and skills of youth can change tremendously. So, how can career advisors assist career seekers as they experiment, work on projects, and explore different options. What have they tried? What did they dislike? These three elements led to Guided Compass.</p>
                    <p className="top-padding-20">During the development, the Guided Compass product underwent several iterations and enhancements.  Training data was collected and analyzed so the product more effectively recommends opportunities to people with little-to-no experience. While there were many career exploration software products out there, few actually placed candidates into entry-level roles. Benchmarks were added so that additional career seeker attributes are considered by the matching algorithm, including ideal candidates’ work preferences, interests, skills, and values to job postings and career pathways.  In addition, more projects and work-based learning opportunities were added to support pathways and job opportunities. As a result, whenever candidates are placed into roles, we gained insight into how to better match the next generation.</p>

                    <p className="top-padding-20">To help the education / work-based learning industry and share insights, Creighton wrote the 52-page whitepaper entitled <Link className="bold-text" to={'/whitepapers/how-to-optimize-wbl'}>“How to Optimize Work-Based Learning After COVID-19”</Link>.</p>
                  </div>
                </div>
              </section>

              <section className="section-demo">
                <div className="row">
                  <h2>Leadership</h2>
                </div>

                <div className="row center-text">
                  {this.state.leaders.map((leader, index) =>
                    <div>
                      <div className="col span-1-of-2 box">
                        <img src={leader.pic} alt="Compass save time and money icon" className="profile-image-large center-horizontally" />
                        <div className="grid-container">
                          <h3 className="clear-padding">{leader.name}</h3>
                          <p className="subtitle-1">{leader.title}</p>

                          <div>
                            <div className="separating-container">
                              <p className="description-text-1">{leader.headline}</p>
                            </div>

                            <div className="separating-container">
                              <p className="description-text-1">{leader.background}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                      {(index % 2 === 1) && (
                        <div className="clear"/>
                      )}
                    </div>
                  )}
                  <div className="clear-float"/>
                </div>
              </section>*/}
              {/*
              <div>
                <hr />
                <section className="section-features half-bold-text">

                  <h2 className="full-width center-text">Friends & Partners</h2>

                  <div className="flex-container row-direction horizontal-padding-3">
                    <div className="flex-50 flex-center horizontal-padding">
                      <a className="background-button" href="https://www.unitela.com" target="_blank" rel="noopener noreferrer">
                        <img src={uniteLALogo} alt="GC" className="image-half-auto center-horizontally" />
                      </a>
                    </div>
                    <div className="flex-50 flex-center horizontal-padding">
                      <a className="background-button" href="https://www.mad-learn.com" target="_blank" rel="noopener noreferrer">
                        <img src={madLearnLogo} alt="GC" className="image-half-auto center-horizontally" />
                      </a>
                    </div>
                  </div>

                </section>
              </div>*/}

              <section className="section-cta">
                <div className="cta-container">
                  <div className="cta-text-box">
                      <p className="purpose-text">Wanna</p>
                      <p className="purpose-title" >Chat?</p>
                  </div>
                  <div className="cta-buttons">
                    <div className="vertically-centered-container">
                      <Link className="btn btn-full" to={'/contact'}>Message Us</Link>
                    </div>
                  </div>
                </div>
              </section>

              {/*Footer()*/}
              <Footer history={this.props.navigate} />
          </div>

        )
    }
}

export default AboutPage;
