import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';;
import SubEndorsementDetails from '../Common/EndorsementDetails';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';

const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const videoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon-dark.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const linkedinIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/linkedin-icon-dark.png';
const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
const coverLetterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png';
const letterOfRecIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/letter-of-rec-icon.png';
const transcriptIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/transcript-icon.png';
const idIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/identification-icon.png';
const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const interviewIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/interview-icon-blue.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const incomingIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/incoming-icon.png';
const gcLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-guided-compass-logo.png';
const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const diversityIconColor = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/diversity-icon-color.png";
const confidentialityIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/confidentiality-icon.png';

class ProfileCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
          includeIconLabels: true,

          selectedApplication: null,
          selectedJob: { postType: 'Internship', subPostType: 'Individual', title: 'Sample Title'},
          matchIndex: 0,
          selectedIndex: 0,

          totalPercent: 100,
          showSubEndorsementDetails: true,

          degreeOptions: [],
          gradYearRangeOptions: [],
          gpaRangeOptions: ['','Top 1%','Top 5%','Top 10%','Top 20%','Top 30%',"Doesn't Matter"],
          courseHourRangeOptions: ['','0 - 100 Hours','100 - 500 Hours','500 - 1,000 Hours','1,000 - 5,000 Hours','5,000 - 10,000 Hours','10,000+ Hours'],
          hourOptions: ['','100','200','300','400','500','600','700','800','900',"1,000","1,000 - 2,000","2,000 - 3,000","3,000+"],
          myersBriggsOptions: ['','N/A','ENFJ','ENFP','ENTJ',"ENTP","ESFJ","ESFP","ESTJ","ESTP",'INFJ','INFP','INTJ',"INTP","ISFJ","ISFP","ISTJ","ISTP"],
          percentageOptions: ['','N/A','0%','5%','10%','15%','20%','25%','30%','35%','40%','45%','50%','55%','60%','65%','70%','75%','80%','85%','90%','95%','100%'],
          functionOptions: [],
          industryOptions: [],
          workDistanceOptions: [],
          hoursPerWeekOptions: [],
          politicalAlignmentOptions: [],
          skillOptions: [],

          genderOptions: [],
          raceOptions: [],
          veteranOptions: [],
          lowIncomeOptions: [],
          fosterYouthOptions: [],
          homelessOptions: [],
          incarceratedOptions: [],
          lgbtqiaOptions: [],
          disabilityOptions: [],
          firstGenImmigrantOptions: [],
          firstGenCollegeStudentOptions: [],
          diversityOfThoughtOptions: [],

          interests: [],
          traits: [],
          courses: [],
          certifications: [],
          interviewQuestions: [],
          existingBenchmark: true,

          changes: []

        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderMatchScore = this.renderMatchScore.bind(this)
        this.renderSkills = this.renderSkills.bind(this)

        this.renderIdealSkills = this.renderIdealSkills.bind(this)
        this.searchSkills = this.searchSkills.bind(this)
        this.skillClicked = this.skillClicked.bind(this)

        this.renderInterviews = this.renderInterviews.bind(this)

        this.toggleView = this.toggleView.bind(this)
        this.connectEmployer = this.connectEmployer.bind(this)
        this.calculateHours = this.calculateHours.bind(this)

        this.viewDetails = this.viewDetails.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderSpecificSkillTraits = this.renderSpecificSkillTraits.bind(this)
        this.calculateBenchmarkWeight = this.calculateBenchmarkWeight.bind(this)
        this.renderEndorsements = this.renderEndorsements.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.renderWork = this.renderWork.bind(this)
        this.renderIntangibles = this.renderIntangibles.bind(this)
        this.calculateIntangiblesText = this.calculateIntangiblesText.bind(this)
        this.renderAllocation = this.renderAllocation.bind(this)

        this.renderItems = this.renderItems.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.intangibleClicked = this.intangibleClicked.bind(this)

    }

    static defaultProps = { selectedApplication: null, selectedJob: null, wpQuestions: [], benchmarkOptions: [], benchmark: null, source: "Student", trackBenchmark: null, matchIndex: 0, employerName: '',  employerContactFirstName: '', employerContactEmail: '', activeOrg: '', orgName: '', orgContactFirstName: '', orgContactLastName: '', orgContactEmail: '', orgContactTitle: '', orgProgramName: '', isCareerAssessments: true, accountCode: '' }

    componentDidMount() {
      console.log('profileCard called', this.props.selectedApplication);

      const email = localStorage.getItem('email');
      const activeOrg = localStorage.getItem('activeOrg');
      const wpQuestions = this.props.wpQuestions

      // gradYearRangeOptions: ['','< 2015','2015 - 2018','2018 - 2019','2019 - 2019','2018 - 2019','2018 - 2019','2018 - 2019','2018 - 2019','2018 - 2019'],
      let gradYearRangeOptions = ['']
      const now = new Date().getFullYear()
      gradYearRangeOptions.push('< ' + (now - 5))
      gradYearRangeOptions.push((now - 5) + ' - ' + (now - 2))

      let startingYear = now - 3
      for (let i = 1; i <= 7; i++) {
        gradYearRangeOptions.push((startingYear + i) + ' - ' + (startingYear + i + 1))
      }

      this.setState({ emailId: email, activeOrg, wpQuestions, gradYearRangeOptions })

      const selectedJob = this.props.selectedJob
      // const benchmarkOptions = this.props.benchmarkOptions

      let interests = this.state.interests
      let traits = this.state.traits
      let benchmark = {}

      if (this.props.benchmark) {
        benchmark = this.props.benchmark
      } else {
        interests = [
          { title: 'Realistic', description: 'Prefers working with animals, tools, or machines. Values things you can see and touch. Sees themselves as practical, mechanical, and realistic.', score: 0, weight: 0 },
          { title: 'Investigative', description: 'Prefers studying and solving math and science problems. Values science. Sees themselves as precise, scientific, and intellectual.', score: 0, weight: 0 },
          { title: 'Artistic', description: 'Prefers creative activities like art, drama, music, and creative writing.  Values helping people and solving social problems. Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
          { title: 'Social', description: 'Prefers to do things to help people like teaching, nursing, or giving first aid. Values helping people, and solving societal problems.  Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
          { title: 'Enterprising', description: 'Prefers to lead and persuade people. Values success in politics, leadership, or business. Sees themselves myself as energetic, ambitious, and sociable.', score: 0, weight: 0 },
          { title: 'Conventional', description: 'Prefers to work with numbers, records, or machines in a set, orderly way. Values success in business. Sees themselves as orderly, and good at following a set plan.', score: 0, weight: 0 },
        ]

        traits = [
          { title: 'Openness', description: 'Appreciation for art, emotion, adventure, unusual ideas, curiosity, and variety of experience. Openness reflects the degree of intellectual curiosity, creativity and a preference for novelty and variety a person has.', score: 0, weight: 0 },
          { title: 'Conscientiousness', description: 'Tendency to be organized and dependable, show self-discipline, act dutifully, aim for achievement, and prefer planned rather than spontaneous behavior. High conscientiousness is often perceived as being stubborn and focused.', score: 0, weight: 0 },
          { title: 'Extraversion', description: 'Energetic, surgency, assertiveness, sociability and the tendency to seek stimulation in the company of others, and talkativeness. High extraversion is often perceived as attention-seeking and domineering.', score: 0, weight: 0 },
          { title: 'Agreeableness', description: "Tendency to be compassionate and cooperative rather than suspicious and antagonistic towards others. It is also a measure of one's trusting and helpful nature, and whether a person is generally well-tempered or not.", score: 0, weight: 0 },
          { title: 'Emotional Stability', description: 'Tendency to be immune to psychological stress. The tendency to manage unpleasant emotions easily, such as anger, anxiety, stress, depression, and vulnerability. A high degree of impulse control.', score: 0, weight: 0 },
        ]

        benchmark = { interests, traits }
      }
      console.log('show benchmark 1', benchmark)

      Axios.get('/api/assessment/questions')
      .then((response) => {
          //console.log('O*Net worked', response.data.body, this);
          console.log('show me response', response.data)
          if ( response.data.success === true ) {
            console.log('o-net query worked')

            let wpQuestions = []
            for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
              wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
            }

            this.setState({ wpQuestions, selectedJob, benchmark, interests, traits })
            // this.calculateMatches(selectedApplication, selectedJob, benchmarkOptions, benchmark)

          } else {
            console.log('Assessment question query failed', response.data.message)
          }


      }).catch((error) => {
          console.log('O*NET did not work', error);
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const degreeOptions = response.data.workOptions[0].degreeOptions

          let functionOptions = response.data.workOptions[0].functionOptions
          functionOptions.shift()
          let industryOptions = response.data.workOptions[0].industryOptions
          industryOptions.shift()
          let workDistanceOptions = response.data.workOptions[0].workDistanceOptions
          workDistanceOptions.shift()
          let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
          hoursPerWeekOptions.shift()
          let politicalAlignmentOptions = response.data.workOptions[0].politicalAlignmentOptions
          if (politicalAlignmentOptions) {
            politicalAlignmentOptions.unshift('')
          }
          let hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
          hourlyPayOptions.shift()
          let annualPayOptions = response.data.workOptions[0].annualPayOptions
          annualPayOptions.shift()

          let genderOptions = response.data.workOptions[0].genderOptions
          genderOptions.unshift('')
          genderOptions[genderOptions.length - 1] = "Not Important (N/A)"

          let raceOptions = response.data.workOptions[0].raceOptions
          raceOptions.unshift('')
          raceOptions[raceOptions.length - 1] = "Not Important (N/A)"

          let veteranOptions = response.data.workOptions[0].veteranOptions
          veteranOptions.unshift('')
          veteranOptions[veteranOptions.length - 1] = "Not Important (N/A)"

          let lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions
          lowIncomeOptions.unshift('')
          lowIncomeOptions[lowIncomeOptions.length - 1] = "Not Important (N/A)"

          let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
          fosterYouthOptions.unshift('')
          fosterYouthOptions[fosterYouthOptions.length - 1] = "Not Important (N/A)"

          let homelessOptions = response.data.workOptions[0].homelessOptions
          homelessOptions.unshift('')
          homelessOptions[homelessOptions.length - 1] = "Not Important (N/A)"

          let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
          incarceratedOptions.unshift('')
          incarceratedOptions[incarceratedOptions.length - 1] = "Not Important (N/A)"

          let lgbtqiaOptions = response.data.workOptions[0].lgbtqiaOptions
          lgbtqiaOptions.unshift('')
          lgbtqiaOptions[lgbtqiaOptions.length - 1] = "Not Important (N/A)"

          let disabilityOptions = response.data.workOptions[0].disabilityOptions
          disabilityOptions.unshift('')
          disabilityOptions[disabilityOptions.length - 1] = "Not Important (N/A)"

          let firstGenImmigrantOptions = response.data.workOptions[0].firstGenImmigrantOptions
          firstGenImmigrantOptions.unshift('')
          firstGenImmigrantOptions[firstGenImmigrantOptions.length - 1] = "Not Important (N/A)"

          let firstGenCollegeStudentOptions = response.data.workOptions[0].firstGenCollegeStudentOptions
          firstGenCollegeStudentOptions.unshift('')
          firstGenCollegeStudentOptions[firstGenCollegeStudentOptions.length - 1] = "Not Important (N/A)"

          this.setState({ degreeOptions, functionOptions, industryOptions, workDistanceOptions, hoursPerWeekOptions,
            hourlyPayOptions, annualPayOptions, politicalAlignmentOptions,
            genderOptions, raceOptions, veteranOptions, lowIncomeOptions, fosterYouthOptions, homelessOptions,
            incarceratedOptions, lgbtqiaOptions, disabilityOptions, firstGenImmigrantOptions, firstGenCollegeStudentOptions
          })
        }
      });

      if (window.innerWidth > 768) {
        //not mobile
      } else {
        //mobile

      }

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called on profileCard ', this.props, prevProps)

      if (this.props.selectedApplication) {
        console.log('t1', this.state.benchmark)
        if (!prevProps.selectedApplication) {
          console.log('t2')
          const selectedApplication = this.props.selectedApplication
          const selectedJob = this.props.selectedJob
          // const benchmarkOptions = this.props.benchmarkOptions
          let benchmark = { interests: this.state.interests, traits: this.state.traits }
          if (this.props.benchmark) {
            benchmark = this.props.benchmark
          }

          // console.log('show benchmark 2', benchmark)
          this.setState({ selectedApplication, selectedJob, benchmark })
          this.retrieveData()
        } else if ( this.props.selectedApplication.match !== prevProps.selectedApplication.match || this.props.selectedApplication.matches !== prevProps.selectedApplication.matches || this.props.selectedApplication.email !== prevProps.selectedApplication.email) {
          console.log('t3')
          const selectedApplication = this.props.selectedApplication
          const selectedJob = this.props.selectedJob
          // const benchmarkOptions = this.props.benchmarkOptions
          let benchmark = { interests: this.state.interests, traits: this.state.traits }
          if (this.props.benchmark) {
            benchmark = this.props.benchmark
          }
          // console.log('show benchmark 2', benchmark)
          this.setState({ selectedApplication, selectedJob, benchmark })
          this.retrieveData()
        } else if ( this.props.selectedApplication.skillScores !== prevProps.selectedApplication.skillScores) {
          console.log('t3.5')
          const selectedApplication = this.props.selectedApplication
          const selectedJob = this.props.selectedJob
          // const benchmarkOptions = this.props.benchmarkOptions
          let benchmark = { interests: this.state.interests, traits: this.state.traits }
          if (this.props.benchmark) {
            benchmark = this.props.benchmark
          }
          // console.log('show benchmark 2', benchmark)
          this.setState({ selectedApplication, selectedJob, benchmark })
          this.retrieveData()
        } else if (this.props.benchmark && prevProps.benchmark) {
          console.log('t4')
          if (this.props.benchmark.title !== prevProps.benchmark.title) {
            console.log('t5')
            const selectedApplication = this.props.selectedApplication
            const selectedJob = this.props.selectedJob
            // const benchmarkOptions = this.props.benchmarkOptions
            let benchmark = { interests: this.state.interests, traits: this.state.traits }
            if (this.props.benchmark) {
              benchmark = this.props.benchmark
            }
            // console.log('show benchmark 2', benchmark)
            this.setState({ selectedApplication, selectedJob, benchmark })
            this.retrieveData()
          } else if (this.props.benchmark.jobTitle !== prevProps.benchmark.jobTitle || this.props.benchmark.jobFunction !== prevProps.benchmark.jobFunction || this.props.benchmark.industry !== prevProps.benchmark.industry || this.props.benchmark.title !== prevProps.benchmark.title) {
            console.log('t6')
            const selectedApplication = this.props.selectedApplication
            const selectedJob = this.props.selectedJob
            console.log('show me selectedJob: ', selectedJob  )
            let benchmark = { interests: this.state.interests, traits: this.state.traits }
            if (this.props.benchmark) {
              benchmark = this.props.benchmark
            }
            this.setState({ selectedApplication, selectedJob, benchmark })
            this.retrieveData()
          } else {
            console.log('t7', this.props.selectedApplication.email, prevProps.selectedApplication.email)
          }
        } else if (this.props.industry !== prevProps.industry) {
          console.log('t8')
          const selectedApplication = this.props.selectedApplication
          const selectedJob = this.props.selectedJob
          let benchmark = { interests: this.state.interests, traits: this.state.traits }
          if (this.props.benchmark) {
            benchmark = this.props.benchmark
          }
          this.setState({ selectedApplication, selectedJob, benchmark })
          this.retrieveData()
        } else if (!prevProps && this.props) {
          console.log('t9')
        } else {
          console.log('t10')
        }
      }
    }

    retrieveData() {
      console.log('retrieveData called in profileCard: ')

      let benchmark = this.props.benchmark

      if (benchmark) {

        //calculate percents
        const denominator = this.calculateBenchmarkWeight(null,'denominator')
        console.log('yeah?', denominator)
        if (denominator || denominator === 0) {
          console.log('show denominator: ', denominator)

          //preferences
          let preferencesPercent = 0
          if (benchmark.workPreferenceWeight) {
            preferencesPercent = benchmark.workPreferenceWeight
          }

          //interests
          let interestsPercent = 0
          if (benchmark.interestsWeight) {
            interestsPercent = benchmark.interestsWeight
          } else if (benchmark.interests) {
            for (let i = 1; i <= benchmark.interests.length; i++) {
              if (benchmark.interests[i - 1].weight) {
                interestsPercent = interestsPercent + Number(benchmark.interests[i - 1].weight)
              }
            }
          }

          //traits
          let traitsPercent = 0
          if (benchmark.personalityWeight) {
            traitsPercent = benchmark.personalityWeight
          } else if (benchmark.traits) {
            for (let i = 1; i <= benchmark.traits.length; i++) {
              if (benchmark.traits[i - 1].weight) {
                traitsPercent = traitsPercent + Number(benchmark.traits[i - 1].weight)
              }
            }
          }

          //endorsements
          let valuesPercent = 0
          if (benchmark.valuesWeight) {
            valuesPercent = benchmark.valuesWeight
          }

          //skills
          let skillsPercent = 0

          let benchmarkHardSkills = []
          let benchmarkSoftSkills = []

          if (benchmark.skills && benchmark.skills.length > 0) {
            for (let i = 1; i <= benchmark.skills.length; i++) {
              if (benchmark.skills[i - 1].weight) {
                skillsPercent = skillsPercent + Number(benchmark.skills[i - 1].weight)

                if (benchmark.skills[i - 1].skillType === 'Hard Skill') {
                  benchmarkHardSkills.push(benchmark.skills[i - 1].title)
                } else {
                  benchmarkSoftSkills.push(benchmark.skills[i - 1].title)
                }
              }
            }
          } else if (benchmark.skillsWeight) {
            skillsPercent = benchmark.skillsWeight
          }

          let thirdPartyAssessmentPercent = 0
          if (benchmark.thirdPartyAssessmentWeight) {
            thirdPartyAssessmentPercent = benchmark.thirdPartyAssessmentWeight
          }

          //endorsements
          let endorsementsPercent = 0
          if (benchmark.endorsementWeight) {
            endorsementsPercent = benchmark.endorsementWeight
          }

          //education
          let educationPercent = 0
          if (benchmark.educationWeight) {
            educationPercent = benchmark.educationWeight
          }

          //projects
          let projectsPercent = 0
          if (benchmark.projectWeight) {
            projectsPercent = benchmark.projectWeight
          }

          //experience
          let experiencePercent = 0
          if (benchmark.experienceWeight) {
            experiencePercent = benchmark.experienceWeight
          }

          //interview
          let interviewPercent = 0
          if (benchmark.interview) {
            interviewPercent = benchmark.interview.weight
          }

          let politicalPercent = 0
          let homeStatePercent = 0
          let adversityScorePercent = 0
          if (this.props.activeOrg === 'c2c') {

            if (benchmark.politicalParties) {
              for (let i = 1; i <= benchmark.politicalParties.length; i++) {
                if (benchmark.politicalParties[i - 1].weight) {
                  politicalPercent = politicalPercent + Number(benchmark.politicalParties[i - 1].weight)
                }
              }
            }

            if (benchmark.hometownWeight) {
              homeStatePercent = benchmark.hometownWeight
            }
          }

          if (benchmark.adversityScoreWeight) {
            adversityScorePercent = benchmark.adversityScoreWeight
          }

          let selectedApplication = this.props.selectedApplication
          if (this.props.selectedJob) {
            if (selectedApplication.workPreferenceResults) {
              selectedApplication['workPreferenceResults'][0] = this.props.selectedJob.workFunction
            }
          }

          // // interests
          // let generalInterests = []
          // if (benchmark.generalInterests) {
          //   generalInterests = benchmark.generalInterests
          // }
          //
          // // education
          // let degreeRequirements = ''
          // if (benchmark.degreeRequirements) {
          //   degreeRequirements = benchmark.degreeRequirements
          // }
          //
          // let idealMajors = ''
          // if (benchmark.idealMajors) {
          //   idealMajors = benchmark.idealMajors
          // }
          //
          // let gpaRange = ''
          // if (benchmark.gpRange) {
          //   gpaRange = benchmark.gpaRange
          // }
          //
          // let gradYearRange = ''
          // if (benchmark.gradYearRange) {
          //   gradYearRange = benchmark.gradYearRange
          // }
          //
          // let testScores = ''
          // if (benchmark.testScores) {
          //   testScores = benchmark.testScores
          // }
          //
          // let courseHours = ''
          // if (benchmark.courseHours) {
          //   courseHours = benchmark.courseHours
          // }
          //
          // let courses = []
          // if (benchmark.courses) {
          //   courses = benchmark.courses
          // }
          //
          // let certifications = []
          // if (benchmark.certifications) {
          //   certifications = benchmark.certifications
          // }
          //
          // // projects and experience
          // let experienceTags = []
          // if (benchmark.experienceTags) {
          //   experienceTags = benchmark.experienceTags
          // }
          //
          // let projectTags = []
          // if (benchmark.projectTags) {
          //   projectTags = benchmark.projectTags
          // }
          //
          // let volunteerTags = []
          // if (benchmark.volunteerTags) {
          //   volunteerTags = benchmark.volunteerTags
          // }
          //
          // // interview
          // let interviewQuestions = []
          // if (benchmark.interviewQuestions) {
          //   interviewQuestions = benchmark.interviewQuestions
          // }
          //
          // let strongFitCues = ''
          // if (benchmark.strongFitCues) {
          //   strongFitCues = benchmark.strongFitCues
          // }
          //
          // let poorFitCues = ''
          // if (benchmark.poorFitCues) {
          //   poorFitCues = benchmark.poorFitCues
          // }
          //
          // // diversity
          // let gender = ''
          // if (benchmark.gender) {
          //   gender = benchmark.gender
          // }
          //
          // let race = ''
          // if (benchmark.race) {
          //   race = benchmark.race
          // }
          //
          // let veteran = ''
          // if (benchmark.veteran) {
          //   veteran = benchmark.veteran
          // }
          //
          // let lowIncome = ''
          // if (benchmark.lowIncome) {
          //   lowIncome = benchmark.lowIncome
          // }
          //
          // let fosterYouth = ''
          // if (benchmark.fosterYouth) {
          //   fosterYouth = benchmark.fosterYouth
          // }
          //
          // let homeless = ''
          // if (benchmark.homeless) {
          //   homeless = benchmark.homeless
          // }
          //
          // let incarcerated = ''
          // if (benchmark.incarcerated) {
          //   incarcerated = benchmark.incarcerated
          // }
          //
          // let lgbtqia = ''
          // if (benchmark.lgbtqia) {
          //   lgbtqia = benchmark.lgbtqia
          // }
          //
          // let disability = ''
          // if (benchmark.disability) {
          //   disability = benchmark.disability
          // }
          //
          // let firstGenImmigrant = ''
          // if (benchmark.firstGenImmigrant) {
          //   firstGenImmigrant = benchmark.firstGenImmigrant
          // }
          //
          // let firstGenCollegeStudent = ''
          // if (benchmark.firstGenCollegeStudent) {
          //   firstGenCollegeStudent = benchmark.firstGenCollegeStudent
          // }

          // console.log('final run: ', thirdPartyAssessmentPercent)

          this.setState({ preferencesPercent, interestsPercent, traitsPercent, valuesPercent, skillsPercent, thirdPartyAssessmentPercent,
            endorsementsPercent, educationPercent, projectsPercent, experiencePercent, interviewPercent, politicalPercent,
            homeStatePercent, adversityScorePercent, benchmarkHardSkills, benchmarkSoftSkills, selectedApplication, benchmark,

            // generalInterests, experienceTags, projectTags, volunteerTags,
            // degreeRequirements, idealMajors, gpaRange, gradYearRange, testScores, courseHours, courses, certifications,
            // interviewQuestions, strongFitCues, poorFitCues,
            // gender, race, veteran, lowIncome, fosterYouth, homeless, incarcerated, lgbtqia, disability, firstGenImmigrant, firstGenCollegeStudent,

          })
        }

      } else {

        const preferencesPercent = 0
        const interestsPercent = 0
        const traitsPercent = 0
        const valuesPercent = 0
        const skillsPercent = 0
        const endorsementsPercent = 0
        const educationPercent = 0
        const projectsPercent = 0
        const experiencePercent = 0
        const interviewPercent = 0
        const politicalPercent = 0
        const homeStatePercent = 0
        const adversityScorePercent = 0
        const totalPercent = 0

        const benchmarkHardSkills = []
        const benchmarkSoftSkills = []

        let selectedApplication = {}
        if (this.props.selectedApplication) {
          selectedApplication = this.props.selectedApplication
        }

        const interests = [
          { title: 'Realistic', description: 'Prefers working with animals, tools, or machines. Values things you can see and touch. Sees themselves as practical, mechanical, and realistic.', score: 0, weight: 0 },
          { title: 'Investigative', description: 'Prefers studying and solving math and science problems. Values science. Sees themselves as precise, scientific, and intellectual.', score: 0, weight: 0 },
          { title: 'Artistic', description: 'Prefers creative activities like art, drama, music, and creative writing.  Values helping people and solving social problems. Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
          { title: 'Social', description: 'Prefers to do things to help people like teaching, nursing, or giving first aid. Values helping people, and solving societal problems.  Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
          { title: 'Enterprising', description: 'Prefers to lead and persuade people. Values success in politics, leadership, or business. Sees themselves myself as energetic, ambitious, and sociable.', score: 0, weight: 0 },
          { title: 'Conventional', description: 'Prefers to work with numbers, records, or machines in a set, orderly way. Values success in business. Sees themselves as orderly, and good at following a set plan.', score: 0, weight: 0 },
        ]

        const traits = [
          { title: 'Openness', description: 'Appreciation for art, emotion, adventure, unusual ideas, curiosity, and variety of experience. Openness reflects the degree of intellectual curiosity, creativity and a preference for novelty and variety a person has.', score: 0, weight: 0 },
          { title: 'Conscientiousness', description: 'Tendency to be organized and dependable, show self-discipline, act dutifully, aim for achievement, and prefer planned rather than spontaneous behavior. High conscientiousness is often perceived as being stubborn and focused.', score: 0, weight: 0 },
          { title: 'Extraversion', description: 'Energetic, surgency, assertiveness, sociability and the tendency to seek stimulation in the company of others, and talkativeness. High extraversion is often perceived as attention-seeking and domineering.', score: 0, weight: 0 },
          { title: 'Agreeableness', description: "Tendency to be compassionate and cooperative rather than suspicious and antagonistic towards others. It is also a measure of one's trusting and helpful nature, and whether a person is generally well-tempered or not.", score: 0, weight: 0 },
          { title: 'Emotional Stability', description: 'Tendency to be immune to psychological stress. The tendency to manage unpleasant emotions easily, such as anger, anxiety, stress, depression, and vulnerability. A high degree of impulse control.', score: 0, weight: 0 },
        ]

        benchmark = { interests, traits }
        let existingBenchmark = false

        this.setState({ preferencesPercent, interestsPercent, traitsPercent, valuesPercent, skillsPercent,
          endorsementsPercent, educationPercent, projectsPercent, experiencePercent, interviewPercent, politicalPercent,
          homeStatePercent, adversityScorePercent, totalPercent, interests, traits,

          benchmarkHardSkills, benchmarkSoftSkills, selectedApplication, benchmark, existingBenchmark
        })
      }
    }

    saveCustomBenchmark = async() => {
      console.log('saveCustomBenchmark called', this.state.benchmark)

      let benchmark = this.state.benchmark
      const changes = this.state.changes
      const selectedApplication = this.state.selectedApplication

      let highPriorityHardSkills = []
      if (benchmark.highPriorityHardSkills) {
        highPriorityHardSkills = benchmark.highPriorityHardSkills
      }

      let lowPriorityHardSkills = []
      if (benchmark.lowPriorityHardSkills) {
        lowPriorityHardSkills = benchmark.lowPriorityHardSkills
      }
      let highPrioritySoftSkills = []
      if (benchmark.highPrioritySoftSkills) {
        highPrioritySoftSkills = benchmark.highPrioritySoftSkills
      }
      let lowPrioritySoftSkills = []
      if (benchmark.lowPrioritySoftSkills) {
        lowPrioritySoftSkills = benchmark.lowPrioritySoftSkills
      }

      if (changes.length > 0) {
        for (let i = 1; i <= changes.length; i++) {
          if (changes[i - 1] === 'preferencesPercent') {
            benchmark['workPreferenceWeight'] = Number(this.state.preferencesPercent)
          } else if (changes[i - 1] === 'interestsPercent') {
            let calcDenominator = 0

            for (let j = 1; j <= selectedApplication.interestResults.length; j++) {

              if (selectedApplication.interestResults[j - 1].score === 'N/A') {
              } else if (selectedApplication.interestResults[j - 1].score === 0) {
              } else {
                calcDenominator = calcDenominator + 1
              }
            }

            for (let j = 1; j <= selectedApplication.interestResults.length; j++) {
              benchmark['interests'][j - 1]['weight'] = benchmark.interests[j - 1].weight / calcDenominator

              if (selectedApplication.interestResults[j - 1].score) {
                if (selectedApplication.interestResults[j - 1].score === 'N/A' || selectedApplication.interestResults[j - 1].score === 0) {
                  benchmark['interests'][j - 1]['weight'] = 0
                  console.log('no interests: ', this.state.interestsPercent, calcDenominator)
                } else {
                  benchmark['interests'][j - 1]['weight'] = Number(this.state.interestsPercent) * ( 1 / calcDenominator )
                  console.log('show interests: ', this.state.interestsPercent, calcDenominator, benchmark.interests[j - 1].weight)
                }
              }
            }

          } else if (changes[i - 1] === 'traitsPercent') {
            let calcDenominator = 0

            if (selectedApplication.personalityResults.fiveFactors) {

              if (selectedApplication.personalityResults.fiveFactors.opennessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.opennessScore === 0) {

              } else {
                calcDenominator = calcDenominator + 1
              }
              if (selectedApplication.personalityResults.fiveFactors.conscientiousnessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.conscientiousnessScore === 0) {
                // benchmark['traits'][1]['weight'] = ((Number(this.state.traitsPercent) / 5 ))

              } else {
                calcDenominator = calcDenominator + 1
              }
              if (selectedApplication.personalityResults.fiveFactors.extraversionScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.extraversionScore === 0) {
                // benchmark['traits'][2]['weight'] = ((Number(this.state.traitsPercent) / 5 ))

              } else {
                calcDenominator = calcDenominator + 1
              }
              if (selectedApplication.personalityResults.fiveFactors.agreeablenessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.agreeablenessScore === 0) {
                // benchmark['traits'][3]['weight'] = ((Number(this.state.traitsPercent) / 5 ))

              } else {
                calcDenominator = calcDenominator + 1
              }
              if (selectedApplication.personalityResults.fiveFactors.neuroticismScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.neuroticismScore === 0) {
                // benchmark['traits'][4]['weight'] = ((Number(this.state.traitsPercent) / 5 ))

              } else {
                calcDenominator = calcDenominator + 1
              }

              console.log('show calcDenominator: ', calcDenominator)

              //weight the weights
              if (selectedApplication.personalityResults.fiveFactors.opennessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.opennessScore === 0) {
                benchmark['traits'][0]['weight'] = 0
                console.log('no traits: ', this.state.traitsPercent, calcDenominator)
              } else {
                benchmark['traits'][0]['weight'] = Number(this.state.traitsPercent) * ( 1 / calcDenominator )
                console.log('show openness 1: ', this.state.traitsPercent, calcDenominator, benchmark.traits[0].weight)
              }

              if (selectedApplication.personalityResults.fiveFactors.conscientiousnessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.conscientiousnessScore === 0) {
                benchmark['traits'][1]['weight'] = 0
              } else {
                benchmark['traits'][1]['weight'] = Number(this.state.traitsPercent) * ( 1 / calcDenominator )
                console.log('show openness 2: ', this.state.traitsPercent, calcDenominator, benchmark.traits[1].weight)
              }

              if (selectedApplication.personalityResults.fiveFactors.extraversionScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.extraversionScore === 0) {
                benchmark['traits'][2]['weight'] = 0
              } else {
                benchmark['traits'][2]['weight'] = Number(this.state.traitsPercent) * ( 1 / calcDenominator )
                console.log('show openness 3: ', this.state.traitsPercent, calcDenominator, benchmark.traits[2].weight)
              }

              if (selectedApplication.personalityResults.fiveFactors.agreeablenessScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.agreeablenessScore === 0) {
                benchmark['traits'][3]['weight'] = 0
              } else {
                benchmark['traits'][3]['weight'] = Number(this.state.traitsPercent) * ( 1 / calcDenominator )
              }

              if (selectedApplication.personalityResults.fiveFactors.neuroticismScore === 'N/A' || selectedApplication.personalityResults.fiveFactors.neuroticismScore === 0) {
                benchmark['traits'][4]['weight'] = 0
              } else {
                benchmark['traits'][4]['weight'] = Number(this.state.traitsPercent) * ( 1 / calcDenominator )
              }
            }
          } else if (changes[i - 1] === 'valuesPercent') {
            benchmark['valuesWeight'] = Number(this.state.valuesPercent)
          } else if (changes[i - 1] === 'skillsPercent') {

            let skills = []

            const benchmarkHardSkills = this.state.benchmarkHardSkills
            if (benchmarkHardSkills) {
              const skillType = 'Hard Skill'
              console.log('got this far?')
              for (let j = 1; j <= 6; j++) {
                if (benchmarkHardSkills[j - 1] && benchmarkHardSkills[j - 1] !== '') {
                  const title = benchmarkHardSkills[j - 1]
                  const description = ''
                  const score = 5
                  const weight = this.state.skillsPercent / 12
                  skills.push({ title, description, skillType, score, weight })
                } else {
                  break;
                }
              }
            }

            const benchmarkSoftSkills = this.state.benchmarkSoftSkills
            if (benchmarkSoftSkills) {
              const skillType = 'Soft Skill'
              for (let j = 1; j <= 6; j++) {
                if (benchmarkSoftSkills[j - 1] && benchmarkSoftSkills[j - 1] !== '') {
                  const title = benchmarkSoftSkills[j - 1]
                  const description = ''
                  const score = 5
                  const weight = this.state.skillsPercent / 12
                  skills.push({ title, description, skillType, score, weight })
                } else {
                  break;
                }
              }
            }

            benchmark['skills'] = skills

          } else if (changes[i - 1] === 'endorsementsPercent') {
            benchmark['endorsementWeight'] = Number(this.state.endorsementsPercent)
          } else if (changes[i - 1] === 'educationPercent') {
            benchmark['educationWeight'] = Number(this.state.educationPercent)
          } else if (changes[i - 1] === 'projectsPercent') {
            benchmark['projectWeight'] = Number(this.state.projectsPercent)
          } else if (changes[i - 1] === 'experiencePercent') {
            benchmark['experienceWeight'] = Number(this.state.experiencePercent)
          } else if (changes[i - 1] === 'interviewPercent') {
            benchmark['interview'] = { score: 100, weight: Number(this.state.experiencePercent) }
          } else if (changes[i - 1] === 'politicalPercent') {
            // benchmark['experienceWeight'] = Number(this.state.experiencePercent)
          } else if (changes[i - 1] === 'homeStatePercent') {

          } else if (changes[i - 1] === 'adversityScorePercent') {
            benchmark['adversityScoreWeight'] = Number(this.state.adversityScorePercent)
          } else if (changes[i - 1] === 'schoolName') {
            benchmark['schoolName'] = selectedApplication.schoolName
          } else if (changes[i - 1] === 'gradYear') {
            benchmark['gradYear'] = selectedApplication.gradYear
          } else if (changes[i - 1] === 'major') {
            benchmark['major'] = selectedApplication.major
          // } else if (changes[i - 1] === 'experienceHours') {
          //   benchmark['experienceHours'] = selectedApplication.experienceHours
          // } else if (changes[i - 1] === 'projectHours') {
          //   benchmark['projectHours'] = selectedApplication.projectHours
          // } else if (changes[i - 1] === 'volunteerHours') {
          //   benchmark['volunteerHours'] = selectedApplication.volunteerHours
          // } else if (changes[i - 1] === 'experienceTags') {
          //   benchmark['experienceTags'] = selectedApplication.experienceTags
          // } else if (changes[i - 1] === 'projectTags') {
          //   benchmark['projectTags'] = selectedApplication.projectTags
          // } else if (changes[i - 1] === 'volunteerTags') {
          //   benchmark['volunteerTags'] = selectedApplication.volunteerTags
          } else if (changes[i - 1] === 'benchmarkHardSkills') {
            let benchmarkHardSkills = this.state.benchmarkHardSkills
            highPriorityHardSkills = []
            let skills = []
            let skillNames = []

            //FIX THE WEIGHT!
            for (let j = 0; j <= benchmarkHardSkills.length; j++) {
              if (benchmarkHardSkills[j - 1] && benchmarkHardSkills[j - 1] !== '') {
                let weight = 1
                if (this.state.benchmark[j]) {
                  if (this.state.benchmark.skills[j].weight) {
                    weight = this.state.benchmark.skills[j].weight
                  }
                }
                skills.push({ title: benchmarkHardSkills[j - 1], skillType: 'Hard Skill', description: '', score: '5', weight })
                skillNames.push(benchmarkHardSkills[j - 1])
                highPriorityHardSkills.push(benchmarkHardSkills[j - 1])
              }
            }

            console.log('show hard skills: ', skills)
            for (let j = 1; j <= benchmark.skills.length; j++) {
              if (benchmark.skills[j - 1].skillType === 'Hard Skill') {
                //ignore
              } else {
                skills.push(benchmark.skills[j - 1])
                skillNames.push(benchmark.skills[j - 1].title)
              }
            }

            for (let j = 1; j <= lowPriorityHardSkills.length; j++) {
              let title = lowPriorityHardSkills[j - 1]
              if (!skillNames.includes(title)) {
                let skillType = 'Hard Skill'
                let description = ''
                let score = '5'
                let weight = '0'
                skills.push({ title, skillType, description, score, weight })
              }
            }

            console.log('show all skills: ', skills)
            benchmark['skills'] = skills
          } else if (changes[i - 1] === 'benchmarkSoftSkills') {
            let benchmarkSoftSkills = this.state.benchmarkSoftSkills
            highPrioritySoftSkills = []

            let skills = []
            let skillNames = []

            for (let j = 1; j <= benchmark.skills.length; j++) {
              if (benchmark.skills[j - 1].skillType === 'Soft Skill') {
                //ignore
              } else {
                skills.push(benchmark.skills[j - 1])
                skillNames.push(benchmark.skills[j - 1].title)
              }
            }

            console.log('show hard skills: ', skills)

            //FIX THE WEIGHT!
            for (let j = 0; j <= benchmarkSoftSkills.length; j++) {
              if (benchmarkSoftSkills[j - 1] && benchmarkSoftSkills[j - 1] !== '') {
                let weight = 1
                if (this.state.benchmark.skills[j + 6]) {
                  if (this.state.benchmark.skills[j + 6].weight) {
                    weight = this.state.benchmark.skills[j + 6].weight
                  }
                }
                skills.push({ title: benchmarkSoftSkills[j - 1], skillType: 'Soft Skill', description: '', score: '5', weight })
                skillNames.push(benchmarkSoftSkills[j - 1])
                highPrioritySoftSkills.push(benchmarkSoftSkills[j - 1])
              }
            }

            for (let j = 1; j <= lowPrioritySoftSkills.length; j++) {
              let title = lowPrioritySoftSkills[j - 1]
              if (!skillNames.includes(title)) {
                let skillType = 'Soft Skill'
                let description = ''
                let score = '5'
                let weight = '0'
                skills.push({ title, skillType, description, score, weight })
              }
            }

            console.log('show all skills: ', skills)
            benchmark['skills'] = skills

          // } else if (changes[i - 1] === 'interviewQuestion') {
          //   benchmark['interviewQuestions'] = selectedApplication.interviewQuestions
          // } else if (changes[i - 1] === 'interviewGuidance') {
          //   benchmark['interviewQuestions'] = selectedApplication.interviewQuestions
          // } else if (changes[i - 1] === 'myersBriggs') {
          //   benchmark['myersBriggs'] = selectedApplication.personalityResults.myersBriggs
          // } else if (changes[i - 1] === 'openness') {
          //   benchmark['traits'][0]['score'] = (Number(selectedApplication.personalityResults.fiveFactors.opennessScore) / 16) * 100 / 20
          // } else if (changes[i - 1] === 'conscientiousness') {
          //   benchmark['traits'][1]['score'] = (Number(selectedApplication.personalityResults.fiveFactors.conscientiousnessScore) / 16) * 100 / 20
          // } else if (changes[i - 1] === 'extraversion') {
          //   benchmark['traits'][2]['score'] = (Number(selectedApplication.personalityResults.fiveFactors.extraversionScore) / 16) * 100 / 20
          // } else if (changes[i - 1] === 'agreeableness') {
          //   benchmark['traits'][3]['score'] = (Number(selectedApplication.personalityResults.fiveFactors.agreeablenessScore) / 16) * 100 / 20
          // } else if (changes[i - 1] === 'neuroticism') {
          //   benchmark['traits'][4]['score'] = (Number(selectedApplication.personalityResults.fiveFactors.neuroticismScore) / 16) * 100 / 20
          // } else if (changes[i - 1] === 'realistic') {
          //   benchmark['interests'][0]['score'] = (Number(selectedApplication.interestResults[0].score) / 40) * 5
          // } else if (changes[i - 1] === 'investigative') {
          //   benchmark['interests'][1]['score'] = (Number(selectedApplication.interestResults[1].score) / 40) * 5
          // } else if (changes[i - 1] === 'artistic') {
          //   benchmark['interests'][2]['score'] = (Number(selectedApplication.interestResults[2].score) / 40) * 5
          // } else if (changes[i - 1] === 'social') {
          //   benchmark['interests'][3]['score'] = (Number(selectedApplication.interestResults[3].score) / 40) * 5
          // } else if (changes[i - 1] === 'enterprising') {
          //   benchmark['interests'][4]['score'] = (Number(selectedApplication.interestResults[4].score) / 40) * 5
          // } else if (changes[i - 1] === 'conventional') {
          //   benchmark['interests'][5]['score'] = (Number(selectedApplication.interestResults[5].score) / 40) * 5
          // } else if (changes[i - 1] === 'passions') {
          //   benchmark['passions'] = selectedApplication.passions
          // } else if (changes[i - 1] === 'degreeRequirements') {
          //   benchmark['degreeRequirements'] = selectedApplication.degreeRequirements
          // } else if (changes[i - 1] === 'idealMajors') {
          //   benchmark['idealMajors'] = selectedApplication.idealMajors
          // } else if (changes[i - 1] === 'gpaRange') {
          //   benchmark['gpaRange'] = selectedApplication.gpaRange
          // } else if (changes[i - 1] === 'gradYearRange') {
          //   benchmark['gradYearRange'] = selectedApplication.gradYearRange
          // } else if (changes[i - 1] === 'courseHours') {
          //   benchmark['courseHours'] = selectedApplication.courseHours
          // } else if (changes[i - 1] === 'strongFitCues') {
          //   benchmark['strongFitCues'] = selectedApplication.strongFitCues
          // } else if (changes[i - 1] === 'poorFitCues') {
            // benchmark['poorFitCues'] = selectedApplication.poorFitCues
          // } else if (changes[i - 1] === 'workPreferences') {
          //   const proximityRequirements = selectedApplication.workPreferenceResults[6]
          //   const maxPay = selectedApplication.workPreferenceResults[selectedApplication.workPreferenceResults.length - 1]
          //   benchmark['proximityRequirements'] = proximityRequirements
          //   benchmark['maxPay'] = maxPay
          // } else if (changes[i - 1] === 'testScores') {
          //   benchmark['testScores'] = selectedApplication.testScores
          // } else if (changes[i - 1] === 'courses') {
          //   benchmark['courses'] = selectedApplication.courses
          // } else if (changes[i - 1] === 'certifications') {
          //   benchmark['certifications'] = selectedApplication.certifications
          // } else if (changes[i - 1] === 'gender') {
          //   benchmark['gender'] = selectedApplication.gender
          // } else if (changes[i - 1] === 'race') {
          //   benchmark['race'] = selectedApplication.race
          // } else if (changes[i - 1] === 'veteran') {
          //   benchmark['veteran'] = selectedApplication.veteran
          // } else if (changes[i - 1] === 'lowIncome') {
          //   benchmark['lowIncome'] = selectedApplication.lowIncome
          // } else if (changes[i - 1] === 'fosterYouth') {
          //   benchmark['fosterYouth'] = selectedApplication.fosterYouth
          // } else if (changes[i - 1] === 'homeless') {
          //   benchmark['homeless'] = selectedApplication.homeless
          // } else if (changes[i - 1] === 'incarcerated') {
          //   benchmark['incarcerated'] = selectedApplication.incarcerated
          // } else if (changes[i - 1] === 'lgbtqia') {
          //   benchmark['lgbtqia'] = selectedApplication.lgbtqia
          // } else if (changes[i - 1] === 'disability') {
          //   benchmark['disability'] = selectedApplication.disability
          // } else if (changes[i - 1] === 'firstGenImmigrant') {
          //   benchmark['firstGenImmigrant'] = selectedApplication.firstGenImmigrant
          // } else if (changes[i - 1] === 'firstGenCollegeStudent') {
          //   benchmark['firstGenCollegeStudent'] = selectedApplication.firstGenCollegeStudent
          }
        }
      }

      //interestsPercent, skillsPercent, traitsPercent, politicalWeight, homeStatePercent
      // console.log('show benchmark 5: ', benchmark)
      // account for competency tags

      if (this.props.competencyTags) {
        let skills = []
        let knowledge = []
        let abilities = []
        let competencyTags = this.props.competencyTags
        for (let i = 1; i <= competencyTags.length; i++) {
          if (competencyTags[i - 1]) {
            const title = competencyTags[i - 1].name
            const category = competencyTags[i - 1].category
            const description = competencyTags[i - 1].description
            const score = competencyTags[i - 1].score
            const weight = competencyTags[i - 1].weight
            if (competencyTags[i - 1].category.includes('Skill')) {
              skills.push({ title, skillType: category, description, score, weight })
            } else if (competencyTags[i - 1].category.includes('Knowledge')){
              // knowledge - add later
              knowledge.push({ title, category, description, score, weight })
            } else if (competencyTags[i - 1].category.includes('Ability')){
              // ability - add later
              abilities.push({ title, category, description, score, weight })
            }
          }
        }

        benchmark['skills'] = skills
        benchmark['lowPrioritySoftSkills'] = skills
        benchmark['highPrioritySoftSkills'] = []
        benchmark['lowPriorityHardSkills'] = skills
        benchmark['highPriorityHardSkills'] = []
        benchmark['knowledge'] = knowledge
        benchmark['abilities'] = abilities

        lowPrioritySoftSkills = skills
        highPrioritySoftSkills = []
        lowPriorityHardSkills = skills
        highPriorityHardSkills = []

        // console.log('show competencySkills change: ', skills)

      }

      //add accountCode
      const accountCode = this.props.accountCode

      //save benchmark
      const _id = this.state.selectedApplication._id
      const title = this.state.selectedJob.title + ' Benchmark'
      const jobTitle = this.state.selectedJob.title
      // const jobType = 'General'
      const jobFunction = this.state.selectedJob.workFunction
      const industry = this.props.industry

      //weights
      const workPreferenceWeight = benchmark.workPreferenceWeight
      const interestsWeight = benchmark.interestsWeight
      const personalityWeight = benchmark.traitsWeight
      const skillsWeight = benchmark.skillsWeight
      const endorsementWeight = benchmark.endorsementWeight
      const educationWeight = benchmark.educationWeight
      const projectWeight = benchmark.projectWeight
      const experienceWeight = benchmark.experienceWeight
      const interviewWeight = benchmark.interviewPercent
      const adversityScoreWeight = benchmark.adversityScoreWeight
      const politicalAlignmentWeight = benchmark.politicalAlignmentWeight
      const hometownWeight = benchmark.hometownWeight

      //importance
      const proximityRequirementsImportance = benchmark.proximityRequirementsImportance
      const maxPayImportance = benchmark.maxPayImportance
      const functionsOfInterestImportance = benchmark.functionsOfInterestImportance
      const industriesOfInterestImportance = benchmark.industriesOfInterestImportance

      const generalInterestsImportance = benchmark.generalInterestsImportance
      const myersBriggsImportance = benchmark.myersBriggsImportance
      const additionalTraitsImportance = benchmark.additionalTraitsImportance

      const degreeRequirementsImportance = benchmark.degreeRequirementsImportance
      const idealMajorsImportance = benchmark.idealMajorsImportance
      const gpaRangeImportance = benchmark.gpaRangeImportance
      const gradYearRangeImportance = benchmark.gradYearRangeImportance
      const testScoresImportance = benchmark.testScoresImportance
      const courseHoursImportance = benchmark.courseHoursImportance
      const coursesImportance = benchmark.coursesImportance
      const certificationsImportance = benchmark.certificationsImportance

      const projectHoursImportance = benchmark.projectHoursImportance
      const projectTagsImportance = benchmark.projectTagsImportance
      const experienceHoursImportance = benchmark.experienceHoursImportance
      const experienceTagsImportance = benchmark.experienceTagsImportance
      const volunteerHoursImportance = benchmark.volunteerHoursImportance
      const volunteerTagsImportance = benchmark.volunteerTagsImportance

      const genderImportance = benchmark.genderImportance
      const raceImportance = benchmark.raceImportance
      const veteranImportance = benchmark.veteranImportance
      const lowIncomeImportance = benchmark.lowIncomeImportance
      const fosterYouthImportance = benchmark.fosterYouthImportance
      const homelessImportance = benchmark.homelessImportance
      const incarceratedImportance = benchmark.incarceratedImportance
      const lgbtqiaImportance = benchmark.lgbtqiaImportance
      const disabilityImportance = benchmark.disabilityImportance
      const firstGenImmigrantImportance = benchmark.firstGenImmigrantImportance
      const firstGenCollegeStudentImportance = benchmark.firstGenCollegeStudentImportance

      // work preferences
      const proximityRequirements = benchmark.proximityRequirements
      const maxPay = benchmark.maxPay
      const functionsOfInterest = benchmark.functionsOfInterest
      const industriesOfInterest = benchmark.industriesOfInterest

      // interests
      const interests = benchmark.interests
      const generalInterests = benchmark.generalInterests
      const workInterests = benchmark.workInterests
      const workInterestFields = benchmark.workInterestFields

      // personality
      const traits = benchmark.traits
      const myersBriggs = benchmark.myersBriggs
      const fiveFactors = benchmark.fiveFactors
      const additionalTraits = benchmark.additionalTraits
      const personalityFields = benchmark.personalityFields

      // skills
      const skills = benchmark.skills
      // low priority hard and soft skill have been accounted for above
      const abilities = benchmark.abilities

      //education
      const degreeRequirements = benchmark.degreeRequirements
      let idealMajors = []
      if (benchmark.idealMajors) {
        if (typeof benchmark.idealMajors === "string") {
          const tagArray = benchmark.idealMajors.split(",")
          for (let i = 1; i <= tagArray.length; i++) {
            if (tagArray[i - 1] !== '') {
              idealMajors.push(tagArray[i - 1])
            }
          }
        } else {
          idealMajors = benchmark.idealMajors
        }
      }
      const knowledge = benchmark.knowledge

      const gpaRange = benchmark.gpaRange
      const gradYearRange = benchmark.gradYearRange
      const testScores = benchmark.testScores
      const courseHours = benchmark.courseHours
      const courses = benchmark.courses
      const certifications = benchmark.certifications

      //examples
      const schoolName = benchmark.schoolName
      const gradYear = benchmark.gradYear
      const major = benchmark.major

      //project
      const projectHours = benchmark.projectHours
      let projectTags = []
      if (benchmark.projectTags) {
        if (typeof benchmark.projectTags === "string") {
          const tagArray = benchmark.projectTags.split(",")
          for (let i = 1; i <= tagArray.length; i++) {
            if (tagArray[i - 1] !== '') {
              projectTags.push(tagArray[i - 1])
            }
          }
        } else {
          projectTags = benchmark.projectTags
        }
      }

      //experience
      const experienceHours = benchmark.experienceHours
      let experienceTags = []
      if (benchmark.experienceTags) {
        if (typeof benchmark.experienceTags === "string") {
          const tagArray = benchmark.experienceTags.split(",")
          for (let i = 1; i <= tagArray.length; i++) {
            if (tagArray[i - 1] !== '') {
              experienceTags.push(tagArray[i - 1])
            }
          }
        } else {
          experienceTags = benchmark.experienceTags
        }
      }
      const volunteerHours = benchmark.volunteerHours
      let volunteerTags = []
      if (benchmark.volunteerTags) {
        if (typeof benchmark.volunteerTags === "string") {
          const tagArray = benchmark.volunteerTags.split(",")
          for (let i = 1; i <= tagArray.length; i++) {
            if (tagArray[i - 1] !== '') {
              volunteerTags.push(tagArray[i - 1])
            }
          }
        } else {
          volunteerTags = benchmark.volunteerTags
        }
      }

      //interview
      const interviewQuestions = benchmark.interviewQuestions
      const interview = benchmark.interview
      const strongFitCues = benchmark.strongFitCues
      const poorFitCues = benchmark.poorFitCues
      const interviewRubric = this.state.interviewRubric

      //diversity preferences
      const gender = benchmark.gender
      const race = benchmark.race
      const veteran = benchmark.veteran
      const lowIncome = benchmark.lowIncome
      const fosterYouth = benchmark.fosterYouth
      const homeless = benchmark.homeless
      const incarcerated = benchmark.incarcerated
      const lgbtqia = benchmark.lgbtqia
      const disability = benchmark.disability
      const firstGenImmigrant = benchmark.firstGenImmigrant
      const firstGenCollegeStudent = benchmark.firstGenCollegeStudent

      //politics
      const politicalParties = benchmark.politicalParties
      const hometown = benchmark.hometown
      const location = benchmark.location

      const orgCode = this.props.activeOrg

      const updatedAt = new Date()

      await Axios.post('/api/benchmarks', { //sessions: sessions
        _id, title, jobTitle, jobFunction, industry,
        workPreferenceWeight, interestsWeight, personalityWeight, skillsWeight, endorsementWeight, educationWeight,
        projectWeight, experienceWeight, interviewWeight, adversityScoreWeight, politicalAlignmentWeight, hometownWeight,

        proximityRequirementsImportance, maxPayImportance, functionsOfInterestImportance, industriesOfInterestImportance,
        generalInterestsImportance, myersBriggsImportance, additionalTraitsImportance, degreeRequirementsImportance, idealMajorsImportance,
        gpaRangeImportance, gradYearRangeImportance, testScoresImportance, courseHoursImportance, coursesImportance,
        certificationsImportance, projectHoursImportance, experienceHoursImportance, volunteerHoursImportance,
        projectTagsImportance, experienceTagsImportance, volunteerTagsImportance,
        genderImportance,
        raceImportance, veteranImportance, lowIncomeImportance, fosterYouthImportance, homelessImportance, incarceratedImportance,
        lgbtqiaImportance, disabilityImportance, firstGenImmigrantImportance, firstGenCollegeStudentImportance,

        proximityRequirements, maxPay, functionsOfInterest, industriesOfInterest,
        workInterestFields, personalityFields,
        generalInterests, workInterests, interests, myersBriggs, fiveFactors, additionalTraits, traits,
        highPriorityHardSkills, lowPriorityHardSkills, highPrioritySoftSkills, lowPrioritySoftSkills, skills, abilities,
        degreeRequirements, idealMajors, gpaRange, gradYearRange, testScores, courseHours, courses, certifications, schoolName,
        gradYear, major, knowledge,
        projectHours, projectTags, experienceHours, experienceTags, volunteerHours, volunteerTags,
        interviewQuestions, strongFitCues, poorFitCues, interviewRubric, interview,
        gender, race, veteran, lowIncome, fosterYouth, homeless, incarcerated, lgbtqia, disability, firstGenImmigrant, firstGenCollegeStudent,
        politicalParties, hometown, location, orgCode, accountCode, updatedAt

       })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Benchmark save worked', response.data);
          const customBenchmarkId = response.data._id
          console.log('show id: ', customBenchmarkId)

          this.props.passBenchmark(customBenchmarkId)
          // this.setState({
          //   serverPostSuccess: true,
          //   serverSuccessMessage: 'Benchmark saved successfully!'
          // })

        } else {
          // console.error('there was an error saving the benchmark');
          // this.setState({
          //   serverPostSuccess: false,
          //   serverErrorMessage: response.data.message,
          // })
        }
      }).catch((error) => {
          console.log('Benchmark save did not work', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value, this.state.benchmark)

      let benchmark = this.state.benchmark
      let selectedApplication = this.state.selectedApplication

      let changes = this.state.changes
      if (!changes.includes(event.target.name)) {
        changes.push(event.target.name)
      }

      if (event.target.name === 'preferencesPercent') {
        const diff = event.target.value - this.state.preferencesPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ preferencesPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'interestsPercent') {
        const diff = event.target.value - this.state.interestsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ interestsPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'traitsPercent') {
        const diff = event.target.value - this.state.traitsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ traitsPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'valuesPercent') {
        const diff = event.target.value - this.state.valuesPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ valuesPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'skillsPercent') {
        const diff = event.target.value - this.state.skillsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ skillsPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'endorsementsPercent') {
        const diff = event.target.value - this.state.endorsementsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ endorsementsPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'educationPercent') {
        const diff = event.target.value - this.state.educationPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ educationPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'projectsPercent') {
        const diff = event.target.value - this.state.projectsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ projectsPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'experiencePercent') {
        const diff = event.target.value - this.state.experiencePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ experiencePercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'interviewPercent') {
        const diff = event.target.value - this.state.interviewPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ interviewPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'politicalPercent') {
        const diff = event.target.value - this.state.politicalPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ politicalPercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'homeStatePercent') {
        const diff = event.target.value - this.state.homeStatePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ homeStatePercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'adversityScorePercent') {
        const diff = event.target.value - this.state.adversityScorePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ adversityScorePercent: event.target.value, totalPercent, changes })
      } else if (event.target.name === 'schoolName') {
        selectedApplication['schoolName'] = event.target.value
        this.setState({ selectedApplication, changes })
      } else if (event.target.name === 'gradYear') {
        selectedApplication['gradYear'] = event.target.value
        this.setState({ selectedApplication, changes })
      } else if (event.target.name === 'major') {
        selectedApplication['major'] = event.target.value
        this.setState({ selectedApplication, changes })
      } else if (event.target.name === 'experienceHours') {
        benchmark['experienceHours'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'projectHours') {
        benchmark['projectHours'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'volunteerHours') {
        benchmark['volunteerHours'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'experienceTags') {
        benchmark['experienceTags'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'projectTags') {
        benchmark['projectTags'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'volunteerTags') {
        benchmark['volunteerTags'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name.includes('benchmarkHardSkills')) {
        console.log('we in here????')
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let benchmarkHardSkills = this.state.benchmarkHardSkills
        benchmarkHardSkills[index] = event.target.value

        if (!changes.includes(nameArray[0])) {
          changes[changes.length - 1] = nameArray[0]
        }
        const selectedIndex = index
        this.setState({ benchmarkHardSkills, changes, selectedIndex })

        this.searchSkills(benchmarkHardSkills, 'Hard Skill', index)
      } else if (event.target.name.includes('benchmarkSoftSkills')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let benchmarkSoftSkills = this.state.benchmarkSoftSkills
        benchmarkSoftSkills[index] = event.target.value

        if (!changes.includes(nameArray[0])) {
          changes[changes.length - 1] = nameArray[0]
        }

        const selectedIndex = index
        this.setState({ benchmarkSoftSkills, changes, selectedIndex  })
        this.searchSkills(benchmarkSoftSkills, 'Soft Skill', index)
      } else if (event.target.name.includes('interviewQuestion')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let interviewQuestions = this.state.benchmark.interviewQuestions
        interviewQuestions[index]['question'] = event.target.value
        benchmark['interviewQuestions'] = interviewQuestions

        this.setState({ benchmark })

      } else if (event.target.name.includes('interviewGuidance')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let interviewQuestions = this.state.benchmark.interviewQuestions
        interviewQuestions[index]['guidance'] = event.target.value
        benchmark['interviewQuestions'] = interviewQuestions

        this.setState({ benchmark })
      } else if (event.target.name === 'myersBriggs') {

        benchmark['myersBriggs'] = event.target.value
        this.setState({ benchmark })

      } else if (event.target.name === 'openness') {
        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['traits'][0]['score'] = calculatedNumber
        this.setState({ benchmark })
      } else if (event.target.name === 'conscientiousness') {
        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['traits'][1]['score'] = calculatedNumber
        this.setState({ benchmark })
      } else if (event.target.name === 'extraversion') {

        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['traits'][2]['score'] = calculatedNumber
        this.setState({ benchmark })
      } else if (event.target.name === 'agreeableness') {

        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['traits'][3]['score'] = calculatedNumber
        this.setState({ benchmark })
      } else if (event.target.name === 'neuroticism') {

        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['traits'][4]['score'] = calculatedNumber
        this.setState({ benchmark })
      } else if (event.target.name.includes('interests')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]


        let valueArray = event.target.value.split("%")
        let rawNumber = valueArray[0]
        let calculatedNumber = (rawNumber / 20)
        benchmark['interests'][index]['score'] = calculatedNumber

        this.setState({ benchmark })
        console.log('show first: ', rawNumber, calculatedNumber)
      } else if (event.target.name === 'generalInterests') {

        benchmark['generalInterests'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'degreeRequirements') {

        benchmark['degreeRequirements'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'idealMajors') {

        benchmark['idealMajors'] = event.target.value
        this.setState({ benchmark })

      } else if (event.target.name === 'gpaRange') {

        benchmark['gpaRange'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'gradYearRange') {
        benchmark['gradYearRange'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'courseHours') {

        benchmark['courseHours'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'strongFitCues') {

        benchmark['strongFitCues'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'poorFitCues') {

        benchmark['poorFitCues'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'testScores') {

        benchmark['testScores'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name.includes('items')) {
        const nameArray = event.target.name.split("|")

        const type = nameArray[1]
        const subtype = nameArray[2]
        const index = nameArray[3]

        if (type === 'course') {
          let courses = this.state.benchmark.courses
          courses[index][subtype] = event.target.value
          benchmark['courses'] = courses
        } else if (type === 'certification') {
          let certifications = this.state.benchmark.certifications
          certifications[index][subtype] = event.target.value
          benchmark['certifications'] = certifications
        }

        if (!changes.includes(nameArray[0])) {
          changes[changes.length - 1] = type + 's'
        }
        this.setState({ benchmark })

      } else if (event.target.name === 'gender') {
        console.log('show benchmark in gender: ', benchmark)
        benchmark['gender'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'race') {

        benchmark['race'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'veteran') {

        benchmark['veteran'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'lowIncome') {

        benchmark['lowIncome'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'fosterYouth') {

        benchmark['fosterYouth'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'homeless') {

        benchmark['homeless'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'incarcerated') {

        benchmark['incarcerated'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'lgbtqia') {

        benchmark['lgbtqia'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'disability') {

        benchmark['disability'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'firstGenImmigrant') {

        benchmark['firstGenImmigrant'] = event.target.value
        this.setState({ benchmark })
      } else if (event.target.name === 'firstGenCollegeStudent') {

        benchmark['firstGenCollegeStudent'] = event.target.value
        this.setState({ benchmark })
      }
    }

    connectEmployer() {
      console.log('connectEmployer called')

      this.setState({ serverSuccessMessage: null, serverErrorMessage: null})

      const accountCode = this.state.selectedJob.accountCode
      const postingId = this.state.selectedJob._id
      const postingName = this.state.selectedJob.title

      let candidateFirstName = this.state.selectedApplication.firstName
      let candidateLastName = this.state.selectedApplication.lastName
      let candidateEmail = this.state.selectedApplication.email
      const employerMessage = this.state.selectedApplication.employerMessage

      const orgName = this.props.orgName
      const orgContactFirstName = this.props.orgContactFirstName
      const orgContactLastName = this.props.orgContactLastName
      const orgContactEmail = this.props.orgContactEmail
      const orgContactTitle = this.props.orgContactTitle
      const orgProgramName = this.props.orgProgramName

      const employerName = this.props.employerName
      const employerContactFirstName = this.props.employerContactFirstName
      const employerContactEmail = this.props.employerContactEmail

      console.log('we got employerContactEmail ', employerContactEmail)

      //send email to employer
      Axios.post('/api/top-candidates/connect', {
        accountCode, postingId, postingName,
        candidateFirstName, candidateLastName, candidateEmail, employerMessage,
        orgName, orgContactFirstName, orgContactLastName, orgContactEmail, orgContactTitle, orgProgramName,
        employerName, employerContactFirstName, employerContactEmail
      })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Message successfully sent', response.data);
          this.setState({ serverSuccessMessage: 'Message was successfully sent!'})

          const _id = this.state.selectedApplication._id
          const connected = true
          const updatedAt = new Date()

          Axios.post('/api/applications', { //sessions: sessions
            _id, connected, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Application save worked', response.data);
              this.setState({ serverSuccessMessage: 'A email connecting you to the candidate has been sent. Please check your email!' })

            } else {
              console.error('there was an error saving the application');
              this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
            }
          }).catch((error) => {
              console.log('Application save did not work', error);
              this.setState({ serverErrorMessage: 'there was an error saving ' + error })
          });

        } else {
          console.error('there was an error sending the message');
          this.setState({ serverErrorMessage: 'There was an error sending out the message ' + response.data.message})
        }
      }).catch((error) => {
          console.log('there was an error sending the message', error);
          this.setState({ serverErrorMessage: 'There was an error sending out the message ' + error})
      });
    }

    renderMatchScore() {
      console.log('renderMatchScore called')

      let matchScore = 0
      if (this.state.selectedJob) {
        if (this.state.selectedJob.postType === "Track" || this.state.selectedJob.subPostType === 'Track') {
          matchScore = this.state.selectedApplication.matches[this.props.matchIndex]
        } else {
          if (this.props.isCareerAssessments) {
            matchScore = this.state.selectedApplication.match
          } else {
            matchScore = 0
          }
        }

        const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
        console.log('show matchScore -----: ', matchScore, primaryColor)

        return (
          <div>
            <button className="btn background-button" onClick={() => this.viewDetails('match')}>
              {(this.state.benchmark && (this.state.benchmark.orgCode || this.state.benchmark.accountCode)) ? (
                <div key="match" className="width-150 center-item">
                  <CircularProgressbar
                    value={matchScore}
                    text={`${matchScore}%`}
                    styles={{
                      path: { stroke: `${primaryColor}, ${matchScore / 100}` },
                      text: { fill: primaryColor, fontSize: '24px' },
                      trail: { stroke: 'transparent' }
                    }}
                  />
                </div>
              ) : (
                <div key="match" className="width-150 center-item cta-border padding-30 bottom-margin-20">
                  <p className="cta-color full-width center-text">No Benchmark Found</p>
                </div>
              )}
            </button>
          </div>
        )
      }
    }

    renderSkills(type) {
      console.log('renderSkills called', this.state.benchmark)

      let rows = []
      let skillType = ''
      if (type === 'hard') {
        skillType = "Hard Skill"
      } else {
        skillType = "Soft Skill"
      }

      // console.log('testing 111 ', skillScores, benchmark)
      if (this.props.source === 'Employer|Edit') {
        let skills = []
        if (skillType === 'Hard Skill') {
          skills = this.state.benchmarkHardSkills
        } else {
          skills = this.state.benchmarkSoftSkills
        }

        for (let i = 1; i <= 6; i++) {
          // console.log('testing 222')
          rows.push(
            <div key={i} className="row-5">
              <div className="float-left">
                <ul className="horizontal-list center-text">
                  <li><img src={fullStar} alt="Guided Compass full star icon" className="image-auto-15 horizontal-margin-tiny"/></li>
                  <li><img src={fullStar} alt="Guided Compass full star icon" className="image-auto-15 horizontal-margin-tiny"/></li>
                  <li><img src={fullStar} alt="Guided Compass full star icon" className="image-auto-15 horizontal-margin-tiny"/></li>
                  <li><img src={fullStar} alt="Guided Compass full star icon" className="image-auto-15 horizontal-margin-tiny"/></li>
                  <li><img src={fullStar} alt="Guided Compass full star icon" className="image-auto-15 horizontal-margin-tiny"/></li>
                </ul>
              </div>
              <div className="float-left">
                <p className="description-text-3 description-text-color left-margin-5">{(skills && skills[i - 1]) ? skills[i - 1] : ""}</p>
              </div>

              <div className="clear"/>
            </div>
          )
        }
      } else {

        let skillScores = []
        let benchmark = null
        if (this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') {
          skillScores = this.state.selectedApplication.skillScores[this.props.matchIndex]
          // let selectedBenchmark = null
          for (let k = 1; k <= this.props.benchmarkOptions.length; k++) {

            if (this.state.selectedJob.tracks[this.props.matchIndex].benchmark._id === this.props.benchmarkOptions[k - 1]._id) {
              benchmark = this.props.benchmarkOptions[k - 1]
            }
          }
        } else {
          skillScores = this.state.selectedApplication.skillScores
          benchmark = this.state.benchmark
        }

        console.log('have skillScores: ', this.state.selectedApplication, skillScores)

        if (benchmark) {
          console.log('testing 000', benchmark)
          if (benchmark._id) {
            console.log('testing 111')
            let counter = 1
            for (let i = 1; i <= benchmark.skills.length; i++) {
              console.log('testing 222')
              if (benchmark.skills[i - 1].skillType === skillType && counter < 7) {

                counter = counter + 1

                let skillScore = 0
                if (skillScores) {
                  for (let j = 1; j <= skillScores.length; j++) {
                    console.log('show skillScore: ', skillScores[j - 1], benchmark.skills[i - 1].title)
                    if (benchmark.skills[i - 1].title === skillScores[j - 1].title) {
                      skillScore = skillScores[j - 1].score
                      if (i < 2) {
                        console.log('show me skillscore ', skillScores[j - 1])
                      }
                    }
                  }
                }

                // let demoingProduct = true
                // if (skillScore === 0 && this.props.accountCode === 'guidedcompass' && demoingProduct) {
                //   skillScore = Math.floor(Math.random() * 5) + 1
                // } else if (skillScore === 0 && this.props.activeOrg === 'unite-la' && demoingProduct) {
                //   skillScore = Math.floor(Math.random() * 5) + 1
                // }

                rows.push(
                  <div key={i} className="row-5">
                    <div className="float-left">
                      <ul className="horizontal-list center-text">
                        <li><img src={(skillScore >= 1) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                        <li><img src={(skillScore >= 2) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                        <li><img src={(skillScore >= 3) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                        <li><img src={(skillScore >= 4) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                        <li><img src={(skillScore >= 5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                      </ul>
                    </div>
                    <div className="float-left">
                      <p className="description-text-3 description-text-color left-margin-5">{benchmark.skills[i - 1].title}</p>
                    </div>

                    <div className="clear"/>
                  </div>
                )
              }
            }
          }
        }
      }
      return rows
    }

    renderIdealSkills() {
      console.log('renderIdealSkills called')

      let rows = []

      let hardSkillCounter = 0
      let softSkillCounter = 0
      // let softSkillCounter2 = 0

      for (let i = 1; i <= 6; i++) {
        if (i < 4) {
          hardSkillCounter = hardSkillCounter + 2

          let skillName1 = ''
          let skillName2 = ''
          if (this.state.benchmarkHardSkills) {
            if (this.state.benchmarkHardSkills[hardSkillCounter - 2]) {
              skillName1 = this.state.benchmarkHardSkills[hardSkillCounter - 2]
            }
            if (this.state.benchmarkHardSkills[hardSkillCounter - 1]) {
              skillName2 = this.state.benchmarkHardSkills[hardSkillCounter - 1]
            }
          }

          const index = i - 1

          rows.push(
            <div key={i.toString() + "idealSkills"}>
              <div className="edit-profile-row">
                <div className="name-container">
                  <p className="profile-label">Hard Skill #{hardSkillCounter - 1}</p>
                  <input type="text" className="text-field" placeholder="Name of skill..." name={"benchmarkHardSkills|" + (hardSkillCounter - 2)} value={skillName1} onChange={this.formChangeHandler}></input>
                </div>
                <div className="name-container">
                  <p className="profile-label">Hard Skill #{hardSkillCounter}</p>
                  <input type="text" className="text-field" placeholder="Name of skill..." name={"benchmarkHardSkills|" + (hardSkillCounter - 1)} value={skillName2} onChange={this.formChangeHandler}></input>
                </div>
                <div className="clear" />
                {console.log('compare the two: ', this.state.selectedIndex, index, hardSkillCounter)}
                {(Number(this.state.selectedIndex) === (hardSkillCounter - 2) && this.state.skillOptions.length > 0) && (
                  <div className="full-width">
                    <div className="spacer" />

                    <div className="name-container">
                      {this.state.skillOptions.map((value, optionIndex) =>
                        <div key={value._id} className="left-text full-width row-5">
                          <button className="background-button" onClick={() => this.skillClicked(Number(this.state.selectedIndex), optionIndex, 'Hard Skill')}>
                            <div className="full-width horizontal-padding-5">
                              <div className="float-left right-margin">
                                <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                              </div>
                              <div className="float-left">
                                <p className="cta-color">{value.name}</p>
                              </div>
                            </div>
                          </button>
                        </div>)}
                    </div>
                    <div className="clear" />
                  </div>
                )}
                {(Number(this.state.selectedIndex) === (hardSkillCounter - 1) && this.state.skillOptions.length > 0) && (
                  <div className="full-width">
                    <div className="spacer" />
                    <div className="name-container" />

                    <div className="name-container">
                      {this.state.skillOptions.map((value, optionIndex) =>
                        <div key={value._id} className="left-text full-width row-5">
                          <button className="background-button" onClick={() => this.skillClicked(Number(this.state.selectedIndex), optionIndex, 'Hard Skill')}>
                            <div className="full-width horizontal-padding-5">
                              <div className="float-left right-margin">
                                <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                              </div>
                              <div className="float-left">
                                <p className="cta-color">{value.name}</p>
                              </div>
                            </div>
                          </button>
                        </div>)}
                    </div>
                    <div className="clear" />
                  </div>
                )}
              </div>
              {(i === 3) && (
                <div>
                  <div className="spacer"/><div className="spacer"/>
                  <hr className="clear-margin clear-padding"/>
                  <div className="spacer"/><div className="spacer"/>
                </div>
              )}
            </div>
          )
        } else {
          softSkillCounter = softSkillCounter + 2

          let skillName1 = ''
          let skillName2 = ''

          if (this.state.benchmarkSoftSkills) {
            if (this.state.benchmarkSoftSkills[softSkillCounter - 2]) {
              skillName1 = this.state.benchmarkSoftSkills[softSkillCounter - 2]
            }
            if (this.state.benchmarkSoftSkills[softSkillCounter - 1]) {
              skillName2 = this.state.benchmarkSoftSkills[softSkillCounter - 1]
            }
          }

          rows.push(
            <div key={i.toString() + "idealSkills"}>
              <div className="edit-profile-row">
                <div className="name-container">
                  <p className="profile-label">Soft Skill #{softSkillCounter - 1}</p>
                  <input type="text" className="text-field" placeholder="Name of skill..." name={"benchmarkSoftSkills|" + (softSkillCounter - 2)} value={skillName1} onChange={this.formChangeHandler}></input>
                </div>
                <div className="name-container">
                  <p className="profile-label">Soft Skill #{softSkillCounter}</p>
                  <input type="text" className="text-field" placeholder="Name of skill..." name={"benchmarkSoftSkills|" + (softSkillCounter - 1)} value={skillName2} onChange={this.formChangeHandler}></input>
                </div>
                <div className="clear" />
              </div>
            </div>
          )
        }
      }

      return rows
    }

    searchSkills(skills, type, index) {
      console.log('searchSkillTraits ', skills, type, index)

      if (skills[index] === '') {
        const skillOptions = []
        this.setState({ skillOptions })
      } else {
        const skillTrait = skills[index]
        Axios.get('/api/skilltrait/search', { params: { skillTrait, type } })
        .then((response) => {
          console.log('Skilltrait search query attempted', response.data);

            if (response.data.success) {
              console.log('skillTrait search query worked')

              const skillOptions = response.data.skills
              const selectedIndex = index
              this.setState({ skillOptions, selectedIndex });

            } else {
              console.log('skilltrait search query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Employer search query did not work for some reason', error);
        });
      }
    }

    skillClicked(index, optionIndex, type) {
      console.log('skillClicked', index, optionIndex, type, this.state.skillOptions)

      if (type === 'trait') {
        console.log('this is an error. This is now a dropdown.')
      } else {
        const name = this.state.skillOptions[optionIndex].name
        // const skillType = this.state.skillOptions[optionIndex].type
        // const rating = ''
        const skillOptions = []

        let benchmarkHardSkills = this.state.benchmarkHardSkills
        let benchmarkSoftSkills = this.state.benchmarkSoftSkills
        if (type === 'Hard Skill') {
          benchmarkHardSkills[index] = name
        } else {
          benchmarkSoftSkills[index] = name
        }

        this.setState({ benchmarkHardSkills, benchmarkSoftSkills, skillOptions })
      }
    }

    viewDetails(type) {
      console.log('viewDetails ', type)

      if (type === 'endorsements') {
        this.setState({ modalIsOpen: true, showEndorsements: true, showMatchDetails: false, showProjects: false, showTraits: false, showInterview: false, showEducation: false, showSkills: false, showDiversity: false });
      } else if (type === 'match') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: true, showProjects: false, showTraits: false, showInterview: false, showEducation: false, showSkills: false, showDiversity: false });
      } else if (type === 'projects') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: true, showTraits: false, showInterview: false, showEducation: false, showSkills: false, showDiversity: false });
      } else if (type === 'personality') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: true, showInterview: false, showEducation: false, showSkills: false, showDiversity: false });
      } else if (type === 'interview') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showInterview: true, showEducation: false, showSkills: false, showDiversity: false });
      } else if (type === 'education') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showInterview: false, showEducation: true, showSkills: false, showDiversity: false });
      } else if (type === 'skills') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showInterview: false, showEducation: false, showSkills: true, showDiversity: false });
      } else if (type === 'diversity') {
        this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showInterview: false, showEducation: false, showSkills: false, showDiversity: true });
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showEndorsements: false, showMatchDetails: false, enlargeImage: false, selectedImage: null });
    }

    renderInterviews() {
      console.log('renderInterviews called')

      let rows = []

      for (let i = 1; i <= this.state.selectedJob.tracks.length; i++) {

        const trackIndex = i - 1

        let nameName = "interview|name|" + i
        let dateName = "interview|date|" + i
        let scoreName = "interview|score|" + i

        rows.push(
          <div key={i}>
            <p className="half-bold-text">#{i} {this.state.selectedJob.tracks[i - 1].name}</p>
            <div className="relative-column-60">
              <p className="profile-descriptor">Interviewer Name</p>
              <input type="text" className="text-field" placeholder="Add the name..." name={nameName} value={this.state.interviews[i - 1].interviewerName} onChange={this.formChangeHandler}></input>
            </div>
            <div className="relative-column-20">
              <p className="profile-descriptor">Interview Date</p>
              <input type="date" className="date-field" placeholder="Add a date..." name={dateName} value={this.state.interviews[i - 1].date} onChange={this.formChangeHandler}></input>
            </div>
            <div className="relative-column-20">
              <p className="profile-descriptor">Interview Score</p>
              <select name={scoreName} value={this.state.interviews[i - 1].score} onChange={this.formChangeHandler} className="dropdown">
                {this.state.scoreOptions.map(value =>
                  <option key={value.key} value={value}>{value}</option>
                )}
              </select>
            </div>
            <div className="clear"/>
            <div className="float-right top-padding">
              <Link className="secret-link" to={'/organizations/' + this.state.org + '/postings/' + this.state.selectedApplication._id + '/applications/' + this.state.selectedApplication._id + '/interview'} state={{ selectedApplication: this.state.selectedApplication, selectedTrack: this.state.selectedJob.tracks[trackIndex], interview: this.state.interviews[trackIndex], selectedJob: this.state.selectedJob, trackIndex }}>
                <div className="float-right horizontal-padding-7">
                  <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-12"/>
                </div>
                <div className="float-right horizontal-padding-7">
                  <label className="description-text-2 description-text-color half-bold-text">Edit Details</label>
                  <div className="half-spacer" />
                </div>
              </Link>
            </div>
            <div className="clear"/>
            <div className="half-spacer" />
          </div>
        )
      }

      return rows
    }

    toggleView(source) {
      console.log('toggleView called', source)

      if (source === 'ca') {
        if (this.state.showCustomAssessment) {
          this.setState({ showCustomAssessment: false, caRotation: 0 })
        } else {
          this.setState({ showCustomAssessment: true, caRotation: 90 })
        }
      } else if (source === 'wp') {
        if (this.state.showWorkPreferences) {
          this.setState({ showWorkPreferences: false, wpRotation: 0 })
        } else {
          this.setState({ showWorkPreferences: true, wpRotation: 90 })
        }
      }
    }

    renderSpecificSkillTraits(skillTraits) {
      console.log('renderSpecificSkillTraits called', skillTraits)

      let rows = []

      if (skillTraits) {

        let hardSkills = []
        let softSkills = []
        let traits = []

        for (let i = 1; i <= skillTraits.length; i++) {
          if (skillTraits[i - 1].skillType === 'Hard Skill') {
            hardSkills.push(skillTraits[i - 1])
          } else if (skillTraits[i - 1].skillType === 'Soft Skill') {
            softSkills.push(skillTraits[i - 1])
          } else {
            traits.push(skillTraits[i - 1])
          }
        }

        rows.push(
          <div key="skills">

            <p className="row-5">Hard Skills</p>
            {hardSkills.map(value =>
              <div>
                <div className="relative-column-30">
                  <p className="description-text-2 bold-text">{value.name}</p>
                </div>
                <div className="relative-column-65">
                  <div className="half-spacer" />
                  <div className="progress-bar primary-border">
                    <div className={"filler primary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                  </div>
                </div>
                <div className="relative-column-5 center-text">
                  <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                </div>
                <div className="clear" />
                <div className="spacer" />
              </div>
            )}

            <div className="spacer"/><div className="half-spacer"/>

            {(softSkills && softSkills.length > 0) && (
              <div>
                <p className="row-5">Soft Skills</p>
                {softSkills.map(value =>
                  <div>
                    <div className="relative-column-30">
                      <p className="description-text-2 bold-text">{value.name}</p>
                    </div>
                    <div className="relative-column-65">
                      <div className="half-spacer" />
                      <div className="progress-bar secondary-border">
                        <div className={"filler secondary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="relative-column-5 center-text">
                      <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}

                <div className="spacer"/><div className="half-spacer"/>
              </div>
            )}

            {(traits && traits.length > 0) && (
              <div>
                <p className="row-5">Traits</p>
                {traits.map(value =>
                  <div>
                    <div className="relative-column-30">
                      <p className="description-text-2 bold-text">{value.name}</p>
                    </div>
                    <div className="relative-column-65">
                      <div className="half-spacer" />
                      <div className="progress-bar tertiary-border">
                        <div className={"filler tertiary-background"} style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="relative-column-5 center-text">
                      <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    calculateBenchmarkWeight(type, subType) {
      console.log('calculateBenchmarkWeight ')

      let numerator = 0
      let denominator = 0

      let benchmark = this.state.benchmark
      if (this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') {
        benchmark = this.props.trackBenchmark
      } else if (this.props.benchmark) {
        benchmark = this.props.benchmark
      }

      if (benchmark) {
        console.log('in benchmark: ', benchmark)
        //preferences
        if (benchmark.workPreferenceWeight) {
          denominator = denominator + benchmark.workPreferenceWeight
          if (type === 'preferences') {
            numerator = numerator + benchmark.workPreferenceWeight
          }
        }

        //interests
        if (benchmark.interests) {
          for (let i = 1; i <= benchmark.interests.length; i++) {
            if (benchmark.interests[i - 1].weight) {
              denominator = denominator + Number(benchmark.interests[i - 1].weight)
              if (type === 'interests') {
                numerator = numerator + Number(benchmark.interests[i - 1].weight)
              }
            }
          }
        }

        //traits
        if (benchmark.traits) {
          for (let i = 1; i <= benchmark.traits.length; i++) {
            if (benchmark.traits[i - 1].weight) {
              denominator = denominator + Number(benchmark.traits[i - 1].weight)
              if (type === 'traits') {
                numerator = numerator + Number(benchmark.traits[i - 1].weight)
              }
            }
          }
        }

        //values
        if (benchmark.valuesWeight) {
          denominator = denominator + benchmark.valuesWeight
          if (type === 'values') {
            numerator = numerator + benchmark.valuesWeight
          }
        }

        //skills
        if (benchmark.skills) {
          for (let i = 1; i <= benchmark.skills.length; i++) {
            if (benchmark.skills[i - 1].weight) {
              denominator = denominator + Number(benchmark.skills[i - 1].weight)
              if (type === 'skills') {
                numerator = numerator + Number(benchmark.skills[i - 1].weight)
              }
            }
          }
        }

        //endorsements
        if (benchmark.thirdPartyAssessmentWeight) {
          denominator = denominator + benchmark.thirdPartyAssessmentWeight
          if (type === 'thirdPartyAssessments') {
            numerator = numerator + benchmark.thirdPartyAssessmentWeight
          }
        }

        //endorsements
        if (benchmark.endorsementWeight) {
          denominator = denominator + benchmark.endorsementWeight
          if (type === 'endorsements') {
            numerator = numerator + benchmark.endorsementWeight
          }
        }

        //education
        if (benchmark.educationWeight) {
          denominator = denominator + benchmark.educationWeight
          if (type === 'education') {
            numerator = numerator + benchmark.educationWeight
          }
        }

        //projects
        if (benchmark.projectWeight) {
          denominator = denominator + benchmark.projectWeight
          if (type === 'projects') {
            numerator = numerator + benchmark.projectWeight
          }
        }

        //experience
        if (benchmark.experienceWeight) {
          denominator = denominator + benchmark.experienceWeight
          if (type === 'experience') {
            numerator = numerator + benchmark.experienceWeight
          }
        }

        //interview
        if (benchmark.interview) {
          denominator = denominator + benchmark.interview.weight
          if (type === 'interview') {
            numerator = numerator + benchmark.interview.weight
          }
        }

        if (this.props.activeOrg === 'c2c') {

          if (benchmark.politicalParties) {
            for (let i = 1; i <= benchmark.politicalParties.length; i++) {
              if (benchmark.politicalParties[i - 1].weight) {
                denominator = denominator + Number(benchmark.politicalParties[i - 1].weight)
                if (type === 'politicalPreference') {
                  numerator = numerator + Number(benchmark.politicalParties[i - 1].weight)
                }
              }
            }
          }

          if (benchmark.hometownWeight) {
            denominator = denominator + benchmark.hometownWeight
            if (type === 'homeState') {
              numerator = numerator + benchmark.hometownWeight
            }
          }

          if (benchmark.adversityScoreWeight) {
            denominator = denominator + benchmark.adversityScoreWeight
            if (type === 'adversityScore') {
              numerator = numerator + benchmark.adversityScoreWeight
            }
          }

        }
      }

      let weight = ((numerator / denominator) * 100).toFixed()

      console.log('show output: ', numerator, denominator, weight)

      if (subType === 'denominator') {
        return denominator
      } else {
        return weight
      }
    }

    renderEndorsements(endorsements) {
      console.log('renderEndorsements called')

      let rows = []
      //need to eventually be able to filter this
      for (let i = 1; i <= endorsements.length; i++) {

        let showEndorsement = true
        if (this.props.source === 'Student' && !endorsements[i - 1].isTransparent) {
          showEndorsement = false
        }

        if (showEndorsement) {
          rows.push(
            <div key="specificEndorsement" className="row-10">
              <hr className="clear-margin clear-padding" />
              <div className="spacer" /><div className="spacer" /><div className="spacer" />

              <div className="fixed-column-90">
                <div className="dark-background width-90 all-padding-10">
                  <p className="heading-text-2 bold-text white-text center-text">{(endorsements[i - 1].overallRecommendation) ? Number(endorsements[i - 1].overallRecommendation) * 20 : "N/A"}%</p>
                  <p className="description-text-5 white-text center-text">OVERALL</p>
                </div>
              </div>
              <div className="endorsement-name">
                <p className="heading-text-4">{endorsements[i - 1].senderFirstName} {endorsements[i - 1].senderLastName}</p>
                <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                <p className="description-text-2">{endorsements[i - 1].relationship}</p>
              </div>
              <div className="endorsement-pathway">
                <p>{endorsements[i - 1].pathway} Pathway</p>
                <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                {(endorsements[i - 1].createdAt && new Date(endorsements[i - 1].createdAt)) && (
                  <p className="description-text-2">{this.formatDate(new Date(endorsements[i - 1].createdAt))}</p>
                )}
              </div>

              <div className="clear" />
              <div className="spacer" /><div className="spacer" />

              <div>
                {this.renderSpecificSkillTraits(endorsements[i - 1].skillTraits)}
              </div>

              <div className="clear" />
              <div className="spacer" /><div className="spacer" />

              <div>
                <p className="heading-text-6 bold-text">Examples of Above Skills & Traits</p>
                <div className="spacer" /><div className="spacer" />
                {endorsements[i - 1].examples.map(value =>
                  <div>
                    <p className="description-text-1">{value.skillTrait} Example</p>
                    <div className="half-spacer" />
                    <p className="description-text-3 description-text-color bold-text">{value.example}</p>
                    <div className="spacer" /><div className="spacer" />
                  </div>
                )}

              </div>

              <div className="clear" />
              <div className="spacer" /><div className="spacer" /><div className="spacer" />

            </div>
          )
        } else {

          rows.push(
            <div key={i - 1} className="padding-40 flex-container flex-center">
              <div className="center-text">
                <img src={confidentialityIcon} alt="Student icon" className="image-auto-100 vertical-margin-20 center-horizontally" />
                <div className="clear" />
                <p className="heading-text-4">This Endorsement is Confidential</p>
                <div className="spacer"/>
                <p className="description-text-color">This endorsement has been marked confidential by the endorser. It will automatically be imported into internship applications.</p>
                <div className="spacer"/><div className="spacer"/><div className="spacer"/>
              </div>
            </div>
          )
        }
      }

      return rows
    }

    formatDate(passedDate) {
      console.log('formatDate called', passedDate)

      let newDate = passedDate.toString()
      newDate = newDate.substring(4,16)

      return newDate
    }

    renderWork(type) {
      console.log('renderWork called ', type)

      let rows = []

      if (type === 'paid') {

        let experience = this.state.selectedApplication.experience
        if (experience) {
          for (let i = 1; i <= experience.length; i++) {

            if (experience[i - 1].wasPaid) {

              const startDateString = experience[i - 1].startDate
              const endDateString = experience[i - 1].endDate
              const hoursPerWeek = experience[i - 1].hoursPerWeek

              let applicantHours = 0
              // console.log('t1 hours', startDateString, endDateString, hoursPerWeek)
              if (startDateString && endDateString && hoursPerWeek) {

                function convertMonth(longMonth) {
                  console.log('convertMonth was called ', longMonth)

                  let shortMonth = '01'
                  if (longMonth === 'February') {
                    shortMonth = '02'
                  } else if (longMonth === 'March') {
                    shortMonth = '03'
                  } else if (longMonth === 'April') {
                    shortMonth = '04'
                  } else if (longMonth === 'May') {
                    shortMonth = '05'
                  } else if (longMonth === 'June') {
                    shortMonth = '06'
                  } else if (longMonth === 'July') {
                    shortMonth = '07'
                  } else if (longMonth === 'August') {
                    shortMonth = '08'
                  } else if (longMonth === 'September') {
                    shortMonth = '09'
                  } else if (longMonth === 'October') {
                    shortMonth = '10'
                  } else if (longMonth === 'November') {
                    shortMonth = '11'
                  } else if (longMonth === 'December') {
                    shortMonth = '12'
                  }

                  return shortMonth
                }

                const startDateArray = startDateString.split(" ")
                const startDateMonth = convertMonth(startDateArray[0])
                let startDate = new Date(startDateArray[1], startDateMonth, '01')
                if (!startDateArray[1]) {
                  startDate = new Date(startDateArray[0])
                }

                const endDateArray = endDateString.split(" ")
                const endDateMonth = convertMonth(endDateArray[0])
                let endDate = new Date(endDateArray[1], endDateMonth, '01')
                if (!endDateArray[1]) {
                  endDate = new Date(endDateArray[0])
                }
                // console.log('startDate stuff: ', startDate, endDate)

                const dateDifference = ((((endDate - startDate) / 1000) / 60) / 60) / 24

                const hoursPerWeekArray = hoursPerWeek.split(" ")
                const hpd = Number(hoursPerWeekArray[1]) / 7

                applicantHours = Math.round(hpd * dateDifference)

                // console.log('t2 hours', startDateArray, startDateMonth, hpd, dateDifference)

              }

              rows.push(
                <div key={"experience" + i}>
                  <div className="float-left">
                    <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{applicantHours}</label>
                    <p className="description-text-2 top-margin-negative-5 clear-padding">hours</p>
                  </div>

                  <div className="vertical-ruler height-50 horizontal-margin-4 top-margin"/>

                  <div className="calc-column-offset-140">
                    <p>{experience[i - 1].jobTitle} | {experience[i - 1].employerName}</p>
                    <p className="description-text-2">{experience[i - 1].startDate} - {experience[i - 1].endDate} | {experience[i - 1].hoursPerWeek}/Week</p>
                    <p className="description-text-2">{experience[i - 1].jobFunction} - {experience[i - 1].industry}</p>
                  </div>
                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
            }
          }
        }

      } else if (type === 'project') {

        let projects = this.state.selectedApplication.projects
        if (projects) {
          for (let i = 1; i <= projects.length; i++) {

            let projectHours = 0
            if (projects[i - 1].hours === '< 10') {
              projectHours = 5
            } else if (projects[i - 1].hours === '10 - 50') {
              projectHours = 30
            } else if (projects[i - 1].hours === '50 - 100') {
              projectHours = 75
            } else if (projects[i - 1].hours === '100 - 500') {
              projectHours = 300
            } else if (projects[i - 1].hours === '500 - 1000') {
              projectHours = 750
            } else if (projects[i - 1].hours === '1000 - 5000') {
              projectHours = 3000
            } else if (projects[i - 1].hours === '5000 - 10000') {
              projectHours = 7500
            } else if (projects[i - 1].hours === '10000+') {
              projectHours = 10000
            }

            rows.push(
              <div key={"project" + i}>
                <div className="float-left">
                  <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{projectHours}</label>
                  <p className="description-text-2 top-margin-negative-5 clear-padding">hours</p>
                </div>

                <div className="vertical-ruler height-50 horizontal-margin-4 top-margin"/>

                <div className="calc-column-offset-140">
                  <p>{projects[i - 1].name} {(projects[i - 1].url) &&  <span>(<a href={projects[i - 1].url} target="_blank">view link</a>)</span>}</p>
                  <p className="description-text-2">{projects[i - 1].startDate} - {projects[i - 1].endDate} | {projects[i - 1].collaborators.length} collaborators</p>
                  <p className="description-text-2">{projects[i - 1].category}</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
              </div>
            )
          }
        }

      } else if (type === 'unpaid') {

        let experience = this.state.selectedApplication.experience
        if (experience) {
          for (let i = 1; i <= experience.length; i++) {

            if (!experience[i - 1].wasPaid) {

              const startDateString = experience[i - 1].startDate
              const endDateString = experience[i - 1].endDate
              const hoursPerWeek = experience[i - 1].hoursPerWeek

              let applicantHours = 0

              if (startDateString && endDateString && hoursPerWeek) {

                function convertMonth(longMonth) {
                  console.log('convertMonth was called ', longMonth)

                  let shortMonth = '01'
                  if (longMonth === 'February') {
                    shortMonth = '02'
                  } else if (longMonth === 'March') {
                    shortMonth = '03'
                  } else if (longMonth === 'April') {
                    shortMonth = '04'
                  } else if (longMonth === 'May') {
                    shortMonth = '05'
                  } else if (longMonth === 'June') {
                    shortMonth = '06'
                  } else if (longMonth === 'July') {
                    shortMonth = '07'
                  } else if (longMonth === 'August') {
                    shortMonth = '08'
                  } else if (longMonth === 'September') {
                    shortMonth = '09'
                  } else if (longMonth === 'October') {
                    shortMonth = '10'
                  } else if (longMonth === 'November') {
                    shortMonth = '11'
                  } else if (longMonth === 'December') {
                    shortMonth = '12'
                  }

                  return shortMonth
                }

                const startDateArray = startDateString.split(" ")
                const startDateMonth = convertMonth(startDateArray[0])
                const startDate = new Date(startDateArray[1], startDateMonth, '01')

                const endDateArray = endDateString.split(" ")
                const endDateMonth = convertMonth(endDateArray[0])
                const endDate = new Date(endDateArray[1], endDateMonth, '01')

                const dateDifference = ((((endDate - startDate) / 1000) / 60) / 60) / 24

                const hoursPerWeekArray = hoursPerWeek.split(" ")
                const hpd = Number(hoursPerWeekArray[1]) / 7

                applicantHours = Math.round(hpd * dateDifference)

              }

              rows.push(
                <div key={"volunteer" + i}>
                  <div className="float-left">
                    <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{applicantHours}</label>
                    <p className="description-text-2 top-margin-negative-5 clear-padding">hours</p>
                  </div>

                  <div className="vertical-ruler height-50 horizontal-margin-4 top-margin"/>

                  <div className="calc-column-offset-140">
                    <p>{experience[i - 1].jobTitle} | {experience[i - 1].employerName}</p>
                    <p className="description-text-2">{experience[i - 1].startDate} - {experience[i - 1].endDate} | {experience[i - 1].hoursPerWeek}/Week</p>
                    <p className="description-text-2">{experience[i - 1].jobFunction} - {experience[i - 1].industry}</p>
                  </div>
                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
            }
          }
        }

      }

      return rows
    }

    renderIntangibles(type) {
      console.log('renderIntangibles called ', type)

      let rows = []

      if (type === 'myersBriggs') {

        if (this.props.source === 'Employer|Edit') {
          const myersBriggs = this.state.benchmark.myersBriggs
          rows.push(
            <div key={"myersBriggs"}>
              <div>
                <div className="float-left">
                  <select name="myersBriggs" value={myersBriggs} onChange={this.formChangeHandler} className="heading-text-2 cta-color bold-text clear-margin clear-padding width-125">
                    {this.state.myersBriggsOptions.map(value =>
                      <option key={value.key} value={value}>{value}</option>
                    )}
                  </select>
                </div>

                <div className="clear" />
                {(myersBriggs && myersBriggs !== 'N/A') && (
                  <p>Learn about this type here: <a target="_blank" rel="noopener noreferrer" href={"https://www.16personalities.com/" + myersBriggs + "-personality"}>https://www.16personalities.com/{myersBriggs}-personality</a></p>
                )}

              </div>
              <div className="clear" />
              <div className="spacer" /><div className="spacer" />
            </div>
          )
        } else {

          if (this.state.selectedApplication.personalityResults) {
            const myersBriggs = this.state.selectedApplication.personalityResults.myersBriggs
            rows.push(
              <div key={"myersBriggs"}>
                <div className="float-left">
                  <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{myersBriggs}</label>
                  <div className="clear" />
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
              </div>
            )
          }
        }

      } else if (type === 'fiveFactors') {

        if (this.props.source === 'Employer|Edit') {
          const traits = this.state.benchmark.traits
          //traits are the same thing as the five factors as of now
          if (traits) {
            //loop through five factor personalities
            for (let i = 1; i <= traits.length; i++) {

              rows.push(
                <div key={'fiveFactors|' + i}>
                  <div>
                    <div>
                      <select name={traits[i - 1].title.toLowerCase()} value={(Number(traits[i - 1].score) * 20).toString() + '%'} onChange={this.formChangeHandler} className="heading-text-2 cta-color bold-text clear-margin clear-padding width-125">
                        {this.state.percentageOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <p className="description-text-2">{traits[i - 1].title}</p>
                  </div>
                </div>
              )
            }
          }
        } else {
          if (this.state.selectedApplication.personalityResults) {
            const fiveFactors = this.state.selectedApplication.personalityResults.fiveFactors
            rows.push(
              <div key={"fiveFactors"}>
                <div>
                  <p className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(Number(fiveFactors.opennessScore) / 16) * 100}%</p>

                  <p className="description-text-2">Openness</p>
                </div>

                <div>
                  <p className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(Number(fiveFactors.conscientiousnessScore) / 16) * 100}%</p>
                  <p className="description-text-2">Conscientiousness</p>
                </div>

                <div>
                  <p className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(Number(fiveFactors.extraversionScore) / 16) * 100}%</p>
                  <p className="description-text-2">Extraversion</p>
                </div>

                <div>
                  <p className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(Number(fiveFactors.agreeablenessScore) / 16) * 100}%</p>
                  <p className="description-text-2">Agreeableness</p>
                </div>

                <div>
                  <p className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(Number(fiveFactors.neuroticismScore) / 16) * 100}%</p>
                  <p className="description-text-2">Emotional Stability</p>
                </div>

                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
              </div>
            )
          }
        }

      } else if (type === 'interests') {

        if (this.props.source === 'Employer|Edit') {
          let interests = this.state.benchmark.interests
          for (let i = 1; i <= 6; i++) {

            const index = i - 1

            rows.push(
              <div key={"interests" + i}>
                <div className="float-left right-padding-20">
                  <select name={"interests|" + index} value={(interests && interests[i - 1]) ? (interests[i - 1].score * 20) + '%' : ''} onChange={this.formChangeHandler} className="heading-text-2 cta-color bold-text clear-margin clear-padding width-125">
                    {this.state.percentageOptions.map(value =>
                      <option key={value.key} value={value}>{value}</option>
                    )}
                  </select>
                </div>

                <div className="clear" />

                <div className="float-left">
                  <p>{(interests && interests[i - 1]) ? interests[i - 1].title : ''}</p>
                  <p className="description-text-2">{(interests && interests[i - 1]) ? interests[i - 1].description : ''}</p>
                </div>
                <div className="clear" />
                <div className="spacer" /><div className="spacer" />
              </div>
            )
          }
        } else {
          let interestResults = this.state.selectedApplication.interestResults
          if (interestResults) {
            for (let i = 1; i <= interestResults.length; i++) {

              rows.push(
                <div key={"interests" + i}>
                  <div>
                    <div className="float-left right-padding-20">
                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(interestResults[i - 1].score / 40) * 100}%</label>
                    </div>

                    <div className="float-left">
                      <p>{interestResults[i - 1].name}</p>
                      <p className="description-text-2">{interestResults[i - 1].description}</p>
                    </div>
                  </div>

                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
            }
          }
        }
      } else if (type === 'generalInterests') {

        rows.push(
          <div key={"generalInterests"}>
            <p className="profile-label">Ideal General Interests & Passions</p>
            <textarea type="text" className="text-field" placeholder="List ideal, relevant interests and passions relevant to this role separated by commas..." name="generalInterests" value={this.state.benchmark.generalInterests} onChange={this.formChangeHandler}></textarea>
          </div>
        )
      } else if (type === 'workPreferences') {
        console.log('show fo3: ', this.state.functionOptions.length)

        if (this.props.source === 'Employer|Edit') {
          for (let i = 1; i <= this.state.wpQuestions.length; i++) {

            const resultsIndex = i - 1

            let wp = ''

            let question = ''
            if (this.state.wpQuestions[i - 1]) {
              question = this.state.wpQuestions[i - 1].name
            }

            let options = []
            let showQuestion = false

            if (question.includes('How close')) {
              wp = this.state.benchmark.proximityRequirements
              options = this.state.workDistanceOptions
              options[6] = 'unlimited / remote'
              showQuestion = true
              question = 'What is the maximum distance a candidate can be from the employer address to be eligible for hire?'
            } else if (resultsIndex === this.state.wpQuestions.length - 1) {
              //substitute last option for pay
              wp = this.state.benchmark.maxPay
              options = this.state.hourlyPayOptions
              showQuestion = true
            }

            if (showQuestion) {
              rows.push(
                <div key={"workPreferences" + i}>
                  <div>
                    <div>
                      {(resultsIndex === this.state.wpQuestions.length - 1) ? (
                        <p>Q: What is the maximum amount your business is willing to pay (hourly) for this role?</p>
                      ) : (
                        <p>Q: {question}</p>
                      )}

                      {options.map((value, index) =>
                        <div key={i} className="float-left row-5 right-padding">
                          {(value === wp) ? (
                            <button className="background-button float-left tag-container-6 cta-background-color cta-border-color" onClick={() => this.intangibleClicked(value, resultsIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text">{value}</p>
                                </div>
                              </div>
                            </button>
                          ) : (
                            <button className="background-button float-left tag-container-6" onClick={() => this.intangibleClicked(value, resultsIndex)}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2">{value}</p>
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
            }
          }
        } else {
          let wpResults  = this.state.selectedApplication.workPreferenceResults
          if (this.props.workPreferenceResults) {
            wpResults = this.props.workPreferenceResults
          }
          console.log('show me wp: ', wpResults, this.props.workPreferenceResults)

          if (wpResults) {
            for (let i = 1; i <= wpResults.length; i++) {

              let question = ''
              if (this.state.wpQuestions[i - 1]) {
                question = this.state.wpQuestions[i - 1].name
              }

              rows.push(
                <div key={"workPreferences" + i}>
                  <div>
                    <div>
                      <p>Q: {question}</p>
                      <p className="description-text-2">A: {wpResults[i - 1]}</p>
                    </div>
                  </div>

                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
            }
          }

        }
      }

      return rows
    }

    intangibleClicked(passedValue, index) {
      console.log('intangibleClicked called', this.state.functionOptions.length, passedValue, index, this.state.selectedApplication.workPreferenceResults)

      let benchmark = this.state.benchmark
      console.log('compare values: ', this.state.wpQuestions, index)
      if (this.state.wpQuestions[index].name.includes('How close')) {
        benchmark['proximityRequirements'] = passedValue
      } else {
        benchmark['maxPay'] = passedValue
      }
      this.setState({ benchmark })


    }

    renderItems(type) {
      console.log('renderItems called', type)

      let rows = []
      let items = []
      let altType = type
      if (type === 'course') {
        items = this.state.benchmark.courses
      } else if (type === 'certification') {
        items = this.state.benchmark.certifications
        altType = 'certification, license, or badge'
      }

      if (items) {
        for (let i = 1; i <= items.length; i++) {

          const index = i - 1

          let uppercaseType = type.charAt(0).toUpperCase() + type.slice(1)

          rows.push(
            <div key={type + i.toString()}>
              <div className="name-container">
                <p className="profile-label">{uppercaseType} Name #{i}</p>
                <input type="text" className="text-field" placeholder={altType + " name..."} name={"items|" + type + "|name|" + index} value={items[index].name} onChange={this.formChangeHandler}></input>
              </div>
              <div className="name-container">
                <p className="profile-label">URL #{i}</p>
                <input type="text" className="text-field" placeholder={altType + " url..."} name={"items|" + type + "|url|" + index} value={items[index].url} onChange={this.formChangeHandler}></input>
                {(items[index].url && items[index].url !== '' && !items[index].url.startsWith('http')) && (
                  <div>
                    <p className="error-message">Please start your link with http</p>
                  </div>
                )}
              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    addItem(type) {
      console.log('addItem called', type)

      let benchmark = this.state.benchmark

      if (type === 'interviewQuestions') {
        let interviewQuestions = this.state.benchmark.interviewQuestions
        if (interviewQuestions) {
          interviewQuestions.push({ question: '', guidance: '' })
        } else {
          interviewQuestions = [{ question: '', guidance: '' }]
        }

        benchmark['interviewQuestions'] = interviewQuestions
        this.setState({ benchmark })
      } else if (type === 'course') {

        let courses = this.state.benchmark.courses
        if (courses) {
          courses.push({ name: '', url: '' })
        } else {
          courses = [{ name: '', url: '' }]
        }

        benchmark['courses'] = courses
        this.setState({ benchmark })
      } else if (type === 'certification') {
        let certifications = this.state.benchmark.certifications
        if (certifications) {
          certifications.push({ name: '', url: '' })
        } else {
          certifications = [{ name: '', url: '' }]
        }

        benchmark['certifications'] = certifications
        this.setState({ benchmark })
      }
    }

    removeItem(index) {
      let benchmark = this.state.benchmark
      let interviewQuestions = this.state.benchmark.interviewQuestions
      if (interviewQuestions[index]) {
        interviewQuestions.splice(index, 1)
        benchmark['interviewQuestions'] = interviewQuestions
        this.setState({ benchmark })
      }
    }

    calculateHours(type, value) {
      console.log('calculateHours called', type, value)

      if (this.props.source === 'Employer|Edit') {
        let hours = 0
        if (value) {
          if (Number(value)) {
            hours = Number(value)
          } else if (value.includes('-')){
            const nameArray = value.split('-')
            const num1 = Number(nameArray[0].trim().replace(/,/g,""))
            const num2 = Number(nameArray[1].trim().replace(/,/g,""))
            let avg = (num1 + num2) / 2
            hours = avg
          } else if (value.includes('+')){
            const nameArray = value.split('+')
            hours = Number(nameArray[0].trim().replace(/,/g,""))
          }
        }
        return hours
      } else {

        let selectedText = ''

        let hours = 0
        let count = 0

        if (this.state.selectedApplication) {

          if (type === 'total') {
            if (this.state.selectedApplication.projects && this.state.selectedApplication.projects.length > 0) {
              for (let i = 1; i <= this.state.selectedApplication.projects.length; i++) {
                if (this.state.selectedApplication.projects[i - 1].hours) {
                  let value = this.state.selectedApplication.projects[i - 1].hours
                  if (Number(value)) {
                    hours = Number(value)
                  } else if (value.includes('-')){
                    const nameArray = value.split('-')
                    const num1 = Number(nameArray[0].trim().replace(/,/g,""))
                    const num2 = Number(nameArray[1].trim().replace(/,/g,""))
                    let avg = (num1 + num2) / 2
                    hours = avg
                  } else if (value.includes('+')){
                    const nameArray = value.split('+')
                    hours = Number(nameArray[0].trim().replace(/,/g,""))
                  }
                }
              }
            }

            if (this.state.selectedApplication.experience && this.state.selectedApplication.experience.length > 0) {
              for (let i = 1; i <= this.state.selectedApplication.experience.length; i++) {
                if (this.state.selectedApplication.experience[i - 1].hours) {
                  let value = this.state.selectedApplication.projects[i - 1].hours
                  if (Number(value)) {
                    hours = Number(value)
                  } else if (value.includes('-')){
                    const nameArray = value.split('-')
                    const num1 = Number(nameArray[0].trim().replace(/,/g,""))
                    const num2 = Number(nameArray[1].trim().replace(/,/g,""))
                    let avg = (num1 + num2) / 2
                    hours = avg
                  } else if (value.includes('+')){
                    const nameArray = value.split('+')
                    hours = Number(nameArray[0].trim().replace(/,/g,""))
                  }
                }
              }
            }
          } else if (type === 'experience') {
            if (this.state.selectedApplication.experienceHours) {
              hours = this.state.selectedApplication.experienceHours
            }

            selectedText = hours + ' relevant hours spent across ' + count + ' paid work experiences'
          } else if (type === 'project') {
            if (this.state.selectedApplication.experienceHours) {
              hours = this.state.selectedApplication.projectHours
            }

            selectedText = hours + ' relevant hours spent across ' + count + ' projects'
          } else if (type === 'volunteer') {
            if (this.state.selectedApplication.volunteerHours) {
              hours = this.state.selectedApplication.volunteerHours
            }
            selectedText = hours + ' relevant hours spent across ' + count + ' volunteer experiences'
          }
        }



        return selectedText
      }


    }

    calculateIntangiblesText() {
      console.log('calculateIntangiblesText called')

      let maxInterestName = ''
      let maxInterestValue = 0
      let maxTraitName = ''
      let maxTraitValue = 0

      if (this.props.source === 'Employer|Edit') {
        if (this.state.benchmark.interests) {
          for (let i = 1; i <= this.state.benchmark.interests.length; i++) {
            if (this.state.benchmark.interests[i - 1].score > maxInterestValue) {
              maxInterestName = this.state.benchmark.interests[i - 1].title
              maxInterestValue = this.state.benchmark.interests[i - 1].score
            }
          }
        }

        if (this.state.benchmark.traits) {
          for (let i = 1; i <= this.state.benchmark.traits.length; i++) {
            if (this.state.benchmark.traits[i - 1].score > maxTraitValue) {
              maxTraitName = this.state.benchmark.traits[i - 1].title
              maxTraitValue = this.state.benchmark.traits[i - 1].score
            }
          }
        }
      } else {
        if (this.state.selectedApplication) {
          if (this.state.selectedApplication.interestResults) {
            for (let i = 1; i <= this.state.selectedApplication.interestResults.length; i++) {
              if (this.state.selectedApplication.interestResults[i - 1].score > maxInterestValue) {
                maxInterestName = this.state.selectedApplication.interestResults[i - 1].name
                maxInterestValue = this.state.selectedApplication.interestResults[i - 1].score
              }
            }
          }

          if (this.state.selectedApplication.personalityResults) {
            if (this.state.selectedApplication.personalityResults.fiveFactors) {
              if (Number(this.state.selectedApplication.personalityResults.fiveFactors.opennessScore) > maxTraitValue) {
                maxTraitName = 'Openness'
                maxTraitValue = Number(this.state.selectedApplication.personalityResults.fiveFactors.opennessScore)
              }

              if (Number(this.state.selectedApplication.personalityResults.fiveFactors.conscientiousnessScore) > maxTraitValue) {
                maxTraitName = 'Conscientiousness'
                maxTraitValue = Number(this.state.selectedApplication.personalityResults.fiveFactors.conscientiousnessScore)
              }

              if (Number(this.state.selectedApplication.personalityResults.fiveFactors.extraversionScore) > maxTraitValue) {
                maxTraitName = 'Extraversion'
                maxTraitValue = Number(this.state.selectedApplication.personalityResults.fiveFactors.extraversionScore)
              }

              if (Number(this.state.selectedApplication.personalityResults.fiveFactors.agreeablenessScore) > maxTraitValue) {
                maxTraitName = 'Agreeableness'
                maxTraitValue = Number(this.state.selectedApplication.personalityResults.fiveFactors.agreeablenessScore)
              }

              if (Number(this.state.selectedApplication.personalityResults.fiveFactors.neuroticismScore) > maxTraitValue) {
                maxTraitName = 'Neuroticism'
                maxTraitValue = Number(this.state.selectedApplication.personalityResults.fiveFactors.neuroticismScore)
              }
            }
          }
        }
      }

      console.log('show values: ', maxInterestName, maxTraitName)
      let intangiblesText = this.state.selectedApplication.firstName + ' ' + this.state.selectedApplication.lastName  + ' is highly interested in ' + maxInterestName.toLowerCase() + ' careers and tests relatively high in the ' + maxTraitName.toLowerCase() + ' personality trait.'
      return intangiblesText

    }

    renderAllocation() {
      console.log('renderAllocation called')

      let rows = []

      let weights = [
        { name: 'Work Preferences', description: 'Candidates interested in relevant industries and employer attributes are awarded maximum points.', ref: 'preferencesPercent', value: this.state.preferencesPercent, ref2: 'preferences' },
        { name: 'Work Interests', description: 'Candidates interested in careers that require relevant skills are awarded maximum points.', ref: 'interestsPercent', value: this.state.interestsPercent, ref2: 'interests' },
        { name: 'Personality', description: 'Candidates whose personality most matches the sought after type are awarded maximum points.', ref: 'traitsPercent', value: this.state.traitsPercent, ref2: 'traits' },
        { name: 'Values', description: 'Candidates whose values match your preferences are awarded maximum points.', ref: 'valuesPercent', value: this.state.valuesPercent, ref2: 'values' },
        { name: 'Skills', description: 'Candidates who self-assess for the relevant skills are awarded maximum points.', ref: 'skillsPercent', value: this.state.skillsPercent, ref2: 'skills' },
        { name: 'Endorsements', description: 'Candidates with high skill/trait endorsement ratings from close, experts are awarded maximum points.', ref: 'endorsementsPercent', value: this.state.endorsementsPercent, ref2: 'endorsements' },
        { name: 'Education', description: 'Candidates with the target level and focus of education are awarded maximum points.', ref: 'educationPercent', value: this.state.educationPercent, ref2: 'education' },
        { name: 'Projects', description: 'Candidates with 1+ years working on projects building relevant skills are awarded maximum points.', ref: 'projectsPercent', value: this.state.projectsPercent, ref2: 'projects' },
        { name: 'Experience', description: 'Candidates who have 1+ years of paid expereience building relevant skills are awarded maximum points.', ref: 'experiencePercent', value: this.state.experiencePercent, ref2: 'experience' },
        { name: 'Interview', description: 'Candidates who perform well on the Bixel Exchange interview are awarded maximum points.', ref: 'interviewPercent', value: this.state.interviewPercent, ref2: 'interview' },
        { name: 'Diversity', description: 'Candidates who are in bring diversity or showcase unique adversity are awarded maximum points.', ref: 'adversityScorePercent', value: this.state.adversityScorePercent, ref2: 'adversityScore' },
        { name: 'Political Preference', description: 'Candidates who align with high demand, low supply political preferences are awarded extra points.', ref: 'politicalPreferencePercent', value: this.state.politicalPercent, ref2: 'politicalPreference' },
      ]

      if (this.state.thirdPartyAssessmentPercent) {
        // console.log('show it: ', this.state.thirdPartyAssessmentPercent)
        const thirdPartyAssessmentWeight = { name: 'Third Party Assessments', description: 'Candidates who complete the third party assessments at a satisfactory level are awarded maximum points.', ref: 'thirdPartyAssessmentPercent', value: this.state.thirdPartyAssessmentPercent, ref2: 'thirdPartyAssessments' }
        weights.splice(5,0,thirdPartyAssessmentWeight)
      }

      if (this.state.coverLetterPercent) {
        const coverLetterWeight = { name: 'Cover Letter', description: 'Candidates who have strong cover letter are awarded maximum points.', ref: 'coverLetterPercent', value: this.state.coverLetterPercent, ref2: 'coverLetter' }
        weights.splice(9,0,coverLetterWeight)
      }

      if (this.state.resumePercent) {
        const resumeWeight = { name: 'Resume', description: 'Candidates who have strong resumes are awarded maximum points.', ref: 'resumePercent', value: this.state.resumePercent, ref2: 'resume' }
        weights.splice(9,0,resumeWeight)
      }

      for (let i = 1; i <= weights.length; i++) {
          console.log('iterate: ', i)

        if (this.props.activeOrg !== 'c2c' && weights[i - 1].name === 'Political Preference') {
          // do no render
        } else {

          rows.push(
            <div key={"weights|" + i}>
              <div>
                <div className="relative-column-85">
                  <p className="half-bold-text">{i}. {weights[i - 1].name}</p>
                  <div className="half-spacer" />
                  <p className="description-text-3">{weights[i - 1].description}</p>
                </div>

                {(this.props.source === 'Employer|Edit') ? (
                  <div className="relative-column-15 right-text">
                    <div className="calc-column-offset-30">
                      <input type="number" className="number-field heading-text-2 cta-color bold-text right-text" min="0" max="100" placeholder="0" name={weights[i - 1].ref} value={weights[i - 1].value} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-30">
                      <div className="mini-spacer"/><div className="mini-spacer"/>
                      <p className="heading-text-2 cta-color bold-text">%</p>
                    </div>
                  </div>
                ):(
                  <div className="relative-column-15 right-text">
                    <p className="heading-text-2 cta-color bold-text">{this.calculateBenchmarkWeight(weights[i - 1].ref2)}%</p>
                  </div>
                )}

                <div className="clear" />
                <div className="spacer"/><div className="half-spacer"/>
              </div>
            </div>
          )
        }
      }

      return rows

    }

    render() {
      console.log('show fo2: ', this.state.functionOptions.length)

      if (this.props.hideProfileCard) {
        return(
          <div/>
        )
      } else {
        let educationText = ' has not added education information yet.'
        if (this.state.selectedApplication) {
          if (this.state.selectedApplication.schoolName && this.state.selectedApplication.gradYear) {
            educationText = this.state.selectedApplication.schoolName + " | " + this.state.selectedApplication.gradYear
          } else if (this.state.selectedApplication.schoolName || this.state.selectedApplication.gradYear) {
            educationText = this.state.selectedApplication.schoolName
          } else {
            educationText = this.state.selectedApplication.firstName + ' has not added education information yet.'
          }
        }

        let subPostType = ''
        if (this.state.selectedJob) {
          subPostType = this.state.selectedJob.postType
          if (this.state.selectedJob.subPostType) {
            subPostType = this.state.selectedJob.subPostType
          }
        }

        let disabled = true
        // let pointerEvents = 'none'
        if (this.props.source === 'Employer|Edit') {
          disabled = false
          // pointerEvents = 'auto'
        }

        let linkPrefix = ''
        if (window.location.pathname.includes('/advisor')) {
          linkPrefix = '/advisor'
        } else if (this.props.source === 'Organization') {
          linkPrefix = '/organizations/' + this.props.activeOrg
        }
        // console.log('got app:', this.state.selectedApplication)
        return(
          <div>

            {(this.state.selectedApplication) && (
              <div>
                {console.log('last dance: ', this.state.selectedApplication.email, this.state.selectedApplication.firstName)}

                <div className="profile-card-basic-info-container">
                  {this.renderMatchScore()}

                  <p className="heading-text-2">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName}</p>

                  <div className="spacer" /><div className="spacer" />
                  <hr className="horizontal-margin-40 cta-border-color cta-background-color" />
                  <div className="spacer" /><div className="spacer" />

                  <p className="description-text-2 description-text-color curtail-text">{this.state.selectedJob.title} Candidate</p>
                  <div className="spacer" />

                  <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: this.state.selectedApplication.pictureURL })}>
                    <img src={this.state.selectedApplication.pictureURL ? this.state.selectedApplication.pictureURL : profileIconBig} alt="GC" className="applicant-image"/>
                  </button>

                  {(this.props.source === 'Employer|Edit') ? (
                    <div>
                      {!this.props.accountCode && (
                        <div>
                          <button className="background-button row-10 horizontal-padding-6" onClick={() => this.viewDetails('diversity')}>
                            <img src={diversityIconColor} alt="Guided Compass diversity icon" className="image-auto-40 center-horizontally"/>
                          </button>
                        </div>
                      )}

                      <div className="horizontal-padding-6">
                        <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="heading-text-5 description-text-color">{this.state.selectedJob.workFunction} Department</p>
                        <div className="spacer" />
                      </div>
                    </div>
                  ) : (
                    <div className="center-text">
                      <div className="spacer" /><div className="spacer" />
                      <p className="description-text-5 description-text-color bold-text">KEY LINKS</p>
                    </div>
                  )}

                  <div className="half-spacer" />
                  <div className="profile-links">
                    <ul>
                      { this.state.selectedApplication.videoResumeURL && <li><a target="_blank" href={this.state.selectedApplication.videoResumeURL}><img src={videoIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">VID</p>}</a></li> }
                      { this.state.selectedApplication.resumeURL && <li><a target="_blank" href={this.state.selectedApplication.resumeURL}><img src={resumeIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">RES</p>}</a></li> }
                      { this.state.selectedApplication.coverLetterURL && <li><a target="_blank" href={this.state.selectedApplication.coverLetterURL}><img src={coverLetterIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">CL</p>}</a></li> }
                      { this.state.selectedApplication.customWebsiteURL && <li><a target="_blank" href={this.state.selectedApplication.customWebsiteURL}><img src={websiteIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">PORT</p>}</a></li> }
                      { this.state.selectedApplication.linkedInURL && <li><a target="_blank" href={this.state.selectedApplication.linkedInURL}><img src={linkedinIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">LNK</p>}</a></li> }
                      { this.state.selectedApplication.letterOfRecommendationURL && <li><a target="_blank" href={this.state.selectedApplication.letterOfRecommendationURL}><img src={letterOfRecIcon} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">LOR</p>}</a></li> }
                      { (this.state.selectedApplication.transcriptURL && this.props.source === 'Organization') && <li><a target="_blank" href={this.state.selectedApplication.transcriptURL}><img src={transcriptIcon} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">TRS</p>}</a></li> }
                      { (this.state.selectedApplication.identificationURL && this.props.source === 'Organization') && <li><a target="_blank" href={this.state.selectedApplication.identificationURL}><img src={idIcon} alt="GC" className="image-30-auto"/>{(this.state.includeIconLabels) && <p className="description-text-5 standard-color top-padding-5">ID</p>}</a></li> }
                    </ul>
                  </div>
                  <div className="spacer" /><div className="spacer" />

                  {(this.props.source === 'Organization') && (
                    <Link className="btn btn-tertiary" to={linkPrefix + '/postings/' + this.state.selectedApplication.postingId + '/messages/' + this.state.selectedApplication.firstName + this.state.selectedApplication.lastName} state={{ selectedApplication: this.state.selectedApplication }}>Message</Link>
                  )}

                  {(this.props.source === 'Employer' && window.location.pathname.includes('/applications')) && (
                    <div>
                      <Link className="btn btn-tertiary" to={'/employers/' + this.props.accountCode + '/postings/' + this.state.selectedApplication.postingId + '/messages/' + this.state.selectedApplication.firstName + this.state.selectedApplication.lastName} state={{ selectedApplication: this.state.selectedApplication }}>Message</Link>
                      {/*<button className="btn btn-tertiary" onClick={() => this.connectEmployer()}>Connect</button>*/}
                    </div>
                  )}

                </div>
                <div className="profile-card-details-container">

                  <div className="height-90 padding-20 light-background">
                    <button className={(this.props.source === 'Employer|Edit') ? "background-button full-width left-text auto-pointers" : "background-button full-width left-text no-pointers"} disabled={disabled} onClick={() => this.viewDetails('education')}>
                    <div>
                      <div className="relative-column-50">
                        <p className="description-text-4 bold-text">EDUCATION</p>
                        <p className="description-text-3 description-text-color bold-text">{educationText}</p>
                        <p className="description-text-3 description-text-color bold-text">{this.state.selectedApplication.major}</p>
                      </div>
                      {(this.props.activeOrg === 'c2c') && (
                        <div className="relative-column-50">
                          <p className="description-text-4 bold-text">POLITICAL ALIGNMENT</p>
                          <p className="description-text-3 description-text-color bold-text">{this.state.selectedApplication.politicalAlignment}</p>
                          <p className="description-text-3 description-text-color bold-text">Home state: {this.state.selectedApplication.hometown}, Home Congressional District: {this.state.selectedApplication.homeCongressionalDistrict}</p>
                        </div>
                      )}

                      <div className="clear" />

                    </div>
                    </button>
                  </div>

                  <button className={(this.props.source === 'Employer|Edit') ? "background-button full-width left-text auto-pointers" : "background-button full-width left-text no-pointers"} onClick={() => this.viewDetails('skills')}>
                    <div className="height-100 row-10 horizontal-padding-5">
                      <p className="description-text-4 bold-text">SKILLS</p>
                      <div className="spacer" />
                      <div className="relative-column-50">
                        <p className="description-text-3 description-text-color">HARD SKILLS</p>
                        <div className="half-spacer" />
                        {this.renderSkills('hard')}
                        <div className="spacer" /><div className="half-spacer" />
                      </div>
                      <div className="relative-column-50">
                        <p className="description-text-3 description-text-color">SOFT SKILLS</p>
                        <div className="half-spacer" />
                        {this.renderSkills('soft')}
                        <div className="spacer" /><div className="half-spacer" />
                      </div>
                    </div>
                  </button>
                  <div className="clear"/>

                  <div className="half-spacer" />
                  <hr className="thin-ruler horizontal-margin-3"/>
                  <div className="half-spacer" />

                  <div className="height-100 row-10 horizontal-padding-5">
                    <p className="description-text-4 bold-text">PROJECTS & EXPERIENCE</p>
                    {(this.props.source === 'Employer|Edit') ? (
                      <button className="btn background-button" onClick={() => this.viewDetails('projects')}>
                        <div className="flex-container left-text">
                          <div className="float-left right-padding top-margin-5">
                            <div>
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.benchmark) ? (this.calculateHours('experience', this.state.benchmark.experienceHours) + this.calculateHours('project', this.state.benchmark.projectHours) + this.calculateHours('volunteer', this.state.benchmark.volunteerHours)) : ""}</label>
                            </div>
                            <div className="clear"/>
                            <div className="top-margin-negative-8">
                              <p className="description-text-color clear-margin clear-padding">hours</p>
                            </div>
                          </div>

                          <div className="vertical-ruler height-50 profile-card-vertical-ruler-margins"/>

                          <div className="vertical-scroll-container float-left left-margin top-margin height-60">
                            <p className="description-text-2"><b>Experience:</b> {(this.state.benchmark) && this.calculateHours('experience', this.state.benchmark.experienceHours)} relevant hours spent across {(this.state.selectedApplication.paidExperienceCount) ? this.state.selectedApplication.paidExperienceCount : 'multiple'} paid work experiences.</p>
                            <p className="description-text-2"><b>Projects:</b> {(this.state.benchmark) && this.calculateHours('project', this.state.benchmark.projectHours)} relevant hours spent across {(this.state.selectedApplication.projectCount) ? this.state.selectedApplication.projectCount : 'multiple'} projects.</p>
                            <p className="description-text-2"><b>Volunteer:</b> {(this.state.benchmark) && this.calculateHours('volunteer', this.state.benchmark.volunteerHours)} relevant hours spent across {(this.state.selectedApplication.volunteerExperienceCount) ? this.state.selectedApplication.volunteerCount : 'multiple'} unpaid work experiences.</p>
                          </div>

                        </div>
                      </button>
                    ) : (
                      <button className="btn background-button" onClick={() => this.viewDetails('projects')}>
                        <div className="flex-container left-text">
                          <div className="float-left right-padding top-margin-5">
                            <div>
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.totalHours || this.state.selectedApplication.totalHours === 0) ? this.state.selectedApplication.totalHours : this.calculateHours('total')}</label>
                            </div>
                            <div className="clear"/>
                            <div className="top-margin-negative-8">
                              <p className="description-text-color clear-margin clear-padding">hours</p>
                            </div>
                          </div>

                          <div className="vertical-ruler height-50 profile-card-vertical-ruler-margins"/>

                          <div className="vertical-scroll-container float-left left-margin top-margin height-60">
                            {(this.state.selectedApplication.experienceHours || this.state.selectedApplication.experienceHours === 0) ? (
                              <p className="description-text-2"><b>Experience:</b> {this.state.selectedApplication.experienceHours} relevant hours spent across {this.state.selectedApplication.paidExperienceCount} paid work experiences.</p>
                            ) : (
                              <div>
                                <p className="description-text-2"><b>Experience:</b> {this.calculateHours('experience')}</p>
                              </div>
                            )}
                            {(this.state.selectedApplication.projectHours || this.state.selectedApplication.projectHours === 0) ? (
                              <p className="description-text-2"><b>Projects:</b> {this.state.selectedApplication.projectHours} relevant hours spent across {this.state.selectedApplication.projectCount} projects.</p>
                            ) : (
                              <div>
                                <p className="description-text-2"><b>Projects:</b> {this.calculateHours('project')}</p>
                              </div>
                            )}
                            {(this.state.selectedApplication.volunteerHours || this.state.selectedApplication.volunteerHours === 0) ? (
                              <p className="description-text-2"><b>Volunteer:</b> {this.state.selectedApplication.volunteerHours} relevant hours spent across {this.state.selectedApplication.volunteerExperienceCount} unpaid work experiences.</p>
                            ) : (
                              <div>
                                <p className="description-text-2"><b>Volunteeer:</b> {this.calculateHours('volunteer')}</p>
                              </div>
                            )}
                          </div>

                        </div>
                      </button>
                    )}
                  </div>

                  <div className="clear"/>
                  <div className="spacer" />

                  <div className="half-spacer" />
                  <hr className="thin-ruler horizontal-margin-3"/>
                  <div className="half-spacer" />

                  <div className="height-100 row-10 horizontal-padding-5">
                    <p className="description-text-4 bold-text">INTANGIBLES - (INTERESTS & PERSONALITY)</p>

                    <button className="btn background-button" onClick={() => this.viewDetails('personality')}>
                      <div className="flex-container left-text">
                        <div className="flex-left row-5">
                          {(this.props.source === 'Employer|Edit') ? (
                            <div>
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.benchmark && this.state.benchmark.myersBriggs) ? this.state.benchmark.myersBriggs : "N/A"}</label>
                            </div>
                          ) : (
                            <div>
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{this.state.selectedApplication.personalityResults ? this.state.selectedApplication.personalityResults.myersBriggs : "N/A"}</label>
                            </div>
                          )}
                          <div className="clear"/>
                          <div className="top-margin-negative-8">
                            <p className="description-text-color clear-margin clear-padding">personality</p>
                          </div>
                        </div>

                        <div className="vertical-ruler height-50 profile-card-vertical-ruler-margins"/>

                        <div className="vertical-scroll-container float-left left-margin top-margin height-60 description-text-1">
                          {(this.props.source === 'Employer|Edit') ? (
                            <p className="description-text-2">{this.calculateIntangiblesText()}</p>
                          ) : (
                            <div>
                              {(this.state.selectedApplication.intangiblesText) ? (
                                <p className="description-text-2">{this.state.selectedApplication.intangiblesText}</p>
                              ) : (
                                <div>
                                  <p>{this.calculateIntangiblesText()}</p>
                                </div>
                              )}
                            </div>
                          )}

                        </div>

                      </div>
                    </button>
                  </div>

                  <div className="clear"/>
                  <div className="spacer" />
                  <div className="padding-20">

                    {(this.state.selectedApplication.endorsements && this.state.selectedApplication.endorsements.length > 0) && (
                      <div className="float-left right-padding-5">
                        <button className="btn background-button" onClick={() => this.viewDetails('endorsements')}>
                          <div className="float-left right-padding-5">
                            <img src={incomingIcon} alt="GC" className="image-auto-16"/>
                          </div>
                          <div className="float-left">
                            <p className="description-text-3 cta-color left-padding-5 bold-text">{this.state.selectedApplication.endorsements.length} Endorsements</p>
                          </div>
                        </button>
                      </div>
                    )}

                    {(subPostType === 'Track' && this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex]) && (
                      <div className="float-left">
                        {(this.state.selectedApplication.endorsements.length > 0) && (
                          <div className="float-left vertical-thin-border" />
                        )}

                        <button className="btn background-button" onClick={() => this.viewDetails('interview')}>
                          <div className="float-left right-padding-5">
                            <img src={interviewIcon} alt="Compass full star icon" className="image-auto-17" />
                          </div>
                          <div className="float-left">
                            <p className="description-text-3 cta-color left-padding-5 bold-text">1 Interview</p>
                          </div>
                        </button>
                      </div>
                    )}

                    {(subPostType !== 'Track' && this.state.selectedApplication.interviewDetails && this.state.selectedApplication.interviewDetails.length > 0) && (
                      <div className="float-left">
                        {(this.state.selectedApplication && this.state.selectedApplication.endorsements && this.state.selectedApplication.endorsements.length > 0) && (
                          <div className="float-left vertical-thin-border" />
                        )}

                        <button className="btn background-button" onClick={() => this.viewDetails('interview')}>
                          <div className="float-left right-padding-5">
                            <img src={interviewIcon} alt="Compass full star icon" className="image-auto-17"/>
                          </div>
                          <div className="float-left">
                            <p className="description-text-3 cta-color left-padding-5 bold-text">1 Interview</p>
                          </div>
                        </button>
                      </div>
                    )}

                    <div className="float-right">
                      <img src={gcLogo} alt="Guided Compass logo icon" className="image-auto-30 top-margin-negative-10"/>
                    </div>
                    <div className="clear"/>
                  </div>

                </div>

                <div className="clear"/>
                <div className="thin-ruler-2" />

                {this.state.serverSuccessMessage && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                {this.state.serverErrorMessage && <p className="error-message">{this.state.serverErrorMessage}</p>}

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
               >
                  {(this.state.showEndorsements) && (
                    <div className="padding-20">
                      {(this.props.source === 'Employer|Edit') ? (
                        <div>
                          <p className="heading-text-4 bottom-padding">Example Endorsement for {this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName}</p>
                          <div>
                            <p>You may edit the below ideal endorsement indirectly:</p>
                            <p className="description-text-2">1) You may edit the weight of "endorsements" by tapping 100% on the profile card</p>
                            <p className="description-text-2">2) You may edit the skills by tapping the skills section of the profile card</p>
                            <p className="description-text-2">3) You may edit the personality traits by tapping the personality section of the profile card</p>
                          </div>
                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      ) : (
                        <p className="heading-text-4 bottom-padding">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName} has {this.state.selectedApplication.endorsements.length} Relevant Endorsements</p>
                      )}

                      {(this.state.showSubEndorsementDetails) ? (
                        <div>
                          {console.log('wee in here???', this.state.showSubEndorsementDetails)}
                          <SubEndorsementDetails orgCode={this.state.activeOrg} endorsements={this.state.selectedApplication.endorsements}/>
                        </div>
                      ) : (
                        <div>
                          {this.renderEndorsements(this.state.selectedApplication.endorsements)}
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.showMatchDetails) && (
                    <div className="padding-20">
                      <p className="heading-text-4 bottom-padding">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName} is a {(subPostType === 'Track') ? this.state.selectedApplication.matches[this.props.matchIndex] : this.state.selectedApplication.match}% Match</p>

                      <p className="description-text-3">Match percentage compares candidate against a "benchmark" (i.e. ideal candidate). Benchmark allocations below:</p>
                      <div className="spacer"/><div className="spacer"/>
                      <div>
                        <div>

                          {this.renderAllocation()}

                          {(this.props.source === 'Employer|Edit') ? (
                            <div>
                              <hr className="clear-margin clear-padding cta-border-color" />
                              <div className="spacer" />
                              <div className="float-left full-width right-text">
                                <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                              </div>
                              <div className="clear" />
                              {(this.state.totalPercent !== 100) && (
                                <div className="full-width">
                                  <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                                </div>
                              )}
                            </div>
                          ):(
                            <div>

                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.state.showProjects) && (
                    <div className="padding-20">
                      {(this.props.source === 'Employer|Edit') ? (
                        <div>
                          <p className="heading-text-4 bottom-padding">Projects & Experience</p>
                          <p>Please specify your preferences for an entry-level candidate</p>

                          <div className="spacer"/><div className="spacer"/>

                          <p className="half-bold-text">Paid Experiences</p>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Minimum Number of Hours</p>
                              <select name="experienceHours" value={this.state.benchmark.experienceHours} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.hourOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">Tags (Separated by commas)</p>
                              <input type="text" className="text-field" placeholder="List key skills and attributes..." name="experienceTags" value={this.state.benchmark.experienceTags} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                          <p className="half-bold-text">Projects</p>
                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Minimum Number of Hours</p>
                              <select name="projectHours" value={this.state.benchmark.projectHours} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.hourOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">Tags (Separated by commas)</p>
                              <input type="text" className="text-field" placeholder="List key skills and attributes..." name="projectTags" value={this.state.benchmark.projectTags} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                          <p className="half-bold-text">Volunteer (Unpaid) Experiences</p>
                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Minimum Number of Hours</p>
                              <select name="volunteerHours" value={this.state.benchmark.volunteerHours} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.hourOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">Tags (Separated by commas)</p>
                              <input type="text" className="text-field" placeholder="List key skills and attributes..." name="volunteerTags" value={this.state.benchmark.volunteerTags} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                        </div>
                      ) : (
                        <div>
                          <p className="heading-text-4 bottom-padding">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName} Projects & Experience</p>

                          <p className="half-bold-text">Paid Experiences</p>
                          <p className="description-text-3">{this.state.selectedApplication.experienceHours} hours spent over {this.state.selectedApplication.paidExperienceCount} paid experiences:</p>
                          <div className="spacer"/><div className="spacer"/>
                          <div>
                            {this.renderWork('paid')}
                          </div>
                          <div className="spacer"/><div className="spacer"/>

                          <p className="half-bold-text">Projects</p>
                          <p className="description-text-3">{this.state.selectedApplication.projectHours} hours spent over {this.state.selectedApplication.projectCount} projects:</p>
                          <div className="spacer"/><div className="spacer"/>
                          <div>
                            {this.renderWork('project')}
                          </div>
                          <div className="spacer"/><div className="spacer"/>

                          <p className="half-bold-text">Volunteer (Unpaid) Experiences</p>
                          <p className="description-text-3">{this.state.selectedApplication.volunteerHours} hours spent over {this.state.selectedApplication.volunteerExperienceCount} unpaid experiences:</p>
                          <div className="spacer"/><div className="spacer"/>
                          <div>
                            {this.renderWork('unpaid')}
                          </div>
                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.showTraits) && (
                    <div className="padding-20">
                      <p className="heading-text-4 bottom-padding">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName} Intangibles (Interests, Personality, & Work Preferences)</p>
                      <div className="spacer"/><div className="spacer"/>

                      <p className="half-bold-text">Personality</p>
                      {(this.props.source === 'Employer|Edit') && (
                        <div>
                          <div className="spacer" />
                          <p>Please specify your preferences for an entry-level candidate</p>
                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>
                      <p className="description-text-1 half-bold-text">16 Personalities</p>
                      <div>
                        {this.renderIntangibles('myersBriggs')}
                      </div>

                      <p className="description-text-1 half-bold-text">The Big Five</p>
                      <div>
                        {this.renderIntangibles('fiveFactors')}
                      </div>

                      <div className="spacer"/><div className="spacer"/>

                      <p className="half-bold-text">Work Interests (Holland Codes)</p>
                      <div className="spacer"/><div className="spacer"/>
                      <div>
                        {this.renderIntangibles('interests')}
                      </div>
                      <div className="spacer"/><div className="spacer"/>

                      {(this.props.source === 'Employer|Edit') && (
                        <div>
                          <p className="half-bold-text">General Interests & Passions</p>
                          <div className="spacer"/><div className="spacer"/>
                          <div>
                            {this.renderIntangibles('generalInterests')}
                          </div>
                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      <div>
                        <p className="half-bold-text">Work Preferences</p>
                        <div className="spacer"/><div className="spacer"/>
                        <div>
                          {this.renderIntangibles('workPreferences')}
                        </div>
                        <div className="spacer"/><div className="spacer"/>
                      </div>

                    </div>
                  )}

                  {(this.state.showInterview) && (
                    <div className="padding-20">
                      {(this.props.source === 'Employer|Edit') ? (
                        <p className="heading-text-4 bottom-padding">Ideal Interview Preferences & Questions</p>
                      ) : (
                        <p className="heading-text-4 bottom-padding">{this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName} Interview</p>
                      )}

                      {(subPostType === 'Track') ? (
                        <div>
                          {(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex]) && (
                            <div>
                              <div className="spacer"/><div className="half-spacer"/>
                              <div className="relative-column-15 right-padding-20">
                                <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviews[this.props.matchIndex].score / 5) * 100}%</label>
                              </div>
                              <div className="relative-column-85">
                                <p className="half-bold-text">Overall Score</p>
                                <p className="description-text-3">A combination of knowledge, skills, ability, communication, collaborating, problem solving, passion, and professionalism.</p>
                              </div>
                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}

                          <div>
                            <div className="relative-column-15 right-padding-20">
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[0].sectionScore / 5) * 100}%</label>
                            </div>
                            <div className="relative-column-85">
                              <p className="half-bold-text">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[0].title}</p>
                              {this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[0].questions.map(value =>
                                <label className="description-text-3">{value.question} </label>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/>

                          <div>
                            <div className="relative-column-15 right-padding-20">
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[1].sectionScore / 5) * 100}%</label>
                            </div>
                            <div className="relative-column-85">
                              <p className="half-bold-text">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[1].title}</p>
                              {this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[1].questions.map(value =>
                                <label className="description-text-3">{value.question} </label>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/>

                          <div>
                            <div className="relative-column-15 right-padding-20">
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[2].sectionScore / 5) * 100}%</label>
                            </div>
                            <div className="relative-column-85">
                              <p className="half-bold-text">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[2].title}</p>
                              {this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[2].questions.map(value =>
                                <label className="description-text-3">{value.question} </label>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/>

                          <div>
                            <div className="relative-column-15 right-padding-20">
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[3].sectionScore / 5) * 100}%</label>
                            </div>
                            <div className="relative-column-85">
                              <p className="half-bold-text">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[3].title}</p>
                              {this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[3].questions.map(value =>
                                <label className="description-text-3">{value.question} </label>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/>

                          <div>
                            <div className="relative-column-15 right-padding-20">
                              <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[4].sectionScore / 5) * 100}%</label>
                            </div>
                            <div className="relative-column-85">
                              <p className="half-bold-text">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[4].title}</p>
                              {this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].sections[4].questions.map(value =>
                                <label className="description-text-3">{value.question} </label>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />
                          <hr />
                          <div className="spacer" /><div className="spacer" />

                          <div>
                            <div>
                              <p className="half-bold-text">Impressive Areas</p>
                              <p className="description-text-3">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].glows}</p>
                            </div>
                          </div>

                          <div className="spacer"/><div className="spacer"/>

                          <div>
                            <div>
                              <p className="half-bold-text">Areas to Improve</p>
                              <p className="description-text-3">{this.state.selectedApplication.interviewDetailsArray[this.props.matchIndex].grows}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>

                          {(this.props.source === 'Employer|Edit') ? (
                            <div>

                              <p className="profile-label">Cues of Strong Fit</p>
                              <textarea type="text" className="text-field" placeholder="Highlight any specific cues that demonstrate that a candidate is a good fit (e.g. interpersonal, analytical, or creative thinking cues)..." name="strongFitCues" value={this.state.benchmark.strongFitCues} onChange={this.formChangeHandler}></textarea>

                              <p className="profile-label">Cues of Poor Fit</p>
                              <textarea type="text" className="text-field" placeholder={"Highlight any specific cues that demonstrate that a candidate is not a good fit (e.g. no conviction for " + this.state.selectedJob.workFunction + ", arrogant / narcissistic, lack perserverance)..."} name="poorFitCues" value={this.state.benchmark.poorFitCues} onChange={this.formChangeHandler}></textarea>

                              <div className="spacer"/><div className="spacer"/>
                              <hr className="clear-margin clear-padding" />
                              <div className="spacer"/><div className="spacer"/>

                              {(this.state.benchmark.interviewQuestions) && (
                                <div>
                                  {this.state.benchmark.interviewQuestions.map((value, index) =>
                                    <div>
                                      <div className="edit-profile-row">
                                        <div>
                                          <div className="calc-column-offset-20">
                                            <p className="profile-label half-bold-text">Question #{index + 1}</p>
                                          </div>
                                          <div className="fixed-column-20">
                                            <div className="spacer" />
                                            <button className="btn background-button" onClick={() => this.removeItem(index)}>
                                              <img src={closeIcon} alt="Guided Compass icon" className="image-auto-15" />
                                            </button>
                                          </div>
                                          <div className="clear" />
                                          <div className="half-spacer" />
                                        </div>


                                        <div>
                                          <div className="fixed-column-90">
                                            <div className="half-spacer" />
                                            <p className="description-text-2">Question:</p>
                                          </div>
                                          <div className="calc-column-offset-90">
                                            <input type="text" className="text-field small-text-field" placeholder="Write the interview question..." name={"interviewQuestion|" + index} value={this.state.benchmark.interviewQuestions[index].question} onChange={this.formChangeHandler}></input>
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="spacer" />

                                        <div>
                                          <div className="fixed-column-90">
                                            <div className="half-spacer" />
                                            <p className="description-text-2">Guidance:</p>
                                          </div>
                                          <div className="calc-column-offset-90">
                                            <input type="text" className="text-field small-text-field" placeholder="What to look out for..." name={"interviewGuidance|" + index} value={this.state.benchmark.interviewQuestions[index].guidance} onChange={this.formChangeHandler}></input>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/>

                              <div className="add-item-line-button">
                                <hr />
                                <a className="background-button add-task-link-container" onClick={() => this.addItem('interviewQuestions')}>
                                  <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                                </a>
                              </div>

                              <div className="spacer"/><div className="spacer"/>

                            </div>
                          ) : (
                            <div>
                              {(this.state.selectedApplication.interview) && (
                                <div>
                                  <div className="spacer"/><div className="half-spacer"/>
                                  <div className="relative-column-15 right-padding-20">
                                    <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interview.score / 5) * 100}%</label>
                                  </div>
                                  <div className="relative-column-85">
                                    <p className="half-bold-text">Overall Score</p>
                                    <p className="description-text-3">A combination of knowledge, skills, ability, communication, collaborating, problem solving, passion, and professionalism.</p>
                                  </div>
                                  <div className="clear" />
                                  <div className="spacer" /><div className="spacer" />
                                  <hr />
                                  <div className="spacer" /><div className="spacer" />
                                </div>
                              )}

                              {(this.state.selectedApplication.interviewDetails && this.state.selectedApplication.interviewDetails.sections && this.state.selectedApplication.interviewDetails.sections.length > 0) && (
                                <div>
                                  {(console.log('getsts',this.state.selectedApplication.interviewDetails.sections[0]))}
                                  <div>
                                    <div className="relative-column-15 right-padding-20">
                                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetails.sections[0].sectionScore / 5) * 100}%</label>
                                    </div>
                                    <div className="relative-column-85">
                                      <p className="half-bold-text">{this.state.selectedApplication.interviewDetails.sections[0].title}</p>
                                      {this.state.selectedApplication.interviewDetails.sections[0].questions.map(value =>
                                        <label className="description-text-3">{value.question} </label>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <div>
                                    <div className="relative-column-15 right-padding-20">
                                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetails.sections[1].sectionScore / 5) * 100}%</label>
                                    </div>
                                    <div className="relative-column-85">
                                      <p className="half-bold-text">{this.state.selectedApplication.interviewDetails.sections[1].title}</p>
                                      {this.state.selectedApplication.interviewDetails.sections[1].questions.map(value =>
                                        <label className="description-text-3">{value.question} </label>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <div>
                                    <div className="relative-column-15 right-padding-20">
                                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetails.sections[2].sectionScore / 5) * 100}%</label>
                                    </div>
                                    <div className="relative-column-85">
                                      <p className="half-bold-text">{this.state.selectedApplication.interviewDetails.sections[2].title}</p>
                                      {this.state.selectedApplication.interviewDetails.sections[2].questions.map(value =>
                                        <label className="description-text-3">{value.question} </label>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <div>
                                    <div className="relative-column-15 right-padding-20">
                                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetails.sections[3].sectionScore / 5) * 100}%</label>
                                    </div>
                                    <div className="relative-column-85">
                                      <p className="half-bold-text">{this.state.selectedApplication.interviewDetails.sections[3].title}</p>
                                      {this.state.selectedApplication.interviewDetails.sections[3].questions.map(value =>
                                        <label className="description-text-3">{value.question} </label>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <div>
                                    <div className="relative-column-15 right-padding-20">
                                      <label className="heading-text-2 cta-color bold-text clear-margin clear-padding">{(this.state.selectedApplication.interviewDetails.sections[4].sectionScore / 5) * 100}%</label>
                                    </div>
                                    <div className="relative-column-85">
                                      <p className="half-bold-text">{this.state.selectedApplication.interviewDetails.sections[4].title}</p>
                                      {this.state.selectedApplication.interviewDetails.sections[4].questions.map(value =>
                                        <label className="description-text-3">{value.question} </label>
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer" /><div className="spacer" />
                                  <hr />
                                  <div className="spacer" /><div className="spacer" />

                                  <div>
                                    <div>
                                      <p className="half-bold-text">Impressive Areas</p>
                                      <p className="description-text-3">{this.state.selectedApplication.interviewDetails.glows}</p>
                                    </div>
                                  </div>

                                  <div className="spacer"/><div className="spacer"/>

                                  <div>
                                    <div>
                                      <p className="half-bold-text">Areas to Improve</p>
                                      <p className="description-text-3">{this.state.selectedApplication.interviewDetails.grows}</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.showEducation) && (
                    <div className="padding-20">
                      {(this.props.source === 'Employer|Edit') && (
                        <div>
                          {(this.props.activeOrg === 'c2c') ? (
                            <p className="heading-text-4 bottom-padding">Education & Political Alignment</p>
                          ) : (
                            <p className="heading-text-4 bottom-padding">Education</p>
                          )}

                          <p>Please specify your preferences for an entry-level candidate</p>

                          <div className="spacer" /><div className="spacer" />

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Degree Requirements</p>
                              <select name="degreeRequirements" value={this.state.benchmark.degreeRequirements} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.degreeOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">List Ideal Majors</p>
                              <input type="text" className="text-field" placeholder="List ideal majors separated by commas..." name="idealMajors" value={this.state.benchmark.idealMajors} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">GPA Percentile Range</p>
                              <select name="gpaRange" value={this.state.benchmark.gpaRange} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.gpaRangeOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">Grad Year Range</p>
                              <select name="gradYearRange" value={this.state.benchmark.gradYearRange} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.gradYearRangeOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Standardized Test Scores</p>
                              <select name="testScores" value={this.state.benchmark.testScores} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.gpaRangeOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              <p className="profile-label"># of Hours in Coursework</p>
                              <select name="courseHours" value={this.state.benchmark.courseHours} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.courseHourRangeOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>


                          <div className="edit-profile-row">
                            <p className="profile-label">Ideal Online Coursework Completed</p>
                            <p className="profile-descriptor">List up to 10 courses</p>
                            {this.renderItems('course')}
                            <div className="spacer" />
                            <div>
                              <hr className="task-line" />
                              <a className="background-button add-task-link-container" onClick={() => this.addItem('course')}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-76" />
                              </a>
                            </div>
                          </div>

                          <div className="edit-profile-row">
                            <p className="profile-label">Ideal Certifications, Licenses, or Badges Completed</p>
                            <p className="profile-descriptor">List up to 10 credentials</p>
                            {this.renderItems('certification')}
                            <div className="spacer" />
                            <div>
                              <hr className="task-line" />
                              <a className="background-button add-task-link-container" onClick={() => this.addItem('certification')}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-76"/>
                              </a>
                            </div>
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                          <hr className="clear-margin clear-padding" />
                          <div className="spacer"/><div className="spacer"/>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Example School</p>
                              <input type="text" className="text-field" placeholder="Example school..." name="schoolName" value={this.state.selectedApplication.schoolName} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="name-container">
                              <p className="profile-label">Example Grad Year</p>
                              <input type="number" className="number-field" max="2020" placeholder="0" name="gradYear" value={this.state.selectedApplication.gradYear} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <p className="profile-label">Example Major / Pathway</p>
                              <input type="text" className="text-field" placeholder="Add an example major / pathway..." name="major" value={this.state.selectedApplication.major} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.props.activeOrg === 'c2c') && (
                            <div>
                              <div className="spacer"/><div className="spacer"/>
                              <hr className="clear-margin clear-padding" />
                              <div className="spacer"/><div className="spacer"/>

                              <div className="edit-profile-row">
                                <div className="name-container">
                                  <p className="profile-label">Political Alignment</p>
                                  <select name="politicalAlignment" value={this.state.benchmark.politicalAlignment} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.politicalAlignmentOptions.map(value =>
                                      <option key={value.key} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                                <div className="name-container">
                                  <p className="profile-label">Home Town</p>
                                  <input type="text" className="text-field" placeholder="Add the ideal home town..." name="hometown" value={this.state.benchmark.homeTown} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="edit-profile-row">
                                <div className="name-container">
                                  <p className="profile-label">Home Town</p>
                                  <input type="text" className="text-field" placeholder="Add the ideal home town..." name="hometown" value={this.state.benchmark.homeTown} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="name-container">
                                  <p className="profile-label">Home Congressional District</p>
                                  <input type="text" className="text-field" placeholder="Add the ideal home congressional district..." name="homeCongressionalDistrict" value={this.state.benchmark.homeCongressionalDistrict} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="edit-profile-row">
                                <div className="name-container">
                                  <p className="profile-label">Current Town</p>
                                  <input type="text" className="text-field" placeholder="Add the ideal current town..." name="location" value={this.state.benchmark.location} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="name-container">
                                  <p className="profile-label">Current Congressional District</p>
                                  <input type="text" className="text-field" placeholder="Add the ideal current town..." name="currentCongressionalDistrict" value={this.state.benchmark.currentCongressionalDistrict} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.showSkills) && (
                    <div className="padding-20">
                      {(this.props.source === 'Employer|Edit') && (
                        <div>
                          <p className="heading-text-4 bottom-padding">Skills</p>
                          <p>Please specify the top 12 skills for this role</p>

                          <div className="spacer" /><div className="spacer" />

                          {this.renderIdealSkills()}
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.showDiversity) && (
                    <div className="padding-20">
                      <p className="heading-text-4 bottom-padding">Diversity & Adversity</p>
                      <p>Please specify your ideal candidate diversity and adversity preferences</p>

                      <div className="spacer" /><div className="spacer" />

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">Gender</p>
                          <select name="gender" value={(this.state.benchmark) && this.state.benchmark.gender} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.genderOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="name-container">
                          <p className="profile-label">Race / Ethnicity</p>
                          <select name="race" value={(this.state.benchmark) && this.state.benchmark.race} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.raceOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">Veteran</p>
                          <select name="veteran" value={(this.state.benchmark) && this.state.benchmark.veteran} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.veteranOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="name-container">
                          <p className="profile-label">Low Income (Household Income / Members)</p>
                          <select name="lowIncome" value={(this.state.benchmark) && this.state.benchmark.lowIncome} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.lowIncomeOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">Foster Youth</p>
                          <select name="fosterYouth" value={(this.state.benchmark) && this.state.benchmark.fosterYouth} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.fosterYouthOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="name-container">
                          <p className="profile-label">Homeless</p>
                          <select name="homeless" value={(this.state.benchmark) && this.state.benchmark.homeless} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.homelessOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">Incarcerated</p>
                          <select name="incarcerated" value={(this.state.benchmark) && this.state.benchmark.incarcerated} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.incarceratedOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="name-container">
                          <p className="profile-label">LGBTQIA</p>
                          <select name="lgbtqia" value={(this.state.benchmark) && this.state.benchmark.lgbtqia} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.lgbtqiaOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">Disablity</p>
                          <select name="disability" value={(this.state.benchmark) && this.state.benchmark.disability} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.disabilityOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="name-container">
                          <p className="profile-label">First Generation Immigrant</p>
                          <select name="firstGenImmigrant" value={(this.state.benchmark) && this.state.benchmark.firstGenImmigrant} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.firstGenImmigrantOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">First Generation College Student</p>
                          <select name="firstGenCollegeStudent" value={(this.state.benchmark) && this.state.benchmark.firstGenCollegeStudent} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.firstGenCollegeStudentOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}

                  {(this.state.enlargeImage) && (
                    <div className="padding-20">
                      <img className="full-space" alt="" src={this.state.selectedImage ? this.state.selectedImage : profileIconBig} />

                      <div className="row-10 center-text">
                       <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    </div>
                  )}


               </Modal>

              </div>
            )}
          </div>
        )
      }
    }
}

export default withRouter(ProfileCard);
