import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import ProfileCard from '../Subcomponents/ProfileCard';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import withRouter from '../Functions/WithRouter';

const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)
  for (let i = 1; i <= result.length; i++) {
    console.log(i);

    result[i - 1]['rank'] = i
    // if (result[i - 1].placementPartners && result[i - 1].placementPartners.some(pe => pe.orgCode === org)) {
    //   console.log('this is a PP')
    // } else {
    //
    // }

  }

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});


class TopCandidates extends Component {
    constructor(props) {
        super(props)

        this.state = {
          emailId: null,
          username: '',
          cuFirstName: '',
          cuLastName: '',
          org: '',

          employerCode: '',
          wpQuestions: [],

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: '',

          topCandidates: [],
          selectedCandidates: [],

          eIndex: 0,
          candidateIndex: 0,
          filterOptions: ['','Highest Rating','Lowest Rating'],
          relationshipOptions: ['','Classmate','Mentor','Teacher'],
          pathwayOptions: [''],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.sendTopCandidates = this.sendTopCandidates.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderCandidates = this.renderCandidates.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this);
        // this.renderSkills = this.renderSkills.bind(this)
        this.saveApplication = this.saveApplication.bind(this)
        this.saveApplications = this.saveApplications.bind(this)
        this.saveMessage = this.saveMessage.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.renderApplications = this.renderApplications.bind(this)

        this.favoriteApplicant = this.favoriteApplicant.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData(){
      console.log('retrieveData called in SubTopCandidates: ', this.props)

      const org = this.props.org
      const selectedJob = this.props.selectedJob
      const applications = this.props.applications
      const selectedBenchmark = this.props.selectedBenchmark

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');


      let pathPrefix = '/organizations/' + org
      if (window.location.pathname.includes('/employers')) {
        pathPrefix = '/employers/' + accountCode
      } else if (window.location.pathname.includes('/advisor')) {
        pathPrefix = '/advisor'
      }

      this.setState({ emailId: email, username, cuFirstName, cuLastName, orgFocus,
        org, selectedJob, applications, selectedBenchmark, pathPrefix
      })

      let topCandidates = []
      let formHasChangedArray = []
      let isPlacementPartner = false

      if (applications) {
        if (applications.length !== 0) {

          for (let i = 1; i <= applications.length; i++) {
            console.log(i);

            if (selectedJob.placementPartners && selectedJob.placementPartners.includes(org)) {

              if (applications[i - 1].partnerEvaluations && applications[i - 1].partnerEvaluations.some(pe => pe.orgCode === org)) {
                console.log('in this org 2')
                const evalIndex = applications[i - 1].partnerEvaluations.findIndex(pe => pe.orgCode === org);
                if (applications[i - 1].partnerEvaluations[evalIndex].rank) {
                  topCandidates.push(applications[i - 1])
                  formHasChangedArray.push(false)
                  isPlacementPartner = true
                }
              }

            } else if (applications[i - 1].rank) {
              console.log('show application: ', i, applications[i - 1])
              topCandidates.push(applications[i - 1])
              formHasChangedArray.push(false)
            } else {
              console.log('show application 0: ', i, applications[i - 1])
            }
          }
          // console.log('?/', topCandidates)
          if (isPlacementPartner) {
            topCandidates.sort(function(a,b) {
              // console.log('this sort work: ', a.rank, b.rank)
              const evalIndex1 = a.partnerEvaluations.findIndex(pe => pe.orgCode === org);
              const evalIndex2 = b.partnerEvaluations.findIndex(pe => pe.orgCode === org);

              return a.partnerEvaluations[evalIndex1].rank - b.partnerEvaluations[evalIndex2].rank;
            })
          } else {
            topCandidates.sort(function(a,b) {
              return a.rank - b.rank;
            })
          }
        }
      }

      if (email) {

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgURL = response.data.orgInfo.orgURL
            const orgMission = response.data.orgInfo.orgMission

            const orgContactFirstName = response.data.orgInfo.contactFirstName
            const orgContactLastName = response.data.orgInfo.contactLastName
            const orgContactEmail = response.data.orgInfo.contactEmail
            const orgContactTitle = response.data.orgInfo.contactTitle
            const orgProgramName = response.data.orgInfo.orgProgramName

            this.setState({

                org, orgName, orgURL, orgMission, orgProgramName,

                orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail,

            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            this.setState({
              pathwayOptions: response.data.workOptions[0].functionOptions
            })
          }
        }).catch((error) => {
            console.log('O*NET did not work', error);
        });

        Axios.get('/api/assessment/questions')
        .then((response) => {
            //console.log('O*Net worked', response.data.body, this);
            console.log('show me response', response.data)
            if ( response.data.success === true ) {
              console.log('o-net query worked')

              let wpQuestions = []
              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
              }

              this.setState({ wpQuestions })

            } else {
              console.log('Assessment question query failed', response.data.message)
            }
        }).catch((error) => {
            console.log('O*NET did not work', error);
        });

        if (selectedJob && selectedJob.accountCode) {
          Axios.get('/api/account', { params: { accountCode: selectedJob.accountCode } })
          .then((response) => {
            console.log('Account info query attempted within sub settings', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              const greenhouseAccessToken = response.data.accountInfo.greenhouseAccessToken
              const leverAccessToken = response.data.accountInfo.leverAccessToken
              const leverRefreshToken = response.data.accountInfo.leverRefreshToken
              const leverUserId = response.data.accountInfo.leverUserId
              // console.log('show leverAccessToken: ', leverAccessToken)
              this.setState({ greenhouseAccessToken, leverAccessToken, leverRefreshToken, leverUserId });

            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });
        }

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, org, isPlacementPartner,

            topCandidates, selectedJob, formHasChangedArray,
            benchmark: selectedBenchmark
        });
      }
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const topCandidates = reorder(
        this.state.topCandidates,
        result.source.index,
        result.destination.index
      );

      if (this.state.isPlacementPartner) {
        // console.log('show reorder values: ', topCandidates, this.state.topCandidates,result.source, result.destination)

        for (let i = 1; i <= topCandidates.length; i++) {
          const evalIndex = topCandidates[i - 1].partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
          topCandidates[i - 1].partnerEvaluations[evalIndex]['rank'] = i
        }
      }
      console.log('new topCandidate values: ', topCandidates)

      if (result.source.index !== result.destination.index) {
        this.saveApplications(result.source.index, result.destination.index, topCandidates)
      }

      this.setState({ topCandidates });

    }

    saveMessage(index) {
      console.log('saveMessage called', index)

      this.setState({ serverSuccessMessage: '', serverErrorMessage: ''})

      const _id = this.state.topCandidates[index]._id
      let employerMessage = this.state.topCandidates[index].employerMessage
      let partnerEvaluations = null
      if (this.state.isPlacementPartner) {
        partnerEvaluations = this.state.topCandidates[index].partnerEvaluations
        const evalIndex = this.state.topCandidates[index].partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
        partnerEvaluations[evalIndex]['employerMessage'] = employerMessage
        employerMessage = null
      }
      const updatedAt = new Date()

      Axios.post('/api/applications/update-by-id', { //sessions: sessions
        _id, employerMessage, partnerEvaluations, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);
          let formHasChangedArray = this.state.formHasChangedArray
          formHasChangedArray[index] = false
          this.setState({ formHasChangedArray, serverSuccessMessage: 'Successfully saved employer message!' })

        } else {
          console.error('there was an error saving the application');
          this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
          this.setState({ serverErrorMessage: 'there was an error saving ' + error })
      });
    }

    saveApplications(index1, index2, topCandidates) {
      console.log('saveApplications called', index1, index2, topCandidates)

      this.setState({ serverSuccessMessage: '', serverErrorMessage: ''})

      if (this.state.isPlacementPartner) {
        console.log('save as a placement partner', topCandidates[index1].partnerEvaluations)

        const _id1 = topCandidates[index1]._id
        const partnerEvaluations1 = topCandidates[index1].partnerEvaluations
        const updatedAt1 = new Date()

        Axios.post('/api/applications/update-by-id', { //sessions: sessions
          _id: _id1, partnerEvaluations: partnerEvaluations1, updatedAt: updatedAt1 })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application save worked', response.data);

          } else {
            console.error('there was an error saving the application');
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
        });

        const _id2 = topCandidates[index2]._id
        let partnerEvaluations2 = topCandidates[index2].partnerEvaluations
        const updatedAt2 = new Date()

        Axios.post('/api/applications/update-by-id', { //sessions: sessions
          _id: _id2, partnerEvaluations: partnerEvaluations2, updatedAt: updatedAt2 })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application save worked number 2', response.data);

          } else {
            console.error('there was an error saving the application number 2');
          }
        }).catch((error) => {
            console.log('Application save did not work number 2', error);
        });

      } else {
        const _id1 = this.state.topCandidates[index1]._id
        const rank1 = this.state.topCandidates[index1].rank
        const updatedAt1 = new Date()

        Axios.post('/api/applications/update-by-id', { //sessions: sessions
          _id: _id1, rank: rank1, updatedAt: updatedAt1 })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application save worked', response.data);

          } else {
            console.error('there was an error saving the application');
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
        });

        const _id2 = this.state.topCandidates[index2]._id
        const rank2 = this.state.topCandidates[index2].rank
        const updatedAt2 = new Date()

        Axios.post('/api/applications/update-by-id', { //sessions: sessions
          _id: _id2, rank: rank2, updatedAt: updatedAt2 })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application save worked number 2', response.data);

          } else {
            console.error('there was an error saving the application number 2');
          }
        }).catch((error) => {
            console.log('Application save did not work number 2', error);
        });
      }
    }

    sendTopCandidates() {
      console.log('sendTopCandidates called')

      this.setState({ serverSuccessMessage: '', serverErrorMessage: '', isSaving: true })

      if (this.state.selectedCandidates.length === 0) {
        this.setState({ serverErrorMessage: 'Please select some candidates to send to ' + this.state.selectedJob.employerContactFirstName + ' by clicking on the checkboxes.', isSaving: false })
      } else {

        const accountCode = this.state.selectedJob.accountCode
        const postingId = this.state.selectedJob._id
        const postingName = this.state.selectedJob.title

        const topCandidates = this.state.selectedCandidates

        const orgName = this.state.orgName
        const orgContactFirstName = this.state.orgContactFirstName
        const orgContactLastName = this.state.orgContactLastName
        const orgContactEmail = this.state.orgContactEmail
        const orgContactTitle = this.state.orgContactTitle
        const orgProgramName = this.state.orgProgramName

        const employerContactFirstName = this.state.selectedJob.employerContactFirstName
        const employerContactEmail = this.state.selectedJob.employerContactEmail

        let testATSPost = false
        if (testATSPost && (this.state.greenhouseAccessToken || this.state.leverAccessToken)) {

          const greenhouseAccessToken = this.state.greenhouseAccessToken
          const leverAccessToken = this.state.leverAccessToken
          const leverRefreshToken = this.state.leverRefreshToken
          const leverUserId = this.state.leverUserId
          // '10a48cf1-8544-4625-831d-e9bc5ae37f8e'

          //send email to employer
          Axios.post('/api/top-candidates/ats-post', {
            accountCode, postingId, postingName,
            topCandidates, greenhouseAccessToken, leverAccessToken, leverRefreshToken, leverUserId,
            orgName, orgContactFirstName, orgContactLastName, orgContactEmail, orgContactTitle, orgProgramName,
            employerContactFirstName, employerContactEmail
          })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('ATS post successful', response.data);
              this.setState({ serverSuccessMessage: 'ATS post successful', isSaving: false })

            } else {
              console.error('there was an error posting to ATS');
              this.setState({ serverErrorMessage: 'There was an error posting to ATS' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('there was an error posting to ATS', error);
              this.setState({ serverErrorMessage: 'There was an error posting to ATS ' + error, isSaving: false })
          });
        } else {
          //send email to employer
          Axios.post('/api/top-candidates/send', {
            accountCode, postingId, postingName,
            topCandidates,
            orgName, orgContactFirstName, orgContactLastName, orgContactEmail, orgContactTitle, orgProgramName,
            employerContactFirstName, employerContactEmail
          })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Message successfully sent', response.data);
              this.setState({ serverSuccessMessage: 'Message was successfully sent!', isSaving: false })

            } else {
              console.error('there was an error sending the message');
              this.setState({ serverErrorMessage: 'There was an error sending out the message ' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('there was an error sending the message', error);
              this.setState({ serverErrorMessage: 'There was an error sending out the message ' + error, isSaving: false })
          });
        }
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called')

      if (event.target.name.includes('employerMessage')) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1])

        let topCandidates = this.state.topCandidates
        topCandidates[index]['employerMessage'] = event.target.value

        let formHasChangedArray = this.state.formHasChangedArray
        formHasChangedArray[index] = true
        this.setState({ topCandidates, formHasChangedArray })
      }
    }

    renderCandidates() {
      console.log('renderCandidates called')

      let rows = [];
      for (let i = 1; i <= this.state.topCandidates.length; i++) {
        console.log(i);

        rows.push(
          <div key={i}>
              <div className="table-row-container">
                <p>An example candidate</p>
              </div>
              <hr className="cell-separator-advisor"/>
          </div>
        )
      }

      return rows;
    }

    itemClicked(item) {
      console.log('itemClicked called', item)

      if (item === 'all') {
        let allSelected = this.state.allSelected
        let selectedCandidates = []
        if (allSelected) {
          allSelected = false
        } else {
          allSelected = true
          selectedCandidates = this.state.topCandidates
        }

        this.setState({ allSelected, selectedCandidates })
      } else {
        let selectedCandidates = this.state.selectedCandidates
        if (selectedCandidates.some(candidate => candidate === item)) {
          const removeIndex = selectedCandidates.findIndex(candidate => candidate === item)
          selectedCandidates.splice(removeIndex,1)
          this.setState({ selectedCandidates })
        } else {
          selectedCandidates.push(item)
          let allSelected = this.state.allSelected
          if (selectedCandidates.length === this.state.topCandidates.length) {
            allSelected = true
            this.setState({ selectedCandidates, allSelected })
          } else {
            this.setState({ selectedCandidates })
          }
        }
      }
    }

    renderApplications() {
      console.log('renderApplications called')

      return (
        <div key="renderApplications">
          <p className="heading-text-4">{(this.state.applications) ? "All " + this.state.applications.length + " " : ""}Applications</p>

          {(this.state.applications) && (
            <div className="top-margin-20">
              {this.state.applications.map((item2,optionIndex) =>
                <div key={"application|" + item2.email}>

                  <Link className="background-button standard-color calc-column-offset-40 left-text row-10" to={this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/applications/' + item2._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: item2, benchmark: this.state.benchmark }}>
                    <div className="fixed-column-30 right-padding">
                      <p className="">{optionIndex + 1}.</p>
                    </div>
                    <div className="calc-column-offset-30 right-padding">
                      <p className="">{item2.firstName} {item2.lastName}</p>
                      <p className="description-text-2 top-margin-3">{item2.email}</p>
                    </div>
                    <div className="clear" />
                  </Link>
                  <div className="fixed-column-40 top-margin-5">
                    <button className={(this.state.topCandidates && this.state.topCandidates.some(tc => tc.email === item2.email)) ? "background-button width-30 height-30 padding-8 circle-radius error-border error-background-color" : "background-button width-30 height-30 padding-8 circle-radius cta-border cta-background-color"} onClick={() => this.favoriteApplicant(optionIndex)}>
                      {(this.state.topCandidates && this.state.topCandidates.some(tc => tc.email === item2.email)) ? (
                        <div>
                          <hr className="white-border" />
                        </div>
                      ) : (
                        <img src={addIconWhite} alt="GC" className={"image-12-fit"} />
                      )}
                    </button>
                  </div>
                  <div className="clear" />

                  <div className="row-10">
                    <hr />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    favoriteApplicant(index) {
      console.log('favoriteApplicant tapped', index)

      if (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) {
        let applications = this.state.applications

        let rankCount = 0

        for (let i = 1; i <= applications.length; i++) {
          // console.log(i, this.state.selectedJob.placementPartners, this.state.org);
          const item = applications[i - 1]

          if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {
            if (item.partnerEvaluations && item.partnerEvaluations.some(pe => pe.orgCode === this.state.org)) {
              console.log('in this org 2')
              const evalIndex = item.partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
              if (item.partnerEvaluations[evalIndex].rank) {
                rankCount = rankCount + 1
              }
            }

          } else if (item.rank) {
            rankCount = rankCount + 1
          }
        }

        if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {

          if (this.state.applications[index].partnerEvaluations && this.state.applications[index].partnerEvaluations.some(pe => pe.orgCode === this.state.org)) {

            const evalIndex = this.state.applications[index].partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
            if (this.state.applications[index].partnerEvaluations[evalIndex].rank) {
              applications[index].partnerEvaluations[evalIndex]['rank'] = null
            } else {
              applications[index].partnerEvaluations[evalIndex].rank = rankCount + 1
            }
          } else {

            if (this.state.applications[index].partnerEvaluations && this.state.applications[index].partnerEvaluations.length > 0) {
              applications[index].partnerEvaluations[0].rank = rankCount + 1
            } else {
              let partnerEvaluations = [{ orgCode: this.state.org, rank: rankCount + 1 }]
              applications[index]['partnerEvaluations'] = partnerEvaluations
            }
          }

        } else {

          if (this.state.applications[index].rank) {
            console.log('up in it')
            applications[index]['rank'] = null

          } else {

            applications[index]['rank'] = rankCount + 1

          }
        }

        // this.props.navigate(this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates')
        this.setState({ applications })
        this.saveApplication(applications[index])

      }
    }

    saveApplication(application) {
      console.log('saveApplications called')

      const _id = application._id
      const updateRank = true
      const rank = application.rank
      let partnerEvaluations = null
      if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {
        partnerEvaluations = application.partnerEvaluations
      }
      const updatedAt = new Date()

      let topCandidates = this.state.topCandidates
      if (topCandidates.some(tc => tc.email === application.email)) {
        const deleteIndex = topCandidates.findIndex(tc => tc.email === application.email)
        topCandidates.splice(deleteIndex,1)
      } else {
        topCandidates.push(application)
      }


      console.log('goods: ', _id, updateRank, rank, partnerEvaluations, updatedAt, topCandidates)

      Axios.post('/api/applications/update-by-id', {
        _id, updateRank, rank, partnerEvaluations, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);

          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Application saved successfully!', topCandidates
          })

        } else {
          console.error('there was an error saving the application');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
      });
    }

    render() {

      return (
          <div>
              <div>
                <div className="half-spacer" />

                {(this.state.topCandidates && this.state.topCandidates.length > 0) ? (
                  <div>
                    <div className="relative-column-30 box">
                      <div className="pin-right card width-90-percent">
                        <div>
                          <p className="heading-text-4">Candidate Ranking</p>
                          <p className="description-text-2 description-text-color top-margin-5 bottom-margin-20">(Drag & drop to change order)</p>
                          {/*<p className="profile-label">Profile Descriptor</p>*/}
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <StrictModeDroppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {this.state.topCandidates.map((item, index) => (
                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >

                                          <div>
                                            <div className="fixed-column-30 padding-5">
                                              <img src={dragIcon} alt="GC" className="image-auto-15" />
                                            </div>
                                            <div className="calc-column-offset-30">
                                              <p>{index + 1}. {item.firstName} {item.lastName}</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </StrictModeDroppable>
                          </DragDropContext>
                        </div>
                        <div className="clear"/>
                        <div className="spacer" /><div className="spacer" />
                        <hr />
                        <div className="spacer" /><div className="spacer" />
                        <div>

                          <p className="heading-text-4">Send Candidates</p>
                          <div className="half-spacer" />
                          <p className="description-text-2 description-text-color top-margin-5">Send selected top {this.state.selectedJob.employerName} candidates to {this.state.selectedJob.employerContactFirstName} {this.state.selectedJob.employerContactLastName} @ {this.state.selectedJob.employerContactEmail}</p>

                          <p className="description-text-2 error-color top-margin">Employers will be able to see this entire referred list, but only selected candidates will be sent via email.</p>

                          <div className="spacer" /><div className="spacer" />

                          <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.sendTopCandidates()}>Send</button>

                          {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                          {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                        </div>
                      </div>

                      <div className="pin-right card width-90-percent top-margin-30">
                        {this.renderApplications()}
                      </div>

                    </div>
                    <div className="relative-column-65 box">
                      <div className="pin-left card width-95-percent">
                        <div className="spacer" />

                        <div>
                          <div className="calc-column-offset-20">
                            <p className="heading-text-2">Referred Candidates for {this.state.selectedJob.title} @ {this.state.selectedJob.employerName}</p>
                          </div>
                          <div className="fixed-column-20">
                            <button className="background-button" onClick={() => this.itemClicked('all')}>
                              <img src={this.state.allSelected ? checkboxChecked : checkboxEmpty } alt="GC" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="clear" />
                          <div className="spacer" /><div className="spacer" />
                        </div>

                        <div className="spacer" /><div className="spacer" />
                        {this.state.topCandidates.map((item, index) => (
                          <div>

                            <div>
                              <div className="calc-column-offset-20">
                                <p className="heading-text-5">{index + 1}. {item.firstName} {item.lastName}</p>
                              </div>
                              <div className="fixed-column-20">
                                <button className="background-button" onClick={() => this.itemClicked(item)}>
                                  <img src={this.state.selectedCandidates.some(candidate => candidate.email === item.email) ? checkboxChecked : checkboxEmpty } alt="GC" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" />
                            </div>

                            <ProfileCard selectedApplication={item} selectedJob={this.state.selectedJob} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} wpQuestions={this.state.wpQuestions} source="Organization" activeOrg={this.state.org}/>

                            <div className="clear" />
                            <div className="spacer" />
                            <div>
                              <label className="profile-label">Message to Employer</label>
                              <textarea type="text" className="employerMessage" placeholder="Message to employer..." name={"employerMessage|" + index} value={item.employerMessage} onChange={(this.formChangeHandler)}></textarea>
                            </div>
                            {(this.state.formHasChangedArray[index]) && (
                              <div>
                                <div className="half-spacer" />
                                <button className="btn btn-primary" onClick={() => this.saveMessage(index)}>Save</button>
                              </div>
                            )}

                            {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                            {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="spacer" />

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="relative-column-30 box">
                      <div className="pin-right card width-90-percent">
                        {this.renderApplications()}
                      </div>
                    </div>
                    <div className="relative-column-65 box">
                      <div className="pin-left card width-95-percent">
                        <div className="spacer" />

                        <p className="heading-text-2">Top Candidates {(this.state.selectedJob) && "for " + this.state.selectedJob.title + " @ " + this.state.selectedJob.employerName}</p>

                        <div className="row-50 flex-container flex-center wash-out-slight horizontal-padding">
                          <div>
                            <img src={socialIconDark} alt="GC" className="image-auto-80 center-horizontally" />
                            <p className="center-text heading-text-5 top-margin-30">No Candidates Referred</p>
                            <p className="center-text description-text-1 top-margin-20">No candidates have been selected to share with employers. All candidates that employers can see within their portal will appear here. Once candidaes are selected, you can send email notifications to the employer contact with custom notes.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          </div>

      )
    }
}

export default withRouter(TopCandidates);
