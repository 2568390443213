import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubMatchMentor from '../Subcomponents/MatchMentor';
import withRouter from '../Functions/WithRouter';

class OrgMatchMentor extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, queryEmail, username, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      let selectedMentor = null
      if (this.props.location && this.props.location.state) {
        selectedMentor = this.props.location.state.selectedMentor
      }

      this.setState({ email, queryEmail, username, id, org, orgFocus, roleName, orgLogo, selectedMentor });

    }

    render() {
      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate} />
          <SubMatchMentor id={this.state.id} activeOrg={this.state.org} selectedMentor={this.state.selectedMentor} source={this.state.source} history={this.props.navigate} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgMatchMentor)
