import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Axios from 'axios';

import {convertDateToString} from '../components/Functions/ConvertDateToString';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";

class BlogPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          newFormat: true,

          posts: [],
          filteredPosts: [],
          searchString: '',
        }

        this.renderSearchResults = this.renderSearchResults.bind(this)
        this.renderPosts = this.renderPosts.bind(this)
    }

    componentDidMount() {
      //see if user is logged in
      console.log('blog page just mounted')
      window.scrollTo(0, 0)

      if (window.location.pathname.includes('/whitepapers')) {
        Axios.get('/api/whitepapers')
        .then((response) => {
          console.log('Whitepaper query attempted', response.data);

          if (response.data.success) {
            console.log('whitepaper query worked')

            if (response.data.posts.length !== 0) {
              //jobs = response.data.postings
              let posts = response.data.posts
              const filteredPosts = posts

              this.setState({ posts, filteredPosts });
            }

          } else {
            console.log('Whitepaper posts query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Whitepaper posts query did not work for some reason', error);
        });
      } else {

        Axios.get('/api/blog-posts', { params: { skipFuturePosts: true }})
        .then((response) => {
          console.log('Blog post query attempted', response.data);

          if (response.data.success) {
            console.log('blog post query worked')

            if (response.data.posts.length !== 0) {
              //jobs = response.data.postings
              let posts = response.data.posts
              const filteredPosts = posts

              this.setState({ posts, filteredPosts });
            }

          } else {
            console.log('Blog posts query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Blog posts query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called')

      this.renderSearchResults(event.target.value)
    }

    renderSearchResults(searchString) {
      console.log('renderSearchResults called')

      let filteredPosts = [];

      for (let i = 1; i <= this.state.posts.length; i++) {
        console.log(i);

        let name = this.state.posts[i - 1].title.toLowerCase()
        if (name.includes(searchString.toLowerCase())) {
            console.log('included', i)

            filteredPosts.push(this.state.posts[i - 1])
        }
      }

      this.setState({ searchString, filteredPosts })

    }

    renderPosts() {
      console.log('renderPosts called')

      let rows = []

      for (let i = 1; i <= this.state.filteredPosts.length; i++) {

        const index = i - 1

        const urlSuffix = this.state.filteredPosts[i - 1].slug

        let subpath = 'blog'
        if (window.location.pathname.includes('/whitepapers')) {
          subpath = 'whitepapers'
        }

        rows.push(
          <div key={i}>
            <div className="top-margin-30">
              <Link className="secret-link full-width" to={'/' + subpath + '/' + urlSuffix} state={{ selectedPost: this.state.filteredPosts[index] }}>
                <div className="calc-column-offset-110">
                  <p className="description-text-1 description-text-color">{convertDateToString(new Date(this.state.filteredPosts[i - 1].createdAt),'date-2')}</p>
                  <h3>{this.state.filteredPosts[i - 1].title}</h3>

                </div>
                <div className="fixed-column-80 top-margin">
                  <p className="description-text-2 description-text-color right-text">[{this.state.filteredPosts[i - 1].category}]</p>
                </div>
                <div className="fixed-column-30 top-margin">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-18 pin-right" />
                </div>
                <div className="clear" />
              </Link>

              <div className="spacer" /><div className="spacer" />
              <hr />
            </div>
          </div>
        )
      }

      return rows
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"blogPage"} darkBackground={true} />

              <section>
                <div className="row">
                  <div className="standard-border row-3 horizontal-padding-7 container-left">
                    <div className="fixed-column-25 top-margin">
                      <img src={searchIcon} alt="GC" className="image-auto-15 left-margin"/>
                    </div>
                    <div className="calc-column-offset-25 left-padding-5">
                      <input type="text" className="text-field clear-border" placeholder="Search posts..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="clear" />
                  <div className="spacer" />
                  {this.renderPosts()}

                  <div className="clear" />
                </div>
              </section>

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default BlogPage;
