import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Axios from 'axios';
import withRouter from '../components/Functions/WithRouter';
import {convertDateToString} from '../components/Functions/ConvertDateToString';

class BlogPostDetailPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        selectedPost: null,
      }

      this.translateMonth = this.translateMonth.bind(this)
    }

    componentDidMount() {
      //see if user is logged in
      console.log('blog post detail page just mounted')
      window.scrollTo(0, 0)

      if (this.props.location && this.props.location.state && this.props.location.state.selectedPost) {
        const { selectedPost } = this.props.location.state;
        console.log('we got post', selectedPost)

        if (selectedPost && selectedPost.category === 'Whitepaper') {
          const filePath = selectedPost.docLink
          this.setState({ selectedPost, filePath })
          console.log('show filePath: ', filePath)
        } else {

          this.setState({ selectedPost })
        }

      } else {

        let { title, id } = this.props.params
        // title.replace("?", "")
        let slug = ''
        if (title) {
          slug = title
        } else if (id) {
          slug = id
        }

        Axios.get('/api/blog-posts/by-slug', { params: { slug } })
        .then((response) => {
          console.log('Blog post query attempted', response.data);

          if (response.data.success) {
            console.log('blog post query worked')

            let selectedPost = response.data.blogPost
            console.log('got filePath?', selectedPost)
            if (selectedPost.category === 'Whitepaper') {
              const filePath = response.data.blogPost.docLink

              this.setState({ filePath })
            } else {

              this.setState({ selectedPost })
            }

          } else {
            console.log('Blog post query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Blog posts query did not work for some reason', error);
        });

      }

    }

    translateMonth(month) {
      console.log('translateMonth called', month)

      let wordMonth = 'January'
      if (month === '01') {
        wordMonth = 'January'
      } else if (month === '02') {
        wordMonth = 'February'
      } else if (month === '03') {
        wordMonth = 'March'
      } else if (month === '04') {
        wordMonth = 'April'
      } else if (month === '05') {
        wordMonth = 'May'
      } else if (month === '06') {
        wordMonth = 'June'
      } else if (month === '07') {
        wordMonth = 'July'
      } else if (month === '08') {
        wordMonth = 'August'
      } else if (month === '09') {
        wordMonth = 'September'
      } else if (month === '10') {
        wordMonth = 'October'
      } else if (month === '11') {
        wordMonth = 'November'
      } else {
        wordMonth = 'December'
      }

      return wordMonth
    }

    render() {

      if (this.state.filePath) {
        return (
          <div className="embedded-pdf-background">
            {/*<PDFViewer filePath={this.state.filePath} width={null} scrollToTop={true} />*/}
            <iframe src={this.state.filePath} width="100%" height="100%" title={this.state.filePath}></iframe>
          </div>
        )
      } else {

        return (
            <div>
                <header className="contact-top-nav">
                  <TopNavigation currentPage={"blogPostDetailPage"}/>
                  <hr />
                </header>
                {(this.state.selectedPost) && (
                  <section>
                    <div className="row">
                      <div className="horizontal-padding-2 bottom-margin-20">
                        <p className="description-text-2">{(this.state.selectedPost && this.state.selectedPost.createdAt) ? (new Date(this.state.selectedPost.createdAt) > new Date()) ? convertDateToString(new Date(),'date-2') : convertDateToString(new Date(this.state.selectedPost.createdAt),'date-2') : ""}</p>

                        <div className="row-10 top-margin-20">
                          <h1 className="clear-margin">{this.state.selectedPost.title}</h1>
                        </div>

                        <p className="description-text-2 description-text-color">{this.state.selectedPost.category}</p>

                      </div>

                      {(this.state.selectedPost.videoURL) && (
                        <div className="horizontal-padding-2">
                          {(this.state.selectedPost.videoURL) && (
                            <div className="top-padding-15 bottom-padding-5">
                              <iframe
                                title="videoLink"
                                className="video-iframe-4"
                                src={this.state.selectedPost.videoURL}
                                frameBorder="0"
                              />
                            </div>
                          )}

                          <div className="spacer" /><div className="spacer" />

                        </div>
                      )}

                      <div className="spacer" /><div className="spacer" />

                      <div className="horizontal-padding-2">
                        <p dangerouslySetInnerHTML={{__html: this.state.selectedPost.body}} />
                      </div>
                    </div>
                  </section>
                )}

                <Footer history={this.props.navigate} />
            </div>
        )
      }
    }
}

export default withRouter(BlogPostDetailPage);
