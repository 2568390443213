import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const subExportFile = (type, disableExport, fileName, fileProfile, pageCount)=>{
  console.log('subExportFile called', type, disableExport, fileName, fileProfile, pageCount)

  if (disableExport) {
    return { modalIsOpen: true, showDisableExportText: true }
  } else {

    let oldSchool = true
    if (oldSchool) {
      const page1 = document.getElementById('printPage1');

      if (!pageCount) {
        pageCount = 1
      }

      html2canvas(page1, { scale: 1 })
      .then(async(canvas1) => {
        console.log('show canvas detail: ', canvas1)

        const imgData = canvas1.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);

        if (fileName) {
          fileName = fileName + '.pdf'
        } else {
          fileName = fileProfile.firstName + '_' + fileProfile.lastName + '_' + type + '.pdf'
        }

        if (pageCount > 1) {
          // convert second page

          const fileArray = fileName.split('.pd')
          fileName = fileArray[0] + '-' + pageCount + '.pdf'

          // const page2 = document.getElementById('printPage2');
          // html2canvas(page2, { scale: 1 })
          // .then((canvas2) => {
          //   // console.log('show canvas detail: ', canvas2)
          //   const imgData2 = canvas2.toDataURL('image/png');
          //   pdf.addPage()
          //   pdf.addImage(imgData2, 'JPEG', 0, 0);
          //
          //   pdf.save(fileName);
          //
          // })

          let loopIt = false
          if (loopIt) {

            const loadPage = async(pageNumber) => {
              console.log('loadPage called', pageNumber)

              const page = document.getElementById('printPage' + pageNumber.toString());
              console.log('--- page: ', page)
              return await html2canvas(page, { scale: 1 })
              .then(async(canvas2) => {
                // console.log('show canvas detail: ', canvas2)
                console.log('t0')
                const imgData2 = canvas2.toDataURL('image/png');
                pdf.addPage()
                pdf.addImage(imgData2, 'JPEG', 0, 0);

                console.log('t1')
                // pdf.save(fileName);
                return await true

              })
            }

            let pageCountArray = []
            for (let i = 1; i <= pageCount; i++) {
              pageCountArray.push(true)

              // if (pageCount > 1) {
              //   const returnedValue = await loadPage(pageCount)
              // }
            }

            await pageCountArray.forEach(async(item, index) => {
              console.log('log it once: ', item, index)
              if (index > 0) {
                const returnedValue = await loadPage(pageCount)
              }
            })

            console.log('done! ')
            pdf.save(fileName);

          } else {

            const loadPage = async(pageNumber) => {
              console.log('loadPage called', pageNumber)

              const page = document.getElementById('printPage' + pageNumber.toString());
              console.log('--- page: ', page)
              return await html2canvas(page, { scale: 1 })
              .then(async(canvas2) => {
                // console.log('show canvas detail: ', canvas2)
                console.log('t0')
                const imgData2 = canvas2.toDataURL('image/png');
                pdf.addPage()
                pdf.addImage(imgData2, 'JPEG', 0, 0);

                console.log('t1')
                // pdf.save(fileName);
                return await true

              })
            }

            const returnedValue = await loadPage(2)
            if (pageCount > 2) {
              const returnedValue = await loadPage(3)
              if (pageCount > 4) {
                const returnedValue = await loadPage(5)
                if (pageCount > 5) {
                  const returnedValue = await loadPage(6)
                  if (pageCount > 6) {
                    const returnedValue = await loadPage(7)
                    if (pageCount > 7) {
                      const returnedValue = await loadPage(8)
                      if (pageCount > 8) {
                        const returnedValue = await loadPage(9)
                        if (pageCount > 9) {
                          const returnedValue = await loadPage(10)
                          pdf.save(fileName);
                        } else {
                          pdf.save(fileName);
                        }
                      } else {
                        pdf.save(fileName);
                      }
                    } else {
                      pdf.save(fileName);
                    }
                  } else {
                    pdf.save(fileName);
                  }
                } else {
                  pdf.save(fileName);
                }
              } else {
                pdf.save(fileName);
              }
            } else {
              pdf.save(fileName);
            }
          }

        } else {

          pdf.save(fileName);

        }
      })
    } else {

      // console.log('show href: ', window.location.href)

      // (async () => {
      //  // launch and create a new page
      //  const browser = await puppeteer.launch();
      //  const page = await browser.newPage();
      //  // go to page in resumeonly mode, wait for any network events to settle
      //  await page.goto("http://localhost:3000?resumeonly=true", {
      //   waitUntil: "networkidle2"
      //  });
      //  // output to a local file
      //  await page.pdf({
      //   path: "output/resume.pdf",
      //   format: "Letter",
      //   printBackground: true
      //  });
      //  // close
      //  await browser.close();
      // })();

      // const url = new URL(window.location.href);
      // console.log('what is happening: ', url, url.search)
      // const params = new URLSearchParams('resumeonlysearch');
      // const resumeOnlyMode = params.get('resumeonly');
      // console.log('resumeOnlyMode: ', resumeOnlyMode)

      // const userInfo = { test: true }
      // const fileData = JSON.stringify(userInfo);
      // const blob = new Blob([fileData], { type: "text/plain" });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement("a");
      // link.download = "user-info.txt";
      // link.href = url;
      // link.click();

    }

    return null
  }
}
