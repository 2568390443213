import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditPathway from '../Subcomponents/EditPathway';
import SubPathwayDetails from '../Subcomponents/PathwayDetails';
import withRouter from '../Functions/WithRouter';

class OrgEditPathway extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params
      // const { selectedPlacement } = this.props.location.state;
      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let selectedTemplate = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        console.log('show location state: ', this.props.location.state)
        selectedTemplate = this.props.location.state.selectedPathway;
        duplicate = this.props.location.state.duplicate;
        this.retrieveData(selectedTemplate,selectedTemplate._id,org, duplicate)
      } else {
        if (this.props.params && this.props.params.id && this.props.params.id !== 'add') {

          Axios.get('/api/pathways/byid', { params: { _id: this.props.params.id } })
          .then((response) => {
            console.log('Pathway info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked')

                const selectedTemplate = response.data.pathway
                this.retrieveData(selectedTemplate,selectedTemplate._id,org,duplicate)

              } else {
                console.log('org info query did not work', response.data.message)
                this.retrieveData(null,null,org,duplicate)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
              this.retrieveData(null,null,org)
          });
        } else {
          this.retrieveData(null,null,org)
        }
      }
    }

    retrieveData(selectedTemplate, pathwayId, org,duplicate) {
      console.log('retrieveData called')

      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ roleName, emailId: email, username,
        cuFirstName, cuLastName, orgFocus, pathwayId, selectedTemplate, org, orgLogo, duplicate
      })

    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />

          {(this.state.selectedTemplate) ? (
            <div>
              {(this.state.selectedTemplate.orgCode === this.state.org) ? (
                <SubEditPathway activeOrg={this.state.org} pathwayId={this.state.pathwayId} selectedTemplate={this.state.selectedTemplate} history={this.props.navigate} duplicate={this.state.duplicate} />
              ) : (
                <SubPathwayDetails history={this.props.navigate} pathwayId={this.state.pathwayId} />
              )}
            </div>
          ) : (
            <div>
              <SubEditPathway activeOrg={this.state.org} pathwayId={this.state.pathwayId} selectedTemplate={this.state.selectedTemplate} history={this.props.navigate} duplicate={this.state.duplicate} />
            </div>
          )}

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(OrgEditPathway)
