import Axios from 'axios';

export const saveCourseChanges = async(saveObject)=>{
  console.log('saveCourseChanges called', saveObject)

  return await Axios.post('/api/courses', saveObject)
  .then(async(response) => {

    if (response.data.success) {

      return { success: true, message: response.data.message }

    } else {

      return { success: false, message: response.data.message }
    }
  }).catch((error) => {
      console.log('Learning module save send did not work', error);
  });
}
