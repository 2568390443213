import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSubmissionDetails from './Subcomponents/SubmissionDetails';
import withRouter from './Functions/WithRouter';

class AdvSubmissionDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      // document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount parent documents')

      const { id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
          orgLogo, id
        })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
              <SubSubmissionDetails org={this.state.activeOrg} history={this.props.navigate} id={this.state.id} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(AdvSubmissionDetails);
