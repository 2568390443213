import React, {Component} from 'react';
import Axios from 'axios';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubRenderResume from '../components/Common/RenderResume';
import SubRenderCareerPlan from '../components/Common/RenderCareerPlan';
import SubRenderCoverLetter from '../components/Common/RenderCoverLetter';
import SubRenderBusinessPlan from '../components/Common/RenderBusinessPlan';
import SubRenderFinancialPlan from '../components/Common/RenderFinancialPlan';
import SubRenderMockInterviewDoc from '../components/Common/RenderMockInterviewDoc';
import withRouter from '../components/Functions/WithRouter';
import {subExportFile} from '../components/Functions/ExportFile';

const downloadIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-white.png';

class PreviewPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          pageCount: 1
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderTopNav = this.renderTopNav.bind(this)
        this.renderFile = this.renderFile.bind(this)
        this.exportFile = this.exportFile.bind(this)
        this.passData = this.passData.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      window.scrollTo(0, 0)
      // document.body.style.backgroundColor = "#F5F5F5";
      document.body.style.backgroundColor = "rgba(90,90,90,1)";

      this.retrieveData()

    }

    retrieveData() {
      console.log('retrieveData called')

      // how do we handle privacy

      let { previewType, id } = this.props.params

      let fileType = null
      if (previewType === 'resumes') {
        fileType = 'resume'
      } else if (previewType === 'career-plans') {
        fileType = 'career_plan'
      } else if (previewType === 'financial-plans') {
        fileType = 'financial_plan'
      } else if (previewType === 'business-plans') {
        fileType = 'business_plan'
      } else if (previewType === 'cover-plans') {
        fileType = 'cover_plan'
      } else if (previewType === 'mock-interviews') {
        fileType = 'mock_interview'
      }

      this.setState({ previewType, id, fileType })

      if (previewType === 'resumes' && id) {
        // pull resume data
        Axios.get('/api/resumes/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Resume info query attempted', response.data);

            if (response.data.success) {
              console.log('resume info query worked')

              const docData = response.data.resume
              this.setState({ docData });

            } else {
              console.log('resume query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Resume query did not work for some reason', error);
        });
      } else if (previewType === 'career-plans' && id) {
        Axios.get('/api/career-plans/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Career plan info query attempted', response.data);

            if (response.data.success) {
              console.log('career plan info query worked')

              const docData = response.data.careerPlan
              this.setState({ docData });

            } else {
              console.log('career plan query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Career plan query did not work for some reason', error);
        });
      } else if (previewType === 'cover-letters' && id) {
        Axios.get('/api/cover-letters/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Cover letter info query attempted', response.data);

            if (response.data.success) {
              console.log('cover letter info query worked')

              const docData = response.data.coverLetter
              this.setState({ docData });

            } else {
              console.log('cover letter query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Cover letter query did not work for some reason', error);
        });
      } else if (previewType === 'business-plans' && id) {
        Axios.get('/api/business-plans/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Business plan info query attempted', response.data);

            if (response.data.success) {
              console.log('business plan info query worked')

              const docData = response.data.businessPlan
              this.setState({ docData });

            } else {
              console.log('business plan query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Business plan query did not work for some reason', error);
        });
      } else if (previewType === 'financial-plans' && id) {
        Axios.get('/api/financial-plans/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Financial plan info query attempted', response.data);

            if (response.data.success && response.data.financialPlan) {
              console.log('financial plan info query worked')

              const docData = response.data.financialPlan

              let counterArray = []
              let incomeArray = []
              let expenseArray = []
              let assetArray = []
              let liabilityArray = []
              if (docData.financialPlanContent) {
                counterArray = docData.financialPlanContent.counterArray
                incomeArray = docData.financialPlanContent.incomeArray
                expenseArray = docData.financialPlanContent.expenseArray
                assetArray = docData.financialPlanContent.assetArray
                liabilityArray = docData.financialPlanContent.liabilityArray
              }

              this.setState({ docData, counterArray, incomeArray, expenseArray, assetArray, liabilityArray });

            } else {
              console.log('financial plan query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Financial plan query did not work for some reason', error);
        });
      } else if (previewType === 'mock-interviews' && id) {
        Axios.get('/api/mock-interviews/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Mock interview info query attempted', response.data);

            if (response.data.success) {
              console.log('mock interview info query worked')

              const docData = response.data.mockInterview
              this.setState({ docData });

            } else {
              console.log('mock interview query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Mock interview query did not work for some reason', error);
        });
      } else if (previewType === 'documents' && id) {
        Axios.get('/api/documents/byid', { params: { _id: id } })
        .then((response) => {
          console.log('Documents info query attempted', response.data);

            if (response.data.success) {
              console.log('mock interview info query worked')

              const docData = response.data.document
              previewType = response.data.document.slug

              if (previewType === 'resumes') {
                fileType = 'resume'
              } else if (previewType === 'career-plans') {
                fileType = 'career_plan'
              } else if (previewType === 'financial-plans') {
                fileType = 'financial_plan'
              } else if (previewType === 'business-plans') {
                fileType = 'business_plan'
              } else if (previewType === 'cover-plans') {
                fileType = 'cover_plan'
              } else if (previewType === 'mock-interviews') {
                fileType = 'mock_interview'
              }

              let counterArray = []
              let incomeArray = []
              let expenseArray = []
              let assetArray = []
              let liabilityArray = []
              if (docData.docContent) {
                counterArray = docData.docContent.counterArray
                incomeArray = docData.docContent.incomeArray
                expenseArray = docData.docContent.expenseArray
                assetArray = docData.docContent.assetArray
                liabilityArray = docData.docContent.liabilityArray
              }

              this.setState({
                docData, previewType, fileType,
                counterArray, incomeArray, expenseArray, assetArray, liabilityArray
              });

            } else {
              console.log('mock interview query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Mock interview query did not work for some reason', error);
        });
      }
    }

    exportFile() {
      console.log('exportFile called')

      const fileType = this.state.fileType
      const fileProfile = {
        firstName: this.state.docData.firstName, lastName: this.state.docData.lastName,
        email: this.state.docData.email, username: this.state.docData.username,
        pictureURL: this.state.docData.pictureURL
      }

      let pageCount = this.state.pageCount

      let returnedValue = subExportFile(fileType,this.state.disableExport, this.state.docData.fileName, fileProfile, pageCount)
      if (returnedValue && returnedValue.passedData) {
        this.setState(returnedValue.passedData)
      }
    }

    renderTopNav() {
      console.log('renderTopNav called')

      return (
        <nav key="renderTopNav" className="sticky-nav darker-background padding-20 dark-border">
          <div className="calc-column-offset-80 left-padding-30">
            <p className="white-text">{this.state.docData.fileName}.pdf</p>
          </div>
          <div className="fixed-column-80">
            <button className="background-button" onClick={() => this.exportFile()}>
              <img src={downloadIconWhite} alt="GC" className="image-auto-20" />
            </button>
            <div className="clear" />
          </div>
          <div className="clear" />
        </nav>
      )
    }

    passData(passedData) {
      console.log('passData called', passedData)
      this.setState(passedData)
    }

    renderFile() {
      console.log('renderFile called')

      const docData = this.state.docData

      let sideSectionClass = ""
      let mainSectionClass = "full-width"
      let headerClass = "center-text"
      let sectionHeaderDivClass = "background-button full-width left-text"
      let sectionHeaderTextClass = "heading-text-4"
      let layoutSelected = 'Traditional'
      let fileProfile = {}
      let selectedSections = []
      let pageCount = this.state.pageCount
      let projectsHeight = 0
      let skillsHeight = 0
      let knowledgeHeight = 0
      let extrasHeight = 0
      if (docData) {

        fileProfile = {
          firstName: docData.firstName, lastName: docData.lastName, email: docData.email,
          username: docData. username, pictureURL: docData.pictureURL
        }
        // sectionOptions: ['Summary','Education','Experience','Projects','Skills','Knowledge','Awards'],
        selectedSections = ['Summary','Education','Experience','Projects','Awards']
        if (docData.selectedSections && docData.selectedSections.length > 0) {
          selectedSections = docData.selectedSections
        }

        if (docData.layoutSelected === 'Modern') {
          layoutSelected = docData.layoutSelected
          mainSectionClass = "relative-column-80 left-padding-20"
          sideSectionClass = "relative-column-20"
          headerClass = "left-text"
          sectionHeaderDivClass = "background-button full-width left-text"
          sectionHeaderTextClass = "heading-text-4"
        }

        if (docData.pageCount) {
          pageCount = docData.pageCount
        }

        if (docData.projectsHeight) {
          projectsHeight = docData.projectsHeight
        }

        if (docData.skillsHeight) {
          skillsHeight = docData.skillsHeight
        }

        if (docData.knowledgeHeight) {
          knowledgeHeight = docData.knowledgeHeight
        }

        if (docData.extrasHeight) {
          extrasHeight = docData.extrasHeight
        }
      }

      if (this.state.previewType === 'resumes') {

        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.docContent || docData.resumeContent)) ? (
              <div className="paper-container">
                <SubRenderResume
                  showIdealCandidate={null} layoutSelected={layoutSelected}
                  sideSectionClass={sideSectionClass}
                  sectionHeaderDivClass={sectionHeaderDivClass} sectionHeaderTextClass={sectionHeaderTextClass}
                  mainSectionClass={mainSectionClass} headerClass={headerClass}
                  resumeContent={(docData.docContent) ? docData.docContent : docData.resumeContent} resumeProfile={fileProfile}
                  profilePicImage={fileProfile.pictureURL} profilePicPath={null}
                  selectedSections={selectedSections}

                  pageCount={pageCount}
                  projectsHeight={projectsHeight} skillsHeight={skillsHeight}
                  knowledgeHeight={knowledgeHeight} extrasHeight={extrasHeight}

                  disableEditing={true} hideIfEmpty={true}
                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      } else if (this.state.previewType === 'career-plans') {
        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.careerPlanContent || fileProfile)) ? (
              <div className="paper-container">
                <SubRenderCareerPlan
                  showIdealCandidate={null}
                  careerPlanContent={(docData.docContent) ? docData.docContent : docData.careerPlanContent} careerPlanProfile={fileProfile}
                  sectionHeaderDivClass={sectionHeaderDivClass} sectionHeaderTextClass={sectionHeaderTextClass}
                  mainSectionClass={mainSectionClass} headerClass={headerClass}

                  objective={docData.objective} passions={docData.passions}
                  topSkills={docData.topSkills} developmentNeeds={docData.developmentNeeds}
                  problems={docData.problems} goals={docData.goals}
                  threeYearIncome={docData.threeYearIncome} fiveYearIncome={docData.fiveYearIncome}
                  tenYearIncome={docData.tenYearIncome} learningObjectives={docData.learningObjectives}

                  removeTag={null} addObjectivesToResume={null}
                  passData={this.passData} formChangeHandler={null}

                  disableEditing={true} hideIfEmpty={true}
                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      } else if (this.state.previewType === 'cover-letters') {
        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.coverLetterContent || fileProfile)) ? (
              <div className="paper-container">
                <SubRenderCoverLetter
                  showIdealCandidate={null}
                  sectionHeaderDivClass={sectionHeaderDivClass} sectionHeaderTextClass={sectionHeaderTextClass}
                  mainSectionClass={mainSectionClass} headerClass={headerClass}

                  coverLetterContent={(docData.docContent) ? docData.docContent : docData.coverLetterContent}
                  pageCount={this.state.pageCount}
                  projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
                  knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

                  passData={this.passData} formChangeHandler={null}
                  disableEditing={true} hideIfEmpty={true}

                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      } else if (this.state.previewType === 'business-plans') {
        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.businessPlanContent || fileProfile)) ? (
              <div className="paper-container">
                <SubRenderBusinessPlan
                  showIdealCandidate={null}
                  sectionHeaderDivClass={sectionHeaderDivClass} sectionHeaderTextClass={sectionHeaderTextClass}
                  mainSectionClass={mainSectionClass} headerClass={headerClass}

                  businessPlanContent={(docData.docContent) ? docData.docContent : docData.businessPlanContent}
                  pageCount={this.state.pageCount}
                  projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
                  knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

                  passData={this.passData} formChangeHandler={null}
                  disableEditing={true} hideIfEmpty={true}
                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      } else if (this.state.previewType === 'financial-plans') {
        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.financialPlanContent || fileProfile)) ? (
              <div className="paper-container">
                <SubRenderFinancialPlan
                  financialPlanContent={(docData.docContent) ? docData.docContent : docData.financialPlanContent}
                  counterArray={(this.state.counterArray) ? this.state.counterArray : []}
                  incomeArray={(this.state.incomeArray) ? this.state.incomeArray : []}
                  expenseArray={(this.state.expenseArray) ? this.state.expenseArray : []}
                  assetArray={(this.state.assetArray) ? this.state.assetArray : []}
                  liabilityArray={(this.state.liabilityArray) ? this.state.liabilityArray : []}
                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      } else if (this.state.previewType === 'mock-interviews') {
        return (
          <div key="renderFile" className="dark-background full-space">
            {(docData && (docData.mockInterviewContent || fileProfile)) ? (
              <div className="paper-container">
                <SubRenderMockInterviewDoc
                  showIdealCandidate={null}
                  sectionHeaderDivClass={sectionHeaderDivClass} sectionHeaderTextClass={sectionHeaderTextClass}
                  mainSectionClass={mainSectionClass} headerClass={headerClass}

                  mockInterviewContent={(docData.docContent) ? docData.docContent : docData.mockInterviewContent}
                  pageCount={this.state.pageCount}
                  projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
                  knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

                  passData={this.passData} formChangeHandler={null}
                  disableEditing={true} hideIfEmpty={true}
                />
              </div>
            ) : (
              <div className="paper-container">
                <p>No {this.state.fileType} was found</p>
              </div>
            )}
          </div>
        )
      }
    }

    render() {

        return (
            <div className="full-space">
              {(this.state.docData) && (
                <div>
                  {this.renderTopNav()}
                  {this.renderFile()}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(PreviewPage);
