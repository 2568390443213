import React, {Component} from 'react';
import { Link } from 'react-router-dom';
// import Axios from 'axios';

const avatarExample = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/avatar_example.png';
const avatarBackground = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/avatar_background.png';

let timerId = null

class Avatar extends Component {
    constructor(props) {
      super(props)

      this.state = {
        mouth: { x: 0, y: 0 }
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.renderAvatar = this.renderAvatar.bind(this)
      this.animateAvatar = this.animateAvatar.bind(this)

      // this.child = React.createRef();
      // canvasRef = useRef(null)

    }

    componentDidMount() {
      //see if user is logged in
      console.log('avatar just mounted')
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubAvatar', this.props, prevProps)

      if (this.props.animateAvatar !== prevProps.animateAvatar) {
        this.animateAvatar()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubAvatar')
    }

    renderAvatar() {
      console.log('renderAvatar called')

      // const canvas = document.getElementById('myCanvas')
      // const canvasRef = useRef(null)
      // const canvasRef = this.child
      // const canvas = canvasRef.current
      const canvas = this.refs.yourCanvas
      console.log('show canvas: ', canvas)
      if (canvas) {
        console.log('in canvas!')
        canvas.width = 300
        canvas.height = 300

        const self = this
        let frameCount = 0;
        let mouth = this.state.mouth

        // const skinTone = "rgb(254,184,150)"
        const skinTone = "rgb(175,110,81)"
        const shirtColor = "rgb(154,153,1)"

        const ctx = canvas.getContext("2d")
        ctx.translate(canvas.width/2,canvas.height/2);
        ctx.scale(canvas.width*0.5,canvas.height*0.5);

        const img = new Image()
        img.src = avatarBackground

        const renderFrame = () => {
          console.log('renderFrame called')

          const livePoints = {
            A: { x:0, y:-0.13, xRange: [0,0.2], yRange: [-0.13,0.1]},
            // B: { x:0, y:-0.77, xRange: [0,0.02], yRange: [-0.77,-0.73]},
            B: { x:0, y:-0.69, xRange: [0,0.02], yRange: [-0.69,-0.73]},
            C: { x:0, y:0.46, xRange: [0,0.2], yRange: [0.46,0.62], yNegRange: [0.46,0.40]},
          }
          // C: { x:0, y:0.46, xRange: [0,0.2], yRange: [0.46,0.62], yNegRange: [0.46,0.66]},

          const {A,B,C}=livePoints

          let showBackgroundImage = true
          if (showBackgroundImage) {
            img.onload = function() {
              // ctx.globalAlpha = 0
              // ctx.drawImage(img,-1,-1,2,2)
              // ctx.globalAlpha = 1

              // drawMe()
              animateOfficially()
            }
          } else {
            drawMe()
          }

          function animateOfficially() {
            ctx.clearRect(-1,-1,2,2)

            ctx.globalAlpha = 0
            ctx.drawImage(img,-1,-1,2,2)
            ctx.globalAlpha = 1
            drawMe()

            // requestAnimationFrame(animateOfficially)

            if (self.state.isAnimating) {


              // if (mouth.y === 0) {
              //   mouth.y = 0.5
              // } else {
              //   mouth.y = 0
              // }

              if (mouth.y === 0 || mouth.y < 0) {
                mouth['y'] = mouth.y + 0.1
                mouth['yStatus'] = 'Ascending'
              } else if (mouth.y === 0.5 || mouth.y > 0.5) {
                mouth['y'] = mouth.y - 0.1
                mouth['yStatus'] = 'Descending'
              } else if (mouth.yStatus === 'Ascending') {
                mouth['y'] = mouth.y + 0.1
              } else if (mouth.yStatus === 'Descending') {
                mouth['y'] = mouth.y - 0.1
              }
              console.log('what is happening now?!?!?!?!')
              // requestAnimationFrame(animateOfficially)

              timerId = window.setInterval(function(){
                requestAnimationFrame(animateOfficially)
                clearInterval(timerId)
              }, 500);
            } else {
              mouth['y'] = 0
              clearInterval(timerId)
              // requestAnimationFrame(animateOfficially)
            }
          }

          canvas.addEventListener("click",getCoords)

          function getCoords(evt) {
            console.log(evt.x,evt.y)
            const x = (evt.x - canvas.width / 2) / (canvas.width / 2)
            const y = (evt.x - canvas.height / 2) / (canvas.height / 2)
          }
          ctx.lineWidth = 0.01
          function drawMe() {
            drawBody()
            drawHead()


            // drawPoint(A,"A")
            // drawPoint(B,"B")
            // drawPoint(C,"C")

          }

          function drawHead() {
            ctx.strokeStyle = 'gray'
            ctx.beginPath()
            // ctx.moveTo(0,-0.77)
            // ctx.lineTo(0,0.46)
            // ctx.moveTo(-0.5,-0.13)
            // ctx.lineTo(0.5,-0.13)
            ctx.moveTo(B.x,B.x)
            ctx.quadraticCurveTo(A.x,A.y,C.x,C.y)
            ctx.moveTo(-0.45,-0.13)
            ctx.quadraticCurveTo(A.x,A.y,0.45,-0.13)
            ctx.stroke()

            ctx.beginPath()
            // ctx.moveTo(0,-0.77)
            // ctx.quadraticCurveTo(-0.44,-0.71,-0.5,-0.13)
            // ctx.quadraticCurveTo(-0.37,0.28,0,0.46)
            // ctx.quadraticCurveTo(+0.37,0.28,0.5,-0.13)
            // ctx.quadraticCurveTo(+0.44,-0.71,0,-0.77)
            ctx.moveTo(B.x,B.y)
            ctx.quadraticCurveTo(-0.44,-0.71,-0.45,-0.13)
            ctx.quadraticCurveTo(-0.37,0.28,C.x,C.y)
            ctx.quadraticCurveTo(+0.37,0.28,0.45,-0.13)
            ctx.quadraticCurveTo(+0.44,-0.71,B.x,B.y)
            ctx.stroke()
            ctx.fillStyle = skinTone
            ctx.fill()

            ctx.strokeStyle = "black"

            drawEyes(A)
            drawBeard(A)
            drawHair(A)
            drawNose(A)
            drawEars(A)
          }

          function drawBody() {

            ctx.strokeStyle = 'gray'
            ctx.beginPath()
            ctx.moveTo(-0.25,0.21)
            ctx.quadraticCurveTo(-0.21,0.41,-0.23,0.61)
            ctx.lineTo(-0.89,0.85)
            ctx.lineTo(-0.89,1.1)
            ctx.lineTo(+0.89,1.1)
            ctx.lineTo(+0.89,0.85)
            ctx.lineTo(+0.23,0.61)
            ctx.quadraticCurveTo(+0.21,0.41,+0.23,0.21)
            ctx.stroke()
            ctx.strokeStyle = 'black'
            ctx.fillStyle = skinTone
            ctx.fill()
            ctx.stroke()

            ctx.fillStyle = shirtColor

            // overall hoodie excluding hood
            ctx.beginPath()
            ctx.moveTo(0,0.88)
            ctx.lineTo(-0.41,0.65)
            ctx.lineTo(-0.84,0.81)
            ctx.quadraticCurveTo(-0.93,0.87,-0.95,1.1)
            ctx.lineTo(0.95,1.1)
            ctx.quadraticCurveTo(0.93,0.87,0.84,0.81)
            ctx.lineTo(0.41,0.65)
            ctx.closePath()
            ctx.fill()
            ctx.stroke()

            // left hood of hoodie
            ctx.beginPath()
            ctx.moveTo(-0.41,0.71)
            ctx.quadraticCurveTo(-0.52,0.67,-0.47,0.61)
            ctx.quadraticCurveTo(-0.37,0.53,-0.18,0.73)
            ctx.quadraticCurveTo(-0.03,0.74,0.11,0.87)
            ctx.quadraticCurveTo(-0.13,0.92,-0.25,0.82)
            ctx.stroke()
            ctx.fill()

            // right hood of hoodie
            ctx.beginPath()
            ctx.moveTo(-0.17,0.87)
            ctx.quadraticCurveTo(-0.01,0.78,0.15,0.77)
            ctx.quadraticCurveTo(0.29,0.75,0.37,0.64)
            ctx.quadraticCurveTo(0.43,0.58,0.49,0.61)
            ctx.quadraticCurveTo(0.54,0.69,0.35,0.83)
            ctx.quadraticCurveTo(0.24,0.89,0.08,0.9)
            ctx.stroke()
            ctx.quadraticCurveTo(-0.06,0.92,-0.17,0.87)

            ctx.fill()

          }

          function drawPoint(loc,label,rad=0.07) {
            ctx.beginPath()
            ctx.arc(loc.x,loc.y,rad,0,Math.PI * 2)
            ctx.stroke()
            ctx.fillStyle="green"
            ctx.fill()
            ctx.fillStyle="white"
            ctx.font=(rad*1.6)+'px Arial'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            // ctx.fill()
            ctx.fillText(label,loc.x,loc.y+rad*0.15)
          }

          function drawEyes(ref) {
            ctx.save()
            ctx.translate(ref.x,ref.y)

            // drawEye(ref.xOffset)
            drawEye()

            ctx.scale(-1,1)

            drawEye()

            ctx.restore()
          }

          function drawEye(scaleX) {
            // ctx.scale(1-scaleX,1)
            ctx.beginPath()
            ctx.moveTo(0.05,0.11)
            ctx.quadraticCurveTo(0.06,0.18,0.20,0.19)
            ctx.quadraticCurveTo(0.35,0.18,0.35,0.06)
            ctx.quadraticCurveTo(0.35,0.02,0.19,0.02)
            ctx.quadraticCurveTo(0.05,0.01,0.05,0.11)
            // ctx.fillStyle = rgba(0,255,0,0.3)
            ctx.fillStyle = 'white'
            ctx.fill()

            ctx.beginPath()
            ctx.fillStyle = 'black'
            ctx.moveTo(0.07,0.07)
            ctx.quadraticCurveTo(0.21,-0.01,0.33,0.08)
            ctx.quadraticCurveTo(0.39,0.10,0.36,0.04)
            ctx.quadraticCurveTo(0.19,-0.06,0.03,0.04)
            ctx.quadraticCurveTo(0.02,0.09,0.07,0.07)
            ctx.fill()

            ctx.beginPath()
            ctx.arc(0.17,0.09,0.065,0,Math.PI*2)
            ctx.fill()

            ctx.beginPath()
            ctx.moveTo(0.20,0.19)
            ctx.quadraticCurveTo(0.35,0.18,0.35,0.06)
            // ctx.restore()
            ctx.stroke()
            // ctx.restore()
          }

          function drawBeard(ref) {
            ctx.save()
            ctx.translate(ref.x,ref.y)

            let xOffset = 0
            if (ref.xOffset) {
              xOffset = ref.xOffset
            }
            let yOffset = 0
            if (ref.yOffset) {
              yOffset = ref.yOffset
            }

            drawHalfBeard(xOffset,yOffset)
            ctx.scale(-1,1)
            drawHalfBeard(xOffset,yOffset)

            ctx.restore()
          }

          function drawHalfBeard(xOffset,yOffset) {
            const verticalScaler = Math.min(0,yOffset)
            const horizontalScaler = Math.min(0,xOffset)

            const scaleX = Math.max(0,xOffset)
            const horizontalSquish = 1 - scaleX*0.45
            const scaleY = yOffset
            const verticalSquish = 1 - Math.abs(scaleY*0.4)
            ctx.scale(horizontalSquish,verticalSquish)

            ctx.beginPath()
            ctx.moveTo(0.41,0.09)
            ctx.quadraticCurveTo(0.35,0.28,0.17,0.36)
            ctx.quadraticCurveTo(0.09,0.33,0,0.35)

            // let mouth = this.state.mouth
            // let mouthOpen = false
            // if (self.state.mouthOpen) {
            //   mouth['y'] = 0.3
            // }

            ctx.lineTo(0.01,0.38)
            ctx.lineTo(0.11 + mouth.x * 0.1,0.42)
            ctx.lineTo(0.06,0.49 + mouth.y * 0.1)
            ctx.lineTo(0.04,0.47 + mouth.y * 0.1)
            // ctx.lineTo(0.01,0.46 + mouth.y * 0.1)
            ctx.lineTo(0,0.47 + mouth.y * 0.1)

            const bottomMostPoint = {
              x: 0,
              y: 0.59 - verticalScaler * 0.3 + mouth.y*0.1
            }
            ctx.lineTo(bottomMostPoint.x,bottomMostPoint.y)
            const rightMostPoint = {
              x: 0.46 - horizontalScaler * 0.2,
              y: 0.09
            }

            const controlPoint = {
              x: 0.35 + horizontalScaler * 0.2,
              y: 0.46 - verticalScaler * 0.2
            }
            ctx.quadraticCurveTo(controlPoint.x,controlPoint.y,rightMostPoint.x,rightMostPoint.y)
            ctx.closePath()

            ctx.fillStyle = "black"
            ctx.fill()
          }

          function drawNose(ref) {
            ctx.save()
            ctx.translate(ref.x,ref.y)

            ctx.beginPath()
            ctx.moveTo(0,0.2)

            let xOffset = 0.2
            if (ref.xOffset) {
              xOffset = ref.xOffset
            }
            let yOffset = 0
            if (ref.yOffset) {
              yOffset = ref.yOffset
            }

            const tip = {
              x: xOffset * 0.2,
              y: 0.29
            }

            ctx.quadraticCurveTo(tip.x,tip.y,0,0.33)
            ctx.stroke()

            ctx.restore()
          }

          function drawHair(ref) {
            ctx.save()
            ctx.translate(ref.x,ref.y)

            let xOffset = 0
            if (ref.xOffset) {
              xOffset = ref.xOffset
            }
            let yOffset = 0
            if (ref.yOffset) {
              yOffset = ref.yOffset
            }

            drawHalfHair(xOffset,yOffset)
            ctx.scale(-1,1)
            drawHalfHair(xOffset,yOffset)

            ctx.restore()
          }

          function drawHalfHair(xOffset,yOffset) {
            // ctx.save()
            const verticalScaler = Math.min(0,yOffset)
            const horizontalScaler = Math.min(0,xOffset)

            const scaleX = Math.max(0,xOffset)
            const horizontalSquish = 1 - scaleX*0.45
            const scaleY = yOffset
            const verticalSquish = 1 - Math.abs(scaleY*0.4)
            ctx.scale(horizontalSquish,verticalSquish)

            ctx.beginPath()
            ctx.moveTo(0.41,0.09)
            ctx.quadraticCurveTo(0.47,-0.44,0,-0.44)
            ctx.lineTo(0,-0.57)
            // ctx.quadraticCurveTo(0.45,-0.54,0,0.35)
            //
            // ctx.lineTo(0.01,0.38)
            // ctx.lineTo(0.11,0.42)
            // ctx.lineTo(0.06,0.49)
            // ctx.lineTo(0.04,0.46)
            // ctx.lineTo(0.01,0.46)
            // ctx.lineTo(0,0.59)
            //
            // const bottomMostPoint = {
            //   x: 0,
            //   y: 0.59 - verticalScaler * 0.2
            // }
            // ctx.lineTo(bottomMostPoint.x,bottomMostPoint.y)
            const rightMostPoint = {
              x: 0.44 - horizontalScaler * 0.2,
              y: 0.09
            }

            // const controlPoint = {
            //   x: 0.35 + horizontalScaler * 0.2,
            //   y: 0.46 - verticalScaler * 0.2
            // }
            // ctx.quadraticCurveTo(controlPoint.x,controlPoint.y,rightMostPoint.x,rightMostPoint.y)
            // ctx.quadraticCurveTo(0.6,-0.6,rightMostPoint.x,rightMostPoint.y)
            // ctx.quadraticCurveTo(0.65,-0.54,rightMostPoint.x,rightMostPoint.y)
            ctx.quadraticCurveTo(0.55,-0.55,rightMostPoint.x,rightMostPoint.y)

            ctx.closePath()

            ctx.fillStyle = "black"
            ctx.fill()
          }

          function drawEars(ref) {
            ctx.save()
            ctx.translate(ref.x,ref.y)

            let xOffset = 0.2
            if (ref.xOffset) {
              xOffset = ref.xOffset
            }
            let yOffset = 0
            if (ref.yOffset) {
              yOffset = ref.yOffset
            }

            drawEar(Math.max(0,-xOffset),yOffset)
            ctx.scale(-1,1)
            drawEar(Math.max(0,-xOffset),yOffset)

            // ctx.beginPath()

            ctx.restore()
          }

          function drawEar(scaleX,scaleY) {
            // ctx.save()
            const verticalSquish = 1 - Math.abs(scaleY*0.2)
            const horizontalSquish = 1 - scaleX * 0.4
            ctx.scale(horizontalSquish,verticalSquish)

            ctx.moveTo(0.41,0.1)
            ctx.quadraticCurveTo(0.4,-0.02,0.48,-0.02)
            ctx.quadraticCurveTo(0.49,0.06,0.40,0.25)
            ctx.quadraticCurveTo(0.37,0.28,0.37,0.23)

            ctx.closePath()
            ctx.fillStyle = skinTone
            ctx.fill()
            ctx.stroke()

            // ctx.restore()
          }

          // if (frameCount < 300) {
          //   frameCount++;
          //   console.log('log the framecount: ', frameCount, mouth.y)
          //
          //   if (mouth.y === 0) {
          //     mouth['y'] + mouth.y + 1
          //     mouth['yStatus'] = 'Ascending'
          //   } else if (mouth.y === 0.5) {
          //     mouth['y'] + mouth.y - 1
          //     mouth['yStatus'] = 'Descending'
          //   } else if (mouth.yStatus === 'Ascending') {
          //     mouth['y'] + mouth.y + 1
          //   } else if (mouth.yStatus === 'Descending') {
          //     mouth['y'] + mouth.y - 1
          //   }
          //
          //   // self.setState({ mouthOpen: !self.state.mouthOpen })
          //   requestAnimationFrame(renderFrame);
          // }

          // if (self.state.isAnimating) {
          //   // frameCount++;
          //   // console.log('show the frameCount: ', frameCount)
          //   // window.requestAnimationFrame(self.animateAvatar)
          //   requestAnimationFrame(animateFrame);
          // }

        };

        renderFrame()
        // requestAnimationFrame(renderFrame);


        // function animateFrame() {
        //
        //   if (self.state.isAnimating && frameCount < 100) {
        //     frameCount++;
        //     console.log('show the frameCount: ', frameCount)
        //     // window.requestAnimationFrame(self.animateAvatar)
        //
        //     if (mouth.y === 0) {
        //       mouth['y'] + mouth.y + 1
        //       mouth['yStatus'] = 'Ascending'
        //     } else if (mouth.y === 0.5) {
        //       mouth['y'] + mouth.y - 1
        //       mouth['yStatus'] = 'Descending'
        //     } else if (mouth.yStatus === 'Ascending') {
        //       mouth['y'] + mouth.y + 1
        //     } else if (mouth.yStatus === 'Descending') {
        //       mouth['y'] + mouth.y - 1
        //     }
        //
        //     // self.setState({ mouth })
        //     // self.renderAvatar()
        //
        //     requestAnimationFrame(renderFrame);
        //   }
        // }

        //
        // const tick = () => {
        //   console.log('show the tick')
        //   // if (!canvas.current) return;
        //   // renderFrame(self.state.mouthOpen)
        //   if (frameCount < 5) {
        //     self.setState({ mouthOpen: !self.state.mouthOpen })
        //     frameCount++;
        //     // draw(frameCount);
        //     // drawBeard(A)
        //     // renderFrame(self.state.mouthOpen)
        //     // requestAnimationFrame(tick);
        //   } else {
        //     // renderFrame(self.state.mouthOpen)
        //   }
        // };



        // let isAnimating = true

        // let animationFrameId;
        //
        // // Check if null context has been replaced on component mount
        // if (isAnimating) {
        //     //Our draw came here
        //     const animate = () => {
        //         if (frameCount < 5) {
        //           self.setState({ mouthOpen: !self.state.mouthOpen })
        //           frameCount++;
        //           // draw(frameCount);
        //           drawBeard(A)
        //         } else {
        //           self.setState({ isAnimating: false })
        //         }
        //
        //         animationFrameId = window.requestAnimationFrame(animate);
        //     };
        //     animate();
        // } else {
        //   window.cancelAnimationFrame(animationFrameId);
        // }


      }
    }

    animateAvatar() {
      console.log('animateAvatar called')

      // const self = this

      // let mouth = this.state.mouth
      // function animate() {
      //   console.log('animate called')
      //
      //   // if (frameCount < 300) {
      //   //   frameCount++;
      //   //   console.log('log the framecount: ', frameCount, mouth.y)
      //   //
      //   //   if (mouth.y === 0) {
      //   //     mouth['y'] + mouth.y + 1
      //   //     mouth['yStatus'] = 'Ascending'
      //   //   } else if (mouth.y === 0.5) {
      //   //     mouth['y'] + mouth.y - 1
      //   //     mouth['yStatus'] = 'Descending'
      //   //   } else if (mouth.yStatus === 'Ascending') {
      //   //     mouth['y'] + mouth.y + 1
      //   //   } else if (mouth.yStatus === 'Descending') {
      //   //     mouth['y'] + mouth.y - 1
      //   //   }
      //   //
      //   //   // self.setState({ mouthOpen: !self.state.mouthOpen })
      //   //   requestAnimationFrame(renderFrame);
      //   // }
      //     // let mouth = self.state.mouth
      //     if (mouth.y === 0) {
      //       mouth['y'] + mouth.y + 1
      //       mouth['yStatus'] = 'Ascending'
      //     } else if (mouth.y === 0.5) {
      //       mouth['y'] + mouth.y - 1
      //       mouth['yStatus'] = 'Descending'
      //     } else if (mouth.yStatus === 'Ascending') {
      //       mouth['y'] + mouth.y + 1
      //     } else if (mouth.yStatus === 'Descending') {
      //       mouth['y'] + mouth.y - 1
      //     }
      //
      //     self.setState({ mouth })
      //     self.renderAvatar()
      //
      //     // self.setState({ mouthOpen: !self.state.mouthOpen })
      //     // requestAnimationFrame(animate);
      // }

      if (this.state.isAnimating) {
        this.setState({ isAnimating: false })
        this.renderAvatar()
      } else {
        this.setState({ isAnimating: true })
        // requestAnimationFrame(animate);
        this.renderAvatar()
      }
    }

    render() {
      console.log('count me')
      return (
        <div key="renderAvatarSubComponent">
          <canvas ref="yourCanvas" />
          {this.renderAvatar()}

          <div className="clear" />
          <button className="btn btn-primary" onClick={() => this.animateAvatar()}>Animate</button>

          {/*
          {(!this.state.avatarRenderedOnce) && (
            <div>

            </div>
          )}
          <canvas ref={this.child} />
          <canvas ref={canvasRef} {renderAvatar()}/>*/}

         </div>
      )
    }
}

export default Avatar;
