import React, {Component} from 'react';

import Axios from 'axios';
// import LogInWidget from '../../components/Common/LogInWidget';
import SubLogInFields from './LogInFields';
import {signOut} from '../Services/AuthRoutes';

class SendMessage extends Component {
    constructor(props) {
      super(props)
      this.state = {
        inBeta: false,
        profileData: {}
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.sendMessage = this.sendMessage.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.passData = this.passData.bind(this)
      this.logout = this.logout.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.profileData !== prevProps.profileData) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within subEditSubscription')

      const email = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const activeOrg = localStorage.getItem('activeOrg');
      const accountCode = localStorage.getItem('accountCode');

      const profileData = this.props.profileData
      const source = this.props.source

      this.setState({
        emailId: email, cuFirstName, cuLastName, orgFocus, roleName, activeOrg, accountCode, profileData, source
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    closeModal() {

      // this.setState({ modalIsOpen: false });
      this.props.closeModal()

    }

    sendMessage() {
      console.log('sendMessage called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (!this.state.message && this.state.message === '') {
        this.setState({ errorMessage: 'Please write your message' })
      } else {

        //make it so that you see that it is coming from the employer brand name
        Axios.post('/api/notifications/message', { //sessions: sessions
          creatorEmail: this.state.emailId, recipientFirstName: this.state.profileData.firstName,
          recipientLastName: this.state.profileData.lastName, recipientEmail: this.state.profileData.email,
          recipientType: 'Profile', senderFirstName: this.state.cuFirstName,senderLastName: this.state.cuLastName,
          senderEmail: this.state.emailId, message: this.state.message,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message sent worked', response.data);
            this.setState({ message: '', successMessage: 'Message sent successfully!' })
          } else {
            console.log('there was an error sending the message');
            this.setState({ message: '', errorMessage: 'Message sent successfully!' })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
            this.setState({ message: '', errorMessage: error.toString() })
        });
      }
    }

    passData(data) {
      console.log('passData called')

      if (data.success) {

        let emailId = localStorage.getItem('email');
        let pictureURL = localStorage.getItem('pictureURL');
        let cuFirstName = localStorage.getItem('firstName');
        let cuLastName = localStorage.getItem('lastName');
        let username = localStorage.getItem('username');
        let activeOrg = localStorage.getItem('activeOrg');
        let placementPartners = localStorage.getItem('placementPartners');
        let orgName = localStorage.getItem('orgName');
        let roleName = localStorage.getItem('roleName');
        let accountCode = localStorage.getItem('emp');
        let employerName = localStorage.getItem('employerName');
        let jobTitle = localStorage.getItem('jobTitle');
        let remoteAuth = localStorage.getItem('remoteAuth');

        this.setState({ successMessage: data.message, errorMessage: null,
          emailId, pictureURL, cuFirstName, cuLastName, username, activeOrg,
          placementPartners, orgName, roleName, accountCode, employerName, jobTitle, remoteAuth
        })
      } else {
        this.setState({ successMessage: data.message, errorMessage: null })
      }
    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(this.state.emailId, this.state.activeOrg, this.state.orgFocus, this.state.accountCode, this.state.roleName, this.props.navigate, true)

      if (returnedValue && returnedValue.error && returnedValue.error.message) {

        this.setState({ errorMessage: returnedValue.error.message.toString(), isSaving: false })
      } else if (returnedValue.success === false) {
        this.setState({ errorMessage: returnedValue.message, isSaving: false })
      } else {
        const signInPage = true
        const emailId = null
        const activeOrg = null
        const orgFocus = null
        const roleName = null
        const username = null

        this.setState({ signInPage, emailId, activeOrg, orgFocus, roleName, username })
      }
    }

    render() {

      return (
        <div>
          {(this.state.emailId) ? (
            <div>
              <p className="heading-text-2">Send a Message to {this.state.profileData.firstName} {this.state.profileData.lastName}</p>
              <p className="description-text-2 half-bold-text">Your signed in as {this.state.cuFirstName} {this.state.cuLastName} <button className="background-button cta-color underline-text" onClick={() => this.logout()}>(sign out)</button></p>
              <div className="spacer" />

              <div className="row-20">
                <textarea className="text-field" type="text" placeholder="Write your message..." name="message" value={this.state.message} onChange={this.formChangeHandler} />
              </div>

              <div className="spacer" />

              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}
              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}

              {(this.state.inBeta) && (
                <div className="bottom-padding">
                  <p className="error-color">This messaging feature is disabled while we are in beta</p>
                </div>
              )}

              <div>
                <div className="float-left right-padding">
                  <button className={(this.state.inBeta) ? "btn medium-background standard-border" : "btn btn-primary"} disabled={(this.state.inBeta) ? true : this.state.isSaving} onClick={() => this.sendMessage()}>Send Message</button>
                </div>
                <div className="float-left">
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                </div>
                <div className="clear" />
              </div>

            </div>
          ) : (
            <div>

              {/*<LogInWidget passData={this.passData} signInPage={this.state.signInPage} history={this.props.navigate} modalIsOpen={true} />*/}

              <div key="showLogInFields">
                <div className="full-width padding-20">
                  {(this.state.loginType === "SignUp") ? (
                    <div className="row-10">
                      <p className="heading-text-4">Sign Up</p>
                    </div>
                  ) : (
                    <div className="row-10">
                      <p className="heading-text-4">Log In</p>
                    </div>
                  )}

                  {(this.state.loginType === 'SignUp') ? (
                    <div>
                      <div className="bottom-margin">
                        <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >Log In</button> to import your information</p>
                      </div>
                    </div>
                  ) : (
                    <div className="bottom-margin">
                      <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                    </div>
                  )}

                  <SubLogInFields history={this.props.navigate}
                    formChangeHandler={this.formChangeHandler} passData={this.passData}
                    onlyFields={true} logout={this.logout} type={this.state.loginType}
                    loggedIn={this.state.emailId} emailId={this.state.emailId}
                    orgCode={this.state.activeOrg} closeModal={this.closeModal}
                  />

                </div>
              </div>
            </div>
          )}
        </div>
      )
    }
}

export default SendMessage;
