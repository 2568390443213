import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubGroupDetails from './Subcomponents/GroupDetails';
import withRouter from './Functions/WithRouter';

class EmpGroupDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('mountedEmpGroupDetails')

      const { emp, groupId } = this.props.params

      let email = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      if (!this.props.location.state) {
        console.log('went directly to posting')

        this.setState({ groupId, emp, activeOrg, orgMode, orgLogo })

      } else {

        const { selectedGroup, objectId } = this.props.location.state;
        console.log('show location state: ', this.props.location.state)

        if (selectedGroup) {
          this.setState({ selectedGroup, emp, activeOrg, orgMode, orgLogo })

        } else if (objectId) {
          this.setState({ groupId: objectId, emp, activeOrg, orgMode, orgLogo })
        }
      }
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate called within parentGroupDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.groupId !== prevProps.params.groupId) {
        // console.log('new group selected in parent', this.props.params.groupId)
        let selectedGroup = this.props.location.state
        if (this.props.location && this.props.location.state && this.props.location.state.selectedGroup) {
          selectedGroup = this.props.location.state.selectedGroup
        }
        this.setState({ groupId: this.props.params.groupId, selectedGroup })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

          {(this.state.emp) && (
            <SubGroupDetails selectedGroup={this.state.selectedGroup} groupId={this.state.groupId} history={this.props.navigate} activeOrg={this.state.activeOrg} accountCode={this.state.emp} />
          )}

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(EmpGroupDetails)
