import React, { Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from './Functions/WithRouter';

class EmpSignUp extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('sign up page is mounting')

      //user navigated from a landing page or from the app
      const { emp } = this.props.params
      this.setState({ emp })
    }

    render() {

      return (
        <div>
          <LoginForm roleName="Employer" orgCode={this.state.orgCode} courseId={this.state.courseId} accountCode={this.state.emp} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignUp" />
        </div>
      )
    }
}

export default withRouter(EmpSignUp)
