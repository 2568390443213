import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCoursesTwo from './Subcomponents/CoursesTwo';
import SubSubscribe from './Subcomponents/Subscribe';
import withRouter from './Functions/WithRouter';

class Courses extends Component {
    constructor(props) {
        super(props)

        this.state = {
          opacity: 0,
          transform: 'translate(90%)',

          enableSubscriptions: false,
          subscribed: false,
        }

        this.loadWorkspace= this.loadWorkspace.bind(this)
        this.passSubscriptionData = this.passSubscriptionData.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const remoteAuth = localStorage.getItem('remoteAuth');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show orgFocus ', orgFocus)

      if (email) {

        let id = null
        if (this.props.params) {
          id = this.props.params.id
        }

        const problemTypeOptions = ['','Exploratory','Technical']
        const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Difficult','Challenger']
        const popularityOptions = ['','More than 10','More than 50']
        const postDateOptions = ['','Within the Last Month','Within the Last Year']

        let subNavSelected = null
        if (this.props.location && this.props.location.state) {
          subNavSelected = this.props.location.state.subNavSelected
        }

        this.setState({ emailId: email, username, activeOrg, orgFocus, orgLogo, subNavSelected,
          problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions, id
        });

        const isActive = true
        const includeMembership = false

        Axios.get('/api/payments/subscription', { params: {
          email, orgCode: activeOrg, accountCode: null, subscriptionType: 'Individual', isActive, includeMembership
        } })
        .then((response) => {
          console.log('Subscription query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved subscription')

             this.setState({ subscribed: true })

           } else {
             console.log('no org data found', response.data.message)

           }

        }).catch((error) => {
           console.log('Subscription query did not work', error);

        });
      }
    }

    // componentWillReceiveProps(newProps) {
    //   console.log('componentWillReceiveProps called', newProps.match, newProps.params)
    //
    //   // if (this.state.careerDetails.explore_more.careers){
    //   //   if (newProps.match.params.careerSelected === this.state.careerDetails.explore_more.careers.career[0].title) {
    //   //     console.log('reload the page');
    //   //     this.getInitialData(newProps.match.params.careerSelected)
    //   //   } else {
    //   //     console.log('do not reload the page');
    //   //   }
    //   // }
    // }

    changeSubNavSelected(subNavSelected) {
      console.log('changeSubNavSelected called', subNavSelected)

      this.setState({ subNavSelected })
      localStorage.setItem('courseNavSelected', subNavSelected)
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in exploration', subscription)

      this.setState({ subscribed: true })

    }

    render() {

      return(
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace}  history={this.props.navigate} changeSubNavSelected={this.changeSubNavSelected}/>

          <div>
            {((this.state.activeOrg === 'guidedcompass') && !this.state.subscribed && this.state.enableSubscriptions) ? (
              <div>
                <div className="standard-container-2">
                  <SubSubscribe history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} />

                </div>
              </div>
            ) : (
              <div>
                <SubCoursesTwo activeOrg={this.state.activeOrg} subNavSelected={this.state.subNavSelected} lessonId={this.state.id} />
              </div>
            )}
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(Courses)
