import React, {Component} from 'react';

class EmpMessages extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
    }

    render() {
      return (
          <div>
          </div>
      )
    }
}

export default EmpMessages
