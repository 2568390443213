import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import SubComments from '../Common/Comments';
import SubRenderProfiles from '../Common/RenderProfiles';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {requestConnection} from '../Services/FriendRoutes';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const linkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-white.png';
const udemyLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udemy-logo.png';
const courseraLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/coursera-logo.png";
const udacityLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udacity-logo.png";
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const courseIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-white.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
// const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
// const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const checkmarkIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png";
// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const favoritesIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-white.png";
const addLessonIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-lesson-icon-white.png";
const shareIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png";

class CourseDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          prioritizeNative: true,
          subNavSelected: 'All',
          totalPercent: 100,
          subNavCategories: [],

          favorites: [],
          favoritedCourseDetails: [],
          newFavorites: [],
          showSubFunction: true,

          completions: [],
          completedCourseDetails: [],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.followPerson = this.followPerson.bind(this)
        this.pullCourses = this.pullCourses.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)

        this.markCompleted = this.markCompleted.bind(this)
        this.enrollInCourse = this.enrollInCourse.bind(this)

        this.configureLink = this.configureLink.bind(this)
        this.calculateDeadline = this.calculateDeadline.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.orgCode !== prevProps.orgCode || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.courseId !== prevProps.courseId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubCourseDetails', this.props)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let pictureURL = localStorage.getItem('pictureURL');
      let org = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');
      if (!org && !this.props.activeOrg && this.state.prioritizeNative) {
        org = 'guidedcompass'
      }
      const passedOrgCode = this.props.orgCode

      let isLoading = true

      let subNavCategories = this.state.subNavCategories
      let subNavSelected = 'All'
      // if (window.location.pathname.includes('/pages/')) {
      //   subNavCategories = this.state.benchmarkCategories
      //   subNavSelected = 'All'
      // }

      this.setState({ isLoading, orgCode: org, passedOrgCode, emailId: email, email, username,
        cuFirstName, cuLastName, pictureURL, roleName, subNavCategories, subNavSelected
      })

      Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites

           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           if (favorites && favorites.length > 0) {
             Axios.get('/api/favorites/detail', { params: { favorites, orgCode: org } })
             .then((response2) => {
               console.log('Favorites detail query attempted', response2.data);

               if (response2.data.success) {
                 console.log('successfully retrieved favorites detail', response2.data.favorites)

                 let favoritedCourseDetails = []

                 for (let i = 1; i <= response.data.favorites.length; i++) {
                   if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'course') {
                     const favoritedCourse = response2.data.favorites[i - 1]
                     favoritedCourse['favoriteId'] = response.data.favorites[i - 1]
                     favoritedCourseDetails.push(favoritedCourse)
                   }
                 }

                 //query info on those favorites
                 this.setState({ favorites, newFavorites, favoritedCourseDetails })

               } else {
                 console.log('no favorites detail data found', response2.data.message)
               }

             }).catch((error) => {
                 console.log('Favorites detail query did not work', error);
             });
           }

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

      if (this.props.courseId) {

        Axios.get('/api/courses/byid', { params: { _id: this.props.courseId } })
        .then((response) => {
          console.log('Course query attempted with native database', response.data);

          if (response.data.success) {
            console.log('course query worked natively')

            // let selectedCourse = response.data.course
            this.pullCourseDetails(response.data.course, org, email)

          } else {
            console.log('course query did not work', response.data.message)

            // try Udemy
            Axios.get('/api/courses/byid', { params: { _id: this.props.courseId, source: 'Udemy', saveCourse: true } })
            .then((response) => {
              console.log('Course query attempted with Udemy', response.data);

              if (response.data.success) {
                console.log('course query worked')

                let selectedCourse = response.data.course
                if (selectedCourse.udemyId) {
                  selectedCourse['id'] = selectedCourse.udemyId
                }

                this.pullCourseDetails(selectedCourse, org, email)

              } else {
                console.log('course query did not work with Udemy', response.data.message)
              }

            }).catch((error) => {
                console.log('Course query with Udemy did not work for some reason', error);
            });
          }

        }).catch((error) => {
            console.log('Course query in native database did not work for some reason', error);
        });
      }
    }

    pullCourseDetails(selectedCourse, org, email) {
      console.log('pullCourseDetails called')

      let courseId = selectedCourse._id
      if (!courseId && selectedCourse.source === 'Udemy') {
        courseId = selectedCourse.id
      }

      let shareURL = window.location.protocol + "//" + window.location.host + "/app/courses/" + courseId
      if (window.location.pathname.includes('/pages/')) {
        shareURL = window.location.protocol + "//" + window.location.host + "/courses/" + courseId
      }
      const shareTitle = 'Check Out ' + selectedCourse.title + ' Course On Guided Compass!'
      let shareBody = 'Check out the ' + selectedCourse.title + ' course on Guided Compass'

      let subNavCategories = ['All','About','Comments','Students','Alumni']
      if (selectedCourse.source === 'Udemy' && !selectedCourse.orgCode) {
        subNavCategories = ['All','Reviews','Comments','Students']
      }

      this.setState({ selectedCourse, isLoading: false, shareURL, shareTitle, shareBody, subNavCategories })

      let courseName = selectedCourse.name
      if (selectedCourse.title) {
        courseName = selectedCourse.title
      }

      this.pullCourses(courseName, null, null, null, selectedCourse, org)

      Axios.get('/api/comments', { params: { parentPostId: selectedCourse._id } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           if (response.data.comments && response.data.comments.length > 0) {

             this.setState({ comments: response.data.comments })
           }
         } else {
           console.log('no comments data found', response.data.message)
         }

      }).catch((error) => {
         console.log('Comments query did not work', error);
      });

      if (selectedCourse.source === 'Udemy') {
        Axios.get('/api/courses/reviews', { params: { _id: selectedCourse.id, source: selectedCourse.source } })
        .then((response) => {
          console.log('Course reviews query attempted', response.data);

          if (response.data.success) {
            console.log('course reviews query worked in sub settings')

            const reviews = response.data.reviews.results
            this.setState({ reviews })
          }

        }).catch((error) => {
          console.log('Coures reviews query did not work for some reason', error);
        });
      }

      if (selectedCourse.source === 'Udemy' && !selectedCourse.orgCode) {
        Axios.get('/api/completions', { params: { emailId: email } })
       .then((response) => {
         console.log('Completions query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved completions')

           const completions = response.data.completions

           if (completions && completions.length > 0) {
             Axios.get('/api/completions/detail', { params: { completions, orgCode: org } })
             .then((response2) => {
               console.log('Completions detail query attempted', response2.data);

               if (response2.data.success) {
                 console.log('successfully retrieved completions detail', response2.data.completions)

                 let completedCourseDetails = []

                 for (let i = 1; i <= response.data.completions.length; i++) {
                   if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'course') {
                     const completedCourse = response2.data.completions[i - 1]
                     completedCourse['completionId'] = response.data.completions[i - 1]
                     completedCourseDetails.push(completedCourse)
                   }
                 }

                 this.setState({ completions, completedCourseDetails })

               } else {
                 console.log('no completions detail data found', response2.data.message)
               }

             }).catch((error) => {
                 console.log('Completions detail query did not work', error);
             });
           }

         } else {
           console.log('no completions data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       const resLimit = 50
       Axios.get('/api/get-followers', { params: { _id: selectedCourse._id, resLimit } })
       .then((response) => {
         console.log('Followers query attempted');

         if (response.data.success) {
           console.log('followers query worked in sub settings', response.data)

           const followers = response.data.followers
           this.setState({ followers })
         }

       }).catch((error) => {
         console.log('Followers query did not work for some reason', error);
       });
      } else {
        Axios.get('/api/courses/enrollments', { params: { courseId: selectedCourse._id } })
       .then((response) => {
         console.log('Enrollments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved enrollments')

           let courseEnrollment = null
           let students = []
           let alumni = []
           for (let i = 1; i <= response.data.enrollments.length; i++) {
             if (response.data.enrollments[i - 1].isCompleted) {
               alumni.push(response.data.enrollments[i - 1])
             } else {
               students.push(response.data.enrollments[i - 1])
             }
             if (response.data.enrollments[i - 1].email === email) {
               courseEnrollment = response.data.enrollments[i - 1]
             }
           }

           this.setState({ courseEnrollment, students, alumni })

         } else {
           console.log('no enrollments data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Enrollments query did not work', error);
       });
      }

      if (selectedCourse.jobFunction) {

        const search = true
        const searchString = selectedCourse.jobFunction
        const excludeMissingOutlookData = true
        const excludeMissingJobZone = true
        // console.log('show search string: ', searchString)
        Axios.put('/api/careers/search', { search, searchString, excludeMissingOutlookData, excludeMissingJobZone  })
        .then((response) => {
            console.log('Career details query attempted 2');

            if (response.data.success) {
              console.log('found careers: ', response.data.careers)
              if (response.data.careers && response.data.careers.length > 0) {
                const selectedCareer = response.data.careers[0]
                this.setState({ selectedCareer })
              }

            } else {
              console.log('there was an error from back-end, message:');
            }
        });
      }

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted 2', response.data);

          if (response.data.success) {
            console.log('org info query worked 2')

            const orgName = response.data.orgInfo.orgName
            // const orgContactEmail = response.data.orgInfo.orgContactEmail
            this.setState({ orgName })

            // const orgCode = org
            // let placementPartners = []
            // if (response.data.orgInfo.placementPartners) {
            //   placementPartners = response.data.orgInfo.placementPartners
            // }
            // const postType = null
            // const postTypes = ['Assignment','Problem','Challenge','Internship','Individual','Work']
            // const pathway = null
            // const accountCode = null
            // const recent = true
            // const active = true
            // const pullPartnerPosts = true
            // const csWorkflow = true
            //
            // //only schools see this screen
            // Axios.get('/api/postings/user', { params: { orgCode, placementPartners, postType, postTypes, pathway, accountCode, recent, active, pullPartnerPosts, csWorkflow }})
            // .then((response) => {
            //   console.log('Posted postings query attempted within subcomponent');
            //
            //   if (response.data.success) {
            //     console.log('posted postings query worked')
            //
            //     if (response.data.postings.length !== 0) {
            //
            //       let projectOpportunities = []
            //       let work = []
            //       for (let i = 1; i <= response.data.postings.length; i++) {
            //         if (response.data.postings[i - 1].postType === 'Assignment' || response.data.postings[i - 1].postType === 'Problem' || response.data.postings[i - 1].postType === 'Work') {
            //           projectOpportunities.push(response.data.postings[i - 1])
            //         } else {
            //           work.push(response.data.postings[i - 1])
            //         }
            //       }
            //
            //       this.setState({ projectOpportunities, work })
            //
            //     }
            //
            //   } else {
            //     console.log('posted postings query did not work', response.data.message)
            //   }
            //
            // }).catch((error) => {
            //     console.log('Posted postings query did not work for some reason', error);
            // });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue, selectedCourse, orgCode) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue, selectedCourse, orgCode)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      Axios.get('/api/courses', { params: { orgCode, isActive: true, resLimit: 4 } })
      .then((response) => {
        console.log('Courses query attempted');

          if (response.data.success) {
            console.log('successfully retrieved courses')

            let otherOrgCourses = response.data.courses
            this.setState({ otherOrgCourses, animating: false })

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      const queryOrgCourses = true
      const excludeOrgCourses = false
      // const orgCode = this.state.orgCode
      const filterObject = null
      const queryUdemyCourses = false
      const sources = null

      const courseParams = {
        searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue,
        difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses,
        sources, isActive: true, resLimit: 5
      }

      Axios.get('/api/courses/search', { params: courseParams })
      .then((response) => {
        console.log('Courses query attempted');

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              let relatedCourses = []
              for (let i = 1; i <= response.data.responseData.results.length; i++) {
                if (response.data.responseData.results[i - 1].title !== selectedCourse.title) {
                  relatedCourses.push(response.data.responseData.results[i - 1])

                }
              }
              this.setState({ relatedCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, showShareButtons: false  })
    }

    followPerson(e, person) {
      console.log('followPerson called', e, person)

      // e.stopPropagation()
      // e.preventDefault()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      const recipientHeadline = person.headline
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      let friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName
      }

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          friends.push(friend)
          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });

    }

    async favoriteItem(item, type,e) {
      console.log('favoriteItem called', item, type, this.state.members)

      // e.preventDefault()
      // e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (item && item.source === 'Udemy') {
        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)){

          let index = favoritesArray.indexOf(itemId)
          let courseIndex = 0
          let favoritedCourseDetails = this.state.favoritedCourseDetails
          if (this.state.favoritedCourseDetails && this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)) {
            courseIndex = favoritedCourseDetails.findIndex(x => x.url === item.url);

            if (courseIndex > -1) {
              index = favoritesArray.indexOf(favoritedCourseDetails[courseIndex].favoriteId)
              favoritedCourseDetails.splice(courseIndex, 1);
            }

          }
          console.log('item to remove 1: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
          console.log('item to remove 2: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save removal from favorites')
            if (response.data.success) {
              console.log('saved removal from favorites', response.data)

              this.setState({ successMessage: 'Removed from saved favorites', favorites: favoritesArray, favoritedCourseDetails, isSaving: false })

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
          });

        } else {

          console.log('adding item: ', favoritesArray, itemId)

          if (type === 'course') {
            // first save the course
            const courseObject = {
              source: 'Udemy', title: item.title, headline: item.headline, udemyId: item.id, url: item.url, programURL: item.url,
              image_125_H: item.image_125_H, image_240x135: item.image_240x135, image_480x270: item.image_480x270,
              is_paid: item.is_paid, price: item.price, visible_instructors: item.visible_instructors
            }

            Axios.post('/api/courses', courseObject)
            .then((response) => {
              console.log('attempting to save course')
              if (response.data.success) {
                console.log('saved course as new', response.data)
                //clear values

                itemId = response.data._id

                favoritesArray.push(itemId)
                let favoritedCourseDetails = this.state.favoritedCourseDetails
                favoritedCourseDetails.push(item)

                Axios.post('/api/favorites/save', {
                  favoritesArray, emailId: this.state.emailId
                })
                .then((response) => {
                  console.log('attempting to save addition to favorites')
                  if (response.data.success) {
                    console.log('saved addition to favorites', response.data)

                    this.setState({
                      successMessage: 'Saved as a favorite!', favorites: favoritesArray, favoritedCourseDetails, isSaving: false
                    })

                  } else {
                    console.log('did not save successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('save did not work', error);
                    this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
                });
              } else {
                console.log('did not save successfully')

              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving favorites', isSaving: false
                })
            });
          } else {
            favoritesArray.push(itemId)
            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save addition to favorites')
              if (response.data.success) {
                console.log('saved addition to favorites', response.data)

                this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
            });
          }
        }
      } else {

        if (this.state.showSubFunction) {

          const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"course")

          if (returnedObject.success) {
            this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
          } else {
            this.setState({ errorMessage: returnedObject.message, isSaving: false })
          }

        } else {
          let itemId = item._id

          let favoritesArray = this.state.favorites
          if (favoritesArray.includes(itemId)) {
            const index = favoritesArray.indexOf(itemId)
            favoritesArray.splice(index, 1)
          } else {
            favoritesArray.push(itemId)
          }


          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save addition to favorites')
            if (response.data.success) {
              console.log('saved addition to favorites', response.data)

              this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
          });
        }
      }
    }

    enrollInCourse(item, type,e,isCompleted) {
      console.log('enrollInCourse called', item, type)

      // e.preventDefault()
      // e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let _id = null
      // let eIndex = null
      if (this.state.courseEnrollment) {
        _id = this.state.courseEnrollment._id
      }
      console.log('t0: ', _id, isCompleted)

      if ((isCompleted) || (!_id && !isCompleted)) {
        console.log('t1: we in')

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const courseId = item._id
        const courseImageURL = item.imageURL
        let courseName = item.name
        if (item.title) {
          courseName = item.title
        }
        const courseCategory = item.category
        const courseDegreeType = item.degreeType
        const courseSchoolName = item.schoolName
        const courseSchoolURL = item.schoolURL
        const courseDescription = item.description
        const courseEstimatedHours = item.estimatedHours
        const courseProgramMethod = item.programMethod
        const courseDifficultyLevel = item.difficultyLevel

        const orgCode = this.state.orgCode
        const orgName = this.state.orgName

        const createdAt = new Date()
        const updatedAt = new Date()

        const courseEnrollment = {
          _id, firstName, lastName, email, username, pictureURL,
          courseId, courseImageURL, courseName, courseCategory, courseDegreeType,
          courseSchoolName, courseSchoolURL, courseDescription, courseEstimatedHours, courseProgramMethod, courseDifficultyLevel,
          isCompleted, orgCode, orgName, createdAt, updatedAt
        }

        Axios.post('/api/courses/enrollments', courseEnrollment)
        .then((response) => {
          console.log('attempting to save addition to enrollments')
          if (response.data.success) {
            console.log('saved addition to enrollments', response.data)

            // let itemId = item._id

            // let courseEnrollment = this.state.courseEnrollment

            if (isCompleted) {
              // courseEnrollment['isCompleted'] = true

              if (item.degreeType === 'Badge' || item.degreeType === 'Certificate' || item.degreeType === 'Certification') {
                // save as certificate

                const certificate = {
                  courseId: item._id, name: item.name, imageURL: item.imageURL, category: item.category,
                  programURL: item.programURL, schoolURL: item.schoolURL, schoolName: item.schoolName,
                  degreeType: item.degreeType, programMethod: item.programMethod, location: item.location,
                  estimatedHours: item.estimatedHours, description: item.description, gradeLevel: item.gradeLevel,
                  knowledgeLevel: item.knowledgeLevel, functions: item.functions, pathways: item.pathways,
                  price: item.price, isCompleted: true, updateCertificate: true,
                  email: this.state.emailId, createdAt: new Date(), updatedAt: new Date()
                }

                Axios.post('/api/certificates', certificate)
                .then((response) => {
                  console.log('attempting to post certificate')

                  if (response.data.success) {
                    console.log('saved post to certificate', response.data)


                  } else {
                    console.log('did not save certificate successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('saving certificate did not work', error);
                    this.setState({ errorMessage: 'there was an error saving enrollment certificate', isSaving: false})
                });

              }
            } else {
              // do nothing
            }

            this.setState({ successMessage: 'Saved enrollment!', courseEnrollment, isSaving: false })

          } else {
            console.log('did not save enrollment successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving enrollment', isSaving: false})
        });
      } else {
        this.setState({ isSaving: false, errorMessage: 'You have already enrolled in this course/program'})
      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={this.state.shareURL} subject={this.state.shareTitle} body={this.state.shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={this.state.shareURL} title={this.state.shareTitle} summary={(this.state.shareBody) ? this.state.shareBody : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={this.state.shareURL} description={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    markCompleted(item, type) {
      console.log('markCompleted called', item, type)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (type) {
        let itemId = item._id

        let completions = this.state.completions

        if (completions.includes(itemId) || this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)){

          let index = completions.indexOf(itemId)
          let courseIndex = 0
          let completedCourseDetails = this.state.completedCourseDetails
          if (this.state.completedCourseDetails && this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url === item.url)) {
            courseIndex = completedCourseDetails.findIndex(x => x.url === item.url);

            if (courseIndex > -1) {
              index = completions.indexOf(completedCourseDetails[courseIndex].completionId)
              completedCourseDetails.splice(courseIndex, 1);
            }

          }
          // console.log('item to remove 1: ', completions, favoritesArray.length, favoritedCourseDetails.length)
          if (index > -1) {
            completions.splice(index, 1);
          }
          // console.log('item to remove 2: ', favoritesArray, favoritesArray.length, favoritedCourseDetails.length)

          Axios.post('/api/completions/save', {
            completions, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save removal from completions')
            if (response.data.success) {
              console.log('saved removal from completions', response.data)

              this.setState({ successMessage: 'Removed from saved completions', completions, completedCourseDetails, isSaving: false })

            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
          });

        } else {

          // console.log('adding item: ', favoritesArray, itemId)

          if (type === 'course') {
            // first save the course
            const courseObject = {
              source: 'Udemy', title: item.title, headline: item.headline, udemyId: item.id, url: item.url,
              image_125_H: item.image_125_H, image_240x135: item.image_240x135, image_480x270: item.image_480x270,
              is_paid: item.is_paid, price: item.price, visible_instructors: item.visible_instructors
            }

            Axios.post('/api/courses', courseObject)
            .then((response) => {
              console.log('attempting to save course')
              if (response.data.success) {
                console.log('saved course as new', response.data)
                //clear values

                itemId = response.data._id

                completions.push(itemId)
                let completedCourseDetails = this.state.completedCourseDetails
                completedCourseDetails.push(item)

                Axios.post('/api/completions/save', {
                  completions, emailId: this.state.emailId
                })
                .then((response) => {
                  console.log('attempting to save addition to completions')
                  if (response.data.success) {
                    console.log('saved addition to completions', response.data)

                    this.setState({
                      successMessage: 'Saved as a completion!', completions, completedCourseDetails, isSaving: false
                    })

                  } else {
                    console.log('did not save successfully')
                    this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('save did not work', error);
                    this.setState({ errorMessage: 'there was an error saving completions', isSaving: false})
                });
              } else {
                console.log('did not save successfully')

              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({
                  serverSuccessPlan: false,
                  serverErrorMessagePlan: 'there was an error saving completions', isSaving: false
                })
            });
          } else {
            completions.push(itemId)
            Axios.post('/api/completions/save', {
              completions, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save addition to completions')
              if (response.data.success) {
                console.log('saved addition to completions', response.data)

                this.setState({ successMessage: 'Saved as a completion!', completions, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving completions', isSaving: false})
            });
          }
        }
      }
    }

    configureLink() {
      console.log('configureLink called')

      let returnedLink = '/'
      if (!this.state.emailId) {
        if (this.state.passedOrgCode) {
          returnedLink = '/organizations/' + this.state.passedOrgCode + '/career-seeker/join'
        } else {
          returnedLink = '/join'
        }
      } else if (this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker') {
        returnedLink = '/app/courses/' + this.state.selectedCourse._id + '/take'
      } else if (this.state.roleName === 'Admin' || this.state.roleName === 'WBLC' || this.state.roleName === 'Work-Based Learning Coordinator') {
        returnedLink = '/organizations/' + this.state.orgCode + '/courses/' + this.state.selectedCourse._id + '/take'
      } else if (this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'School Support') {
        returnedLink = '/advisor/courses/' + this.state.selectedCourse._id + '/take'
      }

      return returnedLink
    }

    calculateDeadline() {
      console.log('calculateDeadline called')

      let nextMonth = new Date()
      nextMonth.setDate(nextMonth.getDate() + 31);

      const year = nextMonth.getFullYear()
      const month = nextMonth.getMonth()
      const day = nextMonth.getDate()

      // const dDate = new Date(year, month, day, 0, 0, 0, 0)
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      const deadline = monthNames[month] + ' 1, ' + year
      // console.log('-----d', deadline)
      return deadline
    }

    render() {

      return (
          <div>
            {(this.state.selectedCourse) && (
              <div>
                {(this.state.isLoading) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Loading...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={(window.innerWidth > 768 && !this.props.inModal) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                      <div>
                        <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                          <div className="full-width height-5 primary-background" />
                          <div className="padding-40">
                            <div className="row-10">
                              <div className="fixed-column-90">
                                <img src={(this.state.selectedCourse.imageURL) ? this.state.selectedCourse.imageURL : (this.state.selectedCourse.image_480x270) ? this.state.selectedCourse.image_480x270 : courseIconBlue} alt="GC" className="image-80-fit center-horizontally" />
                                {(this.state.selectedCourse.price || this.state.selectedCourse.price === 0) && (
                                  <p className="full-width center-text cta-color bold-text heading-text-6 top-margin">{(this.state.selectedCourse.price.startsWith("$")) ? this.state.selectedCourse.price : (this.state.selectedCourse.price === '0' || this.state.selectedCourse.price === 0) ? 'Free' : '$' + this.state.selectedCourse.price}</p>
                                )}

                                {(this.state.selectedCourse.source === 'Udemy') && (
                                  <div className="top-margin-20">
                                    <a href={(this.state.selectedCourse.programURL) ? this.state.selectedCourse.programURL : this.state.selectedCourse.url} target="_blank" rel="noopener noreferrer" className="background-link">
                                      <img src={udemyLogo} alt="GC" className="image-30-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This course is from Udemy" />
                                    </a>
                                  </div>
                                )}

                                {(this.state.selectedCourse.source === 'Coursera') && (
                                  <div className="top-margin-20">
                                    <a href={this.state.selectedCourse.programURL} target="_blank" rel="noopener noreferrer" className="background-link">
                                      <img src={courseraLogo} alt="GC" className="image-30-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This course is from Coursera" />
                                    </a>
                                  </div>
                                )}

                                {(this.state.selectedCourse.source === 'Udacity') && (
                                  <div className="top-margin-20">
                                    <a href={this.state.selectedCourse.programURL} target="_blank" rel="noopener noreferrer" className="background-link">
                                      <img src={udacityLogo} alt="GC" className="image-30-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This course is from Udacity" />
                                    </a>
                                  </div>
                                )}

                              </div>
                              <div className="calc-column-offset-140 left-padding top-padding-5">
                                <p className="heading-text-2">{(this.state.selectedCourse.name) ? this.state.selectedCourse.name : this.state.selectedCourse.title}</p>
                                {(this.state.selectedCourse.schoolName && this.state.selectedCourse.schoolURL) && (
                                  <p className="top-padding-5 description-text-2">{(this.state.selectedCourse.estimatedHours) && this.state.selectedCourse.estimatedHours + ' Hour '}{(this.state.selectedCourse.category) && this.state.selectedCourse.category + ' '} by <a href={this.state.selectedCourse.schoolURL} target="_blank" rel="noopener noreferrer">{this.state.selectedCourse.schoolName}</a></p>
                                )}

                                <p className="top-padding-20">{this.state.selectedCourse.description}</p>
                                {(this.state.selectedCourse.promoText) && (
                                  <div>
                                    {(this.state.selectedCourse.promoText === 'This course is free until August 31, 2023' && new Date() > new Date("08/31/2023")) ? (
                                      <p className="full-width cta-color bold-text description-text-3 top-margin-15">This course is free until {this.calculateDeadline()}</p>
                                    ) : (
                                      <p className="full-width cta-color bold-text description-text-3 top-margin-15">{this.state.selectedCourse.promoText}</p>
                                    )}
                                  </div>
                                )}
                                {(this.state.selectedCourse.source === 'Udemy' && this.state.selectedCourse.visible_instructors && this.state.selectedCourse.visible_instructors.length > 0) && (
                                  <div className="top-padding-15 bottom-padding-5">
                                    <label className="description-text-2 right-padding-5">Created by</label>
                                    {this.state.selectedCourse.visible_instructors.map((value2, index2) =>
                                      <span>
                                        <a href={"https://www.udemy.com" + value2.url} className="background-button" target="_blank" rel="noopener noreferrer">
                                          {/*<img src={(value2.image_100x100) ? value2.image_100x100 : profileIconDark} alt="GC" className="profile-thumbnail-20 float-left" />*/}
                                          <label className="description-text-2 cta-color">{value2.display_name}</label>
                                          <label className="description-text-2">, {value2.job_title}</label>
                                          <div className="clear" />
                                        </a>
                                      </span>
                                    )}
                                  </div>
                                )}

                                <div className="top-padding-20">
                                  {(this.state.selectedCourse.learningObjectives && this.state.selectedCourse.learningObjectives.length > 0) ? (
                                    <span className="right-margin">
                                      <Link className="background-button top-margin" to={this.configureLink()} state={{ courseId: this.state.selectedCourse._id }}>
                                        <button className="btn btn-squarish">
                                          <div className="float-left">
                                            <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                            <img src={courseIconWhite} alt="GC" className="image-auto-15" />
                                          </div>
                                          <div className="float-left left-padding">Take Course</div>
                                          <div className="clear" />
                                        </button>
                                      </Link>
                                    </span>
                                  ) : (
                                    <span className="right-margin">
                                      {(this.state.selectedCourse.programURL || this.state.selectedCourse.url) && (
                                        <span>
                                          {(this.state.selectedCourse.source === 'Udemy' || this.state.selectedCourse.programURL) ? (
                                            <a className="background-button top-margin" href={(this.state.selectedCourse.programURL) ? this.state.selectedCourse.programURL : "https://www.udemy.com" + this.state.selectedCourse.url} target="_blank" rel="noopener noreferrer">
                                              <button className="btn btn-squarish">
                                                <div className="float-left">
                                                  <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                                  <img src={linkIconWhite} alt="GC" className="image-auto-15" />
                                                </div>
                                                <div className="float-left left-padding">{(this.state.selectedCourse.source === "Udemy" && !this.state.selectedCourse.orgCode) ? "Take the Course" : "View Details"}</div>
                                                <div className="clear" />
                                              </button>
                                            </a>
                                          ) : (
                                            <a className="background-button top-margin" href={"https://www.udemy.com" + this.state.selectedCourse.url} target="_blank" rel="noopener noreferrer">
                                              <button className="btn btn-squarish">
                                                <div className="float-left">
                                                  <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                                  <img src={linkIconWhite} alt="GC" className="image-auto-15" />
                                                </div>
                                                <div className="float-left left-padding">{"View Details"}</div>
                                                <div className="clear" />
                                              </button>
                                            </a>
                                          )}
                                        </span>
                                      )}
                                    </span>
                                  )}

                                  {(window.location.pathname.includes('/app/courses')) && (
                                    <span>
                                      {(this.state.selectedCourse.programURL || this.state.selectedCourse.url) && (
                                        <button className="btn btn-squarish quaternary-background right-margin top-margin" disabled={(!this.state.selectedCourse.source && this.state.courseEnrollment && this.state.courseEnrollment.courseId === this.state.selectedCourse._id) ? true : false} onClick={(this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) ? () => this.favoriteItem(this.state.selectedCourse,'course') : (e) => this.enrollInCourse(this.state.selectedCourse,'course',e,false)}>
                                          {(this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) ? (
                                            <div>
                                              <div className="float-left">
                                                <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                                {(this.state.favorites.includes(this.state.selectedCourse._id) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === this.state.selectedCourse.url)) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={favoritesIconWhite} alt="GC" className="image-auto-15" />}
                                              </div>
                                              <div className="float-left left-padding">
                                                {(this.state.favorites.includes(this.state.selectedCourse._id) || this.state.favoritedCourseDetails.some(selectedCourse => selectedCourse.url === this.state.selectedCourse.url)) ? "Favorited" : "Favorite"}
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          ) : (
                                            <div>
                                              <div className="float-left">
                                                <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                                {(this.state.courseEnrollment && this.state.courseEnrollment.courseId === this.state.selectedCourse._id) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={addLessonIconWhite} alt="GC" className="image-auto-15" />}
                                              </div>
                                              <div className="float-left left-padding">
                                                <p>{(this.state.courseEnrollment && this.state.courseEnrollment.courseId === this.state.selectedCourse._id) ? "Enrolled!" : "Mark as Enrolled"}</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          )}
                                        </button>
                                      )}

                                      <button className="btn btn-squarish quinary-background clear-border right-margin top-margin" disabled={(!this.state.selectedCourse.source && this.state.courseEnrollment && this.state.courseEnrollment.courseId === this.state.selectedCourse._id && this.state.courseEnrollment.isCompleted) ? true : false} onClick={(this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) ? () => this.markCompleted(this.state.selectedCourse,'course') : (e) => this.enrollInCourse(this.state.selectedCourse,'course',e,true)}>
                                        <div className="float-left">
                                          <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                          <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" />
                                        </div>
                                        {(this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) ? (
                                          <div className="float-left left-padding">
                                            {(this.state.completions.includes(this.state.selectedCourse._id) || this.state.completedCourseDetails.some(selectedCourse => selectedCourse.url === this.state.selectedCourse.url)) ? "Completed!" : "Mark Completed"}
                                          </div>
                                        ) : (
                                          <div className="float-left left-padding">
                                            <p>{(this.state.courseEnrollment && this.state.courseEnrollment.courseId === this.state.selectedCourse._id && this.state.courseEnrollment.isCompleted) ? "Completed!" : "Mark Completed"}</p>
                                          </div>
                                        )}
                                        <div className="clear" />
                                      </button>
                                    </span>
                                  )}

                                  <button className={(window.location.pathname.includes('/app/benchmarks') || window.location.pathname.includes('/advisor/benchmarks')) ? "btn btn-squarish white-background cta-color left-margin-5 top-margin" : "btn btn-squarish white-background cta-color top-margin"} onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true })}>
                                    <div className="float-left">
                                      <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                      <img src={shareIconDark} alt="GC" className="image-auto-15" />
                                    </div>
                                    <div className="float-left left-padding">Share</div>
                                    <div className="clear" />
                                  </button>
                                </div>
                              </div>
                              <div className="clear" />
                            </div>
                            <ReactTooltip id="tooltip-placeholder-id" />
                          </div>
                        </div>

                        <div>
                          {(window.location.pathname !== '/courses/' + this.state.selectedCourse._id) && (
                            <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 medium-shadow top-margin-2-percent pin-right" : "medium-shadow slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40 "}>
                              <div className="carousel-3 white-background" onScroll={this.handleScroll}>
                                {this.state.subNavCategories.map((value, index) =>
                                  <div className="carousel-item-container">
                                    {(value === this.state.subNavSelected) ? (
                                      <div className="selected-carousel-item">
                                        <label key={value}>{value}</label>
                                      </div>
                                    ) : (
                                      <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                        <label key={value}>{value}</label>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Objectives') && (this.state.selectedCourse.learningObjectives && this.state.selectedCourse.learningObjectives.length > 0)) && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div>
                                <p className="heading-text-3">Learning Objectives</p>
                                <div className="spacer" /><div className="spacer" /><div className="half-spacer" />

                                {this.state.selectedCourse.learningObjectives.map((value, index) =>
                                  <div key={index}>
                                    <p>{index + 1}. {value.name}</p>
                                  </div>
                                )}

                                <div className="clear" />

                              </div>

                            </div>
                          )}

                          {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Modules') && (this.state.selectedCourse.learningModules && this.state.selectedCourse.learningModules.length > 0)) && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div>
                                <p className="heading-text-3">Learning Modules</p>
                                <div className="spacer" /><div className="spacer" /><div className="half-spacer" />

                                {this.state.selectedCourse.learningModules.map((value, index) =>
                                  <div key={"learningModule" + index}>
                                    <div className="standard-border padding-20 top-margin-20">
                                      <p>{index + 1}. {value.name}</p>
                                    </div>
                                    {(value.lessons && value.lessons.length > 0) && (
                                      <div>
                                        {value.lessons.map((value2, index2) =>
                                          <div key={"learningModules|" + index + "|lesson|" + index2}>
                                            <div className="standard-border padding-20">
                                              <div className="calc-column-offset-130">
                                                <p className="left-padding-20 description-text-2">{index + 1}.{index2 + 1}. {value2.name}</p>
                                              </div>
                                              {(value2.content && value2.content[0]) && (
                                                <div className="fixed-column-130">
                                                  <p className="left-padding-20 description-text-2 right-text">[ {value2.content[0].category} ]</p>
                                                </div>
                                              )}
                                              <div className="clear" />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}

                                <div className="clear" />

                              </div>

                            </div>
                          )}

                          {((this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) && (this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Reviews')) && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <p className="heading-text-3">Reviews</p>
                                <p className="description-text-1 description-text-color top-padding">Reviews of {this.state.selectedCourse.title}</p>
                                <div className="spacer" />

                                {this.state.reviews && this.state.reviews.map((value, index) =>
                                  <div>
                                    <div key={index}>
                                      <div className="spacer" />
                                      <div className="fixed-column-70">
                                        <p className="heading-text-3 cta-color full-width center-text">{value.rating}</p>
                                        <p className="description-text-2 description-text-color full-width center-text">/ 5.0</p>
                                      </div>
                                      <div className="calc-column-offset-70">
                                        <p className="heading-text-5">{value.user.display_name}</p>
                                        {(value.content) && (
                                          <p className="description-text-1 description-text-color top-padding-5">{value.content}</p>
                                        )}
                                        {(value.created) && (
                                          <p className="description-text-1 description-text-color top-padding-5">{convertDateToString(value.created,'daysAgo')}</p>
                                        )}

                                        <div className="half-spacer" />


                                        <div className="clear" />
                                        <div className="half-spacer" />
                                      </div>

                                      <div className="clear"/>

                                      <div className="spacer" /><div className="spacer" />
                                      <hr className="clear-margin clear-padding" />
                                      <div className="clear"/>
                                      <div className="spacer" />
                                    </div>

                                  </div>
                                )}
                              </div>

                            </div>
                          )}

                          {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'About') && (!this.state.selectedCourse.learningModules || this.state.selectedCourse.learningModules.length < 1)) && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div>
                                <p className="heading-text-3">About</p>
                                <div className="spacer" /><div className="spacer" /><div className="half-spacer" />

                                <div className="description-text-1">
                                  {(this.state.selectedCourse.headline) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Headline</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.headline}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.source) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Source</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.source}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.category) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Category</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.category}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.degreeType) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Degree Type</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.degreeType}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.estimatedHours) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Estimated Hours</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.estimatedHours}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.gradeLevel) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Grade Level</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.gradeLevel}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.knowledgeLevel) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Knowledge Level</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.knowledgeLevel}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.programMethod) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Program Delivery</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.programMethod}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.location) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Location</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.programMethod}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.mainContactFirstName && this.state.selectedCourse.mainContactLastName) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Main Contact</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.mainContactFirstName} {this.state.selectedCourse.mainContactLastName} {(this.state.selectedCourse.mainContactEmail) && "(" + this.state.selectedCourse.mainContactEmail + ")"}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.industry) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Industry</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.industry}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.functions && this.state.selectedCourse.functions.length > 0) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Work Functions</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.functions.join(', ')}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.selectedCourse.pathways && this.state.selectedCourse.pathways.length > 0) && (
                                    <div className="bottom-margin-20 container-left">
                                      <div className="fixed-column-140">
                                        <p className="description-text-color">Pathways</p>
                                      </div>
                                      <div className="calc-column-offset-140">
                                        <p>{this.state.selectedCourse.pathways.join(', ')}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>

                                <div className="clear" />
                                {/*
                                    // functions, industry, pathways
                                  // category, degreeType, estimatedHours, gradeLevel, knowledgeLevel, programMethod, schoolName, schoolURL
                                  // functions, industry, location, pathways, outcomes, mainContactFirstName,
                                */}

                              </div>

                            </div>
                          )}

                          {(window.location.pathname !== '/courses/' + this.state.selectedCourse._id) && (
                            <div>
                              {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Comments') && (
                                <div className={(window.innerWidth > 768) ? "card slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                                  <SubComments selectedCourse={this.state.selectedCourse} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments}  orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} pageSource={this.state.pageSource}/>
                                  <div className="clear" />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Students') && (
                                <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                                  <SubRenderProfiles
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    members={(this.state.selectedCourse && this.state.selectedCourse.source === 'Udemy' && !this.state.selectedCourse.orgCode) ? this.state.followers : this.state.students} friends={this.state.friends}
                                    pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Alumni') && (
                                <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                                  <SubRenderProfiles
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    members={this.state.alumni} friends={this.state.friends}
                                    pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        </div>
                      </div>
                    </div>

                    {(window.innerWidth > 768 && !this.props.inModal) && (
                      <div className="relative-column-30 horizontal-padding top-margin-40">
                        <div className="left-padding-20">
                          {(this.state.otherOrgCourses && this.state.otherOrgCourses.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                              <div className="full-width height-5 senary-background" />
                              <div className="padding-20">
                                <p className="heading-text-5">Other {this.state.orgName} Courses</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.otherOrgCourses.map((item, index) =>
                                  <div key={item}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={(window.location.pathname.includes('/app/')) ? '/app/courses/' + item._id : '/courses/' + item._id} state={{ selectedCourse: item }} className="background-link">
                                          <div className="fixed-column-50">
                                            <img src={(item.imageURL) ? item.imageURL : courseIconBlue} alt="Compass arrow indicator icon" className="image-50-fit center-item"/>
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p>{item.name}</p>
                                            <p className="description-text-2 standard-color height-70 limited-description" >{item.description}</p>
                                          </div>
                                        </Link>
                                        <div className="float-left left-padding">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <Link to={(window.location.pathname.includes('/app/')) ? '/app/courses/' + item._id : '/courses/' + item._id} state={{ selectedCourse: item }} className="background-link">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                            </Link>
                                          </span>
                                          {/*
                                          {(this.state.path && this.state.path.includes('/app')) && (
                                            <span className="float-right right-padding-15">
                                              <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                                <img src={(this.state.favorites.includes(this.state.item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                              </button>
                                            </span>
                                          )}*/}
                                        </div>
                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.otherOrgCourses.length > 0) && (
                                  <div>
                                    <Link className="background-button full-width right-text" to={(window.location.pathname.includes('/app/')) ? '/app/courses' : '/courses'}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {(this.state.relatedCourses && this.state.relatedCourses.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                              <div className="full-width height-5 senary-background" />
                              <div className="padding-20">
                                <p className="heading-text-5">Related Courses</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.relatedCourses.map((item, index) =>
                                  <div key={item}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={(window.location.pathname.includes('/app/')) ? '/app/courses/' + item.id : '/courses/' + item.id} state={{ selectedCourse: item }} className="background-link">
                                          <div className="fixed-column-50">
                                            <img src={(item.image_480x270) ? item.image_480x270 : courseIconBlue} alt="Compass arrow indicator icon" className="image-50-fit center-item"/>
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p>{item.title}</p>
                                            <p className="description-text-2 standard-color">{item.headline}</p>
                                          </div>
                                        </Link>
                                        <div className="float-left left-padding">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <Link to={(window.location.pathname.includes('/app/')) ? '/app/courses/' + item._id : '/courses/' + item._id} state={{ selectedCourse: item }} className="background-link">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                            </Link>
                                          </span>
                                        </div>
                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.relatedCourses.length > 0) && (
                                  <div>
                                    <Link className="background-button full-width right-text" to={(window.location.pathname.includes('/app/')) ? '/app/courses' : '/courses'}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="clear" />

                  </div>
                )}

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >

                 {(this.state.showShareButtons) && (
                   <div key="showDescription" className="full-width padding-20 center-text">
                      <p className="heading-text-2">Share {this.state.selectedCourse.title} with Friends!</p>

                      <div className="top-padding-20">
                        <p>Share this link:</p>
                        <a href={this.state.shareURL} target="_blank">{this.state.shareURL}</a>
                      </div>

                      <div className="spacer" />

                      <div className="top-padding-20">
                        {this.renderShareButtons()}
                      </div>

                    </div>
                 )}

               </Modal>

              </div>
            )}
          </div>

      )
    }
}

export default withRouter(CourseDetails);
