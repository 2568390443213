import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const checkmarkDarkGreyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-dark-grey.png";
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class MyIdeas extends Component {
    constructor(props) {
        super(props)

        this.state = {
          subNavCategories: ['All','My Purchases','My Creations'],
          subNavSelected: 'All',
          myCreations: [],
          myPurchases: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in submyideas')

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      this.setState({ emailId, cuFirstName, cuLastName, username, activeOrg, orgFocus });

      Axios.get('/api/business-ideas', { params: { email: emailId } })
      .then((response) => {

          if (response.data.success) {
            console.log('Business ideas query worked', response.data);

            const myCreations = response.data.businessIdeas
            const businessIdeas = myCreations
            this.setState({ myCreations, businessIdeas })

          } else {
            console.log('no business ideas found', response.data.message)
          }

      }).catch((error) => {
          console.log('Business ideas query did not work', error);
      });

      Axios.get('/api/business-ideas-purchased', { params: { emailId }})
      .then((response) => {
        console.log('Business ideas purchased query attempted', response.data);

        if (response.data.success) {
          console.log('business ideas purchased query worked')

          // const businessIdea = response.data.businessIdea
          const myPurchases = response.data.purchasedIdeas
          this.setState({ myPurchases })

        } else {
          console.log('business ideas purchased query did not work', response.data.message)
        }
      }).catch((error) => {
        console.log('Business ideas purchased query did not work for some reason', error);
      });
    }

    renderItems() {
      console.log('renderItems called', this.state.myCreations, this.state.myPurchases)

      let ideas = []
      if (this.state.subNavSelected === 'All') {
        ideas = this.state.myCreations.concat(this.state.myPurchases)
      } else if (this.state.subNavSelected === 'My Purchases') {
        ideas = this.state.myPurchases
      } else if (this.state.subNavSelected === 'My Creations') {
        ideas = this.state.myCreations
      }
      console.log('show ideas: ', ideas)
      let rows = []
      if (ideas && ideas.length > 0 ) {

        for (let i = 1; i <= ideas.length; i++) {
          console.log(i);

          const value = ideas[i - 1]
          const index = i - 1
          // console.log('show pageSource: ', this.props.pageSource)

          let url = "/app/edit-business-ideas/" + value._id
          if (value.productId) {
            url = "/app/business-ideas/" + value.productId
          }

          let name = value.name
          if (value.productName) {
            name = value.productName
          }
          let pictureURL = value.pictureURL
          if (value.creatorPictureURL) {
            pictureURL = value.creatorPictureURL
          }
          let firstName = value.firstName
          if (value.creatorFirstName) {
            firstName = value.creatorFirstName
          }
          let lastName = value.lastName
          if (value.creatorLastName) {
            lastName = value.creatorLastName
          }

          rows.push(
            <div key={index}>
              <div className="spacer" />

              <Link to={url} className="background-button standard-color full-width">
                <div className="fixed-column-60">
                  <img src={(pictureURL) ? pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-2" />
                </div>
                <div className="calc-column-offset-150">
                  <p className="full-width bold-text">{name}</p>
                  <p className="full-width description-text-3 top-padding-5">by {firstName} {lastName}</p>
                  <p className="full-width description-text-4 top-padding-5 curtail-text">{value.teaser}</p>
                </div>
                <div className="fixed-column-60">
                  <p className="full-width heading-text-5 cta-color">${value.price}</p>
                </div>
                <div className="fixed-column-30">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 top-margin-3" />
                </div>
                <div className="clear" />


              </Link>

            </div>
          )
        }
      } else {

        rows.push(
          <div key={1} className="flex-container flex-center center-text padding-30">
            <div className="padding-5-percent" >
              <img src={checkmarkDarkGreyIcon} alt="Compass large endorsements icon" className="empty-state-icon"/>
              <h3 className="empty-state-title"><strong>No Business Ideas Yet</strong></h3>
              <p className="empty-state-text full-width">This section contains all business ideas you've purchase or created.</p>
            </div>
          </div>
        )
      }

      return rows;
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      this.setState({ subNavSelected: pageName })
    }

    render() {

        return (
            <div>
              <div className="row-20">
                <p className="heading-text-2">My Ideas</p>
              </div>

              {(this.state.businessIdeas && this.state.businessIdeas.length > 0) && (
                <div>
                  <div className="carousel-3 light-background" onScroll={this.handleScroll}>
                    {this.state.subNavCategories.map((value, index) =>
                      <div className="carousel-item-container">
                        {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                          <div className="selected-carousel-item">
                            <label key={value}>{value}</label>
                          </div>
                        ) : (
                          <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                            <label key={value}>{value}</label>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="spacer"/>
              {this.renderItems()}

              <div className="clear" />
            </div>

        )
    }
}

export default withRouter(MyIdeas);
