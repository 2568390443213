import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubProjectDetails from './Subcomponents/ProjectDetails';
import withRouter from './Functions/WithRouter';

class ProjectDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (this.props.params) {
        let { projectId } = this.props.params


        Axios.get('/api/projects/byid', { params: { _id: projectId } })
        .then((response) => {
            console.log('Project query worked', response.data);

            if (response.data.success) {

              const selectedProject = response.data.project
              this.setState({ selectedProject, activeOrg, orgLogo })
            } else {
              console.log('no endorsements found: ', response.data.message)
            }

        }).catch((error) => {
            console.log('Story query did not work', error);
        });
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
            {(this.state.selectedProject) && (
              <SubProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.selectedProject} orgCode={this.state.activeOrg} excludeModal={true} />
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(ProjectDetails)
