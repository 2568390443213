import React, {Component} from 'react';
import SubSideNav from '../Common/SideNav';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubReferrals from '../Subcomponents/Referrals';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class OrgReferrals extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ cuFirstName, cuLastName, username, emailId: email, org, orgFocus, orgLogo })

      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

          {(!toggleVars.hideAdminSideNav) && (
            <SubSideNav selectedComponent={"OrgReferrals"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
          )}

          <div className={(toggleVars.hideAdminSideNav) ? "center-horizontally width-70-percent max-width-1400 top-margin-2-percent" : "floating-container top-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
            <SubReferrals email={this.state.emailId} activeOrg={this.state.org} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} history={this.props.navigate} />
          </div>
          <div className="clear"/>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(OrgReferrals)
