import React, {Component} from 'react';

import Axios from 'axios';

import {convertDateToString} from '../Functions/ConvertDateToString';

const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class EditTicket extends Component {
    constructor(props) {
      super(props)
      this.state = {
        urgencyOptions: ['','Low','Medium','High'],
        stageOptions: ['','Backlog','To Do','In Progress','In Testing','Done'],
        staffMemberOptions: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveTicket = this.saveTicket.bind(this)
      this.deleteTicket = this.deleteTicket.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEditTicket', this.props, prevProps)

      if (this.props.selectedTicket !== prevProps.selectedTicket) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within subEditSubscription')

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      const activeOrg = localStorage.getItem('activeOrg');
      // const accountCode = localStorage.getItem('accountCode');

      const selectedTicket = this.props.selectedTicket

      let _id = null
      let name = null
      let urgency = null
      let category = null
      let description = null
      let createdAt = null
      let firstName = null
      let lastName = null
      let email = null
      let username = null
      let pictureURL = null
      // let roleName = null
      let dueDate = null
      let stage = null
      let assignedStaff = null

      if (selectedTicket) {
        _id = selectedTicket._id
        name = selectedTicket.name
        urgency = selectedTicket.urgency
        category = selectedTicket.category
        description = selectedTicket.description
        createdAt = selectedTicket.createdAt
        firstName = selectedTicket.firstName
        lastName = selectedTicket.lastName
        email = selectedTicket.email
        username = selectedTicket.username
        pictureURL = selectedTicket.pictureURL
        roleName = selectedTicket.roleName

        if (selectedTicket.dueDate) {
          // dueDate = convertDateToString(new Date(selectedTicket.dueDate), "rawDateTimeForInput")
          dueDate = convertDateToString(selectedTicket.dueDate,"first10")
        }
        console.log('show the dueDate: ', dueDate)

        stage = selectedTicket.stage
        assignedStaff = selectedTicket.assignedStaff
      }

      this.setState({
        emailId, cuFirstName, cuLastName, orgFocus, activeOrg, selectedTicket,
        _id, name, urgency, category, description, createdAt, firstName, lastName, email, username,
        pictureURL, roleName, dueDate, stage, assignedStaff
      })

      const roleNames = ['Admin']
      Axios.get('/api/org/members', { params: { orgCode: activeOrg, roleNames } })
      .then((response) => {
        if (response.data.success) {
          console.log('Member query worked', response.data);

          if (response.data.members && response.data.members.length > 0) {

            let staffMemberOptions = response.data.members
            if (staffMemberOptions) {
              staffMemberOptions.unshift({})
              this.setState({ staffMemberOptions })
            }
          }

        } else {
          console.log('no members found', response.data.message)
        }

      }).catch((error) => {
          console.log('Members query did not work', error);
      });

      Axios.get('/api/org', { params: { orgCode: activeOrg }})
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let ticketCategories = []
            if (response.data.orgInfo.ticketCategories && response.data.orgInfo.ticketCategories.length > 0) {
              ticketCategories = [{ name: '' }].concat(response.data.orgInfo.ticketCategories)
            }
            this.setState({ ticketCategories });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'staffMember') {
        let assignedStaff = this.state.assignedStaff
        for (let i = 1; i <= this.state.staffMemberOptions.length; i++) {
          const fullName = this.state.staffMemberOptions[i - 1].firstName + ' ' + this.state.staffMemberOptions[i - 1].lastName
          if (fullName === event.target.value) {
            assignedStaff = this.state.staffMemberOptions[i - 1]
          }
        }
        this.setState({ assignedStaff })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    saveTicket() {
      console.log('saveTicket called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.name || this.state.name === '') {
        this.setState({ isSaving: false, errorMessage: 'Please title your issue' })
      } else if (!this.state.urgency || this.state.urgency === '') {
        this.setState({ isSaving: false, errorMessage: 'Please share how urgent your issue is' })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ isSaving: false, errorMessage: 'Please share a description of your issue' })
      } else {

        const _id = this.state._id
        let name = this.state.name
        let urgency = this.state.urgency
        let category = this.state.category
        let description = this.state.description

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        let dueDate = this.state.dueDate
        let stage = this.state.stage
        let assignedStaff = this.state.assignedStaff

        const orgCode = this.state.activeOrg
        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail

        let createdAt = new Date()
        if (this.state.createdAt) {
          createdAt = this.state.createdAt
        }
        const updatedAt = new Date()

        let ticketObject = {
          _id, name, urgency, category, description, firstName, lastName, email, username, pictureURL,
          roleName, dueDate, stage, assignedStaff, orgCode, orgName, orgContactEmail,
          createdAt, updatedAt
        }

        Axios.post('/api/tickets', ticketObject).then((response) => {
          console.log('attempting to save ticket rankings')
          if (response.data.success) {
            console.log('saved ticket')

            let successMessage = 'Successfully created the ticket'
            let action = 'add'
            if (_id) {
              successMessage = "Successfully updated the ticket"
              action = 'edit'
            } else {
              name = ''
              urgency = ''
              category = ''
              description = ''
              dueDate = ''
              stage = ''
              assignedStaff = null
            }

            this.setState({ successMessage, name, urgency, category, description, dueDate, stage,
              assignedStaff
            })

            ticketObject['_id'] = response.data.ticket._id
            // console.log('----!', ticketObject)
            this.props.passData(ticketObject,action)

          } else {
            console.log('did not successfully update the rankings', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save rankings did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      }

    }

    deleteTicket() {
      console.log('deleteTicket called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const _id = this.state._id
      if (!_id) {
        this.setState({ isSaving: false, errorMessage: "Error: no ID for the ticket" })
      } else {
        Axios.delete('/api/tickets/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Ticket delete worked');

            this.setState({ isSaving: false, successMessage: 'Successfully delete the ticket', confirmDelete: false })
            this.props.passData(null,'delete')
            this.props.closeModal()

          } else {
            console.error('an error: ', response.data.message);
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
        <div>
          <div>
            <div className="calc-column-offset-40">
              {(this.state.name) ? (
                <p className="heading-text-5">{this.state.name}</p>
              ) : (
                <p className="heading-text-5">Create a New Ticket</p>
              )}
            </div>
            <div className="fixed-column-40">
              <button className="background-button" onClick={() => this.props.closeModal()}>
                <img src={closeIcon} alt="GC" className="image-auto-15" />
              </button>
            </div>
            <div className="clear" />
          </div>
          {(this.state.createdAt) && (
            <div className="row-10">
              <p className="description-text-3">Created {convertDateToString(this.state.createdAt,"daysAgo")}{(this.state.email && this.state.firstName) && " by " + this.state.firstName + " " + this.state.lastName + " (" + this.state.roleName + ")"}</p>
            </div>
          )}

          <div className="spacer" />

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Title<label className="error-color bold-text">*</label></label>
              <input className="text-field" type="text" placeholder="Title the issue..." name="name" value={this.state.name} onChange={this.formChangeHandler} />
            </div>
            <div className="container-right">
              <label className="profile-label">Urgency<label className="error-color bold-text">*</label></label>
              <select name="urgency" className="dropdown" value={this.state.urgency} onChange={this.formChangeHandler}>
                {this.state.urgencyOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
            <div className="clear" />
          </div>
          {(this.state.ticketCategories && this.state.ticketCategories.length > 0) && (
            <div className="row-10">
              <label className="profile-label">Ticket Type<label className="error-color bold-text">*</label></label>
              <select name="category" className="dropdown" value={this.state.category} onChange={this.formChangeHandler}>
                {this.state.ticketCategories.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
              </select>
            </div>
          )}
          <div className="row-10">
            <label className="profile-label">Description<label className="error-color bold-text">*</label></label>
            <textarea className="text-field" type="text" placeholder="Add a description" name="description" value={this.state.description} onChange={this.formChangeHandler} />
          </div>

          <div className="row-10">
            <hr />
          </div>

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Due Date</label>
              <input type="date" className="date-picker white-background" name="dueDate" value={this.state.dueDate} onChange={this.formChangeHandler}></input>
            </div>
            <div className="container-right">
              <label className="profile-label">Assignee</label>
              <select name="staffMember" value={(this.state.assignedStaff && this.state.assignedStaff.email) ? this.state.assignedStaff.firstName + ' ' + this.state.assignedStaff.lastName : ""} onChange={this.formChangeHandler} className="dropdown">
                {this.state.staffMemberOptions.map(value =>
                  <option key={value.email} value={value.firstName + ' ' + value.lastName}>{value.firstName} {value.lastName}</option>
                )}
              </select>
            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Stage</label>
              <select name="stage" className="dropdown" value={this.state.stage} onChange={this.formChangeHandler}>
                {this.state.stageOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
            <div className="container-right">
            </div>
            <div className="clear" />
          </div>

          <div className="spacer" />

          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}

          <div className="row-10">
            <button className="btn btn-primary" onClick={() => this.saveTicket()}>Save</button>
            <button className="btn btn-quaternary left-margin" onClick={() => this.deleteTicket()}>Delete</button>
          </div>
        </div>
      )
    }
}

export default EditTicket;;
