import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubGroups from './Subcomponents/Groups';
import SubSideNav from './Common/SideNav';
import withRouter from './Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class EmpCommunities extends Component {
    constructor(props) {
        super(props)

        this.state = {
          opacity: 0,
          transform: 'translate(90%)',
          renderSideNav: false
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ username, emp, orgMode, activeOrg, orgLogo })

      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
          <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

            {(this.state.renderSideNav) && (
              <SubSideNav selectedComponent={"EmpCommunities"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} accountCode={this.state.emp} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />
            )}

            <div className={(this.state.renderSideNav) ? "calc-column-offset-260-of-91" : "width-70-percent max-width-1400 center-horizontally vertical-margin-2-percent"} style={(this.state.renderSideNav) ? {...styles, opacity: this.state.opacity, transform: this.state.transform} : {}}>
              <SubGroups history={this.props.navigate} accountCode={this.state.emp} roleName="Employer" />
            </div>
            <div className="clear" />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(EmpCommunities)
