import React, {Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from './Functions/WithRouter';

class EmpSignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }

    componentDidMount() {
      console.log('componentDidMount called')
      const { emp } = this.props.params

      console.log('show emp: ', emp)
      this.setState({ emp })
    }

    render() {

      return (
        <div>
          <LoginForm roleName="Employer" orgCode={this.state.orgCode} courseId={this.state.courseId} accountCode={this.state.emp} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignIn" />
        </div>
      )
    }
}

export default withRouter(EmpSignIn)
