import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAdminApplications from './Subcomponents/AdminApplications';
import withRouter from './Functions/WithRouter';

class EmpApplications extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      let { selectedJob, applications, benchmark, benchmarkOptions } = this.props.location.state;
        console.log('show applications ', applications, benchmark, benchmarkOptions)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      if (email) {

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, emp,
            activeOrg, orgMode, orgLogo,

            selectedJob, applications, filteredApplications: applications,
            benchmark, benchmarkOptions
        });
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {
      console.log('show bench: ', this.state.benchmark, this.state.benchmarkOptions)
      return (
          <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace}/>
            <SubAdminApplications accountCode={this.state.emp} org={this.state.activeOrg} selectedJob={this.state.selectedJob} applications={this.state.applications} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(EmpApplications)
