import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubMatches from './Subcomponents/Matches';
import withRouter from './Functions/WithRouter';

class Matches extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, orgLogo })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  history={this.props.navigate}/>

              <div className="standard-container-2">
                <SubMatches history={this.props.navigate} />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(Matches)
