import React, {Component} from 'react';
import Axios from 'axios';
import SubSubscribe from '../components/Subcomponents/Subscribe';
import AppFooter from '../components/AppFooter';
import withRouter from '../components/Functions/WithRouter';

class CheckoutPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        enableSubscriptions: false,
        subscribed: false
      }

      this.passSubscriptionData = this.passSubscriptionData.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "#F5F5F5";

      let emailId = localStorage.getItem('email');

      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (!activeOrg) {
        activeOrg = 'guidedcompass'
      }
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      const { roleName } = this.props.params
      // console.log('vital check in checkout: ', emailId, cuFirstName, cuLastName, activeOrg, orgFocus)

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, orgLogo })

      const isActive = true
      const includeMembership = false

      let subParams = {
        email: emailId, orgCode: activeOrg, accountCode: null, subscriptionType: 'Individual', isActive, includeMembership
      }

      Axios.get('/api/payments/subscription', { params: subParams })
      .then((response) => {
        console.log('Subscription query attempted in checkout page parent', response.data);

         if (response.data.success) {
           console.log('successfully retrieved subscription')

           this.setState({ subscribed: true })

         } else {
           console.log('no org data found', response.data.message)

         }

      }).catch((error) => {
         console.log('Subscription query did not work', error);

      });
    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in exploration', subscription)

      this.setState({ subscribed: true })

    }

    static getDerivedStateFromProps(nextProps, prevState) {
      console.log('getDerivedStateFromProps called', nextProps, prevState)
      if (nextProps.params.roleName !== prevState.roleName){
        const roleName = nextProps.params.roleName
        return { roleName }
      }

      return null;
    }

    render() {
        return (
            <div>
              <div>
                <SubSubscribe history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} roleName={this.state.roleName} />
                <div className="clear" />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(CheckoutPage);
