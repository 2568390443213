import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubChangePassword from './Subcomponents/ChangePassword';
import withRouter from './Functions/WithRouter';

class ChangePassword extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (emailId) {
        this.setState({ emailId, username, cuFirstName, cuLastName, activeOrg, orgLogo });
      }
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  history={this.props.navigate}/>
          <SubChangePassword email={this.state.emailId} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(ChangePassword)
