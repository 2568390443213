import React, { Component} from 'react';

import Axios from 'axios';
import Modal from 'react-modal';
// import {convertDateToString} from '../Functions/ConvertDateToString';
import {signUp, signIn} from '../Services/AuthRoutes';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
// const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

// const styles = {
//     transition: 'all 1.2s ease-in',
// };

class LogInFields extends Component {
    constructor(props) {
        super(props)
        this.state = {
            employerOptions: [],

            subscribed: true,
            toggleLink: '/',
            pathwayOptions: [],
            roleNameOptions: [],
            acceptableRoles: ['','Student','Career-Seeker','School Support','Worker','Teacher','Counselor','WBLC','Mentor','Employer','Admin'],

            opacity: 1,
            error: {
                message: ''
            }
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.validateRoleName = this.validateRoleName.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.startSignUp = this.startSignUp.bind(this)
        this.startSignIn = this.startSignIn.bind(this)

    }

    componentDidMount() {
        console.log('login form page is mounting')

        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within login form ', this.props, prevProps)

      if (this.props.roleName !== prevProps.roleName || this.props.orgCode !== prevProps.orgCode || this.props.workId !== prevProps.workId || this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.fromExternal !== prevProps.fromExternal) {
        this.retrieveData()
      } else if (this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.opportunityId !== prevProps.opportunityId) {
        this.retrieveData()
      } else if (this.props.signInPage !== prevProps.signInPage) {
        this.retrieveData()
      } else if (this.props.type !== prevProps.type) {
        this.retrieveData()
      } else if (this.props.employerOptions !== prevProps.employerOptions || this.props.employerName !== prevProps.employerName) {
        this.retrieveData()
      } else if (this.props.onlyFields !== prevProps.onlyFields || this.props.emailId !== prevProps.emailId) {
        this.retrieveData()
      } else if (this.props.importedEmailId !== prevProps.importedEmailId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in loginform', this.props.opportunityId)

      let signInPage = this.props.signInPage
      let orgCode = this.props.orgCode
      const workId = this.props.workId
      const accountCode = this.props.accountCode
      let roleName = this.props.roleName
      if (this.props.passedRoleName === 'Employer') {
        roleName = this.props.passedRoleName
      }
      const opportunityId = this.props.opportunityId
      const path = window.location.pathname

      let toggleLink = ''
      if (path) {
        if (path.includes("join") || path.includes("signup")) {
          toggleLink = path.replace("join","signin").replace("signup","signin")
        } else if (path.includes("signin")){
          toggleLink = path.replace("signin","signup")
        }
      }

      let mobileView = false
      if (window.innerWidth <= 768) {
        mobileView = true
      }

      let originalRoleName = false
      const onlyFields = this.props.onlyFields
      const loginType = this.props.type
      const loggedIn = this.props.loggedIn
      const emailId = this.props.emailId

      if (roleName && orgCode) {
        roleName = roleName.charAt(0).toUpperCase() + roleName.slice(1)

        let isValid = false
        if (this.state.acceptableRoles.includes(roleName)) {
          if (orgCode !== '') {
            isValid = true
          }
        }

        originalRoleName = true

        console.log('oppId 2: ', opportunityId)

        this.setState({ roleName, originalRoleName, orgCode, path, isValid, workId, opportunityId,
          accountCode, toggleLink, mobileView, signInPage, onlyFields, loginType, loggedIn, emailId })

      } else if (roleName) {
        // no orgCode
        console.log('show toggle 3: ', toggleLink )
        originalRoleName = true
        this.setState({ roleName, originalRoleName, path, accountCode, toggleLink, mobileView, orgCode, opportunityId, onlyFields, loginType, loggedIn, emailId })
      } else {
        // no orgCode or roleName
        const roleNameOptions = ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Mentor','Employer Representative','Other']
        this.setState({ path, accountCode, toggleLink, mobileView, orgCode, opportunityId, roleNameOptions, onlyFields, loginType, loggedIn, emailId })
      }

      if (orgCode) {
        Axios.get('/api/emails', { params: { orgCode } })
        .then((response) => {
          console.log('Emails query attempted', response.data);

            if (response.data.success) {
              console.log('emails query worked')

              const studentBenefits = response.data.benefits[0].studentBenefits
              const careerSeekerBenefits = response.data.benefits[0].studentBenefits
              const teacherBenefits = response.data.benefits[0].teacherBenefits
              const mentorBenefits = response.data.benefits[0].mentorBenefits
              const employerBenefits = response.data.benefits[0].employerBenefits

              this.setState({ studentBenefits, careerSeekerBenefits, teacherBenefits, mentorBenefits, employerBenefits });

            } else {
              console.log('emails query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Emails query did not work for some reason', error);
        });

        Axios.get('/api/org', { params: { orgCode} })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const school = response.data.orgInfo.orgName
              const schoolDistrict = response.data.orgInfo.district
              const orgName = response.data.orgInfo.orgName
              const orgLogoURI = response.data.orgInfo.webLogoURI
              const headerImageURL = response.data.orgInfo.headerImageURL
              const orgFocus = response.data.orgInfo.orgFocus
              const publicOrg = response.data.orgInfo.isPublic
              const placementPartners = response.data.orgInfo.placementPartners

              let pathwayOptions = ['']
              let includePathway = false
              // if (response.data.orgInfo.pathways && response.data.orgInfo.pathways.length > 0 && orgCode === 'dpscd') {
              //   pathwayOptions = pathwayOptions.concat(response.data.orgInfo.pathways)
              //   includePathway = true
              // }

              this.setState({
                school, schoolDistrict, orgName, orgLogoURI, headerImageURL, orgFocus, pathwayOptions, includePathway,
                publicOrg, placementPartners
              });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

      }
    }

    formChangeHandler(event) {
      //validate strings
      if (event.target.name === 'firstName') {
        let capitalizedFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ firstName: capitalizedFirstName })
      } else if (event.target.name === 'lastName') {
        let capitalizedLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ lastName: capitalizedLastName})
      } else if (event.target.name === 'employerName') {
        const employerName = event.target.value
        const accountCode = null
        this.setState({ employerName, accountCode, isLoading: true })
        this.searchEmployers(employerName)
      } else if (event.target.name === 'orgCode') {
        let orgCode = event.target.value
        if (orgCode && orgCode !== '') {
          orgCode.toLowerCase()
        }
        this.setState({ orgCode })
      } else if (event.target.name === 'subscribed') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ subscribed: value, formHasChanged: true })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/account/search', { params: { employerName } })
        .then((response) => {
          console.log('Employer search query attempted', response.data);

            if (response.data.success) {
              console.log('employer search query worked')

              const employerOptions = response.data.employers
              self.setState({ employerOptions, isLoading: false });

            } else {
              console.log('employer search query did not work', response.data.message)
              this.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('Employer search query did not work for some reason', error);
            this.setState({ isLoading: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      this.setState({ employerName: employer.employerName, employerOptions: [], accountCode: employer.accountCode })
      this.props.passData({ employerName: employer.employerName, employerOptions: [], accountCode: employer.accountCode })

    }

    validateRoleName(roleName) {
      console.log('validateRoleName called', roleName)

      const acceptableRoles = this.state.acceptableRoles.concat(this.state.roleNameOptions)

      let returnValue = false
      for (let i = 1; i <= acceptableRoles.length; i++) {

        if (acceptableRoles[i - 1].toLowerCase() === this.state.roleName.toLowerCase()) {
          returnValue = true
        }
      }
      console.log('show returnValue: ', returnValue)

      return returnValue
    }

    itemClicked(value, type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        let roleName = value
        this.setState({ roleName })
      }
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false })
    }

    async startSignUp() {
      console.log('startSignUp called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const firstName = this.state.cuFirstName
      const lastName = this.state.cuLastName
      const email = this.state.emailId
      const password = this.state.password
      const gradYear = this.state.gradYear
      const jobTitle = this.state.jobTitle
      const employerName = this.state.employerName
      const orgCode = this.state.orgCode
      let roleName = this.state.roleName
      if (this.state.roleName === 'Other') {
        roleName = this.state.otherRoleName
      } else {
        if (this.state.roleName === 'Student / Career Seeker') {
          roleName = 'Student'
        } else if (this.state.roleName === 'Employer Representative / Mentor') {
          roleName = 'Mentor'
        } else {
          roleName = this.state.roleName
        }
      }
      const otherRoleName = this.state.otherRoleName
      const schoolName = this.state.schoolName
      const accountCode = this.state.accountCode
      const studentBenefits = this.state.studentBenefits
      const teacherBenefits = this.state.teacherBenefits
      const mentorBenefits = this.state.mentorBenefits
      const employerBenefits = this.state.employerBenefits
      const confirmEmail = this.state.confirmEmail

      const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, null, null, schoolName, null, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, confirmEmail)
      console.log('was sign up successful? ', returnedValue)
      if (returnedValue && returnedValue.success) {
        console.log('sign up was successful: ', returnedValue)

        let dataToPass = {
          emailId: returnedValue.user.email, cuFirstName: returnedValue.user.firstName,
          cuLastName: returnedValue.user.lastName, pictureURL: returnedValue.user.pictureURL,
          username: returnedValue.user.username,
          jobTitle: returnedValue.user.jobTitle, employerName: returnedValue.user.employerName,
          loggedIn: true, importedEmailId: true
        }

        this.props.passData(dataToPass)
        if (!this.props.skipCloseModal) {
          this.props.closeModal()
        }

      } else if (returnedValue && returnedValue.message) {
        console.log('sign up error: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message })
      } else {
        console.log('unknown sign up response: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
      }
    }

    async startSignIn() {
      console.log('startSignIn called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const email = this.state.emailId
      const password = this.state.password
      const orgFocus = this.state.orgFocus

      const returnedValue = await signIn(email, password, orgFocus)
      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        this.setState({ isSaving: false, successMessage: returnedValue.message })

        let dataToPass = {
          emailId: returnedValue.user.email, cuFirstName: returnedValue.user.firstName,
          cuLastName: returnedValue.user.lastName, pictureURL: returnedValue.user.pictureURL,
          username: returnedValue.user.username,
          jobTitle: returnedValue.user.jobTitle, employerName: returnedValue.user.employerName,
          loggedIn: true, importedEmailId: true
        }

        this.props.passData(dataToPass)
        if (!this.props.skipCloseModal) {
          this.props.closeModal()
        }

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSaving: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message })
      }
    }

    render() {

      return (
        <div>
          {(this.state.loginType === 'SignUp') ? (
            <div>
              {(this.state.successMessage) ? (
                <div>
                  <p className="success-message">{this.state.successMessage}</p>
                  {(this.state.successLink) && (
                    <div className="top-padding-20">
                      <a href={this.state.successLink} target="_blank">{this.state.successLink}</a>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {/*
                  <div className="bottom-margin">
                    <p className="description-text-3 top-padding-5">Already have an account? Login to <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >import your information</button></p>
                  </div>*/}

                  {(!this.state.originalRoleName) && (
                    <div>
                      <div className="row-15">
                        <div>
                          <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-45"}>
                            <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                          </div>
                          <div className={(window.innerWidth > 768) ? "float-left left-padding" : "fixed-column-45 left-padding"}>
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <div className="noti-bubble-info-7-9">
                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showRoleDefinitions: true })}>
                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                              </a>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>

                        {this.state.roleNameOptions.map((value, index) =>
                          <div key={value} className="float-left">
                            <div className="float-left row-5 right-padding">
                              <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                {(this.state.roleName === value) ? (
                                  <div className="tag-container-4">
                                    <p className="description-text-2 white-text half-bold-text">{value}</p>
                                  </div>
                                ) : (
                                  <div className="tag-container-inactive-2">
                                    <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>

                      {(this.state.roleName === 'Other') && (
                        <div className="row-15">
                          <label className="profile-label">You selected "other". Please write your role:<label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="Other Role Name..." name="otherRoleName" value={this.state.otherRoleName} onChange={this.formChangeHandler} />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="top-padding">
                    <div className="row-15">
                      <div className="container-left">
                        <input className="number-field height-40" type="text" name="cuFirstName"
                        placeholder="First Name" value={this.state.cuFirstName}
                        onChange={this.formChangeHandler}
                        />
                      </div>
                      <div className="container-right">
                        <input className="number-field height-40" type="text" name="cuLastName"
                          ref="lastName" placeholder="Last Name" value={this.state.cuLastName}
                          onChange={this.formChangeHandler}
                        />
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="row-15">
                      <div className="container-left">
                        <input className="number-field height-40" type="email" name="emailId"
                          placeholder="Email" value={this.state.emailId}
                          onChange={this.formChangeHandler}
                        />
                      </div>
                      <div className="container-right">
                        <input className="number-field height-40" type="Password" name="password"
                          placeholder="Password" value={this.state.password}
                          onChange={this.formChangeHandler}
                        />
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.roleName === 'Employer' && !this.props.orgCode) && (
                      <input className="login-orgCode" type="text" name="orgCode"
                        ref="orgCode" placeholder="Org Code" value={this.state.orgCode}
                        onChange={this.formChangeHandler}
                      />
                    )}

                    {(this.state.roleName && this.state.roleName.toLowerCase() === 'student') && (
                      <div>
                        {(this.state.includePathway) && (
                          <div className="row-5">
                            <label className="profile-label left-text">Pathway</label>
                            <select name="pathway" className="dropdown" value={this.state.pathway} onChange={this.formChangeHandler}>
                                {this.state.pathwayOptions.map(value => <option key={value.title} value={(value.title) ? value.title + ' | ' + value.school : ""}>{(value.title) ? value.title + " | " + value.school : ""}</option>)}
                            </select>
                          </div>
                        )}

                      </div>
                    )}

                    {((this.state.roleName && this.state.roleName.toLowerCase() === 'mentor') || (this.state.roleName && this.state.roleName.toLowerCase() === 'employer representative') || (this.state.roleName && this.state.roleName.toLowerCase() === 'employer' && !this.props.accountCode)) && (
                      <div className="row-15">
                        <div className="container-left">
                          <input className="number-field height-40" type="text" name="jobTitle"
                          placeholder="Job Title" value={this.state.jobTitle}
                          onChange={this.formChangeHandler}
                          />
                        </div>
                        <div className="container-right">
                          <input className="number-field height-40" type="text" name="employerName"
                            placeholder="Employer Name" value={this.state.employerName}
                            onChange={this.formChangeHandler}
                          />

                          {(this.state.isLoading) ? (
                            <div className="flex-container flex-center row-15">
                              <div>
                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                                <p className="center-text cta-color bold-text">Searching...</p>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {(this.state.employerOptions.length > 0) && (
                                <div>
                                  <div className="spacer" />

                                  {this.state.employerOptions.map(value =>
                                    <div key={value._id} className="left-text row-5">
                                      <button className="background-button full-width left-text" onClick={() => this.employerClicked(value)}>
                                        <div>
                                          <div className="fixed-column-30">
                                            <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                                          </div>
                                          <div className="calc-column-offset-30">
                                            <p className="cta-color">{value.employerName}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>)}
                                </div>
                              )}
                            </div>
                          )}

                        </div>

                        <div className="clear" />

                      </div>
                    )}

                    {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'Work-Based Learning Coordinator') && (
                      <div className="row-15">
                        <div className="container-left">
                          <input className="number-field height-40" type="text" name="school"
                          placeholder={(this.state.roleName === 'Work-Based Learning Coordinator') ? "Program Name" : "School Name"} value={this.state.school}
                          onChange={this.formChangeHandler}
                          />
                        </div>

                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  {(this.state.roleName === 'Student / Career Seeker') && (
                    <div className="top-padding">
                      <div className="fixed-column-20">
                        <input type="checkbox" name="subscribed" checked={this.state.subscribed} onChange={this.formChangeHandler} />
                      </div>
                      <div className="calc-column-offset-20 top-padding-5">
                        <div className="mini-spacer" /><div className="mini-spacer" />
                        <p className="description-text-3">Receive weekly emails of event, project, and work opportunities based on your interests.</p>
                      </div>
                      <div className="clear" />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              {/*
              {(this.state.loggedIn) ? (
                <div className="bottom-margin">
                  <p className="description-text-3 top-padding-5">Is this not you? <label className="cta-color">[{this.state.emailId}]</label> <button className="background-button cta-color bold-text" onClick={() => this.props.logout()} disabled={this.state.isSavingModal} >Log out</button></p>
                </div>
              ) : (
                <div className="bottom-margin">
                  <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                </div>
              )}*/}

              <div className="row-10">
                <label className="profile-label">Email</label>
                <input className="number-field height-40" name="emailId" value={this.state.emailId} placeholder="(e.g., jonniedoe@gmail.com)" onChange={this.formChangeHandler}/>
              </div>
              <div className="row-10">
                <label className="profile-label">Password</label>
                <input className="number-field height-40" type="password" name="password" value={this.state.password} placeholder="password" onChange={this.formChangeHandler}/>
              </div>

            </div>
          )}

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

          <div className="top-margin-20">
            {(this.state.loginType === 'SignUp') ? (
              <button className="btn btn-primary right-margin" disabled={this.state.isSaving} onClick={() => this.startSignUp()}>Sign Up</button>
            ) : (
              <button className="btn btn-primary right-margin" disabled={this.state.isSaving} onClick={() => this.startSignIn()}>Sign In</button>
            )}

            <button className="btn btn-secondary" onClick={() => this.props.closeModal()}>Cancel</button>
          </div>

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
          >

            {(this.state.showRoleDefinitions) && (
              <div key="showRoleDefinitions" className="full-width padding-20">
                <p className="heading-text-2">Role Definitions for Guided Compass (GC)</p>

                <div className="row-10 description-text-1">
                  <div className="row-10">
                    <p>1) <label className="half-bold-text cta-color">Student / Career Seeker</label> refers to people in school to learn career-related skills, people seeking to start their careers, or people seeking to switch their careers. Use GC to explore careers, manage courses, RSVP to events, submit projects, and apply to work opportunities.</p>
                  </div>

                  <div className="row-10">
                    <p>2) <label className="half-bold-text cta-color">Teacher</label> refers to a teachers, instructors, professors, and trainers that teach career-releated skills. Use GC to track student progress, endorse student competencies, and oversee student projects.</p>
                  </div>

                  <div className="row-10">
                    <p>3) <label className="half-bold-text cta-color">Work-Based Learning Coordinator</label> refers to coordinators and administrators of work-based learning programs. These people act as intermediaries between career-seekers and employers / employment. Use GC to manage work-based learning postings, refer students, track placements, manage all members, and export reports.</p>
                  </div>

                  <div className="row-10">
                    <p>4) <label className="half-bold-text cta-color">Counselor</label> refers to people who coach or counsel career-seekers on career paths. Use GC to explore career paths, guide students, and oversee progress.</p>
                  </div>

                  <div className="row-10">
                    <p>5) <label className="half-bold-text cta-color">Mentor</label> refers to professionals that would like to be more active in helping career-seekers, including hosting events, providing project feedback, and conducting mock interviews.</p>
                  </div>

                  <div className="row-10">
                    <p>6) <label className="half-bold-text cta-color">Employer Representative</label> refers to HR or hiring managers interested in hands-off activities that directly benefit the employer (e.g., hiring  talent for project or full-time work).</p>
                  </div>

                  <div className="row-10">
                    <p>7) <label className="half-bold-text cta-color">Other</label> refers to anyone that does not fit neatly into the aforementioned categories.</p>
                  </div>
                </div>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
              </div>
            )}

         </Modal>
        </div>

      )
    }
}

export default LogInFields;
