import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubConfigureCartItem from '../Common/ConfigureCartItem';
import SubProductReviews from '../Common/ProductReviews';
import withRouter from '../Functions/WithRouter';

const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class AdvisingServicesProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
          items: [],
          relatedItems: [],
          showAdvisorBio: false
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.slug !== prevProps.slug) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props.activeOrg)

      const slug = this.props.slug

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgName = localStorage.getItem('orgName');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('emp');
      let employerName = localStorage.getItem('employerName');
      let employerLogoURL = localStorage.getItem('employerLogoURL');
      let jobTitle = localStorage.getItem('jobTitle');

      let registered = false
      if (emailId && activeOrg && accountCode && employerName) {
        registered = true
      }
      // console.log('show me asdd:', slug, this.props.params)

      let priceFree = false
      if (activeOrg !== 'guidedcompass' && window.location.pathname.includes('/app/')) {
        priceFree = true
      }

      // console.log('show me categories: ', categories)
      this.setState({ cuFirstName, cuLastName, emailId, activeOrg, orgName, roleName,
        accountCode, employerName, employerLogoURL, jobTitle, registered, priceFree
      })

      Axios.get('/api/advising-services/byid', { params: { slug } })
      .then((response) => {
        console.log('Advising services query attempted', response.data);

        if (response.data.success) {
          console.log('advising services query worked')

          const itemSelected = response.data.advisingService
          this.setState({ itemSelected})

          Axios.get('/api/advising-services', { params: { orgCode: 'guidedcompass', excludeName: itemSelected.name } })
          .then((response) => {
            console.log('Advising services query attempted', response.data);

            if (response.data.success) {
              console.log('advising services query worked')

              const relatedItems = response.data.advisingServices
              this.setState({ relatedItems })

            } else {
              console.log('something went wrong: ', response.data.message)
            }

          }).catch((error) => {
            console.log('Advising services query did not work for some reason', error);
          });

        } else {
          console.log('something went wrong: ', response.data.message)
        }

      }).catch((error) => {
        console.log('Advising services query did not work for some reason', error);
      });
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showAddToCart: false, showReviews: false });
    }

    itemClicked(item, type) {
      console.log('itemClicked called ', item, type)

      if (type === 'Book') {
        this.setState({ modalIsOpen: true, showAddToCart: true, selectedCartItem: item })
      } else if (type === 'Buy') {
        this.props.navigate("/marketplace/" + item.slug)
      } else if (type === 'Sell') {
        this.props.navigate("/marketplace/" + item.slug)
      } else if (type === 'Browse') {
        this.props.navigate("/marketplace/" + item.slug)
      }
    }

    render() {
      return (
          <div>
            {(!this.state.itemSelected) ? (
              <div className="standard-container-2">
                <p className="error-color row-30">No item found.</p>
              </div>
            ) : (
              <div>
                <div className="top-margin-20">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-75"}>
                    <div className={(window.innerWidth < 768) ? "card-clear-padding bottom-margin-20" : "card-clear-padding width-90-percent min-width-300 max-width-900 pin-right bottom-margin-20 left-margin-20"}>
                      <div className="full-width height-5 primary-background" />
                      <div className="padding-20">
                        <div className="spacer" />

                        <div className="fixed-column-150 right-padding-20 top-margin-5">
                          <img src={this.state.itemSelected.imageURL} alt="GC" className="image-full-auto" />
                        </div>
                        <div className="calc-column-offset-150">
                          <p className="heading-text-2">{this.state.itemSelected.name}</p>
                          <p>{this.state.itemSelected.category}</p>

                          {(this.state.itemSelected.averageRating) && (
                            <div className="top-margin">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showReviews: true })}>
                                <div className="float-left">
                                  <ul className="horizontal-list center-text">
                                    <li><img src={(this.state.itemSelected.averageRating >= 1) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    <li><img src={(this.state.itemSelected.averageRating >= 2) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    <li><img src={(this.state.itemSelected.averageRating >= 3) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    <li><img src={(this.state.itemSelected.averageRating >= 4) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    <li><img src={(this.state.itemSelected.averageRating >= 5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                  </ul>

                                </div>
                                <div className="float-left">
                                  <p className="description-text-3 left-margin-5 cta-color bold-text">({this.state.itemSelected.reviewCount})</p>
                                </div>

                                <div className="clear"/>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="clear" />

                        <div className="top-margin-20">
                          <p className="description-text-1">{this.state.itemSelected.description}</p>
                        </div>

                        {(this.state.itemSelected.advisorBio && this.state.showAdvisorBio) ? (
                          <div>
                            <div className="row-30">
                              <hr />
                            </div>

                            <div className="top-margi">
                              <p>Advisor Bio</p>

                              <div className="row-10">
                                <div className="fixed-column-100">
                                  <img src={this.state.itemSelected.advisorPic} alt="GC" className="profile-thumbnail-6" />
                                </div>
                                <div className="calc-column-offset-100">
                                  <p>{this.state.itemSelected.advisorFirstName} {this.state.itemSelected.advisorLastName}</p>
                                  <p className="description-text-3 top-margin-5" >{this.state.itemSelected.advisorBio}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="row-20">
                              <p className="description-text-3">Note: All conversations will remain private and confidential, unless written confirmation is provided to share the contents of the conversation to other parties.</p>
                            </div>

                          </div>
                        ) : (
                          <div>
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-25 horizontal-padding"}>
                    <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-20" : "card-clear-padding min-width-150 max-width-220 pin-left bottom-margin-20"}>
                      <div className="full-width height-5 senary-background" />
                      <div className="padding-20">
                        <div className="row-10">
                          <p className="cta-color heading-text-2">{(this.state.priceFree) ? "Free" : this.state.itemSelected.price}</p>
                          <p className="top-padding-5 description-text-2">advising session with {(window.location.pathname.includes('/app/')) ? <label>{this.state.orgName}</label> : <a href={this.state.itemSelected.advisorProfileLink} target="_blank" rel="noopener noreferrer">{this.state.itemSelected.advisorFirstName} {this.state.itemSelected.advisorLastName}</a>}</p>
                        </div>

                        <div className="spacer" /><div className="spacer" />
                        <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showAddToCart: true, selectedCartItem: this.state.itemSelected })}>Book</button>
                        <div className="spacer" /><div className="half-spacer" />

                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear" />

                <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-75"}>
                  <div className={(window.innerWidth < 768) ? "bottom-margin-20" : "width-90-percent min-width-300 max-width-900 pin-right bottom-margin-20 left-margin-20"}>
                    <div>
                      <p className="heading-text-6 top-margin-20">Related Items</p>

                      {this.state.relatedItems.map((value, optionIndex) =>
                        <div key={value.name}>
                          <div className="relative-column-33 horizontal-padding-4 top-margin-30">
                            <div className="card">
                              <Link to={"/marketplace/" + value.slug} className="background-button standard-color">
                                <img className="image-full-auto contain-object-fit" alt="GC" src={(value.imageURL) ? value.imageURL : profileIconDark} />
                                <p className="full-width description-text-1 top-padding-20 bold-text">{value.name}</p>
                                <p className="full-width description-text-4 top-padding-5 curtail-text">({value.category})</p>

                                {(value.averageRating) && (
                                  <div className="top-margin">
                                    <div className="float-left">
                                    <ul className="horizontal-list center-text">
                                      <li><img src={(value.averageRating >= 1) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 2) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 3) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 4) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(value.averageRating >= 5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    </ul>

                                    </div>
                                    <div className="float-left">
                                      <p className="description-text-3 left-margin-5 cta-color bold-text">({value.reviewCount})</p>
                                    </div>

                                    <div className="clear"/>
                                  </div>
                                )}

                                <p className="full-width heading-text-5 cta-color top-padding">{(this.state.priceFree) ? "Free" : value.price}</p>
                              </Link>

                              {(value.cta === 'Buy') ? (
                                <div className="full-width top-margin-20">
                                  <div className="relative-column-50 right-padding-5">
                                    <button className="btn btn-squarish full-width" onClick={() => this.itemClicked(value,value.cta)}>{value.cta}</button>
                                  </div>
                                  <div className="relative-column-50 left-padding-5">
                                    <button className="btn btn-squarish-white full-width" onClick={() => this.itemClicked(value,'Sell')}>{value.cta2}</button>
                                  </div>
                                  <div className="clear" />
                                </div>
                              ) : (
                                <div>
                                  <button className="btn btn-squarish full-width top-margin-20" onClick={() => this.itemClicked(value,value.cta)}>{value.cta}</button>
                                </div>
                              )}

                            </div>
                          </div>

                          {(optionIndex % 3 === 2) && (
                            <div>
                              <div className="clear" />

                            </div>
                          )}
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  </div>
                </div>

                <div className={(window.innerWidth < 768) ? "" : "relative-column-25 horizontal-padding height-40"} />
                <div className="clear" />

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
            {(this.state.showAddToCart) && (
              <div key="showAddToCart" className="full-width padding-40">
                <SubConfigureCartItem history={this.props.navigate} itemSelected={this.state.selectedCartItem} closeModal={this.closeModal} />
              </div>
            )}
            {(this.state.showReviews) && (
              <div key="showReviews" className="full-width padding-40">
                <SubProductReviews history={this.props.navigate} itemSelected={this.state.itemSelected} closeModal={this.closeModal} />
              </div>
            )}
           </Modal>
          </div>
      )
    }
}


export default withRouter(AdvisingServicesProduct);
