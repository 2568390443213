import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

// const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

class BulkUploader extends Component {
  constructor(props) {
      super(props)

      this.state = {
        uploadLimit: 1000,
        dataTypeOptions: ['','Users','Placements','Programs & Courses','Enrollments','WorkReady Certifying Users'],
        acceptableKeys: [],
        adversityListOptions: [],
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.loadData = this.loadData.bind(this)
      this.renderSelectDataType = this.renderSelectDataType.bind(this)
      this.exportTemplate = this.exportTemplate.bind(this)

  }

  componentDidMount(){
    document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

    this.retrieveData()
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate called in SubBulkUploader ', this.props, prevProps)

    if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
      this.retrieveData()
    }
  }

  retrieveData() {
    console.log('retrieveData called in SubBulkUploader', this.props)

    const accountCode = this.props.accountCode
    const org = this.props.activeOrg

    if (accountCode || org) {
      this.setState({ accountCode, org })

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgContactEmail = response.data.orgInfo.contactEmail

            this.setState({ orgName, orgContactEmail });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }
  }

  formChangeHandler(event) {
    console.log('formChangeHandler called')

    if (event.target.name === 'uploadFile') {
      console.log('profilePicSelectedHandler changed', event.target.files[0])

      if (event.target.files[0]) {
        const mbLimit = 10
        if (event.target.files[0].size > mbLimit * 1024 * 1024) {
          console.log('file is too big')

          const errorMessage = 'File must be less than ' + mbLimit + 'MB. This file is ' + (event.target.files[0].size / (1024 * 1024)).toFixed() + 'MB'
          this.setState({ serverSuccessProfilePic: false, serverErrorMessageProfilePic: errorMessage })

        } else {
          console.log('file is small enough', event.target.files[0].size)

          let acceptedFields = []
          let reader = new FileReader();
          reader.onload = (e) => {
              this.setState({ csvFile: e.target.result, isSaving: true });
              // console.log('how do i access the file: ', e.target.result)
              const data = e.target.result

              let lines = data.split(/\r?\n/);
              // let incorrectLines = []

              let keys = []
              let dataArray = []

              for (let i = 0; i < lines.length; i++) {
                console.log('show me count', i, lines.length)

                if (i === 0) {
                  console.log('show first line ', lines[i])
                  const values = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
                  for (let j = 0; j < values.length; j++) {
                    // console.log('arr['+j+'] =',values[j]);
                    keys.push(values[j])
                  }
                  // console.log('show keys: ', keys)
                }

                if (i > 0) {
                  // const values = lines[i].split(/,/g)
                  dataArray.push({ ignore: ''})

                  let adversityList = []

                  const values = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
                  for (let j = 0; j < values.length; j++) {
                    console.log('arr: ', j, [keys[j]], values[j]);
                    // const tempPair = { [keys[i]]: values[j]}

                    if (this.state.acceptableKeys.some(ak => ak.name === keys[j])) {
                      dataArray[i - 1][keys[j]] = values[j]
                      console.log('up in it')
                      if (!acceptedFields.includes(keys[j])) {
                        acceptedFields.push(keys[j])
                      }

                      const keyIndex = this.state.acceptableKeys.findIndex(ak => ak.name === keys[j])
                      const dataType = this.state.acceptableKeys[keyIndex].dataType
                      // console.log('show index: ', keyIndex, dataType)

                      if (dataType === 'Array') {
                        if (dataArray[i - 1][keys[j]]) {
                          dataArray[i - 1][keys[j]] = dataArray[i - 1][keys[j]].replace(/\"/g,"").split(", ")
                        }
                      }
                      if (dataType === 'Array of Objects') {
                        if (dataArray[i - 1][keys[j]]) {
                          let parsedData = dataArray[i - 1][keys[j]].replace(/\[/g,"").replace(/\]/g,"").split("}, ")
                          // sdf, sdf, dsf, sdf
                          if (parsedData) {
                            for (let k = 0; k < parsedData.length; k++) {
                              let parsedArrayOfObjects = parsedData[k].replace(/ /g,"").replace(/\},/g,"}},").split("},")
                              console.log('po: ', parsedArrayOfObjects)

                              if (parsedArrayOfObjects) {
                                for (let l = 0; l < parsedArrayOfObjects.length; l++) {

                                  // console.log('parsedObject: ', parsedArrayOfObjects[l].replace(/\{'/g,"{\"").replace(/\'}/g,"\"}").replace(/\':\'/g,"\":\"").replace(/\',\'/g,"\",\"").replace(/\"{/g,"\{").replace(/}\"/g,"\}"))
                                  const objectInArray = JSON.parse(parsedArrayOfObjects[l].replace(/\{'/g,"{\"").replace(/\'}/g,"\"}").replace(/\':\'/g,"\":\"").replace(/\',\'/g,"\",\"").replace(/\"{/g,"\{").replace(/}\"/g,"\}"))
                                  // console.log('parsedObject: ', objectInArray)
                                  // parsedArrayOfObjects[l] = objectInArray
                                  parsedArrayOfObjects[l] = objectInArray

                                }
                              }

                              dataArray[i - 1][keys[j]] = parsedArrayOfObjects
                              // parsedData[k] = parsedData[k].replace
                            }
                          }
                          // console.log('show the value: ', typeof parsedData, parsedData[0])
                          // dataArray[i - 1][keys[j]] = JSON.parse(dataArray[i - 1][keys[j]])
                        }
                      }
                      if (dataType === 'Boolean') {
                        if (dataArray[i - 1][keys[j]] === 'Yes' || dataArray[i - 1][keys[j]] === 'YES' || dataArray[i - 1][keys[j]] === 'true' || dataArray[i - 1][keys[j]] === 'TRUE') {
                          dataArray[i - 1][keys[j]] = true
                        } else {
                          dataArray[i - 1][keys[j]] = false
                        }
                      }
                      if (dataType === 'Date') {
                        // console.log('show the date value: ', users[i - 1][keys[j]], new Date(users[i - 1][keys[j]]))
                      }

                      if (dataType === 'String') {
                        dataArray[i - 1][keys[j]] = values[j].replace(/,/g,";").replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
                      }

                      if ([keys[j]] === 'adversityList' && dataArray[i - 1][keys[j]]) {
                        adversityList = dataArray[i - 1][keys[j]].join(',')
                      }


                      // dataArray[i - 1].adversityList = dataArray[i - 1].adversityList.join(',')
                      // console.log('show the key: ', keys[j], this.state.adversityListOptions)

                      if (this.state.adversityListOptions.includes(keys[j])) {
                        adversityList.push(keys[j])
                      }

                      // ARRAY: adversityList, races
                      // BOOLEAN: workAuthorization, isSubsidyEligible, isWorkforceReady, isWorkReadyCertified, hasWorkPermit
                      // DATE: dateOfBirth, createdAt, updatedAt

                    }

                    delete dataArray[i - 1].ignore

                  }

                  if (this.state.selectedDataType === 'Users') {
                    if (dataArray[i - 1].school && dataArray[i - 1].school !== '') {
                      // console.log("users: ", users)
                      dataArray[i - 1]['education'] = [
                        { name: dataArray[i - 1].school, endDate: dataArray[i - 1].gradDate, major: dataArray[i - 1].pathway, degree: dataArray[i - 1].degreeType }
                      ]
                    }

                    if (dataArray[i - 1].race && !dataArray[i - 1].races) {
                      dataArray[i - 1].races = [dataArray[i - 1].race]
                    }

                    if (dataArray[i - 1].firstName && dataArray[i - 1].lastName) {
                      dataArray[i - 1]['username'] = (dataArray[i - 1].firstName + dataArray[i - 1].lastName).toLowerCase().replace(/\s/g, "");
                    }

                    dataArray[i - 1]['roleName'] = 'Student'
                    dataArray[i - 1]['password'] = 'guidedcompass'

                    // dataArray[i - 1]['activeOrg'] = this.state.org
                    // dataArray[i - 1]['myOrgs'] = [this.state.org]
                    // if (dataArray[i - 1].adversityList && dataArray[i - 1].adversityList.length > 0) {
                    //
                    // }}

                    if (adversityList && adversityList.length > 0) {
                      dataArray[i - 1]['adversityList'] = adversityList
                    }

                  } else {

                    dataArray[i - 1]['orgName'] = this.state.orgName
                    dataArray[i - 1]['orgContactEmail'] = this.state.orgContactEmail
                  }

                  dataArray[i - 1]['orgCode'] = this.state.org
                  dataArray[i - 1]['createdAt'] = new Date()
                  dataArray[i - 1]['updatedAt'] = new Date()

                }

              }

              // console.log("users: ", users)
              this.loadData(this.state.selectedDataType,dataArray, acceptedFields)

          };

          reader.readAsText(event.target.files[0]);
          // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
          // this.saveFile(event.target.name, event.target.files[0])
        }
      }
    } else if (event.target.name === 'selectedDataType') {
      let acceptableKeys = []
      let adversityListOptions = []

      if (event.target.value === 'Users') {
        acceptableKeys = [
          { name: 'firstName', required: true, dataType: 'String', example: 'Joe' },
          { name: 'lastName', required: true, dataType: 'String', example: 'Schmoe'},
          { name: 'email', required: true, dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'phoneNumber', dataType: 'String', example: '(555) 555-5555'},
          { name: 'alternativeEmail', dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'alternativePhoneNumber', dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'workAuthorization', dataType: 'Boolean', example: 'Yes'},
          { name: 'address', dataType: 'String', example: '555 First Street Los Angeles, CA 90062'},
          { name: 'city', dataType: 'String', example: 'Los Angeles'},
          { name: 'state', dataType: 'String', example: 'California'},
          { name: 'zipcode', dataType: 'String', example: '90210'},
          { name: 'location', dataType: 'String', example: '90210'},
          { name: 'dateOfBirth', dataType: 'Date', example: '01/02/08'},
          { name: 'gender', dataType: 'String', example: 'Female', options: []},
          { name: 'race', dataType: 'String', example: 'Asian', options: []},
          { name: 'races', dataType: 'Array', example: 'Asian, African-American', options: []},
          { name: 'selfDescribedRace', dataType: 'String', example: ''},
          { name: 'householdIncome', dataType: 'String', example: '$10,000 - $20,000', options: []},
          { name: 'numberOfMembers', dataType: 'String', example: '2', options: []},
          { name: 'adversityList', dataType: 'Array', example: 'Receiving Free or Reduced-Price Lunch,First Generation Immigrant', options: []},
          { name: 'educationStatus', dataType: 'String', example: 'Enrolled in High School', options: []},
          { name: 'school', dataType: 'String', example: 'University of Southern California' },
          { name: 'degreeType', dataType: 'String', example: 'Bachelors Degree', options: [] },
          { name: 'gradDate', dataType: 'String', example: 'May 2026'},
          { name: 'major', dataType: 'String', example: 'Software Engineering' },
          { name: 'referrerName', dataType: 'String', example: 'Cherlynn'},
          { name: 'referrerEmail', dataType: 'String', example: 'Gonder'},
          { name: 'referrerOrg', dataType: 'String', example: 'Foshay Learning Centern'},
          { name: 'isSubsidyEligible', dataType: 'Boolean', example: 'Yes' },
          { name: 'isWorkforceReady', dataType: 'Boolean', example: 'Yes'},
          { name: 'isWorkReadyCertified', dataType: 'Boolean', example: 'Yes'},
          { name: 'hasWorkPermit', dataType: 'Boolean', example: 'Yes'},
          { name: 'linkedInURL', dataType: 'String', example: 'https://www.linkedin.com/in/creightontaylor/' },
          { name: 'resumeURL', dataType: 'String', example: 'https://www.creightontaylor.com' },
          { name: 'customWebsiteURL', dataType: 'String', example: 'https://www.creightontaylor.com' },
          { name: 'pathway', dataType: 'String', example: 'Software Development' },
          { name: 'cohort', dataType: 'String', example: 'January ' + new Date().getFullYear() },
          { name: 'bulkUploaded', dataType: 'Boolean', example: 'Yes'},
        ]

        adversityListOptions = [
          "Not enrolled/attending school","Not currently working","LGBTQIA+","First-Generation Immigrant (born in another country without U.S. citiz...","No parent/guardian completed a four-year college degree (Bachelor’s de...","Currently/formerly a foster youth","Justice Involved","Currently without stable housing (home insecure or homeless)","Pregnant/Parenting","Person with disability","Veteran","Receiving Free or Reduced-Price Lunch","Receiving Food Stamps","Receiving TANF","Receiving General Assistance","Receiving SSI","Receiving Refugee Cash Assistance","None of the above"
        ]

        for (let i = 1; i <= adversityListOptions.length; i++) {
          acceptableKeys.push({ name: adversityListOptions[i - 1], dataType: 'String', example: '' })
        }
      } else if (event.target.value === 'Placements') {
        acceptableKeys = [
          { name: 'workType', required: true, dataType: 'String', example: 'Internship' },
          { name: 'title', required: true, dataType: 'String', example: 'Software Developer'},
          { name: 'hiringManagerFirstName', required: true, dataType: 'String', example: 'Joe'},
          { name: 'hiringManagerLastName', required: true, dataType: 'String', example: 'Schmoe'},
          { name: 'hiringManagerEmail', required: true, dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'payType', dataType: 'String', example: 'Hourly', options: []},
          { name: 'payRange', dataType: 'String', example: '$20 - $30 / Hour', options: []},
          { name: 'description', required: true, dataType: 'String', example: 'The software developer will...'},
          { name: 'timesheetEnabled', dataType: 'Boolean', example: 'true'},
          { name: 'startDate', dataType: 'Date', example: '01/01/2025'},
          { name: 'endDate', dataType: 'Date', example: '12/31/2025'},
          { name: 'isPerpetual', dataType: 'Boolean', example: 'true'},
          { name: 'location', dataType: 'String', example: '555 Cloverfield Lane, Los Angeles, CA 90062'},
          { name: 'workFunction', dataType: 'String', example: 'Software Development', options: []},
          { name: 'industry', dataType: 'String', example: 'Healthcare', options: []},
          { name: 'field', dataType: 'String', example: 'Software Development | Healthcare'},
          { name: 'pathways', dataType: 'Array of Objects', example: "[{ 'sourceId':'12345789', 'name': 'Software Development'},{ 'sourceId':'98765321', 'name': 'IT Support'}]"},
          { name: 'departments', dataType: 'Array of Objects', example: "[{ 'name': 'STEAM Program' }]"},
          { name: 'employerName', required: true, dataType: 'String', example: 'Apple, Inc.'},
          { name: 'employerURL', required: true, dataType: 'String', example: 'https://www.apple.com'},
          { name: 'hoursPerWeek', dataType: 'String', example: '~ 40 Hours', options: []},
          { name: 'workers', required: true, dataType: 'Array of Objects', example: "[{'firstName':'Joe','lastName': 'Schmoe', 'email': 'joeschmoe@gmail.com' },{ 'firstName': 'Jane', 'lastName': 'Doe', 'email': 'janedoe@gmail.com' }]"},
          { name: 'trainingPlanURL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'trainingAgreementURL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'educationDevelopmentPlanURL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'individualizedEducationPlanURL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'w9URL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'supplierFormURL', dataType: 'String', example: 'https://drive.google.com'},
          { name: 'trainingAgreementExecuted', dataType: 'Boolean', example: 'true'},
          { name: 'w9Executed', dataType: 'Boolean', example: 'true'},
          { name: 'supplierFormExecuted', dataType: 'Boolean', example: 'true'}
        ]
      } else if (event.target.value === 'Programs & Courses') {

        acceptableKeys = [
          { name: 'name', required: true, dataType: 'String', example: 'App Development 101' },
          { name: 'imageURL', dataType: 'String', example: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/gc-front-image.png' },
          { name: 'category', required: true, dataType: 'String', example: 'Training Program', options: [] },
          { name: 'programURL', required: true, dataType: 'String', example: 'https://www.harvard.edu/' },
          { name: 'degreeType', dataType: 'String', example: 'Badge', options: [] },
          { name: 'programMethod', required: true, dataType: 'String', example: 'Both Online and In-Person', options: [] },
          { name: 'location', dataType: 'String', example: 'Los Angeles' },
          { name: 'estimatedHours', dataType: 'Number', example: '28', options: [] },
          { name: 'schoolName', required: true, dataType: 'String', example: 'Harvard University' },
          { name: 'schoolURL', required: true, dataType: 'String', example: 'https://www.harvard.edu/' },
          { name: 'description', required: true, dataType: 'String', example: 'This course covers...' },
          { name: 'gradeLevel', dataType: 'String', example: 'All Grade Levels', options: [] },
          { name: 'knowledgeLevel', dataType: 'String', example: 'Beginner', options: [] },
          { name: 'industry', dataType: 'String', example: 'Healthcare', options: [] },
          { name: 'functions', dataType: 'Array', example: 'Customer Service,Art and Design', options: [] },
          { name: 'pathways', dataType: 'Array of Objects', example: "[{ 'sourceId':'12345789', 'name': 'Software Development'},{ 'sourceId':'98765321', 'name': 'IT Support'}]"},
          { name: 'sampleJobTitles', dataType: 'String', example: 'Software Developer, App Developer' },
          { name: 'outcomes', dataType: 'String', example: 'Average salary: $100K, average time to promotion: 1.5 years, etc...' },
          { name: 'mainContactFirstName', dataType: 'String', example: 'Joe' },
          { name: 'mainContactLastName', dataType: 'String', example: 'Schmoe' },
          { name: 'mainContactEmail', dataType: 'String', example: 'joeschmoe@gmail.com' },
          { name: 'mainContactTitle', dataType: 'String', example: 'Teacher' },
          { name: 'pointValue', dataType: 'Number', example: '2', options: [] },
          { name: 'isActive', dataType: 'Boolean', example: 'true' },
          { name: 'isPerpetual', dataType: 'Boolean', example: 'true'},
          { name: 'cohort', dataType: 'String', example: 'January ' + new Date().getFullYear() },
        ]

        // code: String,

        // startTime: Date,
        // endTime: Date,
        // startDate: Date,
        // endDate: Date,
        // isPerpetual: Boolean,
        // enrollmentCount: Number,

        // courses: [],

      } else if (event.target.value === 'Enrollments') {

        acceptableKeys = [
          { name: 'firstName', required: true, dataType: 'String', example: 'Joe' },
          { name: 'lastName', required: true, dataType: 'String', example: 'Schmoe'},
          { name: 'email', required: true, dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'courseId', required: true, dataType: 'String', example: 'h1j2kbkjsbdf3df'},
          { name: 'courseName', required: true, dataType: 'String', example: 'Excel Basics'},
          { name: 'cohort', dataType: 'String', example: 'January ' + new Date().getFullYear() },
          { name: 'isCompleted', required: true, dataType: 'Boolean', example: 'Yes'},
          // { name: 'courseCategory', required: true, dataType: 'String', example: 'Course'},
          // { name: 'courseDegreeType', required: true, dataType: 'String', example: 'Badge'},
          // { name: 'courseSchoolName', required: true, dataType: 'String', example: 'Harvard University'},
          // { name: 'courseSchoolURL', required: true, dataType: 'String', example: 'https://www.harvard.edu'},
          // { name: 'courseDescription', required: true, dataType: 'String', example: 'This course helps you to...'},
          // { name: 'courseEstimatedHours', required: true, dataType: 'String', example: '3 - 5'},
          // { name: 'courseProgramMethod', required: true, dataType: 'String', example: 'Both Online and In-Person'},
          // { name: 'courseDifficultyLevel', required: true, dataType: 'String', example: 'Beginner'},

        ]

        // username: String,

      } else if (event.target.value === 'WorkReady Certifying Users') {

        acceptableKeys = [
          { name: 'firstName', required: true, dataType: 'String', example: 'Joe' },
          { name: 'lastName', required: true, dataType: 'String', example: 'Schmoe'},
          { name: 'email', required: true, dataType: 'String', example: 'joeschmoe@gmail.com'},
          { name: 'postingId', required: true, dataType: 'String', example: 'h1j2kbkjsbdf3df'},
          { name: 'postingTitle', required: true, dataType: 'String', example: 'Cedars Internship'},
          { name: 'createdAt', dataType: 'String', example: convertDateToString(new Date(),"date-2") },
        ]
      }

      this.setState({ [event.target.name]: event.target.value, acceptableKeys, adversityListOptions })

    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  loadData(type,data,acceptedFields) {
    console.log('loadData called ', type, data, acceptedFields)

    this.setState({ isSaving: true, errorMessage: null, successMessage: null, successData: null })

    const orgCode = this.state.org
    // if (data[0].workers) {
    //   console.log('t1: ', typeof data[0].workers)
    //   if (data[0].workers[0]) {
    //     console.log('t2: ', data[0].workers[0].firstName)
    //   }
    // }

    // validate data
    let errorMessage = null
    if (this.state.acceptableKeys && this.state.acceptableKeys.length > 0) {
      for (let i = 1; i <= this.state.acceptableKeys.length; i++) {
        if (this.state.acceptableKeys[i - 1].required) {
          if (data) {
            for (let j = 1; j <= data.length; j++) {
              if (data[j - 1]) {
                if (!data[j - 1][this.state.acceptableKeys[i - 1].name]) {

                  errorMessage = "The following required data is missing from one or more rows. The last row is row " + j.toString() + " of " + data.length + ". They key is " + this.state.acceptableKeys[i - 1].name + "."
                }
              }
            }
          }
        }
      }
    }

    if (!errorMessage && data.length > this.state.uploadLimit) {
      errorMessage = "You are limited to uploading " + this.state.uploadLimit + " rows of data at a time."
    }

    if (errorMessage) {
      this.setState({ isSaving: false, errorMessage })
    } else {
      Axios.post('/api/bulk-upload', { type, data, acceptedFields, orgCode })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Reserve worked', response.data);

          window.scrollTo(0, 0)

          let successMessage = "Successfully bulk-uploaded the data."
          this.setState({ isSaving: false, successMessage, successData: response.data.successData })

        } else {
          console.log('there was an error posting the wbl opportunity');

          let errorMessage = response.data.message
          this.setState({ isSaving: false, errorMessage })
        }
      }).catch((error) => {
          console.log('The postings post did not work', error);
          this.setState({ isSaving: false, errorMessage: error })
      });
    }
  }

  closeModal() {
    console.log('closeModal called')

    this.setState({ modalIsOpen: false, showDataInfo: false })
  }

  renderSelectDataType() {
    console.log('renderSelectDataType called')

    return (
      <div className="row-10 center-text flex-container flex-center" key="renderSelectDataType">
        <div className="max-width-400">
          <label className="profile-label">Select a Data Type<label className="error-color bold-text">*</label></label>
          <select name={"selectedDataType"} className="dropdown" value={this.state.selectedDataType} onChange={this.formChangeHandler}>
            {this.state.dataTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
          </select>
        </div>
      </div>
    )
  }

  exportTemplate(type) {
    console.log('export called', type)

    this.setState({ isSaving: false, errorMessage: null })

    let csvData = [[]]

    // acceptableKeys
    for (let i = 1; i <= this.state.acceptableKeys.length; i++) {
      csvData[0].push(this.state.acceptableKeys[i - 1].name)
    }

    let csvContent = "data:text/csv;charset=utf-8,";
    let csvRows = []
    let headers = csvData[0]
    csvRows.push(headers.join(','))
    console.log('show csvData: ', csvData)

    for (const row of csvData) {
      const values = headers.map((header, index) => {

        if (row[index]) {
          const escaped = (''+row[index]).replace(/"/g,'\\"')
          const literalEscaped = `"${escaped}"`
          console.log('show escaped: ', index, literalEscaped)
          return literalEscaped
        } else {
          console.log('undefined value')
          const undefinedAlt = " "
          return undefinedAlt
        }
      })

      const newRow = values.join(',')
      csvRows.push(newRow)
      csvContent += newRow + "\r\n";

      console.log('show rows: ', csvContent)
    }

    let reportName = type + '-Bulk-Loader.csv'

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", reportName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // this.setState({ isLoading: false })
  }

  render() {

    return (
        <div>
          <div className="standard-container-2">
            <div className="row-10">
              <p className="heading-text-2 full-width center-text">Guided Compass Bulk Uploader</p>
            </div>

            <p className="full-width center-text top-padding-5">Bulk upload data from CSV files.</p>

            <div className="spacer" />

            <div className="row-10 center-text">
              <div className="bottom-padding-30">
                <div className="row-10">
                  {this.renderSelectDataType()}
                </div>

                {(this.state.selectedDataType) && (
                  <div className="row-10">
                    <p className="full-width center-text top-padding-5">Click <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showDataInfo: true })}>here</button> to view the <label className="bold-text">{this.state.selectedDataType}</label> required formatting.</p>
                    <p className="full-width center-text top-padding-5">Click <button className="background-button cta-color" onClick={() => this.exportTemplate(this.state.selectedDataType)}>here</button> to download a <label className="bold-text">{this.state.selectedDataType}</label> CSV template.</p>
                  </div>
                )}

                <div className={(this.state.selectedDataType) ? "row-20" : "row-20 wash-out-2"}>
                  <label for="uploadFile" className="custom-file-upload">
                    Upload CSV File
                  </label>
                  <input type="file" id="uploadFile" name="uploadFile" onChange={this.formChangeHandler} accept=".csv" disabled={(this.state.isSaving || !this.state.selectedDataType) ? true : false} />
                </div>

                <div className="top-margin-20">
                  <p className="description-text-3 center-text">Note: You can only limit <label className="error-color bold-text">{this.state.uploadLimit}</label> rows of data at a time.</p>
                </div>
              </div>

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

              {(this.state.selectedDataType === 'Users') && (
                <div className="top-margin-20">
                  <p className="description-text-3 center-text">If bulk-uploading users who have existing accounts, fields will be updated based on key. Otherwise, user accounts will be created with the temporary password "guidedcompass". Users can change their password from the settings screen of their portal.</p>
                </div>
              )}

              {(this.state.successData) && (
                <div className="row-5 cta-color">
                  <div className="row-5 bold-text">
                    <div className="row-5 description-text-2">
                      <div className="container-left">
                        <div className="calc-column-offset-80">
                          <p className="left-text">{(this.state.selectedDataType === 'Users') ? "Email" : "Name"}</p>
                        </div>
                        <div className="fixed-column-80">
                          <p className="full-width center-text">Newly Created</p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="container-right">
                        <p className="full-width right-text">Data Updated</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  {this.state.successData.map((item,optionIndex) =>
                    <div className="row-5 description-text-2" key={item.email}>
                      {(item.success) ? (
                        <div>
                          <div className="container-left">
                            <div className="calc-column-offset-80">
                              <p className="left-text">{(item.email) ? item.email : item.name}</p>
                            </div>
                            <div className="fixed-column-80">
                              <p className="full-width center-text">{(item.newlyRegistered) ? item.newlyRegistered.toString() : "FALSE"}</p>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="container-right right-text">
                            {(item.acceptedFields) && (
                              <div>
                                {item.acceptedFields.map((item2,optionIndex2) =>
                                  <div className="bottom-margin description-text-2 float-right right-margin-5" key={item2}>
                                    <label>{item2}</label>
                                    {(item.acceptedFields[optionIndex2 + 1]) && (
                                      <label>,</label>
                                    )}
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          <div className="fixed-column-140">
                            <p>{item.email}</p>
                          </div>
                          <div className="calc-column-offset-140">
                            <p className="full-width error-color">{item.message}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

          </div>

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
          >
            {(this.state.showDataInfo) && (
              <div key="showReferSomeone" className="full-width padding-20">
                <p className="heading-text-2 full-width center-text">Required Formatting for CSV File</p>

                <div className="row-10">
                  {this.renderSelectDataType()}

                  {(this.state.acceptableKeys && this.state.acceptableKeys.length > 0) && (
                    <div>
                      <p className="row-10">The following column headers/fields will be accepted by the system: </p>

                      <div className="row-5 bold-text">
                        <div className="relative-column-33">
                          <p>Key</p>
                        </div>
                        <div className="relative-column-33">
                          <p className="full-width center-text">Data Type</p>
                        </div>
                        <div className="relative-column-33">
                          <p className="full-width right-text">Example Data</p>
                        </div>
                        <div className="clear" />
                      </div>
                      {this.state.acceptableKeys.map((item,optionIndex) =>
                        <div className="row-5 description-text-2" key={item.name}>
                          <div className="relative-column-33">
                            <p>{item.name}{(item.required) && <label className="error-color bold-text"> *</label>}</p>
                          </div>
                          <div className="relative-column-33">
                            <p className="full-width center-text">{item.dataType}</p>
                          </div>
                          <div className="relative-column-33">
                            <p className="full-width right-text">{item.example}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                </div>
                {/*
                <div className="row-10">
                  <p>Here's an example: </p>
                </div>*/}
              </div>
            )}
          </Modal>
        </div>

    )
  }
}

export default withRouter(BulkUploader);
