import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubRequestEndorsements from './Subcomponents/RequestEndorsements';
import withRouter from './Functions/WithRouter';

class InviteFriendsForEndorsements extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ email, username, cuFirstName, cuLastName, activeOrg, orgFocus,
        pathway, orgLogo
      })
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate}/>

              <div className="standard-container-2">
                <SubRequestEndorsements />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(InviteFriendsForEndorsements)
