import React, {Component} from 'react';
import Switch from 'react-switch';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import withRouter from '../Functions/WithRouter';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const textIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/text-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class EditAssessment extends Component {
    constructor(props) {
        super(props)

        this.state = {

          _id: '',
          title: '',
          questions: [],
          titles: [],

          typeOptions: ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Linear Scale','Email','Date','Birthdate'],
          assessmentTypeOptions: ['','Survey','Quiz'],
          viewCorrectAnswerOptions: ['','Upon Completion',"Don't Show Correct Answers"],
          shortnameOptions: [],

          formHasChanged: false,

          clientErrorMessage: '',
          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.addPrompt = this.addPrompt.bind(this)
        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.saveAssessment = this.saveAssessment.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.switchClicked = this.switchClicked.bind(this)
        this.calculateConditionNumberOptions = this.calculateConditionNumberOptions.bind(this)
        this.toggleCorrect = this.toggleCorrect.bind(this)
    }

    static defaultProps = { selectedTemplate: null, activeOrg: null }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in EditAssessment', this.props, prevProps)

      if (prevProps.activeOrg !== this.props.activeOrg) {
        console.log('t0')
        this.retrieveData()
      } else if (this.props.assessmentId && !this.props.assessmentId) {
        console.log('t1')
        this.retrieveData()
      } else if (this.props.selectedTemplate && !this.props.selectedTemplate) {
        console.log('t1')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      // script: walkthrough (EditAssessment)
      // title, questionType (how user should answer), placeholder

      // script: conversation (EditResource)
      // title, questionType (whether A.I./admin should answer), placeholder, helper, preparedResponse

      // changes: questionType, preparedResponse

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let selectedTemplate = this.props.selectedTemplate
      let _id = null
      let title = null
      let description = null
      let assessmentType = null
      let timeLimit = null
      let viewCorrectAnswers = null
      let canChangeAnswers = null
      let htmlMode = null
      let questions = []

      const org = this.props.activeOrg

      if (selectedTemplate) {
        if (this.props.duplicate) {
          delete selectedTemplate._id
          selectedTemplate['orgCode'] = org
          selectedTemplate['title'] = selectedTemplate.title + " (Duplicate)"
        }
        _id = selectedTemplate._id
        title = selectedTemplate.title
        description = selectedTemplate.description
        assessmentType = selectedTemplate.type
        timeLimit = selectedTemplate.timeLimit
        viewCorrectAnswers = selectedTemplate.viewCorrectAnswers
        canChangeAnswers = selectedTemplate.canChangeAnswers
        htmlMode = selectedTemplate.htmlMode
        questions = selectedTemplate.questions
      }

      if (email) {
        console.log('show me template', selectedTemplate)

        let readOnly = false
        if (selectedTemplate && selectedTemplate.orgCode === 'general' && org !== 'guidedcompass') {
          readOnly = true
        }

        const shortnameOptions = [
          '','dateOfBirth','gender','race','races','selfDescribedRace','address','zipcode','phoneNumber',
          'alternativePhoneNumber', 'alternativeEmail','numberOfMembers','householdIncome','workAuthorization',
          'adversityList','educationStatus','education|name','education|degree','education|endDate',
          'education|major','referrerName','referrerEmail','referrerOrg'
        ]
        shortnameOptions.sort()

        let assessmentTypeOptions = ['','Survey','Quiz']
        if (org === 'guidedcompass' || email === 'creightontaylor+3@gmail.com') {
          assessmentTypeOptions.push('Script')
        }

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, org,
            _id, selectedTemplate, title, description, assessmentType, timeLimit, viewCorrectAnswers,
            canChangeAnswers, htmlMode,
            questions, readOnly, shortnameOptions, assessmentTypeOptions
        });

        if (this.props.assessmentId) {
          Axios.get('/api/customassessments/byid', { params: { _id: this.props.assessmentId } })
          .then((response) => {
            console.log('Custom assessment by id query attempted', response.data);

            if (response.data.success) {
              console.log('custom assessment query worked')

              let selectedTemplate = response.data.assessment
              let _id = selectedTemplate._id
              let title = selectedTemplate.title
              let questions = selectedTemplate.questions

              let typeOptions = this.state.typeOptions
              if (assessmentType === 'Script') {
                const additionalTypeOptions = ['Chat with Human','Chat with Casper','Search Organizations','Reset Welcome Prompt']
                typeOptions = typeOptions.concat(additionalTypeOptions)
              }

              this.setState({ selectedTemplate, _id, title, questions, typeOptions })

            } else {
              console.log('custom assessment query did not work', response.data.message)
            }
          }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
          });

          // Axios.get('/api/users/profile/details/' + email)
          // .then((response) => {
          //   if (response.data) {
          //
          //     console.log('User details fetch worked', response.data)
          //     if (response.data.success) {
          //
          //       let shortnameOptions = Object.keys(response.data.user)
          //       this.setState({ shortnameOptions });
          //
          //       this.fetchRemainingProfileData(response.data.user.email, response.data.user);
          //
          //     } else {
          //       console.log('there was an error', response.data.message)
          //     }
          //
          //   }
          // }).catch((error) => {
          //     console.log('User details fetch did not work', error);
          // });
        }
      }

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked')

      if (event.target.name === 'title') {
        this.setState({ title: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'assessmentType') {
        let assessmentType = event.target.value
        let typeOptions = ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Linear Scale','Email','Date','Birthdate']
        if (assessmentType === 'Script') {
          const additionalTypeOptions = ['Chat with Human','Chat with Casper','Search Organizations','Reset Welcome Prompt']
          typeOptions = typeOptions.concat(additionalTypeOptions)
        }

        this.setState({ assessmentType, typeOptions, formHasChanged: true })
      } else if (event.target.name === 'timeLimit') {
        this.setState({ timeLimit: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'viewCorrectAnswers') {
        this.setState({ viewCorrectAnswers: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'canChangeAnswers') {
        this.setState({ canChangeAnswers: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'htmlMode') {
        this.setState({ htmlMode: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'description') {
        this.setState({ description: event.target.value, formHasChanged: true })
      } else {
        for (let i = 1; i <= this.state.questions.length; i++) {

          let eventName = 'question' + i.toString()
          let typeName = 'type' + i.toString()
          let promptName = 'prompt' + i.toString()
          let conditionNumber = 'conditionNumber' + i.toString()
          let conditionValue = 'conditionValue' + i.toString()
          let answerChoiceName = 'answerChoice' + i.toString()
          let placeholderName = "placeholder" + i.toString()
          let shortnameName = "shortname" + i.toString()
          // let firstValueName = "firstValue" + i.toString()
          // let midValueName = "midValue" + i.toString()
          // let lastValueName = "lastValue" + i.toString()

          // console.log(i, 'updating questions', event.target.name, this.state.questions);
          if ( event.target.name === eventName ) {
            let questions = this.state.questions
            questions[i - 1]['name'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === typeName ) {
            let questions = this.state.questions
            questions[i - 1]['questionType'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === promptName ) {
            let questions = this.state.questions
            questions[i - 1]['prompt'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === conditionNumber ) {
            let questions = this.state.questions
            questions[i - 1]['conditionNumber'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === conditionValue ) {
            let questions = this.state.questions
            questions[i - 1]['conditionValue'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === promptName ) {
            let questions = this.state.questions
            questions[i - 1]['prompt'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name.startsWith(answerChoiceName) ) {
            console.log('got in answer choices', i, event.target.name)

            let answerChoices = this.state.questions[i - 1].answerChoices
            console.log('gimme answerChoices', answerChoices)

            let stringArray = event.target.name.split("|")

            //let stringEnd = event.target.name.length

            //let choiceIndex = Number(event.target.name.substring(13,stringEnd)) - 1
            let choiceIndex = Number(stringArray[1]) - 1

            console.log('gimme choiceIndex', choiceIndex)
            answerChoices[choiceIndex] = event.target.value
            console.log('gimme answerChoices one mo gain', answerChoices)

            // questions[parentIndex]['correctAnswer'] = questions[parentIndex]['answerChoices'][index]
            // questions[parentIndex]['correctParentIndex'] = parentIndex
            // questions[parentIndex]['correctIndex'] = index

            let questions = this.state.questions
            questions[i - 1]['answerChoices'] = answerChoices
            if (questions[i - 1].correctAnswer && questions[i - 1].correctIndex === choiceIndex) {
              questions[i - 1]['correctAnswer'] = event.target.value
            }

            this.setState({ questions, formHasChanged: true })

          } else if ( event.target.name === placeholderName ) {
            let questions = this.state.questions
            questions[i - 1]['placeholder'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === shortnameName ) {
            let questions = this.state.questions
            questions[i - 1]['shortname'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          // } else if (event.target.name === firstValueName ) {
          //   let questions = this.state.questions
          //   questions[i - 1]['firstValue'] = event.target.value
          //   this.setState({ questions, formHasChanged: true })
          // } else if (event.target.name === midValueName ) {
          //   let questions = this.state.questions
          //   questions[i - 1]['midValue'] = event.target.value
          //   this.setState({ questions, formHasChanged: true })
          // } else if (event.target.name === lastValueName ) {
          //   let questions = this.state.questions
          //   questions[i - 1]['lastValue'] = event.target.value
          //   this.setState({ questions, formHasChanged: true })
          }
        }
      }
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const questions = reorder(
        this.state.questions,
        result.source.index,
        result.destination.index
      );

      this.setState({ questions, formHasChanged: true });

      // if (this.state.questions.includes(result.draggableId)) {
      //   const questions = reorder(
      //     this.state.questions,
      //     result.source.index,
      //     result.destination.index
      //   );
      //
      //   this.setState({ questions });
      // } else if (this.state.titles.includes(result.draggableId)) {
      //   const titles = reorder(
      //     this.state.titles,
      //     result.source.index,
      //     result.destination.index
      //   );
      //
      //   this.setState({ titles });
      // }

    }

    saveAssessment() {
      console.log('saveAssessment clicked');

      this.setState({
        clientErrorMessage: '', errorMessage: null
      })

      if (this.state.formHasChanged === false) {
        this.setState({ clientErrorMessage: 'no changes detected. please update and then save.' })
      } else if (this.state.title === '') {
        this.setState({ clientErrorMessage: 'please add a title' })
      } else if (this.state.questions.length === 0) {
        this.setState({ clientErrorMessage: 'please add a question to your assessment' })
      } else if (this.state.questions[0].name === '') {
        this.setState({ clientErrorMessage: 'please add at least one question to your assessment' })
      } else {
        console.log('about to save', this.state.questions)

        const _id = this.state._id
        const title = this.state.title
        const type = this.state.assessmentType
        const description = this.state.description
        const timeLimit = this.state.timeLimit
        const viewCorrectAnswers = this.state.viewCorrectAnswers
        const canChangeAnswers = this.state.canChangeAnswers
        const htmlMode = this.state.htmlMode

        const questions = this.state.questions

        const orgCode = this.state.org

        const createdAt = new Date()
        const updatedAt = new Date()

        Axios.post('/api/customassessments', {
          _id, title, type, description, timeLimit, viewCorrectAnswers, canChangeAnswers, htmlMode,
          questions, orgCode, createdAt, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Assessment save worked', response.data);

            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Assessment template saved successfully!'
            })

            if ((this.props.duplicate && this.props.navigate.goBack) || (window.location.pathname.endsWith('/add'))) {
              this.props.navigate(-1)
            }

          } else {
            console.error('there was an error saving the assessment template');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Assessment save did not work', error);
        });
      }
    }

    renderQuestions() {
      console.log('renderQuestions called')

      let rows = [];

      if (this.state.questions && this.state.questions.length > 0) {

        let tryDnd = true
        if (tryDnd) {
          rows.push(
            <div key="questions" className="row-5">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.questions.map((item, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <div className="padding-5 standard-color">
                                  {(item.prompt || item.prompt === '') && (
                                    <div className="bottom-padding-20">
                                      <div className="close-button-container-2" >
                                        <button type="button" onClick={() => this.deleteItem('prompt', index)} className="background-button">
                                          <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                        </button>
                                      </div>
                                      <div className="left-padding top-padding">
                                        <div className="highlighted-container">
                                          <p>Section Description - Add a Prompt for the Next Section</p>
                                          <textarea type="text" className="text-field" placeholder="Add a description for the next section..." name={"prompt" + (index + 1).toString()} value={item.prompt} onChange={this.formChangeHandler}></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="close-button-container-2" >
                                    <button type="button" onClick={() => this.deleteItem('question', index)} className="background-button">
                                      <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                    </button>
                                  </div>

                                  <div className="left-padding">
                                    <div className="highlighted-container">
                                      <div className="row-5">
                                        <div className="calc-column-offset-100">
                                          <p>Question #{index + 1}</p>
                                        </div>
                                        <div className="fixed-column-100">
                                          <div>
                                            <img src={dragIcon} alt="GC" className="image-auto-15 pin-right" />
                                          </div>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="row-10">
                                        <input type="text" className="text-field" placeholder="Create a question..." name={"question" + (index + 1).toString()} value={this.state.questions[index].name} onChange={this.formChangeHandler}></input>
                                      </div>

                                      <div className="row-10">
                                        <select name={"type" + (index + 1).toString()} value={this.state.questions[index].questionType} onChange={this.formChangeHandler} className="dropdown">
                                          {this.state.typeOptions.map(value =>
                                            <option key={value.key} value={value}>{value}</option>
                                          )}
                                        </select>
                                      </div>

                                      {(this.state.questions[index].questionType === 'Multiple Choice'  || this.state.questions[index].questionType === 'Checkbox' || this.state.questions[index].questionType === 'Ranking' || this.state.questions[index].questionType === 'Linear Scale') ? (
                                        <div>
                                          {(this.state.questions[index].questionType === 'Linear Scale') && (
                                            <div className="top-margin">
                                              <div className="flex-container flex-space-between row-direction">
                                                <div className="width-20 height-20 circle-radius medium-background dark-border" />
                                                <div className="width-20 height-20 circle-radius medium-background dark-border" />
                                                <div className="width-20 height-20 circle-radius medium-background dark-border" />
                                                <div className="width-20 height-20 circle-radius medium-background dark-border" />
                                                <div className="width-20 height-20 circle-radius medium-background dark-border" />
                                              </div>
                                              <div className="top-margin-negative-20">
                                                <div className="dark-border-bottom top-margin height-1 full-width" />
                                              </div>
                                            </div>
                                          )}

                                          <div className="spacer" />

                                          {(this.state.questions[index].questionType === 'Linear Scale') ? (
                                            <div>
                                              <div className="flex-container flex-space-between row-direction description-text-2">
                                                <p>{(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[0]) ? this.state.questions[index].answerChoices[0] : "Least/most value"}</p>
                                                <p className="center-text">{(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[1]) ? this.state.questions[index].answerChoices[1] : "Lower Mid value"}</p>
                                                <p className="center-text">{(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[2]) ? this.state.questions[index].answerChoices[2] : "Mid value"}</p>
                                                <p className="center-text">{(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[3]) ? this.state.questions[index].answerChoices[3] : "Higher Mid value"}</p>
                                                <p className="right-text">{(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[4]) ? this.state.questions[index].answerChoices[4] : "Least/most value"}</p>
                                              </div>

                                              {console.log('dflkjsd', )}

                                              <div className="flex-container row-direction description-text-2 row-15">
                                                <div className="flex-20 right-padding-5">
                                                  <input type="text" className="text-field" placeholder="First value label..." name={"answerChoice" + (index + 1) + "|1"} value={(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[0]) ? this.state.questions[index].answerChoices[0] : ''} onChange={this.formChangeHandler}></input>
                                                </div>
                                                <div className="flex-20 horizontal-padding-7 center-text">
                                                  <input type="text" className="text-field center-text" placeholder="Lower mid value label..." name={"answerChoice" + (index + 1) + "|2"} value={(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[1]) ? this.state.questions[index].answerChoices[1] : ''} onChange={this.formChangeHandler}></input>
                                                </div>
                                                <div className="flex-20 horizontal-padding-7 center-text">
                                                  <input type="text" className="text-field center-text" placeholder="Mid value label..." name={"answerChoice" + (index + 1) + "|3"} value={(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[2]) ? this.state.questions[index].answerChoices[2] : ''} onChange={this.formChangeHandler}></input>
                                                </div>
                                                <div className="flex-20 horizontal-padding-7 center-text">
                                                  <input type="text" className="text-field center-text" placeholder="Upper mid value label..." name={"answerChoice" + (index + 1) + "|4"} value={(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[3]) ? this.state.questions[index].answerChoices[3] : ''} onChange={this.formChangeHandler}></input>
                                                </div>
                                                <div className="flex-20 left-padding-5">
                                                  <input type="text" className="text-field right-text" placeholder="Last value label..." name={"answerChoice" + (index + 1) + "|5"} value={(this.state.questions[index].answerChoices && this.state.questions[index].answerChoices[4]) ? this.state.questions[index].answerChoices[4] : ''} onChange={this.formChangeHandler}></input>
                                                </div>
                                              </div>

                                            </div>
                                          ) : (
                                            <div>
                                              {this.renderAnswerChoices(index + 1, this.state.questions[index].answerChoices)}

                                              <div className="top-padding-20">
                                                <hr />
                                                <a className="background-button" onClick={() => this.addAnswerChoice(index)}>
                                                  <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.questions[index].questionType === 'Short Answer' || this.state.questions[index].questionType === 'Long Answer' || this.state.questions[index].questionType === 'Chat with Human' || this.state.questions[index].questionType === 'Chat with Casper' || this.state.questions[index].questionType === 'Search Organizations') && (
                                            <div className="row-10 bottom-margin">
                                              <input type="text" className="text-field" placeholder="Add placeholder text for the answer..." name={"placeholder" + (index + 1)} value={this.state.questions[index].placeholder} onChange={this.formChangeHandler}></input>
                                            </div>
                                          )}

                                          {(this.state.questions[index].questionType === 'Long Answer') ? (
                                            <p className="fake-text-area">{(this.state.questions[index].placeholder) ? this.state.questions[index].placeholder : "Candidates will write long answer here..."}</p>
                                          ) : (
                                            <div>
                                              <p className="fake-text-field">{(this.state.questions[index].placeholder) ? this.state.questions[index].placeholder : "Candidates will write answer here..."}</p>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      <div className="top-padding-20">
                                        <hr />

                                        <div className="top-padding-20">
                                          <div className="flex-container justify-end">
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Required</p>
                                            </div>
                                            <div className="right-padding-20">
                                              <Switch
                                                onChange={(change) => this.switchClicked('required',index, change)}
                                                checked={this.state.questions[index].required}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Conditional</p>
                                            </div>
                                            <div className="right-padding-20">
                                              <Switch
                                                onChange={(change) => this.switchClicked('conditional',index, change)}
                                                checked={this.state.questions[index].conditional}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Map to Profile</p>
                                            </div>
                                            <div>
                                              <Switch
                                                onChange={(change) => this.switchClicked('mapToProfile',index, change)}
                                                checked={this.state.questions[index].mapToProfile}
                                                id="normal-switch"
                                              />
                                            </div>
                                          </div>

                                          {(this.state.questions[index].conditional) && (
                                            <div className="top-padding">
                                              <div className="top-padding flex-container row-direction">
                                                <div className="flex-50">
                                                  <p>Condition Question Number:</p>
                                                </div>
                                                <div className="flex-50">
                                                  <select name={"conditionNumber" + (index + 1)} value={this.state.questions[index].conditionNumber} onChange={this.formChangeHandler} className="dropdown">
                                                    {this.calculateConditionNumberOptions().map(value =>
                                                      <option key={value.key} value={value}>{value}</option>
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                              <div className="top-padding flex-container row-direction">
                                                <div className="flex-50">
                                                  <p>Condition Value:</p>
                                                </div>
                                                <div className="flex-50">
                                                  <input type="text" className="text-field" placeholder="Value required to render question..." name={"conditionValue" + (index + 1)} value={this.state.questions[index].conditionValue} onChange={this.formChangeHandler}></input>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {(this.state.questions[index].mapToProfile) && (
                                            <div className="top-padding">
                                              <div className="top-padding flex-container row-direction">
                                                <div className="flex-50">
                                                  <p>Select the Profile Field:</p>
                                                  <p className="description-text-2 description-text-color top-padding-5">(Answers will be saved to the user's profile)</p>
                                                </div>
                                                <div className="flex-50">
                                                  <select name={"shortname" + (index + 1)} value={this.state.questions[index].shortname} onChange={this.formChangeHandler} className="dropdown">
                                                    {this.state.shortnameOptions.map(value =>
                                                      <option key={value} value={value}>{value}</option>
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                      </div>

                                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
          )
        } else {
          for (let i = 1; i <= this.state.questions.length; i++) {
            console.log(i);

            const index = i - 1

            let eventName = "question" + i.toString()
            let typeName = "type" + i.toString()

            rows.push(
              <div key={i} className="row-5">
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('question', index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Question #{i}</p>
                    </div>

                    <div className="row-10">
                      <div className="name-container">
                        <input type="text" className="text-field" placeholder="Create a question..." name={eventName} value={this.state.questions[i - 1].name} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="name-container">
                        <select name={typeName} value={this.state.questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.typeOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear"/>
                    </div>

                    {(this.state.questions[i - 1].questionType === 'Multiple Choice'  || this.state.questions[i - 1].questionType === 'Checkbox' || this.state.questions[i - 1].questionType === 'Ranking' || this.state.questions[i - 1].questionType === 'Linear Scale') ? (
                      <div>
                        <div className="spacer" />
                        {this.renderAnswerChoices(i, this.state.questions[i - 1].answerChoices)}

                        <div className="top-padding-20">
                          <hr />
                          <a className="background-button" onClick={() => this.addAnswerChoice(i - 1)}>
                            <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                          </a>
                        </div>

                      </div>
                    ) : (
                      <div>
                        {(this.state.questions[i - 1].questionType === 'Long Answer') ? (
                          <p className="fake-text-area">Candidates will write long answer here...</p>
                        ) : (
                          <div>
                            <p className="fake-text-field">Candidates will write {this.state.questions[i - 1].questionType.toLowerCase()} here...</p>
                          </div>
                        )}
                      </div>
                    )}
                    {/*
                    <div>
                      <div className="float-left right-padding">
                        <p>Would you like to add a prompt to introduce the next section?</p>
                      </div>
                      <div className="float-left">
                        <Switch
                          onChange={(change) => this.editItem('prompt',index)}
                          checked={this.state.questions[i - 1].addPrompt}
                          id="normal-switch"
                        />
                      </div>
                      <div className="clear" />
                    </div>*/}
                  </div>
                </div>

              </div>
            )
          }
        }
      }

      return rows;

    }

    deleteItem(itemType, index, parentIndex) {
      console.log('deleteItem called', itemType, index, parentIndex)

      if (itemType === 'question') {
        let questions = this.state.questions
        questions.splice(index, 1)
        this.setState({ questions, formHasChanged: true })
      } else if (itemType === 'answerChoice') {
        let questions = this.state.questions
        let answerChoices = questions[parentIndex].answerChoices

        answerChoices.splice(index,1)

        questions[parentIndex]['answerChoices'] = answerChoices
        this.setState({ questions, formHasChanged: true })

      } else if (itemType === 'prompt') {
        let questions = this.state.questions
        delete questions[index].prompt
        this.setState({ questions, formHasChanged: true })
      }
    }

    renderAnswerChoices(iCount, answerChoices) {
      console.log('renderAnswerChoices', iCount)
      const parentIndex = iCount - 1

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        const index = i - 1

        let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()

        rows.push(
          <div key={key}>
            {(this.state.readOnly) ? (
              <div>
                <p className="full-width row-5">{i}. {answerChoices[i - 1]}</p>
              </div>
            ) : (
              <div>
                <div className="fixed-column-40 bottom-margin-15" >
                  <button type="button" onClick={() => this.deleteItem('answerChoice', index, parentIndex)} className="background-button">
                    <img src={deniedIcon} className="image-auto-30 padding-5 top-margin-5" alt="GC" />
                  </button>
                </div>

                <div className={(this.state.assessmentType === 'Quiz') ? "calc-column-offset-90 bottom-margin-15" : "calc-column-offset-40 bottom-margin-15"}>
                  <input type="text" className="adjacent-text-field" placeholder="Create an answer choice..." name={eventName} value={answerChoices[i - 1]} onChange={this.formChangeHandler}></input>
                </div>
                {(this.state.assessmentType === 'Quiz') && (
                  <div className="fixed-column-50 bottom-margin-15 left-padding" >
                    <button type="button" onClick={() => this.toggleCorrect('answerChoice', index, parentIndex)} className="background-button top-margin left-margin">
                      {(this.state.questions[parentIndex].correctAnswer === answerChoices[i - 1]) ? (
                        <img src={checkmarkIcon} className="image-auto-20" alt="GC"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Undo mark as correct answer"/>
                      ) : (
                        <div className="width-20 height-20 thick-error-border" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark answer as correct"/>
                      )}

                    </button>
                  </div>
                )}
                <div className="clear" />
                <ReactTooltip id="tooltip-placeholder-id" />

              </div>
            )}
          </div>
        )
      }

      return rows
    }

    addQuestion() {
      console.log('addQuestion called')

      let questions = this.state.questions
      questions.push({ name: '', questionType:'Short Answer', answerChoices: []})
      this.setState({ questions })

      window.scrollTo(0,document.body.scrollHeight);
    }

    addPrompt() {
      console.log('addPrompt called')

      let questions = this.state.questions
      questions.push({ name: '', questionType:'Short Answer', answerChoices: [], prompt: ''})
      this.setState({ questions })
    }

    addAnswerChoice(index) {
      console.log('addAnswerChoice called', index)

      let answerChoices = this.state.questions[index].answerChoices
      answerChoices.push('')

      let questions = this.state.questions
      questions[index]['answerChoices'] = answerChoices


      this.setState({ questions })
    }

    switchClicked(name, index, value) {
      console.log('switchClicked called', name, index, value)

      let questions = this.state.questions
      questions[index][name] = value
      this.setState({ questions, formHasChanged: true })

    }

    calculateConditionNumberOptions() {
      console.log('calculateConditionNumberOptions called')

      let conditionNumberOptions = ['']
      for (let i = 1; i <= this.state.questions.length; i++) {
        conditionNumberOptions.push(i)
      }

      return conditionNumberOptions
    }

    toggleCorrect(type,index,parentIndex) {
      console.log('toggleCorrect called', type, index, parentIndex)

      this.setState({ errorMessage: null })

      let questions = this.state.questions
      if (questions[parentIndex]['answerChoices'][index] === undefined || questions[parentIndex]['answerChoices'][index] === null || questions[parentIndex]['answerChoices'][index] === "") {
        console.log('error: write an answer')
        this.setState({ errorMessage: "Write an answer choice before selecting it as the correct answer" })
      } else {
        if (questions[parentIndex]['correctAnswer'] === questions[parentIndex]['answerChoices'][index]) {
          questions[parentIndex]['correctAnswer'] = null
          questions[parentIndex]['correctParentIndex'] = null
          questions[parentIndex]['correctIndex'] = null
        } else {
          questions[parentIndex]['correctAnswer'] = questions[parentIndex]['answerChoices'][index]
          questions[parentIndex]['correctParentIndex'] = parentIndex
          questions[parentIndex]['correctIndex'] = index
        }
        console.log('gimme da value: ', questions[parentIndex].correctAnswer, questions[parentIndex]['answerChoices'][index])

        this.setState({ questions, formHasChanged: true })
      }

      // this.state.questions[parentIndex].correctAnswer === answerChoices[i - 1]
    }

    render() {

      return (
          <div>
              <div className="standard-container-2">
                <div>
                  <div className="spacer" /><div className="spacer" />
                  <p className="heading-text-2">{(this.state.selectedTemplate) ? "Edit" : "New"} Assessment</p>
                  <p className="description-text-color top-padding">Create and save a template that you can add to your job postings.</p>

                  {(this.state.selectedTemplate) && (
                    <div>
                      {(this.state.readOnly) ? (
                        <p className="error-color description-text-2 bold-text top-padding">This assessment is read-only. Contact help@guidedcompass.com to request changes.</p>
                      ) : (
                        <p className="error-color description-text-2 bold-text top-padding">After the assessment goes live, any changes will create a descrepancy in candidate answers for reporting purposes.</p>
                      )}
                    </div>
                  )}

                </div>

                <div className="row-10 top-margin">
                  <label className="profile-label">Assessment Title</label>
                  {(this.state.readOnly) ? (
                    <p className="description-text-1">{this.state.title}</p>
                  ) : (
                    <div>
                      <input type="text" className="text-field" placeholder="Title your assessment for your reference..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                    </div>
                  )}
                </div>

                {((this.state.readOnly && this.state.assessmentType) || !this.state.readOnly) && (
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Type</label>
                      {(this.state.readOnly) ? (
                        <p className="description-text-1">{this.state.assessmentType}</p>
                      ) : (
                        <select name="assessmentType" value={this.state.assessmentType} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.assessmentTypeOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      )}
                    </div>
                    {(this.state.assessmentType === 'Quiz') && (
                      <div className="container-right">
                        <label className="profile-label">Time Limit (in Minutes)</label>
                        {(this.state.readOnly) ? (
                          <p className="description-text-1">{this.state.timeLimit}</p>
                        ) : (
                          <input type="number" className="number-field" placeholder="0" name="timeLimit" value={this.state.timeLimit} onChange={this.formChangeHandler}></input>
                        )}
                      </div>
                    )}
                    {(this.state.assessmentType === 'Script') && (
                      <div className="container-right">
                        <label className="profile-label">Questions Written in HTML?</label>
                        <Switch
                          onChange={(change) => this.setState({ htmlMode: change, formHasChanged: true })}
                          checked={this.state.htmlMode}
                          id="normal-switch"
                          disabled={(this.state.readOnly) ? true : false}
                        />
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                )}

                {(this.state.assessmentType === 'Quiz') && (
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">View Correct Answers</label>
                      {(this.state.readOnly) ? (
                        <p className="description-text-1">{this.state.viewCorrectAnswers}</p>
                      ) : (
                        <select name="viewCorrectAnswers" value={this.state.viewCorrectAnswers} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.viewCorrectAnswerOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      )}
                    </div>
                    <div className="container-left">
                      <label className="profile-label">Can Change Answers After Submission</label>
                      <Switch
                        onChange={(change) => this.setState({ canChangeAnswers: change, formHasChanged: true })}
                        checked={this.state.canChangeAnswers}
                        id="normal-switch"
                        disabled={(this.state.readOnly) ? true : false}
                      />
                    </div>
                    <div className="clear" />
                  </div>
                )}

                <div className="row-10">
                  <label className="profile-label">Description (Optional)</label>
                  <textarea type="text" className="text-field" placeholder="Add a description for the assessment..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
                </div>

                <label className="profile-label">Questions</label>

                {(this.state.readOnly) ? (
                  <div>
                    {(this.state.questions) && (
                      <div>
                        {this.state.questions.map((value,index) =>
                          <div>
                            <div className="padding-5 standard-color">
                              <div className="left-padding">
                                <div className="highlighted-container">
                                  <div className="row-5">
                                    <p>Question #{index + 1}</p>
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <p>{value.name}</p>
                                    </div>
                                    <div className="container-right">
                                      <p>{value.questionType}</p>
                                    </div>
                                    <div className="clear"/>
                                  </div>

                                  {(this.state.questions[index].questionType === 'Multiple Choice'  || this.state.questions[index].questionType === 'Checkbox' || this.state.questions[index].questionType === 'Ranking' || this.state.questions[index].questionType === 'Linear Scale') ? (
                                    <div>
                                      <div className="spacer" />
                                      {this.renderAnswerChoices(index + 1, this.state.questions[index].answerChoices)}

                                      {(!this.state.readOnly) && (
                                        <div className="top-padding-20">
                                          <hr />
                                          <a className="background-button" onClick={() => this.addAnswerChoice(index)}>
                                            <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                                          </a>
                                        </div>
                                      )}

                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.questions[index].questionType === 'Long Answer') ? (
                                        <p className="fake-text-area">Candidates will write long answer here...</p>
                                      ) : (
                                        <div>
                                          <p className="fake-text-field">Candidates will write {this.state.questions[index].questionType.toLowerCase()} here...</p>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <div className="top-padding-20">
                                    <hr />

                                    <div className="top-padding-20">
                                      <div className="flex-container justify-end">
                                        <div className="right-padding-5">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <p>Required</p>
                                        </div>
                                        <div className="right-padding-20">
                                          <Switch
                                            onChange={(change) => this.switchClicked('required',index, change)}
                                            checked={this.state.questions[index].required}
                                            id="normal-switch"
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="right-padding-5">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <p>Conditional</p>
                                        </div>
                                        <div className="right-padding-20">
                                          <Switch
                                            onChange={(change) => this.switchClicked('conditional',index, change)}
                                            checked={this.state.questions[index].conditional}
                                            id="normal-switch"
                                            disabled={true}
                                          />
                                        </div>
                                        <div className="right-padding-5">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <p>Map to Profile</p>
                                        </div>
                                        <div>
                                          <Switch
                                            onChange={(change) => this.switchClicked('mapToProfile',index, change)}
                                            checked={this.state.questions[index].mapToProfile}
                                            id="normal-switch"
                                            disabled={true}
                                          />
                                        </div>
                                      </div>

                                      {(this.state.questions[index].conditional) && (
                                        <div className="top-padding">
                                          <div className="top-padding flex-container row-direction">
                                            <div className="flex-50">
                                              <p>Condition Question Number:</p>
                                            </div>
                                            <div className="flex-50">
                                              <select name={"conditionNumber" + (index + 1)} value={this.state.questions[index].conditionNumber} onChange={this.formChangeHandler} className="dropdown">
                                                {this.calculateConditionNumberOptions().map(value =>
                                                  <option key={value.key} value={value}>{value}</option>
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="top-padding flex-container row-direction">
                                            <div className="flex-50">
                                              <p>Condition Value:</p>
                                            </div>
                                            <div className="flex-50">
                                              <input type="text" className="text-field" placeholder="Value required to render question..." name={"conditionValue" + (index + 1)} value={this.state.questions[index].conditionValue} onChange={this.formChangeHandler}></input>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {this.renderQuestions()}

                    {(this.state.questions && this.state.questions.length > 0) ? (
                      <div>
                        { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <p className="error-message">{this.state.serverErrorMessage}</p>
                        )}

                        <button className="btn btn-primary" onClick={() => this.saveAssessment()}>Save Assessment</button>
                      </div>
                    ) : (
                      <div>
                        <p className="error-color">Click the plus button to the right to add a question</p>
                      </div>
                    )}
                  </div>
                )}

              </div>

              {(!this.state.readOnly) && (
                <div className="fixed-side-bar">
                  <div className="row-10">
                    <button className="background-button add-task-link-container" onClick={() => this.addQuestion()}>
                      <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a question" />
                    </button>
                  </div>

                  <div className="row-10">
                    <button className="background-button add-task-link-container" onClick={() => this.addPrompt()}>
                      <img src={textIcon} alt="Compass add circle outline icon" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a short description"/>
                    </button>
                  </div>

                </div>
              )}
          </div>

      )
    }
}

export default withRouter(EditAssessment);
