import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';

const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class SwitchOrgs extends Component {
    constructor(props) {
      super(props)
      this.state = {
        myOrgs: [], academies: [], dataToShare: [], sharePartners: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)

      this.renderOrganizations = this.renderOrganizations.bind(this)
      this.orgClicked = this.orgClicked.bind(this)
      this.addOrg = this.addOrg.bind(this)
      this.removeOrg = this.removeOrg.bind(this)
      this.confirmOrg = this.confirmOrg.bind(this)
      this.renderDataToShare = this.renderDataToShare.bind(this)
      this.pullOrgDetails = this.pullOrgDetails.bind(this)
      this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within switchOrgs', this.props.accountCode)

      const emailId = this.props.emailId
      const activeOrg = this.props.activeOrg
      let myOrgs = this.props.myOrgs
      if (!myOrgs) {
        myOrgs = ['guidedcompass']
      } else {
        if (!myOrgs.includes('guidedcompass')) {
          myOrgs.unshift('guidedcompass')
        }
      }

      let sharePartners = this.props.sharePartners
      if (!sharePartners) {
        sharePartners = []
      }

      const roleName = this.props.roleName

      let academies = this.props.academies
      if (!academies) {
        academies = []
      }

      let academyCodes = this.props.academyCodes
      if (!academyCodes) {
        academyCodes = []
      }

      const accountCode = this.props.accountCode

      let dataToShare = [
        'Name', 'Profile Photo','Resume URL','LinkedIn URL','Portfolio URL','School','Major / Pathway','Graduation Year','Race','Gender','Veteran Status','Work Authorization','Projects','Experience','Career Assessments','Endorsements'
      ]

      this.setState({ emailId, activeOrg, myOrgs, sharePartners, roleName, academies, academyCodes, accountCode, dataToShare })

      let isEmployer = false
      if (roleName === 'Employer' || roleName === 'Employer Representative') {
        isEmployer = true
      }

      if (window.location.pathname.includes('/employers') || isEmployer) {
        let self = this
        function pullAccountInformation(passedAccountCode) {
          console.log('pullAccountInformation called from switchOrgs', passedAccountCode)

          Axios.get('/api/account', { params: { accountCode: passedAccountCode } })
          .then((response) => {
            console.log('Account info query attempted within sub settings', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              const officeType = response.data.accountInfo.officeType
              const politicalAlignment = response.data.accountInfo.politicalAlignment
              const stateRegistration = response.data.accountInfo.stateRegistration
              const congressionalDistrict = response.data.accountInfo.congressionalDistrict
              const hasFunds = response.data.accountInfo.hasFunds
              const hostUndocumented = response.data.accountInfo.hostUndocumented
              const hireTimeframes = response.data.accountInfo.hireTimeframes
              const employerLogoPath = response.data.accountInfo.employerLogoURI
              const employerLogoWhitePath = response.data.accountInfo.employerLogoWhiteURI

              self.setState({
                  isIntermediary: response.data.accountInfo.isIntermediary,
                  companyName: response.data.accountInfo.employerName,
                  companyURL: response.data.accountInfo.employerURL,
                  companyType: response.data.accountInfo.employerType,
                  companyIndustry: response.data.accountInfo.employerIndustry,
                  employeeCount: response.data.accountInfo.employeeCount,
                  employeeGrowth: response.data.accountInfo.employeeGrowth,
                  sharePartners: response.data.accountInfo.sharePartners,
                  employerLocation: response.data.accountInfo.employerLocation,

                  contactFirstName: response.data.accountInfo.contactFirstName,
                  contactLastName: response.data.accountInfo.contactLastName,
                  contactTitle: response.data.accountInfo.contactTitle,
                  contactEmail: response.data.accountInfo.contactEmail,
                  contactPhone: response.data.accountInfo.contactPhone,
                  description: response.data.accountInfo.description,
                  employerCulture: response.data.accountInfo.employerCulture,

                  officeType, politicalAlignment, stateRegistration, congressionalDistrict, hasFunds, hostUndocumented,
                  hireTimeframes, employerLogoPath, employerLogoWhitePath
              });
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });
        }
        if (accountCode && accountCode !== '') {
          pullAccountInformation(accountCode)
        } else {
          // get accountCode from user profile

          if (emailId) {
            Axios.get('/api/users/profile/details', { params: { email: emailId } })
            .then((response) => {

                if (response.data.success) {
                  console.log('User profile query worked', response.data);

                  const newAccountCode = response.data.user.accountCode
                  console.log('did we get: ', newAccountCode)
                  if (newAccountCode) {
                    pullAccountInformation(newAccountCode)
                  } else {
                    const errorMessage = 'there was an error finding your account'
                    console.log(errorMessage)
                    this.setState({ errorMessage })
                  }

                } else {
                  console.log('no user details found', response.data.message)

                }

            }).catch((error) => {
                console.log('User profile query did not work', error);
            });
          }
        }

      } else {
        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const academies = response.data.orgInfo.academies

              console.log('show academies: ', academies)
              let academyCodes = []
              if (academies && academies.length > 0) {
                for (let i = 1; i <= academies.length; i++) {
                  academyCodes.push(academies[i - 1].orgCode)
                }
              }

              this.setState({ orgName, academies, academyCodes });

              // let courses = []
              // if (response.data.orgInfo.courseIds && response.data.orgInfo.courseIds.length > 0) {
              //   console.log('courses exist')
              //
              // }

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {

            if (response.data.success) {
              console.log('User profile query worked', response.data);

              const firstNameValue = response.data.user.firstName
              const lastNameValue = response.data.user.lastName
              const linkedInURLValue = response.data.user.linkedInURL
              const resumeURLValue = response.data.user.resumeURL
              const customWebsiteURLValue = response.data.user.customWebsiteURL
              const jobTitle = response.data.user.jobTitle
              const employerName = response.data.user.employerName
              const workZipcode = response.data.user.workZipcode
              const workTenure = response.data.user.workTenure
              const overallFit = response.data.user.overallFit
              const degreeAttained = response.data.user.degreeAttained
              const schoolName = response.data.user.schoolName
              const studyFields = response.data.user.studyFields
              const myOrgs = response.data.user.myOrgs

              this.setState({
                firstNameValue, lastNameValue, linkedInURLValue, resumeURLValue, customWebsiteURLValue,
                jobTitle, employerName, workZipcode, workTenure, overallFit, degreeAttained, schoolName, studyFields,
                myOrgs
              });

              const courseIds = response.data.user.courseIds

              if (courseIds && courseIds.length > 0 && courseIds[0]) {
                // pull from courses

                Axios.get('/api/courses', { params: { courseIds } })
                .then((response) => {

                    if (response.data.success) {
                      console.log('Courses query worked', response.data);

                      const courses = response.data.courses
                      this.setState({ courses })

                    } else {
                      console.log('no course details found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('course query did not work', error);
                });

                Axios.get('/api/grades', { params: { orgCode: activeOrg, courseIds } })
                .then((response) => {
                  console.log('Grades query attempted', response.data);

                    if (response.data.success) {
                      console.log('grades query worked')

                      const grades = response.data.grades
                      const students = response.data.students
                      const projectNames = response.data.projectNames

                      this.setState({ grades, students, projectNames })

                    } else {
                      console.log('grades query did not work', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Grades query did not work for some reason', error);
                });

              }

            } else {
              console.log('no user details found', response.data.message)

            }

        }).catch((error) => {
            console.log('User profile query did not work', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'search') {
        this.setState({ searchString: event.target.value, textFormHasChanged: true })
      }
    }

    renderOrganizations(type) {
      console.log('renderOrganizations', type)

      // let problemPlatformOrg = false
      let problemPlatformEmployer = false
      if (window.location.pathname.includes('/problem-platform')) {
        if (this.state.roleName === 'Employer') {
          problemPlatformEmployer = true
        } else if (this.state.roleName === 'Admin' || this.state.roleName === 'Work-Based Learning Coordinator') {
          // problemPlatformOrg = true
        }
      }

      let rows = []
      if (window.location.pathname.includes('/employers') || problemPlatformEmployer) {
        console.log('window path includes employers', this.state.myOrgs, this.state.sharePartners)
        for (let i = 1; i <= this.state.sharePartners.length; i++) {
          const index = i - 1

          rows.push(
            <div key={i} className="float-left row-10 right-padding-20">
              <button className="background-button tag-container-6" onClick={() => this.pullOrgDetails(index,type,this.state.sharePartners[index])}>
                <div>
                  <div className="float-left left-text">
                    <p>{this.state.sharePartners[i - 1]}</p>
                  </div>
                  <div className="float-left right-text left-padding">
                    {(this.state.activeOrg === this.state.sharePartners[i - 1]) && (
                      <div>
                        {(window.location.pathname.includes('/problem-platform')) ? (
                          <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin-3 pin-right" />
                        ) : (
                          <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin-3 pin-right" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </button>
            </div>
          )
        }

        return rows

      } else {
        console.log('window path does not include employers: ', this.state.myOrgs, this.state.sharePartners)
        let orgOptions = []
        if (type === 'all') {
          orgOptions = this.state.myOrgs
        } else if (type === 'academies') {
          orgOptions = this.state.academies
        }

        for (let i = 1; i <= orgOptions.length; i++) {
          const index = i - 1

          rows.push(
            <div key={i} className="float-left row-10 right-padding-20">
              <button className="background-button tag-container-6" onClick={(type === 'all') ? () => this.pullOrgDetails(index,type,orgOptions[index]) : () => this.pullOrgDetails(index,type,orgOptions[index].name)}>
                <div>
                  <div className="float-left left-text">
                    {(type === 'all') ? (
                      <p>{orgOptions[i - 1]}</p>
                    ) : (
                      <p>{orgOptions[i - 1].name}</p>
                    )}
                  </div>
                  {(type === 'all') ? (
                    <div className="float-left right-text left-padding">
                      {(this.state.activeOrg === orgOptions[i - 1]) && (
                        <div>
                          {(window.location.pathname.includes('/problem-platform')) ? (
                            <div>
                              <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin-3 pin-right" />
                            </div>
                          ) : (
                            <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin pin-right" />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="float-left right-text left-padding">
                      {(this.state.activeOrg === orgOptions[i - 1].orgCode) && (
                        <div>
                          {(window.location.pathname.includes('/problem-platform')) ? (
                            <div>
                              <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin-3 pin-right" />
                            </div>
                          ) : (
                            <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20 top-margin pin-right" />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </button>
            </div>
          )
        }
      }

      return rows
    }

    orgClicked(index, type) {
      console.log('orgClicked clicked', index, type, this.state.roleName)

      let isEmployer = false
      if (this.state.roleName === 'Employer' || this.state.roleName === 'Employer Representative') {
        isEmployer = true
      }
      if (window.location.pathname.includes('/employers') || isEmployer) {
        console.log('in employers: ', this.state.activeOrg, this.state.sharePartners[index])
        if (this.state.activeOrg !== this.state.sharePartners[index]) {
          let activeOrg = ''
          if (this.state.activeOrg !== this.state.sharePartners[index]) {
            activeOrg = this.state.sharePartners[index]
          }

          this.setState({ activeOrg })
          localStorage.setItem('activeOrg', activeOrg)

          const accountCode = this.state.accountCode
          const updatedAt = new Date()

          Axios.post('/api/account/update', {
            accountCode, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Account update worked', response.data);
              this.setState({

                serverPostSuccess: true,
                serverSuccessMessage: 'Account information saved successfully!', modalIsOpen: false
              })

              this.props.passActiveOrg(activeOrg)

            } else {
              console.error('there was an error updating the account info');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Account info save did not work', error);
          });
        }

      } else {

        let orgOptions = []
        if (type === 'all') {
          orgOptions = this.state.myOrgs
        } else if (type === 'academies') {
          orgOptions = this.state.academyCodes
        }

        let activeOrg = ''
        if (this.state.activeOrg !== orgOptions[index]) {
          activeOrg = orgOptions[index]

          this.setState({ activeOrg })
          localStorage.setItem('activeOrg', activeOrg)

          const emailId = this.state.emailId
          const updatedAt = new Date()

          Axios.post('/api/users/profile/details', {
            emailId, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('User update worked', response.data);

              //update the orgFocus
              Axios.get('/api/org', { params: { orgCode: activeOrg } })
              .then((response) => {
                console.log('Org query attempted', response.data);

                 if (response.data.success) {
                   console.log('successfully retrieved org')

                   const orgFocus = response.data.orgInfo.orgFocus
                   const studentAliasSingular = response.data.orgInfo.studentAliasSingular
                   const studentAliasPlural = response.data.orgInfo.studentAliasPlural
                   const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
                   const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

                   localStorage.setItem('orgFocus', orgFocus)

                   localStorage.setItem('studentAliasSingular', studentAliasSingular)
                   localStorage.setItem('studentAliasPlural', studentAliasPlural)
                   localStorage.setItem('mentorAliasSingular', mentorAliasSingular)
                   localStorage.setItem('mentorAliasPlural', mentorAliasPlural)

                   const serverPostSuccess = true
                   const serverSuccessMessage = 'Active org successfully changed and saved!'

                   this.setState({ orgFocus, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                   this.props.passActiveOrg(activeOrg)

                 } else {
                   console.log('no org data found', response.data.message)
                 }

              }).catch((error) => {
                 console.log('Org query did not work', error);
                 this.setState({ serverErrorMessage: 'No org found'})
              });

            } else {
              console.error('there was an error saving the new active org');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Saving the active org did not work', error);
          });
        }
      }
    }

    addOrg() {
      console.log('addOrg called')

      let orgCode = this.state.searchString
      if (orgCode && orgCode !== '') {

        orgCode = orgCode.toLowerCase()

        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved org')

             const myOrgs = this.state.myOrgs
             if (myOrgs) {
               if (!myOrgs.includes(orgCode)) {
                 const orgName = response.data.orgInfo.orgName
                 const tempOrg = response.data.orgInfo
                 this.setState({ modalIsOpen: true, showDataTransferred: true, orgName, tempOrg })
               } else {
                 this.setState({ serverErrorMessage: 'You have already joined this organization'})
               }
             } else {
               const orgName = response.data.orgInfo.orgName
               this.setState({ modalIsOpen: true, showDataTransferred: true, orgName })
             }

           } else {
             console.log('no org data found', response.data.message)
             this.setState({ serverErrorMessage: 'No org found'})
           }

        }).catch((error) => {
           console.log('Org query did not work', error);
           this.setState({ serverErrorMessage: 'No org found'})
        });
      } else {
        this.setState({ serverErrorMessage: 'No org found'})
      }
    }

    removeOrg(index,type) {
      console.log('removeOrg called', this.state.selectedOrg)

      let isEmployer = false
      if (this.state.roleName === 'Employer' || this.state.roleName === 'Employer Representative') {
        isEmployer = true
      }
      if (window.location.pathname.includes('/employers') || isEmployer) {
        // console.log('in employers: ', this.state.activeOrg, this.state.sharePartners[index])
        // let activeOrg = this.state.sharePartners[index]

        if (this.state.sharePartners.length > 1) {
          // this.setState({ activeOrg })
          // localStorage.setItem('activeOrg', activeOrg)

          const accountCode = this.state.accountCode
          const updatedAt = new Date()

          Axios.post('/api/account/update', {
            accountCode, activeOrg: this.state.sharePartners[index], removeOrg: true, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Account update worked', response.data);

              let changeActiveOrg = false
              if (this.state.sharePartners[index] === this.state.activeOrg) {
                changeActiveOrg = true
              }

              let sharePartners = this.state.sharePartners
              sharePartners.splice(index,1)

              const serverPostSuccess = true
              const serverSuccessMessage = 'Org successfully removed!'

              if (changeActiveOrg) {
                let activeOrg = sharePartners[0]
                localStorage.setItem('activeOrg', activeOrg)
                this.setState({ activeOrg, sharePartners, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                this.props.passActiveOrg(activeOrg)
              } else {
                this.setState({ sharePartners, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
              }

            } else {
              console.error('there was an error updating the account info');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Account info save did not work', error);
          });
        } else {
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
          })
        }

      } else {

        let orgOptions = []
        if (type === 'all') {
          orgOptions = this.state.myOrgs
        } else if (type === 'academies') {
          orgOptions = this.state.academyCodes
        }

        if (orgOptions.length > 1) {

          const emailId = this.state.emailId
          const updatedAt = new Date()

          Axios.post('/api/users/profile/details', {
            emailId, activeOrg: orgOptions[index], removeOrg: true, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('User update worked', response.data);

              if (type === 'all') {

                let changeActiveOrg = false
                if (this.state.myOrgs[index] === this.state.activeOrg) {
                  changeActiveOrg = true
                }

                let myOrgs = this.state.myOrgs
                myOrgs.splice(index,1)

                const serverPostSuccess = true
                const serverSuccessMessage = 'Org successfully removed!'

                if (changeActiveOrg) {
                  const activeOrg = myOrgs[0]
                  this.setState({ activeOrg, myOrgs, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                  localStorage.setItem('activeOrg', activeOrg)
                  localStorage.removeItem('orgFocus')
                  localStorage.removeItem('studentAliasSingular')
                  localStorage.removeItem('studentAliasPlural')
                  localStorage.removeItem('mentorAliasSingular')
                  localStorage.removeItem('mentorAliasPlural')
                  this.props.passActiveOrg(activeOrg)
                } else {
                  this.setState({ myOrgs, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                }

              } else if (type === 'academies') {

                let changeActiveOrg = false
                if (this.state.academyCodes[index] === this.state.activeOrg) {
                  changeActiveOrg = true
                }

                let academyCodes = this.state.academyCodes
                academyCodes.splice(index,1)

                const serverPostSuccess = true
                const serverSuccessMessage = 'Org successfully removed!'

                if (changeActiveOrg) {
                  const activeOrg = academyCodes[0]
                  this.setState({ activeOrg, academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                  localStorage.setItem('activeOrg', activeOrg)
                  localStorage.removeItem('orgFocus')
                  localStorage.removeItem('studentAliasSingular')
                  localStorage.removeItem('studentAliasPlural')
                  localStorage.removeItem('mentorAliasSingular')
                  localStorage.removeItem('mentorAliasPlural')
                  this.props.passActiveOrg(activeOrg)
                } else {
                  this.setState({ academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                }
              }

            } else {
              console.error('there was an error saving the new active org');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Saving the active org did not work', error);
          });
        } else {
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
          })
        }
      }
    }

    confirmOrg() {
      console.log('confirmOrg called')

      this.setState({ serverSuccessMessage: null, serverErrorMessage: null })

      const emailId = this.state.emailId
      const activeOrg = this.state.searchString.toLowerCase()
      let myOrgs = this.state.myOrgs
      myOrgs.push(activeOrg)
      const updatedAt = new Date()

      Axios.post('/api/users/profile/details', {
        emailId, activeOrg, myOrgs, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('User update worked', response.data);

          const orgFocus = this.state.tempOrg.orgFocus
          const studentAliasSingular = this.state.tempOrg.studentAliasSingular
          const studentAliasPlural = this.state.tempOrg.studentAliasPlural
          const mentorAliasSingular = this.state.tempOrg.mentorAliasSingular
          const mentorAliasPlural = this.state.tempOrg.mentorAliasPlural


          localStorage.setItem('activeOrg', activeOrg)
          localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
          localStorage.setItem('orgFocus', orgFocus)

          localStorage.setItem('studentAliasSingular', studentAliasSingular)
          localStorage.setItem('studentAliasPlural', studentAliasPlural)
          localStorage.setItem('mentorAliasSingular', mentorAliasSingular)
          localStorage.setItem('mentorAliasPlural', mentorAliasPlural)

          const searchString = ''
          const modalIsOpen = false

          this.setState({ activeOrg, myOrgs, orgFocus, searchString, modalIsOpen,

            serverPostSuccess: true,
            serverSuccessMessage: 'Organization successfully added!'
          })

          // this.props.passActiveOrg(activeOrg)

        } else {
          console.error('there was an error updating the user info');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('User info save did not work', error);
      });
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, selectedIndex: null, selectedType: null, showDataTransferred: false, showOrgDetails: false })
    }

    renderDataToShare(passedIndex) {
      console.log('renderDataToShare called', passedIndex)

      let rows = []
      for (let i = 1; i <= this.state.dataToShare.length; i++) {
        const index = i - 1
        // console.log('log index: ', index)
        const remainder = index % 3
        console.log('show modulo: ', index, remainder)

        if (passedIndex === remainder) {
          if (this.state.dataToShare[index]) {
            rows.push(
              <div key={i.toString + "dataToShare"}>
                <div className="float-left right-margin">
                  <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-20" />
                </div>
                <div className="float-left">
                  <p>{this.state.dataToShare[index]}</p>
                </div>
                <div className="clear" />
                <div className="half-spacer" />
              </div>
            )
          }
        }
      }

      return rows
    }

    pullOrgDetails(index,type,selectedOrgCode) {
      console.log('pullOrgDetails called: ', index, type, selectedOrgCode)

      Axios.get('/api/org', { params: { orgCode: selectedOrgCode } })
      .then((response) => {
        console.log('Org query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved org')

           this.setState({ selectedIndex: index, selectedType: type, selectedOrg: response.data.orgInfo,
            modalIsOpen: true, showOrgDetails: true
           })

         } else {
          console.log('error: ', response.data.message)
           const errorMessage = response.data.message
           this.setState({ senderErrorMessage: errorMessage, errorMessage })
         }

      }).catch((error) => {
         console.log('Org query did not work', error);
         const errorMessage = error.toString()
         this.setState({ serverErrorMessage: errorMessage, errorMessage })
      });
    }

    render() {

      return (
        <div>
          {(this.props.academies) ? (
            <div>
              <div className="float-left right-padding">
                <p className="profile-label">Switch Career Academies</p>
              </div>

              <div className="clear" />

              {(this.state.editMode) && (
                <div>
                  <div className="filter-field-search half-width">
                    {/*
                    <div className="search-icon-container">
                      <img src={searchIcon} alt="GC" className="image-auto-20" />
                    </div>*/}
                    <div className="filter-search-container calc-column-offset-50" >
                      <input type="text" className="text-field clear-border" placeholder="Type the org code..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                    </div>
                  </div>

                  <div className="spacer" /><div className="half-spacer" />
                  <div>
                    <button className="btn btn-squarish" onClick={() => this.addOrg()}>Add Org</button>
                  </div>
                  <div className="clear" />
                  {(this.state.serverSuccessMessage) && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                  {(this.state.serverErrorMessage) && <p className="error-message">{this.state.serverErrorMessage}</p>}
                  <div className="spacer" />
                </div>
              )}

              {this.renderOrganizations('academies')}

              <div className="clear" />
              <div className="spacer"/><div className="half-spacer"/>
              <hr className="clear-margin clear-padding"/>
              <div className="spacer"/><div className="half-spacer"/>
            </div>
          ) : (
            <div>
              <div>
                {(window.location.pathname.includes('/advisor')) ? (
                  <div>
                    <p className="profile-label">My Organizations / Workspaces</p>
                  </div>
                ) : (
                  <div>
                    <div className="float-left right-padding">
                      {(window.location.pathname.includes('/employers')) ? (
                        <p className="profile-label">Manage {this.state.companyName} Organizations</p>
                      ) : (
                        <p className="profile-label">Manage My Organizations / Workspaces</p>
                      )}
                    </div>
                    <div className="float-left right-padding">
                      <div className="spacer" />
                      <button className="background-button clear-margin" onClick={(window.location.pathname.includes('/employers')) ? () => this.props.navigate('/employers/' + this.state.accountCode + '/add-orgs') : () => this.props.navigate('/app/add-communities')}>
                        <div className="cta-border padding-5 circle-radius cta-background-color">
                          <img src={addIconWhite} alt="GC" className="image-auto-12" />
                        </div>

                      </button>
                    </div>
                  </div>
                )}

                <div className="clear" />

                {(this.state.editMode) && (
                  <div>
                    <div className="filter-field-search half-width">
                      <div className="search-icon-container">
                        <img src={searchIcon} alt="GC" className="image-auto-20" />
                      </div>
                      <div className="filter-search-container calc-column-offset-50" >
                        <input type="text" className="text-field clear-border" placeholder="Search by org code..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                      </div>
                    </div>

                    <div className="spacer" /><div className="half-spacer" />

                    <div>
                      <button className="btn btn-squarish" onClick={() => this.addOrg()}>Add Org</button>
                    </div>
                    <div className="clear" />
                    {(this.state.serverSuccessMessage) && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                    {(this.state.serverErrorMessage) && <p className="error-message">{this.state.serverErrorMessage}</p>}
                    <div className="spacer" />
                  </div>
                )}

                {this.renderOrganizations('all')}
              </div>

              <div className="clear"/>
              <div className="spacer" />
              <hr className="cell-separator-advisor"/>
            </div>
          )}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           >

              <div className="flex-container flex-center center-text padding-40">
                {(this.state.showDataTransferred) && (
                  <div>
                    <p className="heading-text-2">You Are About to Join the {this.state.orgName} Portal</p>
                    <p>But before you do, please confirm that you are willing to share the following profile data:</p>

                    <div className="spacer" />

                    <div className="padding-20">
                      <div className="col span-1-of-3 box">
                        {this.renderDataToShare(0)}
                      </div>
                      <div className="col span-1-of-3 box">
                        {this.renderDataToShare(1)}
                      </div>
                      <div className="col span-1-of-3 box">
                        {this.renderDataToShare(2)}
                      </div>
                    </div>

                    <div className="clear" />

                    <div className="spacer" /><div className="half-spacer" />

                    <div>
                     <div className="float-left">
                       <button className="btn btn-squarish" onClick={() => this.confirmOrg()}>I Agree To Share My Data with {this.state.orgName}</button>
                     </div>
                     <div className="float-left left-padding">
                       <button className="btn btn-squarish cta-border white-background cta-color" onClick={() => this.closeModal()}>Close Modal</button>
                     </div>
                     <div className="clear"/>
                    </div>
                  </div>
                )}

                {(this.state.showOrgDetails) && (
                  <div>
                    {(this.state.selectedOrg.webLogoURI) ? (
                      <div className="full-width center-text bottom-margin-20">
                        <div className="fixed-column-30 height-30">
                          <p> </p>
                        </div>
                        <div className="calc-column-offset-60">
                          <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.selectedOrg.webLogoURIColor} />
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="full-width center-text">
                        <div className="fixed-column-30 height-30">
                          <p> </p>
                        </div>
                        <div className="calc-column-offset-60 height-30">
                          <p> </p>
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>
                    )}

                    <p className="heading-text-2">{this.state.selectedOrg.orgName}</p>
                    <a href={this.state.selectedOrg.orgURL} className="full-width center-text top-padding">{this.state.selectedOrg.orgURL}</a>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p className="description=text-1">{this.state.selectedOrg.orgDescription}</p>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {((window.location.pathname.includes('/employers') && this.state.sharePartners.length > 1) || (!window.location.pathname.includes('/employers') && this.state.myOrgs.length > 1)) && (
                      <div>
                        {(this.state.showConfirmDelete) ? (
                          <div className="full-width center-text">
                            <hr />
                            <div className="spacer" /><div className="spacer" />
                            <p className="heading-text-5">Are you sure you want to remove the {this.state.selectedOrg.orgName} community?</p>

                            <p className="description-text-2 row-20">Removing the community means that you will no longer receive notifications and invited to opportunities specific to this community. If you continue to receive unwanted notifications, please email help@guidedcompass.com.</p>

                            <div className="spacer" />

                            <div className="display-inline left-padding right-padding top-margin-20">
                              <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.removeOrg(this.state.selectedIndex,this.state.selectedType)}>Yes, Remove {this.state.selectedOrg.orgName}</button>
                            </div>
                            <div className="display-inline left-padding right-padding top-margin-20">
                              <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ showConfirmDelete: false })}>Cancel</button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="full-width center-text">
                             <div className="display-inline left-padding right-padding top-margin-20">
                               <button className="btn btn-squarish" onClick={() => this.orgClicked(this.state.selectedIndex, this.state.selectedType)}>Toggle to This Community</button>
                             </div>
                             <div className="display-inline left-padding right-padding top-margin-20">
                               <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.setState({ showConfirmDelete: true })}>Remove from Your Workspaces/Communities</button>
                             </div>
                             {(window.location.pathname.includes('/app')) && (
                               <div className="display-inline left-padding right-padding top-margin-20">
                                 <Link to={"/app/orgs/" + this.state.selectedOrg.orgCode} className="background-button"><button className="btn btn-squarish denary-background clear-border">View Review Page</button></Link>
                               </div>
                             )}
                             <div className="clear"/>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

          </Modal>
        </div>
      )
    }
}

export default withRouter(SwitchOrgs);
