import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubExternalProfile from './Subcomponents/ExternalProfile';
import {bootUser} from './Functions/BootUser';
import withRouter from './Functions/WithRouter';

class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount profile')

      const { username } = this.props.params
      this.setState({ profileUsername: username })

      let email = localStorage.getItem('email');
      const roleName = localStorage.getItem('roleName');

      let accept = false
      if (window.location.pathname.includes('/accept')) {
        accept = true
      }

      if (!email || email === '') {
        console.log('user is not logged in')
        if (accept) {
          // this.props.navigate('/' + username + '/profile/accept')
          this.setState({ showErrorMessage: true })
        } else {

          const bootPath = '/' + username + '/profile'
          bootUser(this.props.navigate, roleName, bootPath)
        }

      } else {

        let activeOrg = localStorage.getItem('activeOrg');
        const orgLogo = localStorage.getItem('orgLogo');

        if (email) {

          let matchScore = null
          if (this.props.location && this.props.location.state) {
            matchScore = this.props.location.state.matchScore
          }
          console.log('show location state: ', this.props.location.state, matchScore)

          this.setState({ emailId: email, profileUsername: username, activeOrg, orgLogo, matchScore, accept });
        }
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentProfile ', this.props, prevProps)
      console.log('go deeper: ', this.props.params.username, prevProps.params.username, this.state.profileUsernme)
      if (this.props.params && this.props.params.username !== this.state.profileUsername) {
        console.log('new username selected')
        this.setState({ profileUsername: this.props.params.username })
      }
    }

    render() {

        console.log('render was called')
        return (
            <div>
              {(this.state.showErrorMessage) ? (
                <div className="top-padding-40">
                  <p className="full-width center-text">Please log in at <Link to="/signin" target="_blank" className="background-button">{window.location.protocol + "//" + window.location.host + "/signin"}</Link> to accept this request.</p>
                </div>
              ) : (
                <div>
                  <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} skipRedirect={true} />
                  <SubExternalProfile username={this.state.profileUsername} history={this.props.navigate} roleName={this.state.roleName} pageSource="portal" matchScore={this.state.matchScore} accept={this.state.accept} />
                </div>
              )}

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(Profile)
