import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubEditTicket from '../Common/EditTicket';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // console.log('show me result', result)

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class Tickets extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showDND: false,
          urgencyOptions: ['Low','Medium','High'],
          stageOptions: ['Backlog','To Do','In Progress','In Testing','Done'],
          staffMemberOptions: [],

          tickets: [],
          filters: [],
          defaultFilterOption: 'All'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.updateTickets = this.updateTickets.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.calculateBackgroundColor = this.calculateBackgroundColor.bind(this)
        this.passData = this.passData.bind(this)
        this.deleteTicket = this.deleteTicket.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderTableRow = this.renderTableRow.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subtickets', this.props.activeOrg, prevProps)

      if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubSendMessage', this.props)

      const org = this.props.orgCode

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (org) {

        this.setState({ emailId: email, username, org, cuFirstName, cuLastName, orgFocus })

        Axios.get('/api/tickets', { params: { orgCode: org } })
        .then((response) => {
          console.log('Tickets query attempted', response.data);

            if (response.data.success) {
              console.log('tickets query worked')

              const tickets = response.data.tickets
              this.setState({ tickets });
              // tickets.sort(function(a,b) {
              //   return a.rank - b.rank;
              // })

            } else {
              console.log('tickets query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        const roleNames = ['Admin']
        Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
        .then((response) => {
          if (response.data.success) {
            console.log('Member query worked', response.data);

            if (response.data.members && response.data.members.length > 0) {

              let staffMemberOptions = response.data.members
              if (staffMemberOptions) {

                const defaultFilterOption = this.state.defaultFilterOption
                let filters = [
                  { name: 'Urgency', optionSubKey: null, shortname: "filter|urgency", value: defaultFilterOption, options: [defaultFilterOption].concat(this.state.urgencyOptions) },
                  { name: 'Due Before', optionSubKey: null, shortname: "filter|dueBefore", renderDate: true, value: defaultFilterOption, options: [] },
                  { name: 'Due After', optionSubKey: null, shortname: "filter|dueAfter", renderDate: true, value: defaultFilterOption, options: [] },
                  { name: 'Assignee Email', optionSubKey: "email", shortname: "filter|assignee", value: defaultFilterOption, options: [defaultFilterOption].concat(staffMemberOptions) },
                  { name: 'Stage', optionSubKey: null, shortname: "filter|stage", value: defaultFilterOption, options: [defaultFilterOption].concat(this.state.stageOptions) }
                ]

                this.setState({ filters })
              }
            }

          } else {
            console.log('no members found', response.data.message)
          }

        }).catch((error) => {
            console.log('Members query did not work', error);
        });
      }
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // console.log('show result: ', result)

      const tickets = reorder(
        this.state.tickets,
        result.source.index,
        result.destination.index
      );

      this.setState({ tickets });

      let ids = []
      let rankings = []
      for (let i = 1; i <= tickets.length; i++) {
        console.log(i);

        tickets[i - 1]['rank'] = i
        ids.push(tickets[i - 1]._id)
        rankings.push(i)

      }
      this.updateTickets(ids, rankings)
    }

    updateTickets(ids,rankings) {
      console.log('updateTickets called', ids, rankings)

      for (let i = 1; i <= ids.length; i++) {

        const _id = ids[i - 1]
        const rank = rankings[i - 1]
        Axios.post('/api/tickets', { _id, rank }).then((response) => {
          console.log('attempting to save ticket rankings')
          if (response.data.success) {
            console.log('saved ticket')

          } else {
            console.log('did not successfully update the rankings', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save rankings did not work', error);
            this.setState({ errorMessage: 'Ticket rankings save did not work' })
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.value, this.state.filters)
      } else if (event.target.name.includes('filter|')) {
        let filters = this.state.filters

        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].shortname === event.target.name) {
            filters[i - 1]['value'] = event.target.value
          }
        }

        this.setState({ filters })
        this.searchItems(null,filters)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
      console.log('gimme the filters: ', this.state.filters)
    }

    searchItems(searchString, filters) {
      console.log('searchItems called', searchString, filters)

      const orgCode = this.state.org

      this.setState({ animating: true })

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/tickets/filter', { searchString, orgCode, filters }).then((response) => {
          console.log('Tickets search filter query attempted');

            if (response.data.success) {
              console.log('tickets search query worked')

              // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)
              const filterCriteria = response.data.filterCriteria

              const tickets = response.data.tickets
              self.setState({ tickets, animating: false, filterCriteria })

            } else {
              console.log('tickets search query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Tickets search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showTicketDetails: false, selectedTicket: null,
        selectedIndex: null
      })
    }

    calculateBackgroundColor(urgency) {
      console.log('calculateBackgroundColor called', urgency)

      let backgroundColor = ''
      if (urgency === 'Low') {
        backgroundColor = 'primary-background'
      } else if (urgency === 'Medium') {
        backgroundColor = 'eleven-background'
      } else if (urgency === 'High') {
        backgroundColor = 'error-background-color'
      }

       return backgroundColor
    }

    passData(ticket,action) {
      console.log('passData called',ticket,action)

      let tickets = this.state.tickets
      if (action === 'add') {
        tickets.push(ticket)
      } else if (action === 'edit') {
        tickets[this.state.selectedIndex] = ticket
      } else if (action === 'delete') {
        tickets.splice(this.state.selectedIndex,1)
      }
      this.setState({ tickets })
    }

    deleteTicket(type, index) {
      console.log('deleteTicket called', type, index)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let tickets = this.state.tickets
      const _id = this.state.tickets[index]._id
      if (!_id) {
        this.setState({ isSaving: false, errorMessage: "Error: no ID for the ticket" })
      } else {
        Axios.delete('/api/tickets/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Ticket delete worked');

            tickets.splice(index,1)
            this.setState({ tickets, isSaving: false, successMessage: 'Successfully delete the ticket',
              confirmDelete: false
            })

          } else {
            console.error('an error: ', response.data.message);
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    renderTableRow(value, optionIndex, inDnD) {
      console.log('renderTableRow called', value, optionIndex, inDnD)

      return (
        <div key="renderTableRow">
          <div className="padding-10">
            {(inDnD) && (
              <div className="fixed-column-40 top-margin-7">
                <img src={dragIcon} alt="GC" className="image-auto-18 left-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reorder tickets" />
              </div>
            )}
            <div className="fixed-column-40 top-margin-7">
              <button className="background-button clear-margin" onClick={() => this.deleteTicket('ticket',optionIndex)}>
                <img src={deniedIcon} alt="GC" className="image-auto-18"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete ticket"/>
              </button>
            </div>

            <div className="fixed-column-40 top-margin-5">
              <label className="half-bold-text standard-color">{optionIndex + 1}.</label>
            </div>
            <div className={(inDnD) ? "calc-column-offset-120" : "calc-column-offset-80"}>
              <button className="background-button clear-margin left-text calc-column-offset-80 row-3" onClick={() => this.setState({ modalIsOpen: true, showTicketDetails: true, selectedTicket: value, selectedIndex: optionIndex })}>
                <p className="half-bold-text cta-color">{value.name}</p>
                {(value.category) && (
                  <p className="description-text-2 top-padding-5 bold-text">Ticket Category/Type: {value.category}</p>
                )}
                <p className="description-text-2 top-padding-5">Submitted by {value.firstName} {value.lastName} ({value.email})</p>
                <p className="description-text-2 description-text-color top-padding-5 curtail-text">{value.description}</p>
              </button>
              {/*
              <div className="fixed-column-40">
                <button className="background-button" onClick={() => console.log('')}>
                  <img className="image-auto-25" src={profileIconDark} alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Assign to someone" />
                </button>
              </div>
              <div className="fixed-column-40">
                <img className="image-auto-25" src={eventIconDark} alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Assign a Due Date" />
              </div>*/}
              <div className="fixed-column-80">
                <div className={"width-25 height-25 circle-radius pin-right " + this.calculateBackgroundColor(value.urgency)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(value.urgency) ? value.urgency + " Urgency" : "Urgency"} />
                {(value.createdAt) && (
                  <p className="top-margin description-text-4 full-width right-text">{convertDateToString(value.createdAt,"daysAgo")}</p>
                )}
                {(value.stage === 'Done') && (
                  <div className="top-margin">
                    <div className="circle-radius cta-border padding-7 float-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This ticket has been resolved">
                      <img src={checkmarkIcon} alt="GC" className="image-auto-15 pin-right"/>
                    </div>
                    <div className="clear" />
                  </div>
                )}
              </div>
              <div className="clear" />
              <ReactTooltip id="tooltip-placeholder-id" />
            </div>
            <div className="clear" />
          </div>

          <div className="spacer" />
          {(!inDnD) && (
            <div>
              <div className="half-spacer" />
              <hr />
            </div>
          )}

        </div>
      )
    }

    render() {

        return (
            <div>
              <div className="standard-container-2 clear-padding">

                <div className="padding-30">
                  <div className="calc-column-offset-40">
                    <p className="heading-text-2">{this.state.tickets.length} Active Tickets</p>
                  </div>
                  <div className="fixed-column-40">
                    <button className="background-button top-margin" onClick={() => this.setState({ modalIsOpen: true, showTicketDetails: true, selectedTicket: null, selectedIndex: null })}>
                      <img src={addIconBlue} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a ticket"/>
                    </button>
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="max-width-1400 width-70-percent center-horizontally white-background medium-shadow">
                <div className="fixed-column-40 padding-6 left-padding-5 top-padding-5">
                  <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                </div>
                <div className="calc-column-offset-95">
                  <input type="text" className="text-field clear-border" placeholder="Search tickets..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                </div>
                <div className="fixed-column-55 top-margin-5">
                  <button type="button" className="background-button pin-right" onClick={(this.state.showingSearchBar) ? () => this.setState({ showingSearchBar: false }) : () => this.setState({ showingSearchBar: true })}>
                    <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                  </button>
                </div>
                <div className="clear" />
              </div>
              <ReactTooltip id="tooltip-placeholder-id" />

              {(this.state.showingSearchBar) && (
                <div className="max-width-1400 width-70-percent center-horizontally">
                  {this.state.filters.map((value, index) =>
                    <div key="filterContainer">
                      <SubCustomDropdown dropdownTitle={value.name} name={value.shortname} value={value.value} options={value.options} optionSubKey={value.optionSubKey} optionClass="float-left curtail-text relative-position z-index-1" renderDate={value.renderDate} formChangeHandler={this.formChangeHandler} />
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}

              <div className="standard-container-2 clear-padding">
                {(this.state.animating) ? (
                  <div className="flex-container flex-center full-space padding-40">
                    <div>
                      <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Calculating results...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.showDND) ? (
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <StrictModeDroppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.tickets.map((value, optionIndex) => (
                                <Draggable key={value._id} draggableId={value._id} index={optionIndex}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div key={"ticket|" + optionIndex} className="padding-10 standard-color">
                                        {this.renderTableRow(value,optionIndex,true)}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </StrictModeDroppable>
                      </DragDropContext>
                    ) : (
                      <div>
                        {this.state.tickets.map((value, optionIndex) => (
                          <div className="padding-10 standard-color">
                            {this.renderTableRow(value,optionIndex,false)}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
              >
                <div key="showPriceChart" className="full-width padding-20">
                  {(this.state.showTicketDetails) && (
                    <div key="showTicketDetails" className="full-width padding-20">
                      <SubEditTicket history={this.props.navigate} selectedTicket={this.state.selectedTicket} closeModal={this.closeModal} passData={this.passData} />
                    </div>
                  )}
                </div>
              </Modal>
            </div>
        )
    }
}

export default withRouter(Tickets);
