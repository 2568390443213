import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// import deniedIcon from '../../images/denied-icon.png';
import {convertDateToString} from '../Functions/ConvertDateToString';

import SubEditLesson from '../Common/EditLesson';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';

const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  // console.log('show me result', result)

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class ArrangeLessons extends Component {
    constructor(props) {
        super(props)

        this.state = {
          lessons: [],
          useDND: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.updateRankings = this.updateRankings.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in ArrangeLessons')

      if (this.props.lessons !== prevProps.lessons) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in ArrangeLessons')
      let lessons = []
      if (this.props.lessons) {
        lessons = this.props.lessons
        lessons.sort(function(a,b) {
          return a.rank - b.rank;
        })
      }
      this.setState({ lessons })
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // console.log('show result: ', result)

      const lessons = reorder(
        this.state.lessons,
        result.source.index,
        result.destination.index
      );

      this.setState({ lessons });
      // console.log('show lessons: ', lessons)

      let ids = []
      let rankings = []
      for (let i = 1; i <= lessons.length; i++) {
        console.log(i);

        lessons[i - 1]['rank'] = i
        ids.push(lessons[i - 1]._id)
        rankings.push(i)

      }
      this.updateRankings(ids, rankings)
    }

    updateRankings(ids,rankings) {
      console.log('updateRankings called', ids, rankings)

      for (let i = 1; i <= ids.length; i++) {

        const _id = ids[i - 1]
        const rank = rankings[i - 1]
        Axios.post('/api/lessons', { _id, rank }).then((response) => {
          console.log('attempting to save lesson rankings')
          if (response.data.success) {
            console.log('saved lesson')

          } else {
            console.log('did not successfully update the rankings', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save rankings did not work', error);
            this.setState({ errorMessage: 'Lesson rankings save did not work' })
        });
      }
    }

    removeItem(type,index){
      console.log('removeItem called', type)

      if (type === 'lesson') {
        let lessons = this.props.lessons
        const deleteId = lessons[index]._id

        Axios.delete('/api/lessons/' + deleteId)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Lessons delete worked');

            // lessons.splice(index,1)
            // this.setState({ lessons })
            this.props.passLesson(null,false,index,false,null)

          } else {
            console.error('there was an error deleting the log');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error })
        });
      }
    }

    closeModal() {
      console.log('closeModal claled')

      this.setState({ modalIsOpen: false, showPriceChart: false, showLessons: false, showLessonDetails: false, selectedLesson: null, selectedIndex: null})
    }

    render() {
      console.log('renderingArrangeLesson')
      return (
          <div>
            <div className="description-text-1 standard-border padding-10">{/*
              <div className="fixed-column-40">
                <p className="full-width left-padding-5">D</p>
              </div>
              <div className="fixed-column-40">
                <p className="full-width left-padding-5">T</p>
              </div>*/}
              <div className="fixed-column-40">
                <p>#</p>
              </div>
              <div className="calc-column-offset-190">
                <p>Name</p>
              </div>
              <div className="fixed-column-90">
                <p>Deadline</p>
              </div>
              <div className="fixed-column-60">
                <p>Visible</p>
              </div>
              <div className="clear" />
            </div>

            {(this.state.useDND) ? (
              <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.lessons.map((value, optionIndex) => (
                          <Draggable key={value._id} draggableId={value._id} index={optionIndex}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div key={"lesson|" + optionIndex} className="padding-10">{/*
                                  <div className="fixed-column-40 top-margin-7">
                                    <button className="background-button clear-margin" onClick={() => this.removeItem('lesson',optionIndex)}>
                                      <img src={dragIcon} alt="GC" className="image-auto-18 left-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reorder lessons" />
                                    </button>
                                  </div>
                                  <div className="fixed-column-40 top-margin-7">
                                    <button className="background-button clear-margin" onClick={() => this.removeItem('lesson',optionIndex)}>
                                      <img src={deniedIcon} alt="GC" className="image-auto-18"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete lessons"/>
                                    </button>
                                  </div>*/}
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                  <div className="fixed-column-40 top-margin-5">
                                    <label className="half-bold-text standard-color">{optionIndex + 1}.</label>
                                  </div>
                                  <button className="background-button clear-margin left-text calc-column-offset-100 row-3" onClick={() => this.setState({ modalIsOpen: true, showLessonDetails: true, selectedLesson: value, selectedIndex: optionIndex })}>
                                    <div className="calc-column-offset-90">
                                      <label className="half-bold-text cta-color">{value.name}</label>
                                    </div>
                                    <div className="fixed-column-90">
                                      {(value.submissionDeadline && !value.limitToGroups) ? (
                                        <div>
                                          <label className="half-bold-text cta-color">{convertDateToString(new Date(value.submissionDeadline),'datetime-2')}</label>
                                        </div>
                                      ) : (
                                        <label className="half-bold-text cta-color">{(value.limitToGroups) ? "N/A" : "None"}</label>
                                      )}
                                    </div>
                                  </button>
                                  <div className="fixed-column-60">
                                    <Switch
                                      onChange={(change) => this.props.toggleVisiblity(optionIndex, change)}
                                      checked={value.isVisible}
                                      id="normal-switch"
                                    />
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              </div>
            ) : (
              <div>
                {this.props.lessons && this.props.lessons.map((value, optionIndex) =>
                  <div key={"lesson|" + optionIndex} className="padding-10 standard-border">
                    <div className="fixed-column-70 top-margin-5">
                      <button className="background-button clear-margin" onClick={() => this.removeItem('lesson',optionIndex)}>
                        <img src={deniedIcon} alt="Compass target icon" className="image-auto-18" />
                      </button>
                    </div>
                    <div className="calc-column-offset-70">
                      <button className="background-button clear-margin left-text relative-column-90" onClick={() => this.setState({ modalIsOpen: true, showLessonDetails: true, selectedLesson: value, selectedIndex: optionIndex })}>
                        <div className="relative-column-50">
                          <label className="half-bold-text cta-color">{value.name}</label>
                        </div>
                        <div className="relative-column-30">
                          {(value.classes && value.classes.length > 0) ? (
                            <label className="half-bold-text cta-color">{value.name}</label>
                          ) : (
                            <label className="half-bold-text cta-color">All</label>
                          )}
                        </div>
                        <div className="relative-column-20">
                          {(value.submissionDeadline) ? (
                            <div>
                              <label className="half-bold-text cta-color">{convertDateToString(new Date(value.submissionDeadline),'datetime-2')}</label>
                            </div>
                          ) : (
                            <label className="half-bold-text cta-color">None</label>
                          )}
                        </div>
                      </button>
                      <div className="relative-column-10">
                        <Switch
                          onChange={(change) => this.props.toggleVisiblity(optionIndex, change)}
                          checked={value.isVisible}
                          id="normal-switch"
                        />
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="clear" />
                  </div>
                )}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >
              <div key="showPriceChart" className="full-width padding-20">

                {(this.state.showLessonDetails) && (
                  <div key="showPricingChart" className="full-width padding-20">
                    <SubEditLesson history={this.props.navigate} passLesson={this.props.passLesson} closeModal={this.closeModal} selectedLesson={this.state.selectedLesson} editLesson={true} selectedIndex={this.state.selectedIndex}/>
                  </div>
                )}
              </div>
            </Modal>
          </div>

      )
    }
}

export default ArrangeLessons;
