import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditPlacement from './Subcomponents/EditPlacement';
import withRouter from './Functions/WithRouter';

class AdvEditPlacement extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let selectedPlacement = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedPlacement = this.props.location.state.selectedPlacement;
        duplicate = this.props.location.state.duplicate
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ roleName, emailId: email, username, orgLogo,
        cuFirstName, cuLastName, activeOrg, orgFocus, selectedPlacement, duplicate
      })
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          <SubEditPlacement selectedPlacement={this.state.selectedPlacement} activeOrg={this.state.activeOrg} history={this.props.navigate} duplicate={this.state.duplicate} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvEditPlacement);
