import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import SubCustomDropdown from '../Common/CustomDropdown';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const trashIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trash-icon-dark.png';
// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
// const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const resumeIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Documents extends Component {
    constructor(props) {
        super(props)

        this.state = {
          resLimit: 300,
          documents: [],
          filters: [],
          defaultFilterOption: 'All'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in documents', this.props, prevProps)

      if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.accountCode)

      let orgCode = this.props.orgCode
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let activeOrg = localStorage.getItem('activeOrg');
      if (!orgCode) {
        orgCode = activeOrg
      }

      if (orgCode) {

        // const defaultFilterOption = this.state.defaultFilterOption
        let prefix = '/app'
        let extendedPrefix = "/app/builders"
        if (window.location.pathname.startsWith('/organizations')) {
          prefix = '/organizations/' + orgCode
          extendedPrefix = prefix + "/documents"
        } else if (window.location.pathname.startsWith('/advisor')) {
          prefix = '/advisor'
          extendedPrefix = prefix + "/documents"
        }

        this.setState({ orgCode, emailId: email, username, orgFocus, accountCode, prefix, extendedPrefix })

        let docParams = { orgCode }
        if (window.location.pathname.startsWith('/app')) {
          docParams = { emailId: email }
        }

        docParams['resLimit'] = this.state.resLimit

        // const onlyUpcoming = true
        Axios.get('/api/documents', { params: docParams })
        .then((response) => {
          console.log('Documents query worked', response.data);

          if (response.data.success) {

            const documents = response.data.documents
            this.setState({ documents })

          } else {
            console.log('no documents data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Documents query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.value, this.state.filters)
      } else if (event.target.name.includes('filter|')) {
        let filters = this.state.filters

        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].shortname === event.target.name) {
            filters[i - 1]['value'] = event.target.value
          }
        }

        this.setState({ filters })
        this.searchItems(null,filters)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
      console.log('gimme the filters: ', this.state.filters)
    }

    searchItems(searchString, filters) {
      console.log('searchItems called', searchString, filters)

      let docParams = { searchString, filters, orgCode: this.state.orgCode }
      if (window.location.pathname.startsWith('/app')) {
        docParams = { searchString, filters, emailId: this.state.emailId }
      }

      const self = this

      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/documents/filter', docParams).then((response) => {
          console.log('Documents search filter query attempted');

            if (response.data.success) {
              console.log('documents search query worked')

              // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)
              const filterCriteria = response.data.filterCriteria

              const documents = response.data.documents
              self.setState({ documents, animating: false, filterCriteria })

            } else {
              console.log('documents search query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Documents search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let documents = this.state.documents
      const _id = this.state.documents[index]._id
      if (!_id) {
        this.setState({ isSaving: false, errorMessage: "Error: no ID for the doc" })
      } else {
        Axios.delete('/api/documents/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Document delete worked');

            documents.splice(index,1)
            this.setState({ documents, isSaving: false, successMessage: 'Successfully delete the document',
              confirmDelete: false
            })

          } else {
            console.error('an error: ', response.data.message);
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    toggleConfirmDelete(e, index) {
      console.log('toggleConfirmDelete called', index)

      e.preventDefault()

      let documents = this.state.documents
      if (documents[index].confirmDelete) {
        documents[index]['confirmDelete'] = false
      } else {
        documents[index]['confirmDelete'] = true
      }

      this.setState({ documents })

    }

    render() {

      return (
          <div>
            <div className="standard-container-2 clear-padding">
              <div className="padding-20">
                <div className="calc-column-offset-25">
                  <p className="heading-text-2">{(this.state.documents) ? (this.state.documents.length === this.state.resLimit) ? this.state.documents.length + "+ " : this.state.documents.length + " " : ""}Documents</p>
                </div>
                <div className="fixed-column-25 top-margin-5">
                  {(window.location.pathname.startsWith('/app')) && (
                    <Link className="background-background" to="/app/builders">
                      <img className="image-25-fit" alt="GC" src={addIconBlue} />
                    </Link>
                  )}
                </div>
                <div className="clear" />
              </div>
            </div>

            <div className="max-width-1400 width-70-percent center-horizontally white-background medium-shadow">
              <div className="fixed-column-40 padding-6 left-padding-5 top-padding-5">
                <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
              </div>
              <div className="calc-column-offset-95">
                <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
              </div>
              {/*
              <div className="fixed-column-55 top-margin-5">
                <button type="button" className="background-button pin-right" onClick={(this.state.showingSearchBar) ? () => this.setState({ showingSearchBar: false }) : () => this.setState({ showingSearchBar: true })}>
                  <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                </button>
              </div>*/}
              <div className="clear" />
            </div>

            {(this.state.showingSearchBar) && (
              <div className="max-width-1400 width-70-percent center-horizontally">
                {this.state.filters.map((value, index) =>
                  <div key="filterContainer">
                    <SubCustomDropdown dropdownTitle={value.name} name={value.shortname} value={value.value} options={value.options} optionSubKey={value.optionSubKey} optionClass="float-left curtail-text relative-position z-index-1" renderDate={value.renderDate} formChangeHandler={this.formChangeHandler} />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            {(this.state.showingSearchBar) && (
              <div className="max-width-1400 width-70-percent center-horizontally">
                {this.state.filters.map((value, index) =>
                  <div key="filterContainer">
                    <SubCustomDropdown dropdownTitle={value.name} name={value.shortname} value={value.value} options={value.options} optionSubKey={value.optionSubKey} optionClass="float-left curtail-text relative-position z-index-1" renderDate={value.renderDate} formChangeHandler={this.formChangeHandler} />
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <div className="standard-container-2 clear-padding">
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space padding-40">
                  <div>
                    <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Calculating results...</p>

                  </div>
                </div>
              ) : (
                <div>
                  {(this.state.documents && this.state.documents.length > 0) ? (
                    <div className="padding-10">
                      {this.state.documents.map((value, optionIndex) => (
                        <div className="left-padding-20 top-padding-5 right-padding-20">
                          <div>
                            <Link className="background-button clear-margin left-text full-width standard-color" to={this.state.extendedPrefix + "/" + value._id}>
                              <div className="row-15">
                                <div className="fixed-column-40">
                                  <p className="heading-text-6 cta-color">{optionIndex + 1}.</p>
                                </div>
                                <div className="calc-column-offset-120">
                                  <p className="heading-text-6 cta-color">{value.fileName}</p>
                                  <p className="description-text-2 top-padding-5">{(value.docType) && value.docType + " "}by {value.firstName} {value.lastName} ({value.email})</p>
                                </div>
                                <div className="fixed-column-80">
                                  <button className="background-button clear-margin float-right" onClick={(e) => this.toggleConfirmDelete(e, optionIndex)}>
                                    <img src={trashIconDark} alt="GC" className="image-auto-18"/>
                                  </button>
                                  <div className="clear" />
                                  {(value.createdAt) && (
                                    <p className="top-margin description-text-4 full-width right-text">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                            </Link>
                            <div className="clear" />

                            {(value.confirmDelete) && (
                              <div className="bottom-margin-20">
                                <p className="bottom-margin error-color description-text-2">Are you sure you want to delete?</p>

                                <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteItem(optionIndex)}>Yes, Confirm Delete</button>
                                <button className="btn btn-squarish-white" onClick={(e) => this.toggleConfirmDelete(e, optionIndex)}>Cancel</button>
                              </div>
                            )}
                          </div>

                          <hr />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <div className="card flex-container flex-center padding-50">
                        <div className="row-30">
                          <img src={resumeIconDark} alt="GC" className="image-auto-80 center-horizontally"/>
                          <p className="center-text heading-text-5 top-margin-30">No Documents Yet</p>
                          <p className="center-text description-text-color top-margin-20">All documents created with the doc builder will appear here.</p>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              )}
            </div>
          </div>

      )
    }
}


export default withRouter(Documents);
