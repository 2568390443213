import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubLogDetails from '../Subcomponents/LogDetails';
import withRouter from '../Functions/WithRouter';

class OrgLogDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, logSelected } = this.props.params
      console.log('logSelected:', logSelected);

      const { logDetails, member } = this.props.location.state;

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      // console.log('show logDetails', member, logDetails)
      this.setState({ org, email, username, orgFocus, orgLogo, logDetails, member })

    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubLogDetails activeOrg={this.state.org} logDetails={this.state.logDetails} member={this.state.member} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgLogDetails);
