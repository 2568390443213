import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSubmissionDetails from '../Subcomponents/SubmissionDetails';
import withRouter from '../Functions/WithRouter';

class OrgSubmissionDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ orgCode: org, org, emailId: email, username, orgFocus, roleName, orgLogo, id })

    }

    render() {

        return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} />
            <SubSubmissionDetails org={this.state.org} id={this.state.id} />

            {(this.state.org && !this.state.hideHelpPrompt) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand, this.state.hideNav)}
              </div>
            )}
          </div>

        )
    }
}

export default withRouter(OrgSubmissionDetails)
