import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class ChangeEmail extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveEmail = this.saveEmail.bind(this)
    }

    componentDidMount() {
      console.log('commonChangeEmail just mounted')
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonChangeEmail', this.props, prevProps)

      if (this.props.email !== prevProps.email) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in commonChangeEmail')

      const email = this.props.email
      this.setState({ oldEmail: email });

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event)

      if (event.target.name === 'oldEmail') {
        this.setState({ oldEmail: event.target.value })
      } else if (event.target.name === 'newEmail') {
        this.setState({ newEmail: event.target.value })
      } else if (event.target.name === 'repeatedEmail') {

        if (this.state.newEmail !== '' && event.target.value === this.state.newEmail) {
          this.setState({ repeatedEmail: event.target.value, successMessage: 'New emails match', errorMessage: '' })
        } else if (this.state.newEmail !== '') {
          this.setState({ repeatedEmail: event.target.value, successMessage: '', errorMessage: 'New emails do not match' })
        } else {
          this.setState({ repeatedEmail: event.target.value })
        }
      }

    }

    saveEmail() {
      console.log('called saveEmail')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      const oldEmail = this.state.oldEmail
      const newEmail = this.state.newEmail
      const repeatedEmail = this.state.repeatedEmail
      // const emailId = this.state.emailId

      if (oldEmail === '') {
        this.setState({ isSaving: false, errorMessage: 'Please enter your old email' })
      } else if (!newEmail || newEmail === '') {
        this.setState({ isSaving: false, errorMessage: 'Please enter your new email' })
      } else if (!newEmail.includes('@')) {
        this.setState({ isSaving: false, errorMessage: 'please enter a valid email' })
      } else if (repeatedEmail === '') {
        this.setState({ isSaving: false, errorMessage: 'Please repeat your new email' })
      } else if (newEmail !== repeatedEmail) {
        this.setState({ isSaving: false, errorMessage: 'Your new emails dont match. Please double check.' })
      } else {

        Axios.post('/api/users/email/change', { oldEmail, newEmail })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Email changed successfully worked', response.data);

            let successMessage = 'Email changed successfully!'
            if (this.props.modalIsOpen) {
              successMessage = "Email changed successfully! You can change your email again from your settings page."
            }
            this.setState({ successMessage, isDone: true, isSaving: false })
            // localStorage.removeItem('temporaryEmail')
            localStorage.setItem('email', newEmail)

          } else {
            console.log('request was not successful', response.data.message)
            this.setState({ errorMessage: response.data.message, isSaving: false })
          }

        }).catch((error) => {
            console.log('Email change did not work', error);
            this.setState({ errorMessage: error, isSaving: false })
        });
      }
    }

    render() {

      return (
        <div>
          <div>
            <div className="calc-column-offset-30">
              <p className="heading-text-2">Change Email</p>
            </div>
            {(this.props.modalIsOpen) && (
              <div className="fixed-column-30">
                <button className="background-button" onClick={() => this.props.closeModal()}>
                  <img className="image-auto-20" alt="GC" src={deniedIcon} />
                </button>
              </div>
            )}
            <div className="clear" />
          </div>

          <div className="spacer" />

          {(this.state.isDone && this.props.modalIsOpen) ? (
            <div>
              <div className="row-30">
                <p className="cta-color">{this.state.successMessage}</p>
              </div>

              <div className="spacer" />

              <button className="btn btn-secondary" onClick={() => this.props.closeModal()}>Close View</button>
            </div>
          ) : (
            <div>
              <div className="edit-profile-row">
                <label className="profile-label">Current Email</label>
                <input className="text-field" type="text" placeholder="Enter current email" name="oldEmail" value={this.state.oldEmail} onChange={this.formChangeHandler}/>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">New Email</label>
                <input className="text-field" type="text" placeholder="Enter new Email" name="newEmail" value={this.state.newEmail} onChange={this.formChangeHandler}/>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Confirm New Email</label>
                <input className="text-field" type="text" placeholder="Repeat new email" name="repeatedEmail" value={this.state.repeatedEmail} onChange={this.formChangeHandler}/>
              </div>

              <div className="spacer" /><div className="spacer" />

              {(this.state.successMessage && this.state.successMessage !== '') && (
                <p className="description-text-2 cta-color vertical-margin-10">{this.state.successMessage}</p>
              )}

              {(this.state.errorMessage && this.state.errorMessage !== '') && (
                <p className="description-text-2 error-color vertical-margin-10">{this.state.errorMessage}</p>
              )}

              <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.saveEmail()}>Save New Email</button>
            </div>
          )}
        </div>
      )
    }
}

export default ChangeEmail;
