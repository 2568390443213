import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import ProfileCard from './Subcomponents/ProfileCard';
import DeactivatedModal from './Common/DeactivatedModal';
import {convertStringToDate} from './Functions/ConvertStringToDate';
import {convertDateToString} from './Functions/ConvertDateToString';
import withRouter from './Functions/WithRouter';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";

class EmpOrgSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {

          currentPage: 1,

          showModule: true,
          createCustomBenchmark: false,
          hideProfileCard: true,

          showMentorships: false,
          accountCode: '',
          isSaving: false,
          wantsInterns: 'true',

          hostedEvents: [],
          problems: [],
          selectedJob: { postType: 'Work', subPostType: 'Individual', title: '', workFunction: ''},

          orgProgramName: '',
          introSummary: '',

          contactFirstName: '',
          contactLastName: '',
          employerName: '',
          contactTitle: '',

          employerURL: '',
          employerType: '',
          employeeCount: '',
          employerIndustry: '',
          employerDescription: '',

          contactEmail: '',
          contactPhone: '',
          password: '',

          projectedHires: '',
          hasFunds: '',
          hostUndocumented: '',
          postCount: '',
          postings: [],
          employers: [],

          postOptions: [],
          tracks: [],
          trackFunctions: [],

          includeHighSchool: true,
          includeAssociates: true,
          includeBachelors: true,

          typeOptions: ['','For-Profit','Non-Profit','Public'],
          industryOptions: [],
          countOptions: [],
          projectedInternOptions: [],
          postCountOptions: [],
          binaryOptions: ['','Yes','No'],
          maybeOptions: ['','Yes','Maybe','No'],
          functionOptions: [],
          hoursPerWeekOptions: [],
          officeTypeOptions: [],
          politicalAlignmentOptions: [],
          registrationOptions: [],
          locationOptions: [],
          applicationMethodOptions: [],
          eventTypeOptions: ["","Talk","Workshop","Field Trip","Job Shadow","Conference","Other"],
          projectPromptTypeOptions: ["","Assignment","Problem","Challenge"],
          durationOptions: [],
          knowledgeLevelOptions: [],
          atsOptions: [],
          timeframeOptions: [],
          referrerOptions: [],
          tagTypeOptions: ['','Hard Skill','Soft Skill','Knowledge'],

          successAnswerChoices: [],
          successSelected: [],
          successMetrics: [],
          hireTimeframes: [],
          volunteerAnswerChoices: [],
          volunteerSelected: [],
          volunteerPreferences: [],

          title: '',
          location: '',
          function: '',
          startDate: '',
          isPerpetual: false,
          endDate: '',
          hoursPerWeek: '',
          hireCount: '',
          description: '',
          employerContactFirstName: '',
          employerContactLastName: '',
          employerContactEmail: '',
          subjectLine: '',
          appRequirements: '',
          submissionDeadline: '',
          applicationMethod: '',
          jobLink: '',

          officeType: '',
          politicalAlignment: '',
          stateRegistration: '',
          congressionalDistrict: '',

          sharePartners: [],

          formComplete: false,
          confirmationMessage: '',

          username: '',

          org: '',
          orgName: '',
          orgURL: '',
          orgMission: '',
          orgLogo: '',

          benchmarkOptions: [],

          animate: false,
          opacity: 0,
          isWaiting: false,
          serverErrorMessage: ''
        }

        this.retrieveTrackPosts = this.retrieveTrackPosts.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderPage = this.renderPage.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.renderConfirmationPage = this.renderConfirmationPage.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.signUp = this.signUp.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.addItem = this.addItem.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)

        this.addTag = this.addTag.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

        this.closeModal = this.closeModal.bind(this)

        this.child = React.createRef();

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      console.log('we up in there')

      //user navigated from a landing page or from the app
      const { org } = this.props.params

      const successAnswerChoices = ['Conversion to jobs','Knowledge gained as a result of the internship','Achieve hiring and diversity needs','Fulfill contingent workforce','Employee exposure to non-traditional student backgrounds']
      const successSelected = [false,false,false,false,false]
      const volunteerAnswerChoices = ['Company Tours','Guest Speaker','Career Fairs','Job Shadowing','Immersive Workshops','Professional & Curriculum Development','Mentorships','Mock Interviews']
      const volunteerSelected = [false,false,false,false,false]

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked')

          const orgName = response.data.orgInfo.orgName
          const orgURL = response.data.orgInfo.orgURL
          const orgMission = response.data.orgInfo.orgMission

          const orgContactFirstName = response.data.orgInfo.contactFirstName
          const orgContactLastName = response.data.orgInfo.contactLastName
          const orgContactEmail = response.data.orgInfo.contactEmail
          const orgContactTitle = response.data.orgInfo.contactTitle
          const orgProgramName = response.data.orgInfo.orgProgramName
          const orgCCEmails = response.data.orgInfo.ccEmails

          const automaticallyAttach = response.data.orgInfo.automaticallyAttach
          const parentPostId = response.data.orgInfo.parentPostId

          let introSummary = response.data.orgInfo.introSummary
          if (!introSummary) {
            introSummary = 'If you are interested in partnering with ' + orgName + ' on our work, please complete this form!'
          }

          let confirmationMessage = "Thank you for submitting your posting(s)! Look out for your confirmation email and reach out to " + orgContactTitle + ", " + orgContactFirstName + " " + orgContactLastName + " (" + orgContactEmail + ").\n\nWe will schedule a call to clarify your needs, and then send you top matches via email. Feel free to provide feedback and check the status of your postings at the dashboard."
          if (org === 'c2c') {
            confirmationMessage = response.data.orgInfo.confirmationMessage
          }

          const orgLogo = response.data.orgInfo.webLogoURI
          const orgPartnerLogo = response.data.orgInfo.partnerLogoURI
          const employerFormTitle = response.data.orgInfo.employerFormTitle
          let officeTypeOptions = response.data.orgInfo.officeTypeOptions
          if (officeTypeOptions) {
            officeTypeOptions.unshift('')
          }

          let isMobileView = false
          if (window.innerWidth < 767) {
            isMobileView = true
          }

          let employerType = ''
          let employerIndustry = ''
          let employeeCount = ''
          if (org === 'c2c') {
            employerType = 'Public'
            employerIndustry = 'Government and Politics'
            employeeCount = '51 - 100 employees'
          }

          // default is off
          let showModule = this.state.showModule
          let createCustomBenchmark = this.state.createCustomBenchmark
          if (org === 'bixel' || org === 'c2c') {
            showModule = false
          }

          createCustomBenchmark = true

          let wantsInterns = 'true'
          if (org === 'unite-la' || org === 'block') {
            wantsInterns = null
          }

          let referrerOptions = [{ contactFirstName: '' }]
          let referrerTracker = []
          if (response.data.orgInfo.pathways && response.data.orgInfo.pathways.length > 0) {
            for (let i = 1; i <= response.data.orgInfo.pathways.length; i++) {
              if (response.data.orgInfo.pathways[i - 1].contactFirstName && response.data.orgInfo.pathways[i - 1].contactLastName) {
                if (!referrerTracker.includes(response.data.orgInfo.pathways[i - 1].contactEmail)) {
                  referrerTracker.push(response.data.orgInfo.pathways[i - 1].contactEmail)
                  referrerOptions.push(response.data.orgInfo.pathways[i - 1])
                }
              }
            }
            referrerOptions.push({ contactFirstName: 'Other', contactLastName: '' })
          }

          const deactivated = response.data.orgInfo.deactivated
          let redirectLink = response.data.orgInfo.employerRedirectLink
          if (redirectLink && !redirectLink.startsWith('http')) {
            redirectLink = 'http://' + redirectLink
          }

          let modalIsOpen = false
          if (deactivated) {
            modalIsOpen = true
          }

          const customVirtualTerm = response.data.orgInfo.customVirtualTerm

          let preSetStartDate = undefined
          if (response.data.orgInfo.preSetStartDate) {
            preSetStartDate = convertDateToString(response.data.orgInfo.preSetStartDate,"first10")
          }

          let preSetEndDate = undefined
          if (response.data.orgInfo.preSetEndDate) {
            preSetEndDate = convertDateToString(response.data.orgInfo.preSetEndDate,"first10")
          }

          let preSetSubmissionDeadline = undefined
          if (response.data.orgInfo.preSetSubmissionDeadline) {
            preSetSubmissionDeadline = convertDateToString(response.data.orgInfo.preSetSubmissionDeadline,"first10")
          }

          let hoursPerWeekOptions = ['']
          if (response.data.orgInfo.hoursPerWeekOptions) {
            hoursPerWeekOptions = hoursPerWeekOptions.concat(response.data.orgInfo.hoursPerWeekOptions)
          }

          this.setState({

              org, orgName, orgURL, orgMission, orgLogo, orgPartnerLogo,

              orgProgramName, orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail, orgCCEmails,

              introSummary, confirmationMessage, automaticallyAttach, parentPostId, employerFormTitle, officeTypeOptions,

              successAnswerChoices, successSelected, volunteerAnswerChoices, volunteerSelected, isMobileView,

              employerType, employerIndustry, employeeCount, showModule, wantsInterns, createCustomBenchmark,

              deactivated, redirectLink, modalIsOpen, referrerOptions,

              customVirtualTerm, preSetStartDate, preSetEndDate, preSetSubmissionDeadline

          });

          Axios.get('/api/workoptions')
          .then((response2) => {
            console.log('Work options query tried', response2.data);

            if (response2.data.success) {
              console.log('Work options query succeeded')

              let postCountOptions = ['']

              let startingPoint = 1
              if (org === 'unite-la' || org === 'block') {
                startingPoint = 0
              }
              for (let i = startingPoint; i <= 6; i++) {
                postCountOptions.push(i.toString())
              }

              let politicalAlignmentOptions = response2.data.workOptions[0].politicalAlignmentOptions
              if (politicalAlignmentOptions) {
                politicalAlignmentOptions.unshift('')
              }

              let registrationOptions = response2.data.workOptions[0].unitedStateOptions
              if (registrationOptions) {
                registrationOptions.unshift('')
              }

              let locationOptions = []
              if (org === 'c2c') {
                locationOptions = ['','DC Office','District/In-State Office','Remote']
              }

              let functionOptions = response2.data.workOptions[0].functionOptions
              // functionOptions.push('Other')

              const industryOptions = response2.data.workOptions[0].industryOptions
              // const hoursPerWeekOptions = response2.data.workOptions[0].hoursPerWeekOptions
              if (!response.data.orgInfo.hoursPerWeekOptions || response.data.orgInfo.hoursPerWeekOptions.length === 0) {
                hoursPerWeekOptions = response2.data.workOptions[0].hoursPerWeekOptions
              }
              // console.log('compare hoursPerWeekOptions: ', hoursPerWeekOptions, response.data.orgInfo.hoursPerWeekOptions, response2.data.workOptions[0].hoursPerWeekOptions)
              const countOptions = response2.data.workOptions[0].employeeCountOptions
              const projectedInternOptions = response2.data.workOptions[0].projectedInternOptions
              // let applicationMethodOptions = response.data.workOptions[0].applicationMethodOptions
              // applicationMethodOptions.unshift('')

              const applicationMethodOptions = [
                '',
                'Only receive candidates referred to us by ' + orgName,
                'Applicants may be referred, but they must also apply via email',
                'Applicants may be referred, but they must also apply via our website posting'
              ]

              const durationOptions = response2.data.workOptions[0].durationOptions
              const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

              let atsOptions = ['','Avature','Workable','JazzHR','Lever','Greenhouse','Breezy HR', 'iCIMS','RecruiterBox','Pinpoint','ClearCompany','Other',]
              atsOptions.sort()
              atsOptions.push('No ATS',"I'm Not Sure")

              let timeframeOptions = []
              const currentMonth = new Date().getMonth()
              const currentYear = new Date().getFullYear()
              const seasons = ['Spring','Summer','Fall','Winter']
              let currentSeason = ''
              if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
                currentSeason = seasons[0]
              } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
                currentSeason = seasons[1]
              } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
                currentSeason = seasons[2]
              } else if (currentMonth === 9 || currentMonth === 10 || currentMonth === 11) {
                currentSeason = seasons[3]
              }

              for (let i = 1; i <= 4; i++) {
                if (i === 1) {
                  timeframeOptions.push(currentSeason + ' ' + currentYear)
                } else {
                  // not currentSeason
                  const index = seasons.indexOf(currentSeason)
                  if (index + i - 1 > 3) {
                    const nextYear = currentYear + 1
                    const newIndex = (index + i - 1) - 4
                    timeframeOptions.push(seasons[newIndex] + ' ' +  nextYear)
                  } else {
                    timeframeOptions.push(seasons[index + i - 1] + ' ' + currentYear)
                  }
                }
              }

              timeframeOptions.push("Ad-Hoc / As-Needed")

              this.setState({
                functionOptions,industryOptions, hoursPerWeekOptions, countOptions, projectedInternOptions,
                postCountOptions, politicalAlignmentOptions, registrationOptions, locationOptions, applicationMethodOptions,
                durationOptions, knowledgeLevelOptions, atsOptions, timeframeOptions
              })

              let orgCode = 'general'
              if (org === 'c2c') {
                orgCode = org
              }

              //pull general benchmarks as backup
              Axios.get('/api/benchmarks', { params: { orgCode } })
              .then((response2) => {
                console.log('Benchmarks query attempted', response2.data);

                  if (response2.data.success) {
                    console.log('benchmark query worked')

                    if (response2.data.benchmarks.length !== 0) {
                      //jobs = response.data.postings
                      let benchmarkOptions = response2.data.benchmarks
                      functionOptions = ['']
                      for (let i = 1; i <= benchmarkOptions.length; i++) {
                        if (!benchmarkOptions[i - 1].title.includes('Scholarship') && !benchmarkOptions[i - 1].title.includes('C2C')) {
                          if (!functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                            functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                          }
                        }
                      }

                      functionOptions.sort()
                      if (org === 'dpscd' && !functionOptions.includes('Other')) {
                        functionOptions.push('Other')
                      }

                      this.setState({ benchmarkOptions, functionOptions })
                    }
                  }
              })
            }
          });

          this.retrieveTrackPosts(org, response.data.orgInfo.parentPostId)

        } else {
          console.log('org info query did not work', response.data.message)
          this.setState({ successAnswerChoices, successSelected, volunteerAnswerChoices, volunteerSelected })
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      const fromExternal = this.props.location.state
      if (fromExternal) {
          setTimeout(() => {
              this.setState({animate: true, opacity: 1})
          }, 0.7)
      } else {
          this.setState({animate: true, opacity: 1})
      }
    }

    retrieveTrackPosts(org, parentPostId) {
      Axios.get('/api/postings/user', { params: { orgCode: org } })
      .then((response) => {
        console.log('Posted positions query attempted', response.data);

        if (response.data.success) {
          console.log('posted positions query worked')

          if (response.data.postings.length !== 0) {
            let jobs = response.data.postings

            let postOptions = []
            let parentPost = null

            let tracks = []
            let trackFunctions = []

            for (let i = 1; i <= jobs.length; i++) {
              if (jobs[i - 1].postType === "Track" || jobs[i - 1].subPostType === "Track") {
                postOptions.push(jobs[i - 1])

                if (parentPostId) {
                  if (parentPostId === jobs[i - 1]._id) {
                    parentPost = jobs[i - 1]
                    tracks = jobs[i - 1].tracks

                    for (let j = 1; j <= jobs[i - 1].tracks.length; j++) {

                      const tempBenchmark = jobs[i - 1].tracks[j - 1].benchmark
                      if (tempBenchmark) {
                        trackFunctions.push(tempBenchmark.jobFunction)
                      }
                    }
                  }
                }
              }
            }

            this.setState({ postOptions, parentPost, tracks, trackFunctions });
          }

        } else {
          console.log('posted jobs query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.value, event.target.name)
      //validate strings
      if (event.target.name === 'contactFirstName') {
        let contactFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        const employerContactFirstName = contactFirstName
        this.setState({ contactFirstName , employerContactFirstName })
      } else if (event.target.name === 'contactLastName') {
        let contactLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        const employerContactLastName = contactLastName
        this.setState({ contactLastName, employerContactLastName })
      } else if (event.target.name === 'employerName') {

        const employerName = event.target.value

        let accountCode = ''
        if (employerName && employerName !== '') {
          let trimmedName = employerName.trim()
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }

        this.setState({ employerName, accountCode })

        this.searchEmployers(employerName)
      } else if (event.target.name === 'employerLocation') {
        this.setState({ employerLocation: event.target.value })
      } else if (event.target.name === 'employerURL') {
        this.setState({ employerURL: event.target.value })
      } else if (event.target.name === 'employerType') {
        this.setState({ employerType: event.target.value })
      } else if (event.target.name === 'employeeCount') {
        this.setState({ employeeCount: event.target.value })
      } else if (event.target.name === 'employerIndustry') {
        this.setState({ employerIndustry: event.target.value })
      } else if (event.target.name === 'employerDescription') {
        this.setState({ employerDescription: event.target.value })
      } else if (event.target.name === 'contactTitle') {
        this.setState({ contactTitle: event.target.value })
      } else if (event.target.name === 'referrerName') {
        let referrerName = event.target.value
        // if (referrerName === 'Other ') {
        //   referrerName = 'Other'
        // }
        this.setState({ referrerName })
      } else if (event.target.name === 'referralExplanation') {
        this.setState({ referralExplanation: event.target.value })
      } else if (event.target.name === 'contactEmail') {
        const contactEmail = event.target.value
        const employerContactEmail = contactEmail
        this.setState({ contactEmail, employerContactEmail })
      } else if (event.target.name === 'contactPhone') {
        this.setState({ contactPhone: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name === 'wantsInterns') {
        this.setState({ wantsInterns: event.target.value })
      } else if (event.target.name === 'projectedHires') {
        this.setState({ projectedHires: event.target.value })
      } else if (event.target.name === 'hasFunds') {
        this.setState({ hasFunds: event.target.value })
      } else if (event.target.name === 'canHost') {
        this.setState({ canHost: event.target.value })
      } else if (event.target.name === 'hostUndocumented') {
        this.setState({ hostUndocumented: event.target.value })
      } else if (event.target.name.includes("success")) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1]) - 1

        let successSelected = this.state.successSelected
        let successMetrics = this.state.successMetrics
        if (successSelected[index]) {
          successSelected[index] = false
          successMetrics.indexOf(this.state.successAnswerChoices[index])
          if (index > -1) {
            successMetrics.splice(index, 1);
          }
        } else {
          successSelected[index] = true
          successMetrics.push(this.state.successAnswerChoices[index])
        }

        this.setState({ successSelected, successMetrics })

      } else if (event.target.name.includes("volunteer")) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1]) - 1

        let volunteerSelected = this.state.volunteerSelected
        let volunteerPreferences = this.state.volunteerPreferences
        if (volunteerSelected[index]) {
          volunteerSelected[index] = false
          volunteerPreferences.indexOf(this.state.volunteerAnswerChoices[index])
          if (index > -1) {
            volunteerPreferences.splice(index, 1);
          }
        } else {
          volunteerSelected[index] = true
          volunteerPreferences.push(this.state.volunteerAnswerChoices[index])
        }

        this.setState({ volunteerSelected, volunteerPreferences })
      } else if (event.target.name === 'title') {
        const title = event.target.value
        let selectedJob = this.state.selectedJob
        selectedJob['title'] = event.target.value
        this.setState({ title, selectedJob })
      } else if (event.target.name === 'location') {
        this.setState({ location: event.target.value })
      } else if (event.target.name === 'function') {
        let selectedJob = this.state.selectedJob
        selectedJob['workFunction'] = event.target.value

        let benchmark = null
        if (this.state.benchmarkOptions && this.state.benchmarkOptions.length > 0) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            const jobFunction = this.state.benchmarkOptions[i - 1].jobFunction
            console.log('comparison: ', i, jobFunction, event.target.value)
            if (jobFunction === event.target.value) {
              if (jobFunction === 'Engineering') {
                // cybersecurity is a temporary problem
                if (!this.state.benchmarkOptions[i - 1].title.includes('Cybersecurity')) {
                  benchmark = this.state.benchmarkOptions[i - 1]
                }
              } else {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            } else if (event.target.value === 'Other') {
              if (this.state.benchmarkOptions[i - 1].title.includes('Basic')) {
                 benchmark = this.state.benchmarkOptions[i - 1]
              } else if (this.state.benchmarkOptions[i - 1].jobFunction === 'Marketing') {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            }
          }
        }
        // console.log('got the benchie?', benchmark)
        let workPreferenceResults = null
        let interestResults = null
        let personalityResults = null
        let skillScores = null
        let skillTraits = []

        let intangiblesText = ''

        let competencyTags = []

        if (benchmark) {
          //workPreferenceResults
          workPreferenceResults = ["['Design']","['Retail','Music', 'Information','Food Services','Fashion']","['Work for a startup or small-sized company']","Graphic Design Intern, Design Intern","n/a","Yes","15 miles","['~ 20 Miles']"]
          console.log('benchmark: ', benchmark)
          interestResults = []
          for (let i = 1; i <= benchmark.interests.length; i++) {
            const name = benchmark.interests[i - 1].title
            const description = benchmark.interests[i - 1].description
            const tempScore = Number(benchmark.interests[i - 1].score)
            // const score = Math.ceil(tempScore/5)*5
            const score = tempScore * 20 * 0.4

            interestResults.push({ name, description, score })
          }

          let tempOpennessScore = benchmark.traits[0].score
          let opennessScore = tempOpennessScore * 20 * (16/100)
          console.log('show trait: ', benchmark.traits[0].score, tempOpennessScore, opennessScore)

          const conscientiousnessScore = benchmark.traits[1].score * 20 * (16/100)
          const extraversionScore = benchmark.traits[2].score * 20 * (16/100)
          const agreeablenessScore = benchmark.traits[3].score * 20 * (16/100)
          const neuroticismScore = benchmark.traits[4].score * 20 * (16/100)

          personalityResults = {
            myersBriggs: 'ENTJ', fiveFactors: {
              opennessScore, conscientiousnessScore, extraversionScore, agreeablenessScore, neuroticismScore
            }
          }
          for (let i = 1; i <= benchmark.traits.length; i++) {

          }

          intangiblesText = 'This person is most interested in engineering careers and least interested in design careers. Their personality is high in openness and low in emotional stability.'

          if (benchmark.skills && benchmark.skills.length > 0) {
            skillScores = []
            let hardSkillCounter = 0
            let softSkillCounter = 0
            for (let i = 1; i <= benchmark.skills.length; i++) {
              let selectedSkill = benchmark.skills[i - 1]
              console.log('show selectedSkill: ', selectedSkill)
              skillScores.push({ title: selectedSkill.title, skillType: selectedSkill.skillType, score: 5 })
              if (selectedSkill.skillType === 'Hard Skill') {
                hardSkillCounter = hardSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              } else {
                softSkillCounter = softSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              }

              competencyTags.push({
                name: selectedSkill.title, category: selectedSkill.skillType, description: selectedSkill.description,
                score: selectedSkill.score, weight: selectedSkill.weight
              })
            }
          }

          if (benchmark.abilities && benchmark.abilities.length > 0) {
            for (let i = 1; i <= benchmark.abilities.length; i++) {
              let selectedItem = benchmark.abilities[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: 'Ability', description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }
          if (benchmark.knowledge && benchmark.knowledge.length > 0) {
            for (let i = 1; i <= benchmark.knowledge.length; i++) {
              let selectedItem = benchmark.knowledge[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: 'Knowledge', description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }

          if (benchmark.traits && benchmark.traits.length > 0) {
            for (let i = 1; i <= benchmark.traits.length; i++) {
              let selectedTrait = benchmark.traits[i - 1]
              // console.log('show selectedSkill: ', selectedSkill)
              skillTraits.push({ name: selectedTrait.title, skillType: 'Trait', rating: 5 })
            }
          }
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        let endorsements = []
        endorsements.push({
          senderFirstName: 'Jane', senderLastName: 'Doe', senderEmail: 'janedoe@gmail.com', overallRecommendation: '5',
          recommendationExplanation: '', isTransparent: true, relationship: 'Mentor', type: 'Provide', recipientFirstName: 'Example',
          recipientLastName: 'Candidate', recipientEmail: 'jondoe@gmail.com', pathway: event.target.value,
          skillTraits,
          examples: [{ skillTrait: 'Empathy', example: 'When Jon was working for me, many customers said that Jon really empathized with their situation and that he went above and beyond to make sure I felt comfortable.'}],
          createdAt, updatedAt
        })

        let interview = { interviewerName: 'Mary Doe', date: createdAt, score: '5' }
        let interviewDetails = {
          sections: [
            { title: 'Knowledge, Skills, Abilities', questions: [{ question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.' }], sectionScore: 5},
            { title: 'Communication, Collaboration, and Problem Solving', questions: [{ question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.' }], sectionScore: 5},
            { title: 'Passion for Technology', questions: [{ question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.' }], sectionScore: 5},
            { title: 'True, Accurate, Error-Free and Complete Resume', questions: [{ question: 'Is there anything that you want us to know that is not mentioned on your resume?', helper: 'What to look for: Missing information, informal education and skills.' }], sectionScore: 5},
            { title: 'Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program', questions: [{ question: 'Tell me about your job experience[s] and/or school activities as they relate to your future goals in an Design, Digital Media, Production or Marketing career.', helper: 'What to look for: Communication skills, ability to tell their story.' }], sectionScore: 5}
          ],
          glows: 'Jon has great communication skills, and shows real passion for making a difference in the world', grows: 'Jon can come across as over-confident or nieve when it comes to implementing ideas or change.'
        }
        let interviewQuestions = [
          { question: 'How have your experiences helped prepare you for work in this field?', guidance: 'What to look for: communication skills, conviction, demonstrated fit, relevant accomplishments, motivation for accomplishments.'},
        ]

        let paidExperienceHours = 500
        let volunteerHours = 100
        let experienceHours = paidExperienceHours + volunteerHours
        let projectHours = 900
        let totalHours = experienceHours + projectHours

        let pictureURL = 'https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB'

        const degreeRequirements = 'High School'
        const gpaRange = "GPA Doesn't Matter"
        const gradYearRange = '2020 - 2025'
        const courseHours = '1,000 - 5,000 Hours'

        const politicalAlignment = 'Republican'
        const hometown = 'Los Angeles, CA'
        const homeCongressionalDistrict = '4'

        const selectedApplication = {
          firstName: 'Example Ideal', lastName: 'Candidate', email: 'fakeemail@email.com', schoolName: 'Sample School', gradYear: '2022',
          pictureURL, major: 'Business', workPreferenceResults, personalityResults,
          intangiblesText, interview, interviewDetails, interviewQuestions, totalHours, degreeRequirements,
          gpaRange, gradYearRange, courseHours,  politicalAlignment, hometown, homeCongressionalDistrict,
          match: 100, createdAt, updatedAt
        }

        this.setState({ function: event.target.value, selectedJob, benchmark, selectedApplication, competencyTags })
      } else if (event.target.name === 'startDate') {
        console.log('lets make sure value', this.state.startDate)
        this.setState({ startDate: event.target.value })
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'endDate') {
        this.setState({ endDate: event.target.value })
      } else if (event.target.name === 'hoursPerWeek') {
        this.setState({ hoursPerWeek: event.target.value })
      } else if (event.target.name === 'hireCount') {
        this.setState({ hireCount: event.target.value })
      } else if (event.target.name === 'description') {
        this.setState({ description: event.target.value })
      } else if (event.target.name === 'postCount') {
        this.setState({ postCount: event.target.value })
      } else if (event.target.name === 'ats') {
        this.setState({ ats: event.target.value })
      } else if (event.target.name === 'isLATechReferral') {
        this.setState({ isLATechReferral: event.target.value })
      } else if (event.target.name === 'officeType') {
        this.setState({ officeType: event.target.value })
      } else if (event.target.name === 'politicalAlignment') {
        const politicalAlignment = event.target.value
        const postPoliticalParty = event.target.value
        this.setState({ politicalAlignment, postPoliticalParty })
      } else if (event.target.name === 'stateRegistration') {
        this.setState({ stateRegistration: event.target.value })
      } else if (event.target.name === 'congressionalDistrict') {
        this.setState({ congressionalDistrict: event.target.value })
      } else if (event.target.name === 'employerContactFirstName') {
        this.setState({ employerContactFirstName: event.target.value })
      } else if (event.target.name === 'employerContactLastName') {
        this.setState({ employerContactLastName: event.target.value })
      } else if (event.target.name === 'employerContactEmail') {
        this.setState({ employerContactEmail: event.target.value })
      } else if (event.target.name === 'subjectLine') {
        this.setState({ subjectLine: event.target.value })
      } else if (event.target.name === 'appRequirements') {
        this.setState({ appRequirements: event.target.value })
      } else if (event.target.name === 'submissionDeadline') {
        this.setState({ submissionDeadline: event.target.value })
      } else if (event.target.name === 'postPoliticalParty') {
        this.setState({ postPoliticalParty: event.target.value })
      } else if (event.target.name === 'applicationMethod') {
        this.setState({ applicationMethod: event.target.value })
      } else if (event.target.name === 'jobLink') {
        this.setState({ jobLink: event.target.value })
      } else if (event.target.name === 'additionalInformation') {
        this.setState({ additionalInformation: event.target.value })
      } else if (event.target.name === 'willHostEvents') {
        this.setState({ willHostEvents: event.target.value })
      } else if (event.target.name === 'willAttendEvents') {
        this.setState({ willAttendEvents: event.target.value })
        console.log('plz show')
      } else if (event.target.name === 'willPostProblem') {
        this.setState({ willPostProblem: event.target.value })
      } else if (event.target.name.includes('eventTitle')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['title'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventType')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['eventType'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventLocation')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['location'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventStartDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['startDate'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('problemName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['name'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('projectPromptType')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['projectPromptType'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDuration')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['duration'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemTags')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['tags'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDeadline')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['submissionDeadline'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemKnowledgeLevel')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['knowledgeLevel'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDescription')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['description'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name === 'tagName') {
        this.setState({ tagName: event.target.value })
        this.searchCompetencies(event.target.value, 'All', null)
      } else if (event.target.name === 'tagType') {
        this.setState({ tagType: event.target.value })
      } else if (event.target.name.includes('candidatesOfInterest|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const index = Number(nameArray[2])

        let candidatesOfInterest = this.state.candidatesOfInterest
        candidatesOfInterest[index][element] = event.target.value
        this.setState({ candidatesOfInterest })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            this.setState({ employers });
            // if (employers && employers.length > 0) {
            //   this.setState({ employers });
            // }

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employerName = employer.employerName
      const employers = []
      const employerLocation = employer.employerLocation
      const employerURL = employer.employerURL
      const employerType = employer.employerType
      const employerIndustry = employer.employerIndustry
      const employeeCount = employer.employeeCount
      const accountCode = employer.accountCode
      const sharePartners = employer.sharePartners
      const contactEmail = employer.contactEmail
      const orgContacts = employer.orgContacts
      const employerContactEmail = contactEmail
      const employerDescription = employer.description
      const employerCulture = employer.employerCulture
      const employerLogoURI = employer.employerLogoURI

      this.setState({ employerName, employers, employerLocation, employerURL, employerType, employerIndustry,
        employeeCount, accountCode, sharePartners, contactEmail, orgContacts,
        employerContactEmail, employerDescription, employerCulture, employerLogoURI })
    }

    nextPage() {
      console.log('nextPage clicked')

      if (this.state.currentPage === 1) {
        if (this.state.contactFirstName === '') {
          this.setState({ serverErrorMessage: 'Please enter your first name'})
        } else if (this.state.contactLastName === '') {
          this.setState({ serverErrorMessage: 'Please enter your last name'})
        } else if (this.state.employerName === '') {
          this.setState({ serverErrorMessage: 'Please enter your company name'})
        } else if (this.state.contactTitle === '') {
          this.setState({ serverErrorMessage: 'Please enter your role at the company'})
        } else {
          let currentPage = this.state.currentPage
          currentPage = currentPage + 1

          const serverErrorMessage = ''
          this.setState({ currentPage, serverErrorMessage })
        }
      } else if (this.state.currentPage === 2) {
        if (this.state.employerLocation === '') {
          this.setState({ serverErrorMessage: "Please enter your employer's office address"})
        } else if (this.state.employerURL === '') {
          this.setState({ serverErrorMessage: "Please enter your employer URL"})
        } else if (this.state.employerType === '') {
          this.setState({ serverErrorMessage: 'Please enter the employer type'})
        } else if (this.state.employerIndustry === '') {
          this.setState({ serverErrorMessage: 'Please enter the employer industry'})
        } else if (this.state.employeeCount === '') {
          this.setState({ serverErrorMessage: 'Please enter the number of employees at the employer'})
        } else if (this.state.org === 'c2c' && this.state.officeType === '') {
          this.setState({ serverErrorMessage: 'Please enter your office type'})
        } else if (this.state.org === 'c2c' && this.state.politicalAlignment === '') {
          this.setState({ serverErrorMessage: 'Please enter the office political alignment'})
        } else if (this.state.org === 'c2c' && this.state.stateRegistration === '') {
          this.setState({ serverErrorMessage: 'Please enter the state to which the office is registered'})
        } else if (this.state.contactEmail === '') {
          this.setState({ serverErrorMessage: 'Please enter your email'})
        } else if (this.state.contactPhone === '') {
          this.setState({ serverErrorMessage: 'Please enter your phone'})
        // } else if (this.state.password === '') {
        //   this.setState({ serverErrorMessage: 'Please enter your password'})
        } else {
          let currentPage = this.state.currentPage
          currentPage = currentPage + 1

          const serverErrorMessage = ''
          this.setState({ currentPage, serverErrorMessage })
        }

      } else if (this.state.currentPage === 3) {

        let wantsInterns = (this.state.wantsInterns === 'true')

        if (this.state.org === 'unite-la' && !this.state.wantsInterns) {
          this.setState({ serverErrorMessage: 'Please indicate whether you want interns'})
        } else if (this.state.org !== 'dpscd' && wantsInterns && this.state.projectedHires === '') {
          this.setState({ serverErrorMessage: 'Please enter your projected interns'})
        } else if (wantsInterns && this.state.postCount === '') {
          this.setState({ serverErrorMessage: 'Please enter the number of jobs you will post'})
        // } else if (wantsInterns && this.state.hasFunds === '') {
        //   this.setState({ serverErrorMessage: 'Please enter whether you have funds to pay interns'})
        } else if (wantsInterns && this.state.hostUndocumented === '' && this.state.org === 'c2c') {
          this.setState({ serverErrorMessage: 'Please indicate whether you can host undocumented workers'})
        } else if (wantsInterns && this.state.canHost === '') {
          this.setState({ serverErrorMessage: 'Please enter whether you can host these interns'})
        } else {

          let currentPage = this.state.currentPage
          currentPage = currentPage + 1

          // let hostedEvents = this.state.hostedEvents
          // if (this.state.willHostEvents === 'false') {
          //   hostedEvents = []
          // }
          //
          // let problems = this.state.problems
          // if (this.state.willPostProblem === 'false') {
          //   problems = []
          // }

          const serverErrorMessage = ''
          this.setState({ currentPage, serverErrorMessage })
        }

      } else {
        console.log('were in internships')

        //we are in internship postings
        if (this.state.title === '') {
          console.log('were in title')
          this.setState({ serverErrorMessage: 'Please add a job title'})
        } else if (this.state.location === '') {
          console.log('were in location')
          this.setState({ serverErrorMessage: 'Please add the job location zip code'})
        } else if (this.state.function === '') {
          console.log('were in function')
          this.setState({ serverErrorMessage: 'Please enter the job function'})
        } else if (this.state.hoursPerWeek === '') {
          this.setState({ serverErrorMessage: 'Please enter the hours per week'})
        } else if (this.state.hireCount === '') {
          this.setState({ serverErrorMessage: 'Please enter the number of hires for this role'})
        } else if (!this.state.preSetStartDate && this.state.startDate === '') {
          console.log('lets see value', this.state.startDate)
          this.setState({ serverErrorMessage: 'please add the ideal start date' })
        } else if (!this.state.preSetEndDate && this.state.isPerpetual === false &&  this.state.endDate === '') {
          console.log('were in first endDate')
          this.setState({ serverErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role' })
        } else if (this.state.description === '') {
          this.setState({ serverErrorMessage: 'please add a job description' })
        } else if (this.state.org === 'c2c' && this.state.applicationMethod === '') {
          this.setState({ serverErrorMessage: 'please add an application method preference' })
        } else if (this.state.org === 'c2c' && this.state.appRequirements === '') {
          this.setState({ serverErrorMessage: 'please add the required components you need for a successful application' })
        } else if (this.state.org === 'c2c' && this.state.applicationMethod.includes('email') && this.state.subjectLine === '') {
          this.setState({ serverErrorMessage: 'please add the required subject line for the emailed application' })
        } else if (this.state.org === 'c2c' && this.state.applicationMethod.includes('posting') && this.state.jobLink === '') {
          this.setState({ serverErrorMessage: 'please add the link to your work posting' })
        } else {

          console.log('did we get in here tho?')
          let currentPage = this.state.currentPage
          currentPage = currentPage + 1

          window.scrollTo(0, 0)

          const serverErrorMessage = ''

          const postType = 'Internship'
          const title = this.state.title
          const location = this.state.location
          const functionValue = this.state.function
          const hoursPerWeek = this.state.hoursPerWeek
          const competencyTags = this.state.competencyTags
          const hireCount = this.state.hireCount
          let startDate = this.state.startDate
          if (this.state.preSetStartDate) {
            startDate = this.state.preSetStartDate
          }

          const isPerpetual = this.state.isPerpetual
          let endDate = this.state.endDate
          if (this.state.preSetEndDate) {
            endDate = this.state.preSetEndDate
          }

          const description = this.state.description

          const employerContactFirstName = this.state.employerContactFirstName
          const employerContactLastName = this.state.employerContactLastName
          const employerContactEmail = this.state.employerContactEmail
          const subjectLine = this.state.subjectLine
          const appRequirements = this.state.appRequirements
          let submissionDeadline = this.state.submissionDeadline
          if (this.state.preSetSubmissionDeadline) {
            submissionDeadline = this.state.preSetSubmissionDeadline
          }

          const politicalParty = this.state.postPoliticalParty
          const applicationMethod = this.state.applicationMethod
          const jobLink = this.state.jobLink
          const candidatesOfInterest = this.state.candidatesOfInterest

          let postings = this.state.postings
          postings.push({  postType, title, location, function: functionValue, hoursPerWeek,
            competencyTags, hireCount, startDate, isPerpetual, endDate,
            description, employerContactFirstName, employerContactLastName, employerContactEmail, subjectLine, appRequirements,
            submissionDeadline, politicalParty, applicationMethod, jobLink, candidatesOfInterest })

          if (this.state.showModule || this.state.createCustomBenchmark) {
            this.saveCustomBenchmark()
          }

          this.setState({ currentPage, serverErrorMessage, postings,
            title: '',
            location: '',
            function: '',
            hoursPerWeek: '',
            competencyTags: [],
            hireCount: '',
            startDate: '',
            isPerpetual: false,
            endDate: '',
            description: '',
            employerContactFirstName: this.state.contactFirstName,
            employerContactLastName: this.state.contactLastName,
            employerContactEmail: this.state.contactEmail,
            subjectLine: '',
            appRequirements: '',
            submissionDeadline: '',
            candidatesOfInterest: null
          })
        }
      }
    }

    previousPage() {
      console.log('previousPage called')

      let currentPage = this.state.currentPage - 1

      console.log('new and old:', this.state.currentPage, currentPage)
      this.setState({ currentPage })
    }

    signUp() {
      console.log('this.state', this.state)

      //save account + postings
      // this.setState({ formComplete: true })

      this.setState({
        clientErrorMessage: '', isSaving: true
      })

      // if (this.state.title === '') {
      //   console.log('were in title')
      //   this.setState({ serverErrorMessage: 'Please add a job title'})
      // } else if (this.state.location === '') {
      //   console.log('were in location')
      //   this.setState({ serverErrorMessage: 'Please add the job location zipcode'})
      // } else if (this.state.function === '') {
      //   console.log('were in function')
      //   this.setState({ serverErrorMessage: 'Please enter the job function'})
      // } else if (this.state.hoursPerWeek === '') {
      //   this.setState({ serverErrorMessage: 'Please enter the hours per week'})
      // } else if (this.state.hireCount === '') {
      //   this.setState({ serverErrorMessage: 'Please enter the amount of hires you intend for this role'})
      // } else if (this.state.startDate === '') {
      //   console.log('lets see value', this.state.startDate)
      //   this.setState({ serverErrorMessage: 'please add the ideal start date' })
      // } else if (this.state.isPerpetual === false &&  this.state.endDate === '') {
      //   console.log('were in first endDate')
      //   this.setState({ serverErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role' })
      // } else if (this.state.description === '') {
      //   console.log('were in description')
      //   this.setState({ serverErrorMessage: 'please add a job description' })
      if (this.state.password === '') {
        this.setState({ serverErrorMessage: 'Please enter your password', isSaving: false })
      } else {
        console.log('did we get in here tho?')

        this.setState({ formComplete: true })

        // const title = this.state.title
        // const location = this.state.location
        // const functionValue = this.state.function
        // const hoursPerWeek = this.state.hoursPerWeek
        // const hireCount = this.state.hireCount
        // const startDate = this.state.startDate
        // const isPerpetual = this.state.isPerpetual
        // const endDate = this.state.endDate
        // const description = this.state.description
        //
        // let postings = this.state.postings
        // postings.push({title, location, function: functionValue, hoursPerWeek, hireCount, startDate, isPerpetual, endDate, description })

        let postings = this.state.postings
        //save us
        const firstName = this.state.contactFirstName
        const lastName = this.state.contactLastName
        const username = this.state.username
        const email = this.state.contactEmail
        const password = this.state.password

        const platform = 'web'

        const orgMode = true
        const activeOrg = this.state.org
        const orgName = this.state.orgName
        let orgContactEmail = this.state.orgContactEmail
        let orgContactFirstName = this.state.orgContactFirstName
        let orgContactLastName = this.state.orgContactLastName
        const orgContactTitle = this.state.orgContactTitle
        const orgProgramName = this.state.orgProgramName
        const orgCCEmails = this.state.orgCCEmails

        const createdAt = new Date()
        const updatedAt = new Date()

        let trimmedName = this.state.employerName.trim()
        let accountCode = ''
        if (!this.state.accountCode || this.state.accountCode === '') {
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        } else {
          accountCode = this.state.accountCode
        }

        this.setState({ accountCode })

        Axios.post('/api/employer/org/register', {
          firstName,lastName, username, email, password,
          createdAt, updatedAt, platform, isEmployer: true, roleName: 'Employer',
          jobTitle: this.state.contactTitle, employerName: this.state.employerName,
          orgMode, activeOrg, orgName, orgContactEmail, orgCCEmails,
          orgContactFirstName, orgContactLastName, orgContactTitle, orgProgramName, accountCode })
        .then((response) => {

          localStorage.setItem('email', email)//this.props.auth.email
          localStorage.setItem('username', username)
          localStorage.setItem('firstName', firstName)
          localStorage.setItem('lastName', lastName)
          localStorage.setItem('isEmployer', 'true')

          if (response.data.success) {
            //save values
            console.log('Employer register worked', response.data);

            this.setState({ serverSuccessMessage: 'Employer user account saved successfully!' })

            //save account
            const employerName = this.state.employerName
            const employerURL = this.state.employerURL
            const employerType = this.state.employerType
            const employerIndustry = this.state.employerIndustry
            const employeeCount = this.state.employeeCount
            const employerLocation = this.state.employerLocation
            const projectedHires = this.state.projectedHires
            const contactFirstName = this.state.contactFirstName
            const contactLastName = this.state.contactLastName
            const contactTitle = this.state.contactTitle
            const contactEmail = this.state.contactEmail
            const contactPhone = this.state.contactPhone

            if (this.state.referrerName && this.state.referrerOptions) {
              for (let i = 1; i <= this.state.referrerOptions.length; i++) {
                const tempName = this.state.referrerOptions[i - 1].contactFirstName + ' ' + this.state.referrerOptions[i - 1].contactLastName
                console.log('tempName to referrer', tempName, this.state.referrerName)
                if (tempName === this.state.referrerName) {
                  orgContactFirstName = this.state.referrerOptions[i - 1].contactFirstName
                  orgContactLastName = this.state.referrerOptions[i - 1].contactLastName
                  orgContactEmail = this.state.referrerOptions[i - 1].contactEmail
                }
              }
            }

            // adjusting array on the back-end, in real-time, if there is an existing account
            let sharePartners = this.state.sharePartners
            if (sharePartners && !sharePartners.includes(this.state.org)) {
              sharePartners.push(this.state.org)
            } else if (!sharePartners) {
              sharePartners = [this.state.org]
            }

            let orgContacts = this.state.orgContacts
            console.log('email already exists? ', this.state.orgContacts)
            if (orgContacts && orgContacts.length) {
              let alreadyExists = false
              for (let i = 1; i <= orgContacts.length; i++) {
                if (orgContacts[i - 1].contactEmail === this.state.contactEmail) {
                  alreadyExists = true
                }
              }

              console.log('email already exists 2? ', alreadyExists, orgContacts, this.state.contactEmail)

              if (!alreadyExists) {
                const orgContactObject = { orgCode: activeOrg, contactFirstName, contactLastName,
                  contactTitle, contactEmail, contactPhone, orgContactFirstName, orgContactLastName, orgContactEmail }
                orgContacts.push(orgContactObject)
              }

            } else {
              const orgContactObject = { orgCode: activeOrg, contactFirstName, contactLastName,
                contactTitle, contactEmail, contactPhone, orgContactFirstName, orgContactLastName, orgContactEmail }
              orgContacts = [orgContactObject]
            }
            // const orgContacts = { orgCode: activeOrg, contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone }
            console.log('show orgContacts: ', orgContacts)

            const hasFunds = this.state.hasFunds
            const canHost = this.state.canHost
            const hostUndocumented = this.state.hostUndocumented
            const employerDescription = this.state.employerDescription
            const officeType = this.state.officeType
            const politicalAlignment = this.state.politicalAlignment
            const stateRegistration = this.state.stateRegistration
            const congressionalDistrict = this.state.congressionalDistrict
            const additionalInformation = this.state.additionalInformation

            const includeHighSchool = this.state.includeHighSchool
            const includeAssociates = this.state.includeAssociates
            const includeBachelors = this.state.includeBachelors

            const successMetrics = this.state.successMetrics
            const volunteerPreferences = this.state.volunteerPreferences

            const isLATechReferral = this.state.isLATechReferral
            const ats = this.state.ats
            const wantsInterns = this.state.wantsInterns
            const hireTimeframes = this.state.hireTimeframes

            const referrerName = this.state.referrerName
            const referralExplanation = this.state.referralExplanation

            Axios.post('/api/account/update', {
              employerName, employerURL, employerType, employerIndustry, employeeCount,
              employerLocation, accountCode, projectedHires, orgContacts,
              contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone,
              hasFunds, canHost, hostUndocumented, description: employerDescription, successMetrics, volunteerPreferences,
              officeType, politicalAlignment, stateRegistration, congressionalDistrict, additionalInformation,
              includeHighSchool, includeAssociates, includeBachelors,
              isLATechReferral, ats, wantsInterns, hireTimeframes,
              orgContactFirstName, orgContactLastName, orgContactEmail,
              referrerName, referralExplanation,
              sharePartners, activeOrg, createdAt, updatedAt })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Account update save worked', response.data);

                localStorage.setItem('activeOrg', this.state.org)
                localStorage.setItem('sharePartners', JSON.stringify([this.state.org]))

                this.setState({ serverSuccessMessage: 'Employer account saved successfully!' })

                console.log('after account update', accountCode)

                const postIndividualPost = async(item, index, passedStateArray) => {
                  console.log('postIndividualPost ', item, index, this.state.postings[index])

                  const newIndex = index

                  //save postings
                  if (item.postType === 'Internship') {
                    //work posting
                    console.log('got in work posting')

                    // const postType = 'Individual'
                    const postType = item.postType
                    const subPostType = 'Individual'
                    const payType = "Hourly"
                    const payRange = "$10 - $20 / Hour"

                    const title = item.title
                    const location = item.location
                    const type = "Internship"
                    const field = item.function + " | " + employerIndustry

                    let startDate = undefined
                    if (item.startDate) {
                      startDate = convertStringToDate(item.startDate,"dateOnly")
                    }

                    const isPerpetual = item.isPerpetual
                    let endDate = undefined
                    if (item.endDate) {
                      endDate = convertStringToDate(item.endDate,"dateOnly")
                    }

                    const hoursPerWeek = item.hoursPerWeek
                    const hireCount = item.hireCount

                    const description = item.description

                    let employerEmployees = employeeCount
                    let employerGrowth = 'Undislosed'
                    const imageURL = this.state.employerLogoURI

                    let applicationComponents = ['basicInfo','resume','interests','skills','personality','endorsements']
                    const appComponents = [
                      { name: 'Basic Profile Info', include: true, required: true, disabled: true },
                      { name: 'Profile Details', include: true, required: false, disabled: true },
                      { name: 'Resume', include: true, required: true, disabled: false },
                      { name: 'Cover Letter', include: false, required: false, disabled: false },
                      { name: 'Academic Transcript', include: false, required: true, disabled: false },
                      { name: 'Letter of Recommendation', include: false, required: true, disabled: false },
                      { name: 'Identification', include: false, required: true, disabled: false },
                      { name: 'Career Assessments', include: true, required: true, disabled: false },
                      { name: 'Endorsements', include: true, required: false, disabled: false },
                      { name: 'Custom Assessment', include: false, required: true, disabled: false },
                    ]

                    const subjectLine = item.subjectLine
                    const appRequirements = item.appRequirements

                    let submissionDeadline = undefined
                    if (item.submissionDeadline) {
                      submissionDeadline = convertStringToDate(item.submissionDeadline,"dateOnly")
                    }

                    const postPoliticalParty = item.politicalParty
                    const applicationMethod = item.applicationMethod
                    const jobLink = item.jobLink

                    let interests = ['Realistic']
                    let skills = ['Wireframing']
                    let traits = ['Resilience']

                    let customAssessmentId = ''

                    let benchmarkId = ''
                    let useCustomBenchmark = false
                    let isChild = false
                    let parentPostId = null
                    let trackIndex = 0

                    if (this.state.automaticallyAttach && this.state.parentPost) {
                      isChild = true
                      parentPostId = this.state.parentPostId

                      let tracks = this.state.tracks
                      for (let j = 1; j <= tracks.length; j++) {
                        console.log(j)

                        if (tracks[j - 1].benchmark.jobFunction === item.function) {
                          benchmarkId = tracks[j - 1].benchmark._id
                          useCustomBenchmark = true
                          trackIndex = j - 1
                        }
                      }
                    }

                    let standardBenchmark = null
                    if (useCustomBenchmark === false) {
                      //if there is no saved benchmark for this function
                      for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                        console.log(j)

                        if (this.state.benchmarkOptions[j - 1].jobFunction === item.function) {
                          benchmarkId = this.state.benchmarkOptions[j - 1]._id
                          standardBenchmark = this.state.benchmarkOptions[j - 1]
                        }
                      }
                    }
                    console.log('benchmarkId 1: ', benchmarkId)
                    if (benchmarkId === '' && this.state.benchmarkOptions[1]) {
                      benchmarkId = this.state.benchmarkOptions[1]._id
                    }
                    console.log('benchmarkId 2: ', benchmarkId)
                    if (this.state.customBenchmarkId && this.state.customBenchmarkId !== '') {
                      benchmarkId = this.state.customBenchmarkId
                    }
                    console.log('benchmarkId 3: ', benchmarkId)
                    const usePrimaryEmployerContact = true
                    let employerContactFirstName = contactFirstName
                    let employerContactLastName = contactLastName
                    let employerContactEmail = contactEmail
                    if (this.state.org === 'c2c' || this.state.org === 'unite-la') {
                      employerContactFirstName = item.employerContactFirstName
                      employerContactLastName = item.employerContactLastName
                      employerContactEmail = item.employerContactEmail
                    }

                    const employerContactPhone = contactPhone
                    const usePrimaryOrgContact = true

                    const candidatesOfInterest = item.candidatesOfInterest

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail
                    // if (this.state.referrerName && this.state.referrerOptions) {
                    //   for (let i = 1; i <= this.state.referrerOptions.length; i++) {
                    //     const tempName = this.state.referrerOptions[i - 1].contactFirstName + ' ' + this.state.referrerOptions[i - 1].contactLastName
                    //     if (tempName === this.state.referrerName) {
                    //       orgContactFirstName = this.state.referrerOptions[i - 1].contactFirstName
                    //       orgContactLastName = this.state.referrerOptions[i - 1].contactLastName
                    //       orgContactEmail = this.state.referrerOptions[i - 1].contactEmail
                    //     }
                    //   }
                    // }

                    const applications = []
                    const applicants = []
                    const orgCode = this.state.org

                    let orgName = ''
                    if (this.state.orgName) {
                      orgName = this.state.orgName
                    }

                    let orgURL = ''
                    if (this.state.orgURL) {
                      orgURL = this.state.orgURL
                    }

                    let orgMission = ''
                    if (this.state.orgMission) {
                      orgMission = this.state.orgMission
                    }

                    const posterEmail = this.state.contactEmail
                    const isActive = true

                    const createdAt = new Date()
                    const updatedAt = new Date()

                    return Axios.post('/api/postings', {
                      postType, subPostType, title, location, type, field, payType, payRange, description,
                      employerName, employerURL, employerType, employerEmployees, employerGrowth, imageURL,
                      applicationComponents, appComponents,
                      applications, applicants,
                      orgCode, interests, skills, traits, customAssessmentId, benchmarkId,
                      hoursPerWeek, hireCount, startDate, isPerpetual, isChild, parentPostId, posterEmail,
                      subjectLine, appRequirements, submissionDeadline, politicalParty: postPoliticalParty, applicationMethod, jobLink,
                      accountCode, usePrimaryEmployerContact, employerContactFirstName, employerContactLastName,
                      employerContactEmail, employerContactPhone, usePrimaryOrgContact, candidatesOfInterest,
                      orgContactFirstName, orgContactLastName, orgContactEmail, referrerName, referralExplanation,
                      orgName, orgURL, orgMission, endDate, isActive, createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        const postingId = response.data._id

                        // const passedState = { isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName,
                        //   orgName, orgContactEmail, firstName, postingId }
                        passedStateArray.push({ isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName, postType,
                          orgName, orgContactEmail, firstName, postingId })

                        console.log('show passedState 1: ', newIndex, passedStateArray)
                        if (newIndex === this.state.postings.length - 1) {
                          loopThroughIndividuals(passedStateArray)
                        }

                        // return passedState
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({
                          serverPostSuccess: false,
                          serverErrorMessage: response.data.message,
                        })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });
                  } else if (item.postType === 'Event') {
                    console.log('got in event posting')

                    const postType = item.postType
                    const title = item.title
                    const eventType = item.eventType
                    const location = item.location
                    const startDate = item.startDate

                    const surveyId = '5e41f8bcd03e055d7c6143ae'

                    const willEmployerLead = 'One Employer Will Lead'

                    const employerContactFirstName = contactFirstName
                    const employerContactLastName = contactLastName
                    const employerContactEmail = contactEmail

                    // const accountCode = this.state.selectedEmployer.accountCode

                    // const summary = this.state.summary
                    // const endDate = this.state.endDate
                    // const locationType = this.state.locationType
                    // const transportationDetails = this.state.transportationDetails
                    // const links = this.state.links
                    // const featured = this.state.featured

                    const status = 'Undecided'
                    const isActive = true

                    let orgCode = this.state.org
                    let posterEmail = this.state.contactEmail

                    let contributorFirstName = firstName
                    let contributorLastName = lastName
                    let contributorEmail = email
                    let contributorTitle = this.state.contactTitle
                    let contributorRoleName = 'Employer'

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail
                    let orgName = this.state.orgName
                    const emailId = this.state.contactEmail

                    const requestMode = true
                    const createdAt = new Date()
                    const updatedAt = new Date()

                    //request to post event
                    return Axios.post('/api/postings', {
                      postType, title, eventType, location, startDate, surveyId, willEmployerLead,
                      accountCode, employerName, employerURL, employerType,
                      employerContactFirstName, employerContactLastName, employerContactEmail,
                      status, isActive, orgCode, posterEmail, emailId,
                      contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorRoleName,
                      orgContactFirstName, orgContactLastName, orgContactEmail, orgName, requestMode,
                      createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        // const postingId = response.data._id

                        // const passedState = { isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName,
                        //   orgName, orgContactEmail, firstName, postingId }
                        // passedStateArray.push({ isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName, postType,
                        //   orgName, orgContactEmail, firstName, postingId })
                        // if (newIndex === this.state.postings.length - 1) {
                        //   loopThroughIndividuals(passedStateArray)
                        // }

                        // return passedState
                        const passedStateArray = { postType }
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({
                          serverPostSuccess: false,
                          serverErrorMessage: response.data.message,
                        })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });

                  } else if (item.postType === 'Project' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenge') {
                    //request to post project opportunity
                    console.log('got in problem posting')

                    const postType = item.postType
                    const projectPromptType = item.projectPromptType
                    const name = item.name
                    const duration = item.duration
                    const tags = item.tags
                    const submissionDeadline = item.submissionDeadline
                    const knowledgeLevel = item.knowledgeLevel
                    const description = item.description

                    const employerContactFirstName = contactFirstName
                    const employerContactLastName = contactLastName
                    const employerContactEmail = contactEmail

                    const status = 'Undecided'
                    let orgCode = this.state.org
                    let posterEmail = this.state.contactEmail

                    let contributorFirstName = firstName
                    let contributorLastName = lastName
                    let contributorEmail = email
                    let contributorTitle = this.state.contactTitle
                    let contributorRoleName = 'Employer'

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail
                    let orgName = this.state.orgName
                    const emailId = this.state.contactEmail

                    const requestMode = true
                    const createdAt = new Date()
                    const updatedAt = new Date()

                    const upvotes = []
                    const downvotes = []
                    const difficultyLevel = 'Medium'

                    const isExternal = false
                    const isExternalPost = false

                    let pointValue = 0
                    if (difficultyLevel === 'Very Easy') {
                      pointValue = 1
                    } else if (difficultyLevel === 'Easy') {
                      pointValue = 3
                    } else if (difficultyLevel === 'Medium') {
                      pointValue = 4
                    } else if (difficultyLevel === 'Hard') {
                      pointValue = 7
                    } else if (difficultyLevel === 'Challenger') {
                      pointValue = 10
                    } else {
                      pointValue = 5
                    }

                    //request to post event
                    return Axios.post('/api/postings', {
                      postType, projectPromptType, name, duration, tags,
                      employerName, employerURL, employerType, difficultyLevel,
                      employerContactFirstName, employerContactLastName, employerContactEmail,
                      contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorRoleName,
                      pointValue, upvotes, downvotes,
                      submissionDeadline, knowledgeLevel, description,
                      isExternal, emailId, posterEmail,
                      orgContactFirstName, orgContactLastName, orgContactEmail,
                      accountCode, orgCode, orgName, requestMode, isExternalPost, status,
                      createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        // const postingId = response.data._id

                        // const passedState = { isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName,
                        //   orgName, orgContactEmail, firstName, postingId }
                        // passedStateArray.push({ isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName, postType,
                        //   orgName, orgContactEmail, firstName, postingId })
                        // if (newIndex === this.state.postings.length - 1) {
                        //   loopThroughIndividuals(passedStateArray)
                        // }

                        // return passedState
                        const passedStateArray = { postType }
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({
                          serverPostSuccess: false,
                          serverErrorMessage: response.data.message,
                        })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });

                  } else {
                    console.log('got in nothing')
                  }
                }

                const editParentPost = async(index, passedState) => {
                  console.log('editParentPost ', index, passedState)

                  let newAttachMethod = true
                  if (newAttachMethod) {
                    // attach on the backend

                    const postingId = passedState.postingId
                    const title = passedState.title
                    const employerName = passedState.employerName


                    const useCustomBenchmark = passedState.useCustomBenchmark
                    const standardBenchmark = passedState.standardBenchmark
                    const trackIndex = passedState.trackIndex
                    let trackFunctions = this.state.trackFunctions

                    const orgName = passedState.orgName
                    const orgContactEmail = passedState.orgContactEmail
                    const firstName = passedState.firstName

                    const employerContactFirstName = passedState.employerContactFirstName
                    const employerContactLastName = passedState.employerContactLastName
                    const employerContactEmail = passedState.employerContactEmail

                    const postToAttach = { _id: postingId, title, employerName }

                    return Axios.post('/api/postings/attach-to-parent', {
                      _id: this.state.parentPost._id, postToAttach,
                      useCustomBenchmark, standardBenchmark, trackIndex, trackFunctions,
                      employerContactFirstName, employerContactLastName, employerContactEmail,
                      orgName, orgContactEmail, firstName })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Attach to parent worked', response.data);

                        const tracks = response.data.tracks
                        trackFunctions = response.data.trackFunctions

                        this.setState({ tracks, trackFunctions })
                        return response.data.success
                      } else {
                        console.error('there was an error attaching the posting to parent');
                        return response.data.success
                      }
                    }).catch((error) => {
                        console.log('The job attach to parent did not work', error);
                        return false
                    });
                  } else {

                    // const postingId = passedState.postingId
                    // const useCustomBenchmark = passedState.useCustomBenchmark
                    // const trackIndex = passedState.trackIndex
                    // const standardBenchmark = passedState.standardBenchmark
                    // const title = passedState.title
                    // const employerName = passedState.employerName
                    // const orgName = passedState.orgName
                    // const orgContactEmail = passedState.orgContactEmail
                    // const firstName = passedState.firstName
                    // const updatedAt = new Date()
                    //
                    // let tracks = this.state.tracks
                    // if (useCustomBenchmark) {
                    //   //add to current track
                    //   console.log('testing 6', tracks[trackIndex])
                    //   let jobs = tracks[trackIndex].jobs
                    //   jobs.push({ _id: postingId, title, employerName })
                    //   tracks[trackIndex] = ({ name: tracks[trackIndex].name, benchmark: {_id: tracks[trackIndex].benchmark._id, title: tracks[trackIndex].benchmark.title, jobFunction: tracks[trackIndex].benchmark.jobFunction}, jobs, approvedApplicants: tracks[trackIndex].approvedApplicants})
                    //   console.log('testing 7', tracks[trackIndex])
                    // } else {
                    //   console.log('testing 8')
                    //   //add new track
                    //   if (standardBenchmark) {
                    //     console.log('testing 9')
                    //     if (this.state.trackFunctions.includes(standardBenchmark.jobFunction)) {
                    //       //add to existing track
                    //       for (let j = 1; j <= this.state.tracks.length; j++) {
                    //         if (this.state.tracks[j - 1].jobFunction === standardBenchmark.jobFunction) {
                    //           let trackIndex2 = j - 1
                    //           let jobs = tracks[trackIndex2].jobs
                    //           jobs.push({ _id: postingId, title, employerName })
                    //           tracks[trackIndex2] = ({ name: tracks[trackIndex2].name, benchmark: {_id: tracks[trackIndex2].benchmark._id, title: tracks[trackIndex2].benchmark.title, jobFunction: tracks[trackIndex2].benchmark.jobFunction}, jobs, approvedApplicants: tracks[trackIndex2].approvedApplicants})
                    //         }
                    //       }
                    //
                    //     } else {
                    //
                    //       //add new track
                    //       const trackName = standardBenchmark.jobFunction + " Track"
                    //       const trackBenchmark = { _id: standardBenchmark._id, title: standardBenchmark.title, jobFunction: standardBenchmark.jobFunction }
                    //       const trackJobs = [{ _id: postingId, title, employerName }]
                    //       const trackApprovedApplicants = []
                    //
                    //       let trackFunctions = this.state.trackFunctions
                    //
                    //       tracks.push({ name: trackName, benchmark: trackBenchmark, jobs: trackJobs, approvedApplicants: trackApprovedApplicants })
                    //       trackFunctions.push(standardBenchmark.jobFunction)
                    //
                    //       this.setState({ tracks, trackFunctions })
                    //
                    //     }
                    //   }
                    // }
                    // console.log('testing success:', index, tracks)
                    //
                    // const postingTitle = title
                    // const postingEmployerName = employerName
                    //
                    // return Axios.post('/api/postings', {
                    //   _id: this.state.parentPost._id, tracks, trackIndex, orgName,
                    //   orgContactEmail, firstName, postingTitle, postingEmployerName, updatedAt })
                    // .then((response) => {
                    //
                    //   if (response.data.success) {
                    //     //save values
                    //     console.log('Attach to parent worked', response.data);
                    //     return response.data.success
                    //   } else {
                    //     console.error('there was an error posting the job');
                    //     return response.data.success
                    //   }
                    // }).catch((error) => {
                    //     console.log('The job post did not work', error);
                    //     return false
                    // });
                  }
                }

                let parentPostEdits = []
                let passedStateArray = []

                //add events
                if (this.state.hostedEvents) {
                  postings = postings.concat(this.state.hostedEvents)
                }
                //add problems
                if (this.state.problems) {
                  postings = postings.concat(this.state.problems)
                }

                console.log('show postings: ', postings, this.state.hostedEvents)

                postings.forEach(async(item, index) => {
                  console.log('log it once: ', item, index)

                  const passedState = await postIndividualPost(item, index, passedStateArray)
                  passedStateArray = passedState

                  console.log('show passedState 2', passedState)
                  if (index === postings.length - 1) {
                    this.setState({ isSaving: false })
                  }

                  if (passedState) {
                    if (passedState.isChild) {
                      parentPostEdits.push(passedState)
                    }
                  }
                })

                const loopThroughIndividuals = async(passedStateArray) => {
                  console.log('loopThroughIndividuals', passedStateArray.length)
                  if (this.state.org === 'bixel' || this.state.org === 'unite-la') {
                    //attach to track parent posting
                    for (let i = 1; i <= passedStateArray.length; i++) {
                      console.log('log passedStateArray ', 1)

                      const index = i - 1
                      const passedPostType = passedStateArray[index].postType
                      console.log('show passedPostType: ', passedStateArray[index], passedPostType)
                      if (passedPostType === 'Work' || passedPostType === 'Internship' || passedPostType === 'Individual') {

                        const success = await editParentPost(index, passedStateArray[index])
                        console.log('lot it thrice ', index, success)
                      }
                    }
                  }
                  console.log('end forLoop')
                }

                console.log('end save')

              } else {
                console.error('there was an error saving the employer ', response.data.message);
                this.setState({ serverErrorMessage: response.data.message })
              }
            }).catch((error) => {
                console.log('The employer save did not work', error);
            });

          } else {
            console.error('there was an error saving the user account ', response.data.message);
            this.setState({ serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The employer save did not work', error);
        });
      }
    }

    renderPage(pageNumber) {
      console.log('renderPage', pageNumber)

      const totalPages = 4 + Number(this.state.postCount)

      const introSummary = 'first second third\n\nfourth'
      console.log('compare: ', introSummary, this.state.introSummary)

      let rows = []
      if (pageNumber === 1) {

        rows.push(
          <div key={0} className="prewrap">
            <div className="spacer"/>

            <div className="progress-bar" >
              <div className="filler" style={{ width: Math.round((pageNumber * 100) / totalPages) + '%' }} />
            </div>

            <div className="spacer" /><div className="spacer" /><div className="spacer" />

            <p className="description-text-1">{this.state.introSummary}</p>
            <div className="spacer" /><div className="half-spacer" />
            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
                <input className="text-field" type="text" name="contactFirstName"
                ref="firstName" placeholder="First Name" value={this.state.contactFirstName}
                onChange={this.formChangeHandler}
                />
              </div>
              <div className="name-container">
                <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
                <input className="text-field" type="text" name="contactLastName"
                  ref="lastName" placeholder="Last Name" value={this.state.contactLastName}
                  onChange={this.formChangeHandler}
                />
              </div>
              <div className="clear"/>
            </div>

            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">{this.state.org === 'c2c' ? "Congressional Office or Company/Organization" : "Company Name"}<label className="error-color bold-text">*</label></label>
                <input type="text" className="text-field" placeholder="Add the company name..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}></input>
                {(this.state.employers.length > 0) && (
                  <div>
                    <div className="spacer"/>
                    {this.state.employers.map(value =>
                      <div key={value._id} className="left-text bottom-margin-5">
                        <button className="background-button" onClick={() => this.employerClicked(value)}>
                          <div>
                            <div className="float-left right-padding">
                              <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                            </div>
                            <div className="float-left">
                              <p className="cta-color">{value.employerName}</p>
                            </div>
                          </div>
                        </button>
                      </div>)}
                  </div>
                )}
              </div>
              <div className="name-container">
                <div>
                  <label className="profile-label">Your Position<label className="error-color bold-text">*</label></label>
                  <input type="text" className="text-field" placeholder="Add your title..." name="contactTitle" value={this.state.contactTitle} onChange={this.formChangeHandler}></input>
                </div>

              </div>

              <div className="clear" />
            </div>

            {(this.state.org === 'dpscd') && (
              <div className="row-10">
                <div className="name-container">
                  <label className="profile-label">Who referred you?<label className="error-color bold-text">*</label></label>
                  <select name="referrerName" value={this.state.referrerName} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.referrerOptions.map(value =>
                      <option key={value} value={(value.contactFirstName) && value.contactFirstName + ' ' + value.contactLastName}>{(value.contactFirstName) && value.contactFirstName + ' '+ value.contactLastName}</option>
                    )}
                  </select>
                </div>
                {(this.state.referrerName === 'Other' || this.state.referrerName === 'Other ') && (
                  <div className="name-container">
                    <div>
                      <label className="profile-label">If other, please explain:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Someone else..." name="referralExplanation" value={this.state.referralExplanation} onChange={this.formChangeHandler}></input>
                    </div>

                  </div>
                )}

                <div className="clear" />
              </div>
            )}

            <div className="row-5 right-padding-20">
              <div className="float-right">
                <button className="btn btn-primary" onClick={() => this.nextPage()}>Next</button>
              </div>
              <div className="clear" />
              <div className="float-right">
                {(this.state.serverErrorMessage !== '') && (
                  <p className="login-error-message">{this.state.serverErrorMessage}</p>
                )}
              </div>
              <div className="clear" />
            </div>

          </div>
        )
      } else if (pageNumber === 2) {

        rows.push(
          <div key={0}>
            <div>
              <div className="spacer"/>

              <div className="progress-bar" >
                <div className="filler" style={{ width: Math.round((pageNumber * 100) / totalPages) + '%' }} />
              </div>

              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

              <p className="heading-text-3">Employer Details</p>
              <p className="description-text-2 description-text-color">These details help to match you with students. Information is saved after you post one opportunity with us, so you will only need to fill out your employer details once.</p>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">{this.state.org === 'c2c' ? "Office Location" : "Employer Office Address"}<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="employerLocation"
                    placeholder="E.g. 123 Fun Rd. Los Angeles, CA 90062" value={this.state.employerLocation}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="name-container">
                  <label className="profile-label">{this.state.org === 'c2c' ? "Website" : "Employer URL"}<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="employerURL"
                    placeholder={this.state.org === 'c2c' ? "Member or Committee Website" : "Employer URL"} value={this.state.employerURL}
                    onChange={this.formChangeHandler}
                  />
                  {(this.state.employerURL && this.state.employerURL !== '' && !this.state.employerURL.startsWith('http')) && (
                    <div>
                      <p className="error-message">Please start your link with http</p>
                    </div>
                  )}
                </div>
                <div className="clear" />

              </div>



              {(this.state.org === 'c2c') ? (
                <div>
                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Type<label className="error-color bold-text">*</label></label>
                      <select name="officeType" value={this.state.officeType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.officeTypeOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Political Alignment<label className="error-color bold-text">*</label></label>
                      <select name="politicalAlignment" className="dropdown" value={this.state.politicalAlignment} onChange={this.formChangeHandler}>
                          {this.state.politicalAlignmentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">State Represented (If Applicable)</label>
                      <select name="stateRegistration" className="dropdown" value={this.state.stateRegistration} onChange={this.formChangeHandler}>
                          {this.state.registrationOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">District Number (If Applicable)</label>
                      <input className="text-field" type="text" placeholder="e.g. 12" name="congressionalDistrict" value={this.state.congressionalDistrict} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Employer Type<label className="error-color bold-text">*</label></label>
                      <select name="employerType" value={this.state.employerType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.typeOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Employer Industry<label className="error-color bold-text">*</label></label>
                      <select name="employerIndustry" value={this.state.employerIndustry} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.industryOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Employee Count<label className="error-color bold-text">*</label></label>
                      <select name="employeeCount" value={this.state.employeeCount} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.countOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              )}

              <div className="edit-profile-row">
                <label className="profile-label">Employer Description</label>
                <textarea type="text" className="text-field" placeholder="…describe your company and team culture as it relates to candidates" name="employerDescription" value={this.state.employerDescription} onChange={this.formChangeHandler}></textarea>
              </div>

            </div>

            <div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Your Email (Contact Email)<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="email" name="contactEmail"
                    ref="email" placeholder="Email" value={this.state.contactEmail}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="name-container">
                  <label className="profile-label">Your Phone Number (Contact Phone Number)<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="contactPhone"
                    placeholder="Phone Number" value={this.state.contactPhone}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="clear" />
              </div>
            </div>

            <div className="spacer" /><div className="spacer" />

            <div>
              <div className="fixed-column-100">
                <button className="btn btn-secondary" onClick={() => this.previousPage()}>Back</button>

              </div>
              <div className="calc-column-offset-200 height-30" />
              <div className="fixed-column-100">
                <button className="btn btn-primary" onClick={() => this.nextPage()}>Next</button>
              </div>
              <div className="clear" />
              {(this.state.serverErrorMessage !== '') && (
                <p className="login-error-message">{this.state.serverErrorMessage}</p>
              )}
            </div>
          </div>
        )
      } else if (pageNumber === 3) {

        rows.push(
          <div key={0}>
            <div>

              <div className="spacer"/>

              <div className="progress-bar" >
                <div className="filler" style={{ width: Math.round((pageNumber * 100) / totalPages) + '%' }} />
              </div>

              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

              {(this.state.showModule) ? (
                <div>
                  {/*
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="heading-text-3">#1: Hosting Career Events<label className="error-color bold-text">*</label></p>
                  <div className="spacer"/>

                  <div className="edit-profile-row">
                    <label className="profile-label">Would you please list any career events (e.g. company tours, workshops, job shadows) you can host that students may attend? (they do not need to be finalized)<label className="error-color bold-text">*</label></label>
                    <select name="willHostEvents" value={this.state.willHostEvents} className="dropdown" onChange={this.formChangeHandler}>
                        <option />
                        <option value={true}>Yes</option>
                        <option value={false}>No, I have no relevant career events to list</option>
                    </select>

                  </div>
                  {console.log('willHostEvents: ', this.state.willHostEvents)}
                  {(this.state.willHostEvents === true || this.state.willHostEvents === 'true') && (
                    <div>
                      <div>
                        <p className="success-message">Great, please add any events that you would like to invite our students to attend below.</p>
                        <div className="spacer"/><div className="half-spacer"/>
                      </div>

                      {this.renderItems('hostEvents')}

                      <div className="add-item-line-button">
                        <hr />
                        <a className="background-button" onClick={() => this.addItem('hostEvents')}>
                          <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <p className="heading-text-3">#2: Crowdsource Solutions<label className="error-color bold-text">*</label></p>
                  <div className="spacer"/>

                  <div className="edit-profile-row">
                    <label className="profile-label">Are you interested in posting on our Problem Platform to crowdsource project-based solutions to problems you’re having at the company? <a href="https://www.guidedcompass.com/problem-platform" target="_blank">[Learn more here]</a><label className="error-color bold-text">*</label></label>
                    <select name="willPostProblem" value={this.state.willPostProblem} className="dropdown" onChange={this.formChangeHandler}>
                        <option />
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                  </div>

                  {(this.state.willPostProblem === true || this.state.willPostProblem === 'true') && (
                    <div>
                      <div>
                        <p className="success-message">Great, please add preliminary ideas for posts to our problem platform below.</p>
                        <div className="spacer" /><div className="half-spacer" />
                      </div>

                      {this.renderItems('problems')}

                      <div className="add-item-line-button">
                        <hr />
                        <a className="background-button" onClick={() => this.addItem('problems')}>
                          <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  {(this.state.showMentorships === true) && (
                    <div>
                      <p className="heading-text-3">#4: Mentorships<label className="error-color bold-text">*</label></p>
                      <div className="spacer"/>

                      <div className="edit-profile-row">
                        <label className="profile-label">Are there employees willing to mentor students? Our mentor portal allows those mentors to post to the Problem Platform (and ”grade” top submissions), match to students at events, and refer students for roles. <a href="https://www.guidedcompass.com/mentors" target="_blank">[Learn more here]</a><label className="error-color bold-text">*</label></label>
                      </div>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    </div>
                  )}*/}

                  <div className="float-left">
                    <p className="heading-text-3">Internships<label className="error-color bold-text">*</label></p>
                  </div>

                  <div className="clear" />
                  <div className="spacer"/>
                  {console.log('show the organ: ', this.state.org)}
                  {(this.state.org === 'unite-la' || this.state.org === 'block' || this.state.org === 'exp') ? (
                    <div>
                      <div className="edit-profile-row">
                        <div className="name-container">
                          <div>
                            <div className="calc-column-offset-40">
                              <label className="profile-label">Would you like interns in the next year?<label className="error-color bold-text">*</label></label>
                            </div>
                            <div>
                              <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <div className="float-left left-margin noti-bubble-info-7-9">
                                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: true, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false })}>
                                  <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                </a>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                          <select name="wantsInterns" value={this.state.wantsInterns} onChange={this.formChangeHandler} className="dropdown">
                            <option value={null}></option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        {(this.state.wantsInterns && this.state.wantsInterns === 'true') && (
                          <div className="name-container">
                            <label className="profile-label">How many interns would you like?<label className="error-color bold-text">*</label></label>
                            <select name="projectedHires" value={this.state.projectedHires} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.projectedInternOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      {(this.state.wantsInterns && this.state.wantsInterns === 'true') && (
                        <div>
                          <div className="edit-profile-row">
                            <div className="name-container">
                              <label className="profile-label">During what time frame are you interested in hosting these interns?<label className="error-color bold-text">*</label></label>
                              {this.state.timeframeOptions.map((value, optionIndex) =>
                                <div key={"success|" + optionIndex} className="float-left">
                                  <a className="background-link" onClick={() => this.addItem('timeframe', value)}>
                                    <div className="float-left row-5 right-padding">
                                      {(this.state.hireTimeframes.includes(value)) ? (
                                        <div className="tag-container-4">
                                          <p className="description-text-2 white-text">{value}</p>
                                        </div>
                                      ) : (
                                        <div className="tag-container-inactive">
                                          <p className="description-text-2">{value}</p>
                                        </div>
                                      )}
                                    </div>
                                  </a>
                                </div>
                              )}
                            </div>
                            <div className="name-container">
                              <label className="profile-label">How many positions will you post now?<label className="error-color bold-text">*</label></label>
                              <select name="postCount" value={this.state.postCount} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.postCountOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>

                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">
                            {/*
                            <div className="name-container">
                              {(this.state.org === 'c2c') ? (
                                <label className="profile-label">Are these internship positions paid?<label className="error-color bold-text">*</label></label>
                              ) :(
                                <div>
                                  <label className="profile-label">Can you pay these interns a minimum of $14.25 / hr for a minimum of 120 hours?<label className="error-color bold-text">*</label></label>
                                </div>
                              )}
                              <select name="hasFunds" value={this.state.hasFunds} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.binaryOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>*/}
                            <div className="name-container">
                              <label className="profile-label">Please Select Your Applicant Tracking System<label className="error-color bold-text">*</label></label>
                              <select name="ats" value={this.state.ats} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.atsOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            {(this.state.org === 'unite-la') && (
                              <div className="name-container">
                                <label className="profile-label">Were you referred here by LA-Tech.org?<label className="error-color bold-text">*</label></label>
                                <select name="isLATechReferral" value={this.state.isLATechReferral} onChange={this.formChangeHandler} className="dropdown">
                                  <option value={null}></option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>
                            )}

                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">

                            <label className="profile-label">How would you measure success of an internship program?<label className="error-color bold-text">*</label></label>

                            {this.state.successAnswerChoices.map((value, optionIndex) =>
                              <div key={"success|" + optionIndex} className="float-left">
                                <a className="background-link" onClick={() => this.addItem('success', value)}>
                                  <div className="float-left row-5 right-padding">
                                    {(this.state.successMetrics.includes(value)) ? (
                                      <div className="tag-container-4">
                                        <p className="description-text-2 white-text">{value}</p>
                                      </div>
                                    ) : (
                                      <div className="tag-container-inactive">
                                        <p className="description-text-2">{value}</p>
                                      </div>
                                    )}
                                  </div>
                                </a>
                              </div>
                            )}
                            <div className="clear" />
                            {/*
                            <div className="name-container">
                              <label className="profile-label">Do you have the resources to host your interns?<label className="error-color bold-text">*</label></label>
                              <select name="canHost" value={this.state.canHost} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.binaryOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">


                            </div>
                            <div className="clear" />*/}
                          </div>
                        </div>
                      )}

                    </div>
                  ) : (
                    <div>
                      <div className="edit-profile-row">
                        {(this.state.org !== 'dpscd') && (
                          <div className="name-container">
                            <label className="profile-label">How many interns are you interested in hosting?<label className="error-color bold-text">*</label></label>
                            <select name="projectedHires" value={this.state.projectedHires} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.projectedInternOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}

                        <div className="name-container">
                          <label className="profile-label">{(this.state.org === 'dpscd') ? "How many interns are you interested in hosting?" : "How many positions will you post now?"}<label className="error-color bold-text">*</label></label>
                          <select name="postCount" value={this.state.postCount} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.postCountOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.org !== 'dpscd') && (
                        <div>
                          <div className="edit-profile-row">
                            <div className="name-container">
                              {(this.state.org === 'c2c') ? (
                                <label className="profile-label">Are these internship positions paid?<label className="error-color bold-text">*</label></label>
                              ) :(
                                <label className="profile-label">Can you pay these interns a minimum of $14.25 / hr for a minimum of 120 hours?<label className="error-color bold-text">*</label></label>
                              )}
                              <select name="hasFunds" value={this.state.hasFunds} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.binaryOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="name-container">
                              {(this.state.org === 'bixel' || this.state.org === 'unite-la') ? (
                                <div>
                                  <label className="profile-label">Do you have the resources to host your interns?<label className="error-color bold-text">*</label></label>
                                  <select name="canHost" value={this.state.canHost} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.binaryOptions.map(value =>
                                      <option key={value} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                              ) : (
                                <div>
                                  <label className="profile-label">Are you able to host students without U.S. work documentation?<label className="error-color bold-text">*</label></label>
                                  <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                                    {this.state.maybeOptions.map(value =>
                                      <option key={value} value={value}>{value}</option>
                                    )}
                                  </select>
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.org === 'c2c') && (
                    <div className="edit-profile-row">
                      <label className="profile-label">Is there any additional information that we need to share with applicants (e.g. security protocols or dates that your office will be closed)?</label>
                      <textarea type="text" className="text-field" placeholder="Add the additional information..." name="additionalInformation" value={this.state.additionalInformation} onChange={this.formChangeHandler}></textarea>
                    </div>
                  )}

                </div>
              ) : (
                <div>
                  <label className="profile-label">How many interns are you interested in hosting?<label className="error-color bold-text">*</label></label>
                  <select name="projectedHires" value={this.state.projectedHires} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.projectedInternOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>

                  <div className="spacer"/>
                  <label className="profile-label">How many positions will you post now?<label className="error-color bold-text">*</label></label>
                  <select name="postCount" value={this.state.postCount} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.postCountOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>

                  <div className="spacer"/>
                  {(this.state.org === 'c2c') ? (
                    <label className="profile-label">Are these internship positions paid?<label className="error-color bold-text">*</label></label>
                  ) :(
                    <label className="profile-label">Can you pay these interns a minimum of $14.25 / hr for a minimum of 120 hours?<label className="error-color bold-text">*</label></label>
                  )}

                  <select name="hasFunds" value={this.state.hasFunds} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.binaryOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>

                  <div className="spacer"/>

                  {(this.state.org === 'bixel' || this.state.org === 'unite-la') ? (
                    <div>
                      <label className="profile-label">Do you have the resources/space to host your interns?<label className="error-color bold-text">*</label></label>
                      <select name="canHost" value={this.state.canHost} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.binaryOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>

                      <div className="spacer"/>
                      <label className="profile-label">Which types of interns are you open to?<label className="error-color bold-text">*</label></label>
                      <div className="relative-column-33 right-padding-3-percent">
                        <label className="profile-label">High School Students</label>
                        <Switch
                          onChange={(change) => this.setState({ includeHighSchool: change, formHasChanged: true })}
                          checked={this.state.includeHighSchool}
                          id="normal-switch"
                        />
                      </div>

                      <div className="relative-column-33 right-padding-3-percent">
                        <label className="profile-label">2-Year College Students</label>
                        <Switch
                          onChange={(change) => this.setState({ includeAssociates: change, formHasChanged: true })}
                          checked={this.state.includeAssociates}
                          id="normal-switch"
                        />
                      </div>

                      <div className="relative-column-33 right-padding-3-percent">
                        <label className="profile-label">4-Year College Students</label>
                        <Switch
                          onChange={(change) => this.setState({ includeBachelors: change, formHasChanged: true })}
                          checked={this.state.includeBachelors}
                          id="normal-switch"
                        />
                      </div>
                      <div className="clear"/>

                      <div className="spacer"/>
                      <label className="profile-label">How do you measure success?<label className="error-color bold-text">*</label></label>
                      {this.renderAnswerChoices("success",this.state.successAnswerChoices)}

                      <div className="clear"/>
                      <div className="spacer"/>
                      <label className="profile-label">Would you be interested in volunteering in any of the following?<label className="error-color bold-text">*</label></label>
                      {this.renderAnswerChoices("volunteer",this.state.volunteerAnswerChoices)}
                      <div className="clear"/>
                    </div>
                  ) : (
                    <div>

                      <div className="spacer"/>
                      <label className="profile-label">Are you able to host students without U.S. work documentation?<label className="error-color bold-text">*</label></label>
                      <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.maybeOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>

                      <div className="edit-profile-row">
                        <label className="profile-label">During what time frame are you interested in hosting these interns?<label className="error-color bold-text">*</label></label>
                        {this.state.timeframeOptions.map((value, optionIndex) =>
                          <div key={"success|" + optionIndex} className="float-left">
                            <a className="background-link" onClick={() => this.addItem('timeframe', value)}>
                              <div className="float-left row-5 right-padding">
                                {(this.state.hireTimeframes.includes(value)) ? (
                                  <div className="tag-container-4">
                                    <p className="description-text-2 white-text">{value}</p>
                                  </div>
                                ) : (
                                  <div className="tag-container-inactive">
                                    <p className="description-text-2">{value}</p>
                                  </div>
                                )}
                              </div>
                            </a>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                      {/*
                      <div className="spacer"/>
                      <label className="profile-label">Would you like to sponsor intern(s) to be placed at a non-profit partner of yours or ours?<label className="error-color bold-text">*</label></label>
                      <select name="sponsorNonProfit" value={this.state.sponsorNonProfit} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.binaryOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>*/}

                      <div className="spacer"/>
                      <div className="edit-profile-row">
                        <label className="profile-label">Is there any additional information that we need to share with applicants (e.g security protocols or dates that your office will be closed)?</label>
                        <textarea type="text" className="text-field" placeholder="Add the additional information..." name="additionalInformation" value={this.state.additionalInformation} onChange={this.formChangeHandler}></textarea>
                      </div>

                    </div>
                  )}
                </div>
              )}

            </div>

            <div className="spacer" /><div className="spacer" />

            <div>
              <div className="fixed-column-100">
                <button className="btn btn-secondary" onClick={() => this.previousPage()}>Back</button>

              </div>
              <div className="calc-column-offset-200 height-30" />
              <div className="fixed-column-100">
                <button className="btn btn-primary" onClick={() => this.nextPage()}>Next</button>
              </div>
              <div className="clear" />
              {(this.state.serverErrorMessage !== '') && (
                <p className="login-error-message">{this.state.serverErrorMessage}</p>
              )}
            </div>
          </div>
        )
      } else if (pageNumber === 4 + Number(this.state.postCount)) {

        rows.push(
          <div key={0}>
            <div>

              <div className="spacer"/>

              <div className="progress-bar" >
                <div className="filler" style={{ width: Math.round((pageNumber * 100) / totalPages) + '%' }} />
              </div>

              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

              <div className="spacer" /><div className="spacer" />
              <p className="heading-text-3">You're almost good to go!</p>
              <div className="spacer"/>
              <p>Please create a password so that you can edit your postings and view candidates {this.state.orgName} refers to you.</p>
              <div className="spacer" /><div className="half-spacer" />
              <div className="edit-profile-row">
                <label className="profile-label">Password<label className="error-color bold-text">*</label></label>
                <input className="text-field full-width height-38" type="Password" name="password"
                  ref="password" placeholder="password" value={this.state.password}
                  onChange={this.formChangeHandler}
                />
              </div>

              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
              <div>

                <div className="calc-column-offset-250 height-30" />
                <div className="fixed-column-250 right-text">
                  <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.signUp()}>Submit Responses</button>
                </div>
                <div className="clear" />
                {(this.state.serverSucessMessage !== '') && (<p className="login-error-message">{this.state.serverSuccessMessage}</p>)}
                {(this.state.serverErrorMessage !== '') && (<p className="login-error-message">{this.state.serverErrorMessage}</p>)}
              </div>

            </div>
          </div>
        )
      } else {
        let hireCount = pageNumber - 3


        rows.push(
          <div key={0}>

            <div className="spacer"/>

            <div className="progress-bar" >
              <div className="filler" style={{ width: Math.round((pageNumber * 100) / totalPages) + '%' }} />
            </div>

            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

            <p className="heading-text-3">Internship Position #{hireCount} of {Number(this.state.postCount)}</p>
            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
            <div className="name-container">
              <label className="profile-label">Position Title<label className="error-color bold-text">*</label></label>
              <input type="text" className="text-field" placeholder="Add the job title..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
            </div>

            {(this.state.org === 'c2c') ? (
              <div className="name-container">
                <label className="profile-label">Position Location<label className="error-color bold-text">*</label></label>
                <select name="location" value={this.state.location} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.locationOptions.map(value =>
                    <option key={value} value={value}>{value}</option>
                  )}
                </select>
              </div>
            ) : (
              <div className="name-container">
                {(this.state.org === 'bixel') ? (
                  <div>
                    <label className="profile-label">Position Location Zip Code<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Add work zip code..." name="location" maxLength="5" value={this.state.location} onChange={this.formChangeHandler}></input>
                  </div>
                ) : (
                  <div>
                    <label className="profile-label">Position Location<label className="error-color bold-text">*</label></label>
                    <select name="location" value={this.state.location} onChange={this.formChangeHandler} className="dropdown">
                      <option />
                      {(this.state.customVirtualTerm) ? (
                        <option value={this.state.customVirtualTerm}>{this.state.customVirtualTerm}</option>
                      ) : (
                        <option value="Remote">Remote</option>
                      )}
                      <option value="On-Site">On-Site</option>
                    </select>
                  </div>
                )}
              </div>
            )}

            <div className="clear" />

            <div className="name-container">

                <div className="float-left">
                  <label className="profile-label">Work Function<label className="error-color bold-text">*</label></label>
                </div>
                <div>
                  <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <div className="float-left left-margin noti-bubble-info-7-9">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: true, showTags: false })}>
                      <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                    </a>
                  </div>

                </div>
                <div className="clear" />

                <select name="function" value={this.state.function} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.functionOptions.map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
                {(!this.state.hideProfileCard && this.state.function && this.state.function !== '') && (
                  <div className="top-padding-5">
                    <button className="background-button description-text-1 bold-text cta-color" onClick={() => window.scrollTo(0,document.body.scrollHeight)}>Click Here to Edit Your Ideal Candidate Below</button>
                  </div>
                )}
            </div>

            <div className="name-container">
                <label className="profile-label">Hours per Week<label className="error-color bold-text">*</label></label>
                <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.hoursPerWeekOptions.map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
            </div>

            <div className="clear"/>

            {(this.state.competencyTags && this.state.competencyTags.length > 0) && (
              <div className="row-10">
                <div className="row-10">
                  <div className="float-left right-padding">
                    <p className="description-text-4">COMPETENCY TAGS</p>
                  </div>
                  <div className="float-left right-padding top-margin-negative-4">
                    <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: true })}>
                        <div className="padding-5 cta-border circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-10" />
                        </div>
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {this.renderTags('competency')}

                <div className="clear" />
              </div>
            )}

            {(this.state.org !== 'c2c' && this.state.org !== 'dpscd') && (
              <div>
                <div className="edit-profile-row">
                  <div className="anonymous-checkmark">
                    <input type="checkbox" name="isPerpetual" checked={this.state.isPerpetual} onChange={this.formChangeHandler} />
                  </div>
                  <div className="anonymous-descriptor">
                    <label className="description-text-1">This role is continual</label>
                  </div>
                </div>
                <div className="clear"/>
              </div>
            )}

            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">Ideal Start Date<label className="error-color bold-text">*</label></label>
                {(this.state.preSetStartDate) ? (
                  <p>{this.state.preSetStartDate}</p>
                ) : (
                  <input type="date" className="date-picker" placeholder="Add the start date..." min={convertDateToString(new Date(),"rawDateForInput")} name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                )}

              </div>

              <div className="name-container">
                {(this.state.isPerpetual) ? (
                  <div>
                    <label className="profile-label">Ideal End Date</label>
                    <label className="heading-text-6">Continual</label>
                  </div>
                ) : (
                  <div>
                    <label className="profile-label">Ideal End Date<label className="error-color bold-text">*</label></label>
                    {(this.state.preSetEndDate) ? (
                      <p>{this.state.preSetEndDate}</p>
                    ) : (
                      <input type="date" className="date-field" placeholder="Add the end date..." min={convertDateToString(new Date(),"rawDateForInput")} name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                    )}
                  </div>
                )}
              </div>
              <div className="clear"/>
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Position Description<label className="error-color bold-text">*</label></label>
              <textarea type="text" className="text-field" placeholder="Copy and paste a position description..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
            </div>

            <div className="edit-profile-row">
              <div className="name-container">
                <label className="profile-label">How many openings are there for this position?<label className="error-color bold-text">*</label></label>
                <select name="hireCount" value={this.state.hireCount} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.postCountOptions.map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
              </div>

              {(!this.state.preSetSubmissionDeadline) && (
                <div className="name-container">
                  <div className="float-left">
                    <label className="profile-label">Application Deadline</label>
                  </div>
                  <div>
                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <div className="float-left left-margin noti-bubble-info-7-9">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: true, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false })}>
                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                      </a>
                    </div>

                  </div>
                  <div className="clear" />
                  <input type="date" className="date-field" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                </div>
              )}

              <div className="clear" />
            </div>

            {(this.state.org !== 'dpscd') && (
              <div>
                <div className="row-10">
                  <div className={(window.innerWidth < 767) ? "calc-column-offset-40" : "float-left right-padding"}>
                    <label className="profile-label">Tag Candidates of Interest (Optional)</label>
                  </div>
                  <div className={(window.innerWidth < 767) ? "fixed-column-40 top-padding" : "float-left top-padding"}>
                    <button className="background-button" onClick={() => this.addItem('candidatesOfInterest',null)}>
                        <div className="padding-5 cta-border circle-radius">
                          <img src={addIconBlue} alt="GC" className="image-auto-15" />
                        </div>
                    </button>
                  </div>
                  <div className="clear" />
                  <p className="description-text-2">Add up to 10 candidates you would like to invite to apply to this posting.</p>
                </div>

                <div className="spacer" />

                {this.renderQuestions('candidatesOfInterest')}

                <div className="spacer" />

                <div className="spacer" /><div className="spacer" />
              </div>
            )}

            {(this.state.org === 'c2c' || this.state.org === 'unite-la' || this.state.org === 'block') && (
              <div>

                {(this.state.org === 'c2c') && (
                  <div>
                    <div className="name-container">
                      <div className="float-left">
                        <label className="profile-label">Political Affiliation</label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politcalPartyInfo: true, showTags: false })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />
                      <select name="postPoliticalParty" value={this.state.postPoliticalParty} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.politicalAlignmentOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                <p className="heading-text-3">Application Method Details<label className="error-color bold-text">*</label></p>
                <div className="spacer"/>

                <div className="edit-profile-row">
                  <label className="profile-label">What is your preferred method for receiving intern applications?</label>
                  <select name="applicationMethod" value={this.state.applicationMethod} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.applicationMethodOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>

                <div className="edit-profile-row bottom-padding-0">
                  <div className="name-container">
                    <label className="profile-label">Contact First Name:<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="First name..." name="employerContactFirstName" value={this.state.employerContactFirstName} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Contact Last Name:<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Last name..." name="employerContactLastName" value={this.state.employerContactLastName} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row bottom-padding-0">
                  <div className="name-container">
                    <label className="profile-label">Contact Email:<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Email address..." name="employerContactEmail" value={this.state.employerContactEmail} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <div className="float-left">
                      <label className="profile-label">Application components:</label>
                    </div>
                    <div>
                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <div className="float-left left-margin noti-bubble-info-7-9">
                        <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: true, politicalPartyInfo: false, functionInfo: false, showTags: false })}>
                          <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                        </a>
                      </div>

                    </div>
                    <div className="clear" />
                    <input type="text" className="text-field" placeholder="e.g. resume, cover letter, writing samples, letters of recommendation..." name="appRequirements" value={this.state.appRequirements} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.applicationMethod === 'Applicants may be referred, but they must also apply via email') && (
                  <div className="edit-profile-row bottom-padding-0">
                    <label className="profile-label">Subject line:<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Please write the desired subject line..." name="subjectLine" value={this.state.subjectLine} onChange={this.formChangeHandler}></input>
                  </div>
                )}
                {(this.state.applicationMethod === 'Applicants may be referred, but they must also apply via our website posting') && (
                  <div className="edit-profile-row bottom-padding-0">
                    <label className="profile-label">Web link to posting:<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Please add the link to the opening..." name="jobLink" value={this.state.jobLink} onChange={this.formChangeHandler}></input>
                    {(this.state.jobLink && this.state.jobLink !== '' && !this.state.jobLink.startsWith('http')) && (
                      <div>
                        <p className="error-message">Please start your link with http</p>
                      </div>
                    )}
                  </div>
                )}

              </div>
            )}

            <div className="clear" />
            <div className="spacer" /><div className="spacer" />

            {(this.state.createCustomBenchmark && this.state.function && this.state.function !== '') && (
              <div>

                {(this.state.hideProfileCard) ? (
                  <div>
                    <ProfileCard ref={this.child} selectedApplication={this.state.selectedApplication} selectedJob={this.state.selectedJob} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} source="Employer|Edit" activeOrg={this.state.org} accountCode={this.state.accountCode} industry={this.state.employerIndustry} passBenchmark={this.passBenchmark} hideProfileCard={this.state.hideProfileCard} competencyTags={this.state.competencyTags} />
                  </div>
                ) : (
                  <div>
                    <hr />
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p className="heading-text-3">Edit Ideal Candidate (Optional)</p>
                    <div className="spacer"/>
                    <p className="description-text-1">The profile card below shows the default ideal entry-level candidate designed by <label className="half-bold-text">{this.state.orgName}</label> for the <label className="half-bold-text">{this.state.function}</label> pathway. We use this to suggest candidates. You may edit here or after you post. Learn more <a href={window.location.protocol + "//" + window.location.host + "/blog/what-are-guided-compass-benchmarks"} target="_blank" className="half-bold-text">here</a>.</p>
                    <div className="spacer" /><div className="spacer" />

                    <ProfileCard ref={this.child} selectedApplication={this.state.selectedApplication} selectedJob={this.state.selectedJob} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} source="Employer|Edit" activeOrg={this.state.org} accountCode={this.state.accountCode} industry={this.state.employerIndustry} passBenchmark={this.passBenchmark} hideProfileCard={this.state.hideProfileCard} />

                    <div className="spacer" /><div className="spacer" />
                  </div>
                )}

              </div>
            )}

            <div>
              <div className="fixed-column-100 height-30">

              </div>
              <div className="calc-column-offset-200 height-30" />
              <div className="fixed-column-100">
                <button className="btn btn-primary" onClick={() => this.nextPage()}>Next</button>
              </div>
              <div className="clear" />
              {(this.state.serverErrorMessage !== '') && (
                <p className="login-error-message">{this.state.serverErrorMessage}</p>
              )}
            </div>

          </div>
        )

      }

      return rows
    }

    addItem(type, value) {
      console.log('addItem called', type, value)

      if (type === 'hostEvents') {
        console.log('test 1')
        let hostedEvents = this.state.hostedEvents
        hostedEvents.push({ postType: 'Event', title: '', eventType: '', location: '', startDate: '' })
        console.log('test 2', hostedEvents)
        this.setState({ hostedEvents })
      } else if (type === 'problems') {
        let problems = this.state.problems
        problems.push({ name: '', postType: '', duration: '', tags: '' })
        console.log('test 2', problems)
        this.setState({ problems })
      } else if (type === 'success') {
        let successMetrics = this.state.successMetrics
        if (successMetrics.includes(value)) {
          const index = successMetrics.indexOf(value)
          if (index > -1) {
            successMetrics.splice(index, 1);
          }
        } else {
          successMetrics.push(value)
        }

        this.setState({ successMetrics })
      } else if (type === 'timeframe') {
        let hireTimeframes = this.state.hireTimeframes
        if (hireTimeframes.includes(value)) {
          const index = hireTimeframes.indexOf(value)
          if (index > -1) {
            hireTimeframes.splice(index, 1);
          }
        } else {
          hireTimeframes.push(value)
        }

        this.setState({ hireTimeframes })
      } else if (type === 'candidatesOfInterest') {
        let candidatesOfInterest = this.state.candidatesOfInterest
        if (candidatesOfInterest) {
          candidatesOfInterest.push({ firstName: '', lastName: '', email: '' })
        } else {
          candidatesOfInterest = [{ firstName: '', lastName: '', email: '' }]
        }

        this.setState({ candidatesOfInterest })

      }
    }

    renderItems(type) {
      console.log('renderItems called' , type)

      let rows = []
      if (type === 'hostEvents') {
        console.log('how about those events', this.state.hostedEvents)
        for (let i = 1; i <= this.state.hostedEvents.length; i++) {
          console.log('show event: ', this.state.hostedEvents[i - 1])

          if (this.state.hostedEvents[i - 1]) {
            console.log('again?')
            const index = i - 1

            rows.push(
              <div key={i.toString() + 'hostEvents'} className="padding-20 standard-border bottom-margin">
                <div className="bottom-padding-20">
                  {(this.state.hostedEvents[i - 1].title === '') ? (
                    <p className="heading-text-4">Hosted Event #{i}</p>
                  ) : (
                    <p className="heading-text-4">Hosted Event #{i}: {this.state.hostedEvents[i - 1].title}</p>
                  )}
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Name of Event<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Event title..." name={"eventTitle|" + index} value={this.state.hostedEvents[i - 1].title} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Event Type<label className="error-color bold-text">*</label></label>
                    <select name={"eventType|" + index} value={this.state.hostedEvents[i - 1].eventType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.eventTypeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Event Location<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Location..." name={"eventLocation|" + index} value={this.state.hostedEvents[i - 1].location} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Event Start Date<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" placeholder="Add the start date..." min={convertDateToString(new Date(),"rawDateForInput")} name={"eventStartDate|" + index} value={this.state.hostedEvents[i - 1].startDate} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            )
          }
        }
      } else if (type === 'attendEvents') {

        if (!this.state.eventsToAttend || this.state.eventsToAttend.length === 0) {
          rows.push(
            <div key="attendEvents">
              <p className="error-message">Great, there are currently no upcoming events for you to attend. We will notify you when we are hosting an event that may be of interest!</p>
            </div>
          )
        }
      } else if (type === 'problems') {
        for (let i = 1; i <= this.state.problems.length; i++) {
          console.log('show event: ', this.state.problems[i - 1])

          if (this.state.problems[i - 1]) {
            console.log('again?')
            const index = i - 1

            rows.push(
              <div key={i.toString() + 'problem'} className="padding-20 standard-border bottom-margin">
                <div className="bottom-padding-20">
                  {(this.state.problems[i - 1].name === '') ? (
                    <p className="heading-text-4">Post #{i}</p>
                  ) : (
                    <p className="heading-text-4">Post #{i}: {this.state.problems[i - 1].name}</p>
                  )}
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Name of Project Prompt<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Problem name..." name={"problemName|" + index} value={this.state.problems[i - 1].name} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Type of Prompt<label className="error-color bold-text">*</label></label>
                    <select name={"projectPromptType|" + index} value={this.state.problems[i - 1].projectPromptType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.promptPromptTypeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Duration (Estimated Man Hours to Solve)<label className="error-color bold-text">*</label></label>
                    <select name={"problemDuration|" + index} value={this.state.problems[i - 1].duration} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.durationOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Tags (e.g. Work Function, Industry, Skills)<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Add tags..." name={"problemTags|" + index} value={this.state.problems[i - 1].tags} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Submission Deadline<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" placeholder="Add the submission deadline..." name={"problemDeadline|" + index} value={this.state.problems[i - 1].submissionDeadline} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Knowledge Level<label className="error-color bold-text">*</label></label>
                    <select name={"problemKnowledgeLevel|" + index} value={this.state.problems[i - 1].knowledgeLevel} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.knowledgeLevelOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <label className="profile-label">Summary<label className="error-color bold-text">*</label></label>
                  <textarea type="text" className="text-field" placeholder="Add a brief summary of what you intend to post..." name={"problemDescription|" + index} value={this.state.problems[i - 1].description} onChange={this.formChangeHandler}></textarea>
                </div>
              </div>
            )
          }
        }
      }
      console.log('show rows: ', rows)
      return rows
    }

    renderAnswerChoices(source,ac) {
      console.log('renderAnswerChoices called', ac)

      let rows = []

      for (let i = 1; i <= ac.length; i++) {

        let checked = false
        if (source === "success") {
          checked = this.state.successSelected[i - 1]
        } else {
          checked = this.state.volunteerSelected[i - 1]
        }
        let name = source + "|" + i
        rows.push(
          <div key={source + i} className="relative-column-50">
            <div className="relative-column-12">
              <input type="checkbox" name={name} checked={checked} onChange={this.formChangeHandler} />
            </div>
            <div className="relative-column-88">
              <label className="description-text-2">{ac[i - 1]}</label>
            </div>
          </div>
        )
      }

      return rows
    }

    renderConfirmationPage() {
      console.log('renderConfirmationPage called', this.state.accountCode)

      let ac = ''
      if (this.state.accountCode && this.state.accountCode !== '') {
        ac = this.state.accountCode.toLowerCase()
      }
      const dashboardLink = "/employers/" + ac + "/dashboard"

      return (
        <div key="confirmationPage">
          <p>{this.state.confirmationMessage}</p>
          <div className="spacer" /><div className="spacer" />
          <Link to={dashboardLink} className="btn btn-primary">View Dashboard</Link>
        </div>
      )
    }

    saveCustomBenchmark = () => {
      console.log('saveCustomBenchmark called')

      this.child.current.saveCustomBenchmark()

    };

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    passBenchmark = (customBenchmarkId) => {
      console.log('passBenchmark called ', customBenchmarkId)
      this.setState({ customBenchmarkId })
    }

    renderTags(type) {
      console.log('renderTags called', type)

      if (type === 'competency') {

        let competencyTags = []
        if (this.state.competencyTags) {
          competencyTags = this.state.competencyTags
        }

        return (
          <div key="tags">
            {competencyTags.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  {(value.category === 'Ability') && (
                    <div className="tag-container-8 primary-background primary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Knowledge') && (
                    <div className="tag-container-8 secondary-background secondary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Soft Skill') && (
                    <div className="tag-container-8 tertiary-background tertiary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Hard Skill') && (
                    <div className="tag-container-8 quaternary-background quaternary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    addTag(type, index) {
      console.log('addTag called')

      this.setState({ errorMessage: null, successMessage: null })

      if (type === 'competency') {
        let competencyTags = this.state.competencyTags
        let tagName = this.state.tagName
        let tagType = this.state.tagType

        if (!tagName || tagName === '') {
          this.setState({ errorMessage: 'Please add a tag name'})
        } else if (!tagType || tagType === '') {
          this.setState({ errorMessage: 'Please add a tag type'})
        } else if (competencyTags && competencyTags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase())) {
          this.setState({ errorMessage: 'This tag has already been added'})
        } else {

          let score = 5
          let weight = 0

          if (competencyTags) {
            for (let i = 1; i <= competencyTags.length; i++) {
              if (competencyTags[i - 1].score) {
                score = competencyTags[i - 1].score
              }
              if (competencyTags[i - 1].weight) {
                weight = competencyTags[i - 1].weight
              }
            }
            competencyTags.push({ name: this.state.tagName, category: this.state.tagType, description: '', score, weight  })
          } else {
            competencyTags = [{ name: this.state.tagName, category: this.state.tagType, description: '', score, weight }]
          }

          this.setState({ competencyTags, tagName, tagType })
        }
      }
    }

    removeTag(index, type, parentIndex) {
      console.log('removeTag called', index, type, parentIndex)

      if (type === 'competency') {
        let competencyTags =  this.state.competencyTags
        competencyTags.splice(index, 1)
        this.setState({ competencyTags })
      }
    }

    searchCompetencies(competency, type, index) {
      console.log('searchCompetencies ', competency, type, index)

      if (type === 'Knowledge') {
        console.log('this is an error. We are not turning on this functionality now')
      } else {
        if (competency === '') {
          const competencyOptions = []
          this.setState({ competencyOptions })
        } else {
          Axios.get('/api/competency/search', { params: { searchString: competency, type } })
          .then((response) => {
            console.log('Competency search query attempted', response.data);

              if (response.data.success) {
                console.log('competency search query worked')

                const competencyOptions = response.data.competencies
                this.setState({ competencyOptions });

              } else {
                console.log('competency search query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Competency search query did not work for some reason', error);
          });
        }
      }
    }

    competencyClicked(optionIndex, type) {
      console.log('competencyClicked', optionIndex, type, this.state.competencyOptions[optionIndex])

      let tagName = this.state.competencyOptions[optionIndex].name
      let tagType = this.state.competencyOptions[optionIndex].type
      const competencyOptions = []
      this.setState({ tagName, tagType, competencyOptions })

    }

    deleteItem(itemType, parentIndex, questionIndex, answerChoiceIndex) {
      console.log('deleteItem called', itemType, parentIndex, questionIndex, answerChoiceIndex)

      if (itemType === 'question') {
        let postings = this.state.postings
        let screeningQuestions = this.state.postings[parentIndex].screeningQuestions
        screeningQuestions.splice(questionIndex, 1)
        postings[parentIndex]['screeningQuestions'] = screeningQuestions
        this.setState({ postings })
      } else if (itemType === 'answerChoice') {
        let postings = this.state.postings
        let screeningAnswerChoices = this.state.postings[parentIndex].screeningQuestions[questionIndex].screeningAnswerChoices
        screeningAnswerChoices.splice(answerChoiceIndex,1)

        postings[parentIndex]['screeningQuestions'][questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ postings })

      } else if (itemType === 'prompt') {
        // let questions = this.state.questions
        // delete questions[index].prompt
        // this.setState({ questions, formHasChanged: true })
      } else if (itemType === 'candidatesOfInterest') {
        let candidatesOfInterest = this.state.candidatesOfInterest
        candidatesOfInterest.splice(questionIndex, 1)
        this.setState({ candidatesOfInterest })
      }
    }

    renderQuestions(type) {
      console.log('renderQuestions called', type)

      if (type === 'screeningQuestions') {
        // let rows = [];
        // console.log('show the postings: ', this.state.postings)
        //
        // if (this.state.postings && this.state.postings[parentIndex] && this.state.postings[parentIndex].screeningQuestions) {
        //   const questions = this.state.postings[parentIndex].screeningQuestions
        //   for (let i = 1; i <= questions.length; i++) {
        //     console.log(i);
        //
        //     const index = i - 1
        //
        //     let eventName = "question" + i.toString()
        //     let typeName = "type" + i.toString()
        //
        //     rows.push(
        //       <div key={i}>
        //         <div className="close-button-container-2" >
        //           <button type="button" onClick={() => this.deleteItem('question', parentIndex, index)} className="background-button">
        //             <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
        //           </button>
        //         </div>
        //
        //         <div className="left-padding-5">
        //           <div className="highlighted-container">
        //             <div className="row-5">
        //               <p>Question #{i}</p>
        //             </div>
        //
        //             <div className="calc-column-offset-140 right-padding row-5">
        //               <input type="text" className="text-field" placeholder="Write a question..." name={"screeningQuestion|name|" + parentIndex + "|" + index} value={questions[i - 1].name} onChange={this.formChangeHandler}></input>
        //             </div>
        //             <div className="fixed-column-140 row-5">
        //               <select name={"screeningQuestion|questionType|" + parentIndex + "|" + index} value={questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
        //                 {this.state.questionTypeOptions.map(value =>
        //                   <option key={value.key} value={value}>{value}</option>
        //                 )}
        //               </select>
        //             </div>
        //             <div className="clear"/>
        //
        //             {(questions[i - 1].questionType === 'Multiple Choice'  || questions[i - 1].questionType === 'Checkbox' || questions[i - 1].questionType === 'Ranking') ? (
        //               <div className="top-padding">
        //
        //                 {(questions[i - 1].screeningAnswerChoices && questions[i - 1].screeningAnswerChoices.length > 0) && (
        //                   <div>
        //                     <div className="row-5 description-text-5 bold-text">
        //                       <div className="fixed-column-30">
        //                         <p> </p>
        //                       </div>
        //                       <div className="calc-column-offset-80 right-padding">
        //                         <p>ANSWER CHOICE</p>
        //                       </div>
        //                       <div className="fixed-column-50">
        //                         <p className="full-width center-text">POINTS</p>
        //                       </div>
        //                       <div className="clear" />
        //                     </div>
        //
        //                     {this.renderAnswerChoices(i - 1, questions[i - 1].screeningAnswerChoices, parentIndex)}
        //                   </div>
        //                 )}
        //
        //                 <div>
        //                   <div className="add-item-line-button">
        //                     <hr />
        //                     <a className="background-button add-task-link-container" onClick={() => this.addAnswerChoice(parentIndex, i - 1)}>
        //                       <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
        //                     </a>
        //                   </div>
        //                   <div className="clear" />
        //                 </div>
        //
        //               </div>
        //             ) : (
        //               <div>
        //                 {(questions[i - 1].questionType === 'Long Answer') ? (
        //                   <p className="fake-text-area">Candidates will write long answer here...</p>
        //                 ) : (
        //                   <p className="fake-text-field">Candidates will write {questions[i - 1].questionType.toLowerCase()} here...</p>
        //                 )}
        //               </div>
        //             )}
        //           </div>
        //         </div>
        //
        //       </div>
        //     )
        //   }
        // }
        //
        // return rows;

      } else if (type === 'candidatesOfInterest') {

        let rows = [];
        console.log('show the postings: ', this.state.postings)

        if (this.state.candidatesOfInterest) {
          const candidatesOfInterest = this.state.candidatesOfInterest
          for (let i = 1; i <= candidatesOfInterest.length; i++) {
            console.log(i);

            const index = i - 1

            rows.push(
              <div key={i}>
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('candidatesOfInterest', index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding-5">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Candidate #{i}</p>
                    </div>

                    <div className="row-5">
                      <div className="container-left">
                        <input type="text" className="text-field" placeholder="First name..." name={"candidatesOfInterest|firstName|" + index} value={candidatesOfInterest[i - 1].firstName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <input type="text" className="text-field" placeholder="Last name..." name={"candidatesOfInterest|lastName|" + index} value={candidatesOfInterest[i - 1].lastName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-5">
                      <input type="text" className="text-field" placeholder="Email..." name={"candidatesOfInterest|email|" + index} value={candidatesOfInterest[i - 1].email} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />

                  </div>
                </div>
              </div>
            )
          }
        }

        return rows;
      }
    }

    render() {

        let logoHeight = '40px'
        let addHeight = '20px'
        let spacing = '15px'
        let partnerLogoHeight = '23px'
        if (this.state.isMobileView) {
          console.log('isMobileView?', this.state.isMobileView)
          logoHeight = '24px'
          addHeight = '12px'
          spacing = '7px'
          partnerLogoHeight = '13px'
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        if (this.state.org === 'c2c') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1jHQpr06zpWTCKhy1F0qj8eROVK1lnZMg)'
        } else if (this.state.org === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
        } else if (this.state.org === 'block') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=132eer_VRe97xCzUfjdWbGQohUY_M1ZBa)'
        } else if (this.state.org === 'dpscd') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        } else if (this.state.org === 'exp') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Interns-ITEP-+Students+group+photo+02.JPG)'
        }

        return (
          <div>
            {(this.state.deactivated) ? (
              <div>
                <DeactivatedModal orgName={this.state.orgName} accountCode={this.state.emp} roleName={this.state.roleName} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} redirectLink={this.state.redirectLink} isLandingPage={true}/>
              </div>
            ) : (
              <div>
                <div className="light-background bottom-padding-50-percent">
                  <header className="front-page-header" style={{ backgroundImage }}>
                    <div className="relative-column-20 row-30 right-padding-3-percent" />
                    <div className="relative-column-60 center-horizontally row-30">
                      {(this.state.orgPartnerLogo) ? (
                        <div>
                          <ul className="horizontal-list center-text flex-center">
                            <li><Link to={'/'}><img src={this.state.orgLogo} alt="Compass logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                            <li><div style={{ width: spacing}}/></li>
                            <li><Link to={'/'}><img src={addIcon} alt="Guided Compass add logo" style={{ height: addHeight, width: 'auto' }} /></Link></li>
                            <li><div style={{ width: spacing }}/></li>
                            <li><Link to={'/'}><img src={this.state.orgPartnerLogo} alt="Guided Compass org partner logo" style={{ height: partnerLogoHeight, width: 'auto' }} /></Link></li>
                          </ul>
                          <div className="half-spacer"/>
                          <p className="description-text-1 white-text center-text">Powered by Guided Compass</p>
                        </div>
                      ) : (
                        <ul className="horizontal-list center-text flex-center">
                          <li><Link to={'/'}><img src={logoImg} alt="Compass logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                          <li><div style={{ width: spacing}}/></li>
                          <li><Link to={'/'}><img src={addIcon} alt="Guided Compass add logo" style={{ height: addHeight, width: 'auto' }} /></Link></li>
                          <li><div style={{ width: spacing }}/></li>
                          <li><Link to={'/'}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                        </ul>
                      )}
                    </div>
                    <div className="relative-column-20 row-30 right-padding-3-percent">
                      <Link className="clear-border" to={'/employers/signin'}>
                        <p className="white-text float-right">Sign In</p>
                      </Link>
                    </div>
                    <div className="clear" />

                    <div className="employer-signup-form" style={{ transition: 'all 1.2s ease-in', opacity: this.state.opacity, transform: 'translate(0%, 1%)'}}>
                      {(this.state.employerFormTitle) ? (
                        <h2 className="full-width center-text">{this.state.employerFormTitle}</h2>
                      ) : (
                        <div>
                          {(this.state.orgProgramName) ? (
                            <h2 className="full-width center-text">{this.state.orgProgramName} Employer Form</h2>
                          ) : (
                            <h2 className="full-width center-text">{this.state.orgName} Employer Form</h2>
                          )}
                        </div>
                      )}

                      {(this.state.formComplete) ? (
                        <div>
                          {this.renderConfirmationPage()}
                        </div>

                      ) : (
                        <div>
                          {this.renderPage(this.state.currentPage)}
                        </div>
                      )}
                    </div>
                  </header>

                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                 >
                   <div key="info" className="full-width padding-40">
                    {(this.state.paymentOptionsInfo) && (
                      <div>
                        <p className="heading-text-2">Internship Types Info</p>
                        <div className="spacer"/>
                        <p>We define <label className="half-bold-text cta-color">internships</label> as a period of work experience greater than <label className="half-bold-text cta-color">120 hours</label>. Internships may be company-paid, subsidized, or unpaid:</p>

                        <div className="spacer"/>

                        <p className="row-10"><label className="half-bold-text">Company-paid</label> internships are paid out-of-pocket by the employer at a wage at least equal to minimum wage.</p>

                        <p className="row-10"><label className="half-bold-text">Subsidized</label> internships may allow employers to have no out-of-pocket expenses. Government or non-profit organizations sponsor internships up to a certain number of hours. Every year, a certain number of subsidized slots are awarded based on the financial need of the employer.</p>

                        <p className="row-10"><label className="half-bold-text">Unpaid</label> internships must provide educational credit. If your posting aligns with the needs of one of our educational partners, the internship can be deemed upaid.</p>
                      </div>
                    )}
                    {(this.state.applicationDeadlineInfo) && (
                      <div>
                        <p className="heading-text-2">Application Deadline Info</p>
                        <div className="spacer"/>
                        <p>The <label className="half-bold-text cta-color">application deadline</label> is the date that candidates should apply or be presented by for first round consideration.</p>
                      </div>
                    )}
                    {(this.state.applicationComponentsInfo) && (
                      <div>
                        <p className="heading-text-2">Application Components Info</p>
                        <div className="spacer"/>
                        <p>The <label className="half-bold-text cta-color">application components</label> section is optional, allowing you to list things you would like candidates to include as part of their application. If there is nothing special beyond their resume, you may leave blank.</p>
                      </div>
                    )}
                    {(this.state.politicalPartyInfo) && (
                      <div>
                        <p className="heading-text-2">Political Affiliation Info</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>Choosing the <label className="half-bold-text cta-color">political affiliation</label> that most closely relates to this position will allow candidates to search for your positions by political party.</p>
                      </div>
                    )}

                    {(this.state.functionInfo) && (
                      <div>
                        <p className="heading-text-2">Function Info</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>We define <label className="half-bold-text cta-color">work functions</label> as a category of work that requires similar skills. It can be thought of as synonymous with "departments" within a company. Functions can be the same across different industries.</p>

                        {(this.state.org === 'c2c') && (
                          <div>
                            <div className="spacer" /><div className="spacer" />

                            <p><label className="half-bold-text cta-color">Communications</label> is any work in marketing, advertising, content writing, or public relations.</p>
                            <div className="spacer" />
                            <p><label className="half-bold-text cta-color">General Operations</label> is any work in programming, project management, human resources, or administration.</p>
                            <div className="spacer" />
                            <p><label className="half-bold-text cta-color">Policy</label> is any work generating policy ideas or policy written materials.</p>
                            <div className="spacer" />
                            <p><label className="half-bold-text cta-color">Legal</label> is any work conducting legal research, legal writing, or legal practice.</p>
                            <div className="spacer" />
                            <p><label className="half-bold-text cta-color">Sales</label> is any work fundraising, business development, or partnerships.</p>
                            <div className="spacer" />
                            <p><label className="half-bold-text cta-color">Research & Development</label> is any work requiring extensive reseach or long-term experimentation.</p>
                            <div className="spacer" />

                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.showTags) && (
                      <div>
                        <p className="heading-text-2">Edit Competency Tags</p>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Tag Name</label>
                            <input type="text" className="text-field" placeholder="Name of tag..." name="tagName" value={this.state.tagName} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <div className="calc-column-offset-60 right-padding">
                              <label className="profile-label">Tag Type</label>
                              <select name="tagType" value={this.state.tagType} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.tagTypeOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="fixed-column-60 top-padding-40">
                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <button className="btn btn-squarish" onClick={() => this.addTag('competency')}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />

                          {(this.state.competencyOptions) && (
                            <div>
                              <div className="spacer" />

                              {this.state.competencyOptions.map((value, optionIndex) =>
                                <div key={value._id} className="left-text row-5 full-width">
                                  <button className="background-button" onClick={() => this.competencyClicked(optionIndex, null)}>
                                    <div className="full-width">
                                      <div className="float-left right-padding">
                                        <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                      </div>
                                      <div className="float-left">
                                        <p className="cta-color">{value.name}</p>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                        </div>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

                        <div className="spacer" />

                        {this.renderTags('competency')}
                        <div className="clear" />

                        <div className="spacer" />

                        <div className="row-10">
                          <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                        </div>
                      </div>
                    )}
                   </div>
                 </Modal>

                </div>
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(EmpOrgSignUp)
