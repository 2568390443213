import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubExchange from '../Subcomponents/Exchange';
import withRouter from '../Functions/WithRouter';

class OrgExchange extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
      this.finishedQuery = this.finishedQuery.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      let passedId = null
      if (this.props.params) {
        passedId = this.props.params.id
      }

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
        passedId, orgLogo
      })
    }

    finishedQuery() {
      console.log('finishedQuery called')

      this.setState({ finishedQuery: true })
    }

    render() {

      return (
          <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

              <div className="width-70-percent max-width-1400 top-margin-2-percent center-horizontally">
                <SubExchange finishedQuery={this.finishedQuery} passedId={this.state.passedId} />
              </div>

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(OrgExchange)
