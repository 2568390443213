import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubAdvisingServicesProduct from '../components/Subcomponents/AdvisingServicesProduct';
import withRouter from '../components/Functions/WithRouter';

class MarketplaceProductPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      window.scrollTo(0, 0)

      this.retrieveData()

    }

    retrieveData() {
      console.log('retrieveData called')

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('emp');
      let employerName = localStorage.getItem('employerName');
      let employerLogoURL = localStorage.getItem('employerLogoURL');
      let jobTitle = localStorage.getItem('jobTitle');

      let registered = false
      if (emailId && activeOrg && accountCode && employerName) {
        registered = true
      }
      // console.log('show me categories: ', categories)
      this.setState({ cuFirstName, cuLastName, emailId, activeOrg, roleName,
        accountCode, employerName, employerLogoURL, jobTitle, registered
      })

      let slug = null
      if (this.props.params) {
        slug = this.props.params.slug
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentMarketplaceProductPage ', this.props, prevProps)

      if (this.props.params.slug !== prevProps.params.slug) {
        console.log('new career selected in parent')
        this.retrieveData()
      }
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"marketplaceProductPage"} darkBackground={true} />
                <SubAdvisingServicesProduct history={this.props.navigate} slug={this.state.slug} />
                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(MarketplaceProductPage);
