import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

const compassAssistantDiagram = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass_Assistant_Diagram.png';

class AssistantPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          opacity: '0',
          commands: [],
          allowDCTest: false
        }

        this.renderSubscribeLink = this.renderSubscribeLink.bind(this)
        this.commandClicked = this.commandClicked.bind(this)

        this.child = React.createRef();

    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

      const valueProps = [
        { name: 'Chat Tag-Ins', description: 'As a user, when you chat with one or more people, ask Compass for advice or to accomplish a task by starting your message with @compass.'},
        { name: 'Direct Prompting', description: 'As a user, click on the floating button at any time to prompt Compass Assistant at any time.'},
        { name: 'Document Building', description: 'As a user, auto-generate documents such as resumes, cover letters, career plans, financial plans, business plans, and letters of recommendation.'},
        { name: 'Learning Management', description: 'As a user, use the prompts and guided lessons from teachers and counselors.'},
        { name: 'Advanced Reporting', description: 'As an admin of a career program, auto-generate drafts of impact reports, grant applications, and ideas on how to improve the program.'},
      ]

      this.setState({ valueProps })

      // const commandQueryObject = { onlyAI: true, onlyTopTier: true }
      // Axios.get('/api/commands', { params: commandQueryObject })
      // .then((response) => {
      //   console.log('Commands query attempted', response.data);
      //
      //    if (response.data.success) {
      //      console.log('successfully retrieved commands')
      //
      //      const popularCommands = response.data.commands
      //      this.setState({ popularCommands })
      //
      //      Axios.get('/api/commands', { params: { onlyGC: true, onlyTopTier: true }})
      //      .then((response2) => {
      //        console.log('Commands query attempted', response2.data);
      //
      //         if (response2.data.success) {
      //           console.log('successfully retrieved commands')
      //
      //           const directCommands = response2.data.commands
      //           this.setState({ directCommands })
      //
      //         } else {
      //           console.log('no commands found', response2.data.message)
      //         }
      //      }).catch((error2) => {
      //         console.log('Commands query did not work', error2);
      //      });
      //
      //    } else {
      //      console.log('no commands found', response.data.message)
      //    }
      // }).catch((error) => {
      //    console.log('Commands query did not work', error);
      // });

      const commandQueryObject = { onlyAI: true, onlyElite: true }
      Axios.get('/api/commands', { params: commandQueryObject })
      .then((response) => {
        console.log('Commands query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved commands')

           let csCommands = []
           let intermediaryCommands = []
           let employerCommands = []

           for (let i = 1; i <= response.data.commands.length; i++) {
             if (response.data.commands[i - 1].roleNames) {
               if (response.data.commands[i - 1].roleNames.includes('Career-Seeker')) {
                 csCommands.push(response.data.commands[i - 1])
               } else if (response.data.commands[i - 1].roleNames.includes('Intermediary Staff')) {
                 intermediaryCommands.push(response.data.commands[i - 1])
               } else if (response.data.commands[i - 1].roleNames.includes('Employer')) {
                 employerCommands.push(response.data.commands[i - 1])
               }
             }
           }

           this.setState({ csCommands, intermediaryCommands, employerCommands })

         } else {
           console.log('no commands found', response.data.message)
         }
      }).catch((error) => {
         console.log('Commands query did not work', error);
      });
    }

    renderSubscribeLink() {
      console.log('renderSubscribeLink called')

      let link = "/subscribe/career-seeker"
      if (this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'School Support' || this.state.roleName === 'Educator') {
        link = "/subscribe/educator"
      } else if (this.state.roleName === 'Admin' || this.state.roleName === 'Work-Based Learning Coordinator' || this.state.roleName === 'WBLC') {
        link = "/subscribe/workforce"
      } else if (this.state.roleName === 'Employer') {
        link = "/subscribe/employer"
      }

      return link
    }

    commandClicked(item) {
      console.log('commandClicked called')

      const goal = "starting a business"
      const salary = "$100K"
      const jobTitle = "Software Engineer"
      const employerName = "Google"
      const businessIdea = "healthy meal app"

      const field = "health & wellness"
      const offerAmount = "$100K"
      const targetCustomer = "Vegan yoga instructors"
      const productName = "Healthispan"
      const productDescription = "healthy meal planning for health conscious individuals"
      const businessName = "Helathispan"
      const businessDescription = "healthy meal planning for health conscious individuals"
      const interest1 = "basketball"
      const interest2 = "music"
      const interest3 = "cooking"
      const skill1 = "communicating with people"
      const skill2 = "collaborating with people"
      const skill3 = "staying positive"
      const myersBriggsScore = "ESFP"
      const interviewQuestion = "How do you prioritize product features and make trade-off decisions?"
      const interviewResponse = "I score features on a scale of 1-10 for both their impact and their effort. Then I calculate the impact divided by effort. Then I sort the results, prioritizing those with the highest ratios."

      const name = item.name.replace(/{{goal}}/g,goal).replace(/{{salary}}/g,salary).replace(/{{jobTitle}}/g,jobTitle).replace(/{{employerName}}/g,employerName).replace(/{{businessIdea}}/g,businessIdea).replace(/{{field}}/g,field).replace(/{{offerAmount}}/g,offerAmount).replace(/{{targetCustomer}}/g,targetCustomer).replace(/{{productName}}/g,productName).replace(/{{productDescription}}/g,productDescription).replace(/{{businessName}}/g,businessName).replace(/{{businessDescription}}/g,businessDescription).replace(/{{interest1}}/g,interest1).replace(/{{interest2}}/g,interest2).replace(/{{interest3}}/g,interest3).replace(/{{skill1}}/g,skill1).replace(/{{skill2}}/g,skill2).replace(/{{skill3}}/g,skill3).replace(/{{myersBriggsScore}}/g,myersBriggsScore).replace(/{{interviewQuestion}}/g,interviewQuestion).replace(/{{interviewResponse}}/g,interviewResponse)

      this.child.current.passCommand(name);

      // .replace(/Compass, /g,"")

    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"assistantPage"} />

              <header className="robot-header">
                <div className="hero-text-box-shortened">
                  <h1 className="light-text">Compass Assistant</h1>
                  <p>Generative A.I. Advisor and Assistant Integrating LLMs with Guided Compass</p>
                  <div className="spacer" /><div className="spacer" />

                  <Link className="btn btn-full" to={this.renderSubscribeLink()}>Subscribe for Unlimited Commanding</Link>
                </div>
              </header>

              <div className={"max-width-1140 center-horizontally padding-30 top-margin-30"}>
                <h2>Value Proposition</h2>
                <div className="spacer" /><div className="spacer" />

                {(this.state.valueProps && this.state.valueProps.length > 0) && (
                  <div>
                    {this.state.valueProps.map((item, optionIndex) =>
                      <div key={"valueProp|" + optionIndex} className="top-margin-30">
                        <p className="heading-text-5">{optionIndex + 1}. {item.name}</p>
                        <p className="top-margin-5 description-text-color">{item.description}</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <hr />

                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={"light-background full-width"}>
                <div className={"max-width-1140 center-horizontally padding-30 top-margin-30"}>
                  <h2>How it Works</h2>
                  <div className="spacer" /><div className="spacer" />

                  <img src={compassAssistantDiagram} alt="GC" className="image-full-auto" />
                </div>
              </div>

              <div className={"max-width-1140 center-horizontally padding-30 top-margin-30"}>
                <h2>Examples</h2>
                <div className="spacer" /><div className="spacer" />

                {(this.state.csCommands && this.state.csCommands.length > 0) && (
                  <div className="row-15 top-margin-50">
                    <p className="heading-text-4">{this.state.csCommands.length} Popular Career-Seeker Commands</p>

                    <div className="description-text-2 top-margin">
                      {this.state.csCommands.map((item, optionIndex) =>
                        <div key={"csCommands|" + optionIndex} className="top-margin-30">
                          <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                            <p className="heading-text-6">{optionIndex + 1}. {item.name}</p>
                          </div>
                          <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                            <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                          </div>
                          <div className="clear" />

                          <hr />

                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(this.state.intermediaryCommands && this.state.intermediaryCommands.length > 0) && (
                  <div className="row-15 top-margin-50">
                    <p className="heading-text-4">{this.state.intermediaryCommands.length} Popular Intermediary (e.g., Teacher, Mentor, Administrator, etc...) Commands</p>

                    <div className="description-text-2 top-margin">
                      {this.state.intermediaryCommands.map((item, optionIndex) =>
                        <div key={"intermediaryCommands|" + optionIndex} className="top-margin-30">
                          <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                            <p className="heading-text-6">{optionIndex + 1}. {item.name}</p>
                          </div>
                          <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                            <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                          </div>
                          <div className="clear" />

                          <hr />

                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(this.state.employerCommands && this.state.employerCommands.length > 0) && (
                  <div className="row-15 top-margin-50">
                    <p className="heading-text-4">{this.state.employerCommands.length} Popular Employer (Talent Acquisition) Commands</p>

                    <div className="description-text-2 top-margin">
                      {this.state.employerCommands.map((item, optionIndex) =>
                        <div key={"employerCommands|" + optionIndex} className="top-margin-30">
                          <div className="calc-column-offset-90 right-padding-20 bottom-margin-20">
                            <p className="heading-text-6">{optionIndex + 1}. {item.name}</p>
                          </div>
                          <div className="fixed-column-90 top-margin-negative-5 bottom-margin-20">
                            <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                          </div>
                          <div className="clear" />

                          <hr />

                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/*
                <div className="row-10">
                  <p className="heading-text-4 bold-text">25 Popular Advising Commands</p>
                </div>

                {this.state.popularCommands.map((item, optionIndex) =>
                  <div key={"commands|" + optionIndex} className="top-margin-30">
                    <div className={(window.innerWidth < 768) ? "calc-column-offset-90 right-padding-20 bottom-margin-20" : "float-left right-padding-20 bottom-margin-20"}>
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    <div className={(window.innerWidth < 768) ? "fixed-column-90 top-margin-negative-5 bottom-margin-20" : "float-left top-margin-negative-5 bottom-margin-20"}>
                      <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                    </div>
                    <div className="clear" />

                    <hr />

                  </div>
                )}

                <div className="row-10 top-margin-50">
                  <p className="heading-text-4 bold-text">10 Direct GC Commands</p>
                </div>

                {this.state.directCommands.map((item, optionIndex) =>
                  <div key={"commands|" + optionIndex} className="top-margin-30">
                    <div className={(window.innerWidth < 768) ? "calc-column-offset-90 right-padding-20 bottom-margin-20" : "float-left right-padding-20 bottom-margin-20"}>
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    {(this.state.allowDCTest) && (
                      <div className={(window.innerWidth < 768) ? "fixed-column-90 top-margin-negative-5 bottom-margin-20" : "float-left top-margin-negative-5 bottom-margin-20"}>
                        <button className={(this.state.allowDCTest) ? "btn btn-squarish" : "btn btn-squarish medium-background standard-border"} disabled={(this.state.allowDCTest) ? false : true} onClick={() => this.commandClicked(item)}>Try Me</button>
                      </div>
                    )}

                    <div className="clear" />

                    <hr />

                  </div>
                )}*/}
              </div>

              <Footer history={this.props.navigate} ref={this.child} />
          </div>

        )
    }
}

export default AssistantPage;
