import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubEditProfileDetails from '../Subcomponents/EditProfileDetails';
import SubTakeAssessment from '../Subcomponents/TakeAssessment';
import SubRequestEndorsements from '../Subcomponents/RequestEndorsements';
import SubNotificationSubscribe from '../Subcomponents/NotificationSubscribe';
import SubLogs from '../Subcomponents/Logs';
import SubEditLog from '../Subcomponents/EditLog';
import SubEditGroup from '../Common/EditGroup';
import SubGroups from '../Subcomponents/Groups';
import SubRenderMyGroups from '../Common/RenderMyGroups';
import SubAddWorkspaces from '../Subcomponents/AddWorkspaces';
import SubEditBenchmark from '../Subcomponents/EditBenchmark';
import SubCreatePost from '../Common/CreatePost';
import SubEditPosting from '../Subcomponents/EditPosting';
import SubEditPipeline from '../Common/EditPipeline';
import SubBenchmarks from '../Subcomponents/Benchmarks';
import SubRenderPosts from '../Common/RenderPosts';
import SubPostings from '../Subcomponents/Postings';
import SubPipelines from '../Subcomponents/Pipelines';
import {requestAccessToWorkspace} from '../Services/ProfileRoutes';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIconLeft = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/left-arrow-indicator.png';
const arrowIndicatorIconRight = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/right-arrow-indicator.png';
const partnerIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/partner-icon.png';
const eventIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-blue.png';
const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const internIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon-blue.png';
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const homeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-blue.png';
const homeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-dark.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const skillsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png';
const skillsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon-blue.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const publicIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/public-icon.png';
const publicIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/public-icon-blue.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const socialIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-blue.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const careerMatchesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const gcFrontImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/gc-front-image.png";
const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const closeIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon-dark.png";
const sendIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-blue.png";
const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Walkthrough extends Component {
    constructor(props) {
        super(props)
        this.state = {
          pageIndex: 0,
          isChecked: false,

          showModule: false,
          limitTo3: true,
          hideAccountabilityGroups: true,

          pages: [],
          autoEnrollInProgram: true,
          requireReferrer: true,
          showWeeklyOpportunitySignUp: true,

          containerWidth: '70%',
          containerPadding: '40px',
          sideContainerWidth: '15%',

          questions: [],
          departmentOptions: [],

          atsOptions: []

        }

        this.renderWalkthroughContents = this.renderWalkthroughContents.bind(this)
        this.movePage = this.movePage.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.passGoal = this.passGoal.bind(this)
        this.passGroup = this.passGroup.bind(this)
        this.passOrgs = this.passOrgs.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.saveToProfile = this.saveToProfile.bind(this)

        this.addItem = this.addItem.bind(this)
        this.suggestItems = this.suggestItems.bind(this)
        this.suggestedItemClicked = this.suggestedItemClicked.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeItem = this.removeItem.bind(this)

        this.orgClicked = this.orgClicked.bind(this)
        this.removeOrg = this.removeOrg.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.openSignUpFieldsModal = this.openSignUpFieldsModal.bind(this)
        this.renderSignUpFields = this.renderSignUpFields.bind(this)
        this.renderSignUpField = this.renderSignUpField.bind(this)
        this.optionClicked2 = this.optionClicked2.bind(this)
        this.submitSignUpFields = this.submitSignUpFields.bind(this)
        this.sendInvite = this.sendInvite.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      window.scrollTo(0, 0)
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within SubWalkthrough', this.props, prevProps)

      if (this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubWalkthrough', this.props)

      // const opportunity = this.props.opportunityId
      const accountCode = this.props.accountCode

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');

      let pageIndex = 0
      let pages = []

      pageIndex = 1

      if (window.location.pathname.includes('/employers')) {
        pages = [
          { title: 'Welcome', iconSelected: homeIconBlue, iconUnselected: homeIconDark },
          { title: 'Design Ideal Candidates', iconSelected: careerMatchesIconBlue, iconUnselected: careerMatchesIconDark },
          { title: "Engage Ideal Candidates", iconSelected: socialIconBlue, iconUnselected: socialIconDark },
          { title: "Hire Ideal Candidates", iconSelected: opportunitiesIconBlue, iconUnselected: opportunitiesIconDark, },
        ]
      } else if (window.location.pathname.includes('/app')) {
        // career-seeker

        if (this.state.showModule) {
          pages = [
            { title: 'Welcome', iconSelected: homeIconBlue, iconUnselected: homeIconDark },
            { title: 'Who you are', iconSelected: profileIconBlue, iconUnselected: profileIconDark },
            { title: "What you want", iconSelected: favoritesIconBlue, iconUnselected: favoritesIconDark },
            { title: "What you're great at", iconSelected: skillsIconBlue, iconUnselected: skillsIconDark, },
            { title: "Who can see your profile", iconSelected: publicIconBlue, iconUnselected: publicIconDark },
            { title: "Who you know", iconSelected: socialIconBlue, iconUnselected: socialIconDark, },
          ]
        } else if (this.state.limitTo3) {
          pages = [
            { title: 'Welcome', iconSelected: homeIconBlue, iconUnselected: homeIconDark },
            { title: 'Who you are', iconSelected: profileIconBlue, iconUnselected: profileIconDark },
            { title: "What you want", iconSelected: favoritesIconBlue, iconUnselected: favoritesIconDark },
          ]
        } else {
          pages = [
            { title: 'Welcome', iconSelected: homeIconBlue, iconUnselected: homeIconDark },
            { title: 'Who you are', iconSelected: profileIconBlue, iconUnselected: profileIconDark },
            { title: "What you want", iconSelected: favoritesIconBlue, iconUnselected: favoritesIconDark },
            { title: "What you're great at", iconSelected: skillsIconBlue, iconUnselected: skillsIconDark, },
            { title: "Who can see your profile", iconSelected: publicIconBlue, iconUnselected: publicIconDark },
          ]
        }

      }

      let requireReferrer = true
      if (activeOrg === 'guidedcompass' || window.location.pathname.includes('/employers')) {
        requireReferrer = false
      }

      let atsOptions = ['','Avature','Workable','JazzHR','Lever','Greenhouse','Breezy HR', 'iCIMS','RecruiterBox','Pinpoint','ClearCompany','Workday', 'Other',]
      atsOptions.sort()
      atsOptions.push('No ATS',"I'm Not Sure")

      this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus, pageIndex, pages,
        remoteAuth, requireReferrer, atsOptions
      })

      if (window.location.pathname.includes('/employers')) {
        // pull employerName, logo, etc...

        if (accountCode) {
          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted in employer dashboard', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              const employerName = response.data.accountInfo.employerName
              const employerLogo = response.data.accountInfo.employerLogoURI
              const accountCode = response.data.accountInfo.accountCode
              const departments = response.data.accountInfo.departments
              const ats= response.data.accountInfo.ats

              this.setState({ employerName, employerLogo, accountCode, departments, ats });

              Axios.get('/api/get-followers', { params: { _id: response.data.accountInfo._id } })
              .then((response) => {
                console.log('Followers query attempted', response.data);

                if (response.data.success) {
                  console.log('followers query worked in sub settings')

                  const followers = response.data.followers
                  this.setState({ followers })
                }

              }).catch((error) => {
                console.log('Followers query did not work for some reason', error);
              });

              let sharePartners = response.data.accountInfo.sharePartners
              if (sharePartners && sharePartners.includes('guidedcompass')) {
                const gcIndex = sharePartners.indexOf('guidedcompass')
                sharePartners.splice(gcIndex,1)
                // console.log('show sharePartners: ', sharePartners)
                if (sharePartners.length > 0) {
                  Axios.get('/api/orgs', { params: { orgCodes: response.data.accountInfo.sharePartners } })
                  .then((response) => {
                    console.log('Org training providers query attempted', response.data);

                    if (response.data.success) {
                      console.log('org training providers query worked in sub settings')

                      const trainingProviders = response.data.orgs
                      this.setState({ trainingProviders })

                    }

                  }).catch((error) => {
                    console.log('Org training providers query did not work for some reason', error);
                  });
                }
              }
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });

          Axios.get('/api/group-posts', { params: { groupId: null, accountCode } })
          .then((response) => {
             console.log('Group posts query attempted in newsfeed', response.data);

             if (response.data.success) {
               console.log('successfully retrieved group posts in newsfeed')

               if (response.data.groupPosts && response.data.groupPosts.length > 0) {
                 let socialPosts = response.data.groupPosts
                 this.setState({ socialPosts })
               }

             }
          }).catch((error) => {
              console.log('Group posts query did not work', error);
          });

          Axios.get('/api/groups', { params: { resLimit: 3, accountCode }})
          .then((response) => {
            console.log('Groups query worked', response.data);

            if (response.data.success && response.data.groups &&  response.data.groups.length > 0) {

              const communities = response.data.groups
              this.setState({ communities })

            } else {
              console.log('no groups data found', response.data.message)
            }

          }).catch((error) => {
              console.log('Groups query did not work', error);
          });
        }

      } else if (window.location.pathname.includes('/app')){

        let myOrgs = localStorage.getItem('myOrgs');
        // console.log('show orgCodes: ', orgCodes)
        if (myOrgs) {
          myOrgs = JSON.parse(myOrgs)
        }

        if (myOrgs) {
          Axios.get('/api/orgs', { params: { orgCodes: myOrgs } })
          .then((response) => {
            console.log('Orgs info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const myOrgObjects = response.data.orgs
              this.setState({ myOrgs, myOrgObjects })

            } else {
              console.log('org info query did not work', response.data.message)

            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        // career-seeker
        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let genderOptions = response.data.workOptions[0].genderOptions
            genderOptions.unshift('')
            let raceOptions = response.data.workOptions[0].raceOptions
            raceOptions.unshift('')
            const basicCountOptions = ['','1','2','3','4','5','6','7','8','9','10']
            let lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions
            lowIncomeOptions.unshift('')
            let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
            fosterYouthOptions.unshift('')
            let homelessOptions = response.data.workOptions[0].homelessOptions
            homelessOptions.unshift('')
            let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
            incarceratedOptions.unshift('')

            const year = (new Date().getFullYear() - 18).toString()
            let month = ''
            if ((new Date().getMonth() + 1) >= 10) {
              month = (new Date().getMonth() + 1).toString()
            } else {
              month = '0' + (new Date().getMonth() + 1).toString()
            }

            const day = new Date().getDate().toString()
            const defaultDate = year + '-' + month + '-' + day

            let questions = [
              { category: 'basic', name: 'Date of Birth', type: 'Date', field: 'dateOfBirth', placeholder: 'e.g. 04/13/98', answer: defaultDate, required: true },
              { category: 'basic', name: 'Gender', type: 'Multiple Choice', field: 'gender', options: genderOptions, required: true },
              { category: 'basic', name: 'Race', type: 'Multiple Choice', field: 'race', options: raceOptions, required: true },
              { category: 'basic', name: 'Home Address (No Zip Code)', type: 'Short Answer', field: 'homeAddress', placeholder: 'e.g. 111 Candy Cane Lane Los Angeles, CA', required: true },
              { category: 'basic', name: 'Zip Code', type: 'Short Answer', field: 'zipcode', placeholder: 'e.g. 90062', required: true },
              { category: 'basic', name: 'Phone Number', type: 'Short Answer', field: 'phoneNumber', placeholder: 'e.g. (323) 299-2991', required: true },
              { category: 'diversity', name: 'Number of Members in Household', type: 'Multiple Choice', field: 'numberOfMembers', options: basicCountOptions, required: true },
              { category: 'diversity', name: 'Estimated Household Income', type: 'Multiple Choice', field: 'householdIncome', options: lowIncomeOptions, required: true },
              { category: 'diversity', name: 'Have you ever been a foster youth?', type: 'Multiple Choice', field: 'fosterYouth', options: fosterYouthOptions, required: true },
              { category: 'diversity', name: 'Are you currently or formerly homeless?', type: 'Multiple Choice', field: 'homeless', options: homelessOptions, required: true },
              { category: 'diversity', name: 'Were you previously incarcerated?', type: 'Multiple Choice', field: 'incarcerated', options: incarceratedOptions, required: true },
              { category: 'diversity', name: 'Designate all that apply.', type: 'Multiple Answer', field: 'adversity', options: ['LGBQIA','ADA','First Generation Immigrant','First Generation College Student','Low Income','None'], required: true },
              { category: 'referral', name: 'Name of person who recommended you', type: 'Short Answer', field: 'referrerName', placeholder: 'e.g. John Smith', required: false },
              { category: 'referral', name: 'Email of person who recommended you', type: 'Short Answer', field: 'referrerEmail', placeholder: 'e.g. johnsmith@love.org', required: false },
              { category: 'referral', name: 'Name of organization that referred you', type: 'Short Answer', field: 'referrerOrg', options: [], placeholder: 'e.g. Franklin High', required: false },
              { category: 'referral', name: 'To enroll in our next WorkforceReady cohort, check here!', type: 'Checkbox', field: 'autoEnrollInProgram', options: [], placeholder: '', required: false },
            ]

            this.setState({ questions })

            Axios.get('/api/org', { params: { orgCode: activeOrg } })
            .then((response) => {
              console.log('Org info query attempted cc', response.data);

                if (response.data.success) {
                  console.log('org info query worked')

                  const possibleReferrers = response.data.orgInfo.possibleReferrers
                  if (questions[questions.length - 1].field === 'referrerOrg') {
                    questions[questions.length - 1]['options'] = possibleReferrers
                  }

                  const partnerStatement = response.data.orgInfo.partnerStatement
                  const partnerName = response.data.orgInfo.orgName.toLowerCase().replace(/ /g, "-")
                  const orgName = response.data.orgInfo.orgName
                  const orgLogo = response.data.orgInfo.webLogoURIColor
                  const requireReferrer = response.data.orgInfo.requireReferrer

                  this.setState({ questions, partnerStatement, partnerName, orgName, orgLogo, requireReferrer })

                  this.pullProfileData(email, questions)

                } else {
                  console.log('org info query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });

          } else {
            console.log('no work options data found', response.data.message)

          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        Axios.get('/api/assessment/results', { params: { emailId: email } })
         .then((response) => {
           console.log('query for assessment results worked');

           if (response.data.success) {
             console.log('actual assessment results', response.data)

             let newSkillAnswers = null
             if (response.data.results.newSkillAnswers && response.data.results.newSkillAnswers.length > 0) {
               newSkillAnswers = response.data.results.newSkillAnswers
             }
             this.setState({ skillsData: newSkillAnswers })

           }

       }).catch((error) => {
           console.log('query for assessment results did not work', error);
       })

       Axios.get('/api/groups', { params: { orgCode: activeOrg, emailId: email, type: 'myGroups' }})
       .then((response) => {
         console.log('My groups query worked', response.data);

         if (response.data.success) {

           let groupsJoined = response.data.groups
           this.setState({ groupsJoined })

         } else {
           console.log('no my groups data found', response.data.message)
         }

       }).catch((error) => {
           console.log('My groups query did not work', error);
       });

        const fetchDetailsURL = '/api/users/profile/details/' + email
        Axios.get(fetchDetailsURL)
        .then((response) => {
          if (response.data) {

            let myOrgs = response.data.user.myOrgs
            const joinRequests = response.data.user.joinRequests

            const workAuthorization = response.data.user.workAuthorization
            const zipcode = response.data.user.zipcode
            const dateOfBirth = response.data.user.dateOfBirth
            const gender = response.data.user.gender
            const race = response.data.user.race
            const races = response.data.user.races
            const selfDescribedRace = response.data.user.selfDescribedRace
            const address = response.data.user.address
            const phoneNumber = response.data.user.phoneNumber
            const alternativePhoneNumber = response.data.user.alternativePhoneNumber
            const alternativeEmail = response.data.user.alternativeEmail
            const numberOfMembers = response.data.user.numberOfMembers
            const householdIncome = response.data.user.householdIncome
            const fosterYouth = response.data.user.fosterYouth
            const homeless = response.data.user.homeless
            const incarcerated = response.data.user.incarcerated
            const adversityList = response.data.user.adversityList

            const pictureURL = response.data.user.pictureURL
            const education = response.data.user.education
            const educationStatus = response.data.user.educationStatus

            const referrerName = response.data.user.referrerName
            const referrerEmail = response.data.user.referrerEmail
            const referrerOrg = response.data.user.referrerOrg

            this.setState({ myOrgs, joinRequests, workAuthorization, zipcode, dateOfBirth, gender, race, races, selfDescribedRace,
              address, phoneNumber, alternativePhoneNumber, alternativeEmail, numberOfMembers,
              householdIncome, fosterYouth, homeless, incarcerated, adversityList, pictureURL, education, educationStatus,
              referrerName, referrerEmail, referrerOrg
            })

            // Axios.get('/api/orgs', { params: { orgCodes: myOrgs } })
            // .then((response) => {
            //   console.log('Org info query attempted', response.data);
            //
            //   if (response.data.success) {
            //     console.log('org info query worked')
            //
            //     const myOrgObjects = response.data.orgs
            //     this.setState({ myOrgObjects })
            //
            //   } else {
            //     console.log('org info query did not work', response.data.message)
            //
            //   }
            //
            // }).catch((error) => {
            //     console.log('Org info query did not work for some reason', error);
            // });

          }
        }).catch((error) => {
            console.log('Profile pic fetch did not work', error);
        });

        Axios.get('/api/members/invite', { params: { emailId: email } })
        .then((response) => {
          console.log('Member invites query attempted', response.data);

          if (response.data.success) {
            console.log('Member invites query query worked')

            const invites = response.data.invites
            let mentors = []
            let peers = []
            if (invites && invites.length > 0) {
              for (let i = 1; i <= invites.length; i++) {
                if (invites[i - 1].recipientType === 'Mentor') {
                  mentors.push({
                    firstName: invites[i - 1].recipientFirstName,
                    lastName: invites[i - 1].recipientLastName,
                    email: invites[i - 1].recipientEmail,
                  })
                } else {
                  peers.push({
                    firstName: invites[i - 1].recipientFirstName,
                    lastName: invites[i - 1].recipientLastName,
                    email: invites[i - 1].recipientEmail,
                  })
                }
              }
            }

            this.setState({ mentors, peers })

          }

        }).catch((error) => {
          console.log('Member invites query did not work for some reason', error);
        });
      }

      if (window.innerWidth <= 768) {

        const containerWidth = '94%'
        const containerPadding = '20px'
        const sideContainerWidth = '3%'

        this.setState({ containerWidth, containerPadding, sideContainerWidth })

      }
    }

    pullProfileData(email, questions) {
      console.log('pullProfileData called ', email, questions)

      Axios.get('/api/users/profile/details/' + email)
      .then((response) => {
        if (response.data.success) {

          const responseData = response.data

          const referrerName = responseData.user.referrerName
          const referrerEmail = responseData.user.referrerEmail
          const referrerOrg = responseData.user.referrerOrg
          let autoEnrollInProgram = responseData.user.autoEnrollInProgram
          if (!autoEnrollInProgram && autoEnrollInProgram !== false) {
            autoEnrollInProgram = true
          }

          this.setState({ referrerName, referrerEmail, referrerOrg, autoEnrollInProgram });

        } else {

        }
      }).catch((error) => {
          console.log('Profile details fetch did not work', error);

      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called ', event.target.name, event.target.value)

      if (event.target.name.includes('question')) {
        const index = Number(event.target.name.split('|')[1])
        for (let i = 1; i <= this.state.questions.length; i++) {
          console.log('compare indices: ', index, i - 1, typeof index)
          if (index === i - 1) {
            let questions = this.state.questions
            questions[index]['answer'] = event.target.value
            console.log('show questions after form: ', questions)
            this.setState({ questions, formHasChanged: true })
          }
        }
      } else if (event.target.name === 'employerDepartment') {
        this.suggestItems('employerDepartment', event.target.value)
        this.setState({ [event.target.name]: event.target.value })
      } else if (event.target.name.includes('peer|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let peers = this.state.peers
        peers[index][name] = event.target.value
        this.setState({ peers })
      } else if (event.target.name.includes('education|')) {
        const name = event.target.name.split("|")[1]

        let education = this.state.education
        if (education && education[0]) {
          education[0][name] = event.target.value
        } else {
          education = [{}]
          education[0][name] = event.target.value
        }

        this.setState({ education })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    suggestItems(type, searchString) {
      console.log('suggestItems called')

      if (type === 'employerDepartment') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, suggestedDepartments: null })
        } else {
          // this.setState({ searchString })

          let suggestedDepartments = []

          const searchStringRegEx = new RegExp(searchString.toLowerCase())

          for (let i = 1; i <= this.state.departmentOptions.length; i++) {
            if (searchStringRegEx.test(this.state.departmentOptions[i - 1].toLowerCase())) {
              // console.log('search: ', departmentOptions[i])
              suggestedDepartments.push(this.state.departmentOptions[i - 1])
            }
          }

          this.setState({ suggestedDepartments })

        }
      }
    }

    suggestedItemClicked(passedItem, type) {
      console.log('suggestedItemClicked called', passedItem, type)

      if (type === 'department') {

        const employerDepartment = passedItem
        const suggestedDepartments = null

        this.setState({ employerDepartment, suggestedDepartments })

      } else if (type === 'org') {
        // join org
        console.log('join org')

        // const employerDepartment = passedItem
        const suggestedOrgs = null
        this.setState({ suggestedOrgs })

        this.submitRequest(null, passedItem, null, false)

      }
    }

    submitSignUpFields() {
      console.log('submitSignUpFields called')

      if (this.state.orgSelected.signUpFieldsRequired && this.state.orgSelected.signUpFieldsRequired.length > 0) {
        for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
          console.log('l1')
          const item = this.state.orgSelected.signUpFieldsRequired[i - 1]

          // if (this.state.orgSelected.signUpFieldsRequired[i - 1].required) {
          //   console.log('l2', this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand, this.state.education, this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand])
          //   // multiple answer is array
          //   if (this.state.orgSelected.signUpFieldsRequired[i - 1].questionType === 'Multiple Answer' && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] || this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand].length === 0)) {
          //     return this.setState({ errorMessage: 'Please add answer(s) for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
          //   } else if (!this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.includes("|") && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] || !this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] === '')) {
          //     return this.setState({ errorMessage: 'Please add an answer for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
          //   } else if (this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.includes("|") && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.split("|")[0]] || this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.split("|")[0]].length === 0)) {
          //     return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
          //     // return this.setState({ errorMessage: 'Please add answer(s) for the ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name + ' field' })
          //   }
          // }

          if (item.required) {
            if (item.questionType === 'Multiple Answer' && (!this.state[item.shorthand] || this.state[item.shorthand].length === 0)) {
              return this.setState({ errorMessage: 'Please add answer(s) for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
            } else if (!item.shorthand.includes("|") && (!this.state[item.shorthand] || !this.state[item.shorthand] === '')) {
              return this.setState({ errorMessage: 'Please add an answer for ' + item.name })
            } else if (item.shorthand.includes("|") && (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0)) {
              return this.setState({ errorMessage: 'Please add at least one educational experience' })
              console.log('what is missing', item.shorthand, this.state[item.shorthand.split("|")[0]])
            } else if (item.shorthand.includes("|")) {
              console.log('l3')
              if (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0) {
                return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
              } else if (!this.state[item.shorthand.split("|")[0]][0][item.shorthand.split("|")[1]]) {
                return this.setState({ errorMessage: 'Please add answer(s) for each of the education fields' })
              }
              console.log('l4')
            }
          }
        }
      }

      const dateOfBirth = this.state.dateOfBirth
      const gender = this.state.gender
      const race = this.state.race
      const races = this.state.races
      const selfDescribedRace = this.state.selfDescribedRace
      const address = this.state.address
      const zipcode = this.state.zipcode
      const phoneNumber = this.state.phoneNumber
      const alternativePhoneNumber = this.state.alternativePhoneNumber
      const alternativeEmail = this.state.alternativeEmail
      const numberOfMembers = this.state.numberOfMembers
      const householdIncome = this.state.householdIncome
      const fosterYouth = this.state.fosterYouth
      const homeless = this.state.homeless
      const incarcerated = this.state.incarcerated
      const workAuthorization = this.state.workAuthorization
      const adversityList = this.state.adversityList
      const educationStatus = this.state.educationStatus

      let education = this.state.education
      // if (this.state.education && this.state.education[0]) {
      //   education[0]['name'] = this.state.education.name
      //   education[0]['endDate'] = this.state.education.endDate
      //   education[0]['major'] = this.state.education.major
      // }
      const referrerName = this.state.referrerName
      const referrerEmail = this.state.referrerEmail
      const referrerOrg = this.state.referrerOrg

      const signUpFields = {
        dateOfBirth, gender, race, races, selfDescribedRace, address, zipcode, phoneNumber, alternativePhoneNumber,
        alternativeEmail, numberOfMembers, householdIncome, workAuthorization,
        fosterYouth, homeless, incarcerated, adversityList, education, educationStatus,
        referrerName, referrerEmail, referrerOrg
      }

      console.log('request submitted', education)
      this.submitRequest(null, this.state.orgSelected, signUpFields, false)
    }

    async submitRequest(e, value, passedSignUpFields, fromButton) {
      console.log('submitRequest called')

      let cta = 'Join Workspace'
      if (!value.isOpen) {
        cta = 'Request Access'
      }

      const showMyCommunities = true

      if (value.isOpen) {
        // console.log('82', value.signUpFields)
        if (value.signUpFieldsRequired && value.signUpFieldsRequired.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let myOrgs = this.state.myOrgs
              if (returnedValue.success) {
                if (myOrgs) {
                  myOrgs.push(value.orgCode)
                } else {
                  myOrgs = [value.orgCode]
                }
              }

              localStorage.setItem('activeOrg', value.orgCode)
              localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs, modalIsOpen: false, showSignUpFields: false, showMyCommunities })
              this.retrieveData()
              // this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)

            } else {

              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }

              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            // this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: true, showOrgDetails: false })

            this.openSignUpFieldsModal(value)
          }

        } else {
          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue.success) {
            let myOrgs = this.state.myOrgs
            if (returnedValue.success) {
              if (myOrgs) {
                myOrgs.push(value.orgCode)
              } else {
                myOrgs = [value.orgCode]
              }
            }

            localStorage.setItem('activeOrg', value.orgCode)
            localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs, showMyCommunities })
            // this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)
            this.retrieveData()

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }

      } else {
        console.log('request access')

        if (value.signUpFields && value.signUpFields.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let joinRequests = this.state.joinRequests
              if (returnedValue.success) {
                if (joinRequests) {
                  joinRequests.push(value.orgCode)
                } else {
                  joinRequests = [value.orgCode]
                }
              }

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests, modalIsOpen: false, showSignUpFields: false, showMyCommunities })
            } else {
              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }
              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: false })
          }

        } else {

          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null, null, null, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue.success) {
            let joinRequests = this.state.joinRequests
            if (returnedValue.success) {
              if (joinRequests) {
                joinRequests.push(value.orgCode)
              } else {
                joinRequests = [value.orgCode]
              }
            }

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests })

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }
      }
    }

    addItem(type, value) {
      console.log('addItem called', type, value)

      if (type === 'timeframe') {
        let hireTimeframes = this.state.hireTimeframes
        if (hireTimeframes.includes(value)) {
          const index = hireTimeframes.indexOf(value)
          if (index > -1) {
            hireTimeframes.splice(index, 1);
          }
        } else {
          hireTimeframes.push(value)
        }

        this.setState({ hireTimeframes })
      } else if (type === 'department') {
        let departments = this.state.departments
        if (departments) {
          departments.push(this.state.employerDepartment)
        } else {
          departments = [this.state.employerDepartment]
        }
        this.setState({ departments, employerDepartment: '', suggestedDepartments: null })
      } else if (type === 'mentor') {
        let mentors = this.state.mentors

        let firstName = this.state.mentorFirstName
        let lastName = this.state.mentorLastName
        let email = this.state.mentorEmail

        if (!firstName || !lastName || !email) {
          this.setState({ mentorErrorMessage: "Please write the mentor's first name, last name, and email before adding"})
        } else if (!email.includes('@')) {
          this.setState({ mentorErrorMessage: "Please enter a valid email"})
        } else if (mentors && mentors.some(m => m.email === email)) {
          this.setState({ mentorErrorMessage: "You've already added this email"})
        } else {
          const mentorObject = { firstName, lastName, email }
          if (mentors) {
            mentors.push(mentorObject)
          } else {
            mentors = [mentorObject]
          }

          this.setState({ mentors, mentorFirstName: '', mentorLastName: '', mentorEmail: '', mentorErrorMessage: null })
        }

      } else if (type === 'peer') {
        let peers = this.state.peers

        let firstName = this.state.peerFirstName
        let lastName = this.state.peerLastName
        let email = this.state.peerEmail

        if (!firstName || !lastName || !email) {
          this.setState({ peerErrorMessage: "Please write the peer's first name, last name, and email before adding"})
        } else if (!email.includes('@')) {
          this.setState({ peerErrorMessage: "Please enter a valid email"})
        } else if (peers && peers.some(p => p.email === email)) {
          this.setState({ peerErrorMessage: "You've already added this email"})
        } else {
          const peerObject = { firstName, lastName, email }
          if (peers) {
            peers.push(peerObject)
          } else {
            peers = [peerObject]
          }
          this.setState({ peers, peerFirstName: '', peerLastName: '', peerEmail: '', peerErrorMessage: null  })
        }
      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'department') {
        let departments = this.state.departments
        departments.splice(index, 1)
        this.setState({ departments })
      } else if (type === 'mentor') {
        let mentors = this.state.mentors
        mentors.splice(index, 1)
        this.setState({ mentors })
      } else if (type === 'peer') {
        let peers = this.state.peers
        peers.splice(index, 1)
        this.setState({ peers })
      }
    }

    renderTags(type, passedArray) {
      console.log('renderTags ', type, passedArray)

      if (passedArray) {
        return (
          <div key={type + "|0"}>
            <div className="spacer" />
            {passedArray.map((value, optionIndex) =>
              <div key={type + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeItem(type, optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    movePage(forward) {
      console.log('moveForward called', this.state.pageIndex)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (forward) {
        if (this.state.pageIndex === 1) {
          if (this.state.requireReferrer && (!this.state.referrerName || this.state.referrerName === '')) {
            this.setState({ errorMessage: 'Please add the name of your referrer. N/A if no one.', isSaving: false })
          } else if (this.state.requireReferrer && (!this.state.referrerEmail || this.state.referrerEmail === '')) {
            this.setState({ errorMessage: 'Please add the email of your referrer. N/A if no one.', isSaving: false })
          } else if (this.state.requireReferrer && (!this.state.referrerOrg || this.state.referrerOrg === '')) {
            this.setState({ errorMessage: 'Please add the organization of your referrer. N/A if no org.', isSaving: false })
          } else {
            // save answers

            if (this.state.requireReferrer) {
              this.saveToProfile()
            } else {
              this.setState({ pageIndex: this.state.pageIndex + 1 })
            }

          }
        } else if (this.state.pageIndex === 2) {
          // this is handled by EditProfileDetails

          if (window.location.pathname.includes('/employers')) {
            this.saveToProfile(true)
          } else {
            this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })
          }

          // window.scrollTo(0, 0)
          // this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })
        } else if (this.state.pageIndex === 3) {

          if (window.location.pathname.includes('/employers')) {
            this.setState({ pageIndex: this.state.pageIndex + 1 })
          } else {
            this.saveToProfile()
          }

        } else if (this.state.pageIndex === 4) {

          window.scrollTo(0, 0)

          if (window.location.pathname.includes('/employers')) {
            this.props.navigate('/employers/' + this.state.accountCode + '/dashboard')
          } else {
            this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })
          }

        } else if (this.state.pageIndex === 5) {

          if (this.state.showModule) {
            window.scrollTo(0, 0)
            this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })

          } else {
            window.scrollTo(0, 0)

            if (this.props.opportunityId) {
              this.props.navigate('/app/opportunities/' + this.props.opportunityId)
            } else {
              this.props.navigate('/app')
            }
          }

        } else {
          // save and move to the app

          window.scrollTo(0, 0)

          if (this.props.opportunityId) {
            this.props.navigate('/app/opportunities/' + this.props.opportunityId)
          } else {
            this.props.navigate('/app')
          }

          // this.props.navigate('/app')
        }
        // this.setState({ pageIndex: this.state.pageIndex + 1 })
      } else  {
        window.scrollTo(0, 0)
        this.setState({ pageIndex: this.state.pageIndex - 1, isSaving: false })
      }
    }

    saveToProfile(employerProfile) {
      console.log('saveToProfile called', employerProfile)

      if (employerProfile) {
        // save departments

        const accountCode = this.state.accountCode
        const departments = this.state.departments

        Axios.post('/api/account/update', { accountCode, departments })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Account update worked', response.data);

            window.scrollTo(0, 0)
            this.setState({ successMessage: 'Account information saved successfully!', pageIndex: this.state.pageIndex + 1, isSaving: false })

          } else {
            console.error('there was an error updating the account info');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Account info save did not work', error);
            this.setState({ errorMessage: error.toString() })
        });

      } else {
        const emailId = this.state.emailId
        const referrerName = this.state.referrerName
        const referrerEmail = this.state.referrerEmail
        const referrerOrg = this.state.referrerOrg
        const autoEnrollInProgram = this.state.autoEnrollInProgram
        const updatedAt = new Date()

        const userObject = { emailId, referrerName, referrerEmail, referrerOrg, autoEnrollInProgram, updatedAt }
        Axios.post('/api/users/profile/details', userObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User update worked', response.data);
            window.scrollTo(0, 0)

            if (this.state.limitTo3 && this.state.pageIndex === 3) {
              if (this.props.opportunityId) {
                this.props.navigate('/app/opportunities/' + this.props.opportunityId)
              } else if (this.props.courseId) {
                this.props.navigate('/app/courses/' + this.props.courseId + '/take')
              } else {
                this.props.navigate('/app')
              }

            } else {
              this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })
            }

          } else {
            console.error('there was an error saving to user profile');
            this.setState({ errorMessage: 'Information was not saved successfully: ' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('Saving to user profile did not work', error);
        });
      }
    }

    renderWalkthroughContents() {
      console.log('renderWalkthroughContents called')

      const pageIndex = this.state.pageIndex

      let rows = []

      rows.push(
        <div className="left-side-walkthrough-container" style={{ width: this.state.sideContainerWidth }} key={2}>
          {(this.state.showArrows) && (
            <div>
              { ( pageIndex !== 0 ) && (
                <a className="background-button-white arrow-container" onClick={() => this.movePage(false)}>
                  <img src={arrowIndicatorIconLeft} alt="Compass arrow indicator icon" className="walkthrough-arrow-indicator" />
                </a>
              )}
            </div>
          )}
        </div>
      )

      rows.push(
        <div key={0} className="medium-background float-left box" style={{ width: this.state.containerWidth }}>
          <div className="full-space white-background rounded-corners padding-5-percent">

            { ( pageIndex === 0 ) && (
              <div>
                <div className="spacer"/><div className="spacer"/>

                <p className="heading-text-2">Welcome to the UNITE-LA Portal, {this.state.cuFirstName}!</p>

                <div className="row-30">
                  <div className="container-left">
                    <div className="row-2-half-percent">
                      <div>
                        <div className="fixed-column-70 top-margin-5">
                          <img src={eventIconBlue} alt="Compass arrow indicator icon" className="image-auto-50 center-item" />
                        </div>
                        <div className="calc-column-offset-70">
                          <p className="heading-text-5 row-5">Discover Events</p>
                          <p className="description-text-2">RSVP to and comment on career development events like job shadows, webinars, and workshops.</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>

                    <div className="row-3-half-percent">
                      <div>
                        <div className="fixed-column-70 top-margin-5">
                          <img src={projectsIconBlue} alt="Compass arrow indicator icon" className="image-auto-50 center-item" />
                        </div>
                        <div className="calc-column-offset-70">
                          <p className="heading-text-5 row-5">Submit Projects</p>
                          <p className="description-text-2">Submit projects directly to employer representatives and get feedback. Participate in competitions.</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>

                    <div className="row-3-half-percent">
                      <div>
                        <div className="fixed-column-70 top-margin-5 horizontal-padding-4">
                          <img src={internIconBlue} alt="Compass arrow indicator icon" className="image-auto-60 center-item" />
                        </div>
                        <div className="calc-column-offset-70">
                          <p className="heading-text-5 row-5">Find Internships</p>
                          <p className="description-text-2">Get internship recommendations, save your favorites, bulk apply, and get notifications of feedback and offers.</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                  <div className="container-right">
                    <div className="center-horizontally">
                      <div className="featured-image-circle-container">
                        <img src={partnerIcon} alt="GC" className="featured-image" />
                      </div>

                      <div className="spacer" />

                      <div className="full-width center-text description-text-2 horizontal-padding-8">
                        <p>{this.state.partnerStatement}</p>
                        <div className="spacer"/><div className="half-spacer"/>
                        <a href={"https://www.guidedcompass.com/partners/" + this.state.partnerName + "/students"} target="_blank">Learn more about how you benefit</a>
                      </div>

                    </div>
                  </div>
                  <div className="clear" />

                </div>
              </div>
            )}

            {( pageIndex === 1 ) && (
              <div>
                <div className="spacer"/><div className="spacer"/>

                <p className="heading-text-2">Basic Info</p>
                <p className="description-text-1">The below demographic information is used for financial aid and for reporting to our funders.</p>

                <div className="row-20">
                  {this.state.questions.map((value, index) =>
                    <div key={index}>
                      {(value.category === 'basic') && (
                        <div className={(index % 2 === 0) ? "container-left" : "container-right"}>
                          <div className="row-2-half-percent">
                            <p className="row-5">{value.name} <label className="error-color bold-text">*</label></p>
                            {(value.type === 'Date') && (
                              <input type="date" className="date-picker" name={'question|' + index} value={value.answer} min={convertDateToString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} max={convertDateToString(new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} onChange={this.formChangeHandler}></input>
                            )}
                            {(value.type === 'Short Answer') && (
                              <input className="text-field" type="text" placeholder={value.placeholder} name={'question|' + index} value={value.answer} onChange={this.formChangeHandler} />
                            )}
                            {(value.type === 'Multiple Choice') && (
                              <select name={'question|' + index} value={value.answer} className="dropdown" onChange={this.formChangeHandler}>
                                {this.state.questions[index].options.map(value2 =>
                                  <option key={value2} value={value2}>{value2}</option>
                                )}
                              </select>
                            )}
                            {(value.type === 'Multiple Answer') && (
                              <div>
                                {this.state.questions[index].options.map((value, optionIndex) =>
                                  <div key={value + optionIndex}>
                                    <div className="top-margin-5 right-padding">
                                      {(this.state.questions[index].answer && this.state.questions[index].answer.includes(value)) ? (
                                        <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex)}>
                                          <div>
                                            <div className="float-left left-text">
                                              <p className="description-text-2 white-text">{value}</p>
                                            </div>
                                          </div>
                                        </button>
                                      ) : (
                                        <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex)}>
                                          <div>
                                            <div className="float-left left-text">
                                              <p className="description-text-2">{value}</p>
                                            </div>
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(index % 2 === 1) && (
                        <div className="clear" />
                      )}
                    </div>
                  )}
                  <div className="clear" />

                </div>
              </div>
            )}

            { ( pageIndex === 2 ) && (
              <div>
                <div className="spacer"/><div className="spacer"/>

                <p className="heading-text-2">Diversity / Adversity Info</p>
                <p className="description-text-1">The below demographic information is used for financial aid and for reporting to our funders.</p>

                <div className="row-20">
                  {this.state.questions.map((value, index) =>
                    <div key={index}>
                      {(value.category === 'diversity') && (
                        <div className="container-left">
                          <div className="row-2-half-percent">
                            <p className="row-5">{value.name} <label className="error-color bold-text">*</label></p>
                            {(value.type === 'Date') && (
                              <input type="date" className="date-picker" name={'question|' + index} value={value.answer}  min={convertDateToString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} max={convertDateToString(new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} onChange={this.formChangeHandler}></input>
                            )}
                            {(value.type === 'Short Answer') && (
                              <input className="text-field" type="text" placeholder={value.placeholder} name={'question|' + index} value={value.answer} onChange={this.formChangeHandler} />
                            )}
                            {(value.type === 'Multiple Choice') && (
                              <select name={'question|' + index} value={value.answer} className="dropdown" onChange={this.formChangeHandler}>
                                {this.state.questions[index].options.map(value2 =>
                                  <option key={value2} value={value2}>{value2}</option>
                                )}
                              </select>
                            )}
                            {(value.type === 'Multiple Answer') && (
                              <div>
                                {this.state.questions[index].options.map((value, optionIndex) =>
                                  <div key={value + optionIndex}>
                                    <div className="float-left top-margin-5 right-padding">
                                      {(this.state.questions[index].answer && this.state.questions[index].answer.includes(value)) ? (
                                        <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex)}>
                                          <div>
                                            <div className="float-left left-text">
                                              <p className="description-text-2 white-text">{value}</p>
                                            </div>
                                          </div>
                                        </button>
                                      ) : (
                                        <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex)}>
                                          <div>
                                            <div className="float-left left-text">
                                              <p className="description-text-2">{value}</p>
                                            </div>
                                          </div>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />

                </div>
              </div>
            )}

            { ( pageIndex === 3 ) && (
              <div>
                <div className="spacer"/><div className="spacer"/>

                <p className="heading-text-2">Referral Source Info</p>
                <p className="description-text-1">Would you mind sharing who referred you?</p>

                <div className="row-20">
                  {this.state.questions.map((value, index) =>
                    <div key={index}>
                      {(value.category === 'referral') && (
                        <div className="container-left">
                          <div className="row-2-half-percent">

                            {(value.type === 'Checkbox') ? (
                              <div>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                <div>
                                  <div className="fixed-column-30">
                                    <button className="background-button" onClick={() => this.optionClicked(index, null)}>
                                      <img src={value.answer ? checkboxChecked : checkboxEmpty } alt="GC" className="image-auto-20" />
                                    </button>
                                  </div>
                                  <div className="calc-column-offset-30">
                                    <p>{value.name}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p>{value.name}</p>
                                <div className="half-spacer" />
                                {(value.type === 'Date') && (
                                  <input type="date" className="date-picker" name={'question|' + index} value={value.answer}  min={convertDateToString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} max={convertDateToString(new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} onChange={this.formChangeHandler}></input>
                                )}
                                {(value.type === 'Short Answer') && (
                                  <input className="text-field" type="text" placeholder={value.placeholder} name={'question|' + index} value={value.answer} onChange={this.formChangeHandler} />
                                )}
                                {(value.type === 'Multiple Choice') && (
                                  <select name={'question|' + index} value={value.answer} className="dropdown" onChange={this.formChangeHandler}>
                                    {this.state.questions[index].options.map(value2 =>
                                      <option key={value2} value={value2}>{value2}</option>
                                    )}
                                  </select>
                                )}
                                {(value.type === 'Multiple Answer') && (
                                  <div>
                                    {this.state.questions[index].options.map((value, optionIndex) =>
                                      <div key={value + optionIndex}>
                                        <div className="top-margin-5 right-padding">
                                          {(this.state.questions[index].answer && this.state.questions[index].answer.includes(value)) ? (
                                            <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex)}>
                                              <div>
                                                <div className="float-left left-text">
                                                  <p className="description-text-2 white-text">{value}</p>
                                                </div>
                                              </div>
                                            </button>
                                          ) : (
                                            <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex)}>
                                              <div>
                                                <div className="float-left left-text">
                                                  <p className="description-text-2">{value}</p>
                                                </div>
                                              </div>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />

                </div>
              </div>
            )}

            <div className="row-20">

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-message row-15">{this.state.errorMessage}</p>}

              {(pageIndex !== 0) ? (
                <div className="relative-column-20">
                  <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ pageIndex: pageIndex - 1 })}>Back</button>
                </div>
              ) : (
                <div className="relative-column-20 height-40"/>
              )}

              <div className="relative-column-60 top-margin height-40">
                <ul className="dot-list padding-1">
                  <div className="dot-list-item"><li>
                    <div className={(pageIndex === 0) ? "slide-indicator-selected" : 'slide-indicator-unselected'} />
                  </li></div>
                  <div className="dot-list-item"><li>
                    <div className={(pageIndex === 1) ? "slide-indicator-selected" : 'slide-indicator-unselected'} />
                  </li></div>
                  <div className="dot-list-item"><li>
                    <div className={(pageIndex === 2) ? "slide-indicator-selected" : 'slide-indicator-unselected'} />
                  </li></div>
                  <div className="dot-list-item"><li>
                    <div className={(pageIndex === 3) ? "slide-indicator-selected" : 'slide-indicator-unselected'} />
                  </li></div>
                </ul>
              </div>

              <div className="relative-column-20">
                <button className="btn btn-squarish float-right" onClick={() => this.nextClicked()}>{(pageIndex === 3) ? "Get Started" : "Next"}</button>
              </div>
              <div className="clear" />
            </div>

          </div>
        </div>
      )

      rows.push(
        <div className="right-side-walkthrough-container" style={{ width: this.state.sideContainerWidth }} key={3}>
          {(this.state.showArrows) && (
            <div>
              { ( pageIndex !== 3 ) && (
                <a className="background-button-white arrow-container" onClick={() => this.movePage(true)}>
                  <img src={arrowIndicatorIconRight} alt="Compass arrow indicator icon" className="walkthrough-arrow-indicator" />
                </a>
              )}
            </div>
          )}
        </div>
      )

      let logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-guided-compass-logo.png'

      rows.push(
        <div key={1}>
          <div className="clear" />

          <div className="full-width center-text" >
            <img src={logoImg} alt="Compass logo" className="image-auto-30 center-horizontally"/>
            <div className="spacer" /><div className="spacer" />
          </div>

        </div>
      )
      return rows
    }

    nextClicked() {
      console.log('nextClicked called')

      // fields: dateOfBirth, gender, race, homeAddress, phoneNumber, numberOfMembers, householdIncome, fosterYouth
      // fields: homeless, incarcerated, adversityList, referrerName, referrerEmail, referrerOrg

      if (this.state.pageIndex === 3) {

        for (let i = 1; i <= this.state.questions.length; i++) {
          if (this.state.questions[i - 1].category === 'referral') {
            if ((!this.state.questions[i - 1].answer || this.state.questions[i - 1].answer === '') && this.state.questions[i - 1].required) {
              return this.setState({ errorMessage: 'Please add your ' + this.state.questions[i - 1].name.toLowerCase() })
            }
          }
        }

        const emailId = this.state.emailId

        if (this.state.formHasChanged) {
          // UNITE-LA lineup
          const dateOfBirth = this.state.questions[0].answer
          const gender = this.state.questions[1].answer
          const race = this.state.questions[2].answer
          const address = this.state.questions[3].answer
          const zipcode = this.state.questions[4].answer
          const phoneNumber = this.state.questions[5].answer
          const numberOfMembers = this.state.questions[6].answer
          const householdIncome = this.state.questions[7].answer
          const fosterYouth = this.state.questions[8].answer
          const homeless = this.state.questions[9].answer
          const incarcerated = this.state.questions[10].answer
          const adversityList = this.state.questions[11].answer
          const referrerName = this.state.questions[12].answer
          const referrerEmail = this.state.questions[13].answer
          const referrerOrg = this.state.questions[14].answer
          const autoEnrollInProgram = this.state.questions[15].answer

          const updatedAt = this.state.updatedAt
          console.log('autoEnroll: ', autoEnrollInProgram)

          //save values then segue
          this.props.saveProfileDetails({
              emailId,
              dateOfBirth, gender, race, address, zipcode, phoneNumber, numberOfMembers, householdIncome, fosterYouth,
              homeless, incarcerated, adversityList, referrerName, referrerEmail, referrerOrg, autoEnrollInProgram,
              updatedAt
          })
          .then((responseData) => {

              if (responseData.success) {

                  //report whether values were successfully saved
                  // this.props.navigate('/app')
                  if (this.props.opportunityId) {
                    this.props.navigate('/app/opportunities/' + this.props.opportunityId)
                  } else {
                    this.props.navigate('/app')
                  }

              } else {

                  console.log('request was not successful')
                  this.setState({ errorMessage: 'Information was not saved successfully: ' + responseData.message })
              }
          })
        } else {
          // this.props.navigate('/app')
          if (this.props.opportunityId) {
            this.props.navigate('/app/opportunities/' + this.props.opportunityId)
          } else {
            this.props.navigate('/app')
          }
        }
      } else {

        if (this.state.pageIndex === 0) {
          const pageIndex = this.state.pageIndex + 1
          this.setState({ pageIndex })
        } else if (this.state.pageIndex === 1) {

          for (let i = 1; i <= this.state.questions.length; i++) {
            if (this.state.questions[i - 1].category === 'basic') {
              console.log('check error 1', this.state.questions[i - 1].answer)
              if (!this.state.questions[i - 1].answer || this.state.questions[i - 1].answer === '') {
                console.log('check error 2', this.state.questions[i - 1].name)
                return this.setState({ errorMessage: 'Please add your ' + this.state.questions[i - 1].name.toLowerCase() })
              }
            }
          }
          console.log('continued')
          const pageIndex = this.state.pageIndex + 1
          this.setState({ pageIndex, errorMessage: '' })
        } else if (this.state.pageIndex === 2) {
          for (let i = 1; i <= this.state.questions.length; i++) {
            if (this.state.questions[i - 1].category === 'diversity') {
              if (!this.state.questions[i - 1].answer || this.state.questions[i - 1].answer === '') {
                return this.setState({ errorMessage: 'Please add your ' + this.state.questions[i - 1].name.toLowerCase() })
              }
            }
          }

          const pageIndex = this.state.pageIndex + 1
          this.setState({ pageIndex, errorMessage: '' })
        } else if (this.state.pageIndex === 3) {
          for (let i = 1; i <= this.state.questions.length; i++) {
            if (this.state.questions[i - 1].category === 'referral') {
              if (!this.state.questions[i - 1].answer || this.state.questions[i - 1].answer === '') {
                return this.setState({ errorMessage: 'Please add your ' + this.state.questions[i - 1].name.toLowerCase() })
              }
            }
          }

          const pageIndex = this.state.pageIndex + 1
          this.setState({ pageIndex, errorMessage: '' })
        }
      }
    }

    optionClicked(index, optionIndex) {
      console.log('optionClicked called', index, optionIndex)

      let autoEnrollInProgram = this.state.autoEnrollInProgram
      if (autoEnrollInProgram) {
        this.setState({ autoEnrollInProgram: false })
      } else {
        this.setState({ autoEnrollInProgram: true })
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showGoal: false, showEditGroup: false, showSearchGroups: false,
        showAddWorkspaces: false, enlargeImage: false, showCreateBenchmark: false, showSocialPost: false, showATS: false,
        showCreateEvent: false, showCreateProject: false, showCreatePipeline: false, showCreateCommunity: false, showCreateWork: false,
        showOrgDetails: false, selectedOrg: null, showSignUpFields: false
      })
    }

    passGoal(goal) {
      console.log('passGoal called', goal)

      this.setState({ passedGoal: goal })
    }

    passGroup(group, add) {
      console.log('passGroup called in walkthrough', group, add)

      let groupsJoined = this.state.groupsJoined
      if (add) {
        if (groupsJoined) {
          console.log('t0', groupsJoined)
          // groupsJoined.push(group)
          groupsJoined = [group].concat(groupsJoined)
        } else {
          groupsJoined = [group]
        }
      } else {

        if (groupsJoined) {
          let index = 0
          for (let i = 1; i <= groupsJoined.length; i++) {
            if (groupsJoined[i - 1]._id === group._id) {
              index = i - 1
            }
          }
          groupsJoined.splice(index,1)
        }
      }
      console.log('t1', groupsJoined)
      this.setState({ groupsJoined })
    }

    passOrgs(activeOrg, myOrgs, orgFocus, orgName, orgLogo) {
      console.log('passOrgs called', activeOrg, myOrgs, orgFocus, orgName, orgLogo )

      this.setState({ activeOrg, myOrgs, orgFocus, orgName, orgLogo })
      this.closeModal()

    }

    passPosts(socialPosts) {
      console.log('passPosts called')

      this.setState({ socialPosts })
    }

    openSignUpFieldsModal(orgSelected) {
      console.log('openSignUpFieldsModal called')

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const degreeOptions = response.data.workOptions[0].degreeOptions.slice(1,response.data.workOptions[0].degreeOptions.lengh)

          const educationStatusOptions = degreeOptions.concat(['Not currently enrolled in school'])

          let educationDateOptions = []

          const currentMonth = new Date().getMonth()
          const currentYear = new Date().getFullYear()

          let numberOfYears = 25
          let educationBump = 5
          let month = ''
          let year = currentYear - numberOfYears

          // console.log('show me current stuff', currentMonth, currentYear)
          for (let i = 1; i <= ((numberOfYears + educationBump) * 12); i++) {
            // console.log('show me stuff', i, (i + currentMonth + 1) % 12)
            if ((i + currentMonth + 1) % 12 === 2) {
              month = 'January'
            } else if ((i + currentMonth + 1) % 12 === 3) {
              month = 'February'
            } else if ((i + currentMonth + 1) % 12 === 4) {
              month = 'March'
            } else if ((i + currentMonth + 1) % 12 === 5) {
              month = 'April'
            } else if ((i + currentMonth + 1) % 12 === 6) {
              month = 'May'
            } else if ((i + currentMonth + 1) % 12 === 7) {
              month = 'June'
            } else if ((i + currentMonth + 1) % 12 === 8) {
              month = 'July'
            } else if ((i + currentMonth + 1) % 12 === 9) {
              month = 'August'
            } else if ((i + currentMonth + 1) % 12 === 10) {
              month = 'September'
            } else if ((i + currentMonth + 1) % 12 === 11) {
              month = 'October'
            } else if ((i + currentMonth + 1) % 12 === 0) {
              month = 'November'
            } else if ((i + currentMonth + 1) % 12 === 1) {
              month = 'December'
            }

            if (month === 'January') {
              year = year + 1
            }

            // dateOptions.push({ value: month + ' ' + year})
            if (i <= (numberOfYears * 12)) {
              // dateOptions.push({ value: month + ' ' + year})
            }
            educationDateOptions.push(month + ' ' + year)

          }

          const raceOptions = response.data.workOptions[0].raceOptions
          const genderOptions = response.data.workOptions[0].genderOptions

          const householdIncomeOptions = response.data.workOptions[0].lowIncomeOptions
          const adversityListOptions = response.data.workOptions[0].adversityListOptions
          const numberOfMembersOptions = response.data.workOptions[0].numberOfMembersOptions
          const workAuthorizationOptions = response.data.workOptions[0].workAuthorizationOptions
          // console.log('show educationDateOptions: ', educationDateOptions)
          const workOptions = {
            raceOptions, genderOptions, degreeOptions, householdIncomeOptions, adversityListOptions,
            numberOfMembersOptions, workAuthorizationOptions,
            educationStatusOptions, educationDateOptions
          }

          if (orgSelected.signUpFieldsRequired && orgSelected.signUpFieldsRequired.length > 0) {
            let signUpFieldsRequired = orgSelected.signUpFieldsRequired
            for (let i = 1; i <= signUpFieldsRequired.length; i++) {
              if (signUpFieldsRequired[i - 1].answerChoices && signUpFieldsRequired[i - 1].answerChoices.length === 1) {
                signUpFieldsRequired[i - 1].answerChoices = workOptions[signUpFieldsRequired[i - 1].answerChoices[0]]
              }
            }
          }

          this.setState({ modalIsOpen: true, orgSelected, showSignUpFields: true, showOrgDetails: false })

        } else {
          console.log('no workOptions found')

        }
      }).catch((error) => {
          console.log('query for work options did not work', error);
      })
    }

    orgClicked(index, type) {
      console.log('orgClicked clicked', index, type, this.state.roleName)

      let activeOrg = ''
      if (this.state.activeOrg !== this.state.myOrgObjects[index].orgCode) {
        activeOrg = this.state.myOrgObjects[index].orgCode

        this.setState({ activeOrg })
        localStorage.setItem('activeOrg', activeOrg)

        const emailId = this.state.emailId
        const updatedAt = new Date()

        console.log('we rollin', emailId, activeOrg)

        Axios.post('/api/users/profile/details', {
          emailId, activeOrg, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User update worked', response.data);

            //update the orgFocus
            Axios.get('/api/org', { params: { orgCode: activeOrg } })
            .then((response) => {
              console.log('Org query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved org')

                 const orgFocus = response.data.orgInfo.orgFocus
                 const studentAlias = response.data.orgInfo.studentAlias

                 localStorage.setItem('orgFocus', orgFocus)
                 localStorage.setItem('studentAlias', studentAlias)

                 const serverPostSuccess = true
                 const serverSuccessMessage = 'Active org successfully changed and saved!'

                 this.setState({ orgFocus, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                 // this.props.passOrgs(activeOrg)
                 this.retrieveData()

               } else {
                 console.log('no org data found', response.data.message)
               }

            }).catch((error) => {
               console.log('Org query did not work', error);
               this.setState({ serverErrorMessage: 'No org found'})
            });

          } else {
            console.error('there was an error saving the new active org');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Saving the active org did not work', error);
        });
      }
    }

    removeOrg(index,type) {
      console.log('removeOrg called', this.state.selectedOrg, index, type)

      const selectedOrg = this.state.myOrgObjects[index].orgCode

      if (this.state.myOrgObjects.length > 1) {

        const emailId = this.state.emailId
        const updatedAt = new Date()


        Axios.post('/api/users/profile/details', {
          emailId, activeOrg: selectedOrg, removeOrg: true, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User update worked', response.data);

            if (type === 'all') {

              let changeActiveOrg = false
              if (selectedOrg === this.state.activeOrg) {
                changeActiveOrg = true
              }

              let myOrgs = this.state.myOrgs
              let removeIndex = null
              for (let i = 1; i <= this.state.myOrgs.length; i++) {
                if (this.state.myOrgs[i - 1] === selectedOrg) {
                  removeIndex = i - 1
                }
              }
              myOrgs.splice(removeIndex,1)
              localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

              let myOrgObjects = this.state.myOrgObjects
              myOrgObjects.splice(index,1)

              const serverPostSuccess = true
              const serverSuccessMessage = 'Org successfully removed!'

              if (changeActiveOrg) {
                const activeOrg = myOrgs[0]
                this.setState({ activeOrg, myOrgs, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                localStorage.setItem('activeOrg', activeOrg)
                localStorage.removeItem('orgFocus')
                localStorage.removeItem('studentAlias')
                // this.props.passOrgs(activeOrg)
                this.retrieveData()
              } else {
                this.setState({ myOrgs, myOrgObjects, showConfirmDelete: false, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
              }

            } else if (type === 'academies') {

              let changeActiveOrg = false
              if (this.state.academyCodes[index] === this.state.activeOrg) {
                changeActiveOrg = true
              }

              let academyCodes = this.state.academyCodes
              academyCodes.splice(index,1)

              const serverPostSuccess = true
              const serverSuccessMessage = 'Org successfully removed!'

              if (changeActiveOrg) {
                const activeOrg = academyCodes[0]
                this.setState({ activeOrg, academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
                localStorage.setItem('activeOrg', activeOrg)
                localStorage.removeItem('orgFocus')
                localStorage.removeItem('studentAlias')
                // this.props.passOrgs(activeOrg)

                // console.log('aadf', activeOrg)
              } else {
                this.setState({ academyCodes, serverPostSuccess, serverSuccessMessage, modalIsOpen: false })
              }
            }

          } else {
            console.error('there was an error saving the new active org');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Saving the active org did not work', error);
        });
      } else {
        this.setState({
          serverPostSuccess: false,
          serverErrorMessage: "You must have more than one workspace/community to be able to remove one.",
        })
      }
    }

    searchItems() {
      console.log('searchItems called')

      this.setState({ isAnimating: true, errorMessage: null, successMessage: null })

      const zipCode = this.state.zipCode

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/org/search-proximity', { params: { zipCode, resLimit: 50 } })
        .then((response) => {
          console.log('Org search proximity query attempted', response.data);

          if (response.data.success) {
            console.log('org search proximity query worked')

            const suggestedOrgs = response.data.organizations
            self.setState({ suggestedOrgs, isAnimating: false });

          } else {
            console.log('organization search proximity query did not work', response.data.message)
            self.setState({ isAnimating: false, filteredOrgs: self.state.orgs })
          }

        }).catch((error) => {
            console.log('Organization search proximity query did not work for some reason', error);
            self.setState({ isAnimating: false, filteredOrgs: self.state.orgs })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    renderSignUpFields() {
      console.log('renderSignUpFields called')

      let rows = []
      let spaceCounter = 0
      for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
        const value = this.state.orgSelected.signUpFieldsRequired[i - 1]
        const index = i - 1

        let showQuestion = true
        if (value.questionType !== 'Multiple Answer') {
          // console.log('in it: ', value.name, spaceCounter)
          if (value.rule) {
            if (value.rule.name === 'races' && this.state.races && this.state.races.includes(value.rule.value)) {
              spaceCounter = spaceCounter + 1
            } else {
              showQuestion = false
            }
          } else {
            spaceCounter = spaceCounter + 1
          }
        }

        rows.push(
          <div key={'renderSignUpFields' + index}>
            {(showQuestion) && (
              <div>
                <div className={(value.questionType === 'Multiple Answer') ? "full-width" : "container-left"}>
                  {this.renderSignUpField(value, index)}
                </div>
                {(value.questionType === 'Multiple Answer' || spaceCounter % 2 === 0) && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
                {(this.state.orgSelected.signUpFieldsRequired[i] && this.state.orgSelected.signUpFieldsRequired[i].questionType === 'Multiple Answer') && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
              </div>
            )}
          </div>
        )
      }

      return rows
    }

    renderSignUpField(value, index) {
      console.log('renderSignUpField called')

      return (
        <div key="signUpField">
          <label className="profile-label">{value.name}{(value.required) && <label className="error-color bold-text">*</label>}</label>
          {(value.questionType === 'Date') && (
            <input type="date" className="date-picker white-background" name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Short Answer') && (
            <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Multiple Choice') && (
            <select name={value.shorthand} className="dropdown" value={this.state[value.shorthand]} onChange={this.formChangeHandler}>
                {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
            </select>
          )}
          {(value.questionType === 'Multiple Answer') && (
            <div>
              {value.answerChoices.map((value2, optionIndex) =>
                <div key={value2 + optionIndex}>
                  <div className="top-margin-5 right-padding">
                    {(this.state[value.shorthand] && this.state[value.shorthand].includes(value2)) ? (
                      <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked2(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2 white-text">{value2}</p>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked2(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2">{value2}</p>
                          </div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      )
    }

    optionClicked2(index, optionIndex, value, value2) {
      console.log('optionClicked2 called', index, optionIndex, value, value2)

      let items = this.state[value.shorthand]
      if (items) {
        if (items.includes(value2)) {
          const index = items.indexOf(value2)
          items.splice(index, 1)
        } else {
          items.push(value2)
        }

      } else {
        items = [value2]
      }

      this.setState({ [value.shorthand]: items })

    }

    sendInvite(userType) {
      console.log('sendInvite called', userType)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      let invites = []
      let recipientType = 'Student'
      let inviteLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.activeOrg + "/career-seeker/join"
      if (userType === 'peer') {
        invites = this.state.peers
      } else if (userType === 'mentor') {
        invites = this.state.mentors
        recipientType = 'Mentor'
        inviteLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.activeOrg + "/mentor/join"
      }

      if (!invites || invites.length === 0) {
        this.setState({ isLoading: false, errorMessage: 'Please add at least one ' + userType })
      } else {

        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId
        let recipientEmails = []
        for (let i = 1; i <= invites.length; i++) {
          recipientEmails.push(invites[i - 1].email)
        }
        const recipients = invites
        // const recipientType = 'Student'
        const joined = false
        const active = false
        const orgCode = this.state.activeOrg
        const orgName = this.state.orgName
        const memberCount = null
        const profileLink = "/" + this.state.username + "/profile"

        Axios.post('/api/members/invite', {
          senderFirstName, senderLastName, senderEmail, recipientEmails, recipients, recipientType,
          joined, userType, active, orgCode, orgName, memberCount, inviteLink, profileLink })
        .then((response) => {

          if (response.data.success) {
            this.setState({ isLoading: false, successMessage: response.data.message })
          } else {
            this.setState({ isLoading: false, errorMessage: response.data.message })

          }
        }).catch((error) => {
            console.log('Invite did not work', error);
            this.setState({ isLoading: false, errorMessage: error.toString() })
        });
      }

    }

    render() {

      return (
        <div>
          <div className={(window.innerWidth <= 768) ? "full-width light-background padding-20" : "fixed-column-375 light-background full-height padding-30 position-fixed top-0 left-0"}>
            <div className={(window.innerWidth <= 768) ? "top-margin-20 full-width center-text" : "full-width center-text"}>
              <Link to={(window.location.pathname.includes('/employers')) ? "/employers/" + this.state.accountCode + "/orgs/" + this.state.activeOrg : "/app/orgs/" + this.state.activeOrg} className="background-button" target="_blank">
                {(window.location.pathname.includes('/employers')) ? (
                  <div>
                    <img src={(this.state.employerLogo) ? this.state.employerLogo : industryIconDark} alt="GC" className="image-full-80-fit center-horizontally"/>
                  </div>
                ) : (
                  <div>
                    <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className={(this.state.activeOrg === 'guidedcompass') ? "image-full-50-fit center-horizontally" : "image-full-80-fit center-horizontally"}/>
                  </div>
                )}
              </Link>
            </div>

            <div className={(window.innerWidth <= 768) ? "top-margin-20" : "top-margin"}>
              <p className="full-width center-text heading-text-3">Build your profile</p>
            </div>

            <div className={(window.innerWidth <= 768) ? "top-margin-20" : "top-margin-7-percent"}>
              <p className="description-text-2">Step {this.state.pageIndex} of {this.state.pages.length}</p>
              <div className="progress-bar-thin top-margin" >
                <div className="filler" style={{ width: Math.round((this.state.pageIndex / this.state.pages.length) * 100) + '%' }} />
              </div>
            </div>

            {(window.innerWidth > 768) && (
              <div>
                <div className="top-margin-40">
                  {this.state.pages.map((value, index) =>
                    <div key={index} className={(this.state.limitTo3) ? "top-margin-10-percent" : "top-margin-7-percent"}>
                      <div className="fixed-column-50">
                        <img src={(this.state.pageIndex === (index + 1) ? value.iconSelected : value.iconUnselected)} alt="GC" className="image-auto-25"/>
                      </div>
                      <div className="calc-column-offset-50">
                        <p className={(this.state.pageIndex === (index + 1)) ? "heading-text-5 bold-text cta-color" : "heading-text-5"}>{value.title}</p>
                      </div>
                      <div className="clear" />
                    </div>
                  )}
                </div>

                {(this.state.activeOrg !== 'guidedcompass' || window.location.pathname.includes('/employers')) && (
                  <div className="absolute-position pin-bottom bottom-padding-20">
                    <p className="description-text-4 description-text-color">Powered by</p>
                    <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/orgLogos/full-guided-compass-logo.png'} alt="GC" className="image-auto-30 top-margin-5"/>
                  </div>
                )}

              </div>
            )}
          </div>
          <div className={(window.innerWidth <= 768) ? "full-width white-background" : "full-width white-background left-padding-375"}>
            <div className="padding-ten-percent">
              {(this.state.pageIndex === 1) && (
                <div>
                  {(window.location.pathname.includes('/employers')) ? (
                    <div>
                      <p className="heading-text-2">Welcome to your {this.state.employerName} Portal, {this.state.cuFirstName}!</p>

                      <div className="top-margin-20">
                        <hr />

                        <div className="row-30">
                          <div className="container-left">
                            <div className="row-2-half-percent">
                              <div>
                                <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                  <img src={opportunitiesIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                                </div>
                                <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Improve Recruitment Marketing</p>
                                  <p className="description-text-2">Employers & staff can design benchmarks for each department, then engage scored / ranked. HR can host talent communities.</p>
                                  <p className="description-text-3 top-padding"><label className="bold-text">Metrics:</label> Time to Fill, Quality of Hire, Acceptance Rate, Retention Rate.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="row-3-half-percent">
                              <div>
                                <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                  <img src={careerMatchesIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                                </div>
                                <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Receive Screened Candidates</p>
                                  <p className="description-text-2">Career-Seekers can apply directly, or be referred by workforce training providers. In both cases, talent is pre-screened.</p>
                                  <p className="description-text-3 top-padding"><label className="bold-text">Metrics:</label> Quality of Hire, Acceptance Rate, Retention Rate.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="row-3-half-percent">
                              <div>
                                <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                  <img src={socialIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                                </div>
                                <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Improve DEI Metrics</p>
                                  <p className="description-text-2">93% of Guided Compass career-seekers are minorities and 96% come from low-income communities. You can also filter by diversity criteria.</p>
                                  <p className="description-text-3 top-padding"><label className="bold-text">Metrics:</label> Diverse Employee Retention, Diverse Employee Performance.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                          <div className="container-right">
                            <div className={(window.innerWidth <= 768) ? "horizontal-padding-8" : "horizontal-padding-8"}>

                              <img className="image-auto-380 top-margin-20 center-horizontally" alt="GC" src={gcFrontImage} />

                              <div className="spacer" />

                            </div>

                          </div>
                          <div className="clear" />

                        </div>

                      </div>

                    </div>
                  ) : (
                    <div>
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-2">Welcome to the {this.state.orgName} Community, {this.state.cuFirstName}!</p>
                        </div>
                        <div className="fixed-column-80">
                          <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.movePage(true)}>
                            <div className="float-right top-margin-3">
                              <img src={arrowIcon} alt="GC" className="image-auto-14" />
                            </div>
                            <div className="float-right right-padding">
                              <p className="cta-color bold-text description-text-1">Next</p>
                            </div>

                            <div className="clear" />
                          </button>
                        </div>
                        <div className="clear" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                      </div>

                      <div className="top-margin-20">
                        <hr />

                        <div className="row-30">
                          <div className="container-left">
                            <div className="row-2-half-percent">
                              <div>
                                <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                  <img src={socialIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                                </div>
                                <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Build Community</p>
                                  <p className="description-text-1">Join groups, attend events, crowdsource suggestions on goals/projects, and get referred for opportunities.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="row-3-half-percent">
                              <div>
                              <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                <img src={careerMatchesIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                              </div>
                              <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Prepare for Your Best Life</p>
                                  <p className="description-text-1">Explore career paths, set goals, collect feedback goal/project suggestions, and track progress.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="row-3-half-percent">
                              <div>
                                <div className={(window.innerWidth <= 768) ? "fixed-column-70 top-margin-5" : "fixed-column-80 top-margin-5"}>
                                  <img src={moneyIconBlue} alt="GC" className={(window.innerWidth <= 768) ? "image-50-fit" : "image-60-fit"} />
                                </div>
                                <div className={(window.innerWidth <= 768) ? "calc-column-offset-70" : "calc-column-offset-80"}>
                                  <p className="heading-text-4 row-5">Land Paid Opportunities</p>
                                  <p className="description-text-1">Import your continuously improved profile into opportunities, and get referred by advocates.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                          {(window.innerWidth > 768) && (
                            <div className="container-right">
                              <div className="horizontal-padding-8">

                                <img className={"image-auto-350 top-margin-20 center-horizontally"} alt="GC" src={gcFrontImage} />

                                <div className="spacer" />

                              </div>
                            </div>
                          )}
                          <div className="clear" />
                          {/*
                          <p className="top-margin-40">To help us best support you, please take a few minutes to tell us a bit about yourself. We’ll use this information to connect you to relevant opportunities. You can always update this information later.</p>*/}
                        </div>
                        {/*
                        <div className="row-30">
                          <p className="top-margin">Thank you for joining the {this.state.orgName} portal!</p>
                          <p className="top-margin-30">In addition to {(this.state.activeOrg === 'unite-la') ? "internships" : "work opportunities"}, you can use this portal to explore careers, RSVP to career events, get feedback on projects, participate in career trainings, and more!</p>
                          <p className="top-margin-30">To help us best support you, please take a few minutes to tell us a bit about yourself. We’ll use this information to connect you to relevant opportunities. You can always update this information later.</p>
                        </div>*/}
                      </div>

                      {(this.state.activeOrg === 'guidedcompass' || this.state.showMyCommunities) && (
                        <div className="full-width">
                          <hr />

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-10">
                            <div className="calc-column-offset-90">
                              <p className="heading-text-4 row-5">Are you part of the community of any of our workforce partners?</p>
                              <p className="description-text-2 description-text-color">Communities are dedicated workspaces for schools, workforce, staffing, and employer organizations.</p>
                            </div>
                            <div className="fixed-column-90">
                              {/*<button className="background-button cta-color full-width underline-text offset-underline bold-text row-5" onClick={() => this.setState({ modalIsOpen: true, showAddWorkspaces: true })}>Join Their Community</button>*/}
                              <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showAddWorkspaces: true })}>Browse</button>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label full-width">Search "Public" Communities by Zip Code</label>
                              <div className="calc-column-offset-90 right-padding">
                                <input className="number-field" type="number" placeholder={"(e.g., 90210)"} name="zipCode" value={this.state.zipCode} min="1" max="99999" onChange={this.formChangeHandler} />
                              </div>
                              <div className="fixed-column-90">
                                <button className="btn btn-squarish pin-right" onClick={() => this.searchItems('zip code')}>Search</button>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.isAnimating) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <div className="super-spacer" />

                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Loading...</p>

                              </div>
                            </div>
                          ) : (
                            <div>
                              {(this.state.suggestedOrgs && this.state.suggestedOrgs.length > 0) && (
                                <div className="cta-border padding-20 top-margin-20 bottom-margin-20">
                                  <p className="description-text-2 row-10 italicize-text">Suggested Communities</p>

                                  {this.state.suggestedOrgs.map((value, optionIndex) =>
                                    <div key={value._id} className="left-text bottom-margin-5 full-width">
                                      <button className="background-button full-width row-5 left-text" onClick={() => this.suggestedItemClicked(value, 'org')}>
                                        <div className="full-width">
                                          <div className="fixed-column-40">
                                            <div className="mini-spacer" />
                                            <img src={(value.webLogoURIColor) ? value.webLogoURIColor : industryIconDark} alt="GC" className="image-30-fit" />
                                          </div>
                                          <div className="calc-column-offset-40">
                                            <p className="cta-color">{value.orgName}</p>
                                            {(value.orgRegion) && (
                                              <p className="description-text-3 description-text-color">{value.orgRegion}</p>
                                            )}
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.myOrgObjects && this.state.myOrgObjects.length > 0) && (
                            <div className="standard-border padding-20">

                              <label className="profile-label">Your Communities</label>

                              {this.state.myOrgObjects.map((item, index) =>
                                <div key={item}>
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <button className="background-button calc-column-offset-50 left-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                      <div className="fixed-column-50">
                                        <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="image-40-fit" />
                                      </div>
                                      <div className="calc-column-offset-50 left-padding-5">
                                        <p>{item.orgName}</p>
                                        {(item.orgRegion) && (
                                          <p className="description-text-3 top-padding-5">{item.orgRegion}</p>
                                        )}
                                        <p className="description-text-3 description-text-color top-padding-5">{item.orgDescription}</p>
                                      </div>
                                    </button>
                                    {(this.state.activeOrg === item.orgCode) ? (
                                      <div className="fixed-column-20">
                                        <span className="float-right">
                                          <div className="spacer"/>
                                          <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                            <img src={checkmarkIcon} alt="GC" className="image-auto-15 pin-right"/>
                                          </button>
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="fixed-column-20 height-30" />
                                    )}
                                    <div className="fixed-column-30 left-text">
                                      <span className="float-right">
                                        <div className="spacer"/>
                                        <button className="background-button full-width right-text" onClick={() => this.setState({ modalIsOpen: true, showOrgDetails: true, selectedOrg: item, selectedIndex: index, selectedType: 'all' })}>
                                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                        </button>
                                      </span>
                                    </div>
                                    <div className="clear"/>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="spacer"/><div className="half-spacer"/>
                        </div>
                      )}

                      {(this.state.requireReferrer) && (
                        <div className="full-width top-margin-20">
                          <hr />

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-10">
                            <p className="heading-text-3">What brought you here?</p>
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Name of person who recommended you (N/A if no one)<label className="error-color bold-text"> *</label></label>
                              <input className="text-field" type="text" placeholder={this.state.referrerName} name="referrerName" value={this.state.referrerName} onChange={this.formChangeHandler} />
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Email of person who recommended you (N/A if no one)<label className="error-color bold-text"> *</label></label>
                              <input className="text-field" type="text" placeholder={this.state.referrerEmail} name="referrerEmail" value={this.state.referrerEmail} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Name of organization that recommended you (N/A if no org)<label className="error-color bold-text"> *</label></label>
                              <input className="text-field" type="text" placeholder={this.state.referrerOrg} name="referrerOrg" value={this.state.referrerOrg} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="full-width top-margin-50">
                    <hr />

                    <div className="full-width top-margin-50">
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding full-width right-text">{this.state.successMessage}</p>}

                      {(this.state.pageIndex > 1) ? (
                        <div className="container-left-static left-text">
                          <button className="btn btn-primary medium-background standard-border" onClick={() => this.setState({ pageIndex: this.state.pageIndex - 1 })}>Back</button>
                        </div>
                      ) : (
                        <div className="container-left-static left-text">
                          <div className="width-40 height-40" />
                        </div>
                      )}

                      <div className="container-right-static right-text">
                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.movePage(true)}>Next step</button>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                </div>
              )}

              {(this.state.pageIndex === 2) && (
                <div>
                  {(window.location.pathname.includes('/employers')) ? (
                    <div>
                      <p className="heading-text-2">{this.state.pages[this.state.pageIndex - 1].title}</p>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <div className="row-10">
                        <div className="relative-column-30">
                          <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true })}>
                            <img src={exampleInternMatch} alt="GC" className="image-full-auto" />
                          </button>
                          <p className="description-text-2">Click the image to enlarge</p>
                        </div>
                        <div className="relative-column-70 left-padding-40">
                          <p className="heading-text-">For each department, define the "100% candidate profile" for entry-level roles by creating <Link to="/benefits/transparent-pathways" target="_blank" className="bold-text">benchmarks</Link>.</p>
                          <p className="heading-text- top-padding-30">Our algorithm scores against your benchmarks, and our workforce and education partners will use your benchmarks to train and refer candidates for years. Independent career-seekers can prepare too! You can even use these for internal hiring!</p>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="top-margin-30">
                        <p className="heading-text-3">Confirm {this.state.employerName} Departments</p>
                        <p className="top-margin-5 description-text-color description-text-1">Examples of departments include design, sales, marketing, engineering, operations, and finance.</p>

                        <div className="spacer" /><div className="half-spacer" />

                        <div className="row-10">
                          <div className="container-left">
                            <div className="calc-column-offset-100 right-padding">
                              <input type="text" className="text-field" placeholder="Department name..." name="employerDepartment" value={this.state.employerDepartment} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="fixed-column-100">
                              <button className="btn btn-squarish" onClick={() => this.addItem('department',this.state.employerDepartment)}>Add</button>
                            </div>
                            <div className="clear" />

                          </div>
                          <div className="clear" />

                          {(this.state.suggestedDepartments && this.state.suggestedDepartments.length > 0) && (
                            <div className="card top-margin">
                              <p className="description-text-2 row-10 italicize-text">Suggested Departments</p>

                              {this.state.suggestedDepartments.map((value, optionIndex) =>
                                <div key={value._id} className="left-text bottom-margin-5 full-width">
                                  <button className="background-button full-width row-5 left-text" onClick={() => this.suggestedItemClicked(value, 'department')}>
                                    <div className="full-width">
                                      <div className="fixed-column-40">
                                        <div className="mini-spacer" />
                                        <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                      </div>
                                      <div className="calc-column-offset-40">
                                        <p className="cta-color">{value}</p>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                          {this.renderTags('department', this.state.departments)}
                          <div className="clear" />

                        </div>
                      </div>

                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Create {this.state.employerName} Benchmarks (Optional)</p>
                            <p className="top-margin-5 description-text-color description-text-1">{this.state.employerName} should have a benchmark for each department.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreateBenchmark: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a benchmark" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />

                        <div>
                          <SubBenchmarks accountCode={this.state.accountCode} history={this.props.navigate} />
                        </div>
                      </div>

                      <div className="full-width top-margin-50">
                        <hr />

                        <div className="full-width top-margin-50">
                          {(this.state.pageIndex > 1) ? (
                            <div className="container-left-static left-text">
                              <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                            </div>
                          ) : (
                            <div className="container-left-static left-text">
                              <div className="width-40 height-40" />
                            </div>
                          )}

                          <div className="container-right-static right-text">
                            <button className="btn btn-primary" onClick={() => this.movePage(true)}>Next step</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>

                    </div>
                  ) : (
                    <div>
                      {/*
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-2">Who you are</p>
                        </div>
                        <div className="fixed-column-80">
                          <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.props.handleSubmit()}>
                            <div className="float-right top-margin-3">
                              <img src={arrowIcon} alt="GC" className="image-auto-14" />
                            </div>
                            <div className="float-right right-padding">
                              <p className="cta-color bold-text description-text-1">Next</p>
                            </div>

                            <div className="clear" />
                          </button>
                        </div>
                        <div className="clear" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                      </div>*/}

                      <div className="top-margin-negative-50">
                        <SubEditProfileDetails activeOrg={this.state.activeOrg} history={this.props.navigate} location={this.props.location} passedType="Basic" movePage={this.movePage} />
                      </div>
                    </div>
                  )}

                </div>
              )}

              {(this.state.pageIndex === 3) && (
                <div>
                  {(window.location.pathname.includes('/employers')) ? (
                    <div>
                      <p className="heading-text-2">{this.state.pages[this.state.pageIndex - 1].title}</p>

                      <div className="top-margin-50">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Social Posts</p>
                            <p className="top-margin-5 description-text-color description-text-1">Social media posts can be viewed by your followers and training providers.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showSocialPost: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a social post" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          <SubRenderPosts posts={this.state.socialPosts} passedGroupPost={null} accountCode={this.state.accountCode} employerLogo={this.state.employerLogo} employerName={this.state.employerName} jobTitle={this.state.jobTitle} />
                        </div>

                      </div>


                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Events</p>
                            <p className="top-margin-5 description-text-color description-text-1">Job shadows, webinars, talks, workshops, and other events that {this.state.employerName} hosts.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreateEvent: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create an event" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          <SubPostings history={this.props.navigate} accountCode={this.state.accountCode} activeOrg={this.state.activeOrg} postTypes={['Event']} path={'/employers/' + this.state.accountCode + '/postings'}/>
                        </div>
                      </div>

                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Projects</p>
                            <p className="top-margin-5 description-text-color description-text-1">Assignments, case studies, and competitions where career-seekers try to solve a {this.state.employerName} problem.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreateProject: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a project" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          <SubPostings history={this.props.navigate} accountCode={this.state.accountCode} activeOrg={this.state.activeOrg} postTypes={['Assignment','Problem','Challenge']} path={'/employers/' + this.state.accountCode + '/postings'}/>
                        </div>
                      </div>

                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Talent Pipelines</p>
                            <p className="top-margin-5 description-text-color description-text-1">Share messages, courses, and opportunities with career-sekeers that score well against {this.state.employerName} benchmark(s).</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreatePipeline: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a talent pipeline" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-margin-20">
                          <SubPipelines source="Employer" accountCode={this.state.accountCode} renderSideNav={false} history={this.props.navigate} />
                        </div>
                      </div>

                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Talent Communities</p>
                            <p className="top-margin-5 description-text-color description-text-1">Social groups, typically dedicated to a department, where {this.state.employerName} can engage with career-seekers.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreateCommunity: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a talent community" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.communities && this.state.communities.length > 0) ? (
                          <div>
                            <div className="row-20">
                              {this.state.communities.map((item, index) =>
                                <div key={"kpis" + index}>
                                  <div className="relative-column-33">
                                    <Link to={"/employers/" + this.state.accountCode + "/groups/" + item._id} className="standard-color">
                                      <div className="width-80-percent center-item">
                                        <img src={(item.pictureURL) ? item.pictureURL : socialIconDark} alt="GC" className="image-half-auto center-horizontally" />
                                      </div>
                                      <p className="heading-text-6 center-text full-width top-padding">{item.name}</p>
                                      {(item.category) && (
                                        <p className="description-text-2 center-text full-width">{item.category}</p>
                                      )}
                                      {(item.members && item.members.length > 0) && (
                                        <p className="description-text-2 center-text full-width">{item.members.length} Members</p>
                                      )}

                                      <div className="spacer"/>
                                    </Link>
                                  </div>
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          </div>
                        ) : (
                          <p className="description-text-color description-text-2 row-20">No talent communities yet. Create <a className="cta-color cta-underline" onClick={() => this.setState({ modalIsOpen: true, showAddGroup: true })}>here</a>.</p>
                        )}

                      </div>
                      <ReactTooltip id="tooltip-placeholder-id" />

                      <div className="full-width top-margin-50">
                        <hr />

                        <div className="full-width top-margin-50">
                          {(this.state.pageIndex > 1) ? (
                            <div className="container-left-static left-text">
                              <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                            </div>
                          ) : (
                            <div className="container-left-static left-text">
                              <div className="width-40 height-40" />
                            </div>
                          )}

                          <div className="container-right-static right-text">
                            <button className="btn btn-primary" onClick={() => this.movePage(true)}>Next step</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>

                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-2">What you want</p>
                        </div>
                        <div className="fixed-column-80">
                          <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.movePage(true)}>
                            <div className="float-right top-margin-3">
                              <img src={arrowIcon} alt="GC" className="image-auto-14" />
                            </div>
                            <div className="float-right right-padding">
                              <p className="cta-color bold-text description-text-1">{(this.state.limitTo3) ? "Submit" : "Next"}</p>
                            </div>

                            <div className="clear" />
                          </button>
                        </div>
                        <div className="clear" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                      </div>

                      <div className="top-margin-50">
                        <div className="row-10">
                          <div>
                            <div className="calc-column-offset-40">
                              <p className="heading-text-3">Career Goals (Optional)</p>
                            </div>
                            <div className="fixed-column-40">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showGoal: true })}>
                                <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create a goal" />
                              </button>
                            </div>
                            <div className="clear" />
                          </div>

                          <p>Set goals to get resources and hold yourself accountable.</p>
                          <SubLogs logType="Goal" history={this.props.navigate} passedGoal={this.state.passedGoal} modalView={true}/>
                        </div>

                        {(!this.state.hideAccountabilityGroups) && (
                          <div>
                            <div className="row-20">
                              <hr />
                            </div>

                            <div className="row-15">
                              <div>
                                <div className="calc-column-offset-80">
                                  <p className="heading-text-3">Accountability Groups (Optional)</p>
                                </div>
                                <div className="fixed-column-40">
                                  <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showEditGroup: true })}>
                                    <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create an accountability group"/>
                                  </button>
                                </div>
                                <div className="fixed-column-40">
                                  <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showSearchGroups: true })}>
                                    <img src={searchIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Join an accountability group" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>
                              <p>Would you like to create or join accountability groups to help you accomplish your goals?</p>
                            </div>

                            <SubRenderMyGroups history={this.props.navigate} groups={this.state.groupsJoined} passedCategory="Accountability" />
                          </div>
                        )}

                        {(this.state.activeOrg === 'unite-la') && (
                          <div>
                            <div className="row-20">
                              <hr />
                            </div>

                            <div className="row-15">

                              <div className="row-10">
                                <p className="heading-text-3">Partner Offerings</p>
                              </div>

                              <div className="top-padding-20">
                                <p className="heading-text-6 bold-text">Workforce Ready</p>
                              </div>

                              <div className="row-10">
                                <p>Cornerstone OnDemand has created WorkforceReady Modules to help you develop the soft skills necessary for the future of work. These are free lessons that must be completed before UNITE-LA staff refers you to internship / work opportunities.</p>
                              </div>

                              <div className="row-10">
                                <div className="fixed-column-30">
                                  <button className="background-button" onClick={() => this.optionClicked(null, null)}>
                                    <img src={this.state.autoEnrollInProgram ? checkboxChecked : checkboxEmpty } alt="GC" className="image-auto-20" />
                                  </button>
                                </div>
                                <div className="calc-column-offset-30">
                                  <p>Click the box to enroll/un-enroll in the next WorkforceReady Cohort</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            {(this.state.showWeeklyOpportunitySignUp && this.state.activeOrg === 'unite-la') && (
                              <div>
                                <div className="row-20">
                                  <hr />
                                </div>

                                <div className="row-15">
                                  <div className="row-10">
                                    <p className="heading-text-3">Subscribe to Weekly {this.state.orgName} Opportunity Notifications</p>
                                  </div>

                                  <SubNotificationSubscribe orgCode={this.state.activeOrg} subscriptionType="email" />
                                </div>

                                {/*
                                <div className="row-15">

                                  <div className="row-10">
                                    <p className="heading-text-3">Partner Offerings</p>
                                  </div>

                                  <div className="top-padding-20">
                                    <p className="heading-text-6 bold-text">Workforce Ready</p>
                                  </div>

                                  <div className="row-10">
                                    <p>Cornerstone OnDemand has created WorkforceReady Modules to help you develop the soft skills necessary for the future of work. These are free lessons that must be completed before UNITE-LA staff refers you to internship / work opportunities.</p>
                                  </div>

                                  <div className="row-10">
                                    <div className="fixed-column-30">
                                      <button className="background-button" onClick={() => this.optionClicked(null, null)}>
                                        <img src={this.state.autoEnrollInProgram ? checkboxChecked : checkboxEmpty } alt="GC" className="image-auto-20" />
                                      </button>
                                    </div>
                                    <div className="calc-column-offset-30">
                                      <p>Click the box to enroll/un-enroll in the next WorkforceReady Cohort</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>*/}
                              </div>
                            )}
                          </div>
                        )}

                        {/*
                        <div className="row-10">
                          <p className="heading-text-3">Partner Workspaces</p>
                          <p>Would you like to join training partner workspaces to help you accomplish your goals?</p>
                        </div>*/}
                      </div>

                      <div className="full-width top-margin-50">
                        <hr />

                        <div className="full-width top-margin-50">
                          {(this.state.pageIndex > 1) ? (
                            <div className="container-left-static left-text">
                              <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                            </div>
                          ) : (
                            <div className="container-left-static left-text">
                              <div className="width-40 height-40" />
                            </div>
                          )}

                          <div className="container-right-static right-text">
                            <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.movePage(true)}>{(this.state.limitTo3) ? "Submit & Get Started" : "Next step"}</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                      <ReactTooltip id="tooltip-placeholder-id" />
                    </div>
                  )}
                </div>
              )}

              {(this.state.pageIndex === 4) && (
                <div>
                  {(window.location.pathname.includes('/employers')) ? (
                    <div>
                      <p className="heading-text-2">{this.state.pages[this.state.pageIndex - 1].title}</p>

                      <div className="top-margin-50">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Post Work Opportunities</p>
                            <p className="top-margin-5 description-text-color description-text-1">Internship, apprenticeship, part-time, and full-time roles are all game. Use an Applicant Tracking System (ATS) that we can integrate with to make your life easier? Add it <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showATS: true })}>here</button>.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showCreateWork: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Post a work opportunity" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-margin">
                          <SubPostings history={this.props.navigate} accountCode={this.state.accountCode} activeOrg={this.state.activeOrg} postTypes={['Work']} path={'/employers/' + this.state.accountCode + '/postings'}/>
                        </div>

                      </div>

                      <div className="top-margin-30">
                        <div>
                          <div className="calc-column-offset-40">
                            <p className="heading-text-3">Training Providers</p>
                            <p className="top-margin-5 description-text-color description-text-1">Add organizations that train and refer you candidates.</p>
                          </div>
                          <div className="fixed-column-40">
                            <div className="spacer" />
                            <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showAddWorkspaces: true })}>
                              <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add training providers" />
                            </button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.trainingProviders && this.state.trainingProviders.length > 0) ? (
                          <div>
                            <div className="row-20">
                              {this.state.trainingProviders.map((item, index) =>
                                <div key={"kpis" + index}>
                                  {(item.orgName !== 'Sandbox') && (
                                    <div className="relative-column-33">
                                      <Link to={"/employers/" + this.state.accountCode + "/orgs/" + item.orgCode} className="standard-color">
                                        <div className="width-80-percent center-item">
                                          <img src={(item.webLogoURIColor) ? item.webLogoURIColor : industryIconDark} alt="GC" className="image-half-auto center-horizontally" />
                                        </div>
                                        <p className="heading-text-6 center-text full-width top-padding">{item.orgName}</p>
                                        <p className="description-text-3 full-width center-text top-padding-5">{item.orgDescription}</p>

                                        <div className="spacer"/>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          </div>
                        ) : (
                          <p className="description-text-color description-text-2 row-20">No training providers yet. Create <Link to={"/employers/" + this.state.accountCode + "/add-orgs"} target="_blank">here</Link>.</p>
                        )}
                      </div>

                      <ReactTooltip id="tooltip-placeholder-id" />

                      <div className="full-width top-margin-50">
                        <hr />

                        <div className="full-width top-margin-50">
                          {(this.state.pageIndex > 1) ? (
                            <div className="container-left-static left-text">
                              <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                            </div>
                          ) : (
                            <div className="container-left-static left-text">
                              <div className="width-40 height-40" />
                            </div>
                          )}

                          <div className="container-right-static right-text">
                            <button className="btn btn-primary" onClick={() => this.movePage(true)}>Save & Go to Dashboard</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-2">What you're great at</p>
                        </div>
                        <div className="fixed-column-80">
                          <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.movePage(true)}>
                            <div className="float-right top-margin-3">
                              <img src={arrowIcon} alt="GC" className="image-auto-14" />
                            </div>
                            <div className="float-right right-padding">
                              <p className="cta-color bold-text description-text-1">Next</p>
                            </div>

                            <div className="clear" />
                          </button>
                        </div>
                        <div className="clear" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                      </div>

                      <div className="top-margin-30">
                        <p>We're here to help. If you need help defining what you're great at, select a pathway for recommendations. These recommendations come from <Link to="/help/what-are-benchmarks" target="_blank">Guided Compass Benchmarks</Link>, which are the ideal candidates defined by programs and employers for career pathways and opportunities. Benchmarks help us recommend opportunities to you and help us help you build a career development roadmap based on your goals.</p>
                      </div>

                      <div className="top-margin-30">
                        <p className="heading-text-3">Skill Self-Assessment (Optional)</p>
                        <p className="top-margin-5">Add some of the skills you're best at.</p>
                        <SubTakeAssessment history={this.props.navigate} type="skills" assessmentTitle="Skill Self-Assessment" assessments={this.state.assessments} index={3} assessment={this.state.assessment} resultsData={[null, null, null, this.state.skillsData, null]} assessmentDescription={this.state.assessmentDescription} />
                      </div>

                      <div className="row-20">
                        <hr />
                      </div>

                      <div className="row-10">
                        <p className="heading-text-3">Request an Endorsement (Optional)</p>

                        <SubRequestEndorsements enableRequestEndorsement="" />
                      </div>

                      <div className="full-width top-margin-50">
                        <hr />

                        <div className="full-width top-margin-50">
                          {(this.state.pageIndex > 1) ? (
                            <div className="container-left-static left-text">
                              <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                            </div>
                          ) : (
                            <div className="container-left-static left-text">
                              <div className="width-40 height-40" />
                            </div>
                          )}

                          <div className="container-right-static right-text">
                            <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.movePage(true)}>Next step</button>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              )}

              {(this.state.pageIndex === 5) && (
                <div>
                  <div>
                    <div className="calc-column-offset-80">
                      <p className="heading-text-2">Who can see your profile</p>
                    </div>
                    <div className="fixed-column-80">
                      <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.movePage(true)}>
                        <div className="float-right top-margin-3">
                          <img src={arrowIcon} alt="GC" className="image-auto-14" />
                        </div>
                        <div className="float-right right-padding">
                          <p className="cta-color bold-text description-text-1">{(this.state.showModule) ? "Next" : "Submit"}</p>
                        </div>

                        <div className="clear" />
                      </button>
                    </div>
                    <div className="clear" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                  </div>

                  <SubEditProfileDetails activeOrg={this.state.activeOrg} history={this.props.navigate} location={this.props.location} passedType="Public" movePage={this.movePage} opportunityId={this.props.opportunityId}/>
                  {/*
                  <div className="full-width top-margin-50">
                    <hr />

                    <div className="full-width top-margin-50">
                      {(this.state.pageIndex > 1) ? (
                        <div className="container-left-static left-text">
                          <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                        </div>
                      ) : (
                        <div className="container-left-static left-text">
                          <div className="width-40 height-40" />
                        </div>
                      )}

                      <div className="container-right-static right-text">
                        <button className="btn btn-primary" onClick={() => this.movePage(true)}>Submit</button>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>*/}
                </div>
              )}

              {(this.state.showModule && this.state.pageIndex === 6) && (
                <div>
                  <div>
                    <div className="calc-column-offset-80">
                      <p className="heading-text-2">Who you know</p>
                    </div>
                    <div className="fixed-column-80">
                      <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.movePage(true)}>
                        <div className="float-right top-margin-3">
                          <img src={arrowIcon} alt="GC" className="image-auto-14" />
                        </div>
                        <div className="float-right right-padding">
                          <p className="cta-color bold-text description-text-1">Submit</p>
                        </div>

                        <div className="clear" />
                      </button>
                    </div>
                    <div className="clear" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color bottom-padding full-width right-text">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color bottom-padding right-text">{this.state.successMessage}</p>}
                  </div>

                  <div className="top-margin-30">
                    <p>Invite mentors and peers who can view your goals, progress, and profile. Preview your public profile <Link to={"/" + this.state.username + "/profile"} target="_blank">here</Link>.</p>
                  </div>

                  <div className="top-margin-30">
                    <div>
                      <div className="float-left">
                        <p className="heading-text-3">Invite Mentors</p>
                      </div>
                      <div className="float-left left-padding top-margin-3">
                        {/*
                        <button className="background-button" onClick={() => this.addItem('mentor')}>
                          <div className="padding-5 cta-border circle-radius">
                            <img className="image-auto-12" src={addIconBlue} />
                          </div>
                        </button>*/}
                      </div>
                      <div className="clear" />
                    </div>

                    <p className="top-margin-5 description-text-1">Advisors, endorsers, and referrers that can help you advance in your career.</p>

                    <div className="top-margin-15">
                      <div className="calc-column-offset-80">
                        <div className="container-left">
                          <input className="text-field" type="text" placeholder={'jondoe@gmail.com'} name={'mentorEmail'} value={this.state.mentorEmail} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <div className="container-left">
                            <input className="text-field" type="text" placeholder={'Jon'} name={'mentorFirstName'} value={this.state.mentorFirstName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="container-right">
                            <input className="text-field" type="text" placeholder={'Doe'} name={'mentorLastName'} value={this.state.mentorLastName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="fixed-column-80">
                        <button className="btn btn-squarish float-right" onClick={() => this.addItem('mentor')}>Add</button>
                        <div className="clear" />
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.mentorErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.mentorErrorMessage}</p>}

                    {(this.state.mentors && this.state.mentors.length > 0) && (
                      <div className="top-margin-15">
                        {this.state.mentors.map((value, index) =>
                          <div key={value} className="float-left">
                            <div className="top-margin">
                              <div className="fixed-column-30 relative-position z-index-1">
                                <button className="background-button top-margin-7" onClick={() => this.removeItem('mentor',index)}>
                                  <div className="standard-border circle-radius white-background">
                                    <img className="image-auto-20" src={closeIconDark} alt="GC" />
                                  </div>
                                </button>
                              </div>
                              <div className="float-left tag-container-basic primary-background white-text left-margin-negative-18 right-margin">
                                <p className="description-text-1">{value.firstName} {value.lastName} ({value.email}){(value.status) && " [" + value.status + "]"}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />

                        <div className="row-20">
                          <button className="btn btn-squarish-white" onClick={() => this.sendInvite('mentor')}>
                            <div className="float-left right-margin top-margin-3">
                              <img className="image-auto-15" alt="GC" src={sendIconBlue} />
                            </div>
                            <div className="float-left">
                              Send
                            </div>
                            <div className="clear" />
                          </button>
                        </div>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                        <hr />
                      </div>
                    )}
                  </div>

                  <div className="top-margin-30">
                    <div>
                      <div className="float-left">
                        <p className="heading-text-3">Invite Peers</p>
                      </div>
                      <div className="float-left left-padding top-margin-3">
                        {/*
                        <button className="background-button" onClick={() => this.addItem('peer')}>
                          <div className="padding-5 cta-border circle-radius">
                            <img className="image-auto-12" src={addIconBlue} />
                          </div>
                        </button>*/}
                      </div>
                      <div className="clear" />
                    </div>
                    <p className="top-margin-5 description-text-1">Friends, accountability buddies, and cheerleaders.</p>

                    <div className="top-margin-15">
                      <div className="calc-column-offset-80">
                        <div className="container-left">
                          <input className="text-field" type="text" placeholder={'jondoe@gmail.com'} name={'peerEmail'} value={this.state.peerEmail} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <div className="container-left">
                            <input className="text-field" type="text" placeholder={'Jon'} name={'peerFirstName'} value={this.state.peerFirstName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="container-right">
                            <input className="text-field" type="text" placeholder={'Doe'} name={'peerLastName'} value={this.state.peerLastName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="fixed-column-80">
                        <button className="btn btn-squarish float-right" onClick={() => this.addItem('peer')}>Add</button>
                        <div className="clear" />
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.peerErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.peerErrorMessage}</p>}

                    {(this.state.peers && this.state.peers.length > 0) && (
                      <div className="top-margin-15">
                        {this.state.peers.map((value, index) =>
                          <div key={value} className="float-left">
                            <div className="top-margin">
                              <div className="fixed-column-30 relative-position z-index-1">
                                <button className="background-button top-margin-7" onClick={() => this.removeItem('peer',index)}>
                                  <div className="standard-border circle-radius white-background">
                                    <img className="image-auto-20" src={closeIconDark} alt="GC" />
                                  </div>
                                </button>
                              </div>
                              <div className="float-left tag-container-basic primary-background white-text left-margin-negative-18 right-margin">
                                <p className="description-text-1">{value.firstName} {value.lastName} ({value.email}){(value.status) && " [" + value.status + "]"}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />

                        <div className="row-20">
                          <button className="btn btn-squarish-white" onClick={() => this.sendInvite('peer')}>
                            <div className="float-left right-margin top-margin-3">
                              <img className="image-auto-15" alt="GC" src={sendIconBlue} />
                            </div>
                            <div className="float-left">
                              Send
                            </div>
                            <div className="clear" />
                          </button>
                        </div>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                        <hr />
                      </div>
                    )}
                  </div>

                  <div className="full-width top-margin-50">
                    <hr />

                    <div className="full-width top-margin-50">
                      {(this.state.pageIndex > 1) ? (
                        <div className="container-left-static left-text">
                          <button className="btn btn-primary medium-background standard-border" onClick={() => this.movePage(false)}>Back</button>
                        </div>
                      ) : (
                        <div className="container-left-static left-text">
                          <div className="width-40 height-40" />
                        </div>
                      )}

                      <div className="container-right-static right-text">
                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.movePage(true)}>Submit & Get Started</button>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="clear" />

          {(this.state.showGoal) && (
            <div>
              <SubEditLog modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} history={this.props.navigate} editExisting={this.state.editExisting} log={this.state.log} logs={this.state.logs} passedLogType="Goal" selectedAdvisor={this.state.selectedAdvisor} logId={this.state.logId} passGoal={this.passGoal} modalView={true}/>
            </div>
          )}

          {(this.state.showEditGroup || this.state.showSearchGroups || this.state.showAddWorkspaces || this.state.enlargeImage || this.state.showCreateBenchmark || this.state.showATS || this.state.showSocialPost || this.state.showCreateEvent || this.state.showCreateProject || this.state.showCreatePipeline || this.state.showCreateCommunity || this.state.showCreateWork || this.state.showOrgDetails || this.state.showSignUpFields) && (
            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >
              <div key="showEditGroup" className="full-width padding-20">
                {(this.state.showEditGroup) && (
                  <SubEditGroup selectedGroup={this.state.selectedGroup} history={this.props.navigate} closeModal={this.closeModal} passGroup={this.passGroup}/>
                )}
                {(this.state.showSearchGroups) && (
                  <div>
                    <SubGroups history={this.props.navigate} category="Accountability" />
                  </div>
                )}

                {(this.state.showAddWorkspaces) && (
                  <SubAddWorkspaces selectedGroup={null} history={this.props.navigate} closeModal={this.closeModal}
                    accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                    employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                    pathway={this.state.pathway} passOrgs={this.passOrgs}
                  />
                )}

                {(this.state.enlargeImage) && (
                  <div>
                    <img src={exampleInternMatch} className="image-full-auto" alt="GC" />
                    <div className="row-10 center-text">
                      <button className="btn btn-secondary" onClick={() => this.closeModal()}>
                        Close View
                      </button>
                    </div>
                  </div>
                )}
                {(this.state.showCreateBenchmark) && (
                  <SubEditBenchmark history={this.props.navigate} passData={this.passData} />
                )}
                {(this.state.showSocialPost) && (
                  <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost} posts={this.state.socialPosts} passPosts={this.passPosts} closeModal={this.closeModal} pictureURL={this.state.employerLogo} accountCode={this.state.accountCode} jobTitle={this.state.jobTitle} />
                )}
                {(this.state.showCreateEvent) && (
                  <SubEditPosting postType="Event" roleName="Employer" path={window.location.pathname}/>
                )}
                {(this.state.showCreateProject) && (
                  <SubEditPosting postType="Problem" roleName="Employer" path={window.location.pathname}/>
                )}
                {(this.state.showCreateWork) && (
                  <SubEditPosting postType="Work" roleName="Employer" path={window.location.pathname}/>
                )}
                {(this.state.showCreatePipeline) && (
                  <SubEditPipeline selectedPipeline={null} history={this.props.navigate} closeModal={this.closeModal}
                    accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                    employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                    pathway={this.state.pathway} departmentOptions={this.state.departments}
                  />
                )}
                {(this.state.showCreateCommunity) && (
                  <SubEditGroup selectedGroup={null} history={this.props.navigate} closeModal={this.closeModal}
                    accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                    employerName={this.state.employerName} jobFunction={'Marketing'}
                    pathway={this.state.pathway}
                  />
                )}

                {(this.state.showATS) && (
                  <div>
                    <p className="heading-text-4">Please indicate the primary Applicant Tracking System (ATS) that {this.state.employerName} uses</p>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <div className="row-10">
                      <select name="ats" value={this.state.ats} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.atsOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    <div className="row-10">
                      <button className="btn btn-primary" onClick={() => this.saveItem()}>Save Changes</button>
                      <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
                    </div>

                  </div>
                )}

                {(this.state.showOrgDetails) && (
                  <div className="center-text">
                    {(this.state.selectedOrg.webLogoURI) ? (
                      <div className="full-width center-text bottom-margin-20">
                        <div className="fixed-column-30 height-30">
                          <p> </p>
                        </div>
                        <div className="calc-column-offset-60">
                          <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.selectedOrg.webLogoURIColor} />
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="full-width center-text">
                        <div className="fixed-column-30 height-30">
                          <p> </p>
                        </div>
                        <div className="calc-column-offset-60 height-30">
                          <p> </p>
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20 pin-right" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>
                    )}

                    <p className="heading-text-2">{this.state.selectedOrg.orgName}</p>
                    <a href={this.state.selectedOrg.orgURL} className="full-width center-text top-padding">{this.state.selectedOrg.orgURL}</a>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p className="description-text-1">{this.state.selectedOrg.orgDescription}</p>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {(this.state.showConfirmDelete) ? (
                      <div className="full-width center-text">
                        <hr />
                        <div className="spacer" /><div className="spacer" />
                        <p className="heading-text-5">Are you sure you want to remove the {this.state.selectedOrg.orgName} community?</p>

                        <p className="description-text-2 row-20">Removing the community means that you will no longer receive notifications and invited to opportunities specific to this community. If you continue to receive unwanted notifications, please email help@guidedcompass.com.</p>

                        <div className="spacer" />

                        <div className="display-inline left-padding right-padding top-margin-20">
                          <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.removeOrg(this.state.selectedIndex,this.state.selectedType)}>Yes, Remove {this.state.selectedOrg.orgName}</button>
                        </div>
                        <div className="display-inline left-padding right-padding top-margin-20">
                          <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ showConfirmDelete: false })}>Cancel</button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="full-width center-text">
                         <div className="display-inline left-padding right-padding top-margin-20">
                           <button className="btn btn-squarish" onClick={() => this.orgClicked(this.state.selectedIndex, this.state.selectedType)}>Toggle to This Community</button>
                         </div>
                         <div className="display-inline left-padding right-padding top-margin-20">
                           <button className="btn btn-squarish error-background-color clear-border" onClick={() => this.setState({ showConfirmDelete: true })}>Remove from Your Workspaces/Communities</button>
                         </div>
                         <div className="display-inline left-padding right-padding top-margin-20">
                           <Link to={(window.location.pathname.includes('/employers')) ? "/employers/" + this.state.accountCode + "/orgs/" + this.state.selectedOrg.orgCode : "/app/orgs/" + this.state.selectedOrg.orgCode} className="background-button"><button className="btn btn-squarish denary-background clear-border">View Review Page</button></Link>
                         </div>
                         <div className="clear"/>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {(this.state.showSignUpFields && this.state.orgSelected) && (
                  <div>
                    <p className="heading-text-2">Sign Up Fields for {this.state.orgSelected.orgName}</p>

                    <p className="row-10">{this.state.orgSelected.orgName} requires the following fields before joining their workspace</p>

                    {(this.props.opportunityId) && (
                      <p className="row-10">After you sign up to the {this.state.orgSelected.orgName} workspace, you will be able to access this opportunity.</p>
                    )}
                    <div className="spacer" />

                    {this.renderSignUpFields()}

                    <div className="clear" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color">{this.state.successMessage}</p>}

                    <div className="top-padding-20">
                      <button className="btn btn-primary" onClick={() => this.submitSignUpFields()}>Submit</button>
                    </div>
                  </div>
                )}
               </div>
            </Modal>
          )}
        </div>
      )
    }
}

export default withRouter(Walkthrough);
