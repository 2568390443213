import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import ProfileCard from './ProfileCard';
import SubEndorsementDetails from '../Common/EndorsementDetails';
import withRouter from '../Functions/WithRouter';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)
  // for (let i = 1; i <= result.length; i++) {
  //   console.log(i);
  //
  //   result[i - 1]['rank'] = i
  //
  // }

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class EditBenchmark extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            showV2App: true,

            viewMode: 'List',
            viewIndex: 0,
            subNavSelected: 'All',
            interviewGrade: 0,

            totalPercent: 100,
            selectedJob: { },

            benchmarkCategories: [],

            typeOptions: ['','Internship','Full-Time','Part-Time'],

            workDistanceOptions: [],
            functionOptions: [],
            industryOptions: [],
            customAssessmentOptions: [{ title : 'No Custom Assessments Saved'}],
            scoreOptions: ['0','1','2','3','4','5'],
            weightOptions: ['0','1','2','3','4','5','6','7','8','9','10','15','20','25','30','35','40','45','50','55','60','65','70','75','80','85','90','95','100'],
            viewOptions: ['','List','Profile Card'],
            importanceOptions: ['','Super Important','Very Important','Important','Somewhat Important','Slightly Important','Not a Factor'],
            interestOptions: [],
            hourOptions: ['','100','200','300','400','500','600','700','800','900',"1,000","1,000 - 2,000","2,000 - 3,000","3,000+"],
            gradYearRangeOptions: [],
            gpaRangeOptions: ['','Top 1%','Top 5%','Top 10%','Top 20%','Top 30%',"Doesn't Matter"],
            courseHourRangeOptions: ['','0 - 100 Hours','100 - 500 Hours','500 - 1,000 Hours','1,000 - 5,000 Hours','5,000 - 10,000 Hours','10,000+ Hours'],
            myersBriggsOptions: ['','N/A','ENFJ','ENFP','ENTJ',"ENTP","ESFJ","ESFP","ESTJ","ESTP",'INFJ','INFP','INTJ',"INTP","ISFJ","ISFP","ISTJ","ISTP"],
            fiveFactorsOptions: ['','Very High','High','Moderate','Low','Very Low'],
            hourlyPayOptions: [],
            workInterestDescriptions: [],
            districtOptions: [],
            unitedStateOptions: [],

            interviewScoreOptions: ['100'],
            interviewWeightOptions: ['0','5','10','15','20','25','30','35','40','45','50','55','60','65','70','75','80','85','90','95','100'],
            pathwayOptions: [],

            genderOptions: [],
            raceOptions: [],
            veteranOptions: [],
            lowIncomeOptions: [],
            fosterYouthOptions: [],
            homelessOptions: [],
            incarceratedOptions: [],
            lgbtqiaOptions: [],
            disabilityOptions: [],
            firstGenImmigrantOptions: [],
            firstGenCollegeStudentOptions: [],

            diversityFields: [],
            politicalFields: [],
            generalInterests: [],
            personalityFields: [],
            additionalTraits: [],
            educationFields: [],
            idealMajors: [],
            projectFields: [],
            projectTags: [],
            paidExperienceFields: [],
            volunteerFields: [],
            volunteerTags: [],
            experienceTags: [],
            courses: [],
            certifications: [],

            gravitateValues: [],
            employerValues: [],
            additionalTechnologyTrends: [],
            additionalSocietalProblems: [],

            guidances: [],
            exampleAnswers: [],

            course: { name: '', url: ''},
            certification: { name: '', url: ''},
            interviewQuestion: { question: '', guidance: ''},
            interviewQuestions: [],
            criteria: 'Technical Prowess',

            highPriorityHardSkills: [],
            lowPriorityHardSkills: [],
            highPrioritySoftSkills: [],
            lowPrioritySoftSkills: [],

            _id: '',
            title: '',
            jobTitle: '',
            jobType: 'Internship',
            jobFunction: '',
            customAssessment: null,

            interests: [
              { title: 'Realistic', description: 'Prefers working with animals, tools, or machines. Values things you can see and touch. Sees themselves as practical, mechanical, and realistic.', score: 0, weight: 0 },
              { title: 'Investigative', description: 'Prefers studying and solving math and science problems. Values science. Sees themselves as precise, scientific, and intellectual.', score: 0, weight: 0 },
              { title: 'Artistic', description: 'Prefers creative activities like art, drama, music, and creative writing.  Values helping people and solving social problems. Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
              { title: 'Social', description: 'Prefers to do things to help people like teaching, nursing, or giving first aid. Values helping people, and solving societal problems.  Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
              { title: 'Enterprising', description: 'Prefers to lead and persuade people. Values success in politics, leadership, or business. Sees themselves myself as energetic, ambitious, and sociable.', score: 0, weight: 0 },
              { title: 'Conventional', description: 'Prefers to work with numbers, records, or machines in a set, orderly way. Values success in business. Sees themselves as orderly, and good at following a set plan.', score: 0, weight: 0 },
            ],
            skills: [],
            traits: [
              { title: 'Openness', description: "Appreciation for art, emotion, adventure, unusual ideas, curiosity, and variety of experience. Openness reflects the degree of intellectual curiosity, creativity and a preference for novelty and variety a person has.", score: 0, weight: 0 },
              { title: 'Conscientiousness', description: "Tendency to be organized and dependable, show self-discipline, act dutifully, aim for achievement, and prefer planned rather than spontaneous behavior. High conscientiousness is often perceived as being stubborn and focused.", score: 0, weight: 0 },
              { title: 'Extraversion', description: "Energetic, surgency, assertiveness, sociability and the tendency to seek stimulation in the company of others, and talkativeness. High extraversion is often perceived as attention-seeking and domineering.", score: 0, weight: 0 },
              { title: 'Agreeableness', description: "Tendency to be compassionate and cooperative rather than suspicious and antagonistic towards others. It is also a measure of one's trusting and helpful nature, and whether a person is generally well-tempered or not.", score: 0, weight: 0 },
              { title: 'Neuroticism', description: "Tendency to be prone to psychological stress. The tendency to experience unpleasant emotions easily, such as anger, anxiety, depression, and vulnerability. Neuroticism also refers to the degree of emotional stability and impulse control.", score: 0, weight: 0 },
            ],
            goals: [],
            educations: [],
            experiences: [],
            politicalParties: [
              { title: 'Democratic', description: 'Students affiliated with the democratic party are a greater match'},
              { title: 'Republic', description: 'Students affiliated with the republican party are a greater match'},
              { title: 'Independent', description: 'Students affiliated with neithr major U.S. party are a greater match'},
            ],
            interview: { score: 5, weight: 0 },

            localAllocation: [
              ['Component', 'Point Value'],
              ['Work Preferences', 0],
              ['Interests', 0],
              ['Personality', 0],
              ['Skills', 0],
              ['Endorsements', 0],
              ['Education', 0],
              ['Projects', 0],
              ['Experience', 0],
              ['Adversity Score', 0],
              ['Interview', 0],
            ],
            benchmarkAllocation: [
              ['Component', 'Point Value'],
              ['Work Preferences', 5],
              ['Interests', 10],
              ['Personality', 15],
              ['Skills', 15],
              ['Endorsements', 10],
              ['Education', 15],
              ['Projects', 20],
              ['Experience', 20],
              ['Adversity Score', 30],
              ['Interview', 0],
            ],

            thirdPartyAssessments: [],
            dealBreakers: [],
            interviewRubric: [],

            clientErrorMessage: '',
            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.finishRetrievingData = this.finishRetrievingData.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.searchCompetencies = this.searchCompetencies.bind(this)
        this.competencyClicked = this.competencyClicked.bind(this)

        this.addItem = this.addItem.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.saveBenchmark = this.saveBenchmark.bind(this)

        this.translateValue = this.translateValue.bind(this)
        this.translateWeight = this.translateWeight.bind(this)
        this.translateTraits = this.translateTraits.bind(this)

        this.handleScroll = this.handleScroll.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.addCriterion = this.addCriterion.bind(this)
        this.renderAllocation = this.renderAllocation.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.prepareEndorsement = this.prepareEndorsement.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)

        this.child = React.createRef();
    }

    static defaultProps = { source: null, activeOrg: null, accountCode: null, selectedTemplate: null }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      console.log('show result: ', result)

      if (this.state.gravitateValues.includes(result.draggableId)) {
        const gravitateValues = reorder(
          this.state.gravitateValues,
          result.source.index,
          result.destination.index
        );

        this.setState({ gravitateValues });
      } else if (this.state.employerValues.includes(result.draggableId)) {
        const employerValues = reorder(
          this.state.employerValues,
          result.source.index,
          result.destination.index
        );

        this.setState({ employerValues });
      } else if (this.state.technologyTrends.includes(result.draggableId)) {
        const technologyTrends = reorder(
          this.state.technologyTrends,
          result.source.index,
          result.destination.index
        );

        this.setState({ technologyTrends });
      } else if (this.state.societalProblems.includes(result.draggableId)) {
        const societalProblems = reorder(
          this.state.societalProblems,
          result.source.index,
          result.destination.index
        );

        this.setState({ societalProblems });
      }

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.selectedTemplate || this.props.benchmarkId) {
        // if (!prevProps.selectedTemplate) {
        //   this.retrieveData()
        // } else if (this.props.selectedTemplate.title !== prevProps.selectedTemplate) {
        //   this.retrieveData()
        // }
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEditBenchmark', this.props)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode
      // const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, accountCode })

      Axios.get('/api/customassessments', { params: { orgCode: org } })
      .then((response) => {
        console.log('Custom assessment query attempted', response.data);

        if (response.data.success) {
          console.log('custom assessment query worked')

          if (response.data.assessments.length !== 0) {

            let customAssessments = response.data.assessments
            customAssessments.unshift({ title: 'No Assessment Selected'})

            this.setState({
                emailId: email, username, org,
                customAssessmentOptions: customAssessments
            });
          }

        } else {
          console.log('custom assessment query did not work', response.data.message)
        }
      }).catch((error) => {
        console.log('Posted jobs query did not work for some reason', error);
      });

      Axios.get('/api/benchmarks', { params: { orgCode: 'general' } })
      .then((response) => {
        console.log('Benchmarks query attempted', response.data);

        if (response.data.success) {
          console.log('benchmark query worked')

          if (response.data.benchmarks.length !== 0) {
            //jobs = response.data.postings
            let benchmarks = response.data.benchmarks
            benchmarks.unshift({ title: 'No Benchmark Added'})

            this.setState({ emailId: email, username, org, benchmarkOptions: benchmarks });
          }

        } else {
          console.log('benchmark query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Benchmark query did not work for some reason', error);
      });

      if (this.props.benchmarkId) {
        Axios.get('/api/benchmarks/byid', { params: { _id: this.props.benchmarkId } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const selectedTemplate = response.data.benchmark
              this.finishRetrievingData(selectedTemplate, org, accountCode, email, username, cuFirstName, cuLastName)

            } else {
              console.log('org info query did not work', response.data.message)
              this.finishRetrievingData(null, org, accountCode, email, username, cuFirstName, cuLastName)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
            this.finishRetrievingData(null, org, accountCode, email, username, cuFirstName, cuLastName)
        });
      } else if (this.props.selectedTemplate) {
        this.finishRetrievingData(this.props.selectedTemplate, org, accountCode, email, username, cuFirstName, cuLastName)
      } else {
        this.finishRetrievingData(null, org, accountCode, email, username, cuFirstName, cuLastName)
      }

      if (accountCode) {
        Axios.get('/api/account', { params: { accountCode } })
        .then((response) => {
          console.log('Employer query by accountCode attempted', response.data);

            if (response.data.success) {
              console.log('employer query worked')

              const employerLogoURL = response.data.accountInfo.employerLogoURI
              const employerName = response.data.accountInfo.employerName
              const employerURL = response.data.accountInfo.employerURL
              const industry = response.data.accountInfo.employerIndustry

              this.setState({ employerLogoURL, employerName, employerURL, industry });

            } else {
              console.log('employer query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Employer query did not work for some reason', error);
        });
      }
    }

    finishRetrievingData(selectedTemplate, org, accountCode, email, username, cuFirstName, cuLastName) {
      console.log('finishRetrievingData called', selectedTemplate)

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const degreeOptions = response.data.workOptions[0].degreeOptions

          let functionOptions = response.data.workOptions[0].functionOptions
          // functionOptions.shift()
          let industryOptions = response.data.workOptions[0].industryOptions
          // industryOptions.shift()
          let workDistanceOptions = response.data.workOptions[0].workDistanceOptions
          // workDistanceOptions.shift()
          let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
          // hoursPerWeekOptions.shift()

          let hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
          // hourlyPayOptions.shift()
          let annualPayOptions = response.data.workOptions[0].annualPayOptions
          // annualPayOptions.shift()

          let genderOptions = response.data.workOptions[0].genderOptions
          genderOptions.unshift('')
          genderOptions[genderOptions.length - 1] = "Not Important (N/A)"

          let raceOptions = response.data.workOptions[0].raceOptions
          raceOptions.unshift('')
          raceOptions[raceOptions.length - 1] = "Not Important (N/A)"

          let veteranOptions = response.data.workOptions[0].veteranOptions
          veteranOptions.unshift('')
          veteranOptions[veteranOptions.length - 1] = "Not Important (N/A)"

          let lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions
          lowIncomeOptions.unshift('')
          lowIncomeOptions[lowIncomeOptions.length - 1] = "Not Important (N/A)"

          let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
          fosterYouthOptions.unshift('')
          fosterYouthOptions[fosterYouthOptions.length - 1] = "Not Important (N/A)"

          let homelessOptions = response.data.workOptions[0].homelessOptions
          homelessOptions.unshift('')
          homelessOptions[homelessOptions.length - 1] = "Not Important (N/A)"

          let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
          incarceratedOptions.unshift('')
          incarceratedOptions[incarceratedOptions.length - 1] = "Not Important (N/A)"

          let lgbtqiaOptions = response.data.workOptions[0].lgbtqiaOptions
          lgbtqiaOptions.unshift('')
          lgbtqiaOptions[lgbtqiaOptions.length - 1] = "Not Important (N/A)"

          let disabilityOptions = response.data.workOptions[0].disabilityOptions
          disabilityOptions.unshift('')
          disabilityOptions[disabilityOptions.length - 1] = "Not Important (N/A)"

          let firstGenImmigrantOptions = response.data.workOptions[0].firstGenImmigrantOptions
          firstGenImmigrantOptions.unshift('')
          firstGenImmigrantOptions[firstGenImmigrantOptions.length - 1] = "Not Important (N/A)"

          let firstGenCollegeStudentOptions = response.data.workOptions[0].firstGenCollegeStudentOptions
          firstGenCollegeStudentOptions.unshift('')
          firstGenCollegeStudentOptions[firstGenCollegeStudentOptions.length - 1] = "Not Important (N/A)"
          console.log('show genderOptions; ', genderOptions)

          let politicalAlignmentOptions = response.data.workOptions[0].politicalAlignmentOptions
          if (politicalAlignmentOptions) {
            politicalAlignmentOptions.unshift('')
          }

          let unitedStateOptions = response.data.workOptions[0].unitedStateOptions
          if (unitedStateOptions) {
            unitedStateOptions.unshift('')
          }

          let districtOptions = []
          for (let i = 0; i <= 40; i++) {
            districtOptions.push(i)
          }

          let technologyTrends = response.data.workOptions[0].technologyTrends
          if (selectedTemplate && selectedTemplate.technologyTrends && selectedTemplate.technologyTrends.length > 0) {
            technologyTrends = selectedTemplate.technologyTrends
          }

          let societalProblems = response.data.workOptions[0].societalProblems
          if (selectedTemplate && selectedTemplate.societalProblems && selectedTemplate.societalProblems.length > 0) {
            societalProblems = selectedTemplate.societalProblems
          }

          this.setState({ degreeOptions, functionOptions, industryOptions, workDistanceOptions, hoursPerWeekOptions,
            hourlyPayOptions, annualPayOptions, politicalAlignmentOptions, unitedStateOptions, districtOptions,
            genderOptions, raceOptions, veteranOptions, lowIncomeOptions, fosterYouthOptions, homelessOptions,
            incarceratedOptions, lgbtqiaOptions, disabilityOptions, firstGenImmigrantOptions, firstGenCollegeStudentOptions,
            technologyTrends, societalProblems
          })

          Axios.get('/api/org', { params: { orgCode: org } })
          .then((response) => {
            console.log('Org info query attempted', org);

              if (response.data.success) {
                console.log('org info query worked')

                if (org === 'c2c' && response.data.orgInfo.orgFunctionOptions) {
                  functionOptions = [''].concat(response.data.orgInfo.orgFunctionOptions)
                }

                let pathwayOptions = []
                if (response.data.orgInfo.pathways && response.data.orgInfo.pathways.length > 0) {
                  pathwayOptions = [{ name: '' }].concat(response.data.orgInfo.pathways)
                }
                const orgName = response.data.orgInfo.orgName
                const orgLogo = response.data.orgInfo.webLogoURIColor

                this.setState({ functionOptions, pathwayOptions, orgName, orgLogo });

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }
      });

      Axios.get('/api/assessment/questions')
      .then((response) => {
          //console.log('O*Net worked', response.data.body, this);
          console.log('show me response', response.data)
          if (response.data.success === true ) {
            console.log('o-net query worked')

            let gravitateValues = response.data.assessments.gravitateValues
            if (selectedTemplate && selectedTemplate.gravitateValues && selectedTemplate.gravitateValues.length > 0) {
              gravitateValues = selectedTemplate.gravitateValues
            }

            let employerValues = response.data.assessments.employerValues
            if (selectedTemplate && selectedTemplate.employerValues && selectedTemplate.employerValues.length > 0) {
              employerValues = selectedTemplate.employerValues
            }

            this.setState({ gravitateValues, employerValues })

          } else {
            console.log('Assessment question query failed', response.data.message)
          }


      }).catch((error) => {
          console.log('O*NET did not work', error);
      });

      //add options
      let gradYearRangeOptions = ['']
      const now = new Date().getFullYear()
      gradYearRangeOptions.push('< ' + (now - 5))
      gradYearRangeOptions.push((now - 5) + ' - ' + (now - 2))

      let startingYear = now - 3
      for (let i = 1; i <= 7; i++) {
        gradYearRangeOptions.push((startingYear + i) + ' - ' + (startingYear + i + 1))
      }

      let workInterestDescriptions = [
        'Prefers working with animals, tools, or machines. Values things you can see and touch. Sees themselves as practical, mechanical, and realistic.',
        'Prefers studying and solving math and science problems. Values science. Sees themselves as precise, scientific, and intellectual.',
        'Prefers creative activities like art, drama, music, and creative writing.  Values helping people and solving social problems. Sees themselves as helpful, friendly, and trustworthy.',
        'Prefers to do things to help people like teaching, nursing, or giving first aid. Values helping people, and solving societal problems.  Sees themselves as helpful, friendly, and trustworthy.',
        'Prefers to lead and persuade people. Values success in politics, leadership, or business. Sees themselves myself as energetic, ambitious, and sociable.',
        'Prefers to work with numbers, records, or machines in a set, orderly way. Values success in business. Sees themselves as orderly, and good at following a set plan.',
      ]

      let benchmarkCategories = ['All','Weights','Work Preferences SA','Interests SA','Personality SA','Values SA','Skills SA','3rd Party Assessments','Endorsements','Education','Projects','Experience','Resume','Cover Letter','Interview','Diversity']

      benchmarkCategories.push('Deal Breakers')
      // if (org === 'c2c') {
      //   benchmarkCategories.push('Politics')
      // }

      const interestOptions = ['','Highly Interested','Interested','Somewhat Interested','Uninterested','Highly Uninterested']

      let existingBenchmark = false
      if (email && selectedTemplate) {
        existingBenchmark = true
        console.log('there is an existing benchmark')

        if (this.props.duplicate) {
          delete selectedTemplate._id
          selectedTemplate['orgCode'] = org
          selectedTemplate['title'] = selectedTemplate.title + " (Duplicate)"
        }

        //basic data
        let _id = selectedTemplate._id
        const title = selectedTemplate.title
        const jobTitle = selectedTemplate.jobTitle
        const jobType = selectedTemplate.jobType
        const jobFunction = selectedTemplate.jobFunction
        const industry = selectedTemplate.industry
        const pathway = selectedTemplate.pathway

        // calculate percents

        //preferences percent
        let preferencesPercent = 0
        if (selectedTemplate.workPreferenceWeight) {
          preferencesPercent = selectedTemplate.workPreferenceWeight
        }

        //interests percent
        let interestsPercent = 0
        if (selectedTemplate.interestsWeight) {
          interestsPercent = selectedTemplate.interestsWeight
        } else {
          if (selectedTemplate.interests) {
            for (let i = 1; i <= selectedTemplate.interests.length; i++) {
              if (selectedTemplate.interests[i - 1].weight) {
                interestsPercent = interestsPercent + Number(selectedTemplate.interests[i - 1].weight)
              }
            }
          }
        }

        //round interestsPercent
        interestsPercent = Number(interestsPercent.toFixed())

        //traits percent
        let traitsPercent = 0
        if (selectedTemplate.personalityWeight) {
          traitsPercent = selectedTemplate.personalityWeight
        } else {
          if (selectedTemplate.traits) {
            for (let i = 1; i <= selectedTemplate.traits.length; i++) {
              if (selectedTemplate.traits[i - 1].weight) {
                traitsPercent = traitsPercent + Number(selectedTemplate.traits[i - 1].weight)
              }
            }
          }
        }

        //round traitsPercent
        traitsPercent = Number(traitsPercent.toFixed())

        //valuesPercent
        let valuesPercent = 0
        if (selectedTemplate.valuesWeight) {
          valuesPercent = selectedTemplate.valuesWeight
        }

        //skills percent
        let skillsPercent = 0
        // let benchmarkHardSkills = []
        // let benchmarkSoftSkills = []
        if (selectedTemplate.skills && selectedTemplate.skills.length > 0) {
          for (let i = 1; i <= selectedTemplate.skills.length; i++) {
            if (selectedTemplate.skills[i - 1].weight) {
              skillsPercent = skillsPercent + Number(selectedTemplate.skills[i - 1].weight)

              // if (selectedTemplate.skills[i - 1].skillType === 'Hard Skill') {
              //   benchmarkHardSkills.push(selectedTemplate.skills[i - 1].title)
              // } else {
              //   benchmarkSoftSkills.push(selectedTemplate.skills[i - 1].title)
              // }
            }
          }
        } else if (selectedTemplate.skillsWeight) {
          skillsPercent = selectedTemplate.skillsWeight
        }

        //round skillsPercent
        skillsPercent = Number(skillsPercent.toFixed())

        let thirdPartyAssessmentPercent = 0
        if (selectedTemplate.thirdPartyAssessmentWeight) {
          thirdPartyAssessmentPercent = selectedTemplate.thirdPartyAssessmentWeight
        }

        //endorsements percent
        let endorsementsPercent = 0
        if (selectedTemplate.endorsementWeight) {
          endorsementsPercent = selectedTemplate.endorsementWeight
        }

        //education percent
        let educationPercent = 0
        if (selectedTemplate.educationWeight) {
          educationPercent = selectedTemplate.educationWeight
        }

        //projects percent
        let projectsPercent = 0
        if (selectedTemplate.projectWeight) {
          projectsPercent = selectedTemplate.projectWeight
        }

        //experience percent
        let experiencePercent = 0
        if (selectedTemplate.experienceWeight) {
          experiencePercent = selectedTemplate.experienceWeight
        }

        let resumePercent = 0
        if (selectedTemplate.resumeWeight) {
          resumePercent = selectedTemplate.resumeWeight
        }

        let coverLetterPercent = 0
        if (selectedTemplate.coverLetterWeight) {
          coverLetterPercent = selectedTemplate.coverLetterWeight
        }

        //interview percent
        let interviewPercent = 0
        let interview = { score: 100, weight: 0 }
        if (selectedTemplate.interview) {
          interviewPercent = selectedTemplate.interview.weight
          interview = { score: 100, weight: interviewPercent }
        }

        let politicalPercent = 0
        let homeStatePercent = 0
        let adversityScorePercent = selectedTemplate.adversityScoreWeight

        //DELETE EVENTUALLY
        const tempStandardization = true

        function totalPercents() {

          const firstQuarter = preferencesPercent + interestsPercent + traitsPercent + valuesPercent
          const secondQuarter = skillsPercent + thirdPartyAssessmentPercent + endorsementsPercent + educationPercent
          const thirdQuarter = projectsPercent + experiencePercent + resumePercent + coverLetterPercent + interviewPercent
          const fourthQuarter = politicalPercent + homeStatePercent + adversityScorePercent
          const total = firstQuarter + secondQuarter + thirdQuarter + fourthQuarter
          // console.log('show quarters: ', firstQuarter, secondQuarter, thirdQuarter, fourthQuarter, total)
          // console.log('show individuals: ', preferencesPercent, interestsPercent, traitsPercent, adversityScorePercent)
          return total
        }

        if (tempStandardization) {

          const total = totalPercents()

          if (total !== 100) {
            const adjuster = (total) / 100

            preferencesPercent = Number((preferencesPercent / adjuster).toFixed())
            interestsPercent = Number((interestsPercent / adjuster).toFixed())
            traitsPercent = Number((traitsPercent / adjuster).toFixed())
            skillsPercent = Number((skillsPercent / adjuster).toFixed())
            endorsementsPercent = Number((endorsementsPercent / adjuster).toFixed())
            thirdPartyAssessmentPercent = Number((thirdPartyAssessmentPercent / adjuster).toFixed())
            educationPercent = Number((educationPercent / adjuster).toFixed())
            projectsPercent = Number((projectsPercent / adjuster).toFixed())
            experiencePercent = Number((experiencePercent / adjuster).toFixed())
            resumePercent = Number((resumePercent / adjuster).toFixed())
            coverLetterPercent = Number((coverLetterPercent / adjuster).toFixed())
            interviewPercent = Number((interviewPercent / adjuster).toFixed())
            politicalPercent = Number((politicalPercent / adjuster).toFixed())
            homeStatePercent = Number((homeStatePercent / adjuster).toFixed())
            adversityScorePercent = Number((adversityScorePercent / adjuster).toFixed())

            const newTotal = totalPercents()
            console.log('show totals: ', total, adjuster, newTotal)
            if (newTotal < 100) {
              skillsPercent = skillsPercent + (100 - newTotal)
            } else if (newTotal > 100) {
              skillsPercent = skillsPercent + (newTotal - 100)
            }
          }
        }

        const totalPercent = 100

        //importance
        // const proximityRequirementsImportance = selectedTemplate.proximityRequirementsImportance
        // const maxPayImportance = selectedTemplate.maxPayImportance
        // const functionsOfInterestImportance = selectedTemplate.func
        // const industriesOfInterestImportance = selectedTemplate.proximityRequirementsImportance

        const generalInterestsImportance = selectedTemplate.generalInterestsImportance
        // const myersBriggsImportance = selectedTemplate.proximityRequirementsImportance
        const additionalTraitsImportance = selectedTemplate.additionalTraitsImportance

        // const degreeRequirementsImportance = selectedTemplate.proximityRequirementsImportance
        // const idealMajorsImportance = selectedTemplate.proximityRequirementsImportance
        // const gpaRangeImportance = selectedTemplate.proximityRequirementsImportance
        // const gradYearRangeImportance = selectedTemplate.proximityRequirementsImportance
        // const testScoresImportance = selectedTemplate.proximityRequirementsImportance
        // const courseHoursImportance = selectedTemplate.proximityRequirementsImportance
        // const coursesImportance = selectedTemplate.proximityRequirementsImportance
        // const certificationsImportance = selectedTemplate.proximityRequirementsImportance

        const projectTagsImportance = selectedTemplate.projectTagsImportance
        const experienceTagsImportance = selectedTemplate.experienceTagsImportance
        const volunteerTagsImportance = selectedTemplate.volunteerTagsImportance

        // const genderImportance = selectedTemplate.proximityRequirementsImportance
        // const raceImportance = selectedTemplate.proximityRequirementsImportance
        // const veteranImportance = selectedTemplate.proximityRequirementsImportance
        // const lowIncomeImportance = selectedTemplate.proximityRequirementsImportance
        // const fosterYouthImportance = selectedTemplate.proximityRequirementsImportance
        // const homelessImportance = selectedTemplate.proximityRequirementsImportance
        // const incarceratedImportance = selectedTemplate.proximityRequirementsImportance
        // const lgbtqiaImportance = selectedTemplate.proximityRequirementsImportance
        // const disabilityImportance = selectedTemplate.proximityRequirementsImportance
        // const firstGenImmigrantImportance = selectedTemplate.proximityRequirementsImportance
        // const firstGenCollegeStudentImportance = selectedTemplate.proximityRequirementsImportance

        let dealBreakers = []
        if (selectedTemplate.dealBreakers) {
          dealBreakers = selectedTemplate.dealBreakers
        }

        // work preferences
        const workPreferenceFields = [
          { name: 'What is the maximum distance a candidate can be from an employer to be eligible for hire?', value: selectedTemplate.proximityRequirements, importance: selectedTemplate.proximityRequirementsImportance },
          { name: 'What is the maximum amount your business is willing to pay (hourly) an entry-level role?', value: selectedTemplate.maxPay, importance: selectedTemplate.maxPayImportance },
          { name: 'What work functions would you like the candidate to be interested in', value: selectedTemplate.functionsOfInterest, importance: selectedTemplate.functionsOfInterestImportance },
          { name: 'What industries would you like the candidate to be interested in', value: selectedTemplate.industriesOfInterest, importance: selectedTemplate.industriesOfInterestImportance },
          { name: 'Which macro-technology trends would you prefer candidates show interest in most (please rank):', value: selectedTemplate.technologyTrends, importance: selectedTemplate.technologyTrendsImportance },
          { name: 'Additional “macro-technology trends” you prefer candidates show interest in:', value: selectedTemplate.additionalTechnologyTrends, importance: selectedTemplate.additionalTechnologyTrendsImportance },
          { name: 'Which macro-societal problems would you prefer candidates show interest in solving most (please rank):', value: selectedTemplate.societalProblems, importance: selectedTemplate.societalProblemsImportance },
          { name: 'Additional “macro-societal problems” you prefer candidates show interest in:', value: selectedTemplate.additionalSocietalProblems, importance: selectedTemplate.additionalSocietalProblemsImportance },
        ]
        let additionalTechnologyTrends = []
        if (selectedTemplate && selectedTemplate.additionalTechnologyTrends && selectedTemplate.additionalTechnologyTrends.length > 0) {
          additionalTechnologyTrends = selectedTemplate.additionalTechnologyTrends
        }

        let additionalSocietalProblems = []
        if (selectedTemplate && selectedTemplate.additionalSocietalProblems && selectedTemplate.additionalSocietalProblems.length > 0) {
          additionalSocietalProblems = selectedTemplate.additionalSocietalProblems
        }

        // interests
        const interests = selectedTemplate.interests //oldSchool
        let generalInterests = []
        if (selectedTemplate.generalInterests) {
          generalInterests = selectedTemplate.generalInterests
        }
        let workInterestFields = []

        let existingInterests = false
        if (selectedTemplate.interests && selectedTemplate.interests.length) {
          for (let i = 1; i <= selectedTemplate.interests.length; i++) {
            if (selectedTemplate.interests[i - 1] && selectedTemplate.interests[i - 1].score) {
              existingInterests = true
            }
          }
        }

        if (existingInterests) {

          workInterestFields = [
            { name: 'Realistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[0].score, true), importance: this.translateWeight(selectedTemplate.interests[0].weight, true)},
            { name: 'Investigative', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[1].score, true), importance: this.translateWeight(selectedTemplate.interests[1].weight, true)},
            { name: 'Artistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[2].score, true), importance: this.translateWeight(selectedTemplate.interests[2].weight, true)},
            { name: 'Social', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[3].score, true), importance: this.translateWeight(selectedTemplate.interests[3].weight, true)},
            { name: 'Enterprising', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[4].score, true), importance: this.translateWeight(selectedTemplate.interests[4].weight, true)},
            { name: 'Conventional', url: 'https://en.wikipedia.org/wiki/Holland_Codes', value: this.translateValue(selectedTemplate.interests[5].score, true), importance: this.translateWeight(selectedTemplate.interests[5].weight, true)},
          ]

          console.log('show interests on load: ', selectedTemplate.interests, selectedTemplate.interests[5], workInterestFields[5])
        } else {

          workInterestFields = [
            { name: 'Realistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
            { name: 'Investigative', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
            { name: 'Artistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
            { name: 'Social', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
            { name: 'Enterprising', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
            { name: 'Conventional', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          ]
        }

        // personality
        const traits = selectedTemplate.traits //oldSchool
        let personalityFields = []
        if (selectedTemplate.traits && selectedTemplate.traits[0]) {
          personalityFields = [
           {name: '16 Personalities', url: 'https://www.16personalities.com/personality-types', value: selectedTemplate.myersBriggs, importance: selectedTemplate.myersBriggsImportance},
           {name: 'Openness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits', value: this.translateTraits(selectedTemplate.traits[0].score, true), importance: this.translateWeight(selectedTemplate.traits[0].weight, true)},
           {name: 'Conscientiousness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits', value: this.translateTraits(selectedTemplate.traits[1].score, true), importance: this.translateWeight(selectedTemplate.traits[1].weight, true)},
           {name: 'Extraversion', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits', value: this.translateTraits(selectedTemplate.traits[2].score, true), importance: this.translateWeight(selectedTemplate.traits[2].weight, true)},
           {name: 'Agreeableness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits', value: this.translateTraits(selectedTemplate.traits[3].score, true), importance: this.translateWeight(selectedTemplate.traits[3].weight, true)},
           {name: 'Neuroticism (Inverse of Emotional Stability)', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits', value: this.translateTraits(selectedTemplate.traits[4].score, true), importance: this.translateWeight(selectedTemplate.traits[4].weight, true)},
         ]
        } else {
          personalityFields = [
           {name: '16 Personalities', url: 'https://www.16personalities.com/personality-types', value: selectedTemplate.myersBriggs, importance: selectedTemplate.myersBriggsImportance},
           {name: 'Openness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
           {name: 'Conscientiousness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
           {name: 'Extraversion', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
           {name: 'Agreeableness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
           {name: 'Neuroticism (Inverse of Emotional Stability)', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
         ]
        }
        let additionalTraits = []
        if (selectedTemplate.additionalTraits) {
          additionalTraits = selectedTemplate.additionalTraits
        }

        let thirdPartyAssessments = []
        if (selectedTemplate.thirdPartyAssessments) {
          thirdPartyAssessments = selectedTemplate.thirdPartyAssessments
        }

        // skills
        const skills = selectedTemplate.skills //oldSchool
        let highPriorityHardSkills = []
        let lowPriorityHardSkills = []
        let highPrioritySoftSkills = []
        let lowPrioritySoftSkills = []
        if (selectedTemplate.skills) {
          let estimateHardSkills = true
          let estimateSoftSkills = true

          if (selectedTemplate.highPriorityHardSkills && selectedTemplate.highPriorityHardSkills.length > 0) {
            highPriorityHardSkills = selectedTemplate.highPriorityHardSkills
            estimateHardSkills = false
          }

          if (selectedTemplate.lowPriorityHardSkills && selectedTemplate.lowPriorityHardSkills.length > 0) {
            lowPriorityHardSkills = selectedTemplate.lowPriorityHardSkills
            estimateHardSkills = false
          }

          if (selectedTemplate.highPrioritySoftSkills && selectedTemplate.highPrioritySoftSkills.length > 0) {
            highPrioritySoftSkills = selectedTemplate.highPrioritySoftSkills
            estimateSoftSkills = false
          }

          if (selectedTemplate.lowPrioritySoftSkills && selectedTemplate.lowPrioritySoftSkills.length > 0) {
            lowPrioritySoftSkills = selectedTemplate.lowPrioritySoftSkills
            estimateSoftSkills = false
          }


          if (estimateHardSkills || estimateSoftSkills) {
            for (let i = 1; i <= selectedTemplate.skills.length; i++) {
              console.log('looping skills', selectedTemplate.skills[i - 1].skillType, selectedTemplate.skills[i - 1].title)
              if (selectedTemplate.skills[i - 1].skillType === 'Hard Skill') {
                if (estimateHardSkills) {
                  if (Number(selectedTemplate.skills[i - 1].weight) >= 1) {
                    highPriorityHardSkills.push(selectedTemplate.skills[i - 1].title)
                  } else {
                    lowPriorityHardSkills.push(selectedTemplate.skills[i - 1].title)
                  }
                }
              } else {
                if (estimateSoftSkills) {
                  if (Number(selectedTemplate.skills[i - 1].weight) >= 1) {
                    highPrioritySoftSkills.push(selectedTemplate.skills[i - 1].title)
                  } else {
                    lowPrioritySoftSkills.push(selectedTemplate.skills[i - 1].title)
                  }
                }
              }
            }
          }
        }

        // education
        const educationFields = [
          {name: 'Degree Requirements', value: selectedTemplate.degreeRequirements, importance: selectedTemplate.degreeRequirementsImportance },
          {name: 'Ideal Majors', value: selectedTemplate.idealMajors, importance: selectedTemplate.idealMajorsImportance},
          {name: 'GPA Percentile Range', value: selectedTemplate.gpaRange, importance: selectedTemplate.gpaRangeImportance},
          {name: 'Grad Year Range', value: selectedTemplate.gradYearRange, importance: selectedTemplate.gradYearRangeImportance},
          {name: 'Standardized Test Scores', value: selectedTemplate.testScores, importance: selectedTemplate.testScoresImportance},
          { name: '# of Hours in Coursework', value: selectedTemplate.courseHours, importance: selectedTemplate.courseHoursImportance},
          { name: 'Ideal Online Coursework Completed', value: selectedTemplate.courses, importance: selectedTemplate.coursesImportance},
          { name: 'Ideal Certifications, Licenses, or Badges Completed', value: selectedTemplate.certifications, importance: selectedTemplate.certificationsImportance }
        ]
        let idealMajors = []
        if (selectedTemplate.idealMajors) {
          idealMajors = selectedTemplate.idealMajors
        }
        let courses = []
        if (selectedTemplate.courses) {
          courses = selectedTemplate.courses
        }
        let certifications = []
        if (selectedTemplate.certifications) {
          certifications = selectedTemplate.certifications
        }

        // projects
        const projectFields = [{ name: 'Minimum Number of Hours', value: selectedTemplate.projectHours, importance: selectedTemplate.projectHoursImportance }]

        let projectTags = []
        if (selectedTemplate.projectTags) {
          projectTags = selectedTemplate.projectTags
        }

        // experience
        const paidExperienceFields = [{ name: 'Minimum Number of Hours', value: selectedTemplate.experienceHours, importance: selectedTemplate.experienceHoursImportance }]
        const volunteerFields = [{ name: 'Minimum Number of Hours', value: selectedTemplate.volunteerHours, importance: selectedTemplate.volunteerHoursImportance }]
        let experienceTags = []
        if (selectedTemplate.experienceTags) {
          experienceTags = selectedTemplate.experienceTags
        }
        let volunteerTags = []
        if (selectedTemplate.volunteerTags) {
          volunteerTags = selectedTemplate.volunteerTags
        }

        // interview

        // let interview = this.state.interview //oldSchool
        // if (selectedTemplate.interviewWeight) {
        //   interview = { score: 5, weight: selectedTemplate.interviewWeight }
        // }
        //change the score in the interviewObject based on the below
        const interviewDetails = {} //oldSchool
        const interviewQuestions = selectedTemplate.interviewQuestions
        const strongFitCues = selectedTemplate.strongFitCues
        const poorFitCues = selectedTemplate.poorFitCues
        let interviewRubric = []
        if (selectedTemplate.interviewRubric) {
          interviewRubric = selectedTemplate.interviewRubric
        }

        // diversity
        const diversityFields = [
          {name: 'Gender', value: selectedTemplate.gender, importance: selectedTemplate.genderImportance},
          {name: 'Race', value: selectedTemplate.race, importance: selectedTemplate.raceImportance},
          {name: 'Veteran', value: selectedTemplate.veteran, importance: selectedTemplate.veteranImportance},
          {name: 'Low Income (Household Income / Members)', value: selectedTemplate.lowIncome, importance: selectedTemplate.lowIncomeImportance},
          {name: 'Foster Youth', value: selectedTemplate.fosterYouth, importance: selectedTemplate.fosterYouthImportance},
          {name: 'Homeless', value: selectedTemplate.homeless, importance: selectedTemplate.homelessImportance},
          {name: 'Incarcerated', value: selectedTemplate.incarcerated, importance: selectedTemplate.incarceratedImportance},
          {name: 'LGBTQIA', value: selectedTemplate.lgbtqia, importance: selectedTemplate.lgbtqiaImportance},
          {name: 'Disability', value: selectedTemplate.disability, importance: selectedTemplate.disabilityImportance},
          {name: 'First Generation Immigrant', value: selectedTemplate.firstGenImmigrant, importance: selectedTemplate.firstGenImmigrantImportance},
          {name: 'First Generation College Student', value: selectedTemplate.firstGenCollegeStudent, importance: selectedTemplate.firstGenCollegeStudentImportance}
        ]

        // politics
        const politicalFields = [
          {name: 'Political Alignment', value: selectedTemplate.politicalAlignment, importance: selectedTemplate.politicalAlignmentImportance},
          {name: 'Hometown', value: selectedTemplate.hometown, importance: selectedTemplate.hometownImportance},
          {name: 'Home Congressional District', value: Number(selectedTemplate.homeCongressionalDistrict), importance: selectedTemplate.homeCongressionalDistrictImportance},
          {name: 'Current Town', value: selectedTemplate.currentTown, importance: selectedTemplate.currentTownImportance },
          {name: 'Current Congressional District', value: Number(selectedTemplate.currentCongressionalDistrict), importance: selectedTemplate.currentCongressionalDistrictImportance },
        ]

        //push data to fake application
        let createFakeApplication = true
        let selectedApplication = null
        let selectedJob = null
        if (createFakeApplication) {
          _id = selectedTemplate._id
          const firstName = 'Jon'
          const lastName = 'Doe'
          const email = 'fakeemail@email.com'
          const schoolName = 'Sample School'
          const gradYear = new Date().getFullYear()
          const pictureURL = 'https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB'
          const major = 'Business - Sample'

          let paidExperienceHours = 0
          if (selectedTemplate.experienceHours) {
            if (Number(selectedTemplate.experienceHours)) {
              paidExperienceHours = Number(selectedTemplate.experienceHours)
            } else if (selectedTemplate.experienceHours.includes('-')){
              const nameArray = selectedTemplate.experienceHours.split('-')
              const num1 = Number(nameArray[0].trim().replace(/,/g,""))
              const num2 = Number(nameArray[1].trim().replace(/,/g,""))
              let avg = (num1 + num2) / 2
              console.log('show avg', avg, nameArray, num1, num2)
              paidExperienceHours = avg
            } else if (selectedTemplate.experienceHours.includes('+')){
              const nameArray = selectedTemplate.experienceHours.split('+')
              paidExperienceHours = Number(nameArray[0].trim().replace(/,/g,""))
            }
          }

          let volunteerHours = 0
          if (selectedTemplate.volunteerHours) {
            if (Number(selectedTemplate.volunteerHours)) {
              volunteerHours = Number(selectedTemplate.volunteerHours)
            } else if (selectedTemplate.volunteerHours.includes('-')){
              const nameArray = selectedTemplate.volunteerHours.split('-')
              const num1 = Number(nameArray[0].trim().replace(/,/g,""))
              const num2 = Number(nameArray[1].trim().replace(/,/g,""))
              let avg = (num1 + num2) / 2
              volunteerHours = avg
            } else if (selectedTemplate.volunteerHours.includes('+')){
              const nameArray = selectedTemplate.volunteerHours.split('+')
              volunteerHours = Number(nameArray[0].trim().replace(/,/g,""))
            }
          }

          const experienceHours = paidExperienceHours
          let projectHours = 0
          if (selectedTemplate.totalHours) {
            if (Number(selectedTemplate.projectHours)) {
              projectHours = Number(selectedTemplate.projectHours)
            } else if (selectedTemplate.projectHours.includes('-')){
              const nameArray = selectedTemplate.projectHours.split('-')
              const num1 = Number(nameArray[0].trim().replace(/,/g,""))
              const num2 = Number(nameArray[1].trim().replace(/,/g,""))
              let avg = (num1 + num2) / 2
              projectHours = avg
            } else if (selectedTemplate.projectHours.includes('+')){
              const nameArray = selectedTemplate.projectHours.split('+')
              projectHours = Number(nameArray[0].trim().replace(/,/g,""))
            }
          }

          const totalHours = experienceHours + projectHours + experienceHours
          console.log('show hors: ', totalHours, experienceHours, projectHours, volunteerHours, selectedTemplate.experienceHours)

          const paidExperienceCount = 2
          const volunteerExperienceCount = 2
          const projectCount = 2

          // const interviewQuestions = selectedTemplate.interviewQuestions
          let workPreferenceResults = null
          let interestResults = selectedTemplate.interests
          let personalityResults = selectedTemplate.traits
          let skillScores = []
          let skillTraits = []

          let intangiblesText = ''
          const passions = selectedTemplate.passions

          //workPreferenceResults
          workPreferenceResults = ["['Design']","['Retail','Music', 'Information','Food Services','Fashion']","['Work for a startup or small-sized company']","Graphic Design Intern, Design Intern","n/a","Yes","15 miles","['~ 20 Miles']"]
          workPreferenceResults[6] = selectedTemplate.proximityRequirements
          workPreferenceResults[7] = selectedTemplate.maxPay

          interestResults = []
          for (let i = 1; i <= selectedTemplate.interests.length; i++) {
            const name = selectedTemplate.interests[i - 1].title
            const description = selectedTemplate.interests[i - 1].description
            const tempScore = Number(selectedTemplate.interests[i - 1].score)
            // const score = Math.ceil(tempScore/5)*5
            const score = tempScore * 20 * 0.4

            interestResults.push({ name, description, score })
          }

          let tempOpennessScore = selectedTemplate.traits[0].score
          let opennessScore = tempOpennessScore * 20 * (16/100)

          const conscientiousnessScore = selectedTemplate.traits[1].score * 20 * (16/100)
          const extraversionScore = selectedTemplate.traits[2].score * 20 * (16/100)
          const agreeablenessScore = selectedTemplate.traits[3].score * 20 * (16/100)
          const neuroticismScore = selectedTemplate.traits[4].score * 20 * (16/100)

          let myersBriggs = 'ENTJ'
          if (selectedTemplate.myersBriggs) {
            myersBriggs = selectedTemplate.myersBriggs
          }

          personalityResults = {
            myersBriggs, fiveFactors: {
              opennessScore, conscientiousnessScore, extraversionScore, agreeablenessScore, neuroticismScore
            }
          }

          intangiblesText = 'This person is most interested in engineering careers and least interested in design careers. Their personality is high in openness and low in emotional stability.'

          if (selectedTemplate.skills && selectedTemplate.skills.length > 0) {
            skillScores = []
            let hardSkillCounter = 0
            let softSkillCounter = 0
            for (let i = 1; i <= selectedTemplate.skills.length; i++) {
              let selectedSkill = selectedTemplate.skills[i - 1]
              console.log('show selectedSkill: ', selectedSkill)
              skillScores.push({ title: selectedSkill.title, skillType: selectedSkill.skillType, score: 5 })
              if (selectedSkill.skillType === 'Hard Skill') {
                hardSkillCounter = hardSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              } else {
                softSkillCounter = softSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              }
            }
          }

          if (selectedTemplate.traits && selectedTemplate.traits.length > 0) {
            for (let i = 1; i <= selectedTemplate.traits.length; i++) {
              let selectedTrait = selectedTemplate.traits[i - 1]
              // console.log('show selectedSkill: ', selectedSkill)
              skillTraits.push({ name: selectedTrait.title, skillType: 'Trait', rating: 5 })
            }
          }

          let endorsements = []

          //education
          const degreeRequirements = selectedTemplate.degreeRequirements
          // const idealMajors = selectedTemplate.idealMajors  pullfromabove
          const gpaRange = selectedTemplate.gpaRange
          const gradYearRange = selectedTemplate.gradYearRange
          const testScores = selectedTemplate.testScores
          const courseHours = selectedTemplate.courseHours
          // const courses = selectedTemplate.courses  pullfromabove
          // const certifications = selectedTemplate.certifications  pullfromabove

          //politics
          const politicalParties = selectedTemplate.politicalParties //
          const politicalAlignment = selectedTemplate.politicalAlignment
          const hometown = selectedTemplate.hometown
          const homeCongressionalDistrict = selectedTemplate.homeCongressionalDistrict

          //diversity
          const gender = selectedTemplate.gender
          const race = selectedTemplate.race
          const veteran = selectedTemplate.veteran
          const lowIncome = selectedTemplate.lowIncome
          const fosterYouth = selectedTemplate.fosterYouth
          const homeless = selectedTemplate.homeless
          const incarcerated = selectedTemplate.incarcerated
          const lgbtqia = selectedTemplate.lgbtqia
          const disability = selectedTemplate.disability
          const firstGenImmigrant = selectedTemplate.firstGenImmigrant
          const firstGenCollegeStudent = selectedTemplate.firstGenCollegeStudent

          const match = 100

          const createdAt = new Date()
          const updatedAt = new Date()

          selectedApplication = {
            _id, firstName, lastName, email, schoolName, pictureURL, endorsements, interview, interviewDetails,
            politicalAlignment, hometown, homeCongressionalDistrict, match, createdAt, updatedAt,
            gradYear, major,
            paidExperienceCount, volunteerExperienceCount, projectCount,
            experienceHours, paidExperienceHours, volunteerHours, projectHours, totalHours, intangiblesText,
            personalityResults, passions, degreeRequirements, gpaRange, gradYearRange, testScores, courseHours,
            politicalParties, gender, race, veteran, lowIncome, fosterYouth, homeless, incarcerated, lgbtqia,
            disability, firstGenImmigrant, firstGenCollegeStudent
          }

          selectedJob = { title: selectedTemplate.jobTitle, workFunction: selectedTemplate.jobFunction }
        }

        const workPreferencesMessage = selectedTemplate.workPreferencesMessage
        const interestsMessage = selectedTemplate.interestsMessage
        const personalityMessage = selectedTemplate.personalityMessage
        const valuesMessage = selectedTemplate.valuesMessage
        const skillsMessage = selectedTemplate.skillsMessage
        const thirdPartyAssessmentsMessage = selectedTemplate.thirdPartyAssessmentsMessage
        const endorsementsMessage = selectedTemplate.endorsementsMessage
        const educationMessage = selectedTemplate.educationMessage
        const projectsMessage = selectedTemplate.projectsMessage
        const experienceMessage = selectedTemplate.experienceMessage
        const resumeMessage = selectedTemplate.resumeMessage
        const coverLetterMessage = selectedTemplate.coverLetterMessage
        const interviewMessage = selectedTemplate.interviewMessage
        const adversityScoreMessage = selectedTemplate.adversityScoreMessage

        const pathwayLevel = selectedTemplate.pathwayLevel
        const approved = selectedTemplate.approved

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, org, accountCode, selectedApplication, selectedJob,
            benchmarkCategories, workPreferenceFields, workInterestFields, personalityFields,
            educationFields, projectFields, paidExperienceFields, volunteerFields, diversityFields, politicalFields,

            interestOptions, gradYearRangeOptions, workInterestDescriptions,

            existingBenchmark,

            _id, title, jobTitle, jobType, jobFunction, industry, pathway,

            preferencesPercent, interestsPercent, traitsPercent, valuesPercent, skillsPercent, thirdPartyAssessmentPercent, endorsementsPercent, educationPercent,
            projectsPercent, experiencePercent, resumePercent, coverLetterPercent, interviewPercent, adversityScorePercent, politicalPercent,
            homeStatePercent, totalPercent,

            additionalTechnologyTrends, additionalSocietalProblems,

            generalInterestsImportance, generalInterests, interests,

            additionalTraitsImportance, additionalTraits, traits,

            highPriorityHardSkills, lowPriorityHardSkills, highPrioritySoftSkills, lowPrioritySoftSkills, skills,

            idealMajors, courses, certifications,

            projectTagsImportance, experienceTagsImportance, volunteerTagsImportance,
            experienceTags, volunteerTags, projectTags,

            interviewQuestions, strongFitCues, poorFitCues, interviewRubric, interview,

            dealBreakers, thirdPartyAssessments,

            workPreferencesMessage, interestsMessage, personalityMessage, valuesMessage, skillsMessage, thirdPartyAssessmentsMessage,
            endorsementsMessage, educationMessage, projectsMessage, experienceMessage, resumeMessage, coverLetterMessage,
            interviewMessage, adversityScoreMessage,

            pathwayLevel, approved
        });

      } else {

        console.log('there is no exising benchmark', selectedTemplate)

        //there is no existing template
        const selectedJob = {}
        const selectedApplication = { match: 100, firstName: 'John', lastName: 'Doe', interview: {}, interviewDetails: {} }

        const preferencesPercent = 0
        const interestsPercent = 0
        const traitsPercent = 0
        const valuesPercent = 0
        const skillsPercent = 0
        const thirdPartyAssessmentPercent = 0
        const endorsementsPercent = 0
        const educationPercent = 0
        const projectsPercent = 0
        const experiencePercent = 0
        const resumePercent = 0
        const coverLetterPercent = 0
        const interviewPercent = 0
        const politicalPercent = 0
        const homeStatePercent = 0
        const adversityScorePercent = 0
        const totalPercent = 0

        // const benchmarkHardSkills = []
        // const benchmarkSoftSkills = []

        const workInterestDescriptions = []
        const existingBenchmark = false

        const additionalTraits = []
        const highPriorityHardSkills = []
        const lowPriorityHardSkills = []
        const highPrioritySoftSkills = []
        const lowPrioritySoftSkills = []
        const skills = []

        const idealMajors = []
        const courses = []
        const certifications = []
        const experienceTags = []
        const projectTags = []
        const volunteerTags = []
        const interviewQuestions = []
        const interviewRubric = []

        // work preferences
        const workPreferenceFields = [
          { name: 'What is the maximum distance a candidate can be from an employer to be eligible for hire?', value: '', importance: '' },
          { name: 'What is the maximum amount your business is willing to pay (hourly) an entry-level role?', value: '', importance: '' },
          { name: 'What work functions would you like the candidate to be interested in', value: [], importance: '' },
          { name: 'What industries would you like the candidate to be interested in', value: [], importance: '' },
        ]

        // interests
        const workInterestFields = [
          { name: 'Realistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          { name: 'Investigative', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          { name: 'Artistic', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          { name: 'Social', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          { name: 'Enterprising', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
          { name: 'Conventional', url: 'https://en.wikipedia.org/wiki/Holland_Codes'},
        ]
        // const interests = [
        //   { title: 'Realistic', description: 'Prefers working with animals, tools, or machines. Values things you can see and touch. Sees themselves as practical, mechanical, and realistic.', score: 0, weight: 0 },
        //   { title: 'Investigative', description: 'Prefers studying and solving math and science problems. Values science. Sees themselves as precise, scientific, and intellectual.', score: 0, weight: 0 },
        //   { title: 'Artistic', description: 'Prefers creative activities like art, drama, music, and creative writing.  Values helping people and solving social problems. Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
        //   { title: 'Social', description: 'Prefers to do things to help people like teaching, nursing, or giving first aid. Values helping people, and solving societal problems.  Sees themselves as helpful, friendly, and trustworthy.', score: 0, weight: 0 },
        //   { title: 'Enterprising', description: 'Prefers to lead and persuade people. Values success in politics, leadership, or business. Sees themselves myself as energetic, ambitious, and sociable.', score: 0, weight: 0 },
        //   { title: 'Conventional', description: 'Prefers to work with numbers, records, or machines in a set, orderly way. Values success in business. Sees themselves as orderly, and good at following a set plan.', score: 0, weight: 0 },
        // ]

        // personality
        let personalityFields = [
         {name: '16 Personalities', url: 'https://www.16personalities.com/personality-types', value: '', importance: '' },
         {name: 'Openness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
         {name: 'Conscientiousness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
         {name: 'Extraversion', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
         {name: 'Agreeableness', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
         {name: 'Neuroticism (Inverse of Emotional Stability)', url: 'https://en.wikipedia.org/wiki/Big_Five_personality_traits'},
       ]

       // const traits = [
       //   { title: 'Openness', description: 'Appreciation for art, emotion, adventure, unusual ideas, curiosity, and variety of experience. Openness reflects the degree of intellectual curiosity, creativity and a preference for novelty and variety a person has.', score: 0, weight: 0 },
       //   { title: 'Conscientiousness', description: 'Tendency to be organized and dependable, show self-discipline, act dutifully, aim for achievement, and prefer planned rather than spontaneous behavior. High conscientiousness is often perceived as being stubborn and focused.', score: 0, weight: 0 },
       //   { title: 'Extraversion', description: 'Energetic, surgency, assertiveness, sociability and the tendency to seek stimulation in the company of others, and talkativeness. High extraversion is often perceived as attention-seeking and domineering.', score: 0, weight: 0 },
       //   { title: 'Agreeableness', description: "Tendency to be compassionate and cooperative rather than suspicious and antagonistic towards others. It is also a measure of one's trusting and helpful nature, and whether a person is generally well-tempered or not.", score: 0, weight: 0 },
       //   { title: 'Emotional Stability', description: 'Tendency to be immune to psychological stress. The tendency to manage unpleasant emotions easily, such as anger, anxiety, stress, depression, and vulnerability. A high degree of impulse control.', score: 0, weight: 0 },
       // ]

        // education
        const educationFields = [
          {name: 'Degree Requirements', value: '', importance: '' },
          {name: 'Ideal Majors', value: '', importance: ''},
          {name: 'GPA Percentile Range', value: '', importance: ''},
          {name: 'Grad Year Range', value: '', importance: ''},
          {name: 'Standardized Test Scores', value: '', importance: ''},
          { name: '# of Hours in Coursework', value: '', importance: ''},
          { name: 'Ideal Online Coursework Completed', value: '', importance: ''},
          { name: 'Ideal Certifications, Licenses, or Badges Completed', value: '', importance: '' }
        ]

        // projects
        const projectFields = [{ name: 'Minimum Number of Hours', value: '', importance: '' }]

        // experience
        const paidExperienceFields = [{ name: 'Minimum Number of Hours', value: '', importance: '' }]
        const volunteerFields = [{ name: 'Minimum Number of Hours', value: '', importance: '' }]

        // diversity
        const diversityFields = [
          {name: 'Gender', value: '', importance: ''},
          {name: 'Race', value: '', importance: ''},
          {name: 'Veteran', value: '', importance: ''},
          {name: 'Low Income (Household Income / Members)', value: '', importance: ''},
          {name: 'Foster Youth', value: '', importance: ''},
          {name: 'Homeless', value: '', importance: ''},
          {name: 'Incarcerated', value: '', importance: ''},
          {name: 'LGBTQIA', value: '', importance: ''},
          {name: 'Disability', value: '', importance: ''},
          {name: 'First Generation Immigrant', value: '', importance: ''},
          {name: 'First Generation College Student', value: '', importance: ''}
        ]

        // politics
        const politicalFields = [
          {name: 'Political Alignment', value: '', importance: ''},
          {name: 'Hometown', value: '', importance: ''},
          {name: 'Home Congressional District', value: '', importance: ''},
          {name: 'Current Town', value: '', importance: '' },
          {name: 'Current Congressional District', value: '', importance: '' },
        ]

        this.setState({ org, accountCode, emailId: email, username, cuFirstName, cuLastName,
          benchmarkCategories, interestOptions, gradYearRangeOptions, selectedApplication, selectedJob,

          preferencesPercent, interestsPercent, traitsPercent, valuesPercent, skillsPercent, thirdPartyAssessmentPercent,
          endorsementsPercent, educationPercent, projectsPercent, experiencePercent, resumePercent, coverLetterPercent,
          interviewPercent, politicalPercent, homeStatePercent, adversityScorePercent, totalPercent,


          workPreferenceFields, workInterestFields, personalityFields,
          educationFields, projectFields, paidExperienceFields, volunteerFields, diversityFields, politicalFields,

          workInterestDescriptions, existingBenchmark,

          additionalTraits,

          highPriorityHardSkills, lowPriorityHardSkills, highPrioritySoftSkills, lowPrioritySoftSkills, skills,

          idealMajors, courses, certifications,

          experienceTags, volunteerTags, projectTags,

          interviewQuestions, interviewRubric
        })
      }
    }

    translateValue(score, toWords) {
      console.log('translateValue called', score, toWords)

      //interests
      if (toWords) {
        let value = ''

        if (Number(score) >= 5) {
          value = 'Highly Interested'
        } else if (Number(score) >= 4) {
          value = 'Interested'
        } else if (Number(score) >= 3) {
          value = 'Somewhat Interested'
        } else if (Number(score) >= 2) {
          value = 'Uninterested'
        } else if (Number(score) >= 1) {
          value = 'Highly Uninterested'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        console.log('show score: ', score)
        let value = 0
        if (score === 'Highly Interested') {
          value = 5
        } else if (score === 'Interested') {
          value = 4
        } else if (score === 'Somewhat Interested') {
          value = 3
        } else if (score === 'Uninterested') {
          value = 2
        } else if (score === 'Highly Uninterested') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value
      }
    }

    translateWeight(weight, toWords) {
      console.log('translateWeight called ', weight, toWords)

      if (toWords) {
        let value = ''
        if (Number(weight) >= 4) {
          value = 'Super Important'
        } else if (Number(weight) >= 4) {
          value = 'Very Important'
        } else if (Number(weight) >= 3) {
          value = 'Important'
        } else if (Number(weight) >= 2) {
          value = 'Somewhat Important'
        } else if (Number(weight) >= 1) {
          value = 'Slightly Important'
        } else if (Number(weight) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0

        if (weight === 'Super Important') {
          value = 5
        } else if (weight === 'Very Important') {
          value = 4
        } else if (weight === 'Important') {
          value = 3
        } else if (weight === 'Somewhat Important') {
          value = 2
        } else if (weight === 'Slightly Important') {
          value = 1
        } else if (weight === 'Not a Factor') {
          value = 0
        }
        console.log('show score in translate: ', weight, value)
        return value
      }
    }

    translateTraits(score, toWords) {
      console.log('translateTraits called', score, toWords)

      if (toWords) {
        let value = ''
        if (Number(score) >= 5) {
          value = 'Very High'
        } else if (Number(score) >= 4) {
          value = 'High'
        } else if (Number(score) >= 3) {
          value = 'Moderate'
        } else if (Number(score) >= 2) {
          value = 'Low'
        } else if (Number(score) >= 1) {
          value = 'Very Low'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0
        if (score === 'Very High') {
          value = 5
        } else if (score === 'High') {
          value = 4
        } else if (score === 'Moderate') {
          value = 3
        } else if (score === 'Low') {
          value = 2
        } else if (score === 'Very Low') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value

      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      let selectedTemplate = this.props.selectedTemplate
      if (!selectedTemplate) {
        selectedTemplate = {}
      }

      console.log('show selectedTemplate: ', selectedTemplate)
      if (event.target.name === 'title') {
        const title = event.target.value
        selectedTemplate['title'] = title
        this.setState({ title, selectedTemplate })
      } else if (event.target.name === 'jobTitle') {
        const jobTitle = event.target.value
        selectedTemplate['jobTitle'] = jobTitle

        let selectedJob = this.state.selectedJob
        selectedJob['title'] = event.target.value

        this.setState({ jobTitle, selectedTemplate, selectedJob })
      } else if (event.target.name === 'jobType') {
        const jobType = event.target.value
        selectedTemplate['jobType'] = jobType
        this.setState({ jobType, selectedTemplate })
      } else if (event.target.name === 'industry') {
        const industry = event.target.value
        console.log('show industry: ', industry, event.target.value, selectedTemplate)
        selectedTemplate['industry'] = industry
        this.setState({ industry, selectedTemplate })
      } else if (event.target.name === 'jobFunction') {
        const jobFunction = event.target.value
        selectedTemplate['jobFunction'] = jobFunction

        let selectedJob = this.state.selectedJob
        selectedJob['workFunction'] = event.target.value

        this.setState({ jobFunction, selectedTemplate, selectedJob })
      } else if (event.target.name === 'pathway') {
        const pathway = event.target.value
        selectedTemplate['pathway'] = pathway

        let selectedJob = this.state.selectedJob
        selectedJob['pathway'] = event.target.value

        this.setState({ pathway, selectedTemplate })
      } else if (event.target.name === 'viewMode') {
        this.setState({ viewMode: event.target.value })
      } else if (event.target.name.includes("screeningQuestion|")) {
        let dealBreakers = this.state.dealBreakers
        dealBreakers[Number(event.target.name.split("|")[2])][event.target.name.split("|")[1]] = event.target.value
        this.setState({ dealBreakers })
      } else if (event.target.name.includes("thirdPartyAssessment|")) {
        let thirdPartyAssessments = this.state.thirdPartyAssessments
        thirdPartyAssessments[Number(event.target.name.split("|")[2])][event.target.name.split("|")[1]] = event.target.value
        this.setState({ thirdPartyAssessments })
      } else if (event.target.name.includes("answerChoice|")) {
        let dealBreakers = this.state.dealBreakers

        const name = event.target.name.split("|")[1]
        const parentIndex = Number(event.target.name.split("|")[2])
        // const questionIndex = Number(event.target.name.split("|")[3])
        const answerChoiceIndex = Number(event.target.name.split("|")[4])
        // console.log('everything: ', name, parentIndex, questionIndex, answerChoiceIndex)
        dealBreakers[parentIndex]['screeningAnswerChoices'][answerChoiceIndex][name] = event.target.value
        this.setState({ dealBreakers })
      } else if (event.target.name.includes("thirdPartyAssessmentAnswerChoice|")) {
        let thirdPartyAssessments = this.state.thirdPartyAssessments

        const name = event.target.name.split("|")[1]
        const parentIndex = Number(event.target.name.split("|")[2])
        // const questionIndex = Number(event.target.name.split("|")[3])
        const answerChoiceIndex = Number(event.target.name.split("|")[4])
        // console.log('everything: ', name, parentIndex, questionIndex, answerChoiceIndex)
        thirdPartyAssessments[parentIndex]['screeningAnswerChoices'][answerChoiceIndex][name] = event.target.value
        this.setState({ thirdPartyAssessments })


      // } else if (event.target.name === 'workPreferenceWeight') {
      //
      //   const workPreferenceWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', workPreferenceWeight],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', workPreferenceWeight],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ workPreferenceWeight, localAllocation })
      //
      // } else if (event.target.name.startsWith('interest')) {
      //
      //   let stringEnd = event.target.name.length
      //
      //   let interests = this.state.interests
      //   if (event.target.name.substring(8,13) === "score") {
      //     let index = Number(event.target.name.substring(13,stringEnd)) - 1
      //     interests[index] = { title: this.state.interests[index].title, description: this.state.interests[index].description, score: event.target.value, weight: this.state.interests[index].weight }
      //     this.setState({ interests })
      //   } else {
      //     let index = Number(event.target.name.substring(14,stringEnd)) - 1
      //     interests[index] = { title: this.state.interests[index].title, description: this.state.interests[index].description, score: this.state.interests[index].score, weight: event.target.value }
      //
      //     let interestWeighting = 0
      //     for (let i = 1; i <= interests.length; i++) {
      //       console.log(i);
      //
      //       let testIndex = i - 1
      //       if (testIndex === index) {
      //         interestWeighting = interestWeighting + Number(event.target.value)
      //       } else {
      //         interestWeighting = interestWeighting + Number(interests[testIndex].weight)
      //       }
      //     }
      //
      //     let localAllocation = []
      //     if (this.state.org === 'c2c') {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', interestWeighting],
      //         ['Personality', this.state.localAllocation[3][1]],
      //         ['Skills', this.state.localAllocation[4][1]],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Political Party', this.state.localAllocation[10][1]],
      //         ['Hometown', this.state.localAllocation[11][1]],
      //         ['Interview', this.state.localAllocation[12][1]]
      //       ]
      //     } else {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', interestWeighting],
      //         ['Personality', this.state.localAllocation[3][1]],
      //         ['Skills', this.state.localAllocation[4][1]],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Interview', this.state.localAllocation[10][1]]
      //       ]
      //     }
      //
      //     this.setState({ interests, localAllocation })
      //   }
      //
      // } else if (event.target.name.startsWith('skill')) {
      //   console.log('in skill')
      //
      //   let stringEnd = event.target.name.length
      //
      //   let skills = this.state.skills
      //   if (event.target.name.substring(5,9) === "name") {
      //     let index = Number(event.target.name.substring(9,stringEnd)) - 1
      //     skills[index] = { title: event.target.value, description: this.state.skills[index].description, skillType: this.state.skills[index].skillType, score: this.state.skills[index].score, weight: this.state.skills[index].weight }
      //     this.setState({ skills })
      //
      //     this.searchSkillTraits(event.target.value, skills[index].skillType, index)
      //
      //   } else if (event.target.name.substring(5,9) === "type") {
      //
      //     let index = Number(event.target.name.substring(9,stringEnd)) - 1
      //     skills[index] = { title: this.state.skills[index].title, description: this.state.skills[index].description, skillType: event.target.value, score: this.state.skills[index].score, weight: this.state.skills[index].weight }
      //     console.log('show me skills', skills)
      //     this.setState({ skills })
      //   } else if (event.target.name.substring(5,10) === "score") {
      //     let index = Number(event.target.name.substring(10,stringEnd)) - 1
      //     skills[index] = { title: this.state.skills[index].title, description: this.state.skills[index].description, skillType: this.state.skills[index].skillType, score: event.target.value, weight: this.state.skills[index].weight }
      //     this.setState({ skills })
      //   } else {
      //     let index = Number(event.target.name.substring(11,stringEnd)) - 1
      //     skills[index] = { title: this.state.skills[index].title, description: this.state.skills[index].description, skillType: this.state.skills[index].skillType, score: this.state.skills[index].score, weight: event.target.value }
      //
      //     let skillsWeighting = 0
      //     for (let i = 1; i <= skills.length; i++) {
      //       console.log(i);
      //
      //       let testIndex = i - 1
      //       if (testIndex === index) {
      //         skillsWeighting = skillsWeighting + Number(event.target.value)
      //       } else {
      //         skillsWeighting = skillsWeighting + Number(skills[testIndex].weight)
      //       }
      //     }
      //
      //     let localAllocation = []
      //     if (this.state.org === 'c2c') {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', this.state.localAllocation[2][1]],
      //         ['Personality', this.state.localAllocation[3][1]],
      //         ['Skills', skillsWeighting],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Political Party', this.state.localAllocation[10][1]],
      //         ['Hometown', this.state.localAllocation[11][1]],
      //         ['Interview', this.state.localAllocation[12][1]]
      //       ]
      //     } else {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', this.state.localAllocation[2][1]],
      //         ['Personality', this.state.localAllocation[3][1]],
      //         ['Skills', skillsWeighting],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Interview', this.state.localAllocation[10][1]]
      //       ]
      //     }
      //
      //     this.setState({ skills, localAllocation })
      //   }
      //
      // } else if (event.target.name.startsWith('trait')) {
      //   console.log('in trait')
      //
      //   let stringEnd = event.target.name.length
      //
      //   let traits = this.state.traits
      //   if (event.target.name.substring(5,10) === "score") {
      //     let index = Number(event.target.name.substring(10,stringEnd)) - 1
      //     traits[index] = { title: this.state.traits[index].title, description: this.state.traits[index].description, score: event.target.value, weight: this.state.traits[index].weight }
      //     this.setState({ traits })
      //   } else {
      //     let index = Number(event.target.name.substring(11,stringEnd)) - 1
      //     traits[index] = { title: this.state.traits[index].title, description: this.state.traits[index].description, score: this.state.traits[index].score, weight: event.target.value }
      //
      //     let traitsWeighting = 0
      //     for (let i = 1; i <= traits.length; i++) {
      //       console.log(i);
      //
      //       let testIndex = i - 1
      //       if (testIndex === index) {
      //         traitsWeighting = traitsWeighting + Number(event.target.value)
      //       } else {
      //         traitsWeighting = traitsWeighting + Number(traits[testIndex].weight)
      //       }
      //     }
      //
      //     let localAllocation = []
      //     if (this.state.org === 'c2c') {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', this.state.localAllocation[2][1]],
      //         ['Personality', traitsWeighting],
      //         ['Skills', this.state.localAllocation[4][1]],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Political Party', this.state.localAllocation[10][1]],
      //         ['Hometown', this.state.localAllocation[11][1]],
      //         ['Interview', this.state.localAllocation[12][1]]
      //       ]
      //     } else {
      //       localAllocation = [
      //         ['Component', 'Point Value'],
      //         ['Work Preferences', this.state.localAllocation[1][1]],
      //         ['Interests', this.state.localAllocation[2][1]],
      //         ['Personality', traitsWeighting],
      //         ['Skills', this.state.localAllocation[4][1]],
      //         ['Endorsements', this.state.localAllocation[5][1]],
      //         ['Education', this.state.localAllocation[6][1]],
      //         ['Projects', this.state.localAllocation[7][1]],
      //         ['Experience', this.state.localAllocation[8][1]],
      //         ['Adversity Score', this.state.localAllocation[9][1]],
      //         ['Interview', this.state.localAllocation[10][1]]
      //       ]
      //     }
      //
      //     this.setState({ traits, localAllocation })
      //   }
      // } else if (event.target.name === 'endorsementWeight') {
      //
      //   const endorsementWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', endorsementWeight],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', endorsementWeight],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ endorsementWeight, localAllocation })
      //
      // } else if (event.target.name === 'educationWeight') {
      //
      //   const educationWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', educationWeight],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', educationWeight],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ educationWeight, localAllocation })
      //
      // } else if (event.target.name === 'projectWeight') {
      //
      //   const projectWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', projectWeight],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', projectWeight],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ projectWeight, localAllocation })
      //
      // } else if (event.target.name === 'experienceWeight') {
      //
      //   const experienceWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', experienceWeight],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', experienceWeight],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ experienceWeight, localAllocation })
      //
      // } else if (event.target.name === 'adversityScoreWeight') {
      //
      //   const adversityScoreWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', adversityScoreWeight],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', adversityScoreWeight],
      //       ['Interview', this.state.localAllocation[10][1]]
      //     ]
      //   }
      //
      //   this.setState({ adversityScoreWeight, localAllocation })
      //
      // } else if (event.target.name.startsWith('politicalParty')) {
      //   console.log('politicalParty: ', this.state.politicalParties)
      //
      //   const nameArray = event.target.name.split("|")
      //
      //   let index = Number(nameArray[2]) - 1
      //   console.log('index: ', nameArray, index)
      //   let politicalParties = this.state.politicalParties
      //   politicalParties[index] = { title: this.state.politicalParties[index].title, description: this.state.politicalParties[index].description, score: 5, weight: event.target.value }
      //
      //   let politicalScoreWeight = 0
      //   for (let i = 1; i <= politicalParties.length; i++) {
      //     console.log(i);
      //
      //     let testIndex = i - 1
      //     if (testIndex === index) {
      //       politicalScoreWeight = politicalScoreWeight + Number(event.target.value)
      //     } else {
      //       if (politicalParties[testIndex].weight) {
      //         politicalScoreWeight = politicalScoreWeight + Number(politicalParties[testIndex].weight)
      //       }
      //     }
      //   }
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', politicalScoreWeight],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   }
      //
      //   this.setState({ politicalParties, politicalScoreWeight, localAllocation })
      //
      // } else if (event.target.name === 'hometownWeight') {
      //
      //   const hometownWeight = Number(event.target.value)
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', hometownWeight],
      //       ['Interview', this.state.localAllocation[12][1]]
      //     ]
      //   }
      //
      //   this.setState({ hometownWeight, localAllocation })
      //
      // } else if (event.target.name === 'interviewWeight') {
      //
      //   let interview = this.state.interview
      //   interview = { score: this.state.interview.score, weight: event.target.value }
      //
      //   let localAllocation = []
      //   if (this.state.org === 'c2c') {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Political Party', this.state.localAllocation[10][1]],
      //       ['Hometown', this.state.localAllocation[11][1]],
      //       ['Interview', Number(event.target.value)]
      //     ]
      //   } else {
      //     localAllocation = [
      //       ['Component', 'Point Value'],
      //       ['Work Preferences', this.state.localAllocation[1][1]],
      //       ['Interests', this.state.localAllocation[2][1]],
      //       ['Personality', this.state.localAllocation[3][1]],
      //       ['Skills', this.state.localAllocation[4][1]],
      //       ['Endorsements', this.state.localAllocation[5][1]],
      //       ['Education', this.state.localAllocation[6][1]],
      //       ['Projects', this.state.localAllocation[7][1]],
      //       ['Experience', this.state.localAllocation[8][1]],
      //       ['Adversity Score', this.state.localAllocation[9][1]],
      //       ['Interview', Number(event.target.value)]
      //     ]
      //   }
      //
      //   this.setState({ interview, localAllocation })
      } else if (event.target.name === 'preferencesPercent') {
        const diff = event.target.value - this.state.preferencesPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ preferencesPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'interestsPercent') {
        const diff = event.target.value - this.state.interestsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ interestsPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'traitsPercent') {
        const diff = event.target.value - this.state.traitsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ traitsPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'valuesPercent') {
        const diff = event.target.value - this.state.valuesPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ valuesPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'skillsPercent') {
        const diff = event.target.value - this.state.skillsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ skillsPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'thirdPartyAssessmentPercent') {
        const diff = event.target.value - this.state.thirdPartyAssessmentPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ thirdPartyAssessmentPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'endorsementsPercent') {
        const diff = event.target.value - this.state.endorsementsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ endorsementsPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'educationPercent') {
        const diff = event.target.value - this.state.educationPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ educationPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'projectsPercent') {
        const diff = event.target.value - this.state.projectsPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ projectsPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'experiencePercent') {
        const diff = event.target.value - this.state.experiencePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ experiencePercent: event.target.value, totalPercent })
      } else if (event.target.name === 'resumePercent') {
        const diff = event.target.value - this.state.resumePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ resumePercent: event.target.value, totalPercent })
      } else if (event.target.name === 'coverLetterPercent') {
        const diff = event.target.value - this.state.coverLetterPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ coverLetterPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'interviewPercent') {
        const diff = event.target.value - this.state.interviewPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ interviewPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'politicalPercent') {
        const diff = event.target.value - this.state.politicalPercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ politicalPercent: event.target.value, totalPercent })
      } else if (event.target.name === 'homeStatePercent') {
        const diff = event.target.value - this.state.homeStatePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ homeStatePercent: event.target.value, totalPercent })
      } else if (event.target.name === 'adversityScorePercent') {
        const diff = event.target.value - this.state.adversityScorePercent
        const totalPercent = this.state.totalPercent + diff
        this.setState({ adversityScorePercent: event.target.value, totalPercent })
      } else if (event.target.name.includes('diversity')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let diversityFields = this.state.diversityFields
        diversityFields[index][field] = event.target.value
        this.setState({ diversityFields })
      } else if (event.target.name.includes('politics')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let politicalFields = this.state.politicalFields
        politicalFields[index][field] = event.target.value
        this.setState({ politicalFields })
      } else if (event.target.name.includes('workPreferences|')) {
        // console.log('show name and value: ', event.target.name, event.target.value)

        let nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let workPreferenceFields = this.state.workPreferenceFields
        workPreferenceFields[index][field] = event.target.value
        this.setState({ workPreferenceFields })
      } else if (event.target.name.includes('workInterests|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let workInterestFields = this.state.workInterestFields
        workInterestFields[index][field] = event.target.value
        this.setState({ workInterestFields })
      } else if (event.target.name.includes('personality|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let personalityFields = this.state.personalityFields
        personalityFields[index][field] = event.target.value
        this.setState({ personalityFields })

      } else if (event.target.name.includes('education|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let educationFields = this.state.educationFields
        educationFields[index][field] = event.target.value
        this.setState({ educationFields })
      } else if (event.target.name === 'idealMajor') {
        this.setState({ idealMajor: event.target.value })
      } else if (event.target.name.includes('course|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        let course = this.state.course
        course[field] = event.target.value
        this.setState({ course })
      } else if (event.target.name.includes('certification|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        let certification = this.state.certification
        certification[field] = event.target.value
        this.setState({ certification })
      } else if (event.target.name.includes('project|')) {
         const nameArray = event.target.name.split("|")
         const field = nameArray[1]
         const index = nameArray[2]

         let projectFields = this.state.projectFields
         projectFields[index][field] = event.target.value
         this.setState({ projectFields })

      } else if (event.target.name === 'volunteerTag') {
         this.setState({ volunteerTag: event.target.value })
       } else if (event.target.name === 'volunteerTagsImportance') {
          this.setState({ volunteerTagsImportance: event.target.value })
        } else if (event.target.name === 'experienceTag') {
           this.setState({ experienceTag: event.target.value })
      } else if (event.target.name === 'experienceTagsImportance') {
         this.setState({ experienceTagsImportance: event.target.value })
      } else if (event.target.name.includes('experience|')) {

        if (!event.target.name.includes('experienceTags')) {
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]
          const index = nameArray[2]

          let paidExperienceFields = this.state.paidExperienceFields
          paidExperienceFields[index][field] = event.target.value
          this.setState({ paidExperienceFields })
        }

      } else if (event.target.name.includes('volunteer|')) {
        if (!event.target.name.includes('volunteerTags')) {
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]
          const index = nameArray[2]

          let volunteerFields = this.state.volunteerFields
          volunteerFields[index][field] = event.target.value
          this.setState({ volunteerFields })
        }
      } else if (event.target.name.includes('interviewQuestion|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        let interviewQuestion = this.state.interviewQuestion
        interviewQuestion[field] = event.target.value
        this.setState({ interviewQuestion })
      } else if (event.target.name === 'guidances') {
        let guidances = this.state.guidances
        guidances[this.state.interviewGrade] = event.target.value
        this.setState({ guidances })
      } else if (event.target.name === 'exampleAnswers') {
        let exampleAnswers = this.state.exampleAnswers
        exampleAnswers[this.state.interviewGrade] = event.target.value
        this.setState({ exampleAnswers })
      } else if (event.target.name.includes('criteria|')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = nameArray[2]

        let interviewRubric = this.state.interviewRubric
        if (field === 'guidances' || field === 'exampleAnswers') {
          const secondIndex = nameArray[3]
          let tempArray = interviewRubric[index][field]
          console.log('show tempArray: ', tempArray, interviewRubric)

          tempArray[secondIndex] = event.target.value
          // if (tempArray) {
          //   tempArray[secondIndex] = event.target.value
          // } else {
          //   tempArray = [event.target.value]
          // }
        } else {
          interviewRubric[index][field] = event.target.value
        }

        this.setState({ interviewRubric })
      } else if (event.target.name.includes('interviewRubric|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let interviewRubric = this.state.interviewRubric
        interviewRubric[index][name] = event.target.value
        this.setState({ interviewRubric })
      } else if (event.target.name === 'employerName') {

        const employerName = event.target.value

        let accountCode = ''
        if (employerName && employerName !== '') {
          let trimmedName = employerName.trim()
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }

        this.setState({ employerName, accountCode })
        this.searchEmployers(employerName)
      } else if (event.target.name === 'highPriorityHardSkill') {
        this.setState({ highPriorityHardSkill: event.target.value })
        this.searchCompetencies(event.target.value, 'All', event.target.name)
      } else if (event.target.name === 'lowPriorityHardSkill') {
        this.setState({ lowPriorityHardSkill: event.target.value })
        this.searchCompetencies(event.target.value, 'All', event.target.name)
      } else if (event.target.name === 'highPrioritySoftSkill') {
        this.setState({ highPrioritySoftSkill: event.target.value })
        this.searchCompetencies(event.target.value, 'All', event.target.name)
      } else if (event.target.name === 'lowPrioritySoftSkill') {
        this.setState({ lowPrioritySoftSkill: event.target.value })
        this.searchCompetencies(event.target.value, 'All', event.target.name)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      this.setState({ loadingEmployerOptions: true })
      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/account/search', { params: { employerName } })
        .then((response) => {
          console.log('Employer search query attempted', response.data);

            if (response.data.success) {
              console.log('employer search query worked')

              const employerOptions = response.data.employers
              self.setState({ employerOptions, loadingEmployerOptions: false });

            } else {
              console.log('employer search query did not work', response.data.message)
              this.setState({ loadingEmployerOptions: false })
            }

        }).catch((error) => {
            console.log('Employer search query did not work for some reason', error);
            this.setState({ loadingEmployerOptions: false })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employerName = employer.employerName
      const employerOptions = []
      const employerLocation = employer.employerLocation
      const employerURL = employer.employerURL
      const employerType = employer.employerType
      const employerIndustry = employer.employerIndustry
      const industries = employer.industries
      const employeeCount = employer.employeeCount
      const accountCode = employer.accountCode
      const sharePartners = employer.sharePartners
      const contactEmail = employer.contactEmail
      const contactPhone = employer.contactPhone
      const orgContacts = employer.orgContacts
      const referrerName = employer.referrerName
      const employerContactEmail = contactEmail
      const employerDescription = employer.description
      const employerCulture = employer.employerCulture
      const employerLogoURL = employer.employerLogoURI

      this.setState({ employerName, employerOptions, employerLocation, employerURL, employerType, employerIndustry,
        industries,
        employeeCount, accountCode, sharePartners, contactEmail, contactPhone, orgContacts, referrerName,
        employerContactEmail, employerDescription, employerCulture, employerLogoURL })
    }

    searchCompetencies(competency, type, competencyType) {
      console.log('searchCompetencies ', competency, type, competencyType)

      if (competencyType === 'highPriorityHardSkill') {
        this.setState({ loadingHighPriorityHardSkillOptions: true })
      } else if (competencyType === 'lowPriorityHardSkill') {
        this.setState({ loadingLowPriorityHardSkillOptions: true })
      } else if (competencyType === 'highPrioritySoftSkill') {
        this.setState({ loadingHighPrioritySoftSkillOptions: true })
      } else if (competencyType === 'lowPrioritySoftSkill') {
        this.setState({ loadingLowPrioritySoftSkillOptions: true })
      }

      if (type === 'Knowledge') {
        console.log('this is an error. We are not turning on this functionality now')
        this.setState({ loadingHighPriorityHardSkillOptions: false, loadingLowPriorityHardSkillOptions: false,
          loadingHighPrioritySoftSkillOptions: false, loadingLowPrioritySoftSkillOptions: false
        })
      } else {
        if (competency === '') {
          let highPriorityHardSkillOptions = null
          let lowPriorityHardSkillOptions = null
          let highPrioritySoftSkillOptions = null
          let lowPrioritySoftSkillOptions = null
          this.setState({ highPriorityHardSkillOptions, lowPriorityHardSkillOptions,
            highPrioritySoftSkillOptions, lowPrioritySoftSkillOptions,
            loadingHighPriorityHardSkillOptions: false, loadingLowPriorityHardSkillOptions: false,
            loadingHighPrioritySoftSkillOptions: false, loadingLowPrioritySoftSkillOptions: false
          })
        } else {

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            Axios.get('/api/competency/search', { params: { searchString: competency, type } })
            .then((response) => {
              console.log('Competency search query attempted', response.data);

                if (response.data.success) {
                  console.log('competency search query worked')

                  let highPriorityHardSkillOptions = null
                  let lowPriorityHardSkillOptions = null
                  let highPrioritySoftSkillOptions = null
                  let lowPrioritySoftSkillOptions = null
                  if (competencyType === 'highPriorityHardSkill') {
                    highPriorityHardSkillOptions = response.data.competencies
                  } else if (competencyType === 'lowPriorityHardSkill') {
                    lowPriorityHardSkillOptions = response.data.competencies
                  } else if (competencyType === 'highPrioritySoftSkill') {
                    highPrioritySoftSkillOptions = response.data.competencies
                  } else if (competencyType === 'lowPrioritySoftSkill') {
                    lowPrioritySoftSkillOptions = response.data.competencies
                  }
                  self.setState({ highPriorityHardSkillOptions, lowPriorityHardSkillOptions,
                    highPrioritySoftSkillOptions, lowPrioritySoftSkillOptions,
                    loadingHighPriorityHardSkillOptions: false, loadingLowPriorityHardSkillOptions: false,
                    loadingHighPrioritySoftSkillOptions: false, loadingLowPrioritySoftSkillOptions: false
                  });

                } else {
                  console.log('competency search query did not work', response.data.message)
                  self.setState({ loadingHighPriorityHardSkillOptions: false, loadingLowPriorityHardSkillOptions: false,
                    loadingHighPrioritySoftSkillOptions: false, loadingLowPrioritySoftSkillOptions: false
                  })
                }

            }).catch((error) => {
                console.log('Skilltrait search query did not work for some reason', error);
                self.setState({ loadingHighPriorityHardSkillOptions: false, loadingLowPriorityHardSkillOptions: false,
                  loadingHighPrioritySoftSkillOptions: false, loadingLowPrioritySoftSkillOptions: false
                })
            });

          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();

        }
      }
    }

    competencyClicked(index, optionIndex, type) {
      console.log('competencyClicked')

      if (type === 'highPriorityHardSkill') {
        let highPriorityHardSkill = this.state.highPriorityHardSkillOptions[optionIndex].name
        let highPriorityHardSkillOptions = []
        this.setState({ highPriorityHardSkill, highPriorityHardSkillOptions })
      } else if (type === 'lowPriorityHardSkill') {
        let lowPriorityHardSkill = this.state.lowPriorityHardSkillOptions[optionIndex].name
        let lowPriorityHardSkillOptions = []
        this.setState({ lowPriorityHardSkill, lowPriorityHardSkillOptions })
      } else if (type === 'highPrioritySoftSkill') {
        let highPrioritySoftSkill = this.state.highPrioritySoftSkillOptions[optionIndex].name
        let highPrioritySoftSkillOptions = []
        this.setState({ highPrioritySoftSkill, highPrioritySoftSkillOptions })
      } else if (type === 'lowPrioritySoftSkill') {
        let lowPrioritySoftSkill = this.state.lowPrioritySoftSkillOptions[optionIndex].name
        let lowPrioritySoftSkillOptions = []
        this.setState({ lowPrioritySoftSkill, lowPrioritySoftSkillOptions })
      }
    }

    saveBenchmark() {
      console.log('saveBenchmark clicked');

      this.setState({ isSaving: true, clientErrorMessage: '' })

      if (this.state.viewMode === 'Profile Card') {
        this.saveCustomBenchmark()
      } else if (this.state.viewMode === 'List') {
        if (this.state.formHasChanged === false) {
          this.setState({ clientErrorMessage: 'no changes detected. please update and then save.', isSaving: false })
        } else if (this.state.totalPercent !== 100) {
          this.setState({ clientErrorMessage: 'Please adjust percentages to equal 100%', isSaving: false })
         } else if (this.state.title === '') {
          this.setState({ clientErrorMessage: 'please title the benchmark', isSaving: false })
        } else if (this.state.jobTitle === '') {
          this.setState({ clientErrorMessage: 'please add an associated job title', isSaving: false })
        } else if (this.state.jobFunction === '') {
          this.setState({ clientErrorMessage: 'please add a job function', isSaving: false })
        } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
          this.setState({ clientErrorMessage: 'Please add your first name', isSaving: false })
        } else if (!this.state.cuLastName || this.state.cuLastName === '') {
          this.setState({ clientErrorMessage: 'Please add your last name', isSaving: false })
        } else if (!this.state.emailId || this.state.emailId === '') {
          this.setState({ clientErrorMessage: 'Please add your email', isSaving: false })
        } else if (window.location.pathname.includes('/create') && (!this.state.cuJobTitle || this.state.cuJobTitle === '')) {
          this.setState({ clientErrorMessage: 'Please add your job title', isSaving: false })
        } else if (window.location.pathname.includes('/create') && (!this.state.employerName || this.state.employerName === '')) {
          this.setState({ clientErrorMessage: 'Please add your employer name', isSaving: false })
        } else {

          // console.log('show essentials: ', this.state.cuFirstName, this.state.cuLastName, this.state.emailId)

          //general
          let _id = this.state._id
          if (_id === '') {
            _id = null
          }
          console.log('show id: ', _id, this.state._id)

          let title = this.state.title
          let jobTitle = this.state.jobTitle
          let jobFunction = this.state.jobFunction
          let industry = this.state.industry
          let pathway = this.state.pathway
          let imageURL = this.state.orgLogo
          if (this.state.employerLogoURL) {
            imageURL = this.state.employerLogoURL
          }

          //weights
          const workPreferenceWeight = this.state.preferencesPercent
          const interestsWeight = this.state.interestsPercent
          const personalityWeight = this.state.traitsPercent
          const valuesWeight = this.state.valuesPercent
          const skillsWeight = this.state.skillsPercent
          const thirdPartyAssessmentWeight = this.state.thirdPartyAssessmentPercent
          const endorsementWeight = this.state.endorsementsPercent
          const educationWeight = this.state.educationPercent
          const projectWeight = this.state.projectsPercent
          const experienceWeight = this.state.experiencePercent
          const resumeWeight = this.state.resumePercent
          const coverLetterWeight = this.state.coverLetterPercent
          const interviewWeight = this.state.interviewPercent
          const adversityScoreWeight = this.state.adversityScorePercent
          const politicalAlignmentWeight = this.state.politicalALignmentPercent
          const hometownWeight = this.state.homeStatePercent
          // console.log('show first half weights: ', workPreferenceWeight, interestsWeight, personalityWeight, skillsWeight, endorsementWeight, educationWeight)
          // console.log('show second half weights: ', projectWeight, experienceWeight, interviewWeight, adversityScoreWeight, politicalAlignmentWeight, hometownWeight)

          //importance
          const proximityRequirementsImportance = this.state.workPreferenceFields[0].importance
          const maxPayImportance = this.state.workPreferenceFields[1].importance
          const functionsOfInterestImportance = this.state.workPreferenceFields[2].importance
          const industriesOfInterestImportance = this.state.workPreferenceFields[3].importance

          let technologyTrendsImportance = undefined
          if (this.state.workPreferenceFields[4]) {
            technologyTrendsImportance = this.state.workPreferenceFields[4].importance
          }
          let additionalTechnologyTrendsImportance = undefined
          if (this.state.workPreferenceFields[5]) {
            additionalTechnologyTrendsImportance = this.state.workPreferenceFields[5].importance
          }
          let societalProblemsImportance = undefined
          if (this.state.workPreferenceFields[6]) {
            societalProblemsImportance = this.state.workPreferenceFields[6].importance
          }
          let additionalSocietalProblemsImportance = undefined
          if (this.state.workPreferenceFields[7]) {
            additionalSocietalProblemsImportance = this.state.workPreferenceFields[7].importance
          }

          const generalInterestsImportance = this.state.generalInterestsImportance
          const myersBriggsImportance = this.state.personalityFields[0].importance
          const additionalTraitsImportance = this.state.additionalTraitsImportance

          const degreeRequirementsImportance = this.state.educationFields[0].importance
          const idealMajorsImportance = this.state.educationFields[1].importance
          const gpaRangeImportance = this.state.educationFields[2].importance
          const gradYearRangeImportance = this.state.educationFields[3].importance
          const testScoresImportance = this.state.educationFields[4].importance
          const courseHoursImportance = this.state.educationFields[5].importance
          const coursesImportance = this.state.educationFields[6].importance
          const certificationsImportance = this.state.educationFields[7].importance

          // const schoolName = this.state.schoolName
          // const gradYear = this.state.gradYear
          // const major = this.state.major

          const projectHoursImportance = this.state.projectFields[0].importance
          const projectTagsImportance = this.state.projectTagsImportance
          const experienceHoursImportance = this.state.paidExperienceFields[0].importance
          const experienceTagsImportance = this.state.experienceTagsImportance
          const volunteerHoursImportance = this.state.volunteerFields[0].importance
          const volunteerTagsImportance = this.state.volunteerTagsImportance

          const genderImportance = this.state.diversityFields[0].importance
          const raceImportance = this.state.diversityFields[1].importance
          const veteranImportance = this.state.diversityFields[2].importance
          const lowIncomeImportance = this.state.diversityFields[3].importance
          const fosterYouthImportance = this.state.diversityFields[4].importance
          const homelessImportance = this.state.diversityFields[5].importance
          const incarceratedImportance = this.state.diversityFields[6].importance
          const lgbtqiaImportance = this.state.diversityFields[7].importance
          const disabilityImportance = this.state.diversityFields[8].importance
          const firstGenImmigrantImportance = this.state.diversityFields[9].importance
          const firstGenCollegeStudentImportance = this.state.diversityFields[10].importance

          // console.log('show politicalFields: ', this.state.politicalFields)
          const politicalAlignmentImportance = this.state.politicalFields[0].importance
          const hometownImportance = this.state.politicalFields[1].importance
          const homeCongressionalDistrictImportance = this.state.politicalFields[2].importance
          const currentTownImportance = this.state.politicalFields[3].importance
          const currentCongressionalDistrictImportance = this.state.politicalFields[4].importance

          //translate interests, traits, and skills importance


          // const proximityRequirementsImportance = this.state.workPreferenceFields[0].importance
          // const myersBriggsImportance = this.state.personalityFields[0].importance

          //work preferences
          const proximityRequirements = this.state.workPreferenceFields[0].value
          const maxPay = this.state.workPreferenceFields[1].value
          const functionsOfInterest = this.state.workPreferenceFields[2].value
          const industriesOfInterest = this.state.workPreferenceFields[3].value
          const technologyTrends = this.state.technologyTrends
          const additionalTechnologyTrends = this.state.additionalTechnologyTrends
          const societalProblems = this.state.societalProblems
          const additionalSocietalProblems = this.state.additionalSocietalProblems
          const workPreferencesMessage = this.state.workPreferencesMessage

          //interests
          let interests = [] //oldSchool, score + weight
          let interestWeights = []
          let interestWeightsTotal = 0
          const workInterestFields = this.state.workInterestFields
          for (let i = 1; i <= this.state.workInterestFields.length; i++) {
            const title = this.state.workInterestFields[i - 1].name
            const description = this.state.workInterestDescriptions[i - 1]
            const score = this.translateValue(this.state.workInterestFields[i - 1].value, false)
            const weight = this.translateWeight(this.state.workInterestFields[i - 1].importance, false)
            interestWeights.push(weight)
            interestWeightsTotal = interestWeightsTotal + weight
            interests.push({title, description, score, weight})
            // console.log('show iweight 1 : ', weight)
          }
          //adjust interest weights
          for (let i = 1; i <= interestWeights.length; i++) {
            // console.log('i, interest', interestWeights[i - 1], interestWeightsTotal, interestsWeight)
            if (interestWeightsTotal) {
              let adjustedWeight = (interestWeights[i - 1] / interestWeightsTotal) * interestsWeight
              console.log('show aw: ', adjustedWeight)

              interests[i - 1]['weight'] = adjustedWeight
            }
            // console.log('show iweight 2: ', interests[i - 1].weight)
          }

          // console.log('show interests: ', interests)

          const generalInterests = this.state.generalInterests
          const workInterests = this.state.workInterests
          const passions = this.state.passions
          const interestsMessage = this.state.interestsMessage

          //personality
          let traits = [] //oldSchool, score + weight
          let traitWeights = []
          let traitWeightsTotal = 0
          const personalityFields = this.state.personalityFields
          for (let i = 1; i <= this.state.personalityFields.length; i++) {

            const addedIndex = i
            if (this.state.personalityFields[addedIndex]) {
              const title = this.state.personalityFields[addedIndex].name
              const description = ''
              const score = this.translateTraits(this.state.personalityFields[addedIndex].value, false)
              const weight = this.translateWeight(this.state.personalityFields[addedIndex].importance, false)
              console.log('show trait weight 1: ', addedIndex, weight, this.state.personalityFields[addedIndex].importance)
              traitWeights.push(weight)
              traitWeightsTotal = traitWeightsTotal + weight
              traits.push({title, description, score, weight})
            }
          }
          //adjust traits weights
          for (let i = 1; i <= traitWeights.length; i++) {
            console.log('i, trait', traitWeights[i - 1], traits[i - 1].weight)
            if (traitWeightsTotal) {
              let adjustedWeight = (traitWeights[i - 1] / traitWeightsTotal) * personalityWeight
              traits[i - 1]['weight'] = adjustedWeight
            }
            console.log('show trait weight 2: ', i - 1, traits[i - 1].weight, traitWeights[i - 1], traitWeightsTotal, (traitWeights[i - 1] / traitWeightsTotal) * personalityWeight)
          }

          const myersBriggs = this.state.personalityFields[0].value
          const fiveFactors = this.state.fiveFactors
          const additionalTraits = this.state.additionalTraits
          const personalityMessage = this.state.personalityMessage

          //values
          const gravitateValues = this.state.gravitateValues
          const employerValues = this.state.employerValues
          const valuesMessage = this.state.valuesMessage

          //skills
          const highPriorityHardSkills = this.state.highPriorityHardSkills
          const lowPriorityHardSkills = this.state.lowPriorityHardSkills
          const highPrioritySoftSkills = this.state.highPrioritySoftSkills
          const lowPrioritySoftSkills = this.state.lowPrioritySoftSkills
          let skills = [] //oldSchool, score + weight
          const skillsMessage = this.state.skillsMessage

          let highPriorityVar = 2 * (highPriorityHardSkills.length + highPrioritySoftSkills.length)
          let lowPriorityVar = lowPriorityHardSkills.length + lowPrioritySoftSkills.length
          let combinedVar = lowPriorityVar + highPriorityVar

          console.log('show calc: ', highPriorityVar, lowPriorityVar, combinedVar)
          for (let i = 1; i <= highPriorityHardSkills.length; i++) {
            const title = highPriorityHardSkills[i - 1]
            const description = ''
            const skillType = 'Hard Skill'
            const score = 5
            const weight = (skillsWeight / combinedVar ) * 2
            skills.push({title, description, skillType, score, weight})
          }

          for (let i = 1; i <= lowPriorityHardSkills.length; i++) {
            const title = lowPriorityHardSkills[i - 1]
            const description = ''
            const skillType = 'Hard Skill'
            const score = 5
            const weight = (skillsWeight / combinedVar )
            skills.push({title, description, skillType, score, weight})
          }

          for (let i = 1; i <= highPrioritySoftSkills.length; i++) {
            const title = highPrioritySoftSkills[i - 1]
            const description = ''
            const skillType = 'Soft Skill'
            const score = 5
            const weight = (skillsWeight / combinedVar ) * 2
            skills.push({title, description, skillType, score, weight})
          }

          for (let i = 1; i <= lowPrioritySoftSkills.length; i++) {
            const title = lowPrioritySoftSkills[i - 1]
            const description = ''
            const skillType = 'Soft Skill'
            const score = 5
            const weight = (skillsWeight / combinedVar )
            skills.push({title, description, skillType, score, weight})
          }

          // let skillsTotal = 0
          // for (let i = 1; i <= skills.length; i++) {
          //   console.log('show weight: ', skills[i - 1])
          //   skillsTotal = skillsTotal + Number(skills[i - 1].weight)
          // }
          //
          // console.log('compare skillsTotals: ', skillsTotal, skillsWeight)
          const endorsementsMessage = this.state.endorsementsMessage

          //education
          const degreeRequirements = this.state.educationFields[0].value
          const idealMajors = this.state.idealMajors
          const gpaRange = this.state.educationFields[2].value
          const gradYearRange = this.state.educationFields[3].value
          const testScores = this.state.educationFields[4].value
          const courseHours = this.state.educationFields[5].value
          const courses = this.state.courses
          const certifications = this.state.certifications
          const schoolName = this.state.schoolName
          const gradYear = this.state.gradYear
          const major = this.state.major
          const educationMessage = this.state.educationMessage

          //projects
          const projectHours = this.state.projectFields[0].value
          const projectTags = this.state.projectTags
          const projectsMessage = this.state.projectsMessage

          //experience
          const experienceHours = this.state.paidExperienceFields[0].value
          const experienceTags = this.state.experienceTags
          const volunteerHours = this.state.volunteerFields[0].value
          const volunteerTags = this.state.volunteerTags
          const experienceMessage = this.state.experienceMessage

          const resumeMessage = this.state.resumeMessage
          const coverLetterMessage = this.state.coverLetterMessage

          //interview
          const strongFitCues = this.state.strongFitCues
          const poorFitCues = this.state.poorFitCues
          const interviewQuestions = this.state.interviewQuestions
          const interviewRubric = this.state.interviewRubric
          const interview = { score: 5, weight: interviewWeight }  //oldSchool
          const interviewMessage = this.state.interviewMessage

          //diversity preferences
          const gender = this.state.diversityFields[0].value
          const race = this.state.diversityFields[1].value
          const veteran = this.state.diversityFields[2].value
          const lowIncome = this.state.diversityFields[3].value
          const fosterYouth = this.state.diversityFields[4].value
          const homeless = this.state.diversityFields[5].value
          const incarcerated = this.state.diversityFields[6].value
          const lgbtqia = this.state.diversityFields[7].value
          const disability = this.state.diversityFields[8].value
          const firstGenImmigrant = this.state.diversityFields[9].value
          const firstGenCollegeStudent = this.state.diversityFields[10].value
          const adversityScoreMessage = this.state.adversityScoreMessage

          //politics
          // const politicalParties = this.state.politicalParties
          const politicalAlignment = this.state.politicalFields[0].value
          const hometown = this.state.politicalFields[1].value
          const homeCongressionalDistrict = this.state.politicalFields[2].value
          const currentTown = this.state.politicalFields[3].value
          const currentCongressionalDistrict = this.state.politicalFields[4].value

          const dealBreakers = this.state.dealBreakers
          const thirdPartyAssessments = this.state.thirdPartyAssessments
          const thirdPartyAssessmentsMessage = this.state.thirdPartyAssessmentsMessage

          let orgCode = this.state.org
          if (!orgCode) {
            orgCode = 'guidedcompass'
          }

          let accountCode = this.state.accountCode
          if (this.state.employerName && !this.state.accountCode) {
            accountCode = this.state.employerName.trim().replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
          }

          const pathwayLevel = this.state.pathwayLevel
          const approved = this.state.approved
          const firstName = this.state.cuFirstName
          const lastName = this.state.cuLastName
          const email = this.state.emailId
          const cuJobTitle = this.state.cuJobTitle
          const employerName = this.state.employerName
          const employerURL = this.state.employerURL
          let requestMode = false
          if (window.location.pathname.includes('/create')) {
            requestMode = true
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          const benchmarkObject = {
            _id, title, jobTitle, jobFunction, industry, pathway, imageURL,
            pathwayLevel, approved, firstName, lastName, email, cuJobTitle, employerName, employerURL,
            workPreferenceWeight, interestsWeight, personalityWeight, valuesWeight, skillsWeight, thirdPartyAssessmentWeight, endorsementWeight, educationWeight,
            projectWeight, experienceWeight, resumeWeight, coverLetterWeight, interviewWeight, adversityScoreWeight, politicalAlignmentWeight, hometownWeight,

            proximityRequirementsImportance, maxPayImportance, functionsOfInterestImportance, industriesOfInterestImportance,
            technologyTrendsImportance, additionalTechnologyTrendsImportance, societalProblemsImportance, additionalSocietalProblemsImportance,
            generalInterestsImportance, myersBriggsImportance, additionalTraitsImportance, degreeRequirementsImportance, idealMajorsImportance,
            gpaRangeImportance, gradYearRangeImportance, testScoresImportance, courseHoursImportance, coursesImportance,
            certificationsImportance, projectHoursImportance, experienceHoursImportance, volunteerHoursImportance,
            projectTagsImportance, experienceTagsImportance, volunteerTagsImportance,
            genderImportance,
            raceImportance, veteranImportance, lowIncomeImportance, fosterYouthImportance, homelessImportance, incarceratedImportance,
            lgbtqiaImportance, disabilityImportance, firstGenImmigrantImportance, firstGenCollegeStudentImportance,
            politicalAlignmentImportance, hometownImportance, homeCongressionalDistrictImportance, currentTownImportance,
            currentCongressionalDistrictImportance,

            proximityRequirements, maxPay, functionsOfInterest, industriesOfInterest,
            technologyTrends, additionalTechnologyTrends, societalProblems, additionalSocietalProblems,
            workInterestFields, personalityFields,
            generalInterests, workInterests, passions, interests, myersBriggs, fiveFactors, additionalTraits, traits,
            gravitateValues, employerValues,
            highPriorityHardSkills, lowPriorityHardSkills, highPrioritySoftSkills, lowPrioritySoftSkills, skills,
            degreeRequirements, idealMajors, gpaRange, gradYearRange, testScores, courseHours, courses, certifications, schoolName,
            gradYear, major,
            projectHours, projectTags, experienceHours, experienceTags, volunteerHours, volunteerTags,
            interviewQuestions, strongFitCues, poorFitCues, interviewRubric, interview,
            gender, race, veteran, lowIncome, fosterYouth, homeless, incarcerated, lgbtqia, disability, firstGenImmigrant, firstGenCollegeStudent,
            politicalAlignment, hometown, homeCongressionalDistrict, currentTown, currentCongressionalDistrict,
            dealBreakers, thirdPartyAssessments,

            workPreferencesMessage, interestsMessage, personalityMessage, valuesMessage, skillsMessage, thirdPartyAssessmentsMessage,
            endorsementsMessage, educationMessage, projectsMessage, experienceMessage, resumeMessage, coverLetterMessage,
            interviewMessage, adversityScoreMessage,

            orgCode, accountCode, requestMode, createdAt, updatedAt
          }

          // console.log('show benchmarkObject: ', benchmarkObject.interestsWeight, interests)

          Axios.post('/api/benchmarks', benchmarkObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Benchmark save worked', response.data);

              if (!_id) {
                title = ''
                jobTitle = ''
                jobFunction = ''
                industry = ''
                pathway = ''
              }

              this.setState({
                isSaving: false, serverPostSuccess: true,
                serverSuccessMessage: 'Benchmark saved successfully!',
                title, jobTitle, jobFunction, industry, pathway,
              })

              if (this.props.duplicate && this.props.navigate.goBack) {
                this.props.navigate(-1)
              }

            } else {
              console.error('there was an error saving the benchmark');

              this.setState({
                isSaving: false, serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Benchmark save did not work', error);
              this.setState({
                isSaving: false, serverPostSuccess: false,
                serverErrorMessage: error.toString(),
              })
          });
        }
      }
    }

    saveCustomBenchmark = () => {
      this.child.current.saveCustomBenchmark();
    };

    handleScroll(event) {

      // if (event.target.scrollLeft + event.target.clientWidth === event.target.scrollWidth) {
      //   console.log('end of scroll')
      // } else if (event.target.scrollLeft < 3) {
      //   console.log('beginning of scroll')
      // }
    }

    renderTags(type, passedArray) {
      console.log('renderTags ', type, passedArray)

      if (passedArray) {
        return (
          <div key={type + "|0"}>
            <div className="spacer" />
            {passedArray.map((value, optionIndex) =>
              <div key={type + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(type, optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}


          </div>
        )
      }
    }

    removeTag(type, index, parentIndex) {
      console.log('removeTag called', type, index, parentIndex)

      if (type === 'generalInterests') {
        let generalInterests = this.state.generalInterests
        generalInterests.splice(index, 1)
        this.setState({ generalInterests })
      } else if (type === 'additionalTraits') {
        let additionalTraits = this.state.additionalTraits
        additionalTraits.splice(index, 1)
        this.setState({ additionalTraits })
      } else if (type === 'highPriorityHardSkills') {
        let highPriorityHardSkills = this.state.highPriorityHardSkills
        highPriorityHardSkills.splice(index, 1)
        this.setState({ highPriorityHardSkills })
      } else if (type === 'lowPriorityHardSkills') {
        let lowPriorityHardSkills = this.state.lowPriorityHardSkills
        lowPriorityHardSkills.splice(index, 1)
        this.setState({ lowPriorityHardSkills })
      } else if (type === 'highPrioritySoftSkills') {
        let highPrioritySoftSkills = this.state.highPrioritySoftSkills
        highPrioritySoftSkills.splice(index, 1)
        this.setState({ highPrioritySoftSkills })
      } else if (type === 'lowPrioritySoftSkills') {
        let lowPrioritySoftSkills = this.state.lowPrioritySoftSkills
        lowPrioritySoftSkills.splice(index, 1)
        this.setState({ lowPrioritySoftSkills })
      } else if (type === 'idealMajors') {
        let idealMajors = this.state.idealMajors
        idealMajors.splice(index, 1)
        this.setState({ idealMajors })
      } else if (type === 'courses') {
        let courses = this.state.courses
        courses.splice(index, 1)
        this.setState({ courses })
      } else if (type === 'certifications') {
        let certifications = this.state.certifications
        certifications.splice(index, 1)
        this.setState({ certifications })
      } else if (type === 'projectTags') {
        let projectTags = this.state.projectTags
        projectTags.splice(index, 1)
        this.setState({ projectTags })
      } else if (type === 'experienceTags') {
        let experienceTags = this.state.experienceTags
        experienceTags.splice(index, 1)
        this.setState({ experienceTags })
      } else if (type === 'volunteerTags') {
        let volunteerTags = this.state.volunteerTags
        volunteerTags.splice(index, 1)
        this.setState({ volunteerTags })
      } else if (type === 'interviewQuestion') {
        let interviewRubric = this.state.interviewRubric
        interviewRubric[parentIndex]['questions'].splice(index, 1)
        this.setState({ interviewRubric })
      } else if (type === 'interviewAnswer') {
        let interviewRubric = this.state.interviewRubric
        interviewRubric[parentIndex]['answers'].splice(index, 1)
        this.setState({ interviewRubric })
      }
    }

    addItem(type, index) {
      console.log('addItem called', type, index)

      if (type === 'db') {
        let dealBreakers = this.state.dealBreakers
        dealBreakers.push({ name: '', questionType: 'Multiple Choice', screeningAnswerChoices: [] })
        this.setState({ dealBreakers })
      } else if (type === 'thirdPartyAssessments') {
        let thirdPartyAssessments = this.state.thirdPartyAssessments
        thirdPartyAssessments.push({ name: '', questionType: 'Number Ranges', screeningAnswerChoices: [] })
        this.setState({ thirdPartyAssessments })
      } else if (type === 'interviewCriterion') {
        let interviewRubric = this.state.interviewRubric
        interviewRubric.push({ criterion: '', questions: [], answers: [] })
        this.setState({ interviewRubric })
      } else if (type === 'interviewQuestion') {
        let interviewRubric = this.state.interviewRubric
        interviewRubric[index]['questions'].push(interviewRubric[index].question)
        interviewRubric[index]['question'] = ''
        this.setState({ interviewRubric })
      } else if (type === 'interviewAnswer') {
        let interviewRubric = this.state.interviewRubric
        interviewRubric[index]['answers'].push(interviewRubric[index].answer)
        interviewRubric[index]['answer'] = ''
        this.setState({ interviewRubric })
      }

    }

    prepareEndorsement() {
      console.log('prepareEndorsement called')

      let selectedBenchmark = this.state.selectedTemplate
      if (!selectedBenchmark) {
        if (this.state.benchmarkOptions && this.state.benchmarkOptions.length > 1) {
          selectedBenchmark = this.state.benchmarkOptions[1]
        }
      }

      let skillTraits = [] // name, skillType, rating
      let competencies = [] // name, category, rating
      let examples = [] // skillTrait, example

      if (selectedBenchmark.skills && selectedBenchmark.skills.length > 0) {
        // benchmarkSkills - title
        for (let i = 1; i <= selectedBenchmark.skills.length; i++) {
          skillTraits.push({
            name: selectedBenchmark.skills[i - 1].title,
            skillType: selectedBenchmark.skills[i - 1].skillType,
            rating: 5
          })
          competencies.push({
            name: selectedBenchmark.skills[i - 1].title,
            category: selectedBenchmark.skills[i - 1].skillType,
            rating: 5
          })
          if (i === 1) {
            examples.push({
              skillTrait: selectedBenchmark.skills[i - 1].title,
              example: "I've worked with this student for 3 years on a project. Never in my 20 years of teaching having I seen someone demonstrate a mastery of " + selectedBenchmark.skills[i - 1].title.toLowerCase() + " with little-to-no experience.",
            })
          }
        }
      }
      if (selectedBenchmark.knowledge && selectedBenchmark.knowledge.length > 0) {
        // benchmarkSkills - title
        for (let i = 1; i <= selectedBenchmark.knowledge.length; i++) {
          skillTraits.push({
            name: selectedBenchmark.knowledge[i - 1].title,
            skillType: 'Knowledge',
            rating: 5
          })
          competencies.push({
            name: selectedBenchmark.knowledge[i - 1].title,
            category: 'Knowledge',
            rating: 5
          })
          if (i === 1) {
            examples.push({
              skillTrait: selectedBenchmark.knowledge[i - 1].title,
              example: "I've worked with this student for 3 years on a project. Never in my 20 years of teaching having I seen someone demonstrate a mastery of " + selectedBenchmark.knowledge[i - 1].title.toLowerCase() + " with little-to-no experience.",
            })
          }
        }
      }

      const selectedEndorsement = {
        senderFirstName: 'Jon', senderLastName: 'Doe', senderEmail: 'jondoe@gmail.com',
        recipientFirstName: 'Jon', recipientLastName: 'Doe', recipientEmail: 'jondoe@gmail.com',
        relationship: 'Teacher', pathway: selectedBenchmark.jobFunction, skillTraits, competencies,
        examples, overallRecommendation: 5, isTransparent: true,
        createdAt: new Date(), updatedAt: new Date()
      }

      this.setState({ modalIsOpen: true, showEndorsementDetails: true, selectedEndorsement })
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, showEndorsementDetails: false, showPathwayInfo: false, showApprovedInfo: false  })
    }

    renderItems(type) {
      console.log('renderItems called', type)

      let rows = []
      if (type === 'workPreferences') {
        console.log('in wp')

        const workPreferenceFields = this.state.workPreferenceFields
        if (workPreferenceFields) {
          for (let i = 1; i <= workPreferenceFields.length; i++) {

            const index = i - 1

            let options = []
            let multipleSelect = false

            let placeholder = ''
            let wpName = ''
            let wpValue = null
            let wpArrayValue = []

            if (index === 0) {
              options = this.state.workDistanceOptions
              options[6] = 'unlimited / remote'
            } else if (index === 1) {
              options = this.state.hourlyPayOptions
            } else if (index === 2) {
              options = this.state.functionOptions
              multipleSelect = true
            } else if (index === 3) {
              options = this.state.industryOptions
              multipleSelect = true
            } else if (index === 4) {
              options = this.state.technologyTrends
            } else if (index === 5) {
              placeholder = 'Add an additional tech trend...'
              wpName = 'additionalTechnologyTrends'
              wpValue = this.state.additionalTechnologyTrend
              wpArrayValue = this.state.additionalTechnologyTrends
            } else if (index === 6) {
              options = this.state.societalProblems
            } else if (index === 7) {
              placeholder = 'Add an additional societal problem...'
              wpName = 'additionalSocietalProblems'
              wpValue = this.state.additionalSocietalProblem
              wpArrayValue = this.state.additionalSocietalProblems
            }

            // console.log('show index: ', index, options)

            if (options) {
              rows.push(
                <div key={"workPreferences|" + i}>
                  <div className="edit-profile-row">
                    <div>
                      <div className="calc-column-offset-220 right-padding">
                        <div className="half-spacer" />
                        <p>{workPreferenceFields[i - 1].name}</p>
                        <div className="half-spacer" />
                        {(index >= 4) ? (
                          <div>
                            {(index % 2 === 0) ? (
                              <div>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                  <StrictModeDroppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                      >
                                        {options.map((item, index) => (
                                          <Draggable key={item} draggableId={item} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <div className="padding-5">
                                                  <div className="float-left left-padding">
                                                    <img src={dragIcon} alt="Compass loading gif icon" className="image-auto-20 center-item" />
                                                  </div>
                                                  <div className="float-left left-padding-20" >
                                                    <label>#{index + 1}: {item}</label>
                                                  </div>
                                                  <div className="clear" />
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </StrictModeDroppable>
                                </DragDropContext>
                              </div>
                            ) : (
                              <div>
                                <div className="calc-column-offset-220"><div className="half-spacer" />
                                  <div className="fixed-column-320 right-margin-5">
                                    <input type="text" className="text-field" placeholder={placeholder} name={wpName} value={wpValue} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="fixed-column-100">
                                    <button className="btn btn-squarish" onClick={() => this.optionClicked(wpName,null,wpValue, null)}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="clear" />
                                {this.renderTags(wpName, wpArrayValue)}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(multipleSelect === true) ? (
                              <div>
                                {options.map((value, optionIndex) =>
                                  <div key={i}>
                                    {(optionIndex > 0) && (
                                      <div className="float-left row-5 right-padding">
                                        {(workPreferenceFields[index].value && workPreferenceFields[index].value.includes(value)) ? (
                                          <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('workPreferences','value', value, index, multipleSelect)}>
                                            <div>
                                              <div className="float-left">
                                                <p className="description-text-2 white-text">{value}</p>
                                              </div>
                                            </div>
                                          </button>
                                        ) : (
                                          <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('workPreferences','value',value, index, multipleSelect)}>
                                            <div>
                                              <div className="float-left">
                                                <p className="description-text-2">{value}</p>
                                              </div>
                                            </div>
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                {options.map((value, optionIndex) =>
                                  <div key={i}>
                                    {(optionIndex > 0) && (
                                      <div className="float-left row-5 right-padding">
                                        {(value === workPreferenceFields[index].value) ? (
                                          <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('workPreferences','value', value, index)}>
                                            <div>
                                              <div className="float-left">
                                                <p className="description-text-2 white-text">{value}</p>
                                              </div>
                                            </div>
                                          </button>
                                        ) : (
                                          <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('workPreferences','value',value, index)}>
                                            <div>
                                              <div className="float-left">
                                                <p className="description-text-2">{value}</p>
                                              </div>
                                            </div>
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="fixed-column-220">
                        <select name={"workPreferences|importance|" + index} value={workPreferenceFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                          {this.state.importanceOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />

                    </div>
                  </div>
                </div>
              )
            }
          }
        }

      } else if (type === 'interests') {

        console.log('in interests')

        rows.push(
          <div key="generalInterests">

            <div className="edit-profile-row">
              <p className="heading-text-6">General Interests</p>
              <div className="spacer"/><div className="spacer"/>

              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <div className="fixed-column-250 right-margin-5">
                    <input type="text" className="text-field" placeholder="Add a general interest..." name="generalInterest" value={this.state.generalInterest} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-100">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('generalInterests',null, this.state.generalInterest, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="fixed-column-220">
                  <select name="generalInterestsImportance" value={this.state.generalInterestsImportance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                    {this.state.importanceOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>
              {this.renderTags('generalInterests', this.state.generalInterests)}

              <div className="clear" />
            </div>

          </div>
        )

        rows.push(
          <div key={"workInterests"}>
            <div className="spacer"/><div className="spacer"/><div className="spacer"/>
            <p className="heading-text-6">Work Interests (Holland Codes)</p>
            <div className="spacer"/><div className="spacer"/>
          </div>
        )

        if (this.state.workInterestFields) {
          const workInterestFields = this.state.workInterestFields
          for (let i = 1; i <= workInterestFields.length; i++) {

            const index = i - 1

            let options = this.state.interestOptions

            rows.push(
              <div key={"workInterests|" + i}>
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220 right-padding">
                      <div className="half-spacer" />
                      <p>{workInterestFields[i - 1].name} Careers <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href={workInterestFields[index].url}>(Learn More)</a></p>
                      <div className="half-spacer" />
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left row-5 right-padding">
                              {(value === workInterestFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('workInterests','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('workInterests','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"workInterests|importance|" + index} value={workInterestFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            )
          }
        }

      } else if (type === 'personality') {
        console.log('in personality')

        if (this.state.personalityFields) {
          const personalityFields = this.state.personalityFields
          for (let i = 1; i <= personalityFields.length; i++) {

            const index = i - 1

            let options = []
            if (index === 0) {
              options = this.state.myersBriggsOptions
            } else if (index === 1) {
              options = this.state.fiveFactorsOptions
            } else if (index === 2) {
              options = this.state.fiveFactorsOptions
            } else if (index === 3) {
              options = this.state.fiveFactorsOptions
            } else if (index === 4) {
              options = this.state.fiveFactorsOptions
            } else if (index === 5) {
              options = this.state.fiveFactorsOptions
            }

            rows.push(
              <div key={"diversity|" + i}>
                {(i === 2) && (
                  <div>
                    <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                    <p className="heading-text-6">Big Five Personality Traits</p>
                    <div className="spacer"/>
                  </div>
                )}
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220 right-padding">
                      <div className="half-spacer" />
                      <p>{personalityFields[i - 1].name} <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href={personalityFields[index].url}>(Learn More)</a></p>
                      <div className="half-spacer" />
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left row-5 right-padding">
                              {(value === personalityFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('personality','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('personality','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"personality|importance|" + index} value={personalityFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />

                  </div>
                </div>
              </div>
            )
          }
        }

        rows.push(
          <div key="additionalTraits">
            <div className="spacer"/><div className="spacer"/>
            <div className="edit-profile-row">
              <p className="heading-text-5">Additional Favorable Personality Traits</p>
              <div className="spacer"/><div className="spacer"/>

              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <div className="fixed-column-250 right-margin-5">
                    <input type="text" className="text-field" placeholder="Add a trait..." name="additionalTrait" value={this.state.additionalTrait} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-100">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('additionalTraits',null, this.state.additionalTrait, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="fixed-column-220">
                  <select name="additionalTraitsImportance" value={this.state.additionalTraitsImportance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                    {this.state.importanceOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>
              {this.renderTags('additionalTraits', this.state.additionalTraits)}

              <div className="clear" />
            </div>

          </div>
        )
      } else if (type === 'values') {
        console.log('in values')

        rows.push(
          <div key="gravitateValues">
            <div className="edit-profile-row">
              <p className="heading-text-5">You prefer candidates who gravitate toward people are:</p>
              <div className="spacer"/><div className="spacer"/>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.gravitateValues.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="padding-5">
                                <div className="float-left left-padding">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                  <img src={dragIcon} alt="Compass loading gif icon" className="image-auto-20 center-item" />
                                </div>
                                <div className="float-left left-padding-20" >
                                  <label>#{index + 1}: {item}</label>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
          </div>
        )

        rows.push(
          <div key="employerValues">
            <div className="spacer"/><div className="spacer"/>

            <hr className="cta-border-color" />

            <div className="spacer"/><div className="spacer"/>
            <div className="edit-profile-row">
              <p className="heading-text-5">You prefer candidates who highly value the following from their employer:</p>
              <div className="spacer"/><div className="spacer"/>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.employerValues.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <div className="float-left horizontal-padding-4">
                                  <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                  <img src={dragIcon} alt="Compass loading gif icon" className="image-auto-20 center-item" />
                                </div>
                                <div className="float-left left-margin">
                                  <label>#{index + 1}: {item}</label>
                                </div>
                                <div className="clear" />

                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
          </div>
        )

      } else if (type === 'skills') {
        console.log('in skills')

        rows.push(
          <div key="skills">
            <div className="row-10">
              <div className="container-left">
                <p className="profile-label">High Priority Hard Skills <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href="https://www.thebalancecareers.com/hard-skills-vs-soft-skills-2063780">(Learn More)</a></p>

                <div>
                  <div className="calc-column-offset-70 right-padding">
                    <input type="text" className="text-field" placeholder="Add a skill..." name="highPriorityHardSkill" value={this.state.highPriorityHardSkill} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('highPriorityHardSkills',null, this.state.highPriorityHardSkill, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.loadingHighPriorityHardSkillOptions) ? (
                  <div className="full-space padding-40">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Pulling competencies...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.highPriorityHardSkillOptions) && (
                      <div>
                        <div className="spacer" />

                        {this.state.highPriorityHardSkillOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text row-5 full-width">
                            <button className="background-button" onClick={() => this.competencyClicked(this.state.selectedIndex, optionIndex, 'highPriorityHardSkill')}>
                              <div className="full-width">
                                <div className="float-left right-padding">
                                  <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="float-left">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}

                      </div>
                    )}

                  </div>
                )}

                {this.renderTags('highPriorityHardSkills', this.state.highPriorityHardSkills)}

              </div>
              <div className="container-right">
                <p className="profile-label">Low Priority Hard Skills <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href="https://www.thebalancecareers.com/hard-skills-vs-soft-skills-2063780">(Learn More)</a></p>

                <div>
                  <div className="calc-column-offset-70 right-padding">
                    <input type="text" className="text-field" placeholder="Add a skill..." name="lowPriorityHardSkill" value={this.state.lowPriorityHardSkill} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('lowPriorityHardSkills',null, this.state.lowPriorityHardSkill, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.loadingLowPriorityHardSkillOptions) ? (
                  <div className="full-space padding-40">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Pulling competencies...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.lowPriorityHardSkillOptions) && (
                      <div>
                        <div className="spacer" />

                        {this.state.lowPriorityHardSkillOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text row-5 full-width">
                            <button className="background-button" onClick={() => this.competencyClicked(this.state.selectedIndex, optionIndex, 'lowPriorityHardSkill')}>
                              <div className="full-width">
                                <div className="float-left right-padding">
                                  <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="float-left">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}

                      </div>
                    )}

                  </div>
                )}

                {this.renderTags('lowPriorityHardSkills', this.state.lowPriorityHardSkills)}

              </div>
              <div className="clear" />
            </div>



            <div className="row-10">
              <div className="container-left">
                <p className="profile-label">High Priority Soft Skills <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href="https://www.thebalancecareers.com/hard-skills-vs-soft-skills-2063780">(Learn More)</a></p>

                <div>
                  <div className="calc-column-offset-70 right-padding">
                    <input type="text" className="text-field" placeholder="Add a skill..." name="highPrioritySoftSkill" value={this.state.highPrioritySoftSkill} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('highPrioritySoftSkills',null, this.state.highPrioritySoftSkill, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.loadingHighPrioritySoftSkillOptions) ? (
                  <div className="full-space padding-40">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Pulling competencies...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.highPrioritySoftSkillOptions) && (
                      <div>
                        <div className="spacer" />

                        {this.state.highPrioritySoftSkillOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text row-5 full-width">
                            <button className="background-button" onClick={() => this.competencyClicked(this.state.selectedIndex, optionIndex, 'highPrioritySoftSkill')}>
                              <div className="full-width">
                                <div className="float-left right-padding">
                                  <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="float-left">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}

                      </div>
                    )}

                  </div>
                )}

                {this.renderTags('highPrioritySoftSkills', this.state.highPrioritySoftSkills)}

              </div>
              <div className="container-right">
                <p className="profile-label">Low Priority Soft Skills <a target="_blank" rel="noopener noreferrer"className="description-text-1 half-bold-text" href="https://www.thebalancecareers.com/hard-skills-vs-soft-skills-2063780">(Learn More)</a></p>

                <div>
                  <div className="calc-column-offset-70 right-padding">
                    <input type="text" className="text-field" placeholder="Add a skill..." name="lowPrioritySoftSkill" value={this.state.lowPrioritySoftSkill} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-70">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('lowPrioritySoftSkills',null, this.state.lowPrioritySoftSkill, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.loadingLowPrioritySoftSkillOptions) ? (
                  <div className="full-space padding-40">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Pulling competencies...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.lowPrioritySoftSkillOptions) && (
                      <div>
                        <div className="spacer" />

                        {this.state.lowPrioritySoftSkillOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text row-5 full-width">
                            <button className="background-button" onClick={() => this.competencyClicked(this.state.selectedIndex, optionIndex, 'lowPrioritySoftSkill')}>
                              <div className="full-width">
                                <div className="float-left right-padding">
                                  <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="float-left">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}

                      </div>
                    )}

                  </div>
                )}

                {this.renderTags('lowPrioritySoftSkills', this.state.lowPrioritySoftSkills)}

              </div>
              <div className="clear" />
            </div>

          </div>
        )
      } else if (type === 'endorsements') {
        console.log('in endorsements')

        rows.push(
          <div key="endorsements">
            <div className="row-10">
              <button className="background-button cta-color underline-text offset-underline" onClick={() => this.prepareEndorsement()}>See Example Endorsement</button>
            </div>
          </div>
        )
      } else if (type === 'education') {
        console.log('in education')

        if (this.state.educationFields) {
          const educationFields = this.state.educationFields
          for (let i = 1; i <= educationFields.length; i++) {

            const index = i - 1

            let options = []
            let name = ''
            let tempValue = null
            if (index === 0) {
              options = this.state.degreeOptions
            } else if (index === 1) {
              // options = this.state.raceOptions
            } else if (index === 2) {
              options = this.state.gpaRangeOptions
            } else if (index === 3) {
              options = this.state.gradYearRangeOptions
            } else if (index === 4) {
              options = this.state.gpaRangeOptions
            } else if (index === 5) {
              options = this.state.courseHourRangeOptions
            } else if (index === 6) {
              name = 'course'
              tempValue = this.state.course
              options = this.state.courses
            } else if (index === 7) {
              name = 'certification'
              tempValue = this.state.certification
              options = this.state.certifications
            }

            // console.log('show options: ', index, options, tempValue)

            rows.push(
              <div key={"education|" + i}>
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220 right-padding">
                      <div className="half-spacer" />
                      <p>{educationFields[i - 1].name}</p>
                      <div className="half-spacer" />
                      {(i > 6) ? (
                        <div>
                          <div>
                            <div className="float-left full-width row-5">
                              <div className="calc-column-offset-70-2 right-padding-5">
                                <input type="text" className="text-field" placeholder={"Add a " + name + " name..."} name={name + "|name"} value={tempValue.name} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="calc-column-offset-70-2 right-padding-5">
                                <input type="text" className="text-field" placeholder="Add a url..." name={name + "|url"} value={tempValue.url} onChange={this.formChangeHandler}></input>
                                {(tempValue.url && !tempValue.url.startsWith('http')) && (
                                  <div>
                                    <p className="error-message">Please start your link with http</p>
                                  </div>
                                )}
                              </div>
                              <div className="fixed-column-70 pin-right">
                                <button className="btn btn-squarish" onClick={() => this.optionClicked(name + 's',null, tempValue.name, null, tempValue.url)}>Add</button>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />
                          </div>

                          {(options) && (
                            <div>
                              {options.map((value, optionIndex) =>
                                <div key={name + "|" + optionIndex} className="float-left">
                                  <div className="spacer" />
                                  <div className="close-button-container-1">
                                    <button className="background-button" onClick={() => this.removeTag(name + 's', optionIndex)}>
                                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                    </button>
                                  </div>
                                  <div className="float-left row-5 right-padding">
                                    <a className="background-button tag-container-4" target="_blank" rel="noopener noreferrer"href={value.url}>
                                      <div>
                                        <div className="float-left">
                                          <p className="description-text-2 underline-text">{value.name}></p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          {(i === 2) ? (
                            <div>
                              <div>
                                <div className="float-left full-width row-5">
                                  <div className="fixed-column-330 right-margin-5">
                                    <input type="text" className="text-field" placeholder="Add a major..." name="idealMajor" value={this.state.idealMajor} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="fixed-column-70 pin-right">
                                    <button className="btn btn-squarish" onClick={() => this.optionClicked('idealMajors',null, this.state.idealMajors, null)}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="clear" />
                              </div>

                              {this.renderTags('idealMajors', this.state.idealMajors)}

                              <div className="clear" />
                            </div>
                          ) : (
                            <div>
                              {(options) && (
                                <div>
                                  {options.map((value, optionIndex) =>
                                    <div key={i}>
                                      {(optionIndex > 0) && (
                                        <div className="float-left row-5 right-padding">
                                          {(value === educationFields[index].value) ? (
                                            <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('education','value', value, index)}>
                                              <div>
                                                <div className="float-left">
                                                  <p className="description-text-2 white-text">{value}</p>
                                                </div>
                                              </div>
                                            </button>
                                          ) : (
                                            <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('education','value',value, index)}>
                                              <div>
                                                <div className="float-left">
                                                  <p className="description-text-2">{value}</p>
                                                </div>
                                              </div>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"education|importance|" + index} value={educationFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />

                  </div>
                </div>
              </div>
            )
          }
        }

      } else if (type === 'projects') {
        console.log('in projects')

        if (this.state.projectFields) {
          const projectFields = this.state.projectFields
          for (let i = 1; i <= projectFields.length; i++) {

            const index = i - 1

            let options = []
            if (index === 0) {
              options = this.state.hourOptions
            } else if (index === 1) {
              // options = this.state.raceOptions
            }

            rows.push(
              <div key={"projects|" + i}>
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220 right-padding">
                      <p className="profile-label">{projectFields[i - 1].name}</p>
                      <div className="half-spacer" />
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left row-5 right-padding">
                              {(value === projectFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('project','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('project','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"project|importance|" + index} value={projectFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            )
          }
        }

        rows.push(
          <div key="projectTags">

            <div className="edit-profile-row">
              <p className="profile-label">Tags</p>

              <div>
                <div className="calc-column-offset-220">
                  <div className="half-spacer" />
                  <div className="fixed-column-330 right-margin-5">
                    <input type="text" className="text-field" placeholder="Tag issues this solves or skills used..." name="projectTag" value={this.state.projectTag} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-100">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('projectTags',null, this.state.projectTag, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="fixed-column-220">
                  <select name="projectTagsImportance" value={this.state.projectTagsImportance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                    {this.state.importanceOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>

              {this.renderTags('projectTags', this.state.projectTags)}

              <div className="clear" />
            </div>

          </div>
        )
      } else if (type === 'experience') {
        console.log('in experience')

        rows.push(
          <div key="experience">
            <p className="heading-text-6 row-10">Paid Experience</p>
          </div>
        )

        if (this.state.paidExperienceFields) {
          const paidExperienceFields = this.state.paidExperienceFields
          for (let i = 1; i <= paidExperienceFields.length; i++) {

            const index = i - 1

            let options = []
            if (index === 0) {
              options = this.state.hourOptions
            }

            rows.push(
              <div key={"experience|" + i}>
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220">
                      <p className="profile-label">{paidExperienceFields[i - 1].name}</p>
                      <div className="half-spacer" />
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left row-5 right-padding">
                              {(value === paidExperienceFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('experience','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('experience','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"experience|importance|" + index} value={paidExperienceFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            )
          }
        }

        rows.push(
          <div key="experienceTags">

            <div className="edit-profile-row">
              <p className="profile-label">Experience Tags</p>

              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <div className="fixed-column-330 right-margin-5">
                    <input type="text" className="text-field" placeholder="Tag issues this solves or skills used..." name="experienceTag" value={this.state.experienceTag} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-100">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('experienceTags',null, this.state.experienceTag, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="fixed-column-220">
                  <select name="experienceTagsImportance" value={this.state.experienceTagsImportance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                    {this.state.importanceOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>

              {this.renderTags('experienceTags', this.state.experienceTags)}

              <div className="clear" />
            </div>

          </div>
        )

        rows.push(
          <div key="volunteer">
            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
            <p className="heading-text-6 row-10">Volunteer (Unpaid Experience)</p>
          </div>
        )

        if (this.state.volunteerFields) {
          const volunteerFields = this.state.volunteerFields
          for (let i = 1; i <= volunteerFields.length; i++) {

            const index = i - 1

            let options = []
            if (index === 0) {
              options = this.state.hourOptions
            }

            rows.push(
              <div key={"volunteer|" + i}>
                <div className="edit-profile-row">
                  <div>
                    <div className="calc-column-offset-220">
                      <p className="profile-label">{volunteerFields[i - 1].name}</p>
                      <div className="half-spacer" />
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left row-5 right-padding">
                              {(value === volunteerFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('volunteer','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('volunteer','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="fixed-column-220">
                      <select name={"volunteer|importance|" + index} value={this.state.volunteerFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                        {this.state.importanceOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />

                  </div>
                </div>
              </div>
            )
          }
        }

        rows.push(
          <div key="volunteerTags">

            <div className="edit-profile-row">
              <p className="profile-label">Volunteer Tags</p>

              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <div className="fixed-column-330 right-margin-5">
                    <input type="text" className="text-field" placeholder="Tag issues this solves or skills used..." name="volunteerTag" value={this.state.volunteerTag} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="fixed-column-100">
                    <button className="btn btn-squarish" onClick={() => this.optionClicked('volunteerTags',null, this.state.volunteerTag, null)}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="fixed-column-220">
                  <select name="volunteerTagsImportance" value={this.state.volunteerTagsImportance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                    {this.state.importanceOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>
                <div className="clear" />
              </div>

              {this.renderTags('volunteerTags', this.state.volunteerTags)}

              <div className="clear" />
            </div>

          </div>
        )
      } else if (type === 'interview') {
        console.log('in interview')

        // let backgroundColor1 = 'rgba(250,250,250,1)'
        // let backgroundColor2 = 'rgba(250,250,250,1)'
        // let backgroundColor3 = 'rgba(250,250,250,1)'
        // let backgroundColor4 = 'rgba(250,250,250,1)'
        // let backgroundColor5 = 'rgba(250,250,250,1)'
        // let selectedBackground = 'auto'
        // let performance = ''
        // let colorClass = 'excellent-color'
        // if (this.state.interviewGrade === 0) {
        //   // backgroundColor1 = '#87CEFA'
        //   // selectedBackground = backgroundColor1
        //   performance = 'excellent'
        // } else if (this.state.interviewGrade === 1) {
        //   // backgroundColor2 = '#AADCBE'
        //   // selectedBackground = backgroundColor2
        //   performance = 'good'
        //   colorClass = 'good-color'
        // } else if (this.state.interviewGrade === 2) {
        //   // backgroundColor3 = '#F8E71C'
        //   // selectedBackground = backgroundColor3
        //   performance = 'okay'
        //   colorClass = 'okay-color'
        // } else if (this.state.interviewGrade === 3) {
        //   // backgroundColor4 = '#F5A623'
        //   // selectedBackground = backgroundColor4
        //   performance = 'poor'
        //   colorClass = 'poor-color'
        // } else if (this.state.interviewGrade === 4) {
        //   // backgroundColor5 = '#F56E14'
        //   // selectedBackground = backgroundColor5
        //   performance = 'terrible'
        //   colorClass = 'terrible-color'
        // }
        //
        // let guidance = ''
        // if (this.state.interviewRubric[optionIndex].guidances[this.state.interviewGrade] && this.state.interviewRubric[optionIndex].guidances[this.state.interviewGrade] !== '') {
        //   guidance = this.state.interviewRubric[optionIndex].guidances[this.state.interviewGrade]
        // }
        //
        // let exampleAnswer = ''
        // if (this.state.interviewRubric[optionIndex].exampleAnswers[this.state.interviewGrade] && this.state.interviewRubric[optionIndex].exampleAnswers[this.state.interviewGrade] !== '') {
        //   exampleAnswer = this.state.interviewRubric[optionIndex].exampleAnswers[this.state.interviewGrade]
        // }

        rows.push(
          <div key={'interview'}>
            {/*
            <div className="edit-profile-row">
              <p className="profile-label">General Cues of Strong Fit</p>
              <textarea type="text" className="text-field" placeholder="Highlight any specific cues that demonstrate that a candidate is a good fit (e.g. interpersonal, analytical, or creative thinking cues)..." name="strongFitCues" value={this.state.strongFitCues} onChange={this.formChangeHandler}></textarea>
            </div>

            <div className="edit-profile-row">
              <p className="profile-label">General Cues of Poor Fit</p>
              <textarea type="text" className="text-field" placeholder={"Highlight any specific cues that demonstrate that a candidate is not a good fit (e.g. no conviction for " + this.state.selectedJob.workFunction + ", arrogant / narcissistic, lack perserverance)..."} name="poorFitCues" value={this.state.poorFitCues} onChange={this.formChangeHandler}></textarea>
            </div>

            <div className="edit-profile-row">
              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <p>Interview Questions</p>
                </div>

                <div className="clear" />

                <div>
                  <div>

                    <div className="float-left row-5 full-width">
                      <div className="calc-column-offset-70-2 right-padding-5">
                        <input type="text" className="text-field" placeholder={"Add an interview question..."} name="interviewQuestion|question" value={this.state.interviewQuestion.question} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="calc-column-offset-70-2 right-padding-5">
                        <input type="text" className="text-field" placeholder="Add interview guidance..." name="interviewQuestion|guidance" value={this.state.interviewQuestion.guidance} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-70 pin-right">
                        <button className="btn btn-squarish" onClick={() => this.optionClicked('interviewQuestion',null, this.state.interviewQuestion.question, null, this.state.interviewQuestion.guidance)}>Add</button>
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="clear" />
                  </div>

                  {this.state.interviewQuestions.map((value, optionIndex) =>
                    <div key={"interviews|" + optionIndex} className="float-left row-5">
                      <div className="close-button-container-1" >
                        <button className="background-button" onClick={() => this.removeTag('interviewQuestions', optionIndex)}>
                          <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="calc-column-offset-25 right-padding-5">
                        <div className="half-spacer" />
                        <div className="tag-container-inactive">
                          <p className="description-text-2"><label className="half-bold-text">#{optionIndex + 1}:</label> Q: {value.question} | G: {value.guidance}</p>
                        </div>
                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}

                  <div className="clear" />
                </div>

                <div className="clear" />
              </div>
            </div>

            <div className="edit-profile-row">
              <div>
                <div className="calc-column-offset-220"><div className="half-spacer" />
                  <p>Interview Rubric</p>
                </div>
                <div className="clear" />

                <div className="spacer"/><div className="spacer"/>

                <div className="highlighted-container-2">
                {console.log('show interview rubric: ', this.state.interviewRubric)}
                {this.state.interviewRubric.map((value, optionIndex) =>
                  <div key={value}>
                    <div className="white-background thick-border">
                      <div className="relative-column-30 padding-20 height-150">
                        <p className="description-text-2 bold-text">Criterion #{optionIndex + 1}</p>
                        <div className="half-spacer" />
                        <input type="text" className="text-field heading-text-3 height-40" placeholder="e.g. professionalism.." name={"criteria|name|" + optionIndex} value={this.state.interviewRubric[optionIndex].name} onChange={this.formChangeHandler}></input>
                      </div>

                      <div className="vertical-separator-3" />

                      <div className="calc-column-offset-2-of-70 padding-20 height-150">
                        <p className="description-text-2 bold-text">Interview Questions</p>
                        <div className="half-spacer" />
                        <div className="calc-column-offset-80">
                          <select name={"criteria|questionPlaceholder|" + optionIndex} value={this.state.interviewRubric[optionIndex].questionPlaceholder} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                            {[''].concat(this.state.interviewQuestions).map(value =>
                              <option key={value.question} value={value.question}>{value.question}</option>
                            )}
                          </select>
                        </div>
                        <div className="fixed-column-80 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.optionClicked('addInterviewQuestion',null, this.state.interviewRubric[optionIndex].questionPlaceholder, optionIndex)}>Add</button>
                        </div>
                        <div className="clear" />
                        {(this.state.interviewRubric[optionIndex].questions) && (
                          <div>
                            {this.state.interviewRubric[optionIndex].questions.map((qValue, qIndex) =>
                              <div className="tag-container-3">
                                <p className="tag-2">#{qIndex + 1}: {qValue}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="clear" />

                      <div className="padding-2">
                        <p className="full-width">The below is guidance for and an example answer from a <label className={"half-bold-text " + colorClass}>{performance}</label> performing candidate.</p>
                      </div>

                      <div className="flex-container row-direction white-background">
                        <div className={(this.state.interviewGrade === 0) ? "flex-20 flex-center center-text light-border excellent-background-color" : "flex-20 flex-center center-text light-border"}>
                          <button className="background-button full-space padding-20" onClick={() => this.setState({ interviewGrade: 0 })}>
                            <p className="full-width heading-text-5">EXCELLENT</p>
                          </button>
                        </div>
                        <div className={(this.state.interviewGrade === 1) ? "flex-20 flex-center center-text light-border good-background-color" : "flex-20 flex-center center-text light-border"}>
                          <button className="background-button full-space padding-20" onClick={() => this.setState({ interviewGrade: 1 })}>
                            <p className="full-width heading-text-5">GOOD</p>
                          </button>
                        </div>
                        <div className={(this.state.interviewGrade === 2) ? "flex-20 flex-center center-text light-border okay-background-color" : "flex-20 flex-center center-text light-border"}>
                          <button className="background-button full-space padding-20" onClick={() => this.setState({ interviewGrade: 2 })}>
                            <p className="full-width heading-text-5">OKAY</p>
                          </button>
                        </div>
                        <div className={(this.state.interviewGrade === 3) ? "flex-20 flex-center center-text light-border poor-background-color" : "flex-20 flex-center center-text light-border"}>
                          <button className="background-button full-space padding-20" onClick={() => this.setState({ interviewGrade: 3 })}>
                            <p className="full-width heading-text-5">POOR</p>
                          </button>
                        </div>
                        <div className={(this.state.interviewGrade === 4) ? "flex-20 flex-center center-text light-border terrible-background-color" : "flex-20 flex-center center-text light-border"}>
                          <button className="background-button full-space padding-20" onClick={() => this.setState({ interviewGrade: 4 })}>
                            <p className="full-width heading-text-5">TERRIBLE</p>
                          </button>
                        </div>
                      </div>

                      <div className="spacer" /><div className="spacer" />

                      <div className="full-width padding-20 height-180">
                        <div className="name-container">
                          <p className="description-text-2 bold-text">Guidance for a <label className={"half-bold-text " + colorClass}>{performance}</label> performing candidate.</p>
                          <div className="half-spacer" />
                          {(this.state.interviewRubric[optionIndex].guidances[this.state.interviewGrade]) ? (
                            <textarea type="text" className="text-field light-border" placeholder="Add guidance for this grade level..." name={"criteria|guidances|" + optionIndex + "|" + this.state.interviewGrade} value={this.state.interviewRubric[optionIndex].guidances[this.state.interviewGrade]} onChange={this.formChangeHandler}></textarea>
                          ) : (
                            <textarea type="text" className="text-field light-border" placeholder="Add guidance for this grade level..." name={"criteria|guidances|" + optionIndex + "|" + this.state.interviewGrade} value={''} onChange={this.formChangeHandler}></textarea>
                          )}
                        </div>
                        <div className="name-container">
                          <p className="description-text-2 bold-text">Example Answer of a <label className={"half-bold-text " + colorClass}>{performance}</label> performing candidate.</p>
                          <div className="half-spacer" />
                          {(this.state.interviewRubric[optionIndex].exampleAnswers[this.state.interviewGrade]) ? (
                            <textarea type="text" className="text-field light-border" placeholder="Give an example answer worthy of this grade.." name={"criteria|exampleAnswers|" + optionIndex + "|" + this.state.interviewGrade} value={this.state.interviewRubric[optionIndex].exampleAnswers[this.state.interviewGrade]} onChange={this.formChangeHandler}></textarea>
                          ) : (
                            <textarea type="text" className="text-field light-border" placeholder="Give an example answer worthy of this grade.." name={"criteria|exampleAnswers|" + optionIndex + "|" + this.state.interviewGrade} value={''} onChange={this.formChangeHandler}></textarea>
                          )}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}

                <div>
                  <div className="spacer"/><div className="spacer"/>

                  <hr className="clear-margin clear-padding"/>
                  <a className="background-button add-task-link-container" onClick={() => this.addCriterion()}>
                    <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-34" />
                  </a>
                </div>
                </div>
              </div>
            </div>*/}

            <div className="row-10">
              <div className="float-left">
                <p className="profile-label">Interview Rubric</p>
              </div>
              <div className="float-left left-padding top-margin">
                <div className="mini-spacer" /><div className="mini-spacer" />

                <button className="background-button" onClick={() => this.addItem('interviewCriterion')}>
                  <div className="cta-border circle-radius padding-5">
                    <img src={addIcon} alt="GC" className="image-auto-10" />
                  </div>
                </button>
              </div>
              <div className="clear" />

            </div>

            {(this.state.interviewRubric && this.state.interviewRubric.length > 0) && (
              <div className="row-10">
                <div className="standard-border">
                  <div className="bold-text standard-border-bottom ">
                    <div className="relative-column-33 padding-20">
                      <p>Criteria</p>
                    </div>
                    <div className="relative-column-33 padding-20">
                      <p>Sample Questions</p>
                    </div>
                    <div className="relative-column-33 padding-20">
                      <p>Qualities of Great Scores</p>
                    </div>
                    <div className="clear" />
                  </div>

                  {this.state.interviewRubric.map((value, optionIndex) =>
                    <div key={value}>
                      <div className="standard-border-bottom">
                        <div className="relative-column-33 padding-20">
                          <div className="fixed-column-30 top-padding">
                            <label className="bold-text">{optionIndex + 1}.</label>
                          </div>
                          <div className="calc-column-offset-30">
                            <input type="text" className="text-field" placeholder="Greeting" name={"interviewRubric|criterion|" + optionIndex} value={value.criterion} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="clear" />

                        </div>
                        <div className="relative-column-33 padding-20">
                          <div>
                            <div className="calc-column-offset-80">
                              <input type="text" className="text-field" placeholder="Hi, how are you?" name={"interviewRubric|question|" + optionIndex} value={value.question} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="fixed-column-80 left-padding">
                              <button className="btn btn-squarish" onClick={() => this.addItem('interviewQuestion', optionIndex)}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>

                          {value.questions.map((value2, optionIndex2) =>
                            <div className="right-margin">
                              <div className="close-button-container-1">
                                <button className="background-button" onClick={() => this.removeTag('interviewQuestion', optionIndex2, optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="tag-container-basic darkish-background float-left top-margin">
                                <p className="white-text">{value2}</p>
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                        <div className="relative-column-33 padding-20">
                          <div>
                            <div className="calc-column-offset-80">
                              <input type="text" className="text-field" placeholder="Clear communication" name={"interviewRubric|answer|" + optionIndex} value={value.answer} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="fixed-column-80 left-padding">
                              <button className="btn btn-squarish" onClick={() => this.addItem('interviewAnswer', optionIndex)}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>

                          {value.answers.map((value2, optionIndex2) =>
                            <div>
                              <div className="close-button-container-1">
                                <button className="background-button" onClick={() => this.removeTag('interviewAnswer', optionIndex2, optionIndex)}>
                                  <img src={deniedIcon} alt="GC" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="tag-container-basic darkish-background float-left right-margin top-margin">
                                <p className="white-text">{value2}</p>
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}
                </div>

              </div>
            )}
          </div>
        )
      } else if (type === 'diversity') {

        const diversityFields = this.state.diversityFields
        for (let i = 1; i <= diversityFields.length; i++) {

          const index = i - 1

          let options = []
          if (index === 0) {
            options = this.state.genderOptions
          } else if (index === 1) {
            options = this.state.raceOptions
          } else if (index === 2) {
            options = this.state.veteranOptions
          } else if (index === 3) {
            options = this.state.lowIncomeOptions
          } else if (index === 4) {
            options = this.state.fosterYouthOptions
          } else if (index === 5) {
            options = this.state.homelessOptions
          } else if (index === 6) {
            options = this.state.incarceratedOptions
          } else if (index === 7) {
            options = this.state.lgbtqiaOptions
          } else if (index === 8) {
            options = this.state.disabilityOptions
          } else if (index === 9) {
            options = this.state.firstGenImmigrantOptions
          } else if (index === 10) {
            options = this.state.firstGenCollegeStudentOptions
          }

          rows.push(
            <div key={"diversity|" + i}>
              <div className="edit-profile-row">
                <div>
                  <div className="calc-column-offset-220">
                    <p>{diversityFields[i - 1].name}</p>
                    <div className="half-spacer" />
                    {options.map((value, optionIndex) =>
                      <div key={i}>
                        {(optionIndex > 0) && (
                          <div className="float-left row-5 right-padding">
                            {(value === diversityFields[index].value) ? (
                              <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('diversity','value', value, index)}>
                                <div>
                                  <div className="float-left">
                                    <p className="description-text-2 white-text">{value}</p>
                                  </div>
                                </div>
                              </button>
                            ) : (
                              <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('diversity','value',value, index)}>
                                <div>
                                  <div className="float-left">
                                    <p className="description-text-2">{value}</p>
                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="fixed-column-220">
                    <select name={"diversity|importance|" + index} value={diversityFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color right-text">
                      {this.state.importanceOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          )
        }
      } else if (type === 'politics') {

        const politicalFields = this.state.politicalFields
        for (let i = 1; i <= politicalFields.length; i++) {

          const index = i - 1

          let options = []
          if (index === 0) {
            options = this.state.politicalAlignmentOptions
          } else if (index === 1) {
            options = this.state.unitedStateOptions
          } else if (index === 2) {
            options = this.state.districtOptions
          } else if (index === 3) {
            options = this.state.unitedStateOptions
          } else if (index === 4) {
            options = this.state.districtOptions
          }

          rows.push(
            <div key={"diversity|" + i}>
              <div className="edit-profile-row">
                <div>
                  <div className="calc-column-offset-220"><div className="half-spacer" />
                    <p>{politicalFields[i - 1].name}</p>
                  </div>
                  <div className="fixed-column-220">
                    <select name={"politics|importance|" + index} value={politicalFields[index].importance} onChange={this.formChangeHandler} className="white-background cta-color center-text">
                      {this.state.importanceOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />

                  {(options) && (
                    <div>
                      {options.map((value, optionIndex) =>
                        <div key={i}>
                          {(optionIndex > 0) && (
                            <div className="float-left right-padding row-10">
                              {(value === politicalFields[index].value) ? (
                                <button className="background-button selected-tag-container-1" onClick={() => this.optionClicked('politics','value', value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button unselected-tag-container-1" onClick={() => this.optionClicked('politics','value',value, index)}>
                                  <div>
                                    <div className="float-left">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="clear" />
                </div>
              </div>
            </div>
          )
        }
      }

      return rows
    }

    addCriterion() {
      console.log('addCriterion')

      let interviewRubric = this.state.interviewRubric
      interviewRubric.push({ name: '', questions: [], guidances: [], exampleAnswers: []})
      this.setState({ interviewRubric })
    }

    optionClicked(name, field, value, index, value2) {
      console.log('optionClicked called', name, field, value, index, value2)

      if (name === 'workPreferences') {
        let workPreferenceFields = this.state.workPreferenceFields
        if (value2) {
          let values = workPreferenceFields[index][field]
          if (values) {
            if (values.includes(value)) {
              const arrayIndex = values.indexOf(value)
              values.splice(arrayIndex, 1)
            } else {
              values.push(value)
            }
          } else {
            values = [value]
          }
          workPreferenceFields[index][field] = values
        } else {
          workPreferenceFields[index][field] = value
        }
        this.setState({ workPreferenceFields })
      } else if (name === 'generalInterests') {
        if (value && value !== '') {
          let generalInterests = this.state.generalInterests
          generalInterests.push(value)
          this.setState({ generalInterests, generalInterest: '' })
        }
      } else if (name === 'workInterests') {
        let workInterestFields = this.state.workInterestFields
        console.log('show wif: ', workInterestFields)
        workInterestFields[index][field] = value
        this.setState({ workInterestFields })
      } else if (name === 'personality') {
        let personalityFields = this.state.personalityFields
        personalityFields[index][field] = value
        this.setState({ personalityFields })
      } else if (name === 'additionalTraits') {
        if (value && value !== '') {
          let additionalTraits = this.state.additionalTraits
          additionalTraits.push(value)
          this.setState({ additionalTraits, additionalTrait: '' })
        }
      } else if (name === 'highPriorityHardSkills') {
        if (value && value !== '') {
          let highPriorityHardSkills = this.state.highPriorityHardSkills
          highPriorityHardSkills.push(value)
          this.setState({ highPriorityHardSkills, highPriorityHardSkill: '' })
        }
      } else if (name === 'lowPriorityHardSkills') {
        if (value && value !== '') {
          let lowPriorityHardSkills = this.state.lowPriorityHardSkills
          lowPriorityHardSkills.push(value)
          this.setState({ lowPriorityHardSkills, lowPriorityHardSkill: '' })
        }
      } else if (name === 'highPrioritySoftSkills') {
        if (value && value !== '') {
          let highPrioritySoftSkills = this.state.highPrioritySoftSkills
          highPrioritySoftSkills.push(value)
          this.setState({ highPrioritySoftSkills, highPrioritySoftSkill: '' })
        }
      } else if (name === 'lowPrioritySoftSkills') {
        if (value && value !== '') {
          let lowPrioritySoftSkills = this.state.lowPrioritySoftSkills
          lowPrioritySoftSkills.push(value)
          this.setState({ lowPrioritySoftSkills, lowPrioritySoftSkill: '' })
        }
      } else if (name === 'endorsements') {
      } else if (name === 'education') {
        let educationFields = this.state.educationFields
        educationFields[index][field] = value
        this.setState({ educationFields })
      } else if (name === 'idealMajors') {
        let idealMajors = this.state.idealMajors
        idealMajors.push(this.state.idealMajor)
        let idealMajor = ''
        this.setState({ idealMajors, idealMajor })
      } else if (name === 'courses') {
        if (value && value !== '' && value2 && value2 !== '') {
          let courses = this.state.courses
          let course = { name: '', url: '' }
          courses.push({ name: value, url: value2 })
          this.setState({ courses, course })
        }
      } else if (name === 'certifications') {
        if (value && value !== '' && value2 && value2 !== '') {
          let certifications = this.state.certifications
          let certification = { name: '', url: '' }
          certifications.push({ name: value, url: value2 })
          this.setState({ certifications, certification })
        }
      } else if (name === 'project') {
        let projectFields = this.state.projectFields
        projectFields[index][field] = value
        this.setState({ projectFields })
      } else if (name === 'projectTags') {
        if (value && value !== '') {
          let projectTags = this.state.projectTags
          projectTags.push(value)
          this.setState({ projectTags, projectTag: '' })
        }
      } else if (name === 'experience') {
        let paidExperienceFields = this.state.paidExperienceFields
        paidExperienceFields[index][field] = value
        this.setState({ paidExperienceFields })
      } else if (name === 'volunteer') {
        let volunteerFields = this.state.volunteerFields
        volunteerFields[index][field] = value
        this.setState({ volunteerFields })
      } else if (name === 'experienceTags') {
        if (value && value !== '') {
          let experienceTags = this.state.experienceTags
          experienceTags.push(value)
          this.setState({ experienceTags, experienceTag: '' })
        }
      } else if (name === 'volunteerTags') {
        if (value && value !== '') {
          let volunteerTags = this.state.volunteerTags
          volunteerTags.push(value)
          this.setState({ volunteerTags, volunteerTag: '' })
        }
      } else if (name === 'interviewQuestion') {
        if (value && value !== '' && value2 && value2 !== '') {
          let interviewQuestions = this.state.interviewQuestions
          let interviewQuestion = { question: '', guidance: '' }
          interviewQuestions.push({ question: value, guidance: value2 })
          this.setState({ interviewQuestions, interviewQuestion })
        }
      } else if (name === 'diversity') {
        let diversityFields = this.state.diversityFields
        diversityFields[index][field] = value
        this.setState({ diversityFields })
      } else if (name === 'addInterviewQuestion') {
        console.log('in add q', index, field, value)
        let interviewRubric = this.state.interviewRubric
        let questions = interviewRubric[index].questions
        questions.push(value)
        interviewRubric[index]['questions'] = questions
        this.setState({ interviewRubric })
      } else if (name === 'politics') {
        let politicalFields = this.state.politicalFields
        politicalFields[index][field] = value
        this.setState({ politicalFields })
      } else if (name === 'additionalTechnologyTrends') {
        if (value && value !== '') {
          let additionalTechnologyTrends = this.state.additionalTechnologyTrends
          additionalTechnologyTrends.push(value)
          this.setState({ additionalTechnologyTrends, additionalTechnologyTrend: '' })
        }
      } else if (name === 'additionalSocietalProblems') {
        if (value && value !== '') {
          let additionalSocietalProblems = this.state.additionalSocietalProblems
          additionalSocietalProblems.push(value)
          this.setState({ additionalSocietalProblems, additionalSocietalProblem: '' })
        }
      }
    }

    renderAllocation() {
      console.log('renderAllocation called')

      let rows = []

      let weights = [
        { name: 'Work Preferences (Self-Assessment)', description: 'Candidates interested in relevant industries and employer attributes are awarded maximum points.', ref: 'preferencesPercent', value: this.state.preferencesPercent },
        { name: 'Work Interests (Self-Assessment)', description: 'Candidates interested in careers that require relevant skills are awarded maximum points.', ref: 'interestsPercent', value: this.state.interestsPercent },
        { name: 'Personality (Self-Assessment)', description: 'Candidates whose personality most matches the sought after type are awarded maximum points.', ref: 'traitsPercent', value: this.state.traitsPercent },
        { name: 'Values (Self-Assessment)', description: 'Candidates whose values match your preferences are awarded maximum points.', ref: 'valuesPercent', value: this.state.valuesPercent },
        { name: 'Skills (Self-Assessment)', description: 'Candidates who self-assess for the relevant skills are awarded maximum points.', ref: 'skillsPercent', value: this.state.skillsPercent },
        { name: 'Endorsements', description: 'Candidates with high skill/trait endorsement ratings from close, experts are awarded maximum points.', ref: 'endorsementsPercent', value: this.state.endorsementsPercent },
        { name: 'Education', description: 'Candidates with the target level and focus of education are awarded maximum points.', ref: 'educationPercent', value: this.state.educationPercent },
        { name: 'Projects', description: 'Candidates with 1+ years working on projects building relevant skills are awarded maximum points.', ref: 'projectsPercent', value: this.state.projectsPercent },
        { name: 'Experience', description: 'Candidates who have 1+ years of paid expereience building relevant skills are awarded maximum points.', ref: 'experiencePercent', value: this.state.experiencePercent },
        { name: 'Resume', description: 'Candidates who have a strong resume are awarded maximum points.', ref: 'resumePercent', value: this.state.resumePercent },
        { name: 'Cover Letter', description: 'Candidates who have a strong cover letter are awarded maximum points.', ref: 'coverLetterPercent', value: this.state.coverLetterPercent },
        { name: 'Interview', description: 'Candidates who perform well on the internal interview are awarded maximum points.', ref: 'interviewPercent', value: this.state.interviewPercent },
        { name: 'Diversity', description: 'Candidates who are in bring diversity or showcase unique adversity are awarded maximum points.', ref: 'adversityScorePercent', value: this.state.adversityScorePercent },
        { name: 'Political Preference', description: 'Candidates who align with high demand, low supply political preferences are awarded extra points.', ref: 'politicalPreferencePercent', value: this.state.politicalPercent },
      ]

      if (this.state.showV2App) {
        const thirdPartyAssessmentWeight = { name: 'Third Party Assessments', description: 'Candidates who complete the third party assessments at a satisfactory level are awarded maximum points.', ref: 'thirdPartyAssessmentPercent', value: this.state.thirdPartyAssessmentPercent }
        weights.splice(5,0,thirdPartyAssessmentWeight)
      }

      for (let i = 1; i <= weights.length; i++) {
        console.log('iterate: ', i)

        if (this.props.activeOrg !== 'c2c' && weights[i - 1].name === 'Political Preference') {
          // do no render
        } else {
          rows.push(
            <div key={"weights|" + i}>
              <div className="relative-column-85">
                <p className="heading-text-6">{i}. {weights[i - 1].name}</p>
                <div className="half-spacer" />
                <p className="description-text-2 description-text-color">{weights[i - 1].description}</p>
              </div>
              <div className="relative-column-15 right-text">
                <div className="calc-column-offset-30">
                  <input type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={weights[i - 1].ref} value={weights[i - 1].value} onChange={this.formChangeHandler}></input>
                </div>
                <div className="fixed-column-30">
                  <div className="mini-spacer"/><div className="mini-spacer"/>
                  <p className="heading-text-2 cta-color bold-text">%</p>
                </div>
              </div>

              <div className="clear" />
              <div className="spacer" /><div className="half-spacer" />
            </div>
          )
        }
      }

      return rows

    }

    renderQuestions(items, type) {
      console.log('renderQuestions called', items, type)

      if (items) {
        let rows = [];

        for (let i = 1; i <= items.length; i++) {
          console.log(i);

          const index = i - 1

          rows.push(
            <div key={i} className="top-padding">
              <div className="close-button-container-2" >
                <button type="button" onClick={() => this.deleteItem(type, index)} className="background-button">
                  <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                </button>
              </div>

              <div className="left-padding-5">
                <div className="highlighted-container">
                  <div className="row-5">
                    <p>{(items[i - 1].questionType === 'Number Ranges') ? "Assessment" : "Question"} #{i}</p>
                  </div>

                  <div className="full-width">
                    <input type="text" className="text-field" placeholder={(items[i - 1].questionType === 'Number Ranges') ? "Assessment name..." : "Write a question..."} name={(items[i - 1].questionType === 'Number Ranges') ? "thirdPartyAssessment|name|" + index : "screeningQuestion|name|" + index} value={items[i - 1].name} onChange={this.formChangeHandler}></input>
                  </div>

                  <div className="clear"/>

                  {(items[i - 1].questionType === 'Multiple Choice' || items[i - 1].questionType === 'Checkbox' || items[i - 1].questionType === 'Ranking' || items[i - 1].questionType === 'Number Ranges') ? (
                    <div className="top-padding">
                      {(items[i - 1].screeningAnswerChoices && items[i - 1].screeningAnswerChoices.length > 0) && (
                        <div>
                          <div className="row-5 description-text-5 bold-text">

                            {(items[i - 1].questionType === 'Number Ranges') ? (
                              <span>
                                <div className="fixed-column-170 left-padding-30">
                                  <p>OPERATOR</p>
                                </div>
                                <div className="calc-column-offset-310 left-padding right-padding">
                                  <p>NUMERICAL SCORE</p>
                                </div>
                              </span>
                            ) : (
                              <div className="calc-column-offset-140 right-padding left-padding-30">
                                <p>ANSWER CHOICE</p>
                              </div>
                            )}

                            <div className="fixed-column-140">
                              <p className="full-width left-text">ACCEPTABILITY</p>
                            </div>
                            <div className="clear" />
                          </div>

                          {this.renderAnswerChoices(i - 1, items[i - 1].screeningAnswerChoices, index, type)}
                        </div>
                      )}

                      <div>
                        <div className="add-item-line-button">
                          <hr />
                          <a className="background-button add-task-link-container" onClick={() => this.addAnswerChoice(i - 1, type)}>
                            <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                          </a>
                        </div>
                        <div className="clear" />
                      </div>

                    </div>
                  ) : (
                    <div>
                      {(items[i - 1].questionType === 'Long Answer') ? (
                        <p className="fake-text-area">Candidates will write long answer here...</p>
                      ) : (
                        <p className="fake-text-field">Candidates will write {items[i - 1].questionType.toLowerCase()} here...</p>
                      )}
                    </div>
                  )}

                </div>
              </div>

            </div>
          )
        }

        return rows;
      }
    }

    renderAnswerChoices(questionIndex, answerChoices, parentIndex, type) {
      console.log('renderAnswerChoices called', type)

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        // let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        // let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()
        const answerChoiceIndex = i - 1

        if (type === 'dealBreakers') {
          rows.push(
            <div key={"screeningAnswerChoice|" + i} className="row-5">
              <div className="fixed-column-30">
                <button type="button" onClick={() => this.deleteItem('answerChoice', parentIndex, questionIndex, answerChoiceIndex)} className="background-button">
                  <img src={deniedIcon} className="answer-bubble" alt="Compass close icon icon" />
                </button>
              </div>
              <div className="calc-column-offset-170 right-padding">
                <input type="text" className="text-field height-40" placeholder="Write an answer choice..." name={"answerChoice|name|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].name} onChange={this.formChangeHandler}></input>
              </div>
              <div className="fixed-column-140">
                <select name={"answerChoice|score|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].score} onChange={this.formChangeHandler} className="dropdown">
                  {['','Acceptable','Inacceptable'].map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="clear" />
            </div>
          )
        } else if (type === 'thirdPartyAssessments') {
          console.log('tpa')

          rows.push(
            <div key={"thirdPartyAssessment|" + i} className="row-5">
              <div className="fixed-column-30">
                <button type="button" onClick={() => this.deleteItem('thirdPartyAssessmentAnswerChoice', parentIndex, questionIndex, answerChoiceIndex)} className="background-button">
                  <img src={deniedIcon} className="answer-bubble" alt="Compass close icon icon" />
                </button>
              </div>
              <div className="fixed-column-140">
                <select name={"thirdPartyAssessmentAnswerChoice|operator|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].operator} onChange={this.formChangeHandler} className="dropdown">
                  {['','Less than','Equal to','Greater than'].map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="calc-column-offset-310 left-padding right-padding">
                <input type="number" className="number-field height-40" placeholder="0" name={"thirdPartyAssessmentAnswerChoice|name|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].name} onChange={this.formChangeHandler}></input>
              </div>
              <div className="fixed-column-140">
                <select name={"thirdPartyAssessmentAnswerChoice|score|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].score} onChange={this.formChangeHandler} className="dropdown">
                  {['','Acceptable','Inacceptable'].map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="clear" />
            </div>
          )
        }
      }

      return rows
    }

    addAnswerChoice(index, type) {
      console.log('addAnswerChoice called', index, type)

      if (type === 'dealBreakers') {
        let screeningAnswerChoices = []
        if (this.state.dealBreakers[this.state.dealBreakers.length - 1] && this.state.dealBreakers[index]) {
          screeningAnswerChoices = this.state.dealBreakers[index].screeningAnswerChoices
        }
        screeningAnswerChoices.push({ name: '', score: '' })
        let dealBreakers = this.state.dealBreakers
        dealBreakers[index] = { name: this.state.dealBreakers[index].name, questionType:this.state.dealBreakers[index].questionType, screeningAnswerChoices }

        this.setState({ dealBreakers })
      } else if (type === 'thirdPartyAssessments') {
        let screeningAnswerChoices = []
        if (this.state.thirdPartyAssessments[this.state.thirdPartyAssessments.length - 1] && this.state.thirdPartyAssessments[index]) {
          screeningAnswerChoices = this.state.thirdPartyAssessments[index].screeningAnswerChoices
        }
        screeningAnswerChoices.push({ name: '', score: '' })
        let thirdPartyAssessments = this.state.thirdPartyAssessments
        thirdPartyAssessments[index] = { name: this.state.thirdPartyAssessments[index].name, questionType:this.state.thirdPartyAssessments[index].questionType, screeningAnswerChoices }

        this.setState({ thirdPartyAssessments })
      }
    }

    deleteItem(itemType, parentIndex, questionIndex, answerChoiceIndex) {
      console.log('deleteItem called', itemType, parentIndex, questionIndex, answerChoiceIndex)

      if (itemType === 'dealBreakers') {
        let dealBreakers = this.state.dealBreakers
        dealBreakers.splice(parentIndex, 1)
        this.setState({ dealBreakers })
      } else if (itemType === 'thirdPartyAssessments') {
        let thirdPartyAssessments = this.state.thirdPartyAssessments
        thirdPartyAssessments.splice(parentIndex, 1)
        this.setState({ thirdPartyAssessments })
      } else if (itemType === 'answerChoice') {
        let dealBreakers = this.state.dealBreakers
        let screeningAnswerChoices = this.state.dealBreakers[questionIndex].screeningAnswerChoices
        screeningAnswerChoices.splice(answerChoiceIndex,1)

        dealBreakers[questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ dealBreakers })
      } else if (itemType === 'thirdPartyAssessmentAnswerChoice') {
        let thirdPartyAssessments = this.state.thirdPartyAssessments
        let screeningAnswerChoices = this.state.thirdPartyAssessments[questionIndex].screeningAnswerChoices
        screeningAnswerChoices.splice(answerChoiceIndex,1)

        thirdPartyAssessments[questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ thirdPartyAssessments })

      }
    }

    render() {

      return (
          <div>
              <div className={(window.location.pathname.includes('/walkthrough')) ? "" : "standard-container"}>
                  <div>
                    <div className="spacer"/><div className="spacer"/>

                    <div>
                      <div className="calc-column-offset-50">
                        <p className="heading-text-2">{(this.state._id) ? "Edit Benchmark" : "Create Your Ideal Candidate (i.e., Benchmark)"}</p>
                      </div>
                      <div className="fixed-column-50">
                        {(this.state.orgLogo && !window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                          <img src={this.state.orgLogo} alt="GC" className="image-auto-40" />
                        )}
                      </div>
                      <div className="clear" />
                      {(!window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                        <div>
                          {(this.state.orgName) && (
                            <p className="description-text-2">for {this.state.orgName}</p>
                          )}
                        </div>
                      )}
                    </div>

                    {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisors')) ? (
                    <p className="profile-descriptor">Design and save the an ideal candidate template that you may attach to postings. <Link to="/help/what-are-benchmarks" target="_blank" className="bold-text">This video</Link> explains benchmarks</p>
                    ) : (
                      <div className="row-15">
                        <p className="description-text-1">Educators, workforce professionals, and career-seekers in diverse communities use benchmarks to prepare for roles at your company. Additionally, Guided Compass algorithms are tied to them, automatically scoring prospects and candidates. <Link to="/help/what-are-benchmarks" target="_blank" className="bold-text">This video</Link> explains benchmarks and <Link to="/employers/pages/ritual/benchmarks/61782dd2317125a58c817093" target="_blank" className="bold-text">here</Link> is an example of a benchmark.</p>
                      </div>
                    )}

                  </div>

                  <div className="row-10">

                    {(window.location.pathname.includes('/create')) && (
                      <div>
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">First Name <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="First name..." name="cuFirstName" value={this.state.cuFirstName} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Last Name <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="Last name..." name="cuLastName" value={this.state.cuLastName} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Email <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="youremail@gmail.com..." name="emailId" value={this.state.emailId} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Job Title <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="HR Manager..." name="cuJobTitle" value={this.state.cuJobTitle} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Employer Name <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="Amazon..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}></input>

                            {(this.state.loadingEmployerOptions) ? (
                              <div className="full-space padding-40">
                                <div>
                                  <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                  <p className="center-text cta-color bold-text">Pulling employers...</p>

                                </div>
                              </div>
                            ) : (
                              <div>
                                {(this.state.employerOptions) && (
                                  <div>
                                    <div className="spacer" />

                                    {this.state.employerOptions.map((value, optionIndex) =>
                                      <div key={value._id} className="left-text row-5 full-width">
                                        <button className="background-button" onClick={() => this.employerClicked(value)}>
                                          <div className="full-width">
                                            <div className="float-left right-padding">
                                              <img src={industryIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                            </div>
                                            <div className="float-left">
                                              <p className="cta-color">{value.employerName}</p>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    )}

                                  </div>
                                )}

                              </div>
                            )}
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Employer Website <label className="error-color bold-text">*</label></label>
                            <input type="text" className="text-field" placeholder="Http..." name="employerURL" value={this.state.employerURL} onChange={this.formChangeHandler}></input>
                            {(this.state.employerURL && !this.state.employerURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your website with http</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Benchmark Title <label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Title your benchmark for your reference..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Example Job Title <label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Software Developer..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                          <label className="profile-label">Work Function <label className="error-color bold-text">*</label></label>
                          <select name="jobFunction" value={this.state.jobFunction} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.functionOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                      </div>
                      <div className="container-right">
                          <label className="profile-label">Industry</label>
                          <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.industryOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                      </div>
                      <div className="clear"/>
                    </div>

                    <div>
                      <div className="row-10">
                        <div className="container-left">

                          <div className="float-left">
                            <label className="profile-label">Pathway Level <label className="error-color bold-text">*</label></label>
                          </div>
                          <div>
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showPathwayInfo: true })}>
                              <div className="float-left left-margin noti-bubble-info-7-9">
                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                              </div>
                            </a>

                          </div>
                          <div className="clear" />

                          <Switch
                            onChange={(change) => this.setState({ pathwayLevel: change })}
                            checked={this.state.pathwayLevel}
                            id="normal-switch"
                          />
                        </div>
                        {(this.state.pathwayLevel) && (
                          <div className="container-right">
                            {(window.location.pathname.includes('/organizations') && this.state.pathwayOptions && this.state.pathwayOptions.length > 0) ? (
                              <div>
                                <div className="container-left">
                                    <label className="profile-label">Pathway</label>
                                    <select name="pathway" value={this.state.pathway} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.pathwayOptions.map(value =>
                                        <option key={value.key} value={(value.school) ? value.name + ' | ' + value.school : value.name}>{(value.school) ? value.name + ' | ' + value.school : value.name}</option>
                                      )}
                                    </select>
                                </div>
                                <div className="clear"/>
                              </div>
                            ) : (
                              <div>
                                <label className="profile-label">Department / Pathway</label>
                                <input type="text" className="text-field" placeholder="Department name..." name="pathway" value={this.state.pathway} onChange={this.formChangeHandler}></input>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="clear"/>
                      </div>

                      {(this.state.pathwayLevel) && (
                        <div className="row-10">
                          <div className="container-left">
                            <div className="float-left">
                              <label className="profile-label">{(this.state.approved) ? "Approved" : "Not Approved"} <label className="error-color bold-text">*</label></label>
                            </div>
                            <div>
                              <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showApprovedInfo: true })}>
                                <div className="float-left left-margin noti-bubble-info-7-9">
                                  <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                </div>
                              </a>

                            </div>
                            <div className="clear" />

                            <Switch
                              onChange={(change) => this.setState({ approved: change })}
                              checked={this.state.approved}
                              id="normal-switch"
                            />
                          </div>

                          <div className="clear"/>
                        </div>
                      )}

                    </div>
                  </div>
                  {/*
                  {(window.location.pathname.includes('/organizations')) && (
                    <div>
                      <div className="spacer"/><div className="spacer"/>
                      <hr className="clear-margin clear-padding"/>
                      <div className="spacer"/><div className="spacer"/>

                      <div className="edit-profile-row">
                        <div className="name-container">
                          <p className="profile-label">How would you like to edit the benchmark?</p>
                          <select name="viewMode" value={this.state.viewMode} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.viewOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}*/}

                </div>
                <div>
                  {(this.state.viewMode === 'Profile Card') && (
                    <div className="standard-container">
                      <div className="clear" />
                      <ProfileCard ref={this.child} selectedApplication={this.state.selectedApplication} selectedJob={this.state.selectedJob} benchmark={this.props.selectedTemplate} benchmarkOptions={null} source="Employer|Edit" activeOrg={this.props.activeOrg} accountCode={this.props.accountCode} />
                    </div>
                  )}

                  {(this.state.viewMode === 'List') && (
                    <div>
                      <div className="standard-container clear-padding whi">
                        <div className="carousel" onScroll={this.handleScroll}>
                          {this.state.benchmarkCategories.map((value, index) =>
                            <div className="carousel-item-container">
                              {(value === this.state.subNavSelected) ? (
                                <div className="selected-carousel-item">
                                  <label key={value}>{value}</label>
                                </div>
                              ) : (
                                <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                  <label key={value}>{value}</label>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*
                      <div className="spacer"/><div className="spacer"/>
                      <hr className="clear-margin clear-padding"/>
                      <div className="spacer"/><div className="spacer"/>
                      <p className="profile-descriptor half-bold-text">Select a category:</p>

                      <div className="carousel" onScroll={this.handleScroll}>
                        {this.state.benchmarkCategories.map((value, index) =>
                          <div className="carousel-item-container">
                            {(value === this.state.subNavSelected) ? (
                              <div className="selected-carousel-item">
                                <label key={value}>{value}</label>
                              </div>
                            ) : (
                              <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                <label key={value}>{value}</label>
                              </button>
                            )}
                          </div>
                        )}
                      </div>*/}

                      <div className="clear" />

                      <div>

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Weights') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Overall Weights</p>

                            <p className="description-text-1 description-text-color row-10">How much does each category matter?</p>
                            <div className="spacer"/><div className="spacer"/>

                            <div>
                              <div>

                                {this.renderAllocation()}

                                <div>
                                  <hr className="cta-border-color"/>
                                  <div className="spacer" />
                                  <div className="float-left full-width right-text">
                                    <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                                  </div>
                                  <div className="clear" />
                                  {(this.state.totalPercent !== 100) && (
                                    <div className="full-width">
                                      <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Deal Breakers') && (
                          <div className="standard-container">
                            <div>
                              <div className="float-left">
                                <p className="heading-text-3">Deal Breakers</p>
                              </div>
                              <div className="float-left left-padding">
                                <div className="half-spacer" />
                                <button className="background-button" onClick={() => this.addItem('db')}>
                                  <div className="cta-border circle-radius padding-5">
                                    <img src={addIcon} alt="GC" className="image-auto-10" />
                                  </div>
                                </button>
                              </div>
                              <div className="clear" />

                              <p className="description-text-1 description-text-color row-10">If candidates do not answer these screening questions correctly, they will be ineligible for the role.</p>
                              <div className="spacer"/><div className="spacer"/>

                            </div>

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            {this.renderQuestions(this.state.dealBreakers,'dealBreakers')}

                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Work Preferences SA') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Work Preferences (Self-Assessment)</p>

                            <p className="description-text-1 description-text-color row-10">What the ideal candidate explicitly says they want in their work, work style, and work environment.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Work Preferences</p>
                              <textarea type="text" className="text-field" placeholder="We like candidates who get gritty, embrace the no's, are not not jerks, are obsessed with details, and seek to make an impact.." name="workPreferencesMessage" value={this.state.workPreferencesMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('workPreferences')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Interests SA') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Interests (Self-Assessment)</p>

                            <p className="description-text-1 description-text-color row-10">Candidate general interests and strong interest inventory.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Interests</p>
                              <textarea type="text" className="text-field" placeholder="Ultimately, we like candidates to have a wide range of interests that overlap with health, wellness, nutrition, and e-commerce..." name="interestsMessage" value={this.state.interestsMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('interests')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Personality SA') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Personality (Self-Assessment)</p>

                            <p className="description-text-1 description-text-color row-10">The big five, 16 personalities, and other personality traits.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Personality</p>
                              <textarea type="text" className="text-field" placeholder="We like software development candidates who are detail-oriented, yet can see the big picture..." name="personalityMessage" value={this.state.personalityMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('personality')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Values SA') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Values (Self-Assessment)</p>

                            <p className="description-text-1 description-text-color row-10">Ranked values on who candidate gravitates towards and what their employer prefers.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Values</p>
                              <textarea type="text" className="text-field" placeholder="We like software development candidates who authentically value our values..." name="valuesMessage" value={this.state.valuesMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('values')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Skills SA') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Skills (Self-Assessment)</p>

                            <p className="description-text-1 description-text-color row-10">The top skills that matter.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Skills</p>
                              <textarea type="text" className="text-field" placeholder="We like software development candidates who are skilled at learning skills quickly, and who want to continually improve for some outcome or mission beyond their pride..." name="skillsMessage" value={this.state.skillsMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('skills')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === '3rd Party Assessments') && (
                          <div className="standard-container">

                            <div>
                              <div className="float-left">
                                <p className="heading-text-3">Third Party Assessments</p>
                              </div>
                              <div className="float-left left-padding">
                                <div className="half-spacer" />
                                <button className="background-button" onClick={() => this.addItem('thirdPartyAssessments')}>
                                  <div className="cta-border circle-radius padding-5">
                                    <img src={addIcon} alt="GC" className="image-auto-10" />
                                  </div>
                                </button>
                              </div>
                              <div className="clear" />

                              <p className="description-text-1 description-text-color row-10">Candidates input their scores from third-party assessments like <a href="https://www.testdome.com" target="_blank" rel="noopener noreferrer">TestDome</a> or <a href="https://www.123test.com" target="_blank" rel="noopener noreferrer">123Test</a>.</p>
                            </div>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Third-Party Assessments</p>
                              <textarea type="text" className="text-field" placeholder="Candidates who have have a TestDome score of 55% or above will pass our requirements and receive maximum points..." name="thirdPartyAssessmentsMessage" value={this.state.thirdPartyAssessmentsMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderQuestions(this.state.thirdPartyAssessments,'thirdPartyAssessments')}

                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Endorsements') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Endorsements</p>

                            <p className="description-text-1 description-text-color row-10">Importance of 3rd party skill and knowledge endorsements.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Endorsements</p>
                              <textarea type="text" className="text-field" placeholder="Candidates who have at least two endorsements, one from a teacher and one from a supervisor, validating the skills and knowledge outlined will receive full points..." name="endorsementsMessage" value={this.state.endorsementsMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('endorsements')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Education') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Education</p>

                            <p className="description-text-1 description-text-color row-10">The educational components that matter.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Education</p>
                              <textarea type="text" className="text-field" placeholder="Education, for us, is not limited to degree pedigree. We want people with relevant knowledge and the ability to acquire relevant knowledge quickly. We want to know what you learned, how quickly you learned it, and how you've applied it. Some of the best education can come from online resources..." name="educationMessage" value={this.state.educationMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('education')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Projects') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Projects</p>

                            <p className="description-text-1 description-text-color row-10">Importance of relevant projects, and what type of project work.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Projects</p>
                              <textarea type="text" className="text-field" placeholder="We highly encourage software developers and aspiring software developers to work on projects. The best projects not only show a product you've built that works, but also that the end product was used, and iterated on. What metrics are tied to its success (e.g., views, sign ups, revenue)? How did it fare on these metrics? Video demos are encouraged..." name="projectsMessage" value={this.state.projectsMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('projects')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Experience') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Experience</p>

                            <p className="description-text-1 description-text-color row-10">Importance of relevant experience, and what type of experience.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Experience</p>
                              <textarea type="text" className="text-field" placeholder="We typically recruit engineers from schools, other companies, or coding bootcamps. If a developer can show that they are skilled in our tech stack, have a passion for our field, self-motivated, and that they can be a reliable team member, we would love to have you..." name="experienceMessage" value={this.state.experienceMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('experience')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Resume') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Resume</p>

                            <p className="description-text-1 description-text-color row-10">What matters on the ideal candidate's resume.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Resume</p>
                              <textarea type="text" className="text-field" placeholder="Resumes for software development roles should particularly pay attention to showing the results of your work and clearly defining your role in team projects. Video demonstrations of code or products you've worked on are truly helpful. Adding links to a maintained Github and personal / portfolio site allows us to see you're serious about building great products. Whenever possible, highlight metrics that you affected (e.g., built landing page that generated 1,000 views a day)..." name="resumeMessage" value={this.state.resumeMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('resume')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Cover Letter') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Cover Letter</p>

                            <p className="description-text-1 description-text-color row-10">What matters on the ideal candidate's cover letter.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Cover Letter</p>
                              <textarea type="text" className="text-field" placeholder="Cover letters for software development roles should quickly showcase your competitive advantage, approach to solving problems, and why you a strong fit for the Ritual team..." name="coverLetterMessage" value={this.state.coverLetterMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('coverLetter')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Interview') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Interview</p>

                            <p className="description-text-1 description-text-color row-10">What matters in the ideal candidate's interview.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe The Ideal Candidate's Interview</p>
                              <textarea type="text" className="text-field" placeholder="We look for developers who know when to hack something up yet also know when to take a more thoughtful / thorough approach. As a growing company, we'll need to do both. We may ask you to explain how you would approach building certain features or what the tradeoffs are for approaching a development process a certain way..." name="interviewMessage" value={this.state.interviewMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('interview')}
                          </div>
                        )}

                        {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Diversity') && (
                          <div className="standard-container">
                            <p className="heading-text-3">Diversity</p>

                            <p className="description-text-1 description-text-color row-10">What valuable diverse candidates look like to us.</p>

                            <div className="row-20">
                              <p className="profile-label">Describe How The Ideal Candidates Helps Diversity</p>
                              <textarea type="text" className="text-field" placeholder="We equally review candidates, regardless of race, color, national origin, religion, sex and age in accordance with EEO Laws. Our founder is female, and we strongly believe in diversity of thought. So, candidates who complement our team in interests, personality, skills, and knowledge are welcome. In addition, we seek to make our workforce reflect the broader populations we serve. Highlight what makes you unique, and we'll celebrate it..." name="adversityScoreMessage" value={this.state.adversityScoreMessage} onChange={this.formChangeHandler}></textarea>
                            </div>

                            {this.renderItems('diversity')}
                          </div>
                        )}
                        {(this.state.showPolitics) && (
                          <div>
                            {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Politics') && (
                              <div className="standard-container">
                                <p className="heading-text-3">Politics</p>
                                <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                                {this.renderItems('politics')}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}

                  <div className="standard-container clear-padding clear-background clear-shadow bottom-margin-30">
                    { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                    { (this.state.serverPostSuccess) ? (
                      <p className="success-message">{this.state.serverSuccessMessage}</p>
                    ) : (
                      <p className="error-message">{this.state.serverErrorMessage}</p>
                    )}

                    <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.saveBenchmark()}>{(window.location.pathname.includes('/create')) ? "Submit Benchmark" : "Save Benchmark"}</button>
                  </div>

              </div>

              {(this.state.showEndorsementDetails) ? (
                <div>
                  <SubEndorsementDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedEndorsement={this.state.selectedEndorsement} orgCode={this.state.org} />
                </div>
              ) : (
                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >

                {(this.state.showPathwayInfo) && (
                  <div key="showMatchingCriteria" className="full-width padding-20">
                    <div>
                      <div className="calc-column-offset-40">
                        <p className="heading-text-2">Pathway Level vs. Job Level Benchmarks</p>
                      </div>
                      <div className="fixed-column-40">
                        <div className="spacer" />
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p><label className="bold-text cta-color">Pathway Level</label> benchmarks represent employer departments, talent pipelines, and career pathways. These benchmarks are viewable by career-seekers and organizations training career-seekers for opportunities over the long run.</p>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p><label className="bold-text cta-color">Job Level</label> benchmarks are designed or automatically generated for specific opportunities to help score candidates. When candidates apply to the job or organizations refer candidates, they will be scored against the benchmark.</p>

                  </div>
                )}

                {(this.state.showApprovedInfo) && (
                  <div key="showApprovedInfo" className="full-width padding-20">
                    <div>
                      <div className="calc-column-offset-40">
                        <p className="heading-text-2">Approving Pathway Benchmarks</p>
                      </div>
                      <div className="fixed-column-40">
                        <div className="spacer" />
                        <button className="background-button" onClick={() => this.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p>Approving benchmarks allows the benchmark to be viewed by career-seeekers, educational institutions, and workforce organizations. For career exploration activities, a benchmark may be recommend based on it's match score to candidate profiles.</p>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <p>Although benchmarks can be attached to jobs to score candidates, employers do not need to use the benchmark to evaluate candidates. Benchmarks are meant to be a proxy for evaluation criteria so candidates can prepare appropriately, training organizations can develop appropriate curriculum, and training organizations can referral appropriate candidates.</p>

                    <div className="spacer" />

                  </div>
                )}

               </Modal>
              )}
          </div>

      )
    }
}

export default withRouter(EditBenchmark);
