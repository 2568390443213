import React, {Component} from 'react';
import ConfirmEmail from './Subcomponents/ConfirmEmail';
import withRouter from './Functions/WithRouter';

class EmpConfirmEmail extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('componentDidMount')

      let { emp, orgCode, email, confirmed } = this.props.params
      console.log('show email: ', orgCode, email, confirmed)

      if (confirmed === 'true') {
        confirmed = true
      } else {
        confirmed = false
      }

      this.setState({ orgCode, email, confirmed, emp })

    }

    render() {

      return (
        <div>
          {(this.state.email) && (
            <ConfirmEmail history={this.props.navigate} orgCode={this.state.orgCode} accountCode={this.state.emp} email={this.state.email} confirmed={this.state.confirmed} />
          )}
        </div>
      )
    }
}

export default withRouter(EmpConfirmEmail)
