import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubMessaging from './Subcomponents/Messaging';
import withRouter from './Functions/WithRouter';

class Messaging extends Component {
    constructor(props) {
      super(props)

      this.state = {
        newMessage: false
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let loadPage = true
      let newMessage = false
      if (this.props.location && this.props.location.state) {

        const { threadId, groupPost, generalPost, recipient, selectedGoal } = this.props.location.state;

        if (groupPost || generalPost || selectedGoal) {
          newMessage = true
        }
        this.setState({ threadId, username, activeOrg, orgFocus, orgLogo, groupPost, generalPost,
          recipient, loadPage, newMessage, selectedGoal
        })

      } else if (this.props.params) {

        const { threadId } = this.props.params
        this.setState({ threadId, username, activeOrg, orgFocus, orgLogo, loadPage })

      } else {
        this.setState({ username, activeOrg, orgFocus, orgLogo, loadPage })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)
      this.setState({ activeOrg })
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />

            {(this.state.loadPage) && (
              <SubMessaging history={this.props.navigate} groupPost={this.state.groupPost} generalPost={this.state.generalPost} newMessage={this.state.newMessage} recipient={this.state.recipient} selectedGoal={this.state.selectedGoal} />
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Messaging)
