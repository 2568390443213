import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import ProfileCard from './Subcomponents/ProfileCard';
import {convertDateToString} from './Functions/ConvertDateToString';
import Modal from 'react-modal';
import withRouter from './Functions/WithRouter';

const thumbsUpIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-icon.png';
const thumbsUpBlueIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-blue-icon.png';
const thumbsDownIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-down-icon.png';
const thumbsDownOrangeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-down-orange-icon.png';

class EmpTopCandidates extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',
            emp: '',

            employerCode: '',
            wpQuestion: [],

            startDate: '',
            endDate: '',
            hoursPerWeek: '',
            pay: '',
            isOnsite: 'Always',
            workLocation: '',
            offerLetterURL: '',
            expirationDate: null,

            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: '',

            topCandidates: [],
            formHasChangedArray: [],

            modalIsOpen: false,

            filterOptions: ['','Highest Rating','Lowest Rating'],
            relationshipOptions: ['','Classmate','Mentor','Teacher'],
            pathwayOptions: [''],

            hoursPerWeekOptions: [],
            hourlyPayRangeOptions: [],
            onsiteOptions: ['','Always','Somtimes','Remote'],
            rankingOptions: []

        }

        this.closeModal = this.closeModal.bind(this);

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.likeClicked = this.likeClicked.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.connectEmployer = this.connectEmployer.bind(this)
        this.saveFeedback = this.saveFeedback.bind(this)
        this.sendFeedback = this.sendFeedback.bind(this)
        this.makeOffer = this.makeOffer.bind(this)
        this.sendOffer = this.sendOffer.bind(this);
        this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp, id } = this.props.params
      console.log('we in', emp, id)

      this.setState({ emp, id })

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          this.setState({
            hoursPerWeekOptions: response.data.workOptions[0].hoursPerWeekOptions,
            hourlyPayRangeOptions: response.data.workOptions[0].hourlyPayOptions,
            annualPayRangeOptions: response.data.workOptions[0].annualPayOptions,
          })
        }
      });

      Axios.get('/api/assessment/questions')
      .then((response) => {
          //console.log('O*Net worked', response.data.body, this);
          console.log('show me response', response.data)
          if ( response.data.success === true ) {
            console.log('o-net query worked')

            let wpQuestions = []
            for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
              wpQuestions.push({ name: response.data.assessments.workPreferenceQuestions[i - 1].name })
            }

            this.setState({ wpQuestions })

          } else {
            console.log('Assessment question query failed', response.data.message)
          }
      }).catch((error) => {
          console.log('O*NET did not work', error);
      });

      let postingIds = [id]
      Axios.get('/api/offers/bypostings', { params: { postingIds } })
      .then((response) => {
        console.log('offers by postings query attempted', response.data);

        if (response.data.success) {
          console.log('offers by postings query worked')
          let offer = null
          let offerMessage = null
          if (response.data.offers && response.data.offers.length > 0) {
            offer = response.data.offers[0]
            if (!offer.decision) {
              offerMessage = "Offer extended. Awaiting decision."
            } else if (offer.decision === 'accept') {
              offerMessage = "This candidate has accepted your offer"
            } else {
              // rejected
              offerMessage = "This candidate has rejected your offer"
            }
          }
          this.setState({ offer, offerMessage })

        } else {

        }
      }).catch((error) => {
          console.log('Offers by postings query did not work for some reason', error);
      });

      if (this.props.location && this.props.location.state) {

        let { selectedJob, applications, selectedBenchmark, topCandidates } = this.props.location.state;
        console.log('gimme stuff', selectedJob, applications, selectedBenchmark, this.props.location.state)

        //obtain email id from localStorage
        let email = localStorage.getItem('email');
        let username = localStorage.getItem('username');
        let cuFirstName = localStorage.getItem('firstName');
        let cuLastName = localStorage.getItem('lastName');
        let activeOrg = localStorage.getItem('activeOrg');
        const orgLogo = localStorage.getItem('orgLogo');

        // let topCandidates = []
        let formHasChangedArray = []

        let payRange = selectedJob.payRange
        let pay = ''

        this.setState({ selectedJob, applications, selectedBenchmark })

        if (selectedJob) {

          let startDate = convertDateToString(selectedJob.startDate,'first10')
          let endDate = convertDateToString(selectedJob.endDate,'first10')

          let hoursPerWeek = selectedJob.hoursPerWeek

          if (payRange.includes(" - ")) {

            let number1 = Number(payRange.substring(1,3))
            let number2 = Number(payRange.substring(7,9))
            pay = ((number1 + number2) / 2).toString()

          } else if (payRange.includes("+")) {
            pay = payRange.substring(1,4)
          } else if (payRange === "Less") {
            pay = '9'
          } else if (payRange === "Unpaid") {
            pay = '0'
          } else if (payRange === "Wide Range") {
            pay = '15'
          }

          if (topCandidates && topCandidates.length > 0) {
            console.log('you have top candidates', topCandidates)

            for (let i = 1; i <= applications.length; i++) {
              formHasChangedArray.push(false)
            }
          } else {
            if (applications) {

              if (applications.length !== 0) {
                //topCandidates = applications

                for (let i = 1; i <= applications.length; i++) {
                  console.log(i);

                  if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
                    for (let j = 1; j <= applications[i - 1].partnerEvaluations.length; j++) {
                      if (applications[i - 1].partnerEvaluations[j - 1].rank) {
                        applications['referrerOrg'] = applications[j - 1].orgCode
                        topCandidates.push(applications[i - 1])
                      }
                    }
                  } else {
                    topCandidates.push(applications[i - 1])
                    formHasChangedArray.push(false)
                  }
                }

                topCandidates.sort(function(a,b) {
                  return a.rank - b.rank;
                })
              }
            }
          }

          if (email) {

            let orgMode = true
            if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
              orgMode = false
            }

            Axios.get('/api/org', { params: { orgCode: activeOrg } })
            .then((response) => {
              console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked')

                this.setState({
                    org: activeOrg,

                    orgName: response.data.orgInfo.orgName,
                    orgURL: response.data.orgInfo.orgURL,
                    orgType: response.data.orgInfo.orgType,
                    orgProgramName: response.data.orgInfo.orgProgramName,
                    orgDescription: response.data.orgInfo.orgDescription,
                    orgMission: response.data.orgInfo.orgMission,

                    orgContactFirstName: response.data.orgInfo.contactFirstName,
                    orgContactLastName: response.data.orgInfo.contactLastName,
                    orgContactTitle: response.data.orgInfo.contactTitle,
                    orgContactEmail: response.data.orgInfo.contactEmail,
                    requestToInterview: response.data.orgInfo.requestToInterview,
                    onlyFeedback: response.data.orgInfo.onlyFeedback

                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

            }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
            });

            Axios.get('/api/account', { params: { accountCode: emp } })
            .then((response) => {
              console.log('Account info query attempted', response.data);

              if (response.data.success) {
                console.log('account info query worked')

                const employerName = response.data.accountInfo.employerName
                const employerURL = response.data.accountInfo.employerURL
                const workLocation = response.data.accountInfo.employerLocation

                let employerContactFirstName = response.data.accountInfo.contactFirstName
                if (selectedJob.employerContactFirstName) {
                  employerContactFirstName = selectedJob.employerContactFirstName
                }
                let employerContactLastName = response.data.accountInfo.contactLastName
                if (selectedJob.employerContactLastName) {
                  employerContactLastName = selectedJob.employerContactLastName
                }
                let employerContactEmail = response.data.accountInfo.contactEmail
                if (selectedJob.employerContactEmail) {
                  employerContactEmail = selectedJob.employerContactEmail
                }

                this.setState({ emp, employerName, employerURL, employerContactFirstName, employerContactLastName, employerContactEmail,
                  workLocation });
              }
            })

            const postingId = selectedJob._id
            const isPerpetual = selectedJob.isPerpetual
            let field = selectedJob.field
            let workFunction = ''
            let industry = ''
            if (field && field !== '') {
              let fieldArray = field.split('|')
              workFunction = fieldArray[0].trim()
              industry = fieldArray[1].trim()
            }

            const description = selectedJob.description

            const orgName = selectedJob.orgName
            const orgContactEmail = selectedJob.orgContactEmail
            const pathways = selectedJob.pathways

            let rankingOptions = ['']
            if (topCandidates) {
              for (let i = 1; i <= topCandidates.length; i++) {
                rankingOptions.push(i)
              }
            }

            this.setState({
                emailId: email, username, cuFirstName, cuLastName,
                emp, orgMode, activeOrg, orgLogo, formHasChangedArray,

                topCandidates, selectedJob,
                benchmark: selectedBenchmark, pay, startDate, endDate, hoursPerWeek,
                postingId, isPerpetual, workFunction,industry, description,

                orgName, orgContactEmail, pathways, rankingOptions
            });

          }
        }

      } else if (emp) {
        //should require person to be authorized, but will fix that later

        // Axios.get('/api/account', { params: { accountCode: emp } })
        // .then((response) => {
        //   console.log('Account info query attempted', response.data);
        //
        //   if (response.data.success) {
        //     console.log('account info query worked')
        //
        //     const activeOrg = response.data.accountInfo.activeOrg
        //     let employerContactFirstName = response.data.accountInfo.employerContactFirstName
        //     let employerContactLastName = response.data.accountInfo.employerContactLastName
        //     let employerContactEmail = response.data.accountInfo.employerContactEmail
        //
        //     this.setState({
        //         emp, employerContactFirstName, employerContactLastName, employerContactEmail,
        //         employerName: response.data.accountInfo.employerName,
        //         workLocation: response.data.accountInfo.employerLocation
        //     });
        //
        //     Axios.get('/api/postings/byid', { params: { _id: id } })
        //     .then((response) => {
        //       console.log('Posted jobs query attempted', response.data);
        //
        //         if (response.data.success) {
        //           console.log('posted jobs query worked')
        //
        //           const selectedJob = response.data.posting
        //
        //           let payRange = selectedJob.payRange
        //           let pay = ''
        //
        //           if (selectedJob) {
        //             if (payRange.includes(" - ")) {
        //               let number1 = Number(payRange.substring(1,3))
        //               let number2 = Number(payRange.substring(7,9))
        //               pay = ((number1 + number2) / 2).toString()
        //
        //             } else if (payRange.includes("+")) {
        //               pay = payRange.substring(1,4)
        //             } else if (payRange === "Less") {
        //               pay = '9'
        //             } else if (payRange === "Unpaid") {
        //               pay = '0'
        //             } else if (payRange === "Wide Range") {
        //               pay = '15'
        //             }
        //           }
        //
        //           let startDate = selectedJob.startDate
        //           let endDate = selectedJob.endDate
        //           let hoursPerWeek = selectedJob.hoursPerWeek
        //
        //           if (selectedJob.employerContactFirstName) {
        //             employerContactFirstName = selectedJob.employerContactFirstName
        //           }
        //
        //           if (selectedJob.employerContactLastName) {
        //             employerContactLastName = selectedJob.employerContactLastName
        //           }
        //
        //           if (selectedJob.employerContactEmail) {
        //             employerContactEmail = selectedJob.employerContactEmail
        //           }
        //
        //           this.setState({ selectedJob, pay, startDate, endDate, hoursPerWeek,
        //             employerContactFirstName, employerContactLastName, employerContactEmail
        //           })
        //
        //           const benchmarkId = selectedJob.benchmarkId
        //
        //           Axios.get('/api/benchmarks/byid', { params: { _id: benchmarkId} })
        //           .then((response) => {
        //             console.log('Benchmarks query attempted', response.data);
        //
        //             if (response.data.success) {
        //               console.log('benchmark query worked')
        //
        //               const benchmark = response.data.benchmark
        //               this.setState({ benchmark })
        //
        //               Axios.get('/api/applications/bypost', { params: { postingId: selectedJob._id } })
        //               .then((response) => {
        //                 console.log('Applications query attempted', response.data);
        //
        //                 if (response.data.success) {
        //                   console.log('applications query worked')
        //
        //                   if (response.data.applications.length !== 0) {
        //
        //                     this.calculateMatches(response.data.applications, selectedJob, benchmark)
        //
        //                   }
        //
        //                 } else {
        //                   console.log('applications query did not work', response.data.message)
        //                 }
        //               }).catch((error) => {
        //                   console.log('Application query did not work for some reason', error);
        //               });
        //
        //             } else {
        //               console.log('benchmark query did not work', response.data.message)
        //             }
        //
        //           }).catch((error) => {
        //               console.log('Benchmark query did not work for some reason', error);
        //           });
        //
        //         } else {
        //           console.log('posted jobs query did not work', response.data.message)
        //         }
        //
        //     }).catch((error) => {
        //         console.log('Posted jobs query did not work for some reason', error);
        //     });
        //
        //     Axios.get('/api/org', { params: { orgCode: activeOrg } })
        //     .then((response) => {
        //       console.log('Org info query attempted', response.data);
        //
        //         if (response.data.success) {
        //           console.log('org info query worked')
        //
        //           this.setState({
        //               org: activeOrg,
        //
        //               orgName: response.data.orgInfo.orgName,
        //               orgURL: response.data.orgInfo.orgURL,
        //               orgType: response.data.orgInfo.orgType,
        //               orgProgramName: response.data.orgInfo.orgProgramName,
        //               orgDescription: response.data.orgInfo.orgDescription,
        //               orgMission: response.data.orgInfo.orgMission,
        //
        //               orgContactFirstName: response.data.orgInfo.contactFirstName,
        //               orgContactLastName: response.data.orgInfo.contactLastName,
        //               orgContactTitle: response.data.orgInfo.contactTitle,
        //               orgContactEmail: response.data.orgInfo.contactEmail,
        //
        //           });
        //
        //         } else {
        //           console.log('org info query did not work', response.data.message)
        //         }
        //
        //       }).catch((error) => {
        //         console.log('Org info query did not work for some reason', error);
        //       });
        //   }
        // }).catch((error) => {
        //   console.log('Account info query did not work for some reason', error);
        // });

      } else {
        //this person needs to log in
      }
    }

    calculateMatches(topCandidates, selectedJob, benchmark, orgCode) {
      console.log('calculateMatches called: ', topCandidates, selectedJob, benchmark, orgCode)

      for (let i = 1; i <= topCandidates.length; i++) {
        console.log(i, 'calculating matches ');

        const application = topCandidates[i - 1]
        const interviewScore = null
        const applicationCount = i

        Axios.post('/api/applications/matches', {
          application, selectedJob, benchmark, interviewScore })
        .then((response) => {
          console.log('application matches query attempted')
          if (response.data.success) {
            //save values
            console.log('Application matches save worked: ', response.data);

            topCandidates[applicationCount - 1] = response.data.application
            this.setState({ topCandidates })
            // if (topCandidates.length === applicationCount) {
            //   console.log('equated')
            //   topCandidates.sort(function(a,b) {
            //     return a.match - b.match;
            //   })
            //
            //   let topCandidatesArray = []
            //   for (let j = 1; j <= applications.length; j++) {
            //     //console.log(i)
            //
            //     if (!orgCode || orgCode === '' || orgCode === 'guidedcompass') {
            //       console.log('t0', selectedJob, applications[j - 1].partnerEvaluations)
            //
            //       // sent by placement partners
            //       if (applications[j - 1].partnerEvaluations && applications[j - 1].partnerEvaluations.length > 0) {
            //         console.log('in this org 2')
            //         for (let k = 1; k <= applications[j - 1].partnerEvaluations.length; k++) {
            //           if (applications[i - 1].partnerEvaluations[k - 1].rank) {
            //             applications['referrerOrg'] = applications[i - 1].orgCode
            //             topCandidatesArray.push(applications[j - 1])
            //           }
            //         }
            //       } else {
            //         // recommended by Guided Compass?
            //         console.log('add rank')
            //         if (applications[j - 1].rank) {
            //           topCandidatesArray.push(applications[j - 1])
            //         }
            //       }
            //
            //     } else {
            //       if (applications[j - 1].rank) {
            //         topCandidatesArray.push(applications[j - 1])
            //       }
            //     }
            //   }
            //
            //   topCandidatesArray.sort(function(a,b) {
            //     return a.rank - b.rank;
            //   })
            //
            //   let topCandidates = this.state.topCandidates
            //   topCandidates[index] = topCandidatesArray
            //   console.log('show values--', applicationsArray, topCandidates, this.state.applications)
            //   this.setState({ applications: applicationsArray, topCandidates })
            //
            // }

          } else {
            console.log('application matches did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Application matches did not work for some reason', error);
        });

      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called')

      if (event.target.name === 'employerCode') {
        this.setState({ employerCode: event.target.value })
      } else if (event.target.name.includes('employerFeedback')) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1])

        let topCandidates = this.state.topCandidates
        topCandidates[index]['employerFeedback'] = event.target.value

        let formHasChangedArray = this.state.formHasChangedArray
        formHasChangedArray[index] = true

        this.setState({ topCandidates, formHasChangedArray })

        // console.log('what is topCandidates', topCandidates)
      } else if (event.target.name.includes('employerRanking')) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1])

        let topCandidates = this.state.topCandidates
        topCandidates[index]['employerRanking'] = event.target.value

        let formHasChangedArray = this.state.formHasChangedArray
        formHasChangedArray[index] = true

        this.setState({ topCandidates, formHasChangedArray })
      } else if (event.target.name === 'startDate') {
        this.setState({ startDate: event.target.value })
      } else if (event.target.name === 'endDate') {
        this.setState({ endDate: event.target.value })
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'hoursPerWeek') {
        this.setState({ hoursPerWeek: event.target.value })
      } else if (event.target.name === 'pay') {
        this.setState({ pay: event.target.value })
      } else if (event.target.name === 'isOnsite') {
        this.setState({ isOnsite: event.target.value })
      } else if (event.target.name === 'workLocation') {
        this.setState({ workLocation: event.target.value })
      } else if (event.target.name === 'offerLetterURL') {
        this.setState({ offerLetterURL: event.target.value })
      } else if (event.target.name === 'expirationDate') {
        this.setState({ expirationDate: event.target.value })
      }
    }

    likeClicked(isLiked, isDisliked, index) {
      console.log('likeClicked clicked', isLiked, isDisliked, index)

      let topCandidates = this.state.topCandidates
      if (isLiked) {
        if (topCandidates[index].liked) {
          topCandidates[index]['liked'] = false
        } else {
          topCandidates[index]['liked'] = true
          topCandidates[index]['disliked'] = false
        }
      }

      if (isDisliked) {
        if (topCandidates[index].disliked) {
          topCandidates[index]['disliked'] = false
        } else {
          topCandidates[index]['disliked'] = true
          topCandidates[index]['liked'] = false
        }
      }

      this.setState({ topCandidates })

      const _id = this.state.topCandidates[index]._id
      const liked = this.state.topCandidates[index].liked
      const disliked = this.state.topCandidates[index].disliked
      const updatedAt = new Date()

      Axios.post('/api/applications/update-by-id', { //sessions: sessions
        _id, liked, disliked, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Your changes have been saved', response.data);

          this.setState({ serverSuccessMessage: 'Your changes have been saved' })

        } else {
          console.error('there was an error saving the application');
          this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
          this.setState({ serverErrorMessage: 'there was an error saving ' + error })
      });
    }

    connectEmployer(index) {
      console.log('connectEmployer called', index)

      this.setState({ serverSuccessMessage: '', serverErrorMessage: ''})

      const requestToInterview = this.state.requestToInterview
      const accountCode = this.state.selectedJob.accountCode
      const postingId = this.state.selectedJob._id
      const postingName = this.state.selectedJob.title

      let candidateFirstName = this.state.topCandidates[index].firstName
      let candidateLastName = this.state.topCandidates[index].lastName
      let candidateEmail = this.state.topCandidates[index].email
      const employerMessage = this.state.topCandidates[index].employerMessage

      const orgName = this.state.orgName
      const orgContactFirstName = this.state.orgContactFirstName
      const orgContactLastName = this.state.orgContactLastName
      const orgContactEmail = this.state.orgContactEmail
      const orgContactTitle = this.state.orgContactTitle
      const orgProgramName = this.state.orgProgramName

      const employerName = this.state.employerName
      const employerContactFirstName = this.state.cuFirstName
      const employerContactLastName = this.state.cuLastName
      const employerContactEmail = this.state.emailId

      let orgMode = this.state.orgMode
      let placementPartners = this.state.selectedJob.placementPartners

      console.log('we got employerContactEmail ', employerContactEmail)

      //send email to employer
      Axios.post('/api/top-candidates/connect', {
        requestToInterview, accountCode, postingId, postingName,
        candidateFirstName, candidateLastName, candidateEmail, employerMessage,
        orgName, orgContactFirstName, orgContactLastName, orgContactEmail, orgContactTitle, orgProgramName,
        orgMode, placementPartners,
        employerName, employerContactFirstName, employerContactLastName, employerContactEmail
      })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Message successfully sent', response.data);
          this.setState({ serverSuccessMessage: response.data.message })

          // const _id = this.state.topCandidates[index]._id
          // const connected = true
          // const updatedAt = new Date()

          let topCandidates = this.state.topCandidates
          topCandidates[index]['stage'] = 'Connected'
          if (requestToInterview) {
            topCandidates[index]['stage'] = 'Interview Requested'
          }
          this.setState({ serverSuccessMessage: 'Your changes have been saved', topCandidates, modalIsOpen: false, showInterview: false })

        } else {
          console.error('there was an error sending the message');
          this.setState({ serverErrorMessage: 'There was an error sending out the message ' + response.data.message})
        }
      }).catch((error) => {
          console.log('there was an error sending the message', error);
          this.setState({ serverErrorMessage: 'There was an error sending out the message ' + error})
      });
    }

    saveFeedback(index) {
      console.log('saveFeedback called', index)

      const _id = this.state.topCandidates[index]._id
      const employerFeedback = this.state.topCandidates[index].employerFeedback

      const postingName = this.state.selectedJob.title
      const candidateFirstName = this.state.topCandidates[index].firstName
      const candidateLastName = this.state.topCandidates[index].lastName
      const candidateEmail = this.state.topCandidates[index].email
      const candidateUsername = this.state.topCandidates[index].username
      const candidatePictureURL = this.state.topCandidates[index].pictureURL
      const employerName = this.state.employerName
      const orgName = this.state.orgName

      const orgContactEmail = this.state.orgContactEmail

      const orgMode = this.state.orgMode
      const placementPartners = this.state.selectedJob.placementPartners

      const updatedAt = new Date()

      Axios.post('/api/applications/update-by-id', {
        _id, employerFeedback, postingName,
        candidateFirstName, candidateLastName, candidateEmail, candidateUsername, candidatePictureURL,
        employerName, orgName, orgMode, placementPartners, orgContactEmail, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);

          this.setState({ serverSuccessMessage: 'Successfully saved!' })

        } else {
          console.error('there was an error saving the application');
          this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
          this.setState({ serverErrorMessage: 'there was an error saving ' + error })
      });
    }

    sendFeedback() {
      console.log('sendFeedback called')

      // const _id = this.state.topCandidates[index]._id
      // const employerFeedback = this.state.topCandidates[index].employerFeedback
      // const candidateFirstName = this.state.topCandidates[index].firstName
      // const candidateLastName = this.state.topCandidates[index].lastName
      // const candidateEmail = this.state.topCandidates[index].email

      const topCandidates = this.state.topCandidates
      const postingName = this.state.selectedJob.title
      const employerName = this.state.employerName
      const employerContactEmail = this.state.selectedJob.employerContactEmail
      const orgName = this.state.orgName
      const orgContactFirstName = this.state.orgContactFirstName
      const orgContactEmail = this.state.orgContactEmail
      const orgMode = this.state.orgMode
      const placementPartners = this.state.selectedJob.placementPartners

      // const updatedAt = new Date()

      Axios.post('/api/applications/send-feedback', { //sessions: sessions
        topCandidates, postingName, employerName, employerContactEmail, orgName, orgContactEmail, orgContactFirstName,
        orgMode, placementPartners})
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);

          this.setState({ serverSuccessMessage: response.data.message })

        } else {
          console.error('there was an error saving the application');
          this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
          this.setState({ serverErrorMessage: 'there was an error saving ' + error })
      });
    }

    makeOffer(index) {
      console.log('makeOffer called')

      const candidateIndex = index
      this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showOffer: true, candidateIndex });
    }

    // viewDetails(type, index) {
    //   console.log('viewDetails ', type, index)
    //
    //   const candidateIndex = index
    //   if (type === 'endorsements') {
    //     this.setState({ modalIsOpen: true, showEndorsements: true, showMatchDetails: false, showProjects: false, showTraits: false, showOffer: false, showInterview: false, candidateIndex });
    //   } else if (type === 'match') {
    //     this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: true, showProjects: false, showTraits: false, showOffer: false, showInterview: false, candidateIndex });
    //   } else if (type === 'projects') {
    //     this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: true, showTraits: false, showOffer: false, showInterview: false, candidateIndex });
    //   } else if (type === 'personality') {
    //     this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: true, showOffer: false, showInterview: false, candidateIndex });
    //   } else if (type === 'interview') {
    //     this.setState({ modalIsOpen: true, showEndorsements: false, showMatchDetails: false, showProjects: false, showTraits: false, showOffer: false, showInterview: true, candidateIndex });
    //   }
    // }

    // viewEndorsements(index) {
    //   console.log('viewEndorsements called')
    //
    //   this.setState({ modalIsOpen: true, showOffer: false, showEndorsements: true, showMatchDetails: false })
    //   //notate candidate selected?
    //   // this.openModal()
    // }

    // openModal() {
    //   this.setState({modalIsOpen: true});
    // }

    // afterOpenModal() {
    //   // references are now sync'd and can be accessed.
    //   //this.subtitle.style.color = '#f00';
    // }

    closeModal() {
      this.setState({ modalIsOpen: false, showOffer: false, showInterview: false });
    }

    sendOffer(index) {
      console.log('sendOffer sent')

      const applicationId = this.state.topCandidates[index]._id
      const firstName = this.state.topCandidates[index].firstName
      const lastName = this.state.topCandidates[index].lastName
      const email = this.state.topCandidates[index].email

      const empContactFirstName = this.state.cuFirstName
      const empContactLastName = this.state.cuLastName
      const empContactEmail = this.state.emailId

      const orgContactEmail = this.state.orgContactEmail
      const orgCode = this.state.org
      const accountCode = this.state.emp
      const orgContactFirstName = this.state.orgContactFirstName
      const orgContactLastName = this.state.orgContactLastName
      const orgContactTitle = this.state.orgContactTitle
      const orgName = this.state.orgName
      const orgProgramName = this.state.orgProgramName

      const title = this.state.selectedJob.title
      const employerName = this.state.selectedJob.employerName

      const startDate = this.state.startDate
      const endDate = this.state.endDate
      const hoursPerWeek = this.state.hoursPerWeek
      const pay = this.state.pay
      const isOnsite = this.state.isOnsite
      const workLocation = this.state.workLocation
      const offerLetterURL = this.state.offerLetterURL
      const expirationDate = this.state.expirationDate

      const postingId = this.state.postingId
      const isPerpetual = this.state.isPerpetual
      const workFunction = this.state.workFunction
      const industry = this.state.industry
      const description = this.state.description
      const pathways = this.state.pathways
      const employerURL = this.state.employerURL

      const orgMode = this.state.orgMode
      const placementPartners = this.state.selectedJob.placementPartners

      const createdAt = new Date()
      const updatedAt = new Date()

      if (startDate === '') {
        this.setState({ serverErrorMessage: 'Please add a start date for the offer'})
      } else if (this.state.isPerpetual === false &&  endDate === '') {
        this.setState({ clientErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role', isSaving: false })
      } else if (hoursPerWeek === '' ) {
        this.setState({ serverErrorMessage: 'Please add hours per week for the offer'})
      } else if (pay === '' ) {
        this.setState({ serverErrorMessage: 'Please add the amount of pay for the offer'})
      } else if (workLocation === '' ) {
        this.setState({ serverErrorMessage: 'Please add the work location for the offer'})
      } else if (offerLetterURL && offerLetterURL !== '' && !offerLetterURL.startsWith('http')) {
        this.setState({ serverErrorMessage: 'Please edit the offer letter url to start with http'})
      } else {

        //save offer and send email
        Axios.post('/api/applications/offer', {
          applicationId, firstName, lastName, email,
          title, employerName, orgContactEmail,
          startDate, endDate, hoursPerWeek, pay, isOnsite, workLocation, offerLetterURL, expirationDate,
          orgContactFirstName, orgContactLastName, orgContactTitle, orgName, orgProgramName,
          empContactFirstName, empContactLastName, empContactEmail, employerURL,
          orgCode, accountCode, orgMode, placementPartners,
          postingId, isPerpetual, workFunction, industry, description, pathways,
          createdAt, updatedAt
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message successfully sent', response.data);

            let topCandidates = this.state.topCandidates
            topCandidates[index]['stage'] = 'Offer'

            this.setState({
              modalIsOpen: false, serverSuccessMessage: 'Offer sent to candidate! View and edit outstanding offers from your dashboard.', topCandidates })

          } else {
            console.error('there was an error sending the message');
            this.setState({ serverErrorMessage: 'There was an error sending out the message ' + response.data.message})
          }
        }).catch((error) => {
            console.log('there was an error sending the message', error);
            this.setState({ serverErrorMessage: 'There was an error sending out the message ' + error})
        });
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      if (this.state.emp === '') {
        return (
          <div>
            <p>You are not authorized to view this page. Please login and then return to this page.</p>
          </div>
        )
      } else {
        if (this.state.selectedJob) {

          let feedbackPlaceholder = "…any feedback on this candidate? How can matches improve?"
          if (this.state.orgName) {
            feedbackPlaceholder = "…any feedback for " + this.state.orgName + " on this candidate? How can matches improve?"
          }

          return (
              <div>
                <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
                <div>
                  <div className="half-spacer" />
                  { (this.state.topCandidates.length !== 0) ? (
                    <div className="candidate-container padding-40">
                      <div className="spacer" />
                      {(this.state.selectedJob) ? (
                        <p className="heading-text-2"><label className="cta-color bold-text">{this.state.topCandidates.length}</label> Top Candidates for {this.state.selectedJob.title}</p>
                      ) : (
                        <p className="heading-text-2">Top Candidates</p>
                      )}

                      <div className="spacer"/>

                      {(this.state.offer) && (
                        <div className="row=10">
                          {(!this.state.offer.decision) && (
                            <p className="error-color">An offer has been extended to {this.state.offer.firstName} {this.state.offer.lastName} for this role.</p>
                          )}
                          {(this.state.offer.decision === 'accept') && (
                            <p className="error-color">{this.state.offer.firstName} {this.state.offer.lastName} has accepted your offer for this role.</p>
                          )}
                        </div>
                      )}

                      {this.state.topCandidates.map((item, index) => (
                        <div>

                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="heading-text-4">{index + 1}. {item.firstName} {item.lastName}</p>
                          <div className="spacer"/>

                          {(this.state.offer && this.state.offer.email === item.email && this.state.offerMessage) && (
                            <div className="row-5">
                              <p className="error-color description-text-2 half-bold-text">{this.state.offerMessage}</p>
                            </div>
                          )}

                          <ProfileCard selectedApplication={item} selectedJob={this.state.selectedJob} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} wpQuestions={this.state.wpQuestions} source="Employer" employerName={this.state.employerName} employerContactFirstName={this.state.employerContactFirstName} employerContactEmail={this.state.employerContactEmail} orgName={this.state.orgName} orgContactFirstName={this.state.orgContactFirstName} orgContactLastName={this.state.orgContactLastName} orgContactEmail={this.state.orgContactEmail} orgContactTitle={this.state.orgContactTitle} orgProgramName={this.state.orgProgramName} accountCode={this.state.emp} />

                          <div className="clear" />
                          <div className="spacer"/><div className="half-spacer"/>
                          {(item.employerMessage && this.state.orgName) && (
                            <p><label className="bold-text">{this.state.orgName}</label> Comments: {item.employerMessage}</p>
                          )}

                          <div className="spacer"/><div className="half-spacer"/>

                          {(this.state.onlyFeedback || this.state.orgMode) ? (
                            <div>
                              <div className="row-10">
                                <label className="profile-label">Do you like this candidate?</label>
                                <div className="float-left right-padding">
                                  <button className="btn background-button" onClick={() => this.likeClicked(true, false, index)}><img src={( item.liked === true) ? thumbsUpBlueIcon : thumbsUpIcon} alt="Guided Compass thumbs up icon" className="image-auto-30"/></button>
                                </div>
                                <div className="float-left right-padding">
                                  <button className="btn background-button" onClick={() => this.likeClicked(false, true, index)}><img src={( item.disliked === true) ? thumbsDownOrangeIcon : thumbsDownIcon} alt="Guided Compass thumbs down icon" className="image-auto-30" /></button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="row-10">
                                <label className="profile-label">Of these referred candidates, what do you rank this candidate?</label>
                                <select name={"employerRanking|" + index} value={item.employerRanking} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.rankingOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="row-10">
                                <label className="profile-label">Please share your overall feedback</label>

                                <textarea type="text" className="text-field" placeholder={feedbackPlaceholder} name={"employerFeedback|" + index} value={item.employerFeedback} onChange={this.formChangeHandler}></textarea>
                                {/*
                                {(this.state.formHasChangedArray[index]) && (
                                  <div>
                                    <div className="spacer"/>
                                    <button className="btn btn-squarish" onClick={() => this.saveFeedback(index)}>Send Feedback</button>
                                  </div>
                                )}
                                {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                                {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}*/}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <div className="float-left right-padding">
                                  <button className="btn background-button" onClick={() => this.likeClicked(true, false, index)}><img src={( item.liked === true) ? thumbsUpBlueIcon : thumbsUpIcon} alt="Guided Compass thumbs up icon" className="image-auto-30"/></button>
                                </div>
                                <div className="float-left right-padding">
                                  <button className="btn background-button" onClick={() => this.likeClicked(false, true, index)}><img src={( item.disliked === true) ? thumbsDownOrangeIcon : thumbsDownIcon} alt="Guided Compass thumbs down icon" className="image-auto-30" /></button>
                                </div>
                                {this.state.orgName && (
                                  <div>

                                  {(this.state.offer && this.state.offer.email === item.email && this.state.offerMessage) ? (
                                    <div>
                                      <p className="error-color">{this.state.offerMessage}</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="float-left right-padding">
                                        {console.log('plz:', item.stage)}
                                        {(item.stage === 'Connected' || item.stage === 'Interview Requested' || item.stage === 'Offer' || item.stage === 'Hired') ? (
                                          <div className="white-text cta-color row-5 horizontal-padding-4 rounded-corners top-margin-negative-5 medium-background">
                                            {(this.state.requestToInterview) ? (
                                              <p>{item.stage}</p>
                                            ) : (
                                              <p>{item.stage}</p>
                                            )}
                                          </div>
                                        ) : (
                                          <button className="btn background-button" onClick={() =>  this.setState({ modalIsOpen: true, showInterview: true, candidateIndex: index })}>
                                            <div className="white-text cta-color row-5 horizontal-padding-4 rounded-corners top-margin-negative-5 cta-background-color">
                                              {(this.state.requestToInterview) ? (
                                                <p>Request an Interview</p>
                                              ) : (
                                                <p>Interview {item.firstName}</p>
                                              )}
                                            </div>
                                          </button>
                                        )}
                                      </div>

                                      <div className="float-left right-padding">
                                        {(item.stage === 'Offer') ? (
                                          <button className="btn background-button" onClick={() => this.makeOffer(index)}>
                                            <div className="white-text cta-color row-5 horizontal-padding-4 rounded-corners top-margin-negative-5 medium-background">
                                              <p>Awaiting Response On Your Offer</p>
                                            </div>
                                          </button>
                                        ) : (
                                          <button className="btn background-button" onClick={() => this.makeOffer(index)}>
                                            <div className="cta-color cta-border row-5 horizontal-padding-4 rounded-corners top-margin-negative-5">
                                              <p>Make an Offer</p>
                                            </div>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  </div>
                                )}
                              </div>
                              <div className="clear"/>
                              {(!this.state.orgMode) && (
                                <div>
                                  {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                                  {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                                  <div className="spacer"/><div className="half-spacer"/>
                                  <textarea type="text" className="text-field" placeholder={feedbackPlaceholder} name={"employerFeedback|" + index} value={item.employerFeedback} onChange={this.formChangeHandler}></textarea>
                                  {(this.state.formHasChangedArray[index]) && (
                                    <div>
                                      <div className="spacer"/>
                                      <button className="btn btn-squarish" onClick={() => this.saveFeedback(index)}>Send Feedback</button>
                                    </div>
                                  )}
                                </div>
                              )}

                            </div>
                          )}

                          {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                          {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}

                          <div className="spacer" /><div className="spacer" /><div className="spacer" />

                          <hr />
                        </div>
                      ))}

                      {(this.state.orgMode) && (
                        <div className="row-20">
                          <button className="btn btn-squarish" onClick={() => this.sendFeedback()}>Send All Feedback</button>
                        </div>
                      )}

                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                      >


                        {(this.state.showInterview) && (
                          <div className="padding-20">
                            <p className="heading-text-3">Interview {this.state.topCandidates[this.state.candidateIndex].firstName} {this.state.topCandidates[this.state.candidateIndex].lastName}</p>
                            <p className="top-padding description-text-2">{this.state.selectedJob.title} @ {this.state.selectedJob.employerName}</p>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            {(this.state.requestToInterview) ? (
                              <div>
                                <p>Guided Compass will send an email notification to {this.state.orgName} that you would like to {this.state.topCandidates[this.state.candidateIndex].firstName} {this.state.topCandidates[this.state.candidateIndex].lastName}.</p>
                              </div>
                            ) : (
                              <div>
                                {(this.state.orgMode) ? (
                                  <p>Guided Compass will automatically send an email connecting you to {this.state.topCandidates[this.state.candidateIndex].firstName} for an interview, CCing {this.state.orgName}</p>
                                ) : (
                                  <p>Guided Compass will automatically send an email connecting you to {this.state.topCandidates[this.state.candidateIndex].firstName} for an interview</p>
                                )}
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="spacer" /><div className="spacer" />
                            {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}

                            <div className="display-inline right-padding">
                              <button className="btn btn-primary" onClick={() => this.connectEmployer(this.state.candidateIndex)}>Send</button>
                            </div>
                            <div className="display-inline">
                              <button className="btn btn-secondary" onClick={() => this.closeModal(this.state.candidateIndex)}>Close</button>
                            </div>

                            <div className="spacer"/>
                          </div>
                        )}

                        {(this.state.showOffer) && (
                          <div className="padding-20">
                            <p className="heading-text-4">Make an Offer to {this.state.topCandidates[this.state.candidateIndex].firstName} {this.state.topCandidates[this.state.candidateIndex].lastName}</p>
                            <p className="top-padding description-text-2">{this.state.selectedJob.title} @ {this.state.employerName}</p>
                            <div className="spacer" />

                            <div>
                              <div className="anonymous-checkmark">
                                <input type="checkbox" name="isPerpetual" checked={this.state.isPerpetual} onChange={this.formChangeHandler} />
                              </div>
                              <div className="anonymous-descriptor">
                                <label className="description-text-1">This role is continual</label>
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 description-text-color bottom-padding-5">Start Date</p>
                                <input type="date" className="date-field" placeholder="Add the start date..." name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                              </div>

                              <div className="container-right">
                                {(this.state.isPerpetual) ? (
                                  <div>
                                    <p className="description-text-2 description-text-color bottom-padding-5">End Date</p>
                                    <label className="heading-text-6">Continual</label>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="description-text-2 description-text-color bottom-padding-5">End Date</p>
                                    <input type="date" className="date-picker" placeholder="Add the end date..." name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                                  </div>
                                )}
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 description-text-color bottom-padding-5">Hours / Week</p>
                                <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.hoursPerWeekOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="container-right">
                                <p className="description-text-2 description-text-color bottom-padding-5">Hourly Pay</p>
                                <input type="text" className="text-field" placeholder="Add pay..." name="pay" value={this.state.pay} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 description-text-color bottom-padding-5">On-Site?</p>
                                <select name="isOnsite" value={this.state.isOnsite} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.onsiteOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="container-right">
                                <p className="description-text-2 description-text-color bottom-padding-5">Work Address</p>
                                <input type="text" className="text-field" placeholder="Add the address..." name="workLocation" value={this.state.workLocation} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 description-text-color bottom-padding-5">Offer Letter URL</p>
                                <input type="text" className="text-field" placeholder="Add the offer letter URL..." name="offerLetterURL" value={this.state.offerLetterURL} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="container-right">
                                <p className="description-text-2 description-text-color bottom-padding-5">Expiration Date</p>
                                <input type="date" className="date-field" placeholder="When does this expire..." name="expirationDate" value={this.state.expirationDate} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer"/><div className="half-spacer"/>
                            {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}

                            <div className="display-inline right-padding">
                              <button className="btn btn-primary" onClick={() => this.sendOffer(this.state.candidateIndex)}>Send</button>
                            </div>
                            <div className="display-inline">
                              <button className="btn btn-secondary" onClick={() => this.closeModal(this.state.candidateIndex)}>Close</button>
                            </div>

                            <div className="spacer"/>

                          </div>
                        )}

                      </Modal>
                    </div>
                  ) : (
                    <div className="standard-container-2">
                        <p>There are no candidates yet</p>
                    </div>
                  )}
                </div>

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
              </div>

          )
        } else {
          return (
            <div>
              <p>There was an error retrieving data</p>
            </div>
          )
        }
      }
    }
}

export default withRouter(EmpTopCandidates)
