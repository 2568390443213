import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAssessments from './Subcomponents/Assessments';
import withRouter from './Functions/WithRouter';

class Assessments extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, orgLogo })
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg}  orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace}  history={this.props.navigate}/>

            <div>
              <div className="standard-container-2">
                <SubAssessments email={this.state.emailId} />
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Assessments)
