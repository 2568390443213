import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class CustomAssessments extends Component {
    constructor(props) {
        super(props)

        this.state = {
          resLimit: 500,
          opacity: 0,
          transform: 'translate(90%)',

          templates: [],
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderTemplates = this.renderTemplates.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

    }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
        // status, activities (checklist), assessment
        this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.org !== prevProps.org ||this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props.activeOrg)

      let orgFocus = localStorage.getItem('orgFocus');
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      // let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');

      if (this.props.org) {

        let org = this.props.org

        const menuOptions = [
          { icon: exchangeIconDark, title: 'Duplicate assessment', shorthand: 'duplicate' },
          { icon: editIconDark, title: 'Edit assessment', shorthand: 'edit' },
          { icon: deleteIconDark, title: 'Delete assessment', shorthand: 'delete' },
        ]

        this.setState({ org, emailId: email, username, orgFocus, roleName, menuOptions })

        const orgCodes = [org,'general']
        const resLimit = this.state.resLimit

        Axios.get('/api/customassessments', { params: { orgCodes, resLimit, prioritizeRecent: true } })
        .then((response) => {
          console.log('Custom assessment query attempted', response.data);

          if (response.data.success) {
            console.log('custom assessment query worked')

            if (response.data.assessments.length !== 0) {

              let templates = response.data.assessments
              this.setState({ templates });

            }

          } else {
            console.log('custom assessment query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
        });

        if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

        } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
        }
      }
    }

    renderTemplates() {
      console.log('renderTemplates called')

      let rows = [];
      if ( this.state.templates.length > 0 ) {

        // rows.push(
        //   <div key={0}>
        //     <hr className="cell-separator-advisor"/>
        //     <div className="spacer" />
        //   </div>
        // )

        for (let i = 1; i <= this.state.templates.length; i++) {
          console.log(i);

          let passedLink = '/organizations/' + this.state.org + '/assessments/' + this.state.templates[i - 1]._id
          if (window.location.pathname.startsWith('/advisor')) {
            passedLink = '/advisor/assessments/' + this.state.templates[i - 1]._id
          }

          rows.push(
            <div key={i} className="row-10">
              <Link className="secret-link full-width" to={passedLink} state={{ selectedTemplate: this.state.templates[i - 1] }}>
                <span className="calc-column-offset-110 right-padding">
                  <div className="fixed-column-40">
                    <p className="heading-text-5">{i}.</p>
                  </div>
                  <div className="calc-column-offset-40">
                    <p className="heading-text-5">{this.state.templates[i - 1].title}</p>
                    <p className="profile-descriptor top-margin-5">{this.state.templates[i - 1].questions ? this.state.templates[i - 1].questions.length : "0"} Questions</p>
                  </div>
                  <div className="clear" />
                </span>
                {(this.state.templates[i - 1].orgCode === 'general' && this.state.org !== 'guidedcompass') ? (
                  <div className="fixed-column-40 top-margin right-padding">
                    <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="noEdit" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                  </div>
                ) : (
                  <div className="fixed-column-40 height-30" />
                )}
                <div className="fixed-column-40 right-padding top-margin-5 relative-position">
                  <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,i - 1)}>
                    <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                  </button>


                  {(this.state.templates[i - 1].showMenu) && (
                    <div className="menu-bottom-right top-margin">
                      {this.state.menuOptions.map((item,optionIndex) =>
                        <div key={item.title}>
                          {((item.title.includes('Delete') && this.state.templates[i - 1].orgCode === this.state.org) || !item.title.includes('Delete')) && (
                            <div>
                              {(item.title === 'Delete assessment' && this.state.templates[i - 1].confirmDelete) ? (
                                <div>
                                  <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                  <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(i - 1)}>Confirm</button>
                                  <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(item.shorthand,i - 1,e)}>Cancel</button>
                                </div>
                              ) : (
                                <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(item.shorthand,i - 1,e)}>
                                  <div className="fixed-column-25">
                                    <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p className={(item.title === 'Delete assessment') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                  </div>
                                  <div className="clear" />
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="fixed-column-30 top-margin">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                </div>
                <div className="clear" />
              </Link>

              {(this.state.templates[i - 1].successMessage) && (
                <div className="row-5 left-padding">
                  <p className="description-text-2 cta-color full-width">{this.state.templates[i - 1].successMessage}</p>
                </div>
              )}

              <ReactTooltip id="noEdit" />
            </div>
          )
        }
      } else {
        //show empty state screen

      }

      return rows;
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      e.stopPropagation()
      e.preventDefault()

      let templates = this.state.templates
      if (this.state.templates[index].showMenu) {
        templates[index]['showMenu'] = false
      } else {
        templates[index]['showMenu'] = true
      }

      this.setState({ templates })

    }

    menuButtonClicked(action, index,e) {
      console.log('menuButtonClicked called', action, index,e)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'duplicate') {
        let pathname = '/organizations/' + this.state.org + '/assessments/add'
        if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/assessments/add'
        }

        let selectedTemplate = this.state.templates[index]
        this.props.navigate(pathname, { state: { selectedTemplate, duplicate: true }})
      } else if (action === 'edit') {
        let pathname = '/organizations/' + this.state.org + '/assessments/' + this.state.templates[index]._id
        if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/assessments/' + this.state.templates[index]._id
        }
        this.props.navigate(pathname, { state: { selectedTemplate: this.state.templates[index] }})

      } else if (action === 'delete') {
        let templates = this.state.templates
        if (templates[index].confirmDelete) {
          templates[index]['confirmDelete'] = false
        } else {
          templates[index]['confirmDelete'] = true
        }
        this.setState({ templates })

      }
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      let templates = this.state.templates
      const _id = templates[index]._id

      Axios.delete('/api/customassessments/' + _id)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked')

          templates.splice(index,1)
          this.setState({ templates, isSaving: false })

        } else {
          console.error('there was an error deleting the posting');

          templates[index]['errorMessage'] = response.data.message
          this.setState({ templates, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          templates[index]['errorMessage'] = error.toString()
          this.setState({ templates, isSaving: false })
      });
    }

    render() {

      let addAsssessmentLink = '/organizations/' + this.state.org + '/assessments/add'
      if (window.location.pathname.includes('/advisor')) {
        addAsssessmentLink = '/advisor/assessments/add'
      }

      return (
          <div>
            <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card" : "floating-container card top-margin-2-percent right-margin-2-percent"} style={(toggleVars.hideSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              <div className={(toggleVars.hideAdminSideNav) ? "" : ""}>
                <div className="calc-column-offset-25 right-padding">
                  <p className="heading-text-2">{(this.state.templates) ? (this.state.templates.length === this.state.resLimit) ? this.state.templates.length + "+ " : this.state.templates.length + " " : ""}{(window.location.pathname.includes('/advisor')) ? "Quizzes" : "Assessments"}</p>
                </div>
                <div className="fixed-column-25">
                  <Link to={addAsssessmentLink}>
                    <img src={addIcon} alt="GC" className="image-auto-25"/>
                  </Link>
                </div>
                <div className="clear"/>
              </div>

              <p className="description-text-2 row-5 standard-color">Create and save custom assessment templates and quizzes that you can add to your learning modules and postings.</p>
            </div>

            <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card" : "floating-container card top-margin-2-percent right-margin-2-percent"} style={(toggleVars.hideSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              {this.renderTemplates()}
              <div className="clear" />
            </div>
          </div>

      )
    }
}


export default withRouter(CustomAssessments);
