import React, {Component} from 'react';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import SubOpportunityDetails from '../components/Subcomponents/OpportunityDetails';
import withRouter from '../components/Functions/WithRouter';

class ProblemDetailsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        if (window.innerWidth > 768) {
        } else {
          //mobile
          this.setState({ onMobile: true })
        }

        if (this.props.location && this.props.location.state && this.props.location.state.selectedItem) {
          //send this logged out user out of the app
          let { selectedItem, selectedOpportunity } = this.props.location.state;
          console.log('show item: ', selectedItem)

          let selectedProblem = selectedOpportunity
          if (!selectedOpportunity && selectedItem) {
            selectedProblem = selectedItem
          }

          this.setState({ selectedProblem });
          this.retrieveData(selectedProblem)

        } else {
          console.log('went directly to the link')

          const { item } = this.props.params
          console.log('show item: ', item)
          Axios.get('/api/postings/byid', { params: { _id: item } })
          .then((response) => {
             console.log('Postings by id query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved posting')

               const selectedProblem = response.data.posting
               this.setState({ selectedProblem })
               this.retrieveData(selectedProblem)

             } else {
               console.log('no posting data found', response.data.message)
             }

          }).catch((error) => {
               console.log('Posting query did not work', error);
          });

        }
    }

    retrieveData(selectedProblem) {
      console.log('retrieveData called in probDeets')

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      this.setState({ emailId, activeOrg, orgFocus, roleName })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentOpportunityDetails ', this.props, prevProps)

      if (this.props.location && this.props.location.state && this.props.location.state.selectedOpportunity !== prevProps.location.state.selectedOpportunity) {
        console.log('new career selected in parent')
        this.setState({ selectedProblem: this.props.location.state.selectedOpportunity })
      }
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"problemDetailsPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

            <SubOpportunityDetails selectedOpportunity={this.state.selectedProblem} path={this.state.path} history={this.props.navigate} pageSource="landingPage" passedTypes={['Project','Assignment','Problem','Challenge']} />

          </div>
      )
    }
}

export default withRouter(ProblemDetailsPage);
