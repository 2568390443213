import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubEditBenchmark from '../components/Subcomponents/EditBenchmark';
import withRouter from '../components/Functions/WithRouter';

class CreateBenchmarkPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('jobs page just mounted')
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#F5F5F5";

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      this.setState({ emailId, activeOrg, orgFocus, roleName });

      if (this.props.params) {
        let orgCode = this.props.params.orgCode
        this.setState({ orgCode })
      }

    }

    render() {

        return (
          <div>
            <TopNavigation currentPage={"benchmarksPage"} darkBackground={true} />

            <div className="center-horizontally clear-float width-70-percent">
              <SubEditBenchmark activeOrg={this.state.orgCode} />
            </div>

            <Footer history={this.props.navigate} />
          </div>
        )
    }
}

export default withRouter(CreateBenchmarkPage);
