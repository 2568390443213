import React, {Component} from 'react';

import Axios from 'axios';
import Switch from 'react-switch';

import {convertDateToString} from '../Functions/ConvertDateToString';

import SubComments from '../Common/Comments';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";
const importIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/import-icon-blue.png";
const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditLesson extends Component {
    constructor(props) {
        super(props)

        this.state = {
          exchangeTypeOptions: ['','Video','Lesson Plan','Project Idea'],
          gradeLevelOptions: ['','All','6','7','8','9','10','11','12','Post Grad - Intermediate','Post Grad - Advanced'],
          careerPathTags: [],
          selectedCareerDetails: [],
          courseOptions: [],
          subNavCategories: ['Details','Submissions','Discussions'],
          subNavSelected: 'Details',
          isVisible: true,

          showCustomAssessment: true,
          customAssessment: { title: ''},
          mentorAssessment: { title: ''},
          customAssessmentOptions: [{ title : 'No Custom Assessments Saved'}],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.saveItem = this.saveItem.bind(this)
        this.saveLesson = this.saveLesson.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.renderText = this.renderText.bind(this)
        this.embedYouTubeVideo = this.embedYouTubeVideo.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.filterItems = this.filterItems.bind(this)
        this.addItem = this.addItem.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.openCurriculumExchange = this.openCurriculumExchange.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonEditPipeline', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.selectedPipeline !== prevProps.selectedPipeline) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in commonEditPipeline', this.props)

      let emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const pictureURL = localStorage.getItem('pictureURL');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');

      let _id = this.state._id
      let curriculumItemId = this.state.curriculumItemId
      let exchangeType = 'Independent Lesson'
      let itemName = this.state.itemName
      let description = this.state.description
      let minutes = this.state.minutes
      let submissionDeadline = this.state.submissionDeadline
      let url = this.state.url
      let studentURL = this.state.studentURL
      let tags = this.state.tags
      let careerPathTags = this.state.careerPathTags
      let gradeLevels = this.state.gradeLevels
      let learningObjectives = this.state.learningObjectives
      let prerequisites = this.state.prerequisites
      let resources = this.state.resources
      let activities = this.state.activities
      let assignmentQuestions = this.state.assignmentQuestions
      let mentorQuestions = this.state.mentorQuestions
      let limitToGroups = this.state.limitToGroups
      let courses = this.state.courses
      let customAssessmentId = this.state.customAssessmentId
      let mentorAssessmentId = this.state.mentorAssessmentId
      let isVisible = this.state.isVisible

      let selectedIndex = this.props.selectedIndex
      let selectedItem = null

      if (this.props.selectedLesson) {
        selectedItem = this.props.selectedLesson
        _id = this.props.selectedLesson._id
        curriculumItemId = this.props.selectedLesson.curriculumItemId
        exchangeType = this.props.selectedLesson.exchangeType
        itemName = this.props.selectedLesson.itemName
        description = this.props.selectedLesson.description
        minutes = this.props.selectedLesson.minutes
        if (this.props.selectedLesson.submissionDeadline) {
          submissionDeadline = convertDateToString(new Date(this.props.selectedLesson.submissionDeadline),"rawDateTimeForInput")
        }

        url = this.props.selectedLesson.url
        studentURL = this.props.selectedLesson.studentURL
        tags = this.props.selectedLesson.tags
        careerPathTags = this.props.selectedLesson.careerPathTags
        gradeLevels = this.props.selectedLesson.gradeLevels
        learningObjectives = this.props.selectedLesson.learningObjectives
        prerequisites = this.props.selectedLesson.prerequisites
        resources = this.props.selectedLesson.resources
        activities = this.props.selectedLesson.activities
        assignmentQuestions = this.props.selectedLesson.assignmentQuestions
        mentorQuestions = this.props.selectedLesson.mentorQuestions
        courses = this.props.selectedLesson.courses
        limitToGroups = this.props.selectedLesson.limitToGroups
        customAssessmentId = this.props.selectedLesson.customAssessmentId
        mentorAssessmentId = this.props.selectedLesson.mentorAssessmentId
        if (this.props.selectedLesson.isVisible || this.props.selectedLesson.isVisible === false) {
          isVisible = this.props.selectedLesson.isVisible
        }

        Axios.get('/api/submissions', { params: { lessonId: _id }})
        .then((response) => {
          console.log('Submissions query attempted', response.data);

            if (response.data.success) {
              console.log('submissions query worked')

              const submissions = response.data.submissions
              this.setState({ submissions })

            } else {
              console.log('submissions query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('submissions query did not work for some reason', error);
        });

        Axios.get('/api/comments', { params: { parentPostId: _id } })
        .then((response) => {
          console.log('Comments query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved comments')

             const comments = response.data.comments
             this.setState({ comments })

           } else {
             console.log('no comments data found', response.data.message)
           }
        }).catch((error) => {
           console.log('Comments query did not work', error);
        });

      } else if (this.props.selectedCurriculumItem) {
        selectedItem = this.props.selectedCurriculumItem
        _id = this.props.selectedCurriculumItem._id
        exchangeType = this.props.selectedCurriculumItem.exchangeType
        itemName = this.props.selectedCurriculumItem.itemName
        description = this.props.selectedCurriculumItem.description
        minutes = this.props.selectedCurriculumItem.minutes
        if (this.props.selectedCurriculumItem.submissionDeadline) {
          submissionDeadline = convertDateToString(new Date(this.props.selectedCurriculumItem.submissionDeadline),"rawDateTimeForInput")
        }

        url = this.props.selectedCurriculumItem.url
        studentURL = this.props.selectedCurriculumItem.studentURL
        tags = this.props.selectedCurriculumItem.tags
        careerPathTags = this.props.selectedCurriculumItem.careerPathTags
        gradeLevels = this.props.selectedCurriculumItem.gradeLevels
        learningObjectives = this.props.selectedCurriculumItem.learningObjectives
        prerequisites = this.props.selectedCurriculumItem.prerequisites
        resources = this.props.selectedCurriculumItem.resources
        activities = this.props.selectedCurriculumItem.activities
        assignmentQuestions = this.props.selectedCurriculumItem.assignmentQuestions
        mentorQuestions = this.props.selectedCurriculumItem.mentorQuestions
      } else {
      }

      this.setState({
        pictureURL, emailId, username, cuFirstName, cuLastName, activeOrg, orgFocus, orgName,
        _id, curriculumItemId, exchangeType, itemName, description, minutes, submissionDeadline, url, studentURL,
        tags, careerPathTags, gradeLevels, learningObjectives, prerequisites, resources, activities,
        assignmentQuestions, mentorQuestions,
        limitToGroups, courses, customAssessmentId, mentorAssessmentId, isVisible,
        selectedIndex
      })
      // emailId = 'mad8682@lausd.net'
      Axios.get('/api/users/profile/details/' + emailId)
      .then((response) => {

          if (response.data.success) {
            console.log('Advisee query worked', response.data);

            const pictureURL = response.data.user.pictureURL
            let educationalInstitution = response.data.user.school
            if (response.data.user.schoolDistrict) {
              if (educationalInstitution) {
                educationalInstitution = educationalInstitution + ' of ' + response.data.user.schoolDistrict
              } else {
                educationalInstitution = response.data.user.schoolDistrict
              }
            }

            const sections = response.data.user.sections
            let courseOptions = [{ name: '' }]
            if (sections) {
              const sectionArray = JSON.parse(sections).data
              for (let i = 1; i <= sectionArray.length; i++) {
                courseOptions.push(sectionArray[i - 1].data)
              }
            }
            // console.log('courses: ', courseOptions)
            this.setState({ pictureURL, educationalInstitution, courseOptions });

          } else {
            console.log('no advisees found', response.data.message)

          }

      }).catch((error) => {
          console.log('User profile query not work', error);
      });


      const orgCodes = [activeOrg,'general']
      Axios.get('/api/customassessments', { params: { orgCodes } })
      .then((response) => {
        console.log('Custom assessment query attempted', response.data);

        if (response.data.success) {
          console.log('custom assessment query worked')

          if (response.data.assessments.length !== 0) {

            let customAssessments = response.data.assessments
            customAssessments.unshift({ title: 'No Assessment Selected'})
            console.log('gimme customAssessments again', customAssessments)
            let customAssessment = { title: 'No Assessment Selected'}
            let mentorAssessment = { title: 'No Assessment Selected'}
            for (let i = 1; i <= response.data.assessments.length; i++) {
              console.log(i);

              if (selectedItem) {
                if (response.data.assessments[i - 1]._id === selectedItem.customAssessmentId) {
                  customAssessment = { title: response.data.assessments[i - 1].title}
                }
                if (response.data.assessments[i - 1]._id === selectedItem.mentorAssessmentId) {
                  mentorAssessment = { title: response.data.assessments[i - 1].title}
                }
              }
            }

            this.setState({ customAssessment, mentorAssessment,
              customAssessmentOptions: customAssessments
            });

            if (customAssessment.title === 'No Assessment Selected' && selectedItem && (selectedItem.customAssessmentId || selectedItem.mentorAssessmentId)) {
              if (selectedItem.customAssessmentId) {
                Axios.get('/api/customassessments/byid', { params: { _id: selectedItem.customAssessmentId } })
                .then((response) => {
                  console.log('Custom assessment query attempted', response.data);

                  if (response.data.success) {
                    console.log('custom assessment query worked')

                    if (response.data.assessment && response.data.assessment.questions) {
                      const customAssessment = response.data.assessment
                      this.setState({ customAssessment })
                    }

                  } else {
                    console.log('custom assessment query did not work', response.data.message)
                  }

                }).catch((error) => {
                  console.log('Posted jobs query did not work for some reason', error);
                });
              }
              if (selectedItem.mentorAssessmentId) {
                Axios.get('/api/customassessments/byid', { params: { _id: selectedItem.mentorAssessmentId } })
                .then((response) => {
                  console.log('Custom assessment for mentor query attempted', response.data);

                  if (response.data.success) {
                    console.log('custom assessment for mentor query worked')

                    if (response.data.assessment && response.data.assessment.questions) {
                      const mentorAssessment = response.data.assessment
                      this.setState({ mentorAssessment })
                    }

                  } else {
                    console.log('custom assessment query did not work', response.data.message)
                  }

                }).catch((error) => {
                  console.log('Posted jobs query did not work for some reason', error);
                });
              }

            }
          }

        } else {
          console.log('custom assessment query did not work', response.data.message)
        }

      }).catch((error) => {
        console.log('Posted jobs query did not work for some reason', error);
      });

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted for orgFocus on login', response.data);

          if (response.data.success) {
            console.log('org info query worked for orgFocus')

            const includeMentorAssessment = response.data.orgInfo.includeMentorAssessment
            this.setState({ includeMentorAssessment })

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else if (event.target.name === 'sortCriteria') {
        this.sortItems(event.target.name, event.target.value)
      } else if (event.target.name === 'customAssessment') {
        let customAssessment = { title: event.target.value }
        this.setState({ customAssessment, formHasChanged: true })
      } else if (event.target.name === 'mentorAssessment') {
        let mentorAssessment = { title: event.target.value }
        this.setState({ mentorAssessment, formHasChanged: true })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'career') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, searchIsAnimatingCareers: false, careerOptions: null })
        } else {

          // let searchString = this.state.searchString
          let searchIsAnimatingCareers = true

          this.setState({ searchString, searchIsAnimatingCareers })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimatingCareers: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  let careerOptions = []
                  self.setState({ careerOptions, searchIsAnimatingCareers: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);

                let careerOptions = []
                self.setState({ careerOptions, searchIsAnimatingCareers: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    renderTags(type) {
      console.log('renderTags called', type)

      if (type === 'career' || type === 'gradeLevel' || type === 'tag' || type === 'learningObjective') {
        let tags = this.state.tags
        if (type === 'career') {
          tags = this.state.careerPathTags
        } else if (type === 'gradeLevel') {
          tags = this.state.gradeLevels
        } else if (type === 'learningObjective') {
          tags = this.state.learningObjectives
        }

        if (tags && tags.length > 0) {

          return (
            <div key={type + 'tags'}>
              <div className="spacer" />
              {tags.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(type,optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'filter') {
        if (this.state.filterTags) {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {this.state.filterTags.map((item, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(type, optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <button className="background-button float-left left-text" onClick={() => this.setState({ modalIsOpen: true, showFilters: true, filterCriteria: item.key })}>
                    <div className="tag-container-8 cta-background-color">
                      <p className="description-text-2 white-text">{item.key}: {item.value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>
                  {/*
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-8 cta-background-color">
                      <p className="description-text-2 white-text">{item.key}: {item.value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>*/}
                </div>
              )}

            </div>
          )
        }
      } else {
        let tags = this.state.tags
        if (type === 'prerequisite') {
          tags = this.state.prerequisites
        } else if (type === 'resource') {
          tags = this.state.resources
        } else if (type === 'activity') {
          tags = this.state.activities
        } else if (type === 'assignmentQuestion') {
          tags = this.state.assignmentQuestions
        } else if (type === 'mentorQuestion') {
          tags = this.state.mentorQuestions
        } else if (type === 'course') {
          tags = this.state.courses
        }

        if (tags && tags.length > 0) {

          return (
            <div key={type + 'tags'}>
              <div className="spacer" />
              {tags.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(type,optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value.name}{(value.url) && <a href={value.url} target="_blank" rel="noopener noreferrer"> ({value.url})</a>}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      }
    }

    removeTag(type, index) {
      console.log('removeTag called', type, index)

      if (type === 'filter') {
        let filterTags = this.state.filterTags
        filterTags.splice(index, 1)
        this.setState({ filterTags })
        this.filterItems(filterTags)
      } else if (type === 'career') {
        // console.log('working?')
        let careerPathTags = this.state.careerPathTags
        careerPathTags.splice(index, 1)

        let selectedCareerDetails = this.state.selectedCareerDetails
        // const careerToRemove = selectedCareerDetails[index]
        selectedCareerDetails.splice(index, 1)
        this.setState({ careerPathTags, selectedCareerDetails })
      } else if (type === 'gradeLevel') {
        let gradeLevels = this.state.gradeLevels
        gradeLevels.splice(index, 1)
        this.setState({ gradeLevels })
      } else if (type === 'learningObjective') {
        let learningObjectives = this.state.learningObjectives
        learningObjectives.splice(index, 1)
        this.setState({ learningObjectives })
      } else if (type === 'prerequisite') {
        let prerequisites = this.state.prerequisites
        prerequisites.splice(index, 1)
        this.setState({ prerequisites })
      } else if (type === 'resource') {
        let resources = this.state.resources
        resources.splice(index, 1)
        this.setState({ resources })
      } else if (type === 'activity') {
        let activities = this.state.activities
        activities.splice(index, 1)
        this.setState({ activities })
      } else if (type === 'assignmentQuestion') {
        let assignmentQuestions = this.state.assignmentQuestions
        assignmentQuestions.splice(index, 1)
        this.setState({ assignmentQuestions })
      } else if (type === 'mentorQuestion') {
        let mentorQuestions = this.state.mentorQuestions
        mentorQuestions.splice(index, 1)
        this.setState({ mentorQuestions })
      }
    }

    saveItem() {
      console.log('saveItem called')

      if (!this.state.exchangeType || this.state.exchangeType === '') {
        this.setState({ errorMessage: 'Please add a type for this curriculum' })
      } else if (!this.state.itemName || this.state.itemName === '') {
        this.setState({ errorMessage: 'Please add a name for this ' + this.state.exchangeType })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ errorMessage: 'Please add a description for this ' + this.state.exchangeType })
      } else if (!this.state.gradeLevels || this.state.gradeLevels.length === 0) {
        this.setState({ errorMessage: 'Please add grade levels for this ' + this.state.exchangeType })
      } else if (!this.state.minutes || this.state.minutes === '') {
        this.setState({ errorMessage: 'Please add estimated class time for this ' + this.state.exchangeType })
      // } else if ((!this.state.url || this.state.url === '') && (!this.state.studentURL || this.state.studentURL === '')) {
      //   this.setState({ errorMessage: 'Please add an educator-facing or student-facing link for your ' + this.state.exchangeType })
      } else {

        if (this.props.editLesson && this.state._id) {
          // editing existing lesson plan

          let _id = this.state._id
          // if (this.props.editLesson) {
          //   _id = this.state.curriculumItemId
          // }

          const itemName = this.state.itemName
          const description = this.state.description
          const pictureURL = this.state.pictureURL
          const firstName = this.state.cuFirstName
          const lastName = this.state.cuLastName
          const email = this.state.emailId
          const educationalInstitution = this.state.educationalInstitution
          const gradeLevels = this.state.gradeLevels
          const minutes = this.state.minutes
          const careerPathTags = this.state.careerPathTags
          const tags = this.state.tags
          const url = this.state.url
          const studentURL = this.state.studentURL
          const exchangeType = this.state.exchangeType
          // const upvotes = []

          const learningObjectives = this.state.learningObjectives
          const prerequisites = this.state.prerequisites
          const resources = this.state.resources
          const activities = this.state.activities
          let assignmentQuestions = this.state.assignmentQuestions
          let mentorQuestions = this.state.mentorQuestions
          const limitToGroups = this.state.limitToGroups
          const courses = this.state.courses
          const isVisible = this.state.isVisible

          const orgCode = this.state.activeOrg
          const submissionDeadline = this.state.submissionDeadline
          const curriculumItemId = this.state.curriculumItemId
          let customAssessmentId = ''
          let mentorAssessmentId = ''
          let canChangeAnswers = false
          if (this.state.customAssessmentOptions) {
            for (let i = 1; i <= this.state.customAssessmentOptions.length; i++) {
              if (this.state.customAssessmentOptions[i - 1].title === this.state.customAssessment.title) {
                customAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                canChangeAnswers = this.state.customAssessmentOptions[i - 1].canChangeAnswers
                assignmentQuestions = []

                if (this.state.customAssessmentOptions[i - 1].questions) {
                  for (let j = 1; j <= this.state.customAssessmentOptions[i - 1].questions.length; j++) {
                    assignmentQuestions.push(this.state.customAssessmentOptions[i - 1].questions[j - 1])
                  }
                }
              } else if (this.state.customAssessmentOptions[i - 1].title === this.state.mentorAssessment.title) {
                mentorAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                canChangeAnswers = this.state.customAssessmentOptions[i - 1].canChangeAnswers
                mentorQuestions = []

                if (this.state.customAssessmentOptions[i - 1].questions) {
                  for (let j = 1; j <= this.state.customAssessmentOptions[i - 1].questions.length; j++) {
                    mentorQuestions.push(this.state.customAssessmentOptions[i - 1].questions[j - 1])
                  }
                }
              }
            }
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          const lessonObject = {
            _id, name: itemName, itemName, description, pictureURL, firstName, lastName, email, educationalInstitution, gradeLevels, minutes, careerPathTags,
            tags, url, studentURL, exchangeType, orgCode, submissionDeadline, curriculumItemId,
            learningObjectives, prerequisites, resources, activities, assignmentQuestions, mentorQuestions,
            limitToGroups, courses, isVisible,
            customAssessmentId, mentorAssessmentId, canChangeAnswers,
            createdAt, updatedAt
          }
          this.saveLesson(lessonObject, _id)
        } else {

          console.log('save ci or new lesson: ', this.state.editLesson, this.state._id)

          let _id = this.state._id
          if (this.props.editLesson) {
            _id = this.state.curriculumItemId
          }

          const itemName = this.state.itemName
          const description = this.state.description
          const pictureURL = this.state.pictureURL
          const firstName = this.state.cuFirstName
          const lastName = this.state.cuLastName
          const email = this.state.emailId
          const educationalInstitution = this.state.educationalInstitution
          const gradeLevels = this.state.gradeLevels
          const minutes = this.state.minutes
          const careerPathTags = this.state.careerPathTags
          const tags = this.state.tags
          const url = this.state.url
          const studentURL = this.state.studentURL
          const exchangeType = this.state.exchangeType
          const upvotes = []

          const learningObjectives = this.state.learningObjectives
          const prerequisites = this.state.prerequisites
          const resources = this.state.resources
          const activities = this.state.activities
          let assignmentQuestions = this.state.assignmentQuestions
          let mentorQuestions = this.state.mentorQuestions

          let customAssessmentId = ''
          let mentorAssessmentId = ''
          let canChangeAnswers = true
          if (this.state.customAssessmentOptions) {
            for (let i = 1; i <= this.state.customAssessmentOptions.length; i++) {
              if (this.state.customAssessmentOptions[i - 1].title === this.state.customAssessment.title) {
                customAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                canChangeAnswers = this.state.customAssessmentOptions[i - 1].canChangeAnswers
                assignmentQuestions = []
                if (this.state.customAssessmentOptions[i - 1].questions) {
                  for (let j = 1; j <= this.state.customAssessmentOptions[i - 1].questions.length; j++) {
                    assignmentQuestions.push(this.state.customAssessmentOptions[i - 1].questions[j - 1])
                  }
                }
              } else if (this.state.customAssessmentOptions[i - 1].title === this.state.mentorAssessment.title) {
                mentorAssessmentId = this.state.customAssessmentOptions[i - 1]._id
                canChangeAnswers = this.state.customAssessmentOptions[i - 1].canChangeAnswers
                mentorQuestions = []
                if (this.state.customAssessmentOptions[i - 1].questions) {
                  for (let j = 1; j <= this.state.customAssessmentOptions[i - 1].questions.length; j++) {
                    mentorQuestions.push(this.state.customAssessmentOptions[i - 1].questions[j - 1])
                  }
                }
              }
            }
          }

          const orgCode = this.state.activeOrg

          const createdAt = new Date()
          const updatedAt = new Date()

          const curriculumObject = {
            _id, itemName, description, pictureURL, firstName, lastName, email, educationalInstitution, gradeLevels, minutes, careerPathTags,
            tags, url, studentURL, exchangeType, upvotes, orgCode,
            learningObjectives, prerequisites, resources, activities, assignmentQuestions, mentorQuestions,
            avoidDuplicateName: true,
            customAssessmentId, mentorAssessmentId,
            createdAt, updatedAt
          }

          Axios.post('/api/curriculum-items', curriculumObject).then((response) => {
            console.log('attempting to save curriculum item')
            if (response.data.success) {
              console.log('saved curriculum item', response.data)

              if (!this.props.editLesson) {
                console.log('adding or editing curriculum item alone')

                this.props.closeModal()
                this.props.passCurriculumItem(response.data.curriculumItem, true, this.props.selectedIndex, true, _id)
                // this.props.passLesson(lesson, false, this.rops.selectedIndex)
              } else if (this.props.editLesson){

                _id = this.state._id
                const limitToGroups = this.state.limitToGroups
                const courses = this.state.courses
                const submissionDeadline = this.state.submissionDeadline
                const curriculumItemId = response.data.curriculumItem._id
                const isVisible = this.state.isVisible

                const lessonObject = {
                  _id, name: itemName, itemName, description, pictureURL, firstName, lastName, email, educationalInstitution, gradeLevels, minutes, careerPathTags,
                  tags, url, studentURL, exchangeType, orgCode, submissionDeadline, curriculumItemId,
                  learningObjectives, prerequisites, resources, activities, assignmentQuestions, mentorQuestions,
                  limitToGroups, courses,
                  isVisible, customAssessmentId, mentorAssessmentId, canChangeAnswers,
                  createdAt, updatedAt
                }

                this.saveLesson(lessonObject, null)

              }

            } else {
              console.log('did not save successfully', response.data.message)
              _id = this.state._id
              const courses = this.state.courses
              const submissionDeadline = this.state.submissionDeadline
              let curriculumItemId = null
              if (response.data.curriculumItem) {
                curriculumItemId = response.data.curriculumItem._id
              }
              const isVisible = this.state.isVisible

              const lessonObject = {
                _id, name: itemName, itemName, description, pictureURL, firstName, lastName, email, educationalInstitution, gradeLevels, minutes, careerPathTags,
                tags, url, studentURL, exchangeType, orgCode, courses, submissionDeadline, curriculumItemId,
                learningObjectives, prerequisites, resources, activities, assignmentQuestions, mentorQuestions,
                isVisible,
                createdAt, updatedAt
              }

              this.setState({ errorMessage: 'error:' + response.data.message })
              this.saveLesson(lessonObject, null)
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'Curriculum item save did not work' })
          });
        }
      }
    }

    saveLesson(lessonObject, _id) {
      console.log('saveLesson called', lessonObject, _id)

      Axios.post('/api/lessons', lessonObject).then((response) => {
        console.log('attempting to save lesson')
        if (response.data.success) {
          console.log('saved lesson', this.props)

          this.props.closeModal(true)
          this.props.passLesson(response.data.lesson, true, this.props.selectedIndex, true, _id)

        } else {
          console.log('did not save successfully', response.data.message)
          this.setState({ errorMessage: 'error:' + response.data.message })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'Curriculum item save did not work' })
      });
    }

    deleteItem() {
      console.log('deleteItem called')

      const _id = this.state._id

      Axios.delete('/api/lessons/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Curriculum item delete worked');

          // let curriculumItems = this.state.curriculumItems
          // curriculumItems.splice(this.state.selectedIndex, 1)
          // this.setState({ curriculumItems, showConfirmDelete: false })
          // this.closeModal()

          this.props.closeModal()
          // this.props.passCurriculumItem(null, false, this.state.selectedIndex, false, null)
          this.props.passLesson(null, false, this.state.selectedIndex)

        } else {
          console.error('there was an error deleting the log');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error })
      });
    }

    embedYouTubeVideo(url) {
      console.log('embedYouTubeVideo called')

      // https://youtu.be/YDAJjw9ocUo
      // https://www.youtube.com/watch?v=YDAJjw9ocUo
      // https://www.youtube.com/embed/uNfiXBEfwt4

      if (url.includes('youtu.be')) {
        const urlArray = url.split('youtu.be')
        const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
        return embedURL
      } else if (url.includes('youtube.com')) {
        if (url.includes('/embed')) {
          return url
        } else {
          const urlArray = url.split('?v=')
          const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
          return embedURL
        }
      }
    }

    renderText(ogText) {
      console.log('renderText called')

      if (ogText === 'Educator-Facing URL') {
        if (!this.state.exchangeType || this.state.exchangeType === '') {
          return ogText
        } else if (this.state.exchangeType === 'Video') {
          return 'Educator-Facing Notes URL'
        } else {
          return 'Educator-Facing ' + this.state.exchangeType + ' URL'
        }
      } else if (ogText === 'Student-Facing URL') {
        if (!this.state.exchangeType || this.state.exchangeType === '') {
          return ogText
        } else if (this.state.exchangeType === 'Lesson Plan') {
          return 'Student-Facing Lesson URL'
        } else {
          return 'Student-Facing ' + this.state.exchangeType + ' URL'
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'career') {

        const searchObject = passedItem
        const searchString = passedItem.name
        const careerOptions = null

        this.setState({ searchObject, searchString, careerOptions })

      }
    }

    filterItems(filterTags) {
      console.log('filterItems called', filterTags)

      const sortValue = this.state.sortCriteria

      let searchTerm = null
      let exchangeType = null
      let gradeLevel = null
      let careerPathTags = null
      let minuteRange = null

      if (filterTags && filterTags.length > 0) {
        for (let i = 1; i <= filterTags.length; i++) {
          if (filterTags[i - 1].key === 'Search') {
            searchTerm = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Curriculum Type') {
            exchangeType = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Grade Level') {
            gradeLevel = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Career Path') {
            careerPathTags = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Minutes') {
            minuteRange = filterTags[i - 1].value
          }
        }
      }

      Axios.get('/api/curriculum-items', { params: { exchangeType, sortValue, searchTerm, gradeLevel, careerPathTags, minuteRange }})
      .then((response) => {
        console.log('Curriculum items query attempted', response.data);

          if (response.data.success) {
            console.log('curriculum items query worked')

            let curriculumItems = response.data.curriculumItems
            this.setState({ curriculumItems })

          } else {
            console.log('curriculumItems query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('curriculumItems query did not work for some reason', error);
      });
    }

    addItem(type,e,value,index) {
      console.log('addItem called', type)

      if (type === 'career') {
        if (this.state.careerPathTags && this.state.careerPathTags.includes(this.state.searchString)) {
          this.setState({ errorMessage: 'You have already added this career' })
        } else {

          if (!this.state.searchObject) {
            return this.setState({ errorMessage: 'Please select a career from search suggestions' })
          }

          const searchString = ''
          const searchObject = null

          let careerPathTags = this.state.careerPathTags
          careerPathTags.unshift(this.state.searchString)

          let selectedCareerDetails = this.state.selectedCareerDetails
          selectedCareerDetails.unshift(this.state.searchObject)

          this.setState({ searchString, searchObject, careerPathTags, selectedCareerDetails, errorMessage: null, careerOptions: [] })
          // this.adjustCoreCompetencies(this.state.searchObject,type, null, true)
        }
      } else if (type === 'filterCareer') {
        if (!this.state.searchObject) {
          return this.setState({ errorMessage: 'Please select a career from search suggestions' })
        }

        const searchString = ''
        const searchObject = null

        let filterTags = this.state.filterTags
        const keyTerm = 'Career Path'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.searchString }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.searchString })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.searchString }]
        }

        this.setState({ searchString, searchObject, filterTags, errorMessage: null, careerOptions: [] })
        this.filterItems(filterTags)
      } else if (type === 'gradeLevel') {

        if (this.state.gradeLevels && this.state.gradeLevels.includes(this.state.gradeLevel)) {
          this.setState({ errorMessage: 'You have already added this grade level' })
        } else {

          let gradeLevels = this.state.gradeLevels
          let gradeLevel = ''

          if (gradeLevels) {
            gradeLevels.push(this.state.gradeLevel)
          } else {
            gradeLevels = [this.state.gradeLevel]
          }
          this.setState({ gradeLevels, gradeLevel })
        }

      } else if (type === 'filterExchangeType') {
        let filterTags = this.state.filterTags
        let filterExchangeType = ''
        const keyTerm = 'Curriculum Type'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterExchangeType }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterExchangeType })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterExchangeType }]
        }

        this.setState({ filterTags, filterExchangeType })
        this.filterItems(filterTags)
      } else if (type === 'filterGradeLevel') {
        let filterTags = this.state.filterTags
        let filterGradeLevel = ''
        const keyTerm = 'Grade Level'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterGradeLevel }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterGradeLevel })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterGradeLevel }]
        }
        this.setState({ filterTags, filterGradeLevel })
        this.filterItems(filterTags)
      } else if (type === 'tag') {

        if (this.state.tags && this.state.tags.includes(this.state.tag)) {
          this.setState({ errorMessage: 'You have already added this tag' })
        } else {
          let tags = this.state.tags
          let tag = ''
          if (tags) {
            tags.push(this.state.tag)
          } else {
            tags = [this.state.tag]
          }
          this.setState({ tags, tag })
        }

      } else if (type === 'searchTerm') {
        let filterTags = this.state.filterTags
        let searchTerm = ''
        const keyTerm = 'Search'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.searchTerm }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.searchTerm })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.searchTer, }]
        }
        this.setState({ filterTags, searchTerm })
        this.filterItems(filterTags)
      } else if (type === 'filterMinutes') {
        let filterTags = this.state.filterTags
        let filterMinutes = ''
        const keyTerm = 'Minutes'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterMinutes }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterMinutes })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterMinutes }]
        }
        this.setState({ filterTags, filterMinutes })
        this.filterItems(filterTags)
      } else if (type === 'lesson') {
        e.stopPropagation()
        e.preventDefault()

        let lessons = this.state.lessons
        const lesson = {
          curriculumItemId: value._id, description: value.description, educationalInstitution: value.educationalInstitution,
          email: value.email, firstName: value.firstName, lastName: value.lastName,
          gradeLevels: value.gradeLevels,name: value.itemName, itemName: value.itemName,minutes: value.minutes,
          exchangeType: value.exchangeType,pictureURL: value.pictureURL,studentURL: value.studentURL,
          tags: value.tags,upvoteCount: value.upvoteCount,upvotes: value.upvotes,
          url: value.url,careerPathTags: value.careerPathTags,
          createdAt: new Date(),updatedAt: new Date(),
        }
        let addLesson = true
        let deleteIndex = null

        if (!lessons) {
          lessons = [{lesson}]
        } else {
          if (lessons.some(l => l.curriculumItemId === value._id)) {
            deleteIndex = lessons.findIndex(l => l.curriculumItem === value._id)
            lessons.splice(deleteIndex,1)
            addLesson = false
          } else {

            lessons.push(lesson)
          }
        }

        this.setState({ lessons })
        this.props.passLesson(lesson, addLesson, deleteIndex)
      } else if (type === 'learningObjective') {

        if (this.state.learningObjectives && this.state.learningObjectives.includes(this.state.learningObjective)) {
          this.setState({ errorMessage: 'You have already added this learningObjective' })
        } else {

          let learningObjectives = this.state.learningObjectives
          let learningObjective = ''

          if (learningObjectives) {
            learningObjectives.push(this.state.learningObjective)
          } else {
            learningObjectives = [this.state.learningObjective]
          }
          this.setState({ learningObjectives, learningObjective })

        }
      } else if (type === 'prerequisite') {

        if (this.state.prerequisites && this.state.prerequisites.some(pr => pr.name === this.state.prerequisite)) {
          this.setState({ errorMessage: 'You have already added this learningObjective' })
        } else {

          let prerequisites = this.state.prerequisites
          let prerequisite = ''
          let prerequisiteURL = ''
          const prerequisiteObject = { name: this.state.prerequisite, url: this.state.prerequisiteURL }

          if (prerequisites) {
            prerequisites.push(prerequisiteObject)
          } else {
            prerequisites = [prerequisiteObject]
          }
          this.setState({ prerequisites, prerequisite, prerequisiteURL })

        }
      } else if (type === 'resource') {

        if (this.state.resources && this.state.resources.some(pr => pr.name === this.state.resource)) {
          this.setState({ errorMessage: 'You have already added this resource' })
        } else {

          let resources = this.state.resources
          let resource = ''
          let resourceURL = ''
          const resourceObject = { name: this.state.resource, url: this.state.resourceURL }

          if (resources) {
            resources.push(resourceObject)
          } else {
            resources = [resourceObject]
          }
          this.setState({ resources, resource, resourceURL })
        }
      } else if (type === 'activity') {

        if (this.state.activities && this.state.activities.some(pr => pr.name === this.state.activity)) {
          this.setState({ errorMessage: 'You have already added this resource' })
        } else {

          let activities = this.state.activities
          let activity = ''

          const activityObject = { name: this.state.activity, completions: [] }

          if (activities) {
            activities.push(activityObject)
          } else {
            activities = [activityObject]
          }
          this.setState({ activities, activity })
        }
      } else if (type === 'assignmentQuestion') {

        if (this.state.assignmentQuestions && this.state.assignmentQuestions.some(pr => pr.name === this.state.assignmentQuestion)) {
          this.setState({ errorMessage: 'You have already added this question' })
        } else {

          let assignmentQuestions = this.state.assignmentQuestions
          let assignmentQuestion = ''

          const assignmentQuestionObject = { name: this.state.assignmentQuestion }

          if (assignmentQuestions) {
            assignmentQuestions.push(assignmentQuestionObject)
          } else {
            assignmentQuestions = [assignmentQuestionObject]
          }
          this.setState({ assignmentQuestions, assignmentQuestion })
        }
      } else if (type === 'mentorQuestion') {

        if (this.state.mentorQuestions && this.state.mentorQuestions.some(pr => pr.name === this.state.mentorQuestion)) {
          this.setState({ errorMessage: 'You have already added this question' })
        } else {

          let mentorQuestions = this.state.mentorQuestions
          let mentorQuestion = ''

          const mentorQuestionObject = { name: this.state.mentorQuestion }

          if (mentorQuestions) {
            mentorQuestions.push(mentorQuestionObject)
          } else {
            mentorQuestions = [mentorQuestionObject]
          }
          this.setState({ mentorQuestions, mentorQuestion })
        }
      } else if (type === 'course') {

        if (this.state.courses && this.state.courses.some(pr => pr.name === this.state.courseName)) {
          this.setState({ errorMessage: 'You have already added this course' })
        } else {

          let courses = this.state.courses
          let courseName = ''

          let courseObject = {}
          for (let i = 1; i <= this.state.courseOptions.length; i++) {
            if (this.state.courseOptions[i - 1].name === this.state.courseName) {
              courseObject = {
                oauthId: this.state.courseOptions[i - 1].id,
                name: this.state.courseOptions[i - 1].name,
                grade: this.state.courseOptions[i - 1].grade,
                students: this.state.courseOptions[i - 1].students,
                subject: this.state.courseOptions[i - 1].subject,
                teacher: this.state.courseOptions[i - 1].teacher,
                teachers: this.state.courseOptions[i - 1].teachers,
              }
            }
          }

          if (courses) {
            courses.push(courseObject)
          } else {
            courses = [courseObject]
          }
          this.setState({ courses, courseName })
        }
      }
    }

    subNavClicked(subNavSelected) {
      console.log('subNavClicked called', subNavSelected)

      this.setState({ subNavSelected })

    }

    openCurriculumExchange(type) {
      console.log('openCurriculumExchange called')

      this.props.closeModal()
      this.props.openCurriculumExchange(type)

    }

    render() {

      return (
          <div>
            {(this.state.itemName && this.state.itemName !== '') ? (
              <div>
                <div className="calc-column-offset-50">
                  <p className="heading-text-2">{(this.state._id) ? "Edit" : "Add"} {this.state.itemName}</p>
                </div>
                <div className="fixed-column-50 top-margin">
                  <button className="background-button" onClick={() => this.props.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-20" />
                  </button>
                </div>
                <div className="clear" />
              </div>
            ) : (
              <div>
                {(this.props.editLesson) ? (
                  <p className="heading-text-2">{(this.state._id) ? "Edit" : "Add"} Learning Module</p>
                ) : (
                  <p className="heading-text-2">{(this.state._id) ? "Edit" : "Add"} Curriculum</p>
                )}
              </div>
            )}

            <div className="spacer" />

            {(this.props.selectedLesson) && (
              <div>
                <div className="full-width standard-border top-margin">
                  <div className="carousel-3" onScroll={this.handleScroll}>
                    {this.state.subNavCategories.map((value, index) =>
                      <div className="display-inline right-padding-20">
                        {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                          <div className="selected-carousel-item">
                            <label key={value}>{value}</label>
                          </div>
                        ) : (
                          <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                            <label key={value}>{value}</label>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {(this.state.subNavSelected === 'Details') && (
              <div>

                {(this.state._id) ? (
                  <div className="row-10 top-margin">
                    <label className="profile-label">View in Career-Seeker Portal:</label>
                    <a href={window.location.protocol + "//" + window.location.host + "/app/learning/" + this.state._id} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/app/learning/" + this.state._id}</a>
                  </div>
                ) : (
                  <div>
                    {(!window.location.pathname.endsWith('/exchange')) && (
                      <div>
                        <div className="row-10">
                          <hr />
                        </div>
                        
                        <button className="background-button left-text row-10 full-width top-margin" onClick={() => this.openCurriculumExchange('Independent Lesson')}>
                          <div className="fixed-column-30 top-margin-3">
                            <img src={importIconBlue} alt="GC" className="image-15-fit" />
                          </div>
                          <div className="calc-column-offset-30">
                            <div className="display-inline">
                              <p className="description-text-2 cta-color float-left right-margin">Import existing lessons from curriculum exchange.</p>
                              <img src={arrowIcon} alt="GC" className="image-12-fit float-left top-margin-3" />
                            </div>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div className="row-10">
                  <label className="profile-label">Name <label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder={(this.state.exchangeType) ? "Name the " + this.state.exchangeType : "Name the curriculum"} name="itemName" value={this.state.itemName} onChange={this.formChangeHandler}/>
                </div>
                {/*
                <div className="row-10">
                  <label className="profile-label">Curriculum Type <label className="error-color bold-text">*</label></label>
                  <select name="exchangeType" value={this.state.exchangeType} onChange={this.formChangeHandler} className="dropdown">
                    {this.state.exchangeTypeOptions.map(value =>
                      <option key={value} value={value}>{value}</option>
                    )}
                  </select>
                </div>*/}

                <div className="row-10">
                  <label className="profile-label">Description <label className="error-color bold-text">*</label></label>
                  <textarea className="text-field" type="text" placeholder={(this.state.exchangeType) ? "Describe the " + this.state.exchangeType : "Describe the curriculum"} name="description" value={this.state.description} onChange={this.formChangeHandler}/>
                </div>

                <div className="row-10">
                  <label className="profile-label">Objectives</label>

                  <div>
                    <div className="calc-column-offset-70">
                      <input className="text-field" type="text" placeholder={"Add an objective..."} name="learningObjective" value={this.state.learningObjective} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.learningObjective || this.state.learningObjective === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.learningObjective || this.state.learningObjective === '') ? true : false} onClick={() => this.addItem('learningObjective')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    {this.renderTags('learningObjective')}
                    <div className="clear" />
                  </div>
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Tag Grade Level(s) <label className="error-color bold-text">*</label></label>
                    <div className="calc-column-offset-70">
                      <select name="gradeLevel" value={this.state.gradeLevel} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.gradeLevelOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.gradeLevel || this.state.gradeLevel === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.gradeLevel || this.state.gradeLevel === '') ? true : false} onClick={() => this.addItem('gradeLevel')}>Add</button>
                    </div>
                    <div className="clear" />

                    <div>

                      {this.renderTags('gradeLevel')}
                      <div className="clear" />

                    </div>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Minutes to Complete <label className="error-color bold-text">*</label></label>
                    <input className="number-field cta-color" type="number" placeholder='0' name="minutes" value={this.state.minutes} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <label className="profile-label">Prerequisites</label>

                  <div>
                    <div className="calc-column-offset-70">
                      <div className="container-left">
                        <input className="text-field" type="text" placeholder={"Prereq name..."} name="prerequisite" value={this.state.prerequisite} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="container-right">
                        <input className="text-field" type="text" placeholder={"Prereq url"} name="prerequisiteURL" value={this.state.prerequisiteURL} onChange={this.formChangeHandler}/>
                        {(this.state.prerequisiteURL && !this.state.prerequisiteURL.startsWith("http")) && (
                          <div className="top-padding-5">
                            <p className="error-color description-text-2">Make sure link starts with http</p>
                          </div>
                        )}
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.prerequisite || this.state.prerequisite === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.prerequisite || this.state.prerequisite === '') ? true : false} onClick={() => this.addItem('prerequisite')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    {this.renderTags('prerequisite')}
                    <div className="clear" />
                  </div>
                </div>

                <div className="row-10">
                  <label className="profile-label">Resources</label>

                  <div>
                    <div className="calc-column-offset-70">
                      <div className="container-left">
                        <input className="text-field" type="text" placeholder={"Resource name..."} name="resource" value={this.state.resource} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="container-right">
                        <input className="text-field" type="text" placeholder={"Resource url"} name="resourceURL" value={this.state.resourceURL} onChange={this.formChangeHandler}/>
                        {(this.state.resourceURL && !this.state.resourceURL.startsWith("http")) && (
                          <div className="top-padding-5">
                            <p className="error-color description-text-2">Make sure link starts with http</p>
                          </div>
                        )}
                      </div>
                      <div className="clear" />

                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.resource || this.state.resource === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.resource || this.state.resource === '') ? true : false} onClick={() => this.addItem('resource')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    {this.renderTags('resource')}
                    <div className="clear" />
                  </div>
                </div>

                <div className="row-10">
                  <label className="profile-label">Activities</label>

                  <div>
                    <div className="calc-column-offset-70">
                      <input className="text-field" type="text" placeholder={"Activity description..."} name="activity" value={this.state.activity} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.activity || this.state.activity === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.activity || this.state.activity === '') ? true : false} onClick={() => this.addItem('activity')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    {this.renderTags('activity')}
                    <div className="clear" />
                  </div>
                </div>

                {(this.state.showCustomAssessment) ? (
                  <div>
                    <div className="row-10">
                      <label className="profile-label">Include a student quiz / custom assessment</label>
                      <select name="customAssessment" value={this.state.customAssessment.title} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.customAssessmentOptions.map(value =>
                          <option key={value.key} value={value.title}>{value.title}</option>
                        )}
                      </select>
                    </div>

                    {(this.state.includeMentorAssessment) && (
                      <div className="row-10">
                        <label className="profile-label">Include a mentor quiz / custom assessment</label>
                        <select name="mentorAssessment" value={this.state.mentorAssessment.title} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.customAssessmentOptions.map(value =>
                            <option key={value.key} value={value.title}>{value.title}</option>
                          )}
                        </select>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="row-10">
                    <label className="profile-label">Assignment Questions</label>
                    <div>
                      <div className="calc-column-offset-70">
                        <input className="text-field" type="text" placeholder={"Assignment question..."} name="assignmentQuestion" value={this.state.assignmentQuestion} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="fixed-column-70 left-padding">
                        <button className={(!this.state.assignmentQuestion || this.state.assignmentQuestion === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.assignmentQuestion || this.state.assignmentQuestion === '') ? true : false} onClick={() => this.addItem('assignmentQuestion')}>Add</button>
                      </div>
                      <div className="clear" />
                    </div>

                    <div>
                      {this.renderTags('assignmentQuestion')}
                      <div className="clear" />
                    </div>

                  </div>

                )}

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Tag Career Pathways</label>

                    <div className="calc-column-offset-70">
                      <input className="text-field" type="text" placeholder={'Search 1,000+ career paths...'} name="searchCareers" value={this.state.searchString} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.searchString || this.state.searchString === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.searchString || this.state.searchString === '') ? true : false} onClick={() => this.addItem('career')}>Add</button>
                    </div>
                    <div className="clear" />

                    {(this.state.careerErrorMessage && this.state.careerErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.careerErrorMessage}</p>}
                    {(this.state.careerSuccessMessage && this.state.careerSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.careerSuccessMessage}</p>}

                    {(this.state.searchIsAnimatingCareers) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <div className="super-spacer" />

                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Searching...</p>

                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {(this.state.careerOptions && this.state.careerOptions.length > 0) && (
                            <div className="card top-margin">
                              {this.state.careerOptions.map((value, optionIndex) =>
                                <div key={value._id} className="left-text bottom-margin-5 full-width">
                                  <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                                    <div className="full-width">
                                      <div className="fixed-column-40">
                                        <div className="mini-spacer" />
                                        <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                      </div>
                                      <div className="calc-column-offset-40">
                                        <p className="cta-color">{value.name}</p>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div>

                          {this.renderTags('career')}
                          <div className="clear" />

                        </div>

                      </div>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="profile-label">General Tags</label>

                    <div className="calc-column-offset-70">
                      <input className="text-field" type="text" placeholder={'(e.g., 21st Century Skills)'} name="tag" value={this.state.tag} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="fixed-column-70 left-padding">
                      <button className={(!this.state.tag || this.state.tag === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.tag || this.state.tag === '') ? true : false} onClick={() => this.addItem('tag')}>Add</button>
                    </div>
                    <div className="clear" />

                    <div>

                      {this.renderTags('tag')}
                      <div className="clear" />

                    </div>
                  </div>
                  <div className="clear" />

                </div>

                {(this.props.editLesson) && (
                  <div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Limit to Courses/Groups</label>
                        <Switch
                          onChange={(change) => this.setState({ limitToGroups: change })}
                          checked={this.state.limitToGroups}
                          id="normal-switch"
                        />
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Submission Deadline</label>
                        <input type="datetime-local" className="date-picker white-background" placeholder="Submission Deadline" name="submissionDeadline" value={this.state.submissionDeadline} onChange={this.formChangeHandler}></input>
                      </div>

                      <div className="clear" />
                    </div>

                    {(this.state.limitToGroups) && (
                      <div>
                        <div className="row-10">
                          <label className="profile-label">Courses</label>

                          <div>
                            <div className="calc-column-offset-70">
                              <select name="courseName" value={this.state.courseName} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.courseOptions.map(value =>
                                  <option key={value.name} value={value.name}>{value.name}</option>
                                )}
                              </select>
                            </div>
                            <div className="fixed-column-70 left-padding">
                              <button className={(!this.state.courseName || this.state.courseName === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.courseName || this.state.courseName === '') ? true : false} onClick={() => this.addItem('course')}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>

                          <div>
                            {this.renderTags('course')}
                            <div className="clear" />
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                )}

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">{this.renderText('Educator-Facing URL')}</label>
                    <input className="text-field" type="text" placeholder={'https://...'} name="url" value={this.state.url} onChange={this.formChangeHandler}/>
                    {(this.state.url && !this.state.url.startsWith('http')) && (
                      <div className="top-padding-5">
                        <p className="description-text-2 bold-text error-color">Please add a valid URL that starts with http</p>
                      </div>
                    )}

                    {(this.state.url && (this.state.url.includes('youtube.com') || this.state.url.includes('youtu.be'))) && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                        <div className="video-container-4">
                          <iframe
                            title="videoLink"
                            className="video-iframe"
                            src={this.embedYouTubeVideo(this.state.url)}
                            frameBorder="0"
                          />
                        </div>
                        <div className="clear"/>
                      </div>
                    )}

                  </div>
                  <div className="container-right">
                    <label className="profile-label">{this.renderText('Student-Facing URL')}</label>
                    <input className="text-field" type="text" placeholder={(this.state.exchangeType === 'Video') ? 'YouTube video url...' : 'https://...'} name="studentURL" value={this.state.studentURL} onChange={this.formChangeHandler}/>
                    {(this.state.studentURL && !this.state.studentURL.startsWith('http')) && (
                      <div className="top-padding-5">
                        <p className="description-text-2 bold-text error-color">Please add a valid URL that starts with http</p>
                      </div>
                    )}

                    {(this.state.studentURL && (this.state.studentURL.includes('youtube.com') || this.state.studentURL.includes('youtu.be'))) && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                        <div className="video-container-4">
                          <iframe
                            title="videoLink"
                            className="video-iframe"
                            src={this.embedYouTubeVideo(this.state.studentURL)}
                            frameBorder="0"
                          />
                        </div>
                        <div className="clear"/>
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>
                {/*
                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" /><div className="spacer" />

                <div className="row-10">
                  <p className="heading-text-4">Additional Details (Optional)</p>
                </div>

                <div className="row-10">
                  <label className="profile-label">Objectives</label>
                  <textarea className="text-field" type="text" placeholder={"Objectives"} name="objectives" value={this.state.objectives} onChange={this.formChangeHandler}/>
                </div>

                <div className="row-10">
                  <label className="profile-label">Competencies</label>
                  <textarea className="text-field" type="text" placeholder={"Career-related knowledge and skills to be acquired..."} name="competencies" value={this.state.competencies} onChange={this.formChangeHandler}/>
                </div>

                <div className="row-10">
                  <label className="profile-label">Activities</label>
                  <textarea className="text-field" type="text" placeholder={"Activities..."} name="competencies" value={this.state.activities} onChange={this.formChangeHandler}/>
                </div>

                <div className="row-10">
                  <label className="profile-label">Standards</label>
                  <textarea className="text-field" type="text" placeholder={"Standards..."} name="standards" value={this.state.standards} onChange={this.formChangeHandler}/>
                </div>

                <div className="row-10">
                  <label className="profile-label">Assesssments</label>
                  <textarea className="text-field" type="text" placeholder={"Questions to ensure comprehension of the material..."} name="assessments" value={this.state.assessments} onChange={this.formChangeHandler}/>
                </div>

                <div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" /><div className="spacer" />

                <div className="row-10">
                  <p className="heading-text-4">Student-Facing Components (Optional)</p>
                </div>*/}
                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color bold-text row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color bold-text row-5">{this.state.successMessage}</p>}

                {(this.state.showConfirmDelete) && (
                  <div className="row-10">
                    <div>
                      <label className="profile-label">Are you sure?</label>
                    </div>

                    <div className="float-left">
                      <button className="btn btn-squarish error-background-color white-text clear-border" onClick={() => this.deleteItem()}>Delete Permanently</button>
                    </div>
                    <div className="float-left left-padding">
                      <button className="btn btn-squarish white-background cta-color cta-border" onClick={() => this.setState({ showConfirmDelete: false })}>Cancel</button>
                    </div>

                    <div className="clear" />
                  </div>
                )}

                <div className="row-10">
                  <div className="float-left top-margin">
                    <button className="btn btn-primary" onClick={() => this.saveItem()}>Save {this.state.exchangeType}</button>
                  </div>
                  <div className="float-left left-padding top-margin">
                    <button className="btn btn-secondary" onClick={() => this.props.closeModal()}>Close Modal</button>
                  </div>

                  {(this.state._id) && (
                    <div className="float-left left-padding top-margin">
                      <button className="btn error-background-color clear-border white-text" onClick={() => this.setState({ showConfirmDelete: true })}>Delete {this.state.exchangeType}</button>
                    </div>
                  )}

                  <div className="clear" />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Submissions') && (
              <div>
                {(this.state.submissions && this.state.submissions.length > 0) ? (
                  <div className="top-margin-30">
                    <p className="heading-text-4">{this.state.submissions.length} Submissions</p>

                    {this.state.submissions.map((value, optionIndex) =>
                      <div key={value.emailId} className="standard-border top-margin-30 padding-20">
                        <div>
                          <div className="fixed-column-60">
                            <img src={(value.pictureURL && value.pictureURL !== 'undefined') ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-2" />
                          </div>
                          <div className="calc-column-offset-120">
                            <p className="heading-text-6">{value.firstName} {value.lastName}</p>
                            {(value.orgName) && (
                              <p className="description-text-2 top-padding-5">{value.orgName}</p>
                            )}
                          </div>
                          <div className="fixed-column-60">
                            <p className="heading-text-4">#{optionIndex + 1}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        {value.answers.map((value2, optionIndex2) =>
                          <div key={value.emailId + value2.question} className="top-margin-20">
                            <p className="description-text-2 description-text-color">{optionIndex + 1}. {value2.question}</p>
                            <p className="top-margin-5">{value2.answer}</p>
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                ) : (
                  <div className="top-margin-20">
                    <p className="error-color">No one has submitted answers to the quizzes/assignments yet.</p>
                  </div>
                )}
              </div>
            )}
            {(this.state.subNavSelected === 'Discussions') && (
              <div>
                <SubComments selectedLesson={this.props.selectedLesson} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} />
              </div>
            )}

          </div>

      )
    }
}

export default EditLesson;
