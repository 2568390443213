import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Modal from 'react-modal';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import SubLogInWidget from '../components/Common/LogInWidget';
import withRouter from '../components/Functions/WithRouter';

const greenhouseLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/greenhouse-logo.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class IntegratePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          webhookSecretKey: '4Z036X089004FE0999TX2',
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.passData = this.passData.bind(this)
        this.initiateOauth = this.initiateOauth.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      window.scrollTo(0, 0)

      this.retrieveData()

    }

    retrieveData() {
      console.log('retrieveData called')

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('emp');
      let employerName = localStorage.getItem('employerName');
      let employerLogoURL = localStorage.getItem('employerLogoURL');
      let jobTitle = localStorage.getItem('jobTitle');

      let serviceId
      if (this.props.params) {
        serviceId = this.props.params.serviceId
      }

      let registered = false
      if (emailId && activeOrg && accountCode && employerName) {
        registered = true
      }

      const integrations = [{ name: 'Greenhouse', shortcode: 'greenhouse', logo: greenhouseLogo }]
      if (integrations.some(i => i.shortcode === serviceId.toLowerCase())) {

        const serviceName = integrations[integrations.findIndex(i => i.shortcode === serviceId.toLowerCase())].name

        this.setState({ serviceId, serviceName, cuFirstName, cuLastName, emailId, activeOrg, roleName,
          accountCode, employerName, employerLogoURL, jobTitle, registered
        })

        // console.log('show ?', registered)

        if (registered) {
          this.setState({ isLoading: true })

          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted within sub settings', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              let authorized = false
              let webhooked = false
              let endpointURL = null
              const greenhouseAccessToken = response.data.accountInfo.greenhouseAccessToken
              const greenhouseWebhooksEnabled = response.data.accountInfo.greenhouseWebhooksEnabled
              if (serviceId === 'greenhouse' && greenhouseAccessToken) {
                authorized = true
                endpointURL = 'https://www.guidedcompass.com/api/greenhouse/webhooks/' + this.state.accountCode

                if (greenhouseWebhooksEnabled) {
                  webhooked = true
                }
              }

              this.setState({ authorized, endpointURL, webhooked, isLoading: false });

            } else {
              this.setState({ isLoading: false })
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
            this.setState({ isLoading: false })
          });
        }
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showRegistration: false });
    }

    passData(data) {
      console.log('passData called')

      if (data.success) {

        this.retrieveData()
        this.closeModal()

        // let emailId = localStorage.getItem('email');
        // let pictureURL = localStorage.getItem('pictureURL');
        // let cuFirstName = localStorage.getItem('firstName');
        // let cuLastName = localStorage.getItem('lastName');
        // let username = localStorage.getItem('username');
        // let activeOrg = localStorage.getItem('activeOrg');
        // let placementPartners = localStorage.getItem('placementPartners');
        // let orgName = localStorage.getItem('orgName');
        // let roleName = localStorage.getItem('roleName');
        // let accountCode = localStorage.getItem('emp');
        // let employerName = localStorage.getItem('employerName');
        // let jobTitle = localStorage.getItem('jobTitle');
        // let remoteAuth = localStorage.getItem('remoteAuth');
        // let employerLogoURL = localStorage.getItem('employerLogoURL');
        //
        // let registered = false
        // if (emailId && activeOrg && accountCode && employerName) {
        //   registered = true
        // }
        // // console.log('show employerLogoURL: ', accountCode, employerLogoURL)
        //
        // this.setState({ successMessage: data.message, errorMessage: null,
        //   emailId, pictureURL, cuFirstName, cuLastName, username, activeOrg,
        //   placementPartners, orgName, roleName, accountCode, employerName, jobTitle, remoteAuth, employerLogoURL,
        //   registered
        // })
        // this.closeModal()
      } else {
        this.setState({ successMessage: data.message, errorMessage: null })
      }
    }

    initiateOauth(index) {
      console.log('initiateOauth called', index)


      const url = '/api/oauth/' + this.state.serviceId + '/signin'
      const accountCode = this.state.accountCode
      const emailId = this.state.emailId

      // console.log('show accountCode before oAuth: ', accountCode)
      Axios.get(url, { params: { accountCode, emailId }})
      .then((response) => {

        if (response.data.success) {
          console.log('Oauth query worked', response.data);
          if (response.data.finalAuthURL) {
            console.log('u1')

            window.location.href=response.data.finalAuthURL
            // console.log('u2')
          }
        } else {
          console.log('there was an error found', response.data.message)

        }

      }).catch((error) => {
          console.log('Oauth query did not work', error);
      });
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"integrationsPage"} darkBackground={true} />

                {(this.state.serviceId) && (
                  <div>
                    <div className="white-background width-70-percent vertical-margin-2-percent padding-40 medium-shadow max-width-1400 center-horizontally">
                      <div className="row">
                        <div>
                          <div className="fixed-column-80 height-40">
                            {(this.state.registered) && (
                              <div>
                                {(this.state.employerLogoURL) ? (
                                  <div>
                                    <img src={this.state.employerLogoURL} alt="GC" className="image-50-fit" />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="cta-color description-text-4 bold-text center-text full-width">{this.state.employerName}</p>
                                  </div>
                                )}
                              </div>
                            )}

                          </div>
                          <div className="calc-column-offset-160">
                            <h2>Integrate with {this.state.serviceName}</h2>
                          </div>
                          <div className="fixed-column-80">
                            <img src={greenhouseLogo} alt="GC" className="image-50-fit" />
                          </div>
                          <div className="clear" />
                        </div>

                        <p className="center-text">As easy as 123.</p>
                      </div>

                      {(this.state.isLoading) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Loading...</p>

                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row-30">
                            <div>
                              <div className="calc-column-offset-120 right-padding">
                                {(this.state.registered) && (
                                  <div className="fixed-column-40">
                                    <img src={checkmarkIcon} alt="GC" className="image-auto-25" />
                                  </div>
                                )}
                                <div className={(this.state.registered) ? "calc-column-offset-40" : "full-width"}>
                                  {(!this.state.registered) && <div className="spacer" />}

                                  <p className="heading-text-4">1. {(this.state.registered) ? "Registered" : "Register"}</p>
                                  {(this.state.registered) ? (
                                    <div className="top-padding">
                                      <p className="description-text-2 cta-color bold-text">{this.state.employerName} is registered. You are signed in as {this.state.cuFirstName} {this.state.cuLastName} ({this.state.emailId}){(this.state.jobTitle) ? ", "+ this.state.jobTitle + " @ " :  " @ "}{this.state.employerName}.</p>
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.emailId && !this.state.accountCode) && (
                                        <div className="top-padding">
                                          <p className="description-text-2 error-color">You are signed in as {this.state.cuFirstName} {this.state.cuLastName} ({this.state.emailId}), but no employer is associated with this account. You must create a new account to integrate with {this.state.serviceName}.</p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="fixed-column-120">
                                <button className={(this.state.registered) ? "btn btn-primary dark-background clear-border" : "btn btn-primary"} onClick={() => this.setState({ modalIsOpen: true, showRegistration: true, signInPage: false })}>{(this.state.registered) ? "Edit" : "Start"}</button>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                          <hr />

                          {(this.state.registered) ? (
                            <div className="row-30">
                              <div>
                                <div className="calc-column-offset-120 right-padding">
                                  {(this.state.authorized) && (
                                    <div className="fixed-column-40">
                                      <img src={checkmarkIcon} alt="GC" className="image-auto-25" />
                                    </div>
                                  )}
                                  <div className={(this.state.authorized) ? "calc-column-offset-40" : "full-width"}>
                                    {(!this.state.authorized) && <div className="spacer" />}

                                    <p className="heading-text-4">2. {(this.state.authorized) ? "Authorized" : "Authorize"}</p>
                                    {(this.state.authorized) && (
                                      <div className="top-padding">
                                        <p className="description-text-2 cta-color bold-text">{this.state.employerName} is authorized.</p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="fixed-column-120">
                                  <button className={(this.state.authorized) ? "btn btn-primary dark-background clear-border" : "btn btn-primary"} onClick={() => this.initiateOauth()}>{(this.state.authorized) ? "Edit" : "Start"}</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          ) : (
                            <div className="row-30 wash-out">
                              <div>
                                <div className="calc-column-offset-120 right-padding">
                                  {(!this.state.authorized) && <div className="spacer" />}

                                  <p className="heading-text-4">2. Authorize</p>
                                </div>
                                <div className="fixed-column-120">
                                  <button className="btn btn-primary" disabled={true} onClick={() => console.log('')}>Start</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          <hr />

                          {(this.state.authorized) ? (
                            <div className="row-30">
                              <div>
                                <div className="calc-column-offset-120">
                                  {(this.state.webhooked) && (
                                    <div className="fixed-column-40">
                                      <img src={checkmarkIcon} alt="GC" className="image-auto-25" />
                                    </div>
                                  )}
                                  <div className={(this.state.webhooked) ? "calc-column-offset-40" : "full-width"}>
                                    {(!this.state.webhooked) && <div className="spacer" />}

                                    <p className="heading-text-4">3. {(this.state.webhooked) ? "Webhooked" : "Webhook"}</p>
                                    {(this.state.webhooked) ? (
                                      <div className="top-padding">
                                        <p className="description-text-2 cta-color bold-text">{this.state.employerName} is webhooked.</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="description-text-2 top-padding">Follow step 3 of the <Link to="/help/greenhouse-ats-integration" target="_blank" className="bold-text">{this.state.serviceName} Help Guide</Link>. Plug in the following values for each webhook you create:</p>

                                        <ul className="top-padding description-text-2 left-padding-20">
                                          <li><label className="bold-text">Endpoint URL:</label> {this.state.endpointURL}</li>
                                          <li><label className="bold-text">Secret Key:</label> {this.state.webhookSecretKey}</li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="fixed-column-120">
                                  <button className={(this.state.webhooked) ? "btn btn-primary dark-background clear-border" : "btn btn-primary"} onClick={() => this.retrieveData()}>Check</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          ) : (
                            <div className="row-30 wash-out">
                              <div>
                                <div className="calc-column-offset-120">
                                  {(!this.state.webhooked) && <div className="spacer" />}
                                  <p className="heading-text-4">3. Webhook</p>
                                </div>
                                <div className="fixed-column-120">
                                  <button className="btn btn-primary" disabled={true} onClick={() => console.log('')}>Start</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          {(this.state.webhooked) && (
                            <div>
                              <hr />
                              <div className="row-30">
                                <p className="heading-text-6 cta-color">You're all set! Thank you for helping our education and workforce partners train and refer candidates to your organization!</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                )}

                <Footer history={this.props.navigate} />

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
               >
                {(this.state.showRegistration) && (
                  <div key="showRegistration" className="full-width padding-40 top-margin-60">
                    <SubLogInWidget passData={this.passData} signInPage={this.state.signInPage} history={this.props.navigate} passedRoleName="Employer" modalIsOpen={true} />
                  </div>
                )}

               </Modal>
            </div>
        )
    }
}

export default withRouter(IntegratePage);
