import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubNotifications from './Subcomponents/Notifications';
import withRouter from './Functions/WithRouter';

class AdvNotifications extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ username, activeOrg, orgFocus, roleName, orgLogo })
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} history={this.props.navigate} />
          <div className="standard-container-2">
            <SubNotifications />
          </div>
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(AdvNotifications)
