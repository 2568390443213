import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
// import Modal from 'react-modal';

import { Tooltip as ReactTooltip } from 'react-tooltip';
// import Switch from 'react-switch';
// import MyEditor from '../Subcomponents/MyEditor';

import {convertDateToString} from '../Functions/ConvertDateToString';

const easyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/easy-icon.png";
const mediumIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/medium-icon.png";
const hardIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hard-icon.png";
const reachIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reach-icon.png";
const pointsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/points-icon.png";
const industryIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon.png";
const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const tagIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon.png";
// const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const skillsIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon-blue.png";
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const deadlineIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/deadline-icon.png";
const prizeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/prize-icon.png";
const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const locationIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/location-icon.png';
const timeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-dark.png';
// const infoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon.png';
const infoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
// const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
// const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
// const sendIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon.png';
// const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const timeRangeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-range-icon.png';
const calendarIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const subsidyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/subsidy-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
// const checkmarkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png';
// const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
// const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
// const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
// const referIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png';
// const modalIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/modal-icon-dark.png';
// const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class RenderOpportunityDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          disableSubmit: false,

          collaborators: [],
          viewIndex: 0,
          subviewIndex: 0,
          selectedIndex: 0,
          summarySplit: [],

          questionIndex: 0,
          survey: [],
          responses: [],
          employers: [],

          projectCategoryOptions: [],
          dateOptions: [],
          collaboratorOptions: [],
          functionOptions: [],
          industryOptions: [],
          projectOptions: [{}],
          gradeOptions: [],
          relationshipOptions: [],
          roleNameOptions: ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Counselor','Employer Representative / Mentor','Other'],
          gradYearOptions: [],
          degreeOptions: [],
          studentActivityOptions: [],
          teacherActivityOptions: [],
          mentorActivityOptions: [],
          educationStatusOptions: [],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderDetails = this.renderDetails.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderOpportunityDetails', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts  || this.props.passedGroupPost !== prevProps.passedGroupPost) {
        this.retrieveData()
      } else if (this.props.selectedOpportunity !== prevProps.selectedOpportunity) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in RenderOpportunityDetails')

      let email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.activeOrg) {
        activeOrg = this.props.activeOrg
      }
      const orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      // let pictureURL = localStorage.getItem('pictureURL');

      const selectedOpportunity = this.props.selectedOpportunity
      const favorites = this.props.favorites
      const oppStats = this.props.oppStats

      this.setState({ emailId: email, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, username,
        selectedOpportunity, favorites, oppStats
      })

      if (selectedOpportunity) {
        if (selectedOpportunity.orgCode) {

          Axios.get('/api/org', { params: { orgCode: selectedOpportunity.orgCode } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked for post')

                const postingOrgCode = response.data.orgInfo.orgCode
                const postingOrgName = response.data.orgInfo.orgName
                const postingOrgContactEmail = response.data.orgInfo.contactEmail
                const orgContactEmail = response.data.orgInfo.contactEmail

                const orgName = response.data.orgInfo.orgName
                const webLogoURI = response.data.orgInfo.webLogoURI
                const orgLogo = response.data.orgInfo.webLogoURIColor
                const headerImageURL = response.data.orgInfo.headerImageURL
                const partnerLandingPage = response.data.orgInfo.partnerLandingPage

                console.log('show hiu: ', headerImageURL)
                this.setState({ postingOrgCode, postingOrgName, postingOrgContactEmail,
                  orgContactEmail, orgName, webLogoURI, orgLogo, headerImageURL, partnerLandingPage
                });

                let postType = null
                let postTypes = []
                if (this.props.passedTypes) {
                  postTypes = this.props.passedTypes
                }
                const placementPartners = response.data.orgInfo.placementPartners
                let accountCode = this.props.accountCode
                const recent = true
                const active = true
                const pullPartnerPosts = true
                let targetRole = this.props.targetRole
                let subPostType = this.props.passedSubPostType
                const projectPromptType = null
                const pathway = null
                const benchmarkId = selectedOpportunity.benchmarkId
                const excludeIds = [selectedOpportunity._id]
                const resLimit = 4

                //only schools see this screen
                Axios.get('/api/postings/user', { params: { orgCode: selectedOpportunity.orgCode, placementPartners,
                  postType, postTypes, projectPromptType, pathway, accountCode, recent, active,
                  pullPartnerPosts, csWorkflow: true,
                  roleName, targetRole, subPostType, benchmarkId, excludeIds, resLimit
                }})
                .then((response) => {
                  console.log('Posted postings query attempted within subcomponent', response.data);

                  if (response.data.success) {
                    console.log('posted postings query worked')

                    if (response.data.postings.length !== 0) {

                      // let useFunction = true

                      const relatedOpportunities = response.data.postings
                      this.setState({ relatedOpportunities })
                    }

                  } else {
                    console.log('posted postings query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Posted postings query did not work for some reason', error);
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        if (selectedOpportunity.postType === 'Individual' || selectedOpportunity.postType === 'Track' || selectedOpportunity.postType === 'Internship' || selectedOpportunity.postType === 'Scholarship' || selectedOpportunity.postType === 'Work' || selectedOpportunity.postType === 'Program') {

          let hasApplied = false
          if (selectedOpportunity.applicants) {
            if (selectedOpportunity.applicants.includes(email)) {
              hasApplied = true
            }
          }

          let employerURL = selectedOpportunity.employerURL
          if (selectedOpportunity.accountCode) {
            if (window.location.pathname.includes('/app')) {
              employerURL = '/app/accounts/' + selectedOpportunity.accountCode
            } else {
              employerURL = '/employers/pages/' + selectedOpportunity.accountCode
            }
          }

          let oppStats = [
            // { icon: industryIconDark, title: selectedOpportunity.employerName, url: employerURL, dataTip: 'Employer Name' },
            { icon: locationIcon, title: selectedOpportunity.location, dataTip: 'Employer Location' },
            { icon: calendarIconDark, title: 'Posted '+ convertDateToString(selectedOpportunity.createdAt,'date'), dataTip: 'Date Opportunity Was Posted' },
            { icon: timeIconDark, title: selectedOpportunity.hoursPerWeek + " Per Week", dataTip: 'Hours per Week' }
          ]

          if (selectedOpportunity.employerName && selectedOpportunity.employerName !== 'No Employer Selected') {
            oppStats.unshift({ icon: industryIconDark, title: selectedOpportunity.employerName, url: employerURL, dataTip: 'Employer Name' })
          }

          if (selectedOpportunity.startDate) {

            let startDateTitle = convertDateToString(selectedOpportunity.startDate,"date")

            if (selectedOpportunity.isPerpetual) {
              startDateTitle = startDateTitle + " - Continual"
            } else {
              startDateTitle = startDateTitle + " - " + convertDateToString(selectedOpportunity.endDate,"date")
            }

            oppStats.push({ icon: timeRangeIcon, title: startDateTitle, dataTip: 'Work Time Range' })
          }

          if (selectedOpportunity.field && !selectedOpportunity.field.includes('undefined') && selectedOpportunity.field.split("|") && selectedOpportunity.field.split("|")[0]) {
            oppStats.push({ icon: skillsIcon, title: selectedOpportunity.field.split("|")[0].trim(), dataTip: 'Work Function' })
          }

          if (selectedOpportunity.industry && selectedOpportunity.industry.split("|") && selectedOpportunity.field.split("|")[1]) {
            oppStats.push({ icon: industryIconDark, title: selectedOpportunity.field.split("|")[1].trim(), dataTip: 'Industry' })
          }

          if (selectedOpportunity.isSubsidized || selectedOpportunity.prioritizeSubsidized) {
            oppStats.push({ icon: subsidyIconDark, title: "This role is subsidized", subtitle: "***Only people 16 - 24 within Los Angeles county limits are eligible to receive subsidized internships***", dataTip: "Employer won't pay you directly" })
          }

          if (selectedOpportunity.workType) {
            oppStats.push({ icon: skillsIcon, title: selectedOpportunity.workType, dataTip: "Work Type" })
          }

          if (selectedOpportunity.payRange && selectedOpportunity.payRange !== '') {
            oppStats.push({ icon: moneyIconDark, title: selectedOpportunity.payRange, dataTip: "Pay Range" })
          }

          if (selectedOpportunity.supplementalPayArray && selectedOpportunity.supplementalPayArray.length > 0) {
            oppStats.push({ icon: tagIcon, title: selectedOpportunity.supplementalPayArray.toString(), dataTip: "Supplemental Pay" })
          }

          if (selectedOpportunity.benefits && selectedOpportunity.benefits.length > 0) {
            oppStats.push({ icon: tagIcon, title: selectedOpportunity.benefits.toString(), dataTip: "Benefits" })
          }

          this.setState({ selectedOpportunity, hasApplied, oppStats})

        } else if (selectedOpportunity.postType === 'Event') {

          let roleNameLinked = null
          if (this.props.roleName) {
            roleName = this.props.roleName
            if (roleName.toLowerCase() === 'student' || roleName.toLowerCase() === 'career-seeker') {
              roleName = 'Student / Career Seeker'
              roleNameLinked = roleName
            } else if (roleName.toLowerCase() === 'volunteer' || roleName.toLowerCase() === 'mentor' || roleName.toLowerCase() === 'employer') {
              roleName = 'Employer Representative / Mentor'
              roleNameLinked = roleName
            }
          } else {
            if (roleName === 'Student') {
              roleName = "Student / Career Seeker"
            }
          }

          let gradYearOptions = ['']
          const startingPoint = new Date().getFullYear() - 6
          for (let i = 1; i <= 10; i++) {
            gradYearOptions.push(startingPoint + i)
          }

          let startDateString = null
          let endDateString = null
          let eventPassed = false

          startDateString = convertDateToString(new Date(selectedOpportunity.startDate),'datetime-2')
          endDateString = convertDateToString(new Date(selectedOpportunity.endDate),'datetime-2')

          let dateToTest = null
          if (selectedOpportunity.endDate && new Date(selectedOpportunity.endDate)) {
            dateToTest = selectedOpportunity.endDate
          } else if (selectedOpportunity.startDate && new Date(selectedOpportunity.startDate)) {
            dateToTest = selectedOpportunity.startDate
          }

          if (dateToTest) {
            if (new Date().getTime() > new Date(dateToTest).getTime()) {
              eventPassed = true
            }
          }

          this.setState({ startDateString, endDateString, eventPassed, roleName, roleNameLinked, gradYearOptions })

        } else if (selectedOpportunity.postType === 'Project' || selectedOpportunity.postType === 'Assignment' || selectedOpportunity.postType === 'Problem' || selectedOpportunity.postType === 'Challenge') {

          let summarySplit = []
          if (selectedOpportunity.summary) {
            summarySplit = selectedOpportunity.summary.split('//n')
            console.log('show summarySplit: ', selectedOpportunity.summary.includes('//n'), selectedOpportunity.summary.substring(0,15), summarySplit)
          }

          //update submissions
          Axios.get('/api/postings/byid', { params: { _id: selectedOpportunity._id } })
          .then((response) => {
            console.log('Postings by id query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved postings')

                if (response.data.posting) {

                  const updatedOpportunity = response.data.posting
                  let registrationPassed = false
                  let deadlinePassed = false
                  let disableVoting = false

                  if (updatedOpportunity.submissionDeadline) {
                    if (new Date().getTime() > new Date(updatedOpportunity.submissionDeadline).getTime()) {
                      deadlinePassed = true
                    }
                  }

                  if (updatedOpportunity.startDate) {
                    if (new Date().getTime() > new Date(updatedOpportunity.startDate).getTime()) {
                      registrationPassed = true
                    }
                  }

                  let submitted = false

                  if (updatedOpportunity.submissions && updatedOpportunity.submissions.length > 0) {

                    let submissions = updatedOpportunity.submissions
                    for (let i = 1; i <= submissions.length; i++) {

                      //for students who already submitted
                      if (submissions[i - 1].userEmail === email) {
                        submitted = true
                      }
                    }
                    disableVoting = updatedOpportunity.disableVoting

                    submissions.sort(function(a,b) {
                      return b.upvotes.length - a.upvotes.length;
                    })

                    // console.log('show submissions: ', submissions)
                    updatedOpportunity['submissions'] = submissions
                  }

                  this.setState({
                    selectedOpportunity: updatedOpportunity, submitted,
                    summarySplit, deadlinePassed, registrationPassed, disableVoting
                  })
                }


              } else {
                console.log('no posting data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Posting query did not work', error);
          });
        }
      }
    }

    formatDate(passedDate) {
      console.log('formatDate called', passedDate)

      if (!passedDate) {
        return null
      } else {
        const startDate = new Date(passedDate)
        const startMonth = startDate.getMonth()
        const startNumDay = startDate.getDate()
        const startYear = startDate.getFullYear()
        // let startHour = startDate.getHours()
        // let startMin = startDate.getMinutes()
        let startHour = Number(passedDate.substring(11,13))
        let startMin = Number(passedDate.substring(14,16))

        console.log('show startHour and startMin ', startHour, typeof startHour, startMin, typeof startMin )

        let calcMonth = (startMonth + 1).toString()
        if (startMonth + 1 < 10) {
          calcMonth = '0' + calcMonth
        }

        let suffix = 'AM'
        let calcHour = startHour
        if (startHour > 12) {
          calcHour = startHour - 12
          suffix = 'PM'
        } else if (startHour === 12) {
          suffix = 'PM'
        }

        let calcMin = ''
        if (startMin > 10) {
          calcMin = startMin.toString()
        } else {
          calcMin = '0' + startMin.toString()
        }

        const finalStartMonth = calcMonth
        const finalStartDay = startNumDay.toString()
        const finalStartYear = startYear.toString()
        const finalHour = calcHour.toString()
        const finalMin = calcMin
        const startDateString = finalStartMonth + "/" + finalStartDay + "/" + finalStartYear + " " + finalHour + ":" + finalMin + suffix

        passedDate = startDateString

        return passedDate
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name.includes('responses|')) {
        const index = Number(event.target.name.split("|")[1])
        let responses = this.state.responses
        responses[index] = event.target.value
        console.log('show responses again: ', responses)
        this.setState({ responses })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    renderDetails(type) {
      console.log('renderDetails ', type)

      let rows = []

      let items = []

      if (type === 'section') {
        if (this.state.selectedOpportunity.sections) {
          items = this.state.selectedOpportunity.sections
        }
      } else if (type === 'exhibit') {
        if (this.state.selectedOpportunity.exhibits) {
          items = this.state.selectedOpportunity.exhibits
        }
      }

      const alphabet = 'abcdefghijklmnopqrstuvwxyz'

      for (let i = 1; i <= items.length; i++) {

        const index = i - 1

        rows.push(
          <div key={type + i}>
            {type === 'section' && (
              <div>
                <p className="heading-text-4">{items[index].title}</p>
                <p className="description-text-1">{items[index].body}</p>
              </div>
            )}
            {type === 'exhibit' && (
              <div className="bottom-padding">
                <p className="heading-text-4">Exhibit {alphabet[index].toUpperCase()}</p>
                {(items[index].link && items[index].link !== '') && (
                  <a href={items[index].link} target="_blank">{items[index].link}</a>
                )}
              </div>
            )}
          </div>
        )
      }

      return rows

    }

    render() {

      let difficultyIcon = easyIcon

      if (this.state.selectedOpportunity) {
        if (this.state.selectedOpportunity.difficultyLevel === 'Medium') {
          difficultyIcon = mediumIcon
        } else if (this.state.selectedOpportunity.difficultyLevel === 'Hard' || this.state.selectedOpportunity.difficultyLevel === 'Very Hard') {
          difficultyIcon = hardIcon
        }
      }

      return (
        <div>
          {(this.state.selectedOpportunity) && (
            <div>
              {(this.state.selectedOpportunity.postType === 'Internship' || this.state.selectedOpportunity.postType === 'Individual' || this.state.selectedOpportunity.postType === 'Track' || this.state.selectedOpportunity.postType === 'Work' || this.state.selectedOpportunity.postType === 'Program') && (
                <div>
                  <div>
                    <div>
                      <div className="full-width">
                        {(this.state.oppStats) && (
                          <div>
                            {this.state.oppStats.map((item, index) =>
                              <span key={item.title}>
                                <div className={(window.innerWidth > 768) ? "relative-column-33 bottom-margin-20" : "full-width"}>
                                  <div data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={item.dataTip}>
                                    <div className="fixed-column-30">
                                      <div className="mini-spacer" /><div className="mini-spacer" />
                                      <img src={item.icon} alt="Compass logo" className="image-auto-23" />
                                    </div>
                                    <div className="calc-column-offset-30">
                                      {(item.url) ? (
                                        <a href={item.url} target="_blank" >{item.title}</a>
                                      ) : (
                                        <p>{item.title}</p>
                                      )}
                                      {(item.subtitle && !item.url) && (
                                        <p className="description-text-2">{item.subtitle}</p>
                                      )}
                                    </div>
                                    <div className="clear" />

                                  </div>
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>
                                {(index % 3 === 2) && (
                                  <div className="clear" />
                                )}
                              </span>
                            )}
                          </div>
                        )}
                        <div className="clear" />

                        {/*
                        {(this.state.selectedOpportunity.competencyTags && this.state.selectedOpportunity.competencyTags.length > 0) && (
                          <div className="edit-profile-row">
                            <div>
                              <div className="fixed-column-33 right-padding" >
                                <div className="mini-spacer" /><div className="mini-spacer" />
                                <img src={skillsIconBlue} alt="Compass logo" className="image-auto-23" />
                              </div>
                              <div className="calc-column-offset-33">
                                {this.state.selectedOpportunity.competencyTags.map((value, optionIndex) =>
                                  <div key={"competencyTags" + optionIndex} className="float-left right-padding">
                                    {(this.state.selectedOpportunity.competencyTags[optionIndex + 1]) ? (
                                      <label>{value.name},</label>
                                    ) : (
                                      <label>{value.name}</label>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />
                          </div>
                        )}*/}

                      </div>
                    </div>

                    <div className="clear" />

                    <div className="spacer"/>

                    <div className="full-width left-padding-5 right-padding-5">
                      <div className="spacer" /><div className="spacer" />

                      <p className="description-text-2">Description</p>
                      <p className="heading-text-6 keep-line-breaks">{this.state.selectedOpportunity.description}</p>

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.selectedOpportunity.taggedPostings && this.state.selectedOpportunity.taggedPostings.length > 0) && (
                        <div className="row-20">
                          <p className="description-text-2 bottom-margin">Tagged Opportunities</p>
                          {this.state.selectedOpportunity.taggedPostings.map((value, index) =>
                            <div className="bottom-margin-20">
                              <Link className="background-button left-text full-width" to={'/app/opportunities/' + value.postingId}>
                                <div className="fixed-column-40">
                                  <p className="heading-text-6 cta-color">{index + 1}.</p>
                                </div>
                                <div className="fixed-column-50">
                                  <img src={(value.imageURL) ? value.imageURL : opportunitiesIconDark} alt="GC" className="image-40-fit" />
                                </div>
                                <div className="calc-column-offset-120">
                                  <p className="heading-text-6 cta-color">{value.title}{(value.employerName && value.employerName !== 'No Employer Selected') && ' @ '+ value.employerName}</p>
                                  <p className="description-text-2 standard-color top-padding-5">Created on {convertDateToString(value.createdAt,'date')}{(value.submissionDeadline) && " | Apply by " + convertDateToString(value.submissionDeadline,'date')}</p>
                                </div>
                                <div className="fixed-column-30 top-margin-5">
                                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="clear" />
                              </Link>
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              )}

              {(this.state.selectedOpportunity.postType === 'Project' || this.state.selectedOpportunity.postType === 'Assignment' || this.state.selectedOpportunity.postType === 'Problem' || this.state.selectedOpportunity.postType === 'Challenge') && (
                <div>
                  {(this.props.showHeader) && (
                    <div>
                      {(this.state.deadlinePassed) && (
                        <div className="bottom-margin-50">
                          <p className="error-color">The deadline has passed for this challenge</p>
                        </div>
                      )}

                      <div>
                        {(this.state.selectedOpportunity.postType === 'Challenge' || this.state.selectedOpportunity.projectPromptType === 'Challenge') && (
                          <div>
                            <div className="float-left padding-five-percent-right">
                              <div className="float-left right-padding">
                                <img src={prizeIcon} alt="Compass favorites icon" className="image-auto-25" />
                              </div>
                              <label className="description-text-1">${this.state.selectedOpportunity.prizes[0]}</label>

                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" /><div className="half-spacer" />

                            </div>
                            <div className="float-left padding-five-percent-right">
                              <div className="float-left right-padding">
                                <img src={deadlineIcon} alt="Compass deadline icon" className="image-auto-25" />
                              </div>
                              <label className="description-text-1">{this.formatDate(this.state.selectedOpportunity.submissionDeadline)}</label>

                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                            </div>
                          </div>
                        )}

                        {(this.state.selectedOpportunity.difficultyLevel) && (
                          <div className="float-left padding-five-percent-right">
                            <div className="float-left right-padding">
                              <img src={difficultyIcon} alt="Compass favorites icon" className="image-auto-25" />
                            </div>
                            <label className="description-text-1">{this.state.selectedOpportunity.difficultyLevel}</label>

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}
                        {/*
                        {(this.state.selectedOpportunity.upvotes && this.state.selectedOpportunity.downvotes) && (
                          <div className="float-left padding-five-percent-right">
                            <div className="float-left right-padding">
                              <img src={checkmarkIcon} alt="Compass favorites icon" className="image-auto-25" />
                            </div>
                            <label className="description-text-1">{this.state.selectedOpportunity.upvotes.length - this.state.selectedOpportunity.downvotes.length} Popularity</label>

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}*/}

                        <div className="float-left padding-five-percent-right">
                          <div className="float-left right-padding">
                            <img src={industryIcon} alt="Compass favorites icon" className="image-auto-25" />
                          </div>
                          <label className="description-text-1">{this.state.selectedOpportunity.industry} Industry</label>

                          <div className="clear" />
                          <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                        </div>

                        {(this.state.selectedOpportunity.knowledgeLevel) && (
                          <div className="float-left padding-five-percent-right">
                            <div className="float-left right-padding">
                              <img src={reachIcon} alt="Compass knowledge level icon" className="image-auto-25" />
                            </div>
                            <label className="description-text-1">{this.state.selectedOpportunity.knowledgeLevel}</label>

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}

                        {(this.state.selectedOpportunity.pointValue) && (
                          <div className="float-left padding-five-percent-right">
                            <div className="float-left right-padding">
                              <img src={pointsIcon} alt="Compass favorites icon" className="image-auto-25" />
                            </div>
                            <label className="description-text-1">{this.state.selectedOpportunity.pointValue} Points</label>

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}

                        {(this.state.selectedOpportunity.functions && this.state.selectedOpportunity.functions.length > 0) && (
                          <div className="float-left padding-five-percent-right">
                            <div className="float-left right-padding">
                              <img src={skillsIconBlue} alt="Compass favorites icon" className="image-auto-25" />
                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                            </div>

                            {this.state.selectedOpportunity.functions.map((value, index) =>
                              <span>
                                {(index === this.state.selectedOpportunity.functions.length - 1) ? (
                                  <label className="description-text-1">{value}</label>
                                ) : (
                                  <label className="description-text-1">{value}, </label>
                                )}
                              </span>
                            )}

                            <div className="clear" />
                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                          </div>
                        )}

                        {(this.state.selectedOpportunity.tags) && (
                          <div className="float-left">
                            <div className="float-left right-padding">
                              <img src={tagIcon} alt="Compass tag icon" className="image-auto-25" />
                              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                            </div>
                            <label className="description-text-1">{this.state.selectedOpportunity.tags}</label>
                          </div>
                        )}

                      </div>

                      <div className="clear" />

                    </div>
                  )}

                  {(this.props.showBody) && (
                    <div>
                      {(this.state.selectedOpportunity.videoLink && this.state.selectedOpportunity.videoLink !== '') && (
                        <div>
                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                          <div>
                            <div className="video-container">
                              <iframe
                                title="videoLink"
                                className="video-iframe"
                                src={`${this.state.selectedOpportunity.videoLink}`}
                                frameBorder="0"
                              />
                            </div>

                          </div>

                          <div className="clear" />
                          <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.summary) && (
                        <div>
                          <div>
                            <p className="heading-text-4">Summary</p>
                            <div className="row-5">
                              {this.state.summarySplit.map((value, index) => <p className="description-text-2 dark-color half-bold-text">{value}</p>)}
                            </div>
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.submissionDeadline) && (
                        <div>
                          <div>
                            <p className="heading-text-4">Submission Deadline</p>

                            <div className="row-5">
                              {(this.state.selectedOpportunity.submissionDeadline && this.state.selectedOpportunity.submissionDeadline !== '') ? (
                                <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{convertDateToString(new Date(this.state.selectedOpportunity.submissionDeadline),'datetime-2')}</p>
                              ) : (
                                <p className="keep-line-breaks"></p>
                              )}
                            </div>

                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.duration) && (
                        <div>
                          <div>
                            <p className="heading-text-4">Duration</p>

                            <div className="row-5">
                              <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.duration} Estimated Man Hours</p>
                            </div>
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.isExternal && this.state.selectedOpportunity.source) ? (
                        <div>
                        <p className="heading-text-4">External Source</p>
                        <a target="_blank" href={this.state.selectedOpportunity.source}>{this.state.selectedOpportunity.source}</a>
                        <div className="spacer"/><div className="spacer"/>
                        </div>
                      ) : (
                        <div>
                          {(this.state.selectedOpportunity.description) && (
                            <div>
                              <p className="heading-text-4">Description</p>

                              <div className="row-5">
                                <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.description}</p>
                              </div>

                              <div className="spacer"/><div className="spacer"/>
                            </div>
                          )}
                          {/*
                          {(this.state.selectedOpportunity.richDescription) && (
                            <div>
                              <p className="heading-text-4">Description</p>
                              <div>
                                <MyEditor showText={true} existingContent={{ blocks: this.state.selectedOpportunity.richDescription, entityMap: {}}} />
                              </div>
                              <div className="spacer"/><div className="spacer"/>
                            </div>
                          )}*/}
                        </div>
                      )}

                      {(this.state.selectedOpportunity.background) && (
                        <div>
                        <p className="heading-text-4">Background</p>

                        <div className="row-5">
                          <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.background}</p>
                        </div>

                        <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.employerInfo) && (
                        <div>
                          <p className="heading-text-4">Employer Info</p>

                          <div className="row-5">
                            <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.employerInfo}</p>
                          </div>

                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>

                      {(this.state.selectedOpportunity.lessons) && (
                        <div>
                        <p className="heading-text-4">Lessons</p>

                        <div className="row-5">
                          <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.lessons}</p>
                        </div>

                        <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.postType === 'Challenge' || this.state.selectedOpportunity.projectPromptType === 'Challenge') && (
                        <div>
                          <p className="heading-text-4">Rules</p>

                          <div className="row-5">
                            <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.rules}</p>
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.guidelines) && (
                        <div>
                          <p className="heading-text-4">Guidelines</p>

                          <div className="row-5">
                            <p className="keep-line-breaks description-text-2 dark-color half-bold-text">{this.state.selectedOpportunity.guidelines}</p>
                          </div>

                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>

                      {(this.state.selectedOpportunity.resources && this.state.selectedOpportunity.resources.length > 0) && (
                        <div>
                        <p className="heading-text-4">Resources</p>

                        <div className="row-5 description-text-2">
                          {this.state.selectedOpportunity.resources.map((value, index) => <div><label>{index + 1}. </label><a href={value} target="_blank">{value}</a></div>)}
                        </div>

                        <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      {(this.state.selectedOpportunity.postType === 'Challenge' || this.state.selectedOpportunity.projectPromptType === 'Challenge') && (
                        <div>
                          <div>
                            <p className="heading-text-4">Prizes</p>

                            <div className="row-5 description-text-2">
                              <p><label className="half-bold-text">1st Place Prize:</label> ${this.state.selectedOpportunity.prizes[0]}</p>
                              {console.log('show prize 1: ', this.state.selectedOpportunity.prizes[1])}
                              {(this.state.selectedOpportunity.prizes[1]) ? (
                                <p><label className="half-bold-text">2nd Place Prize:</label> ${this.state.selectedOpportunity.prizes[1]}</p>
                              ) : (
                                <div />
                              )}
                              {(this.state.selectedOpportunity.prizes[2]) ? (
                                <p><label className="half-bold-text">3rd Place Prize:</label> ${this.state.selectedOpportunity.prizes[2]}</p>
                              ) : (
                                <div />
                              )}
                            </div>

                          </div>

                          <div className="spacer"/><div className="spacer"/>
                          {/*
                          <div>
                            <p className="heading-text-4">Evaluation Method</p>
                            <p>{this.state.selectedOpportunity.evaluationMethod}</p>
                          </div>

                          <div className="spacer"/><div className="spacer"/>*/}

                          <div>
                            <p className="heading-text-4">Timeline</p>
                            <ul className="left-padding-25 row-5 description-text-2">
                              <li><label className="half-bold-text">Register and follow by:</label> {convertDateToString(new Date(this.state.selectedOpportunity.startDate),"datetime-2")}</li>
                              <li><label className="half-bold-text">Submission Deadline:</label> {convertDateToString(new Date(this.state.selectedOpportunity.submissionDeadline),"datetime-2")}</li>
                              <li><label className="half-bold-text">Winner Announcement Date:</label> {convertDateToString(new Date(this.state.selectedOpportunity.announcementDate),"datetime-2")}</li>
                            </ul>
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                        </div>
                      )}

                      <div>
                        {this.renderDetails('section')}
                      </div>

                      <div>
                        {this.renderDetails('exhibit')}
                      </div>

                      <div className="clear" />
                    </div>
                  )}

                </div>
              )}

              {(this.state.selectedOpportunity.postType === 'Event') && (
                <div>
                  <div>
                    <div>
                      <div className="fixed-column-40" >
                        <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        {/*<img src={profileIconBig} alt="img" className="profile-thumbnail-3" />*/}
                        <img src={(this.state.orgLogo) ? this.state.orgLogo : profileIconBig} alt="GC" className="image-30-fit" />
                      </div>
                      <div className="calc-column-offset-40 left-padding">
                        <div className="spacer" />
                        <p>Hosted by {this.state.selectedOpportunity.orgName}</p>
                      </div>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>

                    <div>
                      <div className="fixed-column-40" >
                        <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={locationIcon} alt="GC" className="image-auto-23" />
                      </div>
                      <div className="calc-column-offset-40 left-padding">
                        <div className="spacer" />
                        <p className="wrap-text">Location: {this.state.selectedOpportunity.location}</p>

                      </div>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>

                    <div>
                      <div className="fixed-column-40" >
                        <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={timeIconDark} alt="Compass logo" className="image-auto-23" />
                      </div>
                      <div className="calc-column-offset-40 left-padding">
                        <div className="spacer" />
                        <p>{this.state.startDateString} - {this.state.endDateString}</p>
                      </div>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>

                    <div>
                      <div className="fixed-column-40" >
                        <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={infoIconDark} alt="Compass logo" className="image-auto-23" />
                      </div>
                      <div className="calc-column-offset-40 left-padding">
                        <div className="spacer" />
                        <p className="keep-line-break">{(this.state.selectedOpportunity.description) ? this.state.selectedOpportunity.description : this.state.selectedOpportunity.summary}</p>
                      </div>
                    </div>
                    <div className="clear" />

                    {(this.state.selectedOpportunity.links && this.state.selectedOpportunity.links.length > 0) && (
                      <div>
                        <div className="spacer"/>
                        <div>
                          <div className="fixed-column-40" >
                            <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <img src={linkIcon} alt="Compass logo" className="image-auto-23" />
                          </div>
                          <div className="calc-column-offset-40 left-padding">
                            <div className="spacer" />
                            {this.state.selectedOpportunity.links.map((value, index) =>
                              <div key={index}>
                                <label><a href={value.url} target="_blank" className="background-link">{value.name}</a></label>
                                <div className="spacer"/>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }
}

export default RenderOpportunityDetails;
