import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import Postings from './Subcomponents/Postings';
import DeactivatedModal from './Common/DeactivatedModal';
import withRouter from './Functions/WithRouter';

class EmpJobs extends Component {
    constructor(props) {
      super(props)

      this.state = {
        renderSideNav: false,
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      //obtain email from localStorage
      // let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        const path = window.location.pathname

        this.setState({ emailId: email, username, emp, orgMode, activeOrg, orgLogo, path });

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const deactivated = response.data.orgInfo.deactivated
            let redirectLink = response.data.orgInfo.employerRedirectLink
            if (redirectLink && !redirectLink.startsWith('http')) {
              redirectLink = 'http://' + redirectLink
            }
            let modalIsOpen = false
            if (deactivated) {
              modalIsOpen = true
            }

            this.setState({
                orgName, deactivated, redirectLink, modalIsOpen
            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

        return (
            <div>
                <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

                {(this.state.deactivated) ? (
                  <div>
                    <DeactivatedModal orgName={this.state.orgName} accountCode={this.state.emp} roleName={this.state.roleName} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} redirectLink={this.state.redirectLink} />
                  </div>
                ) : (
                  <div>
                    {(this.state.renderSideNav) && (
                      <SubSideNav selectedComponent={"EmpJobs"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} accountCode={this.state.emp} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />
                    )}

                    <Postings source="Employer" path={this.state.path} activeOrg={this.state.activeOrg} accountCode={this.state.emp} history={this.props.navigate} renderSideNav={this.state.renderSideNav} />
                  </div>
                )}

                <div className="clear"/>

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(EmpJobs)
