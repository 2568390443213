import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

class PartnersPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#F5F5F5";

      const isActive = true
      const isPublic = true

      Axios.get('/api/org/all', { params: { isActive, isPublic } })
      .then((response) => {
         console.log('All orgs query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved orgs')

           const orgs = response.data.orgs
           this.setState({ orgs })

         } else {
           console.log('no orgs found', response.data.message)
         }

      }).catch((error) => {
           console.log('Orgs query did not work', error);
      });
    }

    render() {
        return (
          <div>
            <TopNavigation currentPage={"partnersPage"} darkBackground={true} />

            <div className="contact-container">
              <br/>
              <h2>Public Partners</h2>

              <p className="subtitle-container">
                Partners are workforce training / placement programs that source career events, projects, and work opportunities for career-seekers. The following organizations are open to eligible career-seekers:
              </p>

              <div className="row row-20">
                <div className="horizontal-padding-3">
                  {this.state.orgs && this.state.orgs.map((value, index) =>
                    <div key={index}>
                      <div className="float-left col span-1-of-3 box center-text">
                        <Link className="background-button standard-color" to={'/partners/' + value.orgCode}>
                          <div className="bottom-margin">
                            <img src={value.webLogoURIColor} alt="GC" className="image-auto-50 center-horizontally" />
                          </div>
                          <h4>{value.orgName}</h4>
                          <p className="limited-description">{value.orgDescription}</p>
                        </Link>
                      </div>

                      {(index % 3 === 2) && (
                        <div className="clear" />
                      )}
                    </div>
                  )}
                </div>
              </div>

            </div>

            <Footer history={this.props.navigate} />
          </div>

        )
    }
}

export default PartnersPage;
