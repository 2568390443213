import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';

const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';

class CourseAssignment extends Component {
    constructor(props) {
        super(props)

        this.state = {
          attachmentOptions: [],
          placementOptions: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.saveAssignment = this.saveAssignment.bind(this)
        this.deleteAssignment = this.deleteAssignment.bind(this)
        this.submitToAssignment = this.submitToAssignment.bind(this)
        this.deleteSubmission = this.deleteSubmission.bind(this)
        this.addItem = this.addItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.configureModal = this.configureModal.bind(this)

    }

    componentDidMount() {

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgCode = localStorage.getItem('activeOrg');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');
      const pictureURL = localStorage.getItem('pictureURL');

      const workMode = this.props.workmode
      let placementOptions = null
      if (this.props.placementOptions) {
        placementOptions = [{ title: '' }].concat(this.props.placementOptions)
      }
      const selectedAssignment = this.props.selectedAssignment

      const selectedCourse = this.props.selectedCourse
      const selectedLesson = this.props.selectedLesson
      const selectedIndex = this.props.selectedIndex
      const moduleIndex = this.props.moduleIndex
      const lessonIndex = this.props.lessonIndex

      this.setState({ emailId, cuFirstName, cuLastName, roleName, username, pictureURL,
        orgCode, orgName, workMode, placementOptions, selectedAssignment,
        selectedCourse, selectedLesson, selectedIndex, moduleIndex, lessonIndex
      })

      let content = null
      if (selectedLesson && selectedLesson.content) {
        content = selectedLesson.content
      }

      this.configureModal(content, selectedIndex, emailId)
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in CourseAssignment', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubCourseAssignment', this.props)

    }

    configureModal(content, contentIndex, emailId) {
      console.log('configureModal called')

      this.setState({ isLoading: true })

      let selectedSubmission = this.props.selectedSubmission
      let submissionDetails = null
      let attachments = null
      let externalURL = null

      let assignmentTitle = ''
      let assignmentDeadline = ''
      let assignmentDuration = ''
      let selectedPlacement = null

      let submissionTitle = ''

      if (this.props.selectedAssignment) {
        assignmentTitle = this.props.selectedAssignment.name
        if (this.props.selectedAssignment.title) {
          assignmentTitle = this.props.selectedAssignment.title
        }
        assignmentDeadline = this.props.selectedAssignment.deadline
        assignmentDuration = this.props.selectedAssignment.duration
        selectedPlacement = this.props.selectedAssignment.selectedPlacement

        submissionTitle = this.props.selectedAssignment.name
      } else if (content) {
        submissionTitle = content[contentIndex].title + ' - ' + this.state.cuFirstName + " " + this.state.cuLastName
      }

      if (this.state.submissions && this.state.submissions.some(sub => sub.assessmentId === content[0].contentId)) {
        const subIndex = this.state.submissions.findIndex(sub => sub.assessmentId === content[0].contentId)
        // console.log('----: ', subIndex, this.state.submissions, content[0], subIndex)
        selectedSubmission = this.state.submissions[subIndex]
        submissionTitle = selectedSubmission.title
        submissionDetails = selectedSubmission.details
        attachments = selectedSubmission.attachments
        externalURL = selectedSubmission.externalURL
      }

      const self = this

      function pullGoals(attachmentOptions) {
        console.log('pullGoals called')

        Axios.get('/api/logs/goals', { params: { emailId } })
        .then((response) => {

          if (response.data.success && response.data.goals) {
            console.log('Goals received query worked', response.data);

            for (let i = 0; i < response.data.goals.length; i++) {
              const goalId = response.data.goals[i]._id
              const goalName = response.data.goals[i].title
              const createdAt = response.data.goals[i].createdAt
              attachmentOptions.push({ id: goalId, name: goalName, source: 'Native', url: null, type: 'Goal', createdAt })
            }

            self.setState({ modalIsOpen: true, showSubmitWidget: true, contentIndex, showFullView: false,
              attachmentOptions, isLoading: false,
              selectedSubmission, assignmentTitle, assignmentDeadline, assignmentDuration, selectedPlacement,
              submissionTitle, submissionDetails, attachments, externalURL,
            })

          } else {
            console.log('no goal data found', response.data.message)

            self.setState({ modalIsOpen: true, showSubmitWidget: true, contentIndex, showFullView: false,
              attachmentOptions, selectedSubmission, isLoading: false,
              selectedSubmission, assignmentTitle, assignmentDeadline, assignmentDuration, selectedPlacement,
              submissionTitle, submissionDetails, attachments, externalURL,
            })
          }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });
      }

      Axios.get('/api/documents', { params: { emailId} })
     .then((response) => {
       console.log('Documents query attempted', response.data);

       if (response.data.success && response.data.documents) {
         console.log('successfully retrieved documents')

         let attachmentOptions = [{ name: '' }]
         for (let i = 0; i < response.data.documents.length; i++) {
           const docId = response.data.documents[i]._id
           const docName = response.data.documents[i].fileName
           const docType = response.data.documents[i].docType
           const docURL = window.location.protocol + "//" + window.location.host + "/documents/preview/" + docId
           const createdAt = response.data.documents[i].createdAt
           attachmentOptions.push({ id: docId, name: docName, source: 'Native', url: docURL, type: docType, createdAt })
         }

          pullGoals(attachmentOptions)

       } else {
         console.log('no documents data found', response.data.message)
         pullGoals([{ name: '' }])
       }

     }).catch((error) => {
         console.log('Documents query did not work', error);
     });

     if (this.props.selectedAssignment && this.props.selectedAssignment._id) {
       Axios.get('/api/submissions', { params: { assessmentId: this.props.selectedAssignment._id, emailId } })
      .then((response) => {
        console.log('Submissions query attempted', response.data);

        if (response.data.success && response.data.submissions) {
          console.log('successfully retrieved documents')

          selectedSubmission = response.data.submissions[0]

          submissionTitle = selectedSubmission.title
          submissionDetails = selectedSubmission.details
          attachments = selectedSubmission.attachments
          externalURL = selectedSubmission.externalURL

        } else {
          console.log('no documents data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Documents query did not work', error);
      });
     }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name === 'attachment') {

        let selectedAttachment = null
        if (this.state.attachmentOptions) {
          for (let i = 1; i <= this.state.attachmentOptions.length; i++) {
            if (this.state.attachmentOptions[i - 1].name === event.target.value) {
              selectedAttachment = this.state.attachmentOptions[i - 1]
            }
          }
        }
        this.setState({ selectedAttachment })
      } else if (event.target.name === 'placement') {
        let tempPlacement = null
        if (this.state.placementOptions) {
          for (let i = 1; i <= this.state.placementOptions.length; i++) {
            if (this.state.placementOptions[i - 1].title === event.target.value) {
              tempPlacement = this.state.placementOptions[i - 1]
            }
          }
        }
        this.setState({ tempPlacement })
      } else {
        this.setState({ [event.target.name] : event.target.value })
      }
    }

    saveAssignment() {
      console.log('saveAssignment called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.title === '') {
        this.setState({ isSaving: true, errorMessage: 'please add a title' })
      } else if (!this.state.selectedPlacement) {
        this.setState({ isSaving: true, errorMessage: 'please select a position' })
      } else {
        console.log('about to save', this.state.questions)

        let _id = undefined
        if (this.state.selectedAssignment && this.state.selectedAssignment._id) {
          _id = this.state.selectedAssignment._id
        }

        const type = 'Assignment'
        const title = this.state.assignmentTitle
        const description = this.state.description
        const maxPoints = 10

        const deadline = this.state.assignmentDeadline
        const duration = this.state.assignmentDuration
        const selectedPlacement = this.state.selectedPlacement
        const isSimulation = true

        const creatorEmail = this.state.emailId
        const orgCode = this.state.orgCode

        const createdAt = new Date()
        const updatedAt = new Date()

        let resourceObject = {
          _id, type, title, description, maxPoints,
          deadline, duration, selectedPlacement, isSimulation,
          creatorEmail, orgCode, createdAt, updatedAt
        }

        Axios.post('/api/resources', resourceObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Resource save worked', response.data);

            if (response.data._id) {
              resourceObject['_id'] = response.data._id
            }

            this.setState({ isSaving: false, successMessage: 'Successfully saved resource' })

            if (window.location.pathname.endsWith('/take')) {
              // this.props.navigate(-1)
            }

            if (this.props.passData) {
              this.props.passData(resourceObject)
            }

            if (this.props.closeModal) {
              this.props.closeModal()
            }

          } else {
            console.error('there was an error saving the resource');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Resource save did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    deleteAssignment() {
      console.log('deleteAssignment called')

      const self = this
      function actuallyDelete() {
        console.log('actuallyDelete')

        if (self.props.passData) {
          self.props.passData(null, true)
        }
        if (self.props.closeModal) {
          self.props.closeModal()
        }
      }

      if (this.state.selectedAssignment && this.state.selectedAssignment._id) {

        const _id = this.state.selectedAssignment._id

        Axios.delete('/api/resources/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Resource delete worked');

            actuallyDelete()

          } else {
            console.error('there was an error deleting the resource');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      } else {
        actuallyDelete()
      }
    }

    submitToAssignment() {
      console.log('submitToAssignment called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.submissionTitle || this.state.submissionTitle === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a submission title' })
      } else if (!this.state.submissionDetails || this.state.submissionDetails === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add submission details' })
      } else {

        let _id = null
        if (this.state.selectedSubmission) {
          _id = this.state.selectedSubmission._id
        }

        const title = this.state.submissionTitle
        const details = this.state.submissionDetails
        const attachments = this.state.attachments
        const externalURL = this.state.externalURL

        let subType = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].content[0].category
        let courseId = this.state.selectedCourse._id
        let courseName = this.state.selectedCourse.name
        let lessonName = this.state.selectedLesson.name
        let assessmentId = this.state.selectedLesson.content[0].contentId
        let assessmentName = this.state.selectedLesson.content[0].title
        if (this.state.selectedAssignment) {
          assessmentId = this.state.selectedAssignment._id
          assessmentName = this.state.selectedAssignment.title
        }

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const emailId = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        const selectedPlacement = this.state.selectedPlacement
        const isSimulation = true

        const orgCode = this.state.orgCode
        const orgName = this.state.orgName

        const createdAt = new Date()
        const updatedAt = new Date()

        let subObject = {
          _id, title, details, attachments, externalURL, subType,
          courseId, courseName, lessonName, assessmentId, assessmentName,
          firstName, lastName, emailId, username, pictureURL, roleName,
          selectedPlacement, isSimulation,
          orgCode, orgName, createdAt, updatedAt
        }

        Axios.post('/api/submissions', subObject)
        .then((response) => {
          console.log('attempting to save submission')
          if (response.data.success) {
            console.log('saved submission', response.data)

            let successMessage = 'You have successfully submitted your answers!'
            let errorMessage = null

            if (this.props.workMode) {
              if (response.data._id) {
                subObject['_id'] = response.data._id
                const selectedSubmission = subObject
                this.setState({ isSaving: false, successMessage: "You have successfully submitted to this assignment"})

              } else {
                this.setState({ isSaving: false, successMessage: "You have successfully updated your submission"})
              }
            } else {
              let submissions = this.state.submissions
              if (submissions) {
                submissions.push(response.data.submission)
              } else {
                submissions = [response.data.submission]
              }

              let progress = this.state.progress
              progress[this.state.moduleIndex]['lessons'][this.state.lessonIndex]['isCompleted'] = true
              this.setState({ isSaving: false, errorMessage, successMessage, progress })
              this.closeModal()

              this.saveProgress(progress)
            }

          } else {
            console.log('did not save successfully')
            this.setState({ isSaving: false, errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ isSaving: false, errorMessage: 'there was an error saving submission' })
        });
      }
    }

    deleteSubmission() {
      console.log('deleteSubmission called')

      // const self = this
      // function actuallyDelete() {
      //   console.log('actuallyDelete')
      //
      //   if (self.props.passData) {
      //     self.props.passData(null, true)
      //   }
      //   if (self.props.closeModal) {
      //     self.props.closeModal()
      //   }
      // }

      if (this.state.selectedSubmission && this.state.selectedSubmission._id) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const _id = this.state.selectedSubmission._id

        Axios.delete('/api/submissions/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Submission delete worked');

            this.setState({ isSaving: false, successMessage: 'Submission successfully deleted',
              confirmSubmissionDelete: false, selectedSubmission: null,
              submissionTitle: '', submissionDetails: '', attachments: null, externalURL: ''
            })

          } else {
            console.error('there was an error deleting the resource');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'attachment') {
        let attachments = this.state.attachments
        if (attachments) {
          if (attachments.some(att => att.id === this.state.selectedAttachment.id)) {
            this.setState({ errorMessage: "You have already added this attachment" })
          } else {
            attachments.push(this.state.selectedAttachment)
            this.setState({ attachments, selectedAttachment: null, errorMessage: null })
          }

        } else {
          attachments = [this.state.selectedAttachment]
          this.setState({ attachments, selectedAttachment: null, errorMessage: null })
        }
      } else if (type === 'trainings') {
        let trainings = this.state.trainings
        trainings.push({ title: '' })
        this.setState({ trainings })
      } else if (type === 'placement') {
        let selectedPlacement = this.state.tempPlacement
        this.setState({ selectedPlacement })
      }
    }

    deleteItem(type, index) {
      console.log('deleteItem called')

      if (type === 'placement') {
        let selectedPlacement = null
        this.setState({ selectedPlacement })
      } else {
        let attachments = this.state.attachments
        attachments.splice(index,1)
        this.setState({ attachments })
      }
    }

    render() {
      return (
        <div className="padding-20">
          {(this.props.workMode) && (
            <div>
              <p className="heading-text-2">{(this.state.selectedPlacement) ? this.state.selectedPlacement.title + " Assignment" : (this.props.workMode) ? "Assignment" : (this.state.selectedIndex || this.state.selectedIndex === 0) ? this.state.selectedLesson.content[0].title + " Assignment" : "Assignment"}</p>
              <div className="spacer" />

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Title <label className="error-color bold-text">*</label></label>
                  <input type="text" className="text-field" placeholder="e.g., My Awesome Title..." name={"assignmentTitle"} value={this.state.assignmentTitle} onChange={this.formChangeHandler}></input>
                </div>
                <div className="container-right">
                  <label className="profile-label">Deadline</label>
                  <input type="date" className="date-field" placeholder="e.g., My Awesome Title..." name={"assignmentDeadline"} value={(this.state.assignmentDeadline) ? convertDateToString(new Date(this.state.assignmentDeadline),"rawDateForInput") : ""} onChange={this.formChangeHandler}></input>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Duration (Estimated Hours to Complete)</label>
                  <input type="number" className="number-field" placeholder="0" name={"assignmentDuration"} value={this.state.assignmentDuration} onChange={this.formChangeHandler}></input>
                </div>
                <div className="container-right">

                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div>
                  <div className="calc-column-offset-30 right-padding">
                    <label className="profile-label">Related Position <label className="error-color bold-text">*</label></label>
                  </div>
                  {/*
                  <div className="fixed-column-30 top-margin">
                    <button className="background-button width-30 height-30 circle-radius cta-background-color padding-5" onClick={() => this.addItem('attachment')}>
                      <img src={addIconWhite} alt="GC" className="image-20-fit" />
                    </button>
                  </div>*/}
                  <div className="clear" />
                </div>

                <div>
                  <div className="calc-column-offset-70 right-padding">
                    <select name="placement" value={(this.state.tempPlacement) ? this.state.tempPlacement.title : ""} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.placementOptions.map(value =>
                        <option key={value.title} value={value.title}>{value.title}</option>
                      )}
                    </select>
                  </div>
                  <div className="fixed-column-70">
                    <button className={(this.state.tempPlacement) ? "btn btn-squarish full-width" : "btn btn-squarish medium-background standard-border full-width"} disabled={(this.state.tempPlacement) ? false : true} onClick={() => this.addItem('placement')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.selectedPlacement) && (
                  <div className="top-margin-15">
                    <div className="fixed-column-40 top-margin">
                      <button className="background-button z-index-1" onClick={() => this.deleteItem('placement', 0)}>
                        <img src={deniedIcon} alt="GC" className="image-auto-22 pin-right" />
                      </button>
                    </div>
                    <div className="tag-container-basic light-background calc-column-offset-40">
                      <p>{this.state.selectedPlacement.title} (created {convertDateToString(new Date(this.state.selectedPlacement.createdAt),"date-2")})</p>
                    </div>
                    <div className="clear" />
                  </div>
                )}
              </div>

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

              {(this.state.confirmAssignmentDelete) ? (
                <div className="row-10">
                  <p className="description-text-2 error-color row-10">Are you sure you want to delete this assignment?</p>
                  <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteAssignment()}>Yes, Delete Permanently</button>
                  <button className="btn btn-squarish-white right-margin" onClick={() => this.setState({ confirmAssignmentDelete: false })}>Cancel</button>
                </div>
              ) : (
                <div className="row-10">
                  <button className="btn btn-primary right-margin" onClick={() => this.saveAssignment()}>Save Assignment</button>
                  {(this.state.selectedAssignment) && (
                    <button className="btn btn-quaternary right-margin" onClick={() => this.setState({ confirmAssignmentDelete: true })}>Delete</button>
                  )}
                </div>
              )}
            </div>
          )}

          {((window.location.pathname.endsWith('/take') && this.state.selectedAssignment && this.state.selectedAssignment._id) || (!window.location.pathname.endsWith('/take'))) && (
            <div>
              {(window.location.pathname.endsWith('/take')) && (
                <div className="row-30">
                  <hr />
                </div>
              )}

              <div className={(window.location.pathname.endsWith('/take')) ? "standard-border padding-30" : ""}>
                <p className={(window.location.pathname.endsWith('/take')) ? "heading-text-3" : "heading-text-2"}>Submit to {(this.state.assignmentTitle) ? this.state.assignmentTitle : (this.props.workMode) ? "Position" : (this.state.selectedIndex || this.state.selectedIndex === 0) ? this.state.selectedLesson.content[0].title : "Course"}</p>
                <div className="spacer" />

                <div className="row-10">
                  <label className="profile-label">Title <label className="error-color bold-text">*</label></label>
                  <input type="text" className="text-field" placeholder="e.g., My Awesome Title..." name={"submissionTitle"} value={this.state.submissionTitle} onChange={this.formChangeHandler}></input>
                </div>

                <div className="row-10">
                  <label className="profile-label">Summary <label className="error-color bold-text">*</label></label>
                  <textarea type="text" className="text-field" placeholder="(e.g., Write details, comments, feedback, etc ...)" name={"submissionDetails"} value={this.state.submissionDetails} onChange={this.formChangeHandler}></textarea>
                </div>

                <div className="row-10">
                  <div>
                    <div className="calc-column-offset-30 right-padding">
                      <label className="profile-label">Add Attachments from your Guided Compass Profile</label>
                    </div>
                    {/*
                    <div className="fixed-column-30 top-margin">
                      <button className="background-button width-30 height-30 circle-radius cta-background-color padding-5" onClick={() => this.addItem('attachment')}>
                        <img src={addIconWhite} alt="GC" className="image-20-fit" />
                      </button>
                    </div>*/}
                    <div className="clear" />
                  </div>

                  <div>
                    <div className="calc-column-offset-70 right-padding">
                      <select name="attachment" value={(this.state.selectedAttachment) ? this.state.selectedAttachment.name : ""} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.attachmentOptions.map(value =>
                          <option key={value.name} value={value.name}>{(value.id) ? value.name + ' (created ' + convertDateToString(new Date(value.createdAt),"date-2") + ') [' + value.type + ']' : ""}</option>
                        )}
                      </select>
                    </div>
                    <div className="fixed-column-70">
                      <button className={(this.state.selectedAttachment) ? "btn btn-squarish full-width" : "btn btn-squarish medium-background standard-border full-width"} disabled={(this.state.selectedAttachment) ? false : true} onClick={() => this.addItem('attachment')}>Add</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.attachments) && (
                    <div>
                      {this.state.attachments.map((item, optionIndex) =>
                        <div key={"attachment|" + optionIndex} className="top-margin-15">
                          <div className="fixed-column-40 top-margin">
                            <button className="background-button z-index-1" onClick={() => this.deleteItem('attachment', optionIndex)}>
                              <img src={deniedIcon} alt="GC" className="image-auto-22 pin-right" />
                            </button>
                          </div>
                          <div className="tag-container-basic light-background calc-column-offset-40">
                            <div className="fixed-column-40">
                              <p>{optionIndex + 1}.</p>
                            </div>
                            <div className="calc-column-offset-80">
                              <p>{item.name} (created {convertDateToString(new Date(item.createdAt),"date-2")}) [{item.type}]</p>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row-10">
                  <label className="profile-label">Link to External Document</label>
                  <input type="text" className="text-field" placeholder="(e.g., https://drive.google.com/12345679...)" name={"externalURL"} value={this.state.externalURL} onChange={this.formChangeHandler}></input>
                  {(this.state.externalURL && !this.state.externalURL.startsWith('http')) && (
                    <div className="top-margin-5">
                      <p className="error-color description-text-2">Please start your link with http</p>
                    </div>
                  )}
                </div>

                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                {(this.state.confirmSubmissionDelete) ? (
                  <div className="row-10">
                    <p className="description-text-2 error-color row-10">Are you sure you want to delete this submission?</p>
                    <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteSubmission()}>Yes, Delete Permanently</button>
                    <button className="btn btn-squarish-white right-margin" onClick={() => this.setState({ confirmSubmissionDelete: false })}>Cancel</button>
                  </div>
                ) : (
                  <div className="row-10">
                    <button className="btn btn-primary right-margin" onClick={() => this.submitToAssignment()}>{(this.state.selectedSubmission) ? "Update Submission" : "Submit to Assignment"}</button>
                    {(this.state.selectedSubmission) && (
                      <button className="btn btn-quaternary right-margin" onClick={() => this.setState({ confirmSubmissionDelete: true })}>Delete</button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }
}

export default CourseAssignment;
