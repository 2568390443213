import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubTakeCourse from './Subcomponents/TakeCourse';
import withRouter from './Functions/WithRouter';

class TakeCourse extends Component {
    constructor(props) {
      super(props)

      this.state = {
        hideHelpPrompt: true
      }

      this.commandClicked = this.commandClicked.bind(this)
      this.toggleNav = this.toggleNav.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { id} = this.props.params
      // console.log('show match: ', id)
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const orgLogo = localStorage.getItem('orgLogo');
      const activeOrg = localStorage.getItem('activeOrg');

      this.setState({ emailId: email, username, orgLogo, id, activeOrg })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCourseDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.id !== prevProps.params.id) {
        console.log('new course selected in parent')
        this.setState({ id: this.props.params.id })
      }
    }

    commandClicked(item) {
      console.log('commandClicked called')

      const goal = "starting a business"
      const salary = "$100K"
      const jobTitle = "Software Engineer"
      const employerName = "Google"
      const businessIdea = "healthy meal app"

      const field = "health & wellness"
      const offerAmount = "$100K"
      const targetCustomer = "Vegan yoga instructors"
      const productName = "Healthispan"
      const productDescription = "healthy meal planning for health conscious individuals"
      const businessName = "Helathispan"
      const businessDescription = "healthy meal planning for health conscious individuals"
      const interest1 = "basketball"
      const interest2 = "music"
      const interest3 = "cooking"
      const skill1 = "communicating with people"
      const skill2 = "collaborating with people"
      const skill3 = "staying positive"
      const myersBriggsScore = "ESFP"
      const interviewQuestion = "How do you prioritize product features and make trade-off decisions?"
      const interviewResponse = "I score features on a scale of 1-10 for both their impact and their effort. Then I calculate the impact divided by effort. Then I sort the results, prioritizing those with the highest ratios."

      const name = item.name.replace(/{{ goal }}/g,goal).replace(/{{ salary }}/g,salary).replace(/{{ jobTitle }}/g,jobTitle).replace(/{{ employerName }}/g,employerName).replace(/{{ businessIdea }}/g,businessIdea).replace(/{{ field }}/g,field).replace(/{{ offerAmount }}/g,offerAmount).replace(/{{ targetCustomer }}/g,targetCustomer).replace(/{{ productName }}/g,productName).replace(/{{ productDescription }}/g,productDescription).replace(/{{ businessName }}/g,businessName).replace(/{{ businessDescription }}/g,businessDescription).replace(/{{ interest1 }}/g,interest1).replace(/{{ interest2 }}/g,interest2).replace(/{{ interest3 }}/g,interest3).replace(/{{ skill1 }}/g,skill1).replace(/{{ skill2 }}/g,skill2).replace(/{{ skill3 }}/g,skill3).replace(/{{ myersBriggsScore }}/g,myersBriggsScore).replace(/{{ interviewQuestion }}/g,interviewQuestion).replace(/{{ interviewResponse }}/g,interviewResponse)

      // this.child.current.passCommand(name);
      this.setState({ passedCommand: name })

    }

    toggleNav(hideNav,source) {
      console.log('toggleNav called', hideNav, source)

      this.setState({ hideNav })

    }

    render() {

        return (
            <div>
              {(!this.state.hideNav) && (
                <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              )}

              <div>
                <SubTakeCourse courseId={this.state.id} commandClicked={this.commandClicked} toggleNav={this.toggleNav}/>
              </div>

              {(this.state.activeOrg && !this.state.hideHelpPrompt) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand, this.state.hideNav)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(TakeCourse);
