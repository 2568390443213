import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSettings from './Subcomponents/Settings';
import withRouter from './Functions/WithRouter';

class Settings extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
      this.passActiveOrg = this.passActiveOrg.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let isAdvisor = localStorage.getItem('isAdvisor');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let orgMode = true
      if (!activeOrg || activeOrg === '') {
        orgMode = false
      }

      let dataToShare = [
        'Name', 'Profile Photo','Resume URL','LinkedIn URL','Portfolio URL','School','Major / Pathway','Graduation Year','Race','Gender','Veteran Status','Work Authorization','Projects','Experience','Career Assessments','Endorsements'
      ]

      let showWalkthrough = false
      if (activeOrg === 'unite-la') {
        showWalkthrough = true
      }

      this.setState({ emailId: email, username, isAdvisor, activeOrg, orgLogo,
        orgFocus, orgMode, dataToShare, showWalkthrough
      });
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    passActiveOrg(activeOrg) {
      this.loadWorkspace(activeOrg)
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />
          <SubSettings email={this.state.emailId} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} history={this.props.navigate}  />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(Settings)
