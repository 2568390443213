import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubEditProfile from './Subcomponents/EditProfile';
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class AdvEditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opacity: 0,
            transform: 'translate(90%)'
        }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (orgFocus === 'Placement' && roleName === 'Teacher') {
        roleName = 'Mentor'
      }

      if (email) {

        let profilePath = window.location.protocol + "//" + window.location.host + "/advisor/profile/" + username

        this.setState({ activeOrg, emailId: email, username, orgFocus,
          roleName, profilePath, orgLogo
        })
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

              {(this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin' && !toggleVars.hideSideNav) && (
                <SubSideNav selectedComponent={"AdvEditProfile"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <div className={(this.state.roleName === 'Mentor' || this.state.roleName === 'Admin' || toggleVars.hideSideNav) ? "standard-container-2" : "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"} style={(this.state.roleName === 'Mentor') ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                <SubEditProfile activeOrg={this.state.activeOrg} history={this.props.navigate} />
              </div>

              <div className="clear" />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(AdvEditProfile);
