import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import SubRenderOpportunities from '../../components/Common/RenderOpportunities';
import SubRenderProfiles from '../../components/Common/RenderProfiles';
import SubRenderGoals from '../../components/Common/RenderGoals';
import SubRenderProjects from '../../components/Common/RenderProjects';

// const courseIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png";
// const wfBackgroundImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/workforce_background_image.png"

class MentorBenefits extends Component {
    constructor(props) {
      super(props)
      this.state = {
        gradeOptions: ['','A+','A','A-','B+','B','B-','C+','C','C-','D+','D','D-','F'],
        caseStudies: [],
        panelSelected: 1
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEmployerBenefits', this.props, prevProps)

      if (this.props.orgSelected !== prevProps.orgSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEmployerBenefits')

      // public landing pages: goals, projects, profiles

      const helpTactics = [
        'Resume Reviews','Mock Interviews','Guest Speaking','Job Shadows','Answer Questions',
        'Goal Feedback','Project Feedback','Endorse Skills','Refer Career-Seekers'
      ]

      const orgSelected = this.props.orgSelected
      let orgCode = null
      if (orgSelected) {
        orgCode = orgSelected.orgCode
      }
      this.setState({ helpTactics, orgSelected, orgCode })

      if (orgSelected) {
        const orgCode = orgSelected.orgCode
        const placementPartners = orgSelected.placementPartners

        Axios.get('/api/postings/user', { params: { orgCode, placementPartners,
          recent: true, active: true, pullPartnerPosts: true, csWorkflow: true,
          targetRole: 'Volunteers', resLimit: 3
        }})
        .then((response) => {
          console.log('Posted postings query attempted within subcomponent', response.data);

          if (response.data.success) {
            console.log('posted postings query worked')

            const opportunities = response.data.postings
            this.setState({ opportunities })

          } else {
            console.log('posted postings query did not work', response.data.message)
            this.setState({ opportunities: [] })
          }

        }).catch((error) => {
            console.log('Posted postings query did not work for some reason', error);
        });

        let paramsObject = { orgCode, resLimit: 3 }
        paramsObject['publicExtentArray'] = 'Public'

        Axios.get('/api/logs/goals', { params: paramsObject })
       .then((response) => {
         console.log('Goals query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved goals')

           const goals = response.data.goals
           this.setState({ goals })

         } else {
           console.log('no goals data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Goals query did not work', error);
       });

       Axios.get('/api/org/members', { params: { orgCode, roleNames: ['Student','Career-Seeker'], publicExtentArray: ['Public'], onlyPics: true, limit: 3 } })
       .then((response) => {
         console.log('Members query attempted', response.data);

           if (response.data.success) {
             console.log('members query worked')

             let profiles = response.data.members
             this.setState({ profiles })

           } else {
             console.log('members query did not work', response.data.message)
           }

       }).catch((error) => {
           console.log('Members query did not work for some reason', error);
       });

       Axios.get('/api/projects', { params: { orgCode, publicExtentArray: ['Public'], resLimit: 3 } })
       .then((response) => {
         console.log('Projects query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved projects')

             if (response.data.projects) {

               const projects = response.data.projects
               this.setState({ projects })
             }

           } else {
             console.log('no project data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Project query did not work', error);
       });
      }
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false })
    }

    render() {

      // let panel1ClassName = "white-background standard-border padding-20 center-text full-width"
      // let panel2ClassName = "white-background standard-border padding-20 center-text full-width"
      // let panel3ClassName = "white-background standard-border padding-20 center-text full-width"
      // let panel4ClassName = "white-background standard-border padding-20 center-text full-width"
      //
      // if (window.innerWidth > 768) {
      //   if (this.state.panelSelected === 1) {
      //     panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 2) {
      //     panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 3) {
      //     panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 4) {
      //     panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
      //   }
      // } else {
      //   // redefine for mobile
      //
      //   panel1ClassName = "white-background standard-border padding-20 center-text full-width"
      //   panel2ClassName = "white-background standard-border padding-20 center-text full-width"
      //   panel3ClassName = "white-background standard-border padding-20 center-text full-width"
      //   panel4ClassName = "white-background standard-border padding-20 center-text full-width"
      //   if (this.state.panelSelected === 1) {
      //     panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 2) {
      //     panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 3) {
      //     panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
      //   }
      //   if (this.state.panelSelected === 4) {
      //     panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
      //   }
      // }

      // console.log('panel1 points: ', this.state.panel1Points)

      return (
        <div>
          <section className="section-features white-background horizontal-padding-3">
            <div className="row">
              <h2>How You Can Help</h2>
              <p className="full-width center-text">Scale your impact with Guided Compass</p>

              {(this.state.helpTactics) && (
                <div className="top-margin-30">
                  {this.state.helpTactics.map((item, optionIndex) =>
                    <div className="top-margin">
                      <div className="relative-column-33 description-text-color">
                        <p className="full-width center-text">{item}</p>
                      </div>
                      {(optionIndex % 3 === 2) && (
                        <div className="clear" />
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>
          </section>

          <section className="section-workforce">
            <div className="container-left">
              <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>Not sure where to start?</p>
            </div>
            <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
              <Link to={"/help"} state={{ selectedFilters: ["Employer"] }} className="btn white-background heading-text-5">View Help Articles</Link>
            </div>
            <div className="clear" />
          </section>

          <section className="section-features">
            <div className="row">
              <h2>Recently Posted Volunteer Opportunities</h2>

              {(this.state.opportunities && this.state.opportunities.length > 0) ? (
                <div>
                  <SubRenderOpportunities
                    oppType="all" postings={this.state.opportunities} history={this.props.navigate}
                    activeOrg={this.state.orgCode} pageSource="landingPage"
                  />

                  <div className="clear" />

                  {(this.state.orgSelected) && (
                    <div className="top-margin-20 center-text">
                      <Link className="btn btn-primary" to={"/opportunities/organizations/" + this.state.orgSelected.orgCode + "/roles/volunteers"}>See All Opportunities</Link>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="full-width center-text">There aren't any recent, active opportunities.</p>
                </div>
              )}

            </div>
          </section>

          <div className="row">
            <hr className="cta-border" />
          </div>

          <section className="section-features">
            <div className="row">
              <h2>Latest Public Profiles</h2>

              {(this.state.profiles && this.state.profiles.length > 0) ? (
                <div>
                  <SubRenderProfiles
                    favorites={[]} members={this.state.profiles} friends={[]}
                    pageSource="landingPage" history={this.props.navigate} userType="Peers"
                  />
                  <div className="clear" />

                  {(this.state.orgSelected) && (
                    <div className="top-margin-20 center-text">
                      <Link className="btn btn-primary" to={"/profiles/organizations/" + this.state.orgSelected.orgCode}>See All Profiles</Link>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="full-width center-text">There aren't currently any public profiles.</p>
                </div>
              )}

            </div>
          </section>

          <div className="row">
            <hr className="cta-border" />
          </div>

          <section className="section-features">
            <div className="row">
              <h2>Latest Public Goals</h2>

              {(this.state.goals && this.state.goals.length > 0) ? (
                <div>
                  <SubRenderGoals history={this.props.navigate} filteredGoals={this.state.goals}
                    profileData={this.state.profileData} activeOrg={this.state.orgCode}
                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteria}
                    pageSource="landingPage"
                  />

                  <div className="clear" />

                  {(this.state.orgSelected) && (
                    <div className="top-margin-20 center-text">
                      <Link className="btn btn-primary" to={"/goals/organizations/" + this.state.orgSelected.orgCode}>See All Goals</Link>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="full-width center-text">There aren't any active, public goals.</p>
                </div>
              )}
            </div>
          </section>

          <div className="row">
            <hr className="cta-border" />
          </div>

          <section className="section-features">
            <div className="row">
              <h2>Latest Public Projects</h2>

              {(this.state.projects && this.state.projects.length > 0) ? (
                <div>
                  <SubRenderProjects
                    history={this.props.navigate} favorites={this.state.favorites}
                    projects={this.state.projects}
                    filterCriteriaArray={this.state.filterCriteriaArray}
                    sortCriteriaArray={this.state.sortCriteriaArray}
                    jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                    accountCode={this.state.accountCode}
                    gradeOptions={this.state.gradeOptions} orgContactEmail={this.state.orgContactEmail}
                    org={this.state.orgCode} pageSource="landingPage"
                  />

                  <div className="clear" />

                  {(this.state.orgSelected) && (
                    <div className="top-margin-20 center-text">
                      <Link className="btn btn-primary" to={"/projects/organizations/" + this.state.orgSelected.orgCode}>See All Projects</Link>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="full-width center-text">There aren't any active, public projects.</p>
                </div>
              )}

            </div>
          </section>
        </div>
      )
    }
}

export default MentorBenefits
