import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import {convertDateToString} from './Functions/ConvertDateToString';
import withRouter from './Functions/WithRouter';

const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const difficultyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/difficulty-icon-blue.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const ratingsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/ratings-icon-blue.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';

class MyCourses extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.deleteItem = this.deleteItem.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, cuFirstName, cuLastName, username,
        activeOrg, orgFocus, orgLogo });

      Axios.get('/api/courses/enrollments', { params: { emailId: email } })
     .then((response) => {
       console.log('Enrollments query attempted', response.data);

       if (response.data.success) {
         console.log('successfully retrieved enrollments')

         const enrollments = response.data.enrollments
         this.setState({ enrollments })

       } else {
         console.log('no enrollments data found', response.data.message)
       }

     }).catch((error) => {
         console.log('Enrollments query did not work', error);
     });
    }

    deleteItem(item, index) {
      console.log('deleteItem called', item, index)

      const _id = item._id

      Axios.delete('/api/courses/enrollments/' + _id)
      .then((response) => {
        console.log('tried to delete enrollment', response.data)
        if (response.data.success) {
          //save values
          console.log('Enrollment delete worked');

          let enrollments = this.state.enrollments
          enrollments.splice(index,1)
          this.setState({ enrollments, successMessage: response.data.message })

        } else {
          console.error('there was an error deleting the posting');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error.toString() })
      });
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <div className={"standard-container-2"}>
              <div>
                <div className="row-10">
                  <div className="calc-column-offset-25">
                    <p className="heading-text-2">My Training Programs & Courses</p>
                  </div>
                  <div className="fixed-column-25 top-margin">
                    <Link className="background-button" to="/app/courses">
                      <img src={addIconBlue} className="image-auto-25 pin-right" alt="GC" />
                    </Link>
                  </div>
                  <div className="clear" />

                  <div className="half-spacer" />

                  <p className="description-text-color">Training programs and courses that you're enrolled in or have completed.</p>
                </div>

                <div className="spacer" /><div className="spacer" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                {(this.state.enrollments) && (
                  <div>
                    {this.state.enrollments.map((value, optionIndex) =>
                      <div key={optionIndex} className="top-margin bottom-margin">
                        <Link className="background-button calc-column-offset-60" to={"/app/courses/" + value.courseId}>
                          <div className="fixed-column-70">
                            <img src={(value.courseImageURL) ? value.courseImageURL : courseIconDark} alt="GC" className="image-60-auto"/>

                            {(value.createdAt) && (
                              <div className="top-margin horizontal-padding-4">
                                <p className="description-text-3 full-width center-text standard-color">{convertDateToString(value.createdAt,'daysAgo')}</p>
                              </div>
                            )}

                          </div>
                          <div className="calc-column-offset-70">
                            <p className="heading-text-5">{value.courseName}</p>
                            {(value.courseSchoolName) && (
                              <p className="description-text-3 bottom-margin top-margin-5 standard-color">by {value.courseSchoolName}</p>
                            )}

                            {(value.courseDescription) && (
                              <p className="description-text-1 description-text-color top-margin-15">{value.courseDescription}</p>
                            )}

                            <div className="top-margin-15">
                              {(value.courseCategory) && (
                                <div className="description-text-3">
                                  <div className="float-left right-margin">
                                    <img src={courseIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                  </div>
                                  <div className="float-left right-margin">
                                    <label className="standard-color">{value.courseCategory}</label>
                                  </div>
                                </div>
                              )}

                              {(value.courseEstimatedHours) && (
                                <div className="description-text-3">
                                  <div className="float-left right-margin">
                                    <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                  </div>
                                  <div className="float-left right-margin">
                                    <label className="standard-color">{value.courseEstimatedHours} Hours</label>
                                  </div>
                                </div>
                              )}

                              {(value.courseDegreeType) && (
                                <div className="description-text-3">
                                  <div className="float-left right-margin">
                                    <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                  </div>
                                  <div className="float-left right-margin">
                                    <label className="standard-color">{value.courseDegreeType}</label>
                                  </div>
                                </div>
                              )}

                              {(value.courseProgramMethod) && (
                                <div className="description-text-3">
                                  <div className="float-left right-margin">
                                    <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                  </div>
                                  <div className="float-left right-margin">
                                    <label className="standard-color">{value.courseProgramMethod}</label>
                                  </div>
                                </div>
                              )}

                              {(value.courseDifficultyLevel) && (
                                <div className="description-text-3">
                                  <div className="float-left right-margin">
                                    <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                  </div>
                                  <div className="float-left right-margin">
                                    <label className="standard-color">{value.courseDifficultyLevel ? value.courseDifficultyLevel : "Beginner"}</label>
                                  </div>
                                </div>
                              )}
                              <div className="clear" />
                            </div>

                            {(value.isCompleted) && (
                              <div className="top-margin-15">
                                <div className="fixed-column-30">
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-18" />
                                </div>
                                <div className="calc-column-offset-30">
                                  <p className="description-text-1 description-text-color bottom-margin-15">{(value.courseCategory) && value.courseCategory + " "}Completed</p>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            <div className="half-spacer" />
                            {/*
                            <div className="tag-container-7 top-margin-5">
                              <p className="description-text-3">{value.category}</p>
                              <p className="description-text-3">{value.price}</p>
                            </div>*/}
                          </div>
                        </Link>

                        <div className="fixed-column-30 right-text">
                          <button disabled={this.state.isSaving} className="btn background-button" onClick={() => this.deleteItem(value,optionIndex) }>
                            <img src={deleteIconDark} alt="GC" className="image-auto-20 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete Program/Course"/>
                          </button>
                        </div>
                        <div className="fixed-column-30 right-text">
                          <Link to={'/app/courses/' + value.courseId} state={{ selectedCourse: value }} className="background-button full-width">
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                          </Link>
                        </div>

                        <div className="clear"/>

                        <div className="row-20">
                          <hr />
                        </div>

                      </div>
                    )}
                  </div>
                )}

                <div className="clear"/>
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(MyCourses)
