import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSubscribe from './Subcomponents/Subscribe';
import SubNewsFeed from './Subcomponents/NewsFeed';
import SubPeopleMatching from './Subcomponents/PeopleMatching';
import SubGroups from './Subcomponents/Groups';
import withRouter from './Functions/WithRouter';

class Social extends Component {
    constructor(props) {
      super(props)

      this.state = {
        enableSubscriptions: false,
        subscribed: false,

        subNavCategories: ['Feed','Groups','Peers','Mentors'],
        subNavSelected: 'Feed',
        showFeed: false
      }

      this.subNavClicked = this.subNavClicked.bind(this)
      this.handleChange = this.handleChange.bind(this)

      this.calculateMentorMatches = this.calculateMentorMatches.bind(this)

      this.loadWorkspace = this.loadWorkspace.bind(this)
      this.renderFunctionality = this.renderFunctionality.bind(this)
      this.passSubscriptionData = this.passSubscriptionData.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const socialNavSelected = localStorage.getItem('socialNavSelected');
      const orgLogo = localStorage.getItem('orgLogo');

      let subNavCategories = ['Feed','Groups','Peers','Mentors']
      let subNavSelected = 'Feed'
      if (!this.state.showFeed) {
        subNavCategories = ['Groups','Peers','Mentors']
        subNavSelected = 'Groups'
      }

      if (socialNavSelected) {
        subNavSelected = socialNavSelected
      }

      let calculateMatches = false
      if (this.props.location && this.props.location.state) {
        if (this.props.location.state.subNavSelected) {
          subNavSelected = this.props.location.state.subNavSelected
        }

        if (this.props.location.state.calculateMatches) {
          calculateMatches = this.props.location.state.calculateMatches
        }
      } else if (socialNavSelected) {
        subNavSelected = socialNavSelected
      }

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, orgLogo,
         subNavSelected, calculateMatches, subNavCategories })

      const isActive = true
      const includeMembership = false

      Axios.get('/api/payments/subscription', { params: {
        email: emailId, orgCode: activeOrg, accountCode: null, subscriptionType: 'Individual', isActive, includeMembership
      } })
      .then((response) => {
        console.log('Subscription query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved subscription')

           this.setState({ subscribed: true })

         } else {
           console.log('no org data found', response.data.message)

         }

      }).catch((error) => {
         console.log('Subscription query did not work', error);

      });
    }

    handleChange(event) {
      console.log('handleChange called')

      if (event.target.name === 'matchCriteria') {

        const matchCriteria = event.target.value
        this.setState({ matchCriteria })

        this.calculateMentorMatches(this.state.emailId, this.state.scholars, this.state.interestsData, this.state.skillsAnswers, this.state.personalityData, matchCriteria, null )
      } else if (event.target.name === 'sortDirection') {
        const sortDirection = event.target.value
        this.setState({ sortDirection })

        this.calculateMentorMatches(this.state.emailId, this.state.scholars, this.state.interestsData, this.state.skillsAnswers, this.state.personalityData, null, sortDirection )
      }
    }

    calculateMentorMatches(emailId, mentors, wpData, interestsResults, skillsResults, personalityResults, passedMatchCriteria, passedSortDirection) {
      console.log('calculateMentorMatches called', emailId, mentors, wpData, interestsResults, skillsResults, personalityResults)

      let matchCriteria = this.state.matchCriteria
      if (passedMatchCriteria) {
        matchCriteria = passedMatchCriteria
      }

      let sortDirection = this.state.sortDirection
      if (passedSortDirection) {
        sortDirection = passedSortDirection
      }

      // console.log('show interest and personality results ', wpData, interestsResults, personalityResults)

      Axios.post('/api/assessment/partners/calculate', { emailId, partners: mentors, wpData, interestsResults, skillsResults, personalityResults, matchCriteria, sortDirection })
      .then((response) => {
        console.log('Mentor match query worked', response.data);

        if (response.data.success) {

          console.log('successfully matched to mentors')
          this.setState({ mentorMatches: response.data.matches })

        } else {
          console.log('there was an error matching partners', response.data.message)
        }

      }).catch((error) => {
          console.log('Partner match did not work', error);
      });
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      this.setState({ subNavSelected: itemSelected })
      localStorage.setItem('socialNavSelected', itemSelected)

    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    renderFunctionality() {
      console.log('renderFunctionality called', this.state.emailId)

      return (
        <div key="renderFunctionality">
          <div className="standard-container-2 clear-padding">
            <div className="full-width white-background">
              <div className="clear-float">
                <div className="carousel-3" onScroll={this.handleScroll}>
                  {this.state.subNavCategories.map((value, index) =>
                    <div className="display-inline">
                      {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                        <div className="selected-carousel-item-2">
                          <label key={value}>{value}</label>
                        </div>
                      ) : (
                        <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                          <label key={value}>{value}</label>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>

          {(this.state.subNavSelected === 'Feed') && (
            <div>
              <div className="standard-container-2 clear-padding clear-background clear-shadow">
                <SubNewsFeed history={this.props.navigate} calculateMatches={this.state.calculateMatches} subNavClicked={this.subNavClicked} />
              </div>
            </div>
          )}
          {(this.state.subNavSelected === 'Peers') && (
            <div>
              <div className="standard-container-2 clear-padding clear-background clear-shadow">
                <SubPeopleMatching history={this.props.navigate} userType={this.state.subNavSelected} />
              </div>
            </div>
          )}
          {(this.state.subNavSelected === 'Mentors') && (
            <div className="standard-container-2 clear-padding clear-background clear-shadow">
              <SubPeopleMatching history={this.props.navigate}  userType={this.state.subNavSelected}/>
            </div>
          )}
          {(this.state.subNavSelected === 'Groups') && (
            <div className="standard-container-2 clear-padding clear-background clear-shadow">
              <SubGroups history={this.props.navigate}/>
            </div>
          )}
        </div>
      )
    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in exploration', subscription)

      this.setState({ subscribed: true })

    }

    render() {

        return (
            <div>
                <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />

                {((this.state.activeOrg === 'guidedcompass') && !this.state.subscribed && this.state.enableSubscriptions) ? (
                  <div>
                    <div className="standard-container-2">
                      <SubSubscribe history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} />

                    </div>
                  </div>
                ) : (
                  <div>
                    {this.renderFunctionality()}
                  </div>
                )}

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>
        )
    }
}

export default withRouter(Social)
