import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubOpportunities from '../components/Subcomponents/Opportunities';

const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";

class ProblemsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
        //see if user is logged in
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        const problemTypeOptions = ['','Exploratory','Technical']
        const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Hard','Challenger']
        const popularityOptions = ['','More than 10','More than 50']
        const postDateOptions = ['','Within the Last Month','Within the Last Year']

        let emailId = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');

        let noOrgCode = null
        if (!activeOrg || activeOrg === '') {
          noOrgCode = true
        }

        this.setState({ problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions,
          emailId, activeOrg, orgFocus, roleName, noOrgCode
        })

    }

    render() {

      return (
          <div>

              <TopNavigation currentPage={"problemsPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

              <section className="section-features">
                  <div className="row">
                    <div className="calc-column-offset-140-static">
                      <h3>Problems</h3>
                      <p>Case studies sourced from employer representatives.</p>
                    </div>
                    <div className="fixed-column-140-static top-margin">
                      <Link to={'/problem-platform/add'}>
                        <div className="bordered-button-container">
                          <div className="image-adjacent-text-1">
                            <img src={addIconBlue} alt="Add Icon logo" className="image-20px-auto" />
                          </div>
                          <div className="float-left left-padding">
                            <p className="heading-text-5 center-text">New</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </Link>
                    </div>
                    <div className="clear" />

                  </div>

                  <SubOpportunities passedViewIndex={5} passedSubNavSelected="Projects" passedType="Problem" history={this.props.navigate} noOrgCode={this.state.noOrgCode}/>
              </section>

              <section className="section-cta">
                  <div className="cta-container">
                      <div className="cta-text-box">
                          <p className="heading-text-2">Want to post a</p>
                          <p className="heading-text-1">Problem?</p>
                      </div>

                      <div className="cta-buttons">
                        <div className="vertically-centered-container">
                          <Link className="btn btn-full" to={'/problem-platform/add'} state={{ reason: "Assignment" }}>Post</Link>
                        </div>
                      </div>
                  </div>
              </section>

              <Footer history={this.props.navigate} />
          </div>
      )
    }
}

export default ProblemsPage;
