import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import EditPosting from './Subcomponents/EditPosting';
import withRouter from './Functions/WithRouter';

class OrgAddPosting extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let eventTypeOptions = ["","Talk","Workshop","Field Trip","Job Shadow","Project Showcase","Networking / Career Fair","Conference","Other"]
        if (orgFocus === 'Mentor' || roleName === 'Mentor') {
          eventTypeOptions = ["","Talk","Workshop","Field Trip","Job Shadow","Other"]
        }

        const path = window.location.pathname

        this.setState({ emailId: email, username, cuFirstName, cuLastName,
          activeOrg, orgFocus, roleName, orgLogo,
          eventTypeOptions, path });

        if (this.props.location && this.props.location.state) {

          const { postings } = this.props.location.state;
          this.setState({ postings })


        } else {

        }
      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} fromAdvisor={true} history={this.props.navigate} />
          <div>
            <EditPosting org={this.state.activeOrg} selectedOpportunity={this.state.selectedOpportunity} editMode={this.props.editMode} jobs={this.state.jobs} roleName={this.state.roleName} path={this.state.path} />
          </div>
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
  }
}

export default withRouter(OrgAddPosting);

/*
1) Assessment saved for event is not returning id
*/
