import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import SubTimesheet from './Timesheet';
import SubCustomDropdown from '../Common/CustomDropdown';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Timesheets extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subtimesheets', this.props, prevProps)

      if (this.props.orgCode !== prevProps.orgCode || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subtimesheets')

      let org = this.props.orgCode
      const accountCode = this.props.accountCode

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('cuFirstName');
      let cuLastName = localStorage.getItem('cuLastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      if (!org) {
        org = localStorage.getItem('activeOrg');
      }

      if (org || email) {
        // filter: wblc, pathway, worker name, employer name, manager name, date range

        const statusOptions = ['','Not Submitted','Is Submitted','Approved','Rejected']

        this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, accountCode, orgFocus, statusOptions })

        if (org) {
          Axios.get('/api/timesheets', { params: { orgCode: org } })
          .then((response) => {
            console.log('Timesheets query attempted within employer dashboard', response.data);

            if (response.data.success) {
              console.log('Timesheets query worked in sub settings')

              let timesheets = response.data.timesheets
              if (timesheets && timesheets.length > 0) {
                for (let i = 1; i <= timesheets.length; i++) {
                  if (timesheets[i - 1].isSubmitted) {
                    if (timesheets[i - 1].isApproved) {
                      timesheets[i - 1]['status'] = 'Approved'
                    } else if (timesheets[i - 1].isRejected){
                      timesheets[i - 1]['status'] = 'Rejected'
                    } else {
                      // undecided
                      timesheets[i - 1]['status'] = 'Submitted, Not Approved'
                      timesheets[i - 1]['assignee'] = timesheets[i - 1].managerFirstName + ' ' + timesheets[i - 1].managerLastName
                      timesheets[i - 1]['assigneeType'] = 'Manager'
                    }
                  } else {
                    timesheets[i - 1]['status'] = 'Not Submitted'
                    timesheets[i - 1]['assignee'] = timesheets[i - 1].workerFirstName + ' ' + timesheets[i - 1].workerLastName
                    timesheets[i - 1]['assigneeType'] = 'Worker'
                  }
                }

                this.setState({ timesheets });
              }
            }

          }).catch((error) => {
            console.log('Timesheets query did not work for some reason', error);
          });

          Axios.get('/api/pathways', { params: { orgCode: org } })
          .then((response) => {
            console.log('Pathways query attempted two oh', response.data);

            if (response.data.success && response.data.pathways && response.data.pathways.length > 0) {
              console.log('pathways query worked')

              let pathwayOptions = [''].concat(response.data.pathways)
              this.setState({ pathwayOptions});

            } else {
              console.log('org info query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, null)
      } else if (event.target.name === 'afterDate') {
        this.setState({ afterDate: event.target.value, animating: true })
        this.filterResults(this.state.searchString, { name: event.target.name, value: event.target.value })
      } else if (event.target.name === 'beforeDate') {
        this.setState({ beforeDate: event.target.value, animating: true })
        this.filterResults(this.state.searchString, { name: event.target.name, value: event.target.value })
      } else if (event.target.name === 'pathway') {
        this.setState({ pathway: event.target.value, animating: true })
        this.filterResults(this.state.searchString, { name: event.target.name, value: event.target.value })
      } else if (event.target.name === 'status') {
        this.setState({ status: event.target.value, animating: true })
        this.filterResults(this.state.searchString, { name: event.target.name, value: event.target.value })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, passedFilterObject) {
      console.log('filterResults called', searchString, passedFilterObject)

      const orgCode = this.state.org
      let filterObject = { orgCode }

      // const emailId = this.state.emailId

      if (searchString && searchString !== '') {
        filterObject['searchString'] = searchString
      }

      if (this.state.beforeDate && this.state.beforeDate !== '') {
        filterObject['beforeDate'] = this.state.beforeDate
      }

      if (this.state.afterDate && this.state.afterDate !== '') {
        filterObject['afterDate'] = this.state.afterDate
      }

      if (this.state.pathway && this.state.pathway !== '') {
        filterObject['pathway'] = this.state.pathway
      }

      if (this.state.status && this.state.status !== '') {
        filterObject['status'] = this.state.status
      }

      if (passedFilterObject) {
        filterObject[passedFilterObject.name] = passedFilterObject.value
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called', filterObject)

        Axios.put('/api/timesheets/filter', filterObject)
        .then((response) => {
          console.log('Timesheet filter query attempted', response.data);

            if (response.data.success) {
              console.log('timesheet filter query worked')

              let timesheets = response.data.timesheets
              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              if (timesheets && timesheets.length > 0) {
                for (let i = 1; i <= timesheets.length; i++) {
                  if (timesheets[i - 1].isSubmitted) {
                    if (timesheets[i - 1].isApproved) {
                      timesheets[i - 1]['status'] = 'Approved'
                    } else if (timesheets[i - 1].isRejected){
                      timesheets[i - 1]['status'] = 'Rejected'
                    } else {
                      // undecided
                      timesheets[i - 1]['status'] = 'Submitted, Not Approved'
                      timesheets[i - 1]['assignee'] = timesheets[i - 1].managerFirstName + ' ' + timesheets[i - 1].managerLastName
                    }
                  } else {
                    timesheets[i - 1]['status'] = 'Not Submitted'
                    timesheets[i - 1]['assignee'] = timesheets[i - 1].workerFirstName + ' ' + timesheets[i - 1].workerLastName
                  }
                }
              }

              self.setState({ timesheets, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('timesheet filter query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Timesheet filter query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, editTimesheet: false, selectedTimesheet: null, selectedIndex: null })
    }

    passStatus = (timesheet,action,deleteTimesheet) => {
      console.log('passStatus called', timesheet, action, deleteTimesheet)
      console.log('show cards: ', timesheet.cards)

      let timesheets = this.state.timesheets
      if (deleteTimesheet) {
        timesheets.splice(this.state.selectedIndex,1)
        this.setState({ timesheets })
      } else {
        if (action) {
          timesheets[this.state.selectedIndex]['isSubmitted'] = timesheet.isSubmitted
          timesheets[this.state.selectedIndex]['isApproved'] = timesheet.isApproved
          timesheets[this.state.selectedIndex]['isRejected'] = timesheet.isRejected
          this.setState({ timesheets })
        } else {
          console.log('show values: ', timesheets, this.state.selectedIndex)
          if (timesheets && this.state.selectedIndex && this.state.selectedIndex !== 0) {
            timesheets[this.state.selectedIndex] = timesheet
          } else if (timesheets) {
            timesheets.push(timesheet)
          } else {
            timesheets = [timesheet]
          }
          this.setState({ timesheets })
        }
      }
    }

    render() {

      let passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let passedClassName2 = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
      // const addIconWidth = '45px'
      let passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
      let passedPlaceholder = 'Search timesheets by worker, manager, title, or employer...'
      // const pathname = '/advisor/projects/add'
      let filterClass = "floating-container card top-margin-2-percent right-margin-2-percent"
      if (this.props.noSideNav || (toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations'))) {
        passedClassName = "card width-70-percent max-width-1400 top-margin-2-percent center-horizontally"
        passedClassName2 = "card width-70-percent max-width-1400 top-margin-2-percent center-horizontally"
        passedStyle = {}
        passedStyle2 = {}
        filterClass = "card width-70-percent max-width-1400 top-margin-2-percent center-horizontally"
      }

      return (
          <div>

              <div>
                <div className={passedClassName} style={passedStyle}>
                  <div>
                    <div className="calc-column-offset-65">
                      <div className="spacer" />
                      <p className="heading-text-2">Timesheets</p>
                    </div>
                    <div className="fixed-column-40">
                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <div className="row-5">
                        <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                          <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="GC" className="image-25-fit"/>
                        </button>
                      </div>
                    </div>
                    <div className="fixed-column-25">
                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <div className="row-5">
                        <button onClick={() => this.setState({ modalIsOpen: true, editTimesheet: true, selectedIndex: null })} className="background-button">
                          <img src={addIcon} alt="GC" className="image-25-fit"/>
                        </button>
                      </div>
                    </div>

                    <div className="clear" />
                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.showingSearchBar) && (
                  <div className={filterClass} style={passedStyle2}>
                    <div>
                      <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width">
                        <div className="half-spacer"/>
                        <div className="float-left top-padding-8 left-padding-3">
                          <div className="mini-spacer"/>
                          <img src={searchIcon} alt="GC" className="image-auto-18"/>
                        </div>
                        <div className="calc-column-offset-23">
                          <input type="text" className="text-field clear-border" placeholder={passedPlaceholder} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                      </div>
                      <div className="clear" />
                      <div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />


                      <p className="heading-text-4">Filter</p>
                      <div className="half-spacer" />

                      <div className="light-border float-left top-padding-4 right-padding-5 top-margin-15 right-margin height-46">
                        <div className="display-inline float-left top-padding-8 right-padding-5 left-padding nowrap">
                          <p className="description-text-color">Started After</p>
                        </div>
                        <div className="float-left curtail-text">
                          <input type="date" className="date-picker white-background clear-border" name="afterDate" value={this.state.afterDate} onChange={this.formChangeHandler}></input>
                        </div>
                      </div>

                      <div className="light-border float-left top-padding-4 right-padding-5 top-margin-15 right-margin height-46">
                        <div className="display-inline float-left top-padding-8 right-padding-5 left-padding nowrap">
                          <p className="description-text-color">Started Before</p>
                        </div>
                        <div className="float-left curtail-text">
                          <input type="date" className="date-picker white-background clear-border" name="beforeDate" value={this.state.beforeDate} onChange={this.formChangeHandler}></input>
                        </div>
                      </div>

                      <SubCustomDropdown dropdownTitle="Pathway" name="pathway" value={this.state.pathway} options={this.state.pathwayOptions} optionSubKey="title" optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                      <SubCustomDropdown dropdownTitle="Status" name="status" value={this.state.status} options={this.state.statusOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>

                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.animating) ? (
                  <div className={passedClassName2} style={passedStyle}>
                    <div>
                      <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Loading results...</p>
                    </div>
                  </div>
                ) : (
                  <div className={passedClassName2} style={passedStyle}>
                    {(this.state.timesheets && this.state.timesheets.length > 0) ? (
                      <div>
                        {this.state.timesheets.map((ts, tsIndex) =>
                          <div key={ts}>
                            {console.log('timesheet de: ', tsIndex, ts.workerFirstName)}
                            <div className={(window.innerWidth > 768) ? "relative-column-33 top-margin-30" : "top-margin-30"}>
                              <a className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, editTimesheet: true, selectedTimesheet: ts, selectedIndex: tsIndex })}>
                                {(ts.startDate && ts.endDate) && (
                                  <div className="horizontal-padding-15">
                                    <div className="fixed-column-40">
                                      <p>{tsIndex + 1}.</p>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p className="cta-color half-bold-text">{ts.workerFirstName} {ts.workerLastName}</p>

                                      <div className="description-text-2">
                                        <p className="right-padding top-padding-5">{ts.employerName}</p>
                                        <p className="right-padding top-padding-5">{ts.startDate.substring(5,10).replace(/-/g,"/") + '/' + ts.startDate.substring(0,4).replace(/-/g,"/")} - {ts.isPerpetual ? "Continual" : ts.endDate.substring(5,10).replace(/-/g,"/") + '/' + ts.endDate.substring(0,4).replace(/-/g,"/")}</p>

                                        <div className="clear" />

                                        <p className="right-padding top-padding-5 error-color">Status: {ts.status}</p>
                                        {(ts.assignee) && (
                                          <p className="right-padding top-padding-5">@{ts.assignee} ({ts.assigneeType})</p>
                                        )}

                                        {/*
                                        {(ts.isApproved === true) ? (
                                          <p className="success-message right-padding">Approved</p>
                                        ) : (
                                          <p className="error-message right-padding">Not Approved</p>
                                        )}*/}

                                        <div className="clear" />
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </a>
                            </div>
                            {(window.innerWidth > 768 && tsIndex % 3 === 2) && (
                              <div className="clear" />
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="description-text-1 row-30">
                        <p className="error-message">No outstanding timesheets have been submitted for approval.</p>
                      </div>
                    )}
                  </div>
                )}

              </div>

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
              {(this.state.editTimesheet) && (
                <div className="full-width padding-30">
                  <SubTimesheet selectedTimesheet={this.state.selectedTimesheet} roleName={(this.state.selectedTimesheet && this.state.selectedTimesheet.isSubmitted) ? "Employer" : "Worker"} passStatus={this.passStatus} closeModal={this.closeModal} history={this.props.navigate}/>
                </div>
              )}
             </Modal>

          </div>
      )
    }
}


export default withRouter(Timesheets);
