import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const storyIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/input-icon-big.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const notificationsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notifications-icon-blue.png';

class Notifications extends Component {
    constructor(props) {
      super(props)

      this.state = {
        notifications: []
      }

      this.renderNotifications = this.renderNotifications.bind(this);
      this.clearUnreadNotifications = this.clearUnreadNotifications.bind(this);
    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within subNotifications', this.props)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg })

        Axios.get('/api/notifications', { params: { emailId: email } })
        .then((response) => {
            // console.log('notiquery tried ', response.data)
            if (response.data.success)  {
              console.log('Notification query worked', response.data);

              if (window.location.pathname.includes('/organizations') && response.data.notifications) {

                let notifications = []
                for (let i = 1; i <= response.data.notifications.length; i++) {

                  // if (response.data.notifications[i - 1].type.includes('Requested') || response.data.notifications[i - 1].type.includes('Status Updated')) {
                  //   notifications.push(response.data.notifications[i - 1])
                  // }
                  notifications.push(response.data.notifications[i - 1])
                }

                this.setState({ notifications })
              } else {

                this.setState({ notifications: response.data.notifications })
              }

              this.clearUnreadNotifications(response.data.notifications)

            } else {
              console.log('no notifications found', response.data.message)
            }

        }).catch((error) => {
            console.log('Notifications query did not work', error);
        });

        if (window.innerWidth > 768) {

        } else {

            this.setState({ onMobile: true })

        }
      }
    }

    clearUnreadNotifications(notifications) {
      console.log('clearUnreadNotifications called')
      let unreadNotificationIds = [];

      for (let i = 1; i <= notifications.length; i++) {
        console.log(i);

        if (notifications[i - 1].isRead === false) {
          unreadNotificationIds.push(notifications[i - 1]._id)
        }
      }

      for (let i = 1; i <= unreadNotificationIds.length; i++) {
        console.log(i);

        Axios.put('/api/notifications/read', {
          notificationIds: unreadNotificationIds })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Notification update worked', response.data);

            localStorage.setItem('unreadNotificationsCount', 0)

          } else {
            console.log('there was an error updating the notifications', response.data.message);
          }
        }).catch((error) => {
            console.log('Notification update did not work', error);
        });
      }
    }

    renderNotifications() {
      console.log('renderNotifications called')

      let rows = [];

      for (let i = 1; i <= this.state.notifications.length; i++) {
        console.log(i);

        const index = i - 1

        let message = ''
        let notiPath = '/app/logs'

        let objectId = ''
        let additionalItem = ''

        let notiDate = ''
        if (this.state.notifications[i - 1].createdAt) {
          notiDate = this.state.notifications[i - 1].createdAt.substring(0,10)
        }

        let mainClass = "calc-column-offset-170"
        let arrowClass = "fixed-column-30"
        if (this.state.onMobile) {
          mainClass = "calc-column-offset-65"
          arrowClass = "fixed-column-22"
        }

        let senderName = this.state.notifications[i - 1].senderFirstName + ' ' + this.state.notifications[i - 1].senderLastName
        if (this.state.notifications[i - 1].orgName) {
          senderName = this.state.notifications[i - 1].orgName
        } else if (!this.state.notifications[i - 1].senderFirstName) {
          senderName = 'Error Getting Name'
        }

        if (window.location.pathname.includes('/app')) {

          if (this.state.notifications[i - 1].type === 'Advisor Request') {

            if ( this.state.notifications[i - 1].isDecided === true ) {

              if (this.state.notifications[i - 1].isApproved === true ) {
                message = 'career advisor request was approved'
              } else {
                message = 'career advisor request was denied'
              }
            } else {
              message = 'requests to be your career advisor'
            }
          } else {

            message = this.state.notifications[i - 1].message

            if (this.state.notifications[index].type === 'Track Approval') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/app/opportunities/' + objectId
            } else if (this.state.notifications[index].type === 'Offer Received') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/app/offers/' + objectId
            } else if (this.state.notifications[index].type === 'Program Feedback Received') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/app/logs/' + objectId
              additionalItem = 'Native Application'
            } else if (this.state.notifications[index].type === 'Employer Feedback Received') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/app/logs/' + objectId
              additionalItem = 'Native Application'
            } else if (this.state.notifications[index].type === 'Endorsement Received') {
              objectId = ''
              notiPath = '/app/endorsements'
              additionalItem = ''
            } else if (this.state.notifications[index].type === 'Endorsement Request') {
              objectId = this.state.notifications[index].objectId
              notiPath = '/app/endorsements/send-endorsement'
              additionalItem = ''
            } else if (this.state.notifications[index].type && this.state.notifications[index].type.includes('Feedback on Project')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/app/edit-profile'
              additionalItem = ''
              localStorage.setItem('objectId', objectId)
            } else if (this.state.notifications[index].type === 'Tagged in Post') {
              objectId = this.state.notifications[index].objectId
              notiPath = '/app/social-posts/' + this.state.notifications[index].objectId

              // objectId = this.state.notifications[i - 1].objectId
              // notiPath = '/app/opportunities/' + objectId
            } else if (this.state.notifications[index].type === 'Referral Received') {
              objectId = this.state.notifications[index].objectId
              notiPath = '/app/opportunities/' + this.state.notifications[index].objectId
              additionalItem = ''
            } else if (this.state.notifications[index].type === 'Mentor-Mentee Pairing') {
              objectId = this.state.notifications[index].objectId
              if (objectId) {
                notiPath = '/app/mentors/' + this.state.notifications[index].objectId
              } else {
                notiPath = '/app'
              }

              additionalItem = ''
            } else if (this.state.notifications[index].type && this.state.notifications[index].type.includes('Suggestion')) {
              if (this.state.notifications[index].type.includes('Project')) {
                objectId = this.state.notifications[index].objectId
                notiPath = '/app/edit-profile/profil-details'
                additionalItem = ''
              } else {
                objectId = this.state.notifications[index].objectId
                notiPath = '/app/logs/' + this.state.notifications[index].objectId
                additionalItem = ''
              }
            }
          }

          // let notiPath = '/app/postings/5c703cc7c29ab2400a036875'

          // console.log('show names', this.state.notifications[i - 1].senderFirstName, this.state.notifications[i - 1].senderLastName)

        } else if (window.location.pathname.includes('/advisor')) {

          notiPath = '/'

          if (this.state.notifications[i - 1].type === 'Advisor Request') {

            if ( this.state.notifications[i - 1].isDecided === true ) {

              if (this.state.notifications[i - 1].isApproved === true ) {
                message = 'career advisor request was approved'
              } else {
                message = 'career advisor request was denied'
              }
            } else {
              message = 'requests to be your career advisor'
            }
          } else {

            message = this.state.notifications[i - 1].message

            if (this.state.notifications[index].type === 'Track Approval') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/advisor/postings/' + objectId
            } else if (this.state.notifications[index].type === 'Endorsement Received') {
              objectId = ''
              notiPath = '/advisor/endorsements'
              additionalItem = ''
            } else if (this.state.notifications[index].type === 'Endorsement Request') {
              objectId = this.state.notifications[index].objectId
              notiPath = '/advisor/endorsements/' + this.state.notifications[index].objectId
              additionalItem = ''
            } else if (this.state.notifications[index].type === 'Mentor-Mentee Pairing') {
              objectId = this.state.notifications[index].objectId
              if (objectId) {
                notiPath = '/app/profile/' + objectId
              } else {
                notiPath = '/advisor/dashboard'
              }
            } else if (this.state.notifications[index].type && this.state.notifications[index].type.includes('Requested')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/advisor/opportunities/' + this.state.notifications[index].objectId
            } else if (this.state.notifications[index].type &&this.state.notifications[index].type.includes('Status Update')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/advisor/opportunities/' + this.state.notifications[index].objectId
            } else {
              console.log('testerson 2', this.state.notifications[index].type)
            }
          }

        } else if (window.location.pathname.includes('/organizations')) {

          notiPath = '/'

          if (this.state.notifications[i - 1].type === 'Advisor Request') {

            if ( this.state.notifications[i - 1].isDecided === true ) {

              if (this.state.notifications[i - 1].isApproved === true ) {
                message = 'career advisor request was approved'
              } else {
                message = 'career advisor request was denied'
              }
            } else {
              message = 'requests to be your career advisor'
            }
          } else {

            message = this.state.notifications[i - 1].message

            if (this.state.notifications[index].type === 'Track Approval') {
              objectId = this.state.notifications[i - 1].objectId
              notiPath = '/organizations/' + this.state.activeOrg + '/postings/' + objectId
            } else if (this.state.notifications[index].type.includes('Requested')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/organizations/' + this.state.activeOrg + '/postings/' + this.state.notifications[index].objectId
            } else if (this.state.notifications[index].type.includes('Status Update')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/organizations/' + this.state.activeOrg + '/postings/' + this.state.notifications[index].objectId
            } else if (this.state.notifications[index].type.includes('Session Added') || this.state.notifications[index].type.includes('Session Edited')) {
              objectId = this.state.notifications[index].objectId
              notiPath = '/organizations/' + this.state.activeOrg + '/logs/' + this.state.notifications[index].objectId

            } else {
              console.log('testerson 2', this.state.notifications[index].type)
            }
          }
        }

        rows.push(
          <Link className="secret-link" to={notiPath} state={{ objectId, passedLogType: additionalItem }}>
            <div key={i} className={this.state.notifications[index].isRead ? "table-cell-1" : "table-cell-1 faint-highlight"} >
              <div className="spacer" />
              <div>
                <div className="fixed-column-40">
                  <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={(this.state.notifications[index].senderPictureURL) ? this.state.notifications[index].senderPictureURL : notificationsIconBlue} alt="GC" className="image-auto-40"/>
                </div>

                <div className={mainClass + ' all-padding-10'}>
                  <label className="heading-text-5">{senderName}</label>
                  <div className="clear" />
                  <div className="half-spacer" />
                  <label className="description-text-color">{message}</label>
                </div>

                {(!this.state.onMobile) && (
                  <span className="fixed-column-100 right-text right-padding">
                  <div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <label className="description-text-2 description-text-color right-text">{notiDate}</label>
                  </span>
                )}
                <span className={arrowClass}>
                    <div className="spacer" /><div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-16" />
                </span>

              </div>
              <div className="clear" />
              {(this.state.onMobile) && (
                <span className="left-margin-50">
                  <label className="description-text-2 description-text-color right-text">>{notiDate}</label>
                </span>
              )}
              <div className="spacer" />

              <div className="spacer" />
            </div>
          </Link>
        )
      }

      return rows;

    }

    render() {

      if (window.location.pathname.includes('/app') || window.location.pathname.includes('/advisor') || window.location.pathname.includes('/organizations') || window.location.pathname.includes('/employers')) {

        return (
            <div>
              <div className="top-margin-30">
                <p className="heading-text-2">Notifications</p>
                <div className="spacer" />
              </div>
              <div className="toggle-content">
                { (this.state.notifications.length > 0) ? (
                  <div>
                    {this.renderNotifications()}
                  </div>
                ) : (
                  <div className="toggle-empty-state-container">
                    <img src={storyIconBig} alt="Compass large endorsements icon" className="empty-state-icon"/>
                    <h3 className="empty-state-title"><strong>No Notifications</strong></h3>
                    <p className="empty-state-text">You do not have any notifications.</p>
                  </div>
                )}
              </div>
            </div>

        )
      } else if (window.location.pathname.includes('/employers')) {
        // employers

        return (
          <div className="standard-container-2">

          </div>
        )
      } else {

        // this should never happen
        return (
            <div>

            </div>

        )
      }
    }
}


export default withRouter(Notifications);
