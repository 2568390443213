import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';;
import Modal from 'react-modal';
import SubInviteMembers from '../Common/InviteMembers';
import SubEditGroup from '../Common/EditGroup';
import SubRenderProfiles from '../Common/RenderProfiles';
import SubRenderProjects from '../Common/RenderProjects';
import SubRenderGoals from '../Common/RenderGoals';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const socialIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-grey.png';
const favoriteIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const rightCarrotBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/right-carrot-blue.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const addPeopleIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-people-icon-dark.png';
const targetIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';

class Community extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          showGoals: true,

          showAccountabiliyGroupCTA: true,
          favoriteIds: [],
          newFavorites: [],
          showSubFunction: true,
          friends: [],
          goalFollows: [],
          projectFollows: [],
          employerFollows: [],
          groupsJoined: [],

          prefix: '/app'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.joinGroup = this.joinGroup.bind(this)
        this.viewGroup = this.viewGroup.bind(this)
        this.decideOnRequest = this.decideOnRequest.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderItems = this.renderItems.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount called in employerDetails')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in employerDetails ', this.props, prevProps)

      if (prevProps.employerId !== this.props.employerId) {
        this.retrieveData()
      } else {

      }
    }

    retrieveData() {
      console.log('retrieveData called in employeerDetails', this.props)

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgName = localStorage.getItem('orgName')
      let remoteAuth = localStorage.getItem('remoteAuth')
      let accountCode = localStorage.getItem('accountCode');
      let studentAliasPlural = localStorage.getItem('studentAliasPlural');
      let mentorAliasPlural = localStorage.getItem('mentorAliasPlural');
      // console.log('show orgName --: ', orgName, roleName, accountCode)

      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      // let surveyReady = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // surveyReady = true
        // accountCode = null
      } else {
        accountCode = null
      }
      // colleagues, goals, groups, events, employee of the month
      // org chart

      let prefix = '/app'
      if (roleName === 'Mentor') {
        prefix = '/advisor'
      }

      this.setState({ emailId, username, cuFirstName, cuLastName, firstName: cuFirstName, lastName: cuLastName,
        roleName, activeOrg, orgFocus, orgName, remoteAuth, employerAccount, prefix,
        studentAliasPlural, mentorAliasPlural
      })

      const self = this
      const resLimit = 6

      if (activeOrg && !orgName) {
        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const headerImageURL = response.data.orgInfo.headerImageURL

              this.setState({ orgName, headerImageURL });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

      Axios.get('/api/partners', { params: { partnerEmail: emailId, active: true } })
      .then((response) => {
        console.log('Partner query attempted', response.data);

        if (response.data.success) {
          console.log('partner query worked')

          if (response.data.partners && response.data.partners.length !== 0) {
            let pairings = []
            let pairingEmails = []

            // assume one mentor
            if (response.data.partners && response.data.partners[0]) {
              for (let i = 1; i <= response.data.partners.length; i++) {
                // pairings.push(response.data.partners[i - 1])
                if (window.location.pathname.startsWith('/advisor')) {
                  pairingEmails.push(response.data.partners[i - 1].adviseeEmail)
                } else {
                  pairingEmails.push(response.data.partners[i - 1].advisorEmail)
                }
              }

              for (let i = 1; i <= pairingEmails.length; i++) {

                // const index = i - 1

                Axios.get('/api/users/profile/details', { params: { email: pairingEmails[i - 1] }})
                .then((response) => {
                  console.log('Profile query attempted', response.data);

                  if (response.data.success) {
                    console.log('profile query worked')

                    // let mentor = mentors[index]
                    // mentor['firstName'] = mentors[index].advisorFirstName
                    // mentor['lastName'] = mentors[index].advisorLastName
                    // mentor['email'] = mentors[index].advisorEmail
                    // mentor['pictureURL'] = response.data.user.pictureURL
                    // mentor['username'] = response.data.user.username
                    // mentor['school'] = response.data.user.school
                    // mentor['gradYear'] = response.data.user.gradYear
                    // mentor['education'] = response.data.user.education
                    //
                    // mentors[index] = mentor
                    // this.setState({ mentors })

                    pairings.push(response.data.user)
                    this.setState({ pairings })

                  } else {
                    console.log('query for profile query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Profile query did not work for some reason', error);
                });

              }
            }
          }

        } else {
          console.log('query for partners query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Partners query did not work for some reason', error);
      });

      Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
       .then((response) => {
         console.log('query for profile data worked');

         if (response.data.success) {

           console.log('profile data received', response.data)
           const pictureURL = response.data.user.pictureURL
           const headline = response.data.user.headline

           const jobTitle = response.data.user.jobTitle
           const employerName = response.data.user.employerName
           let schoolName = response.data.user.school
           if (response.data.user.education && response.data.user.education.length > 0) {
             schoolName = response.data.user.education[0].name
             if (response.data.user.education.length > 1) {
               for (let i = 1; i <= response.data.user.education.length; i++) {
                 if (response.data.user.education[i - 1] && this.state.education[i - 1].isContinual) {
                   schoolName = response.data.user.education[i - 1].name
                 }
               }
             }
           }

           const notificationPreferences = response.data.user.notificationPreferences

           this.setState({ pictureURL, headline, jobTitle, employerName, schoolName, notificationPreferences })

         } else {
           console.log('error response', response.data)
         }

      }).catch((error) => {
           console.log('query for profile info did not work', error);
      })

      function pullSuggestedPeople(friendIds) {
        console.log('pullSuggestedPeople called')

        // const roleNames = ['Student','Career-Seeker']
        const roleNames = ['Student','Career-Seeker','Mentor']

        let suggestionParams = { orgCode: activeOrg, emailId, resLimit, roleNames, friendIds }
        if (employerAccount) {
          suggestionParams = { accountCode, emailId, resLimit }
        }
        Axios.get('/api/suggested-people', { params: suggestionParams })
        .then((response) => {
          console.log('Suggested people query attempted', response.data);

            if (response.data.success) {
              console.log('suggested people query worked')

              const suggestedPeople = response.data.users
              self.setState({ suggestedPeople })

            } else {
              console.log('suggested people query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Suggested people query did not work for some reason', error);
        })
      }

      function pullGroupNotifications(showInvitations) {
        console.log('pullGroupNotifications called', showInvitations)

        Axios.get('/api/groups/notifications', { params: { orgCode: activeOrg, emailId } })
        .then((response) => {
          console.log('Group notifications query attempted', response.data);

            if (response.data.success && response.data.groupsWithNotifications && response.data.groupsWithNotifications.length > 0) {
              console.log('group notifications query worked')

              let groupRequests = []
              let groupInvites = []

              let unreadGroups = []
              for (let i = 1; i <= response.data.groupsWithNotifications.length; i++) {

                let tempRequests = response.data.groupsWithNotifications[i - 1].requests
                let admins = response.data.groupsWithNotifications[i - 1].admins
                if (tempRequests && tempRequests.length > 0 && admins && admins[0].email === emailId) {

                  let hasUnreadRequests = false
                  for (let j = 1; j <= tempRequests.length; j++) {

                    if (!tempRequests[j - 1].status) {

                      let request = tempRequests[j - 1]
                      request['groupId'] = response.data.groupsWithNotifications[i - 1]._id
                      request['groupName'] = response.data.groupsWithNotifications[i - 1].name
                      request['name'] = response.data.groupsWithNotifications[i - 1].name
                      request['category'] = response.data.groupsWithNotifications[i - 1].category
                      // request['pictureURL'] = response.data.groupsWithNotifications[i - 1].pictureURL
                      request['admins'] = response.data.groupsWithNotifications[i - 1].admins
                      request['upvotes'] = response.data.groupsWithNotifications[i - 1].upvotes
                      request['members'] = response.data.groupsWithNotifications[i - 1].members
                      groupRequests.push(request)
                      showInvitations = true
                    }
                    if (!tempRequests[j - 1].read) {

                      hasUnreadRequests = true
                    }
                  }
                  if (hasUnreadRequests) {

                    // dont add duplicate unread groups
                    if (unreadGroups.length > 0) {
                      let alreadyIncluded = false
                      for (let j = 1; j <= unreadGroups.length; j++) {
                        if (unreadGroups[j - 1]._id === response.data.groupsWithNotifications[i - 1]._id) {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        unreadGroups.push(response.data.groupsWithNotifications[i - 1])
                      }
                    } else {
                      unreadGroups.push(response.data.groupsWithNotifications[i - 1])
                    }
                  }
                }

                let tempInvites = response.data.groupsWithNotifications[i - 1].invites
                if (tempInvites && tempInvites.length > 0) {
                  let hasUnreadInvites = false
                  for (let j = 1; j <= tempInvites.length; j++) {
                    if (tempInvites[j - 1].email === emailId) {
                      if (!tempInvites[j - 1].status) {
                        let invite = tempInvites[j - 1]
                        invite['groupId'] = response.data.groupsWithNotifications[i - 1]._id
                        invite['groupName'] = response.data.groupsWithNotifications[i - 1].name
                        invite['name'] = response.data.groupsWithNotifications[i - 1].name
                        invite['category'] = response.data.groupsWithNotifications[i - 1].category
                        invite['pictureURL'] = response.data.groupsWithNotifications[i - 1].pictureURL
                        invite['admins'] = response.data.groupsWithNotifications[i - 1].admins
                        invite['upvotes'] = response.data.groupsWithNotifications[i - 1].upvotes
                        invite['members'] = response.data.groupsWithNotifications[i - 1].members
                        groupInvites.push(invite)
                        showInvitations = true
                      }

                      if (!tempInvites[j - 1].read) {
                        hasUnreadInvites = true
                      }
                    }
                  }
                  if (hasUnreadInvites) {
                    // dont add duplicate unread groups
                    if (unreadGroups.length > 0) {
                      let alreadyIncluded = false
                      for (let j = 1; j <= unreadGroups.length; j++) {
                        if (unreadGroups[j - 1]._id === response.data.groupsWithNotifications[i - 1]._id) {
                          alreadyIncluded = true
                        }
                      }
                      if (!alreadyIncluded) {
                        unreadGroups.push(response.data.groupsWithNotifications[i - 1])
                      }
                    } else {
                      unreadGroups.push(response.data.groupsWithNotifications[i - 1])
                    }
                  }
                }
              }
              // console.log('t6', groupInvites)
              self.setState({ groupRequests, groupInvites, showInvitations })

              if (unreadGroups && unreadGroups.length > 0) {
                // change read to true
                Axios.put('/api/groups/notifications/update', { emailId, unreadGroups })
                .then((response) => {
                  console.log('Group notifications update attempted', response.data);

                    if (response.data.success) {
                      console.log('friends update notification worked')

                    } else {
                      console.log('friends update notification did not work', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Groups update notification did not work for some reason', error);
                })
              }

            } else {
              console.log('unread notifications query did not work', response.data.message)

            }

        }).catch((error) => {
            console.log('Unread requests query did not work for some reason', error);
        })
      }

      Axios.get('/api/friends', { params: { orgCode: activeOrg, emailId } })
      .then((response) => {
        console.log('Friends query attempted', response.data);

          if (response.data.success) {
            console.log('friends query worked')

            const allRelationships = response.data.friends
            const friends = []
            let friendIds = []
            let friendRequests = []
            let showInvitations = this.state.showInvitations
            let unreadRequests = []
            if (response.data.friends) {
              for (let i = 1; i <= response.data.friends.length; i++) {

                let friendshipId = response.data.friends[i - 1]._id
                let pictureURL = ''
                let firstName = ''
                let lastName = ''
                let email = ''
                let username = ''
                let headline = ''
                let active = response.data.friends[i - 1].active
                let activeRequest = response.data.friends[i - 1].activeRequest
                // console.log('looping through friends: ', response.data.friends[i - 1].friend1Email, emailId)
                if (response.data.friends[i - 1].friend1Email === emailId) {
                  pictureURL = response.data.friends[i - 1].friend2PictureURL
                  firstName = response.data.friends[i - 1].friend2FirstName
                  lastName = response.data.friends[i - 1].friend2LastName
                  email = response.data.friends[i - 1].friend2Email
                  username = response.data.friends[i - 1].friend2Username
                  headline = response.data.friends[i - 1].friend2Headline

                  friendIds.push(response.data.friends[i - 1].friend2Email)

                } else {
                  pictureURL = response.data.friends[i - 1].friend1PictureURL
                  firstName = response.data.friends[i - 1].friend1FirstName
                  lastName = response.data.friends[i - 1].friend1LastName
                  email = response.data.friends[i - 1].friend1Email
                  username = response.data.friends[i - 1].friend1Username
                  headline = response.data.friends[i - 1].friend1Headline

                  friendIds.push(response.data.friends[i - 1].friend1Email)
                }
                console.log("what: ", response.data.friends[i - 1].friend2Email, response.data.friends[i - 1].active)
                if (response.data.friends[i - 1].activeRequest && response.data.friends[i - 1].requesterEmail !== emailId) {

                  friendRequests.push({ friendshipId, pictureURL, firstName, lastName, email, username, headline, active, activeRequest })
                  showInvitations = true

                  if (response.data.friends[i - 1].isRead === false) {
                    unreadRequests.push(response.data.friends[i - 1])
                  }

                } else if (response.data.friends[i - 1].active) {

                  friends.push({ friendshipId, pictureURL, firstName, lastName, email, username, headline, active, activeRequest })
                  console.log("ab: ", response.data.friends[i - 1].friend2Email, response.data.friends[i - 1].active)

                }
              }
            }

            console.log('show them friendRequests: ', friendRequests, showInvitations)

            this.setState({ allRelationships, friends, friendRequests, showInvitations })

            if (unreadRequests.length > 0) {

              Axios.put('/api/friends/update', { emailId, readRequests: true })
              .then((response) => {
                console.log('Friends update requests attempted', response.data);

                  if (response.data.success) {
                    console.log('friends update request worked')

                  } else {
                    console.log('friends update request did not work', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Friends update request did not work for some reason', error);
              })
            }

            pullSuggestedPeople(friendIds)
            pullGroupNotifications(showInvitations)

          } else {
            console.log('friends query did not work', response.data.message)
            pullSuggestedPeople(null)
            pullGroupNotifications(false)
          }

      }).catch((error) => {
          console.log('Friends query did not work for some reason', error);
      })

      function pullOtherItems(favoriteIds) {
        console.log('pullOtherItems called')

        Axios.get('/api/logs/goals', { params: { orgCode: activeOrg, resLimit, favoriteIds, publicExtentArray: ['Members Only','Public'] } })
        .then((response) => {
          console.log('Goals query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved goals')

              if (response.data.goals) {

                const goals = response.data.goals
                self.setState({ goals })
              }

            } else {
              console.log('no goal data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });

        Axios.get('/api/projects', { params: { orgCode: activeOrg, resLimit, favoriteIds, publicExtentArray: ['Members Only','Public'] } })
        .then((response) => {
          console.log('Projects query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved projects')

              if (response.data.projects) {

                const projects = response.data.projects
                self.setState({ projects })
              }

            } else {
              console.log('no project data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Project query did not work', error);
        });

        Axios.get('/api/account/partners', { params: { org: activeOrg, resLimit, favoriteIds } })
        .then((response) => {
          console.log('Posted employer query attempted', response.data);

          if (response.data.success) {
            console.log('posted employer query worked')

            if (response.data.employers.length !== 0) {
              const employers = response.data.employers
              self.setState({ employers });
            }

          } else {
            console.log('query for employers query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Employer query did not work for some reason', error);
        });
      }
      console.log('show emailId: ', emailId)
      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved favorites', response.data.favorites.length)

            if (response.data.favorites.length > 0) {
              console.log('the array is greater than 0', response.data.favorites.length)

              const orgCode = activeOrg
              const favoriteIds = response.data.favorites

              let newFavorites = []
              if (response.data.newFavorites) {
                newFavorites = response.data.newFavorites
              }
              //query info on those favorites
              Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
              .then((response2) => {
                console.log('Favorites detail query attempted', response2.data);

                if (response2.data.success) {
                  console.log('successfully retrieved favorites detail', response2.data.favorites)

                  let types = response2.data.types
                  let favorites = response2.data.favorites

                  let goalFollows = []
                  let projectFollows = []
                  let employerFollows = []

                  for (let i = 1; i <= types.length; i++) {
                    if (types[i - 1] === 'project') {
                      projectFollows.push(favorites[i - 1])
                    } else if (types[i - 1] === 'employer') {
                      employerFollows.push(favorites[i - 1])
                    } else if (types[i - 1] === 'goal') {
                      goalFollows.push(favorites[i - 1])
                    }
                  }

                  //query info on those favorites
                  this.setState({
                    types, favorites,
                    favoriteIds, newFavorites, goalFollows, projectFollows, employerFollows
                  })

                  pullOtherItems(favoriteIds)

                } else {
                  console.log('no favorites detail data found', response2.data.message)
                  pullOtherItems(favoriteIds)
                }

              }).catch((error) => {
                  console.log('Favorites detail query did not work', error);
                  pullOtherItems(favoriteIds)
              });
            } else {
              pullOtherItems(null)
            }

          } else {
            console.log('no favorites data found', response.data.message)
            pullOtherItems(null)
          }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
          pullOtherItems(null)
      });

      Axios.get('/api/groups', { params: { orgCode: activeOrg, isActive: true, resLimit: 9 }})
      .then((response) => {
        console.log('Groups query worked', response.data);

        if (response.data.success) {

          let groups = response.data.groups
          this.setState({ groups })

          // my groups
          Axios.get('/api/groups', { params: { orgCode: activeOrg, emailId, type: 'myGroups' }})
          .then((response) => {
            console.log('My groups query worked', response.data);

            if (response.data.success) {

              let groupsJoined = response.data.groups
              let noAccountabilityGroups = true
              // if (groupsJoined) {
              //   for (let i = 1; i <= groupsJoined.length; i++) {
              //     if (groupsJoined[i - 1].category === 'Accountability') {
              //       noAccountabilityGroups = false
              //     }
              //   }
              // }
              this.setState({ groupsJoined, noAccountabilityGroups })

            } else {
              console.log('no my groups data found', response.data.message)
              this.setState({ noAccountabilityGroups: true })
            }

          }).catch((error) => {
              console.log('My groups query did not work', error);
          });

        } else {
          console.log('no groups data found', response.data.message)
          this.setState({ noAccountabilityGroups: true })
        }

      }).catch((error) => {
          console.log('Groups query did not work', error);
      });
    }

    async favoriteItem(e, item, entity) {
      console.log('favoriteItem called', e, item, entity)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favoriteIds, this.state.emailId, this.state.newFavorites,"employer")

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favoritesArray: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favoriteIds

        if (favoritesArray.includes(itemId)){
          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
        } else {
          // console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
        }

        console.log('favorites', favoritesArray)
        this.setState({ favoriteIds: favoritesArray })

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)

            if (entity === 'employers') {
              let employerFollows = this.state.employerFollows
              employerFollows.push(item)
              this.setState({ successMessage: 'Favorite saved!', employerFollows, isSaving: false })
            } else if (entity === 'projects') {
              let projectFollows = this.state.projectFollows
              projectFollows.push(item)
              this.setState({ successMessage: 'Favorite saved!', projectFollows, isSaving: false })
            } else if (entity === 'goals') {
              let goalFollows = this.state.goalFollows
              goalFollows.push(item)
              this.setState({ successMessage: 'Favorite saved!', goalFollows, isSaving: false })

            } else {
              this.setState({ successMessage: 'Favorite saved!', isSaving: false })
            }

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }
    }

    joinGroup(e, group, index, joinGroup) {
      console.log('joinGroup called', e, group, index, joinGroup)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let groupId = group._id
      const member = {
        firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
        email: this.state.emailId, roleName: this.state.roleName, pictureURL: this.state.pictureURL
      }
      const accessType = group.accessType

      // let unsubscribed = null
      // if (this.state.notificationPreferences && this.state.notificationPreferences.length > 0) {
      //   for (let i = 1; i <= this.state.notificationPreferences.length; i++) {
      //     if (this.state.notificationPreferences[i - 1].slug === 'group-requests' && this.state.notificationPreferences[i - 1].enabled === false) {
      //       unsubscribed = true
      //     }
      //   }
      // }

      Axios.post('/api/groups/save', { groupId, member, accessType, joinGroup })
      .then((response) => {
        console.log('attempting to save addition to groups')
        if (response.data.success) {
          console.log('saved addition to groups', response.data)

          let groups = this.state.groups
          groups[index] = response.data.group

          let groupsJoined = this.state.groupsJoined
          groupsJoined.push(response.data.group)

          this.setState({ successMessage: 'Saved as a group!', groups, groupsJoined, isSaving: false })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
      });

    }

    viewGroup(group) {
      console.log('viewGroup called', group)

      let groupDetailsPath = this.state.prefix + '/groups/' + group._id
      this.props.navigate(groupDetailsPath, { state: { selectedGroup: group }})

    }

    decideOnRequest(index, decision, type) {
      console.log('decideOnRequest called', index, decision, type)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (type === 'connection') {

        let friendRequests = this.state.friendRequests
        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId
        const senderPictureURL = this.state.pictureURL
        const senderHeadline = this.state.headline
        const senderUsername = this.state.username
        const senderJobTitle = this.state.jobTitle
        const senderEmployerName = this.state.employerName
        const senderSchoolName = this.state.schoolName
        let recipientEmail = friendRequests[index].email

        // let unsubscribed = null
        // if (this.state.notificationPreferences && this.state.notificationPreferences.length > 0) {
        //   for (let i = 1; i <= this.state.notificationPreferences.length; i++) {
        //     if (this.state.notificationPreferences[i - 1].slug === 'accepted-friend-requests' && this.state.notificationPreferences[i - 1].enabled === false) {
        //       unsubscribed = true
        //     }
        //   }
        // }

        const headerImageURL = this.state.headerImageURL

        const self = this
        function asyncTask(unsubscribed) {
          console.log('asyncTask called', unsubscribed)

          const updateBody = {
            decidedOnRequest: true, decision, request: friendRequests[index], recipientEmail,
            senderFirstName, senderLastName, senderEmail, senderPictureURL, senderHeadline,
            senderUsername, senderJobTitle, senderEmployerName, senderSchoolName, unsubscribed, headerImageURL
          }

          Axios.put('/api/friends/update', updateBody)
          .then((response) => {
            console.log('Friends update requests attempted', response.data);

              if (response.data.success) {
                console.log('friends update request worked')

                let friends = self.state.friends
                if (decision) {
                  friends.push(friendRequests[index])
                }

                friendRequests.splice(index,1)

                self.setState({ friends, friendRequests, isSaving: false })

              } else {
                console.log('friends update request did not work', response.data.message)
                self.setState({ isSaving: false, errorMessage: response.data.message })
              }

          }).catch((error) => {
              console.log('Friends update request did not work for some reason', error);
          })
        }

        // pull notification preferences of the owner
        Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
        .then((response) => {
          console.log('Profile query for notipreferences attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved profile information')
             const notificationPreferences = response.data.user.notificationPreferences

             let unsubscribed = null
             if (notificationPreferences && notificationPreferences.length > 0) {
               for (let i = 1; i <= notificationPreferences.length; i++) {
                 if (notificationPreferences[i - 1].slug === 'accepted-friend-requests' && notificationPreferences[i - 1].enabled === false) {
                   unsubscribed = true
                 }
               }
             }

             asyncTask(unsubscribed)

           } else {
             console.log('no profile data found', response.data.message)

             asyncTask()
           }

        }).catch((error) => {
           console.log('Profile query did not work', error);
        });
      } else if (type === 'groupRequest') {
        let groupRequests = this.state.groupRequests
        const _id = groupRequests[index].groupId
        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId

        Axios.put('/api/groups/notifications/update', { decidedOnRequest: true, decision, request: groupRequests[index], senderFirstName, senderLastName, senderEmail, _id, type: 'request' })
        .then((response) => {
          console.log('Groups update notifications attempted', response.data);

            if (response.data.success) {
              console.log('group update notification worked')

              // let groupsJoined = this.state.groupsJoined
              // if (decision) {
              //   groupsJoined.push(groupRequests[index])
              // }

              groupRequests.splice(index,1)

              this.setState({ groupRequests, isSaving: false })

            } else {
              console.log('group notifications update request did not work', response.data.message)
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }

        }).catch((error) => {
            console.log('Group notifications did not work for some reason', error);
        })

      } else if (type === 'groupInvite') {
        let groupInvites = this.state.groupInvites
        const _id = groupInvites[index].groupId
        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId

        Axios.put('/api/groups/notifications/update', { decidedOnRequest: true, decision, request: groupInvites[index], senderFirstName, senderLastName, senderEmail, _id, type: 'invite' })
        .then((response) => {
          console.log('Groups update notifications attempted', response.data);

            if (response.data.success) {
              console.log('group update notification worked')

              let groupsJoined = this.state.groupsJoined
              if (decision) {
                let groupToJoin = groupInvites[index]
                groupToJoin['members'].push({ firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
                  email: this.state.email, username: this.state.username, pictureURL: this.state.pictureURL,
                  roleName: this.state.roleName
                })
                groupToJoin['_id'] = groupToJoin.groupId
                groupsJoined.push(groupToJoin)
              }

              groupInvites.splice(index,1)

              this.setState({ groupInvites, isSaving: false })

            } else {
              console.log('group notifications update request did not work', response.data.message)
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }

        }).catch((error) => {
            console.log('Group notifications did not work for some reason', error);
        })
      }
    }

    closeModal() {
      console.log('closeModal in projects: ')

      this.setState({ modalIsOpen: false, showPeopleYouFollow: false, showGoalsYouFollow: false,
        showProjectsYouFollow: false, showEmployersYouFollow: false,
        showGroupsYouJoined: false, showInviteMembersWidget: false, showEditGroup: false
      });
    }

    renderItems(entity,type) {
      console.log('renderItems called')

      if (entity === 'people') {

        let items = this.state.suggestedPeople
        if (type === 'existing') {
          items = this.state.friends
        }

        let hideCTA = false
        if (this.state.employerAccount) {
          hideCTA = true
        }

        return (
          <div key={entity}>
            {(items && items.length > 0) && (
              <div>
                <SubRenderProfiles
                  favorites={this.state.favoriteIds} newFavorites={this.state.newFavorites}
                  members={items} friends={this.state.allRelationships}
                  pageSource={"Profiles"} history={this.props.navigate} userType="Peers" hideCTA={hideCTA}
                />
              </div>
            )}
          </div>
        )
      } else if (entity === 'goals') {

        let items = this.state.goals
        if (type === 'existing') {
          items = this.state.goalFollows
        }
        // console.log('show items: ', items, this.state.goals, type)
        return (
          <div key={entity}>
            {(items && items.length > 0) && (
              <div>
                <SubRenderGoals
                  history={this.props.navigate} favorites={this.state.favoriteIds} newFavorites={this.state.newFavorites}
                  filteredGoals={items} profileData={null}
                  filterCriteriaArray={this.state.filterCriteriaArray}
                  sortCriteriaArray={this.state.sortCriteriaArray}
                  org={this.state.activeOrg}
                />
              </div>
            )}
          </div>
        )
      } else if (entity === 'projects') {

        let items = this.state.projects
        if (type === 'existing') {
          items = this.state.projectFollows
        }

        return (
          <div key={entity}>
            {(items && items.length > 0) && (
              <div>
                <SubRenderProjects
                  favorites={this.state.favoriteIds} newFavorites={this.state.newFavorites}
                  projects={items} pageSource={this.props.pageSource}
                  filterCriteriaArray={this.state.filterCriteriaArray}
                  sortCriteriaArray={this.state.sortCriteriaArray}
                  jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                  org={this.state.activeOrg} accountCode={this.state.accountCode}
                  gradeOptions={this.state.gradeOptions} orgContactEmail={this.state.orgContactEmail}
                />
              </div>
            )}
          </div>
        )
      } else if (entity === 'employers') {

        let items = this.state.employers
        if (type === 'existing') {
          items = this.state.employerFollows
        }

        return (
          <div key={entity}>
            {(items && items.length > 0) && (
              <div>
                {items.map((item, optionIndex) =>
                  <div key={item + optionIndex}>
                    <div className="top-padding-20 bottom-padding-30">
                      <Link className="background-link" to={this.state.prefix + '/employers/' + item._id} state={{ selectedEmployer: item, employers: items }}>
                        <span className="fixed-column-70">
                          <img src={(item.employerLogoURI) ? item.employerLogoURI : industryIconDark} alt="GC" className="image-50-auto"/>
                        </span>
                        <span className={"calc-column-offset-130"}>
                          <p className="heading-text-5">{item.employerName}</p>
                          <p className="description-text-2 description-text-color top-padding-5">{item.employerIndustry}</p>
                        </span>
                        <div className="fixed-column-30 top-margin">
                          <button className="background-button" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.favoriteItem(e, item, entity)}>
                            <img src={(this.state.favoriteIds.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="GC" className="image-auto-20 pin-right"/>
                          </button>
                        </div>
                        <div className="fixed-column-30 top-margin">
                          <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-20 pin-right"/>
                        </div>
                      </Link>
                    </div>

                    <div className="clear" />

                    <hr className="cell-separator-advisor"/>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      } else if (entity === 'groups') {

        let items = this.state.groups
        if (type === 'existing') {
          items = this.state.groupsJoined
        }

        return (
          <div key={entity}>
            {(items && items.length > 0) && (
              <div>
                {items.map((item, optionIndex) =>
                  <div key={item + optionIndex}>
                    {((!item.members) || (item.members.length === 0) || (!item.members.some(member => member.email === this.state.emailId) || (type === 'existing'))) && (
                      <div>
                        <div className={(window.innerWidth < 768) ? "full-width medium-background" : "relative-column-33 horizontal-padding-7"}>
                          <button onClick={() => this.viewGroup(item)} className="background-button full-width">
                            <div className="elevated-box white-background" >
                              <div className="full-width relative-position tint">
                                <img src={(item.pictureURL && item.pictureURL !== 'undefined') ? item.pictureURL : socialIconDark} alt="GC" className="image-full-width-150 center-horizontally"  />
                                <div className="absolute-position absolute-top-5 absolute-left-5">
                                  {(item.matchScore) && (
                                    <div className="image-40-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                                      <CircularProgressbar
                                        value={item.matchScore}
                                        text={`${item.matchScore}%`}
                                        styles={{
                                          path: { stroke: `rgba(255, 255, 255), ${item.matchScore / 100}` },
                                          text: { fill: 'white', fontSize: '26px' },
                                          trail: { stroke: 'transparent' }
                                        }}
                                      />
                                    </div>
                                  )}
                                  <p className="description-text-5 rounded-corners horizontal-padding-4 row-5 white-border white-text bold-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Category: " + item.category}>{item.category}</p>
                                </div>
                                {/*
                                <button className="background-button clear-padding display-block absolute-position absolute-top-5 absolute-right-5 white-background rounded-corners" onClick={(e) => this.voteOnItem(e, item, 'up', optionIndex) }>
                                  <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                                    <div className="float-left padding-7">
                                      <img src={(item.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                    </div>
                                    <div className="vertical-separator-4" />
                                    <div className="float-left horizontal-padding-10">
                                      <div className="half-spacer" />
                                      <p className="description-text-2 half-bold-text">{item.upvotes.length}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </button>*/}
                              </div>

                              <div className="spacer" />

                              <div className="horizontal-padding left-text">
                                <p className="heading-text-5">{item.name}</p>
                                <p className="description-text-5">{(item.members) ? item.members.length : "0"} Members</p>

                                <div className="top-padding">

                                    {(item.members && item.members.some(member => member.email === this.state.emailId)) ? (
                                      <div>
                                        <div className="float-left right-padding">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                        </div>
                                        <div className="float-left">
                                          <p className="description-text-2 bold-text">Joined</p>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    ) : (
                                      <div>

                                        {(item.accessType === 'Open') ? (
                                          <button className="background-button" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.joinGroup(e, item, optionIndex, true)}>
                                            <p className="description-text-2 bold-text cta-color" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Join!">+ Join Group</p>
                                          </button>
                                        ) : (
                                          <div>
                                            {((item.requests && item.requests.some(request => request.email === this.state.emailId))) ? (
                                              <div className="center-text full-width right-margin-5">
                                                <div className="float-left right-padding">
                                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                  <img src={timeIconBlue} alt="GC" className="image-auto-15" />
                                                </div>
                                                <div className="float-left">
                                                  <p className="description-text-2 bold-text">Requested</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            ) : (
                                              <button className="background-button" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.joinGroup(e, item, optionIndex, true)}>
                                                <p className="description-text-2 bold-text cta-color" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Request to Join">+ Request</p>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>

                            </div>
                          </button>
                        </div>

                        {(optionIndex % 3 === 2 && window.innerWidth >= 768) && (
                          <div>
                            <div className="clear" />
                            <div className="spacer" />
                          </div>
                        )}
                        {(window.innerWidth < 768) && (
                          <div>
                            <div className="clear" />
                            <div className="spacer" />
                          </div>
                        )}
                      </div>
                    )}

                    <ReactTooltip id="tooltip-placeholder-id" />

                  </div>
                )}
              </div>
            )}
          </div>
        )

      }
    }

    render() {

      return (
          <div>
            <div className="top-margin-20">
              <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-30 horizontal-padding"}>
                <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-20" : "card-clear-padding min-width-150 max-width-300 pin-right bottom-margin-20"}>
                  <div className="full-width height-5 primary-background" />
                  <div className="padding-20">
                    <p className="heading-text-5">{(this.state.employerAccount) ? "My Favorites" : "My Community"}</p>
                    <div className="spacer" /><div className="half-spacer" />

                    <div>
                      {(!this.state.employerAccount) && (
                        <div className="row-10 description-text-1">
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showPeopleYouFollow: true })}>
                            <div className="fixed-column-30">
                              <img src={profileIconDark} alt="GC" className="image-auto-20" />
                            </div>
                            <div className="calc-column-offset-60">
                              <p>Your Connections</p>
                            </div>
                            <div className="fixed-column-30 cta-color right-text">
                              <p>{this.state.friends.length}</p>
                            </div>
                            <div className="clear" />
                          </button>
                        </div>
                      )}

                      <div className="row-10 description-text-1">
                        <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showGoalsYouFollow: true })}>
                          <div className="fixed-column-30">
                            <img src={targetIcon} alt="GC" className="image-auto-20" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p>Goals you follow</p>
                          </div>
                          <div className="fixed-column-30 cta-color right-text">
                            <p>{this.state.goalFollows.length}</p>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>

                      {(!this.state.employerAccount) && (
                        <div className="row-10 description-text-1">
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showProjectsYouFollow: true })}>
                            <div className="fixed-column-30">
                              <img src={projectsIconDark} alt="GC" className="image-auto-20" />
                            </div>
                            <div className="calc-column-offset-60">
                              <p>Projects you follow</p>
                            </div>
                            <div className="fixed-column-30 cta-color right-text">
                              <p>{this.state.projectFollows.length}</p>
                            </div>
                            <div className="clear" />
                          </button>
                        </div>
                      )}

                      <div className="row-10 description-text-1">
                        <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showGroupsYouJoined: true })}>
                          <div className="fixed-column-30">
                            <img src={socialIconDark} alt="GC" className="image-auto-20" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p>Your groups</p>
                          </div>
                          <div className="fixed-column-30 cta-color right-text">
                            <p>{this.state.groupsJoined.length}</p>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>

                      {(!this.state.employerAccount) && (
                        <div className="row-10 description-text-1">
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showEmployersYouFollow: true })}>
                            <div className="fixed-column-30">
                              <img src={industryIconDark} alt="GC" className="image-auto-20" />
                            </div>
                            <div className="calc-column-offset-60">
                              <p>Employers you follow</p>
                            </div>
                            <div className="fixed-column-30 cta-color right-text">
                              <p>{this.state.employerFollows.length}</p>
                            </div>
                            <div className="clear" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {(this.state.pairings && this.state.pairings.length > 0) && (
                  <div>
                    <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-20" : "card-clear-padding min-width-150 max-width-300 pin-right bottom-margin-20"}>
                      <div className="full-width height-5 error-background-color" />
                      <div className="padding-20">
                        <p className="heading-text-6">Your Paired {(window.location.pathname.startsWith('/advisor')) ? (this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Mentees" : (this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "Mentors"}</p>

                        <div className="spacer" /><div className="half-spacer" />

                        {this.state.pairings.map((value, index) =>
                          <div key={value}>
                            {(index < 3) && (
                              <div className="bottom-padding">
                                <Link className="background-button full-width standard-color profile-container-right" to={(window.location.pathname.includes('/advisor')) ? this.state.prefix + '/profile/' + value.username : this.state.prefix + '/mentors/' + value.username}>
                                  <div className="fixed-column-35">
                                    <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-25" alt="GC" />
                                  </div>
                                  <div className="calc-column-offset-35">
                                    <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <p className="description-text-1">{value.firstName} {value.lastName}</p>

                                  </div>
                                  <div className="clear" />
                                </Link>

                                <div className="profile-modal-right">
                                  <div>
                                    <div className="fixed-column-55">
                                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-105">
                                      <p className="description-text-1 bold-text">{value.firstName} {value.lastName}</p>

                                      {(value.headline && value.headline !== '') ? (
                                        <div>
                                          <p className="description-text-3 description-text-color">{value.headline}</p>
                                        </div>
                                      ) : (
                                        <div>

                                          {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                                            <div>

                                              <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                                            </div>
                                          ) : (
                                            <div>
                                              <div>
                                                <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      <p className="description-text-3 description-text-color">c</p>
                                    </div>
                                    <div className="fixed-column-50">
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="top-padding-20">
                                    <Link to={this.state.prefix + '/messaging'} state={{ recipient: value }} className="background-button full-width"><button className="btn btn-squarish full-width">Message</button></Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {/*
                        {(this.state.mentors.length > 3) && (
                          <div>
                            <Link className="background-button" to={this.state.prefix + '/mentors'}>
                              <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                            </Link>
                          </div>
                        )}*/}
                      </div>
                    </div>
                  </div>
                )}

                {(this.state.showAccountabiliyGroupCTA && this.state.noAccountabilityGroups) && (
                  <div>
                    <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-20" : "card-clear-padding min-width-150 max-width-300 pin-right bottom-margin-20"}>
                      <div className="full-width height-5 error-background-color" />
                      <div className="padding-30">
                        <div className="full-width">
                          <div className="relative-column-10">
                            <div className="width-30 height-40" />
                          </div>
                          <div className="relative-column-80">
                            <img src={socialIconGrey} alt="GC" className="image-auto-100 center-horizontally" />
                          </div>
                          <div className="relative-column-10">
                            <p className="error-color description-text-3">NEW</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <p className="heading-text-5 full-width center-text top-padding-20">Create an Accountability Group</p>

                        <p className="top-padding-20 full-width center-text">Accountability groups are 4-6 peers that meet regularly to help one another reach their goals.</p>

                        <div className="row-10 center-text">
                          <button className="btn btn-squarish error-background-color full-width top-margin-20 error-border" onClick={() => this.setState({ modalIsOpen: true, showEditGroup: true, groupToEdit: null })}>
                            <p>Create new group</p>
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-70"}>

                {(this.state.showInvitations) && (
                  <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                    <p className="heading-text-5">Requests & Invitations</p>

                    <div className="spacer" /><div className="spacer" />

                    {(this.state.friendRequests && this.state.friendRequests.length > 0) && (
                      <div>
                        {this.state.friendRequests.map((value2, optionIndex) =>
                          <div key={value2 + optionIndex}>
                            <div className="row-10">
                              <Link to={this.state.prefix + '/profile/' + value2.username} className="background-button standard-color calc-column-offset-160 top-padding-5">
                                <div className="fixed-column-40">
                                  <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-30" />
                                </div>
                                <div className="calc-column-offset-40 left-padding">
                                  <p className="heading-text-5">{value2.firstName} {value2.lastName}</p>
                                  <p className="description-text-2 top-padding-5">{value2.firstName} wants to connect with you</p>
                                </div>
                                <div className="clear" />
                              </Link>

                              <div className="fixed-column-160">
                                <div className="float-left">
                                  <button className="background-button top-padding-5" onClick={() => this.decideOnRequest(optionIndex, false,'connection')}>
                                    <p className="description-text-color">Ignore</p>
                                  </button>
                                </div>
                                <div className="float-left left-padding">
                                  <button className="btn btn-small" onClick={() => this.decideOnRequest(optionIndex, true,'connection')}>Accept</button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.groupRequests && this.state.groupRequests.length > 0) && (
                      <div>
                        {this.state.groupRequests.map((value2, optionIndex) =>
                          <div key={value2 + optionIndex}>
                            <div className="row-10">
                              <Link to={this.state.prefix + '/groups/' + value2._id} className="background-button standard-color calc-column-offset-160 top-padding-5">
                                <div className="fixed-column-40">
                                  <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-30-fit" />
                                </div>
                                <div className="calc-column-offset-40 left-padding">
                                  <p className="heading-text-5">{value2.firstName} {value2.lastName}</p>
                                  <p className="description-text-2 top-padding-5">{value2.firstName} wants to join {value2.groupName}</p>
                                </div>
                                <div className="clear" />
                              </Link>

                              <div className="fixed-column-160">
                                <div className="float-left">
                                  <button className="background-button top-padding-5" onClick={() => this.decideOnRequest(optionIndex, false,'groupRequest')}>
                                    <p className="description-text-color">Ignore</p>
                                  </button>
                                </div>
                                <div className="float-left left-padding">
                                  <button className="btn btn-small" onClick={() => this.decideOnRequest(optionIndex, true,'groupRequest')}>Accept</button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.groupInvites && this.state.groupInvites.length > 0) && (
                      <div>
                        {this.state.groupInvites.map((value2, optionIndex) =>
                          <div key={value2 + optionIndex}>
                            <div className="row-10">
                              <Link to={this.state.prefix + '/groups/' + value2._id} className="background-button standard-color calc-column-offset-160 top-padding-5">
                                <div className="fixed-column-40">
                                  <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-30-fit" />
                                </div>
                                <div className="calc-column-offset-40 left-padding">
                                  <p className="heading-text-5">{value2.groupName}</p>
                                  <p className="description-text-2 top-padding-5">{value2.firstName} {value2.lastName} invited you to join their accountability group: {value2.groupName}</p>
                                </div>
                                <div className="clear" />
                              </Link>

                              <div className="fixed-column-160">
                                <div className="float-left">
                                  <button className="background-button top-padding-5" onClick={() => this.decideOnRequest(optionIndex, false,'groupInvite')}>
                                    <p className="description-text-color">Ignore</p>
                                  </button>
                                </div>
                                <div className="float-left left-padding">
                                  <button className="btn btn-small" onClick={() => this.decideOnRequest(optionIndex, true,'groupInvite')}>Accept</button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}

                  </div>
                )}

                <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                  <div>
                    <div className="calc-column-offset-220">
                      <p className="heading-text-5">{(this.state.employerAccount) ? "Colleagues" : "People to Follow"}</p>
                      <div className="spacer" />
                    </div>
                    <div className="fixed-column-220">
                      <Link to={this.state.prefix + '/browse-profiles'} className="background-button full-width right-text heading-text-5">
                        <div className="float-right top-margin-5">
                          <div className="mini-spacer" />
                          <img src={rightCarrotBlue} alt="GC" className="image-auto-15" />
                        </div>
                        <div className="float-right right-padding">
                          Browse Profiles
                        </div>
                        <div className="clear" />
                      </Link>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  {this.renderItems('people','recommended')}

                  <div className="clear" />

                  <div className="row-10">
                    {(!this.state.remoteAuth && !this.state.employerAccount) && (
                      <div>
                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showInviteMembersWidget: true })}>
                          <img src={addPeopleIconDark} alt="GC" className="image-25-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Invite people"/>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {(this.state.showGoals) && (
                  <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                    <div>
                      <div className="calc-column-offset-220">
                        <p className="heading-text-5">{(this.state.employerAccount) ? "Public Goals" : "Goals to Follow"}</p>
                        <div className="spacer" />
                      </div>
                      <div className="fixed-column-220">
                        <Link to={this.state.prefix + '/browse-goals'} className="background-button full-width right-text heading-text-5">
                          <div className="float-right top-margin-5">
                            <div className="mini-spacer" />
                            <img src={rightCarrotBlue} alt="GC" className="image-auto-15" />
                          </div>
                          <div className="float-right right-padding">
                            Browse Goals
                          </div>
                          <div className="clear" />
                        </Link>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {this.renderItems('goals','recommended')}

                    <div className="clear" />

                  </div>
                )}

                {(!this.state.employerAccount) && (
                  <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                    <div>
                      <div className="calc-column-offset-220">
                        <p className="heading-text-5">Projects to Follow</p>
                        <div className="spacer" />
                      </div>
                      <div className="fixed-column-220">
                        <Link to={this.state.prefix + '/browse-projects'} className="background-button full-width right-text heading-text-5">
                          <div className="float-right top-margin-5">
                            <div className="mini-spacer" />
                            <img src={rightCarrotBlue} alt="GC" className="image-auto-15" />
                          </div>
                          <div className="float-right right-padding">
                            Browse Projects
                          </div>
                          <div className="clear" />
                        </Link>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    {this.renderItems('projects','recommended')}

                    <div className="clear" />

                  </div>
                )}

                <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                  <div>
                    <div className="calc-column-offset-220">
                      <p className="heading-text-5">Groups to Join</p>
                      <div className="spacer" />
                    </div>
                    <div className="fixed-column-220">
                      <Link to={this.state.prefix + '/browse-groups'} className="background-button full-width right-text heading-text-5">
                        <div className="float-right top-margin-5">
                          <div className="mini-spacer" />
                          <img src={rightCarrotBlue} alt="GC" className="image-auto-15" />
                        </div>
                        <div className="float-right right-padding">
                          Browse Groups
                        </div>
                        <div className="clear" />
                      </Link>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  {this.renderItems('groups','recommended')}

                  <div className="clear" />

                </div>

                {(!this.state.employerAccount) && (
                  <div className={(window.innerWidth < 768) ? "card-clear-padding padding-20 bottom-margin-20" : "card-clear-padding padding-20 width-90-percent min-width-300 max-width-800 pin-left bottom-margin-20"}>
                    <div>
                      <div className="calc-column-offset-230">
                        <p className="heading-text-5">Employers to Follow</p>
                        <div className="spacer" />
                      </div>
                      <div className="fixed-column-230">
                        <Link to={this.state.prefix + '/browse-employers'} className="background-button full-width right-text heading-text-5">
                          <div className="float-right top-margin-5">
                            <div className="mini-spacer" />
                            <img src={rightCarrotBlue} alt="GC" className="image-auto-15" />
                          </div>
                          <div className="float-right right-padding">
                            Browse Employers
                          </div>
                          <div className="clear" />
                        </Link>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    {this.renderItems('employers','recommended')}

                    <div className="clear" />

                    <div className="spacer" />

                  </div>
                )}

              </div>
              <div className="clear" />
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >
            <div className="row-20">
              {(this.state.showPeopleYouFollow) && (
                <div>
                  <p className="heading-text-4">Your Connections</p>
                  <div className="spacer" />
                  {this.renderItems('people','existing')}

                  <div className="clear" />
                  <div className="spacer" />
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              )}
              {(this.state.showGoals && this.state.showGoalsYouFollow) && (
                <div>
                  <p className="heading-text-4">Goals You Follow</p>
                  <div className="spacer" />
                  {this.renderItems('goals','existing')}

                  <div className="clear" />
                  <div className="spacer" />
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              )}
              {(this.state.showProjectsYouFollow) && (
                <div>
                  <p className="heading-text-4">Projects You Follow</p>
                  <div className="spacer" />
                  {this.renderItems('projects','existing')}

                  <div className="clear" />
                  <div className="spacer" />
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              )}
              {(this.state.showEmployersYouFollow) && (
                <div>
                  <p className="heading-text-4">Employers You Follow</p>
                  <div className="spacer" />
                  {this.renderItems('employers','existing')}

                  <div className="clear" />
                  <div className="spacer" />
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              )}
              {(this.state.showGroupsYouJoined) && (
                <div>
                  <p className="heading-text-4">Groups You Follow</p>
                  <div className="spacer" />
                  {this.renderItems('groups','existing')}

                  <div className="clear" />
                  <div className="spacer" />
                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
              )}

              {(this.state.showInviteMembersWidget) && (
                <div>
                  <SubInviteMembers orgName={this.state.orgName} closeModal={this.closeModal} />
                </div>
              )}

              {(this.state.showEditGroup) && (
                <div key="showEditGroup" className="full-width padding-20">
                   <SubEditGroup selectedGroup={this.state.groupToEdit} history={this.props.navigate} closeModal={this.closeModal} />
                 </div>
              )}

            </div>
           </Modal>
          </div>
      )
    }
}

export default withRouter(Community);
