import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubBenchmarkDetails from '../components/Subcomponents/BenchmarkDetails';
import withRouter from '../components/Functions/WithRouter';

class BenchmarkDetailsPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('benchmark details just mounted')
      window.scrollTo(0, 0)

      const { accountCode, benchmarkId } = this.props.params
      this.setState({ accountCode, benchmarkId })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.benchmarkId !== prevProps.params.benchmarkId) {
        console.log('change benchmark id')
        this.setState({ benchmarkId: this.props.params.benchmarkId })
      }
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"benchmarkPage"} />
              <SubBenchmarkDetails history={this.props.navigate} accountCode={this.state.accountCode} benchmarkId={this.state.benchmarkId} />

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(BenchmarkDetailsPage);
