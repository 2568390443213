import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSideNav from '../Common/SideNav';
import SubEndorsements from '../Subcomponents/Endorsements';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

class OrgEndorsements extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ org, emailId: email, username, orgFocus, orgLogo })
      }
    }

    render() {

      return (
          <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

              {(!toggleVars.hideAdminSideNav) && (
                <SubSideNav selectedComponent={"OrgEndorsements"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <SubEndorsements history={this.props.navigate} activeOrg={this.state.org} />

              <div className="clear" />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(OrgEndorsements)
