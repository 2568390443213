import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import EditPosting from '../Subcomponents/EditPosting';
import withRouter from '../Functions/WithRouter';

class OrgAddJob extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let { selectedOpportunity, jobs, duplicate } = this.props.location.state;

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      const path = window.location.pathname

      this.setState({
          emailId: email, username,cuFirstName, path, cuLastName, org, orgLogo,
          orgFocus, jobs, selectedOpportunity, duplicate
      });
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <div>
            <EditPosting org={this.state.org} selectedOpportunity={this.state.selectedOpportunity} editMode={true} jobs={this.state.jobs} roleName="Admin" path={this.state.path} history={this.props.navigate} duplicate={this.state.duplicate} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgAddJob)
