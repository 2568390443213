import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditResource from '../Subcomponents/EditResource';
import withRouter from '../Functions/WithRouter';

class OrgEditResource extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');
      const orgFocus = localStorage.getItem('orgFocus');

      let resourceId = null
      let org = null
      if (this.props.params && this.props.params.id) {
        resourceId = this.props.params.id
        org = this.props.params.org
      }

      let selectedTemplate = null
      let duplicate = null
      let passedType = null
      if (this.props.location && this.props.location.state) {
        selectedTemplate = this.props.location.state.selectedTemplate
        duplicate = this.props.location.state.duplicate
        passedType = this.props.location.state.passedType
      }

      this.setState({ activeOrg, emailId: username, cuFirstName, cuLastName, org, activeOrg: org, orgLogo, orgFocus,
        resourceId, selectedTemplate, duplicate, passedType
      })
    }

    render() {
      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <SubEditResource activeOrg={this.state.activeOrg} resourceId={this.state.resourceId} selectedTemplate={this.state.selectedTemplate} duplicate={this.state.duplicate} passedType={this.state.passedType} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(OrgEditResource)
