import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSessions from './Subcomponents/Sessions';
import {convertDateToString} from './Functions/ConvertDateToString';
import withRouter from './Functions/WithRouter';

class AdvSessions extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo })

    }

    render() {

        return (
            <div>
                <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} />

                <SubSessions email={this.state.emailId} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} />

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(AdvSessions)
