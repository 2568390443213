import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubPathwayDetails from './Subcomponents/PathwayDetails';
import withRouter from './Functions/WithRouter';

class PathwayDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('pathways page just mounted')
      window.scrollTo(0, 0)

      const { accountCode, pathwayId } = this.props.params

      let email = localStorage.getItem('email');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, orgLogo, accountCode, pathwayId })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.pathwayId !== prevProps.params.pathwayId) {
        console.log('change pathway id')
        this.setState({ pathwayId: this.props.params.pathwayId })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <SubPathwayDetails history={this.props.navigate} accountCode={this.state.accountCode} pathwayId={this.state.pathwayId} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(PathwayDetails);
