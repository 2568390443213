import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import withRouter from '../Functions/WithRouter';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const resourcesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resources-icon-dark.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";

class Resources extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showResources: true,
          resources: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const orgFocus = localStorage.getItem('orgFocus');

      let prefix = '/organizations/' + activeOrg
      if (window.location.pathname.startsWith('/advisor')) {
        prefix = '/advisor'
      }

      const menuOptions = [
        { icon: exchangeIconDark, title: 'Duplicate resource', shorthand: 'duplicate' },
        { icon: editIconDark, title: 'Edit resource', shorthand: 'edit' },
        { icon: deleteIconDark, title: 'Delete resource', shorthand: 'delete' },
      ]

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, prefix, menuOptions })

      if (this.state.showResources) {

        const orgCodes = [activeOrg,'general']
        const self = this
        function pullResources(resources) {
          console.log('pullResources called')

          Axios.get('/api/resources', { params: { orgCodes, prioritizeRecent: true } })
          .then((response) => {
            console.log('Resources query attempted', response.data);

            if (response.data.success && response.data.resources.length > 0) {
              console.log('resource query worked')

              resources = response.data.resources.concat(resources)
              self.setState({ resources })

            } else {
              console.log('resources query did not work', response.data.message)
              self.setState({ resources })
            }
          }).catch((error) => {
              console.log('Resources query did not work for some reason', error);
          });
        }

        Axios.get('/api/customassessments', { params: { orgCodes, prioritizeRecent: true, excludeAppOnboarding: true } })
        .then((response) => {
          console.log('Custom assessment query attempted', response.data);

          if (response.data.success && response.data.assessments.length > 0) {
            console.log('custom assessment query worked')

            let resources = []
            for (let i = 1; i <= response.data.assessments.length; i++) {
              let assessment = response.data.assessments[i - 1]
              if (!assessment.type) {
                assessment['type'] = 'Survey'
              }
              resources.push(assessment)
            }
            pullResources(resources)

          } else {
            console.log('custom assessment query did not work', response.data.message)
            pullResources([])
          }

        }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
        });
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showAddItem: false })
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called', e, index)

      e.stopPropagation()
      e.preventDefault()

      let resources = this.state.resources
      if (this.state.resources[index].showMenu) {
        resources[index]['showMenu'] = false
      } else {
        resources[index]['showMenu'] = true
      }

      this.setState({ resources })

    }

    menuButtonClicked(action, index, e) {
      console.log('menuButtonClicked called', action, index, e)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'duplicate') {

        let pathname = this.state.prefix + '/resources/add'
        let selectedTemplate = this.state.resources[index]
        this.props.navigate(pathname, { state: { selectedTemplate, duplicate: true }})
      } else if (action === 'edit') {
        let pathname = this.state.prefix + '/resources/' + this.state.resources[index]._id
        this.props.navigate(pathname, { state: { selectedTemplate: this.state.resources[index] }})

      } else if (action === 'delete') {
        let resources = this.state.resources
        if (resources[index].confirmDelete) {
          resources[index]['confirmDelete'] = false
        } else {
          resources[index]['confirmDelete'] = true
        }
        this.setState({ resources })

      }
    }

    deleteItem(index, e) {
      console.log('deleteItem called', index, e)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      let resources = this.state.resources
      const _id = resources[index]._id

      if (resources[index].type === 'Quiz' || resources[index].type === 'Script' || resources[index].type === 'Survey') {
        console.log('delete from assessments')

        Axios.delete('/api/customassessments/' + _id)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Assessment delete worked')

            resources.splice(index,1)
            this.setState({ resources, isSaving: false })

          } else {
            console.error('there was an error deleting the assessment');

            resources[index]['errorMessage'] = response.data.message
            this.setState({ resources, isSaving: false })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            resources[index]['errorMessage'] = error.toString()
            this.setState({ resources, isSaving: false })
        });
      } else {
        console.log('delete from actual resources')

        Axios.delete('/api/resources/' + _id)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Resource delete worked')

            resources.splice(index,1)
            this.setState({ resources, isSaving: false })

          } else {
            console.error('there was an error deleting the resource');

            resources[index]['errorMessage'] = response.data.message
            this.setState({ resources, isSaving: false })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            resources[index]['errorMessage'] = error.toString()
            this.setState({ resources, isSaving: false })
        });
      }
    }

    render() {

      return (
          <div>
            <div className="card bottom-margin-30">
              <div className="calc-column-offset-50">
                <p className="heading-text-2">{(this.state.resources) && " " + this.state.resources.length + " "}Resources Added</p>
              </div>
              {(this.state.showResources) && (
                <div className="fixed-column-50">
                  <button className="background-button clear-margin" onClick={() => this.props.navigate(this.state.prefix + '/resources/add')}>
                    <img src={addIcon} alt="GC" className="image-25-fit" />
                  </button>
                </div>
              )}
              <div className="clear" />
              <p className="top-margin standard-color">Import and create resources that you can assign to students/career-seekers.</p>
            </div>
            {(this.state.showResources) ? (
              <div>
                {(this.state.resources) && (
                  <div className="card center-horizontally">
                    {this.state.resources.map((item, index) =>
                      <div key={"resource|" + index}>
                        {console.log('r:', index + 1, item)}
                        <div className="row-10">
                          <Link className="secret-link full-width" to={this.state.prefix + '/resources/' + item._id} state={{ selectedTemplate: item }}>
                            <span className="calc-column-offset-130">
                              <div>
                                <p className="heading-text-6">{item.title}</p>
                                {(item.questions && item.questions.length > 0 && (item.type === 'Quiz' || item.type === 'Script' || item.type === 'Survey')) ? (
                                  <p className="profile-descriptor top-margin-5">{item.type} | {item.questions ? item.questions.length : "0"} Questions</p>
                                ) : (
                                  <p className="profile-descriptor top-margin-5">{item.type}</p>
                                )}
                              </div>
                            </span>
                            {(item.orgCode === 'general' && this.state.activeOrg !== 'guidedcompass') ? (
                              <div className="fixed-column-40 top-margin">
                                <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="noEdit" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                              </div>
                            ) : (
                              <div className="fixed-column-40 height-30" />
                            )}
                            <div className="relative-position fixed-column-50 left-padding top-margin-5">
                              <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                                <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                              </button>

                              {(item.showMenu) && (
                                <div className="menu-bottom-right top-margin">
                                  {this.state.menuOptions.map((item2,optionIndex2) =>
                                    <div key={item2.title}>
                                      {((item2.title.includes('Delete') && item.orgCode === this.state.activeOrg) || !item2.title.includes('Delete')) && (
                                        <div>
                                          {(item2.title === 'Delete resource' && item.confirmDelete) ? (
                                            <div>
                                              <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                              <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={(e) => this.deleteItem(index,e)}>Confirm</button>
                                              <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(item2.shorthand,index,e)}>Cancel</button>
                                            </div>
                                          ) : (
                                            <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(item2.shorthand,index,e)}>
                                              <div className="fixed-column-25">
                                                <img src={item2.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                              </div>
                                              <div className="calc-column-offset-25">
                                                <p className={(item2.title === 'Delete resource') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item2.title}</p>
                                              </div>
                                              <div className="clear" />
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="fixed-column-40 top-margin">
                              <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                            </div>
                            <div className="clear" />
                          </Link>



                          {(item.successMessage) && (
                            <div className="row-5 left-padding">
                              <p className="description-text-2 cta-color full-width">{item.successMessage}</p>
                            </div>
                          )}

                          <ReactTooltip id="noEdit" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="card flex-container flex-center padding-50">
                  <div className="row-30">
                    <div className="width-80 height-80 padding-25 standard-border circle-radius center-horizontally">
                      <img src={resourcesIconDark} alt="GC" className="image-30-fit"/>
                    </div>

                    <p className="center-text heading-text-5 top-margin-30">Upgrade to Upload Resources</p>
                    <p className="center-text description-text-color horizontal-padding-3 top-margin-20">This account does not have the ability to upload resources.</p>
                    <p className="center-text description-text-color horizontal-padding-3 top-margin-20">Resources are limited to surveys, quizzes, and A.I. chatbot scripts. This page will include pages, assignments, rubrics, links, videos, badges, and learning objectives if upgraded. Email help@guidedcompass.com to subscribe @ $10 per student.</p>

                    <div className="top-margin-30 center-text">
                      <button className="btn btn-primary" onClick={() => this.props.navigate('/advisor/quizzes')}>Add Quizzes Instead</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             >
              {(this.state.showAddItem) && (
                <div key="addItem" className="full-width padding-20">

                </div>
              )}

            </Modal>
          </div>

      )
    }
}

export default withRouter(Resources);
