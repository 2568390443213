import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';

class RenderCoverLetter extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderReferrals', this.props, prevProps)

      // if (this.props.activeOrg !== prevProps.activeOrg) {
      //   this.retrieveData()
      // }
    }

    retrieveData() {
      console.log('retrieveData called in RenderReferrals')

      // this.setState({ activeOrg, favorites, emailId, orgName, orgContactEmail })

    }

    render() {

      const showIdealCandidate = this.props.showIdealCandidate
      const layoutSelected = this.props.layoutSelected
      const sideSectionClass = this.props.sideSectionClass
      const profilePicImage = this.props.profilePicImage
      const profilePicPath = this.props.profilePicPath
      const coverLetterContent = this.props.coverLetterContent
      const pageCount = this.props.pageCount
      const selectedSections = this.props.selectedSections
      const sectionHeaderDivClass = this.props.sectionHeaderDivClass
      const sectionHeaderTextClass = this.props.sectionHeaderTextClass
      const mainSectionClass = this.props.mainSectionClass
      const headerClass = this.props.headerClass
      const projectsHeight = this.props.projectsHeight
      const skillsHeight = this.props.skillsHeight
      const knowledgeHeight = this.props.knowledgeHeight
      const extrasHeight = this.props.extrasHeight

      const disableEditing = this.props.disableEditing
      const hideIfEmpty = this.props.hideIfEmpty

      return (
        <div className="description-text-1">
          <div className={"paper-container"}>
            <div id="printPage1" className="full-space padding-40 max-text-lines">
              <div className={mainSectionClass}>
                <div className={headerClass}>
                  <div className="top-margin-20">
                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editCurrentDate: true })}>
                      <p>{(coverLetterContent.currentDate) ? convertDateToString(new Date(coverLetterContent.currentDate),'date-formal') : ""}</p>
                    </button>
                  </div>

                  <div className="top-margin-50">
                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editRecipientInfo: true })}>
                      <p>{coverLetterContent.recipientFirstName} {coverLetterContent.recipientLastName}</p>
                      <p>{coverLetterContent.recipientOrg}</p>
                      <p>{coverLetterContent.addressLine1}</p>
                      {(coverLetterContent.addressLine2) && (
                        <p>{converLetterContent.addressLine2}</p>
                      )}
                      <p>{coverLetterContent.city} {coverLetterContent.state}, {coverLetterContent.zipCode}</p>

                      <div className="top-margin-50">
                        <p>Dear {coverLetterContent.recipientFirstName},</p>
                      </div>
                    </button>
                  </div>

                  <div className="top-margin-30">
                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editTextBody: true })}>
                      <p className={(coverLetterContent.textBody === "Write the body of the cover letter here...") ? "description-text-color" : ""}>{coverLetterContent.textBody}</p>
                    </button>
                  </div>

                  <div className="top-margin-50">
                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSignatureInfo: true })}>
                      <div>
                        <p>Sincerely,</p>
                        <p className="top-margin">{coverLetterContent.senderFirstName} {coverLetterContent.senderLastName}</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>

          {(pageCount > 1) && (
            <div>
              <div className="paper-container">
                <div id="printPage2" className="full-space padding-40 max-text-lines">


                </div>
              </div>
            </div>
          )}
          <div className="clear" />
        </div>
      )
    }
}

export default withRouter(RenderCoverLetter);
