import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
// import io from 'socket.io-client';
// import * as io from 'socket.io-client'
// import socketio from "socket.io-client";
// import * as io from 'socket.io-client';
// const SocketContext = React.createContext()
import { socket } from '../Vendors/Socket';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';
import SubRenderChat from '../Common/RenderChat';

const editIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png";
const commentIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment-icon-grey.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

// const socket = io("http://localhost:4000");
// const socket = socketio.connect('http://localhost:4000');
// const socket = io.connect('http://' + window.location.pathname + ':4000');
// const socket = io.connect('http://localhost:443');
// let socketURL = 'https://guidedcompass.com'
// if (window.location.hostname === 'localhost') {
//   socketURL = 'http://localhost:443'
// }
// const socket = io.connect(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });
// console.log('show me the socketURL: ', socketURL)

class Messaging extends Component {
    constructor(props) {
      super(props)

      this.state = {
        useSubRenderChat: true,
        // useRealTimeChat: true,
        // showMultipleRecipients: true,
        // scrollOnAllUpdates: false,
        resLimit: 500,
        recent: true,

        prefix: '/app',
        newMessage: true,
        viewIndex: 0,
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.filterResults = this.filterResults.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.clearUnreadMessages = this.clearUnreadMessages.bind(this)
      // this.renderMessage = this.renderMessage.bind(this)

      this.recipientClicked = this.recipientClicked.bind(this)
      this.removeRecipient = this.removeRecipient.bind(this)

      // this.sendMessage = this.sendMessage.bind(this)
      this.selectInbox = this.selectInbox.bind(this)
      // this.updateUI = this.updateUI.bind(this)
      this.joinRoom = this.joinRoom.bind(this)
      this.renderMultiMemberHeader = this.renderMultiMemberHeader.bind(this)

      this.updateInboxes = this.updateInboxes.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('didMount in submessaging')

      this.retrieveData()

      // if (!this.state.useSubRenderChat) {
      //   socket.on('receive_message', (data) => {
      //     console.log('received message from socket server: ', data);
      //
      //     if (data.room === this.state.room) {
      //       this.updateUI(data)
      //     }
      //   });
      //   socket.on('error_sending_message', (data) => {
      //     console.log('error sending message: ', data);
      //
      //     this.setState({ errorMessage: data.message, isSaving: false })
      //
      //   });
      // }
    }

    componentWillUnmount() {
      console.log('componentWillUnmount called in submessaging')

      // if (!this.state.useSubRenderChat) {
      //   // Remove event listener on component unmount
      //   // return () => socket.off('receive_message');
      //   // socket.off('receive_message')
      //   // socket.emit("disconnect", { username: this.state.username, room: this.state.room })
      //   socket.off('receive_message');
      //   socket.off('error_sending_message');
      // }
    }

    componentDidUpdate(prevProps) {
      // console.log('componentDidUpdate called in messaging')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.groupPost !== prevProps.groupPost || this.props.newMessage !== prevProps.newMessage) {
        this.retrieveData()
      } else if (this.props.generalPost !== prevProps.generalPost || this.props.selectedGoal !== prevProps.selectedGoal) {
        this.retrieveData()
      } else if (this.props.recipient !== prevProps.recipient) {
        this.retrieveData()
      }

      // if (this.state.scrollOnAllUpdates) {
      //   this.scrollToBottom();
      // }
    }

    retrieveData() {
      console.log('retrieveData called in sub messaging', this.props)

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let pictureURL = localStorage.getItem('pictureURL');

      if (this.props.activeOrg) {
        activeOrg = this.props.activeOrg
      }

      const groupPost = this.props.groupPost
      const generalPost = this.props.generalPost
      let passedNewMessage = this.props.newMessage
      const passedRecipient = this.props.recipient
      const passedRecipients = this.props.recipients
      const selectedGoal = this.props.selectedGoal

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      } else if (window.location.pathname.includes('/organizations')) {
        prefix = '/organizations/' + activeOrg
      }

      let messageDraft = null
      if (groupPost) {
        console.log('in group post')
        if (groupPost.groupName) {
          messageDraft = 'Check out the latest post in ' + groupPost.groupName + ': https://www.guidedcompass.com/' + prefix + '/groups/' + groupPost.groupId
        } else {
          messageDraft = 'Check out the latest post in https://www.guidedcompass.com/' + prefix + '/groups/' + groupPost.groupId
        }
      }
      if (generalPost) {
        console.log('in general post')
        messageDraft = 'Check out this post: https://www.guidedcompass.com/' + prefix + '/social-posts/' + generalPost._id
      }
      if (selectedGoal) {
        const goalLink = window.location.protocol + "//" + window.location.host + '/' + prefix + '/goals/' + selectedGoal._id
        messageDraft = 'Any suggestions on how to achieve my new SMART goal: "' + selectedGoal.title + '"? Here are the details: ' + goalLink
      }

      // if (passedRecipient && !this.state.showMultipleRecipients) {
      //   const searchString = passedRecipient.firstName + ' ' + passedRecipient.lastName
      //   this.setState({
      //     emailId: email, username, cuFirstName, cuLastName, roleName, activeOrg, pictureURL, groupPost, newMessage: true,
      //     messageDraft, searchString, recipient: passedRecipient, viewIndex: 1, prefix
      //   })
      // } else {
      //   this.setState({
      //     emailId: email, username, cuFirstName, cuLastName, roleName, activeOrg, pictureURL, groupPost,
      //     newMessage: passedNewMessage, messageDraft, prefix, recipients: passedRecipients
      //   })
      // }

      this.setState({
        emailId: email, username, cuFirstName, cuLastName, roleName, activeOrg, pictureURL, groupPost,
        newMessage: passedNewMessage, messageDraft, prefix, recipients: passedRecipients
      })

      Axios.get('/api/inboxes', { params: { emailId: email } })
      .then((response) => {
        console.log('Inboxes query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved inboxes')

           const inboxes = response.data.inboxes
           if (inboxes && inboxes.length > 0) {

             if (groupPost || generalPost || passedNewMessage || selectedGoal) {
               console.log('group post, general post, or new message')
               this.setState({ inboxes })

             } else {
               console.log('checkers')

               let room = '12345'
               const resLimit = this.state.resLimit
               const recent = this.state.recent

               // if (passedRecipient && !this.state.showMultipleRecipients) {
               //
               //   let inboxSelected = null
               //   let members = []
               //   let recipient = null
               //   for (let i = 1; i <= inboxes.length; i++) {
               //     for (let j = 1; j <= inboxes[i - 1].members.length; j++) {
               //       if (passedRecipient.email === inboxes[i - 1].members[j - 1].email) {
               //         inboxSelected = i - 1
               //         members = inboxes[i - 1].members
               //         recipient = {
               //           firstName: members[j - 1].firstName,
               //           lastName: members[j - 1].lastName,
               //           email: members[j - 1].email,
               //           pictureURL: members[j - 1].pictureURL,
               //           username: members[j - 1].username,
               //           roleName: members[j - 1].roleName,
               //           accountCode: members[j - 1].accountCode,
               //           notificationPreferences: members[j - 1].notificationPreferences
               //         }
               //
               //         if (inboxes[i - 1].unreadCount) {
               //           this.clearUnreadMessages(inboxes, inboxes[i - 1], i - 1)
               //         } else {
               //           this.setState({ inboxes })
               //         }
               //       }
               //     }
               //   }
               //
               //   if (inboxSelected || inboxSelected === 0) {
               //     if (inboxes[inboxSelected].members && inboxes[inboxSelected].members.length === 2) {
               //       let memberEmails = [inboxes[inboxSelected].members[0].email,inboxes[inboxSelected].members[1].email]
               //       memberEmails.sort()
               //       room =  memberEmails.join("|")
               //     }
               //   }
               //
               //   this.setState({ room })
               //   this.joinRoom(username, room)
               //
               //   if (recipient) {
               //     console.log('current recipient sf')
               //
               //     Axios.get('/api/messages', { params: { emailId: email, members, recipientCount: 1, resLimit, recent, excludeBot: true } })
               //     .then((response) => {
               //       console.log('Messages query attempted', response.data);
               //
               //        if (response.data.success) {
               //          console.log('successfully retrieved messages')
               //
               //          const messages = response.data.messages
               //
               //          this.setState({ inboxSelected, recipient, messages, newMessage: false })
               //
               //          // if (!this.state.scrollOnAllUpdates) {
               //          //   this.scrollToBottom()
               //          // }
               //
               //        } else {
               //          console.log('no messages found', response.data.message)
               //          this.setState({ errorMessage: response.data.message })
               //        }
               //
               //     }).catch((error) => {
               //        console.log('Message query did not work', error);
               //     });
               //
               //   } else {
               //     console.log('attach passed recipient')
               //     const searchString = passedRecipient.firstName + ' ' + passedRecipient.lastName
               //     this.setState({ newMessage: true, searchString, recipient: passedRecipient, inboxes })
               //   }
               //
               // } else {
                 // console.log('no recipient')

                 const inboxSelected = 0
                 const members = inboxes[inboxSelected].members
                 let recipient = null
                 let recipients = []

                 if (inboxSelected || inboxSelected === 0) {
                   if (inboxes[inboxSelected].members) {
                     // let memberEmails = [inboxes[inboxSelected].members[0].email,inboxes[inboxSelected].members[1].email]
                     // memberEmails.sort()
                     // room =  memberEmails.join("|")
                     let memberEmails = []
                     for (let i = 1; i <= inboxes[inboxSelected].members.length; i++) {
                       // room = room + this.state.inboxes[index].members[i - 1].email
                       // if (i < this.state.inboxes[index].members.length) {
                       //   room = room + "|"
                       // }
                       memberEmails.push(inboxes[inboxSelected].members[i - 1].email)
                     }
                     memberEmails.sort()
                     room =  memberEmails.join("|")
                   }
                 }
                 // console.log('gimme members: ', members, inboxes[inboxSelected])
                 this.setState({ room })
                 this.joinRoom(username, room)

                 for (let i = 1; i <= members.length; i++) {
                   if (members[i - 1].email !== email) {
                     recipient = {
                       firstName: members[i - 1].firstName, lastName: members[i - 1].lastName,
                       email: members[i - 1].email, pictureURL: members[i - 1].pictureURL, username: members[i - 1].username,
                       roleName: members[i - 1].roleName, accountCode: members[i - 1].accountCode,
                       notificationPreferences: members[i - 1].notificationPreferences
                     }
                     recipients.push(recipient)
                   }
                 }
                 // console.log('unreadCount...', inboxes[inboxSelected].unreadCount)
                 this.clearUnreadMessages(inboxes,inboxes[inboxSelected], 0)
                 if (inboxes[inboxSelected].unreadCount) {
                   // this.clearUnreadMessages(inboxes,inboxes[inboxSelected], 0)
                 } else {
                   this.setState({ inboxes })
                 }

                 const recipientCount = recipients.length
                 // const myParams = { emailId: email, members, recipientCount, resLimit, recent, excludeBot: true }
                 const myParams = { emailId: email, members, recipientCount, resLimit, recent }
                 Axios.get('/api/messages', { params: myParams })
                 .then((response) => {
                   console.log('Messages query attempted', response.data);

                    if (response.data.success) {
                      console.log('successfully retrieved messages')

                      const messages = response.data.messages
                      let newMessage = false

                      this.setState({ inboxSelected, recipient, recipients, messages, newMessage })

                      // if (!this.state.scrollOnAllUpdates) {
                      //   this.scrollToBottom()
                      // }

                    } else {
                      console.log('no messages found', response.data.message)
                      this.setState({ errorMessage: response.data.message })
                    }

                 }).catch((error) => {
                    console.log('Message query did not work', error);
                 });
               // }
             }
           }

         } else {
           console.log('no inboxes found', response.data.message)
           // this.setState({ errorMessage: response.data.message })

         }

      }).catch((error) => {
         console.log('Inboxes query did not work', error);
      });

      Axios.get('/api/users/profile/details', { params: { email } })
      .then((response) => {
        console.log('Profile query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')

           const pictureURL = response.data.user.pictureURL
           const headline = response.data.user.headline
           let schoolName = response.data.user.school
           if (response.data.user.education && response.data.user.education.length > 0) {
             schoolName = response.data.user.education[0].name
             if (response.data.user.education.length > 1) {
               for (let i = 1; i <= response.data.user.education.length; i++) {
                 if (response.data.user.education[i - 1] && response.data.user.education[i - 1].isContinual) {
                   schoolName = response.data.user.education[i - 1].name
                 }
               }
             }
           }
           const jobTitle = response.data.user.jobTitle
           const employerName = response.data.user.employerName
           const notificationPreferences = response.data.user.notificationPreferences

           this.setState({ pictureURL, headline, schoolName, jobTitle, employerName, notificationPreferences })


         } else {
           console.log('no profile data found', response.data.message)
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }

    joinRoom(username,room) {
      console.log('joinRoom called', username, room)

      // socket.emit("disconnect", { username: this.state.username, room: this.state.room })
      socket.emit('join_room', { username, room })

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, recipient: null, animating: true })
        this.filterResults(event.target.value, null, null, true)

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, filterName, filterValue, search) {
      console.log('filterResults called', searchString, filterName, filterValue, search)

      let roleNames = ['Student','Career-Seeker','Mentor']
      if (this.state.userType === 'Mentors') {
        roleNames = ['Mentor']
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.activeOrg
        const excludeCurrentUser = true
        const emailId = self.state.emailId
        const limit = 25
        Axios.get('/api/members/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId, limit } })
        .then((response) => {
          console.log('Opportunity search query attempted', response.data);

            if (response.data.success) {
              console.log('opportunity search query worked')

              let memberOptions = response.data.members
              self.setState({ memberOptions, filteredResults: true, animating: false })

            } else {
              console.log('opportunity search query did not work', response.data.message)

              let memberOptions = []
              self.setState({ memberOptions, animating: false })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'member') {

        let searchString = passedItem.firstName + ' ' + passedItem.lastName
        const memberOptions = null

        let recipients = this.state.recipients
        console.log('list recipients: ', recipients)

        searchString = ''
        if (recipients && recipients.length > 0) {
          // recipients[this.state.selectedIndex] = passedItem
          if (recipients.some(r => r.email === passedItem.email)) {

          } else {
            console.log('list recipients 1.5: ', recipients)
            recipients.push(passedItem)
          }
        } else {
          recipients = [passedItem]
        }

        console.log('list recipients one mo gain: ', recipients)

        this.setState({ recipient: passedItem, recipients, searchString, memberOptions })

        this.selectInbox(null, null, recipients, this.state.newMessage)

      }
    }

    // sendMessage() {
    //   console.log('sendMessage called')
    //
    //   this.setState({ isSaving: true, errorMessage: null, successMessage: null })
    //
    //   if (!this.state.recipient && !this.state.showMultipleRecipients) {
    //     this.setState({ errorMessage: 'Please add a recipient', isSaving: false })
    //   } else if (!this.state.recipients || this.state.recipients.length === 0) {
    //     this.setState({ errorMessage: 'Please add at least one recipient', isSaving: false })
    //   } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
    //     this.setState({ errorMessage: 'There was an error retrieving your first name. Please sign in.', isSaving: false })
    //   } else if (!this.state.cuLastName || this.state.cuLastName === '') {
    //     this.setState({ errorMessage: 'There was an error retrieving your last name. Please sign in.', isSaving: false })
    //   } else if (!this.state.emailId || this.state.emailId === '') {
    //     this.setState({ errorMessage: 'There was an error retrieving your email. Please sign in.', isSaving: false })
    //   } else {
    //
    //     const emailId = this.state.emailId
    //     const message = this.state.messageDraft
    //
    //     const senderFirstName = this.state.cuFirstName
    //     const senderLastName = this.state.cuLastName
    //     const senderEmail = this.state.emailId
    //
    //     const senderPictureURL = this.state.pictureURL
    //     const senderHeadline = this.state.headline
    //     const senderUsername = this.state.username
    //     const senderSchoolName = this.state.schoolName
    //     const senderJobTitle = this.state.jobTitle
    //     const senderEmployerName = this.state.employerName
    //     const senderRoleName = this.state.roleName
    //
    //     const orgCode = this.state.activeOrg
    //     const accountCode = this.state.accountCode
    //
    //     let recipients = []
    //     if (this.state.showMultipleRecipients) {
    //       recipients = this.state.recipients
    //     } else {
    //       recipients.push({
    //         firstName: this.state.recipient.firstName, lastName: this.state.recipient.lastName, email: this.state.recipient.email, pictureURL: this.state.recipient.pictureURL, username: this.state.recipient.username,
    //         roleName: this.state.recipient.roleName, notificationPreferences: this.state.recipient.notificationPreferences
    //       })
    //     }
    //
    //     let members = [{ firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId, pictureURL: this.state.pictureURL, username: this.state.username, roleName: this.state.roleName,
    //       notificationPreferences: this.state.notificationPreferences
    //     }]
    //     members = members.concat(recipients)
    //
    //     let messageThreadURL = null
    //     let messageThreadURLs = []
    //     if (this.state.showMultipleRecipients) {
    //       for (let i = 1; i <= recipients.length; i++) {
    //         if (!recipients[i - 1].roleName || recipients[i - 1].roleName === 'Student' || recipients[i - 1].roleName === 'Career-Seeker') {
    //           messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/app/messaging')
    //         } else if (recipients[i - 1].roleName === 'Advisor' || recipients[i - 1].roleName === 'Teacher' || recipients[i - 1].roleName === 'Mentor') {
    //           messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/advisor/messaging')
    //         } else if (recipients[i - 1].roleName === 'WBLC' || recipients[i - 1].roleName === 'Work-Based Learning Coordinator' || recipients[i - 1].roleName === 'Admin') {
    //           messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging')
    //         } else if (recipients[i - 1].roleName === 'Employer' || recipients[i - 1].roleName === 'Employer Representative') {
    //           messageThreadURLs.push(window.location.protocol + "//" + window.location.host + '/employers/' + recipients[i - 1].accountCode + '/messaging')
    //         }
    //       }
    //     } else {
    //       if (!this.state.recipient.roleName || this.state.recipient.roleName === 'Student' || this.state.recipient.roleName === 'Career-Seeker') {
    //         messageThreadURL = window.location.protocol + "//" + window.location.host + '/app/messaging'
    //       } else if (this.state.recipient.roleName === 'Advisor' || this.state.recipient.roleName === 'Teacher' || this.state.recipient.roleName === 'Mentor') {
    //         messageThreadURL = window.location.protocol + "//" + window.location.host + '/advisor/messaging'
    //       } else if (this.state.recipient.roleName === 'WBLC' || this.state.recipient.roleName === 'Work-Based Learning Coordinator' || this.state.recipient.roleName === 'Admin') {
    //         messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
    //       } else if (this.state.recipient.roleName === 'Employer' || this.state.recipient.roleName === 'Employer Representative') {
    //         messageThreadURL = window.location.protocol + "//" + window.location.host + '/employers/' + this.state.recipient.accountCode + '/messaging'
    //       }
    //     }
    //
    //     const room = this.state.room
    //
    //     const createdAt = new Date()
    //     const updatedAt = new Date()
    //
    //     const self = this
    //     async function actuallySendMessage(unsubscribed) {
    //       console.log('actuallySendMessage called')
    //
    //       const messageData = {
    //         emailId, message, senderFirstName, senderLastName, senderEmail,
    //         senderPictureURL, senderHeadline, senderUsername, senderSchoolName,
    //         senderJobTitle, senderEmployerName, senderRoleName,
    //         orgCode, accountCode, recipients, members, messageThreadURL, messageThreadURLs, unsubscribed,
    //         room, createdAt, updatedAt
    //       }
    //       console.log('inbox order: ', self.state.inboxes)
    //
    //       if (self.state.useRealTimeChat) {
    //         await socket.emit("send_message", messageData)
    //       } else {
    //         Axios.post('/api/messages/send', messageData)
    //         .then((response) => {
    //           console.log('attempting to save addition to groups', message, senderEmail)
    //
    //           if (response.data.success) {
    //             console.log('saved addition to groups', response.data)
    //
    //             self.updateUI(response.data)
    //
    //           } else {
    //             console.log('did not save successfully')
    //             self.setState({ errorMessage: 'returned error:' + response.data.message, isSaving: false })
    //           }
    //         }).catch((error) => {
    //             console.log('save did not work', error);
    //             self.setState({ errorMessage: 'there was an error sending the message', isSaving: false})
    //         });
    //       }
    //     }
    //
    //     if (this.state.showMultipleRecipients) {
    //       actuallySendMessage(null)
    //     } else {
    //       // check/update notification preferences assuming one recipient
    //       Axios.get('/api/users/profile/details/' + recipients[0].email, { params: { emailId: recipients[0].email } })
    //        .then((response) => {
    //          console.log('query for profile data worked');
    //
    //          if (response.data.success) {
    //
    //            console.log('profile data received', response.data)
    //
    //            const notificationPreferences = response.data.user.notificationPreferences
    //            let unsubscribed = null
    //            if (notificationPreferences && notificationPreferences.length > 0) {
    //              for (let i = 1; i <= notificationPreferences.length; i++) {
    //                if (notificationPreferences[i - 1].slug === 'new-suggestions' && notificationPreferences[i - 1].enabled === false) {
    //                  unsubscribed = true
    //                }
    //              }
    //            }
    //
    //            recipients[0]['notificationPreferences'] = notificationPreferences
    //            actuallySendMessage(unsubscribed, recipients)
    //
    //          } else {
    //            console.log('error response', response.data)
    //
    //            actuallySendMessage(null)
    //          }
    //
    //       }).catch((error) => {
    //            console.log('query for profile info did not work', error);
    //       })
    //     }
    //   }
    // }

    // updateUI(data) {
    //   console.log('updatedUI called: ', data, this.state.inboxSelected, this.state.inboxes)
    //
    //   const inboxObject = data.inboxObject
    //   let inboxes = this.state.inboxes
    //   if (inboxObject) {
    //     if (inboxes) {
    //       inboxes.unshift(inboxObject)
    //     } else {
    //       inboxes = [inboxObject]
    //     }
    //   } else {
    //     inboxes[this.state.inboxSelected]['lastMessage'] = data.messageObject.message
    //     inboxes[this.state.inboxSelected]['senderEmail'] = this.state.emailId
    //
    //     //reorder inbox to front
    //     const tempInbox = inboxes[this.state.inboxSelected]
    //     inboxes.splice(this.state.inboxSelected,1)
    //     inboxes.unshift(tempInbox)
    //   }
    //
    //   // console.log('new inboxes: ', inboxes,this.state.inboxSelected)
    //
    //   const inboxSelected = 0
    //
    //   const returnedMessage = data.messageObject
    //   let messages = this.state.messages
    //   if (returnedMessage) {
    //     if (messages) {
    //       messages.push(returnedMessage)
    //     } else {
    //       messages = [returnedMessage]
    //     }
    //   }
    //
    //   this.setState({ successMessage: null, isSaving: false, messageDraft: '', newMessage: false, inboxSelected, messages, inboxes })
    //
    //   // if (!this.state.scrollOnAllUpdates) {
    //   //   this.scrollToBottom()
    //   // }
    // }

    selectInbox(passedInbox, index, passedRecipients, newMessage) {
      console.log('selectInbox called', passedInbox, index, passedRecipients, newMessage)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      if (!passedInbox && passedRecipients) {
        if (this.state.inboxes && this.state.inboxes.length > 0) {

          for (let i = 1; i <= this.state.inboxes.length; i++) {
            // console.log('t2', item.email, this.state.inboxes[i - 1].members)
            let includedRecipientCount = 0
            let inboxRecipientCount = 0
            for (let j = 1; j <= passedRecipients.length; j++) {
              if (this.state.inboxes[i - 1].members) {
                inboxRecipientCount = this.state.inboxes[i - 1].members.length - 1
                for (let k = 1; k <= this.state.inboxes[i - 1].members.length; k++) {
                  if (this.state.inboxes[i - 1].members[k - 1].email === passedRecipients[j - 1].email) {
                    console.log('show it: ', i, k, j, this.state.inboxes[i - 1].members[k - 1].email, passedRecipients[j - 1].email)
                    includedRecipientCount = includedRecipientCount + 1
                  }
                }
              }
            }
            console.log('show the values: ', i, includedRecipientCount, passedRecipients.length, inboxRecipientCount)
            if (includedRecipientCount === passedRecipients.length && includedRecipientCount === inboxRecipientCount) {
              console.log('so this did not work?', i, includedRecipientCount)
              index = i - 1
            }
            // if (this.state.showMultipleRecipients) {
              // let includedRecipientCount = 0
              // for (let j = 1; j <= passedRecipients.length; j++) {
              //   if (this.state.inboxes[i - 1].members.some(m => m.email === passedRecipients[j - 1].email)) {
              //     // console.log('t3')
              //     // index = i - 1
              //     includedRecipientCount = includedRecipientCount + 1
              //   }
              // }
              // if (includedRecipientCount === passedRecipients.length) {
              //   index = i - 1
              // }
            // } else {
            //   if (this.state.inboxes[i - 1].members.some(m => m.email === passedRecipients[0].email)) {
            //     console.log('t3')
            //     index = i - 1
            //   }
            // }
          }
        }
      }
      // console.log('index? ', index)
      if (index === 0 || index > 0) {
        console.log('show index 2: ', index, typeof index)

        if (this.state.inboxes[index].unreadCount) {
          this.clearUnreadMessages(this.state.inboxes, this.state.inboxes[index], index)
        }

        const members = this.state.inboxes[index].members
        let recipient = null
        let recipients = []
        // if (passedRecipients) {
        //
        //   recipients = passedRecipients
        //   console.log('list recipients 2.5: ', recipients)
        // } else {
        //   for (let i = 1; i <= members.length; i++) {
        //     if (members[i - 1].email !== this.state.emailId) {
        //       recipient = {
        //         firstName: members[i - 1].firstName, lastName: members[i - 1].lastName,
        //         email: members[i - 1].email, pictureURL: members[i - 1].pictureURL, username: members[i - 1].username,
        //         roleName: members[i - 1].roleName, accountCode: members[i - 1].accountCode,
        //         notificationPreferences: members[i - 1].notificationPreferences
        //       }
        //       recipients.push(recipient)
        //     }
        //   }
        // }
        for (let i = 1; i <= members.length; i++) {
          if (members[i - 1].email !== this.state.emailId) {
            recipient = {
              firstName: members[i - 1].firstName, lastName: members[i - 1].lastName,
              email: members[i - 1].email, pictureURL: members[i - 1].pictureURL, username: members[i - 1].username,
              roleName: members[i - 1].roleName, accountCode: members[i - 1].accountCode,
              notificationPreferences: members[i - 1].notificationPreferences
            }
            recipients.push(recipient)
          }
        }

        // joinRoom
        let room = '12345'
        if (index || index === 0) {
          if (this.state.inboxes[index].members) {
            room = ""
            // room = this.state.inboxes[index].members[0].email + '|' + this.state.inboxes[index].members[1].email
            let memberEmails = []
            for (let i = 1; i <= this.state.inboxes[index].members.length; i++) {
              // room = room + this.state.inboxes[index].members[i - 1].email
              // if (i < this.state.inboxes[index].members.length) {
              //   room = room + "|"
              // }
              memberEmails.push(this.state.inboxes[index].members[i - 1].email)
            }
            memberEmails.sort()
            room =  memberEmails.join("|")
          }
        }
        console.log('what is up with this room: ', room, index, this.state.inboxes)
        this.joinRoom(this.state.username, room)

        const recipientCount = recipients.length
        const resLimit = this.state.resLimit
        const recent = this.state.recent

        // const messageParams = { emailId: this.state.emailId, members, recipientCount, resLimit, recent, excludeBot: true }
        const messageParams = { emailId: this.state.emailId, members, recipientCount, resLimit, recent }
        // console.log('show new room: ', room)
        Axios.get('/api/messages', { params: messageParams })
        .then((response) => {
          console.log('Messages query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved messages', index)

             const messages = response.data.messages
             console.log('list recipients 3: ', recipients)
             this.setState({ inboxSelected: index, recipient, recipients, messages, viewIndex: 1, isLoading: false, newMessage, room })

             // if (!this.state.scrollOnAllUpdates) {
             //   this.scrollToBottom()
             // }

           } else {
             console.log('no messages found', response.data.message)
             this.setState({ isLoading: false })
           }

        }).catch((error) => {
           console.log('Message query did not work', error);
           this.setState({ isLoading: false })
        });
      } else {
        console.log('no index found')

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName
        const notificationPreferences = this.state.notificationPreferences

        const cuProfile = { firstName, lastName, email, username, pictureURL, roleName, notificationPreferences }

        let recipients = passedRecipients
        // let members = passedRecipients
        // members.push(cuProfile)
        const members = passedRecipients.concat([cuProfile])
        // const members = passedRecipients.push(cuProfile)
        // console.log('list recipients 4: ', recipients, members)
        // joinRoom
        let room = '12345'
        if (index || index === 0) {
          if (members) {
            room = ""
            let memberEmails = []
            for (let i = 1; i <= members.length; i++) {
              memberEmails.push(members[i - 1].email)
            }
            memberEmails.sort()
            room =  memberEmails.join("|")
          }
        }

        this.joinRoom(this.state.username, room)

        const messages = []
        this.setState({ inboxSelected: null, recipients, messages, viewIndex: 1, isLoading: false, newMessage, room })
      }
    }

    // scrollToBottom = () => {
    //   if (this.messagesEnd) {
    //     this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    //   }
    // }

    clearUnreadMessages(inboxes, selectedInbox, index) {
      console.log('clearUnreadMessages called', inboxes, selectedInbox, index, this.state.inboxes)

      const emailId = this.state.emailId

      Axios.put('/api/inboxes/clear-unread', { emailId, selectedInbox })
      .then((response) => {
        console.log('attempting to clear unread messages')

        if (response.data.success) {
          console.log('cleared messages', response.data)

          // let inboxes = this.state.inboxes
          if (inboxes) {
            inboxes[index] = response.data.inbox
          } else {
            inboxes = [response.data.inbox]
          }

          this.setState({ inboxes })

        } else {
          console.log('There was an error clearing messages: ', response.data.message)
          // this.setState({ errorMessage: 'returned error:' + response.data.message })
        }
      }).catch((error) => {
          console.log('Caught an error clearning messages: ', error);
          // this.setState({ errorMessage: 'there was an error saving groups' })
      });
    }

    // renderMessage(message, cu) {
    //   // console.log('renderMessage called')
    //
    //   if (message && message.length > 0) {
    //     if (message.startsWith('<img src=')) {
    //       // let styleObject = ""
    //       if (!cu) {
    //         message = message.replace(/margin-left: auto;/g,"margin-right: auto;")
    //       }
    //     } else {
    //       let styleObject = "font-size: 70%; white-space: pre-wrap;"
    //       if (cu) {
    //         styleObject = styleObject + "text-align: right;"
    //       }
    //
    //       const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
    //       message = message.replace(Rexp,"<a href='$1' target='_blank'>$1</a>");
    //       message = '<div style="' + styleObject + '">' + message + '</div>'
    //     }
    //   }
    //
    //   return message
    //
    // }

    recipientClicked(index) {
      console.log('recipientClicked called')

      let recipients = this.state.recipients
      recipients[index]['isSelected'] = true
      this.setState({ recipients })

    }

    removeRecipient(e, index) {
      console.log('removeRecipient called')

      e.stopPropagation()

      let recipients = this.state.recipients
      recipients.splice(index, 1)
      this.setState({ recipients })

      this.selectInbox(null, null, recipients, this.state.newMessage)

    }

    renderMultiMemberHeader(value, index, fromMessageHeader) {
      console.log('renderMultiMemberHeader called', value)

      let picIcon1 = profileIconDark
      let picIcon2 = profileIconDark
      let title = ""
      let subtitle = ""
      let singlePicClass = "profile-thumbnail-1"
      let pic1Class = "profile-thumbnail-25 float-left"
      let pic2Class = "profile-thumbnail-25 float-left absolute-position top-0 left-5 left-margin"
      if (fromMessageHeader) {
        singlePicClass = "profile-thumbnail-25"
        pic1Class = "profile-thumbnail-20 float-left"
        pic2Class = "profile-thumbnail-20 float-left"
      }

      for (let i = 1; i <= value.members.length; i++) {
        if (value.members[i - 1].email !== this.state.emailId) {
          if (picIcon1 === profileIconDark) {
            console.log("???", value.members[i - 1].pictureURL)
            if (value.members[i - 1].pictureURL) {
              picIcon1 = value.members[i - 1].pictureURL
            } else if (value.members[i - 1].senderPictureURL) {
              picIcon1 = value.members[i - 1].senderPictureURL
            }
          } else {
            if (picIcon2 === profileIconDark) {
              if (value.members[i - 1].pictureURL) {
                picIcon2 = value.members[i - 1].pictureURL
              } else if (value.members[i - 1].senderPictureURL) {
                picIcon2 = value.members[i - 1].senderPictureURL
              }
            }
          }

          if ((value.members.length === 2 && !fromMessageHeader) || (fromMessageHeader && value.members.length === 1)) {
            title = title + value.members[i - 1].firstName + " " + value.members[i - 1].lastName
          } else if ((value.members.length === 3 && !fromMessageHeader) || (fromMessageHeader && value.members.length === 2)) {
            if (title === "") {
              title = title + value.members[i - 1].firstName
            } else {
              title = title + " & " + value.members[i - 1].firstName
            }
          } else {
            title = title + value.members[i - 1].firstName
            if (i < value.members.length) {
              title = title + ", "
            }
          }

          if (value.senderEmail === this.state.emailId) {
            subtitle = "You: " + value.lastMessage
          } else {
            subtitle = value.members[i - 1].firstName + ": " + value.lastMessage
          }
        }
      }

      let containerClass = "left-padding full-width top-padding"
      let column1Class = "fixed-column-50 relative-position"
      let column2Class = "calc-column-offset-110"
      if (fromMessageHeader) {
        containerClass = "full-width top-padding"
        column2Class = "calc-column-offset-50"
        if (value.members.length === 1) {
          column1Class = "fixed-column-40 relative-position"
          column2Class = "calc-column-offset-40 top-padding-5"
        }
      }

      return (
        <div key={"renderMultiMemberHeader|" + index}>
          <div className={containerClass}>
            <div className={column1Class}>
              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
              {((value.members.length === 2 && !fromMessageHeader) || (fromMessageHeader && value.members.length === 1)) ? (
                <div>
                  <img src={picIcon1} alt="GC" className={singlePicClass} />
                </div>
              ) : (
                <div>
                  <img src={picIcon1} alt="GC" className={pic1Class} />
                  <img src={picIcon2} alt="GC" className={pic2Class} />
                </div>
              )}
              <div className="clear" />
            </div>
            <div className={column2Class}>
              <p className="curtail-text">{title}</p>
              {(!fromMessageHeader) && (
                <p className={(value.unreadCount > 0) ? "description-text-4 left-text curtail-text height-20 bold-text" : "description-text-4 left-text curtail-text height-20"}>{subtitle}</p>
              )}
            </div>
            {(!fromMessageHeader) && (
              <div className="fixed-column-60">
                <p className="description-text-5 bold-text description-text-color right-text">{convertDateToString(value.updatedAt,"daysAgo")}</p>
                {(value.unreadCount > 0) && (
                  <div className="top-margin width-10 height-10 circle-radius primary-background pin-right" />
                )}
              </div>
            )}
            <div className="clear" />
          </div>
        </div>
      )
    }

    updateInboxes(data) {
      console.log('updateInboxes called')

      const inboxObject = data.inboxObject
      let inboxes = this.state.inboxes
      if (inboxObject) {
        if (inboxes) {
          inboxes.unshift(inboxObject)
        } else {
          inboxes = [inboxObject]
        }
      } else {
        inboxes[this.state.inboxSelected]['lastMessage'] = data.messageObject.message
        inboxes[this.state.inboxSelected]['senderEmail'] = this.state.emailId
        // inboxes[this.state.inboxSelected]['senderPictureURL'] = this.state.pictureURL

        //reorder inbox to front
        const tempInbox = inboxes[this.state.inboxSelected]
        inboxes.splice(this.state.inboxSelected,1)
        inboxes.unshift(tempInbox)
      }

      // console.log('new inboxes: ', inboxes,this.state.inboxSelected)

      const inboxSelected = 0

      this.setState({ newMessage: false, inboxSelected, inboxes })
    }

    render() {

      let recipientProfileLink = '/'
      let recipientProfileLinkPrefix
      let cuProfileLink = this.state.prefix + '/profile/' + this.state.username
      // if (this.state.recipient && !this.state.showMultipleRecipients) {
      //   recipientProfileLink = this.state.prefix + '/profile/' + this.state.recipient.username
      //   if (window.location.pathname.includes('/advisor')) {
      //     if (this.state.roleName === 'Mentor') {
      //       recipientProfileLink = '/advisor/profile/' + this.state.recipient.username
      //       cuProfileLink = '/advisor/profile/' + this.state.username
      //     } else {
      //       recipientProfileLink = '/advisor/advisees/' + this.state.recipient.email
      //       cuProfileLink = '/advisor/advisees/' + this.state.emailId
      //     }
      //   }
      // } else if (this.state.recipients && this.state.recipients.length > 0) {
        // show multiple profile links
        recipientProfileLinkPrefix = this.state.prefix + '/profile/'
        if (window.location.pathname.includes('/advisor')) {
          if (this.state.roleName === 'Mentor') {
            recipientProfileLinkPrefix = '/advisor/profile/'
            cuProfileLink = '/advisor/profile/' + this.state.username
          } else {
            recipientProfileLinkPrefix = '/advisor/advisees/'
            cuProfileLink = '/advisor/advisees/' + this.state.emailId
          }
        }
      // }

      return (
        <div>
          {(window.location.pathname.includes('/organizations')) && (
            <div className="widget-container center-horizontally">
              <div className="padding-15 standard-border">
                <div className="calc-column-offset-160 top-margin">
                  <p>Need to send a message blast to members?</p>
                </div>
                <div className="fixed-column-160">
                  <button className="btn btn-squarish" onClick={() => this.props.navigate('/organizations/' + this.state.activeOrg + '/message-blasts')}>Schedule Blast</button>
                </div>
                <div className="clear" />
              </div>

            </div>
          )}

          <div className={(window.innerWidth < 768) ? "top-margin-2-percent center-horizontally white-background slightly-rounded-corners standard-border medium-shadow" : "widget-container top-margin-2-percent center-horizontally white-background slightly-rounded-corners standard-border medium-shadow"}>
            {(window.innerWidth < 768) && (
              <div>
                <div className="container-left-static">
                  <button className="background-button full-width" onClick={() => this.setState({ viewIndex: 0 })}>
                    <div className={(this.state.viewIndex === 0) ? "row-10 horizontal-padding full-width cta-background-color white-text" : "row-10 horizontal-padding full-width"}>
                      <p>Inbox</p>
                    </div>
                  </button>
                </div>
                <div className="container-right-static">
                  <button className="background-button full-width" onClick={() => this.setState({ viewIndex: 1 })}>
                    <div className={(this.state.viewIndex === 1) ? "row-10 horizontal-padding full-width cta-background-color white-text" : "row-10 horizontal-padding full-width"}>
                      <p>Messages</p>
                    </div>
                  </button>
                </div>

                <div className="clear" />
              </div>
            )}

            {(window.innerWidth > 768 || (window.innerWidth < 768 && this.state.viewIndex === 0)) && (
              <div className={(window.innerWidth > 768) ? "fixed-column-300" : "full-width"}>
                <div className="row-10 standard-border horizontal-padding-4">
                  <div className="calc-column-offset-25">
                    <p className="bold-text description-text-1">Create New Message</p>
                  </div>
                  <div className="fixed-column-25">
                    <button className="background-button pin-right" onClick={() => this.setState({ newMessage: true, recipient: null, recipients: null, inboxSelected: null, messages: null, searchString: '', viewIndex: 1 })}>
                      <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                      <img src={editIconDark} alt="GC" className="image-auto-15 pin-right"/>
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="height-483 overflow-y-auto">
                  {(this.state.inboxes && this.state.inboxes.length > 0) ? (
                    <div>
                      {this.state.inboxes.map((value, optionIndex) =>
                        <div key={value._id} className={(this.state.inboxSelected === optionIndex) ? "selected-background-light left-text full-width " : "left-text full-width"}>
                          <button className="background-button left-text full-width top-padding bottom-padding-20 horizontal-padding-4" disabled={this.state.isLoading} onClick={() => this.selectInbox(value, optionIndex, null, false)}>
                            {this.renderMultiMemberHeader(value, optionIndex)}
                            {/*
                            {(this.state.showMultipleRecipients) ? (
                              <div>
                                {this.renderMultiMemberHeader(value, optionIndex)}
                              </div>
                            ) : (
                              <div>
                                {value.members.map((value2, optionIndex2) =>
                                  <div key={value2._id}>
                                    {(value2.email !== this.state.emailId) && (
                                      <div className="left-padding full-width top-padding">
                                        <div className="fixed-column-50">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                        </div>
                                        <div className="calc-column-offset-110">
                                          <p>{value2.firstName} {value2.lastName}</p>
                                          <p className={(value.unreadCount > 0) ? "description-text-4 left-text curtail-text height-20 bold-text" : "description-text-4 left-text curtail-text height-20"}>{(value.senderEmail === this.state.emailId) ? "You: " + value.lastMessage : value2.firstName + ': ' + value.lastMessage}</p>
                                        </div>
                                        <div className="fixed-column-60">
                                          <p className="description-text-5 bold-text description-text-color right-text">{convertDateToString(value.updatedAt,"daysAgo")}</p>
                                          {(value.unreadCount > 0) && (
                                            <div className="top-margin width-10 height-10 circle-radius primary-background pin-right" />
                                          )}
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}

                                  </div>
                                )}
                              </div>
                            )}*/}
                          </button>

                          <div className="clear" />
                          <hr />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="top-padding-40-percent">
                      <img src={commentIconGrey} alt="GC" className="image-auto-40 center-item"/>
                      <div>
                        <p className="full-width top-padding-20 description-text-1 description-text-color horizontal-padding center-text">No Conversations Yet</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(window.innerWidth > 768 || (window.innerWidth < 768 && this.state.viewIndex === 1)) && (
              <div>
                <div className={(window.innerWidth > 768) ? "fixed-column-420" : "full-width"}>
                  <div className="row-10 standard-border horizontal-padding-4 center-text">
                    {(this.state.newMessage) ? (
                      <p className="description-text-1">New Message</p>
                    ) : (
                      <div>
                        {/*
                        {(this.state.recipient && !this.state.showMultipleRecipients) && (
                          <Link to={recipientProfileLink} target="_blank" className="background-button full-width standard-color">
                            <div className="fixed-column-30">
                              <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-20" alt="GC" />
                            </div>
                            <div className="calc-column-offset-30 left-text">
                              <p className="description-text-2 height-20 bold-text">{this.state.recipient.firstName} {this.state.recipient.lastName}</p>
                            </div>
                            <div className="clear" />
                          </Link>
                        )}*/}
                        {(this.state.recipients && this.state.recipients.length > 0) && (
                          <div className="display-inline">
                            {this.renderMultiMemberHeader({ members: this.state.recipients }, 1000, true)}
                            {/*
                            {this.state.recipients.map((value, optionIndex) =>
                              <div key={value.email}>
                                <Link to={(window.location.pathname.includes('/advisor') && this.state.roleName !== 'Mentor') ? recipientProfileLinkPrefix + "/email" : recipientProfileLinkPrefix + "/username"} target="_blank" className="background-button full-width standard-color">
                                  <div className="fixed-column-30">
                                    <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-20" alt="GC" />
                                  </div>
                                  <div className="calc-column-offset-30 left-text">
                                    <p className="description-text-2 height-20 bold-text">{value.firstName} {value.lastName}</p>
                                  </div>
                                  <div className="clear" />
                                </Link>
                              </div>
                            )}*/}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {(this.state.newMessage) && (
                    <div className="row-10 standard-border horizontal-padding-4">
                      {(this.state.recipients && this.state.recipients.length > 0) ? (
                        <div>
                          {this.state.recipients.map((value, optionIndex) =>
                            <div key={value.email}>
                              <button className={(value.isSelected) ? "relative-position background-button cta-background-color slightly-rounded-corners left-text white-text float-left top-margin-5 bottom-margin-5 row-10 horizontal-padding-5 right-margin-20 no-pointers" : "background-button light-background slightly-rounded-corners left-text cta-color float-left top-margin-5 bottom-margin-5 row-10 horizontal-padding-5 right-margin-20"} onClick={() => this.recipientClicked(optionIndex)}>
                                {(value.isSelected) && (
                                  <button className="absolute-position top-0 left-0 background-button left-margin-negative-5 top-margin-negative-5 auto-pointers" onClick={(e) => this.removeRecipient(e, optionIndex)}>
                                    <img src={deniedIcon} alt="GC" className="image-auto-14" />
                                  </button>
                                )}
                                <div className="fixed-column-30">
                                  <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-20" alt="GC" />
                                </div>
                                <div className="calc-column-offset-30 left-text">
                                  <p className="description-text-2 height-20 bold-text">{value.firstName} {value.lastName}</p>
                                </div>
                                <div className="clear" />
                              </button>
                            </div>
                          )}
                          <div className="row-10 standard-border horizontal-padding-4 float-left">
                            <input type="text" className="text-field clear-border description-text-3 height-20" placeholder="Search for members" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                          </div>
                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          <input type="text" className="text-field clear-border description-text-3 height-20" placeholder="Search for members" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                      )}
                      {/*
                      {(this.state.showMultipleRecipients) ? (
                        <div>
                          {(this.state.recipients && this.state.recipients.length > 0) ? (
                            <div>
                              {this.state.recipients.map((value, optionIndex) =>
                                <div key={value.email}>
                                  <button className={(value.isSelected) ? "relative-position background-button cta-background-color slightly-rounded-corners left-text white-text float-left top-margin-5 bottom-margin-5 row-10 horizontal-padding-5 right-margin-20 no-pointers" : "background-button light-background slightly-rounded-corners left-text cta-color float-left top-margin-5 bottom-margin-5 row-10 horizontal-padding-5 right-margin-20"} onClick={() => this.recipientClicked(optionIndex)}>
                                    {(value.isSelected) && (
                                      <button className="absolute-position top-0 left-0 background-button left-margin-negative-5 top-margin-negative-5 auto-pointers" onClick={(e) => this.removeRecipient(e, optionIndex)}>
                                        <img src={deniedIcon} alt="GC" className="image-auto-14" />
                                      </button>
                                    )}
                                    <div className="fixed-column-30">
                                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-20" alt="GC" />
                                    </div>
                                    <div className="calc-column-offset-30 left-text">
                                      <p className="description-text-2 height-20 bold-text">{value.firstName} {value.lastName}</p>
                                    </div>
                                    <div className="clear" />
                                  </button>
                                </div>
                              )}
                              <div className="row-10 standard-border horizontal-padding-4 float-left">
                                <input type="text" className="text-field clear-border description-text-3 height-20" placeholder="Search for members" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                              <div className="clear" />
                            </div>
                          ) : (
                            <div>
                              <input type="text" className="text-field clear-border description-text-3 height-20" placeholder="Search for members" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {(this.state.recipient) ? (
                            <div>
                              <div className="fixed-column-25">
                                <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-20" alt="GC" />
                              </div>
                              <div className="calc-column-offset-25">
                                <input type="text" className="text-field clear-border description-text-3 height-20 bold-text" placeholder="Type a name" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                              <div className="clear" />
                            </div>
                          ) : (
                            <div>
                              <input type="text" className="text-field clear-border description-text-3 height-20" placeholder="Type a name" name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                          )}
                        </div>
                      )}*/}

                      {(this.state.animating) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <div className="super-spacer" />

                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Searching...</p>

                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.state.memberOptions && this.state.memberOptions.length > 0) && (
                            <div>
                              {this.state.memberOptions.map((value, optionIndex) =>
                                <div key={value.email} className="left-text bottom-margin-5 full-width">
                                  <button className="background-button" onClick={() => this.searchItemClicked(value, 'member',optionIndex)}>
                                    <div className="left-padding full-width top-padding">
                                      <div className="float-left right-padding">
                                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="Compass employer icon icon" className="profile-thumbnail-25" />
                                      </div>
                                      <div className="float-left">
                                        <p className="cta-color">{value.firstName} {value.lastName}</p>
                                        <p className="description-text-3 left-text">{value.roleName}</p>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.useSubRenderChat) ? (
                    <div>
                      <SubRenderChat messages={this.state.messages} recipients={this.state.recipients}
                        headline={this.state.headline} schoolName={this.state.schoolName} jobTitle={this.state.jobTitle}
                        employerName={this.state.employerName} accountCode={this.state.accountCode}
                        notificationPreferences={this.state.notificationPreferences} room={this.state.room}
                        updateInboxes={this.updateInboxes}
                      />
                    </div>
                  ) : (
                    <div>
                      {/*
                      <div className="row-10 standard-border horizontal-padding-4 calc-height-180-of-98">
                        {(this.state.messages && this.state.messages.length > 0) ? (
                          <div className="full-space overflow-y-auto">
                            {this.state.messages.map((value, optionIndex) =>
                              <div key={value._id + 'key'} className="left-text full-width bottom-padding-20">
                                {(value.senderEmail === this.state.emailId) ? (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-50">
                                      <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                    </div>
                                    <div className="calc-column-offset-90">
                                      <Link to={cuProfileLink} target="_blank" className="background-button full-width standard-color">
                                        <p className="description-text-2 bold-text full-width right-text">{this.state.cuFirstName} {this.state.cuLastName}</p>
                                      </Link>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>

                                    </div>
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={cuProfileLink} target="_blank" className="background-button full-width standard-color">
                                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
                                      </Link>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                        <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                      </Link>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <div>
                                        <div className="calc-column-offset-50">
                                          <Link to={this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                            <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
                                          </Link>
                                        </div>
                                        <div className="fixed-column-50">
                                          <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />

                            <div ref={(el) => { this.messagesEnd = el; }} />
                          </div>
                        ) : (
                          <div>
                            {(this.state.recipient && !this.state.showMultipleRecipients) && (
                              <div className="top-padding-10-percent">
                                <Link to={recipientProfileLink} target="_blank" className="background-button full-width standard-color">
                                  <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
                                  <div>
                                    <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{this.state.recipient.firstName} {this.state.recipient.lastName}</p>
                                    <p className="full-width description-text-3 center-text top-padding">You two are not connected to each other.</p>
                                  </div>
                                </Link>
                              </div>
                            )}
                            {(this.state.recipients && this.state.recipients.length > 0 && this.state.showMultipleRecipients) && (
                              <div className="top-padding-10-percent">
                                <p className="full-width top-padding heading-text-6 horizontal-padding center-text">No Messages Yet</p>
                                <p className="full-width description-text-3 center-text top-padding">There are no existing messages on this chat thread.</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="row-10 standard-border horizontal-padding-4">
                        <div className="calc-column-offset-70">
                          <input className="text-field description-text-2 clear-border" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder="Write a message..." name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                        </div>
                        <div className="fixed-column-70">
                          <button className={(this.state.messageDraft && this.state.messageDraft !== '') ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
                        </div>
                        <div className="clear" />
                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}
                      </div>*/}
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>
            )}


          </div>

        </div>
      )
    }
}

export default withRouter(Messaging);
