import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const detailsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/details-icon-grey.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class TrainingPrograms extends Component {
    constructor(props) {
        super(props)

        this.state = {
          emailId: null,
          username: '',
          org: '',
          trainingPrograms: [],
          filteredTrainingPrograms: [],
          timerId: 0,

          showProjectDetail: false,

          selectedIndex1: 0,
          selectedIndex2: 0,

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          memberFilterOptions: [],
          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],
          gradeOptions: [],
          pathwayOptions: [{ name: '' }],
          radiusOptions: [],

          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],
          jobOptions: [],

          location: 'US',
          radius: '10',
          occupation: '0',
          programName: '0',
          programLength: '0',
          state: '0',
          region: '0',

          showFilters: true,

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullTrainingPrograms = this.pullTrainingPrograms.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.renderManipulators = this.renderManipulators.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.markCompleted = this.markCompleted.bind(this)

        this.renderItems = this.renderItems.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within trainingPrograms', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in trainingPrograms', this.props.activeOrg)

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('cuFirstName');
      let cuLastName = localStorage.getItem('cuLastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');

      const jobOptions = []
      const pathwayOptions = [{ name: '' }]

      const radiusOptions = ['','10','50']

      this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus,
        jobOptions, pathwayOptions, radiusOptions
      })

      let orgCode = 'general'
      if (org === 'c2c' || org === 'dpscd') {
        orgCode = org
      }

      Axios.get('/api/benchmarks', { params: { orgCode } })
      .then((response) => {

        if (response.data.success) {
          console.log('Benchmark query worked', response.data);

          let pathwayOptions = [{name: '', skills: []}]
          let names = []

          for (let i = 1; i <= response.data.benchmarks.length; i++) {

            if (org === 'dpscd') {
              if (response.data.benchmarks[i - 1].pathway === pathway) {

                // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                let skills = response.data.benchmarks[i - 1].skills

                let skillsObject = []

                for (let j = 1; j <= skills.length; j++) {
                  if (skills[j - 1].title && skills[j - 1].skillType) {
                    const name = skills[j - 1].title
                    const skillType = skills[j - 1].skillType
                    skillsObject.push({ name, skillType })
                  } else {
                    // not adding traits for now
                    // const name = skills[j - 1].title
                    // const skillType = 'Trait'
                    // console.log('no good: ', name)
                    // skillsObject.push({ name, skillType})
                  }
                }

                // this.setState({ skills: skillsObject })
              }
            } else {

              let name = response.data.benchmarks[i - 1].jobFunction
              console.log('show the value 1: ', name)
              if (!names.includes(name)) {
                names.push(name)
                console.log('show the value 2: ', name)
                // let skillTraits = response.data.benchmarks[i - 1].skills.concat(response.data.benchmarks[i - 1].traits)
                let skills = response.data.benchmarks[i - 1].skills

                let skillsObject = []

                for (let j = 1; j <= skills.length; j++) {
                  if (skills[j - 1].title && skills[j - 1].skillType) {
                    const name = skills[j - 1].title
                    const skillType = skills[j - 1].skillType
                    skillsObject.push({ name, skillType })
                  } else {
                    // not adding traits for now
                    // const name = skills[j - 1].title
                    // const skillType = 'Trait'
                    // console.log('no good: ', name)
                    // skillsObject.push({ name, skillType})
                  }
                }

                let jobOptions = []
                if ( name === 'Research & Development') {
                  jobOptions.push(name, 'Research','R&D')
                } else if (name === 'Human Resources') {
                  jobOptions.push(name, 'HR')
                } else if (name === 'Design') {
                  jobOptions.push(name, 'Designer','UX','UI')
                } else if (name === 'Finance & Accounting') {
                  jobOptions.push(name, 'Finance','Accounting','Financial')
                } else if (name === 'Data Science') {
                  jobOptions.push(name, 'Data','Business Intelligence')
                } else if (name === 'Product Management') {
                  jobOptions.push(name, 'Product Manager')
                } else if (name === 'Engineering') {
                  jobOptions.push(name, 'Engineer')
                } else if (name === 'Strategy') {
                  jobOptions.push(name, 'Strategist')
                } else if (name === 'Operations') {
                  jobOptions.push(name)
                } else if (name === 'Partnerships') {
                  jobOptions.push(name)
                } else if (name === 'Marketing') {
                  jobOptions.push(name, 'Social Media')
                } else if (name === 'Sales') {
                  jobOptions.push(name, 'Business Development')
                } else if (name === 'IT Support') {
                  jobOptions.push(name)
                } else if (name === 'Customer Service') {
                  jobOptions.push(name)
                }

                pathwayOptions.push({ name, jobOptions })
              } else {


              }
              console.log('show pathwayOptions: ', pathwayOptions)

            }
          }

          this.setState({ pathwayOptions })
        } else {
          console.log('no benchmarks found', response.data.message)

        }

      }).catch((error) => {
          console.log('Benchmark query did not work', error);
      });
    }

    pullTrainingPrograms(searchValue, location, radius, occupation, programName, programLength, state, region) {
      console.log('pullTrainingPrograms called', searchValue, location, radius)

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedJob
      }
      if (!location) {
        location = this.state.location
      }
      if (!radius) {
        radius = this.state.radius
      }
      if (!occupation) {
        occupation = this.state.occupation
      }
      if (!programName) {
        programName = this.state.programName
      }
      if (!programLength) {
        programLength = this.state.programLength
      }
      if (!state) {
        state = this.state.state
      }
      if (!region) {
        region = this.state.region
      }

      Axios.get('/api/training-programs/search', { params: { searchValue, location, radius, occupation, programName, programLength, state, region } })
      .then((response) => {
        console.log('Training programs query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved training programs')

            if (response.data.trainingPrograms) {

              const trainingPrograms = response.data.trainingPrograms.SchoolPrograms
              const filteredTrainingPrograms = trainingPrograms
              console.log('show trainingPrograms: ', trainingPrograms)
              this.setState({ trainingPrograms, filteredTrainingPrograms })
            }

          } else {
            console.log('no training program data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Training program query did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        // //reset everything
        // let itemFilters = this.state.itemFilters
        // for (let i = 1; i <= itemFilters.length; i++) {
        //   itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        // }
        // let itemSorters = this.state.itemSorters
        // for (let i = 1; i <= itemSorters.length; i++) {
        //   itemSorters[i - 1]['value'] = this.state.defaultSortOption
        // }

        this.setState({ searchString, animating: true })

        // this.setState({ searchString: event.target.value, animating: true })
        // this.resetFilters(event.target.name, event.target.value)
        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        if (this.state.showFilters) {
          const nameArray = event.target.name.split("|")
          if (nameArray[1] === 'location') {
            const location = event.target.value
            this.setState({ location })
            this.pullTrainingPrograms(this.state.selectedJob, location.toLowerCase(), this.state.radius, this.state.occupation, this.state.programName, this.state.programLength, this.state.state, this.state.region)
          } else if (nameArray[1] === 'radius') {
            let radius = event.target.value
            this.setState({ radius })

            this.pullTrainingPrograms(this.state.selectedJob, this.state.location, radius, this.state.occupation, this.state.programName, this.state.programLength, this.state.state, this.state.region)
          }
        } else {
          let filters = this.state.itemFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          let index = 0
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          let itemFilters = filters

          //reset everything
          let searchString = ''
          for (let i = 1; i <= itemFilters.length; i++) {
            if (itemFilters[i - 1].name !== field) {
              itemFilters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }
          let itemSorters = this.state.itemSorters
          for (let i = 1; i <= itemSorters.length; i++) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ filters, itemFilters, animating: true, searchString, itemSorters })

          this.filterResults(this.state.searchString, event.target.value, filters, index, false)
        }

      } else if (event.target.name.includes('sort|')) {

        if (this.state.showFilters) {

        } else {
          let sorters = this.state.itemSorters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= sorters.length; i++) {
            if (sorters[i - 1].name === field) {
              sorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          let itemSorters = sorters

          //reset everything
          let searchString = ''
          let itemFilters = this.state.itemFilters
          for (let i = 1; i <= itemFilters.length; i++) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= itemSorters.length; i++) {
            if (itemSorters[i - 1].name !== field) {
              itemSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, itemFilters, itemSorters, animating: true })

          this.sortResults(event.target.value, field)
        }

      } else if (event.target.name === 'pathway') {

        let selectedPathway = event.target.value
        let jobOptions = []

        for (let i = 1; i <= this.state.pathwayOptions.length; i++) {
          if (this.state.pathwayOptions[i - 1].name === event.target.value) {
            if (this.state.pathwayOptions[i - 1].jobOptions) {
              for (let j = 1; j <= this.state.pathwayOptions[i - 1].jobOptions.length; j++) {
                // console.log('show the title: ', this.state.pathwayOptions[i - 1].skillTraits[j - 1])
                jobOptions.push(this.state.pathwayOptions[i - 1].jobOptions[j - 1])
              }
            }
          }
        }

        this.setState({ selectedPathway, jobOptions })
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      const location = this.state.location
      const radius = this.state.radius
      const occupation = this.state.occupation
      const programName = this.state.programName
      const programLength = this.state.programLength
      const state = this.state.state
      const region = this.state.region

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/training-programs/search', { params: { searchValue: searchString, location, radius, occupation, programName, programLength, state, region } })
        .then((response) => {
          console.log('Training programs query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved training programs')

              if (response.data.trainingPrograms) {

                const trainingPrograms = response.data.trainingPrograms.SchoolPrograms
                const filteredTrainingPrograms = trainingPrograms
                console.log('show trainingPrograms: ', trainingPrograms)
                self.setState({ trainingPrograms, filteredTrainingPrograms, animating: false })
              }

            } else {
              console.log('no training program data found', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Training program query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      // let projects = this.state.projects
      // let filteredProjects = []
      //
      // Axios.put('/api/projects/sort', { sortString, projects, sortName })
      // .then((response) => {
      //   console.log('Project sort query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('project sort query worked')
      //
      //       let filteredProjects = response.data.projects
      //       const filterCriteriaArray = null
      //       const sortCriteriaArray = response.data.sortCriteriaArray
      //
      //       this.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })
      //
      //     } else {
      //       console.log('project sort query did not work', response.data.message)
      //       this.setState({ animating: false })
      //     }
      //
      // }).catch((error) => {
      //     console.log('Project sort query did not work for some reason', error);
      //     this.setState({ animating: false })
      // });
    }

    markCompleted(index) {
      console.log('markCompleted called', index)

      let filteredTrainingPrograms = this.state.filteredTrainingPrograms
      if (filteredTrainingPrograms[index].completed) {
        filteredTrainingPrograms[index]['completed'] = false
        this.setState({ filteredTrainingPrograms })
      } else {
        filteredTrainingPrograms[index]['completed'] = true
        this.setState({ filteredTrainingPrograms })
      }
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters
        console.log('show filters: ', filters)

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters
        console.log('show sorters: ', sorters)

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    favoriteItem(item) {
      console.log('favoriteItem called', item)

      let itemId = item._id

      let favoritesArray = this.state.favorites

      if (favoritesArray.includes(itemId)){

        let index = favoritesArray.indexOf(itemId)
        if (index > -1) {
          favoritesArray.splice(index, 1);
        }

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({
              serverSuccessPlan: true,
              serverSuccessMessagePlan: 'Favorite saved!'
            })
          } else {
            console.log('did not save successfully')
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'error:' + response.data.message
            })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'there was an error saving favorites'
            })
        });

      } else {

        console.log('adding item: ', favoritesArray, itemId)
        favoritesArray.push(itemId)
        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({
              serverSuccessPlan: true,
              serverSuccessMessagePlan: 'Favorite saved!'
            })
          } else {
            console.log('did not save successfully')
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'error:' + response.data.message
            })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'there was an error saving favorites'
            })
        });
      }

      console.log('favorites', favoritesArray)
      this.setState({ favorites: favoritesArray })
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderItems() {
      console.log('renderItems called', this.state.filteredTrainingPrograms)

      let rows = []
      for (let i = 1; i <= this.state.filteredTrainingPrograms.length; i++) {

        const index = i - 1

        let url = ''
        if (this.state.filteredTrainingPrograms[index].SchoolUrl) {
          url = 'http://' + this.state.filteredTrainingPrograms[index].SchoolUrl
        }

        rows.push(
          <div key={i}>
            <div className="horizontal-padding">
              <div className="spacer"/><div className="spacer"/>

              <a href={url} className="background-button full-width" target="_blank">
                <span className="fixed-column-60">
                  <div className="half-spacer"/>
                  <img src={courseIconBlue} alt="Guided Compass project icon" className="image-60-auto"/>
                </span>
                <span className="calc-column-offset-130 left-padding-20">
                  <p className="heading-text-5 cta-color bottom-padding-5">{this.state.filteredTrainingPrograms[index].SchoolName}</p>
                  <p className="standard-color">{this.state.filteredTrainingPrograms[index].ProgramName}</p>
                  <p className="description-text-2 standard-color">{this.state.filteredTrainingPrograms[index].Address} {this.state.filteredTrainingPrograms[index].City}, {this.state.filteredTrainingPrograms[index].StateAbbr} {this.state.filteredTrainingPrograms[index].Zip}</p>
                  <p className="description-text-2 standard-color">{this.state.filteredTrainingPrograms[index].StudentGraduated}</p>
                  {(this.state.filteredTrainingPrograms[index].Occupationslist) && (
                    <div>
                      {this.state.filteredTrainingPrograms[index].Occupationslist.map(value =>
                        <div className="float-left tag-container-8 light-background right-padding description-text-3 half-bold-text standard-border">
                          {value.Name}
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  )}
                </span>
                <div className="fixed-column-37 left-padding top-padding-15">
                  <img src={linkIconBlue} alt="GC" className="image-auto-22" />
                </div>
                <div className="fixed-column-33 left-padding">
                  <span className="float-left">
                    <div className="spacer"/><div className="half-spacer"/>
                    <Link to={''} className="background-link">
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                    </Link>
                  </span>
                </div>
                <div className="clear" />
                <div className="half-spacer" />

              </a>
            </div>
            <div className="clear" />

            <div className="left-padding-40">
              <div className="float-left">
                {/*<button className="btn background-button float-left left-margin-50 row-5 horizontal-padding-5" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: true, showGrade: false, selectedIndex1: index }) }>*/}
                <div className="float-left left-margin-50">
                  <a href={url} className="btn background-button float-left" target="_blank">
                    <div className="row-5 horizontal-padding-5">
                      <div className="float-left right-margin-5 bottom-padding">
                        <div>
                          <img src={detailsIconGrey} alt="GC icon" className="image-auto-20" />
                        </div>
                      </div>
                      <div className="float-left top-margin-negative-3 bottom-padding">
                        <label className="description-text-2 description-text-color">View Program</label>
                      </div>
                    </div>
                  </a>
                </div>

                {/*</button>*/}
                <div className="clear"/>
              </div>

              {(this.props.pageSource !== 'landingPage') && (
                <div>
                  <div className="vertical-separator-2"/>

                  <div className="float-left">
                    <button className="btn background-button float-left row-5 horizontal-padding-5" type="button" onClick={() => this.markCompleted(index) }>
                      <div className="float-left right-margin-5">
                        <div>
                          {/*<img src={(this.state.filteredTrainingPrograms[i - 1].completed) ? checklistIconBlue : checklistIconGrey} alt="Feedback icon logo" className="image-auto-22 padding-3" />*/}
                        </div>
                      </div>
                      <div className="float-left top-margin-negative-3">
                        <label className={(this.state.filteredTrainingPrograms[i - 1].completed) ? "description-text-2 cta-color" : "description-text-2 description-text-color"}>Mark Applied</label>
                      </div>
                    </button>
                    <div className="clear"/>
                  </div>
                </div>
              )}

            </div>

            {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[i - 1] && this.state.sortCriteriaArray[i - 1].name) && (
              <div className="left-padding-120">
                <div className="half-spacer" />
                <p className="description-text-2 error-color">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
              </div>
            )}
            {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[i - 1] && this.state.filterCriteriaArray[i - 1].name) && (
              <div className="left-padding-120">
                <div className="half-spacer" />
                <p className="description-text-2 error-color">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
              </div>
            )}

            <hr className="cell-separator-advisor"/>
          </div>
        )
      }

      return rows
    }

    closeModal() {
      console.log('closeModal in projects: ', this.state.showProjectDetail)

      this.setState({ modalIsOpen: false });

    }

    itemClicked(index) {
      console.log('itemClicked called', index)

      const selectedJob = this.state.jobOptions[index]
      this.setState({ selectedJob })

      this.pullTrainingPrograms(selectedJob,this.state.location, this.state.radius, this.state.occupation, this.state.programName, this.state.programLength, this.state.state, this.state.region)
    }

    render() {

      let passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}

      const passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
      const passedPlaceholder = 'Search projects by name or creator...'

      let filterClass = "floating-container card top-margin-2-percent right-margin-2-percent"

      if (this.props.pageSource === 'landingPage') {
        passedClassName = "card full-width"
        passedStyle = {}
        filterClass = "card full-width bottom-margin"
      }

      return (
          <div>
              <div>
                {(this.props.pageSource !== 'landingPage') && (
                  <div className={passedClassName} style={passedStyle}>
                    <div>
                      <div className="calc-column-offset-45">
                        <div className="spacer" />
                        <p className="heading-text-2">{this.state.filteredTrainingPrograms.length} Training Programs</p>
                      </div>
                      <div className="fixed-column-45">
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="row-5 right-padding-20">
                          {/*
                          <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                            <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="Compass search icon" className="image-25-auto"/>
                          </button>*/}
                        </div>
                      </div>

                      <div className="clear" />
                    </div>
                    <div className="spacer" />
                  </div>
                )}

                {(this.state.showingSearchBar) && (
                  <div className={filterClass} style={passedStyle2}>
                    <div>
                      <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width">
                        <div className="half-spacer"/>
                        <div className="float-left top-padding-8 left-padding-3">
                          <img src={searchIcon} alt="GC" className="image-auto-20"/>
                        </div>
                        <div className="calc-column-offset-23">
                          <input type="text" className="text-field clear-border" placeholder={passedPlaceholder} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="clear" />
                      <div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Filter</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('filter'))}

                      <div className="clear" />
                      <div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Sort</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('sort'))}
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.jobOptions) && (
                  <div className={filterClass} style={passedStyle2}>

                    <p className="bold-text description-text-3">Courtesy of Career OneStop</p>

                    <div className="row-10">
                      <label className="profile-label">Search Training Programs</label>
                      <div className="filter-field-search full-width white-background clear-margin">
                        <div className="search-icon-container">
                          <img src={searchIcon} alt="Compass search icon"/>
                        </div>
                        <div className="filter-search-container calc-column-offset-100-static">

                          <input type="text" className="text-field clear-border" placeholder="Search training programs..." name="search" value={this.state.searchString} onChange={this.formChangeHandler} />
                        </div>
                        {/*
                        <div className="search-icon-container top-margin-negative-3">
                          <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                            <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                          </button>
                        </div>*/}
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <label className="profile-label">Select a Pathway</label>
                      <select name="pathway" value={this.state.selectedPathway} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.pathwayOptions.map(value =>
                          <option key={value.name} value={value.name}>{value.name}</option>
                        )}
                      </select>
                    </div>

                    <div className="row-10">
                      <label className="profile-label">Click a Competency Search Term Below</label>
                      {console.log('show jobOptions: ', this.state.jobOptions)}
                      {this.state.jobOptions.map((value, optionIndex) =>
                        <div>
                          {(value === this.state.selectedJob) ? (
                            <button className="background-button clear-padding float-left" onClick={() => this.itemClicked(optionIndex)}>
                              <div className="tag-container-8 float-left cta-background-color cta-border right-margin">
                                <p className="description-text-3">{value}</p>
                              </div>
                            </button>
                          ) : (
                            <button className="background-button clear-padding float-left" onClick={() => this.itemClicked(optionIndex)}>
                              <div className="tag-container-8 float-left light-background standard-border right-margin">
                                <p className="description-text-3">{value}</p>
                              </div>
                            </button>
                          )}

                        </div>
                      )}
                      <div className="clear" />
                    </div>

                  </div>
                )}

                {(this.state.animating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Calculating results...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.filteredTrainingPrograms.length > 0) && (
                      <div className={passedClassName} style={passedStyle}>
                        {this.renderItems()}
                      </div>
                    )}
                  </div>
                )}

                {(this.props.pageSource === 'landingPage') && (
                  <div className="clear" />
                )}

              </div>

          </div>
      )
    }
}


export default withRouter(TrainingPrograms);
