import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import withRouter from '../components/Functions/WithRouter';
import {convertDateToString} from './Functions/ConvertDateToString';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const locationIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/location-icon.png';
const timeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon.png';
const infoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon.png';

class EventRSVP extends Component {
    constructor(props) {
        super(props)
        this.state = {
          rerouteEveryone: true,
          questionIndex: 0,
          survey: [],
          responses: [],
          employers: [],

          roleNameOptions: ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Counselor','Employer Representative / Mentor','Other'],
          gradYearOptions: [],
          degreeOptions: [],
          studentActivityOptions: [],
          functionOptions: [],
          industryOptions: [],
          teacherActivityOptions: [],
          mentorActivityOptions: [],

          currentQuestion: '',
        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      window.scrollTo(0, 0)

      let { orgCode, eventId, roleName } = this.props.params

      if (this.state.rerouteEveryone) {
        this.props.navigate('/app/opportunities/' + eventId)
      } else {
        //user navigated from a landing page or from the app

        const emailId = localStorage.getItem('email');
        const username = localStorage.getItem('username');
        const cuFirstName = localStorage.getItem('firstName');
        const cuLastName = localStorage.getItem('lastName');
        // const activeOrg = localStorage.getItem('activeOrg');
        const activeOrg = orgCode

        let gradYearOptions = ['']
        const startingPoint = new Date().getFullYear() - 6
        for (let i = 1; i <= 10; i++) {
          gradYearOptions.push(startingPoint + i)
        }

        let responses = []
        let roleNameLinked = null
        if (roleName) {
          if (roleName.toLowerCase() === 'student' || roleName.toLowerCase() === 'career-seeker') {
            roleName = 'Student / Career Seeker'
            roleNameLinked = roleName
          } else if (roleName.toLowerCase() === 'volunteer' || roleName.toLowerCase() === 'mentor' || roleName.toLowerCase() === 'employer') {
            roleName = 'Employer Representative / Mentor'
            roleNameLinked = roleName
          }
        }

        this.setState({ emailId, username, cuFirstName, cuLastName, activeOrg, eventId, gradYearOptions, responses, roleName, roleNameLinked })

        Axios.get('/api/postings/byid', { params: { _id: eventId } })
        .then((response) => {
          console.log('Posting id', response.data);

          if (response.data.success) {
            console.log('Posting successfully queried')

            const event = response.data.posting

            let startDateString = null
            let endDateString = null
            let eventPassed = false
            startDateString = convertDateToString(new Date(event.startDate),'datetime-2')
            endDateString = convertDateToString(new Date(event.endDate),'datetime-2')

            let dateToTest = null
            if (event.endDate && new Date(event.endDate)) {
              dateToTest = event.endDate
            } else if (event.startDate && new Date(event.startDate)) {
              dateToTest = event.startDate
            }

            if (dateToTest) {
              if (new Date().getTime() > new Date(dateToTest).getTime()) {
                eventPassed = true
              }
            }

            let subscribedForReminders = false
            if (event.sendReminderText) {
              subscribedForReminders = true
            }

            this.setState({ event, startDateString, endDateString, eventPassed, subscribedForReminders })

            Axios.get('/api/customassessments/byid', { params: { _id: event.surveyId } })
            .then((response) => {
              console.log('Survey id', response.data);

              if (response.data.success) {
                console.log('Survey successfully queried')

                let survey = response.data.assessment
                // const currentQuestion = survey.questions[0]
                this.setState({ survey });

                if (event.customAssessmentId) {
                  Axios.get('/api/customassessments/byid', { params: { _id: event.customAssessmentId } })
                  .then((response) => {
                    console.log('Custom assessment id', response.data);

                    if (response.data.success) {
                      console.log('Assessment successfully queried')

                      let customAssessment = response.data.assessment
                      this.setState({ customAssessment });

                    } else {
                      console.log('Custom assessment query did not work', response.data.message)
                    }

                  }).catch((error) => {
                      console.log('Org info query did not work for some reason', error);
                  });
                }

              } else {
                console.log('Survey query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });

          } else {
            console.log('Posting query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posting query did not work for some reason', error);
        });

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const webLogoURI = response.data.orgInfo.webLogoURI

              this.setState({ orgName, webLogoURI });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let functionOptions = []
            for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
              if (i > 1) {
                functionOptions.push(response.data.workOptions[0].functionOptions[i - 1])
              }
            }

            let industryOptions = []
            for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
              if (i > 1) {
                industryOptions.push(response.data.workOptions[0].industryOptions[i - 1])
              }
            }

            const degreeOptions = response.data.workOptions[0].degreeOptions

            let gradYearOptions = ['']
            const startingPoint = new Date().getFullYear() - 6
            for (let i = 1; i <= 10; i++) {
              gradYearOptions.push(startingPoint + i)
            }

            let studentActivityOptions = response.data.workOptions[0].studentActivityOptions
            let teacherActivityOptions = response.data.workOptions[0].teacherActivityOptions
            let mentorActivityOptions = response.data.workOptions[0].mentorActivityOptions

            this.setState({ functionOptions, industryOptions,
            degreeOptions, gradYearOptions,
            studentActivityOptions, teacherActivityOptions, mentorActivityOptions })

          } else {
            console.log('no jobFamilies data found', response.data.message)

            const functionOptions = [{value: 'Undecided'}]
            const industryOptions = [{value: 'Undecided'}]

            this.setState({ functionOptions, industryOptions })

          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value )

      let responses = this.state.responses
      if (event.target.name === 'employerName') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchEmployers(event.target.value)
      } else if (event.target.name.includes('responses|')) {
        const index = Number(event.target.name.split("|")[1])
        responses[index] = event.target.value
        this.setState({ responses })
        // console.log('response me: ', index, event.target.name, responses)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    itemClicked(value,type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        let roleName = value
        this.setState({ roleName })
      } else if (type === 'studentActivity') {
        let studentActivityInterests = this.state.studentActivityInterests
        if (studentActivityInterests) {
          if (studentActivityInterests.includes(value)) {
            let index = studentActivityInterests.indexOf(value)
            studentActivityInterests.splice(index, 1)

          } else {
            studentActivityInterests.push(value)
          }
        } else {
          studentActivityInterests = [value]
        }
        this.setState({ studentActivityInterests })
      } else if (type === 'industry') {
        let industries = this.state.industries
        if (industries) {
          if (industries.includes(value)) {
            let index = industries.indexOf(value)
            industries.splice(index, 1)

          } else {
            industries.push(value)
          }
        } else {
          industries = [value]
        }
        this.setState({ industries })
      } else if (type === 'function') {
        let functions = this.state.functions
        if (functions) {
          if (functions.includes(value)) {
            let index = functions.indexOf(value)
            functions.splice(index, 1)

          } else {
            functions.push(value)
          }
        } else {
          functions = [value]
        }
        this.setState({ functions })
      } else if (type === 'teacherActivity') {
        let teacherActivityInterests = this.state.teacherActivityInterests
        if (teacherActivityInterests) {
          if (teacherActivityInterests.includes(value)) {
            let index = teacherActivityInterests.indexOf(value)
            teacherActivityInterests.splice(index, 1)

          } else {
            teacherActivityInterests.push(value)
          }
        } else {
          teacherActivityInterests = [value]
        }
        this.setState({ teacherActivityInterests })
      } else if (type === 'mentorActivity') {
        let mentorActivityInterests = this.state.mentorActivityInterests
        if (mentorActivityInterests) {
          if (mentorActivityInterests.includes(value)) {
            let index = mentorActivityInterests.indexOf(value)
            mentorActivityInterests.splice(index, 1)

          } else {
            mentorActivityInterests.push(value)
          }
        } else {
          mentorActivityInterests = [value]
        }
        this.setState({ mentorActivityInterests })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            if (employers && employers.length > 0) {
              console.log('we in')
              this.setState({ employers });
            }

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employers = []
      const accountCode = employer.accountCode
      const selectedAnswer = employer.employerName
      // let responses = this.state.responses
      let employerName = employer.employerName

      this.setState({ employers, accountCode, selectedAnswer, employerName })

    }

    reserveSpot(questionIndex) {
      console.log('reserveSpot called', this.state.degree)
      //reserve spot, save user, and go to dashboard
      this.setState({ errorMessage: null, successMessage: null, disableSubmit: true })

      const postingId = this.state.eventId
      const postingTitle = this.state.event.title

      let questions = []

      if (!this.state.firstName || this.state.firstName === '') {
        this.setState({ errorMessage: 'Please add your first name', disableSubmit: false })
      } else if (!this.state.lastName || this.state.lastName === '') {
        this.setState({ errorMessage: 'Please add your last name ', disableSubmit: false})
      } else if (!this.state.email || this.state.email === '') {
        this.setState({ errorMessage: 'Please add your email', disableSubmit: false})
      } else if (!this.state.email.includes('@')) {
        this.setState({ errorMessage: 'Please add a valid email', disableSubmit: false})
      } else if (!this.state.roleName || this.state.roleName === '') {
        this.setState({ errorMessage: 'Please add a role that best describes you', disableSubmit: false})
      } else if (this.state.roleName === 'Other' && (!this.state.otherRoleName || this.state.otherRoleName === '')) {
        this.setState({ errorMessage: 'Please specify your role (in the field below other)', disableSubmit: false})
      } else if (this.state.roleName === 'Student / Career Seeker' && (!this.state.schoolName || this.state.schoolName === '')) {
        this.setState({ errorMessage: 'Please add your current school or the last school that you attended', disableSubmit: false})
      } else if (this.state.roleName === 'Student / Career Seeker' && (!this.state.gradYear || this.state.gradYear === '')) {
        this.setState({ errorMessage: 'Please add your graduation year', disableSubmit: false})
      } else if (this.state.roleName === 'Student / Career Seeker' && (!this.state.degree || this.state.degree === '')) {
        this.setState({ errorMessage: 'Please add the degree type', disableSubmit: false})
      } else if (this.state.roleName === 'Teacher' && (!this.state.schoolName || this.state.schoolName === '')) {
        this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Work-Based Learning Coordinator' && (!this.state.schoolName || this.state.schoolName === '')) {
        this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Counselor' && (!this.state.schoolName || this.state.schoolName === '')) {
        this.setState({ errorMessage: 'Please add the name of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Teacher' && (!this.state.degree || this.state.degree === '')) {
        this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Work-Based Learning Coordinator' && (!this.state.degree || this.state.degree === '')) {
        this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Counselor' && (!this.state.degree || this.state.degree === '')) {
        this.setState({ errorMessage: 'Please add the degree type of the school that employs you', disableSubmit: false})
      } else if (this.state.roleName === 'Employer Representative / Mentor' && (!this.state.jobTitle || this.state.jobTitle === '')) {
        this.setState({ errorMessage: 'Please add your job title', disableSubmit: false})
      } else if (this.state.roleName === 'Employer Representative / Mentor' && (!this.state.employerName || this.state.employerName === '')) {
        this.setState({ errorMessage: 'Please add your employer name', disableSubmit: false})
      } else {
        console.log('passed the initial check')

        let phoneNumber = this.state.phoneNumber
        if (this.state.roleName === 'Student / Career Seeker' && this.state.event.rsvpComponents.some(comp => comp.name === 'Phone Number')) {
          const pIndex = this.state.event.rsvpComponents.findIndex(comp => comp.name === 'Phone Number')
          if (this.state.event.rsvpComponents[pIndex].required) {
            if (!phoneNumber || phoneNumber === '' || phoneNumber.length < 7 || phoneNumber.length > 14)
            return this.setState({ errorMessage: 'Please add a valid phone number', disableSubmit: false})
          }
        }

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        let roleName = ''
        let rsvpType = ''

        if (this.state.roleName === 'Other') {
          roleName = this.state.otherRoleName
          rsvpType = roleName
        } else {
          if (this.state.roleName === 'Student / Career Seeker') {
            roleName = 'Student'
            rsvpType = roleName
          } else if (this.state.roleName === 'Employer Representative / Mentor') {
            roleName = 'Mentor'
            rsvpType = roleName
          } else {
            roleName = this.state.roleName
            rsvpType = roleName
          }
        }

        const schoolName = this.state.schoolName
        const gradYear = this.state.gradYear
        const degree = this.state.degree
        const major = this.state.major
        const studentActivityInterests = this.state.studentActivityInterests
        const industries = this.state.industries
        const functions = this.state.functions
        let teacherActivityInterests = this.state.teacherActivityInterests
        let jobTitle = this.state.jobTitle
        let employerName = this.state.employerName
        let mentorActivityInterests = this.state.mentorActivityInterests
        if (roleName === 'Student') {
          teacherActivityInterests = null
          jobTitle = null
          employerName = null
          mentorActivityInterests = null
        }

        // old values
        let activities = []
        let otherAnswers = []

        const orgCode = this.state.activeOrg

        let accountCode = ''
        if (this.state.accountCode && this.state.accountCode !== '') {
          accountCode = this.state.accountCode
        } else {
          if (employerName) {
            accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
          }
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        //stuff for email notification
        const orgName = this.state.orgName
        const orgContactFirstName = this.state.event.orgContactFirstName
        const orgContactLastName = this.state.event.orgContactLastName
        const orgContactEmail = this.state.event.orgContactEmail
        const location = this.state.event.location
        const startDate = this.state.startDateString

        const existingUser = false
        const departments = this.state.event.departments

        const subscribedForReminders = this.state.subscribedForReminders

        let rsvpResponses = []
        if (this.state.roleName === 'Student / Career Seeker') {

          rsvpResponses.push({ question: 'First Name', answer: this.state.firstName })
          rsvpResponses.push({ question: 'Last Name', answer: this.state.lastName})
          rsvpResponses.push({ question: 'Email', answer: this.state.email})
          rsvpResponses.push({ question: 'Role Name', answer: this.state.roleName})
          rsvpResponses.push({ question: 'Other Role Name', answer: this.state.otherRoleName})
          rsvpResponses.push({ question: 'School Name', answer: this.state.schoolName })
          rsvpResponses.push({ question: 'Grad Year', answer: this.state.gradYear})
          rsvpResponses.push({ question: 'Degree Type', answer: this.state.degree })
          rsvpResponses.push({ question: 'Major / Pathway', answer: this.state.major})
          rsvpResponses.push({ question: 'Activity Interests', answer: this.state.studentActivityInterests })

          // verify that all required fields are filled out
          if (this.state.customAssessment && this.state.customAssessment.questions) {
            for (let i = 1; i <= this.state.customAssessment.questions.length; i++) {
              if (this.state.customAssessment.questions[i - 1].required && !this.state.responses[this.state.survey.questions.length + i - 1]) {
                return this.setState({ errorMessage: this.state.customAssessment.questions[i - 1].name + ' is a required field.', disableSubmit: false})
              } else {
                rsvpResponses.push({
                  question: this.state.customAssessment.questions[i - 1].name,
                  answer: this.state.responses[this.state.survey.questions.length + i - 1]
                })
              }
            }
          }
        }

        Axios.post('/api/rsvp', {
          postingId, rsvpType, postingTitle, questions, firstName, lastName, email, jobTitle, employerName, activities,
          gradYear, degree, major, studentActivityInterests, teacherActivityInterests, mentorActivityInterests,
          industries, functions, schoolName, otherAnswers, orgCode, accountCode, roleName, createdAt, updatedAt,
          orgName, orgContactFirstName, orgContactLastName, orgContactEmail, location, startDate, existingUser,
          departments, phoneNumber, subscribedForReminders, rsvpResponses
        })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Reserve worked', response.data);

            window.scrollTo(0, 0)
            this.setState({ done: true, questionIndex, disableSubmit: false })


          } else {
            console.error('there was an error reserving');

            this.setState({ errorMessage: response.data.message, disableSubmit: false
            })
          }
        }).catch((error) => {
            console.log('The rsvp post did not work', error);
            this.setState({ errorMessage: error.toString(), disableSubmit: false })
        });
      }
    }

    renderQuestions(roleName) {
      console.log('renderQuestions called', roleName)

      if (roleName === 'Student / Career Seeker') {
        console.log('in student')

        return (
          <div key="student">
            {(this.state.event && this.state.event.rsvpComponents && this.state.event.rsvpComponents.some(comp => comp.name === 'Phone Number')) && (
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Cell Phone Number<label className="error-color">*</label></label>
                  <input className="text-field" type="text" placeholder="(555) 555-5555" name="phoneNumber" value={this.state.phoneNumber} onChange={this.formChangeHandler}/>
                </div>
                {(this.state.event && this.state.event.sendReminderText) && (
                  <div className="container-right">
                    <label className="profile-label">Text Reminder</label>
                    <Switch
                      onChange={(change) => this.setState({ subscribedForReminders: change })}
                      checked={this.state.subscribedForReminders}
                      id="normal-switch"
                    />
                    <p className="profile-descriptor top-margin-5">[If enabled, you will receive a text reminder before the event.]</p>
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">School Name (Current or Last)<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="School name" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">Grad Year<label className="error-color">*</label></label>
                <select name="gradYear" className="dropdown" value={this.state.gradYear} onChange={this.formChangeHandler}>
                    {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">Degree Type<label className="error-color">*</label></label>
                <select name="degree" className="dropdown" value={this.state.degree} onChange={this.formChangeHandler}>
                    {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>
              <div className="container-right">
                <label className="profile-label">Major / Pathway</label>
                <input className="text-field" type="text" placeholder="Major / pathway..." name="major" value={this.state.major} onChange={this.formChangeHandler} />
              </div>
              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Would you be interested in any of the following?</label>
              {this.state.studentActivityOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'studentActivity')}>
                      {(this.state.studentActivityInterests && this.state.studentActivityInterests.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            {(this.state.customAssessment) && (
              <div>
                {this.state.customAssessment.questions.map((value, index) =>
                  <div key={value}>
                    {((!value.conditional) || (value.conditional && (value.conditionNumber - 1 === (index + this.state.survey.questions.length)) && value.conditionValue === this.state.responses[index - 1])) && (
                      <div key={value} className="row-10">
                        <label className="profile-label">{value.name}{(value.required) && <label className="error-color">*</label>}</label>
                        {(value.questionType === 'Date' || value.questionType === 'Birthdate') && (
                          <input type="date" className="date-picker white-background" name={'responses|' + (index + this.state.survey.questions.length)} value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}></input>
                        )}
                        {(value.questionType === 'Short Answer') && (
                          <input type="text" className="text-field" placeholder="Your answer..." name={'responses|' + (index + this.state.survey.questions.length)} value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}></input>
                        )}
                        {(value.questionType === 'Multiple Choice') && (
                          <select name={'responses|' + (index + this.state.survey.questions.length)} className="dropdown" value={this.state.responses[index + this.state.survey.questions.length]} onChange={this.formChangeHandler}>
                              {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        )}
                        {(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox') && (
                          <div>
                            {value.answerChoices.map((value2, optionIndex) =>
                              <div key={value2 + optionIndex}>
                                <div className="top-margin-5 right-padding">
                                  {(this.state.responses[index + this.state.survey.questions.length] && this.state.responses[index + this.state.survey.questions.length].includes(value2)) ? (
                                    <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked((index + this.state.survey.questions.length), optionIndex, value, value2)}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2 white-text">{value2}</p>
                                        </div>
                                      </div>
                                    </button>
                                  ) : (
                                    <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked((index + this.state.survey.questions.length), optionIndex, value, value2)}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2">{value2}</p>
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )

      } else if (roleName === 'Teacher' || roleName === 'Work-Based Learning Coordinator' || roleName === 'Counselor') {

        return (
          <div key="WBLC">
            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">School Name<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="School name" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">School Type<label className="error-color">*</label></label>
                <select name="degree" className="dropdown" value={this.state.degree} onChange={this.formChangeHandler}>
                    {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Do you focus on any of the below industries?</label>
              {this.state.industryOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'industry')}>
                      {(this.state.industries && this.state.industries.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Do you focus on any of the below work functions?</label>
              {this.state.functionOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'function')}>
                      {(this.state.functions && this.state.functions.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Would you be interested in any of the following?</label>
              {this.state.teacherActivityOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'teacherActivity')}>
                      {(this.state.teacherActivityInterests && this.state.teacherActivityInterests.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          </div>
        )
      } else if (roleName === 'Employer Representative / Mentor') {
        return (
          <div key="employer">
            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">Job Title<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">Employer Name<label className="error-color">*</label></label>
                <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />

                {(this.state.employers.length > 0) && (
                  <div>
                    <div className="spacer"/>
                    {this.state.employers.map(value =>
                      <div key={value._id} className="left-text bottom-margin-5">
                        <button className="background-button" onClick={() => this.employerClicked(value)}>
                          <div>
                            <div className="float-left right-padding">
                              <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                            </div>
                            <div className="float-left">
                              <p className="cta-color">{value.employerName}</p>
                            </div>
                          </div>
                        </button>
                      </div>)}
                  </div>
                )}
              </div>

              <div className="clear" />
            </div>

            <div className="edit-profile-row">
              <label className="profile-label">Would you be interested in any of the following?</label>
              {this.state.mentorActivityOptions.map((value, index) =>
                <div key={value} className="float-left">
                  <div className="float-left row-5 right-padding">
                    <button className="background-button" onClick={() => this.itemClicked(value,'mentorActivity')}>
                      {(this.state.mentorActivityInterests && this.state.mentorActivityInterests.includes(value)) ? (
                        <div className="tag-container-4">
                          <p className="description-text-2 white-text half-bold-text">{value}</p>
                        </div>
                      ) : (
                        <div className="tag-container-inactive-2">
                          <p className="description-text-2 cta-color half-bold-text">{value}</p>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          </div>
        )
      }
    }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let responses = this.state.responses
      if (responses[index]) {
        if (responses[index].includes(value2)) {
          const deleteIndex = responses[index].indexOf(value2)
          responses[index].splice(deleteIndex, 1)
        } else {
          responses[index].push(value2)
        }

      } else {
        responses[index] = [value2]
      }

      this.setState({ responses })

    }

    render() {

        return (
          <div className="medium-background bottom-padding-50-percent">
            <header className="front-page-header">
                <div className="relative-column-20 row-30">
                </div>
                <div className="relative-column-60 center-horizontally row-30">
                  <ul className="horizontal-list flex-center center-text">
                    {(this.state.webLogoURI) ? (
                      <ul className="horizontal-list flex-center center-text">
                        <li><Link to={'/'}><img src={logoImg} alt="Compass logo" className="image-auto-40" /></Link></li>
                        <li><div className="width-15"/></li>
                        <li><Link to={'/'}><img src={addIcon} alt="Guided Compass add logo" className="image-auto-20" /></Link></li>
                        <li><div className="width-15"/></li>
                        <li><Link to={'/'}><img src={this.state.webLogoURI} alt="Guided Compass org partner logo" className="image-auto-40" /></Link></li>
                      </ul>
                    ) : (
                      <ul className="horizontal-list flex-center center-text">
                        <li><Link to={'/'}><img src={logoImg} alt="Compass logo" className="image-auto-40" /></Link></li>
                      </ul>
                    )}
                  </ul>
                </div>
                <div className="relative-column-20 row-30 right-padding-3-percent">

                </div>
                <div className="clear" />
                <div style={{ transition: 'all 1.2s ease-in', opacity: this.state.opacity, backgroundColor: '#fff', width: '60%', borderRadius: '10px', margin: 'auto', padding: '5%', transform: 'translate(0%, 1%)'}}>
                  {(this.state.survey && this.state.event) && (
                    <div>

                      <p className="heading-text-2 bottom-padding">{this.state.event.title} RSVP Form</p>

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.done) ? (
                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <p className="heading-text-3 cta-color">Registration Complete!</p>
                          <div className="spacer" /><div className="spacer" />
                          <p className="description-text-color">We look forward to seeing you there! Please contact <label className="half-bold-text">{this.state.event.orgContactEmail}</label> with questions about the event or if you would like access to the <label className="half-bold-text">Guided Compass {(this.state.roleName !== 'Other') && this.state.roleName} Portal!</label></p>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <div className="row-10">
                              <div className="float-left right-margin top-margin-3">
                                <img src={profileIconBig} alt="Compass logo" className="image-auto-23" />
                              </div>
                              <div className="calc-column-offset-33">
                                <p>Hosted by {this.state.orgName}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="row-10">
                              <div className="float-left right-margin top-margin-3">
                                <img src={locationIcon} alt="Compass logo" className="image-auto-23 right-margin-5"/>
                              </div>
                              <div className="calc-column-offset-33">
                                <p>Location: {this.state.event.location}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="row-10">
                              <div className="float-left right-padding top-margin-3">
                                <img src={timeIcon} alt="Compass logo" className="image-auto-23" />
                              </div>
                              <div className="float-left">
                                <p>{this.state.startDateString} - {this.state.endDateString}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="row-10">
                              <div className="float-left right-padding top-margin-3">
                                <img src={infoIcon} alt="Compass logo" className="image-auto-23" />
                              </div>
                              <div className="calc-column-offset-33">
                                <p className="prewrap">{this.state.event.summary}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                            <hr className="clear-margin clear-padding cta-border-color"/>
                          </div>

                          {(this.state.eventPassed === true) ?   (
                            <div>
                              <div className="spacer"/><div className="half-spacer"/>
                              <p className="error-message">This event has already passed,</p>
                            </div>
                          ) : (
                            <div>
                              <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                              <div className="edit-profile-row">
                                <div className="container-left">
                                  <label className="profile-label">First Name<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                                </div>
                                <div className="container-right">
                                    <label className="profile-label">Last Name<label className="error-color">*</label></label>
                                    <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="edit-profile-row">
                                <label className="profile-label">Email<label className="error-color">*</label></label>
                                <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                              </div>
                              {(!this.state.roleNameLinked) && (
                                <div className="edit-profile-row">
                                  <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                                  {this.state.roleNameOptions.map((value, index) =>
                                    <div key={value} className="float-left">
                                      <div className="float-left row-5 right-padding">
                                        <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                          {(this.state.roleName === value) ? (
                                            <div className="tag-container-4">
                                              <p className="description-text-2 white-text half-bold-text">{value}</p>
                                            </div>
                                          ) : (
                                            <div className="tag-container-inactive-2">
                                              <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                            </div>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <div className="clear" />
                                </div>
                              )}

                              {(this.state.roleName && this.state.roleName === 'Other') && (
                                <div className="edit-profile-row">
                                  <label className="profile-label">Other - Role Name (Please Specify)<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Role Name" name="otherRoleName" value={this.state.responses[4]} onChange={this.formChangeHandler} />
                                </div>
                              )}

                              {this.renderQuestions(this.state.roleName)}

                              <div className="half-spacer"/>

                              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5 description-text-2">{this.state.errorMessage}</p>}
                              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5 description-text-2">{this.state.successMessage}</p>}

                              <button type="button" onClick={() => this.reserveSpot()} disabled={this.state.disableSubmit} className="btn btn-primary">Register</button>
                            </div>
                          )}

                        </div>
                      )}

                    </div>
                  )}
                </div>
            </header>
          </div>
        )
    }
}

export default withRouter(EventRSVP);
