import React, {useEffect,useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Axios from 'axios';

import {convertDateToString} from '../Functions/ConvertDateToString';

// import loadingGIF from '../../images/loading-gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

export default function CheckoutFormBooking(passedValues) {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clientSecret = passedValues.clientSecret

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret = new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   fetch("/create-payment-intent", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setClientSecret(data.clientSecret));
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('no stripe or elements', stripe, elements)
      return;
    }

    setIsLoading(true);
    // console.log('show stripe value: ', stripe)

    // const { error } = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: "https://www.guidedcompass.com/help",
    //   },
    // });

    // console.log('show clientSecret string: ', clientSecret, passedValues)
    //

    // const { error } = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card,
    //     billing_details: {
    //       name: 'Jenny Rosen',
    //     },
    //   },
    // }).then(function(result) {
    //   // Handle result.error or result.paymentIntent
    // });
    //
    // // This point will only be reached if there is an immediate error when
    // // confirming the payment. Otherwise, your customer will be redirected to
    // // your `return_url`. For some payment methods like iDEAL, your customer will
    // // be redirected to an intermediate site first to authorize the payment, then
    // // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }
    //
    // setIsLoading(false);

    let price = passedValues.price
    if (typeof price !== 'number') {
      price = Number(price.replace('$',''))
    }

    Axios.post('/api/payments/create-payment-intent', { price }).then((response) => {
      console.log('we tryin to provide payment intent', response.data)
      if (response.data.success) {
        //save values
        console.log('Payment intent created');

        const clientSecret = response.data.clientSecret

        const card = elements.getElement(CardElement)
        stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card,
            billing_details: {
              name: passedValues.firstName + ' ' + passedValues.lastName,
            },
          },
        })
        .then(function(result) {
          // Handle result.error or result.paymentIntent
          console.log("what's the result: ", result)
          // setMessage("An unexpected error occurred.");
          setIsLoading(false);

          result['success'] = true
          result['successMessage'] = "The transaction was successful!"
          result['purchased'] = true

          const firstName = passedValues.firstName
          const lastName = passedValues.lastName
          const email = passedValues.email
          const orgName = passedValues.orgName
          const accountCode = passedValues.accountCode
          const employerName = passedValues.employerName
          const name = passedValues.name
          const slug = passedValues.slug
          const category = passedValues.category
          const imageURL = passedValues.imageURL
          const productId = passedValues.productId
          const advisorFirstName = passedValues.advisorFirstName
          const advisorLastName = passedValues.advisorLastName
          const advisorEmail = passedValues.advisorEmail
          const advisorBio = passedValues.advisorBio
          const advisorPic = passedValues.advisorPic
          const advisorProfileLink = passedValues.advisorProfileLink
          const description = passedValues.description
          const customerId = passedValues.customerId
          const price = passedValues.price
          const quantity = passedValues.quantity
          const orgCode = passedValues.orgCode
          const promoCode = passedValues.promoCode
          const bookingDate = passedValues.bookingDate
          const bookingDateFormatted = convertDateToString(new Date(passedValues.bookingDate),"datetime-2")
          const profileItems = passedValues.profileItems
          const recipientType = passedValues.recipientType
          const recipientFirstName = passedValues.recipientFirstName
          const recipientLastName = passedValues.recipientLastName
          const recipientEmail = passedValues.recipientEmail
          const createdAt = new Date()
          const updatedAt = new Date()

          Axios.post('/api/payments/purchase', {
            firstName, lastName, email, orgName, accountCode, employerName,
            name, slug, category, imageURL, productId,
            advisorFirstName, advisorLastName, advisorEmail, advisorBio, advisorPic, advisorProfileLink,
            description, customerId, price, quantity,
            orgCode, promoCode, bookingDate, bookingDateFormatted, profileItems,
            recipientType, recipientFirstName, recipientLastName, recipientEmail,
            createdAt, updatedAt
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Product is purchased! ', response.data);

              passedValues.passProductData(result)

            } else {
              console.log('Product not purchased')
              this.setState({ errorMessage: 'There was an error setting up your account'})

            }

          }).catch((error) => {
              console.log('Product purchase did not work', error);
          });

        });

      } else {
        console.log('Payment intent not created')
        this.setState({ errorMessage: 'There was an error setting up your account'})
      }

    }).catch((error) => {
        console.log('Payment intent did not work', error);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {(isLoading) && (
        <div>
          <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
        </div>
      )}

      <CardSection />

      <div className="top-padding-40">
        <button type="submit" disabled={isLoading} className="btn btn-squarish">Confirm Order</button>
      </div>
    </form>
  );
}
