import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEmployerDetails from './Subcomponents/EmployerDetails';
import withRouter from './Functions/WithRouter';

class EmployerDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      // document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { employerSelected, accountCode } = this.props.params
      // console.log('employerSelected:', employerSelected, accountCode);

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, emailId, username, orgFocus, orgLogo,
        employerId: employerSelected, accountCode })

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(40%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />
            <SubEmployerDetails history={this.props.navigate} employerId={this.state.employerId} accountCode={this.state.accountCode} commandClicked={this.commandClicked} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(EmployerDetails);
