import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Axios from 'axios';
import Modal from 'react-modal';

import ProjectDetails from '../Subcomponents/ProjectDetails';

const profileIconBig = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const downvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/downvote-icon-blue.png';
const downvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/downvote-icon-grey.png';
const thumbsUpIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-icon.png';
const thumbsUpIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-blue-icon.png';
const commentIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment_icon_grey.png';
const editIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-grey.png';
const feedbackIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/feedback-icon-grey.png';
const feedbackIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/feedback-icon-blue.png';
const detailsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/details-icon-grey.png';

class Submissions extends Component {
    constructor(props) {
      super(props)
      this.state = {

        showCorrentTimeAdjustment: true,

        submissionComments: [],
        mySubmissionComments: [],
        editSubmissionComments: [],
        myReplies: [],
        gradeOptions: [],

        selectedIndex: 0
      }

      this.voteOnItem = this.voteOnItem.bind(this)
      this.renderSubmissions = this.renderSubmissions.bind(this)
      this.renderSubmissionComments = this.renderSubmissionComments.bind(this)
      this.renderReplies = this.renderReplies.bind(this)
      this.likeItem = this.likeItem.bind(this)
      this.editComment = this.editComment.bind(this)
      this.showReplies = this.showReplies.bind(this)
      this.showComments = this.showComments.bind(this)
      this.showGrade = this.showGrade.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.postComment = this.postComment.bind(this)
      this.saveFeedback = this.saveFeedback.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedOpportunity !== prevProps.selectedOpportunity || this.props.orgFocus !== prevProps.orgFocus || this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.submissionComments !== prevProps.submissionComments) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within sideNav')

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');

      const selectedOpportunity = this.props.selectedOpportunity
      const activeOrg = this.props.activeOrg
      const accountCode = this.props.accountCode
      const submissionComments = this.props.submissionComments

      const orgContactEmail = this.props.orgContactEmail
      const pictureURL = this.props.pictureURL
      const gradeOptions = this.props.gradeOptions

      let disableLinks = false
      if (selectedOpportunity) {
        if (this.state.showCorrentTimeAdjustment) {
          if (new Date().getTime() > new Date(selectedOpportunity.submissionDeadline).getTime() && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/employers')) {
            disableLinks = true
          }
        } else {
          const now = new Date()
          const submissionDeadlineDate = new Date(selectedOpportunity.submissionDeadline)
          const timeDifferenceUnadjusted = now.getTime() - submissionDeadlineDate.getTime()
          const timeZoneDifferenceMiliseconds = (submissionDeadlineDate.getTimezoneOffset()) * 60000
          const timeDifference = timeDifferenceUnadjusted - timeZoneDifferenceMiliseconds
          console.log('show timeDifference: ', timeDifference)
          if (timeDifference < 0 && !window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/employers')) {
            disableLinks = true
          }
        }
      }

      this.setState({ pictureURL, emailId, cuFirstName, cuLastName, orgFocus, roleName, selectedOpportunity, activeOrg,
        accountCode, submissionComments, gradeOptions, disableLinks, orgContactEmail,
      })

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'comment') {
        this.setState({ myComment: event.target.value })
      } else if (event.target.name.includes('reply')) {
        const nameArray = event.target.name.split("|")
        let myReplies = this.state.myReplies
        myReplies[Number(nameArray[1])] = event.target.value
        this.setState({ myReplies })
      } else if (event.target.name.includes('submissionComment')) {
        const nameArray = event.target.name.split("|")
        let mySubmissionComments = this.state.mySubmissionComments
        mySubmissionComments[Number(nameArray[1])] = event.target.value
        this.setState({ mySubmissionComments })
      } else if (event.target.name === 'myEditedComment') {
        this.setState({ myEditedComment: event.target.value })
      } else if (event.target.name === 'myEditedSubmissionComment') {
        this.setState({ myEditedSubmissionComment: event.target.value })
      } else if (event.target.name === 'grade') {
        let selectedOpportunity = this.state.selectedOpportunity
        selectedOpportunity['submissions'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['grade'] = event.target.value
        this.setState({ selectedOpportunity })
        console.log('show me grades: ', selectedOpportunity.submissions[this.state.selectedIndex1].grades)
      } else if (event.target.name === 'feedback') {
        let selectedOpportunity = this.state.selectedOpportunity
        selectedOpportunity['submissions'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['feedback'] = event.target.value
        this.setState({ selectedOpportunity })
      } else if (event.target.name === 'isTransparent') {
        let selectedOpportunity = this.state.selectedOpportunity
        selectedOpportunity['submissions'][this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['isTransparent'] = event.target.value
        console.log('show transparnet: ', event.target.value, typeof event.target.value)
        this.setState({ selectedOpportunity })
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    renderSubmissions() {
      console.log('renderSubmissions called', this.state.selectedOpportunity)

      let rows = []

      for (let i = 1; i <= this.state.selectedOpportunity.submissions.length; i++) {

        const index = i - 1
        console.log('showy: ', index, this.state.selectedOpportunity.submissions[index].downvotes, this.state.selectedOpportunity.submissions[index].upvotes)

        let userFullName = this.state.selectedOpportunity.submissions[i - 1].userFirstName + ' ' + this.state.selectedOpportunity.submissions[i - 1].userLastName
        if (this.state.selectedOpportunity.submissions[i - 1].anonymizeSubmissions) {
          userFullName = 'Anonymous Submitter'
        }

        let disabled = false
        if (this.state.selectedOpportunity.disableDownvoting === true) {
          disabled = true
        }

        let disableVoting = false
        if (this.state.disableVoting || this.state.savingVote) {
          disableVoting = this.state.disableVoting
          disabled = true

          //disable downvoting as well
          disabled = true
        }

        let submissionCommentCount = 0
        if (this.state.submissionComments) {
          for (let j = 1; j <= this.state.submissionComments.length; j++) {
            if (this.state.submissionComments[j - 1].parentSubmissionId === this.state.selectedOpportunity.submissions[index]._id) {
              submissionCommentCount = submissionCommentCount + 1
            }
          }
        }

        let existingGrade = false
        if (this.state.selectedOpportunity.submissions[index].grades) {
          for (let j = 1; j <= this.state.selectedOpportunity.submissions[index].grades.length; j++) {
            if (this.state.selectedOpportunity.submissions[index].grades[j - 1]._id && this.state.selectedOpportunity.submissions[index].grades[j - 1].contributorEmail === this.state.emailId) {
              existingGrade = true
            }
          }
        }

        let projectURL = this.state.selectedOpportunity.submissions[i - 1].url
        let enableClass = "auto-pointers"
        if (this.state.disableLinks) {
          projectURL = ""
          enableClass = "no-pointers"
        }

        rows.push(
          <div key={i} className="full-width">

              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />

              <div className="fixed-column-50 center-text">
                <div>
                  <button className={(this.state.disableVoting || this.state.savingVote) ? "btn background-button no-pointers" : "btn background-button auto-pointers"} disabled={disableVoting} onClick={() => this.voteOnItem(this.state.selectedOpportunity.submissions[index], 'up', index) }>
                    {(this.state.selectedOpportunity.submissions[index].upvotes) ? (
                      <img src={(this.state.selectedOpportunity.submissions[index].upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="up" className={(this.state.disableVoting || this.state.savingVote) ? "image-auto-15 wash-out" : "image-auto-15"}/>
                    ) : (
                      <img src={upvoteIconGrey} alt="up" className={(this.state.disableVoting || this.state.savingVote) ? "image-auto-15 wash-out" : "image-auto-15"}/>
                    )}
                  </button>
                </div>
                <div>
                  {(this.state.selectedOpportunity.submissions[index].upvotes) ? (
                    <label>{this.state.selectedOpportunity.submissions[index].upvotes.length - this.state.selectedOpportunity.submissions[index].downvotes.length}</label>
                  ) : (
                    <label>0</label>
                  )}
                  <div className="clear" />
                </div>
                <div>
                  <button className={(this.state.disableVoting || this.state.savingVote) ? "btn background-button no-pointers" : "btn background-button auto-pointers"} disabled={disabled} onClick={() => this.voteOnItem(this.state.selectedOpportunity.submissions[index], 'down', index) }>
                    {(this.state.selectedOpportunity.submissions[index].upvotes) ? (
                      <img src={(this.state.selectedOpportunity.submissions[index].downvotes.includes(this.state.emailId)) ? downvoteIconBlue : downvoteIconGrey} alt="dn" className={(this.state.disableVoting || this.state.savingVote) ? "image-auto-15 wash-out" : "image-auto-15"}/>
                    ) : (
                      <img src={downvoteIconGrey} alt="dn" className={(this.state.disableVoting || this.state.savingVote) ? "image-auto-15 wash-out" : "image-auto-15"}/>
                    )}
                  </button>
                </div>
              </div>
              <a href={projectURL} target="_blank" className={"background-button-better calc-column-offset-50 left-padding " + enableClass}>

                <div className="calc-column-offset-25">
                  <label className="heading-text-4">{this.state.selectedOpportunity.submissions[i - 1].name}</label>
                  <div className="clear" />
                  <label className="description-text-1">{userFullName}</label>
                  <div className="clear" />
                  <label className="description-text-1">{this.state.selectedOpportunity.submissions[i - 1].category} | {this.state.selectedOpportunity.submissions[i - 1].hours} Hours</label>
                </div>
                <div className="fixed-column-25">
                  <div className="spacer" /><div className="half-spacer" />
                  <span className="float-right">
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22" />
                  </span>
                </div>
              </a>

              <div className="clear" />


              <div className="left-padding-50 calc-column-offset-50">
                <div className="half-spacer" />
                <button className="btn background-button float-left right-padding-20" onClick={() => this.showComments(index) }>
                  <div className="clear" />
                  <div className="spacer" />
                  <div className="float-left left-margin right-margin">
                    <img src={commentIconGrey} alt="Thumbs up icon logo" className="image-auto-18" />
                  </div>
                  <div className="float-left top-margin-negative-8">
                    <label className="description-text-2">{submissionCommentCount} Comments</label>
                  </div>
                  <div className="clear" />
                  <div className="half-spacer" />
                </button>

                <div className="vertical-separator-2" />

                <div className="float-left">
                  <div className="half-spacer" />
                  <button className="btn background-button floaat-left left-padding-20 right-padding-20" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: true, selectedIndex: index, showGrade: false, showComments: false, showJobFunction: false, showIndustry: false }) }>
                    <div className="float-left right-margin-5">
                      <div>
                        <img src={detailsIconGrey} alt="GC icon" className="image-auto-17" />
                      </div>
                    </div>
                    <div className="float-left top-margin-negative-7">
                      <label className="description-text-2">View Details</label>
                    </div>
                  </button>
                  <div className="clear"/>
                  <div className="half-spacer" />
                </div>

                <div className="vertical-separator-2" />

                {(!window.location.pathname.includes('/app')) && (
                  <div>
                    <div className="half-spacer"/>
                    <button className="btn background-button float-left right-padding top-margin-negative-3" onClick={() => this.showGrade(index) }>
                      <div className="float-left left-margin-5 right-margin-5 top-margin-negative-5">
                        <div>
                          <img src={(existingGrade) ? feedbackIconBlue : feedbackIconGrey} alt="Feedback icon logo" className="image-auto-30" />
                        </div>
                      </div>
                      <div className="float-left top-margin-negative-5">
                        <label className={(existingGrade) ? "description-text-2 cta-color" : "description-text-2"}>Add Feedback</label>
                      </div>
                    </button>
                  </div>
                )}
                <div className="clear" />
              </div>

            <div className="clear" />
            <div className="spacer" />
            <hr />

            <div className="spacer" />
          </div>
        )
      }

      return rows
    }

    renderSubmissionComments(submissionIndex) {
      console.log('renderSubmissionComments called', submissionIndex, this.state.submissionComments)

      let rows = []
      let submissionComments = this.state.submissionComments

      if (submissionComments) {
        for (let i = 1; i <= submissionComments.length; i++) {
          const index = i - 1
          console.log('show submissionComment: ', submissionComments[i - 1])

          if (submissionComments[index].parentSubmissionId === this.state.selectedOpportunity.submissions[submissionIndex]._id) {

            let commentBackgroundColor = "comment-background-student"
            if (submissionComments[i - 1].roleName === 'Mentor') {

              commentBackgroundColor = "comment-background-mentor"
            } else if (submissionComments[i - 1].roleName === 'Employer') {

              commentBackgroundColor = "comment-background-employer"
            } else if (submissionComments[i - 1].roleName === 'Teacher') {

              commentBackgroundColor = "comment-background-teacher"
            } else if (submissionComments[i - 1].roleName === 'Admin') {

              commentBackgroundColor = "comment-background-admin"
            }

            let dateString = ''
            if (submissionComments[i - 1].createdAt) {
              dateString = submissionComments[i - 1].createdAt.toString().substring(0,10)
            }

            let showEditOption = false
            let mainClass = 'calc-column-offset-100'

            let smallClass = "fixed-column-100"
            if (submissionComments[i - 1].email === this.state.emailId) {
              showEditOption = true
              mainClass = 'calc-column-offset-121'
              smallClass = "fixed-column-121"
            }

            if (this.state.onMobile) {
              mainClass = 'float-left full-width'
              smallClass = "full-width"
            }

            let commentClass = 'calc-column-offset-160'
            if (this.state.onMobile) {
              commentClass = "full-width"
            }

            let disabled = true
            if (this.state.myEditedSubmissionComment) {
              disabled = false
            }

            rows.push(
              <div key={i}>
                <div className="spacer" />
                <div className="fixed-column-48 right-padding-8">
                  <img src={(submissionComments[i - 1].pictureURL) ? submissionComments[i - 1].pictureURL : profileIconBig} alt="img" className="profile-thumbnail-1" />
                </div>

                <div className={"comment-bubble-2 calc-column-offset-48 " + commentBackgroundColor}>
                  <div className={mainClass}>
                    <label className="description-text-2 bold-text">{submissionComments[i - 1].firstName} {submissionComments[i - 1].lastName}</label>
                    <div className="clear" />
                    <label className="description-text-3">{submissionComments[i - 1].roleName}</label>

                    <div className="clear" />
                  </div>

                  {(this.state.onMobile) && (
                    <div className="clear" />
                  )}

                  <div className={smallClass}>
                    <div className="float-left">
                      <label className="description-text-3">{dateString}</label>
                    </div>
                    {(showEditOption) && (
                      <div className="float-left left-margin">
                        <button className="btn background-button" onClick={() => this.editComment(index,'submission',true) }>
                          <img src={editIconGrey} alt="img" className="image-auto-11" />
                        </button>
                      </div>
                    )}
                    <div className="clear" />
                  </div>


                  <div className="clear" />
                  <div className="spacer" /><div className="half-spacer" />

                  <div className={commentClass}>
                    {(this.state.editSubmissionComments[index]) ? (
                      <input className="edit-comment" type="text" placeholder="Change comment..." name="myEditedSubmissionComment" value={this.state.myEditedSubmissionComment} onChange={this.formChangeHandler} />
                    ) : (
                      <label className="description-text-1">{submissionComments[i - 1].comment}</label>
                    )}
                  </div>

                  {(this.state.editSubmissionComments[index]) && (
                    <div className="fixed-column-160">
                      <div className="float-left">
                        <button className="btn btn-small clear-border cta-color" onClick={() => this.editComment(index,'submission',false)}>Cancel</button>
                      </div>
                      <div className="float-left left-padding-15">
                        <button className={(this.state.myEditedSubmissionComment) ? "btn btn-small" : "btn btn-small wash-out"} disabled={disabled} onClick={() => this.postComment(submissionIndex, 'submission', index)}>Post</button>
                      </div>
                    </div>
                  )}

                  <div className="clear" />
                  <div className="spacer" />
                </div>

                <div className="clear" />
                <div className="spacer" />

                <div className="left-margin-58">
                  <div className="float-left right-margin">
                    <button className="btn background-button" disabled={this.state.savingLike} onClick={() => this.likeItem(index,'submission') }>
                      <img src={submissionComments[index].likes.includes(this.state.emailId) ? thumbsUpIconBlue : thumbsUpIconGrey} alt="Thumbs up icon logo" className="image-auto-25" />
                    </button>
                  </div>
                  <div className="float-left right-margin">
                    <label>{submissionComments[i - 1].likes.length} Likes</label>
                  </div>

                  <div className="vertical-separator" />

                  <div className="float-left left-margin right-margin">
                    <button className="btn background-button" onClick={() => this.showReplies(index,'submission') }>
                      <img src={commentIconGrey} alt="Thumbs up icon logo" className="image-auto-25" />
                    </button>
                  </div>
                  <div className="float-left">
                    <button className="btn background-button" onClick={() => this.showReplies(index,'submission') }>
                      <label>{submissionComments[i - 1].replies.length} Replies</label>
                    </button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="spacer" />
                {(submissionComments[i - 1].showReplies) && (
                  <div className="left-margin-58">
                    {this.renderReplies(index, 'submission')}
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />

              </div>
            )
          }
        }
      }

      return rows
    }

    likeItem(index, type) {
      console.log('likeItem called', index, type)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        this.setState({ savingLike: true, serverSuccessMessage: '', serverErrorMessage: '' })

        let _id = this.state.submissionComments[index]._id
        const emailId = this.state.emailId

        Axios.post('/api/comments/like', { _id, emailId
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Like save worked ', response.data);

            //report whether values were successfully saved
            const serverSuccessMessage  = 'Like submitted successfully!'
            const comment = response.data.comment

            if (type === 'submission') {
              let submissionComments = this.state.submissionComments
              for (let i = 1; i <= submissionComments.length; i++) {
                if (submissionComments[i - 1]._id === comment._id) {
                  submissionComments[i - 1] = comment
                }
              }

              this.setState({ serverSuccessMessage, submissionComments, savingLike: false })

            } else {
              let comments = this.state.comments
              for (let i = 1; i <= comments.length; i++) {
                if (comments[i - 1]._id === comment._id) {
                  comments[i - 1] = comment
                }
              }
              this.setState({ serverSuccessMessage, comments, savingLike: false })
            }

          } else {
            console.log('like did not save successfully')
            this.setState({ serverErrorMessage: response.data.message, savingLike: false })
          }

        }).catch((error) => {
            console.log('Like save did not work', error);
            this.setState({ serverErrorMessage: error, savingLike: false })
        });
      }
    }

    editComment(index, type, action) {
      console.log('editComment', index, type, action)

      if (type === 'posting') {
        let editComments = this.state.editComments
        editComments[index] = action
        const myEditedComment = this.state.comments[index].comment
        this.setState({ editComments, myEditedComment })
      } else {
        let editSubmissionComments = this.state.editSubmissionComments
        editSubmissionComments[index] = action
        const myEditedSubmissionComment = this.state.submissionComments[index].comment
        this.setState({ editSubmissionComments, myEditedSubmissionComment })
      }
    }

    showReplies(index, type) {
      console.log('addReplies called', index, type)

      if (type === 'submission') {
        let submissionComments = this.state.submissionComments
        submissionComments[index]['showReplies'] = true
        this.setState({ submissionComments })
      } else if (type === 'posting'){
        let comments = this.state.comments
        comments[index]['showReplies'] = true
        this.setState({ comments })
      }
    }

    voteOnItem(submission, direction, index) {
      console.log('voteOnItem called', index)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        const _id = this.state.selectedOpportunity._id
        let selectedOpportunity = this.state.selectedOpportunity
        const emailId = this.state.emailId
        this.setState({ savingVote: true })

        Axios.post('/api/postings/submission-vote', { _id, submission, direction, emailId })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Submission vote save worked', response.data);

            const serverSuccessMessage = 'Submission vote successfully saved!'
            const submissions = response.data.submissions
            selectedOpportunity['submissions'] = submissions
            this.setState({ serverSuccessMessage, selectedOpportunity, savingVote: false })

          } else {
            console.error('there was an error posting the vote save');
            const serverErrorMessage = response.data.message
            this.setState({ serverErrorMessage, savingVote: false })
          }
        }).catch((error) => {
            console.log('The vote save did not work', error);
            this.setState({ serverErrorMessage: error, savingVote: false })
        });
      }
    }

    showGrade(index) {
      console.log('showGrade ', index)
      //for the submissions section

      const modalIsOpen = true
      const selectedIndex1 = index
      let selectedIndex2 = 0
      let selectedOpportunity = this.state.selectedOpportunity
      const showComments = false
      const showGrade = true
      const showProjectDetail = false

      const contributorFirstName = this.state.cuFirstName
      const contributorLastName = this.state.cuLastName
      const contributorEmail = this.state.emailId
      const contributorTitle = this.state.jobTitle
      const contributorEmployerName = this.state.employerName
      let orgCode = ''
      if (this.state.selectedOpportunity.orgCode) {
        orgCode = this.state.selectedOpportunity.orgCode
      }
      let accountCode = ''
      if (this.state.selectedOpportunity.accountCode) {
        accountCode = this.state.selectedOpportunity.accountCode
      }
      console.log('show titles 1: ', contributorTitle, this.state.jobTitle)

      const isApproved = false
      const updatedAt = new Date()

      if (this.state.selectedOpportunity.submissions[index].grades && this.state.selectedOpportunity.submissions[index].grades.length > 0) {
        //find the case where provideEmail matches this email, otherwise add to grades array
        console.log('show grades 1: ', selectedOpportunity.submissions[index])

        //determine selectedIndex2
        let existingGrade = false
        for (let i = 1; i <= selectedOpportunity.submissions[index].grades.length; i++) {
          if (selectedOpportunity.submissions[index].grades[i - 1].contributorEmail === this.state.emailId) {
            selectedIndex2 = i - 1
            existingGrade = true
          }
        }

        if (existingGrade === false) {
          selectedIndex2 = selectedOpportunity.submissions[index].grades.length
          selectedOpportunity.submissions[index]['grades'][selectedIndex2] =
            { contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorEmployerName,
              isTransparent: true, orgCode, accountCode, isApproved, updatedAt }
        }
        console.log('show update: ', selectedIndex2, selectedOpportunity.submissions[index].grades)

      } else {

        selectedOpportunity.submissions[index]['grades'] = [
          { contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorEmployerName,
            isTransparent: true, orgCode, accountCode, isApproved, updatedAt }
        ]
        console.log('show grades: ', selectedOpportunity.submissions[index])
      }

      this.setState({ modalIsOpen, selectedIndex1, selectedIndex2, selectedOpportunity, showComments, showGrade, showProjectDetail });

    }

    showComments(index) {
      console.log('showComments ', index)
      //for the submissions section

      const modalIsOpen = true
      const selectedIndex = index
      const showComments = true
      const showGrade = false
      const showProjectDetail = false

      let disabled = false
      let opacity = 1
      let pointerEvents = 'auto'
      if (this.state.selectedOpportunity.disableDownvoting === true) {
        disabled = true
        opacity = 0.25
        pointerEvents = 'none'
      }

      let submissionCommentCount = 0
      for (let i = 1; i <= this.state.submissionComments.length; i++) {
        if (this.state.submissionComments[i - 1].parentSubmissionId === this.state.selectedOpportunity.submissions[index]._id) {
          submissionCommentCount = submissionCommentCount + 1
        }
      }

      this.setState({ modalIsOpen, selectedIndex, showComments, showGrade, disabled, opacity, pointerEvents, submissionCommentCount, showProjectDetail });
    }

    renderReplies(index, type) {
      console.log('renderReplies called', index, type)

      let rows = []
      let comments = this.state.comments
      let postType = 'reply'

      if (type === 'submission') {
        comments = this.state.submissionComments
        postType = 'submissionReply'
      }

      let replies = comments[index].replies

      let myReply = ''
      if (this.state.myReplies[index]) {
        myReply = this.state.myReplies[index]
      }

      rows.push(
        <div key={0}>
          <div className="spacer" />
          <div className="float-left right-padding-8">
            <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconBig} alt="img" className="profile-thumbnail-1" />
          </div>

          <div className="comment-container top-margin-negative-10">
            <input className="text-field reply-text-field" type="text" placeholder="Add a reply..." name={"reply|" + index} value={myReply} onChange={this.formChangeHandler} />
          </div>
          <div className="clear" />
          {(this.state.myReplies[index] !== '') && (
            <div className="left-margin-67">
              <button className="btn btn-small" onClick={() => this.postComment(index,postType)}>Reply</button>
            </div>
          )}
          <div className="spacer" /><div className="spacer" />
        </div>
      )

      if (replies) {
        for (let i = 1; i <= replies.length; i++) {

          const replyIndex = i - 1

          let commentBackgroundColor = "comment-background-student"
          if (this.state.comments[i - 1].roleName === 'Mentor') {
            commentBackgroundColor = "comment-background-mentor"
          } else if (this.state.comments[i - 1].roleName === 'Employer') {
            commentBackgroundColor = "comment-background-employer"
          } else if (this.state.comments[i - 1].roleName === 'Teacher') {
            commentBackgroundColor = "comment-background-teacher"
          } else if (this.state.comments[i - 1].roleName === 'Admin') {
            commentBackgroundColor = "comment-background-admin"
          }

          let dateString = ''
          if (replies[replyIndex]) {
            if (replies[replyIndex].createdAt) {
              dateString = replies[replyIndex].createdAt.toString().substring(0,10)
            }
          }

          rows.push(
            <div key={i}>
              <div className="spacer" />
              <div className="float-left right-padding-8">
                <img src={(replies[i - 1].pictureURL) ? replies[i - 1].pictureURL : profileIconBig} alt="img" className="profile-thumbnail-1" />
              </div>

              <div className={"comment-bubble-2 calc-column-offset-48 " + commentBackgroundColor}>
                <div className="calc-column-offset-100">
                  <label className="description-text-2">{replies[i - 1].firstName} {replies[i - 1].lastName}</label>
                  <div className="clear" />
                  <label className="description-text-3">{replies[i - 1].roleName}</label>
                  <div className="clear" />
                </div>
                <div className="fixed-column-100">
                  <div className="float-left">
                    <label className="description-text-3">{dateString}</label>
                  </div>
                  <div className="clear" />
                </div>

                <div className="clear" />
                <div className="spacer" />
                <label className="description-text-2">{replies[i - 1].comment}</label>
                <div className="clear" />
              </div>

              <div className="clear" />
              <div className="spacer" />

            </div>
          )
        }
      }

      return rows
    }

    postComment(index, type, subCommentIndex) {
      console.log('postComment called ', index, type, subCommentIndex)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        this.setState({ serverErrorMessage: '', serverSuccessMessage: '', disableSubmit: true })

        //submit the selected project
        const email = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        let roleName = "Student"
        if (window.location.pathname.includes('/app')) {
          roleName = 'Student'
        } else {
          roleName = this.state.roleName
        }

        let likes = []

        const orgCode = this.state.postingOrgCode
        const orgName = this.state.postingOrgName
        const orgContactEmail = this.state.orgContactEmail

        let parentPostId = null
        let parentCommentId = null

        const createdAt = new Date()
        const updatedAt = new Date()

        if (type === 'reply' || type === 'submissionReply') {
          //save reply

          let comments = this.state.comments
          if (type === 'submissionReply') {
            comments = this.state.submissionComments
          }

          let _id = comments[index]
          const comment = this.state.myReplies[index]
          parentCommentId = comments[index]._id
          // const parentCommentName = comments[index].comment
          // const parentCommentFirstName = comments[index].firstName
          // const parentCommentEmail = comments[index].email
          const pictureURL = this.state.pictureURL


          const reply = {
              firstName, lastName, email, roleName, comment,
              orgCode, orgName, orgContactEmail,
              pictureURL, createdAt, updatedAt
          }

          Axios.post('/api/comments/reply', {
            _id, firstName, lastName, email,
            orgCode, orgName, orgContactEmail,
            reply
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Comment save worked ', response.data);

              let myReplies = []

              let newComment = response.data.comment

              for (let i = 1; i <= comments.length; i++) {
                if (comments[i - 1]._id === newComment._id) {
                  comments[i - 1] = newComment
                }
              }

              if (type === 'submissionReply') {
                this.setState({ serverSuccessMessage: 'Reply submitted successfully!', submissionComments: comments, myReplies, disableSubmit: false })
              } else {
                this.setState({ serverSuccessMessage: 'Reply submitted successfully!', comments, myReplies, disableSubmit: false })
              }

            } else {

              console.log('comment did not save successfully')
              this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
            }

          }).catch((error) => {
              console.log('Reply save did not work', error);
              this.setState({ serverErrorMessage: error, disableSubmit: false })
          });

        } else if (type === 'submission') {

          //save comment on submission
          console.log('no index found')
          let commentId = null
          const commentType = type
          const pictureURL = this.state.pictureURL
          let replies = []
          let comment = this.state.mySubmissionComments[this.state.selectedIndex]
          const parentPostId = this.state.selectedOpportunity._id
          const parentSubmissionId = this.state.selectedOpportunity.submissions[this.state.selectedIndex]._id
          let submissionName = this.state.selectedOpportunity.submissions[this.state.selectedIndex].name
          let contributorFirstName = this.state.selectedOpportunity.submissions[this.state.selectedIndex].userFirstName
          let contributorEmail = this.state.selectedOpportunity.submissions[this.state.selectedIndex].userEmail

          if (subCommentIndex || subCommentIndex === 0) {
            commentId = this.state.submissionComments[index]._id
            comment = this.state.myEditedSubmissionComment
            likes = this.state.submissionComments[index].likes
            replies = this.state.submissionComments[index].replies
          }

          //save comment
          Axios.post('/api/comments', {
            commentId, commentType, email, firstName, lastName, roleName, comment, pictureURL, likes, replies,
            parentPostId, parentSubmissionId, createdAt, updatedAt, submissionName, contributorFirstName, contributorEmail,
            orgCode, orgName, orgContactEmail
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Comment save worked ', response.data);

              let submissionComments = this.state.submissionComments
              let editSubmissionComments = this.state.editSubmissionComments

              if (subCommentIndex) {
                console.log('existing comment')
                submissionComments[index]['comment'] = this.state.myEditedSubmissionComment
                editSubmissionComments[index] = false
              } else {
                submissionComments.push(
                  { _id: response.data._id, firstName, lastName, email, roleName, comment,
                    pictureURL, likes, replies, parentPostId, parentSubmissionId, createdAt, updatedAt }
                )
              }

              let mySubmissionComments = this.state.mySubmissionComments
              mySubmissionComments[this.state.selectedIndex] = ''

              let submissionCommentCount = 0
              if (this.state.submissionCommentCount) {
                submissionCommentCount = this.state.submissionCommentCount
              }
              submissionCommentCount = submissionCommentCount + 1

              console.log('show submissionComments: ', submissionComments, comment)

              //report whether values were successfully saved
              this.setState({ serverSuccessMessage: 'Comment submitted successfully!',
              submissionComments, mySubmissionComments, submissionCommentCount, disableSubmit: false })

            } else {
              console.log('comment did not save successfully')
              this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
            }

          }).catch((error) => {
              console.log('Comment save did not work', error);
              this.setState({ serverErrorMessage: error, disableSubmit: false })
          });
        } else if (type === 'posting') {

          //save comment on posting
          console.log('no index found')

          let commentId = null
          let commentType = type
          let replies = []
          let comment = this.state.myComment
          parentPostId = this.state.selectedOpportunity._id
          let postingTitle = this.state.selectedOpportunity.name
          let contributorFirstName = this.state.selectedOpportunity.contributorFirstName
          let contributorEmail = this.state.selectedOpportunity.contributorEmail
          if (this.state.selectedOpportunity.postType === 'Event') {
            postingTitle = this.state.selectedOpportunity.title
            contributorFirstName = this.state.selectedOpportunity.orgContactFirstName
            contributorEmail = this.state.selectedOpportunity.orgContactEmail
          }
          const pictureURL = this.state.pictureURL

          if (index || index === 0) {
            commentId = this.state.comments[index]._id
            comment = this.state.myEditedComment
            likes = this.state.comments[index].likes
            replies = this.state.comments[index].replies
          }

          //save comment
          Axios.post('/api/comments', {
            commentId, commentType, email, firstName, lastName, roleName, comment, pictureURL, likes, replies,
            parentPostId, parentCommentId, createdAt, updatedAt, postingTitle, contributorFirstName, contributorEmail,
            orgCode, orgName, orgContactEmail
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Comment save worked ', response.data, index, createdAt);
              let comments = this.state.comments
              let editComments = this.state.editComments

              if (index || index === 0) {
                console.log('existing comment', comments.length)
                comments[index]['comment'] = this.state.myEditedComment
                editComments[index] = false
              } else {
                console.log('new comment', comments.length)
                comments.push(
                  { _id: response.data._id, firstName, lastName, email, roleName, comment,
                    pictureURL, likes, replies, parentPostId, parentCommentId, createdAt, updatedAt }
                )
              }
              console.log('show comments: ', comments)

              //report whether values were successfully saved
              this.setState({ serverSuccessMessage: 'Comment submitted successfully!',
                comments, myComment: '', myEditedComment: '', editComments, disableSubmit: false })

            } else {
              console.log('comment did not save successfully')
              this.setState({ serverErrorMessage: response.data.message, disableSubmit: false })
            }

          }).catch((error) => {
              console.log('Comment save did not work', error);
              this.setState({ serverErrorMessage: error, disableSubmit: false })
          });
        }
      }
    }

    saveFeedback() {
      console.log('saveFeedback called')

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        const grade = this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].grade
        const feedback = this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].feedback
        const isTransparent = this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].isTransparent

        //must set transparency level and give grade or feedback
        if (!grade && !feedback) {
          this.setState({ serverErrorMessage: 'please add a grade or provide feedback'})
        } else if (isTransparent === '') {
          this.setState({ serverErrorMessage: 'please indicate whether this feedback is transparent to the student '})
        } else {

          //submit the selected project
          // const emailId = this.state.emailId
          // const postingId = this.state.selectedOpportunity._id
          // const submissions = this.state.selectedOpportunity.submissions
          // const selectedIndex = this.state.selectedIndex1
          // const gradeObject = submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2]
          // const updatedAt = new Date()

          const emailId = this.state.emailId
          const postingId = this.state.selectedOpportunity._id
          const submissionId = this.state.selectedOpportunity.submissions[this.state.selectedIndex1]._id
          const gradeObject = this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2]

          console.log('show titles 2: ', gradeObject)

          Axios.post('/api/projects/feedback', { emailId, postingId, submissionId, gradeObject })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Project feedback save worked ', response.data);
              //report whether values were successfully saved
              this.setState({ serverSuccessMessage: 'Project feedback successfully sent!', modalIsOpen: false })

            } else {
              console.log('project feedback did not save successfully')
              this.setState({ serverErrorMessage: response.data.message })
            }

          }).catch((error) => {
              console.log('Project feedback save did not work', error);
              this.setState({ serverErrorMessage: error })
          });
        }
      }
    }

    render() {

      let registerLink = '/organizations/' + this.state.activeOrg + '/student/signin'
      if (!this.state.activeOrg || this.state.activeOrg === '') {
        registerLink = '/signin'
      }

      return (
        <div>
          {(this.state.selectedOpportunity) ? (
            <div>
              {(this.state.selectedOpportunity.submissions && this.state.selectedOpportunity.submissions.length > 0) ? (
                <div>
                  <div className="spacer" />

                  {(this.props.pageSource === 'landingPage') ? (
                    <div>
                      <div>
                        <div className="spacer"/><div className="half-spacer"/>
                        <Link className="clear-border" to={registerLink} state={{ opportunityId: this.state.selectedOpportunity._id }}>
                          <button className="btn btn-primary">
                            Register to View Submissions
                          </button>
                        </Link>
                      </div>

                    </div>
                  ) : (
                    <div>
                      {this.renderSubmissions()}
                    </div>
                  )}

                  <div className="clear" />

                  {this.state.serverSuccessMessage !== '' && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                  {this.state.serverErrorMessage !== '' && <p className="error-message">{this.state.serverErrorMessage}</p>}
                </div>
              ) : (
                <div>
                  <div className="spacer" />
                  <div className="spacer" />

                  <p className="error-message">No participants have submitted a project solution yet.</p>

                  <div className="spacer" />
                  <div className="spacer" />

                </div>
              )}
            </div>
          ) : (
            <div>
              <p className="error-message">There was an error</p>
            </div>
          )}

          {(this.state.showProjectDetail) ? (
            <div>
              {console.log('showProjectDetail 2: ', this.state.showProjectDetail)}
              <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.selectedOpportunity.submissions[this.state.selectedIndex]} orgCode={this.state.activeOrg} />
              {console.log('showProjectDetail 3: ', this.state.showProjectDetail)}
            </div>
          ) : (
            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
            {(this.state.showComments) && (
              <div key="submissionDetail" className="full-width">
               {(this.state.selectedOpportunity && this.state.selectedOpportunity.submissions && this.state.selectedOpportunity.submissions.length > 0) && (
                 <div>
                   <div className="fixed-column-50 center-text">
                     <div className="half-spacer" />
                     <div>
                       <button className="btn background-button" disabled={(this.state.disabledVoting || this.state.savingVote) ? true : false} onClick={() => this.voteOnItem(this.state.selectedOpportunity.submissions[this.state.selectedIndex], 'up', this.state.selectedIndex) }>
                         <img src={(this.state.selectedOpportunity.submissions[this.state.selectedIndex].upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="up" className="image-auto-15" />
                       </button>
                     </div>
                     <div>
                       <label>{this.state.selectedOpportunity.submissions[this.state.selectedIndex].upvotes.length - this.state.selectedOpportunity.submissions[this.state.selectedIndex].downvotes.length}</label>
                       <div className="clear" />
                     </div>
                     <div>
                       <button className={(this.state.selectedOpportunity.disableDownvoting) ? "btn background-button no-pointers" : "btn background-button auto-pointers"} disabled={this.state.disabled} onClick={() => this.voteOnItem(this.state.selectedOpportunity.submissions[this.state.selectedIndex], 'down', this.state.selectedIndex) }>
                         <img src={(this.state.selectedOpportunity.submissions[this.state.selectedIndex].downvotes.includes(this.state.emailId)) ? downvoteIconBlue : downvoteIconGrey} alt="dn" className={(this.state.selectedOpportunity.disableDownvoting === true) ? "image-auto-15 wash-out" : "image-auto-15"} />
                       </button>
                     </div>
                   </div>

                   <a href={(this.state.disableLinks) ? "" : this.state.selectedOpportunity.submissions[this.state.selectedIndex].url} target="_blank" className={(this.state.disableLinks) ? "background-button-better calc-column-offset-50 left-padding no-pointers" : "background-button-better calc-column-offset-50 left-padding auto-pointers"}>
                     <div className="calc-column-offset-30">
                       <label className="heading-text-4">{this.state.selectedOpportunity.submissions[this.state.selectedIndex].name}</label>
                       <div className="clear" />
                       <label className="description-text-1">{(this.state.selectedOpportunity.submissions[this.state.selectedIndex].anonymizeSubmissions) ? "Anonymous Submitter" : this.state.selectedOpportunity.submissions[this.state.selectedIndex].userFirstName + " " + this.state.selectedOpportunity.submissions[this.state.selectedIndex].userLastName}</label>
                       <div className="clear" />
                       <label className="description-text-1">{this.state.selectedOpportunity.submissions[this.state.selectedIndex].category} | {this.state.selectedOpportunity.submissions[this.state.selectedIndex].hours} Hours</label>
                     </div>
                     <div className="fixed-column-30">
                      <div className="spacer" /><div className="half-spacer" />
                       <span className="float-right">
                         <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22" />
                       </span>
                     </div>
                   </a>
                   <div className="clear" />

                   <div className="spacer" />

                   <div className="calc-column-offset-50 left-margin-50">
                     <div className="float-left left-margin right-margin">
                       <img src={commentIconGrey} alt="Thumbs up icon logo" className="image-auto-18" />
                     </div>
                     <div className="float-left top-margin-negative-5">
                       {(this.state.submissionCommentCount) ? (
                         <label className="description-text-2">{this.state.submissionCommentCount} Comments</label>
                       ) : (
                         <label className="description-text-2">0 Comments</label>
                       )}
                     </div>
                     <div className="clear" />
                   </div>

                   <div className="clear" />

                   <hr className="right-ruler" />

                   <div className="left-padding-50 right-margin-20">
                     <div className="float-left">
                       <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconBig} alt="img" className="profile-thumbnail-1" />
                     </div>
                     <div className="comment-container top-margin-negative-10">
                       <input className="text-field edit-comment" type="text" placeholder="Add a comment..." name={"submissionComment|" + this.state.selectedIndex} value={this.state.mySubmissionComments[this.state.selectedIndex]} onChange={this.formChangeHandler} />
                     </div>

                     <div className="clear" />
                     {(this.state.mySubmissionComments[this.state.selectedIndex] !== '') && (
                       <div className="left-margin-56">
                         <button className="btn btn-small" disabled={this.state.disableSubmit} onClick={() => this.postComment(this.state.selectedIndex, 'submission')}>Post</button>
                       </div>
                     )}
                     <div className="spacer" /><div className="spacer" />

                     <div>
                      {this.renderSubmissionComments(this.state.selectedIndex)}
                     </div>
                   </div>
                 </div>
               )}
              </div>
            )}

            {(this.state.showGrade) && (
              <div key="gradeProject" className="full-width">
                <div className="edit-profile-row">
                  <p className="heading-text-4">{this.state.selectedOpportunity.submissions[this.state.selectedIndex1].name}</p>
                  <div className="half-spacer" />
                  <label className="description-text-2">You may grade this project, provide feedback, or both!</label>
                </div>

                <div className="edit-profile-row">
                  <p className="profile-label">Grade The Project (You Can Edit Later)</p>
                  <select name="grade" className="dropdown" value={this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].grade} onChange={this.formChangeHandler}>
                      {this.state.gradeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="edit-profile-row">
                  <p className="profile-label">Provide Constructive Feedback</p>
                  <textarea className="text-field" name={"feedback"} placeholder="Project feedback" value={this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].feedback} onChange={this.formChangeHandler} />
                </div>

                <div className="edit-profile-row">
                  <p className="profile-label">Transparency to Student</p>
                  <select name="isTransparent" className="dropdown" value={this.state.selectedOpportunity.submissions[this.state.selectedIndex1].grades[this.state.selectedIndex2].isTransparent} onChange={this.formChangeHandler}>
                    <option value={true}>Students can see endorsement details [Keep transparent]</option>
                    <option value={false}>Students cannot see endorsement details [Keep confidential]</option>
                  </select>
                  <label className="description-text-2">Note: this feedback is viewable by teachers, counselors, mentors, and work placement organizations.</label>
                </div>

                <div>
                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}

                  <div className="float-left">
                    <button className="btn btn-primary" onClick={() => this.saveFeedback()}>Save Feedback</button>
                  </div>

                  <div className="float-left left-padding">
                    <button className="btn btn-secondary" onClick={() => this.setState({ modalIsOpen: false })}>Cancel</button>
                  </div>

                  <div className="clear" />
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            )}

           </Modal>
         )}
        </div>
      )
    }
}

export default Submissions;
