import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import EditBenchmark from '../Subcomponents/EditBenchmark';
import withRouter from '../Functions/WithRouter';

class OrgEditBenchmark extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      const { selectedTemplate, duplicate } = this.props.location.state;

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, orgLogo,
        selectedTemplate, benchmarkId: id, duplicate
      })
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          <EditBenchmark source="Employer" activeOrg={this.state.org} accountCode={this.state.emp} benchmarkId={this.state.benchmarkId} selectedTemplate={this.state.selectedTemplate} history={this.props.navigate} duplicate={this.state.duplicate} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditBenchmark)
