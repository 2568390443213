import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';

class EditPlacement extends Component {
    constructor(props) {
        super(props)

        this.state = {
          workTypeOptions: ['','Internship','Apprenticeship','Part-Time','Full-Time'],
          payTypeOptions: ['','Hourly', 'Salary'],
          functionOptions: [],
          industryOptions: [],
          pathwayOptions: [],
          departmentOptions: [],
          hoursPerWeekOptions: [],
          suggestedWorkers: [],
          employerOptions: [{ employerName: 'No Employers Saved'}],

          selectedEmployer: { employerName: 'No Employers Saved'},

          excludedKeys: ['__v','_id','applications','applicants','applicationComponents','courses','interests','skills','traits','responsibilities','requirements','desirables','rsvps','submissionProjectIds','placementPartners','type','customAssessmentId','isPerpetual','isChild','parentPostId','posterEmail','usePrimaryEmployerContact','employerContactFirstName','employerContactLastName','employerContactEmail','employerContactPhone','referrerName','direct','orgMission','pathways','tracks','sections','exhibits','submissions', 'fakeTracks', 'assignees','richDescription','departments'],

          oneStudent: true,

          workers: [],
          students: [],
          applications: [],
          postings: [],

          pathwayPlacementTypeOptions: ['','CTE','Non-CTE','IEP'],
          postingOptions: [],
          postByOptions: ['','Fresh Placement','Import Posting'],
          searchBy: "Search by Applicants",

          employmentHourDetails: [],
          siteVisitSchedule: [],
          academicLearningObjectives: [],
          careerDevelopmentObjectives: [],

          selectedPosting: { title: '', employerName: ''},
          pathways: [],
          departments: []

        }

        this.retrieveData = this.retrieveData.bind(this);
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.savePlacement = this.savePlacement.bind(this);
        this.deletePlacement = this.deletePlacement.bind(this)
        this.searchObjects = this.searchObjects.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
        this.addItem = this.addItem.bind(this);
        this.finishAddingPosting = this.finishAddingPosting.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.changeItem = this.changeItem.bind(this);
        this.optionClicked = this.optionClicked.bind(this);
        this.renderReadOnlyContent = this.renderReadOnlyContent.bind(this)
        this.renderPathways = this.renderPathways.bind(this)
    }

    static defaultProps = { activeOrg: null, source: '' }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subeditplacement', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      } else if (this.props.title !== prevProps.title) {
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editPlacement', this.props)

      const org = this.props.activeOrg
      // const org = 'dpscd'
      const selectedPlacement = this.props.selectedPlacement
      const fillRole = this.props.fillRole
      const inModal = this.props.inModal

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      const path = window.location.pathname

      if (selectedPlacement) {

        if (this.props.duplicate) {
          delete selectedPlacement._id
          selectedPlacement['orgCode'] = org
          selectedPlacement['title'] = selectedPlacement.title + " (Duplicate)"
        }

        const _id = selectedPlacement._id
        const postingId = selectedPlacement.postingId
        const title = selectedPlacement.title
        const workType = selectedPlacement.workType
        const employerName = selectedPlacement.employerName
        const employerURL = selectedPlacement.employerURL
        const workFunction = selectedPlacement.workFunction
        const industry = selectedPlacement.industry

        let pathways = []
        if (selectedPlacement.pathways) {
          pathways = selectedPlacement.pathways
        }
        let departments = []
        if (selectedPlacement.departments) {
          departments = selectedPlacement.departments
        }
        const startDate = convertDateToString(selectedPlacement.startDate,"first10")
        const endDate = convertDateToString(selectedPlacement.endDate,"first10")
        const isPerpetual = selectedPlacement.isPerpetual
        const location = selectedPlacement.location

        const hoursPerWeek = selectedPlacement.hoursPerWeek
        const payType = selectedPlacement.payType
        const pay = selectedPlacement.pay
        const description = selectedPlacement.description
        const timesheetEnabled = selectedPlacement.timesheetEnabled
        const hiringManagerFirstName = selectedPlacement.hiringManagerFirstName
        const hiringManagerLastName = selectedPlacement.hiringManagerLastName
        const hiringManagerEmail = selectedPlacement.hiringManagerEmail
        const orgContactEmail = selectedPlacement.orgContactEmail
        const workers = selectedPlacement.workers
        const accountCode = selectedPlacement.accountCode

        const trainingPlanURL = selectedPlacement.trainingPlanURL
        const trainingAgreementURL = selectedPlacement.trainingAgreementURL
        const w9URL = selectedPlacement.w9URL
        const supplierFormURL = selectedPlacement.supplierFormURL
        const trainingAgreementExecuted = selectedPlacement.trainingAgreementExecuted
        const w9Executed = selectedPlacement.w9Executed
        const supplierFormExecuted = selectedPlacement.supplierFormExecuted
        const pathwayPlacementType = selectedPlacement.pathwayPlacementType
        const educationDevelopmentPlanURL = selectedPlacement.educationDevelopmentPlanURL
        const individualizedEducationPlanURL = selectedPlacement.individualizedEducationPlanURL
        let employmentHourDetails = selectedPlacement.employmentHourDetails
        if (!employmentHourDetails || employmentHourDetails.length === 0) {
          employmentHourDetails = [
            { day: 'Mon', startTime: '', endTime: '' },{ day: 'Tue', startTime: '', endTime: '' },
            { day: 'Wed', startTime: '', endTime: '' },{ day: 'Thu', startTime: '', endTime: '' },
            { day: 'Fri', startTime: '', endTime: '' },{ day: 'Sat', startTime: '', endTime: '' },
            { day: 'Sun', startTime: '', endTime: '' },
          ]
        }
        const siteVisitSchedule = selectedPlacement.siteVisitSchedule
        const academicLearningObjectives = selectedPlacement.academicLearningObjectives
        const careerDevelopmentObjectives = selectedPlacement.careerDevelopmentObjectives

        const brandNewPlacement = false
        let readOnly = false
        if (selectedPlacement.placementPartners && selectedPlacement.placementPartners.includes(org)) {
          readOnly = true
        }

        this.setState({
          readOnly, selectedPlacement,

          emailId: email, username, cuFirstName, cuLastName, path, org, roleName, orgFocus, accountCode,
          _id, postingId, title, workType, employerName, employerURL, workFunction, industry, pathways, departments,
          startDate, endDate, isPerpetual, location, hoursPerWeek, payType, pay, description, timesheetEnabled,
          hiringManagerFirstName, hiringManagerLastName,
          hiringManagerEmail, orgContactEmail, workers,

          trainingPlanURL, trainingAgreementURL, w9URL, supplierFormURL, trainingAgreementExecuted,
          w9Executed, supplierFormExecuted,
          pathwayPlacementType, educationDevelopmentPlanURL, individualizedEducationPlanURL, employmentHourDetails,
          siteVisitSchedule, academicLearningObjectives, careerDevelopmentObjectives,

          brandNewPlacement, inModal

        })
      } else if (fillRole) {
        // fill role
        console.log('filling role')

        const placed = this.state.selectedPosting.placed
        const title = this.props.title
        const workType = this.props.workType
        const postingId = this.props.postingId
        const description = this.props.description
        const accountCode = this.props.accountCode
        const hiringManagerFirstName = this.props.hiringManagerFirstName
        const hiringManagerLastName = this.props.hiringManagerLastName
        const hiringManagerEmail = this.props.hiringManagerEmail
        const orgContactEmail = this.props.orgContactEmail
        const placementPartners = this.props.placementPartners
        const payType = this.props.payType
        const startDate = this.props.startDate
        const endDate = this.props.endDate
        const isPerpetual = this.props.isPerpetual

        let location = null
        if (this.props.locationValue) {
          location = this.props.locationValue
        }

        const workFunction = this.props.workFunction
        const industry = this.props.industry

        let pathways = []
        if (this.props.pathways) {
          pathways = this.props.pathways
        }
        let departments = []
        if (this.props.departments) {
          departments = this.props.departments
        }
        const hoursPerWeek = this.props.hoursPerWeek
        const employerName = this.props.employerName
        const employerURL = this.props.employerURL
        // console.log('show me the worktype: ', workType)
        let employmentHourDetails = [
          { day: 'Mon', startTime: '', endTime: '' },{ day: 'Tue', startTime: '', endTime: '' },
          { day: 'Wed', startTime: '', endTime: '' },{ day: 'Thu', startTime: '', endTime: '' },
          { day: 'Fri', startTime: '', endTime: '' },{ day: 'Sat', startTime: '', endTime: '' },
          { day: 'Sun', startTime: '', endTime: '' },
        ]

        const brandNewPlacement = false

        this.setState({
          placed, fillRole, emailId: email, username, cuFirstName, cuLastName, path, org, roleName, orgFocus, accountCode,
          postingId, title, workType, employerName, employerURL, workFunction, industry, pathways, departments, startDate, endDate, isPerpetual,
          location, hoursPerWeek, payType, description, hiringManagerFirstName, hiringManagerLastName,
          hiringManagerEmail, orgContactEmail, placementPartners, inModal,

          employmentHourDetails, brandNewPlacement
        })
      } else {
        const brandNewPlacement = true
        const postBy = 'Fresh Placement'
        const accountCode = this.props.accountCode

        this.setState({
          brandNewPlacement, emailId: email, username, cuFirstName, cuLastName, path, org, roleName, orgFocus, accountCode,
          postBy
        })

        const postTypes = ['Internship','Work']

        if (org && org !== '') {
          // pull postingOptions
          Axios.get('/api/postings/user', { params: { orgCode: org, accountCode, postTypes} })
          .then((response) => {
            console.log('Posted postings query attempted now', response.data);

            if (response.data.success) {
              console.log('posted postings query worked')

              if (response.data.postings && response.data.postings.length !== 0) {

                let postingOptions = response.data.postings
                postingOptions.unshift({ title: '', employerName: '' })
                this.setState({ postingOptions });
              }

            } else {
              console.log('posted postings query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Posted postings query did not work for some reason', error);
          });
        }
      }

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted one', response.data);

        if (response.data.success) {
          console.log('org info query worked', path)

          let pathwayOptions = []
          if (response.data.orgInfo.pathways) {
            pathwayOptions = response.data.orgInfo.pathways
          }
          let departmentOptions = []
          if (response.data.orgInfo.departments) {
            departmentOptions = response.data.orgInfo.departments
          }
          this.setState({ pathwayOptions, departmentOptions })

          Axios.get('/api/pathways', { params: { orgCode: org } })
          .then((response) => {
            console.log('Pathways query attempted two oh', response.data);

            if (response.data.success && response.data.pathways && response.data.pathways.length > 0) {
              console.log('pathways query worked')

              pathwayOptions = response.data.pathways
              this.setState({ pathwayOptions});

            } else {
              console.log('org info query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });

          const placementPartners = response.data.orgInfo.placementPartners

          Axios.get('/api/account/partners', { params: { org, placementPartners } })
          .then((response) => {
            console.log('Posted employer query attempted', response.data);

            if (response.data.success) {
              console.log('posted employer query worked')

              if (response.data.employers.length !== 0) {

                let employerOptions = response.data.employers
                employerOptions.sort(function(a, b){
                    if(a.employerName < b.employerName) { return -1; }
                    if(a.employerName > b.employerName) { return 1; }
                    return 0;
                })

                let selectedEmployer = { employerName: 'No Employer Selected'}
                employerOptions.unshift(selectedEmployer)

                if (selectedPlacement) {

                  if (selectedPlacement.accountCode) {
                    for (let i = 1; i <= employerOptions.length; i++) {
                      // console.log('did we get in here for sure: ', selectedOpportunity.accountCode)
                      if (selectedPlacement.accountCode === employerOptions[i - 1].accountCode) {
                        // console.log('testers', employerOptions[i - 1])
                        selectedEmployer = employerOptions[i - 1]
                      }
                    }
                  }
                } else if (fillRole) {
                  for (let i = 1; i <= employerOptions.length; i++) {
                    // console.log('did we get in here for sure: ', selectedOpportunity.accountCode)
                    if (this.props.employerName === employerOptions[i - 1].employerName) {
                      // console.log('testers', employerOptions[i - 1])
                      selectedEmployer = employerOptions[i - 1]
                    }
                  }
                }
                // console.log('show accountCode: ', selectedEmployer, selectedOpportunity)
                this.setState({ employerOptions, selectedEmployer });
              }

            } else {
              console.log('query for employers query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Employer query did not work for some reason', error);
          });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          let functionOptions = response.data.workOptions[0].functionOptions
          let workTypeOptions = response.data.workOptions[0].workTypeOptions
          workTypeOptions.push("Program")

          let politicalPartyOptions = response.data.workOptions[0].politicalAlignmentOptions
          if (politicalPartyOptions) {
            politicalPartyOptions.unshift('')
          }

          this.setState({
            functionOptions ,
            industryOptions: response.data.workOptions[0].industryOptions,
            hoursPerWeekOptions: response.data.workOptions[0].hoursPerWeekOptions,
            hourlyPayRangeOptions: response.data.workOptions[0].hourlyPayOptions,
            annualPayRangeOptions: response.data.workOptions[0].annualPayOptions,
            workTypeOptions, politicalPartyOptions,
          })

          if (org === 'c2c') {
            //pull general benchmarks as backup
            Axios.get('/api/benchmarks', { params: { orgCode: org } })
            .then((response) => {
              console.log('Benchmarks query attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                if (response.data.benchmarks.length !== 0) {

                  let benchmarkOptions = response.data.benchmarks
                  functionOptions = ['']
                  for (let i = 1; i <= benchmarkOptions.length; i++) {
                    if (!benchmarkOptions[i - 1].title.includes('Scholarship') && !benchmarkOptions[i - 1].title.includes('C2C')) {
                      if (benchmarkOptions[i - 1].jobFunction && !functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                        functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                      }

                    }
                  }

                  benchmarkOptions.unshift({ title: 'No Benchmark Added'})
                  this.setState({ functionOptions, benchmarkOptions })
                }
              }
            })
          } else {
            console.log('pull benchmarks ')

            const accountCode = this.props.accountCode
            Axios.get('/api/benchmarks', { params: { orgCodes: [org, 'general'], accountCode } })
            .then((response) => {
              console.log('Benchmarks query attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                if (response.data.benchmarks.length !== 0) {
                  console.log('show fo1: ', functionOptions)
                  let benchmarkOptions = response.data.benchmarks
                  functionOptions = ['']
                  for (let i = 1; i <= benchmarkOptions.length; i++) {
                    if (benchmarkOptions[i - 1].jobFunction && !functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                      functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                    }
                  }
                  console.log('show fo2: ', functionOptions)
                  benchmarkOptions.unshift({ title: 'No Benchmark Added'})
                  functionOptions.sort()
                  this.setState({ benchmarkOptions, functionOptions });
                }

              } else {
                console.log('benchmark query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
            });
          }
        }
      });

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name === 'title') {
        this.setState({ title: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'workType') {
        this.setState({ workType: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerName') {
        this.setState({ employerName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerURL') {
        this.setState({ employerURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'workFunction') {
        this.setState({ workFunction: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'industry') {
        this.setState({ industry: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'startDate') {
        this.setState({ startDate: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'endDate') {
        this.setState({ endDate: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'location') {
        this.setState({ location: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'hoursPerWeek') {
        this.setState({ hoursPerWeek: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'payType') {
        this.setState({ payType: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'pay') {
        this.setState({ pay: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'description') {
        this.setState({ description: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'hiringManagerFirstName') {
        this.setState({ hiringManagerFirstName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'hiringManagerLastName') {
        this.setState({ hiringManagerLastName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'hiringManagerEmail') {
        this.setState({ hiringManagerEmail: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'orgContactEmail') {
        this.setState({ orgContactEmail: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'workerName') {
        const workerName = event.target.value
        this.setState({ workerName, formHasChanged: true })
        this.searchObjects('worker',workerName)
      } else if (event.target.name === 'trainingPlanURL') {
        this.setState({ trainingPlanURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'trainingAgreementURL') {
        this.setState({ trainingAgreementURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'w9URL') {
        this.setState({ w9URL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'supplierFormURL') {
        this.setState({ supplierFormURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'pathwayPlacementType') {
        this.setState({ pathwayPlacementType: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'educationDevelopmentPlanURL') {
        this.setState({ educationDevelopmentPlanURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'individualizedEducationPlanURL') {
        this.setState({ individualizedEducationPlanURL: event.target.value, formHasChanged: true })
      } else if (event.target.name.includes('employmentHourDetails')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = Number(nameArray[2])

        let employmentHourDetails = this.state.employmentHourDetails
        employmentHourDetails[index][field] = event.target.value
        this.setState({ employmentHourDetails })
      } else if (event.target.name.includes('siteVisitSchedule')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = Number(nameArray[2])

        let siteVisitSchedule = this.state.siteVisitSchedule
        siteVisitSchedule[index][field] = event.target.value
        this.setState({ siteVisitSchedule })
      } else if (event.target.name.includes('academicLearningObjectives')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = Number(nameArray[2])

        let academicLearningObjectives = this.state.academicLearningObjectives
        academicLearningObjectives[index][field] = event.target.value
        this.setState({ academicLearningObjectives })
      } else if (event.target.name.includes('careerDevelopmentObjectives')) {
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        const index = Number(nameArray[2])

        let careerDevelopmentObjectives = this.state.careerDevelopmentObjectives
        careerDevelopmentObjectives[index][field] = event.target.value
        // console.log('up in it: ', nameArray, field, index, careerDevelopmentObjectives)
        this.setState({ careerDevelopmentObjectives })
      } else if (event.target.name === 'selectedPosting') {
        let selectedPosting = { title: '', employerName: ''}
        for (let i = 1; i <= this.state.postingOptions.length; i++) {
          const testValue = this.state.postingOptions[i - 1].title + ' | ' + this.state.postingOptions[i - 1].employerName
          if (testValue === event.target.value) {
            selectedPosting = this.state.postingOptions[i - 1]
          }
        }
        console.log('show selectedPosting: ', selectedPosting)
        this.setState({ selectedPosting })
      } else if (event.target.name === 'postBy') {
        this.setState({ postBy: event.target.value })
      } else if (event.target.name === 'searchStudents') {
        this.setState({ studentSearchString: event.target.value, formHasChanged: true })
        this.searchObjects('student', event.target.value)
      } else if (event.target.name === 'searchPostings') {
        this.setState({ postingSearchString: event.target.value, formHasChanged: true })
        this.searchObjects('posting', event.target.value)
      } else if (event.target.name === 'searchApplications') {
        this.setState({ applicationSearchString: event.target.value, formHasChanged: true })
        this.searchObjects('application', event.target.value)
      } else if (event.target.name === "selectEmployer") {

        let selectedEmployer = null
        let employerName = this.state.employerName
        let employerURL = this.state.employerURL
        for (let i = 1; i <= this.state.employerOptions.length; i++) {
          console.log(i, 'updating employer');

          if (event.target.value === this.state.employerOptions[i - 1].employerName) {
            selectedEmployer = this.state.employerOptions[i - 1]
            employerName = this.state.employerOptions[i - 1].employerName
            employerURL = this.state.employerOptions[i - 1].employerURL
          }
        }

        this.setState({ selectedEmployer, formHasChanged: true,
          employerName, employerURL
        })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchObjects(type, workerName) {
      console.log('searchObjects ', type, workerName)

      const orgCode = this.state.org
      const accountCode = this.state.accountCode
      const postingId = this.state.postingId
      const search = true
      const postTypes = ['Internship','Work']

      let searchURL = '/api/applications/search'
      if (type === 'worker') {
        if (this.state.searchBy === 'Search All Career-Seekers') {
          searchURL = '/api/students/search'
        }
      } else if (type === 'student') {
        // do nothing
      } else if (type === 'posting') {
        searchURL = '/api/postings/search'
      } else if (type === 'application') {
        searchURL = '/api/applications/search'
      }

      Axios.get(searchURL, { params: { searchString: workerName, orgCode, accountCode, postingId, search, postTypes } })
      .then((response) => {
        console.log('Search query attempted', response.data);

          if (response.data.success) {
            console.log('worker search query worked')

            if (type === 'worker') {
              // if (onlyApplicants) {
              //   searchURL = '/api/applications/search'
              // }
              let suggestedWorkers = response.data.applicants
              if (this.state.searchBy === 'Search All Career-Seekers') {
                suggestedWorkers = response.data.students
              }
              this.setState({ suggestedWorkers });
            } else if (type === 'student') {
              // do nothing
              let suggestedStudents = response.data.students
              this.setState({ suggestedStudents })
            } else if (type === 'posting') {
              let suggestedPostings = response.data.postings
              this.setState({ suggestedPostings })
            } else if (type === 'application') {
              let suggestedApplications = response.data.applicants
              this.setState({ suggestedApplications })
            }

          } else {
            console.log('workers search query did not work', response.data.message)

            if (type === 'worker') {
              const suggestedWorkers = []
              this.setState({ suggestedWorkers })
            } else if (type === 'student') {
              // do nothing
              let suggestedStudents = []
              this.setState({ suggestedStudents })
            } else if (type === 'posting') {
              let suggestedPostings = []
              this.setState({ suggestedPostings })
            } else if (type === 'application') {
              let suggestedApplications = []
              this.setState({ suggestedApplications })
            }
          }

      }).catch((error) => {
          console.log('Assignee search query did not work for some reason', error);
      });
    }

    itemClicked(type, item) {
      console.log('itemClicked called ', type, item)

      if (type === 'worker') {
        const suggestedWorkers = []
        const workerName = item.firstName + ' ' + item.lastName
        console.log('show aName: ', workerName)

        this.setState({ suggestedWorkers, worker: item, workerName, formHasChanged: true })
      } else if (type === 'student') {
        const suggestedStudents = []
        const selectedStudent = item
        const studentSearchString = item.firstName + ' ' + item.lastName

        console.log('show aName: ', studentSearchString)

        this.setState({ suggestedStudents, selectedStudent, studentSearchString, item, formHasChanged: true })
      } else if (type === 'application') {
        const suggestedApplications = []
        const selectedApplication = item
        const applicationSearchString = item.firstName + ' ' + item.lastName

        console.log('show aName: ', applicationSearchString)

        this.setState({ suggestedApplications, selectedApplication, applicationSearchString, formHasChanged: true })
      } else if (type === 'posting') {
        const suggestedPostings = []
        const selectedPosting = item
        const postingSearchString = item.title + ' ' + item.employerName

        console.log('show aName: ', postingSearchString)

        this.setState({ suggestedPostings, selectedPosting, postingSearchString, formHasChanged: true })
      }

    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'worker') {
        if (this.state.worker) {
          let worker = this.state.worker
          if (worker.postingTitle || worker.postingEmployerName) {
            worker['applicationId'] = worker._id
          }

          let workers = this.state.workers
          workers.push(worker)
          worker = null
          let workerName = ''
          this.setState({ workers, worker, workerName })
        }
      } else if (type === 'siteVisitSchedule') {
        let siteVisitSchedule = this.state.siteVisitSchedule
        siteVisitSchedule.push({ date: '', isCompleted: false, notes: '' })
        this.setState({ siteVisitSchedule })
      } else if (type === 'academicLearningObjectives') {
        let academicLearningObjectives = this.state.academicLearningObjectives
        academicLearningObjectives.push({ objective: '', results: '' })
        this.setState({ academicLearningObjectives })
      } else if (type === 'careerDevelopmentObjectives') {
        let careerDevelopmentObjectives = this.state.careerDevelopmentObjectives
        careerDevelopmentObjectives.push({ objective: '', results: '' })
        this.setState({ careerDevelopmentObjectives })
      } else if (type === 'student') {
        if (this.state.selectedStudent) {
          let selectedStudent = this.state.selectedStudent
          let students = this.state.students

          if (this.state.oneStudent && this.state.students.length === 0) {
            students.push(this.state.selectedStudent)
          } else {
            students = [this.state.selectedStudent]
          }

          selectedStudent = null
          let studentSearchString = ''
          this.setState({ students, selectedStudent, studentSearchString })
        }
      } else if (type === 'application') {
        if (this.state.selectedApplication) {

          let applications = this.state.applications
          applications.push(this.state.selectedApplication)
          let selectedApplication = null
          let applicationSearchString = ''
          this.setState({ applications, selectedApplication, applicationSearchString })
        }
      } else if (type === 'searchedPosting') {
        if (this.state.selectedPosting) {
          // see if there are existing applications
          if (this.state.postBy === 'By Student') {

            const email = this.state.students[0].email
            const postingId = this.state.selectedPosting._id

            Axios.get('/api/applications/bypost', { params: { email, postingId } })
            .then((response) => {
              console.log('Applications by posting query attempted now', response.data);

              if (response.data.success) {
                console.log('applications by posting query worked')

                if (response.data.applications && response.data.applications.length !== 0) {

                  let selectedPosting = this.state.selectedPosting
                  let postings = this.state.postings
                  if (postings.length === 0) {
                    postings.push(this.state.selectedPosting)
                  } else {
                    postings = [this.state.selectedPosting]
                  }

                  // selectedPosting = null
                  let postingSearchString = ''
                  // let selectedApplication = response.data.applications[0]
                  const workers = response.data.applications

                  this.setState({ postings, selectedPosting, postingSearchString, workers,
                    applicationSuccessMessage: 'This career-seeker has submitted an application'

                  })

                  this.finishAddingPosting()
                } else {
                  let selectedPosting = this.state.selectedPosting
                  let postings = this.state.postings
                  if (postings.length === 0) {
                    postings.push(this.state.selectedPosting)
                  } else {
                    postings = [this.state.selectedPosting]
                  }
                  // selectedPosting = null
                  let postingSearchString = ''
                  const workers = this.state.students

                  this.setState({ postings, selectedPosting, postingSearchString, workers,
                    applicationSuccessMessage: null, applicationErrorMessage: 'This career-seeker has not submitted an application. To better evaluate the program, encourage the career-seeker to submit an application. Applications provide a snapshot of their candidacy at that time.'
                  })
                  this.finishAddingPosting()
                }

              } else {
                console.log('posted postings query did not work', response.data.message)
                let selectedPosting = this.state.selectedPosting
                let postings = this.state.postings
                if (postings.length === 0) {
                  postings.push(this.state.selectedPosting)
                } else {
                  postings = [this.state.selectedPosting]
                }
                // selectedPosting = null
                let postingSearchString = ''
                console.log('show postings 1: ', postings, this.state.selectedPosting)
                const workers = this.state.students

                this.setState({ postings, selectedPosting, postingSearchString, workers,
                  applicationSuccessMessage: null, applicationErrorMessage: 'This career-seeker has not submitted an application. To better evaluate the program, encourage the career-seeker to submit an application. Applications provide a snapshot of their candidacy at that time.'
                })
                this.finishAddingPosting()
              }

            }).catch((error) => {
                console.log('Posted postings query did not work for some reason', error);
                let selectedPosting = this.state.selectedPosting
                let postings = this.state.postings
                if (postings.length === 0) {
                  postings.push(this.state.selectedPosting)
                } else {
                  postings = [this.state.selectedPosting]
                }
                // selectedPosting = null
                let postingSearchString = ''
                const workers = this.state.students

                this.setState({ postings, selectedPosting, postingSearchString, workers,
                  applicationSuccessMessage: null, applicationErrorMessage: 'This career-seeker has not submitted an application. To better evaluate the program, encourage the career-seeker to submit an application. Applications provide a snapshot of their candidacy at that time.'
                })

            });
          } else {

            let selectedPosting = this.state.selectedPosting
            let postings = this.state.postings
            if (postings.length === 0) {
              postings.push(this.state.selectedPosting)
            } else {
              postings = [this.state.selectedPosting]
            }
            // selectedPosting = null
            let postingSearchString = ''

            this.setState({ postings, selectedPosting, postingSearchString })
            this.finishAddingPosting()
          }
        }
      } else if (type === 'posting') {
        if (this.state.selectedPosting && this.state.selectedPosting.title && this.state.selectedPosting.title !== '') {
          this.finishAddingPosting()
        }
      }
    }

    finishAddingPosting() {
      console.log('finishAddingPosting called')

      const postingId = this.state.selectedPosting._id
      const fillRoleFromBrandNew = true
      const placed = this.state.selectedPosting.placed

      const title = this.state.selectedPosting.title
      const workType = this.state.selectedPosting.postType
      const description = this.state.selectedPosting.description
      const accountCode = this.state.selectedPosting.accountCode
      const hiringManagerFirstName = this.state.selectedPosting.employerContactFirstName
      const hiringManagerLastName = this.state.selectedPosting.employerContactLastName
      const hiringManagerEmail = this.state.selectedPosting.employerContactEmail
      const orgContactEmail = this.state.selectedPosting.orgContactEmail
      const payType = this.state.selectedPosting.payType
      const startDate = convertDateToString(this.state.selectedPosting.startDate,'first10')
      const endDate = convertDateToString(this.state.selectedPosting.endDate,'first10')
      const isPerpetual = this.state.selectedPosting.isPerpetual
      const location = this.state.selectedPosting.location
      const pathways = this.state.selectedPosting.pathways
      const departments = this.state.selectedPosting.departments
      const hoursPerWeek = this.state.selectedPosting.hoursPerWeek
      const employerName = this.state.selectedPosting.employerName
      const employerURL = this.state.selectedPosting.employerURL

      const field = this.state.selectedPosting.field
      let workFunction = ''
      let industry = ''

      if (field) {
        workFunction = field.split("|")[0].trim()
        industry = field.split("|")[1].trim()
      }

      let employmentHourDetails = [
        { day: 'Mon', startTime: '', endTime: '' },{ day: 'Tue', startTime: '', endTime: '' },
        { day: 'Wed', startTime: '', endTime: '' },{ day: 'Thu', startTime: '', endTime: '' },
        { day: 'Fri', startTime: '', endTime: '' },{ day: 'Sat', startTime: '', endTime: '' },
        { day: 'Sun', startTime: '', endTime: '' },
      ]

      console.log('postings three: ', this.state.postings)

      this.setState({
        postingId, fillRoleFromBrandNew, placed,
        title, workType, description, accountCode, hiringManagerFirstName, hiringManagerLastName,
        hiringManagerEmail, orgContactEmail, payType, startDate, endDate, isPerpetual, location, workFunction,
        industry, pathways, departments, hoursPerWeek, employerName, employerURL, employmentHourDetails
      })
      console.log('postings four: ', this.state.postings)
    }

    removeItem(index, type) {
      console.log('removeItem called', index, type)

      if (type === 'worker') {
        let workers = this.state.workers
        workers.splice(index, 1)
        this.setState({ workers, formHasChanged: true })
      } else if (type === 'student') {
        let students = this.state.students
        students.splice(index, 1)
        this.setState({ students, formHasChanged: true })
      } else if (type === 'application') {
        let applications = this.state.applications
        applications.splice(index, 1)
        this.setState({ applications, formHasChanged: true })
      } else if (type === 'posting') {
        let postings = this.state.postings
        postings.splice(index, 1)
        this.setState({ postings, formHasChanged: true })
      }
    }

    changeItem(name, value) {
      console.log('changeItem called', name, value)

      if (name.includes('siteVisitSchedule')) {
        const nameArray = name.split("|")
        const field = nameArray[1]
        const index = Number(nameArray[2])

        let siteVisitSchedule = this.state.siteVisitSchedule
        siteVisitSchedule[index][field] = value
        this.setState({ siteVisitSchedule })
      }
    }

    savePlacement(sendNotification) {
      console.log('savePlacement called', sendNotification)

      this.setState({ clientErrorMessage: '', serverErrorMessage: '', serverSuccessMessage: '', isSaving: true, confirmNotifyApplicants: false })

      if (this.state.employerContactFirstName === '') {
        this.setState({ clientErrorMessage: 'please add a hiring manager first name', isSaving: false })
      } else if (this.state.employerContactLastName === '') {
        this.setState({ clientErrorMessage: 'please add a hiring manager last name', isSaving: false })
      } else if (this.state.employerContactEmail === '') {
        this.setState({ clientErrorMessage: 'please add a hiring manager email', isSaving: false })
      } else if (this.state.title === '') {
        this.setState({ clientErrorMessage: 'please add a title for the role', isSaving: false })
      } else if (this.state.description === '') {
        this.setState({ clientErrorMessage: 'please add a description for the role', isSaving: false })
      } else if (this.state.orgContactEmail === '') {
        this.setState({ clientErrorMessage: 'please add an internal coordinator contact for the role', isSaving: false })
      } else if (this.state.startDate === '') {
        this.setState({ clientErrorMessage: 'please add a start date for the role', isSaving: false })
      } else if (this.state.isPerpetual === false &&  this.state.endDate === '') {
        this.setState({ clientErrorMessage: 'please add the ideal end date or indicate that this is a perpetual role', isSaving: false })
      } else if (this.state.location === '') {
        this.setState({ clientErrorMessage: 'please add a location for the role', isSaving: false })
      } else if (this.state.hoursPerWeek === '') {
        this.setState({ clientErrorMessage: 'please add the hours per week for the role', isSaving: false })
      } else if (!this.state.payType && this.state.payType === '') {
        this.setState({ clientErrorMessage: 'please add the pay type for the role', isSaving: false })
      } else if (!this.state.pay && this.state.pay === '') {
        this.setState({ clientErrorMessage: 'please add the pay for the role', isSaving: false })
      } else {
        console.log('about to fill')

        if (!this.state.workers || this.state.workers.length === 0) {
          this.setState({ clientErrorMessage: 'please add at least one worker', isSaving: false })
          return;
        } else {
          for (let i = 1; i <= this.state.workers.length; i++) {
            console.log(i, 'worker check', this.state.workers[i - 1])

            if (!this.state.workers[i - 1].email || this.state.workers[i - 1].email === '') {
              console.log('went into name check')
              this.setState({ clientErrorMessage: 'please add the email for each worker', isSaving: false })
              return;
            }
          }
        }

        const _id = this.state._id
        const postingId  = this.state.postingId

        const title = this.state.title
        const workType = this.state.workType
        const hiringManagerFirstName = this.state.hiringManagerFirstName
        const hiringManagerLastName = this.state.hiringManagerLastName
        const hiringManagerEmail = this.state.hiringManagerEmail

        const description = this.state.description
        const orgContactEmail = this.state.orgContactEmail
        const startDate = this.state.startDate
        const endDate = this.state.endDate
        const isPerpetual = this.state.isPerpetual
        const location = this.state.location
        const field = this.state.field
        const workFunction = this.state.workFunction
        const industry = this.state.industry
        const pathways = this.state.pathways
        const departments = this.state.departments
        const selectedPrograms = this.state.selectedPrograms
        const hoursPerWeek = this.state.hoursPerWeek
        const employerName = this.state.employerName
        const employerURL = this.state.employerURL
        const orgCode = this.state.org
        const accountCode = this.state.accountCode

        const payType = this.state.payType
        const pay = this.state.pay
        const timesheetEnabled = this.state.timesheetEnabled

        const workers = this.state.workers
        const trainingPlanURL = this.state.trainingPlanURL
        const trainingAgreementURL = this.state.trainingAgreementURL
        const w9URL = this.state.w9URL
        const supplierFormURL = this.state.supplierFormURL
        const trainingAgreementExecuted = this.state.trainingAgreementExecuted
        const w9Executed = this.state.w9Executed
        const supplierFormExecuted = this.state.supplierFormExecuted
        const pathwayPlacementType = this.state.pathwayPlacementType
        const educationDevelopmentPlanURL = this.state.educationDevelopmentPlanURL
        const individualizedEducationPlanURL = this.state.individualizedEducationPlanURL
        const employmentHourDetails = this.state.employmentHourDetails
        const siteVisitSchedule = this.state.siteVisitSchedule
        const academicLearningObjectives = this.state.academicLearningObjectives
        const careerDevelopmentObjectives = this.state.careerDevelopmentObjectives
        const placementPartners = this.state.placementPartners

        const createdAt = new Date()
        const updatedAt = new Date()
        //
        let postRoute = '/api/work'
        if (this.state.fillRole || this.state.fillRoleFromBrandNew) {
          postRoute = '/api/postings/fill'
        }

        const selectedPlacement = {
          _id, postingId, workType, title, hiringManagerFirstName, hiringManagerLastName, hiringManagerEmail, payType, pay,
          description, orgContactEmail, timesheetEnabled, startDate, endDate, isPerpetual, location, field,
          workFunction, industry, pathways, departments, selectedPrograms, hoursPerWeek, orgCode, accountCode,
          workers, trainingPlanURL, trainingAgreementURL, w9URL, supplierFormURL, trainingAgreementExecuted, w9Executed, supplierFormExecuted,
          pathwayPlacementType, educationDevelopmentPlanURL, individualizedEducationPlanURL,
          employmentHourDetails, siteVisitSchedule, academicLearningObjectives, careerDevelopmentObjectives,
          employerName, employerURL, placementPartners, sendNotification,
          createdAt, updatedAt
        }

        Axios.post(postRoute, selectedPlacement)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Fill save worked', response.data);

            this.setState({ serverPostSuccess: true, serverSuccessMessage: "Placement saved successfully!", isSaving: false })

            if (this.state.inModal) {
              if (this.state.fillRole) {
                this.props.closeModal(true)
              } else {
                this.props.closeModal(selectedPlacement)
              }
            }

            if (this.props.duplicate && this.props.navigate.goBack) {
              this.props.navigate(-1)
            }

          } else {
            console.error('there was an error saving the fill');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message, isSaving: false
            })
          }
        }).catch((error) => {
            console.log('The fill save did not work', error);
            this.setState({ serverPostSuccess: false, serverErrorMessage: 'Placement save did not work for some reason', isSaving: false })
        });
      }
    }

    deletePlacement() {
      console.log('deletePlacement called')

      const _id = this.state._id

      Axios.delete('/api/placements/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('placement delete worked');

          this.props.navigate(-1)
          // this.props.navigate(-1)
          // this.setState({
          //   serverPostSuccess: true,
          //   serverSuccessMessage: 'Posting was deleted successfully! Once you exit, you will no longer have access to the posting.', confirmDelete: false
          // })

        } else {
          console.error('there was an error deleting the placement');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The placement deleting did not work', error);
          this.setState({ errorMessage: error })
      });
    }

    optionClicked(value, index, type) {
      console.log('optionClicked called', value, index, type)

      if (type === 'pathway') {
        let pathways = this.state.pathways
        if (pathways && pathways.length > 0) {

          let removeIndex = -1
          if (!value.name && !value.title) {
            removeIndex = pathways.indexOf(value)
          } else if (!value.name && value.title) {
            removeIndex = pathways.findIndex(p => p.title === value.title)
          } else if (value.name && !value.title) {
            removeIndex = pathways.findIndex(p => p.name === value.name)
          }
          // console.log('show the logs: ', removeIndex1, removeIndex2, removeIndex3)
          if (removeIndex > -1) {
            pathways.splice(removeIndex, 1)
          } else {
            pathways.push(value)
          }
        } else {
          pathways.push(value)
        }
        this.setState({ pathways })
      } else if (type === 'department') {
        let departments = this.state.departments
        if (departments && departments.length > 0) {

          if (departments.includes(value)) {

            const removeIndex = departments.indexOf(value)
            departments.splice(removeIndex, 1)

          } else {
            departments.push(value)
          }
        } else {
          departments.push(value)
        }
        this.setState({ departments })
      }
    }

    renderReadOnlyContent() {
      console.log('renderReadOnlyContent called')

      return (
        <div key="readOnlyContent">
          {(this.state.selectedPlacement.placementPartners && this.state.selectedPlacement.placementPartners.includes(this.state.org)) && (
            <div className="top-padding">
              <p className="error-color">This is a {this.state.selectedPlacement.orgCode} placement. Please email help@guidedcompass.com if you want to remove all {this.state.selectedPlacement.orgCode} placements.</p>
            </div>
          )}

          <div className="top-padding">
            {Object.keys(this.state.selectedPlacement).map((value, optionIndex) =>
              <div key={optionIndex}>
                {(!this.state.excludedKeys.includes(value) && Object.values(this.state.selectedPlacement)[optionIndex]) && (
                  <div>
                    {((typeof Object.values(this.state.selectedPlacement)[optionIndex] !== 'object') || (typeof Object.values(this.state.selectedPlacement)[optionIndex] === 'object' && Object.values(this.state.selectedPlacement)[optionIndex].length > 0)) && (
                      <div>
                        <div className={(optionIndex % 2 === 0) ? "container-left description-text-2" : "container-left description-text-2"}>
                          <label className="profile-label">{value.charAt(0).toUpperCase() + value.slice(1).replace(/(?=[A-Z])/g, ' ')}</label>

                          {(typeof Object.values(this.state.selectedPlacement)[optionIndex] === 'string') && (
                            <div>
                              <p className="heading-text-4">{Object.values(this.state.selectedPlacement)[optionIndex]}</p>
                            </div>
                          )}
                          {(typeof Object.values(this.state.selectedPlacement)[optionIndex] === 'object') && (
                            <div>
                              {(Object.values(this.state.selectedPlacement)[optionIndex]) ? (
                                <div>
                                  {(Object.values(this.state.selectedPlacement)[optionIndex][0] === 'string') ? (
                                    <p className="heading-text-4">{Object.values(this.state.selectedPlacement)[optionIndex].toString()}</p>
                                  ) : (
                                    <div>
                                      {Object.values(this.state.selectedPlacement)[optionIndex].map((value2, optionIndex2) =>
                                        <div key={value2.name}>
                                          <p className="description-text-3">{value2.firstName} {value2.lastName}</p>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                </div>
                              ) : (
                                <p className="heading-text-4">No Value</p>
                              )}

                            </div>
                          )}
                          {(typeof Object.values(this.state.selectedPlacement)[optionIndex] === 'boolean') && (
                            <div>
                              {(Object.values(this.state.selectedPlacement)[optionIndex]) ? (
                                <p className="heading-text-4">YES</p>
                              ) : (
                                <p className="heading-text-4">NO</p>
                              )}
                            </div>
                          )}
                        </div>
                        {(optionIndex % 2 === 1) && (
                          <div>
                            <div className="clear" />
                            <div className="spacer" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )
    }

    renderPathways() {
      console.log('renderPathways called')

      let rows = []
      for (let i = 1; i <= this.state.pathwayOptions.length; i++) {

        let value = this.state.pathwayOptions[i - 1]
        let optionIndex = i - 1

        let tagText = ''
        let tagContainerClass = ''
        if (value.school) {
          tagText = value.name + " _ " + value.school
          if (this.state.pathways.some(p => (p.name + ' - ' + p.school) === (value.name + ' - ' + value.school))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        } else if (value.title) {
          tagText = value.title
          if (this.state.pathways.some(p => (p.title) === (value.title))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        } else {
          tagText = value.name
          if (this.state.pathways.some(p => (p.name) === (value.name))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        }

        rows.push(
          <div key={"pathway" + i}>
            <div key={optionIndex}>
              <div className="float-left row-10 right-padding">
                <button className={tagContainerClass} onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                  <div>
                    <div className="float-left left-text">
                      <p className="description-text-2">{tagText}</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )
      }

      return rows
    }

    render() {

      return (
          <div>
            <div className={(!this.state.inModal) ? "standard-container" : ""}>

              <div className="row-10">
                {(this.state.title) ? (
                  <p className="heading-text-2">{this.state.title} {(window.location.pathname.includes('/organizations')) ? "Placement" : "Hire(s)"}</p>
                ) : (
                  <p className="heading-text-2">New Placement</p>
                )}
              </div>

              {(this.props.duplicate) && (
                <div className="row-10">
                  <p className="description-text-2 error-color bold-text">This is a duplicated posting. Saving this will create a new posting.</p>
                </div>
              )}

              {(this.state.brandNewPlacement) && (
                <div>
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">How would you like to add the placement?</label>
                      <select name="postBy" value={this.state.postBy} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.postByOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.postBy === 'Import Posting') && (
                    <div>
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Select a Posting</label>
                          <div className="half-spacer" />

                          <select name="selectedPosting" value={(this.state.selectedPosting.title && this.state.selectedPosting.title !== '') ? this.state.selectedPosting.title + ' | ' + this.state.selectedPosting.employerName : ''} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.postingOptions.map(value =>
                              <option key={value.title} value={(value.title && value.title !== '') ? value.title + ' | ' + value.employerName : ''}>{(value.title && value.title !== '') ? value.title + ' | ' + value.employerName : ''}</option>
                            )}
                          </select>
                        </div>
                        <div className="container-right">
                          <div className="super-spacer" />
                          <button className="btn btn-squarish" onClick={() => this.addItem('posting')}>Select</button>
                        </div>
                        <div className="clear" />

                        {(this.state.postingId) && (
                          <div>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <hr className="clear-margin clear-padding"/>

                          </div>
                        )}

                      </div>
                    </div>
                  )}

                </div>
              )}

              {(this.state.selectedPlacement && this.state.readOnly && !window.location.pathname.includes('/employers/')) ? (
                <div>
                  {this.renderReadOnlyContent()}
                  <div className="clear" />
                </div>
              ) : (
                <div>
                  <div>
                    {(this.state.placed) && (
                      <div className="row-10">
                        <p className="description-text-2 error-color half-bold-text">{this.state.title} has already been placed. We advise you to edit the existing placement and "edit applicants" if they have changed who they have hired</p>
                      </div>
                    )}
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">{(this.state.workType === 'Program') ? "Program" : "Job"} Title<label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Add the title..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Type<label className="error-color bold-text">*</label></label>
                        <select name="workType" value={this.state.workType} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.workTypeOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">{(this.state.workType === 'Program') ? "Training Provider" : "Employer Name"}<label className="error-color bold-text">*</label></label>
                        <select name="selectEmployer" value={this.state.selectedEmployer.employerName} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.employerOptions.map(value =>
                            <option key={value.key} value={value.employerName}>{value.employerName}</option>
                          )}
                        </select>
                      </div>

                      <div className="container-right">
                        <label className="profile-label">Functional Area</label>
                        <select name="workFunction" value={this.state.workFunction} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.functionOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      {/*
                      <div className="container-left">
                        <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Employer name..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Employer URL<label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Employer name..." name="employerURL" value={this.state.employerURL} onChange={this.formChangeHandler}></input>
                        {(this.state.employerURL && this.state.employerURL !== '' && !this.state.employerURL.startsWith('http')) && (
                          <p className="description-text-2 error-color row-5">Please add a valid link</p>
                        )}
                      </div>*/}
                      <div className="clear" />
                    </div>
                    {/*
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Work Function</label>
                        <select name="workFunction" value={this.state.workFunction} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.functionOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Industry</label>
                        <select name="industry" value={this.state.industry} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.industryOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>*/}

                    {((this.state.pathwayOptions) && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
                      <div>
                        <div className="row-10">
                          <label className="profile-label">Pathways</label>
                          {this.renderPathways()}
                          {/*
                          {this.state.pathwayOptions.map((value, optionIndex) =>
                            <div key={optionIndex}>
                              <div className="float-left row-10 right-padding">
                                {(value.school) ? (
                                  <div>
                                    {(this.state.pathways.some(p => (p.name + ' - ' + p.school) === (value.name + ' - ' + value.school))) ? (
                                      <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                                        <div>
                                          <div className="float-left left-text">
                                            <p className="description-text-2 white-text">{value.name} - {value.school}</p>
                                          </div>
                                        </div>
                                      </button>
                                    ) : (
                                      <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                                        <div>
                                          <div className="float-left left-text">
                                            <p className="description-text-2">{value.name} - {value.school}</p>
                                          </div>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.pathways.some(p => (p.name) === (value.name))) ? (
                                      <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                                        <div>
                                          <div className="float-left left-text">
                                            <p className="description-text-2 white-text">{value.name}</p>
                                          </div>
                                        </div>
                                      </button>
                                    ) : (
                                      <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                                        <div>
                                          <div className="float-left left-text">
                                            <p className="description-text-2">{value.name}</p>
                                          </div>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                )}

                              </div>
                            </div>
                          )}*/}
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    {(this.state.departmentOptions && this.state.departmentOptions.length > 0) && (
                      <div>
                        <div className="row-10">
                          <label className="profile-label">Departments</label>
                          {this.state.departmentOptions.map((value, optionIndex) =>
                            <div key={optionIndex}>
                              <div className="float-left row-10 right-padding">
                                <div>
                                  {(this.state.departments.some(d => (d.name) === (value.name))) ? (
                                    <button className="background-button tag-container-4 float-left top-margin-5 left-margin-5" onClick={() => this.optionClicked(value, optionIndex,'department')}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2 white-text">{value.name}</p>
                                        </div>
                                      </div>
                                    </button>
                                  ) : (
                                    <button className="background-button tag-container-5" onClick={() => this.optionClicked(value, optionIndex,'department')}>
                                      <div>
                                        <div className="float-left left-text">
                                          <p className="description-text-2">{value.name}</p>
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="anonymous-checkmark">
                        <input type="checkbox" name="isPerpetual" checked={this.state.isPerpetual} onChange={this.formChangeHandler} />
                      </div>
                      <div className="anonymous-descriptor">
                        <label className="description-text-1">This role is continual</label>
                      </div>
                      <div className="clear"/>
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                        <input type="date" className="date-picker" placeholder="Add the start date..." name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                      </div>

                      <div className="container-right">
                        {(this.state.isPerpetual) ? (
                          <div>
                            <label className="profile-label">End Date</label>
                            <label className="heading-text-6">Continual</label>
                          </div>
                        ) : (
                          <div>
                            <label className="profile-label">End Date</label>
                            <input type="date" className="date-picker" placeholder="Add the end date..." name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                          </div>
                        )}
                      </div>
                      <div className="clear"/>
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                        <input type="text" className="text-field" placeholder="Add the location..." name="location" value={this.state.location} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Hours Per Week<label className="error-color bold-text">*</label></label>
                        <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.hoursPerWeekOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Pay Type</label>
                        <select name="payType" value={this.state.payType} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.payTypeOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">{(this.state.payType === 'Salary') ? "Annual Salary" : "Hourly Pay"}</label>
                        <input type="number" className="number-field" placeholder="0..." name="pay" value={this.state.pay} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <label className="profile-label">{(this.state.workType === 'Program') ? "Program" : "Work"} Description<label className="error-color bold-text">*</label></label>
                      <textarea type="text" className="text-field" placeholder="Add the description..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
                    </div>

                    {(this.state.workType !== 'Program') && (
                      <div className="row-10">
                        <label className="profile-label">Enable Timesheets</label>
                        <Switch
                          onChange={(change) => this.setState({ timesheetEnabled: change, formHasChanged: true })}
                          checked={this.state.timesheetEnabled}
                          id="normal-switch"
                        />
                      </div>
                    )}

                    <div>
                      <div className="spacer" /><div className="spacer" />

                      <hr />

                      <div className="spacer" />
                      <div className="row-10">
                        <p className="heading-text-3">Contact Information</p>
                      </div>

                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">{(this.state.workType === 'Program') ? "Program" : "Hiring"} Manager First Name</label>
                        <input type="text" className="text-field" placeholder="Hiring manager first name..." name="hiringManagerFirstName" value={this.state.hiringManagerFirstName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">{(this.state.workType === 'Program') ? "Program" : "Hiring"} Manager Last Name</label>
                        <input type="text" className="text-field" placeholder="Hiring manager last name..." name="hiringManagerLastName" value={this.state.hiringManagerLastName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">{(this.state.workType === 'Program') ? "Program" : "Hiring"} Manager Email</label>
                        <input type="text" className="text-field" placeholder="Hiring manager email..." name="hiringManagerEmail" value={this.state.hiringManagerEmail} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Org Contact Email<label className="error-color bold-text">*</label></label>
                        {(window.location.pathname.includes('/organizations')) ? (
                          <input type="text" className="text-field" placeholder="Add the internal coord email..." name="orgContactEmail" value={this.state.orgContactEmail} onChange={this.formChangeHandler}></input>
                        ) : (
                          <label className="heading-text-6">{this.state.orgContactEmail}</label>
                        )}

                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Who can be added?<label className="error-color bold-text">*</label></label>
                        <select name="searchBy" value={this.state.searchBy} onChange={this.formChangeHandler} className="dropdown">
                          {['','Search by Applicants','Search All Career-Seekers'].map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                        {(this.state.searchBy === 'Search All Career-Seekers') && (
                          <p className="description-text-3 error-color top-padding-5" >Note: we highly recommend that only career-seekers that have applied be added.</p>
                        )}

                      </div>
                      <div className="container-right">
                        <label className="profile-label">Add The {(this.state.searchBy === "Search by Applicants") ? "Applicants" : "Career-Seekers"} Selected<label className="error-color bold-text">*</label></label>
                        <div className="calc-column-offset-70">
                          <input type="text" className="text-field" placeholder={(this.state.searchBy === "Search by Applicants") ? "Applicant name..." : "Career-seeker name..."} name="workerName" value={this.state.workerName} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.addItem('worker')}>Add</button>
                        </div>
                        <div className="clear" />

                        {(this.state.suggestedWorkers && this.state.suggestedWorkers.length > 0) && (
                          <div>
                            <div className="spacer" />
                            {this.state.suggestedWorkers.map(value =>
                              <div key={value._id} className="row-5">
                                <button className="background-button" onClick={() => this.itemClicked('worker',value)}>
                                  <div>
                                    <div className="float-left right-padding">
                                      <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                      <img src={profileIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                    </div>
                                    <div className="float-left">
                                      <p className="cta-color">{value.firstName} {value.lastName}</p>
                                    </div>
                                    <div className="clear" />
                                    <div className="left-padding-30">
                                      <p className="description-text-2">{value.email}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}

                        <div>
                          {this.state.workers && this.state.workers.map((value, optionIndex) =>
                            <div key={value.name + "|" + optionIndex} className="float-left">
                              <div className="close-button-container-1">
                                <button className="background-button" onClick={() => this.removeItem(optionIndex,'worker')}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="float-left row-5 right-padding">
                                <div className="tag-container-5">
                                  <p className="description-text-2">{value.firstName} {value.lastName}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.org === 'dpscd' && window.location.pathname.includes('/organizations')) && (
                      <div>
                        <div>
                          <div className="spacer" /><div className="spacer" />

                          <hr />

                          <div className="spacer" />
                          <div className="row-10">
                            <p className="heading-text-3">Other Information Details</p>
                          </div>

                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Training Plan URL</label>
                            <input type="text" className="text-field" placeholder="Link to training plan..." name="trainingPlanURL" value={this.state.trainingPlanURL} onChange={this.formChangeHandler}></input>
                            {(this.state.trainingPlanURL && this.state.trainingPlanURL !== '' && !this.state.trainingPlanURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Training Agreement URL</label>
                            <input type="text" className="text-field" placeholder="Link to training agreement..." name="trainingAgreementURL" value={this.state.trainingAgreementURL} onChange={this.formChangeHandler}></input>
                            {(this.state.trainingAgreementURL && this.state.trainingAgreementURL !== '' && !this.state.trainingAgreementURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">W9 URL</label>
                            <input type="text" className="text-field" placeholder="Link to W9..." name="w9URL" value={this.state.w9URL} onChange={this.formChangeHandler}></input>
                            {(this.state.w9URL && this.state.w9URL !== '' && !this.state.w9URL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Payments / Supplier Form URL</label>
                            <input type="text" className="text-field" placeholder="Link to payment / supplier form..." name="supplierFormURL" value={this.state.supplierFormURL} onChange={this.formChangeHandler}></input>
                            {(this.state.supplierFormURL && this.state.supplierFormURL !== '' && !this.state.supplierFormURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                        {/*
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Training Agreement Executed</label>
                            <Switch
                              onChange={(change) => this.setState({ trainingAgreementExecuted: change, formHasChanged: true })}
                              checked={this.state.trainingAgreementExecuted}
                              id="normal-switch"
                            />
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Payments / Supplier Form URL</label>
                            <Switch
                              onChange={(change) => this.setState({ w9Executed: change, formHasChanged: true })}
                              checked={this.state.w9Executed}
                              id="normal-switch"
                            />
                          </div>
                          <div className="clear" />
                        </div>*/}
                        {/*
                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Payment / Supplier Form Executed</label>
                            <Switch
                              onChange={(change) => this.setState({ supplierFormExecuted: change, formHasChanged: true })}
                              checked={this.state.supplierFormExecuted}
                              id="normal-switch"
                            />
                          </div>
                          <div className="clear" />
                        </div>*/}

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Pathway Placement Type</label>
                            <select name="pathwayPlacementType" value={this.state.pathwayPlacementType} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.pathwayPlacementTypeOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>

                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Education Development Plan URL</label>
                            <input type="text" className="text-field" placeholder="Link to education development plan..." name="educationDevelopmentPlanURL" value={this.state.educationDevelopmentPlanURL} onChange={this.formChangeHandler}></input>
                            {(this.state.educationDevelopmentPlanURL && this.state.educationDevelopmentPlanURL !== '' && !this.state.educationDevelopmentPlanURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Individualized Education Plan URL</label>
                            <input type="text" className="text-field" placeholder="Link to individualized education plan..." name="individualizedEducationPlanURL" value={this.state.individualizedEducationPlanURL} onChange={this.formChangeHandler}></input>
                            {(this.state.individualizedEducationPlanURL && this.state.individualizedEducationPlanURL !== '' && !this.state.individualizedEducationPlanURL.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <label className="profile-label">Employment Hour Details</label>

                          <div className="row-10">
                            <div>
                              <div className="fixed-column-60">
                                <p>Day</p>
                              </div>
                              <div className="fixed-column-140">
                                <div>
                                  <p>Start Time</p>
                                </div>
                              </div>
                              <div className="fixed-column-140">
                                <p>End Time</p>
                              </div>
                              <div className="clear" />
                            </div>

                            {this.state.employmentHourDetails.map((value, index) =>
                              <div key={"employmentHourDetails" + index} className="description-text-1">
                                <div className="fixed-column-60">
                                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                  <div className="right-padding">
                                    <p>{value.day}</p>
                                  </div>
                                </div>
                                <div className="fixed-column-140">
                                  <div className="spacer"/>
                                  <div className="row-10 right-padding">
                                    <input type="time" className="date-picker" placeholder="Add the start date..." name={"employmentHourDetails|startTime|" + index} value={value.startTime} onChange={this.formChangeHandler}></input>
                                  </div>
                                </div>
                                <div className="fixed-column-140">
                                  <div className="spacer"/>
                                  <div className="row-10 right-padding">
                                    <input type="time" className="date-picker" placeholder="Add the end date..." name={"employmentHourDetails|endTime|" + index} value={value.endTime} onChange={this.formChangeHandler}></input>
                                  </div>
                                </div>
                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row-10">
                          <div className="edit-profile-row">
                            <div className="float-left">
                              <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <label className="profile-label">Site Visit Schedule</label>
                            </div>
                            <div className="fixed-column-50 left-padding top-margin-20">
                              <button onClick={() => this.addItem('siteVisitSchedule')} className="background-button" type="button">
                                <div className="circle-corners cta-border padding-5">
                                  <img src={addIconBlue} alt="Compass add icon" className="image-auto-12"/>
                                </div>
                              </button>
                            </div>
                            <div className="clear"/>
                          </div>

                          {this.state.siteVisitSchedule && this.state.siteVisitSchedule.map((value, index) =>
                            <div key={value._id} className="row-5">
                              <div className="fixed-column-50 top-padding">
                                <p className="heading-text-2">#{index + 1}</p>
                              </div>
                              <div className="fixed-column-200">
                                <p className="profile-label">Date</p>
                                <input type="date" className="date-picker" placeholder="Add the date..." name={"siteVisitSchedule|date|" + index} value={convertDateToString(value.date,'first10')} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="fixed-column-100 left-padding">
                                <p className="profile-label">Completed</p>
                                <Switch
                                  onChange={(change) => this.changeItem("siteVisitSchedule|isCompleted|" + index, change)}
                                  checked={value.isCompleted}
                                  id="normal-switch"
                                />
                              </div>
                              <div className="clear"/>

                              <div className="full-width left-padding-50 row-5">
                                <p className="profile-label">Notes</p>
                                <textarea type="text" className="text-field" placeholder="Add notes..." name={"siteVisitSchedule|notes|" + index} value={value.notes} onChange={this.formChangeHandler}></textarea>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>

                        <div className="row-10">
                          <div className="edit-profile-row">
                            <div className="float-left">
                              <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <label className="profile-label">Academic Learning Objectives</label>
                            </div>
                            <div className="fixed-column-50 left-padding top-margin-20">
                              <button onClick={() => this.addItem('academicLearningObjectives')} className="background-button" type="button">
                                <div className="circle-corners cta-border padding-5">
                                  <img src={addIconBlue} alt="Compass add icon" className="image-auto-12"/>
                                </div>
                              </button>
                            </div>
                            <div className="clear"/>
                          </div>

                          {this.state.academicLearningObjectives && this.state.academicLearningObjectives.map((value, index) =>
                            <div key={value._id} className="row-5">
                              <div className="fixed-column-50 top-padding">
                                <p className="heading-text-2">#{index + 1}</p>
                              </div>
                              <div className="calc-column-offset-50 row-5">
                                <p className="profile-label">Objective</p>
                                <input type="text" className="text-field" placeholder="Add objective..." name={"academicLearningObjectives|objective|" + index} value={value.objective} onChange={this.formChangeHandler}></input>
                              </div>

                              <div className="full-width left-padding-50 row-5">
                                <p className="profile-label">Results</p>
                                <textarea type="text" className="text-field" placeholder="Add results..." name={"academicLearningObjectives|results|" + index} value={value.results} onChange={this.formChangeHandler}></textarea>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>

                        <div className="row-10">
                          <div className="edit-profile-row">
                            <div className="float-left">
                              <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <label className="profile-label">Career Development Objectives</label>
                            </div>
                            <div className="fixed-column-50 left-padding top-margin-20">
                              <button onClick={() => this.addItem('careerDevelopmentObjectives')} className="background-button" type="button">
                                <div className="circle-corners cta-border padding-5">
                                  <img src={addIconBlue} alt="Compass add icon" className="image-auto-12"/>
                                </div>
                              </button>
                            </div>
                            <div className="clear"/>
                          </div>

                          {this.state.careerDevelopmentObjectives && this.state.careerDevelopmentObjectives.map((value, index) =>
                            <div key={value._id} className="row-5">
                              <div className="fixed-column-50 top-padding">
                                <p className="heading-text-2">#{index + 1}</p>
                              </div>
                              <div className="calc-column-offset-50 row-5">
                                <p className="profile-label">Objective</p>
                                <input type="text" className="text-field" placeholder="Add objective..." name={"careerDevelopmentObjectives|objective|" + index} value={value.objective} onChange={this.formChangeHandler}></input>
                              </div>

                              <div className="full-width left-padding-50 row-5">
                                <p className="profile-label">Results</p>
                                <textarea type="text" className="text-field" placeholder="Add results..." name={"careerDevelopmentObjectives|results|" + index} value={value.results} onChange={this.formChangeHandler}></textarea>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(this.state.clientErrorMessage && this.state.clientErrorMessage !== '') && <p className="row-5 error-color description-text-2">{this.state.clientErrorMessage}</p>}
                    {(this.state.serverErrorMessage && this.state.serverErrorMessage !== '') && <p className="row-5 error-color description-text-2">{this.state.serverErrorMessage}</p>}
                    {(this.state.serverSuccessMessage && this.state.serverSuccessMessage !== '') && <p className="row-5 cta-color description-text-2">{this.state.serverSuccessMessage}</p>}

                    {(this.state.confirmNotifyApplicants) ? (
                      <div className="row-20">
                        <p className="error-color row-10 description-text-1">Would you like to notify all non-placed applicants?</p>

                        <div className="float-left right-padding-20">
                          <button className="btn btn-quaternary" onClick={() => this.savePlacement(true)}>Yes, And Fill</button>
                        </div>
                        <div className="float-left right-padding-20">
                          <button className="btn btn-secondary error-border error-color" onClick={() => this.savePlacement(false)}>No, But Fill</button>
                        </div>
                        {(this.state.inModal) && (
                          <div className="float-left right-padding-20">
                            <button className="btn btn-secondary" onClick={() => this.setState({ confirmNotifyApplicants: false })}>Cancel</button>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="row-20">
                        {(this.state.confirmDelete === true) ? (
                          <div>
                            <div className="row-10">
                              <p className="description-text-2 bold-text">Are you sure you want to permanently delete this placement?</p>
                            </div>

                            <div className="float-left right-padding-20">
                              <button className={(this.state.isSaving) ? "btn btn-squarish medium-background medium-border-color left-margin" : "btn btn-squarish error-background-color error-border left-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.deletePlacement()}>Yes, Permanently Delete</button>
                            </div>
                            <div className="float-left right-padding-20">
                              <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                            </div>

                            <div className="clear" />
                          </div>
                        ) : (
                          <div>
                            <div className="float-left right-padding-20">
                              <button className="btn btn-primary" onClick={() => this.setState({ confirmNotifyApplicants: true })}>{(this.state.fillRole) ? "Confirm & Fill" : "Save Changes"}</button>
                            </div>
                            {(this.state.inModal) && (
                              <div className="float-left right-padding-20">
                                <button className="btn btn-secondary" onClick={() => this.props.closeModal()}>Cancel</button>
                              </div>
                            )}
                            {(this.state._id) && (
                              <button className={(this.state.isSaving) ? "btn btn-quaternary medium-background medium-border-color left-margin" : "btn btn-quaternary left-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.setState({ confirmDelete: true })}>Delete Placement</button>
                            )}

                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

            </div>
          </div>

      )
    }
}


export default withRouter(EditPlacement);
