import React, {Component} from 'react';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

class Trends extends Component {
    constructor(props) {
        super(props)

        this.state = {
          trends: [],
          hideSubscribe: true,
          showModule: true
        }

        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.subscribe = this.subscribe.bind(this);

    }

    componentDidMount() {

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within shared trends')

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg })

      let trendsParams = {}
      if (this.state.showModule) {
        trendsParams = { autoGenerated: true }
      }

      Axios.get('/api/trends', { params: trendsParams })
      .then((response) => {

          if (response.data.success) {
            console.log('Trends query worked', response.data);

            if (this.state.showModule) {
              let summary = null
              let trendsUpdatedAt = null
              let trends = []
              for (let i = 1; i <= response.data.trends.length; i++) {
                if (response.data.trends[i - 1].summary) {
                  summary = response.data.trends[i - 1].summary
                  trendsUpdatedAt = response.data.trends[i - 1].createdAt
                } else {
                  trends.push(response.data.trends[i - 1])
                }
              }

              this.setState({ summary, trendsUpdatedAt, trends })

            } else {
              const trends = response.data.trends
              let trendsUpdatedAt = null
              let historicalData = []

              // calculate 2-Year Growth
              if (trends[0].data) {
                trendsUpdatedAt = trends[0].updatedAt
                for (let i = 1; i <= trends[0].data.length; i++) {
                  if (i > 1) {
                    const currentValue = trends[0].data[i - 1].currentDate
                    const twoYearsAgoValue = trends[0].data[i - 1].twoYearsAgo
                    if (Number(currentValue) && Number(twoYearsAgoValue)) {
                      const twoYearGrowth = (((Number(currentValue) / Number(twoYearsAgoValue)) - 1) * 100).toFixed()
                      console.log('show twoYearGrowth: ', twoYearGrowth)
                      historicalData.push({
                        name: trends[0].data[i - 1].name,
                        code: trends[0].data[i - 1].code,
                        twoYearGrowth,
                        currentDate: trends[0].data[i - 1].currentDate,
                        lastYear: trends[0].data[i - 1].lastYear,
                        twoYearsAgo: trends[0].data[i - 1].twoYearsAgo,
                        annualizedWage: trends[0].data[i - 1].twoYearsAgo,
                        hourlyWage: trends[0].data[i - 1].twoYearsAgo,
                      })
                    }
                  }
                }
              }

              historicalData.sort(function(a,b) {
                return b.twoYearGrowth - a.twoYearGrowth;
              })
              historicalData =  historicalData.slice(0,10)

              for (let i = 1; i <= historicalData.length; i++) {
                historicalData[i - 1]['rank'] = i
              }

              this.setState({ historicalData, trends, trendsUpdatedAt })
            }

          } else {
            console.log('no trends found', response.data.message)
          }

      }).catch((error) => {
          console.log('Trends query did not work', error);
      });

      const typesArray = [['General Skill','Hard Skill', 'Soft Skill'],['Knowledge'],['Work Style']]

      Axios.put('/api/competency/top', { typesArray })
      .then((response) => {

          if (response.data.success) {
            console.log('Top occurrence competencies query worked', response.data);

            const competencies = response.data.competencies
            if (competencies) {
              this.setState({ competencies })
            }

          } else {
            console.log('no trends found', response.data.message)
          }

      }).catch((error) => {
          console.log('Trends query did not work', error);
      });

      if (window.innerWidth > 768) {

      } else {

          this.setState({ onMobile: true })

      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      }
    }

    subscribe() {
      console.log('subscribe called')

      this.setState({ serverErrorMessage: null, serverSuccessMessage: null, isSaving: true })

      if (!this.state.email || this.state.email === '') {
        this.setState({ serverErrorMessage: 'please add your email'})
      } else if (!this.state.email.includes('@')) {
        this.setState({ serverErrorMessage: 'please add a valid email'})
      } else {

        const emailId = this.state.email

        const trendsSubscriber = true

        const createdAt = new Date()
        const updatedAt = new Date()

        Axios.post('/api/notifications/subscribe', { emailId, trendsSubscriber, createdAt, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Subscribe worked', response.data);
            this.setState({ serverSuccessMessage: response.data.message, isSaving: false, email: '' })

          } else {
            console.error('there was an error subscribing to monthly trends');
            this.setState({ serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Trends subscribe save did not work', error);
        });
      }
    }

    render() {

      return (
          <div className="bottom-margin-20">
            {(this.props.pageSource === 'landingPage') && (
              <div className="bottom-margin-15">
                <div className="card center-horizontally full-width top-margin-2-percent">
                  <div className="relative-column-70">
                    <h3 className="normal-weight">Labor Market Trends</h3>
                  </div>
                  {(!this.state.showModule) && (
                    <div className="relative-column-30 right-text">
                      <p className="description-text-2">Provided by:</p>
                      <a href="https://www.bls.gov" target="_blank" rel="noopener noreferrer" className="description-text-1">U.S. Bureau of Labor Statistics</a>
                    </div>
                  )}

                  <div className="clear" />
                </div>

                {(!this.state.hideSubscribe) && (
                  <div>
                    <div className="top-margin-30">
                      <div className="mini-spacer" />
                      <h3 className="normal-weight">Subscribe to Monthly Trends Report</h3>
                    </div>

                    <div className="full-width">
                      <div className="calc-column-offset-100 right-padding">
                        <input type="text" className="text-field" placeholder="Email address..." name="email" value={this.state.email} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="fixed-column-100">
                        <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.subscribe()}>Subscribe</button>
                      </div>
                      <div className="clear" />
                    </div>

                    {(this.state.serverErrorMessage) && <p className="error-color description-text-2 half-bold-text row-5">{this.state.serverErrorMessage}</p>}
                    {(this.state.serverSuccessMessage) && <p className="cta-color description-text-2 half-bold-text row-5">{this.state.serverSuccessMessage}</p>}
                  </div>
                )}
              </div>
            )}

            {(this.state.showModule) ? (
              <div>
                {(this.state.trends && this.state.trends.length > 0) && (
                  <div>
                    {(this.state.summary) && (
                      <div>
                        <div className="top-margin-50 bottom-margin-20">
                          <div className="calc-column-offset-140">
                            <p className="heading-text-4">Summary of Trends</p>
                          </div>
                          {(this.state.trendsUpdatedAt) && (
                            <div className="fixed-column-140 right-text">
                              <p className="description-text-3">Last updated: {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")}</p>
                            </div>
                          )}
                          <div className="clear" />
                        </div>

                        <div className="card">
                          <p className="description-text-1 high-line-height">{this.state.summary}</p>
                        </div>
                      </div>
                    )}

                    {this.state.trends.map((oValue, index) =>
                      <div key={"trend|" + index}>
                        <div>
                          <div className="top-margin-50 bottom-margin-20">
                            <p className="heading-text-4">{index + 1}. {oValue.category}</p>
                          </div>

                          {(oValue.data && oValue.data.length > 0) && (
                            <div className="card-clear-padding">
                              <div className="bold-text selected-background-light horizontal-padding row-20 standard-border">
                                <div className="fixed-column-40">
                                  <p className="relative-column-5 description-text-1">#</p>
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="relative-column-45 description-text-1">Title</p>
                                  <p className="relative-column-10 description-text-1">Metric</p>
                                  <p className="relative-column-45 description-text-1">Metric Description</p>
                                  <div className="clear" />
                                </div>
                                <div className="clear" />
                              </div>

                              <hr />

                              <div>
                                {oValue.data.map((value, optionIndex) =>
                                  <div key={"data|" + optionIndex}>
                                    <div className="horizontal-padding row-20 standard-border">
                                      <div>
                                        <div className="fixed-column-40">
                                          <p className="relative-column-5 description-text-1">{value.rank}.</p>
                                        </div>
                                        <div className="calc-column-offset-40">
                                          <p className="relative-column-45 description-text-1">{value.title}</p>
                                          <p className="relative-column-10 description-text-1">{(value.formattedValue) ? value.formattedValue : value.value}</p>
                                          <p className="relative-column-45 description-text-1">{value.valueDescription}</p>
                                          <div className="clear" />

                                          <div className="top-margin-12">
                                            <p className="full-width description-text-1 description-text-color">{value.reason}</p>
                                          </div>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div className="clear" />
                                    </div>
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                  </div>
                )}
              </div>
            ) : (
              <div>
                {(this.state.historicalData && this.state.historicalData.length > 0) && (
                  <div>
                    <div>
                      <div className="calc-column-offset-80">
                        <h3 className="normal-weight">Fastest Growing Industries {(this.state.trendsUpdatedAt) && <label className="description-text-4">(Last updated {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")})</label>}</h3>
                      </div>
                      <div className="fixed-column-80 top-padding">
                        <a href="https://www.bls.gov" target="_blank" rel="noopener noreferrer" className="description-text-3">Source: BLS</a>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="card">

                      <div className="half-bold-text bottom-padding">
                        <p className="relative-column-6 description-text-2">Rank</p>
                        <p className="relative-column-50 description-text-2">Name</p>
                        <p className="relative-column-11 description-text-2">2-Yr Gwth</p>
                        <p className="relative-column-11 description-text-2">Last Mth</p>
                        <p className="relative-column-11 description-text-2">Last Yr</p>
                        <p className="relative-column-11 description-text-2">2 Yrs Ago</p>

                        <div className="clear" />
                      </div>

                      <div>
                        {this.state.historicalData.map((value, optionIndex) =>
                          <div key={optionIndex}>
                            <div>
                              <p className="relative-column-6 description-text-2">{value.rank}</p>
                              <p className="relative-column-50 description-text-2">{value.name}</p>
                              <p className="relative-column-11 description-text-2">{value.twoYearGrowth}%</p>
                              <p className="relative-column-11 description-text-2">{value.currentDate}</p>
                              <p className="relative-column-11 description-text-2">{value.lastYear}</p>
                              <p className="relative-column-11 description-text-2">{value.twoYearsAgo}</p>

                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>

                  </div>
                )}

                {(this.state.trends && this.state.trends.length > 1) && (
                  <div>
                    {this.state.trends.map((oValue, index) =>
                      <div key={"trend" + index}>
                        {(index > 1 && oValue.occupations && oValue.occupations.length > 0) && (
                          <div>
                            {(oValue.category !== 'Most Openings') && (
                              <div>
                                <div className="top-margin-15">
                                  <div className="calc-column-offset-80">
                                    <h3 className="normal-weight">Projected {oValue.category} {(this.state.trendsUpdatedAt) && <label className="description-text-4">(Last updated {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")})</label>}</h3>
                                  </div>
                                  <div className="fixed-column-80 top-padding">
                                    <a href="https://www.bls.gov" target="_blank" rel="noopener noreferrer" className="description-text-3">Source: BLS</a>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {(oValue.category === 'Highest Paying Careers') ? (
                                  <div>
                                    {(oValue.occupations && oValue.occupations.length > 0) && (
                                      <div className="card">
                                        <div className="half-bold-text bottom-padding">
                                          <p className="relative-column-5 description-text-2">Rank</p>
                                          <p className="relative-column-50 description-text-2">Title</p>
                                          <p className="relative-column-15 description-text-2">Year</p>
                                          <p className="relative-column-15 description-text-2">Yr Wage</p>
                                          <p className="relative-column-15 description-text-2">Hr Wage</p>

                                          <div className="clear" />
                                        </div>

                                        <div>
                                          {oValue.occupations.map((value, optionIndex) =>
                                            <div key={optionIndex}>
                                              <div>
                                                <p className="relative-column-5 description-text-2">{value.rank}</p>
                                                <p className="relative-column-50 description-text-2">{value.title}</p>
                                                <p className="relative-column-15 description-text-2">{value.projYear}</p>
                                                <p className="relative-column-15 description-text-2">{value.annualizedWage}</p>
                                                <p className="relative-column-15 description-text-2">{value.hourlyWage}</p>

                                                <div className="clear" />
                                              </div>
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(oValue.occupations && oValue.occupations.length > 0) && (
                                      <div className="card">
                                        <div className="half-bold-text bottom-padding">
                                          <p className="relative-column-6 description-text-2">Rank</p>
                                          <p className="relative-column-50 description-text-2">Title</p>
                                          <p className="relative-column-11 description-text-2">% Change</p>
                                          <p className="relative-column-11 description-text-2">Year</p>
                                          <p className="relative-column-11 description-text-2">Emp Last Yr</p>
                                          <p className="relative-column-11 description-text-2">Emp In 10 Yrs</p>

                                          <div className="clear" />
                                        </div>

                                        <div>
                                          {oValue.occupations.map((value, optionIndex) =>
                                            <div key={optionIndex}>
                                              <div>
                                                <p className="relative-column-6 description-text-2">{value.rank}</p>
                                                <p className="relative-column-50 description-text-2">{value.title}</p>
                                                <p className="relative-column-11 description-text-2">{value.percentChange}</p>
                                                <p className="relative-column-11 description-text-2">{value.projYear}</p>
                                                <p className="relative-column-11 description-text-2">{value.estimatedEmployment}</p>
                                                <p className="relative-column-11 description-text-2">{value.projectedEmployment}</p>

                                                <div className="clear" />
                                              </div>
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                )}

                {(this.state.competencies) && (
                  <div>
                    {this.state.competencies.map((value, index) =>
                      <div key={index}>
                        <div className="top-margin-15">
                          <div className="calc-column-offset-80">
                            {(value.type && value.type.includes('Skill')) && (
                              <h3 className="normal-weight">{(value.category === 'occurrences') ? "Most Common In-Demand Skills" : "Skills That Pay The Most"} {(this.state.trendsUpdatedAt) && <label className="description-text-4">(Last updated {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")})</label>}</h3>
                            )}
                            {(value.type === 'Knowledge') && (
                              <h3 className="normal-weight">{(value.category === 'occurrences') ? "Most Common In-Demand Knowledge" : "Knowledge That Pays   The Most"} {(this.state.trendsUpdatedAt) && <label className="description-text-4">(Last updated {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")})</label>}</h3>
                            )}
                            {(value.type === 'Work Style') && (
                              <h3 className="normal-weight">{(value.category === 'occurrences') ? "Most Common In-Demand Work Styles" : "Work Styles That Pay The Most"} {(this.state.trendsUpdatedAt) && <label className="description-text-4">(Last updated {convertDateToString(new Date(this.state.trendsUpdatedAt),"date-2")})</label>}</h3>
                            )}

                          </div>
                          <div className="fixed-column-80 top-padding">
                            <a href="https://www.bls.gov" target="_blank" rel="noopener noreferrer" className="description-text-3">Source: BLS</a>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="card">
                          <div className="half-bold-text bottom-padding">
                            <p className="relative-column-40 description-text-2">Name</p>
                            <p className="relative-column-20 description-text-2">Type</p>
                            <p className="relative-column-20 description-text-2">Occurrences</p>
                            <p className="relative-column-20 description-text-2">Avg. Worth</p>
                            <div className="clear" />
                          </div>

                          {value.competencies.map((oValue, oIndex) =>
                            <div key={oIndex}>
                              <div>
                                <div>
                                  <div>
                                    <div>
                                      <p className="relative-column-40 description-text-2">{oValue.name}</p>
                                      <p className="relative-column-20 description-text-2">{oValue.type}</p>
                                      <p className="relative-column-20 description-text-2">{oValue.occurrences}</p>
                                      <p className="relative-column-20 description-text-2">${Number(oValue.worth.toFixed()).toLocaleString()}</p>

                                      <div className="clear" />
                                    </div>
                                  </div>

                                  <div className="clear" />
                                </div>
                              </div>
                            </div>
                          )}

                        </div>

                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

      )
    }
}


export default withRouter(Trends);
