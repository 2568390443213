import React, {Component} from 'react';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";

class Integrations extends Component {
    constructor(props) {
        super(props)

        this.state = {
          availableIntegrations: []
        }

        this.retrieveData = this.retrieveData.bind(this);
        this.initiateOauth = this.initiateOauth.bind(this);
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubLogDetails ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        console.log('t1')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subLogDetails', this.props)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      if (this.props.emailId) {
        emailId = this.props.emailId
      }

      const availableIntegrations = [
        { logo: '', name: 'Greenhouse' },
        { logo: '', name: 'Lever' },
      ]

      this.setState({ org, emailId, username, orgFocus, accountCode, availableIntegrations })

      Axios.get('/api/account', { params: { accountCode } })
      .then((response) => {
        console.log('Account info query attempted within sub settings', response.data);

        if (response.data.success) {
          console.log('account info query worked in sub settings')

          const greenhouseAccessToken = response.data.accountInfo.greenhouseAccessToken
          const leverAccessToken = response.data.accountInfo.leverAccessToken

          // console.log('show leverAccessToken: ', leverAccessToken)
          this.setState({ greenhouseAccessToken, leverAccessToken });

        }

      }).catch((error) => {
        console.log('Account info query did not work for some reason', error);
      });
    }

    initiateOauth(index) {
      console.log('initiateOauth called', index)

      const url = '/api/oauth/' + this.state.availableIntegrations[index].name + '/signin'
      const accountCode = this.state.accountCode
      const emailId = this.state.emailId

      // console.log('show accountCode before oAuth: ', accountCode)
      Axios.get(url, { params: { accountCode, emailId }})
      .then((response) => {

        if (response.data.success) {
          console.log('Oauth query worked', response.data);
          if (response.data.finalAuthURL) {
            console.log('u1')
            // this.props.navigate(response.data.finalAuthURL)
            window.location.href=response.data.finalAuthURL
            console.log('u2')
          }
        } else {
          console.log('there was an error found', response.data.message)

        }

      }).catch((error) => {
          console.log('Oauth query did not work', error);
      });
    }

    render() {
      return (
          <div>
            <p className="heading-text-2">Integrations</p>
            <div className="spacer" /><div className="spacer" /><div className="spacer" />
            {(this.state.accountCode && this.state.emailId) && (
              <div>
                {console.log('loop integrations', this.state.availableIntegrations)}
                {this.state.availableIntegrations.map((value, index) =>
                  <div>
                    <div>
                      <div className="float-left">
                        <button onClick={() => this.initiateOauth(index)} className="background-button">{value.name}</button>
                      </div>
                      {(this.state.greenhouseAccessToken && value.name === 'Greenhouse') && (
                        <div className="float-left left-padding">
                          <img src={checkmarkIcon} alt="GC" className="image-auto-15" />
                        </div>
                      )}
                      {(this.state.leverAccessToken && value.name === 'Lever') && (
                        <div className="float-left left-padding">
                          <img src={checkmarkIcon} alt="GC" className="image-auto-15" />
                        </div>
                      )}

                      <div className="clear" />
                    </div>


                    <div className="spacer" /><div className="spacer" />
                    <hr />
                    <div className="spacer" /><div className="spacer" />
                  </div>
                )}
              </div>
            )}

          </div>

      )
    }
}


export default withRouter(Integrations);
