import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import SubArrangeLessons from '../Common/ArrangeLessons';
import SubExchange from '../Subcomponents/Exchange';
import withRouter from '../Functions/WithRouter';

const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

class Lessons extends Component {
    constructor(props) {
        super(props)

        this.state = {
          lessons: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.passLesson = this.passLesson.bind(this)
        this.passCurriculumItem = this.passCurriculumItem.bind(this)
        this.toggleVisiblity = this.toggleVisiblity.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const orgFocus = localStorage.getItem('orgFocus');

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus })

      Axios.get('/api/lessons', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Lessons query attempted', response.data);

        if (response.data.success) {
          console.log('lessons query worked')

          const lessons = response.data.lessons
          this.setState({ lessons })

        } else {
          console.log('lessons query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Lessons query did not work for some reason', error);
      });
    }

    passCurriculumItem(ci, addCI, index, newlyCreated, _id) {
      console.log('passCurriculumItem called', ci, addCI, index, newlyCreated, _id)

      // let curriculumItems = this.state.curriculumItems
      // if (_id ) {
      //   curriculumItems[index] = ci
      // } else if (addCI) {
      //   console.log('ci1')
      //   if (curriculumItems) {
      //     console.log('ci2')
      //     if (curriculumItems.findIndex(l => l._id === ci._id) > -1) {
      //       console.log('ci3')
      //       const deleteIndex = curriculumItems.findIndex(l => l._id === ci._id)
      //       console.log('about to delete ci', deleteIndex)
      //       curriculumItems.splice(deleteIndex,1)
      //       addCI = false
      //     } else {
      //       console.log('ci4')
      //       if (newlyCreated) {
      //         console.log('ci5')
      //         curriculumItems.push(ci)
      //       }
      //
      //       // console.log('compare two: ', curriculumItems.length)
      //     }
      //   } else {
      //     curriculumItems = [ci]
      //   }
      // } else {
      //   curriculumItems.splice(index,1)
      // }
      //
      // console.log('compare three: ', curriculumItems.length)
      // this.setState({ curriculumItems, successMessage: 'Changes saved!' })
    }

    passLesson(lesson, addLesson, index, newlyCreated,preexistingId, synced) {
      console.log('passLesson called: ', lesson, addLesson, index, newlyCreated,preexistingId)

      let lessons = this.state.lessons
      if (preexistingId) {
        lessons[index] = lesson
      } else if (addLesson) {
        if (lessons) {
          // console.log('compare one: ', this.state.lessons)
          if (lessons.findIndex(l => l.curriculumItem === lesson.curriculumItemId) > -1) {

            const deleteIndex = lessons.findIndex(l => l.curriculumItem === lesson.curriculumItemId)
            console.log('removing in addLesson', deleteIndex)
            lessons.splice(deleteIndex,1)
            addLesson = false
          } else {

            if (newlyCreated) {
              lessons.push(lesson)
            }

            console.log('compare two: ', lessons.length)
          }
        } else {
          lessons = [lesson]
        }
      } else {
        console.log('about to delete', index)
        if (!synced) {
          lessons.splice(index,1)
        }
      }
      console.log('compare three: ', lessons.length)

      // componentDidUpdate not working in SubArrangeLessons
      let updateArrangeLessons = this.state.updateArrangeLessons
      if (updateArrangeLessons) {
        updateArrangeLessons = false
      } else {
        updateArrangeLessons = true
      }
      this.setState({ lessons, successMessage: 'Changes saved!', updateArrangeLessons   })
    }

    closeModal() {
      console.log('closeModal called')

      const _id = null
      const exchangeType = ''
      const itemName = ''
      const description = ''
      const gradeLevels = []
      const minutes = ''
      const careerPathTags = []
      const tags = null
      const url = ''
      const studentURL = ''

      this.setState({ modalIsOpen: false, showAddItem: false, showComments: false, showFilters: false, parentPost: null,
        _id, exchangeType, itemName, description, gradeLevels, minutes, careerPathTags, tags, url, studentURL,
        selectedCurriculumItem: null, selectedIndex: null
      })
    }

    toggleVisiblity(index,change) {
      console.log('toggleVisiblity called')

      let lessons = this.state.lessons
      const lessonObject = { _id: lessons[index]._id, isVisible: change }

      Axios.post('/api/lessons', lessonObject).then((response) => {
        console.log('attempting to save lesson')
        if (response.data.success) {
          console.log('saved lesson', this.props)

          lessons[index]['isVisible'] = change
          let updateArrangeLessons = this.state.updateArrangeLessons
          if (updateArrangeLessons) {
            updateArrangeLessons = false
          } else {
            updateArrangeLessons = true
          }
          console.log('lessons: ', lessons)
          this.setState({ lessons, updateArrangeLessons })

        } else {
          console.log('did not save successfully', response.data.message)
          this.setState({ errorMessage: 'error:' + response.data.message })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'Lesson save did not work' })
      });

    }

    render() {

      return (
          <div>
            <div className="card bottom-margin-30">
              <div className="calc-column-offset-25 right-padding">
                <p className="heading-text-2">{(this.state.lessons) && " " + this.state.lessons.length + " "}Learning Modules Added</p>
              </div>
              <div className="fixed-column-25">
                <button className="background-button clear-margin" onClick={() => this.setState({ modalIsOpen: true, showAddItem: true })}>
                  <img src={addIcon} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add Learning Module"/>
                </button>
              </div>
              <div className="clear" />
              <p className="description-text-2 row-5 standard-color">Import and create learning modules that you can assign to students/career-seekers.</p>
            </div>
            {(this.state.lessons && this.state.lessons.length > 0) ? (
              <div>
                <div className="card">
                  <SubArrangeLessons lessons={this.state.lessons} passLesson={this.passLesson} toggleVisiblity={this.toggleVisiblity} updateArrangeLessons={this.state.updateArrangeLessons}/>
                </div>

              </div>
            ) : (
              <div className="card">
                <p>No learning modules have been created yet. <button className="background-button clear-margin half-bold-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showAddItem: true })}>Click here</button> to add some.</p>
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             >
              {(this.state.showAddItem) && (
                <div key="addItem" className="full-width padding-20">
                  {/*<SubEditLesson history={this.props.navigate} passLesson={this.passLesson} passCurriculumItem={this.passCurriculumItem} closeModal={this.closeModal} selectedIndex={this.state.selectedIndex} selectedCurriculumItem={null} editLesson={true} />*/}
                  <SubExchange finishedQuery={this.finishedQuery} passedId={null} closeModal={this.closeModal} passLesson={this.passLesson} lessons={this.state.lessons} editLesson={true} orgCode={this.state.activeOrg} exchangeType="Independent Lesson" />
                </div>
              )}

            </Modal>
          </div>

      )
    }
}

export default withRouter(Lessons);
