import React, {Component} from 'react';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const studentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class SendMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',
            org: '',

            email: '',
            message: '',
            searchString: '',
            gradYear: 'Any',
            cohortYear: 'Any',
            memberType: 'All',
            careerTrack: 'All',

            members: [],
            filteredMembers: [],
            suggestedStudents: [],

            clientErrorMessage: '',
            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.renderSearchResults = this.renderSearchResults.bind(this)

        this.searchStudents = this.searchStudents.bind(this)
        this.studentClicked = this.studentClicked.bind(this)

        this.calculateFilteredMembers = this.calculateFilteredMembers.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.addTag = this.addTag.bind(this)
        this.removeTag = this.removeTag.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.org !== prevProps.org) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubSendMessage', this.props)

      const org = this.props.org
      const userId = this.props.userId

      // console.log('please???', userId)
      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (email) {

        this.setState({ emailId: email, username, org, cuFirstName, cuLastName, orgFocus })

        console.log('userId: ', userId, org)

        if (userId) {
          Axios.get('/api/users/profile/details/' + userId)
          .then((response) => {
            console.log('Profile info query attempted in sendMessage', response.data);

            if (response.data.success) {
              console.log('profile info query worked')

              const firstName = response.data.user.firstName
              const lastName = response.data.user.lastName
              const email = response.data.user.email

              const selectedMember = { firstName, lastName, email }
              this.setState({ selectedMember })

            } else {
              console.log('profile query did not work', response.data.message)

            }
          }).catch((error) => {
              console.log('Profile query did not work for some reason', error);
          });
        }

        Axios.get('/api/org/members', { params: { orgCode: org } })
        .then((response) => {

          if (response.data.success) {
            console.log('Member query worked', response.data);

            const members = response.data.members
            const filteredMembers = response.data.members

            console.log('we get members?', filteredMembers)

            this.setState({ members, filteredMembers })

          } else {
            console.log('no members found', response.data.message)

          }

        }).catch((error) => {
            console.log('Members query did not work', error);
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName

              this.setState({ orgName});

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });


      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'search') {
        console.log('search clicked')
        // this.renderSearchResults(event.target.value)
        this.searchStudents(event.target.value)
      } else if (event.target.name === 'gradYear') {
        console.log('gradYear clicked')
        this.setState({ gradYear: event.target.value })
        this.calculateFilteredMembers('gradYear', event.target.value)
      } else if (event.target.name === 'cohortYear') {
        console.log('cohortYear clicked')
        this.setState({ cohortYear: event.target.value })
        this.calculateFilteredMembers('cohortYear', event.target.value)
      } else if (event.target.name === 'memberType') {
        console.log('memberType clicked')
        this.setState({ memberType: event.target.value })
        this.calculateFilteredMembers('memberType', event.target.value)
      } else if (event.target.name === 'careerTrack') {
        console.log('careerTrack clicked')
        this.setState({ careerTrack: event.target.value })
        this.calculateFilteredMembers('careerTrack', event.target.value)
      } else if (event.target.name === 'message') {
        this.setState({ message: event.target.value })
      }
    }

    renderSearchResults(searchString) {
      console.log('renderSearchResults called')

      let possibleValues = [];
      let selectedMember = null

      for (let i = 1; i <= this.state.members.length; i++) {
        console.log(i, searchString, this.state.members[i - 1].firstName.toLowerCase());

        let memberFullName = this.state.members[i - 1].firstName + ' ' + this.state.members[i - 1].lastName

        if (memberFullName.toLowerCase().includes(searchString.toLowerCase())) {
            console.log('included', i)
            possibleValues.push(memberFullName)
            selectedMember = this.state.members[i - 1]
        }
      }

      if (possibleValues.length === 1) {
        console.log('pv')
        this.setState({ searchString: possibleValues[0], selectedMember })

      } else {
        console.log('no pv')
        this.setState({ searchString })
      }
    }

    searchStudents(searchString) {
      console.log('searchStudents ', searchString)

      const orgCode = this.state.org

      Axios.get('/api/students/search', { params: { searchString, orgCode } })
      .then((response) => {
        console.log('Students search query attempted', response.data);

          if (response.data.success) {
            console.log('students search query worked')

            const suggestedStudents = response.data.students
            this.setState({ suggestedStudents, searchString });

          } else {
            console.log('student search query did not work', response.data.message)
            const suggestedStudents = []
            this.setState({ suggestedStudents, searchString })
          }

      }).catch((error) => {
          console.log('Student search query did not work for some reason', error);
          this.setState({ searchString })
      });
    }

    studentClicked(student) {
      console.log('studentClicked called ', student)

      const searchString = student.firstName + ' ' + student.lastName
      const suggestedStudents = []
      const selectedObject = student

      this.setState({ searchString, suggestedStudents, selectedObject })

    }

    addTag() {
      console.log('addTag called', this.state.selectedObject)

      if (this.state.selectedObject) {
        const searchString = ''
        const selectedObject = null
        const selectedMember = {
          firstName: this.state.selectedObject.firstName,
          lastName: this.state.selectedObject.lastName,
          email: this.state.selectedObject.email,
        }
        this.setState({ searchString, selectedObject, selectedMember })
      }
    }

    removeTag() {
      console.log('removeTag called')

      let selectedMember = null
      this.setState({ selectedMember })

    }

    calculateFilteredMembers(filterType, filterData) {
      console.log('calculateFilteredMembers', filterType, filterData)
      let filteredMembers = []

      let memberType = ''
      let gradYear = ''
      let cohortYear = ''
      let careerTrack = ''

      if (filterType === 'memberType') {
        memberType = filterData
        gradYear = this.state.gradYear
        cohortYear = this.state.cohortYear
        careerTrack = this.state.careerTrack
      } else if (filterType === 'gradYear') {
        memberType = this.state.memberType
        gradYear = filterData
        cohortYear = this.state.cohortYear
        careerTrack = this.state.careerTrack
      } else if (filterType === 'cohortYear') {
        memberType = this.state.memberType
        gradYear = this.state.gradYear
        cohortYear = filterData
        careerTrack = this.state.careerTrack
      } else if (filterType === 'careerTrack') {
        memberType = this.state.memberType
        gradYear = this.state.gradYear
        cohortYear = this.state.cohortYear
        careerTrack = filterData
      }

      for (let i = 1; i <= this.state.members.length; i++) {
        console.log('show the details ', this.state.members[i - 1].memberType, memberType)

        let pushMemberType = false
        if ( memberType === 'All' ) {
          console.log('1')
          pushMemberType = true
        } else if ( this.state.members[i - 1].memberType === memberType ) {
          console.log('2')
          pushMemberType = true
        }

        let pushGradYear = false
        if ( gradYear === 'Any' ) {
          //console.log('3')
          pushGradYear = true
        } else if ( this.state.members[i - 1].gradYear === gradYear ) {
          //console.log('4')
          pushGradYear = true
        }

        let pushCohortYear = false
        if ( cohortYear === 'Any' ) {
          //console.log('3')
          pushCohortYear = true
        } else if ( this.state.members[i - 1].cohortYear === cohortYear ) {
          //console.log('4')
          pushCohortYear = true
        }

        let pushCareerTrack = false
        if ( careerTrack === 'All' ) {
          //console.log('5')
          pushCareerTrack = true
        } else if ( this.state.members[i - 1].careerTrack === careerTrack ) {
          //console.log('6')
          pushCareerTrack = true
        }

        if ( pushMemberType && pushGradYear && pushCohortYear && pushCareerTrack ) {
          filteredMembers.push(this.state.members[i - 1])
        }
      }

      this.setState({ filteredMembers })
    }

    sendMessage() {
      console.log('sendMessage clicked');

      this.setState({
        clientErrorMessage: ''
      })

      if (!this.state.selectedMember) {
        this.setState({ clientErrorMessage: 'please enter a recipient' })
      } else if (this.state.message === '') {
        this.setState({ clientErrorMessage: 'please enter a message' })
      } else {

        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId

        const recipientFirstNames = [this.state.selectedMember.firstName]
        const recipientLastNames = [this.state.selectedMember.lastName]
        const recipientEmails = [this.state.selectedMember.email]

        const recipientType = 'Advisee'
        const isRead = false
        const message = this.state.message
        const type = 'Message'
        const relationship = 'Organization'
        const isApproved = false
        const isDecided = false

        const orgCode = this.state.org
        const orgName = this.state.orgName

        Axios.post('/api/notifications/message', {
          senderFirstName, senderLastName, senderEmail, recipientFirstNames, recipientLastNames, recipientEmails,
          recipientType, isRead, message, type, relationship, isApproved, isDecided,
          orgCode, orgName,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message send worked', response.data);

            let searchString: ''
            let selectedMember = null
            let filteredMembers = this.state.members

            this.setState({
              searchString, selectedMember, filteredMembers,

              message: '',

              serverPostSuccess: true,
              serverSuccessMessage: 'Message sent successfully!'
            })
          } else {
            console.error('there was an error sending the message');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
        });
      }
    }

    render() {

        return (
            <div>
                <div className="standard-container-2">
                    <div>
                      <h3 className="normal-weight">Send Message</h3>
                    </div>

                    <div>
                      <div className="row-10">
                        <div className="filter-field-search-2 calc-column-offset-80">
                            <div className="search-icon-container">
                              <img src={searchIcon} alt="Compass search icon"/>
                            </div>
                            <div className="filter-search-container-2 calc-column-offset-50">
                              <input type="text" className="text-field clear-border" placeholder="Search a member..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>

                              {(this.state.suggestedStudents.length > 0) && (
                                <div>
                                  <div className="spacer" />
                                  {this.state.suggestedStudents.map(value =>
                                    <div key={value._id} className="row-5">
                                      <button className="background-button" onClick={() => this.studentClicked(value)}>
                                        <div>
                                          <div className="float-left right-padding">
                                            <img src={studentsIconBlue} alt="GC" className="image-auto-22" />
                                          </div>
                                          <div className="float-left">
                                            <p className="cta-color">{value.firstName} {value.lastName}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>)}
                                </div>
                              )}

                            </div>
                        </div>
                        <div className="fixed-column-70 top-padding">
                          <button className="btn btn-squarish" onClick={() => this.addTag()}>Add</button>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        {(this.state.selectedMember) && (
                          <div>
                            <div className="close-button-container-1" >
                              <button className="background-button" onClick={() => this.removeTag()}>
                                <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                              </button>
                            </div>
                            <div className="float-left right-padding-5">
                              <div className="half-spacer" />
                              <div className="tag-container-inactive">
                                <p className="description-text-2">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName}</p>
                              </div>
                              <div className="half-spacer" />
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                      {/*
                      <input type="text" className="text-field" placeholder="Advisee email..." name="email" value={this.state.email} onChange={this.formChangeHandler}></input>*/}
                    </div>

                    <div className="edit-profile-row">
                      <textarea type="text" className="text-field" placeholder="Write your message..." name="message" value={this.state.message} onChange={this.formChangeHandler}></textarea>
                    </div>

                    { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                    { (this.state.serverPostSuccess) ? (
                      <p className="success-message">{this.state.serverSuccessMessage}</p>
                    ) : (
                      <p className="error-message">{this.state.serverErrorMessage}</p>
                    )}
                    <div className="advisor-cta-container">
                      <button className="btn btn-primary" onClick={() => this.sendMessage()}>Send Message</button>
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(SendMessage);
