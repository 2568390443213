import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import withRouter from './Functions/WithRouter';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const answerBubbleIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/answer-bubble.png';

class EmpEditAssessment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            _id: '',
            title: '',
            questions: [],

            typeOptions: ['Short Answer','Multiple Choice','Checkbox','Ranking','Email','Birthdate'],

            formHasChanged: false,

            clientErrorMessage: '',
            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: ''

        }

        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.saveAssessment = this.saveAssessment.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      const { selectedTemplate } = this.props.location.state;

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        console.log('show me template', selectedTemplate)

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, emp,
            activeOrg, orgLogo,

            _id: selectedTemplate._id,
            title: selectedTemplate.title,
            questions: selectedTemplate.questions
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked')

      if (event.target.name === 'title') {
        this.setState({ title: event.target.value, formHasChanged: true })
      } else {
        for (let i = 1; i <= this.state.questions.length; i++) {

          let eventName = 'question' + i.toString()
          let typeName = 'type' + i.toString()
          let answerChoiceName = 'answerChoice' + i.toString()

          console.log(i, 'updating questions', event.target.name, ' ', answerChoiceName);
          if ( event.target.name === eventName ) {
            let questions = this.state.questions
            questions[i - 1].name = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === typeName ) {
            let questions = this.state.questions
            questions[i - 1].questionType = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name.startsWith(answerChoiceName) ) {
            console.log('got in answer choices')

            let answerChoices = this.state.questions[i - 1].answerChoices
            console.log('gimme answerChoices', answerChoices)

            let stringArray = event.target.name.split("|")

            //let stringEnd = event.target.name.length

            //let choiceIndex = Number(event.target.name.substring(13,stringEnd)) - 1
            let choiceIndex = Number(stringArray[1]) - 1

            console.log('gimme choiceIndex', choiceIndex)
            answerChoices[choiceIndex] = event.target.value
            console.log('gimme answerChoices one mo gain', answerChoices)

            let questions = this.state.questions
            questions[i - 1].answerChoices = answerChoices
            this.setState({ questions, formHasChanged: true })
          }
        }
      }
    }

    saveAssessment() {
      console.log('saveAssessment clicked');

      this.setState({
        clientErrorMessage: ''
      })

      if (this.state.formHasChanged === false) {
        this.setState({ clientErrorMessage: 'no changes detected. please update and then save.' })
      } else if (this.state.title === '') {
        this.setState({ clientErrorMessage: 'please add a title' })
      } else if (this.state.questions.length === 0) {
        this.setState({ clientErrorMessage: 'please add a question to your assessment' })
      } else if (this.state.questions[0].name === '') {
        this.setState({ clientErrorMessage: 'please add at least one question to your assessment' })
      } else {
        console.log('about to save')
        const _id = this.state._id
        const title = this.state.title
        const questions = this.state.questions

        const accountCode = this.state.emp

        const createdAt = new Date()
        const updatedAt = new Date()

        Axios.post('/api/customassessments', {
          _id, title, questions, accountCode, createdAt, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Assessment save worked', response.data);
            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Assessment template updated successfully!'
            })

          } else {
            console.error('there was an error saving the assessment template');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Assessment save did not work', error);
        });
      }
    }

    renderQuestions() {
      console.log('renderQuestions called')

      let rows = [];

      for (let i = 1; i <= this.state.questions.length; i++) {
        console.log(i);

        let eventName = "question" + i.toString()
        let typeName = "type" + i.toString()
        rows.push(
          <div key={i}>
            <div className="highlighted-container">
              <div className="name-container">
                <input type="text" className="text-field" placeholder="Create a question..." name={eventName} value={this.state.questions[i - 1].name} onChange={this.formChangeHandler}></input>
              </div>
              <div className="name-container">
                <select name={typeName} value={this.state.questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.typeOptions.map(value =>
                    <option key={value.key} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="clear"/>
              {(this.state.questions[i - 1].questionType === 'Multiple Choice'  || this.state.questions[i - 1].questionType === 'Checkbox' || this.state.questions[i - 1].questionType === 'Ranking') ? (
                <div>
                  {this.renderAnswerChoices(i, this.state.questions[i - 1].answerChoices)}
                  <div>
                    <hr className="add-task-background-line" />
                    <a className="background-button add-task-link-container" onClick={() => this.addAnswerChoice(i - 1)}>
                      <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="fake-text-field">Candidates will write a short answer here...</p>
                </div>
              )}
            </div>
          </div>
        )
      }

      return rows;

    }

    renderAnswerChoices(iCount, answerChoices) {

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()

        rows.push(
          <div key={key}>
            <div className="choice-container-1">
              <img src={answerBubbleIcon} alt="Compass dashboard icon" className="answer-bubble"/>
            </div>
            <div className="choice-container-2">
              <input type="text" className="adjacent-text-field" placeholder="Create an answer choice..." name={eventName} value={answerChoices[i - 1]} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows
    }

    addQuestion() {
      console.log('addQuestion called')

      let questions = this.state.questions
      questions.push({ name: '', questionType:'Short Answer', answerChoices: []})
      this.setState({ questions })
    }

    addAnswerChoice(index) {
      console.log('addAnswerChoice called')

      let answerChoices = this.state.questions[index].answerChoices
      answerChoices.push('')

      let questions = this.state.questions
      questions[index] = { name: this.state.questions[index].name, questionType:this.state.questions[index].questionType, answerChoices: answerChoices }

      this.setState({ questions })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
          <div>
              <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
              <div className="standard-container-2">
                  <div>
                    <h3>Edit Assessment</h3>
                    <p className="profile-descriptor">Create and save a template that you can add to your job postings.</p>
                    <p className="profile-descriptor">Note: candidates have standard profile information and assessments they can import when they apply to your opening. Make sure this custom assessment isn't asking duplicate questions <a href="https://docs.google.com/document/d/1b4oBkT6EwrvpdNL8WKBxQub7dXWtPsem5oSyQ3fE-Hs/edit?usp=sharing" className="secret-link">here.</a></p>
                  </div>

                  <div className="edit-profile-row">
                    <label className="subtitle">Assessment Title</label>
                    <div className="clear"/>
                    <br />
                    <input type="text" className="text-field" placeholder="Title your assessment for your reference..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                  </div>

                  <p className="subtitle">Questions</p>
                  <br/>

                  {this.renderQuestions()}
                  <div>
                    <hr className="add-task-background-line" />
                    <a className="background-button add-task-link-container" onClick={() => this.addQuestion()}>
                      <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                    </a>
                  </div>

                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}
                  <button className="btn btn-primary" onClick={() => this.saveAssessment()}>Update Assessment</button>

              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(EmpEditAssessment)
