import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import TopNavigation from './TopNavigation';

import SubSubscribe from '../components/Subcomponents/Subscribe';
import SubCareerSeekerBenefits from './Common/CareerSeekerBenefits';

class StudentsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          enableSubscriptions: false,
          showHighSchool: false,

          showModule: true,
          articles: []
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        console.log('change in checkbox handled')
    }

    componentDidMount() {
      //see if user is logged in
      console.log('career seeker page just mounted')
      window.scrollTo(0, 0)

    }

    render() {

      return (
          <div>
              <TopNavigation currentPage={"career-seekersPage"}/>

              <header className="student-header">
                  <div className="hero-text-box">
                    <h1 className="light-text">Career-Seekers</h1>
                    <p>Map your career pathway and be matched with work-based learning opportunities</p>
                    <Link className="btn btn-full" to={'/join'} state={{ roleName: "Student / Career Seeker" }}>Join the Career-Seeker Portal</Link>
                  </div>
                  <div className="clear"/>
              </header>

              {(this.state.enableSubscriptions) ? (
                <div className="row">
                  <SubSubscribe history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} />
                </div>
              ) : (
                <div>
                  <SubCareerSeekerBenefits history={this.props.navigate} />

                  <section className="section-join">
                      <div className="join-container">
                          <div className="join-text-box">
                              <p className="join-text">Want to use</p>
                              <p className="join-title right-text">Guided Compass?</p>
                          </div>
                          <Link className="btn btn-full join-btn" to={'/join'} state={{ roleName: "Student / Career Seeker" }}>Join</Link>
                      </div>
                  </section>
                </div>
              )}

              {/*Footer()*/}
              <Footer history={this.props.navigate} />
          </div>
      )
    }
}

export default StudentsPage;
