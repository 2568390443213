import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import AddMembers from '../Subcomponents/AddMembers';
import withRouter from '../Functions/WithRouter';

class OrgAddAdmins extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ org, emailId: email, username, cuFirstName, cuLastName, orgLogo })

    }

    render() {

        return (
            <div>
                <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
                <AddMembers activeOrg={this.state.org} accountCode={this.state.accountCode} roleName="Admin" path={this.state.path} type="All" />

                {(this.state.org) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}


export default withRouter(OrgAddAdmins);
