import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubComments from '../Common/Comments';
import SubEditLesson from '../Common/EditLesson';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const importIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/import-icon-blue.png';
const searchIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const profileIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png";
const timeIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-dark.png";
const transcriptIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/transcript-icon.png";
const careerMatchesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png";
const upvoteIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png";
const upvoteIconGrey = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const linkIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png";
const editIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-blue.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const checkmarkIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Exchange extends Component {
    constructor(props) {
      super(props)

      this.state = {
        simplifyUI: true,
        exchangeTypeOptions: ['','Course','Lesson','Video','Project Idea'],
        filterTags: [],

        gradeLevelOptions: ['','6','7','8','9','10','11','12','Post Grad - Intermediate','Post Grad - Advanced'],
        minuteOptions: [],
        filterCriteriaOptions: ['Search','Curriculum Type','Grade Level','Career Pathway','Minutes'],
        sortCriteriaOptions: ['Pinned','Top Voted','Most Recent'],
        sortCriteria: 'Most Recent',
        filterCriteria: 'Search',
        careerPathTags: [],
        selectedCareerDetails: [],
        favorites: [],
        lessons: [],
        courses: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.voteOnItem = this.voteOnItem.bind(this)
      this.exchangeTypeClicked = this.exchangeTypeClicked.bind(this)
      this.renderTags = this.renderTags.bind(this)
      this.removeTag = this.removeTag.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.addItem = this.addItem.bind(this)
      this.retrieveComments = this.retrieveComments.bind(this)
      this.renderItems = this.renderItems.bind(this)
      this.renderItem = this.renderItem.bind(this)
      this.renderSubItem = this.renderSubItem.bind(this)
      this.renderFilters = this.renderFilters.bind(this)
      this.sortItems = this.sortItems.bind(this)
      this.filterItems = this.filterItems.bind(this)
      this.embedYouTubeVideo = this.embedYouTubeVideo.bind(this)
      this.editPosting = this.editPosting.bind(this)
      this.passCurriculumItem = this.passCurriculumItem.bind(this)
      this.toggleExpansion = this.toggleExpansion.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subExchange ', this.props, prevProps)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subExchange')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      const orgFocus = localStorage.getItem('orgFocus');
      let orgName = localStorage.getItem('orgName')
      if (!orgName && this.props.orgName) {
        orgName = this.props.orgName
      }

      let filterTags = [
        { key: 'Curriculum Type', value: 'All'},{ key: 'Grade Level', value: 'All'},{ key: 'Career Path', value: 'All'},
        { key: 'Minutes', value: 'All'}
      ]

      const minuteOptions = [
        '','0 - 10','10 - 20','20 - 30','30 - 45','45 - 60','60 - 90','90 - 120','120 - 150','150 - 180','180 - 240','240 - 300','300 - 500','500 - 1000','1000+'
      ]

      let cardClass = "card floating-container top-margin-2-percent right-margin-2-percent"
      let styleObject = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      let cardClass2 = "floating-container"
      let cardClass3 = "card-clear-padding floating-container top-margin-2-percent right-margin-2-percent"
      let containerClass = "floating-container"
      if (this.props.editLesson) {
        containerClass = "top-margin-20"
      }

      if (this.props.editLesson || ((window.location.pathname.startsWith('/organizations') || window.location.pathname.startsWith('/advisor')) && window.location.pathname.includes('/exchange'))) {
        cardClass = "card"
        styleObject = {}
        cardClass2 = "standard-border padding-20"

        if (window.location.pathname.startsWith('/organizations') && window.location.pathname.includes('/exchange')) {
          cardClass2 = "padding-20"
          containerClass = "card-clear-padding top-margin-20"
        } else if (window.location.pathname.startsWith('/advisor') && window.location.pathname.includes('/exchange')) {
          // containerClass = "top-margin-20 dark-background"
          // cardClass2 = "padding-20 card-clear-padding"
          containerClass = "card-clear-padding top-margin-20"
        }
        cardClass3 = "standard-border"
      }

      let lessons = this.state.lessons
      if (this.props.lessons) {
        lessons = this.props.lessons
      }

      let courses = this.state.courses
      if (this.props.courses) {
        courses = this.props.courses
      }

      const exchangeTypeOptions = ['','Course','Independent Lesson']

      // console.log('show lessons length: ', lessons.length)
      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, orgName, filterTags, minuteOptions,
        cardClass, styleObject, cardClass2, cardClass3, containerClass,
        lessons, courses, exchangeTypeOptions
      })

      Axios.get('/api/users/profile/details/' + emailId)
      .then((response) => {

          if (response.data.success) {
            console.log('Advisee query worked', response.data);

            const pictureURL = response.data.user.pictureURL
            let educationalInstitution = response.data.user.school
            if (response.data.user.schoolDistrict) {
              if (educationalInstitution) {
                educationalInstitution = educationalInstitution + ' of ' + response.data.user.schoolDistrict
              } else {
                educationalInstitution = response.data.user.schoolDistrict
              }
            }
            this.setState({ pictureURL, educationalInstitution });

          } else {
            console.log('no advisees found', response.data.message)

          }

      }).catch((error) => {
          console.log('User profile query not work', error);
      });

      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           let favorites = []
           if (response.data.favorites) {
             favorites = response.data.favorites
           }

           this.setState({ favorites })
         } else {
           console.log('no favorites data found', response.data.message)
         }

      }).catch((error) => {
         console.log('Favorites query did not work', error);
      });

      if (this.props.passedId === 'my-posts') {
        console.log('in my posts!')

        filterTags = [{ key: 'Owner', value: 'Me' }]
        this.setState({ filterTags })

        Axios.get('/api/curriculum-items', { params: { emailId, sortValue: this.state.sortCriteria }})
        .then((response) => {
          console.log('Curriculum items query attempted', response.data);

            if (response.data.success) {
              console.log('curriculum items query worked')

              let curriculumItems = response.data.curriculumItems
              this.setState({ curriculumItems })

            } else {
              console.log('curriculumItems query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('curriculumItems query did not work for some reason', error);
        });
      } else {

        let exchangeType = this.state.exchangeType
        if (this.props.exchangeType) {
          exchangeType = this.props.exchangeType
        }

        Axios.get('/api/curriculum-items', { params: { exchangeType, isActive: true, orgCode: 'guidedcompass', sortValue: this.state.sortCriteria }})
        .then((response) => {
          console.log('Curriculum items query attempted', response.data);

            if (response.data.success) {
              console.log('curriculum items query worked')

              let curriculumItems = response.data.curriculumItems
              this.setState({ curriculumItems })

            } else {
              console.log('curriculumItems query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('curriculumItems query did not work for some reason', error);
        });

        if (this.props.passedId) {
          Axios.get('/api/curriculum-items/byid', { params: { _id: this.props.passedId }})
          .then((response) => {
            console.log('Curriculum item query attempted', response.data);

              if (response.data.success) {
                console.log('curriculum item query worked')

                // let curriculumItem = response.data.curriculumItem
                // this.setState({ curriculumItem })
                this.retrieveComments(null, response.data.curriculumItem)

              } else {
                console.log('curriculumItems query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('curriculumItems query did not work for some reason', error);
          });
        }

      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else if (event.target.name === 'sortCriteria') {
        this.sortItems(event.target.name, event.target.value)
      } else if (event.target.name === 'searchTerm' && !window.location.pathname.includes('/exchange')) {
        this.setState({ [event.target.name]: event.target.value, isLoading: true })
        this.filterItems([{ key: 'Search', value: event.target.value}])
      } else if (event.target.name === 'curriculumType') {
        this.setState({ [event.target.name]: event.target.value, isLoading: true })
        this.filterItems([{ key: 'Curriculum Type', value: event.target.value}])
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    sortItems(sortName, sortValue) {
      console.log('sortItems called')

      this.setState({ isLoading: true })

      const exchangeType = this.state.exchangeType
      if (this.props.exchangeType) {
        exchangeType = this.props.exchangeType
      }

      Axios.get('/api/curriculum-items', { params: { sortValue, isActive: true, orgCode: 'guidedcompass', exchangeType }})
      .then((response) => {
        console.log('Curriculum items query attempted', response.data);

          if (response.data.success) {
            console.log('curriculum items query worked')

            let curriculumItems = response.data.curriculumItems
            this.setState({ curriculumItems, [sortName]: sortValue, isLoading: false })

          } else {
            console.log('curriculumItems query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('curriculumItems query did not work for some reason', error);
      });
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'career') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, searchIsAnimatingCareers: false, careerOptions: null })
        } else {

          // let searchString = this.state.searchString
          let searchIsAnimatingCareers = true

          this.setState({ searchString, searchIsAnimatingCareers })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimatingCareers: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  let careerOptions = []
                  self.setState({ careerOptions, searchIsAnimatingCareers: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);

                let careerOptions = []
                self.setState({ careerOptions, searchIsAnimatingCareers: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'career') {

        const searchObject = passedItem
        const searchString = passedItem.name
        const careerOptions = null

        this.setState({ searchObject, searchString, careerOptions })

      }
    }

    addItem(type,e,value,index) {
      console.log('addItem called', type, value)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (type === 'career') {
        if (this.state.careerPathTags && this.state.careerPathTags.includes(this.state.searchString)) {
          this.setState({ errorMessage: 'You have already added this career' })
        } else {

          if (!this.state.searchObject) {
            return this.setState({ errorMessage: 'Please select a career from search suggestions' })
          }

          const searchString = ''
          const searchObject = null

          let careerPathTags = this.state.careerPathTags
          careerPathTags.unshift(this.state.searchString)

          let selectedCareerDetails = this.state.selectedCareerDetails
          selectedCareerDetails.unshift(this.state.searchObject)

          this.setState({ searchString, searchObject, careerPathTags, selectedCareerDetails, errorMessage: null, careerOptions: [] })
          // this.adjustCoreCompetencies(this.state.searchObject,type, null, true)
        }
      } else if (type === 'filterCareer') {
        if (!this.state.searchObject) {
          return this.setState({ errorMessage: 'Please select a career from search suggestions' })
        }

        const searchString = ''
        const searchObject = null

        let filterTags = this.state.filterTags
        const keyTerm = 'Career Path'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.searchString }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.searchString })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.searchString }]
        }

        this.setState({ searchString, searchObject, filterTags, errorMessage: null, careerOptions: [] })
        this.filterItems(filterTags)
      } else if (type === 'gradeLevel') {

        if (this.state.gradeLevels && this.state.gradeLevels.includes(this.state.gradeLevel)) {
          this.setState({ errorMessage: 'You have already added this grade level' })
        } else {

          let gradeLevels = this.state.gradeLevels
          let gradeLevel = ''

          if (gradeLevels) {
            gradeLevels.push(this.state.gradeLevel)
          } else {
            gradeLevels = [this.state.gradeLevel]
          }
          this.setState({ gradeLevels, gradeLevel })
        }

      } else if (type === 'filterExchangeType') {
        let filterTags = this.state.filterTags
        let filterExchangeType = ''
        const keyTerm = 'Curriculum Type'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterExchangeType }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterExchangeType })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterExchangeType }]
        }

        this.setState({ filterTags, filterExchangeType })
        this.filterItems(filterTags)
      } else if (type === 'filterGradeLevel') {
        let filterTags = this.state.filterTags
        let filterGradeLevel = ''
        const keyTerm = 'Grade Level'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterGradeLevel }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterGradeLevel })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterGradeLevel }]
        }
        this.setState({ filterTags, filterGradeLevel })
        this.filterItems(filterTags)
      } else if (type === 'tag') {

        if (this.state.tags && this.state.tags.includes(this.state.tag)) {
          this.setState({ errorMessage: 'You have already added this tag' })
        } else {
          let tags = this.state.tags
          let tag = ''
          if (tags) {
            tags.push(this.state.tag)
          } else {
            tags = [this.state.tag]
          }
          this.setState({ tags, tag })
        }

      } else if (type === 'searchTerm') {
        let filterTags = this.state.filterTags
        let searchTerm = ''
        const keyTerm = 'Search'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.searchTerm }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.searchTerm })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.searchTer, }]
        }
        this.setState({ filterTags, searchTerm })
        this.filterItems(filterTags)
      } else if (type === 'filterMinutes') {
        let filterTags = this.state.filterTags
        let filterMinutes = ''
        const keyTerm = 'Minutes'
        if (filterTags) {
          if (filterTags.some(tag => tag.key === keyTerm)) {
            const filterIndex = filterTags.findIndex(x => x.key === keyTerm);
            filterTags[filterIndex] = { key: keyTerm, value: this.state.filterMinutes }
          } else {
            filterTags.push({ key: keyTerm, value: this.state.filterMinutes })
          }
        } else {
          filterTags = [{ key: keyTerm, value: this.state.filterMinutes }]
        }
        this.setState({ filterTags, filterMinutes })
        this.filterItems(filterTags)
      } else if (type === 'lesson' || type === 'independent lesson') {

        let lessons = this.state.lessons
        let lesson = {
          curriculumItemId: value._id, description: value.description, educationalInstitution: this.state.orgName,
          email: this.state.emailId, firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
          gradeLevels: value.gradeLevels,name: value.itemName, itemName: value.itemName,minutes: value.minutes,
          exchangeType: value.exchangeType,pictureURL: value.pictureURL,studentURL: value.studentURL,
          tags: value.tags, url: value.url,careerPathTags: value.careerPathTags,
          learningObjectives: value.learningObjectives, prerequisites: value.prerequisites,
          activities: value.activities, assignmentQuestions: value.assignmentQuestions,
          orgCode: this.state.activeOrg, customAssessmentId: value.customAssessmentId,
          createdAt: new Date(),updatedAt: new Date(),
        }
        let addLesson = true
        let deleteIndex = null
        let deleteId = null
        if (lessons.findIndex(l => l.curriculumItemId === value._id) > -1) {
          deleteIndex = lessons.findIndex(l => l.curriculumItemId === value._id)
          deleteId = lessons[deleteIndex]._id
          addLesson = false
          // console.log('show me values: ', lessons, deleteIndex)
        }

        if (addLesson) {
          // POST lesson

          Axios.post('/api/lessons', lesson).then((response) => {
            console.log('attempting to save lesson')
            if (response.data.success) {
              console.log('saved lesson', response.data)

              lesson = response.data.lesson
              if (!lessons) {
                lessons = [{lesson}]
              } else {
                lessons.push(lesson)
              }

              this.setState({ lessons, successMessage: 'Lesson saved!' })
              this.props.passLesson(lesson, addLesson, deleteIndex, false, null)
              // this.props.passLesson(response.data.lesson, true, this.props.selectedIndex, true, _id)

            } else {
              console.log('did not save successfully', response.data.message)
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'Curriculum item save did not work' })
          });
        } else {
          // delete
          console.log('about to delete: ', deleteId)
          if (!deleteId) {
            console.error('there is no delete id?! ');
            this.setState({ errorMessage: 'There was an error finding the lesson' })
          } else {
            Axios.delete('/api/lessons/' + deleteId)
            .then((response) => {
              console.log('tried to delete', response.data)
              if (response.data.success) {
                //save values
                console.log('Lessons delete worked');

                lessons.splice(deleteIndex,1)
                this.setState({ lessons, successMessage: 'Lesson saved!' })
                this.props.passLesson(lesson, false, deleteIndex, false, null, true)

              } else {
                console.error('there was an error deleting the log', response.data.message);
                this.setState({ errorMessage: response.data.message })
              }
            }).catch((error) => {
                console.log('The deleting did not work', error);
                this.setState({ errorMessage: error })
            });
          }
        }
      } else if (type === 'course') {

        let courses = this.state.courses
        let course = {
          curriculumItemId: value._id, description: value.description, educationalInstitution: this.state.educationalInstitution,
          email: this.state.emailId, firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
          gradeLevels: value.gradeLevels,name: value.itemName, itemName: value.itemName,minutes: value.minutes,
          exchangeType: value.exchangeType,pictureURL: value.pictureURL,
          learningModules: value.learningModules, learningObjectives: value.learningObjectives, prerequisites: value.prerequisites,
          syllabus: value.syllabus, announcements: value.announcements, assignments: value.assignments,
          quizzes: value.quizzes,
          imageURL: value.imageURL, isActive: false,
          orgCode: this.state.activeOrg, availableToEveryone: true,
          startDate: new Date(), endDate: new Date(),
          createdAt: new Date(),updatedAt: new Date(),
        }

        let addCourse = true
        let deleteIndex = null
        let deleteId = null
        if (courses.findIndex(l => l.curriculumItemId === value._id) > -1) {
          deleteIndex = courses.findIndex(l => l.curriculumItemId === value._id)
          deleteId = courses[deleteIndex]._id
          addCourse = false
        }

        if (addCourse) {

          Axios.post('/api/courses', course).then((response) => {
            console.log('attempting to save course')
            if (response.data.success) {
              console.log('saved course', response.data)

              course['_id'] = response.data._id
              if (!courses) {
                courses = [{course}]
              } else {
                courses.push(course)
              }

              this.setState({ courses, successMessage: 'Course saved!' })
              if (this.props.passItem) {
                this.props.passItem(course, addCourse, deleteIndex, false, null)
              }

            } else {
              console.log('did not save successfully', response.data.message)
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'Curriculum item save did not work' })
          });
        } else {
          // delete
          console.log('about to delete: ', deleteId)
          if (!deleteId) {
            console.error('there is no delete id?! ');
            this.setState({ errorMessage: 'There was an error finding the course' })
          } else {
            Axios.delete('/api/courses/' + deleteId)
            .then((response) => {
              console.log('tried to delete', response.data)
              if (response.data.success) {
                //save values
                console.log('Courses delete worked');

                courses.splice(deleteIndex,1)
                this.setState({ courses, successMessage: 'Course saved!' })
                if (this.props.passItem) {
                  this.props.passItem(course, false, deleteIndex, false, null, true)
                }

              } else {
                console.error('there was an error deleting the log', response.data.message);
                this.setState({ errorMessage: response.data.message })
              }
            }).catch((error) => {
                console.log('The deleting did not work', error);
                this.setState({ errorMessage: error })
            });
          }
        }
      }
    }

    filterItems(filterTags) {
      console.log('filterItems called', filterTags)

      const sortValue = this.state.sortCriteria

      let searchTerm = null
      let exchangeType = null
      let gradeLevel = null
      let careerPathTags = null
      let minuteRange = null

      if (filterTags && filterTags.length > 0) {
        for (let i = 1; i <= filterTags.length; i++) {
          if (filterTags[i - 1].key === 'Search') {
            searchTerm = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Curriculum Type') {
            exchangeType = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Grade Level') {
            gradeLevel = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Career Path') {
            careerPathTags = filterTags[i - 1].value
          } else if (filterTags[i - 1].key === 'Minutes') {
            minuteRange = filterTags[i - 1].value
          }
        }
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/curriculum-items', { params: { exchangeType, sortValue, searchTerm, gradeLevel, careerPathTags, minuteRange, isActive: true, orgCode: 'guidedcompass' }})
        .then((response) => {
          console.log('Curriculum items query attempted', response.data);

            if (response.data.success) {
              console.log('curriculum items query worked')

              let curriculumItems = response.data.curriculumItems
              self.setState({ curriculumItems, isLoading: false })

            } else {
              console.log('curriculumItems query did not work', response.data.message)
              self.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('curriculumItems query did not work for some reason', error);
            self.setState({ isLoading: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    voteOnItem(e, item, direction, index, featured) {
      console.log('voteOnItem called')

      e.stopPropagation()

      const _id = item._id
      const emailId = this.state.emailId
      const updatedAt = new Date()

      Axios.post('/api/curriculum-items', { _id, direction, emailId, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Curriculum item save worked', response.data);

          const successMessage = 'Curriculum item successfully posted!'
          if (index || index === 0) {
            let curriculumItems = this.state.curriculumItems
            curriculumItems[index] = response.data.curriculumItem
            this.setState({ successMessage, curriculumItems })
          } else if (this.state.passedParentPost) {
            let passedParentPost = response.data.curriculumItem
            this.setState({ successMessage, passedParentPost })
          }

        } else {
          console.error('there was an error posting the curriculum item');
          const errorMessage = response.data.message
          this.setState({ errorMessage })
        }
      }).catch((error) => {
          console.log('The curriculum item did not work', error);
      });
    }

    exchangeTypeClicked(exchangeType) {
      console.log('exchangeTypeClicked')

      this.setState({ exchangeType, showExchangeOptions: false })
    }

    renderTags(type) {
      console.log('renderTags called', type)

      if (type === 'career' || type === 'gradeLevel' || type === 'tag') {
        let tags = this.state.tags
        if (type === 'career') {
          tags = this.state.careerPathTags
        } else if (type === 'gradeLevel') {
          tags = this.state.gradeLevels
        }

        if (tags && tags.length > 0) {

          return (
            <div key={type + 'tags'}>
              <div className="spacer" />
              {tags.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(type,optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>

                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'filter') {
        if (this.state.filterTags) {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {this.state.filterTags.map((item, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(type, optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <button className="background-button float-left left-text" onClick={() => this.setState({ modalIsOpen: true, showFilters: true, filterCriteria: item.key })}>
                    <div className="tag-container-8 cta-background-color">
                      <p className="description-text-2 white-text">{item.key}: {item.value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>
                  {/*
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-8 cta-background-color">
                      <p className="description-text-2 white-text">{item.key}: {item.value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>*/}
                </div>
              )}

            </div>
          )
        }
      }
    }

    removeTag(type, index) {
      console.log('removeTag called', type, index)

      if (type === 'filter') {
        let filterTags = this.state.filterTags
        filterTags.splice(index, 1)
        this.setState({ filterTags })
        this.filterItems(filterTags)
      } else if (type === 'career') {
        // console.log('working?')
        let careerPathTags = this.state.careerPathTags
        careerPathTags.splice(index, 1)

        let selectedCareerDetails = this.state.selectedCareerDetails
        // const careerToRemove = selectedCareerDetails[index]
        selectedCareerDetails.splice(index, 1)
        this.setState({ careerPathTags, selectedCareerDetails })
      } else if (type === 'gradeLevel') {
        let gradeLevels = this.state.gradeLevels
        gradeLevels.splice(index, 1)
        this.setState({ gradeLevels })
      }
    }

    retrieveComments(index, passedParentPost) {
      console.log('retrieveComments called', index, passedParentPost)

      let parentPostId = ''
      if (passedParentPost) {
        parentPostId = passedParentPost._id
      } else if (index || index === 0) {
        parentPostId = this.state.curriculumItems[index]._id
      }

      // pull comments
      Axios.get('/api/comments', { params: { parentPostId } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           const comments = response.data.comments
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments, passedParentPost })

         } else {
           console.log('no comments data found', response.data.message)
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [], passedParentPost })
         }
      }).catch((error) => {
         console.log('Comments query did not work', error);
         this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [], passedParentPost })
      });
    }

    renderItems() {
      console.log('renderItems called')

      if (this.state.curriculumItems && this.state.curriculumItems.length > 0) {
        return (
          <div key={'curriculum items'}>
            {this.state.curriculumItems.map((item, optionIndex) =>
              <div key={optionIndex +  'curriculum item'}>
                {this.renderItem(item, optionIndex, null)}
              </div>
            )}
          </div>
        )
      }
    }

    renderItem(item, optionIndex, inModal) {
      console.log('renderItem called', item)

      return (
        <div key={optionIndex + ' sub items'}>
          <div>
            <div style={(inModal) ? {} : this.state.styleObject}>
              {(inModal) ? (
                <div className={"left-text float-left full-width"}>
                  {this.renderSubItem(item, optionIndex, inModal)}
                </div>
              ) : (
                <div className={"left-text float-left full-width"}>
                  {this.renderSubItem(item, optionIndex, inModal)}
                </div>
              )}

            </div>

            {(this.props.editLesson) && (
              <div className="clear" />
            )}

          </div>
        </div>
      )
    }

    renderSubItem(item, optionIndex, inModal) {
      console.log('renderSubItem called')

      return (
        <div key={optionIndex + ' sub items'} className="standard-border">
          <div>
            <div className={(inModal) ? "" : "padding-20"}>
              {(this.state.simplifyUI) ? (
                <div>
                  <div className="row-5">
                    <div className="calc-column-offset-30">
                      <p className="heading-text-6">{optionIndex + 1}. {item.itemName}</p>
                    </div>
                    <div className="fixed-column-30">
                      <button className="background-button clear-margin clear-padding display-block" onClick={(e) => this.addItem(item.exchangeType.toLowerCase(),e,item,optionIndex) }>
                        <img src={(this.state.lessons.some(l => l.curriculumItemId === item._id) || this.state.courses.some(course => course.curriculumItemId === item._id)) ? checkmarkIconBlue : importIconBlue} alt="GC" className="image-30-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import Curriculum Item"/>
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(item.educationalInstitution) && (
                    <div className="row-5">
                      <label className="description-text-2">{item.exchangeType} from {item.educationalInstitution}</label>
                      {/*
                      <label className="description-text-2">by {item.firstName} {item.lastName}</label>
                      {(item.educationalInstitution) && (
                        <label className="description-text-2">, Educator @ {item.educationalInstitution}</label>
                      )}*/}
                    </div>
                  )}

                  <div className="row-5">
                    <p className="description-text-2 description-text-color curtail-text">{item.description}</p>
                  </div>

                  <div className="row-5">
                    <div className="description-text-2">
                      <div className="top-margin-5">
                        <div className="float-left">
                          <img className="image-auto-20" alt="GC" src={timeIconDark} />
                        </div>
                        <div className="float-left left-padding right-padding bottom-margin">
                          <p>{item.minutes} Mins</p>
                        </div>
                      </div>

                      {(item.tags && item.tags.length > 0) && (
                        <span className="float-left">
                          {item.tags.map((item2, optionIndex2) =>
                            <div key={item2 + optionIndex2} className="float-left right-margin bottom-margin medium-background row-5 horizontal-padding-4 top-margin-negative-5 slightly-rounded-corners">
                              <p>{item2}</p>
                            </div>
                          )}
                        </span>
                      )}
                      <div className="clear" />

                    </div>
                  </div>

                  <button className={(item.showDetails) ? "background-button left-text error-border padding-10 slightly-rounded-corners" : "background-button left-text cta-border padding-10 slightly-rounded-corners"} onClick={() => this.toggleExpansion(optionIndex)}>
                    <div className="float-left">
                      <p className="description-text-2">{(item.showDetails) ? "Collapse Details" : "Expand for Details"}</p>
                    </div>
                    <div className="float-left left-margin top-margin-5">
                      <img className={(item.showDetails) ? "image-12-fit rotate-270" : "image-12-fit rotate-90"} alt="GC" src={arrowIndicatorIcon} />
                    </div>
                    <div className="clear" />
                  </button>

                  {(item.showDetails) && (
                    <div className="description-text-2 top-margin-20 error-border padding-20">
                      {(item.imageURL) && (
                        <div className="full-width bottom-margin-20">
                          <img src={item.imageURL} alt="GC" className="image-full-auto" />
                        </div>
                      )}

                      <p>{item.description}</p>

                      {(item.learningObjectives && item.learningObjectives.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Learning Objectives</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.learningObjectives.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <p>{optionIndex2 + 1}. {(item2.name) ? item2.name : item2}</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      {(item.learningModules && item.learningModules.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Learning Modules</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.learningModules.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <p>{optionIndex2 + 1}. {(item2.name) ? item2.name : item2}</p>
                                {(item2.lessons && item2.lessons.length > 0) && (
                                  <div>
                                    {item2.lessons.map((item3, optionIndex3) =>
                                      <div key={item3 + optionIndex3} className="left-padding-20">
                                        <p>{optionIndex2 + 1}.{optionIndex3 + 1}. {(item3.name) ? item3.name : item3}</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      <div className="clear" />

                      {(item.careerPathTags && item.careerPathTags.length > 0) && (
                        <div>
                          <div className="float-left right-padding-5 left-padding-20">
                            <img className="image-auto-20" alt="GC" src={careerMatchesIconDark} />
                          </div>
                          {item.careerPathTags.map((item2, optionIndex2) =>
                            <div key={item2 + optionIndex2} className="float-left left-padding-5 right-margin">
                              {(item.careerPathTags.length === optionIndex2 + 1) ? (
                                <label>{item2}</label>
                              ) : (
                                <label>{item2}, </label>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="clear" />

                      {(item.prerequisites && item.prerequisites.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Prerequisites</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.prerequisites.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                              </div>
                            )}
                          </div>
                          <div className="clear" />

                          <div className="clear" />
                        </div>
                      )}

                      {(item.resources && item.resources.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Resources</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.resources.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                              </div>
                            )}
                          </div>
                          <div className="clear" />

                          <div className="clear" />
                        </div>
                      )}

                      {(item.activities && item.activities.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Activities</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.activities.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <p>{optionIndex2 + 1}. {item2.name}</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />

                          <div className="clear" />
                        </div>
                      )}

                      {(item.assignmentQuestions && item.assignmentQuestions.length > 0) && (
                        <div className="row-15">
                          <div className="row-5">
                            <p className="description-text-color">Comprehension Questions</p>
                          </div>
                          <div className="calc-column-offset-150">
                            {item.assignmentQuestions.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2}>
                                <p>{optionIndex2 + 1}. {item2.name}</p>
                              </div>
                            )}
                          </div>
                          <div className="clear" />

                          <div className="clear" />
                        </div>
                      )}

                      <div>
                        {(item.url) && (
                          <div className="top-margin-15">
                            {(item.url.includes('youtube.com') || item.url.includes('youtu.be')) ? (
                              <div>
                                <p>Educator-Facing Video</p>
                                <div className="spacer"/><div className="spacer"/>
                                <div className="video-container-4">
                                  <iframe
                                    title="videoLink"
                                    className="video-iframe"
                                    src={this.embedYouTubeVideo(item.url)}
                                    frameBorder="0"
                                  />
                                </div>
                                <div className="clear"/>
                              </div>
                            ) : (
                              <div>
                                <div className="float-left right-padding-5">
                                  <img className="image-auto-19" alt="GC" src={linkIconBlue} />
                                </div>
                                <div className="float-left left-padding-5 heading-text-6">
                                  <a href={item.url} target="_blank" onClick={(e) => e.stopPropagation()}>Educator-Facing Link</a>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {(item.studentURL) && (
                          <div>
                            {(item.studentURL.includes('youtube.com') || item.studentURL.includes('youtu.be')) ? (
                              <div>
                                <div className="clear" />
                                <div className="top-margin-15">
                                  <p>Student-Facing Video:</p>
                                  <div className="spacer"/><div className="spacer"/>
                                  <div className="video-container-4">
                                    <iframe
                                      title="videoLink"
                                      className="video-iframe"
                                      src={this.embedYouTubeVideo(item.studentURL)}
                                      frameBorder="0"
                                    />
                                  </div>
                                  <div className="clear"/>
                                </div>
                              </div>
                            ) : (
                              <div className="top-margin-15">
                                <div className={(item.url) ? "float-left right-padding-5 left-padding-20" : "float-left right-padding-5"}>
                                  <img className="image-auto-19" alt="GC" src={linkIconBlue} />
                                </div>
                                <div className="float-left left-padding-5 heading-text-6">
                                  <a href={item.studentURL} target="_blank">Student-Facing Link</a>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                          </div>
                        )}
                      </div>
                    </div>
                  )}

                </div>
              ) : (
                <div>
                  <div>
                    <div className="fixed-column-70">
                      <img className="profile-thumbnail-4" alt="GC" src={(item.pictureURL) ? item.pictureURL : profileIconGrey} />
                    </div>
                    <div className="calc-column-offset-140-static">
                      <p className="heading-text-2">{item.itemName}</p>
                      <label className="description-text-2">by {item.firstName} {item.lastName}</label>
                      {(item.educationalInstitution) && (
                        <label className="description-text-2">, Educator @ {item.educationalInstitution}</label>
                      )}
                    </div>

                    {(this.props.editLesson) ? (
                      <div className="fixed-column-70">
                        <button className="background-button clear-margin clear-padding display-block float-right" onClick={(e) => this.addItem('lesson' ,e,item,optionIndex) }>
                          <img src={(this.state.lessons.some(l => l.curriculumItemId === item._id)) ? checkmarkIconBlue : importIconBlue} alt="GC" className="image-auto-30" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import Lesson for Students/Career-Seekers"/>
                        </button>


                        <div className="clear" />

                        <div className="top-padding-15">
                          <p className="description-text-3 full-width right-text">{convertDateToString(item.createdAt,'daysAgo')}</p>
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>
                    ) : (
                      <div className="fixed-column-70">
                        <button className="background-button clear-margin clear-padding display-block float-right" onClick={(e) => this.voteOnItem(e, item, 'up', optionIndex, true) }>
                          <div className={(item.upvotes.includes(this.state.emailId)) ? "cta-border rounded-corners" : "standard-border rounded-corners"}>
                            <div className="float-left padding-7">
                              <img src={(item.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="Compass arrow indicator icon" className="image-auto-15"/>
                            </div>
                            <div className={(item.upvotes.includes(this.state.emailId)) ? "vertical-separator-4 cta-border" : "vertical-separator-4"} />
                            <div className="float-left horizontal-padding-10">
                              <div className="half-spacer" />
                              <p className="description-text-2 half-bold-text">{item.upvotes.length}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </button>

                        <div className="clear" />

                        <div className="top-padding-15">
                          <p className="description-text-3 full-width right-text">{convertDateToString(item.createdAt,'daysAgo')}</p>
                        </div>

                      </div>
                    )}

                  </div>
                  <div className="clear" />

                  <div className="full-width">
                    <div>
                      <div>
                        <div className="calc-column-offset-30">
                          {(item.description) && (
                            <div className="row-15">
                              <p className={(inModal) ? "full-width description-text-color" : "full-width description-text-color"}>{item.description}</p>
                            </div>
                          )}
                        </div>
                        <div className="fixed-column-30">
                          {(!inModal && item.email === this.state.emailId) && (
                            <div className="top-padding-15 left-padding">
                              <button className="background-button" onClick={(e) => this.editPosting(e, item, optionIndex)}>
                                <img src={editIconBlue} alt="GC" className="image-15-fit" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="description-text-2">
                        <div className="top-padding-15">
                          <div className="float-left">
                            <img className="image-auto-20" alt="GC" src={timeIconDark} />
                          </div>
                          <div className="float-left left-padding">
                            <p>{item.minutes} Mins</p>
                          </div>
                        </div>

                        {(item.gradeLevels && item.gradeLevels.length > 0) && (
                          <div>
                            <div className="float-left right-padding-5 left-padding-20">
                              <img className="image-auto-20" alt="GC" src={transcriptIcon} />
                            </div>
                            {item.gradeLevels.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2} className="float-left left-padding-5 right-margin">
                                {(item.gradeLevels.length === optionIndex2 + 1) ? (
                                  <label>{item2}</label>
                                ) : (
                                  <label>{item2}, </label>
                                )}
                              </div>
                            )}

                            {(item.gradeLevels.length > 1) ? (
                              <label className="float-left left-padding-5"> Grades</label>
                            ) : (
                              <label className="float-left left-padding-5"> Grade</label>
                            )}
                          </div>
                        )}

                        {(item.careerPathTags && item.careerPathTags.length > 0) && (
                          <div>
                            <div className="float-left right-padding-5 left-padding-20">
                              <img className="image-auto-20" alt="GC" src={careerMatchesIconDark} />
                            </div>
                            {item.careerPathTags.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2} className="float-left left-padding-5 right-margin">
                                {(item.careerPathTags.length === optionIndex2 + 1) ? (
                                  <label>{item2}</label>
                                ) : (
                                  <label>{item2}, </label>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        <div className="clear" />

                        {(item.tags && item.tags.length > 0) && (
                          <div className="top-margin-20">
                            {item.tags.map((item2, optionIndex2) =>
                              <div key={item2 + optionIndex2} className="float-left left-padding-5 tag-container-inactive right-margin top-margin">
                                <p>{item2}</p>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                        <div className="clear" />

                        {(item.learningObjectives && item.learningObjectives.length > 0) && (
                          <div className="row-15">
                            <div className="row-5">
                              <p className="description-text-color">Learning Objectives</p>
                            </div>
                            <div className="calc-column-offset-150">
                              {item.learningObjectives.map((item2, optionIndex2) =>
                                <div key={item2 + optionIndex2}>
                                  <p>{optionIndex2 + 1}. {item2}</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />

                            <div className="clear" />
                          </div>
                        )}

                        {(item.prerequisites && item.prerequisites.length > 0) && (
                          <div className="row-15">
                            <div className="row-5">
                              <p className="description-text-color">Prerequisites</p>
                            </div>
                            <div className="calc-column-offset-150">
                              {item.prerequisites.map((item2, optionIndex2) =>
                                <div key={item2 + optionIndex2}>
                                  <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                                </div>
                              )}
                            </div>
                            <div className="clear" />

                            <div className="clear" />
                          </div>
                        )}

                        {(item.resources && item.resources.length > 0) && (
                          <div className="row-15">
                            <div className="row-5">
                              <p className="description-text-color">Resources</p>
                            </div>
                            <div className="calc-column-offset-150">
                              {item.resources.map((item2, optionIndex2) =>
                                <div key={item2 + optionIndex2}>
                                  <a href={item2.url} target="_blank" rel="noopener noreferrer">{optionIndex2 + 1}. {item2.name}</a>
                                </div>
                              )}
                            </div>
                            <div className="clear" />

                            <div className="clear" />
                          </div>
                        )}

                        {(item.activities && item.activities.length > 0) && (
                          <div className="row-15">
                            <div className="row-5">
                              <p className="description-text-color">Activities</p>
                            </div>
                            <div className="calc-column-offset-150">
                              {item.activities.map((item2, optionIndex2) =>
                                <div key={item2 + optionIndex2}>
                                  <p>{optionIndex2 + 1}. {item2.name}</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />

                            <div className="clear" />
                          </div>
                        )}

                        {(item.assignmentQuestions && item.assignmentQuestions.length > 0) && (
                          <div className="row-15">
                            <div className="row-5">
                              <p className="description-text-color">Comprehension Questions</p>
                            </div>
                            <div className="calc-column-offset-150">
                              {item.assignmentQuestions.map((item2, optionIndex2) =>
                                <div key={item2 + optionIndex2}>
                                  <p>{optionIndex2 + 1}. {item2.name}</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />

                            <div className="clear" />
                          </div>
                        )}

                        <div>
                          {(item.url) && (
                            <div className="top-margin-15">
                              {(item.url.includes('youtube.com') || item.url.includes('youtu.be')) ? (
                                <div>
                                  <p>Educator-Facing Video</p>
                                  <div className="spacer"/><div className="spacer"/>
                                  <div className="video-container-4">
                                    <iframe
                                      title="videoLink"
                                      className="video-iframe"
                                      src={this.embedYouTubeVideo(item.url)}
                                      frameBorder="0"
                                    />
                                  </div>
                                  <div className="clear"/>
                                </div>
                              ) : (
                                <div>
                                  <div className="float-left right-padding-5">
                                    <img className="image-auto-19" alt="GC" src={linkIconBlue} />
                                  </div>
                                  <div className="float-left left-padding-5 heading-text-6">
                                    <a href={item.url} target="_blank" onClick={(e) => e.stopPropagation()}>Educator-Facing Link</a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(item.studentURL) && (
                            <div>
                              {(item.studentURL.includes('youtube.com') || item.studentURL.includes('youtu.be')) ? (
                                <div>
                                  <div className="clear" />
                                  <div className="top-margin-15">
                                    <p>Student-Facing Video:</p>
                                    <div className="spacer"/><div className="spacer"/>
                                    <div className="video-container-4">
                                      <iframe
                                        title="videoLink"
                                        className="video-iframe"
                                        src={this.embedYouTubeVideo(item.studentURL)}
                                        frameBorder="0"
                                      />
                                    </div>
                                    <div className="clear"/>
                                  </div>
                                </div>
                              ) : (
                                <div className="top-margin-15">
                                  <div className={(item.url) ? "float-left right-padding-5 left-padding-20" : "float-left right-padding-5"}>
                                    <img className="image-auto-19" alt="GC" src={linkIconBlue} />
                                  </div>
                                  <div className="float-left left-padding-5 heading-text-6">
                                    <a href={item.studentURL} target="_blank">Student-Facing Link</a>
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}

                            </div>
                          )}
                        </div>

                        <div className="clear" />
                        {/*
                        <div className="top-padding">
                          <p className="description-text-2 bold-text">Sorted By: <label className="error-color">Most Upvoted</label></p>
                        </div>*/}
                      </div>
                    </div>
                    <div className="clear" />

                  </div>

                  <div className="spacer" /><div className="spacer" />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    editPosting(e, item, index) {
      console.log('editPosting called', item, index)

      e.stopPropagation()

      const _id = item._id
      const exchangeType = item.exchangeType
      const itemName = item.itemName
      const description = item.description
      const gradeLevels = item.gradeLevels
      const minutes = item.minutes
      const careerPathTags = item.careerPathTags
      const tags = item.tags
      const url = item.url
      const studentURL = item.studentURL

      this.setState({ modalIsOpen: true, showAddItem: true, selectedIndex: index,
        _id, exchangeType, itemName, description, gradeLevels, minutes, careerPathTags, tags, url, studentURL
      })

    }

    renderFilters() {
      console.log('renderFilters called')

      if (this.state.filterCriteria === 'Search') {
        return (
          <div key={this.state.filterCriteria}>
            <div className="row-10">
              <div className="calc-column-offset-70">
                <input className="text-field" type="text" placeholder={"Add a search across curriculum..."} name="searchTerm" value={this.state.searchTerm} onChange={this.formChangeHandler}/>
              </div>
              <div className="fixed-column-70 left-padding">
                <button className={(!this.state.searchTerm || this.state.searchTerm === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.searchTerm || this.state.searchTerm === '') ? true : false} onClick={() => this.addItem('searchTerm')}>Add</button>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (this.state.filterCriteria === 'Curriculum Type') {
        return (
          <div key={this.state.filterCriteria}>
            <div className="row-10">
              <div className="calc-column-offset-70">
                <select name="filterExchangeType" value={this.state.filterExchangeType} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.exchangeTypeOptions.map(value =>
                    <option key={value} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="fixed-column-70 left-padding">
                <button className={(!this.state.filterExchangeType || this.state.filterExchangeType === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.filterExchangeType || this.state.filterExchangeType === '') ? true : false} onClick={() => this.addItem('filterExchangeType')}>Add</button>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (this.state.filterCriteria === 'Grade Level') {
        return (
          <div key={this.state.filterCriteria}>
            <div className="row-10">
              <div className="calc-column-offset-70">
                <select name="filterGradeLevel" value={this.state.filterGradeLevel} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.gradeLevelOptions.map(value =>
                    <option key={value} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="fixed-column-70 left-padding">
                <button className={(!this.state.filterGradeLevel || this.state.filterGradeLevel === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.filterGradeLevel || this.state.filterGradeLevel === '') ? true : false} onClick={() => this.addItem('filterGradeLevel')}>Add</button>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (this.state.filterCriteria === 'Career Pathway') {
        return (
          <div key={this.state.filterCriteria}>
            <div className="row-10">
              <div className="calc-column-offset-70">
                <input className="text-field" type="text" placeholder={'Search career paths...'} name="searchCareers" value={this.state.searchString} onChange={this.formChangeHandler}/>
              </div>
              <div className="fixed-column-70 left-padding">
                <button className={(!this.state.searchString || this.state.searchString === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.searchString || this.state.searchString === '') ? true : false} onClick={() => this.addItem('filterCareer')}>Add</button>
              </div>
              <div className="clear" />

              {(this.state.careerErrorMessage && this.state.careerErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.careerErrorMessage}</p>}
              {(this.state.careerSuccessMessage && this.state.careerSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.careerSuccessMessage}</p>}

              {(this.state.searchIsAnimatingCareers) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <div className="super-spacer" />

                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Searching...</p>

                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {(this.state.careerOptions && this.state.careerOptions.length > 0) && (
                      <div className="card top-margin">
                        {this.state.careerOptions.map((value, optionIndex) =>
                          <div key={value._id} className="left-text bottom-margin-5 full-width">
                            <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                              <div className="full-width">
                                <div className="fixed-column-40">
                                  <div className="mini-spacer" />
                                  <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                </div>
                                <div className="calc-column-offset-40">
                                  <p className="cta-color">{value.name}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>

                    {this.renderTags('career')}
                    <div className="clear" />

                  </div>

                </div>
              )}
            </div>
          </div>
        )
      } else if (this.state.filterCriteria === 'Minutes') {
        return (
          <div key={this.state.filterCriteria}>
            <div className="row-10">
              <div className="calc-column-offset-70">
                <select name="filterMinutes" value={this.state.filterMinutes} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.minuteOptions.map(value =>
                    <option key={value} value={value}>{value}</option>
                  )}
                </select>
              </div>
              <div className="fixed-column-70 left-padding">
                <button className={(!this.state.filterMinutes || this.state.filterMinutes === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.filterMinutes || this.state.filterMinutes === '') ? true : false} onClick={() => this.addItem('filterMinutes')}>Add</button>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      }
    }

    closeModal(passFunction) {
      console.log('closeModal called', passFunction)

      if (passFunction && this.props && this.props.closeModal) {
        this.props.closeModal()
      } else {
        const _id = null
        const exchangeType = ''
        const itemName = ''
        const description = ''
        const gradeLevels = []
        const minutes = ''
        const careerPathTags = []
        const tags = null
        const url = ''
        const studentURL = ''

        this.setState({ modalIsOpen: false, showAddItem: false, showComments: false, showFilters: false, parentPost: null,
          _id, exchangeType, itemName, description, gradeLevels, minutes, careerPathTags, tags, url, studentURL,
          selectedCurriculumItem: null, selectedIndex: null
        })
      }
    }

    embedYouTubeVideo(url) {
      console.log('embedYouTubeVideo called')

      // https://youtu.be/YDAJjw9ocUo
      // https://www.youtube.com/watch?v=YDAJjw9ocUo
      // https://www.youtube.com/embed/uNfiXBEfwt4

      if (url.includes('youtu.be')) {
        const urlArray = url.split('youtu.be')
        const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
        return embedURL
      } else if (url.includes('youtube.com')) {
        if (url.includes('/embed')) {
          return url
        } else {
          const urlArray = url.split('?v=')
          const embedURL = 'https://www.youtube.com/embed/' + urlArray[1]
          return embedURL
        }
      }
    }

    passCurriculumItem(ci, addCI, index, newlyCreated, _id) {
      console.log('passCurriculumItem called', ci, addCI, index, newlyCreated, _id)

      let curriculumItems = this.state.curriculumItems
      if (_id ) {
        curriculumItems[index] = ci
      } else if (addCI) {
        console.log('ci1')
        if (curriculumItems) {
          console.log('ci2')
          if (curriculumItems.findIndex(l => l._id === ci._id) > -1) {
            console.log('ci3')
            const deleteIndex = curriculumItems.findIndex(l => l._id === ci._id)
            console.log('about to delete ci', deleteIndex)
            curriculumItems.splice(deleteIndex,1)
            addCI = false
          } else {
            console.log('ci4')
            if (newlyCreated) {
              console.log('ci5')
              curriculumItems.push(ci)
            }

            // console.log('compare two: ', curriculumItems.length)
          }
        } else {
          curriculumItems = [ci]
        }
      } else {
        curriculumItems.splice(index,1)
      }

      console.log('compare three: ', curriculumItems.length)
      this.setState({ curriculumItems, successMessage: 'Changes saved!' })
    }

    toggleExpansion(index) {
      console.log('toggleExpansion called')

      let curriculumItems = this.state.curriculumItems
      curriculumItems[index]['showDetails'] = !curriculumItems[index].showDetails
      this.setState({ curriculumItems })

    }

    render() {

      return (
          <div>

            {(this.props.editLesson) ? (
              <div>
                <div className="bottom-padding-20">
                  <div className="calc-column-offset-70">
                    <p className="heading-text-2">Import Shared Curriculum</p>
                  </div>

                  {(!this.state.simplifyUI) && (
                    <div className="fixed-column-40 top-padding">
                      <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showAddItem: true })}>
                        <img src={closeIcon} alt="GC" className="image-auto-13 rotate-45 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create Learning Module" />
                      </button>
                    </div>
                  )}

                  <div className="fixed-column-70 top-padding right-text">
                    <button className="background-button clear-padding clear-margin" onClick={() => this.props.closeModal()}>
                      <p className="cta-color half-bold-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Close This View">Cancel</p>
                    </button>
                  </div>
                  <div className="clear" />

                </div>

                <div>
                  <div className="full-width">
                    <div className="standard-border top-margin-15 full-width white-background row-3 horizontal-padding-7">
                      <div className="float-left top-padding-5 left-padding-3">
                        <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                      </div>
                      <div className="calc-column-offset-100-static">
                        <input type="text" className="text-field clear-border" placeholder="Search shared curriculum..." name="searchTerm" value={this.state.searchTerm} onChange={this.formChangeHandler}/>
                      </div>
                      {(!this.state.simplifyUI) && (
                        <div className="search-icon-container top-margin-negative-3">
                          <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showFilters: true })}>
                            <div className={(this.state.showFilters) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                          </button>
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  </div>
                </div>

                {(!this.state.simplifyUI) ? (
                  <div className="top-padding-20">
                    <div>
                      <div className="calc-column-offset-140">
                        {(this.renderTags('filter'))}
                      </div>
                      <div className="fixed-column-140 top-padding-20">
                        <select name="sortCriteria" value={this.state.sortCriteria} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.sortCriteriaOptions.map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="spacer" /><div className="half-spacer" />

                    {(!this.props.exchangeType) && (
                      <div className="cta-border padding-20">
                        <div className="container-left">
                          <p className="bottom-margin">Filter by Curriculum Type</p>
                          <select name="curriculumType" value={this.state.curriculumType} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.exchangeTypeOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>
                    )}

                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className={this.state.cardClass} style={this.state.styleObject}>
                  <div>
                    <div className="calc-column-offset-80">
                      <div className="float-left">
                        <p className="heading-text-2">Curriculum Exchange</p>
                      </div>
                      <div className="clear" />

                      <p className="description-text-2 row-5 standard-color">Search, share, vote on, comment on, and discover lesson plans, project ideas, and videos across teachers in your grade level(s) and career pathway(s).</p>

                    </div>
                    <div className="fixed-column-40 top-padding">
                      <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showFilters: true })}>
                        <img src={searchIcon} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="search and filter results" />
                      </button>
                    </div>
                    <div className="fixed-column-40 top-padding">
                      <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showAddItem: true })}>
                        <img src={addIcon} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="contribute curriculum"/>
                      </button>
                    </div>
                    <div className="clear" />

                  </div>

                </div>

                <div className={this.state.cardClass2} style={this.state.styleObject}>
                  {(this.props.editLesson) && (
                    <div>
                      <p className="description-text-4 description-text-color">FILTER RESULTS</p>
                    </div>
                  )}
                  <div>
                    <div className="calc-column-offset-140">
                      {(this.renderTags('filter'))}
                    </div>
                    <div className="fixed-column-140 top-padding-20">
                      <select name="sortCriteria" value={this.state.sortCriteria} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.sortCriteriaOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            )}

            {(this.state.isLoading) ? (
              <div className={this.state.cardClass2} style={this.state.styleObject}>
                <div>
                  <div className="super-spacer" />

                  <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading...</p>

                </div>
              </div>
            ) : (
              <div className={this.state.containerClass}>
                {this.renderItems()}
                <div className="clear" />
              </div>
            )}

            <div className="clear" />

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             ariaHideApp={false}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             >
              {(this.state.showAddItem) && (
                <div key="addItem" className="full-width padding-20">
                  <SubEditLesson history={this.props.navigate} passLesson={this.props.passLesson} passCurriculumItem={this.passCurriculumItem} closeModal={this.closeModal} selectedIndex={this.state.selectedIndex} selectedCurriculumItem={this.state.curriculumItems[this.state.selectedIndex]} editLesson={this.props.editLesson} />
                </div>
              )}

              {(this.state.showComments) && (
                <div key="showComments" className="full-width">

                  {(this.state.passedParentPost) ? (
                    <div>
                      {this.renderItem(this.state.passedParentPost, null, true)}
                      <div className="clear" />
                      <div className="spacer" />

                      {(this.state.passedParentPost && this.state.activeOrg) && (
                        <SubComments selectedCurriculumPost={this.state.passedParentPost} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} orgLogo={this.state.orgLogo} history={this.props.navigate} pageSource={"exchange"} />
                      )}
                    </div>
                  ) : (
                    <div>
                      {this.renderItem(this.state.curriculumItems[this.state.selectedIndex], this.state.selectedIndex, true)}
                      <div className="clear" />
                      <div className="spacer" />

                      {(this.state.curriculumItems && this.state.activeOrg) && (
                        <SubComments selectedCurriculumPost={this.state.curriculumItems[this.state.selectedIndex]} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} orgLogo={this.state.orgLogo} history={this.props.navigate} pageSource={"exchange"} />
                      )}
                    </div>
                  )}
                </div>
              )}

              {(this.state.showFilters) && (
                <div key="showFilters" className="full-width">
                  <div className="row-10">
                    <p className="heading-text-4">Filter Results</p>
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Filter Criteria Type</label>
                      <select name="filterCriteria" value={this.state.filterCriteria} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.filterCriteriaOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div>
                    {this.renderFilters()}
                  </div>

                  {(this.renderTags('filter'))}

                 <div className="clear" />
                 <div className="spacer" /><div className="spacer" />

                 <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>

                </div>
              )}

            </Modal>
          </div>

      )
    }
}

export default withRouter(Exchange);
