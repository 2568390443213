import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import AdminSendMessage from './Subcomponents/AdminSendMessage';
import withRouter from './Functions/WithRouter';

class EmpSendMessage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let { emp } = this.props.params

      let email = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      let selectedApplication = null
      if (this.props.location && this.props.location.state) {
        selectedApplication = this.props.location.state.selectedApplication
      }

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      this.setState({ emp, activeOrg, orgLogo, orgMode, selectedApplication })

    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

        return (
            <div>
              <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
              <AdminSendMessage org={this.state.activeOrg} accountCode={this.state.emp} selectedApplication={this.state.selectedApplication} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(EmpSendMessage)
