import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubNotificationPreferenes from './Subcomponents/NotificationPreferences';
import withRouter from './Functions/WithRouter';

class NotificationPreferences extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      let notificationType = null
      if (window.location.pathname.includes('/email-subscriptions/')) {
        notificationType = 'email'
      }

      this.setState({ emailId: email, activeOrg, orgLogo, notificationType })
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)
      this.setState({ activeOrg })
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />

            <div className="standard-container-2">
              <SubNotificationPreferenes history={this.props.navigate} emailId={this.state.emailId} notificationType={this.state.notificationType}/>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(NotificationPreferences)
