import React, {Component} from 'react';

const clientLogo1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_1.png";
const clientLogo2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_2.png";
const clientLogo3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_3.png";
const clientLogo4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_4.png";
const clientLogo5 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_5.png";
const clientLogo6 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_6.png";
const clientLogo7 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_7.png";
const clientLogo8 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_8.png";
const clientLogo10 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_10.jpg";
const clientLogo11 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_11.jpg";
const clientLogo12 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_12.png";
const clientLogo13 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_13.png";
const clientLogo14 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_14.png";
const clientLogo15 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_15.png";
const clientLogo16 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_16.png";

class Clients extends Component {
    constructor(props) {
      super(props)
      this.state = {

      }
    }

    componentDidMount() {
      console.log('componentDidMount called')

      let isMobileView = false
      if (window.innerWidth < 767) {
        // mobile view
        isMobileView = true
        this.setState({ isMobileView })
      }
    }

    render() {

      return (
        <div className="row">
          <div>
            <div className="flex-container-mobile-column box">
              <div className="flex-20 box top-padding-5">
                <a href="https://blockknowledge.co/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo15} alt="GC" className="image-full-auto shrink-width" />
                </a>
              </div>
              <div className="flex-20 box">
                <a href="https://www.detroitk12.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo14} alt="GC" className="image-full-auto shrink-width" />
                </a>
              </div>
              <div className="flex-20 box top-margin-45">
                <a href="https://www.expfuture.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo13} alt="GC" className="image-full-auto shrink-width" />
                </a>
              </div>
              <div className="flex-20 box top-margin-30">
                <a href="https://www.unitela.com/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo12} alt="GC" className="image-full-auto shrink-width" />
                </a>
              </div>
              <div className="flex-20 box top-margin-negative-20">
                <div className="padding-40">
                  <a href="https://achieve.lausd.net/domain/4" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo11} alt="GC" className="image-full-auto shrink-width" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex-container-mobile-column">
              <div className="flex-20 box">
                <a href="https://www.collegetocongress.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo10} alt="GC" className="image-full-auto shrink-width" />
                </a>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://dymallyhs-lausd-ca.schoolloop.com/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo16} alt="GC" className="image-full-auto shrink-width" />
                  </a>
                </div>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://elizabethlc-lausd-ca.schoolloop.com/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo8} alt="GC" className="image-full-auto shrink-width" />
                  </a>
                </div>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://www.clevelandhs.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo7} alt="GC" className="image-full-auto shrink-width" />
                  </a>
                </div>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://www.americaneedsyou.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo6} alt="GC" className="image-full-auto shrink-width" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex-container-mobile-column">
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://www.davincischools.org/schools/da-vinci-rise/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo5} alt="GC" className="image-full-auto shrink-width"/>
                  </a>
                </div>
              </div>
              <div className="flex-20 box">
                <a href="https://www.downtownmagnets.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo4} alt="GC" className="image-full-auto shrink-width"/>
                </a>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://www.banninghs.org/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo3} alt="GC" className="image-full-auto shrink-width"/>
                  </a>
                </div>
              </div>
              <div className="flex-20 box top-margin-100-desktop">
                <a href="http://www.bixelexchange.info/latechtalent" className="background-link" target="_blank" rel="noopener noreferrer">
                  <img src={clientLogo2} alt="GC" className="image-full-auto shrink-width"/>
                </a>
              </div>
              <div className="flex-20 box">
                <div className="padding-20">
                  <a href="https://www.narbonnehsgauchos.com/" className="background-link" target="_blank" rel="noopener noreferrer">
                    <img src={clientLogo1} alt="GC" className="image-full-auto shrink-width"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default Clients
