import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

// const sendIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-blue.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 1.2s ease-in',
};

class ConfirmEmail extends Component {
    constructor(props) {
      super(props)
      this.state = {

      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.submitCode = this.submitCode.bind(this)
      this.resendCode = this.resendCode.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called')

      // document.body.style.backgroundColor = "linear-gradient(to right, #87CEFA, white)";
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      const accountCode = this.props.accountCode
      console.log('show accountCode: ', accountCode)
      this.setState({ emailId: email, activeOrg, username, orgFocus, accountCode })

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgLogoURI = response.data.orgInfo.webLogoURI
            const headerImageURL = response.data.orgInfo.headerImageURL
            const orgFocus = response.data.orgInfo.orgFocus

            this.setState({ orgName, orgLogoURI, headerImageURL, orgFocus });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      if (this.props.confirmed) {

        // save user status change and send welcome email
        Axios.get('/api/users/profile/details/' + this.props.email)
        .then((response) => {
          console.log('Profile info query attempted in studentDetails', response.data);

          if (response.data.success) {
            console.log('profile info query worked')

            const firstName = response.data.user.firstName
            const lastName = response.data.user.lastName
            const roleName = response.data.user.roleName
            const emailConfirmed = response.data.user.emailConfirmed
            const workMode = response.data.user.workMode

            if (!emailConfirmed) {

              Axios.get('/api/emails', { params: { orgCode: activeOrg } })
              .then((response) => {
                console.log('Emails query attempted', response.data);

                  if (response.data.success) {
                    console.log('emails query worked')

                    const studentBenefits = response.data.benefits[0].studentBenefits
                    const teacherBenefits = response.data.benefits[0].teacherBenefits
                    const mentorBenefits = response.data.benefits[0].mentorBenefits
                    const employerBenefits = response.data.benefits[0].employerBenefits

                    Axios.get('/api/org', { params: { orgCode: activeOrg } })
                    .then((response) => {
                      console.log('Org info query attempted', response.data);

                        if (response.data.success) {
                          console.log('org info query worked')

                          email = email.toLowerCase()
                          const orgName = response.data.orgInfo.orgName
                          const orgContactFirstName = response.data.orgInfo.contactFirstName
                          const orgContactLastName = response.data.orgInfo.contactLastName
                          const orgContactEmail = response.data.orgInfo.contactEmail
                          const headerImageURL = response.data.orgInfo.headerImageURL

                          let benefits = undefined
                          let isAdvisor = false
                          let isOrganization = false
                          let isEmployer = false
                          if (roleName === 'Student') {
                            benefits = studentBenefits
                          } else if (roleName === 'Teacher') {
                            benefits = teacherBenefits
                            isAdvisor = true
                          } else if (roleName === 'Admin' || roleName === 'admin') {
                            isOrganization = true
                          } else if (roleName === 'Mentor') {
                            benefits = mentorBenefits
                            isAdvisor = true
                          } else if (roleName === 'Employer') {
                            benefits = employerBenefits
                            isEmployer = true
                          }

                          if (benefits) {
                            for (let i = 1; i <= benefits.length; i++) {
                              benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
                            }
                          }

                          const updatedAt = new Date()

                          // save user status change and send welcome email
                          Axios.post('/api/email-confirmed', {
                            firstName,lastName, email, orgName,
                            orgContactFirstName, orgContactLastName, orgContactEmail,
                            activeOrg, roleName, updatedAt, benefits, headerImageURL, isAdvisor, isOrganization, isEmployer
                          }).then((response) => {

                            if (response.data.success) {
                              //save values
                              console.log('Email confirmed save worked ', response.data);

                              this.setState({ ready: true, workMode })

                            } else {
                              console.log('email confirmed did not save successfully')
                              this.setState({ serverErrorMessage: response.data.message })
                            }

                          }).catch((error) => {
                              console.log('Email confirmed save did not work', error);
                              this.setState({ serverErrorMessage: error })
                          });

                        } else {
                          console.log('org info query did not work', response.data.message)
                          //don't allow signups without an org affiliation
                          this.setState({ error: { message: 'There was an error finding the organization' }, isWaiting: false })
                        }

                    }).catch((error) => {
                        console.log('Org info query did not work for some reason', error);
                    });

                  } else {
                    console.log('emails query did not work', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Emails query did not work for some reason', error);
              });
            } else {
              this.setState({ ready: true })
            }

          } else {
            console.log('profile query did not work', response.data.message)

          }
        }).catch((error) => {
            console.log('Profile query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })

    }

    submitCode() {
      console.log('submitCode called')


    }

    resendCode() {
      console.log('resendCode called')


    }

    render() {

      let startLink = '/app/dashboard'
      if (window.location.pathname.includes('/advisor')) {
        startLink = '/advisor/dashboard'
      } else if (window.location.pathname.includes('/app')) {
        if (this.props.orgCode === 'unite-la') {
          if (this.state.workMode) {
            startLink = '/app'
          } else {
            startLink = '/app/walkthrough'
          }
        }
      } else if (window.location.pathname.includes('/organizations')) {
        startLink = '/organizations/' + this.state.activeOrg + '/dashboard'
      } else if (window.location.pathname.includes('/employers')) {
        startLink = '/employers/' + this.state.accountCode + '/dashboard'
      }

      return (
        <div>
          {(this.props.confirmed) ? (
            <div>
              {(this.state.ready) ? (
                <div>
                  <img src={checkboxChecked} alt="GC" className="image-auto-200 center-horizontally"/>
                  <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                  <p className="heading-text-2">Email Confirmed!</p>
                  <div className="spacer"/><div className="half-spacer"/>

                  <p>Your email account has been verified.</p>
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                  <Link to={startLink} className="btn btn-primary">Get Started</Link>
                </div>
              ) : (
                <div className="full-space center-text flex-container flex-center">
                  <div>
                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                    <p className="center-text cta-color bold-text" >Confirming email...</p>

                  </div>
                </div>
              )}
            </div>
          ) : (
            <header className={(this.props.modalIsOpen) ? "" : "full-page-background-image"}>
              {(window.innerWidth <= 768) ? (
                <div>
                  <div className="relative-column-20">
                    <div className="width-50 height-40" />
                  </div>
                  <div className="relative-column-60">
                    <div className="flex-container row-direction flex-center">
                      <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                      )}
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                      )}
                      {/*
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <div style={{ backgroundColor: 'purple'}}>
                          <Link to={'/'}>
                            <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                          </Link>
                          <Link to={'/'}>
                            <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                          </Link>

                          <Link to={'/'}>
                            <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                            <img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-36" />
                            <div className="vertical-spacer" /><div className="vertical-half-spacer" />
                            <img src={this.state.orgLogoURI} alt="Compass logo" className="logo" style={{ height: '40px', width: 'auto', marginTop: '25px' }}/>
                            <div className="clear" />
                          </Link>
                        </div>
                      )}*/}
                    </div>
                  </div>
                  <div className="relative-column-20">
                    {(this.state.courseName) && (
                      <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                        <div className="spacer" /><div className="half-spacer" />
                        <label className="description-text-2 right-text white-text">Course:</label>
                        <div className="clear" />
                        <label className="right-text white-text">{this.state.courseName}</label>
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                  {/*
                  <div className="row-10 full-width left-padding-30">
                    <Link to={'/'}><img src={logoImg} alt="Compass logo" className="logo float-left"/></Link>
                    {(this.state.orgCode && this.state.orgLogoURI) && (
                      <div>
                        <Link to={'/'}>
                          <div className="vertical-spacer" />
                          <img src={addIcon} alt="Compass logo" className="image-auto-14 float-left row-15" />
                          <div className="vertical-spacer" />
                          <img src={this.state.orgLogoURI} alt="Partner logo" className="logo float-left" />
                        </Link>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                  <div className="row-10 full-width left-padding-30">
                    {(this.state.courseName) && (
                      <div className="float-left full-width">
                        <label className="description-text-2 white-text">Course:</label>
                        <div className="clear" />
                        <label className="white-text">{this.state.courseName}</label>
                      </div>
                    )}
                    <div className="clear" />
                  </div>*/}
                </div>
              ) : (
                <div className="row full-width">
                  <div className="relative-column-20">
                    <div className="width-50 height-40" />
                  </div>
                  <div className="relative-column-60">
                    <div className="flex-container row-direction flex-center">
                      <Link to={'/'} className="clear-border"><img src={logoImg} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25" /></Link>
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <Link to={'/'} className="clear-border"><img src={addIcon} alt="Compass logo" className="image-auto-16 top-margin-25 horizontal-padding-4" /></Link>
                      )}
                      {(this.state.orgCode && this.state.orgLogoURI) && (
                        <Link to={'/'} className="clear-border"><img src={this.state.orgLogoURI} alt="Compass logo" className="horizontal-padding-4 image-auto-30 top-margin-25"/></Link>
                      )}
                    </div>
                  </div>
                  <div className="relative-column-20">
                    {(this.state.courseName) && (
                      <div className="calc-column-offset-340 horizontal-padding-4 right-text">
                        <div className="spacer" /><div className="half-spacer" />
                        <label className="description-text-2 right-text white-text">Course:</label>
                        <div className="clear" />
                        <label className="right-text white-text">{this.state.courseName}</label>
                      </div>
                    )}
                  </div>

                  <div className="clear" />
                </div>
              )}

              <div className="login-container standard-border" style={{...styles, opacity: this.state.opacity, height: 'auto', paddingBottom: '50px' }}>
                <h2 className="login-title normal-weight">Verify Your Identity</h2>

                <div className="padding-40">
                  <div className="row-10">
                    <p>A verification code has been sent to your email. Enter the code to continue.</p>
                  </div>

                  <div className="row-10">
                    <input className="number-field" type="number" placeholder="e.g. 123..." name="verificationCode" value={this.state.verificationCode} onChange={this.formChangeHandler}/>
                  </div>

                  <div className="row-15">
                    <button className={(this.state.verificationCode) ? "btn btn-squarish" : "btn btn-squarish wash-out-2"} disabled={(this.state.verificationCode) ? false : true} onClick={() => this.submitCode()}>Continue</button>
                  </div>

                  <div className="row-5">
                    <button className="background-button cta-color bold-text" onClick={() => this.resendCode()}>Resend code ></button>
                  </div>

                  <div className="top-padding-20">
                    <p>Need help? <Link to="/contact" target="_blank">Contact us</Link></p>
                  </div>
                </div>

              </div>
            </header>
          )}


        </div>
      )
    }
}

export default withRouter(ConfirmEmail);
