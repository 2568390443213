import React, { Component} from 'react';
import LoginForm from '../Subcomponents/LoginForm';
import withRouter from '../Functions/WithRouter';

class OrgSignUp extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
        console.log('sign up page is mounting')

        //user navigated from a landing page or from the app
        const { org } = this.props.params
        this.setState({ org })
    }

    render() {

      return (
        <div>
          <LoginForm roleName="Admin" orgCode={this.state.org} courseId={this.state.courseId} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignUp" />
        </div>
      )
    }
}

export default withRouter(OrgSignUp)
