import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import EditCourse from './EditCourse';
import withRouter from '../Functions/WithRouter';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class AddMembers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emails: '',

            emailId: '',
            username: '',
            cuFirstName: '',
            cuLastName: '',

            requests: [],
            org: '',

            courseOptions: [],
            gradYearOptions: [],
            categoryOptions: [],
            roleNameOptions: ['','Teacher','School Support','Counselor','Work-Based Learning Coordinator'],

            employers: [],
            selectedCourse: { name: ''},
            memberRoleName: 'Teacher',
            selectedCourses: [],

            recipientErrorMessage: '',
            serverPostSuccess: false,
            serverSuccessMessage: '',
            serverErrorMessage: ''
        }

        this.retrieveData = this.retrieveData.bind(this);
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)

        this.addNewCourse = this.addNewCourse.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      const studentAliasSingular = localStorage.getItem('studentAliasSingular');
      const studentAliasPlural = localStorage.getItem('studentAliasPlural');
      const mentorAliasSingular = localStorage.getItem('mentorAliasSingular');
      const mentorAliasPlural = localStorage.getItem('mentorAliasPlural');

      const path = window.location.pathname

      // const categoryOptions = ['Builder','Founder']

      this.setState({ emailId: email, username, cuFirstName, cuLastName, path,
        org, roleName, orgFocus, studentAliasSingular, studentAliasPlural,
        mentorAliasSingular, mentorAliasPlural
      })

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','High School Graduate', 'All Grade Levels']
          const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

          let functionOptions = response.data.workOptions[0].functionOptions
          let industryOptions = response.data.workOptions[0].industryOptions

          this.setState({ gradeLevelOptions, knowledgeLevelOptions, functionOptions, industryOptions })
        }
      });

      //
      // if (this.props.userId) {
      //
      //   const userId = this.props.userId
      //
      //   Axios.get('/api/users/profile/details', { params: { userId } })
      //   .then((response) => {
      //     console.log('User profile query attempted', response.data);
      //
      //     if (response.data.success) {
      //       console.log('successfully retrieved profile', this.props.type)
      //
      //       if (this.props.type === 'Educators') {
      //         const memberFirstName = response.data.user.memberFirstName
      //         const memberLastName = response.data.user.memberLastName
      //         const memberEmail = response.data.user.memberEmail
      //         const memberRoleName = response.data.user.memberRoleName
      //         const schoolName = response.data.user.school
      //         const courseIds = response.data.user.courseIds
      //
      //         this.setState({ memberFirstName, memberLastName, memberEmail, memberRoleName, schoolName, courseIds })
      //       }
      //
      //     } else {
      //       console.log('user profile data found', response.data.message)
      //     }
      //
      //   }).catch((error) => {
      //       console.log('User profile query did not work', error);
      //   });
      // }

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgContactFirstName = response.data.orgInfo.contactFirstName
            const orgContactLastName = response.data.orgInfo.contactLastName
            const orgContactEmail = response.data.orgInfo.contactEmail
            const headerImageURL = response.data.orgInfo.headerImageURL

            this.setState({ orgName, orgContactFirstName, orgContactLastName, orgContactEmail, headerImageURL });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      Axios.get('/api/emails', { params: { orgCode: org } })
      .then((response) => {
        console.log('Emails query attempted', response.data);

          if (response.data.success) {
            console.log('emails query worked')

            const studentBenefits = response.data.benefits[0].studentBenefits
            const teacherBenefits = response.data.benefits[0].teacherBenefits
            const mentorBenefits = response.data.benefits[0].mentorBenefits
            const employerBenefits = response.data.benefits[0].employerBenefits

            this.setState({ studentBenefits, teacherBenefits, mentorBenefits, employerBenefits });

          } else {
            console.log('emails query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Emails query did not work for some reason', error);
      });

      if (this.props.type === 'Educators') {

        Axios.get('/api/courses', { params: { orgCode: org } })
        .then((response) => {

            if (response.data.success) {
              console.log('Course details query worked', response.data);

              if (response.data.courses) {
                let courseOptions = response.data.courses
                courseOptions.unshift({ name: ''})
                courseOptions.push({ name: 'Add New Course'})
                this.setState({ courseOptions })
              }
            } else {
              console.log('no courses found', response.data.message)

              let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
              this.setState({ courseOptions })
            }

        }).catch((error) => {
            console.log('Course query did not work', error);
            let courseOptions = [{ name: ''},{ name: 'Add New Course'}]
            this.setState({ courseOptions })
        });
      } else {
        if (roleName === 'Teacher' || roleName === 'School Support' ) {
          // pull students within courses where user is a teacher

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {

              if (response.data.success) {
                console.log('User profile query worked', response.data);

                const courseIds = response.data.user.courseIds

                if (courseIds && courseIds.length > 0) {
                  console.log('there are course on the profile')

                  //need to pull course details
                  Axios.get('/api/courses', { params: { courseIds } })
                  .then((response) => {

                      if (response.data.success) {
                        console.log('Course details query worked', response.data);

                        if (response.data.courses) {
                          let courseOptions = response.data.courses
                          courseOptions.unshift({ name: ''})
                          this.setState({ courseIds, courseOptions })
                        }
                      } else {
                        console.log('no courses found', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('Course query did not work', error);
                  });

                }

              } else {
                console.log('no user details found', response.data.message)

              }

          }).catch((error) => {
              console.log('User profile query did not work', error);
          });

        }
      }
    }

    formChangeHandler(event) {

      if (event.target.name === 'emails') {

        this.setState({ emails: event.target.value })
      } else if (event.target.name === 'courseId') {

        let selectedIndex = null
        for (let i = 1; i <= this.state.courseOptions.length; i++) {
          if (event.target.value === this.state.courseOptions[i - 1].name) {
            selectedIndex = i - 1
          }
        }
        const selectedCourseId = event.target.value
        this.setState({ selectedCourseId, selectedIndex })
      } else if (event.target.name === 'course') {

        let selectedCourse = null
        for (let i = 1; i <= this.state.courseOptions.length; i++) {
          if (event.target.value === this.state.courseOptions[i - 1].name) {
            selectedCourse = this.state.courseOptions[i - 1]
          }
        }

        this.setState({ selectedCourse })
      } else if (event.target.name === 'memberFirstName') {
        this.setState({ memberFirstName: event.target.value })
      } else if (event.target.name === 'memberLastName') {
        this.setState({ memberLastName: event.target.value })
      } else if (event.target.name === 'memberEmail') {
        this.setState({ memberEmail: event.target.value })
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value })
      } else if (event.target.name === 'employerName') {
        const employerName = event.target.value

        let accountCode = ''
        if (employerName && employerName !== '') {
          let trimmedName = employerName.trim()
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }

        this.setState({ employerName, accountCode })

        this.searchEmployers(employerName)

      } else if (event.target.name === 'association') {
        this.setState({ association: event.target.value })
      } else if (event.target.name === 'memberRoleName') {
        this.setState({ memberRoleName: event.target.value })
      } else if (event.target.name === 'schoolName') {
        this.setState({ schoolName: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            this.setState({ employers });

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      this.setState({ employerName: employer.employerName, employers: [], accountCode: employer.accountCode })
    }

    handleSubmit(event) {

      console.log('handleSubmit called', this.state.emails)

      const emailId = this.state.emailId
      const cuFirstName = this.state.cuFirstName
      const cuLastName = this.state.cuLastName

      if (this.props.type && this.props.type === 'Mentors') {
        if (!this.state.memberFirstName || this.state.memberFirstName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's first name"})
        } else if (!this.state.memberLastName || this.state.memberLastName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's last name"})
        } else if (!this.state.memberEmail || this.state.memberEmail === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's email"})
        } else if (!this.state.memberEmail.includes('@')) {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add a valid email"})
        } else if (!this.state.jobTitle || this.state.jobTitle === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's job title"})
        } else if (!this.state.employerName || this.state.employerName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's employer name"})
        } else {
          console.log('add this mentor with a temporary password')

          const delegateEmail = emailId

          const firstName = this.state.memberFirstName
          const lastName = this.state.memberLastName
          const email = this.state.memberEmail
          const password = this.state.org
          const jobTitle = this.state.jobTilte
          const employerName = this.state.employerName
          const accountCode = this.state.accountCode
          const association = this.state.association
          const orgName = this.state.orgName
          const orgContactFirstName = this.state.orgContactFirstName
          const orgContactLastName = this.state.orgContactLastName
          const orgContactEmail = this.state.orgContactEmail
          const headerImageURL = this.state.headerImageURL

          const myOrgs = [this.state.org]
          const activeOrg = this.state.org
          const roleName = 'Mentor'
          let benefits = this.state.mentorBenefits
          for (let i = 1; i <= benefits.length; i++) {
            benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          Axios.post('/api/members/delegate-register', {
            delegateEmail, firstName, lastName, email, password, jobTitle, employerName, accountCode, association, myOrgs, activeOrg,
            roleName, benefits, orgName, orgContactFirstName, orgContactLastName, orgContactEmail, headerImageURL,
            createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              this.setState({
                emails: '',

                memberFirstName: '', memberLastName: '', memberEmail: '', jobTitle: '', employerName: '', association: '',

                serverPostSuccess: true,
                serverSuccessMessage: 'New member has been created successfully!'
              })
            } else {

              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Admin invite did not work', error);
          });

        }
      } else if (this.props.type && this.props.type === 'Educators') {
        if (!this.state.memberFirstName || this.state.memberFirstName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's first name"})
        } else if (!this.state.memberLastName || this.state.memberLastName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's last name"})
        } else if (!this.state.memberEmail || this.state.memberEmail === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the educator's email"})
        } else if (!this.state.memberEmail.includes('@')) {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add a valid email"})
        } else if (this.state.memberRoleName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the the role name"})
        } else if (!this.state.schoolName || this.state.schoolName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the the school name"})
        } else {
          console.log('add this mentor with a temporary password')
          //assume teachers

          const delegateEmail = emailId

          const firstName = this.state.memberFirstName
          const lastName = this.state.memberLastName
          const email = this.state.memberEmail
          const password = this.state.org

          //roleName, schoolName, selectedCourses
          const roleName = this.state.memberRoleName
          const school = this.state.schoolName
          let courseIds = []
          if (this.state.selectedCourses && this.state.selectedCourses.length > 0) {
            console.log('in first', this.state.selectedCourses)
            for (let i = 1; i <= this.state.selectedCourses.length; i++) {
              console.log('in second', this.state.selectedCourses[i - 1]._id)
              courseIds.push(this.state.selectedCourses[i - 1]._id)
              console.log('in third', this.state.selectedCourses[i - 1]._id)
            }
          }
          console.log('show courseIds: ',courseIds)

          const orgName = this.state.orgName
          const orgContactEmail = this.state.orgContactEmail
          const headerImageURL = this.state.headerImageURL

          const myOrgs = [this.state.org]
          const activeOrg = this.state.org

          let benefits = this.state.teacherBenefits
          if (benefits) {
            for (let i = 1; i <= benefits.length; i++) {
              benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
            }
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          Axios.post('/api/members/delegate-register', {
            delegateEmail, firstName, lastName, email, password, school, courseIds, myOrgs, activeOrg,
            roleName, benefits, orgName, orgContactEmail, headerImageURL, createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              this.setState({
                emails: '',

                memberFirstName: '', memberLastName: '', memberEmail: '',
                schoolName: '', selectedCourses: [],

                serverPostSuccess: true,
                serverSuccessMessage: 'New member has been created successfully!'
              })
            } else {

              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Admin invite did not work', error);
          });
        }
      } else if (this.props.type && this.props.type === 'Students') {
        if (!this.state.memberFirstName || this.state.memberFirstName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's first name"})
        } else if (!this.state.memberLastName || this.state.memberLastName === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's last name"})
        } else if (!this.state.memberEmail || this.state.memberEmail === '') {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add the mentor's email"})
        } else if (!this.state.memberEmail.includes('@')) {
          this.setState({ serverPostSuccess: false, serverErrorMessage: "Please add a valid email"})
        } else {
          console.log('add this mentor with a temporary password')

          const delegateEmail = emailId

          const firstName = this.state.memberFirstName
          const lastName = this.state.memberLastName
          const email = this.state.memberEmail
          const password = this.state.org
          const jobTitle = this.state.jobTilte
          const employerName = this.state.employerName
          const accountCode = this.state.accountCode
          const orgName = this.state.orgName
          const orgContactEmail = this.state.orgContactEmail
          const headerImageURL = this.state.headerImageURL

          const myOrgs = [this.state.org]
          const activeOrg = this.state.org
          let roleName = 'Student'
          let benefits = this.state.studentBenefits
          for (let i = 1; i <= benefits.length; i++) {
            benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          Axios.post('/api/members/delegate-register', {
            delegateEmail, firstName, lastName, email, password, accountCode, myOrgs, activeOrg, jobTitle, employerName,
            roleName, benefits, orgName, orgContactEmail, headerImageURL, createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              this.setState({
                emails: '',

                memberFirstName: '', memberLastName: '', memberEmail: '',

                serverPostSuccess: true,
                serverSuccessMessage: 'New member has been created successfully!'
              })
            } else {

              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Admin invite did not work', error);
          });
        }
      } else {
        // invite members in general
        if (this.state.emails.length === 0) {
          this.setState({ recipientErrorMessage: 'please add at least one recipient email' })
        } else {

            //format email array
            let removedCommas = this.state.emails.replace(/,/g, '');
            let removedSpaces = removedCommas.trim();
            let recipientEmails = removedSpaces.split(/\r\n|\r|\n/g);

            for (let i = 1; i <= recipientEmails.length; i++) {
              console.log(i);

              if (!recipientEmails[i - 1].includes('@')) {
                this.setState({ recipientErrorMessage: 'email invalid. please enter a valid email for email #' + i })
                return
              }
            }

            let createdAt = new Date();
            let updatedAt = createdAt

            Axios.post('/api/admins/invite', {
              senderEmail: emailId, senderFirstName: cuFirstName, senderLastName: cuLastName,
              recipientEmails: recipientEmails, recipientType: 'Advisee', active: false,
              joined: false, orgCode: this.state.org, createdAt: createdAt, updatedAt: updatedAt })
            .then((response) => {

              if (response.data.success) {
                //clear values
                this.setState({
                  emails: '',

                  serverPostSuccess: true,
                  serverSuccessMessage: 'Invite(s) sent successfully!'
                })
              } else {

                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: response.data.message,
                })
              }
            }).catch((error) => {
                console.log('Admin invite did not work', error);
            });
        }
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    addItem() {
      console.log('addItem called')

      let alreadyAdded = false
      for (let i = 1; i <= this.state.selectedCourses.length; i++) {
        if (this.state.selectedCourses[i - 1]._id === this.state.selectedCourse._id) {
          alreadyAdded = true
        }
      }

      if (this.state.selectedCourse && this.state.selectedCourse.name === 'Add New Course') {
        console.log('add new course')

        this.setState({ modalIsOpen: true, showEditCourse: true, associationInfo: false, courseInfo: false })

      } else if (alreadyAdded === false && this.state.selectedCourse._id) {
        let selectedCourses = this.state.selectedCourses
        let selectedCourse = this.state.selectedCourse
        selectedCourses.push(selectedCourse)
        selectedCourse = { name: ''}
        this.setState({ selectedCourses, selectedCourse, formHasChanged: true })
      } else {
        console.log('already added')
      }
    }

    removeItem(index) {
      console.log('removeItem called', index)

      let selectedCourses = this.state.selectedCourses
      selectedCourses.splice(index, 1)
      this.setState({ selectedCourses, formHasChanged: true })
    }

    addNewCourse(courseObject) {
      console.log('addNewCourse called', courseObject)

      const course = courseObject

      let courseOptions = this.state.courseOptions
      courseOptions.splice(courseOptions.length - 2,0,courseObject)

      console.log('compare course: ', course, courseOptions)
      this.setState({ courseOptions, course })
    }

    render() {

      let mentorOnboardingLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/mentor/join"
      const studentOnboardingLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/student/join"
      let memberRoleName = ''
      let educatorOnboardingLink = ''
      if (this.state.memberRoleName) {
        memberRoleName = this.state.memberRoleName.toLowerCase().replace(/ /g, "")
        educatorOnboardingLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/" + memberRoleName + "/join"
      }

      console.log('show courseLink: ', this.state.selectedCourse)

      let courseLink = ''
      if (this.state.selectedCourseId && this.state.selectedIndex) {
        courseLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/courses/" + this.state.courseOptions[this.state.selectedIndex]._id + "/student/join"
      } else if (this.props.type === 'Educators' && this.state.selectedCourse._id) {
        if (this.state.memberRoleName) {
          memberRoleName = this.state.memberRoleName.toLowerCase().replace(/ /g, "")
        }

        courseLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/courses/" + this.state.selectedCourse._id + "/" + memberRoleName + "/join"
      }

      let pageTitle = ''
      if (this.props.type && this.props.type === 'All') {
        pageTitle = 'Invite People to ' + this.state.orgName
      } else if (this.props.type && this.props.type === 'Mentors') {
        pageTitle = 'Add Mentors'
        if (this.state.mentorAliasPlural) {
          pageTitle = 'Add ' + this.state.mentorAliasPlural
        }
      } else if (this.props.type && this.props.type === 'Students') {
        pageTitle = 'Add Students'
        if (this.state.studentAliasPlural) {
          pageTitle = 'Add ' + this.state.studentAliasPlural
        }
      } else if (this.props.type && this.props.type === 'Educators') {
        pageTitle = 'Add Educators'
      }

      if (this.state.path && this.state.path.includes('/advisor')) {
        if (this.state.orgFocus === 'Placement') {
          mentorOnboardingLink = window.location.protocol + "//" + window.location.host + "/organizations/" + this.state.org + "/mentor/join"
        } else {
          mentorOnboardingLink = window.location.protocol + "//" + window.location.host + "/schools/" + this.state.org + "/mentor/join"
        }
      }

      return (
          <div>
              <div className="standard-container">

              <div>
                <p className="heading-text-2">{pageTitle}</p>
              </div>

              {(this.props.type && this.props.type === 'All') && (
                <div>
                  <div className="toggle-content">
                    <div className="add-advisees-container">
                      <div className="half-spacer" />
                      <p className="description-text-1">Add as many emails as you like. Paste from a spreadsheet or hit return for each email.</p>
                        <div className="app-row">

                            <br/>
                            <textarea type="text" className="text-field" placeholder="Paste emails from spreadsheet like so:&#13;&#10;email1@gmail.com&#13;&#10;email2@gmail.com&#13;&#10;email3@gmail.com" name="emails" value={this.state.emails} onChange={this.formChangeHandler}/>
                            <p className="error-message">{this.state.recipientErrorMessage}</p>
                        </div>
                    </div>
                    <div className="toggle-cta-container">
                        <button className="btn btn-primary" onClick={this.handleSubmit}>Send</button>
                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <p className="error-message">{this.state.serverErrorMessage}</p>
                        )}
                    </div>
                  </div>
                </div>
              )}

              {(this.props.type && this.props.type === 'Mentors') && (
                <div>
                  <div>
                    {(this.state.org) ? (
                      <div className="edit-profile-row">
                        <label className="profile-label">Share this link with {(this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "mentors"} for them to sign up:</label>
                        <a href={mentorOnboardingLink} target="_blank">{mentorOnboardingLink}</a>

                        <div className="spacer" />

                        <div className="clear" />
                      </div>
                    ) :(
                      <div className="edit-profile-row">
                        <label className="profile-label">An org code required to invite mentors has not been created yet for your organization. You must have at least one student license to have a reserved org code. Please contact help@guidedcompass.com to purchase student licenses.</label>
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="spacer" /><div className="spacer" />
                  <hr className="clear-margin clear-padding"/>
                  <div className="clear" />

                  <div className="spacer" /><div className="spacer" />

                  <div className="edit-profile-row">
                    <p className="heading-text-5">Sign Up {(this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "Mentor"} On His/Her Behalf Manually</p>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">First Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberFirstName"
                      placeholder="First name.." value={this.state.memberFirstName}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Last Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberLastName"
                        placeholder="Last name..." value={this.state.memberLastName}
                        onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Email<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberEmail"
                      placeholder="Email.." value={this.state.memberEmail}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Temporary Password</label>
                      <p className="heading-text-5">{this.state.org}</p>
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Job Title<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="jobTitle"
                      placeholder="Job title..." value={this.state.jobTitle}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Employer Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="employerName"
                        placeholder="Employer name..." value={this.state.employerName}
                        onChange={this.formChangeHandler}
                      />
                      {(this.state.employers.length > 0) && (
                        <div>
                          <div className="spacer" />
                          {this.state.employers.map(value =>
                            <div key={value._id} className="left-text">
                              <button className="background-button" onClick={() => this.employerClicked(value)}>
                                <div>
                                  <div className='float-left right-padding'>
                                    <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                                  </div>
                                  <div className="float-left">
                                    <p className="cta-color">{value.employerName}</p>
                                  </div>
                                </div>
                              </button>
                              <div className="half-spacer" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <div className="float-left">
                        <label className="profile-label">Mentoring Association Name (Optional)</label>
                      </div>

                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, associationInfo: true, courseInfo: false, showEditCourse: false,  })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>

                      <div className="clear" />

                      <input className="text-field" type="text" name="association"
                      placeholder="Add mentoring association..." value={this.state.association}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div>
                    <p className="description-text-1">Note: this user will receive an automatated email confirmation with a link and a temporary password for if they choose to log in.</p>
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  <button className="btn btn-primary" onClick={this.handleSubmit}>Create New User</button>
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}
                </div>
              )}

              {(this.props.type && this.props.type === 'Educators') && (
                <div>
                  <div>
                    {(this.state.org) ? (
                      <div className="edit-profile-row">
                        <label className="profile-label">Share this link with educators for them to sign up:</label>
                        <a href={educatorOnboardingLink} target="_blank">{educatorOnboardingLink}</a>

                        <div className="spacer" />

                        <div className="clear" />
                      </div>
                    ) :(
                      <div className="edit-profile-row">
                        <label className="profile-label">An org code required to invite educators has not been created yet for your organization. You must have at least one student license to have a reserved org code. Please contact help@guidedcompass.com to purchase student licenses.</label>
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="spacer" /><div className="spacer" />
                  <hr className="clear-margin clear-padding"/>
                  <div className="clear" />

                  {(this.state.courseOptions) && (
                    <div>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <div className="edit-profile-row">
                        <p className="heading-text-5">Invite Educators</p>
                      </div>

                      <div className="edit-profile-row">
                        <div className="container-left">
                          <label className="profile-label">Your Courses<label className="error-color bold-text">*</label></label>
                          <select name="course" className="dropdown" value={this.state.selectedCourse.name} onChange={this.formChangeHandler}>
                              {this.state.courseOptions.map(value => <option key={value._id} value={value.name}>{value.name}</option>)}
                          </select>
                        </div>
                        {/*
                        <div className="container-left">
                          <label className="profile-label">Role Name<label className="error-color">*</label></label>
                          <select name="memberRoleName" className="dropdown" value={this.state.memberRoleName} onChange={this.formChangeHandler}>
                              {this.state.roleNameOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>*/}
                        <div className="clear" />

                      </div>

                      <label className="profile-label">Share this link with educators for them to sign up to <label className="half-bold-text">your course:</label></label>
                      <a href={courseLink} target="_blank">{courseLink}</a>

                      <div className="spacer" /><div className="spacer" />
                      <hr className="clear-margin clear-padding"/>

                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />

                  <div className="edit-profile-row">
                    <p className="heading-text-5">Sign Up Educator On His/Her Behalf Manually</p>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">First Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberFirstName"
                      placeholder="First name.." value={this.state.memberFirstName}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Last Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberLastName"
                        placeholder="Last name..." value={this.state.memberLastName}
                        onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Email<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="memberEmail"
                      placeholder="Email.." value={this.state.memberEmail}
                      onChange={this.formChangeHandler}
                      />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Temporary Password</label>
                      <p className="heading-text-5">{this.state.org}</p>
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    {/*
                    <div className="name-container">
                      <label className="profile-label">Role Name<label className="error-color">*</label></label>
                      <select name="memberRoleName" className="dropdown" value={this.state.memberRoleName} onChange={this.formChangeHandler}>
                          {this.state.roleNameOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>*/}
                    <div className="name-container">
                      <label className="profile-label">School Name<label className="error-color">*</label></label>
                      <input className="text-field" type="text" name="schoolName"
                        placeholder="School name..." value={this.state.schoolName}
                        onChange={this.formChangeHandler}
                      />

                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="container-left">
                      <div className="float-left">
                        <label className="profile-label">Associated Courses (Optional)</label>
                      </div>

                      <div>
                        <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, associationInfo: false, courseInfo: true, showEditCourse: true })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>

                      <div className="clear" />
                    </div>
                    <div className="clear"/>

                    <div className="container-left">
                      <select name="course" className="dropdown" value={this.state.selectedCourse.name} onChange={this.formChangeHandler}>
                          {this.state.courseOptions.map(value => <option key={value._id} value={value.name}>{value.name}</option>)}
                      </select>
                    </div>
                    <div className="container-right">
                      <button className="btn btn-squarish" onClick={() => this.addItem() }>Add</button>
                    </div>
                    <div className="clear"/>

                    {this.state.selectedCourses.map((value, optionIndex) =>
                      <div key={value.name + "|" + optionIndex} className="float-left">
                        <div className="close-button-container-1" >
                          <button className="background-button" onClick={() => this.removeItem(value, optionIndex)}>
                            <img src={deniedIcon} alt="Compass target icon" className="image-auto-20"/>
                          </button>
                        </div>
                        <div className="float-left right-padding-5">
                          <div className="half-spacer" />
                          <div className="tag-container-inactive">
                            <p className="description-text-2">{value.name}</p>
                          </div>
                          <div className="half-spacer" />
                        </div>
                      </div>
                    )}

                    <div className="clear" />

                  </div>

                  <div>
                    <p className="description-text-1">Note: this user will receive an automatated email confirmation with a link and a temporary password for if they choose to log in.</p>
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  <button className="btn btn-primary" onClick={this.handleSubmit}>Create New User</button>
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}
                </div>
              )}

              {(this.props.type && this.props.type === 'Students') && (
                <div>
                  <div>
                    {(this.state.org) ? (
                      <div className="edit-profile-row">
                        <label className="profile-label">Share this link with students for them to sign up to <label className="half-bold-text">the organization:</label></label>
                        <a href={studentOnboardingLink} target="_blank">{studentOnboardingLink}</a>

                        <div className="spacer" />

                        {(this.state.courseIds) && (
                          <div>
                            <div className="spacer" /><div className="spacer" />
                            <hr className="clear-margin clear-padding"/>
                            <div className="spacer" /><div className="spacer" />

                            <div className="edit-profile-row">
                              <p>Your Courses<label className="error-color bold-text">*</label></p>
                              <select name={"courseId"} className="dropdown" value={this.state.selectedCourseId} onChange={this.formChangeHandler}>
                                  {this.state.courseOptions.map(value => <option key={value._id} value={value.name}>{value.name}</option>)}
                              </select>
                            </div>

                            <label className="profile-label">Share this link with students for them to sign up to <label className="half-bold-text">your course:</label></label>
                            <a href={courseLink} target="_blank">{courseLink}</a>

                            <div className="spacer" />
                          </div>
                        )}

                        {(this.state.roleName === 'Admin') && (
                          <div>
                            <div className="spacer" /><div className="spacer" />
                            <hr className="clear-margin clear-padding"/>
                            <div className="clear" />

                            <div className="spacer" /><div className="spacer" />

                            <div className="edit-profile-row">
                              <p className="heading-text-5">Sign Up {(this.state.studentAliasSingular) ? this.state.studentAliasSingular : "Student"} On His/Her Behalf Manually</p>
                            </div>

                            <div className="edit-profile-row">
                              <div className="name-container">
                                <label className="profile-label">First Name<label className="error-color">*</label></label>
                                <input className="text-field" type="text" name="memberFirstName"
                                placeholder="First name.." value={this.state.memberFirstName}
                                onChange={this.formChangeHandler}
                                />
                              </div>
                              <div className="name-container">
                                <label className="profile-label">Last Name<label className="error-color">*</label></label>
                                <input className="text-field" type="text" name="memberLastName"
                                  placeholder="Last name..." value={this.state.memberLastName}
                                  onChange={this.formChangeHandler}
                                />
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="edit-profile-row">
                              <div className="name-container">
                                <label className="profile-label">Email<label className="error-color">*</label></label>
                                <input className="text-field" type="text" name="memberEmail"
                                placeholder="Email.." value={this.state.memberEmail}
                                onChange={this.formChangeHandler}
                                />
                              </div>
                              <div className="name-container">
                                <label className="profile-label">Temporary Password</label>
                                <p className="heading-text-5">{this.state.org}</p>
                              </div>
                              <div className="clear"/>
                            </div>
                            {/*
                            <div className="edit-profile-row">
                              <div className="name-container">
                                <label className="profile-label">School Name<label className="error-color">*</label></label>
                                <input className="text-field" type="text" name="schoolName"
                                placeholder="School name..." value={this.state.schoolName}
                                onChange={this.formChangeHandler}
                                />
                              </div>
                              <div className="name-container">
                                <label className="profile-label">Grad Year<label className="error-color">*</label></label>
                                <select name={"course"} className="dropdown" value={this.state.gradYear} onChange={this.formChangeHandler}>
                                    {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                </select>
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="edit-profile-row">
                              <div className="name-container">
                                <label className="profile-label">Category<label className="error-color">*</label></label>
                                <select name={"category"} className="dropdown" value={this.state.category} onChange={this.formChangeHandler}>
                                    {this.state.categoryOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                </select>
                              </div>
                              <div className="name-container">
                                <label className="profile-label">Current Primary Venture Name<label className="error-color">*</label></label>
                                <input className="text-field" type="text" name="ventureName"
                                placeholder="Venture name..." value={this.state.ventureName}
                                onChange={this.formChangeHandler}
                                />
                              </div>
                              <div className="clear"/>
                            </div>*/}

                            <div>
                              <p className="description-text-1">Note: this user will receive an automatated email confirmation with a link and a temporary password for if they choose to log in.</p>
                            </div>

                            <div className="spacer" /><div className="spacer" />

                            <button className="btn btn-primary" onClick={this.handleSubmit}>Create New User</button>
                            { (this.state.serverPostSuccess) ? (
                              <p className="success-message">{this.state.serverSuccessMessage}</p>
                            ) : (
                              <p className="error-message">{this.state.serverErrorMessage}</p>
                            )}
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    ) :(
                      <div className="edit-profile-row">
                        <label className="profile-label">An org code required to invite mentors has not been created yet for your organization. You must have at least one student license to have a reserved org code. Please contact help@guidedcompass.com to purchase student licenses.</label>
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <hr className="cell-separator-advisor"/>
                </div>
              )}

              </div>

              {(this.state.showEditCourse) ? (
                <div>
                  <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedCourse={null} orgCode={this.state.org} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions} knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions} functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse}/>
                </div>
              ) : (
                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
               >
                 {(this.state.associationInfo) && (
                   <div key="submissionDetail" className="full-width">
                      <p className="heading-text-2 cta-color">Association Info</p>
                      <div className="spacer" />
                      <p>Were you affiliated with a mentoring group or association? If so, please indicate the name of the group for our records.</p>
                      <div className="spacer" />
                   </div>
                 )}

                 {(this.state.courseInfo) && (
                   <div key="submissionDetail" className="full-width">
                      <p className="heading-text-2 cta-color">Course Info</p>
                      <div className="spacer" />
                      <p>Does this educator teach or counsel in any specific courses that would you like to source project opportunities for? If so, please add!</p>
                      <div className="spacer" />
                   </div>
                 )}

                 <div className="row-20">
                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>

                </Modal>
              )}
          </div>

      )
    }
}


export default withRouter(AddMembers);
