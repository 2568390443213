import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import withRouter from './Functions/WithRouter';

class ProvideEndorsement extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      // let orgFocus = localStorage.getItem('orgFocus');
      const senderFirstName = cuFirstName
      const senderLastName = cuLastName
      const senderEmail = email

      const relationship = 'Classmate'

      this.setState({ email, username,
      cuFirstName, cuLastName, activeOrg, orgLogo,
      senderFirstName, senderLastName, senderEmail, relationship })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <p>Use AdvEndorsements</p>
              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(ProvideEndorsement)
