import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const mentoringIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-grey.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Sessions extends Component {
    constructor(props) {
      super(props)

      this.state = {
        sessions: [],
        resLimit: 500
      }

      this.retrieveData = this.retrieveData.bind(this)
      // this.sortSessions = this.sortSessions.bind(this)
      this.renderSessions = this.renderSessions.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subSessions', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subSessions')

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      if (email) {

        let linkPrefix = '/organizations/' + activeOrg
        if (window.location.pathname.startsWith('/advisor')) {
          linkPrefix = '/advisor'
        }
        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, linkPrefix })

        if (activeOrg) {

          const resLimit = this.state.resLimit

          //pull sessions
          Axios.get('/api/counseling/session', { params: { orgCode: activeOrg, resLimit } })
          .then((response) => {
            console.log('attempting to pull sessions')
              if (response.data.success) {
                console.log('Session query worked', response.data);

                const sessions = response.data.sessions
                this.setState({ sessions })

                // this.sortSessions(response.data.sessions)

              } else {
                console.log('no session data found', response.data.message)
                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: response.data.message,
                })
              }

          }).catch((error) => {
              console.log('Resource query did not work', error);
          });
        }
      }
    }

    // sortSessions(sessions) {
    //
    //   let upcomingSessions = []
    //   let pastSessions = []
    //
    //   for (let i = 1; i <= sessions.length; i++) {
    //     console.log(i)
    //
    //     let date = new Date(this.state.sessions[i - 1].sessionDate)
    //     let today = new Date()
    //     let difference = date - today
    //     let oneDay = 1000*60*60*24
    //     let dayDifference = Math.round(difference / oneDay)
    //     if ( dayDifference > 0 ) {
    //       upcomingSessions.push(this.state.sessions[i - 1])
    //     } else {
    //       pastSessions.push(this.state.sessions[i - 1])
    //     }
    //   }
    //
    //   this.setState({ upcomingSessions, pastSessions })
    // }

    renderUpcomingSessions() {
      console.log('renderUpcomingSessions called')

      let rows = [];
      if ( this.state.upcomingSessions.length !== 0 ) {

      rows.push(
          <div key={0}>
            <div className="card-subtitle-container">
              <p>{this.state.upcomingSessions.length} Upcoming</p>
            </div>
            <hr className="subtitle-separator" />
          </div>
        )

        for (let i = 1; i <= this.state.upcomingSessions.length; i++) {
          console.log(i);

          if ( i === 4 ) {

            break;
          } else {

            rows.push(
              <div key={this.state.upcomingSessions[i - 1]._id} className="table-row-container-advisor-3">
                <Link to={this.state.linkPrefix + '/edit-logs/' + this.state.upcomingSessions[i - 1]._id} state={{ sessionDetails: this.state.upcomingSessions[i - 1], passedLogType: 'Session', editExisting: true }}>
                  <span className="table-row-header-content-3">
                    <div className="table-row-content-title-2 curtail-text">
                        <p>{this.state.upcomingSessions[i - 1].adviseeFirstName} {this.state.upcomingSessions[i - 1].adviseeLastName}</p>
                    </div>
                    <p className="table-row-content-text-2 curtail-text">{this.state.upcomingSessions[i - 1].category} | {convertDateToString(new Date(this.state.upcomingSessions[i - 1].sessionDate),"datetime-2")}</p>
                  </span>
                  <div className="action-indicator-icon-container">
                    <div className="action-icon-container">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                    </div>
                  </div>
                </Link>
              </div>
            )
          }

        }
      } else {
        //show empty state screen
        rows.push(
          <div key={0}>
            <div className="card-subtitle-container">
              <p>0 Upcoming</p>
            </div>
            <hr className="subtitle-separator" />
          </div>
        )

        rows.push(
          <div key={1} className="empty-state-container-sessions">
            <img src={mentoringIconGrey} alt="Compass mentoring icon" className="empty-state-icon"/>
            <h3 className="empty-state-title"><strong>No Upcoming {this.state.activeOrg === 'any' ? 'Check Ins' : 'Sessions'}</strong></h3>
            <p className="empty-state-text">Add a new session with a mentee. Mentees will be notified when you add them.</p>
            <Link to={this.state.linkPrefix + '/edit-logs/new'} state={{ passedLogType: 'Session' }}><button className="btn btn-primary empty-state-button">Add New {this.state.activeOrg === 'any' ? 'Check Ins' : 'Sessions'}</button></Link>
          </div>
        )
      }

      return rows;
    }

    renderSessions() {
      console.log('renderSessions called')

      let rows = [];
      if (this.state.sessions.length !== 0 ) {

        for (let i = 1; i <= this.state.sessions.length; i++) {
          console.log(i);

          let title = this.state.sessions[i - 1].adviseeFirstName + " " + this.state.sessions[i - 1].adviseeLastName
          if (window.location.pathname.startsWith('/organizations')) {
            title = title + ' (Advisee) with ' + this.state.sessions[i - 1].advisorFirstName + " " + this.state.sessions[i - 1].advisorLastName + " (Advisor)"
          }

          rows.push(
            <div key={this.state.sessions[i - 1]._id}>
              <Link to={this.state.linkPrefix + '/edit-logs/' + this.state.sessions[i - 1]._id} state={{ sessionDetails: this.state.sessions[i - 1], passedLogType: 'Session', editExisting: true }}>
                <div className="fixed-column-50">
                  <p className="heading-text-5 standard-color">{i}.</p>
                </div>
                <span className="calc-column-offset-90">
                  <p className="heading-text-6">{title}</p>
                  <p className="standard-color description-text-1 top-margin">{this.state.sessions[i - 1].category} | {convertDateToString(new Date(this.state.sessions[i - 1].sessionDate),"datetime-2")}</p>
                  <p className="standard-color description-text-3 description-text-color top-margin">{this.state.sessions[i - 1].notes}</p>
                </span>
                <div className="fixed-column-40">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                </div>
                <div className="clear" />
              </Link>

              <div className="row-20">
                <hr />
              </div>
            </div>
          )
        }
      } else {
        //show empty state screen
        rows.push(
          <div key={0} className="card-session-header-container">
            <div className="card-subtitle-container">
              <p>0 Sessions</p>
            </div>
            <hr className="subtitle-separator" />
          </div>
        )

        rows.push(
          <div key={1} className="empty-state-container-sessions">
            <img src={mentoringIconGrey} alt="GC" className="empty-state-icon"/>
            <h3 className="empty-state-title"><strong>No Sessions</strong></h3>
            <p className="empty-state-text">Add a new session with a mentee. Mentees will be notified when you add them.</p>
            <Link to={this.state.linkPrefix + '/edit-logs/new'} state={{ passedLogType: 'Session' }}><button className="btn btn-primary empty-state-button">Add New Sessions</button></Link>
          </div>
        )
      }

      return rows;
    }

    render() {

      return (
          <div>
            <div className={(toggleVars.hideAdminSideNav || window.location.pathname.startsWith('/advisor')) ? "card top-margin-2-percent center-horizontally width-70-percent max-width-1400" : "floating-container card top-margin-2-percent right-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              <div className="calc-column-offset-25 right-padding">
                <p className="heading-text-2">{(this.state.sessions && this.state.sessions.length > 0) ? (this.state.sessions.length === this.state.resLimit) ? this.state.sessions.length + "+ " : this.state.sessions.length + " " : ""}Advising Sessions</p>
              </div>
              <div className="fixed-column-25 top-padding">
                <Link to={this.state.linkPrefix + '/edit-logs/new'} state={{ passedLogType: 'Session' }}>
                  <img src={addIcon} alt="GC" className="image-auto-25 pin-right"/>
                </Link>
              </div>
              <div className="clear"/>

              <div className="spacer" />
              <p className="full-width description-text-color">Resume reviews, mock interviews, counseling, goal strategy, performance reviews, and other advising sessions.</p>
            </div>

            <div className="spacer" /><div className="spacer" />

            <div className={(toggleVars.hideAdminSideNav || window.location.pathname.startsWith('/advisor')) ? "card top-margin-30 center-horizontally width-70-percent max-width-1400" : "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              {this.renderSessions()}
              <div className="clear" />
            </div>

            <div className="clear" />

          </div>

      )
    }
}

export default withRouter(Sessions);
