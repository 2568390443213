import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {signOut} from './Services/AuthRoutes';
import {bootUser} from './Functions/BootUser';
import withRouter from './Functions/WithRouter';

const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const homeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-white.png';
const jobsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobs-icon-grey.png';
const jobsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobs-icon-white.png';
const benchmarksIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-grey.png';
const benchmarksIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-white.png';
const pipelineIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-grey.png';
const pipelineIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-white.png';
const socialIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-grey.png';
const reportingIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reporting-icon-dark.png';
const settingsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon-white.png';
const settingsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon-dark.png';
const exitIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exit-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const membersIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/members-icon-white.png';
const messageIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/message-icon-white.png';
const messageBlastIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/messages-icon-dark.png';
const notificationsWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notification-icon.png';
const dropdownArrowWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow-white.png';
const mentoringIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const socialIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-white.png';
const courseIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-white.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const pathsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-white.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const pathsIconDark2 = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark-2.png';
const opportunitiesIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-white.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const profileIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-white.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const projectsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png";
const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const industryIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-white.png";
const socialIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png";
const eventIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-dark.png";
const assignmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png";
const problemIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-dark.png";
const challengeIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-dark.png";
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const endorsementIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
const publicIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/public-icon.png';
const checkmarkDarkGreyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-dark-grey.png";
const logIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-dark.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/interests-icon.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const targetIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';
const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const addIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const referIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png';
const newsFeedIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/news-feed-icon-dark.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const calendarIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const ticketsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tickets-icon-white.png';
const ticketsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tickets-icon-dark.png';
const naviconIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/navicon-icon-white.png';
const resourcesIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resources-icon-white.png';
const resourcesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resources-icon-dark.png';
const operationsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/operations-icon-white.png';
const operationsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/operations-icon-dark.png';
const gradesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/grades-icon-dark.png';
const timeMoneyIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-money-icon-dark.png";
const mentoringIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png';
const trendsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trends-icon-dark.png';
const educationIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/education-icon.png';
const assessmentsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-dark.png';
const employersIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon-dark.png';
const careerSeekerIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-dark.png';
const teacherIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/teacher-icon-dark.png';

class AppNavigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
          prefix: '/app',
          adminAsMentor: true,

          mobileNavClass: 'nav-items',
          showModule: false,
          isVisible: true,
          isMobileView: false,
          searchString: '',
          toCareerDetail: false,
          careerDetailPath: '',
          showSearchResults: false,
          careerSearchResults: null,
          unreadNotificationsCount: 0,

          showOrgToggle: false,

          courseTabs: [
            { name: 'Browse', url: '/app/courses' },{ name: 'Favorited', url: '/app/courses' },
            { name: 'Completed', url: '/app/courses' },{ name: 'Recommended', url: '/app/courses' }
          ],
          explorePathsTabs: [
            { name: 'Careers', url: '/app/exploration' },{ name: 'Financial Planner', url: '/app/exploration' },
            { name: 'Trends', url: '/app/exploration' }
          ],
          opportunityTabs: [
            { name: 'All', url: '/app/opportunities' },{ name: 'Featured', url: '/app/opportunities' },
            { name: 'Work', url: '/app/opportunities' }, { name: 'Projects', url: '/app/opportunities' },
            { name: 'Events', url: '/app/opportunities' },
          ],
          applicationMaterials: [
            { name: 'Edit Profile Info', url: '/app/edit-profile/basics', icon: profileIconDark },
            // { name: 'Projects & Experience', url: '/app/edit-profile/profile-details', icon: opportunitiesIconDark },
            { name: 'Career Assessments', url: '/app/assessments', icon: abilitiesIconDark },
            { name: 'Endorsements', url: '/app/endorsements', icon: endorsementIconDark }
          ],
          developmentItems: [
            { name: 'Goals & Other Logs', url: '/app/logs', icon: logIconDark },
            // { name: 'My Programs & Courses', url: '/app/my-courses', icon: courseIconDark },
            { name: 'Favorites', url: '/app/favorites', icon: favoritesIconDark },
            { name: 'Enrollments & Completions', url: '/app/completions', icon: checkmarkDarkGreyIcon },
            { name: 'My Top Matches', url: '/app/matches', icon: benchmarksIconDark },
          ],
          otherProfileItems: [
            { name: 'Public Preferences', url: '/app/edit-profile/public-preferences', icon: publicIcon },
            { name: 'My Social Posts', url: '/app/my-social-posts', icon: socialIconDark },
          ],
          engagementTabs: [],
          hiringTabs: [],
          addTabs: []

        }

        //this.props = this.state.currentPage;

        //this.handleChange = this.handleChange.bind(this)
        this.retrieveData = this.retrieveData.bind(this)
        this.countUnreadNotifications = this.countUnreadNotifications.bind(this)
        this.mobileNav = this.mobileNav.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.searchResultClicked = this.searchResultClicked.bind(this)

        this.renderWorkspaces = this.renderWorkspaces.bind(this)
        this.workspaceClicked = this.workspaceClicked.bind(this)
        this.renderModal = this.renderModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.testHiddenTabs = this.testHiddenTabs.bind(this)
        this.renderDesktopNav = this.renderDesktopNav.bind(this)
        this.renderMobileNav = this.renderMobileNav.bind(this)

    }

    static defaultProps = { currentPage: "frontPage", isHiddenProps: true, username: null, fromCareerDetailsSelf: false, fromAdvisor: false, unreadNotificationsCount: 0, fromEmployer: false, emp: '', fromOrganization: false, orgMode: true, org: '', activeOrg: null, orgFocus: null, roleName: null/*}, profilePicPath: null*/ }

    componentDidMount() {
      console.log('appNavigation called');

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgName = localStorage.getItem('orgName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');

      let orgFocus = localStorage.getItem('orgFocus');
      let myOrgs = localStorage.getItem('myOrgs');
      const sharePartners = localStorage.getItem('sharePartners');
      const remoteAuth = localStorage.getItem('remoteAuth');
      let accountCode = localStorage.getItem('emp');
      // const over18  = localStorage.getItem('over18');
      let tempAccountCode = localStorage.getItem('accountCode');
      if (!accountCode && tempAccountCode) {
        accountCode = tempAccountCode
      }

      const studentAliasSingular = localStorage.getItem('studentAliasSingular');
      const studentAliasPlural = localStorage.getItem('studentAliasPlural');
      const mentorAliasSingular = localStorage.getItem('mentorAliasSingular');
      const mentorAliasPlural = localStorage.getItem('mentorAliasPlural');

      let roleNameTitle = roleName
      if (orgFocus === 'Placement' && roleName === 'Teacher') {
        roleName = 'Mentor'
      } else if ((roleName === 'Student' || roleName === 'Career-Seeker') && studentAliasSingular) {
        roleNameTitle = studentAliasSingular
      } else if (roleName === 'Mentor' && mentorAliasSingular) {
        roleNameTitle = mentorAliasSingular
      }
      // console.log("____ roleName: ", roleName)

      let careerSeekerTerm = 'Career-Seekers'
      if (studentAliasPlural) {
        careerSeekerTerm = studentAliasPlural
      }

      let mentorTerm = 'Mentors'
      if (mentorAliasPlural) {
        mentorTerm = mentorAliasPlural
      }

      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else if (!window.location.pathname.startsWith('/employers/')){
        accountCode = null
      }

      let internalToolEnabled = localStorage.getItem('internalToolEnabled')
      // console.log('internalToolEnabled----------!----', internalToolEnabled)

      // console.log('show remoteAuth: ', remoteAuth)

      console.log('show window width: ', window.innerWidth)
      let isMobileView = false
      let mobileNavClass = 'mobile-app-nav-items'
      let isVisible = true

      if (window.innerWidth < 767) {
        isMobileView = true
        mobileNavClass = 'hide-mobile-app-items'
        isVisible = false
      }

      let showOrgToggle = true
      if (remoteAuth) {
        showOrgToggle = false
      }

      let prefix = '/app'
      if (this.props.fromAdvisor || window.location.pathname.startsWith('/advisor')) {
        prefix = '/advisor'
      } else if (window.location.pathname.startsWith('/organizations')) {
        prefix = '/organizations/' + activeOrg
      } else if (window.location.pathname.startsWith('/employers')) {
        prefix = '/employers/' + accountCode
      }

      let applicationMaterials = []
      let otherProfileItems = []
      if (window.location.pathname.startsWith('/employers')) {
        otherProfileItems.push({ name: 'Training Partners', url: prefix + '/add-orgs', icon: educationIconDark })
        otherProfileItems.push({ name: 'Social Posts', url: prefix + '/social-posts', icon: newsFeedIconDark })
        otherProfileItems.push({ name: 'Hires', url: prefix + '/hires', icon: careerMatchesIconDark })
        // otherProfileItems.push({ name: 'Reporting', url: prefix + '/reporting', icon: reportingIconDark })
      } else {
        otherProfileItems.push({ name: 'My Social Posts', url: prefix + '/my-social-posts/' + username, icon: socialIconDark })
      }

      if ((window.location.pathname.startsWith('/app') && !employerAccount) || (window.location.pathname.startsWith('/advisor') && (roleName === 'Mentor' || roleName === 'Admin') && !employerAccount)) {
        otherProfileItems.unshift({ name: 'Public Preferences', url: prefix + '/edit-profile/public-preferences', icon: publicIcon })
      }

      let developmentItems = []
      if (this.props.fromAdvisor && roleName !== 'Mentor') {
        developmentItems.push({ name: 'Advising Sessions', url: prefix + '/sessions', icon: logIconDark })
      } else {
        if (!window.location.pathname.startsWith('/organizations') && !window.location.pathname.startsWith('/employers')) {
          // developmentItems.push({ name: 'Goals & Other Logs', url: prefix + '/logs', icon: logIconDark })
          applicationMaterials.push({ name: 'Goals & Other Logs', url: prefix + '/logs', icon: logIconDark })
        }
      }

      // if (!this.props.fromAdvisor) {
      //   developmentItems.push({ name: 'My Programs & Courses', url: prefix + '/my-courses', icon: courseIconDark })
      // }
      developmentItems.push({ name: 'Favorites', url: prefix + '/favorites', icon: favoritesIconDark })
      if (!this.props.fromAdvisor) {
        developmentItems.push({ name: 'Enrollments & Completions', url: prefix + '/completions', icon: checkmarkDarkGreyIcon })
      }

      if (window.location.pathname.startsWith('/app')) {
        // developmentItems.push({ name: 'My Documents', url: prefix + '/documents', icon: resumeIconDark })
        applicationMaterials.push({ name: 'My Documents', url: prefix + '/documents', icon: resumeIconDark })
      }

      if (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && roleName === 'Mentor')) {
        // applicationMaterials.push({ name: 'Projects & Experience', url: prefix + '/edit-profile/profile-details', icon: opportunitiesIconDark })
        applicationMaterials.unshift({ name: 'Career Assessments', url: prefix + '/assessments', icon: abilitiesIconDark })
      } else if (window.location.pathname.startsWith('/organization')) {
        applicationMaterials.unshift({ name: 'Edit Organization Info', url: prefix + '/edit-organization-profile', icon: industryIconDark })
      } else if (window.location.pathname.startsWith('/employers')) {
        applicationMaterials.unshift({ name: 'Edit Account Info', url: prefix + '/edit-employer-profile', icon: industryIconDark })
      } else if ((window.location.pathname.startsWith('/advisor') && roleName !== 'Mentor')) {
        applicationMaterials.unshift({ name: 'Your Grades', url: prefix + '/grades', icon: gradesIconDark })
      } else {

      }

      if (window.location.pathname.startsWith('/employers')) {
        applicationMaterials.unshift({ name: 'Edit Profile Info', url: prefix + '/edit-profile', icon: profileIconDark })
      } else {
        applicationMaterials.unshift({ name: 'Edit Profile Info', url: prefix + '/edit-profile/basics', icon: profileIconDark })
      }

      if (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && roleName === 'Mentor')) {
        if (employerAccount) {
          applicationMaterials.push({ name: 'Performance Reviews', url: prefix + '/endorsements', icon: endorsementIconDark })
        } else {
          // applicationMaterials.push({ name: 'Endorsements', url: prefix + '/endorsements', icon: industryIconDark })
          developmentItems.push({ name: 'Endorsements', url: prefix + '/endorsements', icon: industryIconDark })
        }
      }

      if ((window.location.pathname.startsWith('/app') && !employerAccount)) {
        developmentItems.push({ name: 'My Top Matches', url: prefix + '/matches', icon: benchmarksIconDark })
      }

      let engagementTabs = []
      let hiringTabs = []
      let addTabs = []
      if (internalToolEnabled) {
        engagementTabs = [
          { name: 'eGoals', url: '/employers/' + accountCode + '/goals', icon: targetIcon },
          { name: 'Surveys', url: '/employers/' + accountCode + '/surveys', icon: abilitiesIconDark },
          { name: 'News Feed', url: '/employers/' + accountCode + '/news-feed', icon: newsFeedIconDark },
          { name: 'Events', url: '/employers/' + accountCode + '/events', icon: calendarIconDark },
          { name: 'Groups', url: '/employers/' + accountCode + '/groups', icon: socialIconDark },

        ]
        hiringTabs = [
          { name: 'Postings', url: '/employers/' + accountCode + '/dashboard', icon: opportunitiesIconDark },
          { name: 'Referrals', url: '/employers/' + accountCode + '/referrals', icon: careerMatchesIconDark },
          { name: 'Hires', url: '/employers/' + accountCode + '/hires', icon: opportunitiesIconDark }
        ]
        addTabs = [
          { name: 'OKR', url: '/employers/' + accountCode + '/okrs/add', icon: addIconDark },
          { name: 'Survey', url: '/employers/' + accountCode + '/surveys/add', icon: addIconDark },
          { name: 'Pathway', url: '/employers/' + accountCode + '/pathways/add', icon: addIconDark },
          { name: 'Social Post', url: '/employers/' + accountCode + '/social-posts/add', icon: addIconDark },
          { name: 'Course', url: '/employers/' + accountCode + '/courses/add', icon: addIconDark },
          { name: 'Benchmark', url: '/employers/' + accountCode + '/benchmarks/add', icon: addIconDark },
          { name: 'Performance Review', url: '/employers/' + accountCode + '/performance-reviews/add', icon: addIconDark },
          { name: 'Posting', url: '/employers/' + accountCode + '/postings/add', icon: addIconDark },
          { name: 'Employee', url: '/employers/' + accountCode + '/employees/invite', icon: addIconDark },
        ]
      }

      let memberTabs = [
        { name: 'All', url: prefix + '/members' },
        { name: careerSeekerTerm, url: prefix + '/career-seekers', icon: careerSeekerIconDark },
        { name: 'Educators', url: prefix + '/educators', icon: teacherIconDark },
        { name: mentorTerm, url: prefix + '/mentors', icon: mentoringIconDark },
        { name: 'Employers', url: prefix + '/employers', icon: employersIconDark },
      ]

      if (window.location.pathname.startsWith('/advisor') && (roleName !== 'Mentor' && roleName !== 'Admin')) {
        memberTabs = [
          { name: 'Students', url: prefix + '/advisees' },
          { name: 'Mentors', url: prefix + '/mentors' },
          { name: 'Employers', url: prefix + '/employers', icon: employersIconDark },
        ]
      }

      let communityTabs = [
        { name: 'Groups', url: prefix + '/groups', icon: socialIconDark },
        { name: 'News Feed', url: prefix + '/news-feed', icon: newsFeedIconDark },
        { name: 'Projects', url: prefix + '/projects', icon: projectsIconDark },
        { name: 'Goals', url: prefix + '/goals', icon: targetIcon },
        // { name: 'Endorsements', url: prefix + '/endorsements' },
        // { name: 'Curriculum Exchange', url: prefix + '/exchange' },
      ]

      if (window.location.pathname.startsWith('/advisor') && (roleName !== 'Mentor' && roleName !== 'Admin')) {
        communityTabs.unshift({ name: 'Employers', url: prefix + '/employers', icon: employersIconDark })
        communityTabs.unshift({ name: 'Members', url: prefix + '/members', icon: assessmentsIconDark })
      }

      let learningTabs = [
        { name: 'Courses', url: prefix + '/courses', icon: courseIconDark },
        { name: 'Learning Modules', url: prefix + '/learning-modules', icon: assessmentsIconDark },
        { name: 'Resources', url: prefix + '/resources', icon: resourcesIconDark },
        // { name: 'Postings', url: prefix + '/postings' },
      ]

      if (window.location.pathname.startsWith('/organizations')) {
        // learningTabs.unshift({ name: 'Assessments', url: prefix + '/assessments' })
        learningTabs.unshift({ name: 'Programs', url: prefix + '/programs', icon: educationIconDark })
      } else {
        // learningTabs.unshift({ name: 'Surveys & Quizzes', url: prefix + '/quizzes' })
      }

      let pathingTabs = [
        { name: 'General Pathways', url: prefix + '/careers', icon: pathsIconDark },
        { name: 'Custom Pathways', url: prefix + '/pathways', icon: pathsIconDark2 },
        { name: 'Advising Sessions', url: prefix + '/sessions', icon: mentoringIconDark },
        { name: 'Goals', url: prefix + '/goals', icon: targetIcon },
        { name: 'Documents', url: prefix + '/documents', icon: resumeIconDark },
        { name: 'Projects', url: prefix + '/projects', icon: projectsIconDark },
        { name: 'Endorsements', url: prefix + '/endorsements', icon: endorsementIconDark },
        { name: 'Labor Market Trends', url: prefix + '/trends', icon: trendsIconDark },
      ]

      if (window.location.pathname.startsWith('/advisor') && (roleName !== 'Mentor' && roleName !== 'Admin')) {
        pathingTabs.push({ name: 'Postings', url: prefix + '/postings', icon: opportunitiesIconDark })
        pathingTabs.push({ name: 'Placements', url: prefix + '/placements' })
        // pathingTabs.push({ name: 'Timesheets', url: prefix + '/timesheets' })
      }

      let workTabs = [
        { name: 'Postings', url: prefix + '/postings', icon: opportunitiesIconDark },
        { name: 'Placements', url: prefix + '/placements', icon: careerMatchesIconDark },
        { name: 'Timesheets', url: prefix + '/timesheets', icon: timeMoneyIconDark }
      ]

      if (window.location.pathname.startsWith('/organizations')) {
        workTabs.push({ name: 'Referrals', url: prefix + '/referrals', icon: referIconDark })
      }

      let operatingTabs = [
        { name: 'Benchmarks', url: prefix + '/benchmarks', icon: benchmarksIconDark },
        { name: 'Postings', url: prefix + '/postings', icon: opportunitiesIconDark },
        { name: 'Reporting', url: prefix + '/reporting', icon: reportingIconDark },
        { name: 'Message Blasts', url: prefix + '/message-blasts', icon: messageBlastIconDark },
      ]

      let resourcesTabs = [
        { name: 'Surveys & Quizzes', url: prefix + '/quizzes' },
        { name: 'Lessons', url: prefix + '/learning-modules' },
      ]

      // if (window.location.pathname.startsWith('/organizations')) {
      //   operatingTabs.unshift({ name: 'People Matching', url: prefix + '/assessments' })
      // }

      if (window.location.pathname.startsWith('/organizations')) {
        operatingTabs.unshift({ name: 'Assessments', url: prefix + '/assessments', icon: abilitiesIconDark })
        operatingTabs.push({ name: 'Tickets', url: prefix + '/tickets', icon: ticketsIconDark })
      } else {
        operatingTabs.unshift({ name: 'Surveys & Quizzes', url: prefix + '/quizzes' })
      }

      let dropdownMenus = []
      if (window.location.pathname.startsWith('/organizations') || (window.location.pathname.startsWith('/advisor') && (roleName !== 'Mentor' && roleName !== 'Admin'))) {

        let workPosition = "dropdown-menu-container-p3"
        let pathingPosition = "dropdown-menu-container-p4"
        let resourcesPosition = "dropdown-menu-container-p5"
        let learningPosition = "dropdown-menu-container-p5"
        let communityPosition = "dropdown-menu-container-p6"
        if (window.location.pathname.startsWith('/advisor') && (roleName !== 'Mentor' && roleName !== 'Admin')) {
          workPosition = "dropdown-menu-container-p1"
          pathingPosition = "dropdown-menu-container-p2"
          resourcesPosition = "dropdown-menu-container-p3"
          learningPosition = "dropdown-menu-container-p4"
          communityPosition = "dropdown-menu-container-p4"
        }

        dropdownMenus.push(
          { name: 'Members', showname: 'showMemberTabs', positionClass: "dropdown-menu-container-p7", tabs: memberTabs, icon: socialIconDark },
          { name: 'Community', showname: 'showCommunityTabs', positionClass: communityPosition, tabs: communityTabs, icon: socialIconDark },
          { name: 'Learning', showname: 'showLearningTabs', positionClass: learningPosition, tabs: learningTabs, icon: courseIconDark },
          { name: 'Resources', showname: 'showResourcesTabs', positionClass: resourcesPosition, tabs: resourcesTabs, icon: resourcesIconDark },
          { name: 'Pathing', showname: 'showPathingTabs', positionClass: pathingPosition, tabs: pathingTabs, icon: pathsIconDark },
          { name: 'Work', showname: 'showWorkTabs', positionClass: workPosition, tabs: workTabs, icon: opportunitiesIconDark },
          { name: 'Operating', showname: 'showOperatingTabs', positionClass: "dropdown-menu-container-p2", tabs: operatingTabs, icon: operationsIconDark },
        )
      }

      this.setState({ emailId: email, cuFirstName, cuLastName, remoteAuth, employerAccount,
        isMobileView, mobileNavClass, isVisible, activeOrg, orgName, orgFocus, roleName, roleNameTitle,
        showOrgToggle, accountCode,
        otherProfileItems, developmentItems, applicationMaterials,
        internalToolEnabled, engagementTabs, hiringTabs, addTabs, prefix,
        memberTabs, learningTabs, communityTabs, pathingTabs, workTabs, operatingTabs, dropdownMenus
      })

      this.retrieveData(activeOrg, accountCode, email, employerAccount, roleName, remoteAuth, roleNameTitle)

      let orgCodes = null
      if (window.location.pathname.startsWith('/employers') && sharePartners) {
        orgCodes = JSON.parse(sharePartners)
      } else if (myOrgs) {
        orgCodes = JSON.parse(myOrgs)
      }
      console.log('show me sharePartnersss', sharePartners)

      // pull myOrgs
      if (orgCodes) {
        // myOrgs = JSON.parse(myOrgs)
        // console.log('show myOrgs: ', typeof myOrgs, myOrgs)

        Axios.get('/api/orgs', { params: { orgCodes } })
        .then((response) => {
          console.log('Org info query attempted for orgCodes', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const myOrgObjects = response.data.orgs
            this.setState({ activeOrg, myOrgObjects })

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

      Axios.get('/api/inboxes', { params: { emailId: email }})
      .then((response) => {
        console.log('Inboxes query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved inboxes')

           const inboxes = response.data.inboxes
           let unreadMessageCount = 0
           if (inboxes && inboxes.length > 0) {
             for (let i = 1; i <= inboxes.length; i++) {
               if (inboxes[i - 1].unreadCount) {
                 unreadMessageCount = unreadMessageCount + inboxes[i - 1].unreadCount
               }
             }
           }
           console.log('show me unreadMessageCount: ', unreadMessageCount)
           this.setState({ unreadMessageCount })

         } else {
           console.log('no inboxes found', response.data.message)
           this.setState({ errorMessage: response.data.message })

         }

      }).catch((error) => {
         console.log('Inboxes query did not work', error);
      });

      if (!window.location.pathname.startsWith('/notifications')) {
        Axios.get('/api/notifications', { params: { emailId: email, orgCode: activeOrg } })
        .then((response) => {
          console.log('Notifications query tried in appNav', response.data);

          if (response.data.success) {

            // this.setState({ notifications: response.data.notifications })
            this.countUnreadNotifications(response.data.notifications)

          } else {
            console.log('no notifications found', response.data.message)
          }

        }).catch((error) => {
            console.log('Notifications query did not work', error);
        });
      }

      if (!window.location.pathname.startsWith(prefix + '/community') && !window.location.pathname.startsWith('/organizations') && !window.location.pathname.startsWith('/employers')) {

        const self = this
        function pullGroupRequests(unreadRequests) {
          console.log('pullGroupRequests called')

          Axios.get('/api/groups/unread-notifications', { params: { orgCode: activeOrg, emailId: email } })
          .then((response) => {
            console.log('Unread notifications query attempted', response.data);

              if (response.data.success && response.data.unreadGroups) {
                console.log('unread notifications query worked')

                let unreadGroupNotifications = response.data.unreadGroups
                unreadRequests = unreadRequests + unreadGroupNotifications.length
                console.log('show unreadRequests: ', unreadRequests)
                self.setState({ unreadRequests })

              } else {
                console.log('unread requests query did not work', response.data.message)

                self.setState({ unreadRequests })
              }

          }).catch((error) => {
              console.log('Unread requests query did not work for some reason', error);
          })
        }

        Axios.get('/api/friends', { params: { orgCode: activeOrg, emailId: email } })
        .then((response) => {
          console.log('Friends query attempted', response.data);

            if (response.data.success) {
              console.log('friends query worked')

              let unreadRequests = 0
              if (response.data.friends) {
                for (let i = 1; i <= response.data.friends.length; i++) {
                  // console.log('looping through the friends', response.data.friends[i - 1].requesterEmail)
                  if (response.data.friends[i - 1].activeRequest && response.data.friends[i - 1].isRead === false && response.data.friends[i - 1].requesterEmail !== email) {
                    // console.log('up in it')
                    unreadRequests = unreadRequests + 1
                  }
                }
              }

              // this.setState({ unreadRequests })
              pullGroupRequests(unreadRequests)

            } else {
              console.log('friends query did not work', response.data.message)
              pullGroupRequests(0)
            }

        }).catch((error) => {
            console.log('Friends query did not work for some reason', error);
        })
      }

      if (window.location.pathname.startsWith('/organizations')) {
        // ban unauthorized users
        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {

            if (response.data.success) {
              console.log('User profile query worked in app nav', this.props.navigate);
              // could also ban people with wrong roleName
              const bannedFromAdmin = response.data.user.bannedFromAdmin
              if (bannedFromAdmin) {
                signOut(email, activeOrg, orgFocus, accountCode, roleName, this.props.navigate)
              }

            } else {
              console.log('no user details found', response.data.message)

            }

        }).catch((error) => {
            console.log('User profile query did not work', error);
        });
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in appNav ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData(this.props.activeOrg,this.state.accountCode, this.state.emailId, this.state.employerAccount,this.state.roleName,this.state.remoteAuth, this.state.roleNameTitle)
      } else if (this.props.emp !== prevProps.emp) {
        this.retrieveData(this.props.activeOrg,this.props.emp, this.state.emailId, this.state.employerAccount,this.state.roleName,this.state.remoteAuth, this.state.roleNameTitle)
      } else if (this.props.org !== prevProps.org) {
        this.retrieveData(this.props.org,this.state.accountCode, this.state.emailId, this.state.employerAccount,this.state.roleName,this.state.remoteAuth, this.state.roleNameTitle)
      }
    }

    retrieveData(orgCode, accountCode, emailId, employerAccount, roleName, remoteAuth, roleNameTitle) {
      console.log('retrieveData called within appNav', orgCode, accountCode, emailId, employerAccount, roleName, remoteAuth, roleNameTitle)

      let activeOrg = localStorage.getItem('activeOrg');
      console.log('h1: ', emailId, this.props.org, activeOrg)
      // "83338447@placeholder.com"

      if (!emailId || emailId === '' || (this.props.fromOrganization && this.props.org && this.props.org !== activeOrg) || this.checkRoleMismatch(roleName)) {
        if (!this.props.skipRedirect) {
          const bootPath = '/signin'
          bootUser(this.props.navigate, roleName, bootPath)
        }
      } else {

        if (!employerAccount) {
          Axios.get('/api/org', { params: { orgCode } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgLogo = response.data.orgInfo.webLogoURI
              const hiddenTabs = response.data.orgInfo.hiddenTabs
              let noOrgSwitching = response.data.orgInfo.noOrgSwitching
              if (remoteAuth) {
                noOrgSwitching = true
              }

              this.setState({ activeOrg: orgCode, orgLogo, hiddenTabs, noOrgSwitching, roleNameTitle });

            } else {
              console.log('org info query did not work', response.data.message)

            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
         .then((response) => {
           console.log('query for profile data worked');

           if (response.data.success) {

             console.log('profile data received for profile pic', response.data)
             const pictureURL = response.data.user.pictureURL
             // const roleName = response.data.user.roleName
             const school = response.data.user.school
             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName

             this.setState({ pictureURL, school, jobTitle, employerName })

           } else {
             console.log('error response', response.data)
           }

       }).catch((error) => {
           console.log('query for profile info did not work', error);
       })

       // console.log('in emp', this.props)
       if ((window.location.pathname.startsWith('/employers') && this.props.emp) || employerAccount) {

         if (!employerAccount) {
           accountCode = this.props.emp
         }
         Axios.get('/api/account', { params: { accountCode } })
         .then((response) => {
           console.log('Account info query attempted in employer dashboard', response.data);

           if (response.data.success) {
             console.log('account info query worked in sub settings')

             const employerName = response.data.accountInfo.employerName
             const employerLogoURL = response.data.accountInfo.employerLogoURI
             const employerLogoWhiteURI = response.data.accountInfo.employerLogoWhiteURI
             const accountCode = response.data.accountInfo.accountCode

             this.setState({ employerName, employerLogoURL, employerLogoWhiteURI, accountCode });

           }

         }).catch((error) => {
           console.log('Account info query did not work for some reason', error);
         });
       }
      }
    }

    mobileNav (event) {
      console.log('mobile nav clicked')
      
      if (this.state.mobileNavClass === "mobile-app-nav-items") {
        this.setState({
            mobileNavClass: 'hide-mobile-app-items',
            isVisible: false,
            isMobileView: true
        })
      } else {
        this.setState({
            mobileNavClass: 'mobile-app-nav-items',
            isVisible: true,
            isMobileView: true
        })
      }
    }

    checkRoleMismatch(roleName) {
      console.log('checkRoleMismatch called', roleName)

      let isMismatch = false
      if (window.location.pathname.startsWith('/app') && (roleName !== 'Student' && roleName !== 'Career-Seeker' && roleName !== 'Employee' && roleName !== 'Admin' && roleName !== 'Teacher' && roleName !== 'Counselor' && roleName !== 'Academy Lead')) {
        isMismatch = true
      } else if (window.location.pathname.startsWith('/advisor') && (roleName === 'Student' || roleName === 'Career-Seeker')) {
        isMismatch = true
      } else if (window.location.pathname.startsWith('/organizations') && (roleName !== 'Admin') && roleName !== 'WBLC' && roleName !== 'Work-Based Learning Coordinator') {
        isMismatch = true
      } else if (window.location.pathname.startsWith('/employers') && (roleName !== 'Employer' && roleName !== 'Admin')) {
        isMismatch = true
      }

      return isMismatch
    }

    countUnreadNotifications(notifications) {
      console.log('countUnreadNotifications called')
      let unreadNotificationsCount = 0;

      for (let i = 1; i <= notifications.length; i++) {
        console.log(i);

        if (notifications[i - 1].isRead === false) {
          unreadNotificationsCount = unreadNotificationsCount + 1
        }
      }

      console.log('show unreadNotificationsCount', unreadNotificationsCount)
      localStorage.setItem('unreadNotificationsCount', unreadNotificationsCount)
      this.setState({ unreadNotificationsCount })
    }

    handleChange(e) {
      console.log('handleChange');

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        if (self.props.fromOrganization) {

          //get search results
          // Axios.get('/api/org/members', { params: { orgCode: self.props.org } })
          // .then((response) => {
          //   console.log('attempting a search')
          //   if (response.data.success) {
          //     console.log('Member search worked', response.data);
          //
          //     self.setState({ members: response.data.members })
          //
          //   } else {
          //     console.log('no members found', response.data.message)
          //   }
          //
          // }).catch((error) => {
          //     console.log('Members query did not work', error);
          // });

          const roleName = self.state.roleName
          const searchString = self.state.searchString
          const orgCode = self.state.activeOrg
          const limit = 25

          Axios.get('/api/members/search', { params: { roleName, searchString, orgCode, limit } })
          .then((response) => {

              if (response.data.success) {
                console.log('Member search worked', response.data);

                self.setState({ members: response.data.members })

              } else {
                console.log('no members found', response.data.message)

              }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });
        } else if (self.props.fromAdvisor && self.props.roleName !== 'Mentor') {
          console.log('search members ')

          const roleName = self.state.roleName
          const searchString = self.state.searchString
          const orgCode = self.state.activeOrg
          const limit = 25

          //school district orgs
          Axios.get('/api/members/search', { params: { roleName, searchString, orgCode, limit } })
          .then((response) => {

              if (response.data.success) {
                console.log('Member search worked', response.data);

                self.setState({ members: response.data.members })

              } else {
                console.log('no members found', response.data.message)

              }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });

        } else {
          //get search results

          self.setState({ searchResults: null })

          const searchString = self.state.searchString
          const orgCode = self.state.activeOrg
          const emailId = self.state.emailId
          // const roleNames = ['Student','Career-Seeker']
          const roleNames = ['Student','Career-Seeker','Mentor']

          Axios.get('/api/home/search', { params: { searchString, orgCode, emailId, roleNames } })
          .then((response) => {
              console.log('Home search worked', response.data.success, response.data);

              if (response.data.success) {

                let searchResults = []
                if (response.data.members) {
                  for (let i = 1; i <= response.data.members.length; i++) {
                    let imageURL = profileIconDark
                    if (response.data.members[i - 1].pictureURL) {
                      imageURL = response.data.members[i - 1].pictureURL
                    }
                    searchResults.push({
                      category: response.data.members[i -1 ].roleName,
                      name: response.data.members[i - 1].firstName + ' ' + response.data.members[i - 1].lastName,
                      email: response.data.members[i - 1].email,
                      imageURL,
                      url: self.state.prefix + '/profile/' + response.data.members[i - 1].username
                    })
                  }
                }
                if (response.data.projects) {
                  for (let i = 1; i <= response.data.projects.length; i++) {
                    let imageURL = projectsIconDark
                    if (response.data.projects[i - 1].imageURL) {
                      imageURL = response.data.projects[i - 1].imageURL
                    }
                    searchResults.push({
                      category: 'Project',
                      name: response.data.projects[i - 1].name,
                      imageURL,
                      url: self.state.prefix + '/projects/' + response.data.projects[i - 1]._id
                    })
                  }
                }
                if (response.data.careers) {
                  for (let i = 1; i <= response.data.careers.length; i++) {
                    let imageURL = careerMatchesIconDark
                    searchResults.push({
                      category: 'Career Path',
                      name: response.data.careers[i - 1].name, imageURL,
                      url: self.state.prefix + '/careers/' + response.data.careers[i - 1].name
                    })
                  }
                }
                if (response.data.employers) {
                  for (let i = 1; i <= response.data.employers.length; i++) {
                    let imageURL = industryIconDark
                    if (response.data.employers[i - 1].employerLogoURI) {
                      imageURL = response.data.employers[i - 1].employerLogoURI
                    }
                    searchResults.push({
                      category: 'Employer',
                      name: response.data.employers[i - 1].employerName,
                      imageURL,
                      url: self.state.prefix + '/employers/' + response.data.employers[i - 1]._id
                    })
                  }
                }
                if (response.data.groups) {

                  for (let i = 1; i <= response.data.groups.length; i++) {
                    let imageURL = socialIconDark
                    if (response.data.groups[i - 1].pictureURL) {
                      imageURL = response.data.groups[i - 1].pictureURL
                    }
                    searchResults.push({
                      category: 'Group',
                      name: response.data.groups[i - 1].name,
                      imageURL,
                      url: self.state.prefix + '/groups/' + response.data.groups[i - 1]._id
                    })
                  }
                }
                if (response.data.postings) {
                  for (let i = 1; i <= response.data.postings.length; i++) {
                    let name = response.data.postings[i - 1].title
                    let imageURL = opportunitiesIconDark
                    if (response.data.postings[i - 1].employerLogoURL) {
                      imageURL = response.data.postings[i - 1].employerLogoURL
                    } else if (response.data.postings[i - 1].postType === 'Event') {
                      imageURL = eventIconDark
                    } else if (response.data.postings[i - 1].postType === 'Assignment') {
                      imageURL = assignmentsIconDark
                    } else if (response.data.postings[i - 1].postType === 'Problem') {
                      imageURL = problemIconDark
                    } else if (response.data.postings[i - 1].postType === 'Challenge') {
                      imageURL = challengeIconDark
                    } else if (response.data.postings[i - 1].postType === 'Internship' || response.data.postings[i - 1].postType === 'Work') {
                      imageURL = opportunitiesIconDark
                    }
                    if (response.data.postings[i - 1].postType === 'Assignment' || response.data.postings[i - 1].postType === 'Problem' || response.data.postings[i - 1].postType === 'Challenge') {
                      name = response.data.postings[i - 1].name
                    }
                    searchResults.push({
                      category: response.data.postings[i - 1].postType,
                      name, imageURL,
                      url: self.state.prefix + '/opportunities/' + response.data.postings[i - 1]._id
                    })
                  }
                }

                self.setState({ searchResults })

              } else {
                console.log('there was an error:', response.data.message);

              }
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

      this.setState({
        searchString: e.target.value,
        showSearchResults: true
      })

    }

    handleKeyPress(e) {

      if (e.key === 'Enter') {
        console.log('enter was pressed')

        //not from suggestions
        // this.navigateToCareerPath(false, 0)

        //this.props.parentMethod();
        //this.props.searchTerm = this.state.searchTerm
        //this.props.navigate('./app/career-pathways/' + this.state.searchTerm)
      }
    }

    searchResultClicked(i) {
      console.log('searchResultClicked called: ', this.props.fromAdvisor)

      if (this.props.fromOrganization) {

         this.setState({ searchString: '' })
         this.props.navigate('/organizations/' + this.props.org + '/members/' + this.state.members[i - 1].email)

      } else if (this.props.fromAdvisor) {

        let adviseeDetailPath = '/advisor/advisees/'
        if (this.state.members && this.state.members[i - 1].email) {
          adviseeDetailPath = adviseeDetailPath + this.state.members[i - 1].email
        } else if (this.state.advisees && this.state.advisees[i - 1].email) {
          adviseeDetailPath = adviseeDetailPath + this.state.advisees[i - 1].email
        }

        this.setState({ searchString: '' })
        this.props.navigate(adviseeDetailPath)
      } else {
      }
    }

    renderSearchResults() {

      if (this.state.careerSearchResults) {
        if (this.state.careerSearchResults.occupation) {
          let rows = []
          for (let i = 1; i <= this.state.careerSearchResults.occupation.length; i++) {
            // console.log(i)

            if (i < 10) {
              rows.push(
                <div key={i}>
                  <span className="row-5">
                    <button className="background-button left-text" onClick={() => this.searchResultClicked(i)}>
                      <div className="fixed-column-40">
                        <img src={careerMatchesIconDark} alt="GC" className="image-auto-30" />
                      </div>
                      <div className="calc-column-offset-40 top-padding-5">
                        <p>{this.state.careerSearchResults.occupation[i - 1].title}</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </span>
                </div>
              )
            }
          }
          console.log('this is called', rows)
          return rows
        } else {
          return
        }

      } else if (this.state.members) {
        console.log('show member search results count: ', this.state.members.length)
        let rows = []

        for (let i = 1; i <= this.state.members.length; i++) {
          // console.log('member', i)

          const fullName = this.state.members[i - 1].firstName.toLowerCase() + this.state.members[i - 1].lastName.toLowerCase()
          rows.push(
            <div key={i}>
              <span className="top-margin-20 full-width">
                <button className="background-button" onClick={() => this.searchResultClicked(i)}>
                  <div className="fixed-column-40">
                    <img src={(this.state.members[i - 1].pictureURL) ? this.state.members[i - 1].pictureURL : profileIconDark} alt="GC" className="image-auto-25" />
                  </div>
                  <div className="calc-column-offset-40">
                    <p>{this.state.members[i - 1].firstName} {this.state.members[i - 1].lastName}</p>
                  </div>
                  <div className="clear" />
                </button>
              </span>
            </div>
          )
        }
        console.log('this is called', rows)
        return rows

      } else if (this.state.advisees) {
        let rows = []
        for (let i = 1; i <= this.state.advisees.length; i++) {
          // console.log(i)

          const fullName = this.state.advisees[i - 1].firstName.toLowerCase() + this.state.advisees[i - 1].lastName.toLowerCase()
          rows.push(
            <div key={i}>
              <span className="top-margin-20"><button className="background-button" onClick={() => this.searchResultClicked(i)}>
                <div className="fixed-column-40">
                  <img src={(this.state.advisees[i - 1].pictureURL) ? this.state.advisees[i - 1].pictureURL : profileIconDark} alt="GC" className="image-auto-25" />
                </div>
                <div className="calc-column-offset-40">
                  <p>{this.state.advisees[i - 1].firstName} {this.state.advisees[i - 1].lastName}</p>
                </div>
                <div className="clear" />
              </button></span>
            </div>
          )
        }
        console.log('this is called', rows)
        return rows
      } else if (this.state.searchResults) {

        return (
          <div key="searchResults">
            {this.state.searchResults.map((item, index) =>
              <div key={index}>
                <span className="row-5">
                  <Link className="background-button left-text" to={item.url} onClick={() => this.setState({ showSearchResults: false, searchString: '' })}>
                    <div className="fixed-column-40">
                      <img src={(item.imageURL) ? item.imageURL : careerMatchesIconDark} alt="GC" className="image-30-fit" />
                    </div>
                    <div className="calc-column-offset-40 top-padding-5">
                      <label>{item.name}</label><label className="description-text-2 description-text-color left-padding">({item.category})</label>
                    </div>
                    <div className="clear" />
                  </Link>
                </span>
              </div>
            )}
          </div>
        )
      } else {
        return
      }
    }

    renderWorkspaces() {
      console.log('renderWorkspaces called', this.state.myOrgObjects)

      let rows = []

      if (this.state.myOrgObjects) {
        for (let i = 1; i <= this.state.myOrgObjects.length; i++) {
          console.log(i)

          const org = this.state.myOrgObjects[i - 1]

          // if (org.orgCode !== 'guidedcompass') {
            rows.push(
              <div key={i} className="row-5">
                <span className="full-width left-padding-5"><button className="background-button row-5 right-padding-20" onClick={() => this.workspaceClicked(org)}>
                  <div className="float-left right-padding">
                    <img src={(org.webLogoURIColor) ? org.webLogoURIColor : mentoringIconBlue} alt="GC" className="image-22" />
                  </div>
                  <div className="float-left">
                    <p>{org.orgName}</p>
                  </div>
                  {(this.state.activeOrg === org.orgCode) && (
                    <div className="float-left left-padding top-margin-5">
                      <div className="mini-spacer" />
                      <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                    </div>
                  )}

                </button></span>
              </div>
            )
          // }
        }
      }

      rows.push(
        <div key={"workspace-last"} className="row-5">
          <span className="full-width left-padding-5">
            <Link to={(window.location.pathname.startsWith('/employers')) ? '/employers/' + this.state.accountCode + '/add-orgs' : '/app/add-communities/'} className="background-button row-5 right-padding-20">
              <div className="float-left top-margin-5 right-margin">
                <div className="mini-spacer" />
                <img src={addIconBlue} alt="Guided Compass members icon" className="image-auto-12" />
              </div>
              <div className="float-left">
                <p>Join Another Community</p>
              </div>
            </Link>
          </span>
        </div>
      )

      return rows
    }

    workspaceClicked(org) {
      console.log('workspaceClicked', org)

      if (window.location.pathname.startsWith('/employers')) {
        console.log('in employers: ')
        if (this.state.activeOrg !== org.orgCode) {
          const activeOrg = org.orgCode
          // if (this.state.activeOrg !== this.state.sharePartners[index]) {
          //   activeOrg = this.state.sharePartners[index]
          // }

          this.setState({ activeOrg })

          const orgName = org.orgName
          const orgLogo = org.webLogoURIColor

          localStorage.setItem('activeOrg', activeOrg)
          localStorage.setItem('orgName', orgName)
          localStorage.setItem('orgLogo', orgLogo)

          const accountCode = this.state.accountCode
          const updatedAt = new Date()

          Axios.post('/api/account/update', {
            accountCode, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Account update worked', response.data);
              this.setState({

                serverPostSuccess: true,
                serverSuccessMessage: 'Account information saved successfully!', showWorkspaces: false
              })

              // this.props.passOrgs(activeOrg)
              if (this.props.loadWorkspace) {
                this.props.loadWorkspace(activeOrg)
              }

            } else {
              console.error('there was an error updating the account info');
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Account info save did not work', error);
          });
        }

      } else {
        if (org.orgCode !== this.state.activeOrg) {

          this.setState({ serverSuccessMessage: null, serverErrorMessage: null })

          const emailId = this.state.emailId
          const activeOrg = org.orgCode
          const updatedAt = new Date()

          Axios.post('/api/users/profile/details', {
            emailId, activeOrg, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Org switch worked', response.data);

              this.setState({ activeOrg, showWorkspaces: false })

              const orgName = org.orgName
              const orgLogo = org.webLogoURIColor

              localStorage.setItem('activeOrg', activeOrg)
              localStorage.setItem('orgName', orgName)
              localStorage.setItem('orgLogo', orgLogo)
              // console.log("a7sdf8a7sdfg8asd", orgName)
              if (this.props.loadWorkspace) {
                this.props.loadWorkspace(activeOrg)
              }

            } else {
              console.error('there was an error switching the orgs');

            }
          }).catch((error) => {
              console.log('Org switch did not work', error);
          });
        }
      }
    }

    renderModal() {
      // console.log('renderModal called')

      return (
        <div key="modal">
          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           >


            {(this.state.showWorkspaceInfo) && (
              <div className="full-width padding-30">
                <p className="heading-text-2">About Communities</p>

                <div className="spacer" />

                <div className="row-10">
                  <p><label className="cta-color bold-text">Communities</label> are dedicated portals or workspaces of educational, workforce, staffing, and other career development institutions. Your core profile transfers, but events, projects, work opportunities, pathways, and members will differ.</p>
                </div>

                <div className="row-10">
                  <p>Use communities to connect with others, gain advocates, gain clarity, and get referred for opportunities that are a strong fit for you.</p>
                </div>


                <div className="spacer"/>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>

              </div>
            )}

          </Modal>
        </div>
      )
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showWorkspaceInfo: false })

    }

    changeSubNavSelected(subNavSelected, resetStateVar) {
      console.log('changeSubNavSelected called', subNavSelected, resetStateVar)

      this.setState({ [resetStateVar]: false})
      if (this.props.changeSubNavSelected) {
        this.props.changeSubNavSelected(subNavSelected)
      }
    }

    testHiddenTabs(name) {
      // console.log('testHiddenTabs called', name, this.state.hiddenTabs)

      let hiddenTabs = this.state.hiddenTabs
      if (!hiddenTabs) {
        return true
      } else {
        const index = hiddenTabs.findIndex(ht => ht.name === name)
        // console.log('show the index: ', index)
        if (hiddenTabs[index] && hiddenTabs[index].hidden) {
          return false
        } else {
          return true
        }
      }
    }

    renderDesktopNav(orgLogo,adviseeTerm, employerLogo) {
      console.log('renderDesktopNav called', this.state.roleName)

      const textSizeClass = "description-text-3"

      return (
        <div key='renderDesktopNav'>
          <div className="app-nav-2">

              {(window.location.pathname.startsWith('/employers')) ? (
                <div>
                  {(this.state.internalToolEnabled && this.state.employerLogoWhiteURI) ? (
                    <div>
                      <Link to={'/employers/' + this.props.emp + '/dashboard'}><img src={this.state.employerLogoWhiteURI} alt="GC" className="logo-nav" /></Link>
                    </div>
                  ) : (
                    <Link to={'/employers/' + this.props.emp + '/dashboard'}><img src={logoImg} alt="Compass logo" className="logo-nav" /></Link>
                  )}
                  {(this.state.activeOrg && this.state.orgLogo && !this.state.internalToolEnabled) && (
                    <div className="float-left left-padding right-padding-5">
                      <div className="float-left">
                        <Link to={'/employers/' + this.props.emp + '/dashboard'}><img src={addIconWhite} alt="Guided Compass add logo" className="image-auto-20 float-left top-margin-23" /></Link>
                      </div>
                      <div className="float-left">
                        <Link to={'/employers/' + this.props.emp + '/dashboard'}><img src={this.state.orgLogo} alt="GC" className="logo-nav" /></Link>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {(!this.state.employerAccount) && (
                    <div>
                      {(orgLogo) ? (
                        <Link to={this.state.prefix + '/dashboard'}><img src={orgLogo} alt="GC" className="logo-nav" /></Link>
                      ) : (
                        <Link to={this.state.prefix + '/dashboard'}><img src={logoImg} alt="GC" className="logo-nav" /></Link>
                      )}
                    </div>
                  )}

                  {(this.state.employerAccount && this.state.employerLogoWhiteURI) && (
                    <div>
                      <Link to={this.state.prefix + '/dashboard'}><img src={this.state.employerLogoWhiteURI} alt="GC" className="logo-nav" /></Link>
                    </div>
                  )}
                </div>
              )}

              {(this.state.showOrgToggle && !this.state.employerAccount && !this.state.noOrgSwitching && !this.props.fromAdvisor && !this.state.internalToolEnabled && !window.location.pathname.startsWith('/organizations')) && (
                <div className="float-left top-padding-20 left-padding">
                  <button className="background-button" onClick={(this.state.showWorkspaces) ? () => this.setState({ showWorkspaces: false }) : () => this.setState({ showWorkspaces: true })}>
                    <img src={dropdownArrowWhite} alt="GC" className="image-auto-8" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Switch communities (org)"/>
                  </button>
                </div>
              )}

              {(!window.location.pathname.startsWith('/employers')) && (
                <span className="display-inline max-width-15-percent min-width-150 left-margin">
                  <div className="float-left white-background left-padding left-margin top-margin-15 relative-position">
                    <div className="float-left top-margin-8">
                      <img src={searchIcon} alt="GC" className="image-auto-15"/>
                    </div>
                    <div className="calc-column-offset-40">
                      <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
                    </div>
                    <div className="clear" />

                    {(this.state.showSearchResults && this.state.searchString !== '') && (
                      <div className={(window.innerWidth < 767) ? "display-inline absolute-position white-background z-index-1 standard-border padding-10 top-margin full-width" : "display-inline absolute-position white-background z-index-1 standard-border padding-10 top-margin-25 width-550"}>
                        {this.renderSearchResults()}
                      </div>
                    )}
                  </div>
                </span>
              )}

              <ul className={"nav-items-short flex-container row-direction justify-end"}>
                  <li>
                    <Link to={this.state.prefix + '/dashboard'} className={"flex-container flex-center"}>
                      <img src={homeIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                    </Link>
                    <Link to={this.state.prefix + '/dashboard'}>
                      <p className={"full-width center-text " + textSizeClass}>Home</p>
                    </Link>
                    {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/dashboard')) && <div className="full-width height-3 white-background float-left" />}
                    <div className="clear"/>
                  </li>

                  {(window.location.pathname.startsWith('/employers') && !this.state.internalToolEnabled) && (
                    <li>
                      <Link to={this.state.prefix + '/postings'} className={"flex-container flex-center"}>
                        <img src={jobsIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/postings'}>
                        <p className={"full-width center-text " + textSizeClass}>Postings</p>
                      </Link>
                      {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/postings')) && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}
                  {(window.location.pathname.startsWith('/employers') && (this.state.activeOrg === 'guidedcompass' || this.state.showModule) && !this.state.internalToolEnabled) && (
                    <li>
                      <Link to={'/employers/' + this.props.emp + '/benchmarks'} className={"flex-container flex-center"}>
                        <img src={benchmarksIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/benchmarks'}>
                        <p className={"full-width center-text " + textSizeClass}>Benchmarks</p>
                      </Link>
                      {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/benchmarks')) && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}
                  {(window.location.pathname.startsWith('/employers') && (this.state.activeOrg === 'guidedcompass' || this.state.showModule) && !this.state.internalToolEnabled) && (
                    <li>
                      <Link to={'/employers/' + this.props.emp + '/pipelines'} className={"flex-container flex-center"}>
                        <img src={pipelineIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/pipelines'}>
                        <p className={"full-width center-text " + textSizeClass}>Pipelines</p>
                      </Link>
                      {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/pipelines')) && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}
                  {(window.location.pathname.startsWith('/employers') && (this.state.activeOrg === 'guidedcompass' || this.state.showModule) && !this.state.internalToolEnabled) && (
                    <li>
                      <Link to={'/employers/' + this.props.emp + '/communities'} className={"flex-container flex-center"}>
                        <img src={socialIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/communities'}>
                        <p className={"full-width center-text " + textSizeClass}>Communities</p>
                      </Link>
                      {((window.location.pathname === this.state.prefix || window.location.pathname === this.state.prefix + '/communities')) && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>

                    </li>
                  )}

                  {(this.testHiddenTabs('Community') && (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName === 'Mentor' || this.state.roleName === 'Admin')))) && (
                    <li>
                      {(this.state.unreadRequests) ? (
                        <span>
                          <div className={"float-left"}>
                            <Link to={this.state.prefix + '/community'} className={"flex-container flex-center"}>
                              <img src={socialIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5 float-left"}/>
                            </Link>
                            <Link to={this.state.prefix + '/community'}>
                              <p className={"full-width center-text " + textSizeClass}>Community</p>
                            </Link>
                          </div>
                          <span className={"noti-bubble description-text-4 float-left error-background-color top-margin-12 left-margin-negative-20"}>{this.state.unreadRequests}</span>
                          <div className="clear" />
                          {(window.location.pathname === this.state.prefix + '/community') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      ) : (
                        <span>
                          <Link to={this.state.prefix + '/community'} className={"flex-container flex-center"}>
                            <img src={socialIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                          </Link>
                          <Link to={this.state.prefix + '/community'}>
                            <p className={"full-width center-text " + textSizeClass}>Community</p>
                          </Link>
                          {(window.location.pathname === this.state.prefix + '/community') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      )}
                    </li>
                  )}
                  {(window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor')) || window.location.pathname.startsWith('/organizations')) && (
                    <li>
                      {(!this.state.unreadMessageCount || this.state.unreadMessageCount === 0 ) ? (
                        <span>
                          <Link to={this.state.prefix + '/messaging'} className={"flex-container flex-center"}>
                            <img src={messageIconWhite} alt="GC" className={"image-25-auto top-margin-8 bottom-padding-5 bottom-margin-negative-5"} />
                          </Link>
                          <Link to={this.state.prefix + '/messaging'}>
                            <p className={"full-width center-text " + textSizeClass}>Messages</p>
                          </Link>
                          {(window.location.pathname === this.state.prefix + '/messaging') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      ) : (
                        <span className={""}>
                          <div className={"float-left"}>
                            <Link to={this.state.prefix + '/messaging'} className={"flex-container flex-center"}>
                              <img src={messageIconWhite} alt="GC" className={"image-auto-25 top-margin-5 bottom-margin-negative-5 float-left"} />
                            </Link>
                            <Link to={this.state.prefix + '/messaging'}>
                              <p className={"full-width center-text " + textSizeClass}>Messages</p>
                            </Link>
                          </div>
                          <span className={"noti-bubble description-text-4 float-left error-background-color top-margin-12 left-margin-negative-20"}>{this.state.unreadMessageCount}</span>
                          <div className="clear" />
                          {(window.location.pathname === this.state.prefix + '/messaging') && <div className="full-width height-3 white-background float-left" />}
                          <div className="clear" />
                        </span>
                      )}
                    </li>
                  )}

                  {( this.state.unreadNotificationsCount === 0 ) ? (
                    <li>
                      <Link to={this.state.prefix + '/notifications'} className={"flex-container flex-center"}>
                        <img src={notificationsWhite} alt="GC" className={"image-28-fit top-margin-5 bottom-padding-4 bottom-margin-negative-7"} />
                      </Link>
                      <Link to={this.state.prefix + '/notifications'}>
                        <p className={"full-width center-text " + textSizeClass}>Notifications</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/notifications') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  ) : (
                    <li className={""}>
                      <div className={"float-left"}>
                        <Link to={this.state.prefix + '/notifications'} className={"flex-container flex-center"}>
                          <img src={notificationsWhite} alt="GC" className={"image-auto-25 top-margin-5 bottom-margin-negative-5 float-left"} />
                        </Link>
                        <Link to={this.state.prefix + '/notifications'}>
                          <p className={"full-width center-text " + textSizeClass}>Notifications</p>
                        </Link>
                      </div>

                      <span className={"noti-bubble description-text-4 float-left error-background-color top-margin-12 left-margin-negative-20"}>{this.state.unreadNotificationsCount}</span>

                      <div className="clear" />
                      {(window.location.pathname === this.state.prefix + '/notifications') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations')) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showMemberTabs) ? () => this.setState({ showMemberTabs: false }) : () => this.setState({ showMemberTabs: true })}>
                          <img src={membersIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"}/>
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showMemberTabs) ? () => this.setState({ showMemberTabs: false }) : () => this.setState({ showMemberTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showMemberTabs) ? () => this.setState({ showMemberTabs: false }) : () => this.setState({ showMemberTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Members</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin'))) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showCommunityTabs) ? () => this.setState({ showCommunityTabs: false }) : () => this.setState({ showCommunityTabs: true })}>
                          <img src={socialIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"} />
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showCommunityTabs) ? () => this.setState({ showCommunityTabs: false }) : () => this.setState({ showCommunityTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showCommunityTabs) ? () => this.setState({ showCommunityTabs: false }) : () => this.setState({ showCommunityTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Community</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/community') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  <li><div className={"width-1 height-66 white-background"} style={{ marginLeft: '-3%', marginRight: '-10%', textAlign: 'center'}} /></li>

                  {(this.testHiddenTabs('Learning') && (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName === 'Mentor' || this.state.roleName === 'Admin')))) && (
                    <li>
                      {/*<li style={{ marginRight: '-1%'}}><div className="width-1 height-40 white-background" style={{ marginLeft: '-3%', marginRight: '-3%'}} /></li>*/}
                      <Link to={this.state.prefix + '/learning'} className={"flex-container flex-center"}>
                        <img src={courseIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/learning'}>
                        <p className={"full-width center-text " + textSizeClass}>Learning</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/learning') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(this.testHiddenTabs('Explore Paths') && (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName === 'Mentor' || this.state.roleName === 'Admin')))) && (
                    <li>
                      <Link to={this.state.prefix + '/exploration'} className={"flex-container flex-center"}>
                        <img src={pathsIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/exploration'}>
                        <p className={"full-width center-text " + textSizeClass}>Explore Paths</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/exploration') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName === 'Mentor' || this.state.roleName === 'Admin'))) && (
                    <li>
                      <Link to={this.state.prefix + '/opportunities'} className={"flex-container flex-center"}>
                        <img src={opportunitiesIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"}/>
                      </Link>
                      <Link to={this.state.prefix + '/opportunities'}>
                        <p className={"full-width center-text " + textSizeClass}>Opportunities</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/opportunities') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations')) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showLearningTabs) ? () => this.setState({ showLearningTabs: false }) : () => this.setState({ showLearningTabs: true })}>
                          <img src={courseIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"}/>
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showWorkTabs) ? () => this.setState({ showLearningTabs: false }) : () => this.setState({ showLearningTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showLearningTabs) ? () => this.setState({ showLearningTabs: false }) : () => this.setState({ showLearningTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Learning</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin')) && (
                    <li>
                      {/*<li style={{ marginRight: '-1%'}}><div className="width-1 height-40 white-background" style={{ marginLeft: '-3%', marginRight: '-3%'}} /></li>*/}
                      <Link to={this.state.prefix + '/courses'} className={"flex-container flex-center"}>
                        <img src={courseIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/courses'}>
                        <p className={"full-width center-text " + textSizeClass}>Courses</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/learning-modules') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {((window.location.pathname.startsWith('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin'))) && (
                    <li>
                      {/*<li style={{ marginRight: '-1%'}}><div className="width-1 height-40 white-background" style={{ marginLeft: '-3%', marginRight: '-3%'}} /></li>*/}
                      <Link to={this.state.prefix + '/resources'} className={"flex-container flex-center"}>
                        <img src={resourcesIconWhite} alt="GC" className={"width-25 height-25 top-margin-5 bottom-margin-negative-5"} />
                      </Link>
                      <Link to={this.state.prefix + '/resources'}>
                        <p className={"full-width center-text " + textSizeClass}>Resources</p>
                      </Link>
                      {(window.location.pathname === this.state.prefix + '/resources') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations') || (window.location.pathname.startsWith('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin'))) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showPathingTabs) ? () => this.setState({ showPathingTabs: false }) : () => this.setState({ showPathingTabs: true })}>
                          <img src={pathsIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"}/>
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showPathingTabs) ? () => this.setState({ showPathingTabs: false }) : () => this.setState({ showPathingTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showPathingTabs) ? () => this.setState({ showPathingTabs: false }) : () => this.setState({ showPathingTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Pathing</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/pathing') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations')) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showWorkTabs) ? () => this.setState({ showWorkTabs: false }) : () => this.setState({ showWorkTabs: true })}>
                          <img src={opportunitiesIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"}/>
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showWorkTabs) ? () => this.setState({ showWorkTabs: false }) : () => this.setState({ showWorkTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showWorkTabs) ? () => this.setState({ showWorkTabs: false }) : () => this.setState({ showWorkTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Work-Related</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  {(window.location.pathname.startsWith('/organizations')) && (
                    <li>
                      <div className={"relative-position flex-container flex-center"}>
                        <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showOperatingTabs) ? () => this.setState({ showOperatingTabs: false }) : () => this.setState({ showOperatingTabs: true })}>
                          <img src={operationsIconWhite} alt="GC" className={"image-auto-30 top-margin bottom-margin-negative-5"}/>
                        </button>
                        <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                          <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showOperatingTabs) ? () => this.setState({ showOperatingTabs: false }) : () => this.setState({ showOperatingTabs: true })}>
                            <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                          </button>
                        </div>
                      </div>
                      <div className="clear" />
                      <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showOperatingTabs) ? () => this.setState({ showOperatingTabs: false }) : () => this.setState({ showOperatingTabs: true })}>
                        <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Operating</p>
                      </button>
                      <div className="clear"/>
                      {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                      <div className="clear"/>
                    </li>
                  )}

                  <li>
                    <div className={"relative-position flex-container flex-center"}>
                      <button className={"background-button clear-margin clear-padding flex-container flex-center"} onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                        <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconWhite} alt="GC" className={"profile-thumbnail-30 white-outline top-margin bottom-margin-negative-5"}/>
                      </button>
                      <div className={"absolute-position right-0 bottom-0 bottom-margin-negative-10"}>
                        <button className="background-button clear-margin clear-padding top-margin" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                          <img src={dropdownArrowWhite} alt="GC" className="image-auto-8"/>
                        </button>
                      </div>
                    </div>
                    <div className="clear" />
                    <button className="background-button clear-margin clear-padding description-text-1 float-left" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                      <p className={"full-width center-text white-text top-margin-8 " + textSizeClass}>Profile</p>
                    </button>
                    <div className="clear"/>
                    {(window.location.pathname === this.state.prefix + '/edit-profile') && <div className="full-width height-3 white-background float-left" />}
                    <div className="clear"/>
                  </li>
                  <ReactTooltip id="tooltip-placeholder-id" />

              </ul>
              <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}><i className="ion-navicon-round"></i></a>
          </div>

          {(this.state.showWorkspaces) && (
            <div className="workspaces-container">
              <div className="top-padding-15 bottom-padding-5">
                <div className="float-left">
                  <p className="description-text-4">COMMUNITIES</p>
                </div>
                <div>
                  <div className="float-left left-margin noti-bubble-info-7-9" style={{ marginTop: '-6px' }}>
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showWorkspaceInfo: true })}>
                      <img src={questionMarkBlue} alt="GC" className="image-auto-10 center-item" />
                    </a>
                  </div>
                </div>
                <div className="clear" />

              </div>

              {this.renderWorkspaces()}
            </div>
          )}

          {(this.state.dropdownMenus) && (
            <div>
              {this.state.dropdownMenus.map((item, index) =>
                <div>
                  {(this.state[item.showname]) && (
                    <div className={item.positionClass}>
                      <div className="top-padding-15 bottom-padding-5">
                        {item.tabs.map((item2, index2) =>
                          <div key={index2}>
                            <div className="row-7">
                              <span className="full-width left-padding-5">
                                {(item2.url === window.location.pathname) ? (
                                  <button className="background-button row-5 right-padding-20 standard-color left-text full-width" onClick={() => this.changeSubNavSelected(item2.name,item.showname)}>
                                    <div className="fixed-column-40">
                                      <img src={(item2.icon) ? item2.icon : (item.icon) ? item.icon : pathsIconDark} alt="GC" className="image-22" />
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p>{item2.name}</p>
                                    </div>
                                    <div className="clear" />
                                  </button>
                                ) : (
                                  <Link className="background-button row-5 right-padding-20 standard-color full-width" to={item2.url} state={{ subNavSelected: item2.name }}>
                                    <div className="fixed-column-40">
                                      <img src={(item2.icon) ? item2.icon : (item.icon) ? item.icon : pathsIconDark} alt="GC" className="image-22" />
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <p>{item2.name}</p>
                                    </div>
                                    <div className="clear" />
                                  </Link>
                                )}
                              </span>
                            </div>

                            {(index2 === 4 && item.tabs.length > 5) && (
                              <div>
                                <hr className="vertical-margin-20" />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {(this.state.showProfileTabs) && (
            <div className={"profile-dropdown-menu-container-2"}>
              <div className="top-padding bottom-padding-5">

                <Link to={(window.location.pathname.startsWith('/organizations') || window.location.pathname.startsWith('/advisor')) ? this.state.prefix + '/edit-profile/basics' : this.state.prefix + '/edit-profile'} className="background-button standard-color full-width">
                  <div>
                    <div className="fixed-column-50 right-padding">
                      <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1 standard-border" />
                    </div>
                    <div className="calc-column-offset-50">
                      <p className="heading-text-6">{this.state.cuFirstName} {this.state.cuLastName}</p>
                      <p className="description-text-3">{(this.state.headline && this.state.headline !== '') ? this.state.headline : (this.state.roleNameTitle) ? this.state.roleNameTitle : this.state.roleName}{(this.state.orgName) && " | " + this.state.orgName + " Portal"}</p>
                    </div>
                    <div className="clear" />
                  </div>
                </Link>

                <div className="spacer" />

                <hr />

                <div className="row-5">
                  <div className="half-spacer" />
                  {(!window.location.pathname.startsWith('/organizations')) ? (
                    <div>
                      <div className="row-10">
                        {/*<p className="description-text-1">{(this.state.employerAccount || this.props.fromAdvisor || window.location.pathname.startsWith('/organizations') || window.location.pathname.startsWith('/employers')) ? "Core Information" : "Application Materials"}</p>*/}
                        <p className="description-text-1">Core Information</p>
                      </div>
                    </div>
                  ) : (
                    <div className="spacer" />
                  )}

                  {this.state.applicationMaterials.map((item, index) =>
                    <div key={index}>
                      <span className="full-width left-padding-5">
                        <Link className="background-button full-width right-padding-20 standard-color" onClick={() => this.setState({ showProfileTabs: false })} to={item.url}>
                          <div className="row-5">
                            <div className="float-left right-padding">
                              <img src={item.icon} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">{item.name}</p>
                            </div>
                            <div className="clear"/>
                          </div>
                        </Link>
                      </span>
                    </div>
                  )}
                  {(!window.location.pathname.startsWith('/organizations')) && (
                    <div>
                      {(!window.location.pathname.startsWith('/employers')) && (
                        <div>
                          <div className="spacer" />

                          <div className="row-10">
                            <p className="description-text-1">Other Profile Information</p>
                          </div>

                          {this.state.developmentItems.map((item, index) =>
                            <div key={index}>
                              <span className="full-width left-padding-5">
                                <Link className="background-button full-width right-padding-20 standard-color" onClick={() => this.setState({ showProfileTabs: false })} to={item.url}>
                                  <div className="row-5">
                                    <div className="float-left right-padding">
                                      <img src={item.icon} alt="GC" className="image-18-fit" />
                                    </div>
                                    <div className="float-left">
                                      <p className="description-text-2">{item.name}</p>
                                    </div>
                                    <div className="clear"/>
                                  </div>
                                </Link>
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(window.location.pathname.startsWith('/employers') && this.state.activeOrg === 'guidedcompass') && (
                        <div>
                          <div className="spacer" />

                          <div className="row-10">
                            <p className="description-text-1">{(window.location.pathname.startsWith('/employers')) ? "Other Items" : "Other Profile Items"}</p>
                          </div>

                          {this.state.otherProfileItems.map((item, index) =>
                            <div key={index}>
                              <span className="full-width left-padding-5">
                                <Link className="background-button full-width right-padding-20 standard-color" onClick={() => this.setState({ showProfileTabs: false })} to={item.url}>
                                  <div className="row-5">
                                    <div className="float-left right-padding">
                                      <img src={item.icon} alt="GC" className="image-18-fit" />
                                    </div>
                                    <div className="float-left">
                                      <p className="description-text-2">{item.name}</p>
                                    </div>
                                    <div className="clear"/>
                                  </div>
                                </Link>
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />

                  <hr />

                  <div className="spacer" /><div className="spacer" />

                  {(!window.location.pathname.startsWith('/app') && !window.location.pathname.startsWith('/organizations') && ((!window.location.pathname.includes('/advisor')) || (window.location.pathname.includes('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin')))) && (
                    <div>
                      <span className="full-width left-padding-5">
                        <Link className="background-button full-width right-padding-20 standard-color" to={this.state.prefix + '/reporting'}>
                          <div className="row-5">
                            <div className="float-left right-padding">
                              <img src={reportingIconDark} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">Reporting</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </Link>
                      </span>
                    </div>
                  )}

                  <div>
                    <span className="full-width left-padding-5">
                      <Link className="background-button full-width right-padding-20 standard-color" to={this.state.prefix + '/settings'}>
                        <div className="row-5">
                          <div className="float-left right-padding">
                            <img src={settingsIconDark} alt="GC" className="image-18-fit" />
                          </div>
                          <div className="float-left">
                            <p className="description-text-2">Settings & Privacy</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </Link>
                    </span>
                  </div>

                  {(!this.state.remoteAuth) && (
                    <div>
                      <span className="full-width left-padding-5">
                        <button className="background-button full-width right-padding-20 standard-color" onClick={() => signOut(
                          this.state.emailId, this.state.activeOrg, this.state.orgFocus,
                          this.state.accountCode, this.state.roleName, this.props.navigate
                        )}>
                          <div className="row-5">
                            <div className="float-left right-padding">
                              <img src={exitIconDark} alt="GC" className="image-18-fit" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">Log Out</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </button>
                      </span>
                    </div>
                  )}
                </div>

                <div className="clear" />

              </div>
            </div>
          )}

          {this.renderModal()}
        </div>
      )
    }

    renderMobileNav(orgLogo,adviseeTerm) {
      console.log('renderMobileNav called')

      return (
        <div key="renderMobileNav">
          <div className="mobile-app-nav">
            {(!this.state.employerAccount) && (
              <div>
                {(orgLogo) ? (
                  <Link to={this.state.prefix + '/dashboard'}><img src={orgLogo} alt="GC" className="logo-nav" /></Link>
                ) : (
                  <Link to={this.state.prefix + '/dashboard'}><img src={logoImg} alt="GC" className="logo-nav" /></Link>
                )}
              </div>
            )}
            {(this.state.employerAccount && this.state.employerLogoWhiteURI) && (
              <div>
                <Link to={this.state.prefix + '/dashboard'}><img src={this.state.employerLogoWhiteURI} alt="GC" className="logo-nav" /></Link>
              </div>
            )}

            {(this.state.showOrgToggle && (!window.location.pathname.startsWith('/advisor') && !window.location.pathname.startsWith('/organizations'))) && (
              <div className="float-left top-padding-20 left-padding">
                <button className={(this.state.showWorkspaces) ? "background-button" : "background-button rotate-90"} onClick={(this.state.showWorkspaces) ? () => this.setState({ showWorkspaces: false }) : () => this.setState({ showWorkspaces: true })}>
                  <img src={dropdownArrowWhite} alt="GC" className="image-auto-8" />
                </button>
              </div>
            )}
            {/*
            {(window.location.pathname.startsWith('/advisor') && (this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin')) ? (
              <div className="calc-column-offset-200 white-background left-padding left-margin top-margin-15">
                <div className="float-left top-margin-8">
                  <img src={searchIcon} alt="GC" className="image-auto-15"/>
                </div>
                <div className="calc-column-offset-40">
                  <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
                </div>
                <div className="clear" />
              </div>
            ) : (
              <div className="calc-column-offset-200 white-background left-padding left-margin top-margin-15">
                <div className="float-left top-margin-8">
                  <img src={searchIcon} alt="GC" className="image-auto-15"/>
                </div>
                <div className="calc-column-offset-40">
                  <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
                </div>
                <div className="clear" />
              </div>
            )}*/}

            <div className="calc-column-offset-200 white-background left-padding left-margin top-margin-15">
              <div className="float-left top-margin-8">
                <img src={searchIcon} alt="GC" className="image-auto-15"/>
              </div>
              <div className="calc-column-offset-40">
                <input type="text" className="text-field description-text-2 clear-border full-width" placeholder="Search..." onKeyPress={this.handleKeyPress} value={this.state.searchString} onChange={this.handleChange}/>
              </div>
              <div className="clear" />
            </div>

            {(this.state.showSearchResults && this.state.searchString !== '') && (
              <div className="search-results-container-mobile">
                {this.renderSearchResults()}
              </div>
            )}

            {(this.state.showWorkspaces) && (
              <div className="workspaces-container-mobile">
                {this.renderWorkspaces()}
              </div>
            )}

            {(this.props.roleName === 'Academy Lead' || this.props.roleName === 'Teacher' || this.props.roleName === 'School Support') && (
              <ul className={this.state.mobileNavClass}>
                  <li><Link to={this.state.prefix + '/dashboard'}>Dashboard</Link></li>
                  <li><Link to={this.state.prefix + '/edit-profile'}>Edit Profile</Link></li>
                  <li><Link to={this.state.prefix + '/members'}>Members</Link></li>
                  <li><Link to={this.state.prefix + '/groups'}>Groups</Link></li>
                  <li><Link to={this.state.prefix + '/news-feed'}>News Feed</Link></li>
                  <li><Link to={this.state.prefix + '/projects'}>Projects</Link></li>
                  <li><Link to={this.state.prefix + '/goals'}>Goals</Link></li>
                  <li><Link to={this.state.prefix + '/endorsements'}>Endorsements</Link></li>
                  <li><Link to={this.state.prefix + '/exchange'}>Curriculum Exchange</Link></li>
                  <li><Link to={this.state.prefix + '/courses'}>Courses</Link></li>
                  <li><Link to={this.state.prefix + '/resources'}>Resources</Link></li>
                  <li><Link to={this.state.prefix + '/careers'}>Careers</Link></li>
                  <li><Link to={this.state.prefix + '/trends'}>Trends</Link></li>
                  <li><Link to={this.state.prefix + '/career-plans'}>Career Plans</Link></li>
                  <li><Link to={this.state.prefix + '/financial-plans'}>Financial Plans</Link></li>
                  <li><Link to={this.state.prefix + '/resumes'}>Resumes</Link></li>
                  <li><Link to={this.state.prefix + '/postings'}>Postings</Link></li>
                  <li><Link to={this.state.prefix + '/placements'}>Placements</Link></li>
                  <li><Link to={this.state.prefix + '/timesheets'}>Timesheets</Link></li>
                  <li><Link to={this.state.prefix + '/notifications'}>Notifications</Link></li>
                  <li><Link to={this.state.prefix + '/settings'}>Settings</Link></li>
              </ul>
            )}

            {(this.props.orgFocus === 'Mentor' || this.state.roleName === 'Mentor' || this.state.roleName === 'Admin' || window.location.pathname.startsWith('/app')) && (
              <ul className={this.state.mobileNavClass}>
                  <li><Link to={this.state.prefix + '/dashboard'}>Dashboard</Link></li>
                  <li><Link to={this.state.prefix + '/edit-profile'}>Edit Profile</Link></li>
                  <li><Link to={this.state.prefix + '/community'}>Community</Link></li>
                  <li><Link to={this.state.prefix + '/messaging'}>Messages</Link></li>
                  <li><Link to={this.state.prefix + '/notifications'}>Notifications</Link></li>
                  <li><Link to={this.state.prefix + '/learning'}>Learning</Link></li>
                  <li><Link to={this.state.prefix + '/exploration'}>Explore Paths</Link></li>
                  <li><Link to={this.state.prefix + '/opportunities'}>Opportunities</Link></li>
                  <li><Link to={this.state.prefix + '/settings'}>Settings</Link></li>
              </ul>
            )}

            <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}>
              <img src={naviconIconWhite} alt="GC" className="image-23-fit top-margin-3" />
            </a>
          </div>
        </div>
      )
    }

    render() {

      if (this.props.fromAdvisor) {
        // console.log('in advisor: ', this.props)
        let orgLogo = null
        if (this.state.orgLogo) {
          orgLogo = this.state.orgLogo
        }

        let adviseeTerm = 'advisee'
        if (this.props.orgFocus) {
          if (this.props.orgFocus.toLowerCase() === 'school' || this.props.orgFocus.toLowerCase() === 'academy') {
            adviseeTerm = 'student'
          }
        }

        if (this.state.isMobileView === false) {
          return (
            <nav className="sticky-nav">
              {this.renderDesktopNav(orgLogo,adviseeTerm)}
            </nav>
          )
        } else {
          return (
              <nav>
                {this.renderMobileNav(orgLogo,adviseeTerm)}
              </nav>
          )
        }

      } else if (this.props.fromOrganization) {

        if (this.state.isMobileView === false) {
          // console.log('are we in here? ', this.state.searchString)
          return (
              <nav className="sticky-nav">
                {this.renderDesktopNav(this.state.orgLogo,this.state.adviseeTerm)}
              </nav>
          )
        } else {
            return (
              <nav>
                {/*{this.renderMobileNav(this.state.orgLogo,null)}*/}

                <div className="mobile-app-nav">

                    {(this.state.orgLogo) ? (
                      <Link to={'/'}><img src={this.state.orgLogo} alt="Compass logo" className="logo-nav" /></Link>
                    ) : (
                      <Link to={'/'}><img src={logoImg} alt="Compass logo" className="logo-nav" /></Link>
                    )}
                    <ul className={this.state.mobileNavClass}>
                        <li><Link to={'/organizations/' + this.props.org + '/dashboard'}>Dashboard</Link></li>
                        <li><Link to={'/organizations/' + this.props.org + '/messaging'}>Members</Link></li>
                        <li><Link to={'/organizations/' + this.props.org + '/members'}>Members</Link></li>
                        <li><Link to={'/organizations/' + this.props.org + '/notifications'}>Notifications</Link></li>
                        <li><Link to={'/organizations/' + this.props.org + '/edit-profile/basics'}>Edit Profile</Link></li>
                        <li><Link to={'/organizations/' + this.props.org + '/settings'}>Settings</Link></li>
                    </ul>
                    <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}>
                    <i className="ion-navicon-round"></i></a>
                </div>
              </nav>

            )
        }
      } else if (this.props.fromEmployer) {

        if (this.state.isMobileView === false) {

            return (
                <nav className="sticky-nav">
                  {this.renderDesktopNav(null,null,this.state.employerLogoWhiteURI)}
                </nav>
            )
        } else {
            return (
              <nav>
                <div className="mobile-app-nav">
                  <Link to={'/'}><img src={logoImg} alt="Compass logo" className="logo-nav" /></Link>

                  <ul className={this.state.mobileNavClass}>
                      <li><Link to={'/employers/' + this.props.emp + '/dashboard'}>Dashboard</Link></li>
                      <li><Link to={'/employers/' + this.props.emp + '/postings'}>Postings</Link></li>
                      {(this.props.org === 'guidedcompass') && (
                        <li><Link to={'/employers/' + this.props.emp + '/pipelines'}>Pipelines</Link></li>
                      )}
                      <li><Link to={'/employers/' + this.props.emp + '/hires'}>Hires</Link></li>
                      <li><Link to={'/employers/' + this.props.emp + '/settings'}>Settings</Link></li>
                  </ul>
                  <a className="mobile-app-nav-icon" onClick={() => this.mobileNav()}>
                  <i className="ion-navicon-round"></i></a>
                </div>
              </nav>
            )
        }
      } else {

        if (this.state.isMobileView === false) {

            let orgLogo = null
            if (this.state.orgLogo) {
              orgLogo = this.state.orgLogo
            }

            return (
                <nav className="sticky-nav">
                  {this.renderDesktopNav(orgLogo,null)}
                </nav>
            )
        } else {

          let orgLogo = null
          if (this.state.orgLogo) {
            orgLogo = this.state.orgLogo
          }

          return (

              <nav>
                {this.renderMobileNav(orgLogo,null)}
                {this.renderModal()}
              </nav>
          )
        }
      }
    }
}

export default withRouter(AppNavigation);
