import React, {Component} from 'react';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const editIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditSubscription extends Component {
    constructor(props) {
      super(props)
      this.state = {

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.confirmEdit = this.confirmEdit.bind(this)
      this.cancelSubscription = this.cancelSubscription.bind(this)
      this.calculateTotal = this.calculateTotal.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within subEditSubscription')

      const email = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const activeOrg = localStorage.getItem('activeOrg');
      const accountCode = localStorage.getItem('accountCode');

      const subscriptionType = null

      this.setState({
        email, cuFirstName, cuLastName, orgFocus, roleName, activeOrg
      })

      const isActive = true
      const includeMembership = true

      const subParams = {
        email, orgCode: activeOrg, accountCode, subscriptionType, isActive, includeMembership, allOrgAccounts: true
      }

      // remove: includeMembership, isActive
      // add: subscriptionType: Organization, allOrgAccounts

      // console.log('subscription params in EditSubscription: ', subParams)

      Axios.get('/api/payments/subscription', { params: subParams})
      .then((response) => {
        console.log('Subscription query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved subscription')

           const subscription = response.data.subscription

           let createdAt = subscription.createdAt
           let nextPaymentDate = null
           if (createdAt) {
             const createdAtDate = new Date(createdAt)
             let createdAtDay = createdAtDate.getDate()
             if (createdAtDay === 29 || createdAtDay === 30 || createdAtDay === 31) {
               createdAtDay = 28
             }

             // today day
             const todayDate = new Date().getDate()

             if (todayDate < createdAtDay) {
               // payment is this month

               const year = new Date().getFullYear()
               const month = new Date().getMonth()
               nextPaymentDate = new Date(year, month, createdAtDay, 0,0,0,0)
               nextPaymentDate = convertDateToString(new Date(nextPaymentDate),"rawDateTimeForInput")

             } else {
               // payment is next month
               const nextMonthDate = new Date(new Date().setMonth(new Date().getMonth()+1));

               const year = nextMonthDate.getFullYear()
               const month = nextMonthDate.getMonth()
               nextPaymentDate = new Date(year, month, createdAtDay, 0,0,0,0)
               nextPaymentDate = convertDateToString(new Date(nextPaymentDate),"rawDateTimeForInput")

             }

             // nextPaymentDate = new Date(createdAt)
             console.log('show createdAt: ', createdAt, typeof createdAt, createdAtDate, createdAtDay, nextPaymentDate)

           }

           this.setState({ subscription, nextPaymentDate })

         } else {
           console.log('no org data found', response.data.message)

         }

      }).catch((error) => {
         console.log('Subscription query did not work', error);

      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'quantity') {
        let subscription = this.state.subscription
        subscription['quantity'] = event.target.value
        this.setState({ subscription })
      }
    }

    confirmEdit() {
      console.log('confirmEdit called')

      this.setState({ successMessage: null, errorMessage: null })

      const _id = this.state.subscription._id
      const quantity = this.state.subscription.quantity
      const stripeId = this.state.subscription.subscriptionId
      const priceId = this.state.subscription.priceId

      Axios.post('/api/payments/edit-subscription', {
        _id, quantity, stripeId, priceId
      }).then((response) => {

        if (response.data.success) {
          const successMessage = 'Edit subscription save worked'
          console.log(successMessage, response.data);
          const editQuantity = false
          this.setState({ successMessage, editQuantity })

        } else {
          const errorMessage = response.data.message
          console.log(errorMessage);
          this.setState({ errorMessage })
        }

      }).catch((error) => {
        const errorMessage = error
        console.log(errorMessage);
        this.setState({ errorMessage })
      });
    }

    cancelSubscription() {
      console.log('cancelSubscription called')

      this.setState({ successMessage: null, errorMessage: null, isSaving: true })

      const _id = this.state.subscription._id
      const stripeId = this.state.subscription.subscriptionId

      let firstName = this.state.subscription.firstName
      let email = this.state.subscription.email
      if (this.state.subscription.subscriptionType !== 'Individual') {
        firstName = this.state.subscription.contactFirstName
        email = this.state.subscription.contactEmail
      }

      Axios.post('/api/payments/cancel-subscription', {
        _id, stripeId, email, firstName
      }).then((response) => {

        if (response.data.success) {

          const successMessage = 'Your subscription has been successfully cancelled! Please look out for an email confirmation.'
          console.log(successMessage, response.data);
          const subscription = null
          this.setState({ successMessage, subscription, confirmCancel: false, isSaving: false })

        } else {
          const errorMessage = response.data.message
          console.log(errorMessage);
          this.setState({ errorMessage, isSaving: false })
        }

      }).catch((error) => {
        const errorMessage = error
        console.log(errorMessage);
        this.setState({ errorMessage, isSaving: false })
      });
    }

    calculateTotal(price,quantity,discount,promoDiscount) {
      console.log('calculateTotal called')

      let total = price * quantity
      if (discount) {
        total = (1 - discount) * total
      }
      if (promoDiscount) {
        total = (1 - promoDiscount) * total
      }
      return total
    }

    render() {

      return (
        <div>
          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color bold-text top-padding">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color bold-text top-padding">{this.state.successMessage}</p>}

          {(this.state.subscription) ? (
            <div className={(this.props.pageSource === 'pricingPage') && "description-text-2"}>
              {(this.props.pageSource === 'pricingPage') ? (
                <div className="top-padding-20 bottom-padding">
                  <p className="half-bold-text">You have an existing subscription. Cancel it below to create a new plan.</p>
                </div>
              ) : (
                <div className="row-10">
                  <p className="heading-text-4">Your Account Subscription</p>
                </div>
              )}

              {(this.state.isSaving) ? (
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                </div>
              ) : (
                <div>
                  {(this.state.subscription.subscriptionType === 'Employer') && (
                    <div className="row-10">
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-5 cta-color half-bold-text">{this.state.subscription.employerName} Basic Subscription</p>
                          <p className="description-text-2 half-bold-text">{this.state.subscription.quantity} Licenses | 1,000 Members | Created on {convertDateToString(new Date(this.state.subscription.createdAt),"date-2")}</p>

                          <div className="row-10">
                            <p className="description-text-2">This subscription allows you to create customized talent pipelines and career pathways, career development functionality, unlimited posts, and timesheet functionality. <a href="https://www.guidedcompass.com/employers" target="_blank" rel="noopener noreferrer">Learn More</a></p>
                          </div>
                        </div>
                        <div className="fixed-column-50">
                          <label className="description-text-2">$</label><label className="heading-text-3 cta-color">{this.calculateTotal(this.state.subscription.price,this.state.subscription.quantity,this.state.subscription.discount,this.state.subscription.promoDiscount)}</label>
                          <p className="description-text-2">/ mo</p>
                        </div>
                        <div className="fixed-column-30">
                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <div>
                            <button type="button" className="background-button" onClick={() => this.setState({ editQuantity: true })}>
                              <img src={editIconBlue} alt="GC" className="image-auto-15" />
                            </button>
                          </div>

                          <div className="top-margin-5">
                            <button type="button" className="background-button" onClick={() => this.setState({ confirmCancel: true })}>
                              <img src={closeIcon} alt="GC" className="image-auto-15" />
                            </button>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.editQuantity) && (
                        <div className="row-10">
                          <div className="float-left">
                            <label className="profile-label">Edit Number of Licenses</label>
                            <input className="number-field" type="number" placeholder="10" min="10" name="quantity" value={this.state.subscription.quantity} onChange={this.formChangeHandler} />
                          </div>
                          <div className="fixed-column-100 left-padding">
                            <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="mini-spacer" />
                            <button type="button" className="btn btn-squarish" onClick={() => this.confirmEdit()}>Change</button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.subscription.subscriptionType === 'Organization') && (
                    <div className="row-10">
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-5 cta-color half-bold-text">{this.state.subscription.orgName} Member Subscription</p>
                          <p className="description-text-2 half-bold-text">{this.state.subscription.quantity} Licenses | {this.state.subscription.members.length} Members | Created on {convertDateToString(new Date(this.state.subscription.createdAt),"date-2")}</p>

                          <div className="row-10">
                            <p className="description-text-2">This subscription allows members to use Guided Compass for career exploration, mentor management, career event, projects, and work opportunities. <a href="https://www.guidedcompass.com/workforce" target="_blank" rel="noopener noreferrer" >Learn More</a></p>
                          </div>
                        </div>
                        <div className="fixed-column-50">
                          <label className="description-text-2">$</label><label className="heading-text-3 cta-color">{this.calculateTotal(this.state.subscription.price,this.state.subscription.quantity,this.state.subscription.discount,this.state.subscription.promoDiscount)}</label>
                          <p className="description-text-2">/ mo</p>
                        </div>
                        <div className="fixed-column-30">
                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <div>
                            <button type="button" className="background-button" onClick={() => this.setState({ editQuantity: true })}>
                              <img src={editIconBlue} alt="GC" className="image-auto-15" />
                            </button>
                          </div>

                          <div className="top-margin-5">
                            <button type="button" className="background-button" onClick={() => this.setState({ confirmCancel: true })}>
                              <img src={closeIcon} alt="GC" className="image-auto-15" />
                            </button>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.editQuantity) && (
                        <div className="row-10">
                          <div className="float-left">
                            <label className="profile-label">Edit Number of Licenses</label>
                            <input className="number-field" type="number" placeholder="10" min="10" name="quantity" value={this.state.subscription.quantity} onChange={this.formChangeHandler} />
                          </div>
                          <div className="fixed-column-100 left-padding">
                            <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="mini-spacer" />
                            <button type="button" className="btn btn-squarish" onClick={() => this.confirmEdit()}>Change</button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.subscription.subscriptionType === 'Individual') && (
                    <div className="row-10">
                      <div>
                        <div className="calc-column-offset-80">
                          <p className="heading-text-5 cta-color half-bold-text">Guided Compass Basic Subscription</p>
                          <p className="description-text-2 half-bold-text">Created on {convertDateToString(new Date(this.state.subscription.createdAt),"date-2")} | Next payment: {convertDateToString(new Date(this.state.nextPaymentDate),"date-2")}</p>

                          <div className="row-10">
                            <p className="description-text-2">This subscription allows you to use Guided Compass for career exploration, career event, projects, and work opportunities. <a href="https://www.guidedcompass.com/career-seekers" target="_blank" rel="noopener noreferrer" >Learn More</a></p>
                          </div>
                        </div>
                        <div className="fixed-column-50">
                          <label className="description-text-2">$</label><label className="heading-text-3 cta-color">{this.calculateTotal(this.state.subscription.price,this.state.subscription.quantity,this.state.subscription.discount,this.state.subscription.promoDiscount)}</label>
                          <p className="description-text-2">/ mo</p>
                        </div>
                        <div className="fixed-column-30">
                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <div className="top-margin-5">
                            <button type="button" className="background-button" onClick={() => this.setState({ confirmCancel: true })}>
                              <img src={closeIcon} alt="GC" className="image-auto-15" />
                            </button>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.editQuantity) && (
                        <div className="row-10">
                          <div className="float-left">
                            <label className="profile-label">Edit Number of Licenses</label>
                            <input className="number-field" type="number" placeholder="10" min="10" name="quantity" value={this.state.subscription.quantity} onChange={this.formChangeHandler} />
                          </div>
                          <div className="fixed-column-100 left-padding">
                            <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="mini-spacer" />
                            <button type="button" className="btn btn-squarish" onClick={() => this.confirmEdit()}>Change</button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>
                  )}

                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}

                  {(this.state.confirmCancel) && (
                    <div className="bottom-padding">
                      <p className="heading-text-4 error-color">Are you sure?</p>

                      <div className="row-10">
                        <p>Canceling your subscription will mean that you will no longer be able to access event, project, and work opportunities.</p>
                      </div>

                      <div className="row-10">
                        <button className="btn btn-squarish error-background-color error-border-color white-text right-margin" disabled={this.state.isSaving} onClick={() => this.cancelSubscription()}>Confirm Cancel</button>
                        <button className="btn btn-squarish white-background cta-border cta-color" onClick={() => this.setState({ confirmCancel: false })}>Cancel</button>
                      </div>

                      <div className="spacer" />

                    </div>
                  )}

                </div>
              )}

              <hr />

            </div>
          ) : (
            <div className="top-margin-20">
              <p className="full-width center-text">No subscription(s) found</p>
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(EditSubscription);
