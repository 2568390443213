import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubOpportunityDetails from './Subcomponents/OpportunityDetails';
import withRouter from './Functions/WithRouter';

class OpportunityDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.loadWorkspace = this.loadWorkspace.bind(this)
      this.commandClicked = this.commandClicked.bind(this)
      this.passActiveOrg = this.passActiveOrg.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('opportunityDetails parent mounted')

      let { opportunityId, referralCode } = this.props.params

      let email = localStorage.getItem('email');
      if (!email || email === '') {
        console.log('user is not logged in')

        Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
        .then((response) => {
           console.log('Posting detail by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             if (response.data.posting) {

               let orgCode = null
               if (response.data.posting.postingOrgCode) {
                 orgCode = response.data.posting.postingOrgCode
               } else if (response.data.posting.orgCode) {
                 orgCode = response.data.posting.orgCode
               }

               this.props.navigate('/opportunities/organizations/' + orgCode + '/' + opportunityId, { state: { opportunityId }})

             }
           }
        }).catch((error) => {
            console.log('Posting query did not work', error);
        });

      } else {

        if (!this.props.location.state) {
          console.log('went directly to posting')

          console.log('show opportunityId: ', opportunityId, referralCode)

          Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
          .then((response) => {
             console.log('Posting detail by id query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved posting')

               const selectedOpportunity = response.data.posting
               this.retrieveData(selectedOpportunity, referralCode)
             }
          }).catch((error) => {
              console.log('Posting query did not work', error);
          });

        } else {

          const { selectedOpportunity, objectId } = this.props.location.state;
          console.log('show location state: ', this.props.location.state)

          if (selectedOpportunity) {

            this.retrieveData(selectedOpportunity)

          } else {

            if (objectId) {
              opportunityId = objectId
            }

            Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
            .then((response) => {
               console.log('Posting detail by id query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved posting')

                 const selectedOpportunity = response.data.posting
                 this.retrieveData(selectedOpportunity)

               }
            }).catch((error) => {
                console.log('Posting query did not work', error);
            });
          }
        }
      }
    }

    retrieveData(selectedOpportunity, referralCode) {
      console.log('retrieveData', selectedOpportunity, referralCode)

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      const path = window.location.pathname

      this.setState({ emailId: email, username, cuFirstName, cuLastName,
        roleName, activeOrg, orgLogo, selectedOpportunity, path, referralCode })
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      // if (this.props.location && ((this.props.location.state && !prevProps.location) || (this.props.location.state !== prevProps.location.state))) {
      //   // console.log('change pathway id')
      //   this.retrieveData(this.props.location.state.selectedOpportunity,this.state.referralCode)
      // }
      // if (this.props.location && this.props.location.state && this.props.location.state.selectedOpportunity && (!prevProps.location.state || this.props.location.state.selectedOpportunity !== prevProps.location.state.selectedOpportunity)) {
      //   this.retrieveData(this.props.location.state.selectedOpportunity,this.state.referralCode)
      // }
      if (this.props.params && this.props.params.opportunityId && (!prevProps.params.opportunityId || this.props.params.opportunityId !== prevProps.params.opportunityId)) {

        Axios.get('/api/postings/byid', { params: { _id: this.props.params.opportunityId } })
        .then((response) => {
           console.log('Posting detail by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             const selectedOpportunity = response.data.posting
             this.retrieveData(selectedOpportunity, this.state.referralCode)
             // this.retrieveData(this.props.location.state.selectedOpportunity,this.state.referralCode)
           }
        }).catch((error) => {
            console.log('Posting query did not work', error);
        });
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    passActiveOrg(activeOrg) {
      console.log('passActiveOrg called', activeOrg)

      this.setState({ activeOrg })
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} loadWorkspace={this.loadWorkspace} skipRedirect={true} />

          <div>
            <SubOpportunityDetails selectedOpportunity={this.state.selectedOpportunity} path={this.state.path}
            opportunityId={this.state.opportunityId} activeOrg={this.state.activeOrg} referralCode={this.state.referralCode}
            commandClicked={this.commandClicked} passActiveOrg={this.passActiveOrg}
            />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo, this.state.passedCommand)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OpportunityDetails)
