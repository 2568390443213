import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Axios from 'axios';
import SubCustomDropdown from '../Common/CustomDropdown';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const favoriteIconSelected  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Employers extends Component {
    constructor(props) {
        super(props)

        this.state = {
          emailId: null,
          username: '',
          org: '',
          filteredEmployers: [],
          timerId: 0,
          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          defaultFilterOption: 'Everyone',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],

          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.renderManipulators = this.renderManipulators.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)

        this.renderEmployers = this.renderEmployers.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      //   this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.activeOrg)

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (email && org) {

        //filters
        const defaultFilterOption = this.state.defaultFilterOption
        const defaultSortOption = this.state.defaultSortOption

        let industryFilterOptions = []
        let politicalPartyFilterOptions = []
        let typeFilterOptions = []
        let employeeCountFilterOptions = []
        let employeeGrowthFilterOptions = []
        let foundingYearFilterOptions = []
        let activeFilterOptions = []

        //sort options
        let postSortOptions = []
        let eventSortOptions = []
        let applicationSortOptions = []
        let interviewSortOptions = []
        let hireSortOptions = []
        let projectedHireSortOptions = []

        let employerFilters = []
        let employerSorters = []

        //employerFilters
        typeFilterOptions = [defaultFilterOption]
        industryFilterOptions = [defaultFilterOption]
        employeeCountFilterOptions = [defaultFilterOption]
        employeeGrowthFilterOptions = [defaultFilterOption]
        politicalPartyFilterOptions = [defaultFilterOption]
        foundingYearFilterOptions = [defaultFilterOption,'Before 25 Years Ago','Before 10 Years Ago','Before 5 Years Ago','Before 3 Years Ago','3 Years Ago or After']
        activeFilterOptions = [defaultFilterOption,'Is Active','Is Not Active']

        employerFilters = [
          { name: 'Type', value: defaultFilterOption, options: typeFilterOptions},
          { name: 'Industry', value: defaultFilterOption, options: typeFilterOptions},
          { name: '# of Employees', value: defaultFilterOption, options: employeeCountFilterOptions},
          { name: 'Employee Growth', value: defaultFilterOption, options: employeeGrowthFilterOptions},
          { name: 'Founding Year', value: defaultFilterOption, options: foundingYearFilterOptions},
          { name: 'Active', value: defaultFilterOption, options: activeFilterOptions},
        ]

        if (org === 'c2c') {
          employerFilters.push(
            { name: 'Political Party', value: defaultFilterOption, options: politicalPartyFilterOptions}
          )
        }

        //sort
        eventSortOptions = [defaultSortOption,'Most RSVPs to Events Hosted']
        postSortOptions = [defaultSortOption,'Most Posts']
        applicationSortOptions = [defaultSortOption,'Most Applications']
        interviewSortOptions = [defaultSortOption,'Most Interviews']
        hireSortOptions = [defaultSortOption,'Most Prior Hires']
        projectedHireSortOptions = [defaultSortOption,'Most Projected Hires']

        employerSorters = [
          { name: 'Events', value: defaultSortOption, options: eventSortOptions},
          { name: 'Posts', value: defaultSortOption, options: postSortOptions},
          { name: 'Applications', value: defaultSortOption, options: applicationSortOptions},
          { name: 'Interviews', value: defaultSortOption, options: interviewSortOptions},
          { name: 'Previous Hires', value: defaultSortOption, options: hireSortOptions},
          { name: 'Projected Hires', value: defaultSortOption, options: projectedHireSortOptions}
        ]

        this.setState({ emailId: email, username, org, roleName, orgFocus,
          employerFilters, employerSorters, animating: true
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let typeOptions = response.data.workOptions[0].employerTypeOptions
            typeOptions.shift()
            let industryOptions = response.data.workOptions[0].industryOptions
            industryOptions.shift()
            let employeeCountOptions = response.data.workOptions[0].employeeCountOptions
            employeeCountOptions.shift()
            let employeeGrowthOptions = response.data.workOptions[0].employeeGrowthOptions
            employeeGrowthOptions.shift()
            let politicalPartyOptions = response.data.workOptions[0].politicalAlignmentOptions


            for (let i = 1; i <= employerFilters.length; i++) {
              const name = employerFilters[i - 1].name
              if (name === 'Type') {
                typeFilterOptions = typeFilterOptions.concat(typeOptions)
                employerFilters[i - 1]['options'] = typeFilterOptions
              } else if (name === 'Industry') {
                industryFilterOptions = industryFilterOptions.concat(industryOptions)
                employerFilters[i - 1]['options'] = industryFilterOptions
              } else if (name === '# of Employees') {
                employeeCountFilterOptions = employeeCountFilterOptions.concat(employeeCountOptions)
                employerFilters[i - 1]['options'] = employeeCountFilterOptions
              } else if (name === 'Employee Growth') {
                employeeGrowthFilterOptions = employeeGrowthFilterOptions.concat(employeeGrowthOptions)
                employerFilters[i - 1]['options'] = employeeGrowthFilterOptions
              } else if (name === 'Political Party') {
                politicalPartyFilterOptions = politicalPartyFilterOptions.concat(politicalPartyOptions)
                employerFilters[i - 1]['options'] = politicalPartyFilterOptions
              }
            }

            this.setState({ employerFilters })
          }
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             const favorites = response.data.favorites

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })

           } else {
             console.log('no favorites data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       let self = this
       function pullAccounts(placementPartners) {
         console.log('pullAccounts called')

         const sortAlphabetically = true
         let resLimit = 250
         Axios.get('/api/account/partners', { params: { org, placementPartners, resLimit, sortAlphabetically } })
         .then((response) => {
           console.log('Posted employer query attempted', response.data);

           if (response.data.success) {
             console.log('posted employer query worked')

             if (response.data.employers.length !== 0) {
               const employers = response.data.employers
               let filteredEmployers = employers

               self.setState({ employers, filteredEmployers, animating: false });
             } else {
               self.setState({ animating: false })
             }

           } else {
             console.log('query for employers query did not work', response.data.message)
             self.setState({ animating: false })
           }

         }).catch((error) => {
             console.log('Employer query did not work for some reason', error);
             self.setState({ animating: false })
         });
       }

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            this.setState({ orgFocus: response.data.orgInfo.orgFocus });
            pullAccounts(response.data.orgInfo.placementPartners)

          } else {
            console.log('org info query did not work', response.data.message)
            pullAccounts(null)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        let employerFilters = this.state.employerFilters
        for (let i = 1; i <= employerFilters.length; i++) {
          employerFilters[i - 1]['value'] = this.state.defaultFilterOption
        }
        let employerSorters = this.state.employerSorters
        for (let i = 1; i <= employerSorters.length; i++) {
          employerSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ searchString, employerFilters, employerSorters, animating: true })

        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        const filters = this.state.employerFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].name === field) {
            filters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        let employerFilters = filters

        //reset everything
        let searchString = ''
        for (let i = 1; i <= employerFilters.length; i++) {
          if (employerFilters[i - 1].name !== field) {
            employerFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }
        let employerSorters = this.state.employerSorters
        for (let i = 1; i <= employerSorters.length; i++) {
          employerSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ filters, employerFilters, animating: true, searchString, employerSorters })

        // this.resetFilters(event.target.name, event.target.value)
        this.filterResults(this.state.searchString, event.target.value, filters, index, false)
      } else if (event.target.name.includes('sort|')) {

        let sorters = this.state.employerSorters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= sorters.length; i++) {
          if (sorters[i - 1].name === field) {
            sorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        let employerSorters = sorters

        //reset everything
        let searchString = ''
        let employerFilters = this.state.employerFilters
        for (let i = 1; i <= employerFilters.length; i++) {
          employerFilters[i - 1]['value'] = this.state.defaultFilterOption
        }

        for (let i = 1; i <= employerSorters.length; i++) {
          if (employerSorters[i - 1].name !== field) {
            employerSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        this.setState({ searchString, employerFilters, employerSorters, animating: true })

        // this.resetFilters(event.target.name, event.target.value)
        this.sortResults(event.target.value, field)
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption
      const employers = this.state.employers

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/employers/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, employers })
        .then((response) => {
          console.log('Member filter query attempted', response.data);

            if (response.data.success) {
              console.log('member filter query worked')

              let filteredEmployers = response.data.employers
              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              self.setState({ filteredEmployers, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('member filter query did not work', response.data.message)
              this.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Member filter query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let employers = this.state.employers

      Axios.put('/api/employers/sort', { sortString, employers, sortName })
      .then((response) => {
        console.log('Member sort query attempted', response.data);

          if (response.data.success) {
            console.log('member sort query worked')

            let filteredEmployers = response.data.employers
            const filterCriteriaArray = null
            const sortCriteriaArray = response.data.sortCriteriaArray

            this.setState({ filteredEmployers, animating: false, filterCriteriaArray, sortCriteriaArray })

          } else {
            console.log('member sort query did not work', response.data.message)
            this.setState({ animating: false })
          }

      }).catch((error) => {
          console.log('Member sort query did not work for some reason', error);
          this.setState({ animating: false })
      });
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.employerFilters

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} forceArrow={true} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.employerSorters

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    async favoriteItem(e, item) {
      console.log('favoriteItem called', e, item)

      // e.stopPropagation()
      e.preventDefault()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'employer')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){

          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

        } else {

          // console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
        }

        console.log('show favorites: ', favoritesArray)
        this.setState({ favorites: favoritesArray })

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({ isSaving: false, successMessage: response.data.message })
          } else {
            console.log('did not save successfully')
            this.setState({ isSaving: false, errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ isSaving: false, errorMessage: 'there was an error saving favorites' })
        });
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderEmployers() {
      console.log('renderMatches called', this.state.filteredEmployers)

      let rows = []

      for (let i = 1; i <= this.state.filteredEmployers.length; i++) {

        const item = this.state.filteredEmployers[i - 1]
        const index = i - 1

        let subtitle = ''
        let pathname = '/organizations/' + this.state.org + '/employers/' + this.state.filteredEmployers[index]._id
        // let passedState = { member: this.state.filteredEmployers[index] }
        let updatedAtString = ''
        if (this.state.filteredEmployers[i - 1].updatedAt) {
          const year = new Date(this.state.filteredEmployers[i - 1].updatedAt).getFullYear()
          const month = new Date(this.state.filteredEmployers[i - 1].updatedAt).getMonth()
          const day = new Date(this.state.filteredEmployers[i - 1].updatedAt).getDate()
          const formattedMonth = convertDateToString(month,'formatMonth')
          updatedAtString = formattedMonth + ' ' + day + ', ' + year

          subtitle = 'Last Update: ' + updatedAtString
          if (this.state.filteredEmployers[i - 1].employerIndustry) {
            subtitle = this.state.filteredEmployers[i - 1].employerIndustry + ' | ' + subtitle
          }
        }

        let thirdTitle = null
        if (this.state.filteredEmployers[i - 1].employeeCount) {
          thirdTitle = this.state.filteredEmployers[i - 1].employeeCount
          if (!this.state.filteredEmployers[i - 1].employeeCount.includes('employees')) {
            thirdTitle = thirdTitle + ' employees'
          }
        }
        if (this.state.filteredEmployers[i - 1].employerLocation) {
          if (thirdTitle) {
            thirdTitle = thirdTitle + ' | ' + this.state.filteredEmployers[i - 1].employerLocation
          } else {
            thirdTitle = this.state.filteredEmployers[i - 1].employerLocation
          }
        }

        if (this.props.path && this.props.path.includes('/advisor')) {
          pathname = this.props.path + '/' + this.state.filteredEmployers[index]._id
        }

        // if (item.orgContacts && item.orgContacts.length > 0) {
        //   orgContacts = item.orgContacts
        // }
        let prefix = '/app'
        if (window.location.pathname.includes('/advisor')) {
          prefix = '/advisor'
        }

        let mainColumnClass = "calc-column-offset-100-static"
        if (this.props.pageSource === 'BrowseEmployers' || (item.orgCode === 'general' && item.orgCode !== this.state.org)) {
          mainColumnClass = "calc-column-offset-130"
          if (this.props.pageSource === 'BrowseEmployers') {
            pathname = prefix +'/employers/' + this.state.filteredEmployers[index]._id
          }
        }

        rows.push(
          <div key={i}>
              <div className="top-padding-20 bottom-padding-30">
                <Link className="background-link" to={pathname} state={{ selectedEmployer: item, employers: this.state.filteredEmployers }}>
                  <span className="fixed-column-70">
                    <img src={(item.employerLogoURI) ? item.employerLogoURI : industryIconDark} alt="GC" className="image-50-auto"/>
                  </span>
                  <span className={mainColumnClass}>
                    <p className="heading-text-5">{item.employerName}</p>
                    <p className="description-text-2 description-text-color top-padding-5">{subtitle}</p>
                    {(thirdTitle) && (
                      <p className="description-text-2 error-color top-padding-5">{thirdTitle}</p>
                    )}
                  </span>
                  {(this.props.pageSource === 'BrowseEmployers') ? (
                    <div className="fixed-column-30 top-margin">
                      <button className="background-button" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.favoriteItem(e, item)}>
                        <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20 pin-right"/>
                      </button>
                    </div>
                  ) : (
                    <div className="fixed-column-30 top-margin">
                      {((!item.sharePartners) || (item.sharePartners && !item.sharePartners.includes(this.state.org))) ? (
                        <div>
                          <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  )}
                  <div className="fixed-column-30 top-margin">
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-20 pin-right"/>
                  </div>
                </Link>
              </div>

              <ReactTooltip id="tooltip-placeholder-id" />

              <div className="clear" />
              {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[i - 1] && this.state.sortCriteriaArray[i - 1].name) && (
                <div className="left-padding-110">
                  <div className="half-spacer" />
                  <p className="description-text-2 error-color">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
                </div>
              )}
              {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[i - 1] && this.state.filterCriteriaArray[i - 1].name) && (
                <div className="left-padding-110">
                  <div className="half-spacer" />
                  <p className="description-text-2 error-color">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                </div>
              )}
              <hr className="cell-separator-advisor"/>

          </div>
        )
      }

      return rows
    }

    render() {

      let passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}

      const passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
      const passedPlaceholder = 'Search employers by name...'
      let filterClass = "floating-container card top-margin-2-percent right-margin-2-percent"

      let pathname = '/organizations/' + this.state.org + '/employers/add'
      if (this.props.path && this.props.path.includes('/advisor')) {
        pathname = this.props.path + '/add'
      }

      let headerLeftColumnWidth = "calc-column-offset-70"
      let headerRightColumnWidth = "fixed-column-70"

      if (this.props.pageSource === 'BrowseEmployers' || (toggleVars.hideSideNav && window.location.pathname.startsWith('/advisor'))) {
        passedClassName = 'standard-container-2'
        passedStyle = {}
        filterClass = 'standard-container-2'
        if (this.props.pageSource === 'BrowseEmployers') {
          headerLeftColumnWidth = "calc-column-offset-25"
          headerRightColumnWidth = "fixed-column-25"
        }
      } else if (toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations')) {
        passedClassName = 'width-70-percent top-margin-2-percent max-width-1400 center-horizontally card'
        passedStyle = {}
        filterClass = 'width-70-percent top-margin-2-percent max-width-1400 center-horizontally card'
        if (this.props.pageSource === 'BrowseEmployers') {
          headerLeftColumnWidth = "calc-column-offset-25"
          headerRightColumnWidth = "fixed-column-25"
        }
      }

      return (
          <div>
              <div>
                <div className={passedClassName} style={passedStyle}>
                  <div>
                    <div className={headerLeftColumnWidth}>
                      {(!toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations')) && (
                        <div className="half-spacer" />
                      )}

                      <p className="heading-text-2">{this.state.filteredEmployers.length} Employers</p>
                    </div>
                    <div className={headerRightColumnWidth}>
                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <div className="float-left">
                        <div className="half-spacer" />
                        <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                          <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="Compass search icon" className="image-25-fit"/>
                        </button>
                      </div>
                      {(this.props.pageSource !== 'BrowseEmployers') && (
                        <div className="float-left left-padding-20">
                          <div className="half-spacer" />
                          <Link className="background-button float-left" to={pathname}>
                            <img src={addIcon} alt="GC" className="image-auto-25" />
                          </Link>
                        </div>
                      )}
                    </div>

                    <div className="clear" />
                  </div>
                </div>

                {(this.state.showingSearchBar) && (
                  <div className={filterClass} style={passedStyle2}>
                    <div>
                      <div className="standard-border display-inline row-5 left-padding-5 right-padding-5 full-width">
                          <div className="float-left top-padding-8 left-padding-3">
                            <img src={searchIcon} alt="GC" className="image-auto-20" />
                          </div>
                          <div className="calc-column-offset-23">
                            <input type="text" className="text-field clear-border" placeholder={passedPlaceholder} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                          </div>
                      </div>
                      <div className="clear" />
                      <div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Filter</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('filter'))}

                      <div className="clear" />
                      <div className="spacer" />
                      <hr />

                      <div className="spacer" /><div className="spacer" />

                      <p>Sort</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('sort'))}
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.animating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Calculating results...</p>

                    </div>
                  </div>
                ) : (
                  <div className={passedClassName} style={passedStyle}>
                    {this.renderEmployers()}
                  </div>
                )}

              </div>
          </div>
      )
    }
}


export default withRouter(Employers);
