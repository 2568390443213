import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubNotifications from './Subcomponents/Notifications';
import withRouter from './Functions/WithRouter';

class EmpNotifications extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ username, emp, orgMode, activeOrg, orgLogo })

      }
    }

    render() {
      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg}  history={this.props.navigate}/>
          <div className="standard-container-2">
            <SubNotifications />
          </div>
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}


export default withRouter(EmpNotifications)
