import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import Axios from 'axios';
import withRouter from './Functions/WithRouter';

class EmpAddAdmins extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emails: '',

            emailId: '',
            username: '',
            cuFirstName: '',
            cuLastName: '',

            requests: [],
            emp: '',

            recipientErrorMessage: '',
            serverPostSuccess: false,
            serverSuccessMessage: '',
            serverErrorMessage: ''
        }
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let activeOrg = localStorage.getItem('activeOrg')
      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ activeOrg, orgMode, orgLogo })

        Axios.get('/api/account', { params: { accountCode: emp } })
        .then((response) => {
          console.log('Account info query attempted', response.data);

          if (response.data.success) {
            console.log('account info query worked')

            const employerName = response.data.accountInfo.employerName
            this.setState({ employerName });

          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });

        Axios.get('/api/partner/request', { params: { emailId: email, type: 'Advisor Request' } })
        .then((response) => {

            if (response.data.success) {
              console.log('Advisee request query worked', response.data);
              this.setState({
                emailId: email,
                username: username,
                cuFirstName: cuFirstName,
                cuLastName: cuLastName,
                requests: response.data.requests,
                emp: emp
              })

            } else {
              console.log('no advisee requests found', response.data.message)
              this.setState({
                emailId: email,
                username: username,
                cuFirstName: cuFirstName,
                cuLastName: cuLastName,
                emp: emp
              })
            }

        }).catch((error) => {
            console.log('Request query did not work', error);
            this.setState({
              emailId: email,
              username: username,
              cuFirstName: cuFirstName,
              cuLastName: cuLastName,
              emp: emp
            })
        });
      }
    }

    formChangeHandler(event) {

      if (event.target.name === 'emails') {

        this.setState({ emails: event.target.value })
      }
    }

    handleSubmit(event) {

      console.log('handleSubmit called', this.state.emails)

      const emailId = this.state.emailId
      const cuFirstName = this.state.cuFirstName
      const cuLastName = this.state.cuLastName

      //validate forms
      if (this.state.emails.length === 0) {
        this.setState({ recipientErrorMessage: 'please add at least one recipient email' })
      } else {

          //format email array
          let removedCommas = this.state.emails.replace(/,/g, '');
          let removedSpaces = removedCommas.trim();
          let recipientEmails = removedSpaces.split(/\r\n|\r|\n/g);

          for (let i = 1; i <= recipientEmails.length; i++) {
            console.log(i);

            if (!recipientEmails[i - 1].includes('@')) {
              this.setState({ recipientErrorMessage: 'email invalid. please enter a valid email for email #' + i })
              return
            }
          }

          let createdAt = new Date();
          let updatedAt = createdAt

          Axios.post('/api/admins/invite', {
            senderEmail: emailId, senderFirstName: cuFirstName, senderLastName: cuLastName,
            recipientEmails: recipientEmails, recipientType: 'Advisee', active: false,
            joined: false, accountCode: this.state.emp, createdAt: createdAt, updatedAt: updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              this.setState({
                emails: '',

                serverPostSuccess: true,
                serverSuccessMessage: 'Invite(s) sent successfully!'
              })
            } else {

              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }
          }).catch((error) => {
              console.log('Admin invite did not work', error);
          });
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

        return (
            <div>
              <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg}  history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

              <div className="standard-container-2">
                  <div className="app-title-container-1">
                    <p className="heading-text-3 description-text-color">Invite People to the {this.state.employerName} Portal</p>
                  </div>
                  <div className="toggle-content">
                    <div className="add-advisees-container">
                        <div className="app-row">
                            <p className="heading-text-6">Add as many emails as you like. Paste from a spreadsheet or hit return for each email.</p>
                            <br/>
                            <textarea type="text" className="text-field" placeholder="Paste emails from spreadsheet like so:&#13;&#10;email1@gmail.com&#13;&#10;email2@gmail.com&#13;&#10;email3@gmail.com" name="emails" value={this.state.emails} onChange={this.formChangeHandler}/>
                            <p className="error-message">{this.state.recipientErrorMessage}</p>
                        </div>
                    </div>
                    <div className="toggle-cta-container">
                        <button className="btn btn-primary" onClick={this.handleSubmit}>Send</button>
                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <p className="error-message">{this.state.serverErrorMessage}</p>
                        )}
                    </div>
                  </div>
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}


export default withRouter(EmpAddAdmins)
