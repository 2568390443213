import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { CircularProgressbar } from 'react-circular-progressbar';;
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const careerIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const trendingUpIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trending-up.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const membersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/members-icon-blue.png';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Careers extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showSubFunction: true,
          careers: [],
          itemFilters: [],
          itemSorters: [],

          favorites: [],
          newFavorites: [],

          totalPercent: 100,

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
        this.standardizeValue = this.standardizeValue.bind(this)
        this.pullSimilarCareeers = this.pullSimilarCareers.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in org / accountCode')
        this.retrieveData()
      } else if (this.props.calculateMatches !== prevProps.calculateMatches) {
        console.log('were in calcMatches from props')
        this.retrieveData()
      } else if (this.props.selectedGoal !== prevProps.selectedGoal) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props.activeOrg)

      const jobFamilyOptions = ['Management','Business and Financial Operations','Computer and Mathematical','Architecture and Engineering','Life, Physical, and Social Science','Community and Social Service','Legal','Education, Training, and Library','Arts, Design, Entertainment, Sports, and Media','Healthcare Practitioners and Technical','Healthcare Support','Protective Service','Food Preparation and Serving Related','Building and Grounds Cleaning and Maintenance','Personal Care and Service','Sales and Related','Office and Administrative Support','Farming, Fishing, and Forestry','Construction and Extraction','Installation, Maintenance, and Repair','Production','Transportation and Material Moving']
      const growthOptions = ['8% or higher','5% to 7%','3% to 4%','1% to 2%','-1% or lower']
      const payOptions = ['< $30,000','$30,000 - $50,000','$50,000 - $70,000','$70,000 - $100,000','$100,000 - $150,000','$150,000 - $208,000','$208,000+']
      const employmentOptions = ['< 10,000','10,000 - 50,000','50,000 - 100,000','100,000 - 200,000','200,000 - 300,000','300,000 - 500,000','500,000+']

      const itemFilters = [
        { name: 'Job Family', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(jobFamilyOptions)},
        { name: 'Growth', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(growthOptions)},
        { name: 'Pay', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(payOptions)},
        { name: 'Employment', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(employmentOptions)},
      ]

      const itemSorters = [
        // { name: 'Growth', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(growthOptions)},
        // { name: 'Pay', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(payOptions)},
        // { name: 'Employment', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(employmentOptions)},
        { name: 'Realistic', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Investigative', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Artistic', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Social', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Enterprising', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Conventional', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
      ]

      this.setState({ itemFilters, itemSorters, animating: true })

      const excludeMissingOutlookData = true
      const excludeMissingJobZone = true

      if (this.props.pageSource !== 'Goal' && !this.props.calculateMatches) {
        Axios.get('/api/careers', { params: { excludeMissingOutlookData, excludeMissingJobZone } })
        .then((response) => {
          console.log('Careers query worked', response.data);

          if (response.data.success) {

            const careers = response.data.careers
            this.setState({ careers, animating: false })

          } else {
            console.log('no careers data found', response.data.message)
            this.setState({ animating: false })
          }

        }).catch((error) => {
            console.log('Careers query did not work', error);
            this.setState({ animating: false })
        });
      }

      if (this.props.pageSource !== 'landingPage') {

        let email = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        this.setState({ emailId: email, activeOrg })

        let matchingCriteria = [
          { name: 'Work Function',  description: 'Scores careers / opportunities the highest that match your preferred work functions indicated in your work preferences assessment', value: 20 },
          { name: 'Industry',  description: 'Scores careers / opportunities the highest that match your preferred industries indicated in your work preferences assessment', value: 10 },
          { name: 'Location',  description: 'Scores careers / opportunities the highest that match your preferred location preferences indicated in your work preferences assessment', value: 0 },
          { name: 'Pay',  description: 'Scores high paying careers / opportunities highest', value: 0 },
          { name: 'Stability',  description: 'Scores careers / opportunities the highest with little variability in pay and job growth', value: 0 },
          { name: 'Interests',  description: 'Scores careers / opportunities the highest that match your interest assessment', value: 15 },
          { name: 'Personality',  description: 'Scores careers / opportunities the highest that match your personality assessment', value: 15 },
          { name: 'Values',  description: 'Scores careers / opportunities that the highest match your values assessment', value: 10 },
          { name: 'Skills',  description: 'Scores careers / opportunities that the highest match your skills assessment', value: 20 },
          { name: 'Education',  description: 'Scores careers / opportunities the highest that match your target education level indicated in work preferences', value: 0 },
          { name: 'Projects',  description: 'Scores careers / opportunities the highest that will likely value your project work the highest', value: 5 },
          { name: 'Experience',  description: 'Scores careers / opportunities the highest that will likely value your experience (that you like)', value: 5 },
        ]

        let useCases = [
          { name: 'Purpose', description: 'Optimize for careers that may give you the most purpose (e.g., solving a global problem, or large problem I am specifically interested in)', selected: false }, // interests
          { name: 'Stability', description: 'Optimize for careers where you will likely have the most job stability.', selected: false }, // job growth, company size
          { name: 'Pay', description: 'Optimize for careers where you will be paid the most in the short to medium term.', selected: false }, // pay
          { name: 'Recognition', description: 'Optimize for careers where you will be provided positive reinforcement and public praise for your accomplishments.', selected: false }, // social, artistic
          { name: 'Interests', description: 'Optimize for careers where you have the most long-term, genuine interest in.', selected: false }, // interests, personality
          { name: 'Competitive Advantage', description: 'Optimize for careers where you have the highest competitive advantage amongst candidates in your peer group.', selected: false }, // skills
        ]

        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             let favorites = []
             let newFavorites = []
             if (response.data.user.favoritesArray) {
               favorites = response.data.user.favoritesArray
               newFavorites = response.data.user.newFavorites
               // console.log('favorites? ', favorites)
               this.setState({ favorites, newFavorites })
             }

             Axios.get('/api/assessment/results', { params: { emailId: email } })
              .then((response2) => {
                console.log('query for assessment results worked');

                if (response2.data.success) {

                  console.log('actual assessment results', response2.data)

                  let profile = response.data.user
                  profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                  profile['interests'] = response2.data.results.interestScores
                  profile['personality'] = response2.data.results.personalityScores
                  profile['skills'] = response2.data.results.newSkillAnswers
                  profile['gravitateValues'] = response2.data.results.topGravitateValues
                  profile['employerValues'] = response2.data.results.topEmployerValues

                  // let matchingCriteria = this.state.matchingCriteria
                  if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                    matchingCriteria = response.data.user.matchingPreferences
                  }
                  if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                    useCases = response.data.user.matchingUseCases
                  }

                  this.setState({ profile, matchingCriteria, useCases })

                  console.log('show the props in subCareers: ', this.props)
                  if (this.props.calculateMatches) {
                    this.calculateMatches(true, true, false)
                  } else if (this.props.pageSource === 'Goal') {
                    if (this.props.selectedGoal && this.props.selectedGoal.selectedCareers && this.props.selectedGoal.selectedCareers.length > 0) {
                      this.pullSimilarCareers(this.props.selectedGoal.selectedCareers)
                    } else {
                      this.calculateMatches(true, true, false)
                    }
                  }

                } else {
                  console.log('no assessment results', response2.data)

                  // let matchingCriteria = this.state.matchingCriteria
                  if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                    matchingCriteria = response.data.user.matchingPreferences
                  }
                  if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                    useCases = response.data.user.matchingUseCases
                  }
                  this.setState({ matchingCriteria, useCases })

                  if (this.props.calculateMatches) {
                    console.log('about to calculateMatches from props', this.props)
                    this.calculateMatches(true, true, false)
                  } else if (this.props.pageSource === 'Goal') {
                    if (this.props.selectedGoal && this.props.selectedGoal.selectedCareers && this.props.selectedGoal.selectedCareers.length > 0) {
                      this.pullSimilarCareers(this.props.selectedGoal.selectedCareers)
                    } else {
                      this.calculateMatches(true, true, false)
                    }
                  }
                }

            }).catch((error) => {
                console.log('query for assessment results did not work', error);
                // let matchingCriteria = this.state.matchingCriteria
                if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                  matchingCriteria = response.data.user.matchingPreferences
                }
                if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                  useCases = response.data.user.matchingUseCases
                }
                this.setState({ matchingCriteria, useCases })

                if (this.props.calculateMatches) {
                  this.calculateMatches(true, true, false)
                }
            })

            Axios.get('/api/projects', { params: { emailId: email, includeCollaborations: true } })
            .then((response2) => {
              console.log('Projects query attempted', response2.data);

                if (response2.data.success && response2.data.projects) {
                  console.log('successfully retrieved projects')

                  let profile = response.data.user
                  profile['projects'] = response2.data.projects
                  this.setState({ profile })

                } else {
                  console.log('no project data found', response2.data.message)
                }

            }).catch((error) => {
                console.log('Project query did not work', error);
            });

            Axios.get('/api/experience', { params: { emailId: email } })
            .then((response2) => {
              console.log('Experience query attempted', response2.data);

                if (response2.data.success && response2.data.experience) {
                  console.log('successfully retrieved experience')

                  let profile = response.data.user
                  profile['experience'] = response2.data.experience
                  this.setState({ profile })

                } else {
                  console.log('no experience data found', response2.data.message)
                }

            }).catch((error) => {
                console.log('Experience query did not work', error);
            });

            Axios.get('/api/story', { params: { emailId: email } })
            .then((response2) => {
                console.log('Endorsement query worked', response2.data);

                if (response2.data.success) {

                  if (response2.data.stories) {
                    let profile = response.data.user
                    profile['endorsements'] = response2.data.stories
                    this.setState({ profile })
                  }
                } else {
                  console.log('no endorsements found: ', response2.data.message)
                }

            }).catch((error) => {
                console.log('Story query did not work', error);
            });

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const hourOptions = response.data.workOptions[0].hourOptions

            this.setState({ hourOptions });
          }
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'search') {
        this.setState({ searchString: event.target.value, animating: true })
        this.filterResults(event.target.value, null, null, null, true)
      } else if (event.target.name.includes('filter|')) {

        let itemFilters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name === field) {
            itemFilters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        //reset everything
        let searchString = ''
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name !== field) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }

        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ animating: true, searchString, itemFilters, itemSorters })

        this.filterResults(this.state.searchString, event.target.value, itemFilters, index, false)

      } else if (event.target.name.includes('sort|')) {

        let itemSorters = this.state.itemSorters
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name === field) {
            itemSorters[i - 1]['value'] = event.target.value
          }
        }

        //reset everything
        let searchString = ''
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        this.setState({ searchString, itemFilters, itemSorters, animating: true })


        this.sortResults(event.target.value, field)

      } else if (event.target.name.includes('useCase')) {
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1].trim())

        let useCases = this.state.useCases
        // useCases[index]["value"] = event.target.value
        for (let i = 1; i <= useCases.length; i++) {
          if (i - 1 === index) {
            useCases[index]["selected"] = true
          } else {
            useCases[index]["selected"] = false
          }
        }

        let matchingCriteria = this.state.matchingCriteria
        if (useCases[index].name === 'Interest') {
          // max interests, personality

           matchingCriteria[0]["value"] = 0 // work function
           matchingCriteria[1]["value"] = 0 // industry
           matchingCriteria[2]["value"] = 0 // location
           matchingCriteria[3]["value"] = 0 // pay
           matchingCriteria[4]["value"] = 0 // stability
           matchingCriteria[5]["value"] = 60 // interests
           matchingCriteria[6]["value"] = 40 // personality
           matchingCriteria[7]["value"] = 0 // values
           matchingCriteria[8]["value"] = 0 // skills
           matchingCriteria[9]["value"] = 0 // education
           matchingCriteria[10]["value"] = 0 // projects
           matchingCriteria[11]["value"] = 0 // experience
        } else if (useCases[index].name === 'Competitive Advantage') {
          // max rare, in-demand skills

          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 70 // skills
          matchingCriteria[9]["value"] = 10 // education
          matchingCriteria[10]["value"] = 10 // projects
          matchingCriteria[11]["value"] = 10 // experience

        } else if (useCases[index].name === 'Purpose') {
          // max interests, mission-driven opportunities
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 25 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 50 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 25 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Stability') {
          // max interests
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 100 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Pay') {
          // max pay
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 100 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Recognition') {
          // max social, artistic, prestige, client-facing (sales)
          matchingCriteria[0]["value"] = 10 // work function
          matchingCriteria[1]["value"] = 10 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 40 // interests
          matchingCriteria[6]["value"] = 10 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 30 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience
        }

        this.setState({ useCases, matchingCriteria })
      } else if (event.target.name.includes('custom')) {
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1].trim())

        const ogValue = this.state.matchingCriteria[index].value
        const diff = event.target.value - ogValue
        const totalPercent = this.state.totalPercent + diff

        let matchingCriteria = this.state.matchingCriteria
        matchingCriteria[index]["value"] = Number(event.target.value)
        this.setState({ matchingCriteria, totalPercent })
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // let careers = this.state.careers

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called in careers')

        const excludeMissingOutlookData = true
        const excludeMissingJobZone = true

        Axios.put('/api/careers/search', {  searchString, filterString, filters, index, search, excludeMissingOutlookData, excludeMissingJobZone })
        .then((response) => {
          console.log('Careers query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved careers')

              if (response.data) {

                const careers = response.data.careers

                // THIS IS SUPER UNNECESSARY
                const filterCriteriaArray = response.data.filterCriteriaArray
                const sortCriteriaArray = null

                self.setState({ careers, animating: false, filterCriteriaArray, sortCriteriaArray })
              }

            } else {
              console.log('no career data found', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Career query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let careers = this.state.careers

      Axios.put('/api/careers/sort', { sortString, careers, sortName })
      .then((response) => {
        console.log('Career sort query attempted', response.data);

          if (response.data.success) {
            console.log('posting sort query worked')

            // THIS IS SUPER UNNECESSARY
            const filterCriteriaArray = null
            const sortCriteriaArray = response.data.sortCriteriaArray

            const careers = response.data.careers
            this.setState({ careers, animating: false, filterCriteriaArray, sortCriteriaArray })

          } else {
            console.log('career sort query did not work', response.data.message)
            this.setState({ animating: false })
          }

      }).catch((error) => {
          console.log('Career sort query did not work for some reason', error);
          this.setState({ animating: false })
      });
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} forceArrow={true} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    standardizeValue(type, index, value) {
      console.log('standardize value called')

      // interests max at 7
      if (type === 'sort') {
        if (this.state.sortCriteriaArray[index].name === 'Realistic') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        } else if (this.state.sortCriteriaArray[index].name === 'Investigative') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        } else if (this.state.sortCriteriaArray[index].name === 'Artistic') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        } else if (this.state.sortCriteriaArray[index].name === 'Social') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        } else if (this.state.sortCriteriaArray[index].name === 'Enterprising') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        } else if (this.state.sortCriteriaArray[index].name === 'Conventional') {
          value = ((Number(this.state.sortCriteriaArray[index].criteria) / 7) * 100).toFixed().toString() + '%'
        }
      }

      return value

    }

    pullSimilarCareers(selectedCareers) {
      console.log('pullSimilarCareers called', selectedCareers)

      Axios.put('/api/careers/similar', { selectedCareers })
      .then((response) => {
        console.log('Similar careers query attempted', response.data);

          if (response.data.success) {
            console.log('similar careers query worked')

            let matchScores = response.data.matchScores
            const careers = response.data.careers

            this.setState({ animating: false, matchingView: true, matchScores, careers })

          } else {
            console.log('Career match did not work', response.data.message)
            this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
          }

      }).catch((error) => {
          console.log('Career match did not work for some reason', error);
          this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
      });
    }

    calculateMatches(matchingView, calcMatches, newPreferences) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences)

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })
        // console.log('got profile? ', this.state.profile)
        if (!this.state.profile) {
          this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
        } else {
          this.setState({ animating: true, modalIsOpen: false })

          const profile = this.state.profile
          const matchingCriteria = this.state.matchingCriteria
          const useCases = this.state.useCases
          const hourOptions = this.state.hourOptions

          const self = this

          function officiallyCalculate() {
            console.log('officiallyCalculate called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            // query postings on back-end
            Axios.put('/api/careers/matches', { profile, matchingCriteria, useCases, excludeMissingOutlookData, excludeMissingJobZone, hourOptions })
            .then((response) => {
              console.log('Career matches attempted', response.data);

                if (response.data.success) {
                  console.log('career match query worked')

                  let matchScores = response.data.matchScores
                  self.setState({ animating: false, matchingView: true, matchScores, careers: response.data.careers })

                } else {
                  console.log('Career match did not work', response.data.message)
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                }

            }).catch((error) => {
                console.log('Career match did not work for some reason', error);
                self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
            });
          }

          if (newPreferences) {
            console.log('new preferences stated')

            if (this.state.totalPercent !== 100) {
              this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'The sum of matching criteria weights must equal 100' })
            } else {
              const emailId = this.state.emailId
              const matchingPreferences = matchingCriteria
              const matchingUseCases = useCases
              const updatedAt = new Date()

              Axios.post('/api/users/profile/details', {
                emailId, matchingPreferences, matchingUseCases, updatedAt })
              .then((response) => {

                if (response.data.success) {
                  console.log('successfully saved new preferences')
                  officiallyCalculate()

                } else {
                  console.log('request was not successful')
                  officiallyCalculate()
                }
              }).catch((error) => {
                  console.log('Saving the info did not work', error);
                  officiallyCalculate()
              });
            }

          } else {
            officiallyCalculate()
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    itemClicked(name, value) {
      console.log('itemClicked called', name, value)

      const nameArray = name.split("|")
      const index = Number(nameArray[1].trim())

      let useCases = this.state.useCases
      // useCases[index]["value"] = event.target.value
      for (let i = 1; i <= useCases.length; i++) {
        console.log('compare indices: ', i - 1, index)
        if (i - 1 === index) {
          useCases[i - 1]["selected"] = true
        } else {
          useCases[i - 1]["selected"] = false
        }
      }

      let matchingCriteria = this.state.matchingCriteria
      if (useCases[index].name === 'Interests') {
        // max interests, personality

         matchingCriteria[0]["value"] = 0 // work function
         matchingCriteria[1]["value"] = 0 // industry
         matchingCriteria[2]["value"] = 0 // location
         matchingCriteria[3]["value"] = 0 // pay
         matchingCriteria[4]["value"] = 0 // stability
         matchingCriteria[5]["value"] = 60 // interests
         matchingCriteria[6]["value"] = 40 // personality
         matchingCriteria[7]["value"] = 0 // values
         matchingCriteria[8]["value"] = 0 // skills
         matchingCriteria[9]["value"] = 0 // education
         matchingCriteria[10]["value"] = 0 // projects
         matchingCriteria[11]["value"] = 0 // experience
      } else if (useCases[index].name === 'Competitive Advantage') {
        // max rare, in-demand skills

        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 70 // skills
        matchingCriteria[9]["value"] = 10 // education
        matchingCriteria[10]["value"] = 10 // projects
        matchingCriteria[11]["value"] = 10 // experience
      } else if (useCases[index].name === 'Purpose') {
        // max interests, mission-driven opportunities
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 25 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 50 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 25 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience
      } else if (useCases[index].name === 'Stability') {
        // max interests
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 100 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience
      } else if (useCases[index].name === 'Pay') {
        // max pay
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 100 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience

      } else if (useCases[index].name === 'Recognition') {
        // max social, artistic, prestige, client-facing (sales)
        matchingCriteria[0]["value"] = 10 // work function
        matchingCriteria[1]["value"] = 30 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 40 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 20 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience
      }

      this.setState({ useCases, matchingCriteria })
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    async favoriteItem(item) {
      console.log('favoriteItem called', item)

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"career")

        if (returnedObject.success) {
          this.setState({ serverSuccessPlan: true, serverSuccessMessagePlan: returnedObject.message, favoritesArray: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites })
        } else {
          this.setState({ serverSuccessPlan: false, serverSuccessMessagePlan: returnedObject.message })
        }

      } else {

        let itemId = item._id
        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){

          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });

        } else {

          console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })
      }
    }

    render() {

      let pathPrefix = '/app/careers/'
      if (window.location.pathname.includes('/advisor')) {
        pathPrefix = '/advisor/careers/'
      } else if (window.location.pathname.includes('/organizations')) {
        pathPrefix = '/organizations/' + this.state.activeOrg + '/careers/'
      } else if (this.props.pageSource === 'landingPage') {
        pathPrefix = '/careers/'
      }

      return (
          <div className="bottom-margin-20">
            {(this.props.pageSource !== 'Goal') && (
              <div>
                <div className={(this.props.pageSource === 'landingPage') && "row"}>
                  <div className={(this.props.pageSource === 'landingPage') && "full-width white-background"}>
                    {(this.state.matchingView) ? (
                      <div>
                        <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                          <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                            <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                          </button>
                        </div>
                        <div className="full-width">
                          <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                            <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                              <p>Adjust</p>
                            </div>
                          </button>
                          <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                            <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                              <p>Close</p>
                            </div>
                          </button>

                          <div className="clear" />

                          <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(this.props.pageSource === 'landingPage') ? (
                          <div className="filter-field-search full-width white-background">
                            <div className="search-icon-container">
                              <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                            </div>
                            <div className="filter-search-container calc-column-offset-100-static">
                              {(this.props.passedType) ? (
                                <input type="text" className="text-field clear-border" placeholder={"Search " + this.props.passedType.toLowerCase() + "s..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              ) : (
                                <input type="text" className="text-field clear-border" placeholder={"Search..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              )}

                            </div>
                            <div className="search-icon-container top-margin-negative-3">
                              <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                              </button>
                            </div>

                          </div>
                        ) : (
                          <div className="full-width row-5 white-background standard-border medium-shadow">
                            <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                              <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} onClick={() => this.calculateMatches(true, true, false)}>
                                <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches"/>
                              </button>
                            </div>
                            <div className="filter-field-search clear-margin calc-column-offset-100-static white-background clear-border">
                              <div className="search-icon-container">
                                <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                              </div>
                              <div className="filter-search-container calc-column-offset-100-static">
                                <input type="text" className="text-field clear-border" placeholder={"Search 1,000+ careers..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                            </div>
                            <div className="fixed-column-50 center-item">
                              <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                                <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                              </button>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        <ReactTooltip id="tooltip-placeholder-id" />

                      </div>
                    )}
                  </div>

                </div>

                {(this.state.showingSearchBar) && (
                  <div className={"row box-container-1 white-background"}>
                    <div>

                    {(!window.location.pathname.includes('/problem-platform') && this.props.pageSource !== 'landingPage') && (
                      <div>
                        <div className="spacer" /><div className="spacer" />
                        <hr />
                        <div className="spacer" /><div className="spacer" />
                      </div>
                    )}

                    <p>Filter</p>
                    <div className="half-spacer" />
                    {(this.renderManipulators('filter'))}

                    <div className="clear" />
                    <div className="spacer" />
                    <hr />
                    <div className="spacer" /><div className="spacer" />
                    <p>Sort</p>
                    <div className="half-spacer" />
                    {(this.renderManipulators('sort'))}
                    <div className="clear" />

                    </div>
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />
              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Pulling career matches...</p>

                </div>
              </div>
            ) : (
              <div className={(this.props.pageSource === 'landingPage') ? "card row" : "card"}>
                  {this.state.careers.map((value, index) =>
                    <div key={index}>
                      <div>
                        <div className="spacer" />
                        <Link to={pathPrefix + value.name} state={{ careerSelected: value }} className="background-link">
                          <div className="fixed-column-70">
                            {(this.state.matchScores && this.state.matchScores[index]) ? (
                              <div className="padding-10">
                                <CircularProgressbar
                                  value={this.state.matchScores[index]}
                                  text={`${this.state.matchScores[index]}%`}
                                  styles={{
                                    path: { stroke: `${getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${this.state.matchScores[index] / 100}` },
                                    text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '26px' },
                                    trail: { stroke: 'transparent' }
                                  }}
                                />
                              </div>
                            ) : (
                              <img src={careerIcon} alt="Compass arrow indicator icon" className="image-auto-50 top-margin-5 center-item"/>
                            )}

                          </div>
                          <div className={(this.props.pageSource === 'landingPage') ? "calc-column-offset-100-static" : "calc-column-offset-140-static"}>
                            <p className="heading-text-5">{value.name}</p>
                            <p className="description-text-1 description-text-color curtail-text">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}{(!value.jobFunction && value.industry) && value.industry}{(value.jobFamily) && ' | ' + value.jobFamily}</p>

                            {(value.marketData) && (
                              <div className="row-5 description-text-2 bold-text">
                                <div>
                                  <div className="float-left right-padding">
                                    <img src={trendingUpIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{value.marketData.growth}</p>
                                  </div>
                                </div>

                                <div>
                                  <div className="float-left right-padding">
                                    <img src={moneyIconBlue} alt="Compass arrow indicator icon" className="image-auto-20"/>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">${Number(value.marketData.pay).toLocaleString()}</p>
                                  </div>
                                </div>

                                {(window.innerWidth <= 768) && (
                                  <div className="clear" />
                                )}

                                <div>
                                  <div className="float-left right-padding">
                                    <img src={membersIconBlue} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{Number(value.marketData.totalEmployment).toLocaleString()}</p>
                                  </div>
                                </div>

                                <div className="clear" />
                              </div>
                            )}

                            {(value.onetInterests) && (
                              <div className="row-5 description-text-2 bold-text">
                                <div>
                                  <div className="float-left right-padding">
                                    <img src={favoritesIconBlue} alt="Compass arrow indicator icon" className="image-auto-20"/>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.realistic / 7) * 100).toFixed()}% R  -</p>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.investigative / 7) * 100).toFixed()}% I  -</p>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.artistic / 7) * 100).toFixed()}% A  -</p>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.social / 7) * 100).toFixed()}% S  -</p>
                                  </div>
                                  <div className="float-left right-padding">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.enterprising / 7) * 100).toFixed()}% E  -</p>
                                  </div>
                                  <div className="float-left">
                                    <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.conventional / 7) * 100).toFixed()}% C</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Link>

                        {(this.props.pageSource !== 'landingPage') && (
                          <span className="fixed-column-40 top-margin-15">
                            <button className="btn background-button" onClick={() => this.favoriteItem(value) }>
                              <img src={(this.state.favorites.includes(value._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                            </button>
                          </span>
                        )}

                        <div className="fixed-column-25 left-padding">
                          <span className="float-right">
                            <div className="spacer"/><div className="half-spacer"/>
                            <Link to={'/careers' + value.name} state={{ careerSelected: value }} className="background-link">
                              <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                            </Link>
                          </span>
                        </div>
                        <div className="clear"/>

                        {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[index] && this.state.sortCriteriaArray[index].name) && (
                          <div className="left-padding-70">
                            <div className="half-spacer" />
                            <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[index].name}: {this.standardizeValue('sort',index, this.state.sortCriteriaArray[index].criteria)}</p>
                          </div>
                        )}
                        {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[index] && this.state.filterCriteriaArray[index].name) && (
                          <div className="left-padding-70">
                            <div className="half-spacer" />
                            <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[index].name}: {this.state.filterCriteriaArray[index].criteria}</p>
                          </div>
                        )}
                        <div className="spacer" /><div className="spacer" />
                        <hr className="clear-margin clear-padding" />
                        <div className="clear"/>
                        <div className="spacer" />
                      </div>

                    </div>
                  )}

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.showMatchingCriteria) && (
              <div key="showMatchingCriteria" className="full-width padding-20">
                <p className="heading-text-2">Adjust Matching Criteria</p>
                <div className="spacer" />

                <div className="row-10">
                  <div className="flex-container">
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                        Adjust by Needs
                      </div>
                    </button>
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                        Custom Adjust
                      </div>
                    </button>

                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.customAdjustment) ? (
                  <div>

                    {(this.state.matchingCriteria) && (
                      <div>
                        {this.state.matchingCriteria.map((value ,index) =>
                          <div key={"c" + index} className={(value.name === 'Location') && "wash-out-2"}>
                            <div className="calc-column-offset-100-static">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-100 right-text">
                              <div className="fixed-column-70">
                                <input disabled={(value.name === 'Location') ? true : false} type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="fixed-column-30">
                                <div className="mini-spacer"/><div className="mini-spacer"/>
                                <p className="heading-text-2 cta-color bold-text">%</p>
                              </div>
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                        <div>
                          <hr className="cta-border-color"/>
                          <div className="spacer" />
                          <div className="float-left full-width right-text">
                            <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                          </div>
                          <div className="clear" />
                          {(this.state.totalPercent !== 100) && (
                            <div className="full-width">
                              <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>

                    {(this.state.useCases) && (
                      <div>
                        {this.state.useCases.map((value ,index) =>
                          <div key={"u" + index} className={(value.name === 'Purpose') && "wash-out-2"}>
                            <div className="calc-column-offset-50">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                              <button disabled={(value.name === 'Purpose') ? true : false} onClick={() => this.itemClicked('useCase|' + index, true)} type="button" className="background-button">
                                {(value.selected) ? (
                                  <div className="circle-option-container-2 cta-border center-text" >
                                    <div className="circle-selected-2"/>
                                  </div>
                                ) : (
                                  <div className="circle-option-container-2 standard-border center-text" />
                                )}
                              </button>

                              {/*
                              <select name={"useCases|" + index} className="dropdown description-text-2" value={value.value} onChange={this.formChangeHandler}>
                                  {['Optimize','Ignore','De-Optimize'].map(value => <option key={value} value={value}>{value}</option>)}
                              </select>*/}
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                      </div>
                    )}
                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

              </div>
            )}

            <div className="row-20 center-text">
              <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
            </div>
           </Modal>
          </div>
      )
    }
}


export default withRouter(Careers);
