import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubOpportunities from './Subcomponents/Opportunities';
import SubSubscribe from './Subcomponents/Subscribe';
import withRouter from './Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Opportunities extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            enableSubscriptions: false,
            subscribed: false,
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
        this.passSubscriptionData = this.passSubscriptionData.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show orgFocus ', orgFocus)

      if (email) {

        const problemTypeOptions = ['','Exploratory','Technical']
        const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Difficult','Challenger']
        const popularityOptions = ['','More than 10','More than 50']
        const postDateOptions = ['','Within the Last Month','Within the Last Year']

        let calculateMatches = false
        let subNavSelected = null
        if (this.props.location && this.props.location.state) {
          if (this.props.location.state.calculateMatches) {
            calculateMatches = this.props.location.state.calculateMatches
          }

          if (this.props.location.state.subNavSelected) {
            subNavSelected = this.props.location.state.subNavSelected
          }
        }
        console.log('show subNavSelected: ', this.props.location.state)
        this.setState({ emailId: email, username, activeOrg, orgFocus, orgLogo, subNavSelected,
          problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions, calculateMatches });

        const isActive = true
        const includeMembership = false

        Axios.get('/api/payments/subscription', { params: {
          email, orgCode: activeOrg, accountCode: null, subscriptionType: 'Individual', isActive, includeMembership
        } })
        .then((response) => {
          console.log('Subscription query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved subscription')

             this.setState({ subscribed: true })

           } else {
             console.log('no org data found', response.data.message)

           }

        }).catch((error) => {
           console.log('Subscription query did not work', error);

        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

          this.setState({ onMobile: true })

      }
    }

    changeSubNavSelected(subNavSelected) {
      console.log('changeSubNavSelected called', subNavSelected)

      this.setState({ subNavSelected })
      localStorage.setItem('opportunitiesNavSelected', subNavSelected)
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)
      this.setState({ activeOrg })
    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in exploration', subscription)

      this.setState({ subscribed: true })

    }

    render() {
      return (
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} changeSubNavSelected={this.changeSubNavSelected}/>

          <div>

            {((this.state.activeOrg === 'guidedcompass') && !this.state.subscribed && this.state.enableSubscriptions) ? (
              <div>
                <div className="standard-container-2" style={{...styles, opacity: this.state.opacity, transform: this.state.transform }}>
                  <SubSubscribe history={this.props.navigate} passSubscriptionData={this.passSubscriptionData} />

                </div>
              </div>
            ) : (
              <div>
                {(this.state.activeOrg) && (
                  <SubOpportunities calculateMatches={this.state.calculateMatches} activeOrg={this.state.activeOrg} passedSubNavSelected={this.state.subNavSelected} />
                )}
              </div>
            )}
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(Opportunities)
