import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Axios from 'axios';
import ReactGA from 'react-ga4';

import SubProfileCard from '../components/Subcomponents/ProfileCard';
import SubRenderOpportunities from '../components/Common/RenderOpportunities';
import withRouter from '../components/Functions/WithRouter';

const employerIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png";
const mentoringIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png";
const teacherIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/teacher-icon-blue.png";
const studentIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-blue.png";
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
// const exampleMatchedCandidate = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const logoImgDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';

class PartnerPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        partnerName: '',

        employerCTAText: 'Employ Career-Seekers',
        mentorCTAText: 'Mentor Career-Seekers',
        educatorCTAText: 'Educate Career-Seekers',
        careerSeekerCTAText: 'Get Opportunities'
      }
    }

    componentDidMount() {
      console.log('partner page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      const category = 'Acquisition';
      const action = 'lp1|partnerLandingPageVisits'

      ReactGA.initialize([{ trackingId: "376007762" }]);
      ReactGA.event({ category, action, label: action });

      const { partner } = this.props.params

      if (partner) {

        this.setState({ isPartner: true, partner })

        let orgCode = partner
        if (orgCode === 'detroitk12') {
          orgCode = 'dpscd'
        }

        Axios.get('/api/benchmarks', { params: { orgCode, excludeAccounts: true } })
        .then((response) => {
          console.log('Benchmarks query attempted', response.data);

            if (response.data.success) {
              console.log('benchmark query worked')

              if (response.data.benchmarks.length !== 0) {
                //jobs = response.data.postings
                let benchmarks = response.data.benchmarks

                let selectedApplication = null
                let workPreferenceResults = null
                let selectedJob = null
                let selectedBenchmark = null
                let wpQuestions = null

                for (let i = 1; i <= benchmarks.length; i++) {
                  if (benchmarks[i - 1].title.includes('Design') || benchmarks[i - 1].title.includes('IT') || benchmarks[i - 1].title.includes('Marketing') || benchmarks[i - 1].title.includes('Engineering')) {
                    benchmarks.splice(0,0,benchmarks.splice(i - 1, 1)[0])
                  }
                  if (i === 1) {

                    selectedJob = { title: benchmarks[i - 1].jobTitle }
                    selectedBenchmark = benchmarks[i - 1]

                    let workPreferenceResults = null
                    let interestResults = null
                    let personalityResults = null
                    let skillScores = null
                    let skillTraits = []

                    let intangiblesText = ''

                    let competencyTags = []
                    const benchmark = selectedBenchmark
                    if (benchmark) {
                      //workPreferenceResults
                      workPreferenceResults = ["['Design']","['Retail','Music', 'Information','Food Services','Fashion']","['Work for a startup or small-sized company']","Graphic Design Intern, Design Intern","n/a","Yes","15 miles","['~ 20 Miles']"]
                      console.log('benchmark: ', benchmark)
                      interestResults = []
                      for (let i = 1; i <= benchmark.interests.length; i++) {
                        const name = benchmark.interests[i - 1].title
                        const description = benchmark.interests[i - 1].description
                        const tempScore = Number(benchmark.interests[i - 1].score)
                        // const score = Math.ceil(tempScore/5)*5
                        const score = tempScore * 20 * 0.4

                        interestResults.push({ name, description, score })
                      }

                      let tempOpennessScore = benchmark.traits[0].score
                      let opennessScore = tempOpennessScore * 20 * (16/100)
                      console.log('show trait: ', benchmark.traits[0].score, tempOpennessScore, opennessScore)

                      const conscientiousnessScore = benchmark.traits[1].score * 20 * (16/100)
                      const extraversionScore = benchmark.traits[2].score * 20 * (16/100)
                      const agreeablenessScore = benchmark.traits[3].score * 20 * (16/100)
                      const neuroticismScore = benchmark.traits[4].score * 20 * (16/100)

                      personalityResults = {
                        myersBriggs: 'ENTJ', fiveFactors: {
                          opennessScore, conscientiousnessScore, extraversionScore, agreeablenessScore, neuroticismScore
                        }
                      }
                      for (let i = 1; i <= benchmark.traits.length; i++) {

                      }

                      intangiblesText = 'This person is most interested in engineering careers and least interested in design careers. Their personality is high in openness and low in emotional stability.'

                      if (benchmark.skills && benchmark.skills.length > 0) {
                        skillScores = []
                        let hardSkillCounter = 0
                        let softSkillCounter = 0
                        for (let i = 1; i <= benchmark.skills.length; i++) {
                          let selectedSkill = benchmark.skills[i - 1]
                          console.log('show selectedSkill: ', selectedSkill)
                          skillScores.push({ title: selectedSkill.title, skillType: selectedSkill.skillType, score: 5 })
                          if (selectedSkill.skillType === 'Hard Skill') {
                            hardSkillCounter = hardSkillCounter + 1
                            if (hardSkillCounter < 7) {
                              skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                            }
                          } else {
                            softSkillCounter = softSkillCounter + 1
                            if (hardSkillCounter < 7) {
                              skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                            }
                          }

                          competencyTags.push({
                            name: selectedSkill.title, category: selectedSkill.skillType, description: selectedSkill.description,
                            score: selectedSkill.score, weight: selectedSkill.weight
                          })
                        }
                      }

                      if (benchmark.abilities && benchmark.abilities.length > 0) {
                        for (let i = 1; i <= benchmark.abilities.length; i++) {
                          let selectedItem = benchmark.abilities[i - 1]
                          competencyTags.push({
                            name: selectedItem.title, category: 'Ability', description: selectedItem.description,
                            score: selectedItem.score, weight: selectedItem.weight
                          })
                        }
                      }
                      if (benchmark.knowledge && benchmark.knowledge.length > 0) {
                        for (let i = 1; i <= benchmark.knowledge.length; i++) {
                          let selectedItem = benchmark.knowledge[i - 1]
                          competencyTags.push({
                            name: selectedItem.title, category: 'Knowledge', description: selectedItem.description,
                            score: selectedItem.score, weight: selectedItem.weight
                          })
                        }
                      }

                      if (benchmark.traits && benchmark.traits.length > 0) {
                        for (let i = 1; i <= benchmark.traits.length; i++) {
                          let selectedTrait = benchmark.traits[i - 1]
                          // console.log('show selectedSkill: ', selectedSkill)
                          skillTraits.push({ name: selectedTrait.title, skillType: 'Trait', rating: 5 })
                        }
                      }
                    }

                    const createdAt = new Date()
                    const updatedAt = new Date()

                    let endorsements = []
                    endorsements.push({
                      senderFirstName: 'Jane', senderLastName: 'Doe', senderEmail: 'janedoe@gmail.com', overallRecommendation: '5',
                      recommendationExplanation: '', isTransparent: true, relationship: 'Mentor', type: 'Provide', recipientFirstName: 'Example',
                      recipientLastName: 'Candidate', recipientEmail: 'jondoe@gmail.com', pathway: benchmark.pathway,
                      skillTraits,
                      examples: [{ skillTrait: 'Empathy', example: 'When Jon was working for me, many customers said that Jon really empathized with their situation and that he went above and beyond to make sure I felt comfortable.'}],
                      createdAt, updatedAt
                    })

                    let interview = { interviewerName: 'Mary Doe', date: createdAt, score: '5' }
                    let interviewDetails = {
                      sections: [
                        { title: 'Knowledge, Skills, Abilities', questions: [{ question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.' }], sectionScore: 5},
                        { title: 'Communication, Collaboration, and Problem Solving', questions: [{ question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.' }], sectionScore: 5},
                        { title: 'Passion for Technology', questions: [{ question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.' }], sectionScore: 5},
                        { title: 'True, Accurate, Error-Free and Complete Resume', questions: [{ question: 'Is there anything that you want us to know that is not mentioned on your resume?', helper: 'What to look for: Missing information, informal education and skills.' }], sectionScore: 5},
                        { title: 'Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program', questions: [{ question: 'Tell me about your job experience[s] and/or school activities as they relate to your future goals in an Design, Digital Media, Production or Marketing career.', helper: 'What to look for: Communication skills, ability to tell their story.' }], sectionScore: 5}
                      ],
                      glows: 'Jon has great communication skills, and shows real passion for making a difference in the world', grows: 'Jon can come across as over-confident or nieve when it comes to implementing ideas or change.'
                    }
                    let interviewQuestions = [
                      { question: 'How have your experiences helped prepare you for work in this field?', guidance: 'What to look for: communication skills, conviction, demonstrated fit, relevant accomplishments, motivation for accomplishments.'},
                    ]

                    let experienceHours = 434
                    let volunteerHours = 0
                    // let experienceHours = paidExperienceHours + volunteerHours
                    let projectHours = 750
                    let totalHours = experienceHours + volunteerHours + projectHours

                    let projects = [{ name: 'Fake Project', url: 'https://www.creightontaylor.com', category: 'Marketing', description: 'This is a great description of my project', startDate: 'August 2020', endDate: 'July 2021', hours: '500 - 1000', collaborators: [] }]
                    let experience = [{ jobTitle: 'Intern', employerName: 'Google', workFunction: 'Marketing', startDate: 'October 2020', endDate: 'August 2021', hoursPerWeek: '~ 10 Hours', jobFunction: benchmark.jobFunction, industry: benchmark.industry, wasPaid: true }]

                    let pictureURL = 'https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB'

                    const degreeRequirements = 'High School'
                    const gpaRange = "GPA Doesn't Matter"
                    const gradYearRange = '2020 - 2025'
                    const courseHours = '1,000 - 5,000 Hours'

                    selectedApplication = {
                      firstName: 'Jimmy', lastName: 'Jones', email: 'fakeemail@email.com', schoolName: 'Sample School', gradYear: '2022',
                      pictureURL, major: 'Business', workPreferenceResults, personalityResults, skillScores,
                      intangiblesText, interview, interviewDetails, interviewQuestions, degreeRequirements,
                      gpaRange, gradYearRange, courseHours,
                      experienceHours, volunteerHours, projectHours, totalHours, projects, experience,
                      resumeURL: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/resumes/resume%7Ccreightontaylor%2B7%40gmail.com%7CCreighton%20Taylor%20Resume%20-%20Extended%20PM.pdf%7CSun%20Mar%2013%202022%2010%3A53%3A14%20GMT-0700%20%28Pacific%20Daylight%20Time%29',
                      customWebsiteURL: "https://www.creightontaylor.com",
                      match: 95, createdAt, updatedAt
                    }
                  }
                }

                this.setState({ benchmarks, selectedApplication, selectedJob, workPreferenceResults, selectedBenchmark, wpQuestions });
              }

            } else {
              console.log('benchmark query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);

        });

        if (orgCode) {
          // console.log('about to query: ', activeOrg)
          Axios.get('/api/org', { params: { orgCode } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const partnerName = response.data.orgInfo.orgName
              const orgLogo = response.data.orgInfo.webLogoURI

              let orgProgramName = response.data.orgInfo.orgProgramName
              const placementPartners = response.data.orgInfo.placementPartners
              let partnershipDescription = response.data.orgInfo.partnerStatement

              let employerBenefitsText = 'Post opportunities, design your ideal candidate, and get screened, referred candidates.'
              if (response.data.orgInfo.employerBenefitsText) {
                employerBenefitsText = response.data.orgInfo.employerBenefitsText
              }

              let mentorBenefitsText = "Provide resources, track progress, review resumes, mock interview, and refer career-seekers."
              if (response.data.orgInfo.mentorBenefitsText) {
                mentorBenefitsText = response.data.orgInfo.mentorBenefitsText
              }

              let educatorBenefitsText = "Assign lessons, source projects, lead workshops, endorse career-seekers, and export grades."
              if (response.data.orgInfo.educatorBenefitsText) {
                educatorBenefitsText = response.data.orgInfo.educatorBenefitsText
              }

              let studentBenefitsText = 'Explore paths, set goals, learn skills, track progress, and get opportunities.'
              if (response.data.orgInfo.studentBenefitsText) {
                studentBenefitsText = response.data.orgInfo.studentBenefitsText
              }

              const studentAliasSingular = response.data.orgInfo.studentAliasSingular
              const studentAliasPlural = response.data.orgInfo.studentAliasPlural
              const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
              const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

              const deactivated = response.data.orgInfo.deactivated
              const backgroundImageURL = response.data.orgInfo.backgroundImageURL
              const partners = response.data.orgInfo.partners
              const includeEducators = response.data.orgInfo.includeEducators

              let employerCTAText = this.state.employerCTAText
              let mentorCTAText = this.state.mentorCTAText
              let educatorCTAText = this.state.educatorCTAText
              let careerSeekerCTAText = this.state.careerSeekerCTAText
              if (studentAliasPlural) {
                employerCTAText = 'Employ ' + studentAliasPlural
                mentorCTAText = 'Mentor ' + studentAliasPlural
                educatorCTAText = 'Educate ' + studentAliasPlural
              }

              // employerCTAText = 'Employer Portal'
              // mentorCTAText = 'Mentor Portal'
              // careerSeekerCTAText = 'Student Portal'

              this.setState({
                orgCode,
                orgName: response.data.orgInfo.orgName, partnerName,
                orgURL: response.data.orgInfo.orgURL, orgProgramName,
                orgDescription: response.data.orgInfo.orgDescription,
                orgMission: response.data.orgInfo.orgMission,

                contactFirstName: response.data.orgInfo.contactFirstName,
                contactLastName: response.data.orgInfo.contactLastName,
                contactTitle: response.data.orgInfo.contactTitle,
                contactEmail: response.data.orgInfo.contactEmail,
                partnershipDescription,
                employerBenefitsText, mentorBenefitsText, educatorBenefitsText, studentBenefitsText,
                studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural,
                deactivated, orgLogo, backgroundImageURL, partners, includeEducators,
                employerCTAText, mentorCTAText, educatorCTAText, careerSeekerCTAText
              });

              Axios.get('/api/postings/user', { params: { orgCode, placementPartners,
                recent: true, active: true,
                pullPartnerPosts: true, csWorkflow: true, resLimit: 3
              }})
              .then((response) => {
                console.log('Posted postings query attempted within subcomponent', response.data);

                if (response.data.success) {
                  console.log('posted postings query worked')

                  const opportunities = response.data.postings
                  this.setState({ opportunities })

                } else {
                  console.log('posted postings query did not work', response.data.message)
                  this.setState({ opportunities: [] })
                }

              }).catch((error) => {
                  console.log('Posted postings query did not work for some reason', error);
              });

              Axios.get('/api/pathways', { params: { orgCode, isActive: true } })
              .then((response) => {
                console.log('Pathways query attempted no 1', response.data);

                if (response.data.success) {
                  console.log('pathway query worked no 1')

                  if (response.data.pathways.length !== 0) {

                    const pathways = response.data.pathways
                    this.setState({ pathways });
                  }

                } else {
                  console.log('pathway query did not work', response.data.message)
                }

              }).catch((error) => {
                  console.log('Pathway query did not work for some reason', error);
              });


            } else {
              console.log('org info query did not work', response.data.message)

            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }
      }
    }

    render() {
        console.log('testing 6')

        let divClass = "col span-1-of-2 box"
        if (this.state.partner !== 'detroitk12' && !this.state.includeEducators) {
          divClass = "col span-1-of-3 box"
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        if (this.state.partner === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
        } else if (this.state.partner === 'detroitk12') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        } else if (this.state.backgroundImageURL) {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(' + this.state.backgroundImageURL + ')'
        }

        return (
            <div>
              {(this.state.partner) && (
                <div>
                  <header className="front-page-header" style={(this.state.partner === 'detroitk12') ? { backgroundImage, objectFit: 'none' } : { backgroundImage }}>
                      {(window.innerWidth < 768) ? (
                        <div className="row top-margin">
                          <Link to={'/partners/' + this.state.partner}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" className="image-auto-40 float-left top-margin-15 left-margin" /></Link>
                        </div>
                      ) : (
                        <div className="row top-margin">
                          <Link to={'/partners/' + this.state.partner}><img src={logoImgDark} alt="Compass logo" className="image-auto-50 float-left top-margin-25" /></Link>
                          <div className="partner-container">
                            <div className="float-left">
                              <Link to={'/partners/' + this.state.partner}><img src={addIcon} alt="Guided Compass add logo" className="partner-add-icon" /></Link>
                            </div>
                            <div className="float-left">
                              <Link to={'/partners/' + this.state.partner}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" className="image-auto-40 float-left top-margin-15 left-margin" /></Link>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      <div className="hero-text-box">
                        <div>
                          <div>
                            <h1 className="white-text">{this.state.partnerName}'s Online Portal</h1>
                          </div>

                          <p className="two-thirds-width white-text">{this.state.partnershipDescription}</p>
                        </div>

                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                        <div>
                          <div className="float-left right-padding-20 top-margin">
                            <Link className="clear-border" to={'/partners/' + this.state.partner + '/employers'}><button className="btn btn-primary">{this.state.employerCTAText}</button></Link>
                          </div>
                          <div className="float-left right-padding-20 top-margin">
                            <Link className="clear-border" to={'/partners/' + this.state.partner + '/mentors'}><button className="btn btn-primary dark-blue-background clear-border white-text">{this.state.mentorCTAText}</button></Link>
                          </div>
                          {(this.state.includeEducators) && (
                            <div className="float-left right-padding-20 top-margin">
                              <Link className="clear-border" to={'/partners/' + this.state.partner + '/educators'}><button className="btn btn-primary denary-background clear-border">{this.state.educatorCTAText}</button></Link>
                            </div>
                          )}
                          <div className="float-left top-margin">
                            <Link className="clear-border" to={'/partners/' + this.state.partner + '/career-seekers'}><button className="btn btn-primary white-background cta-color">{this.state.careerSeekerCTAText}</button></Link>
                          </div>
                        </div>

                      </div>
                      <div className="clear"/>
                  </header>

                  {(!this.state.deactivated) && (
                    <div>
                      <section className="section-features">
                          <div className="row">
                            <h2>About {this.state.orgName}</h2>
                            <p className="subtitle-container">{this.state.orgDescription}</p>
                            <div className="spacer" /><div className="spacer" />
                          </div>

                          <div className="row">
                            <div className="horizontal-padding-3">
                              <div className={divClass}>
                                  <img src={employerIcon} alt="Employer icon" className="icon-big" />
                                  <h3 className="center-text bold-text">Employers</h3>
                                  <p className="center-text">{this.state.employerBenefitsText}</p>

                                  <div className="clear" />
                                  <div className="spacer" /><div className="spacer" />

                                  <div className="center-text">
                                    <Link className="clear-border" to={'/partners/' + this.state.partner + '/employers'}><button className="btn btn-primary">{this.state.employerCTAText}</button></Link>
                                  </div>
                              </div>
                              {(this.state.partner !== 'detroitk12') && (
                                <div className={divClass}>
                                    <img src={mentoringIconBlue} alt="GC" className="icon-big" />
                                    <h3 className="center-text bold-text">{(this.state.mentorAliasPlural) ? this.state.mentorAliasPlural : "Mentors"}</h3>
                                    <p className="center-text">{this.state.mentorBenefitsText}</p>

                                    <div className="clear" />
                                    <div className="spacer" /><div className="spacer" />

                                    <div className="center-text">
                                      <Link className="clear-border" to={'/partners/' + this.state.partner + '/mentors'}><button className="btn btn-primary dark-blue-background clear-border white-text">{this.state.mentorCTAText}</button></Link>
                                    </div>
                                </div>
                              )}
                              {(!this.state.includeEducators) && (
                                <div className={divClass}>
                                    <img src={studentIcon} alt="GC" className="icon-big" />
                                    <h3 className="center-text bold-text">{(this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Career-Seekers"}</h3>
                                    <p className="center-text">{this.state.studentBenefitsText}</p>

                                    <div className="clear" />
                                    <div className="spacer" /><div className="spacer" />

                                    <div className="center-text">
                                      <Link className="clear-border" to={'/partners/' + this.state.partner + '/career-seekers'}><button className="btn btn-primary white-background cta-color">{this.state.careerSeekerCTAText}</button></Link>
                                    </div>
                                </div>
                              )}

                              <div className="clear"/>
                            </div>

                            {(this.state.includeEducators) && (
                              <div className="horizontal-padding-3">
                                <div className={divClass}>
                                    <img src={teacherIconBlue} alt="GC" className="icon-big" />
                                    <h3 className="center-text bold-text">Educators & Counselors</h3>
                                    <p className="center-text">{this.state.educatorBenefitsText}</p>

                                    <div className="clear" />
                                    <div className="spacer" /><div className="spacer" />

                                    <div className="center-text">
                                      <Link className="clear-border" to={'/partners/' + this.state.partner + '/educators'}><button className="btn btn-primary denary-background clear-border">{this.state.educatorCTAText}</button></Link>
                                    </div>
                                </div>
                                <div className={divClass}>
                                    <img src={studentIcon} alt="GC" className="icon-big" />
                                    <h3 className="center-text bold-text">{(this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Career-Seekers"}</h3>
                                    <p className="center-text">{this.state.studentBenefitsText}</p>

                                    <div className="clear" />
                                    <div className="spacer" /><div className="spacer" />

                                    <div className="center-text">
                                      <Link className="clear-border" to={'/partners/' + this.state.partner + '/career-seekers'}><button className="btn btn-primary white-background cta-color">{this.state.careerSeekerCTAText}</button></Link>
                                    </div>
                                </div>
                                <div className="clear"/>
                              </div>
                            )}
                          </div>
                      </section>
                      {/*
                      {(this.state.partner === 'unite-la') && (
                        <div>
                          <section className="section-demo">
                            <div className="row">
                                <h2>How it Works</h2>
                                <div className="spacer" /><div className="spacer" />
                            </div>
                            <div className="row">
                              {(this.state.partner === 'unite-la' || this.state.partner === 'detroitk12') && (
                                <div className="seventy-percent-width center-horizontally">
                                  <ul>
                                    {this.renderBulletPoints()}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div className="spacer" /><div className="spacer" />
                          </section>

                        </div>
                      )}*/}

                      <div className="row">
                        <hr className="cta-border" />
                      </div>

                      {(this.state.selectedJob) && (
                        <div>
                          <section className="section-features">
                            <div className="row">
                              <h2>Example Match</h2>
                              <div className="top-margin center-horizontally width-80-percent standard-border medium-shadow">
                                <SubProfileCard selectedApplication={this.state.selectedApplication} workPreferenceResults={this.state.workPreferenceResults} selectedJob={this.state.selectedJob} benchmark={this.state.selectedBenchmark} benchmarkOptions={null} wpQuestions={this.state.wpQuestions} source={null} trackBenchmark={null} matchIndex={null} activeOrg={this.state.activeOrg} accountCode={null} />
                              </div>
                            </div>

                            {/*<img src={exampleMatchedCandidate} alt="example matched candidate icon" className={(window.innerWidth < 768) ? "image-full-auto center-horizontally" : "image-auto-450 center-horizontally"} />*/}

                          </section>

                          <div className="row">
                            <hr className="cta-border" />
                          </div>
                        </div>
                      )}

                      {(this.state.pathways && this.state.pathways.length > 0) && (
                        <div>
                          <section className="section-features">
                            <div className="row">
                              <h2>Pathways</h2>

                              <p className="full-width center-text">We're primarily focused on {this.state.pathways.length} pathways</p>

                              <div className="top-margin-20 center-horizontally width-80-percent standard-border padding-20">
                                {this.state.pathways.map((item,index) =>
                                  <div>
                                    <Link className="secret-link" to={'/pathways/' + item._id} state={{ selectedPathway: item }}>
                                      <span className="fixed-column-60">
                                        <div className="spacer" />

                                        <img src={(item.imageURL) ? item.imageURL : pathsIconBlue} alt="GC" className="image-auto-50 center-horizontally"/>
                                      </span>
                                      <span className="calc-column-offset-90 left-padding-20">
                                        <div className="spacer" />
                                        <p className="heading-text-5">{item.title}</p>
                                        <p className="description-text-2 top-padding-5">{item.jobTitle}</p>
                                      </span>

                                      <span className="fixed-column-30 top-margin-20">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                                      </span>
                                      <div className="clear" />
                                    </Link>

                                    <div className="spacer" /><div className="spacer" />

                                    {(this.state.pathways[index + 1]) && (
                                      <div>
                                        <hr />
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                            </div>
                          </section>

                          <div className="row">
                            <hr className="cta-border" />
                          </div>
                        </div>
                      )}

                      <section className="section-features">
                        <div className="row">
                          <h2>Most Recently Posted Opportunities</h2>

                          {(this.state.opportunities && this.state.opportunities.length > 0) ? (
                            <div>
                              <SubRenderOpportunities
                                oppType="all" postings={this.state.opportunities} history={this.props.navigate}
                                activeOrg={this.state.orgCode} pageSource="landingPage"
                              />
                            </div>
                          ) : (
                            <div>
                              <p className="full-width center-text">There aren't any recent, active opportunities.</p>
                            </div>
                          )}

                          <div className="top-margin-20 center-text">
                            <Link className="btn btn-primary" to={"/opportunities/organizations/" + this.state.orgCode}>See All Opportunities</Link>
                          </div>
                        </div>
                      </section>

                      {(this.state.partners && this.state.partners.length > 0) && (
                        <div>
                          <div className="row">
                            <hr className="cta-border" />
                          </div>

                          <section className="section-features">
                            <div className="row">
                              <h2>Partners</h2>

                              {/*<p className="full-width center-text">We're primarily focused on {this.state.pathways.length} pathways</p>*/}

                              <div className="top-margin center-horizontally width-80-percent padding-20">
                                {this.state.partners.map((item,index) =>
                                  <div className="top-margin-30">

                                    <div className="relative-column-33">
                                      <a href={item.url} target="_blank" rel="noopener noreferrer" className="background-button full-space">
                                        <img src={(item.imageURL) ? item.imageURL : employerIcon} alt="GC" className="image-auto-50 center-horizontally"/>
                                        <p className="heading-text-5 full-width center-text top-margin">{item.name}</p>
                                        <p className="description-text-2 top-padding-5 description-text-color full-width center-text">{item.type}</p>
                                      </a>
                                    </div>


                                    {(index % 3 === 2) && (
                                      <div className="clear" />
                                    )}
                                  </div>
                                )}
                                <div className="clear" />
                              </div>

                            </div>
                          </section>

                          <div className="row">
                            <hr className="cta-border" />
                          </div>
                        </div>
                      )}

                    </div>
                  )}

                </div>
              )}

              <Footer history={this.props.navigate} />
            </div>

        )
    }
}

export default withRouter(PartnerPage);
