import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditBenchmark from './Subcomponents/EditBenchmark';
import SubBenchmarkDetails from './Subcomponents/BenchmarkDetails';
import withRouter from './Functions/WithRouter';

class AdvEditBenchmark extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let selectedTemplate = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedTemplate = this.props.location.state.selectedBenchmark;
        duplicate = this.props.location.state.duplicate
      }

      if (selectedTemplate) {
        this.retrieveData(selectedTemplate,selectedTemplate._id,duplicate)
      } else {
        if (this.props.params && this.props.params.id) {

          Axios.get('/api/benchmarks/byid', { params: { _id: this.props.params.id } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked')

                const selectedTemplate = response.data.benchmark
                this.retrieveData(selectedTemplate,selectedTemplate._id,duplicate)

              } else {
                console.log('org info query did not work', response.data.message)
                this.retrieveData(null,null,duplicate)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }
      }
    }

    retrieveData(selectedTemplate, benchmarkId,duplicate) {
      console.log('retrieveData called')

      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ roleName, emailId: email, username,
        cuFirstName, cuLastName, activeOrg, orgFocus, orgLogo,
        benchmarkId, selectedTemplate,duplicate
      })

    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.selectedTemplate) ? (
            <div>
              {(this.state.selectedTemplate.orgCode === this.state.activeOrg) ? (
                <SubEditBenchmark activeOrg={this.state.activeOrg} benchmarkId={this.state.benchmarkId} selectedTemplate={this.state.selectedTemplate} history={this.props.navigate} duplicate={this.state.duplicate} />
              ) : (
                <SubBenchmarkDetails history={this.props.navigate} benchmarkId={this.state.benchmarkId} />
              )}
            </div>
          ) : (
            <div>
              <SubEditBenchmark activeOrg={this.state.activeOrg} benchmarkId={this.state.benchmarkId} selectedTemplate={this.state.selectedTemplate} history={this.props.navigate} duplicate={this.state.duplicate} />
            </div>
          )}

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(AdvEditBenchmark);
