import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditDocument from './Subcomponents/EditDocument';
import withRouter from './Functions/WithRouter';

class AdvEditDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPromptDesigner: false
    }

    this.passData = this.passData.bind(this)
  }

  componentDidMount() {
    // document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
    console.log('componentDidMount parent documents')

    const { id } = this.props.params

    let email = localStorage.getItem('email');
    let roleName = localStorage.getItem('roleName');
    let orgFocus = localStorage.getItem('orgFocus');
    let username = localStorage.getItem('username');
    let activeOrg = localStorage.getItem('activeOrg');
    const orgLogo = localStorage.getItem('orgLogo');

    if (email) {
      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
        orgLogo, id
      })
    }
  }

  passData(passedData) {
    console.log('passData called', passedData)

    if (passedData && passedData.togglePromptDesigner) {
      // because of appfooter function
      // passedData['showPromptDesigner'] = !this.state.showPromptDesigner
      passedData['clickerToggler'] = !this.state.clickerToggler

    }

    this.setState(passedData)

  }

  render() {

    return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
          <SubEditDocument org={this.state.activeOrg} history={this.props.navigate} id={this.state.id} passData={this.passData} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo,this.state.passedCommand,null, null, this.state.showPromptDesigner,this.state.passedCommandDraft,this.state.docContent, this.state.showGradePrompt, this.state.modalIsOpen, this.state.clickerToggler)}
            </div>
          )}
        </div>

    )
  }
}

export default withRouter(AdvEditDocument);
