import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubGoalDetails from '../Common/GoalDetails';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const difficultyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/difficulty-icon-blue.png';
const ratingsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/ratings-icon-blue.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';

class RenderCourses extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderCourses', this.props, prevProps)

      // if (this.props.activeOrg !== prevProps.activeOrg) {
      //   this.retrieveData()
      // } else if (this.props.animating) {
      // }
    }

    retrieveData() {
      console.log('retrieveData called in renderGoals')

      // const activeOrg = this.props.activeOrg
      // this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <div className="row-20">

            {(this.props.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Pulling results...</p>

                </div>
              </div>
            ) : (
              <div>
                  {this.props.courses && this.props.courses.map((value, index) =>
                    <div>
                      <div key={index}>
                        <div className="spacer" />
                        <a href={(value.programURL) ? value.programURL : 'https://www.udemy.com' + value.url} className={(this.props.pageSource === 'landingPage') ? "background-button calc-column-offset-25" : "background-button calc-column-offset-65"} target="_blank">
                          <div className="fixed-column-70">
                            <img src={(value.image_125_H) ? value.image_125_H : (value.imageURL) ? value.imageURL : courseIconDark} alt="GC" className="image-60-auto"/>
                          </div>
                          <div className="calc-column-offset-70">
                            <p className="heading-text-5">{value.title}</p>
                            <p className="description-text-1 description-text-color">{(value.headline) ? value.headliine : value.description}</p>

                            <div className="half-spacer" />

                            {(value.duration) && (
                              <div className="description-text-3">
                                <div className="float-left right-margin">
                                  <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                </div>
                                <div className="float-left right-margin">
                                  <label>{value.duration}</label>
                                </div>
                              </div>
                            )}

                            {(value.price) && (
                              <div className="description-text-3">
                                <div className="float-left right-margin">
                                  <img src={moneyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                </div>
                                <div className="float-left right-margin">
                                  <label>{value.price}</label>
                                </div>
                              </div>
                            )}

                            {(value.difficultyLevel) && (
                              <div className="description-text-3">
                                <div className="float-left right-margin">
                                  <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                </div>
                                <div className="float-left right-margin">
                                  <label>{value.difficultyLevel ? value.difficultyLevel : "Beginner"}</label>
                                </div>
                              </div>
                            )}

                            {(value.rating && value.ratingCount) && (
                              <div className="description-text-3">
                                <div className="float-left right-margin">
                                  <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                </div>
                                <div className="float-left right-margin">
                                  <label>{value.rating} / 5.0 - {value.ratingCount} Ratings</label>
                                </div>
                              </div>
                            )}

                            {(value.studentCount) && (
                              <div className="description-text-3">
                                <div className="float-left right-margin">
                                  <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                                </div>
                                <div className="float-left right-margin">
                                  <label>{value.studentCount} Students</label>
                                </div>
                              </div>
                            )}

                            <div className="clear" />
                            <div className="half-spacer" />
                          </div>
                        </a>

                        {(this.props.pageSource !== 'landingPage') && (
                          <span>
                            <div>
                              <div className="fixed-column-25 left-padding" >
                                <span>
                                  <div className="spacer"/><div className="half-spacer"/>
                                  <a href={(value.programURL) ? value.programURL : 'https://www.udemy.com' + value.url} className="background-button full-width" target="_blank">
                                    <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </span>
                        )}

                        <div className="clear"/>

                        {(this.props.sortCriteriaArray && this.props.sortCriteriaArray[index] && this.props.sortCriteriaArray[index].name) && (
                          <div className="left-padding-70">
                            <div className="half-spacer" />
                            <p className="description-text-2 error-color row-5">{this.props.sortCriteriaArray[index].name}: {this.standardizeValue('sort',index, this.props.sortCriteriaArray[index].criteria)}</p>
                          </div>
                        )}
                        {(this.props.filterCriteriaArray && this.props.filterCriteriaArray[index] && this.props.filterCriteriaArray[index].name) && (
                          <div className="left-padding-70">
                            <div className="half-spacer" />
                            <p className="description-text-2 error-color row-5">{this.props.filterCriteriaArray[index].name}: {this.props.filterCriteriaArray[index].criteria}</p>
                          </div>
                        )}
                        <div className="spacer" /><div className="spacer" />
                        <hr className="clear-margin clear-padding" />
                        <div className="clear"/>
                        <div className="spacer" />
                      </div>

                    </div>
                  )}

              </div>
            )}

          </div>
        </div>
      )
    }
}

export default RenderCourses;
