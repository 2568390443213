import React, {Component} from 'react';
import Axios from 'axios';

const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class RenderMyGroups extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.confirmDelete = this.confirmDelete.bind(this)
        this.leaveGroup = this.leaveGroup.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderMyGroups', this.props.groups)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('a0 will update')
        this.retrieveData()
      } else if (this.props.groups !== prevProps.groups) {
        console.log('a1 will update')
        this.retrieveData()
      } else if (this.props.groups && prevProps.groups) {
        console.log('a2 will update', this.props.groups.length, prevProps.groups.length)
        if (this.props.groups.length !== prevProps.groups.length) {
          console.log('a5 will update')
          this.retrieveData()
        }
      } else if (this.props.passedCategory !== prevProps.passedCategory) {
        console.log('a3 will update')
        this.retrieveData()
      } else {
        console.log('a4 wont update')
      }
    }

    retrieveData() {
      console.log('retrieveData called in commonRenderMyGroups', this.props.groups)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const pictureURL = localStorage.getItem('pictureURL');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');

      const groups = this.props.groups

      this.setState({ emailId, username, cuFirstName, cuLastName, pictureURL, activeOrg, orgFocus, orgName,
        groups
      })

    }

    confirmDelete(index,confirm) {
      console.log('confirmDelete called')

      let groups = this.state.groups
      if (confirm) {
        groups[index]['confirmDelete'] = true
      } else {
        groups[index]['confirmDelete'] = false
      }

      this.setState({ groups })
    }

    leaveGroup(index) {
      console.log('leaveGroup called')

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let groupId = this.state.groups[index]._id
      const emailId = this.state.emalId
      const member = { email: this.state.emailId }
      const leaveGroup = true

      Axios.post('/api/groups/save', { groupId, emailId, member, leaveGroup })
      .then((response) => {
        console.log('attempting to save addition to groups')
        if (response.data.success) {
          console.log('saved addition to groups', response.data)

          let groups = this.state.groups
          groups.splice(index,1)

          this.setState({ successMessage: 'Saved changes', groups, isSaving: false })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
      });
    }

    render() {

      return (
          <div>
            {(this.state.groups && this.state.groups.length > 0) && (
              <div className="row-20 full-width">
                {this.state.groups.map((item, index) =>
                  <div key={index}>
                    {console.log('show item: ', index + 1, item.name)}
                    {((!this.props.passedCategory) || (this.props.passedCategory === 'Accountability' && item.category === 'Accountability')) && (
                      <div className="top-margin">
                        <div className="fixed-column-90">
                          <img src={(item.pictureURL) ? item.pictureURL :"https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/headerImages/1210x311.png"} alt="GC" className="image-70-cover"  />
                        </div>
                        <div className="calc-column-offset-170">
                          <p className="heading-text-5">{item.name}</p>
                          <p className="description-text-1 top-padding-5">{item.category} | {(item.category === 'Accountability') ? item.members.length + ' / 6' : item.memberCount} Members</p>
                          <div className="top-margin">
                            <div className="float-left right-padding">
                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                            </div>
                            <div className="float-left">
                              <p className="description-text-2">Joined</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                        <div className="fixed-column-80 right-text">
                          {(item.confirmDelete) ? (
                            <div>
                              <p className="description-text-2 full-width right-text">You sure?</p>
                              <button className="btn btn-squarish error-background-color white-text top-margin" onClick={() => this.leaveGroup(index)}>Leave</button>
                              <button className="btn btn-squarish top-margin white-background cta-color" onClick={() => this.confirmDelete(index,false)}>Cancel</button>
                            </div>
                          ) : (
                            <button className="background-button" onClick={() => this.confirmDelete(index,true)}>
                              <div className="padding-10 circle-radius standard-border">
                                <img src={closeIcon} alt="GC" className="image-auto-15 pin-right"  />
                              </div>
                            </button>
                          )}
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </div>
                )}
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}

              </div>
            )}
          </div>

      )
    }
}

export default RenderMyGroups;
