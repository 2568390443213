import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";

import SubComments from '../Common/Comments';
import SubCreatePost from '../Common/CreatePost';
import {toggleVars} from '../Functions/ToggleVars';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const educationIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/education-icon.png";
const favoritesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png";
const trendsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trends-icon-dark.png';
const likeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-blue.png';
const likeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-dark.png';
const commentIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment-icon-dark.png';
const shareIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const menuIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png';
const reportIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/report-icon-dark.png';
const hideIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon-dark.png';
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const dropdownArrow = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png';
// const targetIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png";
const targetIconOrange = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const pinIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pin-icon.png';
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const infoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png';
// const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
// const trashIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trash-icon-dark.png';

class RenderPosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showCompassAssistant: true,
          adjustFeedPreferenceOptions: [
            "I'm not interested in the author",
            "I'm not interested in this topic",
            "I've seen too many posts on this topic",
            "I've seen this post before",
            "This post is old",
            "It's something else",
          ],
          reportOptions: [
            "Suspicious or fake",
            "Harassment or hateful speech",
            "Violence or physical harm",
            "Adult content",
            "Intellectual property infringement or defamation",
          ],
        }

        this.renderPost = this.renderPost.bind(this)
        this.calculateWidth = this.calculateWidth.bind(this)
        this.renderOriginalPost = this.renderOriginalPost.bind(this)
        this.selectAnswer = this.selectAnswer.bind(this)
        this.retrieveComments = this.retrieveComments.bind(this)
        this.retrieveLikes = this.retrieveLikes.bind(this)
        this.togglePostMenu = this.togglePostMenu.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.submitReport = this.submitReport.bind(this)
        this.showPollDetails = this.showPollDetails.bind(this)
        this.renderTaggedItem = this.renderTaggedItem.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.commandClicked = this.commandClicked.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderPosts', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts  || this.props.passedGroupPost !== prevProps.passedGroupPost) {
        this.retrieveData()
      } else if (this.props.mostActiveGoal !== prevProps.mostActiveGoal) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderPosts')

      let emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let pictureURL = localStorage.getItem('pictureURL');
      if (this.props.pictureURL && !pictureURL) {
        pictureURL = this.props.pictureURL
      }

      const posts = this.props.posts
      const passedGroupPost = this.props.passedGroupPost
      let modalIsOpen = false
      if (passedGroupPost) {
        modalIsOpen = true
        this.retrieveComments(posts,null, passedGroupPost._id)
      }
      const accountCode = this.props.accountCode
      const mostActiveGoal = this.props.mostActiveGoal
      const disableChatbotPrompts = this.props.disableChatbotPrompts

      let showCompassAssistant = false
      // if (mostActiveGoal && (toggleVars.demoMode || (activeOrg !== 'fosternation' && activeOrg !== 'unite-la'))) {
      if (mostActiveGoal && this.state.showCompassAssistant && !disableChatbotPrompts) {
        showCompassAssistant = true
      }

      let goalCommands = []
      if (showCompassAssistant) {
        goalCommands = [
          'What are some strategies and tactics to achieve my goal of ' + mostActiveGoal.title + ' in 12 months?',
          'What are the key competencies I need to achieve my goal of ' + mostActiveGoal.title + '?',
          'What are some great resources to help me achieve my goal of ' + mostActiveGoal.title + '?',
          'Create a checklist of tasks to complete with deadlines to achieve my goal of ' + mostActiveGoal.title + '?',
          'What are some independent projects I can work on to gain advocates for achieving my goal of ' + mostActiveGoal.title + '?',
        ]
      }
      // console.log('---- dd', goalCommands)
      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, username, posts,
        passedGroupPost, modalIsOpen, pictureURL, accountCode, mostActiveGoal, showCompassAssistant, goalCommands })

    }

    closeModal() {

      this.setState({ modalIsOpen: false, showMessageWidget: false, showUpvotes: false, showComments: false, showShareButtons: false,
        sharePosting: false, adjustFeedPreferences: false, reportPostView: false, showDeletePost: false, showReports: false, originalPost: null,
        passedGroupPost: null
      });
    }

    calculateWidth(item, answer) {
      console.log('calculateWidth called', item, answer)

      let width = '0%'

      let aValue = 0
      if (item.aVotes) {
        aValue = item.aVotes.length
      }

      let bValue = 0
      if (item.bVotes) {
        bValue = item.bVotes.length
      }

      let totalValue = aValue + bValue
      if (totalValue > 0) {
        let numRawValue = 0
        if (answer === 'a') {
          numRawValue = (aValue / (aValue + bValue)) * 100
        } else {
          numRawValue = (bValue / (bValue + aValue)) * 100
        }
        if (numRawValue) {
          const roundedValue = numRawValue.toFixed()
          width = (roundedValue).toString() + '%'
        }
      }


      return width
    }

    renderPost(value, index, inModal, passedGroupPost) {
      console.log('renderPost called', passedGroupPost, value)

      if (value) {
        let defaultProfileItemIcon = projectsIconDark
        if (value.profileItem) {
          if (value.profileItem.category === 'Experience') {
            defaultProfileItemIcon = experienceIcon
          } else if (value.profileItem.category === 'Education') {
            defaultProfileItemIcon = educationIcon
          } else if (value.profileItem.category === 'Career Goal') {
            defaultProfileItemIcon = targetIconOrange
          } else if (value.profileItem.category === 'Passion') {
            defaultProfileItemIcon = favoritesIconDark
          }
        }

        let profileLink = ''
        let isDisabled = false
        let newTab = false
        if (window.location.pathname.includes('/app')) {
          profileLink = '/app/profile/' + value.username
          if (value.employerId) {
            profileLink = '/app/employers/' + value.employerId
          }
          if (value.roleName === 'Admin') {
            profileLink = '/app/orgs/' + value.orgCode
          } else if (value.roleName === 'Teacher' || value.roleName === 'School Support' || value.roleName === 'Counselor' || value.roleName === 'WBLC' || value.roleName === 'Work-Based Learning Coordinator') {
            profileLink = '/app/advisors/' + value.username
            isDisabled = true
          }
        } else if (window.location.pathname.includes('/advisor')) {
          profileLink = '/advisor/advisees/' + value.email
          if (this.state.roleName === 'Mentor') {
            profileLink = '/advisor/profile/' + value.username
          }

          if (value.employerId) {
            profileLink = '/advisor/employers/' + value.employerId
          }
          if (value.roleName === 'Admin') {
            profileLink = '/advisor/orgs/' + value.orgCode
          } else if (value.roleName === 'Teacher' || value.roleName === 'School Support' || value.roleName === 'Counselor' || value.roleName === 'WBLC' || value.roleName === 'Work-Based Learning Coordinator') {
            profileLink = '/advisor/advisors/' + value.username
            isDisabled = true
          }

        } else if (window.location.pathname.includes('/organizations')) {
          profileLink = '/organizations/' + this.state.activeOrg + '/students/' + value.email
          if (value.employerId) {
            profileLink = '/organizations/' + this.state.activeOrg + '/employer-details/' + value.employerId
          }
          if (value.roleName === 'Admin') {
            profileLink = '/organizations/' + this.state.activeOrg + '/orgs/' + value.orgCode
          } else if (value.roleName === 'Teacher' || value.roleName === 'School Support' || value.roleName === 'Counselor' || value.roleName === 'WBLC' || value.roleName === 'Work-Based Learning Coordinator') {
            profileLink = '/organizations/' + this.state.activeOrg + '/advisors/' + value.username
            isDisabled = true
          }
        } else if (window.location.pathname.includes('/employers')) {
          profileLink = '/employers/' + this.state.accountCode + '/career-seekers/' + value.email
          if (value.employerId) {
            profileLink = '/employers/pages/' + this.state.accountCode
            newTab = true
          }
          if (value.roleName === 'Admin') {
            profileLink = '/organizations/' + this.state.activeOrg + '/orgs/' + value.orgCode
          } else if (value.roleName === 'Teacher' || value.roleName === 'School Support' || value.roleName === 'Counselor' || value.roleName === 'WBLC' || value.roleName === 'Work-Based Learning Coordinator') {
            profileLink = '/organizations/' + this.state.activeOrg + '/advisors/' + value.username
            isDisabled = true
          }
        }

        return (
          <div key={value + index}>
            <div className={(!inModal) && "card top-margin-20"}>
              <div>
                <Link to={profileLink} disabled={isDisabled} target={(newTab) && "_blank"} className={(window.location.pathname.includes('/app/groups/')) ? "background-button left-text standard-color profile-container-right calc-column-offset-80" : "background-button left-text standard-color profile-container-right calc-column-offset-30"}>
                  <div className="fixed-column-70 right-padding">
                    {(value.roleName === 'Admin') ? (
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="image-60-fit" alt="GC" />
                    ) : (
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-2 standard-border" alt="GC" />
                    )}
                  </div>
                  <div className="calc-column-offset-70">
                    <div className="calc-column-offset-25">
                      <p className="heading-text-5">{value.firstName} {value.lastName}</p>
                    </div>
                    {(value.pinned) && (
                      <div className="fixed-column-25 top-padding-5 left-padding">
                        <img src={pinIcon} className="image-auto-10" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="pinned post" />
                      </div>
                    )}
                    <div className="clear" />

                    <div className="top-padding-5">
                      {(value.headline && value.headline !== '') ? (
                        <div>
                          <p className="description-text-3">{value.headline}</p>
                        </div>
                      ) : (
                        <div>
                          {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                            <div>
                              {console.log('show edu: ', value.education)}
                              <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="top-padding-5">
                      <p className="description-text-4 description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                    </div>

                  </div>
                </Link>
                {/*
                <div className="fixed-column-70 right-padding">
                  <Link to={'/app/profile/' + item.username} className="background-button" target="_blank">
                    <img src={item.pictureURL} alt="GC" className="profile-thumbnail-2 standard-border" />
                  </Link>
                </div>
                <div className="calc-column-offset-150">
                  <Link to={'/app/profile/' + item.username} className="background-button" target="_blank">
                    <p className="heading-text-5 bold-text">{item.firstName} {item.lastName}</p>
                  </Link>
                  <p className="description-text-3">{item.headline}</p>
                  <p className="description-text-4 description-text-color bold-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
                </div>
                <div className="fixed-column-80 right-padding">
                  <button className="background-button" onClick={(e) => this.voteOnItem(e, item, 'up', index) }>
                    <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                      <div className="float-left padding-7">
                        <img src={(item.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="Compass arrow indicator icon" className="image-auto-15"/>
                      </div>
                      <div className="vertical-separator-4" />
                      <div className="float-left horizontal-padding-10">
                        <div className="half-spacer" />
                        <p className="description-text-2 half-bold-text">{item.upvotes.length}</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </button>
                </div>
                <div className="clear" />*/}

                <ReactTooltip id="tooltip-placeholder-id" />

                <div className="profile-modal-right">
                  <div>
                    <div className="fixed-column-55">
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                    </div>
                    <div className="calc-column-offset-55">
                      <p className="description-text-2 bold-text">{value.firstName} {value.lastName}</p>

                      {(value.headline && value.headline !== '') ? (
                        <div>
                          <p className="description-text-4 description-text-color">{value.headline}</p>
                        </div>
                      ) : (
                        <div>
                          {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                            <div>
                              <p className="description-text-4 description-text-color">Student @ {value.education[0].name}</p>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <p className="description-text-4 description-text-color">{this.state.orgName} Member</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <p className="description-text-4 description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="top-padding-20">
                    <Link to={'/app/messaging'} state={{ recipient: value }} className="background-button full-width"><button className="btn btn-squarish full-width">Message</button></Link>
                  </div>
                </div>

                {(window.location.pathname.includes('/app/groups/')) ? (
                  <div className="fixed-column-80 right-padding">
                    <button className="background-button" onClick={(e) => this.voteOnItem(e, value, 'up', index) }>
                      <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                        <div className="float-left padding-7">
                          <img src={(value.upvotes && value.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="GC" className="image-auto-15"/>
                        </div>
                        <div className="vertical-separator-4" />
                        <div className="float-left horizontal-padding-10">
                          <div className="half-spacer" />
                          <p className="description-text-2 half-bold-text">{(value.upvotes) ? value.upvotes.length : '0'}</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </button>
                  </div>
                ) : (
                  <div className="fixed-column-30">
                    <button onClick={(value.showPostMenu) ? () => this.togglePostMenu(index) : () => this.togglePostMenu(index)} className="background-button">
                      <div className="row-5 horizontal-padding-4">
                        <img src={menuIconDark} className="image-15-auto pin-right" alt="GC" />
                      </div>
                    </button>
                    {(value.showPostMenu) && (
                      <div className="menu-bottom description-text-3">

                        {(window.location.pathname.includes('/organizations')) ? (
                          <div>
                            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showDeletePost: true, selectedIndex: index })}>
                              <div className="row-5">
                                <div className="fixed-column-25">
                                  <img src={hideIconDark} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="calc-column-offset-25">
                                  <p>Delete this post</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showReports: true, selectedIndex: index })}>
                              <div className="row-5">
                                <div className="fixed-column-25">
                                  <img src={reportIconDark} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="calc-column-offset-25">
                                  <p>View all reports</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true, selectedIndex: index })}>
                              <div className="row-5">
                                <div className="fixed-column-25">
                                  <img src={shareIconDark} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="calc-column-offset-25">
                                  <p>Share outside of Guided Compass</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, adjustFeedPreferences: true, selectedIndex: index })}>
                              <div className="row-5">
                                <div className="fixed-column-25">
                                  <img src={hideIconDark} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="calc-column-offset-25">
                                  <p>I don't want to see this</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, reportPostView: true, selectedIndex: index })}>
                              <div className="row-5">
                                <div className="fixed-column-25">
                                  <img src={reportIconDark} alt="GC" className="image-auto-15" />
                                </div>
                                <div className="calc-column-offset-25">
                                  <p>Report this post</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                            {(value.email === this.state.emailId) && (
                              <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showDeletePost: true, selectedIndex: index })}>
                                <div className="row-5">
                                  <div className="fixed-column-25">
                                    <img src={hideIconDark} alt="GC" className="image-auto-15" />
                                  </div>
                                  <div className="calc-column-offset-25">
                                    <p className="error-color">Delete this post</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="clear" />
              </div>

              <div className="row-15">
                <p className={(value.postType === 'alternatives') ? "" : "description-text-3"}>{value.message}</p>
                {(value.url) && (
                  <a className="description-text-3 top-padding bold-text" href={value.url} target="_blank">{value.url}</a>
                )}
                {/*
                <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showVotes: true, selectedIndex: index })}>
                  <div>
                    <div className="progress-bar-thin" >
                      <div className="filler" style={{ width: '60%' }} />
                    </div>
                  </div>
                </button>
                <p>answer selected</p>*/}

                {(value.postType === 'alternatives') && (
                  <div className="top-padding">

                    <div className="row-10">
                      <button className="background-button full-width left-text" onClick={() => this.showPollDetails(value, index)}>
                        <div>
                          <div className="float-left">
                            <p className="description-text-3 cta-color">{(value.showPollDetails) ? "Collapse Details" : "Expand Details"}</p>
                          </div>
                          <div className="float-left left-padding top-padding-5">
                            <img src={dropdownArrow} alt="GC" className="image-auto-8 pin-right" />
                          </div>
                          <div className="clear" />

                        </div>
                      </button>

                      {(value.showPollDetails) && (
                        <div>
                          <div className="row-10">
                            {/*
                            <Link to={'/app/projects/' + item._id} className="background-button standard-color full-width">
                              <div className="calc-column-offset-80">
                                <p>A: {value.aName}</p>
                              </div>
                              <div className="fixed-column-80">
                                <p className="bold-text right-text cta-color">${value.aValue}</p>
                              </div>
                              <div className="clear" />
                            </Link>*/}

                            {(value.aItem) && (
                              <div>
                                <div>
                                  {(value.comparisonType === 'Projects') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'project', 'a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Careers') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'career','a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Competencies') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'competency','a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Jobs') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'work','a')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <p className="description-text-3">{value.aCase}</p>
                          </div>

                          <div className="row-10">
                            {(value.bItem) && (
                              <div>
                                <div>
                                  {(value.comparisonType === 'Projects') && (
                                    <div>
                                      {this.renderTaggedItem(value,'project','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Careers') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'career','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Competencies') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'competency','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Jobs') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'work','b')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <p className="description-text-3">{value.bCase}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {((value.aVotes && value.aVotes.includes(this.state.emailId)) || (value.bVotes && value.bVotes.includes(this.state.emailId))) ? (
                      <div>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'a')}>
                          <div>
                            <div className="progress-bar-fat" >
                              <div className="filler-error" style={{ width: this.calculateWidth(value, 'a'), zIndex: -1, height: '36px' }} />
                              <div className="row-10 horizontal-padding " style={{ marginTop: '-36px'}}>
                                <div className="calc-column-offset-40 left-text">
                                  <p className="description-text-2 curtail-text">{value.aName}</p>
                                </div>
                                <div className="fixed-column-40 right-text">
                                  <p className="description-text-2 curtail-text">{this.calculateWidth(value, 'a')}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </button>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'b')}>
                          <div>
                            <div className="progress-bar-fat" >
                              <div className="filler-error" style={{ width: this.calculateWidth(value, 'b'), zIndex: -1, height: '36px' }} />
                              <div className="row-10 horizontal-padding" style={{ marginTop: '-36px'}}>
                                <div className="calc-column-offset-40 left-text">
                                  <p className="description-text-2 curtail-text">{value.bName}</p>
                                </div>
                                <div className="fixed-column-40 right-text">
                                  <p className="description-text-2 curtail-text">{this.calculateWidth(value, 'b')}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'a')}>
                          <div className="row-10 horizontal-padding cta-border">
                            <p className="description-text-2">{value.aName}</p>
                          </div>
                        </button>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'b')}>
                          <div className="row-10 horizontal-padding cta-border">
                            <p className="description-text-2">{value.bName}</p>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}

              </div>

              {(value.imageURL) && (
                <div className="row-10">
                  <img src={value.imageURL} alt="GC" className="image-full-auto" />
                </div>
              )}

              {(value.videoURL) && (
                <div className="row-10">
                  <div className="spacer"/>

                  <div>
                    <div className="video-container">
                      <iframe
                        title="videoLink"
                        className="video-iframe"
                        src={`${value.videoURL}`}
                        frameBorder="0"
                      />
                    </div>

                  </div>

                  <div className="clear" />
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                </div>
              )}

              {(value.profileItem) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={(value.profileItem.category === "Career Goal") ? '/app/goals/' + value.profileItem.objectId : '/app/profile/' + value.username} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.profileItem.imageURL) ? value.profileItem.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{value.profileItem.name}</p>
                          <div className="mini-spacer" /><div className="mini-spacer" />

                          {(value.profileItem.category === 'Project') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.category} | {value.profileItem.hours} Hours</p>
                          )}
                          {(value.profileItem.category === 'Experience') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.startDate} - {value.profileItem.endDate}</p>
                          )}
                          {(value.profileItem.category === 'Education') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.startDate} - {value.profileItem.endDate}</p>
                          )}
                          {(value.profileItem.category === 'Career Goal') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.description}</p>
                          )}
                          {(value.profileItem.category === 'Passion') && (
                            <p className="description-text-3 description-text-color">Last Updated {value.profileItem.updatedAt}</p>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.opportunityTags && value.opportunityTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/opportunities/' + value.opportunityTags[0]._id} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.opportunityTags[0].imageURL) ? value.opportunityTags[0].imageURL : opportunitiesIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          {(value.opportunityTags[0].title) ? (
                            <p>{value.opportunityTags[0].title}</p>
                          ) : (
                            <p>{value.opportunityTags[0].name}</p>
                          )}

                          {(value.opportunityTags[0].employerName) && (
                            <p className="description-text-3 description-text-color">{value.opportunityTags[0].employerName}</p>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.careerTags && value.careerTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/careers/' + value.careerTags[0].name} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.careerTags[0].imageURL) ? value.careerTags[0].imageURL : careerMatchesIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{value.careerTags[0].name}</p>
                          <label className="description-text-3 description-text-color">{value.careerTags[0].jobFamily}</label>

                          {(value.careerTags[0].marketData) && (
                            <label className="description-text-3 description-text-color"> | ${Number(value.careerTags[0].marketData.pay).toLocaleString()} avg pay</label>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.trendTags && value.trendTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/exploration'} state={{ subNavSelected: 'Trends' }} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.trendTags[0].imageURL) ? value.trendTags[0].imageURL : trendsIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-120">
                          <p>{value.trendTags[0].name}</p>
                          <label className="description-text-3 description-text-color">{value.trendTags[0].category}</label>
                        </div>

                        {(value.trendTags[0].percentChange) && (
                          <div className="fixed-column-60">
                            <p className="heading-text-3 cta-color full-width right-text">{Number(value.trendTags[0].percentChange).toFixed()}%</p>
                            <p className="description-text-5 full-width right-text">increase in U.S. jobs</p>
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.tags && value.tags.length > 0) && (
                <div className="bottom-padding">
                  {value.tags.map((item2, index2) =>
                    <div key={index2} className="float-left right-padding top-padding">
                      <div className="tag-container-thin">
                        <p className="description-text-4">{item2}</p>
                      </div>
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}

              {(value.entityTags && value.entityTags.length > 0) && (
                <div className="top-padding">
                  {value.entityTags.map((value2, optionIndex2) =>
                    <div key={value2} className="float-left right-padding">
                      <Link className="background-button standard-color" to={'/app/profile/' + value2.username}>
                        <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={value2.firstName + ' ' + value2.lastName} />
                      </Link>
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}

              {(value.originalPost && value.originalPost.message) && (
                <div className="cta-border padding-20">
                  {this.renderOriginalPost(value)}
                </div>
              )}

              {(value.upvotes || (value.comments && value.comments.length > 0)) && (
                <div className="bottom-padding-5">
                  <div className="fixed-column-160">
                    <button onClick={() => this.retrieveLikes(index)} className="background-button">
                      <label className="description-text-4">{(value.upvotes) ? value.upvotes.length : 0} Upvotes</label>
                    </button>
                    <label className="description-text-4 horizontal-padding-7">&#8226;</label>
                    <button onClick={() => this.retrieveComments(this.state.posts,index,null)} className="background-button">
                      <label className="description-text-4">{(value.commentCount) ? value.commentCount : 0} Comments</label>
                    </button>
                  </div>

                  <div className="clear" />

                </div>
              )}

              <div className="spacer" />

              <hr />

              {(!inModal) && (
                <div className="top-padding-15">
                  <div className={(window.innerWidth > 768) ? "float-left" : "relative-column-25"}>
                    <button onClick={(e) => this.voteOnItem(e, value, 'up', index) } className="background-button">
                      <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                        <img src={(value.upvotes.includes(this.state.emailId))? likeIconBlue : likeIconDark} alt="GC" className={(window.innerWidth > 768) ? "image-15-fit center-horizontally" : "image-auto-14 center-horizontally"} />
                      </div>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                        <p className={(value.upvotes.includes(this.state.emailId)) ? "description-text-3 cta-color bold-text" : "description-text-2"}>{(value.upvotes.includes(this.state.emailId)) ? "Liked" : "Like"}</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>

                  <div className={(window.innerWidth > 768) ? "float-left" : "relative-column-25"}>
                    <button onClick={() => this.retrieveComments(this.state.posts,index,null)} className="background-button" disabled={this.state.isLoading}>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                        <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                        <img src={commentIconDark} alt="GC" className={(window.innerWidth > 768) ? "image-15-fit center-horizontally" : "image-auto-14 center-horizontally"} />
                      </div>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "calc-column-offset-30 right-padding-20 curtail-text"}>
                        <p className="description-text-3">Comment</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>
                  {(!window.location.pathname.includes('/employers')) && (
                    <div className={(window.innerWidth > 768) ? "float-left" : "relative-column-25"}>
                      <button onClick={(value.originalPost && value.originalPost.message) ? () => this.setState({ modalIsOpen: true, sharePosting: true, originalPost: value.originalPost, selectedIndex: index }) : () => this.setState({ modalIsOpen: true, sharePosting: true, originalPost: value, selectedIndex: index })} className="background-button">
                        <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                          <img src={shareIconDark} alt="GC" className={(window.innerWidth > 768) ? "image-15-fit center-horizontally" : "image-auto-14 center-horizontally"} />
                        </div>
                        <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                          <p className="description-text-3">Share</p>
                        </div>
                        <div className="clear" />
                      </button>
                    </div>
                  )}
                  {(!window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor') && !window.location.pathname.includes('/employers')) && (
                    <div className={(window.innerWidth > 768) ? "float-left" : "relative-column-25"}>
                      <Link className="background-button standard-color" to={'/app/messaging'} state={{ generalPost: value }}>
                        <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                          <img src={sendIconDark} alt="GC" className={(window.innerWidth > 768) ? "image-auto-18 center-horizontally" : "image-auto-14 center-horizontally"} />
                        </div>
                        <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                          <p className="description-text-3">Send</p>
                        </div>
                        <div className="clear" />
                      </Link>
                    </div>
                  )}

                  <div className="clear" />
                </div>
              )}
            </div>
          </div>
        )
      }
    }

    renderOriginalPost(value) {
      console.log('renderOriginalPost called', value)

      let defaultProfileItemIcon = projectsIconDark
      if (value.profileItem) {
        if (value.profileItem.category === 'Experience') {
          defaultProfileItemIcon = experienceIcon
        } else if (value.profileItem.category === 'Education') {
          defaultProfileItemIcon = educationIcon
        } else if (value.profileItem.category === 'Career Goal') {
          defaultProfileItemIcon = targetIconOrange
        } else if (value.profileItem.category === 'Passion') {
          defaultProfileItemIcon = favoritesIconDark
        }
      }

      let profileLink = '/app/profile/' + value.originalPost.username
      if (value.originalPost.employerId) {
        profileLink = '/app/employers/' + value.originalPost.employerId
      }

      return (
        <div key="originalPost">
          <div>
            <Link to={profileLink} className="background-button standard-color profile-container-right calc-column-offset-30">
              <div className="fixed-column-55">
                {(value.originalPost.roleName === 'Admin') ? (
                  <img src={(value.originalPost.pictureURL) ? value.originalPost.pictureURL : profileIconDark} className="image-40-fit" alt="GC" />
                ) : (
                  <img src={(value.originalPost.pictureURL) ? value.originalPost.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                )}
              </div>
              <div className="calc-column-offset-55">
                <div className="calc-column-offset-25">
                  <p className="description-text-1 bold-text">{value.originalPost.firstName} {value.originalPost.lastName}</p>
                </div>
                <div className="clear" />

                <div className="mini-spacer" /><div className="mini-spacer" />

                {(value.originalPost.headline && value.originalPost.headline !== '') ? (
                  <div>
                    <p className="description-text-3 description-text-color">{value.originalPost.headline}</p>
                  </div>
                ) : (
                  <div>
                    {(value.originalPost.education && value.originalPost.education[0] && value.originalPost.education[0].name && value.originalPost.education[0].isContinual) ? (
                      <div>
                        <p className="description-text-3 description-text-color">Student @ {value.originalPost.education[0].name}</p>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <p className="description-text-4 description-text-color">{convertDateToString(value.originalPost.createdAt,"daysAgo")}</p>
              </div>
            </Link>

            <div className="fixed-column-30">

            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <p className="description-text-2">{value.originalPost.message}</p>

            {(value.originalPost.url) && (
              <a className="description-text-3 top-padding bold-text" href={value.url} target="_blank">{value.originalPost.url}</a>
            )}
          </div>
          {(value.originalPost.imageURL) && (
            <div className="row-10">
              <img src={value.originalPost.imageURL} alt="GC" className="image-full-auto" />
            </div>
          )}

          {(value.originalPost.videoURL) && (
            <div className="row-10">
              <div className="spacer"/>

              <div>
                <div className="video-container">
                  <iframe
                    title="videoLink"
                    className="video-iframe"
                    src={`${value.originalPost.videoURL}`}
                    frameBorder="0"
                  />
                </div>

              </div>

              <div className="clear" />
              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
            </div>
          )}

          {(value.originalPost.profileItem) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/profile/' + value.originalPost.username} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.profileItem.imageURL) ? value.originalPost.profileItem.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{value.originalPost.profileItem.name}</p>
                      {(value.originalPost.profileItem.category === 'Project') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.category} | {value.originalPost.profileItem.hours} Hours</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Experience') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.startDate} - {value.originalPost.profileItem.endDate}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Education') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.startDate} - {value.originalPost.profileItem.endDate}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Career Goal' && value.originalPost.profileItem.deadline) && (
                        <p className="description-text-3 description-text-color">Deadline: {value.originalPost.profileItem.deadline}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Passion') && (
                        <p className="description-text-3 description-text-color">Last Updated {value.originalPost.profileItem.updatedAt}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.opportunityTags && value.originalPost.opportunityTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + value.originalPost.opportunityTags[0]._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.opportunityTags[0].imageURL) ? value.originalPost.opportunityTags[0].imageURL : opportunitiesIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      {(value.originalPost.opportunityTags[0].title) ? (
                        <p>{value.originalPost.opportunityTags[0].title}</p>
                      ) : (
                        <p>{value.originalPost.opportunityTags[0].name}</p>
                      )}

                      {(value.originalPost.opportunityTags[0].employerName) && (
                        <p className="description-text-3 description-text-color">{value.originalPost.opportunityTags[0].employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.careerTags && value.originalPost.careerTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + value.originalPost.careerTags[0].name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.careerTags[0].imageURL) ? value.originalPost.careerTags[0].imageURL : careerMatchesIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{value.originalPost.careerTags[0].name}</p>
                      <label className="description-text-3 description-text-color">{value.originalPost.careerTags[0].jobFamily}</label>

                      {(value.originalPost.careerTags[0].marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(value.originalPost.careerTags[0].marketData.pay).toLocaleString()} avg pay</label>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.trendTags && value.originalPost.trendTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/exploration'} state={{ subNavSelected: 'Trends' }} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.trendTags[0].imageURL) ? value.originalPost.trendTags[0].imageURL : trendsIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-120">
                      <p>{value.originalPost.trendTags[0].name}</p>
                      <label className="description-text-3 description-text-color">{value.originalPost.trendTags[0].category}</label>
                    </div>

                    {(value.originalPost.trendTags[0].percentChange) && (
                      <div className="fixed-column-60">
                        <p className="heading-text-3 cta-color full-width right-text">{Number(value.originalPost.trendTags[0].percentChange).toFixed()}%</p>
                        <p className="description-text-5 full-width right-text">increase in U.S. jobs</p>
                      </div>
                    )}

                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.tags && value.originalPost.tags.length > 0) && (
            <div className="bottom-padding">
              {value.originalPost.tags.map((item2, index2) =>
                <div key={index2} className="float-left right-padding top-padding">
                  <div className="tag-container-thin">
                    <p className="description-text-4">{item2}</p>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          )}

          {(value.originalPost.entityTags && value.originalPost.entityTags.length > 0) && (
            <div className="top-padding">
              {value.originalPost.entityTags.map((value2, optionIndex2) =>
                <div key={value2} className="float-left right-padding">
                  <Link className="background-button standard-color" to={'/app/profile/' + value2.username}>
                    <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={value2.firstName + ' ' + value2.lastName} />
                  </Link>
                </div>
              )}
              <div className="clear" />
            </div>
          )}

        </div>
      )
    }

    selectAnswer(value, index,answer) {
      console.log('selectAnswer', answer)

      let posts = this.state.posts
      const emailId = this.state.emailId

      // do all this on the backend
      Axios.put('/api/group-post/poll-vote', {  _id: posts[index]._id, answer, emailId })
      .then((response) => {
        console.log('Poll vote attempted', response.data);

          if (response.data.success) {
            console.log('successfully recorded poll vote')

            posts[index] = response.data.groupPost
            this.setState({ posts })

          } else {
            console.log('there was an error saving the poll data', response.data.message)

          }

      }).catch((error) => {
          console.log('there was an error saving the poll data', error);

      });

    }

    retrieveComments(posts, index, passedPostId) {
      console.log('retrieveComments called', index)

      let parentPostId = passedPostId
      if (posts[index]) {
        parentPostId = posts[index]._id
      }
      // if (index || index === 0) {
      //   parentPostId = this.state.posts[index]._id
      // } else {
      //   parentPostId = this.state.passedGroupPost
      // }
      // pull comments
      Axios.get('/api/comments', { params: { parentPostId } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           const comments = response.data.comments
           if (passedPostId) {
             this.setState({ modalIsOpen: true, comments })
           } else {
             this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments })
           }

         } else {
           console.log('no comments data found', response.data.message)
           if (passedPostId) {
             this.setState({ modalIsOpen: true, comments: [] })
           } else {
             this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
           }
         }
      }).catch((error) => {
         console.log('Comments query did not work', error);
         if (passedPostId) {
           this.setState({ modalIsOpen: true, comments: [] })
         } else {
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
         }
      });
    }

    retrieveLikes(index) {
      console.log('retrieveLikes called', index)

      const userIds = this.state.posts[index].upvotes
      if (userIds) {
        // pull comments
        Axios.get('/api/users', { params: { userIds } })
        .then((response) => {
          console.log('Users query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved users')

             const upvotes = response.data.users
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes })

           } else {
             console.log('no upvotes data found', response.data.message)
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
           }
        }).catch((error) => {
           console.log('Upvotes query did not work', error);
           this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
        });
      } else {
        this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
      }
    }

    togglePostMenu(index) {
      console.log('togglePostMenu called', index)

      let posts = this.state.posts
      if (posts[index].showPostMenu) {
        posts[index]['showPostMenu'] = false
      } else {
        posts[index]['showPostMenu'] = true
      }

      this.setState({ posts })

    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      const shareURL = window.location.protocol + "//" + window.location.host + "/app/social-posts/" + this.state.posts[this.state.selectedIndex]._id
      const shareTitle = 'Check Out My Post On Guided Compass!'
      const shareBody = "Guided Compass is a great forum to connect with like-minded individuals, mentors / mentees, and opportunities."

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={shareURL} subject={shareTitle} body={shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={shareURL} title={shareTitle} summary={shareBody} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={shareURL} description={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    voteOnItem(e, item, direction, index) {
      console.log('voteOnItem called')

      this.setState({ successMessage: null, errorMessage: null })

      const _id = item._id
      const emailId = this.state.emailId
      let changeUpvote = true
      const updatedAt = new Date()

      Axios.post('/api/group-posts', { _id, emailId, changeUpvote, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Group posts save worked', response.data);

          const successMessage = 'Group posting successfully updated!'

          let upvotes = item.upvotes
          let downvotes = item.downvotes

          if (direction === 'up') {
            console.log('in up')

            if (upvotes.includes(this.state.emailId)) {
              const removeIndex = upvotes.indexOf(this.state.emailId)
              if (removeIndex > -1) {
                upvotes.splice(removeIndex, 1);
                item['upvotes'] = upvotes
                changeUpvote = true

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index]= item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }
              }
            } else {

              upvotes.push(this.state.emailId)
              changeUpvote = true

              if (downvotes.includes(this.state.emailId)) {
                const removeIndex = downvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  downvotes.splice(removeIndex, 1);
                }

                item['upvotes'] = upvotes
                item['downvotes'] = downvotes

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index] = item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }

              } else {

                item['upvotes'] = upvotes

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index] = item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }
              }
            }

          } else {

            if (downvotes.includes(this.state.emailId)) {
              console.log('un-downvoting')
              const removeIndex = downvotes.indexOf(this.state.emailId)
              if (removeIndex > -1) {
                downvotes.splice(removeIndex, 1);
                item['downvotes'] = downvotes

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index] = item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }
              }
            } else {
              console.log('initial downvote')
              downvotes.push(this.state.emailId)

              if (upvotes.includes(this.state.emailId)) {
                console.log('downvoting from previous upvote')
                const removeIndex = upvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  upvotes.splice(removeIndex, 1);
                  changeUpvote = true
                }

                item['upvotes'] = upvotes
                item['downvotes'] = downvotes

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index] = item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }

              } else {
                item['downvotes'] = downvotes

                if (index || index === 0) {
                  let posts = this.state.posts
                  posts[index] = item
                  this.setState({ posts, successMessage })
                } else {
                  const passedGroupPost = item
                  this.setState({ passedGroupPost, successMessage })
                }
              }
            }
          }

        } else {
          console.error('there was an error posting the group post');
          const errorMessage = response.data.message
          this.setState({ errorMessage })
        }
      }).catch((error) => {
          console.log('The talk post did not work', error);
      });
    }

    itemClicked(item, type) {
      console.log('itemClicked called', item, type)

      if (type === 'adjustFeedPreferences') {
        let selectedPreferences = this.state.selectedPreferences
        if (selectedPreferences) {
          if (selectedPreferences.includes(item)) {
            const index = selectedPreferences.indexOf(item)
            selectedPreferences.splice(index,1)
          } else {
            selectedPreferences.push(item)
          }
        } else {
          selectedPreferences = [item]
        }
        this.setState({ selectedPreferences })
      } else if (type === 'report') {

        let selectedReportReasons = this.state.selectedReportReasons
        if (selectedReportReasons) {
          if (selectedReportReasons.includes(item)) {
            const index = selectedReportReasons.indexOf(item)
            selectedReportReasons.splice(index,1)
          } else {
            selectedReportReasons.push(item)
          }
        } else {
          selectedReportReasons = [item]
        }
        this.setState({ selectedReportReasons })
      } else {
        let tags = this.state.tags
        if (tags.includes(item)) {
          const index = tags.indexOf(item)
          tags.splice(index, 1)
        } else {
          tags.push(item)
        }
        this.setState({ tags })
      }
    }

    submitReport(type) {
      console.log('submitReport called', type)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const postId = this.state.posts[this.state.selectedIndex]._id
      const postName = this.state.posts[this.state.selectedIndex].message

      const emailId = this.state.emailId
      const posterFirstName = this.state.posts[this.state.selectedIndex].firstName
      const posterLastName = this.state.posts[this.state.selectedIndex].lastName
      const reporterFirstName = this.state.cuFirstName
      const reporterLastName = this.state.cuLastName

      let selectedPreferences = this.state.selectedPreferences
      if (type === 'report') {
        selectedPreferences = this.state.selectedReportReasons
      }

      Axios.post('/api/group-posts/report', {
        postId, postName, emailId, posterFirstName, posterLastName, reporterFirstName, reporterLastName,
        type, selectedPreferences
      }).then((response) => {
        console.log('attempting to remove favorites')
        if (response.data.success) {
          console.log('saved removal from favorites', response.data)

          let posts = this.state.posts
          posts.splice(this.state.selectedIndex,1)
          this.setState({ posts, successMessage: response.data.message, isSaving: false })
          this.closeModal()
        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
      });
    }

    showPollDetails(value, index) {
      console.log('showPollDetails called', value, index)

      let posts = this.state.posts
      if (posts[index].showPollDetails) {
        posts[index]['showPollDetails'] = false
      } else {
        posts[index]['showPollDetails'] = true
      }

      this.setState({ posts })
    }

    renderTaggedItem(item, type, answer) {
      console.log('renderTaggedItem called', item, type, answer)

      let defaultProfileItemIcon = projectsIconDark
      if (type === 'project') {
        defaultProfileItemIcon = projectsIconDark
      } else if (type === 'career') {
        defaultProfileItemIcon = careerMatchesIconDark
      } else if (type === 'competency') {
        defaultProfileItemIcon = educationIcon
      } else if (type === 'work') {
        defaultProfileItemIcon = opportunitiesIconDark
      }

      let itemObject = item.aItem
      if (answer === 'b') {
        itemObject = item.bItem
      }

      if (type === 'project') {

        return (
          <div key="taggedProjectItem">
            <Link to={'/app/projects/' + itemObject._id} className="background-button standard-color full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <div>
                    {(item.aValue) && (
                      <p className="bold-text right-text cta-color">${item.aValue}</p>
                    )}
                  </div>
                ) : (
                  <div>
                    {(item.bValue) && (
                      <p className="bold-text right-text cta-color">${item.bValue}</p>
                    )}
                  </div>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="bottom-padding">
                <div className="cta-border">
                  <Link to={'/app/projects/' + itemObject._id} className="background-button standard-color padding-20 full-width">
                    <div className="padding-20">
                      <div className="fixed-column-60">
                        <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                      </div>
                      <div className="calc-column-offset-60">
                        <p>{itemObject.name}</p>
                        <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                      </div>
                      <div className="clear" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (type === 'work') {
        return (
          <div key="taggedWorkItem">
            <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-50">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-40-fit" />
                    </div>
                    <div className="calc-column-offset-50">
                      {(itemObject.title) ? (
                        <p>{itemObject.title}</p>
                      ) : (
                        <p>{itemObject.name}</p>
                      )}

                      {(itemObject.employerName) && (
                        <p className="description-text-3 description-text-color">{itemObject.employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'career') {
        return (
          <div key="taggedCareerItem">
            <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.jobFamily}</label>

                      {(itemObject.marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(itemObject.marketData.pay).toLocaleString()} avg pay</label>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'competency') {
        return (
          <div key="taggedCompetencyItem">
            <div className="bottom-padding">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </div>

            <div className="bottom-padding">
              <div className="cta-border">
                <div className="standard-color padding-20 full-width">
                  <div>
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.category}</label>

                      {(itemObject.description) && (
                        <div>
                          <div className="clear" />
                          <label className="description-text-3 description-text-color">{itemObject.description}</label>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      }
    }

    passPosts(posts) {
      console.log('passPosts called')
      this.setState({ posts })
    }

    deletePost() {
      console.log('deletePost called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })
      const _id = this.state.posts[this.state.selectedIndex]._id

      Axios.delete('/api/group-posts/' + _id)
      .then((response) => {
        console.log('tried to delete the post', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked');

          let posts = this.state.posts
          posts.splice(this.state.selectedIndex, 1)
          this.setState({ posts, successMessage: 'Post was successfully deleted!', modalIsOpen: false })

        } else {
          console.error('there was an error deleting the course');
          this.setState({ errorMessage: 'There was an error deleting the post'})
        }
      }).catch((error) => {
        console.log('The deleting did not work', error);
        this.setState({ errorMessage: 'There was an error deleting the post: ' + error})
      });
    }

    commandClicked(e,command) {
      console.log('commandClicked called')

      e.stopPropagation()
      e.preventDefault()

      this.props.commandClicked(command)

    }

    render() {

      return (
        <div>
          {(this.state.posts) && (
            <div>
              {(!window.location.pathname.includes('/app') && !window.location.pathname.includes('/advisor') && !window.location.pathname.includes('/organizations/') && !window.location.pathname.includes('/employers/')) ? (
                <div key={"postRender"}>
                  <p className="error-color">Please sign in to the portal to view these posts.</p>
                </div>
              ) :(
                <div>
                  {this.state.posts.map((value, index) =>
                    <div key={"socialPost|" + index}>
                      {(this.props.pageSource === 'externalProfile') ? (
                        <div>
                          <div className="relative-column-33">
                            {(this.props.limit && (this.props.limit < (index + 1))) ? (
                              <div />
                            ) : (
                              <div>
                                {this.renderPost(value, index)}
                              </div>
                            )}
                          </div>
                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          {(this.state.mostActiveGoal && index === 1 && this.state.showCompassAssistant) && (
                            <div className="top-margin-20">
                              <div>
                                <img src={targetIconOrange} className="image-auto-50 center-horizontally bottom-margin-negative-35 relative-position z-index-1 padding-10 error-border circle-radius white-background" alt="GC" />
                              </div>

                              <div className="elevated-box white-background" >

                                <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

                                <div className="horizontal-padding">

                                  <p className="heading-text-5 full-width center-text">Ask Compass Assistant About Your Goal</p>

                                  <button className="background-button full-width error-border padding-20 top-margin-20" onClick={(window.location.pathname.startsWith('/app')) ? () => this.props.navigate('/app/logs/' + this.state.mostActiveGoal._id) : () => this.props.navigate('/advisor/edit-logs/' + this.state.mostActiveGoal._id, { state: { editExisting: true, log: value }})}>
                                    <p className="full-width center-text">{this.state.mostActiveGoal.title}</p>

                                    {(this.state.mostActiveGoal.goalType) && (
                                      <div className="top-margin-5">
                                        <p className="description-text-3 full-width center-text">[{this.state.mostActiveGoal.goalType.description}]</p>
                                      </div>
                                    )}

                                    {(this.state.mostActiveGoal.description) && (
                                      <p className="description-text-3 description-text-color top-margin full-width center-text">{this.state.mostActiveGoal.description}</p>
                                    )}
                                  </button>

                                  <div className="spacer" /><div className="spacer" />

                                  <hr />

                                  {(this.state.goalCommands) && (
                                    <div className="top-margin-15 left-text">
                                      {this.state.goalCommands.map((value2, index2) =>
                                        <div key={"goalCommand|" + index2}>
                                          <div className="calc-column-offset-70">
                                            <p className="description-text-2">{value2}</p>
                                          </div>
                                          <div className="fixed-column-70 left-padding">
                                            <button className="btn btn-squarish" onClick={(e) => this.commandClicked(e, value2)}>
                                              Ask
                                            </button>
                                          </div>
                                          <div className="clear" />

                                          <div className="row-15">
                                            <hr />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {/*}
                                  <div className="top-padding-20">
                                    {(this.state.mostActiveGoal.startDate) ? (
                                      <p className="description-text-3 full-width center-text">{convertDateToString(new Date(this.state.mostActiveGoal.startDate),"date-2")} - {convertDateToString(new Date(this.state.mostActiveGoal.deadline),"date-2")}</p>
                                    ) : (
                                      <p className="description-text-3 full-width center-text">Deadline: {convertDateToString(new Date(this.state.mostActiveGoal.deadline),"date-2")}</p>
                                    )}
                                  </div>

                                  <div className="top-padding">
                                    {(this.state.mostActiveGoal.goalType && this.state.mostActiveGoal.goalType.name === 'Alternatives') && (
                                      <div>
                                        {(this.state.mostActiveGoal.pollQuestion) && (
                                          <p className="heading-text-5 top-margin-20">{this.state.mostActiveGoal.pollQuestion}</p>
                                        )}
                                        <div className="top-margin-20">
                                          <div className="calc-column-offset-30-of-50">
                                            <p className="">{this.state.mostActiveGoal.aName}</p>
                                          </div>
                                          <div className="fixed-column-60">
                                            <p className="full-width center-text heading-text-4">VS</p>
                                          </div>
                                          <div className="calc-column-offset-30-of-50">
                                            <p className="full-width right-text">{this.state.mostActiveGoal.bName}</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}
                                  </div>*/}

                                  <div className="clear" />

                                  <div className="spacer" />
                                </div>
                              </div>
                            </div>
                          )}

                          {(this.state.activeOrg === 'defaultsandbox' && (window.location.pathname.startsWith('/advisor') && this.state.roleName !== 'Mentor')) && (
                            <div className="card top-margin flex-container flex-center">
                              <div>
                                <img src={infoIconDark} alt="GC" className="image-auto-40 center-item" />
                                <p className="center-text top-margin-20 heading-text-6">This is a Sandbox Account</p>
                                <p className="center-text top-margin description-text-1">To create a customized, dedicated community, email <label className="cta-color">help@guidedcompass.com</label>. A dedicated community includes portals for career-seekers, administrators, parents, employers, and mentors.</p>

                                <p className="center-text row-20 description-text-1">Pricing is dependent on the number of members in the community.</p>
                              </div>
                            </div>
                          )}

                          {this.renderPost(value, index)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >
               <div key="showShareButtons" className="full-width">

                 {(this.state.showPost || this.state.sharePosting) && (
                   <div key="showPost" className="full-width padding-20">
                      <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost} posts={this.state.posts} passPosts={this.passPosts} closeModal={this.closeModal} />
                    </div>
                 )}

                  {(this.state.showComments) && (
                    <div key="showPost" className="full-width padding-20">
                      {this.renderPost(this.state.posts[this.state.selectedIndex], this.state.selectedIndex, true)}

                      <div className="spacer" />

                      {(this.state.posts && this.state.activeOrg) && (
                        <SubComments selectedGroup={null} selectedGroupPost={this.state.posts[this.state.selectedIndex]} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} orgLogo={this.state.orgLogo} history={this.props.navigate} pageSource={"newsFeed"} employerLogo={this.props.employerLogo} employerName={this.props.employerName} jobTitle={this.props.jobTitle} />
                      )}
                    </div>
                  )}

                  {(this.state.passedGroupPost) && (
                    <div key="passedGroupPost" className="full-width">

                     {this.renderPost(this.state.passedGroupPost, null, true, true)}

                     <div className="spacer" />

                     {(this.state.passedGroupPost && this.state.activeOrg) && (
                       <SubComments selectedGroup={null} selectedGroupPost={this.state.passedGroupPost} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} history={this.props.navigate} pageSource={"newsFeed"} employerLogo={this.props.employerLogo} employerName={this.props.employerName} jobTitle={this.props.jobTitle} />
                     )}

                    </div>
                  )}

                  {(this.state.showShareButtons) && (
                     <div className="full-width padding-20 center-text">
                       <p className="heading-text-2">Share This Post with Friends!</p>

                       <div className="top-padding-20">
                         <p>Share this link:</p>
                         <label className="bold-text cta-color">{window.location.protocol + "//" + window.location.host + "/app/social-posts/" + this.state.posts[this.state.selectedIndex]._id}</label>
                       </div>

                       <div className="spacer" />

                       <div className="top-padding-20">
                         {this.renderShareButtons()}
                       </div>
                     </div>
                  )}

                  {(this.state.adjustFeedPreferences) && (
                    <div key="adjustFeedPreferences" className="full-width padding-20">
                       <p className="heading-text-4">Don't want to see this</p>
                       <div className="spacer" />

                       <div className="row-10 description-text-2">
                         <p>Tell us why you don't want to see this</p>
                         <p className="description-text-2 description-text-color">Your feedback will help us improve your experience</p>
                       </div>

                       <div className="spacer" />

                       {this.state.adjustFeedPreferenceOptions.map((item2, index2) =>
                         <div key={index2} className="row-5">
                           <div className="fixed-column-40">
                             {(this.state.selectedPreferences && this.state.selectedPreferences.includes(item2)) ? (
                               <button className="background-button" onClick={() => this.itemClicked(item2,'adjustFeedPreferences') }>
                                 <img src={checkboxChecked} alt="GC" className="image-auto-18" />
                               </button>
                             ) : (
                               <button className="background-button" onClick={() => this.itemClicked(item2,'adjustFeedPreferences')}>
                                 <img src={checkboxEmpty} alt="GC" className="image-auto-18" />
                               </button>
                             )}
                           </div>
                           <div className="calc-column-offset-40">
                             <p className="description-text-2">{item2}</p>
                           </div>
                           <div className="clear" />
                         </div>
                       )}

                       <div className="spacer" />

                       <div className="row-10 description-text-2">
                         <p>If you think this post goes against our Professional Community Policies, please report this post.</p>
                       </div>

                       <div className="spacer" />

                       <button className="btn btn-squarish right-margin" disabled={(this.state.isSaving) ? true : false} onClick={() => this.submitReport('preference')}>Submit</button>
                       <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>Cancel</button>
                     </div>
                  )}

                  {(this.state.reportPostView) && (
                    <div key="reportPostView" className="full-width padding-20">
                       <p className="heading-text-4">Report</p>
                       <div className="spacer" />

                       <div className="row-10 description-text-2">
                         <p>Why are you reporting this?</p>
                         <p className="description-text-2 description-text-color">Your feedback will help us improve your experience</p>
                       </div>

                       <div className="spacer" />

                       {this.state.reportOptions.map((item2, index2) =>
                         <div key={index2} className="row-5">
                           <div className="fixed-column-40">
                             {(this.state.selectedReportReasons && this.state.selectedReportReasons.includes(item2)) ? (
                               <button className="background-button" onClick={() => this.itemClicked(item2,'report') }>
                                 <img src={checkboxChecked} alt="GC" className="image-auto-18" />
                               </button>
                             ) : (
                               <button className="background-button" onClick={() => this.itemClicked(item2,'report')}>
                                 <img src={checkboxEmpty} alt="GC" className="image-auto-18" />
                               </button>
                             )}
                           </div>
                           <div className="calc-column-offset-40">
                             <p className="description-text-2">{item2}</p>
                           </div>
                           <div className="clear" />
                         </div>
                       )}

                       <div className="spacer" /><div className="spacer" />

                       <button className="btn btn-squarish right-margin" disabled={(this.state.isSaving) ? true : false} onClick={() => this.submitReport('report')}>Submit</button>
                       <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>Cancel</button>
                     </div>
                  )}

                  {(this.state.showDeletePost) && (
                    <div key="deletePost" className="full-width padding-20">
                      <p className="heading-text-4">Are you sure you want to delete this post?</p>
                      <div className="spacer" />

                      <div className="spacer" /><div className="spacer" />

                      <button className="btn btn-squarish error-background-color clear-border right-margin" disabled={(this.state.isSaving) ? true : false} onClick={() => this.deletePost()}>Delete</button>
                      <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>Cancel</button>
                    </div>
                  )}

                  {(this.state.showReports) && (
                    <div key="reports" className="full-width padding-20">
                      <p className="heading-text-4">Reports on this post</p>
                      <div className="spacer" /><div className="spacer" />

                      <p className="description-text-color description-text-2">There has not been any reports on this post</p>

                      <div className="spacer" /><div className="spacer" />

                      <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>
                        <div>
                          <div className="float-left top-padding-5"><img className="image-auto-11" alt="img" src={closeIcon} /></div>
                          <div className="float-left left-padding">Close View</div>
                          <div className="clear" />
                        </div>
                      </button>
                    </div>
                  )}

                  {(this.state.showUpvotes) && (
                    <div key="showPost" className="full-width">
                      <div className="bottom-padding">
                        <p className="heading-text-2">Post Upvotes</p>
                      </div>

                     <div className="spacer" />

                     {(this.state.upvotes && this.state.upvotes.length > 0) ? (
                       <div className="top-padding">
                         {this.state.upvotes.map((value, optionIndex) =>
                           <div key={"upvote|" + optionIndex}>
                             <div>
                               <div className="fixed-column-60">
                                 <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-2" />
                               </div>
                               <div className="calc-column-offset-60 left-padding top-padding-5">
                                 <p className="heading-text-4">{value.firstName} {value.lastName}</p>
                               </div>
                               <div className="clear" />
                             </div>
                           </div>
                         )}
                       </div>
                     ) : (
                       <div>
                         <p className="error-color">There are no upvotes</p>
                       </div>
                     )}

                    </div>
                  )}

                  {(this.state.showReports) && (
                    <div key="reports" className="full-width padding-20">
                      <p className="heading-text-4">Reports on this post</p>
                      <div className="spacer" /><div className="spacer" />

                      <p className="description-text-color description-text-2">There has not been any reports on this post</p>

                      <div className="spacer" /><div className="spacer" />

                      <button className="btn btn-squarish white-background cta-color" onClick={() => this.closeModal()}>
                        <div>
                          <div className="float-left top-padding-5"><img className="image-auto-11" alt="img" src={closeIcon} /></div>
                          <div className="float-left left-padding">Close View</div>
                          <div className="clear" />
                        </div>
                      </button>
                    </div>
                  )}

                </div>

             </Modal>
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(RenderPosts);
