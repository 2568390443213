import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';

class RenderBusinessPlan extends Component {
    constructor(props) {
      super(props)

      this.state = {
        headerMargin: 10,
        maxDocHeight: 1100,
        docPadding: 40,
        sectionTopMargin: 50,
        sectionSpacer: 40,
        sectionTitleMargin: 10
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.calculatePages = this.calculatePages.bind(this)
      this.testSectionBreak = this.testSectionBreak.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.calculatePages()
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderReferrals', this.props, prevProps)

      if (this.props.businessPlanContent !== prevProps.businessPlanContent) {
        this.calculatePages()
      }
    }

    retrieveData() {
      console.log('retrieveData called in RenderReferrals')

      // this.setState({ activeOrg, favorites, emailId, orgName, orgContactEmail })

    }

    calculatePages() {
      console.log('calculatePages called', this.props.businessPlanContent)

      const maxDocHeight = this.state.maxDocHeight
      let pageCount = 1
      let sectionBreaks = []

      let contentHeight = 0
      contentHeight = contentHeight + this.state.docPadding // padding-40

      if (document.getElementById('business-plan-header')) {
        // console.log('summary long text', document.getElementById('long-text').clientHeight)
        // console.log('content height header: ', document.getElementById('business-plan-header').clientHeight)
        contentHeight = contentHeight + this.state.headerMargin

        contentHeight = contentHeight + document.getElementById('business-plan-header').clientHeight
      }

      contentHeight = contentHeight + this.state.sectionTopMargin // margin-top-50

      let sectionCount = 0
      if (this.props.businessPlanContent && this.props.businessPlanContent.sections) {
        sectionCount = this.props.businessPlanContent.sections.length
      }

      console.log('content height before sections: ', contentHeight, sectionCount)

      for (let i = 1; i <= sectionCount; i++) {
        console.log('hmmm?')
        if (document.getElementById('business-plan-section-body-' + i.toString())) {
          console.log('content height section text: ', i, document.getElementById('business-plan-section-title-' + i.toString()).clientHeight)
          console.log('content height section body: ', i, document.getElementById('business-plan-section-body-' + i.toString()).clientHeight)

          contentHeight = contentHeight + document.getElementById("business-plan-section-title-" + i.toString()).clientHeight

          contentHeight = contentHeight + this.state.sectionTitleMargin // margin-bottom-5

          contentHeight = contentHeight + document.getElementById("business-plan-section-body-" + i.toString()).clientHeight

          // console.log('summary long text', document.getElementById('long-text').clientHeight)
          console.log('content height at section ', i, contentHeight)
          // contentHeight = contentHeight + 30 // margin-bottom-30
          contentHeight = contentHeight + this.state.sectionSpacer // margin-bottom-30

          if (contentHeight > (pageCount * maxDocHeight)) {
            pageCount = pageCount + 1
            sectionBreaks.push(i)
          }
        }
      }

      contentHeight = contentHeight + this.state.docPadding // padding-40

      console.log('show total content height: ', contentHeight, pageCount, sectionBreaks)

      // re-distribute as pages


      this.setState({ pageCount, sectionBreaks, contentHeight })

      this.props.passData({ pageCount })
      // 1030 or 1263
    }

    testSectionBreak(index, page) {
      console.log('testSectionBreak called', index, page, this.state.sectionBreaks)

      let renderSection = true
      const sectionCount = index + 1

      if (!this.state.sectionBreaks) {
        renderSection = true
      } else {
        if (this.state.sectionBreaks[page - 2]) {
          if (this.state.sectionBreaks[page - 2] > (sectionCount)) {
            renderSection = false
          }
        }
        if (this.state.sectionBreaks[page - 1]) {
          if (this.state.sectionBreaks[page - 1] < sectionCount + 1) {
            renderSection = false
          }
        }

        // for (let i = 1; i <= this.state.sectionBreaks; i++) {
        //   if (this.state.sectionBreaks[page - 1] < index) {
        //     renderSection = false
        //   }
        // }
      }

      return renderSection
    }

    render() {

      const showIdealCandidate = this.props.showIdealCandidate
      const layoutSelected = this.props.layoutSelected
      const sideSectionClass = this.props.sideSectionClass
      const profilePicImage = this.props.profilePicImage
      const profilePicPath = this.props.profilePicPath
      const businessPlanContent = this.props.businessPlanContent
      const pageCount = this.props.pageCount
      const selectedSections = this.props.selectedSections
      const sectionHeaderDivClass = this.props.sectionHeaderDivClass
      const sectionHeaderTextClass = this.props.sectionHeaderTextClass
      const mainSectionClass = this.props.mainSectionClass
      const headerClass = this.props.headerClass
      const projectsHeight = this.props.projectsHeight
      const skillsHeight = this.props.skillsHeight
      const knowledgeHeight = this.props.knowledgeHeight
      const extrasHeight = this.props.extrasHeight

      const disableEditing = this.props.disableEditing
      const hideIfEmpty = this.props.hideIfEmpty

      return (
        <div className="description-text-1">
          <div className={"paper-container"}>
            <div id="printPage1" className={"full-space max-text-lines padding-" + this.state.docPadding}>
              <div className={mainSectionClass}>
                <div className={headerClass} id="business-plan-header">
                  <div className={"top-margin-" + this.state.headerMargin}>
                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers" : "background-button full-width"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editTitleSection: true })}>
                      <p className="heading-text-2">{businessPlanContent.title}</p>
                      {(businessPlanContent.subtitle) && (
                        <p className="heading-text-5 top-margin italicize-text">"{businessPlanContent.subtitle}"</p>
                      )}
                      <p className="top-margin description-text-1 description-text-color">Business Plan by {businessPlanContent.creatorFirstName} {businessPlanContent.creatorLastName}</p>
                    </button>
                  </div>
                </div>

                {(businessPlanContent.sections) && (
                  <div className={"top-margin-" + this.state.sectionTopMargin}>
                    {businessPlanContent.sections.map((value, optionIndex) =>
                      <div key={"businessPlanSection" + optionIndex.toString()}>
                        {(this.testSectionBreak(optionIndex,1)) && (
                          <div className={"left-text full-width bottom-margin-" + this.state.sectionSpacer}>
                            <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSection: true, selectedSectionIndex: optionIndex })}>
                              <p className={"heading-text-4 bottom-margin-" + this.state.sectionTitleMargin} id={"business-plan-section-title-" + (optionIndex + 1).toString()}>{value.title}</p>
                              <p className={(value.body === value.placeholder) ? "description-text-color" : ""} id={"business-plan-section-body-" + (optionIndex + 1).toString()}>{value.body}</p>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {/*
                <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editTextBody: true })}>
                  <p className={(businessPlanContent.textBody === "Write the body of the business plan here...") ? "description-text-color" : ""}>{businessPlanContent.textBody}</p>
                </button>*/}
              </div>
              <div className="clear" />
            </div>
          </div>

          {(this.state.pageCount > 1 && this.state.sectionBreaks) && (
            <div>
              {this.state.sectionBreaks.map((value, optionIndex) =>
                <div key={"sectionBreak" + (optionIndex + 2).toString()}>
                  <div className="paper-container">
                    <div id={"printPage" + (optionIndex + 2).toString()} className="full-space padding-40 max-text-lines">
                      <div className={mainSectionClass}>
                        {(businessPlanContent.sections) && (
                          <div className="top-margin-50">
                            {businessPlanContent.sections.map((value2, optionIndex2) =>
                              <div key={"businessPlanSection" + optionIndex2.toString()}>
                                {(this.testSectionBreak(optionIndex2,(optionIndex + 2).toString())) && (
                                  <div className={"left-text full-width bottom-margin-" + this.state.sectionSpacer}>
                                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSection: true, selectedSectionIndex: optionIndex2 })}>
                                      <p className="heading-text-4 bottom-margin-5" id={"business-plan-section-title-" + (optionIndex2 + 1).toString()}>{value2.title}</p>
                                      <p className={(value2.body === value2.placeholder) ? "description-text-color" : ""} id={"business-plan-section-body-" + (optionIndex2 + 1).toString()}>{value2.body}</p>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="clear" />
        </div>
      )
    }
}

export default withRouter(RenderBusinessPlan);
