import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
// import io from 'socket.io-client';
// import * as io from 'socket.io-client'
// import socketio from "socket.io-client";
// const SocketContext = React.createContext()
// import * as io from 'socket.io-client';
// import { io } from 'socket.io-client';

import Switch from 'react-switch';
import SubSpeechToText from '../Vendors/SpeechToText';
import SubTextToSpeech from '../Vendors/TextToSpeech';
// import { SubTextToSpeech } from '../Vendors/TextToSpeech';
import SubAvatar from '../Common/Avatar';
import SubRenderChat from '../Common/RenderChat';

import { socket } from '../Vendors/Socket';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const questionMarkWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-white.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
// const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-dark.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const closeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon-white.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const backButtonWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/back-button-white.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const chatbubblesIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbubbles-icon.png';
const chatbubblesIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbubbles-icon-white.png';
const linkIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-dark.png';
const reloadIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reload-icon-blue.png';
const reloadIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reload-icon-white.png';
const industryIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon.png';
const coverLetterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const activelyMessagingGif = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/actively_messaging_gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const callWaitingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/call-waiting-gif.gif';

// const socket = io("http://localhost:4000");
// const socket = socketio.connect('http://localhost:4000');
// const socket = io.connect('http://localhost:443');
// let socketURL = 'https://guidedcompass.com'
// if (window.location.hostname === 'localhost') {
//   socketURL = 'http://localhost:443'
// }
// const socketURL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';
// const socketURL = window.location.hostname === 'localhost' ? 'http://localhost:443' : 'https://guidedcompass.com';

// const socket = io.connect(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });
// const socket = io(socketURL, { transports: ['websocket', 'polling', 'flashsocket'] });

class HelpPrompt extends Component {
    constructor(props) {
      super(props)
      this.state = {
        useSubRenderChat: true,
        showHumanChatInAdmin: false,

        showGradePromptSpecifics: false,
        showVoiceButton: false,
        testSpeechKit: true,
        showBot: false,
        useRealTimeChat: true,

        disableHumanChatForUnderaged: true,
        includeBotAsOption: true,

        resLimit: 5000,
        recent: true,

        questionIndex: 0,
        unreadMessageCount: 1,

        helpOptions: [],
        urgencyOptions: ['','Low','Medium','High'],
        recipient: {},
        prefix: '',
        transform: 'translate(150%)',
        opacity: '0',

        documentTypeOptions: ['','Resume','Cover Letter','Career Plan','Financial Plan','Business Plan'],
        sectionOptions: ['','Entire Document','Part of the Document'],
        importDocTypeOptions: ['','Cover Letter','Career Plan','Business Plan'],
        docOptions: [],
        profileItemTypeOptions: [],
        adminRoleNames: ['Admin','Teacher','Counselor','School Support','Work-Based Learning Coordinator'],

        includeSkills: true,
        includeEducation: true,
        includeExperience: true,
        includeAssessments: true,
        includeEndorsements: true,
        includeFeedback: false,
        includeSections: true,
        includeGoals: true,

      }

      this.retrieveData = this.retrieveData.bind(this)

      this.openModal = this.openModal.bind(this)
      this.closeModal = this.closeModal.bind(this)

      this.buttonClicked = this.buttonClicked.bind(this)
      this.submitTicket = this.submitTicket.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.renderMessage = this.renderMessage.bind(this)
      this.sendMessage = this.sendMessage.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.resetWelcomeMessage = this.resetWelcomeMessage.bind(this)
      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.addItem = this.addItem.bind(this)
      // this.updateUI = this.updateUI.bind(this)
      this.pullMessages = this.pullMessages.bind(this)
      this.selectInbox = this.selectInbox.bind(this)
      this.clearUnreadMessages = this.clearUnreadMessages.bind(this)
      this.passCommand = this.passCommand.bind(this)
      // this.renderConversation = this.renderConversation.bind(this)
      // this.openAssistant = this.openAssistant.bind(this)
      this.passData = this.passData.bind(this)

      this.craftPrompt = this.craftPrompt.bind(this)
      // this.renderChatBox = this.renderChatBox.bind(this)
      this.renderSelectedResponse = this.renderSelectedResponse.bind(this)
      // this.toggleMessageMenu = this.toggleMessageMenu.bind(this)
      // this.renderMessageMenu = this.renderMessageMenu.bind(this)
      // this.importToProfile = this.importToProfile.bind(this)
      // this.toggleDocumentsWidget = this.toggleDocumentsWidget.bind(this)
      this.goBack = this.goBack.bind(this)
      this.renderImportWidget = this.renderImportWidget.bind(this)
      this.renderHelpWidget = this.renderHelpWidget.bind(this)
      this.pullProfileItems = this.pullProfileItems.bind(this)
      this.importToMessage = this.importToMessage.bind(this)
      this.commandClicked = this.commandClicked.bind(this)
      this.passData = this.passData.bind(this)
      this.doublePassData = this.doublePassData.bind(this)
      // this.child = React.createRef();
      // const childRef = useRef()
      this.child = React.createRef()
      // this.child2 = React.createRef()
    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

      if (!this.state.useSubRenderChat) {
        // socket.on('receive_message', (data) => {
        //   console.log('received message from socket server: ', data);
        //
        //   if (data.room === this.state.room) {
        //     this.updateUI(data)
        //   }
        // });
        // socket.on('error_sending_message', (data) => {
        //   console.log('error sending message: ', data);
        //
        //   this.setState({ errorMessage: data.message, isSaving: false })
        //
        // });
      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in help prompt', this.props, prevProps)

      if (this.props.orgContactEmail !== prevProps.orgContactEmail || this.props.orgLogo !== prevProps.orgLogo) {
        console.log('a1')
        this.retrieveData()
      } else if (this.props.passedCommand !== prevProps.passedCommand) {
        console.log('a2')
        this.passCommand()
      } else if (this.props.openUpAssistant !== prevProps.openUpAssistant) {
        console.log('a3')
        this.openModal('showChatBot', null, true)
      } else if (this.props.chatOnly !== prevProps.chatOnly) {
        console.log('a4')
        this.passCommand()
      } else if (this.props.modalIsOpen === true && (!this.state.modalIsOpen || this.state.modalIsOpen === false) && this.props.clickerToggler !== prevProps.clickerToggler) {
        console.log('a6')
        this.openModal('showChatBot', null, true, true, this.props.showGradePrompt)

      }

      if (this.state.messages && this.state.messages.length > 0 && this.state.messageCount !== this.state.messages.length) {
        this.setState({ messageCount: this.state.messages.length })
        this.scrollToBottom();
      }
    }

    retrieveData() {
      console.log('retrieveData called within sub help prompt', this.props)

      let loggedIn = true

      let emailId = localStorage.getItem('email');
      if (!emailId) {
        emailId = "yourname@placeholder.com"
        loggedIn = false
      }
      let cuFirstName = localStorage.getItem('firstName');
      if (!cuFirstName) {
        cuFirstName = "Your"
      }
      let cuLastName = localStorage.getItem('lastName');
      if (!cuLastName) {
        cuLastName = "Name"
      }
      let username = localStorage.getItem('username');
      if (!username) {
        username = "yourname"
      }
      let pictureURL = localStorage.getItem('pictureURL');
      if (pictureURL === 'undefined') {
        pictureURL = null
      }

      const orgFocus = localStorage.getItem('orgFocus');

      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      } else if (!activeOrg) {
        activeOrg = 'guidedcompass'
      }

      const accountCode = localStorage.getItem('accountCode');
      let orgName = localStorage.getItem('orgName');
      // console.log('------L ', orgName)
      let orgContactEmail = localStorage.getItem('orgContactEmail');
      let orgChatEmail = localStorage.getItem('orgChatEmail');

      let sessionUserId = localStorage.getItem('sessionUserId');
      if (!sessionUserId) {
        sessionUserId = Date.now()
        localStorage.setItem('sessionUserId', sessionUserId)
      }

      let promptWelcomeMessage = true
      if (loggedIn && !window.location.pathname.includes('/organizations')) {
        promptWelcomeMessage = localStorage.getItem('promptWelcomeMessage')
        if (promptWelcomeMessage) {
          localStorage.removeItem('promptWelcomeMessage')
        }
      }

      if (window.location.pathname.startsWith('/organizations') && !this.state.showHumanChatInAdmin) {
        promptWelcomeMessage = false
      }

      // console.log('show sessionUserId: ', sessionUserId, emailId)

      const roleName = localStorage.getItem('roleName');
      const remoteAuth = localStorage.getItem('remoteAuth');

      let assistantChatCount = localStorage.getItem('assistantChatCount');
      if (!assistantChatCount || assistantChatCount === 'undefined') {
        assistantChatCount = 0
      }
      // console.log('show assistantChatCount: ', assistantChatCount)
      // localStorage.setItem('assistantChatCount', 0)

      let assistantChatLimit = localStorage.getItem('assistantChatLimit');
      if (!assistantChatLimit || assistantChatLimit === 10 || assistantChatLimit === 15 || assistantChatLimit === 50) {
        assistantChatLimit = 100
      }

      // let chatDirectly = false
      let disableHumanChat = true
      let disableFreeformChatbotMessaging = null
      // if (remoteAuth || roleName === 'Teacher') {
      if (remoteAuth && (roleName === 'Student' || roleName === 'Career-Seeker')) {
        // chatDirectly = true
        // disableHumanChat = true
        disableFreeformChatbotMessaging = true
      }

      let chatText = 'Chat with Guided Compass Staff'
      // if (orgName && !chatDirectly) {
      if (orgName) {
        chatText = 'Chat with ' + orgName + ' Staff'
      }
      // console.log('show me the orgName: ', orgName)
      let helpOptions = [
        { name: chatText, shortname: 'chat', showname: 'showChat', icon: chatbubblesIcon},
        // { name: 'Schedule Office Hours', shortname: 'officeHours', icon: eventIconDark},
      ]

      if (this.state.showBot || window.location.pathname === '/chatgpt') {
        helpOptions.unshift({ name: 'Chat with GC A.I. Assistant', shortname: 'chatbot', showname: 'showChatBot', icon: chatbubblesIcon})
      }

      if (loggedIn) {
        helpOptions.push({ name: 'Submit a Question, Bug, or Issue', shortname: 'bug', showname: 'showTicketWindow', icon: sendIconDark })
      }

      if (loggedIn) {
        helpOptions.push({ name: 'View Help Articles and Videos', shortname: 'help', icon: courseIconDark, url: '/help' })
      }

      let lastUnreadMessage = 'Hello and welcome to Guided Compass! What can I help you with today?'
      // if (orgName && !chatDirectly) {
      if (orgName) {
        lastUnreadMessage = 'Hello and welcome to the ' + orgName + ' portal! What can I help you with today?'
      }
      if (window.location.pathname.startsWith('/organizations') && this.state.showHumanChatInAdmin) {
        lastUnreadMessage = null
      }

      let senderFirstName = 'Guided Compass'
      // if (orgName && !chatDirectly) {
      if (orgName) {
        senderFirstName = orgName
      }

      let senderLastName = ''
      let senderEmail = 'creightontaylor@gmail.com'
      // if (orgChatEmail && !chatDirectly) {
      if (orgChatEmail) {
        senderEmail = orgChatEmail
      }

      let senderUsername = 'guidedcompass'
      if (activeOrg) {
        senderUsername = activeOrg
      } else if (senderFirstName) {
        senderUsername = (senderFirstName + senderLastName).toLowerCase().replace(/ /g,"")
      }

      let senderRoleName = 'Admin'

      let orgLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
      let senderPictureURL = orgLogo
      // if (this.props.orgLogo && !chatDirectly) {
      if (this.props.orgLogo) {
        orgLogo = this.props.orgLogo
        senderPictureURL = this.props.orgLogo
        // console.log('91##', orgLogo, chatDirectly)
      }
      // console.log('9119', orgLogo, chatDirectly, this.props.orgLogo)

      let recipient = { firstName: senderFirstName, lastName: senderLastName, email: senderEmail, username: senderUsername, pictureURL: senderPictureURL, roleName: senderRoleName }
      if ((window.location.pathname.startsWith('/organizations') && !this.state.showHumanChatInAdmin) || this.props.chatOnly) {
        recipient['firstName'] = "Compass"
        recipient['lastName'] = " Assistant"
        recipient['email'] = 'creightontaylor+40@gmail.com'
        recipient['username'] = 'compass'
        recipient['pictureURL'] = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
        recipient['roleName'] = 'Admin'
      }

      let messages = [{
        emailId: recipient.email, message: lastUnreadMessage,
        senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
        senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
        senderHeadline: recipient.headline, senderUsername: recipient.username,
        senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
        senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
        orgCode: activeOrg, accountCode: null,
        recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
        createdAt: new Date(), updatedAt: new Date()
      }]
      if (this.props.chatOnly) {
        messages = []
      }

      let profileItemTypeOptions = ['','Career Assessment','Certificate','Demographics','Document','Education','Endorsement','Experience','Extracurricular','Favorite','Goal','Notes','Passion','Project']
      // for (let i = 1; i <= profileItemTypeOptions.length; i++) {
      //   if (profileItemTypeOptions[i - 1]) {
      //     profileItemTypeOptions[i - 1] = (i - 1).toString() + '. ' + profileItemTypeOptions[i - 1]
      //   }
      // }

      let selectedMember = null
      if (!this.state.useSubRenderChat) {
        selectedMember = { email: emailId, firstName: cuFirstName, lastName: cuLastName, username, pictureURL }
      }

      this.setState({ emailId, cuFirstName, cuLastName, orgFocus, roleName, activeOrg, loggedIn,
        orgName, orgContactEmail, orgChatEmail, orgLogo,
        accountCode, username, pictureURL, selectedMember,
        sessionUserId, helpOptions,
        lastUnreadMessage, messages, recipient, promptWelcomeMessage,
        assistantChatCount, assistantChatLimit, profileItemTypeOptions
      })

      const self = this
      function officiallyTranslate() {
        console.log('officiallyTranslate called')
        self.setState({ transform: 'translate(0%)', opacity: 1 })
      }

      const delayTranslate = () => {
        console.log('delayTranslate called')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyTranslate, 1000)
      }

      let customAssessmentId = null
      if ((!window.location.pathname.startsWith('/organizations') || (window.location.pathname.startsWith('/organizations') && !this.state.showHumanChatInAdmin)) && !window.location.pathname.startsWith('/chatgpt')) {
        if (loggedIn) {
          customAssessmentId = '63d84cf7b525424f14c10be0'
        } else {
          customAssessmentId = '63d5d31cc4bc4511077d5603'
        }
      }

      function pullCustomAssessment(passedId, passedDisableHumanChat, passedDisableFreeFormChatbotMessaging) {
        console.log('pullCustomAssessment called', passedId, passedDisableHumanChat)

        if (!self.props.chatOnly) {
          Axios.get('/api/customassessments/byid', { params: { _id: passedId } })
          .then((response) => {
            console.log('Custom assessment query attempted', response.data);

            if (response.data.success) {
              console.log('custom assessment query worked', self)

              if (response.data.assessment && response.data.assessment.questions) {
                let customAssessment = response.data.assessment
                if (self.state.includeBotAsOption && customAssessment.questions[0] && customAssessment.questions[0].answerChoices) {
                  let answerChoices = customAssessment.questions[0].answerChoices
                  if ((!passedDisableFreeFormChatbotMessaging) || (passedDisableFreeFormChatbotMessaging && window.location.pathname.startsWith('/organizations'))) {
                    answerChoices.unshift("Chat with A.I. Assistant")
                  }
                  customAssessment['questions'][0]['answerChoices'] = answerChoices
                }

                if (customAssessment.questions.length > 0) {
                  for (let i = 1; i <= customAssessment.questions.length; i++) {
                    const item = customAssessment.questions[i - 1]
                    if (item.name.includes("{{orgName}}")) {
                      customAssessment['questions'][i - 1]['name'] = item.name.replace(/{{orgName}}/g,orgName)
                    }

                    if (item.answerChoices && item.answerChoices.length > 0) {
                      for (let j = 1; j <= item.answerChoices.length; j++) {
                        const ac = item.answerChoices[j - 1]

                        if (ac.includes("{{orgName}}")) {
                          if (ac === "Chat with {{orgName}} Staff" && (self.state.disableHumanChatForUnderaged && (roleName === 'Student' || roleName === 'Career-Seeker') && remoteAuth) || passedDisableHumanChat) {
                            console.log('for good?!?!')
                            // ac.splice(j - 1)
                            // console.log('show me the j 1: ', j - 1, item.answerChoices)
                            let answerChoices = item.answerChoices
                            answerChoices.splice(j - 1,1)
                            // console.log('show me the 2: ', j - 1, answerChoices)
                            customAssessment['questions'][i - 1]['answerChoices'] = answerChoices
                            // customAssessment['questions'][i - 1]['answerChoices'].splice(j,1)

                            // console.log('show me the 3: ', j - 1, customAssessment)
                          } else {
                            customAssessment['questions'][i - 1]['answerChoices'][j - 1] = ac.replace(/{{orgName}}/g,orgName)
                          }
                          // if (ac === "Chat with {{orgName}}" && self.state.includeBotAsOption) {
                          //   // ac.splice(j - 1)
                          //   // console.log('show me the j 1: ', j - 1, item.answerChoices)
                          //   let answerChoices = item.answerChoices
                          //   answerChoices.unshift("Chat with A.I. Career Advisor")
                          //   // console.log('show me the 2: ', j - 1, answerChoices)
                          //   customAssessment['questions'][i - 1]['answerChoices'] = answerChoices
                          //   // customAssessment['questions'][i - 1]['answerChoices'].splice(j,1)
                          //   // customAssessment['questions'][i - 1]['answerChoices'] = answerChoices
                          //   // console.log('show me the 3: ', j - 1, customAssessment)
                          // }


                        }
                      }
                    }
                  }
                }

                // console.log('show me the custom assessment: ', customAssessment.questions)

                self.setState({ customAssessment })
                delayTranslate();
              } else {
                delayTranslate();
              }

            } else {
              console.log('custom assessment query did not work', response.data.message)
              delayTranslate();
            }

          }).catch((error) => {
            console.log('Custom assessments query did not work for some reason', error);
            delayTranslate();
          });
        } else {
          delayTranslate();
        }
      }

      if (activeOrg) {
        Axios.get('/api/org', { params: { orgCode: activeOrg }})
        .then((response) => {
          console.log('Org info query attempted in SubHelpPrompt', response.data);

          if (response.data.success) {
            console.log('org info query worked in SubHelpPrompt', customAssessmentId)

            let ticketCategories = []
            if (response.data.orgInfo.ticketCategories) {
              ticketCategories = [{ name: '' }].concat(response.data.orgInfo.ticketCategories)
            }

            // let disableFreeformChatbotMessaging = response.data.orgInfo.disableFreeformChatbotMessaging
            if ((response.data.orgInfo.disableFreeformChatbotMessaging || response.data.orgInfo.disableFreeformChatbotMessaging === false) && (window.location.pathname.startsWith('/app') || (window.location.pathname.startsWith('/advisor') && roleName === 'Mentor'))) {
              disableFreeformChatbotMessaging = response.data.orgInfo.disableFreeformChatbotMessaging
            }
            // console.log('dfdfddfdd---', disableFreeformChatbotMessaging, response.data.orgInfo.disableFreeformChatbotMessaging)
            this.setState({ ticketCategories, disableFreeformChatbotMessaging });

            if (response.data.orgInfo.disableHumanChat || response.data.orgInfo.disableHumanChat === false) {
              disableHumanChat = response.data.orgInfo.disableHumanChat
            }

            if (customAssessmentId) {
              pullCustomAssessment(customAssessmentId,disableHumanChat,disableFreeformChatbotMessaging)
            }

          } else {
            console.log('org info query did not work', response.data.message)

            if (customAssessmentId) {
              pullCustomAssessment(customAssessmentId,disableHumanChat,disableFreeformChatbotMessaging)
            }
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      } else {
        if (customAssessmentId) {
          pullCustomAssessment(customAssessmentId,disableHumanChat,disableFreeformChatbotMessaging)
        }
      }

      // if (customAssessmentId) {
      //   pullCustomAssessment(customAssessmentId)
      //
      //
      // }

      if (window.location.pathname.startsWith('/organizations') && this.state.showHumanChatInAdmin) {
        // const inboxParams = { orgCode: activeOrg, orgMessage: true, orgUnread: true, onWaitlist: true, emailId: orgContactEmail }
        const inboxParams = { orgCode: activeOrg, orgMessage: true, emailId: orgChatEmail }

        Axios.get('/api/inboxes', { params: inboxParams })
        .then((response) => {
          console.log('Inboxes query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved inboxes')

             const inboxes = response.data.inboxes
             let unreadMessageCount = 0
             let waitlistCount = 0
             let lastWaitlistDate = null
             let lastUnreadMessage = null
             if (inboxes && inboxes.length > 0) {
               for (let i = 1; i <= inboxes.length; i++) {
                 if (inboxes[i - 1].orgUnread) {
                   unreadMessageCount = unreadMessageCount + inboxes[i - 1].unreadCount
                 }
                 if (inboxes[i - 1].onWaitlist) {

                   waitlistCount = waitlistCount + 1
                   if (!lastWaitlistDate) {
                     lastWaitlistDate = new Date(inboxes[i - 1].updatedAt)
                   }
                   if (lastWaitlistDate && (new Date(inboxes[i - 1].updatedAt) > lastWaitlistDate)) {
                     lastWaitlistDate = new Date(inboxes[i - 1].updatedAt)
                   }
                 }

                 this.clearUnreadMessages(inboxes[i - 1], i - 1)
               }

               if (waitlistCount > 0) {
                 // console.log('onWaitlist ', lastWaitlistDate, waitlistCount)
                 lastUnreadMessage = waitlistCount + " people are on the waitlist. The most recent joining " + convertDateToString(lastWaitlistDate,"daysAgo") + "."
               }
               // console.log('lastUnreadMessage> ', lastUnreadMessage)
             }

             this.setState({ inboxes, unreadMessageCount, lastUnreadMessage })

           } else {
             console.log('no inboxes found', response.data.message)
             this.setState({ errorMessage: response.data.message })

           }

        }).catch((error) => {
           console.log('Inboxes query did not work', error);
        });

      }

      if (emailId && !emailId.includes('@placeholder')) {
        console.log('is there an emailId? ', emailId)
        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success && (response.data.user.assistantChatCount || response.data.user.assistantChatCount === 0)) {
           console.log('successfully retrieved user details')

           assistantChatCount = response.data.user.assistantChatCount

           let educationOptions = []
           if (response.data.user.education && response.data.user.education.length > 0) {
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.user.education.length; i++) {
               let tempCertificate = response.data.user.education[i - 1]
               tempCertificate['title'] = tempCertificate.name
               tempCertificate['message'] = "I have earned the " + tempCertificate.name + " " + tempCertificate.degree + " degree."
               educationOptions.push(response.data.user.education[i - 1])
               combinedMessageArray.push("I have earned the " + tempCertificate.name + " " + tempCertificate.degree + " degree.")
             }

             educationOptions.unshift({ title: 'All Education', message: combinedMessageArray.join(" Also, ")})
             educationOptions.unshift({ title: '' })
           }

           let certificateOptions = []
           if (response.data.user.certificates && response.data.user.certificates.length > 0) {
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.user.certificates.length; i++) {
               let tempCertificate = response.data.user.certificates[i - 1]
               tempCertificate['title'] = tempCertificate.name
               tempCertificate['message'] = "I have earned the " + tempCertificate.name + " certificate."
               certificateOptions.push(response.data.user.certificates[i - 1])
               combinedMessageArray.push("I have earned the " + tempCertificate.name + " certificate.")
             }

             certificateOptions.unshift({ title: 'All Certificates', message: combinedMessageArray.join(" ")})
             certificateOptions.unshift({ title: '' })
           }

           let demographicsOptions = []
           if (response.data.user.race || (response.data.user.races && response.data.user.races.length > 0)) {
             demographicsOptions.push({ title: '' })

             let message = "Here is my demographic information: "
             if (response.data.user.races) {
               message = message + "{races:" + JSON.stringify(response.data.user.races) + "}"
             } else if (response.data.user.race) {
               message = message + "{race:" + response.data.user.race + "}"
             }

             if (response.data.user.gender) {
               message = message + ", {gender:" + response.data.user.gender + "}"
             }

             if (response.data.user.dateOfBirth) {
               message = message + ", {Birth Date:" + response.data.user.dateOfBirth + "}"
             }

             if (response.data.user.zipcode) {
               message = message + ", {Zip Code:" + response.data.user.zipcode + "}"
             }

             if (response.data.user.veteranStatus) {
               message = message + ", {Veteran Status:" + response.data.user.veteranStatus + "}"
             }

             if (response.data.user.adversityList && response.data.user.adversityList.length > 0) {
               for (let i = 1; i <= response.data.user.adversityList.length; i++) {
                 message = message + ", {BackgroundInfo" + i + ":" + response.data.user.adversityList[i - 1] + "}"
               }
             }
             message = message + "."
             // zip code, veteran status, background information
             demographicsOptions.push({ title: 'All My Demographic Data (e.g., race, gender, etc...)', message })
           }
           const favorites = response.data.user.favoritesArray

           this.setState({ assistantChatCount, educationOptions, certificateOptions,
             demographicsOptions, favorites
           })

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });

        // const subQueryParams = { email: emailId, orgCode: activeOrg, accountCode, isActive: true }
        const subQueryParams = { email: emailId, isActive: true }

        // pull assistantChatLimit via subsctiption, org, or account
        Axios.get('/api/payments/subscription', { params: subQueryParams })
        .then((response) => {
          console.log('Subscription query attempted under helpPrompt', response.data);

           if (response.data.success && response.data.subscription) {
             console.log('successfully retrieved subscription')

             assistantChatLimit = 1000
             this.setState({ assistantChatLimit })

           } else {
             console.log('no subscription data found', response.data.message)
           }
        }).catch((error) => {
           console.log('Subscription query did not work', error);
        });
      }

      if (this.props.chatOnly) {
        const members = [
          { firstName: cuFirstName, lastName: cuLastName, email: emailId, username },
          { firstName: recipient.firstName, lastName: recipient.lastName, email: recipient.email, username: recipient.username }
        ]
        // let recipients = [{
        //   firstName: this.state.recipient.firstName, lastName: this.state.recipient.lastName, email: this.state.recipient.email, pictureURL: this.state.recipient.pictureURL, username: this.state.recipient.username,
        //   roleName: this.state.recipient.roleName, notificationPreferences: this.state.recipient.notificationPreferences
        // }]
        //
        // let members = [{ firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId, pictureURL: this.state.pictureURL, username: this.state.username, roleName: this.state.roleName,
        //   notificationPreferences: this.state.notificationPreferences
        // }]
        // members = members.concat(recipients)
        // recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
        this.pullMessages(null, emailId, activeOrg, null, members)
      }

      const commandQueryObject = { onlyAI: true, onlyTopTier: true }
      Axios.get('/api/commands', { params: commandQueryObject })
      .then((response) => {
        console.log('Commands query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved commands')

           const popularCommands = response.data.commands
           this.setState({ popularCommands })

         } else {
           console.log('no commands found', response.data.message)
         }
      }).catch((error) => {
         console.log('Commands query did not work', error);
      });
    }

    componentWillUnmount() {
      console.log('componentWillUnmount called')

      if (!this.state.useSubRenderChat) {
        // socket.off('receive_message');
        // socket.off('error_sending_message');
      }
    }

    passCommand() {
      console.log('passCommand to helpPrompt', this.props.passedCommand)

      if (this.state.useSubRenderChat) {
        this.openModal('showChatBot', this.props.passedCommand, null)
      } else {
        let messageDraft = null
        if (this.props.passedCommand) {
          messageDraft = this.props.passedCommand
        }

        let preparedResponse = null
        if (this.props.preparedResponse) {
          preparedResponse = this.props.preparedResponse
        }

        // this.openAssistant(showChatBot, messageDraft)
        this.openModal('showChatBot', messageDraft, null)
        this.sendMessage(messageDraft, null, true, preparedResponse)
      }


      // this.itemClicked('Multiple Choice',this.state.customAssessment.questions[this.state.questionIndex].answerChoices[0], 0)
      // {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
      //   <div key={"answerChoice|" + optionIndex2}>
      //     <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
      //       <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
      //         <p>{item2}</p>
      //       </div>
      //     </button>
      //   </div>
      // )}
    }

    pullMessages(sessionUserId, emailId, activeOrg, orgMessage, members) {
      console.log('pullMessages called')

      let messageParams = {}
      if (sessionUserId) {
        messageParams['sessionUserId'] = sessionUserId
      }
      if (emailId) {
        messageParams['emailId'] = emailId
      }
      if (activeOrg) {
        messageParams['orgCode'] = activeOrg
      }
      if (orgMessage) {
        messageParams['orgMessage'] = orgMessage
      }
      if (members) {
        messageParams['members'] = members
      }
      if (this.props.courseId) {
        messageParams['courseId'] = this.props.courseId
      }

      if (this.state.resLimit) {
        messageParams['resLimit'] = this.state.resLimit
      }

      if (this.state.recent) {
        messageParams['recent'] = this.state.recent
      }

      Axios.get('/api/messages', { params: messageParams })
      .then((response) => {
        console.log('Messages query attempted', response.data);

         if (response.data.success && response.data.messages.length > 0) {
           console.log('successfully retrieved messages')

           const messages = response.data.messages
           this.setState({  messages })
           // this.scrollToBottom();

         } else {
           console.log('no messages found', response.data.message)
           // this.setState({ errorMessage: response.data.message })
         }

      }).catch((error) => {
         console.log('Message query did not work', error);
      });
    }

    clearUnreadMessages(selectedInbox, index) {
      console.log('clearUnreadMessages called', selectedInbox, index)

      Axios.put('/api/inboxes/clear-unread', { selectedInbox })
      .then((response) => {
        console.log('attempting to clear unread messages')

        if (response.data.success) {
          console.log('cleared messages', response.data)

          let inboxes = this.state.inboxes
          inboxes[index] = response.data.inbox
          this.setState({ inboxes })

        } else {
          console.log('There was an error clearing messages: ', response.data.message)
        }
      }).catch((error) => {
          console.log('Caught an error clearning messages: ', error);
          // this.setState({ errorMessage: 'there was an error saving groups' })
      });
    }

    formChangeHandler = event => {
      console.log('formHasChanged: ', event.target.name, event.target.value)

      if (event.target.name === 'messageDraft' && this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
        this.setState({ [event.target.name]: event.target.value, searchIsAnimating: true })
        this.searchItems(event.target.name,event.target.value)
      } else if (event.target.name === 'membersSearchText') {
        this.setState({ [event.target.name]: event.target.value, searchIsAnimating: true })
        this.searchItems(event.target.name,event.target.value)
      } else if (event.target.name === 'selectedDocument') {
        let selectedDocument = null
        for (let i = 1; i <= this.state.docOptions.length; i++) {
          console.log('comparing one: ', this.state.docOptions[i - 1].fileName, event.target.value)
          if (this.state.docOptions[i - 1].fileName === event.target.value) {
            console.log('goddup')
            selectedDocument = this.state.docOptions[i - 1]
          }
        }
        this.setState({ selectedDocument })
      } else if (event.target.name === 'profileItemType') {

        if (this.state.selectedMember) {
          this.setState({ [event.target.name]: event.target.value })
          this.pullProfileItems(event.target.name, event.target.value)
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(name,value) {
      console.log('searchItems called', name, value)

      if (name === 'messageDraft') {
        const orgName = value
        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')

          Axios.get('/api/org/search', { params: { orgName, resLimit: 50 } })
          .then((response) => {
            console.log('Org search query attempted', response.data);

            if (response.data.success) {
              console.log('org search query worked')

              const orgOptions = response.data.organizations
              self.setState({ orgOptions, searchIsAnimating: false });

            } else {
              console.log('organization search query did not work', response.data.message)
              self.setState({ searchIsAnimating: false, orgOptions: [] })
            }
          }).catch((error) => {
              console.log('Organization search query did not work for some reason', error);
              self.setState({ searchIsAnimating: false, orgOptions: [] })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      } else if (name === 'membersSearchText') {
        const searchString = value
        const orgCode = this.state.activeOrg

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called')
          self.setState({ animating: true, errorMessage: null, successMessage: null })

          Axios.get('/api/members/search', { params: { searchString, orgCode } })
          .then((response) => {
            console.log('Members query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved members')

                let memberOptions = null
                if (response.data.members) {
                  memberOptions = response.data.members
                }

                self.setState({ memberOptions, searchIsAnimating: false })

              } else {
                console.log('no course data found', response.data.message)
                self.setState({ searchIsAnimating: false, errorMessage: 'No courses were found that match that criteria' })
              }

          }).catch((error) => {
              console.log('Course query did not work in filter results', error);
              self.setState({ searchIsAnimating: false, errorMessage: 'Course query did not work for an unknown reason'})
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'org') {

        const searchObject = passedItem
        const messageDraft = passedItem.orgName
        const unready = false
        const orgOptions = null
        const errorMessage = null
        const successMessage = null

        this.setState({ searchObject, messageDraft, unready, orgOptions, errorMessage,
          successMessage })

      } else if (type === 'member') {
        const searchObject = passedItem
        let membersSearchText = passedItem.firstName + ' ' + passedItem.lastName
        let memberOptions = null
        this.setState({ searchObject, membersSearchText, memberOptions })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      let selectedMember = this.state.searchObject
      let membersSearchText = ''
      let searchObject = null

      this.setState({ selectedMember, membersSearchText, searchObject })

    }

    pullProfileItems(name, value) {
      console.log('pullProfileItems called', name, value)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      let emailId = this.state.emailId
      if (this.state.selectedMember) {
        emailId = this.state.selectedMember.email
      }

      if (value.endsWith('Career Assessment')) {
        if (this.state.aResultsOptions && this.state.aResultsOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.aResultsOptions, isLoading: false })
        } else {
          Axios.get('/api/assessment/results', { params: { emailId } })
          .then((response) => {
           console.log('query for assessment results worked');

           if (response.data.success) {

             console.log('actual assessment results', response.data)

             const workPreferenceAnswers = response.data.results.workPreferenceAnswers
             const interestScores = response.data.results.interestScores
             const personalityScores = response.data.results.personalityScores
             const skillsAnswers = response.data.results.skillsAnswers
             const newSkillAnswers = response.data.results.newSkillAnswers
             const skillsScores = response.data.results.skillsScores
             const topEmployerValues = response.data.results.topEmployerValues
             const topGravitateValues = response.data.results.topGravitateValues

             let aResultsOptions = []
             let combinedMessageArray = []
             if (workPreferenceAnswers && workPreferenceAnswers.length > 0) {
               const message = "I'm interested in the following functions: " + workPreferenceAnswers[0] + ", and the following industries: " + workPreferenceAnswers[1] + "."
               aResultsOptions.push({ title: 'Work Preference Results', message })
               combinedMessageArray.push(message)
             }
             if (interestScores && interestScores.length > 0) {
               let message = "My strong interest inventory scores are: "
               for (let i = 1; i <= interestScores.length; i++) {
                 if (i === interestScores.length) {
                  message = message + "and {" + interestScores[i - 1].name + ":" + ((interestScores[i - 1].score / 40) * 100) + "%}."
                 } else {
                  message = message + "{" + interestScores[i - 1].name + ":" + ((interestScores[i - 1].score / 40) * 100) + "%}, "
                 }
               }
               aResultsOptions.push({ title: 'Work Interest Results', message })
               combinedMessageArray.push(message)
             }
             if (personalityScores) {
               let message = ''
               if (personalityScores.myersBriggs) {
                 message = message + personalityScores.myersBriggs + ' personality score, '

                 if (personalityScores.fiveFactors) {
                   message = message + ((personalityScores.fiveFactors.opennessScore / 16) * 100) + '% openness score, '
                   message = message + ((personalityScores.fiveFactors.conscientiousnessScore / 16) * 100) + '% conscientiousness score, '
                   message = message + ((personalityScores.fiveFactors.extraversionScore / 16) * 100) + '% extraversion score, '
                   message = message + ((personalityScores.fiveFactors.agreeablenessScore / 16) * 100) + '% agreeableness score, '
                   message = message + ((personalityScores.fiveFactors.neuroticismScore / 16) * 100) + '% neuroticism score.'
                 }
               }

               aResultsOptions.push({ title: 'Personality Results', message })
               combinedMessageArray.push(message)
             }
             if (newSkillAnswers && newSkillAnswers.length > 0) {
               let message = "My top skills are: "
               for (let i = 1; i <= 5; i++) {
                 if (newSkillAnswers[i - 1]) {
                   if (i === 5) {
                     message = message + "and {" + newSkillAnswers[i - 1].name + ":" + ((newSkillAnswers[i - 1].score / 5) * 100) + "%}."
                   } else {
                     message = message + "{" + newSkillAnswers[i - 1].name + ":" + ((newSkillAnswers[i - 1].score / 5) * 100) + "%}, "
                   }
                 }
               }

               aResultsOptions.push({ title: 'Skills Results', message })
               combinedMessageArray.push(message)
             }
             if (topGravitateValues && topGravitateValues.length > 0) {
               let message = "My top values that I gravitate toward are: "
               for (let i = 1; i <= 5; i++) {
                 if (topGravitateValues[i - 1]) {
                   if (i === 5) {
                     message = message + "and " + topGravitateValues[i - 1] + "."
                   } else {
                     message = message + topGravitateValues[i - 1] + ", "
                   }
                 }
               }
               aResultsOptions.push({ title: 'Values Results', message })
               combinedMessageArray.push(message)
             }
             if (aResultsOptions && aResultsOptions.length > 0) {
               aResultsOptions.unshift({ title: 'All Assessment Results', message: combinedMessageArray.join(" ")})
             }

             const profileItemOptions = [{ title: '' }].concat(aResultsOptions)

             this.setState({ workPreferenceAnswers, interestScores, personalityScores,
               newSkillAnswers, topGravitateValues, topEmployerValues,
               aResultsOptions, profileItemOptions, isLoading: false
             })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for assessment results did not work', error);
         })
        }
      } else if (value.endsWith('Certificate')) {
        // console.log("@sdfsdf", this.state.certificateOptions)
        this.setState({ profileItemOptions: this.state.certificateOptions, isLoading: false })
        // if (this.state.certificateOptions && this.state.certificateOptions.length > 0) {
        //   this.setState({ profileItemOptions: this.state.certificateOptions, isLoading: false })
        // } else {
        //
        //   // Axios.get('/api/users/profile/details', { params: { email: emailId } })
        //   // .then((response) => {
        //   //   console.log('User details query 1 attempted', response.data);
        //   //
        //   //   if (response.data.success && (response.data.user.assistantChatCount || response.data.user.assistantChatCount === 0)) {
        //   //    console.log('successfully retrieved user details')
        //   //
        //   //    assistantChatCount = response.data.user.assistantChatCount
        //   //    this.setState({ assistantChatCount })
        //   //
        //   //   } else {
        //   //    console.log('no user details data found', response.data.message)
        //   //   }
        //   //
        //   // }).catch((error) => {
        //   //    console.log('User details query did not work', error);
        //   // });
        // }
      } else if (value.endsWith('Demographics')) {
        this.setState({ profileItemOptions: this.state.demographicsOptions, isLoading: false })
      } else if (value.endsWith('Document')) {
        if (this.state.docOptions && this.state.docOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.docOptions, isLoading: false })
        } else {
          Axios.get('/api/documents', { params: { emailId } })
          .then((response) => {
           console.log('query for documents worked');

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let docOptions = []
             for (let i = 1; i <= response.data.documents.length; i++) {
               let tempDoc = response.data.documents[i - 1]
               tempDoc['title'] = tempDoc.fileName

               const docType = tempDoc.docType

               delete tempDoc._id
               delete tempDoc.username
               delete tempDoc.slug
               delete tempDoc.selectedSections
               delete tempDoc.docType
               delete tempDoc.docContent.docType
               delete tempDoc.orgCode
               delete tempDoc.createdAt
               delete tempDoc.updatedAt
               delete tempDoc.__v

               tempDoc['message'] = "My " + docType + " in JSON: " + JSON.stringify(tempDoc)
               docOptions.push(tempDoc)
             }

             const profileItemOptions = [{ title: '' }].concat(docOptions)
             this.setState({ docOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for assessment results did not work', error);
         })
        }
      } else if (value.endsWith('Endorsement')) {
        if (this.state.endorsementOptions && this.state.endorsementOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.endorsementOptions, isLoading: false })
        } else {
          Axios.get('/api/story', { params: { emailId } })
          .then((response) => {
           console.log('query for endorsements worked');

           if (response.data.success) {

             console.log('actual results', response.data)

             let endorsementOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.stories.length; i++) {
               let tempDoc = response.data.stories[i - 1]
               tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")


               let competencies = []
               if (tempDoc && tempDoc.competencies && tempDoc.competencies.length > 0) {
                 for (let j = 1; j <= tempDoc.competencies.length; j++) {
                   if (tempDoc.competencies[j - 1]) {
                     const name = tempDoc.competencies[j - 1].name
                     const rating = (tempDoc.competencies[j - 1].rating / 5) * 100
                     competencies.push({ name, rating })
                   }
                 }
               }

               let message = ''
               if (competencies.length > 0) {
                 competencies.sort(function(a,b) {
                   return b.rating - a.rating;
                 })
                 competencies = JSON.stringify(competencies.slice(0,10))
                 message = "My " + tempDoc.relationship + " endorsed me on the following competencies: " + competencies + "."
               }

               if (message) {
                 tempDoc['message'] = message
                 endorsementOptions.push(tempDoc)
                 combinedMessageArray.push(message)
               }
             }

             if (response.data.stories.length > 0) {
               endorsementOptions.unshift({ title: 'All Endorsements', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(endorsementOptions)
             this.setState({ endorsementOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for endorsements did not work', error);
         })
        }

      } else if (value.endsWith('Education')) {
        this.setState({ profileItemOptions: this.state.educationOptions, isLoading: false })
      } else if (value.endsWith('Experience')) {
        if (this.state.experienceOptions && this.state.experienceOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.experienceOptions, isLoading: false })
        } else {
          Axios.get('/api/experience', { params: { emailId } })
          .then((response) => {
           console.log('query for experience worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let experienceOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.experience.length; i++) {
               let tempDoc = response.data.experience[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               tempDoc['title'] = response.data.experience[i - 1].jobTitle + ' @ ' + response.data.experience[i - 1].employerName

               let message = "I worked as a " + tempDoc.jobTitle + " @ " + tempDoc.employerName + " from " + tempDoc.startDate + " to " + tempDoc.endDate + " " + tempDoc.hoursPerWeek + " hours per week."
               if (tempDoc.jobFunction && tempDoc.industry) {
                 message = message + " It was in the " + tempDoc.jobFunction + " functional area and the " + tempDoc.industry + " industry."
               }
               message = message + " My job description was as follows: {"  + tempDoc.description + "}."
               console.log("?@?!?", message)
               tempDoc['message'] = message
               experienceOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (experienceOptions) {
               experienceOptions.unshift({ title: 'All Experience', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(experienceOptions)
             this.setState({ experienceOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for experience did not work', error);
         })
        }
      } else if (value.endsWith('Extracurricular')) {
        if (this.state.extraOptions && this.state.extraOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.extraOptions, isLoading: false })
        } else {
          Axios.get('/api/extras', { params: { emailId } })
          .then((response) => {
           console.log('query for extra worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let extraOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.extras.length; i++) {
               let tempDoc = response.data.extras[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               tempDoc['title'] = response.data.extras[i - 1].name

               const extraType = response.data.extras[i - 1].type

               delete tempDoc._id
               delete tempDoc.emailId
               delete tempDoc.name
               delete tempDoc.activityName
               delete tempDoc.type
               delete tempDoc.bullets
               delete tempDoc.orgCode
               delete tempDoc.createdAt
               delete tempDoc.updatedAt
               delete tempDoc.__v

               let message = ''
               if (extraType === 'Award') {
                 message = "I received the following award: " + JSON.stringify(tempDoc) + "."
               } else {
                 message = "I had the following extracurricular activity: " + JSON.stringify(tempDoc) + "."
               }
               tempDoc['message'] = message
               extraOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (extraOptions) {
               extraOptions.unshift({ title: 'All Extracurricular Activities & Awards', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(extraOptions)
             this.setState({ extraOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for extras did not work', error);
         })
        }
      } else if (value.endsWith('Favorite')) {

        if (this.state.favoriteOptions && this.state.favoriteOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.favoriteOptions, isLoading: false })
        } else if (this.state.favorites && this.state.favorites.length > 0) {

          Axios.get('/api/favorites/detail', { params: { favorites: this.state.favorites } })
          .then((response) => {
           console.log('query for favorites worked', response.data);

           if (response.data.success && response.data.favorites && response.data.favorites.length > 0) {

             let favoriteOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.favorites.length; i++) {
               let tempDoc = response.data.favorites[i - 1]
               if (response.data.favorites[i - 1].name) {
                 tempDoc['title'] = response.data.favorites[i - 1].name
               }
               if (tempDoc.title) {
                 let message = 'I love the ' + response.data.favorites[i - 1].title
                 if (response.data.favorites[i - 1].employerName) {
                   message = message + ' @ ' + response.data.favorites[i - 1].employerName
                 }

                 let type = response.data.types[i - 1]
                 if (type === 'work') {
                   type = 'work opportunity'
                 }

                 message = message + ' ' + type + '.'
                 tempDoc['message'] = message
                 favoriteOptions.push(tempDoc)
                 combinedMessageArray.push(message)
               }
             }

             if (favoriteOptions) {
               favoriteOptions.unshift({ title: 'All Favorites', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(favoriteOptions)
             this.setState({ favoriteOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for favoritees did not work', error);
         })

         // Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
         // .then((response2) => {
         //   console.log('Favorites detail query attempted', response2.data);
         //
         //   if (response2.data.success) {
         //     console.log('successfully retrieved favorites detail', response2.data.favorites)
         //
         //     //query info on those favorites
         //     this.setState({
         //       types: response2.data.types,
         //       filteredTypes: response2.data.types,
         //       favorites: response2.data.favorites,
         //       filteredFavorites: response2.data.favorites,
         //       favoriteIds: response.data.favorites
         //     })
         //
         //   } else {
         //     console.log('no favorites detail data found', response2.data.message)
         //   }
         //
         // }).catch((error) => {
         //     console.log('Favorites detail query did not work', error);
         // });
        }
      } else if (value === 'Goal') {
        if (this.state.goalOptions && this.state.goalOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.goalOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/goals', { params: { emailId } })
          .then((response) => {
           console.log('query for goals worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let goalOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.goals.length; i++) {
               let tempDoc = response.data.goals[i - 1]
               // tempDoc['title'] = 'Endorsement from ' + response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' on ' + convertDateToString(new Date(response.data.stories[i - 1].createdAt),"date-2")
               let message = ''
               if (tempDoc.goalType.name === 'Alternatives') {
                 message = message + "My goal is to choose between alternatives by " + convertDateToString(new Date(tempDoc.deadline),"date-2") + "."
                 message = message + " The question is {" + tempDoc.pollQuestion + "}"
                 message = message + " One the one hand, I can choose {" + tempDoc.aName + "}. The case for this is { " + tempDoc.aCase + "}."
                 message = message + " On the other hand, I can choose {" + tempDoc.bName + "}. The case for this is { " + tempDoc.bCase + "}."
               } else {
                 message = message + 'My goal is to {' + tempDoc.description + '}. I would classify it as a {' + tempDoc.goalType.description + '} goal.'
                 if (tempDoc.intensity && tempDoc.deadline) {
                   message = message + ' I will work ' + tempDoc.intensity + ' to achieve this goal by ' + convertDateToString(new Date(tempDoc.deadline),"date-2") + "."
                 }
               }
               if (tempDoc.status) {
                 message = message + " I am " + tempDoc.status + "."
               }
               tempDoc['message'] = message
               goalOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (goalOptions) {
               goalOptions.unshift({ title: 'All Goals', message: combinedMessageArray.join(" Also, ")})
             }
             // description, deadline, goalType.description, intensity, successDefined, status
             const profileItemOptions = [{ title: '' }].concat(goalOptions)
             this.setState({ goalOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for goals did not work', error);
         })
        }
      } else if (value.endsWith('Notes')) {
        if (this.state.notesOptions && this.state.notesOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.notesOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/notes', { params: { emailId } })
          .then((response) => {
           console.log('query for notes worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let notesOptions = []
             for (let i = 1; i <= response.data.notes.length; i++) {
               let tempDoc = response.data.notes[i - 1]

               if (response.data.notes[i - 1].generalNotes.length < 50) {
                 tempDoc['title'] = response.data.notes[i - 1].generalNotes
               } else {
                 tempDoc['title'] = response.data.notes[i - 1].generalNotes.substring(0,50) + '...'
               }

               tempDoc['message'] = response.data.notes[i - 1].generalNotes
               notesOptions.push(tempDoc)
             }

             const profileItemOptions = [{ title: '' }].concat(notesOptions)
             this.setState({ notesOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for goals did not work', error);
         })
        }
      } else if (value.endsWith('Passion')) {
        if (this.state.passionOptions && this.state.passionOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.passionOptions, isLoading: false })
        } else {
          Axios.get('/api/logs/passions', { params: { emailId } })
          .then((response) => {
           console.log('query for passions worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let passionOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.passions.length; i++) {
               let tempDoc = response.data.passions[i - 1]
               tempDoc['title'] = response.data.passions[i - 1].passionTitle

               let message = 'My passion is ' + tempDoc.passionTitle + '.'
               if (tempDoc.passionReason) {
                 message = message + "The reason I'm passionate about this is: {" + tempDoc.passionReason + "}."
               }
               tempDoc['message'] = message
               passionOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (passionOptions) {
               passionOptions.unshift({ title: 'All Passions', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(passionOptions)
             this.setState({ passionOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for passions did not work', error);
         })
        }
      } else if (value.endsWith('Project')) {
        if (this.state.projectOptions && this.state.projectOptions.length > 0) {
          this.setState({ profileItemOptions: this.state.projectOptions, isLoading: false })
        } else {
          Axios.get('/api/projects', { params: { emailId } })
          .then((response) => {
           console.log('query for projects worked', response.data);

           if (response.data.success) {

             // console.log('actual assessment results', response.data)

             let projectOptions = []
             let combinedMessageArray = []
             for (let i = 1; i <= response.data.projects.length; i++) {
               let tempDoc = response.data.projects[i - 1]
               tempDoc['title'] = response.data.projects[i - 1].name

               let message = "I have worked on my {" + tempDoc.title + "} project for { " + tempDoc.hours + " hours}"
               if (tempDoc.collaborators && tempDoc.collaborators.length > 0) {
                 message = message + " with " + tempDoc.collaborators.length + " teammate(s)/collaborator(s)"
               }
               message = message + ". Here is my description of the project {" + tempDoc.description + "}."

               tempDoc['message'] = message
               projectOptions.push(tempDoc)
               combinedMessageArray.push(message)
             }

             if (projectOptions) {
               projectOptions.unshift({ title: 'All Projects', message: combinedMessageArray.join(" Also, ")})
             }

             const profileItemOptions = [{ title: '' }].concat(projectOptions)
             this.setState({ projectOptions, profileItemOptions, isLoading: false })

           } else {
             console.log('error response', response.data)
             this.setState({ isLoading: false })
           }
         }).catch((error) => {
             console.log('query for projects did not work', error);
         })
        }
      }

    }

    openModal(type,messageDraft, openChatBotOnly, showPromptDesigner, showGradePrompt, showHelpPromptTitle) {
      console.log('openModal called in SubHelpPrompt', type, messageDraft, openChatBotOnly, showPromptDesigner, showGradePrompt, showHelpPromptTitle)

      let modalIsOpen = true
      if (this.props.chatOnly) {
        modalIsOpen = false
      }
      let showHelpPrompt = false
      let showTicketWindow = false
      let showChat = false
      let showChatBot = false
      let showChatPanel = false
      let lastUnreadMessage = null
      let modalHeading1 = null
      let modalHeading2 = null
      let modalHeading3 = null
      let showModalBackButton = true
      let recipient = this.state.recipient
      let messages = this.state.messages
      let enableFreeformChatbotMessaging = this.state.enableFreeformChatbotMessaging
      const customAssessment = this.state.customAssessment
      let hideAnswerChoices = this.state.hideAnswerChoices
      let passedMessageDraft = this.state.messageDraft
      let documentType = null
      let gradingRubricName = "Default Grading Rubric"

      let includeSkills = true
      let includeEducation = true
      let includeExperience = true
      let includeAssessments = false
      let includeEndorsements = true
      let includeFeedback = false
      let includeSections = false
      let includeGoals = false

      if (this.props.passedCommandDraft && showPromptDesigner) {
        if (this.props.docContent) {
          documentType = this.props.docContent.docType

          if (documentType === 'Career Plan') {
            includeAssessments = true
            includeSections = true
            includeGoals = true
          } else if (documentType === 'Financial Plan') {
            includeGoals = true
          } else if (documentType === 'Business Plan') {
            includeGoals = true
            includeAssessments = true
          } else if (documentType === 'Mock Interview Doc') {
            includeEndorsements = false
            includeAssessments = false
          }
        }
      }

      if (type === 'showChat') {
        showChat = true
        modalHeading1 = "Guided Compass Conversation"
        if (this.state.orgName) {
          modalHeading1 = this.state.orgName + " Conversation"
        }
        // modalHeading2 = "Chat between 9am - 5pm PT. Avg wait time: 5 mins."
        if (this.state.orgName) {
          recipient['firstName'] = this.state.orgName
        } else {
          recipient['firstName'] = 'Guided Compass'
        }
        recipient['lastName'] = ''
        recipient['pictureURL'] = this.state.orgLogo
      } else if (type === 'showChatBot') {
        showChatBot = true
        modalHeading1 = "Compass Assistant"
        modalHeading2 = (this.state.assistantChatLimit - this.state.assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."
        if (showHelpPromptTitle) {
          modalHeading1 = "Hi " + this.state.cuFirstName
          if (this.state.cuFirstName === 'Your') {
            modalHeading1 = "Hello There!"
          }
          modalHeading2 = "How can we help?"
        }
        recipient['firstName'] = "Compass"
        recipient['lastName'] = "Assistant"
        recipient['email'] = "creightontaylor+40@gmail.com"
        recipient['username'] = "compass"
        recipient['pictureURL'] = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'

        if (messageDraft || (!messageDraft && openChatBotOnly)) {
          hideAnswerChoices = true
        }

        if (!messageDraft && openChatBotOnly) {
          enableFreeformChatbotMessaging = true
          const openingMessage = "Waiving in Compass Assistant A.I. Write a question, prompt, or command."

          messages = [{
            emailId: recipient.email, message: openingMessage,
            senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
            senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
            senderHeadline: recipient.headline, senderUsername: recipient.username,
            senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
            senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
            orgCode: this.state.activeOrg, accountCode: null,
            recipients: [{ firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId, pictureURL: this.state.pictureURL, username: this.state.username, roleName: this.state.roleName }],
            createdAt: new Date(), updatedAt: new Date()
          }]

        }
      } else if (type === 'showHelpPrompt') {
        console.log('load showHelpPrompt')
        showHelpPrompt = true
        modalHeading1 = "Hello!"
        if (this.state.loggedIn && this.state.cuFirstName) {
          modalHeading1 = "Hi " + this.state.cuFirstName
          if (this.state.cuFirstName === 'Your') {
            modalHeading1 = "Hello There!"
          }
        }
        modalHeading2 = "How can we help?"
        showModalBackButton = false
      } else if (type === 'showTicketWindow') {
        showTicketWindow = true
        modalHeading1 = "Submit a Ticket"
        modalHeading2 = "Could be a question, issue, or bug."
      } else if (type === 'showChatPanel') {
        showChatPanel = true
        modalHeading1 = this.state.orgName + " Chat Requests"

        // modalHeading2 = "Chat between 9am - 5pm PT. Avg wait time: 5 mins."
        if (this.state.orgName) {
          recipient['firstName'] = this.state.orgName
        } else {
          recipient['firstName'] = 'Guided Compass'
        }
        recipient['lastName'] = ''
        recipient['pictureURL'] = this.state.orgLogo
      }

      // this.setState({ modalIsOpen, showHelpPrompt, showTicketWindow, showChat, showChatBot, showChatPanel,
      //   lastUnreadMessage, modalHeading1, modalHeading2, modalHeading3, showModalBackButton,
      //   recipient, customAssessment, hideAnswerChoices, messages, enableFreeformChatbotMessaging,
      //   showPromptDesigner, documentType,
      //   includeSkills, includeEducation, includeExperience, includeAssessments, includeEndorsements,
      //   includeFeedback, includeSections, includeGoals
      // })

      this.setState({
        modalIsOpen, showHelpPrompt, showTicketWindow, showChat, showChatBot, showChatPanel,
          lastUnreadMessage, modalHeading1, modalHeading2, modalHeading3, showModalBackButton,
          recipient, customAssessment, hideAnswerChoices, messages, enableFreeformChatbotMessaging,
          showPromptDesigner, showGradePrompt, documentType, gradingRubricName,
          includeSkills, includeEducation, includeExperience, includeAssessments, includeEndorsements,
          includeFeedback, includeSections, includeGoals
      }, () => {
        if (this.props.passedCommandDraft && showPromptDesigner) {
          this.craftPrompt(this.props.docContent, null, null, null, null)
        }
      });
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showHelpPrompt: false, showTicketWindow: false,
        showChatBot: false, showChat: false, modalHeading1: null, modalHeading2: null,
        modalHeading3: null, showPromptDesigner: false
      })
    }

    buttonClicked(item) {
      console.log('buttonClicked called')

      if (item.shortname === 'help') {
        window.open('/help')
        // this.props.navigate('/help')
      } else {
        this.openModal(item.showname)
      }
    }

    submitTicket() {
      console.log('submitTicket called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.name || this.state.name === '') {
        this.setState({ isSaving: false, errorMessage: 'Please title your issue' })
      } else if (!this.state.urgency || this.state.urgency === '') {
        this.setState({ isSaving: false, errorMessage: 'Please share how urgent your issue is' })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ isSaving: false, errorMessage: 'Please share a description of your issue' })
      } else {

        const name = this.state.name
        const urgency = this.state.urgency
        const category = this.state.category
        const description = this.state.description

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const email = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        const orgCode = this.state.activeOrg
        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail

        const createdAt = new Date()
        const updatedAt = new Date()

        const ticketObject = {
          name, urgency, category, description, firstName, lastName, email, username, pictureURL,
          roleName, orgCode, orgName, orgContactEmail, createdAt, updatedAt
        }

        Axios.post('/api/tickets', ticketObject).then((response) => {
          console.log('attempting to save ticket rankings')
          if (response.data.success) {
            console.log('saved ticket')

            const successMessage = 'Successfully created the ticket'
            this.setState({ successMessage, name: '', urgency: '', category: '', description: '', isSaving: false })

          } else {
            console.log('did not successfully update the rankings', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save rankings did not work', error);
            this.setState({ errorMessage: error.toString(), isSaving: false })
        });
      }
    }

    renderMessage(message, cu) {
      // console.log('renderMessage called')

      if (message && message.length > 0) {
        if (message.startsWith('<img src=')) {
          // let styleObject = ""
          if (!cu) {
            message = message.replace(/margin-left: auto;/g,"margin-right: auto;")
          }
        } else {
          let styleObject = "text-align: left; font-size: 70%; white-space: pre-wrap;"
          if (cu) {
            styleObject = styleObject + "text-align: right;"
          }

          const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
          message = message.replace(Rexp,"<a href='$1' target='_blank'>$1</a>");
          message = '<div style="' + styleObject + '">' + message + '</div>'

          console.log('show me the message: ', message)
        }
      }

      return message

    }

    async sendMessage(passedMessage, passedRoom, passedShowChatBot, preparedResponse) {
      console.log('sendMessage called', passedMessage, passedRoom, passedShowChatBot, preparedResponse)

      this.setState({ isSaving: true, activelyMessaging: true, errorMessage: null, successMessage: null })

      let messageDraft = this.state.messageDraft
      if (passedMessage) {
        messageDraft = passedMessage
      }

      if (!messageDraft || messageDraft === '') {
        this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please add a message' })
      } else if (this.state.showChat && this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations' && !this.state.searchObject) {
        this.setState({ isSaving: false, activelyMessaging: false, errorMessage: 'Please search and select an organization' })
      } else {

        if (this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
          this.itemClicked('Short Answer',messageDraft, null)
          this.setState({ messageDraft: '', isSaving: false, activelyMessaging: false })
        } else {

          const cuFirstName = this.state.cuFirstName
          const cuLastName = this.state.cuLastName
          const emailId = this.state.emailId
          const pictureURL = this.state.pictureURL
          const username = this.state.username
          const roleName = this.state.roleName

          // const emailId = this.state.emailId
          const prompt = messageDraft

          let senderFirstName = this.state.cuFirstName
          let senderLastName = this.state.cuLastName
          let senderEmail = this.state.emailId
          let senderPictureURL = this.state.pictureURL
          let senderHeadline = this.state.headline
          let senderUsername = this.state.username
          let senderSchoolName = this.state.schoolName
          let senderJobTitle = this.state.jobTitle
          let senderEmployerName = this.state.employerName
          let senderRoleName = this.state.roleName
          let sessionUserId = this.state.sessionUserId

          let recipients = [this.state.recipient]

          let members = [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName, sessionUserId,
          }]
          members = members.concat(recipients)
          // console.log("----- members", members)
          if (window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) {
            senderFirstName = this.state.orgName
            senderLastName = ''
            senderEmail = this.state.orgChatEmail
            senderUsername = this.state.activeOrg
            senderRoleName = 'Admin'
            senderPictureURL = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
            if (this.state.orgLogo) {
              senderPictureURL = this.state.orgLogo
            }

            senderHeadline = null
            senderSchoolName = null
            senderJobTitle = null
            senderEmployerName = null
            sessionUserId = null

            let recipientMemberIndex = 0

            // console.log('wait inbox: ', this.state.selectedInbox)
            if (this.state.selectedInbox && this.state.selectedInbox.members) {
              members = this.state.selectedInbox.members
              if (members.length > 0) {
                console.log('compare the two: ', members[0].username, this.state.activeOrg)
                if (members[0].username === this.state.activeOrg) {
                  recipientMemberIndex = 1
                  console.log('t1')
                } else {
                  recipientMemberIndex = 0
                }
                console.log('t3', members[recipientMemberIndex].firstName)
                recipients = [{
                  firstName: members[recipientMemberIndex].firstName,
                  lastName: members[recipientMemberIndex].lastName,
                  email: members[recipientMemberIndex].email,
                  username: members[recipientMemberIndex].username,
                  pictureURL: members[recipientMemberIndex].pictureURL,
                  roleName: members[recipientMemberIndex].roleName,
                  relationship: 'Admin'
                }]

              }
            } else {
              // in the rare case where org starts the chat

            }
          }

          let room = this.state.room
          if (passedRoom) {
            room = passedRoom
          }

          const orgCode = this.state.activeOrg
          const orgName = this.state.orgName
          const accountCode = this.state.accountCode

          let messageThreadURL = null
          if (!window.location.pathname.includes('/organization') && this.state.showChat) {
            messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
          } else {
            if (this.state.recipient) {
              if (!this.state.recipient.roleName || this.state.recipient.roleName === 'Student' || this.state.recipient.roleName === 'Career-Seeker') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/app/messaging'
              } else if (this.state.recipient.roleName === 'Advisor' || this.state.recipient.roleName === 'Teacher' || this.state.recipient.roleName === 'Mentor') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/advisor/messaging'
              } else if (this.state.recipientRoleName === 'WBLC' || this.state.recipient.roleName === 'Work-Based Learning Coordinator' || this.state.recipient.roleName === 'Admin') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/organizations/' + orgCode + '/messaging'
              } else if (this.state.recipientRoleName === 'Employer' || this.state.recipient.roleName === 'Employer Representative') {
                messageThreadURL = window.location.protocol + "//" + window.location.host + '/employers/' + this.state.recipient.accountCode + '/messaging'
              }
            }
          }

          let orgMessage = false
          let onWaitlist = false
          let orgUnread = true
          if (!window.location.pathname.startsWith('/organizations') && this.state.showChat) {
            orgMessage = true
            onWaitlist = true
            orgUnread = true
          } else if (window.location.pathname.startsWith('/organizations') && this.state.showChat && this.state.showHumanChatInAdmin) {
            orgMessage = true
            onWaitlist = false
            orgUnread = false
          }

          let assistantChatCount = 0
          if (this.state.assistantChatCount || this.state.assistantChatCount === 0) {
            assistantChatCount = Number(this.state.assistantChatCount)
          }
          assistantChatCount = assistantChatCount + 1
          console.log('log assistantChatCount before saving: ', assistantChatCount)
          let assistantChatLimit = this.state.assistantChatLimit

          let platform = 'web'
          let messageType = null
          if (this.state.showChatBot || passedShowChatBot) {
            messageType = 'Bot'
          }

          const courseId = this.props.courseId
          const questionId = this.props.questionId

          let profileEmail = emailId
          if (this.state.selectedMember) {
            profileEmail = this.state.selectedMember.email
          }

          const createdAt = new Date()
          const updatedAt = new Date()

          const sendObject = {
            emailId, profileEmail, prompt, message: prompt, senderFirstName, senderLastName, senderEmail,
            senderPictureURL, senderHeadline, senderUsername, senderSchoolName,
            senderJobTitle, senderEmployerName, senderRoleName,
            orgCode, orgName, accountCode, recipients, members, messageThreadURL, orgMessage, onWaitlist, orgUnread,
            sessionUserId, room, assistantChatCount, assistantChatLimit, platform, messageType,
            preparedResponse, courseId, questionId, createdAt, updatedAt
          }

          let messageObject = sendObject
          console.log('did we get the bot right: ', this.state.showChatBot)
          if (this.state.showChatBot || passedShowChatBot) {
            console.log('in showChatBot')

            orgMessage = false
            onWaitlist = false
            orgUnread = false

            let messages = this.state.messages
            if (messageObject) {
              if (messages) {
                messages.push(messageObject)
              } else {
                messages = [messageObject]
              }
            }

            this.setState({ successMessage: null, messageDraft: '', newMessage: false,
              inboxSelected: 0, messages, showReturnMessage: true
            })

            Axios.post('/api/messages/ai/send', sendObject)
            .then((response) => {
              console.log('attempting to send message to ai', prompt, senderEmail)

              if (response.data.success) {
                console.log('successfully send message to ai', response.data)

                let responseObject = response.data.responseObject
                if (responseObject && Array.isArray(responseObject.message)) {
                  responseObject['message'] = responseObject['message'][0]
                }
                let messages = this.state.messages

                if (responseObject) {
                  messages.push(responseObject)
                }

                let modalHeading2 = this.state.modalHeading2
                if (response.data.assistantChatCount) {
                  assistantChatCount = response.data.assistantChatCount
                  localStorage.setItem('assistantChatCount', response.data.assistantChatCount)
                  modalHeading2 = (assistantChatLimit - assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."
                }

                // console.log('---ai response: ', messages, )

                this.setState({
                  successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '',
                  newMessage: false, inboxSelected: 0, messages, assistantChatCount, modalHeading2
                })

                if (this.child && this.child.current) {
                  console.log('show me the current: ', this.child)
                  this.child.current.speakText(responseObject.message);
                }

                if (this.props.passData) {
                  this.props.passData()
                }

                const jobTitle = this.state.jobTitle
                const employerName = this.state.employerName
                const smartGoalDescription = this.state.smartGoalDescription
                const smartGoalStatus = this.state.smartGoalStatus
                const businessIdeaDescription = this.state.businessIdeaDescription

                if (window.location.pathname.startsWith('/app') && (jobTitle || employerName || smartGoalDescription || smartGoalStatus || businessIdeaDescription)) {

                  const variables = { jobTitle, employerName, smartGoalDescription, smartGoalStatus, businessIdeaDescription }
                  const orgName = this.state.orgName

                  const promptObject = {
                    firstName: cuFirstName, lastName: cuLastName, email: emailId,
                    username, pictureURL, variables, orgCode, orgName, createdAt, updatedAt
                  }

                  Axios.post('/api/prompt-variables', promptObject)
                  .then((response) => {
                    console.log('attempting to save prompt variables')
                    if (response.data.success) {
                      console.log('saved prompt variables', response.data)
                    } else {
                      console.log('did not save prompt variables')
                    }
                  }).catch((error) => {
                      console.log('saving prompt variables', error);
                  });
                }

              } else {
                console.log('did not save chatbot successfully in helpprompt')
                this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error sending the message: ' + error.toString(), isSaving: false, activelyMessaging: false })
            });
          } else {
            console.log('show me the sendObject: ', this.state.showChatBot, passedShowChatBot, sendObject)

            if (!this.state.useSubRenderChat) {
              // if (this.state.useRealTimeChat) {
              //   await socket.emit("send_message", sendObject)
              // } else {
              //   Axios.post('/api/messages/send', sendObject)
              //   .then((response) => {
              //     console.log('attempting to save new message')
              //
              //     if (response.data.success) {
              //       console.log('saved new message', response.data)
              //
              //       // this.updateUI(response.data)
              //
              //       const returnedMessage = response.data.messageObject
              //       let messages = this.state.messages
              //       if (returnedMessage) {
              //         if (messages) {
              //           messages.push(returnedMessage)
              //         } else {
              //           messages = [returnedMessage]
              //         }
              //       }
              //
              //       this.setState({ successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '', newMessage: false, messages })
              //
              //     } else {
              //       console.log('did not save user message successfully in helpprompt')
              //       this.setState({ errorMessage: response.data.message, isSaving: false, activelyMessaging: false })
              //     }
              //   }).catch((error) => {
              //       console.log('save did not work', error);
              //       this.setState({ errorMessage: 'there was an error sending the message', isSaving: false, activelyMessaging: false })
              //   });
              // }
            }
          }
        }
      }
    }

    scrollToBottom = () => {
      if (this.props.chatOnly) {
        this.props.scrollToBottom()
      } else if (this.messagesEnd) {
        console.log('in messagesEnd', this.messagesEnd)

        this.messagesEnd.scrollIntoView({ behavior: "smooth" });

        // const self = this
        // function officiallyTranslate() {
        //   console.log('officiallyTranslate called')
        //   // self.setState({ transform: 'translate(0%)', opacity: 1 })
        //   self.messagesEnd.scrollIntoView({ behavior: "smooth" });
        // }
        //
        // const delayTranslate = () => {
        //   console.log('delayTranslate called')
        //   clearTimeout(self.state.timerId)
        //   self.state.timerId = setTimeout(officiallyTranslate, 1000)
        // }
        //
        // delayTranslate();
      }
    }

    itemClicked(type,item,index) {
      console.log('itemClicked called in SubHelpPrompt')

      if (!this.state.customAssessment) {
        this.setState({ errorMessage: "Could not find the script to respond" })
      } else {
        const customAssessment = this.state.customAssessment

        let recipient = this.state.recipient
        const cuFirstName = this.state.cuFirstName
        const cuLastName = this.state.cuLastName
        const emailId = this.state.emailId
        const pictureURL = this.state.pictureURL
        const username = this.state.username
        const roleName = this.state.roleName

        let selectedAnswer = null
        if (type === 'Short Answer') {
          selectedAnswer = item
        } else {
          selectedAnswer = customAssessment.questions[this.state.questionIndex].answerChoices[index]
        }

        // change questionIndex
        let questionIndex = this.state.questionIndex
        // questionIndex = questionIndex + 1
        for (let i = 1; i <= customAssessment.questions.length; i++) {
          // test if next questions have conditionals
          const question = customAssessment.questions[i - 1]
          // console.log('i me: ', i - 1, this.state.questionIndex, question.conditional, question.conditionNumber, this.state.questionIndex + 1, question.conditionValue, selectedAnswer)
          let conditionValue = question.conditionValue
          if (conditionValue) {
            conditionValue = conditionValue.replace(/{{orgName}}/g,this.state.orgName)
          }
          if (i - 1 > this.state.questionIndex && question.conditional && question.conditionNumber === (this.state.questionIndex + 1) && (conditionValue === selectedAnswer || conditionValue === 'N/A')) {
            questionIndex = i - 1
          }
        }

        if (customAssessment.questions[questionIndex].questionType === 'Chat with Casper' || (window.location.pathname.startsWith('/organizations') && !this.state.showHumanChatInAdmin)) {
          recipient['firstName'] = "Compass"
          recipient['lastName'] = "Assistant"
          recipient['email'] = 'creightontaylor+40@gmail.com'
          recipient['username'] = 'compass'
          recipient['pictureURL'] = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/employerLogos/employerLogo%7Cguidedcompass%7CLogo-600.png%7CSat%20Aug%2014%202021%2011%3A19%3A04%20GMT-0700%20%28Pacific%20Daylight%20Time%29'
          recipient['roleName'] = 'Admin'
        }

        let messages = this.state.messages
        messages.push({
          emailId, message: '<div style="width: fit-content; margin-left: auto; border: 1px solid black; background-color: black; padding: 7px 20px; border-radius: 5px; color: white;"><p>' + selectedAnswer + '</p></div>',
          senderFirstName: cuFirstName, senderLastName: cuLastName, senderEmail: emailId,
          senderPictureURL: pictureURL, senderUsername: username,
          senderHeadline: this.state.headline,
          senderSchoolName: this.state.schoolName, senderJobTitle: this.state.jobTitle,
          senderEmployerName: this.state.employerNane, senderRoleName: roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: recipient.firstName, lastName: recipient.lastName,
            email: recipient.email, pictureURL: recipient.pictureURL,
            username: recipient.username, roleName: recipient.roleName
          }],
          createdAt: new Date(), updatedAt: new Date()
        })

        let autoResponse = customAssessment.questions[questionIndex].name
        if (customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') {
          autoResponse = autoResponse.replace(/{{orgCode}}/g,this.state.searchObject.orgCode)
          autoResponse = autoResponse.replace(/{{orgContactEmail}}/g,this.state.searchObject.contactEmail)
        }
        // console.log('my check in: ', customAssessment.questions[questionIndex].questionType, autoResponse)
        // console.log('show my autoResponse plz: ', customAssessment, questionIndex, this.state.questionIndex, autoResponse)
        // add question to messages
        messages.push({
          emailId: recipient.email, message: autoResponse,
          senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
          senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
          senderHeadline: recipient.headline, senderUsername: recipient.username,
          senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
          senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
          createdAt: new Date(), updatedAt: new Date()
        })

        // let awaitingConnection = false
        // if (customAssessment.questions[questionIndex].questionType === 'Chat with Human') {
        //   awaitingConnection = true
        // }

        this.setState({ messages, questionIndex })

        if (selectedAnswer === 'Submit a Question, Bug, or Issue') {
          this.openModal('showTicketWindow')
        }

        if (customAssessment.questions[questionIndex].questionType === 'Chat with Human') {
          this.joinRoom()
        }

        if (customAssessment.questions[questionIndex].questionType === 'Chat with Casper') {
          this.openModal('showChatBot')
        }
      }
    }

    joinRoom(inbox) {
      console.log('joinRoom called', inbox)

      const username = this.state.username
      let user1Id = null
      let user2Id = null
      if (window.location.pathname.startsWith('/organizations')) {
        if (inbox.members && inbox.members.length > 0) {
          if (inbox.members[0].firstName === this.state.orgName) {
            user1Id = inbox.members[1].sessionUserId
            if (inbox.members[1].email) {
              user1Id = inbox.members[1].email
            }
          } else {
            user1Id = inbox.members[0].sessionUserId
            if (inbox.members[0].email) {
              user1Id = inbox.members[0].email
            }
          }
        }
        user2Id = this.state.activeOrg
      } else {
        user1Id = this.state.sessionUserId
        if (this.state.emailId) {
          user1Id = this.state.emailId
        }
        if (this.state.activeOrg) {
          user2Id = this.state.activeOrg
        } else {
          user2Id = 'guidedcompass'
        }
      }

      let memberEmails = [user1Id,user2Id]
      memberEmails.sort()
      const room =  memberEmails.join("|")
      socket.emit('join_room', { username, room })
      this.setState({ room })
      if (!window.location.pathname.includes('/organizations')) {
        const messageDraft = '<img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/call-waiting-gif.gif" alt="GC" style="margin-left: auto; width: auto; height: 80px;" />'
        this.sendMessage(messageDraft, room)
      }
    }

    resetWelcomeMessage(fromPromptDesigner) {
      console.log('resetWelcomeMessage called')

      if (fromPromptDesigner) {
        this.setState({ showReturnMessage: false })
      } else {

        let questionIndex = 0

        let messages = this.state.messages
        let recipient = this.state.recipient
        let cuFirstName = this.state.cuFirstName
        let cuLastName = this.state.cuLastName
        let emailId = this.state.emailId
        let pictureURL = this.state.pictureURL
        let username = this.state.username
        let roleName = this.state.roleName

        let message = 'N/A'
        if (this.state.customAssessment && this.state.customAssessment.questions) {
          message = this.state.customAssessment.questions[questionIndex].name
        }

        messages.push({
          emailId: recipient.email, message,
          senderFirstName: recipient.firstName, senderLastName: recipient.lastName,
          senderEmail: recipient.email, senderPictureURL: recipient.pictureURL,
          senderHeadline: recipient.headline, senderUsername: recipient.username,
          senderSchoolName: recipient.schoolName, senderJobTitle: recipient.jobTitle,
          senderEmployerName: recipient.employerName, senderRoleName: recipient.roleName,
          orgCode: this.state.activeOrg, accountCode: null,
          recipients: [{ firstName: cuFirstName, lastName: cuLastName, email: emailId, pictureURL, username, roleName }],
          enableFreeformChatbotMessaging: false,
          createdAt: new Date(), updatedAt: new Date()
        })

        const hideAnswerChoices = false

        this.setState({ questionIndex, messages, hideAnswerChoices })
        this.openModal('showChat')
      }
    }

    // updateUI(data) {
    //   console.log('updatedUI called: ', data, this.state.inboxSelected, this.state.inboxes)
    //
    //   const returnedMessage = data.messageObject
    //   let messages = this.state.messages
    //   if (returnedMessage) {
    //     if (messages) {
    //       messages.push(returnedMessage)
    //     } else {
    //       messages = [returnedMessage]
    //     }
    //   }
    //
    //   this.setState({ successMessage: null, isSaving: false, activelyMessaging: false, messageDraft: '', messages })
    //
    // }

    selectInbox(value, optionIndex) {
      console.log('selectInbox called')

      let sessionUserId = null
      let emailId = null
      let orgCode = value.orgCode
      let orgMessage = false
      let members = value.members

      this.pullMessages(sessionUserId, emailId, orgCode, orgMessage, members)
      this.openModal('showChat')
      this.joinRoom(value)
      this.setState({ selectedInbox: value })

    }

    passData(data, speakingStatus, untransformed) {
      console.log('passData called: ', data, speakingStatus)

      if (speakingStatus) {
        // toggle avatar animation
        let animateAvatar = false
        if (speakingStatus === 'start') {
          animateAvatar = true
        } else if (speakingStatus === 'end') {
          animateAvatar = false
        }
        this.setState({ animateAvatar })
      } else if (untransformed) {
        this.setState(data)
      } else {
        // pass speech to text values
        this.setState({ messageDraft: data })
      }
    }

    // renderConversation() {
    //   console.log('renderConversation called')
    //
    //   return (
    //     <div key="renderConversation">
    //       {(this.state.messages && this.state.messages.length > 0) ? (
    //         <div>
    //           <div>
    //             {this.state.messages.map((value, optionIndex) =>
    //               <div key={value._id} className={(value.showMenu) ? "left-text full-width bottom-padding-20 medium-background" : "left-text full-width bottom-padding-20"}>
    //                 {((window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) ? (value.senderUsername === this.state.activeOrg) : (value.senderEmail === this.state.emailId)) ? (
    //                   <div className="left-padding full-width top-padding">
    //                     <div className="fixed-column-50">
    //                       <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
    //                     </div>
    //
    //                     <div className="calc-column-offset-90">
    //                       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : (value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className="background-button full-width standard-color">
    //                         <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
    //                       </Link>
    //
    //                       <button className="background-button" onClick={() => this.toggleMessageMenu(optionIndex)}>
    //                         <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>
    //                       </button>
    //
    //                       {(value.showMenu) && (
    //                         <div>
    //                           {this.renderMessageMenu(true, optionIndex)}
    //                         </div>
    //                       )}
    //
    //                       {(value.importConfirmationMessage) && (
    //                         <div className="row-10">
    //                           <p className="cta-color description-text-2 right-text">{value.importConfirmationMessage}</p>
    //                         </div>
    //                       )}
    //
    //                     </div>
    //                     <div className="fixed-column-40">
    //                       <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
    //                       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : '/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
    //                         <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
    //                       </Link>
    //                     </div>
    //                     <div className="clear" />
    //                   </div>
    //                 ) : (
    //                   <div className="left-padding full-width top-padding">
    //                     <div className="fixed-column-40">
    //                       <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
    //                       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
    //                         <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
    //                       </Link>
    //                     </div>
    //                     <div className="calc-column-offset-40">
    //                       <div>
    //                         <div className="calc-column-offset-50">
    //                           <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
    //                             <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
    //                           </Link>
    //                         </div>
    //                         <div className="fixed-column-50">
    //                           <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
    //                         </div>
    //                         <div className="clear" />
    //                       </div>
    //
    //                       <button className="background-button" onClick={() => this.toggleMessageMenu(optionIndex)}>
    //                         <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
    //                       </button>
    //
    //                       {(value.showMenu) && (
    //                         <div>
    //                           {this.renderMessageMenu(false, optionIndex)}
    //                         </div>
    //                       )}
    //
    //                       {(value.importConfirmationMessage) && (
    //                         <div className="row-10">
    //                           <p className="cta-color description-text-2">{value.importConfirmationMessage}</p>
    //                         </div>
    //                       )}
    //
    //                     </div>
    //                     <div className="clear" />
    //                   </div>
    //                 )}
    //               </div>
    //             )}
    //             <div className="clear" />
    //
    //             {(this.state.activelyMessaging) && (
    //               <img src={activelyMessagingGif} alt="GC" className="image-auto-30" />
    //             )}
    //             {(this.state.awaitingConnection) && (
    //               <img src={callWaitingGIF} alt="GC" className="image-auto-80 pin-right" />
    //             )}
    //
    //             {(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Multiple Choice' && !this.state.hideAnswerChoices) && (
    //               <div className="flex-container row-direction">
    //                 <div className="flex-25">
    //                 </div>
    //                 <div className="flex-75 flex-container row-direction justify-end flex-wrap">
    //                   {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
    //                     <div key={"answerChoice|" + optionIndex2}>
    //                       <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
    //                         <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
    //                           <p>{item2}</p>
    //                         </div>
    //                       </button>
    //                     </div>
    //                   )}
    //                 </div>
    //               </div>
    //             )}
    //
    //             {/*<div ref={(el) => { this.messagesEnd = el; }} />*/}
    //           </div>
    //
    //           {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
    //           {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}
    //         </div>
    //       ) : (
    //         <div className="flex-container flex-center wash-out-slight padding-40">
    //           <div>
    //             <img src={chatbubblesIcon} alt="GC" className="image-auto-80 center-item" />
    //             <p className="heading-text-6 top-margin-30 center-text">No chat history</p>
    //             <p className="top-margin-20 center-text">No message have yet been exchanged with the A.I.</p>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   )
    // }

    craftPrompt(docContent, jobTitle, employerName, smartGoalDescription, smartGoalStatus, businessPlanType, businessIdeaDescription) {
      console.log('craftPrompt called', docContent, jobTitle, employerName, smartGoalDescription, smartGoalStatus, businessPlanType, businessIdeaDescription)

      let passedMessageDraft = 'Generate a Resume.'
      let selectedGradingRubric = null

      let docType = null
      if (docContent) {
        docType = docContent.docType
      }

      if (this.props.showGradePrompt) {

        if (!jobTitle) {
          jobTitle = 'Software Developer'
        }

        if (!employerName) {
          employerName = 'Google'
        }

        if (docType === 'Resume') {
          selectedGradingRubric = {
            name: 'Default Grading Rubric',
            criteria: [
              { name: "Spelling & Grammar", oneHundredPercentCriteria: "A grade of 75% - 100% is when the resume includes 0 or 1 spelling or grammar mistakes.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the resume includes 2 or 3 spelling or grammar mistakes.", fiftyPercentCriteria: "A grade of 25% - 50% is when the resume includes between 4 and 10 spelling or grammar mistakes.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the resume includes over 11 spelling or grammar mistakes." },
              { name: "Content", oneHundredPercentCriteria: "A grade of 75% - 100% is when the resume includes education and experience sections, and the resume has approximately one page of clear, relevant content.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the resume includes education and experience sections, but the content is either less than 1/2 a page or over 2 pages.", fiftyPercentCriteria: "A grade of 25% - 50% is when the resume content is missing an experience or education section, or has confusing content.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the resume is not coherent or understandable. And major sections are missing information." },
              { name: "Formatting", oneHundredPercentCriteria: "A grade of 75% - 100% is when the resume includes bullet points that start with action verbs and numbers quantify accomplishments for each section.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the resume includes bullet points that start with action verbs and numbers quantify accomplishments for greater than 50% of the sections.", fiftyPercentCriteria: "A grade of 25% - 50% is when the resume includes bullet points that start with action verbs but does not include numbers that quantify accomplishments.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the resume does not include bullet points that start with action verbs or numbers that quantify accomplishments." },
              { name: "Effectiveness", oneHundredPercentCriteria: "A grade of 75% - 100% is when the resume is highly impressive to the hiring manager because it clearly demonstrates all relevant competencies.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the resume is somewhat impressive to the hiring manager because it demonstrates greater than 50% of relevant competencies.", fiftyPercentCriteria: "A grade of 25% - 50% is when the resume is not impressive to the hiring manager because it doesn't demonstrate most of the relevant competencies.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the resume is immediately unimpressive to the hiring manager because it fails to showcase any competencies." }
            ]
          }

          passedMessageDraft = "Please assume the role of a hiring manager for " + jobTitle + " at " + employerName + ". You have 100 applicants for your " + jobTitle + " job description, but you only have one job opening. All of the resumes are impressive. You have the below grading rubric to help grade resumes, where grades can range anywhere between 0% and 100%. Please complete the following instructions: First, generate a job description that includes the typical competencies for a " + jobTitle + " role at " + employerName + ". Second, using the grading criteria and the job description, explain three ways the below resume is impressive or positively differentiated from the average resume. Third, using the grading criteria and the job description, explain three ways the below resume can be improved. Fourth, using the grading criteria, grade the below resume on scale of 0% to 100% for each criterion and explain why you gave each grade. Fifth, combine the individual graded scores to arrive at an overall grade for the resume. Last, assuming that the candidate pool is highly competitive, share whether you would hire the person for the job and why you've arrived at your decision.\n\n"

          if (selectedGradingRubric && selectedGradingRubric.criteria) {
            passedMessageDraft = passedMessageDraft + "Grading Rubric: " + JSON.stringify(selectedGradingRubric.criteria) + ".\n\n"
          }

          if (docContent) {
            let education = 'None'
            if (docContent.education && docContent.education.length > 0) {
              education = []
              for (let i = 1; i <= docContent.education.length; i++) {
                let item = docContent.education[i - 1]
                delete item._id
                if (item.bullets && item.bullets.length < 1) {
                  delete item.bullets
                }
                education.push(item)
              }
            }

            let experience = 'None'
            if (docContent.experience && docContent.experience.length > 0) {
              experience = []
              for (let i = 1; i <= docContent.experience.length; i++) {
                let item = docContent.experience[i - 1]
                delete item._id
                if (item.bullets && item.bullets.length < 1) {
                  delete item.bullets
                }
                experience.push(item)
              }
            }

            let projects = 'None'
            if (docContent.projects && docContent.projects.length > 0) {
              projects = []
              for (let i = 1; i <= docContent.projects.length; i++) {
                let item = docContent.projects[i - 1]
                delete item._id
                if (item.bullets && item.bullets.length < 1) {
                  delete item.bullets
                }
                projects.push(item)
              }
            }

            let extracurriculars = 'None'
            if (docContent.extras && docContent.extras.length > 0) {
              extracurriculars = []
              for (let i = 1; i <= docContent.extras.length; i++) {
                let item = docContent.extras[i - 1]
                delete item._id
                if (item.bullets && item.bullets.length < 1) {
                  delete item.bullets
                }
                extracurriculars.push(item)
              }
            }

            const rawResumeDocContent = {
              firstName: docContent.firstName,
              lastName: docContent.lastName,
              phoneNumber: docContent.phoneNumber,
              education, experience, projects, extracurriculars
            }

            passedMessageDraft = passedMessageDraft + "Resume: " + JSON.stringify(rawResumeDocContent)

          }
        } else if (docType === 'Cover Letter') {

          selectedGradingRubric = {
            name: 'Default Grading Rubric',
            criteria: [
              { name: "Spelling & Grammar", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes 0 or 1 spelling or grammar mistakes.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes 2 or 3 spelling or grammar mistakes.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes between 4 and 10 spelling or grammar mistakes.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " includes over 11 spelling or grammar mistakes." },
              { name: "Content", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes approximately one page of clear, relevant content.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " content is either less than 1/2 a page or over 2 pages.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " has confusing content.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is not coherent or understandable." },
              { name: "Formatting", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments for greater than 50% of the sections.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " does not include numbers that quantify accomplishments." },
              { name: "Effectiveness", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " is highly impressive to the hiring manager because it clearly demonstrates all relevant competencies.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " is somewhat impressive to the hiring manager because it demonstrates greater than 50% of relevant competencies.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " is not impressive to the hiring manager because it doesn't demonstrate most of the relevant competencies.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is immediately unimpressive to the hiring manager because it fails to showcase any competencies." }
            ]
          }

          passedMessageDraft = "Please assume the role of a hiring manager for " + jobTitle + " at " + employerName + ". You have 100 applicants for your " + jobTitle + " job description, but you only have one job opening. All of the " + docType.toLowerCase() + "s are impressive. You have the below grading rubric to help grade " + docType.toLowerCase() + "s, where grades can range anywhere between 0% and 100%. Please complete the following instructions: First, generate a job description that includes the typical competencies for a " + jobTitle + " role at " + employerName + ". Second, using the grading criteria and the job description, explain three ways the below " + docType.toLowerCase() + " is impressive or positively differentiated from the average " + docType.toLowerCase() + ". Third, using the grading criteria and the job description, explain three ways the below " + docType.toLowerCase() + " can be improved. Fourth, using the grading criteria, grade the below " + docType.toLowerCase() + " on scale of 0% to 100% for each criterion and explain why you gave each grade. Fifth, combine the individual graded scores to arrive at an overall grade for the "  + docType.toLowerCase() + ". Last, assuming that the candidate pool is highly competitive, share whether you would hire the person for the job and why you've arrived at your decision.\n\n"

          if (selectedGradingRubric && selectedGradingRubric.criteria) {
            passedMessageDraft = passedMessageDraft + "Grading Rubric: " + JSON.stringify(selectedGradingRubric.criteria) + ".\n\n"
          }

          if (docContent && docContent.textBody) {
            passedMessageDraft = passedMessageDraft + "Cover Letter: " + docContent.textBody
          }
        } else if (docType === 'Career Plan') {

          selectedGradingRubric = {
            name: 'Default Grading Rubric',
            criteria: [
              { name: "Spelling & Grammar", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes 0 or 1 spelling or grammar mistakes.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes 2 or 3 spelling or grammar mistakes.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes between 4 and 10 spelling or grammar mistakes.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " includes over 11 spelling or grammar mistakes." },
              { name: "Content", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes relevant content that competently addresses the required sections in a business plan.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " content that somewhat competently addresses the required sections in a business plan.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " has confusing content.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is not coherent or understandable." },
              { name: "Formatting", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " thoroughly explains assumptions and estimates in a clear, concise way.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " somewhat explains assumptions and estimates.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " does not explain assumptions and estimates.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " does not explain assumptions and estimates" },
              { name: "Effectiveness", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " is highly thorough and insightful to a career counseling professional.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " is somewhat thorough and insightful to a career counseling professional.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " is not thorough or insightful to a career counseling professional.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is not thorough or insightful to a career counseling professional." }
            ]
          }

          passedMessageDraft = "Please assume the role of a career counselor that is looking to help your client efficiently and effectively thrive in a fulfilling career. You have the below grading rubric to help grade " + docType.toLowerCase() + "s, where grades can range anywhere between 0% and 100%. Please complete the following instructions: First, generate an outline for the requirements for an impressive career plan that will serve as an example. Second, using the grading criteria and your created career plan example, explain three ways the below " + docType.toLowerCase() + " is impressive or positively differentiated from the average " + docType.toLowerCase() + ". Third, using the grading criteria and your created career plan example, explain three ways the below " + docType.toLowerCase() + " can be improved. Fourth, using the grading criteria, grade the below " + docType.toLowerCase() + " on scale of 0% to 100% for each criterion and explain why you gave each grade. Last, combine the individual graded scores to arrive at an overall grade for the "  + docType.toLowerCase() + ".\n\n"

          if (selectedGradingRubric && selectedGradingRubric.criteria) {
            passedMessageDraft = passedMessageDraft + "Grading Rubric: " + JSON.stringify(selectedGradingRubric.criteria) + ".\n\n"
          }

          if (docContent && docContent.sections && docContent.sections.length > 0) {
            passedMessageDraft = passedMessageDraft + "Business Plan: " + JSON.stringify(docContent.sections)
          }
        } else if (docType === 'Business Plan') {

          selectedGradingRubric = {
            name: 'Default Grading Rubric',
            criteria: [
              { name: "Spelling & Grammar", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes 0 or 1 spelling or grammar mistakes.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes 2 or 3 spelling or grammar mistakes.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes between 4 and 10 spelling or grammar mistakes.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " includes over 11 spelling or grammar mistakes." },
              { name: "Content", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes relevant content that competently addresses the required sections in a business plan.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " content that somewhat competently addresses the required sections in a business plan.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " has confusing content.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is not coherent or understandable." },
              { name: "Formatting", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " thoroughly explains assumptions and estimates in a clear, concise way.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " somewhat explains assumptions and estimates.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " does not explain assumptions and estimates.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " does not explain assumptions and estimates" },
              { name: "Effectiveness", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " is highly impressive to a business equity investor because it clearly demonstrates all relevant competencies.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " is somewhat impressive to the business equity investor.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " is not impressive to a business equity investor.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is immediately unimpressive to the business equity investor." }
            ]
          }

          passedMessageDraft = "Please assume the role of an investor that early-stage companies. You have 100 applicants for $250,000 in pre-seed funding, but you will only invest in one company. All of the " + docType.toLowerCase() + "s are impressive. You have the below grading rubric to help grade " + docType.toLowerCase() + "s, where grades can range anywhere between 0% and 100%. Please complete the following instructions: First, generate an investment application for a pre-seed investment opportunity. Second, using the grading criteria and your created investment application, explain three ways the below " + docType.toLowerCase() + " is impressive or positively differentiated from the average " + docType.toLowerCase() + ". Third, using the grading criteria and your created investment application, explain three ways the below " + docType.toLowerCase() + " can be improved. Fourth, using the grading criteria, grade the below " + docType.toLowerCase() + " on scale of 0% to 100% for each criterion and explain why you gave each grade. Fifth, combine the individual graded scores to arrive at an overall grade for the "  + docType.toLowerCase() + ". Last, assuming that the candidate pool is highly competitive, share whether you would invest in this business and why you've arrived at your decision.\n\n"

          if (selectedGradingRubric && selectedGradingRubric.criteria) {
            passedMessageDraft = passedMessageDraft + "Grading Rubric: " + JSON.stringify(selectedGradingRubric.criteria) + ".\n\n"
          }

          if (docContent && docContent.sections && docContent.sections.length > 0) {
            passedMessageDraft = passedMessageDraft + "Business Plan: " + JSON.stringify(docContent.sections)
          }
        } else if (docType === 'Mock Interview Doc') {

          selectedGradingRubric = {
            name: 'Default Grading Rubric',
            criteria: [
              { name: "Spelling & Grammar", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes 0 or 1 spelling or grammar mistakes.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes 2 or 3 spelling or grammar mistakes.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes between 4 and 10 spelling or grammar mistakes.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " includes over 11 spelling or grammar mistakes." },
              { name: "Content", oneHundredPercentCriteria: "A grade of 75% - 100% is when each answer in the " + docType.toLowerCase() + " fully, comprehensively, and clearly answers the corresponding question.", seventyFivePercentCriteria: "A grade of 50% - 75% is when most answers in the " + docType.toLowerCase() + " fully, comprehensively, and clearly answer their corresponding question.", fiftyPercentCriteria: "A grade of 25% - 50% is only a few of the answers in the " + docType.toLowerCase() + " fully, comprehensively, and clearly answer the corresponding question.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the no answers in the " + docType.toLowerCase() + " fully, comprehensively, and clearly answer the corresponding question." },
              { name: "Formatting", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments for greater than 50% of the sections.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " includes numbers that quantify accomplishments.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " does not include numbers that quantify accomplishments." },
              { name: "Effectiveness", oneHundredPercentCriteria: "A grade of 75% - 100% is when the " + docType.toLowerCase() + " is highly impressive to the hiring manager because it clearly demonstrates all relevant competencies.", seventyFivePercentCriteria: "A grade of 50% - 75% is when the " + docType.toLowerCase() + " is somewhat impressive to the hiring manager because it demonstrates greater than 50% of relevant competencies.", fiftyPercentCriteria: "A grade of 25% - 50% is when the " + docType.toLowerCase() + " is not impressive to the hiring manager because it doesn't demonstrate most of the relevant competencies.", twentyFivePercentCriteria: "A grade of 0% - 25% is when the " + docType.toLowerCase() + " is immediately unimpressive to the hiring manager because it fails to showcase any competencies." }
            ]
          }

          passedMessageDraft = "Please assume the role of a hiring manager for " + jobTitle + " at " + employerName + ". You have 100 applicants for your " + jobTitle + " job description, but you only have one job opening. The candidate has provided answers for your interview questions listed in the " + docType.toLowerCase() + ". You have the below grading rubric to help grade " + docType.toLowerCase() + " answers, where grades can range anywhere between 0% and 100%. Please complete the following instructions: First, generate a job description that includes the typical competencies for a " + jobTitle + " role at " + employerName + ". Second, using the grading criteria and the job description, explain three ways the answers in the below " + docType.toLowerCase() + " are impressive or positively differentiated from the average " + docType.toLowerCase() + ". Third, using the grading criteria and the job description, explain three ways the answers in the below " + docType.toLowerCase() + " can be improved. Fourth, using the grading criteria, grade the answers in the below " + docType.toLowerCase() + " on scale of 0% to 100% for each criterion and explain why you gave each grade. Fifth, combine the individual graded scores to arrive at an overall grade for the "  + docType.toLowerCase() + ". Last, assuming that the candidate pool is highly competitive, share whether you would hire the person for the job and why you've arrived at your decision.\n\n"

          if (selectedGradingRubric && selectedGradingRubric.criteria) {
            passedMessageDraft = passedMessageDraft + "Grading Rubric: " + JSON.stringify(selectedGradingRubric.criteria) + ".\n\n"
          }

          if (docContent && docContent.textBody) {
            passedMessageDraft = passedMessageDraft + "Cover Letter: " + docContent.textBody
          }

        }

      } else if (docContent) {
        let docType = docContent.docType

        console.log("IN doc content", docType)

        if (docType === 'Resume' || docType === 'Cover Letter') {

          if (!jobTitle) {
            jobTitle = 'Software Developer'
          }

          if (!employerName) {
            employerName = 'Google'
          }

          passedMessageDraft = "Generate a " + docType.toLowerCase() + " that highly appeals to a hiring manager for a " + jobTitle + " role at " + employerName + " based on my profile information below. Please limit the " + docType.toLowerCase() + " to one page and try to highlight how I'm a perfect fit for the role. You may exclude profile information from the " + docType.toLowerCase() + " if it's not relevant.\n\n"

          let phoneNumber = "555-555-555"
          if (docContent.phoneNumber) {
            phoneNumber = docContent.phoneNumber
          }

          let email = "jondoe@gmail.com"
          if (this.state.emailId) {
            email = this.state.emailId
          }

          passedMessageDraft = passedMessageDraft + "My name is " + this.state.cuFirstName + " " + this.state.cuLastName + ". My phone number is " + phoneNumber + " and my email is " + email + ".\n\n"

        } else if (docType === 'Career Plan') {

          if (!smartGoalDescription) {
            smartGoalDescription = 'becoming an entry-level software Developer at Google within 10 years'
          }

          if (!smartGoalStatus) {
            smartGoalStatus = 'being a 10th grade student at Lamar High School'
          }

          passedMessageDraft = "Generate a comprehensive " + docType.toLowerCase() + " to reach my goal of " + smartGoalDescription + " from my current status of " + smartGoalStatus + ".\n\n"

          if (this.state.includeSections) {
            passedMessageDraft = passedMessageDraft + "Include the following sections in the career plan: 1) Summary - where you summarize the end goal, strategies, and tactics; 2) Lifestyle - where you depict my lifestyle once the plan is reached; 3) Fuel - where you detail motivations and fuel for reaching the end goal; 4) Competencies - where you detail the core competencies required to reach the goal and thrive; 5) Competitive Advantage - where you detail in what aspects I have a competive advantage over others who would like to reach the same end goal; 6) Employer Strategy - where you detail strategies and tactics for connecting with the relevant people and earning income; 7) Financials - where you project my income, expenses, net income, assets, liabilities, and net worth over my desired time period to reach my end goal; 8) Steps - where you provide a step by step playbook for achieving your end goal. \n\n"
          }

        } else if (docType === 'Financial Plan') {

          if (!smartGoalDescription) {
            smartGoalDescription = 'becoming an entry-level software Developer at Google within 10 years'
          }

          if (!smartGoalStatus) {
            smartGoalStatus = 'being a 10th grade student at Lamar High School'
          }

          passedMessageDraft = "Generate a " + docType.toLowerCase() + " that details my income, expenses, net income, assets, liabilities, and net worth each year for the next ten years assuming I reach my goal of " + smartGoalDescription + " from my current status of " + smartGoalStatus + ".\n\n"

        } else if (docType === 'Business Plan') {

          if (!businessPlanType) {
            businessPlanType = 'Create and Detail a New Business Idea'
          }

          if (!businessIdeaDescription) {
            businessIdeaDescription = 'Uber for Kids'
          }

          if (businessPlanType === "Create and Detail a New Business Idea") {
            passedMessageDraft = "Based on my profile information outlined below, please generate a new business idea and a full business plan to execute that business idea.\n\n"
          } else {
            passedMessageDraft = "Generate a Business Plan to execute my business idea: " + businessIdeaDescription + ".\n\n"
          }

          passedMessageDraft = passedMessageDraft + "Include the following sections in the business plan: 1) Executive Summary - where you summarize the business plan, define metrics for success, and detail goals; 2) Problem - where you detail the problem that this business will be solving; 3) Solution - where you detail my solution, product, and services to solve the problem; 4) Market - where you detail the market, market size, and market growth rate as it relates to the solution; 5) Competitive Advantage - where you detail in what aspects our solution and team have a competive advantage over others; 6) Go to Market Strategy - where you detail how you will reach your target customers efficiently and effectively; 7) Business Model - where you detail how the business will make money; 8) Financials - where you project my income, expenses, net income, assets, liabilities, and net worth for this business; 9) Steps - where you provide a step by step playbook for achieving your end goal.\n\n"

          if (businessPlanType === "Create and Detail a New Business Idea" && docContent.email) {
            passedMessageDraft = passedMessageDraft + " Below is my profile information.\n\n"
          }

        } else if (docType === 'Mock Interview' || docType === 'Mock Interview Doc') {

          if (!jobTitle) {
            jobTitle = 'Software Developer'
          }

          if (!employerName) {
            employerName = 'Google'
          }

          passedMessageDraft = "Assume the role of a hiring manager for a " + jobTitle + " role at " + employerName + ". Generate a list of 10 interview questions that you would ask a candidate to decide whether you would like to hire them as a " + jobTitle + " role at " + employerName + ". Below is some of the information submitted with the candidate's application.\n\n"

        }

        if (docContent.skillsAnswers && docContent.skillsAnswers.length > 0 && this.state.includeSkills) {
          let lastIndex = 9
          if (docContent.skillsAnswers && docContent.skillsAnswers.length < 10) {
            lastIndex = docContent.skillsAnswers.length - 1
          }

          if (lastIndex === 1) {
            passedMessageDraft = passedMessageDraft + 'I think my top skill is ' + docContent.skillsAnswers[0].name
          } else {
            passedMessageDraft = passedMessageDraft + 'I think my top skills are: '
            for (let i = 1; i <= lastIndex; i++) {
              if (i === lastIndex) {
                passedMessageDraft = passedMessageDraft + ' and ' + i.toString() + ') ' + docContent.skillsAnswers[i - 1].name + '.'
              } else {
                passedMessageDraft = passedMessageDraft + i.toString() + ') ' + docContent.skillsAnswers[i - 1].name + ', '
              }
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.topEndorsedSkills && docContent.topEndorsedSkills.length > 0 && this.state.includeEndorsements) {
          let lastIndex = 9
          if (docContent.topEndorsedSkills && docContent.topEndorsedSkills.length < 10) {
            lastIndex = docContent.topEndorsedSkills.length - 1
          }

          if (lastIndex === 1) {
            passedMessageDraft = passedMessageDraft + 'Others say my top skill is ' + docContent.topEndorsedSkills[0].name
          } else {
            passedMessageDraft = passedMessageDraft + 'Others say my top skills are: '
            for (let i = 1; i <= lastIndex; i++) {
              if (i === lastIndex) {
                passedMessageDraft = passedMessageDraft + ' and ' + i.toString() + ') ' + docContent.topEndorsedSkills[i - 1].name + '.'
              } else {
                passedMessageDraft = passedMessageDraft + i.toString() + ') ' + docContent.topEndorsedSkills[i - 1].name + ', '
              }
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.education && docContent.education.length > 0 && this.state.includeEducation) {
          passedMessageDraft = passedMessageDraft + "My education includes: "
          for (let i = 1; i <= docContent.education.length; i++) {
            passedMessageDraft = passedMessageDraft + i.toString() + ")"
            const item = docContent.education[i - 1]

            if (item.degree) {
               passedMessageDraft = passedMessageDraft + ' ' + item.degree
            }
            if (docContent.education[i - 1].name) {
               passedMessageDraft = passedMessageDraft + ' ' + item.name

               if (docContent.education[i - 1].location) {
                 passedMessageDraft = passedMessageDraft + ' in ' + item.location
               }

               if (docContent.education[i - 1].startDate && docContent.education[i - 1].endDate) {
                  passedMessageDraft = passedMessageDraft + ' from ' + item.startDate + ' to ' + item.endDate
               }

               if (item.summary) {
                  passedMessageDraft = passedMessageDraft + ' and the following description {{{' + item.summary + '}}}'
               }
            }

            if (i === docContent.education.length) {
              passedMessageDraft = passedMessageDraft + ". "
            } else {
              passedMessageDraft = passedMessageDraft + "; "
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.projects && docContent.projects.length > 0 && this.state.includeExperience) {
          passedMessageDraft = passedMessageDraft + "My projects includes: "
          for (let i = 1; i <= docContent.projects.length; i++) {
            passedMessageDraft = passedMessageDraft + i.toString() + ")"
            const item = docContent.projects[i - 1]

            if (item.name) {
               passedMessageDraft = passedMessageDraft + ' ' + item.name

               if (item.category) {
                 passedMessageDraft = passedMessageDraft + ', a ' + item.category + ' project'
               } else {
                 passedMessageDraft = passedMessageDraft + ', a project'
               }

               if (item.hours && item.startDate && item.endDate) {
                  passedMessageDraft = passedMessageDraft + ' that took ' + item.hours + ' between ' + item.startDate + ' to ' + item.endDate
               }

               if (item.summary) {
                  passedMessageDraft = passedMessageDraft + ' and the following description {{{' + item.summary + '}}}'
               }
            }

            if (i === docContent.projects.length) {
              passedMessageDraft = passedMessageDraft + ". "
            } else {
              passedMessageDraft = passedMessageDraft + "; "
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.experience && docContent.experience.length > 0 && this.state.includeExperience) {
          passedMessageDraft = passedMessageDraft + "My experience includes: "
          for (let i = 1; i <= docContent.experience.length; i++) {

            const item = docContent.experience[i - 1]
            console.log('show item: ', item)
            // description, endDate, hoursPerWeek, location, startDate
            if (item.title && item.employerName) {
               passedMessageDraft = passedMessageDraft + i.toString() + ")"

               passedMessageDraft = passedMessageDraft + ' ' + item.title + ' @ ' + item.employerName

               if (item.jobFunction && item.industry) {
                  passedMessageDraft = passedMessageDraft + ', working within the ' + item.industry + ' industry and the ' + item.jobFunction + ' department'
               }

               if (item.location) {
                 passedMessageDraft = passedMessageDraft + ' in ' + item.location
               }

               if (item.hoursPerWeek && item.startDate && item.endDate) {
                  passedMessageDraft = passedMessageDraft + ' between ' + item.startDate + ' to ' + item.endDate + ' for ' + item.hoursPerWeek
               }

               if (item.description) {
                  passedMessageDraft = passedMessageDraft + ' and the following description {{{' + item.description + '}}}'
               }

               if (i === docContent.experience.length) {
                 passedMessageDraft = passedMessageDraft + ". "
               } else {
                 passedMessageDraft = passedMessageDraft + "; "
               }
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.extras && docContent.extras.length > 0 && this.state.includeExperience) {
          passedMessageDraft = passedMessageDraft + "My extracurricular activities and awards include: "
          for (let i = 1; i <= docContent.extras.length; i++) {

            const item = docContent.extras[i - 1]
            // console.log('show item: ', item)
            // description, endDate, hoursPerWeek, location, startDate
            if (item.type && item.name) {
               passedMessageDraft = passedMessageDraft + i.toString() + ")"

               passedMessageDraft = passedMessageDraft + ' ' + item.name + ' ' + item.type

               if (item.startDate) {
                  passedMessageDraft = passedMessageDraft + ' between ' + item.startDate + ' to ' + item.endDate
               }

               if (item.awardDate) {
                  passedMessageDraft = passedMessageDraft + ' on ' + item.awardDate
               }

               if (item.description) {
                  passedMessageDraft = passedMessageDraft + ' and the following description {{{' + item.description + '}}}'
               }

               if (i === docContent.extras.length) {
                 passedMessageDraft = passedMessageDraft + ". "
               } else {
                 passedMessageDraft = passedMessageDraft + "; "
               }
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.interestsData && docContent.interestsData.length > 0 && this.state.includeAssessments) {
          passedMessageDraft = passedMessageDraft + 'My strong interest inventory results are:'

          let loopCount = 6
          for (let i = 1; i <= loopCount; i++) {
            if (i === loopCount) {
              passedMessageDraft = passedMessageDraft + ' and ' + ((docContent.interestsData[i - 1].score / 40) * 100) + '% ' + docContent.interestsData[i - 1].name + '. '
            } else {
              passedMessageDraft = passedMessageDraft + ' ' + ((docContent.interestsData[i - 1].score / 40) * 100) + '% ' + docContent.interestsData[i - 1].name + ', '
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.personalityData && docContent.personalityData.myersBriggs && docContent.personalityData.fiveFactors && this.state.includeAssessments) {

          passedMessageDraft = passedMessageDraft + 'My Myers-Briggs results are: ' + docContent.personalityData.myersBriggs + '. '
          passedMessageDraft = passedMessageDraft + 'My Big Five Personality results are: ' + ((Number(docContent.personalityData.fiveFactors.opennessScore) / 16) * 100) + '% percentile for openness, ' + ((Number(docContent.personalityData.fiveFactors.conscientiousnessScore) / 16) * 100) + '% percentile for conscientiousness, ' + ((Number(docContent.personalityData.fiveFactors.extraversionScore) / 16) * 100) + '% percentile for extraversion, ' + ((Number(docContent.personalityData.fiveFactors.agreeablenessScore) / 16) * 100) + '% percentile for agreeableness, ' + ((Number(docContent.personalityData.fiveFactors.neuroticismScore) / 16) * 100) + '% percentile for neuroticism.'

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.valuesData && docContent.valuesData.length > 0 && this.state.includeAssessments) {
          passedMessageDraft = passedMessageDraft + 'I tend to gravitate toward people who are: '

          let loopCount = 6
          for (let i = 1; i <= loopCount; i++) {
            if (i === loopCount) {
              passedMessageDraft = passedMessageDraft + ' and ' + docContent.valuesData[i - 1].value + '. '
            } else {
              passedMessageDraft = passedMessageDraft + ' ' + docContent.valuesData[i - 1].value + ', '
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"
        }

        if (docContent.goals && docContent.goals.length > 0 && this.state.includeGoals) {
          let lastIndex = 9
          if (docContent.goals.length < 10) {
            lastIndex = docContent.goals.length - 1
          }

          if (lastIndex === 1) {
            passedMessageDraft = passedMessageDraft + 'My goal is ' + docContent.goals[0].title + ' - ' + docContent.goals[0].description + ' by ' + convertDateToString(new Date(docContent.goals[0].deadline),"date-2") + '.'
          } else {
            passedMessageDraft = passedMessageDraft + 'My goals are: '
            for (let i = 1; i <= lastIndex; i++) {
              if (i === lastIndex) {
                passedMessageDraft = passedMessageDraft + ' and ' + i.toString() + ') ' + docContent.goals[i - 1].title + ' by ' + convertDateToString(new Date(docContent.goals[i - 1].deadline),"date-2") + '.'
              } else {
                passedMessageDraft = passedMessageDraft + i.toString() + ') ' + docContent.goals[i - 1].title + ' by ' + convertDateToString(new Date(docContent.goals[i - 1].deadline),"date-2") + '; '
              }
            }
          }

          passedMessageDraft = passedMessageDraft + "\n\n"

        }

      } else {
        // passedMessageDraft = "shot the doctype: " + docType

        if (docType === 'Resume') {

          passedMessageDraft = "I haven't built out my profile on Guided Compass yet so that it automatically gives me a custom prompt. Generate an example of a stellar resume."

        } else if (docType === 'Cover Letter') {

          passedMessageDraft = "I haven't built out my profile on Guided Compass yet so that it automatically gives me a custom prompt. Generate an example of a stellar cover letter."

        } else if (docType === 'Career Plan') {

          passedMessageDraft = "I haven't built out my profile on Guided Compass yet so that it automatically gives me a custom prompt. Generate an example of a stellar career plan."

        } else if (docType === 'Business Plan') {

          passedMessageDraft = "I haven't built out my profile on Guided Compass yet so that it automatically gives me a custom prompt. Generate an example of a stellar business plan."

        } else if (docType === 'Mock Interview' || docType === 'Mock Interview Doc') {

          passedMessageDraft = "I haven't built out my profile on Guided Compass yet so that it automatically gives me a custom prompt. Generate an examples of good general mock interview questions.."

        }
      }

      this.setState({ messageDraft: passedMessageDraft, jobTitle, employerName, selectedGradingRubric })

    }

    // renderChatBox(fromPromptDesigner, fromCourse) {
    //   console.log('renderChatBox called', fromPromptDesigner, fromCourse)
    //
    //   return (
    //     <div key="chatbox">
    //       <div>
    //         {(this.state.messages && this.state.messages.length > 0) ? (
    //           <div className={(fromCourse) ? "" : (fromPromptDesigner) ? "row-10 standard-border horizontal-padding-4 calc-height-140-of-100" : "row-10 standard-border horizontal-padding-4 calc-height-340-of-100"}>
    //             <div className="full-space overflow-y-auto">
    //               {this.state.messages.map((value, optionIndex) =>
    //                 <div key={value._id} className={(value.showMenu) ? "left-text full-width bottom-padding-20 medium-background" : "left-text full-width bottom-padding-20"}>
    //                   {((window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) ? (value.senderUsername === this.state.activeOrg) : (value.senderEmail === this.state.emailId)) ? (
    //                     <div className="left-padding full-width top-padding">
    //                       <div className="fixed-column-50">
    //                         <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
    //                       </div>
    //
    //                       <div className="calc-column-offset-90">
    //                         <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : (value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className="background-button full-width standard-color">
    //                           <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
    //                         </Link>
    //
    //                         <button className="background-button float-right" onClick={() => this.toggleMessageMenu(optionIndex)}>
    //                           <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>
    //                         </button>
    //                         <div className="clear" />
    //
    //                         {(value.showMenu) && (
    //                           <div>
    //                             {this.renderMessageMenu(true, optionIndex)}
    //                           </div>
    //                         )}
    //
    //                         {(value.importConfirmationMessage) && (
    //                           <div className="row-10">
    //                             <p className="cta-color description-text-2 right-text">{value.importConfirmationMessage}</p>
    //                           </div>
    //                         )}
    //
    //                       </div>
    //                       <div className="fixed-column-40">
    //                         <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
    //                         <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : '/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
    //                           <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
    //                         </Link>
    //                       </div>
    //                       <div className="clear" />
    //                     </div>
    //                   ) : (
    //                     <div className="left-padding full-width top-padding">
    //                       <div className="fixed-column-40">
    //                         <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
    //                         <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
    //                           <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
    //                         </Link>
    //                       </div>
    //                       <div className="calc-column-offset-40">
    //                         <div>
    //                           <div className="calc-column-offset-50">
    //                             <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
    //                               <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
    //                             </Link>
    //                           </div>
    //                           <div className="fixed-column-50">
    //                             <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
    //                           </div>
    //                           <div className="clear" />
    //                         </div>
    //
    //                         <button className="background-button" onClick={() => this.toggleMessageMenu(optionIndex)}>
    //                           <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
    //                         </button>
    //                         <div className="clear" />
    //
    //                         {(value.showMenu) && (
    //                           <div>
    //                             {this.renderMessageMenu(false, optionIndex)}
    //                           </div>
    //                         )}
    //
    //                         {(value.importConfirmationMessage) && (
    //                           <div className="row-10">
    //                             <p className="cta-color description-text-2">{value.importConfirmationMessage}</p>
    //                           </div>
    //                         )}
    //
    //                       </div>
    //                       <div className="clear" />
    //                     </div>
    //                   )}
    //                 </div>
    //               )}
    //               <div className="clear" />
    //
    //               {(this.state.activelyMessaging) && (
    //                 <img src={activelyMessagingGif} alt="GC" className="image-auto-30" />
    //               )}
    //               {(this.state.awaitingConnection) && (
    //                 <img src={callWaitingGIF} alt="GC" className="image-auto-80 pin-right" />
    //               )}
    //
    //               {(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Multiple Choice' && !this.state.hideAnswerChoices) && (
    //                 <div className="flex-container row-direction">
    //                   <div className="flex-25">
    //                   </div>
    //                   <div className="flex-75 flex-container row-direction justify-end flex-wrap">
    //                     {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
    //                       <div key={"answerChoice|" + optionIndex2}>
    //                         <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
    //                           <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
    //                             <p>{item2}</p>
    //                           </div>
    //                         </button>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //               )}
    //
    //               <div ref={(el) => { this.messagesEnd = el; }} />
    //             </div>
    //           </div>
    //         ) : (
    //           <div className="row-10 standard-border horizontal-padding-4 calc-height-400-of-95">
    //             {(this.state.recipient) && (
    //               <div className="padding-30">
    //                 <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
    //                 <div>
    //                   <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{this.state.recipient.firstName} {this.state.recipient.lastName}</p>
    //                   <p className="full-width description-text-3 center-text top-padding">The {(this.state.showChatBot) ? "Compass Assistant is here to help" : (this.state.orgName) ? this.state.orgName + " is here to help" : "Guided Compass is here to help"}.</p>
    //                 </div>
    //               </div>
    //             )}
    //           </div>
    //         )}
    //       </div>
    //
    //       {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
    //       {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}
    //
    //       {((!this.state.customAssessment && !this.state.disableFreeformChatbotMessaging && !fromPromptDesigner && !fromCourse) || (this.state.enableFreeformChatbotMessaging && !fromPromptDesigner && !fromCourse) || (this.state.customAssessment && (this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Short Answer' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Human' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Casper' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') && !this.state.disableFreeformChatbotMessaging && !fromPromptDesigner&& !fromCourse)) && (
    //         <div className="horizontal-padding-4">
    //           <div className="row-10">
    //             {(this.state.showVoiceButton) && (
    //               <div className="fixed-column-40">
    //                 <SubSpeechToText passData={this.passData} />
    //               </div>
    //             )}
    //             <div className="fixed-column-40">
    //               <button className="background-button" onClick={(this.state.showImportWidget) ? () => this.setState({ showImportWidget: false  }) : () => this.setState({ showImportWidget: true, showHelpWidget: false, showBackButton: true, modalHeading1: "Import Profile Information", modalHeading2: "Select and import your profile info to A.I."  })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import profile information">
    //                 <div className={(this.state.showImportWidget)? "error-border padding-5 circle-radius" : "cta-border padding-5 circle-radius"}>
    //                   <img src={(this.state.showImportWidget) ? closeIcon : addIconBlue} alt="GC" className="image-15-fit" />
    //                 </div>
    //               </button>
    //             </div>
    //             <div className="fixed-column-40">
    //               <button className="background-button" onClick={(this.state.showHelpWidget) ? () => this.setState({ showHelpWidget: false  }) : () => this.setState({ showHelpWidget: true, showImportWidget: false, showBackButton: true, modalHeading1: "Assistance with A.I. Assistant", modalHeading2: "Better understand A.I. and proc common prompts."  })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Get help, tips, and pointers">
    //                 <div className={(this.state.showHelpWidget)? "error-border padding-5 circle-radius" : "cta-border padding-5 circle-radius"}>
    //                   <img src={(this.state.showHelpWidget) ? closeIcon : questionMarkBlue} alt="GC" className="image-15-fit" />
    //                 </div>
    //               </button>
    //             </div>
    //             <div className={(this.state.showVoiceButton) ? "calc-column-offset-190 right-padding" : "calc-column-offset-150 right-padding"}>
    //               <textarea className="text-field description-text-2 clear-border height-30 light-background" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder={(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType !== 'Short Answer' && this.state.customAssessment.questions[this.state.questionIndex].placeholder) ? this.state.customAssessment.questions[this.state.questionIndex].placeholder : "Write a message..."} name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
    //             </div>
    //             <div className="fixed-column-70">
    //               <button className={(this.state.messageDraft && this.state.messageDraft !== '') ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
    //             </div>
    //             <div className="clear" />
    //           </div>
    //
    //           {(this.state.searchIsAnimating) ? (
    //             <div className="flex-container flex-center full-space">
    //               <div>
    //                 <div className="super-spacer" />
    //
    //                 <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
    //                 <div className="spacer" /><div className="spacer" /><div className="spacer" />
    //                 <p className="center-text cta-color bold-text">Searching...</p>
    //
    //               </div>
    //             </div>
    //           ) : (
    //             <div>
    //               <div>
    //                 {(this.state.orgOptions && this.state.orgOptions.length > 0) && (
    //                   <div className="cta-border padding-20 top-margin bottom-margin">
    //                     {this.state.orgOptions.map((value, optionIndex) =>
    //                       <div key={value._id} className="left-text bottom-margin-5 full-width">
    //                         <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'org')}>
    //                           <div className="full-width">
    //                             <div className="fixed-column-40">
    //                               <div className="mini-spacer" />
    //                               <img src={industryIcon} alt="GC" className="image-auto-22" />
    //                             </div>
    //                             <div className="calc-column-offset-40">
    //                               <p className="cta-color">{value.orgName}</p>
    //                             </div>
    //                           </div>
    //                         </button>
    //                       </div>
    //                     )}
    //                   </div>
    //                 )}
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       )}
    //
    //       {((this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Reset Welcome Prompt') || (fromPromptDesigner)) && (
    //         <div className="horizontal-padding-4">
    //           <button className="background-button full-width left-text row-10" onClick={() => this.resetWelcomeMessage(fromPromptDesigner)}>
    //             <div className="fixed-column-30">
    //               <img className="image-auto-20" alt="GC" src={reloadIconBlue} />
    //             </div>
    //             <div className="calc-column-offset-30">
    //               <p className="description-text-1 cta-color">{(fromPromptDesigner) ? "Back to Prompt Designer" : "Reset the Welcome Message"}</p>
    //             </div>
    //             <div className="clear" />
    //           </button>
    //         </div>
    //       )}
    //     </div>
    //   )
    // }

    renderSelectedResponse() {
      console.log('renderSelectedResponse called')

      let promptName = null
      let promptResponse = null
      if (this.props.scriptQuestionId) {
        console.log('A-1')
        if (this.state.messages && this.state.messages.length > 0) {
          console.log('A0-')
          for (let i = 1; i <= this.state.messages.length; i++) {
            console.log('A1-', this.state.messages[i - 1].questionId, this.props.scriptQuestionId)
            if (this.state.messages[i - 1].questionId === this.props.scriptQuestionId) {
              console.log('A2-')
              if (!promptName) {
                promptName = this.state.messages[i - 1].message
              } else {
                promptResponse = this.state.messages[i - 1].message
              }
              // <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
            }
          }
        }
      }

      // {((window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) ? (value.senderUsername === this.state.activeOrg) : (value.senderEmail === this.state.emailId)) ? (
      //   <div className="left-padding full-width top-padding">
      //     <div className="fixed-column-50">
      //       <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
      //     </div>
      //
      //     <div className="calc-column-offset-90">
      //       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : (value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className="background-button full-width standard-color">
      //         <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
      //       </Link>
      //
      //       <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>
      //
      //     </div>
      //     <div className="fixed-column-40">
      //       <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
      //       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : '/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
      //         <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
      //       </Link>
      //     </div>
      //     <div className="clear" />
      //   </div>
      // ) : (
      //   <div className="left-padding full-width top-padding">
      //     <div className="fixed-column-40">
      //       <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
      //       <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
      //         <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
      //       </Link>
      //     </div>
      //     <div className="calc-column-offset-40">
      //       <div>
      //         <div className="calc-column-offset-50">
      //           <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
      //             <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
      //           </Link>
      //         </div>
      //         <div className="fixed-column-50">
      //           <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
      //         </div>
      //         <div className="clear" />
      //       </div>
      //
      //       <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>
      //
      //     </div>
      //     <div className="clear" />
      //   </div>
      // )}

      return (
        <div key="renderSelectedResponse">
          <div className="row-10">
            <p className="bold-text description-text-2">{promptName}</p>
          </div>

          <div className="row-10">
            <div dangerouslySetInnerHTML={{__html: this.renderMessage(promptResponse)}}></div>
          </div>
        </div>
      )
    }

    // toggleMessageMenu(index) {
    //   console.log('toggleMessageMenu', index)
    //
    //   let messages = this.state.messages
    //   messages[index]['showMenu'] = !this.state.messages[index].showMenu
    //   this.setState({ messages })
    //
    // }

    // renderMessageMenu(cu, index) {
    //   console.log('renderMessageMenu called')
    //
    //   return (
    //     <div>
    //       <div className={(cu) ? "top-margin-20 padding-10 white-background rounded-corners float-right" : "top-margin-20 padding-10 white-background rounded-corners right-margin-20 float-left"}>
    //         <button className={(cu) ? "background-button left-margin-20" : "background-button right-margin-20"} onClick={() => this.importToProfile(index,'notes')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import to Notes">
    //           <img src={coverLetterIconDark} className="image-auto-16" alt="GC" />
    //         </button>
    //         <button className={(cu) ? "background-button left-margin-20" : "background-button right-margin-20"} onClick={() => this.toggleDocumentsWidget(index)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Import to Documents">
    //           <img src={resumeIconDark} className="image-auto-16" alt="GC" />
    //         </button>
    //       </div>
    //
    //       {(this.state.messages[index].showDocumentsMenu) && (
    //         <div>
    //           <div className="clear" />
    //
    //           <div className={(cu) ? "top-margin-20 padding-10 white-background rounded-corners float-right" : "top-margin-20 padding-10 white-background rounded-corners right-margin-20 float-left"}>
    //             <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
    //               <label className="profile-label">Document Type<label className="error-color bold-text">*</label></label>
    //               <select name="docType" className="dropdown description-text-2" value={this.state.docType} onChange={this.formChangeHandler}>
    //                 {this.state.importDocTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
    //               </select>
    //             </div>
    //             <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
    //               <label className="profile-label">Select an Existing Document?<label className="error-color bold-text">*</label></label>
    //               <select name="existingDoc" className="dropdown description-text-2" value={this.state.existingDoc} onChange={this.formChangeHandler}>
    //                 {['','Yes','No'].map(value => <option key={value} value={value}>{value}</option>)}
    //               </select>
    //             </div>
    //
    //             {(this.state.docType === 'Resume') && (
    //               <div className={(cu) ? "float-right left-padding-20" : "float-left right-padding-20"}>
    //                 <label className="profile-label">Select What Section to Import<label className="error-color bold-text">*</label></label>
    //               </div>
    //             )}
    //
    //             {(this.state.existingDoc === 'Yes') && (
    //               <div className={(cu) ? "float-right" : "float-left"}>
    //                 <label className="profile-label">Select a Saved Document<label className="error-color bold-text">*</label></label>
    //                 <select name="selectedDocument" className="dropdown description-text-2" value={(this.state.selectedDocument) ? this.state.selectedDocument.fileName : ""} onChange={this.formChangeHandler}>
    //                   {this.state.docOptions.map(value => <option key={value.fileName} value={value.fileName}>{value.fileName}</option>)}
    //                 </select>
    //               </div>
    //             )}
    //
    //             {(this.state.existingDoc === 'No') && (
    //               <div className={(cu) ? "float-right" : "float-left"}>
    //                 <label className="profile-label">Name the New Document<label className="error-color bold-text">*</label></label>
    //                 <input className="text-field description-text-2 height-40" type="text" placeholder="(e.g., My First Resume)..." name="fileName" value={this.state.fileName} onChange={this.formChangeHandler} />
    //               </div>
    //             )}
    //             <div className="clear" />
    //
    //             <div className="top-margin">
    //               <button className="btn btn-squarish" onClick={() => this.importToProfile(index,'documents')}>Import</button>
    //             </div>
    //
    //           </div>
    //         </div>
    //       )}
    //       <div className="clear" />
    //     </div>
    //   )
    // }

    // toggleDocumentsWidget(index) {
    //   console.log('toggleDocumentsWidget called', index)
    //
    //   let messages = this.state.messages
    //   messages[index]['showDocumentsMenu'] = !this.state.messages[index].showDocumentsMenu
    //   this.setState({ messages })
    //
    //   if (!this.state.docOptions || this.state.docOptions.length === 0) {
    //     // const onlyUpcoming = true
    //     Axios.get('/api/documents', { params: { emailId: this.state.emailId }})
    //     .then((response) => {
    //       console.log('Documents query worked', response.data);
    //
    //       if (response.data.success && response.data.documents && response.data.documents.length > 0) {
    //
    //         const docOptions = response.data.documents
    //         const selectedDocument = docOptions[0]
    //         this.setState({ docOptions, selectedDocument })
    //
    //       } else {
    //         console.log('no documents data found', response.data.message)
    //         this.setState({ docOptions: [] })
    //       }
    //
    //     }).catch((error) => {
    //         console.log('Documents query did not work', error);
    //     });
    //   }
    //
    // }

    // importToProfile(index, type) {
    //   console.log('importToProfile called')
    //
    //   this.setState({ isSaving: true, errorMessage: null, successMessage: null })
    //
    //   let messages = this.state.messages
    //   messages[index]['showMenu'] = !this.state.messages[index].showMenu
    //
    //   let postURL = null
    //   let postObject = {}
    //   if (type === 'notes') {
    //     messages[index]['importedToNotes'] = true
    //     messages[index]['importConfirmationMessage'] = 'You have successfully imported this message into your notes!'
    //     postURL = '/api/logs/notes'
    //
    //     const _id = this.state._id
    //     const creatorEmail = this.state.emailId
    //     const creatorFirstName = this.state.cuFirstName
    //     const creatorLastName = this.state.cuLastName
    //     const creatorUsername = this.state.username
    //     const creatorPictureURL = this.state.pictureURL
    //     const creatorRoleName = this.state.roleName
    //     const logType = 'Notes'
    //     const generalNotes = messages[index].message
    //     const orgCode = this.state.activeOrg
    //     const createdAt = new Date()
    //     const updatedAt = new Date()
    //
    //     postObject = {
    //       creatorEmail, creatorFirstName, creatorLastName, creatorUsername, creatorPictureURL,
    //       creatorRoleName, logType, generalNotes, orgCode, createdAt, updatedAt
    //     }
    //   } else {
    //     messages[index]['importedToDocuments'] = true
    //     messages[index]['importConfirmationMessage'] = 'You have successfully imported this message into your notes!'
    //     postURL = '/api/documents/save'
    //
    //     const _id = this.state._id
    //
    //     const email = this.state.emailId
    //     const firstName = this.state.cuFirstName
    //     const lastName = this.state.cuLastName
    //     const username = this.state.username
    //     const pictureURL = this.state.pictureURL
    //     const roleName = this.state.roleName
    //
    //     let docType = this.state.docType
    //
    //     let fileName = this.state.fileName
    //     let selectedSections = ['Summary','Education','Experience','Projects','Awards']
    //     let fileId = this.state.fileId
    //     let slug = null
    //     let docContent = this.state.docContent
    //
    //     if (this.state.selectedDocument && this.state.existingDoc === 'Yes') {
    //       fileName = this.state.selectedDocument.fileName
    //       selectedSections = this.state.selectedDocument.selectedSections
    //       fileId = this.state.selectedDocument._id
    //       docContent = this.state.selectedDocument.docContent
    //       docType = this.state.selectedDocument.docType
    //
    //       if (docType === 'Resume') {
    //         slug = 'resumes'
    //       } else if (docType === "Cover Letter") {
    //         slug = 'cover-letters'
    //         docContent['textBody'] = messages[index].message
    //       } else if (docType === "Career Plan") {
    //         slug = 'career-plans'
    //         if (docContent.sections) {
    //           docContent.sections.push({ title: 'Imported from Chat', body: messages[index].message })
    //         } else {
    //           docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
    //         }
    //       } else if (docType === "Financial Plan") {
    //         slug = 'financial-plans'
    //       } else if (docType === "Business Plan") {
    //         slug = 'business-plans'
    //         docContent['title'] = "My Imported Business Plan"
    //         if (docContent.sections) {
    //           docContent.sections.push({ title: 'Imported from Chat', body: messages[index].message })
    //         } else {
    //           docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
    //         }
    //       } else if (docType === "Mock Interview") {
    //         slug = 'mock-interviews'
    //       }
    //     } else if (this.state.existingDoc === 'No') {
    //       docContent = {}
    //       docContent['docType'] = docType
    //       docContent['docName'] = fileName
    //       docContent['firstName'] = firstName
    //       docContent['lastName'] = lastName
    //       docContent['email'] = email
    //       docContent['username'] = username
    //
    //       docContent['currentDate'] = new Date()
    //       docContent['recipientFirstName'] = 'Jon'
    //       docContent['recipientLastName'] = 'Schmoe'
    //       docContent['recipientOrg'] = 'Schmoe Enterprises'
    //       docContent['addressLine1'] = '123 Schmoe Lane'
    //       docContent['city'] = 'Los Angeles'
    //       docContent['state'] = 'CA'
    //       docContent['zipCode'] = '90210'
    //       docContent['senderFirstName'] = firstName
    //       docContent['senderLastName'] = lastName
    //       docContent['creatorFirstName'] = 'Jon'
    //       docContent['creatorLastName'] = 'Schmoe'
    //
    //       if (docType === 'Resume') {
    //         slug = 'resumes'
    //       } else if (docType === "Cover Letter") {
    //         slug = 'cover-letters'
    //         docContent['textBody'] = messages[index].message
    //       } else if (docType === "Career Plan") {
    //         slug = 'career-plans'
    //         docContent['title'] = "My Imported Career Plan"
    //         docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
    //       } else if (docType === "Financial Plan") {
    //         slug = 'financial-plans'
    //       } else if (docType === "Business Plan") {
    //         slug = 'business-plans'
    //         docContent['title'] = "My Imported Business Plan"
    //         docContent['sections'] = [{ title: 'Imported from Chat', body: messages[index].message }]
    //       } else if (docType === "Mock Interview") {
    //         slug = 'mock-interviews'
    //       }
    //     }
    //
    //     const orgCode = this.state.activeOrg
    //     const createdAt = new Date()
    //     const updatedAt = new Date()
    //
    //     postObject = {
    //       email, firstName, lastName, username, pictureURL, roleName, orgCode,
    //       docType, slug, fileName, selectedSections,
    //       docContent, fileId, createdAt, updatedAt
    //     }
    //   }
    //
    //   // this.setState({ messages })
    //
    //   const createdAt = new Date()
    //   const updatedAt = new Date()
    //
    //   Axios.post(postURL, postObject).then((response) => {
    //     console.log('attempting to improve to profile')
    //     if (response.data.success) {
    //       console.log('successfully imported')
    //
    //       // const successMessage = 'Successfully imported the message to your profile'
    //       this.setState({ isSaving: false, messages })
    //
    //     } else {
    //       console.log('did not successfully update the rankings', response.data.message)
    //       this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
    //     }
    //   }).catch((error) => {
    //       console.log('save rankings did not work', error);
    //       this.setState({ errorMessage: error.toString(), isSaving: false })
    //   });
    // }

    goBack() {
      console.log('goBack called')

      const modalHeading1 = "Compass Assistant"
      const modalHeading2 = (this.state.assistantChatLimit - this.state.assistantChatCount).toLocaleString() + " Response(s) Remaining. Upgrade for More."

      this.setState({ showHelpWidget: false, showImportWidget: false, showBackButton: false, modalHeading1, modalHeading2 })

    }

    renderImportWidget() {
      console.log('renderImportWidget called')

      return (
        <div key="renderImportWidget">
          {(this.state.adminRoleNames.includes(this.state.roleName)) && (
            <div>
              <div className="row-5">
                <label className="profile-label">Select a Member<label className="error-color bold-text">*</label></label>

                <div className="calc-column-offset-70 right-padding">
                  <input className="text-field description-text-2 height-40" type="text" placeholder="Search members..." name="membersSearchText" value={this.state.membersSearchText} onChange={this.formChangeHandler} />
                </div>
                <div className="fixed-column-70">
                  <button className="btn btn-squarish description-text-2" onClick={() => this.addItem('member')}>Add</button>
                </div>
                <div className="clear" />
              </div>

              {(this.state.memberOptions && this.state.membersSearchText && this.state.membersSearchText !== '') && (
                <div>
                  {this.state.memberOptions.map((value, optionIndex2) =>
                    <div key={value._id}>
                      <button className="background-button full-width left-text" onClick={() => this.searchItemClicked(value, 'member')}>
                        <div className="left-padding full-width row-5">
                          <div className="fixed-column-50">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                          </div>
                          <div className="calc-column-offset-50 top-padding-5">
                            <p>{value.firstName} {value.lastName}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(this.state.searchIsAnimating) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Pulling search results...</p>

                  </div>
                </div>
              ) : (
                <div className="row-10">
                  {(this.state.selectedMember) && (
                    <div>
                      <div className="close-button-container-1" >
                        <button className="background-button" onClick={() => this.setState({ selectedMember: null })}>
                          <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="background-button float-left right-padding-5">
                        <div className="half-spacer" />
                        <div className="tag-container-inactive">
                          <div className="fixed-column-40">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <img src={(this.state.selectedMember.pictureURL) ? this.state.selectedMember.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-25" />
                          </div>
                          <div className="calc-column-offset-40 top-padding-5">
                            <p className="description-text-">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName}</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>
          )}
          {(this.state.selectedMember) && (
            <div>
              <div className="row-5">
                <label className="profile-label">Select a Type of Profile Item<label className="error-color bold-text">*</label></label>
                <select name="profileItemType" className="dropdown description-text-2" value={this.state.profileItemType} onChange={this.formChangeHandler}>
                  {this.state.profileItemTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              {(this.state.profileItemType) && (
                <div>
                  {(this.state.isLoading) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <div className="super-spacer" />

                        <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Searching...</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      {(this.state.profileItemOptions && this.state.profileItemOptions.length > 1) ? (
                        <div className="row-5">
                          <label className="profile-label">Select a {(this.state.profileItemType === 'Notes') ? this.state.profileItemType + ' Entry' : this.state.profileItemType}<label className="error-color bold-text">*</label></label>
                          <select name="profileItem" className="dropdown description-text-2" value={this.state.profileItem} onChange={this.formChangeHandler}>
                            {this.state.profileItemOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                          </select>
                        </div>
                      ) : (
                        <div className="row-10 top-margin">
                          {(this.state.errorMessage) ? (
                            <p className="error-color description-text-2">{(this.state.selectedMember && this.state.selectedMember.email === this.state.emailId) ? "You have" : this.state.selectedMember.firstName + " " + this.state.selectedMember.lastName + " has"} no {(this.state.profileItemType === 'Notes') ? this.state.profileItemType.toLowerCase() + ' entrie' : (this.state.profileItemType === 'Career Assessment') ? this.state.profileItemType.toLowerCase() + ' result' : this.state.profileItemType.toLowerCase()}s saved. Save to your profile and then return to import.</p>
                          ) : (
                            <p className="error-color description-text-2">You have no {(this.state.profileItemType === 'Notes') ? this.state.profileItemType.toLowerCase() + ' entrie' : (this.state.profileItemType === 'Career Assessment') ? this.state.profileItemType.toLowerCase() + ' result' : this.state.profileItemType.toLowerCase()}s saved. Save to your profile and then return to import.</p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {(this.state.profileItem) && (
                <div className="row-10 top-margin">
                  <button className="btn btn-squarish right-margin" onClick={() => this.importToMessage(null, 'profileItem')}>Import to Message</button>
                  <button className="btn btn-squarish-white right-margin" onClick={() => this.goBack()}>Cancel</button>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    renderHelpWidget() {
      console.log('renderHelpWidget called')

      return (
        <div key="renderHelpWidget">
          <div className="row-5">
            <p className="description-text-2">Use this A.I. chatbot to summarize, expand, infer, and translate text. Import/export profile information to guide conversations. Scroll down to populate popular A.I. commands.</p>
          </div>
          <div className="row-15">
            <p className="description-text-">A.I. Assistant Capabilities</p>
            <ol className="description-text-2 top-margin-5 left-padding-20">
              <li><b>Summarizing text:</b>: condensing text into a shorter form. (e.g., give me a summary of the book The Great Gatsby that does not exceed 100 words)</li>
              <li><b>Inferring from text:</b>: drawing insights from long form text (e.g., what is the general emotion of these 100+ customer reviews? Is it positive or negative?)</li>
              <li><b>Transforming text:</b>: translating text from one thing to another. (e.g., spell check, grammar check, foreign language translation, data/code translation)</li>
              <li><b>Expanding text:</b>: increasing the word count and expanding upon prompts / commands (e.g., brainstorming, creating an email, creating an article, creating a plan, generating an automatic response based on a customer review)</li>
            </ol>
          </div>
          <div className="row-15">
            <p className="description-text-">Importing/Exporting Profile Information</p>
            <ol className="description-text-2 top-margin-5 left-padding-20">
              <li><b>Importing Profile Information:</b>: import your career assessment results, documents, education, experience, projects, favorites, goals, notes, and more!</li>
              <li><b>Exporting Profile Information:</b>: export A.I. generated content to your notes or to a career document (e.g., resume, cover letter, career plan, business plan, etc...)</li>
            </ol>
          </div>
          <div className="row-15">
            <p className="description-text-">Popular Commands</p>
            {(this.state.popularCommands) && (
              <div className="description-text-2 top-margin-5">
                {this.state.popularCommands.map((item, optionIndex) =>
                  <div key={"commands|" + optionIndex} className="top-margin-30">
                    <div className={(window.innerWidth < 768) ? "calc-column-offset-90 right-padding-20 bottom-margin-20" : "float-left right-padding-20 bottom-margin-20"}>
                      <p>{optionIndex + 1}. {item.name}</p>
                    </div>
                    <div className={(window.innerWidth < 768) ? "fixed-column-90 top-margin-negative-5 bottom-margin-20" : "float-left top-margin-negative-5 bottom-margin-20"}>
                      <button className="btn btn-squarish" onClick={() => this.commandClicked(item)}>Try Me</button>
                    </div>
                    <div className="clear" />

                    <hr />

                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )
    }

    importToMessage(selectedItem, type) {
      console.log('importToMessage called')

      if (type === 'profileItem') {
        for (let i = 1; i <= this.state.profileItemOptions.length; i++) {
          if (this.state.profileItemOptions[i - 1].title === this.state.profileItem) {
            selectedItem = this.state.profileItemOptions[i - 1]
          }
        }
      }

      if (selectedItem) {
        let messageDraft = selectedItem.message
        if (this.state.messageDraft) {
          messageDraft = this.state.messageDraft + ' ' + selectedItem.message
        }
        this.setState({ messageDraft })
        this.goBack()
      }
    }

    commandClicked(item) {
      console.log('commandClicked called')

      this.importToMessage({ message: item.name, type: 'popularCommand' })

    }

    passData(data) {
      console.log('passData called')

      this.setState(data)

    }

    doublePassData() {
      console.log('doublePassData called')

      if (this.props.passData) {
        this.props.passData()
      }
    }

    render() {

      return (
        <div>
          {(this.state.lastUnreadMessage && this.state.promptWelcomeMessage) && (
            <div>
              <button className={(window.innerWidth > 768) ? "background-button position-fixed bottom-0 right-0 bottom-margin-60 right-margin-460 z-index-2 circle-radius medium-shadow transition-ease-1" : "background-button position-fixed bottom-0 right-0 bottom-margin-60 right-margin-negative-40 z-index-2 circle-radius medium-shadow transition-ease-1"} style={{ transform: this.state.transform, opacity: this.state.opacity }} onClick={() => this.setState({ lastUnreadMessage: null })}>
                <img src={deniedIcon} className="image-auto-16" alt="GC" />
              </button>
              <button className={(window.innerWidth > 768) ? "background-button-best card-clear-padding height-63 position-fixed bottom-0 right-0 rounded-corners bottom-margin-20 right-margin-100 z-index-2 width-350 padding-10 medium-shadow transition-ease-1" : "background-button-best card-clear-padding height-63 position-fixed bottom-0 right-0 rounded-corners bottom-margin-20 right-margin-100 z-index-2 calc-column-offset-130 padding-10 medium-shadow transition-ease-1"} style={{ transform: this.state.transform }} onClick={(window.location.pathname.startsWith('/chatgpt')) ? () => this.openModal('showChatBot') : (window.location.pathname.startsWith('/organizations') && this.state.showHumanChatInAdmin) ? () => this.openModal('showChatPanel') : () => this.openModal('showChat')}>
                <p className="description-text-2 left-text">{this.state.lastUnreadMessage}</p>
              </button>
            </div>
          )}

          {(!this.props.hideNav && !this.props.chatOnly && !this.state.showPromptDesigner) && (
            <div className="position-fixed bottom-0 right-0 rounded-corners bottom-margin-20 right-margin-20 z-index-2 transition-ease-1" style={{ opacity: this.state.opacity, transform: this.state.transform }}>
              <button className="background-button float-left rounded-corners medium-shadow" onClick={(this.state.modalIsOpen) ? () => this.closeModal() : (window.location.pathname.startsWith('/chatgpt')) ? () => this.openModal('showChatBot') : (window.location.pathname.startsWith('/organizations') && this.state.showHumanChatInAdmin) ? () => this.openModal('showChatPanel') : () => this.openModal('showChatBot', null, null, null, null, true)}>
                <div className="primary-background rounded-corners padding-15 thick-border-dark">
                  <img src={(this.state.modalIsOpen) ? closeIconWhite : (this.state.loggedIn && !window.location.pathname.startsWith('/organizations')) ? questionMarkWhite : chatbubblesIconWhite} alt="GC" className="image-25-fit" />
                </div>
              </button>
              {((!window.location.pathname.startsWith('/organizations') && this.state.lastUnreadMessage && this.state.promptWelcomeMessage) || (window.location.pathname.startsWith('/organizations') && this.state.showHumanChatInAdmin && this.state.unreadMessageCount)) ? (
                <button className="background-button-white width-30 height-30 flex-container flex-center center-text circle-radius description-text-4 float-left error-background-color top-margin-negative-12 left-margin-negative-18 medium-shadow">{this.state.unreadMessageCount}</button>
              ) : (
                <div />
              )}
              <div className="clear" />

              {(this.state.testSpeechKit) && (
                <div>
                  {/*
                  <SubTextToSpeech passedText={this.state.speakingText} ref={this.child} passData={this.passData} />
                  <SubAvatar animateAvatar={this.state.animateAvatar} />*/}

                </div>
              )}
            </div>
          )}

          {(this.props.chatOnly) && (
            <div className="row-10 horizontal-padding-4">
              {(this.props.showPromptResponse) ? (
                <div>
                  {this.renderSelectedResponse()}
                </div>
              ) : (
                <div>
                  {(this.state.useSubRenderChat) ? (
                    <div>
                      <SubRenderChat messages={this.state.messages} recipients={[this.state.recipient]}
                        headline={this.state.headline} schoolName={this.state.schoolName} jobTitle={this.state.jobTitle}
                        employerName={this.state.employerName} accountCode={this.state.accountCode}
                        notificationPreferences={this.state.notificationPreferences} room={this.state.room}
                        updateInboxes={null} fromHelpPrompt={true} fromPromptDesigner={null} fromCourse={true}
                        resetWelcomeMessage={this.resetWelcomeMessage}
                        showHumanChatInAdmin={this.state.showHumanChatInAdmin}
                        customAssessment={this.state.customAssessment}
                        questionIndex={this.state.questionIndex}
                        hideAnswerChoices={this.state.hideAnswerChoices}
                        disableFreeformChatbotMessaging={this.state.disableFreeformChatbotMessaging}
                        enableFreeformChatbotMessaging={this.state.enableFreeformChatbotMessaging}
                        openModal={this.openModal} joinRoom={this.joinRoom}
                        showImportWidget={this.state.showImportWidget} showHelpWidget={this.state.showHelpWidget}
                        passData={this.passData} showChat={this.state.showChat} showChatBot={this.state.showChatBot}
                        sessionUserId={this.state.sessionUserId} orgName={this.state.orgName}
                        orgChatEmail={this.state.orgChatEmail} orgLogo={this.state.orgLogo}
                        selectedInbox={this.state.selectedInbox}
                        assistantChatCount={this.state.assistantChatCount} assistantChatLimit={this.state.assistantChatLimit}
                        courseId={this.state.courseId} questionId={this.state.questionId} selectedMember={this.state.selectedMember}
                        doublePassData={this.doublePassData} smartGoalDescription={this.state.smartGoalDescription}
                        smartGoalStatus={this.state.smartGoalStatus} businessIdeaDescription={this.state.businessIdeaDescription}
                        _id={this.state._id} docType={this.state.docType} fileName={this.state.fileName} docContent={this.state.docContent}
                        errorMessage={this.state.errorMessage} successMessage={this.state.successMessage}
                        activelyMessaging={this.state.activelyMessaging} searchIsAnimating={this.state.searchIsAnimating}
                        popularCommands={this.state.popularCommands}
                        passedCommand={this.props.passedCommand} passedCommandDraft={this.props.passedCommandDraft}
                        craftPrompt={this.craftPrompt}
                        docContent={this.props.docContent} emailId={this.state.emailId}
                        cuFirstName={this.state.cuFirstName} cuLastName={this.state.cuLastName}
                        username={this.state.username}

                      />
                    </div>
                  ) : (
                    <div>
                      {/*this.renderChatBox(null, true)*/}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className={(this.state.showPromptDesigner) ? "modal-full-space" : "modal-chat"}
           overlayClassName={(this.state.showPromptDesigner) ? "modal-overlay" : "modal-overlay-none"}
           contentLabel="Example Modal"
           ariaHideApp={false}
         >
           {(this.state.showPromptDesigner) ? (
             <div className="top-margin-60">

              <div className="full-width card-clear-padding flex-container row-direction standard-border">
                {(this.state.showReturnMessage) ? (
                  <div className="full-width calc-height-100-of-100">
                    {(this.state.useSubRenderChat) ? (
                      <div>
                        <SubRenderChat messages={this.state.messages} recipients={[this.state.recipient]}
                          headline={this.state.headline} schoolName={this.state.schoolName} jobTitle={this.state.jobTitle}
                          employerName={this.state.employerName} accountCode={this.state.accountCode}
                          notificationPreferences={this.state.notificationPreferences} room={this.state.room}
                          updateInboxes={null} fromHelpPrompt={true} fromPromptDesigner={true} fromCourse={null}
                          resetWelcomeMessage={this.resetWelcomeMessage}
                          showHumanChatInAdmin={this.state.showHumanChatInAdmin}
                          customAssessment={this.state.customAssessment}
                          questionIndex={this.state.questionIndex}
                          hideAnswerChoices={this.state.hideAnswerChoices}
                          disableFreeformChatbotMessaging={this.state.disableFreeformChatbotMessaging}
                          enableFreeformChatbotMessaging={this.state.enableFreeformChatbotMessaging}
                          openModal={this.openModal} joinRoom={this.joinRoom}
                          showImportWidget={this.state.showImportWidget} showHelpWidget={this.state.showHelpWidget}
                          passData={this.passData} showChat={this.state.showChat} showChatBot={this.state.showChatBot}
                          sessionUserId={this.state.sessionUserId} orgName={this.state.orgName}
                          orgChatEmail={this.state.orgChatEmail} orgLogo={this.state.orgLogo}
                          selectedInbox={this.state.selectedInbox}
                          assistantChatCount={this.state.assistantChatCount} assistantChatLimit={this.state.assistantChatLimit}
                          courseId={this.state.courseId} questionId={this.state.questionId} selectedMember={this.state.selectedMember}
                          doublePassData={this.doublePassData} smartGoalDescription={this.state.smartGoalDescription}
                          smartGoalStatus={this.state.smartGoalStatus} businessIdeaDescription={this.state.businessIdeaDescription}
                          _id={this.state._id} docType={this.state.docType} fileName={this.state.fileName} docContent={this.state.docContent}
                          errorMessage={this.state.errorMessage} successMessage={this.state.successMessage}
                          activelyMessaging={this.state.activelyMessaging} searchIsAnimating={this.state.searchIsAnimating}
                          popularCommands={this.state.popularCommands}
                          passedCommand={this.props.passedCommand} passedCommandDraft={this.props.passedCommandDraft}
                          craftPrompt={this.craftPrompt}
                          docContent={this.props.docContent} emailId={this.state.emailId}
                          cuFirstName={this.state.cuFirstName} cuLastName={this.state.cuLastName}
                          username={this.state.username}
                        />
                      </div>
                    ) : (
                      <div>
                        {/*this.renderChatBox(true)*/}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="full-space">
                    <div className={(this.state.showGradePrompt) ? "relative-column-25 primary-background-extra-light padding-40 calc-height-100-of-100 overflow-y-auto" : "relative-column-40 primary-background-extra-light padding-40 calc-height-100-of-100 overflow-y-auto"}>

                      {(this.state.showGradePrompt) ? (
                        <div>
                          <p className="heading-text-3">Select a Grading Rubric</p>
                          {(this.state.documentType) && (
                            <p className="top-margin description-text-2 description-text-color">Select your grading rubric for the {this.state.documentType.toLowerCase()}</p>
                          )}

                          <div className="spacer" /><div className="spacer" /><div className="spacer" />

                          <div className="row-5">
                            <label className="profile-label">Select a Grading Rubric<label className="error-color bold-text">*</label></label>
                            <select name="gradingRubricName" className="dropdown description-text-2" value={this.state.gradingRubricName} onChange={this.formChangeHandler}>
                              {['','Default Grading Rubric'].map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          {(this.state.documentType !== 'Business Plan') && (
                            <div>
                              <div className="row-5">
                                <label className="profile-label">Target Job Title<label className="error-color bold-text">*</label></label>
                                <input className="text-field description-text-2" type="text" placeholder="(e.g., Software Developer)..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                              </div>

                              <div className="row-5">
                                <label className="profile-label">Target Employer Name<label className="error-color bold-text">*</label></label>
                                <input className="text-field description-text-2" type="text" placeholder="(e.g., Google)..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                              </div>
                            </div>
                          )}

                          <div className="spacer" /><div className="spacer" />

                          <div className="top-margin-20">
                            <button className="btn btn-squarish" onClick={() => this.craftPrompt(this.props.docContent, this.state.jobTitle, this.state.employerName, this.state.smartGoalDescription, this.state.smartGoalStatus, this.state.businessPlanType, this.state.businessIdeaDescription)}>Update Prompt</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <p className="heading-text-3">Design Your Prompt</p>

                          {(this.state.documentType) && (
                            <p className="top-margin description-text-2 description-text-color">{this.state.documentType} Document</p>
                          )}
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />

                          <div>
                            {(this.state.documentType === 'Resume' || this.state.documentType === 'Cover Letter' || this.state.documentType === 'Mock Interview Doc') && (
                              <div>
                                <div className="row-5">
                                  <div className="container-left">
                                    <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
                                    <input className="text-field description-text-2" type="text" placeholder="(e.g., Software Developer)..." name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
                                    <input className="text-field description-text-2" type="text" placeholder="(e.g., Google)..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            )}

                            {(this.state.documentType === 'Career Plan' || this.state.documentType === 'Financial Plan') && (
                              <div>
                                <div className="row-5">
                                  <label className="profile-label">SMART Goal (Optional)</label>
                                  <textarea className="text-field description-text-2" type="text" placeholder="becoming an entry-level software Developer at Google within 10 years..." name="smartGoalDescription" value={this.state.smartGoalDescription} onChange={this.formChangeHandler} />
                                </div>

                                <div className="row-5">
                                  <label className="profile-label">Current Status (Optional)</label>
                                  <textarea className="text-field description-text-2" type="text" placeholder="being a 10th grade student at Lamar High School..." name="smartGoalStatus" value={this.state.smartGoalStatus} onChange={this.formChangeHandler} />
                                </div>
                              </div>
                            )}

                            {(this.state.documentType === 'Business Plan') && (
                              <div>
                                <div className="row-5">
                                  <label className="profile-label">New or Existing<label className="error-color bold-text">*</label></label>
                                  <select name="businessPlanType" className="dropdown description-text-2" value={this.state.businessPlanType} onChange={this.formChangeHandler}>
                                    {['','Create and Detail a New Business Idea','Detail My Existing Business Idea'].map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                </div>
                                {(this.state.businessPlanType === 'Detail My Existing Business Idea') && (
                                  <div>
                                    <div className="row-5">
                                      <label className="profile-label">My Business Idea<label className="error-color bold-text">*</label></label>
                                      <textarea className="text-field description-text-2" type="text" placeholder="detail the problem, solution, and market for your business idea..." name="businessIdeaDescription" value={this.state.businessIdeaDescription} onChange={this.formChangeHandler} />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="standard-border padding-20 top-margin-20">
                              {/*
                              {(this.state.documentType) && (
                                <div className="row-5">
                                  <div className="calc-column-offset-70">
                                    <p className="">Include Previous {this.state.documentType}s</p>
                                  </div>
                                  <div className="fixed-column-70 right-text">
                                    <Switch
                                      onChange={(change) => this.setState({ includeInNotifications: change, formHasChanged: true })}
                                      checked={this.state.includeInNotifications}
                                      id="normal-switch"
                                    />
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}*/}

                              {(this.state.documentType === 'Resume' || this.state.documentType === 'Cover Letter' || this.state.documentType === 'Career Plan' || this.state.documentType === 'Mock Interview Doc') && (
                                <div className="row-5">
                                  <div className="calc-column-offset-70">
                                    <p className="">Include My Self-Assessed Skills</p>
                                  </div>
                                  <div className="fixed-column-70 right-text">
                                    <Switch
                                      onChange={(change) => this.setState({ includeSkills: change })}
                                      checked={this.state.includeSkills}
                                      id="normal-switch"
                                    />
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}
                              {(this.state.documentType !== 'Financial Plan' && this.state.documentType !== 'Business Plan' && this.state.documentType !== 'Mock Interview Doc') && (
                                <div>
                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Endorsements</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeEndorsements: change, formHasChanged: true })}
                                        checked={this.state.includeEndorsements}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                              {(this.state.documentType !== 'Financial Plan') && (
                                <div>
                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Education & Coursework</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeEducation: change })}
                                        checked={this.state.includeEducation}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Projects & Experience</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeExperience: change })}
                                        checked={this.state.includeExperience}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}

                              {(this.state.documentType === 'Career Plan' || this.state.documentType === 'Financial Plan' || this.state.documentType === 'Business Plan') && (
                                <div>
                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Goals</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeGoals: change, formHasChanged: true })}
                                        checked={this.state.includeGoals}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                </div>
                              )}

                              {(this.state.documentType === 'Career Plan' || this.state.documentType === 'Business Plan') && (
                                <div>
                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Career Assessments</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeAssessments: change, formHasChanged: true })}
                                        checked={this.state.includeAssessments}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                  <div className="row-5">
                                    <div className="calc-column-offset-70">
                                      <p className="">Include Sections</p>
                                    </div>
                                    <div className="fixed-column-70 right-text">
                                      <Switch
                                        onChange={(change) => this.setState({ includeSections: change, formHasChanged: true })}
                                        checked={this.state.includeSections}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                </div>
                              )}

                              <div className="row-5">
                                <div className="calc-column-offset-70">
                                  <p className="">Include Previous Feedback</p>
                                </div>
                                <div className="fixed-column-70 right-text">
                                  <Switch
                                    onChange={(change) => this.setState({ includeFeedback: change, formHasChanged: true })}
                                    checked={this.state.includeFeedback}
                                    id="normal-switch"
                                  />
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>

                          <div className="top-margin-20">
                            <button className="btn btn-squarish" onClick={() => this.craftPrompt(this.props.docContent, this.state.jobTitle, this.state.employerName, this.state.smartGoalDescription, this.state.smartGoalStatus, this.state.businessPlanType, this.state.businessIdeaDescription)}>Generate Prompt</button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={(this.state.showGradePrompt) ? "relative-column-75 padding-40 calc-height-100-of-100 overflow-y-auto" : "relative-column-60 padding-40 calc-height-100-of-100"}>
                      <div>
                        <div className="calc-column-offset-30">
                          <p className="heading-text-3">Your Prompt</p>
                        </div>
                        <div className="fixed-column-30">
                          <button className="background-button full-width top-margin-3" onClick={() => this.closeModal()}>
                            <img src={deniedIcon} alt="GC" className="image-auto-20 pin-right" />
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.showGradePrompt) ? (
                        <div>
                          <p className="description-text-1 description-text-color top-margin">Edit the grading criteria to grade the {(this.state.documentType) ? this.state.documentType.toLowerCase() : "resume"}, then submit it to the A.I.</p>

                          <div>
                            {(this.props.docContent) && (
                              <div className="relative-column-33 standard-border padding-20 light-background top-margin-30">
                                <p className="description-text-3">{this.state.documentType}</p>
                                <p className="top-margin-5 description-text-1 bold-text">{(this.props.docContent.docName) ? this.props.docContent.docName + ".pdf" : this.props.docContent.firstName + "_" + this.props.docContent.lastName + ".pdf"}</p>
                              </div>
                            )}

                            {(this.state.jobTitle && this.state.documentType !== 'Business Plan') && (
                              <div className="relative-column-33 standard-border padding-20 light-background top-margin-30">
                                <p className="description-text-3">Job Title</p>
                                <p className="top-margin-5 description-text-1 bold-text">{this.state.jobTitle}</p>
                              </div>
                            )}

                            {(this.state.employerName && this.state.documentType !== 'Business Plan') && (
                              <div className="relative-column-33 standard-border padding-20 light-background top-margin-30">
                                <p className="description-text-3">Employer Name</p>
                                <p className="top-margin-5 description-text-1 bold-text">{this.state.employerName}</p>
                              </div>
                            )}
                            <div className="clear" />
                          </div>

                          <div className="top-margin-40">
                            {(this.state.selectedGradingRubric && this.state.selectedGradingRubric.criteria && this.state.selectedGradingRubric.criteria.length > 0) && (
                              <div>
                                <div className="darker-background white-text description-text-2 bold-text">
                                  <div className="fixed-column-150 padding-20 bold-text standard-border">
                                    <p>Criteria</p>
                                  </div>
                                  <div className="calc-column-offset-150">
                                    <div className="relative-column-25 padding-20 standard-border">
                                      <p>Outstanding</p>
                                    </div>
                                    <div className="relative-column-25 padding-20 standard-border">
                                      <p>Good</p>
                                    </div>
                                    <div className="relative-column-25 padding-20 standard-border">
                                      <p>Average</p>
                                    </div>
                                    <div className="relative-column-25 padding-20 standard-border">
                                      <p>Unsatisfactory</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                  <div className="clear" />
                                </div>
                                {this.state.selectedGradingRubric.criteria.map((item, optionIndex) =>
                                  <div key={"gradingRubricCriteria|" + optionIndex} className="description-text-2 standard-border">
                                    <div className="fixed-column-150 padding-10 bold-text standard-border">
                                      <textarea className="text-field height-150 clear-border" type="text" placeholder="Write your prompt to the A.I." name={"criteria|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler} />
                                    </div>
                                    <div className="calc-column-offset-150">
                                      <div className="relative-column-25 padding-10 standard-border">
                                        <textarea className="text-field height-150 clear-border" type="text" placeholder="Write your prompt to the A.I." name={"criteria|criteria1|" + optionIndex} value={item.oneHundredPercentCriteria} onChange={this.formChangeHandler} />
                                      </div>
                                      <div className="relative-column-25 padding-10 standard-border">
                                        <textarea className="text-field height-150 clear-border" type="text" placeholder="Write your prompt to the A.I." name={"criteria|criteria2|" + optionIndex} value={item.seventyFivePercentCriteria} onChange={this.formChangeHandler} />
                                      </div>
                                      <div className="relative-column-25 padding-10 standard-border">
                                        <textarea className="text-field height-150 clear-border" type="text" placeholder="Write your prompt to the A.I." name={"criteria|criteria3|" + optionIndex} value={item.fiftyPercentCriteria} onChange={this.formChangeHandler} />
                                      </div>
                                      <div className="relative-column-25 padding-10 standard-border">
                                        <textarea className="text-field height-150 clear-border" type="text" placeholder="Write your prompt to the A.I." name={"criteria|criteria4|" + optionIndex} value={item.twentyFivePercentCriteria} onChange={this.formChangeHandler} />
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          {(this.state.showGradePromptSpecifics) && (
                            <div className="top-margin-20">
                              <p className="keep-line-breaks">{this.state.messageDraft}</p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="description-text-1 description-text-color top-margin">Edit the generated prompt and submit it to the A.I.</p>

                          <div className="top-margin-40">
                            <textarea className="text-field calc-height-340-of-100" type="text" placeholder="Write your prompt to the A.I." name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                          </div>
                        </div>
                      )}

                      <div className="top-margin-20 right-text">
                        <button className="btn btn-squarish" onClick={() => this.sendMessage()}>Submit</button>
                      </div>

                    </div>
                    <div className="clear" />
                  </div>
                )}
              </div>
             </div>
           ) : (
             <div>
               <div>
                 <div className="dark-background padding-15">
                    {(this.state.showBackButton) && (
                      <div className="fixed-column-25">
                        <button className="background-button top-margin-3" onClick={() => this.goBack()}>
                          <img src={backButtonWhite} alt="GC" className="image-12-fit rotate-180" />
                        </button>
                      </div>
                    )}
                   <div className={(this.state.showChat || (this.state.showChatBot && !window.location.pathname.includes('/chatgpt')) || this.state.showTicketWindow) ? "calc-column-offset-25" : "full-width"}>
                     <button className={(this.state.showChatBot) ? "background-button full-width left-text" : "background-button full-width left-text no-pointers"} disabled={(this.state.showChatBot) ? false : true} onClick={() => window.open('/assistant')}>
                       <p className="heading-text-6 white-text">{this.state.modalHeading1}</p>
                       {(this.state.modalHeading2) && (
                         <p className="top-padding-5 description-text-3 white-text">{this.state.modalHeading2}</p>
                       )}
                       {(this.state.modalHeading3) && (
                         <p className="top-padding-5 description-text-3 white-text">{this.state.modalHeading3}</p>
                       )}
                     </button>
                   </div>
                   {((!window.location.pathname.includes('/organizations') || (window.location.pathname.includes('/organizations') && !this.state.showHumanChatInAdmin)) && (this.state.showChat || (this.state.showChatBot && !this.state.showBackButton && !window.location.pathname.includes('/chatgpt'))) && (!this.state.useSubRenderChat)) && (
                     <div className="fixed-column-25">
                       <button className="background-button top-margin-3 full-width" onClick={() => this.resetWelcomeMessage()}>
                         <img src={reloadIconWhite} alt="GC" className="image-18-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reset the Welcome Prompt" />
                       </button>
                     </div>
                   )}
                   {(this.state.showCancelButton) && (
                     <div className="fixed-column-25">
                       <button className="background-button top-margin-3 full-width" onClick={() => this.setState({ showChatBot: true })}>
                         <img src={closeIconWhite} alt="GC" className="image-18-fit pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reset the Welcome Prompt" />
                       </button>
                     </div>
                   )}

                   {(this.state.showTicketWindow) && (
                     <div className="fixed-column-25">
                       <button className="background-button top-margin-3 full-width" onClick={() => this.openModal('showChat')}>
                         <img src={deniedIcon} className="image-auto-16 pin-right" alt="GC" />
                       </button>
                     </div>
                   )}
                   <div className="clear" />
                 </div>

                 {/*<ReactTooltip id="tooltip-placeholder-id" />*/}

                {(this.state.showHelpPrompt) && (
                  <div key="showHelpPrompt" className="full-width">
                    <div className="spacer" />

                    <div className="left-padding-30 right-padding-30 bottom-padding-30">
                      {(this.state.helpOptions) && (
                        <div>
                          {this.state.helpOptions.map((item, optionIndex) =>
                            <div key={"helpOptions|" + optionIndex}>
                              <button className="background-button full-width left-text row-20" onClick={() => this.buttonClicked(item)}>
                                <div className="fixed-column-40">
                                  <img src={item.icon} alt="GC" className="image-auto-25" />
                                </div>
                                <div className="calc-column-offset-80">
                                  <p>{item.name}</p>
                                </div>
                                <div className="fixed-column-40">
                                  <img src={(item.url) ? linkIconDark : arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right" />
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(this.state.showTicketWindow) && (
                  <div key="showTicketWindow" className="full-width">

                    <div className="spacer" />

                    <div className="left-padding-30 right-padding-30 bottom-padding-30">
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Title<label className="error-color bold-text">*</label></label>
                          <input className="text-field" type="text" placeholder="Title the issue..." name="name" value={this.state.name} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Urgency<label className="error-color bold-text">*</label></label>
                          <select name="urgency" className="dropdown" value={this.state.urgency} onChange={this.formChangeHandler}>
                            {this.state.urgencyOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      {(this.state.ticketCategories && this.state.ticketCategories.length > 0) && (
                        <div className="row-10">
                          <label className="profile-label">Ticket Type<label className="error-color bold-text">*</label></label>
                          <select name="category" className="dropdown" value={this.state.category} onChange={this.formChangeHandler}>
                            {this.state.ticketCategories.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                          </select>
                        </div>
                      )}
                      <div className="row-10">
                        <label className="profile-label">Description<label className="error-color bold-text">*</label></label>
                        <textarea className="text-field" type="text" placeholder="Add a description" name="description" value={this.state.description} onChange={this.formChangeHandler} />
                      </div>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      <div className="row-10">
                        <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.submitTicket()}>Submit</button>
                      </div>
                    </div>
                  </div>
                )}

                {(this.state.showChat || this.state.showChatBot) && (
                  <div>
                    {(this.state.showBackButton) ? (
                      <div className="padding-15 calc-height-340-of-100">
                        {(this.state.showImportWidget) && (
                          <div>
                            {this.renderImportWidget()}
                          </div>
                        )}
                        {(this.state.showHelpWidget) && (
                          <div>
                            {this.renderHelpWidget()}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {(this.state.useSubRenderChat) ? (
                          <div>
                            <SubRenderChat messages={this.state.messages} recipients={[this.state.recipient]}
                              headline={this.state.headline} schoolName={this.state.schoolName} jobTitle={this.state.jobTitle}
                              employerName={this.state.employerName} accountCode={this.state.accountCode}
                              notificationPreferences={this.state.notificationPreferences} room={this.state.room}
                              updateInboxes={null} fromHelpPrompt={true} fromPromptDesigner={null} fromCourse={null}
                              resetWelcomeMessage={this.resetWelcomeMessage}
                              showHumanChatInAdmin={this.state.showHumanChatInAdmin}
                              customAssessment={this.state.customAssessment}
                              questionIndex={this.state.questionIndex}
                              hideAnswerChoices={this.state.hideAnswerChoices}
                              disableFreeformChatbotMessaging={this.state.disableFreeformChatbotMessaging}
                              enableFreeformChatbotMessaging={this.state.enableFreeformChatbotMessaging}
                              openModal={this.openModal} joinRoom={this.joinRoom}
                              showImportWidget={this.state.showImportWidget} showHelpWidget={this.state.showHelpWidget}
                              passData={this.passData} showChat={this.state.showChat} showChatBot={this.state.showChatBot}
                              sessionUserId={this.state.sessionUserId} orgName={this.state.orgName}
                              orgChatEmail={this.state.orgChatEmail} orgLogo={this.state.orgLogo}
                              selectedInbox={this.state.selectedInbox}
                              assistantChatCount={this.state.assistantChatCount} assistantChatLimit={this.state.assistantChatLimit}
                              courseId={this.state.courseId} questionId={this.state.questionId} selectedMember={this.state.selectedMember}
                              doublePassData={this.doublePassData} smartGoalDescription={this.state.smartGoalDescription}
                              smartGoalStatus={this.state.smartGoalStatus} businessIdeaDescription={this.state.businessIdeaDescription}
                              _id={this.state._id} docType={this.state.docType} fileName={this.state.fileName} docContent={this.state.docContent}
                              errorMessage={this.state.errorMessage} successMessage={this.state.successMessage}
                              activelyMessaging={this.state.activelyMessaging} searchIsAnimating={this.state.searchIsAnimating}
                              popularCommands={this.state.popularCommands}
                              passedCommand={this.props.passedCommand} passedCommandDraft={this.props.passedCommandDraft}
                              craftPrompt={this.craftPrompt}
                              docContent={this.props.docContent} emailId={this.state.emailId}
                              cuFirstName={this.state.cuFirstName} cuLastName={this.state.cuLastName}
                              username={this.state.username}
                            />
                          </div>
                        ) : (
                          <div>
                            {/*this.renderChatBox()*/}
                          </div>
                        )}
                      </div>
                    )}
                    {/*
                    <div>
                      {(this.state.messages && this.state.messages.length > 0) ? (
                        <div className="row-10 standard-border horizontal-padding-4 calc-height-340-of-100">
                          <div className="full-space overflow-y-auto">
                            {this.state.messages.map((value, optionIndex) =>
                              <div key={value._id} className="left-text full-width bottom-padding-20">
                                {((window.location.pathname.includes('/organizations') && this.state.showHumanChatInAdmin) ? (value.senderUsername === this.state.activeOrg) : (value.senderEmail === this.state.emailId)) ? (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-50">
                                      <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                    </div>

                                    <div className="calc-column-offset-90">
                                      <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : (value.senderUsername) ? '/' + value.senderUsername + '/profile/' : '/'} target="_blank" className="background-button full-width standard-color">
                                        <p className="description-text-2 bold-text full-width right-text">{(value.senderFirstName) ? value.senderFirstName + ' ' + value.senderLastName : "Your Name"}</p>
                                      </Link>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message,true)}}></div>

                                    </div>
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : '/' + value.senderUsername + '/profile'} target="_blank" className="background-button full-width standard-color">
                                        <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 pin-right" />
                                      </Link>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <div className="left-padding full-width top-padding">
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                        <img src={(value.senderPictureURL) ? value.senderPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                      </Link>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <div>
                                        <div className="calc-column-offset-50">
                                          <Link to={(this.state.orgLogo === value.senderPictureURL) ? '/partners/' + value.orgCode : this.state.prefix + '/profile/' + value.senderUsername} target="_blank" className="background-button full-width standard-color">
                                            <p className="description-text-2 bold-text">{value.senderFirstName} {value.senderLastName}</p>
                                          </Link>
                                        </div>
                                        <div className="fixed-column-50">
                                          <p className="description-text-5 bold-text description-text-color top-padding">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      <div dangerouslySetInnerHTML={{__html: this.renderMessage(value.message)}}></div>

                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />

                            {(this.state.activelyMessaging) && (
                              <img src={activelyMessagingGif} alt="GC" className="image-auto-30" />
                            )}
                            {(this.state.awaitingConnection) && (
                              <img src={callWaitingGIF} alt="GC" className="image-auto-80 pin-right" />
                            )}

                            {(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Multiple Choice' && !this.state.hideAnswerChoices) && (
                              <div className="flex-container row-direction">
                                <div className="flex-25">
                                </div>
                                <div className="flex-75 flex-container row-direction justify-end flex-wrap">
                                  {this.state.customAssessment.questions[this.state.questionIndex].answerChoices.map((item2, optionIndex2) =>
                                    <div key={"answerChoice|" + optionIndex2}>
                                      <button className="background-button" onClick={() => this.itemClicked('Multiple Choice',item2, optionIndex2)}>
                                        <div className="row-7 horizontal-padding-5 description-text-3 cta-border cta-color slightly-rounded-corners left-margin bottom-margin">
                                          <p>{item2}</p>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            <div ref={(el) => { this.messagesEnd = el; }} />
                          </div>
                        </div>
                      ) : (
                        <div className="row-10 standard-border horizontal-padding-4 calc-height-400-of-95">
                          {(this.state.recipient) && (
                            <div className="padding-30">
                              <img src={(this.state.recipient.pictureURL) ? this.state.recipient.pictureURL : profileIconDark} className="profile-thumbnail-4 center-item" alt="GC" />
                              <div>
                                <p className="full-width top-padding heading-text-6 horizontal-padding center-text">{this.state.recipient.firstName} {this.state.recipient.lastName}</p>
                                <p className="full-width description-text-3 center-text top-padding">The {(this.state.showChatBot) ? "Compass Assistant is here to help" : (this.state.orgName) ? this.state.orgName + " is here to help" : "Guided Compass is here to help"}.</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-3 error-color row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-3 cta-color row-5">{this.state.successMessage}</p>}

                    {((!this.state.customAssessment && !this.state.disableFreeformChatbotMessaging) || (this.state.enableFreeformChatbotMessaging) || (this.state.customAssessment && (this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Short Answer' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Human' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Chat with Casper' || this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Search Organizations') && !this.state.disableFreeformChatbotMessaging)) && (
                      <div className="horizontal-padding-4">
                        <div className="row-10">
                          {(this.state.showVoiceButton) && (
                            <div className="fixed-column-40">
                              <SubSpeechToText passData={this.passData} />
                            </div>
                          )}
                          <div className={(this.state.showVoiceButton) ? "calc-column-offset-110" : "calc-column-offset-70"}>
                            <input className="text-field description-text-2 clear-border" type="text" onKeyPress={(e) => (e.key === 'Enter') ? this.sendMessage() : console.log('key clicked')} placeholder={(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType !== 'Short Answer' && this.state.customAssessment.questions[this.state.questionIndex].placeholder) ? this.state.customAssessment.questions[this.state.questionIndex].placeholder : "Write a message..."} name="messageDraft" value={this.state.messageDraft} onChange={this.formChangeHandler} />
                          </div>
                          <div className="fixed-column-70">
                            <button className={(this.state.messageDraft && this.state.messageDraft !== '') ? "btn btn-small" : "btn btn-small medium-background standard-border"} disabled={(this.state.messageDraft && this.state.messageDraft !== '' && !this.state.isSaving) ? false : true} onClick={() => this.sendMessage()}>Send</button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.searchIsAnimating) ? (
                          <div className="flex-container flex-center full-space">
                            <div>
                              <div className="super-spacer" />

                              <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                              <div className="spacer" /><div className="spacer" /><div className="spacer" />
                              <p className="center-text cta-color bold-text">Searching...</p>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {(this.state.orgOptions && this.state.orgOptions.length > 0) && (
                                <div className="cta-border padding-20 top-margin bottom-margin">
                                  {this.state.orgOptions.map((value, optionIndex) =>
                                    <div key={value._id} className="left-text bottom-margin-5 full-width">
                                      <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'org')}>
                                        <div className="full-width">
                                          <div className="fixed-column-40">
                                            <div className="mini-spacer" />
                                            <img src={industryIcon} alt="GC" className="image-auto-22" />
                                          </div>
                                          <div className="calc-column-offset-40">
                                            <p className="cta-color">{value.orgName}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.customAssessment && this.state.customAssessment.questions[this.state.questionIndex].questionType === 'Reset Welcome Prompt') && (
                      <div className="horizontal-padding-4">
                        <button className="background-button full-width left-text row-10" onClick={() => this.resetWelcomeMessage()}>
                          <div className="fixed-column-30">
                            <img className="image-auto-20" alt="GC" src={reloadIconBlue} />
                          </div>
                          <div className="calc-column-offset-30">
                            <p className="description-text-1 cta-color">Reset the Welcome Message</p>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>
                    )}*/}
                  </div>
                )}

                {(this.state.showChatPanel) && (
                  <div key="showChatPanel" className="full-width">

                    <div className="spacer" />

                    <div>
                      {(this.state.inboxes) ? (
                        <div>
                          {this.state.inboxes.map((value, optionIndex) =>
                            <div key={value._id} className={(this.state.inboxSelected === optionIndex) ? "selected-background-light left-text full-width " : "left-text full-width"}>
                              <button className="background-button left-text full-width horizontal-padding-4" disabled={this.state.isLoading} onClick={() => this.selectInbox(value, optionIndex)}>
                                {(value.members.length === 2) && (
                                  <div>
                                    {value.members.map((value2, optionIndex2) =>
                                      <div key={value2._id}>
                                        {(value2.username !== this.state.activeOrg) && (
                                          <div className="left-padding full-width row-10">
                                            <div className="fixed-column-50">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                            </div>
                                            <div className="calc-column-offset-100-static">
                                              <p>{value2.firstName} {value2.lastName}</p>
                                              <p className="description-text-4 left-text curtail-text height-20">{(value.senderEmail === this.state.emailId) ? "You: " + value.lastMessage : value2.firstName + ': ' + value.lastMessage}</p>
                                            </div>
                                            <div className="fixed-column-50">
                                              <p className="description-text-5 bold-text description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                                              {(value.onWaitlist) && (
                                                <div className="top-margin-5">
                                                  <div className="width-20 height-20 circle-radius error-background-color center-text padding-3 description-text-3 pin-right">
                                                    W
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </div>
                                )}
                              </button>

                              <div className="clear" />
                              <hr />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="row-30">
                          <p className="error-color">You have no outstanding conversations. Create a message by navigating to the messages tab.</p>
                        </div>
                      )}

                    </div>
                  </div>
                )}
               </div>
             </div>
           )}
         </Modal>
        </div>
      )
    }
}

export default withRouter(HelpPrompt);

// so that we keep in contact in case we get disconnected, what is your email?
// integrate real-time chat; show wait times to chat with a real body;
