import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const targetIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';

class RenderGoalPlan extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showModule: true,

        headerMargin: 10,
        maxDocHeight: 1100,
        docPadding: 40,
        sectionTopMargin: 50,
        sectionSpacer: 40,
        sectionTitleMargin: 10

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.renderTags = this.renderTags.bind(this)
      this.calculatePages = this.calculatePages.bind(this)
      this.testSectionBreak = this.testSectionBreak.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.calculatePages()
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderGoalPlan', this.props, prevProps)

      if (this.props.goalPlanContent !== prevProps.goalPlanContent) {
        this.calculatePages()
      }
    }

    retrieveData() {
      // console.log('retrieveData called in RenderReferrals')
    }

    calculatePages() {
      console.log('calculatePages called', this.props.goalPlanContent)

      const maxDocHeight = this.state.maxDocHeight
      let pageCount = 1
      let sectionBreaks = []

      let contentHeight = 0
      contentHeight = contentHeight + this.state.docPadding // padding-40

      if (document.getElementById('career-plan-header')) {
        // console.log('summary long text', document.getElementById('long-text').clientHeight)
        // console.log('content height header: ', document.getElementById('career-plan-header').clientHeight)
        contentHeight = contentHeight + this.state.headerMargin

        contentHeight = contentHeight + document.getElementById('career-plan-header').clientHeight
      }

      contentHeight = contentHeight + this.state.sectionTopMargin // margin-top-50

      let sectionCount = 0
      if (this.props.goalPlanContent && this.props.goalPlanContent.sections) {
        sectionCount = this.props.goalPlanContent.sections.length
      }

      console.log('content height before sections: ', contentHeight, sectionCount)

      for (let i = 1; i <= sectionCount; i++) {
        console.log('hmmm?')
        if (document.getElementById('career-plan-section-body-' + i.toString())) {
          console.log('content height section text: ', i, document.getElementById('career-plan-section-title-' + i.toString()).clientHeight)
          console.log('content height section body: ', i, document.getElementById('career-plan-section-body-' + i.toString()).clientHeight)

          contentHeight = contentHeight + document.getElementById("career-plan-section-title-" + i.toString()).clientHeight

          contentHeight = contentHeight + this.state.sectionTitleMargin // margin-bottom-5

          contentHeight = contentHeight + document.getElementById("career-plan-section-body-" + i.toString()).clientHeight

          // console.log('summary long text', document.getElementById('long-text').clientHeight)
          console.log('content height at section ', i, contentHeight)
          // contentHeight = contentHeight + 30 // margin-bottom-30
          contentHeight = contentHeight + this.state.sectionSpacer // margin-bottom-30

          if (contentHeight > (pageCount * maxDocHeight)) {
            pageCount = pageCount + 1
            sectionBreaks.push(i)
          }
        }
      }

      contentHeight = contentHeight + this.state.docPadding // padding-40

      console.log('show total content height: ', contentHeight, pageCount, sectionBreaks)

      // re-distribute as pages


      this.setState({ pageCount, sectionBreaks, contentHeight })
      this.props.passData({ pageCount })
      // 1030 or 1263
    }

    testSectionBreak(index, page) {
      console.log('testSectionBreak called', index, page, this.state.sectionBreaks)

      let renderSection = true
      const sectionCount = index + 1

      if (!this.state.sectionBreaks) {
        renderSection = true
      } else {
        if (this.state.sectionBreaks[page - 2]) {
          if (this.state.sectionBreaks[page - 2] > (sectionCount)) {
            renderSection = false
          }
        }
        if (this.state.sectionBreaks[page - 1]) {
          if (this.state.sectionBreaks[page - 1] < sectionCount + 1) {
            renderSection = false
          }
        }

        // for (let i = 1; i <= this.state.sectionBreaks; i++) {
        //   if (this.state.sectionBreaks[page - 1] < index) {
        //     renderSection = false
        //   }
        // }
      }

      return renderSection
    }

    renderTags(type, items) {
      console.log('renderTags ')

      if (type === 'learningObjective') {
        if (items && items.length > 0) {
          // console.log('compare 0')
          return (
            <div key={"learningObjectives"}>
              <div className="spacer" />
              {items.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className={(disableEditing) ? "background-button no-pointers" : "background-button"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div>
                    <div className="half-spacer" />

                    {(value.category === 'Ability') && (
                      <div className="tag-container-8 primary-background primary-border white-text">
                        <button className={(disableEditing) ? "background-button left-text clear-margin clear-padding full-space no-pointers" : "background-button left-text clear-margin clear-padding full-space"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.addObjectivesToResumes(value,'competency',value.category)}>
                          <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                        </button>
                      </div>
                    )}
                    {(value.category === 'Knowledge') && (
                      <div className="tag-container-8 secondary-background secondary-border white-text">
                        <button className={(disableEditing) ? "background-button left-text clear-margin clear-padding full-space no-pointers" : "background-button left-text clear-margin clear-padding full-space"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.addObjectivesToResumes(value,'competency',value.category)}>
                          <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                        </button>
                      </div>
                    )}
                    {(value.category === 'Soft Skill') && (
                      <div className="tag-container-8 tertiary-background tertiary-border white-text">
                        <button className={(disableEditing) ? "background-button left-text clear-margin clear-padding full-space no-pointers" : "background-button left-text clear-margin clear-padding full-space"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.addObjectivesToResumes(value,'competency',value.category)}>
                          <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                        </button>
                      </div>
                    )}
                    {(value.category === 'General Hard Skill') && (
                      <div className="tag-container-8 quaternary-background quaternary-border white-text">
                        <button className={(disableEditing) ? "background-button left-text clear-margin clear-padding full-space no-pointers" : "background-button left-text clear-margin clear-padding full-space"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.addObjectivesToResumes(value,'competency',value.category)}>
                          <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                        </button>
                      </div>
                    )}
                    {(value.category === 'Tech Skill') && (
                      <div className="tag-container-8 quinary-background quinary-border white-text">
                        <button className={(disableEditing) ? "background-button left-text clear-margin clear-padding full-space no-pointers" : "background-button left-text clear-margin clear-padding full-space"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.addObjectivesToResumes(value,'competency',value.category)}>
                          <p className="description-text-2">{value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                        </button>
                      </div>
                    )}


                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )

        } else {
          return (
            <div key={"learningObjectives"} className="top-padding-20">
              <p className="description-text-2 error-color">Specify a career path or opportunity above for learning objectives to populate.</p>
            </div>
          )
        }
      }
    }

    render() {

      // const pageCount = this.props.pageCount
      const showIdealCandidate = this.props.showIdealCandidate
      const goalPlanContent = this.props.goalPlanContent
      const goalPlanProfile = this.props.goalPlanProfile
      const sectionHeaderDivClass = this.props.sectionHeaderDivClass
      const sectionHeaderTextClass = this.props.sectionHeaderTextClass
      const mainSectionClass = this.props.mainSectionClass
      const headerClass = this.props.headerClass

      const objective = this.props.objective
      const passions = this.props.passions
      const topSkills = this.props.topSkills
      const developmentNeeds = this.props.developmentNeeds
      const problems = this.props.problems
      const goals = this.props.goals
      const threeYearIncome = this.props.threeYearIncome
      const fiveYearIncome = this.props.fiveYearIncome
      const tenYearIncome = this.props.tenYearIncome

      const learningObjectives = this.props.learningObjectives

      const disableEditing = this.props.disableEditing
      const hideIfEmpty = this.props.hideIfEmpty

      return (
        <div>
          {(showIdealCandidate && !this.state.showModule) && (
            <div>
              <div className="row-20">
                <hr className="horizontal-margin-5-percent"/>
              </div>

              <div className="widget-container-left">
                <div className="row-10">
                  <div className="calc-column-offset-50">
                    <p className={sectionHeaderTextClass}>Ideal Candidate</p>
                  </div>
                  <div className="fixed-column-50">
                    <button className={(disableEditing) ? "background-button no-pointers" : "background-button"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ showIdealCandidate: false })}>
                      <div className="half-spacer" />
                      <img src={deniedIcon} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Close ideal candidate widget"/>
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  {this.renderTags('learningObjective', learningObjectives)}
                  <div className="clear" />
                </div>
                <ReactTooltip id="tooltip-placeholder-id" />
              </div>

            </div>
          )}

          {(goalPlanContent) && (
            <div className="paper-container">
              <div id="printPage1" className={"full-space max-text-lines padding-" + this.state.docPadding}>
                <div className={mainSectionClass}>
                  <div className={headerClass} id="career-plan-header">
                    {(this.state.showModule) ? (
                      <div className={"top-margin-" + this.state.headerMargin}>
                        <button className={(this.props.disableEditing) ? "background-button full-width no-pointers" : "background-button full-width"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editTitleSection: true })}>
                          <p className="heading-text-2">{goalPlanContent.title}</p>
                          <p className="top-margin description-text-1 description-text-color">Goal Plan by {goalPlanContent.creatorFirstName} {goalPlanContent.creatorLastName}</p>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button className={(disableEditing) ? "background-button full-width no-pointers" : "background-button full-width"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editBasicInfo: true })}>
                          <p className="heading-text-2">{(goalPlanContent && goalPlanContent.firstName) ? goalPlanContent.firstName : (goalPlanProfile) ? goalPlanProfile.firstName : ""} {(goalPlanContent && goalPlanContent.lastName) ? goalPlanContent.lastName : (goalPlanProfile) ? goalPlanProfile.lastName : ""}</p>

                          <div className="description-text-2">
                            <p>Career Plan</p>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>

                  {(this.state.showModule) ? (
                    <div>
                      {(goalPlanContent.sections) && (
                        <div className={"top-margin-" + this.state.sectionTopMargin}>
                          {goalPlanContent.sections.map((value, optionIndex) =>
                            <div key={"goalPlanSection" + optionIndex.toString()}>
                              {(this.testSectionBreak(optionIndex,1)) && (
                                <div className={"left-text full-width bottom-margin-" + this.state.sectionSpacer}>
                                  <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSection: true, selectedSectionIndex: optionIndex })}>
                                    <p className={"heading-text-4 bottom-margin-" + this.state.sectionTitleMargin} id={"career-plan-section-title-" + (optionIndex + 1).toString()}>{value.title}</p>
                                    <p className={(value.body === value.placeholder) ? "description-text-color" : "keep-line-breaks"} id={"career-plan-section-body-" + (optionIndex + 1).toString()}>{value.body}</p>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <hr className="vertical-margin-20" />

                      {(!hideIfEmpty || (hideIfEmpty && objective)) && (
                        <div className="row-10 description-text-2" id="summary">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editObjective: true })}>
                            <p className={sectionHeaderTextClass}>Objective / Life Purpose</p>
                          </button>

                          {(objective) && (
                            <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editObjective: true })}>
                              {objective}
                            </button>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && passions && passions.length > 0)) && (
                        <div className="row-10 description-text-2" id="passion">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editPassions: true })}>
                            <p className={sectionHeaderTextClass}>Passions</p>
                          </button>

                          {(passions) && (
                            <button className={(disableEditing) ? "background-button left-text top-margin no-pointers" : "background-button left-text top-margin"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editPassions: true })}>
                              <div>
                                {passions.map((value, optionIndex) =>
                                  <div key={value._id} className="left-text bottom-margin-5 tag-container-inactive-2 float-left right-margin">
                                    <div>
                                      <div className="fixed-column-25">
                                        <div className="mini-spacer" />
                                        <img src={favoritesIconDark} alt="GC" className="image-15-fit" />
                                      </div>
                                      <div className="calc-column-offset-25">
                                        <p className="cta-color">{value.passionTitle}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            </button>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && topSkills && topSkills.length > 0)) && (
                        <div className="row-10 description-text-2" id="competitiveAdvantage">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editCompetitiveAdvantage: true })}>
                            <p className={sectionHeaderTextClass}>Competitive Advantage</p>
                          </button>

                          {(topSkills) && (
                            <div>
                              {topSkills.map((value, optionIndex) =>
                                <div key={value} className="bottom-margin">
                                  <button className={(disableEditing) ? "background-button float-left left-text no-pointers" : "background-button float-left left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editCompetitiveAdvantage: true })}>
                                    <div className="right-padding-5">
                                      <div className="half-spacer" />
                                      <div className="tag-container-inactive">
                                        <p className="description-text-2">{value}</p>
                                      </div>
                                      <div className="half-spacer" />
                                    </div>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && developmentNeeds && developmentNeeds.length > 0)) && (
                        <div className="row-10 description-text-2" id="developmentNeeds">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editDevelopmentNeeds: true })}>
                            <p className={sectionHeaderTextClass}>Development Needs</p>
                          </button>

                          {(developmentNeeds) && (
                            <div>
                              {developmentNeeds.map((value, optionIndex) =>
                                <div key={value} className="bottom-margin">
                                  <button className={(disableEditing) ? "background-button float-left left-text no-pointers" : "background-button float-left left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editDevelopmentNeeds: true })}>
                                    <div className="right-padding-5">
                                      <div className="half-spacer" />
                                      <div className="tag-container-inactive">
                                        <p className="description-text-2">{value}</p>
                                      </div>
                                      <div className="half-spacer" />
                                    </div>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && problems && problems.length > 0)) && (
                        <div className="row-10 description-text-2" id="editProblems">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProblems: true })}>
                            <p className={sectionHeaderTextClass}>Problems I'm Solving</p>
                          </button>

                          {(problems) && (
                            <div>
                              {problems.map((value, optionIndex) =>
                                <div key={value} className="bottom-margin">
                                  <button className={(disableEditing) ? "background-button float-left left-text no-pointers" : "background-button float-left left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProblems: true })}>
                                    <div className="right-padding-5">
                                      <div className="half-spacer" />
                                      <div className="tag-container-inactive">
                                        <p className="description-text-2">{value}</p>
                                      </div>
                                      <div className="half-spacer" />
                                    </div>
                                  </button>
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && goals && goals.length > 0)) && (
                        <div className="row-10 description-text-2" id="goals">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editGoals: true })}>
                            <p className={sectionHeaderTextClass}>Goals</p>
                          </button>

                          {(goals) && (
                              <div className="top-padding">
                                {goals.map((value, optionIndex) =>
                                  <div key={value._id} className="left-text bottom-margin full-width">
                                    <button className={(disableEditing) ? "background-button full-width left-text standard-color no-pointers" : "background-button full-width left-text standard-color"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editGoals: true })}>
                                      <div className="full-width">
                                        <div className="fixed-column-50">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <img src={targetIcon} alt="GC" className="image-auto-35" />
                                        </div>
                                        <div className="calc-column-offset-200">
                                          <p className="heading-text-6 bold-text">{value.title}</p>
                                          {(value.goalType) && (
                                            <p className="description-text-1">{value.goalType.description}</p>
                                          )}

                                          {(value.description) && (
                                            <p className="description-text-1">{value.description}</p>
                                          )}

                                          {(value.aName && value.bName) && (
                                            <p className="description-text-1">{value.aName} vs. {value.bName}</p>
                                          )}
                                          {(value.decision) && (
                                            <p className="description-text-1">{value.decision}</p>
                                          )}
                                        </div>
                                        <div className="fixed-column-150 right-text description-text-2 bold-text">
                                          <p>Due:</p>
                                          <p>{convertDateToString(new Date(value.deadline),'date-2')}</p>

                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                          )}
                        </div>
                      )}

                      {(!hideIfEmpty || (hideIfEmpty && (threeYearIncome || fiveYearIncome || tenYearIncome))) && (
                        <div className="row-10 description-text-2" id="financials">
                          <button className={(disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editFinancials: true })}>
                            <p className={sectionHeaderTextClass}>Projected Financials</p>
                          </button>

                          <div>
                            <div className="row-10">
                              <div className="relative-column-33">
                                <label className="profile-label">3-Year Income</label>
                                <div className="float-left standard-border light-background width-22 center-text height-40">
                                  <div className="half-spacer" />
                                  <p className="heading-text-4 cta-color bold-text full-width">$</p>
                                </div>
                                <div className="calc-column-offset-22 float-left">
                                  <input type="number" className="number-field cta-color float-left" placeholder="0" name="threeYearIncome" value={threeYearIncome} onChange={this.props.formChangeHandler}></input>
                                </div>
                              </div>
                              <div className="relative-column-33">
                                <label className="profile-label">5-Year Income</label>
                                <div className="float-left standard-border light-background width-22 center-text height-40">
                                  <div className="half-spacer" />
                                  <p className="heading-text-4 cta-color bold-text full-width">$</p>
                                </div>
                                <div className="calc-column-offset-22 float-left">
                                  <input type="number" className="number-field cta-color float-left" placeholder="0" name="fiveYearIncome" value={fiveYearIncome} onChange={this.props.formChangeHandler}></input>
                                </div>
                              </div>
                              <div className="relative-column-33">
                                <label className="profile-label">10-Year Income</label>
                                <div className="float-left standard-border light-background width-22 center-text height-40">
                                  <div className="half-spacer" />
                                  <p className="heading-text-4 cta-color bold-text full-width">$</p>
                                </div>
                                <div className="calc-column-offset-22 float-left">
                                  <input type="number" className="number-field cta-color float-left" placeholder="0" name="tenYearIncome" value={tenYearIncome} onChange={this.props.formChangeHandler}></input>
                                </div>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                </div>
                <div className="clear" />
              </div>
            </div>
          )}

          {(this.state.pageCount > 1 && this.state.sectionBreaks && this.state.showModule && goalPlanContent) && (
            <div>
              {this.state.sectionBreaks.map((value, optionIndex) =>
                <div key={"sectionBreak" + (optionIndex + 2).toString()}>
                  <div className="paper-container">
                    <div id={"printPage" + (optionIndex + 2).toString()} className="full-space padding-40 max-text-lines">
                      <div className={mainSectionClass}>
                        {(goalPlanContent.sections) && (
                          <div className="top-margin-50">
                            {goalPlanContent.sections.map((value2, optionIndex2) =>
                              <div key={"goalPlanSection" + optionIndex2.toString()}>
                                {(this.testSectionBreak(optionIndex2,(optionIndex + 2).toString())) && (
                                  <div className={"left-text full-width bottom-margin-" + this.state.sectionSpacer}>
                                    <button className={(this.props.disableEditing) ? "background-button full-width no-pointers left-text" : "background-button full-width left-text"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSection: true, selectedSectionIndex: optionIndex2 })}>
                                      <p className="heading-text-4 bottom-margin-5" id={"career-plan-section-title-" + (optionIndex2 + 1).toString()}>{value2.title}</p>
                                      <p className={(value2.body === value2.placeholder) ? "description-text-color" : "keep-line-breaks"} id={"career-plan-section-body-" + (optionIndex2 + 1).toString()}>{value2.body}</p>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="clear" />
        </div>
      )
    }
}

export default withRouter(RenderGoalPlan);
