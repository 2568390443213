import React, {Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from '../components/Functions/WithRouter';

class SignIn extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('componentDidMount called on signin')

      let { roleName, orgCode } = this.props.params
      if (roleName) {
        roleName = roleName.charAt(0).toUpperCase() + roleName.slice(1)
      }

      // let courseId = null
      let opportunityId = null
      let opportunityOrg = null
      let workId = null
      let courseId = null

      if (this.props.location && this.props.location.state) {
        // orgCode = this.props.location.state.orgCode
        // courseId = this.props.location.state.orgCode
        // roleName = this.props.location.state.roleName
        opportunityId = this.props.location.state.opportunityId
        opportunityOrg = this.props.location.state.opportunityOrg
        workId = this.props.location.state.workId
        courseId = this.props.location.state.courseId
      }

      this.setState({ orgCode, roleName, opportunityId, opportunityOrg, workId, courseId })
    }

    render() {
      return (
        <div>
          <LoginForm roleName={this.state.roleName} orgCode={this.state.orgCode}
            opportunityId={this.state.opportunityId} opportunityOrg={this.state.opportunityOrg}
            courseId={this.state.courseId} fromExternal={this.state.fromExternal}
            workId={this.state.workId} type="SignIn"
          />

        </div>
      )
    }
}

export default withRouter(SignIn);
