import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';;
import SubSendMessage from '../../components/Common/SendMessage';
import SubEditGroup from '../../components/Common/EditGroup';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const socialIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png";
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Groups extends Component {
    constructor(props) {
        super(props)

        this.state = {
          groups: [],
          favorites: [],

          genderOptions: [],
          raceOptions: [],
          ageRangeOptions: [],
          proximityOptions: [],
          groupCategoryOptions: [],
          accessTypeOptions: ['','Open'],

          itemFilters: [],
          itemSorters: [],

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

          disableCustomMatches: true,
          inBeta: true

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderGroups = this.renderGroups.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
        this.joinGroup = this.joinGroup.bind(this)
        this.viewGroup = this.viewGroup.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      //   this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');

      const categoryOptions = ['Accountability','Location','Age','Interests','Skills & Abilities','Values','Career Goals','Tech Trends','Societal Problems','Employers','Popular Career Areas','Organizations']
      const booleanOptions = ['Yes','No']
      const accessTypeOptions = ['Open','Request','Waitlist']
      const groupCategoryOptions = ['','Location','Age','Interests','Skills & Abilities','Values','Career Goals','Tech Trends','Societal Problems','Employers','Popular Career Areas','Organizations','Other']

      const itemFilters = [
        { name: 'Category', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(categoryOptions)},
        { name: 'Featured', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(booleanOptions)},
        { name: 'Access Type', value: this.state.defaultFilterOption, options: [this.state.defaultFilterOption].concat(accessTypeOptions)},
      ]

      const itemSorters = [
        { name: 'Votes', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
        { name: 'Membership', value: this.state.defaultSortOption, options: [this.state.defaultSortOption].concat(['High','Low'])},
      ]

      const passedCategory = this.props.category

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, roleName, orgFocus, itemFilters, itemSorters, orgName,
        accessTypeOptions, groupCategoryOptions, passedCategory
      })

      const accountCode = this.props.accountCode

      if ((activeOrg && !window.location.pathname.includes('/employers')) || (accountCode && window.location.pathname.includes('/employers'))) {

        let isActive = null
        if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
          isActive = true
        }

        Axios.get('/api/groups', { params: { orgCode: activeOrg, resLimit: 100, category: passedCategory, accountCode, isActive }})
        .then((response) => {
          console.log('Groups query worked', response.data);

          if (response.data.success) {

            const groups = response.data.groups
            this.setState({ groups })

          } else {
            console.log('no groups data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Groups query did not work', error);
        });
      }

      Axios.get('/api/users/profile/details', { params: { email: emailId } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
           profile['zipcode'] = response.data.user.zipcode
           let pictureURL = response.data.user.pictureURL

           this.setState({ profile, pictureURL });

           Axios.get('/api/assessment/results', { params: { emailId } })
            .then((response2) => {
              console.log('query for assessment results worked');

              if (response2.data.success) {

                console.log('actual assessment results', response2.data)

                // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
                profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                profile['interests'] = response2.data.results.interestScores
                profile['personality'] = response2.data.results.personalityScores
                profile['skills'] = response2.data.results.newSkillAnswers
                profile['gravitateValues'] = response2.data.results.topGravitateValues
                profile['employerValues'] = response2.data.results.topEmployerValues

                this.setState({ profile })

              } else {
                console.log('no assessment results', response2.data)
              }

          }).catch((error) => {
            console.log('query for assessment results did not work', error);
          })

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      Axios.get('/api/account', { params: { accountCode } })
      .then((response) => {
        console.log('Account info query attempted within sub settings', response.data);

        if (response.data.success) {
          console.log('account info query worked in sub settings')

          let employerInfo = response.data.accountInfo
          employerInfo['jobFunction'] = 'Marketing'
          this.setState({ employerInfo })

        }

      }).catch((error) => {
        console.log('Account info query did not work for some reason', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, null, null, true)

      } else if (event.target.name.includes('filter|')) {

        const nameArray = event.target.name.split("|")

        let itemFilters = this.state.itemFilters
        const eventName = nameArray[1]
        const index = Number(nameArray[2])

        itemFilters[index]['value'] = event.target.value
        console.log('show the item values: ', itemFilters, eventName, index, event.target.value)

        this.setState({ itemFilters, animating: true })
        this.filterResults(this.state.searchString, eventName, event.target.value, false)

      } else if (event.target.name.includes('sort|')) {

        const nameArray = event.target.name.split("|")

        let itemSorters = this.state.itemSorters
        const eventName = nameArray[1]
        const index = Number(nameArray[2])

        itemSorters[index]['value'] = event.target.value
        console.log('show the item values: ', itemSorters, eventName, index, event.target.value)

        this.setState({ itemSorters, animating: true })
        this.sortResults(this.state.searchString, eventName, event.target.value, false)
      } else if (event.target.name === 'groupCoverImage') {

          if (event.target.files[0]) {
              let reader = new FileReader();
              reader.onload = (e) => {
                  this.setState({ groupCoverImage: e.target.result });
                  console.log('how do i access the image', e.target.result)
              };
              reader.readAsDataURL(event.target.files[0]);
              // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
              // this.saveFile(event.target.name, event.target.files[0])
          }
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, filterName, filterValue, search) {
      console.log('filterResults called', searchString, filterName, filterValue, search)

      let roleNames = ['Student','Career-Seeker']

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.activeOrg
        const excludeCurrentUser = true
        const emailId = self.state.emailId
        const category = self.state.passedCategory
        let isActive = null
        if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
          isActive = true
        }

        if (search) {
          Axios.get('/api/groups/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId, category, isActive } })
          .then((response) => {
            console.log('Opportunity search query attempted', response.data);

              if (response.data.success) {
                console.log('opportunity search query worked')

                let groups = response.data.groups
                self.setState({ groups, filteredResults: true, animating: false })

              } else {
                console.log('opportunity search query did not work', response.data.message)

                let memberOptions = []
                self.setState({ memberOptions, animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });
        } else {
          console.log('filter results: ', searchString, orgCode, filterName, filterValue)

          const onlyPics = true
          const profile = self.state.profile
          let groups = self.state.groups
          // if (self.state.groups && self.state.groups[0] && self.state.groups[0].matchScore) {
          //   users = self.state.groups
          // }

          // eventually query groups from back-end
          Axios.put('/api/groups/filter', { searchString, orgCode, filterName, filterValue, roleNames, onlyPics, profile, groups, isActive })
          .then((response) => {
            console.log('Groups filter query attempted', response.data);

              if (response.data.success) {
                console.log('opportunity search query worked')

                let groups = response.data.groups
                const filterCriteriaArray = response.data.filterCriteriaArray
                console.log('show filterCriteriaArray: ', filterCriteriaArray)
                self.setState({ groups, filterCriteriaArray, filteredResults: true, animating: false })

              } else {
                console.log('opportunity search query did not work', response.data.message)

                self.setState({ animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(searchString, sortName, sortValue, search) {
      console.log('filterResults called', searchString, sortName, sortValue, search)

      let roleNames = ['Student','Career-Seeker']

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.activeOrg
        const excludeCurrentUser = true
        const emailId = self.state.emailId

        const onlyPics = true
        const profile = self.state.profile
        let groups = self.state.groups
        // if (self.state.groups && self.state.groups[0] && self.state.groups[0].matchScore) {
        //   users = self.state.groups
        // }

        let isActive = null
        if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
          isActive = true
        }

        // eventually query groups from back-end
        Axios.put('/api/groups/sort', { searchString, orgCode, sortName, sortValue, roleNames, onlyPics, profile, groups, excludeCurrentUser, emailId, isActive })
        .then((response) => {
          console.log('Groups sort query attempted', response.data);

            if (response.data.success) {
              console.log('sort query worked')

              let groups = response.data.groups
              self.setState({ groups, sortedResults: true, animating: false })

            } else {
              console.log('sorted query did not work', response.data.message)

              self.setState({ animating: false })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    calculateMatches(matchingView, calcMatches, newPreferences) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences)

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (!this.state.profile) {
          this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
        } else {
          this.setState({ animating: true, modalIsOpen: false })

          const profile = this.state.profile
          const matchingCriteria = this.state.matchingCriteria
          const similarityCriteria = this.state.similarityCriteria
          let roleNames = ['Student','Career-Seeker']
          const orgCode = this.state.activeOrg
          const onlyPics = true
          const category = this.state.passedCategory

          let isActive = null
          if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
            isActive = true
          }

          console.log('show our profile orgCode: ', orgCode)

          const self = this

          function officiallyCalculate() {
            console.log('officiallyCalculate called')

            // query postings on back-end
            Axios.put('/api/groups/matches', { profile, matchingCriteria, similarityCriteria, roleNames, orgCode, onlyPics, category, isActive })
            .then((response) => {
              console.log('Opportunity matches attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity match query worked')

                  let groups = response.data.groups
                  // let matchScores = response.data.matchScores
                  self.setState({ animating: false, matchingView: true, groups })

                } else {
                  console.log('Career match did not work', response.data.message)
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                }

            }).catch((error) => {
                console.log('Career match did not work for some reason', error);
                self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
            });
          }

          if (newPreferences) {
            console.log('new preferences stated')

            if (this.state.disableCustomMatches) {
              this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'Ability to customize your matches is coming soon!' })
            } else {
              const emailId = this.state.emailId

              let savePrefences = false
              if (!savePrefences) {
                officiallyCalculate()
              } else {

                const matchingPreferences = matchingCriteria
                const similarityCriteria = this.state.similarityCriteria
                const updatedAt = new Date()

                Axios.post('/api/users/profile/details', {
                  emailId, matchingPreferences, similarityCriteria, updatedAt })
                .then((response) => {

                  if (response.data.success) {
                    console.log('successfully saved new preferences')
                    officiallyCalculate()

                  } else {
                    console.log('request was not successful')
                    officiallyCalculate()
                  }
                }).catch((error) => {
                    console.log('Saving the info did not work', error);
                    officiallyCalculate()
                });
              }
            }

          } else {
            officiallyCalculate()
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    renderGroups() {
      console.log('renderGroups called')

      let rows = []

      let cardClass = 'relative-column-33 horizontal-padding-7'
      if (window.innerWidth < 768) {
        cardClass = ''
      }

      if (this.state.groups) {
        for (let i = 1; i <= this.state.groups.length; i++) {

          const index = i - 1

          rows.push(
            <div key={i}>
              <div className={cardClass}>
                <button onClick={() => this.viewGroup(this.state.groups[i - 1])} className="background-button full-width">
                  <div className="elevated-box white-background" >
                    <div className="full-width relative-position tint">
                      <img src={(this.state.groups[i - 1].pictureURL) ? this.state.groups[i - 1].pictureURL :"https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/headerImages/1210x311.png"} alt="GC" className="image-full-width-150 center-horizontally"  />
                      <div className="absolute-position absolute-top-5 absolute-left-5">
                        {(this.state.groups[i - 1].matchScore) && (
                          <div className="image-40-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                            <CircularProgressbar
                              value={this.state.groups[i - 1].matchScore}
                              text={`${this.state.groups[i - 1].matchScore}%`}
                              styles={{
                                path: { stroke: `rgba(255, 255, 255, ${this.state.groups[i - 1].matchScore / 100})` },
                                text: { fill: 'white', fontSize: '26px' },
                                trail: { stroke: 'transparent' }
                              }}
                            />
                          </div>
                        )}
                        <p className="description-text-5 rounded-corners horizontal-padding-4 row-5 white-border white-text bold-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Category: " + this.state.groups[i - 1].category}>{this.state.groups[i - 1].category}</p>
                      </div>

                      <button className="background-button clear-padding display-block absolute-position absolute-top-5 absolute-right-5 white-background rounded-corners" onClick={(e) => this.voteOnItem(e, this.state.groups[i - 1], 'up', index) }>
                        <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                          <div className="float-left padding-7">
                            <img src={(this.state.groups[i - 1].upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="Compass arrow indicator icon" className="image-auto-15"/>
                          </div>
                          <div className="vertical-separator-4" />
                          <div className="float-left horizontal-padding-10">
                            <div className="half-spacer" />
                            <p className="description-text-2 half-bold-text">{this.state.groups[i - 1].upvotes.length}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </button>
                    </div>

                    <div className="spacer" />

                    <div className="horizontal-padding left-text">
                      <p className="heading-text-5">{this.state.groups[i - 1].name}</p>
                      <p className="description-text-3 top-padding-5">{(this.state.groups[i - 1].category === 'Accountability') ? this.state.groups[i - 1].members.length + ' / 6' : this.state.groups[i - 1].members.length} Members</p>

                      <div className="top-padding">

                          {(this.state.groups[i - 1].members && this.state.groups[i - 1].members.some(member => member.email === this.state.emailId)) ? (
                            <div className="top-margin-30">
                              <div className="float-left right-padding">
                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                              </div>
                              <div className="float-left">
                                <p className="description-text-2 bold-text">Joined</p>
                              </div>
                              <div className="clear" />
                            </div>
                          ) : (
                            <div>

                              {(this.state.groups[index].accessType === 'Open') ? (
                                <button className="btn btn-squarish top-margin" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.joinGroup(e, this.state.groups[index], index, true)}>
                                  <p className="bold-text white-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Join!">+ Join Group</p>
                                </button>
                              ) : (
                                <div>
                                  {((this.state.groups[index].requests && this.state.groups[index].requests.some(request => request.email === this.state.emailId))) ? (
                                    <div className="center-text full-width right-margin-5 top-margin-30">
                                      <div className="float-left right-padding">
                                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <img src={timeIconBlue} alt="GC" className="image-auto-15" />
                                      </div>
                                      <div className="float-left">
                                        <p className="description-text-2 bold-text">Requested</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  ) : (
                                    <button className="btn btn-squarish top-margin" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.joinGroup(e, this.state.groups[index], index, true)}>
                                      <p className="bold-text white-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Request to Join">+ Request</p>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>

                    {(this.state.filterCriteriaArray && this.state.filterCriteriaArray.length > 0 && this.state.filterCriteriaArray.length === this.state.groups.length) && (
                      <div className="top-padding horizontal-padding">
                        <p className="error-color description-text-5 left-text bold-text">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                      </div>
                    )}

                    {(this.state.groups[i - 1].category === 'Accountability' && this.state.groups[i - 1].admins && this.state.groups[i - 1].admins.length > 0) && (
                      <div className="top-padding-20 horizontal-padding">
                        <p className="description-text-3 left-text">Admin:</p>
                        {this.state.groups[i - 1].admins.map((item, index) =>
                          <div key={index}>
                            <p className="error-color description-text-3 left-text bold-text">{item.firstName} {item.lastName}</p>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </button>
              </div>

              {(i % 3 === 0 && window.innerWidth >= 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}
              {(window.innerWidth < 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}

              <ReactTooltip id="tooltip-placeholder-id" />

            </div>
          )
        }
      }

      return rows
    }

    viewGroup(group) {
      console.log('viewGroup called', group)

      let groupDetailsPath = '/app/groups/' + group._id
      if (window.location.pathname.includes('/organizations/')) {
        groupDetailsPath = '/organizations/' + this.state.activeOrg + '/groups/' + group._id
      } else if (window.location.pathname.includes('/advisor')) {
        groupDetailsPath = '/advisor/groups/' + group._id
      } else if (window.location.pathname.includes('/employers')) {
        groupDetailsPath = '/employers/' + this.props.accountCode + '/groups/' + group._id
      }

      if (window.location.pathname.includes('/app/walkthrough')) {
        window.open(window.location.protocol + "//" + window.location.host + groupDetailsPath)
      } else {
        this.props.navigate(groupDetailsPath, { state: { selectedGroup: group }})
      }
    }

    joinGroup(e, group, index, joinGroup) {
      console.log('joinGroup called', e, group, index, joinGroup)
      e.stopPropagation()

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let groupId = group._id
      const member = {
        firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId,
        username: this.state.username, roleName: this.state.roleName, pictureURL: this.state.pictureURL
      }

      const accessType = group.accessType

      Axios.post('/api/groups/save', { groupId, member, accessType, joinGroup })
      .then((response) => {
        console.log('attempting to save addition to groups')
        if (response.data.success) {
          console.log('saved addition to groups', response.data)

          let groups = this.state.groups
          groups[index] = response.data.group

          this.setState({ successMessage: 'Saved as a group!', groups, isSaving: false })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
      });
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showMessageWidget: false, showMatchingCriteria: false, addOrgGroup: false })
    }

    itemClicked(name, value) {
      console.log('itemClicked called', name, value)

      const nameArray = name.split("|")
      const index = Number(nameArray[1].trim())

      let similarityCriteria = this.state.similarityCriteria
      // similarityCriteria[index]["value"] = event.target.value
      for (let i = 1; i <= similarityCriteria.length; i++) {
        console.log('compare indices: ', i - 1, index)
        if (i - 1 === index) {
          similarityCriteria[i - 1]["selected"] = true
        } else {
          similarityCriteria[i - 1]["selected"] = false
        }
      }

      this.setState({ similarityCriteria })

    }

    voteOnItem(e, item, direction, index) {
      console.log('voteOnItem called')

      e.stopPropagation()

      const _id = item._id
      const emailId = this.state.emailId
      let changeUpvote = true
      const updatedAt = new Date()

      Axios.post('/api/groups', { _id, emailId, changeUpvote, updatedAt })
      .then((response) => {
        console.log('attempting to save')

        if (response.data.success) {
          //save values
          console.log('Group save worked', response.data);

          const serverSuccessMessage = 'Problem successfully posted!'

          let upvotes = item.upvotes
          let downvotes = item.downvotes

          if (direction === 'up') {
            console.log('in up')

            if (upvotes.includes(this.state.emailId)) {
              const removeIndex = upvotes.indexOf(this.state.emailId)
              console.log('removing: ',removeIndex)
              if (removeIndex > -1) {
                upvotes.splice(removeIndex, 1);
                item['upvotes'] = upvotes

                let groups = this.state.groups
                groups[index]= item
                this.setState({ groups, serverSuccessMessage })
              }
            } else {
              console.log('adding: ', this.state.emailId)
              upvotes.push(this.state.emailId)

              if (downvotes.includes(this.state.emailId)) {
                const removeIndex = downvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  downvotes.splice(removeIndex, 1);
                }

                item['upvotes'] = upvotes
                item['downvotes'] = downvotes

                let groups = this.state.groups
                groups[index] = item
                this.setState({ groups, serverSuccessMessage })
              } else {

                item['upvotes'] = upvotes

                let groups = this.state.groups
                groups[index] = item

                this.setState({ groups, serverSuccessMessage })
              }
            }

          } else {

            if (downvotes.includes(this.state.emailId)) {
              console.log('un-downvoting')
              const removeIndex = downvotes.indexOf(this.state.emailId)
              if (removeIndex > -1) {
                downvotes.splice(removeIndex, 1);
                item['downvotes'] = downvotes

                let groups = this.state.groups
                groups[index] = item

                this.setState({ groups, serverSuccessMessage })
              }
            } else {
              console.log('initial downvote')
              downvotes.push(this.state.emailId)

              if (upvotes.includes(this.state.emailId)) {
                console.log('downvoting from previous upvote')
                const removeIndex = upvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  upvotes.splice(removeIndex, 1);
                }

                item['upvotes'] = upvotes
                item['downvotes'] = downvotes

                let groups = this.state.groups
                groups[index] = item

                this.setState({ groups, serverSuccessMessage })
              } else {
                item['downvotes'] = downvotes

                let groups = this.state.groups
                groups[index] = item
                this.setState({ groups, serverSuccessMessage })
              }
            }
          }

        } else {
          console.error('there was an error posting the talk / workshop');
          const serverErrorMessage = response.data.message
          this.setState({ serverErrorMessage })
        }
      }).catch((error) => {
          console.log('The talk post did not work', error);
      });
    }

    render() {

      return (
          <div className="top-margin-20">

            {(window.location.pathname.includes('/employers')) ? (
              <div>
                <div>
                  <div className="full-width row-5 white-background standard-border medium-shadow">
                    <div className="row-10 horizontal-padding">
                      <div className="calc-column-offset-30">
                        <p className="heading-text-2">Talent Communities</p>
                      </div>
                      {(this.state.employerInfo) && (
                        <div className="fixed-column-30 top-padding">
                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, addOrgGroup: true, accountCode: this.state.employerInfo.accountCode, employerLogoURI: this.state.employerInfo.employerLogoURI, employerName: this.state.employerInfo.employerName, jobFunction: this.state.employerInfo.jobFunction, pathway: this.state.employerInfo.pathway })}>
                            <img src={addIcon} alt="GC" className="image-auto-18" />
                          </button>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {(this.state.matchingView) ? (
                  <div>
                    <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                      <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                        <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                      </button>
                    </div>
                    <div className="full-width">
                      <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                        <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                          <p>Adjust</p>
                        </div>
                      </button>
                      <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                        <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                          <p>Close</p>
                        </div>
                      </button>

                      <div className="clear" />

                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    </div>
                  </div>
                ) : (
                  <div>
                    {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor/')) ? (
                      <div>
                        <div className="full-width card">
                          <div className="">
                            <div className="calc-column-offset-25 right-padding">
                              <p className="heading-text-2">Groups</p>
                            </div>
                            <div className="fixed-column-25 top-padding">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, addOrgGroup: true })}>
                                <img src={addIcon} alt="GC" className="image-auto-25" />
                              </button>
                            </div>
                            <div className="clear" />
                            <p className="description-text-2 row-5 standard-color">Admin-created groups or career-seeker-created accountability groups for collaboration.</p>
                          </div>
                        </div>

                        <div className="full-width row-5 white-background standard-border medium-shadow top-margin-20">
                          <div className="filter-field-search clear-margin calc-column-offset-50 white-background clear-border">
                            <div className="search-icon-container">
                              <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                            </div>
                            <div className="filter-search-container calc-column-offset-100-static top-padding-5">
                              <input type="text" className="text-field clear-border" placeholder="Search groups..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                          </div>
                          <div className="fixed-column-50 center-item">
                            <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                              <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                            </button>
                          </div>

                          <div className="clear" />
                        </div>
                      </div>
                    ) : (
                      <div className="full-width row-5 white-background standard-border medium-shadow">
                        <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                          <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} onClick={() => this.calculateMatches(true, true, false)}>
                            <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches"/>
                          </button>
                        </div>

                        <div className="filter-field-search clear-margin calc-column-offset-100-static white-background clear-border">
                          <div className="search-icon-container">
                            <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                          </div>
                          <div className="filter-search-container calc-column-offset-100-static top-padding-5">
                            <input type="text" className="text-field clear-border" placeholder="Search groups and group organizers..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                          </div>
                        </div>
                        {(!this.state.passedCategory) && (
                          <div className="fixed-column-50 center-item">
                            <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                              <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                            </button>
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    )}

                    <ReactTooltip id="tooltip-placeholder-id" />

                  </div>
                )}
              </div>
            )}

            {(this.state.showingSearchBar) && (
              <div className={"box-container-1 white-background"}>

                <label className="profile-label">Filter Options</label>

                <div>
                  {this.state.itemFilters.map((item, index) =>
                    <div key={index}>
                      <SubCustomDropdown dropdownTitle={item.name} name={"filter|" + item.name + '|' + index} value={item.value} options={item.options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />

                      {(index % 3 === 2) && (
                        <div>
                          <div className="clear" />
                          <div className="spacer" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>


                <div className="spacer" />

                <label className="profile-label">Sort Options</label>

                <div>
                  {this.state.itemSorters.map((item, index) =>
                    <div>
                      <SubCustomDropdown dropdownTitle={item.name} name={"sort|" + item.name + '|' + index} value={item.value} options={item.options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />

                      {(index % 3 === 2) && (
                        <div>
                          <div className="clear" />
                          <div className="spacer" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>

              </div>
            )}

            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color">{this.state.errorMessage}</p>}
            <div className="spacer" /><div className="spacer" />

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Pulling results...</p>

                </div>
              </div>
            ) : (
              <div className="top-padding-20">
                {(this.state.groups && this.state.groups.length > 0) ? (
                  <div>
                    {this.renderGroups()}
                    <div className="clear" />
                  </div>
                ) : (
                  <div>
                    <div className="card top">
                      <div className="full-space row-20">
                        <img src={socialIconDark} alt="GC" className="image-auto-100 center-horizontally" />

                        <div className="row-20">
                          <p className="center-text heading-text-2">{(window.location.pathname.includes('/employers')) ? "Communities" : "Groups"}</p>
                          {(window.location.pathname.includes('/employers')) ? (
                            <p className="center-text top-padding-20 horizontal-padding">Communities are groups where you can connect with talent, educators, career counselors, and workforce program managers around company updates, events, preferences, wins, and more!</p>
                          ) : (
                            <p className="center-text top-padding-20 horizontal-padding">Groups are communities where you can connect with peers, educators, program managers, or employers around goals, interests, purpose, and other factors.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

              {(this.state.showMatchingCriteria) && (
                <div key="showMatchingCriteria" className="full-width padding-20">
                  {(this.state.showMatchingCriteria) && (
                    <div key="showMatchingCriteria" className="full-width padding-20">
                      <p className="heading-text-2">Adjust Matching Criteria</p>
                      <div className="spacer" />

                      <div className="row-10">
                        <div className="flex-container">
                          <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                            <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                              Adjust by Similarity
                            </div>
                          </button>
                          <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                            <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                              Design Your Group
                            </div>
                          </button>

                        </div>
                        <div className="spacer" />
                      </div>

                      {(this.state.inBeta) && (
                        <div className="bottom-padding">
                          <p className="error-color full-width center-text">The ability to customize your matches is coming soon.</p>
                        </div>
                      )}

                      {(this.state.customAdjustment) ? (
                        <div>
                          {(this.state.disableCustomMatches) && (
                            <div className="bottom-padding">
                              <p className="error-color">Ability to customize your matches is coming soon</p>
                            </div>
                          )}

                          {(this.state.matchingCriteria) && (
                            <div>
                              {this.state.matchingCriteria.map((value ,index) =>
                                <div key={"c" + index} className={(value.name === 'Location') && "wash-out-2"}>
                                  <div className="calc-column-offset-100-static">
                                    <p className="half-bold-text">{index + 1}. {value.name}</p>
                                    <div className="half-spacer" />
                                    <p className="description-text-3">{value.description}</p>
                                  </div>
                                  <div className="fixed-column-100 right-text">
                                    <div className="fixed-column-70">
                                      <input disabled={(value.name === 'Location') ? true : false} type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="fixed-column-30">
                                      <div className="mini-spacer"/><div className="mini-spacer"/>
                                      <p className="heading-text-2 cta-color bold-text">%</p>
                                    </div>
                                  </div>

                                  <div className="clear" />
                                  <div className="spacer" /><div className="half-spacer" />

                                </div>
                              )}

                              <div>
                                <hr className="cta-border-color"/>
                                <div className="spacer" />
                                <div className="float-left full-width right-text">
                                  <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                                </div>
                                <div className="clear" />
                                {(this.state.totalPercent !== 100) && (
                                  <div className="full-width">
                                    <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {(this.state.similarityCriteria) && (
                            <div>
                              {(this.state.disableCustomMatches) && (
                                <div className="bottom-padding">
                                  <p className="error-color">Ability to customize your matches is coming soon</p>
                                </div>
                              )}

                              {this.state.similarityCriteria.map((value ,index) =>
                                <div key={"u" + index} className={(value.disabled) && "wash-out-2"}>
                                  <div className="calc-column-offset-50">
                                    <p className="half-bold-text">{index + 1}. {value.name}</p>
                                    <div className="half-spacer" />
                                    <p className="description-text-3">{value.description}</p>
                                  </div>
                                  <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                                    <button disabled={value.disabled} onClick={() => this.itemClicked('similarityCriteria|' + index, true)} type="button" className="background-button">
                                      {(value.selected) ? (
                                        <div className="circle-option-container-2 cta-border center-text" >
                                          <div className="circle-selected-2"/>
                                        </div>
                                      ) : (
                                        <div className="circle-option-container-2 standard-border center-text" />
                                      )}
                                    </button>
                                  </div>

                                  <div className="clear" />
                                  <div className="spacer" /><div className="half-spacer" />

                                </div>
                              )}

                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

                    </div>
                  )}

                  <div className="row-20 center-text">
                    <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}

             {(this.state.showMessageWidget) && (
               <div key="showServiceDefinitions" className="full-width padding-20">
                  <SubSendMessage
                    profileData={{ firstName: this.state.groups[this.state.selectedIndex].firstName, lastName: this.state.groups[this.state.selectedIndex].lastName, email: this.state.groups[this.state.selectedIndex].email}}
                    history={this.props.navigate} closeModal={this.closeModal}
                    />
                </div>
             )}

             {(this.state.addOrgGroup) && (
               <div key="addOrgGroup" className="full-width padding-20">
                  <SubEditGroup selectedGroup={null} history={this.props.navigate} closeModal={this.closeModal}
                    accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogoURI}
                    employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                    pathway={this.state.pathway}
                  />
                </div>
             )}

           </Modal>

          </div>

      )
    }
}

export default withRouter(Groups);
