import Axios from 'axios';

export const subFavoriteItem = async (item, favoritesArray, emailId, newFavorites, category)=>{
  console.log('subFavoriteItem called', favoritesArray, newFavorites)

  let itemId = item._id
  let name = item.name
  if (item.title) {
    name = item.title
  } else if (item.employerName && category !== 'person') {
    name = item.employerName
  } else if (item.firstName && item.lastName) {
    name = item.firstName + " " + item.lastName
  }

  if (favoritesArray.includes(itemId)){

    let index = favoritesArray.indexOf(itemId)
    if (index > -1) {
      favoritesArray.splice(index, 1);
    }

    let newIndex = newFavorites.findIndex(nf => nf.id === itemId)
    if (newIndex > -1) {
      newFavorites.splice(newIndex, 1)
    }

    return await Axios.post('/api/favorites/save', { favoritesArray, emailId, newFavorites })
    .then(async(response) => {
      console.log('attempting to save favorites')
      if (response.data.success) {
        console.log('saved successfully', response.data)

        return { success: true, message: "Favorite was successfully removed!", favoritesArray, newFavorites }

      } else {
        console.log('did not save successfully')
        return { success: false, message: response.data.message }
      }
    }).catch((error) => {
        console.log('save did not work', error);

        return { success: false, message: error.toString() }

    });

  } else {

    favoritesArray.push(itemId)
    newFavorites.push({ id: itemId, name, category, createdAt: new Date() })

    return await Axios.post('/api/favorites/save', { favoritesArray, emailId, newFavorites })
    .then(async(response) => {
      console.log('attempting to save favorites')
      if (response.data.success) {
        console.log('saved successfully', response.data)

        return { success: true, message: "Favorite was successfully added!", favoritesArray, newFavorites }

      } else {
        console.log('did not save successfully')
        return { success: false, message: response.data.message }
      }
    }).catch((error) => {
        console.log('save did not work', error);
        return { success: false, message: error.toString() }
    });
  }
}
