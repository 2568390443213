import React, {Component} from 'react';
import {convertDateToString} from '../Functions/ConvertDateToString';

const dropdownArrow = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png";

class CustomDropdown extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.renderOptions = this.renderOptions.bind(this)
    }

    componentDidMount() {
      //see if user is logged in
      console.log('CustomDropdown just mounted')
    }

    // componentDidUpdate(prevProps) {
    //   console.log('componentDidUpdate called in SubConfigureEndorsement', this.props, prevProps)
    //
    //   if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.posts !== prevProps.posts) {
    //     this.retrieveData()
    //   }
    // }

    renderOptions() {
      console.log('renderOptions called', this.props.name)

      let rows = []
      for (let i = 1; i <= this.props.options.length; i++) {
        const value = this.props.options[i - 1]

        if (this.props.name === 'selectedPosting' || this.props.name === 'selectedWork') {
          rows.push(<option key={(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title} value={(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title}>{(value.title && value.title !== 'All') ? value.title + ' | ' + value.employerName : value.title}</option>)
        } else if (this.props.name === 'selectedProject') {
          rows.push(<option key={value.name} value={value.name}>{value.name}</option>)
        } else if (this.props.name === 'selectedEvent') {
          rows.push(<option key={(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title} value={(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title}>{(value.startDate) ? value.title + ' on ' + convertDateToString(new Date(value.startDate),'date-2') : value.title}</option>)
        } else if (this.props.optionSubKey) {
          rows.push(<option key={value[this.props.optionSubKey]} value={(value[this.props.optionSubKey]) && value[this.props.optionSubKey]}>{(value[this.props.optionSubKey]) && value[this.props.optionSubKey]}</option>)
        } else {
          rows.push(<option key={value} value={value}>{value}</option>)
        }
      }

      return rows
    }

    render() {
      console.log('is this even working', this.props)
      return (
        <div>
          <div>
            {(this.props.renderDate) ? (
              <div className="float-left top-margin-15 right-margin-20">
                <div className="light-border float-left height-46 white-background">
                  <div className="display-inline float-left top-padding-8 right-padding-5 left-padding nowrap">
                    <p className="description-text-color">{this.props.dropdownTitle}</p>
                  </div>
                  <div className="float-left right-padding">
                    <input type="datetime-local" className="date-picker clear-border" placeholder="" name={this.props.name} value={this.props.value} onChange={this.props.formChangeHandler}></input>
                  </div>
                </div>
              </div>
            ) : (
              <div className="float-left">
                {(this.props.options) && (
                  <div className={(this.props.clearPadding) ? "light-border float-left top-padding-4 height-46 white-background" : "light-border float-left top-padding-4 right-padding-5 top-margin-15 right-margin height-46 white-background"}>
                      <div className={(this.props.clearPadding) ? "display-inline float-left top-padding-8 right-padding-5 left-padding nowrap" : "display-inline float-left top-padding-8 right-padding-5 left-padding nowrap"}>
                        <p className="description-text-color">{this.props.dropdownTitle}</p>
                      </div>
                      <div className={this.props.optionClass}>
                        <select name={this.props.name} value={this.props.value} className="filter-select right-padding-30" onChange={this.props.formChangeHandler}>
                          {this.renderOptions()}
                        </select>
                      </div>
                      <div className="float-left top-padding-17 left-margin-negative-20 relative-position">
                        <img src={dropdownArrow} alt="GC" className="image-auto-10"/>
                      </div>
                  </div>
                )}
                {/*
                <select name="staffMember" value={(this.state.assignedStaff && this.state.assignedStaff.email) ? this.state.assignedStaff.firstName + ' ' + this.state.assignedStaff.lastName : ""} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.staffMemberOptions.map(value =>
                    <option key={value.email} value={value.firstName + ' ' + value.lastName}>{value.firstName} {value.lastName}</option>
                  )}
                </select>*/}
              </div>
            )}
          </div>
         </div>
      )
    }
}

export default CustomDropdown;
