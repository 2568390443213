import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubRenderOpportunities from '../Common/RenderOpportunities';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
// const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Opportunities extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            showModule: true,
            showPrograms: true,
            emailId: null,
            username: '',

            defaultFilterOption: 'All',
            defaultSortOption: 'No Sort Applied',

            showAssignments: false,
            subNavSelected: 'All',

            totalPercent: 100,

            subNavCategories: ['All','Featured','Work','Projects','Events'],
            featuredOpportunities: [],
            filteredFeaturedOpportunities: [],
            assignments: [],
            filteredAssignments: [],
            problems: [],
            filteredProblems: [],
            challenges: [],
            filteredChallenges: [],
            projectWork: [],
            filteredProjectWork: [],
            internships: [],
            filteredInternships: [],
            work: [],
            filteredWork: [],
            programs: [],
            filteredPrograms: [],
            events: [],
            filteredEvents: [],
            upcomingEvents: [],
            filteredUpcomingEvents: [],
            pastEvents: [],
            filteredPastEvents: [],
            filteredPostings: [],
            postings: [],

            favorites: [],
            newFavorites: [],

            problemTypeOptions: [],
            difficultyLevelOptions: [],
            popularityOptions: [],
            postDateOptions: [],
            functionOptions: [],
            industryOptions: [],
            employerSizeOptions: [],
            employerTypeOptions: [],

            categorySelected: 0,
            searchString: '',
            problemType: '',
            difficultyLevel: '',
            popularity: '',
            postDate: '',
            workFunction: '',
            industry: '',
            employerSize: '',
            employerType: '',

            viewIndex: 0
        }

        this.retrievePostings = this.retrievePostings.bind(this)

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleView = this.toggleView.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.renderManipulators = this.renderManipulators.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)

        this.filterPostings = this.filterPostings.bind(this)

    }

    static defaultProps = { selectedApplication: null, selectedJob: null, wpQuestions: [], benchmarkOptions: [], benchmark: null, source: "Student", trackBenchmark: null, matchIndex: 0, employerName: '',  employerContactFirstName: '', employerContactEmail: '', activeOrg: '', orgName: '', orgContactFirstName: '', orgContactLastName: '', orgContactEmail: '', orgContactTitle: '', orgProgramName: '', isCareerAssessments: true }

    componentDidMount() {
      console.log('SubOpportunities componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubOpportunities ', this.props)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      } else if (this.props.passedSubNavSelected !== prevProps.passedSubNavSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subOpps')

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');
      // const orgFocus = localStorage.getItem('orgFocus');
      const pathway = localStorage.getItem('pathway');
      const opportunitiesNavSelected = localStorage.getItem('opportunitiesNavSelected');
      let accountCode = localStorage.getItem('accountCode');
      // console.log('show orgName --: ', orgName, roleName, accountCode)

      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else {
        accountCode = null
      }

      let matchingCriteria = [
        { name: 'Work Function',  description: 'Scores careers / opportunities the highest that match your preferred work functions indicated in your work preferences assessment', value: 20 },
        { name: 'Industry',  description: 'Scores careers / opportunities the highest that match your preferred industries indicated in your work preferences assessment', value: 10 },
        { name: 'Location',  description: 'Scores careers / opportunities the highest that match your preferred location preferences indicated in your work preferences assessment', value: 0 },
        { name: 'Pay',  description: 'Scores high paying careers / opportunities highest', value: 0 },
        { name: 'Stability',  description: 'Scores careers / opportunities the highest with little variability in pay and job growth', value: 0 },
        { name: 'Interests',  description: 'Scores careers / opportunities the highest that match your interest assessment', value: 15 },
        { name: 'Personality',  description: 'Scores careers / opportunities the highest that match your personality assessment', value: 15 },
        { name: 'Values',  description: 'Scores careers / opportunities that the highest match your values assessment', value: 10 },
        { name: 'Skills',  description: 'Scores careers / opportunities that the highest match your skills assessment', value: 20 },
        { name: 'Education',  description: 'Scores careers / opportunities the highest that match your target education level indicated in work preferences', value: 0 },
        { name: 'Projects',  description: 'Scores careers / opportunities the highest that will likely value your project work the highest', value: 5 },
        { name: 'Experience',  description: 'Scores careers / opportunities the highest that will likely value your experience (that you like)', value: 5 },
      ]

      let useCases = [
        { name: 'Purpose', description: 'Optimize for opportunities that may give you the most purpose (e.g., solving a global problem, or large problem I am specifically interested in)', selected: false }, // interests
        { name: 'Stability', description: 'Optimize for opportunities where you will likely have the most job stability.', selected: false }, // job growth, company size
        { name: 'Pay', description: 'Optimize for opportunities where you will be paid the most in the short to medium term.', selected: false }, // pay
        { name: 'Recognition', description: 'Optimize for opportunities where you will be provided positive reinforcement and public praise for your accomplishments.', selected: false }, // social, artistic
        { name: 'Interests', description: 'Optimize for opportunities where you have the most long-term, genuine interest in.', selected: false }, // interests, personality
        { name: 'Competitive Advantage', description: 'Optimize for opportunities where you have the highest competitive advantage amongst candidates in your age group.', selected: false }, // skills
      ]

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried');

        if (response.data.success) {
          console.log('Work options query succeeded')

          const eventTypeOptions = response.data.workOptions[0].eventTypeOptions

          const postTypeOptions = ['Event','Project','Work']
          const durationOptions = response.data.workOptions[0].durationOptions
          let functionOptions = response.data.workOptions[0].functionOptions
          const industryOptions = response.data.workOptions[0].industryOptions
          const roleNameOptions = ['Mentor','Teacher','School Support','WBLC','Admin','Employer']
          const difficultyOptions = ['Very Easy','Easy','Medium','Hard','Challenger']

          const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','Freshman and Sophomore of College','Junior and Senior of College', 'All Grade Levels Are Welcome to Apply']
          const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']
          const employerTypeOptions = response.data.workOptions[0].employerTypeOptions
          // const employerSizeOptions = response.data.workOptions[0].employeeCountOptions

          const payRangeOptions = response.data.workOptions[0].hourlyPayOptions
          const hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
          // const projectedInternOptions = response.data.workOptions[0].projectedInternOptions

          const hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
          const annualPayOptions = response.data.workOptions[0].annualPayOptions
          const employeeCountOptions = response.data.workOptions[0].employeeCountOptions
          const hourOptions = response.data.workOptions[0].hourOptions

          const applicationMethodOptions = ['Referral Only','Also By Email','Also By Web']

          //filters
          const defaultFilterOption = this.state.defaultFilterOption

          const eventTypeFilterOptions = [defaultFilterOption].concat(eventTypeOptions.slice(1, eventTypeOptions.length))

          const postTypeFilterOptions = [defaultFilterOption].concat(postTypeOptions.slice(1,4))
          const durationFilterOptions = [defaultFilterOption].concat(durationOptions.slice(1, durationOptions.length))
          const functionFilterOptions = [defaultFilterOption].concat(functionOptions.slice(1, functionOptions.length))
          const industryFilterOptions = [defaultFilterOption].concat(industryOptions.slice(1, industryOptions.length))
          const roleNameFilterOptions = [defaultFilterOption].concat(roleNameOptions)
          const difficultyLevelFilterOptions = [defaultFilterOption].concat(difficultyOptions)
          const gradeLevelFilterOptions = [defaultFilterOption].concat(gradeLevelOptions.slice(1, gradeLevelOptions.length))
          const knowledgeLevelFilterOptions = [defaultFilterOption].concat(knowledgeLevelOptions.slice(1, knowledgeLevelOptions.length))
          const employerTypeFilterOptions = [defaultFilterOption].concat(employerTypeOptions.slice(1, employerTypeOptions.length))

          const payRangeFilterOptions = [defaultFilterOption].concat(payRangeOptions.slice(1, payRangeOptions.length))
          const hoursPerWeekFilterOptions = [defaultFilterOption].concat(hoursPerWeekOptions.slice(1, hoursPerWeekOptions.length))
          // const projectedHiresFilterOptions = [defaultFilterOption].concat(projectedInternOptions.slice(1, projectedInternOptions.length))
          const applicationMethodFilterOptions = [defaultFilterOption].concat(applicationMethodOptions)
          const workTypeFilterOptions = [defaultFilterOption].concat(response.data.workOptions[0].workTypeOptions.slice(1,response.data.workOptions[0].workTypeOptions.length))

          const eventFilters = [
            { name: 'Event Type', value: defaultFilterOption, options: eventTypeFilterOptions},
          ]

          let projectFilters = [
            { name: 'Work Function', value: defaultFilterOption, options: functionFilterOptions },
            { name: 'Industry', value: defaultFilterOption, options: industryFilterOptions },
            { name: 'Time Commitment', value: defaultFilterOption, options: durationFilterOptions },
            { name: 'Contributor Role Name', value: defaultFilterOption, options: roleNameFilterOptions },
            { name: 'Difficulty Level', value: defaultFilterOption, options: difficultyLevelFilterOptions},
            { name: 'Grade Level', value: defaultFilterOption, options: gradeLevelFilterOptions },
            { name: 'Knowledge Level', value: defaultFilterOption, options: knowledgeLevelFilterOptions },
            { name: 'Employer Type', value: defaultFilterOption, options: employerTypeFilterOptions },
            // { name: 'Employer Size', value: defaultFilterOption, options: employerSizeFilterOptions },
            // { name: 'Employer Growth', value: defaultFilterOption, options: contributorFilterOptions },
          ]

          let workFilters = [
            { name: 'Work Type', value: defaultFilterOption, options: workTypeFilterOptions },
            { name: 'Work Function', value: defaultFilterOption, options: functionFilterOptions },
            { name: 'Industry', value: defaultFilterOption, options: industryFilterOptions },

            // { name: 'Location', value: defaultFilterOption, options: industryFilterOptions },
            { name: 'Pay Range', value: defaultFilterOption, options: payRangeFilterOptions },
            { name: 'Hours Per Week', value: defaultFilterOption, options: hoursPerWeekFilterOptions},
            // { name: 'Projected Hires', value: defaultFilterOption, options: projectedHiresFilterOptions },
            { name: 'Application Method', value: defaultFilterOption, options: applicationMethodFilterOptions },
            { name: 'Employer Type', value: defaultFilterOption, options: employerTypeFilterOptions },
            { name: 'Years of Experience', value: defaultFilterOption, options: [defaultFilterOption].concat(response.data.workOptions[0].yearsOfExperienceOptions) },

            // { name: 'Employer Size', value: defaultFilterOption, options: employerSizeFilterOptions },
            // { name: 'Employer Growth', value: defaultFilterOption, options: contributorFilterOptions },
          ]

          let postingsFilters = [
            { name: 'Post Type', value: defaultFilterOption, options: postTypeFilterOptions },
          ]

          // if (activeOrg === 'c2c') {
          //   const politicalPartyOptions = response.data.workOptions[0].politicalAlignmentOptions
          //   workFilters.push({ name: 'Political Party', value: defaultFilterOption, options: [defaultFilterOption].concat(politicalPartyOptions)})
          // }

          //sort options
          const defaultSortOption = this.state.defaultSortOption

          const eventSorters = []

          const projectSorters = [
            { name: 'Prize Money', value: defaultSortOption, options: [defaultSortOption].concat(['Most'])},
            // { name: 'Popularity', value: defaultSortOption, options: [defaultSortOption].concat(['Highest'])},
          ]

          const workSorters = [
            // { name: 'Match', value: defaultSortOption, options: [defaultSortOption].concat(['Highest'])},
            { name: 'Application Deadline', value: defaultSortOption, options: [defaultSortOption].concat(['Soonest'])},
            { name: 'Start Date', value: defaultSortOption, options: [defaultSortOption].concat(['Soonest'])},
          ]

          let allFilters = []
          const totalFilters = postingsFilters.concat(workFilters, projectFilters, eventFilters)
          let filterTracker = []
          for (let i = 1; i <= totalFilters.length; i++) {
            if (!filterTracker.includes(totalFilters[i - 1].name)) {
              filterTracker.push(totalFilters[i - 1].name)
              if (totalFilters[i - 1].name === 'Post Type') {
                allFilters.push({ name: 'Post Type', value: defaultFilterOption, options: [defaultFilterOption].concat(postTypeOptions) })
              } else {
                allFilters.push(totalFilters[i - 1])
              }
            }
          }

          let allSorters = []
          const totalSorters = workSorters.concat(projectSorters, eventSorters)
          let sorterTracker = []
          for (let i = 1; i <= totalSorters.length; i++) {
            if (!sorterTracker.includes(totalSorters[i - 1].name)) {
              sorterTracker.push(totalSorters[i - 1].name)
              allSorters.push(totalSorters[i - 1])
            }
          }

          this.setState({
            eventFilters, eventSorters, projectFilters, projectSorters, workFilters, workSorters, allFilters, allSorters,
            hourlyPayOptions, annualPayOptions, employeeCountOptions, hourOptions
          });

          // if (activeOrg === 'c2c') {
          //   const orgCode = activeOrg
          //
          //   Axios.get('/api/benchmarks', { params: { orgCode } })
          //   .then((response) => {
          //     console.log('Benchmarks query attempted');
          //
          //       if (response.data.success) {
          //         console.log('benchmark query worked')
          //
          //         if (response.data.benchmarks.length !== 0) {
          //           //jobs = response.data.postings
          //
          //           functionOptions = []
          //
          //           let benchmarks = response.data.benchmarks
          //           for (let i = 1; i <= benchmarks.length; i++) {
          //             if (!benchmarks[i - 1].title.includes('Scholarship') && !benchmarks[i - 1].title.includes('C2C')) {
          //               if (!functionOptions.includes(benchmarks[i - 1].jobFunction)) {
          //                 functionOptions.push(benchmarks[i - 1].jobFunction)
          //               }
          //             }
          //           }
          //
          //           functionOptions.sort()
          //
          //           projectFilters[0]['options'] = [defaultFilterOption].concat(functionOptions)
          //           workFilters[0]['options'] = [defaultFilterOption].concat(functionOptions)
          //
          //           this.setState({ projectFilters, workFilters })
          //         }
          //       }
          //   })
          // }

        }
      });

      let viewIndex = 0
      if (this.props.passedViewIndex) {
        // for problem-platform
        viewIndex = this.props.passedViewIndex
      }

      let subNavSelected = 'All'
      if (this.props.passedSubNavSelected) {
        subNavSelected = this.props.passedSubNavSelected
      } else if (opportunitiesNavSelected) {
        subNavSelected = opportunitiesNavSelected
      }

      let subNavCategories = ['All','Featured','Work','Projects','Events']
      if (this.state.showPrograms) {
        subNavCategories = ['All','Featured','Work','Programs','Projects','Events']
      }
      // if (activeOrg !== 'unite-la') {
      //   subNavCategories = ['All','Featured','Work','Projects','Events','View External Jobs']
      // }

      this.setState({ viewIndex, subNavSelected, subNavCategories })
      console.log('we email', email)
      if (!email || email === '') {
        console.log('no email found', this.props.noOrgCode)

        activeOrg = this.props.activeOrg

        if (activeOrg || this.props.noOrgCode) {
          // console.log('show orgCode: dd', activeOrg, this.props.noOrgCode)
          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response2) => {
            console.log('Org info query attempted within nested opportunities');

            if (response2.data.success) {
              console.log('org info query worked!')

              let placementPartners = []
              if (response2.data.orgInfo.placementPartners) {
                placementPartners = response2.data.orgInfo.placementPartners
              }

              const pageSource = this.props.pageSource
              this.setState({ pageSource, activeOrg })

              this.retrievePostings(activeOrg, placementPartners, null, pathway)

            } else {
              console.log('there was an error findign the org')

              this.setState({ pageSource: this.props.pageSource })
              this.retrievePostings(null, null, null, null)

            }
          }).catch((error) => {
             console.log('Error finding the org', error);
          });
        }

      } else {

        if (activeOrg || this.props.noOrgCode) {
          let placementAgency = false
          if (activeOrg === 'bixel' || activeOrg === 'tip' || activeOrg === 'c2c' || activeOrg === 'layaw' || activeOrg === 'exp' || activeOrg === 'unite-la') {
            placementAgency = true
          }

          const path = window.location.pathname

          let subpath = ''
          if (path) {
            let pathArray = path.split('/')
            if (pathArray[1]) {
              subpath = pathArray[1]
            }
          }

          const problemTypeOptions = ['','Exploratory','Technical']
          const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Difficult','Challenger']
          const popularityOptions = ['','More than 10','More than 50']
          const postDateOptions = ['','Within the Last Month','Within the Last Year']
          const pageSource = this.props.pageSource

          this.setState({ emailId: email, username, activeOrg, roleName, placementAgency, path, subpath,
            problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions, pageSource, employerAccount
          });

          let placementPartners = []

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {
            console.log('User details query attempted');

            if (response.data.success) {
               console.log('successfully retrieved user details')

               const courseIds = response.data.user.courseIds

               Axios.get('/api/assessment/results', { params: { emailId: email } })
                .then((response2) => {
                  console.log('query for assessment results worked');

                  if (response2.data.success) {

                    console.log('actual assessment results')

                    // let wpData = null
                    // if (response.data.results.workPreferenceAnswers) {
                    //   wpData
                    // }


                    let profile = response.data.user
                    profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                    profile['interests'] = response2.data.results.interestScores
                    profile['personality'] = response2.data.results.personalityScores
                    profile['skills'] = response2.data.results.newSkillAnswers
                    profile['gravitateValues'] = response2.data.results.topGravitateValues
                    profile['employerValues'] = response2.data.results.topEmployerValues
                    profile['selectedGoal'] = this.props.selectedGoal

                    // let matchingCriteria = this.state.matchingCriteria
                    if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                      matchingCriteria = response.data.user.matchingPreferences
                    }
                    if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                      useCases = response.data.user.matchingUseCases
                    }

                    this.setState({ profile, matchingCriteria, useCases })

                    if (this.props.pageSource === 'Goal') {
                      this.calculateMatches(true, true, false, [this.props.pageSource], 1000, activeOrg)
                    }

                  } else {
                    console.log('no assessment results')

                    // let matchingCriteria = this.state.matchingCriteria
                    if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                      matchingCriteria = response.data.user.matchingPreferences
                    }
                    if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                      useCases = response.data.user.matchingUseCases
                    }
                    this.setState({ matchingCriteria, useCases })
                  }

              }).catch((error) => {
                  console.log('query for assessment results did not work', error);
                  // let matchingCriteria = this.state.matchingCriteria
                  if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                    matchingCriteria = response.data.user.matchingPreferences
                  }
                  if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                    useCases = response.data.user.matchingUseCases
                  }
                  this.setState({ matchingCriteria, useCases })
              })

               Axios.get('/api/org', { params: { orgCode: activeOrg } })
               .then((response2) => {
                 console.log('Org info query attempted within nested opportunities');

                 if (response2.data.success) {
                   console.log('org info query worked!')

                   if (response2.data.orgInfo.placementPartners) {
                     placementPartners = response2.data.orgInfo.placementPartners
                   }

                   let calculateMatches  = false
                   if (this.props.calculateMatches) {
                     calculateMatches = true
                   }

                   if (this.props.pageSource !== 'Goal') {
                     this.retrievePostings(activeOrg, placementPartners, courseIds, pathway, calculateMatches, roleName)
                   }

                 } else {
                   console.log('there was an error findign the org')

                 }
               }).catch((error) => {
                  console.log('Error finding the org', error);
               });

            } else {
             console.log('no user details data found', response.data.message)
            }

          }).catch((error) => {
             console.log('User details query did not work', error);
          });

          Axios.get('/api/favorites', { params: { emailId: email } })
          .then((response) => {
            console.log('Favorites query attempted');

             if (response.data.success) {
               console.log('successfully retrieved favorites')

               let favorites = []
               if (response.data.favorites) {
                 favorites = response.data.favorites
               }

               let newFavorites = []
               if (response.data.newFavorites) {
                 newFavorites = response.data.newFavorites
               }

               this.setState({ favorites, newFavorites })

             } else {
               console.log('no favorites data found', response.data.message)
             }

          }).catch((error) => {
             console.log('Favorites query did not work', error);
          });
        }
      }

      Axios.get('/api/applications', { params: { emailId: email, orgCode: activeOrg } })
      .then((response) => {
        console.log('Applications query attempted');

        if (response.data.success) {
          console.log('applications query worked')

          if (response.data.applications.length !== 0) {
            let applications = response.data.applications
            //jobs.push(response.data.postings[0])
            this.setState({ applications });
          }

        } else {
          console.log('query for applications query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Applications query did not work for some reason', error);
      });

      Axios.get('/api/rsvps', { params: { email } })
      .then((response) => {
        console.log('Rsvp query attempted');

        if (response.data.success) {
          console.log('rsvp query worked')

          let rsvps = response.data.rsvps
          this.setState({ rsvps })


        } else {
          console.log('rsvp query did not work', response.data.message)
          //there is no rsvp data

        }

      }).catch((error) => {
          console.log('Rsvp query did not work for some reason', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

          this.setState({ onMobile: true })

      }
    }

    retrievePostings(orgCode, placementPartners, courseIds, pathway, calculateMatches, roleName) {
      console.log('retrievePostings called')

      let postType = undefined
      let projectPromptType = undefined
      if (this.props.passedType) {
        postType = this.props.passedType
        if (this.props.passedType === 'Project' || this.props.passedType === 'Assignment' || this.props.passedType === 'Problem' || this.props.passedType === 'Challenge') {
          postType = null
          projectPromptType = this.props.passedType
        }
      }

      let postTypes = []
      if (this.props.passedTypes) {
        postTypes = this.props.passedTypes
      }

      let recent = true
      let active = true
      let excludeUnlisted = true
      let excludePastEvents = true

      let queryOrgCode = orgCode
      if (window.location.pathname.includes('/problem-platform')) {
        queryOrgCode = null
        recent = null
      }
      // placementPartners = 'guidedcompass'

      let accountCode = this.props.accountCode

      if (this.props.pageSource === 'landingPage' && window.location.pathname.includes('/employers/')) {
        recent = null
      }

      const pullPartnerPosts = true

      let targetRole = null
      if (this.props.targetRole) {
        targetRole = this.props.targetRole
      }

      let subPostType = null
      if (this.props.passedSubPostType) {
        subPostType = this.props.passedSubPostType
      }

      const postingQueryObject = { params: { orgCode: queryOrgCode, placementPartners,
        postType, postTypes, projectPromptType, pathway, accountCode, recent, active, excludeUnlisted, excludePastEvents,
        pullPartnerPosts, csWorkflow: true,
        roleName, targetRole, subPostType
      }}

      // console.log('show me postingQueryObject: ', postingQueryObject)

      //only schools see this screen
      Axios.get('/api/postings/user', postingQueryObject)
      .then((response) => {
        console.log('Posted postings query attempted within subcomponent', queryOrgCode, response.data);

        if (response.data.success) {
          console.log('posted postings query worked')

          if (response.data.postings.length !== 0) {

            // let useFunction = true

            const allPostings = this.filterPostings(response.data.postings, courseIds)

            const featuredOpportunities = allPostings.featuredOpportunities
            const filteredFeaturedOpportunities = featuredOpportunities

            const projectWork = allPostings.projectWork
            const filteredProjectWork =  projectWork
            const internships = allPostings.internships
            const filteredInternships = internships
            const work = allPostings.work
            const filteredWork = work

            const programs = allPostings.programs
            const filteredPrograms = programs

            const events = allPostings.events
            const filteredEvents = events

            const upcomingEvents = allPostings.upcomingEvents
            const filteredUpcomingEvents = upcomingEvents
            const pastEvents = allPostings.pastEvents
            const filteredPastEvents = pastEvents

            const adjustedPostings = allPostings.adjustedPostings

            // this should be adjustedPostings
            // const postings = allPostings.postings
            const postings = adjustedPostings
            const filteredPostings = postings

            this.setState({ featuredOpportunities, filteredFeaturedOpportunities,
              projectWork, filteredProjectWork, internships, filteredInternships, work, filteredWork, programs, filteredPrograms,
              events, filteredEvents, upcomingEvents, filteredUpcomingEvents, pastEvents, filteredPastEvents,
              postings, filteredPostings, adjustedPostings,
              orgCode: queryOrgCode, placementPartners, postType, postTypes, pathway
            });

            if (calculateMatches) {
              this.calculateMatches(true, true, false, null, null, queryOrgCode)
            }
          }

        } else {
          console.log('posted postings query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Posted postings query did not work for some reason', error);
      });
    }

    filterPostings(postings, courseIds, matchScores) {
      console.log('filterPostings called. MOVE TO BACK-END!')

      let adjustedPostings = []
      let featuredOpportunities = []
      let assignments = []
      let problems = []
      let challenges = []
      let projectWork = []
      let internships = []
      let work = []
      let programs = []
      let events = []
      let upcomingEvents = []
      let pastEvents = []

      for (let i = 1; i <= postings.length; i++) {
        console.log('show posttype: ', postings[i - 1].postType)
        if (matchScores && postings.length === matchScores.length) {
          postings[i - 1]['matchScore'] = matchScores[i - 1]
        }

        // isChild, isActive, submissionDeadline, requestMode

        if (postings[i - 1].featured) {

          featuredOpportunities.push(postings[i - 1])

        }

        if (postings[i - 1].postType === 'Individual' || postings[i - 1].postType === 'Track' || postings[i - 1].postType === 'Internship' || postings[i - 1].postType === 'Work') {

          internships.push(postings[i - 1])
          work.push(postings[i - 1])
          adjustedPostings.push(postings[i - 1])
        } else if (postings[i - 1].postType === 'Program') {
          programs.push(postings[i - 1])
          adjustedPostings.push(postings[i - 1])
        } else if (postings[i - 1].postType === 'Assignment') {
          assignments.push(postings[i - 1])
        } else if (postings[i - 1].postType === 'Problem') {
          problems.push(postings[i - 1])
        } else if (postings[i - 1].postType === 'Challenge') {
          challenges.push(postings[i - 1])
        } else if (postings[i - 1].postType === 'Event') {
          events.push(postings[i - 1])

          let dateToTest = null
          if (postings[i - 1].endDate && new Date(postings[i - 1].endDate)) {
            dateToTest = postings[i - 1].endDate
          } else if (postings[i - 1].startDate && new Date(postings[i - 1].startDate)) {
            dateToTest = postings[i - 1].startDate
          }

          if (new Date(dateToTest).getTime() > new Date().getTime()) {
            upcomingEvents.push(postings[i - 1])
            adjustedPostings.push(postings[i - 1])

          } else {
            pastEvents.push(postings[i - 1])
            adjustedPostings.push(postings[i - 1])
          }

        } else if (postings[i - 1].postType === 'Scholarship') {
          adjustedPostings.push(postings[i - 1])
        } else {
          //perhaps scholarship
        }

        if (postings[i - 1].postType === 'Project' || postings[i - 1].postType === 'Assignment' || postings[i - 1].postType === 'Problem' || postings[i - 1].postType === 'Challenge') {

          if (postings[i - 1].attachToCourses) {
            // only for specific courses
            if (courseIds && courseIds.length > 0 && postings[i - 1].courses && postings[i - 1].courses.some(course => courseIds.includes(course._id))) {
              projectWork.push(postings[i - 1])
              adjustedPostings.push(postings[i - 1])
            }
          } else {
            // open to everyone

            projectWork.push(postings[i - 1])
            adjustedPostings.push(postings[i - 1])

          }

        } else if (postings[i - 1].postType === 'Work' || postings[i - 1].postType === 'Internship' || postings[i - 1].postType === 'Individual' || postings[i - 1].postType === 'Track') {

        } else {

        }
      }

      upcomingEvents.sort(function(a,b) {
        let startDateString1 = a.startDate
        let startDateString2 = b.startDate
        if (startDateString1 && startDateString2) {
          let startDate1 = new Date(startDateString1)
          let startDate2 = new Date(startDateString2)
          return startDate1 - startDate2;
        }
      })

      pastEvents.sort(function(a,b) {
        let startDateString1 = a.startDate
        let startDateString2 = b.startDate
        if (startDateString1 && startDateString2) {
          let startDate1 = new Date(startDateString1)
          let startDate2 = new Date(startDateString2)
          return startDate2 - startDate1;
        }
      })

      const allPostings = { postings, adjustedPostings, featuredOpportunities, projectWork, internships, work, programs,
        events, upcomingEvents, pastEvents
      }

      // console.log('show postings: ', postings.length, projectWork.length)

      return allPostings

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        let type = ''

        let isEvents = false
        if (this.state.subNavSelected === 'Events') {
          isEvents = true
        } else if (this.state.viewIndex === 1) {
          isEvents = true
        }

        let isProjects = false
        if (this.state.subNavSelected === 'Projects') {
          isProjects = true
        } else if (this.state.viewIndex === 5) {
          isProjects = true
        }

        let isWork = false
        if (this.state.subNavSelected === 'Work') {
          isWork = true
        } else if (this.state.viewIndex === 7) {
          isWork = true
        }

        let isAll = false
        if (this.state.subNavSelected === 'All') {
          isAll = true
        } else if (this.state.viewIndex === 8) {
          isAll = true
        }

        if (isEvents) {
          // events
          type = 'Event'
          let eventFilters = this.state.eventFilters
          for (let i = 1; i <= eventFilters.length; i++) {
            eventFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          let eventSorters = this.state.eventSorters
          for (let i = 1; i <= eventSorters.length; i++) {
            eventSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, eventFilters, eventSorters, animating: true })

        } else if (isProjects) {
          // projects
          type = 'Project'
          let projectFilters = this.state.projectFilters
          for (let i = 1; i <= projectFilters.length; i++) {
            projectFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          let projectSorters = this.state.projectSorters
          for (let i = 1; i <= projectSorters.length; i++) {
            projectSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, projectFilters, projectSorters, animating: true })

        } else if (isWork) {
          // work
          type = 'Work'
          let workFilters = this.state.workFilters
          for (let i = 1; i <= workFilters.length; i++) {
            workFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          let workSorters = this.state.workSorters
          for (let i = 1; i <= workSorters.length; i++) {
            workSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, workFilters, workSorters, animating: true })

        } else if (isAll) {
          // all
          type = 'All'
          let allFilters = this.state.allFilters
          for (let i = 1; i <= allFilters.length; i++) {
            allFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          let allSorters = this.state.allSorters
          for (let i = 1; i <= allSorters.length; i++) {
            allSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          this.setState({ searchString, allFilters, allSorters, animating: true })

        }

        this.filterResults(event.target.value, '', null, null, true, type)

      } else if (event.target.name.includes('filter|')) {

        let type = ''
        let filters = []
        let index = 0

        let isEvents = false
        if (this.state.subNavSelected === 'Events') {
          isEvents = true
        } else if (this.state.viewIndex === 1) {
          isEvents = true
        }
        let isProjects = false
        if (this.state.subNavSelected === 'Projects') {
          isProjects = true
        } else if (this.state.viewIndex === 5) {
          isProjects = true
        }

        let isWork = false
        if (this.state.subNavSelected === 'Work') {
          isWork = true
        } else if (this.state.viewIndex === 7) {
          isWork = true
        }

        let isAll = false
        if (this.state.subNavSelected === 'All') {
          isAll = true
        } else if (this.state.viewIndex === 8) {
          isAll = true
        }

        if (isEvents) {
          // events
          type = 'Event'

          let eventFilters = this.state.eventFilters
          filters = eventFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name !== field) {
              filters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }

          let eventSorters = this.state.eventSorters
          for (let i = 1; i <= eventSorters.length; i++) {
            eventSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          eventFilters = filters

          this.setState({ filters, animating: true, searchString, eventFilters, eventSorters })

        } else if (isProjects) {

          // projects
          type = 'Project'
          let projectFilters = this.state.projectFilters
          filters = projectFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name !== field) {
              filters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }

          let projectSorters = this.state.projectSorters
          for (let i = 1; i <= projectSorters.length; i++) {
            projectSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          projectFilters = filters

          this.setState({ filters, animating: true, searchString, projectFilters, projectSorters })

        } else if (isWork) {
          // work
          type = 'Work'
          let workFilters = this.state.workFilters
          filters = workFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name !== field) {
              filters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }

          let workSorters = this.state.workSorters
          for (let i = 1; i <= workSorters.length; i++) {
            workSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          workFilters = filters

          this.setState({ filters, animating: true, searchString, workFilters, workSorters })
        } else if (isAll) {
          // all
          type = 'All'
          let allFilters = this.state.allFilters
          filters = allFilters

          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name === field) {
              filters[i - 1]['value'] = event.target.value
              index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          for (let i = 1; i <= filters.length; i++) {
            if (filters[i - 1].name !== field) {
              filters[i - 1]['value'] = this.state.defaultFilterOption
            }
          }

          let allSorters = this.state.allSorters
          for (let i = 1; i <= allSorters.length; i++) {
            allSorters[i - 1]['value'] = this.state.defaultSortOption
          }

          allFilters = filters

          this.setState({ filters, animating: true, searchString, allFilters, allSorters })
        }

        this.filterResults(this.state.searchString, event.target.value, filters, index, false, type)

      } else if (event.target.name.includes('sort|')) {

        let type = ''

        let isEvents = false
        if (this.state.subNavSelected === 'Events') {
          isEvents = true
        } else if (this.state.viewIndex === 1) {
          isEvents = true
        }
        let isProjects = false
        if (this.state.subNavSelected === 'Projects') {
          isProjects = true
        } else if (this.state.viewIndex === 5) {
          isProjects = true
        }

        let isWork = false
        if (this.state.subNavSelected === 'Work') {
          isWork = true
        } else if (this.state.viewIndex === 7) {
          isWork = true
        }

        let isAll = false
        if (this.state.subNavSelected === 'All') {
          isAll = true
        } else if (this.state.viewIndex === 8) {
          isAll = true
        }

        if (isEvents) {
          type = 'Event'

          let eventSorters = this.state.eventSorters
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= eventSorters.length; i++) {
            if (eventSorters[i - 1].name === field) {
              eventSorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          let eventFilters = this.state.eventFilters
          for (let i = 1; i <= eventFilters.length; i++) {
            eventFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= eventSorters.length; i++) {
            if (eventSorters[i - 1].name !== field) {
              eventSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, eventFilters, eventSorters, animating: true })

        } else if (isProjects) {
          type = 'Project'
          let projectSorters = this.state.projectSorters
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= projectSorters.length; i++) {
            if (projectSorters[i - 1].name === field) {
              projectSorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          let projectFilters = this.state.projectFilters
          for (let i = 1; i <= projectFilters.length; i++) {
            projectFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= projectSorters.length; i++) {
            if (projectSorters[i - 1].name !== field) {
              projectSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, projectFilters, projectSorters, animating: true })
        } else if (isWork) {
          type = 'Work'
          let workSorters = this.state.workSorters
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= workSorters.length; i++) {
            if (workSorters[i - 1].name === field) {
              workSorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          let workFilters = this.state.workFilters
          for (let i = 1; i <= workFilters.length; i++) {
            workFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= workSorters.length; i++) {
            if (workSorters[i - 1].name !== field) {
              workSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, workFilters, workSorters, animating: true })
        } else if (isAll) {
          type = 'All'
          let allSorters = this.state.allSorters
          const nameArray = event.target.name.split("|")
          const field = nameArray[1]

          // let index = 0
          for (let i = 1; i <= allSorters.length; i++) {
            if (allSorters[i - 1].name === field) {
              allSorters[i - 1]['value'] = event.target.value
              // index = i - 1
            }
          }

          //reset everything
          let searchString = ''
          let allFilters = this.state.allFilters
          for (let i = 1; i <= allFilters.length; i++) {
            allFilters[i - 1]['value'] = this.state.defaultFilterOption
          }

          for (let i = 1; i <= allSorters.length; i++) {
            if (allSorters[i - 1].name !== field) {
              allSorters[i - 1]['value'] = this.state.defaultSortOption
            }
          }

          this.setState({ searchString, allFilters, allSorters, animating: true })
        }

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]
        this.sortResults(event.target.value, field, type)

      } else if (event.target.name.includes('useCase')) {
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1].trim())

        let useCases = this.state.useCases
        // useCases[index]["value"] = event.target.value
        for (let i = 1; i <= useCases.length; i++) {
          if (i - 1 === index) {
            useCases[index]["selected"] = true
          } else {
            useCases[index]["selected"] = false
          }
        }

        let matchingCriteria = this.state.matchingCriteria
        if (useCases[index].name === 'Interests') {
          // max interests, personality

           matchingCriteria[0]["value"] = 0 // work function
           matchingCriteria[1]["value"] = 0 // industry
           matchingCriteria[2]["value"] = 0 // location
           matchingCriteria[3]["value"] = 0 // pay
           matchingCriteria[4]["value"] = 0 // stability
           matchingCriteria[5]["value"] = 60 // interests
           matchingCriteria[6]["value"] = 40 // personality
           matchingCriteria[7]["value"] = 0 // values
           matchingCriteria[8]["value"] = 0 // skills
           matchingCriteria[9]["value"] = 0 // education
           matchingCriteria[10]["value"] = 0 // projects
           matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Competitive Advantage') {
          // max rare, in-demand skills

          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 70 // skills
          matchingCriteria[9]["value"] = 10 // education
          matchingCriteria[10]["value"] = 10 // projects
          matchingCriteria[11]["value"] = 10 // experience

        } else if (useCases[index].name === 'Purpose') {
          // max interests, mission-driven opportunities
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 25 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 50 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 25 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Stability') {
          // max interests
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 100 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Pay') {
          // max pay
          matchingCriteria[0]["value"] = 0 // work function
          matchingCriteria[1]["value"] = 0 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 100 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 0 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 0 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        } else if (useCases[index].name === 'Recognition') {
          // max social, artistic, prestige, client-facing (sales)
          matchingCriteria[0]["value"] = 10 // work function
          matchingCriteria[1]["value"] = 30 // industry
          matchingCriteria[2]["value"] = 0 // location
          matchingCriteria[3]["value"] = 0 // pay
          matchingCriteria[4]["value"] = 0 // stability
          matchingCriteria[5]["value"] = 40 // interests
          matchingCriteria[6]["value"] = 0 // personality
          matchingCriteria[7]["value"] = 0 // values
          matchingCriteria[8]["value"] = 20 // skills
          matchingCriteria[9]["value"] = 0 // education
          matchingCriteria[10]["value"] = 0 // projects
          matchingCriteria[11]["value"] = 0 // experience

        }

        this.setState({ useCases, matchingCriteria })
      } else if (event.target.name.includes('custom')) {
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1].trim())

        const ogValue = this.state.matchingCriteria[index].value
        const diff = event.target.value - ogValue
        const totalPercent = this.state.totalPercent + diff

        let matchingCriteria = this.state.matchingCriteria
        matchingCriteria[index]["value"] = Number(event.target.value)
        this.setState({ matchingCriteria, totalPercent })
      }
    }

    filterResults(searchString, filterString, filters, index, search, type) {
      console.log('filterResults called')

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption

      let postings = []
      if (this.state.subNavSelected === 'Events') {
        postings = this.state.events
      } else if (this.state.subNavSelected === 'Projects') {
        postings = this.state.projectWork
      } else if (this.state.subNavSelected === 'Work') {
        postings = this.state.work
      } else if (this.state.subNavSelected === 'All') {
        postings = this.state.postings
      }

      const emailId = this.state.emailId

      const roleName = this.state.roleName
      let targetRole = null
      if (this.props.targetRole) {
        targetRole = this.props.targetRole
      }

      let subPostType = null
      if (this.props.passedSubPostType) {
        subPostType = this.props.passedSubPostType
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        // console.log('first matchScore: ', postings[0].matchScore, postings[0].title, postings[0].name)

        Axios.put('/api/postings/filter', {
          searchString, filterString, filters, defaultFilterOption, index, search, postings, type, emailId,
          roleName, targetRole, subPostType
        })
        .then((response) => {
          console.log('Posting filter query attempted');

            if (response.data.success) {
              console.log('posting filter query worked')

              // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)

              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              if (self.state.subNavSelected === 'Events') {

                const filteredEvents = response.data.postings
                let filteredUpcomingEvents = []
                let filteredPastEvents = []
                for (let i = 1; i <= filteredEvents.length; i++) {
                  if (filteredEvents[i - 1].startDate && new Date(filteredEvents[i - 1].startDate)) {
                    if ((new Date() - new Date(filteredEvents[i - 1].startDate)) > 0) {
                      filteredPastEvents.push(postings[i - 1])
                    } else {
                      filteredUpcomingEvents.push(postings[i - 1])
                    }
                  }
                }
                self.setState({ filteredEvents, filteredUpcomingEvents, filteredPastEvents, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (self.state.subNavSelected === 'Projects') {
                const filteredProjectWork = response.data.postings
                self.setState({ filteredProjectWork, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (self.state.subNavSelected === 'Programs') {
                const filteredPrograms = response.data.postings
                self.setState({ filteredPrograms, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (self.state.subNavSelected === 'Work') {
                const filteredWork = response.data.postings
                self.setState({ filteredWork, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (self.state.subNavSelected === 'All') {
                const filteredPostings = response.data.postings
                self.setState({ filteredPostings, animating: false, filterCriteriaArray, sortCriteriaArray })
              }

            } else {
              console.log('posting filter query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Posting filter query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let postings = []
      if (this.state.viewIndex === 1) {
        postings = this.state.events
      } else if (this.state.viewIndex === 5) {
        postings = this.state.projectWork
      } else if (this.state.viewIndex === 7) {
        postings = this.state.work
      } else if (this.state.viewIndex === 8) {
        postings = this.state.postings
      }

      if (sortName === 'Match') {
        console.log('in benchmark match')

        // const appEnd = postings.length - 1
        //
        // for (let i = 1; i <= postings.length; i++) {
        //   const index = i - 1
        //
        //   Axios.get('/api/assessment/results', { params: { emailId: this.state.emailId } })
        //   .then((response) => {
        //       console.log('query for assessment results worked');
        //
        //       if (response.data.success) {
        //
        //         console.log('actual assessment results', response.data)
        //
        //         let wpData = null
        //         if (response.data.results.workPreferenceAnswers) {
        //           wpData = response.data.results.workPreferenceAnswers
        //         }
        //
        //         let interestResults = null
        //         if (response.data.results.interestScores) {
        //           interestResults = response.data.results.interestScores
        //         }
        //
        //         let personalityResults = null
        //         if (response.data.results.personalityScores) {
        //           personalityResults = response.data.results.personalityScores
        //         }
        //
        //         const skillAnswers = null
        //
        //         let application = userDetails
        //         application['workPreferenceResults'] = wpData
        //         application['interestResults'] = interestResults
        //         application['personalityResults'] = personalityResults
        //         application['skillAnswers'] = skillAnswers
        //
        //         const selectedJob = { title: sortString, postType: 'Individual' }
        //         console.log('about to add benchmark')
        //         let benchmark = null
        //         for (let j = 1; j <= this.state.benchmarks.length; j++) {
        //           console.log('compare benchmarks: ',this.state.benchmarks[j - 1].title, sortString)
        //           if (this.state.benchmarks[j - 1].title === sortString) {
        //             benchmark = this.state.benchmarks[j - 1]
        //             console.log('got it')
        //           }
        //         }
        //         console.log('show benchmark: ', benchmark)
        //
        //         let endLoop = false
        //         if (index === appEnd) {
        //           endLoop = true
        //         }
        //
        //
        //         const activeOrg = this.state.activeOrg
        //         console.log('compare index and endLoop: ', index, appEnd, endLoop, activeOrg )
        //
        //         // pull benchmark
        //         const _id = postings[index].benchmarkId
        //         Axios.get('/api/benchmarks/byid', { params: { _id } })
        //         .then((response) => {
        //           console.log('Benchmarks query attempted', response.data);
        //
        //             if (response.data.success) {
        //               console.log('benchmark query worked')
        //               const benchmark = response.data.benchmark
        //
        //               if (benchmark) {
        //
        //                 Axios.post('/api/applications/matches', {
        //                   application, selectedJob, benchmark, activeOrg })
        //                 .then((response) => {
        //                   console.log('application matches query attempted within members')
        //                   if (response.data.success) {
        //                     //save values
        //                     console.log('Application matches save worked', response.data);
        //
        //                     filteredMembers.push(response.data.application)
        //                     console.log('at the end? 1', index, appEnd, endLoop, filteredMembers.length)
        //
        //                     if (endLoop) {
        //                       filteredMembers.sort(function(a,b) {
        //                         return b.match - a.match;
        //                       })
        //                       console.log('got in')
        //                       this.setState({ filteredMembers, animating: false });
        //                     }
        //
        //                   } else {
        //                     console.log('application matches did not work', response.data.message)
        //                     console.log('at the end? 2', index, appEnd, endLoop)
        //                     if (index === appEnd) {
        //                       filteredMembers.sort(function(a,b) {
        //                         return b.match - a.match;
        //                       })
        //                       console.log('got in')
        //                       this.setState({ filteredMembers, animating: false });
        //                     }
        //                   }
        //                 }).catch((error) => {
        //                     console.log('Application matches did not work for some reason', error);
        //                     console.log('at the end? 3', index, appEnd, endLoop)
        //                     if (index === appEnd) {
        //                       filteredMembers.sort(function(a,b) {
        //                         return b.match - a.match;
        //                       })
        //                       console.log('got in')
        //                       this.setState({ filteredMembers, animating: false });
        //                     }
        //                 });
        //               }
        //
        //             }
        //         })
        //
        //       } else {
        //         console.log('error response for assessments', response.data)
        //       }
        //   }).catch((error) => {
        //       console.log('query for assessment results did not work', error);
        //   })
        // }

      } else {

        Axios.put('/api/postings/sort', { sortString, postings, sortName })
        .then((response) => {
          console.log('Postings sort query attempted', response.data);

            if (response.data.success) {
              console.log('posting sort query worked')

              // THIS IS SUPER UNNECESSARY
              const filterCriteriaArray = null
              const sortCriteriaArray = response.data.sortCriteriaArray

              let isEvents = false
              if (this.state.subNavSelected === 'Events') {
                isEvents = true
              } else if (this.state.viewIndex === 1) {
                isEvents = true
              }

              let isProjects = false
              if (this.state.subNavSelected === 'Projects') {
                isProjects = true
              } else if (this.state.viewIndex === 5) {
                isProjects = true
              }

              let isPrograms = false
              if (this.state.subNavSelected === 'Programs') {
                isPrograms = true
              } else if (this.state.viewIndex === 9) {
                isPrograms = true
              }

              let isWork = false
              if (this.state.subNavSelected === 'Work') {
                isWork = true
              } else if (this.state.viewIndex === 7) {
                isWork = true
              }

              let isAll = false
              if (this.state.subNavSelected === 'All') {
                isAll = true
              } else if (this.state.viewIndex === 8) {
                isAll = true
              }

              if (isEvents) {
                const filteredEvents = response.data.postings
                let filteredUpcomingEvents = []
                let filteredPastEvents = []
                for (let i = 1; i <= filteredEvents.length; i++) {
                  if (filteredEvents[i - 1].startDate && new Date(filteredEvents[i - 1].startDate)) {
                    if ((new Date() - new Date(filteredEvents[i - 1].startDate)) > 0) {
                      filteredPastEvents.push(postings[i - 1])
                    } else {
                      filteredUpcomingEvents.push(postings[i - 1])
                    }
                  }
                }
                this.setState({ filteredEvents, filteredUpcomingEvents, filteredPastEvents, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (isProjects) {
                const filteredProjectWork = response.data.postings
                this.setState({ filteredProjectWork, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (isPrograms) {
                const filteredPrograms = response.data.postings
                this.setState({ filteredPrograms, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (isWork) {
                const filteredWork = response.data.postings
                this.setState({ filteredWork, animating: false, filterCriteriaArray, sortCriteriaArray })
              } else if (isAll) {
                const filteredPostings = response.data.postings
                this.setState({ filteredPostings, animating: false, filterCriteriaArray, sortCriteriaArray })
              }

            } else {
              console.log('posting sort query did not work', response.data.message)
              this.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Posting sort query did not work for some reason', error);
            this.setState({ animating: false })
        });
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    toggleView(view) {
      console.log('toggleView called', view)

      if (view === 'featured') {
        this.setState({ viewIndex: 0 })
      } else if (view === 'events'){
        this.setState({ viewIndex: 1 })
      } else if (view === 'assignments'){
        this.setState({ viewIndex: 2 })
      } else if (view === 'problems') {
        this.setState({ viewIndex: 3 })
      } else if (view === 'challenges'){
        this.setState({ viewIndex: 4 })
      } else if (view === 'projectWork'){
        this.setState({ viewIndex: 5 })
      } else if (view === 'internships'){
        this.setState({ viewIndex: 6 })
      } else if (view === 'work'){
        this.setState({ viewIndex: 7 })
      }
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = []
        if (this.state.subNavSelected === 'Events') {
          // events
          filters = this.state.eventFilters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          filters = this.state.projectFilters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          filters = this.state.workFilters
        } else if (this.state.subNavSelected === 'All') {
          filters = this.state.allFilters
        }

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = []

        if (this.state.subNavSelected === 'Events') {
          // events
          sorters = this.state.eventSorters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          sorters = this.state.projectSorters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          sorters = this.state.workSorters
        } else if (this.state.subNavSelected === 'All') {
          sorters = this.state.allSorters
        }

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    calculateMatches(matchingView, calcMatches, newPreferences, specificCriteria, resLimit, passedOrgCode) {
      console.log('calculateMatches called')

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (this.state.postings) {

          if (!this.state.profile) {
            this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized recommendations' })
          } else {
            this.setState({ animating: true, modalIsOpen: false })

            // orgCode: queryOrgCode, placementPartners, postType, postTypes, pathway
            const profile = this.state.profile
            let orgCode = this.state.orgCode
            if (!orgCode && passedOrgCode) {
              orgCode = passedOrgCode
            }
            const placementPartners = this.state.placementPartners

            const pathway = this.state.pathway

            let postType = this.state.postType
            let postTypes = this.state.postTypes
            postType = null
            postTypes = null
            // if (this.state.subNavSelected === 'All') {
            //   postType = null
            //   postTypes = null
            // } else if (this.state.subNavSelected === 'Featured') {
            //   postType === 'Featured'
            // } else if (this.state.subNavSelected === 'Work') {
            //   postType = null
            //   postTypes = ['Internship','Work']
            // } else if (this.state.subNavSelected === 'Projects') {
            //   postType = null
            //   postTypes = ['Assignment','Problem','Challenge']
            // } else if (this.state.subNavSelected === 'Events') {
            //   postType = null
            //   postTypes = ['Event']
            // }

            const matchingCriteria = this.state.matchingCriteria
            const useCases = this.state.useCases

            const self = this

            function officiallyCalculate() {
              console.log('officiallyCalculate called')

              const hourlyPayOptions = self.state.hourlyPayOptions
              const annualPayOptions = self.state.annualPayOptions
              const employeeCountOptions = self.state.employeeCountOptions
              const hourOptions = self.state.hourOptions

              const queryObject = {
                profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
                annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions, specificCriteria, resLimit,
                recent: true, active: true, csWorkflow: true
              }

              // query postings on back-end
              Axios.put('/api/opportunities/matches', queryObject)
              .then((response) => {
                console.log('Opportunity matches attempted');

                  if (response.data.success) {
                    console.log('opportunity match query worked')

                    let matchScores = response.data.matchScores

                    const allPostings = self.filterPostings(response.data.postings, null, matchScores)

                    const featuredOpportunities = allPostings.featuredOpportunities
                    const filteredFeaturedOpportunities = featuredOpportunities

                    const projectWork = allPostings.projectWork
                    const filteredProjectWork =  projectWork
                    const internships = allPostings.internships
                    const filteredInternships = internships
                    const work = allPostings.work
                    const filteredWork = work

                    const programs = allPostings.programs
                    const filteredPrograms = programs

                    const events = allPostings.events
                    const filteredEvents = events

                    const upcomingEvents = allPostings.upcomingEvents
                    const filteredUpcomingEvents = upcomingEvents
                    const pastEvents = allPostings.pastEvents
                    const filteredPastEvents = pastEvents

                    const adjustedPostings = allPostings.adjustedPostings
                    const postings = adjustedPostings
                    const filteredPostings = postings

                    self.setState({ animating: false, matchingView: true, matchScores,
                      postings, filteredPostings, featuredOpportunities, filteredFeaturedOpportunities,
                      work, filteredWork, internships, filteredInternships, programs, filteredPrograms,
                      projectWork, filteredProjectWork,
                      events, filteredEvents, upcomingEvents, filteredUpcomingEvents, pastEvents, filteredPastEvents
                    })

                  } else {
                    console.log('Opportunity match did not work', response.data.message)
                    self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                  }

              }).catch((error) => {
                  console.log('Opportunity match did not work for some reason', error);
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
              });
            }

            if (newPreferences) {
              console.log('new preferences stated')

              // totalPercent must equal 100
              if (this.state.totalPercent !== 100) {
                this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'The sum of matching criteria weights must equal 100' })
              } else {
                const emailId = this.state.emailId
                const matchingPreferences = matchingCriteria
                const matchingUseCases = useCases
                const updatedAt = new Date()

                Axios.post('/api/users/profile/details', {
                  emailId, matchingPreferences, matchingUseCases, updatedAt })
                .then((response) => {

                  if (response.data.success) {
                    console.log('successfully saved new preferences')
                    officiallyCalculate()

                  } else {
                    console.log('request was not successful')
                    officiallyCalculate()
                  }
                }).catch((error) => {
                    console.log('Saving the info did not work', error);
                    officiallyCalculate()
                });
              }
            } else {
              officiallyCalculate()
            }
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      this.setState({ subNavSelected: pageName })
      localStorage.setItem('opportunitiesNavSelected', pageName)
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showMatchingCriteria: false });
    }

    itemClicked(name, value) {
      console.log('itemClicked called', name, value)

      const nameArray = name.split("|")
      const index = Number(nameArray[1].trim())

      let useCases = this.state.useCases
      // useCases[index]["value"] = event.target.value
      for (let i = 1; i <= useCases.length; i++) {

        if (i - 1 === index) {
          useCases[i - 1]["selected"] = true
        } else {
          useCases[i - 1]["selected"] = false
        }
      }

      let matchingCriteria = this.state.matchingCriteria
      if (useCases[index].name === 'Interest') {
        // max interests, personality

         matchingCriteria[0]["value"] = 0 // work function
         matchingCriteria[1]["value"] = 0 // industry
         matchingCriteria[2]["value"] = 0 // location
         matchingCriteria[3]["value"] = 0 // pay
         matchingCriteria[4]["value"] = 0 // stability
         matchingCriteria[5]["value"] = 60 // interests
         matchingCriteria[6]["value"] = 40 // personality
         matchingCriteria[7]["value"] = 0 // values
         matchingCriteria[8]["value"] = 0 // skills
         matchingCriteria[9]["value"] = 0 // education
         matchingCriteria[10]["value"] = 0 // projects
         matchingCriteria[11]["value"] = 0 // experience

      } else if (useCases[index].name === 'Competitive Advantage') {
        // max rare, in-demand skills

        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 70 // skills
        matchingCriteria[9]["value"] = 10 // education
        matchingCriteria[10]["value"] = 10 // projects
        matchingCriteria[11]["value"] = 10 // experience

      } else if (useCases[index].name === 'Purpose') {
        // max interests, mission-driven opportunities
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 25 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 50 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 25 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience

      } else if (useCases[index].name === 'Stability') {
        // max interests
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 100 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience

      } else if (useCases[index].name === 'Pay') {
        // max pay
        matchingCriteria[0]["value"] = 0 // work function
        matchingCriteria[1]["value"] = 0 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 100 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 0 // interests
        matchingCriteria[6]["value"] = 0 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 0 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience

      } else if (useCases[index].name === 'Recognition') {
        // max social, artistic, prestige, client-facing (sales)
        matchingCriteria[0]["value"] = 10 // work function
        matchingCriteria[1]["value"] = 10 // industry
        matchingCriteria[2]["value"] = 0 // location
        matchingCriteria[3]["value"] = 0 // pay
        matchingCriteria[4]["value"] = 0 // stability
        matchingCriteria[5]["value"] = 40 // interests
        matchingCriteria[6]["value"] = 10 // personality
        matchingCriteria[7]["value"] = 0 // values
        matchingCriteria[8]["value"] = 30 // skills
        matchingCriteria[9]["value"] = 0 // education
        matchingCriteria[10]["value"] = 0 // projects
        matchingCriteria[11]["value"] = 0 // experience
      }

      this.setState({ useCases, matchingCriteria })
    }

    render() {

        let headerClass = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        let mainClass = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
        let mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform }
        let mainStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, paddingTop: '20px'}
        let filterBackgroundClass = "floating-container card top-margin-2-percent right-margin-2-percent"
        let matchButtonClass = "float-left top-padding-5 left-padding-3 top-margin-negative-3"
        if (!window.location.pathname.includes('/advisor/' && this.state.roleName !== 'Mentor')) {
          headerClass = 'horizontally-centered-container card-clear-padding top-margin-2-percent padding-20'
          mainClass = 'horizontally-centered-container card top-margin-2-percent'
          mainStyle = {}
          mainStyle2 = {}
          filterBackgroundClass = "width-70-percent max-width-1400 center-horizontally card top-margin-20"
          matchButtonClass = "fixed-column-30 top-padding-5 top-margin-5"
        }

        if (this.props.pageSource === 'Goal') {
          mainClass = ''
          mainStyle = {}
          mainStyle2 = {}
        }

        let postings = []
        if (this.state.subNavSelected === "All" && this.state.postings) {
          postings = this.state.postings
        } else if (this.state.subNavSelected === "Featured" && this.state.featuredOpportunities) {
          postings = this.state.featuredOpportunities
        } else if (this.state.subNavSelected === "Work" && this.state.work) {
          postings = this.state.work
        } else if (this.state.subNavSelected === "Projects" && this.state.projectWork) {
          postings = this.state.projectWork
        } else if (this.state.subNavSelected === "Events" && this.state.events) {
          postings = this.state.events
        }

        return (
            <div>
                <div>
                  {(this.props.pageSource !== 'Goal') && (
                    <div>
                      <div className={(!window.location.pathname.includes('/problem-platform') && this.state.pageSource !== 'landingPage') && headerClass} style={(!window.location.pathname.includes('/problem-platform') && this.state.pageSource !== 'landingPage') ? mainStyle : {}}>
                        {(window.location.pathname.includes('/problem-platform') || this.state.pageSource === 'landingPage') ? (
                          <div className="row">

                            <div className="spacer" />
                            <div className="spacer" />
                            <div className="spacer" />

                            {(!this.props.targetRole && !this.props.passedSubPostType) && (
                              <div>
                                <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width white-background">
                                  <div className="fixed-column-40 top-padding-8 left-padding-3">
                                    <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                                  </div>
                                  <div className="calc-column-offset-120">
                                    <input type="text" className="text-field clear-border" placeholder={(this.props.passedType) ? "Search " + this.props.passedType.toLowerCase() + "s..." : "Search..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="fixed-column-80 top-padding-8 left-padding-3 top-margin-negative-3">
                                    <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                      <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className={(this.state.matchingView) ? "top-padding-8 top-margin-negative-3 full-width" : matchButtonClass} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                              <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button"} onClick={(this.state.matchingView) ? () => this.calculateMatches(false, false, false) : () => this.calculateMatches(true, true, false)} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches">
                                {(this.state.matchingView) ? <img src={matchIconSelected} alt="GC" className="image-auto-30" /> : <img src={matchIcon} alt="GC" className="image-30-fit" />}
                              </button>
                            </div>
                            {(this.state.matchingView) && (
                              <div className="full-width">
                                <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                                  <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                                    <p>Adjust</p>
                                  </div>
                                </button>
                                <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                                  <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                                    <p>Close</p>
                                  </div>
                                </button>

                                <div className="clear" />

                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              </div>
                            )}

                            {(!this.state.matchingView) && (
                              <div>
                                <div className="calc-column-offset-60 left-padding right-padding">
                                  <div className="standard-border display-inline row-3 right-padding-5 left-padding-5 full-width">
                                    <div className="fixed-column-40 top-padding-3 left-padding-5">
                                      <img src={searchIcon} alt="GC" className="image-auto-28 padding-5"/>
                                    </div>
                                    <div className="calc-column-offset-40">
                                      <input type="text" className="text-field clear-border description-text-1" placeholder={(this.props.passedType) ? "Search " + this.props.passedType.toLowerCase() + "s..." : "Search " + postings.length + ' Opportunities...'} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>

                                <div className={matchButtonClass}>
                                  <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results">
                                    {(this.state.showingSearchBar) ? <img src={filterIconSelected} alt="GC" className="image-auto-25" /> : <img src={filterIcon} alt="GC" className="image-auto-25" />}
                                  </button>
                                </div>
                              </div>
                            )}

                            <div className="clear" />
                            <ReactTooltip id="tooltip-placeholder-id" />

                            {(!this.state.showModule) && (
                              <div>
                                {(this.state.employerAccount) ? (
                                  <div className="height-20 full-width" />
                                ) : (
                                  <div>
                                    <div className="carousel-3" onScroll={this.handleScroll}>
                                      {this.state.subNavCategories.map((value, index) =>
                                        <div className="carousel-item-container">
                                          {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                            <div key={value} className="selected-carousel-item">
                                              <label>{value}</label>
                                              {/*
                                              <div className="flex-container row-direction">
                                                <label className="left-margin-18">{value}</label>
                                                <div className="noti-bubble-small selected-background description-text-6 left-margin-3">{60}</div>
                                              </div>*/}
                                            </div>
                                          ) : (
                                            <div>
                                              {(value === 'All') && (
                                                <div>
                                                  {(this.state.filteredPostings.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredPostings.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'Featured') && (
                                                <div>
                                                  {(this.state.filteredFeaturedOpportunities.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredFeaturedOpportunities.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'Work') && (
                                                <div>
                                                  {(this.state.filteredWork.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredWork.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'Programs') && (
                                                <div>
                                                  {(this.state.filteredPrograms.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredPrograms.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'Projects') && (
                                                <div>
                                                  {(this.state.filteredProjectWork.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredProjectWork.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'Events') && (
                                                <div>
                                                  {(this.state.filteredEvents.length > 0) ? (
                                                    <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                      <div className="flex-container row-direction">
                                                        <label className="left-margin-18">{value}</label>
                                                        <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredEvents.length}</div>
                                                      </div>
                                                    </button>
                                                  ) : (
                                                    <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                      <label>{value}</label>
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                              {(value === 'View External Jobs' && !window.location.pathname.includes('/advisor/')) && (
                                                <div className="left-padding-50">
                                                  <Link to="/app/jobs" className="clear-decoration background-button">
                                                    <label className="error-color description-text-2 bold-text auto-pointers">{value}</label>
                                                  </Link>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {(this.state.showModule && !window.location.pathname.includes('/problem-platform') && this.props.pageSource !== 'landingPage') && (
                        <div>
                          {(this.state.employerAccount) ? (
                            <div className="height-20 full-width" />
                          ) : (
                            <div className="horizontally-centered-container row-10 top-margin-20">
                              <div className="carousel-3" onScroll={this.handleScroll}>
                                {this.state.subNavCategories.map((value, index) =>
                                  <div className="display-inline right-padding-20">
                                    {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                      <div key={value} className="selected-carousel-item">
                                        <label>{value}</label>
                                        {/*
                                        <div className="flex-container row-direction">
                                          <label className="left-margin-18">{value}</label>
                                          <div className="noti-bubble-small selected-background description-text-6 left-margin-3">{60}</div>
                                        </div>*/}
                                      </div>
                                    ) : (
                                      <div>
                                        {(value === 'All') && (
                                          <div>
                                            {(this.state.filteredPostings.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredPostings.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'Featured') && (
                                          <div>
                                            {(this.state.filteredFeaturedOpportunities.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredFeaturedOpportunities.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'Work') && (
                                          <div>
                                            {(this.state.filteredWork.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredWork.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'Programs') && (
                                          <div>
                                            {(this.state.filteredPrograms.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredPrograms.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'Projects') && (
                                          <div>
                                            {(this.state.filteredProjectWork.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredProjectWork.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'Events') && (
                                          <div>
                                            {(this.state.filteredEvents.length > 0) ? (
                                              <button key={value} className="menu-button-noti-bubble" onClick={() => this.subNavClicked(value)}>
                                                <div className="flex-container row-direction">
                                                  <label className="left-margin-18">{value}</label>
                                                  <div className="noti-bubble-small unselected-background description-text-6 left-margin-3">{this.state.filteredEvents.length}</div>
                                                </div>
                                              </button>
                                            ) : (
                                              <button key={value} className="menu-button" onClick={() => this.subNavClicked(value)}>
                                                <label>{value}</label>
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        {(value === 'View External Jobs' && !window.location.pathname.includes('/advisor/')) && (
                                          <div className="left-padding-50">
                                            <Link to="/app/jobs" className="clear-decoration background-button">
                                              <label className="error-color description-text-2 bold-text auto-pointers">{value}</label>
                                            </Link>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                  </div>
                                )}
                                <hr />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.showingSearchBar) && (
                        <div className={(window.location.pathname.includes('/problem-platform') || this.state.pageSource === 'landingPage') ? "row box-container-1 white-background" : filterBackgroundClass} style={(window.location.pathname.includes('/problem-platform') || this.state.pageSource === 'landingPage') ? {} : mainStyle}>
                          <div>
                            {(this.state.subNavSelected !== "Featured") && (
                              <div>
                                <div className="clear" />

                                <p className="heading-text-6">Filter</p>
                                <div className="half-spacer" />
                                {(this.renderManipulators('filter'))}

                                <div className="clear" />

                                <div className="row-20">
                                  <hr />
                                </div>

                                <p className="heading-text-6">Sort</p>
                                <div className="half-spacer" />
                                {(this.renderManipulators('sort'))}
                                <div className="clear" />
                              </div>
                            )}

                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {(window.location.pathname.includes('/problem-platform')) && (
                    <div className="row">
                      {(this.state.animating) ? (
                        <div className="flex-container flex-center full-space">
                          <div>
                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Calculating results...</p>

                          </div>
                        </div>
                      ) : (
                        <div className="box-container-1">
                          <SubRenderOpportunities oppType="projectWork" postings={this.state.filteredProjectWork} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                            pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                            favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                            sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                            activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {(!window.location.pathname.includes('/problem-platform') && this.state.pageSource !== 'landingPage') && (
                    <div>
                      {(this.state.animating) ? (
                        <div className="full-space">
                          <div>
                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Calculating results...</p>

                          </div>
                        </div>
                      ) : (
                        <div>

                          {(this.state.matchingView && this.state.errorMessage && this.state.errorMessage !== '') ? (
                            <div>
                              <div className={mainClass} style={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '0px' }}>
                                <div>
                                  <p className="error-color row-5">{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {(this.state.subNavSelected === 'All') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="all" postings={this.state.filteredPostings} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'Featured') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="featured" postings={this.state.filteredPostings} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'Work') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="work" postings={this.state.filteredWork} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'Programs') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="programs" postings={this.state.filteredPrograms} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'Projects') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="projectWork" postings={this.state.filteredProjectWork} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}

                              {(this.state.subNavSelected === 'Events') && (
                                <div className={mainClass} style={mainStyle2}>
                                  <SubRenderOpportunities oppType="events" postings={this.state.filteredPostings} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                    pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                                  />
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.pageSource === 'landingPage') && (
                    <div className="row">
                      {(this.state.animating) ? (
                        <div className="flex-container flex-center full-space white-background">
                          <div>
                            <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer" /><div className="spacer" />
                            <p className="center-text cta-color bold-text">Calculating results...</p>

                          </div>
                        </div>
                      ) : (
                        <div className="box-container-1 white-background">
                          {(this.props.passedViewIndex === 1) && (
                            <div>
                              <SubRenderOpportunities oppType="events" postings={this.state.filteredPostings} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                              />
                            </div>
                          )}
                          {(this.props.passedViewIndex === 5) && (
                            <div>
                              <SubRenderOpportunities oppType="projectWork" postings={this.state.filteredProjectWork} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                              />
                            </div>
                          )}
                          {(this.props.passedViewIndex === 7) && (
                            <div>
                              <SubRenderOpportunities oppType="work" postings={this.state.filteredWork} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                              />
                            </div>
                          )}
                          {(this.props.passedViewIndex === 8) && (
                            <div>
                              <SubRenderOpportunities oppType="all" postings={this.state.filteredPostings} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                              />
                            </div>
                          )}
                          {(this.props.passedViewIndex === 9) && (
                            <div>
                              <SubRenderOpportunities oppType="programs" postings={this.state.filteredPrograms} upcomingEvents={this.state.filteredUpcomingEvents} pastEvents={this.state.filteredPastEvents} history={this.props.navigate}
                                pageSource={this.props.pageSource} rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.activeOrg} targetRole={this.props.targetRole}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}


                </div>

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >

                {(this.state.showMatchingCriteria) && (
                  <div key="showMatchingCriteria" className="full-width padding-20">
                    <p className="heading-text-2">Adjust Matching Criteria</p>
                    <div className="spacer" />

                    <div className="row-10">
                      <div className="flex-container">
                        <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                          <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                            Adjust by Needs
                          </div>
                        </button>
                        <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                          <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                            Custom Adjust
                          </div>
                        </button>

                      </div>
                      <div className="spacer" />
                    </div>

                    {(this.state.customAdjustment) ? (
                      <div>

                        {(this.state.matchingCriteria) && (
                          <div>
                            {this.state.matchingCriteria.map((value ,index) =>
                              <div key={"c" + index}>
                                <div className="calc-column-offset-100-static">
                                  <p className="half-bold-text">{index + 1}. {value.name}</p>
                                  <div className="half-spacer" />
                                  <p className="description-text-3">{value.description}</p>
                                </div>
                                <div className="fixed-column-100 right-text">
                                  <div className="fixed-column-70">
                                    <input type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="fixed-column-30">
                                    <div className="mini-spacer"/><div className="mini-spacer"/>
                                    <p className="heading-text-2 cta-color bold-text">%</p>
                                  </div>
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="half-spacer" />

                              </div>
                            )}

                            <div>
                              <hr className="cta-border-color"/>
                              <div className="spacer" />
                              <div className="float-left full-width right-text">
                                <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                              </div>
                              <div className="clear" />
                              {(this.state.totalPercent !== 100) && (
                                <div className="full-width">
                                  <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>

                        {(this.state.useCases) && (
                          <div>
                            {this.state.useCases.map((value ,index) =>
                              <div key={"u" + index} className={(value.name === 'Purpose') && "wash-out-2"}>
                                <div className="calc-column-offset-50">
                                  <p className="half-bold-text">{index + 1}. {value.name}</p>
                                  <div className="half-spacer" />
                                  <p className="description-text-3">{value.description}</p>
                                </div>
                                <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                                  <button disabled={(value.name === 'Purpose') ? true : false} onClick={() => this.itemClicked('useCase|' + index, true)} type="button" className="background-button">
                                    {(value.selected) ? (
                                      <div className="circle-option-container-2 cta-border center-text" >
                                        <div className="circle-selected-2"/>
                                      </div>
                                    ) : (
                                      <div className="circle-option-container-2 standard-border center-text" />
                                    )}
                                  </button>

                                  {/*
                                  <select name={"useCases|" + index} className="dropdown description-text-2" value={value.value} onChange={this.formChangeHandler}>
                                      {['Optimize','Ignore','De-Optimize'].map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>*/}
                                </div>

                                <div className="clear" />
                                <div className="spacer" /><div className="half-spacer" />

                              </div>
                            )}

                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

                  </div>
                )}

                <div className="row-20 center-text">
                  <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>
               </Modal>

            </div>

        )
    }
}

export default withRouter(Opportunities);
