import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubApply from './Subcomponents/Apply';
import withRouter from './Functions/WithRouter';

class Apply extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.passActiveOrg = this.passActiveOrg.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let selectedPosting = null
      let passedTasks = null
      let customAssessmentResponses = null
      let caQuestions = null
      let application = null
      let referralCode = null
      if (this.props.location && this.props.location.state) {
        selectedPosting = this.props.location.state.selectedPosting
        passedTasks = this.props.location.state.passedTasks
        customAssessmentResponses = this.props.location.state.customAssessmentResponses
        caQuestions = this.props.location.state.caQuestions
        application = this.props.location.state.application
        referralCode = this.props.location.state.referralCode
      }
      // console.log("'what's the deal:", selectedPosting, this.props.location )

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      const profilePath = window.location.protocol + "//" + window.location.host + "/app/profile/" + username

      console.log('show appId ', application)
      let newCustomAssessmentResponses = null
      if (application) {
        if (application.customAssessmentResults && !customAssessmentResponses) {
          console.log('pass application customAssessmentResponses')
          customAssessmentResponses = application.customAssessmentResults
        } else if (application.newCustomAssessmentResults && !customAssessmentResponses) {
          newCustomAssessmentResponses = application.newCustomAssessmentResults
        }
      }

      this.setState({ activeOrg, emailId: email, cuFirstName, cuLastName, username,
        orgFocus, selectedPosting, passedTasks, profilePath, orgLogo,
        customAssessmentResponses, newCustomAssessmentResponses, caQuestions, application, referralCode
      })
    }

    passActiveOrg(activeOrg) {
      console.log('passActiveOrg called', activeOrg)

      this.setState({ activeOrg })
    }

    render() {

        return (
            <div>
                <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate} />

                <div className="standard-container-2">
                  <SubApply
                    selectedPosting={this.state.selectedPosting} activeOrg={this.state.activeOrg} passActiveOrg={this.passActiveOrg}
                    path={this.state.path} history={this.props.navigate} passedTasks={this.state.passedTasks}
                    customAssessmentResponses={this.state.customAssessmentResponses} caQuestions={this.state.caQuestions}
                    application={this.state.application} referralCode={this.state.referralCode}
                  />
                </div>

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(Apply);
