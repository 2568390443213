import React, {Component} from 'react';
import withRouter from '../Functions/WithRouter';

class OrgWalkthrough extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount(){
    }

    render() {
      return (
          <div>
            <p>org walkthrough</p>
          </div>
      )
    }
}

export default withRouter(OrgWalkthrough)
