import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditDocument from './Subcomponents/EditDocument';
import withRouter from '../components/Functions/WithRouter';

class EditDocument extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let id = null
      if (this.props.params && this.props.params.id && this.props.params.id !== 'add') {
        id = this.props.params.id
      }

      this.setState({ emailId: email, username, activeOrg, orgFocus,
        id, orgLogo
      });
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)
      this.setState({ activeOrg })
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} loadWorkspace={this.loadWorkspace} />

            {(this.state.id) && (
              <SubEditDocument id={this.state.id} />
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(EditDocument);
