import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubGroupDetails from '../Subcomponents/GroupDetails';
import withRouter from '../Functions/WithRouter';

class OrgGroupDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('opportunity mounted')

      let { org, groupId } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      if (!this.props.location.state) {
        console.log('went directly to posting', groupId)

        this.setState({ groupId, org })

      } else {

        const { selectedGroup, objectId } = this.props.location.state;
        console.log('show location state: ', this.props.location.state)

        if (objectId) {
          groupId = objectId
        }

        this.setState({ selectedGroup, org, groupId })
      }
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate called within parentGroupDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.groupId !== prevProps.params.groupId) {
        // console.log('new group selected in parent', this.props.params.groupId)
        let selectedGroup = this.props.location.state
        if (this.props.location && this.props.location.state && this.props.location.state.selectedGroup) {
          selectedGroup = this.props.location.state.selectedGroup
        }
        this.setState({ groupId: this.props.params.groupId, selectedGroup })
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

          <div>
            <SubGroupDetails selectedGroup={this.state.selectedGroup} groupId={this.state.groupId} history={this.props.navigate} activeOrg={this.state.org} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(OrgGroupDetails)
