import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubOpportunities from './Subcomponents/Opportunities';
import withRouter from './Functions/WithRouter';

class AdvPostings extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        const contributorOptions = ['','All','My Org','Me']
        const problemTypeOptions = ['','Exploratory','Technical']
        const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Hard','Challenger']
        const popularityOptions = ['','More than 10','More than 50']
        const postDateOptions = ['','Within the Last Month','Within the Last Year']

        this.setState({ activeOrg, emailId: email, username, orgFocus,
          cuFirstName, cuLastName, roleName, orgLogo,
          contributorOptions, problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions })
      }
    }

    render() {

      return(
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          {(this.state.activeOrg) && (
            <SubOpportunities calculateMatches={this.state.calculateMatches} activeOrg={this.state.activeOrg} passedSubNavSelected={this.state.subNavSelected} history={this.props.navigate} />
          )}
          <div className="clear" />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvPostings)
