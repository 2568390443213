import React, {Component} from 'react';
import Axios from 'axios';
import SubGoalDetails from '../Common/GoalDetails';
import withRouter from '../Functions/WithRouter';

class GoalDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in goalDetails ', this.props, prevProps)

      if (this.props.selectedGoal !== prevProps.selectedGoal) {
        this.retrieveData()
      } else if (this.props.goalId !== prevProps.goalId) {
        this.retrieveData()
      } else if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in Goal Details', this.props)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.activeOrg) {
        activeOrg = this.props.activeOrg
      }

      // const accountCode = this.props.accountCode
      // console.log('show deets: ', cuFirstName)

      let goalId = null
      if (this.props.goalId) {
        goalId = this.props.goalId
      } else if (this.props.selectedGoal) {
        goalId = this.props.selectedGoal._id
      } else {
        console.log('something went wrong')
      }

      if (goalId) {
        Axios.get('/api/logs/byid', { params: { _id: goalId } })
        .then((response) => {
           console.log('Goal detail by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             const selectedGoal = response.data.log
             this.setState({ selectedGoal, emailId, username, cuFirstName, cuLastName, activeOrg, orgFocus, orgName, roleName })

           } else {
             this.setState({ emailId, username, cuFirstName, cuLastName, activeOrg, orgFocus, orgName, roleName })
           }
        }).catch((error) => {
            console.log('Goal query did not work', error);
        });
      }
    }

    render() {

      return (
          <div>
            <div className="standard-container-2">
              <SubGoalDetails selectedGoal={this.state.selectedGoal} history={this.props.navigate} excludeModal={true}/>
            </div>
          </div>
      )
    }
}

export default withRouter(GoalDetails);
