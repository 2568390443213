import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEmployerDetails from '../Subcomponents/EmployerDetails';
import withRouter from '../Functions/WithRouter';

class OrgEmployerDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, employerSelected } = this.props.params

      let employerDetails = null
      if (this.props.location && this.props.location.state) {
        employerDetails = this.props.location.state.employerDetails
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ org, employerSelected, employerDetails, emailId: email, orgLogo,
        username, orgFocus
      })
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <SubEmployerDetails history={this.props.navigate} employerId={this.state.employerSelected} commandClicked={this.commandClicked} />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(OrgEmployerDetails);
