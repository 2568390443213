import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCareers from './Subcomponents/Careers';
import withRouter from './Functions/WithRouter';

class AdvCareerPaths extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo })
      }
    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />

            <div className="width-70-percent max-width-1400 top-margin-2-percent center-horizontally">
              <SubCareers />
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

        )
    }
}

export default withRouter(AdvCareerPaths);
