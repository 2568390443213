import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditProfileDetails from './Subcomponents/EditProfileDetails';
import withRouter from './Functions/WithRouter';

class EmpEditProfile extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ username, emp, orgMode, activeOrg, orgLogo })

      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
          <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace}/>

            <div>
              <div className="standard-container-2">
                <SubEditProfileDetails activeOrg={this.state.activeOrg} passedType="Basic" />
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}

          </div>
      )
    }
}

export default withRouter(EmpEditProfile)
