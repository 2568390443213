import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import SubAdvisingServices from '../components/Subcomponents/AdvisingServices';
import SubIdeaExchange from '../components/Subcomponents/IdeaExchange';
import SubCourses from '../components/Subcomponents/CoursesTwo';
import withRouter from '../components/Functions/WithRouter';

const mentorIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';

class MarketplacePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          categorySelected: 'Advising Services'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      window.scrollTo(0, 0)

      this.retrieveData()

    }

    retrieveData() {
      console.log('retrieveData called')

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('emp');
      let employerName = localStorage.getItem('employerName');
      let employerLogoURL = localStorage.getItem('employerLogoURL');
      let jobTitle = localStorage.getItem('jobTitle');

      let categorySelected = "Advising Services"
      if (window.location.pathname === '/business-ideas') {
        categorySelected = "Business Ideas"
      }

      // console.log('show me categories: ', categories)
      this.setState({ cuFirstName, cuLastName, emailId, activeOrg, roleName,
        accountCode, employerName, employerLogoURL, jobTitle, categorySelected
      })
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showMarketplaceDefined: false, showAddToCart: false });
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"marketplacePage"} darkBackground={true} />

                <div className="top-margin-20">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-25 horizontal-padding"}>
                    <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-30" : "card-clear-padding min-width-150 max-width-220 pin-right bottom-margin-30"}>
                      <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <div className="calc-column-offset-30">
                            <p>Marketplaces</p>
                          </div>
                          <div className="fixed-column-25">
                            <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showMarketplaceDefined: true })}>
                              <img className="image-auto-15 pin-right" alt="GC" src={questionMarkBlue} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="What is this marketplace?" />
                            </button>
                          </div>
                          <div className="clear" />
                          <div className="spacer" /><div className="half-spacer" />

                          <button className="background-button full-width left-text" onClick={() => this.setState({ categorySelected: 'Advising Services' })}>
                            <div className={(this.state.categorySelected === 'Advising Services') ? "selected-background-light row-15 description-text-3 horizontal-padding-4" : "row-15 description-text-3 horizontal-padding-4"}>
                              <div className="fixed-column-30">
                                <img src={mentorIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p>Advising Services</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>

                          <button className="background-button full-width left-text" onClick={() => this.setState({ categorySelected: 'Business Ideas' })}>
                            <div className={(this.state.categorySelected === 'Business Ideas') ? "selected-background-light row-15 description-text-3 horizontal-padding-4" : "row-15 description-text-3 horizontal-padding-4"}>
                              <div className="fixed-column-30">
                                <img src={exchangeIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p>Business Ideas</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>

                          <button className="background-button full-width left-text" onClick={() => this.setState({ categorySelected: 'Courses' })}>
                            <div className={(this.state.categorySelected === 'Courses') ? "selected-background-light row-15 description-text-3 horizontal-padding-4" : "row-15 description-text-3 horizontal-padding-4"}>
                              <div className="fixed-column-30">
                                <img src={courseIconDark } alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p>Courses</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                        </div>
                    </div>

                    <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-30" : "card-clear-padding min-width-150 max-width-220 pin-right bottom-margin-30"}>
                      <div className="full-width height-5 senary-background" />

                      <div className="padding-20">
                        <p>Create a Dedicated Community!</p>
                        <div className="spacer" /><div className="spacer" />
                        <p className="description-text-3">Set up a dedicated upskilling and referral community for your educational institution, workforce organization, or employer.</p>
                        <div className="spacer" /><div className="spacer" />
                        <Link className="background-button" to={"/contact"} state={{ reason: 'Buy Subscription Licenses', body: "I'd like to buy subscription licenses for a dedicated career development community." }}><button className="btn btn-squarish">Buy Licenses</button></Link>
                      </div>
                    </div>

                    {(this.state.emailId) && (
                      <div className={(window.innerWidth < 768) ? "card-clear-padding full-width bottom-margin-30" : "card-clear-padding min-width-150 max-width-220 pin-right bottom-margin-30"}>
                        <div className="full-width height-5 senary-background" />

                        <div className="padding-20">
                          <p>My Stuff</p>

                          <div className="spacer" /><div className="half-spacer" />

                          <button className="background-button full-width left-text" onClick={() => this.props.navigate("/app/bookings")}>
                            <div className="row-15 description-text-3 horizontal-padding-4">
                              <div className="fixed-column-30">
                                <img src={mentorIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-45">
                                <p>My bookings</p>
                              </div>
                              <div className="fixed-column-15">
                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right" />
                              </div>
                              <div className="clear" />
                            </div>
                          </button>

                          <hr />

                          <button className="background-button full-width left-text" onClick={() => this.props.navigate("/app/my-ideas")}>
                            <div className="row-15 description-text-3 horizontal-padding-4">
                              <div className="fixed-column-30">
                                <img src={exchangeIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-45">
                                <p>My Ideas</p>
                              </div>
                              <div className="fixed-column-15">
                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right" />
                              </div>
                              <div className="clear" />
                            </div>
                          </button>

                          <hr />

                          <button className="background-button full-width left-text" onClick={() => this.props.navigate("/app/my-courses")}>
                            <div className="row-15 description-text-3 horizontal-padding-4">
                              <div className="fixed-column-30">
                                <img src={courseIconDark } alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-45">
                                <p>My Courses</p>
                              </div>
                              <div className="fixed-column-15">
                                <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right" />
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-75"}>
                    {(this.state.categorySelected === 'Advising Services') && (
                      <div>
                        <SubAdvisingServices history={this.props.navigate} />
                      </div>
                    )}
                    {(this.state.categorySelected === 'Business Ideas') && (
                      <div>
                        <SubIdeaExchange history={this.props.navigate} activeOrg={'guidedcompass'} />
                      </div>
                    )}
                    {(this.state.categorySelected === 'Courses') && (
                      <div>
                        <SubCourses history={this.props.navigate} activeOrg={'guidedcompass'} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="clear" />

                {/*Footer()*/}
                <Footer history={this.props.navigate} />

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
               >
                {(this.state.showMarketplaceDefined) && (
                  <div key="showMarketplaceDefined" className="full-width padding-40 center-text">
                    <h2>What is this Marketplace?</h2>
                    <div className="spacer" /><div className="spacer" />
                    <p>The marketplace includes resources for career-seekers to purchase, request, or sell services.</p>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <button className="btn btn-primary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                )}

               </Modal>
            </div>
        )
    }
}

export default withRouter(MarketplacePage);
