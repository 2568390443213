import React, {Component} from 'react';
import Axios from 'axios';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutFormBooking from './CheckoutFormBooking';
import withRouter from '../Functions/WithRouter';

const stripeTestPromise = loadStripe("pk_test_51I6htEBMA9yyOaS5GG6dCAJXzACvstyF0j5v6xIZJkHCRlkvvz7VlQzLqAT26bd8TBCyW2WuYyjWmu4fsdPYF6cT00zf4fma2z");
const stripeLivePromise = loadStripe("pk_live_51I6htEBMA9yyOaS5WfSIqrWCbixiXQ7Fm3iRpTd4grD1jfXAHVOUv1UYLuGY5Gi92TPBumqYGCGfbIr7c059mzwg00KxKIJB0l");

class PreCheckoutBooking extends Component {
    constructor(props) {
      super(props)
      this.state = {
        testingStripe: false,
        price: 100,

        availableOrgs: ['unite-la'],
        discount: 0,
        promo: 0,

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)

      this.checkEntry = this.checkEntry.bind(this)
      this.createCustomer = this.createCustomer.bind(this)

      this.passProductData = this.passProductData.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

      let firstName = localStorage.getItem('firstName')
      let lastName = localStorage.getItem('lastName')
      let email = localStorage.getItem('email')
      let activeOrg = localStorage.getItem('activeOrg')
      let orgName = localStorage.getItem('orgName')

      if (this.props.firstName) {
        firstName = this.props.firstName
      }
      if (this.props.lastName) {
        lastName = this.props.lastName
      }
      if (this.props.email) {
        email = this.props.email
      }
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      if (this.props.orgName) {
        orgName = this.props.orgName
      }

      this.createCustomer({ firstName, lastName, email, orgCode: activeOrg, orgName })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in precheckout booking', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        console.log('t0')
        this.retrieveData()
      } else if (this.props.orgType !== prevProps.orgType || this.props.employerName !== prevProps.employerName) {
        console.log('t1')
        this.retrieveData()
      } else if (this.props.bookingDate !== prevProps.bookingDate || this.props.profileItems !== prevProps.profileItems) {
        console.log('t2')
        this.retrieveData()
      } else if (this.props.profileItems !== prevProps.profileItems) {
        console.log('t3')
        this.retrieveData()
      } else if (this.props.profileItemsChanged !== prevProps.profileItemsChanged) {
        console.log('t4')
        this.retrieveData()
      } else if (this.props.recipientType !== prevProps.recipientType || this.props.recipientFirstName !== prevProps.recipientFirstName || this.props.recipientLastName !== prevProps.recipientLastName || this.props.recipientEmail !== prevProps.recipientEmail || this.props.firstName !== prevProps.firstName || this.props.lastName !== prevProps.lastName || this.props.email !== prevProps.email) {
        console.log('t5')
        this.retrieveData()
      } else if (this.props.promoCode !== prevProps.promoCode) {
        console.log('t6')
        this.retrieveData()
      } else {
        // console.log('t2', this.props.orgType !== prevProps.orgType)
      }
    }

    retrieveData() {
      console.log('retrieveData called within precheckout booking')

      let priceId = ''
      const testingStripe = this.state.testingStripe
      if (testingStripe) {
        priceId = 'price_1I6ivTBMA9yyOaS5RJ1CztXY'
      } else {
        priceId = 'price_1I8wv4BMA9yyOaS5WGs5LmuT'
        if (this.props.product) {
          priceId = this.props.product.stripeProductId
        }

        // 'price_1LYkawBMA9yyOaS5Fk1gB4Qu' // advising services tier 1
        // price_1LYkdqBMA9yyOaS5h117xvFC // advising services tier 2
        // price_1LYkeKBMA9yyOaS5x1HH7B8X // advising services tier 3
      }

      let firstName = localStorage.getItem('firstName')
      let lastName = localStorage.getItem('lastName')
      let email = localStorage.getItem('email')
      let activeOrg = localStorage.getItem('activeOrg')
      let orgName = localStorage.getItem('orgName')
      let roleName = localStorage.getItem('roleName')
      // console.log('show orgName: ', orgName)
      if (this.props.roleName) {
        roleName = this.props.roleName
      }

      const price = this.props.price
      const productName = this.props.productName
      const product = this.props.product
      if (this.props.firstName) {
        firstName = this.props.firstName
      }
      if (this.props.lastName) {
        lastName = this.props.lastName
      }
      if (this.props.email) {
        email = this.props.email
      }
      if (this.props.orgName) {
        orgName = this.props.orgName
      }

      const accountCode = this.props.accountCode
      const employerName = this.props.employerName
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const promoCode = this.props.promoCode
      const bookingDate = this.props.bookingDate
      const profileItems = this.props.profileItems
      const recipientType = this.props.recipientType
      const recipientFirstName = this.props.recipientFirstName
      const recipientLastName = this.props.recipientLastName
      const recipientEmail = this.props.recipientEmail
      console.log("!!!! bookingDate", profileItems)
      this.setState({ activeOrg, orgCode: activeOrg, orgName, priceId, testingStripe, price, productName, product,
        firstName, lastName, email, accountCode, employerName,
        promoCode, bookingDate, profileItems, recipientType, recipientFirstName, recipientLastName,
        recipientEmail, roleName
      })

      if (email && email !== '') {
        console.log('the check')

        // Axios.get('/api/users/profile/details', { params: { email } })
        // .then((response) => {
        //   console.log('User profile query attempted', response.data);
        //
        //    if (response.data.success) {
        //      console.log('successfully retrieved user details')
        //
        //      const firstName = response.data.user.firstName
        //      const lastName = response.data.user.lastName
        //      const roleName  = response.data.user.roleName
        //      const orgCode = response.data.user.activeOrg
        //      const accountCode = response.data.user.accountCode
        //      const school = response.data.user.school
        //      const jobTitle = response.data.user.jobTitle
        //      const employerName = response.data.user.employerName
        //
        //      this.setState({
        //        firstName, lastName, email, orgCode, accountCode, school, jobTitle, employerName, roleName, orgName
        //      })
        //
        //      if (!this.state.testingStripe) {
        //
        //        // pull customerId
        //        let subscriptionType = ''
        //        if (roleName === 'Student') {
        //          subscriptionType = 'Individual'
        //        } else if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator' || roleName === 'Admin'){
        //          subscriptionType = 'Organization'
        //        } else {
        //          subscriptionType = roleName
        //        }
        //
        //        let isActive = null
        //        let allOrgAccounts = true
        //
        //        const subParams = { email, orgCode, accountCode, subscriptionType, isActive, allOrgAccounts }
        //
        //        // query for customerId
        //        Axios.get('/api/payments/subscription', { params: subParams })
        //        .then((response) => {
        //          console.log('Subscription query attempted', response.data);
        //
        //           if (response.data.success) {
        //             console.log('successfully retrieved subscription')
        //
        //             const subscription = response.data.subscription
        //             const customerId = subscription.customerId
        //
        //             this.setState({ errorMessage: null, subscription, customerId })
        //
        //           } else {
        //             console.log('no active subscriptions data found', response.data.message)
        //             // this.setState({ errorMessage: response.data.message })
        //           }
        //
        //        }).catch((error) => {
        //           console.log('Subscription query did not work', error);
        //
        //           this.setState({ errorMessage: null })
        //        });
        //      }
        //
        //    } else {
        //      console.log('no profile details found', response.data.message)
        //    }
        //
        // }).catch((error) => {
        //    console.log('Org query did not work', error);
        // });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    checkEntry(type) {
      console.log('checkEntry called', type)

      if (type === 'promoCode') {
        if (this.state.availablePromos && this.state.availablePromos.includes(this.state.promoCode)) {
          const promoSuccessMessage = this.state.promoCode + ' successfully redeemed!'
          const promoErrorMessage = null
          this.setState({ promo: 1, promoSuccessMessage, promoErrorMessage })
        } else {
          const promoSuccessMessage = null
          const promoErrorMessage = 'Promo code not found'
          this.setState({ promoSuccessMessage, promoErrorMessage })
        }
      }
    }

    createCustomer(user) {
      console.log('createCustomer called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      const firstName = user.firstName
      const lastName = user.lastName
      const email = user.email
      const orgCode = user.orgCode
      const orgName = user.orgName
      const accountCode = user.accountCode
      const employerName = user.employerName
      const productPurchase = true

      const createdAt = new Date()
      const updatedAt = new Date()

      Axios.post('/api/payments/create-customer', {
        firstName, lastName, email, orgCode, orgName, accountCode, employerName, productPurchase,
        createdAt, updatedAt
      }).then((response) => {

        if (response.data.success) {
          //save values
          console.log('Customer created! ', response.data);

          const customerId = response.data.customerId
          this.setState({ customerId, isSaving: false })

        } else {
          console.log('Customer not created')
          this.setState({ errorMessage: 'There was an error setting up your account'})

        }

      }).catch((error) => {
          console.log('Customer creation did not work', error);
      });
    }

    passProductData(product) {
      console.log('passProductData called in precheckoutbooking', product)

      if (product && !product.success && product.errorMessage) {
        this.setState({ errorMessage: product.errorMessage })
      } else {
        this.props.passProductData(product)
      }
    }

    render() {

      return (
        <div>

          {(this.props.pageSource !== 'landingPage') && (
            <div>
              <p className="heading-text-2">Payment Info</p>

            </div>
          )}
          <div className="spacer" />

          <div>
            <div>
              <p>Order Summary</p>
              <div className="spacer" />

              <div className="row-10 description-text-1">
                <div className="full-width bold-text row-5">
                  <div className="relative-column-55">
                    <p>Service</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Price</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Qty</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Total</p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="full-width row-5">
                  <div className="relative-column-55">
                    <p>{this.state.productName}</p>
                  </div>
                  <div className="relative-column-15">
                    <p>${this.state.price}</p>
                  </div>
                  <div className="relative-column-15">
                    <p>1</p>
                  </div>
                  <div className="relative-column-15">
                    <p>${this.state.price * 1}</p>
                  </div>
                  <div className="clear" />
                </div>

                <div className="full-width top-padding-5">
                  <div className="relative-column-55">
                    <p>Promo</p>
                  </div>
                  <div className="relative-column-15">
                    <p>{this.state.promo * 100}%</p>
                  </div>

                  <div className="relative-column-15 height-20" />

                  <div className="relative-column-15">
                    <p className="error-color">(${(this.state.promo * 1 * this.state.price).toFixed()})</p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="spacer"/>

                <hr />
                <div className="half-spacer" />

                <div className="row-5">
                  <div className="relative-column-55">
                    <p>Total</p>
                  </div>
                  <div className="relative-column-15 height-30" />

                  <div className="relative-column-15 height-30" />

                  <div className="relative-column-15 half-bold-text cta-color">
                    <p>${((1 * this.state.price) - (this.state.discount * 1 * this.state.price) - (this.state.promo * 1 * this.state.price) < 0 ? 0 : ((1 * this.state.price) - (this.state.discount * 1 * this.state.price) - (this.state.promo * 1 * this.state.price)).toFixed())}</p>
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />
              </div>
            </div>

            <div className="row-10 description-text-2">
              <div className="container-left">
                <p className="bold-text bottom-padding">Promo Code</p>
                <input className="text-field" type="text" placeholder="Promo Code" name="promoCode" value={this.state.promoCode} onChange={this.formChangeHandler} />
                {(this.state.promoSuccessMessage && this.state.promoSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.promoSuccessMessage}</p>}
                {(this.state.promoErrorMessage && this.state.promoErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.promoErrorMessage}</p>}

              </div>
              <div className="container-right top-padding-27">
                <button className="btn btn-squarish white-background cta-color row-3" onClick={() => this.checkEntry('promoCode')}>Redeem</button>
              </div>
              <div className="clear" />
            </div>

            {(this.state.bookingDate) ? (
              <div>
                {(this.state.customerId) ? (
                  <div>
                    <div className="row-10 description-text-2">
                      <p className="bold-text bottom-padding">Refunds and Cancellations</p>
                      <p>Refunds can be made available within 30 days of purchase if you are unsatisfied with your service.</p>
                    </div>
                    <div className="row-10">
                      <Elements stripe={(this.state.testingStripe) ? stripeTestPromise : stripeLivePromise}>
                        <CheckoutFormBooking history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                          lastName={this.state.lastName} orgName={this.state.orgName} employerName={this.state.employerName}
                          subscriptionType={null} customerId={this.state.customerId} price={this.state.price}
                          quantity={1} sourceLink={this.state.sourceLink} priceId={this.state.priceId}
                          passProductData={this.passProductData} productPurchase={true}

                          promoCode={this.state.promoCode} bookingDate={this.state.bookingDate}
                          profileItems={this.state.profileItems} recipientType={this.state.recipientType}

                          accountCode={this.state.accountCode} orgCode={this.state.orgCode}
                          productId={this.state.product._id}
                          name={this.state.product.name} description={this.state.product.description}

                          recipientFirstName={this.state.recipientFirstName} recipientLastName={this.state.recipientLastName}
                          recipientEmail={this.state.recipientEmail}
                          slug={this.state.product.slug} category={this.state.product.category} imageURL={this.state.product.imageURL}
                          advisorFirstName={this.state.product.advisorFirstName} advisorLastName={this.state.product.advisorLastName}
                          advisorEmail={this.state.product.advisorEmail} advisorBio={this.state.product.advisorBio}
                          advisorPic={this.state.product.advisorPic} advisorProfileLink={this.state.product.advisorProfileLink}
                          clientSecret={this.state.clientSecret}
                          />
                      </Elements>

                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                    </div>
                  </div>
                ) : (
                  <div className="row-10">
                    {(this.state.email) ? (
                      <div>
                        <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>

                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                      </div>
                    ) : (
                      <div>
                        <button className="btn btn-squarish medium-background standard-border" disabled={true} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="top-padding-20">
                <p className="error-color">Add your preferred booking date to checkout</p>
              </div>
            )}
          </div>
        </div>
      )
    }
}

export default withRouter(PreCheckoutBooking);
