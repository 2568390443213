import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import SubOpportunities from '../components/Subcomponents/Opportunities';
import withRouter from '../components/Functions/WithRouter';

class OpportunitiesPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          orgCode: null
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
      console.log('change in checkbox handled')
    }

    componentDidMount() {
      //see if user is logged in
      console.log('jobs page just mounted')
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#fff";

      let { orgCode, accountCode, targetRole, subPostType } = this.props.params

      if (subPostType) {
        subPostType = subPostType.replace(/-/g," ")
      }

      let noOrgCode = false
      if (!orgCode) {
        noOrgCode = true
      }
      // console.log('show the orgCode: ', orgCode)
      this.setState({ orgCode, noOrgCode, accountCode, targetRole, subPostType, ready: true })

      if (orgCode) {
        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const orgLogo = response.data.orgInfo.webLogoURIColor
              this.setState({ orgName, orgLogo });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      } else if (accountCode) {
        Axios.get('/api/account', { params: { accountCode } })
        .then((response) => {
          console.log('Account info query attempted within sub settings', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employerName = response.data.accountInfo.employerName
            const employerLogoPath = response.data.accountInfo.employerLogoURI

            this.setState({ employerName, employerLogoPath });
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }
    }

    componentWillUnmount(){
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"opportunitiesPage"} darkBackground={true}  activeOrg={this.state.orgCode}/>

              <section className="section-features">
                <div className="row">

                  <div className="calc-column-offset-140-static">
                    {(this.state.orgCode && this.state.orgName) && (
                      <h3>{this.state.orgName} Opportunities</h3>
                    )}
                    {(this.state.employerName && this.state.accountCode) && (
                      <h3>{this.state.employerName} Opportunities</h3>
                    )}
                    {(!this.state.orgCode && !this.state.accountCode) && (
                      <h3>Opportunities</h3>
                    )}

                  </div>
                  <div className="fixed-column-140">
                    {(this.state.orgLogo && this.state.orgLogo !== '') && (
                      <img src={this.state.orgLogo} alt="GC" className="image-auto-40 pin-right"/>
                    )}
                    {(this.state.employerLogoPath && this.state.employerLogoPath !== '') && (
                      <img src={this.state.employerLogoPath} alt="GC" className="image-auto-40 pin-right"/>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.ready) && (
                  <SubOpportunities activeOrg={this.state.orgCode} noOrgCode={this.state.noOrgCode} accountCode={this.state.accountCode} targetRole={this.state.targetRole} passedViewIndex={8} passedType={null} passedSubPostType={this.state.subPostType} pageSource="landingPage" history={this.props.navigate}/>
                )}
              </section>

              <section className="section-cta">
                  <div className="cta-container">
                      <div className="cta-text-box">
                          <p className="heading-text-2">Want to post an</p>
                          <p className="heading-text-1">Opportunity?</p>
                      </div>

                      <div className="cta-buttons">
                        <div className="vertically-centered-container">
                          <Link className="btn btn-full" to={'/problem-platform/add'} state={{ reason: "Challenge" }}>Post</Link>
                        </div>
                      </div>
                  </div>
              </section>

              {/*Footer()*/}
              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(OpportunitiesPage);
