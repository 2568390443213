import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import Modal from 'react-modal';
import withRouter from '../components/Functions/WithRouter';

const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const mentoringIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const projectIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const priceChart = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/price_chart.png';

class PricingPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          freeForCareerSeekers: true
        }

        this.renderBenefits = this.renderBenefits.bind(this)
        this.showModal = this.showModal.bind(this)
        this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

    }

    mobileNav (event) {
        console.log('mobile nav clicked')
        if (this.state.mobileNavClass === "side-nav") {
            this.setState({
                mobileNavClass: 'side-nav-mobile',
                isVisible: false
            })
        } else {
            this.setState({
                mobileNavClass: 'side-nav',
                isVisible: true
            })
        }
    }

    renderBenefits(beneficiary) {
      console.log('renderBenefits called', beneficiary)

      if (beneficiary === 'careerSeekers') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-left center-text horizontal-padding-3">
                {/*
                <div className="horizontal-padding-3">
                  <img src={founderPic} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#1: Call w/ CEO</p>
                <p className="row-5 description-text-2">Let's talk about you.</p>
                <p className="row-5 description-text-2">Harvard Business School graduate, 4 years investment experience, 6 years software development, product management, and entrepreneurship experience across industries. Personally conducted hundred of interviews with you.</p>
                <a href="https://www.creightontaylor.com" target="_blank" rel="noopener noreferrer"className="description-text-2">View Background</a>*/}
              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pathsIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Explore Paths</p>
                <p className="row-5 description-text-2">View, search, filter, match, and favorite to 1,000+ career paths. View detailed data on competencies, tasks, pay associated with career paths.</p>
                <p className="row-5 description-text-2">View fastest growing industries, highest paid occupations, and other labor market trends!</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={moneyIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#3: Use the Financial Planner</p>
                <p className="row-5 description-text-2">Import career info into our financial planning tool. Use the tool to weigh different career path incomes, expenses, and net worth in different scenarios. Add your expenses and loans to see how much money you'll take home.</p>
              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={socialIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#4: Connect with Others</p>
                <p className="row-5 description-text-2">Match, follow, and connect with peers, mentors, and groups. See who is similar in interests, values, personality, skills, and more. See who is complimentary. Send them a message, ask for a referral, or give a shoutout!</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">


              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={courseIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#5: Hands-On Learning</p>
                <p className="row-5 description-text-2">Create, edit, and get recommended weekly competency-based course schedules based on your career goals. Build and utilize competencies by submitting projects to real world problems. Get constructive feedback on your submissions. Easily import your accomplishments into work applications.</p>

              </div>
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={opportunitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#6: Opportunities</p>
                <p className="row-5 description-text-2">View, search, filter, favorite, get recommended, and apply to different opportunities.</p>
                <p className="row-5 description-text-2">Opportunities include career event, project, and work opportunities. Import your profile information for RSVPs, project submissions, and work applications.</p>
              </div>

              <div className="clear" />
            </div>
          </div>
        )
      } else if (beneficiary === 'workforce') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pathsIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#1: Career Exploration</p>
                <p className="row-5 description-text-2">Career-seekers can take career assessments and build up their profile before viewing top career matches and comparing them using the financial planning tool. Use these tools during workshops, and review favorites and financial outcomes during advising sessions. Clarity here make placement easier later.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={mentoringIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Mentor Matching</p>
                <p className="row-5 description-text-2">Guided Compass uses career assessments, competency endorsements, projects, experience, and other informaton to help you match career-seekers to mentors. Students and mentors can log resume reviews, mock interviews, and other advising information in Guided Compass.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={courseIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#3: Skill-Based Courses</p>
                <p className="row-5 description-text-2">Career-seekers get skill-based course recommendations to supplement skills they may be weaker in for their desired career path. Recommeend courses for career-seekers to upskill.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={eventIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#4: Career Events</p>
                <p className="row-5 description-text-2">Post workshops, job shadows, webinars, career fairs, and other career events. Career-seekers can RSVP and print out their Guided Compass profile for mentors and employers.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">

                <div className="horizontal-padding-3">
                  <img src={projectIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#5: Projects</p>
                <p className="row-5 description-text-2">Source and post assignments, problems (i.e., case studies), and challenges for career-seekers to submit projects. Provide public and/or private feedback on those project solutions, and award money.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={opportunitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#6: Work</p>
                <p className="row-5 description-text-2">Post work opportunites or have employers post work opportunities to your community. Design ideal candidate profiles for pathways. Career-seekers can import their profiles to apply to each posting. Easily refer candidates to employers, gather feedback, and track who gets hired.</p>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (beneficiary === 'employers') {
        return (
          <div key={beneficiary}>
            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={abilitiesIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#1: Design Ideal Candidates</p>
                <p className="row-5 description-text-2">Design benchmarks: the work preferences, interests, personality, skills, abilities, values, knowledge, education, projects, experience, and interview performance of your ideal entry-level candidates for each department.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#2: Build Your Talent Pipeline</p>
                <p className="row-5 description-text-2">Our matching algorithm will recommend your employer profile to career seekers as they explore career paths. Those who favorite your profile and mark their profile public, will be viewable in your talent pipeline. Additionally, as an employer, you can favorite top recommended candidates. Lastly, you can invite candidate leads to Guided Compass to see how they fare against your ideal.</p>
              </div>
              <div className="clear" />
            </div>

            <div className="row-10">
              <div className="container-right center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>
                <p className="heading-text-4 top-padding">#3: Engage Your Talent Pipeline</p>
                <p className="row-5 description-text-2">Invite your talent pipeline to job shadows, webinars, talks, and other career-related events. Additionally, you can invite people to work on assignments, case studies, or challenges you post as a screening mechanism. Engaged candidates earn points for when they apply.</p>
              </div>
              <div className="container-left center-text horizontal-padding-3">
                <div className="horizontal-padding-3">
                  <img src={pipelineIconDark} alt="GC" className="image-half-auto center-horizontally" />
                </div>

                <p className="heading-text-4 top-padding">#4: Get Recommended Candidates</p>
                <p className="row-5 description-text-2">Get algorithmically matched talent pipelines based on your ideal candidate profiles. Engage with that talent pipeline by messaging, inviting to career events, giving assignments, providing feedback on projects, and hiring.</p>
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      }
    }

    showModal(e,type) {
      console.log('showModal called')

      e.preventDefault()

      if (type === 'signup') {
        this.props.navigate('/join')
      } else if (type === 'showPricingChart') {
        this.setState({ modalIsOpen: true, showPricingChart: true })
      } else if (type === 'navEmpPost') {
        this.props.navigate('/employers/post')
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showPricingChart: false })

    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"pricingPage"} darkBackground={true} />

              <section className="section-features row">
                {(window.innerWidth < 768) && (
                  <div>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  </div>
                )}
                <div className="bottom-padding-20">
                  <p className="full-width description-text-4 cta-color bold-text center-text bottom-padding">PRICING</p>
                  <h2>Our Career Development Tech Helps Everyone</h2>
                </div>

                <div>
                  <div className={(window.innerWidth > 768) ? "flex-container row-direction center-text horizontal-padding-7-percent" : "flex-container column-direction center-text horizontal-padding-7-percent"}>
                    <div className={(window.innerWidth > 768) ? "flex-33 horizontal-padding-2-percent" : "flex-33 horizontal-padding-2-percent bottom-padding-20"}>
                      <Link to={(this.state.freeForCareerSeekers) ? "/join" : { pathname: "/career-seekers/checkout", state: { reason: 'Purchase Guided Compass Subscription', body: 'I am interested in joining the monthly career-seeker subscription plan.' }}} className="background-button standard-color">
                        <div className="white-background medium-shadow">
                          <div className="cs-background-image padding-30">
                            <p className="description-text-1 bold-text">Career-Seekers</p>
                            <div className="spacer" />

                            {(this.state.freeForCareerSeekers) ? (
                              <div className="top-padding bottom-padding">
                                <label className="heading-text-1 cta-color">$0</label>
                                <div className="clear" />
                              </div>
                            ) : (
                              <div className="top-padding bottom-padding">
                                <label className="heading-text-1">$5</label><label className="description-text-color">/mo</label><label className="error-color">*</label>
                                <div className="clear" />
                              </div>
                            )}

                            <p className="description-text-2 row-10 bold-text"><label className="italicize-text">Supercharge</label> your purpose and career</p>

                            <div className="top-padding">
                              <Link to={(this.state.freeForCareerSeekers) ? "/join" : { pathname: "/career-seekers/checkout", state: { reason: 'Purchase Guided Compass Subscription', body: 'I am interested in joining the monthly career-seeker subscription plan.' }}} className="background-button"><button className="btn btn-squarish" onClick={() => console.log('hit')}>Get Started</button></Link>
                            </div>

                            <div className="spacer" />
                          </div>

                          <div className="bottom-padding-30 horizontal-padding-3">
                            <div className="left-text top-padding-20 horizontal-padding-3 description-text-2">
                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Transparent Pathways</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Explore Career Paths</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Financial Planner</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Connect with Others</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Hands-On Learning</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Manage Opportunities</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>

                            <div className="top-padding-20">
                              <label className="error-color">*</label><label className="description-text-3">Join for free <button className="background-button cta-color bold-text underline-text offset-underline" onClick={(e) => this.showModal(e,'signup')}>here</button>, or get sponsored by an education / workforce organization.</label>
                            </div>
                          </div>
                        </div>
                      </Link>

                    </div>
                    <div className={(window.innerWidth > 768) ? "flex-33 horizontal-padding-2-percent" : "flex-33 horizontal-padding-2-percent bottom-padding-20"}>
                      <Link to={"/workforce/checkout"} state={{ reason: 'Purchase Guided Compass Subscription', body: 'I am interested in joining the monthly career-seeker subscription plan.' }} className="background-button standard-color">
                        <div className="white-background medium-shadow">
                          <div className="wf-background-image padding-30">
                            <p className="description-text-1 bold-text">Educators & Workforce</p>
                            <div className="spacer" />

                            <div className="top-padding bottom-padding">
                              <label className="heading-text-1">$2</label><label className="description-text-color">/mo/cs</label><label className="error-color">*</label>
                              <div className="clear" />
                            </div>

                            <p className="description-text-2 row-10 bold-text"><label className="italicize-text">Supercharge</label> your assistance to career-seekers</p>

                            <div className="top-padding">
                              <Link to={"/workforce/checkout"} state={{ reason: 'Purchase Guided Compass Subscription', body: 'I am interested in a dedicated portal for my career-seekers and other stakeholders.' }} className="background-button"><button className="btn btn-squarish" onClick={() => console.log('hit')}>Get Started</button></Link>
                            </div>

                            <div className="spacer" />
                          </div>

                          <div className="horizontal-padding-3 bottom-padding-30">
                            <div className="left-text top-padding-20 horizontal-padding-3 description-text-2">
                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Customized portals</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>PBL / WBL Matching</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>1K+ Hours of Lessons</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Engage Alumni</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Automate Tasks</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Improve Outcomes</p>
                                </div>
                                <div className="clear" />
                              </div>
                              {/*
                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Dashboard & Reporting</p>
                                </div>
                                <div className="clear" />
                              </div>*/}
                            </div>

                            <div className="top-padding-20">
                              <label className="error-color">*</label><label className="description-text-3">Discounts are available based on the number of career-seekers in your organization. <button className="background-button cta-color bold-text underline-text offset-underline" onClick={(e) => this.showModal(e,'showPricingChart')}>See Price Chart</button>.</label>
                            </div>
                          </div>
                        </div>

                      </Link>
                    </div>
                    <div className={(window.innerWidth > 768) ? "flex-33 horizontal-padding-2-percent" : "flex-33 horizontal-padding-2-percent bottom-padding-20"}>
                      <Link to={"/contact"} state={{ reason: 'Buy Subsription Licenses', body: 'I am purchasing employee licenses to create a better talent acquisition and talent retention ecosystem.' }} className="background-button standard-color">
                        <div className="white-background medium-shadow">
                          <div className="emp-background-image padding-30">
                            <p className="description-text-1 bold-text">Employers</p>
                            <div className="spacer" />

                            <div className="top-padding bottom-padding">
                              <label className="heading-text-1">$5</label><label className="description-text-color">/mo/emp</label><label className="error-color">*</label>
                            </div>

                            <p className="description-text-2 row-10 bold-text"><label className="italicize-text">Supercharge</label> your talent recruitment & retention</p>

                            <div className="top-padding">
                              <Link to={"/contact"} state={{ reason: 'Buy Subscription Licenses', body: 'I am purchasing employee licenses to create a better talent acquisition and talent retention ecosystem.' }} className="background-button standard-color"><button className="btn btn-squarish" onClick={() => console.log('hit')}>Get Started</button></Link>
                            </div>

                            <div className="spacer" />
                          </div>

                          <div className="horizontal-padding-3 bottom-padding-30">
                            <div className="left-text top-padding-20 horizontal-padding-3 description-text-2">
                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Design Internal Pathways</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>360 Employee Feedback</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Upskilling Tracking</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Talent Retention Optimizations</p>
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-5">
                                <div className="fixed-column-20">
                                  <div className="half-spacer" />
                                  <img src={checkmarkIcon} alt="GC" className="image-auto-12" />
                                </div>
                                <div className="calc-column-offset-20">
                                  <p>Get Referred Candidates</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                            {/*
                            <div className="top-padding-20">
                              <label className="error-color">*</label><label className="description-text-3">You can post opportunities for free <button className="background-button cta-color bold-text underline-text offset-underline" onClick={(e) => this.showModal(e,'navEmpPost')}>here</button>.</label>
                            </div>*/}

                            <div className="top-padding-20">
                              <label className="error-color">*</label><label className="description-text-3">Discounts are available as employee count increases.</label>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
              {/*
              <section className="section-demo">
                <div className="bottom-padding-20 row">
                  <h2>Career-Seekers</h2>
                  <div className="row-5 horizontal-padding-3 center-text">
                    <p>Explore career paths, employers, courses, our planning tools, and more! Use our platform to identify your career path, build your profile, and get referred to opportunities. View our <Link to="/help" target="_blank" rel="noopener noreferrer">Help Videos</Link> for walkthroughs.</p>
                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.freeForCareerSeekers) ? (
                  <div className="full-width center-text">
                    <Link to={"/join"} className="background-button">
                      <button className="btn btn-primary" onClick={() => console.log('hit')}>
                        Get Started
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div className="row">
                    {this.renderBenefits('careerSeekers')}
                  </div>
                )}

              </section>

              <section className="section-features">
                <div className="bottom-padding-20 row">
                  <h2>Educators & Workforce Professionals</h2>
                  <div className="row-5 horizontal-padding-3 center-text">
                    <p>Guided Compass works for with educators and workforce to more effectively and efficiently place career-seekers into careers that optimize their fulfillment. Benchmarks are the back-bone of our matching algorithm. Learn about benchmarks <Link to="/help/what-are-benchmarks" target="_blank" rel="noopener noreferrer"className="bold-text">here</Link>.</p>
                  </div>
                  <div className="spacer" />
                </div>

                <div className="row">
                  {this.renderBenefits('workforce')}
                  <div className="clear" />
                </div>

                <div className="full-width center-text top-padding-20">
                  <Link to={"/workforce/checkout"} state={{ reason: 'Purchase Guided Compass Subscription', body: 'I am interested in joining the monthly career-seeker subscription plan.' }} className="background-button">
                    <button className="btn btn-primary" onClick={() => console.log('hit')}>
                      Get Started
                    </button>
                  </Link>
                </div>
              </section>

              <section className="section-demo">
                <div className="bottom-padding-20">
                  <h2>Employers</h2>
                  <div className="row-5 horizontal-padding-3 center-text">
                    <p>Guided Compass allows you to design your ideal, entry-level candidate for each department, and uses an algorithm to rank candidates against that profile. This not only allows you to build digital talent pipelines, it also provides benchmarks so that our placement partners can train and refer best-fit candidates. Benchmarks are the back-bone of our matching algorithm. Learn about benchmarks <Link to="/help/what-are-benchmarks" target="_blank" rel="noopener noreferrer"className="bold-text">here</Link>.</p>
                  </div>
                  <div className="spacer" />
                </div>

                <div className="row">
                  {this.renderBenefits('employers')}
                  <div className="clear" />
                </div>

                <div className="full-width center-text top-padding-20">
                  <Link to={"/employers/checkout"} state={{ reason: 'Purchase Guided Compass Subscription', body: 'I am interested in joining the monthly career-seeker subscription plan.' }} className="background-button">
                    <button className="btn btn-primary" onClick={() => console.log('hit')}>
                      Get Started
                    </button>
                  </Link>
                </div>
              </section>*/}

              <Footer history={this.props.navigate} />

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
              >

              {(this.state.showPricingChart) && (
                <div key="showPricingChart" className="full-width padding-20">
                  <p className="heading-text-2">Pricing Chart</p>
                  <div className="spacer" />

                  <div className="row-10">
                    <img src={priceChart} alt="GC" className="image-full-auto"/>
                  </div>

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>

                </div>
              )}

             </Modal>
          </div>

        )
    }
}

export default withRouter(PricingPage);
