import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubTimesheet from './Subcomponents/Timesheet';
import SubNewsFeed from './Subcomponents/NewsFeed';
import DeactivatedModal from './Common/DeactivatedModal';
import withRouter from '../components/Functions/WithRouter';
import {requestAccessToWorkspace} from './Services/ProfileRoutes';

const careerMatchesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const internIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon-blue.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
          selectedIndex: 0,

          carouselCards: [],
          postings: [],
          mentors: [],
          trends: [],
          resources: [],
          selectedPosting: { _id: ''},
          endorsements: [],
          favorites: [],
          logs: [],
          applications: [],
          approvedTracks: [],

          isVisible: true,
          placeholder: { _id: '123'},

          searchTerm: '',
          placementAgency: false,
        }

        this.events = [
          "load",
          "mousemove",
          "mousedown",
          "click",
          "scroll",
          "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.addStory = this.addStory.bind(this)

        this.retrieveData = this.retrieveData.bind(this)

        this.renderApplications = this.renderApplications.bind(this)
        this.renderApprovedTracks = this.renderApprovedTracks.bind(this)
        this.updateApplications = this.updateApplications.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.submitRequest = this.submitRequest.bind(this)
        this.submitSignUpFields = this.submitSignUpFields.bind(this)
        this.renderSignUpField = this.renderSignUpField.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this)
        this.commandClicked = this.commandClicked.bind(this)

        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }


    clearTimeout() {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
      this.warnTimeout = setTimeout(this.warn, 15 * 60 * 1000);

      this.logoutTimeout = setTimeout(this.logout, 30 * 60 * 1000);
    }

    resetTimeout() {
      this.clearTimeout();
      this.setTimeout();
    }

    warn() {
      // alert("You will be logged out automatically in 15 minutes.");
    }

    logout() {
      // Send a logout request to the API
      console.log("Sending a logout request to the API...");
      //this.setState({ logginStatus: false });

      const emailId = this.state.emailId
      Axios.post('/api/users/logout', {
        emailId
      })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Successfully logged out', response.data);
          /*
          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Employer saved successfully!'
          })*/

        } else {
          console.error('There was an error logging out', response.data);

        }
      }).catch((error) => {
          console.log('There was an error logging out', error);
      });
    }

    destroy() {
      this.clearTimeout();

      for (var i in this.events) {
        window.removeEventListener(this.events[i], this.resetTimeout);
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const workModeString  = localStorage.getItem('workMode');
      const orgLogo = localStorage.getItem('orgLogo');

      let workMode = false
      if (workModeString === 'true') {
        workMode = true
      }

      const { postId } = this.props.params
      console.log('show postId: ', postId)

      const trends = [{ name: 'Highest Paying Occupations'}, {name: 'Fastest Growing Occupations'}, {name: 'Fastest Growing Industries'}, {name: ''}]
      const resources = [
        { name: 'McKinsey Skills Report', url: 'https://www.mckinsey.com/~/media/McKinsey/Featured%20Insights/Future%20of%20Organizations/Skill%20shift%20Automation%20and%20the%20future%20of%20the%20workforce/MGI-Skill-Shift-Automation-and-future-of-the-workforce-May-2018.ashx'},
        {name: 'Wealthfront Companies Report', url: 'https://cdn.wealthfront.com/public.email.images/2020_Career-Launching_ListFinal.pdf'},
        {name: 'LinkedIn Workforce Report', url: 'https://economicgraph.linkedin.com/resources/linkedin-workforce-report-october-2018'}
      ]

      let finishedQuery = false
      let carouselCards = []

      if (this.props.location && this.props.location.state) {

        const { source, passedOrg, passedFirstName, passedLastName, passedEmail, passedUsername } = this.props.location.state;
        console.log('show the goods: ', source, passedOrg, passedFirstName, passedLastName, passedEmail, passedUsername)

        this.setState({
            emailId: passedEmail, username: passedUsername, cuFirstName: passedFirstName, cuLastName: passedLastName,
            activeOrg: passedOrg, orgFocus, roleName: 'admin', orgLogo,
            trends, resources, workMode, finishedQuery, postId, ready: true
        });

        if (workMode) {
          this.retrieveData(passedEmail, passedOrg, orgFocus, workMode)
        }

      } else {
        console.log('show the bads', emailId)

        const sampleAssignment = { _id: '123', postType: 'Assignment', title: 'Sample Title', firstName: 'Creighton', lastName: 'Taylor', employerName: 'Guided Compass', background: 'sample background', description: 'sample description', guidelines: 'sample guidelines', resources: null, createdAt: 'Oct 20, 2020', updatedAt: 'Oct 20, 2020' }

        this.setState({
            emailId, username, cuFirstName, cuLastName, activeOrg, orgLogo, orgFocus,
            roleName, trends, resources,
            sampleAssignment, workMode, finishedQuery, carouselCards, postId, ready: true
        });

        if (workMode) {
          this.retrieveData(emailId, activeOrg, orgFocus, workMode)
        }
      }
    }

    retrieveData(emailId, activeOrg, orgFocus, workMode) {
      console.log('retrieveData called')

      if (emailId) {

        const email = emailId
        const finishedQuery = true

        if (workMode) {
          console.log('in work mode')
          const pullById = false

          if (pullById) {
            Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
             .then((response) => {
               console.log('query for profile data worked');

               if (response.data.success) {

                 console.log('profile data received', response.data)
                 const workIds = response.data.user.workIds

                 Axios.get('/api/work', { params: { workIds, onlyCurrent: true } })
                 .then((response) => {
                   console.log('Work query attempted within employer dashboard', response.data);

                   if (response.data.success) {
                     console.log('account info query worked in sub settings')

                     const workArray = response.data.workArray
                     this.setState({ workArray });

                     Axios.get('/api/timesheets', { params: { emailId, roleName: 'Worker', isSubmitted: false } })
                     .then((response) => {
                       console.log('Timesheets query attempted within employer dashboard', response.data);

                       if (response.data.success) {
                         console.log('Timesheets query worked in sub settings')

                         const timesheets = response.data.timesheets
                         if (timesheets) {
                           this.setState({ timesheets });
                         }
                       }

                     }).catch((error) => {
                       console.log('Timesheets query did not work for some reason', error);
                     });

                     Axios.get('/api/assignments', { params: { emailId, postType: 'Assignment', assigneeGroup: 'Private' } })
                     .then((response) => {
                       console.log('Assignments query attempted', response.data);

                       if (response.data.success) {
                         console.log('assignments query worked')

                         const assignments = response.data.assignments
                         if (response.data.assignments) {
                           this.setState({ assignments })
                         }

                       } else {
                         console.log('found no assignments', response.data.message)

                       }

                     }).catch((error) => {
                         console.log('Org info query did not work for some reason', error);
                     });

                     //pull projects for timesheet project options
                     Axios.get('/api/projects', { params: { emailId: email } })
                     .then((response) => {
                       console.log('Projects query attempted', response.data);

                         if (response.data.success) {
                           console.log('successfully retrieved projects')

                           if (response.data.projects) {

                             const projects = response.data.projects
                             if (projects.length > 0) {
                               this.setState({ projects, finishedQuery })
                             }
                           }

                         } else {
                           console.log('no project data found', response.data.message)
                           this.setState({ finishedQuery })
                         }
                     }).catch((error) => {
                         console.log('Project query did not work', error);
                         this.setState({ finishedQuery })
                     });
                   }

                 }).catch((error) => {
                   console.log('Account info query did not work for some reason', error);
                 });

               } else {
                 console.log('error response', response.data)
               }

           }).catch((error) => {
               console.log('query for assessment results did not work', error);
           })
          } else {

            Axios.get('/api/work', { params: { workerEmail: email, onlyCurrent: true } })
            .then((response) => {
              console.log('Work query attempted within employer dashboard', response.data);

              if (response.data.success) {
                console.log('account info query worked in sub settings')

                if (response.data.workArray && response.data.workArray.length > 0) {

                  const workArray = response.data.workArray
                  this.setState({ workArray });

                  Axios.get('/api/timesheets', { params: { emailId, roleName: 'Worker', isSubmitted: false } })
                  .then((response) => {
                    console.log('Timesheets query attempted within employer dashboard', response.data);

                    if (response.data.success) {
                      console.log('Timesheets query worked in sub settings')

                      const timesheets = response.data.timesheets
                      if (timesheets) {
                        this.setState({ timesheets });
                      }
                    }

                  }).catch((error) => {
                    console.log('Timesheets query did not work for some reason', error);
                  });

                  Axios.get('/api/assignments', { params: { emailId, postType: 'Assignment', assigneeGroup: 'Private' } })
                  .then((response) => {
                    console.log('Assignments query attempted', response.data);

                    if (response.data.success) {
                      console.log('assignments query worked')

                      const assignments = response.data.assignments
                      if (response.data.assignments) {
                        this.setState({ assignments })
                      }

                    } else {
                      console.log('found no assignments', response.data.message)

                    }

                  }).catch((error) => {
                      console.log('Org info query did not work for some reason', error);
                  });

                  //pull projects for timesheet project options
                  Axios.get('/api/projects', { params: { emailId: email } })
                  .then((response) => {
                    console.log('Projects query attempted', response.data);

                      if (response.data.success) {
                        console.log('successfully retrieved projects')

                        if (response.data.projects) {

                          const projects = response.data.projects
                          if (projects.length > 0) {
                            this.setState({ projects, finishedQuery })
                          }
                        }

                      } else {
                        console.log('no project data found', response.data.message)
                        this.setState({ finishedQuery })
                      }
                  }).catch((error) => {
                      console.log('Project query did not work', error);
                      this.setState({ finishedQuery })
                  });
                }
              }

            }).catch((error) => {
              console.log('Account info query did not work for some reason', error);
            });
          }

        } else {

        }
      }

    }

    addStory() {
        console.log('add story')
    }

    renderApplications() {
      console.log('renderApplications')

      let rows = []

      const applications = this.state.applications
      let rowCount = applications.length
      if (rowCount > 10) {
        rowCount = 10
      }

      for (let i = 1; i <= applications.length; i++) {

        rows.push(
          <div key={i}>
            <Link className="secret-link" to={'/app/logs/' + applications[i - 1]._id} state={{ objectId: applications[i - 1]._id, passedLogType: 'Native Application' }}>
              <div>
                <div className="spacer"/>
                <div>
                  <div className="fixed-column-50 top-margin-5">
                    <img src={internIconBlue} alt="GC" className="image-auto-50" />
                  </div>

                  <div className="calc-column-offset-110 padding-10">
                    <label className="heading-text-5">{applications[i - 1].postingTitle} @ {applications[i - 1].postingEmployerName}</label>
                    <div className="clear" />
                    <label className="description-text-color">{applications[i - 1].stage}</label>
                  </div>

                  <span className="float-right width-60 top-margin">
                    <div className="half-spacer" />
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                  </span>
                </div>
                <div className="clear" />
                <div className="spacer"/>
              </div>
            </Link>
          </div>
        )
      }

      return rows
    }

    renderApprovedTracks() {
      console.log('renderApprovedTracks')

      let rows = []

      const approvedTracks = this.state.approvedTracks

      for (let i = 1; i <= approvedTracks.length; i++) {

        rows.push(
          <div key={i}>
            <div>
              <div className="spacer"/>
              <div>
                <div className="fixed-column-50">
                  <img src={careerMatchesIconBlue} alt="Compass profile icon" className="image-auto-40"/>
                </div>

                <div className="calc-column-offset-100-static">
                  <label className="heading-text-5">{approvedTracks[i - 1].name}</label>
                  <div className="clear" />
                  <label className="description-text-color">{approvedTracks[i - 1].jobs.length} Postings</label>
                </div>

                <span className="fixed-column-50 top-margin">

                  <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                </span>
              </div>
              <div className="clear" />
              <div className="spacer"/>
            </div>
          </div>
        )
      }

      return rows
    }

    updateApplications() {
      console.log('updateApplications')

      this.setState({ isUpdating: true, serverErrorMessage: '', serverSuccessMessage: '' })

      //update all applications
      const firstName = this.state.cuFirstName
      const lastName = this.state.cuLastName
      const email = this.state.emailId
      const schoolName = this.state.schoolName
      const major = this.state.major
      const gradYear = this.state.gradYear
      const resumeURL = this.state.resumeURL
      const linkedInURL = this.state.linkedInURL
      const customWebsiteURL = this.state.customWebsiteURL
      const pictureURL = this.state.pictureURL

      const politicalAlignment = this.state.politicalAlignment
      const stateRegistration = this.state.stateRegistration
      const currentCongressionalDistrict = this.state.currentCongressionalDistrict
      const hometown = this.state.hometown
      const homeCongressionalDistrict = this.state.homeCongressionalDistrict
      const dacaStatus = this.state.dacaStatus

      const workPreferenceResults = this.state.workPreferenceResults
      const interestResults = this.state.interestResults
      const skillAnswers = this.state.skillAnswers
      const newSkillAnswers = this.state.newSkillAnswers
      const skillResults = this.state.skillResults
      const personalityResults = this.state.personalityResults

      const endorsements = this.state.endorsements
      const projects = this.state.projects
      const experience = this.state.experience
      const updatedAt = new Date()

      const applications = this.state.applications

      Axios.post('/api/applications/update', {
        firstName, lastName, email, schoolName, major, gradYear,
        politicalAlignment, stateRegistration, currentCongressionalDistrict, hometown, homeCongressionalDistrict, dacaStatus,
        workPreferenceResults, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults,
        resumeURL, linkedInURL, customWebsiteURL, pictureURL, endorsements, projects, experience, updatedAt,
        applications
      }).then((response) => {
        console.log('attempting to save')
        if (response.data.success) {
          console.log('saved successfully', response.data.message)

          this.setState({ modalIsOpen: true, hasUpdated: true, serverSuccessMessage: 'Applications successfully updated', isUpdating: false, showInfo: false })

        } else {
          console.log('did not save successfully', response.data)
          this.setState({ modalIsOpen: true, serverErrorMessage: 'error:' + response.data.message, isUpdating: false, showInfo: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ modalIsOpen: true, serverErrorMessage: 'There was an error updating your application', isUpdating: false, showInfo: false })
      });
    }

    closeModal() {
      this.setState({ modalIsOpen: false, hasUpdated: false, updateApplications: false, viewWork: false, timesheetInfo: false, showInfo: false, showSignUpFields: false, watchVideo: false, aboutGC: false });
    }

    passStatus = () => {
      console.log('passStatus called', this.state.timesheets, this.state.selectedIndex)
      let timesheets = this.state.timesheets
      timesheets[this.state.selectedIndex]['isSubmitted'] = true
      this.setState({ timesheets })
    }

    submitSignUpFields() {
      console.log('submitSignUpFields called')

      if (!this.state.dateOfBirth || this.state.dateOfBirth === '') {
        this.setState({ errorMessage: 'Please add your date of birth' })
      } else if (!this.state.gender || this.state.gender === '') {
        this.setState({ errorMessage: 'Please add your gender' })
      } else if (!this.state.race || this.state.race === '') {
        this.setState({ errorMessage: 'Please add your race' })
      } else if (!this.state.address || this.state.address === '') {
        this.setState({ errorMessage: 'Please add your address' })
      } else if (!this.state.phoneNumber || this.state.phoneNumber === '') {
        this.setState({ errorMessage: 'Please add your phone number' })
      } else if (!this.state.numberOfMembers || this.state.numberOfMembers === '') {
        this.setState({ errorMessage: 'Please add the number of members in your household' })
      } else if (!this.state.householdIncome || this.state.householdIncome === '') {
        this.setState({ errorMessage: 'Please add your household income' })
      } else if (!this.state.fosterYouth || this.state.fosterYouth === '') {
        this.setState({ errorMessage: 'Please indicate whether you have been a foster youth' })
      } else if (!this.state.homeless || this.state.homeless === '') {
        this.setState({ errorMessage: 'Please indicate whether you have been homeless' })
      } else if (!this.state.incarcerated || this.state.incarcerated === '') {
        this.setState({ errorMessage: 'Please indicate whether you have been incarcerated' })
      } else if (!this.state.adversityList || this.state.adversityList === '') {
        this.setState({ errorMessage: 'Please add all that applies' })
      } else {

        const dateOfBirth = this.state.dateOfBirth
        const gender = this.state.gender
        const race = this.state.race
        const address = this.state.address
        const phoneNumber = this.state.phoneNumber
        const numberOfMembers = this.state.numberOfMembers
        const householdIncome = this.state.householdIncome
        const fosterYouth = this.state.fosterYouth
        const homeless = this.state.homeless
        const incarcerated = this.state.incarcerated
        const adversityList = this.state.adversityList

        const signUpFields = {
          dateOfBirth, gender, race, address, phoneNumber, numberOfMembers, householdIncome,
          fosterYouth, homeless, incarcerated, adversityList
        }

        this.submitRequest(this.state.orgSelected, signUpFields)

      }
    }

    async submitRequest(value, passedSignUpFields) {
      console.log('submitRequest called')

      if (value.cta === 'Join Workspace') {

        if (value.signUpFields) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, value.cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue) {
              let myOrgs = this.state.myOrgs
              if (returnedValue.success) {
                if (myOrgs) {
                  myOrgs.push(value.orgCode)
                } else {
                  myOrgs = [value.orgCode]
                }
              }

              localStorage.setItem('activeOrg', value.orgCode)
              localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs, modalIsOpen: false, showSignUpFields: false })
            } else {
              this.setState({ errorMessage: 'There was an unknown error' })
            }
          } else {
            this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: true })
          }

        } else {
          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, value.cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue) {
            let myOrgs = this.state.myOrgs
            if (returnedValue.success) {
              if (myOrgs) {
                myOrgs.push(value.orgCode)
              } else {
                myOrgs = [value.orgCode]
              }
            }

            localStorage.setItem('activeOrg', value.orgCode)
            localStorage.setItem('myOrgs', JSON.stringify(myOrgs))

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs })
          } else {
            this.setState({ errorMessage: 'There was an unknown error' })
          }
        }

      } else if (value.cta === 'Request Access' || value.cta === 'Join Waitlist') {
        console.log('request access')

        if (value.signUpFields) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, value.cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue) {
              let joinRequests = this.state.joinRequests
              if (returnedValue.success) {
                if (joinRequests) {
                  joinRequests.push(value.orgCode)
                } else {
                  joinRequests = [value.orgCode]
                }
              }

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests, modalIsOpen: false, showSignUpFields: false })
            } else {
              this.setState({ errorMessage: 'There was an unknown error' })
            }
          } else {
            this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: false })
          }

        } else {

          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, value.cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue) {
            let joinRequests = this.state.joinRequests
            if (returnedValue.success) {
              if (joinRequests) {
                joinRequests.push(value.orgCode)
              } else {
                joinRequests = [value.orgCode]
              }
            }

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests })

          } else {
            this.setState({ errorMessage: 'There was an unknown error' })
          }
        }
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })

    }

    renderSignUpField(value, index) {
      console.log('renderSignUpField called')

      return (
        <div key="signUpField">
          <label className="profile-label">{value.name}<label className="error-color bold-text">*</label></label>
          {(value.questionType === 'Date') && (
            <input type="date" className="date-picker white-background" name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Short Answer') && (
            <input type="text" className="text-field" placeholder="Your answer..." name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
          )}
          {(value.questionType === 'Multiple Choice') && (
            <select name={value.shorthand} className="dropdown" value={this.state[value.shorthand]} onChange={this.formChangeHandler}>
                {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
            </select>
          )}
          {(value.questionType === 'Multiple Answer') && (
            <div>
              {value.answerChoices.map((value2, optionIndex) =>
                <div key={value2 + optionIndex}>
                  <div className="top-margin-5 right-padding">
                    {(this.state[value.shorthand] && this.state[value.shorthand].includes(value2)) ? (
                      <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2 white-text">{value2}</p>
                          </div>
                        </div>
                      </button>
                    ) : (
                      <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                        <div>
                          <div className="float-left left-text">
                            <p className="description-text-2">{value2}</p>
                          </div>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}


        </div>
      )
    }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let items = this.state[value.shorthand]
      if (items) {
        if (items.includes(value2)) {
          const index = items.indexOf(value2)
          items.splice(index, 1)
        } else {
          items.push(value2)
        }

      } else {
        items = [value2]
      }

      this.setState({ [value.shorthand]: items })

    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />

              {(this.state.deactivated) ? (
                <div>
                  <DeactivatedModal orgName={this.state.orgName} roleName={this.state.roleName} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} redirectLink={this.state.redirectLink} />
                </div>
              ) : (
                <div>
                  {(this.state.workMode) ? (
                    <div>

                      <div className="standard-container-2">
                        {(this.state.workArray) ? (
                          <div>
                            {this.state.workArray.map((value, index) =>
                              <div className="padding-15">
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                <div className="horizontal-padding-4">
                                  <div className="float-left">
                                    <p className="heading-text-2">Manage Your Work</p>
                                  </div>

                                  <div className="clear" />
                                  <div className="spacer"/><div className="spacer"/>
                                </div>

                                <div>
                                  <div className="col span-1-of-3 box">
                                    <div className="float-left">
                                      <p className="heading-text-5">Positions</p>
                                    </div>

                                    <div className="clear" />

                                    <div className="top-margin-5">
                                      <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, updateApplications: false, viewWork: true, selectedIndex: index, viewTimesheetInfo: false, submitTimesheet: false, showInfo: false })}>
                                        <div className="row-15 right-padding">
                                          <p className="cta-color half-bold-text">{value.title}</p>

                                          <div className="description-text-2 top-margin-5">
                                            <label className="right-padding">{value.startDate.substring(0,10)} - {value.isPerpetual ? "Continual" : value.endDate.substring(0,10)}</label>
                                            <label className="right-padding">|</label>
                                            <label className="right-padding">{value.hoursPerWeek} Per Week</label>
                                            <label className="right-padding">|</label>
                                            <label className="right-padding">15 interns</label>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      </a>
                                    </div>

                                  </div>
                                  <div className="col span-1-of-3 box">
                                    <div>
                                      <div className="float-left">
                                        <p className="heading-text-5">Timesheets</p>
                                      </div>
                                      <div className="float-left left-margin">
                                        <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, viewWork: false, timesheetInfo: true, submitTimesheet: false, editAssignment: false, showInfo: false })}>
                                          <div className="cta-border noti-bubble-info-7-9">
                                            <div>
                                              <img src={questionMarkBlue} alt="GC" className="image-auto-15 center-item"/>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    <div>
                                      {(this.state.timesheets && this.state.timesheets.length > 0) ? (
                                        <div>
                                          {this.state.timesheets.map((ts, tsIndex) =>
                                            <div>
                                              <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, viewWork: false, timesheetInfo: false, submitTimesheet: true, editAssignment: false, selectedIndex: tsIndex, showInfo: false })}>
                                                {(ts.startDate && ts.endDate) && (
                                                  <div className="row-15 right-padding">
                                                    <p className="cta-color half-bold-text">{ts.startDate.substring(5,10).replace(/-/g,"/") + '/' + ts.startDate.substring(0,4).replace(/-/g,"/")} - {ts.isPerpetual ? "Continual" : ts.endDate.substring(5,10).replace(/-/g,"/") + '/' + ts.endDate.substring(0,4).replace(/-/g,"/")}</p>

                                                    <div className="description-text-2 top-margin-5">
                                                      <label className="right-padding">{ts.title} @ {ts.employerName.replace("'",'')}</label>

                                                      <div className="clear" />
                                                      {(ts.isSubmitted === true) ? (
                                                        <div>
                                                          {(ts.isApproved) ? (
                                                            <label className="cta-color right-padding">Approved!</label>
                                                          ) : (
                                                            <label className="error-color right-padding">Submitted</label>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {(ts.isRejected) ? (
                                                            <label className="cta-color right-padding">Denied</label>
                                                          ) : (
                                                            <label className="error-color right-padding">Not Submitted</label>
                                                          )}
                                                        </div>
                                                      )}

                                                      <div className="clear" />
                                                    </div>
                                                  </div>
                                                )}
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="description-text-1 top-margin">
                                          <p className="error-message">There are no timesheets available to submit.</p>
                                        </div>
                                      )}

                                    </div>

                                  </div>
                                  <div className="col span-1-of-3 box">
                                    <div>
                                      <div className="float-left">
                                        <p className="heading-text-5">Assignments</p>
                                      </div>

                                      <div className="clear" />
                                    </div>

                                    {(this.state.assignments && this.state.assignments.length > 0) ? (
                                      <div className="top-margin-5">
                                        {this.state.assignments.map((value, index) =>
                                          <div key={"assignment|" + index}>
                                            {(value.name) && (
                                              <Link to={'/app/opportunities/' + value._id} state={{ selectedOpportunity: value, source: 'Student' }} className="background-link">
                                                <div className="row-15 right-padding">
                                                  <p className="cta-color half-bold-text">{value.name}</p>

                                                  <div className="description-text-2 top-margin-5">
                                                    <label className="right-padding">{value.employerName}</label>

                                                    <label className="right-padding">|</label>
                                                    {value.submissionDeadline && (
                                                      <span>
                                                        <label className="right-padding">Deadline: {value.submissionDeadline.substring(0,10)}</label>
                                                        <label className="right-padding">|</label>
                                                      </span>
                                                    )}

                                                    <label className="right-padding">{value.duration} Estimated Hours</label>
                                                    <div className="clear" />
                                                  </div>
                                                </div>
                                              </Link>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="description-text-1 top-margin">
                                        <p className="error-message">There are no outstanding assignments.</p>
                                      </div>
                                    )}

                                  </div>

                                  <div className="clear" />
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                            <p className="error-message">We were not able to find any work that you were assigned to.</p>
                            <div className="spacer"/>
                          </div>
                        )}
                      </div>

                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                       >

                        {(this.state.viewWork) && (
                          <div className="full-width padding-30">

                            <div>
                              <div className="calc-column-offset-30">
                                <p className="heading-text-2">{this.state.workArray[this.state.selectedIndex].title}</p>
                                <p>{this.state.workArray[this.state.selectedIndex].workType} @ <a target="_blank" href={this.state.workArray[this.state.selectedIndex].employerURL}>{this.state.workArray[this.state.selectedIndex].employerName}</a></p>
                              </div>
                              <div className="fixed-column-30">
                                <button className="background-button" onClick={() => this.closeModal()}>
                                  <img src={closeIcon} alt="GC" className="image-auto-15 pin-right top-margin" />
                                </button>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer" />

                            <div className="edit-profile-row">
                              <div className="container-left">
                                <label className="description-text-2 top-padding">Start Date</label>
                                <p>{this.state.workArray[this.state.selectedIndex].startDate.substring(0,10)}</p>
                              </div>
                              <div className="container-right">
                                <label className="description-text-2 top-padding">End Date</label>
                                {(this.state.workArray[this.state.selectedIndex].isPerpetual) ? (
                                  <div>
                                    <p>Is Continual</p>
                                  </div>
                                ) : (
                                  <div>
                                    <p>{this.state.workArray[this.state.selectedIndex].endDate.substring(0,10)}</p>
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="edit-profile-row">
                              <div className="container-left">
                                <label className="description-text-2 top-padding">Field</label>
                                <p>{this.state.workArray[this.state.selectedIndex].field}</p>
                              </div>
                              <div className="container-right">
                                <label className="description-text-2 top-padding">Hours Per Week</label>
                                <p>{this.state.workArray[this.state.selectedIndex].hoursPerWeek}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="spacer" />
                            <hr className="clear-margin clear-padding"/>
                            <div className="spacer" />

                            <div className="edit-profile-row">
                              <div className="container-left">
                                <label className="description-text-2 top-padding">Hiring Manager First Name</label>
                                <p>{this.state.workArray[this.state.selectedIndex].hiringManagerFirstName}</p>
                              </div>
                              <div className="container-right">
                                <label className="description-text-2 top-padding">Hiring Manager Last Name</label>
                                <p>{this.state.workArray[this.state.selectedIndex].hiringManagerLastName}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="edit-profile-row">
                              <div className="container-left">
                                <label className="description-text-2 top-padding">Location</label>
                                <p>{this.state.workArray[this.state.selectedIndex].location}</p>
                              </div>
                              <div className="container-right">
                                <label className="description-text-2 top-padding">Affiliated Organization</label>
                                <p>{this.state.workArray[this.state.selectedIndex].orgName}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="edit-profile-row">
                              <div className="container-left">
                                <label className="description-text-2 top-padding">Pay Range</label>
                                <p>{this.state.workArray[this.state.selectedIndex].payRange}</p>
                              </div>
                              <div className="container-right">
                                <label className="description-text-2 top-padding">Pay Type</label>
                                <p>{this.state.workArray[this.state.selectedIndex].payType}</p>
                              </div>
                              <div className="clear" />
                            </div>

                          </div>
                        )}

                        {(this.state.timesheetInfo) && (
                          <div className="full-width padding-30">

                            <div>
                              <div className="calc-column-offset-30">
                                <p className="heading-text-2">Timesheet Info</p>
                              </div>
                              <div className="fixed-column-30">
                                <button className="background-button" onClick={() => this.closeModal()}>
                                  <img src={closeIcon} alt="GC" className="image-auto-15 pin-right top-margin" />
                                </button>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="spacer" />
                            <p>Weekly timesheets will appear here on Sunday until the end of the work period or until your employer disables them.</p>

                            <div className="spacer"/>
                          </div>
                        )}

                        {(this.state.submitTimesheet) && (
                          <div className="full-width padding-30">
                            <SubTimesheet selectedTimesheet={this.state.timesheets[this.state.selectedIndex]} roleName="Worker" assignmentOptions={this.state.assignments} projectOptions={this.state.projects} passStatus={this.passStatus} closeModal={this.closeModal} />
                          </div>
                        )}

                      </Modal>

                    </div>
                  ) : (
                    <div>
                      {(this.state.ready) && (
                        <SubNewsFeed history={this.props.navigate} postId={this.state.postId} commandClicked={this.commandClicked} />
                      )}
                    </div>
                  )}
                </div>
              )}

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(App);
