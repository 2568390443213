import React, {Component} from 'react';
import LoginForm from '../Subcomponents/LoginForm';
import withRouter from '../Functions/WithRouter';

class OrgSignIn extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('componentDidMount called on org')
      const { org } = this.props.params
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <LoginForm roleName="Admin" orgCode={this.state.org} courseId={this.state.courseId} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignIn" />
        </div>
      )
    }
}

export default withRouter(OrgSignIn)
