import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';

class NotificationPreferences extends Component {
    constructor(props) {
        super(props)

        this.state = {
          categories: [],
          preferences: [],

          preferenceOptions: [],
          unsubscribeOptions: [],
          binaryOptions: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.togglePreference = this.togglePreference.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subnotificationpreferences', this.props, prevProps)

      if (this.props.emailId !== prevProps.emailId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subnotificationpreferences', this.props.notificationType)

      // sign in, change preferences

      if (this.props.orgCode) {
        const preferenceOptions = [
          'Receive notifications on opportunities',
          'Unsubscribe from notifications on opportunities'
        ]

        Axios.get('/api/org', { params: { orgCode: this.props.orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const orgContactEmail = response.data.orgInfo.contactEmail
              const careerTrackNotifications = response.data.orgInfo.careerTrackNotifications
              const parentPostId = response.data.orgInfo.parentPostId

              const option1 = "I've gotten a job separate from " + orgName
              const option2 = "I've gotten a position through " + orgName
              const option3 = "I'm not looking for anything for the next 6+ months"
              const option4 = "These opportunities were never relevant to me or my network"
              const option5 = "Other"

              const unsubscribeOptions = [ option1, option2, option3, option4, option5 ]

              this.setState({ preferenceOptions, unsubscribeOptions, careerTrackNotifications, parentPostId,
                orgName, orgContactEmail
              })

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        const email = this.props.emailId
        console.log('show props: ', this.props.emailId)

        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          if (response.data) {

            console.log('Successfully retrieved data', response.data);

            if (response.data.success) {
              const unsubscribed = response.data.user.unsubscribed
              const reason = response.data.user.unsubscribeReason
              const otherExplanation = response.data.user.otherExplanation
              const placedJobTitle = response.data.user.placedJobTitle
              const placedEmployerName = response.data.user.placedEmployerName

              let preferences = preferenceOptions[0]
              if (unsubscribed) {
                preferences = preferenceOptions[1]
              }

              if (unsubscribed !== undefined) {
                this.setState({ preferences, reason, otherExplanation, placedJobTitle, placedEmployerName })
              }
            } else {
              console.log('there was an error retrieving profile info: ', response.data.message)
            }

          }
        }).catch((error) => {
            console.log('Error retrieving profile information', error);
        });
      // } else if (this.props.slug) {
      //   // unsubscribe from specific notification
      //
      //   const binaryOptions = ['Yes','No']
      //   this.setState({ binaryOptions })
    } else if (this.props.emailId) {
        // by type, unsubscribe by id

        const emailId = this.props.emailId
        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {
          if (response.data) {

            console.log('Successfully retrieved data', response.data);

            if (response.data.success) {

              let preferences = [
                { name: 'Notifications when people send you messages', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'incoming-messages', types: ['email'], enabled: true },
                { name: 'Comments on your post and replies to your comments', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'incoming-comments', types: ['email'], enabled: true },
                { name: 'Mentions or tags of you in a post', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'post-tags', types: ['email'], enabled: true },
                { name: 'Announcements from community admin staff', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'announcements', types: ['email','text'], enabled: true },
                // { name: 'Email announcements from community admin staff', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'email-announcements', types: ['email'], enabled: true },
                // { name: 'Text announcements from community admin staff', category: "Conversations & Announcements", categoryDescription: 'Messages, posts, comments', slug: 'text-announcements', types: ['text'], enabled: true },
                { name: 'Connection requests sent to you', category: "Community", categoryDescription: 'Groups, connections', slug: 'friend-requests', types: ['email'], enabled: true },
                { name: 'Acceptances of your connection request', category: "Community", categoryDescription: 'Groups, connections', slug: 'accepted-friend-requests', types: ['email'], enabled: true },
                { name: 'Group invitations sent to you', category: "Community", categoryDescription: 'Groups, connections', slug: 'group-invites', types: ['email'], enabled: true },
                { name: 'Group requests to join your group', category: "Community", categoryDescription: 'Groups, connections', slug: 'group-requests', types: ['email'], enabled: true },
                { name: 'Endorsements are added to your profile', category: "Profile", categoryDescription: 'Endorsements, suggestions', slug: 'new-endorsements', types: ['email'], enabled: true },
                { name: 'Suggestions/resources are sent to you', category: "Profile", categoryDescription: 'Endorsements, suggestions', slug: 'new-suggestions', types: ['email'], enabled: true },
                { name: 'Weekly email of event, project, and work opportunities', category: "Opportunities", categoryDescription: 'Events, projects, work', types: ['email'], slug: 'opportunities-digest', enabled: true },
                { name: 'Reminders before events if enabled by host', category: "Opportunities", categoryDescription: 'Events, projects, work', slug: 'event-reminders', types: ['text','email'], enabled: true },
                { name: 'Updates on your referrals', category: "Opportunities", categoryDescription: 'Events, projects, work', slug: 'referral-updates', types: ['email'], enabled: true },
              ]

              if (response.data.user.notificationPreferences && response.data.user.notificationPreferences.length > 0) {
                preferences = response.data.user.notificationPreferences
              }

              let categories = []
              let notificationName = null
              for (let i = 1; i <= preferences.length; i++) {
                if (!categories.some(a => a.name === preferences[i - 1].category)) {
                  categories.push({ name: preferences[i - 1].category, description: preferences[i - 1].categoryDescription })
                }
                console.log('a2: ', preferences[i - 1].slug, this.props.slug)
                if (this.props.slug && preferences[i - 1].slug === this.props.slug) {
                  notificationName = preferences[i - 1].name.toLowerCase()
                }
              }
              console.log("+++++===, a1", categories)
              const binaryOptions = ['Yes','No']

              this.setState({ categories, preferences,notificationName, emailId, binaryOptions })

              // { name: 'Notifications when people send you messages', category: "Conversations", categoryDescription: 'Messages, posts, comments', slug: 'incoming-messages', types: ['email'], enabled: true },
              // { name: 'Friend requests sent to you', category: "Community", categoryDescription: 'Groups, connections', slug: 'friend-requests', types: ['email'], enabled: true },

              // someone accepted your request

              // { name: 'Mentions or tags of you in a post', category: "Conversations", categoryDescription: 'Messages, posts, comments', slug: 'post-tags', types: ['email'], enabled: true },
              // { name: 'Group invitations sent to you', category: "Community", categoryDescription: 'Groups, connections', slug: 'group-invites', types: ['email'], enabled: true },
              // { name: 'Group requests to join your group', category: "Community", categoryDescription: 'Groups, connections', slug: 'group-requests', types: ['email'], enabled: true },

              // { name: 'Endorsements are added to your profile', category: "Profile", categoryDescription: 'Endorsements, suggestions', slug: 'new-endorsements', types: ['email'], enabled: true },
              // { name: 'Suggestions/resources are sent to you', category: "Profile", categoryDescription: 'Endorsements, suggestions', slug: 'new-suggestions', types: ['email'], enabled: true },
              // comments on your post

            } else {
              console.log('there was an error retrieving profile info: ', response.data.message)
            }

          }
        }).catch((error) => {
            console.log('Error retrieving profile information', error);
        });
      } else {
        console.log("+++++===, a3")
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler ', event)

      if (event.target.name === 'otherExplanation') {
        this.setState({ otherExplanation: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'placedJobTitle') {
        this.setState({ placedJobTitle: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'placedEmployerName') {
        this.setState({ placedEmployerName: event.target.value, formHasChanged: true })
      }
    }

    saveChanges() {
      console.log('saveChanges called', this.props.slug)

      this.setState({ serverErrorMessage: null, serverSuccessMessage: null, isSaving: true })

      if ((this.props.slug) || (window.location.pathname.includes('/email-subscriptions/'))) {
        const emailId = this.props.emailId
        const slug = this.props.slug
        const notificationPreferences = this.state.preferences
        let notificationContentType = null
        if (window.location.pathname.includes('/email-subscriptions/')) {
          notificationContentType = this.props.notificationType
        }

        Axios.post('/api/notification-preferences', {
          emailId, slug, notificationPreferences, notificationContentType
        })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Preferences successfully saved', response.data);
            this.setState({ serverErrorMessage: null, serverSuccessMessage: response.data.message, unsubscribed: true })

          } else {
            console.error('there was an error saving preferences');
            this.setState({ serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('there was an error saving preferences', error);
            this.setState({ serverErrorMessage: error })
        });
      } else if (this.props.orgCode) {

        if ((this.props.orgCode && !this.state.preferences) || this.state.preferences === '') {
          this.setState({ serverErrorMessage: 'Please add your notification preferences', isSaving: false })
        } else if (this.props.orgCode && !this.state.reason && this.state.preferences === 'Unsubscribe from notifications on opportunities') {
          this.setState({ serverErrorMessage: 'Please add your reason for unsubscribing', isSaving: false })
        } else if (this.props.orgCode && this.state.reason === 'Other' && !this.state.otherExplanation && this.state.preferences === 'Unsubscribe from notifications on opportunities') {
          this.setState({ serverErrorMessage: 'Please describe your reason', isSaving: false })
        } else if (this.props.orgCode && this.state.reason && this.state.reason.includes("I've gotten a") && !this.state.placedJobTitle && this.state.preferences === 'Unsubscribe from notifications on opportunities') {
          this.setState({ serverErrorMessage: 'Please add a job title', isSaving: false })
        } else if (this.props.orgCode && !this.state.formHasChanged) {
          this.setState({ serverErrorMessage: 'Please make changes to the form before saving', isSaving: false })
        } else {
          // save changes

          const emailId = this.props.emailId

          const orgName = this.state.orgName
          const orgContactEmail = this.state.orgContactEmail

          const notificationContentType = "opportunities"

          let unsubscribed = false
          if (this.props.orgCode && this.state.preferences === 'Unsubscribe from notifications on opportunities') {
            unsubscribed = true
          }

          let unsubscribedFromNotifications = false
          if (!this.props.orgCode) {
            unsubscribedFromNotifications = true
            unsubscribed = true
          }

          let unsubscribeReason = this.state.reason
          let otherExplanation = this.state.otherExplanation
          const placedJobTitle = this.state.placedJobTitle
          const placedEmployerName = this.state.placedEmployerName

          Axios.post('/api/notification-preferences', {
            emailId, notificationContentType, unsubscribed, unsubscribeReason, otherExplanation,
            orgName, orgContactEmail, placedJobTitle, placedEmployerName,
            unsubscribedFromNotifications
          })
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Preferences successfully saved', response.data);
              this.setState({ serverErrorMessage: null, serverSuccessMessage: 'Preferences successfully saved' })

              // console.log('remove from approved list?', this.state.careerTrackNotifications, this.props.orgCode, this.state.parentPostId)
              // if (this.state.careerTrackNotifications && this.state.parentPostId) {
              //   // remove user from approval all career tracks
              //   console.log('removing user from approved lists')
              //
              //   const orgCode = this.props.orgCode
              //   const parentPostId = this.state.parentPostId
              //
              //   Axios.post('/api/remove-approved-user', {
              //     emailId, orgCode, parentPostId, orgName, orgContactEmail
              //   }).then((response) => {
              //
              //     if (response.data.success) {
              //       //save values
              //       console.log('User successfully removed', response.data);
              //
              //       this.setState({ serverErrorMessage: null, serverSuccessMessage: 'Preferences successfully saved', isSaving: false  })
              //
              //     } else {
              //       console.error('User successfully removed');
              //       this.setState({ serverErrorMessage: 'There was an error removing user from approved users ' + response.data.message, isSaving: false})
              //     }
              //   }).catch((error) => {
              //       console.log('There was an error removing user from approved users ', error);
              //       this.setState({ serverErrorMessage: 'There was an error removing user from approved users ' + error, isSaving: false })
              //   });
              // } else {
              //   this.setState({ serverErrorMessage: null, serverSuccessMessage: 'Preferences successfully saved', isSaving: false  })
              // }

            } else {
              console.error('there was an error saving preferences');
              this.setState({ serverErrorMessage: 'There was an error saving preferences ' + response.data.message, isSaving: false})
            }
          }).catch((error) => {
              console.log('there was an error saving preferences', error);
              this.setState({ serverErrorMessage: 'There was an error saving preferences ' + error, isSaving: false })
          });
        }

      } else {

        const emailId = this.props.emailId
        const notificationType = this.props.notificationType

        Axios.post('/api/notification-preferences', {
          emailId, notificationType
        })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Preferences successfully saved', response.data);
            this.setState({ serverErrorMessage: null, serverSuccessMessage: response.data.message, unsubscribed: true })

          } else {
            console.error('there was an error saving preferences');
            this.setState({ serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('there was an error saving preferences', error);
            this.setState({ serverErrorMessage: error })
        });
      }
    }

    togglePreference(index,change) {
      console.log('togglePreference called', index, change)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let preferences = this.state.preferences
      preferences[index]['enabled'] = change
      // this.setState({ preferences })

      const emailId = this.props.emailId
      const postBody = { emailId, notificationPreferences: preferences }

      Axios.post('/api/notification-preferences', postBody)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Preferences successfully saved', response.data);
          this.setState({ isSaving: false, successMessage: response.data.message, preferences })

        } else {
          console.error('there was an error saving preferences');
          this.setState({ isSaving: false, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('there was an error saving preferences', error);
          this.setState({ isSaving: false, errorMessage: error })
      });

    }

    render() {

      return (
          <div>


            {(!window.location.pathname.startsWith('/organizations')) && (
              <div>
                <div className="spacer"/><div className="spacer"/>

                <div className="flex-container row-direction">
                  <div className="flex-10">
                    {(this.state.showSubcategory) && (
                      <button className="background-button left-text cta-color" onClick={() => this.setState({ showSubcategory: false })}>
                        <div className="float-left">
                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <img className="image-auto-15 rotate-180" src={arrowIndicatorIcon} alt="GC" />
                        </div>
                        <div className="float-left left-padding">
                          <p>Back</p>
                        </div>
                        <div className="clear" />
                      </button>
                    )}
                  </div>
                  <div className="flex-80">
                    {(this.props.slug || this.props.orgCode) ? (
                      <h2>Unsubscribe from {(this.state.notificationName) ? this.state.notificationName : "Notifications"}</h2>
                    ) : (
                      <h2>Manage Notifications</h2>
                    )}
                  </div>
                  <div className="flex-10">
                  </div>
                </div>
              </div>
            )}

            <div className="spacer" />

            {(this.props.orgCode) ? (
              <div>
                <p className="heading-text-6">What are your notification preferences?</p>
                <div className="spacer" />
                {this.state.preferenceOptions.map((option, index) =>
                  <div key={index}>
                    <button onClick={() => this.setState({ preferences: option, formHasChanged: true })} type="button" className="background-button full-width">
                      <div className="horizontal-padding row-20 standard-border ">
                        {(this.state.preferences === option) ? (
                          <div className="circle-option-container cta-border" >
                            <div className="circle-selected"/>
                          </div>
                        ) : (
                          <div className="circle-option-container standard-border" />
                        )}

                        <div className="float-left">
                          <p>{option}</p>
                        </div>
                        <div className="clear" />
                      </div>
                    </button>
                  </div>
                )}

                {(this.state.preferences === 'Unsubscribe from notifications on opportunities') && (
                  <div>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="heading-text-6">Why would you like to unsubscribe?</p>
                    <div className="spacer" />

                    {this.state.unsubscribeOptions.map((option, index) =>
                      <div key={index}>
                        <button onClick={() => this.setState({ reason: option, formHasChanged: true })} type="button" className="background-button full-width">
                          <div className="horizontal-padding row-20 standard-border ">
                            {(this.state.reason && option.includes(this.state.reason.replace(this.state.orgName,""))) ? (
                              <div className="circle-option-container cta-border" >
                                <div className="circle-selected"/>
                              </div>
                            ) : (
                              <div className="circle-option-container standard-border" />
                            )}

                            <div className="float-left">
                              <p>{option}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </button>
                      </div>
                    )}

                    {(this.state.reason === 'Other') && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                        <p className="heading-text-6">Last Question: Would you mind briefly describing your reason?</p>
                        <div className="spacer" />
                        <textarea type="text" className="text-field" placeholder="Add reason..." name="otherExplanation" value={this.state.otherExplanation} onChange={this.formChangeHandler} />

                      </div>
                    )}

                    {(this.state.reason && this.state.reason.includes("I've gotten a")) && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        <div>
                          <div className="container-left">
                            <p className="heading-text-6">What was/is your job title?</p>
                            <div className="spacer" />
                            <input type="text" className="text-field" placeholder="(e.g. software engineer)" name="placedJobTitle" value={this.state.placedJobTitle} onChange={this.formChangeHandler} />
                          </div>
                          <div className="container-right">
                            <p className="heading-text-6">What was/is your employer?</p>
                            <div className="spacer" />
                            <input type="text" className="text-field" placeholder="(e.g. Google, Inc.)" name="placedEmployerName" value={this.state.placedEmployerName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="spacer"/><div className="spacer"/>
                      </div>
                    )}
                  </div>
                )}

                <div className="spacer"/><div className="spacer"/>

                {(this.state.serverErrorMessage && this.state.serverErrorMessage !== '') && <p className="error-message row-10">{this.state.serverErrorMessage}</p>}
                {(this.state.serverSuccessMessage && this.state.serverSuccessMessage !== '') && <p className="success-message row-10">{this.state.serverSuccessMessage}</p>}

                <button onClick={() => this.saveChanges()} disabled={this.state.isSaving} className="btn btn-primary">Save Changes</button>
              </div>
            ) : (
              <div>
                {((this.props.slug) || (window.location.pathname.includes('/email-subscriptions/'))) ? (
                  <div>
                    {(!this.state.unsubscribed) && (
                      <div>
                        <p className="heading-text-6">Are you sure you want to unsubscribe?</p>
                        <div className="spacer" />

                        {this.state.binaryOptions.map((option, index) =>
                          <div key={index}>
                            <button onClick={() => this.setState({ confirmUnsubscribe: option })} type="button" className="background-button full-width">
                              <div className="horizontal-padding row-20 standard-border ">
                                {(this.state.confirmUnsubscribe === option) ? (
                                  <div className="circle-option-container cta-border" >
                                    <div className="circle-selected"/>
                                  </div>
                                ) : (
                                  <div className="circle-option-container standard-border" />
                                )}

                                <div className="float-left">
                                  <p>{option}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.serverErrorMessage) && <p className="heading-text-6 error-color">{this.state.serverErrorMessage}</p>}
                    {(this.state.serverSuccessMessage) && <p className="heading-text-6 cta-color">{this.state.serverSuccessMessage}</p>}

                    {(this.state.confirmUnsubscribe === 'Yes' && !this.state.unsubscribed) && (
                      <div className="row-10">
                        <button onClick={() => this.saveChanges()} disabled={this.state.isSaving} className="btn btn-primary">Save Changes</button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>

                    {(this.state.errorMessage) && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                    {(this.state.successMessage) && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                    {(this.state.showSubcategory && window.location.pathname.startsWith('/organizations')) && (
                      <button className="background-button left-text cta-color bottom-margin" onClick={() => this.setState({ showSubcategory: false })}>
                        <div className="float-left">
                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          <img className="image-auto-15 rotate-180" src={arrowIndicatorIcon} alt="GC" />
                        </div>
                        <div className="float-left left-padding">
                          <p>Back</p>
                        </div>
                        <div className="clear" />
                      </button>
                    )}

                    {(this.state.showSubcategory) ? (
                      <div>
                        {this.state.preferences.map((p, index) =>
                          <div key={p}>
                            {console.log('loading them up', p.category, this.state.categorySelected, this.props.notificationType, p.types)}
                            {(p.category === this.state.categorySelected && ((this.props.notificationType && p.types.includes(this.props.notificationType)) || (!this.props.notificationType))) && (
                              <div>
                                {console.log('ins')}
                                <button className="background-button left-text full-width" onClick={() => this.setState({ showSubcategory: true, categorySelected: p.category })}>
                                  <div className="row-10">
                                    <div className="calc-column-offset-80">
                                      <p className="heading-text-5">{p.name}</p>
                                      {(p.description) && (
                                        <p className="description-text-2 top-padding-5">{p.description}</p>
                                      )}
                                    </div>
                                    <div className="fixed-column-80">
                                      <Switch
                                        onChange={(change) => this.togglePreference(index, change)}
                                        checked={p.enabled}
                                        id="normal-switch"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>

                                </button>

                                <div className="row-10">
                                  <hr />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.state.categories.map((p, index) =>
                          <div key={p}>
                            <button className="background-button left-text full-width" onClick={() => this.setState({ showSubcategory: true, categorySelected: p.name })}>
                              <div className="row-10">
                                <div className="calc-column-offset-50">
                                  <p className="heading-text-5">{p.name}</p>
                                  <p className="description-text-2 top-padding-5">{p.description}</p>
                                </div>
                                <div className="fixed-column-50 top-padding">
                                  <img className="image-auto-20" src={arrowIndicatorIcon} alt="GC" />
                                </div>
                                <div className="clear" />
                              </div>
                            </button>

                            <div className="row-10">
                              <hr />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                )}

              </div>
            )}

          </div>
      )
    }
}


export default withRouter(NotificationPreferences);
