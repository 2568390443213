import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class AssistantWidget extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.commandClicked = this.commandClicked.bind(this)
      this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubAssistantWidget', this.props, prevProps)

      if (this.props.commands !== prevProps.commands) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubAssistantWidget', this.props)
    }

    commandClicked(command) {
      console.log('commandClicked called in assistantwidget')

      this.props.commandClicked(command)

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showDescription: false })
    }

    render() {

      return (
        <div>
          {(this.props.commands) && (
            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
              <div className="full-width height-5 senary-background" />
              <div className="padding-20">
                <div className="calc-column-offset-40">
                  <p className="heading-text-5">Ask ChatGPT</p>
                  <p className="description-text-2 top-padding-5 description-text-color">Advanced A.I. Chatbot</p>
                </div>
                <div className="fixed-column-40 top-margin-5">
                  <div className="left-margin noti-bubble-info-7-9">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showDescription: true })}>
                      <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                    </a>
                  </div>
                </div>
                <div className="clear" />

                <div className="spacer" />

                <div className="top-margin-20 left-text">
                  {this.props.commands.map((value2, index2) =>
                    <div key={"goalCommand|" + index2}>
                      <div className="calc-column-offset-70">
                        <p className="description-text-2">{value2}</p>
                      </div>
                      <div className="fixed-column-70 left-padding">
                        <button className="btn btn-small" onClick={() => this.commandClicked(value2)}>
                          Ask
                        </button>
                      </div>
                      <div className="clear" />

                      <div className="row-15">
                        <hr />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
         >
          {(this.state.showDescription) && (
            <div key="showAddToCart" className="full-width padding-40">
              <div>
                <div className="calc-column-offset-40">
                  <p className="heading-text-2">What is Compass Assistant?</p>
                </div>
                <div className="fixed-column-40 left-padding top-margin">
                  <button className="background-button full-width" onClick={() => this.closeModal()}>
                    <div className="width-30 height-30 padding-9 error-border circle-radius">
                      <img src={closeIcon} alt="GC" className="image-auto-10 pin-right" />
                    </div>
                  </button>
                </div>
                <div className="clear" />
              </div>

              <div className="spacer" /><div className="spacer" /><div className="half-spacer" />

              <div className="row-10 description-text-1">
                <p><label className="bold-text">Compass Assistant</label> is an advanced chatbot built atop ChatGPT and other technologies to provide you instant, personalized, conversational assistance. In some cases, profile information can be importent into your Compass Assistant conversation, and responses can be imported into your profile.</p>
              </div>

              <div className="row-10 description-text-1">
                <p>Want to learn more? Email help@guidedcompass.com.</p>
              </div>

              <div className="spacer" />
            </div>
          )}
        </Modal>
        </div>
      )
    }
}

export default AssistantWidget;
