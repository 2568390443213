import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubEndorsements from "./Subcomponents/Endorsements"
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

class AdvEndorsements extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (orgFocus === 'Placement' && roleName === 'Teacher') {
        roleName = 'Mentor'
      }

      if (email) {

        this.setState({ activeOrg, emailId: email, username, orgFocus,
          roleName, orgLogo
        })
      }
    }

    render() {

        return (
            <div>
                <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

                {(this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin' && !toggleVars.hideSideNav) && (
                  <SubSideNav selectedComponent={"AdvEndorsements"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
                )}

                <SubEndorsements history={this.props.navigate} activeOrg={this.state.activeOrg} roleName={this.state.roleName}/>

                <div className="clear" />

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(AdvEndorsements);
