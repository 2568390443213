import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const careerMatchesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const mentoringIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const employerIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const jobsIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobs-icon-grey.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
// const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const eventIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-blue.png';
const problemIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-blue.png';
const challengeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-blue.png';
const internIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';

class Favorites extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            favorites: [],

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderRightColumn = this.renderRightColumn.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      //   this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');

      // let placementAgency = false
      // if (activeOrg === 'bixel' || activeOrg === 'c2c' || activeOrg === 'exp' || activeOrg === 'unite-la') {
      //   placementAgency = true
      // } else if (orgFocus === 'Placement') {
      //   placementAgency = true
      // }

      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus })

      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved favorites', response.data.favorites.length)

            if (response.data.favorites.length > 0) {
              console.log('the array is greater than 0', response.data.favorites.length)

              const orgCode = activeOrg

              //query info on those favorites
              Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
              .then((response2) => {
                console.log('Favorites detail query attempted', response2.data);

                if (response2.data.success) {
                  console.log('successfully retrieved favorites detail', response2.data.favorites)

                  //query info on those favorites
                  this.setState({
                    types: response2.data.types,
                    filteredTypes: response2.data.types,
                    favorites: response2.data.favorites,
                    filteredFavorites: response2.data.favorites,
                    favoriteIds: response.data.favorites
                  })

                } else {
                  console.log('no favorites detail data found', response2.data.message)
                }

              }).catch((error) => {
                  console.log('Favorites detail query did not work', error);
              });
            }

          } else {
            console.log('no favorites data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    favoriteItem(item) {
      console.log('favoriteItem called', item, this.state.favoriteIds.length)

      let itemId = item._id

      let favoriteIds = this.state.favoriteIds
      let favorites = this.state.favorites
      let types = this.state.types

      if (favoriteIds.includes(itemId)){
        console.log('its included')
        let index = favoriteIds.indexOf(itemId)

        if (index > -1) {
          favoriteIds.splice(index, 1);
        }

        for (let i = 1; i <= favorites.length; i++) {
          if (favorites[i - 1]._id === itemId) {
            favorites.splice(i - 1, 1);
            types.splice(i - 1, 1);
          }
        }

        console.log('show favorites before save', itemId, index, favoriteIds)

        Axios.post('/api/favorites/save', {
          favoritesArray: favoriteIds, emailId: this.state.emailId
        }).then((response) => {
          console.log('attempting to remove favorites')
          if (response.data.success) {
            console.log('saved removal from favorites', response.data)
            //clear values
            this.setState({
              serverSuccessPlan: true,
              serverSuccessMessagePlan: 'Favorite saved!'
            })
          } else {
            console.log('did not save successfully')
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'error:' + response.data.message
            })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccessPlan: false,
              serverErrorMessagePlan: 'there was an error saving favorites'
            })
        });
      }

      console.log('favorites', favoriteIds, favorites, types)
      this.setState({ favoriteIds: favoriteIds, favorites, types })
    }

    renderFavorites() {
      console.log('renderFavorites called')

      let rows = [];
      if (this.state.favorites) {
        for (let i = 1; i <= this.state.favorites.length; i++) {

          const index = i - 1
          const item = this.state.favorites[i - 1]
          // console.log('show type: ', this.state.types[i - 1], item.name)

          if (this.state.types[i - 1] === 'course') {
            console.log('in course', this.state.types, this.state.favorites)

            // console.log('compare ids: ', this.state.favorites, item._id )
            if (item.source === 'Udemy') {
              rows.push(
                <div key={index}>
                  <div className="spacer" />
                  <a href={'https://www.udemy.com' + item.url} className="background-button calc-column-offset-60" target="_blank">
                    <div className="fixed-column-60">
                      <img src={item.image_125_H} alt="GC" className="image-60-auto"/>
                    </div>
                    <div className="calc-column-offset-60 left-padding-20">
                      <p className="heading-text-5">{item.title}</p>
                      <p className="description-text-1 description-text-color">{item.headline}</p>
                      <div className="half-spacer" />
                    </div>
                  </a>

                  {this.renderRightColumn(item,this.state.types[i - 1],item.url,true, undefined)}

                  <div className="clear"/>

                  <div className="spacer" />

                </div>
              )
            } else {
              rows.push(
                <div key={index}>
                  <div className="spacer" />
                  <a href={item.programURL} className="background-button calc-column-offset-60" target="_blank">
                    <div className="fixed-column-60">
                      <img src={(item.imageURL) ? item.imageURL : courseIconDark} alt="GC" className="image-60-auto"/>
                    </div>
                    <div className="calc-column-offset-60 left-padding-20">
                      <p className="heading-text-5">{item.title}</p>
                      <p className="description-text-1 description-text-color">{item.description}</p>
                      <div className="half-spacer" />
                    </div>
                  </a>

                  {this.renderRightColumn(item,this.state.types[i - 1],item.programURL,true, undefined)}

                  <div className="clear"/>

                  <div className="spacer" />

                </div>
              )
            }

          } else {
            console.log('not in course')
            if (this.props.pageSource !== 'courses') {

              let pathPrefix = '/app'
              if (window.location.pathname.includes('/advisor')) {
                pathPrefix = '/advisor'
              }
              if (this.state.types[i - 1] === 'career') {

                rows.push(
                  <div key={index} className="full-width row-20">
                    <Link to={pathPrefix + '/careers/' + item.name} className="background-link">
                      <span className="fixed-column-60">
                        <img src={(item.imageURL) ? careerMatchesIconBlue : careerMatchesIconBlue} alt="GC" className="image-auto-50 center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.name}</label>
                        </div>
                        <div>
                          <p className="description-text-1 description-text-color curtail-text">{item.jobFunction}{(item.jobFunction && item.industry) && ' | ' + item.industry}{(!item.jobFunction && item.industry) && item.industry}{(item.jobFamily) && ' | ' + item.jobFamily}</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/careers/' + item.name,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'mentor') {

                let workLine = item.firstName + ' has not entered their work information yet'

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/profile/' + item._id} state={{ selectedMentor: item }} className="background-link">
                      <span className="fixed-column-60">
                        <img src={(item.pictureURL) ? item.pictureURL : mentoringIconBlue} alt="GC" className="image-auto-50 center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.title}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">{item.firstName} {item.lastName}</p>
                          <p className="standard-color">{workLine}</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/profile/' + item._id,false, { selectedMentor: item })}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'student') {

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <a href={window.location.protocol + "//" + window.location.host + '/' + item.username + '/profile'} className="background-link" target="_blank">
                      <span className="fixed-column-60">
                        <img src={(item.pictureURL) ? item.pictureURL : mentoringIconBlue} alt="GC" className="image-auto-50 center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.title}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">{item.firstName} {item.lastName}</p>
                          <p className="standard-color">{item.school} {item.gradYear}</p>
                        </div>
                      </span>
                    </a>

                    {this.renderRightColumn(item,this.state.types[i - 1],window.location.protocol + "//" + window.location.host + '/' + item.username + '/profile',true, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'problem' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenge') {
                let imgSrc = internIconBlue
                if (item.postType === 'Event') {
                  imgSrc = eventIconBlue
                } else if (item.postType === 'Problem') {
                  imgSrc = problemIconBlue
                } else if (item.postType === 'Challenge') {
                  imgSrc = challengeIconBlue
                } else if (item.postType === 'Scholarship') {
                  imgSrc = moneyIconBlue
                }

                if (item.imageURL) {
                  imgSrc = item.imageURL
                }

                let subtitle = item.employerName + " | " + item.postType
                if (!item.employerName) {
                  subtitle = item.orgName + " | " + item.postType
                }

                let path = '/app/opportunities/'
                let passedState = { selectedOpportunity: item }
                if (window.location.pathname.includes('/advisor')) {
                  path = '/advisor/opportunities/'
                }

                rows.push(
                  <div key={index}>
                    <div className="row-20 full-width">
                      <Link to={path + item._id} state={passedState} className="background-link full-width">
                        <span className="fixed-column-60">
                          <img src={imgSrc} alt="GC" className="image-50-fit center-item"/>
                        </span>
                        <span className="calc-column-offset-120 left-padding-20">
                          <div>
                            {(item.name) ? (
                              <label className="heading-text-5">{item.name}</label>
                            ) : (
                              <label className="heading-text-5">{item.title}</label>
                            )}
                          </div>
                          <div>
                            <p className="standard-color">{subtitle}</p>
                          </div>
                        </span>
                      </Link>

                      {this.renderRightColumn(item,this.state.types[i - 1],path + item._id,false, passedState)}

                      <div className="clear" />
                    </div>

                  </div>
                )
              } else if (this.state.types[i - 1] === 'work') {

                let imgSrc = internIconBlue
                if (item.imageURL) {
                  imgSrc = item.imageURL
                }

                let subtitle = item.employerName + " | " + item.postType
                if (!item.employerName) {
                  subtitle = item.orgName + " | " + item.postType
                }

                let path = '/app/opportunities/'
                let passedState = { selectedOpportunity: item }
                if (window.location.pathname.includes('/advisor')) {
                  path = '/advisor/opportunities/'
                }

                if (item.isExternal) {
                  rows.push(
                    <div key={index} className="row-20 full-width">
                      <a href={item.jobLink} className="background-link">
                        <span className="fixed-column-60">
                          <img src={(item.imageURL) ? item.imageURL : jobsIconGrey} alt="GC" className="image-50-fit"/>
                        </span>
                        <span className="calc-column-offset-120 left-padding-20">
                          <div>
                            <label>{item.title}</label>
                          </div>
                          <div>
                            <p className="standard-color">{item.employerName}</p>
                          </div>
                        </span>
                      </a>

                      {this.renderRightColumn(item,this.state.types[i - 1],item.jobLink,true, undefined)}

                    </div>
                  )
                } else {
                  rows.push(
                    <div key={index}>
                      <div className="row-20 full-width">
                        <Link to={path + item._id} state={passedState} className="background-link full-width">
                          <span className="fixed-column-60">
                            <img src={imgSrc} alt="GC" className="image-50-fit center-item"/>
                          </span>
                          <span className="calc-column-offset-120 left-padding-20">
                            <div>
                              {(item.name) ? (
                                <label className="heading-text-5">{item.name}</label>
                              ) : (
                                <label className="heading-text-5">{item.title}</label>
                              )}
                            </div>
                            <div>
                              <p className="standard-color">{subtitle}</p>
                            </div>
                          </span>
                        </Link>
                        {this.renderRightColumn(item,this.state.types[i - 1],path + item._id,false, passedState)}

                        <div className="clear" />
                      </div>
                    </div>
                  )
                }
              } else if (this.state.types[i - 1] === 'project') {

                // let pathPrefix = '/app'
                // if (window.location.pathname.includes('/advisor')) {
                //   pathPrefix = '/advisor'
                // }

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/projects/' + item._id} className="background-link full-width">
                      <span className="fixed-column-60">
                        <img src={(item.imageURL) ? item.imageURL : projectsIconBlue} alt="GC" className="image-50-fit center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.name}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">{item.userFirstName} {item.userLastName}</p>
                          <p className="standard-color">{item.hours} Hours</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/projects/' + item._id,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'goal') {
                console.log('in the goals...')
                // let pathPrefix = '/app'
                // if (window.location.pathname.includes('/advisor')) {
                //   pathPrefix = '/advisor'
                // }

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/goals/' + item._id} className="background-link full-width">
                      <span className="fixed-column-60">
                        <img src={(item.imageURL) ? item.imageURL : targetIconOrange} alt="GC" className="image-50-fit center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.name}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">{item.title}</p>
                          <p className="standard-color">{(item.goalType) && item.goalType.description}</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/goals/' + item._id,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'employer') {

                // let pathPrefix = '/app'
                // if (window.location.pathname.includes('/advisor')) {
                //   pathPrefix = '/advisor'
                // }

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/employers/' + item._id} className="background-link full-width">
                      <span className="fixed-column-60">
                        <img src={(item.employerLogoURI) ? item.employerLogoURI : employerIconBlue} alt="GC" className="image-50-fit center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.employerName}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">{item.employerIndustry}</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/employers/' + item._id,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'curriculumItem') {

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/exchange/' + item._id} className="background-link full-width">
                      <span className="fixed-column-60">
                        <img src={courseIconBlue} alt="GC" className="image-50-fit center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.itemName}</label>
                        </div>
                        <div>
                          <p className="heading-text-6">by {item.firstName} {item.lastName}</p>
                        </div>
                        <div>
                          <p className="description-text-2">Curriculum Type: {item.exchangeType}</p>
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/exchange/' + item._id,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              } else if (this.state.types[i - 1] === 'pathway') {

                rows.push(
                  <div key={index} className="row-20 full-width">
                    <Link to={pathPrefix + '/pathways/' + item._id} className="background-link full-width">
                      <span className="fixed-column-60">
                        <img src={pathsIconBlue} alt="GC" className="image-50-fit center-item"/>
                      </span>
                      <span className="calc-column-offset-120 left-padding-20">
                        <div>
                          <label className="heading-text-5">{item.title}</label>
                        </div>
                        <div>
                          <label className="heading-text-6">{item.jobFunction}</label>
                          {(item.industry) && (
                            <label className="heading-text-6"> | {item.industry}</label>
                          )}
                        </div>
                      </span>
                    </Link>

                    {this.renderRightColumn(item,this.state.types[i - 1],pathPrefix + '/exchange/' + item._id,false, undefined)}

                    <div className="clear" />
                  </div>
                )
              }
            }
          }
        }
      }

      return rows

    }

    renderRightColumn(item,type,url, isExternal, passedState) {
      console.log('renderRightColumn called')

      return (
        <span key={"renderRightColumn"}>
          {(isExternal) ? (
            <span>
              <span className="fixed-column-30 top-margin" >
                <button disabled={this.state.isSaving} className="btn background-button float-right" onClick={() => this.favoriteItem(item,type) }>
                  <img src={(this.state.favoriteIds.includes(item._id)) ? favoritesIconBlue : favoritesIconGrey} alt="GC" className="image-auto-20"/>
                </button>
              </span>
              <div className="fixed-column-30 top-margin" >
                <a href={url} className="background-button full-width" target="_blank">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                </a>
              </div>
            </span>
          ) : (
            <div className="float-left">
              <span className="fixed-column-30 top-margin">
                <button disabled={this.state.isSaving} className="btn background-button float-right" onClick={() => this.favoriteItem(item) }>
                  <img src={favoritesIconBlue} alt="GC" className="image-auto-20"/>
                </button>
              </span>
              <span className="fixed-column-30 top-margin">
                <Link to={url} state={passedState} className="background-link">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                </Link>
              </span>
            </div>
          )}
        </span>
      )
    }

    render() {

      let topPadding = "top-padding-40"
      let wrapper = ""
      let subtitle = "Favorited career paths, courses, and work based learning opportunities."
      if (this.props.pageSource === 'courses') {
        topPadding = ""
        wrapper = "padding-20"
        subtitle = "Favorited courses"
      }

      return (
          <div className={wrapper}>
            <div>
              <div className={topPadding}>
                <p className="heading-text-2">Favorites</p>
              </div>

              <p className="description-text-color row-10">{subtitle}</p>
              <div className="spacer" />

              {this.renderFavorites()}

              <div className="clear"/>
            </div>
          </div>

      )
    }
}

export default withRouter(Favorites);
