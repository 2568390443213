import Axios from 'axios';
import {convertDateToString} from './ConvertDateToString';
import {calculateAge} from './CalculateAge';

export const subExportStandardCSV = async(passedData)=>{
  console.log('subExportStandardCSV called', passedData.csvData)

  const reportOptions = passedData.reportOptions
  const reportName = passedData.reportName
  const passedValue = passedData.passedValue
  const passedName = passedData.passedName
  let fields = passedData.fields
  const reportType = passedData.reportType
  const orgCode = passedData.orgCode
  const placementPartners = passedData.placementPartners
  const surveyName = passedData.surveyData
  const lessonName = passedData.lessonName
  const selectedEvent = passedData.selectedEvent
  const selectedProject = passedData.selectedProject
  const selectedWork = passedData.selectedWork
  const approved = passedData.approved
  const logType = passedData.logType
  const assignedStaffName = passedData.assignedStaffName
  const assignedStaffEmail = passedData.assignedStaffEmail
  const olderThan = passedData.olderThan
  const youngerThan = passedData.youngerThan
  const department = passedData.department
  const pathway = passedData.pathway
  const accountCode = passedData.accountCode
  const afterDate = passedData.afterDate
  const beforeDate = passedData.beforeDate
  const reportFilters = passedData.reportFilters
  const sortCriteria = passedData.sortCriteria
  const resLimit = passedData.resLimit
  const csvData = passedData.csvData
  const componentState = passedData.componentState

  let errorMessage = 'There is no data to export'

  let showNestedHeaders = false
  if (reportName === 'Participants Report') {
    showNestedHeaders = true
  }

  let indexOffset = 1

  let selectedReport = null
  let headerArray = null
  for (let i = 1; i <= reportOptions.length; i++) {
    if (reportOptions[i - 1].name === reportName) {
      selectedReport = reportOptions[i - 1]
      if (selectedReport && selectedReport.fields) {
        fields = selectedReport.fields
        if (passedName === 'fields') {
          fields = passedValue
        }

        headerArray = []

        if (showNestedHeaders && fields && fields[0] && fields[0][0]) {
          for (let j = 1; j <= fields.length; j++) {
            if (fields[j - 1]) {
              let subheaderArray = []
              for (let l = 1; l <= fields[j - 1].length; l++) {
                subheaderArray.push(fields[j - 1][l - 1].name)
              }
              headerArray.push(subheaderArray)
              indexOffset = j - 1
            }
          }

          // update fields
          fields = selectedReport.fields[selectedReport.fields.length - 1]

        } else {
          for (let j = 1; j <= fields.length; j++) {
            headerArray.push(fields[j - 1].name)
          }
        }
      }
    }
  }

  const payload = {
    reportType, orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject,
    selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan,
    department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria, resLimit,
    csvData
  }

  function formatString(passedString) {
    // console.log('formatString called', passedString)

    // let commaStartingValue = /,/g
    // let commaReplacementValue = ","

    const returnedString = passedString.trim().replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")

    return returnedString

  }

  console.log('about to export csv report')
  return await Axios.put('/api/reporting/general', payload)
  .then(async (response) => {
      if (response.data.success) {
        console.log('Reporting query worked in showModule', response.data);

        if (showNestedHeaders) {
          for (let i = 1; i <= headerArray.length; i++) {
            csvData.push(headerArray[i - 1])
          }
        } else {
          csvData.push(headerArray)
        }

        let tempData = csvData[0]
        // tempData = tempData.concat(["App Requirements","Application Method","Job Link","Subject Line","Pathways","Departments"])
        // csvData[0] = tempData
        // console.log('list the csv data: ', csvData)
        const pullIndividuals = true
        let tempDataArray = response.data[reportType]
        if (reportType === 'placements' && pullIndividuals) {
          let individuals = []

          for (let i = 1; i <= tempDataArray.length; i++) {
            if (tempDataArray[i - 1] && tempDataArray[i - 1].workers) {
              for (let j = 1; j <= tempDataArray[i - 1].workers.length; j++) {

                let keys = Object.keys(tempDataArray[i - 1])
                let values = Object.values(tempDataArray[i - 1])
                let objectPairs = []

                if (keys && keys.length > 0) {

                  for (let l = 1; l <= keys.length; l++) {
                    if (keys[l - 1] && keys[i - 1] !== 'workers') {
                      objectPairs.push({ key: keys[l - 1], value: values[l - 1]})
                    }
                  }
                }

                let workerKeys = Object.keys(tempDataArray[i - 1].workers[j - 1])
                let workerValues = Object.values(tempDataArray[i - 1].workers[j - 1])
                if (workerKeys && workerKeys.length > 0) {
                  for (let l = 1; l <= workerKeys.length; l++) {
                    if (workerKeys[l - 1]) {
                      objectPairs.push({ key: workerKeys[l - 1], value: workerValues[l - 1]})
                    }
                  }
                }

                let worker = {}
                for (let l = 1; l <= objectPairs.length; l++) {
                  worker[objectPairs[l - 1].key] = objectPairs[l - 1].value
                }

                individuals.push(worker)
              }
            }
          }
          // console.log('length: ', individualPlacements.length, individualPlacements[0])
          tempDataArray = individuals
        }

        let reportDataArray = tempDataArray
        // if (reportType === 'postings') {
        //   reportDataArray = response.data.postings
        // }
          // console.log('what is the deal', reportDataArray)

        let surveyIndex = null
        let reachedLimit = false

        if (reportDataArray && reportDataArray.length > 0) {
          // console.log('compare the two: ', reportDataArray.length, resLimit, typeof reportDataArray.length, typeof resLimit, reportDataArray.length === resLimit)
          if (reportDataArray.length === resLimit) {
            reachedLimit = true
            // console.log('i dont get it')
          }

          for (let i = 1; i <= reportDataArray.length; i++) {
            // console.log(i, 'data element')

            let rIndex = i - indexOffset
            console.log("?#?#?#", reportDataArray[rIndex])
            let commaStartingValue = /,/g
            let commaReplacementValue = ","

            let dataArray = []
            for (let j = 1; j <= fields.length; j++) {
              let dataField = ' - '
              // console.log('RSVP #', i, reportDataArray[rIndex][fieldShortname])
              let fieldType = fields[j - 1].type
              let fieldShortname = fields[j - 1].shortname

              if (fields[j - 1].altCondition) {
                if (fields[j - 1].altCondition.split("|")[1] === 'ne' && fields[j - 1][fields[j - 1].altCondition.split("|")[0]] === fields[j - 1].altCondition.split("|")[2]) {
                  fieldType = fields[j - 1].altType
                  fieldShortname = fields[j - 1].altShortname
                }
              }


              // console.log('how did we fare: ', fieldType, dataField, reportDataArray[rIndex], fieldShortname)
              // console.log("csvData0: ", csvData[0])

              if (fieldType === 'String') {
                dataField = reportDataArray[rIndex][fieldShortname]

                if (dataField) {

                  // dataField = dataField.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                  dataField = formatString(dataField)
                }
                // console.log('wtf: ', dataField)
              } else if (fieldType === 'Number') {
                dataField = reportDataArray[rIndex][fieldShortname]
              } else if (fieldType === 'Boolean') {
                if (reportDataArray[rIndex][fieldShortname]) {
                  dataField = 'YES'
                } else {
                  dataField = 'NO'
                }
              } else if (fieldType === 'Age') {
                if (reportDataArray[rIndex][fieldShortname]) {
                  dataField = calculateAge(reportDataArray[rIndex][fieldShortname])
                }
              } else if (fieldType === 'Splitter') {
                dataField = reportDataArray[rIndex][fieldShortname]
                if (dataField) {
                  const index = Number(dataField.split("|")[1])
                  // dataField = dataField[index].trim().replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                  dataField = formatString(dataField[index])
                }
              } else if (fieldType === 'Count') {
                if (reportDataArray[rIndex][fieldShortname]) {
                  dataField = reportDataArray[rIndex][fieldShortname].length
                }
              } else if (fieldType === 'Date') {
                if (reportDataArray[rIndex][fieldShortname]) {
                  dataField = convertDateToString(new Date(reportDataArray[rIndex][fieldShortname]),"datetime-2")
                }
              } else if (fieldType === 'Stringify') {
                if (reportDataArray[rIndex][fieldShortname]) {

                  dataField = formatString(JSON.stringify(reportDataArray[rIndex][fieldShortname]))
                }
              } else if (fieldType === 'Simple Join') {
                if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].length > 0) {
                  for (let l = 1; l <= reportDataArray[rIndex][fieldShortname].length; l++) {
                    const item = reportDataArray[rIndex][fieldShortname][l - 1]
                    if (item) {
                      if (dataField === ' - ') {
                        // dataField = item.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = formatString(item)
                      } else {
                        // dataField = dataField + commaReplacementValue + ' ' + item.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = dataField + ' - ' + formatString(item)
                      }
                    }
                  }
                }
              } else if (fieldType === 'Object Join') {
                if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].length > 0) {
                  for (let l = 1; l <= reportDataArray[rIndex][fieldShortname].length; l++) {
                    const item = reportDataArray[rIndex][fieldShortname][l - 1]
                    if (item) {
                      if (dataField === ' - ') {
                        // dataField = JSON.stringify(item).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = formatString(JSON.stringify(item))
                      } else {
                        // dataField = dataField + commaReplacementValue + ' ' + JSON.stringify(item).replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = dataField + ' - ' + formatString(JSON.stringify(item))
                      }
                    }
                  }
                }
              } else if (fieldType === 'Splitter Join') {
                const aShortname = fieldShortname.split("|")[0]
                const bShortname = fieldShortname.split("|")[1]
                const cShortname = fieldShortname.split("|")[2]

                if (reportDataArray[rIndex][aShortname] && reportDataArray[rIndex][aShortname].length > 0) {
                  for (let l = 1; l <= reportDataArray[rIndex][aShortname].length; l++) {
                    const item = reportDataArray[rIndex][aShortname][l - 1]
                    if (item && item[bShortname]) {
                      let dataItem = item[bShortname].toString()
                      if (cShortname) {
                        dataItem = item[bShortname] + "-" + item[cShortname]
                      }
                      if (dataField === ' - ') {
                        // dataField = dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = formatString(dataItem)
                      } else {
                        // dataField = dataField + commaReplacementValue + ' ' + dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                        dataField = dataField + ' - ' + formatString(dataItem)
                      }
                    }
                  }
                }
              } else if (fieldType === 'Object') {

                const aShortname = fieldShortname.split("|")[0]
                const bShortname = fieldShortname.split("|")[1]

                if (reportDataArray[rIndex][aShortname]) {
                  if (reportDataArray[rIndex][aShortname] && reportDataArray[rIndex][aShortname][bShortname]) {
                    if (typeof reportDataArray[rIndex][aShortname][bShortname] === 'object') {
                      let keys = Object.keys(reportDataArray[rIndex][aShortname][bShortname])
                      let values = Object.values(reportDataArray[rIndex][aShortname][bShortname])

                      if (keys && keys.length > 0) {
                        for (let l = 1; l <= keys.length; l++) {
                          if (keys[l - 1]) {
                            let dataItem = keys[l - 1] + "-" + values[l - 1]

                            if (dataField === ' - ') {
                              // dataField = dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                              dataField = formatString(dataItem)
                            } else {
                              // dataField = dataField + commaReplacementValue + ' ' + dataItem.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"'")
                              dataField = dataField + ' - ' + formatString(dataItem)
                            }
                          }
                        }
                      }
                    } else {
                      // add to data
                      dataField = reportDataArray[rIndex][aShortname][bShortname]

                    }
                  } else {
                    // add to data
                  }
                }
              } else if (fieldType === 'Survey') {

                if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].length > 0) {

                  for (let l = 1; l <= reportDataArray[rIndex][fieldShortname].length; l++) {

                    if (reportDataArray[rIndex][fieldShortname][l - 1]) {

                      const questionName = 'Q-' + formatString(reportDataArray[rIndex][fieldShortname][l - 1].question)

                      if (!csvData[0].includes(questionName)) {
                        if (l === 1) {

                          for (let m = 1; m <= csvData[0].length; m++) {
                            if (csvData[0][m - 1] === fields[j - 1].name) {
                              csvData[0].splice(m - 1,1)
                            }
                          }

                          csvData[0].push(questionName)
                        } else {
                          // csvData[0].splice(surveyIndex + 1,0,questionName)
                          // surveyIndex + 1
                          csvData[0].push(questionName)
                        }
                      }

                      if (!reportDataArray[rIndex][fieldShortname][l - 1].answer) {
                        // console.log('Z0', csvData, csvData.length )
                        const result = " - "
                        if (l === reportDataArray[rIndex][fieldShortname].length) {
                          dataField = result
                        } else {
                          dataArray.push(result)
                        }
                      } else if (typeof reportDataArray[rIndex][fieldShortname][l - 1].answer === 'string') {
                        // console.log('Z1', csvData, csvData.length )

                        const result = formatString(reportDataArray[rIndex][fieldShortname][l - 1].answer)

                        if (l === reportDataArray[rIndex][fieldShortname].length) {
                          dataField = result
                        } else {
                          dataArray.push(result)
                        }

                      } else {
                        // console.log('Z2', reportDataArray[rIndex][fieldShortname][l - 1])

                        const result = formatString(reportDataArray[rIndex][fieldShortname][l - 1].answer.join())

                        if (l === reportDataArray[rIndex][fieldShortname].length) {
                          dataField = result
                        } else {
                          dataArray.push(result)
                        }

                        if (result === undefined || result === null) {
                          // console.log('result is undefined or null 1')
                        }
                      }
                    }
                    // console.log('eee')
                    if (reportDataArray[rIndex][fieldShortname][l - 1] && (reportDataArray[rIndex][fieldShortname][l - 1].questionType === 'Checkbox' || reportDataArray[rIndex][fieldShortname][l - 1].questionType === 'Multiple Answer')) {
                      // console.log('fff', reportDataArray[rIndex].email)
                      if (reportDataArray[rIndex][fieldShortname][l - 1].question === 'Background Information [Please mark all that apply]') {
                        const acs = reportDataArray[rIndex][fieldShortname][l - 1].answerChoices
                        if (acs && acs.length > 0) {
                          for (let k = 1; k <= acs.length; k++) {
                            if (acs[k - 1]) {
                              if (!csvData[0].includes(acs[k - 1])) {
                                csvData[0].push(acs[k - 1])
                              }
                              if (reportDataArray[rIndex][fieldShortname][l - 1].answer && reportDataArray[rIndex][fieldShortname][l - 1].answer.length > 0 && reportDataArray[rIndex][fieldShortname][l - 1].answer.includes(acs[k - 1])) {
                                // csvData[j - 1].push('TRUE')
                                // if (l === componentState[optionShortname].length) {
                                //   dataField = "FALSE"
                                // } else {
                                //   dataArray.push("TRUE")
                                // }
                                dataArray.push("TRUE")
                              } else {
                                // csvData[j - 1].push('FALSE')
                                dataArray.push("FALSE")
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  // delete the header at this index

                  if (i === 1 && fields.some(field => field.type === 'Multiple Answers Transposed')) {
                    const tIndex = fields.findIndex(field => field.type === 'Multiple Answers Transposed')
                    const tempShortname = fields[tIndex].shortname
                    const optionShortname = tempShortname + "Options"
                    if (componentState[optionShortname] && componentState[optionShortname].length > 0) {
                      const headerName = csvData[0][j - 1]
                      csvData[0].splice(j - 1,1)
                      csvData[0].push(headerName)
                    }
                  }
                }
              } else if (fieldType === 'MappedItems') {
                // e.g., participationArray
                if (showNestedHeaders) {
                  if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].length > 0) {

                    for (let l = 1; l <= reportDataArray[rIndex][fieldShortname].length; l++) {
                      if (reportDataArray[rIndex][fieldShortname][l - 1]) {
                        if (rIndex === 0) {
                          const questionName = formatString(reportDataArray[rIndex][fieldShortname][l - 1].question)
                          if (l === 1) {
                            csvData[0][csvData[0].length - 1] = questionName
                          } else {
                            csvData[0].push(questionName)
                          }

                          // loop by the number of subcategories. Add blank spaces.
                          // console.log('is this right?', reportDataArray[rIndex][fieldShortname][l - 1])
                          if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories) {
                            for (let m = 1; m <= reportDataArray[rIndex][fieldShortname][l - 1].subcategories.length; m++) {
                              if (m > 1) {
                                csvData[0].push("")
                              }
                            }

                            // map subcategories in the next row
                            if (l === 1) {
                              if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories && reportDataArray[rIndex][fieldShortname][l - 1].subcategories.length > 0) {
                                for (let m = 1; m <= reportDataArray[rIndex][fieldShortname][l - 1].subcategories.length; m++) {
                                  if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories[m - 1]) {
                                    if (m === 1) {
                                      csvData[1][csvData[1].length - 1] = reportDataArray[rIndex][fieldShortname][l - 1].subcategories[m - 1]
                                    } else {
                                      csvData[1].push(reportDataArray[rIndex][fieldShortname][l - 1].subcategories[m - 1])
                                    }
                                  }
                                }
                              }
                              // if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories[0]) {
                              //   csvData[1][csvData[1].length - 1] = reportDataArray[rIndex][fieldShortname][l - 1].subcategories[0]
                              // }
                              // if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories[1]) {
                              //   csvData[1].push(reportDataArray[rIndex][fieldShortname][l - 1].subcategories[1])
                              // }
                              // if (reportDataArray[rIndex][fieldShortname][l - 1].subcategories[2]) {
                              //   csvData[1].push(reportDataArray[rIndex][fieldShortname][l - 1].subcategories[2])
                              // }
                            } else {
                              for (let m = 1; m <= reportDataArray[rIndex][fieldShortname][l - 1].subcategories.length; m++) {
                                csvData[1].push(reportDataArray[rIndex][fieldShortname][l - 1].subcategories[m - 1])
                              }
                            }
                          }
                        }

                        const answerName = formatString(reportDataArray[rIndex][fieldShortname][l - 1].answer)

                        let answers = []
                        if (reportDataArray[rIndex][fieldShortname][l - 1].answers) {
                          for (let k = 1; k <= reportDataArray[rIndex][fieldShortname][l - 1].answers.length; k++) {
                            dataArray.push(reportDataArray[rIndex][fieldShortname][l - 1].answers[k - 1])
                            // csvData.push(dataArray)
                          }
                        }

                        console.log('wtf is happening: ', fieldShortname, reportDataArray[rIndex][fieldShortname], reportDataArray[rIndex][fieldShortname][l - 1].subcategories, reportDataArray[rIndex][fieldShortname][l - 1].answers, reportDataArray[rIndex].email)
                        // console.log('wtf is happening: ', fieldShortname, reportDataArray[rIndex][fieldShortname], reportDataArray[rIndex].email)
                      }
                    }
                  } else {

                    // console.log('is this a real thing?')
                  }
                } else {
                  if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].length > 0) {

                    for (let l = 1; l <= reportDataArray[rIndex][fieldShortname].length; l++) {
                      if (reportDataArray[rIndex][fieldShortname][l - 1]) {
                        if (i === 1) {
                          const questionName = formatString(reportDataArray[rIndex][fieldShortname][l - 1].question)
                          if (l === 1) {
                            csvData[0][csvData[0].length - 1] = questionName
                          } else {
                            csvData[0].push(questionName)
                          }
                        }

                        const answerName = formatString(reportDataArray[rIndex][fieldShortname][l - 1].answer)
                        dataArray.push(answerName)
                      }
                    }
                  } else {
                    // delete the header at this index

                    if (i === 1 && fields.some(field => field.type === 'Multiple Answers Transposed')) {
                      const tIndex = fields.findIndex(field => field.type === 'Multiple Answers Transposed')
                      const tempShortname = fields[tIndex].shortname
                      const optionShortname = tempShortname + "Options"
                      if (componentState[optionShortname] && componentState[optionShortname].length > 0) {
                        const headerName = csvData[0][j - 1]
                        csvData[0].splice(j - 1,1)
                        csvData[0].push(headerName)
                      }
                    }

                    // headerIndicesToDelete.push(j - 1)


                  }
                }

              } else if (fieldType === 'Multiple Answers Transposed') {
                // console.log("J: ", i, )
                const optionShortname = fieldShortname + "Options"

                if (i === 1) {
                  if (componentState[optionShortname] && componentState[optionShortname].length > 0) {
                    for (let l = 1; l <= csvData[0].length; l++) {
                      if (csvData[0][l - 1] === fields[j - 1].name) {
                        csvData[0][l - 1] = csvData[0][l - 1].replace(fields[j - 1].name,componentState[optionShortname][0])
                      }
                    }
                    for (let l = 1; l <= componentState[optionShortname].length; l++) {
                      if (l > 1) {
                        // console.log("csvData1: ", csvData[0], componentState[optionShortname][l - 1])
                        csvData[0].push(componentState[optionShortname][l - 1])
                      }
                    }
                  }
                }

                if (componentState[optionShortname] && componentState[optionShortname].length > 0) {
                  for (let l = 1; l <= componentState[optionShortname].length; l++) {
                    if (reportDataArray[rIndex][fieldShortname] && reportDataArray[rIndex][fieldShortname].includes(componentState[optionShortname][l - 1])) {
                      if (l === componentState[optionShortname].length) {
                        dataField = "TRUE"
                      } else {
                        dataArray.push("TRUE")
                      }
                    } else {
                      if (l === componentState[optionShortname].length) {
                        dataField = "FALSE"
                      } else {
                        dataArray.push("FALSE")
                      }
                    }
                  }
                }
              }

              dataArray.push(dataField)

            }

            csvData.push(dataArray)

          }
          // console.log('S2: about to return data', reachedLimit)

          return { success: true, csvData, reachedLimit }

        } else {

          return { success: false, stateObject: { errorMessage, isLoading: false }}
        }

      } else {
        console.log('no report data found', response.data.message)

        // if (previewMode) {
        //   this.configurePreview(csvData)
        // }

        return { success: false, stateObject: { errorMessage, isLoading: false }}
      }
  }).catch((error) => {
      console.log('Report query did not work', error);


      if (error && error.message) {
        errorMessage = error.message.toString()
      }

      return { success: false, stateObject: { errorMessage, isLoading: false }}
  });
}
