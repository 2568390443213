import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubAdminApplications from '../Subcomponents/AdminApplications';
import withRouter from '../Functions/WithRouter';

class OrgApplications extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let selectedJob = null
      let applications = null
      let benchmark = null
      let benchmarkOptions = null
      if (this.props.location.state) {
        selectedJob = this.props.location.state.selectedJob;
        applications = this.props.location.state.applications;
        benchmark = this.props.location.state.benchmark;
        benchmarkOptions = this.props.location.state.benchmarkOptions;
      }

      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');
      let orgFocus = localStorage.getItem('orgFocus');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName, org, orgLogo,
          orgFocus, benchmark, benchmarkOptions,
          selectedJob, applications, filteredApplications: applications
        })

      }
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <SubAdminApplications accountCode={this.state.emp} org={this.state.org} selectedJob={this.state.selectedJob} applications={this.state.applications} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgApplications)
