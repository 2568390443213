import React from 'react';
import {EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
// import '!style-loader!css-loader!draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js/dist/Draft.css';
// import '../../../../node_modules/draft-js/dist/Draft.css';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import withRouter from '../Functions/WithRouter';

class MyEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      placeholder: 'Start writing!'
    };
    // this.onChange = (editorState) => this.setState({editorState});
    this.setEditor = (editor) => {
      this.editor = editor;
    };
    this.focusEditor = () => {
      if (this.editor) {
        this.editor.focus();
      }
    };

    this.retrieveData = this.retrieveData.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleKeyCommand = this.handleKeyCommand.bind(this);

  }

  componentDidMount() {
    // this.focusEditor();
    this.retrieveData()

  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate called within myEditor ')

    if (this.props.existingContent !== prevProps.existingContent) {
      this.retrieveData()
    }
  }

  retrieveData() {
    console.log('retrieveData in myEditor', this.props)

    if (this.props.existingContent) {
      const editorState = EditorState.createWithContent(convertFromRaw(this.props.existingContent))
      console.log('show editorState: ', editorState)
      this.setState({ editorState })
    } else {
      console.log('there is no content')
      const editorState = EditorState.createEmpty()
      this.setState({ editorState })
    }
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  onChange = (editorState) => {
    console.log('onChange called: ', editorState)

    this.setState({ editorState })
    this.props.indicateFormChange(true)
  }

  convertEditorState() {
    console.log('convertEditorState called in myEditor')

    const rawJSON = convertToRaw(this.state.editorState.getCurrentContent())
    return rawJSON
  }

  onUnderlineClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'));
  }

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
  }

  onItalicClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
  }

  convertFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw(text))
    // return '<div>test</div>'
  }

  render() {

    // draft.js - rich text
    if (this.props.showText) {
      return (
        <div >
          <div dangerouslySetInnerHTML={{ __html: this.convertFromJSONToHTML(this.props.existingContent)}} />
        </div>
      );
    } else {
      return (
        <div>
          <div className="standard-border padding-20" onClick={this.focusEditor}>
            {/*
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onChange}
              placeholder={this.state.placeholder}
            />*/}
          </div>
          {/* Use this to render saved JSON in read-only viewer form
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.convertFromJSONToHTML(this.props.existingContent)}} />
          </div>
          */}
        </div>
      );
    }
  }
}

export default withRouter(MyEditor);

/*
<button onClick={this.onUnderlineClick}>U</button>
<button onClick={this.onBoldClick}><b>B</b></button>
<button onClick={this.onItalicClick}><em>I</em></button>
<Editor
  ref={this.setEditor}
  handleKeyCommand={this.handleKeyCommand}
  editorState={this.state.editorState}
  onChange={this.onChange}
  placeholder={this.state.placeholder}
/>*/
