import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

const mentoringIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-dark.png";
const rateIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/rate-icon-dark.png";

class Bookings extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.submitRating = this.submitRating.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.openReviewModal = this.openReviewModal.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subcompletions')

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let pictureURL = localStorage.getItem('pictureURL');

      this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus, pictureURL });

      Axios.get('/api/bookings', { params: { email, orgCode: activeOrg } })
      .then((response) => {
          if (response.data.success) {
            console.log('Bookings received query worked', response.data);

            const bookings = response.data.bookings
            this.setState({ bookings })

          } else {
            console.log('no booking data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Booking query did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      this.setState({ [event.target.name] : event.target.value })

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showRating: false, selectedItem: null, selectedIndex: null })
    }

    submitRating() {
      console.log('submitRating called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.rating || this.state.rating === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a rating' })
      } else if (!this.state.review || this.state.review === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a review' })
      } else {

        const email = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const pictureURL = this.state.pictureURL
        const username = this.state.username

        const bookingId = this.state.selectedItem._id
        const productId = this.state.selectedItem.productId
        const productName = this.state.selectedItem.name
        const productPrice = this.state.selectedItem.price
        const rating = this.state.rating
        const review = this.state.review
        const orgCode = this.state.activeOrg
        const createdAt = new Date()
        const updatedAt = new Date()

        const postObject = { email, firstName, lastName, pictureURL, username,
          bookingId, productId, productName, productPrice, rating, review, orgCode, createdAt, updatedAt
        }

        Axios.post('/api/products/review', postObject)
        .then((response) => {
          console.log('product review post attempted')
          if (response.data.success) {
            //save values
            console.log('Product review post successful');

            this.setState({ isSaving: false, successMessage: 'You have successfully reviewed the product!', done: true })
            // this.closeModal()

          } else {
            console.log('Product review post did not work', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Product review post did not work for some reason', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });

      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      const shareURL = window.location.protocol + "//" + window.location.host + "/marketplace/" + this.state.selectedItem.slug
      const shareTitle = 'Check Out ' + this.state.selectedItem.name + ' On Guided Compass!'
      const shareBody = this.state.selectedItem.name  + " was very helpful! Definitely give it a try."

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={shareURL} subject={shareTitle} body={shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={shareURL} title={shareTitle} summary={(shareBody) ? shareBody : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={shareURL} description={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    openReviewModal(value, index) {
      console.log('openReviewModal called', value, index)

      const queryObject = { email: this.state.emailId, productId: value.productId }
      Axios.get('/api/reviews', { params: queryObject })
      .then((response) => {
          if (response.data.success && response.data.reviews && response.data.reviews.length > 0) {
            console.log('Reviews received query worked', response.data);

            const rating = response.data.reviews[0].rating
            const review = response.data.reviews[0].review

            this.setState({ modalIsOpen: true, showRating: true, selectedItem: value, selectedIndex: index, rating, review })

          } else {
            console.log('no reviews data found', response.data.message)
            this.setState({ modalIsOpen: true, showRating: true, selectedItem: value, selectedIndex: index, rating: null, review: null })
          }

      }).catch((error) => {
          console.log('Reviews query did not work', error);
      });
    }

    render() {

        return (
            <div>
              <div className="row-20">
                <p className="heading-text-2">Bookings</p>
                <p className="top-padding-5 description-text-color description-text-1">Questions? Email help@guidedcompass.com.</p>
              </div>

              <div className="spacer" />

              {(this.state.bookings && this.state.bookings.length > 0) ? (
                <div>
                  {(this.state.errorMessage && this.state.errorMesssage !== '') && <p className="error-color row-5 description-text-2">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMesssage !== '') && <p className="cta-color row-5 description-text-2">{this.state.successMessage}</p>}

                  {this.state.bookings.map((value, index) =>
                    <div key={value.name} className="bottom-margin-15">
                      <div className="fixed-column-70">
                        <img src={(value.imageURL) ? value.imageURL : mentoringIconDark} alt="GC" className="image-50-fit" />
                      </div>
                      <div className="calc-column-offset-140">
                        <p className="heading-text-5">{value.name}</p>
                        <p className="description-text-1 top-padding-5">{value.category}{(value.bookingDate) && " on " + convertDateToString(new Date(value.bookingDate),"datetime-2") } hosted by <a href={value.advisorProfileLink} target="_blank" rel="noopener noreferrer">{value.advisorFirstName} {value.advisorLastName}</a></p>
                        {(value.recipientType === 'Someone Else') && (
                          <p className="description-text-1 top-padding-5">For {value.recipientFirstName} {value.recipientLastName}</p>
                        )}
                        <p className="description-text-4 description-text-color bold-text top-padding-5">Created {convertDateToString(value.createdAt,"daysAgo")}</p>
                      </div>
                      <div className="fixed-column-70">
                        <p className="heading-text-3 full-width right-text cta-color">${value.price}</p>
                        <div className="half-spacer" />
                        <button className="background-button full-width" onClick={() => this.openReviewModal(value,index)}>
                          <img src={rateIconDark} alt="GC" className="image-auto-30 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Rate this booking"/>
                        </button>
                      </div>
                      <div className="clear" />
                      <ReactTooltip id="tooltip-placeholder-id" />
                      <div className="spacer" /><div className="half-spacer" />
                      <hr />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p className="error-message">No bookings found</p>
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               ariaHideApp={false}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >

              {(this.state.showRating) && (
                <div className="full-width padding-30">
                  <p className="full-width heading-text-2 center-text">Rate {this.state.selectedItem.name}</p>

                  {(this.state.done) ? (
                    <div>
                      {(this.state.rating && Number(this.state.rating) > 3) ? (
                        <div>
                          <p className="full-width center-text top-padding description-text-color">Share your experience with friends and family!</p>

                          <div className="top-padding-40 full-width center-text">
                            {this.renderShareButtons()}
                          </div>

                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        </div>
                      ) : (
                        <div>
                          <p className="full-width center-text top-padding description-text-color">Thank you for your feedback!</p>
                          <div className="spacer" /><div className="spacer" />
                        </div>
                      )}

                      <div className="row-10 center-text">
                        <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="full-width center-text top-padding description-text-color">Ratings will be viewable by the viewing public.</p>
                      <div className="spacer" /><div className="spacer" />

                      <div className="row-10">
                        <label className="profile-label">On a scale of 1-5 with 5 being the best, how do you rate this experience? <label className="error-color">*</label></label>
                        <select name="rating" value={this.state.rating} onChange={this.formChangeHandler} className="dropdown">
                          {["","1","2","3","4","5"].map(value =>
                            <option key={value} value={value}>{value}</option>
                          )}
                        </select>
                      </div>

                      <div className="row-10">
                        <label className="profile-label">Please explain your rating. <label className="error-color">*</label></label>
                        <textarea type="text" className="text-field" placeholder="(e.g., this was a fantastic experience because...)" name="review" value={this.state.review} onChange={this.formChangeHandler}></textarea>
                      </div>

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.errorMessage && this.state.errorMesssage !== '') && <p className="error-color row-5 description-text-2">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMesssage !== '') && <p className="cta-color row-5 description-text-2">{this.state.successMessage}</p>}

                      <div className="row-10">
                        <button className="btn btn-primary right-margin" onClick={() => this.submitRating()}>Submit Rating</button>
                        <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Modal>
            </div>

        )
    }
}

export default withRouter(Bookings);
