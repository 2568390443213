import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubOpportunities from '../components/Subcomponents/Opportunities';
import withRouter from '../components/Functions/WithRouter';

class WorkOpportunitiesPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          subNavCategories: ['Trends','Careers','Financials','Events','Projects','Work','Programs & Courses']
        }

        this.handleScroll = this.handleScroll.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
    }

    componentDidMount() {
      //see if user is logged in
      console.log('jobs page just mounted')
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#F5F5F5";

    }

    handleScroll(event) {
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      if (pageName === 'Training Programs') {
        pageName = 'training-programs'
        this.props.navigate('/' + pageName)
      } else if (pageName === 'Programs & Courses') {
        pageName = 'courses'
        this.props.navigate('/' + pageName)
      } else if (pageName === 'Projects') {
        pageName = 'project-opportunities'
        this.props.navigate('/' + pageName)
      } else if (pageName === 'Work') {
        pageName = 'work-opportunities'
        this.props.navigate('/' + pageName)
      } else {
        pageName = pageName.toLowerCase()
        this.props.navigate('/' + pageName)
      }
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"WorkOpportunitiesPage"} darkBackground={true} />

              <div className="full-width white-background">
                <div className="center-horizontally clear-float width-70-percent">
                  <div className="carousel-3" onScroll={this.handleScroll}>
                    {this.state.subNavCategories.map((value, index) =>
                      <div className="carousel-item-container">
                        {(this.state.subNavCategories[index] === 'Work') ? (
                          <div className="selected-carousel-item">
                            <label key={value}>{value}</label>
                          </div>
                        ) : (
                          <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                            <label key={value}>{value}</label>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />

              <div className="center-horizontally clear-float width-70-percent">
                <SubOpportunities noOrgCode={true} passedViewIndex={7} passedSubNavSelected="Work" passedTypes={['Internship','Work']} pageSource="landingPage" history={this.props.navigate}/>
              </div>

              {/*Footer()*/}
              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(WorkOpportunitiesPage);
