import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';;
import Modal from 'react-modal';
import SubCustomDropdown from '../Common/CustomDropdown';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const linkIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-dark.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';
const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";
const matchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon.png";
const matchIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/match-icon-selected.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

const styles2 = {
    transition: 'all 0.75s ease',
    transform: 'translate(85%)'
};

class Pathways extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderPathways = this.renderPathways.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)

        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.subNavSelected !== prevProps.subNavSelected) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode || this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subPathways', this.props)

      let org = this.props.activeOrg

      let accountCode = this.props.accountCode

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let placementPartners = localStorage.getItem('placementPartners');

      let matchingCriteria = [
        { name: 'Work Function',  description: 'Scores careers / opportunities the highest that match your preferred work functions indicated in your work preferences assessment', value: 20 },
        { name: 'Industry',  description: 'Scores careers / opportunities the highest that match your preferred industries indicated in your work preferences assessment', value: 10 },
        { name: 'Location',  description: 'Scores careers / opportunities the highest that match your preferred location preferences indicated in your work preferences assessment', value: 0 },
        { name: 'Pay',  description: 'Scores high paying careers / opportunities highest', value: 0 },
        { name: 'Stability',  description: 'Scores careers / opportunities the highest with little variability in pay and job growth', value: 0 },
        { name: 'Interests',  description: 'Scores careers / opportunities the highest that match your interest assessment', value: 15 },
        { name: 'Personality',  description: 'Scores careers / opportunities the highest that match your personality assessment', value: 15 },
        { name: 'Values',  description: 'Scores careers / opportunities that the highest match your values assessment', value: 10 },
        { name: 'Skills',  description: 'Scores careers / opportunities that the highest match your skills assessment', value: 20 },
        { name: 'Education',  description: 'Scores careers / opportunities the highest that match your target education level indicated in work preferences', value: 0 },
        { name: 'Projects',  description: 'Scores careers / opportunities the highest that will likely value your project work the highest', value: 5 },
        { name: 'Experience',  description: 'Scores careers / opportunities the highest that will likely value your experience (that you like)', value: 5 },
      ]

      let useCases = [
        { name: 'Purpose', description: 'Optimize for opportunities that may give you the most purpose (e.g., solving a global problem, or large problem I am specifically interested in)', selected: false }, // interests
        { name: 'Stability', description: 'Optimize for opportunities where you will likely have the most job stability.', selected: false }, // job growth, company size
        { name: 'Pay', description: 'Optimize for opportunities where you will be paid the most in the short to medium term.', selected: false }, // pay
        { name: 'Recognition', description: 'Optimize for opportunities where you will be provided positive reinforcement and public praise for your accomplishments.', selected: false }, // social, artistic
        { name: 'Interests', description: 'Optimize for opportunities where you have the most long-term, genuine interest in.', selected: false }, // interests, personality
        { name: 'Competitive Advantage', description: 'Optimize for opportunities where you have the highest competitive advantage amongst candidates in your age group.', selected: false }, // skills
      ]

      const menuOptions = [
        { icon: exchangeIconDark, title: 'Duplicate pathway', shorthand: 'duplicate' },
        { icon: linkIconDark, title: 'Copy link for CS', shorthand: 'copy' },
        { icon: editIconDark, title: 'Edit pathway', shorthand: 'edit' },
        { icon: hideIcon, title: 'Hide pathway', shorthand: 'hide', altTitle: 'Unhide pathway' },
        { icon: deleteIconDark, title: 'Delete pathway', shorthand: 'delete' },
      ]

      this.setState({ org, accountCode, email, username, orgFocus, placementPartners,
        matchingCriteria, useCases, menuOptions
      })

      if (org || this.props.noOrgCode) {

        let queryParams = { orgCode: org }
        if (window.location.pathname.includes('/app')) {
          queryParams['isActive'] = true
        }

        Axios.get('/api/pathways', { params: queryParams })
        .then((response) => {
          console.log('Pathways query attempted no 1', response.data);

          if (response.data.success) {
            console.log('pathway query worked no 1')

            if (response.data.pathways.length !== 0) {

              const pathways = response.data.pathways
              const filteredPathways = response.data.pathways
              this.setState({ pathways, filteredPathways });
            }

          } else {
            console.log('pathway query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathway query did not work for some reason', error);
        });

        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          console.log('User details query attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             Axios.get('/api/assessment/results', { params: { emailId: email } })
              .then((response2) => {
                console.log('query for assessment results worked');

                if (response2.data.success) {

                  console.log('actual assessment results', response2.data)

                  let profile = response.data.user
                  profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                  profile['interests'] = response2.data.results.interestScores
                  profile['personality'] = response2.data.results.personalityScores
                  profile['skills'] = response2.data.results.newSkillAnswers
                  profile['gravitateValues'] = response2.data.results.topGravitateValues
                  profile['employerValues'] = response2.data.results.topEmployerValues

                  // let matchingCriteria = this.state.matchingCriteria
                  if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                    matchingCriteria = response.data.user.matchingPreferences
                  }
                  if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                    useCases = response.data.user.matchingUseCases
                  }

                  this.setState({ profile, matchingCriteria, useCases })

                } else {
                  console.log('no assessment results', response2.data)

                  // let matchingCriteria = this.state.matchingCriteria
                  if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                    matchingCriteria = response.data.user.matchingPreferences
                  }
                  if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                    useCases = response.data.user.matchingUseCases
                  }
                  this.setState({ matchingCriteria, useCases })
                }

            }).catch((error) => {
                console.log('query for assessment results did not work', error);
                // let matchingCriteria = this.state.matchingCriteria
                if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
                  matchingCriteria = response.data.user.matchingPreferences
                }
                if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
                  useCases = response.data.user.matchingUseCases
                }
                this.setState({ matchingCriteria, useCases })
            })

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.value)
      } else if (event.target.name.includes('filter|')) {

        let type = 'All'
        let index = 0

        let itemFilters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name === field) {
            itemFilters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        //reset everything
        let searchString = ''

        // let allSorters = this.state.allSorters
        // for (let i = 1; i <= allSorters.length; i++) {
        //   allSorters[i - 1]['value'] = this.state.defaultSortOption
        // }

        this.setState({ itemFilters, animating: true, searchString })

        this.filterResults(this.state.searchString, event.target.value, itemFilters, index, false, type)

      } else if (event.target.name.includes('sort|')) {

        let type = 'All'
        let itemSorters = this.state.itemSorters
        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name === field) {
            itemSorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        let searchString = ''
        this.setState({ searchString, itemSorters, animating: true })

        this.sortResults(event.target.value, field, type)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString) {
      console.log('searchItems called ', searchString)

      const orgCode = this.state.org
      const accountCode = this.state.accountCode
      const queryParams = { searchString, orgCode, accountCode }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/pathways/search', { params: queryParams })
        .then((response) => {
          console.log('Pathways search query attempted', response.data);

            if (response.data.success) {
              console.log('pathway search query worked')

              const filteredPathways = response.data.pathways
              self.setState({ filteredPathways, animating: false });

            } else {
              console.log('pathway search query did not work', response.data.message)
              self.setState({ filteredPathways: this.state.pathways, animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark search query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    filterResults(searchString, filterString, filters, index, search, type) {
      console.log('filterResults called', searchString, filterString, filters, index, search, type)

      // let pathways = this.state.pathways

      const orgCode = null
      const pathwayLevel = true

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/pathways/filter', { params: { filters, orgCode, pathwayLevel } })
        .then((response) => {
          console.log('Pathways filter query attempted', response.data);

            if (response.data.success) {
              console.log('pathways filter query worked')

              const filteredPathways = response.data.pathways
              self.setState({ filteredPathways, animating: false });

            } else {
              console.log('pathway filter query did not work', response.data.message)
              self.setState({ filteredPathways: this.state.pathways, animating: false })
            }

        }).catch((error) => {
            console.log('Pathway filter query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let pathways = this.state.pathways

      if (sortName === 'Match') {
        console.log('in benchmark match')

      } else {

        Axios.put('/api/pathways/sort', { sortString, pathways, sortName })
        .then((response) => {
          console.log('Pathways sort query attempted', response.data);

            if (response.data.success) {
              console.log('pathways sort query worked')

              // THIS IS SUPER UNNECESSARY
              const filterCriteriaArray = null
              const sortCriteriaArray = response.data.sortCriteriaArray

              const filteredBenchmarks = response.data.benchmakrs
              this.setState({ filteredBenchmarks, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('pathway sort query did not work', response.data.message)
              this.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Pathway sort query did not work for some reason', error);
            this.setState({ animating: false })
        });
      }
    }

    renderPathways() {
      console.log('renderPathways called')

      let rows = [];

      if (this.state.filteredPathways && this.state.filteredPathways.length > 0 ) {

        for (let i = 1; i <= this.state.filteredPathways.length; i++) {
          // console.log(i);

          const index = i - 1
          let title = this.state.filteredPathways[i - 1].title
          if ((window.location.pathname.includes('/app/') || this.props.pageSource === 'landingPage') && this.state.filteredPathways[i - 1].pathway) {
            title  = this.state.filteredPathways[i - 1].pathway
          }

          let subtitle = this.state.filteredPathways[i - 1].jobFunction
          if (this.state.filteredPathways[i - 1].jobTitle) {
            subtitle = subtitle + ' | ' + this.state.filteredPathways[i - 1].jobTitle
          }

          if (this.state.filteredPathways[i - 1].accountCode) {
            subtitle = subtitle + ' | Employer-Generated'
          }

          let pathname = ''
          let showModal = false
          let passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          if (this.props.pageSource === 'landingPage') {
            if (window.location.pathname.includes('/employers')) {
              pathname = '/employers/pages/' + this.state.filteredPathways[i - 1].accountCode + '/pathways/' + this.state.filteredPathways[i - 1]._id
              passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
            } else {
              pathname = '/pathways/' + this.state.filteredPathways[i - 1]._id
              passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
            }
          } else if (window.location.pathname.includes('/organizations')) {
            pathname = '/organizations/' + this.state.org + '/pathways/' + this.state.filteredPathways[i - 1]._id
            passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          } else if (this.props.source === 'Employer') {
            pathname = '/employers/' + this.props.accountCode + '/pathways/' + this.state.filteredPathways[i - 1]._id
            passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          } else if (this.props.pageSource === 'landingPage') {

          } else if (window.location.pathname.includes('/walkthrough')) {
            showModal = true
            passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          } else if (window.location.pathname.includes('/app/')) {
            pathname = '/app/pathways/' + this.state.filteredPathways[i - 1]._id
            passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          } else if (window.location.pathname.includes('/advisor/')) {
            pathname = '/advisor/pathways/' + this.state.filteredPathways[i - 1]._id
            passedState = { selectedPathway:  this.state.filteredPathways[i - 1] }
          }

          let midClassName = "calc-column-offset-190 left-padding-20"
          if ((this.state.filteredPathways[i - 1].pathwayLevel && this.props.pageSource !== 'landingPage') || this.state.filteredPathways[i - 1].orgCode !== this.state.org) {
            midClassName = "calc-column-offset-240 left-padding-20"
          }

          const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

          rows.push(
            <div key={i} >
              <Link className="secret-link" onClick={(showModal) ? () => this.setState({ modalIsOpen: true, showEditPathway: true, selectedPathway: this.state.filteredPathways[index] }) : () => console.log('')} to={pathname} state={passedState}>
                <span className="fixed-column-60">
                  <div className="spacer" />

                  {(this.state.matchScores && this.state.matchScores[i - 1]) ? (
                    <div className="padding-10">
                      <CircularProgressbar
                        value={this.state.matchScores[i - 1]}
                        text={`${this.state.matchScores[i - 1]}%`}
                        styles={{
                          path: { stroke: `${primaryColor}, ${this.state.matchScores[i - 1] / 100}` },
                          text: { fill: primaryColor, fontSize: '26px' },
                          trail: { stroke: 'transparent' }
                        }}
                      />
                    </div>
                  ) : (
                    <img src={(this.state.filteredPathways[i - 1].imageURL) ? this.state.filteredPathways[i - 1].imageURL : pathsIconBlue} alt="GC" className="image-auto-50 center-horizontally"/>
                  )}
                </span>
                <span className={midClassName}>
                  <div className="spacer" />
                  <p className="heading-text-5">{title}</p>
                  <p className="description-text-2 top-padding-5">{subtitle}</p>
                </span>
                {(this.state.filteredPathways[i - 1].pathwayLevel || this.state.filteredPathways[i - 1].orgCode !== this.state.org) && (
                  <span className="fixed-column-40 top-margin">
                    {(this.state.filteredPathways[i - 1].pathwayLevel) && (
                      <div>
                        <img src={pipelineIconDark} alt="GC" className="image-auto-22 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This is a pathway"/>
                      </div>
                    )}

                    <ReactTooltip id="tooltip-placeholder-id" />
                  </span>
                )}

                {(window.location.pathname.includes('/app/')) ? (
                  <div>
                    <div className="fixed-column-80 height-30 right-padding" />
                  </div>
                ) : (
                  <div>
                    {(this.state.filteredPathways[i - 1].isActive === false) ? (
                      <div className="fixed-column-40 right-padding">
                        <div className="spacer" />
                        <img src={hideIcon} alt="Compass arrow indicator icon" className="image-auto-25"/>

                        {(this.state.filteredPathways[i - 1].orgCode !== this.state.org) && (
                          <div className="top-margin">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        )}

                      </div>
                    ) : (
                      <div>
                        {(this.state.filteredPathways[i - 1].orgCode !== this.state.org) ? (
                          <div className="fixed-column-40 right-padding">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        ) : (
                          <div className="fixed-column-40 height-30 right-padding" />
                        )}
                      </div>
                    )}
                    <div className="fixed-column-50 left-padding top-margin-5">
                      <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                        <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                      </button>
                    </div>
                  </div>
                )}

                <span className="fixed-column-30 top-margin">
                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                </span>
                <div className="clear" />
              </Link>

              {(this.state.filteredPathways[i - 1].showMenu) && (
                <div className="menu-bottom-4">
                  {this.state.menuOptions.map((item,optionIndex) =>
                    <div key={item.title}>
                      {((item.title.includes('Delete') && this.state.filteredPathways[i - 1].orgCode === this.state.org) || !item.title.includes('Delete')) && (
                        <div>
                          {(item.title === 'Delete pathway' && this.state.filteredPathways[i - 1].confirmDelete) ? (
                            <div>
                              <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                              <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(i - 1)}>Confirm</button>
                              <button className="btn btn-squarish white-background cta-color description-text-3" onClick={() => this.menuButtonClicked(item.shorthand,index)}>Cancel</button>
                            </div>
                          ) : (
                            <button className="background-button row-10 clear-margin left-text full-width" onClick={() => this.menuButtonClicked(item.shorthand,index)}>
                              <div className="fixed-column-25">
                                <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                              </div>
                              <div className="calc-column-offset-25">
                                {(item.title === 'Hide pathway' && !this.state.filteredPathways[i - 1].isActive) ? (
                                  <p className="description-text-2">{item.altTitle}</p>
                                ) : (
                                  <p className={(item.title === 'Delete pathway') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                )}
                              </div>
                              <div className="clear" />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {(this.state.filteredPathways[i - 1].successMessage) && (
                <div className="row-5 left-padding">
                  <p className="description-text-2 cta-color full-width">{this.state.filteredPathways[i - 1].successMessage}</p>
                </div>
              )}

              <div className="spacer" /><div className="spacer" /><div className="spacer" />
              <hr />
              <div className="spacer" /><div className="spacer" />
            </div>
          )

        }

      } else {
        //show empty state screen

      }

      return rows;
    }

    calculateMatches(matchingView, calcMatches, newPreferences) {
      console.log('calculateMatches called', matchingView, calcMatches, newPreferences)

      if (matchingView) {

        this.setState({ matchingView: true, errorMessage: null })

        if (this.state.pathways) {

          if (!this.state.profile) {
            console.log('no profile')
            this.setState({ animating: false, matchingView: true, errorMessage: 'Please take career assessments before receiving personalized matches' })
          } else {
            this.setState({ animating: true, modalIsOpen: false })

            // orgCode: queryOrgCode, placementPartners, postType, postTypes, pathway
            const profile = this.state.profile
            const orgCode = this.state.org
            const placementPartners = this.state.placementPartners

            const pathway = this.state.pathway

            let postType = this.state.postType
            let postTypes = this.state.postTypes
            postType = null
            postTypes = null
            const pathwayLevel = true

            const matchingCriteria = this.state.matchingCriteria
            const useCases = this.state.useCases

            const self = this

            function officiallyCalculate() {
              console.log('officiallyCalculate called')

              const hourlyPayOptions = self.state.hourlyPayOptions
              const annualPayOptions = self.state.annualPayOptions
              const employeeCountOptions = self.state.employeeCountOptions
              const hourOptions = self.state.hourOptions

              Axios.put('/api/pathways/matches', {
                profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
                annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions, pathwayLevel })
              .then((response) => {
                console.log('Pathways matches attempted', response.data);

                  if (response.data.success) {
                    console.log('pathways match query worked')

                    let matchScores = response.data.matchScores
                    const pathways = response.data.pathways
                    const filteredPathways = response.data.pathways

                    self.setState({ animating: false, matchingView: true, matchScores, pathways, filteredPathways })

                  } else {
                    console.log('Pathways match did not work', response.data.message)
                    self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
                  }

              }).catch((error) => {
                  console.log('Pathway match did not work for some reason', error);
                  self.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
              });
            }

            if (newPreferences) {
              console.log('new preferences stated')

              // totalPercent must equal 100
              if (this.state.totalPercent !== 100) {
                this.setState({ animating: false, modalIsOpen: true, matchingView: true, errorMessage: 'The sum of matching criteria weights must equal 100' })
              } else {
                const emailId = this.state.emailId
                const matchingPreferences = matchingCriteria
                const matchingUseCases = useCases
                const updatedAt = new Date()

                Axios.post('/api/users/profile/details', {
                  emailId, matchingPreferences, matchingUseCases, updatedAt })
                .then((response) => {

                  if (response.data.success) {
                    console.log('successfully saved new preferences')
                    officiallyCalculate()

                  } else {
                    console.log('request was not successful')
                    officiallyCalculate()
                  }
                }).catch((error) => {
                    console.log('Saving the info did not work', error);
                    officiallyCalculate()
                });
              }
            } else {
              officiallyCalculate()
            }
          }
        }
      } else {
        this.setState({ matchingView: false, errorMessage: null })
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showEditPathway: false, selectedPathway: null, showMatchingCriteria: false })
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      e.stopPropagation()
      e.preventDefault()

      let filteredPathways = this.state.filteredPathways
      if (this.state.filteredPathways[index].showMenu) {
        filteredPathways[index]['showMenu'] = false
      } else {
        filteredPathways[index]['showMenu'] = true
      }

      this.setState({ filteredPathways })

    }

    menuButtonClicked(action, index) {
      console.log('menuButtonClicked called', action, index)

      if (action === 'duplicate') {
        let pathname = ''
        if (window.location.pathname.includes('/organizations')) {
          pathname = '/organizations/' + this.state.org + '/pathways/add'
        } else if (window.location.pathname.includes('/advisor/')) {
          pathname = '/advisor/pathways/add'
        }
        let selectedPathway = this.state.filteredPathways[index]
        this.props.navigate(pathname, { state: { selectedPathway, duplicate: true }})
      } else if (action === 'copy') {
        navigator.clipboard.writeText('https://www.guidedcompass.com/app/pathways/' + this.state.filteredPathways[index]._id)
        let filteredPathways = this.state.filteredPathways
        filteredPathways[index]['showMenu'] = false
        filteredPathways[index]['successMessage'] = 'Link has been successfully copied. Send to it to career-seekers!'
        this.setState({ filteredPathways })
      } else if (action === 'edit') {
        let pathname = ''
        if (window.location.pathname.includes('/organizations')) {
          pathname = '/organizations/' + this.state.org + '/pathways/' + this.state.filteredPathways[index]._id
        } else if (window.location.pathname.includes('/advisor/')) {
          pathname = '/advisor/pathways/' + this.state.filteredPathways[index]._id
        }

        this.props.navigate(pathname, { state: { selectedPathway: this.state.filteredPathways[index] } })

      } else if (action === 'hide') {

        const _id = this.state.filteredPathways[index]._id
        let isActive = null
        if (this.state.filteredPathways[index].isActive) {
          isActive = false
        } else {
          isActive = true
        }

        const updatedAt = new Date()

        Axios.post('/api/pathways', {
          _id, isActive, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Post save worked', response.data);

            let filteredPathways = this.state.filteredPathways
            filteredPathways[index]['successMessage'] = 'Pathway updated successfully!'
            filteredPathways[index]['isActive'] = isActive
            filteredPathways[index]['showMenu'] = false
            this.setState({ filteredPathways, isSaving: false })

          } else {
            console.error('there was an error saving the pathway');

            let filteredPathways = this.state.filteredPathways
            filteredPathways[index]['errorMessage'] = response.data.message
            this.setState({ filteredPathways, isSaving: false })
          }
        }).catch((error) => {
            console.log('The pathway save did not work', error);
            let filteredPathways = this.state.filteredPathways
            filteredPathways[index]['errorMessage'] = error.toString()
            this.setState({ filteredPathways, isSaving: false })
        });
      } else if (action === 'delete') {
        let filteredPathways = this.state.filteredPathways
        if (filteredPathways[index].confirmDelete) {
          filteredPathways[index]['confirmDelete'] = false
        } else {
          filteredPathways[index]['confirmDelete'] = true
        }
        this.setState({ filteredPathways })

      }
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      let filteredPathways = this.state.filteredPathways
      const _id = filteredPathways[index]._id

      Axios.delete('/api/pathways/' + _id)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Pathway delete worked')

          filteredPathways.splice(index,1)
          this.setState({ filteredPathways, isSaving: false })

        } else {
          console.error('there was an error deleting the pathway');

          filteredPathways[index]['errorMessage'] = response.data.message
          this.setState({ filteredPathways, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          filteredPathways[index]['errorMessage'] = error.toString()
          this.setState({ filteredPathways, isSaving: false })
      });
    }

    render() {

      let cardClass = "floating-container card top-margin-2-percent right-margin-2-percent"
      let cardStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      let searchClass = "floating-container card-clear-padding top-margin-2-percent right-margin-2-percent"
      let searchStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      if (this.props.pageSource === 'landingPage') {
        cardClass = "card"
        cardStyle = {}
        searchStyle = {}
      } else if (window.location.pathname.includes('/app/')) {
        cardClass = "card"
        cardStyle = {}
        searchStyle = {}
      } else if (window.location.pathname.includes('/walkthrough')) {
        cardClass = "top-margin-30"
        cardStyle = {}
        searchStyle = {}
      } else if (this.props.renderSideNav === false || (toggleVars.hideSideNav && window.location.pathname.startsWith('/advisor'))) {
        cardClass = "white-background width-70-percent vertical-margin-2-percent padding-40 medium-shadow max-width-1400 center-horizontally"
        cardStyle = {}
        searchClass = "max-width-1400 width-70-percent center-horizontally"
        searchStyle = {}
      } else if (toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations')) {
        cardClass = "card width-70-percent vertical-margin-2-percent max-width-1400 center-horizontally"
        cardStyle = {}
        searchClass = "max-width-1400 width-70-percent center-horizontally"
        searchStyle = {}
      }

      let addPath = ''
      if (window.location.pathname.includes('/organizations')) {
        addPath = '/organizations/' + this.props.activeOrg + '/pathways/add'
      } else if (this.props.source === 'Employer') {
        addPath = '/employers/' + this.props.accountCode + '/pathways/add'
      } else if (window.location.pathname.includes('/advisor')) {
        addPath = '/advisor/pathways/add'
      }

      let mainWidth = "calc-column-offset-65"

      return (
          <div>
            {(window.location.pathname.includes('/app')) && (
              <div>
                <div>
                  <div className={(this.props.pageSource === 'landingPage') && "row"}>
                    <div className={(this.props.pageSource === 'landingPage') && "full-width white-background"}>
                      {(this.state.matchingView) ? (
                        <div>
                          <div className="search-icon-container top-margin-negative-3 full-width" style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <img src={matchIconSelected} alt="GC" className="image-auto-30 right-margin" />
                            </button>
                          </div>
                          <div className="full-width">
                            <button type="button" className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMatchingCriteria: true })}>
                              <div className="float-left right-margin slightly-rounded-corners row-5 horizontal-padding cta-background-color cta-border white-text">
                                <p>Adjust</p>
                              </div>
                            </button>
                            <button type="button" className="background-button float-left" onClick={() => this.calculateMatches(false, false, false)}>
                              <div className="float-left right-padding standard-border slightly-rounded-corners row-5 horizontal-padding">
                                <p>Close</p>
                              </div>
                            </button>

                            <div className="clear" />

                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.props.pageSource === 'landingPage') ? (
                            <div className="filter-field-search full-width white-background">
                              <div className="search-icon-container">
                                <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                              </div>
                              <div className="filter-search-container calc-column-offset-100-static">
                                <input type="text" className="text-field clear-border" placeholder={"Search..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                              <div className="search-icon-container top-margin-negative-3">
                                <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                  <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                                </button>
                              </div>

                            </div>
                          ) : (
                            <div className="full-width row-5 white-background standard-border medium-shadow">
                              <div className={(this.state.matchingView) ? "float-left full-width" : "fixed-column-50 center-item"} style={(this.state.matchingView) ? { ...styles2, position: 'absolute' } : { }}>
                                <button type="button" className={(this.state.matchingView) ? "background-button float-left" : "background-button full-space bottom-padding"} onClick={() => this.calculateMatches(true, true, false)}>
                                  <img src={(this.state.matchingView) ? matchIconSelected : matchIcon} alt="GC" className="image-auto-30 right-margin center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Calculate Matches"/>
                                </button>
                              </div>
                              <div className="filter-field-search clear-margin calc-column-offset-100-static white-background clear-border">
                                <div className="search-icon-container">
                                  <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                                </div>
                                <div className="filter-search-container calc-column-offset-100-static">
                                  <input type="text" className="text-field clear-border" placeholder={"Search..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                                </div>
                              </div>
                              <div className="fixed-column-50 center-item">
                                <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                                  <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                                </button>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                          <ReactTooltip id="tooltip-placeholder-id" />

                        </div>
                      )}
                    </div>
                  </div>

                  {(this.state.showingSearchBar) && (
                    <div className={"row box-container-1 white-background"}>
                      <div>

                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <hr />
                          <div className="spacer" /><div className="spacer" />
                        </div>

                        <p>Filter</p>
                        <div className="half-spacer" />
                        {(this.renderManipulators('filter'))}

                        <div className="clear" />
                        <div className="spacer" />
                        <hr />
                        <div className="spacer" /><div className="spacer" />
                        <p>Sort</p>
                        <div className="half-spacer" />
                        {(this.renderManipulators('sort'))}
                        <div className="clear" />

                      </div>
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />
                </div>
              </div>
            )}
            {(this.props.pageSource !== 'landingPage' && !window.location.pathname.includes('/walkthrough') && !window.location.pathname.includes('/app/')) && (
              <div className={cardClass} style={cardStyle}>
                <div className={mainWidth}>
                  <p className="heading-text-2">{(this.state.filteredPathways && this.state.filteredPathways.length > 0) && this.state.filteredPathways.length + " "}Pathways</p>
                </div>

                {(this.props.pageSource !== 'landingPage') && (
                  <div>
                    <div className="fixed-column-40 top-padding-5">
                      <button className="background-button" onClick={(this.state.showSearchBar) ? () => this.setState({ showSearchBar: false }) : () => this.setState({ showSearchBar: true })}>
                        <img src={searchIcon} alt="GC" className="image-auto-25"/>
                      </button>
                    </div>
                    <div className="fixed-column-25 top-padding-5">
                      <Link to={addPath}>
                        <img src={addIcon} alt="GC" className="image-auto-25"/>
                      </Link>
                    </div>

                  </div>
                )}
                <div className="clear" />

                {(window.location.pathname.includes('/employers')) ? (
                  <div>
                    <p className="description-text-color top-padding">Specify pathways to build talent pipelines for each of your departments. Educators, workforce professionals, and career-seekers in diverse communities will use this to prepare for roles at your company, and you can engage your pipelines at any time.</p>
                  </div>
                ) : (
                  <p className="description-text-2 row-5 standard-color">Specify custom pathways with videos, curriculum, benchmarks, and more!</p>
                )}
              </div>
            )}

            {(!window.location.pathname.includes('/walkthrough')) && (
              <div>
                {(this.props.pageSource === 'landingPage') ? (
                  <div>
                    <div className="row bottom-margin-20">
                      <div className="filter-field-search full-width white-background">
                        <div className="search-icon-container">
                          <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                        </div>
                        <div className="filter-search-container calc-column-offset-100-static">
                          <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                        {/*
                        <div className="search-icon-container top-margin-negative-3">
                          <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                            <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                          </button>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {(this.state.showSearchBar) && (
                      <div className="bottom-padding-40">
                        <div className={searchClass} style={searchStyle}>
                          <div className="filter-field-search full-width white-background clear-margin">
                            <div className="float-left top-padding-5 left-padding-5 right-padding-5">
                              <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                            </div>
                            <div className="calc-column-offset-100-static">
                              <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                            {/*
                            <div className="search-icon-container top-margin-negative-3">
                              <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                                <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                              </button>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    )}

                    {(this.props.renderSideNav === false) && (
                      <div className="clear" />
                    )}

                  </div>
                )}
              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space white-background">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Calculating results...</p>

                </div>
              </div>
            ) : (
              <div className={cardClass} style={cardStyle}>
                {this.renderPathways()}
              </div>
            )}

            <div className="clear"/>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

            {(this.state.showEditPathway) && (
              <div className="full-width padding-30">
                {/*<SubEditBenchmark selectedTemplate={this.state.selectedPathway} history={this.props.navigate} />*/}
              </div>
            )}

            {(this.state.showMatchingCriteria) && (
              <div key="showMatchingCriteria" className="full-width padding-20">
                <p className="heading-text-2">Adjust Matching Criteria</p>
                <div className="spacer" />

                <div className="row-10">
                  <div className="flex-container">
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: false })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text" : "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text"}>
                        Adjust by Needs
                      </div>
                    </button>
                    <button className="background-button flex-50" type="button" onClick={() => this.setState({ customAdjustment: true })}>
                      <div className={(this.state.customAdjustment) ? "cta-border center-item row-15 horizontal-padding center-text cta-background-color white-text" : "cta-border center-item row-15 horizontal-padding center-text"}>
                        Custom Adjust
                      </div>
                    </button>

                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.customAdjustment) ? (
                  <div>

                    {(this.state.matchingCriteria) && (
                      <div>
                        {this.state.matchingCriteria.map((value ,index) =>
                          <div key={"c" + index}>
                            <div className="calc-column-offset-100-static">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-100 right-text">
                              <div className="fixed-column-70">
                                <input type="number" className="text-field heading-text-2 cta-color bold-text full-width right-text standard-border" min="0" max="100" placeholder="0" name={"custom|" + index} value={value.value} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="fixed-column-30">
                                <div className="mini-spacer"/><div className="mini-spacer"/>
                                <p className="heading-text-2 cta-color bold-text">%</p>
                              </div>
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                        <div>
                          <hr className="cta-border-color"/>
                          <div className="spacer" />
                          <div className="float-left full-width right-text">
                            <p className="heading-text-2 cta-color bold-text">{this.state.totalPercent}%</p>
                          </div>
                          <div className="clear" />
                          {(this.state.totalPercent !== 100) && (
                            <div className="full-width">
                              <p className="error-message right-text">Please adjust percentages to equal 100%</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>

                    {(this.state.useCases) && (
                      <div>
                        {this.state.useCases.map((value ,index) =>
                          <div key={"u" + index} className={(value.name === 'Purpose') && "wash-out-2"}>
                            <div className="calc-column-offset-50">
                              <p className="half-bold-text">{index + 1}. {value.name}</p>
                              <div className="half-spacer" />
                              <p className="description-text-3">{value.description}</p>
                            </div>
                            <div className="fixed-column-50 horizontally-center center-text top-padding-5">

                              <button disabled={(value.name === 'Purpose') ? true : false} onClick={() => this.itemClicked('useCase|' + index, true)} type="button" className="background-button">
                                {(value.selected) ? (
                                  <div className="circle-option-container-2 cta-border center-text" >
                                    <div className="circle-selected-2"/>
                                  </div>
                                ) : (
                                  <div className="circle-option-container-2 standard-border center-text" />
                                )}
                              </button>

                              {/*
                              <select name={"useCases|" + index} className="dropdown description-text-2" value={value.value} onChange={this.formChangeHandler}>
                                  {['Optimize','Ignore','De-Optimize'].map(value => <option key={value} value={value}>{value}</option>)}
                              </select>*/}
                            </div>

                            <div className="clear" />
                            <div className="spacer" /><div className="half-spacer" />

                          </div>
                        )}

                      </div>
                    )}
                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}

                <div className="row-20 center-text">
                  <button className="btn btn-primary right-margin" onClick={() => this.calculateMatches(true, true, true)}>Apply Changes</button>

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                </div>

              </div>
            )}

           </Modal>
          </div>
      )
    }
}

export default withRouter(Pathways);
