import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import SubHelp from '../components/Subcomponents/Help';
import withRouter from '../components/Functions/WithRouter';

class HelpPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      console.log('help page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      let ready = true
      let selectedFilters = null
      if (this.props.location && this.props.location.state) {
        selectedFilters = this.props.location.state.selectedFilters
      }

      this.setState({ ready, selectedFilters })
    }

    render() {
      console.log('testing 6')

      return (
          <div>
            <div>
              <TopNavigation currentPage={"helpPage"} darkBackground={true} />

              {(this.state.ready) && (
                <SubHelp history={this.props.navigate} selectedFilters={this.state.selectedFilters} />
              )}

              <Footer history={this.props.navigate} />

            </div>

          </div>

      )
    }
}

export default withRouter(HelpPage);
