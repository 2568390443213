import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAdminApplications from './Subcomponents/AdminApplications';
import withRouter from './Functions/WithRouter';

class AdvApplications extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let selectedJob = null
      let applications = null
      let benchmark = null
      let benchmarkOptions = null
      if (this.props.location && this.props.location.state) {
        selectedJob = this.props.location.state.selectedJob
        applications = this.props.location.state.applications
        benchmark = this.props.location.state.benchmark
        benchmarkOptions = this.props.location.state.benchmarkOptions
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ roleName, orgLogo, emailId: email, username,
        cuFirstName, cuLastName, activeOrg, orgFocus,
        selectedJob, applications, benchmark, benchmarkOptions
      })
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          <SubAdminApplications accountCode={this.state.emp} org={this.state.activeOrg} selectedJob={this.state.selectedJob} applications={this.state.applications} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} />
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvApplications);
