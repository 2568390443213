import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import withRouter from './Functions/WithRouter';

class EditOffer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            offer: null,

            formHasChanged: false,

            sessionErrorMessage: '',
            serverPostSuccess: false,
            serverSuccessMessage: '',
            serverErrorMessage: ''

        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.decide = this.decide.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      if (this.props.location && this.props.location.state) {
        const { objectId } = this.props.location.state;
        console.log('show me the money: ', objectId)

        //obtain email id from localStorage
        let email = localStorage.getItem('email');

        if (email) {
          let username = localStorage.getItem('username');
          let cuFirstName = localStorage.getItem('firstName');
          let cuLastName = localStorage.getItem('lastName');
          let activeOrg = localStorage.getItem('activeOrg');
          const orgLogo = localStorage.getItem('orgLogo');

          this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, orgLogo });

          Axios.get('/api/offers/byid', { params: { _id: objectId } })
          .then((response) => {

            if (response.data.success) {
              console.log('Offer query worked', response.data);

              if (response.data.offer) {
                const offer = response.data.offer

                let hasDecided = false
                let serverPostSuccess = false
                let serverSuccessMessage = ''
                if (offer.decision === 'accept' || offer.decision === 'reject') {
                  hasDecided = true
                  serverPostSuccess = true
                  serverSuccessMessage = 'You have already decided on this offer. If you would like to change the offer, please email help@guidedcompass.com or ' + offer.orgContactEmail + '.'
                }

                this.setState({ offer, hasDecided, serverPostSuccess, serverSuccessMessage })

                Axios.get('/api/account', { params: { accountCode: offer.accountCode } })
                .then((response) => {
                  console.log('Account info query attempted', response.data);

                  if (response.data.success) {
                    console.log('account info query worked')

                    const employerURL = response.data.accountInfo.employerURL
                    const employerType = response.data.accountInfo.employerType
                    const employerIndustry = response.data.accountInfo.employerIndustry
                    const employeeCount = response.data.accountInfo.employeeCount

                    const empContactFirstName = response.data.accountInfo.contactFirstName
                    const empContactLastName = response.data.accountInfo.contactLastName
                    const empContactEmail = response.data.accountInfo.contactEmail

                    this.setState({
                        employerURL, employerType, employerIndustry, employeeCount,
                        empContactFirstName, empContactLastName, empContactEmail
                    });

                  }

                }).catch((error) => {
                  console.log('Account info query did not work for some reason', error);
                });

                Axios.get('/api/postings/byid', { params: { _id: offer.postingId } })
                .then((response) => {
                  console.log('Postings query by id attempted', response.data);

                  if (response.data.success) {
                    console.log('postings query by id worked')

                    const selectedJob = response.data.posting
                    this.setState({ selectedJob })

                  }

                }).catch((error) => {
                  console.log('Account info query did not work for some reason', error);
                });
              }

            } else {
              console.log('no offer found', response.data.message)
            }

          }).catch((error) => {
              console.log('Offer query did not work', error);
          });
        }
      } else {
        //query only if data hasn't been passed from previous screen
        const { sessionId } = this.props.params
        if (sessionId !== '' && this.state.firstName !== '') {
          //query session data
          this.setState({ sessionId })
        }
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'searchAdvisors') {
        if (event.target.value === '') {
          this.setState({ searchString: event.target.value, showSearchResults: false })
        } else {
          this.setState({ searchString: event.target.value, showSearchResults: true })
        }
      } else if (event.target.name === 'sessionDate') {
        this.setState({ sessionDate: event.target.value })
      }
    }

    decide(decision) {
      console.log('decide clicked', decision);

      if (!this.state.offer) {
        this.setState({ clientErrorMessage: 'there was an error finding the offer '})
      } else {

        const _id = this.state.offer._id

        const empContactFirstName = this.state.empContactFirstName
        const empContactLastName = this.state.empContactLastName
        const empContactEmail = this.state.empContactEmail
        const orgContactEmail = this.state.offer.orgContactEmail

        let orgMode = true
        if (!this.state.activeOrg || this.state.activeOrg === '' || this.state.activeOrg === 'guidedcompass') {
          orgMode = false
        }

        let placementPartners = this.state.selectedJob.placementPartners

        const email = this.state.emailId
        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName

        const updatedAt = new Date()

        Axios.put('/api/applications/offer', {
          _id, decision,
          empContactFirstName, empContactLastName, empContactEmail, orgContactEmail,
          orgMode, placementPartners,
          email, firstName, lastName,
          updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Offer update worked', response.data);
            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Offer response successfully updated! View your logs to review this offer and other career-related events.'
            })

          } else {
            console.error('there was an error updating the offer');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Offer response save did not work', error);
        });
      }
    }

    render() {

        let offerLetterURL = null
        if (this.state.offer) {
          if (this.state.offer.offerLetterURL && this.state.offer.offerLetterURL !== '') {
            if (this.state.offer.offerLetterURL.startsWith('http')) {
              offerLetterURL = this.state.offer.offerLetterURL
            } else {
              offerLetterURL = 'http://' + this.state.offer.offerLetterURL
            }
          }
        }

        let employerURL = null
        if (this.state.employerURL && this.state.employerURL !== '') {
          if (this.state.employerURL.startsWith('http')) {
            employerURL = this.state.employerURL
          } else {
            employerURL = 'http://' + this.state.employerURL
          }
        }

        return (
            <div>
                <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  history={this.props.navigate}/>
                <div className="standard-container-2">

                    {(this.state.offer) && (
                      <div>
                        <div className="row-20">
                          <p className="heading-text-3 cta-color bold-text">{this.state.offer.employerName} Has Made You an Offer!!!</p>
                        </div>

                        <div>
                          <p className="description-text-2 cta-color bold-text">{this.state.offer.employerName} wants you to join as a {this.state.offer.title} starting {this.state.offer.startDate}. Please accept or reject this offer within a week to notify all relevant parties of your intention, keep within your records, and improve our recommendations for you.</p>
                          <p className="description-text-2 cta-color bold-text">***This is not a substiture for signing an offer letter or communicating with the employer directly that you would like to accept or reject your offer.</p>
                        </div>

                        <hr />

                        <div>
                          <p className="heading-text-5">Offer Details</p>
                        </div>

                        <div className="top-padding-15">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">Start Date</label>
                            <p>{this.state.offer.startDate}</p>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">End Date</label>
                            <p>{(this.state.offer.isPerpetual) ? "Continual" : this.state.offer.endDate}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-padding">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">Hours / Week</label>
                            <p>{this.state.offer.hoursPerWeek}</p>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">{(this.state.offer.payType === 'Hourly') ? "Hourly Pay" : "Annual Pay"}</label>
                            <p>${this.state.offer.pay}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-padding">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">On-Site?</label>
                            <p>{this.state.offer.isOnsite}</p>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">Work Address</label>
                            <p>{this.state.offer.workLocation}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-padding">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">Offer Letter URL</label>
                            <a target="_blank" href={offerLetterURL}>{offerLetterURL}</a>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">Expiration Date</label>
                            <p>{this.state.offer.expirationDate}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <hr />

                        <div>
                          <p className="heading-text-5">Employer Info</p>
                        </div>

                        <div className="top-padding-15">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">Employer Website</label>
                            <div className="clear" />
                            <a target="_blank" href={employerURL}>{employerURL}</a>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">Employer Type</label>
                            <p>{this.state.employerType}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="top-padding-15">
                          <div className="container-left">
                            <label className="description-text-2 description-text-color bottom-padding-5">Employer Industry</label>
                            <p>{this.state.employerIndustry}</p>
                          </div>
                          <div className="container-right">
                            <label className="description-text-2 description-text-color bottom-padding-5">Employee Count</label>
                            <p>{this.state.employeeCount}</p>
                          </div>
                          <div className="clear" />
                        </div>

                        <hr />

                        { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                        { (this.state.serverPostSuccess) ? (
                          <p className="success-message">{this.state.serverSuccessMessage}</p>
                        ) : (
                          <div>
                            <p className="error-message">{this.state.serverErrorMessage}</p>

                            <div className="float-left right-margin-15">
                              <button className="btn btn-primary" onClick={() => this.decide('accept')}>Accept Offer</button>
                            </div>
                            <div className="float-left right-margin-15">
                              <button className="btn btn-secondary" onClick={() => this.decide('reject')}>Reject Offer</button>
                            </div>

                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                </div>

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(EditOffer)
