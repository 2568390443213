import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEmployers from './Subcomponents/Employers';
import withRouter from './Functions/WithRouter';

class Employers extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId, activeOrg, orgLogo })
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
            <SubEmployers history={this.props.navigate} activeOrg={this.state.activeOrg} pageSource="BrowseEmployers"/>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Employers)
