import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import SubTimesheet from '../Subcomponents/Timesheet';
import SubOpportunityDetails from '../Subcomponents/OpportunityDetails';
import SubCourseAssignment from '../Common/CourseAssignment';

import {convertDateToString} from '../Functions/ConvertDateToString';

const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const filterIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';

class WorkModeWidget extends Component {
    constructor(props) {
        super(props)

        this.state = {
          editMode: true,
          positionListType: 'Only Active Roles',
          timesheetType: 'All Timesheets',
          timesheetStatus: 'All Timesheets',
          assignmentType: 'All Assignments',
          useCourseAssignments: true,

          hoursPerWeekOptions: [],
          levelOptions: [],
          positionListTypeOptions: ['','All Roles','Only Active Roles'],
          timesheetTypeOptions: [],
          timesheetStatusOptions: ['','All Timesheets','Only Submitted Timesheets','Only Unsubmitted Timesheets'],
          assignmentTypeOptions: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.pullWorkDetails = this.pullWorkDetails.bind(this)
        this.pullTimesheets = this.pullTimesheets.bind(this)
        this.pullAssignments = this.pullAssignments.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.addItem = this.addItem.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.updateData = this.updateData.bind(this)
        this.passStatus = this.passStatus.bind(this)
        this.passData = this.passData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const username = localStorage.getItem('username');
      const pictureURL = localStorage.getItem('pictureURL');
      const roleName = localStorage.getItem('roleName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgName = localStorage.getItem('orgName');

      this.setState({ emailId, cuFirstName, cuLastName, username, pictureURL, roleName, activeOrg, orgName })

      this.retrieveData(emailId)
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubWorkModeWidget', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData(this.state.emailId)
      }
    }

    retrieveData(emailId){
      console.log('retrieveData called in SubWorkModeWidget', this.props)

      this.pullWorkDetails(emailId, this.state.positionListType)
    }

    updateData(type) {
      console.log('updateData called', type)

      this.setState({ errorMessage: null, successMessage: null })
      if (type === 'positions') {
        if (!this.state.positionListType || this.state.positionListType === '') {
          this.setState({ errorMessage: 'Please select an option' })
        } else {
          this.closeModal()
          this.pullWorkDetails(this.state.emailId, this.state.positionListType)
        }
      } else if (type === 'timesheets') {
        if (!this.state.timesheetType || this.state.timesheetType === '') {
          this.setState({ errorMessage: 'Please select an option' })
        } else if (!this.state.timesheetStatus || this.state.timesheetStatus === '') {
          this.setState({ errorMessage: 'Please select an option' })
        } else {
          this.closeModal()
          this.pullTimesheets(this.state.emailId, this.state.timesheetType, this.state.trainings, this.state.timesheetStatus)
        }
      } else if (type === 'assignments') {
        if (!this.state.assignmentType || this.state.assignmentType === '') {
          this.setState({ errorMessage: 'Please select an option' })
        } else {
          this.closeModal()
          this.pullAssignments(this.state.emailId, this.state.assignmentType)
        }
      }
    }

    pullWorkDetails(emailId, positionListType) {
      console.log('pullWorkDetails called', emailId, positionListType)

      // const workIds = response.data.user.workIds
      const workerEmail = emailId

      let onlyCurrent = true
      if (positionListType === 'All Roles') {
        onlyCurrent = null
      }

      const isSimulation = true
      // console.log('hmm???', positionListType, onlyCurrent)
      Axios.get('/api/work', { params: { workerEmail, onlyCurrent, isSimulation } })
      .then((response) => {
        console.log('Work query attempted within employer dashboard', response.data);

        if (response.data.success) {
          console.log('account info query worked in sub settings')

          const trainings = response.data.workArray
          let timesheetTypeOptions = [{ title: '' }, { title: 'All Timesheets'}]
          let assignmentTypeOptions = [{ title: '' }, { title: 'All Assignments'}]
          for (let i = 1; i <= trainings.length; i++) {
            let training = trainings[i - 1]
            training['title'] = training.title + ' Only'
            timesheetTypeOptions.push(training)
            assignmentTypeOptions.push(training)
          }

          this.setState({ trainings, timesheetTypeOptions, assignmentTypeOptions });

          this.pullTimesheets(emailId, this.state.timesheetType, trainings, this.state.timesheetStatus)
          this.pullAssignments(emailId, this.state.assignmentType)

          //pull projects for timesheet project options
          Axios.get('/api/projects', { params: { emailId } })
          .then((response) => {
            console.log('Projects query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved projects')

                if (response.data.projects) {

                  const projects = response.data.projects
                  if (projects.length > 0) {
                    this.setState({ projects })
                  }
                }

              } else {
                console.log('no project data found', response.data.message)
              }
          }).catch((error) => {
              console.log('Project query did not work', error);
          });
        } else {
          this.setState({ trainings: [] })
        }

      }).catch((error) => {
        console.log('Account info query did not work for some reason', error);
      });

     Axios.get('/api/workoptions')
     .then((response) => {
       console.log('Work options query tried', response.data);

       if (response.data.success) {
         console.log('Work options query succeeded')

         let functionOptions = response.data.workOptions[0].functionOptions
         // for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
         //   if (i > 1) {
         //     functionOptions.push(response.data.workOptions[0].functionOptions[i - 1])
         //   }
         // }

         let industryOptions = response.data.workOptions[0].industryOptions
         // for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
         //   if (i > 1) {
         //     industryOptions.push(response.data.workOptions[0].industryOptions[i - 1])
         //   }
         // }

         let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions
         // for (let i = 1; i <= response.data.workOptions[0].hoursPerWeekOptions.length; i++) {
         //   if (i > 1) {
         //     hoursPerWeekOptions.push(response.data.workOptions[0].hoursPerWeekOptions[i - 1])
         //   }
         // }

         let workTypeOptions = []
         for (let i = 1; i <= response.data.workOptions[0].workTypeOptions.length; i++) {
           if (i > 1) {
             workTypeOptions.push(response.data.workOptions[0].workTypeOptions[i - 1])
           }
         }

         let hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
         // for (let i = 1; i <= response.data.workOptions[0].hourlyPayOptions.length; i++) {
         //   if (i > 1) {
         //     hourlyPayOptions.push(response.data.workOptions[0].hourlyPayOptions[i - 1])
         //   }
         // }

         let annualPayOptions = response.data.workOptions[0].annualPayOptions
         // for (let i = 1; i <= response.data.workOptions[0].annualPayOptions.length; i++) {
         //   if (i > 1) {
         //     annualPayOptions.push(response.data.workOptions[0].annualPayOptions[i - 1])
         //   }
         // }

         const levelOptions = ['','Entry-Level','Manager','Director','Executive']
         this.setState({ functionOptions, industryOptions,
           hoursPerWeekOptions, workTypeOptions, hourlyPayOptions, annualPayOptions,
           levelOptions
         })

       } else {
         console.log('no jobFamilies data found', response.data.message)

       }
     }).catch((error) => {
         console.log('query for work options did not work', error);
     })
    }

    pullTimesheets(emailId, timesheetType, trainings, timesheetStatus) {
      console.log('pullTimesheets called', emailId, timesheetType, trainings, timesheetStatus)

      let workIds = null

      if (trainings && trainings.length > 0) {
        workIds = []
        for (let i = 1; i <= trainings.length; i++) {
          if (timesheetType === 'All Timesheets') {
            workIds.push(trainings[i - 1]._id)
          } else if (timesheetType === trainings[i - 1].title){
            workIds.push(trainings[i - 1]._id)
          }
        }
      }

      let isSubmitted = null
      if (timesheetStatus === 'Only Submitted Timesheets') {
        isSubmitted = true
      } else if (timesheetStatus === 'Only Unsubmitted Timesheets') {
        isSubmitted = false
      }

      const tParams = { emailId, workIds, roleName: 'Worker', isSubmitted }
      // console.log("confirm params: ", tParams)
      Axios.get('/api/timesheets', { params: tParams })
      .then((response) => {
        console.log('Timesheets query attempted within employer dashboard', response.data);

        if (response.data.success) {
          console.log('Timesheets query worked in sub settings')

          const timesheets = response.data.timesheets
          if (timesheets) {

            this.setState({ timesheets });
          }
        }

      }).catch((error) => {
        console.log('Timesheets query did not work for some reason', error);
      });
    }

    pullAssignments(emailId, assignmentType) {
      console.log('pullAssignments called', emailId, assignmentType)

      let workIds = null
      if (this.state.trainings && this.state.trainings.length > 0) {
        workIds = []
        for (let i = 1; i <= this.state.trainings.length; i++) {
          if (assignmentType === 'All Assignments') {
            workIds.push(this.state.trainings[i - 1]._id)
          } else if (assignmentType === this.state.trainings[i - 1].title){
            workIds.push(this.state.trainings[i - 1]._id)
          }
        }
      }

      if (window.location.pathname.endsWith('/take') && this.state.useCourseAssignments) {

        const resourceType = 'Assignment'

        Axios.get('/api/resources', { params: { resourceType, creatorEmail: emailId, workIds } })
        .then((response) => {
          console.log('Resource query attempted', response.data);

          if (response.data.success) {
            console.log('resource query worked')

            const assignments = response.data.resources
            this.setState({ assignments })

          } else {
            console.log('resource query did not work', response.data.message)
            pullAssessment(this.props.resourceId)
          }
        }).catch((error) => {
          console.log('Resource query did not work for some reason', error);
        });

        // let aParams = { emailId, postType: 'Assignment', assigneeGroup: 'Private', workIds }
        //
        // Axios.get('/api/assignments', { params: aParams })
        // .then((response) => {
        //   console.log('Assignments query attempted', response.data);
        //
        //   if (response.data.success) {
        //     console.log('assignments query worked')
        //
        //     const assignments = response.data.assignments
        //     if (response.data.assignments) {
        //       this.setState({ assignments })
        //     }
        //
        //   } else {
        //     console.log('found no assignments', response.data.message)
        //
        //   }
        //
        // }).catch((error) => {
        //     console.log('Org info query did not work for some reason', error);
        // });

      } else {

        let aParams = { emailId, postType: 'Assignment', assigneeGroup: 'Private', workIds }

        Axios.get('/api/assignments', { params: aParams })
        .then((response) => {
          console.log('Assignments query attempted', response.data);

          if (response.data.success) {
            console.log('assignments query worked')

            const assignments = response.data.assignments
            if (response.data.assignments) {
              this.setState({ assignments })
            }

          } else {
            console.log('found no assignments', response.data.message)
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, viewWork: false, timesheetInfo: false, submitTimesheet: false,
        showAssignment: false, selectedAssignment: null, selectedIndex: null,
        showPositionFilter: false, showTimesheetFilter: false, showAssignmentFilter: false,
        confirmDelete: false
      })

      // if (this.props.toggleNav) {
      //   this.props.toggleNav(false,'fromCloseModal')
      // }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'trainings') {
        let trainings = this.state.trainings

        let title = 'Sample Job Title'
        let employerName = 'Sample Employer Name'
        let startDate = convertDateToString(new Date(),'rawDateForInput')
        let endDate = convertDateToString(new Date(),'rawDateForInput')
        let isPerpetual = true
        let hoursPerWeek = '~ 20 Hours'
        let timesheetEnabled = true
        if (trainings) {
          trainings.push({ title, employerName, startDate, endDate, isPerpetual, hoursPerWeek, timesheetEnabled })
        } else {
          trainings = [{ title, employerName, startDate, endDate, isPerpetual, hoursPerWeek, timesheetEnabled }]
        }

        // console.log('list trainings: ', trainings)
        this.setState({ trainings })

      } else if (type === 'timesheets') {

        let timesheets = this.state.timesheets
        const defaultPositionIndex = 0

        const workId = this.state.trainings[defaultPositionIndex]._id
        const emailId = this.state.emailId
        const workerFirstName = this.state.cuFirstName
        const workerLastName = this.state.cuLastName
        const managerFirstName = 'A.I.'
        const managerLastName = 'Assistant'
        const managerEmail = 'creightontaylor@gmail.com'
        const workType = this.state.trainings[defaultPositionIndex].workType
        const title = this.state.trainings[defaultPositionIndex].title
        const employerName = this.state.trainings[defaultPositionIndex].employerName
        const accountCode = this.state.trainings[defaultPositionIndex].accountCode
        const orgCode = this.state.activeOrg
        let startDate = convertDateToString(new Date(),'rawDateForInput')

        const isSubmitted = false
        const coordinatorEmail = 'creightontaylor@gmail.com'
        const createdAt = new Date()
        const updatedAt = new Date()

        // const cards = []
        const workStartDate = new Date()

        const oneWeek = 604800000
        // const workEndDate = new Date(new Date() + oneWeek)
        // const workEndDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
        let workEndDate = new Date()
        workEndDate.setDate(workEndDate.getDate() + 7);
        let endDate = convertDateToString(workEndDate,'rawDateForInput')

        const oneDay = 60 * 60 * 24 * 1000

        let timesheetStartDate = workStartDate
        // console.log('compare date: ', oneWeekAgo)
        // if (workStartDate < oneWeekAgo) {
        //   console.log('workStartDate was more than a week ago')
        //   timesheetStartDate = oneWeekAgo
        // } else {
        //   console.log('workStartDate was less than a week ago')
        // }

        // create missing timesheets
        const diffTime = workEndDate - new Date()
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1
        console.log('what is the diffDays', diffDays, workEndDate)
        let cards = []

        function convertDay(numDay) {
          console.log('convert Day called', numDay, typeof numday)

          let stringDay = 'Sun'
          if (numDay === 1) {
            stringDay = 'Mon'
          } else if (numDay === 2) {
            stringDay = 'Tue'
          } else if (numDay === 3) {
            stringDay = 'Wed'
          } else if (numDay === 4) {
            stringDay = 'Thu'
          } else if (numDay === 5) {
            stringDay = 'Fri'
          } else if (numDay === 6) {
            stringDay = 'Sat'
          }

          return stringDay
        }

        for (let j = 0; j < 7; j++) {

          const tempDate = new Date(timesheetStartDate.getTime() + (oneDay * (j + 1)))

          const day = convertDay(tempDate.getDay())
          const month = Number(tempDate.getMonth()) + 1
          const numericDay = tempDate.getDate()
          const year = tempDate.getFullYear()

          const fullDate = day + ' ' + month + '/' + numericDay + '/' + year

          const hours = ''
          const tasks = ''
          cards.push({ day: fullDate, hours, tasks })
          console.log('show values: ', j, tempDate, fullDate, hours, tasks)

        }

        const tObject = {
          workId, emailId, workerFirstName, workerLastName,
          managerFirstName, managerLastName, managerEmail,
          workType, title, employerName, accountCode, orgCode,
          startDate, endDate, cards, isSubmitted, coordinatorEmail,
          createdAt, updatedAt
        }

        if (timesheets) {
          timesheets.push(tObject)
        } else {
          timesheets = [tObject]
        }

        this.setState({ timesheets })

      } else if (type === 'assignments') {

        let assignments = this.state.assignments
        const defaultPositionIndex = 0

        const name = "Sample Name"
        const employerName = "Sample Employer Name"
        const deadline = new Date().toString()
        const duration = 5
        const selectedPlacement = this.state.trainings[0]

        const createdAt = new Date()
        const updatedAt = new Date()

        const tObject = {
          name, employerName, deadline, duration, selectedPlacement,
          createdAt, updatedAt
        }

        if (assignments) {
          assignments.push(tObject)
        } else {
          assignments = [tObject]
        }

        this.setState({ assignments })

      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name.startsWith('trainings|')) {
        let trainings = this.state.trainings
        const index = Number(event.target.name.split("|")[2])
        const name = event.target.name.split("|")[1]
        trainings[index][name] = event.target.value
        this.setState({ trainings })
        // "trainings|startDate|" + this.state.selectedIndex

        if (name === 'employerName') {
          this.searchEmployers(event.target.value)
        }
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/account/search', { params: { employerName } })
        .then((response) => {
          console.log('Employer search query attempted', response.data);

            if (response.data.success) {
              console.log('employer search query worked')

              const employerOptions = response.data.employers
              self.setState({ employerOptions });

            } else {
              console.log('employer search query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Employer search query did not work for some reason', error);
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      let trainings = this.state.trainings
      trainings[this.state.selectedIndex]['employerName'] = employer.employerName
      trainings[this.state.selectedIndex]['employerType'] = employer.employerType
      trainings[this.state.selectedIndex]['employerCount'] = employer.employerCount
      trainings[this.state.selectedIndex]['accountCode'] = employer.accountCode
      trainings[this.state.selectedIndex]['employerIndustry'] = employer.employerIndustry
      trainings[this.state.selectedIndex]['employerURL'] = employer.employerURL
      trainings[this.state.selectedIndex]['employerLogoURL'] = employer.employerLogoURI

      this.setState({ trainings, employerOptions: [] })

    }

    saveChanges() {
      console.log('saveChanges called')
      // search on employerName, accountCode, payRange -> pay
      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const selectedTraining = this.state.trainings[this.state.selectedIndex]

      const _id = selectedTraining._id
      const workType = selectedTraining.workType
      const title = selectedTraining.title
      const employerName = selectedTraining.employerName
      const employerURL = selectedTraining.employerURL
      const accountCode = selectedTraining.accountCode
      const level = selectedTraining.level
      const payType = selectedTraining.payType
      const payRange = selectedTraining.payRange
      const pay = selectedTraining.pay
      const description = selectedTraining.description

      const timesheetEnabled = selectedTraining.timesheetEnabled
      const timesheetStartDay = null
      const startDate = selectedTraining.startDate
      const endDate = selectedTraining.endDate
      const isPerpetual = selectedTraining.isPerpetual
      const workFunction = selectedTraining.workFunction
      const industry = selectedTraining.industry
      const field = selectedTraining.field
      const hoursPerWeek = selectedTraining.hoursPerWeek

      const firstName = this.state.cuFirstName
      const lastName = this.state.cuLastName
      const email = this.state.emailId
      const username = this.state.username
      const pictureURL = this.state.pictureURL
      const roleName = this.state.roleName
      const workers = [{ firstName, lastName, email, username, pictureURL, roleName }]

      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      const isSimulation = true

      const createdAt = new Date()
      const updatedAt = new Date()

      if (!title || title === '') {
        this.setState({ errorMessage: 'please add your title for this role', isSaving: false })
      } else if (!employerName || employerName === '') {
        this.setState({ errorMessage: 'please add a name for the employer', isSaving: false})
      } else if (!startDate || startDate === '') {
        this.setState({ errorMessage: 'please add a start date for this work experience', isSaving: false})
      } else if (!endDate && !isPerpetual) {
        this.setState({ errorMessage: 'please add an end date for this work experience', isSaving: false})
      } else if (endDate === '' && !isPerpetual) {
        this.setState({ errorMessage: 'please add an end date for this work experience', isSaving: false})
      } else if (!hoursPerWeek || hoursPerWeek === '') {
        this.setState({ errorMessage: 'please add the hours per week for this work experience', isSaving: false})
      } else {

        const workObject = {
          _id, workType, title, employerName, employerURL, accountCode, level, isSimulation,
          payType, payRange, pay, description, orgCode, orgName, timesheetEnabled, timesheetStartDay,
          startDate, endDate, isPerpetual,  workFunction, industry, field, hoursPerWeek, workers,
          createdAt, updatedAt
        }

        Axios.post('/api/work', workObject)
        .then((response) => {
          if (response.data.success) {
            this.setState({ isSaving: false, successMessage: 'Your training has been saved' })
            this.closeModal()
          } else {
            console.log('project did not save successfully')
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Project save did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    deleteItem(type) {
      console.log('deleteItem called', type)

      let deletePath = null
      if (type === 'training') {
        deletePath = '/api/placements/' + this.state.trainings[this.state.selectedIndex]._id
      }

      if (deletePath) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })
        console.log('?!?!', deletePath)
        Axios.delete(deletePath)
        .then((response) => {
          console.log('tried to  delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Placement delete worked');

            let trainings = this.state.trainings
            trainings.splice(this.state.selectedIndex,1)

            this.setState({ trainings, successMessage: response.data.message, isSaving: false })
            this.closeModal()

          } else {
            console.error('there was an error deleting the rsvp');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    itemClicked(type, value) {
      console.log('itemClicked called')

      let trainings = this.state.trainings
      trainings[this.state.selectedIndex][type] = value
      this.setState({ trainings })

    }

    passStatus(passedTimesheet, unknownVar, deleteTimesheet) {
      console.log('passStatus called')

      let timesheets = this.state.timesheets

      if (deleteTimesheet) {
        timesheets.splice(this.state.selectedIndex, 1)
      } else {
        timesheets[this.state.selectedIndex] = passedTimesheet
      }

      this.setState({ timesheets })

    }

    passData(passedData, deleteAssignment) {
      console.log('passData called')

      let assignments = this.state.assignments
      if (deleteAssignment) {
        assignments.splice(this.state.selectedIndex, 1)
      } else {
        assignments[this.state.selectedIndex] = passedData
      }

      this.setState({ assignments })
    }

    render() {

      return (
          <div>
            {(window.location.pathname.endsWith('/take') || this.state.trainings) ? (
              <div>

                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                {/*
                <div className="horizontal-padding-4">
                  <div className="float-left">
                    <p className="heading-text-2">Manage Your Work</p>
                  </div>

                  <div className="clear" />
                  <div className="spacer"/><div className="spacer"/>
                </div>*/}

                <div>
                  <div className="col span-1-of-3 box">
                    <div>
                      <div className="calc-column-offset-50 right-padding">
                        <p className="heading-text-5">Positions</p>
                      </div>
                      {(window.location.pathname.endsWith('/take')) && (
                        <span>
                          <div className="fixed-column-35">
                            <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.setState({ modalIsOpen: true, showPositionFilter: true })}>
                              <img src={filterIconSelected} alt="GC" className="image-auto-9" />
                            </button>
                          </div>
                          <div className="fixed-column-15">
                            <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.addItem('trainings')}>
                              <img src={addIconBlue} alt="GC" className="image-auto-9" />
                            </button>
                          </div>
                        </span>
                      )}
                      <div className="clear" />
                    </div>
                    <div className="clear" />

                    {(this.state.trainings && this.state.trainings.length > 0) ? (
                      <div>
                        {this.state.trainings.map((value, index) =>
                          <div className="top-margin-5">
                            <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, updateApplications: false, viewWork: true, selectedIndex: index, viewTimesheetInfo: false, submitTimesheet: false, showInfo: false })}>
                              <div className="row-15 right-padding">
                                <p className="cta-color half-bold-text">{value.title}{(value.employerName) && ' @ ' + value.employerName}</p>
                                {(value.startDate) && (
                                  <div className="description-text-2 top-margin-5">
                                    <label className="right-padding">{value.startDate.substring(0,10)} - {value.isPerpetual ? "Continual" : value.endDate.substring(0,10)}</label>
                                    <label className="right-padding">|</label>
                                    <label className="right-padding">{value.hoursPerWeek} Per Week</label>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            </a>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="description-text-2 top-margin">
                        <p className="error-message">Please place yourself into a simulated role (e.g., internship) to submit timesheets and assignments.</p>
                      </div>
                    )}
                  </div>
                  <div className="col span-1-of-3 box">
                    <div>
                      <div className="calc-column-offset-50 right-padding">
                        <p className="heading-text-5">Timesheets</p>
                      </div>
                      {(window.location.pathname.endsWith('/take') && (this.state.trainings && this.state.trainings.length > 0)) && (
                        <span>
                          <div className="fixed-column-35">
                            <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.setState({ modalIsOpen: true, showTimesheetFilter: true })}>
                              <img src={filterIconSelected} alt="GC" className="image-auto-9" />
                            </button>
                          </div>
                          <div className="fixed-column-15">
                            <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.addItem('timesheets')}>
                              <img src={addIconBlue} alt="GC" className="image-auto-9" />
                            </button>
                          </div>
                        </span>
                      )}
                      <div className="clear" />
                    </div>

                    <div>
                      {(this.state.timesheets && this.state.timesheets.length > 0) ? (
                        <div>
                          {this.state.timesheets.map((ts, tsIndex) =>
                            <div>
                              <a className="background-button" onClick={() => this.setState({ modalIsOpen: true, readyInfo: false, viewWork: false, timesheetInfo: false, submitTimesheet: true, editAssignment: false, selectedIndex: tsIndex, showInfo: false })}>
                                {(ts.startDate && ts.endDate) && (
                                  <div className="row-15 right-padding">
                                    <p className="cta-color half-bold-text">{ts.startDate.substring(5,10).replace(/-/g,"/") + '/' + ts.startDate.substring(0,4).replace(/-/g,"/")} - {ts.isPerpetual ? "Continual" : ts.endDate.substring(5,10).replace(/-/g,"/") + '/' + ts.endDate.substring(0,4).replace(/-/g,"/")}</p>

                                    <div className="description-text-2 top-margin-5">
                                      <label className="right-padding">{ts.title} @ {ts.employerName.replace("'",'')}</label>

                                      <div className="clear" />
                                      {(ts.isSubmitted === true) ? (
                                        <div>
                                          {(ts.isApproved) ? (
                                            <label className="cta-color right-padding">Approved!</label>
                                          ) : (
                                            <label className="error-color right-padding">Submitted</label>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {(ts.isRejected) ? (
                                            <label className="cta-color right-padding">Denied</label>
                                          ) : (
                                            <label className="error-color right-padding">Not Submitted</label>
                                          )}
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  </div>
                                )}
                              </a>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="description-text-2 top-margin">
                          <p className="error-message">There are no timesheets available to submit.</p>
                        </div>
                      )}

                    </div>

                  </div>
                  <div className="col span-1-of-3 box">
                    <div>
                      <div>
                        <div className="calc-column-offset-50 right-padding">
                          <p className="heading-text-5">Assignments</p>
                        </div>
                        {(window.location.pathname.endsWith('/take') && (this.state.trainings && this.state.trainings.length > 0)) && (
                          <span>
                            <div className="fixed-column-35">
                              <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.setState({ modalIsOpen: true, showAssignmentFilter: true })}>
                                <img src={filterIconSelected} alt="GC" className="image-auto-9" />
                              </button>
                            </div>
                            <div className="fixed-column-15">
                              <button className="background-button padding-5 cta-border circle-radius" onClick={() => this.addItem('assignments')}>
                                <img src={addIconBlue} alt="GC" className="image-auto-9" />
                              </button>
                            </div>
                          </span>
                        )}
                        <div className="clear" />
                      </div>

                      <div className="clear" />
                    </div>

                    {(this.state.assignments && this.state.assignments.length > 0) ? (
                      <div className="top-margin-5">
                        {this.state.assignments.map((value, index) =>
                          <div key={"assignment|" + index}>
                            {(value.name || value.title) && (
                              <button onClick={() => this.setState({ modalIsOpen: true, showAssignment: true, selectedAssignment: value, selectedIndex: index })} className="background-button left-text">
                                <div className="row-15 right-padding">
                                  <p className="cta-color half-bold-text">{(value.title) ? value.title : value.name}</p>

                                  <div className="description-text-2 top-margin-5">
                                    <label className="right-padding">{(value.selectedPlacement) ? value.selectedPlacement.employerName : value.employerName}</label>

                                    <label className="right-padding">|</label>
                                    {(value.deadline) && (
                                      <span>
                                        <label className="right-padding">Deadline: {value.deadline.substring(0,10)}</label>
                                        <label className="right-padding">|</label>
                                      </span>
                                    )}

                                    <label className="right-padding">{value.duration} Estimated Hours</label>
                                    <div className="clear" />
                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="description-text-2 top-margin">
                        <p className="error-message">There are no outstanding assignments.</p>
                      </div>
                    )}

                  </div>

                  <div className="clear" />
                </div>
              </div>
            ) : (
              <div>
                <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                <p className="error-message">We were not able to find any work that you were assigned to.</p>
                <div className="spacer"/>
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className={(this.state.showFullView) ? "modal-full-space-2" : "modal"}
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.viewWork) && (
              <div className="full-width padding-30">

                <div>
                  <div className="calc-column-offset-15 right-padding">
                    <p className="heading-text-2">{this.state.trainings[this.state.selectedIndex].title}</p>
                    {(!this.state.editMode) && (
                      <p>{this.state.trainings[this.state.selectedIndex].workType} @ <a target="_blank" href={this.state.trainings[this.state.selectedIndex].employerURL}>{this.state.trainings[this.state.selectedIndex].employerName}</a></p>
                    )}
                  </div>
                  <div className="fixed-column-15">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15 pin-right top-margin" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" />

                {(this.state.editMode) && (
                  <div>
                    <div className="row-10">
                      <div className="container-left">
                        <label className="description-text-2 top-padding">Job Title</label>
                        <input className="text-field" type="text" placeholder="(e.g., Software Engineer)" name={"trainings|title|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].title} onChange={this.formChangeHandler} />
                      </div>
                      <div className="container-right">
                        <label className="description-text-2 top-padding">Employer Name</label>
                        <input className="text-field" type="text" placeholder="(e.g., Google)" name={"trainings|employerName|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].employerName} onChange={this.formChangeHandler} />

                        {(this.state.employerOptions && this.state.employerOptions.length > 0) && (
                          <div>
                            <div className="spacer" />
                            {this.state.employerOptions.map(value =>
                              <div key={value._id} className="row-5">
                                <button className="background-button" onClick={() => this.employerClicked(value)}>
                                  <div>
                                    <div className="float-left right-padding">
                                      <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                                    </div>
                                    <div className="float-left">
                                      <p className="cta-color">{value.employerName}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>)}
                          </div>
                        )}
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="description-text-2 top-padding">Employer Website</label>
                        <input className="text-field" type="text" placeholder="(e.g., https://google.com)" name={"trainings|employerURL|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].employerURL} onChange={this.formChangeHandler} />
                        {(this.state.trainings[this.state.selectedIndex].employerURL && !this.state.trainings[this.state.selectedIndex].employerURL.startsWith('http')) && (
                          <div>
                            <p className="error-color description-text-2 row-5">Please start the website with http</p>
                          </div>
                        )}
                      </div>
                      <div className="container-right">
                        <label className="description-text-2 top-padding">Levels</label>
                        <select name={"trainings|level|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].level} onChange={this.formChangeHandler}>
                          {this.state.levelOptions.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="description-text-2 top-padding">Start Date</label>
                    {(this.state.editMode) ? (
                      <input type="date" className="date-picker white-background" name={"trainings|startDate|" + this.state.selectedIndex} value={(this.state.trainings[this.state.selectedIndex].startDate) ? convertDateToString(new Date(this.state.trainings[this.state.selectedIndex].startDate), "rawDateForInput") : ""} onChange={this.formChangeHandler}></input>
                    ) : (
                      <p>{this.state.trainings[this.state.selectedIndex].startDate.substring(0,10)}</p>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="description-text-2 top-padding">{(this.state.editMode) ? "Will this be active in perpetuity?" : "End Date"}</label>
                    <div className="clear" />
                    {(this.state.editMode) ? (
                      <div>
                        <div className="spacer" />
                        <Switch
                          onChange={(change) => this.itemClicked('isPerpetual', change)}
                          checked={this.state.trainings[this.state.selectedIndex].isPerpetual}
                          id="normal-switch"
                        />
                      </div>
                    ) : (
                      <div>
                        {(this.state.trainings[this.state.selectedIndex].isPerpetual) ? (
                          <div>
                            <p>Is Continual</p>
                          </div>
                        ) : (
                          <div>
                            <p>{this.state.trainings[this.state.selectedIndex].endDate.substring(0,10)}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.editMode && !this.state.trainings[this.state.selectedIndex].isPerpetual) && (
                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="description-text-2 top-padding">End Date</label>
                      <div className="clear" />
                      <input type="date" className="date-picker white-background" name={"trainings|endDate|" + this.state.selectedIndex} value={(this.state.trainings[this.state.selectedIndex].startDate) ? convertDateToString(new Date(this.state.trainings[this.state.selectedIndex].endDate), "rawDateForInput") : ""} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="description-text-2 top-padding">{(this.state.editMode) ? "Work Function" : "Field"}</label>
                    {(this.state.editMode) ? (
                      <select name={"trainings|workFunction|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].workFunction} onChange={this.formChangeHandler}>
                        {this.state.functionOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    ) : (
                      <p>{this.state.trainings[this.state.selectedIndex].field}</p>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="description-text-2 top-padding">Hours Per Week</label>
                    {(this.state.editMode) ? (
                      <select name={"trainings|hoursPerWeek|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].hoursPerWeek} onChange={this.formChangeHandler}>
                        {this.state.hoursPerWeekOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    ) : (
                      <p>{this.state.trainings[this.state.selectedIndex].hoursPerWeek}</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.editMode) && (
                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="description-text-2 top-padding">Industry</label>
                      <select name={"trainings|industry|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].industry} onChange={this.formChangeHandler}>
                        {this.state.industryOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    {(window.location.pathname.endsWith('/take')) && (
                      <div className="container-right">
                        <label className="description-text-2 top-padding">Auto-Create Timesheets Weekly</label>
                        <div className="clear" />

                        <div className="spacer" />

                        <Switch
                          onChange={(change) => this.itemClicked('timesheetEnabled', change)}
                          checked={this.state.trainings[this.state.selectedIndex].timesheetEnabled}
                          id="normal-switch"
                        />
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                )}

                {(!window.location.pathname.endsWith('/take')) && (
                  <div>
                    <div className="spacer" /><div className="spacer" />
                    <hr className="clear-margin clear-padding"/>
                    <div className="spacer" />

                    <div className="edit-profile-row">
                      <div className="container-left">
                        <label className="description-text-2 top-padding">Hiring Manager First Name</label>
                        {(this.state.editMode) ? (
                          <input className="text-field" type="text" placeholder="(e.g., John)" name={"trainings|hiringManagerFirstName|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].hiringManagerFirstName} onChange={this.formChangeHandler} />
                        ) : (
                          <p>{this.state.trainings[this.state.selectedIndex].hiringManagerFirstName}</p>
                        )}
                      </div>
                      <div className="container-right">
                        <label className="description-text-2 top-padding">Hiring Manager Last Name</label>
                        {(this.state.editMode) ? (
                          <input className="text-field" type="text" placeholder="(e.g., Jackson)" name={"trainings|hiringManagerLastName|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].hiringManagerLastName} onChange={this.formChangeHandler} />
                        ) : (
                          <p>{this.state.trainings[this.state.selectedIndex].hiringManagerLastName}</p>
                        )}
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(!this.state.editMode) && (
                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="description-text-2 top-padding">Location</label>
                      <p>{this.state.trainings[this.state.selectedIndex].location}</p>
                    </div>
                    <div className="container-right">
                      <label className="description-text-2 top-padding">Affiliated Organization</label>
                      <p>{this.state.trainings[this.state.selectedIndex].orgName}</p>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="description-text-2 top-padding">Pay Type</label>
                    {(this.state.editMode) ? (
                      <select name={"trainings|payType|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].payType} onChange={this.formChangeHandler}>
                        {['','Hourly','Annual'].map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    ) : (
                      <p>{this.state.trainings[this.state.selectedIndex].payType}</p>
                    )}
                  </div>
                  <div className="container-right">
                    {(window.location.pathname.endsWith('/take')) ? (
                      <div>
                        <label className="description-text-2 top-padding">Pay</label>
                        <div className="clear" />
                        <div className="fixed-column-22 standard-border light-background height-40 center-text flex-container flex-center">
                          <div className="mini-spacer"/><div className="mini-spacer"/>
                          <p className="heading-text-4 cta-color bold-text full-width">$</p>
                        </div>
                        <div className="calc-column-offset-22 float-right">
                          <input type="number" className="number-field cta-color height-40" placeholder="0" name={"trainings|pay|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].pay} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div>
                        <label className="description-text-2 top-padding">Pay Range</label>
                        {(this.state.editMode) ? (
                          <div>
                            {(this.state.trainings[this.state.selectedIndex].payType === 'Hourly') ? (
                              <select name={"trainings|payRange|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].payRange} onChange={this.formChangeHandler}>
                                {this.state.hourlyPayOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            ) : (
                              <select name={"trainings|payRange|" + this.state.selectedIndex} className="dropdown" value={this.state.trainings[this.state.selectedIndex].payRange} onChange={this.formChangeHandler}>
                                {this.state.annualPayOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            )}
                          </div>
                        ) : (
                          <p>{this.state.trainings[this.state.selectedIndex].payRange}</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {(window.location.pathname.endsWith('/take')) && (
                  <div className="row-10">
                    <label className="description-text-2 top-padding">Job Description</label>
                    <textarea className="text-field" type="text" placeholder="(e.g., Responsibilities, Requirements, Desirables, etc...)" name={"trainings|description|" + this.state.selectedIndex} value={this.state.trainings[this.state.selectedIndex].description} onChange={this.formChangeHandler} />
                  </div>
                )}

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                {(window.location.pathname.endsWith('/take')) && (
                  <div className="top-margin-20">
                    {(this.state.confirmDelete) ? (
                      <div>
                        <p className="row-5">Are you sure you want to delete this position?</p>
                        <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteItem('training')}>Yes, Delete Permanently</button>
                        <button className="btn btn-squarish-white" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                      </div>
                    ) : (
                      <div>
                        <button className="btn btn-primary right-margin" onClick={() => this.saveChanges()}>Save Changes</button>
                        {(this.state.trainings[this.state.selectedIndex]._id) && (
                          <button className="btn btn-quaternary right-margin" onClick={() => this.setState({ confirmDelete: true })}>Delete Training</button>
                        )}
                      </div>
                    )}
                  </div>
                )}

              </div>
            )}

            {(this.state.timesheetInfo) && (
              <div className="full-width padding-30">

                <div>
                  <div className="calc-column-offset-30">
                    <p className="heading-text-2">Timesheet Info</p>
                  </div>
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15 pin-right top-margin" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="spacer" />
                <p>Weekly timesheets will appear here on Sunday until the end of the work period or until your employer disables them.</p>

                <div className="spacer"/>
              </div>
            )}

            {(this.state.submitTimesheet) && (
              <div className="full-width padding-30">
                <SubTimesheet selectedTimesheet={this.state.timesheets[this.state.selectedIndex]} roleName="Worker" assignmentOptions={this.state.assignments} projectOptions={this.state.projects} passStatus={this.passStatus} closeModal={this.closeModal} positionOptions={this.state.trainings} selectedPlacement={this.state.trainings[0]} />
              </div>
            )}

            {(this.state.showAssignment) && (
              <div className="full-width">
                <div className="float-right">
                  <button className="background-button" onClick={() => this.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-15" />
                  </button>
                </div>
                <div className="clear" />

                {(window.location.pathname.endsWith('/take') && this.state.useCourseAssignments) ? (
                  <SubCourseAssignment selectedAssignment={this.state.selectedAssignment} selectedSubmission={this.state.selectedSubmission} activeOrg={this.state.activeOrg} inModal={true} closeModal={this.closeModal} attachmentOptions={this.props.attachmentOptions} workMode={true} placementOptions={this.state.trainings} passData={this.passData} selectedCourse={this.props.selectedCourse} selectedLesson={this.props.selectedLesson} moduleIndex={this.props.moduleIndex} lessonIndex={this.props.lessonIndex} />
                ) : (
                  <SubOpportunityDetails selectedOpportunity={this.state.selectedAssignment} path={window.location.pathname}
                  opportunityId={this.state.selectedAssignment._id} activeOrg={this.state.activeOrg} inModal={true} />
                )}
              </div>
            )}

            {(this.state.showPositionFilter) && (
              <div className="full-width padding-20">
                <div className="calc-column-offset-15">
                  <p className="heading-text-2">Filter Positions</p>
                </div>
                <div className="fixed-column-15">
                  <button className="background-button" onClick={() => this.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-15" />
                  </button>
                </div>
                <div className="clear" />

                <div className="row-20">
                  <p className="description-text-2 row-10">Which positions would you like to list?</p>
                  <select name="positionListType" className="dropdown" value={this.state.positionListType} onChange={this.formChangeHandler}>
                    {this.state.positionListTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                <div className="spacer" />

                <button className="btn btn-primary right-margin" onClick={() => this.updateData('positions')}>Update Positions</button>

              </div>
            )}

            {(this.state.showTimesheetFilter) && (
              <div className="full-width padding-20">
                <div className="calc-column-offset-15">
                  <p className="heading-text-2">Filter Timesheets</p>
                </div>
                <div className="fixed-column-15">
                  <button className="background-button" onClick={() => this.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-15" />
                  </button>
                </div>
                <div className="clear" />
                <p className="description-text-2 row-10">Which timesheets would you like listed?</p>

                <div className="row-20">
                  <div className="row-10">
                    <p className="description-text-2 row-10">Timesheets for which positions?</p>
                    <select name="timesheetType" className="dropdown" value={this.state.timesheetType} onChange={this.formChangeHandler}>
                      {this.state.timesheetTypeOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                    </select>
                  </div>

                  <div className="row-10">
                    <p className="description-text-2 row-10">Submisssion status of timehssets</p>
                    <select name="timesheetStatus" className="dropdown" value={this.state.timesheetStatus} onChange={this.formChangeHandler}>
                      {this.state.timesheetStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  </div>
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                <div className="spacer" />

                <button className="btn btn-primary right-margin" onClick={() => this.updateData('timesheets')}>Update Timesheets</button>
              </div>
            )}

            {(this.state.showAssignmentFilter) && (
              <div className="full-width padding-20">
                <div className="calc-column-offset-15">
                  <p className="heading-text-2">Filter Assignments</p>
                </div>
                <div className="fixed-column-15">
                  <button className="background-button" onClick={() => this.closeModal()}>
                    <img src={closeIcon} alt="GC" className="image-auto-15" />
                  </button>
                </div>
                <div className="clear" />
                <p className="description-text-2 row-10">Which assignments would you like listed?</p>

                <div className="row-20">
                  <div className="row-10">
                    <p className="description-text-2 row-10">Assignments for which positions?</p>
                    <select name="assignmentType" className="dropdown" value={this.state.assignmentType} onChange={this.formChangeHandler}>
                      {this.state.assignmentTypeOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                    </select>
                  </div>
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                <div className="spacer" />

                <button className="btn btn-primary right-margin" onClick={() => this.updateData('assignments')}>Update Assignments</button>
              </div>
            )}
           </Modal>
          </div>

      )
    }
}

export default WorkModeWidget;
