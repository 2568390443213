import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSettings from './Subcomponents/Settings';
import withRouter from './Functions/WithRouter';

class EmpSettings extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.passActiveOrg = this.passActiveOrg.bind(this)
      this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      const { emp } = this.props.params

      console.log('show accountCode: ', emp, email)

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        let timeframeOptions = []
        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        const seasons = ['Spring','Summer','Fall','Winter']
        let currentSeason = ''
        if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
          currentSeason = seasons[0]
        } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
          currentSeason = seasons[1]
        } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
          currentSeason = seasons[2]
        } else if (currentMonth === 9 || currentMonth === 10 || currentMonth === 11) {
          currentSeason = seasons[3]
        }

        for (let i = 1; i <= 4; i++) {
          if (i === 1) {
            timeframeOptions.push(currentSeason + ' ' + currentYear)
          } else {
            // not currentSeason
            const index = seasons.indexOf(currentSeason)
            if (index + i - 1 > 3) {
              const nextYear = currentYear + 1
              const newIndex = (index + i - 1) - 4
              timeframeOptions.push(seasons[newIndex] + ' ' +  nextYear)
            } else {
              timeframeOptions.push(seasons[index + i - 1] + ' ' + currentYear)
            }
          }
        }
        timeframeOptions.push("Ad-Hoc / As-Needed")

        this.setState({
            emailId: email, username, emp, activeOrg, orgMode, orgLogo, timeframeOptions
        });
      }
    }

    passActiveOrg(org) {
      console.log('passActiveOrg called', org)

      this.setState({ org })

    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

          <div className="standard-container-2">
            <SubSettings email={this.state.emailId} accountCode={this.state.emp} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} history={this.props.navigate}  />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}


export default withRouter(EmpSettings)
