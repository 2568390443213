import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditPlacement from '../Subcomponents/EditPlacement';
import withRouter from '../Functions/WithRouter';

class OrgEditPlacement extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params
      // const { selectedPlacement } = this.props.location.state;

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let selectedPlacement = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedPlacement = this.props.location.state.selectedPlacement;
        duplicate = this.props.location.state.duplicate;
      }
      console.log('show selectedPlacement: ', selectedPlacement)
      //obtain email id from localStorage
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, orgLogo,
        selectedPlacement, duplicate
      })
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          <SubEditPlacement selectedPlacement={this.state.selectedPlacement} activeOrg={this.state.org} history={this.props.navigate} duplicate={this.state.duplicate} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditPlacement)
