import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubCourseDetails from '../components/Subcomponents/CourseDetails';
import withRouter from '../components/Functions/WithRouter';

class CourseDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('counselors page just mounted')
      window.scrollTo(0, 0)

      const { id, orgCode } = this.props.params
      this.setState({ id, orgCode })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCourseDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.id !== prevProps.params.id) {
        this.setState({ id: this.props.params.id })
      } else if (this.props.params && this.props.params.orgCode !== prevProps.params.orgCode) {
        this.setState({ orgCode: this.props.params.orgCode })
      }
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"courseDetailsPage"} />
              <SubCourseDetails courseId={this.state.id} orgCode={this.state.orgCode} />

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(CourseDetailsPage);
