import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubCourseDetails from '../Subcomponents/CourseDetails';
import withRouter from '../Functions/WithRouter';

class OrgCourseDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const {org,id} = this.props.params

      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ org, id, orgLogo })

    }

    render() {

        return (
            <div>
                <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

                <SubCourseDetails courseId={this.state.id} history={this.props.navigate} />
                <div className="clear" />

                {(this.state.org) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                  </div>
                )}
            </div>
        )
    }
}


export default withRouter(OrgCourseDetails)
