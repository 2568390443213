import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import SubRenderOpportunities from '../Common/RenderOpportunities';
import SubRenderProfiles from '../Common/RenderProfiles';
import SubRenderCourses from '../Common/RenderCourses';
import SubEndorsementDetails from '../Common/EndorsementDetails';
import withRouter from '../Functions/WithRouter';
import {requestConnection} from '../Services/FriendRoutes';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const approvedIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/approved-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const checkmarkIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png";
const favoritesIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-white.png";
const shareIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png";

class BenchmarkDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          subNavSelected: 'Ideal Profile',
          totalPercent: 100,
          // benchmarkCategories: ['Overall Weights','Work Preferences','Interests','Personality','Values','Skills','Endorsements','Education','Projects','Experience','Interview'],
          benchmarkCategories: ['All','Weights','Work Preferences SA','Interests SA','Personality SA','Values SA','Skills SA','Endorsements','Education','Projects','Experience','Interview','Diversity'],
          subNavCategories: ['Ideal Profile','About','People','Courses','Work'],
          favorites: [],
          newFavorites: [],
          showSubFunction: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.translateValue = this.translateValue.bind(this)
        this.translateWeight = this.translateWeight.bind(this)
        this.translateTraits = this.translateTraits.bind(this)
        this.renderAllocation = this.renderAllocation.bind(this)
        this.returnColorClass = this.returnColorClass.bind(this)
        this.prepareEndorsement = this.prepareEndorsement.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.followPerson = this.followPerson.bind(this)
        this.pullCourses = this.pullCourses.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.selectedTemplate !== prevProps.selectedTemplate) {
        // if (!prevProps.selectedTemplate) {
        //   this.retrieveData()
        // } else if (this.props.selectedTemplate.title !== prevProps.selectedTemplate) {
        //   this.retrieveData()
        // }
      } else if (this.props.benchmarkId !== prevProps.benchmarkId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subBenchmarkDetails', this.props)
      // greeting, communication, posture and eye contact, appearance, behavioral questions, interest / motivation, competencies

      // const org = this.props.activeOrg
      const accountCode = this.props.accountCode
      const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let org = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let isLoading = true

      let subNavCategories = this.state.subNavCategories
      let subNavSelected = 'Ideal Profile'
      if (window.location.pathname.includes('/pages/')) {
        subNavCategories = this.state.benchmarkCategories
        subNavSelected = 'All'
      }
      this.setState({ isLoading, orgCode: org, selectedTemplate, emailId: email, email, username, cuFirstName, cuLastName, subNavCategories, subNavSelected })

      Axios.get('/api/account', { params: { accountCode } })
      .then((response) => {
        console.log('Account info query attempted within sub settings', response.data);

        if (response.data.success) {
          console.log('account info query worked in sub settings')

          const employerName = response.data.accountInfo.employerName
          const employerLogoURI = response.data.accountInfo.employerLogoURI
          this.setState({ employerName, employerLogoURI });

        }

      }).catch((error) => {
        console.log('Account info query did not work for some reason', error);
      });

      Axios.get('/api/favorites', { params: { emailId: email } })
     .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites
           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }
           this.setState({ favorites, newFavorites })

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

      if (this.props.benchmarkId) {
        Axios.get('/api/benchmarks/byid', { params: { _id: this.props.benchmarkId } })
        .then((response) => {
          console.log('Benchmarks query attempted', response.data);

          if (response.data.success) {
            console.log('benchmark query worked')

            const selectedBenchmark = response.data.benchmark
            this.setState({ selectedBenchmark, isLoading: false })

            let selectedApplication = null
            let selectedJob = null
            const _id = selectedBenchmark._id
            const firstName = 'Jon'
            const lastName = 'Doe'
            const email = 'fakeemail@email.com'
            const schoolName = 'Sample School'
            const gradYear = new Date().getFullYear()
            const pictureURL = 'https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB'
            const major = 'Business - Sample'

            let paidExperienceHours = 0
            if (selectedBenchmark.experienceHours) {
              if (Number(selectedBenchmark.experienceHours)) {
                paidExperienceHours = Number(selectedBenchmark.experienceHours)
              } else if (selectedBenchmark.experienceHours.includes('-')){
                const nameArray = selectedBenchmark.experienceHours.split('-')
                const num1 = Number(nameArray[0].trim().replace(/,/g,""))
                const num2 = Number(nameArray[1].trim().replace(/,/g,""))
                let avg = (num1 + num2) / 2
                console.log('show avg', avg, nameArray, num1, num2)
                paidExperienceHours = avg
              } else if (selectedBenchmark.experienceHours.includes('+')){
                const nameArray = selectedBenchmark.experienceHours.split('+')
                paidExperienceHours = Number(nameArray[0].trim().replace(/,/g,""))
              }
            }

            let volunteerHours = 0
            if (selectedBenchmark.volunteerHours) {
              if (Number(selectedBenchmark.volunteerHours)) {
                volunteerHours = Number(selectedBenchmark.volunteerHours)
              } else if (selectedBenchmark.volunteerHours.includes('-')){
                const nameArray = selectedBenchmark.volunteerHours.split('-')
                const num1 = Number(nameArray[0].trim().replace(/,/g,""))
                const num2 = Number(nameArray[1].trim().replace(/,/g,""))
                let avg = (num1 + num2) / 2
                volunteerHours = avg
              } else if (selectedBenchmark.volunteerHours.includes('+')){
                const nameArray = selectedBenchmark.volunteerHours.split('+')
                volunteerHours = Number(nameArray[0].trim().replace(/,/g,""))
              }
            }

            const experienceHours = paidExperienceHours
            let projectHours = 0
            if (selectedBenchmark.totalHours) {
              if (Number(selectedBenchmark.projectHours)) {
                projectHours = Number(selectedBenchmark.projectHours)
              } else if (selectedBenchmark.projectHours.includes('-')){
                const nameArray = selectedBenchmark.projectHours.split('-')
                const num1 = Number(nameArray[0].trim().replace(/,/g,""))
                const num2 = Number(nameArray[1].trim().replace(/,/g,""))
                let avg = (num1 + num2) / 2
                projectHours = avg
              } else if (selectedBenchmark.projectHours.includes('+')){
                const nameArray = selectedBenchmark.projectHours.split('+')
                projectHours = Number(nameArray[0].trim().replace(/,/g,""))
              }
            }

            const totalHours = experienceHours + projectHours + experienceHours
            console.log('show hors: ', totalHours, experienceHours, projectHours, volunteerHours, selectedBenchmark.experienceHours)

            const paidExperienceCount = 2
            const volunteerExperienceCount = 2
            const projectCount = 2

            // const interviewValue = selectedTemplate.interview.weight
            const interview = selectedBenchmark.interview
            const interviewDetails = selectedBenchmark.interviewDetails

            let workPreferenceResults = null
            let interestResults = selectedBenchmark.interests
            let personalityResults = selectedBenchmark.traits
            let skillScores = []
            let skillTraits = []

            let intangiblesText = ''
            const passions = selectedBenchmark.passions

            //workPreferenceResults
            workPreferenceResults = ["['Design']","['Retail','Music', 'Information','Food Services','Fashion']","['Work for a startup or small-sized company']","Graphic Design Intern, Design Intern","n/a","Yes","15 miles","['~ 20 Miles']"]
            workPreferenceResults[6] = selectedBenchmark.proximityRequirements
            workPreferenceResults[7] = selectedBenchmark.maxPay

            interestResults = []
            for (let i = 1; i <= selectedBenchmark.interests.length; i++) {
              const name = selectedBenchmark.interests[i - 1].title
              const description = selectedBenchmark.interests[i - 1].description
              const tempScore = Number(selectedBenchmark.interests[i - 1].score)
              // const score = Math.ceil(tempScore/5)*5
              const score = tempScore * 20 * 0.4

              interestResults.push({ name, description, score })
            }

            let tempOpennessScore = selectedBenchmark.traits[0].score
            let opennessScore = tempOpennessScore * 20 * (16/100)

            const conscientiousnessScore = selectedBenchmark.traits[1].score * 20 * (16/100)
            const extraversionScore = selectedBenchmark.traits[2].score * 20 * (16/100)
            const agreeablenessScore = selectedBenchmark.traits[3].score * 20 * (16/100)
            const neuroticismScore = selectedBenchmark.traits[4].score * 20 * (16/100)

            let myersBriggs = 'ENTJ'
            if (selectedBenchmark.myersBriggs) {
              myersBriggs = selectedBenchmark.myersBriggs
            }

            personalityResults = {
              myersBriggs, fiveFactors: {
                opennessScore, conscientiousnessScore, extraversionScore, agreeablenessScore, neuroticismScore
              }
            }

            intangiblesText = 'This person is most interested in engineering careers and least interested in design careers. Their personality is high in openness and low in emotional stability.'

            if (selectedBenchmark.skills && selectedBenchmark.skills.length > 0) {
              skillScores = []
              let hardSkillCounter = 0
              let softSkillCounter = 0
              for (let i = 1; i <= selectedBenchmark.skills.length; i++) {
                let selectedSkill = selectedBenchmark.skills[i - 1]
                console.log('show selectedSkill: ', selectedSkill)
                skillScores.push({ title: selectedSkill.title, skillType: selectedSkill.skillType, score: 5 })
                if (selectedSkill.skillType === 'Hard Skill') {
                  hardSkillCounter = hardSkillCounter + 1
                  if (hardSkillCounter < 7) {
                    skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                  }
                } else {
                  softSkillCounter = softSkillCounter + 1
                  if (hardSkillCounter < 7) {
                    skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                  }
                }
              }
            }
            // console.log('show skillTraits: ', skillScores)

            if (selectedBenchmark.traits && selectedBenchmark.traits.length > 0) {
              for (let i = 1; i <= selectedBenchmark.traits.length; i++) {
                let selectedTrait = selectedBenchmark.traits[i - 1]
                // console.log('show selectedSkill: ', selectedSkill)
                skillTraits.push({ name: selectedTrait.title, skillType: 'Trait', rating: 5 })
              }
            }

            let endorsements = []

            //education
            const degreeRequirements = selectedBenchmark.degreeRequirements
            // const idealMajors = selectedBenchmark.idealMajors  pullfromabove
            const gpaRange = selectedBenchmark.gpaRange
            const gradYearRange = selectedBenchmark.gradYearRange
            const testScores = selectedBenchmark.testScores
            const courseHours = selectedBenchmark.courseHours

            //politics
            const politicalParties = selectedBenchmark.politicalParties //
            const politicalAlignment = selectedBenchmark.politicalAlignment
            const hometown = selectedBenchmark.hometown
            const homeCongressionalDistrict = selectedBenchmark.homeCongressionalDistrict

            //diversity
            const gender = selectedBenchmark.gender
            const race = selectedBenchmark.race
            const veteran = selectedBenchmark.veteran
            const lowIncome = selectedBenchmark.lowIncome
            const fosterYouth = selectedBenchmark.fosterYouth
            const homeless = selectedBenchmark.homeless
            const incarcerated = selectedBenchmark.incarcerated
            const lgbtqia = selectedBenchmark.lgbtqia
            const disability = selectedBenchmark.disability
            const firstGenImmigrant = selectedBenchmark.firstGenImmigrant
            const firstGenCollegeStudent = selectedBenchmark.firstGenCollegeStudent

            const match = 100

            const createdAt = new Date()
            const updatedAt = new Date()

            selectedApplication = {
              _id, firstName, lastName, email, schoolName, pictureURL, endorsements, interview, interviewDetails,
              politicalAlignment, hometown, homeCongressionalDistrict, match, createdAt, updatedAt,
              gradYear, major, skillScores,
              paidExperienceCount, volunteerExperienceCount, projectCount,
              experienceHours, paidExperienceHours, volunteerHours, projectHours, totalHours, intangiblesText,
              personalityResults, passions, degreeRequirements, gpaRange, gradYearRange, testScores, courseHours,
              politicalParties, gender, race, veteran, lowIncome, fosterYouth, homeless, incarcerated, lgbtqia,
              disability, firstGenImmigrant, firstGenCollegeStudent
            }

            selectedJob = { title: selectedBenchmark.jobTitle, workFunction: selectedBenchmark.jobFunction }
            // console.log('show the title: ', selectedJob.title)

            // let cat2 = 'Work Preferences (0%)'
            // if (selectedBenchmark.workPreferenceWeight) {
            //   cat2 = 'Work Preferences (' + selectedBenchmark.workPreferenceWeight + '%)'
            // }
            //
            // let cat3 = 'Work Preferences (0%)'
            // if (selectedBenchmark.workPreferenceWeight) {
            //   cat3 = 'Work Preferences (' + selectedBenchmark.workPreferenceWeight + '%)'
            // }
            //
            // const benchmarkCategories = [
            //   'All', cat2,
            //   'Interests',
            //   'Personality',
            //   'Values',
            //   'Skills','Endorsements','Education','Projects','Experience','Interview'
            // ]
            // let weights = [
            //   { name: 'Work Preferences', description: 'Candidates interested in relevant industries and employer attributes are awarded maximum points.', ref: 'preferencesPercent', value: this.state.selectedBenchmark.workPreferenceWeight },
            //   { name: 'Work Interests', description: 'Candidates interested in careers that require relevant skills are awarded maximum points.', ref: 'interestsPercent', value: this.state.selectedBenchmark.interestsWeight },
            //   { name: 'Personality', description: 'Candidates whose personality most matches the sought after type are awarded maximum points.', ref: 'traitsPercent', value: this.state.selectedBenchmark.personalityWeight },
            //   { name: 'Values', description: 'Candidates whose values match your preferences are awarded maximum points.', ref: 'valuesPercent', value: this.state.selectedBenchmark.valuesWeight },
            //   { name: 'Skills', description: 'Candidates who self-assess for the relevant skills are awarded maximum points.', ref: 'skillsPercent', value: this.state.selectedBenchmark.skillsWeight },
            //   { name: 'Education', description: 'Candidates with the target level and focus of education are awarded maximum points.', ref: 'educationPercent', value: this.state.selectedBenchmark.educationWeight },
            //   { name: 'Endorsements', description: 'Candidates with high skill/trait endorsement ratings from close, experts are awarded maximum points.', ref: 'endorsementsPercent', value: this.state.selectedBenchmark.endorsementWeight },
            //   { name: 'Projects', description: 'Candidates with 1+ years working on projects building relevant skills are awarded maximum points.', ref: 'projectsPercent', value: this.state.selectedBenchmark.projectWeight },
            //   { name: 'Experience', description: 'Candidates who have 1+ years of paid expereience building relevant skills are awarded maximum points.', ref: 'experiencePercent', value: this.state.selectedBenchmark.experienceWeight },
            //   { name: 'Resume', description: 'Ideal resume...', ref: 'experiencePercent', value: this.state.selectedBenchmark.resumeWeight },
            //   { name: 'Cover Letter', description: 'Ideal cover letter...', ref: 'experiencePercent', value: this.state.selectedBenchmark.coverLetterWeight },
            //   { name: 'Interview', description: 'Candidates who perform well on the internal interview are awarded maximum points.', ref: 'interviewPercent', value: this.state.selectedBenchmark.interviewWeight },
            //   // { name: 'Adversity Score', description: 'Candidates who are in higher need of financial aid or who have overcome adversity are awarded extra points.', ref: 'adversityScorePercent', value: this.state.selectedBenchmark.adversityScoreWeight },
            //   // { name: 'Political Preference', description: 'Candidates who align with high demand, low supply political preferences are awarded extra points.', ref: 'politicalPreferencePercent', value: this.state.selectedBenchmark.politicalScoreWeight },
            // ]
            let workPreferenceTags = []
            if (selectedBenchmark.functionsOfInterest && selectedBenchmark.functionsOfInterest.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.functionsOfInterest)
            }
            if (selectedBenchmark.industriesOfInterest && selectedBenchmark.industriesOfInterest.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.industriesOfInterest)
            }
            if (selectedBenchmark.technologyTrends && selectedBenchmark.technologyTrends.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.technologyTrends)
            }
            if (selectedBenchmark.additionalTechnologyTrends && selectedBenchmark.additionalTechnologyTrends.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalTechnologyTrends)
            }
            if (selectedBenchmark.societalProblems && selectedBenchmark.societalProblems.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.societalProblems)
            }
            if (selectedBenchmark.additionalSocietalProblems && selectedBenchmark.additionalSocietalProblems.length > 0) {
              workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalSocietalProblems)
            }
            if (selectedBenchmark.maxPay && selectedBenchmark.maxPay !== '') {
              if (selectedBenchmark.maxPay === 'Wide Range') {
                workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay + ' of Pay')
              } else {
                workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay)
              }
            }

            // sort interests
            let interests = []
            if (selectedBenchmark.interests && selectedBenchmark.interests.length > 0) {
              interests = selectedBenchmark.interests
              interests.sort(function(a,b) {
                return b.score - a.score;
              })
            }

            // sort traits
            let traits = []
            if (selectedBenchmark.traits && selectedBenchmark.traits.length > 0) {
              traits = selectedBenchmark.traits
              traits.sort(function(a,b) {
                return b.score - a.score;
              })
            }

            let knowledge = []
            if (selectedBenchmark.knowledge && selectedBenchmark.knowledge.length > 0) {
              for (let i = 1; i <= selectedBenchmark.knowledge.length; i++) {
                knowledge.push(selectedBenchmark.knowledge[i - 1].title)
              }
            }

            // console.log('show ir: ', selectedBenchmark)
            this.setState({ selectedApplication, selectedJob, workPreferenceTags, interests, traits, knowledge });

            this.pullCourses(selectedBenchmark.jobFunction, null, null, null)

            Axios.get('/api/benchmarks', { params: { accountCode, pathwayLevel: true } })
            .then((response) => {
              console.log('Benchmarks query within employerDetails attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                if (response.data.benchmarks.length !== 0) {
                  //jobs = response.data.postings
                  console.log('set the benchmark to state')

                  let benchmarks = []
                  if (response.data.benchmarks && response.data.benchmarks.length > 0) {
                    for (let i = 1; i <= response.data.benchmarks.length; i++) {
                      if (response.data.benchmarks[i - 1].title !== selectedBenchmark.title) {
                        benchmarks.push(response.data.benchmarks[i - 1])
                      }
                    }
                  }
                  this.setState({ benchmarks });
                }

              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
            });

            if (selectedBenchmark.accountCode) {
              Axios.get('/api/account', { params: { accountCode: selectedBenchmark.accountCode } })
              .then((response) => {
                console.log('Account info query attempted within sub settings');

                if (response.data.success) {
                  console.log('account info query worked in sub settings')

                  const selectedEmployer = response.data.accountInfo
                  const employerId = response.data.accountInfo._id
                  const employerName = response.data.accountInfo.employerName
                  const employerLogoURI = response.data.accountInfo.employerLogoURI

                  let shareURL = window.location.protocol + "//" + window.location.host + "/app/benchmarks/" + selectedBenchmark._id
                  if (window.location.pathname.includes('/pages/')) {
                    shareURL = window.location.protocol + "//" + window.location.host + "/employers/pages/" + selectedBenchmark.accountCode + "/benchmarks/" + selectedBenchmark._id
                  }
                  const shareTitle = 'Check Out ' + selectedBenchmark.title + ' On Guided Compass!'
                  let shareBody = ''
                  if (selectedBenchmark.jobFunction && selectedBenchmark.jobFunction !== 'Other') {
                    shareBody = "The ideal candidate to join the " + selectedBenchmark.jobFunction.toLowerCase() + ' team at ' + employerName + '.'
                  }

                  this.setState({ selectedEmployer, employerId, employerName, employerLogoURI, shareURL, shareTitle, shareBody });

                }

              }).catch((error) => {
                console.log('Account info query did not work for some reason', error);
              });
            }

            const resLimit = 50
            Axios.get('/api/get-followers', { params: { _id: selectedBenchmark._id, resLimit } })
            .then((response) => {
              console.log('Followers query attempted');

              if (response.data.success) {
                console.log('followers query worked in sub settings')

                const followers = response.data.followers
                this.setState({ followers })
              }

            }).catch((error) => {
              console.log('Followers query did not work for some reason', error);
            });

            if (selectedBenchmark.jobFunction) {

              const search = true
              const searchString = selectedBenchmark.jobFunction
              const excludeMissingOutlookData = true
              const excludeMissingJobZone = true
              // console.log('show search string: ', searchString)
              Axios.put('/api/careers/search', { search, searchString, excludeMissingOutlookData, excludeMissingJobZone  })
              .then((response) => {
                  console.log('Career details query attempted 2');

                  if (response.data.success) {
                    console.log('found careers: ', response.data.careers)
                    if (response.data.careers && response.data.careers.length > 0) {
                      const selectedCareer = response.data.careers[0]
                      this.setState({ selectedCareer })
                    }

                  } else {
                    console.log('there was an error from back-end, message:');
                  }
              });
            }

            Axios.get('/api/org', { params: { orgCode: org } })
            .then((response) => {
              console.log('Org info query attempted 2', response.data);

                if (response.data.success) {
                  console.log('org info query worked 2')

                  // const orgName = response.data.orgInfo.orgName

                  const orgCode = org
                  let placementPartners = []
                  if (response.data.orgInfo.placementPartners) {
                    placementPartners = response.data.orgInfo.placementPartners
                  }
                  const postType = null
                  const postTypes = ['Assignment','Problem','Challenge','Internship','Individual','Work']
                  const pathway = null
                  const accountCode = null
                  const recent = true
                  const active = true
                  const pullPartnerPosts = true
                  const csWorkflow = true
                  const benchmarkId = selectedBenchmark._id
                  console.log('about to pull postings')
                  //only schools see this screen
                  Axios.get('/api/postings/user', { params: { orgCode, placementPartners, postType, postTypes, pathway, accountCode, recent, active, pullPartnerPosts, csWorkflow, benchmarkId }})
                  .then((response) => {
                    console.log('Posted postings query attempted within subcomponent');

                    if (response.data.success) {
                      console.log('posted postings query worked')

                      if (response.data.postings.length !== 0) {

                        let projectOpportunities = []
                        let work = []
                        for (let i = 1; i <= response.data.postings.length; i++) {
                          if (response.data.postings[i - 1].postType === 'Assignment' || response.data.postings[i - 1].postType === 'Problem' || response.data.postings[i - 1].postType === 'Work') {
                            projectOpportunities.push(response.data.postings[i - 1])
                          } else {
                            work.push(response.data.postings[i - 1])
                          }
                        }

                        this.setState({ projectOpportunities, work })

                      }

                    } else {
                      console.log('posted postings query did not work', response.data.message)
                    }

                  }).catch((error) => {
                      console.log('Posted postings query did not work for some reason', error);
                  });

                } else {
                  console.log('org info query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    translateValue(score, toWords) {
      console.log('translateValue called', score, toWords)

      //interests
      if (toWords) {
        let value = ''

        if (Number(score) >= 5) {
          value = 'Highly Interested'
        } else if (Number(score) >= 4) {
          value = 'Interested'
        } else if (Number(score) >= 3) {
          value = 'Somewhat Interested'
        } else if (Number(score) >= 2) {
          value = 'Uninterested'
        } else if (Number(score) >= 1) {
          value = 'Highly Uninterested'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        console.log('show score: ', score)
        let value = 0
        if (score === 'Highly Interested') {
          value = 5
        } else if (score === 'Interested') {
          value = 4
        } else if (score === 'Somewhat Interested') {
          value = 3
        } else if (score === 'Uninterested') {
          value = 2
        } else if (score === 'Highly Uninterested') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value
      }
    }

    translateWeight(weight, toWords) {
      console.log('translateWeight called ', weight, toWords)

      if (toWords) {
        let value = ''
        if (Number(weight) >= 4) {
          value = 'Super Important'
        } else if (Number(weight) >= 4) {
          value = 'Very Important'
        } else if (Number(weight) >= 3) {
          value = 'Important'
        } else if (Number(weight) >= 2) {
          value = 'Somewhat Important'
        } else if (Number(weight) >= 1) {
          value = 'Slightly Important'
        } else if (Number(weight) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0

        if (weight === 'Super Important') {
          value = 5
        } else if (weight === 'Very Important') {
          value = 4
        } else if (weight === 'Important') {
          value = 3
        } else if (weight === 'Somewhat Important') {
          value = 2
        } else if (weight === 'Slightly Important') {
          value = 1
        } else if (weight === 'Not a Factor') {
          value = 0
        }
        console.log('show score in translate: ', weight, value)
        return value
      }
    }

    translateTraits(score, toWords) {
      console.log('translateTraits called', score, toWords)

      if (toWords) {
        let value = ''
        if (Number(score) >= 5) {
          value = 'Very High'
        } else if (Number(score) >= 4) {
          value = 'High'
        } else if (Number(score) >= 3) {
          value = 'Moderate'
        } else if (Number(score) >= 2) {
          value = 'Low'
        } else if (Number(score) >= 1) {
          value = 'Very Low'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0
        if (score === 'Very High') {
          value = 5
        } else if (score === 'High') {
          value = 4
        } else if (score === 'Moderate') {
          value = 3
        } else if (score === 'Low') {
          value = 2
        } else if (score === 'Very Low') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value

      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      Axios.get('/api/courses/search', { params: { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue } })
      .then((response) => {
        console.log('Courses query attempted');

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              this.setState({ courses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    renderTags(type, passedArray, limit) {
      console.log('renderTags: ', type, passedArray, limit)

      let backgroundColorClass = "primary-background"
      if (type === 'gravitateValues') {
        backgroundColorClass = "secondary-background"
      } else if (type === 'employerValues') {
        backgroundColorClass = "tertiary-background"
      } else if (type === 'hardSkills') {
        backgroundColorClass = "quaternary-background"
      } else if (type === 'softSkills') {
        backgroundColorClass = "quinary-background"
      } else if (type === 'workPreferenceTags') {
        backgroundColorClass = "nonary-background"
      } else if (type === 'knowledge') {
        backgroundColorClass = "denary-background"
      }
      if (passedArray) {
        return (
          <div key={type + "|0"}>
            <div className="spacer" />
            {passedArray.map((value, optionIndex) =>
              <div key={type + "|" + optionIndex} className="float-left">
                {(!limit || (limit && optionIndex < limit)) && (
                  <div className="float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className={"tag-container-basic " + backgroundColorClass}>
                      <p className="description-text-2 white-text">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>
                )}
              </div>
            )}
            {/*
            {this.state.selectedBenchmark.functionsOfInterest.map((value, optionIndex) =>
              <div key={value} className="float-left">
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}*/}
          </div>
        )
      }
    }

    renderAllocation() {
      console.log('renderAllocation called')

      let rows = []

      let weights = [
        { name: 'Work Preferences (Self-Assessment)', urlKey: 'Work Preferences SA', description: 'Candidates interested in relevant industries and employer attributes are awarded maximum points.', value: this.state.selectedBenchmark.workPreferenceWeight },
        { name: 'Interests (Self-Assessment)', urlKey: 'Interests SA', description: 'Candidates interested in careers that require relevant skills are awarded maximum points.', value: this.state.selectedBenchmark.interestsWeight },
        { name: 'Personality (Self-Assessment)', urlKey: 'Personality SA', description: 'Candidates whose personality most matches the sought after type are awarded maximum points.', value: this.state.selectedBenchmark.personalityWeight },
        { name: 'Values (Self-Assessment)', urlKey: 'Values SA', description: 'Candidates whose values match your preferences are awarded maximum points.', value: this.state.selectedBenchmark.valuesWeight },
        { name: 'Skills (Self-Assessment)', urlKey: 'Skills SA', description: 'Candidates who self-assess for the relevant skills are awarded maximum points.', value: this.state.selectedBenchmark.skillsWeight },
        { name: 'Education', urlKey: 'Education', description: 'Candidates with the target level and focus of education are awarded maximum points.', value: this.state.selectedBenchmark.educationWeight },
        { name: 'Endorsements', urlKey: 'Endorsements', description: 'Candidates with high skill/trait endorsement ratings from close, experts are awarded maximum points.', value: this.state.selectedBenchmark.endorsementWeight },
        { name: 'Projects', urlKey: 'Projects', description: 'Candidates who can showcase impressive, metrics-driven projects building relevant skills are awarded maximum points.', value: this.state.selectedBenchmark.projectWeight },
        { name: 'Experience', urlKey: 'Experience', description: 'Candidates who have strong, relevant, paid expereience building relevant skills are awarded maximum points.', value: this.state.selectedBenchmark.experienceWeight },
        { name: 'Resume', urlKey: 'Resume', description: 'Candidates who have strong resumes will be awarded maximum points', value: this.state.selectedBenchmark.resumeWeight },
        { name: 'Cover Letter', urlKey: 'Cover Letter', description: 'Candidates who have strong cover letters will be awarded maximum points', value: this.state.selectedBenchmark.coverLetterWeight },
        { name: 'Interview', urlKey: 'Interview', description: 'Candidates who perform well on the internal interview are awarded maximum points.', value: this.state.selectedBenchmark.interviewWeight },
        { name: 'Diversity', urlKey: 'Diversity', description: 'Candidates who provide diversity of through to our ogranizion in a positive way are awarded maximum points.', value: this.state.selectedBenchmark.adversityScoreWeight }
      ]

      if (this.state.selectedBenchmark.thirdPartyAssessmentWeight) {
        const thirdPartyAssessmentWeight = { name: 'Third Party Assessments', description: 'Candidates who complete the third party assessments at a satisfactory level are awarded maximum points.', ref: 'thirdPartyAssessmentPercent', value: this.state.selectedBenchmark.thirdPartyAssessmentWeight }
        weights.splice(5,0,thirdPartyAssessmentWeight)
      }

      for (let i = 1; i <= weights.length; i++) {
        console.log('iterate: ', i)

        rows.push(
          <div key={"weights|" + i}>
            <button className="background-button full-width left-text" onClick={() => this.setState({ subNavSelected: weights[i - 1].urlKey })}>
              <div className="row-10">
                <div className="calc-column-offset-70">
                  <p className="heading-text-6">{i}. {weights[i - 1].name}</p>
                  <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <p className="description-text-2">{weights[i - 1].description}</p>
                </div>
                <div className="fixed-column-70">
                  <div className="mini-spacer"/><div className="mini-spacer"/>
                  <p className="heading-text-2 cta-color bold-text full-width right-text">{(weights[i - 1].value) ? weights[i - 1].value : "0"}%</p>
                </div>
                <div className="clear" />
              </div>
            </button>
          </div>
        )
      }

      return rows

    }

    returnColorClass(index,type) {
      console.log('returnColorClass called', index, type)

      let colorClass = "nonary-background"
      if (type === 'text') {
        if (index === 0) {
          colorClass = 'nonary-text'
        } else if (index === 1) {
          colorClass = 'primary-text'
        } else if (index === 2) {
          colorClass = 'twelve-text'
        } else if (index === 3) {
          colorClass = 'denary-text'
        } else if (index === 4) {
          colorClass = 'thirteen-text'
        } else if (index === 5) {
          colorClass = 'secondary-text'
        }
      } else if (type === 'background') {
        if (index === 0) {
          colorClass = 'nonary-background'
        } else if (index === 1) {
          colorClass = 'primary-background'
        } else if (index === 2) {
          colorClass = 'twelve-background'
        } else if (index === 3) {
          colorClass = 'denary-background'
        } else if (index === 4) {
          colorClass = 'thirteen-background'
        } else if (index === 5) {
          colorClass = 'secondary-background'
        }
      }
      return colorClass
    }

    prepareEndorsement() {
      console.log('prepareEndorsement called')

      let skillTraits = [] // name, skillType, rating
      let competencies = [] // name, category, rating
      let examples = [] // skillTrait, example

      if (this.state.selectedBenchmark.skills && this.state.selectedBenchmark.skills.length > 0) {
        // benchmarkSkills - title
        for (let i = 1; i <= this.state.selectedBenchmark.skills.length; i++) {
          skillTraits.push({
            name: this.state.selectedBenchmark.skills[i - 1].title,
            skillType: this.state.selectedBenchmark.skills[i - 1].skillType,
            rating: 5
          })
          competencies.push({
            name: this.state.selectedBenchmark.skills[i - 1].title,
            category: this.state.selectedBenchmark.skills[i - 1].skillType,
            rating: 5
          })
          if (i === 1) {
            examples.push({
              skillTrait: this.state.selectedBenchmark.skills[i - 1].title,
              example: "I've worked with this student for 3 years on a project. Never in my 20 years of teaching having I seen someone demonstrate a mastery of " + this.state.selectedBenchmark.skills[i - 1].title.toLowerCase() + " with little-to-no experience.",
            })
          }
        }
      }
      if (this.state.selectedBenchmark.knowledge && this.state.selectedBenchmark.knowledge.length > 0) {
        // benchmarkSkills - title
        for (let i = 1; i <= this.state.selectedBenchmark.knowledge.length; i++) {
          skillTraits.push({
            name: this.state.selectedBenchmark.knowledge[i - 1].title,
            skillType: 'Knowledge',
            rating: 5
          })
          competencies.push({
            name: this.state.selectedBenchmark.knowledge[i - 1].title,
            category: 'Knowledge',
            rating: 5
          })
          if (i === 1) {
            examples.push({
              skillTrait: this.state.selectedBenchmark.knowledge[i - 1].title,
              example: "I've worked with this student for 3 years on a project. Never in my 20 years of teaching having I seen someone demonstrate a mastery of " + this.state.selectedBenchmark.knowledge[i - 1].title.toLowerCase() + " with little-to-no experience.",
            })
          }
        }
      }

      const selectedEndorsement = {
        senderFirstName: 'Jon', senderLastName: 'Doe', senderEmail: 'jondoe@gmail.com',
        recipientFirstName: 'Jon', recipientLastName: 'Doe', recipientEmail: 'jondoe@gmail.com',
        relationship: 'Teacher', pathway: this.state.selectedBenchmark.jobFunction, skillTraits, competencies,
        examples, overallRecommendation: 5, isTransparent: true,
        createdAt: new Date(), updatedAt: new Date()
      }

      this.setState({ modalIsOpen: true, showEndorsementDetails: true, selectedEndorsement })
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, showEndorsementDetails: false, showShareButtons: false  })
    }

    followPerson(e, person) {
      console.log('followPerson called', e, person)

      e.stopPropagation()
      e.preventDefault()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      const recipientHeadline = person.headline
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      const friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName
      }

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          friends.push(friend)
          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type, this.state.members)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"benchmark")

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favoritesArray: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites
        if (favoritesArray.includes(itemId)) {
          const index = favoritesArray.indexOf(itemId)
          favoritesArray.splice(index, 1)
        } else {
          favoritesArray.push(itemId)
        }


        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save addition to favorites')
          if (response.data.success) {
            console.log('saved addition to favorites', response.data)

            this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
        });
      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={this.state.shareURL} subject={this.state.shareTitle} body={this.state.shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={this.state.shareURL} title={this.state.shareTitle} summary={(this.state.shareBody) ? this.state.shareBody : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={this.state.shareURL} description={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    render() {

      let benchmarkLinkPrefix = ''
      if (this.state.selectedBenchmark) {
        if (window.location.pathname.includes('/pages')) {
          benchmarkLinkPrefix = '/employers/pages/' + this.state.selectedBenchmark.accountCode
        } else {
          benchmarkLinkPrefix = '/app/employers/' + this.state.selectedBenchmark.accountCode
        }
      }

      return (
          <div>
            {(this.state.selectedBenchmark) && (
              <div>
                {(this.state.isLoading) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Loading...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    {/*
                    <div className="flex-container flex-center full-space position-fixed wash-out-3">
                      <div>
                        <p className="rotate-45" style={{ color: 'red', fontSize: '800%', marginTop: '-20%'}}>DRAFT</p>
                      </div>
                    </div>*/}
                    <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                      <div>
                        <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                          <div className="full-width height-5 primary-background" />
                          <div className="padding-40">
                            <div className="row-10">
                              <div className="fixed-column-90">
                                <Link to={(window.location.pathname.includes('/app/')) ? '/app/employers/' + this.state.selectedBenchmark.accountCode : '/employers/' + this.state.selectedBenchmark.accountCode} className="background-link">
                                  <img src={(this.state.employerLogoURI) ? this.state.employerLogoURI : benchmarksIconDark} alt="GC" className="image-auto-80" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Visit the " + this.state.employerName + " profile"} />
                                </Link>
                              </div>
                              <div className="calc-column-offset-140 left-padding top-padding-5">
                                {((window.location.pathname.includes('/app/') || window.location.pathname === '/benchmarks/' + this.state.selectedBenchmark._id) && this.state.selectedBenchmark.pathway) ? (
                                  <p className="heading-text-2">{this.state.selectedBenchmark.pathway}</p>
                                ) : (
                                  <p className="heading-text-2">{this.state.selectedBenchmark.title}</p>
                                )}

                                <p className="top-padding">The ideal candidate to join {(this.state.selectedBenchmark.jobFunction && this.state.selectedBenchmark.jobFunction !== 'Other') && ' the ' + this.state.selectedBenchmark.jobFunction.toLowerCase() + ' team at '}{this.state.employerName}. </p>
                                <p className="profile-descriptor top-padding-15">Note: The "perfect candidate" will not be guaranteed a job. This is meant to be a guide for career-seekers, educators, and workforce professionals.</p>

                                <div className="top-padding-20">
                                  {(window.location.pathname.includes('/app/benchmarks')) && (
                                    <button className="btn btn-squarish right-margin" onClick={() => this.favoriteItem(this.state.selectedBenchmark)}>
                                      <div className="float-left">
                                        <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                        {(this.state.favorites.includes(this.state.selectedBenchmark._id)) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={favoritesIconWhite} alt="GC" className="image-auto-15" />}
                                      </div>
                                      <div className="float-left left-padding">
                                        {(this.state.favorites.includes(this.state.selectedBenchmark._id)) ? "Favorited" : "Favorite"}
                                      </div>
                                      <div className="clear" />
                                    </button>
                                  )}

                                  <button className={(window.location.pathname.includes('/app/benchmarks') || window.location.pathname.includes('/advisor/benchmarks')) ? "btn btn-squarish white-background cta-color left-margin-5" : "btn btn-squarish white-background cta-color"} onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true })}>
                                    <div className="float-left">
                                      <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                      <img src={shareIconDark} alt="GC" className="image-auto-15" />
                                    </div>
                                    <div className="float-left left-padding">Share</div>
                                    <div className="clear" />
                                  </button>
                                </div>
                              </div>
                              <div className="fixed-column-50" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={'A member of the ' + this.state.employerName + ' team has reviewed and approved this benchmark.'}>
                                <img src={approvedIconBlue} alt="GC" className="image-auto-25 center-horizontally" />

                                <p className="description-text-2 full-width center-text top-margin-5">{this.state.employerName} Verified!</p>
                              </div>
                              <div className="clear" />
                            </div>
                            <ReactTooltip id="tooltip-placeholder-id" />
                          </div>
                        </div>
                        {/*
                        <div className="standard-container clear-padding">
                          <select name="viewMode" value={this.state.viewMode} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.viewOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>

                        {(this.state.viewMode === 'Profile Card') && (
                          <div className="standard-container">
                            {console.log('show selectedBenchmark: ', this.state.selectedBenchmark)}
                            <div className="clear" />
                            <ProfileCard ref={this.child} selectedApplication={this.state.selectedApplication} selectedJob={this.state.selectedJob} benchmark={this.state.selectedBenchmark} benchmarkOptions={null} source="EmployerDetails" activeOrg={this.props.activeOrg} accountCode={this.props.accountCode} />
                          </div>
                        )}*/}
                        <div>
                          <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 medium-shadow top-margin-2-percent pin-right" : "medium-shadow slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40 "}>
                            <div className="carousel-3 white-background" onScroll={this.handleScroll}>
                              {this.state.subNavCategories.map((value, index) =>
                                <div className="carousel-item-container">
                                  {(value === this.state.subNavSelected) ? (
                                    <div className="selected-carousel-item">
                                      <label key={value}>{value}</label>
                                    </div>
                                  ) : (
                                    <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                      <label key={value}>{value}</label>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Weights') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <p className="heading-text-3">Weights</p>
                                <p className="description-text-1 description-text-color top-padding">How much does each category matter?</p>
                                <div className="spacer" />
                              </div>

                              {this.renderAllocation()}

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Work Preferences SA') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <div className="float-left">
                                  <p className="heading-text-3">Work Preferences (Self-Assessment)</p>
                                </div>
                                <div className="float-left left-padding">
                                  <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.workPreferenceWeight) ? "(" + this.state.selectedBenchmark.workPreferenceWeight + "%)" : "(0%)"}</p>
                                </div>
                                <div className="clear" />
                                <p className="description-text-1 description-text-color top-padding">What the ideal candidate explicitly says they want in their work, work style, and work environment</p>
                              </div>

                              {(this.state.workPreferenceTags && this.state.workPreferenceTags.length > 0) && (
                                <div className="row-10">
                                  {this.renderTags('workPreferenceTags', this.state.workPreferenceTags)}
                                  <div className="clear" />
                                </div>
                              )}

                              {(this.state.selectedBenchmark.workPreferencesMessage) && (
                                <div className="row-10">
                                  <p className="italicize-text">"{this.state.selectedBenchmark.workPreferencesMessage}"</p>
                                </div>
                              )}

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Interests SA') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Interests (Self-Assessment)</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.interestsWeight) ? "(" + this.state.selectedBenchmark.interestsWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">Candidate general interests and strong interest inventory</p>
                            </div>

                            {(this.state.selectedBenchmark.generalInterests && this.state.selectedBenchmark.generalInterests.length > 0) && (
                              <div className="row-10">
                                {this.renderTags('generalInterests', this.state.selectedBenchmark.generalInterests)}
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.interests && this.state.interests.length > 0) && (
                              <div className="standard-border padding-30 top-margin-20">
                                <p className="heading-text-5">Strong Interest Inventory Results</p>
                                <p className="row-5 description-text-2">Strong Interest Inventory is a popular interest assessment used by career counselors. Learn more <a href="https://en.wikipedia.org/wiki/Strong_Interest_Inventory" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                <div className="spacer" /><div className="spacer" />
                                {this.state.interests.map((value, optionIndex) =>
                                  <div key={value}>
                                    <div className="fixed-column-130">
                                      <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                                    </div>
                                    <div className="calc-column-offset-190 top-padding-5">
                                      <div className="mini-spacer" />
                                      <div className="progress-bar clear-border" >
                                        <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                                      </div>
                                    </div>
                                    <div className="fixed-column-60">
                                      <p className={"full-width right-text " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                                    </div>
                                    <div className="clear" />
                                    {(optionIndex + 1 !== this.state.interests.length) && (
                                      <div className="row-10">
                                        <hr />
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            )}

                            <div className="spacer" />

                            {(this.state.selectedBenchmark.interestsMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.interestsMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Personality SA') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Personality (Self-Assessment)</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.personalityWeight) ? "(" + this.state.selectedBenchmark.personalityWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">The big five, 16 personalities, and other personality traits</p>
                            </div>

                            {(this.state.selectedBenchmark.additionalTraits && this.state.selectedBenchmark.additionalTraits.length > 0) && (
                              <div className="row-10">
                                {this.renderTags('additionalTraits', this.state.selectedBenchmark.additionalTraits)}
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.selectedBenchmark.myersBriggs) && (
                              <div className="standard-border padding-30 top-margin-20">
                                <p className="heading-text-5">16 Personalities Results</p>
                                <p className="row-5 description-text-2">16 personalities / myers briggs is a popular way for career counselors to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Myers–Briggs_Type_Indicator" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                <div className="spacer" />
                                <p className="heading-text-2 cta-color">{this.state.selectedBenchmark.myersBriggs}</p>
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.traits && this.state.traits.length > 0) && (
                              <div className="standard-border padding-30 top-margin-20">
                                <p className="heading-text-5">Big Five Results</p>
                                <p className="row-5 description-text-2">The Big Five personality traits is a popular way for career counselors and psychologists to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                <div className="spacer" /><div className="spacer" />
                                {this.state.traits.map((value, optionIndex) =>
                                  <div key={value}>
                                    <div className={(window.innerWidth > 768) ? "fixed-column-180" : "full-width"}>
                                      <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                                    </div>
                                    <div className={(window.innerWidth > 768) ? "calc-column-offset-240 top-padding-5" : "full-width top-padding-5"}>
                                      <div className="mini-spacer" />
                                      <div className="progress-bar clear-border" >
                                        <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                                      </div>
                                    </div>
                                    <div className="fixed-column-60">
                                      <p className={(window.innerWidth > 768) ? "full-width right-text " + this.returnColorClass(optionIndex,'text') : "full-width " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                                    </div>
                                    <div className="clear" />
                                    {(optionIndex + 1 !== this.state.traits.length) && (
                                      <div className="row-10">
                                        <hr />
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.selectedBenchmark.personalityMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.personalityMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Values SA') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Values (Self-Assessment)</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.valuesWeight) ? "(" + this.state.selectedBenchmark.valuesWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">Ranked values on who candidate gravitates towards and what their employer prefers</p>
                              <div className="spacer" /><div className="spacer" />
                            </div>

                            {(this.state.selectedBenchmark.gravitateValues && this.state.selectedBenchmark.gravitateValues.length > 0) && (
                              <div className="row-10">
                                <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate gravitates toward people who are:</p>
                                {this.renderTags('gravitateValues', this.state.selectedBenchmark.gravitateValues, 5)}
                                <div className="clear" />
                              </div>
                            )}
                            {(this.state.selectedBenchmark.employerValues && this.state.selectedBenchmark.employerValues.length > 0) && (
                              <div className="row-10">
                                <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate wants to work with employers that provide:</p>
                                {this.renderTags('employerValues', this.state.selectedBenchmark.employerValues, 5)}
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.selectedBenchmark.valuesMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.valuesMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Skills SA') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Skills (Self-Assessment)</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.skillsWeight) ? "(" + this.state.selectedBenchmark.skillsWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">The top skills that matter</p>
                              <div className="spacer" />
                            </div>

                            <div className="row-10">
                              <div className="container-left">
                                {((this.state.selectedBenchmark.highPriorityHardSkills && this.state.selectedBenchmark.highPriorityHardSkills.length > 0) || (this.state.selectedBenchmark.lowPriorityHardSkills && this.state.selectedBenchmark.lowPriorityHardSkills.length > 0)) && (
                                  <div className="row-10">
                                    <p>Top hard skills:</p>

                                    {(this.state.selectedBenchmark.lowPriorityHardSkills) ? (
                                      <div>
                                        {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills.concat(this.state.selectedBenchmark.lowPriorityHardSkills), 6)}
                                      </div>
                                    ) : (
                                      <div>
                                        {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills, 6)}
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                              <div className="container-right">
                                {((this.state.selectedBenchmark.highPrioritySoftSkills && this.state.selectedBenchmark.highPrioritySoftSkills.length > 0) || (this.state.selectedBenchmark.lowPrioritySoftSkills && this.state.selectedBenchmark.lowPrioritySoftSkills.length > 0)) && (
                                  <div className="row-10">
                                    <p>Top soft skills:</p>

                                    {(this.state.selectedBenchmark.lowPrioritySoftSkills) ? (
                                      <div>
                                        {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills.concat(this.state.selectedBenchmark.lowPrioritySoftSkills), 6)}
                                      </div>
                                    ) : (
                                      <div>
                                        {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills, 6)}
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                              <div className="clear" />
                            </div>

                            {(this.state.selectedBenchmark.skillCourses && this.state.selectedBenchmark.skillCourses.length > 0) && (
                              <div className="row-10">
                                <label className="bold-text">Suggested Courses: </label>

                                {this.state.selectedBenchmark.skillCourses.map((value, optionIndex) =>
                                  <span key={value} className="right-padding">
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    {(optionIndex + 1 !== this.state.selectedBenchmark.skillCourses.length) && (
                                      <label>,</label>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            {(this.state.selectedBenchmark.skillCertifications && this.state.selectedBenchmark.skillCertifications.length > 0) && (
                              <div className="row-10">
                                <label className="bold-text">Suggested Certifications / Badges: </label>

                                {this.state.selectedBenchmark.skillCertifications.map((value, optionIndex) =>
                                  <span key={value} className="right-padding">
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    {(optionIndex + 1 !== this.state.selectedBenchmark.skillCertifications.length) && (
                                      <label>,</label>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            {(this.state.selectedBenchmark.skillsMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.skillsMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Education') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Education</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.educationWeight) ? "(" + this.state.selectedBenchmark.educationWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">The educational components that matter</p>

                            </div>

                            {(this.state.knowledge && this.state.knowledge.length > 0) && (
                              <div className="row-10">
                                {this.renderTags('knowledge', this.state.knowledge)}
                                <div className="clear" />
                              </div>
                            )}

                            <div className="spacer"/>

                            {(this.state.selectedBenchmark.degreeRequirements) && (
                              <div className="row-10">
                                <p><label className="bold-text">Degree Requirements:</label> {this.state.selectedBenchmark.degreeRequirements}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.idealMajors && this.state.selectedBenchmark.idealMajors.length > 0) && (
                              <div className="row-10">
                                <p><label className="bold-text">Ideal Majors:</label> {this.state.selectedBenchmark.idealMajors.toString().replace(/,/g,", ")}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.gpaRange && this.state.selectedBenchmark.gpaRange !== '') && (
                              <div className="row-10">
                                <p><label className="bold-text">GPA Range:</label> {this.state.selectedBenchmark.gpaRange}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.gradYearRange && this.state.selectedBenchmark.gradYearRange !== '') && (
                              <div className="row-10">
                                <p><label className="bold-text">Grad Year Range:</label> {this.state.selectedBenchmark.gradYearRange}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.testScores && this.state.selectedBenchmark.testScores !== '') && (
                              <div className="row-10">
                                <p><label className="bold-text">Standardized Test Scores:</label> {this.state.selectedBenchmark.testScores}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.courseHours && this.state.selectedBenchmark.courseHours !== '') && (
                              <div className="row-10">
                                <p><label className="bold-text">Hours of Relevant Coursework Completed:</label> {this.state.selectedBenchmark.courseHours}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.courses && this.state.selectedBenchmark.courses.length > 0) && (
                              <div className="row-10">
                                <label className="bold-text">Suggested Courses: </label>

                                {this.state.selectedBenchmark.courses.map((value, optionIndex) =>
                                  <span key={value} className="right-padding">
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    {(optionIndex + 1 !== this.state.selectedBenchmark.courses.length) && (
                                      <label>,</label>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            {(this.state.selectedBenchmark.certifications && this.state.selectedBenchmark.certifications.length > 0) && (
                              <div className="row-10">
                                <label className="bold-text">Suggested Certifications / Badges: </label>

                                {this.state.selectedBenchmark.certifications.map((value, optionIndex) =>
                                  <span key={value} className="right-padding">
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    {(optionIndex + 1 !== this.state.selectedBenchmark.certifications.length) && (
                                      <label>,</label>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            <div className="spacer"/>

                            {(this.state.selectedBenchmark.educationMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.educationMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Endorsements') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Endorsements</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.endorsementWeight) ? "(" + this.state.selectedBenchmark.endorsementWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">Importance of 3rd party skill and knowledge endorsements</p>
                            </div>

                            {(this.state.selectedBenchmark.endorsementsMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.endorsementsMessage}"</p>
                              </div>
                            )}

                            <div className="row-10">
                              <button className="background-button cta-color underline-text offset-underline" onClick={() => this.prepareEndorsement()}>See Example Endorsement</button>
                            </div>

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Projects') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Projects</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.projectWeight) ? "(" + this.state.selectedBenchmark.projectWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">Importance of relevant projects, and what type of project work</p>
                            </div>

                            {(this.state.selectedBenchmark.projectTags && this.state.selectedBenchmark.projectTags.length > 0) && (
                              <div className="row-10">
                                {this.renderTags('projectTags', this.state.selectedBenchmark.projectTags)}
                                <div className="clear" />
                              </div>
                            )}

                            {(this.state.selectedBenchmark.projectHours && this.state.selectedBenchmark.projectHours !== '') && (
                              <div className="row-10">
                                <p><label className="bold-text">Recommended Invested Hours:</label> {this.state.selectedBenchmark.projectHours}</p>
                              </div>
                            )}

                            {(this.state.selectedBenchmark.exampleProjects && this.state.selectedBenchmark.exampleProjects.length > 0) && (
                              <div className="row-10">
                                <label className="bold-text">Example Impressive Projects: </label>

                                {this.state.selectedBenchmark.exampleProjects.map((value, optionIndex) =>
                                  <span key={value} className="right-padding">
                                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    {(optionIndex + 1 !== this.state.selectedBenchmark.exampleProjects.length) && (
                                      <label>,</label>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            {(this.state.selectedBenchmark.projectsMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.projectsMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Experience') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <div className="float-left">
                                  <p className="heading-text-3">Experience</p>
                                </div>
                                <div className="float-left left-padding">
                                  <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.experienceWeight) ? "(" + this.state.selectedBenchmark.experienceWeight + "%)" : "(0%)"}</p>
                                </div>
                                <div className="clear" />
                                <p className="description-text-1 description-text-color top-padding">Importance of relevant experience, and what type of experience</p>
                              </div>

                              {(this.state.selectedBenchmark.experienceTags && this.state.selectedBenchmark.experienceTags.length > 0) && (
                                <div className="row-10">
                                  {this.renderTags('projectTags', this.state.selectedBenchmark.experienceTags)}
                                  <div className="clear" />
                                </div>
                              )}

                              {(this.state.selectedBenchmark.experienceMessage) && (
                                <div className="row-10">
                                  <p className="italicize-text">"{this.state.selectedBenchmark.experienceMessage}"</p>
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Resume') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <div className="float-left">
                                  <p className="heading-text-3">Resume</p>
                                </div>
                                <div className="float-left left-padding">
                                  <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.resumeWeight) ? "(" + this.state.selectedBenchmark.resumeWeight + "%)" : "(0%)"}</p>
                                </div>
                                <div className="clear" />
                                <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's resume</p>
                              </div>

                              {(this.state.selectedBenchmark.resumeMessage) && (
                                <div className="row-10">
                                  <p className="italicize-text">"{this.state.selectedBenchmark.resumeMessage}"</p>
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Cover Letter') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <div className="float-left">
                                  <p className="heading-text-3">Cover Letter</p>
                                </div>
                                <div className="float-left left-padding">
                                  <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.coverLetterWeight) ? "(" + this.state.selectedBenchmark.coverLetterWeight + "%)" : "(0%)"}</p>
                                </div>
                                <div className="clear" />
                                <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's cover letter</p>
                              </div>

                              {(this.state.selectedBenchmark.coverLetterMessage) && (
                                <div className="row-10">
                                  <p className="italicize-text">"{this.state.selectedBenchmark.coverLetterMessage}"</p>
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Interview') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <div className="float-left">
                                  <p className="heading-text-3">Interview</p>
                                </div>
                                <div className="float-left left-padding">
                                  <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.interviewWeight) ? "(" + this.state.selectedBenchmark.interviewWeight + "%)" : "(0%)"}</p>
                                </div>
                                <div className="clear" />
                                <p className="description-text-1 description-text-color top-padding">What matters in the ideal candidate's interview</p>
                              </div>

                              <div className="spacer"/>

                              {(this.state.selectedBenchmark.interviewRubric && this.state.selectedBenchmark.interviewRubric.length > 0) && (
                                <div className="row-20">
                                  <p className="heading-text-5">Interview Rubric</p>
                                  <div className="spacer"/>

                                  <div className="standard-border">
                                    <div className="bold-text standard-border-bottom ">
                                      <div className="relative-column-33 padding-20">
                                        <p>Criteria</p>
                                      </div>
                                      <div className="relative-column-33 padding-20">
                                        <p>Sample Questions</p>
                                      </div>
                                      <div className="relative-column-33 padding-20">
                                        <p>Qualities of Great Scores</p>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    {this.state.selectedBenchmark.interviewRubric.map((value, optionIndex) =>
                                      <div key={value}>
                                        <div className="standard-border-bottom">
                                          <div className="relative-column-33 padding-20">
                                            <p className="bold-text">{optionIndex + 1}. {value.criterion}</p>
                                          </div>
                                          <div className="relative-column-33 padding-20">
                                            {value.questions.map((value2, optionIndex2) =>
                                              <div>
                                                <p>{value2}</p>
                                              </div>
                                            )}
                                          </div>
                                          <div className="relative-column-33 padding-20">
                                            {value.answers.map((value2, optionIndex2) =>
                                              <div>
                                                <p>{value2}</p>
                                              </div>
                                            )}
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                </div>
                              )}

                              {(this.state.selectedBenchmark.interviewMessage) && (
                                <div className="row-10">
                                  <p className="italicize-text">"{this.state.selectedBenchmark.interviewMessage}"</p>
                                </div>
                              )}

                              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Diversity') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                            <div className="row-10">
                              <div className="float-left">
                                <p className="heading-text-3">Diversity</p>
                              </div>
                              <div className="float-left left-padding">
                                <p className="heading-text-3 cta-color bold-text">{(this.state.selectedBenchmark.adversityScoreWeight) ? "(" + this.state.selectedBenchmark.adversityScoreWeight + "%)" : "(0%)"}</p>
                              </div>
                              <div className="clear" />
                              <p className="description-text-1 description-text-color top-padding">What valuable diverse candidates look like to us</p>
                            </div>

                            {(this.state.selectedBenchmark.adversityScoreMessage) && (
                              <div className="row-10">
                                <p className="italicize-text">"{this.state.selectedBenchmark.adversityScoreMessage}"</p>
                              </div>
                            )}

                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                            </div>
                          )}

                          {(this.state.subNavSelected === 'About') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              {(this.state.selectedEmployer) && (
                                <div>
                                  <p className="heading-text-3 bottom-padding">About {this.state.employerName}</p>

                                  {(this.state.selectedEmployer.videos && this.state.selectedEmployer.videos.length > 0) ? (
                                    <div>
                                      {this.state.selectedEmployer.videos.map((value, index) =>
                                        <div key={value}>
                                          <div>
                                            <div className="top-margin-20">
                                              <div className={(index % 2 === 0) ? "container-left" : "container-right"}>
                                                <div className="video-container-4">
                                                  {(this.state.useYouTubeAPI) ? (
                                                    <YouTube
                                                      videoId={value.split("/embed/")[1]}
                                                      id={value._id}
                                                      className="video-iframe-4"
                                                      containerClassName={""}
                                                      opts={(e) => this.videoCallback(e,'opts')}
                                                      onReady={(e) => this.videoCallback(e,'onReady')}
                                                      onPlay={(e) => this.videoCallback(e,'onPlay')}
                                                      onPause={(e) => this.videoCallback(e,'onPause')}
                                                      onEnd={(e) => this.videoCallback(e,'onEnd')}
                                                      onError={(e) => this.videoCallback(e,'onError')}
                                                      onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                                      onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                                      onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                                    />
                                                  ) : (
                                                    <iframe
                                                      title="videoLink"
                                                      className="video-iframe"
                                                      src={value}
                                                      frameBorder="0"
                                                    />
                                                  )}
                                                </div>
                                                <div className="clear"/>
                                              </div>

                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    </div>
                                  ) : (
                                    <div>
                                      <p className="description-text-color">No Videos Yet</p>
                                    </div>
                                  )}

                                  <div className="clear" />
                                  <div className="spacer" />

                                  {(this.state.selectedEmployer.description) && (
                                    <p className="description-text-1 description-text-color row-10">{this.state.selectedEmployer.description}</p>
                                  )}

                                  {(this.state.selectedEmployer.employerCulture) && (
                                    <p className="description-text-1 description-text-color">{this.state.selectedEmployer.employerCulture}</p>
                                  )}

                                  {(this.state.selectedEmployer.employerType) && (
                                    <p className="description-text-1 row-10"><p className="bold-text">Type:</p> {this.state.selectedEmployer.employerType}</p>
                                  )}

                                  {(this.state.selectedEmployer.employeeCount) && (
                                    <p className="description-text-1 row-10"><p className="bold-text">Employees:</p> {this.state.selectedEmployer.employeeCount}</p>
                                  )}

                                  {(this.state.selectedEmployer.employeeGrowth) && (
                                    <p className="description-text-1 row-10"><p className="bold-text">Growth:</p> {this.state.selectedEmployer.employeeGrowth}</p>
                                  )}

                                  {(this.state.selectedEmployer.employerLocation) && (
                                    <p className="description-text-1 row-10"><p className="bold-text">Location:</p> {this.state.selectedEmployer.employerLocation}</p>
                                  )}

                                  <div className="row-10">
                                    <Link className="background-button" to={"/app/employers/" + this.state.employerId}>
                                      <p className="cta-color">View the {this.state.employerName} Profile</p>
                                    </Link>
                                  </div>

                                </div>
                              )}

                              {(this.state.selectedCareer) && (
                                <div>
                                  {(this.state.selectedEmployer) && (
                                    <div className="row-30">
                                      <div className="cta-underline" />
                                    </div>
                                  )}

                                  <p className="heading-text-3 bottom-padding centert-text">About {this.state.selectedCareer.name}</p>

                                  {(this.state.selectedCareer.overview) ? (
                                    <div className="row-15">
                                      <p className="description-text-1">{this.state.selectedCareer.overview.summary}</p>
                                    </div>
                                  ) : (
                                    <div />
                                  )}

                                  { (this.state.selectedCareer.marketData) && (
                                    <div>
                                      <div className="top-padding-20 bottom-padding-5">
                                        <p className="heading-text-6 underline-text">Market Data</p>
                                      </div>
                                      <div className="flex-container row-direction">
                                        <div className="width-160">
                                          <p className="description-text-1">Pay</p>
                                        </div>
                                        <div className="calc-column-offset-220 left-padding">
                                          <p className="description-text-1 right-text">${Number(this.state.selectedCareer.marketData.pay).toLocaleString()}</p>
                                        </div>

                                      </div>
                                      <div className="flex-container row-direction">
                                        <div className="width-160">
                                          <p className="description-text-1">Total Employment</p>
                                        </div>
                                        <div className="calc-column-offset-220 left-padding">
                                          <p className="description-text-1 right-text">{this.state.selectedCareer.marketData.totalEmployment}</p>
                                        </div>

                                      </div>
                                      <div className="flex-container row-direction">
                                        <div className="width-160">
                                          <p className="description-text-1">Growth</p>
                                        </div>
                                        <div className="calc-column-offset-220 left-padding">
                                          <p className="description-text-1 right-text">{this.state.selectedCareer.marketData.growth}</p>
                                        </div>

                                      </div>
                                      <div className="flex-container row-direction">
                                        <div className="width-160">
                                          <p className="description-text-1">New Jobs</p>
                                        </div>
                                        <div className="calc-column-offset-220 left-padding">
                                          <p className="description-text-1 right-text">{Number(this.state.selectedCareer.marketData.newJobs).toLocaleString()}</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="row-10 top-margin-20">
                                    <Link className="btn btn-secondary" to={'/app/careers/' + this.state.selectedCareer.name} state={{ careerSelected: this.state.selectedCareer }}>
                                      <p>View Career Profile</p>
                                    </Link>
                                  </div>

                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.subNavSelected === 'People') && (
                            <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                              <SubRenderProfiles
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                members={this.state.followers} friends={this.state.friends}
                                pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                              />
                            </div>
                          )}

                          {(this.state.subNavSelected === 'Courses') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <SubRenderCourses
                                history={this.props.navigate} animating={this.state.animating}
                                courses={this.state.courses} pageSource={this.props.pageSource}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                              />
                            </div>
                          )}

                          {(this.state.subNavSelected === 'Work') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <SubRenderOpportunities oppType="work" postings={this.state.filteredPostings} history={this.props.navigate}
                                rsvps={this.state.rsvps} applications={this.state.applications}
                                favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                activeOrg={this.state.orgCode}
                              />
                            </div>
                          )}

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        </div>
                      </div>
                    </div>

                    {(window.innerWidth > 768) && (
                      <div className="relative-column-30 horizontal-padding top-margin-40">
                        <div className="left-padding-20">

                          {(this.state.benchmarks && this.state.benchmarks.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                              <div className="full-width height-5 senary-background" />
                              <div className="padding-20">
                                <p className="heading-text-5">Other {this.state.employerName} Benchmarks</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.benchmarks.map((item, index) =>
                                  <div key={item}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={benchmarkLinkPrefix + '/benchmarks/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                          <div className="fixed-column-50">
                                            <img src={(this.state.employerLogoURI) ? this.state.employerLogoURI : benchmarksIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p>{item.title}</p>
                                            <p className="description-text-2 standard-color">{item.jobFunction} | {item.jobType}</p>
                                          </div>
                                        </Link>
                                        <div className="float-left left-padding">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <Link to={benchmarkLinkPrefix + '/benchmarks/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                            </Link>
                                          </span>
                                          {/*
                                          {(this.state.path && this.state.path.includes('/app')) && (
                                            <span className="float-right right-padding-15">
                                              <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                                <img src={(this.state.favorites.includes(this.state.item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                              </button>
                                            </span>
                                          )}*/}
                                        </div>
                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.benchmarks.length > 0) && (
                                  <div>
                                    <Link className="background-button full-width right-text" to={benchmarkLinkPrefix} state={{ subNavSelected: 'Benchmarks' }}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="clear" />

                    {(this.state.showEndorsementDetails) && (
                      <div>
                        <SubEndorsementDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedEndorsement={this.state.selectedEndorsement} orgCode={this.state.activeOrg} />
                      </div>
                    )}
                  </div>
                )}

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >

                 {(this.state.showShareButtons) && (
                   <div key="showDescription" className="full-width padding-20 center-text">
                      <p className="heading-text-2">Share the {this.state.selectedBenchmark.title} Benchmark Page with Friends!</p>

                      <div className="top-padding-20">
                        <p>Share this link:</p>
                        <a href={this.state.shareURL} target="_blank">{this.state.shareURL}</a>
                      </div>

                      <div className="spacer" />

                      <div className="top-padding-20">
                        {this.renderShareButtons()}
                      </div>

                    </div>
                 )}

               </Modal>

              </div>
            )}
          </div>

      )
    }
}

export default withRouter(BenchmarkDetails);
