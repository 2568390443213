import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
// import Modal from 'react-modal';
// import Axios from 'axios';
// import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';

class RenderResume extends Component {
    constructor(props) {
      super(props)

      this.state = {
        pageHeight: 200,
        extremelyLongText: 'asdjfhasdfhaosidfhasiodfhaos dhfaisdhfoasdfiasdfoasdifnaosifhsodifhaodfihas dofhasodifhasoihsdofhasdiofho shidfasohdiafhosdfhaisdofhiasdohfiadhsofidshoifdnaiohdsiofhadsofihasoidfhaosdi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi fhasdoifhnasohfisodhf hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi hfaosidhfsofhasodihfasoidfhasiodfhasiodfoiasdhfioasdnofhasdoi'
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.calculatePages = this.calculatePages.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderReferrals', this.props, prevProps)

      if (this.props.resumeContent !== prevProps.resumeContent) {
        this.calculatePages()
      }
    }

    retrieveData() {
      console.log('retrieveData called in RenderReferrals')
    }

    calculatePages() {
      console.log('calculatePages called')

      let contentHeight = 50
      if (document.getElementById('long-text')) {
        console.log('summary long text', document.getElementById('long-text').clientHeight)
      }
    }

    render() {

      const showIdealCandidate = this.props.showIdealCandidate
      const layoutSelected = this.props.layoutSelected
      const sideSectionClass = this.props.sideSectionClass
      const profilePicImage = this.props.profilePicImage
      const profilePicPath = this.props.profilePicPath
      const resumeContent = this.props.resumeContent
      const resumeProfile = this.props.resumeProfile
      const pageCount = this.props.pageCount
      const selectedSections = this.props.selectedSections
      const sectionHeaderDivClass = this.props.sectionHeaderDivClass
      const sectionHeaderTextClass = this.props.sectionHeaderTextClass
      const mainSectionClass = this.props.mainSectionClass
      const headerClass = this.props.headerClass
      const projectsHeight = this.props.projectsHeight
      const skillsHeight = this.props.skillsHeight
      const knowledgeHeight = this.props.knowledgeHeight
      const extrasHeight = this.props.extrasHeight

      const disableEditing = this.props.disableEditing
      const hideIfEmpty = this.props.hideIfEmpty

      return (
        <div>
          {(this.props.showTestModule) ? (
            <div>
              <div className={"paper-container"}>
                <div id="printPage1" className="full-space padding-40">
                  <p>This is page 1</p>

                  <ul>
                    <li>test 1</li>
                    <li>test 2</li>
                  </ul>

                  <p id="long-text">{this.state.extremelyLongText}</p>
                </div>
              </div>
              <div className={"paper-container"}>
                <div id="printPage2" className="full-space padding-40">
                  <ul>
                    <li>test 3</li>
                  </ul>
                  <p>This is page 2</p>

                  <p>{this.state.extremelyLongText}</p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={(showIdealCandidate) ? "paper-container-right" : "paper-container"}>
                <div id="printPage1" className="full-space padding-40 max-text-lines">

                  {(layoutSelected === 'Modern') && (
                    <div className={sideSectionClass}>

                      <div className="row-10">

                        <div className="relative-position">
                          <label for="profilePic" className="profile-pic-button">
                            <img src={
                              profilePicImage ? ( profilePicImage )
                              : resumeContent.pictureURL ? ( resumeContent.pictureURL )
                              : profilePicPath ? ( profilePicPath )
                              : ( addProfilePhotoIcon)}
                            alt="GC" for="profilePic" className="profile-image-medium"/>

                          </label>
                          <input type="file" id="profilePic" name="profilePic" onChange={this.props.formChangeHandler} accept="image/*" />
                        </div>
                      </div>

                      <div className="row-10 left-text description-text-4 wrap-text">
                        <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editBasicInfo: true })}>
                          <p>{(resumeContent.email) ? resumeContent.email : resumeProfile.email}</p>
                          {(resumeContent.phoneNumber) && (
                            <p>{resumeContent.phoneNumber}</p>
                          )}
                          {(resumeContent.customWebsiteURL) && (
                            <p>{resumeContent.customWebsiteURL}</p>
                          )}
                          {(resumeContent.linkedInURL) && (
                            <a href={resumeContent.linkedInURL} target="_blank" rel="noopener noreferrer">{resumeContent.linkedInURL}</a>
                          )}
                        </button>
                      </div>

                      <div className="spacer" /><div className="spacer" />
                      <hr />

                      {(selectedSections.includes('Skills')) && (
                        <div className="top-padding">
                          <div className="row-10 description-text-2" id="skills">
                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                              <p className={sectionHeaderTextClass}>Skills</p>
                            </button>

                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                              {(resumeContent.skills && resumeContent.skills.length > 0) && (
                                <div>
                                  {resumeContent.skills.map((value, optionIndex) =>
                                    <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                      <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </button>
                            <div className="clear" />
                          </div>

                        </div>
                      )}
                      {(selectedSections.includes('Knowledge')) && (
                        <div className="top-padding">
                          <div className="row-10 description-text-2" id="knowledge">
                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                              <p className={sectionHeaderTextClass}>Knowledge</p>
                            </button>

                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                              {(resumeContent.knowledge && resumeContent.knowledge.length > 0) && (
                                <div>
                                  {resumeContent.knowledge.map((value, optionIndex) =>
                                    <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                      <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </button>

                            <div className="clear" />

                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className={mainSectionClass}>
                    <div className={headerClass}>
                      {(layoutSelected === 'Traditional') && (
                        <div>
                          <button className={(this.props.disableEditing) ? "background-button full-width no-pointers" : "background-button full-width"} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editBasicInfo: true })}>
                            <p className="heading-text-2">{(resumeContent.firstName) ? resumeContent.firstName : resumeProfile.firstName} {(resumeContent.lastName) ? resumeContent.lastName : resumeProfile.lastName}</p>

                            <div className="description-text-2">
                              <p>{(resumeContent.email) ? resumeContent.email : resumeProfile.email}{(resumeContent.phoneNumber) && " | " + resumeContent.phoneNumber}</p>
                              {(resumeContent.customWebsiteURL || resumeContent.linkedInURL) && (
                                <div>
                                  {(resumeContent.customWebsiteURL) ? (
                                    <div>
                                      {(resumeContent.linkedInURL) ? (
                                        <p><a href={resumeContent.customWebsiteURL} target="_blank" rel="noopener noreferrer">{resumeContent.customWebsiteURL}</a> | <a href={resumeContent.linkedInURL} target="_blank" rel="noopener noreferrer">{resumeContent.linkedInURL}</a></p>
                                      ) : (
                                        <p><a href={resumeContent.customWebsiteURL} target="_blank" rel="noopener noreferrer">{resumeContent.customWebsiteURL}</a></p>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <p><a href={resumeContent.customWebsiteURL} target="_blank" rel="noopener noreferrer">{resumeContent.linkedInURL}</a></p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </button>
                        </div>
                      )}
                    </div>

                    {(layoutSelected === 'Traditional') && (
                      <hr className="vertical-margin-20" />
                    )}

                    {(selectedSections.includes('Summary') && (!hideIfEmpty || (hideIfEmpty && resumeContent.summary))) && (
                      <div>
                        <div className="row-10 description-text-2" id="summary">
                          <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSummary: true })}>
                            <p className={sectionHeaderTextClass}>Summary</p>
                          </button>

                          {(resumeContent.summary) && (
                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSummary: true })}>
                              {resumeContent.summary}
                            </button>
                          )}
                        </div>

                      </div>
                    )}

                    {(selectedSections.includes('Education') && (!hideIfEmpty || (hideIfEmpty && resumeContent.education && resumeContent.education.length > 0))) && (
                      <div>

                        <div className="row-10 description-text-2" id="education">
                          <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editEducation: true })}>
                            <p className={sectionHeaderTextClass}>Education</p>
                          </button>

                          {(resumeContent.education && resumeContent.education.length > 0) && (
                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editEducation: true })}>
                              {resumeContent.education.map((value, optionIndex) =>
                                <div className="bottom-padding">
                                  {(layoutSelected !== 'Modern') && (
                                    <div className="fixed-column-100">
                                      <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                    </div>
                                  )}
                                  <div className={(layoutSelected === 'Modern') ? "calc-column-offset-130" : "calc-column-offset-230"}>
                                    <p className="bold-text">{value.name}</p>
                                    {(layoutSelected === 'Modern') && (
                                      <div>
                                        <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                      </div>
                                    )}
                                    {(value.degree) && (
                                      <p className="">{value.degree} - {value.major}</p>
                                    )}
                                    {(value.summary) && (
                                      <p className="">{value.summary}</p>
                                    )}
                                    {(value.bullets) && (
                                      <ul className="left-padding-20">
                                        {value.bullets.map((value2, optionIndex2) =>
                                          <li key={"experiencebullets" + optionIndex + value2}>{value2}</li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                  <div className="fixed-column-130">
                                    <p className="bold-text right-text">{value.location}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}
                            </button>
                          )}
                        </div>

                      </div>
                    )}

                    {(selectedSections.includes('Experience') && (!hideIfEmpty || (hideIfEmpty && resumeContent.experience && resumeContent.experience.length > 0))) && (
                      <div>
                        <div className="row-10 description-text-2" id="experience">
                          <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editExperience: true })}>
                            <p className={sectionHeaderTextClass}>Experience</p>
                          </button>

                          {(resumeContent.experience && resumeContent.experience.length > 0) && (
                            <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editExperience: true })}>
                              {resumeContent.experience.map((value, optionIndex) =>
                                <div className="bottom-padding" id={"experience|" + optionIndex}>
                                  {(layoutSelected !== 'Modern') && (
                                    <div className="fixed-column-100">
                                      <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                      {(value.hoursPerWeek) && (
                                        <p>{value.hoursPerWeek} Hours</p>
                                      )}
                                    </div>
                                  )}

                                  <div className={(layoutSelected === 'Modern') ? "calc-column-offset-130" : "calc-column-offset-230"}>
                                    <p className="bold-text">{value.employerName}</p>
                                    <p className="bold-text">{value.title}</p>
                                    {(layoutSelected === 'Modern') && (
                                      <div>
                                        <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                        {(value.hoursPerWeek) && (
                                          <p>{value.hoursPerWeek} Hours</p>
                                        )}
                                      </div>
                                    )}
                                    {(value.summary) && (
                                      <p className="">{value.summary}</p>
                                    )}
                                    {(value.bullets) && (
                                      <ul className="left-padding-20">
                                        {value.bullets.map((value2, optionIndex2) =>
                                          <li key={"experiencebullets" + optionIndex + value2}>{value2}</li>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                  <div className="fixed-column-130">
                                    <p className="bold-text right-text">{value.location}</p>
                                  </div>
                                  <div className="clear" />

                                </div>
                              )}
                            </button>
                          )}

                        </div>
                      </div>
                    )}

                    {((!projectsHeight) || (projectsHeight && projectsHeight < 1000)) && (
                      <div>
                        {(selectedSections.includes('Projects') && (!hideIfEmpty || (hideIfEmpty && resumeContent.projects && resumeContent.projects.length > 0))) && (
                          <div>
                            <div className="row-10 description-text-2" id="projects">
                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProjects: true })}>
                                <p className={sectionHeaderTextClass}>Projects</p>
                              </button>

                              {(resumeContent.projects && resumeContent.projects.length > 0) && (
                                <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProjects: true })}>
                                  {resumeContent.projects.map((value, optionIndex) =>
                                    <div className="bottom-padding" id={"projects|" + optionIndex}>

                                      {(layoutSelected !== 'Modern') && (
                                        <div className="fixed-column-100">
                                          <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                        </div>
                                      )}

                                      <div className={(layoutSelected === 'Modern') ? "calc-column-offset-130" : "calc-column-offset-230"}>
                                        <p className="bold-text">{value.name}</p>
                                        <a href={value.url} target="_blank" rel="noopener noreferrer">{value.url}</a>
                                        {(layoutSelected === 'Modern') && (
                                          <div>
                                            <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                          </div>
                                        )}
                                        {(value.summary) && (
                                          <p className="">{value.summary}</p>
                                        )}
                                        {(value.bullets) && (
                                          <ul className="left-padding-20">
                                            {value.bullets.map((value2, optionIndex2) =>
                                              <li key={"projectbullets" + optionIndex + value2}>{value2}</li>
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                      <div className="fixed-column-130">
                                        <p className="bold-text right-text">{value.location}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </button>
                              )}
                            </div>

                          </div>
                        )}
                      </div>
                    )}

                    {((!skillsHeight) || (skillsHeight && skillsHeight < 1000)) && (
                      <div>
                        {(layoutSelected !== 'Modern') && (
                          <div>
                            {(selectedSections.includes('Skills') && (!hideIfEmpty || (hideIfEmpty && resumeContent.skills && resumeContent.skills.length > 0))) && (
                              <div>
                                <div className="row-10 description-text-2" id="skills">
                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                                    <p className={sectionHeaderTextClass}>Skills</p>
                                  </button>

                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                                    {(resumeContent.skills && resumeContent.skills.length > 0) && (
                                      <div>
                                        {resumeContent.skills.map((value, optionIndex) =>
                                          <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                            <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </button>

                                  <div className="clear" />
                                </div>

                              </div>
                            )}
                            {(selectedSections.includes('Knowledge') && (!hideIfEmpty || (hideIfEmpty && resumeContent.knowledge && resumeContent.knowledge.length > 0))) && (
                              <div>
                                <div className="row-10 description-text-2" id="knowledge">
                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                                    <p className={sectionHeaderTextClass}>Knowledge</p>
                                  </button>

                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                                    {(resumeContent.knowledge && resumeContent.knowledge.length > 0) && (
                                      <div>
                                        {resumeContent.knowledge.map((value, optionIndex) =>
                                          <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                            <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </button>
                                  <div className="clear" />
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {(selectedSections.includes('Awards') && (!hideIfEmpty || (hideIfEmpty && resumeContent.extras && resumeContent.extras.length > 0))) && (
                          <div>
                            <div className="row-10 description-text-2" id="extras">
                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editExtras: true })}>
                                <p className={sectionHeaderTextClass}>Extracurriculars & Awards</p>
                              </button>

                              {(resumeContent.extras && resumeContent.extras.length > 0) && (
                                <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editExtras: true })}>
                                  {resumeContent.extras.map((value, optionIndex) =>
                                    <div className="bottom-padding"  id={"extras|" + optionIndex}>
                                      {(layoutSelected !== 'Modern') && (
                                        <div className="fixed-column-100">
                                          {(value.startDate) && (
                                            <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                          )}
                                          {(value.awardDate) && (
                                            <p>{value.awardDate}</p>
                                          )}
                                        </div>
                                      )}

                                      <div className={(layoutSelected === 'Modern') ? "calc-column-offset-130" : "calc-column-offset-230"}>
                                        <p className="bold-text">{value.name}</p>
                                        {(layoutSelected === 'Modern') && (
                                          <div>
                                            {(value.startDate) && (
                                              <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                            )}
                                            {(value.awardDate) && (
                                              <p>{value.awardDate}</p>
                                            )}
                                          </div>
                                        )}

                                        {(value.summary) && (
                                          <p className="">{value.summary}</p>
                                        )}
                                        {(value.bullets) && (
                                          <ul className="left-padding-20">
                                            {value.bullets.map((value2, optionIndex2) =>
                                              <li key={"extrasbullets" + optionIndex + value2}>{value2}</li>
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                      <div className="fixed-column-130">
                                        <p className="bold-text right-text">{value.location}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </button>
                              )}
                            </div>

                          </div>
                        )}

                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              </div>

              {(pageCount > 1) && (
                <div>
                  <div className="paper-container">
                    <div id="printPage2" className="full-space padding-40 max-text-lines">
                      {((!projectsHeight) || (projectsHeight && projectsHeight > 1000)) && (
                        <div>
                          {(selectedSections.includes('Projects') && (!hideIfEmpty || (hideIfEmpty && resumeContent.projects && resumeContent.projects.length > 0))) && (
                            <div>
                                <div className="row-10 description-text-2" id="projects">
                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProjects: true })}>
                                    <p className={sectionHeaderTextClass}>Projects</p>
                                  </button>

                                  {(resumeContent.projects && resumeContent.projects.length > 0) && (
                                    <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editProjects: true })}>
                                      {resumeContent.projects.map((value, optionIndex) =>
                                        <div className="bottom-padding" id={"projects|" + optionIndex}>
                                          <div className="fixed-column-100">
                                            <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                          </div>
                                          <div className="calc-column-offset-230">
                                            <p className="bold-text">{value.name}</p>
                                            {(value.summary) && (
                                              <p className="">{value.summary}</p>
                                            )}
                                            {(value.bullets) && (
                                              <ul className="left-padding-20">
                                                {value.bullets.map((value2, optionIndex2) =>
                                                  <li key={"projectbullets" + optionIndex + value2}>{value2}</li>
                                                )}
                                              </ul>
                                            )}
                                          </div>
                                          <div className="fixed-column-130">
                                            <p className="bold-text right-text">{value.location}</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </button>
                                  )}

                                </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(selectedSections.includes('Skills') && (!hideIfEmpty || (hideIfEmpty && resumeContent.skills && resumeContent.skills.length > 0))) && (
                        <div>
                          {((!skillsHeight) || (skillsHeight && skillsHeight > 1000)) && (
                            <div className="row-10 description-text-2" id="skills">
                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                                <p className={sectionHeaderTextClass}>Skills</p>
                              </button>

                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editSkills: true })}>
                                {(resumeContent.skills && resumeContent.skills.length > 0) && (
                                  <div>
                                    {resumeContent.skills.map((value, optionIndex) =>
                                      <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                        <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </button>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(selectedSections.includes('Knowledge') && (!hideIfEmpty || (hideIfEmpty && resumeContent.knowledge && resumeContent.knowledge.length > 0))) && (
                        <div>
                          {((!knowledgeHeight) || (knowledgeHeight && knowledgeHeight > 1000)) && (
                            <div className="row-10 description-text-2" id="knowledge">
                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                                <p className={sectionHeaderTextClass}>Knowledge</p>
                              </button>

                              <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editKnowledge: true })}>
                                {(resumeContent.knowledge && resumeContent.knowledge.length > 0) && (
                                  <div>
                                    {resumeContent.knowledge.map((value, optionIndex) =>
                                      <div className="left-text tag-container-inactive float-left right-margin top-margin dark-background white-text description-text-2">
                                        <p>{value.name}{(value.score) && " - " + ((Number(value.score) / 5) * 100).toString() + '%'}</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </button>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                      {(selectedSections.includes('Awards') && (!hideIfEmpty || (hideIfEmpty && resumeContent.extras && resumeContent.extras.length > 0))) && (
                        <div>
                          {((!extrasHeight) || (extrasHeight && extrasHeight > 1000)) && (
                            <div>
                              {(resumeContent.extras && resumeContent.extras.length > 0) && (
                                <div className="row-10 description-text-2" id="extras">
                                  <button className={(this.props.disableEditing) ? sectionHeaderDivClass + ' no-pointers' : sectionHeaderDivClass} disabled={(this.props.disableEditing) ? true : false} onClick={() => this.props.passData({ modalIsOpen: true, editExtras: true })}>
                                    <p className={sectionHeaderTextClass}>Extracurriculars & Awards</p>
                                  </button>

                                  {resumeContent.extras.map((value, optionIndex) =>
                                    <div className="bottom-padding"  id={"extras|" + optionIndex}>
                                      <div className="fixed-column-100">
                                        <p>{value.startDate}-{(value.isContinual) ? 'Present' : value.endDate}</p>
                                      </div>
                                      <div className="calc-column-offset-230">
                                        <p className="bold-text">{value.name}</p>
                                        {(value.summary) && (
                                          <p className="">{value.summary}</p>
                                        )}
                                        {(value.bullets) && (
                                          <ul className="left-padding-20">
                                            {value.bullets.map((value2, optionIndex2) =>
                                              <li key={"extrasbullets" + optionIndex + value2}>{value2}</li>
                                            )}
                                          </ul>
                                        )}
                                      </div>
                                      <div className="fixed-column-130">
                                        <p className="bold-text right-text">{value.location}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="clear" />
        </div>
      )
    }
}

export default withRouter(RenderResume);
