import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import { Link } from 'react-router-dom';
import Axios from 'axios';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';

class JobsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          jobs: [],
        }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('jobs page just mounted')
      window.scrollTo(0, 0)

      // const jobs = [
      //   { title: 'Director of Business Development', location: 'Los Angeles, CA', workFunction: 'Sales & Partnerships', url: "https://docs.google.com/document/d/1OWCIqGNg2f1hAlNGuaxJwTeGyGbrNezBpuWgkejoUU8/edit?usp=sharing" },
      //   // { title: 'Account Executive', location: 'Los Angeles, CA', workFunction: 'Sales & Partnerships', url: "https://docs.google.com/document/d/1rKhtveiNC7OJ1uC4UdDdoduqkKF2OJBqAdBIx8grI1c/edit?usp=sharing" },
      //   // { title: 'Director of Marketing', location: 'Los Angeles, CA', workFunction: 'Marketing & Communications', url: "https://docs.google.com/document/d/1O8fz36RpNJFxQP-I5-qke0icnZKiGYdYGLiowz7riks/edit?usp=sharing" },
      //   { title: 'Chief Operating Officer', location: 'Los Angeles, CA', workFunction: 'Operations', url: "https://docs.google.com/document/d/1xfPaCmtnVppSm_3XZrN8rtCjcKujNf270xisz2Yr1Fg/edit#" },
      // ]
      //
      // this.setState({ jobs })

      // const postType = undefined
      const postTypes = ['Work']
      const recent = true
      const active = true
      const excludeUnlisted = true
      const orgCode = 'guidedcompass'
      const accountCode = 'guidedcompass'
      const isInternal = true

      const postingQueryObject = { params: { postTypes, orgCode, accountCode, recent, active,
        excludeUnlisted, isInternal
      }}
      //only schools see this screen
      Axios.get('/api/postings/user', postingQueryObject)
      .then((response) => {
        console.log('Posted postings query attempted within subcomponent', response.data);

        if (response.data.success) {
          console.log('posted postings query worked')

          const jobs = response.data.postings
          this.setState({ jobs })

        } else {
          console.log('posted postings query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Posted postings query did not work for some reason', error);
      });

    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"jobsPage"} darkBackground={true} />

                <section>
                    <div className="row">
                        <h2>Join the Guided Compass Team</h2>
                    </div>
                    <div className="row">
                      {(this.state.jobs && this.state.jobs.length > 0) ? (
                        <div>
                          {this.state.jobs.map((value, index) =>
                            <div key={index}>
                              <Link to={"/opportunities/" + value._id} target="_blank" rel="noopener noreferrer">
                                <div className="row-20 full-width">
                                  <div className="calc-column-offset-80">
                                    <p className="heading-text-3">{value.title}</p>
                                    <p className="top-margin standard-color">{value.field} | {value.location}</p>
                                  </div>
                                  <div className="fixed-column-80 top-margin">
                                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right" />
                                  </div>
                                  <div className="clear" />
                                </div>
                              </Link>
                              <hr />
                            </div>
                          )}
                          <div className="clear" />
                          <div className="row top-margin-40">
                            <p className="heading-text-6">Are you an all-star that doesn't see something that fits your skill set? Feel free to contact us <Link to="/contact">here</Link></p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row center-text">
                            <hr />
                            <p className="top-margin-30">Guided Compass is not actively looking for roles at this time. Feel free to contact us <Link to="/contact">here</Link>.</p>
                          </div>
                        </div>
                      )}

                        {/*
                        <hr className="clear-padding horizontal-margin-10-percent"/>
                        <div className="spacer"/><div className="spacer"/>


                        <div className="job-listing">
                            <div className="listing-text-box">
                                <h3>Director, Career Counseling</h3>
                                <p>Los Angeles, CA</p>
                            </div>
                            <a href="https://docs.google.com/document/d/1bA6ruZ_C03JC-ApYd3fd7XUtPhcdsGY3b4zc2gKus9k/edit?usp=sharing"><img src={arrowIcon} alt="listing arrow icon" className="view-listing-icon" /></a>
                            <a href="https://docs.google.com/document/d/1bA6ruZ_C03JC-ApYd3fd7XUtPhcdsGY3b4zc2gKus9k/edit?usp=sharing"> <p className="view-listing-text">View and Apply</p></a>
                        </div>
                        <hr />
                        <div className="job-listing">
                            <div className="listing-text-box">
                                <h3>Career Coach Manager</h3>
                                <p>Los Angeles, CA</p>
                            </div>
                            <a href="https://docs.google.com/document/d/1nKzV_z3eO289DL7SHRkZ2hyywlax7bJn8NvV11uJQ_A/edit?usp=sharing"><img src={arrowIcon} alt="listing arrow icon" className="view-listing-icon" /></a>
                            <a href="https://docs.google.com/document/d/1nKzV_z3eO289DL7SHRkZ2hyywlax7bJn8NvV11uJQ_A/edit?usp=sharing"><p className="view-listing-text">View and Apply</p></a>
                        </div>
                        <hr />
                        <div className="job-listing">
                            <div className="listing-text-box">
                                <h3>Product Marketing Manager</h3>
                                <p>Los Angeles, CA</p>
                            </div>
                            <a href="https://docs.google.com/document/d/1vehaDsOZmKFQFkgGie_RHtqw0YpORqLsW6Adpix0ln4/edit?usp=sharing"><img src={arrowIcon} alt="listing arrow icon" className="view-listing-icon" /></a>
                            <a href="https://docs.google.com/document/d/1vehaDsOZmKFQFkgGie_RHtqw0YpORqLsW6Adpix0ln4/edit?usp=sharing"><p className="view-listing-text">View and Apply</p></a>
                        </div>
                        <hr />
                        <div className="job-listing">
                            <div className="listing-text-box">
                                <h3>UI / UX Designer</h3>
                                <p>Los Angeles, CA</p>
                            </div>
                            <a href="https://docs.google.com/document/d/1vcCx81lCVVLy_XyggTJnXisA4IbTEQexD4_Nl2gkrm0/edit?usp=sharing" ><img src={arrowIcon} alt="listing arrow icon" className="view-listing-icon" /></a>
                            <a href="https://docs.google.com/document/d/1vcCx81lCVVLy_XyggTJnXisA4IbTEQexD4_Nl2gkrm0/edit?usp=sharing"><p className="view-listing-text">View and Apply</p></a>
                        </div>*/}
                    </div>
                </section>

                {/*Footer()*/}
                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default JobsPage;
