import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import Axios from 'axios';
import withRouter from '../components/Functions/WithRouter';

// const endorsementCoverImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-cover-image.jpg";

class CatalogPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('blog post detail page just mounted')
      window.scrollTo(0, 0)

    }

    render() {
      // https://drive.google.com/file/d/1Vn69RNhynnLRO6eB1bwVI6KyNC-zN74g/preview
      // https://drive.google.com/file/d/17zteCW6OWwhbb1ellF4adoDw3cODJhVG/view?usp=sharing
      return (
        <div className="embedded-pdf-background">
          {/*<PDFViewer filePath={this.state.filePath} width={null} scrollToTop={true} />*/}
          <iframe src={'https://drive.google.com/file/d/17zteCW6OWwhbb1ellF4adoDw3cODJhVG/preview'} width="100%" height="100%" title={'M'}></iframe>
        </div>
      )
    }
}

export default withRouter(CatalogPage);
