import React, { Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from './Functions/WithRouter';

class AdvSignUp extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
        console.log('sign up page is mounting')
        console.log('this come from top navigation?',this.props.location.state)
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.props.location && this.props.location.state) {
          let { orgCode } = this.props.location.state;
          if (orgCode) {
            this.setState({ orgCode })
          }
        }

        //user navigated from a landing page or from the app
        const fromExternal = this.props.location.state
        if (fromExternal) {
            setTimeout(() => {
                this.setState({animate: true, opacity: 1})
            }, 0.7)
        } else {
            this.setState({animate: true, opacity: 1})
        }
    }

    render() {
      return (
        <div>
          <LoginForm roleName={this.state.roleName} orgCode={this.state.activeOrg} courseId={this.state.courseId} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignUp" />
        </div>
      )
    }
}

export default withRouter(AdvSignUp)
