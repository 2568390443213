import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';

class AssessmentResultsDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderWPData = this.renderWPData.bind(this);

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in assDetails ', this.props.resultsData, prevProps)

      if (this.props.selectedAssessment !== prevProps.selectedAssessment) {
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within shared assessment results data', this.props.selectedAssessment)

      if (this.props.selectedAssessment) {

        const selectedAssessment = this.props.selectedAssessment
        const modalIsOpen = this.props.modalIsOpen

        Axios.get('/api/assessment/questions')
        .then((response) => {
            //console.log('O*Net worked', response.data.body, this);
            console.log('show me response', response.data)
            if ( response.data.success === true ) {
              console.log('assessment query worked')

              let assessmentQuestions = []

              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                assessmentQuestions.push(response.data.assessments.workPreferenceQuestions[i - 1].name)
              }

              this.setState({ assessmentQuestions, selectedAssessment, modalIsOpen })

            } else {
              console.log('Assessment question query failed', response.data.message)
            }

        }).catch((error) => {
            console.log('Assessment question query did not work', error);
        });
      } else {
        console.log('user navigated directly to this screen')
      }
    }

    renderResults() {
      console.log('renderResults called')

      let rows = []

      if (this.state.selectedAssessment) {

        if (this.state.selectedAssessment.category === 'Work Preferences') {
            console.log('testing 4')
            if (this.state.selectedAssessment.results) {
              rows.push(
                <div key={0}>
                  <div className="spacer" /><div className="spacer" />
                  {this.renderWPData(this.state.selectedAssessment.results)}
                </div>
              )
            } else {
              console.log('there is no data')
              rows.push(
                <div key={0}>
                  <p className="center-text">View your results here after you take the assessment</p>
                </div>
              )
            }
        } else if (this.state.selectedAssessment.category === 'Interests') {

          if (this.state.selectedAssessment.results) {
            if (this.state.selectedAssessment.results.length !== 0) {

              rows.push(
                <div key={0}>
                  <div className="spacer" /><div className="spacer" />
                </div>
              )
              for (let i = 0; i < this.state.selectedAssessment.results.length; i++) {
                console.log('gimme i value for filters', i)
                let relativeScore = ((Number(this.state.selectedAssessment.results[i].score) / 40).toFixed(2) * 100).toString() + '%'
                rows.push(
                  <div key={i} className="row-10">
                    <p className="heading-text-5">{this.state.selectedAssessment.results[i].name}</p>

                    <p>{relativeScore}</p>
                    <p>{this.state.selectedAssessment.results[i].description}</p>
                  </div>
                )
              }
            }
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0}>
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.selectedAssessment.category === 'Personality'){
          //personality assessments
          console.log('in personality assessment')
          if (this.state.selectedAssessment.results) {
            rows.push(
              <div key={0}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-5">16 Personalities</p>
                <p>Learn about your type here: <a target="_blank" rel="noopener noreferrer" href={"https://www.16personalities.com/" + this.state.selectedAssessment.results.myersBriggs + "-personality"}>https://www.16personalities.com/{this.state.selectedAssessment.results.myersBriggs}-personality</a></p>
                <p className="heading-text-2 cta-color">{this.state.selectedAssessment.results.myersBriggs}</p>

                <div className="spacer" />

                <p className="heading-text-5">Five Factors</p>
                <p>Learn about the big five personality traits: <a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Big_Five_personality_traits">https://en.wikipedia.org/wiki/Big_Five_personality_traits</a></p>
                <p>Extraversion Score: <label className="cta-color">{((this.state.selectedAssessment.results.fiveFactors.extraversionScore / 16)*100).toString() + '%'}</label></p>
                <p>Openness Score: <label className="cta-color">{((this.state.selectedAssessment.results.fiveFactors.opennessScore / 16)*100).toString() + '%'}</label></p>
                <p>Conscientiousness Score: <label className="cta-color">{((this.state.selectedAssessment.results.fiveFactors.conscientiousnessScore / 16)*100).toString() + '%'}</label></p>
                <p>Agreeableness Score: <label className="cta-color">{((this.state.selectedAssessment.results.fiveFactors.agreeablenessScore / 16)*100).toString() + '%'}</label></p>
                <p>Neuroticism Score: <label className="cta-color">{((this.state.selectedAssessment.results.fiveFactors.neuroticismScore / 16)*100).toString() + '%'}</label></p>
              </div>
            )
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0}>
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.selectedAssessment.category === 'Values') {

          if (this.state.selectedAssessment.results && this.state.selectedAssessment.topGravitateValues) {
            console.log('in values', this.state.selectedAssessment.results)

            rows.push(
              <div key={0}>
                <div className="spacer"/><div className="spacer"/>
              </div>
            )
            rows.push(
              <div key={"gravitateValuesTitle"}>
                <p className="heading-text-5">Top Types of People You Gravitate Towards</p>
              </div>
            )

            let gravitateCounter = 1
            function renderValue1(passedData, index) {
              console.log('called renderValue 1')
              rows.push(
                <div key={"gravitateValues" + index}>
                  <p>{gravitateCounter}. {passedData}</p>
                </div>
              )
              gravitateCounter = gravitateCounter + 1
            }

            if (this.state.selectedAssessment.results.topGravitateValues) {
              console.log('in gravitateValues')
              for (let i = 0; i < this.state.selectedAssessment.results.topGravitateValues.length; i++) {
                console.log('looping values 1', i, this.state.selectedAssessment.results.topGravitateValues[i])
                renderValue1(this.state.selectedAssessment.results.topGravitateValues[i], i)
              }
            }

            rows.push(
              <div key={"employerValuesTitle"}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-5">Top Things You Value In Employers</p>
              </div>
            )

            let employerCounter = 1
            function renderValue2(passedData, index) {
              console.log('called renderValue 2')
              rows.push(
                <div key={"employerValues" + index}>
                  <p>{employerCounter}. {passedData}</p>
                </div>
              )
              employerCounter = employerCounter + 1
            }

            if (this.state.selectedAssessment.results.topEmployerValues) {
              for (let i = 0; i < this.state.selectedAssessment.results.topEmployerValues.length; i++) {
                // console.log('looping values 2', i, this.state.selectedAssessment.results[i].valueType)
                renderValue2(this.state.selectedAssessment.results.topEmployerValues[i], i)
              }
            }
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0}>
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else if (this.state.selectedAssessment.category === 'Skills') {
          console.log('testing 3')
          if (this.state.selectedAssessment.results) {
            rows.push(
              <div key={0}>
                <div className="spacer" /><div className="spacer" />
                <p className="heading-text-5">Top Skills</p>
                {(this.state.selectedAssessment.results[0]) && (
                  <p>1. {this.state.selectedAssessment.results[0].name} - Top {(120 - (this.state.selectedAssessment.results[0].answer * 20))}th Percentile</p>
                )}
                {(this.state.selectedAssessment.results[1]) && (
                  <p>2. {this.state.selectedAssessment.results[1].name}  - Top {(120 - (this.state.selectedAssessment.results[1].answer * 20))}th Percentile</p>
                )}
                {(this.state.selectedAssessment.results[2]) && (
                  <p>3. {this.state.selectedAssessment.results[2].name}  - Top {(120 - (this.state.selectedAssessment.results[2].answer * 20))}th Percentile</p>
                )}
              </div>
            )
          } else {
            console.log('there is no data')
            rows.push(
              <div key={0}>
                <p className="center-text">View your results here after you take the assessment</p>
              </div>
            )
          }
        } else {
          console.log('there is some other assessmentTitle: ', this.state.selectedAssessment.category)
        }
      }

      return rows
    }

    renderWPData(wp) {
      console.log('renderWPData called', wp)

      let rows = []

      for (let i = 0; i < wp.length; i++) {

        let answer = ''
        if (wp[i]) {
          if (wp[i][0] === '[') {
            console.log('test 1er')
            const removedArrayEnds = wp[i].substring(1,wp[i].length - 1).replace(/"/g,"").replace(/"/g,"").replace(/,/g,", ")
            answer = removedArrayEnds
          } else if (typeof wp[i] === 'object') {
            console.log('test 3er', typeof wp[i], wp[i])
            answer = wp[i].join(", ")
          } else {
            console.log('test 2er', typeof wp[i], wp[i])
            answer = wp[i]
          }

          rows.push(
            <div key={'wpData' + i.toString()}>
              {(this.state.assessmentQuestions[i] && wp[i]) && (
                <div>
                  <label className="description-text-2">{this.state.assessmentQuestions[i]}</label>
                  <div className="clear"/>
                  <div className="half-spacer" />
                  <label>{answer}</label>
                  <div className="spacer" /><div className="spacer" />
                </div>
              )}
            </div>
          )
        }
      }

      return rows
    }

    closeModal() {
      console.log('show closeModal in projectDetails')

      this.props.closeModal()
    }

    render() {

        return (
            <div>

              {this.state.modalIsOpen && (
                 <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                   ariaHideApp={false}
                 >

                  {(this.state.selectedAssessment && this.state.selectedAssessment.category) && (
                    <div key="assDetails" className="full-width padding-20">
                      <div>

                        <div className="full-width center-text">
                          <div>
                            <div className="super-spacer"/>
                            <h3 className="center-text">{this.state.selectedAssessment.category}</h3>
                            <p className="center-text">{this.state.selectedAssessment.description}</p>

                            <div className="spacer" /><div className="spacer" /><div className="half-spacer" />
                            <div className="super-spacer"/>
                          </div>
                        </div>

                        <hr className="cta-border-color clear-margin clear-padding" />

                        {this.state.selectedAssessment.results && (
                          <div>
                            <hr />

                            {this.renderResults()}

                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Modal>
              )}
            </div>

        )
    }
}


export default withRouter(AssessmentResultsDetails);
