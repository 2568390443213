import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import TopNavigation from './TopNavigation';

const internIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon.png";
const benchmarksIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png";
const uninspiredIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/uninspired-icon.png";
const pipelineIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon.png";
const caseIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/case-icon-blue.png";
const challengeIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-blue.png";
const assignmentsIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png";
const remoteLearningIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/remote-learning-icon.png";
const gamificationIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/gamification-icon.png";
const coOpIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/co-op-icon.png";

class ProblemPlatformPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          points: [],
        }

    }

    componentDidMount() {
        //see if user is logged in
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        let points = [
          'Problems come directly from employers, tying to their needs',
          'Engages students into learning the right skills for the right reasons',
          'Different versions / difficulty levels account for different grade levels',
          'Employers can add video and data exhibits to problems',
          'Employers can hidden solutions that can be later revealed by instructors',
          'Upvoting / downvoting and tagging provide filters for problems',
          'Favoriting allows students and instructors to easily adopt problems',
          'Gateway to other WBL opportunities since projects are tagged with problems',
          'Upgrading a problem to a challenge / competition is as easy as adding a prize',
        ]

        let emailId = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');

        console.log('show ls: ', emailId, activeOrg, orgFocus, roleName)

        this.setState({ points, emailId, activeOrg, orgFocus, roleName })


        if (window.innerWidth < 768) {

          this.setState({ onMobile: true })
        }

    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"problemPlatformPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

                <section className="section-features">
                    <div className="row">
                        <h2>The Problem with Student & Career-Seeker Projects Today</h2>
                    </div>

                    <div className="row">
                      <div className="col span-1-of-3 box">
                          <img src={benchmarksIconBlue} alt="Greater reach with Compass icon" className="icon-big" />
                          <h3 className="center-text bold-text">Not Real Problems</h3>
                          <p className="center-text">
                            Projects aren't often tied to solving real employer or societal problems.
                          </p>
                      </div>
                      <div className="col span-1-of-3 box">
                          <img src={internIcon} alt="Better counseling with Compass icon" className="icon-big" />
                          <h3 className="center-text bold-text">Unrelated to Internships</h3>
                          <p className="center-text">
                            Projects don’t necessarily tie in with the project work employers value today.
                          </p>
                      </div>
                      <div className="col span-1-of-3 box">
                          <img src={uninspiredIcon} alt="Gain reliable info with Compass icon" className="icon-big" />
                          <h3 className="center-text bold-text">Uninspired Projects</h3>
                          <p className="center-text">
                            Students aren't passionate to continue project work outside of designated class times.
                          </p>
                      </div>
                    </div>
                </section>

                <section className="section-demo">
                  <div className="row">
                    <h2>Three Employer-Sourced Solutions Improves Project-Based Learning</h2>
                  </div>

                  <div className="spacer" />
                  <div className="spacer" />

                  <div className="row">
                    <div className="col span-1-of-3 box">
                      <div className="calc-column-offset-25">
                        <p className="heading-text-3">#1 Assignments</p>
                      </div>
                      <div className="fixed-column-25">
                        <img src={assignmentsIconBlue} alt="Intern icon Guided Compass" className="image-auto-25" />
                      </div>
                      <div className="clear" />
                      <div className="spacer" />
                      <p>Assignments are explicit prompts mimicking freelance work assignments (e.g. design a logo).</p>
                    </div>
                    <div className="col span-1-of-3 box">
                      <div className="calc-column-offset-25">
                        <p className="heading-text-3">#2 Problems</p>
                      </div>
                      <div className="fixed-column-25">
                        <img src={caseIconBlue} alt="Case icon Guided Compass" className="image-auto-25" />
                      </div>
                      <div className="clear" />
                      <div className="spacer" />
                      <p>Problems are case studies that have more data, exhibits, and grey area than assignments.</p>
                    </div>
                    <div className="col span-1-of-3 box">
                      <div className="calc-column-offset-25">
                        <p className="heading-text-3">#3 Challenges</p>
                      </div>
                      <div className="fixed-column-25">
                        <img src={challengeIconBlue} alt="Challenge icon Guided Compass" className="image-auto-25" />
                      </div>
                      <div className="clear" />
                      <div className="spacer" />
                      <p>Challenges are individual or team competitions with rules and a hard timeline. Monetary rewards are encouraged to be given to the winner.</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="separating-container">
                      <p>Note: Students who participate in any of the above options are given extra points for internships and other work-based learning opportunities with that employer.</p>
                    </div>
                  </div>

                  <div className="row edit-profile-row">

                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>More employer feedback</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>Less employer feedback</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">

                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-third flex-align-left">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-center">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"}/>
                          </div>
                          <div className="flex-col-third flex-align-right">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row edit-profile-row">
                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>More explicit direction</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>Room for more creativity</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">
                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-third flex-align-left">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-center">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-right">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="row edit-profile-row">
                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>For more competitive students</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>For less competitive students</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">
                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-third flex-align-left">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-center">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-right">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row edit-profile-row">
                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>For students wanting security</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>For entrepreneurial students</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">
                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-7-of-10 flex-align-left">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-1-of-10 flex-align-left">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-2-of-10 flex-align-left">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row edit-profile-row">
                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>Regimented skill building</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>Intricate passion projects</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">
                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-third flex-align-left">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-left">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-third flex-align-right">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row edit-profile-row">
                    <div className="separating-container">
                      <div className="float-left">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>More projects per year</p>
                      </div>
                      <div className="float-right">
                        <p className={(this.state.onMobile) ? "description-text-2 bold-text" : "description-text-1 bold-text"}>Larger projects per year</p>
                      </div>
                      <div className="clear" />

                      <div className="separating-container">
                        <hr />

                        <div className="flex-container half-spacing-top">
                          <div className="flex-col-third flex-align-left">
                            <img src={assignmentsIconBlue} alt="Assignments icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-47-of-100 flex-align-right">
                            <img src={challengeIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                          <div className="flex-col-2-of-10 flex-align-right">
                            <img src={caseIconBlue} alt="Intern icon Guided Compass" className={(this.state.onMobile) ? "image-auto-25" : "image-auto-36"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-purpose">
                    <div className="dark-semi-transparent-background">
                      <div className="connect-to-real-container">
                          <div className="connect-to-real-text-box">
                              <p className="heading-text-4">In a Hetchinger survey, more than 70% of high school students  wished their high school did more to connect them with real-life work opportunities.</p>
                          </div>
                      </div>
                    </div>
                </section>

                <section className="section-features">
                    <div className="row">
                      <h2>Benefits to Using the GC Problem Platform</h2>
                    </div>

                    <div className="spacer" />
                    <div className="spacer" />

                    <div className="row">
                        <div className="col span-1-of-2 box">
                            <img src={remoteLearningIcon} alt="Talent pipeline with Compass icon" className="icon-big" />
                            <h3 className="center-text bold-text">Remote Learning</h3>
                            <p className="center-text">
                              This platform can be used both in the classroom and in a remote learning environment (e.g. quarantine).
                            </p>
                        </div>
                        <div className="col span-1-of-2 box">
                            <img src={gamificationIcon} alt="Public relations with Compass icon" className="icon-big" />
                            <h3 className="center-text bold-text">Gamification</h3>
                            <p className="center-text">
                              Challenges gamify project-based learning (PBL). Voting and commenting make PBL more fun and personalized.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                      <div className="col span-1-of-2 box">
                          <img src={coOpIcon} alt="Insipire students with Compass icon" className="icon-big" />
                          <h3 className="center-text bold-text">Outsourced Work</h3>
                          <p className="center-text">
                            Sourcing projects, grades, and feedback from industry professionals allows for a <a href="https://en.wikipedia.org/wiki/Cooperative_education" target="_blank" rel="noopener noreferrer" className="bold-text">Co-Op Model</a>.
                          </p>
                      </div>
                      <div className="col span-1-of-2 box">
                          <img src={pipelineIcon} alt="Insipire students with Compass icon" className="icon-big" />
                          <h3 className="center-text bold-text">Build Talent Pipelines</h3>
                          <p className="center-text">
                            Students earn points that give them an edge when applying for relevant internships and apprenticeships.
                          </p>
                      </div>
                    </div>
                </section>

                <section className="section-cta">
                    <div className="cta-container">
                        <div className="cta-text-box">
                          <p className="heading-text-3">Ready to post or solve a</p>
                          <p className="heading-text-1">Problem?</p>
                        </div>

                        <div className="cta-buttons">
                          <div className="vertically-centered-container">
                            <Link className="btn btn-full" to={'/problem-platform/add'} state={{ reason: "Problem" }}>Post</Link>
                            <Link className="btn btn-secondary" to={'/problem-platform/problems'} state={{ reason: "Browse Problems on Problem Platform" }}>Browse</Link>
                          </div>
                        </div>
                    </div>
                </section>

                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default ProblemPlatformPage;
