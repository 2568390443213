import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSideNav from '../Common/SideNav';
import SubNewsFeed from '../Subcomponents/NewsFeed';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class OrgNewsFeed extends Component {
    constructor(props) {
      super(props)

      this.state = {
          opacity: 0,
          transform: 'translate(90%)',
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ org, emailId: email, username, orgFocus, orgLogo })
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    render() {

      return (
          <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              {(!toggleVars.hideAdminSideNav) && (
                <SubSideNav selectedComponent={"OrgNewsFeed"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent max-width-1400 center-horizontally top-margin-2-percent" : "floating-container"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                <SubNewsFeed history={this.props.navigate} postId={this.state.postId} />
              </div>
              <div className="clear" />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(OrgNewsFeed)
