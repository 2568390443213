import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';

const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class RequestMaterials extends Component {
    constructor(props) {
      super(props)
      this.state = {
        modalIsOpen: true
      }

      this.handleSubmit = this.handleSubmit.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called in requestMaterials')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in requestMaterials', this.props)

      const modalIsOpen = this.props.modalIsOpen

      let firstName = localStorage.getItem('firstName')
      let lastName = localStorage.getItem('lastName')
      let email = localStorage.getItem('email')
      let jobTitle = localStorage.getItem('jobTitle')
      let employerName = localStorage.getItem('employerName')

      if (email && email !== '') {
        this.setState({ modalIsOpen, firstName, lastName, email, jobTitle, employerName })
      } else {
        this.setState({ modalIsOpen })
      }
    }

    formChangeHandler = event => {

      console.log('show data: ', event.target.name)
      if (event.target.name === 'firstName') {
        this.setState({ firstName: event.target.value })
      } else if (event.target.name === 'lastName') {
        this.setState({ lastName: event.target.value })
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value })
      } else if (event.target.name === 'employerName') {
        this.setState({ employerName: event.target.value })
      }
    }

    handleSubmit() {
      console.log('handleSubmit called')

      this.setState({ isSaving: true })

      if (!this.state.firstName || this.state.firstName === '') {
        this.setState({ serverErrorMessage: 'Please enter your first name'})
      } else if (!this.state.lastName || this.state.lastName === '') {
        this.setState({ serverErrorMessage: 'Please enter your last name'})
      } else if (!this.state.email || this.state.email === '') {
        this.setState({ serverErrorMessage: 'Please enter your email'})
      } else if (!this.state.email.includes('@')) {
        this.setState({ serverErrorMessage: 'Please enter a valid email'})
      } else if (!this.state.jobTitle || this.state.jobTitle === '') {
        this.setState({ serverErrorMessage: 'Please enter your job title'})
      } else if (!this.state.employerName || this.state.employerName === '') {
        this.setState({ serverErrorMessage: 'Please enter your employer name or school'})
      } else {
        // send link

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        const jobTitle = this.state.jobTitle
        const employerName = this.state.employerName

        // reason, title, materialsLink
        const reason = "Access Materials"
        const title = this.props.material.title
        const materialsLink = this.props.material.url

        console.log('about to share')
        Axios.post('/api/share-materials', {
          firstName, lastName, email, jobTitle, employerName, reason, title, materialsLink
        })
        .then((response) => {
          console.log('about to share 2')
          if (response.data.success) {
            //save values
            console.log('Sent materials!', response.data);

            this.setState({
              showHeaderNotification: false, isSaving: false, serverSuccessMessage: "Check your email!",
              firstName: '', lastName: '', email: '', jobTitle: '', employerName: ''
            })

            localStorage.setItem('showHeaderNotification', 'false')

            localStorage.setItem('firstName', firstName)
            localStorage.setItem('lastName', lastName)
            localStorage.setItem('email', email)
            localStorage.setItem('jobTitle', jobTitle)
            localStorage.setItem('employerName', employerName)

          } else {
            console.error('There was an error sending materials');
            this.setState({
              serverErrorMessage: response.data.message, isSaving: false
            })
          }
        }).catch((error) => {
            console.log('There was an error sending materials', error);
            this.setState({ serverErrorMessage: error, isSaving: false })
        });


      }

    }

    closeModal() {
      console.log('closeModal called')
      this.props.closeModal()
    }

    render() {

      return (
        <Modal
         isOpen={this.state.modalIsOpen}
         onAfterOpen={this.afterOpenModal}
         onRequestClose={this.closeModal}
         className="modal"
         overlayClassName="modal-overlay"
         contentLabel="Example Modal"
       >
       <div key="newPost" className="full-width">

         <div className="spacer" />

         <a onClick={() => this.closeModal()}>
           <img src={closeIcon} alt="GC" className="image-auto-15" />
         </a>

         <div className="padding-40">
           <p className="description-text-3">Download Our {this.props.material.type}</p>
           <p className="heading-text-2">{this.props.material.title}</p>
           <div className="spacer" />
           <p>{this.props.material.description}</p>

           <div className="spacer" /><div className="spacer" />

           <div className="edit-profile-row">
             <div className="container-left">
                 <label className="profile-label">First Name</label>
                 <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
             </div>
             <div className="container-right">
                 <label className="profile-label">Last Name</label>
                 <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
             </div>
             <div className="clear" />
           </div>

           <div className="edit-profile-row">
             <div className="container-left">
                 <label className="profile-label">Email</label>
                 <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
             </div>
             <div className="container-right">
                 <label className="profile-label">Job Title</label>
                 <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}/>
             </div>
             <div className="clear" />
           </div>

           <div className="edit-profile-row">
             <div className="container-left">
                 <label className="profile-label">Employer Name or School</label>
                 <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
             </div>
             <div className="clear" />
           </div>

           <div className="spacer" /><div className="spacer" /><div className="spacer" />

           {(this.state.serverSuccessMessage) && <p className="row-10 cta-color">{this.state.serverSuccessMessage}</p>}
           {(this.state.serverErrorMessage) && <p className="row-10 error-color">{this.state.serverErrorMessage}</p>}

           <button type="button" className="btn btn-full white-text" disable={this.state.isSaving} onClick={() => this.handleSubmit()}>Email Me The Link</button>

         </div>
       </div>

       </Modal>
      )
    }
}

export default RequestMaterials
