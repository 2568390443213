import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import SubEmployerDetails from '../Subcomponents/EmployerDetails';
import withRouter from '../Functions/WithRouter';

const studentIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/student-icon-dark.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const exampleLogoLight = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-light.png';
const exampleLogoDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-dark.png';
const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';

class EditEmployer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pathwayOptions: [],
            pathways: [],

            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            org: '',

            employerName: '',
            employerURL: '',
            employerType: '',
            employerIndustry: '',
            employeeCount: '',
            employeeGrowth: '',
            employerLocation: '',
            employerCulture: '',
            projectedHires: '',
            subsidyApproved: false,
            subsidyAward: '',

            contactFirstName: '',
            contactLastName: '',
            contactTitle: '',
            contactEmail: '',
            contactPhone: '',

            typeOptions: ['','For-Profit','Non-Profit','Public Sector'],
            industryOptions: [],
            countOptions: [],
            growthOptions: [],
            hireOptions: [],
            postOptions: [],

            binaryOptions: ['','Yes','No'],
            maybeOptions: ['','Yes','Maybe','No'],
            officeTypeOptions: [],
            politicalAlignmentOptions: [],
            registrationOptions: [],

            includeHighSchool: true,
            includeAssociates: true,
            includeBachelors: true,

            volunteerOptions: ['Resume Building','Public Speaking','Leadership Skills','Job Shadowing','Company Tour + Q&A'],
            volunteerPreferences: [],

            successOptions: ['Conversion to jobs','Knowledge gained as a result of internship','Achieve hiring and diversity needs','Fulfill contingent workforce','Employee exposure to non-traditional student backgrounds'],
            successMetrics: [],

            hasFunds: false,

            formHasChanged: false,

            clientErrorMessage: '',
            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: ''

        }

        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.renderVolunteerOptions = this.renderVolunteerOptions.bind(this)
        this.renderSuccessOptions = this.renderSuccessOptions.bind(this)
        this.switchHandler = this.switchHandler.bind(this)
        this.saveEmployer = this.saveEmployer.bind(this)
        this.renderOrgContacts = this.renderOrgContacts.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.saveFile = this.saveFile.bind(this)
        this.renderPathways = this.renderPathways.bind(this)
        this.optionClicked = this.optionClicked.bind(this);
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode || this.props.selectedEmployer !== prevProps.selectedEmployer) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editMembers')

      const org = this.props.org
      const selectedEmployer = this.props.selectedEmployer
      let accountCode = this.props.accountCode

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      let employerName = null
      let employerURL = null
      let employerType = null
      let employerIndustry = null
      let employeeCount = null
      let employeeGrowth = null
      let employerLocation = null
      let projectedHires = null
      let subsidyApproved = null
      let subsidyAward = null
      let employerLogoURI = null
      let employerLogoWhiteURI = null
      let contactFirstName = null
      let contactLastName = null
      let contactTitle = null
      let contactEmail = null
      let contactPhone = null
      let showOrgContacts = true

      let _id = null
      let hasFunds = null
      let successMetrics = null
      let volunteerPreferences = null
      let employerCulture = null
      let description = null
      let officeType = null
      let politicalAlignment = null
      let stateRegistration = null
      let congressionalDistrict = null
      let hostUndocumented = null
      let orgContacts = [{ orgCode: org }]
      let allowChanges = true
      let pathways = this.state.pathways

      if (selectedEmployer) {
        employerName = selectedEmployer.employerName
        employerURL = selectedEmployer.employerURL
        employerType = selectedEmployer.employerType
        employerIndustry = selectedEmployer.employerIndustry
        employeeCount = selectedEmployer.employeeCount
        employeeGrowth = selectedEmployer.employeeGrowth
        employerLocation = selectedEmployer.employerLocation
        accountCode = selectedEmployer.accountCode
        projectedHires = selectedEmployer.projectedHires
        subsidyApproved = selectedEmployer.subsidyApproved
        subsidyAward = selectedEmployer.subsidyAward
        employerLogoURI = selectedEmployer.employerLogoURI
        employerLogoWhiteURI = selectedEmployer.employerLogoWhiteURI
        contactFirstName = selectedEmployer.contactFirstName
        contactLastName = selectedEmployer.contactLastName
        contactTitle = selectedEmployer.contactTitle
        contactEmail = selectedEmployer.contactEmail
        contactPhone = selectedEmployer.contactPhone

        if (selectedEmployer.orgContacts && selectedEmployer.orgContacts.length > 0) {
          orgContacts = selectedEmployer.orgContacts
          for (let i = 1; i <= selectedEmployer.orgContacts.length; i++) {
            if (selectedEmployer.orgContacts[i - 1].orgCode === org) {
              contactFirstName = selectedEmployer.orgContacts[i - 1].contactFirstName
              contactLastName = selectedEmployer.orgContacts[i - 1].contactLastName
              contactTitle = selectedEmployer.orgContacts[i - 1].contactTitle
              contactEmail = selectedEmployer.orgContacts[i - 1].contactEmail
              contactPhone = selectedEmployer.orgContacts[i - 1].contactPhone
              showOrgContacts = true
            }
          }
        }

        _id = selectedEmployer._id
        hasFunds = selectedEmployer.hasFunds
        successMetrics = selectedEmployer.successMetrics
        volunteerPreferences = selectedEmployer.volunteerPreferences
        employerCulture = selectedEmployer.employerCulture
        description = selectedEmployer.description

        officeType = selectedEmployer.officeType
        politicalAlignment = selectedEmployer.politicalAlignment
        stateRegistration = selectedEmployer.stateRegistration
        congressionalDistrict = selectedEmployer.congressionalDistrict
        hostUndocumented = selectedEmployer.hostUndocumented

        allowChanges = false
        pathways = selectedEmployer.pathways
      } else {
        accountCode = null
      }

      if (org) {
        // console.log('shooo', employerLogoURI)
        this.setState({
            emailId: email, username, cuFirstName, cuLastName, org, orgFocus,

            employerName, employerURL, employerType, employerIndustry, employeeCount, employeeGrowth, employerLocation,
            accountCode, projectedHires, subsidyApproved, subsidyAward, employerLogoURI, employerLogoWhiteURI,

            contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone, showOrgContacts,

            _id, hasFunds, successMetrics, volunteerPreferences, employerCulture, description,
            officeType, politicalAlignment, stateRegistration, congressionalDistrict, hostUndocumented,
            orgContacts, allowChanges, pathways
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let officeTypeOptions = response.data.orgInfo.officeTypeOptions
            if (officeTypeOptions) {
              officeTypeOptions.unshift('')
            }

            this.setState({ officeTypeOptions });

          }

        }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let politicalAlignmentOptions = response.data.workOptions[0].politicalAlignmentOptions
            if (politicalAlignmentOptions) {
              politicalAlignmentOptions.unshift('')
            }

            let registrationOptions = response.data.workOptions[0].unitedStateOptions
            if (registrationOptions) {
              registrationOptions.unshift('')
            }

            this.setState({
              industryOptions: response.data.workOptions[0].industryOptions,
              countOptions: response.data.workOptions[0].employeeCountOptions,
              growthOptions: response.data.workOptions[0].employeeGrowthOptions,
              hireOptions: response.data.workOptions[0].projectedInternOptions,
              politicalAlignmentOptions, registrationOptions
            })
          }
        });

        Axios.get('/api/pathways', { params: { orgCode: org } })
        .then((response) => {
          console.log('Pathways query attempted two oh', response.data);

          if (response.data.success) {
            console.log('pathways query worked')

            const pathwayOptions = response.data.pathways
            this.setState({ pathwayOptions});

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked')

      if (event.target.name === 'employerName') {
        const employerName = event.target.value

        let accountCode = ''
        if (employerName && employerName !== '') {
          let trimmedName = employerName.trim()
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }

        this.setState({ employerName, accountCode, formHasChanged: true })

        this.searchEmployers(employerName)
      } else if (event.target.name === 'employerURL') {
        this.setState({ employerURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerType') {
        this.setState({ employerType: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerIndustry') {
        this.setState({ employerIndustry: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employeeCount') {
        this.setState({ employeeCount: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employeeGrowth') {
        this.setState({ employeeGrowth: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerLocation') {
        this.setState({ employerLocation: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'projectedHires') {
        this.setState({ projectedHires: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'subsidyAward') {
        this.setState({ subsidyAward: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'contactFirstName') {
        this.setState({ contactFirstName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'contactLastName') {
        this.setState({ contactLastName: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'contactTitle') {
        this.setState({ contactTitle: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'contactEmail') {
        this.setState({ contactEmail: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'contactPhone') {
        this.setState({ contactPhone: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'employerCulture') {
        this.setState({ employerCulture: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'officeType') {
        this.setState({ officeType: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'politicalAlignment') {
        this.setState({ politicalAlignment: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'stateRegistration') {
        this.setState({ stateRegistration: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'congressionalDistrict') {
        this.setState({ congressionalDistrict: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'hasFunds') {
        this.setState({ hasFunds: event.target.value, formHasChanged: true })
      } else if (event.target.name.includes('employerContact')) {
        let orgContacts = this.state.orgContacts
        const nameArray = event.target.name.split('|')
        const index = Number(nameArray[1])

        let objectName = ''
        if (nameArray[0] === 'employerContactFirstName') {
          objectName = 'contactFirstName'
        } else if (nameArray[0] === 'employerContactLastName') {
          objectName = 'contactLastName'
        } else if (nameArray[0] === 'employerContactEmail') {
          objectName = 'contactEmail'
        } else if (nameArray[0] === 'employerContactPhone') {
          objectName = 'contactPhone'
        } else if (nameArray[0] === 'employerContactTitle') {
          objectName = 'contactTitle'
        }

        orgContacts[index][objectName] = event.target.value
        this.setState({ orgContacts, formHasChanged: true })
      } else if (event.target.name.includes('orgContact')) {
        let orgContacts = this.state.orgContacts
        const nameArray = event.target.name.split('|')
        const index = Number(nameArray[1])

        orgContacts[index][nameArray[0]] = event.target.value
        console.log('show values: ', orgContacts, nameArray[0])
        this.setState({ orgContacts, formHasChanged: true })
      } else if (event.target.name === 'employerLogo') {
        console.log('employerLogoSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ employerLogoImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({ employerLogoFile: event.target.files[0], formHasChanged: true })
            // this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'employerLogoWhite') {
        console.log('employerLogoWhiteSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ employerLogoWhiteImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({ employerLogoWhiteFile: event.target.files[0], formHasChanged: true })
            // this.saveFile(event.target.name, event.target.files[0])
        }
      } else {
        this.setState({ [event.target.name]: event.target.value, formHasChanged: true })
      }

    }

    saveFile(category, passedFile, accountCode) {
      console.log('saveFile called', category, passedFile, accountCode)

      const fileName = passedFile.name
      let originalName = category + '|' + accountCode + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'orgLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoFile: passedFile })
          } else if (category === 'orgLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoWhiteFile: passedFile })
          } else if (category === 'employerLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, employerLogoFile: passedFile })
          } else if (category === 'employerLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, employerLogoWhiteFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'orgLogo') {
              existingFilePath = self.state.orgLogoPath
            } else if (category === 'orgLogoWhite') {
              existingFilePath = self.state.orgLogoWhite
            } else if (category === 'employerLogo') {
              existingFilePath = self.state.employerLogo
            } else if (category === 'employerLogoWhite') {
              existingFilePath = self.state.employerLogoWhite
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'orgLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoPath: newFilePath
                    })
                  } else if (category === 'orgLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoWhitePath: newFilePath
                    })
                  } else if (category === 'employerLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', employerLogoPath: newFilePath
                    })
                  } else if (category === 'employerLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', employerLogoWhitePath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessOrgLogo: false,
              serverErrorMessageOrgLogo: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessOrgLogo: false,
            serverErrorMessageOrgLogo: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    switchHandler(name, index, change) {
      console.log('switchHandler called')

      if (name === 'success') {

        let successMetrics = this.state.successMetrics
        if (change) {
          // this.state.successOptions[index]
          successMetrics.push(this.state.successOptions[index])
        } else {
          successMetrics.indexOf(this.state.successOptions[index])
          successMetrics.splice(index, 1);
        }

        this.setState({ successMetrics, formHasChanged: true })
      } else if (name === 'volunteer') {
        console.log('got in volunteer')
        let volunteerPreferences = this.state.volunteerPreferences
        if (change) {
          console.log('called positive change', this.state.volunteerOptions[index])
          volunteerPreferences.push(this.state.volunteerOptions[index])
        } else {
          volunteerPreferences.indexOf(this.state.volunteerOptions[index])
          volunteerPreferences.splice(index, 1);
        }

        this.setState({ volunteerPreferences, formHasChanged: true })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            this.setState({ employers });

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employerName = employer.employerName
      const employers = []
      const employerLocation = employer.employerLocation
      const employerURL = employer.employerURL
      const employerType = employer.employerType
      const employerIndustry = employer.employerIndustry
      const employeeCount = employer.employeeCount
      const employeeGrowth = employer.employeeGrowth
      const accountCode = employer.accountCode
      const sharePartners = employer.sharePartners

      this.setState({ employerName, employers, employerLocation, employerURL, employerType, employerIndustry,
        employeeCount, employeeGrowth, accountCode, sharePartners })
    }

    saveEmployer() {
      console.log('saveEmployer clicked');

      this.setState({ isSaving: true, clientErrorMessage: '' })

      if (this.state.formHasChanged === false) {
        this.setState({ clientErrorMessage: 'no changes detected', isSaving: false })
      } else if (this.state.employerName === '') {
        this.setState({ clientErrorMessage: 'please add the employer name', isSaving: false })
      } else if (this.state.employerURL === '') {
        this.setState({ clientErrorMessage: 'please add the employer URL', isSaving: false })
      } else if (this.state.employerType === '') {
        this.setState({ clientErrorMessage: 'please add the employer type', isSaving: false })
      } else if (this.state.employerIndustry === '') {
        this.setState({ clientErrorMessage: 'please add the employer industry', isSaving: false })
      } else if (this.state.employeeCount === '') {
        this.setState({ clientErrorMessage: 'please add the estimated employee count', isSaving: false })
      } else if (this.state.employeeGrowth === '') {
        this.setState({ clientErrorMessage: 'please add the estimated employee growth', isSaving: false })
      } else if (this.state.employerLocation === '') {
        this.setState({ clientErrorMessage: 'please add the employer location', isSaving: false })
      } else if (this.state.subsidyApproved === true && this.state.subsidyAward === '') {
        this.setState({ clientErrorMessage: 'please add a subsidy award amount', isSaving: false })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ clientErrorMessage: 'please add a description', isSaving: false })
      } else {

        if (this.state.showOrgContacts) {
          for (let i = 1; i <= this.state.orgContacts.length; i++) {
            if (!this.state.orgContacts[i - 1].contactFirstName || this.state.orgContacts[i - 1].contactFirstName === '') {
              return this.setState({ clientErrorMessage: 'please add the contact first name', isSaving: false })
            } else if (!this.state.orgContacts[i - 1].contactLastName || this.state.orgContacts[i - 1].contactLastName === '') {
              return this.setState({ clientErrorMessage: 'please add the contact last name', isSaving: false })
            } else if (!this.state.orgContacts[i - 1].contactEmail || this.state.orgContacts[i - 1].contactEmail === '') {
              return this.setState({ clientErrorMessage: 'please add the contact email', isSaving: false })
            } else if (!this.state.orgContacts[i - 1].contactTitle || this.state.orgContacts[i - 1].contactTitle === '') {
              return this.setState({ clientErrorMessage: 'please add the contact title', isSaving: false })
            } else if (!this.state.orgContacts[i - 1].contactEmail.includes('@')) {
              return this.setState({ clientErrorMessage: 'please add a valid email', isSaving: false })
            } else if (!this.state.orgContacts[i - 1].contactPhone || this.state.orgContacts[i - 1].contactPhone === '') {
              return this.setState({ clientErrorMessage: 'please add the contact phone number', isSaving: false })
            }
          }
        }

        const _id = this.state._id
        const employerName = this.state.employerName
        const employerURL = this.state.employerURL
        const employerType = this.state.employerType
        const employerIndustry = this.state.employerIndustry
        const employeeCount = this.state.employeeCount
        const employeeGrowth = this.state.employeeGrowth
        const employerLocation = this.state.employerLocation

        let accountCode = ''
        if (!this.state.accountCode || this.state.accountCode === '') {
          if (employerName) {
            accountCode = employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
          } else {
            return this.setState({ clientErrorMessage: 'There was an issue detecting the employer name. Contact help@guidedcompass.com.', isSaving: false })
          }
        } else {
          accountCode = this.state.accountCode
        }

        const projectedHires = this.state.projectedHires
        const subsidyApproved = this.state.subsidyApproved
        const subsidyAward = this.state.subsidyAward
        const employerCulture = this.state.employerCulture
        const description = this.state.description
        let sharePartners = this.state.sharePartners
        if (sharePartners && this.state.sharePartners.length > 0) {
          console.log('show sharePartners 1: ', sharePartners)
          if (!sharePartners.includes(this.state.org)) {
            sharePartners.push(this.state.org)
          }
        } else {
          console.log('show sharePartners 2: ', sharePartners)
          sharePartners = [this.state.org]
        }

        const pathways = this.state.pathways
        const orgContacts = this.state.orgContacts
        const contactFirstName = this.state.contactFirstName
        const contactLastName = this.state.contactLastName
        const contactTitle = this.state.contactTitle
        const contactEmail = this.state.contactEmail
        const contactPhone = this.state.contactPhone

        const includeHighSchool = this.state.includeHighSchool
        const includeAssociates = this.state.includeAssociates
        const includeBachelors = this.state.includeBachelors
        const hasFunds = this.state.hasFunds
        const successMetrics = this.state.successMetrics
        const volunteerPreferences = this.state.volunteerPreferences

        const officeType = this.state.officeType
        const politicalAlignment = this.state.politicalAlignment
        const stateRegistration = this.state.stateRegistration
        const congressionalDistrict = this.state.congressionalDistrict
        // const hasFunds = this.state.hasFunds
        const hostUndocumented = this.state.hostUndocumented

        const activeOrg = this.state.org

        const createdAt = new Date()
        const updatedAt = new Date()

        console.log('about to save')

        Axios.post('/api/account/update', {
          _id, employerName, employerURL, employerType, employerIndustry, employeeCount, employeeGrowth,
          employerLocation, accountCode, projectedHires, subsidyApproved, subsidyAward,
          employerCulture, description, sharePartners,
          contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone,
          orgContacts, pathways,
          includeHighSchool, includeAssociates, includeBachelors, activeOrg,
          hasFunds, successMetrics, volunteerPreferences,
          officeType, politicalAlignment, stateRegistration, congressionalDistrict, hostUndocumented,
          createdAt, updatedAt })
        .then((response) => {
          console.log('saving employer now')

          if (response.data.success) {
            //save values
            console.log('Post save worked', response.data);

            if (this.state.employerLogoFile) {
              this.saveFile("employerLogo", this.state.employerLogoFile, response.data.accountCode)
            }

            if (this.state.employerLogoWhiteFile) {
              this.saveFile("employerLogoWhite", this.state.employerLogoWhiteFile, response.data.accountCode)
            }

            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Employer saved successfully!', isSaving: false
            })

          } else {
            console.error('there was an error saving the employer', response.data.message);

            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message, isSaving: false
            })
          }
        }).catch((error) => {
            console.log('The employer save did not work', error);
            this.setState({ serverPostSuccess: false, serverErrorMessage: 'error', isSaving: false })
        });
      }
    }

    renderSuccessOptions() {
      console.log('renderSuccessOptions called', this.state.successOptions, this.state.successMetrics)

      let rows = []
      for (let i = 1; i <= this.state.successOptions.length; i++) {

        const index = i - 1

        let sm = false
        if (this.state.successMetrics) {
          if (this.state.successMetrics.includes(this.state.successOptions[i - 1])) {
            sm = true
          }
        }

        rows.push(
          <div key={i} className="relative-column-33 right-padding">
            <label className="profile-label">{this.state.successOptions[i - 1]}</label>
            <Switch
              onChange={(change) => this.switchHandler('success', index, change)}
              checked={sm}
              id="normal-switch"
            />
          </div>
        )
      }
      return rows
    }

    renderVolunteerOptions() {
      console.log('renderVolunteerOptions called')

      let rows = []
      for (let i = 1; i <= this.state.volunteerOptions.length; i++) {

        const index = i - 1

        let vp = false
        if (this.state.volunteerPreferences) {
          if (this.state.volunteerPreferences.includes(this.state.volunteerOptions[i - 1])) {
            console.log('does it include?')
            vp = true
          }
        }

        rows.push(
          <div key={i} className="relative-column-33 right-padding">
            <label className="profile-label">{this.state.volunteerOptions[i - 1]}</label>
            <Switch
              onChange={(change) => this.switchHandler('volunteer', index, change)}
              checked={vp}
              id="normal-switch"
            />
          </div>
        )
      }
      return rows
    }

    renderOrgContacts() {
      console.log('renderOrgContacts called', this.state.orgContacts)

      if (this.state.orgContacts) {
        let rows = []

        let counter = 0
        for (let i = 1; i <= this.state.orgContacts.length; i++) {

          if (this.state.orgContacts[i - 1].orgCode === this.state.org) {

            const value = this.state.orgContacts[i - 1]
            const index = i - 1

            counter++

            rows.push(
              <div key={index} className="padding-20 standard-border">
                <div className="fixed-column-50 top-padding">
                  <p className="heading-text-3 cta-color">#{counter}</p>
                </div>
                <div className="calc-column-offset-50">
                  <div className="row-5">
                    <div className="container-left">
                        <label className="profile-label">{this.state.companyName} Contact First Name <label className="error-color">*</label></label>
                        <input type="text" className="text-field" placeholder="Employer contact first name..." name={"employerContactFirstName|" + index} value={value.contactFirstName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                        <label className="profile-label">{this.state.companyName} Contact Last Name <label className="error-color">*</label></label>
                        <input type="text" className="text-field" placeholder="Employer contact last name..." name={"employerContactLastName|" + index} value={value.contactLastName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-5">
                    <div className="container-left">
                        <label className="profile-label">{this.state.companyName} Contact Title <label className="error-color">*</label></label>
                        <input type="text" className="text-field" placeholder="Employer contact title..." name={"employerContactTitle|" + index} value={value.contactTitle} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">{this.state.companyName} Contact Email <label className="error-color">*</label></label>
                      <input type="text" className="text-field" placeholder="Employer contact email..." name={"employerContactEmail|" + index} value={value.contactEmail} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-5">
                    <div className="container-left">
                      <label className="profile-label">{this.state.companyName} Contact Phone <label className="error-color">*</label></label>
                      <input type="text" className="text-field" placeholder="Employer contact phone..." name={"employerContactPhone|" + index} value={value.contactPhone} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />
                  </div>

                  {(this.state.activeOrg === 'dpscd') && (
                    <div>
                      <div className="row-5">
                        <div className="container-left">
                            <label className="profile-label">Org Referrer First Name</label>
                            <input type="text" className="text-field" placeholder="Internal first name..." name={"orgContactFirstName|" + index} value={value.orgContactFirstName} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="container-right">
                            <label className="profile-label">Org Referrer Last Name</label>
                            <input type="text" className="text-field" placeholder="Internal contact last name..." name={"orgContactLastName|" + index} value={value.orgContactLastName} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-5">
                        <div className="container-left">
                          <label className="profile-label">Org Referrer Contact Email</label>
                          <input type="text" className="text-field" placeholder="Internal contact email..." name={"orgContactEmail|" + index} value={value.orgContactEmail} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}

                </div>
                <div className="clear" />
              </div>
            )
          }
        }

        return rows
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false })
    }

    renderPathways() {
      console.log('renderPathways called')

      let rows = []
      for (let i = 1; i <= this.state.pathwayOptions.length; i++) {

        let value = this.state.pathwayOptions[i - 1]
        let optionIndex = i - 1

        let tagText = ''
        let tagContainerClass = ''
        if (value.school) {
          tagText = value.title + " _ " + value.school
          if (this.state.pathways.some(p => (p.title + ' - ' + p.school) === (value.title + ' - ' + value.school))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        } else if (value.title) {
          tagText = value.title
          if (this.state.pathways.some(p => (p.title) === (value.title))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        } else {
          tagText = value.name
          if (this.state.pathways.some(p => (p.name) === (value.name))) {
            tagContainerClass = "background-button tag-container-4 float-left top-margin-5 left-margin-5 white-text"
          } else {
            tagContainerClass = "background-button tag-container-5"
          }
        }

        rows.push(
          <div key={"pathway" + i}>
            <div key={optionIndex}>
              <div className="float-left row-10 right-padding">
                <button className={tagContainerClass} onClick={() => this.optionClicked(value, optionIndex,'pathway')}>
                  <div>
                    <div className="float-left left-text">
                      <p className="description-text-2">{tagText}</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )
      }

      return rows
    }

    optionClicked(value, index, type) {
      console.log('optionClicked called', value, index, type)

      if (type === 'pathway') {
        let pathways = this.state.pathways
        if (pathways && pathways.length > 0) {

          let removeIndex = -1
          if (!value.name && !value.title) {
            removeIndex = pathways.indexOf(value)
          } else if (!value.name && value.title) {
            removeIndex = pathways.findIndex(p => p.title === value.title)
          } else if (value.name && !value.title) {
            removeIndex = pathways.findIndex(p => p.name === value.name)
          }
          // console.log('show the logs: ', removeIndex1, removeIndex2, removeIndex3)
          if (removeIndex > -1) {
            pathways.splice(removeIndex, 1)
          } else {
            pathways.push(value)
          }
        } else {
          pathways.push(value)
        }
        this.setState({ pathways, formHasChanged: true })
      } else if (type === 'department') {
        let departments = this.state.departments
        if (departments && departments.length > 0) {

          if (departments.includes(value)) {

            const removeIndex = departments.indexOf(value)
            departments.splice(removeIndex, 1)

          } else {
            departments.push(value)
          }
        } else {
          departments.push(value)
        }
        this.setState({ departments, formHasChanged: true })
      }
    }

    render() {

      return (
          <div>
              <div className="standard-container-2">
                  <div className="row-10">
                    <p className="heading-text-2">{(this.state.employerName) ? this.state.employerName : "New Employer"}</p>
                  </div>

                  {(this.state._id) ? (
                    <div>
                      <div className="row-10">
                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showPreview: true })}>
                          <div className="fixed-column-30">
                            <img className="image-auto-22" alt="GC" src={studentIconDark} />
                          </div>
                          <div className="calc-column-offset-30 cta-color bold-text">
                            <p>View as Career-Seeker</p>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p className="subtitle">Shareable Link</p>

                        <div className="edit-profile-row">
                          <label className="profile-label">Share this link with employers for them to sign up:</label>
                          <a href={window.location.protocol + "//" + window.location.host + "/employers/post/" + this.state.org} target="_blank">{window.location.protocol + "//" + window.location.host + "/employers/post/" + this.state.org}</a>

                          <div className="spacer" />

                          <div className="clear" />
                        </div>
                      </div>

                      <hr className="cell-separator-advisor"/>
                    </div>
                  )}

                  <div className="row-10">
                    <div className="row-10">
                      <p className="subtitle">Basic Info</p>
                    </div>

                    <div className="row-10">
                      <div className="standard-border">
                        <div className="container-left">
                          <div className="upload-image">
                            <div className="row-10 description-text-2 full-width center-text">
                              <p>Add a logo for light backgrounds</p>
                            </div>

                            <div className="relative-position">
                              <label for="employerLogo" className="profile-pic-button">
                                <img src={ this.state.employerLogoImage ? ( this.state.employerLogoImage )
                                  : this.state.employerLogoURI ? ( this.state.employerLogoURI )
                                  : ( exampleLogoLight)}
                                alt="Compass add logo" for="employerLogo" className="logo-image-largish center-item"/>
                                <div className="bottom-right-overlay">
                                  <div className="bottom-right-subcontainer-2">
                                    <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </label>
                              <input type="file" id="employerLogo" name="employerLogo" onChange={this.formChangeHandler} accept="image/*" />
                            </div>

                            <div className="clear" />

                            <div className="row-10 description-text-4 full-width center-text">
                              <p>Preferred Dimensions: 150 x 150</p>
                            </div>

                            { (this.state.serverPostSuccess) ? (
                              <p className="success-message">{this.state.serverSuccessMessage}</p>
                            ) : (
                              <p className="error-message">{this.state.serverErrorMessage}</p>
                            )}
                          </div>
                        </div>
                        <div className="container-right dark-background white-text horizontal-padding-5">
                          <div className="upload-image">
                            <div className="row-10 description-text-2 full-width center-text">
                              <p>Add a logo for dark backgrounds</p>
                            </div>

                            <div className="relative-position">
                              <label for="employerLogoWhite" className="profile-pic-button">
                                <img src={ this.state.employerLogoWhiteImage ? ( this.state.employerLogoWhiteImage )
                                  : this.state.employerLogoWhiteURI ? ( this.state.employerLogoWhiteURI )
                                  : ( exampleLogoDark)}
                                alt="Compass add logo" for="employerLogoWhite" className="logo-image-largish center-item" />
                                <div className="bottom-right-overlay">
                                  <div className="bottom-right-subcontainer-2">
                                    <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </label>
                              <input type="file" id="employerLogoWhite" name="employerLogoWhite" onChange={this.formChangeHandler} accept="image/*" />
                            </div>

                            <div className="clear" />

                            <div className="row-10 description-text-4 full-width center-text">
                              <p>Preferred Dimensions: 150 x 150</p>
                            </div>

                            { (this.state.serverPostSuccess) ? (
                              <p className="success-message">{this.state.serverSuccessMessage}</p>
                            ) : (
                              <p className="error-message">{this.state.serverErrorMessage}</p>
                            )}

                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Employer Name <label className="error-color">*</label></label>
                        {(this.state.allowChanges) ? (
                          <div>
                            <input type="text" className="text-field" placeholder="Employer name..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}></input>
                          </div>
                        ) : (
                          <div>
                            <label className="heading-text-5">{this.state.employerName}</label>
                          </div>
                        )}
                        {(this.state.employers && this.state.employers.length > 0) && (
                          <div>
                            <div className="clear" />
                            <div className="spacer" />
                            {this.state.employers.map(value =>
                              <div key={value._id} className="left-left">
                                <button className="background-button" onClick={() => this.employerClicked(value)}>
                                  <div>
                                    <div className="float-left right-margin">
                                      <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                                    </div>
                                    <div className="float-left">
                                      <p className="cta-color">{value.employerName}</p>
                                    </div>
                                  </div>
                                </button>
                                <div className="half-spacer" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {(this.state._id) && (
                        <div className="container-right">
                          <label className="profile-label">Account Code</label>
                          <label className="heading-text-5">{this.state.accountCode}</label>
                        </div>
                      )}

                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Employer URL</label>
                        <input type="text" className="text-field" placeholder="Employer url..." name="employerURL" value={this.state.employerURL} onChange={this.formChangeHandler}></input>
                      </div>

                      <div className="container-right">
                        <label className="profile-label">Type <label className="error-color">*</label></label>
                        <select name="employerType" value={this.state.employerType} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.typeOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Industry <label className="error-color">*</label></label>
                        <select name="employerIndustry" value={this.state.employerIndustry} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.industryOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                        </select>
                      </div>

                      <div className="container-right">
                        <label className="profile-label">Number of Employees <label className="error-color">*</label></label>
                        <select name="employeeCount" value={this.state.employeeCount} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.countOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                        </select>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Y/Y Employee Growth</label>
                        <select name="employeeGrowth" value={this.state.employeeGrowth} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.growthOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                        </select>
                      </div>

                      <div className="container-right">
                        <label className="profile-label">Headquarters Work Address</label>
                        <input type="text" className="text-field" placeholder="Add full work address..." name="employerLocation" value={this.state.employerLocation} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    {((this.state.pathwayOptions) && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
                      <div>
                        <div className="row-10">
                          <label className="profile-label">Pathways</label>
                          {this.renderPathways()}
                          <div className="clear" />
                        </div>
                      </div>
                    )}
                  </div>

                  <hr className="cell-separator-advisor"/>
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <p className="subtitle">Projected Hires</p>
                    <div className="half-spacer" />

                    <div className="container-left">
                        <label className="profile-label">{new Date().getFullYear()} Projected Hires (Number)</label>
                        {/*
                        <select name="projectedHires" value={this.state.projectedHires} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.hireOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                        </select>*/}
                        <input className="number-field" type="number" placeholder="0" name="projectedHires" value={this.state.projectedHires} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="clear"/>

                    {(this.state.org === 'bixel') && (
                      <div>
                        <div className="relative-column-33 right-padding">
                          <label className="profile-label">High School Students</label>
                          <Switch
                            onChange={(change) => this.setState({ includeHighSchool: change, formHasChanged: true })}
                            checked={this.state.includeHighSchool}
                            id="normal-switch"
                          />
                        </div>

                        <div className="relative-column-33 right-padding">
                          <label className="profile-label">2-Year College Students</label>
                          <Switch
                            onChange={(change) => this.setState({ includeAssociates: change, formHasChanged: true })}
                            checked={this.state.includeAssociates}
                            id="normal-switch"
                          />
                        </div>

                        <div className="relative-column-33 right-padding">
                          <label className="profile-label">4-Year College Students</label>
                          <Switch
                            onChange={(change) => this.setState({ includeBachelors: change, formHasChanged: true })}
                            checked={this.state.includeBachelors}
                            id="normal-switch"
                          />
                        </div>
                        <div className="clear"/>
                      </div>
                    )}

                  </div>

                  <div className="spacer" />

                  {(this.state.org === 'bixel') && (
                    <div>
                      <hr className="cell-separator-advisor"/>
                      <div>
                        <p className="subtitle">Subsidies</p>
                        <div className="half-spacer" />

                        <div className="edit-profile-row">
                          <label className="profile-label">Has Funds</label>
                          <Switch
                            onChange={(change) => this.setState({ hasFunds: change, formHasChanged: true })}
                            checked={this.state.hasFunds}
                            id="normal-switch"
                          />
                        </div>
                        <div className="clear"/>
                        <div className="container-left">
                          <label className="profile-label">Subsidy Approved</label>
                          <Switch
                            onChange={(change) => this.setState({ subsidyApproved: change, formHasChanged: true })}
                            checked={this.state.subsidyApproved}
                            id="normal-switch"
                          />
                        </div>
                        { (this.state.subsidyApproved) && (
                          <div className="container-right">
                              <label className="profile-label">Total Subsidy Award</label>
                              <input type="text" className="text-field" placeholder="Add award..." name="subsidyAward" value={this.state.subsidyAward} onChange={this.formChangeHandler}></input>
                          </div>
                        )}
                        <div className="clear"/>
                      </div>
                    </div>
                  )}

                  <div className="spacer" />
                  <hr className="cell-separator-advisor"/>
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <p className="subtitle">Contact Info</p>
                    <div className="half-spacer" />

                    {(this.state.showOrgContacts) ? (
                      <div>
                        {this.renderOrgContacts()}
                      </div>
                    ) : (
                      <div>
                        <div className="name-container">
                            <label className="profile-label">Contact First Name</label>
                            <input type="text" className="text-field" placeholder="Add first name..." name="contactFirstName" value={this.state.contactFirstName} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Contact Last Name</label>
                            <input type="text" className="text-field" placeholder="Add last name..." name="contactLastName" value={this.state.contactLastName} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Contact Title</label>
                            <input type="text" className="text-field" placeholder="Add position / title..." name="contactTitle" value={this.state.contactTitle} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Contact Email</label>
                            <input type="text" className="text-field" placeholder="Add email..." name="contactEmail" value={this.state.contactEmail} onChange={this.formChangeHandler}></input>
                        </div>
                        <div className="name-container">
                            <label className="profile-label">Contact Phone</label>
                            <input type="text" className="text-field" placeholder="Add phone..." name="contactPhone" value={this.state.contactPhone} onChange={this.formChangeHandler}></input>
                        </div>
                      </div>
                    )}


                    <div className="clear"/>
                  </div>

                  <div className="spacer" />
                  <hr className="cell-separator-advisor"/>

                  {(this.state.org === 'bixel') && (
                    <div>
                      <div>
                        <p className="subtitle">Measuring Success</p>
                        <div className="spacer" />
                        {this.renderSuccessOptions()}
                        <div className="clear"/>
                      </div>

                      <div className="spacer" />
                      <hr className="cell-separator-advisor"/>

                      <div>
                        <p className="subtitle">Volunteering</p>
                        <div className="spacer" />
                        {this.renderVolunteerOptions()}
                        <div className="clear"/>
                      </div>

                      <div className="spacer" />
                      <hr className="cell-separator-advisor"/>
                    </div>
                  )}

                  {(this.state.org === 'c2c') && (
                    <div>
                      <div className="name-container">
                          <label className="profile-label">Office Type</label>
                          <select name="officeType" value={this.state.officeType} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.officeTypeOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                      </div>
                      <div className="name-container">
                          <label className="profile-label">Political Alignment</label>
                          <select name="politicalAlignment" className="dropdown" value={this.state.politicalAlignment} onChange={this.formChangeHandler}>
                              {this.state.politicalAlignmentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                      </div>
                      <div className="name-container">
                          <label className="profile-label">State Registration</label>
                          <select name="stateRegistration" className="dropdown" value={this.state.stateRegistration} onChange={this.formChangeHandler}>
                              {this.state.registrationOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                      </div>
                      <div className="name-container">
                          <label className="profile-label">Current Congressional District</label>
                          <input className="text-field" type="text" placeholder="e.g. 12" name="congressionalDistrict" value={this.state.congressionalDistrict} onChange={this.formChangeHandler}/>
                      </div>

                      <div className="name-container">
                          <label className="profile-label">Are these internship positions paid?</label>
                          <select name="hasFunds" value={this.state.hasFunds} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.binaryOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                      </div>
                      <div className="name-container">
                          <label className="profile-label">Host undocumented students?</label>
                          <select name="hostUndocumented" value={this.state.hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.maybeOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                      </div>
                      <div className="clear" />
                    </div>
                  )}

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <p className="subtitle">Employer Description (Optional)</p>
                    <div className="row-10">
                      <label className="profile-label">Employer Description / Overview <label className="error-color">*</label></label>
                      <textarea type="text" className="text-field" placeholder="Describe the company..." name="description" value={this.state.description} onChange={this.formChangeHandler}></textarea>
                    </div>
                    <div className="row-10">
                      <label className="profile-label">Employer Culture</label>
                      <textarea type="text" className="text-field" placeholder="Describe the company culture..." name="employerCulture" value={this.state.employerCulture} onChange={this.formChangeHandler}></textarea>
                    </div>
                  </div>

                  <div className="spacer" />
                  <hr className="cell-separator-advisor"/>
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <p className="subtitle">Hire Evaluations & Feedback</p>
                    <div className="half-spacer" />
                    <p className="profile-label">No feedback provided yet</p>
                  </div>
                  <div className="spacer" />

                  { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                  { (this.state.serverPostSuccess) ? (
                    <p className="success-message">{this.state.serverSuccessMessage}</p>
                  ) : (
                    <p className="error-message">{this.state.serverErrorMessage}</p>
                  )}
                  <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.saveEmployer()}>Save Employer</button>

              </div>

              {(this.state.showPreview) && (
                <div>
                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                   ariaHideApp={false}
                 >
                   <SubEmployerDetails selectedEmployer={this.state.selectedEmployer} employerId={this.state._id} history={this.props.navigate} closeModal={this.closeModal} inModal={true} />

                 </Modal>
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(EditEmployer);
