import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditEmployer from '../Subcomponents/EditEmployer';
import withRouter from '../Functions/WithRouter';

class OrgAddEmployer extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      const orgContacts = [{ orgCode: org }]

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, orgLogo,
        orgContacts, orgFocus
      });
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <SubEditEmployer org={this.state.org} selectedEmployer={null} />
            <div className="clear" />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgAddEmployer)
