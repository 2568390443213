import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import SubConfigureProfileMatch from '../Common/ConfigureProfileMatch';
import SubRenderOpportunities from '../Common/RenderOpportunities';
import SubRenderProfiles from '../Common/RenderProfiles';
import SubRenderCourses from '../Common/RenderCourses';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {requestConnection} from '../Services/FriendRoutes';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const coverLetterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png';
const checkmarkIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png";
const shareIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const favoriteIconSelected  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const benchmarksIconDark  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';
const targetIconWhite  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-white.png';

class PathwayDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          subNavSelected: 'All',
          subNavCategories: ['All','About','Ideal Profile','People','Programs & Courses','Employers','Opportunities'],
          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          friends: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.translateValue = this.translateValue.bind(this)
        this.translateWeight = this.translateWeight.bind(this)
        this.translateTraits = this.translateTraits.bind(this)
        this.returnColorClass = this.returnColorClass.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.followPerson = this.followPerson.bind(this)
        this.pullCourses = this.pullCourses.bind(this)

        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.configureVideo = this.configureVideo.bind(this)
        this.renderEmployers = this.renderEmployers.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in subPathwayDetails ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.selectedTemplate !== prevProps.selectedTemplate) {
        // if (!prevProps.selectedTemplate) {
        //   this.retrieveData()
        // } else if (this.props.selectedTemplate.title !== prevProps.selectedTemplate) {
        //   this.retrieveData()
        // }
      } else if (this.props.pathwayId !== prevProps.pathwayId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subPathwayDetails', this.props)
      // greeting, communication, posture and eye contact, appearance, behavioral questions, interest / motivation, competencies

      // const org = this.props.activeOrg
      // const accountCode = this.props.accountCode
      const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let org = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let placementPartners = localStorage.getItem('placementPartners');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('accountCode');
      // console.log('show orgName --: ', orgName, roleName, accountCode)

      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else {
        accountCode = null
      }

      // console.log('org: ', org)
      let isLoading = true

      let subNavCategories = this.state.subNavCategories
      if (!email) {
        subNavCategories = ['All','About','Ideal Profile','Programs & Courses','Employers','Opportunities']
      }
      if (employerAccount) {
        subNavCategories = ['All','About','Ideal Profile','People','Programs & Courses','Opportunities']
      }


      let subNavSelected = 'All'

      this.setState({ isLoading, orgCode: org, selectedTemplate, emailId: email,
        email, username, cuFirstName, cuLastName, subNavCategories, subNavSelected,
        employerAccount
      })

      Axios.get('/api/favorites', { params: { emailId: email } })
     .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           let favorites = []
           if (response.data.favorites) {
             favorites = response.data.favorites
           }

           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           this.setState({ favorites, newFavorites })

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       Axios.get('/api/applications', { params: { emailId: email, orgCode: org } })
       .then((response) => {
         console.log('Applications query attempted');

         if (response.data.success) {
           console.log('applications query worked')

           if (response.data.applications.length !== 0) {
             let applications = response.data.applications
             //jobs.push(response.data.postings[0])
             this.setState({ applications });
           }

         } else {
           console.log('query for applications query did not work', response.data.message)
         }

       }).catch((error) => {
           console.log('Applications query did not work for some reason', error);
       });

       Axios.get('/api/rsvps', { params: { emailId: email } })
       .then((response) => {
         console.log('Rsvp query attempted');

         if (response.data.success) {
           console.log('rsvp query worked')

           let rsvps = response.data.rsvps
           this.setState({ rsvps })


         } else {
           console.log('rsvp query did not work', response.data.message)
           //there is no rsvp data

         }

       }).catch((error) => {
           console.log('Rsvp query did not work for some reason', error);
       });

      if (this.props.pathwayId) {
        Axios.get('/api/pathways/byid', { params: { _id: this.props.pathwayId } })
        .then((response) => {
          console.log('Pathways query attempted', response.data);

          if (response.data.success) {
            console.log('pathway query worked')

            const selectedPathway = response.data.pathway
            // if (selectedPathway && selectedPathway.programs && selectedPathway.programs.length > 0) {
            //   subNavCategories = ['All','About','Ideal Profile','Programs & Courses','Employers','Opportunities']
            // }

            this.setState({ selectedPathway, subNavCategories, isLoading: false })

            let orgCode = org
            if (!orgCode) {
              orgCode = selectedPathway.orgCode
            }
            console.log('what orgCode: ', orgCode)
            Axios.get('/api/pathways', { params: { orgCode } })
            .then((response) => {
              console.log('Pathways query attempted no 1', response.data);

              if (response.data.success) {
                console.log('pathway query worked no 1')

                if (response.data.pathways.length !== 0) {

                  let otherPathways = []
                  for (let i = 1; i <= response.data.pathways.length; i++) {
                    if (response.data.pathways[i - 1]._id !== selectedPathway._id) {
                      otherPathways.push(response.data.pathways[i - 1])
                    }
                  }

                  this.setState({ otherPathways });
                }

              } else {
                console.log('pathway query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Pathway query did not work for some reason', error);
            });

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedPathway.benchmarkId } })
            .then((response) => {
              console.log('Benchmarks query attempted', response.data);

              if (response.data.success) {
                console.log('benchmark query worked')

                const selectedBenchmark = response.data.benchmark

                let workPreferenceTags = []
                if (selectedBenchmark.functionsOfInterest && selectedBenchmark.functionsOfInterest.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.functionsOfInterest)
                }
                if (selectedBenchmark.industriesOfInterest && selectedBenchmark.industriesOfInterest.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.industriesOfInterest)
                }
                if (selectedBenchmark.technologyTrends && selectedBenchmark.technologyTrends.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.technologyTrends)
                }
                if (selectedBenchmark.additionalTechnologyTrends && selectedBenchmark.additionalTechnologyTrends.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalTechnologyTrends)
                }
                if (selectedBenchmark.societalProblems && selectedBenchmark.societalProblems.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.societalProblems)
                }
                if (selectedBenchmark.additionalSocietalProblems && selectedBenchmark.additionalSocietalProblems.length > 0) {
                  workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.additionalSocietalProblems)
                }
                if (selectedBenchmark.maxPay && selectedBenchmark.maxPay !== '') {
                  if (selectedBenchmark.maxPay === 'Wide Range') {
                    workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay + ' of Pay')
                  } else {
                    workPreferenceTags = workPreferenceTags.concat(selectedBenchmark.maxPay)
                  }
                }

                // sort interests
                let interests = []
                if (selectedBenchmark.interests && selectedBenchmark.interests.length > 0) {
                  interests = selectedBenchmark.interests
                  interests.sort(function(a,b) {
                    return b.score - a.score;
                  })
                }

                // sort traits
                let traits = []
                if (selectedBenchmark.traits && selectedBenchmark.traits.length > 0) {
                  traits = selectedBenchmark.traits
                  traits.sort(function(a,b) {
                    return b.score - a.score;
                  })
                }

                let knowledge = []
                if (selectedBenchmark.knowledge && selectedBenchmark.knowledge.length > 0) {
                  for (let i = 1; i <= selectedBenchmark.knowledge.length; i++) {
                    knowledge.push(selectedBenchmark.knowledge[i - 1].title)
                  }
                }

                this.setState({ selectedBenchmark, workPreferenceTags, interests, traits, knowledge });

                this.pullCourses(selectedBenchmark.jobFunction, null, null, null)

              } else {
                console.log('benchmark query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
            });

            const resLimit = 50
            Axios.get('/api/get-followers', { params: { _id: selectedPathway._id, resLimit } })
            .then((response) => {
              console.log('Followers query attempted');

              if (response.data.success) {
                console.log('followers query worked in sub settings')

                const followers = response.data.followers
                this.setState({ followers })
              }

            }).catch((error) => {
              console.log('Followers query did not work for some reason', error);
            });

            Axios.get('/api/friends', { params: { orgCode: org, emailId: email } })
            .then((response) => {
              console.log('Friends query attempted', response.data);

                if (response.data.success) {
                  console.log('friends query worked')

                  const friends = response.data.friends
                  this.setState({ friends })

                } else {
                  console.log('friends query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('Friends query did not work for some reason', error);
            })

            const postTypes = ['Event','Internship','Work']
            const pullPartnerPosts = true
            const pathway = selectedPathway.title

            Axios.get('/api/postings/user', { params: { orgCode, placementPartners, pullPartnerPosts, postTypes, pathway }})
            .then((response) => {
              console.log('Sub postings query attempted now', response.data);

              if (response.data.success) {
                console.log('posted postings query worked')

                if (response.data.postings && response.data.postings.length !== 0) {

                  const postings = response.data.postings
                  const filteredPostings = postings
                  this.setState({ postings, filteredPostings });
                }

              } else {
                console.log('posted postings query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Posted postings query did not work for some reason', error);
            });

            // let resLimit = 250
            const sortAlphabetically = true
            Axios.get('/api/account/partners', { params: { org: orgCode, placementPartners, resLimit, sortAlphabetically, pathway } })
            .then((response) => {
              console.log('Posted employer query attempted', response.data);

              if (response.data.success) {
                console.log('posted employer query worked')

                if (response.data.employers.length !== 0) {
                  const employers = response.data.employers
                  let filteredEmployers = employers
                  this.setState({ employers, filteredEmployers });
                }

              } else {
                console.log('query for employers query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Employer query did not work for some reason', error);

            });

            Axios.get('/api/courses', { params: { pathwayId: selectedPathway._id, orgCode: selectedPathway.orgCode } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Course details for pathway query worked', response.data);

                  if (response.data.courses) {
                    let orgCourses = response.data.courses
                    this.setState({ orgCourses })
                  }
                } else {
                  console.log('no courses found for pathway', response.data.message)
                }

            }).catch((error) => {
                console.log('Course query did not work for pathway', error);
            });

          } else {
            console.log('pathway query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathwaay query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    translateValue(score, toWords) {
      console.log('translateValue called', score, toWords)

      //interests
      if (toWords) {
        let value = ''

        if (Number(score) >= 5) {
          value = 'Highly Interested'
        } else if (Number(score) >= 4) {
          value = 'Interested'
        } else if (Number(score) >= 3) {
          value = 'Somewhat Interested'
        } else if (Number(score) >= 2) {
          value = 'Uninterested'
        } else if (Number(score) >= 1) {
          value = 'Highly Uninterested'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        console.log('show score: ', score)
        let value = 0
        if (score === 'Highly Interested') {
          value = 5
        } else if (score === 'Interested') {
          value = 4
        } else if (score === 'Somewhat Interested') {
          value = 3
        } else if (score === 'Uninterested') {
          value = 2
        } else if (score === 'Highly Uninterested') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value
      }
    }

    translateWeight(weight, toWords) {
      console.log('translateWeight called ', weight, toWords)

      if (toWords) {
        let value = ''
        if (Number(weight) >= 4) {
          value = 'Super Important'
        } else if (Number(weight) >= 4) {
          value = 'Very Important'
        } else if (Number(weight) >= 3) {
          value = 'Important'
        } else if (Number(weight) >= 2) {
          value = 'Somewhat Important'
        } else if (Number(weight) >= 1) {
          value = 'Slightly Important'
        } else if (Number(weight) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0

        if (weight === 'Super Important') {
          value = 5
        } else if (weight === 'Very Important') {
          value = 4
        } else if (weight === 'Important') {
          value = 3
        } else if (weight === 'Somewhat Important') {
          value = 2
        } else if (weight === 'Slightly Important') {
          value = 1
        } else if (weight === 'Not a Factor') {
          value = 0
        }
        console.log('show score in translate: ', weight, value)
        return value
      }
    }

    translateTraits(score, toWords) {
      console.log('translateTraits called', score, toWords)

      if (toWords) {
        let value = ''
        if (Number(score) >= 5) {
          value = 'Very High'
        } else if (Number(score) >= 4) {
          value = 'High'
        } else if (Number(score) >= 3) {
          value = 'Moderate'
        } else if (Number(score) >= 2) {
          value = 'Low'
        } else if (Number(score) >= 1) {
          value = 'Very Low'
        } else if (Number(score) < 1) {
          value = 'Not a Factor'
        }

        return value
      } else {
        let value = 0
        if (score === 'Very High') {
          value = 5
        } else if (score === 'High') {
          value = 4
        } else if (score === 'Moderate') {
          value = 3
        } else if (score === 'Low') {
          value = 2
        } else if (score === 'Very Low') {
          value = 1
        } else if (score === 'Not a Factor') {
          value = 0
        }

        return value

      }
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      Axios.get('/api/courses/search', { params: { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue } })
      .then((response) => {
        console.log('Courses query attempted');

          if (response.data.success) {
            console.log('successfully retrieved courses', response.data.responseData)

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              this.setState({ courses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    renderTags(type, passedArray, limit) {
      console.log('renderTags: ', type, passedArray, limit)

      let backgroundColorClass = "primary-background"
      let includeLink = false
      if (type === 'gravitateValues') {
        backgroundColorClass = "secondary-background"
      } else if (type === 'employerValues') {
        backgroundColorClass = "tertiary-background"
      } else if (type === 'hardSkills') {
        backgroundColorClass = "quaternary-background"
      } else if (type === 'softSkills') {
        backgroundColorClass = "quinary-background"
      } else if (type === 'workPreferenceTags') {
        backgroundColorClass = "nonary-background"
      } else if (type === 'knowledge') {
        backgroundColorClass = "denary-background"
      } else if (type === 'courses') {
        backgroundColorClass = "denary-background"
        includeLink = true
      }

      if (passedArray) {
        if (includeLink) {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  {(!limit || (limit && optionIndex < limit)) && (
                    <a className="float-left right-padding-5" target="_blank" rel="noopener noreferrer" href={value.url}>
                      <div className="half-spacer" />
                      <div className={"tag-container-basic " + backgroundColorClass}>
                        <p className="description-text-2 white-text">{value.name}</p>
                      </div>
                      <div className="half-spacer" />
                    </a>
                  )}
                </div>
              )}
            </div>
          )
        } else {
          return (
            <div key={type + "|0"}>
              <div className="spacer" />
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className="float-left">
                  {(!limit || (limit && optionIndex < limit)) && (
                    <div className="float-left right-padding-5">
                      <div className="half-spacer" />
                      <div className={"tag-container-basic " + backgroundColorClass}>
                        <p className="description-text-2 white-text">{value}</p>
                      </div>
                      <div className="half-spacer" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }
    }

    returnColorClass(index,type) {
      console.log('returnColorClass called', index, type)

      let colorClass = "nonary-background"
      if (type === 'text') {
        if (index === 0) {
          colorClass = 'nonary-text'
        } else if (index === 1) {
          colorClass = 'primary-text'
        } else if (index === 2) {
          colorClass = 'twelve-text'
        } else if (index === 3) {
          colorClass = 'denary-text'
        } else if (index === 4) {
          colorClass = 'thirteen-text'
        } else if (index === 5) {
          colorClass = 'secondary-text'
        }
      } else if (type === 'background') {
        if (index === 0) {
          colorClass = 'nonary-background'
        } else if (index === 1) {
          colorClass = 'primary-background'
        } else if (index === 2) {
          colorClass = 'twelve-background'
        } else if (index === 3) {
          colorClass = 'denary-background'
        } else if (index === 4) {
          colorClass = 'thirteen-background'
        } else if (index === 5) {
          colorClass = 'secondary-background'
        }
      }
      return colorClass
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, showEndorsementDetails: false, showShareButtons: false,
        showMatch: false, showGoal: false
      })
    }

    followPerson(e, person) {
      console.log('followPerson called', e, person)

      e.stopPropagation()
      e.preventDefault()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      const recipientHeadline = person.headline
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      let friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName }

      // Axios.post('/api/friend/request', friend)
      // .then((response) => {
      //
      //   if (response.data.success) {
      //
      //     friend['active'] = false
      //     friend['friend2Email'] = recipientEmail
      //
      //     let friends = this.state.friends
      //     friends.push(friend)
      //     console.log('show friends: ', friends)
      //     this.setState({ successMessage: response.data.message, friends })
      //
      //   } else {
      //
      //     this.setState({ errorMessage: response.data.message })
      //   }
      // }).catch((error) => {
      //     console.log('Advisee request send did not work', error);
      // });

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          friends.push(friend)
          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }


    async favoriteItem(e, item, type) {
      console.log('favoriteItem called', item, type, this.state.members)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,type)

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites
        if (favoritesArray.includes(itemId)) {
          const index = favoritesArray.indexOf(itemId)
          favoritesArray.splice(index, 1)
        } else {
          favoritesArray.push(itemId)
        }

        const pathway = item

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId, pathway
        })
        .then((response) => {
          console.log('attempting to save addition to favorites')
          if (response.data.success) {
            console.log('saved addition to favorites', response.data)

            this.setState({ successMessage: 'Saved as a favorite!', favorites: favoritesArray, isSaving: false })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
        });
      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={this.state.shareURL} subject={this.state.shareTitle} body={this.state.shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={this.state.shareURL} title={this.state.shareTitle} summary={(this.state.shareBody) ? this.state.shareBody : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={this.state.shareURL} description={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    configureVideo(videoLink) {
      console.log('configureVideo called')

      if (videoLink) {
        if (videoLink.includes("v=")) {
          videoLink = videoLink.split("v=")[1]
        } else if (videoLink.includes("/embed")) {
          videoLink = videoLink.split("/embed/")[1]
        }

        return videoLink
      }
    }

    renderEmployers() {
      console.log('renderEmployers called')

      let rows = []

      if (this.state.filteredEmployers && this.state.filteredEmployers.length > 0) {
        for (let i = 1; i <= this.state.filteredEmployers.length; i++) {

          const item = this.state.filteredEmployers[i - 1]
          const index = i - 1

          let subtitle = ''
          let pathname = '/app/employers/' + this.state.filteredEmployers[index]._id
          // let passedState = { member: this.state.filteredEmployers[index] }
          let updatedAtString = ''
          if (this.state.filteredEmployers[i - 1].updatedAt) {
            const year = new Date(this.state.filteredEmployers[i - 1].updatedAt).getFullYear()
            const month = new Date(this.state.filteredEmployers[i - 1].updatedAt).getMonth()
            const day = new Date(this.state.filteredEmployers[i - 1].updatedAt).getDate()
            const formattedMonth = convertDateToString(month,'formatMonth')
            updatedAtString = formattedMonth + ' ' + day + ', ' + year

            subtitle = 'Last Update: ' + updatedAtString
            if (this.state.filteredEmployers[i - 1].employerIndustry) {
              subtitle = this.state.filteredEmployers[i - 1].employerIndustry + ' | ' + subtitle
            }
          }

          let thirdTitle = null
          if (this.state.filteredEmployers[i - 1].employeeCount) {
            thirdTitle = this.state.filteredEmployers[i - 1].employeeCount
            if (!this.state.filteredEmployers[i - 1].employeeCount.includes('employees')) {
              thirdTitle = thirdTitle + ' employees'
            }
          }
          if (this.state.filteredEmployers[i - 1].employerLocation) {
            if (thirdTitle) {
              thirdTitle = thirdTitle + ' | ' + this.state.filteredEmployers[i - 1].employerLocation
            } else {
              thirdTitle = this.state.filteredEmployers[i - 1].employerLocation
            }
          }

          if (this.props.path && this.props.path.includes('/advisor')) {
            pathname = this.props.path + '/' + this.state.filteredEmployers[index]._id
          }

          // if (item.orgContacts && item.orgContacts.length > 0) {
          //   orgContacts = item.orgContacts
          // }

          let mainColumnClass = "calc-column-offset-100-static"
          if (this.props.pageSource === 'BrowseEmployers' || (item.orgCode === 'general' && item.orgCode !== this.state.org)) {
            mainColumnClass = "calc-column-offset-130"
            if (this.props.pageSource === 'BrowseEmployers') {
              pathname = '/app/employers/' + this.state.filteredEmployers[index]._id
            }
          }

          if (this.state.pageSource === 'landingPage') {
            pathname = '/employers/' + this.state.filteredEmployers[index]._id
          }

          rows.push(
            <div key={i}>
                <div className="top-padding-20 bottom-padding-30">
                  <Link className="background-link" to={pathname} state={{ selectedEmployer: item, employers: this.state.filteredEmployers }}>
                    <span className="fixed-column-70">
                      <img src={(item.employerLogoURI) ? item.employerLogoURI : industryIconDark} alt="GC" className="image-50-auto"/>
                    </span>
                    <span className={mainColumnClass}>
                      <p className="heading-text-5">{item.employerName}</p>
                      <p className="description-text-2 description-text-color top-padding-5">{subtitle}</p>
                      {(thirdTitle) && (
                        <p className="description-text-2 error-color top-padding-5">{thirdTitle}</p>
                      )}
                    </span>
                    {(this.props.pageSource !== 'landingPage') && (
                      <div className="fixed-column-30 top-margin">
                        <button className="background-button" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.favoriteItem(e, item, 'employer')}>
                          <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20 pin-right"/>
                        </button>
                      </div>
                    )}
                    <div className="fixed-column-30 top-margin">
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-20 pin-right"/>
                    </div>
                  </Link>
                </div>

                <ReactTooltip id="tooltip-placeholder-id" />

                <div className="clear" />
                {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[i - 1] && this.state.sortCriteriaArray[i - 1].name) && (
                  <div className="left-padding-110">
                    <div className="half-spacer" />
                    <p className="description-text-2 error-color">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
                  </div>
                )}
                {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[i - 1] && this.state.filterCriteriaArray[i - 1].name) && (
                  <div className="left-padding-110">
                    <div className="half-spacer" />
                    <p className="description-text-2 error-color">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                  </div>
                )}
                <hr className="cell-separator-advisor"/>

            </div>
          )
        }
      } else {

        rows.push(
          <div key={"no-followers"}>
            <p>No employers have been attached to this pathway yet.</p>
          </div>
        )
      }

      return rows
    }

    render() {

      return (
          <div>
            {(this.state.selectedPathway) && (
              <div>
                {(this.state.isLoading) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Loading...</p>

                    </div>
                  </div>
                ) : (
                  <div>

                    <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                      <div>
                        <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                          <div className="full-width height-5 primary-background" />
                          <div className="padding-40">
                            <div className="row-10">
                              <div className="fixed-column-90">
                                <img src={(this.state.selectedPathway.imageURL) ? this.state.selectedPathway.imageURL : pathsIconDark} alt="GC" className="image-auto-80" />
                              </div>
                              <div className="calc-column-offset-140 left-padding top-padding-5">
                                <p className="heading-text-2">{this.state.selectedPathway.title}</p>
                                {(this.state.selectedPathway.description) && (
                                  <p className="description-text-1 description-text-color row-10">{this.state.selectedPathway.description}</p>
                                )}

                                <div className="top-padding-20">
                                  {/*
                                  {(window.location.pathname.includes('/app/pathways')) && (
                                    <button className="btn btn-squarish denary-background cta-border cta-color left-margin-15" onClick={() => window.open(this.state.selectedPathway.documentation)}>
                                      <div className="float-left">
                                        <div className='mini-spacer' /><div className='mini-spacer' />
                                        <img src={coverLetterIconDark} alt="GC" className="image-auto-19 white-image" />
                                      </div>
                                      <div className="float-left left-padding white-text">Adopt</div>
                                      <div className="clear" />
                                    </button>
                                  )}*/}

                                  {(window.location.pathname.includes('/app/pathways')) && (
                                    <button className="btn btn-squarish right-margin" onClick={() => this.setState({ modalIsOpen: true, showGoal: true })}>
                                      <div className="float-left">
                                        <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                        {(this.state.adoptions && this.state.adoptions.includes(this.state.selectedPathway._id)) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={targetIconWhite} alt="GC" className="image-auto-15" />}
                                      </div>
                                      <div className="float-left left-padding">
                                        {(this.state.adoptions && this.state.adoptions.includes(this.state.selectedPathway._id)) ? "Adopted" : "Adopt"}
                                      </div>
                                      <div className="clear" />
                                    </button>
                                  )}

                                  <button className={(window.location.pathname.includes('/app/pathways') || window.location.pathname.includes('/advisor/pathways')) ? "btn btn-squarish white-background cta-color left-margin-5" : "btn btn-squarish white-background cta-color"} onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true })}>
                                    <div className="float-left">
                                      <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                      <img src={shareIconDark} alt="GC" className="image-auto-15" />
                                    </div>
                                    <div className="float-left left-padding">Share</div>
                                    <div className="clear" />
                                  </button>

                                  {(this.state.selectedPathway && this.state.selectedPathway.benchmarkId && window.location.pathname.includes('/app/pathways')) && (
                                    <button className="btn btn-squarish senary-background cta-border cta-color left-margin-15" onClick={() => this.setState({ modalIsOpen: true, showMatch: true })}>
                                      <div className="float-left">
                                        <div className='mini-spacer' /><div className='mini-spacer' />
                                        <img src={benchmarksIconDark} alt="GC" className="image-auto-19" />
                                      </div>
                                      <div className="float-left left-padding">View Match</div>
                                      <div className="clear" />
                                    </button>
                                  )}

                                  {(this.state.selectedPathway && this.state.selectedPathway.documentation) && (
                                    <button className="btn btn-squarish denary-background cta-border cta-color left-margin-15" onClick={() => window.open(this.state.selectedPathway.documentation)}>
                                      <div className="float-left">
                                        <div className='mini-spacer' /><div className='mini-spacer' />
                                        <img src={coverLetterIconDark} alt="GC" className="image-auto-19 white-image" />
                                      </div>
                                      <div className="float-left left-padding white-text">Documentation</div>
                                      <div className="clear" />
                                    </button>
                                  )}

                                </div>
                              </div>
                              <div className="fixed-column-50">
                                <button className="background-button top-margin" onClick={() => this.favoriteItem(null, this.state.selectedPathway, 'pathway')}>
                                  {(this.state.favorites.includes(this.state.selectedPathway._id)) ? <img src={favoritesIconBlue} alt="GC" className="image-auto-15" /> : <img src={favoritesIconGrey} alt="GC" className="image-auto-15" />}
                                </button>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 medium-shadow top-margin-2-percent pin-right" : "medium-shadow slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40 "}>
                            <div className="carousel-3 white-background" onScroll={this.handleScroll}>
                              {this.state.subNavCategories.map((value, index) =>
                                <div className="carousel-item-container">
                                  {(value === this.state.subNavSelected) ? (
                                    <div className="selected-carousel-item">
                                      <label key={value}>{value}</label>
                                    </div>
                                  ) : (
                                    <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                      <label key={value}>{value}</label>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'About') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <p className="heading-text-3 bottom-padding">About {this.state.selectedPathway.title}</p>

                              {(this.state.selectedPathway.videos && this.state.selectedPathway.videos.length > 0) ? (
                                <div>
                                  {this.state.selectedPathway.videos.map((value, index) =>
                                    <div key={value}>
                                      <div>
                                        <div className="top-margin-20">
                                          <div className={(index % 2 === 0) ? "container-left" : "container-right"}>
                                            <div className="video-container-4">
                                              {(this.state.useYouTubeAPI) ? (
                                                <YouTube
                                                  videoId={this.configureVideo(value)}
                                                  id={value._id}
                                                  className="video-iframe-4"
                                                  containerClassName={""}
                                                  opts={(e) => this.videoCallback(e,'opts')}
                                                  onReady={(e) => this.videoCallback(e,'onReady')}
                                                  onPlay={(e) => this.videoCallback(e,'onPlay')}
                                                  onPause={(e) => this.videoCallback(e,'onPause')}
                                                  onEnd={(e) => this.videoCallback(e,'onEnd')}
                                                  onError={(e) => this.videoCallback(e,'onError')}
                                                  onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                                  onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                                  onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                                />
                                              ) : (
                                                <iframe
                                                  title="videoLink"
                                                  className="video-iframe"
                                                  src={value}
                                                  frameBorder="0"
                                                />
                                              )}
                                            </div>
                                            <div className="clear"/>
                                          </div>

                                          {(index % 2 === 1) && (
                                            <div className="clear" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                </div>
                              ) : (
                                <div>
                                  <p className="description-text-color">No Videos Yet</p>
                                </div>
                              )}

                              <div className="clear" />
                              <div className="spacer" />

                              {(this.state.selectedPathway.description) && (
                                <p className="description-text-1 description-text-color row-10">{this.state.selectedPathway.description}</p>
                              )}

                              {(this.state.selectedPathway.stats && this.state.selectedPathway.stats.length > 0) && (
                                <div className="row-10">
                                  <div className="row-10">
                                    <p>Relevant Stats</p>
                                  </div>

                                  {this.state.selectedPathway.stats.map((value, index) =>
                                    <div key={value} className="bottom-margin">
                                      <div className="float-left">
                                        <p className="description-text-color">{value.name}</p>
                                      </div>
                                      <div className="float-left left-padding">
                                        <p>{value.value}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.subNavSelected === 'Ideal Profile' || this.state.subNavSelected === 'All') && (
                            <div>
                              <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                                <div className="bottom-margin">
                                  <div className="float-left right-padding">
                                    <p className="heading-text-3">Ideal Candidate (Benchmark)</p>
                                  </div>
                                  <div className="float-left noti-bubble-info-7-9">
                                    <a className="background-link" target="_blank" rel="noopener noreferrer" href={window.location.protocol + "//" + window.location.host + "/help/what-are-benchmarks"}>
                                      <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                                    </a>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="spacer" />

                                {(this.state.selectedBenchmark) && (
                                  <div>
                                    {(this.state.selectedBenchmark.workPreferenceWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Work Preferences (Self-Assessment)</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.workPreferenceWeight) ? "(" + this.state.selectedBenchmark.workPreferenceWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">What the ideal candidate explicitly says they want in their work, work style, and work environment</p>
                                        </div>

                                        {(this.state.workPreferenceTags && this.state.workPreferenceTags.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('workPreferenceTags', this.state.workPreferenceTags)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.workPreferencesMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.workPreferencesMessage}"</p>
                                          </div>
                                        )}

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.selectedBenchmark.interestsWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Interests (Self-Assessment)</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.interestsWeight) ? "(" + this.state.selectedBenchmark.interestsWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">Candidate general interests and strong interest inventory</p>
                                        </div>

                                        {(this.state.selectedBenchmark.generalInterests && this.state.selectedBenchmark.generalInterests.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('generalInterests', this.state.selectedBenchmark.generalInterests)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.interests && this.state.interests.length > 0) && (
                                          <div className="standard-border padding-30 top-margin-20">
                                            <p className="heading-text-5">Strong Interest Inventory Results</p>
                                            <p className="row-5 description-text-2">Strong Interest Inventory is a popular interest assessment used by career counselors. Learn more <a href="https://en.wikipedia.org/wiki/Strong_Interest_Inventory" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                            <div className="spacer" /><div className="spacer" />
                                            {this.state.interests.map((value, optionIndex) =>
                                              <div key={value}>
                                                <div className="fixed-column-130">
                                                  <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                                                </div>
                                                <div className="calc-column-offset-190 top-padding-5">
                                                  <div className="mini-spacer" />
                                                  <div className="progress-bar clear-border" >
                                                    <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                                                  </div>
                                                </div>
                                                <div className="fixed-column-60">
                                                  <p className={"full-width right-text " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                                                </div>
                                                <div className="clear" />
                                                {(optionIndex + 1 !== this.state.interests.length) && (
                                                  <div className="row-10">
                                                    <hr />
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        <div className="spacer" />

                                        {(this.state.selectedBenchmark.interestsMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.interestsMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.personalityWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Personality (Self-Assessment)</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.personalityWeight) ? "(" + this.state.selectedBenchmark.personalityWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">The big five, 16 personalities, and other personality traits</p>
                                        </div>

                                        {(this.state.selectedBenchmark.additionalTraits && this.state.selectedBenchmark.additionalTraits.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('additionalTraits', this.state.selectedBenchmark.additionalTraits)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.myersBriggs) && (
                                          <div className="standard-border padding-30 top-margin-20">
                                            <p className="heading-text-5">16 Personalities Results</p>
                                            <p className="row-5 description-text-2">16 personalities / myers briggs is a popular way for career counselors to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Myers–Briggs_Type_Indicator" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                            <div className="spacer" />
                                            <p className="heading-text-2 cta-color">{this.state.selectedBenchmark.myersBriggs}</p>
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.traits && this.state.traits.length > 0) && (
                                          <div className="standard-border padding-30 top-margin-20">
                                            <p className="heading-text-5">Big Five Results</p>
                                            <p className="row-5 description-text-2">The Big Five personality traits is a popular way for career counselors and psychologists to distinguish how people have different personalities. Learn more <a href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                            <div className="spacer" /><div className="spacer" />
                                            {this.state.traits.map((value, optionIndex) =>
                                              <div key={value}>
                                                <div className={(window.innerWidth > 768) ? "fixed-column-180" : "full-width"}>
                                                  <p className={this.returnColorClass(optionIndex,'text')}>{value.title}</p>
                                                </div>
                                                <div className={(window.innerWidth > 768) ? "calc-column-offset-240 top-padding-5" : "full-width top-padding-5"}>
                                                  <div className="mini-spacer" />
                                                  <div className="progress-bar clear-border" >
                                                    <div className={"filler " + this.returnColorClass(optionIndex,'background')} style={(value.score) ? { width: (value.score * 20).toString() + '%' }: { width: '0%' }} />
                                                  </div>
                                                </div>
                                                <div className="fixed-column-60">
                                                  <p className={(window.innerWidth > 768) ? "full-width right-text " + this.returnColorClass(optionIndex,'text') : "full-width " + this.returnColorClass(optionIndex,'text')}>{(value.score) ? (value.score * 20).toString() + '%' : '0%'}</p>
                                                </div>
                                                <div className="clear" />
                                                {(optionIndex + 1 !== this.state.traits.length) && (
                                                  <div className="row-10">
                                                    <hr />
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.personalityMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.personalityMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.valuesWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Values (Self-Assessment)</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.valuesWeight) ? "(" + this.state.selectedBenchmark.valuesWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">Ranked values on who candidate gravitates towards and what their employer prefers</p>
                                          <div className="spacer" /><div className="spacer" />
                                        </div>

                                        {(this.state.selectedBenchmark.gravitateValues && this.state.selectedBenchmark.gravitateValues.length > 0) && (
                                          <div className="row-10">
                                            <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate gravitates toward people who are:</p>
                                            {this.renderTags('gravitateValues', this.state.selectedBenchmark.gravitateValues, 5)}
                                            <div className="clear" />
                                          </div>
                                        )}
                                        {(this.state.selectedBenchmark.employerValues && this.state.selectedBenchmark.employerValues.length > 0) && (
                                          <div className="row-10">
                                            <p>The ideal{(this.state.selectedBenchmark.jobFunction) && " " + this.state.selectedBenchmark.jobFunction.toLowerCase()} candidate wants to work with employers that provide:</p>
                                            {this.renderTags('employerValues', this.state.selectedBenchmark.employerValues, 5)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.valuesMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.valuesMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.selectedBenchmark.skillsWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Skills (Self-Assessment)</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.skillsWeight) ? "(" + this.state.selectedBenchmark.skillsWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">The top skills that matter</p>
                                          <div className="spacer" />
                                        </div>

                                        <div className="row-10">
                                          <div className="container-left">
                                            {((this.state.selectedBenchmark.highPriorityHardSkills && this.state.selectedBenchmark.highPriorityHardSkills.length > 0) || (this.state.selectedBenchmark.lowPriorityHardSkills && this.state.selectedBenchmark.lowPriorityHardSkills.length > 0)) && (
                                              <div className="row-10">
                                                <p>Top hard skills:</p>

                                                {(this.state.selectedBenchmark.lowPriorityHardSkills) ? (
                                                  <div>
                                                    {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills.concat(this.state.selectedBenchmark.lowPriorityHardSkills), 6)}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {this.renderTags('hardSkills', this.state.selectedBenchmark.highPriorityHardSkills, 6)}
                                                  </div>
                                                )}
                                                <div className="clear" />
                                              </div>
                                            )}
                                          </div>
                                          <div className="container-right">
                                            {((this.state.selectedBenchmark.highPrioritySoftSkills && this.state.selectedBenchmark.highPrioritySoftSkills.length > 0) || (this.state.selectedBenchmark.lowPrioritySoftSkills && this.state.selectedBenchmark.lowPrioritySoftSkills.length > 0)) && (
                                              <div className="row-10">
                                                <p>Top soft skills:</p>

                                                {(this.state.selectedBenchmark.lowPrioritySoftSkills) ? (
                                                  <div>
                                                    {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills.concat(this.state.selectedBenchmark.lowPrioritySoftSkills), 6)}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {this.renderTags('softSkills', this.state.selectedBenchmark.highPrioritySoftSkills, 6)}
                                                  </div>
                                                )}
                                                <div className="clear" />
                                              </div>
                                            )}
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        {(this.state.selectedBenchmark.skillCourses && this.state.selectedBenchmark.skillCourses.length > 0) && (
                                          <div className="row-10">
                                            <label className="bold-text">Suggested Courses: </label>

                                            {this.state.selectedBenchmark.skillCourses.map((value, optionIndex) =>
                                              <span key={value} className="right-padding">
                                                <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                                {(optionIndex + 1 !== this.state.selectedBenchmark.skillCourses.length) && (
                                                  <label>,</label>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.skillCertifications && this.state.selectedBenchmark.skillCertifications.length > 0) && (
                                          <div className="row-10">
                                            <label className="bold-text">Suggested Certifications / Badges: </label>

                                            {this.state.selectedBenchmark.skillCertifications.map((value, optionIndex) =>
                                              <span key={value} className="right-padding">
                                                <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                                {(optionIndex + 1 !== this.state.selectedBenchmark.skillCertifications.length) && (
                                                  <label>,</label>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.skillsMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.skillsMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.educationWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Education</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.educationWeight) ? "(" + this.state.selectedBenchmark.educationWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">The educational components that matter</p>

                                        </div>

                                        {(this.state.knowledge && this.state.knowledge.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('knowledge', this.state.knowledge)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        <div className="spacer"/>

                                        {(this.state.selectedBenchmark.degreeRequirements) && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Degree Requirements:</label> {this.state.selectedBenchmark.degreeRequirements}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.idealMajors && this.state.selectedBenchmark.idealMajors.length > 0) && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Ideal Majors:</label> {this.state.selectedBenchmark.idealMajors.toString().replace(/,/g,", ")}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.gpaRange && this.state.selectedBenchmark.gpaRange !== '') && (
                                          <div className="row-10">
                                            <p><label className="bold-text">GPA Range:</label> {this.state.selectedBenchmark.gpaRange}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.gradYearRange && this.state.selectedBenchmark.gradYearRange !== '') && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Grad Year Range:</label> {this.state.selectedBenchmark.gradYearRange}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.testScores && this.state.selectedBenchmark.testScores !== '') && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Standardized Test Scores:</label> {this.state.selectedBenchmark.testScores}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.courseHours && this.state.selectedBenchmark.courseHours !== '') && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Hours of Relevant Coursework Completed:</label> {this.state.selectedBenchmark.courseHours}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.courses && this.state.selectedBenchmark.courses.length > 0) && (
                                          <div className="row-10">
                                            <label className="bold-text">Suggested Courses: </label>

                                            {this.state.selectedBenchmark.courses.map((value, optionIndex) =>
                                              <span key={value} className="right-padding">
                                                <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                                {(optionIndex + 1 !== this.state.selectedBenchmark.courses.length) && (
                                                  <label>,</label>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.certifications && this.state.selectedBenchmark.certifications.length > 0) && (
                                          <div className="row-10">
                                            <label className="bold-text">Suggested Certifications / Badges: </label>

                                            {this.state.selectedBenchmark.certifications.map((value, optionIndex) =>
                                              <span key={value} className="right-padding">
                                                <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                                {(optionIndex + 1 !== this.state.selectedBenchmark.certifications.length) && (
                                                  <label>,</label>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                        <div className="spacer"/>

                                        {(this.state.selectedBenchmark.educationMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.educationMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.projectWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Projects</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.projectWeight) ? "(" + this.state.selectedBenchmark.projectWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">Importance of relevant projects, and what type of project work</p>
                                        </div>

                                        {(this.state.selectedBenchmark.projectTags && this.state.selectedBenchmark.projectTags.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('projectTags', this.state.selectedBenchmark.projectTags)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.projectHours && this.state.selectedBenchmark.projectHours !== '') && (
                                          <div className="row-10">
                                            <p><label className="bold-text">Recommended Invested Hours:</label> {this.state.selectedBenchmark.projectHours}</p>
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.exampleProjects && this.state.selectedBenchmark.exampleProjects.length > 0) && (
                                          <div className="row-10">
                                            <label className="bold-text">Example Impressive Projects: </label>

                                            {this.state.selectedBenchmark.exampleProjects.map((value, optionIndex) =>
                                              <span key={value} className="right-padding">
                                                <a href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                                {(optionIndex + 1 !== this.state.selectedBenchmark.exampleProjects.length) && (
                                                  <label>,</label>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.projectsMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.projectsMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.selectedBenchmark.experienceWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-4">Experience</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-4 cta-color bold-text">{(this.state.selectedBenchmark.experienceWeight) ? "(" + this.state.selectedBenchmark.experienceWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">Importance of relevant experience, and what type of experience</p>
                                        </div>

                                        {(this.state.selectedBenchmark.experienceTags && this.state.selectedBenchmark.experienceTags.length > 0) && (
                                          <div className="row-10">
                                            {this.renderTags('projectTags', this.state.selectedBenchmark.experienceTags)}
                                            <div className="clear" />
                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.experienceMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.experienceMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.selectedBenchmark.resumeWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-4">Resume</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-4 cta-color bold-text">{(this.state.selectedBenchmark.resumeWeight) ? "(" + this.state.selectedBenchmark.resumeWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's resume</p>
                                        </div>

                                        {(this.state.selectedBenchmark.resumeMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.resumeMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.selectedBenchmark.coverLetterWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Cover Letter</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.coverLetterWeight) ? "(" + this.state.selectedBenchmark.coverLetterWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">What matters on the ideal candidate's cover letter</p>
                                        </div>

                                        {(this.state.selectedBenchmark.coverLetterMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.coverLetterMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.interviewWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Interview</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.interviewWeight) ? "(" + this.state.selectedBenchmark.interviewWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">What matters in the ideal candidate's interview</p>
                                        </div>

                                        <div className="spacer"/>

                                        {(this.state.selectedBenchmark.interviewRubric && this.state.selectedBenchmark.interviewRubric.length > 0) && (
                                          <div className="row-20">
                                            <p className="heading-text-5">Interview Rubric</p>
                                            <div className="spacer"/>

                                            <div className="standard-border">
                                              <div className="bold-text standard-border-bottom ">
                                                <div className="relative-column-33 padding-20">
                                                  <p>Criteria</p>
                                                </div>
                                                <div className="relative-column-33 padding-20">
                                                  <p>Sample Questions</p>
                                                </div>
                                                <div className="relative-column-33 padding-20">
                                                  <p>Qualities of Great Scores</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              {this.state.selectedBenchmark.interviewRubric.map((value, optionIndex) =>
                                                <div key={value}>
                                                  <div className="standard-border-bottom">
                                                    <div className="relative-column-33 padding-20">
                                                      <p className="bold-text">{optionIndex + 1}. {value.criterion}</p>
                                                    </div>
                                                    <div className="relative-column-33 padding-20">
                                                      {value.questions.map((value2, optionIndex2) =>
                                                        <div>
                                                          <p>{value2}</p>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="relative-column-33 padding-20">
                                                      {value.answers.map((value2, optionIndex2) =>
                                                        <div>
                                                          <p>{value2}</p>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="clear" />
                                                  </div>
                                                </div>
                                              )}
                                            </div>

                                          </div>
                                        )}

                                        {(this.state.selectedBenchmark.interviewMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.interviewMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.selectedBenchmark.adversityScoreWeight > 0) && (
                                      <div>
                                        <div className="row-10">
                                          <div className="float-left">
                                            <p className="heading-text-5">Diversity</p>
                                          </div>
                                          <div className="float-left left-padding">
                                            <p className="heading-text-5 cta-color bold-text">{(this.state.selectedBenchmark.adversityScoreWeight) ? "(" + this.state.selectedBenchmark.adversityScoreWeight + "%)" : "(0%)"}</p>
                                          </div>
                                          <div className="clear" />
                                          <p className="description-text-1 description-text-color top-padding">What valuable diverse candidates look like to us</p>
                                        </div>

                                        {(this.state.selectedBenchmark.adversityScoreMessage) && (
                                          <div className="row-10">
                                            <p className="italicize-text">"{this.state.selectedBenchmark.adversityScoreMessage}"</p>
                                          </div>
                                        )}

                                        <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                                        <div className="row-10">
                                          <hr />
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                )}

                              </div>
                            </div>
                          )}

                          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'People') && (
                            <div className={(window.innerWidth > 768) ? "slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                              {(this.state.followers && this.state.followers.length > 0) ? (
                                <div>
                                  <SubRenderProfiles
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    members={this.state.followers} friends={this.state.friends}
                                    pageSource={this.props.pageSource} history={this.props.navigate} userType="Peers"
                                  />
                                </div>
                              ) : (
                                <div key={"no-followers"} className="card">
                                  <p>No one has followed/joined this pathway yet.</p>
                                </div>
                              )}
                            </div>
                          )}

                          {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Programs & Courses') && (this.state.selectedPathway.courses && this.state.selectedPathway.courses.length > 0)) && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              <div className="row-10">
                                <p className="heading-text-5">{this.state.selectedPathway.title} Training Programs & Courses</p>
                                <div className="spacer" />

                                {(this.state.orgCourses && this.state.orgCourses.length > 0) && (
                                  <div>
                                    {this.state.orgCourses.map((item, index) =>
                                      <div key={item}>
                                        <div className="bottom-padding">
                                          <div className="spacer" />
                                          <a href={item.programURL} target="_blank" className="background-link">
                                            <div className="fixed-column-30">
                                              <p className="description-text-2 standard-color">{index + 1}.</p>
                                            </div>
                                            <div className="calc-column-offset-70 left-padding-5">
                                              <p>{item.name} @ {item.schoolName}</p>
                                              <p className="description-text-2 standard-color">{item.degreeType} | {item.programMethod} | {item.careerPathway}</p>
                                              <p className="description-text-2 standard-color">{item.sampleJobTitles}</p>
                                              {/*<p className="description-text-2 standard-color">10 - 50 hours | 5/5 satisfaction rating</p>*/}
                                            </div>
                                          </a>
                                          <div className="float-left left-padding">
                                            <span className="float-right">
                                              <div className="spacer"/>
                                              <a href={item.programURL} target="_blank" className="background-link">
                                                <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                              </a>
                                            </span>
                                          </div>
                                          <div className="clear"/>

                                          <div className="spacer" /><div className="spacer" />
                                          <hr />
                                          <div className="clear"/>
                                          <div className="spacer" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          {(!this.state.selectedPathway.courses || this.state.selectedPathway.courses.length === 0) && (
                            <div>
                              {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Programs & Courses') && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                                  {(this.state.courses && this.state.courses.length > 0) ? (
                                    <div className="row-10">
                                      <p className="heading-text-5">Related Udemy Courses</p>
                                      <div className="spacer" />

                                      <SubRenderCourses
                                        history={this.props.navigate} animating={this.state.animating}
                                        courses={this.state.courses} pageSource={this.props.pageSource}
                                        sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                      />
                                    </div>
                                  ) : (
                                    <div className="row-10">
                                      <p className="heading-text-6 error-color">No programs or courses have been recommended for this pathway.</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Employers') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              {this.renderEmployers()}
                            </div>
                          )}

                          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Opportunities') && (
                            <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 padding-40 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally padding-40 top-margin-40"}>
                              {(this.state.filteredPostings && this.state.filteredPostings.length > 0) ? (
                                <div>
                                  <SubRenderOpportunities oppType="all" postings={this.state.filteredPostings} history={this.props.navigate}
                                    rsvps={this.state.rsvps} applications={this.state.applications}
                                    favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                    sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    activeOrg={this.state.orgCode}
                                  />
                                </div>
                              ) : (
                                <div key={"no-followers"}>
                                  <p>No opportunities have been attached to this pathway yet.</p>
                                </div>
                              )}

                            </div>
                          )}

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        </div>
                      </div>
                    </div>

                    {(window.innerWidth > 768) && (
                      <div className="relative-column-30 horizontal-padding top-margin-40">
                        <div className="left-padding-20">

                          {(this.state.otherPathways && this.state.otherPathways.length > 0) && (
                            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                              <div className="full-width height-5 senary-background" />
                              <div className="padding-20">
                                <p className="heading-text-5">Other Pathways</p>

                                <div className="spacer" /><div className="half-spacer" />

                                {this.state.otherPathways.map((item, index) =>
                                  <div key={item}>
                                    {(index < 3) && (
                                      <div className="bottom-padding">
                                        <div className="spacer" />
                                        <Link to={'/app/pathways/' + item._id} state={{ selectedPathway: item }} className="background-link">
                                          <div className="fixed-column-50">
                                            <img src={(item.imageURL) ? item.imageURL : pathsIconDark} alt="GC" className="image-auto-50 center-item" />
                                          </div>
                                          <div className="calc-column-offset-90 left-padding-5">
                                            <p>{item.title}</p>
                                            <p className="description-text-2 standard-color">{item.jobFunction}</p>
                                          </div>
                                        </Link>
                                        <div className="float-left left-padding">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <Link to={'/app/pathways/' + item._id} state={{ selectedPathway: item }} className="background-link">
                                              <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                            </Link>
                                          </span>
                                        </div>
                                        <div className="clear"/>

                                        <div className="spacer" /><div className="spacer" />
                                        <hr />
                                        <div className="clear"/>
                                        <div className="spacer" />
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(this.state.otherPathways.length > 0) && (
                                  <div>
                                    <Link className="background-button full-width right-text" to={'/app/exploration'} state={{ subNavSelected: 'Pathways' }}>
                                      <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="clear" />

                  </div>
                )}

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >

                 {(this.state.showShareButtons) && (
                   <div key="showDescription" className="full-width padding-20 center-text">
                      <p className="heading-text-2">Share the {this.state.selectedPathway.title} Page with Friends!</p>

                      <div className="top-padding-20">
                        <p>Share this link:</p>
                        <a href={this.state.shareURL} target="_blank">{this.state.shareURL}</a>
                      </div>

                      <div className="spacer" />

                      <div className="top-padding-20">
                        {this.renderShareButtons()}
                      </div>

                    </div>
                 )}

                 {(this.state.showMatch) && (
                   <div key="showDescription" className="full-width">
                      <SubConfigureProfileMatch title={this.state.selectedPathway.title} workFunction={this.state.selectedPathway.jobFunction} selectedBenchmark={this.state.selectedBenchmark} closeModal={this.closeModal} matchType="Pathway" />
                    </div>
                 )}

                 {(this.state.showGoal) && (
                   <div key="showGoal" className="full-width">
                     <p className="heading-text-2">Adopt the {this.state.selectedPathway.title}{(this.state.selectedPathway.title && !this.state.selectedPathway.title.includes("Pathway")) && " Pathway"}?</p>
                     <p className="description-text-1 description-text-color top-margin-20">Adopting the {this.state.selectedPathway.title} pathway will transfer the associated videos, courses, projects, and other relevant roadmap components into your very own SMART goal.</p>

                     <div className="spacer" /><div className="spacer" />

                     <div className="top-padding-20">
                       <button className="btn btn-primary right-margin" onClick={() => this.props.navigate('/app/edit-goals/new', { state: { selectedPathway: this.state.selectedPathway }})}>Yes</button>
                       <button className="btn btn-secondary" onClick={() => this.closeModal()}>Cancel</button>
                     </div>
                    </div>
                 )}

               </Modal>

              </div>
            )}
          </div>

      )
    }
}

export default withRouter(PathwayDetails);
