import React, {Component} from 'react';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import SubEndorsementDetails from '../Common/EndorsementDetails';
import SubConfigureEndorsement from '../Common/ConfigureEndorsement';
import withRouter from '../Functions/WithRouter';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const confidentialityIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/confidentiality-icon.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";

class SendEndorsement extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showConfigureEndorsement: true,
          pathwayIndex: 0,

          isAnonymousContribution: false,
          recipientFirstName: '',
          recipientLastName: '',
          recipientEmail: '',
          relationship: '',

          selectedMembers: [],
          recipients: [],

          showSubEndorsementDetails: true,

          pathwayOptions: [{value: 'Custom'}],
          skillTypeOptions: [],
          relationshipOptions: [],
          yesOrNoOptions: ['Yes or No','Yes','No'],
          hoursOptions: ['0 - 20 Hours','20 - 100 Hours','100 - 1,000 Hours','1,000 - 5,000 Hours',
          '5,000 - 10,000 Hours','10,000 Hours+'],
          traitOptions: [],
          recommendationOptions: [],
          transparencyOptions: ['Students can see endorsement details [Keep transparent]','Students cannot see endorsement details [Keep confidential]'],

          selectedPathway: 'Custom',
          skillTraits: [],
          competencies: [],
          examples: [{ skillTrait: 'Select a Skill', compentency: 'Select a Competency', example: '' }],
          competencyOptions: [],

          checked: [],
          exampleChecked: [],

          hasStudied: 'Yes or No',
          hoursStudied: '0 - 20 Hours',
          hasWorked: 'Yes or No',
          hoursWorked: '0 - 20 Hours',
          hasManaged: 'Yes or No',
          hoursManaged: '0 - 20 Hours',
          hasTaught: 'Yes or No',
          hoursTaught: '0 - 20 Hours',

          isTransparent: true,
          showDelete: false,

          senderFirstName: '',
          senderLastName: '',
          senderEmail: '',
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.pullRecommendationOptions = this.pullRecommendationOptions.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.renderTags = this.renderTags.bind(this)

        this.renderCompetencies = this.renderCompetencies.bind(this)
        this.renderTraits = this.renderTraits.bind(this)
        this.renderExamples = this.renderExamples.bind(this)
        this.addCompetency = this.addCompetency.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.addExample = this.addExample.bind(this)
        this.removeCompetency = this.removeCompetency.bind(this)
        this.removeExample = this.removeExample.bind(this)
        this.renderSpecificCompetencies = this.renderSpecificCompetencies.bind(this)

        this.competencyClicked = this.competencyClicked.bind(this)
        this.searchCompetencies = this.searchCompetencies.bind(this)
        this.passState = this.passState.bind(this)


    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubSendEndorsement')

      if (this.props.orgCode !== prevProps.orgCode || this.props.requestId !== prevProps.requestId) {
        this.retrieveData()
      } else if (this.props.recipient !== prevProps.recipient) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubSendEndorsement', this.props)

      const requestId = this.props.requestId
      let orgCode = this.props.orgCode
      const recipient = this.props.recipient

      // console.log('please???', userId)
      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      let checked = []

      const recommendationOptions = ['','Highly Recommend','Recommend','I Guess I Recommend', 'Only If You Must Hire','Stay Away From This Candidate At All Costs']
      let skillTypeOptions = ['','Hard Skill','Soft Skill']
      let competencyOptions = ['','Hard Skill','Soft Skill','General Skill','Ability','Knowledge','Work Style']

      let recipientFirstName = ''
      let recipientLastName = ''
      let recipientEmail = ''
      let selectedMembers = []
      let recipients = []
      if (recipient) {
        recipientFirstName = recipient.firstName
        recipientLastName = recipient.lastName
        recipientEmail = recipient.email
        selectedMembers = [recipient.firstName + ' ' + recipient.lastName]
        recipients = [recipient]
      }

      this.setState({
        emailId: email, username, cuFirstName, cuLastName, orgFocus, orgCode,
        checked, recommendationOptions, skillTypeOptions, competencyOptions,
        recipientFirstName, recipientLastName, recipientEmail, selectedMembers, recipients
      })

      if (email) {
        Axios.get('/api/users/profile/details/' + email, { params: { emailId: email } })
         .then((response) => {
           console.log('query for profile data worked');

           if (response.data.success) {

             console.log('profile data received', response.data)
             const pictureURL = response.data.user.pictureURL
             const headline = response.data.user.headline

             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName
             let schoolName = response.data.user.school
             if (response.data.user.education && response.data.user.education.length > 0) {
               schoolName = response.data.user.education[0].name
               if (response.data.user.education.length > 1) {
                 for (let i = 1; i <= response.data.user.education.length; i++) {
                   if (response.data.user.education[i - 1] && this.state.education[i - 1].isContinual) {
                     schoolName = response.data.user.education[i - 1].name
                   }
                 }
               }
             }

             this.setState({ pictureURL, headline, jobTitle, employerName, schoolName })

           } else {
             console.log('error response', response.data)
           }

        }).catch((error) => {
             console.log('query for profile info did not work', error);
        })
      }

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const relationshipOptions = [''].concat(response.data.workOptions[0].relationshipOptions)
          this.setState({ relationshipOptions })

        } else {
          console.log('no jobFamilies data found', response.data.message)
        }
      }).catch((error) => {
          console.log('query for work options did not work', error);
      })

      if (orgCode) {
        // pull specific pathways
        Axios.get('/api/org', { params: { orgCode } })
        .then((response2) => {
          console.log('Org info query attempted', response2.data);

            if (response2.data.success) {
              console.log('org info query worked')

              const orgName = response2.data.orgInfo.orgName
              const orgProgramName = response2.data.orgInfo.orgProgramName
              const orgContactEmail = response2.data.orgInfo.contactEmail
              const pathways = response2.data.orgInfo.pathways

              this.setState({ orgCode, orgName, orgProgramName, orgContactEmail });

              // pull benchmarks
              this.pullRecommendationOptions(orgCode, pathways)

            } else {
              console.log('org info query did not work', response2.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

      } else if (requestId) {

        Axios.get('/api/story/byid', { params: { _id: requestId } })
        .then((response) => {
          console.log('Endorsement request query attempted', response.data);

          if (response.data.success) {
            console.log('endorsement query worked')

            if (response.data.endorsementRequest) {

              //firstName, lastName, email, relationship, pathway, skills, traits, firstName, lastName, email
              let recipientFirstName = ''
              if (response.data.endorsementRequest.senderFirstName) {
                recipientFirstName = response.data.endorsementRequest.senderFirstName
              }

              let recipientLastName = ''
              if (response.data.endorsementRequest.senderLastName) {
                recipientLastName = response.data.endorsementRequest.senderLastName
              }
              console.log('testing 1')
              let recipientEmail = ''
              if (response.data.endorsementRequest.senderEmail) {
                console.log('testing 2')
                recipientEmail = response.data.endorsementRequest.senderEmail
              }
              console.log('testing 3 ', recipientEmail)

              let senderFirstName = ''
              if (response.data.endorsementRequest.recipientFirstName) {
                senderFirstName = response.data.endorsementRequest.recipientFirstName
              }

              let senderLastName = ''
              if (response.data.endorsementRequest.recipientLastName) {
                senderLastName = response.data.endorsementRequest.recipientLastName
              }

              let senderEmail = ''
              if (response.data.endorsementRequest.recipientEmail) {
                senderEmail = response.data.endorsementRequest.recipientEmail
              }

              let relationship = ''
              if (response.data.endorsementRequest.relationship) {
                relationship = response.data.endorsementRequest.relationship
              }

              let selectedPathway = ''
              let pathway = ''
              if (response.data.endorsementRequest.pathway) {
                selectedPathway = response.data.endorsementRequest.pathway
                pathway = response.data.endorsementRequest.pathway
              }

              const goalType = response.data.endorsementRequest.goalType
              const opportunityId = response.data.endorsementRequest.opportunityId

              let skillTraits = []
              if (response.data.endorsementRequest.skillTraits) {
                skillTraits = response.data.endorsementRequest.skillTraits
              }

              let competencies = []
              let requestedCompetencies = []
              if (response.data.endorsementRequest.competencies) {
                competencies = response.data.endorsementRequest.competencies
                requestedCompetencies = response.data.endorsementRequest.competencies
              }

              orgCode = response.data.endorsementRequest.orgCode

              this.setState({ recipientFirstName, recipientLastName, recipientEmail,
                senderFirstName, senderLastName, senderEmail, relationship, selectedPathway, goalType, pathway, opportunityId,
                skillTraits, competencies, requestedCompetencies, requestId, orgCode
              })

              this.pullRecommendationOptions(response.data.endorsementRequest.orgCode)

              Axios.get('/api/org', { params: { orgCode: response.data.endorsementRequest.orgCode } })
              .then((response2) => {
                console.log('Org info query attempted', response2.data);

                  if (response2.data.success) {
                    console.log('org info query worked')


                    const requestOrgCode = response2.data.orgInfo.orgCode
                    const requestOrgName = response2.data.orgInfo.orgName
                    const requestOrgProgramName = response2.data.orgInfo.orgProgramName
                    const requestOrgContactEmail = response2.data.orgInfo.contactEmail

                    this.setState({ requestOrgCode, requestOrgName, requestOrgProgramName,
                      requestOrgContactEmail });

                  } else {
                    console.log('org info query did not work', response2.data.message)
                  }

              }).catch((error) => {
                  console.log('Org info query did not work for some reason', error);
              });

            }

          } else {
            console.log('endorsement query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Endorsement query did not work for some reason', error);
        });
      } else {
        this.pullRecommendationOptions('general')
      }
    }

    pullRecommendationOptions(orgCode, pathways) {
      console.log('pullRecommendationOptions called', orgCode)

      if (orgCode !== 'c2c' && orgCode !== 'dpscd') {
        orgCode = 'general'
      }

      Axios.get('/api/benchmarks', { params: { orgCode } })
      .then((response) => {

        if (response.data.success) {
          console.log('Benchmark query worked', response.data);

          let pathwayOptions = [{value: 'Custom', skillTraits: [], competencies: []}]
          let values = []

          for (let i = 1; i <= response.data.benchmarks.length; i++) {

            let value = response.data.benchmarks[i - 1].jobFunction
            let benchmark = response.data.benchmarks[i - 1]

            let meetsCriteria = false
            if (orgCode === 'dpscd' && pathways) {
              value = response.data.benchmarks[i - 1].pathway
              for (let j = 1; j <= pathways.length; j++) {
                if (pathways[j - 1]) {
                  let pathwayName = pathways[j - 1].name
                  if (pathways[j - 1].school) {
                    pathwayName = pathwayName + ' | ' + pathways[j - 1].school
                  }

                  if ((pathwayName === value) && !values.includes(value)) {
                    meetsCriteria = true
                  }
                }
              }

            } else if (!values.includes(value)) {
              meetsCriteria = true
            }

            if (meetsCriteria) {
              values.push(value)

              let competencies = []

              const highPriorityHardSkills = response.data.benchmarks[i - 1].highPriorityHardSkills
              const lowPriorityHardSkills = response.data.benchmarks[i - 1].lowPriorityHardSkills
              const highPrioritySoftSkills = response.data.benchmarks[i - 1].highPrioritySoftSkills
              const lowPrioritySoftSkills = response.data.benchmarks[i - 1].lowPrioritySoftSkills
              const knowledge = response.data.benchmarks[i - 1].knowledge
              // const traits = response.data.benchmarks[i - 1].traits
              const abilities = response.data.benchmarks[i - 1].abilities
              // lowPriorityHardSkills, highPriorityHardSkills, lowPrioritySoftSkills, highPriorityHardSkills, knowledge, traits
              if (highPriorityHardSkills) {
                for (let i = 1; i <= highPriorityHardSkills.length; i++) {
                  competencies.push({ name: highPriorityHardSkills[i - 1], category: 'Hard Skill' })
                }
              }
              if (lowPriorityHardSkills) {
                for (let i = 1; i <= lowPriorityHardSkills.length; i++) {
                  competencies.push({ name: lowPriorityHardSkills[i - 1], category: 'Hard Skill' })
                }
              }
              if (highPrioritySoftSkills) {
                for (let i = 1; i <= highPrioritySoftSkills.length; i++) {
                  competencies.push({ name: highPrioritySoftSkills[i - 1], category: 'Soft Skill' })
                }
              }
              if (lowPriorityHardSkills) {
                for (let i = 1; i <= lowPrioritySoftSkills.length; i++) {
                  competencies.push({ name: lowPrioritySoftSkills[i - 1], category: 'Soft Skill' })
                }
              }
              if (knowledge) {
                for (let i = 1; i <= knowledge.length; i++) {
                  competencies.push({ name: knowledge[i - 1].title, category: 'Knowledge' })
                }
              }
              // if (traits) {
              //   for (let i = 1; i <= traits.length; i++) {
              //     competencies.push({ name: traits[i - 1].title, category: 'Traits' })
              //   }
              // }
              if (abilities) {
                for (let i = 1; i <= abilities.length; i++) {
                  competencies.push({ name: abilities[i - 1].title, category: 'Ability' })
                }
              }
              pathwayOptions.push({ value, competencies, benchmark })
              // if (response.data.benchmarks[i - 1].competencies) {
              //   const competencies = response.data.benchmarks[i - 1].competencies
              //   pathwayOptions.push({ value, competencies, benchmark })
              // } else {
              //
              //   let skillTraits = response.data.benchmarks[i - 1].skills
              //   let skillTraitsObject = []
              //
              //   for (let j = 1; j <= skillTraits.length; j++) {
              //     if (skillTraits[j - 1].title && skillTraits[j - 1].skillType) {
              //       const name = skillTraits[j - 1].title
              //       const skillType = skillTraits[j - 1].skillType
              //       skillTraitsObject.push({ name, skillType })
              //     }
              //   }
              //   // console.log('show value: ', value)
              //   pathwayOptions.push({ value, skillTraits: skillTraitsObject, benchmark })
              // }
            }
          }

          this.setState({ pathwayOptions })


        } else {
          console.log('no benchmarks found', response.data.message)

        }

      }).catch((error) => {
          console.log('Benchmark query did not work', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called ', event.target.name, event.target.value)

      if (event.target.name === 'relationship') {
        this.setState({ relationship: event.target.value })

      } else if (event.target.name === 'anonymousCheckmark') {

          const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
          this.setState({ isAnonymousContribution: value });
      } else if (event.target.name === 'firstName') {
        this.setState({ recipientFirstName: event.target.value })
      } else if (event.target.name === 'lastName') {
        this.setState({ recipientLastName: event.target.value })
      } else if (event.target.name === 'email') {
        this.setState({ recipientEmail: event.target.value })
      } else if (event.target.name === 'senderFirstName') {
        this.setState({ senderFirstName: event.target.value })
      } else if (event.target.name === 'senderLastName') {
        this.setState({ senderLastName: event.target.value })
      } else if (event.target.name === 'senderEmail') {
        this.setState({ senderEmail: event.target.value })
      } else if (event.target.name === 'pathway') {
        let selectedPathway = event.target.value

        let pathwayIndex = 0
        let skillTraits = []
        let competencies = []
        for (let i = 1; i <= this.state.pathwayOptions.length; i++) {
          if (this.state.pathwayOptions[i - 1].value === selectedPathway) {
            skillTraits = this.state.pathwayOptions[i - 1].skillTraits
            competencies = this.state.pathwayOptions[i - 1].competencies
            pathwayIndex = i - 1
          }
        }

        let checked = []
        if (competencies && competencies.length > 0) {
          console.log('in competencies')
          if (competencies.length !== 0) {
            for (let i = 1; i <= competencies.length; i++) {
              checked.push(false)
            }
          }
        } else {
          if (skillTraits.length !== 0) {
            for (let i = 1; i <= skillTraits.length; i++) {
              checked.push(false)
            }
          }
        }

        this.setState({ pathway: selectedPathway, selectedPathway, pathwayIndex, skillTraits, competencies, checked })
      } else if (event.target.name.includes('skillName')) {
        console.log('show me what were working with', this.state.skillTraits)
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1

        const name = event.target.value
        const skillType = skillTraits[index].skillType
        const rating = skillTraits[index].rating
        skillTraits[index] = { name, skillType, rating }
        this.setState({ skillTraits })

        if (skillType !== 'Trait') {
          this.searchCompetencies(event.target.value, skillTraits[index].skillType, index)
        }

      } else if (event.target.name.includes('skillType')) {
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        skillTraits[index] = { name: skillTraits[index].name, skillType: event.target.value, rating: skillTraits[index].rating }
        this.setState({ skillTraits })
      } else if (event.target.name.includes('skillRating')) {
        let skillTraits = this.state.skillTraits
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        skillTraits[index]['rating'] = event.target.value
        this.setState({ skillTraits })
      } else if (event.target.name.includes('exampleSkillTrait')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        examples[index] = { skillTrait: event.target.value, example: examples[index].example }
        this.setState({ examples })
      } else if (event.target.name.includes('competencyName')) {

        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1

        const name = event.target.value
        const category = competencies[index].category
        const rating = competencies[index].rating
        competencies[index] = { name, category, rating }
        this.setState({competencies })

        this.searchCompetencies(event.target.value, competencies[index].category, index)

      } else if (event.target.name.includes('competencyType')) {

        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        competencies[index] = { name: competencies[index].name, category: event.target.value, rating: competencies[index].rating }
        console.log('show competencies: ', event.target.name, index, competencies.length, competencies)
        this.setState({ competencies })
      } else if (event.target.name.includes('competencyRating')) {
        let competencies = this.state.competencies
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        competencies[index]['rating'] = event.target.value
        this.setState({ competencies })
      } else if (event.target.name.includes('exampleCompetency')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        examples[index] = { competency: event.target.value, example: examples[index].example }
        this.setState({ examples })
      } else if (event.target.name.includes('exampleExample')) {
        let examples = this.state.examples
        const nameArray = event.target.name.split("|")
        const index = Number(nameArray[1]) - 1
        if (examples[index].competency) {
          examples[index] = { competency: examples[index].competency, example: event.target.value }
        } else {
          examples[index] = { skillTrait: examples[index].skillTrait, example: event.target.value }
        }

        this.setState({ examples })
      } else if (event.target.name === 'hasStudied') {
        this.setState({ hasStudied: event.target.value })
      } else if (event.target.name === 'hoursStudied') {
        this.setState({ hoursStudied: event.target.value })
      } else if (event.target.name === 'hasWorked') {
        this.setState({ hasWorked: event.target.value })
      } else if (event.target.name === 'hoursWorked') {
        this.setState({ hoursWorked: event.target.value })
      } else if (event.target.name === 'hasManaged') {
        this.setState({ hasManaged: event.target.value })
      } else if (event.target.name === 'hoursManaged') {
        this.setState({ hoursManaged: event.target.value })
      } else if (event.target.name === 'hasTaught') {
        this.setState({ hasTaught: event.target.value })
      } else if (event.target.name === 'hoursTaught') {
        this.setState({ hoursTaught: event.target.value })
      } else if (event.target.name === 'overallRecommendation') {
        this.setState({ overallRecommendation: event.target.value })
      } else if (event.target.name === 'recommendationExplanation') {
        this.setState({ recommendationExplanation: event.target.value })
      } else if (event.target.name === 'isTransparent') {
        this.setState({ isTransparent: event.target.value })
      } else if (event.target.name === 'searchAdvisees') {
        this.searchItems(event.target.name,event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(type,searchString) {
      console.log('searchItems called')

      if (!searchString || searchString === '') {
        this.setState({ searchString: searchString, searchIsAnimatingMembers: false, memberOptions: null })
      } else {
        this.setState({ searchString: searchString, searchIsAnimatingMembers: true })

        let roleNames = ['Student','Career-Seeker']

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter called', searchString)

          const orgCode = self.state.orgCode

          //school district orgs
          Axios.get('/api/members/search', { params: { roleNames, searchString, orgCode } })
          .then((response) => {

              if (response.data.success) {
                console.log('Member query worked', response.data);

                self.setState({ memberOptions: response.data.members, searchIsAnimatingMembers: false })

              } else {
                console.log('no members found', response.data.message)
              }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();

      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      const searchObject = passedItem
      let searchString = passedItem.firstName + ' ' + passedItem.lastName

      const memberOptions = null

      this.setState({ searchObject, searchString, memberOptions })
    }

    searchCompetencies(competency, type, index) {
      console.log('searchCompetencies ', competency, type, index)

      if (competency === '') {
        const skillOptions = []
        const competencyOptions = []
        this.setState({ skillOptions, competencyOptions })
      } else {
        Axios.get('/api/competency/search', { params: { searchString: competency, type } })
        .then((response) => {
          console.log('Competency search query attempted', response.data);

            if (response.data.success) {
              console.log('competency search query worked')

              const skillOptions = response.data.skills
              const competencyOptions = response.data.competencies
              const selectedIndex = index
              this.setState({ skillOptions, competencyOptions, selectedIndex });

            } else {
              console.log('competency search query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Competency search query did not work for some reason', error);
        });
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (this.state.selectedMembers.includes(this.state.searchString)) {
        this.setState({ errorMessage: 'You have already added this member' })
      } else if (!this.state.searchObject) {
        this.setState({ errorMessage: 'Please select a member from search suggestions' })
      } else {

        const searchString = ''
        const searchObject = null

        let selectedMembers = this.state.selectedMembers
        selectedMembers.unshift(this.state.searchString)

        let recipients = this.state.recipients
        recipients.unshift(this.state.searchObject)

        selectedMembers = [this.state.searchString]
        recipients = [this.state.searchObject]

        this.setState({ searchString, searchObject, selectedMembers, recipients, errorMessage: null, memberOptions: [] })

      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      let selectedMembers = this.state.selectedMembers
      selectedMembers.splice(index, 1)

      let recipients = this.state.recipients
      // const memberToRemove = recipients[index]
      recipients.splice(index, 1)
      this.setState({ selectedMembers, recipients })
    }

    handleSubmit(event) {
        console.log('handleSubmit called')

        this.setState({ isSaving: true, errorMessage: null, successMessage: null})

        //validate forms
        if (this.state.emailId && this.state.recipients.length === 0) {
          this.setState({ isSaving: false, errorMessage: 'please add at least one recipient' })
        } else if (!this.state.emailId && this.state.recipientFirstName === '') {
          this.setState({ isSaving: false, errorMessage: "please add the recipient's first name" })
        } else if (!this.state.emailId && this.state.recipientLastName === '') {
          this.setState({ isSaving: false, errorMessage: "please add the recipient's last name" })
          } else if (!this.state.emailId && this.state.recipientEmail === '') {
          this.setState({ isSaving: false, errorMessage: 'please add a recipient email' })
        } else if (!this.state.emailId && !this.state.recipientEmail.includes('@')) {
          this.setState({ isSaving: false, errorMessage: 'email invalid. please enter a valid email.' })
        } else if (this.state.relationship === '') {
          this.setState({ isSaving: false, errorMessage: 'please add a relationship for your endorsement' })
        } else if (this.state.competencies.length === 0) {
          this.setState({ isSaving: false, errorMessage: 'please add at least one competency' })
        } else if (this.state.selectedPathway !== "Custom" && this.state.hasStudied === "Yes or No" && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please indicate whether you have studied ' + this.state.selectedPathway })
        } else if (this.state.selectedPathway !== "Custom" && this.state.hasWorked === "Yes or No" && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please indicate whether you have worked in ' + this.state.selectedPathway })
        } else if (this.state.selectedPathway !== "Custom" && this.state.hasManaged === "Yes or No" && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please indicate whether you have managed people in ' + this.state.selectedPathway})
        } else if (this.state.selectedPathway !== "Custom" && this.state.hasTaught === "Yes or No" && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please indicate whether you have taught ' + this.state.selectedPathway })
        } else if (this.state.senderFirstName === '' && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please add your first name' })
        } else if (this.state.senderLastName === '' && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please add your last name' })
        } else if (this.state.senderEmail === '' && !this.state.emailId) {
          this.setState({ isSaving: false, errorMessage: 'please add your email' })
        } else if (!this.state.emailId && !this.state.senderEmail.includes('@')) {
          this.setState({ isSaving: false, errorMessage: 'email invalid. please enter your valid email.' })
        } else {

            let competencies = []
            let skillTraits = []
            let overallRecommendation = this.state.overallRecommendation

            let pointSum = 0
            let entryCounter = 0
            let averageRating = 0

            if (this.state.competencies) {
              for (let i = 1; i <= this.state.competencies.length; i++) {
                console.log('show competency: ', i, this.state.competencies[i - 1])
                if (this.state.competencies[i - 1].name === '' || this.state.competencies[i - 1].category === '' || !this.state.competencies[i - 1].rating) {
                  // console.log('show skillTrait: ', this.state.skillTraits[i - 1].name)
                  this.setState({ isSaving: false, errorMessage: 'please add a name, type, and rating to each competency'})
                  return
                } else if (this.state.competencies[i - 1].rating !== "I'm Not Sure") {
                  competencies.push(this.state.competencies[i - 1])
                  pointSum = pointSum + Number(this.state.competencies[i - 1].rating)
                  entryCounter = entryCounter + 1
                }
              }

              if (entryCounter) {
                if (!overallRecommendation) {
                  // calculate overall recommendation
                  overallRecommendation = (pointSum / entryCounter).toFixed()
                  console.log('so what is the overallRecommendation? ', overallRecommendation)
                }
                averageRating = (pointSum / entryCounter).toFixed()
              }

            } else {

              for (let i = 1; i <= this.state.skillTraits.length; i++) {
                if (this.state.skillTraits[i - 1].name === '' || this.state.skillTraits[i - 1].skillType === '' || !this.state.skillTraits[i - 1].rating) {
                  // console.log('show skillTrait: ', this.state.skillTraits[i - 1].name)
                  this.setState({ isSaving: false, errorMessage: 'please add a name, type, and rating to each skill'})
                  return
                } else if (this.state.skillTraits[i - 1].rating !== "I'm Not Sure") {
                  skillTraits.push(this.state.skillTraits[i - 1])
                }
              }
            }

            const examples = this.state.examples
            console.log('lets see examples 1', examples)
            for (let i = 1; i <= examples.length; i++) {
              console.log('lets see examples 2', examples)

              if (this.state.competencies) {
                if (examples[i - 1].competency && examples[i - 1].competency === 'Select a Competency') {
                  return this.setState({ isSaving: false, errorMessage: 'please add a competency for each example'})
                }
              } else {
                if (examples[i - 1].skillTrait === 'Select a Skill') {
                  return this.setState({ isSaving: false, errorMessage: 'please add a skill for each example'})
                }
              }

              if (examples[i - 1].example === '') {
                this.setState({ isSaving: false, errorMessage: 'please add at least one example of how this person demonstrated an endorsed competency'})
                return
              }
            }

            const goalType = this.state.goalType
            const pathway = this.state.selectedPathway
            const opportunityId = this.state.opportunityId

            const recommendationExplanation = this.state.recommendationExplanation
            const isTransparent = this.state.isTransparent
            const isInternal = false

            let orgCode = ''
            let orgName = ''
            let orgProgramName = ''
            let orgContactEmail = ''
            if (this.state.requestId) {
              orgCode = this.state.requestOrgCode
              orgName = this.state.requestOrgName
              orgProgramName = this.state.requestOrgProgramName
              orgContactEmail = this.state.requestOrgContactEmail
            } else if (this.state.orgCode) {
              orgCode = this.state.orgCode
              orgName = this.state.orgName
              orgProgramName = this.state.orgProgramName
              orgContactEmail = this.state.orgContactEmail
            }

            let relationship = this.state.relationship;
            let isAnonymousContribution = this.state.isAnonymousContribution
            let recipientFirstName = this.state.recipientFirstName
            let recipientLastName = this.state.recipientLastName
            let recipientEmail = this.state.recipientEmail
            let recipientUsername = this.state.recipientUsername
            let recipientPictureURL = this.state.recipientPictureURL
            if (this.state.emailId) {
              recipientFirstName = this.state.recipients[0].firstName
              recipientLastName = this.state.recipients[0].lastName
              recipientEmail = this.state.recipients[0].email
              recipientUsername = this.state.recipients[0].username
              recipientPictureURL = this.state.recipients[0].pictureURL
            }

            let senderFirstName = this.state.senderFirstName
            let senderLastName = this.state.senderLastName
            let senderEmail = this.state.senderEmail
            let senderUsername = null
            if (this.state.emailId) {
              senderFirstName = this.state.cuFirstName
              senderLastName = this.state.cuLastName
              senderEmail = this.state.emailId
              senderUsername = this.state.username
            }

            const hasStudied = this.state.hasStudied
            const hoursStudied = this.state.hoursStudied
            const hasWorked = this.state.hasWorked
            const hoursWorked = this.state.hoursWorked
            const hasManaged = this.state.hasManaged
            const hoursManaged = this.state.hoursManaged
            const hasTaught = this.state.hasTaught
            const hoursTaught = this.state.hoursTaught

            const senderPictureURL = this.state.pictureURL
            const senderHeadline = this.state.headline
            let senderJobTitle = this.state.jobTitle
            if (this.state.senderJobTitle) {
              senderJobTitle = this.state.senderJobTitle
            }
            let senderEmployerName = this.state.employerName
            if (this.state.senderEmployerName) {
              senderEmployerName = this.state.senderEmployerName
            }

            const senderSchoolName = this.state.schoolName
            const senderLinkedIn = this.state.senderLinkedIn

            let createdAt = new Date();
            let updatedAt = createdAt

            const self = this

            function actuallyProvideEndorsement(recipient) {
              console.log('actuallyProvideEndorsement called', recipient)

              recipientUsername = recipient.username
              recipientPictureURL = recipient.pictureURL

              let unsubscribed = null
              if (recipient.notificationPreferences && recipient.notificationPreferences.length > 0) {
                for (let i = 1; i <= recipient.notificationPreferences.length; i++) {
                  if (recipient.notificationPreferences[i - 1].slug === 'new-endorsements' && recipient.notificationPreferences[i - 1].enabled === false) {
                    unsubscribed = true
                  }
                }
              }

              console.log(' recipientUsername? ', recipientUsername)

              Axios.post('/api/story/provide', {
                senderFirstName, senderLastName, senderEmail, senderUsername,
                relationship, isAnonymousContribution, type: 'provide',
                recipientFirstName,  recipientLastName, recipientEmail, recipientUsername, recipientPictureURL,
                isDecided: false, isApproved: false, isDenied: false,
                averageRating, overallRecommendation, recommendationExplanation, isTransparent,
                goalType, pathway, opportunityId, skillTraits, competencies, examples, isInternal,
                orgCode, orgName, orgProgramName, orgContactEmail,
                hasStudied, hoursStudied, hasWorked, hoursWorked,
                hasManaged, hoursManaged, hasTaught, hoursTaught,
                senderPictureURL, senderHeadline, senderJobTitle, senderEmployerName, senderSchoolName, senderLinkedIn,
                unsubscribed,
                createdAt, updatedAt })
              .then((response) => {

                if (response.data.success) {
                  //clear values
                  self.setState({
                    relationship: '',
                    recipientFirstName: '',
                    recipientLastName: '',
                    recipientEmail: '',
                    senderFirstName: '',
                    senderLastName: '',
                    senderEmail: '',
                    competencies: [], examples: [],

                    isSaving: false, successMessage: 'Endorsement sent successfully!'
                  })
                } else {
                  self.setState({ isSaving: false, errorMessage: response.data.message })
                }
              }).catch((error) => {
                  console.log('endorsement send did not work', error);
                  self.setState({ isSaving: false, errorMessage: error.toString() })
              });
            }

            Axios.get('/api/users/profile/details/' + recipientEmail, { params: { emailId: recipientEmail } })
             .then((response) => {
               console.log('query for profile data worked');

               if (response.data.success) {

                 console.log('profile data received', response.data)

                 const username = response.data.user.username
                 const pictureURL = response.data.user.pictureURL
                 const notificationPreferences = response.data.user.notificationPreferences

                 actuallyProvideEndorsement({ username, pictureURL, notificationPreferences })

               } else {
                 console.log('error response', response.data)

                 actuallyProvideEndorsement({ username: recipientUsername, pictureURL: recipientPictureURL, notificationPreferences: null })
               }

            }).catch((error) => {
                 console.log('query for profile info did not work', error);
            })
        }
    }

    toggleCheck(index) {
      console.log('toggleCheck called', this.state.checked)

      let checked = this.state.checked
      if (this.state.checked[index]) {
        checked[index] = false
        this.setState({ checked })
      } else {
        checked[index] = true
        this.setState({ checked, showDelete: true })
      }
    }

    removeCompetency() {
      console.log('removeCompetency called')

      if (this.state.competencies) {
        let competencies = this.state.competencies
        let checked = this.state.checked
        for (let i = 1; i <= competencies.length; i++) {
          if (this.state.checked[ i - 1]) {
            console.log('we got in here')
            competencies.splice(i - 1, 1)
            checked.splice(i - 1, 1)
            // console.log('what happened to values', skillTraits, checked)
          }
        }

        this.setState({ competencies, checked, showDelete: false })
      } else {
        let skillTraits = this.state.skillTraits
        let checked = this.state.checked
        for (let i = 1; i <= skillTraits.length; i++) {
          if (this.state.checked[ i - 1]) {
            console.log('we got in here')
            skillTraits.splice(i - 1, 1)
            checked.splice(i - 1, 1)
            console.log('what happened to values', skillTraits, checked)
          }
        }

        this.setState({ skillTraits, checked, showDelete: false })
      }
    }

    renderCompetencies() {
      console.log('renderCompetencies called')

      let rows = []

      let competencies = []
      let competencyOptions = []

      if (this.state.competencies) {
        competencies = this.state.competencies
        competencyOptions = this.state.competencyOptions
        // console.log('show competencyOptions 1: ', competencyOptions)
      } else {
        competencies = this.state.skillTraits
        competencyOptions = this.state.skillOptions
        // console.log('show competencyOptions 2: ', competencyOptions)
      }

      for (let i = 1; i <= competencies.length; i++) {

        if ((competencies[i - 1].skillType && competencies[i - 1].skillType.includes('Skill')) || (competencies[i - 1].category)) {

          const index = i - 1

          let nameName = "skillName|" + i.toString()
          let typeName = "skillType|" + i.toString()
          let ratingName = "skillRating|" + i.toString()
          if (this.state.competencies) {
            nameName = "competencyName|" + i.toString()
            typeName = "competencyType|" + i.toString()
            ratingName = "competencyRating|" + i.toString()
          }

          rows.push(
            <div key={i}>
              <div>
                 <div className="col span-1-of-2">
                   <input type="text" className="text-field" placeholder="Skill Name" name={nameName} value={competencies[i - 1].name} onChange={this.formChangeHandler} />
                 </div>

                 <div className="col span-1-of-4">
                  {(this.state.competencies && this.state.competencies.length > 0) ? (
                    <select name={typeName} value={this.state.competencies[i - 1].category} className="dropdown" onChange={this.formChangeHandler}>
                      {console.log('compatencyOptions: ', this.state.competencyOptions, this.state.skillTypeOptions)}
                      {this.state.competencyOptions.map(value =>
                        <option key={value.key} value={value}>{value}</option>
                      )}
                    </select>
                  ) : (
                    <select name={typeName} value={this.state.skillTraits[i - 1].skillType} className="dropdown" onChange={this.formChangeHandler}>
                      {this.state.skillTypeOptions.map(value =>
                        <option key={value.key} value={value}>{value}</option>
                      )}
                    </select>
                  )}
                 </div>

                 <div className="col span-1-of-4">
                   <select name={ratingName} value={competencies[i - 1].rating} className="dropdown" onChange={this.formChangeHandler}>
                       <option />
                       <option value="5">Exceptional</option>
                       <option value="4">Great</option>
                       <option value="3">Average</option>
                       <option value="2">Needs Improvement</option>
                       <option value="1">Needs Significant Improvement</option>
                       <option value="I'm Not Sure">I'm Not Sure</option>
                   </select>
                 </div>
                 <div className="clear" />
                 {(this.state.onMobile) && (
                   <div className="spacer" />
                 )}

                 {(this.state.selectedIndex === index && competencyOptions.length > 0) && (
                   <div className="full-width">
                     <div className="spacer" />
                     {competencyOptions.map((value, optionIndex) =>
                       <div key={value._id}>
                         <button className="background-button" onClick={() => this.competencyClicked(index, optionIndex, 'skill')}>
                           <div className="left-padding">
                              <div className="float-left right-padding">
                               <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-20" />
                             </div>
                             <div className="float-left">
                               <p>{value.name}</p>
                             </div>
                           </div>
                         </button>
                         <div className="clear" />
                         <div className="half-spacer" />
                       </div>
                     )}
                   </div>
                 )}
              </div>
            </div>
          )
        }
      }

      return rows
    }

    competencyClicked(index, optionIndex, type) {
      console.log('competencyClicked', index, optionIndex, type, this.state.skillOptions)

      if (type === 'trait') {
        console.log('this is an error. This is now a dropdown.')
      } else if (this.state.competencies && this.state.competencies.length > 0) {
        const name = this.state.competencyOptions[optionIndex].name
        const skillType = this.state.competencyOptions[optionIndex].type
        const rating = ''
        const skillOptions = []
        let competencies = this.state.competencies
        competencies[index] = { name, skillType, rating }
        this.setState({ competencies, skillOptions })
      } else {
        const name = this.state.skillOptions[optionIndex].name
        const skillType = this.state.skillOptions[optionIndex].type
        const rating = ''
        const skillOptions = []
        let skillTraits = this.state.skillTraits
        skillTraits[index] = { name, skillType, rating }
        this.setState({ skillTraits, skillOptions })
      }
    }

    renderTraits() {
      console.log('renderTraits called')

      let rows = []

      for (let i = 1; i <= this.state.skillTraits.length; i++) {

        if (this.state.skillTraits[i - 1].skillType === 'Trait') {

          let nameName = "skillName|" + i.toString()

          let ratingName = "skillRating|" + i.toString()

          rows.push(
            <div key={i}>
              <div>
                 <div className="col span-3-of-4">
                   <select name={nameName} value={this.state.skillTraits[i - 1].name} className="dropdown" onChange={this.formChangeHandler}>
                     {this.state.traitOptions.map(value =>
                       <option key={value.key} value={value}>{value}</option>
                     )}
                   </select>
                 </div>
                 <div className="col span-1-of-4">
                   <select name={ratingName} value={this.state.skillTraits[i - 1].rating} className="dropdown" onChange={this.formChangeHandler}>
                       <option />
                       <option value="5">Very High</option>
                       <option value="4">High</option>
                       <option value="3">Average</option>
                       <option value="2">Low</option>
                       <option value="1">Very Low</option>
                       <option value="I'm Not Sure">I'm Not Sure</option>
                   </select>
                 </div>
                 <div className="clear" />
                 {(this.state.onMobile) && (
                   <div className="spacer" />
                 )}

              </div>
            </div>
          )
        }
      }

      return rows
    }

    addCompetency(type) {
      console.log('add SkillTrait called')

      if (this.state.competencies && this.state.competencies.length > 0) {
        let competencies = this.state.competencies
        competencies.push({ name: '', category: 'Hard Skill'})

        let checked = this.state.checked
        checked.push(false)
        this.setState({ competencies, checked })
      } else {
        let skillTraits = this.state.skillTraits
        if (type === 'Trait') {
          skillTraits.push({ name: '', skillType: 'Trait'})
        } else {
          skillTraits.push({ name: '', skillType: 'Hard Skill'})
        }

        let checked = this.state.checked
        checked.push(false)
        this.setState({ skillTraits, checked })
      }
    }

    renderExamples() {
      console.log('renderExamples called')

      let rows = []

      let skillOptions = []
      skillOptions.push("Select a Skill")
      for (let i = 1; i <= this.state.skillTraits.length; i++) {
        skillOptions.push(this.state.skillTraits[i - 1].name)
      }

      for (let i = 1; i <= this.state.examples.length; i++) {

        let exampleSkillTrait = "exampleSkillTrait|" + i.toString()
        if (this.state.competencies && this.state.competencies.length > 0) {
          exampleSkillTrait = "exampleCompetency|" + i.toString()
        }
        const exampleExample = "exampleExample|" + i.toString()
        let exampleCompetency = this.state.examples[i - 1].skillTrait
        if (this.state.competencies) {
          exampleCompetency = this.state.examples[i - 1].competency
        }

        rows.push(
          <div key={i}>
            <select name={exampleSkillTrait} value={exampleCompetency} className="dropdown" onChange={this.formChangeHandler}>
              {skillOptions.map(value =>
                <option key={value.key} value={value}>{value}</option>
              )}
            </select>
            <textarea type="text" className="text-field" placeholder="Share an example where the endorsee exceptionally demonstrated this skill…." name={exampleExample}value={this.state.examples[i - 1].example} onChange={this.formChangeHandler}></textarea>
          </div>
        )
      }

      return rows
    }

    addExample() {
      console.log('addExample called')

      let examples = this.state.examples
      if (this.state.competencies) {
        examples.push({ skillTrait: 'Select a Competency', example: '' })
      } else {
        examples.push({ skillTrait: 'Select a Skill', example: '' })
      }

      let checked = this.state.checked
      checked.push(false)
      this.setState({ examples, checked })
    }

    removeExample() {
      console.log('removeExample called')

      let examples = this.state.examples
      let exampleChecked = this.state.exampleChecked
      for (let i = 1; i <= examples.length; i++) {
        if (this.state.exampleChecked[ i - 1]) {
          console.log('we got in here')
          examples.splice(i - 1, 1)
          exampleChecked.splice(i - 1, 1)
          console.log('what happened to values', examples, exampleChecked)
        }
      }

      this.setState({ examples, exampleChecked, showExampleDelete: false })
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    renderSpecificCompetencies() {
      console.log('renderSpecificCompetencies called')

      let rows = []

      let skillTraits = this.state.skillTraits
      if (this.state.competencies) {
        skillTraits = this.state.competencies
      }

      if (skillTraits) {

        let hardSkills = []
        let softSkills = []
        let traits = []

        for (let i = 1; i <= skillTraits.length; i++) {
          console.log('looping skillTraits: ', skillTraits[i - 1])
          let skillTrait = skillTraits[i - 1]

          if (!skillTrait.rating) {
            skillTrait['rating'] = 0
          }
          if (skillTraits[i - 1].skillType === 'Hard Skill' || skillTraits[i - 1].category === 'Hard Skill') {
            hardSkills.push(skillTrait)
          } else if (skillTraits[i - 1].skillType === 'Soft Skill' || skillTraits[i - 1].category === 'Soft Skill') {
            softSkills.push(skillTrait)
          } else {
            traits.push(skillTrait)
          }
        }

        rows.push(
          <div key="skills">
            <p>Hard Skills</p>

            <div className="spacer" />

            {hardSkills.map(value =>
              <div>
                {(value.rating !== "I'm Not Sure") && (
                  <div>
                    <div className="custom-col-30-of-100">
                      <p className="description-text-2 half-bold-text">{value.name}</p>
                    </div>
                    <div className="custom-col-65-of-100">
                      <div className="half-spacer" />

                      <div className="progress-bar">
                        <div className="filler" style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="custom-col-5-of-100 center-text">
                      <p className="description-text-2 half-bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>
                  </div>
                )}
              </div>
            )}

            <div className="spacer" />
            <div className="spacer" />

            <p>Soft Skills</p>
            <div className="spacer" />

            {softSkills.map(value =>
              <div>
                {(value.rating !== "I'm Not Sure") && (
                  <div>
                    <div className="custom-col-30-of-100">
                      <p className="description-text-2 half-bold-text">{value.name}</p>
                    </div>
                    <div className="custom-col-65-of-100">
                      <div className="half-spacer" />

                      <div className="progress-bar secondary-border">
                        <div className="filler secondary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="custom-col-5-of-100 center-text">
                      <p className="description-text-2 half-bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    renderTags(type) {
      console.log('renderTags called', type)

      const selectedMembers = this.state.selectedMembers
      if (selectedMembers && selectedMembers.length > 0) {
        console.log('show selectedMembers: ', selectedMembers)
        return (
          <div key={"selectedMembers"}>
            <div className="spacer" />
            {selectedMembers.map((value, optionIndex) =>
              <div key={"career|" + optionIndex} className="float-left">

                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>

              </div>
            )}
          </div>
        )
      }
    }

    passState(passedState) {
      console.log('passState called', passedState, this.state.competencies)
      this.setState(passedState)
    }

    render() {
      return (
          <div>
            <div className="row-15">
              <p className="heading-text-2 center-text">Send an Endorsement</p>
            </div>

            <div className="row-15 top-margin-20">
              <p className="heading-text-3 center-text" id="works">Recipient Info</p>
              <div className="invite-container">
                {/*
                  <div className="app-row">
                      <input type="text" className="text-field" placeholder="Search if you previously sent or they are already on Compass" />
                  </div>
                  */}
                  {(this.state.emailId) ? (
                    <div>
                      <div>
                        <label className="profile-label">Recipient(s)<label className="error-color">*</label></label>

                        <div className="row-10">
                          <div className="calc-column-offset-70">
                            <input type="text" className="text-field" placeholder="Search advisees..." name="searchAdvisees"  autoComplete="off" value={this.state.searchString} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.searchString || this.state.searchString === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.searchString || this.state.searchString === '') ? true : false} onClick={() => this.addItem('member')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.searchIsAnimatingMembers) ? (
                          <div className="flex-container flex-center full-space">
                            <div>
                              <div className="super-spacer" />

                              <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                              <div className="spacer" /><div className="spacer" /><div className="spacer" />
                              <p className="center-text cta-color bold-text">Searching...</p>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {(this.state.memberOptions && this.state.memberOptions.length > 0) && (
                                <div className="card top-margin">
                                  {this.state.memberOptions.map((value, optionIndex) =>
                                    <div key={value._id} className="left-text bottom-margin-5 full-width">
                                      <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'member')}>
                                        <div className="full-width">
                                          <div className="fixed-column-40">
                                            <div className="mini-spacer" />
                                            <img src={profileIconDark} alt="GC" className="image-auto-22" />
                                          </div>
                                          <div className="calc-column-offset-40">
                                            <p className="cta-color">{value.firstName} {value.lastName}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div>

                              {this.renderTags('member')}
                              <div className="clear" />

                            </div>

                          </div>
                        )}

                        <div className="clear"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="app-row row-5">
                          <div className="container-left">
                            <label className="profile-label">First Name <label className="error-color">*</label></label>
                            <input type="text" className="text-field" placeholder="(e.g., Jon)" name="firstName" value={this.state.recipientFirstName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="container-right">
                            <label className="profile-label">Last Name <label className="error-color">*</label></label>
                            <input type="text" className="text-field" placeholder="(e.g., Wilson)" name="lastName" value={this.state.recipientLastName} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                      </div>
                      <div className="app-row row-5">
                        <label className="profile-label">Email <label className="error-color">*</label></label>
                          <input type="email" className="text-field" placeholder="(e.g., jonwilson@gmail.com)" name="email" value={this.state.recipientEmail} onChange={this.formChangeHandler}/>
                      </div>

                    </div>
                  )}

                  <div className="app-row row-5">
                      <label className="profile-label">What is your relationship with the recipient? <label className="error-color">*</label></label>
                      <select name="relationship" value={this.state.relationship} className="dropdown" onChange={this.formChangeHandler}>
                        {this.state.relationshipOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                  </div>

              </div>
            </div>

            <hr className="horizontal-margin-2 vertical-margin-20"/>

            <div className="spacer" /><div className="spacer" />

            <div className="row-15">
              <p className="heading-text-3 center-text">Endorsement Details</p>
              <div className="invite-container">
                  {/*
                  <div className="app-row">
                    <p className="field-descriptor">Not sure how to contribute? View the <Link to={'/endorsement-guide'}>Compass Endorsement Guide</Link> for inspiration.</p>
                  </div>*/}
                  <div className="app-row">
                      {(!this.state.requestedCompetencies) && (
                        <div>
                          <label className="profile-label">Pathway</label>

                          <select name="pathway" value={this.state.selectedPathway} className="dropdown" onChange={this.formChangeHandler}>
                            {this.state.pathwayOptions.map(value =>
                              <option key={value.key} value={value.value}>{value.value}</option>
                            )}
                          </select>
                        </div>
                      )}

                      <div className="spacer" />
                      <div className="spacer" />
                      <div className="spacer" />

                      <div>
                        <div className="row-10">
                          <label className="profile-label">Would you like to submit an overall recommendation?</label>

                          <div className="spacer" />

                          <Switch
                            onChange={(change) => this.setState({ includeRecommendation: change })}
                            checked={this.state.includeRecommendation}
                            id="normal-switch"
                          />

                          <div className="spacer" />

                        </div>

                        {(this.state.includeRecommendation) && (
                          <div>
                            <label className="profile-label">Would You Recommend {this.state.recipientFirstName} {this.state.selectedPathway === 'Custom' ? "for Work Opportunities?" : " to Work in " + this.state.selectedPathway + "?"}</label>

                            <div className="spacer" />

                            <select name="overallRecommendation" value={this.state.overallRecommendation} className="dropdown" onChange={this.formChangeHandler}>
                              <option />
                              <option value="5">Highly Recommend</option>
                              <option value="4">Recommend</option>
                              <option value="3">Somewhat Recommend</option>
                              <option value="2">Only If You Must Hire</option>
                              <option value="1">Stay Away From This Candidate</option>
                            </select>

                            <div className="spacer" />

                            <textarea type="text" className="text-field" placeholder="Why did you choose this recommendation option…." name="recommendationExplanation" value={this.state.recommendationExplanation} onChange={this.formChangeHandler}></textarea>
                          </div>
                        )}
                      </div>

                      {(this.state.showConfigureEndorsement) ? (
                        <div>
                          <SubConfigureEndorsement history={this.props.navigate} passState={this.passState} selectedBenchmark={this.state.pathwayOptions[this.state.pathwayIndex].benchmark} requestedCompetencies={this.state.requestedCompetencies}/>
                        </div>
                      ) : (
                        <div>
                          <div className="row-20">
                            <label className="profile-label">Competencies <label className="error-color">*</label></label>
                          </div>

                          {this.renderCompetencies()}
                          <div className="clear" />

                          <div className="add-item-line-button">
                            <hr />
                            <a className="background-button" onClick={() => this.addCompetency('Skill')}>
                              <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                            </a>
                          </div>

                          <label className="profile-label">Examples <label className="error-color">*</label></label>
                          <div className="spacer" />
                          <p className="profile-descriptor">Please provide at least one supporting story / example</p>
                          <div className="spacer" />
                          {this.renderExamples()}
                          <div className="clear" />

                          <div className="add-item-line-button">
                            <hr />
                            <a className="background-button" onClick={() => this.addExample()}>
                              <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                            </a>
                          </div>
                        </div>
                      )}

                  </div>
              </div>
            </div>

            <div className="clear" />
            <div className="spacer" />
            <hr className="horizontal-margin-2"/>

            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />

            <div className="invite-container">

              <div className="row-15">
                <p className="heading-text-3">Recipient Transparency</p>
              </div>

              <div className="spacer" />

              <div className="row-10">
                <label className="profile-label">Select Your Transparency Preference</label>
                <select name="isTransparent" value={this.state.isTransparent} className="dropdown" onChange={this.formChangeHandler}>
                    <option value={true}>Students can see endorsement details [Keep transparent]</option>
                    <option value={false}>Students cannot see endorsement details [Keep confidential]</option>

                </select>
                {/*
                <select name="isTransparent" value={this.state.isTransparent} className="dropdown" onChange={this.formChangeHandler}>
                  {this.state.transparencyOptions.map(value =>
                    <option key={value} value={value}>{value}</option>
                  )}
                </select>*/}
                <div className="spacer" />
                <div className="spacer" />
              </div>

              <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true })}>View what {(!this.state.recipientFirstName || this.state.recipientFirstName === '') ? "student" : this.state.recipientFirstName} sees</button>
            </div>

            {(this.state.showSubEndorsementDetails) ? (
              <div>
                <SubEndorsementDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen}
                  selectedEndorsement={{
                    senderFirstName: this.state.senderFirstName, senderLastName: this.state.senderLastName, senderEmail: this.state.senderEmail,
                    isTransparent: this.state.isTransparent, overallRecommendation: this.state.overallRecommendation, recommendationExplanation: this.state.recommendationExplanation,
                    relationship: this.state.relationship,
                    pathway: this.state.pathway, skillTraits: this.state.skillTraits, competencies: this.state.competencies, examples: this.state.examples,
                    createdAt: this.state.createdAt, updatedAt: this.state.updatedAt
                  }}
                />
              </div>
            ) : (
              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
                  {(this.state.isTransparent.toString() === 'true') ? (
                    <div className="padding-20">

                      <p className="heading-text-4">Student's View of Your Endorsement</p>

                      <div className="spacer" />
                      <div className="spacer" />

                      <div key="specificEndorsement">
                        <hr />
                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                        <div className="rating-box float-left">
                          <label className="heading-text-2 bold-text">{(this.state.overallRecommendation) ? Number(this.state.overallRecommendation) * 20 : "N/A"}%</label>
                          <p className="description-text-5 white-text">OVERALL</p>
                        </div>
                        <div className="calc-column-offset-240 left-padding">
                          <p className="heading-text-4">{this.state.senderFirstName} {this.state.senderLastName}</p>
                          <div className="half-spacer" />
                          <p className="description-text-2">{this.state.relationship}</p>
                        </div>
                        <div className="fixed-column-150 right-text">
                          <p>{this.state.selectedPathway} Pathway</p>
                          <div className="half-spacer" />
                          <p className="description-text-2">{new Date().toString().substring(0,15)}</p>
                        </div>

                        <div className="clear" />

                        <div className="spacer" />
                        <div className="spacer" />

                        <div>
                          {this.renderSpecificCompetencies()}
                        </div>

                        <div className="clear" />

                        <div className="spacer" />
                        <div className="spacer" />

                        <div>
                          <p className="heading-text-6 bold-text">Examples of Above Skills</p>

                          <div className="spacer" />
                          <div className="spacer" />

                          {this.state.examples.map(value =>
                            <div>
                              <p className="description-text-1">{value.category ? value.category : value.skillTrait} Example</p>
                              <div className="spacer" />
                              <p className="description-text-3 bold-text">{value.example}</p>
                              <div className="spacer" />
                              <div className="spacer" />
                            </div>
                          )}

                        </div>

                        <div className="clear" />
                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                      </div>

                    </div>
                  ) : (
                    <div className="empty-state-container-2">
                      <div className="center-text">

                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                        <img src={confidentialityIcon} alt="Student icon" className="image-auto-100 center-horizontally" />

                        <div className="spacer" />
                        <div className="spacer" />

                        <p className="heading-text-4">This Endorsement is Confidential</p>
                        <div className="spacer" />f
                        <p>This endorsement has been marked confidential by the endorser. It will automatically be imported into work applications.</p>
                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />
                      </div>
                    </div>
                  )}

              </Modal>
            )}

            {(!this.state.emailId) && (
              <div>
                <div className="spacer" />
                <hr className="horizontal-margin-2"/>

                <div className="spacer" />
                <div className="spacer" />
                <div className="spacer" />

                <h3 className="outreach-subheader">Verify Your Identity</h3>

                <div className="invite-container">
                  <div className="app-row row-5">
                    <div className="container-left">
                      <label className="profile-label">Your First Name <label className="error-color">*</label></label>
                      <input type="text" className="text-field" placeholder="(e.g., Jane)" name="senderFirstName" value={this.state.senderFirstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Your Last Name <label className="error-color">*</label></label>
                      <input type="text" className="text-field" placeholder="(e.g., Thornton)" name="senderLastName" value={this.state.senderLastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="app-row row-5">
                    <div className="container-left">
                      <label className="profile-label">Your Email <label className="error-color">*</label></label>
                      <input type="email" className="text-field" placeholder="(e.g., janethornton@gmail.com)" name="senderEmail" value={this.state.senderEmail} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Your LinkedIn</label>
                      <input type="text" className="text-field" placeholder="(e.g., https://www.linkedin.com/in/creightontaylor/)" name="senderLinkedIn" value={this.state.senderLinkedIn} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />

                  </div>
                  <div className="app-row row-5">
                    <div className="container-left">
                      <label className="profile-label">Your Job Title</label>
                      <input type="text" className="text-field" placeholder="(e.g., Marketing Manager)" name="senderJobTitle" value={this.state.senderJobTitle} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Your Employer Name</label>
                      <input type="text" className="text-field" placeholder="(e.g., Nike)" name="senderEmployerName" value={this.state.senderEmployerName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                {(this.state.selectedPathway && this.state.selectedPathway !== "Custom") && (
                  <div>
                    <div className="invite-container">
                        <div className="edit-profile-row">
                          <p>Have you studied or worked on projects in <b>{(this.state.selectedPathway.includes(" | ")) ? this.state.selectedPathway.split("|")[0] : this.state.selectedPathway}</b>?</p>
                        </div>
                        <div className="name-container">
                          <select name="hasStudied" value={this.state.hasStudied} className="dropdown" onChange={this.formChangeHandler}>
                            {this.state.yesOrNoOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {(this.state.hasStudied === 'Yes') && (
                          <div className="name-container">
                            <select name="hoursStudied" value={this.state.hoursStudied} className="dropdown" onChange={this.formChangeHandler}>
                              {this.state.hoursOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}
                    </div>

                    <div className="clear"/>

                    <div className="invite-container">
                        <div className="edit-profile-row">
                          <p>Have you worked in <b>{(this.state.selectedPathway.includes(" | ")) ? this.state.selectedPathway.split("|")[0] : this.state.selectedPathway}</b>?</p>
                        </div>
                        <div className="name-container">
                          <select name="hasWorked" value={this.state.hasWorked} className="dropdown" onChange={this.formChangeHandler}>
                            {this.state.yesOrNoOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {(this.state.hasWorked === 'Yes') && (
                          <div className="name-container">
                            <select name="hoursWorked" value={this.state.hoursWorked} className="dropdown" onChange={this.formChangeHandler}>
                              {this.state.hoursOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}
                    </div>

                    <div className="clear"/>

                    <div className="invite-container">
                        <div className="edit-profile-row">
                          <p>Have you managed people in <b>{(this.state.selectedPathway.includes(" | ")) ? this.state.selectedPathway.split("|")[0] : this.state.selectedPathway}</b>?</p>
                        </div>
                        <div className="name-container">
                          <select name="hasManaged" value={this.state.hasManaged} className="dropdown" onChange={this.formChangeHandler}>
                            {this.state.yesOrNoOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {(this.state.hasManaged === 'Yes') && (
                          <div className="name-container">
                            <select name="hoursManaged" value={this.state.hoursManaged} className="dropdown" onChange={this.formChangeHandler}>
                              {this.state.hoursOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}
                    </div>

                    <div className="clear"/>

                    <div className="invite-container">
                        <div className="edit-profile-row">
                          <p>Have you taught <b>{(this.state.selectedPathway.includes(" | ")) ? this.state.selectedPathway.split("|")[0] : this.state.selectedPathway}</b> classes / courses?</p>
                        </div>
                        <div className="name-container">
                          <select name="hasTaught" value={this.state.hasTaught} className="dropdown" onChange={this.formChangeHandler}>
                            {this.state.yesOrNoOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {(this.state.hasTaught === 'Yes') && (
                          <div className="name-container">
                            <select name="hoursTaught" value={this.state.hoursTaught} className="dropdown" onChange={this.formChangeHandler}>
                              {this.state.hoursOptions.map(value =>
                                <option key={value.key} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                        )}
                    </div>
                    <div className="clear"/>
                  </div>
                )}
              </div>
            )}

            <div className="invite-container btn-centered">
              { (this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p> }
              { (this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p> }
              <button className="btn btn-primary" onClick={this.handleSubmit}>Send</button>
            </div>
          </div>
      )
    }
}

export default withRouter(SendEndorsement);
