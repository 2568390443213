import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
// import SubBenchmarks from './Subcomponents/Benchmarks';
import SubPathways from './Subcomponents/Pathways';
// import SubEditResume from './Subcomponents/EditResume';
// import SubEditCareerPlan from './Subcomponents/EditCareerPlan';
// import SubFinancials from './Subcomponents/Financials';
// import SubEditCoverLetter from './Subcomponents/EditCoverLetter';
// import SubEditBusinessPlan from './Subcomponents/EditBusinessPlan';
// import SubEditMockInterviewDoc from './Subcomponents/EditMockInterviewDoc';

import SubEditDocument from './Subcomponents/EditDocument';
import withRouter from './Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class Builders extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showSideNav: true,
          subNavCategories: ['Goal Plan','Resume','Cover Letter','Career Plan','Financial Plan','Business Plan','Mock Interview'],
          subNavSelected: 'Goal Plan',

          showPromptDesigner: false
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
        this.renderFunctionality = this.renderFunctionality.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
        this.passData = this.passData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.autoGeneratePrompt = this.autoGeneratePrompt.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const placementPartners = localStorage.getItem('placementPartners');
      const builderNavSelected = localStorage.getItem('builderNavSelected');
      let roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('accountCode');
      const orgLogo = localStorage.getItem('orgLogo');

      let subNavSelected = this.state.subNavSelected
      let subNavCategories = this.state.subNavCategories

      let id = null
      if (this.props.params && this.props.params.id && this.props.params.id !== 'add') {
        id = this.props.params.id
      }

      let calculateMatches = false
      let selectedGoal = null

      if (builderNavSelected) {
        subNavSelected = builderNavSelected
      }

      let fileTypeSingular = 'Resume'
      let fileTypePlural = 'Resumes'
      if (subNavSelected === 'Goal Plan') {
        fileTypeSingular = 'Goal Plan'
        fileTypePlural = 'Goal Plans'
      } else if (subNavSelected === 'Career Plan') {
        fileTypeSingular = 'Career Plan'
        fileTypePlural = 'Career Plans'
      } else if (subNavSelected === 'Financial Plan') {
        fileTypeSingular = 'Financial Plan'
        fileTypePlural = 'Financial Plans'
      } else if (subNavSelected === 'Cover Letter') {
        fileTypeSingular = 'Cover Letter'
        fileTypePlural = 'Cover Letters'
      } else if (subNavSelected === 'Business Plan') {
        fileTypeSingular = 'Business Plan'
        fileTypePlural = 'Business Plans'
      } else if (subNavSelected === 'Mock Interview') {
        fileTypeSingular = 'Mock Interview'
        fileTypePlural = 'Mock Interviews'
      }

      this.setState({ activeOrg, subNavSelected, subNavCategories, fileTypeSingular,
        fileTypePlural, id, readyToRender: true
      })

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked in editLog')

          const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
          this.setState({ disableChatbotPrompts });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      localStorage.setItem('builderNavSelected', itemSelected)

      let fileTypeSingular = 'Resume'
      let fileTypePlural = 'Resumes'
      if (itemSelected === 'Goal Plan') {
        fileTypeSingular = 'Goal Plan'
        fileTypePlural = 'Goal Plans'
      } else if (itemSelected === 'Career Plan') {
        fileTypeSingular = 'Career Plan'
        fileTypePlural = 'Career Plans'
      } else if (itemSelected === 'Financial Plan') {
        fileTypeSingular = 'Financial Plan'
        fileTypePlural = 'Financial Plans'
      } else if (itemSelected === 'Cover Letter') {
        fileTypeSingular = 'Cover Letter'
        fileTypePlural = 'Cover Letters'
      } else if (itemSelected === 'Business Plan') {
        fileTypeSingular = 'Business Plan'
        fileTypePlural = 'Business Plans'
      } else if (itemSelected === 'Mock Interview') {
        fileTypeSingular = 'Mock Interview'
        fileTypePlural = 'Mock Interviews'
      }

      this.setState({ subNavSelected: itemSelected, savedItems: null, fileTypeSingular, fileTypePlural, id: null })

    }

    changeSubNavSelected(subNavSelected) {
      console.log('changeSubNavSelected called', subNavSelected)

      localStorage.setItem('builderNavSelected', subNavSelected)
      this.setState({ subNavSelected })

    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    renderFunctionality() {
      console.log('renderFunctionality called')

      let containerClass = ""
      let mainClass = "standard-container-2 clear-padding clear-background clear-shadow"
      let navClass = "standard-container-2 clear-padding clear-background"
      if (window.innerWidth > 768 && this.state.showSideNav) {
        containerClass = "relative-column-60 padding-five-percent-left top-margin-2-percent min-width-840"
        mainClass = "top-margin-2-percent"
        navClass = "full-width min-width-778"
      }

      return (
        <div key="renderFunctionality">
          <div className={containerClass}>
            {(!this.state.employerAccount) && (
              <div className={navClass}>
                <div className="full-width white-background">
                  {(window.innerWidth > 768 && this.state.showSideNav) && (
                    <div className="full-width height-5 primary-background" />
                  )}

                  <div className="clear-float">
                    <div className="carousel-3" onScroll={this.handleScroll}>
                      {this.state.subNavCategories.map((value, index) =>
                        <div className="display-inline">
                          {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                            <div className="selected-carousel-item-2">
                              <label key={value}>{value}</label>
                            </div>
                          ) : (
                            <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                              <label key={value}>{value}</label>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )}

            {(this.state.subNavSelected === 'Goal Plan') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} docType="Goal Plan" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Resume') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} docType="Resume" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Cover Letter') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} source="Builders" docType="Cover Letter" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Career Plan') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} docType="Career Plan" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Financial Plan') && (
              <div>
                <div className={mainClass}>
                  {/*<SubFinancials passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} source="Builders" />*/}
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} source="Builders" docType="Financial Plan" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Business Plan') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} source="Builders" docType="Business Plan" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
            {(this.state.subNavSelected === 'Mock Interview') && (
              <div>
                <div className={mainClass}>
                  <SubEditDocument passData={this.passData} modalIsOpen={this.state.modalIsOpen} selectedFile={this.state.selectedFile} closeModal={this.closeModal} source="Builders" docType="Mock Interview" id={this.state.id} subNavSelected={this.state.subNavSelected} />
                </div>
              </div>
            )}
          </div>

          {(window.innerWidth > 768 && this.state.showSideNav) && (
            <div className="relative-column-40 horizontal-padding top-margin-2-percent max-width-32-percent">
              <div className="left-padding-20">
                <div className="card-clear-padding min-width-100 max-width-400 bottom-margin-20">
                  <div className="full-width height-5 senary-background" />
                  <div className="padding-20">
                    <div className="calc-column-offset-40">
                      <p className="heading-text-5">My Saved {this.state.fileTypePlural}</p>
                    </div>
                    <div className="fixed-column-40 top-margin-5">

                    </div>
                    <div className="clear" />

                    <div className="spacer" />

                    {(this.state.savedItems) && (
                      <div className="top-margin-20 left-text">
                        {this.state.savedItems.map((value2, index2) =>
                          <div key={"savedItems|" + index2}>
                            {(!value2.docType || value2.docType === this.state.subNavSelected) && (
                              <div>
                                <button className="background-button full-width row-10 left-text cta-color" onClick={() => this.setState({ modalIsOpen: true, selectedFile: value2 })}>
                                  <div className="calc-column-offset-70">
                                    <p className="description-text-">{value2.fileName}</p>
                                  </div>
                                  <div className="fixed-column-70 left-padding">

                                  </div>
                                  <div className="clear" />
                                </button>

                                <div className="row-5" />
                                <hr />

                                <div className="row-10" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {(!this.state.disableChatbotPrompts) && (
                  <div className="card-clear-padding min-width-100 max-width-400 bottom-margin-20">
                    <div className="full-width height-5 senary-background" />
                    <div className="padding-20">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5">A.I. Your {this.state.fileTypeSingular}</p>
                      </div>
                      <div className="fixed-column-40 top-margin-5">

                      </div>
                      <div className="clear" />

                      <div className="spacer" />

                      <div className="row-15">
                        <p className="description-text-2">Use ChatGPT, other A.I tools, and your profile to auto-generate your documents.</p>
                      </div>

                      <div className="row-15">
                        <button className="btn btn-squarish" onClick={() => this.autoGeneratePrompt()}>Auto-Generate</button>
                      </div>

                    </div>
                  </div>
                )}

                {(this.state.showIdealCandidate && this.state.selectedIdealCandidate && this.state.learningObjectives && this.state.learningObjectives.length > 0) && (
                  <div className="card-clear-padding min-width-100 bottom-margin-20 max-width-400">
                    <div className="full-width height-5 senary-background" />
                    <div className="padding-20">
                      <div className="calc-column-offset-40">
                        <p className="heading-text-5">Match {this.state.fileTypeSingular} to {this.state.selectedIdealCandidate} Competencies</p>
                      </div>
                      <div className="fixed-column-40 top-margin-5">
                        <button className="background-button" onClick={() => this.setState({ showIdealCandidate: false, selectedIdealCandidate: null, learningObjectives: null })}>
                          <img src={deniedIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />

                      <div className="spacer" />

                      <div className="top-margin-20 left-text">
                        {this.state.learningObjectives.map((value, index) =>
                          <div key={"savedItems|" + index}>
                            <button className="background-button full-width left-text no-pointers clear-margin clear-padding" disabled={true} onClick={() => this.addObjectivesToResume(value,'competency',value.category)}>
                              {(value.category === 'Ability') && (
                                <div className="tag-container-8 primary-background primary-border white-text">
                                  <p className="description-text-2">{index + 1}. {value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                                </div>
                              )}
                              {(value.category === 'Knowledge') && (
                                <div className="tag-container-8 secondary-background secondary-border white-text">
                                  <p className="description-text-2">{index + 1}. {value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                                </div>
                              )}
                              {(value.category === 'Soft Skill') && (
                                <div className="tag-container-8 tertiary-background tertiary-border white-text">
                                  <p className="description-text-2">{index + 1}. {value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                                </div>
                              )}
                              {(value.category === 'General Hard Skill') && (
                                <div className="tag-container-8 quaternary-background quaternary-border white-text">
                                  <p className="description-text-2">{index + 1}. {value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                                </div>
                              )}
                              {(value.category === 'Tech Skill') && (
                                <div className="tag-container-8 quinary-background quinary-border white-text">
                                  <p className="description-text-2">{index + 1}. {value.category}: {value.name}{(value.description) && " (" + value.description + ")"}</p>
                                </div>
                              )}
                            </button>

                            <div className="spacer" />
                            <hr />
                            <div className="half-spacer" />

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {(this.state.builderCommands) && (
                  <div>
                    <SubAssistantWidget commands={this.state.builderCommands} commandClicked={this.commandClicked} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="clear" />
        </div>
      )
    }

    autoGeneratePrompt() {
      console.log('autoGeneratePrompt called', this.state.fileTypeSingular)

      const passedCommandDraft = "Generate a " + this.state.fileTypeSingular.toLowerCase() + "."

      let showPromptDesigner = true
      let clickerToggler = !this.state.clickerToggler

      let docContent = this.state.docContent
      if (!docContent || !docContent.email) {
        docContent = { docType: this.state.subNavSelected }
      }

      this.setState({ modalIsOpen: true, showPromptDesigner, passedCommandDraft,
        clickerToggler, showGradePrompt: false, docContent
      })

    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    passData(passedData) {
      console.log('passData called', passedData)

      if (passedData && passedData.togglePromptDesigner) {
        // because of appfooter function
        // passedData['showPromptDesigner'] = !this.state.showPromptDesigner
        passedData['clickerToggler'] = !this.state.clickerToggler

      }

      this.setState(passedData)

    }

    closeModal() {
      console.log('closeModal called in parent')

      this.setState({ modalIsOpen: false, selectedFile: null, showPromptDesigner: false,
        showGradePrompt: false
      })

    }

    render() {

        return (
            <div>
              {(this.state.readyToRender) && (
                <div>
                  <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} changeSubNavSelected={this.changeSubNavSelected}/>

                  {this.renderFunctionality()}

                  {(this.state.activeOrg) && (
                    <div>
                      {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand,null, null, this.state.showPromptDesigner,this.state.passedCommandDraft,this.state.docContent, this.state.showGradePrompt, this.state.modalIsOpen, this.state.clickerToggler)}
                    </div>
                  )}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(Builders)
