import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import {subExportCSV} from '../Functions/ExportCSV';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const downloadIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-blue.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';

class KeyMetrics extends Component {
    constructor(props) {
      super(props)

      this.state = {
        metricDefinitions: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.exportInfo = this.exportInfo.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in keymetrics ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in keymetrics', this.props)

      let org = this.props.activeOrg

      //obtain email id from localStorage
      let activeOrg = localStorage.getItem('activeOrg');
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('cuFirstName');
      let cuLastName = localStorage.getItem('cuLastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');

      if (!org) {
        org = activeOrg
      }

      if (email && org) {

        const metricDefinitions = [
          { title: 'Sign Ups', shortname: 'signUpCount', description: 'All member sign ups over the time period.'},
          { title: 'Active Users', shortname: 'activeUsers', description: 'All unique members who visited the home page of their account over the period.'},
          { title: 'Submissions', shortname: 'submissionCount', description: 'All submissions associated with learning (e.g., assignments, quizzes, surveys) over the period.'},
          { title: 'Grades', shortname: 'gradeCount', description: 'All grades assigned to submissions over the period.'},
          { title: 'Certificates', shortname: 'certificateCount', description: 'All certificates issued after completing a course over the period.'},
          { title: 'Placements', shortname: 'placementCount', description: 'All placements into volunteer, internship, apprenticeship, or work opportunities over the period.'},
          { title: 'Referrals', shortname: 'referralCount', description: 'All referrals for work opportunities over the period.'},
          { title: 'Endorsements', shortname: 'endorsementCount', description: 'All endorsements for competencies relevant to a specific pathway or opportunity over the period.'},
          { title: 'Documents', shortname: 'documentCount', description: 'All career-related documents created using the document builder over the period.'},
          { title: 'Goals', shortname: 'goalCount', description: 'All goals created over the period.'},
          { title: 'Advising Sessions', shortname: 'advisingSessionCount', description: 'All advising sessions logged / created over the period.'},
          { title: 'RSVPs', shortname: 'rsvpCount', description: 'All RSVPs to events over the period.'},
          { title: 'Applications', shortname: 'applicationCount', description: 'All applications to work opportunities over the period.'},
          { title: 'Career Assessments', shortname: 'assessmentResultCount', description: 'Number of people who completed at least one career assessment over the time period.'},
        ]

        this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus, metricDefinitions })

        const orgCode = org

        Axios.get('/api/key-metrics', { params: { orgCode, sortByRecency: true } })
        .then((response) => {
          if (response.data.success) {
            console.log('Metrics query worked', response.data);

            const metrics = response.data.keyMetrics
            this.setState({ metrics })

          } else {
            console.log('no metrics found', response.data.message)
          }
        }).catch((error) => {
            console.log('Metrics query did not work', error);
        });
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showDefinitions: false })

    }

    exportInfo() {
      console.log('exportInfo called')

      let csvData = []

      let headerArray = ["Date"]
      if (this.state.metricDefinitions && this.state.metricDefinitions.length > 0) {
        for (let i = 1; i <= this.state.metricDefinitions.length; i++) {
          headerArray.push(this.state.metricDefinitions[i - 1].title)
        }
      }

      csvData.push(headerArray)

      let commaStartingValue = /,/g
      let commaReplacementValue = ";"

      if (this.state.metrics && this.state.metrics.length > 0) {
        for (let i = 1; i <= this.state.metrics.length; i++) {
          const item = this.state.metrics[i - 1]

          if (item) {
            const mDate = convertDateToString(new Date(item.createdAt).getMonth(),'formatMonth') + (new Date(item.createdAt).getFullYear())
            let dataArray = [mDate]

            for (let j = 1; j <= this.state.metricDefinitions.length; j++) {
              if (this.state.metricDefinitions[j - 1].shortname && item[this.state.metricDefinitions[j - 1].shortname]) {
                dataArray.push(item[this.state.metricDefinitions[j - 1].shortname])
              } else {
                dataArray.push('0')
              }
            }

            csvData.push(dataArray)
          } else {
            console.log('====', item)
          }
        }
      }

      if (this.state.messages && this.state.messages.length > 0) {
        for (let i = 1; i <= this.state.messages.length; i++) {
          const item = this.state.messages[i - 1]

          const firstName = selectedMember.firstName
          const lastName = selectedMember.lastName
          let email = selectedMember.email

          let course = 'N/A'
          if (item.courseName) {
            course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          }

          const reportType = 'A.I. Conversation'

          let title = ''
          let assignment = ''
          let grade = ''
          let gradeExplanation = ''

          let direction = 'Received'
          if (item.senderEmail === email) {
            direction = 'Sent'
          }

          let message = ''
          if (item.message) {
            message = item.message.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
          }

          const createdAt = item.createdAt
          const updatedAt = item.updatedAt

          csvData.push([
            firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
            direction, message, createdAt, updatedAt
          ])
        }
      }

      const returnedData = subExportCSV("key_metrics",csvData)
    }

    render() {

      return (
          <div>
            <div className="padding-five-percent-left padding-five-percent-right top-margin-2-percent">
              <div className={"card-clear-padding"}>
                {(window.innerWidth > 768 && window.location.pathname.endsWith('/dashboard')) && (
                  <div className="full-width height-5 dark-background" />
                )}

                <div className={"padding-20"}>
                  <div className="calc-column-offset-80">
                    <p className="heading-text-3">Key Metrics</p>
                  </div>
                  <div className="fixed-column-40">
                    <button className="background-button float-right" onClick={() => this.exportInfo()}>
                      <img src={downloadIconBlue} alt="GC" className="image-23-fit" />
                    </button>
                    <div className="clear" />
                  </div>
                  <div className="fixed-column-40">
                    <button className="background-button float-right" onClick={() => this.setState({ modalIsOpen: true, showDefinitions: true })}>
                      <img src={questionMarkBlue} alt="GC" className="image-23-fit" />
                    </button>
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
            <div className="padding-five-percent-left padding-five-percent-right top-margin-2-percent">
              <div className="standard-border carousel-3">
                <div className="standard-border description-text-3 bold-text width-1910">
                  <span className="standard-border selected-background-light row-15 horizontal-padding-4 float-left">
                    <div className="fixed-column-40">
                      <p>#</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Month</p>
                    </div>
                  </span>
                  <span className="standard-border eleven-background-light row-15 horizontal-padding-4 float-left">
                    <div className="fixed-column-120">
                      <p>Sign Ups</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Active Users</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Submissions</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Grades</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Certificates</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Placements</p>
                    </div>
                  </span>
                  <span className="standard-border selected-background-light row-15 horizontal-padding-4 float-left">
                    <div className="fixed-column-120">
                      <p>Referrals</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Endorsements</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Documents</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Goals</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Advising Sessions</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>RSVPs</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Applications</p>
                    </div>
                    <div className="fixed-column-120">
                      <p>Career Assessments</p>
                    </div>
                  </span>

                  <div className="clear" />
                </div>

                {(this.state.metrics && this.state.metrics.length > 0) ? (
                  <div>
                    {this.state.metrics.map((value, index) =>
                      <div key={"metric" + index} className="standard-border description-text-3 white-background width-1910">
                        <span className="standard-border row-15 horizontal-padding-4 float-left">
                          <div className="fixed-column-40">
                            <p>{index + 1}.</p>
                          </div>
                          <div className="fixed-column-120">
                            {(value.createdAt) ? (
                              <p>{convertDateToString(new Date(value.createdAt).getMonth(),'formatMonth')} {new Date(value.createdAt).getFullYear()}</p>
                            ) : (
                              <p> - </p>
                            )}
                          </div>
                        </span>
                        <span className="standard-border row-15 horizontal-padding-4 float-left">
                          <div className="fixed-column-120">
                            <p>{(value.signUpCount) ? value.signUpCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.activeUsers) ? value.activeUsers : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.submissionCount) ? value.submissionCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.gradeCount) ? value.gradeCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.certificateCount) ? value.certificateCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.placementCount) ? value.placementCount : "0"}</p>
                          </div>
                        </span>
                        <span className="standard-border row-15 horizontal-padding-4 float-left">
                          <div className="fixed-column-120">
                            <p>{(value.referralCount) ? value.referralCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.endorsementCount) ? value.endorsementCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.documentCount) ? value.documentCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.goalCount) ? value.goalCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.advisingSessionCount) ? value.advisingSessionCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.rsvpCount) ? value.rsvpCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.applicationCount) ? value.applicationCount : "0"}</p>
                          </div>
                          <div className="fixed-column-120">
                            <p>{(value.assessmentResultCount) ? value.assessmentResultCount : "0"}</p>
                          </div>
                        </span>

                        <div className="clear" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="padding-40 white-background">
                    <p className="error-color bold-text description-text-2">No metrics have been saved yet. Email help@guidedcompass.com to enable monthly metric tracking.</p>
                  </div>
                )}
              </div>
            </div>

            <div className="padding-five-percent-left padding-five-percent-right row-20">
              <p className="description-text-2">View chart definitions <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showDefinitions: true })}>here</button></p>
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             >
              {(this.state.showDefinitions) && (
                <div key="showDefinitions" className="full-width padding-20">
                  <div>
                    <div className="calc-column-offset-30">
                      <p className="heading-text-2">Chart Definitions</p>
                    </div>
                    <div className="fixed-column-30 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img className="image-auto-20" alt="GC" src={closeIcon} />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-20 description-text-1">
                    {this.state.metricDefinitions.map((value, index) =>
                      <div key={"metric" + index}>
                        <p className="full-width">{index + 1}. <label className="bold-text">{value.title}</label>: {value.description}</p>

                        <div className="row-25">
                          <hr />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row-20">
                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                  </div>

                </div>
              )}
            </Modal>
          </div>
      )
    }
}


export default withRouter(KeyMetrics);
