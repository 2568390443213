import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import EditPosting from './Subcomponents/EditPosting';
import withRouter from './Functions/WithRouter';

class EmpAddJob extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let activeOrg = localStorage.getItem('activeOrg')
      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }
      const orgLogo = localStorage.getItem('orgLogo');

      let postType = undefined
      if (this.props.location && this.props.location.state) {
        postType = this.props.location.state.postType
      }

      this.setState({ emailId: email, username, cuFirstName, cuLastName, orgLogo,
        activeOrg, orgMode, emp, postType
      })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg}  history={this.props.navigate} loadWorkspace={this.loadWorkspace}/>
          <div>
            <EditPosting org={this.state.activeOrg} accountCode={this.state.emp} selectedOpportunity={this.state.selectedJob} editMode={this.state.showModule} jobs={this.state.jobs} roleName="Employer" path={window.location.pathname} postType={this.state.postType} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(EmpAddJob)
