import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCourseDetails from './Subcomponents/CourseDetails';
import withRouter from './Functions/WithRouter';

class CourseDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { id} = this.props.params
      // console.log('show match: ', id)
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const orgLogo = localStorage.getItem('orgLogo');
      this.setState({ emailId: email, username, orgLogo, id })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCourseDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.id !== prevProps.params.id) {
        console.log('new course selected in parent')
        this.setState({ id: this.props.params.id })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <div>
                <SubCourseDetails courseId={this.state.id}  history={this.props.navigate}/>
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(CourseDetails);
