import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import SubComments from '../Common/Comments';
import SubRenderCourses from '../Common/RenderCourses';
import SubAssistantWidget from '../Common/AssistantWidget';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const checkmarkIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png";
const favoritesIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-white.png";
const shareIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
// const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
// const difficultyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/difficulty-icon-blue.png';
// const ratingsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/ratings-icon-blue.png';
// const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const trendingUpIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trending-up.png';
const membersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/members-icon-blue.png';
const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const upvoteIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-white.png';
const likeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-blue.png';
const likeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-dark.png';
const commentIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment-icon-dark.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const defaultProfileBackgroundImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/default-profile-background-image.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const targetIconWhite  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-white.png';

class CareerDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          useYouTubeAPI: false,
          players: [],
          subNavCategories: ['All','Videos','Details','Ideal Profile','Questions','Projects','Courses','Jobs','Similar Careers'],
          subNavSelected: 'All',
          questionFilters: ['Hot','New'],
          questionFilterSelected: 'Hot',
          projectFilterSelected: 'Hot',
          favorites: [],
          newFavorites: [],
          showSubFunction: true,

          completions: [],
          completedCourseDetails: [],
          favoritedCourseDetails: [],
        }

        this.getInitialData = this.getInitialData.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.renderKnowledge = this.renderKnowledge.bind(this)
        this.renderSkills = this.renderSkills.bind(this)
        this.renderAbilities = this.renderAbilities.bind(this)
        this.renderPersonality = this.renderPersonality.bind(this)
        this.renderTechnology = this.renderTechnology.bind(this)
        this.renderEducation = this.renderEducation.bind(this)
        this.renderOutlook = this.renderOutlook.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.questionFilterClicked = this.questionFilterClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.pullCourses = this.pullCourses.bind(this)
        this.pullJobs = this.pullJobs.bind(this)
        this.pullSimilarCareers = this.pullSimilarCareers.bind(this)
        this.pullGroupPosts = this.pullGroupPosts.bind(this)
        this.renderJobs = this.renderJobs.bind(this)
        this.addItem = this.addItem.bind(this)

        this.postGroupPost = this.postGroupPost.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
        this.renderGroupPost = this.renderGroupPost.bind(this)
        this.retrieveComments = this.retrieveComments.bind(this)
        this.retrieveLikes = this.retrieveLikes.bind(this)
        this.videoCallback = this.videoCallback.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const careerSelected = this.props.careerSelected
      console.log('careerSelected:', careerSelected);

      if (careerSelected && careerSelected !== '') {
        this.getInitialData(careerSelected)
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(40%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within careerDetails ', this.props.careerSelected, prevProps.careerSelected)

      if (this.props.careerSelected !== prevProps.careerSelected) {
        console.log('new career selected')
        this.getInitialData(this.props.careerSelected)
      }
    }

    // componentWillReceiveProps(newProps) {
    //   console.log('componentWillReceiveProps called', newProps.match, newProps.params)
    //
    //   if (this.state.careerDetails) {
    //     if (this.state.careerDetails.explore_more.careers){
    //       if (newProps.match.params.careerSelected === this.state.careerDetails.explore_more.careers.career[0].title) {
    //         console.log('reload the page');
    //         this.getInitialData(newProps.match.params.careerSelected)
    //       } else {
    //         console.log('do not reload the page');
    //       }
    //     }
    //   }
    // }

    getInitialData(careerSelected) {
      console.log('getInitialData called in SubCareerDetails', careerSelected, this.state.directLink)
      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      if (email) {

        let singularCareerName = careerSelected
        if (singularCareerName && singularCareerName.endsWith('s')) {
          singularCareerName = singularCareerName.substring(0, singularCareerName.length - 1);
        }

        let careerCommands = []
        // careerCommands.push('Create a resume based on my profile for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName + '. {{profile}}')
        // careerCommands.push('Create a cover letter based on my profile for ' + selectedOpportunity.title + ' @ ' + selectedOpportunity.employerName + '. {{profile}}')
        careerCommands.push('List 5 interview questions that would likely be asked in the first round for ' + singularCareerName)
        careerCommands.push('What are 3 example projects that I can work on to impress a hiring manager to hire me as a ' + singularCareerName)
        careerCommands.push('List 3 example projects that my supervisor may give me in first weeks of being a ' + singularCareerName)
        careerCommands.push('What are some strategies and tactics to reach my goal of being a ' + singularCareerName)
        careerCommands.push('How much pay can I expect, on average, for the next 1, 3, and 5 years as a ' + singularCareerName)
        careerCommands.push('What are the top competencies I need to be successful as a ' + singularCareerName)
        careerCommands.push('What are some indications that I would be a good fit for ' + singularCareerName)

        let subNavCategories = ['All','Videos','Details','Ideal Profile','Questions','Projects','Courses','Jobs','Similar Careers']
        if (this.state.showModule) {
          subNavCategories = ['All','Videos','Details','Courses','Jobs','Similar Careers']
        }

        // {this.state.careerDetails.sections.map((value, index) =>
        //   <div key={"section|" + index.toString()}>
        //     <div className="top-padding-30 full-width">
        //       <p className="heading-text-4">{value.sectionTitle}</p>
        //     </div>
        //
        //     {(value.data && value.data.length > 0) && (
        //       <div className="top-margin-30">
        //         {value.data..map((value2, index2) =>
        //           <div key={"section|" + index.toString() + "|data|" + index2.toString()}>
        //             <div>
        //               <p className="heading-text-5">{value2.data.subSectionTitle}</p>
        //               <p className="description-text-1 top-padding high-line-height">{value2.data.subSectionDescription}</p>
        //
        //               <div className="row-20">
        //                 <hr />
        //               </div>
        //             </div>
        //           </div>
        //         )}
        //       </div>
        //     )}
        //
        //     <div>
        //       <div className="col span-2-of-12 description-text-color">
        //         <p>{value.name}</p>
        //       </div>
        //       <div className="col span-10-of-12">
        //         <p>{value.description}</p>
        //       </div>
        //       <div className="clear" />
        //     </div>
        //   </div>
        // )}

        this.setState({ emailId: email, username, activeOrg, cuFirstName, cuLastName, careerCommands, subNavCategories });
      }

      Axios.get('/api/favorites', { params: { emailId: email } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           let favorites = []
           if (response.data.favorites) {
             favorites = response.data.favorites
           }

           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           this.setState({ favorites, newFavorites })
         } else {
           console.log('no favorites data found', response.data.message)
         }

      }).catch((error) => {
         console.log('Favorites query did not work', error);
      });

      Axios.get('/api/users/profile/details', { params: { email } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           let headline = response.data.user.headline
           let pictureURL = response.data.user.pictureURL
           this.setState({ headline, pictureURL });

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      if (this.state.directLink) {
        //the searchTerm should be the id to avoid the search
        Axios.get('/api/careers/details', { params: { searchTerm: careerSelected } })
        .then((response) => {
            console.log('Career details query attempted 1', response.data.success, response.data.career);

            if (response.data.success) {

              let careerDetailsPath = '/app/careers/' + response.data.career.name
              if (this.props.pageSource === 'landingPage') {
                careerDetailsPath = '/careers/' + response.data.career.name
              } else if (window.location.pathname.includes('/advisor')) {
                careerDetailsPath = '/advisor/careers/' + response.data.career.name
              }

              const shareURL = window.location.protocol + "//" + window.location.host + "/careers/" + response.data.career.name
              const shareTitle = 'Check Out ' + response.data.career.name + ' On Guided Compass!'
              let shareBody = ''
              if (response.data.career.overview) {
                shareBody = response.data.career.overview.summary
              }

              this.setState({ careerDetails: response.data.career, careerDetailsPath, shareURL, shareTitle, shareBody })

              let searchTerm = response.data.career.name
              if (response.data.career.knowledgeArray && response.data.career.knowledgeArray.length > 0) {
                searchTerm = response.data.career.knowledgeArray[0].category
              }

              this.pullCourses(searchTerm, null, null, null)

              let jobSearchTerm = response.data.career.name
              if (response.data.career.overview.alsoCalledArray && response.data.career.overview.alsoCalledArray.length > 0) {
                jobSearchTerm = response.data.career.overview.alsoCalledArray[0]
              }

              this.pullJobs(jobSearchTerm, 'US', '10')

              this.pullSimilarCareers([response.data.career])
              this.pullGroupPosts(response.data.career.groupId, activeOrg, true)
              this.pullProjects(response.data.career.name, activeOrg, true)

              // pull group details for comments
              Axios.get('/api/groups/byid', { params: { groupId: response.data.career.groupId } })
              .then((response) => {
                 console.log('Group detail by id query attempted', response.data);

                 if (response.data.success) {
                   console.log('successfully retrieved posting')

                   const selectedGroup = response.data.group
                   this.setState({ selectedGroup })

                 } else {
                   console.log('there was an issue')
                 }
              }).catch((error) => {
                  console.log('Group query did not work', error);
              });
            } else {
              console.log('there was an error on back-end:', response.data.message);
            }
        });
      } else {
        console.log('about to query', careerSelected)

        //the searchTerm should be the url param, to search the best match
        Axios.get('/api/careers/details', { params: { searchTerm: careerSelected } })
        .then((response) => {
            console.log('Career details query attempted 2');

            if (response.data.success) {

              let careerDetailsPath = '/app/careers/' + response.data.career.name
              if (this.props.pageSource === 'landingPage') {
                careerDetailsPath = '/careers/' + response.data.career.name
              } else if (window.location.pathname.includes('/advisor')) {
                careerDetailsPath = '/advisor/careers/' + response.data.career.name
              }

              const shareURL = window.location.protocol + "//" + window.location.host + "/careers/" + response.data.career.name
              const shareTitle = 'Check Out ' + response.data.career.name + ' On Guided Compass!'
              let shareBody = ''
              if (response.data.career.overview) {
                shareBody = response.data.career.overview.summary
              }

              this.setState({ careerDetails: response.data.career, careerDetailsPath, shareURL, shareTitle, shareBody })

              let searchTerm = response.data.career.name
              if (response.data.career.knowledgeArray && response.data.career.knowledgeArray.length > 0) {
                searchTerm = response.data.career.knowledgeArray[0].category
              }

              this.pullCourses(searchTerm, null, null, null)

              let jobSearchTerm = response.data.career.name
              if (response.data.career.overview.alsoCalledArray && response.data.career.overview.alsoCalledArray.length > 0) {
                jobSearchTerm = response.data.career.overview.alsoCalledArray[0]
              }

              this.pullJobs(jobSearchTerm, 'US', '10')

              this.pullSimilarCareers([response.data.career])
              this.pullGroupPosts(response.data.career.groupId, activeOrg, true)
              this.pullProjects(response.data.career.name, activeOrg, true)

              // pull group details for comments
              Axios.get('/api/groups/byid', { params: { groupId: response.data.career.groupId } })
              .then((response) => {
                 console.log('Group detail by id query attempted', response.data);

                 if (response.data.success) {
                   console.log('successfully retrieved posting')

                   const selectedGroup = response.data.group
                   this.setState({ selectedGroup })

                 } else {
                   console.log('there was an issue')
                 }
              }).catch((error) => {
                  console.log('Group query did not work', error);
              });

            } else {
              console.log('there was an error from back-end, message:', response.data.message);
            }
        });
      }

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked for post')

          const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
          const showCompassAssistant = true
          this.setState({ disableChatbotPrompts, showCompassAssistant });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      const queryUdemyCourses = true
      const queryOrgCourses = false
      const excludeOrgCourses = false
      const orgCode = this.state.activeOrg
      const filterObject = null
      const sources = null

      const courseQueryObject = { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 }

      Axios.get('/api/courses/search', { params: courseQueryObject })
      .then((response) => {
        console.log('Courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const courses = response.data.responseData.results
              this.setState({ courses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    pullJobs(searchValue, location, radius) {
      console.log('pullJobs called', searchValue, location, radius)

      this.setState({ animating: true })
      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedJob
      }
      if (!location) {
        location = this.state.location
      }
      if (!radius) {
        radius = this.state.radius
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/external-jobs/search', { params: { searchValue, location, radius } })
        .then((response) => {
          console.log('Jobs query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved jobs')

              if (response.data.postings) {

                const jobs = response.data.postings
                self.setState({ jobs, animating: false })
              }

            } else {
              console.log('no job data found', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Job query did not work', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();

    }

    pullSimilarCareers(selectedCareers) {
      console.log('pullSimilarCareers called', selectedCareers)

      Axios.put('/api/careers/similar', { selectedCareers })
      .then((response) => {
        console.log('Similar careers query attempted', response.data);

          if (response.data.success) {
            console.log('similar careers query worked')

            let matchScores = response.data.matchScores
            const similarCareers = response.data.careers

            this.setState({ animating: false, matchingView: true, matchScores, similarCareers })

          } else {
            console.log('Career match did not work', response.data.message)
            this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message })
          }

      }).catch((error) => {
          console.log('Similar careers did not work for some reason', error);
          this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error' })
      });
    }

    pullGroupPosts(groupId, orgCode, sortByUpvotes) {
      console.log('pullGroupPosts called', groupId, orgCode, sortByUpvotes)

      this.setState({ groupPostsAreLoading: true })

      Axios.get('/api/group-posts', { params: { groupId, orgCode, sortByUpvotes, resLimit: 5, recent: true } })
      .then((response) => {
         console.log('Group posts query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved group posts')

           let groupPosts = []
           if (response.data.groupPosts) {
             groupPosts = response.data.groupPosts
           }

           this.setState({ groupPosts, groupPostsAreLoading: false })

         } else {
           this.setState({ groupPostsAreLoading: false })
         }
      }).catch((error) => {
          console.log('Group posts query did not work', error);
          this.setState({ groupPostsAreLoading: false })
      });
    }

    pullProjects(careerPath, orgCode, sortByUpvotes) {
      console.log('pullProjects called', careerPath, orgCode, sortByUpvotes)

      this.setState({ projectsAreLoading: true })

      Axios.get('/api/projects', { params: { orgCode, resLimit: 6, careerPath, sortByUpvotes, recent: true } })
      .then((response) => {
        console.log('Projects query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved projects')

            if (response.data.projects) {

              const projects = response.data.projects
              this.setState({ projects, projectsAreLoading: false })
            } else {
              this.setState({ projectsAreLoading: false })
            }

          } else {
            console.log('no project data found', response.data.message)
            this.setState({ projectsAreLoading: false })
          }

      }).catch((error) => {
          console.log('Project query did not work', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }
    renderKnowledge() {
      console.log('renderKnowledge called');

      let rows = [];
      for (let i = 1; i <= this.state.careerDetails.knowledgeArray.length; i++) {
        console.log(i)
        rows.push(
          <div key={i}>
            <div className="col span-2-of-12 description-text-color">
                { (i === 1) ? <p>Knowledge</p> : <p> </p>}
            </div>
            <div className="col span-3-of-12">
                <p>{this.state.careerDetails.knowledgeArray[i - 1].category}</p>
            </div>
            <div className="col span-7-of-12">
                <ul className="category-detail-tags">
                  { this.state.careerDetails.knowledgeArray[i - 1].subcategories[0] && (
                    <li><p>{this.state.careerDetails.knowledgeArray[i - 1].subcategories[0]}</p></li>
                  )}
                  { this.state.careerDetails.knowledgeArray[i - 1].subcategories[1] && (
                    <li><p>{this.state.careerDetails.knowledgeArray[i - 1].subcategories[1]}</p></li>
                  )}
                </ul>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderSkills() {
      console.log('renderSkills called');

      let rows = [];
      for (let i = 1; i <= this.state.careerDetails.skillsArray.length; i++) {
        console.log(i)
        rows.push(
          <div key={i}>
            <div className="col span-2-of-12 description-text-color">
                { (i === 1) ? <p>Skills</p> : <p> </p>}
            </div>
            <div className="col span-3-of-12">
                <p>{this.state.careerDetails.skillsArray[i - 1].category}</p>
            </div>
            <div className="col span-7-of-12">
                <ul className="category-detail-tags">
                  { this.state.careerDetails.skillsArray[i - 1].subcategories[0] && (
                    <li><p>{this.state.careerDetails.skillsArray[i - 1].subcategories[0]}</p></li>
                  )}
                  { this.state.careerDetails.skillsArray[i - 1].subcategories[1] && (
                    <li><p>{this.state.careerDetails.skillsArray[i - 1].subcategories[1]}</p></li>
                  )}
                </ul>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderAbilities() {
      console.log('renderAbilities called');

      let rows = [];
      for (let i = 1; i <= this.state.careerDetails.abilitiesArray.length; i++) {
        console.log(i)
        rows.push(
          <div key={i}>
            <div className="col span-2-of-12 description-text-color">
                { (i === 1) ? <p>Abilities</p> : <p> </p>}
            </div>
            <div className="col span-3-of-12">
                <p>{this.state.careerDetails.abilitiesArray[i - 1].category}</p>
            </div>
            <div className="col span-7-of-12">
                <ul className="category-detail-tags">
                  { this.state.careerDetails.abilitiesArray[i - 1].subcategories[0] && (
                    <li><p>{this.state.careerDetails.abilitiesArray[i - 1].subcategories[0]}</p></li>
                  )}
                  { this.state.careerDetails.abilitiesArray[i - 1].subcategories[1] && (
                    <li><p>{this.state.careerDetails.abilitiesArray[i - 1].subcategories[1]}</p></li>
                  )}
                </ul>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderPersonality() {
      console.log('renderPersonality called');

      let rows = [];

      if (this.state.careerDetails.onetInterests && this.state.careerDetails.onetInterests.enterprising) {
        rows.push(
          <div key={0}>
            <div className="col span-2-of-12 description-text-color">
                <p>Interests</p>
            </div>
            <div className="col span-3-of-12">
                <p>Realistic</p>
                <p>Artistic</p>
                <p>Investigative</p>
                <p>Social</p>
                <p>Enterprising</p>
                <p>Conventional</p>
            </div>
            <div className="col span-7-of-12">
                <p>{((this.state.careerDetails.onetInterests.realistic / 7) * 100).toFixed()}%</p>
                <p>{((this.state.careerDetails.onetInterests.artistic / 7) * 100).toFixed()}%</p>
                <p>{((this.state.careerDetails.onetInterests.investigative / 7) * 100).toFixed()}%</p>
                <p>{((this.state.careerDetails.onetInterests.social / 7) * 100).toFixed()}%</p>
                <p>{((this.state.careerDetails.onetInterests.enterprising / 7) * 100).toFixed()}%</p>
                <p>{((this.state.careerDetails.onetInterests.conventional / 7) * 100).toFixed()}%</p>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderTechnology() {
      console.log('renderTechnology called');

      let rows = [];
      for (let i = 1; i <= this.state.careerDetails.technologyArray.length; i++) {

        rows.push(
          <div key={i}>
            <div className="col span-2-of-12 description-text-color">
                { (i === 1) ? <p>Technology</p> : <p> </p>}
            </div>
            <div className="col span-3-of-12">
                <p>{this.state.careerDetails.technologyArray[i - 1].name}</p>
            </div>
            <div className="col span-7-of-12">
                <ul className="category-detail-tags">
                  { this.state.careerDetails.technologyArray[i - 1].examples[0] && (
                    <li><p>{this.state.careerDetails.technologyArray[i - 1].examples[0]}</p></li>
                  )}
                  { this.state.careerDetails.technologyArray[i - 1].examples[1] && (
                    <li><p>{this.state.careerDetails.technologyArray[i - 1].examples[1]}</p></li>
                  )}
                </ul>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderEducation() {
      console.log('renderEducation called');

      let rows = [];
      for (let i = 1; i <= this.state.careerDetails.educationData.education_usually_needed.length; i++) {
        rows.push(
          <div key={i}>
            <div className="col span-2-of-12 description-text-color">
                { (i === 1) ? <p>Education</p> : <p> </p>}
            </div>
            <div className="col span-10-of-12">
                <p>{this.state.careerDetails.educationData.education_usually_needed[i - 1]}</p>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows

    }

    renderOutlook() {
      console.log('renderOutlook called');

      return (
        <div key={0}>
          {(this.state.careerDetails.outlookData && this.state.careerDetails.outlookData.salaryMedian) && (
            <div className="top-padding-20">
              <ul className="career-details-salary">
                  <li>
                    {(this.state.careerDetails.outlookData.salary10thPercentile) ? (
                      <label className="cta-color heading-text-6">${Number(this.state.careerDetails.outlookData.salary10thPercentile).toLocaleString()}</label>
                    ) : (
                      <label className="cta-color heading-text-6">$0</label>
                    )}
                    <p className="field-descriptor">bottom 10%</p>
                  </li>
                  <li>
                    <div className="top-margin-negative-30">
                      &#8212;
                    </div>
                  </li>
                  <li>
                    <label className="cta-color heading-text-6">${Number(this.state.careerDetails.outlookData.salaryMedian).toLocaleString()}</label>
                    <p className="field-descriptor">middle 50%</p>
                  </li>
                  <li>&#8212;</li>
                  <li>
                    {(this.state.careerDetails.outlookData.salary90thPercentile) ? (
                      <label className="cta-color heading-text-6">${Number(this.state.careerDetails.outlookData.salary90thPercentile).toLocaleString()}</label>
                    ) : (
                      <label className="cta-color heading-text-6">$210K+</label>
                    )}
                    <p className="field-descriptor">top 90%</p>
                  </li>
              </ul>
            </div>
          )}
        </div>
      )
    }

    subNavClicked(subNavSelected) {
      console.log('subNavClicked called', subNavSelected)

      this.setState({ subNavSelected })

    }

    questionFilterClicked(questionFilterSelected, type) {
      console.log('questionFilterClicked called', questionFilterSelected, type)

      if (type === 'question') {
        this.setState({ questionFilterSelected })
        if (questionFilterSelected === 'Hot') {
          this.pullGroupPosts(this.state.careerDetails._id, this.state.activeOrg, true)
        } else {
          // new
          this.pullGroupPosts(this.state.careerDetails._id, this.state.activeOrg, false)
        }
      } else if (type === 'project') {
        this.setState({ projectFilterSelected: questionFilterSelected })
        if (questionFilterSelected === 'Hot') {
          this.pullProjects(this.state.careerDetails.name, this.state.activeOrg, true)
        } else {
          // new
          this.pullProjects(this.state.careerDetails.name, this.state.activeOrg, false)
        }
      }
    }

    async favoriteItem(item) {
      console.log('favoriteItem called', item)

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,"career")

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favoritesArray: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){

          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });

        } else {
          console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({
                serverSuccessPlan: true,
                serverSuccessMessagePlan: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'error:' + response.data.message
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({
                serverSuccessPlan: false,
                serverErrorMessagePlan: 'there was an error saving favorites'
              })
          });
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })
      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={this.state.shareURL} subject={this.state.shareTitle} body={this.state.shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={this.state.shareURL} title={this.state.shareTitle} summary={(this.state.careerDetails.overview) ? this.state.careerDetails.overview.summary : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={this.state.shareURL} description={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showShareButtons: false, addQuestion: false, addProject: false, showUpvotes: false, showComments: false })
    }

    renderJobs() {
      console.log('renderJobs called')

      let rows = []
      if (this.state.jobs) {
        for (let i = 1; i <= this.state.jobs.length; i++) {

          const index = i - 1

          let url = ''
          if (this.state.jobs[index].URL) {
            url = this.state.jobs[index].URL
          }

          rows.push(
            <div key={i}>
              <div>
                <div className="spacer"/><div className="spacer"/>

                <a href={url} className="background-button full-width standard-color" target="_blank">
                  <span className="fixed-column-50">
                    <div className="half-spacer"/>
                    <img src={opportunitiesIconDark} alt="Guided Compass project icon" className="image-40-auto"/>
                  </span>
                  <span className="calc-column-offset-80 left-padding-20">
                    <p className="heading-text-6">{this.state.jobs[index].JobTitle} @ {this.state.jobs[index].Company}</p>
                    <p className="description-text-2 top-padding-5">{this.state.jobs[index].Location} | Posted on {this.state.jobs[index].AccquisitionDate}</p>
                  </span>
                  <div className="fixed-column-30 left-padding">
                    <span className="float-left">
                      <div className="spacer"/><div className="half-spacer"/>
                      <Link to={''} className="background-link">
                        <img src={linkIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                      </Link>
                    </span>
                  </div>
                  <div className="clear" />
                  <div className="half-spacer" />

                </a>
              </div>
              <div className="clear" />

              <div className="left-padding-70">
                {(this.state.searchString && this.state.searchString !== '') && (
                  <label className="description-text-2 error-color right-padding-5">Search Term: {this.state.searchString};</label>
                )}
                {(this.state.location && this.state.location !== '') && (
                  <label className="description-text-2 error-color right-padding-5">Location: {this.state.location};</label>
                )}
                {(this.state.radius && this.state.radius !== '') && (
                  <label className="description-text-2 error-color">Radius: {this.state.radius} Miles;</label>
                )}
                <div className="clear" />
              </div>

              <hr className="cell-separator-advisor"/>
            </div>
          )
        }
      }

      return rows
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'question') {
        if (window.location.pathname.includes('/app/careers') || window.location.pathname.includes('/advisor/careers')) {
          this.setState({ modalIsOpen: true, addQuestion: true })
        } else {
          this.props.navigate('/join')
        }
      } else if (type === 'project') {
        if (window.location.pathname.includes('/app/careers') || window.location.pathname.includes('/advisor/careers')) {
          this.setState({ modalIsOpen: true, addProject: true })
        } else {
          this.props.navigate('/join')
        }
      }
    }

    postGroupPost() {
      console.log('postMessage called')

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })
      if (!this.state.postMessage || this.state.postMessage === '') {
        this.setState({ errorMessage: 'Please add a message', isSaving: false })
      } else {

        const postMessage = this.state.postMessage
        const postLink = this.state.postLink
        const tags = this.state.tags

        const postObject = {
          groupId: this.state.careerDetails.groupId, groupName: this.state.careerDetails.name,
          firstName: this.state.cuFirstName, lastName: this.state.cuLastName, email: this.state.emailId,
          pictureURL: this.state.pictureURL, username: this.state.username,
          roleName: this.state.roleName, headline: this.state.headline,
          message: postMessage, url: postLink, tags, upvotes: [], downvotes: [], commentCount: 0,
          orgCode: this.state.activeOrg,
          createdAt: new Date(), updatedAt: new Date()
        }

        Axios.post('/api/group-posts', postObject)
        .then((response) => {
          console.log('attempting to save addition to groups')
          if (response.data.success) {
            console.log('saved addition to groups', response.data)

            let groupPosts = this.state.groupPosts
            if (groupPosts) {
              groupPosts.unshift(postObject)
            } else {
              groupPosts = [postObject]
            }

            this.setState({ groupPosts, isSaving: false })
            this.closeModal()

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving the group posts', isSaving: false})
        });
      }
    }

    voteOnItem(e, item, direction, index, type) {
      console.log('voteOnItem called', index)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ successMessage: null, errorMessage: null })

      const _id = item._id
      const emailId = this.state.emailId
      let changeUpvote = true
      const updatedAt = new Date()

      if (type === 'project') {
        console.log('vote on project')
        Axios.post('/api/projects', { _id, emailId, changeUpvote, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Project save worked', response.data);

            const successMessage = 'Project successfully updated!'

            let projects = this.state.projects
            projects[index] = response.data.project
            this.setState({ projects, successMessage })

          } else {
            console.error('there was an error posting the project');
            const errorMessage = response.data.message
            this.setState({ errorMessage })
          }
        }).catch((error) => {
            console.log('The talk post did not work', error);
        });
      } else {
        Axios.post('/api/group-posts', { _id, emailId, changeUpvote, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Group posts save worked', response.data);

            const successMessage = 'Group posting successfully updated!'

            let upvotes = item.upvotes
            let downvotes = item.downvotes

            if (direction === 'up') {
              console.log('in up')

              if (upvotes.includes(this.state.emailId)) {
                const removeIndex = upvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  upvotes.splice(removeIndex, 1);
                  item['upvotes'] = upvotes
                  changeUpvote = true

                  let groupPosts = this.state.groupPosts
                  groupPosts[index]= item
                  this.setState({ groupPosts, successMessage })
                }
              } else {

                upvotes.push(this.state.emailId)
                changeUpvote = true

                if (downvotes.includes(this.state.emailId)) {
                  const removeIndex = downvotes.indexOf(this.state.emailId)
                  if (removeIndex > -1) {
                    downvotes.splice(removeIndex, 1);
                  }

                  item['upvotes'] = upvotes
                  item['downvotes'] = downvotes

                  let groupPosts = this.state.groupPosts
                  groupPosts[index] = item
                  this.setState({ groupPosts, successMessage })
                } else {

                  item['upvotes'] = upvotes

                  let groupPosts = this.state.groupPosts
                  groupPosts[index] = item

                  this.setState({ groupPosts, successMessage })
                }
              }

            } else {

              if (downvotes.includes(this.state.emailId)) {
                console.log('un-downvoting')
                const removeIndex = downvotes.indexOf(this.state.emailId)
                if (removeIndex > -1) {
                  downvotes.splice(removeIndex, 1);
                  item['downvotes'] = downvotes

                  let groupPosts = this.state.groupPosts
                  groupPosts[index] = item

                  this.setState({ groupPosts, successMessage })
                }
              } else {
                console.log('initial downvote')
                downvotes.push(this.state.emailId)

                if (upvotes.includes(this.state.emailId)) {
                  console.log('downvoting from previous upvote')
                  const removeIndex = upvotes.indexOf(this.state.emailId)
                  if (removeIndex > -1) {
                    upvotes.splice(removeIndex, 1);
                    changeUpvote = true
                  }

                  item['upvotes'] = upvotes
                  item['downvotes'] = downvotes

                  let groupPosts = this.state.GroupPosts
                  groupPosts[index] = item

                  this.setState({ groupPosts, successMessage })
                } else {
                  item['downvotes'] = downvotes

                  let groupPosts = this.state.groupPosts
                  groupPosts[index] = item
                  this.setState({ groupPosts, successMessage })
                }
              }
            }

          } else {
            console.error('there was an error posting the group post');
            const errorMessage = response.data.message
            this.setState({ errorMessage })
          }
        }).catch((error) => {
            console.log('The talk post did not work', error);
        });
      }
    }

    renderGroupPost(item, index, inModal) {
      console.log('renderGroupPost called')

      return (
        <div key={index} className={(inModal) ? "" : "card"}>
          <div className="padding-10">
            <div>
              <div className="fixed-column-70 right-padding">
                <Link to={'/app/profile/' + item.username} className="background-button" target="_blank">
                  <img src={item.pictureURL} alt="GC" className="profile-thumbnail-2 standard-border" />
                </Link>
              </div>
              <div className="calc-column-offset-150">
                <Link to={'/app/profile/' + item.username} className="background-button" target="_blank">
                  <p className="heading-text-5 bold-text">{item.firstName} {item.lastName}</p>
                </Link>
                <p className="description-text-3">{item.headline}</p>
                <p className="description-text-4 description-text-color bold-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
              </div>
              <div className="fixed-column-80 right-padding">
                <button className="background-button" onClick={(e) => this.voteOnItem(e, item, 'up', index) }>
                  <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                    <div className="float-left padding-7">
                      <img src={(item.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="Compass arrow indicator icon" className="image-auto-15"/>
                    </div>
                    <div className="vertical-separator-4" />
                    <div className="float-left horizontal-padding-10">
                      <div className="half-spacer" />
                      <p className="description-text-2 half-bold-text">{item.upvotes.length}</p>
                    </div>
                    <div className="clear" />
                  </div>
                </button>
              </div>
              <div className="clear" />
            </div>
            <div className="row-10">
              <p className="description-text-3">{item.message}</p>
              <a className="description-text-3 top-padding bold-text" href={item.url} target="_blank">{item.url}</a>
            </div>
            {(item.tags && item.tags.length > 0) && (
              <div className="bottom-padding">
                {item.tags.map((item2, index2) =>
                  <div key={index2} className="float-left right-padding top-padding">
                    <div className="tag-container-thin">
                      <p className="description-text-4">{item2}</p>
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            )}

            {(item.upvotes || (item.comments && item.comments.length > 0)) && (
              <div className="bottom-padding-5">
                <button onClick={() => this.retrieveLikes(index)} className="background-button">
                  <label className="description-text-3">{(item.upvotes) ? item.upvotes.length : 0} Upvotes</label>
                </button>
                <label className="description-text-3 horizontal-padding-7">&#8226;</label>
                <button onClick={() => this.retrieveComments(index)} className="background-button">
                  <label className="description-text-3">{(item.commentCount) ? item.commentCount : 0} Comments</label>
                </button>
              </div>
            )}
          </div>

          <hr />

          {(!inModal) && (
            <div className="row-10 horizontal-padding-5">
              <div className="float-left">
                <button onClick={(e) => this.voteOnItem(e, item, 'up', index) } className="background-button">
                  <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                    <img src={(item.upvotes.includes(this.state.emailId))? likeIconBlue : likeIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                  </div>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-15"}>
                    <p className={(item.upvotes.includes(this.state.emailId)) ? "description-text-2 cta-color bold-text" : "description-text-2"}>{(item.upvotes.includes(this.state.emailId)) ? "Liked" : "Like"}</p>
                  </div>
                  <div className="clear" />
                </button>
              </div>

              <div className="float-left">
                <button onClick={() => this.retrieveComments(index)} className="background-button" disabled={this.state.isLoading}>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                    <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                    <img src={commentIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                  </div>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-15"}>
                    <p className="description-text-2">Comment</p>
                  </div>
                  <div className="clear" />
                </button>
              </div>

              <div className="float-left">
                <button onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true, sharePosting: true, selectedIndex: index })} className="background-button">
                  <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                    <img src={shareIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                  </div>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-15"}>
                    <p className="description-text-2">Share</p>
                  </div>
                  <div className="clear" />
                </button>
              </div>

              <div className="float-left">
                <Link className="background-button standard-color" to={'/app/messaging'} state={{ groupPost: item }}>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                    <img src={sendIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                  </div>
                  <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-15"}>
                    <p className="description-text-2">Send</p>
                  </div>
                  <div className="clear" />
                </Link>
              </div>
              <div className="clear" />
            </div>
          )}
        </div>
      )
    }

    retrieveComments(index) {
      console.log('retrieveComments called', index)

      // pull comments
      Axios.get('/api/comments', { params: { parentPostId: this.state.groupPosts[index]._id } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           const comments = response.data.comments
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments })

         } else {
           console.log('no comments data found', response.data.message)
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
         }
      }).catch((error) => {
         console.log('Comments query did not work', error);
         this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
      });
    }

    retrieveLikes(index) {
      console.log('retrieveLikes called', index)

      const userIds = this.state.groupPosts[index].upvotes
      if (userIds) {
        // pull comments
        Axios.get('/api/users', { params: { userIds } })
        .then((response) => {
          console.log('Users query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved users')

             const upvotes = response.data.users
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes })

           } else {
             console.log('no upvotes data found', response.data.message)
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
           }
        }).catch((error) => {
           console.log('Upvotes query did not work', error);
           this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
        });
      } else {
        this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
      }
    }

    videoCallback(event, callback) {
      console.log('videoCallback called', event, callback)

      if (callback === 'onReady') {
        const players = this.state.players;
        players.push(event.target);
        this.setState({ players });
        console.log('players: ', event.target.id)
      } else if (callback === 'onPlay') {
        this.state.players.forEach((player) => {
          if (player.id !== event.target.id) {
            player.pauseVideo();
          }
        });
      }
    }

    commandClicked(command) {
      console.log('commandClicked called in subcareerDetails')

      this.props.commandClicked(command)

    }

    render() {

      let pathPrefix = '/app/careers/'
      if (window.location.pathname.includes('/advisor')) {
        pathPrefix = '/advisor/careers/'
      } else if (this.props.pageSource === 'landingPage') {
        pathPrefix = '/careers/'
      }

      let mainClass = "standard-container-2"
      let mainStyle = {}
      let mainClass2 = "width-70-percent center-horizontally max-width-1400"
      // "standard-container-2 clear-padding clear-background"
      let containerClass = ""
      let subClass = "full-width"
      let subClass2 = "full-width"
      let subSubClass = ""
      if (window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) {
        containerClass = "relative-column-70 padding-five-percent-left"
        mainClass = ""
        mainClass2 = ""
        subClass = "card slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"
        subClass2 = "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"
        subSubClass = "padding-30"
      }

      return (
          <div>
              {this.state.careerDetails && (
                <div>
                  <div className={containerClass}>
                    <div className={mainClass} style={mainStyle}>
                      <div className={subClass2}>
                        {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) && (
                          <div className="full-width height-5 primary-background" />
                        )}

                        <div className={subSubClass}>
                          <div className="top-padding-20">
                            <h2>{this.state.careerDetails.name}</h2>
                          </div>

                          {this.renderOutlook()}

                          <div className="top-padding-20 center-text">
                            {(window.location.pathname.includes('/app/careers')) && (
                              <button className="btn btn-squarish right-margin" onClick={() => this.setState({ modalIsOpen: true, showGoal: true })}>
                                <div className="float-left">
                                  <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                  {(this.state.adoptions && this.state.adoptions.includes(this.state.careerDetails._id)) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={targetIconWhite} alt="GC" className="image-auto-15" />}
                                </div>
                                <div className="float-left left-padding">
                                  {(this.state.adoptions && this.state.adoptions.includes(this.state.careerDetails._id)) ? "Adopted" : "Adopt"}
                                </div>
                                <div className="clear" />
                              </button>
                            )}
                            {(window.location.pathname.includes('/app/careers') || window.location.pathname.includes('/advisor/careers')) && (
                              <button className="btn btn-squarish denary-background clear-border white-text right-margin-5" onClick={() => this.favoriteItem(this.state.careerDetails)}>
                                <div className="float-left">
                                  <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                  {(this.state.favorites.includes(this.state.careerDetails._id)) ? <img src={checkmarkIconWhite} alt="GC" className="image-auto-15" /> : <img src={favoritesIconWhite} alt="GC" className="image-auto-15" />}
                                </div>
                                <div className="float-left left-padding">
                                  {(this.state.favorites.includes(this.state.careerDetails._id)) ? "Favorited" : "Favorite"}
                                </div>
                                <div className="clear" />
                              </button>
                            )}

                            <button className={(window.location.pathname.includes('/app/careers') || window.location.pathname.includes('/advisor/careers')) ? "btn btn-squarish white-background cta-color left-margin-5" : "btn btn-squarish white-background cta-color"} onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true })}>
                              <div className="float-left">
                                <div className='mini-spacer' /><div className='mini-spacer' /><div className='mini-spacer' />
                                <img src={shareIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="float-left left-padding">Share</div>
                              <div className="clear" />
                            </button>
                          </div>

                          {(this.state.careerDetails.overview) && (
                            <div className="center-text row-20">
                              <div className="float-left">
                                <p>{this.state.careerDetails.overview.summary}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={mainClass2}>
                        <div className={subClass2}>
                          <div className="full-width white-background">
                            <div className="clear-float">
                              <div className="carousel-3" onScroll={this.handleScroll}>
                                {this.state.subNavCategories.map((value, index) =>
                                  <div className="carousel-item-container heading-text-5">
                                    {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                      <div className="selected-carousel-item">
                                        <label key={value}>{value}</label>
                                      </div>
                                    ) : (
                                      <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                                        <label key={value}>{value}</label>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Videos') && this.state.careerDetails.videos && this.state.careerDetails.videos.length > 0) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-3">Videos</p>
                              <p className="description-text-1">Watch a day in the life of this profession, get interview tips, and learn relevant skills.</p>
                            </div>

                            <div className="spacer" />

                            {this.state.careerDetails.videos.map((value, index) =>
                              <div key={value}>
                                <div>
                                  <div className="top-margin-20">
                                    <div className={(index % 2 === 0) ? "container-left" : "container-right"}>
                                      <div className="video-container-4">
                                        {(this.state.useYouTubeAPI) ? (
                                          <YouTube
                                            videoId={value.split("/embed/")[1]}
                                            id={value._id}
                                            className="video-iframe-4"
                                            containerClassName={""}
                                            opts={(e) => this.videoCallback(e,'opts')}
                                            onReady={(e) => this.videoCallback(e,'onReady')}
                                            onPlay={(e) => this.videoCallback(e,'onPlay')}
                                            onPause={(e) => this.videoCallback(e,'onPause')}
                                            onEnd={(e) => this.videoCallback(e,'onEnd')}
                                            onError={(e) => this.videoCallback(e,'onError')}
                                            onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                            onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                            onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                          />
                                        ) : (
                                          <iframe
                                            title="videoLink"
                                            className="video-iframe"
                                            src={value}
                                            frameBorder="0"
                                          />
                                        )}
                                      </div>
                                      <div className="clear"/>
                                    </div>

                                    {(index % 2 === 1) && (
                                      <div className="clear" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="clear" />
                          </div>
                        </div>
                      </div>
                    )}

                    {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Details') && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-4">Details</p>
                            </div>

                            <div className="spacer" />

                            {(this.state.showModule) ? (
                              <div>
                                {(this.state.careerDetails.sections && this.state.careerDetails.sections.length > 0) && (
                                  <div>
                                    {this.state.careerDetails.sections.map((value, index) =>
                                      <div key={"section|" + index.toString()} className={(index === 0) ? "standard-border padding-30 top-margin-30" : "standard-border padding-30 top-margin-50"}>
                                        <div className="full-width row-10">
                                          <p className="heading-text-6 bold-text">{value.sectionTitle}</p>
                                        </div>

                                        {(value.data && value.data.length > 0) && (
                                          <div>
                                            {value.data.map((value2, index2) =>
                                              <div key={"section|" + index.toString() + "|data|" + index2.toString()}>
                                                {(index2 === 0) ? (
                                                  <div className="row-25 bottom-margin-20">
                                                    <hr />
                                                  </div>
                                                ) : (
                                                  <div className="row-30">
                                                    <hr />
                                                  </div>
                                                )}

                                                <div>
                                                  <p className="bold-text darkish-color">{value2.subSectionTitle}</p>
                                                  <p className="description-text-1 top-padding-15 high-line-height">{value2.subSectionDescription}</p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}

                                        <div>
                                          <div className="col span-2-of-12 description-text-color">
                                            <p>{value.name}</p>
                                          </div>
                                          <div className="col span-10-of-12">
                                            <p>{value.description}</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                {(this.state.careerDetails.overview && this.state.careerDetails.overview.alsoCalledArray && this.state.careerDetails.overview.alsoCalledArray[0]) && (
                                  <div>
                                    <div className="col span-1-of-6 description-text-color">
                                        <p>Also called</p>
                                    </div>
                                    <div className="col span-5-of-6">
                                      <label>{this.state.careerDetails.overview.alsoCalledArray[0]}</label>
                                      {(this.state.careerDetails.overview.alsoCalledArray[1]) && (
                                        <label>, {this.state.careerDetails.overview.alsoCalledArray[1]}</label>
                                      )}
                                      {(this.state.careerDetails.overview.alsoCalledArray[2]) && (
                                        <label>, {this.state.careerDetails.overview.alsoCalledArray[2]}</label>
                                      )}
                                      <div className="clear" />

                                    </div>
                                  </div>
                                )}
                                {(this.state.careerDetails.overview && this.state.careerDetails.overview.tasks && this.state.careerDetails.overview.tasks[0]) && (
                                  <div className="bottom-padding-40">
                                    <div className="col span-1-of-6 description-text-color">
                                        <p>Common Tasks</p>
                                    </div>
                                    <div className="col span-5-of-6">
                                        <p>Task 1: {this.state.careerDetails.overview.tasks[0]}</p>
                                        {(this.state.careerDetails.overview.tasks[1]) && (
                                          <div className="top-padding">
                                            <p>Task 2: {this.state.careerDetails.overview.tasks[1]}</p>
                                          </div>
                                        )}
                                        {(this.state.careerDetails.overview.tasks[2]) && (
                                          <div className="top-padding">
                                            <p>Task 3: {this.state.careerDetails.overview.tasks[2]}</p>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )}

                                {(this.state.careerDetails.activities && this.state.careerDetails.activities.length > 0) && (
                                  <div>
                                    <div className="top-padding-20">
                                      <p className="heading-text-6 underline-text">Activities</p>
                                    </div>

                                    {this.state.careerDetails.activities.map((value, index) =>
                                      <div key={value}>
                                        <div>
                                          <div className="col span-2-of-12 description-text-color">
                                            <p>{value.name}</p>
                                          </div>
                                          <div className="col span-10-of-12">
                                            <p>{value.description}</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                )}

                                {(this.state.careerDetails.outlookData && this.state.careerDetails.outlookData.outlook) && (
                                  <div>
                                    <div className="col span-2-of-12 description-text-color">
                                      <p>Future Outlook</p>
                                    </div>
                                    <div className="col span-10-of-12">
                                      <p>{this.state.careerDetails.outlookData.outlook}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}

                                {(this.state.careerDetails.marketData) && (
                                  <div>
                                    <div className="top-padding-20">
                                      <p className="heading-text-6 underline-text">Market Data</p>
                                    </div>
                                    <div>
                                      <div className="col span-2-of-12 description-text-color">
                                        <p>Pay</p>
                                      </div>
                                      <div className="col span-10-of-12">
                                        <p>${Number(this.state.careerDetails.marketData.pay).toLocaleString()}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div>
                                      <div className="col span-2-of-12 description-text-color">
                                        <p>Total Employment</p>
                                      </div>
                                      <div className="col span-10-of-12">
                                        <p>{this.state.careerDetails.marketData.totalEmployment}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div>
                                      <div className="col span-2-of-12 description-text-color">
                                        <p>Growth</p>
                                      </div>
                                      <div className="col span-10-of-12">
                                        <p>{this.state.careerDetails.marketData.growth}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div>
                                      <div className="col span-2-of-12 description-text-color">
                                        <p>New Jobs</p>
                                      </div>
                                      <div className="col span-10-of-12">
                                        <p>{Number(this.state.careerDetails.marketData.newJobs).toLocaleString()}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </div>
                                )}

                                {(this.state.careerDetails.whereTheyWork && this.state.careerDetails.whereTheyWork.length > 0) && (
                                  <div>
                                    <div className="top-padding-20">
                                      <p className="heading-text-6 underline-text">Where They Work</p>
                                    </div>

                                    {this.state.careerDetails.whereTheyWork.map((value, index) =>
                                      <div key={value}>
                                        <div>
                                          <div className="col span-2-of-12 description-text-color">
                                            <p>{value.title}</p>
                                          </div>
                                          <div className="col span-10-of-12">
                                            <p>{value.percentEmployed}% Employed</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                )}

                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {((!this.state.showModule) && (this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Ideal Profile')) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-3">Ideal Candidate Profile</p>
                            </div>

                            <div className="spacer" />

                            { this.state.careerDetails.knowledgeArray && this.state.careerDetails.knowledgeArray.length > 0 && (
                              <div>
                                {this.renderKnowledge()}
                              </div>
                            )}
                            { this.state.careerDetails.skillsArray && this.state.careerDetails.skillsArray.length > 0 && (
                              <div>
                                {this.renderSkills()}
                              </div>
                            )}
                            { this.state.careerDetails.abilitiesArray && this.state.careerDetails.abilitiesArray.length > 0 && (
                              <div>
                                {this.renderAbilities()}
                              </div>
                            )}
                            { this.state.careerDetails.personalityData && (
                              <div>
                                {this.renderPersonality()}
                              </div>
                            )}
                            { this.state.careerDetails.technologyArray && this.state.careerDetails.technologyArray.length > 0 && (
                              <div>
                                {this.renderTechnology()}
                              </div>
                            )}
                            { this.state.careerDetails.educationData && this.state.careerDetails.educationData.education_usually_needed && (
                              <div>
                                  {this.renderEducation()}
                              </div>
                            )}

                            <div className="clear" />
                          </div>
                        </div>
                      </div>
                    )}

                    {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Courses') && (this.state.courses && this.state.courses.length > 0)) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-3">Courses</p>
                            </div>

                            <div className="spacer" />

                            <SubRenderCourses
                              history={this.props.navigate} animating={this.state.animating}
                              courses={this.state.courses} pageSource={this.props.pageSource}
                              sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                            />

                            <div className="clear" />
                          </div>
                        </div>
                      </div>
                    )}

                    {((!this.state.showModule) && (this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Questions')) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-3">Questions, Answers, and Discussion</p>
                              </div>
                              <div className="fixed-column-40">
                                <div className="background-button top-padding-5" onClick={() => this.addItem('question')}>
                                  <img className="image-auto-18" alt="GC" src={addIcon} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Start a discussion"/>
                                </div>
                              </div>
                              <div className="clear" />
                              <ReactTooltip id="tooltip-placeholder-id" />
                            </div>

                            <div className="spacer" />

                            <div className="full-width white-background top-padding-20">
                              <div>
                                <div className="carousel-3" onScroll={this.handleScroll}>
                                  {this.state.questionFilters.map((value, index) =>
                                    <div className="display-inline">
                                      {(this.state.questionFilters[index] === this.state.questionFilterSelected) ? (
                                        <div className="selected-carousel-item-2">
                                          <label key={value}>{value}</label>
                                        </div>
                                      ) : (
                                        <button className="menu-button-2" onClick={() => this.questionFilterClicked(value,'question')}>
                                          <label key={value}>{value}</label>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                            <hr />

                            {(this.state.groupPostsAreLoading) ? (
                              <div className="flex-container flex-center full-space">
                                <div>
                                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                  <p className="center-text cta-color bold-text">Pulling results...</p>

                                </div>
                              </div>
                            ) : (
                              <div>
                                {(this.state.groupPosts) && (
                                  <div className="top-padding-20">
                                    {this.state.groupPosts.map((item, index) =>
                                      <div key={index} className="bottom-padding">
                                        {this.renderGroupPost(item, index)}
                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {((!this.state.showModule) && (this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Projects')) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-3">Projects by Career-Seekers</p>
                              </div>
                              <div className="fixed-column-40">
                                <Link className="background-button top-padding-5" to={'/app/edit-profile/profile-details'} target="_blank">
                                  <img className="image-auto-18" alt="GC" src={addIcon} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a project"/>
                                </Link>
                              </div>
                              <div className="clear" />
                              <ReactTooltip id="tooltip-placeholder-id" />
                            </div>

                            <div className="spacer" />

                            <div className="full-width white-background top-padding-20">
                              <div>
                                <div className="carousel-3" onScroll={this.handleScroll}>
                                  {this.state.questionFilters.map((value, index) =>
                                    <div className="display-inline">
                                      {(this.state.questionFilters[index] === this.state.projectFilterSelected) ? (
                                        <div className="selected-carousel-item-2">
                                          <label key={value}>{value}</label>
                                        </div>
                                      ) : (
                                        <button className="menu-button-2" onClick={() => this.questionFilterClicked(value,'project')}>
                                          <label key={value}>{value}</label>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                            <hr />

                            {(this.state.projects && this.state.projects.length > 0) && (
                              <div>
                                {(this.state.projectsAreLoading) ? (
                                  <div className="flex-container flex-center full-space">
                                    <div>
                                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                      <p className="center-text cta-color bold-text">Pulling results...</p>

                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    {this.state.projects.map((item, optionIndex) =>
                                      <div key={item + optionIndex}>
                                        <div className={(window.innerWidth < 768) ? "" : "relative-column-33 horizontal-padding-7"}>
                                          <Link className="background-button full-width standard-color" to={'/app/projects/' + item._id}>

                                            <div className="elevated-box white-background" >
                                              <div className="full-width relative-position tint">
                                                <img src={(item.imageURL) ? item.imageURL : defaultProfileBackgroundImage} alt="Trending up icon" className="image-full-width-150 center-horizontally"  />
                                                <div className="absolute-position absolute-top-5 absolute-left-5">
                                                  <p className="description-text-5 rounded-corners horizontal-padding-4 row-5 white-border white-text bold-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Category: " + item.category}>{item.category}</p>
                                                </div>
                                                <div className="absolute-position absolute-top-5 absolute-right-5">
                                                  <button className="background-button" onClick={(e) => this.voteOnItem(e, item, 'up', optionIndex,'project') }>
                                                    <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                                                      <div className="float-left padding-7">
                                                        <img src={(item.upvotes && item.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconWhite} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                                      </div>
                                                      <div className="vertical-separator-4" />
                                                      <div className="float-left horizontal-padding-10">
                                                        <div className="half-spacer" />
                                                        <p className="description-text-2 half-bold-text white-text">{(item.upvotes) ? item.upvotes.length : 0}</p>
                                                      </div>
                                                      <div className="clear" />
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>

                                              <div className="spacer" />

                                              <div className="horizontal-padding left-text">
                                                <p className="heading-text-5">{item.name}</p>

                                                <div className="top-padding">
                                                  <div className="fixed-column-35">
                                                    <img className="profile-thumbnail-25" alt="GC" src={(item.userPic) ? item.userPic : profileIconDark} />
                                                  </div>
                                                  <div className="calc-column-offset-35 description-text-2">
                                                    <p>{item.userFirstName} {item.userLastName}</p>
                                                    <label className="description-text-3">{item.hours} Hours</label>
                                                  </div>
                                                  <div className="clear" />
                                                </div>

                                                {(item.collaborators && item.collaborators.length > 0) && (
                                                  <span className="top-padding">
                                                    <label className="description-text-5 left-padding-5">|</label>
                                                    <label className="description-text-5 left-padding-5">{item.collaborators.length} Collaborators</label>
                                                  </span>
                                                )}

                                                {(item.skillTags) && (
                                                  <div>
                                                    <div className="top-padding">
                                                      {item.skillTags.split(',').map((value, optionIndex) =>
                                                        <div key={value}>
                                                          {(optionIndex < 3) && (
                                                            <div key={value} className="float-left row-5 right-padding">
                                                              <div className="tag-container-7">
                                                                <p className="description-text-3">{value}</p>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      )}
                                                      <div className="clear" />
                                                    </div>
                                                  </div>
                                                )}

                                                <div className="clear" />

                                                <div className="top-padding-20">
                                                  <div className="display-inline full-width">
                                                    <button className={this.state.favorites.includes(item._id) ? "btn btn-profile medium-background clear-border full-width" : "btn btn-profile full-width"} disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.favoriteItem(e, item)}>{this.state.favorites.includes(item._id) ? "Following" : "Follow"}</button>
                                                  </div>
                                                </div>

                                                <div className="spacer" />
                                              </div>
                                            </div>
                                          </Link>
                                        </div>

                                        {(optionIndex % 3 === 2 && window.innerWidth >= 768) && (
                                          <div>
                                            <div className="clear" />
                                            <div className="spacer" />
                                          </div>
                                        )}

                                        {(window.innerWidth < 768) && (
                                          <div>
                                            <div className="clear" />
                                            <div className="spacer" />
                                          </div>
                                        )}

                                        <ReactTooltip id="tooltip-placeholder-id" />

                                      </div>
                                    )}
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Jobs') && (this.state.jobs && this.state.jobs.length > 0)) && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-3">Jobs</p>
                              <p className="profile-descriptor">(External to Guided Compass)</p>
                            </div>

                            <div className="spacer" />

                            {this.renderJobs()}

                            <div className="clear" />
                          </div>
                        </div>
                      </div>
                    )}

                    {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Similar Careers') && (
                      <div>
                        <div className={mainClass} style={mainStyle}>
                          <div className={subClass}>
                            <div className="top-padding-20 full-width">
                              <p className="heading-text-3">Similar Careers</p>
                            </div>

                            <div className="spacer" />

                            {(this.state.similarCareers) && (
                              <div>
                                {this.state.similarCareers.map((value, index) =>
                                  <div>
                                    <div key={index}>
                                      <div className="spacer" />
                                      <Link to={pathPrefix + value.name} state={{ careerSelected: value }} className="background-link">
                                        <div className="fixed-column-70">
                                          <img src={careerMatchesIconDark} alt="GC" className="image-auto-50 top-margin-5 center-item"/>
                                        </div>
                                        <div className={(this.props.pageSource === 'landingPage') ? "calc-column-offset-100-static" : "calc-column-offset-140-static"}>
                                          <p className="heading-text-5">{value.name}</p>
                                          <p className="description-text-1 description-text-color curtail-text">{value.jobFunction}{(value.jobFunction && value.industry) && ' | ' + value.industry}{(!value.jobFunction && value.industry) && value.industry}{(value.jobFamily) && ' | ' + value.jobFamily}</p>

                                          {(value.marketData) && (
                                            <div className="row-5 description-text-2 bold-text">
                                              <div>
                                                <div className="float-left right-padding">
                                                  <img src={trendingUpIcon} alt="Compass arrow indicator icon" className="image-auto-15"/>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{value.marketData.growth}</p>
                                                </div>
                                              </div>

                                              <div>
                                                <div className="float-left right-padding">
                                                  <img src={moneyIconBlue} alt="Compass arrow indicator icon" className="image-auto-20"/>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">${Number(value.marketData.pay).toLocaleString()}</p>
                                                </div>
                                              </div>

                                              {(window.innerWidth <= 768) && (
                                                <div className="clear" />
                                              )}

                                              <div>
                                                <div className="float-left right-padding">
                                                  <img src={membersIconBlue} alt="GC" className="image-auto-22"/>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{Number(value.marketData.totalEmployment).toLocaleString()}</p>
                                                </div>
                                              </div>

                                              <div className="clear" />
                                            </div>
                                          )}

                                          {(value.onetInterests) && (
                                            <div className="row-5 description-text-2 bold-text">
                                              <div>
                                                <div className="float-left right-padding">
                                                  <img src={favoritesIconBlue} alt="Compass arrow indicator icon" className="image-auto-20"/>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.realistic / 7) * 100).toFixed()}% R  -</p>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.investigative / 7) * 100).toFixed()}% I  -</p>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.artistic / 7) * 100).toFixed()}% A  -</p>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.social / 7) * 100).toFixed()}% S  -</p>
                                                </div>
                                                <div className="float-left right-padding">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.enterprising / 7) * 100).toFixed()}% E  -</p>
                                                </div>
                                                <div className="float-left">
                                                  <p className="description-text-1 description-text-color curtail-text">{((value.onetInterests.conventional / 7) * 100).toFixed()}% C</p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </Link>

                                      {(this.props.pageSource !== 'landingPage') && (
                                        <span className="fixed-column-40 top-margin-15">
                                          <button className="btn background-button" onClick={() => this.favoriteItem(value) }>
                                            <img src={(this.state.favorites.includes(value._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                                          </button>
                                        </span>
                                      )}

                                      <div className="fixed-column-25 left-padding">
                                        <span className="float-right">
                                          <div className="spacer"/><div className="half-spacer"/>
                                          <Link to={pathPrefix + value.name} state={{ careerSelected: value }} className="background-link">
                                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                                          </Link>
                                        </span>
                                      </div>
                                      <div className="clear"/>

                                      {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[index] && this.state.sortCriteriaArray[index].name) && (
                                        <div className="left-padding-70">
                                          <div className="half-spacer" />
                                          <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[index].name}: {this.standardizeValue('sort',index, this.state.sortCriteriaArray[index].criteria)}</p>
                                        </div>
                                      )}
                                      {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[index] && this.state.filterCriteriaArray[index].name) && (
                                        <div className="left-padding-70">
                                          <div className="half-spacer" />
                                          <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[index].name}: {this.state.filterCriteriaArray[index].criteria}</p>
                                        </div>
                                      )}
                                      <div className="spacer" /><div className="spacer" />
                                      <hr className="clear-margin clear-padding" />
                                      <div className="clear"/>
                                      <div className="spacer" />
                                    </div>

                                  </div>
                                )}

                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) && (
                    <div className="relative-column-30 horizontal-padding top-margin-40">
                      <div className="left-padding-20">
                        {(this.state.showCompassAssistant && !this.state.disableChatbotPrompts && this.state.careerCommands && this.state.careerCommands.length > 0) && (
                          <div>
                            <SubAssistantWidget commands={this.state.careerCommands} commandClicked={this.commandClicked} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="clear" />

                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

               {(this.state.showShareButtons) && (
                 <div key="showDescription" className="full-width padding-20 center-text">
                    <p className="heading-text-2">Share the {this.state.careerDetails.name} Career Page with Friends!</p>

                    <div className="top-padding-20">
                      <p>Share this link:</p>
                      <a href={this.state.shareURL} target="_blank">{this.state.shareURL}</a>
                    </div>

                    <div className="spacer" />

                    <div className="top-padding-20">
                      {this.renderShareButtons()}
                    </div>

                  </div>
               )}

               {(this.state.addQuestion) && (
                 <div key="addQuestion" className="full-width padding-20">
                    <div className="row-10">
                      <div className="fixed-column-70 right-padding">
                        <Link to={'/app/profile/' + this.state.username} className="background-button standard-color" target="_blank">
                          <img src={this.state.pictureURL} alt="GC" className="profile-thumbnail-2 standard-border" />
                        </Link>
                      </div>
                      <div className="calc-column-offset-70">
                        <Link to={'/app/profile/' + this.state.username} className="background-button standard-color" target="_blank">
                          <p className="heading-text-2">{this.state.cuFirstName} {this.state.cuLastName}</p>
                        </Link>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-10">
                      <textarea type="text" className="text-field clear-border standard-border" placeholder="Start a conversation in this group..." name="postMessage" value={this.state.postMessage} onChange={this.formChangeHandler} />
                    </div>

                    <div className="row-10">
                      <p className="description-text-5 description-text-color bold-text">SHARE A LINK (Optional)</p>
                      <div className="half-spacer" />
                      <input type="text" className="text-field" placeholder="Add a link..." name="postLink" value={this.state.postLink} onChange={this.formChangeHandler}/>
                      {(this.state.postLink && !this.state.postLink.startsWith('http')) && (
                        <div className="row-5">
                          <p className="error-color bold-text description-text-2">Please enter a valid link</p>
                        </div>
                      )}
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-message">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="error-message">{this.state.successMessage}</p>}

                    <div className="row-10">
                      <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.postGroupPost()}>Post</button>
                    </div>

                  </div>
               )}

               {(this.state.addProject) && (
                 <div key="addProject" className="full-width padding-20 center-text">
                    <p className="heading-text-2">Add Project</p>
                  </div>
               )}

               {(this.state.showComments) && (
                 <div key="showComments" className="full-width">
                  {this.renderGroupPost(this.state.groupPosts[this.state.selectedIndex], this.state.selectedIndex, true)}

                  <div className="spacer" />

                  {(this.state.selectedGroup) && (
                    <SubComments selectedGroup={this.state.selectedGroup} selectedGroupPost={this.state.groupPosts[this.state.selectedIndex]} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={'guidedcompass'} postingOrgName={'Guided Compass'} orgContactEmail={'help@guidedcompass.com'} pictureURL={this.state.pictureURL} history={this.props.navigate} pageSource={"groupDetails"} />
                  )}

                 </div>
               )}

               {(this.state.showUpvotes) && (
                 <div key="showUpvotes" className="full-width">
                   <div className="bottom-padding">
                     <p className="heading-text-2">{this.state.careerDetails.name} Upvotes</p>
                   </div>

                  <div className="spacer" />

                  {(this.state.upvotes && this.state.upvotes.length > 0) ? (
                    <div className="top-padding">
                      {this.state.upvotes.map((value, optionIndex) =>
                        <div key={"upvote|" + optionIndex}>
                          <div>
                            <div className="fixed-column-60">
                              <img src={value.pictureURL} alt="GC" className="profile-thumbnail-2" />
                            </div>
                            <div className="calc-column-offset-60 left-padding top-padding-5">
                              <p className="heading-text-4">{value.firstName} {value.lastName}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <p className="error-color">There are no upvotes</p>
                    </div>
                  )}

                 </div>
               )}

               {(this.state.showGoal) && (
                 <div key="showGoal" className="full-width">
                   <p className="heading-text-2">Adopt the {this.state.careerDetails.name}{(this.state.careerDetails.name && !this.state.careerDetails.name.includes("Pathway")) && " Pathway"}?</p>
                   <p className="description-text-1 description-text-color top-margin-20">Adopting the {this.state.careerDetails.name} pathway will transfer the associated videos, courses, projects, and other relevant roadmap components into your very own SMART goal.</p>

                   <div className="spacer" /><div className="spacer" />

                   <div className="top-padding-20">
                     <button className="btn btn-primary right-margin" onClick={() => this.props.navigate('/app/edit-goals/new', { state: { selectedCareer: this.state.careerDetails }})}>Yes</button>
                     <button className="btn btn-secondary" onClick={() => this.closeModal()}>Cancel</button>
                   </div>
                  </div>
               )}

             </Modal>
          </div>

      )
    }
}

export default withRouter(CareerDetails);
/*
1) Include two APIs. One where the searchTerm is a directLink, and search is skipped. Query should be on Id. The other should be a search based on the URL parameter.
2) Include the abilty to click on tags and sub-tags to search for skillsets, and browse other jobs.
3) Increase accuracy of dashboard by pulling more data and creating algorithms
*/
