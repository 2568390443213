import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import StudentDetails from '../Subcomponents/StudentDetails';
import withRouter from '../Functions/WithRouter';

class OrgMemberDetail extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount called in orgMemberDetail')

      const { org, queryEmail, username } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgLogo = localStorage.getItem('orgLogo');

      let member = null
      if (this.props.location && this.props.location.state) {
        member = this.props.location.state.member
      }

      this.setState({ org, queryEmail, username, member, orgLogo })

      if (this.props.location && this.props.location.state) {

        const { member } = this.props.location.state
        console.log('did we get member', member)
        // this.setState({ emailId: email, member, org });

        this.retrieveData(member, org, email)

      } else {

        if (queryEmail) {
          Axios.get('/api/users/profile/details/' + queryEmail)
          .then((response) => {
            if (response.data) {

              console.log('User details fetch worked', response.data)
              if (response.data.success) {

                let member = response.data.user
                this.retrieveData(member, org, email)

              } else {
                console.log('there was an error', response.data.message)

              }
            }
          }).catch((error) => {
              console.log('User details fetch did not work', error);
          });
        } else if (username) {
          Axios.get('/api/users/profile/details/temporary/' + username)
          .then((response) => {
            if (response.data) {

              console.log('User details fetch worked', response.data)
              if (response.data.success) {

                let member = response.data.user
                this.retrieveData(member, org, email)

              } else {
                console.log('there was an error', response.data.message)

              }
            }
          }).catch((error) => {
              console.log('User details fetch did not work', error);
          });
        }
      }
    }

    retrieveData(member, org, email) {
      console.log('retrieveData called')

      this.setState({ member, org, email })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called  in orgmemberdetail', this.props, prevProps, this.props.params.queryEmail)

      if (this.props.params.queryEmail !== prevProps.params.queryEmail) {
        console.log("oh there's a difference")
        Axios.get('/api/users/profile/details/' + this.props.params.queryEmail)
        .then((response) => {
          if (response.data) {

            console.log('User details fetch worked', response.data)
            if (response.data.success) {

              let member = response.data.user
              this.retrieveData(member, this.state.org, this.props.params.queryEmail)

            } else {
              console.log('there was an error', response.data.message)
            }
          }
        }).catch((error) => {
            console.log('User details fetch did not work', error);
        });
      } else if (this.props.params.username !== prevProps.params.username) {
        Axios.get('/api/users/profile/details/temporary/' + this.props.params.username)
        .then((response) => {
          if (response.data) {

            console.log('User details fetch worked', response.data)
            if (response.data.success) {

              let member = response.data.user
              this.retrieveData(member, this.state.org, response.data.user.email)

            } else {
              console.log('there was an error', response.data.message)

            }
          }
        }).catch((error) => {
            console.log('User details fetch did not work', error);
        });
      }
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate} />
          <div>
            <StudentDetails activeOrg={this.state.org} accountCode={this.state.accountCode} roleName="Admin" path={this.state.path} queryEmail={this.state.queryEmail} username={this.state.username} member={this.state.member} type="All" history={this.props.navigate} />
          </div>

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgMemberDetail)
