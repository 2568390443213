import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubOrgDetails from './Subcomponents/OrgDetails';
import withRouter from './Functions/WithRouter';

class EmpOrgDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
      let email = localStorage.getItem('email');
      const { emp, orgSelected } = this.props.params

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ emp, orgMode, activeOrg, emailId: email,
          cuFirstName, cuLastName, username, orgLogo, orgSelected })

      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    render() {

        return (
          <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg}  history={this.props.navigate}/>
            <SubOrgDetails history={this.props.navigate} orgId={this.state.orgSelected} accountCode={this.state.emp} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(EmpOrgDetails);
