import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubTrainingPrograms from './Subcomponents/TrainingPrograms';
import withRouter from './Functions/WithRouter';

class TrainingPrograms extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        const problemTypeOptions = ['','Exploratory','Technical']
        const difficultyLevelOptions = ['','Very Easy','Easy','Medium','Difficult','Challenger']
        const popularityOptions = ['','More than 10','More than 50']
        const postDateOptions = ['','Within the Last Month','Within the Last Year']

        this.setState({ emailId: email, username, activeOrg, orgFocus, orgLogo,
          problemTypeOptions, difficultyLevelOptions, popularityOptions, postDateOptions });
      }
    }

    render() {
      return(
        <div>
          <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate} />

          <SubSideNav selectedComponent={"TrainingPrograms"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />

          <div>
            <SubTrainingPrograms activeOrg={this.state.activeOrg} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(TrainingPrograms)
