import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import EditSubscription from '../Common/EditSubscription';
import withRouter from '../Functions/WithRouter';

const priceChart = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/price_chart.png';
// const stripeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stripe-icon.png';
// const creditCardIcons = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/credit-card-icons.png';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeTestPromise = loadStripe("pk_test_51I6htEBMA9yyOaS5GG6dCAJXzACvstyF0j5v6xIZJkHCRlkvvz7VlQzLqAT26bd8TBCyW2WuYyjWmu4fsdPYF6cT00zf4fma2z");
const stripeLivePromise = loadStripe("pk_live_51I6htEBMA9yyOaS5WfSIqrWCbixiXQ7Fm3iRpTd4grD1jfXAHVOUv1UYLuGY5Gi92TPBumqYGCGfbIr7c059mzwg00KxKIJB0l");

class PreCheckoutForm extends Component {
    constructor(props) {
      super(props)
      this.state = {
        testingStripe: false,
        signInPage: false,
        basePrice: 5,
        showOrgLogos: false,

        viewIndex: 0,
        roleName: 'Student / Career Seeker',

        selectedIndex: 0,
        orderItems: [],
        servicesOffered: [],
        availablePromos: [],
        availableOrgs: ['unite-la'],
        discount: 0,
        promoDiscount: 0,
        subtotal: 5,
        numberOfLicenses: 1,


        numberOfLicenseOptions: [],
        roleNameOptions: ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Counselor','Mentor','Employer Representative','Other'],
        serviceOptions: ['Career Exploration','Mentorship','Career Events','Projects','Work'],
        orgTypeOptions: [],
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.adjustDiscount = this.adjustDiscount.bind(this)
      this.checkEntry = this.checkEntry.bind(this)
      this.createCustomer = this.createCustomer.bind(this)
      this.officiallyCreateCustomer = this.officiallyCreateCustomer.bind(this)

      this.subscribe = this.subscribe.bind(this)
      this.passSubscriptionData = this.passSubscriptionData.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.renderPromoCode = this.renderPromoCode.bind(this)
      this.renderOrderSummary = this.renderOrderSummary.bind(this)
      this.createSubscription = this.createSubscription.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in PreCheckoutForm', this.props)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        console.log('t0')
        this.retrieveData()
      } else if (this.props.orgType !== prevProps.orgType) {
        console.log('t1')
        this.retrieveData()
      } else if (this.props.employerName !== prevProps.employerName) {
        console.log('t2')
        this.retrieveData()
      } else {
        // console.log('t2', this.props.orgType !== prevProps.orgType)
      }
    }

    retrieveData() {
      console.log('retrieveData called within switchOrgs', this.props.orgType)

      let priceId = ''
      const testingStripe = this.state.testingStripe
      if (testingStripe) {
        priceId = 'price_1I6ivTBMA9yyOaS5RJ1CztXY'
      } else {
        priceId = 'price_1I8wv4BMA9yyOaS5WGs5LmuT'
      }

      let numberOfLicenseOptions = ['','Just Me','2 People','3 People']
      let orderItems = [{ name: 'Monthly License', price: this.state.basePrice, qty: 1 }]

      const orgTypeOptions = ['','Workforce Development Organization','Educational Institution']

      let signInPage = false
      if (window.location.pathname.includes('/signin')) {
        signInPage = true
      }

      let isMobile = false
      if (window.innerWidth <= 768) {
        isMobile = true
      }

      const email = localStorage.getItem('email')
      let activeOrg = localStorage.getItem('activeOrg')
      let orgName = localStorage.getItem('orgName')
      let roleName = localStorage.getItem('roleName')
      // console.log('show orgName: ', orgName)
      if (this.props.roleName) {
        roleName = this.props.roleName
        if (roleName === 'Educator') {
          roleName = 'Teacher'
        } else if (roleName === 'Workforce') {
          roleName = 'Work-Based Learning Coordinator'
        }
        // console.log('in it to win it ', roleName)
      }

      let basePrice = this.state.basePrice
      let numberOfLicenses = 1
      if (roleName === 'Career-Seeker' || roleName === 'Student') {
        basePrice = 9.99
        priceId = "price_1MYJ6eBMA9yyOaS5u7fnErYF"
        orderItems = [{ name: 'Monthly License', price: basePrice, qty: numberOfLicenses }]
      } else if (roleName === 'Employer') {
        priceId = 'price_1JHyGUBMA9yyOaS5iDynUKgd'
        basePrice = 99
        orderItems = [{ name: 'Monthly License', price: basePrice, qty: numberOfLicenses }]
      } else if (roleName === 'Workforce' || roleName === 'Educator' || roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
        priceId = "price_1I8wv4BMA9yyOaS5WGs5LmuT"
        basePrice = 5
        numberOfLicenses = 10
        this.adjustDiscount(numberOfLicenses)
        orderItems = [{ name: 'Monthly License', price: basePrice, qty: numberOfLicenses }]
      }
      // console.log('numberOfLicenses plz', roleName, numberOfLicenses)
      let orgType = null
      if (this.props.orgType) {
        orgType = this.props.orgType
      }
      // console.log('show orgType: ', orgType)

      let orgURL = this.props.orgURL
      let orgRegion = this.props.orgRegion
      let orgDescription = this.props.orgDescription
      if (this.props.orgName) {
        orgName = this.props.orgName
      }

      let employerName = this.props.employerName
      let employerURL = this.props.employerURL
      let employerLocation = this.props.employerLocation
      let employerDescription = this.props.employerDescription
      // console.log('show emp values in precheckout: ', employerName, employerURL, employerLocation, employerDescription)

      this.setState({
        activeOrg,
        priceId, testingStripe, numberOfLicenses,
        numberOfLicenseOptions, orderItems, signInPage, orgTypeOptions,
        isMobile, roleName, basePrice, orgType, orgName, orgURL, orgRegion, orgDescription,
        employerName, employerURL, employerLocation, employerDescription
      })

      if (email && email !== '') {
        console.log('the check')
        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          console.log('User profile query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved user details')

             const firstName = response.data.user.firstName
             const lastName = response.data.user.lastName
             // const roleName  = response.data.user.roleName
             const orgCode = response.data.user.activeOrg
             const accountCode = response.data.user.accountCode
             const school = response.data.user.school
             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName

             this.setState({
               firstName, lastName, email, orgCode, accountCode, school, jobTitle, employerName, orgName
             })

             if (!this.state.testingStripe) {

               let subscriptionType = ''
               if (roleName === 'Student' || roleName === 'Career-Seeker') {
                 subscriptionType = 'Individual'
               } else if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator' || roleName === 'Admin'){
                 subscriptionType = 'Organization'
               } else {
                 subscriptionType = roleName
               }

               let isActive = null
               let allOrgAccounts = true

               const subParams = { email, orgCode, accountCode, subscriptionType, isActive, allOrgAccounts }
               console.log('subParams in PreCheckoutForm: ', subParams)

               Axios.get('/api/payments/subscription', { params: subParams })
               .then((response) => {
                 console.log('Subscription query attempted in precheckout 1 retrieveData', response.data);

                  if (response.data.success) {
                    console.log('successfully retrieved subscription')

                    const subscription = response.data.subscription
                    const customerId = subscription.customerId

                    this.setState({ errorMessage: null, subscription, customerId })

                  } else {
                    console.log('no active subscriptions data found', response.data.message)
                    // this.setState({ errorMessage: response.data.message })
                  }

               }).catch((error) => {
                  console.log('Subscription query did not work', error);

                  let viewIndex = 1
                  let selectedIndex = 0
                  // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                  //   selectedIndex = 0
                  // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                  //   selectedIndex = 0
                  // }
                  this.setState({ errorMessage: null, viewIndex, selectedIndex })
               });
             }

           } else {
             console.log('no profile details found', response.data.message)
           }

        }).catch((error) => {
           console.log('Org query did not work', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'numberOfLicenses') {
        this.setState({ [event.target.name]: event.target.value })
        this.adjustDiscount(event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    adjustDiscount(numberOfLicenses) {
      console.log('adjustDiscount called', numberOfLicenses)

      if (numberOfLicenses < 10) {
        this.setState({ discount: 0 })
      } else if (numberOfLicenses < 50) {
        this.setState({ discount: 0.60 })
      } else if (numberOfLicenses < 100) {
        this.setState({ discount: 0.65 })
      } else if (numberOfLicenses < 500) {
        this.setState({ discount: 0.70 })
      } else if (numberOfLicenses < 1000) {
        this.setState({ discount: 0.75 })
      } else if (numberOfLicenses < 5000) {
        this.setState({ discount: 0.80 })
      } else if (numberOfLicenses < 10000) {
        this.setState({ discount: 0.85 })
      } else if (numberOfLicenses < 100000) {
        this.setState({ discount: 0.90 })
      } else if (numberOfLicenses >= 100000) {
        this.setState({ discount: 0.95 })
      }
    }

    checkEntry(type) {
      console.log('checkEntry called', type)

      if (type === 'promoCode') {
        if (this.state.availablePromos.includes(this.state.promoCode)) {
          const promoSuccessMessage = this.state.promoCode + ' successfully redeemed!'
          const promoErrorMessage = null
          this.setState({ promoDiscount: 1, promoSuccessMessage, promoErrorMessage })
        } else {
          const promoSuccessMessage = null
          const promoErrorMessage = 'Promo code not found'
          this.setState({ promoSuccessMessage, promoErrorMessage })
        }
      } else if (type === 'orgCode') {
        console.log('trying: ', this.state.availableOrgs, this.state.orgCode)

        this.setState({ orgExists: false, orgSubscriptionExists: false })

        const orgCode = this.state.orgCode
        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved org')

             const orgName = response.data.orgInfo.orgName
             const orgDescription = response.data.orgInfo.orgDescription
             const orgCodeSuccessMessage = this.state.orgCode + ' successfully found!'
             const orgCodeErrorMessage = null

             this.setState({ orgName, orgDescription, orgCodeSuccessMessage, orgCodeErrorMessage, orgExists: true })

             const subscriptionType = 'Organization'

             // see if they have a subscription
             // query for customerId
             Axios.get('/api/payments/subscription', { params: { orgCode, subscriptionType, isActive: true } })
             .then((response) => {
               console.log('Subscription query attempted under checkEntry', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved subscription')

                  // const subscription = response.data.subscription
                  this.setState({ orgSubscriptionExists: true })

                } else {
                  console.log('no subscription found', response.data.message)

                }

             }).catch((error) => {
                console.log('Subscription query did not work', error);

             });

           } else {
             console.log('no org data found', response.data.message)

             const orgCodeSuccessMessage = null
             const orgCodeErrorMessage = 'Org code not found'
             this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
           }

        }).catch((error) => {
           console.log('Org query did not work', error);

           const orgCodeSuccessMessage = null
           const orgCodeErrorMessage = 'Org code not found'
           this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        });
        // if (this.state.availableOrgs.includes(this.state.orgCode)) {
        //   console.log('found it')
        //   const orgCodeSuccessMessage = this.state.orgCode + ' successfully found!'
        //   const orgCodeErrorMessage = null
        //   this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        // } else {
        //   const orgCodeSuccessMessage = null
        //   const orgCodeErrorMessage = 'Org code not found'
        //   this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        // }
      }
    }

    createCustomer() {
      console.log('createCustomer called', this.state.roleName)

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      let subscriptionType = 'Individual'
      if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student' && this.state.roleName !== 'Career-Seeker') {

        if (this.state.roleName === 'Mentor' || this.state.roleName === 'Employer') {
          subscriptionType = 'Employer'

          if (!this.state.employerName || this.state.employerName === '') {
            this.setState({ errorMessage: 'Please add the name of the employing organization', isSaving: false})
          // } else if (!this.state.employerLocation || this.state.employerLocation === '') {
          //   this.setState({ errorMessage: 'Please add the region of the organization', isSaving: false})
          } else if (!this.state.employerURL || this.state.employerURL === '') {
            this.setState({ errorMessage: 'Please add the main website of the organization', isSaving: false})
          } else if (!this.state.employerDescription || this.state.employer === '') {
            this.setState({ errorMessage: 'Please add a description of the organization', isSaving: false})
          } else {
            const accountCode = this.state.employerName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()

            const contactFirstName = this.state.firstName
            const contactLastName = this.state.lastName
            const contactEmail = this.state.email
            const contactTitle = this.state.jobTitle
            const employerName = this.state.employerName

            const employerLocation = this.state.employerLocation
            const employerURL = this.state.employerURL

            const employerDescription = this.state.employerDescription
            const quantity = this.state.numberOfLicenses
            const webLogoURI = this.state.employerLogoWhite
            const webLogoURIColor = this.state.employerLogoColor

            const createdAt = new Date()
            const updatedAt = new Date()

            // create account
            Axios.post('/api/account/create', {
              contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
              employerLocation, employerURL, description: employerDescription, quantity,
              webLogoURI, webLogoURIColor,
              createdAt, updatedAt
            }).then((response) => {

              if (response.data.success) {
                //save values
                console.log('Employer created! ', response.data);

                const email = this.state.email
                const makeAdmin = true

                // associate the user with this account
                Axios.post('/api/users/attach-to-account', {
                  email, accountCode, makeAdmin, createdAt, updatedAt
                }).then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Succesfully attached to user account! ', response.data);

                    //save to localStorage
                    localStorage.setItem('emp', accountCode)
                    localStorage.setItem('employerName', employerName)

                    const customerObject = {
                      contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
                      employerLocation, employerURL, employerDescription, quantity,
                      webLogoURI, webLogoURIColor,
                      createdAt, updatedAt
                    }

                    this.officiallyCreateCustomer(customerObject)

                  } else {
                    console.log('Could not attach to account')
                    this.setState({ errorMessage: 'Could not attch to account', isSaving: false })

                  }

                }).catch((error) => {
                    console.log('Attaching user to org did not work', error);
                    this.setState({ errorMessage: error, isSaving: false})
                });

              } else {
                console.log('Employer not already created')
                // this.setState({ errorMessage: 'The name of this organization is already taken. If this organization is already a Guided Compass partner, communicate with staff to use the existing org code.'})
                const customerObject = {
                  contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
                  employerLocation, employerURL, employerDescription, quantity,
                  webLogoURI, webLogoURIColor,
                  createdAt, updatedAt
                }
                this.officiallyCreateCustomer(customerObject)

              }

            }).catch((error) => {
                console.log('Customer creation did not work', error);

            });
          }
        } else {
          subscriptionType = 'Organization'

          if (!this.state.orgType || this.state.orgType === '') {
            this.setState({ errorMessage: 'Please add a type of the organization', isSaving: false })
          } else if (!this.state.orgName || this.state.orgName === '') {
            this.setState({ errorMessage: 'Please add the name of the organization', isSaving: false})
          } else if (!this.state.orgRegion || this.state.orgRegion === '') {
            this.setState({ errorMessage: 'Please add the region of the organization', isSaving: false})
          } else if (!this.state.orgURL || this.state.orgURL === '') {
            this.setState({ errorMessage: 'Please add the main website of the organization', isSaving: false})
          } else if (!this.state.orgDescription || this.state.orgDescription === '') {
            this.setState({ errorMessage: 'Please add a description of the organization', isSaving: false})
          } else if (!this.state.numberOfLicenses || this.state.numberOfLicenses === 0) {
            this.setState({ errorMessage: 'Please add the number of licenses you would like to purchase for the organization', isSaving: false})
          } else {
            const orgCode = this.state.orgName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()

            const contactFirstName = this.state.firstName
            const contactLastName = this.state.lastName
            const contactEmail = this.state.email
            const contactTitle = this.state.jobTitle
            const accountCode = this.state.accountCode
            const employerName = this.state.employerName


            const orgName = this.state.orgName
            const orgType = this.state.orgType
            let orgFocus = 'Placement'
            if (orgType === 'educationalIntitution' || orgType === 'Educational Intitution') {
              orgFocus = 'School'
            }
            const orgRegion = this.state.orgRegion
            const orgURL = this.state.orgURL
            const servicesOffered = this.state.servicesOffered
            const orgDescription = this.state.orgDescription
            const quantity = this.state.numberOfLicenses
            const webLogoURI = this.state.orgLogoWhite
            const webLogoURIColor = this.state.orgLogoColor

            const createdAt = new Date()
            const updatedAt = new Date()

            // create org
            Axios.post('/api/org/create', {
              contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
              orgCode, orgName, orgType, orgRegion, orgURL, servicesOffered, orgDescription, quantity, orgFocus,
              webLogoURI, webLogoURIColor,
              createdAt, updatedAt
            }).then((response) => {

              if (response.data.success) {
                //save values
                console.log('Org created! ', response.data);

                const email = this.state.email
                const makeAdmin = true

                // associate the user with this org
                Axios.post('/api/users/attach-to-org', {
                  email, orgCode, makeAdmin, createdAt, updatedAt
                }).then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Succesfully attached to user account! ', response.data);

                    //save to localStorage
                    localStorage.setItem('activeOrg', orgCode)
                    localStorage.setItem('orgName', orgName)

                    const customerObject = {
                      contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
                      orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered, orgDescription, quantity,
                      webLogoURI, webLogoURIColor,
                      createdAt, updatedAt
                    }

                    this.officiallyCreateCustomer(customerObject)

                  } else {
                    console.log('Could not attach to org')
                    this.setState({ errorMessage: 'Could not attch to org', isSaving: false })

                  }

                }).catch((error) => {
                    console.log('Attaching user to org did not work', error);
                    this.setState({ errorMessage: error, isSaving: false})
                });

              } else {
                console.log('Org not created')
                // this.setState({ errorMessage: 'The name of this organization is already taken. If this organization is already a Guided Compass partner, communicate with staff to use the existing org code. --- '})
                const customerObject = {
                  contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
                  orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered, orgDescription, quantity,
                  webLogoURI, webLogoURIColor,
                  createdAt, updatedAt
                }
                this.officiallyCreateCustomer(customerObject)

              }

            }).catch((error) => {
                console.log('Customer creation did not work', error);

            });
          }
        }

      } else {
        // subscriptionType is individual

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        const orgCode = this.state.orgCode
        const orgName = this.state.orgName
        const accountCode = this.state.accountCode
        const employerName = this.state.employerName

        const createdAt = new Date()
        const updatedAt = new Date()

        const customerObject = {
          firstName, lastName, email, subscriptionType, orgCode, orgName, accountCode, employerName,
          createdAt, updatedAt
        }

        this.officiallyCreateCustomer(customerObject)

      }
    }

    officiallyCreateCustomer(customerObject) {
      console.log('officiallyCreateCustomer called')

      // create a Stripe account
      Axios.post('/api/payments/create-customer', customerObject).then((response) => {
        if (response.data.success) {
          //save values
          console.log('Customer created! ', response.data);

          const customerId = response.data.customerId
          const subscriptionType = customerObject.subscriptionType

          this.setState({ customerId, subscriptionType, isSaving: false, successMessage: 'Great! Please add your payment details!' })


        } else {
          console.log('Customer not created')
          this.setState({ errorMessage: 'This Stripe customer account has already been created. Consider switching accounts to set up a subscription. If you need assistance, email help@guidedcompass.com', isSaving: false})
        }

      }).catch((error) => {
          console.log('Org creation did not work', error);
          this.setState({ errorMessage: error, isSaving: false})
      });
    }

    subscribe(type) {
      console.log('subscribe called', type)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (type === 'Individual') {
        console.log('in individual')

        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        let orgCode = 'guidedcompass'
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        const jobTitle = this.state.jobTitle
        let subscriptionType = type
        const roleName = this.state.roleName

        if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') {
          subscriptionType = 'Organization'
          orgCode = this.state.orgCode
        }
        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode
        console.log('about to post')

        const subObject = {
          email, firstName, lastName, roleName, orgCode, orgName, accountCode, employerName, jobTitle,
          subscriptionType, price, quantity, promoCode
        }

        this.createSubscription(subObject)

      } else if (type === 'Organization') {

        const promoCode = this.state.promoCode

        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName

        const contactEmail = this.state.email
        const contactFirstName = this.state.firstName
        const contactLastName = this.state.lastName
        const contactTitle = this.state.jobTitle
        const orgCode = this.state.orgName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        const orgName = this.state.orgName
        const orgType = this.state.orgType
        const orgRegion = this.state.orgRegion
        const orgURL = this.state.orgURL
        const orgFocus = this.state.orgFocus
        const servicesOffered = this.state.servicesOffered
        const orgDescription = this.state.orgDescription
        const webLogoURI = this.state.orgLogoWhite
        const webLogoURIColor = this.state.orgLogoColor

        const employerName = this.state.employerName
        const roleName = this.state.roleName

        let subscriptionType = type
        const price = 0
        const quantity = this.state.numberOfLicenses

        const createdAt = new Date()
        const updatedAt = new Date()

        // create org
        Axios.post('/api/org/create', {
          firstName, lastName, email, roleName,
          contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, employerName,
          orgCode, orgName, orgType, orgRegion, orgURL, servicesOffered, orgDescription, quantity, orgFocus,
          webLogoURI, webLogoURIColor,
          createdAt, updatedAt
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Org created! ', response.data);

            const email = this.state.email
            const makeAdmin = true

            // associate the user with this org
            Axios.post('/api/users/attach-to-org', {
              email, orgCode, makeAdmin, createdAt, updatedAt
            }).then((response) => {

              if (response.data.success) {
                //save values
                console.log('Succesfully attached to user account! ', response.data);

                //save to localStorage
                localStorage.setItem('activeOrg', orgCode)
                localStorage.setItem('orgName', orgName)

                const subObject = {
                  contactEmail, contactFirstName, contactLastName, contactTitle,
                  orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered,
                  roleName, employerName,
                  subscriptionType, price, quantity, promoCode
                }

                this.createSubscription(subObject)

              } else {
                console.log('Could not attach to org')
                this.setState({ errorMessage: 'Could not attch to org', isSaving: false })

              }

            }).catch((error) => {
                console.log('Attaching user to org did not work', error);
                this.setState({ errorMessage: error, isSaving: false })
            });

          } else {
            console.log('Org not created')
            // this.setState({ errorMessage: 'The name of this organization is already taken. If this organization is already a Guided Compass partner, communicate with staff to use the existing org code.', isSaving: false })

            const subObject = {
              contactEmail, contactFirstName, contactLastName, contactTitle,
              orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered,
              roleName, employerName,
              subscriptionType, price, quantity, promoCode
            }

            this.createSubscription(subObject)

          }

        }).catch((error) => {
            console.log('Customer creation did not work', error);
            this.setState({ errorMessage: error, isSaving: false })

        });
      } else if (type === 'toOrg') {

        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const roleName = this.state.roleName
        let orgCode = this.state.orgCode
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        let subscriptionType = 'toOrg'
        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode

        // only subscribe if org has a subscription
        if (!orgCode || orgCode === '' || orgCode === 'guidedcompass') {
          this.setState({ errorMessage: 'please add an org code', isSaving: false })
        // } else if (orgCode === 'guidedcompass') {
        //   this.setState({ errorMessage: 'this organization does not have a subscription' })
        } else {

          const subObject = {
            email, firstName, lastName, roleName, orgCode, orgName, accountCode, employerName,
            subscriptionType, price, quantity, promoCode
          }

          this.createSubscription(subObject,true)

        }

      } else if (type === 'acrossOrgs') {
        // mentors, employers, and other roles
        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        let orgCode = 'guidedcompass'
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        const jobTitle = this.state.jobTitle
        const roleName = this.state.roleName
        let subscriptionType = roleName

        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode

        const subObject = {
          email, firstName, lastName, orgCode, orgName, accountCode, employerName, jobTitle, roleName,
          subscriptionType, price, quantity, promoCode
        }

        this.createSubscription(subObject)

      }
    }

    createSubscription(subObject,attachToOrg) {
      console.log('createSubscription called')

      //free subscription
      Axios.post('/api/payments/create-subscription', subObject).then((response) => {
        if (response.data.success) {
          //save values
          console.log('Free subscription created! ', response.data);

          if (attachToOrg) {
            const email = subObject.email
            const orgCode = subObject.orgCode
            const orgName = subObject.orgName
            const createdAt = new Date()
            const updatedAt = new Date()

            // attach to user account and save to localStorage
            // associate the user with this org
            Axios.post('/api/users/attach-to-org', {
              email, orgCode, createdAt, updatedAt
            }).then((response) => {

              if (response.data.success) {
                //save values
                console.log('Succesfully attached to user account! ', response.data);

                //save to localStorage
                localStorage.setItem('activeOrg', orgCode)
                localStorage.setItem('orgName', orgName)

                if (window.location.pathname.includes('/problem-platform')) {
                  let pathname = '/problem-platform/profile'
                  if (this.state.sourceLink) {
                    pathname = this.state.sourceLink
                  }
                  this.props.navigate(pathname, { state: { newUser: true }})
                } else {
                  this.props.passSubscriptionData(response.data.subscription)
                }

              } else {
                console.log('Could not attach to org')
                this.setState({ errorMessage: 'Could not attch to org', isSaving: false})

              }

            }).catch((error) => {
                console.log('Attaching user to org did not work', error);
                this.setState({ errorMessage: error, isSaving: false})
            });

          } else {
            if (window.location.pathname.includes('/problem-platform')) {
              let pathname = '/problem-platform/profile'
              if (this.state.sourceLink) {
                pathname = this.state.sourceLink
              }
              this.props.navigate(pathname, { state: { newUser: true }})
            } else {
              this.props.passSubscriptionData(response.data.subscription)
            }
          }

        } else {
          console.log('There was an error with creating an individual subscription')
          this.setState({ errorMessage: 'There was an error creating an individual subscription', isSaving: false })

        }

      }).catch((error) => {
          console.log('Customer creation did not work', error);
          this.setState({ errorMessage: error, isSaving: false })
      });
    }

    passSubscriptionData(subscription) {
      console.log('passSubscriptionData called in precheckoutform', subscription)

      this.props.passSubscriptionData(subscription)

    }

    closeModal() {
      console.log('closeModal called: ')

      this.setState({ modalIsOpen: false, showPricingChart: false });

    }

    renderOrderSummary(inContainer) {
      console.log('renderOrderSummary called')

      return (
        <div key="renderOrderSummary">
          <p className="bold-text">Order Summary</p>

          <div className="top-margin description-text-3">
            <div className={(inContainer) ? "full-width" : "container-left"}>
              <div className="bold-text row-5">
                <div className="calc-column-offset-120">
                  <p>Service</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>Price</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>Qty</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>Total</p>
                </div>
                <div className="clear"/>
              </div>

              <div>
                <div className="calc-column-offset-120">
                  <p>{(this.state.roleName === 'Student / Career Seeker' || this.state.roleName === 'Student') ? "Monthly License" : "Member Licenses"}</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>${this.state.basePrice}</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>{this.state.numberOfLicenses}</p>
                </div>
                <div className="fixed-column-40 right-text">
                  <p>${this.state.basePrice * this.state.numberOfLicenses}</p>
                </div>
                <div className="clear" />
              </div>

              <div>
                {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'Work-Based Learning Coordinator' || this.state.roleName === 'Admin') && (
                  <div className="row-5">
                    <div className="calc-column-offset-120">
                      <p>Volume Discounts</p>
                    </div>
                    <div className="fixed-column-40 right-text">
                      <p>{this.state.discount * 100}%</p>
                    </div>

                    <div className="fixed-column-40 height-20" />

                    <div className="fixed-column-40 right-text">
                      <p className="error-color">(${(this.state.discount * this.state.numberOfLicenses * this.state.basePrice).toFixed(2)})</p>
                    </div>
                    <div className="clear"/>
                  </div>
                )}

                <div className="row-5">
                  <div className="calc-column-offset-120">
                    <p>Promo</p>
                  </div>
                  <div className="fixed-column-40 right-text">
                    <p>{this.state.promoDiscount * 100}%</p>
                  </div>

                  <div className="fixed-column-40 height-20" />

                  <div className="fixed-column-40 right-text">
                    <p className="error-color">(${(this.state.promoDiscount * this.state.numberOfLicenses * this.state.basePrice).toFixed(2)})</p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="spacer"/>

                <hr />
                <div className="half-spacer" />

                <div>
                  <div className="calc-column-offset-120">
                    <p className="bold-text">Total</p>
                  </div>
                  <div className="fixed-column-40 height-30" />

                  <div className="fixed-column-40 height-30" />

                  <div className="fixed-column-40 bold-text cta-color right-text">
                    <p>${((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promoDiscount * this.state.numberOfLicenses * this.state.basePrice) < 0 ? 0 : ((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promoDiscount * this.state.numberOfLicenses * this.state.basePrice)).toFixed(2))}<label className="standard-color">/mo</label></p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="clear"/>
              </div>
            </div>
            <div className="container-right">
            </div>
            <div className="clear" />
          </div>
        </div>
      )
    }

    renderPromoCode() {
      console.log('renderPromoCode called')

      return (
        <div key="renderPromoCode">
          <div className="row-10 description-text-3">
            <div className="container-left">
              <div className="calc-column-offset-80">
                <input className="text-field description-text-2" type="text" placeholder="Enter promo code..." name="promoCode" value={this.state.promoCode} onChange={this.formChangeHandler} />
              </div>
              <div className="fixed-column-80 flex-container align-end">
                <button className="btn btn-small white-background cta-color row-3 pin-right" onClick={() => this.checkEntry('promoCode')}>Redeem</button>
              </div>
              <div className="clear" />
              {/*<p className="bold-text bottom-padding">Promo Code</p>*/}

              {(this.state.promoSuccessMessage && this.state.promoSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.promoSuccessMessage}</p>}
              {(this.state.promoErrorMessage && this.state.promoErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.promoErrorMessage}</p>}

            </div>
            <div className="clear" />
          </div>
        </div>
      )
    }

    render() {

      return (
        <div>

          {(this.props.pageSource !== 'landingPage') && (
            <div>
              <p className="heading-text-2">Payment Info</p>

            </div>
          )}

          <div className="spacer" /><div className="spacer" />

          {(this.state.subscription && this.state.subscription.isActive) ? (
            <div>
              {(this.state.subscription.contactEmail !== this.state.email && this.state.subscription.email !== this.state.email) && (
                <p className="error-color row-5 full-width center-text">A subscription exists with this organization, but you are not listed as the primary contact. Please reach out to the admin of the account.</p>
              )}
              <EditSubscription pageSource="pricingPage" />
            </div>
          ) : (
            <div>

              <div className="row-10 bottom-margin">
                <hr />
              </div>

              {(this.props.pageSource === 'landingPage' && (this.state.roleName !== 'Student' && this.state.roleName !== 'Career-Seeker' && this.state.roleName !== 'Employer')) && (
                <div className="bottom-padding-20">
                  <div className="container-left">
                    <p className="bold-text bottom-padding">Number of Member Licenses</p>
                    <input className="number-field" min="10" type="number" placeholder="0" name="numberOfLicenses" value={this.state.numberOfLicenses} onChange={this.formChangeHandler} />
                    <div className="half-spacer" />
                    <p className="description-text-3">View the price chart <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showPricingChart: true })}>here</button></p>
                  </div>
                  <div className="container-right">

                  </div>
                  <div className="clear" />


                </div>
              )}

              <div className="top-margin">
                {this.renderOrderSummary(false)}
              </div>

              {this.renderPromoCode()}
              {/*
              <div className="row-10">
                <div className="float-left right-margin">
                  <img src={stripeIcon} alt="GC" className="image-auto-30" />
                </div>
                <div className="float-left">
                  <img src={creditCardIcons} alt="GC" className="image-auto-30" />
                </div>
                <div className="clear" />

                <p className="description-text-3 top-margin">Payments are facilitated safely and securely by <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a></p>
              </div>*/}

              <div className="row-20">
                <hr />
              </div>

              {((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promoDiscount * this.state.numberOfLicenses * this.state.basePrice) > 0) ? (
                <div>
                  <div>
                    {(this.state.customerId) ? (
                      <div>
                        <div>
                          <Elements stripe={(this.state.testingStripe) ? stripeTestPromise : stripeLivePromise}>
                            <CheckoutForm history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                              lastName={this.state.lastName} orgName={this.state.orgName} employerName={this.state.employerName}
                              subscriptionType={this.state.subscriptionType} customerId={this.state.customerId} price={this.state.basePrice}
                              quantity={this.state.numberOfLicenses} sourceLink={this.state.sourceLink} priceId={this.state.priceId}
                              discount={this.state.discount} promoCode={this.state.promoCode} promoDiscount={this.state.promoDiscount}
                              passSubscriptionData={this.passSubscriptionData}
                              />
                          </Elements>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(this.state.email) ? (
                          <div className="row-10">
                            <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.createCustomer()}>Register for Stripe & Enter Payment Details</button>

                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          </div>
                        ) : (
                          <div className="row-10">
                            <button className="btn btn-squarish medium-background standard-border" disabled={true} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                {/*
                  {(this.state.testingStripe) ? (
                    <div>

                      {(this.state.customerId) ? (
                        <div className="row-10">
                          <Elements stripe={stripeTestPromise}>
                            <CheckoutForm history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                              lastName={this.state.lastName} orgName={this.state.orgName} employerName={this.state.employerName}
                              subscriptionType={this.state.subscriptionType} customerId={this.state.customerId} price={this.state.basePrice}
                              quantity={this.state.numberOfLicenses}
                              />
                          </Elements>
                        </div>
                      ) : (
                        <div className="row-10">
                          <button className="btn btn-squarish" onClick={() => this.createCustomer()}>Sign Up & Enter Payment Details</button>

                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                        </div>
                      )}

                    </div>
                  ) : (
                    <div>
                      <div className="row-10">
                        <label className="profile-label">Name on Card<label className="error-color">*</label></label>
                        <input className="text-field" type="text" placeholder="Name on Card" name="nameOnCard" value={this.state.nameOnCard} onChange={this.formChangeHandler} />
                      </div>
                      <div className="row-10">
                        <label className="profile-label">Card Number<label className="error-color">*</label></label>
                        <input className="text-field" type="text" placeholder="Card Number" name="cardNumber" value={this.state.cardNumber} onChange={this.formChangeHandler} />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Expiration Date<label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="Expiration Date" name="expirationDate" value={this.state.expirationDate} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Security Code<label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="CVV" name="securityCode" value={this.state.securityCode} onChange={this.formChangeHandler} />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Zip Code<label className="error-color">*</label></label>
                          <input className="text-field number-field" type="number" placeholder="zip code..." name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler} />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <button className="btn btn-primary" onClick={() => this.setState({ preApproved: true})}>Subscribe & Participate in Challenge</button>
                      </div>
                    </div>
                  )}*/}

                </div>
              ) : (
                <div>
                  <div className="row-10">
                    {(this.state.roleName === 'Employer')} && (
                      <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('Employer')}>Subscribe & Participate in Challenge</button>
                    )}
                    {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor' || this.state.roleName === 'Work-Based Learning Coordinator' || this.state.roleName === 'Admin') && (
                      <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('Organization')}>Subscribe & Participate in Challenge</button>
                    )}
                    {(this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker')} && (
                      <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('Individual')}>Subscribe & Participate in Challenge</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
         >

            {(this.state.showPricingChart) && (
              <div key="priceChart" className="full-width padding-20">
                <p className="heading-text-2">Pricing Chart</p>
                <div className="spacer" />

                <div className="row-10">
                  <img src={priceChart} alt="GC" className="image-full-auto"/>
                </div>
              </div>
            )}

            <div className="row-20 center-text">
             <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
            </div>
         </Modal>
        </div>
      )
    }
}

export default withRouter(PreCheckoutForm);
