import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import {saveCourseChanges} from '../Services/CourseRoutes';
import withRouter from '../Functions/WithRouter';

const textIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/text-icon.png";
const videoIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon-dark.png";
const linkIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-dark.png";
const assignmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png";
const assessmentsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-dark.png";
const coverLetterIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png";
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const deleteIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png";
const opportunitiesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png";

class EditCourseLesson extends Component {
    constructor(props) {
      super(props)

      this.state = {
        resourceOptions: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.editItem = this.editItem.bind(this)
      this.addItem = this.addItem.bind(this)
      this.deleteItem = this.deleteItem.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.saveLearningModules = this.saveLearningModules.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in courseDetails', this.props, prevProps)

      if (this.props.learningModules && !prevProps.learningModules) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in editCourseLesson:  ', this.props)

      let orgCode = this.props.org
      const _id = this.props.courseId
      let learningModules = this.props.learningModules
      const selectedIndex = this.props.selectedIndex
      const selectedIndex2 = this.props.selectedIndex2

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgName = localStorage.getItem('orgName');

      let prefix = '/advisor'
      if (window.location.pathname.startsWith('/organizations')) {
        prefix = '/organizations/' + orgCode
      }

      const contentTypeOptions = [
        { icon: textIcon, name: 'Text' },
        // { icon: addIconBlue, name: 'Image' },
        { icon: videoIconDark, name: 'Video' },
        // { icon: linkIconDark, name: 'Link' },
        { icon: assignmentsIconDark, name: 'Assignment' },
        { icon: assessmentsIconDark, name: 'Survey' },
        { icon: assessmentsIconDark, name: 'Quiz' },
        { icon: coverLetterIconDark, name: 'A.I. Script' },
        { icon: opportunitiesIconDark, name: 'Training' },
      ]

      this.setState({
        orgCode, orgName,
        emailId, cuFirstName, cuLastName, prefix,
        _id, learningModules, selectedIndex, selectedIndex2, contentTypeOptions
      })
    }

    formChangeHandler = event => {
      console.log('formChangeHandler called ')

      if (event.target.name === 'contentValue') {

        let contentId = null
        if (this.state.useExistingResource) {
          console.log()
          for (let i = 1; i <= this.state.resourceOptions.length; i++) {
            if (this.state.resourceOptions[i - 1].title === event.target.value) {
              contentId = this.state.resourceOptions[i - 1]._id
            }
          }
        }

        this.setState({ [event.target.name]: event.target.value, contentId })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, selectedOption: false, existingItem: null, selectedIndex3: null })
    }

    addItem() {
      console.log('addItem called', this.state.learningModules)

      this.setState({ errorMessage: null })

      if (!this.state.contentValue) {
        this.setState({ errorMessage: "Please add some content" })
      } else if (this.state.learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content && this.state.learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content[0]) {
        this.setState({ errorMessage: "Learning modules are currently limited to one piece of content per lesson" })
      } else {
        let learningModules = this.state.learningModules
        let content = learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content

        let category = this.state.selectedOption.name
        let title = this.state.contentTitle
        let value = this.state.contentValue
        let contentId = this.state.contentId
        let htmlMode = this.state.htmlMode

        if (content) {
          content.push({ category, title, value, contentId, htmlMode })
        } else {
          content = [{ category, title, value, contentId, htmlMode }]
        }

        learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'] = content

        this.setState({ learningModules})
        this.closeModal()
        this.saveLearningModules(learningModules)
      }
    }

    editItem() {
      console.log('editItem called', this.state.selectedIndex3, this.state.learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'])

      let learningModules = this.state.learningModules
      learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'][this.state.selectedIndex3]['title'] = this.state.contentTitle
      learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'][this.state.selectedIndex3]['value'] = this.state.contentValue
      learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'][this.state.selectedIndex3]['objectId'] = this.state.contentId
      learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2]['content'][this.state.selectedIndex3]['htmlMode'] = this.state.htmlMode

      this.setState({ learningModules})
      this.closeModal()
      this.saveLearningModules(learningModules)
    }

    deleteItem(e,index) {
      console.log('deleteItem called')

      e.stopPropagation()

      let learningModules = this.state.learningModules
      learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content.splice(index,1)
      this.setState({ learningModules})
      this.saveLearningModules(learningModules)

    }

    itemClicked(index, existingItem, value) {
      console.log('itemClicked called', index, existingItem, value)

      let selectedOption = value
      let contentTitle = null
      let contentValue = null
      let useExistingResource = false
      let htmlMode = false
      if (existingItem) {
        contentTitle = value.title
        contentValue = value.value
        htmlMode = value.htmlMode
        selectedOption = { name: this.state.learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content[index].category }
        value = selectedOption

        if (this.state.learningModules[this.state.selectedIndex]['lessons'][this.state.selectedIndex2].content[index].contentId) {
          useExistingResource = true
        }
      }

      if (value.name === 'A.I. Script' || value.name === 'Quiz' || value.name === 'Survey') {
        useExistingResource = true

        const orgCodes = [this.state.orgCode,'general']
        let customAssParams = { orgCodes }
        if (value.name === 'A.I. Script') {
          customAssParams['assessmentType'] = 'Script'
        } else if (value.name === 'Quiz' || value.name === 'Survey') {
          customAssParams['assessmentType'] = value.name
        }

        Axios.get('/api/customassessments', { params: customAssParams })
        .then((response) => {
          console.log('Custom assessment query attempted', response.data);

          if (response.data.success && response.data.assessments.length > 0) {
            console.log('custom assessment query worked')

            let resourceOptions = response.data.assessments

            this.setState({ modalIsOpen: true, selectedOption, existingItem, selectedIndex3: index, resourceOptions, contentValue, useExistingResource })

          } else {
            console.log('custom assessment query did not work', response.data.message)

            this.setState({ modalIsOpen: true, selectedOption, existingItem, selectedIndex3: index, resourceOptions: [], contentValue, useExistingResource })
          }

        }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
        });
      } else {

        if (value.name === 'Assignment') {
          useExistingResource = true
        }

        if (value.name === 'Training') {
          contentValue = value.name
        }

        const orgCodes = [this.state.orgCode,'general']
        let customResParams = { orgCodes }
        if (value.name === 'Text') {
          customResParams['resourceType'] = 'Page'
        } else if (value.name === 'Video' || value.name === 'Assignment') {
          customResParams['resourceType'] = value.name
        }

        Axios.get('/api/resources', { params: customResParams })
        .then((response) => {
          console.log('Resources query attempted', response.data);

          if (response.data.success && response.data.resources.length > 0) {
            console.log('Reesource query worked')

            let resourceOptions = response.data.resources

            this.setState({ modalIsOpen: true, selectedOption, existingItem, selectedIndex3: index, resourceOptions, contentTitle, contentValue, useExistingResource, htmlMode })

          } else {
            console.log('resource query did not work', response.data.message)

            this.setState({ modalIsOpen: true, selectedOption, existingItem, selectedIndex3: index, resourceOptions: [], contentTitle, contentValue, useExistingResource, htmlMode })

          }
        }).catch((error) => {
            console.log('Resources query did not work for some reason', error);
        });
      }
    }

    async saveLearningModules(learningModules) {
      console.log('saveLearningModules called')

      if (!learningModules) {
        learningModules = this.state.learningModules
      }

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const moduleObject = { _id: this.state._id, learningModules, updatedAt: new Date() }
      const returnedValue = await saveCourseChanges(moduleObject)

      if (returnedValue && returnedValue.success) {
        console.log('save was successful: ', returnedValue)
        this.setState({ isSaving: false, successMessage: returnedValue.message })
      } else if (returnedValue && returnedValue.message) {
        console.log('sign up error: ', returnedValue)
        this.setState({ isSaving: false, errorMessage: returnedValue.message })
      } else {
        this.setState({ isSaving: false, errorMessage: returnedValue.error.message })
      }
    }

    render() {

      return (
          <div>
            {(this.state.learningModules) && (
              <div>
                <div className={(window.innerWidth > 768 && !this.props.fromModal) && "relative-column-60 padding-five-percent-left"}>
                  <div className={(!this.props.fromModal) && "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"}>
                    {(window.innerWidth > 768 && !this.props.fromModal) && (
                      <div className="full-width height-5 primary-background" />
                    )}

                    <div className={(window.innerWidth > 768 && !this.props.fromModal) ? "padding-40" : ""}>
                      <div className="bottom-margin">
                       <div className="calc-column-offset-40">
                         <p className="heading-text-2">{this.state.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].name}</p>
                         <p className="top-margin">Course: {this.state.learningModules[this.state.selectedIndex].name}</p>
                         </div>
                        <div className="clear" />
                      </div>

                      <div className="full-width top-margin-20">
                        {(this.state.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].content && this.state.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].content.length > 0) ? (
                          <div className="top-margin-30">
                            {this.state.learningModules[this.state.selectedIndex].lessons[this.state.selectedIndex2].content.map((value, index) =>
                              <div key={value.value + index}>
                                <button className="background-button full-width left-text" onClick={() => this.itemClicked(index,true,value)}>
                                  <div className="standard-border padding-30">
                                    <div className="calc-column-offset-30">
                                      {(value.title) && (
                                        <p className="heading-text-5 keep-line-breaks"><label className="bold-text">[{value.category} Title]</label>: {value.title}</p>
                                      )}
                                      <p className={(value.title) ? "keep-line-breaks top-margin-20" : "keep-line-breaks"}><label className="bold-text">[{value.category} Content]</label>: {value.value}</p>
                                      {(value.contentId) && (
                                        <div>
                                          <p className="top-margin description-text-2 description-text-color">[Pulled from Resources]</p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="fixed-column-30">
                                      <button className="background-button" onClick={(e) => this.deleteItem(e, index)}>
                                        <img src={deleteIconDark} alt="GC" className="image-auto-20"/>
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <p className="description-text-color row-30">No content added yet. Use the widget to add content.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/*
                  lesson name, estimated duration, deadline
                  */}
                </div>

                {(window.innerWidth > 768 && !this.props.fromModal) && (
                  <div className="relative-column-40 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      <div className="card-clear-padding min-width-100 max-width-600 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-3">Add Content</p>
                        </div>

                        <hr />

                        <div className="padding-20">
                          {(this.state.contentTypeOptions.length && this.state.contentTypeOptions.length > 0) && (
                            <div>
                              {this.state.contentTypeOptions.map((value, index) =>
                                <div key={value.name + index}>
                                  <div className="relative-column-33 padding-5 top-margin">
                                    <button className="background-button full-width center-text padding-20 standard-border rounded-corners" onClick={() => this.itemClicked(index, false, value)}>
                                      <img src={value.icon} alt="GC" className="image-40-fit center-horizontally" />
                                      <p className="top-margin-20">{value.name}</p>
                                    </button>
                                  </div>
                                  {(index % 3 === 2) && (
                                    <div className="clear" />
                                  )}
                                </div>
                              )}
                              <div className="clear" />
                            </div>
                          )}

                          <div className="spacer" />
                        </div>

                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

            {(this.state.selectedOption) && (
              <div key="selectedOption" className="full-width padding-20">
               <p className="heading-text-3">Add {this.state.selectedOption.name}</p>

               <div className="spacer" /><div className="spacer" />

               {(this.state.selectedOption.name === 'Text') && (
                 <div>
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Add an existing resource</label>
                      <Switch
                        onChange={(change) => this.setState({ useExistingResource: change, formHasChanged: true })}
                        checked={this.state.useExistingResource}
                        id="normal-switch"
                      />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Render as HTML</label>
                      <Switch
                        onChange={(change) => this.setState({ htmlMode: change, formHasChanged: true })}
                        checked={this.state.htmlMode}
                        id="normal-switch"
                      />
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.useExistingResource) ? (
                    <div>
                      <div className="row-10">
                        <div className="float-left">
                          <label className="profile-label">Select a Page Resource <label className="error-color bold-text">*</label></label>
                        </div>
                        <div className="float-left left-margin top-margin">
                          <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Page' }})}>
                            <div className="circle-radius cta-border padding-5">
                            <img src={addIconBlue} alt="GC" className="image-12-fit" />
                            </div>
                          </button>
                        </div>
                        <div className="clear" />

                        <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                          {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                            <option key={value.title} value={value.title}>{value.title}</option>
                          )}
                        </select>
                        <p className="description-text-2 description-text-color row-10">Pages can be added and deleted from the resources tab. Be careful not to delete pages that have been added to live courses.</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row-10">
                        <label className="profile-label">Title <label className="error-color bold-text">*</label></label>
                        <input className="text-field" type="text" placeholder="Add a title..." name={"contentTitle"} value={this.state.contentTitle} onChange={this.formChangeHandler} />
                      </div>
                      <div className="row-10">
                        <label className="profile-label">Content <label className="error-color bold-text">*</label></label>
                        <textarea className="text-field top-margin-20" type="text" placeholder="Add text..." name={"contentValue"} value={this.state.contentValue} onChange={this.formChangeHandler} />
                      </div>
                    </div>
                  )}
                 </div>
               )}
               {(this.state.selectedOption.name === 'Video') && (
                 <div>
                   <label className="profile-label">Add an existing resource</label>
                   <Switch
                     onChange={(change) => this.setState({ useExistingResource: change, formHasChanged: true })}
                     checked={this.state.useExistingResource}
                     id="normal-switch"
                   />

                   {(this.state.useExistingResource) ? (
                     <div>
                       <div className="row-10">
                         <div className="float-left">
                           <label className="profile-label">Select a Video Resource <label className="error-color bold-text">*</label></label>
                         </div>
                         <div className="float-left left-margin top-margin">
                           <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Video' }})}>
                             <div className="circle-radius cta-border padding-5">
                             <img src={addIconBlue} alt="GC" className="image-12-fit" />
                             </div>
                           </button>
                         </div>
                         <div className="clear" />

                         <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                           {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                             <option key={value.title} value={value.title}>{value.title}</option>
                           )}
                         </select>
                         <p className="description-text-2 description-text-color row-10">Videos can be added and deleted from the resources tab. Be careful not to delete videos that have been added to live courses.</p>
                       </div>
                     </div>
                   ) : (
                     <div>
                       <div className="row-10">
                         <label className="profile-label">Link to Video <label className="error-color bold-text">*</label></label>
                         <input className="text-field" type="text" placeholder="Add a video link..." name={"contentValue"} value={this.state.contentValue} onChange={this.formChangeHandler} />
                       </div>
                     </div>
                   )}

                 </div>
               )}
               {(this.state.selectedOption.name === 'Link') && (
                 <div>
                  <input className="text-field" type="text" placeholder="Add a link..." name={"contentValue"} value={this.state.contentValue} onChange={this.formChangeHandler} />
                 </div>
               )}
               {(this.state.selectedOption.name === 'Assignment') && (
                 <div>
                   <div className="float-left">
                     <label className="profile-label">Select an Assignment <label className="error-color bold-text">*</label></label>
                   </div>
                   <div className="float-left left-margin top-margin">
                     <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Assignment' }})}>
                       <div className="circle-radius cta-border padding-5">
                       <img src={addIconBlue} alt="GC" className="image-12-fit" />
                       </div>
                     </button>
                   </div>
                   <div className="clear" />

                   <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                     {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                       <option key={value.title} value={value.title}>{value.title}</option>
                     )}
                   </select>
                   <p className="description-text-2 description-text-color row-10">Assignments can be added and deleted from the resources tab. Be careful not to delete assignments that have been added to live courses.</p>
                 </div>
               )}
               {(this.state.selectedOption.name === 'Survey') && (
                 <div>
                   <div className="float-left">
                     <label className="profile-label">Select a Survey <label className="error-color bold-text">*</label></label>
                   </div>
                   <div className="float-left left-margin top-margin">
                     <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Survey' }})}>
                       <div className="circle-radius cta-border padding-5">
                       <img src={addIconBlue} alt="GC" className="image-12-fit" />
                       </div>
                     </button>
                   </div>
                   <div className="clear" />

                   <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                     {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                       <option key={value.title} value={value.title}>{value.title}</option>
                     )}
                   </select>
                   <p className="description-text-2 description-text-color row-10">Surveys are questionnaires assigned to students where there isn't assigned right or wrong answers. Surveys can be added and deleted from the resources tab. Be careful not to delete surveys that have been added to live courses.</p>
                 </div>
               )}
               {(this.state.selectedOption.name === 'Quiz') && (
                 <div>
                   <div className="float-left">
                     <label className="profile-label">Select a Quiz <label className="error-color bold-text">*</label></label>
                   </div>
                   <div className="float-left left-margin top-margin">
                     <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Quiz' }})}>
                       <div className="circle-radius cta-border padding-5">
                       <img src={addIconBlue} alt="GC" className="image-12-fit" />
                       </div>
                     </button>
                   </div>
                   <div className="clear" />

                   <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                     {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                       <option key={value.title} value={value.title}>{value.title}</option>
                     )}
                   </select>
                   <p className="description-text-2 description-text-color row-10">Quizzes are multiple choice style questionnaires assigned to students that have right or wrong answers. Quizzes can be added and deleted from the resources tab. Be careful not to delete quizzes that have been added to live courses.</p>
                 </div>
               )}
               {(this.state.selectedOption.name === 'A.I. Script') && (
                 <div>
                  {(this.state.resourceOptions) && (
                    <div>

                      <div className="float-left">
                        <label className="profile-label">Select a Script <label className="error-color bold-text">*</label></label>
                      </div>
                      <div className="float-left left-margin top-margin">
                        <button className="background-button" onClick={() => this.props.navigate(this.state.prefix + '/resources/add', { state: { passedType: 'Script' }})}>
                          <div className="circle-radius cta-border padding-5">
                          <img src={addIconBlue} alt="GC" className="image-12-fit" />
                          </div>
                        </button>
                      </div>
                      <div className="clear" />
                      <select name="contentValue" value={this.state.contentValue} onChange={this.formChangeHandler} className="dropdown">
                        {[{ title: ''}].concat(this.state.resourceOptions).map(value =>
                          <option key={value.title} value={value.title}>{value.title}</option>
                        )}
                      </select>
                      <p className="description-text-2 description-text-color row-10">Scripts are a series of questions, commands, and prompts designed for students to edit and submit to our A.I. chatbot. Script can be added and deleted from the resources tab. Be careful not to delete scripts that have been added to live courses.</p>
                    </div>
                  )}
                 </div>
               )}

               {(this.state.selectedOption.name === 'Training') && (
                 <div className="row-20">
                  <p>This module allows students to place themselves into a simulated job, submit timesheets, and submit assignments with an A.I. as their supervisor.</p>

                  <p className="error-color top-margin-20">We advise to only add this once into training & advancement course(s)</p>
                 </div>
               )}

               <div className="spacer" /><div className="spacer" />

               {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
               {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

               {(this.state.existingItem) ? (
                 <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.editItem()}>Edit</button>
               ) : (
                 <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.addItem()}>Add</button>
               )}

               <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
              </div>
            )}

           </Modal>
          </div>
      )
    }
}


export default withRouter(EditCourseLesson);
