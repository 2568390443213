import React, {Component} from 'react';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

class AdminSendMessage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
    }

    componentDidMount(){

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in AdminSendMessage ', this.prop, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedApplication !== prevProps.selectedApplication) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subAdminMessage')

      const org = this.props.org
      const accountCode = this.props.accountCode
      const selectedApplication = this.props.selectedApplication

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName, orgFocus, org, accountCode, selectedApplication })

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgContactEmail = response.data.orgInfo.contactEmail

            this.setState({ orgName, orgContactEmail })

          } else {
            console.log('org info query did not work', response.data.message)
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        if (accountCode) {
          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted within sub settings', response.data);

            if (response.data.success) {
              console.log('account info query worked in sub settings')

              this.setState({
                employerName: response.data.accountInfo.employerName,
                employerURL: response.data.accountInfo.employerURL,
                employerContactFirstName: response.data.accountInfo.contactFirstName,
                employerContactLastName: response.data.accountInfo.contactLastName,
                employerContactTitle: response.data.accountInfo.contactTitle,
                employerContactEmail: response.data.accountInfo.contactEmail,

              });
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
          });
        }

      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'message') {
        console.log('message clicked')
        this.setState({ message: event.target.value })
      }
    }

    sendMessage() {
      console.log('sendMessage clicked');

      this.setState({
        clientErrorMessage: ''
      })

      if (this.state.message === '') {
        this.setState({ clientErrorMessage: 'please add a message for the recipient' })
      } else {

        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail
        const employerName = this.state.employerName
        const employerContactEmail = this.state.employerContactEmail
        const postingTitle = this.state.selectedApplication.postingTitle

        let senderType = 'Org'
        if (window.location.pathname.includes('/employers')) {
          senderType = 'Employer'
        }

        //make it so that you see that it is coming from the employer brand name
        Axios.post('/api/notifications/message', { //sessions: sessions
          creatorEmail: this.state.emailId, recipientFirstName: this.state.selectedApplication.firstName,
          recipientLastName: this.state.selectedApplication.lastName, recipientEmail: this.state.selectedApplication.email,
          recipientType: 'Advisee', senderFirstName: this.state.cuFirstName,senderLastName: this.state.cuLastName,
          senderEmail: this.state.emailId, message: this.state.message,
          orgName, orgContactEmail, employerName, employerContactEmail, senderType, postingTitle,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message sent worked', response.data);
            this.setState({
              message: '',

              serverPostSuccess: true,
              serverSuccessMessage: 'Message saved successfully!'
            })
          } else {
            console.log('there was an error sending the message');
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
        });
      }
    }

    render() {

      return (
          <div>
              <div className="white-background width-70-percent vertical-margin-2-percent padding-30 medium-shadow max-width-1400 center-horizontally">

                { (this.state.selectedApplication) && (
                  <div className="row-10">
                    <p className="heading-text-6"><label className="bold-text">To:</label> {this.state.selectedApplication.firstName} {this.state.selectedApplication.lastName}</p>
                  </div>
                )}

                <div className="edit-profile-row">
                  <textarea type="text" className="text-field" placeholder="Write your message..." name="message" value={this.state.message} onChange={this.formChangeHandler}></textarea>
                </div>

                { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                { (this.state.serverPostSuccess) ? (
                  <p className="success-message">{this.state.serverSuccessMessage}</p>
                ) : (
                  <p className="error-message">{this.state.serverErrorMessage}</p>
                )}

                <button className="btn btn-primary" onClick={() => this.sendMessage()}>Send Message</button>
              </div>
          </div>

      )
    }
}

export default withRouter(AdminSendMessage);
