import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Axios from 'axios';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

import {convertDateToString} from '../Functions/ConvertDateToString';

// const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
// const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const educationIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/education-icon.png";
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';

class ReferralDetails extends Component {
    constructor(props) {
      super(props)
      this.state = {

        selectedPeople: [],
        selectedLinks: [],
        selectedTimes: [],
        selectedProjects: [],
        selectedCareers: [],
        linkCategoryOptions: ['','Video','Event','Course','Article','Report','Job Opportunity','Other']
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.renderTaggedItem = this.renderTaggedItem.bind(this)

      this.closeModal = this.closeModal.bind(this)

      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.formatStartDate = this.formatStartDate.bind(this)
      this.configureLink = this.configureLink.bind(this)
      this.renderDetails = this.renderDetails.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called within subEditSubscription')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonReferralDetails', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedReferral !== prevProps.selectedReferral) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within commonReferralDetails', this.props)

      const email = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      const accountCode = localStorage.getItem('accountCode');

      // const subscriptionType = null
      const selectedReferral = this.props.selectedReferral
      const modalIsOpen = this.props.modalIsOpen
      const showReferralDetails = this.props.showReferralDetails
      const profileData = this.props.profileData
      const excludeModal = this.props.excludeModal

      // let loggedIn = false
      // if (cuFirstName && email) {
      //   loggedIn = true
      // }

      this.setState({
        emailId: email, email, cuFirstName, cuLastName, orgFocus, roleName, activeOrg,
        accountCode, selectedReferral, modalIsOpen, showReferralDetails,
        excludeModal, profileData
      })

      if (!this.props.profileData && this.props.selectedReferral) {
        Axios.get('/api/users/profile/details', { params: { email: this.props.selectedReferral.creatorEmail } })
        .then((response) => {
          console.log('User details query 1 attempted', response.data);

          if (response.data.success) {
             console.log('successfully retrieved user details')

             let profileData = response.data.user
             this.setState({ profileData });

          } else {
           console.log('no user details data found', response.data.message)
          }

        }).catch((error) => {
           console.log('User details query did not work', error);
        });
      }

      if (selectedReferral && selectedReferral.orgCode) {
        // get orgContactEmail
        Axios.get('/api/org', { params: { orgCode: selectedReferral.orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const referralOrgCode = selectedReferral.orgCode
            const referralOrgName = selectedReferral.orgName
            const referralOrgContactEmail = response.data.orgInfo.contactEmail
            this.setState({ referralOrgCode, referralOrgName, referralOrgContactEmail });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name.includes("people|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedPeople = this.state.selectedPeople
        selectedPeople[index][name] = event.target.value
        this.setState({ selectedPeople })
      } else if (event.target.name.includes("link|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedLinks = this.state.selectedLinks
        selectedLinks[index][name] = event.target.value
        this.setState({ selectedLinks })
      } else if (event.target.name.includes("time|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedTimes = this.state.selectedTimes
        selectedTimes[index][name] = event.target.value
        this.setState({ selectedTimes })
      } else if (event.target.name.includes("project|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedProjects = this.state.selectedProjects
        selectedProjects[index][name] = event.target.value
        this.setState({ selectedProjects })
      } else if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type.includes('career')) {
        if (!searchString || searchString === '') {
          this.setState({ searchCareers: searchString, searchIsAnimatingCareers: false, careerOptions: null })
        } else {

          let searchIsAnimatingCareers = true

          this.setState({ searchCareers: searchString, searchIsAnimatingCareers })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimatingCareers: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  let careerOptions = []
                  self.setState({ careerOptions, searchIsAnimatingCareers: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);

                let careerOptions = []
                self.setState({ careerOptions, searchIsAnimatingCareers: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type.includes('career')) {

        let searchObject = passedItem
        let searchCareers = passedItem.name
        let careerOptions = null
        let unready = false

        this.setState({ searchObject, searchCareers, careerOptions, unready })

      }
    }

    renderTaggedItem(item, type, answer) {
      console.log('renderTaggedItem called', item, type, answer)

      let defaultProfileItemIcon = projectsIconDark
      if (type === 'project') {
        defaultProfileItemIcon = projectsIconDark
      } else if (type === 'career') {
        defaultProfileItemIcon = careerMatchesIconDark
      } else if (type === 'competency') {
        defaultProfileItemIcon = educationIcon
      } else if (type === 'work') {
        defaultProfileItemIcon = opportunitiesIconDark
      }

      let itemObject = item.aItem
      if (answer === 'b') {
        itemObject = item.bItem
      }

      if (type === 'project') {

        return (
          <div key="taggedProjectItem">
            <Link to={'/app/projects/' + itemObject._id} className="background-button standard-color full-width">
              {(answer === 'a') ? (
                <div>
                  <div className="calc-column-offset-80 heading-text-5 right-text">
                    <p>A: {item.aName}</p>
                  </div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.aValue) && (
                      <p className="bold-text right-text cta-color">${item.aValue}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.bValue) ? (
                      <p className="bold-text cta-color">${item.bValue}</p>
                    ) : (
                      <div className="width-40 height-30" />
                    )}
                  </div>
                  <div className="calc-column-offset-80 heading-text-5">
                    <p className="full-width right-text">B: {item.bName}</p>
                  </div>
                </div>
              )}

              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="bottom-padding">
                <div className="cta-border">
                  <Link to={'/app/projects/' + itemObject._id} className={(answer === 'a') ? "background-button standard-color padding-20 full-width" : "background-button standard-color padding-20 full-width right-text"}>
                    {(answer === 'a') ? (
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="padding-20">
                        <div className="calc-column-offset-60 right-padding">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (type === 'work') {
        return (
          <div key="taggedWorkItem">
            <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-50">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-40-fit" />
                    </div>
                    <div className="calc-column-offset-50">
                      {(itemObject.title) ? (
                        <p>{itemObject.title}</p>
                      ) : (
                        <p>{itemObject.name}</p>
                      )}

                      {(itemObject.employerName) && (
                        <p className="description-text-3 description-text-color">{itemObject.employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'career') {
        return (
          <div key="taggedCareerItem">
            <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.jobFamily}</label>

                      {(itemObject.marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(itemObject.marketData.pay).toLocaleString()} avg pay</label>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'competency') {
        return (
          <div key="taggedCompetencyItem">
            <div className="bottom-padding">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </div>

            <div className="bottom-padding">
              <div className="cta-border">
                <div className="standard-color padding-20 full-width">
                  <div>
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.category}</label>

                      {(itemObject.description) && (
                        <div>
                          <div className="clear" />
                          <label className="description-text-3 description-text-color">{itemObject.description}</label>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showReferralDetails: false });
      if (!this.state.excludeModal) {
        this.props.closeModal()
      }
    }

    formatStartDate(startDate) {
      console.log('formatStartDate called', startDate)

      // is string
      startDate = startDate.substring(5,7) + '/' + startDate.substring(8,10) + '/' + startDate.substring(0,4)
      return startDate
    }

    configureLink(referral) {
      console.log('configureLink')

      if (window.location.pathname.startsWith('/organizations')) {
        return '/organizations/' + this.state.activeOrg + '/members/' + referral.creatorUsername
      } else if (window.location.pathname.includes('/advisor')) {
        return '/advisor/members/' + referral.creatorUsername
      } else if (window.location.pathname.startsWith('/app/')) {
        return '/app/profile/' + referral.creatorUsername
      } else {
        return '/' + referral.creatorUsername + '/profile'
      }
    }

    renderDetails() {
      console.log('renderDetails called')

      return (
        <div key="referralDetails">
          <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

          <div className="horizontal-padding">
            <p className="heading-text-2 full-width center-text">{this.state.selectedReferral.opportunityTitle} @ {this.state.selectedReferral.opportunityEmployerName}</p>

            <div className="top-padding-20">
              <p className="full-width center-text"><Link to={(this.state.selectedReferral.referrerLinkedInURL) ? this.state.selectedReferral.referrerLinkedInURL : '/'} className={(this.state.selectedReferral.referrerLinkedInURL) ? "background-button" : "invisible-button"} disabled={(this.state.selectedReferral.referrerLinkedInURL) ? false : true}>{this.state.selectedReferral.referrerFirstName} {this.state.selectedReferral.referrerLastName}{(this.state.selectedReferral.referrerTitle) && ",  " + this.state.selectedReferral.referrerTitle + ' @ ' + this.state.selectedReferral.referrerEmployerName}</Link> refers <Link to={(this.state.selectedReferral.recipientUsername) ? "/" + this.state.selectedReferral.recipientUsername+ "/profile" : '/'} className={(this.state.selectedReferral.recipientUsername) ? "background-button" : "invisible-button"} disabled={(this.state.selectedReferral.recipientUsername) ? false : true}>{this.state.selectedReferral.recipientFirstName} {this.state.selectedReferral.recipientLastName}</Link></p>
            </div>

            <div className="top-padding-5">
              <p className="full-width center-text description-text-2">[{this.state.selectedReferral.relationship}]</p>
            </div>

            <div className="top-padding-20">
              <p className="full-width center-text">Date: {convertDateToString(new Date(this.state.selectedReferral.createdAt),"date-2")}</p>
            </div>

            <div className="top-padding-20">
              <p className="full-width center-text italicize-text">"{this.state.selectedReferral.reason}"</p>
            </div>

            <div className="top-padding-20">
              <p className="full-width center-text">Status: <label className="cta-color">{this.state.selectedReferral.status}</label></p>
            </div>

            {(this.state.selectedReferral.attachEndorsement && this.state.selectedReferral.recipientUsername) && (
              <div className="top-padding-20">
                <p className="full-width center-text"><Link to={"/" + this.state.selectedReferral.recipientUsername + "/" + this.state.selectedReferral.endorsementId} target="_target">Endorsement Attached</Link></p>
              </div>
            )}
            {/*
            <div className="spacer" /><div className="spacer" />

            <hr />

            <div className="clear" />

            <div className="top-padding-20">
              <button className="btn btn-profile full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.itemClicked(e,'helpOut', value)}>Send to Employer</button>
            </div>*/}

            <div className="spacer" />
          </div>

          <div className="top-margin-40 center-text">

            {(!this.state.excludeModal) && (
              <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Cancel</button>
            )}

          </div>
        </div>
      )
    }

    render() {

      return (
        <div>
          {(this.state.selectedReferral) && (
            <div>
              {(this.state.excludeModal) && (
                <div>
                   {this.renderDetails()}
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
               className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
              >
                <div key="showReferralDetails">
                  {(this.state.showReferralDetails) && (
                    <div className="full-width padding-20">
                       {this.renderDetails()}
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          )}
        </div>
      )
    }
}

export default ReferralDetails;
