import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const testimonialBackground3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-3.png";
const quote3 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-3.png";
const testimonialBackground4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/testimonial-background-4.png";
const quote4 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/quote-4.png";

class EmployerBenefits extends Component {
    constructor(props) {
      super(props)
      this.state = {
        panelSelected: 1
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called')
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEmployerBenefits', this.props, prevProps)

      if (this.props.orgSelected !== prevProps.orgSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEmployerBenefits')

      const orgSelected = this.props.orgSelected
      const hcmServices = [
        'Internal Career Pathing','Timesheets','Learning & Development',
        'Employee Engagement','Employee Performance','Employee Referrals'
      ]
      this.setState({ orgSelected, hcmServices })

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, enlargeImage: false, selectedImage: null })

    }

    render() {

      // let columnClass = "col span-1-of-3 box"
      // if (this.state.orgSelected) {
      //   columnClass = "col span-1-of-2 box"
      // }

      return (
        <div>

          <section className="section-features white-background">
              <div className="row">
                <h2>Easily Hire Screened, Referred Candidates</h2>

                <div className="spacer" /><div className="spacer" />

                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png' })}>
                      <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png'} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                    <p className="heading-text-3">1) Integrate ATS or Post Opportunities</p>

                    <div className="top-padding-20">
                      <p>We highly recommend an HR Manager approve Guided Compass to integrate with their Applicant Tracking System (ATS). Request an integration <Link to={"/contact"} state={{ reason: "Integrate with Applicant Tracking System", roleName: "Employer Representative" }}>here</Link>. Integrating means Guided Compass can automatically distribute opportunities, and push referred candidates to your posting without you moving a muscle.</p>
                      <p className="top-padding-30">In the absense of an integration, employers can post events, projects, and work opportunities <Link to={(this.state.orgSelected) ? "/employers/post/" + this.state.orgSelected.orgCode : "/employers/post"} target="_blank" className="bold-text">here</Link>.</p>
                    </div>
                  </div>
                  <div className="clear" />

                </div>
                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 right-padding-30"}>
                    <p className="heading-text-3">2) Hire Referred Candidates</p>

                    <div className="top-padding-20">
                      <p>Once referred candidates come in, please provide feedback on the candidates. If candidates are hired, we will make a note in our system.</p>
                      <p className="top-padding-30">If you provide feedback via the employer portal, referred candidates come in clickable profile cards that show match scores, summarize hours, and provide insight into intangible elements that indicate a good fit.</p>
                    </div>

                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png' })}>
                      <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png'} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className="clear" />

                </div>
                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: exampleInternMatch })}>
                      <img src={exampleInternMatch} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                    <p className="heading-text-3">3) Design Ideal Candidates</p>

                    <div className="top-padding-20">
                      <p>While it isn't necessary to customize ideal candidates (i.e., <Link to="/benefits/transparent-pathways" target="_blank" className="bold-text">benchmarks</Link>) for each of your departments, it will help us serve you better candidates.</p>
                      <p className="top-padding-30">Our algorithm scores against your benchmarks, and {(this.state.orgSelected) ? this.state.orgSelected.orgName : "our workforce and education partners"} will use your benchmarks to train and refer candidates.</p>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
          </section>
          {/*
          <section className="section-features white-background">
              <div className="row">
                <h2>Benefits</h2>

                <div>
                  <div className={columnClass}>
                    <img src={trainingIcon} alt="GC" className="image-auto-150 center-horizontally bottom-margin-20" />
                    <p className="heading-text-4 full-width center-text">Outsource Candidate Training</p>
                    <p className="full-width center-text top-padding-20">{(this.state.orgSelected) ? this.state.orgSelected.orgName + " develops" : "Education / workforce partners develop"} programs and pathways around your posted opportunities and <Link to="/benefits/transparent-pathways">benchmarks</Link> (i.e., ideal candidates).{(!this.state.orgSelected) && " Some can subsidize entry-level candidates."}</p>
                    <p className="description-text-2 full-width center-text row-10"><label className="bold-text">Metrics:</label> Time to Fill, Quality of Hire, Acceptance Rate, Retention Rate</p>
                  </div>
                  <div className={columnClass}>
                    <img src={candidateSelector1} alt="GC" className="image-auto-150 center-horizontally bottom-margin-20" />
                    <p className="heading-text-4 full-width center-text">Receive Pre-Screened Candidates</p>
                    <p className="full-width center-text top-padding-20">Instead of scouring through thousands of resumes, receive 3-5 recommended candidates scored by the <Link to="/benefits/transparent-pathways">benchmarks</Link> you approve.</p>
                    <p className="description-text-2 full-width center-text row-10"><label className="bold-text">Metrics:</label> Quality of Hire, Acceptance Rate, Retention Rate</p>
                  </div>
                  {(!this.state.orgSelected) && (
                    <div className={columnClass}>
                      <img src={diverseCandidate} alt="GC" className="image-auto-150 center-horizontally bottom-margin-20" />
                      <p className="heading-text-4 full-width center-text">Improve DEI / ESG Metrics</p>
                      <p className="full-width center-text top-padding-20">Most of Guided Compass workforce partners serve low-income communities. You can also filter by diversity criteria.</p>
                      <p className="description-text-2 full-width center-text row-10"><label className="bold-text">Metrics:</label> Diverse Employee Retention, Diverse Employee Performance</p>
                    </div>
                  )}
                </div>

              </div>
          </section>*/}

          <section className="section-workforce">
            <div className="container-left">
              <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>Not sure where to start?</p>
            </div>
            <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
              <Link to={"/help"} state={{ selectedFilters: ["Employer"] }} className="btn white-background heading-text-5">View Help Articles</Link>
            </div>
            <div className="clear" />
          </section>
          {/*
          <section className="section-features white-background">
              <div className="row">
                <h2>Easily Hire Screened, Referred Candidates</h2>

                <div className="spacer" /><div className="spacer" />

                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png' })}>
                      <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/top-atss.png'} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                    <p className="heading-text-3">1) Integrate ATS or Post Opportunities</p>

                    <div className="top-padding-20">
                      <p>We highly recommend an HR Manager approve Guided Compass to integrate with their Applicant Tracking System (ATS). Request an integration <Link to={"/contact"} state={{ reason: "Integrate with Applicant Tracking System", roleName: "Employer Representative" }}>here</Link>. Integrating means Guided Compass can automatically distribute opportunities, and push referred candidates to your posting without you moving a muscle.</p>
                      <p className="top-padding-30">In the absense of an integration, employers can post events, projects, and work opportunities <Link to={(this.state.orgSelected) ? "/employers/post/" + this.state.orgSelected.orgCode : "/employers/post"} target="_blank" className="bold-text">here</Link>.</p>
                    </div>
                  </div>
                  <div className="clear" />

                </div>
                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 right-padding-30"}>
                    <p className="heading-text-3">2) Hire Referred Candidates</p>

                    <div className="top-padding-20">
                      <p>Once referred candidates come in, please provide feedback on the candidates. If candidates are hired, we will make a note in our system.</p>
                      <p className="top-padding-30">If you provide feedback via the employer portal, referred candidates come in clickable profile cards that show match scores, summarize hours, and provide insight into intangible elements that indicate a good fit.</p>
                    </div>

                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png' })}>
                      <img src={'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerCandidatesSS.png'} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className="clear" />

                </div>
                <div className="row-30 horizontal-padding-3">
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-40"}>
                    <button className="background-button" alt="GC" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage: exampleInternMatch })}>
                      <img src={exampleInternMatch} alt="GC" className="image-full-auto" />
                    </button>
                    <p className="description-text-2 bottom-margin">Click the image to enlarge</p>
                  </div>
                  <div className={(window.innerWidth < 768) ? "full-width" : "relative-column-60 left-padding-30"}>
                    <p className="heading-text-3">3) Design Ideal Candidates</p>

                    <div className="top-padding-20">
                      <p>While it isn't necessary to customize ideal candidates (i.e., <Link to="/benefits/transparent-pathways" target="_blank" className="bold-text">benchmarks</Link>) for each of your departments, it will help us serve you better candidates.</p>
                      <p className="top-padding-30">Our algorithm scores against your benchmarks, and {(this.state.orgSelected) ? this.state.orgSelected.orgName : "our workforce and education partners"} will use your benchmarks to train and refer candidates.</p>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
          </section>*/}

          <section className="section-features white-background">
              <div className="row">
                <h2>Manage Internal Career Pathways</h2>

                <div className="spacer" /><div className="spacer" />

                <div className="row-30 horizontal-padding-3">
                  <p className="center-text">Just as you can use Guided Compass for managing external talent (i.e., recruiting, talent pipeline building), you can manage internal talent. Services include:</p>

                  {(this.state.hcmServices) && (
                    <div className="top-margin-30">
                      {this.state.hcmServices.map((item, optionIndex) =>
                        <div className="top-margin">
                          <div className="relative-column-33 description-text-color">
                            <p className="full-width center-text">{item}</p>
                          </div>
                          {(optionIndex % 3 === 2) && (
                            <div className="clear" />
                          )}
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  )}
                </div>

                <div className="center-text">
                  <Link className="btn btn-primary cta-background-color white-text" to={'/contact'} state={{ reason: "Schedule Demo of Employer Portal", roleName: "Employer Representative", body: "I'm interested in scheduling a demo of the Human Capital Management (HCM) version of Guided Compass." }}>Schedule a Demo</Link>
                </div>

              </div>
          </section>
          {/*
          <section className="section-next-generation">
              <div className="next-generation-container">
                  <div className="next-generation-text-box">
                      <p className="next-generation-title left-text">"The average cost-per-hire is $4,129"</p>
                      <div className="spacer"/>
                      <p className="next-generation top-padding left-text">- 2016 SHRM Survey</p>
                  </div>
              </div>
          </section>*/}

          <section className="section-features orange-background">
              <div className="row">
                <h2>Hear From Our Employer Partners</h2>
              </div>

              <div className="row top-margin-60">
                <div className="container-left">
                  <div className="white-background relative-position">
                    <div className="padding-20">
                      <img src={quote3} alt="GC" className="image-auto-30" />
                    </div>

                    <div className="bottom-padding-20 horizontal-padding-5">
                      <p className="heading-text-5">“Guided Compass helped us define our ideal entry-level candidates, before recommending some great candidates for internships and full-time. We hired 5 people for our marketing and content teams through the platform. Our hires are highly engaged and very strong matches for our companies.”</p>
                    </div>

                    <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                      <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/roger_smith.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                      <div className="white-text bottom-padding-10-percent">
                        <p className="top-padding">Roger Smith</p>
                        <p className="description-text-2">President</p>
                        <p className="description-text-2">All Sports Television Network</p>
                      </div>
                    </div>

                    <img src={testimonialBackground3} alt="GC" className="image-full-auto" />
                  </div>
                </div>
                <div className="container-right">
                  <div className="white-background relative-position">
                    <div className="padding-20">
                      <img src={quote4} alt="GC" className="image-auto-30" />
                    </div>

                    <div className="bottom-padding horizontal-padding-5">
                      <p className="heading-text-5">“Guided Compass really helped me narrow down my search for diverse candidates that are a good fit for our firm. After a few events, we hired two interns that we continue to work with today. Creating the benchmarks alone, seemed to be helpful to the diverse communities Guided Compass works in.”</p>
                    </div>

                    <div className="horizontal-padding-5 center-text full-width absolute-position pin-bottom">
                      <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/featuredProfilePics/sebastian_de_vivo.jpg" alt="GC" className="profile-thumbnail-6 center-horizontally" />

                      <div className="white-text bottom-padding-10-percent">
                        <p className="top-padding">Sebastian De Vivo</p>
                        <p className="description-text-2">Co-Founder & CEO</p>
                        <p className="description-text-2">List Ventures</p>
                      </div>
                    </div>

                    <img src={testimonialBackground4} alt="GC" className="image-full-auto" />
                  </div>
                </div>
                <div className="clear" />
              </div>
          </section>

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
          >
            <div key="showEditGroup" className="full-width padding-20">

              {(this.state.enlargeImage) && (
                <div>
                  <img src={this.state.selectedImage} className="image-full-auto" alt="GC" />
                  <div className="row-10 center-text">
                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>
                      Close View
                    </button>
                  </div>
                </div>
              )}
             </div>
          </Modal>
        </div>
      )
    }
}

export default EmployerBenefits
