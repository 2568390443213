import React, {Component} from 'react';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
import Switch from 'react-switch';
import ReactGA from 'react-ga4';

import MyEditor from '../Subcomponents/MyEditor';
import SubRenderResume from '../Common/RenderResume';
import SubRenderCoverLetter from '../Common/RenderCoverLetter';
import SubRenderCareerPlan from '../Common/RenderCareerPlan';
import SubFinancials from '../Subcomponents/Financials';
import SubRenderBusinessPlan from '../Common/RenderBusinessPlan';
import SubRenderMockInterviewDoc from '../Common/RenderMockInterviewDoc';
import SubRenderGoalPlan from '../Common/RenderGoalPlan';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';
import {subExportFile} from '../Functions/ExportFile';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const newDocumentIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/new-document-icon.png';
const openIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/open-icon-dark.png';
const openIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/open-icon-blue.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
const layoutIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/layout-icon-dark.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const saveIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/save-icon-dark.png';
const saveIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/save-icon-blue.png';
const exportIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/export-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const gradesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/grades-icon-dark.png';
const gradesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/grades-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)
  for (let i = 1; i <= result.length; i++) {
    console.log(i);

    result[i - 1]['rank'] = i

  }

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class EditDocument extends Component {
    constructor(props) {
      super(props)

      this.state = {
        docType: '',
        showGrade: false,
        disableExport: false,
        showUpdateProfile: false,
        docProfile: {},
        docContent: {},
        unready: true,

        saveOptions: ['','Save New','Update Document File'],
        sectionOptions: ['Summary','Education','Experience','Projects','Skills','Knowledge','Awards'],
        selectedSections: ['Summary','Education','Experience','Projects','Awards'],

        projectCategoryOptions: [], dateOptions: [], collaboratorOptions: [], hourOptions: [], docFileNames: [],
        favoritedCareers: [], favoritedCareerDetails: [], favoritedOpportunities: [], favoritedOpportunityDetails: [],

        newFavorites: [],
        showSubFunction: true,

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.generateDoc = this.generateDoc.bind(this)
      this.createNewDoc = this.createNewDoc.bind(this)
      this.importProfile = this.importProfile.bind(this)
      this.child = React.createRef();
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.indicateFormChange = this.indicateFormChange.bind(this)
      this.saveDoc = this.saveDoc.bind(this)
      this.exportFile = this.exportFile.bind(this)
      this.renderDocument = this.renderDocument.bind(this)
      this.getHeight = this.getHeight.bind(this)
      this.pullProfileData = this.pullProfileData.bind(this)
      this.finalizeDocContent = this.finalizeDocContent.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.toggleToolbar = this.toggleToolbar.bind(this)
      this.editDocData = this.editDocData.bind(this)

      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)
      this.renderTags = this.renderTags.bind(this)
      this.addObjectivesToDoc = this.addObjectivesToDoc.bind(this)
      this.favoriteItem = this.favoriteItem.bind(this)
      this.onDragEnd = this.onDragEnd.bind(this);

      this.addItem = this.addItem.bind(this)
      this.deleteItem = this.deleteItem.bind(this)
      this.selectItem = this.selectItem.bind(this)
      this.addBulletPoints = this.addBulletPoints.bind(this)
      this.changeContinual = this.changeContinual.bind(this)

      this.loadDoc = this.loadDoc.bind(this)
      this.deleteDoc = this.deleteDoc.bind(this)

      this.passData = this.passData.bind(this)
      this.doublePassData = this.doublePassData.bind(this)
      this.fileClicked = this.fileClicked.bind(this)
      this.addSection = this.addSection.bind(this)
      this.saveGrade = this.saveGrade.bind(this)

    }

    componentDidMount(){

      console.log('sub component mounted')
      this.retrieveData()

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');

      ReactGA.initialize([
        {
          trackingId: "376007762",
          gaOptions: { userId: emailId }, // optional
          // gtagOptions: {...}, // optional
        },
        // {
        //   trackingId: "your second GA measurement id",
        // },
      ]);

      ReactGA.event({
        category: activeOrg,
        action: "opened_edit_document",
        label: "opened_edit_document" , // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
      });

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEditDocument', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.id !== prevProps.id) {
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen && this.props.modalIsOpen === true && this.props.selectedFile) {
        this.setState({ modalIsOpen: true, showFile: true, selectedFile: this.props.selectedFile, docFileName: this.props.selectedFile.fileName })
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEditDocument', this.props)

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.activeOrg) {
        activeOrg = this.props.activeOrg
      }

      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');
      const pictureURL = localStorage.getItem('pictureURL');
      const remoteAuth = localStorage.getItem('remoteAuth');

      // const id = this.props.id
      let docType = this.props.docType

      if (this.props.pageSource === 'landingPage') {
        cuFirstName = 'Jon'
        cuLastName = 'Doe'
        emailId = 'jondoe@gmail.com'
        activeOrg = 'guidedcompass'
      }

      let docProfile = { email: emailId, firstName: cuFirstName, lastName: cuLastName,
        username, pictureURL
      }

      const sideSectionClass = ""
      const mainSectionClass = ""
      const headerClass = "center-text"
      const sectionHeaderDivClass = "background-button full-width left-text"
      const sectionHeaderTextClass = "heading-text-4"

      let showGrade = false
      if (window.location.pathname.startsWith('/advisor') || window.location.pathname.startsWith('/organizations')) {
        showGrade = true
      }

      this.setState({ emailId, cuFirstName, cuLastName, username, pictureURL,
        activeOrg, orgFocus, roleName, remoteAuth, docProfile, docType,
        sideSectionClass, mainSectionClass, headerClass, sectionHeaderDivClass, sectionHeaderTextClass,
        showGrade
      })

      if (this.state.useEditor) {
        const richDescriptionRaw = this.props.existingContent
        if (richDescriptionRaw) {
          const richDescription = { blocks: richDescriptionRaw, entityMap: {}}
          this.setState({ richDescription })
        } else {
          this.generateDoc()
        }
      }

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted in', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let schoolOptions = []
            if (response.data.orgInfo.schools) {
              schoolOptions = response.data.orgInfo.schools
              schoolOptions.unshift('')
            }

            let pathwayOptions = []
            if (response.data.orgInfo.pathways) {
              pathwayOptions = response.data.orgInfo.pathways
              pathwayOptions.unshift({ name: ''})
            }

            let gradYearOptions = []
            if (response.data.orgInfo.gradYearOptions) {
              gradYearOptions = response.data.orgInfo.gradYearOptions
              gradYearOptions.unshift('')
            }

            let projectCategoryOptions = [{value: 'I am not sure'}]
            if (activeOrg === 'c2c') {
              if (response.data.orgInfo.projectCategories) {
                for (let i = 1; i <= response.data.orgInfo.projectCategories.length; i++) {
                  if (i > 1) {
                    projectCategoryOptions.push({ value: response.data.orgInfo.projectCategories[i - 1]})
                  }
                }
              }
            }
            // const degree = response.data.orgInfo.degreeType

            let dateOptions = []

            const currentMonth = new Date().getMonth()
            const currentYear = new Date().getFullYear()

            let month = ''
            let year = currentYear - 10

            console.log('show me current stuff', currentMonth, currentYear)
            for (let i = 1; i <= 120 + currentMonth; i++) {
              console.log('show me stuff', i)
              if (i % 12 === 0) {
                month = 'January'
              } else if (i % 12 === 1) {
                month = 'February'
              } else if (i % 12 === 2) {
                month = 'March'
              } else if (i % 12 === 3) {
                month = 'April'
              } else if (i % 12 === 4) {
                month = 'May'
              } else if (i % 12 === 5) {
                month = 'June'
              } else if (i % 12 === 6) {
                month = 'July'
              } else if (i % 12 === 7) {
                month = 'August'
              } else if (i % 12 === 8) {
                month = 'September'
              } else if (i % 12 === 9) {
                month = 'October'
              } else if (i % 12 === 10) {
                month = 'November'
              } else if (i % 12 === 11) {
                month = 'December'
              }

              if (i > 12 && i <= 24) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 1 + 1
                } else {
                  year = currentYear - 10 + 1
                }
              } else if (i > 24 && i <= 36) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 2 + 1
                } else {
                  year = currentYear - 10 + 2
                }
              } else if (i > 36 && i <= 48) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 3 + 1
                } else {
                  year = currentYear - 10 + 3
                }
              } else if (i > 48 && i <= 60) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 4 + 1
                } else {
                  year = currentYear - 10 + 4
                }
              } else if (i > 60 && i <= 72) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 5 + 1
                } else {
                  year = currentYear - 10 + 5
                }
              } else if (i > 72 && i <= 84) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 6 + 1
                } else {
                  year = currentYear - 10 + 6
                }
              } else if (i > 84 && i <= 96) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 7 + 1
                } else {
                  year = currentYear - 10 + 7
                }
              } else if (i > 96 && i <= 108) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 8 + 1
                } else {
                  year = currentYear - 10 + 8
                }
              } else if (i > 108 && i <= 120) {
                if (i % 12 === 0) {
                  year = currentYear - 10 + 9 + 1
                } else {
                  year = currentYear - 10 + 9
                }
              }
              dateOptions.push({ value: month + ' ' + year})
            }

            const startDate = dateOptions[dateOptions.legnth - 1]
            const endDate = dateOptions[dateOptions.length - 1]

            Axios.get('/api/workoptions')
            .then((response) => {
              console.log('Work options query tried', response.data);

              if (response.data.success) {
                console.log('Work options query succeeded')

                let functionOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
                  if (i > 1) {
                    functionOptions.push({ value: response.data.workOptions[0].functionOptions[i - 1]})
                  }
                }

                let industryOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
                  if (i > 1) {
                    industryOptions.push({ value: response.data.workOptions[0].industryOptions[i - 1]})
                  }
                }

                let workDistanceOptions = [{value: '0 miles'},{value: '10 miles'}]
                for (let i = 1; i <= response.data.workOptions[0].workDistanceOptions.length; i++) {
                  if (i > 1) {
                    workDistanceOptions.push({ value: response.data.workOptions[0].workDistanceOptions[i - 1]})
                  }
                }

                let hoursPerWeekOptions = [{value: ''}]
                for (let i = 1; i <= response.data.workOptions[0].hoursPerWeekOptions.length; i++) {
                  if (i > 1) {
                    hoursPerWeekOptions.push({ value: response.data.workOptions[0].hoursPerWeekOptions[i - 1]})
                  }
                }

                let hourOptions = [{value: ''}]
                for (let i = 1; i <= response.data.workOptions[0].hourOptions.length; i++) {
                  if (i > 1) {
                    hourOptions.push({ value: response.data.workOptions[0].hourOptions[i - 1]})
                  }
                }

                let workTypeOptions = [{value: 'Internship'}]
                for (let i = 1; i <= response.data.workOptions[0].workTypeOptions.length; i++) {
                  if (i > 1) {
                    workTypeOptions.push({ value: response.data.workOptions[0].workTypeOptions[i - 1]})
                  }
                }

                let hourlyPayOptions = [{value: 'Flexible'}]
                for (let i = 1; i <= response.data.workOptions[0].hourlyPayOptions.length; i++) {
                  if (i > 1) {
                    hourlyPayOptions.push({ value: response.data.workOptions[0].hourlyPayOptions[i - 1]})
                  }
                }

                let annualPayOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].annualPayOptions.length; i++) {
                  if (i > 1) {
                    annualPayOptions.push({ value: response.data.workOptions[0].annualPayOptions[i - 1]})
                  }
                }

                const degreeOptions = response.data.workOptions[0].degreeOptions

                if (activeOrg === 'c2c' || activeOrg === 'exp' || activeOrg === 'dpscd') {
                  // this.setState({ schoolOptions, pathwayOptions, gradYearOptions, projectCategoryOptions });
                } else {
                  // let gradYearOptions = []
                  const startingPoint = new Date().getFullYear() - 6
                  for (let i = 1; i <= 10; i++) {
                    gradYearOptions.push(startingPoint + i)
                  }

                  // let projectCategoryOptions = [{value: 'I am not sure'}]
                  for (let i = 1; i <= response.data.workOptions[0].projectCategoryOptions.length; i++) {
                    if (i > 1) {
                      projectCategoryOptions.push({ value: response.data.workOptions[0].projectCategoryOptions[i - 1]})
                    }
                  }
                }

                const basicCountOptions = ['','1','2','3','4','5']

                this.setState({ functionOptions, industryOptions, gradYearOptions, projectCategoryOptions, pathwayOptions,
                  workDistanceOptions, hoursPerWeekOptions, workTypeOptions, hourlyPayOptions, payOptions: annualPayOptions,
                  dateOptions, hourOptions, degreeOptions,
                  basicCountOptions
                })

              } else {
                console.log('no jobFamilies data found', response.data.message)

                const functionOptions = [{value: 'Undecided'}]
                const industryOptions = [{value: 'Undecided'}]
                //const workDistanceOptions = [{value: '0 miles'},{value: '10 miles'}]
                const hoursPerWeekOptions = [{value: '~ 40 hours / week'}]
                //const workTypeOptions = [{value: 'Internship'}]
                //const hourlyPayOptions = [{value: 'Flexible'}]
                const payOptions = [{value: 'Flexible'}]
                const hourOptions = [{value: ''}]
                const dateOptions = []

                this.setState({ functionOptions, industryOptions, hoursPerWeekOptions, payOptions, dateOptions, hourOptions, startDate, endDate })

              }
            }).catch((error) => {
                console.log('query for work options did not work', error);
            })

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

     const self = this
     function pullFavorites(docProfileEmail) {
       console.log('pullFavorites called', docProfileEmail)

       Axios.get('/api/favorites', { params: { emailId: docProfileEmail } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved favorites')

          const favorites = response.data.favorites
          let newFavorites = []
          if (response.data.newFavorites) {
            newFavorites = response.data.newFavorites
          }

          if (favorites && favorites.length > 0) {
            Axios.get('/api/favorites/detail', { params: { favorites, orgCode: activeOrg } })
            .then((response2) => {
              console.log('Favorites detail query attempted', response2.data);

              if (response2.data.success) {
                console.log('successfully retrieved favorites detail', response2.data.favorites)

                let favoritedCareers = []
                let favoritedCareerDetails = []
                let favoritedOpportunities = []
                let favoritedOpportunityDetails = []

                for (let i = 1; i <= response.data.favorites.length; i++) {
                  if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'career') {
                    favoritedCareers.push(response2.data.favorites[i - 1].name)
                    favoritedCareerDetails.push(response2.data.favorites[i - 1])
                  } else if (response2.data.types[i - 1] && response2.data.types[i - 1] === 'work') {
                    if (response2.data.favorites[i - 1].postType !== 'Event') {
                      if (response2.data.favorites[i - 1].title && response2.data.favorites[i - 1].title !== '') {
                        favoritedOpportunities.push(response2.data.favorites[i - 1].title)
                      } else {
                        favoritedOpportunities.push(response2.data.favorites[i - 1].name)
                      }

                      favoritedOpportunityDetails.push(response2.data.favorites[i - 1])
                    }
                  }
                }

                //query info on those favorites
                self.setState({ favorites, newFavorites, favoritedCareers, favoritedCareerDetails, favoritedOpportunities, favoritedOpportunityDetails })

              } else {
                console.log('no favorites detail data found', response2.data.message)
              }

            }).catch((error) => {
                console.log('Favorites detail query did not work', error);
            });
          }

        } else {
          console.log('no favorites data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });
     }

     if (!window.location.pathname.startsWith('/app') ||  this.props.id) {
       console.log('a2 : ', this.props.id)
       // if (window.location.pathname.includes('/organizations/') || (window.location.pathname.includes('/advisor/') && roleName !== 'Mentor' && activeOrg !== 'guidedcompass')) {
       if (this.props.id) {
         Axios.get('/api/documents', { params: { _id: this.props.id }})
        .then((response) => {
          console.log('Documents query attempted', response.data);

          if (response.data.success && response.data.documents && response.data.documents.length > 0) {
            console.log('successfully retrieved documents')

            const documents = response.data.documents

            const docFile = response.data.documents[0]

            let docFileName = docFile.fileName
            let selectedSections = this.state.selectedSections
            if (docFile.selectedSections && docFile.selectedSections.length > 0) {
              selectedSections = docFile.selectedSections
            }

            docType = docFile.docType
            const docId = docFile._id
            const grade = docFile.grade
            const gradeExplanation = docFile.gradeExplanation

            docProfile = {
              firstName: docFile.firstName,
              lastName: docFile.lastName,
              email: docFile.email,
              username: docFile.username,
              pictureURL: docFile.pictureURL
            }

            let docFileNames = [docFile.fileName]

            this.setState({ documents, docFile, docFileName, selectedSections, docFileNames, docProfile,
              docType, docId, grade, gradeExplanation, docFile
            })

            this.loadDoc(docFileName, documents, docType)

            pullFavorites(docProfile.email)

            if (this.props.passData) {
              this.props.passData({ savedItems: documents })
            }

          } else {
            console.log('no documents data found', response.data.message)
          }

        }).catch((error) => {
            console.log('documents query did not work', error);
        });
       }
     } else {

       Axios.get('/api/documents', { params: { emailId } })
      .then((response) => {
        console.log('documents query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved documents')

          const documents = response.data.documents

          let docFileNames = ['']
          if (documents) {
            for (let i = 0; i < documents.length; i++) {
              docFileNames.push(documents[i].fileName)
            }
          }

          this.setState({ documents, docFileNames })

          if (this.props.passData) {
            this.props.passData({ savedItems: documents })
          }

          pullFavorites(docProfile.email)

        } else {
          console.log('no documents data found', response.data.message)
          if (this.props.passData) {
            this.props.passData({ savedItems: null })
          }
        }

      }).catch((error) => {
          console.log('documents query did not work', error);
      });

      this.importProfile(emailId, docType)

     }
    }

    pullProfileData(dataType, docContent, emailId) {
      console.log('pullProfileData called', dataType, docContent, emailId)

      if (dataType === 'experience') {

        Axios.get('/api/experience', { params: { emailId } })
        .then((response) => {
          console.log('Experience query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved experience')

              if (response.data.experience) {
                if (response.data.experience.length > 0) {

                  let experience = []
                  for (let i = 0; i < response.data.experience.length; i++) {
                    console.log('let see iteration', i)

                    let bullets = []
                    if (response.data.experience[i].bullets) {
                      bullets = response.data.experience[i].bullets
                    }

                    const summary = response.data.experience[i].resumeSummary
                    let description = response.data.experience[i].description
                    if (!description) {
                      description = summary
                    }

                    experience.push({
                      _id: response.data.experience[i]._id,
                      startDate: response.data.experience[i].startDate,
                      endDate: response.data.experience[i].endDate,
                      employerName: response.data.experience[i].employerName,
                      title: response.data.experience[i].jobTitle,
                      location: response.data.experience[i].location,
                      summary, description,
                      jobFunction: response.data.experience[i].jobFunction,
                      industry: response.data.experience[i].industry, bullets
                    })
                  }
                  docContent['experience'] = experience
                  this.pullProfileData('projects', docContent, emailId)
                } else {
                  this.pullProfileData('projects', docContent, emailId)
                }
              } else {
                this.pullProfileData('projects', docContent, emailId)
              }

            } else {
              console.log('no experience data found', response.data.message)
              this.pullProfileData('projects', docContent, emailId)
            }

        }).catch((error) => {
            console.log('Experience query did not work', error);
            this.pullProfileData('projects', docContent, emailId)
        });

      } else if (dataType === 'projects') {

        Axios.get('/api/projects', { params: { emailId, includeCollaborations: true } })
        .then((response) => {
          console.log('Projects query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved projects')

              if (response.data.projects) {

                let projects = []
                for (let i = 0; i < response.data.projects.length; i++) {
                  console.log('let see iteration', i)

                  let bullets = []
                  if (response.data.projects[i].bullets) {
                    bullets = response.data.projects[i].bullets
                  }

                  const summary = response.data.projects[i].resumeSummary
                  let description = response.data.projects[i].description
                  if (!description) {
                    description = summary
                  }

                  projects.push({
                    _id: response.data.projects[i]._id,
                    url: response.data.projects[i].url,
                    videoURL: response.data.projects[i].videoURL,
                    startDate: response.data.projects[i].startDate,
                    endDate: response.data.projects[i].endDate,
                    hours: response.data.projects[i].hours,
                    category: response.data.projects[i].category,
                    collaborators: response.data.projects[i].collaborators,
                    name: response.data.projects[i].name,
                    employerName: response.data.projects[i].employerName,
                    location: response.data.projects[i].location,
                    summary, description, bullets
                  })
                }
                docContent['projects'] = projects
                this.pullProfileData('extras', docContent, emailId)

              } else {
                this.pullProfileData('extras', docContent, emailId)
              }

            } else {
              console.log('no project data found', response.data.message)
              this.pullProfileData('extras', docContent, emailId)
            }

        }).catch((error) => {
            console.log('Project query did not work', error);
            this.pullProfileData('extras', docContent, emailId)
        });

      } else if (dataType === 'extras') {

        Axios.get('/api/extras', { params: { emailId } })
        .then((response) => {
          console.log('Extras query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved extras')

            if (response.data.extras) {
              if (response.data.extras.length > 0) {
                console.log('the array is greater than 0')

                let extras = []
                for (let i = 0; i < response.data.extras.length; i++) {
                  console.log('let see iteration', i)

                  let name = response.data.extras[i].name
                  if (response.data.extras[i].activityName) {
                    name = response.data.extras[i].activityName
                  }

                  let bullets = []
                  if (response.data.extras[i].bullets) {
                    bullets = response.data.extras[i].bullets
                  }

                  const summary = response.data.extras[i].resumeSummary
                  let description = response.data.extras[i].description
                  if (!description) {
                    description = summary
                  }

                  // type: String,
                  // activityName: String,
                  // awardDate: String,

                  extras.push({
                    _id: response.data.extras[i]._id,
                    type: response.data.extras[i].type,
                    startDate: response.data.extras[i].startDate,
                    endDate: response.data.extras[i].endDate,
                    hoursPerWeek: response.data.extras[i].hoursPerWeek,
                    awardDate: response.data.extras[i].awardDate,
                    name, summary, description,
                    activityName: response.data.extras[i].activityName,
                    roleName: response.data.extras[i].roleName,
                    employerName: response.data.extras[i].employerName,
                    location: response.data.extras[i].location, bullets
                  })
                }
                docContent['extras'] = extras
                this.pullProfileData('endorsements', docContent, emailId)

              } else {
                this.pullProfileData('endorsements', docContent, emailId)
              }
            } else {
              this.pullProfileData('endorsements', docContent, emailId)
            }

          } else {
            console.log('no extras data found', response.data.message)
            this.pullProfileData('endorsements', docContent, emailId)
          }

        }).catch((error) => {
            console.log('Extras query did not work', error);
            this.pullProfileData('endorsements', docContent, emailId)
        });
      } else if (dataType === 'endorsements') {
        Axios.get('/api/story', { params: { emailId } })
        .then((response) => {
          console.log('Endorsement query worked', response.data);

          if (response.data.success && response.data.stories) {

            const endorsements = response.data.stories
            let topEndorsedSkills = []

            for (let i = 1; i <= endorsements.length; i++) {
              if (endorsements[i - 1] && endorsements[i - 1].competencies && endorsements[i - 1].competencies.length > 0) {
                for (let j = 1; j <= endorsements[i - 1].competencies.length; j++) {
                  if (endorsements[i - 1].competencies[j - 1]) {
                    const name = endorsements[i - 1].competencies[j - 1].name
                    const rating = endorsements[i - 1].competencies[j - 1].rating
                    if (name && rating && !topEndorsedSkills.some(es => es.name === name)) {
                      topEndorsedSkills.push({ name, rating })
                    }
                  }
                }
              }
            }

            topEndorsedSkills.sort(function(a,b) {
              return b.rating - a.rating;
            })

            docContent['topEndorsedSkills'] = topEndorsedSkills
            this.pullProfileData('assessments', docContent, emailId)
          } else {
            this.pullProfileData('assessments', docContent, emailId)
          }

        }).catch((error) => {
            console.log('Story query did not work', error);
            this.pullProfileData('assessments', docContent, emailId)
        });
      } else if (dataType === 'assessments') {

        Axios.get('/api/assessment/results', { params: { emailId } })
        .then((response) => {
          console.log('Assessments query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved assessments')

            const wpData = response.data.results.workPreferenceAnswers
            const interestsData = response.data.results.interestScores
            const skillsData = response.data.results.skillsScores
            const skillsAnswers = response.data.results.newSkillAnswers
            skillsAnswers.sort(function(a,b) {
              return b.score - a.score;
            })

            let skills = []
            if (skillsAnswers) {
              for (let i = 1; i <= 10; i++) {
                if (skillsAnswers[i - 1] && skillsAnswers[i - 1].name) {
                  skills.push({ name: skillsAnswers[i - 1].name, score: skillsAnswers[i - 1].score})
                }
              }
            }
            console.log('show sorted answers: ', skillsAnswers, skills)

            const personalityData = response.data.results.personalityScores
            let valuesData = null
            if (response.data.results.topGravitateValues && response.data.results.topEmployerValues){
              // valuesData = response.data.results.topGravitateValues.concat(response.data.results.topEmployerValues)

              valuesData = []
              const topGravitateValues = response.data.results.topGravitateValues
              const topEmployerValues = response.data.results.topEmployerValues
              // valuesData = topGravitateValues.concat(topEmployerValues)

              for (let i = 1; i <= topGravitateValues.length; i++) {
                console.log('lopping gravitate')
                valuesData.push({ value: topGravitateValues[i - 1], valueType: 'gravitate'})
              }
              for (let i = 1; i <= topEmployerValues.length; i++) {
                console.log('lopping employers')
                valuesData.push({ value: topEmployerValues[i - 1], valueType: 'employer'})
              }
            }

            // docContent['docType'] = docType
            docContent['wpData'] = wpData
            docContent['interestsData'] = interestsData
            docContent['skillsData'] = skillsData
            docContent['skillsAnswers'] = skillsAnswers
            docContent['skills'] = skills
            docContent['personalityData'] = personalityData
            docContent['valuesData'] = valuesData

            this.finalizeDocContent(docContent)
            if (this.props.passData) {
              this.props.passData({ docContent })
            }

          } else {
            console.log('no assessments data found', response.data.message)
            this.finalizeDocContent(docContent)
          }

        }).catch((error) => {
            console.log('Assessments query did not work', error);
            this.finalizeDocContent(docContent)
        });
      }
    }

    onDragEnd(result) {
      console.log('onDragEnd called', result)

      // dropped outside the list
      if (!result.destination) {
        return;
      }
      // console.log('t2')

      // console.log('show projects: ', projects, this.state.docContent)
      if (result.source.index !== result.destination.index) {
        // this.saveApplications(result.source.index, result.destination.index, projects)

      }

      let docContent = this.state.docContent
      if (result.source.droppableId === 'docEducation') {
        const education = reorder(
          this.state.docContent.education,
          result.source.index,
          result.destination.index
        );
        docContent['education'] = education
        this.setState({ docContent });
      } else if (result.source.droppableId === 'docExperience') {
        const experience = reorder(
          this.state.docContent.experience,
          result.source.index,
          result.destination.index
        );
        docContent['experience'] = experience
        this.setState({ docContent });
      } else if (result.source.droppableId === 'docProjects') {
        const projects = reorder(
          this.state.docContent.projects,
          result.source.index,
          result.destination.index
        );
        docContent['projects'] = projects
        this.setState({ docContent });
      } else if (result.source.droppableId === 'docExtras') {
        const extras = reorder(
          this.state.docContent.extras,
          result.source.index,
          result.destination.index
        );
        docContent['extras'] = extras
        this.setState({ docContent });
      } else if (result.source.droppableId === 'docSections') {

        const selectedSections = reorder(
          this.state.selectedSections,
          result.source.index,
          result.destination.index
        );

        this.setState({ selectedSections });
      }
    }

    finalizeDocContent(docContent) {
      console.log('finalizeDocContent called', docContent)

      this.setState({
        docContent
      }, () => {

          const experienceHeight = this.getHeight('experience')
          const projectsHeight = this.getHeight('projects')
          const skillsHeight = this.getHeight('skills')
          const knowledgeHeight = this.getHeight('knowledge')
          const extrasHeight = this.getHeight('extras')
          let pageCount = 1
          if (extrasHeight) {
            if (extrasHeight > 1000) {
              pageCount = 2
            }
          }
          console.log('calculating height', experienceHeight, projectsHeight, skillsHeight, knowledgeHeight, extrasHeight)

          this.setState({ experienceHeight, projectsHeight, skillsHeight, knowledgeHeight, extrasHeight, pageCount, isLoading: false, successMessage: 'Successfully imported relevant doc information from your profile!' })

          if (docContent.docType === 'Cover Letter' || docContent.docType === 'Career Plan' || docContent.docType === 'Business Plan' || docContent.docType === 'Mock Interview' || docContent.docType === 'Goal Plan') {
            this.createNewDoc(docContent.firstName, docContent.lastName, docContent.docType)
          }
      });

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'sectionType') {
        this.setState({ [event.target.name]: event.target.value })
      } else if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else if (event.target.name === 'searchOpportunities') {
        this.searchItems(event.target.value, 'opportunity')
      } else if (event.target.name === 'searchSkills') {
        this.searchItems(event.target.value, 'skill')
      } else if (event.target.name === 'searchKnowledge') {
        this.searchItems(event.target.value, 'knowledge')
      } else if (event.target.name.includes('|')) {
        const nameArray = event.target.name.split("|")
        const fieldName1 = nameArray[0]
        const fieldName2 = nameArray[1]
        const index = Number(nameArray[2])
        let fieldName3 = ''
        if (nameArray[3]) {
          fieldName3 = nameArray[3]
        }

        let docContent = this.state.docContent
        console.log('show the values: ', nameArray, fieldName1, index, fieldName2, docContent)
        if (fieldName2 === 'bullets') {
          const index2 = Number(nameArray[3])
          docContent[fieldName1][index][fieldName2][index2] = event.target.value
        } else if (fieldName2 === 'sections') {
          docContent[fieldName2][index][fieldName3] = event.target.value
        } else {
          docContent[fieldName1][index][fieldName2] = event.target.value
        }

        this.setState({ docContent })

      } else if (event.target.name === 'score' || event.target.name === 'saveType' || event.target.name === 'docFileName') {
        this.setState({ [event.target.name]: event.target.value })
      } else if (event.target.name === 'docFileName') {
        this.setState({ [event.target.name]: event.target.value })
      } else if (event.target.name === 'firstName') {
        let docProfile = this.state.docProfile
        docProfile['firstName'] = event.target.value
        this.setState({ docProfile })
      } else if (event.target.name === 'lastName') {
        let docProfile = this.state.docProfile
        docProfile['lastName'] = event.target.value
        this.setState({ docProfile })
      } else if (event.target.name === 'grade' || event.target.name === 'gradeExplanation') {
        this.setState({ [event.target.name]: event.target.value })
      } else {
        let docContent = this.state.docContent
        docContent[event.target.name] = event.target.value
        this.setState({ docContent })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type === 'career') {
        if (!searchString || searchString === '') {
          this.setState({ searchString, searchIsAnimating: false, careerOptions: null })
        } else {
          this.setState({ searchString, searchIsAnimating: true })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimating: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  self.setState({ searchIsAnimating: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'opportunity') {
        if (!searchString || searchString === '') {
          this.setState({ searchStringOpportunities: searchString, searchIsAnimating: false, opportunityOptions: null })
        } else {
          this.setState({ searchStringOpportunities: searchString, searchIsAnimating: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const orgCode = self.state.activeOrg
            const placementPartners = self.state.placementPartners
            const accountCode = self.state.accountCode
            const search = true
            const postTypes = ['Internship','Work','Assignment','Problem','Challenge']

            Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, accountCode, search, postTypes } })
            .then((response) => {
              console.log('Opportunity search query attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity search query worked')

                  let opportunityOptions = response.data.postings
                  self.setState({ opportunityOptions, searchIsAnimating: false })

                } else {
                  console.log('opportunity search query did not work', response.data.message)

                  let opportunityOptions = []
                  self.setState({ opportunityOptions, searchIsAnimating: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'skill') {
        if (!searchString || searchString === '') {
          this.setState({ searchStringSkills: searchString, searchIsAnimating: false, skillOptions: null })
        } else {
          this.setState({ searchStringSkills: searchString, searchIsAnimating: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const types = ['General Skill','Hard Skill','Soft Skill','Ability','Knowledge','Work Style']

            Axios.get('/api/competency/search', { params: { searchString, types }})
            .then((response) => {
              console.log('Competency search query attempted', response.data);

                if (response.data.success) {
                  console.log('competency search query worked')

                  let skillOptions = response.data.competencies
                  self.setState({ skillOptions, searchIsAnimating: false })

                } else {
                  console.log('competency search query did not work', response.data.message)

                  let skillOptions = []
                  self.setState({ skillOptions, searchIsAnimating: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'knowledge') {
        if (!searchString || searchString === '') {
          this.setState({ searchStringKnowledge: searchString, searchIsAnimating: false, knowledgeOptions: null })
        } else {
          this.setState({ searchStringKnowledge: searchString, searchIsAnimating: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const types = ['Knowledge']

            Axios.get('/api/competency/search', { params: { searchString, types }})
            .then((response) => {
              console.log('Competency search query attempted', response.data);

                if (response.data.success) {
                  console.log('competency search query worked')

                  let knowledgeOptions = response.data.competencies
                  self.setState({ knowledgeOptions, searchIsAnimating: false })

                } else {
                  console.log('competency search query did not work', response.data.message)

                  let knowledgeOptions = []
                  self.setState({ knowledgeOptions, searchIsAnimating: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'career') {

        const searchObject = passedItem
        const searchString = passedItem.name
        const unready = false
        const careerOptions = null

        this.setState({ searchObject, searchString, unready, careerOptions })

      } else if (type === 'opportunity') {
        const searchObject = passedItem
        let searchStringOpportunities = passedItem.name
        if (passedItem.title) {
          searchStringOpportunities = passedItem.title
        }
        const unready = false
        const opportunityOptions = null
        console.log('show searchString: ', searchStringOpportunities, searchObject)

        this.setState({ searchObject, searchStringOpportunities, unready, opportunityOptions })
      } else if (type === 'skill') {
        const searchObject = passedItem
        let searchStringSkills = passedItem.name

        const unready = false
        const skillOptions = null

        this.setState({ searchObject, searchStringSkills, unready, skillOptions })
      } else if (type === 'knowledge') {
        const searchObject = passedItem
        let searchStringKnowledge = passedItem.name

        const unready = false
        const knowledgeOptions = null
        console.log('show searchStringKnowledge: ', searchStringKnowledge)

        this.setState({ searchObject, searchStringKnowledge, unready, knowledgeOptions })
      }
    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.selectedCareer)

      if (type === 'career') {
        const favoritedCareers = this.state.favoritedCareers

        if (favoritedCareers && favoritedCareers.length > 0) {

          return (
            <div key={"favoritedCareers"}>
              <div className="spacer" />
              {favoritedCareers.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">
                  <button className="background-button float-left right-padding-5" onClick={() => this.addObjectivesToDoc(this.state.favoritedCareerDetails[optionIndex], type)}>
                    <div className="half-spacer" />
                    <div className={(this.state.selectedCareer === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'opportunity') {
        const favoritedOpportunities = this.state.favoritedOpportunities
        if (favoritedOpportunities && favoritedOpportunities.length > 0) {
          console.log('about to in', favoritedOpportunities)
          return (
            <div key={"favoritedOpportunities"}>
              <div className="spacer" />
              {favoritedOpportunities.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">
                  <button className="background-button float-left right-padding-5" onClick={() => this.addObjectivesToDoc(this.state.favoritedOpportunityDetails[optionIndex], type)}>
                    <div className="half-spacer" />
                    <div className={(this.state.selectedOpportunity === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </button>

                </div>
              )}
            </div>
          )
        }
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'career') {
        if (this.state.favoritedCareers.includes(this.state.searchString)) {
          this.setState({ errorMessage: 'You have already added this career' })
        } else {

          const searchString = ''
          const searchObject = null
          const unready = true

          let favoritedCareers = this.state.favoritedCareers
          favoritedCareers.unshift(this.state.searchString)

          let favoritedCareerDetails = this.state.favoritedCareerDetails
          favoritedCareerDetails.unshift(this.state.searchObject)

          this.setState({ searchString, searchObject, unready, favoritedCareers, errorMessage: null })

          if (this.props.pageSource !== 'landingPage') {
            // favorite item
            this.favoriteItem(this.state.searchObject, type)
          }

          // console.log('pre-add learning: ', this.state.searchObject)
          // populate chart
          this.addObjectivesToDoc(this.state.searchObject,type)
        }
      } else if (type === 'opportunity') {
        if (this.state.favoritedOpportunities.includes(this.state.searchStringOpportunities)) {
          this.setState({ errorMessage: 'You have already added this opportunity' })
        } else {

          const searchStringOpportunities = ''
          const searchObject = null
          const unready = true

          let favoritedOpportunities = this.state.favoritedOpportunities
          favoritedOpportunities.unshift(this.state.searchStringOpportunities)

          let favoritedOpportunityDetails = this.state.favoritedOpportunityDetails
          favoritedOpportunityDetails.unshift(this.state.searchObject)

          this.setState({ searchStringOpportunities, searchObject, unready, favoritedOpportunities, errorMessage: null })

          if (this.props.pageSource !== 'landingPage') {
            // favorite item
            this.favoriteItem(this.state.searchObject, type)
          }

          // populate chart
          this.addObjectivesToDoc(this.state.searchObject,type)
        }
      } else if (type === 'project') {
        let docContent = this.state.docContent
        let projects = this.state.docContent.projects
        if (projects) {
          const projectNumber = projects.length + 1
          projects.push({ name: 'Project ' + projectNumber + ' Name' })
        } else {
          projects = [{ name: 'Project 1 Name' }]
        }
        console.log('projects show: ', projects)
        docContent['projects'] = projects
        this.setState({ docContent })
      } else if (type === 'experience') {
        let docContent = this.state.docContent
        let experience = this.state.docContent.experience
        if (experience) {
          const experienceNumber = experience.length + 1
          experience.push({ title: 'Experience ' + experienceNumber + ' Name' })
        } else {
          experience = [{ title: 'Experience 1 Name' }]
        }
        console.log('experiences show: ', experience)
        docContent['experience'] = experience
        this.setState({ docContent })
      } else if (type === 'education') {
        let docContent = this.state.docContent
        let education = this.state.docContent.education
        if (education) {
          const educationNumber = education.length + 1
          education.push({ name: 'Education ' + educationNumber + ' Name' })
        } else {
          education = [{ name: 'Education 1 Name' }]
        }
        console.log('education show: ', education)
        docContent['education'] = education
        this.setState({ docContent })
      } else if (type === 'extras') {
        let docContent = this.state.docContent
        let extras = this.state.docContent.extras
        if (extras) {
          const extraNumber = extras.length + 1
          extras.push({ name: 'Extra ' + extraNumber + ' Name' })
        } else {
          extras = [{ name: 'Extra 1 Name' }]
        }

        docContent['extras'] = extras
        this.setState({ docContent })
      } else if (type === 'section') {
        let docContent = this.state.docContent
        if (this.state.selectionType === '') {

        }
        this.setState({ docContent })

      } else if (type === 'skill') {

        if (this.state.docContent.skills && this.state.docContent.skills.includes(this.state.searchStringSkills)) {
          this.setState({ errorMessage: 'You have already added this skill' })
        } else {

          const searchStringSkills = ''
          const searchObject = null
          const unready = true

          let docContent = this.state.docContent
          let skills = this.state.docContent.skills
          if (skills) {
            skills.push({ name: this.state.searchStringSkills, score: this.state.score })
          } else {
            skills = [{ name: this.state.searchStringSkills, score: this.state.score }]
          }
          docContent['skills'] = skills
          console.log('show relevant skills: ', skills, this.state.searchObject)

          this.setState({ searchStringSkills, searchObject, unready, docContent, errorMessage: null })
        }
      } else if (type === 'knowledge') {

        if (this.state.docContent.knowledge && this.state.docContent.knowledge.includes(this.state.searchStringKnowledge)) {
          this.setState({ errorMessage: 'You have already added this knowledge' })
        } else {

          const searchStringKnowledge = ''
          const searchObject = null
          const unready = true

          let docContent = this.state.docContent
          if (this.state.docContent.knowledge) {
            let knowledge = this.state.docContent.knowledge
            knowledge.push({ name: this.state.searchStringKnowledge, score: this.state.score })
            docContent['knowledge'] = knowledge
          } else {
            const knowledge = [{ name: this.state.searchStringKnowledge, score: this.state.score }]
            docContent['knowledge'] = knowledge
            console.log('show knowledge: ', knowledge)
          }

          this.setState({ searchStringKnowledge, searchObject, unready, docContent, errorMessage: null })

        }
      }
    }

    deleteItem(type, index) {
      console.log('deleteItem called', type, index)

      let docContent = this.state.docContent
      if (type === 'project') {
        let projects = this.state.docContent.projects
        projects.splice(index,1)
        docContent['projects'] = projects
      } else if (type === 'experience') {
        let experience = this.state.docContent.experience
        experience.splice(index,1)
        docContent['experience'] = experience
      } else if (type === 'education') {
        let education = this.state.docContent.education
        education.splice(index,1)
        docContent['education'] = education
      } else if (type === 'extras') {
        let extras = this.state.docContent.extras
        extras.splice(index,1)
        docContent['extras'] = extras
      }

      this.setState({ docContent })
    }

    selectItem(value) {
      console.log('selectItem called', value)

      let selectedSections = this.state.selectedSections
      if (selectedSections.includes(value)) {
        const index = selectedSections.indexOf(value)
        selectedSections.splice(index, 1)
      } else {
        selectedSections.push(value)
      }
      this.setState({ selectedSections })
    }

    createNewDoc(firstName, lastName, docType) {
      console.log('createNewDoc called', firstName, lastName, docType)

      let docContent = {}
      if (docType === 'Goal Plan') {

        const summaryPlaceholder  = 'Provide an overview or summary of your plan.'
        // const detailsPlaceholder = 'Provide some details of your plan, including the timeline and major milestones for your plan.'
        // const lifestylePlaceholder = "Detail your ideal lifestyle, including how much money you estimate you'll need to live."
        // const fuelPlaceholder = "Detail the inciting incidents in the story of your life that have led you to this plan. Detail your motivations."
        // const competenciesPlaceholder = "Detail the competencies required for employment to be successful executing your career plan. Outline how you stack up against these competencies. How will you continue to upskill?"
        // const advantagePlaceholder = "Detail your competitive standing and competitive advantage for achieving your career plan."
        // const strategyPlaceholder = "Detail your strategy for reaching and impressing key employers. For entrepreneurship, employers are customers and funders."
        // const advocatesPlaceholder = "Detail a few initial advocates, as well as a strategy for gaining advocates as you work through your career plan."
        // const financialsPlaceholder = "Project your income, expenses, net income, assets, liabilities, and net worth for each year over the relevant time horizon."
        // const stepsPlaceholder = "Provide a step by step playbook for achieving your end goal"
        // const appendicesPlaceholder = "Add supplementary information that led to this plan, including passions, career assessment results, endorsements, favorites, and feedback from others."

        const sections = [
          { title: 'Summary', body: summaryPlaceholder, placeholder: summaryPlaceholder },
          // { title: 'Details', body: detailsPlaceholder, placeholder: detailsPlaceholder },
          // { title: 'Lifestyle', body: lifestylePlaceholder, placeholder: lifestylePlaceholder },
          // { title: 'Fuel / Motivations', body: fuelPlaceholder, placeholder: fuelPlaceholder },
          // { title: 'Competencies', body: competenciesPlaceholder, placeholder: competenciesPlaceholder },
          // { title: 'Competitive Advantage', body: advantagePlaceholder, placeholder: advantagePlaceholder },
          // { title: 'Employer Strategy', body: strategyPlaceholder, placeholder: strategyPlaceholder },
          // { title: 'Advocates', body: advocatesPlaceholder, placeholder: advocatesPlaceholder },
          // { title: 'Financials', body: financialsPlaceholder, placeholder: financialsPlaceholder },
          // { title: 'Steps', body: stepsPlaceholder, placeholder: stepsPlaceholder },
          // { title: 'Appendices', body: appendicesPlaceholder, placeholder: appendicesPlaceholder },
        ]

        docContent = {
          currentDate: convertDateToString(new Date(),'date-2'),
          title: 'Name of My Goal Plan',
          creatorFirstName: firstName, creatorLastName: lastName, sections
        }
      } else if (docType === 'Resume') {
        docContent['firstName'] = this.state.docContent.firstName
        docContent['lastName'] = this.state.docContent.lastName
        docContent['email'] = this.state.docContent.email
      } else if (docType === 'Cover Letter') {
        docContent = {
          currentDate: convertDateToString(new Date(),'date-formal'),
          recipientFirstName: 'Jon', recipientLastName: 'Doe',
          recipientOrg: 'Google', addressLine1: '600 Amphitheatre Parkway',
          city: 'Mountain View', state: 'CA', zipCode: '90043',
          senderFirstName: firstName, senderLastName: lastName, senderEmail: this.state.docContent.email,
          textBody: 'Write the body of the cover letter here...',
        }
      } else if (docType === 'Career Plan') {
        const summaryPlaceholder  = 'Provide an overview or summary of your plan.'
        // const detailsPlaceholder = 'Provide some details of your plan, including the timeline and major milestones for your plan.'
        const lifestylePlaceholder = "Detail your ideal lifestyle, including how much money you estimate you'll need to live."
        const fuelPlaceholder = "Detail the inciting incidents in the story of your life that have led you to this plan. Detail your motivations."
        const competenciesPlaceholder = "Detail the competencies required for employment to be successful executing your career plan. Outline how you stack up against these competencies. How will you continue to upskill?"
        const advantagePlaceholder = "Detail your competitive standing and competitive advantage for achieving your career plan."
        const strategyPlaceholder = "Detail your strategy for reaching and impressing key employers. For entrepreneurship, employers are customers and funders."
        const advocatesPlaceholder = "Detail a few initial advocates, as well as a strategy for gaining advocates as you work through your career plan."
        const financialsPlaceholder = "Project your income, expenses, net income, assets, liabilities, and net worth for each year over the relevant time horizon."
        const stepsPlaceholder = "Provide a step by step playbook for achieving your end goal"
        const appendicesPlaceholder = "Add supplementary information that led to this plan, including passions, career assessment results, endorsements, favorites, and feedback from others."

        const sections = [
          { title: 'Summary', body: summaryPlaceholder, placeholder: summaryPlaceholder },
          // { title: 'Details', body: detailsPlaceholder, placeholder: detailsPlaceholder },
          { title: 'Lifestyle', body: lifestylePlaceholder, placeholder: lifestylePlaceholder },
          { title: 'Fuel / Motivations', body: fuelPlaceholder, placeholder: fuelPlaceholder },
          { title: 'Competencies', body: competenciesPlaceholder, placeholder: competenciesPlaceholder },
          { title: 'Competitive Advantage', body: advantagePlaceholder, placeholder: advantagePlaceholder },
          { title: 'Employer Strategy', body: strategyPlaceholder, placeholder: strategyPlaceholder },
          { title: 'Advocates', body: advocatesPlaceholder, placeholder: advocatesPlaceholder },
          { title: 'Financials', body: financialsPlaceholder, placeholder: financialsPlaceholder },
          { title: 'Steps', body: stepsPlaceholder, placeholder: stepsPlaceholder },
          { title: 'Appendices', body: appendicesPlaceholder, placeholder: appendicesPlaceholder },
        ]

        docContent = {
          currentDate: convertDateToString(new Date(),'date-2'),
          title: 'Name of My Career Plan',
          creatorFirstName: firstName, creatorLastName: lastName, sections
        }
      } else if (docType === 'Business Plan') {
        const summaryPlaceholder  = 'Provide an executive summary of your business plan, define key metrics, and detail goals.'
        const problemPlaceholder = "Clearly identify the problem that you're solving."
        const solutionPlaceholder = "Detail your solution to the problem, including the products and services."
        const marketPlaceholder = "Detail the market, the market size, and the market growth rate."
        const advantagePlaceholder = "Detail your competitive advantage for competing in this market."
        const strategyPlaceholder = "Detail your go-to-market strategy, and how you will reach your target customers."
        const modelPlaceholder = "Detail your business model. How will you make money?"
        const metricsPlaceholder = "Detail your key performance metrics. How will you measure success in 1,3, and 5 years?"
        const financialsPlaceholder = "Project your income, expenses, net income, assets, liabilities, and equity over 1, 3, and 5 years."
        const teamPlaceholder = "Detail an overview of your team and why they are the ideal team to solve this problem."
        const stepsPlaceholder = "Provide a step by step playbook for achieving your end goal."

        const sections = [
          { title: 'Executive Summary', body: summaryPlaceholder, placeholder: summaryPlaceholder },
          { title: 'Problem', body: problemPlaceholder, placeholder: problemPlaceholder },
          { title: 'Solution', body: solutionPlaceholder, placeholder: solutionPlaceholder },
          { title: 'Market', body: marketPlaceholder, placeholder: marketPlaceholder },
          { title: 'Competitive Advantage', body: advantagePlaceholder, placeholder: advantagePlaceholder },
          { title: 'Go to Market Strategy', body: strategyPlaceholder, placeholder: strategyPlaceholder },
          { title: 'Business Model', body: modelPlaceholder, placeholder: modelPlaceholder },
          { title: 'Financials', body: financialsPlaceholder, placeholder: financialsPlaceholder },
          { title: 'Team', body: teamPlaceholder, placeholder: teamPlaceholder },
          { title: 'Steps', body: stepsPlaceholder, placeholder: stepsPlaceholder },
        ]

        docContent = {
          currentDate: convertDateToString(new Date(),'date-2'),
          title: 'Name of My Business', subtitle: 'Uber for Kids',
          creatorFirstName: firstName, creatorLastName: lastName, sections
        }
      } else if (docType === 'Mock Interview') {

        const q1Placeholder  = "My answer to the first interview question..."
        const q2Placeholder = "My answer to the second interview question..."
        const q3Placeholder = "My answer to the third interview question..."

        const sections = [
          { title: 'Q1: My First Interview Question', body: q1Placeholder, placeholder: q1Placeholder },
          { title: 'Q2: My Second Interview Question', body: q2Placeholder, placeholder: q2Placeholder },
          { title: 'Q3: My Third Interview Question', body: q3Placeholder, placeholder: q3Placeholder },
        ]

        const jobTitle = "Software Engineer"
        const employerName = "Google"

        docContent = {
          currentDate: convertDateToString(new Date(),'date-2'),
          title: 'Name of My Mock Interview Doc', jobTitle, employerName,
          creatorFirstName: firstName, creatorLastName: lastName, sections
        }
      }

      this.setState({ docContent, successMessage: 'Successfully cleared the document!'})

    }

    importProfile(emailId, docType) {
      console.log('importProfile called', emailId, docType)

      this.setState({ isLoading: true, succesMessage: null, errorMessage: null })

      Axios.get('/api/users/profile/details/' + emailId)
      .then((response) => {
        if (response.data.success) {

          const responseData = response.data

          let startDate = new Date().getFullYear()
          if (responseData.user.gradYear) {
            startDate = responseData.user.gradYear - 4
          }

          let docContent = {}
          docContent['docType'] = docType
          docContent['firstName'] = responseData.user.firstName
          docContent['lastName'] = responseData.user.lastName
          docContent['email'] = responseData.user.email
          docContent['pictureURL'] = responseData.user.pictureURL
          docContent['summary'] = responseData.user.resumeSummary
          docContent['phoneNumber'] = responseData.user.phoneNumber
          docContent['linkedInURL'] = responseData.user.linkedInURL
          docContent['customWebsiteURL'] = responseData.user.customWebsiteURL
          if (responseData.user.education && responseData.user.education.length > 0) {
            docContent['education'] = responseData.user.education
          } else {
            docContent['education'] = [{ startDate, endDate: responseData.user.gradYear, name: responseData.user.school }]
          }
          // console.log('----!!! educ', responseData.user.education)

          this.pullProfileData('experience', docContent, emailId)

        } else {
          this.setState({ isLoading: false })
        }
      }).catch((error) => {
          console.log('Profile pic fetch did not work', error);
          this.setState({ isLoading: false })
      });
    }

    generateDoc() {
      console.log('generateDoc called')

      let richDescription = null
      let blocks = []

      const name = 'Creighton Taylor'
      const email = 'creightontaylor@gmail.com'
      blocks.push({ key: Math.random(), text: name, type: 'unstyled',depth: 0, entityRanges: [], data: { 'text-align': 'center', 'line-height': '75%'}, inlineStyleRanges: [{ offset: 0, length: name.length, style: 'BOLD'}, { offset: 0, length: name.length, style: 'fontsize-30'}]})
      blocks.push({ key: Math.random(), text: email, type: 'unstyled',depth: 0, entityRanges: [], data: { 'text-align': 'center'}, inlineStyleRanges: []})
      richDescription = { blocks, entityMap: {}}
      this.child.current.focusEditor();

      this.setState({ richDescription })
    }

    indicateFormChange(formHasChanged) {
      console.log('indicateFormChange ', formHasChanged)
      this.setState({ formHasChanged })
    }

    saveDoc(updateProfile) {
      console.log('saveDoc called', updateProfile)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.saveType || this.state.saveType === '') {
        this.setState({ errorMessage: 'Please add whether you would like to save as a new file or existing file' })
      } else if (!this.state.docFileName || this.state.docFileName === '') {
        this.setState({ errorMessage: 'Please add a file name for the document' })
      } else {

        const email= this.state.docProfile.email
        const firstName = this.state.docProfile.firstName
        const lastName = this.state.docProfile.lastName
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        const orgCode = this.state.activeOrg

        const docType = this.state.docType
        let slug = ''
        if (docType === 'Goal Plan') {
          slug = 'goal-plans'
        } else if (docType === 'Resume') {
          slug = 'resumes'
        } else if (docType === "Cover Letter") {
          slug = 'cover-letters'
        } else if (docType === "Career Plan") {
          slug = 'career-plans'
        } else if (docType === "Financial Plan") {
          slug = 'financial-plans'
        } else if (docType === "Business Plan") {
          slug = 'business-plans'
        } else if (docType === "Mock Interview") {
          slug = 'mock-interviews'
        }

        const fileName = this.state.docFileName
        const selectedSections = this.state.selectedSections

        const docContent = this.state.docContent
        let fileId = this.state.fileId
        if (this.state.documents && this.state.documents.length > 0) {
          for (let i = 1; i <= this.state.documents.length; i++) {
            if (this.state.documents[i - 1].fileName === this.state.docFileName) {
              fileId = this.state.documents[i - 1]._id
            }
          }
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        let docSaveObject = {
          email, firstName, lastName, username, pictureURL, roleName, orgCode,
          docType, slug, fileName, selectedSections,
          docContent, fileId, createdAt, updatedAt
        }

        Axios.post('/api/documents/save', docSaveObject)
        .then((response) => {
          console.log('attempting to save document')
          if (response.data.success) {
            console.log('saved document', response.data)

            this.setState({ successMessage: response.data.message, isSaving: false })

            if (!fileId) {
              docSaveObject['_id'] = response.data._id
              let documents = this.state.documents
              if (documents) {
                documents.push(docSaveObject)
              } else {
                documents = [docSaveObject]
              }

              if (this.props.passData) {
                this.props.passData({ savedItems: documents })
              }
            }

            if (updateProfile) {
              const emailId = this.state.docProfile.email
              const resumeSummary = this.state.docContent.summary
              const phoneNumber = this.state.docContent.phoneNumber
              const linkedInURL = this.state.docContent.linkedInURL
              const customWebsiteURL = this.state.docContent.customWebsiteURL
              const education = this.state.docContent.education

              let school = null
              let degree = null
              let major = null
              let gradYear = null
              if (education && education.length > 0) {
                let selectedEducation = null
                for (let i = 1; i <= education.length; i++) {
                  if (education[i - 1].isContinual) {
                    selectedEducation = education[i - 1]
                  } else if (education[i - 1].endDate && education[i - 1].endDate.split(" ")) {
                    const endYear = Number(education[i - 1].endDate.split(" ")[1])
                    console.log('show endYear: ', endYear)
                    if (!selectedEducation) {
                      selectedEducation = education[i - 1]
                    } else if (endYear > Number(selectedEducation.endDate.split(" ")[1])) {
                      selectedEducation = education[i - 1]
                    }
                  }
                }
                if (selectedEducation) {
                  school = selectedEducation.name
                  degree = selectedEducation.degree
                  major = selectedEducation.major
                  if (selectedEducation.endDate) {
                    const endYear = Number(selectedEducation.endDate.split(" ")[1])
                    gradYear = endYear
                    console.log('show gradYear: ', gradYear)
                  }
                }
              }

              Axios.post('/api/users/profile/details', {
                emailId, resumeSummary, phoneNumber, linkedInURL, customWebsiteURL, education, school, degree, major, gradYear
              })
              .then((response) => {
                console.log('attempting to update profile')
                if (response.data.success) {
                  console.log('successfully updated profile', response.data)

                  this.setState({ successMessage: 'Successfully saved document and updated profile', isSaving: false })

                } else {
                  console.log('did not save successfully')
                  this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                }
              }).catch((error) => {
                  console.log('save did not work', error);
                  this.setState({ errorMessage: 'there was an error updating profile', isSaving: false })
              });

              // experience, projects, extras - update in bulk
              if (this.state.docContent.experience && this.state.docContent.experience.length > 0) {
                for (let i = 1; i <= this.state.docContent.experience.length; i++) {

                  const emailId = this.state.docProfile.email

                  const _id = this.state.docContent.experience[i - 1]._id
                  const userFirstName = this.state.docProfile.firstName
                  const userLastName = this.state.docProfile.lastName
                  const jobTitle = this.state.docContent.experience[i - 1].title
                  const employerName = this.state.docContent.experience[i - 1].employerName
                  const startDate = this.state.docContent.experience[i - 1].startDate
                  const endDate = this.state.docContent.experience[i - 1].endDate
                  const isContinual = this.state.docContent.experience[i - 1].isContinual
                  const jobFunction = this.state.docContent.experience[i - 1].jobFunction
                  const industry = this.state.docContent.experience[i - 1].industry

                  const resumeSummary = this.state.docContent.experience[i - 1].summary
                  let description = this.state.docContent.experience[i - 1].description
                  if (!description) {
                    description = resumeSummary
                  }
                  const location = this.state.docContent.experience[i - 1].location
                  const bullets = this.state.docContent.experience[i - 1].bullets
                  const orgCode = this.state.activeOrg

                  Axios.post('/api/experience', {
                    emailId, userFirstName, userLastName, _id, jobTitle, employerName, startDate, endDate, isContinual,
                    jobFunction, industry,
                    resumeSummary, description, location, bullets, orgCode
                  })
                  .then((response) => {
                    console.log('attempting to bulk update experience')
                    if (response.data.success) {
                      console.log('successfully updated experience', response.data)

                      // this.setState({ successMessage: response.data.message, isSaving: false })

                    } else {
                      console.log('did not save experience successfully')
                      this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                    }
                  }).catch((error) => {
                      console.log('save did not work', error);
                      this.setState({ errorMessage: 'there was an error bulk updating experience', isSaving: false })
                  });
                }
              }

              if (this.state.docContent.projects && this.state.docContent.projects.length > 0) {
                for (let i = 1; i <= this.state.docContent.projects.length; i++) {

                  const emailId = this.state.docProfile.email
                  const userFirstName = this.state.docProfile.firstName
                  const userLastName = this.state.docProfile.lastName
                  const userPic = this.state.docContent.pictureURL

                  const _id = this.state.docContent.projects[i - 1]._id
                  const name = this.state.docContent.projects[i - 1].name
                  const url = this.state.docContent.projects[i - 1].url
                  const startDate = this.state.docContent.projects[i - 1].startDate
                  const endDate = this.state.docContent.projects[i - 1].endDate
                  const isContinual = this.state.docContent.projects[i - 1].isContinual
                  const category = this.state.docContent.projects[i - 1].category
                  const hours = this.state.docContent.projects[i - 1].hours

                  const resumeSummary = this.state.docContent.projects[i - 1].summary
                  let description = this.state.docContent.projects[i - 1].description
                  if (!description) {
                    description = resumeSummary
                  }

                  const location = this.state.docContent.projects[i - 1].location
                  const bullets = this.state.docContent.projects[i - 1].bullets
                  const orgCode = this.state.activeOrg

                  Axios.post('/api/projects', {
                    emailId, userFirstName, userLastName, userPic,
                    _id, name, url, startDate, endDate, isContinual, category, hours,
                    resumeSummary, description, location, bullets, orgCode
                  })
                  .then((response) => {
                    console.log('attempting to bulk update projects')
                    if (response.data.success) {
                      console.log('successfully updated projects', response.data)

                      // this.setState({ successMessage: response.data.message, isSaving: false })

                    } else {
                      console.log('did not save projects successfully')
                      this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                    }
                  }).catch((error) => {
                      console.log('save did not work', error);
                      this.setState({ errorMessage: 'there was an error bulk updating projects', isSaving: false })
                  });
                }
              }

              if (this.state.docContent.extras && this.state.docContent.extras.length > 0) {
                for (let i = 1; i <= this.state.docContent.extras.length; i++) {

                  const emailId = this.state.docProfile.email
                  const userFirstName = this.state.docProfile.firstName
                  const userLastName = this.state.docProfile.lastName
                  const userPic = this.state.docContent.pictureURL

                  const _id = this.state.docContent.extras[i - 1]._id
                  const name = this.state.docContent.extras[i - 1].name
                  const startDate = this.state.docContent.extras[i - 1].startDate
                  const endDate = this.state.docContent.extras[i - 1].endDate
                  const isContinual = this.state.docContent.extras[i - 1].isContinual

                  const type = this.state.docContent.extras[i - 1].type
                  const activityName = this.state.docContent.extras[i - 1].activityName
                  const roleName = this.state.docContent.extras[i - 1].roleName
                  const awardDate = this.state.docContent.extras[i - 1].awardDate

                  const resumeSummary = this.state.docContent.extras[i - 1].summary
                  let description = this.state.docContent.extras[i - 1].description
                  if (!description) {
                    description = resumeSummary
                  }

                  const location = this.state.docContent.extras[i - 1].location
                  const bullets = this.state.docContent.extras[i - 1].bullets
                  const orgCode = this.state.activeOrg

                  Axios.post('/api/extras', {
                    emailId, userFirstName, userLastName, userPic,
                    _id, name, startDate, endDate, isContinual,
                    type, activityName, roleName, awardDate,
                    resumeSummary, description, location, bullets, orgCode
                  })
                  .then((response) => {
                    console.log('attempting to bulk update extras')
                    if (response.data.success) {
                      console.log('successfully updated extras', response.data)

                      // this.setState({ successMessage: response.data.message, isSaving: false })

                    } else {
                      console.log('did not save extras successfully')
                      this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
                    }
                  }).catch((error) => {
                      console.log('save did not work', error);
                      this.setState({ errorMessage: 'there was an error bulk updating extras', isSaving: false })
                  });
                }
              }


            }

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving document', isSaving: false })
        });

      }
    }

    exportFile() {
      console.log('exportFile called')

      let pageCount = this.state.pageCount

      let returnedValue = subExportFile(this.state.docType.toLowerCase(),this.state.disableExport, this.state.docFileName, this.state.docProfile, pageCount)

      if (returnedValue && returnedValue.passedData) {
        this.setState(returnedValue.passedData)
      }

      this.closeModal()

    }

    renderDocument() {
      console.log('renderDocument called')

      return (
        <div key="page1">
          {(this.state.showIdealCandidate) && (
            <div>
              <div className="row-20">
                <hr className="horizontal-margin-5-percent"/>
              </div>

              <div className="widget-container-left">
                <div className="row-10">
                  <div className="calc-column-offset-50">
                    <p className={this.state.sectionHeaderTextClass}>Ideal Candidate</p>
                  </div>
                  <div className="fixed-column-50">
                    <button className="background-button" onClick={() => this.setState({ showIdealCandidate: false })}>
                      <div className="half-spacer" />
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Close ideal candidate widget"/>
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  {this.renderTags('learningObjective')}
                  <div className="clear" />
                </div>
                <ReactTooltip id="tooltip-placeholder-id" />
              </div>

            </div>
          )}

          {(this.state.docType === 'Goal Plan' && this.state.docContent) && (
            <SubRenderGoalPlan
              showIdealCandidate={this.state.showIdealCandidate}
              sideSectionClass={this.state.sideSectionClass}
              goalPlanContent={this.state.docContent} goalPlanProfile={this.state.docProfile}
              pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
          {(this.state.docType === 'Resume' && this.state.docContent) && (
            <SubRenderResume
              showIdealCandidate={this.state.showIdealCandidate} layoutSelected="Traditional"
              sideSectionClass={this.state.sideSectionClass}
              resumeContent={this.state.docContent} resumeProfile={this.state.docProfile}
              profilePicImage={this.state.profilePicImage} profilePicPath={this.state.profilePicPath}
              selectedSections={this.state.selectedSections} pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
          {(this.state.docType === 'Cover Letter' && this.state.docContent) && (
            <SubRenderCoverLetter
              showIdealCandidate={this.state.showIdealCandidate}
              sideSectionClass={this.state.sideSectionClass}
              coverLetterContent={this.state.docContent} coverLetterProfile={this.state.docProfile}
              pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
          {(this.state.docType === 'Career Plan' && this.state.docContent) && (
            <SubRenderCareerPlan
              showIdealCandidate={this.state.showIdealCandidate}
              sideSectionClass={this.state.sideSectionClass}
              careerPlanContent={this.state.docContent} careerPlanProfile={this.state.docProfile}
              pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
          {(this.state.docType === 'Business Plan' && this.state.docContent) && (
            <SubRenderBusinessPlan
              showIdealCandidate={this.state.showIdealCandidate}
              sideSectionClass={this.state.sideSectionClass}
              businessPlanContent={this.state.docContent} businessPlanProfile={this.state.docProfile}
              pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
          {(this.state.docType === 'Mock Interview' && this.state.docContent) && (
            <SubRenderMockInterviewDoc
              showIdealCandidate={this.state.showIdealCandidate}
              sideSectionClass={this.state.sideSectionClass}
              mockInterviewContent={this.state.docContent} mockInterviewProfile={this.state.docProfile}
              pageCount={this.state.pageCount}
              sectionHeaderDivClass={this.state.sectionHeaderDivClass} sectionHeaderTextClass={this.state.sectionHeaderTextClass}
              mainSectionClass={this.state.mainSectionClass} headerClass={this.state.headerClass}
              projectsHeight={this.state.projectsHeight} skillsHeight={this.state.skillsHeight}
              knowledgeHeight={this.state.knowledgeHeight} extrasHeight={this.state.extrasHeight}

              formChangeHandler={this.formChangeHandler} passData={this.passData}
            />
          )}
        </div>
      )
    }

    getHeight(source) {
      console.log('getHeight called', source)

      let docHeight = 50

      if (document.getElementById('summary')) {
        console.log('summary 1')
        docHeight = docHeight + document.getElementById('summary').clientHeight
        console.log('summary 2', docHeight)
      }
      if (document.getElementById('education')) {
        docHeight = docHeight + document.getElementById('education').clientHeight
      }

      if (source === 'experience') {
        if (document.getElementById('experience')) {
          docHeight = docHeight + document.getElementById('experience').clientHeight
        }
      } else if (source === 'projects') {
        if (document.getElementById('experience')) {
          docHeight = docHeight + document.getElementById('experience').clientHeight
        }
        if (document.getElementById('projects')) {
          docHeight = docHeight + document.getElementById('projects').clientHeight
        }
      } else if (source === 'skills') {
        if (document.getElementById('experience')) {
          docHeight = docHeight + document.getElementById('experience').clientHeight
        }
        if (document.getElementById('projects')) {
          docHeight = docHeight + document.getElementById('projects').clientHeight
        }
        if (document.getElementById('skills')) {
          docHeight = docHeight + document.getElementById('skills').clientHeight
        }
      } else if (source === 'knowledge') {
        if (document.getElementById('experience')) {
          docHeight = docHeight + document.getElementById('experience').clientHeight
        }
        if (document.getElementById('projects')) {
          docHeight = docHeight + document.getElementById('projects').clientHeight
        }
        if (document.getElementById('skills')) {
          docHeight = docHeight + document.getElementById('skills').clientHeight
        }
        if (document.getElementById('knowledge')) {
          docHeight = docHeight + document.getElementById('knowledge').clientHeight
        }
      } else if (source === 'extras') {
        if (document.getElementById('experience')) {
          docHeight = docHeight + document.getElementById('experience').clientHeight
        }
        if (document.getElementById('projects')) {
          docHeight = docHeight + document.getElementById('projects').clientHeight
        }
        if (document.getElementById('skills')) {
          docHeight = docHeight + document.getElementById('skills').clientHeight
        }
        if (document.getElementById('knowledge')) {
          docHeight = docHeight + document.getElementById('knowledge').clientHeight
        }
        if (document.getElementById('extras')) {
          docHeight = docHeight + document.getElementById('extras').clientHeight
        }
      }

      return docHeight

    }

    closeModal() {
      console.log('closeModal called in EditDocument')

      this.setState({ modalIsOpen: false, editBasicInfo: false, editSummary: false, editEducation: false,
        editExperience: false, editProjects: false, editSkills: false, editKnowledge: false, editExtras: false,
        editCurrentDate: false, editRecipientInfo: false, editTextBody: false, editSignatureInfo: false,
        editTitleSection: false, editSection: false,
        addSection: false, showDisableExportText: false,
        showFile: false, selectedFile: null, showConfirmExport: false,
        showPromptDesigner: false, showGradePrompt: false
      });

      if (this.props.closeModal) {
        this.props.closeModal()
      }
    }

    toggleToolbar(type) {
      console.log('toggleToolbar called', type)

      if (type === 'career') {
        if (this.state.showCareerToolbar) {
          this.setState({ showCareerToolbar: false })
        } else {
          this.setState({ showCareerToolbar: true, showOpportunityToolbar: false, showTutorialToolbar: false, showGradeToolbar: false, showSaveToolbar: false, showOpenToolbar: false })
        }
      } else if (type === 'opportunity') {
        if (this.state.showOpportunityToolbar) {
          this.setState({ showOpportunityToolbar: false })
        } else {
          this.setState({ showOpportunityToolbar: true, showCareerToolbar: false, showTutorialToolbar: false, showGradeToolbar: false, showSaveToolbar: false, showOpenToolbar: false })
        }
      } else if (type === 'grade') {
        if (this.state.showGradeToolbar) {
          this.setState({ showGradeToolbar: false })
        } else {
          this.setState({ showGradeToolbar: true, showCareerToolbar: false, showOpportunityToolbar: false, showTutorialToolbar: false, showSaveToolbar: false, showOpenToolbar: false })
        }
      } else if (type === 'save') {
        if (this.state.showSaveToolbar) {
          this.setState({ showSaveToolbar: false })
        } else {
          this.setState({ showSaveToolbar: true, showCareerToolbar: false, showOpportunityToolbar: false, showTutorialToolbar: false, showGradeToolbar: false, showOpenToolbar: false })
        }
      } else if (type === 'open') {
        if (this.state.showOpenToolbar) {
          this.setState({ showOpenToolbar: false })
        } else {
          this.setState({ showOpenToolbar: true, showCareerToolbar: false, showOpportunityToolbar: false, showSaveToolbar: false, showGradeToolbar: false, showTutorialToolbar: false })
        }
      }
    }

    editDocData(type) {
      console.log('editDocData called', type)

      if (type === 'basicInfo') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <p className="heading-text-2">Edit Basic Info</p>

                <div className="bottom-padding">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Jon" name="firstName" value={this.state.docProfile.firstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Jon" name="lastName" value={this.state.docProfile.lastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <label className="profile-label">Phone Number<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="(555) 555-555" name="phoneNumber" value={this.state.docContent.phoneNumber} onChange={this.formChangeHandler} />
                  </div>

                  <div className="row-10">
                    <label className="profile-label">Portfolio Website<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="https://www..." name="customWebsiteURL" value={this.state.docContent.customWebsiteURL} onChange={this.formChangeHandler} />
                  </div>

                  <div className="row-10">
                    <label className="profile-label">LinkedIn Profile<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="https://www..." name="linkedInURL" value={this.state.docContent.linkedInURL} onChange={this.formChangeHandler} />
                  </div>
                </div>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>
          </div>
        )
      } else if (type === 'summary') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">

                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Summary</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div key={type} className="row-20">
                  <textarea className="text-field" type="text" placeholder="Add a summary" name={"summary"} value={this.state.docContent.summary} onChange={this.formChangeHandler} />
                </div>

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>
          </div>
        )
      } else if (type === 'education'){

        return (
          <div key="education">
            <div className="modal-left">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Education</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.docContent.education) && (
                  <div>
                    {this.state.docContent.education.map((item, optionIndex) =>
                      <div key={"docContent education" + optionIndex}>

                        <div className="bottom-padding">
                          <div className="row-10">
                            <p className="heading-text-5">Education #{optionIndex + 1}</p>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Name<label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="Name of School" name={"education|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler} />
                          </div>

                          <div className="edit-profile-row">
                            <div className="container-left">
                              <label className="profile-label">Degree Type<label className="error-color bold-text">*</label></label>
                              <select name={"education|degree|" + optionIndex} className="dropdown" value={item.degree} onChange={this.formChangeHandler}>
                                  {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Major / Pathway</label>
                              {(this.state.activeOrg === 'exp') ? (
                                <select name={"education|major|" + optionIndex} className="dropdown" value={item.major} onChange={this.formChangeHandler}>
                                    {this.state.pathwayOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                                </select>
                              ) : (
                                <input className="text-field" type="text" placeholder="e.g. Business Management" name={"education|major|" + optionIndex} value={item.major} onChange={this.formChangeHandler}/>
                              )}
                            </div>
                            <div className="clear" />

                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Summary</label>
                            <textarea className="text-field" name={"education|summary|" + optionIndex} placeholder="Education summary" value={item.summary} onChange={this.formChangeHandler}/>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Accomplishment Bullet Points</label>

                            {(item.bullets) && (
                              <div>
                                {item.bullets.map((item, optionIndex2) =>
                                  <input key={"experiencebullet" + optionIndex + optionIndex2} className="text-field" type="text" placeholder="add bullet point..." name={"education|bullets|" + optionIndex + '|' + optionIndex2} value={item} onChange={this.formChangeHandler} />
                                )}
                              </div>
                            )}

                            <div>
                              <div className="spacer"/><div className="spacer"/>

                              <hr className="clear-margin clear-padding"/>
                              <a className="background-button add-task-link-container" onClick={() => this.addBulletPoints('education', optionIndex)}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-34" />
                              </a>
                            </div>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Are you still at this school?</label>
                            <Switch
                              onChange={(change) => this.changeContinual(optionIndex, change,'education')}
                              checked={item.isContinual}
                              id="normal-switch"
                            />
                          </div>

                          <div className="edit-profile-row">
                            <div className="container-left">
                              <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                              <select name={"education|startDate|" + optionIndex} className="dropdown" value={item.startDate} onChange={this.formChangeHandler}>
                                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>

                            <div className="container-right">
                              <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                              <select name={"education|endDate|" + optionIndex} className="dropdown" value={item.endDate} onChange={this.formChangeHandler}>
                                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>

                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                              <input className="text-field" type="text" placeholder="(e.g., Los Angeles, CA)" name={"education|location|" + optionIndex} value={item.location} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                          <hr />
                          <div className="spacer"/>

                        </div>

                      </div>
                    )}
                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>

            <div className="fixed-side-bar-modal-left">
              <div className="row-10">
                <div className="calc-column-offset-20">
                  <p>Manage Education</p>
                </div>
                <div className="fixed-column-20">
                  <button className="background-button center-text full-width" onClick={() => this.addItem('education')}>
                    <img src={addIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a degree"/>
                  </button>
                </div>
                <div className="clear" />
              </div>

              {(this.state.docContent.education && this.state.docContent.education.length > 0) && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId="docEducation">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.docContent.education.map((item, index) => (
                          <Draggable key={item.name} draggableId={item.name} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {/*<p className="description-text-3 bold-text">{index + 1}. {item.name}</p>*/}
                                <div>
                                  <div className="fixed-column-20 top-padding-5">
                                    <img src={dragIcon} alt="GC" className="image-auto-12 left-margin-negative-10" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="description-text-3 bold-text">{index + 1}. {item.name}</p>
                                  </div>
                                  <div className="fixed-column-20">
                                    <button className="background-button full-width right-text" onClick={() => this.deleteItem('education', index)}>
                                      <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete education entry"/>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              )}
            </div>
          </div>
        )

      } else if (type === 'experience'){

        return (
          <div key="experience">
            <div className="modal-left">
              <div className="padding-40">

                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Your Experience</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.docContent.experience && this.state.docContent.experience.length > 0) && (
                  <div>
                    {this.state.docContent.experience.map((item, index) =>
                      <div key={"experience|" + index}>
                        <div className="bottom-padding">
                          <div className="row-10">
                            <p className="heading-text-5">Experience #{index + 1}</p>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="Project Title" name={"experience|title|" + index} value={this.state.docContent.experience[index].title} onChange={this.formChangeHandler} />
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="" name={"experience|employerName|" + index} value={this.state.docContent.experience[index].employerName} onChange={this.formChangeHandler} />
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Summary of Your Role</label>
                            <textarea className="text-field" name={"experience|summary|" + index} placeholder="Experience summary" value={this.state.docContent.experience[index].summary} onChange={this.formChangeHandler}/>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Accomplishment Bullet Points</label>

                            {(this.state.docContent.experience[index].bullets) && (
                              <div>
                                {this.state.docContent.experience[index].bullets.map((item, optionIndex) =>
                                  <input key={"experiencebullet" + index + optionIndex} className="text-field" type="text" placeholder="add bullet point..." name={"experience|bullets|" + index + '|' + optionIndex} value={item} onChange={this.formChangeHandler} />
                                )}
                              </div>
                            )}

                            <div>
                              <div className="spacer"/><div className="spacer"/>

                              <hr className="clear-margin clear-padding"/>
                              <a className="background-button add-task-link-container" onClick={() => this.addBulletPoints('experience', index)}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-34" />
                              </a>
                            </div>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Are you still working here?</label>
                            <Switch
                              onChange={(change) => this.changeContinual(index, change,'experience')}
                              checked={this.state.docContent.experience[index].isContinual}
                              id="normal-switch"
                            />
                          </div>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                              <select name={"experience|startDate|" + index} className="dropdown" value={this.state.docContent.experience[index].startDate} onChange={this.formChangeHandler}>
                                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>

                            {(this.state.docContent.experience[index].isContinual) ? (
                              <div className="name-container">
                                {(!this.state.isMobile) && (
                                  <div>
                                    <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                                  </div>
                                )}

                                <p className="heading-text-5">Still Working On This</p>
                              </div>
                            ) : (
                              <div className="name-container">
                                <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                                <select name={"experience|endDate|" + index} className="dropdown" value={this.state.docContent.experience[index].endDate} onChange={this.formChangeHandler}>
                                    {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                                </select>
                              </div>
                            )}

                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Job Function</label>
                              <select name={"experience|jobFunction|" + index} className="dropdown" value={this.state.docContent.experience[index].jobFunction} onChange={this.formChangeHandler}>
                                  {this.state.functionOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Job Industry</label>
                              <select name={"experience|industry|" + index} className="dropdown" value={this.state.docContent.experience[index].industry} onChange={this.formChangeHandler}>
                                  {this.state.industryOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                              <input className="text-field" type="text" placeholder="(e.g., Los Angeles, CA)" name={"experience|location|" + index} value={this.state.docContent.experience[index].location} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                          <hr />
                          <div className="spacer"/>

                        </div>

                      </div>
                    )}
                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>

            <div className="fixed-side-bar-modal-left">
              <div className="row-10">
                <div className="calc-column-offset-20">
                  <p>Manage Experience</p>
                </div>
                <div className="fixed-column-20">
                  <button className="background-button center-text full-width" onClick={() => this.addItem('experience')}>
                    <img src={addIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add an experience"/>
                  </button>
                </div>
                <div className="clear" />
              </div>

              {(this.state.docContent.experience && this.state.docContent.experience.length > 0) && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId="docExperience">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.docContent.experience.map((item, index) => (
                          <Draggable key={item.title} draggableId={item.title} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {/*<p className="description-text-3 bold-text">{index + 1}. {item.name}</p>*/}
                                <div>
                                  <div className="fixed-column-20 top-padding-5">
                                    <img src={dragIcon} alt="GC" className="image-auto-12 left-margin-negative-10" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="description-text-3 bold-text">{index + 1}. {item.title}</p>
                                  </div>
                                  <div className="fixed-column-20">
                                    <button className="background-button full-width right-text" onClick={() => this.deleteItem('experience', index)}>
                                      <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete experience"/>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              )}
            </div>
          </div>
        )

      } else if (type === 'projects'){

        return (
          <div key={type}>

            <div className="modal-left">
              <div className="padding-40">

                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Your Projects</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.docContent.projects && this.state.docContent.projects.length > 0) && (
                  <div>
                    {this.state.docContent.projects.map((item, index) =>
                      <div key={"projects|" + index}>
                        <div className="bottom-padding">
                          <div className="row-10">
                            <p className="heading-text-5">Project #{index + 1}</p>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Project Title<label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="Project Title" name={"projects|name|" + index} value={this.state.docContent.projects[index].name} onChange={this.formChangeHandler} />
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Project URL<label className="error-color bold-text">*</label></label>
                            <p className="description-text-3 bottom-padding">Add a link from your own website or a file sharing site like Google Drive that details your project.</p>

                            <input className="text-field" type="text" placeholder="" name={"projects|url|" + index} value={this.state.docContent.projects[index].url} onChange={this.formChangeHandler} />
                            <label className="error-color description-text-2">Please make sure that the link allows anyone to view the contents!</label>
                            {(this.state.docContent.projects[index].url && this.state.docContent.projects[index].url !== '' && !this.state.docContent.projects[index].url.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Project Summary</label>
                            <textarea className="text-field" name={"projects|summary|" + index} placeholder="Project summary" value={this.state.docContent.projects[index].summary} onChange={this.formChangeHandler}/>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Accomplishment Bullet Points</label>

                            {(this.state.docContent.projects[index].bullets) && (
                              <div>
                                {this.state.docContent.projects[index].bullets.map((item, optionIndex) =>
                                  <input key={"projectbullet" + index + optionIndex} className="text-field" type="text" placeholder="add bullet point..." name={"projects|bullets|" + index + '|' + optionIndex} value={item} onChange={this.formChangeHandler} />
                                )}
                              </div>
                            )}

                            <div>
                              <div className="spacer"/><div className="spacer"/>

                              <hr className="clear-margin clear-padding"/>
                              <a className="background-button add-task-link-container" onClick={() => this.addBulletPoints('projects', index)}>
                                <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-34" />
                              </a>
                            </div>
                          </div>

                          <div className="edit-profile-row">
                            <label className="profile-label">Are you still working on this project?</label>
                            <Switch
                              onChange={(change) => this.changeContinual(index, change,'project')}
                              checked={this.state.docContent.projects[index].isContinual}
                              id="normal-switch"
                            />
                          </div>

                          <div className="edit-profile-row">
                            <div className="name-container">
                              <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                              <select name={"projects|startDate|" + index} className="dropdown" value={this.state.docContent.projects[index].startDate} onChange={this.formChangeHandler}>
                                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>

                            {(this.state.docContent.projects[index].isContinual) ? (
                              <div className="name-container">
                                {(!this.state.isMobile) && (
                                  <div>
                                    <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                                  </div>
                                )}

                                <p className="heading-text-5">Still Working On This</p>
                              </div>
                            ) : (
                              <div className="name-container">
                                <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                                <select name={"projects|endDate|" + index} className="dropdown" value={this.state.docContent.projects[index].endDate} onChange={this.formChangeHandler}>
                                    {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                                </select>
                              </div>
                            )}

                            <div className="clear" />
                          </div>

                          <div className="edit-profile-row">

                            <div>
                              <div className="float-left">
                                <label className="profile-label">Competency Tags</label>
                              </div>
                              <div>
                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <div className="float-left left-margin noti-bubble-info-7-9">
                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showJobFunction: false, showIndustry: false, showMetricsInfo: false, addMetric: false, skillTagsInfo: true })}>
                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                  </a>
                                </div>

                              </div>
                              <div className="clear" />
                            </div>
                            <input className="text-field" type="text" placeholder="add skills acquired separated by commas" name={"projects|skillTags|" + index} value={this.state.docContent.projects[index].skillTags} onChange={this.formChangeHandler} />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Project Category<label className="error-color bold-text">*</label></label>
                              <select name={"projects|category|" + index} className="dropdown" value={this.state.docContent.projects[index].category} onChange={this.formChangeHandler}>
                                  {this.state.projectCategoryOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Number of HoursCommitted<label className="error-color bold-text">*</label></label>
                              <select name={"projects|hours|" + index} className="dropdown" value={this.state.docContent.projects[index].hours} onChange={this.formChangeHandler}>
                                  {this.state.hourOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                              <input className="text-field" type="text" placeholder="(e.g., Los Angeles, CA)" name={"projects|location|" + index} value={this.state.docContent.projects[index].location} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                          <hr />
                          <div className="spacer"/>

                        </div>

                      </div>
                    )}
                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>

            <div className="fixed-side-bar-modal-left">
              <div className="row-10">
                <div className="calc-column-offset-20">
                  <p>Manage Projects</p>
                </div>
                <div className="fixed-column-20">
                  <button className="background-button center-text full-width" onClick={() => this.addItem('project')}>
                    <img src={addIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a project"/>
                  </button>
                </div>
                <div className="clear" />
              </div>

              {(this.state.docContent.projects && this.state.docContent.projects.length > 0) && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId="docProjects">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.docContent.projects.map((item, index) => (
                          <Draggable key={item.name} draggableId={item.name} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {/*<p className="description-text-3 bold-text">{index + 1}. {item.name}</p>*/}
                                <div>
                                  <div className="fixed-column-20 top-padding-5">
                                    <img src={dragIcon} alt="GC" className="image-auto-12 left-margin-negative-10" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="description-text-3 bold-text">{index + 1}. {item.name}</p>
                                  </div>
                                  <div className="fixed-column-20">
                                    <button className="background-button full-width right-text" onClick={() => this.deleteItem('project', index)}>
                                      <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete project"/>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              )}
            </div>

          </div>
        )

      } else if (type === 'skills'){

        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <p className="heading-text-2">Edit Skills</p>

                <div className="row-10">
                  <div className="half-spacer" />
                  <div className="calc-column-offset-70">
                    <div className="container-left">
                      <input type="text" className="text-field" placeholder="Search a skill..." name="searchSkills" value={this.state.searchStringSkills} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <select name={"score"} className="dropdown" value={this.state.score} onChange={this.formChangeHandler}>
                          {this.state.basicCountOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="fixed-column-70 left-padding">
                    <button className="btn btn-squarish" onClick={() => this.addItem('skill')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                {(this.state.searchIsAnimating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Searching...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      {(this.state.skillOptions && this.state.skillOptions.length > 0) && (
                        <div className="card top-margin">
                          {this.state.skillOptions.map((value, optionIndex) =>
                            <div key={value._id} className="left-text bottom-margin-5 full-width">
                              <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'skill')}>
                                <div className="full-width">
                                  <div className="fixed-column-40">
                                    <div className="mini-spacer" />
                                    <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="cta-color">{value.name}</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div>

                      {this.renderTags('skills')}
                      <div className="clear" />

                    </div>

                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>
          </div>
        )
      } else if (type === 'knowledge'){
        console.log('in knowledge now', this.state.score)
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <p className="heading-text-2">Edit Knowledge</p>

                <div className="row-10">
                  <div className="half-spacer" />
                  <div className="calc-column-offset-70">
                    <div className="container-left">
                      <input type="text" className="text-field" placeholder="Search a knowledge..." name="searchKnowledge" value={this.state.searchStringKnowledge} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="container-right">
                      <select name={"score"} className="dropdown" value={this.state.score} onChange={this.formChangeHandler}>
                          {this.state.basicCountOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="fixed-column-70 left-padding">
                    <button className="btn btn-squarish" onClick={() => this.addItem('knowledge')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>
                <div className="clear" />

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                {(this.state.searchIsAnimating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <div className="super-spacer" />

                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="center-text cta-color bold-text">Searching...</p>

                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      {(this.state.knowledgeOptions && this.state.knowledgeOptions.length > 0) && (
                        <div className="card top-margin">
                          {this.state.knowledgeOptions.map((value, optionIndex) =>
                            <div key={value._id} className="left-text bottom-margin-5 full-width">
                              <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'knowledge')}>
                                <div className="full-width">
                                  <div className="fixed-column-40">
                                    <div className="mini-spacer" />
                                    <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="cta-color">{value.name}</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      {this.renderTags('knowledge')}
                      <div className="clear" />

                    </div>
                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>
          </div>
        )
      } else if (type === 'extras'){

        return (
          <div key={type}>

            <div className="modal-left">
              <div className="padding-40">

                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit My Extracurricular Activities</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.docContent.extras && this.state.docContent.extras.length > 0) && (
                  <div>
                    {this.state.docContent.extras.map((item, index) =>
                      <div key={"extras|" + index} className="bottom-padding">
                        <div className="row-10">
                          <p className="heading-text-5">Extras #{index + 1}</p>
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Extracurricular Name<label className="error-color bold-text">*</label></label>
                          <input className="text-field" type="text" placeholder="Project Title" name={"extras|name|" + index} value={this.state.docContent.extras[index].name} onChange={this.formChangeHandler} />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Extracurricular Type<label className="error-color bold-text">*</label></label>
                            <select name={"extras|type|" + index} className="dropdown" value={this.state.docContent.extras[index].type} onChange={this.formChangeHandler}>
                                {['','Extracurrricular','Award'].map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          {(item.type === 'Award') && (
                            <div className="container-right">
                              <label className="profile-label">Award Date<label className="error-color bold-text">*</label></label>
                              <input type="date" className="date-picker white-background" name={"extras|awardDate|" + index} value={this.state.docContent.extras[index].awardDate} onChange={this.formChangeHandler} />
                            </div>
                          )}

                          <div className="clear" />
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Summary</label>
                          <textarea className="text-field" name={"extras|summary|" + index} placeholder="Extra summary" value={this.state.docContent.extras[index].summary} onChange={this.formChangeHandler}/>
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Accomplishment Bullet Points</label>

                          {(this.state.docContent.extras[index].bullets) && (
                            <div>
                              {this.state.docContent.extras[index].bullets.map((item, optionIndex) =>
                                <input key={"extrabullet" + index + optionIndex} className="text-field" type="text" placeholder="add bullet point..." name={"extras|bullets|" + index + '|' + optionIndex} value={item} onChange={this.formChangeHandler} />
                              )}
                            </div>
                          )}

                          <div>
                            <div className="spacer"/><div className="spacer"/>

                            <hr className="clear-margin clear-padding"/>
                            <a className="background-button add-task-link-container" onClick={() => this.addBulletPoints('extras', index)}>
                              <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task top-margin-negative-34" />
                            </a>
                          </div>
                        </div>

                        <div className="edit-profile-row">
                          <label className="profile-label">Are you still working here?</label>
                          <Switch
                            onChange={(change) => this.changeContinual(index, change,'extras')}
                            checked={this.state.docContent.extras[index].isContinual}
                            id="normal-switch"
                          />
                        </div>

                        <div className="edit-profile-row">
                          <div className="name-container">
                            <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                            <select name={"extras|startDate|" + index} className="dropdown" value={this.state.docContent.extras[index].startDate} onChange={this.formChangeHandler}>
                                {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                            </select>
                          </div>

                          {(this.state.docContent.extras[index].isContinual) ? (
                            <div className="name-container">
                              {(!this.state.isMobile) && (
                                <div>
                                  <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                                </div>
                              )}

                              <p className="heading-text-5">Still Working On This</p>
                            </div>
                          ) : (
                            <div className="name-container">
                              <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                              <select name={"extras|endDate|" + index} className="dropdown" value={this.state.docContent.extras[index].endDate} onChange={this.formChangeHandler}>
                                  {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                              </select>
                            </div>
                          )}

                          <div className="clear" />
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                            <input className="text-field" type="text" placeholder="(e.g., Los Angeles, CA)" name={"extras|location|" + index} value={this.state.docContent.extras[index].location} onChange={this.formChangeHandler} />
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                        <hr />
                        <div className="spacer"/>

                      </div>
                    )}
                  </div>
                )}

                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>

            <div className="fixed-side-bar-modal-left">
              <div className="row-10">
                <div className="calc-column-offset-20">
                  <p>Manage Extras & Awards</p>
                </div>
                <div className="fixed-column-20 top-margin">
                  <button className="background-button center-text full-width" onClick={() => this.addItem('extras')}>
                    <img src={addIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add an extracurricular or award"/>
                  </button>
                </div>
                <div className="clear" />
              </div>

              {(this.state.docContent.extras && this.state.docContent.extras.length > 0) && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId="docExtras">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.docContent.extras.map((item, index) => (
                          <Draggable key={item.name} draggableId={item.name} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {/*<p className="description-text-3 bold-text">{index + 1}. {item.name}</p>*/}
                                <div>
                                  <div className="fixed-column-20 top-padding-5">
                                    <img src={dragIcon} alt="GC" className="image-auto-12 left-margin-negative-10" />
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <p className="description-text-3 bold-text">{index + 1}. {item.name}</p>
                                  </div>
                                  <div className="fixed-column-20">
                                    <button className="background-button full-width right-text" onClick={() => this.deleteItem('extras', index)}>
                                      <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete extra"/>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              )}
            </div>

          </div>
        )
      } else if (type === 'currentDate') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="bottom-padding">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Cover Letter Date<label className="error-color bold-text">*</label></label>
                      <input className="date-field" type="date" placeholder="Jon" name="currentDate" value={convertDateToString(new Date(this.state.docContent.currentDate),'rawDateForInput')} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            </div>
          </div>
        )
      } else if (type === 'recipientInfo') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="bottom-padding">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Recipient First Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Jon" name="recipientFirstName" value={this.state.docContent.recipientFirstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Recipient Last Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Doe" name="recipientLastName" value={this.state.docContent.recipientLastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Recipient Org<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Google" name="recipientOrg" value={this.state.docContent.recipientOrg} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Address Line 1<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="600 Amphitheatre Parkway" name="addressLine1" value={this.state.docContent.addressLine1} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Address Line 2 (e.g., Suite #)</label>
                      <input className="text-field" type="text" placeholder="Ste 400" name="addressLine2" value={this.state.docContent.addressLine2} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">State (Abbreviated)<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="CA" name="state" value={this.state.docContent.state} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Zip Code<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="90210" name="zipCode" value={this.state.docContent.zipCode} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">

                    </div>
                    <div className="clear" />
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            </div>
          </div>
        )
      } else if (type === 'textBody') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="bottom-padding">
                  <div className="row-10">
                    <label className="profile-label">Body of the Cover Letter<label className="error-color bold-text">*</label></label>
                    <textarea className="text-field" type="text" placeholder="Write the body of the cover letter here..." name="textBody" value={this.state.docContent.textBody} onChange={this.formChangeHandler} />
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            </div>
          </div>
        )
      } else if (type === 'signatureInfo') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="bottom-padding">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Your First Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Jane" name="senderFirstName" value={this.state.docContent.senderFirstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Your Last Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Schmoe" name="senderLastName" value={this.state.docContent.senderLastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            </div>
          </div>
        )
      } else if (type === 'editTitleSection') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <div className="bottom-padding">
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Title<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Jon" name="title" value={this.state.docContent.title} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Business Plan Date<label className="error-color bold-text">*</label></label>
                      <input className="date-field" type="date" placeholder="Jon" name="currentDate" value={convertDateToString(new Date(this.state.docContent.currentDate),'rawDateForInput')} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear" />
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />

              </div>
            </div>
          </div>
        )
      } else if (type === 'editSection') {
        return (
          <div key={type}>
            <div className="modal">
              <div className="padding-40">
                <div className="bottom-margin">
                  <div className="calc-column-offset-20">
                    <p className="heading-text-2">Edit Info</p>
                  </div>
                  <div className="fixed-column-20 top-margin">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-15" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.docContent.sections && this.state.docContent.sections[this.state.selectedSectionIndex]) && (
                  <div>
                    <div className="row-10">
                      <label className="profile-label">Title<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Title of the section..." name={"docContent|sections|" + this.state.selectedSectionIndex + "|title"} value={this.state.docContent.sections[this.state.selectedSectionIndex].title} onChange={this.formChangeHandler} />
                    </div>
                    <div className="row-10">
                      <label className="profile-label">Body<label className="error-color bold-text">*</label></label>
                      <textarea className="text-field" type="text" placeholder="Body of the section..." name={"docContent|sections|" + this.state.selectedSectionIndex + "|body"} value={this.state.docContent.sections[this.state.selectedSectionIndex].body} onChange={this.formChangeHandler} />
                    </div>

                    <div className="spacer" /><div className="spacer" />
                  </div>
                )}

                {(this.state.confirmDeleteSection) ? (
                  <div>
                    <p className="description-text-2 error-color bottom-margin">Are you sure you want to delete this section?</p>
                    <button className="btn btn-squarish error-background-color error-border right-margin" onClick={() => this.deleteSection()}>Yes, Delete</button>
                    <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ confirmDeleteSection: false })}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <button className="btn btn-primary right-margin" onClick={() => this.closeModal()}>Add & Exit</button>
                    <button className="btn btn-quaternary" onClick={() => this.setState({ confirmDeleteSection: true })}>Delete Section</button>
                  </div>
                )}

              </div>
            </div>
          </div>
        )
      }
    }

    addBulletPoints(type, index) {
      console.log('addBulletPoints called', type, index)

      let docContent = this.state.docContent
      let bullets = this.state.docContent[type][index].bullets
      if (bullets) {
        bullets.push('')
      } else {
        bullets = ['']
      }
      // console.log('show bullets: ', bullets)
      docContent[type][index]['bullets'] = bullets
      this.setState({ docContent })
    }

    changeContinual(index, change, type) {
      console.log('changeContinual called', index, change, type)

      let docContent = this.state.docContent
      docContent[type][index]['isContinual'] = change
      this.setState({ docContent })

    }

    // optionClicked() {
    //   console.log('optionClicked called')
    //
    //   if (this.state.favoritedCareers.includes(this.state.searchString)) {
    //     this.setState({ errorMessage: 'You have already added this career' })
    //   } else {
    //
    //     const searchString = ''
    //     const searchObject = null
    //     const unready = true
    //
    //     let favoritedCareers = this.state.favoritedCareers
    //     favoritedCareers.unshift(this.state.searchString)
    //
    //     let favoritedCareerDetails = this.state.favoritedCareerDetails
    //     favoritedCareerDetails.unshift(this.state.searchObject)
    //
    //     const selectedCareer = this.state.searchString
    //
    //     this.setState({ searchString, searchObject, unready, favoritedCareers, errorMessage: null })
    //
    //     // favorite item
    //     this.favoriteItem(this.state.searchObject)
    //
    //     // populate chart
    //     this.pullCareerAssumptions(this.state.searchObject)
    //
    //   }
    // }

    addObjectivesToDoc(selectedItem, type, subType) {
      console.log('addObjectivesToDoc called', selectedItem, type)

      if (type === 'career') {
        const selectedCareer = selectedItem.name

        let learningObjectives = []
        // console.log('in career: ')
        if (selectedItem.abilitiesArray) {
          for (let i = 1; i <= selectedItem.abilitiesArray.length; i++) {
            learningObjectives.push({
              category: 'Ability',
              name: selectedItem.abilitiesArray[i - 1].category,
              description: selectedItem.abilitiesArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.knowledgeArray) {
          for (let i = 1; i <= selectedItem.knowledgeArray.length; i++) {
            learningObjectives.push({
              category: 'Knowledge',
              name: selectedItem.knowledgeArray[i - 1].category,
              description: selectedItem.knowledgeArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.personalityData && selectedItem.personalityData.workStyles) {
          for (let i = 1; i <= selectedItem.personalityData.workStyles.length; i++) {
            learningObjectives.push({
              category: 'Soft Skill',
              name: selectedItem.personalityData.workStyles[i - 1],
            })
          }
        }
        if (selectedItem.skillsArray) {
          for (let i = 1; i <= selectedItem.skillsArray.length; i++) {
            learningObjectives.push({
              category: 'General Hard Skill',
              name: selectedItem.skillsArray[i - 1].category,
              description: selectedItem.skillsArray[i - 1].subcategories.toString(),
            })
          }
        }
        if (selectedItem.technologyArray) {
          for (let i = 1; i <= selectedItem.technologyArray.length; i++) {
            learningObjectives.push({
              category: 'Tech Skill',
              name: selectedItem.technologyArray[i - 1].name,
              description: selectedItem.technologyArray[i - 1].examples.toString(),
            })
          }
        }
        console.log('show learningObjectives: ')
        const showIdealCandidate = true
        // this.setState({ selectedCareer, learningObjectives, showIdealCandidate })
        this.setState({ selectedCareer })
        this.props.passData({ selectedIdealCandidate: selectedCareer, learningObjectives, showIdealCandidate })

      } else if (type === 'opportunity') {
        console.log('in oppObjectives')

        let selectedOpportunity = null
        if (selectedItem.title && selectedItem.title !== '') {
          selectedOpportunity = selectedItem.title
        } else {
          selectedOpportunity = selectedItem.name
        }

        this.setState({ searchIsAnimating: true })

        let benchmarkId = null
        let jobFunction = null
        if (selectedItem.benchmarkId) {
          benchmarkId = selectedItem.benchmarkId
        } else {
          if (selectedItem.jobFunction) {
            jobFunction = selectedItem.jobFunction
          } else if (selectedItem.workFunction) {
            jobFunction = selectedItem.workFunction
          } else if (selectedItem.functions && selectedItem.functions[0]) {
            jobFunction = selectedItem.functions[0]
          } else if (selectedItem.field && selectedItem.field.split("|")) {
            jobFunction = selectedItem.field.split("|")[0].trim()
          }
        }

        const showIdealCandidate = true

        Axios.get('/api/benchmarks/byid', { params: { _id: benchmarkId, jobFunction } })
        .then((response) => {
          console.log('Benchmarks query by id attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved benchmarks for individual')

              let learningObjectives = []
              if (response.data.benchmark && response.data.benchmark.skills) {
                for (let i = 1; i <= response.data.benchmark.skills.length; i++) {

                  let category = response.data.benchmark.skills[i - 1].skillType
                  if (response.data.benchmark.skills[i - 1].skillType === 'Hard Skill') {
                    category = 'General Hard Skill'
                  }

                  const name = response.data.benchmark.skills[i - 1].title
                  const description = response.data.benchmark.skills[i - 1].description

                  learningObjectives.push({ category, name, description, showIdealCandidate })
                }
              }

              // this.setState({ selectedOpportunity, selectedItem, learningObjectives, showIdealCandidate, searchIsAnimating: false })
              this.setState({ selectedOpportunity, selectedItem, searchIsAnimating: false })
              this.props.passData({ selectedIdealCandidate: selectedOpportunity, learningObjectives, showIdealCandidate })

            } else {
              console.log('no benchmarks by id data found', response.data.message)

              // this.setState({ selectedOpportunity, selectedItem, showIdealCandidate, searchIsAnimating: false })
              this.setState({ selectedOpportunity, selectedItem, searchIsAnimating: false })
              this.props.passData({ selectedIdealCandidate: selectedOpportunity, learningObjectives, showIdealCandidate })
            }

        }).catch((error) => {
            console.log('Benchmarks query by id did not work', error);
            // this.setState({ selectedOpportunity, selectedItem, showIdealCandidate, searchIsAnimating: false })
            this.setState({ selectedOpportunity, selectedItem, searchIsAnimating: false })
            this.props.passData({ selectedIdealCandidate: selectedOpportunity, learningObjectives, showIdealCandidate })
        });
      } else if (type === 'competency') {
        if (subType === 'Knowledge') {
          let selectedSections = this.state.selectedSections
          selectedSections.push('Knowledge')

          let docContent = this.state.docContent
          let knowledge = this.state.docContent.knowledge
          if (knowledge) {
            knowledge.push({ name: selectedItem.name, score: 5 })
          } else {
            knowledge = [{ name: selectedItem.name, score: 5 }]
          }
          docContent['knowledge'] = knowledge

          let learningObjectives = this.state.learningObjectives
          if (learningObjectives.some(lo => lo.name === selectedItem.name)) {
            const loIndex = learningObjectives.findIndex(lo => lo.name === selectedItem.name);
            learningObjectives.splice(loIndex, 1)
          }
          this.setState({ selectedSections, docContent, learningObjectives })
        } else {
          let selectedSections = this.state.selectedSections
          selectedSections.push('Skills')

          let docContent = this.state.docContent
          let skills = this.state.docContent.skills
          if (skills) {
            skills.push({ name: selectedItem.name, score: 5 })
          } else {
            skills = [{ name: selectedItem.name, score: 5 }]
          }
          docContent['skills'] = skills

          let learningObjectives = this.state.learningObjectives
          if (learningObjectives.some(lo => lo.name === selectedItem.name)) {
            const loIndex = learningObjectives.findIndex(lo => lo.name === selectedItem.name);
            console.log('show loIndex in skills: ', loIndex)
            learningObjectives.splice(loIndex, 1)
          }
          this.setState({ selectedSections, docContent, learningObjectives })
        }

      }
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,type)

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        if (type) {
          let itemId = item._id

          let favoritesArray = this.state.favorites

          if (favoritesArray && favoritesArray.includes(itemId)){

            let index = favoritesArray.indexOf(itemId)
            if (index > -1) {
              favoritesArray.splice(index, 1);
            }

            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.docProfile.email
            })
            .then((response) => {
              console.log('attempting to save removal from favorites')
              if (response.data.success) {
                console.log('saved removal from favorites', response.data)

                const successMessage = "Removed from " + this.state.docProfile.firstName + "'s favorites"
                this.setState({ successMessage, favorites: favoritesArray, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
            });

          } else {

            console.log('adding item: ', favoritesArray, itemId)

            if (favoritesArray) {
              favoritesArray.push(itemId)
            } else {
              favoritesArray = [itemId]
            }

            const emailId = this.state.docProfile.email

            Axios.post('/api/favorites/save', { favoritesArray, emailId })
            .then((response) => {
              console.log('attempting to save addition to favorites')
              if (response.data.success) {
                console.log('saved addition to favorites', response.data)

                const successMessage = "Saved to " + this.state.docProfile.firstName + "'s favorites"
                this.setState({ successMessage, favorites: favoritesArray, isSaving: false })

              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false})
            });
          }
        }
      }
    }

    loadDoc(docFileName, documents, docType) {
      console.log('loadDoc called', docFileName, documents, docType)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      if (!docFileName) {
        docFileName = this.state.docFileName
      }

      if (!documents) {
        documents = this.state.documents
      }

      if (!docType) {
        docType = this.state.docType
      }

      if (!docFileName || docFileName === '') {
        this.setState({ isLoading: false, errorMessage: 'Please select a file'})
      } else if (!documents || documents.length === 0) {
        this.setState({ isLoading: false, errorMessage: 'No document files found'})
      } else {

        let selectedDoc = null
        for (let i = 1; i <= documents.length; i++) {
          if (documents[i - 1].fileName === docFileName) {
            selectedDoc = documents[i - 1]
          }
        }

        if (!selectedDoc) {
          this.setState({ isLoading: false, errorMessage: 'Document file not found for some reason'})
        } else if ((selectedDoc.docType !== docType) || (this.props.subNavSelected && this.props.subNavSelected !== docType)) {
          this.setState({ isLoading: false, errorMessage: 'Document file type does not match the document loader. Please navigate to the ' + selectedDoc.docType + ' loader section.'})

          if (this.props.passData) {

            const subNavSelected = selectedDoc.docType
            // let fileTypeSingular = 'Resume'
            // let fileTypePlural = 'Resumes'

            function configureFileTypes() {
              let fileTypeSingular = 'Resume'
              let fileTypePlural = 'Resumes'
              if (subNavSelected === 'Goal Plan') {
                fileTypeSingular = 'Goal Plan'
                fileTypePlural = 'Goal Plans'
              } else if (subNavSelected === 'Career Plan') {
                fileTypeSingular = 'Career Plan'
                fileTypePlural = 'Career Plans'
              } else if (subNavSelected === 'Financial Plan') {
                fileTypeSingular = 'Financial Plan'
                fileTypePlural = 'Financial Plans'
              } else if (subNavSelected === 'Cover Letter') {
                fileTypeSingular = 'Cover Letter'
                fileTypePlural = 'Cover Letters'
              } else if (subNavSelected === 'Business Plan') {
                fileTypeSingular = 'Business Plan'
                fileTypePlural = 'Business Plans'
              } else if (subNavSelected === 'Mock Interview') {
                fileTypeSingular = 'Mock Interview'
                fileTypePlural = 'Mock Interviews'
              }

              return { fileTypeSingular, fileTypePlural }
            }

            const fileTypesObject = configureFileTypes()

            this.props.passData({ subNavSelected, fileTypeSingular: fileTypesObject.fileTypeSingular, fileTypePlural: fileTypesObject.fileTypePlural, id: selectedDoc._id })

          }
        } else {
          console.log('compare the two: ', selectedDoc.docType, docType)

          let docContent = selectedDoc.docContent
          docContent['docType'] = docType
          docContent['docName'] = selectedDoc.fileName
          docContent['firstName'] = selectedDoc.firstName
          docContent['lastName'] = selectedDoc.lastName
          docContent['email'] = selectedDoc.email

          let selectedSections = this.state.selectedSections
          if (selectedDoc.selectedSections && selectedDoc.selectedSections.length > 0) {
            selectedSections = selectedDoc.selectedSections
          }

          this.setState({ docContent, docFileName, selectedSections,
            isLoading: false, successMessage: 'Successfully loaded document!'
          })

          this.closeModal()
          // console.log('show the props: ', this.props.passData, this.state.showGrade)
          if (this.props.passData) {
          // if (this.props.passData && this.state.showGrade) {
            this.props.passData({ docContent, togglePromptDesigner: false })
          }
        }
      }
    }

    deleteDoc() {
      console.log('deleteDoc called')

      let _id = null
      let docFileNames = this.state.docFileNames
      let documents = this.state.documents
      for (let i = 0; i < this.state.documents.length; i++) {
        if (this.state.documents[i].fileName === this.state.docFileName) {
          _id = this.state.documents[i]._id
          docFileNames.splice(i + 1,1)
          documents.splice(i,1)
        }
      }

      if (!_id) {
        console.log('something went wrong')
        this.setState({ errorMessage: 'Error: something went wrong'})
      } else {
        Axios.delete('/api/documents/' + _id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('document delete worked');

            let docFileName = ''
            this.setState({ documents, docFileNames, docFileName, successMessage: "document was successfully deleted!", confirmDelete: false })

            if (this.props.passData) {
              this.props.passData({ savedItems: documents })
            }
            this.closeModal()

          } else {
            console.error('there was an error deleting the document');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      }
    }

    passData(passedData) {
      console.log('passData called', passedData)

      this.setState(passedData)
    }

    doublePassData(passedData) {
      console.log('doublePassData called', passedData)

      if (this.props.passData) {
        this.props.passData(passedData)
      }
    }

    fileClicked() {
      console.log('fileClicked called')

    }

    addSection() {
      console.log('addSection called')

      const sectionCount = this.state.docContent.sections.length + 1

      let docContent = this.state.docContent
      docContent['sections'].push({
        title: 'Section Title ' + sectionCount,
        body: 'Section body ' + sectionCount,
        placeholder: 'Section placeholder ' + sectionCount
      })

      this.setState({ docContent })

    }

    saveGrade() {
      console.log('saveGrade called')

      const fileId = this.state.docId
      const grade = this.state.grade
      const gradeExplanation = this.state.gradeExplanation
      // const docContent = this.state.docContent
      const updatedAt = new Date()

      const docSaveObject = {  fileId, grade, gradeExplanation, updatedAt }
      // const docSaveObject = { fileId, docContent, updatedAt }

      Axios.post('/api/documents/save', docSaveObject)
      .then((response) => {
        console.log('attempting to save document')
        if (response.data.success) {
          console.log('saved document', response.data)

          this.setState({ isSaving: false, successMessage: 'saved document successfully!' })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving document', isSaving: false })
      });
    }

    render() {

      let docWidth = "width-778"
      // let docWidth = "full-width"

      return (
          <div ref={this.props.innerRef}>
            {(this.state.docType === 'Financial Plan') ? (
              <div className="width-778 max-width-1400 center-item top-margin-2-percent">
                <SubFinancials passData={this.doublePassData} modalIsOpen={this.state.modalIsOpen} passedDocFile={this.state.docFile} selectedFile={this.props.selectedFile} closeModal={this.closeModal} source="Builders" />
              </div>
            ) : (
              <div>
                <div>
                  {(window.innerWidth < 768 && this.state.hideOnMobile) ? (
                    <div className="flex-container flex-center full-space">
                      <div className="horizontal-padding">
                        <div className="super-spacer" />

                        <img src={layoutIconDark} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text bold-text">Document builder has not been optimized for mobile. Please use a laptop / desktop sized screen.</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="top-padding">
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>
                          <div>
                            <div className="fixed-column-200">
                              {(this.props.pageSource !== 'landingPage' && this.state.docType === 'Resume') && (
                                <button className="btn background-button right-margin-20" onClick={() => this.importProfile(this.state.docProfile.email, this.state.docType)}><img src={profileIconDark} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Import Profile to " + this.state.docType}/></button>
                              )}
                              <button className="btn background-button right-margin-20" onClick={() => this.createNewDoc(this.state.cuFirstName, this.state.cuLastName, this.state.docType)}><img src={newDocumentIcon} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Create New Document"/></button>
                              {(this.state.docType === 'Resume') && (
                                <button className="btn background-button right-margin-20" onClick={() => this.setState({ modalIsOpen: true, addSection: true })}><img src={addIcon} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Add & Manage Sections"}/></button>
                              )}
                              {(this.state.docType === 'Career Plan' || this.state.docType === 'Business Plan' || this.state.docType === 'Goal Plan') && (
                                <button className="btn background-button right-margin-20" onClick={() => this.addSection()}><img src={addIcon} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a Section"/></button>
                              )}
                            </div>

                            {(this.props.id || this.state.docType === 'Business Plan') ? (
                              <div className="calc-column-offset-340 height-40" />
                            ) : (
                              <div className="calc-column-offset-340 center-text">
                                <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('career')}><img src={(this.state.showCareerToolbar) ? pathsIconBlue : pathsIconDark} alt="GC" className="image-auto-16" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Career Path Ideal Competencies"/></button>
                                <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('opportunity')}><img src={(this.state.showOpportunityToolbar) ? opportunitiesIconBlue : opportunitiesIconDark} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Opportunity Ideal Competencies"/></button>
                              </div>
                            )}

                            <div className="fixed-column-140">
                              <div className="float-right">
                                {(this.state.showGrade) && (
                                  <button className={"btn background-button right-margin-20"} onClick={() => this.toggleToolbar('grade')}><img src={(this.state.showGradeToolbar) ? gradesIconBlue : gradesIconDark} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(this.state.docType) ? "Prompt A.I. to grade " + this.state.docType.toLowerCase() : ""}/></button>
                                )}
                                {(this.props.pageSource !== 'landingPage') && (
                                  <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('save')}><img src={(this.state.showSaveToolbar) ? saveIconBlue : saveIconDark} alt="GC" className="image-auto-16" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Save " + this.state.docType}/></button>
                                )}
                                {(this.props.pageSource !== 'landingPage' && !this.props.id) && (
                                  <button className="btn background-button right-margin-20" onClick={() => this.toggleToolbar('open')}><img src={(this.state.showOpenToolbar) ? openIconBlue : openIconDark} alt="GC" className="image-auto-16" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Open Saved " + this.state.docType}/></button>
                                )}
                                <button className={"btn background-button"} onClick={() => this.setState({ modalIsOpen: true, showConfirmExport: true })}><img src={exportIconDark} alt="GC" className="image-auto-18" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Export " + this.state.docType}/></button>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      </div>

                      <ReactTooltip id="tooltip-placeholder-id" />

                      {(this.state.showCareerToolbar) && (
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>
                          <div className={"container-left"}>
                            <div className="half-spacer" />
                            <div className="calc-column-offset-70">
                              <input type="text" className="text-field" placeholder="Search 1,000+ career paths..." name="searchCareers" value={this.state.searchString} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="fixed-column-70 left-padding">
                              <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('career')}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                          {/*
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}*/}

                          {(this.state.searchIsAnimating) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <div className="super-spacer" />

                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Searching...</p>

                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                {(this.state.careerOptions) && (
                                  <div className="card top-margin">
                                    {this.state.careerOptions.map((value, optionIndex) =>
                                      <div key={value._id} className="left-text bottom-margin-5 full-width">
                                        <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                                          <div className="full-width">
                                            <div className="fixed-column-40">
                                              <div className="mini-spacer" />
                                              <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                            </div>
                                            <div className="calc-column-offset-40">
                                              <p className="cta-color">{value.name}</p>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                              <div>

                                {this.renderTags('career')}
                                <div className="clear" />

                              </div>

                            </div>
                          )}

                          {(this.state.selectedCareer && this.state.selectedCareer !== '') && (
                            <div className="top-padding-20">
                              {(this.props.pageSource === 'landingPage') ? (
                                <p className="description-text-2">View Full Career Profile: <a href={window.location.protocol + "//" + window.location.host + "/careers/" + this.state.selectedCareer} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/careers/" + this.state.selectedCareer}</a></p>
                              ) : (
                                <p className="description-text-2">View Full Career Profile: <a href={window.location.protocol + "//" + window.location.host + "/app/careers/" + this.state.selectedCareer} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/app/careers/" + this.state.selectedCareer}</a></p>
                              )}
                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.showOpportunityToolbar) && (
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>
                          <div className={"container-left"}>
                            <div className="half-spacer" />
                            <div className="calc-column-offset-70">
                              <input type="text" className="text-field" placeholder="Search opportunities..." name="searchOpportunities" value={this.state.searchStringOpportunities} onChange={this.formChangeHandler}></input>
                            </div>
                            <div className="fixed-column-70 left-padding">
                              <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('opportunity')}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {(this.state.searchIsAnimating) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <div className="super-spacer" />

                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Searching...</p>

                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                {(this.state.opportunityOptions) && (
                                  <div className="card top-margin">
                                    {this.state.opportunityOptions.map((value, optionIndex) =>
                                      <div key={value._id} className="left-text bottom-margin-5 full-width">
                                        <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'opportunity')}>
                                          <div className="full-width">
                                            <div className="fixed-column-40">
                                              <div className="mini-spacer" />
                                              <img src={experienceIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                            </div>
                                            <div className="calc-column-offset-40">
                                              <p className="cta-color">{(value.title) ? value.title : value.name}{value.employerName && " | " + value.employerName}</p>
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>

                              <div>

                                {this.renderTags('opportunity')}
                                <div className="clear" />

                              </div>

                            </div>
                          )}

                          {(this.state.selectedOpportunity && this.state.selectedOpportunity !== '') && (
                            <div className="top-padding-20">
                              {(this.props.pageSource === 'landingPage') ? (
                                <p className="description-text-2">View Full Posting: <a href={window.location.protocol + "//" + window.location.host + "/opportunities/organizations/" + this.state.activeOrg + "/" + this.state.selectedItem._id} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/opportunities/organizations/guidedcompass/" + this.state.selectedItem._id}</a></p>
                              ) : (
                                <p className="description-text-2">View Full Posting: <a href={window.location.protocol + "//" + window.location.host + "/app/opportunities/" + this.state.selectedItem._id} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + "/app/opportunities/" + this.state.selectedItem._id}</a></p>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.showGradeToolbar) && (
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>

                          <p className="heading-text-4">Grade this {this.state.docType.toLowerCase()}?</p>

                          <div className="row-20">

                            <div className="container-left">
                              <label className="profile-label">Your Grade (0% - 100%)</label>
                              <div className="calc-column-offset-30">
                                <input type="number" className="number-field" min="0" max="100" placeholder="(e.g., 0)" name={"grade"} value={this.state.grade} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="fixed-column-30 light-background standard-border">
                                <div className="flex-container flex-center height-38">
                                  <p className="heading-text-3 center-text">%</p>
                                </div>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />

                            {(this.state.grade) && (
                              <div className="row-10">
                                <label className="profile-label">Your Grade Explanation (Optional)</label>
                                <textarea type="text" className="text-field" placeholder="(e.g., reason for your grade" name={"gradeExplanation"} value={this.state.gradeExplanation} onChange={this.formChangeHandler}></textarea>
                              </div>
                            )}

                            <div className="spacer" />
                          </div>

                          <div>
                            <div className="float-left">
                              <button className="btn btn-squarish" onClick={() => this.saveGrade()}>Save Grade</button>
                            </div>
                            <div className="float-left left-padding">
                            <button className="btn btn-squarish white-background cta-color" onClick={() => this.props.passData({ modalIsOpen: true, showGradePrompt: true, passedCommandDraft: "Grade the document", togglePromptDesigner: true })}>Auto-Generate Grade</button  >
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.showSaveToolbar) && (
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>
                          <p className="heading-text-4">How would you like to save?</p>
                          <div className="row-10">
                            <div className="container-left">
                              <select name={"saveType"} className="dropdown" value={this.state.saveType} onChange={this.formChangeHandler}>
                                  {this.state.saveOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                            {(this.state.saveType && this.state.saveType !== '') && (
                              <div className="container-right">
                                {(this.state.saveType === 'Save New') ? (
                                  <input className="text-field" type="text" placeholder="Add a file name" name="docFileName" value={this.state.docFileName} onChange={this.formChangeHandler} />
                                ) : (
                                  <select name={"docFileName"} className="dropdown" value={this.state.docFileName} onChange={this.formChangeHandler}>
                                    {this.state.docFileNames.map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                )}

                              </div>
                            )}
                            <div className="clear" />
                            <div className="spacer" />
                          </div>

                          <div>
                            <div className="float-left">
                              <button className="btn btn-squarish" onClick={() => this.saveDoc(false)}>Save {this.state.docType}</button>
                            </div>
                            {(this.state.showUpdateProfile) && (
                              <div className="float-left left-padding">
                                <button className="btn btn-squarish white-background cta-color" onClick={() => this.saveDoc(true)}>Save {this.state.docType} & Update Guided Compass Profile</button  >
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.showOpenToolbar) && (
                        <div className={"top-margin-2-percent center-horizontally " + docWidth}>
                          <p className="heading-text-4">What would you like to open?</p>
                          <div className="row-10">
                            <div className="container-left">
                              <select name={"docFileName"} className="dropdown" value={this.state.docFileName} onChange={this.formChangeHandler}>
                                {this.state.docFileNames.map((value, index) => <option key={value} value={value}>{value}{(value && this.state.documents && this.state.documents[index - 1] && this.state.documents[index - 1].docType) && " [" + this.state.documents[index - 1].docType + "]"}</option>)}
                              </select>
                            </div>
                            <div className="container-right">
                              <button className="btn btn-squarish" onClick={() => this.loadDoc()}>Open</button>
                              <button className="btn btn-squarish error-background-color error-border left-margin" onClick={() => this.setState({ confirmDelete: true })}>Delete</button>
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.confirmDelete) && (
                            <div className="row-10">
                              <p className="error-color description-text-2 bottom-margin">Are you sure?</p>

                              <button className="btn btn-squarish error-background-color error-border" onClick={() => this.deleteDoc()}>Delete</button>
                              <button className="btn btn-squarish-white left-margin" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.errorMessage && this.state.errorMessage !== '') && (
                        <div className={"center-horizontally row-5" + docWidth}>
                          <div className="padding-10 cta-border top-margin">
                            <button className="background-button fixed-column-30" onClick={() => this.setState({ errorMessage: null })}>
                              <img src={deniedIcon} alt="Compass target icon" className="image-auto-15" />
                            </button>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2 error-color">{this.state.errorMessage}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.successMessage && this.state.successMessage !== '') && (
                        <div className={"center-horizontally row-5 " + docWidth}>
                          <div className="padding-10 cta-border top-margin">
                            <button className="background-button fixed-column-30" onClick={() => this.setState({ successMessage: null })}>
                              <img src={deniedIcon} alt="GC" className="image-auto-20" />
                            </button>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2 cta-color">{this.state.successMessage}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.isLoading) ? (
                        <div className="flex-container flex-center row-10">
                          <div>
                            <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                            <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                            <p className="center-text cta-color bold-text">Loading...</p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {(this.state.useEditor) ? (
                            <div>
                              <p className="heading-text-2">Edit {this.state.docType}</p>

                              <div>
                                <MyEditor ref={this.child} existingContent={this.state.richDescription} indicateFormChange={this.indicateFormChange}/>
                              </div>
                            </div>
                          ) : (
                            <div id="pdf">
                              {this.renderDocument()}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal-full-space"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                >
                  {(this.state.editBasicInfo) && (
                    <div>
                      {this.editDocData('basicInfo', false)}
                    </div>
                  )}

                  {(this.state.editSummary) && (
                    <div>
                      {this.editDocData('summary', false)}
                    </div>
                  )}

                  {(this.state.editEducation) && (
                    <div>
                      {this.editDocData('education', false)}
                    </div>
                  )}

                  {(this.state.editExperience) && (
                    <div>
                      {this.editDocData('experience', false)}
                    </div>
                  )}

                  {(this.state.editProjects) && (
                    <div>
                      {this.editDocData('projects', false)}
                    </div>
                  )}

                  {(this.state.editSkills) && (
                    <div>
                      {this.editDocData('skills', false)}
                    </div>
                  )}

                  {(this.state.editKnowledge) && (
                    <div>
                      {this.editDocData('knowledge', false)}
                    </div>
                  )}

                  {(this.state.editExtras) && (
                    <div>
                      {this.editDocData('extras', false)}
                    </div>
                  )}

                  {(this.state.editCurrentDate) && (
                    <div>
                      {this.editDocData('currentDate', false)}
                    </div>
                  )}

                  {(this.state.editRecipientInfo) && (
                    <div>
                      {this.editDocData('recipientInfo', false)}
                    </div>
                  )}

                  {(this.state.editTextBody) && (
                    <div>
                      {this.editDocData('textBody', false)}
                    </div>
                  )}

                  {(this.state.editSignatureInfo) && (
                    <div>
                      {this.editDocData('signatureInfo', false)}
                    </div>
                  )}

                  {(this.state.editTitleSection) && (
                    <div>
                      {this.editDocData('editTitleSection', false)}
                    </div>
                  )}

                  {(this.state.editSection) && (
                    <div>
                      {this.editDocData('editSection', false)}
                    </div>
                  )}

                  {(this.state.addSection) && (
                    <div>
                      <div className="modal">
                        <div className="padding-40">
                          <p className="heading-text-2">Add & Manage Sections</p>

                          <div className="row-10">
                            <label className="profile-label">Select Your Desired Sections</label>
                            {this.state.sectionOptions.map((value, optionIndex) =>
                              <div key={"section|" + optionIndex} className="float-left">
                                <button className="background-button float-left right-padding-5" onClick={() => this.selectItem(value)}>
                                  <div className="half-spacer" />
                                  <div className={(this.state.selectedSections.includes(value)) ? "tag-container-active" : "tag-container-inactive"}>
                                    <p className="description-text-2">{value}</p>
                                  </div>
                                  <div className="half-spacer" />
                                </button>

                              </div>
                            )}
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />
                          <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.state.showDisableExportText) && (
                    <div>
                      <div className="modal">
                        <div className="padding-40">
                          <p className="heading-text-2">Export Temporary Disabled</p>
                          <div className="spacer" /><div className="spacer" />

                          <p>The ability to export your document has been temporarily disabled due to technical issues.</p>
                          <p className="top-padding-20">Please save a version of your document so that you can export it when it comes back online. If you need a PDF version immediately, please contact us, and we will generate a PDF version of your document for you and send it to you.</p>

                          <div className="spacer" /><div className="spacer" />
                          <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.state.showFile) && (
                    <div className="modal">
                      <div className="padding-40">
                        <div className="calc-column-offset-40">
                          {(this.state.selectedFile) ? (
                            <div>
                              <p className="heading-text-2">{this.state.selectedFile.fileName}</p>
                              {(this.state.selectedFile.updatedAt) && (
                                <div className="top-margin-5">
                                  <p>Last Updated: {convertDateToString(new Date(this.state.selectedFile.updatedAt),'date-2')}</p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <p className="heading-text-2">{this.state.docType} File Not Found</p>
                            </div>
                          )}
                        </div>
                        <div className="fixed-columnd-40 top-margin">
                          <button className="background-button float-right" onClick={() => this.closeModal()}>
                            <img src={deniedIcon} alt="GC" className="image-auto-25" />
                          </button>
                          <div className="clear" />
                        </div>
                        <div className="clear" />

                        <div className="spacer" /><div className="spacer" />

                        {(this.state.selectedFile) && (
                          <div className="row-20">
                            <p>What would you like to do with {this.state.selectedFile.fileName}?</p>
                          </div>
                        )}

                        <div className="spacer" /><div className="spacer" />

                        {(this.state.confirmDelete) ? (
                          <div className="row-10">
                            <p className="error-color description-text-2 bottom-margin">Are you sure?</p>

                            <button className="btn btn-squarish error-background-color error-border" onClick={() => this.deleteDoc()}>Delete</button>
                            <button className="btn btn-squarish-white left-margin" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                          </div>
                        ) : (
                          <div>
                            <button className="btn btn-primary right-margin" onClick={() => this.loadDoc()}>Open</button>
                            {(!this.state.remoteAuth) && (
                              <button className="btn btn-primary senary-background senary-border right-margin" onClick={() => window.open('/documents/preview/' + this.state.selectedFile._id)}>Preview</button>
                            )}

                            {/*<button className="btn btn-primary right-margin" onClick={() => console.log('')}>Share</button>*/}
                            <button className="btn btn-primary error-background-color error-border right-margin" onClick={() => this.setState({ confirmDelete: true })}>Delete</button>
                          </div>
                        )}

                      </div>
                    </div>
                  )}

                  {(this.state.showConfirmExport) && (
                    <div className="modal">
                      <div className="padding-40">
                        <div className="calc-column-offset-40">
                          <div>
                            <p className="heading-text-2">Export File to PDF</p>
                          </div>
                        </div>
                        <div className="fixed-columnd-40">
                          <button className="background-button float-right" onClick={() => this.closeModal()}>
                            <img src={deniedIcon} alt="GC" className="image-auto-25" />
                          </button>
                          <div className="clear" />
                        </div>
                        <div className="clear" />

                        <div className="spacer" /><div className="spacer" />

                        <div className="row-20">
                          <p>Are you sure that you want to export this file to PDF?</p>
                          <p className="error-color row-5 top-margin-15">Note: PDF files are currently limited to one page.</p>
                        </div>

                        <div className="spacer" /><div className="spacer" />

                        <button className="btn btn-primary right-margin" onClick={() => this.exportFile()}>Export to PDF</button>
                        <button className="btn btn-secondary right-margin" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    </div>
                  )}
                </Modal>
              </div>
            )}
          </div>
      )
    }
}


export default withRouter(EditDocument);
