import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubMatchMentor from './Subcomponents/MatchMentor';
import withRouter from './Functions/WithRouter';

class AdvMatchMentor extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      const { queryEmail, username, id } = this.props.params

      let selectedMentor = null
      if (this.props.location && this.props.location.state) {
        selectedMentor = this.props.location.state.selectedMentor
      }

      if (email) {
        this.setState({ activeOrg, emailId: email, username, orgFocus,
          roleName, orgLogo, queryEmail, id, selectedMentor
        })
      }
    }

    render() {

      return (
          <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
              <SubMatchMentor id={this.state.id} activeOrg={this.state.activeOrg} selectedMentor={this.state.selectedMentor} source={this.state.source} history={this.props.navigate} />

              <div className="clear" />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
          </div>

      )
    }
}

export default withRouter(AdvMatchMentor)
