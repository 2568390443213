import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubEmployerDetails from '../components/Subcomponents/EmployerDetails';
import withRouter from '../components/Functions/WithRouter';

class EmployerDetailsPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('counselors page just mounted')
      window.scrollTo(0, 0)

      const { accountCode } = this.props.params
      let subNavSelected = null
      if (this.props.location && this.props.location.state) {
        subNavSelected = this.props.location.state.subNavSelected
      }

      this.setState({ accountCode, subNavSelected })
    }

    render() {

        return (
            <div>
              <TopNavigation currentPage={"employerPage"} />
              <SubEmployerDetails history={this.props.navigate} accountCode={this.state.accountCode} subNavSelected={this.state.subNavSelected} pageSource="landingPage" />

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(EmployerDetailsPage);
