import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubLessons from './Subcomponents/Lessons';
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class AdvLessons extends Component {
    constructor(props) {
      super(props)

      this.state = {
        opacity: 0,
        transform: 'translate(90%)',
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ activeOrg, emailId: email, username, orgFocus,
          roleName, orgLogo
        })
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    render() {

      return (
          <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

              {(!toggleVars.hideSideNav) && (
                <SubSideNav selectedComponent={"AdvLessons"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <div className={(toggleVars.hideSideNav) ? "width-70-percent max-width-1400 top-margin-2-percent center-horizontally" : "floating-container top-margin-2-percent"} style={(toggleVars.hideSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                <SubLessons history={this.props.navigate} />
              </div>

              <div className="clear" />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}

          </div>

      )
    }
}

export default withRouter(AdvLessons);
