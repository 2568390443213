import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubBenchmarkDetails from './Subcomponents/BenchmarkDetails';
import withRouter from './Functions/WithRouter';

class BenchmarkDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('counselors page just mounted')
      window.scrollTo(0, 0)

      const { accountCode, benchmarkId } = this.props.params
      // console.log('benchmarkId: ', benchmarkId)
      let email = localStorage.getItem('email');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ accountCode, benchmarkId, emailId: email, orgLogo })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.benchmarkId !== prevProps.params.benchmarkId) {
        console.log('change benchmark id')
        this.setState({ benchmarkId: this.props.params.benchmarkId })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              <SubBenchmarkDetails history={this.props.navigate} accountCode={this.state.accountCode} benchmarkId={this.state.benchmarkId} />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(BenchmarkDetails);
