import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditLog from './Subcomponents/EditLog';
import withRouter from './Functions/WithRouter';

// const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';

class NewLog extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      // let { editExisting, log, logs, passedLogType, selectedAdvisor } = this.props.location.state;
      let editExisting = false
      let log = null
      let logs = null
      let selectedAdvisor = null
      let passedLogType = null
      let selectedPathway = null
      let selectedCareer = null
      if (this.props.location && this.props.location.state) {
        editExisting = this.props.location.state.editExisting
        log = this.props.location.state.log
        logs = this.props.location.state.logs
        selectedAdvisor = this.props.location.state.selectedAdvisor
        passedLogType = this.props.location.state.passedLogType
        selectedPathway = this.props.location.state.selectedPathway
        selectedCareer = this.props.location.state.selectedCareer
      }

      // const { logId } = this.props.params
      let logId = null
      if (this.props.params && this.props.params.logId && this.props.params.logId !== 'new') {
        logId = this.props.params.logId
        editExisting = true
      }

      console.log('show the log: ', logId, this.props.location)

      this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus,
        orgLogo, editExisting, log, logs, passedLogType, selectedAdvisor,
        selectedPathway, selectedCareer, logId
      });
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
            <div>
                <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

                <div>
                  <SubEditLog history={this.props.navigate} editExisting={this.state.editExisting} log={this.state.log} logs={this.state.logs} passedLogType={this.state.passedLogType} selectedAdvisor={this.state.selectedAdvisor} selectedPathway={this.state.selectedPathway} selectedCareer={this.state.selectedCareer} logId={this.state.logId} commandClicked={this.commandClicked} />
                </div>

                {(this.state.activeOrg) && (
                  <div>
                    {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo, this.state.passedCommand)}
                  </div>
                )}
            </div>

        )
    }
}

export default withRouter(NewLog)
