import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAssessmentDetails from './Subcomponents/AssessmentDetails';
import withRouter from './Functions/WithRouter';

class AdvAssessmentDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {

        assessments: [],
        assessmentQuestions: []
      }

      this.getInitialData = this.getInitialData.bind(this);
      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.getInitialData(this.props.location.state)
    }

    getInitialData(passedState) {

      const { assessments, index, assessment, resultsData } = passedState;

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show assessment name ', assessment, resultsData, assessments, index)

      if (assessment) {

        let assessmentTitle = assessment.title
        let assessmentDescription = ''

        if (assessmentTitle === 'Work Preferences') {
          assessmentDescription = 'Self-assesses your fit to current and similar work'
        } else if (assessmentTitle === 'Personal Goals & Preferences') {
          assessmentDescription = 'Share your personal goals, frustrations, brands, and influencers'
        } else if (assessmentTitle === 'Interest Assessment') {
          assessmentDescription = 'Assesses what category of work you may be most interested in'
        } else if (assessmentTitle === 'Skill Assessment') {
          assessmentDescription = 'Self-assessment of hard and soft skills associated with different career paths'
        } else if (assessmentTitle === 'Personality Assessment') {
          assessmentDescription = 'Assesses your personality type along axis relevant to different career paths'
        } else if (assessmentTitle === 'Values Assessment') {
          assessmentDescription = 'Assesses your values and the values of your ideal employer'
        }

        let nextIndex = index + 1
        if (assessments) {
          if (nextIndex > assessments.length - 1) {
            nextIndex = 0
          }
        }

        this.setState({ email, username, cuFirstName, cuLastName, activeOrg, assessmentTitle, assessmentDescription,
        resultsData, orgFocus, roleName, orgLogo, assessment, assessments, index, nextIndex })


      } else {
        console.log('user navigated directly to this screen')
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
          <SubAssessmentDetails history={this.props.navigate} assessments={this.state.assessments} index={this.state.index} assessment={this.state.assessment} resultsData={this.state.resultsData} commandClicked={this.commandClicked} />
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo, this.state.passedCommand)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvAssessmentDetails);
