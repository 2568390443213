import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubJoinNewsletter from './Common/JoinNewsletter';
import withRouter from '../components/Functions/WithRouter';

class NewsletterPage extends Component {
    constructor(props) {
        super(props)

        // this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"newsletterPage"} darkBackground={true}/>

                <section className="section-features">
                  <SubJoinNewsletter />
                </section>

                <Footer />
            </div>
        )
    }
}

export default withRouter(NewsletterPage);
