import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';

import Clients from './Common/Clients';

import SubEducatorBenefits from './Common/EducatorBenefits';

class SchoolsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          caseStudies: [],
          panelSelected: 1,

        }
    }

    componentDidMount() {
        //see if user is logged in
        console.log('jobs page just mounted')
        window.scrollTo(0, 0)

    }

    render() {

      return (
          <div>
              <TopNavigation currentPage={"educatorsPage"}/>

              <header className="jobs-header">
                  <div className="hero-text-box">
                    <h1 className="light-text">Educators</h1>
                    <p>Middle-school to college educators and counselors can use Guided Compass to help students.</p>
                    <Link className="btn btn-full" to={'/educators/checkout'}>Purchase Student Licenses</Link>
                    <Link className="btn btn-secondary white-background" to={'/contact'} state={{ reason: "Guided Compass to Work with My Org / School" }}>Contact Us</Link>

                  </div>
              </header>

              <SubEducatorBenefits history={this.props.navigate} orgSelected={this.state.orgSelected}/>

              <section className="section-scenarios white-background">
                <div className="row">
                  <h2>Clients & Partners</h2>
                </div>

                <Clients />

              </section>

              <section className="section-join">
                  <div className="join-container">
                      <div className="join-text-box">
                          <p className="join-text">Would you like to schedule a</p>
                          <p className="join-title">Demo?</p>
                      </div>
                      <Link className="btn btn-full join-btn" to={'/contact'} state={{ reason: "Guided Compass to Work with My Org / School" }}>Just Ask</Link>
                  </div>
              </section>

              {/*Footer()*/}
              <Footer history={this.props.navigate} />

          </div>
      )
    }
}

export default SchoolsPage;
