import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import Axios from 'axios';
import Modal from 'react-modal';
import SubContact from '../components/Subcomponents/Contact';
import withRouter from '../components/Functions/WithRouter';

const askQuestionIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/askQuestionIconDark.png";

class HelpDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          defaultFilters: [],
          selectedFilters: []
        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.craftQuestion = this.craftQuestion.bind(this)
    }

    componentDidMount() {
      console.log('help detail page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      let email = localStorage.getItem('email');

      const { slug } = this.props.params

      this.setState({ emailId: email })

      if (slug) {
        Axios.get('/api/help-articles', { params: { slug }})
        .then((response) => {
          console.log('Article', response.data);

            if (response.data.success) {
              console.log('article query worked')

              const article = response.data.article
              this.setState({ article })

            } else {
              console.log('article did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Article did not work for some reason', error);
        });
      }
    }

    formChangeHandler = event => {
      console.log('formChangeHandler called: ', event.target.name, event.target.value)

      this.setState({ [event.target.name]: event.target.value })
    }

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    craftQuestion(questionAbout) {

      this.setState({ modalIsOpen: true, questionAbout })

    }

    render() {

      if (this.state.article && this.state.article.googleDocLink) {
        return (
          <div className="embedded-pdf-background">
            <iframe src={this.state.article.googleDocLink} width="100%" height="100%" title={this.state.article.googleDocLink}></iframe>
          </div>
        )
      } else {
        return (
            <div>
              <TopNavigation currentPage={"helpPage"} darkBackground={true} />
              {(this.state.article) && (
                <div className="contact-container">
                    <br/>
                    <div>
                      <div className="flex-container row-direction">
                        <div className="flex-20">
                        </div>
                        <div className="flex-60">
                          <h2>{this.state.article.title}</h2>
                        </div>
                        <div className="flex-20">
                          <button className="background-button float-right" onClick={() => this.craftQuestion('Question About ' + this.state.article.title + ' Article')}>
                            <img src={askQuestionIconDark} className="image-auto-30 pin-right" alt="GC" />
                          </button>
                          <div className="clear" />
                        </div>
                      </div>
                    </div>

                    <p className="description-text-1 top-padding-5 full-width center-text">{this.state.article.description}</p>

                    {(this.state.article.portals) && (
                      <div className="row-10 center-text">
                        {this.state.article.portals.map((value2, optionIndex2) =>
                          <div key={this.state.article.title + '|' + value2} className="display-inline top-margin-5 left-margin-5 unclickable-tags">
                            <p className="description-text-4">{value2}</p>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.article.videoURL) && (
                      <div className="top-padding-15 bottom-padding-5">
                        <iframe
                          title="videoLink"
                          className="video-iframe-4"
                          src={this.state.article.videoURL}
                          frameBorder="0"
                        />
                      </div>
                    )}

                    {(this.state.article.body) && (
                      <p className="description-text-1 top-padding-5 bottom-padding">{this.state.article.body}</p>
                    )}

                </div>
              )}

              <Footer history={this.props.navigate} />

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
               <div key="askQuestion" className="full-width">
                 <h2>Ask A Question</h2>
                 <SubContact reason={this.state.questionAbout} reasonOptions={null} history={this.props.navigate} inModal={true} closeModal={this.closeModal} />
               </div>

             </Modal>

            </div>

        )
      }
    }
}

export default withRouter(HelpDetailPage);
