import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditDocument from '../Subcomponents/EditDocument';
import withRouter from '../Functions/WithRouter';

class OrgEditDocument extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showPromptDesigner: false
      }

      this.passData = this.passData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, id, orgLogo })
    }

    passData(passedData) {
      console.log('passData called', passedData)

      if (passedData && passedData.togglePromptDesigner) {
        // because of appfooter function
        // passedData['showPromptDesigner'] = !this.state.showPromptDesigner
        passedData['clickerToggler'] = !this.state.clickerToggler

      }

      this.setState(passedData)

    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          <SubEditDocument history={this.props.navigate} id={this.state.id} activeOrg={this.state.org} passData={this.passData} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo,this.state.passedCommand,null, null, this.state.showPromptDesigner,this.state.passedCommandDraft,this.state.docContent, this.state.showGradePrompt, this.state.modalIsOpen, this.state.clickerToggler)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditDocument)
