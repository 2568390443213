import React, {Component} from 'react';

import Axios from 'axios';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class ProductReviews extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in ProductReviews', this.props, prevProps)

      if (this.props.itemSelected !== prevProps.itemSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within ProductReviews', this.props)

      const itemSelected = this.props.itemSelected

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      const accountCode = localStorage.getItem('accountCode');
      const pictureURL = localStorage.getItem('pictureURL');

      this.setState({ itemSelected,
        emailId, cuFirstName, cuLastName, orgFocus, roleName, activeOrg, accountCode, pictureURL,
      })

      if (itemSelected) {
        // pull product reviews

        Axios.get('/api/reviews', { params: { productId: itemSelected._id } })
        .then((response) => {
            if (response.data.success) {
              console.log('Reviews received query worked', response.data);

              const reviews = response.data.reviews
              this.setState({ reviews })

            } else {
              console.log('no reviews data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Reviews query did not work', error);
        });
      }
    }

    render() {

      return (
        <div>
          {(this.props.itemSelected) && (
            <div>
              <div className="calc-column-offset-30">
                <p className="full-width heading-text-3">{this.props.itemSelected.name} Product Reviews</p>
              </div>
              <div className="fixed-column-30">
                <button className="background-button top-margin-5" onClick={() => this.props.closeModal()}>
                  <img src={closeIcon} alt="GC" className="image-auto-15" />
                </button>
              </div>
              <div className="clear" />
              <div className="spacer" /><div className="spacer" />
              <hr />

              <div className="spacer" />

              {(this.state.reviews && this.state.reviews.length > 0) ? (
                <div>
                  {this.state.reviews.map((value, optionIndex) =>
                    <div key={value.review} className="top-margin-20">
                      <div className="fixed-column-80">
                        <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-4" />
                      </div>
                      <div className="calc-column-offset-80">
                        <p className="heading-text-6">{value.firstName} {value.lastName}</p>
                        <ul className="horizontal-list">
                          <li><img src={(value.rating >= 1) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                          <li><img src={(value.rating >= 2) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                          <li><img src={(value.rating >= 3) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                          <li><img src={(value.rating >= 4) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                          <li><img src={(value.rating >= 5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                        </ul>
                        <p className="top-padding description-text-2 full-width">{value.review}</p>
                      </div>
                      <div className="clear" />

                    </div>
                  )}
                </div>
              ) : (
                <div className="row-20">
                  <p className="error-color">No public product reviews found for {this.props.itemSelected.name}.</p>
                </div>
              )}
            </div>
          )}
        </div>
      )
    }
}

export default ProductReviews;
