import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubCompletions from './Subcomponents/Completions';
import withRouter from '../components/Functions/WithRouter';

class Completions extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const orgLogo = localStorage.getItem('orgLogo');
      this.setState({ emailId: email, orgLogo })
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      this.setState({ subNavSelected: pageName })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username}  activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  history={this.props.navigate}/>

              <div className={"standard-container-2"}>
                <SubCompletions />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(Completions);
