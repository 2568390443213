
export const subExportCSV = (type, csvData)=>{
  console.log('subExportCSV called')

  if (csvData) {

    let csvContent = "data:text/csv;charset=utf-8,";
    let csvRows = []
    let headers = csvData[0]
    csvRows.push(headers.join(','))
    // console.log('show csvData: ', csvData)

    for (const row of csvData) {
      const values = headers.map((header, index) => {

        if (row[index]) {
          const escaped = (''+row[index]).replace(/"/g,'\\"')
          const literalEscaped = `"${escaped}"`
          // console.log('show escaped: ', index, literalEscaped)
          return literalEscaped
        } else {
          // console.log('undefined value')
          const undefinedAlt = " "
          return undefinedAlt
        }
      })

      const newRow = values.join(',')
      csvRows.push(newRow)
      csvContent += newRow + "\r\n";

      // console.log('show rows: ', csvContent)
    }

    let reportName = type + '-report.csv'

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", reportName);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);

    return { isLoading: false }

  } else {

    return { clientErrorMessage: 'There is no data to export', isLoading: false}
  }
}
