import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubIntegrations from './Subcomponents/Integrations';
import withRouter from './Functions/WithRouter';

class EmpIntegrations extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp, userId } = this.props.params

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      console.log('show accountCode: ', emp, email)
      if (userId) {
        email = userId.replace('%20','+')
      }

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      this.setState({ emailId: email, username, emp, activeOrg, orgLogo, orgMode });
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

          <div className="standard-container-2">
            <SubIntegrations email={this.state.emailId} accountCode={this.state.emp} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} passActiveOrg={this.passActiveOrg} history={this.props.navigate}  />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}

        </div>
      )
    }
}


export default withRouter(EmpIntegrations)
