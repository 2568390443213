import React, {Component} from 'react';
import Switch from 'react-switch';
import Axios from 'axios';
import SubConfigureEndorsement from '../Common/ConfigureEndorsement';
import { Link } from 'react-router-dom';
// const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

import {convertDateToString} from '../Functions/ConvertDateToString';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';

class ReferSomeone extends Component {
    constructor(props) {
      super(props)

      this.state = {
        currentlyWorking: true,
        relationshipOptions: []
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.searchEmployers = this.searchEmployers.bind(this)
      this.employerClicked = this.employerClicked.bind(this)
      this.searchOpportunities = this.searchOpportunities.bind(this)
      this.opportunityClicked = this.opportunityClicked.bind(this)
      this.submitReferral = this.submitReferral.bind(this)
      this.passState = this.passState.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonReferSomeone', this.props, prevProps)

      if (this.props.selectedOpportunity !== prevProps.selectedOpportunity) {
        this.retrieveData()
      } else if (this.props.benchmark !== prevProps.benchmark) {
        this.retrieveData()
      } else if (this.props.headerImageURL !== prevProps.headerImageURL) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called i commonReferSomeone', this.props.headerImageURL)

      const selectedOpportunity = this.props.selectedOpportunity
      let importedOpportunity = false
      if (selectedOpportunity) {
        importedOpportunity = true
      }
      const referralBonus = this.props.referralBonus
      const pageSource = this.props.pageSource
      const linkedInURL = this.props.linkedInURL
      const benchmark = this.props.benchmark
      let relationshipOptions = []
      if (this.props.relationshipOptions) {
        relationshipOptions = this.props.relationshipOptions
      }

      const jobTitle = this.props.jobTitle
      const employerName = this.props.employerName

      const orgCode = this.props.orgCode

      let postingOrgCode = null
      if (selectedOpportunity) {
        postingOrgCode = selectedOpportunity.orgCode
      }
      const postingOrgName = this.props.postingOrgName
      const postingOrgProgramName = this.props.postingOrgName
      let postingOrgContactEmail = this.props.postingOrgContactEmail
      if (this.props.orgContactEmail) {
        postingOrgContactEmail = this.props.orgContactEmail
      }

      const headerImageURL = this.props.headerImageURL
      const partnerLandingPage = this.props.partnerLandingPage

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let remoteAuth = localStorage.getItem('remoteAuth');

      let importedEmailId = false
      if (emailId) {
        importedEmailId = true
      }

      this.setState({ selectedOpportunity, importedOpportunity, referralBonus, pageSource, linkedInURL,
        benchmark, relationshipOptions,
        emailId, username, cuFirstName, cuLastName, roleName, remoteAuth, jobTitle, employerName,
        orgCode, postingOrgCode, postingOrgName, postingOrgProgramName, postingOrgContactEmail, importedEmailId,
        headerImageURL, partnerLandingPage
      })

      if (!this.props.relationshipOptions) {
        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const relationshipOptions = [''].concat(response.data.workOptions[0].relationshipOptions)
            this.setState({ relationshipOptions })

          } else {
            console.log('no jobFamilies data found', response.data.message)

          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })
      }

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'employerName') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchEmployers(event.target.value)
      } else if (event.target.name === 'opportunityTitle') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchOpportunities(event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            if (employers && employers.length > 0) {
              console.log('we in')
              this.setState({ employers });
            }

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employers = []
      const accountCode = employer.accountCode
      const selectedAnswer = employer.employerName
      // let responses = this.state.responses
      let employerName = employer.employerName

      this.setState({ employers, accountCode, selectedAnswer, employerName })

    }

    searchOpportunities(searchString) {
      console.log('searchOpportunities called ', searchString)

      const orgCode = this.state.orgCode
      const placementPartners = this.state.placementPartners
      const recent = true
      const active = true
      const postTypes = ['Work','Internship']

      Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, recent, active, postTypes } })
      .then((response) => {
        console.log('Opportunities search query attempted', response.data);

          if (response.data.success) {
            console.log('Opportunities search query worked')

            const opportunityOptions = response.data.postings
            if (opportunityOptions && opportunityOptions.length > 0) {
              this.setState({ opportunityOptions });
            }

          } else {
            console.log('opportunities search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Opportunities search query did not work for some reason', error);
      });
    }

    opportunityClicked(selectedOpportunity) {
      console.log('opportunityClicked called ', selectedOpportunity)

      const opportunityOptions = []
      let opportunityTitle = selectedOpportunity.title
      if (selectedOpportunity.name) {
        opportunityTitle = selectedOpportunity.name
      }
      if (selectedOpportunity.employerName) {
        opportunityTitle = opportunityTitle + " | " + selectedOpportunity.employerName
      }

      const postingOrgCode = selectedOpportunity.orgCode
      const postingOrgName = selectedOpportunity.orgName
      const postingOrgContactEmail = selectedOpportunity.orgContactEmail

      this.setState({ opportunityOptions, selectedOpportunity, opportunityTitle, postingOrgCode,
        postingOrgName, postingOrgContactEmail
      })

      if (selectedOpportunity.benchmarkId) {

        Axios.get('/api/benchmarks/byid', { params: { _id: selectedOpportunity.benchmarkId } })
        .then((response) => {
           console.log('Benchmark query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved benchmark')

             const benchmark = response.data.benchmark
             this.setState({ benchmark })

           }

        }).catch((error) => {
             console.log('Benchmark query did not work', error);
        });
      }

    }

    submitReferral() {
      console.log('submitReferral called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.recipientFirstName || this.state.recipientFirstName === '') {
        this.setState({ isSaving: false, errorMessage: "Please add the candidate's first name"})
      } else if (!this.state.recipientLastName || this.state.recipientLastName === '') {
        this.setState({ isSaving: false, errorMessage: "Please add the candidate's last name"})
      } else if (!this.state.recipientEmail || this.state.recipientEmail === '') {
        this.setState({ isSaving: false, errorMessage: "Please add the candidate's email"})
      } else if (!this.state.recipientEmail.includes('@')) {
        this.setState({ isSaving: false, errorMessage: "Please add a valid email for the candidate"})
      } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
        this.setState({ isSaving: false, errorMessage: "Please add your first name"})
      } else if (!this.state.cuLastName || this.state.cuLastName === '') {
        this.setState({ isSaving: false, errorMessage: "Please add your last name"})
      } else if (!this.state.emailId || this.state.emailId === '') {
        this.setState({ isSaving: false, errorMessage: "Please add your email"})
      } else if (!this.state.relationship || this.state.relationship === '') {
        this.setState({ isSaving: false, errorMessage: "Please add your relationship with the candidate"})
      } else if (!this.state.reason || this.state.reason === '') {
        this.setState({ isSaving: false, errorMessage: "Please add your reason for referring this candidate"})
      } else if (this.state.attachEndorsement && (!this.state.competencies || this.state.competencies.length === 0)) {
        this.setState({ isSaving: false, errorMessage: "Please add at least one competency"})
      } else if (this.state.attachEndorsement && (!this.state.examples || this.state.examples.length === 0)) {
        this.setState({ isSaving: false, errorMessage: "Please add at least one example"})
      } else {

        if (this.state.competencies) {
          // ensure all competency information is filled
          for (let i = 1; i <= this.state.competencies.length; i++) {
            if (!this.state.competencies[i - 1].name || this.state.competencies[i - 1].name === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a name for each competency' })
            } else if (!this.state.competencies[i - 1].category || this.state.competencies[i - 1].category === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a category for each competency' })
            } else if (!this.state.competencies[i - 1].rating || this.state.competencies[i - 1].rating === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a rating for each competency' })
            }
          }
        }

        if (this.state.examples) {
          // ensure all example information is filled
          for (let i = 1; i <= this.state.examples.length; i++) {
            // console.log('show example: ', this.state.examples[i - 1])
            if (!this.state.examples[i - 1].competency || this.state.examples[i - 1].competency === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a competency for each example' })
            } else if (!this.state.examples[i - 1].example || this.state.examples[i - 1].example === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add example details for each example' })
            }
          }
        }

        // save referral
        const recipientPictureURL = this.state.recipientPictureURL
        const recipientFirstName = this.state.recipientFirstName
        const recipientLastName = this.state.recipientLastName
        const recipientEmail = this.state.recipientEmail
        const recipientUsername = this.state.recipientUsername
        const referrerPictureURL = this.state.pictureURL
        const referrerFirstName = this.state.cuFirstName
        const referrerLastName = this.state.cuLastName
        const referrerEmail = this.state.emailId
        const referrerUsername = this.state.username
        const referrerLinkedInURL = this.state.linkedInURL
        const referrerJobTitle = this.state.jobTitle
        const referrerEmployerName = this.state.employerName
        let referrerPartnerOrg = null
        if (this.state.partnerOrgRep) {
          referrerPartnerOrg = this.state.partnerOrg
        }
        const accountCode = this.state.accountCode
        const relationship = this.state.relationship
        const reason = this.state.reason
        let existingUser = true
        let isInternal = true
        if (!this.state.importedEmailId) {
          existingUser = false
          isInternal = false
        }

        const attachEndorsement = this.state.attachEndorsement
        const competencies = this.state.competencies
        const examples = this.state.examples

        let pathway = null
        if (this.state.selectedOpportunity.pathways && this.state.selectedOpportunity.pathways[0] && this.state.selectedOpportunity.pathways[0].name) {
          // pathway = this.state.selectedOpportunity.pathways[0]
        }

        const opportunityId = this.state.selectedOpportunity._id
        let opportunityTitle = this.state.selectedOpportunity.title
        if (this.state.selectedOpportunity.name) {
          opportunityTitle = this.state.selectedOpportunity.name
        }
        const opportunityEmployerName = this.state.selectedOpportunity.employerName
        const opportunityDeadline = this.state.selectedOpportunity.submissionDeadline

        const referralBonus = this.state.referralBonus

        let orgCode = this.state.orgCode
        if (!orgCode) {
          orgCode = this.state.postingOrgCode
        }
        const postingOrgCode = this.state.postingOrgCode
        const postingOrgName = this.state.postingOrgName
        const postingOrgProgramName = this.state.postingOrgName
        const postingOrgContactEmail = this.state.postingOrgContactEmail
        const headerImageURL = this.state.headerImageURL
        const partnerLandingPage = this.state.partnerLandingPage

        const createdAt = new Date()
        const updatedAt = new Date()

        const self = this

        function saveEndorsement() {
          console.log('saveEndorsement called')

          const goalType = { name: 'Career', description: 'Land a job within a career path (Career)'}

          const hasStudied = self.state.hasStudied
          const hoursStudied = self.state.hoursStudied
          const hasWorked = self.state.hasWorked
          const hoursWorked = self.state.hoursWorked
          const hasManaged = self.state.hasManaged
          const hoursManaged = self.state.hoursManaged
          const hasTaught = self.state.hasTaught
          const hoursTaught = self.state.hoursTaught

          Axios.post('/api/story/provide', {
            senderFirstName: referrerFirstName, senderLastName: referrerLastName, senderEmail: referrerEmail,
            senderUsername: referrerUsername, senderPictureURL: referrerPictureURL,
            relationship, type: 'provide',
            recipientFirstName,  recipientLastName, recipientEmail, recipientUsername, recipientPictureURL,
            isDecided: false, isApproved: false, isDenied: false,
            overallRecommendation: 5,
            recommendationExplanation: reason, isTransparent: true,
            goalType, pathway, opportunityId, opportunityTitle, opportunityEmployerName, competencies, examples, isInternal,
            orgCode, orgName: postingOrgName, orgProgramName: postingOrgName, orgContactEmail: postingOrgContactEmail,
            hasStudied, hoursStudied, hasWorked, hoursWorked,
            hasManaged, hoursManaged, hasTaught, hoursTaught,
            createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              saveReferral(response.data._id)
            } else {
              self.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('endorsement send did not work', error);
              self.setState({ isSaving: false, errorMessage: error.toString() })
          });
        }

        function saveReferral(endorsementId) {
          console.log('saveReferral called', endorsementId)

          const status = "Sent"

          Axios.post('/api/referrals', {
            recipientPictureURL, recipientFirstName,  recipientLastName, recipientEmail, recipientUsername,
            referrerPictureURL, referrerFirstName, referrerLastName, referrerEmail, referrerUsername,
            referrerJobTitle, referrerEmployerName, referrerPartnerOrg,
            accountCode,
            relationship, reason, existingUser, referrerLinkedInURL,
            attachEndorsement, endorsementId,
            pathway, opportunityId, opportunityTitle, opportunityEmployerName, opportunityDeadline,
            referralBonus, isInternal, status,
            orgCode, postingOrgCode, postingOrgName, postingOrgProgramName, postingOrgContactEmail,
            orgContactEmail: postingOrgContactEmail, headerImageURL, partnerLandingPage,
            createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              //clear values
              self.setState({ isSaving: false, successMessage: response.data.message,
                recipientFirstName: '', recipientLastName: '', recipientEmail: '',
                relationship: '', reason: '', attachEndorsement: null,
                competencies: null, examples: null,
                isInternal: null
              })
              self.props.closeModal()

            } else {
              self.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('endorsement send did not work', error);
              self.setState({ isSaving: false, errorMessage: error.toString() })
          });
        }

        if (attachEndorsement) {
          saveEndorsement()
        } else {
          saveReferral()
        }

      }
    }

    passState(passedState) {
      console.log('passState called', passedState, this.state.competencies)
      this.setState(passedState)
    }

    render() {

      let headerTitle = "Refer Someone for an Opportunity"
      if (this.state.selectedOpportunity) {
        if (this.state.selectedOpportunity.title) {
          headerTitle = "Refer Someone for " + this.state.selectedOpportunity.title
        } else {
          headerTitle = "Refer Someone for " + this.state.selectedOpportunity.name
        }

        if (this.state.selectedOpportunity.employerName) {
          headerTitle = headerTitle + " @ " + this.state.selectedOpportunity.employerName
        }
      }

      return (
        <div>
          <p className="heading-text-2">{headerTitle}</p>
          {(this.state.referralBonus) && (
            <p className="top-padding">If (s)he gets hired, you both get <label className="cta-color">$50!</label></p>
          )}

          <div className="spacer"/>

          {(!this.state.importedOpportunity) && (
            <div className="row-10">
              <div>
                <div className="calc-column-offset-90">
                  <label className="profile-label">Select an Opportunity</label>
                </div>
                <div className="fixed-column-90">
                  <Link className="background-button full-width right-text" to="/opportunities" target="_blank">
                    <p className="description-text-3 full-width right-text">Browse ></p>
                  </Link>
                </div>
                <div className="clear" />
              </div>

              <input className="text-field" type="text" placeholder="(e.g., Software Development Intern)" name="opportunityTitle" value={this.state.opportunityTitle} onChange={this.formChangeHandler} />

              {(this.state.selectedOpportunity) && (
                <div className="top-padding-20">
                  <label className="description-text-2">View details: <Link target="_blank" to={"/opportunities/organizations/guidedcompass/" + this.state.selectedOpportunity._id}>{window.location.protocol + "//" + window.location.host + "/opportunities/organizations/guidedcompass/" + this.state.selectedOpportunity._id}</Link></label>
                </div>
              )}

              {(this.state.opportunityOptions && this.state.opportunityOptions.length > 0) && (
                <div>
                  <div className="spacer"/>
                  {this.state.opportunityOptions.map(value =>
                    <div key={value._id} className="left-text bottom-margin">
                      <button className="background-button full-width left-text" onClick={() => this.opportunityClicked(value)}>
                        <div>
                          <div className="fixed-column-30">
                            <img src={opportunitiesIconBlue} alt="GC" className="image-auto-22" />
                          </div>
                          <div className="calc-column-offset-30">
                            <p className="cta-color">{(value.title) ? value.title : value.name}{(value.employerName) && " | " + value.employerName}</p>
                            {(value.createdAt) && (
                              <p className="description-text-3">{convertDateToString(value.createdAt,"daysAgo")}</p>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>)}
                </div>
              )}
            </div>
          )}

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Candidate's First Name<label className="error-color">*</label></label>
              <input className="text-field" type="text" placeholder="(e.g., Jon)" name="recipientFirstName" value={this.state.recipientFirstName} onChange={this.formChangeHandler} />
            </div>
            <div className="container-right">
              <label className="profile-label">Candidate's Last Name<label className="error-color">*</label></label>
              <input className="text-field" type="text" placeholder="(e.g., Doe)" name="recipientLastName" value={this.state.recipientLastName} onChange={this.formChangeHandler} />
            </div>
            <div className="clear" />
          </div>
          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Candidate's Email<label className="error-color">*</label></label>
              <input className="text-field" type="text" placeholder="(e.g., jondoe@gmail.com)" name="recipientEmail" value={this.state.recipientEmail} onChange={this.formChangeHandler} />
            </div>
            <div className="container-right">
            </div>
            <div className="clear" />
          </div>

          {(this.state.pageSource === 'landingPage') && (
            <div>
              <div className="row-20">
                <hr />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Your First Name<label className="error-color">*</label></label>
                  <input className="text-field" type="text" placeholder="(e.g., Jane)" name="cuFirstName" value={this.state.cuFirstName} onChange={this.formChangeHandler} />
                </div>
                <div className="container-right">
                  <label className="profile-label">Your Last Name<label className="error-color">*</label></label>
                  <input className="text-field" type="text" placeholder="(e.g., Shmoe)" name="cuLastName" value={this.state.cuLastName} onChange={this.formChangeHandler} />
                </div>
                <div className="clear" />
              </div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Your Email<label className="error-color">*</label></label>
                  <input className="text-field" type="text" placeholder="(e.g., janeschmoe@gmail.com)" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler} />
                </div>
                <div className="container-left">
                  <label className="profile-label">Your LinkedIn Profile</label>
                  <input className="text-field" type="text" placeholder="https://www.linkedin.com/in/creightontaylor/" name="linkedInURL" value={this.state.linkedInURL} onChange={this.formChangeHandler} />
                  {(this.state.linkedInURL && !this.state.linkedInURL.startsWith('http')) && (
                    <p className="top-padding-5 error-color">Please start the link with http</p>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <label className="profile-label">Are you currently working?</label>
                <Switch
                  onChange={(change) => this.setState({ currentlyWorking: change })}
                  checked={this.state.currentlyWorking}
                  id="working-switch"
                />
              </div>

              {(this.state.currentlyWorking) && (
                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Your Job Title</label>
                    <input className="text-field" type="text" placeholder="(e.g., janeschmoe@gmail.com)" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                  </div>
                  <div className="container-left">
                    <label className="profile-label">Your Employer Name</label>
                    <input className="text-field" type="text" placeholder="(e.g., Nike)" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />

                    {(this.state.employers && this.state.employers.length > 0) && (
                      <div>
                        <div className="spacer"/>
                        {this.state.employers.map(value =>
                          <div key={value._id} className="left-text bottom-margin">
                            <button className="background-button full-width left-text" onClick={() => this.employerClicked(value)}>
                              <div>
                                <div className="fixed-column-30">
                                  <img src={employersIconBlue} alt="GC" className="image-auto-22" />
                                </div>
                                <div className="calc-column-offset-30">
                                  <p className="cta-color">{value.employerName}</p>
                                </div>
                              </div>
                            </button>
                          </div>)}
                      </div>
                    )}
                  </div>
                  <div className="clear" />
                </div>
              )}

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Do you represent a partner organization?</label>
                  <Switch
                    onChange={(change) => this.setState({ partnerOrgRep: change })}
                    checked={this.state.partnerOrgRep}
                    id="working-switch"
                  />
                </div>
                {(this.state.partnerOrgRep) && (
                  <div className="container-right">
                    <label className="profile-label">Your Partner Org</label>
                    <input className="text-field" type="text" placeholder="(e.g., Year Up)" name="partnerOrg" value={this.state.partnerOrg} onChange={this.formChangeHandler} />
                  </div>
                )}

                <div className="clear" />

              </div>
            </div>
          )}

          <div className="row-20">
            <hr />
          </div>

          <div className="row-10">
            <label className="profile-label">Relationship Type<label className="error-color">*</label></label>
            <select name="relationship" className="dropdown" value={this.state.relationship} onChange={this.formChangeHandler}>
              {this.state.relationshipOptions.map(value => <option key={value} value={value}>{value}</option>)}
            </select>
          </div>

          <div className="row-10">
            <label className="profile-label">Reason<label className="error-color">*</label></label>
            <textarea className="text-field" type="text" placeholder="(e.g., I've worked with mr. doe for 20 years, and for each of these 20 years he has impressed me more and more in his approach to problem solving)" name="reason" value={this.state.reason} onChange={this.formChangeHandler} />
          </div>

          {(this.state.benchmark) && (
            <div>
              <div className="row-10">
                <label className="profile-label">Attach an Endorsement</label>
                <Switch
                  onChange={(change) => this.setState({ attachEndorsement: change })}
                  checked={this.state.attachEndorsement}
                  id="normal-switch"
                />
              </div>

              {(this.state.attachEndorsement) && (
                <div>
                  <SubConfigureEndorsement history={this.props.navigate} passState={this.passState} selectedBenchmark={this.state.benchmark}/>
                </div>
              )}
            </div>
          )}

          <div className="row-20">
            <hr />
          </div>

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5 full-width description-text-2">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5 full-widthh description-text-2">{this.state.successMessage}</p>}

          <div className="row-20">
            <button className="btn btn-primary" onClick={() => this.submitReferral()}>Refer</button>
            <button className="btn btn-secondary left-margin" onClick={() => this.props.closeModal()}>Close View</button>
          </div>
        </div>
      )
    }
}

export default ReferSomeone;
