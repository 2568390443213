import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import ProjectDetails from '../components/Subcomponents/ProjectDetails';
import withRouter from '../components/Functions/WithRouter';

class ProjectDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      //see if user is logged in
      console.log('profilePage just mounted')
      document.body.style.backgroundColor = "#fff";
      window.scrollTo(0, 0)

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      if (this.props.location && this.props.location.selectedItem) {
        //send this logged out user out of the app
        const { selectedProject } = this.props.location;
        console.log('show item: ', selectedProject)

        this.setState({ selectedProject });
        // this.retrieveData(selectedProblem)

      } else if (this.props.params) {
        // show welcome message
        const { id } = this.props.params
        console.log('username: ', id)

        const projectId = id

        this.setState({ emailId, activeOrg, orgFocus, roleName, projectId })
      }
    }

    closeModal() {

      this.setState({ modalIsOpen: false });
    }

    render() {

      return (
        <div>
          <TopNavigation currentPage={"ProjectDetails"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

          {(this.state.showProjectDetail) && (
            <div>
              <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.projects[this.state.selectedIndex]} orgCode={this.state.activeOrg} />
            </div>
          )}

        </div>
      )
    }
}

export default withRouter(ProjectDetailPage);
