import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubEndorsementDetails from '../Common/EndorsementDetails';
import SubRequestEndorsements from './RequestEndorsements';
import SubAssistantWidget from '../Common/AssistantWidget';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const addIncomingIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-incoming-icon.png";
const addOutgoingIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-outgoing-icon.png";
const confidentialityIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/confidentiality-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Endorsements extends Component {
    constructor(props) {
        super(props)

        this.state = {
            resLimit: 500,

            opacity: 0,
            transform: 'translate(90%)',

            showOutgoing: false,
            showSubEndorsementDetails: true,

            areEndorsements: false,
            endorsements: [],
            filteredEndorsements: [],
            detailIndex: 0,

            categorySelected: 0,//endorsements for me
            searchString: '',
            direction: 'Any',
            status: 'Any',
            relationship: 'Any',
            skill: 'Any'
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderEndorsements = this.renderEndorsements.bind(this)
        this.renderFilteredEndorsements = this.renderFilteredEndorsements.bind(this)
        this.approveEndorsement = this.approveEndorsement.bind(this)
        this.deleteEndorsement = this.deleteEndorsement.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.renderSkillTraits = this.renderSkillTraits.bind(this)
        this.renderSpecificSkillTraits = this.renderSpecificSkillTraits.bind(this)
        this.removeEndorsement = this.removeEndorsement.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      //   this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      let email = localStorage.getItem('email');
      if (!email || email === '') {
        console.log('user is not logged in')
        this.props.navigate('/signin')
      } else {

        let username = localStorage.getItem('username');
        const activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');
        if (orgFocus === 'Placement' && roleName === 'Teacher') {
          roleName = 'Mentor'
        }

        if (email) {

          this.setState({ emailId: email, username, activeOrg, orgFocus });

          if (window.location.pathname.includes('/app/') || (window.location.pathname.includes('/advisor/') && roleName === 'Mentor')) {
            Axios.get('/api/story', { params: { emailId: email, resLimit: this.state.resLimit }})
            .then((response) => {
                console.log('Endorsement query worked', response.data);

                if (response.data.success) {

                  if (response.data.stories) {
                    const areEndorsements = true
                    const endorsements = response.data.stories
                    const filteredEndorsements = endorsements

                    let endorsementCommands = []
                    let topCompetency = null
                    let showCompassAssistant = false

                    if (window.location.pathname.startsWith('/app')) {
                      showCompassAssistant = true

                      for (let i = 1; i <= endorsements.length; i++) {
                        if (endorsements[i - 1] && endorsements[i - 1].competencies && endorsements[i - 1].competencies.length > 0) {
                          for (let j = 1; j <= endorsements[i - 1].competencies.length; j++) {
                            if (endorsements[i - 1].competencies[j - 1]) {
                              const name = endorsements[i - 1].competencies[j - 1].name
                              const rating = endorsements[i - 1].competencies[j - 1].rating
                              if (!topCompetency) {
                                topCompetency = { name, rating }
                              } else if (endorsements[i - 1].competencies[j - 1].rating > topCompetency.rating) {
                                topCompetency = { name, rating }
                              }
                            }
                          }
                        }
                      }

                      if (topCompetency) {
                        endorsementCommands = [
                          "People tell me I'm great at " + topCompetency.name + ". What career field should I go into?",
                          "People tell me I'm great at " + topCompetency.name + ". What are some employers that would pay me a lot of money to use this skill?",
                          "People tell me I'm great at " + topCompetency.name + ". What are some business ideas for using this skill?",
                          "People tell me I'm great at " + topCompetency.name + ". What some great ways to showcase this to employer?",
                          "People tell me I'm great at " + topCompetency.name + ". Is this a rare skill that other people want to learn?",
                        ]
                      }
                    }

                    this.setState({ areEndorsements, endorsements, filteredEndorsements,
                      endorsementCommands, showCompassAssistant
                    })
                  }
                }

            }).catch((error) => {
                console.log('Story query did not work', error);
            });
          } else {
            Axios.get('/api/story', { params: { orgCode: activeOrg, resLimit: this.state.resLimit }})
            .then((response) => {
                console.log('Endorsement query worked', response.data);

                if (response.data.success) {

                  if (response.data.stories) {
                    const areEndorsements = true
                    const endorsements = response.data.stories
                    const filteredEndorsements = endorsements
                    this.setState({ areEndorsements, endorsements, filteredEndorsements })
                  }
                }

            }).catch((error) => {
                console.log('Story query did not work', error);
            });
          }

          Axios.get('/api/org', { params: { orgCode: activeOrg } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked in editLog')

              const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
              // console.log('askfajldkfajsldkfaldsf', disableChatbotPrompts)
              this.setState({ disableChatbotPrompts });

            } else {
              console.log('org info query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        if (window.innerWidth > 768) {

            setTimeout(() => {
                this.setState(state => ({
                    opacity: 1,
                    transform: 'translate(2%)'
                  }));
            }, 0.3)

        } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

        }
      }
    }

    handleChange(e) {
      console.log('handleChange');
      //search using searchString

      let searchString = this.state.searchString
      let relationship = this.state.relationship

      if (e.target.name === 'search') {
        console.log('search was called')
        searchString = e.target.value
        // this.setState({ searchString: e.target.value })
      // } else if (e.target.name === 'direction') {
      //   console.log('direction was called')
      //   this.setState({ direction: e.target.value })
      } else if (e.target.name === 'relationship') {
        relationship = e.target.value
        // this.setState({ relationship: e.target.value })
      }

      let filteredEndorsements = []
      for (let i = 1; i <= this.state.endorsements.length; i++) {

        let iSearch = false

        let iRelationship = false

        let fullTerm = ''
        if (this.state.endorsements[i - 1].recipientFirstName) {
          fullTerm = fullTerm + this.state.endorsements[i - 1].recipientFirstName
        }

        if (this.state.endorsements[i - 1].recipientLastName) {
          fullTerm = fullTerm + ' ' + this.state.endorsements[i - 1].recipientLastName
        }

        // if (this.state.endorsements[i - 1].recipientEmail) {
        //   fullTerm + this.state.endorsements[i - 1].recipientEmail
        // }

        if (this.state.endorsements[i - 1].senderFirstName) {
          fullTerm = fullTerm + ' ' + this.state.endorsements[i - 1].senderFirstName
        }

        if (this.state.endorsements[i - 1].senderLastName) {
          fullTerm = fullTerm + ' ' + this.state.endorsements[i - 1].senderLastName
        }
        console.log('show search: ', fullTerm, 'break', searchString, this.state.endorsements[i - 1])
        if (searchString === '' || fullTerm.toLowerCase().includes(searchString.toLowerCase())) {
          console.log('we upin')
          iSearch = true
        }

        if (relationship === '' || relationship === 'Any') {
          iRelationship = true
        } else if (relationship === this.state.endorsements[i - 1].relationship) {
          iRelationship = true
        }

        // console.log('lets see values ', iSearch, iRelationship)
        if (iSearch && iRelationship) {
          filteredEndorsements.push(this.state.endorsements[i - 1])
        }
      }
      // console.log('show final value ', filteredAdvisees.length)
      this.setState({ searchString, relationship, filteredEndorsements })
    }

    approveEndorsement(endorsementId, index, isApproved) {
      console.log('approveEndorsement clicked');

      let endorsements = this.state.endorsements

      if (isApproved.isApproved) {
        endorsements[index.index].isDecided = true
        endorsements[index.index].isApproved = isApproved.isApproved
      } else {
        endorsements[index.index].isDecided = true
        endorsements[index.index].isApproved = isApproved.isApproved
      }
      console.log('value of endorsements', endorsements, !isApproved.isApproved)

      Axios.put('/api/story/is-approved', {
        senderEmail: this.state.emailId, storyId: endorsementId.endorsementId, isApproved: isApproved.isApproved, isDenied: !isApproved.isApproved })
      .then((response) => {

        if (response.data.success) {
          console.log('Endorsement update worked', response.data);
          this.setState({
            endorsements: endorsements
          })
        }

      }).catch((error) => {
          console.log('endorsement send did not work', error);
      });

    }

    deleteEndorsement(index) {
      console.log('deleteEndorsement', index)

      const _id = this.state.endorsements[index]._id

      // delete endorsements attached to profile
      Axios.delete('/api/endorsements/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked');

          let endorsements = []
          for (let i = 1; i <= this.state.endorsements.length; i++) {
            const tempIndex = i - 1
            if (index !== tempIndex) {
              endorsements.push(this.state.endorsements[i - 1])
            }
          }
          console.log('show endorsements: ', this.state.endorsements.length, endorsements.length)

          const filteredEndorsements = endorsements

          const searchString = ''
          const relationship = 'Any'

          const serverSuccessMessage = 'Endorsement was deleted successfully!'
          const confirmDelete = false
          const modalIsOpen = false

          this.setState({ endorsements, filteredEndorsements, searchString, relationship,
            serverSuccessMessage, confirmDelete, modalIsOpen })

          // const emailId = this.state.emailId
          // const endorsementId = _id
          //
          // // delete endorsements attached to applications
          // Axios.put('/api/endorsements/remove-from-applications', { emailId, endorsementId })
          // .then((response) => {
          //
          //   if (response.data.success) {
          //     console.log('Endorsements were successfully deleted from relevant applications', response.data);
          //
          //     this.setState({ serverSuccessMessage: 'Endorsements were successfully deleted from profile and applications' })
          //
          //   } else {
          //     console.log('there was an error')
          //     this.setState({ serverSuccessMessage: response.data.message })
          //   }
          //
          // }).catch((error) => {
          //     console.log('endorsement send did not work', error);
          // });

        } else {
          console.error('there was an error deleting the endorsement');
          this.setState({ serverErrorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ serverErrorMessage: error })
      });
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showRequestEndorsements: false, showSubEndorsementDetails: false });
    }

    renderEndorsements() {
      console.log('renderEndosements called');

      let rows = [];
      for (let i = 1; i <= this.state.filteredEndorsements.length; i++) {
        console.log(i);
        this.renderFilteredEndorsements(i , rows)
      }

      return rows;
    }

    renderFilteredEndorsements(i, rows) {
      console.log('filteredEndorsements called');

      const detailIndex = i - 1
      const filteredEndorsements = this.state.filteredEndorsements

      //format date
      let createdAt = filteredEndorsements[i - 1].createdAt
      let year = createdAt.substring(0,4);
      let subCA = createdAt.substring(5,10);
      let replacedCA = subCA.replace("-","/")
      let formattedCA = replacedCA + "/" + year

      //determine anonymity value.
      //ideally this is determined on back-end.
      let incoming = true
      if (filteredEndorsements[i - 1].recipientEmail === this.state.emailId) {
        incoming = true
      } else {
        incoming = false
      }

      let name = '';
      if (filteredEndorsements[i - 1].isAnonymousContribution === true) {
        name = 'Anonymous';
      } else if (filteredEndorsements[i - 1].senderFirstName && filteredEndorsements[i - 1].senderLastName) {
        if (incoming) {
          name = filteredEndorsements[i - 1].senderFirstName + ' ' + filteredEndorsements[i - 1].senderLastName
        } else {
          name = filteredEndorsements[i - 1].recipientFirstName + ' ' + filteredEndorsements[i - 1].recipientLastName
        }
      } else if (incoming) {
        name = filteredEndorsements[i - 1].senderEmail
      } else {
        name = filteredEndorsements[i - 1].recipientEmail
      }

      let relationship = '';
      if (!filteredEndorsements[i - 1].relationship) {
          relationship = 'Anonymous';
      } else if (filteredEndorsements[i - 1].relationship === 'Anonymous') {
          relationship = 'Anonymous';
      } else {
        relationship = filteredEndorsements[i - 1].relationship
      }

      let subtitle = relationship
      if (filteredEndorsements[i - 1].pathway) {
        subtitle = subtitle + ' | ' + filteredEndorsements[i - 1].pathway
      } else if (filteredEndorsements[i - 1].opportunityTitle) {
        subtitle = subtitle + ' | ' + filteredEndorsements[i - 1].opportunityTitle
      }

      let endorsementClass = "background-button full-width"
      if (window.location.pathname.includes('/apply')) {
        endorsementClass = "background-button full-width left-margin"
      }

      rows.push(
        <div key={i} className={(window.location.pathname.includes('/apply')) ? "top-margin-5" : "top-margin-20"}>
          {(window.location.pathname.includes('/apply')) && (
            <div className="close-button-container-1" style={{ marginBottom: '-12px'}}>
              <button className="background-button" onClick={() => this.removeEndorsement(i)}>
                <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
              </button>
            </div>
          )}

          <a className={endorsementClass} onClick={() => this.setState({ modalIsOpen: true, showRequestEndorsements: false, showSubEndorsementDetails: true, detailIndex })}>
            <div className="white-background medium-shadow">
              <div className="table-row-subheader full-width bottom-padding-5">
                  <span className="fixed-column-50">
                    <img src={profileIconGrey} alt="Compass profile icon icon" className="image-auto-50"/>
                  </span>
                  <span className="calc-column-offset-150 left-padding-20">
                    <div className="float-left heading-text-6 curtail-text">
                      <label>{name}</label>
                      {(incoming) ? (
                        <label className="left-padding description-text-2 cta-color">(Incoming)</label>
                      ) : (
                        <label className="left-padding description-text-2 error-color">(Outgoing)</label>
                      )}
                    </div>

                    <div className="clear" />
                    <p className="description-text-2 curtail-text">{subtitle}</p>
                  </span>

                  <div className="fixed-column-100">
                    <p className="description-text-3 full-width right-text">{formattedCA}</p>
                  </div>
                  {(filteredEndorsements[i - 1].overallRecommendation) && (
                    <div className="fixed-column-100 top-padding-5">
                      <p className="heading-text-5 cta-color full-width right-text">{Number(filteredEndorsements[i - 1].overallRecommendation) * 20}%</p>
                    </div>
                  )}
                  {/*
                  <div className="float-left">
                    <a className="background-button" onClick={() => this.setState({ confirmDelete: true, deleteIndex: detailIndex })}>
                      <img src={deleteIcon} alt="Compass exit icon" className="image-auto-10"/>
                    </a>
                  </div>*/}
                  <div className="clear" />
              </div>

              <div className="clear" />
              <div className="spacer" />
              <hr className="clear-padding horizontal-margin-30"/>
              <div className="spacer" />

              {(filteredEndorsements[i - 1].isTransparent) ? (
                <div>
                  <div className="clear"/>
                  <div className="top-padding-5 bottom-padding-20 horizontal-padding">
                    {this.renderSkillTraits(i - 1)}
                  </div>
                </div>
              ) : (
                <div className="row-10 horizontal-padding description-text-2">
                  <p>This endorsement has been marked confidential by the endorser. It will be imported into applications.</p>
                </div>
              )}

              <div className="clear"/>
              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
            </div>
          </a>
        </div>
      )

      return rows;
    }

    removeEndorsement(i) {
      console.log('removeEndorsement called', i)

      let filteredEndorsements = this.state.filteredEndorsements
      filteredEndorsements.splice(i - 1, 1)
      this.setState({ filteredEndorsements })
      this.props.passData('endorsements', filteredEndorsements, null)

    }

    renderSkillTraits(index) {
      console.log('renderEndorsedSkills called', index)

      let rows = []

      let competencies = []
      if (this.state.filteredEndorsements[index].competencies && this.state.filteredEndorsements[index].competencies.length > 0) {
        competencies = this.state.filteredEndorsements[index].competencies
      } else {
        competencies = this.state.filteredEndorsements[index].skillTraits
      }

      if (competencies) {

        for (let i = 1; i <= competencies.length; i++) {

          rows.push(
            <div key={i.toString() + 'skills'} className="float-left top-padding right-padding">
              <div className="float-left row-5 horizontal-padding-15 rounded-corners cta-border description-text-2 cta-background-color white-text" >
                <label>{competencies[i - 1].name} | {competencies[i - 1].rating}</label>
              </div>
            </div>
          )
        }
      }

      return rows
    }

    renderSpecificSkillTraits(index) {
      console.log('renderSpecificSkillTraits called', index)

      let rows = []

      let competencies = []
      if (this.state.endorsements[this.state.detailIndex].competencies && this.state.endorsements[this.state.detailIndex].competencies.length > 0) {
        competencies = this.state.endorsements[this.state.detailIndex].competencies
      } else {
        competencies = this.state.endorsements[this.state.detailIndex].skillTraits
      }

      if (competencies) {

        let hardSkills = []
        let softSkills = []
        let traits = []

        for (let i = 1; i <= competencies.length; i++) {
          console.log('looping competencies: ', competencies[i - 1])
          let skillTrait = competencies[i - 1]

          if (!skillTrait.rating) {
            skillTrait['rating'] = 0
          }
          if (competencies[i - 1].skillType === 'Hard Skill') {
            hardSkills.push(skillTrait)
          } else if (competencies[i - 1].skillType === 'Soft Skill') {
            softSkills.push(skillTrait)
          } else {
            traits.push(skillTrait)
          }
        }

        rows.push(
          <div key="skills">
            <p className="bottom-padding-5">Hard Skills</p>
            {hardSkills.map(value =>
              <div>
                {(value.rating !== "I'm Not Sure") && (
                  <div>
                    <div className="relative-column-30">
                      <p className="description-text-2 bold-text">{value.name}</p>
                    </div>
                    <div className="relative-column-65">
                      <div className="half-spacer"/>
                      <div className="progress-bar primary-border">
                        <div className="filler primary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="relative-column-5">
                      <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>
                  </div>
                )}
              </div>
            )}

            <div className="spacer"/><div className="half-spacer"/>

            {(softSkills) && (
              <div>
                <p className="bottom-padding-5">Soft Skills</p>
                {softSkills.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar secondary-border">
                            <div className="filler secondary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}
                  </div>
                )}
                <div className="spacer"/><div className="half-spacer"/>
              </div>
            )}

            {(traits) && (
              <div>
              <p className="bottom-padding-5">Traits</p>
                {traits.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar quaternary-border">
                            <div className="filler quaternary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}

                  </div>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    commandClicked(command) {
      console.log('commandClicked called')

      this.props.commandClicked(command)

    }

    render() {

        let mainClass = "width-70-percent center-horizontally max-width-1400 top-margin-2-percent card"
        let mainStyle = {}
        let mainClass2 = "width-70-percent center-horizontally max-width-1400"
        let containerClass = ""
        let subClass = "full-width"
        if (window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && window.location.pathname.startsWith('/app') && this.state.endorsements && this.state.endorsements.length > 0) {
          containerClass = "relative-column-70 padding-five-percent-left"
          mainClass = ""
          mainClass2 = ""
          subClass = "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"
        }

        if (window.location.pathname.startsWith('/organizations') && toggleVars.hideAdminSideNav) {
          // do nothing

        } else if (!window.location.pathname.includes('/app')) {
          if (window.location.pathname.startsWith('/advisor') && (this.props.roleName === 'Mentor' || this.props.roleName === 'Admin' || toggleVars.hideSideNav)) {
            // do nothing
          } else {
            mainClass = "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"
            mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
            mainClass2 = "floating-container"
          }
        }

        let linkPrefix = '/advisor'
        if (window.location.pathname.includes('/organizations')) {
          linkPrefix = '/organizations/' + this.state.activeOrg
        }

        return (
            <div>
              {(window.location.pathname.includes('/apply')) ? (
                <div>
                  <div className="row-10">
                    <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showRequestEndorsements: true, showSubEndorsementDetails: false })}>
                      <p>Request an Endorsement</p>
                    </button>
                  </div>
                  {this.renderEndorsements()}
                </div>
              ) : (
                <div>
                  {this.state.areEndorsements ? (
                    <div>
                      {(window.location.pathname.includes('/app')) ? (
                        <div>
                          <div className={containerClass}>
                            <div className={mainClass} style={mainStyle}>
                              <div className={subClass}>
                                {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && this.state.endorsements && this.state.endorsements.length > 0) && (
                                  <div className="full-width height-5 primary-background" />
                                )}

                                <div className={(this.state.showCompassAssistant && !this.state.disableChatbotPrompts && this.state.endorsements && this.state.endorsements.length > 0) ? "padding-40" : ""}>
                                  <div className="calc-column-offset-50 top-padding right-padding">
                                    <p className="heading-text-2">{(this.state.filteredEndorsements) ? this.state.filteredEndorsements.length : "0"} Endorsements</p>
                                  </div>
                                  {(this.state.showOutgoing) && (
                                    <div className="float-right top-padding right-padding-20">
                                        <Link className="clear-border" to={'/app/endorsements/send-endorsement'}><img src={addOutgoingIcon} alt="GC" /></Link>
                                    </div>
                                  )}
                                  <div className="fixed-column-50">
                                    <Link className="clear-border" to={'/app/endorsements/get-endorsements'}><img src={addIncomingIcon} alt="GC" className="image-auto-50"/></Link>
                                  </div>

                                  <div className="clear" />
                                </div>
                              </div>
                            </div>

                            <div className={mainClass2}>
                              <div>
                                <div>
                                  {(this.state.serverSuccessMessage) && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                                  {this.renderEndorsements()}
                                </div>
                              </div>
                            </div>
                          </div>

                          {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && window.location.pathname.startsWith('/app') && this.state.endorsements && this.state.endorsements.length > 0) && (
                            <div className="relative-column-30 horizontal-padding top-margin-40">
                              <div className="left-padding-20">
                                {(this.state.showCompassAssistant && !this.state.disableChatbotPrompts && this.state.endorsementCommands && this.state.endorsementCommands.length > 0) && (
                                  <div>
                                    <SubAssistantWidget commands={this.state.endorsementCommands} commandClicked={this.commandClicked} />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      ) : (
                        <div>
                          <div className={mainClass2}>
                            <div className="card top-margin-2-percent">
                              <div className="calc-column-offset-60">
                                <p className="heading-text-2">{(this.state.filteredEndorsements) ? (this.state.filteredEndorsements === this.state.resLimit) ? this.state.filteredEndorsements.length + "+" : this.state.filteredEndorsements.length : "0"} Endorsements</p>
                              </div>
                              <div className="fixed-column-60 top-padding-5">
                                <Link to={linkPrefix + '/endorsements/new'}>
                                  <img src={addIcon} alt="Compass add icon" className="add-icon pin-right"/>
                                </Link>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>

                          <div className={mainClass2}>
                            {this.renderEndorsements()}
                          </div>

                        </div>
                      )}

                    </div>
                  ) : (
                    <div>
                      <div className={mainClass} style={mainStyle}>


                        <div className={(!toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations')) ? "top-margin-30" : ""}>
                          <p className="heading-text-2">Guided Compass Endorsements!</p>
                        </div>

                        {(window.location.pathname.includes('/app')) ? (
                          <div>
                            <p className="top-margin-20">Use Guided Compass to receive endorsements from employers, teachers, counselors, and advisors on competencies relevant to your preferred career pathway.</p>

                            <p className="top-margin-20">Use these endorsements as a pick me up, for advisors to reference, or to import into work applications.</p>

                            <div className="top-margin-30">
                              <Link className="clear-border" to={'/app/endorsements/get-endorsements'}><button className="btn btn-primary">Get Endorsements</button></Link>
                              {(this.state.showOutgoing) && (
                                <Link className="clear-border" to={'/app/endorsements/send-endorsement'}><button className="btn btn-secondary">Send Endorsement</button></Link>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <p className="top-margin-20">Guided Compass Endorsements allow employers, teachers, counselors, and advisors to rate career-seekers on specific skills and provide examples of those skills.</p>

                            <p className="top-margin-20">Students/career-seekers can use endorsements as a pick me up, or to import into work applications as reusable letters of recommendation.</p>

                            <div className="top-margin-30">
                              <Link className="clear-border" to={(window.location.pathname.startsWith('/organizations')) ? '/organizations/' + this.state.activeOrg + '/endorsements/new' : '/advisor/endorsements/new'}><button className="btn btn-primary">Give an Endorsement</button></Link>
                            </div>
                          </div>
                        )}

                      </div>

                    </div>
                  )}
                </div>
              )}


              {(this.state.showSubEndorsementDetails) ? (
                <div>
                  {(this.state.endorsements && this.state.endorsements.length > 0) && (
                    <SubEndorsementDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedEndorsement={this.state.endorsements[this.state.detailIndex]} orgCode={this.state.activeOrg} />
                  )}
                </div>
              ) : (
                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 >

                 {(this.state.showRequestEndorsements) ? (
                   <div key="skillAssessment" className="full-width padding-20">
                     <SubRequestEndorsements closeModal={this.closeModal} selectedOpportunity={this.props.selectedOpportunity} />
                   </div>
                 ) : (
                   <div>
                     {(this.state.endorsements && this.state.endorsements.length > 0) && (
                       <div>
                         {(this.state.confirmDelete === true && this.state.deleteIndex === this.state.detailIndex) ? (
                           <div className="padding-5-percent">
                            <p className="heading-text-4">Delete {this.state.endorsements[this.state.detailIndex].senderFirstName} {this.state.endorsements[this.state.detailIndex].senderLastName}'s Endorsement</p>
                            <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                             <div>
                               <p className="error-message">Are you sure you want to delete this endorsement? All information will be deleted forever.</p>
                             </div>
                             <div className="clear" />
                             <div className="spacer"/><div className="spacer"/>

                             <div className="float-left right-padding-20">
                               <button className="btn btn-quaternary" onClick={() => this.deleteEndorsement(this.state.detailIndex)}>Yes, Delete</button>
                             </div>
                             <div className="float-left right-padding-20">
                               <button className="btn btn-secondary" onClick={() => this.setState({ confirmDelete: false, modalIsOpen: false, showRequestEndorsements: false })}>Cancel</button>
                             </div>
                             <div className="clear" />
                             <div className="spacer"/><div className="half-spacer"/>
                             {(this.state.serverErrorMessage) && <p className="error-message">{this.state.serverErrorMessage}</p>}
                           </div>
                         ) : (
                           <div>
                             {(this.state.endorsements[this.state.detailIndex].isTransparent) ? (
                               <div className="padding-20">
                                 {console.log('testers 1: ', typeof this.state.endorsements[this.state.detailIndex].isTransparent, this.state.endorsements[this.state.detailIndex].isTransparent)}
                                 <p className="heading-text-4 bottom-padding">Endorsement Details</p>

                                 <div key="specificEndorsement" className="row-10">
                                   <hr className="clear-margin clear-padding" />
                                   <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                   <div className="fixed-column-90">
                                     <div className="dark-background width-90 height-76 padding-10">
                                       <p className="heading-text-2 white-text bold-text center-text">{(this.state.endorsements[this.state.detailIndex].overallRecommendation) ? Number(this.state.endorsements[this.state.detailIndex].overallRecommendation) * 20 : "N/A"}%</p>
                                       <p className="description-text-5 white-text">OVERALL</p>
                                     </div>
                                   </div>
                                   <div className="endorsement-name">
                                     <p className="heading-text-4">{this.state.endorsements[this.state.detailIndex].senderFirstName} {this.state.endorsements[this.state.detailIndex].senderLastName}</p>
                                     <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                     <p className="description-text-2">{this.state.endorsements[this.state.detailIndex].relationship}</p>

                                   </div>
                                   <div className="endorsement-pathway">
                                     <p>{this.state.endorsements[this.state.detailIndex].pathway} Pathway</p>
                                     <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                     <p className="description-text-2">{this.state.endorsements[this.state.detailIndex].createdAt}</p>
                                   </div>

                                   <div className="clear" />
                                   <div className="spacer" /><div className="spacer" />

                                   <div>
                                     {this.renderSpecificSkillTraits()}
                                   </div>

                                   <div className="clear" />
                                   <div className="spacer" /><div className="spacer" />

                                   <div>
                                     <p className="heading-text-6 bold-text">Examples of Above Competencies</p>
                                     <div className="spacer" /><div className="spacer" />
                                     {this.state.endorsements[this.state.detailIndex].examples.map(value =>
                                       <div>
                                         <p className="description-text-1">{(value.competency) ? value.competency : value.skillTrait} Example</p>
                                         <div className="half-spacer"/>
                                         <p className="description-text-3 description-text-color bold-text">{value.example}</p>
                                         <div className="spacer"/><div className="spacer"/>
                                       </div>
                                     )}

                                   </div>

                                   <div className="clear" />
                                   <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                                 </div>

                               </div>
                             ) : (
                               <div className="padding-40 flex-container flex-center">

                                 <div className="center-text">
                                   <img src={confidentialityIcon} alt="Student icon" className="image-auto-100 vertical-margin-20 center-horizontally" />
                                   <div className="clear" />
                                   <p className="heading-text-4">This Endorsement is Confidential</p>
                                   <div className="spacer"/>
                                   <p className="description-text-color">This endorsement has been marked confidential by the endorser. It will automatically be imported into internship applications.</p>
                                   <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                                 </div>
                               </div>
                             )}
                           </div>
                         )}
                       </div>
                     )}
                   </div>
                 )}

                </Modal>
              )}

            </div>

        )
    }
}

export default withRouter(Endorsements);
