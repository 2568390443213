import React, {Component} from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'react-modal';
import Axios from 'axios';
import ProjectDetails from '../Subcomponents/ProjectDetails';
import SubHelpOutWidget from './HelpOutWidget';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const defaultProfileBackgroundImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/default-profile-background-image.png';

class RenderProjects extends Component {
    constructor(props) {
      super(props)

      this.state = {
        favorites: [],
        newFavorites: [],
        showSubFunction: true
      }

      this.renderItems = this.renderItems.bind(this)
      this.favoriteItem = this.favoriteItem.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.saveFeedback = this.saveFeedback.bind(this)
      this.itemClicked = this.itemClicked.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderProjects', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.projects !== prevProps.projects) {
        this.retrieveData()
      } else if (this.props.favorites !== prevProps.favorites) {
        this.retrieveData()
      } else if (this.props.sortCriteriaArray !== prevProps.sortCriteriaArray || this.props.filterCriteriaArray !== prevProps.filterCriteriaArray) {
        this.retrieveData()
      } else if (this.props.gradeOptions !== prevProps.gradeOptions) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderProjects')

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      // let roleName = localStorage.getItem('roleName');
      // console.log('show cuFirstName: ', cuFirstName)
      const favorites = this.props.favorites
      const newFavorites = this.props.newFavorites
      const jobTitle = this.props.jobTitle
      const employerName = this.props.employerName
      const org = this.props.org
      const accountCode = this.props.accountCode
      const gradeOptions = this.props.gradeOptions
      const orgName = this.props.orgName
      const orgContactEmail = this.props.orgContactEmail

      this.setState({ emailId, cuFirstName, cuLastName, username, roleName,
        favorites, newFavorites, jobTitle, employerName, org, accountCode, gradeOptions, orgName, orgContactEmail
      })
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'grade') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['grade'] = event.target.value
        this.setState({ projects })
      } else if (event.target.name === 'feedback') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['feedback'] = event.target.value
        this.setState({ projects })
      } else if (event.target.name === 'isTransparent') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['isTransparent'] = event.target.value
        this.setState({ projects })
      }
    }

    async favoriteItem(e, item) {
      console.log('favoriteItem called', e, item)

      e.stopPropagation()

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'project')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){
          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
        } else {
          // console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({ successMessage: 'Favorite saved!', isSaving: false })
          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }

    }

    renderItems() {
      console.log('renderItems called')

      let rows = []

      if (this.props.projects) {
        const projects = this.props.projects
        for (let i = 1; i <= projects.length; i++) {

          const index = i - 1

          // let pathname = '/advisor/projects/' + projects[i - 1]._id
          // let passedState = { selectedProject: projects[i - 1] }
          let tagArray = []
          if (projects[i - 1].skillTags) {
            tagArray = projects[i - 1].skillTags.split(',')
          }

          let subtitle = ''
          if (projects[i - 1].userFirstName) {
            subtitle = projects[i - 1].userFirstName + ' ' + projects[i - 1].userLastName
          }

          if (projects[i - 1].hours) {
            if (subtitle === '') {
              subtitle = projects[i - 1].hours + ' Hours'
            } else {
              subtitle = subtitle + ' | ' + projects[i - 1].hours + ' Hours'
            }
          }

          // let matchScore = projects[i - 1].matchScore
          let cardClass = 'relative-column-33 horizontal-padding-7'
          if (window.innerWidth < 768) {
            cardClass = ''
          }

          rows.push(
            <div key={index}>
              <div className={cardClass}>
                <button onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: true, showGrade: false, selectedIndex1: index })} className="background-button">
                  <div className="elevated-box white-background" >
                    <div className="full-width relative-position tint">
                      <img src={(projects[i - 1].imageURL) ? projects[i - 1].imageURL : defaultProfileBackgroundImage} alt="Trending up icon" className="image-full-width-150 center-horizontally"  />
                      <div className="absolute-position absolute-top-5 absolute-left-5">
                        {(projects[i - 1].matchScore) && (
                          <div className="image-40-auto" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                            <CircularProgressbar
                              percentage={projects[i - 1].matchScore}
                              text={`${projects[i - 1].matchScore}%`}
                              styles={{
                                path: { stroke: `rgba(255, 255, 255, ${projects[i - 1].matchScore / 100})` },
                                text: { fill: 'white', fontSize: '26px' },
                                trail: { stroke: 'transparent' }
                              }}
                            />
                          </div>
                        )}
                        <p className="description-text-5 rounded-corners horizontal-padding-4 row-5 white-border white-text bold-text" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Category: " + projects[index].category}>{projects[index].category}</p>
                      </div>
                    </div>

                    <div className="spacer" />

                    <div className="horizontal-padding left-text">
                      <p className="heading-text-5">{projects[index].name}</p>

                      <div className="top-padding-20">
                        <div className="container-left">
                          <div className="fixed-column-35">
                            <img className="profile-thumbnail-25" alt="GC" src={(projects[index].userPic) ? projects[index].userPic : profileIconDark} />
                          </div>
                          <div className="calc-column-offset-35 description-text-2">
                            <p className="heading-text-6">{projects[index].userFirstName} {projects[index].userLastName}</p>
                            <label className="description-text-3">{projects[index].hours} Hours</label>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="container-right">
                          <p className="description-text-3 full-width right-text">{(projects[index].startDate) && projects[index].startDate + " - "}{(projects[index].isContinual) ? "Present" : projects[index].endDate}</p>
                        </div>
                        <div className="clear" />

                      </div>

                      {(projects[index].collaborators && projects[index].collaborators.length > 0) && (
                        <span className="top-padding">
                          <label className="description-text-5 left-padding-5">|</label>
                          <label className="description-text-5 left-padding-5">{projects[index].collaborators.length} Collaborators</label>
                        </span>
                      )}

                      <div className="top-padding">
                        {tagArray.map((value, optionIndex) =>
                          <div key={value}>
                            {(optionIndex < 3) && (
                              <div key={value} className="float-left row-5 right-padding">
                                <div className="tag-container-7">
                                  <p className="description-text-3">{value}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>

                      <div className="clear" />

                      {(window.location.pathname.includes('/app') || window.location.pathname.includes('/advisor')) ? (
                        <div className="top-padding-20">
                          <div className={(window.location.pathname.includes('/community')) ? "display-inline full-width" : "display-inline half-width-static right-padding-5"}>
                            <button className={this.state.favorites.includes(projects[i - 1]._id) ? "btn btn-profile medium-background clear-border full-width" : "btn btn-profile full-width"} disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.favoriteItem(e, projects[i - 1], 'project')}>{this.state.favorites.includes(projects[i - 1]._id) ? "Following" : "Follow"}</button>
                          </div>
                          {(!window.location.pathname.includes('/community')) && (
                            <div className="display-inline half-width-static left-padding-5">
                              <button className="btn btn-profile-white full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.itemClicked(e,'helpOut', projects[index])}>Help Out</button>
                            </div>
                          )}

                        </div>
                      ) : (
                        <div>
                          {(this.props.pageSource === 'landingPage' || this.props.helpOut) ? (
                            <div className="top-padding-20">
                              <button className="btn btn-profile full-width" disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.itemClicked(e,'helpOut', projects[index])}>Help Out</button>
                            </div>
                          ) : (
                            <div className="top-padding-20">
                              <div className="display-inline half-width-static">
                                <button className="btn btn-profile description-text-3 full-width right-margin-5" disabled={(this.state.isSaving) ? true : false} onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: true, showGrade: false, selectedIndex1: index })}>View</button>
                              </div>
                              <div className="display-inline half-width-static">
                                {/*<button onClick={() => this.setState({ modalIsOpen: true, showMessageWidget: true, selectedIndex: i - 1 })} className="btn btn-profile description-text-3 full-width left-margin-5">Message</button>*/}
                                {(this.props.pageSource === 'BrowseProjects') ? (
                                  <button className={this.state.favorites.includes(projects[i - 1]._id) ? "btn btn-profile medium-background white-color clear-border description-text-3 full-width left-margin-5" : "btn btn-profile-white description-text-3 full-width left-margin-5"} disabled={(this.state.isSaving) ? true : false} onClick={(e) =>  this.favoriteItem(e, projects[i - 1],'project')}>{this.state.favorites.includes(projects[i - 1]._id) ? "Following" : "Follow"}</button>
                                ) : (
                                  <div>
                                    {(this.state.gradeOptions) && (
                                      <div>
                                        <button className="btn btn-profile-white description-text-3 full-width left-margin-5" onClick={(e) => this.showGrade(e, index) }>Feedback</button>
                                      </div>
                                    )}
                                  </div>

                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {(this.props.sortCriteriaArray && this.props.sortCriteriaArray[i - 1] && this.props.sortCriteriaArray[i - 1].name) && (
                        <div className="top-padding">
                          <div className="half-spacer" />
                          <p className="error-color description-text-2">{this.props.sortCriteriaArray[i - 1].name}: {this.props.sortCriteriaArray[i - 1].criteria}</p>
                        </div>
                      )}
                      {(this.props.filterCriteriaArray && this.props.filterCriteriaArray[i - 1] && this.props.filterCriteriaArray[i - 1].name) && (
                        <div className="top-padding">
                          <div className="half-spacer" />
                          <p className="error-color description-text-2">{this.props.filterCriteriaArray[i - 1].name}: {this.props.filterCriteriaArray[i - 1].criteria}</p>
                        </div>
                      )}

                      <div className="spacer" />
                    </div>
                  </div>
                </button>
              </div>

              {(i % 3 === 0 && window.innerWidth >= 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}

              {(window.innerWidth < 768) && (
                <div>
                  <div className="clear" />
                  <div className="spacer" />
                </div>
              )}

              <ReactTooltip id="tooltip-placeholder-id" />

            </div>
          )
        }
      }

      return rows
    }

    showGrade(e, index) {
      console.log('showGrade called', e)

      e.stopPropagation()

      const modalIsOpen = true
      const selectedIndex1 = index
      const showProjectDetail = false
      const showGrade = true

      let selectedIndex2 = 0
      let filteredProjects = this.props.projects

      const contributorFirstName = this.state.cuFirstName
      const contributorLastName = this.state.cuLastName
      const contributorEmail = this.state.emailId
      const contributorRoleName = this.props.roleName
      const contributorTitle = this.state.jobTitle
      const contributorEmployerName = this.state.employerName

      let orgCode = ''
      if (this.state.org && this.state.org !== '') {
        orgCode = this.state.org
      }

      let accountCode = ''
      if (this.state.accountCode) {
        accountCode = this.state.accountCode
      }

      const isApproved = false
      const updatedAt = new Date()

      if (filteredProjects[index].grades && filteredProjects[index].grades.length > 0) {
        //find the case where provideEmail matches this email, otherwise add to grades array
        console.log('show grades 1: ', filteredProjects[index].grades[0].contributorEmail, this.state.emailId)

        //determine selectedIndex2
        let existingGrade = false
        for (let i = 1; i <= filteredProjects[index].grades.length; i++) {
          if (filteredProjects[index].grades[i - 1].contributorEmail === this.state.emailId) {
            selectedIndex2 = i - 1
            existingGrade = true
          }
        }

        if (existingGrade === false) {
          selectedIndex2 = filteredProjects[index].grades.length
          filteredProjects[index]['grades'][selectedIndex2] =
            { contributorFirstName, contributorLastName, contributorEmail, contributorRoleName, contributorTitle, contributorEmployerName,
              isTransparent: true, orgCode, accountCode, isApproved, updatedAt }
        }
        console.log('show update: ', existingGrade, selectedIndex2)

      } else {
        console.log('no grades to be found')
        filteredProjects[index]['grades'] = [
          { contributorFirstName, contributorLastName, contributorEmail, contributorRoleName, contributorTitle, contributorEmployerName,
            isTransparent: true, orgCode, accountCode, isApproved, updatedAt }
        ]

      }
      console.log('show contributorRoleName ', contributorFirstName, contributorLastName, contributorRoleName, contributorTitle, contributorEmployerName, filteredProjects[index].grades)

      this.setState({ showProjectDetail, showGrade, modalIsOpen, selectedIndex1, selectedIndex2, filteredProjects })
    }

    closeModal() {
      console.log('closeModal in projects: ')

      this.setState({ modalIsOpen: false, showProjectDetail: false, showGrade: false, selectedIndex1: null,
        showHelpOutWidget: false, selectedProject: null
      });

    }

    saveFeedback() {
      console.log('saveFeedback called')

      const grade = this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].grade
      const feedback = this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].feedback
      const isTransparent = this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].isTransparent

      //must set transparency level and give grade or feedback
      if (!grade && !feedback) {
        this.setState({ serverErrorMessage: 'please add a grade or provide feedback'})
      } else if (!isTransparent || isTransparent === '') {
        this.setState({ serverErrorMessage: 'please indicate whether this feedback is transparent to the student '})
      } else {

        const emailId = this.state.emailId
        const postingId = null
        const submissionId = null
        const gradeObject = this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2]

        const projectId = this.props.projects[this.state.selectedIndex1]._id
        const orgContactEmail = this.state.orgContactEmail

        // console.log('show titles 2: ', gradeObject)

        //save submission to opportunity + project
        Axios.post('/api/projects/feedback', { emailId, postingId, submissionId, gradeObject, projectId, orgContactEmail })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Project feedback save worked ', response.data);
            //report whether values were successfully saved
            this.setState({ serverSuccessMessage: 'Project feedback successfully saved!', modalIsOpen: false })

          } else {
            console.log('project feedback did not save successfully')
            this.setState({ serverErrorMessage: response.data.message })
          }

        }).catch((error) => {
            console.log('Project feedback save did not work', error);
            this.setState({ serverErrorMessage: error })
        });
      }
    }

    itemClicked(e, type, value) {
      console.log('itemClicked called', e, type, value)

      e.preventDefault()
      e.stopPropagation()

      let profileData = {
        firstName: value.userFirstName,
        lastName: value.userLastName,
        email: value.emailId,
        pictureURL: value.userPictureURL,
        username: value.userUsername
      }

      this.setState({ modalIsOpen: true, showHelpOutWidget: true, selectedProject: value, profileData })
    }

    render() {

      return (
        <div>
          {this.renderItems()}

          {(this.state.showProjectDetail) ? (
            <div>
              <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.props.projects[this.state.selectedIndex1]} orgCode={this.state.org} />
            </div>
          ) : (
              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

              {(this.state.showGrade) && (
                <div key="gradeProject">
                  <div className="full-width padding-20">
                    {(this.props.projects[this.state.selectedIndex1]) && (
                      <div>
                        <div className="edit-profile-row">
                          <p className="heading-text-4">{this.props.projects[this.state.selectedIndex1].name} - Add Grade & Feedback</p>
                          <div className="half-spacer" />
                          <label className="description-text-2 half-bold-text">Add a grade and feedback here. Browse previous feedback below.</label>
                        </div>

                        <div className="edit-profile-row">
                          <p className="profile-label">Grade The Project (You Can Edit Later)</p>
                          <select name="grade" className="dropdown" value={this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].grade} onChange={this.formChangeHandler}>
                            {this.state.gradeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="edit-profile-row">
                          <p className="profile-label">Provide Constructive Feedback</p>
                          <textarea className="text-field" name={"feedback"} placeholder="Project feedback" value={this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].feedback} onChange={this.formChangeHandler} />
                        </div>

                        <div className="edit-profile-row">
                          <p className="profile-label">Transparency to Student</p>
                          <select name="isTransparent" className="dropdown" value={this.props.projects[this.state.selectedIndex1].grades[this.state.selectedIndex2].isTransparent} onChange={this.formChangeHandler}>
                            <option value={true}>Students can see the feedback [Keep transparent]</option>
                            <option value={false}>Students cannot see the feedback [Keep confidential]</option>
                          </select>
                          <label className="description-text-2">Note: this feedback is viewable by teachers, counselors, mentors, and work placement organizations.</label>
                        </div>

                        <div>
                          { (this.state.clientErrorMessage!== '') && <p className="error-message">{this.state.clientErrorMessage}</p> }
                          { (this.state.serverPostSuccess) ? (
                            <p className="success-message">{this.state.serverSuccessMessage}</p>
                          ) : (
                            <p className="error-message">{this.state.serverErrorMessage}</p>
                          )}

                          <div className="float-left">
                            <button className="btn btn-primary" onClick={() => this.saveFeedback()}>Save Feedback</button>
                          </div>

                          <div className="float-left left-padding">
                            <button className="btn btn-secondary" onClick={() => this.setState({ modalIsOpen: false })}>Cancel</button>
                          </div>

                          <div className="clear" />
                        </div>

                        <div className="spacer"/><div className="spacer"/>
                        <hr className="clear-margin clear-padding"/>
                        <div className="spacer"/><div className="spacer"/>

                        <p className="heading-text-6">Other Feedback on {this.props.projects[this.state.selectedIndex1].name}</p>
                        <div className="spacer"/><div className="spacer"/>

                        {this.props.projects[this.state.selectedIndex1].grades.map((value, index) =>
                          <div key={value}>
                            {(index !== this.state.selectedIndex2) && (
                              <div>
                                <div>
                                  <div className="calc-column-offset-60 heading-text-2 cta-color half-bold-text">
                                    <p>{value.grade}</p>
                                  </div>
                                  <div className="calc-column-offset-60">
                                    <p className="description-text-2">{value.contributorFirstName} {value.contributorLastName} ({value.contributorRoleName}) {(value.contributorTitle) && " - " + value.contributorTitle + " @ " + value.contributorEmployerName}</p>
                                    <p className="heading-text-6">{value.feedback}</p>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="spacer" /><div className="spacer" />
                              </div>
                            )}
                          </div>
                        )}

                        <div className="spacer" /><div className="spacer" />
                      </div>
                    )}
                  </div>

                  <div className="row-20">
                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}

              {(this.state.showHelpOutWidget) && (
                <div>
                  <SubHelpOutWidget profileData={this.state.profileData} selectedProject={this.state.selectedProject}
                    closeModal={this.closeModal} history={this.props.navigate}
                    selectedPeople={this.state.selectedPeople} selectedLinks={this.state.selectedLinks}
                    selectedTimes={this.state.selectedTimes} selectedProjects={this.state.selectedProjects}
                    selectedCareers={this.state.selectedCareers} orgCode={this.state.org}
                    orgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail}
                  />
               </div>
              )}


             </Modal>
          )}
        </div>
      )
    }
}

export default RenderProjects;
