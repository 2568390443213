import React, {Component} from 'react';
import LoginForm from './Subcomponents/LoginForm';
import withRouter from './Functions/WithRouter';

class AdvSignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
      console.log('componentDidMount advsignin')

      const fromExternal = this.props.location.state
      if (fromExternal) {
          setTimeout(() => {
              this.setState({animate: true, opacity: 1, fromExternal})
          }, 0.7)
      } else {
          this.setState({animate: true, opacity: 1, fromExternal })
      }
    }

    render() {

      return (
        <div>
          <LoginForm roleName={this.state.roleName} orgCode={this.state.activeOrg} courseId={this.state.courseId} fromExternal={this.state.fromExternal} history={this.props.navigate} type="SignIn" />
        </div>
      )
    }
}

export default withRouter(AdvSignIn)
