import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {signOut} from '../components/Services/AuthRoutes';

const logoImgDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-guided-compass-logo.png';
const logoImgBlack = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Logo-black.png";
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const dropdownArrow = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const profileIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-white.png';
const homeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/home-icon-dark.png';
const settingsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon-dark.png';
const exitIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exit-icon-dark.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const naviconIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/navicon-icon-dark.png';
const naviconIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/navicon-icon-white.png';

class TopNavigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mobileListClass: 'main-nav',
            mobileListClassDark: 'main-nav-dark',
            darkBackgroundText: 'white-text',
            isVisible: true,
            showIntermediaries: true,
            hideBenefits: true,

            customerTabs: [],
            solutionTabs: [
              { name: 'Transparent Pathways', url: '/benefits/transparent-pathways'},
              { name: 'Self-Awareness', url: '/benefits/self-awareness'},
              { name: 'Career Exploration', url: '/benefits/career-exploration'},
              { name: 'Career Planning', url: '/benefits/career-planning'},
              { name: 'Project-Based Learning', url: '/benefits/project-based-learning'},
              { name: 'Jobs & WBL', url: '/benefits/work'},
              { name: 'Community & Alumni', url: '/benefits/community'},
              { name: 'Courses & Lessons', url: '/benefits/learning'},
              { name: 'Financial Planning', url: '/benefits/financial-planning'},
              { name: 'Entrepreneurship', url: '/benefits/entrepreneurship'},
            ],
            resourceTabs: [
              { name: 'Blog', url: '/blog'},
              { name: 'Whitepapers', url: '/whitepapers'},
              { name: 'Help Articles', url: '/help'},
              // { name: 'Tools & Features', url: '/trends'},
              // { name: 'Marketplace', url: '/marketplace'},
              // { name: 'Workshops', url: '/workshops'},
            ],
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.mobileNav = this.mobileNav.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
        this.logout = this.logout.bind(this)

    }

    static defaultProps = { currentPage: "frontPage", isHiddenProps: true }

    componentDidMount() {
      console.log('topNavigation called');

      let emailId = localStorage.getItem('email');

      let navClass = "sticky-nav bottom-padding-30 white-background"
      let mobileListClass = 'main-nav'
      if (this.props.darkBackground) {
        navClass = "sticky-nav bottom-padding-30 contact-top-nav"
        mobileListClass = 'main-nav-dark'
      }

      let customerTabs = [
        { name: 'Employers', url: '/employers'},
        { name: 'Mentors', url: '/mentors'},
        { name: 'Workforce', url: '/workforce'},
        { name: 'Educators', url: '/educators'},
        { name: 'Career-Seekers', url: '/career-seekers'},
      ]
      if (this.state.showIntermediaries) {
        customerTabs = [
          { name: 'Employers', url: '/employers'},
          { name: 'Intermediaries', url: '/intermediaries'},
          { name: 'Career-Seekers', url: '/career-seekers'},
        ]
      }

      this.setState({ emailId, navClass, mobileListClass, customerTabs })

      if (emailId) {

        let cuFirstName = localStorage.getItem('firstName');
        let cuLastName = localStorage.getItem('lastName');
        let accountCode = localStorage.getItem('emp');
        let roleName = localStorage.getItem('roleName');
        let orgFocus = localStorage.getItem('orgFocus');

        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {

            if (response.data.success) {
              console.log('User profile query worked', response.data);

              const activeOrg = response.data.user.activeOrg
              const pictureURL = response.data.user.pictureURL
              const headline = response.data.user.headline

              let editProfileLink = '/app/edit-profile'
              let homeLink = '/app'
              let settingsLink = '/app/settings'
              if (roleName === 'Student' || roleName === 'Career-Seeker') {
                editProfileLink = '/app/edit-profile'
                homeLink = '/app'
                settingsLink = '/app/settings'
              } else if (roleName === 'Admin' || roleName === 'Work-Based Learning Coordinator' || roleName === 'WBLC') {
                editProfileLink = '/organizations/' + activeOrg + '/edit-profile/basics'
                homeLink = '/organizations/' + activeOrg + '/dashboard'
                settingsLink = '/organizations/' + activeOrg + '/settings'
              } else if (roleName === 'Employer') {
                editProfileLink = '/employers/' + accountCode + '/edit-profile'
                homeLink = '/employers/' + accountCode + '/dashboard'
                settingsLink = '/employers/' + accountCode + '/settings'
              } else if (roleName === 'Mentor') {
                editProfileLink = '/advisor/edit-profile'
                homeLink = '/advisor/dashboard'
                settingsLink = '/advisor/settings'
              } else {
                // educator
                console.log('role name 2? ', roleName)
                editProfileLink = '/advisor/edit-profile'
                homeLink = '/advisor/dashboard'
                settingsLink = '/advisor/settings'
              }
              console.log('role name? ', roleName)

              this.setState({
                cuFirstName, cuLastName, accountCode, roleName, orgFocus,
                activeOrg, pictureURL, headline,
                editProfileLink, homeLink, settingsLink
              })

            } else {
              console.log('no user details found', response.data.message)

            }

        }).catch((error) => {
            console.log('User profile query did not work', error);
        });
      }

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in topNav ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in topNavigation')

      if (this.props.activeOrg) {
        const activeOrg = this.props.activeOrg

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgLogo = response.data.orgInfo.webLogoURIColor
            const orgLogoWhite = response.data.orgInfo.webLogoURI
            this.setState({ activeOrg, orgLogo, orgLogoWhite })

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    mobileNav (event) {
        console.log('mobile nav clicked')

        if (this.props.darkBackground) {

          if (this.state.mobileListClass === "main-nav-dark") {
              this.setState({
                  mobileListClass: 'mobile-nav',
                  isVisible: false, darkBackgroundText: '#1e1e1e'
              })
          } else {
              this.setState({
                  mobileListClass: 'main-nav-dark',
                  isVisible: true, darkBackgroundText: 'white'
              })
          }
        } else {
          if (this.state.mobileListClass === "main-nav") {
              this.setState({
                  mobileListClass: 'mobile-nav',
                  isVisible: false
              })
          } else {
              this.setState({
                  mobileListClass: 'main-nav',
                  isVisible: true
              })
          }
        }
    }

    changeSubNavSelected(subNavSelected, resetStateVar) {
      console.log('changeSubNavSelected called', subNavSelected, resetStateVar)

      this.setState({ [resetStateVar]: false})

      if (this.state.solutionTabs[0].name === subNavSelected) {
        subNavSelected = 'Transparent Career Pathways'
      } else if (this.state.solutionTabs[1].name === subNavSelected) {
        subNavSelected = 'Self-Awareness'
      } else if (this.state.solutionTabs[2].name === subNavSelected) {
        subNavSelected = 'Career Awareness & Exploration'
      } else if (this.state.solutionTabs[3].name === subNavSelected) {
        subNavSelected = 'Career Planning'
      } else if (this.state.solutionTabs[4].name === subNavSelected) {
        subNavSelected = 'Project-Based Learning'
      } else if (this.state.solutionTabs[5].name === subNavSelected) {
        subNavSelected = 'Work and Work-Based Learning'
      } else if (this.state.solutionTabs[6].name === subNavSelected) {
        subNavSelected = 'Community, Alumni, & Mentorship'
      } else if (this.state.solutionTabs[7].name === subNavSelected) {
        subNavSelected = 'Lessons, Courses, and Resources'
      } else if (this.state.solutionTabs[8].name === subNavSelected) {
        subNavSelected = 'Financial Planning'
      } else if (this.state.solutionTabs[9].name === subNavSelected) {
        subNavSelected = 'Entrepreneurship'
      }
      console.log('show it: ', subNavSelected)
      this.props.changeSubNavSelected(subNavSelected)

    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        const emailId = null
        const showProfileTabs = false
        this.setState({ emailId, showProfileTabs })

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isLoading: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    render() {

        let profileIcon = profileIconDark
        if (this.props.darkBackground) {
          profileIcon = profileIconWhite
        }

        let showOrgLogo = false
        if (this.state.activeOrg && this.state.activeOrg !== 'guidedcompass' && this.state.orgLogo) {
          showOrgLogo = true
        }

        return (
            <nav className={this.state.navClass}>
                <div className="row">
                  {(showOrgLogo && window.innerWidth < 768) ? (
                    <div>
                      <div>
                        <Link to={'/'}><img src={(this.props.darkBackground) ? this.state.orgLogoWhite : this.state.orgLogo} alt="GC" className={"image-auto-50 float-left top-margin-25"} /></Link>
                        {/*<Link to={'/'}><img src={logoImgBlack} alt="GC" className="logo-black" /></Link>*/}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <Link to={'/'}><img src={(this.props.darkBackground) ? logoImgDark : logoImg} alt="GC" className={(showOrgLogo) ? "image-auto-40 float-left top-margin-30" : "image-auto-50 float-left top-margin-25"} /></Link>
                        <Link to={'/'}><img src={logoImgBlack} alt="GC" className="logo-black" /></Link>
                      </div>

                      {(showOrgLogo && ((this.props.darkBackground && this.state.orgLogoWhite) || (!this.props.darkBackground && this.state.orgLogo))) && (
                        <div className="float-left left-padding">
                          <div className="float-left horizontal-padding-4">
                            <Link to={'/partners/' + this.state.activeOrg} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Learn more about our partnership"><img src={(this.props.darkBackground) ? addIcon : addIconBlue} alt="GC" className="image-auto-20 float-left top-margin-40" /></Link>
                          </div>
                          <div className="float-left">
                            <Link to={'/partners/' + this.state.activeOrg} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Learn more about our partnership"><img src={(this.props.darkBackground) ? this.state.orgLogoWhite : this.state.orgLogo} alt="GC" className="image-auto-40 float-left top-margin-30" /></Link>
                          </div>
                          <ReactTooltip id="tooltip-placeholder-id" />
                        </div>
                      )}
                    </div>
                  )}

                  <div>
                    {(this.props.currentPage === "problemPlatformPage" || this.props.currentPage === "assignmentsPage" || this.props.currentPage === "problemsPage" || this.props.currentPage === "challengesPage" || this.props.currentPage === "addProblemPage" || this.props.currentPage === "problemDetailsPage" || this.props.currentPage === "editProfilePage") ? (
                      <ul className={this.state.mobileListClass}>
                        <li className={(this.props.currentPage === "problemPlatformPage") ? " selected-nav-item-1" : ""}><Link to={'/problem-platform'}>Home</Link></li>
                        <li className={(this.props.currentPage === "assignmentsPage") ? " selected-nav-item-1" : ""}><Link to={'/problem-platform/assignments'} >Assignments</Link></li>
                        <li className={(this.props.currentPage === "problemsPage") ? " selected-nav-item-1" : ""}><Link to={'/problem-platform/problems'}>Problems</Link></li>
                        <li className={(this.props.currentPage === "challengesPage") ? " selected-nav-item-1" : ""}><Link to={'/problem-platform/challenges'}>Challenges</Link></li>
                        <li className={(this.props.currentPage === "addProblemPage") ? " selected-nav-image" : ""}><Link to={'/problem-platform/add'} ><img src={addIcon} alt="GC Icon" className="image-auto-15" /></Link></li>
                        <li />
                        {(!this.state.emailId) ? (
                          <li><Link to={'/join'}>Join</Link></li>
                        ) : (
                          <li className="bottom-margin-negative-5">
                            <div>
                              <div className="vertical-separator right-margin float-left"/>
                              <button className="background-button clear-margin clear-padding relative-position right-margin float-left" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                                <div>
                                  <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIcon} alt="GC" className="profile-thumbnail-25 white-outline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Profile"/>
                                  <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                                  <div className="clear" />
                                </div>
                              </button>
                              <div className="clear" />
                              {/*<button className={(this.props.darkBackground) ? "background-button description-text-1 relative-position right-margin " + this.state.darkBackgroundText : "background-button description-text-1 relative-position right-margin"} onClick={(this.state.showBeneficiaryTabs) ? () => this.setState({ showBeneficiaryTabs: false }) : () => this.setState({ showBeneficiaryTabs: true, showSolutionTabs: false, showResourceTabs: false })}>

                                {this.state.emailId}
                                <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                              </button>*/}
                            </div>
                          </li>
                        )}
                      </ul>
                    ) : (
                      <ul className={this.state.mobileListClass}>
                        {(showOrgLogo) ? (
                          <span>

                            <li><Link to={(showOrgLogo) ? '/partners/' + this.state.activeOrg : '/about'}>About</Link></li>

                            {(this.state.emailId) ? (
                              <li className="bottom-margin-negative-5">
                                <div>
                                  <div className="vertical-separator right-margin float-left"/>
                                  <button className="background-button clear-margin clear-padding relative-position right-margin float-left" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                                    <div>
                                      <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIcon} alt="GC" className="profile-thumbnail-25 white-outline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Profile"/>
                                      <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                                      <div className="clear" />
                                    </div>
                                  </button>
                                  <div className="clear" />
                                  {/*<button className={(this.props.darkBackground) ? "background-button description-text-1 relative-position right-margin " + this.state.darkBackgroundText : "background-button description-text-1 relative-position right-margin"} onClick={(this.state.showBeneficiaryTabs) ? () => this.setState({ showBeneficiaryTabs: false }) : () => this.setState({ showBeneficiaryTabs: true, showSolutionTabs: false, showResourceTabs: false })}>

                                    {this.state.emailId}
                                    <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                                  </button>*/}
                                </div>
                              </li>
                            ) : (
                              <li><Link to={(showOrgLogo) ? '/organizations/' + this.state.activeOrg + '/career-seeker/join' : '/join'}><button className="btn btn-primary description-text-1">Get Started</button></Link></li>
                            )}
                          </span>
                        ) : (
                          <span>
                            <li>
                              <button className={(this.props.darkBackground) ? "background-button heading-text-4 relative-position right-margin " + this.state.darkBackgroundText : "background-button heading-text-4 relative-position right-margin"} onClick={(this.state.showBeneficiaryTabs) ? () => this.setState({ showBeneficiaryTabs: false }) : () => this.setState({ showBeneficiaryTabs: true, showSolutionTabs: false, showResourceTabs: false })}>
                                Beneficiaries
                                <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '8px', right: '-18px' }}/>
                              </button>
                            </li>

                            {(!this.state.hideBenefits) && (
                              <li>
                                <button className={(this.props.darkBackground) ? "background-button heading-text-4 relative-position right-margin " + this.state.darkBackgroundText : "background-button heading-text-4 relative-position right-margin"} onClick={(this.state.showSolutionTabs) ? () => this.setState({ showSolutionTabs: false }) : () => this.setState({ showSolutionTabs: true, showBeneficiaryTabs: false, showResourceTabs: false })}>
                                  Benefits
                                  <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '8px', right: '-18px' }}/>
                                </button>
                              </li>
                            )}

                            <li>
                              <button className={(this.props.darkBackground) ? "background-button heading-text-4 relative-position right-margin " + this.state.darkBackgroundText : "background-button heading-text-4 relative-position right-margin"} onClick={(this.state.showResourceTabs) ? () => this.setState({ showResourceTabs: false }) : () => this.setState({ showResourceTabs: true, showBeneficiaryTabs: false, showSolutionTabs: false })}>
                                Resources
                                <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '8px', right: '-18px' }}/>
                              </button>
                            </li>

                            <li><Link to={'/about'}>About</Link></li>

                            {(this.state.emailId) ? (
                              <li className="bottom-margin-negative-5">
                                <div>
                                  <div className="vertical-separator right-margin float-left"/>
                                  <button className="background-button clear-margin clear-padding relative-position right-margin float-left" onClick={(this.state.showProfileTabs) ? () => this.setState({ showProfileTabs: false }) : () => this.setState({ showProfileTabs: true })}>
                                    <div>
                                      <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIcon} alt="GC" className="profile-thumbnail-25 white-outline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Profile"/>
                                      <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                                      <div className="clear" />
                                    </div>
                                  </button>
                                  <div className="clear" />
                                  {/*<button className={(this.props.darkBackground) ? "background-button description-text-1 relative-position right-margin " + this.state.darkBackgroundText : "background-button description-text-1 relative-position right-margin"} onClick={(this.state.showBeneficiaryTabs) ? () => this.setState({ showBeneficiaryTabs: false }) : () => this.setState({ showBeneficiaryTabs: true, showSolutionTabs: false, showResourceTabs: false })}>

                                    {this.state.emailId}
                                    <img src={dropdownArrow} alt="GC" className="image-auto-8 absolute-position" style={{ bottom: '5px', right: '-18px' }}/>
                                  </button>*/}
                                </div>
                              </li>
                            ) : (
                              <li><Link to={'/join'}><button className="btn btn-primary description-text-1">Get Started</button></Link></li>
                            )}
                          </span>
                        )}

                      </ul>
                    )}

                    <button className="mobile-nav-icon" onClick={() => this.mobileNav()}>
                      <img src={(this.props.darkBackground) ? naviconIconWhite : naviconIconDark} alt="GC" className="image-25-fit top-margin-13" />
                    </button>
                  </div>
                </div>

                {(this.state.showBeneficiaryTabs) && (
                  <div className="customers-dropdown-menu-container">
                    {(window.innerWidth > 768) && (
                      <div className="relative-column-40 padding-40">
                        <div className="left-padding-fifteen-percent right-border">
                          <p className="heading-text-1 bottom-padding">Beneficiaries</p>
                          <p className="heading-text-5 description-text-color top-padding">The Guided Compass Platform connects three interrelated groups that all benefit from career-seekers finding purposeful work.</p>
                        </div>
                      </div>
                    )}

                    <div className={(window.innerWidth > 768) ? "relative-column-60 padding-40 top-margin-negative-20" : "full-width padding-20"}>
                      <div className={(window.innerWidth > 768) ? "right-padding-fifteen-percent" : ""}>
                        {this.state.customerTabs.map((item, index) =>
                          <div key={index} className="bottom-margin-20">
                            <div className={(index % 2 === 1) ? "container-left" : "container-right"}>
                              <Link className="top-margin horizontal-padding heading-text-2 standard-color clear-decoration background-button-option padding-10" to={item.url} state={{ subNavSelected: item.name }}>
                                {item.name}
                              </Link>
                            </div>
                            {(index % 2 === 1 && (window.innerWidth > 768)) && (
                              <div className="clear" />
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                {(this.state.showSolutionTabs) && (
                  <div className="customers-dropdown-menu-container">
                    {(window.innerWidth > 768) && (
                      <div className="relative-column-40 padding-40">
                        <div className="left-padding-fifteen-percent right-border">
                          <p className="heading-text-1 bottom-padding">Benefits</p>
                          <p className="heading-text-5 description-text-color top-padding">We've highlighted some of the primary benefits of the Guided Compass platform.</p>
                        </div>
                      </div>
                    )}

                    <div className={(window.innerWidth > 768) ? "relative-column-60 padding-40 top-margin-negative-20" : "full-width padding-20"}>
                      <div className={(window.innerWidth > 768) ? "right-padding-fifteen-percent" : ""}>
                        {this.state.solutionTabs.map((item, index) =>
                          <div key={index} className="bottom-margin-20">
                            <div className={(index % 2 === 1) ? "container-left" : "container-right"}>

                              {(window.location.pathname.includes('/benefitsasd')) ? (
                                <button className="heading-text-3 standard-color clear-decoration background-button-option left-text" onClick={() => this.changeSubNavSelected(item.name,'showSolutionTabs')}>
                                  {item.name}
                                </button>
                              ) : (
                                <Link className="horizontal-padding heading-text-3 standard-color clear-decoration background-button-option padding-10" onClick={() => this.changeSubNavSelected(item.name,'showSolutionTabs')} to={item.url} state={{ subNavSelected: item.name }}>
                                  {item.name}
                                </Link>
                              )}
                            </div>
                            {(index % 2 === 1 && (window.innerWidth > 768)) && (
                              <div className="clear" />
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                {(this.state.showResourceTabs) && (
                  <div className="customers-dropdown-menu-container">
                    {(window.innerWidth > 768) && (
                      <div className="relative-column-40 padding-40">
                        <div className="left-padding-fifteen-percent right-border">
                          <p className="heading-text-1 bottom-padding">Resources</p>
                          <p className="heading-text-5 description-text-color top-padding">We've highlighted some resources for our community.</p>
                        </div>
                      </div>
                    )}

                    <div className={(window.innerWidth > 768) ? "relative-column-60 padding-40 top-margin-negative-20" : "full-width padding-20"}>
                      <div className={(window.innerWidth > 768) ? "right-padding-fifteen-percent" : ""}>
                        {this.state.resourceTabs.map((item, index) =>
                          <div key={index} className="bottom-margin-20">
                            <div className={(index % 2 === 1) ? "container-left" : "container-right"}>
                              <Link className="horizontal-padding heading-text-2 standard-color clear-decoration background-button-option padding-10" to={item.url} state={{ subNavSelected: item.name }}>
                                {item.name}
                              </Link>
                            </div>
                            {(index % 2 === 1 && (window.innerWidth > 768)) && (
                              <div className="clear" />
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                )}

                {(this.state.showProfileTabs) && (
                  <div className="profile-dropdown-menu-container-external">
                    <div className="top-padding bottom-padding-5">

                      <Link to={this.state.editProfileLink} className="background-button standard-color full-width">
                        <div>
                          <div className="fixed-column-50 right-padding">
                            <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1 standard-border" />
                          </div>
                          <div className="calc-column-offset-50">
                            <p className="heading-text-6">{this.state.cuFirstName} {this.state.cuLastName}</p>
                            <p className="description-text-3">{(this.state.headline && this.state.headline !== '') ? this.state.headline : this.state.roleName}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </Link>

                      <div className="spacer" />

                      <hr />

                      <div className="spacer" />

                      <div className="row-5">
                        <div>
                          <span className="full-width left-padding-5">
                            <Link className="background-button full-width right-padding-20 standard-color" to={this.state.homeLink}>
                              <div className="row-5">
                                <div className="float-left right-padding">
                                  <img src={homeIconDark} alt="GC" className="image-18-fit" />
                                </div>
                                <div className="float-left">
                                  <p className="description-text-2">{(this.state.roleName === 'Student' || this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Other') ? "Home" : "Dashboard"}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </Link>
                          </span>
                        </div>
                        <div>
                          <span className="full-width left-padding-5">
                            <Link className="background-button full-width right-padding-20 standard-color" to={this.state.settingsLink}>
                              <div className="row-5">
                                <div className="float-left right-padding">
                                  <img src={settingsIconDark} alt="GC" className="image-18-fit" />
                                </div>
                                <div className="float-left">
                                  <p className="description-text-2">Settings</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </Link>
                          </span>
                        </div>

                        <div>
                          <span className="full-width left-padding-5">
                            <button className="background-button full-width right-padding-20 standard-color" onClick={() => this.logout() }>
                              <div className="row-5">
                                <div className="float-left right-padding">
                                  <img src={exitIconDark} alt="GC" className="image-18-fit" />
                                </div>
                                <div className="float-left">
                                  <p className="description-text-2">Log Out</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="clear" />

                    </div>
                  </div>
                )}

            </nav>
        )
    }
}

export default TopNavigation;
