import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSideNav from '../Common/SideNav';
import SubPrograms from '../Subcomponents/Programs';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

class OrgPrograms extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let username = localStorage.getItem('username');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        const path = window.location.pathname

        this.setState({ org, emailId: email, username, orgFocus, roleName, orgLogo, path })

      }
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            {(!toggleVars.hideAdminSideNav) && (
              <SubSideNav selectedComponent={"OrgPrograms"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
            )}

            <SubPrograms activeOrg={this.state.org} accountCode={this.state.accountCode} history={this.props.navigate} />
            <div className="clear" />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(OrgPrograms)
