import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import EditPosting from './Subcomponents/EditPosting';
import withRouter from './Functions/WithRouter';

class EmpEditJob extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp, id } = this.props.params

      let selectedOpportunity = null
      let jobs = null
      let objectId = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedOpportunity = this.props.location.state.selectedOpportunity
        jobs = this.props.location.state.jobs
        objectId = this.props.location.state.objectId
        duplicate = this.props.location.state.duplicate
      }

      let postingId = null
      if (id) {
        postingId = id
      }

      if (objectId) {
        postingId = objectId
      }

      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      this.setState({ emailId: email, username, cuFirstName, cuLastName,
        activeOrg, orgMode, emp, orgLogo,
        selectedOpportunity, postingId, jobs, duplicate
      })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
          <div>
            <EditPosting org={this.state.activeOrg} accountCode={this.state.emp} selectedOpportunity={this.state.selectedOpportunity} editMode={true} jobs={this.state.jobs} roleName="Employer" path={window.location.pathname} history={this.props.navigate} duplicate={this.state.duplicate} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(EmpEditJob)
