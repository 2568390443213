import React, {Component} from 'react';

import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import {convertDateToString} from '../Functions/ConvertDateToString';

const confidentialityIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/confidentiality-icon.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

class EndorsementDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderSpecificSkillTraits = this.renderSpecificSkillTraits.bind(this)
        this.renderEndorsement = this.renderEndorsement.bind(this)
        this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in endorsementDetails! ', this.props, prevProps)

      if (this.props.selectedEndorsement !== prevProps.selectedEndorsement) {
        console.log('t0')
        this.retrieveData()
      } else if (this.props.modalIsOpen !== prevProps.modalIsOpen) {
        console.log('t1')
        this.retrieveData()
      } else if (this.props.selectedEndorsement) {
        // look for changes in the following
        console.log('transparent compare: ', this.props.selectedEndorsement.isTransparent, prevProps.selectedEndorsement.isTransparent)

        if (this.props.selectedEndorsement.senderFirstName !== prevProps.selectedEndorsement.senderFirstName) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.senderLastName !== prevProps.selectedEndorsement.senderLastName) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.senderEmail !== prevProps.selectedEndorsement.senderEmail) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.relationship !== prevProps.selectedEndorsement.relationship) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.pathway !== prevProps.selectedEndorsement.pathway) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.overallRecommendation !== prevProps.selectedEndorsement.overallRecommendation) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.recommendationExplanation !== prevProps.selectedEndorsement.recommendationExplanation) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.isTransparent !== prevProps.selectedEndorsement.isTransparent) {
          this.retrieveData()
        } else if (this.props.selectedEndorsement.skillTraits) {
          let somethingChanged = false
          let skillTraits1 = this.props.selectedEndorsement.skillTraits
          let skillTraits2 = prevProps.selectedEndorsement.skillTraits
          for (let i = 1; i <= skillTraits1.length; i++) {
            if (skillTraits1[i - 1] && !skillTraits2) {
              somethingChanged = true
            } else if (skillTraits1[i - 1].name !== skillTraits2[i - 1].name) {
              somethingChanged = true
            } else if (skillTraits1[i - 1].skillTrait !== skillTraits2[i - 1].skillTrait) {
              somethingChanged = true
            } else if (skillTraits1[i - 1].score !== skillTraits2[i - 1].score) {
              somethingChanged = true
            }
          }
          if (somethingChanged) {
            this.retrieveData()
          }
        } else if (this.props.selectedEndorsement.competencies) {
          let somethingChanged = false
          let competencies1 = this.props.selectedEndorsement.competencies
          let competencies2 = prevProps.selectedEndorsement.competencies
          for (let i = 1; i <= competencies1.length; i++) {
            if (competencies1[i - 1] && !competencies2) {
              somethingChanged = true
            } else if (competencies1[i - 1].name !== competencies2[i - 1].name) {
              somethingChanged = true
            } else if (competencies1[i - 1].category !== competencies2[i - 1].category) {
              somethingChanged = true
            } else if (competencies1[i - 1].score !== competencies2[i - 1].score) {
              somethingChanged = true
            }
          }
          if (somethingChanged) {
            this.retrieveData()
          }
        } else if (this.props.selectedEndorsement.examples) {
          let somethingChanged = false
          let examples1 = this.props.selectedEndorsement.examples
          let examples2 = prevProps.selectedEndorsement.examples
          for (let i = 1; i <= examples1.length; i++) {
            if (examples1[i - 1] && !examples2) {
              somethingChanged = true
            } else if (examples1[i - 1].skillTrait !== examples2[i - 1].skillTrait) {
              somethingChanged = true
            } else if (examples1[i - 1].compentency !== examples2[i - 1].competency) {
              somethingChanged = true
            } else if (examples1[i - 1].example !== examples2[i - 1].example) {
              somethingChanged = true
            }
          }
          if (somethingChanged) {
            this.retrieveData()
          }
        }
        // skillTraits: name, skillType, score
        // competencies: name, category, score
        // examples: skillTrait, competency, example
        // goalType, opportunity
      } else if (this.props.endorsements !== prevProps.endorsements) {
        this.retrieveData()
      }
    }

    retrieveData() {
        console.log('retrieveData called within shared endorsement data', this.props.selectedEndorsement)

      if (this.props.selectedEndorsement) {

        const selectedEndorsement = this.props.selectedEndorsement
        const modalIsOpen = this.props.modalIsOpen

        this.setState({ selectedEndorsement, modalIsOpen })

      } else {
        console.log('user navigated directly to this screen')
      }
    }

    closeModal() {
      console.log('show closeModal in projectDetails')

      this.props.closeModal()
    }

    renderSpecificSkillTraits(endorsement) {
      console.log('renderSpecificSkillTraits called', endorsement)

      let rows = []

      if (endorsement.competencies && endorsement.competencies.length > 0) {
        const competencies = endorsement.competencies

        let hardSkills = []
        let softSkills = []
        let abilities = []
        let knowledge = []
        let traits = []

        for (let i = 1; i <= competencies.length; i++) {
          console.log('looping competencies: ', competencies[i - 1])
          let competency = competencies[i - 1]

          if (!competency.rating) {
            competency['rating'] = 0
          }
          if (competencies[i - 1].category === 'Hard Skill' || competencies[i - 1].category === 'General Skill') {
            hardSkills.push(competency)
          } else if (competencies[i - 1].category === 'Soft Skill' || competencies[i - 1].category === 'Work Style') {
            softSkills.push(competency)
          } else if (competencies[i - 1].category === 'Ability') {
            abilities.push(competency)
          } else if (competencies[i - 1].category === 'Knowledge') {
            knowledge.push(competency)
          } else {
            traits.push(competency)
          }
        }

        rows.push(
          <div key="skills">
            {(hardSkills) && (
              <div>
                <p className="bottom-padding-5">Hard & General Skills</p>
                {hardSkills.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar primary-border">
                            <div className="filler primary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="spacer"/><div className="half-spacer"/>

            {(softSkills) && (
              <div>
                {(softSkills && softSkills.length > 0) && (
                  <div>
                    <p className="bottom-padding-5">Soft Skills & Work Styles</p>
                    {softSkills.map(value =>
                      <div>
                        {(value.rating !== "I'm Not Sure") && (
                          <div>
                            <div className="relative-column-30">
                              <p className="description-text-2 bold-text">{value.name}</p>
                            </div>
                            <div className="relative-column-65">
                              <div className="half-spacer"/>
                              <div className="progress-bar secondary-border">
                                <div className="filler secondary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                              </div>
                            </div>
                            <div className="relative-column-5">
                              <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                            </div>
                            <div className="clear" />
                            <div className="spacer"/>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="spacer"/><div className="half-spacer"/>
                  </div>
                )}
              </div>
            )}

            {(abilities) && (
              <div>
                {(abilities && abilities.length > 0) && (
                  <div>
                    <p className="bottom-padding-5">Abilities</p>
                    {abilities.map(value =>
                      <div>
                        {(value.rating !== "I'm Not Sure") && (
                          <div>
                            <div className="relative-column-30">
                              <p className="description-text-2 bold-text">{value.name}</p>
                            </div>
                            <div className="relative-column-65">
                              <div className="half-spacer"/>
                              <div className="progress-bar tertiary-border">
                                <div className="filler tertiary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                              </div>
                            </div>
                            <div className="relative-column-5">
                              <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                            </div>
                            <div className="clear" />
                            <div className="spacer"/>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="spacer"/><div className="half-spacer"/>
                  </div>
                )}
              </div>
            )}

            {(knowledge) && (
              <div>
                {(knowledge && knowledge.length > 0) && (
                  <div>
                    <p className="bottom-padding-5">Knowledge</p>
                    {knowledge.map(value =>
                      <div>
                        {(value.rating !== "I'm Not Sure") && (
                          <div>
                            <div className="relative-column-30">
                              <p className="description-text-2 bold-text">{value.name}</p>
                            </div>
                            <div className="relative-column-65">
                              <div className="half-spacer"/>
                              <div className="progress-bar quaternary-border">
                                <div className="filler quaternary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                              </div>
                            </div>
                            <div className="relative-column-5">
                              <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                            </div>
                            <div className="clear" />
                            <div className="spacer"/>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="spacer"/><div className="half-spacer"/>
                  </div>
                )}
              </div>
            )}

            {(traits && traits.length > 0) && (
              <div>
                <p className="bottom-padding-5">Traits</p>
                {traits.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar quaternary-border">
                            <div className="filler quaternary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}

                  </div>
                )}
              </div>
            )}

          </div>
        )
      } else if (endorsement.skillTraits) {
        let skillTraits = endorsement.skillTraits

        let hardSkills = []
        let softSkills = []
        let traits = []

        for (let i = 1; i <= skillTraits.length; i++) {
          console.log('looping skillTraits: ', skillTraits[i - 1])
          let skillTrait = skillTraits[i - 1]

          if (!skillTrait.rating) {
            skillTrait['rating'] = 0
          }
          if (skillTraits[i - 1].skillType === 'Hard Skill') {
            hardSkills.push(skillTrait)
          } else if (skillTraits[i - 1].skillType === 'Soft Skill') {
            softSkills.push(skillTrait)
          } else {
            traits.push(skillTrait)
          }
        }

        rows.push(
          <div key="skills">
            <p className="bottom-padding-5">Hard Skills</p>
            {hardSkills.map(value =>
              <div>
                {(value.rating !== "I'm Not Sure") && (
                  <div>
                    <div className="relative-column-30">
                      <p className="description-text-2 bold-text">{value.name}</p>
                    </div>
                    <div className="relative-column-65">
                      <div className="half-spacer"/>
                      <div className="progress-bar primary-border">
                        <div className="filler primary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                      </div>
                    </div>
                    <div className="relative-column-5">
                      <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                    </div>
                    <div className="clear" />
                    <div className="spacer"/>
                  </div>
                )}
              </div>
            )}

            <div className="spacer"/><div className="half-spacer"/>

            {(softSkills && softSkills.length > 0) && (
              <div>
                <p className="bottom-padding-5">Soft Skills</p>
                {softSkills.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar secondary-border">
                            <div className="filler secondary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}
                  </div>
                )}

                <div className="spacer"/><div className="half-spacer"/>
              </div>
            )}


            {(traits && traits.length > 0) && (
              <div>
                <p className="bottom-padding-5">Traits</p>
                {traits.map(value =>
                  <div>
                    {(value.rating !== "I'm Not Sure") && (
                      <div>
                        <div className="relative-column-30">
                          <p className="description-text-2 bold-text">{value.name}</p>
                        </div>
                        <div className="relative-column-65">
                          <div className="half-spacer"/>
                          <div className="progress-bar quaternary-border">
                            <div className="filler quaternary-background" style={{ width: (value.rating * 20).toString() + '%' }} />
                          </div>
                        </div>
                        <div className="relative-column-5">
                          <p className="description-text-2 bold-text">{(value.rating * 20).toString() + '%'}</p>
                        </div>
                        <div className="clear" />
                        <div className="spacer"/>
                      </div>
                    )}

                  </div>
                )}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    renderEndorsement(endorsement) {
      console.log('renderEndorsement called', endorsement)

      return (
        <div key="endorsementDetail">
          <div>
            <div className="calc-column-offset-40">
              <p className="heading-text-4 bottom-padding">Endorsement Details</p>
            </div>
            {(this.state.modalIsOpen) && (
              <div className="fixed-column-40">
                <button className="background-button full-width" onClick={() => this.closeModal()}>
                  <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" />
                </button>
              </div>
            )}

            <div className="clear" />
          </div>

          <div key="specificEndorsement" className="row-10">
            <hr className="clear-margin clear-padding" />
            <div className="spacer" /><div className="spacer" /><div className="spacer" />

            <div className="fixed-column-90">
              <div className="square-82 circle-radius primary-background senary-border-thick">
                <div className="spacer" /><div className="half-spacer" />
                <p className="heading-text-3 white-text bold-text full-width center-text">{(endorsement.overallRecommendation) ? Number(endorsement.overallRecommendation) * 20 : "N/A"}%</p>
                <p className="description-text-5 white-text full-width center-text">OVERALL</p>
              </div>
            </div>
            <div className="endorsement-name">
              {(endorsement.recipientUsername) ? (
                <p className="heading-text-4">Endorsement for <Link to={"/" + endorsement.recipientUsername + "/profile"} target="_blank">{endorsement.recipientFirstName} {endorsement.recipientLastName}</Link></p>
              ) : (
                <p className="heading-text-4">Endorsement for {endorsement.recipientFirstName} {endorsement.recipientLastName}</p>
              )}
              {(endorsement.senderLinkedIn) ? (
                <p className="description-text-2 description-text-color top-padding-5">by <a href={endorsement.senderLinkedIn} target="_target" rel="noopener noreferrer">{endorsement.senderFirstName} {endorsement.senderLastName}{(endorsement.senderJobTitle) && ", " + endorsement.senderJobTitle + " " + endorsement.senderEmployerName}</a></p>
              ) : (
                <p className="description-text-2 description-text-color top-padding-5">by {endorsement.senderFirstName} {endorsement.senderLastName}{(endorsement.senderJobTitle) && ", " + endorsement.senderJobTitle + " " + endorsement.senderEmployerName}</p>
              )}

              <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
              <p className="description-text-2">{endorsement.relationship}</p>

            </div>
            <div className="endorsement-pathway">
              {(endorsement.pathway) && (
                <p>{endorsement.pathway} Pathway</p>
              )}
              {(endorsement.opportunityTitle) && (
                <p>{endorsement.opportunityTitle}</p>
              )}

              <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>

              {(endorsement.createdAt && typeof endorsement.createdAt === 'string') ? (
                <p className="description-text-2">{convertDateToString(new Date(endorsement.createdAt),"datetime-2")}</p>
              ) : (
                <p className="description-text-2">{convertDateToString(endorsement.createdAt,"date-2")}</p>
              )}
            </div>

            <div className="clear" />
            <div className="spacer" /><div className="spacer" />

            {(endorsement.recommendationExplanation) && (
              <div className="bottom-margin-30 top-margin">
                <p className="italicize-text heading-text-5 cta-color">"{endorsement.recommendationExplanation}"</p>
              </div>
            )}

            <div>
              {this.renderSpecificSkillTraits(endorsement)}
            </div>

            <div className="clear" />
            <div className="spacer" /><div className="spacer" />

            <div>
              {endorsement.examples.map(value =>
                <div>
                   {(value.competency) ? (
                     <div>
                       {(value.competency && value.competency !== 'Select a Competency') && (
                         <div>
                           <p>Example of {value.competency}</p>
                           <div className="half-spacer"/>
                           <p className="description-text-3 description-text-color bold-text">{value.example}</p>
                           <div className="spacer"/><div className="spacer"/>
                         </div>
                       )}
                     </div>
                   ) : (
                     <div>
                       {(value.skillTrait && value.skillTrait !== 'Select a Skill') && (
                         <div>
                           <p className="description-text-1">{value.skillTrait} Example</p>
                           <div className="half-spacer"/>
                           <p className="description-text-3 description-text-color bold-text">{value.example}</p>
                           <div className="spacer"/><div className="spacer"/>
                         </div>
                       )}
                     </div>
                   )}
                </div>
              )}

            </div>

            <div className="clear" />
            <div className="spacer"/><div className="spacer"/><div className="spacer"/>

          </div>
        </div>
      )

    }

    render() {

        return (
            <div>

              {(!this.props.selectedEndorsement && this.props.endorsements && this.props.endorsements.length > 0) && (
                <div>
                  {this.props.endorsements.map((value, index) =>
                    <div>
                       {this.renderEndorsement(value)}
                    </div>
                  )}
                </div>
              )}

              {(this.state.modalIsOpen && this.props.selectedEndorsement) && (
                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 >
                 {(this.state.confirmDelete === true && this.state.deleteIndex === this.state.detailIndex) ? (
                   <div className="padding-5-percent">
                    <p className="heading-text-4">Delete {this.props.selectedEndorsement.senderFirstName} {this.props.selectedEndorsement.senderLastName}'s Endorsement</p>
                    <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                     <div>
                       <p className="error-message">Are you sure you want to delete this endorsement? All information will be deleted forever.</p>
                     </div>
                     <div className="clear" />
                     <div className="spacer"/><div className="spacer"/>

                     <div className="float-left right-padding-20">
                       <button className="btn btn-quaternary" onClick={() => this.deleteEndorsement(this.state.detailIndex)}>Yes, Delete</button>
                     </div>
                     <div className="float-left right-padding-20">
                       <button className="btn btn-secondary" onClick={() => this.setState({ confirmDelete: false, modalIsOpen: false })}>Cancel</button>
                     </div>
                     <div className="clear" />
                     <div className="spacer"/><div className="half-spacer"/>
                     {(this.state.serverErrorMessage) && <p className="error-message">{this.state.serverErrorMessage}</p>}
                   </div>
                 ) : (
                   <div>
                     {(this.props.selectedEndorsement.isTransparent && this.props.selectedEndorsement.isTransparent !== 'false') ? (
                       <div className="padding-20">
                        {this.renderEndorsement(this.props.selectedEndorsement)}
                       </div>
                     ) : (
                       <div className="padding-40 flex-container flex-center">
                         {console.log('testers 2: ', typeof this.props.selectedEndorsement.isTransparent, this.props.selectedEndorsement.isTransparent)}
                         <div className="center-text">
                           <img src={confidentialityIcon} alt="Student icon" className="image-auto-100 vertical-margin-20 center-horizontally" />
                           <div className="clear" />
                           <p className="heading-text-4">This Endorsement is Confidential</p>
                           <div className="spacer"/>
                           <p className="description-text-color">This endorsement has been marked confidential by the endorser. It will automatically be imported into work applications.</p>
                           <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                         </div>
                       </div>
                     )}
                   </div>
                 )}
                </Modal>
              )}
            </div>

        )
    }
}


export default EndorsementDetails;
