import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubAdminApplicationDetail from '../Subcomponents/AdminApplicationDetail';
import withRouter from '../Functions/WithRouter';

class OrgApplicationDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount(){
    document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

    const { org, id } = this.props.params

    let { selectedApplication, applications, selectedJob, benchmark, benchmarkOptions } = this.props.location.state;
    console.log('show me all details ', selectedApplication, selectedJob)

    let email = localStorage.getItem('email');
    let roleName = localStorage.getItem('roleName');
    let activeOrg = localStorage.getItem('activeOrg');
    let username = localStorage.getItem('username');
    let cuFirstName = localStorage.getItem('firstName');
    let cuLastName = localStorage.getItem('lastName');
    let orgLogo = localStorage.getItem('orgLogo');

    let noBenchmark = true
    if (benchmark && (benchmark.orgCode || benchmark.accountCode)) {
      noBenchmark = false
    }

    this.setState({ org, id, selectedApplication, applications, selectedJob, benchmark, benchmarkOptions, noBenchmark,
      emailId: email, username, cuFirstName, cuLastName, roleName, orgLogo
    })
  }

  render() {

    return (
        <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
            <SubAdminApplicationDetail accountCode={this.state.emp} org={this.state.org} id={this.state.id} selectedJob={this.state.selectedJob} selectedApplication={this.state.selectedApplication} applications={this.state.applications} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} noBenchmark={this.state.noBenchmark} history={this.props.navigate} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
        </div>

    )
  }
}

export default withRouter(OrgApplicationDetail)
