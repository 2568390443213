import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubCourses from '../Subcomponents/Courses';
import SubSideNav from '../Common/SideNav';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

class OrgCourses extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        this.setState({ cuFirstName, cuLastName, username, emailId: email, org, orgFocus, orgLogo })
      }
    }

    render() {

        return (
            <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
              {(!toggleVars.hideAdminSideNav) && (
                <SubSideNav selectedComponent={"OrgCourses"} activeOrg={this.state.org} orgFocus={this.state.orgFocus} workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <SubCourses source="Admin" activeOrg={this.state.org} />

              <div className="clear" />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}


export default withRouter(OrgCourses)
