import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubEditGroup from '../Common/EditGroup';
import SubGoalDetails from '../Common/GoalDetails';
import SubRenderPosts from '../Common/RenderPosts';
import SubCreatePost from '../Common/CreatePost';
import SubEditLog from '../Subcomponents/EditLog';
import withRouter from '../Functions/WithRouter';

const infoIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png";
const notificationsIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notifications-icon-dark.png";
const notificationsIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/notifications-icon-blue.png";
const arrowIndicatorIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png";
const editIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png";
const videoIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon.png";
const imageIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/image-icon.png";
const exitIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exit-icon-dark.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const searchIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png';
const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
const calendarIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/calendar-icon-dark.png';
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class GroupDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          prefix: '/app',
          defaultCoverImage: 'https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/headerImages/1210x311.png',
          members: [],
          groupPosts: [],
          comments: [],

          matchCriteria: 'Overall Match',

          showIndividualMeetings: false,
          inviteMethod: 'Specific Individuals',

          enableMediaPosts: false,
          tagOptions: ['Asking a Question','Giving a Shoutout','Sharing a Job Opportunity','Sharing a Resource','Trade Services','Request Feedback','Recruiting Teammates'],
          tags: [],

          subNavCategories: ['Hot','New'],
          subNavSelected: 'Hot',

          membersToInvite: [],
          inviteMethodOptions: []

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullOtherData = this.pullOtherData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.itemClicked = this.itemClicked.bind(this)

        this.joinGroup = this.joinGroup.bind(this)
        this.filterResults = this.filterResults.bind(this)

        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.addItem = this.addItem.bind(this)

        this.changeGroup = this.changeGroup.bind(this)
        this.renderGroups = this.renderGroups.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.passMeeting = this.passMeeting.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.inviteMembers = this.inviteMembers.bind(this)

    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in groupDetails ', this.props, prevProps)

      if (this.props.selectedGroup !== prevProps.selectedGroup) {
        this.retrieveData()
      } else if (this.props.groupId !== prevProps.groupId) {
        this.retrieveData()
      } else if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in Group Details', this.props)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.activeOrg) {
        activeOrg = this.props.activeOrg
      }

      let prefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        prefix = '/advisor'
      } else if (window.location.pathname.includes('/organizations')) {
        prefix = '/organizations/' + activeOrg
      }

      const accountCode = this.props.accountCode
      // console.log('show deets: ', cuFirstName)

      let groupId = null
      if (this.props.selectedGroup) {
        console.log('show selectedGroup in props: ', this.props.selectedGroup)

        groupId = this.props.selectedGroup._id

      } else if (this.props.groupId) {
        console.log('show id in props ', this.props.groupId)

        groupId = this.props.groupId

      } else {
        console.log('something went wrong')
      }

      // console.log('please--_! ', groupId)

      if (groupId) {
        // pull group details
        Axios.get('/api/groups/byid', { params: { groupId } })
        .then((response) => {
           console.log('Group detail by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             const selectedGroup = response.data.group
             let inviteMethodOptions = ['','All Members', 'Specific Individuals']
             if (window.location.pathname.includes('/employers')) {
              inviteMethodOptions = ['','Entire Talent Pipeline', 'Specific Individuals']
             }

             this.setState({ selectedGroup, emailId, username, cuFirstName, cuLastName, activeOrg,
               orgFocus, orgName, roleName, inviteMethodOptions, prefix })
             this.pullOtherData(selectedGroup, emailId, cuFirstName, cuLastName, activeOrg)

             if (selectedGroup.accountCode) {
               Axios.get('/api/account', { params: { accountCode: selectedGroup.accountCode } })
               .then((response) => {
                 console.log('Account info query attempted within sub settings', response.data);

                 if (response.data.success) {
                   console.log('account info query worked in sub settings')

                   const employerName = response.data.accountInfo.employerName
                   const employerURL = response.data.accountInfo.employerURL
                   const employerType = response.data.accountInfo.employerType
                   const employerIndustry = response.data.accountInfo.employerIndustry
                   const employeeCount = response.data.accountInfo.employeeCount
                   const employerLocation = response.data.accountInfo.employerLocation
                   const employerDescription = response.data.accountInfo.description
                   const employerCulture = response.data.accountInfo.employerCulture
                   const employerLogoURI = response.data.accountInfo.employerLogoURI

                   this.setState({
                     employerName, employerURL, employerType, employerIndustry, employeeCount, employerLocation, employerDescription,
                     employerCulture, employerLogoURI
                   });
                 }

               }).catch((error) => {
                 console.log('Account info query did not work for some reason', error);
               });
             }

           }
        }).catch((error) => {
            console.log('Group query did not work', error);
        });
      }

      let groupsQuery = { orgCode: activeOrg, resLimit: 4, emailId, type: 'myGroups' }
      if ((window.location.pathname.includes('/advisor/') && roleName !== 'Mentor') || window.location.pathname.includes('/organizations/')) {
        groupsQuery = { orgCode: activeOrg, resLimit: 4 }
      }

      if (window.location.pathname.includes('/employers/')) {
        groupsQuery = { accountCode, reslimit: 4 }
      }

      if (activeOrg || accountCode) {
        // console.log('groups query: ', groupsQuery)
        Axios.get('/api/groups', { params: groupsQuery })
        .then((response) => {
          console.log('Groups query worked', response.data);

          if (response.data.success) {

            const groups = response.data.groups

            let groupsManaged = null
            let groupsJoined = null
            if (window.location.pathname.includes('/advisor/') || window.location.pathname.includes('/organizations/') || window.location.pathname.includes('/employers/')) {
              groupsManaged = groups
            } else {
              if (groups) {
                groupsManaged = []
                groupsJoined = []
                for (let i = 1; i <= groups.length; i++) {
                  if (groups[i - 1].creatorEmail === emailId) {
                    groupsManaged.push(groups[i - 1])
                  } else {
                    groupsJoined.push(groups[i - 1])
                  }
                }
              }
            }

            this.setState({ groups, groupsManaged, groupsJoined })

          } else {
            console.log('no groups data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Groups query did not work', error);
        });
      }

      if (window.location.pathname.includes(prefix + '/groups')) {
        Axios.get('/api/logs/goals', { params: { emailId } })
        .then((response) => {

            if (response.data.success) {
              console.log('Goals received query worked', response.data);

              if (response.data.goals && response.data.goals.length > 0) {

                const goals = response.data.goals
                this.setState({ goals })

              }

            } else {
              console.log('no goal data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });
      }
    }

    pullOtherData(selectedGroup, emailId, cuFirstName, cuLastName, activeOrg) {
      console.log('pullOtherData called', selectedGroup, emailId)

      let members = []
      if (selectedGroup.members) {
        members = selectedGroup.members
      }

      const shareURL = window.location.protocol + "//" + window.location.host + this.state.prefix + "/groups/" + selectedGroup._id
      const shareTitle = 'Check Out ' + selectedGroup.name + ' On Guided Compass!'
      const shareBody = selectedGroup.name + " is a great forum to connect with like-minded individuals, mentors / mentees, and opportunities."

      this.setState({ members, shareURL, shareTitle, shareBody })

      Axios.get('/api/logs/meetings', { params: { groupId: selectedGroup._id, resLimit: 12 } })
      .then((response) => {

          if (response.data.success) {
            console.log('Meetings query worked', response.data);

            let meetings = response.data.meetings
            this.setState({ meetings })

          } else {
            console.log('no meeting data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Meeting query did not work', error);
      });

      const accountabilityParams = { params: { emailId, members, resLimit: 10 }}
      // const accountabilityParam = { params: { emailId, excludeCurrentUser: true, members, resLimit: 10 }}
      Axios.get('/api/groups/accountability/goals', accountabilityParams)
      .then((response) => {

        if (response.data.success) {
          console.log('Member goals received query worked', response.data);

          if (response.data.memberGoals && response.data.memberGoals.length > 0) {

            const memberGoals = response.data.memberGoals
            this.setState({ memberGoals })

          }

        } else {
          console.log('no goal data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Goal query did not work', error);
      });

      Axios.get('/api/group-posts', { params: { groupId: selectedGroup._id, orgCode: activeOrg, sortByUpvotes: true } })
      .then((response) => {
         console.log('Group posts query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved group posts')

           let groupPosts = []
           if (response.data.groupPosts) {
             groupPosts = response.data.groupPosts
           }

           this.setState({ groupPosts })

         } else {
           this.setState({ groupPosts: [] })
         }
      }).catch((error) => {
          console.log('Group posts query did not work', error);
          this.setState({ groupPosts: [] })
      });

      Axios.get('/api/users/profile/details', { params: { email: emailId } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           let profile = response.data.user
           let pictureURL = response.data.user.pictureURL
           this.setState({ profile, pictureURL });

           Axios.get('/api/assessment/results', { params: { emailId } })
            .then((response2) => {
              console.log('query for assessment results worked');

              if (response2.data.success) {

                console.log('actual assessment results', response2.data)

                // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
                profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                profile['interests'] = response2.data.results.interestScores
                profile['personality'] = response2.data.results.personalityScores
                profile['skills'] = response2.data.results.newSkillAnswers
                profile['gravitateValues'] = response2.data.results.topGravitateValues
                profile['employerValues'] = response2.data.results.topEmployerValues

                this.setState({ profile })

                if (selectedGroup.category === 'Accountability') {
                  this.calculateMatches(emailId, profile, selectedGroup.members, response2.data.results.workPreferenceAnswers, response2.data.results.interestScores, response2.data.results.newSkillAnswers, response2.data.results.personalityScores, null, 'Descending')
                }

              } else {
                console.log('no assessment results', response2.data)
              }

          }).catch((error) => {
            console.log('query for assessment results did not work', error);
          })

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      Axios.get('/api/org', { params: { orgCode: selectedGroup.orgCode } })
      .then((response) => {
        console.log('Org info query attempted for orgFocus on login', response.data);

          if (response.data.success) {
            console.log('org info query worked for orgFocus')

            const headerImageURL = response.data.orgInfo.headerImageURL
            const orgLogo = response.data.orgInfo.webLogoURIColor
            const orgName = response.data.orgInfo.orgName

            selectedGroup['orgName'] = response.data.orgInfo.orgName
            selectedGroup['orgContactEmail'] = response.data.orgInfo.contactEmail
            selectedGroup['orgContactFirstName'] = response.data.orgInfo.contactFirstName
            selectedGroup['orgContactLastName'] = response.data.orgInfo.contactLastName

            this.setState({ headerImageURL, orgLogo, orgName, selectedGroup })

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    calculateMatches(emailId, passedPartner, members, wpData, interestsResults, skillsResults, personalityResults, passedMatchCriteria, passedSortDirection) {
      console.log('calculateMatches called', emailId, passedPartner, members, wpData, interestsResults, skillsResults, personalityResults)

      let matchCriteria = this.state.matchCriteria
      if (passedMatchCriteria) {
        matchCriteria = passedMatchCriteria
      }

      let sortDirection = this.state.sortDirection
      if (passedSortDirection) {
        sortDirection = passedSortDirection
      }

      // console.log('show interest and personality results ', wpData, interestsResults, personalityResults)

      Axios.post('/api/assessment/partners/calculate', { emailId, partners: members, passedPartner, wpData, interestsResults, skillsResults, personalityResults, matchCriteria, sortDirection, orgCode: this.state.org })
      .then((response) => {
        console.log('Member match query worked', response.data);

        if (response.data.success) {

          console.log('successfully matched to members')
          let matches = response.data.matches
          if (response.data.matches) {
            for (let i = 1; i <= response.data.matches.length; i++) {
              for (let j = 1; j <= members.length; j++) {
                // console.log('show emails: ', response.data.matches[i - 1].partner.email)
                if (response.data.matches[i - 1].partner.email === members[j - 1].email) {
                  let matchScore = (100 - response.data.matches[i - 1].differential).toFixed()
                  if (matchScore > 0) {
                    members[j - 1].matchScore = matchScore
                  }
                }
              }
            }
          }
          console.log('show matches: ', matches)
          this.setState({ members })

          // this.indicateWhetherPaired(matches, null)

        } else {
          console.log('there was an error matching partners', response.data.message)
        }

      }).catch((error) => {
          console.log('Partner match did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'searchMembers') {
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, '', null, null, true, 'referee','members')
      } else if (event.target.name === 'searchGroups') {
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, '', null, null, true, null,'groups')
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, filterString, filters, index, search, searchType, type) {
      console.log('filterResults called', searchString, filterString, filters, index, search, searchType, type)

      const orgCode = this.state.activeOrg
      const excludeCurrentUser = true
      const emailId = this.state.emailId
      const roleNames = ['Student','Career-Seeker']

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called', orgCode)
        self.setState({ animating: true, errorMessage: null, successMessage: null })

        if (type === 'groups') {

          Axios.get('/api/groups/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId } })
          .then((response) => {
            console.log('Opportunity search query attempted', response.data);

              if (response.data.success) {
                console.log('opportunity search query worked')

                let groupOptions = response.data.groups
                self.setState({ groupOptions, filteredResults: true, animating: false })

              } else {
                console.log('opportunity search query did not work', response.data.message)

                let memberOptions = []
                self.setState({ memberOptions, animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });

        } else {
          Axios.get('/api/members/search', { params: { searchString, orgCode } })
          .then((response) => {
            console.log('Members query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved members')

                let memberOptions = null
                if (response.data.members) {
                  memberOptions = response.data.members
                }

                self.setState({ memberOptions, animating: false })

              } else {
                console.log('no member data found', response.data.message)
                self.setState({ animating: false, errorMessage: 'No courses were found that match that criteria' })
              }

          }).catch((error) => {
              console.log('Course query did not work', error);
              self.setState({ animating: false, errorMessage: 'Course query did not work for an unknown reason'})
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    // filterResults(searchString, filterName, filterValue, search) {
    //   console.log('filterResults called', searchString, filterName, filterValue, search)
    //
    //   let roleNames = ['Student','Career-Seeker']
    //
    //   const self = this
    //   function officiallyFilter() {
    //     console.log('officiallyFilter called')
    //
    //     const orgCode = self.state.activeOrg
    //     const excludeCurrentUser = true
    //     const emailId = self.state.emailId
    //
    //     if (search) {
    //       Axios.get('/api/groups/search', { params: { searchString, orgCode, roleNames, excludeCurrentUser, emailId } })
    //       .then((response) => {
    //         console.log('Opportunity search query attempted', response.data);
    //
    //           if (response.data.success) {
    //             console.log('opportunity search query worked')
    //
    //             let groups = response.data.groups
    //             self.setState({ groups, filteredResults: true, animating: false })
    //
    //           } else {
    //             console.log('opportunity search query did not work', response.data.message)
    //
    //             let memberOptions = []
    //             self.setState({ memberOptions, animating: false })
    //
    //           }
    //
    //       }).catch((error) => {
    //           console.log('Search query did not work for some reason', error);
    //           self.setState({ animating: false })
    //       });
    //     } else {
    //       console.log('filter results: ', searchString, orgCode, filterName, filterValue)
    //
    //       const onlyPics = true
    //       const profile = self.state.profile
    //       let groups = self.state.groups
    //       // if (self.state.groups && self.state.groups[0] && self.state.groups[0].matchScore) {
    //       //   users = self.state.groups
    //       // }
    //
    //       // eventually query groups from back-end
    //       Axios.put('/api/groups/filter', { searchString, orgCode, filterName, filterValue, roleNames, onlyPics, profile, groups })
    //       .then((response) => {
    //         console.log('Groups filter query attempted', response.data);
    //
    //           if (response.data.success) {
    //             console.log('opportunity search query worked')
    //
    //             let groups = response.data.groups
    //             const filterCriteriaArray = response.data.filterCriteriaArray
    //             console.log('show filterCriteriaArray: ', filterCriteriaArray)
    //             self.setState({ groups, filterCriteriaArray, filteredResults: true, animating: false })
    //
    //           } else {
    //             console.log('opportunity search query did not work', response.data.message)
    //
    //             self.setState({ animating: false })
    //
    //           }
    //
    //       }).catch((error) => {
    //           console.log('Search query did not work for some reason', error);
    //           self.setState({ animating: false })
    //       });
    //     }
    //   }
    //
    //   const delayFilter = () => {
    //     console.log('delayFilter called: ')
    //     clearTimeout(self.state.timerId)
    //     self.state.timerId = setTimeout(officiallyFilter, 1000)
    //   }
    //
    //   delayFilter();
    // }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      const searchObject = passedItem
      let searchString = passedItem.firstName + ' ' + passedItem.lastName
      let memberOptions = null
      this.setState({ searchObject, searchString, memberOptions })

    }

    renderTags(type) {
      console.log('renderTags ', type)

      const membersToInvite = this.state.membersToInvite
      if (membersToInvite && membersToInvite.length > 0) {
        // console.log('about to in', favoritedOpportunities)
        return (
          <div key={"members"}>
            <div className="spacer" />
            {membersToInvite.map((value, optionIndex) =>
              <div key={"career|" + optionIndex} className="float-left">

                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="background-button float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value.firstName} {value.lastName}</p>
                  </div>
                  <div className="half-spacer" />
                </div>

              </div>
            )}
          </div>
        )
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      let membersToInvite = this.state.membersToInvite
      membersToInvite.splice(index, 1)
      this.setState({ membersToInvite })

    }

    addItem(type) {
      console.log('optionClicked called', type)

      this.setState({ errorMessage: null })

      if (!this.state.searchObject) {
        this.setState({ errorMessage: 'Please add a member'})
      } else {
        let membersToInvite = this.state.membersToInvite
        membersToInvite.push(this.state.searchObject)

        let searchString = ''
        let searchObject = null
        this.setState({ membersToInvite, searchString, searchObject })
      }
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      let groupPosts = this.state.groupPosts
      if (itemSelected === 'Hot') {
        groupPosts.sort(function(a,b) {
          return b.upvotes.length - a.upvotes.length;
        })

      } else {
        // new posts

        groupPosts.sort(function(a,b) {
          let ds1 = a.updatedAt
          let ds2 = b.updatedAt
          if (ds1 && ds2) {
            let d1 = new Date(ds1)
            let d2 = new Date(ds2)
            console.log('eh? eh?', d1, d2)
            return d2 - d1;
          }
        })

        console.log('eh? ', groupPosts)
      }

      this.setState({ subNavSelected: itemSelected, groupPosts })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showDescription: false, showMembers: false, showShareButtons: false,
        showInvite: false, showPost: false, sharePosting: false,
        showAddMeeting: false, showEditGroup: false, showGoalDetails: false, showHelpOutWidget: false,
        groupToEdit: false, showAllMeetings: false, showViewMeeting: false
      })
    }

    joinGroupNotifications() {
      console.log('joinGroupNotifications called')

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let groupId = this.state.selectedGroup._id
      const member = {
        firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
        email: this.state.emailId, roleName: this.state.roleName, pictureURL: this.state.pictureURL,
        username: this.state.username
      }

      Axios.post('/api/groups/save', { groupId, member, changeNotifications: true })
      .then((response) => {
        console.log('attempting to save addition to groups')
        if (response.data.success) {
          console.log('saved addition to groups', response.data)

          let selectedGroup = response.data.group
          this.setState({ successMessage: response.data.message, selectedGroup, isSaving: false })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
      });
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={this.state.shareURL} subject={this.state.shareTitle} body={this.state.shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={this.state.shareURL} title={this.state.shareTitle} summary={this.state.selectedGroup.description} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={this.state.shareURL} description={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={this.state.shareURL} quote={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={this.state.shareURL} title={this.state.shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />


          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    itemClicked(selectedTag) {
      console.log('itemClicked called', selectedTag)

      let tags = this.state.tags
      if (tags.includes(selectedTag)) {
        const index = tags.indexOf(selectedTag)
        tags.splice(index, 1)
      } else {
        tags.push(selectedTag)
      }
      this.setState({ tags })
    }

    joinGroup(joinGroup) {
      console.log('joinGroup called', joinGroup)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      let groupId = this.state.selectedGroup._id
      const member = {
        firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
        email: this.state.emailId, roleName: this.state.roleName, pictureURL: this.state.pictureURL,
        username: this.state.username
      }
      const accessType = this.state.selectedGroup.accessType

      let leaveGroup = false
      if (!joinGroup) {
        leaveGroup = true
      }

      Axios.post('/api/groups/save', { groupId, member, accessType, joinGroup, leaveGroup })
      .then((response) => {
        console.log('attempting to save addition to groups')
        if (response.data.success) {
          console.log('saved addition to groups', response.data)

          let selectedGroup = response.data.group
          this.setState({ successMessage: response.data.message, selectedGroup, isSaving: false })

        } else {
          console.log('did not save successfully')
          this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
      });
    }

    changeGroup(index, type) {
      console.log('changeGroup called', this.state.groupOptions, index)

      const groupOptions = null
      this.setState({ groupOptions, searchString: '' })

      let gos = this.state.groupOptions
      if (type === 'managed') {
        gos = this.state.groupsManaged
      } else if (type === 'joined') {
        gos = this.state.groupsJoined
      }

      if (window.location.pathname.includes("/advisor/")) {
        this.props.navigate("/advisor/groups/" + gos[index]._id)
      } else if (window.location.pathname.includes("/organizations")) {
        this.props.navigate("/organizations/" + this.state.activeOrg + "/groups/" + gos[index]._id)
      } else if (window.location.pathname.includes("/app/groups")){
        this.props.navigate("/app/groups/" + gos[index]._id, { state: { selectedGroup: gos[index] }})
      } else if (window.location.pathname.includes("/employers")) {
        this.props.navigate("/employers/" + this.state.accountCode + "/groups/" + gos[index]._id)
      }
    }

    passPosts(groupPosts) {
      console.log('passPosts called')
      this.setState({ groupPosts })
    }

    renderGroups(groups, passedKey) {
      console.log('renderGroups called')

      return (
        <div key={passedKey}>
          {groups && groups.map((value, index) =>
            <div key={value}>
              <div className="row-5">
                <button className="background-button cta-color left-text" onClick={() => this.changeGroup(index, passedKey)}>
                  <div className="fixed-column-70">
                    <img src={(value.pictureURL) ? value.pictureURL : this.state.defaultCoverImage} alt="GC" className="image-50-fit"/>
                  </div>
                  <div className="calc-column-offset-70">
                    <p className="description-text-1">{value.name}</p>
                    <div className="half-spacer" />
                  </div>
                  <div className="clear"/>
                </button>
              </div>
            </div>
          )}
        </div>
      )

    }

    passMeeting(meeting) {
      console.log('passMeeting called', meeting)
      let meetings = this.state.meetings
      if (meetings) {
        meetings.unshift(meeting)
      } else {
        meetings = [meeting]
      }
      this.setState({ meetings })
    }

    inviteMembers() {
      console.log('inviteMembers called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.membersToInvite || this.state.membersToInvite.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'Please add at least one member' })
      } else {

        // const membersToInvite = this.state.membersToInvite
        // for (let i = 1; i <= membersToInvite.length; i++) {
        //   recipientEmails.push(membersToInvite[i - 1].email)
        //   recipientFirstNames.push(membersToInvite[i - 1].firstName)
        //   recipientLastNames.push(membersToInvite[i - 1].lastName)
        //
        // }

        let recipients = this.state.membersToInvite
        const _id = this.state.selectedGroup._id

        let senderFirstName = this.state.cuFirstName
        let senderLastName = this.state.cuLastName
        let senderEmail = this.state.emailId
        let senderUsername = this.state.username
        let senderPictureURL = this.state.pictureURL

        const orgCode = this.state.activeOrg
        let orgName = null
        if (window.location.pathname.includes('/organizations')) {
          orgName = this.state.orgName
          senderFirstName = this.state.orgName
          senderLastName = " "
          senderPictureURL = this.state.orgLogo
        }
        const accountCode = this.state.accountCode
        const employerName = this.state.employerName
        const recipientType = ''
        const message = 'Check out this group: ' + this.state.shareURL
        const type = 'Message'
        const relationship = ''
        const isApproved = false
        const isDecided = false

        const headerImageURL = this.state.headerImageURL

        const createdAt = new Date()
        const updatedAt = new Date()

        const notiObject = {
          recipients, _id,
          orgCode, orgName, accountCode, employerName,  recipientType, message, type, relationship, isApproved, isDecided,
          senderFirstName, senderLastName, senderEmail, senderPictureURL, senderUsername,
          headerImageURL, createdAt, updatedAt
        }

        Axios.post('/api/groups', notiObject)
        .then((response) => {
          console.log('attempting to save addition to groups')
          if (response.data.success) {
            console.log('saved addition to groups', response.data)

            this.setState({ successMessage: response.data.message, isSaving: false })
            this.closeModal()

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving groups', isSaving: false})
        });
      }
    }

    renderMeetings(all) {
      console.log('renderMeetings called', all)


      return (
        <div>
          {this.state.meetings.map((value, index) =>
            <div key={value}>
              {(all || (!all && index < 3)) && (
                <div className="bottom-padding">
                  {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor') || window.location.pathname.includes('/employers') || this.state.selectedGroup.admins.some(admin => admin.email === this.state.emailId)) ? (
                    <button className="background-button left-text full-width" onClick={() => this.setState({ modalIsOpen: true, showAddMeeting: true, editExisting: true, logId: value._id, log: value })}>
                      <div className="fixed-column-35 height-50">
                        <div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={calendarIconDark} className="image-auto-18" alt="GC" />
                      </div>
                      <div className="calc-column-offset-35">
                        <p className="description-text-1">{convertDateToString(new Date(value.startTime),"datetime-2")} Meeting</p>
                        <p className="description-text-3">{value.location}</p>
                      </div>
                      <div className="clear" />
                    </button>
                  ) : (
                    <button className="background-button left-text full-width" onClick={() => this.setState({ modalIsOpen: true, showViewMeeting: true, selectedMeeting: value })}>
                      <div className="fixed-column-35 height-50">
                        <div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={calendarIconDark} className="image-auto-18" alt="GC" />
                      </div>
                      <div className="calc-column-offset-35">
                        <p className="description-text-1">{convertDateToString(new Date(value.startTime),"datetime-2")} Meeting</p>
                        <p className="description-text-3">{value.location}</p>
                      </div>
                      <div className="clear" />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )
    }

    render() {

      let browseGroupsLink = "/app/browse-groups"
      if (window.location.pathname.includes("/advisor/")) {
        browseGroupsLink = "/advisor/browse-groups"
      } else if (window.location.pathname.includes("/organizations")) {
        browseGroupsLink = "/organizations/" + this.state.activeOrg + "/groups"
      } else if (window.location.pathname.includes("/employers")) {
        browseGroupsLink = "/employers/" + this.state.accountCode + "/communities"
      }

      return (
          <div>
            {(this.state.selectedGroup) && (
              <div className="top-margin-20">
                {(window.innerWidth > 768) && (
                  <div className="relative-column-30 horizontal-padding">
                    <div className="right-padding-20">

                      {(!window.location.pathname.includes('/organizations/') && !window.location.pathname.includes('/advisor/') && !window.location.pathname.includes('/employers/')) && (
                        <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                          <div className="full-width height-5 primary-background" />

                          <Link to={this.state.prefix + '/app/edit-profile'} className="background-button full-width standard-color">
                            <div className="top-padding-20">
                              <div className="row-10">
                                <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} className="profile-thumbnail-6 center-horizontally" alt="GC" />
                              </div>
                            </div>
                            <div className="row-5 center-text">
                              <p className="heading-text-6">{this.state.cuFirstName} {this.state.cuLastName}</p>
                            </div>
                          </Link>

                          <div className="row-10 horizontal-padding">
                            <hr className="cta-border" />
                          </div>

                          {(this.state.goals && this.state.goals.length > 0) ? (
                            <div className="row-10 horizontal-padding">
                              {this.state.goals.map((value, index) =>
                                <div key={value}>
                                  {(index < 3) && (
                                    <div className="bottom-padding">
                                      <Link className="background-button full-width standard-color profile-container-right" to={this.state.prefix + '/logs/' + value._id} state={{ editExisting: true, log: value }}>
                                        <div className="fixed-column-35 height-50">
                                          <div className="mini-spacer" /><div className="mini-spacer" />
                                          <img src={targetIconOrange} className="image-auto-18" alt="GC" />
                                        </div>
                                        <div className="calc-column-offset-35">
                                          <p className="description-text-1 curtail-text">{value.title}</p>
                                          <p className="description-text-3">Due {value.deadline}</p>

                                        </div>
                                        <div className="clear" />
                                      </Link>

                                      <div className="profile-modal-right">
                                        <div>

                                          <div className="bottom-padding">
                                            <img src={targetIconOrange} className="image-half-auto center-horizontally" alt="GC" />
                                          </div>
                                          <div className="row-10">
                                            <p className="heading-text-6 center-text full-width">{value.title}</p>
                                            <p className="description-text-2 center-text full-width">Due: {value.deadline}</p>

                                            {(value.goalType) && (
                                              <p className="description-text-3 row-5">Goal Type: {value.goalType.description}</p>
                                            )}

                                            {(value.createdAt) && (
                                              <p className="description-text-3 row-5">Created on: {value.createdAt}</p>
                                            )}

                                            {(value.intensity) && (
                                              <p className="description-text-3 row-5">Intensity: {value.intensity}</p>
                                            )}

                                            {(value.selectedCareers && value.selectedCareers.length > 0) && (
                                              <div className="row-5">
                                                {value.selectedCareers.map((value2, index2) =>
                                                  <div key={value2}>
                                                    <p className="description-text-3 row-5">Career {index2 + 1}: {value2.name}</p>
                                                  </div>
                                                )}

                                              </div>
                                            )}
                                          </div>

                                        </div>

                                        <div className="top-padding-20">
                                          <Link to={this.state.prefix + '/logs/' + value._id} state={{ editExisting: true, log: value }} className="background-button full-width"><button className="btn btn-squarish full-width">View Details</button></Link>
                                        </div>

                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              <div>
                                <hr className="cta-border" />
                                <div className="spacer" />

                                <Link className="background-button center-text full-width row-5" to={this.state.prefix + '/edit-goals/new'} state={{ editExisting: false }}>
                                  <p className="description-text-2">Add a Goal</p>
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="row-10">
                              <img src={targetIconOrange} alt="GC" className="image-auto-38 center-horizontally" />

                              <div className="row-10 center-text horizontal-padding">
                                <p className="description-text-2">You're Goalless!</p>
                                <p className="description-text-4 row-5">Get your life together and aim for a brighter future!</p>
                              </div>

                              <div className="row-10 full-width description-text-2 center-text">
                                <Link to={this.state.prefix + "/edit-goals/new"} state={{ editExisting: false, logs: null }} className="background-button"><button className="btn btn-small center-horizontally"  onClick={() => console.log('')}>Add a Goal</button></Link>
                              </div>
                            </div>
                          )}

                          <div className="spacer" />

                        </div>
                      )}

                      <div className="card-clear-padding min-width-100 max-width-260 pin-right bottom-margin-20">
                        <div className="padding-20">
                          <p className="heading-text-3">Groups</p>

                          <div className="filter-field-search full-width white-background">
                            <div className="search-icon-container">
                              <div className="mini-spacer" />
                              <img src={searchIcon} alt="Compass search icon" className="image-auto-18" />
                            </div>
                            <div className="filter-search-container calc-column-offset-50">
                              <input type="text" className="text-field clear-border description-text-1" placeholder="Search groups..." name="searchGroups" value={this.state.searchString} onChange={this.formChangeHandler}/>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />

                          {(this.state.animating) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Pulling results...</p>

                              </div>
                            </div>
                          ) : (
                            <div className="full-width">
                              {this.renderGroups(this.state.groupOptions,'options')}
                            </div>
                          )}

                          <div className="spacer" />

                          <div className="row-10">
                            <Link to={browseGroupsLink} className="background-button standard-color">
                              <div className="fixed-column-30">
                                <img src={websiteIconDark} alt="GC" className="image-auto-20" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p>Browse groups</p>
                              </div>
                              <div className="clear" />
                            </Link>
                          </div>
                          <div className="row-10">
                            <button className="btn btn-squarish full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showEditGroup: true, groupToEdit: null })}>
                              <div className="fixed-column-30">
                                <img src={addIconWhite} alt="GC" className="image-auto-20 padding-3" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p>Create new group</p>
                              </div>
                              <div className="clear" />
                            </button>
                          </div>
                        </div>

                        <hr />

                        <div className="padding-20">
                          <p className="heading-text-5">Groups you manage</p>

                          {(this.state.groupsManaged && this.state.groupsManaged.length > 0) ? (
                            <div className="full-width top-margin">
                              {this.renderGroups(this.state.groupsManaged,'managed')}

                              {(this.state.groupsManaged.length > 3) && (
                                <div>
                                  <hr />

                                  <div className="top-margin">
                                    <Link to={(window.location.pathname.includes('/advisor/') || window.location.pathname.includes('/organizations/') || window.location.pathname.includes('/employers/')) ? this.state.prefix + "/community" : "/app/community"} className="background-button full-width cta-color center-text description-text-1">
                                      View All
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="full-width row-20">
                              <p className="description-text-1 description-text-color">You currently aren't the admin of any groups</p>
                            </div>
                          )}

                        </div>

                        {(!window.location.pathname.includes('/advisor/') && !window.location.pathname.includes('/organizations/') && !window.location.pathname.includes('/employers/')) && (
                          <div>
                            <hr />

                            <div className="padding-20">
                              <p className="heading-text-5">Groups you're in</p>

                              {(this.state.groupsJoined && this.state.groupsJoined.length > 0) ? (
                                <div className="full-width row-20">
                                  {this.renderGroups(this.state.groupsJoined,'joined')}

                                  {(this.state.groupsJoined.length > 3) && (
                                    <div>
                                      <hr />

                                      <div className="top-margin">
                                        <Link to={this.state.prefix + "/community"} className="background-button full-width cta-color center-text description-text-1">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="full-width top-margin">
                                  <p className="description-text-1 description-text-color">You currently haven't joined any groups</p>
                                </div>
                              )}

                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                )}

                <div className={(window.innerWidth > 768) ? "relative-column-40" : 'full-width'}>
                  <div className="card-clear-padding full-width">
                    <img src={(this.state.selectedGroup.pictureURL) ? this.state.selectedGroup.pictureURL : this.state.defaultCoverImage} alt="GC" className="image-full-width-150 center-horizontally slightly-rounded-corners"  />
                    <hr />
                    <div className="padding-30">
                      <div className="row-10">
                        <div className="calc-column-offset-120">
                          <p className="heading-text-2">{this.state.selectedGroup.name}</p>
                          <p className="top-padding-15">{this.state.selectedGroup.category}</p>
                        </div>
                        <div className="fixed-column-120 top-padding">
                          <div className="float-right">
                            <div className="right-padding display-inline">
                              <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showDescription: true })}>
                                <img src={infoIconDark} alt="GC" className="image-auto-20 pin-right display-inline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(this.state.selectedGroup.description) && this.state.selectedGroup.description.substring(0, Math.min(this.state.selectedGroup.description.length, 40)) + '...'} />
                              </button>
                            </div>

                            {(this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) && (
                              <div className="right-padding display-inline">
                                <button className="background-button" onClick={() => this.joinGroupNotifications()}>
                                  <img src={(this.state.selectedGroup.notifiees && this.state.selectedGroup.notifiees.some(notifiee => notifiee.email === this.state.emailId)) ? notificationsIconBlue : notificationsIconDark} alt="GC" className="image-auto-20 pin-right display-inline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Turn on notifications" />
                                </button>
                              </div>
                            )}

                            {(this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) && (
                              <div className="right-padding display-inline">
                                <button className="background-button" onClick={() => this.joinGroup(false)}>
                                  <img src={exitIconDark} alt="GC" className="image-auto-20 pin-right display-inline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Leave group" />
                                </button>
                              </div>
                            )}

                            {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor') || window.location.pathname.includes('/employers') || this.state.selectedGroup.creatorEmail === this.state.emailId) && (
                              <div className="right-padding display-inline">
                                <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showEditGroup: true, groupToEdit: this.state.selectedGroup })}>
                                  <img src={editIconDark} alt="GC" className="image-auto-18 pin-right display-inline" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Edit group" />
                                </button>
                              </div>
                            )}

                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>

                      {(window.innerWidth <= 768) && (
                        <div>
                          {(this.state.members && this.state.members.length > 0) && (
                            <div className="row-10">
                              <button onClick={() => this.setState({ modalIsOpen: true, showMembers: true })} className="background-button">
                                <div className="float-left right-padding">
                                  {(this.state.members) && (
                                    <div className="left-padding">
                                      {this.state.members.map((item, index) =>
                                        <div key={index} className="float-left">
                                          {(index < 3) && (
                                            <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 standard-border left-margin-negative-10" />
                                          )}

                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="float-left right-padding">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                  <p><b>{this.state.members.length} members</b></p>
                                </div>
                                <div className="float-left right-padding">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                  <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right display-inline" />
                                </div>
                                <div className="clear" />
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {((this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) || window.location.pathname.includes('/organizations/') || window.location.pathname.includes('/employers/')) ? (
                        <div className="top-padding-20">
                          <div className="display-inline half-width-static">
                            <button className="btn btn-profile-white full-width right-margin-5" onClick={() => this.setState({ modalIsOpen: true, showInvite: true })}>
                              Invite
                            </button>
                          </div>
                          <div className="display-inline half-width-static">
                            <button onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true })} className="btn btn-profile full-width left-margin-5">
                              Share
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="top-padding">
                          <div className="display-inline half-width-static">
                            {(this.state.selectedGroup.accessType === 'Open') ? (
                              <button className="btn btn-profile-white full-width right-margin-5" onClick={() => this.joinGroup(true)}>
                                <p>Join Group</p>
                              </button>
                            ) : (
                              <div>
                                {((this.state.selectedGroup.requests && this.state.selectedGroup.requests.some(request => request.email === this.state.emailId))) ? (
                                  <div className="center-text full-width right-margin-5">
                                    <div className="float-left right-padding">
                                      <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                      <img src={timeIconBlue} alt="GC" className="image-auto-15" />
                                    </div>
                                    <div className="float-left">
                                      <p className="description-text-2 bold-text">Requested Access</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <button className="btn btn-profile-white full-width right-margin-5" onClick={() => this.joinGroup(true)}>
                                    <p>Request</p>
                                  </button>
                                )}
                              </div>
                            )}

                          </div>
                        </div>
                      )}

                    </div>
                  </div>

                  {(this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) && (
                    <div>
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color full-width center-text row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color full-width center-text row-5">{this.state.successMessage}</p>}

                      <div className="card-clear-padding full-width top-margin-20">
                        <div className="filter-field-search full-width row-5 white-background clear-margin" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>
                          <div className="search-icon-container">
                            <button className="background-button" onClick={() => console.log('edit')}>
                              <img src={editIconDark} alt="Compass search icon" className="image-auto-28 padding-5" />
                            </button>
                          </div>
                          <div className="filter-search-container calc-column-offset-120">
                            <p className="description-text-color row-10 left-padding">Start a conversation...</p>
                          </div>
                          {(this.state.enableMediaPosts) && (
                            <div>
                              <div className="search-icon-container top-margin-negative-5">
                                <button className="background-button" onClick={() => console.log('video')}>
                                  <img src={videoIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                                </button>
                              </div>
                              <div className="search-icon-container top-margin-negative-5">
                                <button className="background-button" onClick={() => console.log('image')}>
                                  <img src={imageIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                                </button>
                              </div>
                            </div>
                          )}

                        </div>
                        <div className="clear" />
                      </div>

                      <div className="card-clear-padding full-width top-margin-20">
                        <div className="full-width white-background">
                          <div className="clear-float">
                            <div className="carousel-3" onScroll={this.handleScroll}>
                              {this.state.subNavCategories.map((value, index) =>
                                <div className="display-inline">
                                  {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                    <div className="selected-carousel-item-2">
                                      <label key={value}>{value}</label>
                                    </div>
                                  ) : (
                                    <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                                      <label key={value}>{value}</label>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>

                      {(this.state.groupPosts) && (
                        <div>
                          <SubRenderPosts history={this.props.navigate} posts={this.state.groupPosts} passedGroupPost={this.state.passedGroupPost} />
                        </div>
                      )}
                    </div>
                  )}

                  <ReactTooltip id="tooltip-placeholder-id" />
                </div>

                {(window.innerWidth > 768) && (
                  <div className="relative-column-30 horizontal-padding">
                    <div className="left-padding-20">
                      <div className="card min-width-100 max-width-260 bottom-margin-20">
                        <p className="heading-text-3">{this.state.members.length} Members</p>

                        {(this.state.selectedGroup.category === 'Accountability') ? (
                          <div>
                            {(this.state.members && this.state.members.length > 0) && (
                              <div className="row-10">
                                <div>
                                  {(this.state.members) && (
                                    <div>
                                      {this.state.members.map((item, index) =>
                                        <div key={index} className="row-5">
                                          <Link to={this.state.prefix + "/profile/" + item.username} className="background-link full-width standard-color" target="_blank">
                                            <div className="fixed-column-40 right-padding">
                                              <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 standard-border" />
                                            </div>
                                            <div className="calc-column-offset-40">
                                              <p className="description-text-1">{item.firstName} {item.lastName}{(this.state.selectedGroup.admins && this.state.selectedGroup.admins.some(admin => admin.email === item.email)) && " (Admin)"}</p>
                                              {(item.matchScore) && (
                                                <p className="description-text-3 cta-color bold-text">({item.matchScore}% match)</p>
                                              )}
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="top-margin">
                            {(this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) ? (
                              <div>
                                {(this.state.members && this.state.members.length > 0) && (
                                  <div className="row-10">
                                    <button onClick={() => this.setState({ modalIsOpen: true, showMembers: true })} className="background-button">
                                      <div className="float-left right-padding">
                                        {(this.state.members) && (
                                          <div className="left-padding">
                                            {this.state.members.map((item, index) =>
                                              <div key={index} className="float-left">
                                                {(index < 3) && (
                                                  <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 standard-border left-margin-negative-10" />
                                                )}

                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="float-left right-padding">
                                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <p><b>See all</b></p>
                                      </div>
                                      <div className="float-left right-padding">
                                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-12 pin-right display-inline" />
                                      </div>
                                      <div className="clear" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row-20">
                                <p className="description-text-2 description-text-color">Join to view group members</p>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="top-padding">
                          <button className="btn btn-profile full-width" onClick={() => this.setState({ modalIsOpen: true, showInvite: true })}>
                            Invite
                          </button>
                        </div>
                      </div>

                      <div className="card min-width-100 max-width-260 bottom-margin-20">
                        <p className="heading-text-3">About this group</p>
                        <p className="row-20 description-text-1">{this.state.selectedGroup.description}</p>
                        {(this.state.selectedGroup.groupGoals && this.state.selectedGroup.groupGoals.length > 0) && (
                          <div>
                            {this.state.selectedGroup.groupGoals.map((value, index) =>
                              <div key={value} className="float-left right-margin row-10 horizontal-padding-4 senary-background-light top-margin description-text-3">{value}</div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      {(this.state.selectedGroup.category === 'Employers') && (
                        <div className="card min-width-100 max-width-260 bottom-margin-20">
                          <p className="heading-text-3">About {this.state.employerName}</p>
                          <div className="spacer" />
                          <a className="row-10 description-text-1" href={this.state.employerURL} target="_blank" rel="noopener noreferrer">About {this.state.employerURL}</a>

                          {(this.state.employerType) && (
                            <p className="row-10 description-text-1">Type: {this.state.employerType}</p>
                          )}

                          {(this.state.employerIndustry) && (
                            <p className="row-10 description-text-1">Industry: {this.state.employerIndustry}</p>
                          )}

                          {(this.state.employeeCount) && (
                            <p className="row-10 description-text-1">Employee Count: {this.state.employeeCount}</p>
                          )}

                          {(this.state.employerLocation) && (
                            <p className="row-10 description-text-1">Employee Location: {this.state.employerLocation}</p>
                          )}

                          {(this.state.employerDescription) && (
                            <p className="row-10 description-text-1">{this.state.employerDescription}</p>
                          )}

                        </div>
                      )}

                      {((this.state.selectedGroup.members && this.state.selectedGroup.members.some(member => member.email === this.state.emailId)) || this.state.selectedGroup.creatorEmail === this.state.emailId || window.location.pathname.includes('/advisor/') || window.location.pathname.includes('/organizations/') || window.location.pathname.includes('/employers/')) && (
                        <div>
                          {(this.state.selectedGroup.category === 'Accountability') && (
                            <div className="card min-width-100 max-width-260 bottom-margin-20">

                              {(this.state.showIndividualMeetings) ? (
                                <div>
                                  <div>
                                    <div className="calc-column-offset-30">
                                      <p className="heading-text-3">Meetings</p>
                                    </div>

                                    {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor') || window.location.pathname.includes('/employers') || this.state.selectedGroup.admins.some(admin => admin.email === this.state.emailId)) && (
                                      <div className="fixed-column-30">
                                        <div className="mini-spacer" /><div className="mini-spacer" />
                                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showAddMeeting: true })}>
                                          <img src={addIcon} alt="GC" className="image-auto-20" />
                                        </button>
                                      </div>
                                    )}

                                    <div className="clear" />
                                  </div>

                                  {(this.state.meetings && this.state.meetings.length > 0) ? (
                                    <div className="top-padding">
                                      {this.renderMeetings(false)}

                                      <div className="clear" />

                                      {(this.state.meetings && this.state.meetings.length > 0) && (
                                        <div>
                                          <hr />
                                          <button className="background-button full-width top-margin row-10 cta-color" onClick={() => this.setState({ modalIsOpen: true, showAllMeetings: true })}>
                                            <p>See All</p>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="top-padding">
                                      <p className="description-text-1 description-text-color">There are no past or upcoming meetings.</p>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <div className="row-10">
                                    <p className="heading-text-3">Meetings</p>
                                  </div>

                                  <div className="row-10">
                                    <p className="description-text-1 description-text-color bottom-padding-5">Frequency</p>
                                    <p className="heading-text-6">{this.state.selectedGroup.meetingRepeats}</p>
                                  </div>

                                  <div className="row-10">
                                    <p className="description-text-1 description-text-color bottom-padding-5">Start & End Times</p>
                                    <p className="heading-text-6">{convertDateToString(new Date(this.state.selectedGroup.meetingStartTime),"datetime-2")} - {convertDateToString(new Date(this.state.selectedGroup.meetingEndTime),"datetime-2")}</p>
                                  </div>

                                  <div className="row-10">
                                    <p className="description-text-1 description-text-color bottom-padding-5">Method</p>
                                    <p className="heading-text-6">{this.state.selectedGroup.meetingMethod}</p>
                                  </div>

                                  <div className="row-10">
                                    <p className="description-text-1 description-text-color bottom-padding-5">{(this.state.selectedGroup.meetingMethod === 'Remote') ? "Meeting Link" : "Location"}</p>
                                    <p className="heading-text-6">{this.state.selectedGroup.meetingLocation}</p>
                                  </div>

                                </div>
                              )}

                            </div>
                          )}
                        </div>
                      )}

                      {(this.state.selectedGroup.category === 'Accountability') && (
                        <div className="card min-width-100 max-width-260 bottom-margin-20">
                          <p className="heading-text-3">Goals of Members</p>

                          {(this.state.memberGoals && this.state.memberGoals.length > 0) ? (
                            <div className="row-10">
                              <div className="spacer" />
                              {this.state.memberGoals.map((value, index) =>
                                <div key={value}>
                                  <div className="bottom-padding">
                                    <button className="background-button left-text full-width" onClick={() => this.setState({ modalIsOpen: true, showGoalDetails: true, selectedGoal: value })}>
                                      <div className="fixed-column-35 height-50">
                                        <div className="mini-spacer" /><div className="mini-spacer" />
                                        <img src={targetIconOrange} className="image-auto-18" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-35">
                                        <p className="description-text-1 curtail-text">{value.title}</p>
                                        <p className="description-text-3">Due {value.deadline}</p>
                                        <p className="description-text-3">By {value.creatorFirstName} {value.creatorLastName}</p>
                                      </div>
                                      <div className="clear" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="row-10">
                              <p className="description-text-1 description-text-color">Member of this group have not added any goals yet</p>
                            </div>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                )}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >

             {(this.state.showDescription) && (
               <div key="showDescription" className="full-width padding-20">
                  <p className="heading-text-2">{this.state.selectedGroup.name}</p>
                  <p className="top-padding-20">{this.state.selectedGroup.description}</p>
                </div>
             )}

             {(this.state.showMembers) && (
               <div key="showMembers" className="full-width padding-20">
                  <div>
                    <div className="calc-column-offset-30">
                      <p className="heading-text-2 bottom-padding">{this.state.members.length} Members of {this.state.selectedGroup.name}</p>
                    </div>
                    <div className="fixed-column-30 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  {this.state.members.map((item, index) =>
                    <div key={index}>
                      <Link to={this.state.prefix + "/profile/" + item.username} className="background-link full-width standard-color" target="_blank">
                        <div className="fixed-column-30 top-padding-4">
                          <p>{index + 1}.</p>
                        </div>
                        <div className="fixed-column-50">
                          <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30 standard-border" />
                        </div>
                        <div className="calc-column-offset-110 top-padding-4">
                          <p className="heading-text-6">{item.firstName} {item.lastName}{(item.email === this.state.selectedGroup.creatorEmail) && " (Admin)"}</p>
                        </div>
                        <div className="fixed-column-30 top-padding-5">
                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 pin-right display-inline" />
                        </div>
                        <div className="clear" />

                        <div className="row-15">
                          <hr />
                        </div>

                      </Link>
                    </div>
                  )}

                </div>
             )}

             {(this.state.showShareButtons) && (
               <div key="showDescription" className="full-width padding-20 center-text">
                  <p className="heading-text-2">Share {(this.state.sharePosting) ? " This Post " : this.state.selectedGroup.name} with Friends!</p>

                  <div className="top-padding-20">
                    <p>Share this link:</p>
                    <label className="bold-text cta-color">{this.state.shareURL}</label>
                  </div>

                  <div className="spacer" />

                  <div className="top-padding-20">
                    {this.renderShareButtons()}
                  </div>

                </div>
             )}

             {(this.state.showInvite) && (
               <div key="showInvite" className="full-width padding-20">
                 <div className="bottom-padding">
                   <p className="heading-text-2">Select Members to Invite To {this.state.selectedGroup.name}</p>
                 </div>
                 {/*
                 <div className="row-10">
                   <select name={"inviteMethod"} value={this.state.inviteMethod} onChange={this.formChangeHandler} className="dropdown">
                     {this.state.inviteMethodOptions.map(value =>
                       <option key={value} value={value}>{value}</option>
                     )}
                   </select>
                 </div>*/}

                 {(this.state.inviteMethod === 'Specific Individuals') && (
                   <div>
                     <div className="row-10">
                       <div className="calc-column-offset-100-static">
                         <input type="text" className="text-field" placeholder={"Search members..."} name="searchMembers" value={this.state.searchString} onChange={this.formChangeHandler}/>
                       </div>
                       <div className="fixed-column-100 left-padding">
                         <button className="btn btn-squarish description-text-2" onClick={() => this.addItem('member')}>Add</button>
                       </div>
                       <div className="clear" />
                     </div>

                     {(this.state.memberOptions && this.state.searchString && this.state.searchString !== '') && (
                       <div>
                         {this.state.memberOptions.map((value, optionIndex2) =>
                           <div key={value._id}>
                             <button className="background-button full-width left-text" onClick={() => this.searchItemClicked(value, 'member')}>
                               <div className="left-padding full-width row-5">
                                 <div className="fixed-column-50">
                                   <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                   <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                 </div>
                                 <div className="calc-column-offset-50">
                                  <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                   <p>{value.firstName} {value.lastName}</p>
                                   {value.roleName && (
                                    <p className="description-text-3">({value.roleName})</p>
                                   )}

                                 </div>
                                 <div className="clear" />
                               </div>
                             </button>
                           </div>
                         )}
                       </div>
                     )}

                     {(this.state.animating) ? (
                       <div className="flex-container flex-center full-space">
                         <div>
                           <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                           <div className="spacer" /><div className="spacer" /><div className="spacer" />
                           <p className="center-text cta-color bold-text">Pulling search results...</p>

                         </div>
                       </div>
                     ) : (
                       <div className="bottom-padding">
                         {this.renderTags('members')}
                         <div className="clear" />
                       </div>
                     )}

                   </div>
                 )}

                 {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color full-width row-5">{this.state.errorMessage}</p>}
                 {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color full-width row-5">{this.state.successMessage}</p>}

                 <div className="row-10">
                   <button className="btn btn-primary" onClick={() => this.inviteMembers()}>
                     Invite Members
                   </button>
                 </div>

               </div>
             )}

             {(this.state.showPost || this.state.sharePosting) && (
               <div key="showPost" className="full-width padding-20">
                  <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost}  posts={this.state.groupPosts} passPosts={this.passPosts} closeModal={this.closeModal} pictureURL={this.state.pictureURL} groupId={this.state.selectedGroup._id} groupName={this.state.selectedGroup.name} groupNotifiees={this.state.selectedGroup.notifiees} />
                </div>
             )}

             {(this.state.showEditGroup) && (
               <div key="showEditGroup" className="full-width padding-20">
                  <SubEditGroup selectedGroup={this.state.groupToEdit} history={this.props.navigate} closeModal={this.closeModal} />
                </div>
             )}

             {(this.state.showAddMeeting) && (
               <div key="showAddMeeting" className="full-width padding-20">
                 <div className="bottom-padding">
                   <p className="heading-text-2">Add a Meeting</p>
                   <SubEditLog modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} history={this.props.navigate} editExisting={this.state.editExisting} log={this.state.log} logs={this.state.logs} passedLogType="Meeting" selectedAdvisor={this.state.selectedAdvisor} logId={this.state.logId} selectedGroup={this.state.selectedGroup} passMeeting={this.passMeeting}/>
                  </div>
                </div>
             )}

             {(this.state.showViewMeeting) && (
               <div key="showViewMeeting" className="full-width padding-20">
                 <div className="bottom-padding">
                   <p className="heading-text-2 full-width center-text">{convertDateToString(new Date(this.state.selectedMeeting.startTime),"datetime-2")} Meeting</p>
                   <p className="full-width center-text top-padding">{this.state.selectedGroup.name}</p>

                   <div className="spacer" /><div className="spacer" />

                   <div className="row-10">
                     <p className="heading-text-6"><label className="bold-text">Location:</label> {this.state.selectedMeeting.location}</p>
                   </div>

                   <div className="row-10">
                     <p className="heading-text-6"><label className="bold-text">Description</label></p>
                     <p className="heading-text-6">{this.state.selectedMeeting.description}</p>
                   </div>
                   {(this.state.selectedMeeting.notes) && (
                     <div className="row-10">
                       <p className="heading-text-6"><label className="bold-text">Meeting Minutes</label></p>
                       <p className="heading-text-6">{this.state.selectedMeeting.notes}</p>
                     </div>
                   )}

                   {(this.state.selectedMeeting.links && this.state.selectedMeeting.links.length > 0) && (
                     <div className="row-10">
                       <p className="heading-text-6"><label className="bold-text">Links</label></p>
                       {this.state.selectedMeeting.links.map((item, optionIndex) =>
                        <div key={item} className="row-5">
                          <a href={item} target="_blank" rel="noopener noreferrer">{item}></a>
                        </div>
                       )}
                     </div>
                   )}
                   {(this.state.selectedMeeting.actionItems && this.state.selectedMeeting.actionItems.length > 0) && (
                     <div className="row-10">
                       <p className="heading-text-6"><label className="bold-text">Action Items</label></p>
                       {this.state.selectedMeeting.actionItems.map((item, optionIndex) =>
                        <div key={item} className="row-5">
                          <p>{item}</p>
                        </div>
                       )}
                     </div>
                   )}

                  </div>
                </div>
             )}

             {(this.state.showGoalDetails || this.state.showHelpOutWidget) && (
               <div>
                 <SubGoalDetails history={this.props.navigate} selectedGoal={this.state.selectedGoal} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showGoalDetails={this.state.showGoalDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.state.profileData}/>
              </div>
             )}

             {(this.state.showAllMeetings) && (
               <div>
                  <div className="row-10">
                    <p className="heading-text-2">Meetings</p>
                  </div>

                 {this.renderMeetings(true)}
              </div>
             )}

           </Modal>
          </div>
      )
    }
}

export default withRouter(GroupDetails);
