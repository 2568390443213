import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAddWorkspaces from './Subcomponents/AddWorkspaces';
import withRouter from './Functions/WithRouter';

class EmpAddOrgs extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.passOrgs = this.passOrgs.bind(this)
      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('opportunity mounted')

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg')
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ emp, orgMode, activeOrg, emailId: email,
          cuFirstName, cuLastName, username, orgLogo
        })

        Axios.get('/api/account', { params: { accountCode: emp } })
        .then((response) => {
          console.log('Account info query attempted within sub settings', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employerName = response.data.accountInfo.employerName

            this.setState({ employerName });
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    passOrgs(org, myOrgs) {
      console.log('passOrgs called', org, myOrgs )

      this.setState({ org, myOrgs })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)

      this.setState({ org })

    }

    render() {

      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

          <div>
            <SubAddWorkspaces opportunityId={this.state.opportunityId} orgCode={this.state.activeOrg} accountCode={this.state.emp} history={this.props.navigate} passOrgs={this.passOrgs}/>
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(EmpAddOrgs);
