import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditResource from './Subcomponents/EditResource';
import withRouter from './Functions/WithRouter';

class AdvEditResource extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let resourceId = null
        if (this.props.params && this.props.params.id) {
          resourceId = this.props.params.id
        }
        let selectedTemplate = null
        let duplicate = null
        let passedType = null
        if (this.props.location && this.props.location.state) {
          selectedTemplate = this.props.location.state.selectedTemplate
          duplicate = this.props.location.state.duplicate
          passedType = this.props.location.state.passedType
        }

        this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo,
          resourceId, selectedTemplate, duplicate, passedType
        })

      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      }
    }


    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          <SubEditResource activeOrg={this.state.activeOrg} resourceId={this.state.resourceId} selectedTemplate={this.state.selectedTemplate} duplicate={this.state.duplicate} passedType={this.state.passedType} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvEditResource);
