import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import StudentDetails from './Subcomponents/StudentDetails';
import withRouter from './Functions/WithRouter';

class AdvAdviseesDetail extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.params.adviseeEmail !== prevProps.params.adviseeEmail) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in advadvisees')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let cuUsername = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      const path = window.location.pathname

      if (this.props.location && this.props.location.state && this.props.location.state.advisee) {

        let { advisee, favorites } = this.props.location.state
        console.log('show me advisee', this.props.location.state, this.props.location.state.advisee)

        if (!favorites) {
          favorites = []
        }

        this.setState({ emailId: email, username: cuUsername, advisee, member: advisee, favorites, path,
          activeOrg, orgFocus, roleName, orgLogo
        });

      } else {

        const { adviseeEmail } = this.props.params
        console.log('lets see adviseeEmail', adviseeEmail)

        this.setState({
            emailId: email,
            username: cuUsername, adviseeEmail, path,
            activeOrg, orgFocus, roleName
        });
      }
    }

    render() {

      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

          <div>
            <StudentDetails activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} roleName={this.state.roleName} path={this.state.path} queryEmail={this.state.adviseeEmail} member={this.state.member} type="All" history={this.props.navigate} />
          </div>

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvAdviseesDetail);
