import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

// import loadingGIF from '../../images/loading-gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditPipeline extends Component {
    constructor(props) {
        super(props)

        this.state = {
          benchmarkOptions: [],
          pathwayOptions: [],

          pipelineBenchmark: { title: '' }

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.EditPipeline = this.EditPipeline.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.deletePipeline = this.deletePipeline.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonEditPipeline', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.selectedPipeline !== prevProps.selectedPipeline) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in commonEditPipeline', this.props)

      const accountCode = this.props.accountCode
      let pathwayOptions = []
      if (this.props.departmentOptions) {
        pathwayOptions = [''].concat(this.props.departmentOptions)
      }
      const employerName = this.props.employerName

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const pictureURL = localStorage.getItem('pictureURL');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      // let orgName = localStorage.getItem('orgName');

      let _id = null
      let pipelineName = null
      let pipelinePathway = null
      let pipelineBenchmark = { title: '' }

      // if (window.location.pathname.includes('/employers/')) {
      //   pipelineName = this.props.employerName + " " + this.props.jobFunction + " Talent Pipeline"
      //   pipelinePathway = this.props.pathway
      // }

      let selectedPipeline = null

      if (this.props.selectedPipeline) {
        _id = this.props.selectedPipeline._id
        pipelineName = this.props.selectedPipeline.name
        pipelinePathway = this.props.selectedPipeline.pathway
        pipelineBenchmark = this.props.selectedPipeline.benchmark

        selectedPipeline = this.props.selectedPipeline
      }

      this.setState({
        pictureURL, emailId, username, cuFirstName, cuLastName, activeOrg, orgFocus, accountCode, employerName,
        pathwayOptions,
        _id, pipelineName, pipelinePathway, pipelineBenchmark, selectedPipeline
      })

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted one', response.data);

        if (response.data.success) {
          console.log('org info query worked')

          const orgName = response.data.orgInfo.orgName
          const orgContactEmail = response.data.orgInfo.contactEmail
          const placementPartners = response.data.orgInfo.placementPartners

          this.setState({ orgName, orgContactEmail, placementPartners });

        } else {
          console.log('org info query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      if (accountCode) {
        console.log('we have an accountCode', accountCode)

        Axios.get('/api/benchmarks', { params: { accountCode }})
        .then((response) => {
          console.log('Benchmarks query within sub attempted', response.data);

          if (response.data.success) {
            console.log('benchmark query worked')

            if (response.data.benchmarks.length !== 0) {
              //jobs = response.data.postings
              console.log('set the benchmark to state')

              const benchmarkOptions = [{ title: '' }].concat(response.data.benchmarks)
              this.setState({ benchmarkOptions });
            }

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'pipelineBenchmark') {
        let pipelineBenchmark = this.state.pipelineBenchmark
        for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
          if (this.state.benchmarkOptions[i - 1].title === event.target.value) {
            pipelineBenchmark = this.state.benchmarkOptions[i - 1]
          }
        }

        this.setState({ pipelineBenchmark })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.props.closeModal()
    }

    deletePipeline() {
      console.log('deletePipeline called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const _id = this.state.selectedPipeline._id

      Axios.delete('/api/pipelines/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Pipeline delete worked');

          this.setState({ successMessage: response.data.message, confirmDelete: false, isSaving: false })
          this.props.navigate("/employers/" + this.state.accountCode + "/pipelines")

        } else {
          console.error('there was an error deleting the pipeline');
          this.setState({ errorMessage: response.data.message, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error, isSaving: false })
      });
    }

    EditPipeline() {
      console.log('EditPipeline called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.pipelineName || this.state.pipelineName === '') {
        this.setState({ errorMessage: 'please add a pipeline name', isSaving: false })
      } else if (!this.state.pipelinePathway || this.state.pipelinePathway === '') {
        this.setState({ errorMessage: 'please add a pipeline pathway', isSaving: false })
      } else if (!this.state.pipelineBenchmark || this.state.pipelineBenchmark.title === '') {
        this.setState({ errorMessage: 'please add a pipeline benchmark', isSaving: false })
      } else {

        let _id = this.state._id
        if (!_id) {
          _id = null
        }

        const name = this.state.pipelineName
        const pathway = this.state.pipelinePathway
        const benchmark = this.state.pipelineBenchmark
        const creatorFirstName = this.state.cuFirstName
        const creatorLastName = this.state.cuLastName
        const creatorEmail = this.state.emailId

        const orgCode = this.state.activeOrg
        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail
        const placementPartners = this.state.placementPartners

        const accountCode = this.state.accountCode
        const employerName = this.state.employerName

        const createdAt = new Date()
        const updatedAt = new Date()

        let saveNewPipeline = true
        if (_id) {
          saveNewPipeline = false
        }

        let posting = {
          _id, name, pathway, benchmark,
          creatorFirstName, creatorLastName, creatorEmail,
          orgCode, orgName, orgContactEmail, placementPartners, accountCode, employerName,
          createdAt, updatedAt, saveNewPipeline
        }

        Axios.post('/api/pipeline', posting)
        .then((response) => {
          console.log('attempted to save pipeline')
          if (response.data.success) {
            //save values
            console.log('Pipeline save worked', response.data);

            const successMessage = response.data.message

            if (_id) {
              this.setState({ successMessage, isSaving: false })
            } else {

              this.setState({ successMessage, isSaving: false, pipelineName: '',
                pipelinePathway: '', pipelineBenchmark: { title: '' }
              })

              this.closeModal()

              if (response.data._id) {
                this.props.navigate("/employers/" + this.state.accountCode + "/pipelines/" + response.data._id)
              } else {
                this.props.navigate("/employers/" + this.state.accountCode + "/pipelines")
              }

            }

          } else {
            console.error('there was an error creating the pipeline', response.data.message);
            this.setState({ errorMessage: response.data.message, isSaving: false })

          }
        }).catch((error) => {
            console.log('there was an error creating the pipeline', error);
            this.setState({ errorMessage: 'There was an error creating the pipeline', isSaving: false })
        });
      }
    }

    render() {

      return (
          <div>

            <div key="addPipeline" className="full-width padding-20">
                <div>
                  <p className="heading-text-2 bottom-padding">Create a Talent Pipeline</p>

                  <div className="spacer" /><div className="spacer" />
                </div>

                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

               {(this.state.isSaving) ? (
                 <div className="flex-container flex-center full-space">
                   <div>
                     <div className="super-spacer" />

                     <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                     <div className="spacer" /><div className="spacer" /><div className="spacer" />
                     <p className="center-text cta-color bold-text">Searching...</p>

                   </div>
                 </div>
               ) : (
                 <div>
                   <div className="row-15">
                     <div className="container-left">
                       <p className="heading-text-6">Pipeline Name<label className="error-color bold-text">*</label></p>
                       <div className="spacer" />
                       <input type="text" className="text-field" placeholder={"e.g., " + this.props.employerName + " Marketing Talent Pipeline..."} name="pipelineName" value={this.state.pipelineName} onChange={this.formChangeHandler}></input>
                     </div>
                     <div className="container-right">
                       <p className="heading-text-6">Pipeline Pathway (i.e., Department)<label className="error-color bold-text">*</label></p>
                       <div className="spacer" />
                       <select name="pipelinePathway" value={this.state.pipelinePathway} onChange={this.formChangeHandler} className="dropdown">
                         {this.state.pathwayOptions.map(value =>
                           <option key={value} value={value}>{value}</option>
                         )}
                       </select>
                       <div className="spacer" />
                       <p className="description-text-2">Need to edit your company departments? <Link to={"/employers/" + this.state.accountCode + "/edit-employer-profile"}>Click Here</Link> to edit.</p>
                     </div>
                     <div className="clear" />
                   </div>

                   <div className="row-15">
                    <p className="heading-text-6">Attach a Benchmark to Score & Rank Members' <label className="error-color bold-text">*</label></p>
                    <div className="spacer" />
                    <select name="pipelineBenchmark" value={this.state.pipelineBenchmark.title} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.benchmarkOptions.map(value =>
                        <option key={value.title} value={value.title}>{value.title}</option>
                      )}
                    </select>

                    <div className="spacer" />
                    <p className="description-text-2">Want to create a benchmark for this? <Link to={"/employers/" + this.state.accountCode + "/benchmarks/add"}>Click Here</Link> to save one, then return to select it.</p>
                   </div>

                   {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5">{this.state.successMessage}</p>}
                   {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5">{this.state.errorMessage}</p>}

                   <div className="row-15">
                     <div className="spacer" />

                     {(this.state.confirmDelete) ? (
                       <div>
                        <p className="bottom-margin error-color">Are you sure you want to delete this pipeline?</p>
                        <button className="btn btn-squarish error-background-color standard-border right-margin" onClick={() => this.deletePipeline()}>Confirm & Delete</button>
                        <button className="btn btn-squarish white-background cta-color" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                       </div>
                     ) : (
                       <div>
                         <button className="btn btn-primary right-margin" disabled={this.state.isSaving} onClick={() => this.EditPipeline()}>{(this.state.selectedPipeline) ? "Save & Edit Pipeline" : "Save & Add Pipeline"}</button>
                         <button className="btn btn-secondary right-margin" onClick={() => this.closeModal()}>Close View</button>
                         {(this.state._id) && (
                           <button className="btn btn-quaternary" onClick={() => this.setState({ confirmDelete: true })}>Delete Pipeline</button>
                         )}

                       </div>
                     )}
                   </div>
                 </div>
               )}

             </div>

          </div>

      )
    }
}

export default withRouter(EditPipeline);
