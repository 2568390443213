import Axios from 'axios';

export const requestConnection = async(friend)=>{
  console.log('requestConnection called', friend)

  return await Axios.post('/api/friend/request', friend)
  .then(async(response) => {

    if (response.data.success) {

      return { success: true, message: response.data.message }

    } else {

      return { success: false, message: response.data.message }
    }
  }).catch((error) => {
      console.log('Advisee request send did not work', error);
  });
}
