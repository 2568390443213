import React from 'react';
import SubHelpPrompt from './Common/HelpPrompt';
import {toggleVars} from './Functions/ToggleVars';

const AppFooter = (history,orgCode, orgLogo, passedCommand, hideNav, openUpAssistant, showPromptDesigner, passedCommandDraft, docContent, showGradePrompt, modalIsOpen, clickerToggler) => {
  console.log('did we get it>!!>>!>!>', passedCommand)

  return (
    <div>
      <SubHelpPrompt history={history} orgCode={orgCode} orgLogo={orgLogo}
        passedCommand={passedCommand} hideNav={hideNav} openUpAssistant={openUpAssistant}
        showPromptDesigner={showPromptDesigner} passedCommandDraft={passedCommandDraft}
        docContent={docContent} showGradePrompt={showGradePrompt} modalIsOpen={modalIsOpen}
        clickerToggler={clickerToggler}
      />
    </div>
  )
}

export default AppFooter;
