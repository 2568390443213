import React, {Component} from 'react';
import Axios from 'axios';
import SubReferralDetails from '../Common/ReferralDetails';
import SubCustomDropdown from '../Common/CustomDropdown';
import SubRenderReferrals from '../Common/RenderReferrals';
import withRouter from '../Functions/WithRouter';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";

class Referrals extends Component {
    constructor(props) {
        super(props)

        this.state = {
          resLimit: 500,
          itemFilters: [],
          itemSorters: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.toggleSearchBar = this.toggleSearchBar.bind(this)

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in referrals', this.props)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      } else if (this.props.passedId !== prevProps.passedId) {
        this.retrieveData()
      } else if (this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in referrals', this.props)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const orgFocus = localStorage.getItem('orgFocus');
      // console.log('show me activeOrg: ', activeOrg)
      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, loadingItems: true })

      if (activeOrg || this.props.noOrgCode) {

        let queryParams = { resLimit: this.state.resLimit }
        if (activeOrg) {
          queryParams['orgCode'] = activeOrg
        } else {
          // queryParams = { orgCode: 'guidedcompass' }
          // queryParams = { isPublic: true }
        }

        Axios.get('/api/referral', { params: queryParams })
       .then((response) => {
         console.log('Referrals query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved referrals')

           const referrals = response.data.referrals
           const filteredReferrals = response.data.referrals
           this.setState({ referrals, filteredReferrals, loadingItems: false })

         } else {
           console.log('no referrals data found', response.data.message)
           this.setState({ loadingItems: false })
         }

       }).catch((error) => {
           console.log('Referrals query did not work', error);
           this.setState({ loadingItems: false })
       });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ searchString, itemFilters, itemSorters, loadingItems: true })
        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        let filters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].name === field) {
            filters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        let itemFilters = filters

        //reset everything
        let searchString = ''
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name !== field) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ filters, itemFilters, loadingItems: true, searchString, itemSorters })

        this.filterResults(this.state.searchString, event.target.value, filters, index, false)
      } else if (event.target.name.includes('sort|')) {

        let sorters = this.state.itemSorters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= sorters.length; i++) {
          if (sorters[i - 1].name === field) {
            sorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        let itemSorters = sorters

        //reset everything
        let searchString = ''
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        this.setState({ searchString, itemFilters, itemSorters, loadingItems: true })

        this.sortResults(event.target.value, field)

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption
      const referrals = this.state.referrals
      const type = this.props.type
      const emailId = this.state.emailId
      const orgCode = this.state.activeOrg

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/referrals/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, referrals, type, emailId, orgCode })
        .then((response) => {
          console.log('Referrals filter query attempted', response.data);

            if (response.data.success) {
              console.log('referral filter query worked')

              let filteredReferrals = response.data.referrals
              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              self.setState({ filteredReferrals, loadingItems: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('project filter query did not work', response.data.message)
              self.setState({ loadingItems: false })
            }

        }).catch((error) => {
            console.log('Project filter query did not work for some reason', error);
            self.setState({ loadingItems: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let referrals = this.state.referrals
      // let filteredProjects = []

      Axios.put('/api/referrals/sort', { sortString, referrals, sortName })
      .then((response) => {
        console.log('Referral sort query attempted', response.data);

          if (response.data.success) {
            console.log('project sort query worked')

            let filteredReferrals = response.data.referrals
            const filterCriteriaArray = null
            const sortCriteriaArray = response.data.sortCriteriaArray

            this.setState({ filteredReferrals, loadingItems: false, filterCriteriaArray, sortCriteriaArray })

          } else {
            console.log('referral sort query did not work', response.data.message)
            this.setState({ loadingItems: false })
          }

      }).catch((error) => {
          console.log('Referral sort query did not work for some reason', error);
          this.setState({ loadingItems: false })
      });
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showReferralDetails: false,
        selectedReferral: null
      });

    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters
        console.log('show filters: ', filters)

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <div>
                  <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                </div>
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters
        console.log('show sorters: ', sorters)

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    render() {

      let passedClassName = "card bottom-margin-30"
      let passedClassName2 = ''

      // const passedPlaceholder = 'Search projects by name or creator...'
      // const pathname = '/advisor/projects/add'
      let filterClass = "card"

      return (
          <div>
            <div className={passedClassName}>
              <div className="calc-column-offset-25 right-padding">
                <p className="heading-text-2">{(this.state.filteredReferrals) ? (this.state.filteredReferrals.length === this.state.resLimit) ? this.state.filteredReferrals.length + "+ " : this.state.filteredReferrals.length + " " : ""}{(!this.state.activeOrg) && " Public "}Referrals</p>
              </div>
              <div className="fixed-column-25">

                <div className="row-5">
                  <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                    <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="GC" className="image-25-fit"/>
                  </button>
                </div>
              </div>
              <div className="clear" />
            </div>

            {(this.state.showingSearchBar) && (
              <div className={filterClass}>
                <div>
                  <div className="filter-field-search full-width">
                      <div className="half-spacer"/>
                      <div className="search-icon-container">
                        <img src={searchIcon} alt="Compass search icon" className="image-auto-20"/>
                      </div>
                      <div className="filter-search-container calc-column-offset-23">
                        <input type="text" className="text-field clear-border" placeholder={"Search referrals..."} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                      </div>
                  </div>
                  <div className="clear" />
                  <div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Filter</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('filter'))}

                  <div className="clear" />
                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Sort</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('sort'))}
                  <div className="clear" />
                </div>
              </div>
            )}

            {(this.state.loadingItems) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading results...</p>

                </div>
              </div>
            ) : (
              <div className={passedClassName2}>
                <SubRenderReferrals history={this.props.navigate} filteredReferrals={this.state.filteredReferrals}
                  profileData={this.state.profileData} activeOrg={this.state.activeOrg}
                  sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                />
              </div>
            )}

            {(this.state.showReferralDetails) && (
              <div>
                <SubReferralDetails history={this.props.navigate} selectedReferral={this.state.selectedReferral} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showReferralDetails={this.state.showReferralDetails} profileData={this.state.profileData}
                  orgCode={this.state.activeOrg}
                />
             </div>
            )}

          </div>

      )
    }
}

export default withRouter(Referrals);
