import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Axios from 'axios';
import SubEmployerBenefits from './Common/EmployerBenefits';
import withRouter from '../components/Functions/WithRouter';

const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const logoImgDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';

class PartnerEmployerPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          questions: []
        }

        this.renderQuestions = this.renderQuestions.bind(this);
        this.questionClicked = this.questionClicked.bind(this);

    }

    componentDidMount() {
      console.log('partner employer page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      const { partner } = this.props.params

      if (partner) {

        //do stuff
        let partnerName = ''

        let activeOrg = partner
        if (activeOrg === 'block-knowledge') {
          activeOrg = 'block'
        } else if (activeOrg === 'detroitk12') {
          activeOrg = 'dpscd'
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        if (partner === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
        } else if (partner === 'detroitk12') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        }

        this.setState({ backgroundImage, partner, partnerName, activeOrg })

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgSelected = response.data.orgInfo
            const orgName = response.data.orgInfo.orgName
            partnerName = orgName
            const orgProgramName = response.data.orgInfo.orgProgramName
            const orgDescription = response.data.orgInfo.orgDescription
            const contactEmail = response.data.orgInfo.contactEmail
            let employerBenefitsText = 'Hire interns and entry-level roles based on forward looking data, including endorsed soft skills and culture match.'
            if (response.data.orgInfo.employerBenefitsText) {
              employerBenefitsText = response.data.orgInfo.employerBenefitsText
            }

            const employerLongBenefitsText = response.data.orgInfo.employerLongBenefitsText

            if (response.data.orgInfo.backgroundImageURL) {
              backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(' + response.data.orgInfo.backgroundImageURL + ')'
            }
            // console.log('show background Image: ', backgroundImage, activeOrg)

            const questions = [
              { question: 'Why should I post openings to Guided Compass and ' + partnerName + '?', answer: 'Guided Compass and ' + partnerName + ' presents you with 3-5 pre-screened, referred candidates for each opening, saving you time and headache screening candidates with little relevant work experience. Guided Compass uses 30+ criteria including details about you, as an employer, your specific functional opening to help train, screen, and refer career-seekers that are the best fit for the opportunity.', isSelected: false},

              { question: 'How do I see “best-fit” candidates for each of my postings?', answer: partnerName + ' will send a custom list of ranked candidates, usually 3 - 5 candidates via email. You also will have the option is viewing the dashboard to view all of the outstanding recommended candidates. From there, you can like, dislike, rank, request to interview, provide an offer, and/or give feedback for each recommended candidate.', isSelected: false},
              { question: 'How can I define my ideal candidate for each of my pathways?', answer: 'Guided Compass uses something called benchmarks to define the ideal candidate. By default, your benchmark for each role posted is the functional track that it falls into. For example, for a UX/UI design intern we would use the “Design Track” benchmark that defines the ideal candidate’s interests, personality, skills, work preferences, project hours, education, experience, and more! You have the option of customizing these benchmarks to your postings. Your benchmarks can be viewed in your portal. If you have any questions or if you would like to customize your benchmark beyond what is capable today, please email ' + contactEmail + '.', isSelected: false},
              { question: 'How can I quickly determine the quality of candidate projects?', answer: 'As vetting services, Guided Compass and ' + partnerName + ' aim to do that for you. Because career-seekers often little work experience, finding quality candidates quickly can be difficult. We’ve designed methods that allow you to quickly see why a candidate is a strong fit. After you interview and/or hire our recommended candidates, we just ask that you provide honest feedback, both the good and the bad!', isSelected: false},
              { question: 'How do I extend an offer to a candidate?', answer: 'In your employer portal, view the candidate that you would like to extend an offer to and click the offer button. Enter the pay amount, confirm the posting details, and confirm the date when this offer expires. Then click send to send to the candidate!', isSelected: false},
              { question: 'Is there anything I should do before candidate(s) start?', answer: 'You should be good to go! Ideally, you would have a workspace that the candidate work out of, but this is not required.', isSelected: false},
              { question: 'Is there anything I should do during and/or after the internship?', answer: "You can enable timesheets to have Guided Compass automatically generate timesheets for your approval. You can also create assignments for the people you've hired.", isSelected: false},
            ]

            if (partner === 'unite-la') {
              questions.push({ question: 'Do I qualify for subsidies to hire candidates?', answer: 'You must request subsidies from ' + partnerName + '. Please email ' + contactEmail + '.', isSelected: false},)
            }

            const deactivated = response.data.orgInfo.deactivated
            let redirectLink = response.data.orgInfo.employerRedirectLink
            if (redirectLink && !redirectLink.startsWith('http')) {
              redirectLink = 'http://' + redirectLink
            }

            const orgLogo = response.data.orgInfo.webLogoURI

            const studentAliasSingular = response.data.orgInfo.studentAliasSingular
            const studentAliasPlural = response.data.orgInfo.studentAliasPlural
            const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
            const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

            this.setState({
              questions, employerBenefitsText, employerLongBenefitsText, backgroundImage,
              orgSelected, orgName, partnerName, orgProgramName, orgDescription, contactEmail,
              deactivated, redirectLink, orgLogo,
              studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural
            });

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

    }


    renderQuestions() {
      console.log('renderQuestions called')

      let rows = []
      for (let i = 1; i <= this.state.questions.length; i++) {
        console.log('show ', this.state.questions[i - 1].answer.split('\n')[1])
        const index = i - 1

        rows.push(
          <div key={i} className="horizontal-padding-3">
            <div className="spacer" />
            <a onClick={() => this.questionClicked(index)} className="background-link">
              <div className="float-left">
                <div className="half-spacer" />
                <img src={arrowIcon} alt="Employer icon" className={this.state.questions[i - 1].isSelected ? "image-auto-20 rotate-90" : "image-auto-20"} />
              </div>
              <div className="float-left left-padding-15">
                <p className="heading-text-6">{this.state.questions[i - 1].question}</p>
              </div>
              <div className="clear" />
            </a>
            {this.state.questions[i - 1].isSelected && (
              <div className="left-padding-30">
                <div className="spacer" />
                <div className="half-spacer" />
                {this.renderAnswers(index)}
              </div>
            )}
            <div className="spacer" />
          </div>
        )
      }

      return rows
    }

    questionClicked(index) {
      console.log('questionClicked called')

      let questions = this.state.questions
      if (this.state.questions[index].isSelected) {
        questions[index]['isSelected'] = false
      } else {
        questions[index]['isSelected'] = true
      }

      this.setState({ questions })

    }

    renderAnswers(index) {
      console.log('renderAnswers called')

      let rows = []

      for (let i = 1; i <= this.state.questions[index].answer.split('\n').length; i++) {
        rows.push(
          <div key={"answer" + i.toString()}>
            <p className="description-text-2 bold-text cta-color">{this.state.questions[index].answer.split('\n')[i - 1]}</p>
          </div>
        )
      }

      return rows
    }

    render() {

      return (
          <div>
              <header className="front-page-header" style={{ backgroundImage: this.state.backgroundImage }}>
                  <div className="row top-margin">
                    <Link to={'/partners/' + this.state.partner}><img src={logoImgDark} alt="Compass logo" className="image-auto-50 float-left top-margin-25" /></Link>
                    <div className="partner-container">
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={addIcon} alt="Guided Compass add logo" className="partner-add-icon" /></Link>
                      </div>
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" className="image-auto-40 float-left top-margin-15 left-margin" /></Link>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="hero-text-box">

                    {(this.state.deactivated) ? (
                      <div>
                        <h1 className="light-text">{this.state.partnerName} Is No Longer a Guided Compass Partner</h1>
                        <p className="two-thirds-width">Click the link below to redirect to {this.state.partnerName}.</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <a href={this.state.redirectLink} target="_blank" className="btn btn-primary cta-background-color white-text">Redirect to {this.state.partnerName}</a>

                      </div>
                    ) : (
                      <div>
                        <h1 className="light-text">Employ {(this.state.studentAliasPlural) ? this.state.studentAliasPlural : "Career-Seekers"}</h1>

                        <p className="two-thirds-width">{this.state.employerBenefitsText}</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <div className="buttonContainer">
                          <Link className="clear-border" to={'/employers/post/' + this.state.activeOrg} state={{ orgCode: this.state.activeOrg }}><button className="btn btn-primary">Post Opportunities</button></Link>
                        </div>
                      </div>
                    )}

                  </div>
                  <div className="clear"/>
              </header>

              {(!this.state.deactivated) && (
                <div>
                  <SubEmployerBenefits history={this.props.navigate} orgSelected={this.state.orgSelected}/>
                  {/*
                  <section className="section-features">
                      <div className="row">
                          <h2>Why Post with {this.state.partnerName}</h2>

                          {(this.state.partner === 'college-to-congress' || this.state.partner === 'detroitk12') ? (
                            <p className="two-thirds-width center-text center-horizontally wrap-text">
                                {this.state.employerLongBenefitsText}
                            </p>
                          ) : (
                            <div>
                              <p className="two-thirds-width center-text center-horizontally">
                                  Guided Compass and {this.state.partnerName} presents you with up to five nearly perfect, pre-screened candidates for each opening, saving you time and headache screening candidates with little-to-no work experience.
                              </p>

                              <div className="spacer" />
                              <div className="spacer" />

                              <p className="two-thirds-width center-text center-horizontally">Guided Compass uses 30+ criteria including details about you, as an employer, your specific functional opening, as well as student career assessments and teacher endorsements on work preferences, skills, and personality traits to help screen students for {this.state.partnerName}.</p>

                              <div className="spacer" />
                              <div className="spacer" />

                              <p className="two-thirds-width center-text center-horizontally">{this.state.partnerName} then interviews, rates, and recommends candidates specifically for your company and opening.</p>

                              <div className="spacer" />
                              <div className="spacer" />
                            </div>
                          )}
                      </div>
                  </section>

                  {(this.state.partner !== 'college-to-congress' && this.state.partner !== 'detroitk12') && (
                    <section className="section-demo">
                      <div className="row">
                        <h2>Frequently Asked Questions</h2>
                      </div>

                      <div className="spacer" />
                      <div className="spacer" />

                      {this.renderQuestions()}

                    </section>
                  )}*/}
                </div>
              )}

              <section className="section-features contrasting-background" >
                {(window.innerWidth < 768) ? (
                  <div className="full-width center-text">
                    <p className="full-width heading-text-2">Are you a Career-Seeker?</p>
                    <div className="top-margin">
                      <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/career-seekers'} state={{ fromExternal: true }}>Switch to Career-Seeker Page</Link>
                    </div>
                  </div>
                ) : (
                  <div className="full-width">
                      <div className="container-left right-text">
                          <div className="full-width float-right">
                            <p className="join-text">Are you a</p>
                            <p className="join-title">Career-Seeker?</p>
                          </div>
                      </div>
                      <div className="container-right left-padding center-item">
                        <div className="padding-20">
                          <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/career-seekers'} state={{ fromExternal: true }}>Switch to Career-Seeker Page</Link>
                        </div>
                      </div>
                      <div className="clear" />
                  </div>
                )}
              </section>

              <Footer history={this.props.navigate} />
          </div>

      )
    }
}

export default withRouter(PartnerEmployerPage);
