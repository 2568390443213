import React, {Component} from 'react';

import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';
// import {convertDateToString} from '../Functions/ConvertDateToString';
import {signOut} from '../Services/AuthRoutes';

import SubLogInFields from './LogInFields';

const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const projectsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-blue.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

const addPeopleIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-people-icon-dark.png';
const addPeopleIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-people-icon-blue.png';
const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';
const phoneIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/phone-icon-dark.png';
const phoneIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/phone-icon-blue.png';
const tagIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon-dark.png';
const tagIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/tag-icon-blue.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const pathsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-blue.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const celebrationIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/celebration-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';

class HelpOutWidget extends Component {
    constructor(props) {
      super(props)
      this.state = {
        selectedPeople: [],
        selectedLinks: [],
        selectedTimes: [],
        selectedProjects: [],
        selectedCareers: [],
        linkCategoryOptions: ['','Video','Event','Course','Article','Report','Job Opportunity','Other']
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.startWallowing = this.startWallowing.bind(this)
      this.stopWallowing = this.stopWallowing.bind(this)
      this.addItem = this.addItem.bind(this)
      this.removeItem = this.removeItem.bind(this)
      this.searchItems = this.searchItems.bind(this)
      this.searchItemClicked = this.searchItemClicked.bind(this)

      this.renderTags = this.renderTags.bind(this)
      this.passData = this.passData.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.segueToProfile = this.segueToProfile.bind(this)

      this.logout = this.logout.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in help out widget')

      if (this.props.profileData !== prevProps.profileData) {
        this.retrieveData()
      } else if (this.props.selectedGoal !== prevProps.selectedGoal || this.props.selectedProject !== prevProps.selectedProject) {
        this.retrieveData()
      } else if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within HelpOutWidget', this.props)

      const profileData = this.props.profileData
      const selectedGoal = this.props.selectedGoal
      const selectedProject = this.props.selectedProject
      // const orgName = this.props.orgName
      // const orgContactEmail = this.props.orgContactEmail
      const headerImageURL = this.props.headerImageURL

      let selectedItem = selectedGoal
      if (selectedProject) {
        selectedItem = selectedProject

        selectedItem['title'] = selectedItem.name
        selectedItem['creatorPictureURL'] = selectedItem.userPic
        selectedItem['creatorFirstName'] = selectedItem.userFirstName
        selectedItem['creatorLastName'] = selectedItem.userLastName
        selectedItem['creatorEmail'] = selectedItem.emailId
        selectedItem['creatorUsername'] = selectedItem.userUsername
      }

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      const accountCode = localStorage.getItem('accountCode');
      const pictureURL = localStorage.getItem('pictureURL');

      let loggedIn = false
      let loginType = "SignUp"
      if (cuFirstName && emailId) {
        loggedIn = true
        loginType = "SignIn"
      }
      console.log('show selectedItem: ', selectedItem)
      this.setState({ profileData, selectedGoal, selectedProject, selectedItem,
        emailId, cuFirstName, cuLastName, orgFocus, roleName, activeOrg, accountCode, pictureURL,
        loggedIn, loginType, headerImageURL
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name.includes("people|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedPeople = this.state.selectedPeople
        selectedPeople[index][name] = event.target.value
        this.setState({ selectedPeople })
      } else if (event.target.name.includes("link|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedLinks = this.state.selectedLinks
        selectedLinks[index][name] = event.target.value
        this.setState({ selectedLinks })
      } else if (event.target.name.includes("time|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedTimes = this.state.selectedTimes
        selectedTimes[index][name] = event.target.value
        this.setState({ selectedTimes })
      } else if (event.target.name.includes("project|")) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let selectedProjects = this.state.selectedProjects
        selectedProjects[index][name] = event.target.value
        this.setState({ selectedProjects })
      } else if (event.target.name === 'searchCareers') {
        this.searchItems(event.target.value, 'career')
      } else if (event.target.name === 'cuFirstName') {
        let capitalizedFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ cuFirstName: capitalizedFirstName })
      } else if (event.target.name === 'cuLastName') {
        let capitalizedLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        this.setState({ cuLastName: capitalizedLastName})
      } else if (event.target.name === 'subscribed') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ subscribed: value, formHasChanged: true })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', searchString, type)

      if (type.includes('career')) {
        if (!searchString || searchString === '') {
          this.setState({ searchCareers: searchString, searchIsAnimatingCareers: false, careerOptions: null })
        } else {

          let searchIsAnimatingCareers = true

          this.setState({ searchCareers: searchString, searchIsAnimatingCareers })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const excludeMissingOutlookData = true
            const excludeMissingJobZone = true

            Axios.put('/api/careers/search', {  searchString, search, excludeMissingOutlookData, excludeMissingJobZone })
            .then((response) => {
              console.log('Careers query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved careers')

                  if (response.data) {

                    let careerOptions = []
                    if (response.data.careers && response.data.careers.length > 0) {
                      careerOptions = response.data.careers
                    }

                    self.setState({ careerOptions, searchIsAnimatingCareers: false })
                  }

                } else {
                  console.log('no career data found', response.data.message)
                  let careerOptions = []
                  self.setState({ careerOptions, searchIsAnimatingCareers: false })
                }

            }).catch((error) => {
                console.log('Career query did not work', error);

                let careerOptions = []
                self.setState({ careerOptions, searchIsAnimatingCareers: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    startWallowing() {
      console.log('startWallowing called')
      const self = this
      const intervalId = window.setInterval(function(){
        /// call your function here
        console.log('we wallowing')
        if (self.state.wallow) {
          self.setState({ wallowing: true, wallow: false, intervalId })
        } else {
          self.setState({ wallowing: true, wallow: true, intervalId })
        }
      }, 800);
    }

    stopWallowing() {
      console.log('stopWallowing called')

      clearInterval(this.state.intervalId)
      this.setState({ wallowing: false, intervalId: null })

    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'career') {
        if (this.state.selectedCareers && this.state.selectedCareers.includes(this.state.searchCareers)) {
          this.setState({ errorMessage: 'You have already added this career path' })
        } else {

          const searchCareers = ''
          const searchObject = null
          const unready = true

          let selectedCareers = this.state.selectedCareers
          selectedCareers.unshift(this.state.searchCareers)

          // let selectedCareerDetails = this.state.selectedCareerDetails
          // selectedCareerDetails.unshift(this.state.searchObject)

          // const selectedCareer = this.state.searchString
          this.setState({ searchCareers, searchObject, unready, selectedCareers, errorMessage: null })

        }
      } else if (type === 'entity') {
        let selectedPeople = this.state.selectedPeople
        selectedPeople.push({ firstName: '', lastName: '', email: '', relationship: '', reason: '' })
        this.setState({ selectedPeople })
      } else if (type === 'link') {
        let selectedLinks = this.state.selectedLinks
        selectedLinks.push({ category: '', url: '' })
        this.setState({ selectedLinks })
      } else if (type === 'time') {
        let selectedTimes = this.state.selectedTimes
        selectedTimes.push({ time: '' })
        this.setState({ selectedTimes })
      } else if (type === 'project') {
        let selectedProjects = this.state.selectedProjects
        selectedProjects.push({ name: '', description: '' })
        this.setState({ selectedProjects })

      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'entity') {
        let selectedPeople = this.state.selectedPeople
        selectedPeople.splice(index,1)
        this.setState({ selectedPeople })
      } else if (type === 'link') {
        let selectedLinks = this.state.selectedLinks
        selectedLinks.splice(index,1)
        this.setState({ selectedLinks })
      } else if (type === 'time') {
        let selectedTimes = this.state.selectedTimes
        selectedTimes.splice(index,1)
        this.setState({ selectedTimes })
      } else if (type === 'project') {
        let selectedProjects = this.state.selectedProjects
        selectedProjects.splice(index,1)
        this.setState({ selectedProjects })
      } else if (type === 'careers') {
        let selectedCareers = this.state.selectedCareers
        selectedCareers.splice(index,1)
        this.setState({ selectedCareers })
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type.includes('career')) {

        let searchObject = passedItem
        let searchCareers = passedItem.name
        let careerOptions = null
        let unready = false

        this.setState({ searchObject, searchCareers, careerOptions, unready })

      }
    }

    sendResource() {
      console.log('sendResource called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.message || this.state.message === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add an accompanying message'})
      } else if (!this.state.cuFirstName || this.state.cuFirstName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your first name and/or login'})
      } else if (!this.state.cuLastName || this.state.cuLastName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your last name'})
      } else if (!this.state.emailId || this.state.emailId === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your email'})
      } else if (!this.state.emailId.includes('@')) {
        this.setState({ isSaving: false, errorMessage: 'Please add a valid email for your email'})
      } else {
        let selectedPeople = null
        if (this.state.selectedPeople && this.state.selectedPeople.length > 0) {
          selectedPeople = []
          for (let i = 1; i <= this.state.selectedPeople.length; i++) {
            if (this.state.selectedPeople[i - 1].firstName === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a first name for each person you recommend'})
            } else if (this.state.selectedPeople[i - 1].lastName === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a last name for each person you recommend'})
            } else if (this.state.selectedPeople[i - 1].email === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add an email for each person you recommend'})
            } else if (!this.state.selectedPeople[i - 1].email.includes('@')) {
              return this.setState({ isSaving: false, errorMessage: 'Please add a valid email for each person you recommend'})
            } else if (this.state.selectedPeople[i - 1].relationship === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add your relationship for each person you recommend'})
            } else if (this.state.selectedPeople[i - 1].reason === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a reason for each person you recommend'})
            } else {
              selectedPeople.push(this.state.selectedPeople[i - 1])
            }
          }
        }

        let selectedLinks = null
        if (this.state.selectedLinks && this.state.selectedLinks.length > 0) {
          selectedLinks = []
          for (let i = 1; i <= this.state.selectedLinks.length; i++) {
            if (this.state.selectedLinks[i - 1].category === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a category to each of your suggested links'})
            } else if (this.state.selectedLinks[i - 1].url === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a link to each of your suggested links'})
            } else {
              selectedLinks.push(this.state.selectedLinks[i - 1])
            }
          }
        }

        let selectedTimes = null
        if (this.state.selectedTimes && this.state.selectedTimes.length > 0) {
          selectedTimes = []
          for (let i = 1; i <= this.state.selectedTimes.length; i++) {
            if (this.state.selectedTimes[i - 1].time === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please select a time for each of your suggested times'})
            } else {
              selectedTimes.push(this.state.selectedTimes[i - 1])
            }
          }
        }

        let selectedProjects = null
        if (this.state.selectedProjects && this.state.selectedProjects.length > 0) {
          selectedProjects = []
          for (let i = 1; i <= this.state.selectedProjects.length; i++) {
            if (this.state.selectedProjects[i - 1].name === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a name to each of your suggested projects'})
            } else if (this.state.selectedProjects[i - 1].description === '') {
              return this.setState({ isSaving: false, errorMessage: 'Please add a description to each of your suggested projects'})
            } else {
              selectedProjects.push(this.state.selectedProjects[i - 1])
            }
          }
        }

        let selectedCareers = null
        if (this.state.selectedCareers && this.state.selectedCareers.length > 0) {
          selectedCareers = this.state.selectedCareers
        }

        const senderPictureURL = this.state.pictureURL
        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId
        const senderUsername = this.state.username

        const recipientPictureURL = this.state.profileData.pictureURL
        const recipientFirstName = this.state.profileData.firstName
        const recipientLastName = this.state.profileData.lastName
        const recipientEmail = this.state.profileData.email
        const recipientUsername = this.state.profileData.username

        let goalId = null
        let goalTitle = null
        let itemType = null
        let itemId = null
        let itemTitle = null
        if (this.state.selectedGoal) {
          itemType = 'Goal'
          itemId = this.state.selectedGoal._id
          itemTitle = this.state.selectedGoal.title
          goalId = this.state.selectedGoal._id
          goalTitle = this.state.selectedGoal.title
        } else if (this.state.selectedProject) {
          itemType = 'Project'
          itemId = this.state.selectedProject._id
          itemTitle = this.state.selectedProject.name
        }

        let message = this.state.message

        const orgCode = this.state.activeOrg
        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail

        const headerImageURL = this.state.headerImageURL

        const createdAt = new Date()
        const updatedAt = new Date()

        const self = this
        function actuallySendSuggestion(unsubscribed) {
          console.log('actuallySendSuggestion called')

          // save and send
          Axios.post('/api/suggestions', {
            senderPictureURL, senderFirstName, senderLastName, senderEmail, senderUsername,
            unsubscribed, headerImageURL,
            recipientPictureURL, recipientFirstName, recipientLastName, recipientEmail, recipientUsername,
            itemType, goalId, goalTitle, itemId, itemTitle, message,
            selectedPeople, selectedLinks, selectedTimes, selectedProjects, selectedCareers,
            orgCode, orgName, orgContactEmail,
            createdAt, updatedAt
          })
          .then((response) => {
            console.log('attempting to save addition to suggestion')
            if (response.data.success) {
              console.log('saved suggestion', response.data)

              self.setState({successMessage: response.data.message, isSaving: false, message: '',
                selectedPeople: [], selectedLinks: [], selectedTimes: [], selectedProjects: [], selectedCareers: [],
                showConfirmation: true
              })

              // this.closeModal()
              window.scrollTo(0, 0)

            } else {
              console.log('did not save successfully')
              self.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              self.setState({ errorMessage: 'there was an error saving suggestion', isSaving: false})
          });
        }

        // check notification preferences
        Axios.get('/api/users/profile/details/' + recipientEmail, { params: { emailId: recipientEmail } })
         .then((response) => {
           console.log('query for profile data worked');

           if (response.data.success) {

             console.log('profile data received', response.data)

             const notificationPreferences = response.data.user.notificationPreferences
             let unsubscribed = null
             if (notificationPreferences && notificationPreferences.length > 0) {
               for (let i = 1; i <= notificationPreferences.length; i++) {
                 if (notificationPreferences[i - 1].slug === 'new-suggestions' && notificationPreferences[i - 1].enabled === false) {
                   unsubscribed = true
                 }
               }
             }

             actuallySendSuggestion(unsubscribed)

           } else {
             console.log('error response', response.data)

             actuallySendSuggestion(null)
           }

        }).catch((error) => {
             console.log('query for profile info did not work', error);
        })

      }
    }

    renderTags(passedArray, type, editMode, inModal) {
      // console.log('renderTags  called', passedArray, type, editMode)

      if (passedArray && passedArray.length > 0) {
        let backgroundColorClass = ''
        if (type === 'careers' || type === 'functions' || type === 'industries') {
          backgroundColorClass = 'primary-background-light'
        } else if (type === 'opportunities') {
          backgroundColorClass = 'secondary-background-light'
        } else if (type === 'competencies') {
          backgroundColorClass = 'tertiary-background-light'
        } else if (type === 'hours') {
          backgroundColorClass = 'quaternary-background-light'
        } else if (type === 'payRanges') {
          backgroundColorClass = 'quinary-background-light'
        } else if (type === 'schools') {
          backgroundColorClass = 'senary-background-light'
        } else if (type === 'majors') {
          backgroundColorClass = 'septary-background-light'
        }

        return (
          <div key={type + "|0"} className={(inModal) && "display-inline center-text"}>
            <div className={(inModal) ? "display-inline center-text" : "top-margin"}>
              {passedArray.map((value, optionIndex) =>
                <div key={type + "|" + optionIndex} className={(inModal) ? "display-inline center-text" : "float-left"}>
                  {(optionIndex < 3) && (
                    <div>
                      {(editMode) && (
                        <div className="close-button-container-1" >
                          <button className="background-button" onClick={() => this.removeItem(type, optionIndex)}>
                            <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                          </button>
                        </div>
                      )}
                      <div className={(inModal) ? "display-inline right-padding-5 center-text" : "float-left right-padding-5"}>
                        <div className="half-spacer" />
                        <div className={"rounded-corners row-7 horizontal-padding-5 " + backgroundColorClass}>
                          {(typeof value === 'object') ? (
                            <div>
                              {(value.title) && (
                                <p className="description-text-2">{value.title}</p>
                              )}
                              {(value.name) && (
                                <p className="description-text-2">{value.name}</p>
                              )}
                            </div>
                          ) : (
                            <p className="description-text-2">{value}</p>
                          )}
                        </div>
                        <div className="half-spacer" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
    }

    passData(data) {
      console.log('passData called', data)
      this.setState(data)
    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        let importedEmailId = null
        let cuFirstName = ''
        let cuLastName = ''
        let emailId = ''
        let password = ''
        let roleName = null
        let roleNameLinked = null
        const otherRoleName = null

        // let phoneNumber = null
        // let accountCode = null
        let loginType = "SignUp"
        let loggedIn = false

        this.setState({ isSavingModal: false, modalIsOpen: false, successMessage: returnedValue.message,
          importedEmailId, cuFirstName, cuLastName, emailId, password, roleName, roleNameLinked, otherRoleName,
          loginType, loggedIn
        })

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    closeModal() {
      console.log('closeModaal called')

      this.setState({ modalIsOpen: false, showLogInFields: false })
    }

    segueToProfile(e, goal) {
      console.log('segueToProfile called', goal)

      e.stopPropagation()
      e.preventDefault()

      if (window.location.pathname.startsWith('/organizations')) {
        window.open('/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername)
        // this.props.navigate('/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername)
        // return '/organizations/' + this.props.activeOrg + '/members/' + goal.creatorUsername
      } else if (window.location.pathname.includes('/advisor')) {
        window.open('/advisor/members/' + goal.creatorUsername)
        // this.props.navigate('/advisor/members/' + goal.creatorUsername)
        // return '/advisor/members/' + goal.creatorUsername
      } else if (window.location.pathname.startsWith('/goals') || window.location.pathname.startsWith('/projects')) {
        window.open('/' + goal.creatorUsername + '/profile')
        // this.props.navigate('/' + goal.creatorUsername + '/profile')
        // return '/' + goal.creatorUsername + '/profile'
      } else if (this.props.pageSource === 'landingPage') {
        window.open('/' + goal.creatorUsername + '/profile')
        // this.props.navigate('/' + goal.creatorUsername + '/profile')
        // return '/' + goal.creatorUsername + '/profile')
      } else if (window.location.pathname.startsWith('/app/')) {
        this.props.navigate('/app/profile/' + goal.creatorUsername)
      } else {
        window.open('/' + goal.creatorUsername + '/profile')
      }
    }

    render() {

      return (
        <div>
          <div key="showHelpOutWidget" className="full-width padding-20">

            {(this.state.showConfirmation) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <div className="super-spacer" />

                  <img src={celebrationIcon} alt="GC" className={"image-auto-100 center-horizontally"} style={(this.state.wallow) ? { transition: 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)', position: 'relative', transform: 'translate(50%)' } : { transition: 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)', position: 'relative', transform: 'translate(10%)' }}/>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <div className="horizontal-padding">
                    <p className="heading-text-1 center-text cta-color bold-text">You're Awesome!</p>
                    <p className="center-text bold-text row-20 description-text-color">Thanks for helping {this.state.profileData.firstName} out! We sent an email to both of you with details and your suggestion will be pinned to their goal profile for future reference.</p>
                  </div>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <div className="full-width center-text">
                    {(this.state.wallowing) ? (
                      <button className="btn btn-primary right-margin error-background-color clear-border" onClick={() => this.stopWallowing()}>Stop this Nonsense</button>
                    ) : (
                      <button className="btn btn-primary right-margin" onClick={() => this.startWallowing()}>Wallow in Pride</button>
                    )}

                    <button className="btn btn-secondary white-background cta-color left-margin" onClick={() => this.props.closeModal()}>Help Others</button>
                  </div>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                </div>
              </div>
            ) : (
              <div>
                {(this.state.profileData) && (
                  <div>
                    <p className="heading-text-2 full-width center-text">"{this.state.selectedItem.title}"</p>
                    {/*<p className="heading-text-5 top-margin description-text-color full-width center-text"> by {convertDateToString(new Date(this.state.selectedGoal.deadline),"datetime-2")}</p>*/}

                    <div className="top-padding flex-container row-direction flex-center">
                      <button target="_blank" className={(this.state.selectedItem.creatorUsername) ? "background-button cta-color flex-center padding-10" : "background-button flex-center padding-10"} disabled={(this.state.selectedItem.creatorUsername) ? false : true} onClick={(e) => this.segueToProfile(e,this.state.selectedItem)}>
                        <div className="float-left">
                          <img src={(this.state.selectedItem.creatorPictureURL) ? this.state.selectedItem.creatorPictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1 display-inline" />
                        </div>
                        <div className="float-left left-padding top-margin-5">
                          <p className="heading-text-5">{this.state.selectedItem.creatorFirstName} {this.state.selectedItem.creatorLastName}</p>
                        </div>
                        <div className="clear" />
                      </button>
                    </div>

                    <div className="spacer" />

                    {(!this.state.loggedIn) && (
                      <div className="error-border vertical-margin-20 row-15 horizontal-padding-5">
                        <div>
                          <div className="fixed-column-30 top-margin-5">
                            <img src={xIcon} alt="GC" className="image-auto-15" />
                          </div>
                          <div className="calc-column-offset-30">
                            <p className="description-text-3 top-padding-5">Your profile information could not be imported (e.g., name). Login <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showLogInFields: true })} disabled={this.state.isSavingModal}>here</button> to import your information</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}

                    <p className="heading-text-5 row-20">Click an icon to provide a resource <label className="error-color bold-text">*</label></p>

                    <div className="row-10">
                      <div className="float-left">
                        <button className="background-button" onClick={(this.state.showPeople) ? () => this.setState({ showPeople: false }) : () => this.setState({ showPeople: true })}>
                          <img src={(this.state.showPeople) ? addPeopleIconBlue : addPeopleIconDark} className="image-auto-20" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Suggest a person or organization that can help" />
                        </button>
                      </div>
                      <div className="float-left left-padding-20">
                        <button className="background-button" onClick={(this.state.showLink) ? () => this.setState({ showLink: false }) : () => this.setState({ showLink: true })}>
                          <img src={(this.state.showLink) ? linkIconBlue : linkIcon} className="image-auto-20" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Share a helpful video, event, course, article, or report"/>
                        </button>
                      </div>
                      <div className="float-left left-padding-20">
                        <button className="background-button" onClick={(this.state.showSchedule) ? () => this.setState({ showSchedule: false }) : () => this.setState({ showSchedule: true })}>
                          <img src={(this.state.showSchedule) ? phoneIconBlue : phoneIconDark} className="image-auto-20" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Schedule some time to chat" />
                        </button>
                      </div>
                      {(this.state.selectedGoal) && (
                        <div>
                          <div className="float-left left-padding-20">
                            <button className="background-button" onClick={(this.state.showProject) ? () => this.setState({ showProject: false }) : () => this.setState({ showProject: true })}>
                              <img src={(this.state.showProject) ? projectsIconBlue : projectsIconDark} className="image-auto-20" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Suggest a project they could work on" />
                            </button>
                          </div>
                          <div className="float-left left-padding-20">
                            <button className="background-button" onClick={(this.state.showPaths) ? () => this.setState({ showPaths: false }) : () => this.setState({ showPaths: true })}>
                              <img src={(this.state.showPaths) ? pathsIconBlue : pathsIconDark} className="image-auto-20 float-left" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Share a career path" />
                              <img src={(this.state.showPaths) ? tagIconBlue : tagIconDark} className="image-auto-9 float-left right-margin-negative-24 right-margin-2" alt="GC" />
                              <div className="clear" />
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="clear" />
                      <ReactTooltip id="tooltip-placeholder-id" />
                    </div>

                    {(this.state.showPeople) && (
                      <div className="row-20">
                        <div>
                          <div className="float-left">
                            <p className="heading-text-5 bottom-margin-20">Suggest People Who Can Help</p>
                          </div>
                          <div className="float-left left-padding">
                            <button className="background-button" onClick={() => this.addItem('entity')}>
                              <div className="cta-border padding-7 circle-corners">
                                <img src={addIcon} alt="GC" className="image-auto-12" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a contact"/>
                              </div>
                            </button>
                          </div>
                          <div className="clear" />
                          <ReactTooltip id="tooltip-placeholder-id" />
                        </div>

                        <div className="half-spacer" />

                        {(this.state.selectedPeople && this.state.selectedPeople.length > 0) ? (
                          <div>
                            {this.state.selectedPeople.map((item, optionIndex) =>
                              <div key={item}>
                                <div className="top-margin-15">
                                  <div className="float-left">
                                    <p className="heading-text-6">Contact #{optionIndex + 1}</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <button className="background-button" onClick={() => this.removeItem('entity', optionIndex)}>
                                      <div className="error-border padding-7 circle-corners">
                                        <img src={closeIcon} alt="GC" className="image-auto-10" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Remove contact"/>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">First Name</label>
                                    <input type="text" className="text-field" placeholder="First name" name={"people|firstName|" + optionIndex} value={item.firstName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">Last Name</label>
                                    <input type="text" className="text-field" placeholder="Last name" name={"people|lastName|" + optionIndex} value={item.lastName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">Email</label>
                                    <input type="text" className="text-field" placeholder="Email" name={"people|email|" + optionIndex} value={item.email} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">Relationship</label>
                                    <input type="text" className="text-field" placeholder="What is your relationship with this person?" name={"people|relationship|" + optionIndex} value={item.relationship} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>
                                <div className="row-10">
                                  <label className="profile-label">Why are you connecting {this.state.profileData.firstName} to this person?</label>
                                  <textarea type="text" className="text-field" placeholder="How can this peron help?" name={"people|reason|" + optionIndex} value={item.reason} onChange={this.formChangeHandler}/>
                                </div>

                                <hr />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                           <div className="spacer" /><div className="spacer" />
                           <hr />
                          </div>
                        )}

                      </div>
                    )}

                    {(this.state.showLink) && (
                      <div className="row-20">
                        <div>
                          <div className="float-left">
                            <p className="heading-text-5 bottom-margin-20">Share a Link to a Video, Event, Course, Article, Report, or Job Opportunity</p>
                          </div>
                          <div className="float-left left-padding">
                            <button className="background-button" onClick={() => this.addItem('link')}>
                              <div className="cta-border padding-7 circle-corners">
                                <img src={addIcon} alt="GC" className="image-auto-12" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a link to a resource" />
                              </div>
                            </button>
                          </div>
                          <div className="clear" />
                          <ReactTooltip id="tooltip-placeholder-id" />
                        </div>

                        <div className="half-spacer" />

                        {(this.state.selectedLinks && this.state.selectedLinks.length > 0) ? (
                          <div>
                            {this.state.selectedLinks.map((item, optionIndex) =>
                              <div key={item}>

                                <div className="top-margin-15">
                                  <div className="float-left">
                                    <p className="heading-text-6">Link Resource #{optionIndex + 1}</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <button className="background-button" onClick={() => this.removeItem('link', optionIndex)}>
                                      <div className="error-border padding-7 circle-corners">
                                        <img src={closeIcon} alt="GC" className="image-auto-10" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Remove link resource"/>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>

                                <div className="bottom-padding">
                                  <div className="container-left">
                                    <label className="profile-label">Which type of resource is this?</label>
                                    <select name={"link|category|" + optionIndex} className="dropdown" value={item.category} onChange={this.formChangeHandler}>
                                      {this.state.linkCategoryOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                    </select>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">Paste the actual link below</label>
                                    <input type="text" className="text-field" placeholder="Http..." name={"link|url|" + optionIndex} value={item.url} onChange={this.formChangeHandler}/>
                                    {(item.url && !item.url.startsWith('http')) && (
                                      <div className="row-5">
                                        <p className="error-color bold-text description-text-2">The link must start with http</p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="clear" />
                                  <div className="spacer" />
                                </div>

                                <hr />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                           <div className="spacer" /><div className="spacer" />
                           <hr />
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.showSchedule) && (
                      <div className="row-20">
                        <div>
                          <div className="float-left">
                            <p className="heading-text-5 bottom-margin-20">Offer Some Times You Are Available to Chat</p>
                          </div>
                          <div className="float-left left-padding">
                            <button className="background-button" onClick={() => this.addItem('time')}>
                              <div className="cta-border padding-7 circle-corners">
                                <img src={addIcon} alt="GC" className="image-auto-12" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a time to chat" />
                              </div>
                            </button>
                          </div>
                          <div className="clear" />
                          <ReactTooltip id="tooltip-placeholder-id" />
                        </div>

                        <div className="half-spacer" />

                        {(this.state.selectedTimes && this.state.selectedTimes.length > 0) ? (
                          <div>
                            {this.state.selectedTimes.map((item, optionIndex) =>
                              <div key={item}>
                                <div className="row-10">
                                  <div className="fixed-column-40">
                                    <div className="mini-spacer" /><div className="mini-spacer" />
                                    <button className="background-button" onClick={() => this.removeItem('time', optionIndex)}>
                                      <div className="error-border padding-10 circle-corners">
                                        <img src={closeIcon} alt="GC" className="image-auto-13" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Remove time to chat"/>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="calc-column-offset-40">
                                    <input type="datetime-local" className="date-picker" placeholder="Time to chat" name={"time|time|" + optionIndex} value={item.time} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="clear" />
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>

                                <hr />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                           <div className="spacer" /><div className="spacer" />
                           <hr />
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.showProject) && (
                      <div className="row-20">
                        <div>
                          <div className="float-left">
                            <p className="heading-text-5 bottom-margin-20">Suggest Projects to Work On</p>
                          </div>
                          <div className="float-left left-padding">
                            <button className="background-button" onClick={() => this.addItem('project')}>
                              <div className="cta-border padding-7 circle-corners">
                                <img src={addIcon} alt="GC" className="image-auto-12" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Suggest a project"/>
                              </div>
                            </button>
                          </div>
                          <div className="clear" />
                          <ReactTooltip id="tooltip-placeholder-id" />
                        </div>

                        <div className="half-spacer" />

                        {(this.state.selectedProjects && this.state.selectedProjects.length > 0) ? (
                          <div>
                            {this.state.selectedProjects.map((item, optionIndex) =>
                              <div key={item}>
                                <div className="top-margin-15">
                                  <div className="float-left">
                                    <p className="heading-text-6">Suggested Project #{optionIndex + 1}</p>
                                  </div>
                                  <div className="float-left left-padding">
                                    <button className="background-button" onClick={() => this.removeItem('project', optionIndex)}>
                                      <div className="error-border padding-7 circle-corners">
                                        <img src={closeIcon} alt="GC" className="image-auto-10" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Remove project"/>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="clear" />
                                  <ReactTooltip id="tooltip-placeholder-id" />
                                </div>

                                <div className="row-10">
                                  <label className="profile-label">Name</label>
                                  <input type="text" className="text-field" placeholder="Name of project..." name={"project|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler}/>
                                </div>
                                <div className="row-10">
                                  <label className="profile-label">Description</label>
                                  <textarea type="text" className="text-field" placeholder="Description of project..." name={"project|description|" + optionIndex} value={item.description} onChange={this.formChangeHandler}/>
                                </div>
                                <hr />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                           <div className="spacer" /><div className="spacer" />
                           <hr />
                          </div>
                        )}

                      </div>
                    )}

                    {(this.state.showPaths) && (
                      <div className="row-20">
                        <p className="heading-text-5 bottom-margin-20">Suggest Career Paths</p>
                        <div className="spacer" />

                        <div>
                          <div className="calc-column-offset-70">
                            <input type="text" className="text-field" placeholder="Search over 1,100 career paths..." name="searchCareers" value={this.state.searchCareers} onChange={this.formChangeHandler}/>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(this.state.unready) ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={this.state.unready} onClick={() => this.addItem('career')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>

                        <div className="spacer" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                        {(this.state.searchIsAnimatingCareers) ? (
                          <div className="flex-container flex-center full-space">
                            <div>
                              <div className="super-spacer" />

                              <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                              <div className="spacer" /><div className="spacer" /><div className="spacer" />
                              <p className="center-text cta-color bold-text">Searching...</p>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {(this.state.careerOptions && this.state.careerOptions.length > 0) && (
                                <div className="card top-margin">
                                  {this.state.careerOptions.map((value, optionIndex) =>
                                    <div key={value._id} className="left-text bottom-margin-5 full-width">
                                      <button className="background-button full-width row-5 left-text" onClick={() => this.searchItemClicked(value, 'career')}>
                                        <div className="full-width">
                                          <div className="fixed-column-40">
                                            <div className="mini-spacer" />
                                            <img src={careerMatchesIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                          </div>
                                          <div className="calc-column-offset-40">
                                            <p className="cta-color">{value.name}</p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div>
                              {this.renderTags(this.state.selectedCareers, 'careers', true)}
                              <div className="clear" />
                            </div>

                            <div>
                             <div className="spacer" /><div className="spacer" />
                             <hr />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <p className="heading-text-5 row-20">Share an accompanying message <label className="error-color bold-text">*</label></p>
                    <textarea className="text-field" type="text" placeholder="Add a message" name="message" value={this.state.message} onChange={this.formChangeHandler} />

                    <div className="top-margin-20">
                      <p className="heading-text-5">Your Information</p>

                      {(this.state.loggedIn) ? (
                        <div>
                          <div className="cta-border vertical-margin-20 row-15 horizontal-padding-5">
                            <div>
                              <div className="fixed-column-30 top-margin-5">
                                <img src={checkmarkIcon} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-30">
                                <p className="description-text-3 top-padding-5">You are signed in as (<label className="cta-color">{this.state.emailId})</label>). Is this not you? <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal} >Log out</button></p>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                          {/*
                          <div className="row-20">


                            {(this.state.loginType === 'SignUp') ? (
                              <div>
                                <div className="bottom-margin">
                                  <p className="description-text-3 top-padding-5">Already have an account? Login to <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >import your information</button></p>
                                </div>
                              </div>
                            ) : (
                              <div className="bottom-margin">
                                <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                              </div>
                            )}

                            <SubLogInFields history={this.props.navigate}
                              formChangeHandler={this.formChangeHandler} passData={this.passData}
                              onlyFields={true} logout={this.logout} type={this.state.loginType}
                              loggedIn={this.state.loggedIn} emailId={this.state.emailId}
                            />
                          </div>*/}
                        </div>
                      ) : (
                        <div className="error-border vertical-margin-20 row-15 horizontal-padding-5">
                          <div>
                            <div className="fixed-column-30 top-margin-5">
                              <img src={xIcon} alt="GC" className="image-auto-15" />
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-3 top-padding-5">Your profile information could not be imported (e.g., name). Login <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showLogInFields: true })} disabled={this.state.isSavingModal}>here</button> to import your information</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}
                    </div>

                    {/*
                      <div>
                        {(!this.state.loggedIn) && (
                          <div className="top-margin-20">
                            <hr />

                            <div className="row-20">
                              <p className="heading-text-5">Your Information</p>

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">First Name</label>
                                  <input type="text" className="text-field" placeholder="Your first name" name="cuFirstName" value={this.state.cuFirstName} onChange={this.formChangeHandler}/>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Last Name</label>
                                  <input type="text" className="text-field" placeholder="Your last name" name="cuLastName" value={this.state.cuLastName} onChange={this.formChangeHandler}/>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Email</label>
                                  <input type="text" className="text-field" placeholder="Your email" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler}/>
                                </div>
                                <div className="clear" />
                              </div>

                            </div>

                          </div>
                        )}
                      </div>
                    */}

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-1 row-5 error-color">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-1 row-5 cta-color">{this.state.successMessage}</p>}

                    <div className="top-padding-20">
                      <button className="btn btn-primary right-margin" onClick={() => this.sendResource()}>Send Suggestion/Message</button>
                      <button className="btn btn-secondary" onClick={() => this.props.closeModal()}>Cancel</button>
                    </div>
                  </div>
                )}

              </div>
            )}

          </div>

          <Modal
           isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
           className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
          >
            <div key="showLogInFields">
              <div className="full-width padding-20">
                {(this.state.loginType === "SignUp") ? (
                  <div className="row-10">
                    <p className="heading-text-4">Sign Up</p>
                  </div>
                ) : (
                  <div className="row-10">
                    <p className="heading-text-4">Log In</p>
                  </div>
                )}

                {(this.state.loginType === 'SignUp') ? (
                  <div>
                    <div className="bottom-margin">
                      <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >Log In</button> to import your information</p>
                    </div>
                  </div>
                ) : (
                  <div className="bottom-margin">
                    <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                  </div>
                )}

                {(this.state.showLogInFields) && (
                  <div>
                    <SubLogInFields history={this.props.navigate}
                      formChangeHandler={this.formChangeHandler} passData={this.passData}
                      onlyFields={true} logout={this.logout} type={this.state.loginType}
                      loggedIn={this.state.loggedIn} emailId={this.state.emailId}
                      orgCode={this.state.activeOrg} closeModal={this.closeModal}
                    />
                  </div>
                )}

              </div>
            </div>
          </Modal>
        </div>
      )
    }
}

export default HelpOutWidget;
