import React, {Component} from 'react';
import Axios from 'axios';
import SubExchange from './Exchange';
import EditCourse from './EditCourse';
import Modal from 'react-modal';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Courses extends Component {
    constructor(props) {
      super(props)

      this.state = {
        resLimit: 500,
        opacity: 0,
        transform: 'translate(90%)',

        subNavSelected: 'All',
        subNavCategories: ['All','Editable Courses',"Courses You're Enrolled In"],
        emailId: null,
        username: '',
        org: '',
        courses: [],
        enrolledCourses: [],

        selectedIndex: 0

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)

      this.addNewCourse = this.addNewCourse.bind(this)
      this.updateCourse = this.updateCourse.bind(this)
      this.removeItem = this.removeItem.bind(this)
      this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)

      this.openCurriculumExchange = this.openCurriculumExchange.bind(this)
      this.passItem = this.passItem.bind(this)
      this.subNavClicked = this.subNavClicked.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.activeOrg)

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (email && org) {

        let prefix = '/advisor'
        if (window.location.pathname.startsWith('/organizations')) {
          prefix = '/organizations/' + org
        }

        this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus, prefix })

        const resLimit = this.state.resLimit
        Axios.get('/api/courses', { params: { orgCode: org, resLimit } })
        .then((response) => {

            if (response.data.success && response.data.courses) {
              console.log('Course details query worked', response.data);

              let courses = response.data.courses
              this.setState({ courses })

            } else {
              console.log('no courses found', response.data.message)
            }

        }).catch((error) => {
            console.log('Course query did not work', error);
        });

        let enrollmentParams = { emailId: email }
        Axios.get('/api/courses/enrollments', { params: enrollmentParams })
       .then((response) => {
         console.log('Enrollments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved enrollments')

           let courseIds = []
           for (let i = 1; i <= response.data.enrollments.length; i++) {
             if (response.data.enrollments[i - 1].courseId) {
               courseIds.push(response.data.enrollments[i - 1].courseId)
             }
           }

           if (courseIds.length > 0) {
             Axios.get('/api/courses', { params: { courseIds } })
             .then((response) => {

                 if (response.data.success && response.data.courses) {
                   console.log('Course details query worked', response.data);

                   const enrolledCourses = response.data.courses
                   this.setState({ enrolledCourses })

                 } else {
                   console.log('no courses found', response.data.message)
                 }

             }).catch((error) => {
                 console.log('Course query did not work', error);
             });
           }

         } else {
           console.log('no enrollments data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Enrollments query did not work', error);
       });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const gradeLevelOptions = ['','6th - 8th','9th - 10th','11th - 12th','High School Graduate','College','All Grade Levels']
            const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

            let functionOptions = response.data.workOptions[0].functionOptions
            let industryOptions = response.data.workOptions[0].industryOptions

            this.setState({ gradeLevelOptions, knowledgeLevelOptions, functionOptions, industryOptions })
          }
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted one', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let orgContactEmail = response.data.orgInfo.contactEmail
            let orgName = response.data.orgInfo.orgName
            this.setState({ orgContactEmail, orgName })

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

        } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
        }
      }
    }

    closeModal() {
      console.log('closeModal in courses: ', this.state.showCourseDetail)

      this.setState({ modalIsOpen: false, showEditCourse: false, showLessons: false });
    }

    addNewCourse(courseObject) {
      console.log('addNewCourse called', courseObject)

      // const course = courseObject

      let courses = this.state.courses
      courses.splice(courses.length - 2,0,courseObject)

      console.log('compare course: ', courses)

      const showEditCourse = false
      this.setState({ courses, showEditCourse })
    }

    updateCourse(courseObject, deleteCourse) {
      console.log('updateCourse called', courseObject, deleteCourse)

      const selectedIndex = this.state.selectedIndex
      let courses = this.state.courses

      if (deleteCourse) {
        courses.splice(selectedIndex,1)
      } else {
        courses[selectedIndex] = courseObject
      }

      const showEditCourse = false
      this.setState({ courses, showEditCourse })

    }

    toggleConfirmDelete(index) {
      console.log('toggleConfirmDelete called', index)

      let courses = this.state.courses
      if (courses[index].confirmDelete) {
        courses[index]['confirmDelete'] = false
      } else {
        courses[index]['confirmDelete'] = true
      }
      this.setState({ courses })
    }

    removeItem(index) {
      console.log('removeItem called', index)

      this.setState({ serverSuccessMessage: null, serverErrorMessage: null })
      const _id = this.state.courses[index]._id

      Axios.delete('/api/courses/' + _id)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Course delete worked');

          let courses = this.state.courses
          courses.splice(index, 1)
          this.setState({ courses, serverSuccessMessage: 'Course was successfully deleted!' })

        } else {
          console.error('there was an error deleting the course');
          this.setState({ serverErrorMessage: 'There was an error deleting the course'})
        }
      }).catch((error) => {
        console.log('The deleting did not work', error);
        this.setState({ serverErrorMessage: 'There was an error deleting the course: ' + error})
      });
    }

    openCurriculumExchange(exchangeType) {
      console.log('openCurriculumExchange called')

      this.setState({ modalIsOpen: true, showLessons: true, exchangeType })

    }

    passItem(course, addCourse, index, newlyCreated,preexistingId, synced) {
      console.log('passItem called: ', course, addCourse, index, newlyCreated,preexistingId)

      let courses = this.state.courses
      if (preexistingId) {
        courses[index] = course
      } else if (addCourse) {
        if (courses) {
          if (courses.findIndex(l => l.curriculumItem === course.curriculumItemId) > -1) {

            const deleteIndex = courses.findIndex(l => l.curriculumItem === course.curriculumItemId)
            courses.splice(deleteIndex,1)
            addCourse = false
          } else {

            if (newlyCreated) {
              courses.push(course)
            }
          }
        } else {
          courses = [course]
        }
      } else {
        console.log('about to delete', index)
        if (!synced) {
          courses.splice(index,1)
        }
      }

      this.setState({ courses, successMessage: 'Changes saved!', })
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      this.setState({ subNavSelected: itemSelected })
      localStorage.setItem('courseNavSelected', itemSelected)

    }

    render() {

      let mainClass = ""
      let mainStyle = {}
      let navClass = ""
      let navStyle = {}
      let mainClass2 = ""
      if (window.location.pathname.startsWith('/organizations')) {
        if (toggleVars.hideAdminSideNav) {
          mainClass = "center-horizontally card top-margin-2-percent width-70-percent max-width-1400"
          navClass = "center-horizontally top-margin-2-percent width-70-percent max-width-1400"
          mainClass2 = "center-horizontally card top-margin-30 width-70-percent max-width-1400"
        } else {
          mainClass = "floating-container top-margin-2-percent card"
          mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
          navClass = "floating-container top-margin-2-percent"
          navStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
          mainClass2 = "floating-container card"
        }
      } else if (window.location.pathname.startsWith('/advisor')) {
        mainClass = "center-horizontally card top-margin-2-percent width-70-percent max-width-1400"
        navClass = "center-horizontally top-margin-2-percent width-70-percent max-width-1400"
        mainClass2 = "center-horizontally card top-margin-30 width-70-percent max-width-1400"
      }

      return (
        <div>
          <div className={mainClass} style={mainStyle}>
            <div className="calc-column-offset-30">
              <p className="heading-text-2">{(this.state.courses) ? (this.state.courses.length === this.state.resLimit) ? this.state.courses.length + "+ " : this.state.courses.length + " " : ""}Courses</p>
              <p className="description-text-color top-padding-5">Courses to upskill students / career-seekers.</p>
            </div>
            <div className="fixed-column-30 top-margin">
              <button onClick={() => this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: true, selectedCourse: null })} className="background-button full-width clear-margin">
                <img alt="GC" src={addIconBlue} className="image-auto-25 pin-right" />
              </button>
            </div>
            <div className="clear" />
          </div>

          {(this.state.serverSuccessMessage) && <p className="success-message">{this.state.serverSuccessMessage}</p>}
          {(this.state.serverErrorMessage) && <p className="error-message">{this.state.serverErrorMessage}</p>}

          {(!window.location.pathname.startsWith('/organizations') && !window.location.pathname.startsWith('/advisor')) && (
            <div>
              <div className="spacer"/><div className="spacer"/><div className="spacer"/>
            </div>
          )}

          <div className={navClass} style={navStyle}>
            {(this.state.enrolledCourses && this.state.enrolledCourses.length > 0) && (
              <div className="full-width">
                <div className="carousel-3" onScroll={this.handleScroll}>
                  {this.state.subNavCategories.map((value, index) =>
                    <div className="display-inline">
                      {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                        <div className="selected-carousel-item-2">
                          <label key={value}>{value}</label>
                        </div>
                      ) : (
                        <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                          <label key={value}>{value}</label>
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <hr />
              </div>
            )}
          </div>

          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Editable Courses') && (
            <div>
              {(this.state.courses && this.state.courses.length > 0) ? (
                <div className={mainClass2} style={mainStyle}>
                  {this.state.courses.map((course, index) =>
                    <div>
                      <div>
                        <div className="fixed-column-40" >
                          <button className="background-button top-margin-20" onClick={() => this.toggleConfirmDelete(index)}>
                            <img src={deniedIcon} alt="GC" className="image-auto-20" />
                          </button>
                        </div>
                        <button onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + course._id)} className="background-button left-text row-10 calc-column-offset-40">
                          {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                          <span className="fixed-column-70">
                            <img src={(course.imageURL) ? course.imageURL : courseIconBlue} alt="GC" className="image-60-fit"/>
                          </span>
                          <span className="calc-column-offset-150 left-padding-20">
                            <p className="heading-text-5">{course.name}</p>
                            <div className="half-spacer" />

                            <div className="float-left description-text-2 left-text full-width">
                              <p>{(course.minutes) ? course.minutes + " mins" : course.estimatedHours + " hours"} | {convertDateToString(new Date(course.startDate),"date-2")} - {(course.isPerpetual) ? "Continual" : convertDateToString(new Date(course.endDate),"datetime-2")}</p>
                              {(course.schoolName) && (
                                <div>
                                  <label>{course.schoolName}</label>
                                  <div className="clear" />
                                  <div className="half-spacer" />
                                </div>
                              )}
                              {(course.gradeLevel) && (
                                <div>
                                  <label>{course.gradeLevel} | {course.knowledgeLevel}</label>
                                  <div className="clear" />
                                </div>
                              )}

                              {(course.functions && course.functions.length > 0) && (
                                <div>
                                  <div className="half-spacer" />
                                  <label className="description-text-color">Occupations: {course.functions.join()}</label>
                                  <div className="clear" />
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </span>
                          <div className="fixed-column-50 top-margin">
                            <p className="cta-color description-text-4 right-text">EDIT</p>
                          </div>
                          <div className="fixed-column-30">
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right top-margin-5"/>
                          </div>
                          <div className="clear" />
                        </button>
                        <div className="clear" />
                      </div>
                      <div className="clear" />

                      {(course.confirmDelete) && (
                        <div className="error-border padding-10 top-margin-20 bottom-margin-20">
                          <p className="error-color bottom-margin">Are you sure you want to delete this item?</p>
                          <button onClick={() => this.removeItem(index)} className="btn btn-squarish error-background-color error-border">Delete Permanently</button>
                          <button onClick={() => this.toggleConfirmDelete(index)} className="btn btn-squarish-white left-margin">Cancel</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                </div>
              )}
            </div>
          )}
          {(this.state.subNavSelected === 'All' || this.state.subNavSelected === "Courses You're Enrolled In") && (
            <div>
              {(this.state.enrolledCourses && this.state.enrolledCourses.length > 0) ? (
                <div className={mainClass2} style={mainStyle}>
                  {this.state.enrolledCourses.map((course, index) =>
                    <div>
                      <div>
                        <button onClick={() => this.props.navigate(this.state.prefix + '/courses/' + course._id + '/take')} className="background-button left-text row-10 full-width">
                          {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                          <span className="fixed-column-70">
                            <img src={(course.imageURL) ? course.imageURL : courseIconBlue} alt="GC" className="image-60-fit"/>
                          </span>
                          <span className="calc-column-offset-150 left-padding-20">
                            <p className="heading-text-5">{course.name}</p>
                            <div className="half-spacer" />

                            <div className="float-left description-text-2 left-text full-width">
                              <p>{course.estimatedHours} Hours | {convertDateToString(new Date(course.startDate),"date-2")} - {(course.isPerpetual) ? "Continual" : convertDateToString(new Date(course.endDate),"datetime-2")}</p>
                              {(course.schoolName) && (
                                <div>
                                  <label>{course.schoolName}</label>
                                  <div className="clear" />
                                  <div className="half-spacer" />
                                </div>
                              )}
                              {(course.gradeLevel) && (
                                <div>
                                  <label>{course.gradeLevel} | {course.knowledgeLevel}</label>
                                  <div className="clear" />
                                </div>
                              )}

                              {(course.functions && course.functions.length > 0) && (
                                <div>
                                  <div className="half-spacer" />
                                  <label className="description-text-color">Occupations: {course.functions.join()}</label>
                                  <div className="clear" />
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </span>
                          <div className="fixed-column-50 top-margin">
                            <p className="cta-color description-text-4 right-text">TAKE</p>
                          </div>
                          <div className="fixed-column-30">
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right top-margin-5"/>
                          </div>
                          <div className="clear" />
                        </button>
                        <div className="clear" />
                      </div>
                      <div className="clear" />

                      {(course.confirmDelete) && (
                        <div className="error-border padding-10 top-margin-20 bottom-margin-20">
                          <p className="error-color bottom-margin">Are you sure you want to delete this item?</p>
                          <button onClick={() => this.removeItem(index)} className="btn btn-squarish error-background-color error-border">Delete Permanently</button>
                          <button onClick={() => this.toggleConfirmDelete(index)} className="btn btn-squarish-white left-margin">Cancel</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                </div>
              )}
            </div>
          )}

          {(this.state.showEditCourse) ? (
            <div>
              <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen}
                fromModal={true} selectedCourse={this.state.selectedCourse}
                orgCode={this.state.org} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions}
                knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions}
                functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse} updateCourse={this.updateCourse}
                courseOptions={this.state.courses} openCurriculumExchange={this.openCurriculumExchange}
              />
            </div>
          ) : (
            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >
              <div key="showPriceChart" className="full-width padding-20">
                {(this.state.showLessons) && (
                  <div key="showLessons" className="full-width padding-20">
                    <SubExchange passedId={null} closeModal={this.closeModal} passLesson={this.passLesson} passItem={this.passItem} lessons={this.state.lessons} courses={this.state.courses} editLesson={true} exchangeType={this.state.exchangeType}/>
                  </div>
                )}
              </div>
            </Modal>
          )}


        </div>
      )
    }
}


export default withRouter(Courses);
