import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubDashboard from './Subcomponents/Dashboard';
import SubNewsFeed from './Subcomponents/NewsFeed';
import SubSideNav from './Common/SideNav';
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

class AdvDashboard extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
      this.openAssistant = this.openAssistant.bind(this)

      // this.child = React.createRef();

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');
      let authType = localStorage.getItem('authType');
      const orgLogo = localStorage.getItem('orgLogo');

      if (orgFocus === 'Placement' && roleName === 'Teacher') {
        roleName = 'Mentor'
      }

      if (email) {

        this.setState({ activeOrg, emailId: email, username, orgFocus, cuFirstName, cuLastName, roleName,
          remoteAuth, authType, orgLogo
        })
      }
    }

    commandClicked(item, demo) {
      console.log('commandClicked called')

      if (demo) {
        // a demo for educators

        const goal = "starting a business"
        const salary = "$100K"
        const jobTitle = "Software Engineer"
        const employerName = "Google"
        const businessIdea = "healthy meal app"

        const field = "health & wellness"
        const offerAmount = "$100K"
        const targetCustomer = "Vegan yoga instructors"
        const productName = "Healthispan"
        const productDescription = "healthy meal planning for health conscious individuals"
        const businessName = "Helathispan"
        const businessDescription = "healthy meal planning for health conscious individuals"
        const interest1 = "basketball"
        const interest2 = "music"
        const interest3 = "cooking"
        const skill1 = "communicating with people"
        const skill2 = "collaborating with people"
        const skill3 = "staying positive"
        const myersBriggsScore = "ESFP"
        const interviewQuestion = "How do you prioritize product features and make trade-off decisions?"
        const interviewResponse = "I score features on a scale of 1-10 for both their impact and their effort. Then I calculate the impact divided by effort. Then I sort the results, prioritizing those with the highest ratios."

        const name = item.name.replace(/{{goal}}/g,goal).replace(/{{salary}}/g,salary).replace(/{{jobTitle}}/g,jobTitle).replace(/{{employerName}}/g,employerName).replace(/{{businessIdea}}/g,businessIdea).replace(/{{field}}/g,field).replace(/{{offerAmount}}/g,offerAmount).replace(/{{targetCustomer}}/g,targetCustomer).replace(/{{productName}}/g,productName).replace(/{{productDescription}}/g,productDescription).replace(/{{businessName}}/g,businessName).replace(/{{businessDescription}}/g,businessDescription).replace(/{{interest1}}/g,interest1).replace(/{{interest2}}/g,interest2).replace(/{{interest3}}/g,interest3).replace(/{{skill1}}/g,skill1).replace(/{{skill2}}/g,skill2).replace(/{{skill3}}/g,skill3).replace(/{{myersBriggsScore}}/g,myersBriggsScore).replace(/{{interviewQuestion}}/g,interviewQuestion).replace(/{{interviewResponse}}/g,interviewResponse)

        // this.child.current.passCommand(name);
        this.setState({ passedCommand: name })
      } else {
        // for mentors
        this.setState({ passedCommand: item })
      }
    }


    openAssistant() {
      console.log('openAssistant called')
      this.setState({ openUpAssistant: true })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />

              {(this.state.roleName !== 'Mentor' && this.state.roleName !== 'Admin' && !toggleVars.hideSideNav) && (
                <SubSideNav selectedComponent={"AdvDashboard"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} />
              )}

              {(this.state.roleName === 'Academy Lead' || this.state.roleName === 'Teacher'  || this.state.roleName === 'School Support' || this.state.roleName === 'Counselor') && (
                <div>
                  <SubDashboard commandClicked={this.commandClicked} openAssistant={this.openAssistant} />
                </div>
              )}

              {(this.state.roleName === 'Mentor' || this.state.roleName === 'Admin') && (
                <div>
                  <SubNewsFeed history={this.props.navigate} postId={this.state.postId} commandClicked={this.commandClicked} />
                </div>
              )}

              {(this.state.roleName === 'Career-Seeker' || this.state.roleName === 'Employer') && (
                <div className="standard-container-2">
                  <p className="error-color">You have entered the education/mentor portal, but your account isn't configured for this portal. Please log out and then log into your appropriate portal or contact this organization to get your role name changed appropriately.</p>
                </div>
              )}

              <div className="clear" />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand,null,this.state.openUpAssistant)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(AdvDashboard);
