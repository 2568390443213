import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import SubExternalProfile from '../components/Subcomponents/ExternalProfile';
import withRouter from '../components/Functions/WithRouter';

class ProfilePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('profilePage just mounted')
      document.body.style.backgroundColor = "#fff";
      window.scrollTo(0, 0)

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      if (this.props.params) {
        // show welcome message
        const { username, id } = this.props.params
        console.log('username: ', username)

        let profileOptions = ['Projects','Goals','Passions','Assessments','Endorsements']

        this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, username, id,
          profileOptions
        })

      }
    }

    render() {

      return (
        <div>
          <TopNavigation currentPage={"profilePage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true} />

          <SubExternalProfile username={this.state.username} history={this.props.navigate} pageSource="landingPage" id={this.state.id}/>

        </div>
      )
    }
}

export default withRouter(ProfilePage);
