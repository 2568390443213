import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditCourse from '../Subcomponents/EditCourse';
import withRouter from '../Functions/WithRouter';

class OrgEditCourse extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      // let roleName = localStorage.getItem('roleName');
      // let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      let org = null
      let courseId = null
      if (this.props.params) {
        org = this.props.params.org
        courseId = this.props.params.id
      }

      this.setState({ emailId: email, org, orgFocus, orgLogo, courseId
      });
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubEditCourse courseId={this.state.courseId} selectedCourse={this.state.selectedCourse} orgCode={this.state.org} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditCourse)
