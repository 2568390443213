import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubTrends from './Subcomponents/Trends';
import withRouter from './Functions/WithRouter';

class Trends extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
    }

    render() {
      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            <div className="center-horizontally clear-float width-70-percent">
              <SubTrends />
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(Trends)
