import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import {signUp, signIn} from '../components/Services/AuthRoutes';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../components/Common/CheckoutForm';
import withRouter from '../components/Functions/WithRouter';

const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const priceChart = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/price_chart.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const exampleLogoLight = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-light.png';
const exampleLogoDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-logo-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeTestPromise = loadStripe("pk_test_51I6htEBMA9yyOaS5GG6dCAJXzACvstyF0j5v6xIZJkHCRlkvvz7VlQzLqAT26bd8TBCyW2WuYyjWmu4fsdPYF6cT00zf4fma2z");
const stripeLivePromise = loadStripe("pk_live_51I6htEBMA9yyOaS5WfSIqrWCbixiXQ7Fm3iRpTd4grD1jfXAHVOUv1UYLuGY5Gi92TPBumqYGCGfbIr7c059mzwg00KxKIJB0l");

class ProblemPlatformSignUpPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          testingStripe: false,
          signInPage: false,
          basePrice: 5,
          showOrgLogos: false,

          viewIndex: 0,
          roleName: 'Student / Career Seeker',

          selectedIndex: 0,
          orderItems: [],
          servicesOffered: [],
          availablePromos: [],
          availableOrgs: ['unite-la'],
          discount: 0,
          promo: 0,
          subtotal: 5,
          numberOfLicenses: 1,


          numberOfLicenseOptions: [],
          roleNameOptions: ['Student / Career Seeker','Teacher','Work-Based Learning Coordinator','Counselor','Mentor','Employer Representative','Other'],
          serviceOptions: ['Career Exploration','Mentorship','Career Events','Projects','Work'],
          orgTypeOptions: [],
        }

        this.itemClicked = this.itemClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.saveFile = this.saveFile.bind(this)
        this.checkEntry = this.checkEntry.bind(this)
        this.adjustDiscount = this.adjustDiscount.bind(this)
        this.createCustomer = this.createCustomer.bind(this)
        this.cancelSubscription = this.cancelSubscription.bind(this)
        this.subscribe = this.subscribe.bind(this)
        this.logout = this.logout.bind(this)

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)

    }

    componentDidMount() {
        //see if user is logged in
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        // only one kind of subscription
        let priceId = ''
        const testingStripe = this.state.testingStripe
        if (testingStripe) {
          priceId = 'price_1I6ivTBMA9yyOaS5RJ1CztXY'
        } else {
          priceId = 'price_1I8wv4BMA9yyOaS5WGs5LmuT'
        }

        let numberOfLicenseOptions = ['','Just Me','2 People','3 People']
        let orderItems = [{ name: 'Annual License', price: this.state.basePrice, qty: 1 }]
        const orgTypeOptions = ['','Workforce Development Organization','Educational Institution']

        let signInPage = false
        if (window.location.pathname.includes('/signin')) {
          signInPage = true
        }

        let isMobile = false
        if (window.innerWidth <= 768) {
          isMobile = true
        }

        this.setState({
          priceId, testingStripe,
          numberOfLicenseOptions, orderItems, signInPage, orgTypeOptions,
          isMobile
        })

        if (this.props.location && this.props.location.state) {
          //send this logged out user out of the app
          const { selectedItem, sourceLink } = this.props.location.state;
          console.log('we got the item: ', selectedItem, sourceLink)
          this.setState({ selectedItem, sourceLink })
        }

        const email = localStorage.getItem('email')
        // const activeOrg = localStorage.getItem('activeOrg')
        const orgName = localStorage.getItem('orgName')

        if (email && email !== '') {
          console.log('the check')
          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {
            console.log('User profile query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved user details')

               const firstName = response.data.user.firstName
               const lastName = response.data.user.lastName
               const roleName  = response.data.user.roleName
               const orgCode = response.data.user.activeOrg
               const accountCode = response.data.user.accountCode
               const school = response.data.user.school
               const jobTitle = response.data.user.jobTitle
               const employerName = response.data.user.employerName

               this.setState({
                 firstName, lastName, email, orgCode, accountCode, school, jobTitle, employerName, roleName, orgName
               })

               // pull customerId
               let subscriptionType = ''
               if (roleName === 'Student') {
                 subscriptionType = 'Individual'
               } else if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator'){
                 subscriptionType = 'Organization'
               } else {
                 subscriptionType = roleName
               }

               // query for customerId
               Axios.get('/api/payments/subscription', { params: { email, orgCode, accountCode, subscriptionType, isActive: true } })
               .then((response) => {
                 console.log('Subscription query attempted', response.data);

                  if (response.data.success) {
                    console.log('successfully retrieved subscription')

                    const subscription = response.data.subscription
                    const customerId = subscription.customerId

                    this.setState({ errorMessage: null, subscription, customerId })

                  } else {
                    console.log('no active subscriptions data found', response.data.message)
                    this.setState({ errorMessage: response.data.message })
                  }

               }).catch((error) => {
                  console.log('Subscription query did not work', error);

                  let viewIndex = 1
                  let selectedIndex = 0
                  // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                  //   selectedIndex = 0
                  // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                  //   selectedIndex = 0
                  // }
                  this.setState({ errorMessage: null, viewIndex, selectedIndex })
               });

             } else {
               console.log('no profile details found', response.data.message)

             }

          }).catch((error) => {
             console.log('Org query did not work', error);
          });
        }

    }

    formChangeHandler = event => {

      console.log('show data: ', event.target.name, event.target.value)

      if (event.target.name === 'roleName') {
        this.setState({ roleName: event.target.value })
      } else if (event.target.name === 'otherRoleName') {
        this.setState({ otherRoleName: event.target.value })
      } else if (event.target.name === 'firstName') {
        this.setState({ firstName: event.target.value })
      } else if (event.target.name === 'lastName') {
        this.setState({ lastName: event.target.value })
      } else if (event.target.name === 'school') {
        this.setState({ school: event.target.value })
      } else if (event.target.name === 'employerName') {
        this.setState({ employerName: event.target.value })
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value})
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name === 'nameOnCard') {
        this.setState({ nameOnCard: event.target.value })
      } else if (event.target.name === 'cardNumber') {
        this.setState({ cardNumber: event.target.value })
      } else if (event.target.name === 'expirationDate') {
        this.setState({ expirationDate: event.target.value })
      } else if (event.target.name === 'securityCode') {
        this.setState({ securityCode: event.target.value })
      } else if (event.target.name === 'zipcode') {
        this.setState({ zipcode: event.target.value })
      } else if (event.target.name === 'promoCode') {
        this.setState({ promoCode: event.target.value })
      } else if (event.target.name === 'orgType') {
        this.setState({ orgType: event.target.value })
      } else if (event.target.name === 'orgName') {
        this.setState({ orgName: event.target.value })
      } else if (event.target.name === 'orgCode') {
        this.setState({ orgCode: event.target.value })
      } else if (event.target.name === 'orgRegion') {
        this.setState({ orgRegion: event.target.value })
      } else if (event.target.name === 'orgURL') {
        this.setState({ orgURL: event.target.value })
      } else if (event.target.name === 'orgDescription') {
        this.setState({ orgDescription: event.target.value })
      } else if (event.target.name === 'orgLogo') {
        console.log('orgLogoSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ orgLogoImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'orgLogoWhite') {
        console.log('orgLogoWhiteSelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ orgLogoWhiteImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);

            this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name === 'numberOfLicenses') {
        this.setState({ numberOfLicenses: event.target.value })
        this.adjustDiscount(event.target.value)
      } else {
        console.log('there was an error in formChangeHandler')
      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      const orgCode = this.state.activeOrg
      const fileName = passedFile.name
      let originalName = category + '|' + orgCode + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'orgLogo') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoFile: passedFile })
          } else if (category === 'orgLogoWhite') {
            this.setState({ serverPostSuccess, serverSuccessMessage, orgLogoWhiteFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'orgLogo') {
              existingFilePath = self.state.orgLogoPath
            } else if (category === 'orgLogoWhite') {
              existingFilePath = self.state.orgLogoWhite
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'orgLogo') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoPath: newFilePath
                    })
                  } else if (category === 'orgLogoWhite') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', orgLogoWhitePath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessOrgLogo: false,
              serverErrorMessageOrgLogo: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessOrgLogo: false,
            serverErrorMessageOrgLogo: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    logout() {
      console.log('logout called')

      localStorage.removeItem('email')//this.props.auth.email
      localStorage.removeItem('username')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('isAdvisor')
      localStorage.removeItem('unreadNotificationsCount')
      localStorage.removeItem('orgAffiliation')
      localStorage.removeItem('myOrgs')
      localStorage.removeItem('activeOrg')
      localStorage.removeItem('placementPartners')
      localStorage.removeItem('orgName')
      localStorage.removeItem('orgFocus')
      localStorage.removeItem('roleName')
      localStorage.removeItem('studentAlias')
      localStorage.removeItem('workMode')
      localStorage.removeItem('accountCode')

      const viewIndex = 0
      const signInPage = true
      this.setState({ viewIndex, signInPage })
    }

    async nextPage() {
      console.log('nextPage called')

      if (this.state.viewIndex === 0) {

        if (this.state.signInPage) {
          // signin

          this.setState({ isSaving: true })

          const email = this.state.email
          const password = this.state.password
          const orgFocus = this.state.orgFocus

          const returnedValue = await signIn(email, password, orgFocus)
          console.log('show returnedValue: ', returnedValue)

          if (returnedValue && returnedValue.error && returnedValue.error.message) {

            this.setState({ errorMessage: returnedValue.error.message.toString(), isSaving: false })
          } else if (returnedValue.success === false) {
            this.setState({ errorMessage: returnedValue.message, isSaving: false })
          } else {

            const firstName = returnedValue.user.firstName
            const lastName = returnedValue.user.lastName
            let roleName = returnedValue.user.roleName
            if (roleName === 'Student') {
              roleName = 'Student / Career Seeker'
            }

            const school = returnedValue.user.school
            const jobTitle = returnedValue.user.jobTitle
            const employerName = returnedValue.user.employerName

            // const email = this.state.email
            const orgCode = returnedValue.user.activeOrg
            const accountCode = returnedValue.user.accountCode
            let subscriptionType = 'Individual'
            if (roleName !== 'Student / Career Seeker' && roleName !== 'Student') {
              subscriptionType = this.state.subscriptionType
            }

            this.setState({ firstName, lastName, roleName, school, jobTitle, employerName,
              orgCode, accountCode, subscriptionType
            })

            let changeSubscription = false
            if (!changeSubscription) {
              let pathname = '/problem-platform/profile'
              if (this.state.sourceLink) {
                pathname = this.state.sourceLink
              }

              this.props.navigate({ pathname, state: { newUser: false }})

            } else {
              if (orgCode !== 'guidedcompass') {
                Axios.get('/api/org', { params: { orgCode } })
                .then((response) => {
                  console.log('Org query attempted', response.data);

                   if (response.data.success) {
                     console.log('successfully retrieved org')

                     const orgName = response.data.orgInfo.orgName
                     this.setState({ orgName })
                     localStorage.setItem('orgName', orgName)

                   } else {
                     console.log('no org data found', response.data.message)
                   }

                }).catch((error) => {
                   console.log('Org query did not work', error);
                   this.setState({ serverErrorMessage: 'No org found'})
                });
              }

              // query for customerId
              Axios.get('/api/payments/subscription', { params: { email, orgCode, accountCode, subscriptionType, isActive: true } })
              .then((response) => {
                console.log('Subscription query attempted', response.data);

                 if (response.data.success) {
                   console.log('successfully retrieved subscription')

                   const subscription = response.data.subscription
                   const customerId = subscription.customerId

                   let viewIndex = 1
                   let selectedIndex = 0
                   // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                   //   selectedIndex = 0
                   // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                   //   selectedIndex = 0
                   // }

                   this.setState({ viewIndex, selectedIndex, errorMessage: null, subscription, customerId, isSaving: false })

                 } else {
                   console.log('no org data found', response.data.message)

                   let viewIndex = 1
                   let selectedIndex = 0
                   // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                   //   selectedIndex = 0
                   // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                   //   selectedIndex = 0
                   // }
                   this.setState({ viewIndex, selectedIndex, errorMessage: null, isSaving: false })
                 }

              }).catch((error) => {
                 console.log('Org query did not work', error);

                 let viewIndex = 1
                 let selectedIndex = 0
                 // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                 //   selectedIndex = 0
                 // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                 //   selectedIndex = 0
                 // }
                 this.setState({ viewIndex, selectedIndex, errorMessage: null, isSaving: false })

              });
            }
          }

        } else {
          console.log('show employerName: ', this.state.employerName)
          this.setState({ isSaving: true })

          const firstName = this.state.firstName
          const lastName = this.state.lastName
          const email = this.state.email
          const password = this.state.password
          const gradYear = this.state.gradYear
          const jobTitle = this.state.jobTitle
          const employerName = this.state.employerName
          const orgCode = 'guidedcompass'
          let roleName = this.state.roleName
          if (this.state.roleName === 'Student / Career Seeker') {
            roleName = 'Student'
          }
          const otherRoleName = this.state.otherRoleName
          const courseId = this.state.courseId
          const workId = this.state.workId
          const school = this.state.school
          const schoolDistrict = this.state.schoolDistrict
          const accountCode = this.state.accountCode
          const studentBenefits = this.state.studentBenefits
          const teacherBenefits = this.state.teacherBenefits
          const mentorBenefits = this.state.mentorBenefits
          const employerBenefits = this.state.employerBenefits
          const confirmEmail = this.state.confirmEmail

          const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, orgCode, roleName, otherRoleName, courseId, workId, school, schoolDistrict, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, confirmEmail)
          console.log('show returnedValue: ', returnedValue)

          if (returnedValue && returnedValue.error && returnedValue.error.message) {
            this.setState({ errorMessage: returnedValue.error.message.toString(), isSaving: false })
          } else if (returnedValue.success === false) {
            this.setState({ errorMessage: returnedValue.message, isSaving: false })
          } else {

            // const firstName = returnedValue.user.firstName
            // const lastName = returnedValue.user.lastName
            // let roleName = returnedValue.user.roleName
            // if (roleName === 'Student') {
            //   roleName = 'Student / Career Seeker'
            // }
            //
            // const school = returnedValue.user.school
            // const jobTitle = returnedValue.user.jobTitle
            // const employerName = returnedValue.user.employerName
            //
            // // const email = this.state.email
            // const orgCode = returnedValue.user.activeOrg
            // const accountCode = returnedValue.user.accountCode
            // let subscriptionType = 'Individual'
            // if (roleName !== 'Student / Career Seeker' && roleName !== 'Student') {
            //   subscriptionType = this.state.subscriptionType
            // }
            //
            // this.setState({ firstName, lastName, roleName, school, jobTitle, employerName,
            //   orgCode, accountCode, subscriptionType
            // })

            const email = this.state.email
            // const orgCode = returnedValue.user.activeOrg
            const accountCode = this.state.accountCode
            let subscriptionType = 'Individual'
            if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') {
              subscriptionType = this.state.subscriptionType
            }

            this.setState({ orgCode })

            // query for customerId
            Axios.get('/api/payments/subscription', { params: { email, orgCode, accountCode, subscriptionType, isActive: true } })
            .then((response) => {
              console.log('Subscription query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved subscription')

                 const subscription = response.data.subscription
                 const customerId = subscription.customerId
                 let viewIndex = 1
                 let selectedIndex = 0
                 // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                 //   selectedIndex = 0
                 // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                 //   selectedIndex = 0
                 // }
                 // this.setState({ errorMessage: null, viewIndex, selectedIndex })
                 this.setState({ viewIndex, selectedIndex, errorMessage: null, subscription, customerId, isSaving: false })

               } else {
                 console.log('no org data found', response.data.message)

                 let viewIndex = 1
                 let selectedIndex = 0
                 // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
                 //   selectedIndex = 0
                 // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
                 //   selectedIndex = 0
                 // }

                 this.setState({ viewIndex, selectedIndex, errorMessage: null, isSaving: false })
               }

            }).catch((error) => {
               console.log('Org query did not work', error);

               let viewIndex = 1
               let selectedIndex = 0
               // if (roleName === 'Teacher' || roleName === 'Counselor' || roleName === 'School Support' || roleName === 'Work-Based Learning Coordinator') {
               //   selectedIndex = 0
               // } else if (roleName === 'Employer Representative' || roleName === 'Mentor' || roleName === 'Other') {
               //   selectedIndex = 0
               // }

               this.setState({ viewIndex, selectedIndex, errorMessage: null, isSaving: false })

            });
          }
        }
      }
    }

    previousPage() {
      console.log('previousPage called')

      if (this.state.viewIndex === 0) {

      }
    }

    adjustDiscount(numberOfLicenses) {
      console.log('adjustDiscount called', numberOfLicenses)

      if (numberOfLicenses < 50) {
        this.setState({ discount: 0.60 })
      } else if (numberOfLicenses < 100) {
        this.setState({ discount: 0.65 })
      } else if (numberOfLicenses < 500) {
        this.setState({ discount: 0.70 })
      } else if (numberOfLicenses < 1000) {
        this.setState({ discount: 0.75 })
      } else if (numberOfLicenses < 5000) {
        this.setState({ discount: 0.80 })
      } else if (numberOfLicenses < 10000) {
        this.setState({ discount: 0.85 })
      } else if (numberOfLicenses < 100000) {
        this.setState({ discount: 0.90 })
      } else if (numberOfLicenses >= 100000) {
        this.setState({ discount: 0.95 })
      }

    }

    itemClicked(value, type) {
      console.log('itemClicked', value, type)

      if (type === 'roleName') {
        let roleName = value
        let selectedIndex = 0
        let numberOfLicenses = 1
        let discount = 0
        if (roleName === 'Teacher' || roleName === 'Counselor'|| roleName === 'Work-Based Learning Coordinator') {
          // selectedIndex = 0
          numberOfLicenses = 10
          discount = 0.6
        }
        this.setState({ roleName, selectedIndex, numberOfLicenses, discount })
      } else if ('service') {
        let servicesOffered = this.state.servicesOffered
        if (servicesOffered.includes(value)) {
          let removeIndex = servicesOffered.indexOf(value)
          servicesOffered.splice(removeIndex, 1)
          this.setState({ servicesOffered })
        } else {
          servicesOffered.push(value)
          this.setState({ servicesOffered })
        }
      }
    }

    closeModal() {

      this.setState({ modalIsOpen: false });
    }

    checkEntry(type) {
      console.log('checkEntry called', type)

      if (type === 'promoCode') {
        if (this.state.availablePromos.includes(this.state.promoCode)) {
          const promoSuccessMessage = this.state.promoCode + ' successfully redeemed!'
          const promoErrorMessage = null
          this.setState({ promo: 1, promoSuccessMessage, promoErrorMessage })
        } else {
          const promoSuccessMessage = null
          const promoErrorMessage = 'Promo code not found'
          this.setState({ promoSuccessMessage, promoErrorMessage })
        }
      } else if (type === 'orgCode') {
        console.log('trying: ', this.state.availableOrgs, this.state.orgCode)

        this.setState({ orgExists: false, orgSubscriptionExists: false })

        const orgCode = this.state.orgCode
        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved org')

             const orgName = response.data.orgInfo.orgName
             const orgDescription = response.data.orgInfo.orgDescription
             const orgCodeSuccessMessage = this.state.orgCode + ' successfully found!'
             const orgCodeErrorMessage = null

             this.setState({ orgName, orgDescription, orgCodeSuccessMessage, orgCodeErrorMessage, orgExists: true })

             const subscriptionType = 'Organization'

             // see if they have a subscription
             // query for customerId
             Axios.get('/api/payments/subscription', { params: { orgCode, subscriptionType, isActive: true } })
             .then((response) => {
               console.log('Subscription query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved subscription')

                  // const subscription = response.data.subscription
                  this.setState({ orgSubscriptionExists: true })

                } else {
                  console.log('no subscription found', response.data.message)

                }

             }).catch((error) => {
                console.log('Subscription query did not work', error);

             });

           } else {
             console.log('no org data found', response.data.message)

             const orgCodeSuccessMessage = null
             const orgCodeErrorMessage = 'Org code not found'
             this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
           }

        }).catch((error) => {
           console.log('Org query did not work', error);

           const orgCodeSuccessMessage = null
           const orgCodeErrorMessage = 'Org code not found'
           this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        });
        // if (this.state.availableOrgs.includes(this.state.orgCode)) {
        //   console.log('found it')
        //   const orgCodeSuccessMessage = this.state.orgCode + ' successfully found!'
        //   const orgCodeErrorMessage = null
        //   this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        // } else {
        //   const orgCodeSuccessMessage = null
        //   const orgCodeErrorMessage = 'Org code not found'
        //   this.setState({ orgCodeSuccessMessage, orgCodeErrorMessage })
        // }
      }
    }

    createCustomer() {
      console.log('createCustomer called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      let subscriptionType = 'Individual'
      if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') {
        subscriptionType = 'Organization'

        if (!this.state.orgType || this.state.orgType === '') {
          this.setState({ errorMessage: 'Please add a type of the organization', isSaving: false })
        } else if (!this.state.orgName || this.state.orgName === '' || this.state.orgName === 'Guided Compass') {
          this.setState({ errorMessage: 'Please add the name of the organization', isSaving: false})
        } else if (!this.state.orgRegion || this.state.orgRegion === '') {
          this.setState({ errorMessage: 'Please add the region of the organization', isSaving: false})
        } else if (!this.state.orgURL || this.state.orgURL === '') {
          this.setState({ errorMessage: 'Please add the main website of the organization', isSaving: false})
        } else if (!this.state.orgDescription || this.state.orgDescription === '') {
          this.setState({ errorMessage: 'Please add a description of the organization', isSaving: false})
        } else if (!this.state.numberOfLicenses || this.state.numberOfLicenses < 10) {
          this.setState({ errorMessage: 'Please add the number of licenses you would like to purchase for the organization', isSaving: false})
        } else {
          const orgCode = this.state.orgName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()

          const contactFirstName = this.state.firstName
          const contactLastName = this.state.lastName
          const contactEmail = this.state.email
          const contactTitle = this.state.jobTitle
          const accountCode = this.state.accountCode
          const employerName = this.state.employerName

          const orgName = this.state.orgName
          const orgType = this.state.orgType
          let orgFocus = 'Placement'
          if (orgType === 'educationalIntitution') {
            orgFocus = 'School'
          }
          const orgRegion = this.state.orgRegion
          const orgURL = this.state.orgURL
          const servicesOffered = this.state.servicesOffered
          const orgDescription = this.state.orgDescription
          const quantity = this.state.numberOfLicenses
          const webLogoURI = this.state.orgLogoWhite
          const webLogoURIColor = this.state.orgLogoColor

          const createdAt = new Date()
          const updatedAt = new Date()

          // create org
          Axios.post('/api/org/create', {
            contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
            orgCode, orgName, orgType, orgRegion, orgURL, servicesOffered, orgDescription, quantity, orgFocus,
            webLogoURI, webLogoURIColor,
            createdAt, updatedAt
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Org created! ', response.data);

              const email = this.state.email
              const makeAdmin = true

              // associate the user with this org
              Axios.post('/api/users/attach-to-org', {
                email, orgCode, makeAdmin, createdAt, updatedAt
              }).then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Succesfully attached to user account! ', response.data);

                  //save to localStorage
                  localStorage.setItem('activeOrg', orgCode)
                  localStorage.setItem('orgName', orgName)

                  // create a Stripe account
                  Axios.post('/api/payments/create-customer', {
                    contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, accountCode, employerName,
                    orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered, orgDescription, quantity,
                    webLogoURI, webLogoURIColor,
                    createdAt, updatedAt
                  }).then((response) => {

                    if (response.data.success) {
                      //save values
                      console.log('Customer created! ', response.data);

                      const customerId = response.data.customerId
                      this.setState({ customerId, subscriptionType, isSaving: false, successMessage: 'Great! Please add your payment details!' })

                    } else {
                      console.log('Customer not created')
                      this.setState({ errorMessage: 'This organization name has already been taken. Please make another one', isSaving: false})

                    }

                  }).catch((error) => {
                      console.log('Org creation did not work', error);
                      this.setState({ errorMessage: error, isSaving: false})

                  });

                } else {
                  console.log('Could not attach to org')
                  this.setState({ errorMessage: 'Could not attch to org', isSaving: false })

                }

              }).catch((error) => {
                  console.log('Attaching user to org did not work', error);
                  this.setState({ errorMessage: error, isSaving: false})
              });

            } else {
              console.log('Org not created')
              this.setState({ errorMessage: 'There name of this organization is already taken. If this organization is already a Guided Compass partner, communicate with staff to use the existing org code.'})

            }

          }).catch((error) => {
              console.log('Customer creation did not work', error);

          });
        }

      } else if (this.state.roleName === 'Mentor' || this.state.roleName === 'Employer') {
        // do something here later
      } else {
        // subscriptionType is individual

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        const orgCode = this.state.orgCode
        const orgName = this.state.orgName
        const accountCode = this.state.accountCode
        const employerName = this.state.employerName

        const createdAt = new Date()
        const updatedAt = new Date()

        Axios.post('/api/payments/create-customer', {
          firstName, lastName, email, subscriptionType, orgCode, orgName, accountCode, employerName,
          createdAt, updatedAt
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Customer created! ', response.data);

            const customerId = response.data.customerId
            this.setState({ customerId, subscriptionType, isSaving: false, successMessage: 'Great! Please add your payment details!' })

          } else {
            console.log('Customer not created')
            this.setState({ errorMessage: 'There was an error setting up your account'})

          }

        }).catch((error) => {
            console.log('Customer creation did not work', error);

        });

      }
    }

    subscribe(type) {
      console.log('subscribe called', type)

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (type === 'Individual') {
        console.log('in individual')
        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        let orgCode = 'guidedcompass'
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        const jobTitle = this.state.jobTitle
        let subscriptionType = type
        const roleName = this.state.roleName

        if (this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') {
          subscriptionType = 'Organization'
          orgCode = this.state.orgCode
        }
        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode
        console.log('about to post')
        //free subscription
        Axios.post('/api/payments/create-subscription', {
          email, firstName, lastName, roleName, orgCode, orgName, accountCode, employerName, jobTitle,
          subscriptionType, price, quantity, promoCode
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Free subscription created! ', response.data);

            let pathname = '/problem-platform/profile'
            if (this.state.sourceLink) {
              pathname = this.state.sourceLink
            }
            this.props.navigate({ pathname, state: { newUser: true }})

          } else {
            console.log('There was an error with creating an individual subscription')
            this.setState({ errorMessage: 'There was an error creating an individual subscription', isSaving: false})

          }

        }).catch((error) => {
            console.log('Customer creation did not work', error);
            this.setState({ errorMessage: error, isSaving: false})
        });
      } else if (type === 'Organization') {

        // contactFirstName, contactLastName, contactEmail, contactTitle, orgType, orgRegion, orgURL, orgFocus,
        // servicesOffered, orgDescription,

        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName

        const contactEmail = this.state.email
        const contactFirstName = this.state.firstName
        const contactLastName = this.state.lastName
        const contactTitle = this.state.jobTitle
        const orgCode = this.state.orgName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        const orgName = this.state.orgName
        const orgType = this.state.orgType
        const orgRegion = this.state.orgRegion
        const orgURL = this.state.orgURL
        const orgFocus = this.state.orgFocus
        const servicesOffered = this.state.servicesOffered
        const orgDescription = this.state.orgDescription
        const webLogoURI = this.state.orgLogoWhite
        const webLogoURIColor = this.state.orgLogoColor

        const employerName = this.state.employerName
        const roleName = this.state.roleName

        let subscriptionType = type
        const price = 0
        const quantity = this.state.numberOfLicenses

        const createdAt = new Date()
        const updatedAt = new Date()

        // create org
        Axios.post('/api/org/create', {
          firstName, lastName, email, roleName,
          contactFirstName, contactLastName, contactEmail, contactTitle, subscriptionType, employerName,
          orgCode, orgName, orgType, orgRegion, orgURL, servicesOffered, orgDescription, quantity, orgFocus,
          webLogoURI, webLogoURIColor,
          createdAt, updatedAt
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Org created! ', response.data);

            const email = this.state.email
            const makeAdmin = true

            // associate the user with this org
            Axios.post('/api/users/attach-to-org', {
              email, orgCode, makeAdmin, createdAt, updatedAt
            }).then((response) => {

              if (response.data.success) {
                //save values
                console.log('Succesfully attached to user account! ', response.data);

                //save to localStorage
                localStorage.setItem('activeOrg', orgCode)
                localStorage.setItem('orgName', orgName)

                const promoCode = this.state.promoCode

                //free subscription
                Axios.post('/api/payments/create-subscription', {
                  contactEmail, contactFirstName, contactLastName, contactTitle,
                  orgCode, orgName, orgType, orgRegion, orgURL, orgFocus, servicesOffered,
                  roleName, employerName,
                  subscriptionType, price, quantity, promoCode
                }).then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Free subscription created! ', response.data);

                    let pathname = '/problem-platform/profile'
                    if (this.state.sourceLink) {
                      pathname = this.state.sourceLink
                    }
                    this.props.navigate({ pathname, state: { newUser: true }})

                  } else {
                    console.log('There was an error with creating an individual subscription')
                    this.setState({ errorMessage: 'There was an error creating an individual subscription', isSaving: false })

                  }

                }).catch((error) => {
                    console.log('Customer creation did not work', error);
                    this.setState({ errorMessage: error, isSaving: false })
                });

              } else {
                console.log('Could not attach to org')
                this.setState({ errorMessage: 'Could not attch to org', isSaving: false })

              }

            }).catch((error) => {
                console.log('Attaching user to org did not work', error);
                this.setState({ errorMessage: error, isSaving: false })
            });

          } else {
            console.log('Org not created')
            this.setState({ errorMessage: 'There name of this organization is already taken. If this organization is already a Guided Compass partner, communicate with staff to use the existing org code.', isSaving: false })

          }

        }).catch((error) => {
            console.log('Customer creation did not work', error);
            this.setState({ errorMessage: error, isSaving: false })

        });
      } else if (type === 'toOrg') {

        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const roleName = this.state.roleName
        let orgCode = this.state.orgCode
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        let subscriptionType = 'toOrg'
        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode

        // only subscribe if org has a subscription
        if (!orgCode || orgCode === '' || orgCode === 'guidedcompass') {
          this.setState({ errorMessage: 'please add an org code', isSaving: false })
        // } else if (orgCode === 'guidedcompass') {
        //   this.setState({ errorMessage: 'this organization does not have a subscription' })
        } else {
          //free subscription
          Axios.post('/api/payments/create-subscription', {
            email, firstName, lastName, roleName, orgCode, orgName, accountCode, employerName,
            subscriptionType, price, quantity, promoCode
          }).then((response) => {

            if (response.data.success) {
              //save values
              console.log('Free subscription created! ', response.data);

              const createdAt = new Date()
              const updatedAt = new Date()

              // attach to user account and save to localStorage
              // associate the user with this org
              Axios.post('/api/users/attach-to-org', {
                email, orgCode, createdAt, updatedAt
              }).then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Succesfully attached to user account! ', response.data);

                  //save to localStorage
                  localStorage.setItem('activeOrg', orgCode)
                  localStorage.setItem('orgName', orgName)

                  let pathname = '/problem-platform/profile'
                  if (this.state.sourceLink) {
                    pathname = this.state.sourceLink
                  }
                  this.props.navigate({ pathname, state: { newUser: true }})

                } else {
                  console.log('Could not attach to org')
                  this.setState({ errorMessage: 'Could not attch to org', isSaving: false})

                }

              }).catch((error) => {
                  console.log('Attaching user to org did not work', error);
                  this.setState({ errorMessage: error, isSaving: false})
              });

            } else {
              console.log('There was an error: ', response.data.message)
              this.setState({ errorMessage: response.data.message, isSaving: false })

            }

          }).catch((error) => {
              console.log('Customer creation did not work', error);
              this.setState({ errorMessage: error, isSaving: false})

          });
        }

      } else if (type === 'acrossOrgs') {
        // mentors, employers, and other roles
        const email = this.state.email
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        let orgCode = 'guidedcompass'
        const orgName = this.state.orgName
        let accountCode = this.state.accountCode
        const employerName = this.state.employerName
        const jobTitle = this.state.jobTitle
        const roleName = this.state.roleName
        let subscriptionType = roleName

        const price = 0
        const quantity = this.state.numberOfLicenses
        const promoCode = this.state.promoCode

        //free subscription
        Axios.post('/api/payments/create-subscription', {
          email, firstName, lastName, orgCode, orgName, accountCode, employerName, jobTitle, roleName,
          subscriptionType, price, quantity, promoCode
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Free subscription created! ', response.data);

            let pathname = '/problem-platform/profile'
            if (this.state.sourceLink) {
              pathname = this.state.sourceLink
            }
            this.props.navigate({ pathname, state: { newUser: true }})

          } else {
            console.log('There was an error with creating an across orgs subscription')
            this.setState({ errorMessage: 'There was an error creating an across orgs subscription', isSaving: false})

          }

        }).catch((error) => {
            console.log('Customer creation did not work', error);
            this.setState({ errorMessage: error, isSaving: false})
        });
      }
    }

    cancelSubscription() {
      console.log('cancelSubscription called')

      const subscriptionId = 'sub_IifIjlxXHuHBdP'
      // this assumes an individual subscription
      Axios.post('/api/payments/cancel-subscription', { subscriptionId
      }).then((response) => {

        if (response.data.success) {
          //save values
          console.log('Subscription cancelled! ', response.data);


        } else {
          console.log('Subscription not cancelled')

        }

      }).catch((error) => {
          console.log('Subscription was not cancelled', error);

      });
    }

    render() {

      let mainContainer = { transition: 'all 1.2s ease-in', opacity: this.state.opacity, backgroundColor: '#fff', width: '60%', borderRadius: '10px', margin: 'auto', padding: '5%', transform: 'translate(0%, 1%)'}
      if (this.state.isMobile) {
        mainContainer = { transition: 'all 1.2s ease-in', opacity: this.state.opacity, backgroundColor: '#fff', width: '90%', borderRadius: '10px', margin: 'auto', padding: '5%', transform: 'translate(0%, 1%)'}
      }

      return (
        <div className="medium-background bottom-padding-50-percent">
          <header className="front-page-header">
            <div className="spacer"/><div className="spacer"/><div className="spacer"/>

            <div className="relative-column-20 row-30">
            </div>
            <div className="relative-column-60 center-horizontally">
              <ul className="horizontal-list flex-center center-text">
                <li><Link to={'/'}><img src={logoImg} alt="Compass logo" className="image-auto-40" /></Link></li>
              </ul>
              <p className="full-width center-text white-text description-text-2 top-padding">District-approved. Private. Secure.</p>
            </div>
            <div className="relative-column-20 row-30 right-padding-3-percent">

            </div>
            <div className="clear" />

            <div className="spacer"/><div className="spacer"/><div className="spacer"/>

            <div className="medium-shadow" style={mainContainer}>
              <div>

                {(this.state.viewIndex === 0) && (
                  <div>
                    {(this.state.signInPage) ? (
                      <div>
                        <p className="heading-text-2">1) Sign In Guided Compass</p>
                        {(this.state.selectedItem) ? (
                          <div className="row-5">
                            <p className="description-text-2">Sign in to access <label className="half-bold-text">{this.state.selectedItem.name}</label>, other recommended projects, career events, work opportunities and more! Don't have an account? <button type="button" className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: false })}>Join</button></p>
                          </div>
                        ) : (
                          <div className="row-5">
                            <p className="description-text-2">Don't have an account? <button type="button" className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: false })}>Join</button></p>
                          </div>
                        )}

                        <div className="row-10">
                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Email<label className="error-color">*</label></label>
                              <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                            </div>
                            <div className="container-right">
                            <label className="profile-label">Password<label className="error-color">*</label></label>
                            <input className="text-field date-picker" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10 full-width">
                            <p className="login-switch float-right">Forgot your password? <Link to={'/reset-password'}>Reset password</Link></p>
                            <div className="clear" />
                          </div>

                          {(this.state.isSaving) ? (
                            <div>
                              <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                            </div>
                          ) : (
                            <div className="row-10 full-width">
                              <button className="btn btn-primary float-right" disabled={this.state.isSaving} onClick={() => this.nextPage()}>Next</button>
                              <div className="clear" />
                              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5 full-width right-text">{this.state.errorMessage}</p>}
                            </div>
                          )}

                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="heading-text-2">1) Register with Guided Compass</p>
                        {(this.state.selectedItem) ? (
                          <div className="row-5">
                            <p className="description-text-2">Register to access <label className="half-bold-text">{this.state.selectedItem.name}</label>, other recommended projects, career events, work opportunities and more! Already have an account? <button type="button" className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: true })}>Sign In</button></p>
                          </div>
                        ) : (
                          <div className="row-5">
                            <p className="description-text-2">Already have an account? <button type="button" className="background-button cta-color underline-text" onClick={() => this.setState({ signInPage: true })}>Sign In</button></p>
                          </div>
                        )}

                        <div className="row-10">

                          <div className="row-10">
                            <div>
                              <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-45"}>
                                <label className="profile-label">Which best describes you?<label className="error-color">*</label></label>
                              </div>
                              <div className={(window.innerWidth > 768) ? "float-left left-padding" : "fixed-column-45 left-padding"}>
                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <div className="noti-bubble-info-7-9">
                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showServiceDefinitions: false, showPricingChart: false, showRoleDefinitions: true })}>
                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                  </a>
                                </div>
                              </div>
                              <div className="clear" />
                            </div>

                            {this.state.roleNameOptions.map((value, index) =>
                              <div key={value} className="float-left">
                                <div className="float-left row-5 right-padding">
                                  <button className="background-button" onClick={() => this.itemClicked(value,'roleName')}>
                                    {(this.state.roleName === value) ? (
                                      <div className="tag-container-4">
                                        <p className="description-text-2 white-text half-bold-text">{value}</p>
                                      </div>
                                    ) : (
                                      <div className="tag-container-inactive-2">
                                        <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                            <div className="clear" />
                          </div>

                          {(this.state.roleName === 'Other') && (
                            <div className="row-10">
                              <label className="profile-label">You selected "other". Please write your role:<label className="error-color">*</label></label>
                              <input className="text-field" type="text" placeholder="Other Role Name..." name="otherRoleName" value={this.state.otherRoleName} onChange={this.formChangeHandler} />
                            </div>
                          )}

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">First Name<label className="error-color">*</label></label>
                              <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                            </div>
                            <div className="container-right">
                              <label className="profile-label">Last Name<label className="error-color">*</label></label>
                              <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              {(this.state.roleName === 'Student / Career Seeker' || this.state.roleName === 'Student') ? (
                                <div>
                                  <label className="profile-label">School Name<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="School Name" name="school" value={this.state.school} onChange={this.formChangeHandler} />
                                </div>
                              ) : (
                                <div>
                                  <label className="profile-label">Employer Name<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                                </div>
                              )}
                            </div>
                            <div className="container-right">
                              {(this.state.roleName !== 'Student / Career Seeker' && this.state.roleName !== 'Student') && (
                                <div>
                                  <label className="profile-label">Job Title<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler} />
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor') && (
                            <div className="row-10">
                              <div className="container-left">
                                <label className="profile-label">School Name<label className="error-color">*</label></label>
                                <input className="text-field" type="text" placeholder="School Name" name="school" value={this.state.school} onChange={this.formChangeHandler} />
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Email<label className="error-color">*</label></label>
                              <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                            </div>
                            <div className="container-right">
                            <label className="profile-label">Password<label className="error-color">*</label></label>
                            <input className="text-field date-picker" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler} />
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10 full-width">
                            <button className="btn btn-primary float-right" onClick={() => this.nextPage()}>Next</button>
                            <div className="clear" />
                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5 full-width right-text">{this.state.errorMessage}</p>}
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                )}

                {(this.state.viewIndex === 1) && (
                  <div>
                    <div className="row-20">
                      <p className="heading-text-4">Your Account</p>
                      <p className="description-text-2 half-bold-text">{this.state.email} [{this.state.roleName}] <button className="background-button cta-color underline-text" onClick={() => this.logout()}>(change)</button></p>
                    </div>
                    <div className="row-20">
                      <p className="heading-text-4">Select How You Would Like to Subscribe</p>
                      <div className="spacer" />

                      {(!this.state.roleName || this.state.roleName === '') && (
                        <div className="top-padding">
                          <p className="error-color">Please select a role above to view options</p>
                        </div>
                      )}

                      <div className="row-20">
                        <div className="flex-container row-direction">

                          {(this.state.roleName === 'Student / Career Seeker' || this.state.roleName === 'Student') && (
                            <div className="flex-container row-direction">
                              <div className="flex-50">
                                <div className="row-10">
                                  <div className="width-30 height-65" />
                                </div>

                                <div className="row-10">
                                  <p className="description-text-2">Which opportunities can I access?</p>
                                </div>
                                <div className="row-10">
                                  <p className="description-text-2">Monthly price</p>
                                </div>
                              </div>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 0 })}>
                                <div className={(this.state.selectedIndex === 0) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                  <div className="flex-20 horizontal-padding-4">
                                    <div className="row-10">
                                      {(this.state.orgCode && this.state.orgCode !== 'guidedcompass') ? (
                                        <div className="tertiary-background padding-20 white-text">Continue w/ {this.state.orgName}</div>
                                      ) : (
                                        <div className="tertiary-background padding-20 white-text">Join Existing Org</div>
                                      )}
                                    </div>

                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Your Org's Only</p>
                                    </div>
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Free</p>
                                    </div>
                                  </div>
                                </div>
                              </button>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 2 })}>
                                <div className={(this.state.selectedIndex === 2) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                    <div>
                                      <div className="row-10">
                                        <div className="primary-background padding-20 white-text">
                                          Join as Individual
                                        </div>
                                      </div>

                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">All</p>
                                      </div>
                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">{(this.state.basePrice === 0) ? "Free" : "$" + this.state.basePrice + " / month"}</p>
                                      </div>
                                    </div>

                                </div>
                              </button>

                            </div>
                          )}

                          {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor'|| this.state.roleName === 'Work-Based Learning Coordinator') && (
                            <div className="flex-container row-direction">
                              <div className="flex-50">
                                <div className="row-10">
                                  <div className="width-30 height-65" />
                                </div>

                                <div className="row-10">
                                  <p className="description-text-2">Which opportunities can I access?</p>
                                </div>
                                <div className="row-10">
                                  <p className="description-text-2">Monthly price</p>
                                </div>
                              </div>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 0 })}>
                                <div className={(this.state.selectedIndex === 0) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                  <div className="flex-20 horizontal-padding-4">
                                    <div className="row-10">
                                      <div className="tertiary-background padding-20 white-text">
                                        Join Existing Org
                                      </div>
                                    </div>

                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Your Org's Only</p>
                                    </div>
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Free</p>
                                    </div>
                                  </div>
                                </div>
                              </button>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 1 })}>
                                <div className={(this.state.selectedIndex === 1) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                  <div className="row-10">
                                    <div className="secondary-background padding-20 white-text">
                                      Create New Org
                                    </div>
                                  </div>

                                  <div className="row-10">
                                    <p className="description-text-2 full-width center-text">Public + Private</p>
                                  </div>
                                  <div className="row-10">
                                    <p className="description-text-2 full-width center-text">{(this.state.basePrice === 0) ? "Free" + <label className="cta-color">*</label> : "Custom pricing"}</p>
                                  </div>
                                </div>
                              </button>
                            </div>
                          )}

                          {(this.state.roleName === 'Mentor' || this.state.roleName === 'Employer Representative'|| this.state.roleName === 'Other') && (
                            <div className="flex-container row-direction">
                              <div className="flex-50">
                                <div className="row-10">
                                  <div className="width-30 height-65" />
                                </div>

                                {(this.state.roleName === 'Mentor') && (
                                  <div className="row-10">
                                    <p className="description-text-2">Who can I help?</p>
                                  </div>
                                )}

                                {(this.state.roleName === 'Mentor' || this.state.roleName === 'Employer Representative') && (
                                  <div className="row-10">
                                    <p className="description-text-2">Who can view my postings?</p>
                                  </div>
                                )}

                                {(this.state.roleName === 'Employer Representative') && (
                                  <div className="row-10">
                                    <p className="description-text-2">Which applicants can I see?</p>
                                  </div>
                                )}

                                <div className="row-10">
                                  <p className="description-text-2">Monthly price</p>
                                </div>

                                {(this.state.roleName !== 'Other') && (
                                  <div className="row-10">
                                    <p className="description-text-2">Can {(this.state.roleName === 'Employer Representative') ? "we" : "I"} earn money?</p>
                                  </div>
                                )}
                              </div>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 0 })}>
                                <div className={(this.state.selectedIndex === 0) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                  <div className="flex-20 horizontal-padding-4">
                                    <div className="row-10">
                                      <div className="tertiary-background padding-20 white-text">
                                        Join Existing Org
                                      </div>
                                    </div>

                                    {(this.state.roleName === 'Mentor') && (
                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">Your Org Only</p>
                                      </div>
                                    )}

                                    {(this.state.roleName === 'Mentor' || this.state.roleName === 'Employer Representative') && (
                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">Your Org's Only</p>
                                      </div>
                                    )}

                                    {(this.state.roleName === 'Employer Representative') && (
                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">Referred Only</p>
                                      </div>
                                    )}

                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Free</p>
                                    </div>

                                    {(this.state.roleName !== 'Other') && (
                                      <div className="row-10">
                                        <p className="description-text-2 full-width center-text">Yes, via Projects</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </button>

                              <button className="background-button" onClick={() => this.setState({ selectedIndex: 1 })}>
                                <div className={(this.state.selectedIndex === 1) ? "flex-20 horizontal-padding-4 cta-border" : "flex-20 horizontal-padding-4"}>
                                  <div className="row-10">
                                    <div className="secondary-background padding-20 white-text">
                                      Join Across Orgs
                                    </div>
                                  </div>

                                  {(this.state.roleName === 'Mentor') && (
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Across Orgs</p>
                                    </div>
                                  )}

                                  {(this.state.roleName === 'Mentor' || this.state.roleName === 'Employer Representative') && (
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">{(this.state.roleName === 'Employer Representative') ? "My Posts" : "Public + Private"}</p>
                                    </div>
                                  )}

                                  {(this.state.roleName === 'Employer Representative') && (
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Referred Only</p>
                                    </div>
                                  )}

                                  <div className="row-10">
                                    <p className="description-text-2 full-width center-text">Free</p>
                                  </div>

                                  {(this.state.roleName !== 'Other') && (
                                    <div className="row-10">
                                      <p className="description-text-2 full-width center-text">Yes, via Projects</p>
                                    </div>
                                  )}

                                </div>
                              </button>
                            </div>
                          )}

                        </div>
                      </div>

                      {(this.state.selectedIndex === 0) && (
                        <div className="description-text-1">
                          {(this.state.orgCode && this.state.orgCode !== 'guidedcompass') ? (
                            <div>
                              <div className="row-10">
                                <p>You've selected that you would like to continue with <label className="cta-color half-bold-text">{this.state.orgName}</label>. If you would like to switch your active org portal, please enter the org code below.</p>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row-10">
                                <p>You've selected that you would like to join an <label className="cta-color half-bold-text">existing org</label>. Please add the org code below to join for free.</p>
                              </div>
                            </div>
                          )}

                          <div className="row-5">
                            <div className="calc-column-offset-160">
                              <p className="profile-label">Search by Org Code</p>
                              <input className="text-field" type="text" placeholder="Enter Org Code..." name="orgCode" value={(this.state.orgCode === 'guidedcompass') ? "" : this.state.orgCode} onChange={this.formChangeHandler} />
                            </div>
                            <div className="fixed-column-160 left-padding top-padding-40">
                              <button className="btn btn-squarish" onClick={() => this.checkEntry('orgCode')}>Check for Org</button>
                            </div>
                            <div className="clear" />

                            {(this.state.orgCodeSuccessMessage && this.state.orgCodeSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.orgCodeSuccessMessage}</p>}
                            {(this.state.orgCodeErrorMessage && this.state.orgCodeErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.orgCodeErrorMessage}</p>}

                            {(this.state.orgCodeSuccessMessage && this.state.orgName) && (
                              <div>
                                <div className="row-10">
                                  <p>You have entered the org code for <label className="cta-color half-bold-text">{this.state.orgName}</label>! {this.state.orgDescription}</p>
                                </div>

                                {(this.state.orgSubscriptionExists) ? (
                                  <div className="row-10">
                                    <p>This organization has available subscription slots. By clicking below, you will join their organization portal for <label className="cta-color half-bold-text">free</label>.</p>
                                  </div>
                                ) : (
                                  <div className="row-10">
                                    <p>This organization <label className="error-color half-bold-text">does not</label> have any more subscription slots. Please contact them to request slots so that you can join their portal for <label className="cta-color half-bold-text">free</label>.</p>
                                  </div>
                                )}
                              </div>
                            )}

                          </div>

                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}

                          <div className="row-20">
                            <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('toOrg')}>Subscribe as {this.state.roleName}</button>
                          </div>

                        </div>
                      )}

                      {(this.state.selectedIndex === 1) && (
                        <div className="description-text-1">
                          {(this.state.roleName === 'Teacher' || this.state.roleName === 'Work-Based Learning Coordinator' || this.state.roleName === 'Counselor') && (
                            <div>
                              <div className="row-10">
                                <p>You've selected that you would like to add a <label className="cta-color half-bold-text">new org</label>. This allow you to sponsor members in a dedicated portal environment. These members can then join for free using your "org code".</p>

                                <div className="row-10">
                                  <p>The following members would have dedicated portals:</p>
                                  <ul className="left-padding-25">
                                    <li>Students / Career-Seekers</li>
                                    <li>Teachers</li>
                                    <li>Career Counselors / Support Staff</li>
                                    <li>Work-Based Learning Coordinators and Administrators</li>
                                    <li>Mentors and Parents</li>
                                    <li>Employer Representatives (i.e., HR and Hiring Managers)</li>
                                  </ul>
                                </div>

                                <div>
                                  <div className="calc-column-offset-45">
                                    <p className="row-5">Orgs should be either educational institutions or workforce development organizations that offer one or more of the following services. Please select the services you offer:<label className="error-color half-bold-text">*</label></p>
                                  </div>
                                  <div className="fixed-column-45 left-padding">
                                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <div className="noti-bubble-info-7-9">
                                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showServiceDefinitions: true, showPricingChart: false, showRoleDefinitions: false })}>
                                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="clear" />
                                </div>


                                <div>
                                  {this.state.serviceOptions.map((value, index) =>
                                    <div key={value} className="float-left">
                                      <div className="float-left row-5 right-padding">
                                        <button className="background-button" onClick={() => this.itemClicked(value,'service')}>
                                          {(this.state.servicesOffered.includes(value)) ? (
                                            <div className="tag-container-4">
                                              <p className="description-text-2 white-text half-bold-text">{value}</p>
                                            </div>
                                          ) : (
                                            <div className="tag-container-inactive-2">
                                              <p className="description-text-2 cta-color half-bold-text">{value}</p>
                                            </div>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  )}

                                  <div className="clear" />
                                </div>

                              </div>

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Org Type<label className="error-color">*</label></label>
                                  <select name="orgType" className="dropdown" value={this.state.orgType} onChange={this.formChangeHandler}>
                                    {this.state.orgTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Org Name<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Org Name..." name="orgName" value={(this.state.orgName === 'Guided Compass') ? "" : this.state.orgName} onChange={this.formChangeHandler} />
                                </div>
                                <div className="clear" />
                              </div>

                              <div className="row-10">
                                {/*
                                <div className="container-left">
                                  <label className="profile-label">Org Code<label className="error-color">*</label></label>

                                  <input className="text-field" type="text" placeholder="Org Code..." name="orgCode" value={this.state.orgCode} onChange={this.formChangeHandler} />
                                </div>*/}
                                <div className="container-left">
                                  <label className="profile-label">Org Headquarters Region (City)<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="City..." name="orgRegion" value={this.state.orgRegion} onChange={this.formChangeHandler} />
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">Org Main Website<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Website URL..." name="orgURL" value={this.state.orgURL} onChange={this.formChangeHandler} />
                                  {(this.state.orgURL && !this.state.orgURL.startsWith('http')) && (
                                    <div className='row-5'>
                                      <p className="description-text-2 error-color">Please start the website link with "http"</p>
                                    </div>
                                  )}
                                </div>

                                <div className="clear" />
                              </div>

                              <div className="row-10">
                                <label className="profile-label">Description of Org<label className="error-color">*</label></label>
                                <textarea className="text-field" type="text" placeholder="Briefly describe what your organization does..." name="orgDescription" value={this.state.orgDescription} onChange={this.formChangeHandler} />
                              </div>

                              {(this.showOrgLogos) && (
                                <div className="row-10">
                                  <div className="container-left">
                                    <div className="upload-image">

                                      <div className="spacer" />

                                      <div className="row-10">
                                        <p>Add your logo for light backgrounds</p>
                                      </div>

                                      <div className="relative-position">
                                        <label for="orgLogo" className="profile-pic-button">
                                          <img src={ this.state.orgLogoImage ? ( this.state.orgLogoImage )
                                            : this.state.orgLogoPath ? ( this.state.orgLogoPath )
                                            : ( exampleLogoLight)}
                                          alt="Compass add logo" for="orgLogo" className="logo-image-large"/>
                                          <div className="relative-position width-40">
                                            <div className="bottom-right-subcontainer">
                                              <img src={addIcon} alt="Compass add icon" className="image-auto-18 center-item"/>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </label>
                                        <input type="file" id="orgLogo" name="orgLogo" onChange={this.formChangeHandler} accept="image/*" />
                                      </div>

                                      <div className="clear" />
                                      <div className="spacer" />
                                      <p className="description-text-color">Preferred Orientation: Square or Landscape</p>
                                      <div className="spacer" />

                                      { (this.state.serverPostSuccess) ? (
                                        <p className="success-message">{this.state.serverSuccessMessage}</p>
                                      ) : (
                                        <p className="error-message">{this.state.serverErrorMessage}</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="container-right dark-background white-text horizontal-padding-5">
                                    <div className="upload-image">

                                      <div className="row-10">
                                        <p>Add your logo for dark backgrounds</p>
                                      </div>

                                      <div className="relative-position">
                                        <label for="orgLogoWhite" className="profile-pic-button">
                                          <img src={ this.state.orgLogoWhiteImage ? ( this.state.orgLogoWhiteImage )
                                            : this.state.orgLogoWhitePath ? ( this.state.orgLogoWhitePath )
                                            : ( exampleLogoDark)}
                                          alt="Compass add logo" for="orgLogoWhite" className="logo-image-large circle-border-white" />
                                          <div className="relative-position width-40">
                                            <div className="bottom-right-subcontainer">
                                              <img src={addIcon} alt="Compass add icon" className="image-auto-18 center-item"/>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </label>
                                        <input type="file" id="orgLogoWhite" name="orgLogoWhite" onChange={this.formChangeHandler} accept="image/*" />

                                      </div>

                                      <div className="clear" />
                                      <div className="spacer" />
                                      <p>Preferred Dimensions: 200 x 200</p>
                                      <div className="spacer" />

                                      { (this.state.serverPostSuccess) ? (
                                        <p className="success-message">{this.state.serverSuccessMessage}</p>
                                      ) : (
                                        <p className="error-message">{this.state.serverErrorMessage}</p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="clear" />
                                </div>
                              )}

                              <div className="row-10">
                                <div className="container-left">
                                  <label className="profile-label">Number of Member Licenses <button type="button" className="background-button display-inline underline-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showServiceDefinitions: false, showPricingChart: true, showRoleDefinitions: false })}>(Click Here for Pricing)</button> <label className="error-color">*</label></label>
                                  <input className="text-field number-field" type="number" placeholder="0" min="10" name="numberOfLicenses" value={this.state.numberOfLicenses} onChange={this.formChangeHandler} />
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          {(this.state.roleName === 'Mentor') && (
                            <div>
                              <div className="row-10">
                                <div className="row-5">
                                  <p>You've selected that you would like to <label className="cta-color half-bold-text">join across orgs</label>. This allows you to:</p>
                                </div>

                                <ul className="left-padding-30">
                                  <li>RSVP to and Host Career Events (e.g., talks, workshops, webinars)</li>
                                  <li>Crowdsource Solutions to Work Problems</li>
                                  <li>Match to Students / Career-Seekers for Mentorship</li>
                                  <li>Endorse and Provide Feeedback to Students / Career-Seekers</li>
                                </ul>
                              </div>

                              <div className="row-10">
                                <button className="btn btn-primary" onClick={() => this.setState({ preApproved: true})}>Register as {this.state.roleName}</button>
                              </div>
                            </div>
                          )}

                          {(this.state.roleName === 'Employer Representative') && (
                            <div>
                              <div className="row-10">
                                <div className="row-5">
                                  <p>You've selected that you would like to <label className="cta-color half-bold-text">join across orgs</label>. This allows you to:</p>
                                </div>

                                <ul className="left-padding-30">
                                  <li>RSVP to and Host Career Events</li>
                                  <li>Crowdsource Solutions</li>
                                  <li>Hire Screened Talent</li>
                                  <li>Build Custom Talent Pipelines</li>
                                </ul>
                              </div>

                              <div className="row-10">
                                <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('acrossOrgs') }>Register as {this.state.roleName}</button>
                              </div>
                            </div>
                          )}

                          {(this.state.roleName === 'Other') && (
                            <div>
                              <div className="row-10">
                                <div className="row-5">
                                  <p>You've selected that you would like to <label className="cta-color half-bold-text">join across orgs</label>.</p>
                                </div>
                              </div>

                              <div className="row-10">
                                <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('acrossOrgs')}>Register as {this.state.roleName}</button>
                              </div>
                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.roleName && this.state.roleName.includes('Student') && this.state.selectedIndex === 2) && (
                        <div className="description-text-1">
                          <div className="row-10">
                            <p>You've selected an <label className="cta-color half-bold-text">individual basic plan</label>, which gives you access to all public work-based learning opportunities as well as the ability to work with our workforce development partners. Think of this as an aggregator service, like ClassPass, for career event, project, and work opportunities.</p>
                          </div>

                          <div className="row-10">
                            <p>More specifically, your subscription allows you to:</p>
                            <div className="half-spacer" />

                            <ul className="left-padding-25">
                              <li>RSVP to career events (e.g., mock interviews, webinars, career fairs)</li>
                              <li>Submit project solutions to case studies and challenges</li>
                              <li>Apply to and get referred for internship and work opportunities</li>
                              <li>Work with our partner workforce development organizations, if eligible</li>
                            </ul>
                          </div>

                          <div className="row-10">
                            <p>You can can cancel anytime. No strings attached!</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {(this.state.roleName && this.state.roleName !== '' && this.state.roleName !== 'Mentor' && this.state.roleName !== 'Employer Representative' && this.state.roleName !== 'Other' && this.state.selectedIndex !== 0) && (
                      <div className="row-20">
                        <p className="heading-text-4">3) Payment Info</p>
                        <div className="spacer" />
                        {/*
                        <div className="row-10">
                          <label className="profile-label">Number of Users / Licenses</label>
                          <select name="numberOfLicenses" className="dropdown" value={this.state.numberofLicenses} onChange={this.formChangeHandler}>
                            {this.state.numberOfLicenseOptions.map(value => <option key={value} value={value}>{value.value}</option>)}
                          </select>
                        </div>

                        <div className="row-10">
                          <label className="profile-label">Will these be affiliated with an Educational Institution or Workforce Development Organization?</label>
                          <select name="numberOfLicenses" className="dropdown" value={this.state.numberofLicenses} onChange={this.formChangeHandler}>
                            {this.state.numberOfLicenseOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                          </select>
                        </div>*/}

                        <div>
                          <p>Order Summary</p>

                          <div className="row-10 description-text-2">
                            <div className="width-270 half-bold-text row-5">
                              <div className="relative-column-55">
                                <p>Service</p>
                              </div>
                              <div className="relative-column-15">
                                <p>Price</p>
                              </div>
                              <div className="relative-column-15">
                                <p>Qty</p>
                              </div>
                              <div className="relative-column-15">
                                <p>Total</p>
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="width-270">
                              <div className="relative-column-55">
                                <p>{(this.state.roleName === 'Student / Career Seeker' || this.state.roleName === 'Student') ? "Annual License" : "Member Licenses"}</p>
                              </div>
                              <div className="relative-column-15">
                                <p>${this.state.basePrice}</p>
                              </div>
                              <div className="relative-column-15">
                                <p>{this.state.numberOfLicenses}</p>
                              </div>
                              <div className="relative-column-15">
                                <p>${this.state.basePrice * this.state.numberOfLicenses}</p>
                              </div>
                              <div className="clear" />
                            </div>

                            <div className="width-270">

                              {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor'|| this.state.roleName === 'Work-Based Learning Coordinator') && (
                                <div>
                                  <div className="relative-column-55">
                                    <p>Volume Discounts</p>
                                  </div>
                                  <div className="relative-column-15">
                                    <p>{this.state.discount * 100}%</p>
                                  </div>

                                  <div className="relative-column-15 height-20" />

                                  <div className="relative-column-15">
                                    <p className="error-color">(${(this.state.discount * this.state.numberOfLicenses * this.state.basePrice).toFixed()})</p>
                                  </div>
                                  <div className="clear"/>
                                </div>
                              )}

                              <div>
                                <div className="relative-column-55">
                                  <p>Promo</p>
                                </div>
                                <div className="relative-column-15">
                                  <p>{this.state.promo * 100}%</p>
                                </div>

                                <div className="relative-column-15 height-20" />

                                <div className="relative-column-15">
                                  <p className="error-color">(${(this.state.promo * this.state.numberOfLicenses * this.state.basePrice).toFixed()})</p>
                                </div>
                                <div className="clear"/>
                              </div>

                              <div className="spacer"/>

                              <hr />
                              <div className="half-spacer" />

                              <div>
                                <div className="relative-column-55">
                                  <p>Total</p>
                                </div>
                                <div className="relative-column-15 height-30" />

                                <div className="relative-column-15 height-30" />

                                <div className="relative-column-15 half-bold-text cta-color">
                                  <p>${((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promo * this.state.numberOfLicenses * this.state.basePrice) < 0 ? 0 : ((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promo * this.state.numberOfLicenses * this.state.basePrice)).toFixed())}</p>
                                </div>
                              </div>

                              <div className="clear"/>
                            </div>


                          </div>
                        </div>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Promo Code<label className="error-color">*</label></label>
                            <input className="text-field" type="text" placeholder="Promo Code" name="promoCode" value={this.state.promoCode} onChange={this.formChangeHandler} />
                            {(this.state.promoSuccessMessage && this.state.promoSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.promoSuccessMessage}</p>}
                            {(this.state.promoErrorMessage && this.state.promoErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.promoErrorMessage}</p>}

                          </div>
                          <div className="container-right top-padding-40">
                            <button className="btn btn-squarish" onClick={() => this.checkEntry('promoCode')}>Redeem</button>
                          </div>
                          <div className="clear" />
                        </div>

                        {((this.state.numberOfLicenses * this.state.basePrice) - (this.state.discount * this.state.numberOfLicenses * this.state.basePrice) - (this.state.promo * this.state.numberOfLicenses * this.state.basePrice) > 0) ? (
                          <div>
                            <div>

                              {(this.state.customerId) ? (
                                <div className="row-10">
                                  <Elements stripe={(this.state.testingStripe) ? stripeTestPromise : stripeLivePromise}>
                                    <CheckoutForm history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                                      lastName={this.state.lastName} orgName={this.state.orgName} employerName={this.state.employerName}
                                      subscriptionType={this.state.subscriptionType} customerId={this.state.customerId} price={this.state.basePrice}
                                      quantity={this.state.numberOfLicenses} sourceLink={this.state.sourceLink} priceId={this.state.priceId}
                                      />
                                  </Elements>
                                </div>
                              ) : (
                                <div className="row-10">
                                  {(this.state.email) ? (
                                    <div>
                                      <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>

                                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                                    </div>
                                  ) : (
                                    <div>
                                      <button className="btn btn-squarish medium-background standard-border" disabled={true} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/*<button className="btn primary-button" onClick={() => this.cancelSubscription()}>Cancel Subscription</button>*/}

                            </div>
                          {/*
                            {(this.state.testingStripe) ? (
                              <div>

                                {(this.state.customerId) ? (
                                  <div className="row-10">
                                    <Elements stripe={stripeTestPromise}>
                                      <CheckoutForm history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                                        lastName={this.state.lastName} orgName={this.state.orgName} employerName={this.state.employerName}
                                        subscriptionType={this.state.subscriptionType} customerId={this.state.customerId} price={this.state.basePrice}
                                        quantity={this.state.numberOfLicenses}
                                        />
                                    </Elements>
                                  </div>
                                ) : (
                                  <div className="row-10">
                                    <button className="btn btn-squarish" onClick={() => this.createCustomer()}>Sign Up & Enter Payment Details</button>

                                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                                  </div>
                                )}

                              </div>
                            ) : (
                              <div>
                                <div className="row-10">
                                  <label className="profile-label">Name on Card<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Name on Card" name="nameOnCard" value={this.state.nameOnCard} onChange={this.formChangeHandler} />
                                </div>
                                <div className="row-10">
                                  <label className="profile-label">Card Number<label className="error-color">*</label></label>
                                  <input className="text-field" type="text" placeholder="Card Number" name="cardNumber" value={this.state.cardNumber} onChange={this.formChangeHandler} />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">Expiration Date<label className="error-color">*</label></label>
                                    <input className="text-field" type="text" placeholder="Expiration Date" name="expirationDate" value={this.state.expirationDate} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">Security Code<label className="error-color">*</label></label>
                                    <input className="text-field" type="text" placeholder="CVV" name="securityCode" value={this.state.securityCode} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">Zip Code<label className="error-color">*</label></label>
                                    <input className="text-field number-field" type="number" placeholder="zip code..." name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler} />
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <button className="btn btn-primary" onClick={() => this.setState({ preApproved: true})}>Subscribe & Participate in Challenge</button>
                                </div>
                              </div>
                            )}*/}

                          </div>
                        ) : (
                          <div>
                            <div className="row-10">
                              {(this.state.roleName === 'Teacher' || this.state.roleName === 'Counselor'|| this.state.roleName === 'Work-Based Learning Coordinator') ? (
                                <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('Organization')}>Subscribe & Participate in Challenge</button>
                              ) : (
                                <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.subscribe('Individual')}>Subscribe & Participate in Challenge</button>
                              )}
                            </div>
                          </div>
                        )}

                        {/*
                        <div className="bottom-padding">
                          <p>Agree to receive promotional materials and "Subscribe Agreement"</p>
                        </div>*/}


                      </div>
                    )}
                  </div>
                )}

                {(this.state.viewIndex === 2) && (
                  <div>
                  </div>
                )}

              </div>
            </div>
          </header>

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
          >

          {(this.state.showServiceDefinitions) && (
            <div key="showServiceDefinitions" className="full-width padding-20">
              <p className="heading-text-2">Service Definitions</p>
              <div className="spacer" />

              <div className="row-10">
                <p>1) <label className="half-bold-text cta-color">Career Exploration</label> refers any activity / operations where you help students or career-seekers explore career and get explore to career paths.</p>
              </div>

              <div className="row-10">
                <p>2) <label className="half-bold-text cta-color">Mentorship</label> refers any operations where you match students / career-seekers to career mentors and help facilitate that relationship.</p>
              </div>

              <div className="row-10">
                <p>3) <label className="half-bold-text cta-color">Career Events</label> refers any operations where you help plan events. Example events include talks, workshops, webinars, field trips, job shadows, career fairs, and conferences.</p>
              </div>

              <div className="row-10">
                <p>4) <label className="half-bold-text cta-color">Projects</label> refers any operations where you source project opportunities for students / career-seekers to work on, and where you provide grades / feedback on submitted project solutions. Projects can be either paid or unpaid opportunities.</p>
              </div>

              <div className="row-10">
                <p>5) <label className="half-bold-text cta-color">Work</label> refers to any operations were you help place students / career-seekers into internship, apprenticeships, part-time work, or full-time work</p>
              </div>

              <div className="spacer" />

              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
            </div>
          )}

          {(this.state.showPricingChart) && (
            <div key="showPricingChart" className="full-width padding-20">
              <p className="heading-text-2">Pricing Chart</p>
              <div className="spacer" />

              <div className="row-10">
                <img src={priceChart} alt="GC" className="image-full-auto"/>
              </div>

              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>

            </div>
          )}

          {(this.state.showRoleDefinitions) && (
            <div key="showRoleDefinitions" className="full-width padding-20">
              <p className="heading-text-2">Role Definitions</p>
              <div className="spacer" />

              <div className="row-10">
                <p>1) <label className="half-bold-text cta-color">Student / Career Seeker</label> refers to people in school to learn career-related skills, people seeking to start their careers, or people seeking to switch their careers.</p>
              </div>

              <div className="row-10">
                <p>2) <label className="half-bold-text cta-color">Teacher</label> refers to a teachers, instructors, professors, and trainers that teach career-releated skills.</p>
              </div>

              <div className="row-10">
                <p>3) <label className="half-bold-text cta-color">Work-Based Learning Coordinator</label> refers to coordinators and administrators of work-based learning programs. These people act as intermediaries between career-seekers and employers / employment.</p>
              </div>

              <div className="row-10">
                <p>4) <label className="half-bold-text cta-color">Counselor</label> refers to people who coach or counsel career-seekers on career paths.</p>
              </div>

              <div className="row-10">
                <p>5) <label className="half-bold-text cta-color">Mentor</label> refers to employer representatives that would like to be more active in helping career-seekers, including hosting events, providing project feedback, and conducting mock interviews.</p>
              </div>

              <div className="row-10">
                <p>6) <label className="half-bold-text cta-color">Employer Representative</label> refers to HR or hiring managers interested in hands-off activities that directly benefit the employer (e.g., hiring incredible talent for project or full-time work).</p>
              </div>

              <div className="row-10">
                <p>7) <label className="half-bold-text cta-color">Other</label> refers to anyone that does not fit neatly into the aforementioned categories.</p>
              </div>

              <div className="spacer" />

              <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
            </div>
          )}

         </Modal>
        </div>
      )
    }
}

export default withRouter(ProblemPlatformSignUpPage);
