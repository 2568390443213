import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const appliedIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/applied-icon-blue.png';
const rsvpIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/rsvp-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const eventIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-blue.png';
const assignmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png';
const problemIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-blue.png';
const challengeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-blue.png';
// const internIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon-blue.png';
const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
// const checkmarkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
// const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class RenderOpportunities extends Component {
    constructor(props) {
        super(props)

        this.state = {
          favorites: [],
          newFavorites: [],
          showSubFunction: true
        }

        this.favoriteItem = this.favoriteItem.bind(this)
        this.renderOpportunities = this.renderOpportunities.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderOpportunities', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.oppType !== prevProps.oppType) {
        this.retrieveData()
      } else if (this.props.postings !== prevProps.postings) {
        this.retrieveData()
      } else if (this.props.upcomingEvents !== prevProps.upcomingEvents || this.props.pastEvents !== prevProps.pastEvents) {
        this.retrieveData()
      } else if (this.props.rsvps !== prevProps.rsvps || this.props.applications !== prevProps.applications || this.props.favorites !== prevProps.favorites) {
        this.retrieveData()
      } else if (this.props.sortCriteriaArray !== prevProps.sortCriteriaArray || this.props.filterCriteriaArray !== prevProps.filterCriteriaArray) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in RenderOpportunities')

      // emailId, roleName, activeOrg
      let emailId = localStorage.getItem('email');
      // let username = localStorage.getItem('username');
      // let activeOrg = localStorage.getItem('activeOrg');
      let roleName = localStorage.getItem('roleName');

      // let type = this.props.oppType
      let upcomingEvents = []
      if (this.props.upcomingEvents) {
        upcomingEvents = this.props.upcomingEvents
      }
      let pastEvents = []
      if (this.props.pastEvents) {
        pastEvents = this.props.pastEvents
      }

      let postings = this.props.postings

      if (!postings) {
        postings = []
      }

      const rsvps = this.props.rsvps
      const applications = this.props.applications
      const favorites = this.props.favorites
      const newFavorites = this.props.newFavorites
      const sortCriteriaArray = this.props.sortCriteriaArray
      const filterCriteriaArray = this.props.filterCriteriaArray

      const activeOrg = this.props.activeOrg

      let subpath = ''
      if (window.location.pathname && window.location.pathname.split('/')) {
        subpath = window.location.pathname.split('/')[1]
      }

      this.setState({ upcomingEvents, pastEvents, postings, rsvps, applications,
        favorites, newFavorites, sortCriteriaArray, filterCriteriaArray,
        emailId, roleName, activeOrg, subpath
      })
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type)

      this.setState({ errorMessage: null, successMessage: null })

      if (window.location.pathname.includes('/problem-platform') && !this.state.emailId && !this.state.roleName && !this.state.activeOrg) {
        this.props.navigate('/problem-platform/' + item._id + '/checkout', { state: { selectedItem: item, sourceLink: window.location.pathname }})
      } else if (this.props.pageSource === 'landingPage') {
        this.props.navigate('/organizations/' + item.orgCode + '/student/signin', { state: { selectedItem: item._id, sourceLink: window.location.pathname }})
      } else {

        if (this.state.showSubFunction) {

          this.setState({ isSaving: true, errorMessage: null, successMessage: null })

          type = type.toLowerCase()

          const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,type)

          if (returnedObject.success) {
            this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
          } else {
            this.setState({ errorMessage: returnedObject.message, isSaving: false })
          }

        } else {

          let itemId = item._id

          let favoritesArray = this.state.favorites

          if (favoritesArray.includes(itemId)){

            let index = favoritesArray.indexOf(itemId)
            if (index > -1) {
              favoritesArray.splice(index, 1);
            }

            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save favorites')
              if (response.data.success) {
                console.log('saved successfully', response.data)
                //clear values
                this.setState({ successMessage: 'Favorite saved!'
                })
              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites' })
            });

          } else {

            console.log('adding item: ', favoritesArray, itemId)
            favoritesArray.push(itemId)
            Axios.post('/api/favorites/save', {
              favoritesArray, emailId: this.state.emailId
            })
            .then((response) => {
              console.log('attempting to save favorites')
              if (response.data.success) {
                console.log('saved successfully', response.data)
                //clear values
                this.setState({ successMessage: 'Favorite saved!' })
              } else {
                console.log('did not save successfully')
                this.setState({ errorMessage: 'error:' + response.data.message })
              }
            }).catch((error) => {
                console.log('save did not work', error);
                this.setState({ errorMessage: 'there was an error saving favorites' })
            });
          }

          console.log('favorites', favoritesArray)
          this.setState({ favorites: favoritesArray })
        }
      }
    }

    renderOpportunities(type) {
      console.log('renderOpportunities called', type);

      let rows = [];
      const subpath = this.state.subpath
      let tempVarForPostings = 'opportunities'

      if (type === 'events') {
        // console.log('show events: ', this.state.events.length, this.state.filteredUpcomingEvents.length)
        //renderUpcomingEvents
        rows.push(
          <div key="upcoming" className="bottom-padding-30">
            <p className="heading-text-3 standard-color">Upcoming/Ongoing Events</p>
          </div>
        )

        const filteredUpcomingEvents = this.state.upcomingEvents
        for (let i = 1; i <= filteredUpcomingEvents.length; i++) {
          console.log(i);

          const index = i - 1

          let pathname = '/' + subpath + '/opportunities/' + filteredUpcomingEvents[index]._id
          if (window.location.pathname.includes('/problem-platform')) {
            pathname = '/problem-platform/' + filteredUpcomingEvents[index]._id
          } else {
            if (this.props.pageSource === 'landingPage') {
              console.log('a target role? ', this.props.targetRole)
              if (this.props.targetRole) {
                pathname = '/opportunities/organizations/' + filteredUpcomingEvents[index].orgCode + '/' + filteredUpcomingEvents[index]._id + '/roles/' + this.props.targetRole
              } else {
                pathname = '/opportunities/organizations/' + filteredUpcomingEvents[index].orgCode + '/' + filteredUpcomingEvents[index]._id
              }
            }
          }

          rows.push(
            <div key={"upcoming|" +i}>
              <div className="spacer" />
              <Link to={pathname} state={{ selectedOpportunity: filteredUpcomingEvents[index], source: 'Student' }} className="background-link">
                <div className="fixed-column-70">
                  {(filteredUpcomingEvents[index].matchScore) ? (
                    <div className="padding-10">
                      <CircularProgressbar
                        percentage={filteredUpcomingEvents[index].matchScore}
                        text={`${filteredUpcomingEvents[index].matchScore}%`}
                        styles={{
                          path: { stroke: `rgba(31, 130, 240, ${filteredUpcomingEvents[index].matchScore / 100})` },
                          text: { fill: '#1F82CC', fontSize: '26px' },
                          trail: { stroke: 'transparent' }
                        }}
                      />
                    </div>
                  ) : (
                    <img src={(filteredUpcomingEvents[index].imageURL) ? filteredUpcomingEvents[index].imageURL : eventIconBlue} alt="GC" className="image-50-fit top-margin-5 center-item"/>
                  )}
                  {(filteredUpcomingEvents[index].createdAt) && (
                    <div className="top-padding horizontal-padding-7">
                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(filteredUpcomingEvents[index].createdAt,"daysAgo")}</p>
                    </div>
                  )}

                </div>
                <div className="calc-column-offset-150">
                  <p className="heading-text-5">{filteredUpcomingEvents[i - 1].title}</p>
                  <p className="description-text-1 standard-color">{filteredUpcomingEvents[i - 1].orgName}</p>
                  <p className="description-text-2 standard-color">{convertDateToString(new Date(filteredUpcomingEvents[i - 1].startDate),"datetime-2")} - {convertDateToString(new Date(filteredUpcomingEvents[i - 1].endDate),"datetime-2")}</p>
                  {(this.props.pageSource === 'landingPage') && (
                    <div className="row-5">
                      <p className="description-text-2">Hosted by <label className="cta-color bold-text">{filteredUpcomingEvents[i - 1].orgName}</label></p>
                    </div>
                  )}
                </div>
              </Link>
              <div className="float-left left-padding">
                <span className="float-right">
                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                  <Link to={'/' + subpath + '/opportunities/' + filteredUpcomingEvents[index]._id} state={{ selectedOpportunity: filteredUpcomingEvents[index] }} className="background-link">
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                  </Link>
                </span>
                {(window.location.pathname.includes('/app') || window.location.pathname.includes('/advisor')) && (
                  <span className="float-right right-padding-15">
                    {(this.state.rsvps && this.state.rsvps.some(rsvp => rsvp.postingId === filteredUpcomingEvents[index]._id)) && (
                      <div className="top-margin">
                        <img src={rsvpIconBlue} alt="Compass arrow indicator icon" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already registered!"/>
                      </div>
                    )}
                    <button className="btn background-button top-margin-20" onClick={() => this.favoriteItem(filteredUpcomingEvents[i - 1],'event') }>
                      <img src={(this.state.favorites.includes(filteredUpcomingEvents[index]._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                    </button>
                  </span>
                )}
              </div>
              <div className="clear"/>
              {(filteredUpcomingEvents[i - 1].sortCriteria || this.state.sortCriteriaArray) && (
                <div className="left-padding-70">
                  {(this.state.sortCriteriaArray.length > 0) && (
                    <div>
                      <div className="half-spacer" />
                      <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
                    </div>
                  )}
                </div>
              )}
              {(filteredUpcomingEvents[i - 1].filterCriteria || this.state.filterCriteriaArray) && (
                <div className="left-padding-70">
                  <div className="half-spacer" />
                  <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                </div>
              )}
              <div className="spacer" /><div className="spacer" />
              <hr />
              <div className="clear"/>
              <div className="spacer" />
            </div>
          )
        }

        //renderPastEvents
        rows.push(
          <div key="past" className="row-30">
            <p className="heading-text-3 standard-color">Past Events</p>
          </div>
        )

        const filteredPastEvents = this.state.pastEvents
        for (let i = 1; i <= filteredPastEvents.length; i++) {
          console.log(i);

          const index = i - 1

          let pathname = '/' + subpath + '/opportunities/' + filteredPastEvents[index]._id
          if (window.location.pathname.includes('/problem-platform')) {
            pathname = '/problem-platform/' + filteredPastEvents[index]._id
          } else {
            if (this.props.pageSource === 'landingPage') {
              if (this.props.targetRole) {
                pathname = '/opportunities/organizations/' + filteredPastEvents[index].orgCode + '/' + filteredPastEvents[index]._id + '/roles/' + this.props.targetRole
              } else {
                pathname = '/opportunities/organizations/' + filteredPastEvents[index].orgCode + '/' + filteredPastEvents[index]._id
              }
            }
          }

          rows.push(
            <div key={"past|" +i}>
              <div className="spacer" />
              <Link to={pathname} state={{ selectedOpportunity: filteredPastEvents[index], source: 'Student' }} className="background-link">
                <div className="fixed-column-70">
                  {(filteredPastEvents[index].matchScore) ? (
                    <div className="padding-10">
                      <CircularProgressbar
                        percentage={filteredPastEvents[index].matchScore}
                        text={`${filteredPastEvents[index].matchScore}%`}
                        styles={{
                          path: { stroke: `rgba(31, 130, 240, ${filteredPastEvents[index].matchScore / 100})` },
                          text: { fill: '#1F82CC', fontSize: '26px' },
                          trail: { stroke: 'transparent' }
                        }}
                      />
                    </div>
                  ) : (
                    <img src={(filteredPastEvents[index].imageURL) ? filteredPastEvents[index].imageURL : eventIconBlue} alt="GC" className="image-50-fit center-item" />
                  )}
                  {(filteredPastEvents[index].createdAt) && (
                    <div className="top-padding horizontal-padding-7">
                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(filteredPastEvents[index].createdAt,"daysAgo")}</p>
                    </div>
                  )}
                </div>
                <div className="calc-column-offset-150">
                <p className="heading-text-5">{filteredPastEvents[i - 1].title}</p>
                <p className="description-text-1 standard-color top-padding-3">{filteredPastEvents[i - 1].orgName}</p>
                <p className="description-text-2 standard-color top-padding-3">{convertDateToString(new Date(filteredPastEvents[i - 1].startDate),"datetime-2")} - {convertDateToString(new Date(filteredPastEvents[i - 1].endDate),"datetime-2")}</p>
                {(this.props.pageSource === 'landingPage') && (
                  <div className="row-5">
                    <p className="description-text-2">Hosted by <label className="cta-color bold-text">{filteredPastEvents[i - 1].orgName}</label></p>
                  </div>
                )}
                </div>
              </Link>
              <div className="float-left left-padding">
                <span className="float-right">
                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                  <Link to={'/' + subpath + '/opportunities/' + filteredPastEvents[index]._id} state={{ selectedOpportunity: filteredPastEvents[index] }} className="background-link">
                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                  </Link>
                </span>
                {(window.location.pathname.includes('/app') || window.location.pathname.includes('/advisor')) && (
                  <span className="float-right right-padding-15">
                    {(this.state.rsvps && this.state.rsvps.some(app => app.postingId === filteredPastEvents[index]._id)) && (
                      <div className="top-margin">
                        <img src={rsvpIconBlue} alt="Compass arrow indicator icon" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already registered!"/>
                      </div>
                    )}
                    <button className="btn background-button top-margin-20" onClick={() => this.favoriteItem(filteredPastEvents[i - 1],'event') }>
                      <img src={(this.state.favorites.includes(filteredPastEvents[index]._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                    </button>
                  </span>
                )}
              </div>
              <div className="clear"/>
              {(filteredPastEvents[i - 1].sortCriteria || this.state.sortCriteriaArray) && (
                <div className="left-padding-70">
                  {(this.state.sortCriteriaArray.length > 0) && (
                    <div>
                      <div className="half-spacer" />
                      <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
                    </div>
                  )}
                </div>
              )}
              {(filteredPastEvents[i - 1].filterCriteria || this.state.filterCriteriaArray) && (
                <div className="left-padding-70">
                  <div className="half-spacer" />
                  <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                </div>
              )}
              <div className="spacer" /><div className="spacer" />
              <hr />
              <div className="clear"/>
              <div className="spacer" />
            </div>
          )
        }
      } else {
        let filteredPostings = []
        filteredPostings = this.state.postings

        for (let i = 1; i <= filteredPostings.length; i++) {
          console.log(i);

          // const index = i - 1
          const posting = filteredPostings[i - 1]
          let isActive = true

          let postingIcon = opportunitiesIconBlue
          let postingIconClassName = "image-auto-50 center-item"

          if (posting.postType === 'Event') {
            postingIcon = eventIconBlue
            postingIconClassName = "image-auto-48 center-item"
          } else if (posting.postType === 'Assignment' || posting.projectPromptType === 'Assignment') {
            postingIcon = assignmentsIconBlue
            postingIconClassName = "image-auto-50 top-margin-5 center-item"
          } else if (posting.postType === 'Problem' || posting.projectPromptType === 'Assignment') {
            postingIcon = problemIconBlue
            postingIconClassName = "image-auto-50 top-margin-5 center-item"
          } else if (posting.postType === 'Challenge' || posting.projectPromptType === 'Assignment') {
            postingIcon = challengeIconBlue
            postingIconClassName = "image-auto-50 top-margin-5 center-item"
          } else if (posting.postType === 'Work' || posting.postType === 'Internship' || posting.postType === 'Programs') {
            // none of these yet
            if (!posting.isActive) {
              isActive = false
            }
          } else if (posting.postType === 'Scholarship') {
            postingIcon = moneyIconBlue
            postingIconClassName = "image-auto-50 top-margin-5 center-item"
          }

          if (posting.imageURL) {
            postingIcon = posting.imageURL
            postingIconClassName = "image-50-fit top-margin-5 center-item"
          }

          if (isActive) {
            let title = posting.title
            if (!posting.title) {
              title = posting.name
            }

            let subtitle1 = posting.orgName
            if (posting.employerName && posting.employerName !== 'No Employers Saved' && posting.employerName !== 'No Employer Selected') {
              subtitle1 = posting.employerName
            }

            let subtitle2 = ''
            if (posting.eventType && posting.eventType !== '') {
              if (subtitle2 === '') {
                subtitle2 = posting.eventType
              } else {
                subtitle2 = subtitle2 + ' | ' + posting.eventType
              }
            } else {
              if (posting.postType === 'Project') {
                subtitle2 = posting.projectPromptType
              } else {
                subtitle2 = posting.postType
              }
            }

            if (posting.field && posting.field !== '' && !posting.field.includes('undefined')) {
              if (subtitle2 === '') {
                subtitle2 = posting.field.split("|")[0].trim()
              } else {
                subtitle2 = subtitle2 + ' | ' + posting.field.split("|")[0].trim()
              }
            }

            if (posting.industry && posting.industry !== '') {
              if (subtitle2 === '') {
                subtitle2 = posting.industry
              } else {
                subtitle2 = subtitle2 + ' | Industry: ' + posting.industry
              }
            }

            if (posting.difficultyLevel && posting.difficultyLevel !== '') {
              if (subtitle2 === '') {
                subtitle2 = posting.difficultyLevel
              } else {
                subtitle2 = subtitle2 + ' | Difficulty Level: ' + posting.difficultyLevel
              }
            }

            if (posting.submissionDeadline) {
              if (subtitle2 === '') {
                subtitle2 = 'Deadline :' + convertDateToString(new Date(posting.submissionDeadline),"datetime-2")
              } else {
                subtitle2 = subtitle2 + ' | Deadline: ' + convertDateToString(new Date(posting.submissionDeadline),"datetime-2")
              }
            }

            if (posting.startDate) {
              if (subtitle2 === '') {
                subtitle2 = convertDateToString(new Date(posting.startDate),"datetime-2")
              } else {
                subtitle2 = subtitle2 + ' | Start Date: ' + convertDateToString(new Date(posting.startDate),"datetime-2")
              }
            }

            let pathname = '/' + subpath + '/' + tempVarForPostings + '/' + posting._id
            if (window.location.pathname.includes('/problem-platform')) {
              pathname = '/problem-platform/' + posting._id
            } else {
              if (this.props.pageSource === 'landingPage') {
                if (this.state.activeOrg && this.state.activeOrg !== '') {
                  console.log('a target role? ', this.props.targetRole)
                  if (this.props.targetRole) {
                    pathname = '/opportunities/organizations/' + this.state.activeOrg + '/' + posting._id + '/roles/' + this.props.targetRole
                  } else {
                    pathname = '/opportunities/organizations/' + this.state.activeOrg + '/' + posting._id
                  }
                } else {
                  if (this.props.targetRole) {
                    pathname = '/opportunities/organizations/guidedcompass/' + posting._id + '/roles/' + this.props.targetRole
                  } else {
                    pathname = '/opportunities/' + posting._id
                  }
                }
              }
            }
            // if (type === 'featured') {
            //   console.log('in featured: ', posting.featured, posting.title)
            // }
            if ((type === 'featured' && posting.featured) || (type !== 'featured')) {
              rows.push(
                <div key={i}>

                  <Link to={pathname} state={{ selectedOpportunity: posting, selectedPosting: posting, postings: filteredPostings, source: 'Student' }} className="background-link">
                    <div className="fixed-column-70">
                      {(posting.matchScore) ? (
                        <div className="padding-10">
                          <CircularProgressbar
                            percentage={posting.matchScore}
                            text={`${posting.matchScore}%`}
                            styles={{
                              path: { stroke: `rgba(31, 130, 240, ${posting.matchScore / 100})` },
                              text: { fill: '#1F82CC', fontSize: '26px' },
                              trail: { stroke: 'transparent' }
                            }}
                          />
                        </div>
                      ) : (
                        <img src={postingIcon} alt="GC" className={postingIconClassName}/>
                      )}
                      {(posting.createdAt) && (
                        <div className="top-padding horizontal-padding-7">
                          <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(posting.createdAt,"daysAgo")}</p>
                        </div>
                      )}

                    </div>
                    <div className="calc-column-offset-150">
                      <p className="heading-text-5">{title}</p>
                      <p className="description-text-1 top-padding-3">{subtitle1}</p>
                      <p className="description-text-2 standard-color top-padding-3">{subtitle2}</p>
                      {(posting.payRange && (posting.subPostType === 'Full-Time' || posting.subPostType === 'Part-Time')) && (
                        <p className="description-text-3 cta-color bold-text top-padding-5">{posting.payRange}</p>
                      )}
                    </div>
                  </Link>
                  <div className="float-left left-padding">
                    <span className="float-right">
                      <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                      <Link to={pathname} state={{ selectedOpportunity: posting, selectedPosting: posting, postings: filteredPostings, source: 'Student' }} className="background-link">
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                      </Link>
                    </span>
                    {(window.location.pathname.includes('/app') || window.location.pathname.includes('/advisor')) && (
                      <span className="float-right right-padding-15">
                        {((this.state.applications && this.state.applications.some(app => app.postingId === posting._id)) || (posting.submissions && posting.submissions.some(sub => sub.userEmail === this.state.emailId))) ? (
                          <div className="top-margin">
                            <img src={appliedIconBlue} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already applied!"/>
                          </div>
                        ) : (
                          <div>
                            {(this.state.rsvps && this.state.rsvps.some(rsvp => rsvp.postingId === posting._id)) && (
                              <div className="top-margin">
                                <img src={rsvpIconBlue} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already registered!"/>
                              </div>
                            )}
                          </div>
                        )}

                        <button className="btn background-button top-margin-20" onClick={() => this.favoriteItem(posting,posting.postType) }>
                          <img src={(this.state.favorites.includes(posting._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                        </button>
                      </span>
                    )}
                  </div>
                  <div className="clear"/>

                  {(posting.sortCriteria || (this.state.sortCriteriaArray && this.state.sortCriteriaArray[i - 1])) && (
                    <div className="left-padding-70">
                      {(this.state.sortCriteriaArray.length > 0) && (
                        <div>
                          <div className="half-spacer" />
                          <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[i - 1].name}: {this.state.sortCriteriaArray[i - 1].criteria}</p>
                        </div>
                      )}
                    </div>
                  )}
                  {(posting.filterCriteria || (this.state.filterCriteriaArray && this.state.filterCriteriaArray[i - 1])) && (
                    <div className="left-padding-70">
                      <div className="half-spacer" />
                      <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[i - 1].name}: {this.state.filterCriteriaArray[i - 1].criteria}</p>
                    </div>
                  )}
                  <div className="spacer" /><div className="spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <hr />
                  <div className="clear"/>
                  <div className="spacer" /><div className="spacer" />

                  <ReactTooltip id="tooltip-placeholder-id" />
                </div>
              )
            }
          }
        }
      }

      return rows;
    }


    render() {

      return (
        <div>
          {(this.props.oppType && (this.state.postings || this.state.upcomingEvents || this.state.pastEvents)) && (
            <div>
              {this.renderOpportunities(this.props.oppType)}
              <ReactTooltip id="tooltip-placeholder-id" />
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(RenderOpportunities);
