import React, {Component} from 'react';
import Axios from 'axios';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import CountdownTimer from '../Functions/CountdownTimer';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class TakeBasicAssessment extends Component {
    constructor(props) {
        super(props)

        this.state = {
          questions: [],
          questionIndex: 0,
          responses: [],
          rankingAnswerChoices: [],
          done: false
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.renderRankingChoices = this.renderRankingChoices.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.submitAssessment = this.submitAssessment.bind(this)
        this.timeExpired = this.timeExpired.bind(this)
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      console.log('show result: ', result)

      if (this.props.selectedLesson && this.props.selectedLesson.customAssessmentId) {
        console.log('we in a lesson')

        if (!result.destination) {
          return;
        }
        console.log('show result: ', result)
        let answerChoices = []
        let questionIndex = 0
        for (let i = 1; i <= this.state.customAssessment.questions.length; i++) {
          console.log('compare the two: ',this.state.customAssessment.questions[i - 1]._id, result.destination.droppableId)
          if (this.state.customAssessment.questions[i - 1]._id === result.destination.droppableId) {
            answerChoices = this.state.customAssessment.questions[i - 1].answerChoices
            questionIndex = i - 1
            console.log('did this work?', answerChoices, questionIndex)
          }
        }

        const rankingAnswerChoices = reorder( answerChoices, result.source.index, result.destination.index );

        let customAssessment = this.state.customAssessment
        customAssessment['questions'][questionIndex]['answerChoices'] = rankingAnswerChoices

        let responses = this.state.responses
        responses[questionIndex] = rankingAnswerChoices

        this.setState({ customAssessment, responses })

      } else {
        // eventually use this for TakeAssessment

        if (this.state.type === 'values') {

          if (result.destination.droppableId === 'gravitate') {
            const topGravitateValues = reorder(
              this.state.topGravitateValues,
              result.source.index,
              result.destination.index
            );

            this.setState({ topGravitateValues });
          } else if (result.destination.droppableId === 'employer') {
            const topEmployerValues = reorder(
              this.state.topEmployerValues,
              result.source.index,
              result.destination.index
            );

            this.setState({ topEmployerValues });
          }
        } else {

          let index = 0
          if (result.destination.droppableId && result.destination.droppableId.split('|')) {
            index = Number(result.destination.droppableId.split('|')[1])
          }

          if (this.state.questions[index].includes('technology trends')) {
            const technologyTrends = reorder(
              this.state.technologyTrends,
              result.source.index,
              result.destination.index
            );

            let wpResponses = this.state.wpResponses
            wpResponses[index] = technologyTrends

            this.setState({ technologyTrends, wpResponses });
          } else if (this.state.questions[index].includes('societal problems')) {
            const societalProblems = reorder(
              this.state.societalProblems,
              result.source.index,
              result.destination.index
            );

            let wpResponses = this.state.wpResponses
            wpResponses[index] = societalProblems

            this.setState({ societalProblems, wpResponses });
          }
        }
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubTakeAssessment', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.questions !== prevProps.questions || this.props.selectedSubmission !== prevProps.selectedSubmission) {
        this.retrieveData()
      }
     }

    retrieveData(){
      console.log('retrieveData called in SubTakeAssessment', this.props)

      let questions = this.props.questions
      const orgCode = this.props.orgCode

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      const pictureURL = localStorage.getItem('pictureURL');
      const orgName = localStorage.getItem('orgName');
      const roleName = localStorage.getItem('roleName');

      let responses = []
      if (this.props.submissions && this.props.selectedLesson) {
        if (this.props.submissions.findIndex(s => s.lessonId === this.props.selectedLesson._id) > -1) {
          const lessonIndex = this.props.submissions.findIndex(s => s.lessonId === this.props.selectedLesson._id)
          const answers = this.props.submissions[lessonIndex].answers
          if (answers) {
            for (let i = 1; i <= answers.length; i++) {
              responses.push(answers[i - 1].answer)
            }
          }
        }
      } else if (this.props.submissions && this.props.selectedSubmission) {
        const answers = this.props.selectedSubmission.answers
        if (answers) {
          for (let i = 1; i <= answers.length; i++) {
            responses.push(answers[i - 1].answer)
          }
        }
      }
      // console.log('plz show responses: ', responses, this.props.selectedSubmission)
      this.setState({ questions, responses,
        emailId, cuFirstName, cuLastName, username, pictureURL, orgName, roleName, orgCode
      })

      let lessonCustomAssessmentId = null
      if (this.props.selectedLesson) {
        if (window.location.pathname.includes('/advisor') && this.props.selectedLesson.mentorAssessmentId) {
          lessonCustomAssessmentId = this.props.selectedLesson.mentorAssessmentId
        } else if (this.props.selectedLesson.customAssessmentId){
          lessonCustomAssessmentId = this.props.selectedLesson.customAssessmentId
        }

        Axios.get('/api/customassessments/byid', { params: { _id: lessonCustomAssessmentId } })
        .then((response) => {
          console.log('Custom assessment by id query attempted in subtakebasicassessment', response.data);

          if (response.data.success && response.data.assessment) {
            console.log('custom assessment query worked')

            const description = response.data.assessment.description
            questions = response.data.assessment.questions
            const viewCorrectAnswers = response.data.assessment.viewCorrectAnswers
            const timeLimit = response.data.assessment.timeLimit
            let countdownTime = null
            if (timeLimit) {

              let hours = 0
              let minutes = 0
              let seconds = 0
              if (timeLimit > 60) {
                hours = Math.floor(timeLimit/60)
                minutes = timeLimit % 60
              } else {
                minutes = timeLimit
              }

              countdownTime = { hours, minutes, seconds }
            }

            this.setState({ description, questions, viewCorrectAnswers, timeLimit, countdownTime })

          } else {
            console.log('custom assessment query did not work', response.data.message)
          }
        }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
        });
      } else if (this.props.selectedCustomAssessment) {

        lessonCustomAssessmentId = this.props.selectedCustomAssessment._id

        const description = this.props.selectedCustomAssessment.description
        questions = this.props.selectedCustomAssessment.questions
        const viewCorrectAnswers = this.props.selectedCustomAssessment.viewCorrectAnswers
        const timeLimit = this.props.selectedCustomAssessment.timeLimit
        let countdownTime = null
        if (timeLimit) {

          let hours = 0
          let minutes = 0
          let seconds = 0
          if (timeLimit > 60) {
            hours = Math.floor(timeLimit/60)
            minutes = timeLimit % 60
          } else {
            minutes = timeLimit
          }

          countdownTime = { hours, minutes, seconds }
        }
        this.setState({ description, questions, viewCorrectAnswers, timeLimit, countdownTime })
      }
    }

    formChangeHandler = event => {
      console.log('show data: ', event.target.name)

      if (event.target.name === 'shortAnswer') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }

      } else if (event.target.name === 'longAnswer') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }
      } else if (event.target.name === 'email') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }
      } else if (event.target.name === 'birthdate') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }
      } else if (event.target.name === 'date') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }

      } else if (event.target.name === 'multipleChoice') {
        let responses = this.state.responses
        responses[this.state.questionIndex] = event.target.value
        this.setState({ multipleChoice: event.target.value, responses })
      } else if (event.target.name === 'multipleAnswer') {
        let responses = this.state.responses
        let thisResponseArray = this.state.responses[this.state.questionIndex]

        if (Array.isArray(thisResponseArray)) {
          if (thisResponseArray.includes(event.target.value)) {
            let index = thisResponseArray.indexOf(event.target.value);
            if (index > -1) {
              thisResponseArray.splice(index, 1);
            }
          } else {

            thisResponseArray.push(event.target.value)

          }
        } else {
          thisResponseArray = []
          thisResponseArray.push(event.target.value)
        }

        responses[this.state.questionIndex] = thisResponseArray
        this.setState({ selectedAnswer: thisResponseArray, responses })
      } else if (event.target.name === 'boolean') {
        let responses = this.state.responses
        responses[this.state.questionIndex] = event.target.value
        this.setState({ selectedAnswer: event.target.value, responses })
      } else if (event.target.name.includes('listedAnswer')) {
        let responses = this.state.responses

        const type = event.target.name.split('|')[1]
        const index = event.target.name.split('|')[2]

        if (type === 'boolean') {
          responses[index] = event.target.value
          this.setState({ responses })
        } else if (type === 'multipleChoice') {
          // console.log('gimme the value: ', responses[index], event.target.name, event.target.value)
          if (responses[index] === event.target.value) {
            responses[index] = null
          } else {
            responses[index] = event.target.value
          }
          this.setState({ responses })
        } else if (type === 'multipleAnswer') {
          let thisResponseArray = this.state.responses[index]

          if (Array.isArray(thisResponseArray)) {
            if (thisResponseArray.includes(event.target.value)) {
              let index = thisResponseArray.indexOf(event.target.value);
              if (index > -1) {
                thisResponseArray.splice(index, 1);
              }
            } else {

              thisResponseArray.push(event.target.value)

            }
          } else {
            thisResponseArray = []
            thisResponseArray.push(event.target.value)
          }

          responses[index] = thisResponseArray
          this.setState({ responses })

        } else {
          if (event.target.value === '') {
            responses[index] = null
            this.setState({ responses })
          } else {
            responses[index] = event.target.value
            this.setState({ responses })
          }
        }

        // let testShorthand = 'customAssessment'
        // let testQuestions = []
        // if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
        //   testShorthand = 'thirdPartyAssessments'
        //   testQuestions = this.state.thirdPartyAssessments
        // } else if (event.target.name.split("|")[3] === 'dealBreakers') {
        //   testShorthand = 'dealBreakers'
        //   testQuestions = this.state.dealBreakers
        // } else {
        //   if (this.state.customAssessment) {
        //     testQuestions = this.state.customAssessment.questions
        //   }
        // }

      } else if (event.target.name.includes('question')) {
        const index = Number(event.target.name.split('|')[1])
        for (let i = 1; i <= this.state.questions.length; i++) {
          console.log('compare indices: ', index, i - 1, typeof index)
          if (index === i - 1) {
            let questions = this.state.questions
            questions[index]['answer'] = event.target.value
            console.log('show questions after form: ', questions)
            this.setState({ questions, formHasChanged: true })
          }
        }
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    renderQuestions() {
      console.log('renderQuestions called')

      let items = []
      let responses = this.state.responses
      items = this.state.questions

      let rows = []
      if (items && items.length > 0) {
        console.log('show items: ', items)
        for (let i = 1; i <= items.length; i++) {
          const index = i - 1
          const question = items[index]

          // const response = responses[index]

          let response = ''
          if (responses[index]) {
            response = responses[index]
          }

          console.log('looping: ', i - 1, question, response)

          rows.push(
            <div key={"question|" + i} className="left-text row-10">
              {(question.prompt) && (
                <div>
                  <p className="heading-text-5 half-bold-text">{question.prompt}</p>

                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                </div>
              )}

              <div>
                <label className="capitalize-text half-bold-text">{i}. {question.name}{(question.required) && <label className="error-color bold-text"> *</label>}</label>
                <div className="clear"/>
                <div className="spacer"/><div className="half-spacer"/>
              </div>

              {(question.questionType === 'Short Answer') && (
                <div>
                  <input className="text-field" type="text" placeholder={(question.placeholder) ? question.placeholder : "Write your answer..."} name={"listedAnswer|shortAnswer|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Long Answer') && (
                <div>
                  <textarea className="text-field" type="text" placeholder={(question.placeholder) ? question.placeholder : "Write your answer..."} name={"listedAnswer|longAnswer|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Email') && (
                <div>
                  <input className="text-field" type="text" placeholder="Write your email..." name={"listedAnswer|email|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Birthdate') && (
                <div>
                  <input className="text-field" type="text" placeholder="Write your birthdate..." name={"listedAnswer|birthdate|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Date') && (
                <div>
                  <input className="date-field" type="date" placeholder="Write your date..." name={"listedAnswer|date|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Multiple Choice') && (
                <div>
                  {this.renderAnswerChoices('multipleChoice', index)}
                </div>
              )}

              {(question.questionType === 'Checkbox') && (
                <div>
                  {this.renderAnswerChoices('checkbox', index)}
                </div>
              )}

              {(question.questionType === 'Ranking') && (
                <div>
                  <p className="description-text-1 description-text-color">Drag and drop options into the order you like.</p>
                  <div className="spacer" /><div className="spacer" />
                  {this.renderRankingChoices(index)}
                </div>
              )}

              {(question.questionType === 'Linear Scale') && (
                <div>
                  {this.renderAnswerChoices('linearScale', index)}
                </div>
              )}

              {(question.questionType === 'Number Ranges') && (
                <div>
                  <input className="number-field" type="number" placeholder="0" name={"listedAnswer|shortAnswer|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') && (
                <div>
                  {(question.correctAnswer === response) ? (
                    <div>
                      <p className="cta-color">{response} is the correct answer</p>
                    </div>
                  ) : (
                    <div>
                      <p className="error-color">{response} is incorrect</p>
                    </div>
                  )}
                </div>
              )}

              <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="half-spacer" />
            </div>
          )
        }
      }

      return rows
    }

    renderAnswerChoices(type, passedIndex) {
      console.log('renderAnswerChoices called', type, passedIndex)

      let rows = []

      let questionIndex = passedIndex

      let screening = false
      let responses = this.state.responses

      let answerChoices = []
      if (this.state.questions[questionIndex].answerChoices) {
        answerChoices = this.state.questions[questionIndex].answerChoices
      }

      if (type === 'multipleChoice') {

        if (answerChoices) {

          rows.push(
            <div key={answerChoices + passedIndex} className="top-padding">
              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(screening) ? (
                          <div>
                            {(responses[passedIndex] === answerChoices[optionIndex].name) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex].name }}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(responses[passedIndex] === answerChoices[optionIndex]) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear"/>
                </div>
              )}
            </div>
          )
        }

      } else if (type === 'checkbox') {

        if (answerChoices) {

          rows.push(
            <div key={answerChoices + passedIndex} className="top-padding">
              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">

                        {(screening) ? (
                          <div>
                            {(Array.isArray(responses[passedIndex]) && responses[passedIndex].includes(answerChoices[optionIndex].name)) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(Array.isArray(responses[passedIndex]) && responses[passedIndex].includes(answerChoices[optionIndex])) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: this.state.customAssessment.questions[passedIndex].answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear"/>
                </div>
              )}
            </div>
          )
        }
      } else if (type === 'linearScale') {

        if (answerChoices) {
          rows.push(
            <div key={answerChoices + passedIndex} className="top-padding">

              <div className="top-margin bottom-margin-20">
                <div className={(window.innerWidth < 768) ? "flex-container column-direction description-text-2 align-start" : "flex-container flex-space-between row-direction description-text-2 relative-position"}>
                  <button className={(window.innerWidth < 768) ? "background-button flex-container row-direction" : "background-button"} onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[0]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                    <div className={(window.innerWidth < 768) ? "width-20 height-20 circle-radius white-background dark-border" : "width-20 height-20 circle-radius white-background dark-border"}>
                      {(responses[passedIndex] === answerChoices[0]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                    </div>
                    <p className={(window.innerWidth < 768) ? "left-margin top-margin-3" : ""}>{(answerChoices[0]) ? answerChoices[0] : "Least/most value"}</p>
                  </button>
                  <button className={(window.innerWidth < 768) ? "background-button flex-container row-direction top-margin" : "background-button"} onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[1]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                    <div className={(window.innerWidth < 768) ? "width-20 height-20 circle-radius white-background dark-border" : "width-20 height-20 circle-radius white-background dark-border center-item"}>
                      {(responses[passedIndex] === answerChoices[1]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                    </div>
                    <p className={(window.innerWidth < 768) ? "left-margin top-margin-3" : "center-text"}>{(answerChoices[1]) ? answerChoices[1] : "Lower Mid value"}</p>
                  </button>
                  <button className={(window.innerWidth < 768) ? "background-button flex-container row-direction top-margin" : "background-button"} onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[2]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                    <div className={(window.innerWidth < 768) ? "width-20 height-20 circle-radius white-background dark-border" : "width-20 height-20 circle-radius white-background dark-border center-item"}>
                      {(responses[passedIndex] === answerChoices[2]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                    </div>
                    <p className={(window.innerWidth < 768) ? "left-margin top-margin-3" : "center-text"}>{(answerChoices[2]) ? answerChoices[2] : "Mid value"}</p>
                  </button>
                  <button className={(window.innerWidth < 768) ? "background-button flex-container row-direction top-margin" : "background-button"} onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[3]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                    <div className={(window.innerWidth < 768) ? "width-20 height-20 circle-radius white-background dark-border" : "width-20 height-20 circle-radius white-background dark-border center-item"}>
                      {(responses[passedIndex] === answerChoices[3]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                    </div>
                    <p className={(window.innerWidth < 768) ? "left-margin top-margin-3" : "center-text"}>{(answerChoices[3]) ? answerChoices[3] : "Higher Mid value"}</p>
                  </button>
                  <button className={(window.innerWidth < 768) ? "background-button flex-container row-direction top-margin" : "background-button"} onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[4]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                    <div className={(window.innerWidth < 768) ? "width-20 height-20 circle-radius white-background dark-border" : "width-20 height-20 circle-radius white-background dark-border pin-right"}>
                      {(responses[passedIndex] === answerChoices[4]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                    </div>
                    <p className={(window.innerWidth < 768) ? "left-margin top-margin-3" : "right-text"}>{(answerChoices[4]) ? answerChoices[4] : "Least/most value"}</p>
                  </button>

                  {/*
                  <div className="width-20 height-20 circle-radius medium-background dark-border" />
                  <div className="width-20 height-20 circle-radius medium-background dark-border" />
                  <div className="width-20 height-20 circle-radius medium-background dark-border" />
                  <div className="width-20 height-20 circle-radius medium-background dark-border" />*/}

                  {(window.innerWidth > 768) && (
                    <div className="absolute-position top-0 left-0 right-0 z-index-negative-1" style={{}}>
                      <div className="dark-border-bottom top-margin height-1 full-width" />
                    </div>
                  )}
                </div>
                {/*
                {(window.innerWidth > 768) && (
                  <div className="top-margin-negative-40">
                    <div className="dark-border-bottom top-margin height-1 full-width" />
                  </div>
                )}*/}

              </div>
              {/*
              <div className="flex-container row-direction flex-space-between description-text-2">

                <div className="flex-20">
                  <p>{(answerChoices[0]) ? answerChoices[0] : "Least/most value"}</p>
                </div>
                <div className="flex-20">
                  <p className="center-text">{(answerChoices[1]) ? answerChoices[1] : "Lower Mid value"}</p>
                </div>
                <div className="flex-20">
                  <p className="center-text">{(answerChoices[2]) ? answerChoices[2] : "Mid value"}</p>
                </div>
                <div className="flex-20">
                  <p className="center-text">{(answerChoices[3]) ? answerChoices[3] : "Higher Mid value"}</p>
                </div>
                <div className="flex-20">
                  <p className="right-text">{(answerChoices[4]) ? answerChoices[4] : "Least/most value"}</p>
                </div>

                <p>{(answerChoices[0]) ? answerChoices[0] : "Least/most value"}</p>
                <p className="center-text">{(answerChoices[1]) ? answerChoices[1] : "Lower Mid value"}</p>
                <p className="center-text">{(answerChoices[2]) ? answerChoices[2] : "Mid value"}</p>
                <p className="center-text">{(answerChoices[3]) ? answerChoices[3] : "Higher Mid value"}</p>
                <p className="right-text">{(answerChoices[4]) ? answerChoices[4] : "Least/most value"}</p>
              </div>

              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(responses[passedIndex] === answerChoices[optionIndex]) ? (
                          <div>
                            <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text">{value}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) } disabled={(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion') ? true : false}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2">{value}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear"/>
                </div>
              )}*/}
            </div>
          )
        }
      }

      return rows
    }

    renderRankingChoices(passedIndex) {
      console.log('renderRankingChoices called', passedIndex)

      let rankingAnswerChoices = this.state.rankingAnswerChoices
      let droppableId = '111'
      rankingAnswerChoices = this.state.customAssessment.questions[passedIndex].answerChoices
      droppableId = this.state.customAssessment.questions[passedIndex]._id

      if (this.state.responses[passedIndex] && Array.isArray(this.state.responses[passedIndex])) {
        rankingAnswerChoices = this.state.responses[passedIndex]
      }

      console.log('show droppableId: ', droppableId)

      return (
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <StrictModeDroppable droppableId={droppableId}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {rankingAnswerChoices.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      )
    }

    optionClicked(index, optionIndex) {
      console.log('optionClicked called', index, optionIndex)

      let questions = this.state.questions

      if (questions[index].answer) {
        if (questions[index]['answer'].includes(questions[index].options[optionIndex])) {
          const removeIndex = questions[index]['answer'].indexOf(questions[index].options[optionIndex])
          questions[index]['answer'].splice(removeIndex, 1)

        } else {
          questions[index]['answer'].push(questions[index].options[optionIndex])
        }
      } else {
        questions[index]['answer'] = [questions[index].options[optionIndex]]
      }

      this.setState({ questions })

    }

    submitAssessment(e, timeExpired) {
      console.log('submitAssessment called', this.props.selectedCourse)

      if (e) {
        e.stopPropagation()
      }

      this.setState({ errorMessage: null, successMessage: null, isSaving: true })

      if (!timeExpired && this.state.responses.length === 0) {
        return this.setState({ errorMessage: "Please add a response to each required question", isSaving: false })
      } else {
        if (!timeExpired) {
          for (let i = 1; i <= this.state.questions.length; i++) {
            if (!this.state.responses[i - 1] && this.state.questions[i - 1].required) {
              return this.setState({ errorMessage: "Please add a response to each required question", isSaving: false })
            }
          }
        }

        // save changes to lesson
        let _id = null
        if (this.props.submissions && this.props.selectedLesson) {
          if (this.props.submissions.findIndex(s => s.lessonId === this.props.selectedLesson._id) > -1) {
            const lessonIndex = this.props.submissions.findIndex(s => s.lessonId === this.props.selectedLesson._id)
            _id = this.props.submissions[lessonIndex]._id
          }
        } else if (this.props.submissions && this.props.selectedSubmission) {
          _id = this.props.selectedSubmission._id
        }

        let surveySubmitted = false
        let courseId = null
        let courseName = null
        if (this.props.selectedCourse) {
          courseId = this.props.selectedCourse._id
          courseName = this.props.selectedCourse.name
        }
        let lessonId = null
        let lessonName = null
        let assessmentId = null
        let assessmentName = null
        let subType = null

        if (this.props.selectedLesson) {
          lessonId = this.props.selectedLesson._id
          lessonName = this.props.selectedLesson.name
        } else if (this.props.selectedSurvey) {
          surveySubmitted = true
          lessonId = this.props.selectedSurvey._id
          lessonName = this.props.selectedSurvey.title
        } else if (this.props.selectedCustomAssessment) {
          surveySubmitted = true

          if (this.state.questions && this.state.questions.length > 0) {
            for (let i = 1; i <= this.state.questions.length; i++) {
              console.log('show each question: ', this.state.questions[i - 1])
              if (this.state.questions[i - 1].name === "What lesson did you cover?" && this.state.responses[i - 1]) {
                lessonId = null
                lessonName = this.state.responses[i - 1]
              }
            }
          }

          assessmentId = this.props.selectedCustomAssessment._id
          assessmentName = this.props.selectedCustomAssessment.title
          subType = this.props.selectedCustomAssessment.type

        }

        let answers = []
        if (this.state.responses) {
          for (let i = 1; i <= this.state.responses.length; i++) {
            answers.push({
              question: this.state.questions[i - 1].name,
              answer: this.state.responses[i - 1]
            })
          }
        }

        const cohort = this.props.cohort

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const emailId = this.state.emailId
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName
        const orgCode = this.state.orgCode
        const orgName = this.state.orgName

        let orgContactFirstName = null
        let orgContactEmail = null
        if (this.props.selectedLesson) {
          orgContactFirstName = this.props.selectedLesson.firstName
          orgContactEmail = this.props.selectedLesson.email
        } else if (this.props.orgContactEmail) {
          orgContactEmail = this.props.orgContactEmail
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        let submission = {
          _id, surveySubmitted,
          courseId, courseName, lessonId, lessonName, assessmentId, assessmentName, subType,
          answers, cohort,
          firstName, lastName, emailId, username, pictureURL, roleName, orgCode, orgName,
          orgContactFirstName, orgContactEmail,
          createdAt, updatedAt
        }

        Axios.post('/api/submissions', submission)
        .then((response) => {
          console.log('attempting to save submission')
          if (response.data.success) {
            console.log('saved submission', response.data)

            let successMessage = 'You have successfully submitted your answers!'
            let errorMessage = null
            if (timeExpired) {
              successMessage = null
              errorMessage = "Your time has expired. Your answers have been submitted"
            }

            if (this.props && this.props.passResponse) {
              this.props.passResponse(response.data.submission, _id)
            }

            this.setState({ isSaving: false, errorMessage, successMessage, countdownTime: null, done: true })

          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving submission', isSaving: false })
        });

      }
    }

    timeExpired() {
      console.log('timeExpired called')

      this.submitAssessment(null,true)
    }

    render() {

      return (
          <div>

            <div>
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text row-10">Calculating results...</p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="full-width">
                    <div>
                      {(this.state.done === true && this.state.viewCorrectAnswers !== 'Upon Completion') ? (
                        <div>
                            <div>
                              <p className="heading-text-2 row-10 full-width center-text cta-color">You're done!</p>
                            </div>

                            <div className="padding-20">
                              <div>
                                <div className="flex-container row-direction flex-center" >
                                  <div className="full-width center-text">

                                    <div>
                                      {(this.state.successMessage) && <p className="row-10">{this.state.successMessage}</p>}
                                      {(this.state.errorMessage) && <p className="row-10">{this.state.errorMessage}</p>}
                                      <div className="spacer" />
                                      {(this.props.closeModal) && (
                                        <button className="btn btn-primary" onClick={() => this.props.closeModal()}>Close View</button>
                                      )}
                                    </div>

                                  </div>
                                </div>
                              </div>

                              <p className="error-message row-10">{this.state.resultsErrorMessage}</p>
                            </div>
                        </div>
                      ) : (
                          <div>
                            <div className="row-20">
                              <div className="fixed-column-40 height-30">
                              </div>
                              <div className="calc-column-offset-80">
                                <p className={(window.location.pathname.endsWith('/take')) ? "full-width center-text heading-text-2" : "full-width center-text heading-text-2"}>{(this.state.questions) ? this.state.questions.length : '0'} Assessment Questions</p>
                              </div>
                              {(this.props.closeModal) && (
                                <div className="fixed-column-40">
                                  <button className="background-button clear-margin" onClick={() => this.props.closeModal()}><p className="description-text-3 cta-color bold-text">Cancel</p></button>
                                </div>
                              )}
                              <div className="clear" />

                              {(this.state.description) && (
                                <div className="row-20">
                                  <p className="description-text-color center-text">{this.state.description}</p>
                                </div>
                              )}

                              {(this.state.countdownTime) && (
                                <div className="full-width row-10">
                                  <CountdownTimer hoursMinSecs={this.state.countdownTime} timeExpired={this.timeExpired} passedClass="full-width center-text error-color"/>
                                </div>
                              )}

                            </div>

                            <div>

                              <div>
                                {this.renderQuestions()}
                              </div>

                              {(this.state.errorMessage) && <p className="error-color row-10">{this.state.errorMessage}</p>}
                              {(this.state.successMessage) && <p className="cta-color row-10">{this.state.successMessage}</p>}

                              {(this.state.done === true && this.state.viewCorrectAnswers === 'Upon Completion' && this.props.closeModal) ? (
                                <div>
                                  <button className="btn btn-secondary" onClick={(e) => this.props.closeModal()}>Close View</button>
                                </div>
                              ) : (
                                <div>
                                  <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={(e) => this.submitAssessment(e)}>Submit</button>
                                </div>
                              )}

                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

      )
    }
}

export default TakeBasicAssessment;
