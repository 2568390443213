import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubEmployers from './Subcomponents/Employers';
import {toggleVars} from './Functions/ToggleVars';
import withRouter from './Functions/WithRouter';

class AdvEmployers extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {
        console.log('lets see the values ', activeOrg, roleName)
        const path = window.location.pathname
        this.setState({ emailId: email, username, orgFocus, activeOrg, roleName, orgLogo, path })

      }
    }

    render() {
      return (
        <div>
          <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  roleName={this.state.roleName} history={this.props.navigate} />

          {(!toggleVars.hideSideNav) && (
            <SubSideNav selectedComponent={"AdvEmployers"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
          )}

          <SubEmployers activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} roleName={this.state.roleName} path={this.state.path} />
          <div className="clear" />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(AdvEmployers);
