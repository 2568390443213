import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubPathways from './Subcomponents/Pathways';
import SubFinancials from './Subcomponents/Financials';
import SubCareers from './Subcomponents/Careers';
import SubTrends from './Subcomponents/Trends';
import SubAssistantWidget from './Common/AssistantWidget';
import withRouter from './Functions/WithRouter';

class AdvExploration extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showCompassAssistant: true,
          subNavCategories: ['Careers','Financial Planner','Trends'],
          subNavSelected: 'Careers',
        }

        // this.retrieveD = this.calculateCheckIns.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');
      let authType = localStorage.getItem('authType');
      let placementPartners = localStorage.getItem('placementPartners');
      const explorationNavSelected = localStorage.getItem('explorationNavSelected');
      let accountCode = localStorage.getItem('accountCode');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let employerAccount = false
        if (roleName === 'Employee' && accountCode) {
          employerAccount = true
          // accountCode = null
        } else {
          accountCode = null
        }

        let subNavSelected = 'Careers'
        let subNavCategories = ['Careers','Financial Planner','Trends']
        // if (employerAccount) {
        //   subNavSelected = 'Pathways'
        // }
        let calculateMatches = false
        let selectedGoal = null
        if (this.state.showPathways) {

          let queryParams = {}
          if (activeOrg) {
            queryParams['orgCode'] = activeOrg
          }
          if (placementPartners) {
            queryParams['orgCode'] = null
            queryParams['orgCodes'] = [activeOrg].concat(placementPartners)
          }

          Axios.get('/api/pathways', { params: queryParams })
          .then((response) => {
            console.log('Exploration pathways query attempted', response.data);

            if (response.data.success) {
              console.log('pathways query worked')

              if (response.data.pathways.length !== 0) {

                const pathways = response.data.pathways
                subNavSelected = 'Pathways'
                subNavCategories = ['Pathways','Careers','Financial Planner','Trends']
                this.setState({ pathways, subNavSelected, subNavCategories })
              }

            } else {
              console.log('pathway query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Pathway query did not work for some reason', error);
          });
        }

        if (this.props.location && this.props.location.state) {

          if (this.props.location.state.subNavSelected) {
            subNavSelected = this.props.location.state.subNavSelected
          } else if (explorationNavSelected) {
            subNavSelected = explorationNavSelected
          }
          // console.log('explorationNavSelected: ', explorationNavSelected, this.props.location.state, subNavSelected)


          if (this.props.location.state.calculateMatches) {
            calculateMatches = this.props.location.state.calculateMatches
            // this.props.navigate.replace('', null);
            window.history.replaceState(null, '')
          }

          selectedGoal = this.props.location.state.selectedGoal
        }

        let explorationCommands = []
        let careerCommands = [
          'What motivates most people to enter careers?',
          'What motivates people to stay in careers?',
          'On average, how many times do people switch careers in their lifetime?',
          'On average, how many times do people switch employers in their lifetime?',
          'Why do people pursue entrepreneurship rather than a traditional career?',
          'What percentage of people are self-employed?',
          'What percentage of businesses are still in operation after 5 years of starting?',
          'How much income does the average influencer make?',
          'What are competencies and how do they relate to hiring within career paths?',
          "What are some careers that prefer college degrees and some careers that don't require college degrees?",
        ]
        let financialCommands = [
          "What's the difference between a personal income statement and balance sheet?",
          "What are the different types of personal income that people can have?",
          "What type of personal income is most important for most people?",
          "How do most people increase their personal income?",
          "What are the common types of personal expenses that most people have?",
          "How much are taxes? And why is it hard to project how much taxes could be?",
          "What is the difference between high cost of living areas and low cost of living areas? What's an example of the cost of living for each?",
          "What are the most common types of personal assets that people can have?",
          "Why do people seek to acquire personal assets? How do people typically acquire personal assets?",
          "What are the most common types of personal liabilities that people can have?",
          "What types of personal liabilities can be particularly dangerous if one is not careful?",
          "What is net worth? How do people increase their net worth?",
        ]
        let trendCommands = [
          "What are the fastest growing technologies that may cause paradigm shifts across industries?",
          "On average, what are some of the highest paying careers?",
          "Which careers are projected to have the highest salary increases in the next five years?",
          "What are some of the most stable careers? And what are some of the riskiest careers? What does income variance matter when choosing a career?",
          "What types of companies have received the most venture capital funding? What are some of the common attributes of their product and team?",
          "Which employers are projected to hire the most? For what roles?",
          "Which employers are paying the most per employee? What roles are they paying the most for?",
          "What competencies are most in-demand? Which are most valued by employers?",
          "Which college majors are most populars? Which college majors do employers seem to value the most?",
          "What are the largest global problems that humanity is concerned about solving?",
          "What are ten trends that innovators and entrepreneurs are projected to take advantage of?",
          "How are innovative ideas changing and projected to change in the near future?"
        ]

        if (subNavSelected === 'Careers') {
          explorationCommands = explorationCommands.concat(careerCommands)
        } else if (subNavSelected === 'Careers') {
          explorationCommands = explorationCommands.concat(careerCommands)
        } else if (subNavSelected === 'Financial Planner') {
          explorationCommands = explorationCommands.concat(financialCommands)
        } else if (subNavSelected === 'Trends') {
          explorationCommands = explorationCommands.concat(trendCommands)
        }

        this.setState({ activeOrg, emailId: email, username, orgFocus, orgLogo,
          cuFirstName, cuLastName, roleName,
          remoteAuth, authType, subNavSelected, calculateMatches,
          selectedGoal, subNavCategories, employerAccount,
          explorationCommands, careerCommands, financialCommands, trendCommands
        })

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked in editLog')

            const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
            this.setState({ disableChatbotPrompts });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    subNavClicked(itemSelected) {
      console.log('subNavClicked called', itemSelected)

      localStorage.setItem('explorationNavSelected', itemSelected)

      let explorationCommands = []
      if (itemSelected === 'Pathways') {
        explorationCommands = this.state.careerCommands
      } else if (itemSelected === 'Careers') {
        explorationCommands = this.state.careerCommands
      } else if (itemSelected === 'Financial Planner') {
        explorationCommands = this.state.financialCommands
      } else if (itemSelected === 'Trends') {
        explorationCommands = this.state.trendCommands
      }

      this.setState({ subNavSelected: itemSelected, explorationCommands })
    }

    changeSubNavSelected(subNavSelected) {
      console.log('changeSubNavSelected called', subNavSelected)

      localStorage.setItem('explorationNavSelected', subNavSelected)
      this.setState({ subNavSelected })

    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

      let containerClass = ""
      let mainClass = "standard-container-2 clear-padding clear-background clear-shadow"
      if (window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) {
        containerClass = "relative-column-70 padding-five-percent-left top-margin-2-percent"
        mainClass = "top-margin-2-percent"
      }

      return (
          <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />

              <div className={containerClass}>
                {(!this.state.employerAccount) && (
                  <div className={mainClass}>
                    <div className="full-width white-background">

                      {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) && (
                        <div className="full-width height-5 primary-background" />
                      )}

                      <div className="clear-float">
                        <div className="carousel-3" onScroll={this.handleScroll}>
                          {this.state.subNavCategories.map((value, index) =>
                            <div className="display-inline">
                              {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                <div className="selected-carousel-item-2">
                                  <label key={value}>{value}</label>
                                </div>
                              ) : (
                                <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                                  <label key={value}>{value}</label>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                )}

                {(this.state.subNavSelected === 'Pathways') && (
                  <div>
                    <div className={mainClass}>
                      <SubPathways history={this.props.navigate} calculateMatches={this.state.calculateMatches} pathways={this.state.pathways} subNavSelected={this.state.subNavSelected} activeOrg={this.state.activeOrg} />
                    </div>
                  </div>
                )}
                {(this.state.subNavSelected === 'Careers') && (
                  <div>
                    <div className={mainClass}>
                      <SubCareers history={this.props.navigate} calculateMatches={this.state.calculateMatches} selectedGoal={this.state.selectedGoal} />
                    </div>
                  </div>
                )}
                {(this.state.subNavSelected === 'Financial Planner') && (
                  <div>
                    <div className={mainClass}>
                      <SubFinancials history={this.props.navigate}/>
                    </div>
                  </div>
                )}
                {(this.state.subNavSelected === 'Trends') && (
                  <div className={mainClass}>
                    <SubTrends history={this.props.navigate}/>
                  </div>
                )}
              </div>

              {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) && (
                <div className="relative-column-30 horizontal-padding top-margin-40">
                  <div className="left-padding-20">
                    {(this.state.explorationCommands) && (
                      <div>
                        <SubAssistantWidget commands={this.state.explorationCommands} commandClicked={this.commandClicked} />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="clear" />

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
                </div>
              )}
          </div>
      )
    }
}

export default withRouter(AdvExploration);
