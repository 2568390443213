import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubGoals from './Subcomponents/Goals';
import withRouter from './Functions/WithRouter';

class AdvBrowseGoals extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, orgLogo })
    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
            <div className="standard-container-2 clear-background clear-shadow clear-padding">
              <SubGoals history={this.props.navigate} pageSource="BrowseGoals" activeOrg={this.state.activeOrg} />
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(AdvBrowseGoals);
