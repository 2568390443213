import React, {Component} from 'react';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const careerMatchesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Timesheet extends Component {
    constructor(props) {
        super(props)

        this.state = {
          defaultFilterOption: 'All',

          ratingOptions: [],
          assignmentOptions: [{ name: '' }],
          projectOptions: [{ name: '' }],
          workerOptions: [],

          assignment: { name: '' },
          project: { name: '' },

          selectedAssignments: [],
          selectedProjects: [],
          positionOptions: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.renderCards = this.renderCards.bind(this)
        this.adjustCards = this.adjustCards.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.deleteTimesheet = this.deleteTimesheet.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedTimesheet && !prevProps.selectedTimesheet) {
        this.retrieveData()
      } else if (this.props.assignmentOptions && !prevProps.assignmentOptions) {
        this.retrieveData()
      } else if (this.props.projectOptions && !prevProps.projectOptions) {
        this.retrieveData()
      }
    }

    retrieveData(){
      console.log('retrieveData called in SubTakeAssessment', this.props.selectedTimesheet)

      let roleName = this.props.roleName
      let selectedTimesheet = this.props.selectedTimesheet
      let positionOptions = this.props.positionOptions
      let selectedPlacement = this.props.selectedPlacement

      let assignmentOptions = this.state.assignmentOptions
      if (this.props.assignmentOptions && this.props.assignmentOptions.length > 0) {
        assignmentOptions = assignmentOptions.concat(this.props.assignmentOptions)
      }

      let projectOptions = this.state.projectOptions
      if (this.props.projectOptions && this.props.projectOptions.length > 0) {
        projectOptions = projectOptions.concat(this.props.projectOptions)
      }

      let selectedAssignments = []
      let selectedProjects = []
      if (selectedTimesheet) {
        if (selectedTimesheet.assignments) {
          selectedAssignments = selectedTimesheet.assignments
        }
        if (selectedTimesheet && selectedTimesheet.projects) {
          selectedProjects = selectedTimesheet.projects
        }
      } else {
        // workId, workType
        // workerFirstName, workerLastName, workerEmail
        // managerFirstName, managerLastName, managerEmail
        // title, employerName, accountCode
        // start date, end date
        // cards
        // WBLC assigned
        roleName = "Admin"
      }

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, orgFocus,
        roleName, selectedTimesheet, assignmentOptions, projectOptions, selectedAssignments, selectedProjects,
        positionOptions, selectedPlacement
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value )

      if (event.target.name.includes('selectedTimesheet|startDate')) {
        let selectedTimesheet = this.state.selectedTimesheet
        selectedTimesheet['startDate'] = event.target.value
        this.setState({ selectedTimesheet })
        this.adjustCards(event.target.value, null)

      } else if (event.target.name === 'selectedPlacement') {
        let selectedPlacement = null
        let selectedTimesheet = this.state.selectedTimesheet
        for (let i = 1; i <= this.state.positionOptions.length; i++) {
          if (this.state.positionOptions[i - 1].title === event.target.value) {
            selectedPlacement = this.state.positionOptions[i - 1]
            selectedTimesheet['title'] = selectedPlacement.title
            selectedTimesheet['employerName'] = selectedPlacement.employerName
          }
        }

        this.setState({ selectedPlacement, selectedTimesheet })

      } else if (event.target.name.includes('|')) {
        const nameArray = event.target.name.split("|")
        const eventName = nameArray[0]
        const index = Number(nameArray[1])

        if (this.state.selectedTimesheet) {
          let selectedTimesheet = this.state.selectedTimesheet
          selectedTimesheet['cards'][index][eventName] = event.target.value
          this.setState({ selectedTimesheet })
        } else {
          let cards = this.state.cards
          cards[index][eventName] = event.target.value
          this.setState({ cards })
        }

      } else if (event.target.name === 'workerRating') {
        if (this.state.selectedTimesheet) {
          let selectedTimesheet = this.state.selectedTimesheet
          selectedTimesheet['workerRating'] = event.target.value
          this.setState({ selectedTimesheet })
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }

      } else if (event.target.name === 'workerFeedback') {
        if (this.state.selectedTimesheet) {
          let selectedTimesheet = this.state.selectedTimesheet
          selectedTimesheet['workerFeedback'] = event.target.value
          this.setState({ selectedTimesheet })
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
      } else if (event.target.name === 'managerRating') {
        if (this.state.selectedTimesheet) {
          let selectedTimesheet = this.state.selectedTimesheet
          selectedTimesheet['managerRating'] = event.target.value
          this.setState({ selectedTimesheet })
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
      } else if (event.target.name === 'managerFeedback') {
        if (this.state.selectedTimesheet) {
          let selectedTimesheet = this.state.selectedTimesheet
          selectedTimesheet['managerFeedback'] = event.target.value
          this.setState({ selectedTimesheet })
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
      } else if (event.target.name === 'assignment') {
        let assignment = null
        for (let i = 1; i <= this.state.assignmentOptions.length; i++) {
          if (this.state.assignmentOptions[i - 1].name === event.target.value) {
              assignment = this.state.assignmentOptions[i - 1]
          }
        }
        this.setState({ assignment })
      } else if (event.target.name === 'project') {

        let project = null
        for (let i = 1; i <= this.state.projectOptions.length; i++) {
          if (this.state.projectOptions[i - 1].name === event.target.value) {
              project = this.state.projectOptions[i - 1]
          }
        }
        console.log('winning called', project)
        this.setState({ project })
      } else if (event.target.name === 'message') {
        let selectedTimesheet = this.state.selectedTimesheet
        selectedTimesheet['message'] = event.target.value
        this.setState({ selectedTimesheet })
      } else if (event.target.name === 'startDate') {
        this.setState({ [event.target.name]: event.target.value })
        if (this.state.endDate) {
          this.adjustCards(event.target.value, this.state.endDate)
        }
      } else if (event.target.name === 'endDate') {
        this.setState({ [event.target.name]: event.target.value })
        if (this.state.startDate) {
          this.adjustCards(this.state.startDate, event.target.value)
        }
      } else if (event.target.name === 'placementName') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.name,event.target.value)
      } else if (event.target.name === 'worker') {
        let selectedWorker = { firstName: '', lastName: '' }
        for (let i = 1; i <= this.state.workerOptions.length; i++) {

          const fullName = this.state.workerOptions[i - 1].firstName + ' ' + this.state.workerOptions[i - 1].lastName
          if (fullName === event.target.value) {
            selectedWorker = this.state.workerOptions[i - 1]
          }
        }

        this.setState({ selectedWorker })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(type, searchString) {
      console.log('searchItems called', type, searchString)

      let searchURL = ""
      let searchObject = {}
      if (type === 'placementName') {
        searchURL = "/api/placements/search"
        searchObject = { params: { orgCode: this.state.activeOrg, searchString }}
      } else if (type === 'worker') {
        searchURL = "/api/members/search"
      }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called', searchObject)

        Axios.get(searchURL, searchObject)
        .then((response) => {
          console.log(type, 'search query attempted', response.data);

            if (response.data.success) {
              console.log(type, 'search query worked')

              if (type === 'placementName') {
                let placementOptions = response.data.placements
                self.setState({ placementOptions, animating: false })
              }

            } else {
              console.log('placement filter query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Placements filter query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    adjustCards(startDate,endDate) {
      console.log('adjustCards called', startDate, endDate)

      this.setState({ errorMessage: null, successMessage: null })

      const diffTime = new Date(endDate) - new Date(startDate)
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1
      if (startDate && !endDate) {
        diffDays = 5
      }

      console.log('deets: ', diffDays)
      if (diffDays < 0) {
        this.setState({ errorMessage: 'Please enter an end date after the start date'})
      } else if (diffDays > 31) {
        this.setState({ errorMessage: 'Time window is too large. Please enter a time window under a month.'})
      } else {

        const oneDay = 60 * 60 * 24 * 1000

        let cards = []

        function convertDay(numDay) {
          console.log('convert Day called', numDay, typeof numday)

          let stringDay = 'Sun'
          if (numDay === 1) {
            stringDay = 'Mon'
          } else if (numDay === 2) {
            stringDay = 'Tue'
          } else if (numDay === 3) {
            stringDay = 'Wed'
          } else if (numDay === 4) {
            stringDay = 'Thu'
          } else if (numDay === 5) {
            stringDay = 'Fri'
          } else if (numDay === 6) {
            stringDay = 'Sat'
          }

          return stringDay
        }

        for (let j = 0; j < (diffDays + 2); j++) {
          console.log('gimme adjust')
          const tempDate = new Date(new Date(startDate).getTime() + (oneDay * (j + 1)))

          const day = convertDay(tempDate.getDay())
          const month = Number(tempDate.getMonth()) + 1
          const numericDay = tempDate.getDate()
          const year = tempDate.getFullYear()

          const fullDate = day + ' ' + month + '/' + numericDay + '/' + year

          const hours = ''
          const tasks = ''
          cards.push({ day: fullDate, hours, tasks })
          console.log('show values: ', j, tempDate, fullDate, hours, tasks)

        }
        console.log('show cards: ', cards)

        let selectedTimesheet = this.state.selectedTimesheet
        if (selectedTimesheet) {
          selectedTimesheet['cards'] = cards
        }
        this.setState({ cards, selectedTimesheet })
      }

    }

    handleSubmit(action) {
      console.log('handleSubmit called', action)

      this.setState({ errorMessage: null, successMessage: null })

      if (this.state.roleName === 'Worker') {
        if (!this.state.selectedTimesheet.workerRating || this.state.selectedTimesheet.workerRating === '') {
          this.setState({ errorMessage: 'Please add a rating'})
        } else {
          for (let i = 1; i <= this.state.selectedTimesheet.cards.length; i++) {
            if (!this.state.selectedTimesheet.cards[i - 1].hours || this.state.selectedTimesheet.cards[i - 1].hours === '') {
              console.log('show hour: ', this.state.selectedTimesheet.cards[i - 1].hours)
              this.setState({ errorMessage: 'Please add hours to each row of the timesheet'})
              return
            } else if (this.state.selectedTimesheet.cards[i - 1].hours !== '0' && (!this.state.selectedTimesheet.cards[i - 1].tasks || this.state.selectedTimesheet.cards[i - 1].tasks === '')) {
              this.setState({ errorMessage: 'Please add tasks to each row of the timesheet'})
              return
            } else {
              console.log('we made it')
            }
          }

          const _id = this.state.selectedTimesheet._id
          const cards = this.state.selectedTimesheet.cards
          const workerRating = this.state.selectedTimesheet.workerRating
          const workerFeedback = this.state.selectedTimesheet.workerFeedback
          const assignments = this.state.selectedAssignments
          const projects = this.state.selectedProjects
          const isSubmitted = true

          const workId = this.state.selectedPlacement._id
          const workType = this.state.selectedPlacement.workType
          // const workerFirstName = this.state.selectedWorker.firstName
          // const workerLastName = this.state.selectedWorker.lastName
          // const workerEmail = this.state.selectedWorker.email
          // const managerFirstName = this.state.selectedPlacement.hiringManagerFirstName
          // const managerLastName = this.state.selectedPlacement.hiringManagerLastName
          // const managerEmail = this.state.selectedPlacement.hiringManagerEmail
          // const title = this.state.selectedPlacement.title
          const employerName = this.state.selectedPlacement.employerName
          const accountCode = this.state.selectedPlacement.accountCode

          // notification info
          const workerFirstName = this.state.selectedTimesheet.workerFirstName
          const workerLastName = this.state.selectedTimesheet.workerLastName
          const workerEmail = this.state.selectedTimesheet.emailId
          const emailId = workerEmail
          const managerFirstName = this.state.selectedTimesheet.managerFirstName
          const managerLastName = this.state.selectedTimesheet.managerLastName
          const managerEmail = this.state.selectedTimesheet.managerEmail
          const title = this.state.selectedTimesheet.title
          const roleName = this.state.roleName
          const startDate = this.state.selectedTimesheet.startDate
          const endDate = this.state.selectedTimesheet.endDate

          // let isSubmitted = false
          // if (window.location.pathname.endsWith('/take')) {
          //   isSubmitted = true
          // }

          const isSimulation = true

          const createdAt = new Date()
          const updatedAt = new Date()

          let tObject = {
            _id, cards, workerRating, workerFeedback, assignments, projects, isSubmitted, action, workId,
            workerFirstName, workerLastName, workerEmail, emailId, managerFirstName, managerLastName, managerEmail,
            workId, workType, employerName, accountCode, startDate, endDate,
            title, roleName, isSimulation, createdAt, updatedAt
          }

          // update timesheet
          Axios.put('/api/timesheets', tObject)
          .then((response) => {
            console.log('Timesheets update attempted', response.data);

              if (response.data.success) {
                console.log('timesheet query worked in worker')

                if (response.data._id) {
                  let selectedTimesheet = this.state.selectedTimesheet
                  selectedTimesheet['_id'] = response.data._id
                  this.setState({ selectedTimesheet })
                }

                if (action === 'submit') {
                  this.setState({ successMessage: 'Timesheet has been successfully submitted' })

                } else {
                  this.setState({ successMessage: 'Timesheet has been successfully saved' })
                }

                if (this.props.passStatus) {
                  this.props.passStatus(tObject, null)
                }

                if (this.props.closeModal) {
                  this.props.closeModal()
                }
              } else {
                console.log('timesheet query did not work on worker', response.data.message)
              }

          }).catch((error) => {
              console.log('Timesheet query did not work for some reason', error);
          });
        }
      } else if (this.state.roleName === 'Employer') {
        // employer

        const _id = this.state.selectedTimesheet._id
        const managerRating = this.state.selectedTimesheet.managerRating
        const managerFeedback = this.state.selectedTimesheet.managerFeedback

        let isApproved = false
        if (action === 'approve') {
          isApproved = true
        }

        let isRejected = false
        let isSubmitted = true
        if (action === 'reject') {
          isRejected = true
          isSubmitted = false
        }

        // notification info
        const workerFirstName = this.state.selectedTimesheet.workerFirstName
        const workerLastName = this.state.selectedTimesheet.workerLastName
        const workerEmail = this.state.selectedTimesheet.emailId
        const managerFirstName = this.state.selectedTimesheet.managerFirstName
        const managerLastName = this.state.selectedTimesheet.managerLastName
        const managerEmail = this.state.selectedTimesheet.managerEmail
        const title = this.state.selectedTimesheet.title
        const roleName = this.state.roleName

        const message = this.state.selectedTimesheet.message
        const updatedAt = new Date()

        let tObject = {
          _id, managerRating, managerFeedback, isApproved, isRejected, message, isSubmitted, action,
          workerFirstName, workerLastName, workerEmail, managerFirstName, managerLastName, managerEmail, title, roleName,
          updatedAt
        }

        // update timesheet
        Axios.put('/api/timesheets', tObject)
        .then((response) => {
          console.log('Timesheets update attempted', response.data);

            if (response.data.success) {
              console.log('timesheet query worked')

              this.props.passStatus(tObject, action)

              if (action === 'approve') {
                this.setState({ successMessage: 'Timesheet has been successfully approved. A notification has been sent out.' })
              } else {
                this.setState({ successMessage: 'Timesheet has been rejected.  A notification has been sent out.' })
              }

              this.props.closeModal()

            } else {
              console.log('timesheet query did not work on employer', response.data.message)
            }

        }).catch((error) => {
            console.log('Timesheet query did not work for some reason', error);
        });
      } else if (this.state.roleName === 'Admin') {

        if (!this.state.selectedPlacement) {
          this.setState({ errorMessage: 'Please select a placement' })
        } else if (!this.state.selectedWorker) {
          this.setState({ errorMessage: 'Please select a worker' })
        } else if (!this.state.startDate) {
          this.setState({ errorMessage: 'Please select a start date' })
        } else if (!this.state.endDate) {
          this.setState({ errorMessage: 'Please select an end date' })
        } else if (!this.state.cards) {
          this.setState({ errorMessage: 'Please add a valid time range, hours, and tasks.' })
        } else {
          for (let i = 1; i <= this.state.cards.length; i++) {
            if (!this.state.cards[i - 1].hours || this.state.cards[i - 1].hours === '') {
              this.setState({ errorMessage: 'Please add hours to each row of the timesheet'})
              return
            } else if (this.state.cards[i - 1].hours !== '0' && (!this.state.cards[i - 1].tasks || this.state.cards[i - 1].tasks === '')) {
              this.setState({ errorMessage: 'Please add tasks to each row of the timesheet'})
              return
            } else {
              console.log('we made it')
            }
          }

          const workId = this.state.selectedPlacement._id
          const workType = this.state.selectedPlacement.workType
          const workerFirstName = this.state.selectedWorker.firstName
          const workerLastName = this.state.selectedWorker.lastName
          const workerEmail = this.state.selectedWorker.email
          const managerFirstName = this.state.selectedPlacement.hiringManagerFirstName
          const managerLastName = this.state.selectedPlacement.hiringManagerLastName
          const managerEmail = this.state.selectedPlacement.hiringManagerEmail
          const title = this.state.selectedPlacement.title
          const employerName = this.state.selectedPlacement.employerName
          const accountCode = this.state.selectedPlacement.accountCode

          const startDate = this.state.startDate
          const endDate = this.state.endDate
          const cards = this.state.cards

          const workerRating = this.state.workerRating
          const workerFeedback = this.state.workerFeedback
          const managerRating = this.state.managerRating
          const managerFeedback = this.state.managerFeedback

          const isSubmitted = true
          const isApproved = true
          const isRejected = false

          const coordinatorEmail = this.state.emailId
          const orgCode = this.state.activeOrg

          const createdAt = new Date()
          const updatedAt = new Date()

          let tObject = {
            workId, workType, workerFirstName, workerLastName, workerEmail,
            managerFirstName, managerLastName, managerEmail,
            title, employerName, accountCode,
            startDate, endDate, cards,
            workerRating, workerFeedback, managerRating, managerFeedback,
            isSubmitted, isApproved, isRejected,
            orgCode, coordinatorEmail,
            createdAt, updatedAt
          }

          Axios.post('/api/timesheets', tObject)
          .then((response) => {
            console.log('Timesheets update attempted', response.data);

              if (response.data.success) {
                console.log('timesheet query worked')

                tObject['_id'] = response.data._id
                this.props.passStatus(tObject, null)
                this.setState({ successMessage: 'Successfully added the timesheet' })
                this.props.closeModal()

              } else {
                console.log('timesheet query did not work on admin', response.data.message)
              }

          }).catch((error) => {
              console.log('Timesheet query did not work for some reason', error);
          });

        }
      }
    }

    addItem(type) {
      console.log('addItem called ', type)

      if (type === 'assignment') {
        if (this.state.assignment) {
          let selectedAssignments = this.state.selectedAssignments
          console.log('about to check', selectedAssignments, this.state.assignment)
          if (selectedAssignments.length === 0 || selectedAssignments.some(assignment => assignment._id !== this.state.assignment._id)) {
            console.log('about to checked')
            selectedAssignments.push(this.state.assignment)
            let assignment = { name: ' ' }
            this.setState({ selectedAssignments, assignment })
          }
        }
      } else if (type === 'project') {
        if (this.state.project) {
          let selectedProjects = this.state.selectedProjects
          if (selectedProjects.length === 0 || selectedProjects.some(project => project._id !== this.state.project._id)) {

            selectedProjects.push(this.state.project)
            let project = { name: ' ' }
            this.setState({ selectedProjects, project })
          }
        }
      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'assignment') {
        let selectedAssignments = this.state.selectedAssignments
        selectedAssignments.splice(index, 1)
        this.setState({ selectedAssignments })
      } else if (type === 'project') {
        let selectedProjects = this.state.selectedProjects
        selectedProjects.splice(index, 1)
        this.setState({ selectedProjects })
      }
    }

    renderCards(cards) {
      console.log('renderCards called', cards)

      let isSubmitted = false
      if (this.state.selectedTimesheet && this.state.selectedTimesheet.isSubmitted) {
        isSubmitted = this.state.selectedTimesheet.isSubmitted
      }

      return (
        <div key="renderCards">
          {cards.map((value, index) =>
            <div key={"timesheet" + index} className="description-text-1">
              <div className="relative-column-25">
                <div className="spacer"/><div className="spacer"/><div className="spacer"/>
                <div className="right-padding">
                  <p>{value.day}</p>
                </div>
              </div>
              <div className="relative-column-15">
                <div className="spacer"/>
                {(this.state.roleName === 'Employer' || isSubmitted) ? (
                  <div className="row-10 right-padding">
                    <p>{value.hours}</p>
                  </div>
                ) : (
                  <div className="row-10 right-padding">
                    <input className="number-field" type="number" placeholder="Hours" name={"hours|" + index} value={value.hours} min="0" max="24" onChange={this.formChangeHandler} />
                  </div>
                )}
              </div>
              <div className="relative-column-60">
                <div className="spacer"/>
                {(this.state.roleName === 'Employer' || isSubmitted) ? (
                  <div className="row-10 right-padding">
                    <p>{value.tasks}</p>
                  </div>
                ) : (
                  <div className="row-10 right-padding">
                    <input className="text-field height-40" type="text" placeholder="(e.g., reviewed spreadsheet data for errors)" name={"tasks|" + index} value={value.tasks} onChange={this.formChangeHandler} />
                  </div>
                )}
              </div>
              <div className="clear" />
            </div>
          )}
        </div>
      )
    }

    itemClicked(type, item) {
      console.log('itemClicked called ', type, item)

      if (type === 'placement') {
        const placementOptions = []
        const selectedPlacement = item
        const placementName = item.title + ' ' + item.employerName

        let workerOptions = [{ firstName: '', lastName: '' }]
        let selectedWorker = { firstName: '', lastName: '' }
        if (selectedPlacement.workers) {
          workerOptions = workerOptions.concat(selectedPlacement.workers)
          selectedWorker = workerOptions[0]
        }

        this.setState({ placementOptions, selectedPlacement, placementName, workerOptions, selectedWorker,
          formHasChanged: true
        })
      }

    }

    deleteTimesheet() {
      console.log('deleteTimesheet called')

      const _id = this.state.selectedTimesheet._id

      Axios.delete('/api/timesheets/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('timesheet delete worked');

          this.props.passStatus(this.state.selectedTimesheet, null,true)
          this.props.closeModal()
          // this.props.navigate(-1)
          // this.setState({
          //   serverPostSuccess: true,
          //   serverSuccessMessage: 'Posting was deleted successfully! Once you exit, you will no longer have access to the posting.', confirmDelete: false
          // })

        } else {
          console.error('there was an error deleting the timesheet');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The timesheet deleting did not work', error);
          this.setState({ errorMessage: error })
      });
    }

    render() {

      return (
          <div>
            {(this.state.selectedTimesheet) ? (
              <div>
                <div>
                  {(this.state.roleName === 'Worker' || this.state.roleName === 'Admin') ? (
                    <div>
                      <div className="calc-column-offset-20 right-padding">
                        <p className="heading-text-2">{this.state.selectedTimesheet.title} @ {this.state.selectedTimesheet.employerName}</p>
                      </div>
                      {(this.props.closeModal) && (
                        <div className="fixed-column-20">
                          <button className="background-button top-margin" onClick={() => this.props.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-20" />
                          </button>
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  ) : (
                    <div>
                      <div className="calc-column-offset-20 right-padding">
                        <p className="heading-text-2">{this.state.selectedTimesheet.workerFirstName} {this.state.selectedTimesheet.workerLastName} Timesheet</p>
                      </div>
                      <div className="fixed-column-20">
                        <button className="background-button top-margin" onClick={() => this.props.closeModal()}>
                          <img src={closeIcon} alt="GC" className="image-auto-20" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>
                  )}

                  <div className="half-spacer" />
                  <p className="description-text-1">{(this.state.roleName === 'Employer') && this.state.selectedTimesheet.title + " "}Timesheet for {convertDateToString(new Date(this.state.selectedTimesheet.startDate),"date-2")} - {convertDateToString(new Date(this.state.selectedTimesheet.endDate),"date-2")}</p>
                </div>

                {(window.location.pathname.endsWith('/take') && !this.state.selectedTimesheet.isSubmitted) && (
                  <div>

                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <hr />

                    <div className="row-30 bottom-margin">
                      <div className="container-left">
                        <label className="profile-label">Select a Position<label className="error-color bold-text">*</label></label>
                        <select name="selectedPlacement" className="dropdown" value={(this.state.selectedPlacement) ? this.state.selectedPlacement.title : ""} onChange={this.formChangeHandler}>
                          {this.state.positionOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                        </select>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">Timesheet Start Date<label className="error-color bold-text">*</label></label>
                        <input type="date" className="date-picker" placeholder="Add the start date..." name="selectedTimesheet|startDate" value={this.state.selectedTimesheet.startDate} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <hr />
                  </div>
                )}

                {(this.state.roleName === 'Employer') && (
                  <div className="top-padding-5">
                    {(!this.state.selectedTimesheet.isApproved && !this.state.selectedTimesheet.isRejected) ? (
                      <div>
                        <p className="description-text-1 error-color">[Undecided]</p>
                      </div>
                    ) : (
                      <div>
                        {(this.state.selectedTimesheet.isApproved) ? (
                          <div>
                            <p className="cta-color description-text-1">[Approved]</p>
                          </div>
                        ) : (
                          <div>
                            <p className="error-color description-text-1">[Rejected]</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {((this.state.roleName === 'Worker' || this.state.roleName === 'Admin') && this.state.selectedTimesheet.isSubmitted) && (
                  <div className="row-10">
                    {(this.state.selectedTimesheet.isApproved || this.state.selectedTimesheet.isRejected) ? (
                      <div>
                        {(this.state.selectedTimesheet.isApproved) ? (
                          <p className="success-message">This timesheet has been approved!</p>
                        ) : (
                          <p className="error-message">This timesheet has been denied.</p>
                        )}
                      </div>
                    ) : (
                      <p className="error-message">You have submitted this timesheet. Awaiting feedback from manager.</p>
                    )}
                  </div>
                )}

                {((this.state.roleName === 'Worker' || this.state.roleName === 'Admin') && this.state.selectedTimesheet.message) && (
                  <div className="padding-20 standard-border">

                    <p>Message from manager:</p>
                    {(this.state.selectedTimesheet.isApproved) ? (
                      <p className="cta-color">{this.state.selectedTimesheet.message}</p>
                    ) : (
                      <p className="error-color">{this.state.selectedTimesheet.message}</p>
                    )}
                  </div>
                )}

                <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

                <div>
                  <div className="relative-column-25">
                    <p>Date</p>
                  </div>
                  <div className="relative-column-15">
                    <div>
                      <p>Hours<label className="error-color bold-text">*</label></p>
                    </div>
                  </div>
                  <div className="relative-column-60">
                    <p>Tasks<label className="error-color bold-text">*</label></p>
                  </div>
                </div>

                {this.renderCards(this.state.selectedTimesheet.cards)}

                {((this.state.roleName === 'Employer' && this.state.selectedTimesheet.assignments && this.state.selectedTimesheet.assignments.length > 0) || (this.state.roleName !== 'Employer' && !this.state.selectedTimesheet.isSubmitted)) && (
                  <div>
                    <div className="row-15">
                      <div className="relative-column-80">
                        {(this.state.roleName === 'Worker') ? (
                          <p>Tag assignments you worked on this week:</p>
                        ) : (
                          <p>Assignments they worked on this week:</p>
                        )}
                      </div>
                      <div className="clear" />

                      {(this.state.roleName === 'Worker') && (
                        <div>
                          <div className="spacer" />
                          <div className="calc-column-offset-100">
                            <select name="assignment" className="dropdown" value={this.state.assignment.name} onChange={this.formChangeHandler}>
                              {this.state.assignmentOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-90 left-padding">
                            <button className="btn btn-squarish description-text-1 full-width" onClick={() => this.addItem('assignment')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>

                    {(this.state.selectedAssignments && this.state.selectedAssignments.length > 0) && (
                      <div>
                        {this.state.selectedAssignments.map((value2, optionIndex) =>
                          <div key={"assignment|" + optionIndex} className="float-left">
                            {(this.state.roleName === 'Worker') && (
                              <div className="close-button-container-1">
                                <button className="background-button" onClick={() => this.removeItem('assignment', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                            )}
                            <div className="float-left row-5 right-padding">
                              <div className="tag-container-inactive">
                                <p className="description-text-2">{value2.name}</p>
                              </div>
                            </div>

                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>
                )}

                {((this.state.roleName === 'Employer' && this.state.selectedTimesheet.assignments && this.state.selectedTimesheet.assignments.length > 0) || (this.state.roleName !== 'Employer' && !this.state.selectedTimesheet.isSubmitted)) && (
                  <div>
                    <div className="row-15">
                      <div className="relative-column-80">

                        {(this.state.roleName === 'Worker') ? (
                          <p>Tag projects you worked on this week:</p>
                        ) : (
                          <p>Projects they worked on this week:</p>
                        )}
                      </div>

                      <div className="clear" />

                      {(this.state.roleName === 'Worker') && (
                        <div>
                          <div className="spacer" />

                          <div className="calc-column-offset-100">
                            <select name="project" className="dropdown" value={this.state.project.name} onChange={this.formChangeHandler}>
                                {this.state.projectOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-90 left-padding">
                            <button className="btn btn-squarish description-text-1 full-width" onClick={() => this.addItem('project')}>Add</button>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>

                    {(this.state.selectedProjects && this.state.selectedProjects.length > 0) && (
                      <div>
                        {this.state.selectedProjects.map((value2, optionIndex) =>
                          <div key={"project|" + optionIndex} className="float-left">
                            {(this.state.roleName === 'Worker') && (
                              <div className="close-button-container-1">
                                <button className="background-button" onClick={() => this.removeItem('project', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                            )}

                            <div className="float-left row-5 right-padding">
                              <div className="tag-container-inactive">
                                <p className="description-text-2">{value2.name}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>
                )}

                <div className="spacer" /><div className="spacer" />

                {((this.state.roleName === 'Worker' || this.state.roleName === 'Admin') && !this.state.selectedTimesheet.isSubmitted) ? (
                  <div>
                    <div className="relative-column-80 top-margin-5 right-padding">
                      <label>Rate this week. Any feedback for your employer? (Optional)</label>
                      <div className="clear" />
                    </div>

                    <div className="relative-column-20 right-padding row-5 top-margin-negative-5">
                      <select name="workerRating" className="dropdown" value={this.state.selectedTimesheet.workerRating} onChange={this.formChangeHandler}>
                        <option />
                        <option value="5">Great!</option>
                        <option value="4">Solid</option>
                        <option value="3">Average</option>
                        <option value="2">Not Great</option>
                        <option value="1">Terrible</option>
                        <option value="I'm Not Sure">I'm Not Sure</option>
                      </select>
                    </div>
                    <div className="clear" />
                    <div className="right-padding">
                      <textarea className="text-field" type="text" placeholder="What worked well? What could work better?" name="workerFeedback" value={this.state.selectedTimesheet.workerFeedback} onChange={this.formChangeHandler} />
                    </div>
                    <div className="spacer" /><div className="spacer" />
                  </div>
                ) : (
                  <div>

                  </div>
                )}

                {(this.state.roleName === 'Employer') && (
                  <div>
                    <label className="profile-label">Send a Message With Your Decision (Optional)</label>
                    <textarea className="text-field" type="text" placeholder="Why did you approve or reject this timesheet?" name="message" value={this.state.selectedTimesheet.message} onChange={this.formChangeHandler} />
                  </div>
                )}

                <div className="spacer" />

                {this.state.successMessage && <p className="success-message row-5">{this.state.successMessage}</p>}
                {this.state.errorMessage !== '' && <p className="error-message row-5">{this.state.errorMessage}</p>}

                <div>
                  <div className="float-left right-margin-15">
                    {(this.state.roleName === 'Worker' || this.state.roleName === 'Admin') ? (
                      <div>
                        {(this.state.selectedTimesheet.isSubmitted) ? (
                          <div>
                            {(this.state.selectedTimesheet.isApproved || this.state.selectedTimesheet.isRejected) ? (
                              <div>
                                {(this.state.selectedTimesheet.isApproved) ? (
                                  <p className="success-message">This timesheet has been approved!</p>
                                ) : (
                                  <p className="error-message">This timesheet has been denied.</p>
                                )}
                              </div>
                            ) : (
                              <p className="error-message">You have submitted this timesheet. Awaiting feedback from manager.</p>
                            )}

                            {(window.location.pathname.endsWith('/take') && this.state.selectedTimesheet.isSubmitted) && (
                              <div className='top-margin-20'>
                                {(this.state.confirmDelete) ? (
                                  <div>
                                    <p className="row-10">Are you sure you want to delete this timesheet?</p>

                                    <button className={(this.state.isSaving) ? "btn btn-squarish medium-background medium-border-color right-margin" : "btn btn-squarish error-background-color error-border right-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.deleteTimesheet()}>Yes, Delete Permanently</button>
                                    <button className="btn btn-squarish-white" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                                  </div>
                                ) : (
                                  <button className={(this.state.isSaving) ? "btn btn-quaternary medium-background medium-border-color" : "btn btn-quaternary"} disabled={(this.state.isSaving) && true} onClick={() => this.setState({ confirmDelete: true })}>Delete Timesheet</button>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <button className={(this.state.successMessage) ? "btn btn-primary medium-background medium-border-color" : "btn btn-primary"} disabled={(this.state.successMessage) && true} onClick={() => this.handleSubmit('submit')}>Submit</button>
                            <button className="btn btn-secondary left-margin" onClick={() => this.props.closeModal()}>Cancel</button>
                            {(window.location.pathname.includes('/organizations/')) && (
                              <button className={(this.state.isSaving) ? "btn btn-quaternary medium-background medium-border-color left-margin" : "btn btn-quaternary left-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.deleteTimesheet()}>Delete Timesheet</button>
                            )}
                          </div>
                        )}

                      </div>
                    ) : (
                      <div>
                        <button className={(this.state.successMessage) ? "btn btn-primary medium-background medium-border-color" : "btn btn-primary"} disabled={(this.state.successMessage) && true} onClick={() => this.handleSubmit('approve')}>Approve</button>
                        <button className={(this.state.successMessage) ? "btn btn-quaternary medium-background medium-border-color left-margin" : "btn btn-quaternary left-margin"} disabled={(this.state.successMessage) && true} onClick={() => this.handleSubmit('reject')}>Reject</button>
                        {(window.location.pathname.includes('/organizations/')) && (
                          <button className={(this.state.isSaving) ? "btn btn-quaternary medium-background medium-border-color left-margin" : "btn btn-quaternary left-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.deleteTimesheet()}>Delete Timesheet</button>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="clear" />
                </div>
              </div>
            ) : (
              <div>

                <p className="heading-text-2 row-10">New Timesheet</p>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Placement<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Search placements" name="placementName" value={this.state.placementName} onChange={this.formChangeHandler}></input>

                    {(this.state.animating) ? (
                      <div className="flex-container flex-center">
                        <div>
                          <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Loading results...</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {(this.state.placementOptions && this.state.placementOptions.length > 0) && (
                          <div>
                            <div className="spacer" />
                            {this.state.placementOptions.map(value =>
                              <div key={value._id} className="row-5">
                                <button className="background-button left-text" onClick={() => this.itemClicked('placement',value)}>
                                  <div>
                                    <div className="fixed-column-40">
                                      <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                      <img src={careerMatchesIconDark} alt="GC" className="image-auto-22" />
                                    </div>
                                    <div className="calc-column-offset-40">
                                        <p className="cta-color">{value.title} @ {value.employerName}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </button>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Worker<label className="error-color bold-text">*</label></label>
                    <select name="worker" className="dropdown" value={(this.state.selectedWorker && this.state.selectedWorker.firstName) ? this.state.selectedWorker.firstName + ' ' + this.state.selectedWorker.lastName : ""} onChange={this.formChangeHandler}>
                      {this.state.workerOptions.map(value => <option key={value.firstName + ' ' + value.lastName} value={value.firstName + ' ' + value.lastName}>{value.firstName + ' ' + value.lastName}</option>)}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" placeholder="Add the start date..." name="startDate" value={this.state.startDate} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" placeholder="Add the end date..." name="endDate" value={this.state.endDate} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.cards) && (
                  <div>
                    <div className="row-10">
                    </div>
                    {this.renderCards(this.state.cards)}

                    <div className="row-10">
                      <div className="container-left">
                        <label className="profile-label">Worker's rating for the period?</label>
                        <select name="workerRating" className="dropdown" value={this.state.workerRating} onChange={this.formChangeHandler}>
                          <option />
                          <option value="5">Great!</option>
                          <option value="4">Solid</option>
                          <option value="3">Average</option>
                          <option value="2">Not Great</option>
                          <option value="1">Terrible</option>
                          <option value="I'm Not Sure">I'm Not Sure</option>
                        </select>

                        <div className="row-10">
                          <label className="profile-label">Worker's feedback for the employer?</label>
                          <textarea className="text-field" type="text" placeholder="What worked well? What could work better?" name="workerFeedback" value={this.state.workerFeedback} onChange={this.formChangeHandler} />
                        </div>

                      </div>
                      <div className="container-right">
                        <div>
                          <label className="profile-label">Manager's rating for the period?</label>
                          <select name="managerRating" className="dropdown" value={this.state.managerRating} onChange={this.formChangeHandler}>
                            <option />
                            <option value="5">Great!</option>
                            <option value="4">Solid</option>
                            <option value="3">Average</option>
                            <option value="2">Not Great</option>
                            <option value="1">Terrible</option>
                            <option value="I'm Not Sure">I'm Not Sure</option>
                          </select>

                          <div className="row-10">
                            <label className="profile-label">Manager's feedback for the employer?</label>
                            <textarea className="text-field" type="text" placeholder="What worked well? What could work better?" name="managerFeedback" value={this.state.managerFeedback} onChange={this.formChangeHandler} />
                          </div>
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {this.state.successMessage && <p className="success-message row-5">{this.state.successMessage}</p>}
                {this.state.errorMessage !== '' && <p className="error-message row-5">{this.state.errorMessage}</p>}

                <div className="row-10 top-margin">
                  <button className={(this.state.successMessage) ? "btn btn-primary medium-background medium-border-color" : "btn btn-primary"} disabled={(this.state.successMessage) && true} onClick={() => this.handleSubmit('submit')}>Submit</button>
                  <button className="btn btn-secondary left-margin" onClick={() => this.props.closeModal()}>Cancel</button>
                  {(window.location.pathname.includes('/organizations/')) && (
                    <button className={(this.state.isSaving) ? "btn btn-quaternary medium-background medium-border-color left-margin" : "btn btn-quaternary left-margin"} disabled={(this.state.isSaving) && true} onClick={() => this.deleteTimesheet()}>Delete Timesheet</button>
                  )}
                </div>

              </div>
            )}
          </div>
      )
    }
}

export default withRouter(Timesheet);
