import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

/* eslint-disable import/first */

import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';

import FrontPage from './landingPages/FrontPage';
import MentorsPage from './landingPages/MentorsPage';
import WorkforcePage from './landingPages/WorkforcePage';
import IntermediariesPage from './landingPages/IntermediariesPage';
import SchoolsPage from './landingPages/SchoolsPage';
import StudentsPage from './landingPages/StudentsPage';
import EmployersPage from './landingPages/EmployersPage';
import SolutionsPage from './landingPages/SolutionsPage';
import AboutPage from './landingPages/AboutPage';
import WorkshopsPage from './landingPages/WorkshopsPage';
import JobsPage from './landingPages/JobsPage';
import GoalsPage from './landingPages/GoalsPage';
import ProjectsPage from './landingPages/ProjectsPage';
import ProfilesPage from './landingPages/ProfilesPage';
import OpportunitiesPage from './landingPages/OpportunitiesPage';
import OpportunityDetailsPage from './landingPages/OpportunityDetailsPage';
import PathwaysPage from './landingPages/PathwaysPage';
import EndorsePage from './landingPages/EndorsePage';
import IntegrationsPage from './landingPages/IntegrationsPage';
import IntegratePage from './landingPages/IntegratePage';
import MarketplacePage from './landingPages/MarketplacePage';
import MarketplaceProductPage from './landingPages/MarketplaceProductPage';
import EditBusinessIdeaPage from './landingPages/EditBusinessIdeaPage';
import BusinessIdeaDetailsPage from './landingPages/BusinessIdeaDetailsPage';
import CheckoutPage from './landingPages/CheckoutPage';
import HelpPage from './landingPages/HelpPage';
import HelpDetailPage from './landingPages/HelpDetailPage';
import ProfilePage from './landingPages/ProfilePage';
import ProjectDetailPage from './landingPages/ProjectDetailPage';
import ApplyPage from './landingPages/ApplyPage';
import EditProfilePage from './landingPages/EditProfilePage';
import TrendsPage from './landingPages/TrendsPage';
import CareersPage from './landingPages/CareersPage';
import FinancialsPage from './landingPages/FinancialsPage';
import BenchmarksPage from './landingPages/BenchmarksPage';
import CreateBenchmarkPage from './landingPages/CreateBenchmarkPage';
import CoursesPage from './landingPages/CoursesPage';
import CourseDetailsPage from './landingPages/CourseDetailsPage';
import TrainingProgramsPage from './landingPages/TrainingProgramsPage';
import EventsPage from './landingPages/EventsPage';
import ProjectOpportunitiesPage from './landingPages/ProjectOpportunitiesPage';
import WorkOpportunitiesPage from './landingPages/WorkOpportunitiesPage';
import CareerDetailsPage from './landingPages/CareerDetailsPage';
import PathwayDetailsPage from './landingPages/PathwayDetailsPage';
import BlogPage from './landingPages/BlogPage';
import BlogPostDetailPage from './landingPages/BlogPostDetailPage';
import ContactPage from './landingPages/ContactPage';
import ResetPasswordPage from './landingPages/ResetPasswordPage';
import PricingPage from './landingPages/PricingPage';
import PartnersPage from './landingPages/PartnersPage';
import PartnerPage from './landingPages/PartnerPage';
import PartnerStudentPage from './landingPages/PartnerStudentPage';
import PartnerEducatorPage from './landingPages/PartnerEducatorPage';
import PartnerMentorPage from './landingPages/PartnerMentorPage';
import PartnerEmployerPage from './landingPages/PartnerEmployerPage';
import CatalogPage from './landingPages/CatalogPage';

import ProblemPlatformPage from './landingPages/ProblemPlatformPage';
import AssignmentsPage from './landingPages/AssignmentsPage';
import ProblemsPage from './landingPages/ProblemsPage';
import ChallengesPage from './landingPages/ChallengesPage';
import AddProblemPage from './landingPages/AddProblemPage';
import ProblemDetailsPage from './landingPages/ProblemDetailsPage';
import NewsletterPage from './landingPages/NewsletterPage';
import ProblemPlatformSignUpPage from './landingPages/ProblemPlatformSignUpPage';
import NotificationPreferencesPage from './landingPages/NotificationPreferencesPage';
import NotificationSubscribePage from './landingPages/NotificationSubscribePage';
import TermsPage from './landingPages/TermsPage';
import PrivacyPolicyPage from './landingPages/PrivacyPolicyPage';
import ErrorPage from './landingPages/ErrorPage';
import EmployerDetailsPage from './landingPages/EmployerDetailsPage';
import BenchmarkDetailsPage from './landingPages/BenchmarkDetailsPage';
import OrgDetailsPage from './landingPages/OrgDetailsPage';
import AssistantPage from './landingPages/AssistantPage';
import PreviewPage from './landingPages/PreviewPage';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import StudentConfirmEmail from './components/StudentConfirmEmail';
import RemoteAuthConfig from './components/RemoteAuthConfig';

import EventRSVP from './components/EventRSVP';
import App from './components/App';
import Walkthrough from './components/Walkthrough';
import EditProfile from './components/EditProfile';
import EditProfileDetails from './components/EditProfileDetails';
import UserSocialPosts from './components/UserSocialPosts';
import Logs from './components/Logs';

import Completions from './components/Completions';
import Assessments from './components/Assessments';
import Trends from './components/Trends';
import Courses from './components/Courses';
import Social from './components/Social';
import Matches from './components/Matches';
import Exploration from './components/Exploration';
import MyIdeas from './components/MyIdeas';
import EditBusinessIdea from './components/EditBusinessIdea';
import BusinessIdeaDetails from './components/BusinessIdeaDetails';
import TrainingPrograms from './components/TrainingPrograms';
import Jobs from './components/Jobs';
import Favorites from './components/Favorites';
import Endorsements from './components/Endorsements';
import Opportunities from './components/Opportunities';
import ChangePassword from './components/ChangePassword';
import ChangeEmail from './components/ChangeEmail';
import MyCourses from './components/MyCourses';
import CourseDetails from './components/CourseDetails';
import TakeCourse from './components/TakeCourse';
import AdvisingServicesProduct from './components/AdvisingServicesProduct';
import Notifications from './components/Notifications';
import Profile from './components/Profile';
import Profiles from './components/Profiles';
import Projects from './components/Projects';
import Goals from './components/Goals';
import Employers from './components/Employers';
import Groups from './components/Groups';
import Messaging from './components/Messaging';
import Bookings from './components/Bookings';
import Settings from './components/Settings';

import NotificationPreferences from './components/NotificationPreferences';
import AddWorkspaces from './components/AddWorkspaces';
import GroupDetails from './components/GroupDetails';
import OpportunityDetails from './components/OpportunityDetails';
import AssessmentDetails from './components/AssessmentDetails';
import TakeAssessment from './components/TakeAssessment';
import CareerDetails from './components/CareerDetails';
import BenchmarkDetails from './components/BenchmarkDetails';
import PathwayDetails from './components/PathwayDetails';
import ProjectDetails from './components/ProjectDetails';

import EmployerDetails from './components/EmployerDetails';
import OrgDetails from './components/OrgDetails';
import Apply from './components/Apply';
import InviteFriendsForEndorsements from './components/InviteFriendsForEndorsements';
import ProvideEndorsement from './components/ProvideEndorsement';
import EditOffer from './components/EditOffer';
import NewLog from './components/NewLog';
import Documents from './components/Documents';
import EditDocument from './components/EditDocument';
import Builders from './components/Builders';
import Community from './components/Community';
import GoalDetails from './components/GoalDetails';
import OKRs from './components/OKRs';

import AdvProfile from './components/AdvProfile';

import AdvConfirmEmail from './components/AdvConfirmEmail';
import AdvDashboard from './components/AdvDashboard';
import AdvSignIn from './components/AdvSignIn';
import AdvSignUp from './components/AdvSignUp';
import AdvEditProfile from './components/AdvEditProfile';
import AdvEditProfileDetails from './components/AdvEditProfileDetails';
import AdvFavorites from './components/AdvFavorites';
import AdvSocialPosts from './components/AdvSocialPosts';
import AdvMembers from './components/AdvMembers';
import AdvAdvisees from './components/AdvAdvisees';
import AdvOpportunities from './components/AdvOpportunities';
import AdvSessions from './components/AdvSessions';
import AdvEditAssessment from './components/AdvEditAssessment';
import AdvEditResource from './components/AdvEditResource';
import AdvStudentEvaluation from './components/AdvStudentEvaluation';
import AdvProjects from './components/AdvProjects';
import AdvEditCourse from './components/AdvEditCourse';
import AdvEditCourseLesson from './components/AdvEditCourseLesson';
import AdvEditDocument from './components/AdvEditDocument';
import AdvSubmissions from './components/AdvSubmissions';
import AdvSubmissionDetails from './components/AdvSubmissionDetails';
import AdvCustomAssessments from './components/AdvCustomAssessments';
import AdvExchange from './components/AdvExchange';
import AdvPostings from './components/AdvPostings';
import AdvApplications from './components/AdvApplications';
import AdvApplicationDetail from './components/AdvApplicationDetail';
import AdvInterviewDetail from './components/AdvInterviewDetail';
import AdvTopCandidates from './components/AdvTopCandidates';
import AdvPlacements from './components/AdvPlacements';
import AdvBenchmarks from './components/AdvBenchmarks';
import AdvEndorsements from './components/AdvEndorsements';
import AdvNewsFeed from './components/AdvNewsFeed';
import AdvLessons from './components/AdvLessons';
import AdvCourses from './components/AdvCourses';
import AdvResources from './components/AdvResources';
import AdvGroups from './components/AdvGroups';
import AdvGroupDetails from './components/AdvGroupDetails';
import AdvCareerPaths from './components/AdvCareerPaths';
import AdvMatches from './components/AdvMatches';
import AdvMentors from './components/AdvMentors';
import AdvGoals from './components/AdvGoals';
import AdvTimesheets from './components/AdvTimesheets';
import AdvAddStudents from './components/AdvAddStudents';
import AdvAddOpportunity from './components/AdvAddOpportunity';
import AdvEditOpportunity from './components/AdvEditOpportunity';
import AdvEditPlacement from './components/AdvEditPlacement';
import AdvEditPosting from './components/AdvEditPosting';
import AdvEditBenchmark from './components/AdvEditBenchmark';
import AdvEditPathway from './components/AdvEditPathway';
import AdvAddMentor from './components/AdvAddMentor';
import AdvEditMentor from './components/AdvEditMentor';
import AdvAdviseesDetail from './components/AdvAdviseesDetail';
import AdvCareerDetails from './components/AdvCareerDetails';
import AdvSettings from './components/AdvSettings';
import AdvReporting from './components/AdvReporting';
import AdvSendEndorsement from './components/AdvSendEndorsement';
import AdvNewMessage from './components/AdvNewMessage';
import AdvMessaging from './components/AdvMessaging';
import AdvNotifications from './components/AdvNotifications';
import AdvChangePassword from './components/AdvChangePassword';
import AdvChangeEmail from './components/AdvChangeEmail';
import AdvAssessmentDetails from './components/AdvAssessmentDetails';
import AdvTakeAssessment from './components/AdvTakeAssessment';
import AdvSendReferral from './components/AdvSendReferral';
import AdvLogDetails from './components/AdvLogDetails';
import AdvEmployers from './components/AdvEmployers';
import AdvEmployerDetails from './components/AdvEmployerDetails';
import AdvOrgDetails from './components/AdvOrgDetails';
import AdvPathways from './components/AdvPathways';
import AdvScheduledMessages from './components/AdvScheduledMessages';
import AdvCommunity from './components/AdvCommunity';
import AdvLearning from './components/AdvLearning';
import AdvExploration from './components/AdvExploration';
import AdvBrowseProfiles from './components/AdvBrowseProfiles';
import AdvBrowseProjects from './components/AdvBrowseProjects';
import AdvBrowseGoals from './components/AdvBrowseGoals';
import AdvBrowseGroups from './components/AdvBrowseGroups';
import AdvBrowseEmployers from './components/AdvBrowseEmployers';
import AdvProjectDetails from './components/AdvProjectDetails';
import AdvAssessments from './components/AdvAssessments';
import AdvLogs from './components/AdvLogs';
import AdvEditLog from './components/AdvEditLog';
import AdvCourseDetails from './components/AdvCourseDetails';
import AdvTakeCourse from './components/AdvTakeCourse';
import AdvTrends from './components/AdvTrends';
import AdvDocuments from './components/AdvDocuments';
import AdvMatchMentor from './components/AdvMatchMentor';
import AdvGrades from './components/AdvGrades';

import OrgConfirmEmail from './components/Org/OrgConfirmEmail';
import OrgSignIn from './components/Org/OrgSignIn';
import OrgSignUp from './components/Org/OrgSignUp';
import OrgDashboard from './components/Org/OrgDashboard';
import OrgJobs from './components/Org/OrgJobs';
import OrgAssessments from './components/Org/OrgAssessments';
import OrgBenchmarks from './components/Org/OrgBenchmarks';
import OrgPathways from './components/Org/OrgPathways';
import OrgPrograms from './components/Org/OrgPrograms';
import OrgProjects from './components/Org/OrgProjects';
import OrgPlacements from './components/Org/OrgPlacements';
import OrgStudents from './components/Org/OrgStudents';
import OrgEducators from './components/Org/OrgEducators';
import OrgMentors from './components/Org/OrgMentors';
import OrgEmployers from './components/Org/OrgEmployers';
import OrgMessages from './components/Org/OrgMessages';
import OrgLearningModules from './components/Org/OrgLearningModules';
import OrgGoals from './components/Org/OrgGoals';
import OrgSessions from './components/Org/OrgSessions';
import OrgNewsFeed from './components/Org/OrgNewsFeed';
import OrgGroups from './components/Org/OrgGroups';
import OrgGroupDetails from './components/Org/OrgGroupDetails';
import OrgTimesheets from './components/Org/OrgTimesheets';
import OrgReporting from './components/Org/OrgReporting';
import OrgEndorsements from './components/Org/OrgEndorsements';
import OrgReferrals from './components/Org/OrgReferrals';
import OrgExchange from './components/Org/OrgExchange';
import OrgCourses from './components/Org/OrgCourses';
import OrgCourseDetails from './components/Org/OrgCourseDetails';
import OrgEditCourse from './components/Org/OrgEditCourse';
import OrgEditCourseLesson from './components/Org/OrgEditCourseLesson';
import OrgResources from './components/Org/OrgResources';
import OrgEditResource from './components/Org/OrgEditResource';
import OrgEditDocument from './components/Org/OrgEditDocument';
import OrgSettings from './components/Org/OrgSettings';
import OrgWalkthrough from './components/Org/OrgWalkthrough';
import OrgChangePassword from './components/Org/OrgChangePassword';
import OrgChangeEmail from './components/Org/OrgChangeEmail';
import OrgNotifications from './components/Org/OrgNotifications';
import OrgStudentEvaluation from './components/Org/OrgStudentEvaluation';
import OrgAddJob from './components/Org/OrgAddJob';
import OrgAddStudent from './components/Org/OrgAddStudent';
import OrgAddEducator from './components/Org/OrgAddEducator';
import OrgAddMentor from './components/Org/OrgAddMentor';
import OrgAddEmployer from './components/Org/OrgAddEmployer';
import OrgAddAdmins from './components/Org/OrgAddAdmins';
import OrgMembers from './components/Org/OrgMembers';
import OrgAddAssessment from './components/Org/OrgAddAssessment';
import OrgAddBenchmark from './components/Org/OrgAddBenchmark';
import OrgEditJob from './components/Org/OrgEditJob';
import OrgEditAssessment from './components/Org/OrgEditAssessment';
import OrgEditBenchmark from './components/Org/OrgEditBenchmark';
import OrgEditPathway from './components/Org/OrgEditPathway';
import OrgEditProgram from './components/Org/OrgEditProgram';
import OrgEditPlacement from './components/Org/OrgEditPlacement';
import OrgEditEmployer from './components/Org/OrgEditEmployer';
import OrgEditEducator from './components/Org/OrgEditEducator';
import OrgEditMentor from './components/Org/OrgEditMentor';
import OrgEditLog from './components/Org/OrgEditLog';
import OrgApplications from './components/Org/OrgApplications';
import OrgApplicationDetail from './components/Org/OrgApplicationDetail';
import OrgInterviewDetail from './components/Org/OrgInterviewDetail';
import OrgApplicationExternalScores from './components/Org/OrgApplicationExternalScores';
import OrgTopCandidates from './components/Org/OrgTopCandidates';
import OrgSendMessage from './components/Org/OrgSendMessage';
import OrgNewMessage from './components/Org/OrgNewMessage';
import OrgMemberDetail from './components/Org/OrgMemberDetail';
import OrgCareers from './components/Org/OrgCareers';
import OrgCareerDetails from './components/Org/OrgCareerDetails';
import OrgEmployerDetails from './components/Org/OrgEmployerDetails';
import OrgOrgDetails from './components/Org/OrgOrgDetails';
import OrgLogDetails from './components/Org/OrgLogDetails';
import OrgMessaging from './components/Org/OrgMessaging';
import OrgEditProfileDetails from './components/Org/OrgEditProfileDetails';
import OrgSendEndorsement from './components/Org/OrgSendEndorsement';
import OrgScheduledMessages from './components/Org/OrgScheduledMessages';
import OrgEditScheduledMessage from './components/Org/OrgEditScheduledMessage';
import OrgBulkUploader from './components/Org/OrgBulkUploader';
import OrgTakeCourse from './components/Org/OrgTakeCourse';
import OrgTickets from './components/Org/OrgTickets';
import OrgTrends from './components/Org/OrgTrends';
import OrgDocuments from './components/Org/OrgDocuments';
import OrgEditOrganizationProfile from './components/Org/OrgEditOrganizationProfile';
import OrgMatchMentor from './components/Org/OrgMatchMentor';
import OrgSubmissions from './components/Org/OrgSubmissions';
import OrgSubmissionDetails from './components/Org/OrgSubmissionDetails';

import EmpPost from './components/EmpPost';
import EmpConfirmEmail from './components/EmpConfirmEmail';
import EmpSignIn from './components/EmpSignIn';
import EmpSignUp from './components/EmpSignUp';
import EmpSignUpOrg from './components/EmpSignUpOrg';
import EmpDashboard from './components/EmpDashboard';
import EmpJobs from './components/EmpJobs';
import EmpAssessments from './components/EmpAssessments';
import EmpBenchmarks from './components/EmpBenchmarks';
import EmpPipelines from './components/EmpPipelines';
import EmpSocialPosts from './components/EmpSocialPosts';
import EmpHires from './components/EmpHires';
import EmpMessages from './components/EmpMessages';
import EmpReporting from './components/EmpReporting';
import EmpEditProfile from './components/EmpEditProfile';
import EmpEditEmployerProfile from './components/EmpEditEmployerProfile';
import EmpAddOrgs from './components/EmpAddOrgs';
import EmpSettings from './components/EmpSettings';
import EmpNotifications from './components/EmpNotifications';
import EmpWalkthrough from './components/EmpWalkthrough';
import EmpChangePassword from './components/EmpChangePassword';
import EmpChangeEmail from './components/EmpChangeEmail';
import EmpIntegrations from './components/EmpIntegrations';
import EmpAddJob from './components/EmpAddJob';
import EmpAddAdmins from './components/EmpAddAdmins';
import EmpAddAssessment from './components/EmpAddAssessment';
import EmpAddBenchmark from './components/EmpAddBenchmark';
import EmpEditJob from './components/EmpEditJob';
import EmpEditAssessment from './components/EmpEditAssessment';
import EmpEditBenchmark from './components/EmpEditBenchmark';
import EmpEditHire from './components/EmpEditHire';
import EmpPipelineDetails from './components/EmpPipelineDetails';
import EmpCommunities from './components/EmpCommunities';
import EmpGroupDetails from './components/EmpGroupDetails';
import EmpApplications from './components/EmpApplications';
import EmpApplicationDetail from './components/EmpApplicationDetail';
import EmpInterviewDetail from './components/EmpInterviewDetail';
import EmpTopCandidates from './components/EmpTopCandidates';
import EmpCareerSeekerDetail from './components/EmpCareerSeekerDetail';
import EmpSendMessage from './components/EmpSendMessage';
import EmpOrgSignUp from './components/EmpOrgSignUp';
import EmpOrgDetails from './components/EmpOrgDetails';

import TagManager from 'react-gtm-module';

import './css/style.css';
import './css/queries.css';
import './vendors/css/normalize.css';
import './vendors/css/grid.css';

import 'react-tooltip/dist/react-tooltip.css'
import 'react-circular-progressbar/dist/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<FrontPage/>} />
          <Route path="/chat" element={<FrontPage/>} />
          <Route path="/chatgpt" element={<FrontPage/>} />
          <Route path="/mentors" element={<MentorsPage/>} />
          <Route path="/workforce" element={<WorkforcePage/>} />
          <Route path="/educators" element={<SchoolsPage/>} />
          <Route path="/intermediaries" element={<IntermediariesPage/>} />
          <Route path="/career-seekers" element={<StudentsPage/>} />
          <Route path="/employers" element={<EmployersPage/>} />
          <Route path="/benefits/transparent-pathways" element={<SolutionsPage/>} />
          <Route path="/benefits/self-awareness" element={<SolutionsPage/>} />
          <Route path="/benefits/career-awareness" element={<SolutionsPage/>} />
          <Route path="/benefits/career-exploration" element={<SolutionsPage/>} />
          <Route path="/benefits/career-planning" element={<SolutionsPage/>} />
          <Route path="/benefits/project-based-learning" element={<SolutionsPage/>} />
          <Route path="/benefits/work" element={<SolutionsPage/>} />
          <Route path="/benefits/mentorship" element={<SolutionsPage/>} />
          <Route path="/benefits/community" element={<SolutionsPage/>} />
          <Route path="/benefits/learning" element={<SolutionsPage/>} />
          <Route path="/benefits/financial-planning" element={<SolutionsPage/>} />
          <Route path="/benefits/entrepreneurship" element={<SolutionsPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/workshops" element={<WorkshopsPage/>} />
          <Route path="/jobs" element={<JobsPage/>} />
          <Route path="/goals" element={<GoalsPage/>} />
          <Route path="/goals/:id" element={<GoalsPage/>} />
          <Route path="/goals/organizations/:orgCode" element={<GoalsPage/>} />
          <Route path="/projects" element={<ProjectsPage/>} />
          <Route path="/projects/organizations/:orgCode" element={<ProjectsPage/>} />
          <Route path="/profiles" element={<ProfilesPage/>} />
          <Route path="/profiles/organizations/:orgCode" element={<ProfilesPage/>} />
          <Route path="/opportunities" element={<OpportunitiesPage/>} />
          <Route path="/opportunities/organizations/:orgCode" element={<OpportunitiesPage/>} />
          <Route path="/opportunities/employers/:accountCode" element={<OpportunitiesPage/>} />
          <Route path="/opportunities/:opportunityId" element={<OpportunityDetailsPage/>} />
          <Route path="/opportunities/organizations/:orgCode/:opportunityId" element={<OpportunityDetailsPage/>} />
          <Route path="/opportunities/organizations/:orgCode/:opportunityId/roles/:roleName" element={<OpportunityDetailsPage/>} />
          <Route path="/opportunities/organizations/:orgCode/:opportunityId/referral/:referralCode" element={<OpportunityDetailsPage/>} />
          <Route path="/schools/catalog" element={<CatalogPage/>} />

          <Route path="/opportunities/:opportunityId/apply" element={<ApplyPage/>} />
          <Route path="/opportunities/organizations/:orgCode/:opportunityId/apply" element={<ApplyPage/>} />
          <Route path="/opportunities/organizations/:orgCode/roles/:targetRole" element={<OpportunitiesPage/>} />
          <Route path="/opportunities/organizations/:orgCode/roles/:targetRole/types/:subPostType" element={<OpportunitiesPage/>} />
          <Route path="/pathways" element={<PathwaysPage/>} />
          <Route path="/pathways/organizations/:orgCode" element={<PathwaysPage/>} />
          <Route path="/endorse" element={<EndorsePage/>} />
          <Route path="/endorse/:requestId" element={<EndorsePage/>} />
          <Route path="/endorse/organizations/:orgCode" element={<EndorsePage/>} />
          <Route path="/integrations" element={<IntegrationsPage/>} />
          <Route path="/:serviceId/integrate" element={<IntegratePage/>} />
          <Route path="/marketplace" element={<MarketplacePage/>} />
          <Route path="/business-ideas" element={<MarketplacePage/>} />
          <Route path="/marketplace/:slug" element={<MarketplaceProductPage/>} />
          <Route path="/edit-business-ideas/:id" element={<EditBusinessIdeaPage/>} />
          <Route path="/business-ideas/:id" element={<BusinessIdeaDetailsPage/>} />
          <Route path="/help" element={<HelpPage/>} />
          <Route path="/help/:slug" element={<HelpDetailPage/>} />
          <Route path="/:roleName/checkout" element={<CheckoutPage/>} />
          <Route path="/subscribe/:roleName" element={<CheckoutPage/>} />
          <Route path="/problem-platform/profile" element={<EditProfilePage/>} />
          <Route path="/:username/profile" element={<ProfilePage/>} />
          <Route path="/:username/projects" element={<ProfilePage/>} />
          <Route path="/:username/endorsements/:id/share" element={<ProfilePage/>} />
          <Route path="/projects/:id" element={<ProjectDetailPage/>} />
          <Route path="/trends" element={<TrendsPage/>} />
          <Route path="/resources" element={<TrendsPage/>} />
          <Route path="/careers" element={<CareersPage/>} />
          <Route path="/financials" element={<FinancialsPage/>} />
          <Route path="/benchmarks" element={<BenchmarksPage/>} />
          <Route path="/benchmarks/:orgCode/create" element={<CreateBenchmarkPage/>} />
          <Route path="/courses" element={<CoursesPage/>} />
          <Route path="/courses/:id" element={<CourseDetailsPage/>} />
          <Route path="/courses/:id/organizations/:orgCode" element={<CourseDetailsPage/>} />
          <Route path="/training-programs" element={<TrainingProgramsPage/>} />
          <Route path="/events" element={<EventsPage/>} />
          <Route path="/project-opportunities" element={<ProjectOpportunitiesPage/>} />
          <Route path="/work-opportunities" element={<WorkOpportunitiesPage/>} />
          <Route path="/careers/:careerSelected" element={<CareerDetailsPage/>} />
          <Route path="/pathways/:pathwayId" element={<PathwayDetailsPage/>} />
          <Route path="/blog" element={<BlogPage/>} />
          <Route path="/blog/:title" element={<BlogPostDetailPage/>} />
          <Route path="/whitepapers" element={<BlogPage/>} />
          <Route path="/whitepapers/:id" element={<BlogPostDetailPage/>} />
          <Route path="/reset-password" element={<ResetPasswordPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/contact/:reason" element={<ContactPage/>} />
          <Route path="/contact/:reason/:body" element={<ContactPage/>} />
          <Route path="/pricing" element={<PricingPage/>} />
          <Route path="/partners" element={<PartnersPage/>} />
          <Route path="/partners/:partner" element={<PartnerPage/>} />
          <Route path="/partners/:partner/students" element={<PartnerStudentPage/>} />
          <Route path="/partners/:partner/career-seekers" element={<PartnerStudentPage/>} />
          <Route path="/partners/:partner/educators" element={<PartnerEducatorPage/>} />
          <Route path="/partners/:partner/mentors" element={<PartnerMentorPage/>} />
          <Route path="/partners/:partner/employers" element={<PartnerEmployerPage/>} />
          <Route path="/problem-platform" element={<ProblemPlatformPage/>} />
          <Route path="/problem-platform/assignments" element={<AssignmentsPage/>} />
          <Route path="/problem-platform/problems" element={<ProblemsPage/>} />
          <Route path="/problem-platform/challenges" element={<ChallengesPage/>} />
          <Route path="/problem-platform/add" element={<AddProblemPage/>} />
          <Route path="/problem-platform/add/:org" element={<AddProblemPage/>} />
          <Route path="/problem-platform/join" element={<ProblemPlatformSignUpPage/>} />
          <Route path="/problem-platform/signin" element={<ProblemPlatformSignUpPage/>} />
          <Route path="/problem-platform/:item/checkout" element={<ProblemPlatformSignUpPage/>} />
          <Route path="/problem-platform/:item" element={<ProblemDetailsPage/>} />
          <Route path="/newsletter" element={<NewsletterPage/>} />
          <Route path="/notification-preferences/:orgCode/:emailId" element={<NotificationPreferencesPage/>} />
          <Route path="/notification-preferences/types/:notificationType/:emailId" element={<NotificationPreferencesPage/>} />
          <Route path="/notification-preferences/types/:notificationType/:emailId/:slug" element={<NotificationPreferencesPage/>} />
          <Route path="/email-subscriptions/preferences/:notificationType/:emailId" element={<NotificationPreferencesPage/>} />
          <Route path="/email-subscriptions/preferences/:emailId" element={<NotificationPreferencesPage/>} />
          <Route path="/notification-subscriptions/:subscriptionType/:orgCode/subscribe" element={<NotificationSubscribePage/>} />
          <Route path="/terms" element={<TermsPage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
          <Route path="/error/:errorString" element={<ErrorPage/>} />
          <Route path="/employers/pages/:accountCode" element={<EmployerDetailsPage/>} />
          <Route path="/employers/pages/:accountCode/benchmarks/:benchmarkId" element={<BenchmarkDetailsPage/>} />
          <Route path="/organizations/pages/:orgSelected" element={<OrgDetailsPage/>} />
          <Route path="/assistant" element={<AssistantPage/>} />
          <Route path="/:previewType/preview/:id" element={<PreviewPage/>} />

          <Route path="/signup" element={<SignUp/>} />
          <Route path="/join" element={<SignUp/>} />
          <Route path="/schools/:orgCode/:roleName/join" element={<SignUp/>} />
          <Route path="/schools/:orgCode/:roleName/signup" element={<SignUp/>} />
          <Route path="/schools/:orgCode/courses/:courseId/:roleName/signup" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/:roleName/join" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/:roleName/signup" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/courses/:courseId/:roleName/join" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/courses/:courseId/:roleName/signup" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/work/:workId/:roleName/join" element={<SignUp/>} />
          <Route path="/organizations/:orgCode/work/:workId/:roleName/signup" element={<SignUp/>} />

          <Route path="/signin" element={<SignIn/>} />
          <Route path="/schools/:orgCode/:roleName/signin" element={<SignIn/>} />
          <Route path="/schools/:orgCode/courses/:courseId/:roleName/signin" element={<SignIn/>} />
          <Route path="/organizations/:orgCode/:roleName/signin" element={<SignIn/>} />
          <Route path="/organizations/:orgCode/courses/:courseId/:roleName/signin" element={<SignIn/>} />
          <Route path="/organizations/:orgCode/work/:workId/:roleName/signin" element={<SignIn/>} />

          <Route path="/app/confirm-email/:orgCode/:email" element={<StudentConfirmEmail/>} />
          <Route path="/app/confirm-email/:orgCode/:email/:confirmed" element={<StudentConfirmEmail/>} />
          <Route path="/app/remote-auth-config/:oauthUid" element={<RemoteAuthConfig/>} />

          <Route path="/schools/:orgCode/events/:eventId/:roleName/rsvp" element={<EventRSVP/>} />
          <Route path="/schools/:orgCode/events/:eventId/rsvp" element={<EventRSVP/>} />
          <Route path="/organizations/:orgCode/events/:eventId/rsvp" element={<EventRSVP/>} />
          <Route path="/organizations/:orgCode/events/:eventId/:roleName/rsvp" element={<EventRSVP/>} />
          <Route path="/app" element={<App/>} />
          <Route path="/app/dashboard" element={<App/>} />
          <Route path="/app/home" element={<App/>} />
          <Route path="/app/social-posts/:postId" element={<App/>} />
          <Route path="/app/edit-profile" element={<EditProfile/>} />
          <Route path="/app/edit-profile/basics" element={<EditProfileDetails/>} />
          <Route path="/app/edit-profile/profile-details" element={<EditProfileDetails/>} />
          <Route path="/app/edit-profile/public-preferences" element={<EditProfileDetails/>} />
          <Route path="/app/walkthrough" element={<Walkthrough/>} />
          <Route path="/app/my-social-posts/:postUsername" element={<UserSocialPosts/>} />
          <Route path="/app/logs" element={<Logs/>} />
          <Route path="/app/completions" element={<Completions/>} />
          <Route path="/app/assessments" element={<Assessments/>} />
          <Route path="/app/matches" element={<Matches/>} />
          <Route path="/app/exploration" element={<Exploration/>} />
          <Route path="/app/favorites" element={<Favorites/>} />
          <Route path="/app/endorsements" element={<Endorsements/>} />
          <Route path="/app/my-ideas" element={<MyIdeas/>} />
          <Route path="/app/edit-business-ideas/:id" element={<EditBusinessIdea/>} />
          <Route path="/app/business-ideas/:id" element={<BusinessIdeaDetails/>} />
          <Route path="/app/trends" element={<Trends/>} />
          <Route path="/app/courses" element={<Courses/>} />
          <Route path="/app/learning" element={<Courses/>} />
          <Route path="/app/social" element={<Social/>} />
          <Route path="/app/training-programs" element={<TrainingPrograms/>} />
          <Route path="/app/jobs" element={<Jobs/>} />
          <Route path="/app/opportunities" element={<Opportunities/>} />
          <Route path="/app/change-password" element={<ChangePassword/>} />
          <Route path="/app/change-email" element={<ChangeEmail/>} />
          <Route path="/app/notifications" element={<Notifications/>} />
          <Route path="/app/profile/:username" element={<Profile/>} />
          <Route path="/app/profile/:username/accept" element={<Profile/>} />
          <Route path="/app/browse-profiles" element={<Profiles/>} />
          <Route path="/app/browse-projects" element={<Projects/>} />
          <Route path="/app/browse-goals" element={<Goals/>} />
          <Route path="/app/browse-employers" element={<Employers/>} />
          <Route path="/app/browse-groups" element={<Groups/>} />
          <Route path="/app/messaging" element={<Messaging/>} />
          <Route path="/app/messaging/thread/:threadId" element={<Messaging/>} />
          <Route path="/app/bookings" element={<Bookings/>} />
          <Route path="/app/settings" element={<Settings/>} />
          <Route path="/app/notification-preferences" element={<NotificationPreferences/>} />
          <Route path="/app/learning/:id" element={<Courses/>} />
          <Route path="/app/advising-services/:slug" element={<AdvisingServicesProduct/>} />
          <Route path="/app/add-workspaces" element={<AddWorkspaces/>} />
          <Route path="/app/add-communities" element={<AddWorkspaces/>} />
          <Route path="/app/add-workspaces/:orgCode" element={<AddWorkspaces/>} />
          <Route path="/app/add-workspaces/:orgCode/:opportunityId" element={<AddWorkspaces/>} />
          <Route path="/app/groups/:groupId" element={<GroupDetails/>} />
          <Route path="/app/opportunities/:opportunityId" element={<OpportunityDetails/>} />
          <Route path="/app/opportunities/:opportunityId/referral/:referralCode" element={<OpportunityDetails/>} />
          <Route path="/app/assessments/assessment-details" element={<AssessmentDetails/>} />
          <Route path="/app/assessments/assessment-details/:assessmentTitle" element={<TakeAssessment/>} />
          <Route path="/app/careers/:careerSelected" element={<CareerDetails/>} />
          <Route path="/app/employers/:accountCode/benchmarks/:benchmarkId" element={<BenchmarkDetails/>} />
          <Route path="/app/projects/:projectId" element={<ProjectDetails/>} />
          <Route path="/app/employers/:employerSelected" element={<EmployerDetails/>} />
          <Route path="/app/accounts/:accountCode" element={<EmployerDetails/>} />
          <Route path="/app/orgs/:orgSelected" element={<OrgDetails/>} />
          <Route path="/app/postings/:postingSelected/apply" element={<Apply/>} />
          <Route path="/app/benchmarks/:benchmarkId" element={<BenchmarkDetails/>} />
          <Route path="/app/pathways/:pathwayId" element={<PathwayDetails/>} />
          <Route path="/app/courses/:id" element={<CourseDetails/>} />
          <Route path="/app/courses/:id/take" element={<TakeCourse/>} />
          <Route path="/app/mentors/:username" element={<AdvProfile/>} />
          <Route path="/app/endorsements/get-endorsements" element={<InviteFriendsForEndorsements/>} />
          <Route path="/app/endorsements/send-endorsement" element={<ProvideEndorsement/>} />
          <Route path="/app/offers/:offerSelected" element={<EditOffer/>} />
          <Route path="/app/edit-goals/:logId" element={<NewLog/>} />
          <Route path="/app/logs/:logId" element={<NewLog/>} />
          <Route path="/app/documents" element={<Documents/>} />
          <Route path="/app/documents/:id" element={<EditDocument/>} />
          <Route path="/app/builders" element={<Builders/>} />
          <Route path="/app/builders/:id" element={<Builders/>} />
          <Route path="/app/community" element={<Community/>} />
          <Route path="/app/goals/:goalId" element={<GoalDetails/>} />
          <Route path="/app/okrs" element={<OKRs/>} />
          <Route path="/app/my-courses" element={<MyCourses/>} />

          <Route path="/advisor/confirm-email/:orgCode/:email" element={<AdvConfirmEmail/>} />
          <Route path="/advisor/confirm-email/:orgCode/:email/:confirmed" element={<AdvConfirmEmail/>} />
          <Route path="/advisor" element={<AdvDashboard/>} />
          <Route path="/advisor/dashboard" element={<AdvDashboard/>} />
          <Route path="/advisor/signin" element={<AdvSignIn/>} />
          <Route path="/advisor/signup" element={<AdvSignUp/>} />
          <Route path="/advisor/join" element={<AdvSignUp/>} />
          <Route path="/advisor/edit-profile" element={<AdvEditProfile/>} />
          <Route path="/advisor/edit-profile/basics" element={<AdvEditProfileDetails/>} />
          <Route path="/advisor/edit-profile/profile-details" element={<AdvEditProfileDetails/>} />
          <Route path="/advisor/edit-profile/public-preferences" element={<AdvEditProfileDetails/>} />
          <Route path="/advisor/favorites" element={<AdvFavorites/>} />
          <Route path="/advisor/my-social-posts/:postUsername" element={<AdvSocialPosts/>} />
          <Route path="/advisor/members" element={<AdvMembers/>} />
          <Route path="/advisor/advisees" element={<AdvAdvisees/>} />
          <Route path="/advisor/sessions" element={<AdvSessions/>} />
          <Route path="/advisor/students/:userId/evaluations" element={<AdvStudentEvaluation/>} />
          <Route path="/advisor/members/:userId/evaluations" element={<AdvStudentEvaluation/>} />
          <Route path="/advisor/quizzes" element={<AdvCustomAssessments/>} />
          <Route path="/advisor/exchange" element={<AdvExchange/>} />
          <Route path="/advisor/exchange/:id" element={<AdvExchange/>} />
          <Route path="/advisor/postings" element={<AdvPostings/>} />
          <Route path="/advisor/postings/:id/applications" element={<AdvApplications/>} />
          <Route path="/advisor/postings/:id/applications/:candidateId" element={<AdvApplicationDetail/>} />
          <Route path="/advisor/postings/:id/applications/:candidateId/interview" element={<AdvInterviewDetail/>} />
          <Route path="/advisor/postings/:id/top-candidates" element={<AdvTopCandidates/>} />
          <Route path="/advisor/postings/:id/messages/:username" element={<AdvNewMessage/>} />
          <Route path="/advisor/placements" element={<AdvPlacements/>} />
          <Route path="/advisor/benchmarks" element={<AdvBenchmarks/>} />
          <Route path="/advisor/endorsements" element={<AdvEndorsements/>} />
          <Route path="/advisor/news-feed" element={<AdvNewsFeed/>} />
          <Route path="/advisor/learning-modules" element={<AdvLessons/>} />
          <Route path="/advisor/courses" element={<AdvCourses/>} />
          <Route path="/advisor/resources" element={<AdvResources/>} />
          <Route path="/advisor/groups" element={<AdvGroups/>} />
          <Route path="/advisor/groups/:groupId" element={<AdvGroupDetails/>} />
          <Route path="/advisor/careers" element={<AdvCareerPaths/>} />
          <Route path="/advisor/matches" element={<AdvMatches/>} />
          <Route path="/advisor/mentors" element={<AdvMentors/>} />
          <Route path="/advisor/goals" element={<AdvGoals/>} />
          <Route path="/advisor/timesheets" element={<AdvTimesheets/>} />
          <Route path="/advisor/career-seekers/add" element={<AdvAddStudents/>} />
          <Route path="/advisor/opportunities/add" element={<AdvAddOpportunity/>} />
          <Route path="/advisor/opportunities/:postingId" element={<AdvEditOpportunity/>} />
          <Route path="/advisor/postings/:postingId" element={<AdvEditPosting/>} />
          <Route path="/advisor/placements/:id" element={<AdvEditPlacement/>} />
          <Route path="/advisor/benchmarks/:id" element={<AdvEditBenchmark/>} />
          <Route path="/advisor/pathways/:id" element={<AdvEditPathway/>} />
          <Route path="/advisor/mentors/add" element={<AdvAddMentor/>} />
          <Route path="/advisor/mentors/:mentorId" element={<AdvEditMentor/>} />
          <Route path="/advisor/assessments/:id" element={<AdvEditAssessment/>} />
          <Route path="/advisor/resources/:id" element={<AdvEditResource/>} />
          <Route path="/advisor/endorsements/requests/:requestId" element={<AdvSendEndorsement/>} />
          <Route path="/advisor/endorsements/:endorsementId" element={<AdvSendEndorsement/>} />
          <Route path="/advisor/profile/:username" element={<AdvProfile/>} />
          <Route path="/advisor/opportunities" element={<AdvOpportunities/>} />
          <Route path="/advisor/advisees/:adviseeEmail" element={<AdvAdviseesDetail/>} />
          <Route path="/advisor/members/:adviseeEmail" element={<AdvAdviseesDetail/>} />
          <Route path="/advisor/careers/:careerSelected" element={<AdvCareerDetails/>} />
          <Route path="/advisor/messages/:userId" element={<AdvNewMessage/>} />
          <Route path="/advisor/settings" element={<AdvSettings/>} />
          <Route path="/advisor/reporting" element={<AdvReporting/>} />
          <Route path="/advisor/messaging" element={<AdvMessaging/>} />
          <Route path="/advisor/messaging/thread/:threadId" element={<AdvMessaging/>} />
          <Route path="/advisor/notifications" element={<AdvNotifications/>} />
          <Route path="/advisor/settings/change-password" element={<AdvChangePassword/>} />
          <Route path="/advisor/settings/change-email" element={<AdvChangeEmail/>} />
          <Route path="/advisor/career-assessments/assessment-details" element={<AdvAssessmentDetails/>} />
          <Route path="/advisor/career-assessments/assessment-details/:assessmentTitle" element={<AdvTakeAssessment/>} />
          <Route path="/advisor/refer/send" element={<AdvSendReferral/>} />
          <Route path="/advisor/logs/:logSelected" element={<AdvLogDetails/>} />
          <Route path="/advisor/employers" element={<AdvEmployers/>} />
          <Route path="/advisor/employers/:employerSelected" element={<AdvEmployerDetails/>} />
          <Route path="/advisor/orgs/:orgSelected" element={<AdvOrgDetails/>} />
          <Route path="/advisor/pathways" element={<AdvPathways/>} />
          <Route path="/advisor/message-blasts" element={<AdvScheduledMessages/>} />
          <Route path="/advisor/community" element={<AdvCommunity/>} />
          <Route path="/advisor/learning" element={<AdvLearning/>} />
          <Route path="/advisor/exploration" element={<AdvExploration/>} />
          <Route path="/advisor/browse-profiles" element={<AdvBrowseProfiles/>} />
          <Route path="/advisor/browse-projects" element={<AdvBrowseProjects/>} />
          <Route path="/advisor/browse-goals" element={<AdvBrowseGoals/>} />
          <Route path="/advisor/browse-groups" element={<AdvBrowseGroups/>} />
          <Route path="/advisor/browse-employers" element={<AdvBrowseEmployers/>} />
          <Route path="/advisor/projects/:projectId" element={<AdvProjectDetails/>} />
          <Route path="/advisor/assessments" element={<AdvAssessments/>} />
          <Route path="/advisor/logs" element={<AdvLogs/>} />
          <Route path="/advisor/edit-goals/:logId" element={<AdvEditLog/>} />
          <Route path="/advisor/edit-logs/:logId" element={<AdvEditLog/>} />
          <Route path="/advisor/courses/:id" element={<AdvCourseDetails/>} />
          <Route path="/advisor/courses/:id/take" element={<AdvTakeCourse/>} />
          <Route path="/advisor/trends" element={<AdvTrends/>} />
          <Route path="/advisor/documents" element={<AdvDocuments/>} />
          <Route path="/advisor/members/match/:id" element={<AdvMatchMentor/>} />
          <Route path="/advisor/projects" element={<AdvProjects/>} />
          <Route path="/advisor/edit-courses/:id" element={<AdvEditCourse/>} />
          <Route path="/advisor/edit-courses/:id/lesson" element={<AdvEditCourseLesson/>} />
          <Route path="/advisor/documents/:id" element={<AdvEditDocument/>} />
          <Route path="/advisor/submissions" element={<AdvSubmissions/>} />
          <Route path="/advisor/submissions/:id" element={<AdvSubmissionDetails/>} />
          <Route path="/advisor/grades" element={<AdvGrades/>} />

          <Route path="/organizations/:org/confirm-email/:orgCode/:email" element={<OrgConfirmEmail/>} />
          <Route path="/organizations/:org/confirm-email/:orgCode/:email/:confirmed" element={<OrgConfirmEmail/>} />
          <Route path="/organizations/:org/signin" element={<OrgSignIn/>} />
          <Route path="/organizations/:org/signup" element={<OrgSignUp/>} />
          <Route path="/organizations/:org/join" element={<OrgSignUp/>} />
          <Route path="/organizations/:org/dashboard" element={<OrgDashboard/>} />
          <Route path="/organizations/:org/postings" element={<OrgJobs/>} />
          <Route path="/organizations/:org/assessments" element={<OrgAssessments/>} />
          <Route path="/organizations/:org/benchmarks" element={<OrgBenchmarks/>} />
          <Route path="/organizations/:org/pathways" element={<OrgPathways/>} />
          <Route path="/organizations/:org/programs" element={<OrgPrograms/>} />
          <Route path="/organizations/:org/projects" element={<OrgProjects/>} />
          <Route path="/organizations/:org/placements" element={<OrgPlacements/>} />
          <Route path="/organizations/:org/students" element={<OrgStudents/>} />
          <Route path="/organizations/:org/career-seekers" element={<OrgStudents/>} />
          <Route path="/organizations/:org/educators" element={<OrgEducators/>} />
          <Route path="/organizations/:org/mentors" element={<OrgMentors/>} />
          <Route path="/organizations/:org/employers" element={<OrgEmployers/>} />
          <Route path="/organizations/:org/messages" element={<OrgMessages/>} />
          <Route path="/organizations/:org/learning-modules" element={<OrgLearningModules/>} />
          <Route path="/organizations/:org/goals" element={<OrgGoals/>} />
          <Route path="/organizations/:org/sessions" element={<OrgSessions/>} />
          <Route path="/organizations/:org/news-feed" element={<OrgNewsFeed/>} />
          <Route path="/organizations/:org/groups" element={<OrgGroups/>} />
          <Route path="/organizations/:org/groups/:groupId" element={<OrgGroupDetails/>} />
          <Route path="/organizations/:org/timesheets" element={<OrgTimesheets/>} />
          <Route path="/organizations/:org/courses" element={<OrgCourses/>} />
          <Route path="/organizations/:org/courses/:id" element={<OrgCourseDetails/>} />
          <Route path="/organizations/:org/edit-courses/:id" element={<OrgEditCourse/>} />
          <Route path="/organizations/:org/edit-courses/:id/lesson" element={<OrgEditCourseLesson/>} />
          <Route path="/organizations/:org/resources" element={<OrgResources/>} />
          <Route path="/organizations/:org/resources/:id" element={<OrgEditResource/>} />
          <Route path="/organizations/:org/documents/:id" element={<OrgEditDocument/>} />
          <Route path="/organizations/:org/reporting" element={<OrgReporting/>} />
          <Route path="/organizations/:org/endorsements" element={<OrgEndorsements/>} />
          <Route path="/organizations/:org/referrals" element={<OrgReferrals/>} />
          <Route path="/organizations/:org/exchange" element={<OrgExchange/>} />
          <Route path="/organizations/:org/:id" element={<OrgExchange/>} />
          <Route path="/organizations/:org/settings" element={<OrgSettings/>} />
          <Route path="/organizations/:org/walkthrough" element={<OrgWalkthrough/>} />
          <Route path="/organizations/:org/settings/change-password" element={<OrgChangePassword/>} />
          <Route path="/organizations/:org/settings/change-email" element={<OrgChangeEmail/>} />
          <Route path="/organizations/:org/notifications" element={<OrgNotifications/>} />
          <Route path="/organizations/:org/postings/add" element={<OrgAddJob/>} />
          <Route path="/organizations/:org/career-seekers/add" element={<OrgAddStudent/>} />
          <Route path="/organizations/:org/educators/add" element={<OrgAddEducator/>} />
          <Route path="/organizations/:org/mentors/add" element={<OrgAddMentor/>} />
          <Route path="/organizations/:org/employers/add" element={<OrgAddEmployer/>} />
          <Route path="/organizations/:org/students/:userId/evaluations" element={<OrgStudentEvaluation/>} />
          <Route path="/organizations/:org/members/:userId/evaluations" element={<OrgStudentEvaluation/>} />
          <Route path="/organizations/:org/members" element={<OrgMembers/>} />
          <Route path="/organizations/:org/members/invite" element={<OrgAddAdmins/>} />
          <Route path="/organizations/:org/members/:queryEmail" element={<OrgMemberDetail/>} />
          <Route path="/organizations/:org/profile/:username" element={<OrgMemberDetail/>} />
          <Route path="/organizations/:org/assessments/add" element={<OrgAddAssessment/>} />
          <Route path="/organizations/:org/benchmarks/add" element={<OrgAddBenchmark/>} />
          <Route path="/organizations/:org/placements/add" element={<OrgEditPlacement/>} />
          <Route path="/organizations/:org/postings/:id" element={<OrgEditJob/>} />
          <Route path="/organizations/:org/educators/:id" element={<OrgEditEducator/>} />
          <Route path="/organizations/:org/mentors/:id" element={<OrgEditMentor/>} />
          <Route path="/organizations/:org/employers/:id" element={<OrgEditEmployer/>} />
          <Route path="/organizations/:org/edit-logs/:logId" element={<OrgEditLog/>} />
          <Route path="/organizations/:org/assessments/:id" element={<OrgEditAssessment/>} />
          <Route path="/organizations/:org/benchmarks/:id" element={<OrgEditBenchmark/>} />
          <Route path="/organizations/:org/pathways/:id" element={<OrgEditPathway/>} />
          <Route path="/organizations/:org/programs/:id" element={<OrgEditProgram/>} />
          <Route path="/organizations/:org/placements/:id" element={<OrgEditPlacement/>} />
          <Route path="/organizations/:org/postings/:id/applications" element={<OrgApplications/>} />
          <Route path="/organizations/:org/postings/:id/applications/:candidateId" element={<OrgApplicationDetail/>} />
          <Route path="/organizations/:org/postings/:id/applications/:candidateId/interview" element={<OrgInterviewDetail/>} />
          <Route path="/organizations/:org/postings/:postingId/scores" element={<OrgApplicationExternalScores/>} />
          <Route path="/organizations/:org/postings/:id/top-candidates" element={<OrgTopCandidates/>} />
          <Route path="/organizations/:org/messages/:userId" element={<OrgSendMessage/>} />
          <Route path="/organizations/:org/postings/:id/messages/:username" element={<OrgNewMessage/>} />
          <Route path="/organizations/:org/careers" element={<OrgCareers/>} />
          <Route path="/organizations/:org/careers/:careerSelected" element={<OrgCareerDetails/>} />
          <Route path="/organizations/:org/employer-details/:employerSelected" element={<OrgEmployerDetails/>} />
          <Route path="/organizations/:org/orgs/:orgSelected" element={<OrgOrgDetails/>} />
          <Route path="/organizations/:org/logs/:logSelected" element={<OrgLogDetails/>} />
          <Route path="/organizations/:org/messaging" element={<OrgMessaging/>} />
          <Route path="/organizations/:org/messaging/thread/:threadId" element={<OrgMessaging/>} />
          <Route path="/organizations/:org/edit-profile/basics" element={<OrgEditProfileDetails/>} />
          <Route path="/organizations/:org/endorsements/requests/:requestId" element={<OrgSendEndorsement/>} />
          <Route path="/organizations/:org/endorsements/:endorsementId" element={<OrgSendEndorsement/>} />
          <Route path="/organizations/:org/message-blasts" element={<OrgScheduledMessages/>} />
          <Route path="/organizations/:org/message-blasts/:id" element={<OrgEditScheduledMessage/>} />
          <Route path="/organizations/:org/bulk-uploader" element={<OrgBulkUploader/>} />
          <Route path="/organizations/:org/courses/:id/take" element={<OrgTakeCourse/>} />
          <Route path="/organizations/:org/tickets" element={<OrgTickets/>} />
          <Route path="/organizations/:org/trends" element={<OrgTrends/>} />
          <Route path="/organizations/:org/documents" element={<OrgDocuments/>} />
          <Route path="/organizations/:org/edit-organization-profile" element={<OrgEditOrganizationProfile/>} />
          <Route path="/organizations/:org/members/match/:id" element={<OrgMatchMentor/>} />
          <Route path="/organizations/:org/submissions" element={<OrgSubmissions/>} />
          <Route path="/organizations/:org/submissions/:id" element={<OrgSubmissionDetails/>} />

          <Route path="/employers/post" element={<EmpPost/>} />
          <Route path="/employers/post/:org" element={<EmpPost/>} />
          <Route path="/employers/:emp/confirm-email/:orgCode/:email" element={<EmpConfirmEmail/>} />
          <Route path="/employers/:emp/confirm-email/:orgCode/:email/:confirmed" element={<EmpConfirmEmail/>} />
          <Route path="/employers/signin" element={<EmpSignIn/>} />
          <Route path="/employers/:emp/signin" element={<EmpSignIn/>} />
          <Route path="/employers/:emp/signup" element={<EmpSignUp/>} />
          <Route path="/employers/:emp/join" element={<EmpSignUp/>} />
          <Route path="/employers/:emp/organizations/:org/join" element={<EmpSignUpOrg/>} />
          <Route path="/employers/:emp/dashboard" element={<EmpDashboard/>} />
          <Route path="/employers/:emp/postings" element={<EmpJobs/>} />
          <Route path="/employers/:emp/assessments" element={<EmpAssessments/>} />
          <Route path="/employers/:emp/benchmarks" element={<EmpBenchmarks/>} />
          <Route path="/employers/:emp/pipelines" element={<EmpPipelines/>} />
          <Route path="/employers/:emp/social-posts" element={<EmpSocialPosts/>} />
          <Route path="/employers/:emp/hires" element={<EmpHires/>} />
          <Route path="/employers/:emp/messages" element={<EmpMessages/>} />
          <Route path="/employers/:emp/reporting" element={<EmpReporting/>} />
          <Route path="/employers/:emp/edit-profile" element={<EmpEditProfile/>} />
          <Route path="/employers/:emp/edit-employer-profile" element={<EmpEditEmployerProfile/>} />
          <Route path="/employers/:emp/add-orgs" element={<EmpAddOrgs/>} />
          <Route path="/employers/:emp/settings" element={<EmpSettings/>} />
          <Route path="/employers/:emp/notifications" element={<EmpNotifications/>} />
          <Route path="/employers/:emp/walkthrough" element={<EmpWalkthrough/>} />
          <Route path="/employers/:emp/settings/change-password" element={<EmpChangePassword/>} />
          <Route path="/employers/:emp/settings/change-email" element={<EmpChangeEmail/>} />
          <Route path="/employers/:emp/settings/integrations" element={<EmpIntegrations/>} />
          <Route path="/employers/:emp/postings/add" element={<EmpAddJob/>} />
          <Route path="/employers/:emp/admin/invite" element={<EmpAddAdmins/>} />
          <Route path="/employers/:emp/assessments/add" element={<EmpAddAssessment/>} />
          <Route path="/employers/:emp/benchmarks/add" element={<EmpAddBenchmark/>} />
          <Route path="/employers/:emp/postings/:id" element={<EmpEditJob/>} />
          <Route path="/employers/:emp/assessments/:id" element={<EmpEditAssessment/>} />
          <Route path="/employers/:emp/benchmarks/:id" element={<EmpEditBenchmark/>} />
          <Route path="/employers/:emp/hires/:id" element={<EmpEditHire/>} />
          <Route path="/employers/:emp/pipelines/:id" element={<EmpPipelineDetails/>} />
          <Route path="/employers/:emp/communities" element={<EmpCommunities/>} />
          <Route path="/employers/:emp/groups/:groupId" element={<EmpGroupDetails/>} />
          <Route path="/employers/:emp/postings/:id/applications" element={<EmpApplications/>} />
          <Route path="/employers/:emp/postings/:id/applications/:candidateId" element={<EmpApplicationDetail/>} />
          <Route path="/employers/:emp/postings/:id/applications/:candidateId/interview" element={<EmpInterviewDetail/>} />
          <Route path="/employers/:emp/postings/:id/top-candidates" element={<EmpTopCandidates/>} />
          <Route path="/employers/:emp/career-seekers/:profileEmail" element={<EmpCareerSeekerDetail/>} />
          <Route path="/employers/:emp/postings/:id/messages/:username" element={<EmpSendMessage/>} />
          <Route path="/employers/organizations/:org/join" element={<EmpOrgSignUp/>} />
          <Route path="/employers/:emp/orgs/:orgSelected" element={<EmpOrgDetails/>} />{/**/}

          <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
// <App />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// 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
