import React, {Component} from 'react';
import SubWalkthrough from './Subcomponents/Walkthrough';
import withRouter from './Functions/WithRouter';

class EmpWalkthrough extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');

      const { emp } = this.props.params

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg')

      let orgMode = true
      if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
        orgMode = false
      }

      this.setState({ emp, orgMode, org: activeOrg, emailId: email, cuFirstName, cuLastName, username })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {

        return (
            <div>
              <SubWalkthrough history={this.props.navigate} opportunityId={null} accountCode={this.state.emp} loadWorkspace={this.loadWorkspace} />
            </div>

        )
    }
}

export default withRouter(EmpWalkthrough)
