import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubInterviewDetail from '../Subcomponents/InterviewDetail';
import withRouter from '../Functions/WithRouter';

class OrgInterviewDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let id = null
      let org = null
      if (this.props.params) {
        id = this.props.params.id
        org = this.props.params.org
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgLogo = localStorage.getItem('orgLogo');

      let selectedJob = null
      let selectedApplication = null
      let benchmark = null
      let benchmarkOptions = null
      let trackIndex = null

      let selectedTrack = null
      let interview = null
      if (this.props.location && this.props.location.state) {
        selectedJob = this.props.location.state.selectedJob
        selectedApplication = this.props.location.state.selectedApplication
        benchmark = this.props.location.state.benchmark
        benchmarkOptions = this.props.location.state.benchmarkOptions
        trackIndex = this.props.location.state.trackIndex
        selectedTrack = this.props.location.state.selectedTrack
        interview = this.props.location.state.interview
      }

      this.setState({ emailId: email, username,
        cuFirstName, cuLastName, org, orgLogo,
        selectedJob, selectedApplication, benchmark, benchmarkOptions, trackIndex, id,
        selectedTrack, interview
      })
  }

  render() {

    return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
          <SubInterviewDetail accountCode={this.state.emp} org={this.state.org} id={this.state.id} selectedJob={this.state.selectedJob} selectedApplication={this.state.selectedApplication} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} trackIndex={this.state.trackIndex} selectedTrack={this.state.selectedTrack} interview={this.state.interview} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>

    )
  }
}

export default withRouter(OrgInterviewDetail)
