import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubSideNav from '../Common/SideNav';
import Members from '../Subcomponents/Members';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

class OrgMentors extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        const path = window.location.pathname

        this.setState({ emailId: email, username, org, orgFocus, orgLogo, path })

      }
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

            {(!toggleVars.hideAdminSideNav) && (
              <SubSideNav selectedComponent={"OrgMentors"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
            )}

            <Members activeOrg={this.state.org} accountCode={this.state.accountCode} roleName="Admin" path={this.state.path} type="Mentors" />

            <div className="clear" />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(OrgMentors)
