import React, {Component} from 'react';
import Axios from 'axios';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubNotificationSubscribe from '../components/Subcomponents/NotificationSubscribe';
import withRouter from '../components/Functions/WithRouter';

const industryIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";

class NotificationSubscribePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          useSub: true,
          opportunityTypeOptions: [],
          educationLevelOptions: [],
          pathwayOptions: [],
          functionOptions: [],
          industryOptions: [],
          roleNameOptions: [],
          adversityTagOptions: []
        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidMount() {
      //componentDidMount
      const { orgCode, subscriptionType } = this.props.params
      console.log('componentDidMount notificationSubscribe: ', orgCode, subscriptionType)

      let firstName = localStorage.getItem('firstName');
      let lastName = localStorage.getItem('lastName');
      let email = localStorage.getItem('email');

      const roleNameOptions = ['','Career-Seeker','Teacher','Employer','Counselor','Service Provider','Community Organizer','Other']

      this.setState({ orgCode, subscriptionType, firstName, lastName, email, roleNameOptions })

      Axios.get('/api/org', { params: { orgCode } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const orgLogo = response.data.orgInfo.webLogoURIColor
            const orgContactEmail = response.data.orgInfo.contactEmail

            this.setState({ orgName, orgLogo, orgContactEmail });

            // let courses = []
            // if (response.data.orgInfo.courseIds && response.data.orgInfo.courseIds.length > 0) {
            //   console.log('courses exist')
            //
            // }

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      Axios.get('/api/pathways', { params: { orgCode }})
      .then((response) => {
        console.log('Pathways query attempted no 1', response.data);

        if (response.data.success) {
          console.log('pathway query worked no 1')

          if (response.data.pathways.length !== 0) {

            let pathwayOptions = []
            if (response.data.pathways && response.data.pathways.length > 0) {
              pathwayOptions = [{ title: '' },{ title: 'All' }].concat(response.data.pathways)
            }

            this.setState({ pathwayOptions });
          }

        } else {
          console.log('pathway query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Pathway query did not work for some reason', error);
      });

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const functionOptions = ['','All'].concat(response.data.workOptions[0].functionOptions.slice(1,response.data.workOptions[0].functionOptions.length))
          const industryOptions = ['','All'].concat(response.data.workOptions[0].industryOptions.slice(1,response.data.workOptions[0].industryOptions.length))
          const opportunityTypeOptions = [''].concat(response.data.workOptions[0].typeTagOptions)
          const educationLevelOptions = [''].concat(response.data.workOptions[0].educationTagOptions)
          const adversityTagOptions = [''].concat(response.data.workOptions[0].adversityTagOptions)

          this.setState({ functionOptions, industryOptions, opportunityTypeOptions, educationLevelOptions,
            adversityTagOptions
          })

        } else {
          console.log('no jobFamilies data found', response.data.message)

        }
      }).catch((error) => {
          console.log('query for work options did not work', error);
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })

      // if (event.target.name === 'opportunityType') {
      //   this.setState({ [event.target.name]: event.target.value })
      // } else {
      //   this.setState({ [event.target.name]: event.target.value })
      // }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'opportunityType') {
        let opportunityTypes = this.state.opportunityTypes
        if (opportunityTypes && opportunityTypes.includes(this.state.opportunityType)) {
          this.setState({ errorMessage: "You've already added that opportunity type"})
        } else if (opportunityTypes) {
          opportunityTypes.push(this.state.opportunityType)
        } else {
          opportunityTypes = [this.state.opportunityType]
        }

        let opportunityType = ''
        this.setState({ opportunityTypes, opportunityType })
      } else if (type === 'educationLevel') {
        let educationLevels = this.state.educationLevels
        if (educationLevels && educationLevels.includes(this.state.educationLevel)) {
          this.setState({ errorMessage: "You've already added that education level"})
        } else if (educationLevels) {
          educationLevels.push(this.state.educationLevel)
        } else {
          educationLevels = [this.state.educationLevel]
        }

        let educationLevel = ''
        this.setState({ educationLevels, educationLevel })
      } else if (type === 'adversityTag') {
        let adversityTags = this.state.adversityTags
        if (adversityTags && adversityTags.includes(this.state.adversityTag)) {
          this.setState({ errorMessage: "You've already added that tag"})
        } else if (adversityTags) {
          adversityTags.push(this.state.adversityTag)
        } else {
          adversityTags = [this.state.adversityTag]
        }

        let adversityTag = ''
        this.setState({ adversityTags, adversityTag })
      } else if (type === 'pathway') {
        let pathways = this.state.pathways
        if (pathways && pathways.includes(this.state.pathway)) {
          this.setState({ errorMessage: "You've already added that pathway"})
        } else if (pathways) {
          pathways.push(this.state.pathway)
        } else {
          pathways = [this.state.pathway]
        }

        let pathway = ''
        this.setState({ pathways, pathway })
      } else if (type === 'workFunction') {
        let workFunctions = this.state.workFunctions
        if (workFunctions && workFunctions.includes(this.state.workFunction)) {
          this.setState({ errorMessage: "You've already added that work function"})
        } else if (workFunctions) {
          workFunctions.push(this.state.workFunction)
        } else {
          workFunctions = [this.state.workFunction]
        }

        let workFunction = ''
        this.setState({ workFunctions, workFunction })
      } else if (type === 'industry') {
        let industries = this.state.industries
        if (industries && industries.includes(this.state.industry)) {
          this.setState({ errorMessage: "You've already added that industry"})
        } else if (industries) {
          industries.push(this.state.industry)
        } else {
          industries = [this.state.industry]
        }

        let industry = ''
        this.setState({ industries, industry })
      }
    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.selectedCareer)

      let items = null
      if (type === 'opportunityType') {
        items = this.state.opportunityTypes
      } else if (type === 'educationLevel') {
        items = this.state.educationLevels
      } else if (type === 'adversityTag') {
        items = this.state.adversityTags
      } else if (type === 'pathway') {
        items = this.state.pathways
      } else if (type === 'workFunction') {
        items = this.state.workFunctions
      } else if (type === 'industry') {
        items = this.state.industries
      }

      if (items && items.length > 0) {
        return (
          <div key={"favoritedCareers"}>
            <div className="spacer" />
            {items.map((value, optionIndex) =>
              <div key={"career|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className={"tag-container-active"}>
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>

              </div>
            )}
          </div>
        )
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'opportunityType') {
        let opportunityTypes = this.state.opportunityTypes
        opportunityTypes.splice(index, 1)
        this.setState({ opportunityTypes, formHasChanged: true })
      } else if (type === 'educationLevel') {
        let educationLevels = this.state.educationLevels
        educationLevels.splice(index, 1)
        this.setState({ educationLevels, formHasChanged: true })
      } else if (type === 'adversityTag') {
        let adversityTags = this.state.adversityTags
        adversityTags.splice(index, 1)
        this.setState({ adversityTags, formHasChanged: true })
      } else if (type === 'pathway') {
        let pathways = this.state.pathways
        pathways.splice(index, 1)
        this.setState({ pathways, formHasChanged: true })
      } else if (type === 'workFunction') {
        let workFunctions = this.state.workFunctions
        workFunctions.splice(index, 1)
        this.setState({ workFunctions, formHasChanged: true })
      } else if (type === 'industry') {
        let industries = this.state.industries
        industries.splice(index, 1)
        this.setState({ industries, formHasChanged: true })
      }
    }

    handleSubmit() {
      console.log('handleSubmit called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.firstName || this.state.firstName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your first name' })
      } else if (!this.state.lastName || this.state.lastName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your last name' })
      } else if (!this.state.email || this.state.email === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your email' })
      } else if (!this.state.email.includes('@')) {
        this.setState({ isSaving: false, errorMessage: 'Please add a valid email' })
      } else if (!this.state.opportunityTypes || this.state.opportunityTypes.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'Please tag opportunity types' })
      } else {

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const emailId = this.state.email
        const jobTitle = this.state.jobTitle
        const employerName = this.state.employerName
        const roleName = this.state.roleName
        const opportunityTypes = this.state.opportunityTypes
        const educationLevels = this.state.educationLevels
        const adversityTags = this.state.adversityTags
        const pathways = this.state.pathways
        const workFunctions = this.state.workFunctions
        const industries = this.state.industries
        const notes = this.state.notes

        const opportunitiesSubscriber = true
        const orgCode = this.state.orgCode
        const orgName = this.state.orgName
        const orgContactEmail = this.state.orgContactEmail

        const createdAt = new Date()
        const updatedAt = new Date()

        const postObject = {
          firstName, lastName, emailId, jobTitle, employerName, roleName,
          opportunityTypes, educationLevels, adversityTags, pathways, workFunctions, industries, notes,
          opportunitiesSubscriber, orgCode, orgName, orgContactEmail,
          createdAt, updatedAt
        }

        Axios.post('/api/notifications/subscribe', postObject)
        .then((response) => {
          console.log('Notifications subscribe query worked', response.data);

          if (response.data.success) {

            console.log('successfully subscribed to notifications')
            this.setState({ isSaving: false,  firstName: '', lastName: '', email: '',
              roleName: '',
              opportunityTypes: null, educationLevels: null, adversityTags: null,
              pathways: null, workFunctions: null, industries: null, notes: '',
              successMessage: response.data.message
            })

          } else {
            console.log('there was an error matching partners', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }

        }).catch((error) => {
            console.log('Partner match did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"notificationSubscribePage"} darkBackground={true} />
            <div className="contact-container">
              {(this.state.orgCode) && (
                <div>
                  {(this.state.useSub) ? (
                    <div>
                      <SubNotificationSubscribe orgCode={this.state.orgCode} subscriptionType={this.state.subscriptionType} />
                    </div>
                  ) : (
                    <div>
                      <div className="row-10">
                        <div className="fixed-column-50">
                          <img src={(this.state.orgLogo) ? this.state.orgLogo : industryIconDark} alt="GC" className="image-40-fit" />
                        </div>
                        <div className="calc-column-offset-100">
                          <p className="heading-text-2 center-text">Subscribe to Weekly {this.state.orgName} Opportunity Notifications</p>
                        </div>
                        <div className="fixed-column-50 height-30">
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="spacer" />

                      <div className="row-30">
                        <p className="description-text-1 center-text">Subscribe to weekly event, project, and work opportunities from {this.state.orgName} and {this.state.orgName} partners. Use this digest for your interests or to forward to others/refer others that have these interests.</p>
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">First Name <label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="(e.g., Jon)" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Last Name <label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="(e.g., Doe)" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Email <label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="(e.g., jondoe@gmail.com)" name="email" value={this.state.email} onChange={this.formChangeHandler} />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Relationship <label className="error-color">*</label></label>
                          <select name={"roleName"} className="dropdown" value={this.state.roleName} onChange={this.formChangeHandler}>
                            {this.state.roleNameOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        {/*
                        <div className="container-right">
                          <label className="profile-label">Job Title <label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="(e.g., Teacher)" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}/>
                        </div>*/}
                        <div className="clear" />
                      </div>
                      {/*
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Employer Name <label className="error-color">*</label></label>
                          <input className="text-field" type="text" placeholder="(e.g., Los Angeles Unified School District)" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler} />
                        </div>
                        <div className="clear" />
                      </div>*/}

                      <div className="spacer" /><div className="spacer" />

                      <div className="row-10">
                        <hr />
                      </div>

                      <div className="spacer" /><div className="spacer" />

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">What types of opportunities? [Select all that apply]:<label className="error-color">*</label></label>
                          <div className="calc-column-offset-70">
                            <select name={"opportunityType"} className="dropdown" value={this.state.opportunityType} onChange={this.formChangeHandler}>
                              {this.state.opportunityTypeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.opportunityType || this.state.opportunityType === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.opportunityType || this.state.opportunityType === '') ? true : false} onClick={() => this.addItem('opportunityType')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('opportunityType')}
                          <div className="clear" />

                          <div className="clear" />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Which Education Level(s) [Select all that apply]<label className="error-color">*</label></label>
                          <div className="calc-column-offset-70">
                            <select name={"educationLevel"} className="dropdown" value={this.state.educationLevel} onChange={this.formChangeHandler}>
                              {this.state.educationLevelOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.educationLevel || this.state.educationLevel === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.educationLevel || this.state.educationLevel === '') ? true : false} onClick={() => this.addItem('educationLevel')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('educationLevel')}
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Which target demographics? [Select all that apply]</label>
                          <div className="calc-column-offset-70">
                            <select name={"adversityTag"} className="dropdown" value={this.state.adversityTag} onChange={this.formChangeHandler}>
                              {this.state.adversityTagOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.adversityTag || this.state.adversityTag === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.adversityTag || this.state.adversityTag === '') ? true : false} onClick={() => this.addItem('adversityTag')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('adversityTag')}
                          <div className="clear" />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Pathway(s)</label>
                          <div className="calc-column-offset-70">
                            <select name={"pathway"} className="dropdown" value={this.state.pathway} onChange={this.formChangeHandler}>
                              {this.state.pathwayOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.pathway || this.state.pathway === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.pathway || this.state.pathway === '') ? true : false} onClick={() => this.addItem('pathway')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('pathway')}
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Work Function(s)</label>
                          <div className="calc-column-offset-70">
                            <select name={"workFunction"} className="dropdown" value={this.state.workFunction} onChange={this.formChangeHandler}>
                              {this.state.functionOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.workFunction || this.state.workFunction === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.workFunction || this.state.workFunction === '') ? true : false} onClick={() => this.addItem('workFunction')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('workFunction')}
                          <div className="clear" />
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Industries(s)</label>
                          <div className="calc-column-offset-70">
                            <select name={"industry"} className="dropdown" value={this.state.industry} onChange={this.formChangeHandler}>
                              {this.state.industryOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className={(!this.state.industry || this.state.industry === '') ? "btn btn-squarish medium-background standard-border" : "btn btn-squarish"} disabled={(!this.state.industry || this.state.industry === '') ? true : false} onClick={() => this.addItem('industry')}>Add</button>
                          </div>
                          <div className="clear" />

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                          {this.renderTags('industry')}
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <label className="profile-label">Notes</label>
                        <textarea className="text-field" type="text" placeholder="Have anything else you want to add about the type of opportunities you receive?" name="notes" value={this.state.notes} onChange={this.formChangeHandler} />
                      </div>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      <div className="row-10">
                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.handleSubmit()}>Subscribe</button>
                      </div>
                    </div>
                  )}
                </div>
              )}

            </div>

            <Footer history={this.props.navigate} />
          </div>
      )
    }
}

export default withRouter(NotificationSubscribePage);
