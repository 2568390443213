import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubOrgDetails from '../components/Subcomponents/OrgDetails';
import withRouter from '../components/Functions/WithRouter';

class OrgDetailsPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      //see if user is logged in

      window.scrollTo(0, 0)

      const { orgSelected } = this.props.params
      let subNavSelected = null
      if (this.props.location && this.props.location.state) {
        subNavSelected = this.props.location.state.subNavSelected
      }

      this.setState({ orgSelected, subNavSelected })

    }

    render() {
        return (
            <div>
              <TopNavigation currentPage={"employerPage"} />
              <SubOrgDetails history={this.props.navigate} orgId={this.state.orgSelected} subNavSelected={this.state.subNavSelected} />

              <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(OrgDetailsPage);
