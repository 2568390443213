import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const thumbsUpIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-icon.png';
const thumbsUpIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-blue-icon.png';
const commentIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment_icon_grey.png';
const editIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-grey.png';

class Comments extends Component {
    constructor(props) {
      super(props)
      this.state = {
        comments: [],
        editComments: [],
        myReplies: []
      }

      this.renderReplies = this.renderReplies.bind(this)
      this.likeItem = this.likeItem.bind(this)
      this.editComment = this.editComment.bind(this)
      this.showReplies = this.showReplies.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.postComment = this.postComment.bind(this)
      this.renderComments = this.renderComments.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      // console.log('componentDidUpdate called in subcomments ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedOpportunity !== prevProps.selectedOpportunity || this.props.orgFocus !== prevProps.orgFocus || this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.comments !== prevProps.comments) {
        this.retrieveData()
      } else if (this.props.selectedGroup !== prevProps.selectedGroup) {
        this.retrieveData()

      }
    }

    retrieveData() {
      console.log('retrieveData called within subcomments', this.props)

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      const username = localStorage.getItem('username');

      const selectedOpportunity = this.props.selectedOpportunity
      const activeOrg = this.props.activeOrg
      const accountCode = this.props.accountCode
      const employerName = this.props.employerName
      const jobTitle = this.props.jobTitle

      let comments = []
      if (this.props.comments) {
        comments = this.props.comments
      }

      const postingOrgCode = this.props.postingOrgCode
      const postingOrgName = this.props.postingOrgName
      const orgContactEmail = this.props.orgContactEmail
      let pictureURL = this.props.pictureURL
      // console.log('show pictureURL', pictureURL)
      if (pictureURL === 'undefined') {
        pictureURL = null
      }

      const selectedGroup = this.props.selectedGroup
      const selectedGroupPost = this.props.selectedGroupPost
      const selectedCurriculumPost = this.props.selectedCurriculumPost
      const selectedCourse = this.props.selectedCourse
      const selectedLesson = this.props.selectedLesson
      const orgLogo = this.props.orgLogo
      const employerLogo = this.props.employerLogo

      this.setState({
        emailId, cuFirstName, cuLastName, orgFocus, roleName, selectedOpportunity, activeOrg, accountCode, comments,
        postingOrgCode, postingOrgName, orgContactEmail, pictureURL, selectedGroup, selectedGroupPost, selectedCurriculumPost, selectedCourse,
        selectedLesson, username,
        orgLogo, employerLogo, employerName, jobTitle
      })

      if (activeOrg) {
        Axios.get('/api/users/profile/details', { params: { email: emailId } })
        .then((response) => {
          console.log('Profile query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved profile information')

             if (response.data.user.pictureURL) {
               pictureURL = response.data.user.pictureURL
             }
             const headline = response.data.user.headline
             let schoolName = response.data.user.school
             if (response.data.user.education && response.data.user.education.length > 0) {
               schoolName = response.data.user.education[0].name
               if (response.data.user.education.length > 1) {
                 for (let i = 1; i <= response.data.user.education.length; i++) {
                   if (response.data.user.education[i - 1] && response.data.user.education[i - 1].isContinual) {
                     schoolName = response.data.user.education[i - 1].name
                   }
                 }
               }
             }
             const jobTitle = response.data.user.jobTitle
             const employerName = response.data.user.employerName

             this.setState({ pictureURL, headline, schoolName, jobTitle, employerName })

           } else {
             console.log('no profile data found', response.data.message)
           }

        }).catch((error) => {
           console.log('Profile query did not work 1', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'comment') {
        this.setState({ myComment: event.target.value })
      } else if (event.target.name.includes('reply')) {
        const nameArray = event.target.name.split("|")
        let myReplies = this.state.myReplies
        myReplies[Number(nameArray[1])] = event.target.value
        this.setState({ myReplies })
      } else if (event.target.name === 'myEditedComment') {
        this.setState({ myEditedComment: event.target.value })
      }
    }

    likeItem(index, type) {
      console.log('likeItem called', index, type)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        this.setState({ savingLike: true, successMessage: '', errorMessage: '' })

        let _id = this.state.comments[index]._id
        const emailId = this.state.emailId

        Axios.post('/api/comments/like', { _id, emailId
        }).then((response) => {

          if (response.data.success) {
            //save values
            console.log('Like save worked ', response.data);

            //report whether values were successfully saved
            const successMessage  = 'Like submitted successfully!'
            const comment = response.data.comment

            if (type === 'submission') {
              let submissionComments = this.state.submissionComments
              for (let i = 1; i <= submissionComments.length; i++) {
                if (submissionComments[i - 1]._id === comment._id) {
                  submissionComments[i - 1] = comment
                }
              }

              this.setState({ successMessage, submissionComments, savingLike: false })

            } else {
              let comments = this.state.comments
              for (let i = 1; i <= comments.length; i++) {
                if (comments[i - 1]._id === comment._id) {
                  comments[i - 1] = comment
                }
              }
              this.setState({ successMessage, comments, savingLike: false })
            }

          } else {
            console.log('like did not save successfully')
            this.setState({ errorMessage: response.data.message, savingLike: false })
          }

        }).catch((error) => {
            console.log('Like save did not work', error);
            this.setState({ errorMessage: error, savingLike: false })
        });
      }
    }

    editComment(index, type, action) {
      console.log('editComment', index, type, action)

      if (type === 'posting') {
        let editComments = this.state.editComments
        editComments[index] = action
        const myEditedComment = this.state.comments[index].comment
        this.setState({ editComments, myEditedComment })
      } else {
        let editSubmissionComments = this.state.editSubmissionComments
        editSubmissionComments[index] = action
        const myEditedSubmissionComment = this.state.submissionComments[index].comment
        this.setState({ editSubmissionComments, myEditedSubmissionComment })
      }
    }

    showReplies(index, type) {
      console.log('addReplies called', index, type)

      if (type === 'submission') {
        let submissionComments = this.state.submissionComments
        submissionComments[index]['showReplies'] = true
        this.setState({ submissionComments })
      } else if (type === 'posting'){
        let comments = this.state.comments
        comments[index]['showReplies'] = true
        this.setState({ comments })
      }
    }

    renderComments() {
      // console.log('renderComments called', this.state.comments)

      let rows = []

      for (let i = 1; i <= this.state.comments.length; i++) {

        const index = i - 1

        let commentBackgroundColor = "comment-background-student"
        if (this.state.comments[i - 1].roleName === 'Mentor') {
          commentBackgroundColor = "comment-background-mentor"
        } else if (this.state.comments[i - 1].roleName === 'Teacher') {
          commentBackgroundColor = "comment-background-teacher"
        } else if (this.state.comments[i - 1].roleName === 'Admin') {
          commentBackgroundColor = "comment-background-admin"
        } else if (this.state.comments[i - 1].roleName === 'Employer') {
          commentBackgroundColor = "comment-background-employer"
        }

        let dateString = ''
        if (this.state.comments[i - 1].createdAt) {
          dateString = this.state.comments[i - 1].createdAt.toString().substring(0,10)
        }

        let showEditOption = false

        let mainClass = 'calc-column-offset-100'

        let smallClass = "fixed-column-100"
        if (this.state.comments[i - 1].email === this.state.emailId) {
          showEditOption = true

          mainClass = 'calc-column-offset-121'

          smallClass = "fixed-column-121"
        }

        if (this.state.onMobile) {
          mainClass = "full-width"
          smallClass = "full-width"
        }

        let commentClass = 'calc-column-offset-160'
        if (this.state.onMobile) {
          commentClass = "full-width"
        }

        let disabled = true
        if (this.state.myEditedComment) {
          disabled = false
        }

        rows.push(
          <div key={i}>
            <div className="spacer" />
            <Link className={(this.state.comments[i - 1].username) ? "background-button float-left clear-margin clear-padding" : "background-button float-left clear-margin clear-padding no-pointers"} target="_blank" to={"/app/profile/" + this.state.comments[i - 1].username}>
              <div className="float-left right-padding-8">
                <img src={(this.state.comments[i - 1].pictureURL) ? this.state.comments[i - 1].pictureURL : profileIconDark} alt="img" className="profile-thumbnail-2"/>
              </div>
            </Link>

            <div className={"comment-bubble-2 calc-column-offset-58 " + commentBackgroundColor}>
              <div className={mainClass}>
                <Link className={(this.state.comments[i - 1].username) ? "background-button standard-color full-width clear-margin clear-padding" : "background-button standard-color full-width clear-margin clear-padding no-pointers"} target="_blank" to={"/app/profile/" + this.state.comments[i - 1].username}>
                  <label className="heading-text-6">{this.state.comments[i - 1].firstName} {this.state.comments[i - 1].lastName}</label>
                  <div className="clear" />
                  <label className="description-text-2">{this.state.comments[i - 1].roleName}</label>
                  <div className="clear" />
                </Link>
              </div>

              {(this.state.onMobile) && (
                <div className="clear" />
              )}

              <div className={smallClass}>
                <div className="float-left">
                  <label className="description-text-3">{dateString}</label>
                </div>
                {(showEditOption) && (
                  <div className="float-left left-margin">
                    <button className="btn background-button" onClick={() => this.editComment(index,'posting',true) }>
                      <img src={editIconGrey} alt="img" className="image-auto-11" />
                    </button>
                  </div>
                )}
                <div className="clear" />
              </div>

              <div className="clear" />
              <div className="spacer" /><div className="half-spacer" />

              <div className={commentClass}>
                {(this.state.editComments[index]) ? (
                  <input className="edit-comment" type="text" placeholder="Change comment..." name="myEditedComment" value={this.state.myEditedComment} onChange={this.formChangeHandler} />
                ) : (
                  <label className="description-text-1">{this.state.comments[i - 1].comment}</label>
                )}

              </div>

              {(this.state.editComments[index]) && (
                <div className="fixed-column-160">
                  <div className="float-left">
                    <button className="btn btn-small clear-border transparent-background cta-color" onClick={() => this.editComment(index,'posting',false)}>Cancel</button>
                  </div>
                  <div className="float-left left-padding-15">
                    <button className={(this.state.myEditedComment) ? "btn btn-small" : "btn btn-small wash-out-2"} disabled={disabled} onClick={() => this.postComment(index, 'posting')}>Post</button>
                  </div>
                </div>
              )}

              <div className="clear" />
              <div className="spacer" />
              {/*
              {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
              {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}*/}

            </div>

            <div className="clear" />
            <div className="spacer" />

            <div className="left-margin-58">
              <div className="float-left right-margin">
                <button className="btn background-button" disabled={this.state.savingLike} onClick={() => this.likeItem(index,'posting') }>
                  <img src={this.state.comments[index].likes.includes(this.state.emailId) ? thumbsUpIconBlue : thumbsUpIconGrey} alt="Thumbs up icon logo" className="image-auto-25" />
                </button>
              </div>
              <div className="float-left right-margin">
                <label>{this.state.comments[i - 1].likes.length} Likes</label>
              </div>

              <div className="vertical-separator" />

              <div className="float-left left-margin right-margin">
                <button className="btn background-button" onClick={() => this.showReplies(index,'posting') }>
                  <img src={commentIconGrey} alt="Thumbs up icon logo" className="image-auto-25" />
                </button>
              </div>
              <div className="float-left">
                <button className="btn background-button" onClick={() => this.showReplies(index,'posting') }>
                  <label>{this.state.comments[i - 1].replies.length} Replies</label>
                </button>
              </div>
              <div className="clear" />
            </div>
            <div className="spacer" />
            {(this.state.comments[i - 1].showReplies) && (
              <div className="left-margin-58">
                {this.renderReplies(index,'posting')}
              </div>
            )}

            <div className="spacer" /><div className="spacer" />
          </div>
        )
      }

      return rows
    }

    renderReplies(index, type) {
      console.log('renderReplies called', index, type)

      let rows = []
      let comments = this.state.comments
      let postType = 'reply'

      if (type === 'submission') {
        comments = this.state.submissionComments
        postType = 'submissionReply'
      }

      let replies = comments[index].replies

      let myReply = ''
      if (this.state.myReplies[index]) {
        myReply = this.state.myReplies[index]
      }

      rows.push(
        <div key={0}>

          <div className="float-left right-margin-8">
            <div className="spacer" />
            {(window.location.pathname.includes('/organizations')) ? (
              <img src={(this.state.orgLogo) ? this.state.orgLogo : profileIconDark} alt="img" className="image-40-fit" />
            ) : (
              <div>
                {(window.location.pathname.includes('/employers')) ? (
                  <img src={(this.state.employerLogo) ? this.state.employerLogo : profileIconDark} alt="img" className="image-40-fit" />
                ) : (
                  <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="img" className="profile-thumbnail-1" />
                )}
              </div>
            )}
          </div>
          <div className="comment-container">
            <input className="edit-comment" type="text" placeholder="Add a reply..." name={"reply|" + index} value={myReply} onChange={this.formChangeHandler} />
          </div>
          <div className="clear" />
          {(this.state.myReplies[index] !== '') && (
            <div className="left-margin-67">
              <button className="btn btn-small" onClick={() => this.postComment(index,postType)}>Reply</button>
            </div>
          )}

          <div className="spacer" /><div className="spacer" />

        </div>
      )

      if (replies) {
        for (let i = 1; i <= replies.length; i++) {

          const replyIndex = i - 1

          let commentBackgroundColor = "comment-background-student"
          if (replies[i - 1].roleName === 'Mentor') {
            commentBackgroundColor = "comment-background-mentor"
          } else if (replies[i - 1].roleName === 'Employer') {
            commentBackgroundColor = "comment-background-employer"
          } else if (replies[i - 1].roleName === 'Teacher') {
            commentBackgroundColor = "comment-background-teacher"
          } else if (replies[i - 1].roleName === 'Admin') {
            commentBackgroundColor = "comment-background-admin"
          }

          let dateString = ''
          if (replies[replyIndex]) {
            if (replies[replyIndex].createdAt) {
              dateString = replies[replyIndex].createdAt.toString().substring(0,10)
            }
          }

          rows.push(
            <div key={i}>
              <div className="spacer" />
              <div className="float-left right-padding-8">
                <img src={(replies[i - 1].pictureURL) ? replies[i - 1].pictureURL : profileIconDark} alt="img" className="profile-thumbnail-1" />
              </div>

              <div className={"comment-bubble-2 calc-column-offset-48 " + commentBackgroundColor}>
                <div className="calc-column-offset-100">
                  <label className="description-text-2 bold-text">{replies[i - 1].firstName} {replies[i - 1].lastName}</label>
                  <div className="clear" />
                  <label className="description-text-3">{replies[i - 1].roleName}</label>
                  <div className="clear" />
                </div>
                <div className="fixed-column-100">
                  <div className="float-left">
                    <label className="description-text-3">{dateString}</label>
                  </div>
                  <div className="clear" />
                </div>

                <div className="clear" />

                <div className="spacer" />
                <label className="description-text-2">{replies[i - 1].comment}</label>
                <div className="clear" />
              </div>

              <div className="clear" />
              <div className="spacer" />

            </div>
          )
        }
      }

      return rows
    }

    postComment(index, type, subCommentIndex) {
      console.log('postComment called ', index, type, subCommentIndex)

      if (window.location.pathname.includes('/problem-platform')) {
        this.props.navigate('/problem-platform/' + this.state.selectedOpportunity._id + '/checkout', { state: { selectedItem: this.state.selectedOpportunity }})
      } else {
        this.setState({ errorMessage: '', successMessage: '', isSaving: true })

        //submit the selected project
        const email = this.state.emailId
        let firstName = this.state.cuFirstName
        let lastName = this.state.cuLastName
        let roleName = "Student"
        if (this.props.path && this.props.path.includes('/app')) {
          roleName = 'Student'
        } else if (window.location.pathname.includes('/organizations')) {
          firstName = this.state.postingOrgName
          lastName = ''
          roleName = "Admin"
        } else if (window.location.pathname.includes('/employers')) {
          firstName = this.state.employerName
          lastName = ''
          roleName = this.state.jobTitle
        } else {
          roleName = this.state.roleName
        }

        let likes = []

        const orgCode = this.state.postingOrgCode
        const orgName = this.state.postingOrgName
        const orgContactEmail = this.state.orgContactEmail

        let parentPostId = null
        let parentCommentId = null

        const headline = this.state.headline
        const username = this.state.username
        const schoolName = this.state.schoolName
        const jobTitle = this.state.jobTitle
        const employerName = this.state.employerName

        const headerImageURL = this.state.headerImageURL

        const createdAt = new Date()
        const updatedAt = new Date()

        if (type === 'reply' || type === 'submissionReply') {
          //save reply

          let comments = this.state.comments
          if (type === 'submissionReply') {
            comments = this.state.submissionComments
          }
          const commentType = type

          let _id = comments[index]
          const comment = this.state.myReplies[index]
          parentCommentId = comments[index]._id
          if (parentCommentId) {
            _id = parentCommentId
          }
          let pictureURL = this.state.pictureURL
          if (window.location.pathname.includes('/organizations')) {
            pictureURL = this.state.orgLogo
          } else if (window.location.pathname.includes('/employers')) {
            pictureURL = this.state.employerLogo
          }
          const reply = {
              firstName, lastName, email, roleName, comment, headerImageURL,
              headline, username, schoolName, jobTitle, employerName, parentCommentId,
              orgCode, orgName, orgContactEmail,
              pictureURL, createdAt, updatedAt
          }

          const self = this
          function actuallyPostComment(unsubscribed) {
            console.log('actuallyPostComment called')

            if (!comment) {
              self.setState({ errorMessage: 'Please add a comment', isSaving: false })
            } else {
              Axios.post('/api/comments', {
                _id, firstName, lastName, email, pictureURL, commentType,
                headline, username, schoolName, jobTitle, employerName,
                orgCode, orgName, orgContactEmail,
                reply
              }).then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Comment save worked ', response.data);

                  let myReplies = []

                  let newComment = response.data.comment

                  for (let i = 1; i <= comments.length; i++) {
                    if (comments[i - 1]._id === newComment._id) {
                      comments[i - 1] = newComment
                    }
                  }

                  if (type === 'submissionReply') {
                    self.setState({ successMessage: 'Reply submitted successfully!', submissionComments: comments, myReplies, isSaving: false })
                  } else {
                    self.setState({ successMessage: 'Reply submitted successfully!', comments, myReplies, isSaving: false })
                  }

                } else {

                  console.log('comment did not save successfully')
                  self.setState({ errorMessage: response.data.message, isSaving: false })
                }

              }).catch((error) => {
                  console.log('Reply save did not work', error);
                  self.setState({ errorMessage: error, isSaving: false })
              });
            }
          }

          // pull notification preferences of the owner
          Axios.get('/api/users/profile/details', { params: { email: comments[index].email } })
          .then((response) => {
            console.log('Profile query for notipreferences attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved profile information')
               const notificationPreferences = response.data.user.notificationPreferences

               let unsubscribed = null
               if (notificationPreferences && notificationPreferences.length > 0) {
                 for (let i = 1; i <= notificationPreferences.length; i++) {
                   if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                     unsubscribed = true
                   }
                 }
               }

               actuallyPostComment(unsubscribed)

             } else {
               console.log('no profile data found', response.data.message)
               actuallyPostComment(null)
             }

          }).catch((error) => {
             console.log('Profile query did not work 2', error);
          });

        } else if (type === 'submission') {

          //save comment on submission
          console.log('in submission')

          let commentId = null
          const commentType = type
          let pictureURL = this.state.pictureURL
          if (window.location.pathname.includes('/organizations')) {
            pictureURL = this.state.orgLogo
          } else if (window.location.pathname.includes('/employers')) {
            pictureURL = this.state.employerLogo
          }

          let replies = []
          let comment = this.state.mySubmissionComments[this.state.selectedIndex]
          const parentPostId = this.state.selectedOpportunity._id
          const parentSubmissionId = this.state.selectedOpportunity.submissions[this.state.selectedIndex]._id
          let submissionName = this.state.selectedOpportunity.submissions[this.state.selectedIndex].name
          let contributorFirstName = this.state.selectedOpportunity.submissions[this.state.selectedIndex].userFirstName
          let contributorEmail = this.state.selectedOpportunity.submissions[this.state.selectedIndex].userEmail

          if (subCommentIndex || subCommentIndex === 0) {
            commentId = this.state.submissionComments[index]._id
            comment = this.state.myEditedSubmissionComment
            likes = this.state.submissionComments[index].likes
            replies = this.state.submissionComments[index].replies
          }

          const self = this
          function actuallyPostComment(unsubscribed) {
            console.log('actuallyPostComment called')

            if (!comment) {
              self.setState({ errorMessage: 'Please add a comment', isSaving: false })
            } else {
              //save comment
              Axios.post('/api/comments', {
                commentId, commentType, email, firstName, lastName, roleName, comment, pictureURL, likes, replies,
                parentPostId, parentSubmissionId, createdAt, updatedAt, submissionName, contributorFirstName, contributorEmail,
                headline, username, schoolName, jobTitle, employerName,
                unsubscribed, headerImageURL,
                orgCode, orgName, orgContactEmail
              }).then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Comment save worked ', response.data);

                  let submissionComments = self.state.submissionComments
                  let editSubmissionComments = self.state.editSubmissionComments

                  if (subCommentIndex) {
                    console.log('existing comment')
                    submissionComments[index]['comment'] = self.state.myEditedSubmissionComment
                    editSubmissionComments[index] = false
                  } else {
                    submissionComments.unshift(
                      { _id: response.data._id, firstName, lastName, email, roleName, comment,
                        pictureURL, likes, replies, parentPostId, parentSubmissionId, createdAt, updatedAt }
                    )
                  }

                  let mySubmissionComments = self.state.mySubmissionComments
                  mySubmissionComments[self.state.selectedIndex] = ''

                  let submissionCommentCount = 0
                  if (self.state.submissionCommentCount) {
                    submissionCommentCount = self.state.submissionCommentCount
                  }
                  submissionCommentCount = submissionCommentCount + 1

                  console.log('show submissionComments: ', submissionComments, comment)

                  //report whether values were successfully saved
                  self.setState({ successMessage: 'Comment added successfully!',
                  submissionComments, mySubmissionComments, submissionCommentCount, isSaving: false })

                } else {
                  console.log('comment did not save successfully')
                  self.setState({ errorMessage: response.data.message, isSaving: false })
                }

              }).catch((error) => {
                  console.log('Comment save did not work', error);
                  self.setState({ errorMessage: error, isSaving: false })
              });
            }
          }

          // pull notification preferences of the owner
          Axios.get('/api/users/profile/details', { params: { email: contributorEmail } })
          .then((response) => {
            console.log('Profile query for notipreferences attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved profile information')
               const notificationPreferences = response.data.user.notificationPreferences

               let unsubscribed = null
               if (notificationPreferences && notificationPreferences.length > 0) {
                 for (let i = 1; i <= notificationPreferences.length; i++) {
                   if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                     unsubscribed = true
                   }
                 }
               }

               actuallyPostComment(unsubscribed)

             } else {
               console.log('no profile data found', response.data.message)
               actuallyPostComment(null)
             }

          }).catch((error) => {
             console.log('Profile query did not work 3', error);
          });

        } else if (type === 'posting') {

          //save comment on posting
          console.log('in posting')

          let commentId = null
          let commentType = type
          let replies = []
          let comment = this.state.myComment

          let postingTitle = ''
          let contributorFirstName = ''
          let contributorEmail = ''

          let isGroup = false

          // console.log('going in 1', this.state.selectedOpportunity, this.state.selectedGroup)
          if (this.state.selectedOpportunity) {
            console.log('going in 2')
            parentPostId = this.state.selectedOpportunity._id
            postingTitle = this.state.selectedOpportunity.name
            contributorFirstName = this.state.selectedOpportunity.contributorFirstName
            contributorEmail = this.state.selectedOpportunity.contributorEmail

            if (this.state.selectedOpportunity.postType === 'Event') {
              postingTitle = this.state.selectedOpportunity.title
              contributorFirstName = this.state.selectedOpportunity.orgContactFirstName
              contributorEmail = this.state.selectedOpportunity.orgContactEmail
            }
          } else if (this.state.selectedGroup) {
            console.log('going in 3')
            parentPostId = this.state.selectedGroupPost._id
            postingTitle = this.state.selectedGroupPost.groupName
            contributorFirstName = this.state.selectedGroupPost.firstName
            contributorEmail = this.state.selectedGroupPost.email
            isGroup = true
          } else if (this.state.selectedCurriculumPost) {
            console.log('going in 3')
            parentPostId = this.state.selectedCurriculumPost._id
            postingTitle = this.state.selectedCurriculumPost.itemName
            contributorFirstName = this.state.selectedCurriculumPost.firstName
            contributorEmail = this.state.selectedCurriculumPost.email
            isGroup = true
          } else if (this.state.selectedCourse) {
            console.log('going in 3')
            parentPostId = this.state.selectedCourse._id
            postingTitle = this.state.selectedCourse.name
            if (this.state.selectedCourse.title) {
              postingTitle = this.state.selectedCourse.title
            }
            contributorFirstName = 'Creighton'
            contributorEmail = 'creightontaylor@gmail.com'
            isGroup = true
          } else if (this.state.selectedLesson) {
            console.log('going in 3')
            parentPostId = this.state.selectedLesson._id
            postingTitle = this.state.selectedLesson.itemName
            contributorFirstName = this.state.selectedLesson.firstName
            contributorEmail = this.state.selectedLesson.email
            isGroup = true
          } else {

            parentPostId = this.state.selectedGroupPost._id
            postingTitle = this.state.selectedGroupPost.message
            contributorFirstName = this.state.selectedGroupPost.firstName
            contributorEmail = this.state.selectedGroupPost.email
            isGroup = true
          }

          let pictureURL = this.state.pictureURL
          if (window.location.pathname.includes('/organizations')) {
            pictureURL = this.state.orgLogo
          } else if (window.location.pathname.includes('/employers')) {
            pictureURL = this.state.employerLogo
          }

          if (index || index === 0) {
            commentId = this.state.comments[index]._id
            comment = this.state.myEditedComment
            likes = this.state.comments[index].likes
            replies = this.state.comments[index].replies
          }

          const self = this
          function actuallyPostComment(unsubscribed) {
            console.log('actuallyPostComment called')

            if (!comment) {
              this.setState({ errorMessage: 'Please add a comment', isSaving: false })
            } else {
              //save comment
              Axios.post('/api/comments', {
                commentId, commentType, email, firstName, lastName, roleName, comment, pictureURL, likes, replies,
                parentPostId, parentCommentId, createdAt, updatedAt, postingTitle, contributorFirstName, contributorEmail,
                headline, username, schoolName, jobTitle, employerName,
                isGroup, unsubscribed, headerImageURL,
                orgCode, orgName, orgContactEmail
              }).then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Comment save worked ', response.data, index, createdAt);
                  let comments = self.state.comments
                  let editComments = self.state.editComments

                  if (index || index === 0) {
                    console.log('existing comment', comments.length)
                    comments[index]['comment'] = self.state.myEditedComment
                    editComments[index] = false
                  } else {
                    console.log('new comment', comments.length)
                    comments.unshift(
                      { _id: response.data._id, firstName, lastName, email, roleName, comment,
                        pictureURL, likes, replies, parentPostId, parentCommentId, createdAt, updatedAt }
                    )
                  }
                  console.log('show comments: ', comments)

                  //report whether values were successfully saved
                  self.setState({ successMessage: 'Comment submitted successfully!',
                    comments, myComment: '', myEditedComment: '', editComments, isSaving: false })

                } else {
                  console.log('comment did not save successfully')
                  self.setState({ errorMessage: response.data.message, isSaving: false })
                }

              }).catch((error) => {
                  console.log('Comment save did not work', error);
                  self.setState({ errorMessage: error, isSaving: false })
              });
            }
          }

          console.log('show the things: ', this.state.comments, index)

          // pull notification preferences of the owner
          Axios.get('/api/users/profile/details', { params: { email: contributorEmail } })
          .then((response) => {
            console.log('Profile query for notipreferences attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved profile information')
               const notificationPreferences = response.data.user.notificationPreferences

               let unsubscribed = null
               if (notificationPreferences && notificationPreferences.length > 0) {
                 for (let i = 1; i <= notificationPreferences.length; i++) {
                   if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                     unsubscribed = true
                   }
                 }
               }

               actuallyPostComment(unsubscribed)

             } else {
               console.log('no profile data found', response.data.message)
               actuallyPostComment(null)
             }

          }).catch((error) => {
             console.log('Profile query did not work 4', error);
          });

        }
      }
    }

    render() {

      let registerLink = '/organizations/' + this.state.activeOrg + '/student/signin'
      if (!this.state.activeOrg || this.state.activeOrg === '') {
        registerLink = '/signin'
      }

      let passedState = { opportunityId: null }
      if (this.state.selectedOpportunity) {
        passedState = { opportunityId: this.state.selectedOpportunity._id }
      }

      return (
        <div>
          {(this.state.selectedOpportunity || this.state.selectedGroup || this.state.selectedGroupPost || this.state.selectedCurriculumPost || this.state.selectedCourse || this.state.selectedLesson) ? (
            <div>
              {(!this.state.emailId) ? (
                <div>
                  <div>
                    <div className="spacer"/><div className="half-spacer"/>
                    <Link className="clear-border" to={registerLink} state={passedState}>
                      <button className="btn btn-primary">
                        Register to Comment
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div className="float-left right-padding-8">
                      <div className="spacer" />
                      {(window.location.pathname.includes('/organizations')) ? (
                        <img src={(this.state.orgLogo) ? this.state.orgLogo : profileIconDark} alt="img" className="image-50-fit" />
                      ) : (
                        <div>
                          {(window.location.pathname.includes('/employers')) ? (
                            <img src={(this.state.employerLogo) ? this.state.employerLogo : profileIconDark} alt="img" className="image-50-fit" />
                          ) : (
                            <img src={(this.state.pictureURL) ? this.state.pictureURL : profileIconDark} alt="img" className="profile-thumbnail-2" />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="comment-container" >
                      <input className="text-field comment-text-field" type="text" placeholder="Add a comment..." name="comment" value={this.state.myComment} onChange={this.formChangeHandler} />
                    </div>

                    <div className="clear" />

                    {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                    {/*{(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}*/}

                    {(this.state.myComment !== '') && (
                      <div className="left-margin-67">
                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.postComment(null,'posting')}>Post</button>
                      </div>
                    )}
                    <div className="spacer" /><div className="spacer" />
                  </div>
                  {this.renderComments()}
                </div>
              )}

            </div>
          ) : (
            <div>
              <p className="error-message">There was an error finding the parent object</p>
            </div>
          )}


        </div>
      )
    }
}

export default withRouter(Comments);
