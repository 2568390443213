import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubReporting from './Subcomponents/Reporting';
import withRouter from './Functions/WithRouter';

class EmpReporting extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { emp } = this.props.params

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ emp, orgMode, activeOrg, emailId: email,
          cuFirstName, cuLastName, username, orgLogo })

        Axios.get('/api/account', { params: { accountCode: emp } })
        .then((response) => {
          console.log('Account info query attempted within sub settings', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employerName = response.data.accountInfo.employerName

            this.setState({ employerName });
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {
      return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

          <SubReporting org={this.state.activeOrg} accountCode={this.state.emp} history={this.props.navigate} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>

      )
    }
}

export default withRouter(EmpReporting)
