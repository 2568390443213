import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditLog from '../Subcomponents/EditLog';
import withRouter from '../Functions/WithRouter';

class OrgEditLog extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let editExisting = false
      let log = null
      let logs = null
      let selectedAdvisor = null
      let passedLogType = null
      if (this.props.location && this.props.location.state) {
        editExisting = this.props.location.state.editExisting
        log = this.props.location.state.log
        logs = this.props.location.state.logs
        selectedAdvisor = this.props.location.state.selectedAdvisor
        passedLogType = this.props.location.state.passedLogType
      }

      // const { logId } = this.props.params
      let logId = null
      if (this.props.params) {
        logId = this.props.params.logId
      } else if (id) {
        logId = id
      }

      let orgFocus = localStorage.getItem('orgFocus');
      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ emailId: email, org, orgFocus, orgLogo,
        editExisting, log, logs, passedLogType, selectedAdvisor, logId
      });
    }

    render() {

      return (
        <div>
          <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
          <SubEditLog history={this.props.navigate} editExisting={this.state.editExisting} log={this.state.log} logs={this.state.logs} passedLogType={this.state.passedLogType} selectedAdvisor={this.state.selectedAdvisor} logId={this.state.logId} />

          {(this.state.org) && (
            <div>
              {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(OrgEditLog)
