import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';;
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubMentorMatchingAlgorithm from '../Common/MentorMatchingAlgorithm';
import withRouter from '../Functions/WithRouter';

const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
// const mentoringIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/mentoring-icon-blue.png';
// const matchedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const addIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png";
const thumbsUpBlueIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-blue-icon.png";
const thumbsDownOrangeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-down-blue-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class MatchMentor extends Component {
    constructor(props) {
        super(props)

        this.state = {
          calculateImmediately: false,

          emailId: null,
          profileEmailId: '',
          username: '',
          profileUsername: '',

          enableMatching: false,
          oneMentorPerMentee: true,
          resLimit: 200,

          firstName: '',
          lastName: '',

          linkedInURL: '',
          resumeURL: '',
          customWebsiteURL: '',
          schoolName: '',

          source: 'Teacher',

          personalityResults: { myersBriggs: null, fiveFactors: { opennessScore: null, conscientiousnessScore: null, extraversionScore: null, agreeablenessScore: null, neuroticismScore: null } },
          interestResults: [{ score: 0},{ score: 0},{ score: 0},{ score: 0},{ score: 0},{ score: 0}],

          personalityTraits: [{ name: 'Openness', score: 30 },{ name: 'Conscientiousness', score: 80 },{ name: 'Extraversion', score: 50 },{ name: 'Agreeableness', score: 10 },{ name: 'Neuroticism', score: 96 },{ name: 'Narcissism', score: 40 }],
          workInterests: [{ name: 'Realistic', score: 30 },{ name: 'Investigative', score: 80 },{ name: 'Artistic', score: 50 },{ name: 'Social', score: 10 },{ name: 'Enterprising', score: 96 },{ name: 'Conventional', score: 40 }],

          mentorMatchingAlgorithm: { name: 'Default' },


          tbShotDate: '',
          fingerprintDate: '',
          students: [],
          matches: [],
          recipients: [],

          benchmarkOptions: [],
          trackOptions: [],
          staffMemberOptions: [],
          mentorMatchingAlgorithmOptions: [{ name: 'Default' },{ name: 'Add New'}],

          matchIndex: 0,
          matchScore: 0,

          matchCriteria: 'Overall Match',
          partnerMatches: [],

          serverErrorMessage: '',
          serverSuccessMessage: ''

        }

        this.calculateMatches = this.calculateMatches.bind(this)
        this.indicateWhetherPaired = this.indicateWhetherPaired.bind(this)
        this.renderMatchScore = this.renderMatchScore.bind(this)

        this.renderSection = this.renderSection.bind(this)
        this.renderTopMatches = this.renderTopMatches.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.changeRecipients = this.changeRecipients.bind(this)
        this.renderRecipients = this.renderRecipients.bind(this)
        this.matchMentor = this.matchMentor.bind(this)
        this.renderPartnerMatches = this.renderPartnerMatches.bind(this)

        this.retrieveData = this.retrieveData.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this)
        this.saveStudent = this.saveStudent.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.recalculateMatchScore = this.recalculateMatchScore.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEditMentor ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.userId !== prevProps.userId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subEditMentor')

      const org = this.props.activeOrg
      let selectedMentor = this.props.selectedMentor
      const source = this.props.source

      const email = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');

      if (email && selectedMentor) {

        let enableMatching = true

        let mentorMatchingAlgorithm = { name: 'Default', orgCode: 'guidedcompass', sections: [
          { name: 'Work Preferences', careerSeekerField: 'Work Preferences', operator: 'Equal To', mentorField: 'Work Preferences' },
          { name: 'Interests', careerSeekerField: 'Interests', operator: 'Equal To', mentorField: 'Interests' },
          { name: 'Personality', careerSeekerField: 'Personality', operator: 'Equal To', mentorField: 'Personality' },
          { name: 'Skills', careerSeekerField: 'Skills', operator: 'Equal To', mentorField: 'Skills' },
        ]}
        let mentorMatchingAlgorithmOptions = [mentorMatchingAlgorithm,{ name: 'Add New'}]

        this.setState({ emailId: email, username, selectedMentor, org, orgFocus, roleName,
          source, enableMatching, mentorMatchingAlgorithm, mentorMatchingAlgorithmOptions
        });

        // update mentor
        Axios.get('/api/users/profile/details/' + selectedMentor.email)
        .then((response) => {
          if (response.data) {

            console.log('User details fetch worked', response.data)
            if (response.data.success) {

              selectedMentor = response.data.user
              const caseNotes = response.data.user.caseNotes
              this.setState({ caseNotes })

              const resLimit = this.state.resLimit
              const memberQuery = { orgCode: org, roleNames: ['Career-Seeker'], resLimit }

              //school district orgs
              Axios.get('/api/org/members', { params: memberQuery })
              .then((response) => {

                 if (response.data.success) {
                   console.log('Classmates query worked', response.data);

                   const students = response.data.members
                   if (students && students.length > 0) {
                     this.setState({ students })

                     Axios.get('/api/assessment/results', { params: { emailId: selectedMentor.email } })
                      .then((response) => {
                        console.log('query for assessment results trued');

                        if (response.data.success) {

                          console.log('actual assessment results', response.data)

                          let wpData = null

                          if (response.data.results.mentorWorkPreferenceAnswers) {
                            wpData = response.data.results.mentorWorkPreferenceAnswers

                          }

                          console.log('testing this: ', response.data.results.mentorPersonalPreferenceAnswers)
                          let pgpData = null

                          if (response.data.results.mentorPersonalPreferenceAnswers) {
                            pgpData = response.data.results.mentorPersonalPreferenceAnswers

                          }

                          let isInterestsData = false

                          if (response.data.results.interestScores) {
                            isInterestsData = true

                          }

                          let isSkillsData = false

                          if (response.data.results.skillsScores) {
                            if (response.data.results.skillsScores.length !== 0) {
                              isSkillsData = true

                            }
                          }

                          let isPersonalityData = false

                          if (response.data.results.personalityScores) {
                            isPersonalityData = true

                          }

                          const interestsData = response.data.results.interestScores
                          const skillsData = response.data.results.skillsScores
                          const skillsAnswers = response.data.results.skillsAnswers
                          const personalityData = response.data.results.personalityScores

                          this.setState({
                            wpData, pgpData, isInterestsData, isSkillsData, isPersonalityData,
                            interestsData, skillsData, skillsAnswers, personalityData
                          });

                          if (this.state.calculateImmediately) {
                            this.calculateMatches(email, selectedMentor, students, wpData, interestsData, skillsAnswers, personalityData, null, 'Descending')
                          }

                        } else {
                          console.log('error response', response.data)
                          // this.setState({ resultsErrorMessage: response.data.message })

                          if (this.state.calculateImmediately) {
                            this.calculateMatches(email, selectedMentor, students, null, null, null, null, null, 'Descending')
                          }
                        }

                    }).catch((error) => {
                        console.log('query for assessment results did not work', error);
                    })
                   }

                 } else {
                   console.log('no classmates found', response.data.message)
                 }

              }).catch((error) => {
                 console.log('Classmates query did not work', error);
              });

              // console.log('show mentorEmail: ', selectedMentor.email)
              Axios.get('/api/partners', { params: { partnerEmail: selectedMentor.email, active: true } })
              .then((response) => {

                  if (response.data.success) {
                    console.log('Partners query worked', response.data);

                    const partners = response.data.partners
                    if (partners && partners.length > 0) {
                      let partnerMatches = []

                      for (let i = 1; i <= partners.length; i++) {
                        if (partners[i - 1].advisorEmail === selectedMentor.email && partners[i - 1].active) {
                          if (!this.state.oneMentorPerMentee && partners[i - 1].advisees && partners[i - 1].advisees.length > 0) {
                            partnerMatches = partnerMatches.concat(partners[i - 1].advisees)
                            console.log('show me partnerMatches: ', partnerMatches, partners[i - 1].advisees)
                          }

                          if (this.state.oneMentorPerMentee) {
                            partnerMatches.push({
                              firstName: partners[i - 1].adviseeFirstName, lastName: partners[i - 1].adviseeLastName,
                              email: partners[i - 1].adviseeEmail, username: partners[i - 1].adviseeUsername,
                              pictureURL: partners[i - 1].pictureURL, matchScore: partners[i - 1].matchScore
                            })
                          }
                        }
                      }

                      this.setState({ partnerMatches })

                    }

                  } else {
                    console.log('no partners found', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Partners query did not work', error);
              });

              Axios.get('/api/org', { params: { orgCode: org } })
              .then((response) => {
                console.log('Org info query attempted', response.data);

                  if (response.data.success) {
                    console.log('org info query worked')

                    const orgName = response.data.orgInfo.orgName
                    const orgContactTitle = response.data.orgInfo.contactTitle
                    const orgContactFirstName = response.data.orgInfo.contactFirstName
                    const orgContactLastName = response.data.orgInfo.contactLastName
                    const orgContactEmail = response.data.orgInfo.contactEmail
                    const orgContactCCEmails = response.data.orgInfo.ccEmails

                    this.setState({ orgName, orgContactTitle, orgContactFirstName, orgContactLastName,
                      orgContactEmail, orgContactCCEmails });

                  } else {
                    console.log('org info query did not work', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Org info query did not work for some reason', error);
              });

              const roleNames = ['Admin']
              Axios.get('/api/org/members', { params: { orgCode: org, roleNames, resLimit } })
              .then((response) => {

                if (response.data.success) {
                  console.log('Member query worked', response.data);

                  if (response.data.members && response.data.members.length > 0) {

                    let staffMemberOptions = response.data.members
                    if (staffMemberOptions) {
                      staffMemberOptions.unshift({})
                      this.setState({ staffMemberOptions })
                    }
                  }

                } else {
                  console.log('no members found', response.data.message)

                }

              }).catch((error) => {
                  console.log('Members query did not work', error);
              });

            } else {
              console.log('something went wrong!')
            }

          }
        }).catch((error) => {
            console.log('User details fetch did not work', error);
        });

        Axios.get('/api/algorithms', { params: { orgCode: org } })
        .then((response) => {
          console.log('Algorithms query attempted', response.data);

            if (response.data.success) {
              console.log('Algorithms query worked')

              const algorithms = response.data.algorithms
              mentorMatchingAlgorithmOptions = algorithms.concat(mentorMatchingAlgorithmOptions)

              if (algorithms[0]) {
                mentorMatchingAlgorithm = algorithms[0]
              }

              this.setState({ mentorMatchingAlgorithmOptions, mentorMatchingAlgorithm });

            } else {
              console.log('Algorithms query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Algorithms query did not work for some reason', error);
        });
      }
    }

    calculateMatches(emailId, passedPartner, scholars, wpData, interestsResults, skillsResults, personalityResults, passedMatchCriteria, passedSortDirection) {
      console.log('calculateMatches called', emailId, passedPartner, scholars, wpData, interestsResults, skillsResults, personalityResults)

      this.setState({ isSaving: true, loadingMatches: true })

      let matchCriteria = this.state.matchCriteria
      if (passedMatchCriteria) {
        matchCriteria = passedMatchCriteria
      }

      let sortDirection = this.state.sortDirection
      if (passedSortDirection) {
        sortDirection = passedSortDirection
      }

      let customAlgo = false
      if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.orgCode !== 'guidedcompass') {
        customAlgo = true
      }

      let algoSections = null
      if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.sections) {
        algoSections = this.state.mentorMatchingAlgorithm.sections
      }

      const resLimit = this.state.resLimit

      // console.log('show interest and personality results ', wpData, interestsResults, personalityResults)

      Axios.post('/api/assessment/partners/calculate', { emailId, partners: scholars, passedPartner, wpData, interestsResults, skillsResults, personalityResults, matchCriteria, sortDirection, orgCode: this.state.org, customAlgo, algoSections, resLimit })
      .then((response) => {
        console.log('Student match query worked', response.data);

        if (response.data.success) {
          console.log('successfully retrieved matches')

          const matches = response.data.matches
          // const responseMatches2 = response.data.partnerMatches
          // console.log('lets see: ', matches, responseMatches2)

          // let matches = []
          // if (responseMatches) {
          //   matches = responseMatches2
          //   for (let i = 1; i <= responseMatches.length; i++) {
          //     for (let j = 1; j <= this.state.partnerMatches.length; j++) {
          //       if (responseMatches[i - 1].partner.email === this.state.partnerMatches[j - 1].email) {
          //         matches.splice(i - 1,1)
          //       }
          //     }
          //   }
          // }

          this.setState({ matches, isSaving: false, loadingMatches: false })
          this.indicateWhetherPaired(matches, null)

        } else {
          console.log('there was an error matching partners', response.data.message)
          this.setState({ isSaving: false, loadingMatches: false })
        }

      }).catch((error) => {
          console.log('Partner match did not work', error);
          this.setState({ isSaving: false, loadingMatches: false })
      });
    }

    indicateWhetherPaired(matches, selectedStudent) {
      console.log('indicateWhetherPaired called', matches, selectedStudent)

      if (matches) {
        console.log('up in matches')
        for (let i = 1; i <= matches.length; i++) {

          const index = i - 1

          Axios.get('/api/partners', { params: { partnerEmail: matches[index].partner.email, active: true } })
          .then((response) => {
            console.log('Partner query attempted to verify whether paired', response.data);

            if (response.data.success) {
              // console.log('partner query worked')

              if (response.data.partners && response.data.partners.length !== 0) {
                if (this.state.oneMentorPerMentee) {
                  matches[index]['isPaired'] = true
                  this.setState({ matches });
                } else if (!this.state.oneMentorPerMentee && response.data.partners.some(p => p.advisees.some(adv => adv.email === matches[index].partner.email))) {
                  matches[index]['isPaired'] = true
                  this.setState({ matches });
                } else {
                  matches[index]['isPaired'] = false
                  this.setState({ matches });
                }

              } else {
                matches[index]['isPaired'] = false
                this.setState({ matches });
              }

            } else {
              // console.log('query for partners query did not work', response.data.message)
              matches[index]['isPaired'] = false
              this.setState({ matches });
            }

          }).catch((error) => {
              // console.log('Partners query did not work for some reason', error);
              matches[index]['isPaired'] = false
              this.setState({ matches });
          });
        }
      } else {
        // console.log('up in changeRecipient')

        Axios.get('/api/partners', { params: { partnerEmail: selectedStudent.email, active: true } })
        .then((response) => {
          console.log('Partner query attempted 2', response.data);

          if (response.data.success) {
            console.log('partner query worked')

            if (response.data.partners && response.data.partners.length !== 0) {

              let recipients = this.state.recipients
              if (recipients) {

                if (this.state.oneMentorPerMentee) {
                  recipients[0]['isPaired'] = true
                  this.setState({ recipients });
                } else if (!this.state.oneMentorPerMentee && response.data.partners.some(p => p.advisees.some(adv => adv.email === selectedStudent.email))) {
                  recipients[0]['isPaired'] = true
                  this.setState({ recipients });
                } else {
                  recipients[0]['isPaired'] = false
                  this.setState({ recipients });
                }
              }

            } else {
              let recipients = this.state.recipients
              if (recipients) {
                recipients[0]['isPaired'] = false
                this.setState({ recipients });
              }
            }

          } else {
            console.log('query for partners query did not work', response.data.message)
            let recipients = this.state.recipients
            if (recipients) {
              recipients[0]['isPaired'] = false
              this.setState({ recipients });
            }
          }

        }).catch((error) => {
            console.log('Partners query did not work for some reason', error);
            let recipients = this.state.recipients
            if (recipients) {
              recipients[0]['isPaired'] = false
              this.setState({ recipients });
            }
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'track') {

        let trackSelected = event.target.value
        let matchIndex = 0
        for (let i = 1; i <= this.state.trackOptions.length; i++) {
          console.log(i);
          if (trackSelected === this.state.trackOptions[i - 1]) {
            matchIndex = i - 1
          }
        }

        this.setState({ trackSelected, matchIndex })
      } else if (event.target.name === 'searchStudents') {
        this.setState({ [event.target.name]: event.target.value })
        this.filterResults(event.target.value, '', null, null, true, null)
      } else if (event.target.name === 'mentorMatchingAlgorithm') {
        let mentorMatchingAlgorithm = null
        for (let i = 1; i <= this.state.mentorMatchingAlgorithmOptions.length; i++) {
          if (this.state.mentorMatchingAlgorithmOptions[i - 1].name === event.target.value) {
            mentorMatchingAlgorithm = this.state.mentorMatchingAlgorithmOptions[i - 1]
          }
        }
        if (mentorMatchingAlgorithm && mentorMatchingAlgorithm.name === 'Add New') {
          this.setState({ mentorMatchingAlgorithm, modalIsOpen: true, showMentorMatchingAlgorithm: true })
        } else {
          this.setState({ mentorMatchingAlgorithm })
        }

      } else if (event.target.name.includes("caseNotes|")) {
        let caseNotes = this.state.caseNotes

        const nameArray = event.target.name.split('|')
        const name = nameArray[1]
        const index = Number(nameArray[2])

        caseNotes[index][name] = event.target.value

        this.setState({ caseNotes, formHasChanged: true })
      }
      console.log('testign 12')
    }

    filterResults(searchString, filterString, filters, index, search, searchType) {
      console.log('filterResults called', searchString, filterString, filters, index, search, searchType)

      const orgCode = this.state.org
      const roleNames = ["Student","Career-Seeker"]

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')
        self.setState({ animating: true, errorMessage: null, successMessage: null })

        Axios.get('/api/members/search', { params: { searchString, roleNames, orgCode } })
        .then((response) => {
          console.log('Members query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved members')

              let memberOptions = null
              if (response.data.members) {
                memberOptions = response.data.members
              }

              self.setState({ memberOptions, animating: false })

            } else {
              console.log('no course data found', response.data.message)
              self.setState({ animating: false, errorMessage: 'No courses were found that match that criteria' })
            }

        }).catch((error) => {
            console.log('Course query did not work', error);
            self.setState({ animating: false, errorMessage: 'Course query did not work for an unknown reason'})
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    renderMatchScore() {
      console.log('renderMatchScore called')

      const matchScore = this.state.matchScore
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

      return (
        <div key="match" className="width-150 center-item">
          <CircularProgressbar
            value={matchScore}
            text={`${matchScore}%`}
            styles={{
              path: { stroke: `${primaryColor}, ${matchScore / 100}` },
              text: { fill: primaryColor, fontSize: '24px' },
              trail: { stroke: 'transparent' }
            }}
          />
        </div>
      )
    }

    renderSection(section) {
      console.log('renderSection ', section)

      let rows = []

      if (section === 'personality') {

        if (this.state.personalityData && this.state.personalityData.fiveFactors) {
          rows.push(
            <div key={'personality'} className="right-padding">
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Openness</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.fiveFactors.opennessScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Conscientiousness</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.fiveFactors.conscientiousnessScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Extraversion</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.fiveFactors.extraversionScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Agreeableness</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.fiveFactors.agreeablenessScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Neuroticism</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.fiveFactors.neuroticismScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
              <div className="relative-column-40 description-text-2 right-padding-5">
                <p>Confidence</p>
              </div>
              <div className="relative-column-60 top-padding-5 left-padding-5">
                <div className="progress-bar" >
                  <div className="filler" style={{ width: this.state.personalityData.confidenceScore + '%' }} />
                </div>
              </div>
              <div className="clear"/>
            </div>
          )
        }

      } else if (section === 'work interests') {
        if (this.state.interestsData && this.state.interestsData.length > 0) {
          for (let i = 1; i <= this.state.interestsData.length; i++) {
            console.log('did this work: ', section)
            rows.push(
              <div key={i.toString + 'interests'} className="right-padding">
                <div className="relative-column-40 description-text-2 right-padding-5">
                  <p>{this.state.interestsData[i - 1].name}</p>
                </div>
                <div className="relative-column-60 top-padding-5 left-padding-5">
                  <div className="progress-bar" >
                    <div className="filler" style={{ width: this.state.interestsData[i - 1].score.toString() + '%' }} />
                  </div>
                </div>
                <div className="clear"/>
              </div>
            )
          }
        }
      }

      return rows
    }

    changeRecipients(index, action) {
      console.log('changeRecipient called', index, action)

      // let students = this.state.students

      if (action === 'add') {
        if (this.state.recipients.length === 0) {
          let recipients = this.state.recipients
          recipients.push(this.state.memberOptions[index])

          this.indicateWhetherPaired(null, recipients[0])

          this.setState({ recipients, searchStudents: '', formHasChanged: true })

          const emailId = this.state.emailId
          const partners = [this.state.memberOptions[index]]
          const wpData = this.state.wpData
          const interestsResults = this.state.interestsData
          const skillsResults = this.state.skillsData
          const personalityResults = this.state.personalityData
          const matchCriteria = 'Overall Match'
          const sortDirection = 'Descending'

          let customAlgo = false
          if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.orgCode !== 'guidedcompass') {
            customAlgo = true
          }

          let algoSections = null
          if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.sections) {
            algoSections = this.state.mentorMatchingAlgorithm.sections
          }

          const passedPartner = this.state.selectedMentor
          // console.log("____!", this.state.mentorMatchingAlgorithm, customAlgo)

          Axios.post('/api/assessment/partners/calculate', {
            emailId, passedPartner, partners, wpData, interestsResults, skillsResults, personalityResults, matchCriteria, sortDirection, customAlgo, algoSections
          })
          .then((response) => {
            console.log('Student match query worked', response.data);

            if (response.data.success) {

              console.log('successfully matched to mentors')
              if (response.data.matches && response.data.matches[0] && (response.data.matches[0].differential || response.data.matches[0].differential === 0)) {
                let matchScore = (100 - response.data.matches[0].differential).toFixed()
                recipients[0]['matchScore'] = matchScore
                recipients[0]['components'] = response.data.matches[0].components
                recipients[0]['combinedWeight'] = response.data.matches[0].combinedWeight
                this.setState({ matchScore, recipients })
              }

            } else {
              console.log('there was an error matching partners', response.data.message)
            }

          }).catch((error) => {
              console.log('Partner match did not work', error);
          });
        } else {
          this.setState({ sessionErrorMessage: 'There is currently a limit to one student'})
        }
      } else {
        //action === remove
        let recipients = this.state.recipients
        if (index > -1) {
          recipients.splice(index, 1);
        }
        this.setState({ recipients, formHasChanged: true })
      }
    }

    renderRecipients() {
      console.log('renderRecipients called', this.state.recipients)

      let rows = []

      for (let i = 1; i <= this.state.recipients.length; i++) {

        const key = "student" + i
        const index = i - 1

        rows.push(
          <div key={key} className="float-left">
            <div className="float-left">
              <p className="float-left">{this.state.recipients[i - 1].firstName} {this.state.recipients[i - 1].lastName}</p>
            </div>
            <div className="float-left horizontal-padding-4 top-margin-5">
              <button className="background-button float-left" onClick={() => this.changeRecipients(index, 'remove')}>
                <img src={deniedIcon} alt="Compass tap icon" className="image-auto-15 float-left"/>
              </button>
            </div>
          </div>
        )
      }

      return rows

    }

    renderTopMatches() {
      console.log('renderTopMatches called')

      let rows = []

      if (this.state.matches.length === 0) {

        rows.push(
          <div key={'mentor'}>
            <p>There is no student assessment data to compare against this mentor's assessment data.</p>
          </div>
        )
      } else {
        for (let i = 1; i <=this.state.matches.length; i++) {
          const index = i - 1

          let pathname = ''
          let passedState = {}
          if (window.location.pathname.includes('/organizations')) {
            pathname = '/organizations/' + this.state.org + '/members/' + this.state.matches[index].partner.username
            passedState =  { member: this.state.matches[index].partner }
          } else if (window.location.pathname.includes('/advisor')) {
            pathname = '/advisor/advisees/' + this.state.matches[index].partner.email
            passedState =  { advisee: this.state.matches[index].partner }
          }

          let matchScore = 0
          if (this.state.matches[i- 1].differential || this.state.matches[i- 1].differential === 0) {
            matchScore = (100 - this.state.matches[i - 1].differential).toFixed()
          }

          rows.push(
            <div key={i.toString() + 'topMatch'}>
              <div className="float-left right-padding-25">
                <Link to={pathname} state={passedState} className="background-link">
                  <p className="heading-text-6">{i}. {this.state.matches[i - 1].partner.firstName} {this.state.matches[i - 1].partner.lastName}</p>
                </Link>
              </div>
              <div className="float-left right-padding-25">
                <button to={pathname} state={passedState} className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showMatchCalculation: true, selectedMatch: this.state.matches[i - 1], selectedMatchScore: matchScore  })}>
                  <p className="heading-text-6">{matchScore}%</p>
                </button>
              </div>
              {(this.state.enableMatching && !this.state.matches[i - 1].isPaired) && (
                <div className="float-left right-margin-20">
                  <img src={(this.state.matches[i - 1].partner.openToMentoring === false) ? thumbsDownOrangeIcon : thumbsUpBlueIcon} alt="GC" className="image-auto-24 tooltip-anchor" data-tooltip-content={(this.state.matches[i - 1].partner.openToMentoring === false) ? "Doesn't want to be mentored" : "Wants to be mentored"} />
                  <ReactTooltip anchorSelect={".tooltip-anchor"}/>
                </div>
              )}

              {(this.state.matches[i - 1].isPaired) && (
                <div className="float-left right-margin-20 cta-border circle-corners">
                  <img src={(this.state.matches[i - 1].partner.pictureURL) ? this.state.matches[i - 1].partner.pictureURL : profileIconDark} alt="GC" className="image-auto-24" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Already paired with " + this.state.matches[i - 1].partner.firstName + " " + this.state.matches[i - 1].partner.lastName} />
                  <ReactTooltip id="tooltip-placeholder-id" />
                </div>
              )}
              {(this.state.enableMatching && !this.state.matches[i - 1].isPaired) && (
                <div className="float-left">
                  <button className="btn btn-small tooltip-anchor" data-tooltip-content="Match to this mentor" onClick={() => this.matchMentor(this.state.matches[index].partner, this.state.matches[i - 1].differential)}>Match</button>
                  <ReactTooltip anchorSelect={".tooltip-anchor"}/>
                </div>
              )}
              <div className="clear" />
              <div className="spacer" />
            </div>
          )
        }
      }

      return rows
    }

    matchMentor(mentee, differential) {
      console.log('matchMentor called', mentee, differential)

      this.setState({ serverErrorMessage: '', serverSuccessMessage: ''})

      let emailId = this.state.emailId
      let partnerMatches = this.state.partnerMatches

      let alreadyMatchedIndex = null
      if (partnerMatches && partnerMatches.length > 0) {
        for (let i = 1; i <= partnerMatches.length; i++) {
          console.log('show matches: ', partnerMatches[i - 1].email, mentee.email)
          if (partnerMatches[i - 1].email === mentee.email) {
            console.log('test 1')
            alreadyMatchedIndex = i - 1
          }
        }
      }

      if (alreadyMatchedIndex || alreadyMatchedIndex === 0) {
        console.log('nonzero alreadyMatchedIndex')
        if (alreadyMatchedIndex > -1) {
          console.log('these two have already been matched')
          partnerMatches.splice(alreadyMatchedIndex, 1);
          this.setState({ partnerMatches })

          const remove = true
          const advisorEmail = this.state.selectedMentor.email
          const adviseeEmail = mentee.email
          const oneMentorPerMentee = this.state.oneMentorPerMentee
          let matchScore = null
          if (differential && differential > -1) {
            matchScore = (100 - differential).toFixed()
          }

          Axios.post('/api/partners/admin', {
            emailId, remove, advisorEmail, adviseeEmail, oneMentorPerMentee, matchScore, doNotNotify: true })
          .then((response) => {
            console.log('partner matches query attempted')
            if (response.data.success) {
              //save values
              console.log('Partner matches save worked', response.data);

              let recipients = this.state.recipients
              if (recipients && recipients[0]) {
                recipients[0]['isPaired'] = false
              }

              this.setState({ serverSuccessMessage: response.data.message, recipients })

            } else {
              console.log('partner match did not work', response.data.message)
              this.setState({ serverErrorMessage: 'there was an error matching the mentee: ' + response.data.message })

            }
          }).catch((error) => {
              console.log('Partner matches did not work for some reason', error);
              this.setState({ serverErrorMessage: 'there was an error matching the mentee: ' + error })
          });
        }
      } else {
        console.log('these two have not matched yet')

        const advisorFirstName = this.state.selectedMentor.firstName
        const advisorLastName = this.state.selectedMentor.lastName
        const advisorEmail = this.state.selectedMentor.email
        const advisorUsername = this.state.selectedMentor.username
        const adviseeFirstName = mentee.firstName
        const adviseeLastName = mentee.lastName
        const adviseeEmail = mentee.email
        const adviseeUsername = mentee.username

        let mentorTitle = this.state.selectedMentor.jobTitle
        if (!mentorTitle || mentorTitle === '') {
          mentorTitle = 'Undisclosed Title'
        }

        let mentorEmployerName = this.state.selectedMentor.employerName
        if (!mentorEmployerName || mentorEmployerName === '') {
          mentorTitle = 'Undisclosed Employer'
        }

        let mentorPoliticalAlignment = this.state.selectedMentor.politicalAlignment
        if (!mentorPoliticalAlignment || mentorPoliticalAlignment === '') {
          mentorPoliticalAlignment = 'Undisclosed Political Alignment'
        }

        const requesterEmail = 'Admin'
        const relationship = 'Mentor'
        const isPrimary = true

        const orgCode = this.state.org
        const orgName = this.state.orgName
        const orgContactTitle = this.state.orgContactTitle
        const orgContactFirstName = this.state.orgContactFirstName
        const orgContactLastName = this.state.orgContactLastName
        const orgContactEmail = this.state.orgContactEmail
        const orgContactCCEmails = this.state.orgContactCCEmails

        let school = mentee.school
        if (!school || school === '') {
          school = 'Undisclosed'
        }

        let homeState = mentee.hometown
        if (!homeState || homeState === '') {
          homeState = 'Undisclosed'
        }

        let homeCongressionalDistrict = mentee.homeCongressionalDistrict
        if (!homeCongressionalDistrict || homeCongressionalDistrict === '') {
          homeCongressionalDistrict = 'Undisclosed'
        }

        let politicalAlignment = mentee.politicalAlignment
        if (!politicalAlignment || politicalAlignment === '') {
          politicalAlignment = 'Undisclosed'
        }

        let resumeURL = mentee.resumeURL
        if (!resumeURL || resumeURL === '') {
          resumeURL = 'Undisclosed'
        }
        const active = true
        const oneMentorPerMentee = this.state.oneMentorPerMentee
        let matchScore = null
        if (differential && differential > -1) {
          matchScore = (100 - differential).toFixed()
        }

        Axios.post('/api/partners/admin', {
          emailId, advisorFirstName, advisorLastName, advisorEmail, advisorUsername, adviseeFirstName, adviseeLastName,
          adviseeEmail, adviseeUsername, requesterEmail, relationship, isPrimary, orgCode, orgName, orgContactTitle,
          orgContactFirstName, orgContactLastName, orgContactEmail, orgContactCCEmails,
          mentorTitle, mentorEmployerName, mentorPoliticalAlignment,
          school, homeState, homeCongressionalDistrict, politicalAlignment, resumeURL,
          active, oneMentorPerMentee, matchScore, doNotNotify: true })
        .then((response) => {
          console.log('partner matches query attempted')
          if (response.data.success) {
            //save values
            console.log('Partner matches save worked', response.data);

            //remove new match
            let matches = this.state.matches
            for (let i = 1; i <= this.state.matches.length; i++) {
              console.log('show emails: ', this.state.matches[i - 1].partner.email, mentee.email)
              if (this.state.matches[i - 1].partner.email === mentee.email) {
                matches.splice(i - 1,1)
              }
            }

            partnerMatches.push({ firstName: mentee.firstName, lastName: mentee.lastName, email: mentee.email, username: mentee.username })
            let recipients = this.state.recipients
            if (recipients && recipients[0]) {
              recipients[0]['isPaired'] = true
            }
            this.setState({ serverSuccessMessage: 'Match successfully added', partnerMatches, matches, recipients })

          } else {
            console.log('partner match did not work', response.data.message)
            this.setState({ serverErrorMessage: 'error: ' + response.data.message })

          }
        }).catch((error) => {
            console.log('Partner matches did not work for some reason', error);
            this.setState({ serverErrorMessage: 'error: ' + error })
        });
      }
    }

    renderPartnerMatches() {
      console.log('renderPartnerMatches called')

      let rows = []

      for (let i = 1; i <= this.state.partnerMatches.length; i++) {
        // console.log('show matches: ', this.state.partnerMatches[i - 1])
        const index = i - 1
        const item = this.state.partnerMatches[index]

        let username = item.username
        if (!username) {
          username = item.firstName.toLowerCase() + item.lastName.toLowerCase()
        }

        let pathname = ''
        let passedState = {}
        if (window.location.pathname.includes('/organizations')) {
          pathname = '/organizations/' + this.state.org + '/members/' + username
          passedState =  { member: item }
        } else if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/advisees/' + item.email
          passedState =  { advisee: item }
        }

        rows.push(
          <div key={i.toString() + 'topMatch'}>
            <div className="float-left right-padding-25">
              <Link to={pathname} state={passedState} className="background-link">
                <p className="heading-text-6">{item.firstName} {item.lastName}</p>
              </Link>
            </div>
            {(item.matchScore || item.matchScore === 0) ? (
              <div className="float-left right-padding-25">
                <button className="background-button cta-color" onClick={() => this.recalculateMatchScore(item)}>
                  <p className="heading-text-6 cta-color">{item.matchScore}%</p>
                </button>
              </div>
            ) : (
              <div />
            )}
            <div className="float-left">
              <button className="btn btn-small white-background cta-color" onClick={() => this.matchMentor(item)}>Remove</button>
            </div>
            <div className="clear" />
            <div className="spacer" />
          </div>
        )
      }


      return rows
    }

    recalculateMatchScore(selectedMatch) {
      console.log('recalculateMatchScore called')

      Axios.get('/api/users/profile/details/' + selectedMatch.email)
      .then((response) => {
        if (response.data) {

          console.log('User details fetch worked', response.data)
          if (response.data.success) {

            const emailId = this.state.emailId
            const partners = [response.data.user]
            const wpData = this.state.wpData
            const interestsResults = this.state.interestsData
            const skillsResults = this.state.skillsData
            const personalityResults = this.state.personalityData
            const matchCriteria = 'Overall Match'
            const sortDirection = 'Descending'

            let customAlgo = false
            if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.orgCode !== 'guidedcompass') {
              customAlgo = true
            }

            let algoSections = null
            if (this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.sections) {
              algoSections = this.state.mentorMatchingAlgorithm.sections
            }

            const passedPartner = this.state.selectedMentor

            Axios.post('/api/assessment/partners/calculate', {
              emailId, partners, wpData, interestsResults, skillsResults, personalityResults, matchCriteria, sortDirection, customAlgo, algoSections, passedPartner
            })
            .then((response) => {
              console.log('Student match query worked', response.data);

              if (response.data.success) {

                console.log('successfully matched to mentors')
                if (response.data.matches && response.data.matches[0] && (response.data.matches[0].differential || response.data.matches[0].differential === 0)) {
                  // let matchScore = (100 - response.data.matches[0].differential).toFixed()
                  // selectedMatch['matchScore'] = (100 - response.data.matches[0].differential).toFixed()
                  selectedMatch['components'] = response.data.matches[0].components
                  selectedMatch['combinedWeight'] = response.data.matches[0].combinedWeight
                  this.setState({ modalIsOpen: true, showMatchCalculation: true, selectedMatch })

                }

              } else {
                console.log('there was an error matching partners', response.data.message)
              }

            }).catch((error) => {
                console.log('Partner match did not work', error);
            });

          } else {
            console.log('something went wrong!')
          }

        }
      }).catch((error) => {
          console.log('User details fetch did not work', error);
      });
    }

    addItem(type) {
      console.log('addItem called')

      let caseNotes = this.state.caseNotes
      if (caseNotes) {
        caseNotes.push({ caseDate: null, workerName: null, notes: null })
      } else {
        caseNotes = [{ caseDate: null, workerName: null, notes: null }]
      }

      this.setState({ caseNotes, formHasChanged: true })

    }

    deleteItem(index,type) {
      console.log('deleteItem called', index,type)

      if (type === 'caseNote') {
        let caseNotes = this.state.caseNotes
        caseNotes.splice(index, 1)
        this.setState({ caseNotes, formHasChanged: true })
      }
    }

    saveStudent() {
      console.log('saveStudent called')

      const email = this.state.selectedMentor.email
      const caseNotes = this.state.caseNotes
      // const orgCode = this.state.org
      const updatedAt = new Date()

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      Axios.post('/api/members/evaluations', { email, caseNotes, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Student save worked', response.data);
          this.setState({ successMessage: 'Student updated successfully!' })

        } else {
          console.error('there was an error updating the student');
          this.setState({ isSaving: true, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('Student save did not work', error);
          this.setState({ isSaving: true, errorMessage: error.toString() })
      });
    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false, showMentorMatchingAlgorithm: false,
        selectedAlgorithm: null, showMatchCalculation: false, selectedMatch: null, selectedMatchScore: null
      });
    }

    render() {

      let pathname = ''
      let passedState = {}
      if (this.state.recipients.length > 0) {
        if (window.location.pathname.includes('/organizations')) {
          pathname = '/organizations/' + this.state.org + '/members/' + this.state.recipients[0].username
          passedState =  { member: this.state.recipients[0] }
        } else if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/advisees/' + this.state.recipients[0].email
          passedState =  { advisee: this.state.recipients[0] }
        }
      }

      return (
          <div>
            {(this.state.selectedMentor) ? (
              <div>
                <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : ""}>
                  <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : ""}>
                    {(window.innerWidth > 768) && (
                      <div className="full-width height-5 dark-background" />
                    )}

                    <div className={(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) ? "padding-40" : ""}>
                      <div className="padding-20">
                        <p className="heading-text-2">{this.state.selectedMentor.firstName} {this.state.selectedMentor.lastName}</p>
                        <p className="top-margin-7 description-text-color">Match mentees to {this.state.selectedMentor.firstName}</p>
                      </div>
                    </div>
                  </div>

                  <div className={(window.innerWidth > 768) ? "full-width max-width-1400 top-margin-40 pin-right" : ""}>
                    <div className="full-width vertical-margin-2-percent center-horizontally">
                      <div className="standard-border container-left clear-padding clear-margin white-background medium-shadow">
                        <div className="standard-border float-left full-width row-3 horizontal-padding-7">
                          <div className="fixed-column-30 top-padding right-padding-3 left-padding-5">
                            <img src={searchIcon} alt="GC" className="image-auto-18" />
                          </div>
                          <div className="filter-search-container calc-column-offset-30" >
                            <input type="text" className="text-field clear-border" placeholder="Search a career-seeker to assess match..." name="searchStudents" autoComplete="off" value={this.state.searchStudents} onChange={this.formChangeHandler}/>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.animating) ? (
                          <div className="flex-container flex-center full-space">
                            <div>
                              <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                              <div className="spacer" /><div className="spacer" /><div className="spacer" />
                              <p className="center-text cta-color bold-text">Pulling search results...</p>

                            </div>
                          </div>
                        ) : (
                          <div>
                            {(this.state.memberOptions && this.state.memberOptions.length > 0 && this.state.searchStudents && this.state.searchStudents !== '') && (
                              <div>
                                {this.state.memberOptions.map((value, optionIndex2) =>
                                  <div key={value._id}>
                                    <button className="background-button full-width left-text" onClick={() => this.changeRecipients(optionIndex2,'add')}>
                                      <div className="left-padding full-width row-5">
                                        <div className="fixed-column-50">
                                          <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                          <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                        </div>
                                        <div className="calc-column-offset-50 top-padding-5">
                                          <p>{value.firstName} {value.lastName}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.recipients.length > 0 && (
                          <div className="float-left margin-5 row-5 horizontal-padding cta-background-color rounded-corner">
                            {this.renderRecipients()}
                            <div className="spacer"/>
                          </div>
                        )}
                        <div className="clear"/>
                      </div>

                      <div className="clear"/>
                    </div>
                  </div>
                  <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : ""}>
                    {(window.innerWidth > 768) && (
                      <div className="full-width height-5 dark-background" />
                    )}

                    <div className={(window.innerWidth > 768 && this.state._id && !window.location.pathname.includes('/walkthrough')) ? "padding-40" : ""}>
                      <div>
                        {(this.state.recipients.length > 0) && (
                          <div className="padding-20">
                            <p className="heading-text-3">Selected Student</p>
                            <div className="half-spacer" />

                            <div className="float-left right-margin-20">
                              <Link to={pathname} state={passedState} className="background-link">
                                <p className="heading-text-6">{this.state.recipients[0].firstName} {this.state.recipients[0].lastName}</p>
                              </Link>
                            </div>
                            {(this.state.recipients[0].matchScore || this.state.recipients[0].matchScore === 0) ? (
                              <button className="float-left background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showMatchCalculation: true, selectedMatch: this.state.recipients[0], selectedMatchScore: this.state.recipients[0].matchScore })}>
                                <div className="float-left right-margin-20">
                                  <p className="heading-text-6 cta-color">{this.state.recipients[0].matchScore}%</p>
                                </div>
                              </button>
                            ) : (
                              <div className="float-left right-margin-20">
                                <p className="heading-text-6 cta-color">N/A</p>
                              </div>
                            )}
                            {(this.state.recipients[0].isPaired) && (
                              <div className="float-left right-margin-20 cta-border circle-corners">
                                <img src={(this.state.recipients[0].pictureURL) ? this.state.recipients[0].pictureURL : profileIconDark} alt="GC" className="image-auto-24" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Already paired with " + this.state.recipients[0].firstName + " " + this.state.recipients[0].lastName} />
                                <ReactTooltip id="tooltip-placeholder-id" />
                              </div>
                            )}
                            {(this.state.enableMatching && !this.state.recipients[0].isPaired) && (
                              <div className="float-left">
                                <button className="btn btn-small" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match to this mentor" onClick={() => this.matchMentor(this.state.recipients[0], (100 - this.state.recipients[0].matchScore))}>Match</button>
                              </div>
                            )}
                            <ReactTooltip id="tooltip-placeholder-id" />

                            <div className="clear" />
                          </div>
                        )}

                        <div className="padding-20">
                          <p className="heading-text-3">Matched Mentee(s)</p>
                          <div className="half-spacer" />

                          {(this.state.partnerMatches && this.state.partnerMatches.length > 0) ? (
                            <div>
                              {this.renderPartnerMatches()}
                            </div>
                          ) : (
                            <div>
                              <p>No mentees have been matched to this mentor yet.</p>
                            </div>
                          )}

                          <div className="clear"/>
                        </div>

                        <div className="padding-20">
                          <p className="heading-text-3">Top Student Matches</p>
                          <div className="spacer" />

                          {(this.state.loadingMatches) ? (
                            <div className="padding-30">
                              <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                              <div className="spacer" /><div className="spacer" /><div className="spacer" />
                              <p className="center-text cta-color bold-text full-width">Pulling search results...</p>
                            </div>
                          ) : (
                            <div>
                              {this.renderTopMatches()}
                              <div className="clear"/>
                            </div>
                          )}
                        </div>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <div className="left-padding-30">
                          {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                          {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {(window.innerWidth > 768) && (
                  <div className="relative-column-30 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <div>
                            <div className="row-10">
                              <div className="calc-column-offset-25">
                                <p className="heading-text-3">Matching Algorithm</p>
                              </div>
                              <div className="float-column-25 left-padding top-padding-5">
                                <button onClick={() => this.setState({ modalIsOpen: true, showMentorMatchingAlgorithm: true, selectedAlgorithm: null })} className="background-button">
                                  <div className="padding-5 standard-border rounded-corners">
                                    <img src={addIcon} alt="GC" className="image-auto-10 pin-right" />
                                  </div>
                                </button>
                              </div>
                              <div className="clear"/>
                            </div>

                            <div className="row-15">
                              <div className="row-10">
                                <select name={"mentorMatchingAlgorithm"} className="dropdown" value={this.state.mentorMatchingAlgorithm.name} onChange={this.formChangeHandler}>
                                  {this.state.mentorMatchingAlgorithmOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                                </select>
                              </div>

                              {(this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.name !== 'Add New') && (
                                <button className="background-button cta-color left-text bottom-margin" onClick={() => this.setState({ modalIsOpen: true, showMentorMatchingAlgorithm: true, selectedAlgorithm: this.state.mentorMatchingAlgorithm })}>
                                  <p>View Algorithm Details</p>
                                </button>
                              )}
                            </div>

                            <button className="btn btn-squarish pin-right" disabled={(this.state.isSaving) ? true : false} onClick={() => this.calculateMatches(this.state.emailId, this.state.selectedMentor, this.state.students, this.state.wpData, this.state.interestsData, this.state.skillsAnswers, this.state.personalityData, null, 'Descending')}>Calculate</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            ) : (
              <p className="error-color padding-30">No mentor selected. Go back and select a mentor.</p>
            )}
            {/*
            {(this.state.selectedMentor) && (
              <div className="width-70-percent vertical-margin-2-percent center-horizontally">
                <div className="standard-border container-left clear-padding clear-margin white-background medium-shadow">
                  <div className="standard-border float-left full-width row-3 horizontal-padding-7">
                    <div className="search-icon-container right-margin-5">
                      <div className="mini-spacer" />
                      <img src={searchIcon} alt="GC" className="image-auto-18" />
                    </div>
                    <div className="filter-search-container width-80-percent" >
                      <input type="text" className="text-field clear-border" placeholder="Search a career-seeker to assess match..." name="searchStudents" autoComplete="off" value={this.state.searchStudents} onChange={this.formChangeHandler}/>
                    </div>
                  </div>

                  {(this.state.animating) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Pulling search results...</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      {(this.state.memberOptions && this.state.memberOptions.length > 0 && this.state.searchStudents && this.state.searchStudents !== '') && (
                        <div>
                          {this.state.memberOptions.map((value, optionIndex2) =>
                            <div key={value._id}>
                              <button className="background-button full-width left-text" onClick={() => this.changeRecipients(optionIndex2,'add')}>
                                <div className="left-padding full-width row-5">
                                  <div className="fixed-column-50">
                                    <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-30" />
                                  </div>
                                  <div className="calc-column-offset-50 top-padding-5">
                                    <p>{value.firstName} {value.lastName}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.recipients.length > 0 && (
                    <div className="float-left margin-5 row-5 horizontal-padding cta-background-color rounded-corner">
                      {this.renderRecipients()}
                      <div className="spacer"/>
                    </div>
                  )}
                  <div className="clear"/>
                </div>

                <div className="clear"/>
              </div>
            )}


            <div className="candidate-container">
                <div>
                  {(this.state.selectedMentor) ? (
                    <div>

                      <div className="relative-column-30 height-600 center-item mentor-background-color center-text padding-20">

                        {(this.state.matchScore === undefined || this.state.matchScore !== 0) ? (
                          <div>
                            {this.renderMatchScore()}
                          </div>
                        ) : (
                          <div>
                            <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                          </div>
                        )}

                        <p className="heading-text-2">{this.state.selectedMentor.firstName} {this.state.selectedMentor.lastName}</p>

                        <div className="spacer"/><div className="spacer"/>
                        <hr className="cta-border-color horizontal-margin-40"/>
                        <div className="spacer"/>

                        <div className="spacer" />
                        <img src={(this.state.selectedMentor.pictureURL) ? this.state.selectedMentor.pictureURL : profileIconDark} alt="GC" className="applicant-image-100"/>
                        <div className="spacer"/><div className="spacer"/>
                        {(this.state.org === 'c2c') && (
                          <div>
                            <p className="description-text-3">{this.state.selectedMentor.email}</p>
                            <div className="spacer"/>
                          </div>
                        )}

                        <div className="full-width">
                          <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                            {(this.state.selectedMentor.dateOfBirth) && (
                              <p className="row-5">Age</p>
                            )}

                            {(this.state.wpData && this.state.wpData[2]) && (
                              <p className="row-5">Work Title</p>
                            )}

                            {(this.state.wpData && this.state.wpData[6]) && (
                              <p className="row-5">Employer</p>
                            )}
                            {(this.state.org !== 'c2c') && (
                              <div>
                                {(this.state.wpData && this.state.wpData[11]) && (
                                  <p className="row-5">Function</p>
                                )}

                                {(this.state.wpData && this.state.wpData[12]) && (
                                  <p className="row-5">Industry</p>
                                )}

                                {(this.state.wpData && this.state.wpData[13]) && (
                                  <p className="row-5">Fit</p>
                                )}

                                {(this.state.selectedMentor.location) && (
                                  <p className="row-5">>Location</p>
                                )}
                              </div>
                            )}

                          </div>

                          <div className="relative-column-60 left-text description-text-2">
                            {(this.state.selectedMentor.dateOfBirth) && (
                              <p className="row-5">{new Date().getFullYear() - Number(this.state.selectedMentor.dateOfBirth.substring(6,10))}</p>
                            )}

                            {(this.state.wpData && this.state.wpData[2]) && (
                              <p className="row-5">{this.state.wpData[2]}</p>
                            )}

                            {(this.state.wpData && this.state.wpData[6]) && (
                              <p className="row-5">{this.state.wpData && this.state.wpData[6]}</p>
                            )}

                            {(this.state.org !== 'c2c') && (
                              <div>
                                {(this.state.wpData && this.state.wpData[11]) && (
                                  <p className="row-5">{this.state.wpData && this.state.wpData[11]}</p>
                                )}

                                {(this.state.wpData && this.state.wpData[12]) && (
                                  <p className="row-5">{this.state.wpData && this.state.wpData[12]}</p>
                                )}

                                {(this.state.wpData && this.state.wpData[13]) && (
                                  <p className="row-5">{this.state.wpData && this.state.wpData[13]}</p>
                                )}

                                {(this.state.selectedMentor.location) && (
                                  <p className="row-5">{this.state.selectedMentor.location}</p>
                                )}
                              </div>
                            )}

                          </div>

                          <div className="clear" />

                          {(this.state.org === 'c2c') && (
                            <div>
                              <div className="spacer"/><div className="half-spacer"/>
                              <hr className="clear-padding horizontal-margin-20" />
                              <div className="spacer"/><div className="half-spacer"/>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Career Track</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.careerTrack}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Politics</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.politicalAlignment}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Current State</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.stateRegistration}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Current District</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.congressionalDistrict}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Home State</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.hometown}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                              <div>
                                <div className="relative-column-40 left-text left-padding-20 description-text-2 description-text-color">
                                  <p>Home District</p>
                                </div>
                                <div className="relative-column-60 left-text description-text-2">
                                  <p>{this.state.selectedMentor.homeCongressionalDistrict}</p>
                                </div>
                                <div className="clear" />
                                <div className="spacer"/>
                              </div>

                            </div>
                          )}

                        </div>

                        {
                        //resume, cover letter, portfolio
                        //message
                      }
                      </div>
                      <div className="relative-column-70 height-600">
                        <div className="light-background height-100 padding-20">
                          <div>
                            <p className="description-text-4 bold-text">SCHEDULE & WORK PREFERENCES</p>
                            {(this.state.wpData && this.state.wpData[9]) ? (
                              <p className="description-text-1">{this.state.selectedMentor.firstName} works {this.state.wpData && this.state.wpData[9]} hours a week. {this.state.selectedMentor.firstName} has not shared what she does in his/her free time.</p>
                            ) : (
                              <p className="description-text-1">{this.state.selectedMentor.firstName} has not yet shared how many hours a week (s)he work and what she does in his/her free time.</p>
                            )}

                          </div>
                        </div>
                        <div className="padding-20">

                          <div className="spacer" />
                          <div className="container-left">
                            <p className="description-text-4 bold-text">GOALS</p>
                            <div className="half-spacer" />

                            <div className="description-text-1 left-padding-20">
                            {(this.state.pgpData && this.state.pgpData[0]) ? (
                              <p>{this.state.pgpData[0]}</p>
                            ) : (
                              <p>{this.state.selectedMentor.firstName} has not shared his/her goals yet.</p>
                            )}
                            </div>
                            <div className="spacer" /><div className="half-spacer" />
                          </div>
                          <div className="container-right">
                            <p className="description-text-4 bold-text">MOTIVATIONS</p>
                            <div className="half-spacer" />

                            {(this.state.pgpData && this.state.pgpData[2]) ? (
                              <p>{this.state.pgpData[2]}</p>
                            ) : (
                              <p>{this.state.selectedMentor.firstName} has not shared his/her motivations yet.</p>
                            )}
                            <div className="spacer" /><div className="half-spacer" />
                          </div>
                        </div>
                        <div className="clear"/>
                        <div className="spacer" />
                        <hr className="horizontal-margin-3" />
                        <div className="spacer" />

                        <div className="height-100 padding-20">
                          <p className="description-text-4 bold-text">BRANDS & INFLUENCERS</p>
                          <div className="float-left left-margin top-margin">
                            {(this.state.pgpData && this.state.pgpData[3]) ? (
                              <div>
                                <label className="description-text-2">Brands: </label>
                                {JSON.parse(this.state.pgpData[3]).map(value =>
                                  <label className="description-text-2">{value}, </label>
                                )}
                              </div>
                            ) : (
                              <p className="description-text-2">{this.state.selectedMentor.firstName} has not shared any brands they love yet.</p>
                            )}

                            <div className="spacer" />

                            {(this.state.pgpData && this.state.pgpData[4]) ? (
                              <div>
                                <label className="description-text-2">Influencers / Leaders: </label>
                                {JSON.parse(this.state.pgpData[4]).map(value =>
                                  <label className="description-text-2">{value}, </label>
                                )}
                              </div>
                            ) : (
                              <p className="description-text-2">{this.state.selectedMentor.firstName} has not shared any influencers they love yet.</p>
                            )}
                          </div>
                        </div>

                        <div className="clear"/>
                        <div className="spacer" /><div className="spacer" />

                        <hr className="horizontal-margin-3" />
                        <div className="spacer" />

                        <div className="height-100 padding-20">
                          <p className="description-text-4 bold-text">PERSONALITY & WORK INTERESTS</p>
                          <div className="half-spacer" />

                          <div className="full-width">
                            <div className="container-left">
                              {this.renderSection('personality')}
                            </div>
                            <div className="container-right">
                              {this.renderSection('work interests')}
                            </div>

                            <div className="clear"/>
                          </div>

                        </div>
                      </div>

                      <div className="clear"/>

                      <hr className="clear-padding clear-margin" />
                      <div className="spacer" /><div className="spacer" />

                      <div className="standard-border padding-20">
                        <div className="row-10">
                          <div className="float-left">
                            <p className="heading-text-3">Case Notes</p>
                          </div>
                          <div className="float-left left-padding top-padding-5">
                            <button onClick={() => this.addItem('case note')} className="background-button">
                              <div className="padding-5 standard-border rounded-corners">
                                <img src={addIcon} alt="GC" className="image-auto-10" />
                              </div>
                            </button>
                          </div>
                          <div className="clear"/>
                        </div>

                        {(this.state.caseNotes && this.state.caseNotes.length > 0) && (
                          <div>
                            {this.state.caseNotes.map((value, optionIndex) =>
                              <div key={value}>
                                <div className="top-margin">
                                  <div className="close-button-container-2" >
                                    <button type="button" onClick={() => this.deleteItem(optionIndex,'caseNote')} className="background-button">
                                      <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                    </button>
                                  </div>
                                  <div className="standard-border padding-20 left-margin">
                                    <div className="container-left">
                                      <p className="profile-descriptor">Date</p>
                                      <input type="datetime-local" className="date-field half-bold-text" placeholder="Add a date..." name={"caseNotes|caseDate|" + optionIndex} value={value.caseDate} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="container-right">
                                      <p className="profile-descriptor">Staff Name</p>
                                      <select name={"caseNotes|workerName|" + optionIndex} className="dropdown" value={value.workerName} onChange={this.formChangeHandler}>
                                        {this.state.staffMemberOptions.map(value => <option key={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""} value={(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}>{(value && value.firstName) ? value.firstName + ' ' + value.lastName : ""}</option>)}
                                      </select>
                                    </div>
                                    <div className="clear" />
                                    <div className="top-margin">
                                      <textarea type="text" className="text-field" placeholder="Add notes on this candidate..." name={"caseNotes|notes|" + optionIndex} value={value.notes} onChange={(this.formChangeHandler)}></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="clear"/>

                          </div>
                        )}

                        {(this.state.formHasChanged) && (
                          <div>
                            <div className="row-20">
                              <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.saveStudent()}>Save Changes</button>
                            </div>

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 bottom-margin-20">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 bottom-margin-20">{this.state.successMessage}</p>}
                          </div>
                        )}

                      </div>

                      <div className="standard-border padding-20">
                        <div className="row-10">
                          <div className="float-left">
                            <p className="heading-text-3">Matching Algorithm</p>
                          </div>
                          <div className="float-left left-padding top-padding-5">
                            <button onClick={() => this.setState({ modalIsOpen: true, showMentorMatchingAlgorithm: true, selectedAlgorithm: null })} className="background-button">
                              <div className="padding-5 standard-border rounded-corners">
                                <img src={addIcon} alt="GC" className="image-auto-10" />
                              </div>
                            </button>
                          </div>
                          <div className="clear"/>
                        </div>

                        <div className="row-10">
                          <div className="calc-column-offset-100">
                            <select name={"mentorMatchingAlgorithm"} className="dropdown" value={this.state.mentorMatchingAlgorithm.name} onChange={this.formChangeHandler}>
                              {this.state.mentorMatchingAlgorithmOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                            </select>
                          </div>
                          <div className="fixed-column-100 left-padding">
                            <button className="btn btn-squarish pin-right" disabled={(this.state.isSaving) ? true : false} onClick={() => this.calculateMatches(this.state.emailId, this.state.selectedMentor, this.state.students, this.state.wpData, this.state.interestsData, this.state.skillsAnswers, this.state.personalityData, null, 'Descending')}>Calculate</button>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.mentorMatchingAlgorithm && this.state.mentorMatchingAlgorithm.name !== 'Default' && this.state.mentorMatchingAlgorithm.name !== 'Add New') && (
                          <button className="background-button cta-color left-text bottom-margin" onClick={() => this.setState({ modalIsOpen: true, showMentorMatchingAlgorithm: true, selectedAlgorithm: this.state.mentorMatchingAlgorithm })}>
                            <p>View Algorithm Details</p>
                          </button>
                        )}

                      </div>

                      {(this.state.recipients.length > 0) && (
                        <div className="padding-20">
                          <p className="heading-text-3">Selected Student</p>
                          <div className="half-spacer" />

                          <div className="float-left right-margin-20">
                            <Link to={pathname} state={passedState} className="background-link">
                              <p className="heading-text-6">{this.state.recipients[0].firstName} {this.state.recipients[0].lastName}</p>
                            </Link>
                          </div>
                          {(this.state.recipients[0].isPaired) && (
                            <div className="float-left right-margin-20 cta-border circle-corners">
                              <img src={(this.state.recipients[0].pictureURL) ? this.state.recipients[0].pictureURL : profileIconDark} alt="GC" className="image-auto-24" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Already paired with " + this.state.recipients[0].firstName + " " + this.state.recipients[0].lastName} />
                              <ReactTooltip id="tooltip-placeholder-id" />
                            </div>
                          )}
                          {(this.state.recipients[0].matchScore) && (
                            <div className="float-left right-margin-20">
                              <p className="heading-text-6 cta-color">{this.state.recipients[0].matchScore}%</p>
                            </div>
                          )}
                          {(this.state.enableMatching && !this.state.recipients[0].isPaired) && (
                            <div className="float-left">
                              <button className="btn btn-small" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match to this mentor" onClick={() => this.matchMentor(this.state.recipients[0], (100 - this.state.recipients[0].matchScore))}>Match</button>
                            </div>
                          )}
                          <ReactTooltip id="tooltip-placeholder-id" />

                          <div className="clear" />
                        </div>
                      )}

                      <div className="padding-20">
                        <p className="heading-text-3">Matched Mentee(s)</p>
                        <div className="half-spacer" />

                        {(this.state.partnerMatches && this.state.partnerMatches.length > 0) ? (
                          <div>
                            {this.renderPartnerMatches()}
                          </div>
                        ) : (
                          <div>
                            <p>No mentees have been matched to this mentor yet.</p>
                          </div>
                        )}

                        <div className="clear"/>
                      </div>

                      <div className="padding-20">
                        <p className="heading-text-3">Top Student Matches</p>
                        <div className="spacer" />
                        {this.renderTopMatches()}
                        <div className="clear"/>
                      </div>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                      <div className="left-padding-30">
                        {(this.state.serverErrorMessage !== '') && <p className="error-message">{this.state.serverErrorMessage}</p>}
                        {(this.state.serverSuccessMessage !== '') && <p className="success-message">{this.state.serverSuccessMessage}</p>}
                      </div>

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    </div>
                  ) : (
                    <p className="error-color padding-30">No mentor selected. Go back and select a mentor.</p>
                  )}
                </div>
            </div>*/}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
            >
              {(this.state.showMentorMatchingAlgorithm) && (
                <div key="showMentorMatchingAlgorithm">
                  <SubMentorMatchingAlgorithm history={this.props.navigate} selectedAlgorithm={this.state.selectedAlgorithm} closeModal={this.closeModal} />
                </div>
              )}

              {(this.state.showMatchCalculation) && (
                <div key="showMatchCalculation">
                  <div className="calc-column-offset-40">
                    <p className="heading-text-2">{(this.state.selectedMatch.partner) ? this.state.selectedMatch.partner.firstName + " " + this.state.selectedMatch.partner.lastName : this.state.selectedMatch.firstName + " " + this.state.selectedMatch.lastName}</p>
                    <p className="top-margin-5">Match Score Calculation</p>
                  </div>
                  <div className="fixed-column-40">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={deniedIcon} alt="GC" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="clear" />

                  <div className="spacer" /><div className="spacer" />

                  {(this.state.selectedMatch.components) && (
                    <div className="description-text-1">
                      <div className="row-10 bold-text">
                        <div className="container-left">
                          <p>Name</p>
                        </div>
                        <div className="container-right">
                          <div className="relative-column-25">
                            <p>Failed</p>
                          </div>
                          <div className="relative-column-25">
                            <p>Value</p>
                          </div>
                          <div className="relative-column-25">
                            <p>Weight</p>
                          </div>
                          <div className="relative-column-25">
                            <p>WV</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>

                      <hr />

                      {this.state.selectedMatch.components.map((item, optionIndex2) =>
                        <div key={item.name} className="top-margin">
                          <div className="container-left">
                            <p>{item.name}</p>
                          </div>
                          <div className="container-right">
                            <div className="relative-column-25">
                              <p>{(item.failedRequirements) ? 'TRUE' : 'FALSE'}</p>
                            </div>
                            <div className="relative-column-25">
                              <p>{item.value}</p>
                            </div>
                            <div className="relative-column-25">
                              <p>{item.weight}%</p>
                            </div>
                            <div className="relative-column-25">
                              <p>{(item.failedRequirements) ? "FAILED" : (this.state.selectedMatch.combinedWeight) ? (item.value * (item.weight/this.state.selectedMatch.combinedWeight)).toFixed() + '%' : "0%"}</p>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      <div className="half-spacer" />
                      <hr />

                      <div className="row-10 bold-text">
                        <div className="container-left">
                          <p>Total</p>
                        </div>
                        <div className="container-right">
                          <div className="relative-column-25 height-40" />
                          <div className="relative-column-25 height-40" />
                          <div className="relative-column-25">
                            <p>{this.state.selectedMatch.combinedWeight}%</p>
                          </div>
                          <div className="relative-column-25">
                            <p>{(this.state.selectedMatch.matchScore) ? this.state.selectedMatch.matchScore : this.state.selectedMatchScore}%</p>
                          </div>
                          <div className="clear" />
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  )}
                </div>
              )}

            </Modal>
          </div>

      )
    }
}


export default withRouter(MatchMentor);
