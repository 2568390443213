import React, {Component} from 'react';
import Axios from 'axios';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutFormIdea from './CheckoutFormIdea';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeTestPromise = loadStripe("pk_test_51I6htEBMA9yyOaS5GG6dCAJXzACvstyF0j5v6xIZJkHCRlkvvz7VlQzLqAT26bd8TBCyW2WuYyjWmu4fsdPYF6cT00zf4fma2z");
const stripeLivePromise = loadStripe("pk_live_51I6htEBMA9yyOaS5WfSIqrWCbixiXQ7Fm3iRpTd4grD1jfXAHVOUv1UYLuGY5Gi92TPBumqYGCGfbIr7c059mzwg00KxKIJB0l");

class PreCheckoutIdea extends Component {
    constructor(props) {
      super(props)
      this.state = {
        testingStripe: false,
        price: 100,

        availableOrgs: ['unite-la'],
        discount: 0,
        promo: 0,

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)

      this.checkEntry = this.checkEntry.bind(this)
      this.createCustomer = this.createCustomer.bind(this)

      // this.subscribe = this.subscribe.bind(this)
      this.passProductData = this.passProductData.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

      let firstName = localStorage.getItem('firstName')
      let lastName = localStorage.getItem('lastName')
      let email = localStorage.getItem('email')
      let username = localStorage.getItem('username')
      let pictureURL = localStorage.getItem('pictureURL')
      let activeOrg = localStorage.getItem('activeOrg')
      let orgName = localStorage.getItem('orgName')

      if (this.props.firstName) {
        firstName = this.props.firstName
      }
      if (this.props.lastName) {
        lastName = this.props.lastName
      }
      if (this.props.email) {
        email = this.props.email
      }
      if (this.props.username) {
        username = this.props.username
      }
      if (this.props.pictureURL) {
        pictureURL = this.props.pictureURL
      }
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }
      if (this.props.orgName) {
        orgName = this.props.orgName
      }

      this.createCustomer({ firstName, lastName, email, username, pictureURL, orgCode: activeOrg, orgName })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in precheckout idea', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        console.log('t0')
        this.retrieveData()
      } else {
        // console.log('t2', this.props.orgType !== prevProps.orgType)
      }
    }

    retrieveData() {
      console.log('retrieveData called within precheckout idea')

      let priceId = ''
      const testingStripe = this.state.testingStripe
      if (testingStripe) {
        priceId = 'price_1I6ivTBMA9yyOaS5RJ1CztXY'
      } else {
        priceId = 'price_1LbTHhBMA9yyOaS55WpBn0aH'
        // if (this.props.product) {
        //   priceId = this.props.product.stripeProductId
        // }
      }

      let firstName = localStorage.getItem('firstName')
      let lastName = localStorage.getItem('lastName')
      let email = localStorage.getItem('email')
      let activeOrg = localStorage.getItem('activeOrg')
      let orgName = localStorage.getItem('orgName')
      let roleName = localStorage.getItem('roleName')
      // console.log('show orgName: ', orgName)
      if (this.props.roleName) {
        roleName = this.props.roleName
      }

      const price = this.props.price
      const productName = this.props.productName
      const product = this.props.product
      if (this.props.firstName) {
        firstName = this.props.firstName
      }
      if (this.props.lastName) {
        lastName = this.props.lastName
      }
      if (this.props.email) {
        email = this.props.email
      }
      if (this.props.orgName) {
        orgName = this.props.orgName
      }

      const accountCode = this.props.accountCode
      const employerName = this.props.employerName
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      this.setState({ activeOrg, orgCode: activeOrg, orgName, priceId, testingStripe, price,
        productName, product, firstName, lastName, email, accountCode, employerName,
        roleName
      })
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    checkEntry(type) {
      console.log('checkEntry called', type)

      if (type === 'promoCode') {
        if (this.state.availablePromos && this.state.availablePromos.includes(this.state.promoCode)) {
          const promoSuccessMessage = this.state.promoCode + ' successfully redeemed!'
          const promoErrorMessage = null
          this.setState({ promo: 1, promoSuccessMessage, promoErrorMessage })
        } else {
          const promoSuccessMessage = null
          const promoErrorMessage = 'Promo code not found'
          this.setState({ promoSuccessMessage, promoErrorMessage })
        }
      }
    }

    createCustomer(user) {
      console.log('createCustomer called')

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      const firstName = user.firstName
      const lastName = user.lastName
      const email = user.email
      const orgCode = user.orgCode
      const orgName = user.orgName
      const accountCode = user.accountCode
      const employerName = user.employerName
      const productPurchase = true

      const createdAt = new Date()
      const updatedAt = new Date()

      Axios.post('/api/payments/create-customer', {
        firstName, lastName, email, orgCode, orgName, accountCode, employerName, productPurchase,
        createdAt, updatedAt
      }).then((response) => {

        if (response.data.success) {
          //save values
          console.log('Customer created! ', response.data);

          const customerId = response.data.customerId
          this.setState({ customerId, isSaving: false })

        } else {
          console.log('Customer not created')
          this.setState({ errorMessage: 'There was an error setting up your account'})

        }

      }).catch((error) => {
          console.log('Customer creation did not work', error);
      });
    }

    passProductData(product) {
      console.log('passProductData called in precheckoutbidea', product)

      if (product && !product.success && product.errorMessage) {
        this.setState({ errorMessage: product.errorMessage })
      } else {
        this.props.passProductData(product)
      }
    }

    render() {

      return (
        <div>
          <div>
            <div>
              <p className="heading-text-4">Confirm Your Order Details</p>
              <div className="spacer" />

              <div className="row-10 description-text-1">
                <div className="full-width bold-text row-5">
                  <div className="relative-column-55">
                    <p>Product</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Price</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Qty</p>
                  </div>
                  <div className="relative-column-15">
                    <p>Total</p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="full-width row-5">
                  <div className="relative-column-55">
                    <p>{this.state.productName}</p>
                  </div>
                  <div className="relative-column-15">
                    <p>${this.state.price}</p>
                  </div>
                  <div className="relative-column-15">
                    <p>1</p>
                  </div>
                  <div className="relative-column-15">
                    <p>${this.state.price * 1}</p>
                  </div>
                  <div className="clear" />
                </div>

                <div className="full-width top-padding-5">
                  <div className="relative-column-55">
                    <p>Promo</p>
                  </div>
                  <div className="relative-column-15">
                    <p>{this.state.promo * 100}%</p>
                  </div>

                  <div className="relative-column-15 height-20" />

                  <div className="relative-column-15">
                    <p className="error-color">(${(this.state.promo * 1 * this.state.price).toFixed()})</p>
                  </div>
                  <div className="clear"/>
                </div>

                <div className="spacer"/>

                <hr />
                <div className="half-spacer" />

                <div className="row-5">
                  <div className="relative-column-55">
                    <p>Total</p>
                  </div>
                  <div className="relative-column-15 height-30" />

                  <div className="relative-column-15 height-30" />

                  <div className="relative-column-15 half-bold-text cta-color">
                    <p>${((1 * this.state.price) - (this.state.discount * 1 * this.state.price) - (this.state.promo * 1 * this.state.price) < 0 ? 0 : ((1 * this.state.price) - (this.state.discount * 1 * this.state.price) - (this.state.promo * 1 * this.state.price)).toFixed())}</p>
                  </div>
                </div>

                <div className="spacer" /><div className="spacer" />
              </div>
            </div>

            <div className="row-10 description-text-2">
              <div className="container-left">
                <p className="bold-text bottom-padding">Promo Code</p>
                <input className="text-field" type="text" placeholder="Promo Code" name="promoCode" value={this.state.promoCode} onChange={this.formChangeHandler} />
                {(this.state.promoSuccessMessage && this.state.promoSuccessMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.promoSuccessMessage}</p>}
                {(this.state.promoErrorMessage && this.state.promoErrorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.promoErrorMessage}</p>}

              </div>
              <div className="container-right top-padding-27">
                <button className="btn btn-squarish white-background cta-color row-3" onClick={() => this.checkEntry('promoCode')}>Redeem</button>
              </div>
              <div className="clear" />
            </div>

            <div>
              {(this.state.customerId) ? (
                <div>
                  <div className="row-10 description-text-2">
                    <p className="bold-text bottom-padding">Refunds and Cancellations</p>
                    <p>Because ideas are intellectual property, all sales are final.</p>
                  </div>
                  <div className="row-10">
                    <Elements stripe={(this.state.testingStripe) ? stripeTestPromise : stripeLivePromise}>
                      <CheckoutFormIdea history={this.props.navigate} email={this.state.email} firstName={this.state.firstName}
                        lastName={this.state.lastName} username={this.state.username} pictureURL={this.state.pictureURL}
                        orgName={this.state.orgName} employerName={this.state.employerName}
                        customerId={this.state.customerId} price={this.state.price}
                        quantity={1} priceId={this.state.priceId} promoCode={this.state.promoCode}
                        passProductData={this.passProductData} productPurchase={true}
                        accountCode={this.state.accountCode} orgCode={this.state.orgCode}
                        product={this.state.product} clientSecret={this.state.clientSecret}
                        />
                    </Elements>

                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                  </div>
                </div>
              ) : (
                <div className="row-10">
                  {(this.state.email) ? (
                    <div>
                      <button className="btn btn-squarish" disabled={this.state.isSaving} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>

                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}
                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                    </div>
                  ) : (
                    <div>
                      <button className="btn btn-squarish medium-background standard-border" disabled={true} onClick={() => this.createCustomer()}>Register & Enter Payment Details</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
}

export default PreCheckoutIdea;
