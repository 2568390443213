import React, {Component} from 'react';
import Axios from 'axios';
import SubCustomDropdown from '../Common/CustomDropdown';
import SubRenderProjects from '../Common/RenderProjects';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
// const defaultProfileBackgroundImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/default-profile-background-image.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Projects extends Component {
    constructor(props) {
        super(props)

        this.state = {
          resLimit: 500,
          emailId: null,
          username: '',
          org: '',
          projects: [],
          filteredProjects: [],
          timerId: 0,

          showProjectDetail: false,

          selectedIndex1: 0,
          selectedIndex2: 0,

          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

          memberFilter: '',
          applicationFilter: '',
          assessmentFilter: '',
          favoriteFilter: '',
          passionFilter: '',
          endorsementFilter: '',

          memberFilterOptions: [],
          applicationFilterOptions: [],
          assessmentFilterOptions: ['','Has None','At Least One','Has All'],
          favoriteFilterOptions: ['','Has None','At Least One'],
          passionFilterOptions: ['','Has None','At Least One'],
          endorsementFilterOptions: ['','Has None','At Least One'],
          gradeOptions: [],

          applications: [],
          filteredApplications: [],
          selectedJob: null,
          benchmark: null,
          benchmarkOptions: [],
          favorites: [],
          newFavorites: [],

          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.sortResults = this.sortResults.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount(){
        document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

        if (this.state.emailId) {
          //send this logged out user out of the app
        } else {

          this.retrieveData()

        }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.activeOrg)

      const org = this.props.activeOrg

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('cuFirstName');
      let cuLastName = localStorage.getItem('cuLastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      let orgFocus = localStorage.getItem('orgFocus');

      if (org || this.props.noOrgCode) {

        this.setState({ emailId: email, cuFirstName, cuLastName, username, roleName, org, orgFocus })

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let functionOptions = response.data.workOptions[0].functionOptions
            let industryOptions = response.data.workOptions[0].industryOptions
            let projectCategoryOptions = response.data.workOptions[0].projectCategoryOptions
            let hourOptions = response.data.workOptions[0].hourOptions
            const gradeOptions = ['','A+','A','A-','B+','B','B-','C+','C','C-','D+','D','D-','F']

            //filters
            const defaultFilterOption = this.state.defaultFilterOption

            // const gradeFilterOptions = [defaultFilterOption].concat(gradeOptions.slice(1, gradeOptions.length))
            const feedbackFilterOptions = [defaultFilterOption,'Has None','At Least One']
            const categoryFilterOptions = [defaultFilterOption].concat(projectCategoryOptions.slice(1, projectCategoryOptions.length))
            const functionFilterOptions = [defaultFilterOption].concat(functionOptions.slice(1, functionOptions.length))
            const industryFilterOptions = [defaultFilterOption].concat(industryOptions.slice(1, industryOptions.length))
            const hourCountFilterOptions = [defaultFilterOption].concat(hourOptions.slice(1, hourOptions.length))
            const contributorFilterOptions = [defaultFilterOption,'Only Mine']
            const activeFilterOptions = [defaultFilterOption,'Is Being Actively Worked On','Is Not Being Actively Worked On']

            const itemFilters = [
              // { name: 'Grade', value: defaultFilterOption, options: gradeFilterOptions},
              { name: 'Has Feedback', value: defaultFilterOption, options: feedbackFilterOptions},
              { name: 'Category', value: defaultFilterOption, options: categoryFilterOptions },
              { name: 'Work Function', value: defaultFilterOption, options: functionFilterOptions },
              { name: 'Industry', value: defaultFilterOption, options: industryFilterOptions },
              { name: '# of Hours', value: defaultFilterOption, options: hourCountFilterOptions },
              { name: 'Feedback Contributor', value: defaultFilterOption, options: contributorFilterOptions },
              { name: 'Active', value: defaultFilterOption, options: activeFilterOptions },
            ]

            //sort options
            const defaultSortOption = this.state.defaultSortOption

            // const gradeSortOptions = [defaultSortOption].concat(gradeOptions.slice(1, gradeOptions.length))
            // let hourCountSortOptions = [defaultSortOption].concat(hourOptions.slice(1, hourOptions.length))

            const itemSorters = [
              { name: 'Average Grade', value: defaultSortOption, options: [defaultSortOption].concat(['Highest'])},
              { name: '# of Hours', value: defaultSortOption, options: [defaultSortOption].concat(['Most'])},
            ]

            this.setState({ orgFocus, gradeOptions,itemFilters, itemSorters });

          } else {
            console.log('no jobFamilies data found', response.data.message)
          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        if (roleName === 'Teacher' || roleName === 'School Support' ) {
          // pull students within courses where user is a teacher

          Axios.get('/api/users/profile/details', { params: { email } })
          .then((response) => {

              if (response.data.success) {
                console.log('User profile query worked', response.data);

                const courseIds = response.data.user.courseIds

                if (courseIds && courseIds.length > 0) {
                  // pull from courses
                  console.log('there are course ids')

                  Axios.get('/api/projects', { params: { courseIds } })
                  .then((response) => {
                    console.log('Projects query attempted', response.data);

                      if (response.data.success) {
                        console.log('successfully retrieved projects')

                        if (response.data.projects) {

                          const projects = response.data.projects
                          const filteredProjects = projects
                          this.setState({ projects, filteredProjects })
                        }

                      } else {
                        console.log('no project data found', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('Project query did not work', error);
                  });

                } else {
                  // pull everyone at the organization

                  const resLimit = this.state.resLimit
                  Axios.get('/api/projects', { params: { orgCode: org, resLimit } })
                  .then((response) => {
                    console.log('Projects query attempted teacher', response.data);

                      if (response.data.success) {
                        console.log('successfully retrieved projects')

                        if (response.data.projects) {

                          const projects = response.data.projects
                          const filteredProjects = projects
                          this.setState({ projects, filteredProjects })
                        }

                      } else {
                        console.log('no project data found', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('Project query did not work', error);
                  });
                }

              } else {
                console.log('no user details found', response.data.message)

              }

          }).catch((error) => {
              console.log('User profile query did not work', error);
          });

        } else {
          // pull all students within the organization

          let resLimit = this.state.resLimit
          let queryParams = { orgCode: org, resLimit }
          if (window.location.pathname.startsWith('/projects')) {
            queryParams['publicExtentArray'] = ['Public']
          } else if (window.location.pathname.startsWith('/app/browse-projects')) {
            queryParams['publicExtentArray'] = ['Members Only','Public']
          }

          Axios.get('/api/projects', { params: queryParams })
          .then((response) => {
            console.log('Projects query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved projects')

                if (response.data.projects) {

                  const projects = response.data.projects
                  const filteredProjects = projects
                  this.setState({ projects, filteredProjects })
                }

              } else {
                console.log('no project data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Project query did not work', error);
          });
        }

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted one', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let orgContactEmail = response.data.orgInfo.contactEmail
            this.setState({ orgContactEmail })

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/users/profile/details', { params: { email } })
        .then((response) => {
          console.log('User profile query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved profile')

              if (response.data.user.jobTitle) {

                const cuFirstName = response.data.user.firstName
                const cuLastName = response.data.user.lastName
                const jobTitle = response.data.user.jobTitle
                const employerName = response.data.user.employerName
                this.setState({ cuFirstName, cuLastName, jobTitle, employerName })

              }
            } else {
              console.log('user profile data found', response.data.message)
            }

        }).catch((error) => {
            console.log('User profile query did not work', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
           console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             const favorites = response.data.favorites
             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })
           } else {
             console.log('no favorites data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

        // Axios.get('/api/org', { params: { orgCode: org } })
        // .then((response) => {
        //   console.log('Org info query attempted', response.data);
        //
        //   if (response.data.success) {
        //     console.log('org info query worked')
        //
        //     this.setState({ orgFocus: response.data.orgInfo.orgFocus });
        //
        //   } else {
        //     console.log('org info query did not work', response.data.message)
        //
        //   }
        //
        // }).catch((error) => {
        //     console.log('Org info query did not work for some reason', error);
        // });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'search') {

        const searchString = event.target.value

        //reset everything
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ searchString, itemFilters, itemSorters, animating: true })

        // this.setState({ searchString: event.target.value, animating: true })
        // this.resetFilters(event.target.name, event.target.value)
        this.filterResults(event.target.value, '', null, null, true)
      } else if (event.target.name.includes('filter|')) {

        let filters = this.state.itemFilters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        let index = 0
        for (let i = 1; i <= filters.length; i++) {
          if (filters[i - 1].name === field) {
            filters[i - 1]['value'] = event.target.value
            index = i - 1
          }
        }

        let itemFilters = filters

        //reset everything
        let searchString = ''
        for (let i = 1; i <= itemFilters.length; i++) {
          if (itemFilters[i - 1].name !== field) {
            itemFilters[i - 1]['value'] = this.state.defaultFilterOption
          }
        }
        let itemSorters = this.state.itemSorters
        for (let i = 1; i <= itemSorters.length; i++) {
          itemSorters[i - 1]['value'] = this.state.defaultSortOption
        }

        this.setState({ filters, itemFilters, animating: true, searchString, itemSorters })

        this.filterResults(this.state.searchString, event.target.value, filters, index, false)
      } else if (event.target.name.includes('sort|')) {

        let sorters = this.state.itemSorters

        const nameArray = event.target.name.split("|")
        const field = nameArray[1]

        // let index = 0
        for (let i = 1; i <= sorters.length; i++) {
          if (sorters[i - 1].name === field) {
            sorters[i - 1]['value'] = event.target.value
            // index = i - 1
          }
        }

        let itemSorters = sorters

        //reset everything
        let searchString = ''
        let itemFilters = this.state.itemFilters
        for (let i = 1; i <= itemFilters.length; i++) {
          itemFilters[i - 1]['value'] = this.state.defaultFilterOption
        }

        for (let i = 1; i <= itemSorters.length; i++) {
          if (itemSorters[i - 1].name !== field) {
            itemSorters[i - 1]['value'] = this.state.defaultSortOption
          }
        }

        this.setState({ searchString, itemFilters, itemSorters, animating: true })

        this.sortResults(event.target.value, field)

      }
    }

    filterResults(searchString, filterString, filters, index, search) {
      console.log('filterResults called', searchString, filterString, filters, index, search)

      // const emailId = this.state.emailId
      // const orgCode = this.state.org
      const defaultFilterOption = this.state.defaultFilterOption
      const projects = this.state.projects
      const type = this.props.type
      const emailId = this.state.emailId

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.put('/api/projects/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, projects, type, emailId })
        .then((response) => {
          console.log('Project filter query attempted', response.data);

            if (response.data.success) {
              console.log('project filter query worked')

              let filteredProjects = response.data.projects
              const filterCriteriaArray = response.data.filterCriteriaArray
              const sortCriteriaArray = null

              self.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })

            } else {
              console.log('project filter query did not work', response.data.message)
              self.setState({ animating: false })
            }

        }).catch((error) => {
            console.log('Project filter query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(sortString, sortName) {
      console.log('sortResults called', sortString, sortName)

      let projects = this.state.projects
      // let filteredProjects = []

      Axios.put('/api/projects/sort', { sortString, projects, sortName })
      .then((response) => {
        console.log('Project sort query attempted', response.data);

          if (response.data.success) {
            console.log('project sort query worked')

            let filteredProjects = response.data.projects
            const filterCriteriaArray = null
            const sortCriteriaArray = response.data.sortCriteriaArray

            this.setState({ filteredProjects, animating: false, filterCriteriaArray, sortCriteriaArray })

          } else {
            console.log('project sort query did not work', response.data.message)
            this.setState({ animating: false })
          }

      }).catch((error) => {
          console.log('Project sort query did not work for some reason', error);
          this.setState({ animating: false })
      });
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = this.state.itemFilters
        console.log('show filters: ', filters)

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <div>
                  <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
                </div>
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = this.state.itemSorters
        console.log('show sorters: ', sorters)

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    render() {

      let passedClassName = 'floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent'
      let passedClassName2 = 'floating-container top-margin-2-percent right-margin-2-percent bottom-margin-2-percent clear-background clear-shadow clear-padding'
      let passedStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0px', padding: '15px 20px'}
      // const addIconWidth = '45px'
      const passedStyle2 = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginTop: '2%', padding: '20px' }
      const passedPlaceholder = 'Search projects by name or creator...'
      // const pathname = '/advisor/projects/add'
      let filterClass = "floating-container card top-margin-2-percent right-margin-2-percent"

      if (this.props.pageSource === 'BrowseProjects') {

        passedClassName = "standard-container-2"
        passedStyle = {}
        filterClass = "standard-container-2"
        passedClassName2 = "standard-container-2 clear-background clear-shadow clear-padding"
      } else if (this.props.pageSource === 'landingPage') {

        passedClassName = "horizontal-padding bottom-margin-30"
        passedClassName2 = ""
        filterClass = "card standard-border horizontal-margin-30"
      } else if (toggleVars.hideSideNav && window.location.pathname.startsWith('/advisor')) {
        passedClassName = "standard-container-2"
        passedStyle = {}
        filterClass = "standard-container-2"
        passedClassName2 = "standard-container-2 clear-background clear-shadow clear-padding"
      } else if (toggleVars.hideAdminSideNav && window.location.pathname.startsWith('/organizations')) {
        passedClassName = "card center-horizontally top-margin-2-percent width-70-percent max-width-1400"
        passedStyle = {}
        filterClass = "card center-horizontally top-margin-2-percent width-70-percent max-width-1400"
        passedClassName2 = "center-horizontally top-margin-2-percent width-70-percent max-width-1400"

      }

      return (
          <div>
              <div>
                <div className={passedClassName} style={passedStyle}>
                  <div>
                    <div className="calc-column-offset-25">
                      <div className="spacer" />
                      <p className="heading-text-2">{(this.state.filteredProjects) ? (this.state.filteredProjects.length === this.state.resLimit) ? this.state.filteredProjects.length + "+ " : this.state.filteredProjects.length + " " : ""}{(window.location.pathname.includes('/app/')) ? "Projects" : "Career-Seeker Projects"}</p>
                    </div>
                    <div className="fixed-column-25">
                      <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                      <div className="row-5">
                        <button onClick={() => this.toggleSearchBar('show')} className="background-button">
                          <img src={(this.state.showingSearchBar) ? hideIcon : searchIcon} alt="GC" className="image-25-fit"/>
                        </button>
                      </div>
                    </div>

                    <div className="clear" />
                  </div>
                  <div className="spacer" />
                </div>

                {(this.state.showingSearchBar) && (
                  <div className={filterClass} style={passedStyle2}>
                    <div>
                      <div className="standard-border display-inline row-3 left-padding-5 right-padding-5 full-width">
                        <div className="half-spacer"/>
                        <div className="float-left top-padding-8 left-padding-3">
                          <img src={searchIcon} alt="GC" className="image-auto-20"/>
                        </div>
                        <div className="calc-column-offset-23">
                          <input type="text" className="text-field clear-border" placeholder={passedPlaceholder} name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                        </div>
                      </div>
                      <div className="clear" />
                      <div className="spacer" /><div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Filter</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('filter'))}

                      <div className="clear" />
                      <div className="spacer" />
                      <hr />
                      <div className="spacer" /><div className="spacer" />
                      <p>Sort</p>
                      <div className="half-spacer" />
                      {(this.renderManipulators('sort'))}
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.animating) ? (
                  <div className="flex-container flex-center full-space">
                    <div>
                      <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      {(this.state.orgFocus === 'Placement') ? (
                        <p className="center-text cta-color bold-text">Calculating results...</p>
                      ) : (
                        <p className="center-text cta-color bold-text">Calculating results and work matches...</p>
                      )}

                    </div>
                  </div>
                ) : (
                  <div className={passedClassName2} style={passedStyle}>
                    <SubRenderProjects
                      favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                      projects={this.state.filteredProjects} pageSource={this.props.pageSource}
                      filterCriteriaArray={this.state.filterCriteriaArray}
                      sortCriteriaArray={this.state.sortCriteriaArray}
                      jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                      org={this.state.org} accountCode={this.state.accountCode}
                      gradeOptions={this.state.gradeOptions} orgContactEmail={this.state.orgContactEmail}
                      helpOut={true}
                    />
                  </div>
                )}

              </div>

          </div>
      )
    }
}


export default withRouter(Projects);
