import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubGroups from './Subcomponents/Groups';
import withRouter from './Functions/WithRouter';

class Groups extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let calculateMatches = false
      if (this.props.location && this.props.location.state) {

        if (this.props.location.state.calculateMatches) {
          calculateMatches = this.props.location.state.calculateMatches
        }
      }

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus,
        orgLogo, calculateMatches
      })

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)
      this.setState({ activeOrg })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />

              <div className="standard-container-2 clear-padding clear-background clear-shadow">
                <SubGroups history={this.props.navigate}/>
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>
        )
    }
}

export default withRouter(Groups)
