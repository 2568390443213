import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import EditPosting from '../components/Subcomponents/EditPosting';
import withRouter from '../components/Functions/WithRouter';

class AddProblemPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      console.log('problemPlatformPage just mounted')
      document.body.style.backgroundColor = "#fff";
      window.scrollTo(0, 0)

      const path = window.location.pathname

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      this.setState({ path, emailId, activeOrg, orgFocus, roleName });

      if (this.props.params) {

        const { org } = this.props.params

        let postType = null
        if (this.props.location && this.props.location.state) {
          postType = this.props.location.state.postType
          console.log('got the postType: ', postType)
        }

        this.setState({ org, postType })

      }
    }

    render() {

      return (
        <div>
          <TopNavigation currentPage={"addProblemPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true} />

          <EditPosting org={this.state.org} selectedOpportunity={this.state.selectedOpportunity} editMode={false} jobs={this.state.jobs} roleName={this.state.roleName} path={this.state.path} unreadNotificationsCount={0} />

          <Footer history={this.props.navigate} />
        </div>
      )
    }
}

export default withRouter(AddProblemPage);
