import React, {Component } from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEditBusinessIdea from './Subcomponents/EditBusinessIdea';
import withRouter from './Functions/WithRouter';

class EditBusinessIdea extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      // const workModeString  = localStorage.getItem('workMode');
      const orgLogo = localStorage.getItem('orgLogo');

      let id = null
      if (this.props.params && this.props.params.id && this.props.params.id !== 'add') {
        id = this.props.params.id
      }

      this.setState({ emailId: email, username, activeOrg, orgFocus, orgLogo, id });
    }

    loadWorkspace(activeOrg) {
      console.log('loadWorkspace called', activeOrg)

      this.setState({ activeOrg })

    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} workMode={this.state.workMode} loadWorkspace={this.loadWorkspace} history={this.props.navigate}/>

            <div>
              <div className="standard-container-2">
                <SubEditBusinessIdea activeOrg={this.state.activeOrg} id={this.state.id} history={this.props.navigate} />
              </div>
            </div>

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(EditBusinessIdea)
