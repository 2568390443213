import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubConfigureCartItem from '../Common/ConfigureCartItem';
import SubPurchaseIdea from '../Common/PurchaseIdea';
import SubCustomDropdown from '../Common/CustomDropdown';
import withRouter from '../Functions/WithRouter';

const searchIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
// const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
// const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png'
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png'

const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png'
const videoIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/video-icon-dark.png'
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png'
const chatBubblesIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbubbles-icon.png';
const assignmentsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-dark.png';

// const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
// const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const stageIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stage-icon-dark.png';

class IdeaExchange extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showImage: true,
          showPrice: false,
          showCTA: false,

          items: [],
          filteredItems: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)
        this.calculateStageColor = this.calculateStageColor.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within coursesTwo ', this.props.activeOrg, prevProps.activeOrg)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('were in org / accountCode')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in coursesTwo', this.props.activeOrg)

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let pictureURL = localStorage.getItem('pictureURL');
      let activeOrg = localStorage.getItem('activeOrg');

      this.setState({ emailId, cuFirstName, cuLastName, username, pictureURL, activeOrg })

      let queryObject = { isActive: true, status: "Accepted", resLimit: 100 }
      Axios.get('/api/business-ideas', { params: queryObject })
      .then((response) => {
        console.log('Ideas query attempted');

        if (response.data.success) {
          console.log('ideas query worked')

          const items = response.data.businessIdeas
          const filteredItems = items
          this.setState({ items, filteredItems })

        } else {
          console.log('something went wrong: ', response.data.message)
        }

      }).catch((error) => {
        console.log('Ideas query did not work for some reason', error);
      });

      if (emailId) {
        Axios.get('/api/business-ideas-purchased', { params: { email: emailId }})
        .then((response) => {
          console.log('Business ideas purchased query attempted', response.data);

          if (response.data.success) {
            console.log('business ideas purchased query worked')

            // const businessIdea = response.data.businessIdea
            const purchasedIdeas = response.data.purchasedIdeas
            this.setState({ purchasedIdeas })

          } else {
            console.log('business ideas purchased query did not work', response.data.message)
          }
        }).catch((error) => {
          console.log('Business ideas purchased query did not work for some reason', error);
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, animating: true })
        this.searchItems(event.target.name, event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchItems(name,searchString) {
      console.log('searchItems called ', name, searchString)

      const orgCode = this.state.org
      const queryParams = { searchString, orgCode, resLimit: 100 }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/business-ideas/search', { params: queryParams })
        .then((response) => {
          console.log('Ideas search query attempted', response.data);

            if (response.data.success && response.data.businessIdeas) {
              console.log('ideas search query worked')

              const filteredItems = response.data.businessIdeas
              self.setState({ filteredItems, animating: false });

            } else {
              console.log('pathway search query did not work', response.data.message)
              self.setState({ filteredItems: this.state.items, animating: false })
            }

        }).catch((error) => {
            console.log('Benchmark search query did not work for some reason', error);
            self.setState({ animating: false, errorMessage: error.toString() })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showRegistration: false, showAddToCart: false, showBuyItem: false });
    }

    itemClicked(item, type) {
      console.log('itemClicked called ', item, type)

      if (type === 'Book') {
        this.setState({ modalIsOpen: true, showAddToCart: true, itemSelected: item })
      } else if (type === 'Buy') {
        const selectedIdea = item

        let includedItems = []
        if (selectedIdea.problem) {
          includedItems.push({ name: "Problem Definition" })
        }
        if (selectedIdea.solution) {
          includedItems.push({ name: "Solution Definition" })
        }
        if (selectedIdea.marketSize) {
          includedItems.push({ name: "Market Size & Growth" })
        }
        if (selectedIdea.acquisitionStrategy) {
          includedItems.push({ name: "Acquisition Strategy" })
        }
        if (selectedIdea.competitors && selectedIdea.competitors.length > 0) {
          includedItems.push({ name: "Competitors" })
        }
        if (selectedIdea.competitiveAdvantage && selectedIdea.competitiveAdvantage) {
          includedItems.push({ name: "Competitive Advantage" })
        }
        if (selectedIdea.businessModel && selectedIdea.businessModel) {
          includedItems.push({ name: "Business Model" })
        }
        if (selectedIdea.personas && selectedIdea.personas) {
          includedItems.push({ name: "User Personas" })
        }
        if (selectedIdea.progress && selectedIdea.progress) {
          includedItems.push({ name: "Progress" })
        }
        if (selectedIdea.team && selectedIdea.team) {
          includedItems.push({ name: "Team" })
        }
        if (selectedIdea.interviews && selectedIdea.interviews.length > 0) {
          includedItems.push({ name: "User Interviews" })
        }
        if (selectedIdea.demoURL) {
          includedItems.push({ name: "Demo Video" })
        }
        if (selectedIdea.pitchDeckURL) {
          includedItems.push({ name: "Pitch Deck" })
        }
        if (selectedIdea.promoURL) {
          includedItems.push({ name: "Promo Video" })
        }
        if (selectedIdea.metrics && selectedIdea.metrics.length > 0) {
          includedItems.push({ name: "Performance Metrics" })
        }

        this.setState({ modalIsOpen: true, showBuyItem: true, selectedIdea, includedItems })
      } else if (type === 'Sell') {
        this.props.navigate("/business-ideas/" + item._id)
      } else if (type === 'Browse') {
        this.props.navigate("/marketplace/" + item.slug)
      }
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    renderManipulators(type) {
      console.log('renderManipulators called')

      if (type === 'filter') {
        let filters = []
        if (this.state.subNavSelected === 'Events') {
          // events
          filters = this.state.eventFilters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          filters = this.state.projectFilters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          filters = this.state.workFilters
        } else if (this.state.subNavSelected === 'All') {
          filters = this.state.allFilters
        }

        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {
        let sorters = []

        if (this.state.subNavSelected === 'Events') {
          // events
          sorters = this.state.eventSorters
        } else if (this.state.subNavSelected === 'Projects') {
          // projects
          sorters = this.state.projectSorters
        } else if (this.state.subNavSelected === 'Work') {
          // work
          sorters = this.state.workSorters
        } else if (this.state.subNavSelected === 'All') {
          sorters = this.state.allSorters
        }

        if (sorters) {

          let rows = []
          for (let i = 1; i <= sorters.length; i++) {
            rows.push(
              <div key={sorters[i - 1] + i.toString()}>
                <SubCustomDropdown dropdownTitle={sorters[i - 1].name} name={"sort|" + sorters[i - 1].name} value={sorters[i - 1].value} options={sorters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />
              </div>
            )
          }

          return rows

        }
      }
    }

    calculateStageColor(stage,type) {
      console.log('calculateStageColor called', stage,type)

      if (stage === 'Idea') {
        return "secondary-" + type
      } else if (stage === 'Pre-Launch Tested') {
        return "twelve-" + type
      } else if (stage === 'Launched Product/Service') {
        return "senary-" + type
      } else if (stage === 'Revenue-Generating') {
        return "nonary-" + type
      } else {
        return "denary-" + type
      }
    }

    voteOnItem(e, value, index) {
      console.log('voteOnItem called', e, value, index)

      e.preventDefault()
      e.stopPropagation()

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (!this.state.emailId) {
        this.setState({ isSaving: true, errorMessage: 'You must sign in to vote on business ideas' })
      } else {

        const _id = value._id
        const emailId = this.state.emailId
        let changeUpvote = true
        const updatedAt = new Date()

        Axios.post('/api/business-ideas', { _id, emailId, changeUpvote, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Business idea save worked', response.data);

            const successMessage = 'Business idea successfully updated!'

            let filteredItems = this.state.filteredItems
            filteredItems[index] = response.data.businessIdea
            this.setState({ filteredItems, isSaving: false, successMessage })

          } else {
            console.error('there was an error posting the business idea');
            const errorMessage = response.data.message
            this.setState({ errorMessage, isSaving: false })
          }
        }).catch((error) => {
            console.log('The business idea post did not work', error);
            this.setState({ errorMessage: error.toString(), isSaving: false })
        });
      }
    }

    render() {
      return (
          <div>
            <div className={(window.innerWidth < 768) ? "card-clear-padding bottom-margin-20" : "card-clear-padding width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"}>
              <div className="full-width height-5 primary-background" />

              <div className="padding-20">
                <div className="calc-column-offset-50">
                  <p className="heading-text-2">{this.state.filteredItems.length} Business Ideas</p>
                </div>
                <div className="fixed-column-50">
                  <button className="background-button top-margin-5" onClick={() => this.props.navigate('/edit-business-ideas/add')}>
                    <img className="image-auto-25" alt="GC" src={addIconBlue} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Monetize your idea" />
                  </button>
                </div>
                <div className="clear" />
                <ReactTooltip id="tooltip-placeholder-id" />
              </div>
            </div>

            <div className={(window.innerWidth < 768) ? "bottom-margin-20" : "width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"}>
              <div className="filter-field-search full-width white-background medium-shadow">
                <div className="search-icon-container">
                  <img src={searchIcon} alt="GC" className="image-auto-28 padding-5" />
                </div>
                <div className="filter-search-container calc-column-offset-100-static">
                  <input type="text" className="text-field clear-border" placeholder={"Search Business Ideas..."} name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                </div>
                {/*
                <div className="search-icon-container top-margin-negative-3">
                  <button type="button" className="background-button" onClick={() => this.toggleSearchBar('show')}>
                    <div className={(this.state.showingSearchBar) ? "cta-border rounded-corners row-5 horizontal-padding-10 description-text-3 cta-background-color white-text" : "standard-border rounded-corners row-5 horizontal-padding-10 description-text-3"}>Filter</div>
                  </button>
                </div>*/}
              </div>
              <div className="clear" />
            </div>

            {(this.state.showingSearchBar) && (
              <div className={(window.innerWidth < 768) ? "card bottom-margin-20" : "card width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"}>
                <div>
                  <p>Filter</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('filter'))}

                  <div className="clear" />
                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" />
                  <p>Sort</p>
                  <div className="half-spacer" />
                  {(this.renderManipulators('sort'))}
                  <div className="clear" />
                </div>
              </div>
            )}

            <div className={(window.innerWidth < 768) ? "bottom-margin-20" : "width-90-percent min-width-300 max-width-900 pin-left bottom-margin-20 left-margin-20"}>
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space white-background">
                  <div>
                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    <p className="center-text cta-color bold-text">Calculating results...</p>

                  </div>
                </div>
              ) : (
                <div>
                  {this.state.filteredItems.map((value, optionIndex) =>
                    <div key={value.name}>
                      {((this.state.filterCategory && this.state.filterCategory === value.category) || (!this.state.filterCategory || this.state.filterCategory === 'All')) && (
                        <div>
                          <div className={(window.innerWidth < 768) ? "full-width bottom-margin-30" : "relative-column-32 bottom-margin-30"}>
                            <div className="card-clear-padding">
                              <div className={"full-width height-5 " + this.calculateStageColor(value.stage,"background")} />
                              <div className="padding-20">
                                <Link to={"/business-ideas/" + value._id} className="background-button standard-color full-width">
                                  <div className="fixed-column-50 top-margin-3">
                                    <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                  </div>
                                  <div className="calc-column-offset-110">
                                    <p className="full-width bold-text heading-text-6">{value.name}</p>
                                    <p className="full-width description-text-4 top-padding-3">by {value.firstName} {value.lastName}</p>
                                  </div>
                                  <div className="fixed-column-60">
                                    {/*
                                    <button className="background-button" onClick={(e) => this.voteOnItem(e, value, optionIndex) }>
                                      <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                                        <div className="float-left padding-7">
                                          <img src={(value.upvotes && value.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="GC" className="image-auto-15"/>
                                        </div>
                                        <div className="vertical-separator-4" />
                                        <div className="float-left horizontal-padding-10">
                                          <div className="half-spacer" />
                                          <p className="description-text-2 half-bold-text">{(value.upvotes) ? value.upvotes.length : 0}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    </button>*/}
                                  </div>
                                  <div className="clear" />

                                  <p className="full-width description-text-4 top-padding">{value.teaser}</p>

                                  {(this.state.showImage && value.imageURL) && (
                                    <div className="top-margin">
                                      <img src={value.imageURL} alt="GC" className="image-full-auto" />
                                    </div>
                                  )}

                                  <div>
                                    {(value.progress) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={careerMatchesIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has had progress" />
                                      </div>
                                    )}
                                    {(value.interviews && value.interviews.length > 0) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={chatBubblesIcon} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has had interviews" />
                                      </div>
                                    )}
                                    {(value.funding && value.funding.length > 0) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={moneyIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has secured funding" />
                                      </div>
                                    )}
                                    {(value.demoURL && value.demoURL.length > 0) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={videoIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has a product demo video" />
                                      </div>
                                    )}
                                    {(value.pitchDeckURL) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={assignmentsIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has a pitch deck" />
                                      </div>
                                    )}
                                    {(value.promoURL && value.promoURL.length > 0) && (
                                      <div className="float-left right-padding top-margin-15">
                                        <img src={videoIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This idea has a promotional video" />
                                      </div>
                                    )}
                                    <div className="clear" />

                                  </div>

                                  <div className="top-padding" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Stage of the Idea: " + value.stage}>
                                    <div className="fixed-column-25 top-margin-3">
                                      <img src={stageIconDark} alt="GC" className="image-auto-15 horizontal-margin-tiny" />
                                    </div>
                                    <div className="calc-column-offset-25">
                                      <p className={"full-width description-text-2 " + this.calculateStageColor(value.stage,"text")}>{value.stage}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                  <ReactTooltip id="tooltip-placeholder-id" />

                                  {(this.state.showPrice) && (
                                    <p className="full-width heading-text-5 cta-color top-padding-30">${value.price}</p>
                                  )}

                                </Link>

                                {((!this.state.showCTA) || (this.state.purchasedIdeas && this.state.purchasedIdeas.some(pi => (pi.productId === value._id && pi.productId && value._id)))) ? (
                                  <div />
                                ) : (
                                  <button className="btn btn-squarish full-width top-margin-20" onClick={() => this.itemClicked(value,"Buy")}>Buy</button>
                                )}

                              </div>

                              {(this.state.purchasedIdeas && this.state.purchasedIdeas.some(pi => (pi.productId === value._id && pi.productId && value._id))) && (
                                <div className="full-width medium-background center-text">
                                  <p className="description-text-2 white-text row-10">You Own This Idea</p>
                                </div>
                              )}
                            </div>
                          </div>

                          {(optionIndex % 3 !== 2 && window.innerWidth > 768) && (
                            <div>
                              <div className="relative-column-2 height-40" />
                            </div>
                          )}

                          {(optionIndex % 3 === 2 && window.innerWidth > 768) && (
                            <div>
                              <div className="clear" />

                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
            {(this.state.showAddToCart) && (
              <div key="showAddToCart" className="full-width padding-40">
                <SubConfigureCartItem history={this.props.navigate} itemSelected={this.state.itemSelected} closeModal={this.closeModal} />
              </div>
            )}
            {(this.state.showBuyItem) && (
              <div key="showBuyItem" className="full-width padding-40">
                 <SubPurchaseIdea history={this.props.navigate} selectedIdea={this.state.selectedIdea} includedItems={this.state.includedItems} closeModal={this.closeModal} />
               </div>
            )}
           </Modal>
          </div>
      )
    }
}


export default withRouter(IdeaExchange);
