import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSendMessage from './Subcomponents/SendMessage';
import SubAdminSendMessage from './Subcomponents/AdminSendMessage';
import withRouter from './Functions/WithRouter';

class AdvNewMessage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      let userId = null
      if (this.props.params) {
        userId = this.props.params.userId
      }

      let selectedApplication = null
      let advisee = null
      if (this.props.location && this.props.location.state) {
        selectedApplication = this.props.location.state.selectedApplication
        advisee = this.props.location.state.advisee
      }

      this.setState({
          emailId: email, username, cuFirstName, cuLastName, advisee, activeOrg,
          orgFocus, roleName, userId, selectedApplication, orgLogo
      });
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} roleName={this.state.roleName} orgFocus={this.state.orgFocus} history={this.props.navigate} />
              {(this.state.selectedApplication) && (
                <SubAdminSendMessage org={this.state.activeOrg} accountCode={this.state.emp} selectedApplication={this.state.selectedApplication} />
              )}
              {(this.state.userId) && (
                <SubSendMessage org={this.state.activeOrg} userId={this.state.userId} history={this.props.navigate} />
              )}

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}

            </div>

        )
    }
}

export default withRouter(AdvNewMessage)
