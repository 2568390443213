import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubAdminApplicationDetail from './Subcomponents/AdminApplicationDetail';
import withRouter from './Functions/WithRouter';

class EmpCareerSeekerDetail extends Component {
  constructor(props) {
      super(props)

      this.state = {
      }

      this.configureAppDetail = this.configureAppDetail.bind(this)
      this.loadWorkspace = this.loadWorkspace.bind(this)

  }

  componentDidMount(){
    document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

    const { emp, profileEmail } = this.props.params

    //obtain email id from localStorage
    let email = localStorage.getItem('email');
    // let username = localStorage.getItem('username');
    let cuFirstName = localStorage.getItem('firstName');
    let cuLastName = localStorage.getItem('lastName');
    let activeOrg = localStorage.getItem('activeOrg')
    const orgLogo = localStorage.getItem('orgLogo');

    let orgMode = true
    if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
      orgMode = false
    }

    if (email) {

      this.setState({
          emailId: email, cuFirstName, cuLastName, emp, activeOrg, orgLogo, orgMode,
          profileEmail
      });

      this.configureAppDetail(profileEmail, emp, activeOrg)
    }
  }

  configureAppDetail(email, accountCode, orgCode) {
    console.log('configureAppDetail called', email, accountCode, orgCode)

    Axios.get('/api/applications', { params: { emailId: email } })
    .then((response) => {
        if (response.data.success && response.data.applications && response.data.applications.length > 0) {
          console.log('Applications submitted query worked', response.data);

          let selectedApplication = response.data.applications[0]

          Axios.get('/api/benchmarks', { params: { accountCode, pathwayLevel: true }})
          .then((response) => {
            console.log('Benchmarks query within sub attempted', response.data);

            if (response.data.success) {
              console.log('benchmark query worked')

              if (response.data.benchmarks.length !== 0) {
                //jobs = response.data.postings
                console.log('set the benchmark to state')
                const benchmarkOptions = response.data.benchmarks
                const benchmark = benchmarkOptions[0]
                let selectedJob = { title: benchmark.jobFunction, postType: 'Track', tracks: [] }

                if (benchmarkOptions && benchmarkOptions.length > 0) {
                  for (let i = 1; i <= benchmarkOptions.length; i++) {
                    selectedJob['tracks'].push({})
                    selectedJob['tracks'][i - 1]['name'] = benchmarkOptions[i - 1].title
                    selectedJob['tracks'][i - 1]['benchmark'] = benchmarkOptions[i - 1]
                  }
                }

                this.setState({ selectedJob, selectedApplication, benchmark, benchmarkOptions })
              } else {
                console.log('no benchmarks found')
              }

            } else {
              console.log('benchmark query did not have success', response.data.message)

            }

          }).catch((error) => {
              console.log('Benchmark query did not work for some reason', error);
          });

        } else {
          console.log('no application data found', response.data.message)
        }

    }).catch((error) => {
        console.log('Application query did not work', error);
    });
  }

  loadWorkspace(org) {
    console.log('loadWorkspace called', org)
    this.setState({ org })
  }

  render() {

    return (
        <div>
          <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />
          <SubAdminApplicationDetail accountCode={this.state.emp} org={this.state.activeOrg} profileEmail={this.state.profileEmail} history={this.props.navigate} selectedJob={this.state.selectedJob} selectedApplication={this.state.selectedApplication} benchmark={this.state.benchmark} benchmarkOptions={this.state.benchmarkOptions} calcImmediately={true} />

          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>

    )
  }
}

export default withRouter(EmpCareerSeekerDetail)
