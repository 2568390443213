import React, {Component} from 'react';
import withRouter from '../components/Functions/WithRouter';
// import Axios from 'axios';

import TopNavigation from './TopNavigation';
import SubBusinessIdeaDetails from '../components/Subcomponents/BusinessIdeaDetails';

class BusinessIdeaDetailsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount(){
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        let id = null
        if (this.props.params && this.props.params.id) {
          id = this.props.params.id
        }
        this.setState({ id })
    }

    retrieveData(selectedProblem) {
      console.log('retrieveData called in probDeets')

      let emailId = localStorage.getItem('email');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');

      this.setState({ emailId, activeOrg, orgFocus, roleName })
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within BusinessIdeaDetailsPage ', this.props, prevProps)

      if (this.props.params && this.props.params.id !== prevProps.params.id) {
        // console.log('new career selected in parent')
        this.setState({ id: this.props.params.id })
      }
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"BusinessIdeaDetailsPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

            <SubBusinessIdeaDetails id={this.state.id} history={this.props.navigate} pageSource="landingPage"  />

          </div>
      )
    }
}

export default withRouter(BusinessIdeaDetailsPage);
