import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import withRouter from '../Functions/WithRouter';

const linkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class OKRs extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.pullCourses = this.pullCourses.bind(this)
    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within subNotifications', this.props)

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');

      const okrs = [
        { _id: '123', objective: { title: "Grow Revenues 2x", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Sales','Marketing','Social Media']},
        { _id: '123', objective: { title: "Expand Into New Markets", startDate: "October 1, 2022", endDate: "November 30, 2023"}, results: [{ title: "Interview 5x quality candidates", teams: ["HR"], priority: "High", status: "Done", startDate: "October 1, 2022", endDate: "November 30, 2023"}], competencies: ['Business Development','Mergers & Acquisitions','Corporate Development','Strategy','Finance']},
      ]

      this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, okrs })
      this.pullCourses("Marketing", this.state.priceValue, this.state.durationValue, this.state.difficultyLevelValue, null, null, null, true)

    }

    pullCourses(searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources) {
      console.log('pullCourses called', searchValue, priceValue, durationValue, difficultyLevelValue, queryOrgCourses, orgCode, filterObject, queryUdemyCourses, sources)

      this.setState({ animating: true, errorMessage: null, successMessage: null })

      // const searchValue = 'Excel'
      if (!searchValue) {
        searchValue = this.state.selectedSkill
      }
      const categoryValue = null
      const subcategoryValue = null
      // let priceValue = this.state.priceValue
      if (!priceValue) {
        priceValue = this.state.priceValue
      }
      let ratingValue = null
      if (!ratingValue) {
        ratingValue = 3.0
      }
      // let durationValue = this.state.durationValue
      if (!durationValue) {
        durationValue = this.state.durationValue
      }

      if (difficultyLevelValue) {
        difficultyLevelValue = difficultyLevelValue.toLowerCase()
      }

      let excludeOrgCourses = false
      if (sources && !sources.includes('OrgCourses')) {
        orgCode = null
        excludeOrgCourses = true
      }

      if (sources && sources.length === 1 && sources[0] === 'Udemy') {
        queryUdemyCourses = true
        if (!searchValue) {
          searchValue = "Marketing"
        }
      } else if ((sources && sources.length > 1) || (sources && sources.length === 1 && sources[0] !== 'Udemy')) {
        queryUdemyCourses = false
      }
      const courseQueryObject = { searchValue, categoryValue, subcategoryValue, priceValue, ratingValue, durationValue, difficultyLevelValue, queryOrgCourses, excludeOrgCourses, orgCode, filterObject, queryUdemyCourses, sources, isActive: true, resLimit: 100 }
      // console.log('show courses queryObject: ', courseQueryObject)
      Axios.get('/api/courses/search', { params: courseQueryObject })
      .then((response) => {
        console.log('Search courses query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved courses')

            if (response.data.responseData) {

              const relatedCourses = response.data.responseData.results

              this.setState({ relatedCourses, sources, queryUdemyCourses, animating: false })
            }

          } else {
            console.log('no course data found', response.data.message)
            this.setState({ sources, queryUdemyCourses, animating: false, errorMessage: 'Found no courses that match the criteria'})
          }

      }).catch((error) => {
          console.log('Course query did not work', error);
          this.setState({ sources, animating: false, errorMessage: 'There was an unknown error retrieving the courses'})
      });
    }

    render() {

      return (
          <div>
            {(this.state.isLoading) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <div className="super-spacer" />

                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading...</p>

                </div>
              </div>
            ) : (
              <div>
                <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                  <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                    <div className="full-width height-5 primary-background" />
                    <div className="padding-40">
                      <div className="spacer"/><div className="spacer"/>

                      <div>
                        <p className="heading-text-2">OKRs</p>

                        <div className="spacer" /><div className="half-spacer" />

                        {(this.state.okrs) && (
                          <div>
                            {this.state.okrs.map((value, index) =>
                              <div key={value}>
                                {(index < 3) && (
                                  <div className="row-30">
                                    {/*<Link className="background-button full-width standard-color profile-container-left" to={'/app/okrs/' + value._id}>*/}
                                      <div className="fixed-column-60 height-50">
                                        <img src={targetIconOrange} className="image-40-fit" alt="GC" />
                                      </div>
                                      <div className="calc-column-offset-60">
                                        <p className="heading-text-4">Objective #{index + 1}. {value.objective.title}</p>
                                        <p className="description-text-3 description-text-color top-padding-5">{value.objective.startDate} - {value.objective.endDate}</p>

                                        {value.results.map((value2, index2) =>
                                          <div key={value2} className="top-margin-20">
                                            <p className="description-text-1">Key Result #{index2 + 1}: {value2.title}</p>
                                            <p className="description-text-3 top-padding-5">{value2.priority} Priority | Status: {value2.status}</p>
                                            <p className="description-text-3 description-text-color top-padding-5">{value2.startDate} - {value2.endDate} | {value2.teams.toString()}</p>
                                          </div>
                                        )}

                                        {(value.competencies && value.competencies.length > 0) && (
                                          <div className="top-margin-5">
                                            {value.competencies.map((value2, index2) =>
                                              <div key={value2} className="top-margin float-left right-margin-15">
                                                <div className="tag-container-inactive description-text-3">
                                                  <p>{value2}</p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="clear" />

                                    {/*</Link>*/}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {(window.innerWidth > 768) && (
                  <div className="relative-column-30 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      {(this.state.relatedCourses && this.state.relatedCourses.length > 0) && (
                        <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                          <div className="full-width height-5 senary-background" />
                          <div className="padding-20">
                            <p className="heading-text-5">Related Courses</p>

                            <div className="spacer" /><div className="half-spacer" />

                            {this.state.relatedCourses.map((item, index) =>
                              <div key={item}>

                                {(index < 3) && (
                                  <div className="bottom-padding">
                                    <div className="spacer" />
                                    <a href={'https://www.udemy.com' + item.url} target="_blank" rel="noopener noreferrer">
                                      <div>
                                        <div className="calc-column-offset-40">
                                          <img src={item.image_125_H} alt="GC" className="image-70-auto" />
                                        </div>
                                        <div className="fixed-column-40">
                                          <span className="float-right">
                                            <div className="spacer"/>
                                            <img src={linkIcon} alt="GC" className="image-auto-22"/>
                                          </span>
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      <div className="full-width top-padding">
                                        <p>{item.title}</p>
                                        <p className="description-text-2 standard-color">{item.headline}</p>
                                      </div>
                                    </a>

                                    <div className="spacer" /><div className="spacer" />
                                    <hr />
                                    <div className="clear"/>
                                    <div className="spacer" />
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.relatedCourses.length > 0) && (
                              <div>
                                <Link className="background-button full-width right-text" to={(this.state.pageSource === 'landingPage') ? '/courses' : '/app/courses'} state={{ subNavSelected: 'Courses' }}>
                                  <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            )}
          </div>

      )
    }
}


export default withRouter(OKRs);
