import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
// import { InlineWidget } from "react-calendly";

import {convertDateToString} from '../Functions/ConvertDateToString';
import SubLogInFields from '../Common/LogInFields';
import {signOut} from '../Services/AuthRoutes';
import PreCheckoutBooking from '../Common/PreCheckoutBooking';

const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

class ConfigureCartItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
          quantityOptions: ['1','2','3','4','5','6','7','8','9','10'],
          recipientTypeOptions: ['','Me',"Someone Else"],
          profileItems: [],
          orgSessionsFree: true
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.passData = this.passData.bind(this)
        this.logout = this.logout.bind(this)
        this.switchPreference = this.switchPreference.bind(this)
        this.pullProfileInfo = this.pullProfileInfo.bind(this)
        this.passProductData = this.passProductData.bind(this)
        this.bookSession = this.bookSession.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in ConfigureCartItem', this.props, prevProps)

      if (this.props.itemSelected !== prevProps.itemSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in ConfigureCartItem', this.props)

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgName = localStorage.getItem('orgName');
      let orgFocus = localStorage.getItem('orgFocus');
      let pictureURL = localStorage.getItem('pictureURL');

      const itemSelected = this.props.itemSelected
      let price = 0
      let total = 0

      if (itemSelected && (!activeOrg || activeOrg === 'guidedcompass' || (activeOrg !== 'guidedcompass' && !this.state.orgSessionsFree))) {
        price = itemSelected.price
        total = price
      }

      let recipientType = null
      if (window.location.pathname.includes('/app/')) {
        recipientType = "Me"
      }

      if (!activeOrg) {
        activeOrg = 'guidedcompass'
      }

      let importedEmailId = false
      if (email) {
        importedEmailId = true
      }

      let profileItems = [
        { name: 'LinkedIn Profile', include: true },
        { name: 'Resume', include: true },
        { name: 'SMART Goals', include: true },
        { name: 'Career Plan', include: true },
        { name: 'Career Assessments', include: true },
        { name: 'Endorsements', include: false },
        { name: 'Favorited Items', include: false },
        { name: 'Prior Advising Sessions', include: false },
        { name: 'Passions', include: false },
      ]

      this.setState({ emailId: email, cuFirstName, cuLastName, username, recipientType,
        activeOrg, orgName,
        orgFocus, pictureURL, importedEmailId,
        itemSelected, price, total, profileItems
      });

      this.pullProfileInfo(email, activeOrg, orgFocus)
    }

    pullProfileInfo(emailId, orgCode, orgFocus) {
      console.log('pullProfileInfo called', emailId, orgCode, orgFocus)

      Axios.get('/api/projects', { params: { emailId, includeCollaborations: true } })
      .then((response) => {
        console.log('Projects query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved projects')

            if (response.data.projects) {

              const projects = response.data.projects
              this.setState({ projects })

            }

          } else {
            console.log('no project data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Project query did not work', error);
      });

      Axios.get('/api/logs/goals', { params: { emailId } })
      .then((response) => {

          if (response.data.success) {
            console.log('Goals received query worked', response.data);

            const goals = response.data.goals
            this.setState({ goals })

          } else {
            console.log('no goal data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Goal query did not work', error);
      });

      Axios.get('/api/counseling/session', { params: { emailId, type: 'Advisee', hiddenToCareerSeeker: false } })
      .then((response) => {

          if (response.data.success) {
            console.log('Session query worked', response.data);

            const advisingSessions = response.data.sessions
            this.setState({ advisingSessions })

          } else {
            console.log('no session data found', response.data.message)
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }

      }).catch((error) => {
          console.log('Resource query did not work', error);
      });

      Axios.get('/api/logs/passions', { params: { emailId } })
      .then((response) => {

          if (response.data.success) {
            console.log('Passions received query worked', response.data);

            const passions = response.data.passions
            this.setState({ passions })

          } else {
            console.log('no passion data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Passion query did not work', error);
      });

      Axios.get('/api/career-plans', { params: { emailId } })
     .then((response) => {
       console.log('Career plan query attempted', response.data);

       if (response.data.success) {
         console.log('successfully retrieved career plans')

         const careerPlans = response.data.careerPlans
         let careerPlanFileNames = []
         if (careerPlans) {
           for (let i = 0; i < careerPlans.length; i++) {
             careerPlanFileNames.push(careerPlans[i].fileName)
           }
         }

         let careerPlanFileName = null
         if (careerPlanFileNames && careerPlanFileNames.length > 0) {
           careerPlanFileName = careerPlanFileNames[0]
         }
         this.setState({ careerPlans, careerPlanFileNames, careerPlanFileName })

       } else {
         console.log('no career plans data found', response.data.message)
       }

     }).catch((error) => {
         console.log('Career plan query did not work', error);
     });

     //  Axios.get('/api/assessment/results', { params: { emailId } })
     //   .then((response) => {
     //     console.log('query for assessment results worked');
     //
     //     if (response.data.success) {
     //
     //       console.log('actual assessment results', response.data)
     //
     //       if (response.data.results) {
     //
     //         let assessments = []
     //         const updatedAt = response.data.results.updatedAt
     //         if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
     //           const description = 'My specific preferences on where, how, and when I work'
     //           profileData['workPreferences'] = response.data.results.workPreferenceAnswers
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Work Preferences')) {
     //               assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
     //             }
     //           }
     //         }
     //         if (response.data.results.interestScores && response.data.results.interestScores.length > 0) {
     //           const description = 'My strong interest inventory assessment on what type of work I may like'
     //           profileData['interests'] = response.data.results.interestScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Interests')) {
     //               assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.skillsScores && response.data.results.skillsScores.length > 0) {
     //           const description = "Skills I think I'm good at and need work at based on my pathways of interest"
     //           profileData['skills'] = response.data.results.skillsScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Skills')) {
     //               assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.personalityScores) {
     //           const description = "Five factor personality assessment"
     //           profileData['personality'] = response.data.results.personalityScores
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Personality')) {
     //               assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
     //             }
     //           }
     //         }
     //         if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0 && response.data.results.topEmployerValues && response.data.results.topEmployerValues.length > 0) {
     //           const description = 'The people and employers I gravitate towards'
     //           profileData['values'] = { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }
     //
     //           if (assessmentPublicPreference === 'All') {
     //             assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
     //           } else if (assessmentPublicPreference === 'Some'){
     //             if (publicAssessments.includes('Values')) {
     //               assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
     //             }
     //           }
     //         }
     //
     //         this.setState({ assessments });
     //       }
     //
     //     } else {
     //       console.log('error response', response.data)
     //
     //       this.setState({ resultsErrorMessage: response.data.message })
     //     }
     //
     // }).catch((error) => {
     //     console.log('query for assessment results did not work', error);
     // })

      Axios.get('/api/story', { params: { emailId } })
      .then((response) => {
          console.log('Story query worked', response.data);

          if (response.data.success) {

            const endorsements = response.data.stories
            this.setState({ endorsements })

          }

      }).catch((error) => {
          console.log('Story query did not work', error);
      });

      Axios.get('/api/users/profile/details', { params: { email: emailId } })
      .then((response) => {
        console.log('User details query 1 attempted', response.data);

        if (response.data.success) {
           console.log('successfully retrieved user details')

           const zipcode = response.data.user.zipcode
           const pictureURL = response.data.user.pictureURL
           const headline = response.data.user.headline
           const linkedInURL = response.data.user.linkedInURL
           const jobTitle = response.data.user.jobTitle
           const employerName = response.data.user.employerName
           const education = response.data.user.education
           const resumeURL = response.data.user.resumeURL

           this.setState({ zipcode, pictureURL, headline, linkedInURL, jobTitle, employerName,
             education, resumeURL
           });

        } else {
         console.log('no user details data found', response.data.message)
        }

      }).catch((error) => {
         console.log('User details query did not work', error);
      });

      Axios.get('/api/assessment/results', { params: { emailId } })
       .then((response2) => {
         console.log('query for assessment results worked');

         if (response2.data.success) {

           console.log('actual assessment results', response2.data)

           // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
           const workPreferences = response2.data.results.workPreferenceAnswers
           const interests = response2.data.results.interestScores
           const personality = response2.data.results.personalityScores
           const skills = response2.data.results.newSkillAnswers
           const topGravitateValues = response2.data.results.topGravitateValues
           const topEmployerValues = response2.data.results.topEmployerValues

           let careerAssessments = []
           if (workPreferences && workPreferences.length > 0) {
             careerAssessments.push('Work Preferences')
           }
           if (interests && interests.length > 0) {
             careerAssessments.push('Interests')
           }
           if (personality) {
             careerAssessments.push('Personality')
           }
           if (skills && skills.length > 0) {
             careerAssessments.push('Skills')
           }
           if (topGravitateValues && topGravitateValues.length > 0) {
             careerAssessments.push('Values')
           }

           this.setState({ workPreferences, interests, personality, skills, topGravitateValues, topEmployerValues, careerAssessments })

         } else {
           console.log('no assessment results', response2.data)
         }

     }).catch((error) => {
       console.log('query for assessment results did not work', error);
     })

      Axios.get('/api/favorites', { params: { emailId } })
      .then((response) => {
        console.log('Favorites query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved favorites')

          Axios.get('/api/favorites/detail', { params: { favorites: response.data.favorites, orgCode, orgFocus } })
          .then((response2) => {
            console.log('Favorites detail query attempted', response2.data);

            if (response2.data.success) {
              console.log('successfully retrieved favorites detail', response2.data.favorites)

              const favorites = response2.data.favorites
              const types = response2.data.types
              this.setState({ favorites, types })

            } else {
              console.log('no favorites detail data found', response2.data.message)
            }

          }).catch((error) => {
              console.log('Favorites detail query did not work', error);
          });

        } else {
          console.log('no favorites data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Favorites query did not work', error);
      });

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      this.setState({ [event.target.name]: event.target.value })

    }

    passData(data) {
      console.log('passData called', data)

      if (data) {
        this.setState(data)
        this.pullProfileInfo(data.emailId,this.state.activeOrg,this.state.orgFocus)
      }

    }

    async logout() {
      console.log('logout called')

      const returnedValue = await signOut(
        this.state.emailId, this.state.activeOrg, this.state.orgFocus,
        this.state.accountCode, this.state.roleName, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        let importedEmailId = null
        let cuFirstName = ''
        let cuLastName = ''
        let emailId = ''
        let password = ''
        let roleName = null
        let roleNameLinked = null
        const otherRoleName = null

        // let phoneNumber = null
        // let accountCode = null
        let loginType = "SignUp"
        let loggedIn = false

        this.setState({ isSavingModal: false, modalIsOpen: false, successMessage: returnedValue.message,
          importedEmailId, cuFirstName, cuLastName, emailId, password, roleName, roleNameLinked, otherRoleName,
          loginType, loggedIn
        })

      } else if (returnedValue && returnedValue.message) {
        this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
      } else {
        console.log('show returnedValue 2: ', returnedValue)
      }
    }

    switchPreference(type, name, index, value) {
      console.log('switchPreference called', type, name, index, value)

      let profileItems = this.state.profileItems
      profileItems[index][name] = value
      this.setState({ profileItems, profileItemsChanged: !this.state.profileItemsChanged })

    }

    passProductData(passedData) {
      console.log('passProductData called in superview', passedData)

      if (passedData) {
        const purchased = passedData.purchased
        const success = passedData.success
        const paymentIntent = passedData.paymentIntent
        const successMessage = passedData.successMessage
        this.setState({ purchased, success, paymentIntent, successMessage })
      }

      // this.setState({ product, recipientType: null })
      // this.props.closeModal()

    }

    bookSession() {
      console.log('bookSession called')

      // price={Number(this.state.itemSelected.price.replace('$',''))} productName={this.state.itemSelected.name}
      // product={this.state.itemSelected} orgCode={this.state.activeOrg}
      // firstName={this.state.cuFirstName} lastName={this.state.cuLastName}
      // email={this.state.emailId} orgName={this.state.orgName}
      // accountCode={this.state.accountCode} employerName={this.state.employerName}
      // promoCode={this.state.promoCode} bookingDate={this.state.bookingDate}
      // profileItems={this.state.profileItems} recipientType={this.state.recipientType}
      // recipientFirstName={this.state.recipientFirstName}
      // recipientLastName={this.state.recipientLastName}
      // recipientEmail={this.state.recipientEmail} profileItemsChanged={this.state.profileItemsChanged}

      const firstName = this.state.cuFirstName
      const lastName = this.state.cuLastName
      const email = this.state.emailId
      const orgName = this.state.orgName
      const accountCode = this.state.accountCode
      const employerName = this.state.employerName
      const name = this.state.itemSelected.name
      const slug = this.state.itemSelected.slug
      const category = this.state.itemSelected.category
      const imageURL = this.state.itemSelected.imageURL
      const productId = this.state.itemSelected.productId
      const advisorFirstName = this.state.itemSelected.advisorFirstName
      const advisorLastName = this.state.itemSelected.advisorLastName
      const advisorEmail = this.state.itemSelected.advisorEmail
      const advisorBio = this.state.itemSelected.advisorBio
      const advisorPic = this.state.itemSelected.advisorPic
      const advisorProfileLink = this.state.itemSelected.advisorProfileLink
      const description = this.state.itemSelected.description
      const customerId = this.state.itemSelected.customerId
      const price = this.state.price
      const quantity = 1
      const orgCode = this.state.activeOrg
      const promoCode = this.state.promoCode
      const bookingDate = this.state.bookingDate
      const bookingDateFormatted = convertDateToString(new Date(this.state.bookingDate),"datetime-2")
      const profileItems = this.state.profileItems
      const recipientType = this.state.recipientType
      const recipientFirstName = this.state.recipientFirstName
      const recipientLastName = this.state.recipientLastName
      const recipientEmail = this.state.recipientEmail
      const createdAt = new Date()
      const updatedAt = new Date()

      Axios.post('/api/bookings', {
        firstName, lastName, email, orgName, accountCode, employerName,
        name, slug, category, imageURL, productId,
        advisorFirstName, advisorLastName, advisorEmail, advisorBio, advisorPic, advisorProfileLink,
        description, customerId, price, quantity,
        orgCode, promoCode, bookingDate, bookingDateFormatted, profileItems,
        recipientType, recipientFirstName, recipientLastName, recipientEmail,
        createdAt, updatedAt
      }).then((response) => {

        if (response.data.success) {
          //save values
          console.log('Product is purchased! ', response.data);

          this.setState({ successMessage: response.data.message })

        } else {
          console.log('Product not purchased')
          this.setState({ errorMessage: 'There was an error setting up your account'})

        }

      }).catch((error) => {
          console.log('Product purchase did not work', error);
      });
    }

    render() {
      // console.log('show me itemSelected-----: ', this.state.price)
      return (
          <div>
            {(this.state.itemSelected) && (
              <div>
                <div>
                  <div className="fixed-column-30 height-40" />
                  <div className="calc-column-offset-60">
                    <img className="image-auto-80 center-horizontally" alt="GC" src={this.state.itemSelected.imageURL} />
                  </div>
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.props.closeModal()}>
                      <img src={closeIcon} alt="GC" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>

                <p className="heading-text-4 full-width center-text top-margin-20">{this.state.itemSelected.name}</p>

                <p className="description-text-1 top-margin center-text">{this.state.itemSelected.category} with {(window.location.pathname.includes('/app/')) ? <label>{this.state.orgName}</label> : <a href={this.state.itemSelected.advisorProfileLink} target="_blank" rel="noopener noreferrer">{this.state.itemSelected.advisorFirstName} {this.state.itemSelected.advisorLastName}</a>}</p>

                <div className="spacer" /><div className="half-spacer" />

                {(this.state.purchased) ? (
                  <div className="top-margin-30">
                    <p className="cta-color full-width center-text">You've successfully booked this service! Check your email for a confirmation of your booking!</p>
                    <p className="full-width center-text top-margin-20">To view all of your bookings and leave a review of your experience, visit the bookings section of your profile <Link to={"/app/bookings"} target="_blank">here</Link>.</p>
                  </div>
                ) : (
                  <div>
                    {(this.state.itemSelected.category) && (
                      <div className="row-20">

                        {(!window.location.pathname.includes('/app/')) && (
                          <div>
                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 bottom-margin-5">Who is this for?</p>
                                <select name="recipientType" value={this.state.recipientType} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.recipientTypeOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              {(this.state.recipientType === 'Someone Else') && (
                                <div className="container-right">
                                  <p className="description-text-2 bottom-margin-5">Recipient's First Name</p>
                                  <input type="text" className="text-field" placeholder="(e.g., Jon)..." name="recipientFirstName" value={this.state.recipientFirstName} onChange={this.formChangeHandler}></input>
                                </div>
                              )}
                              <div className="clear" />

                            </div>

                            {(this.state.recipientType === 'Someone Else') && (
                              <div className="row-10">
                                <div className="container-left">
                                  <p className="description-text-2 bottom-margin-5">Recipient's Last Name</p>
                                  <input type="text" className="text-field" placeholder="(e.g., Schmoe)..." name="recipientLastName" value={this.state.recipientLastName} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <p className="description-text-2 bottom-margin-5">Recipient's Email</p>
                                  <input type="text" className="text-field" placeholder="(e.g., jonschmoe@gmail.com)..." name="recipientEmail" value={this.state.recipientEmail} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />

                              </div>
                            )}
                          </div>
                        )}

                        {(this.state.recipientType === 'Me') && (
                          <div>
                            {(this.state.importedEmailId) ? (
                              <div>
                                {(!window.location.pathname.includes('/app/')) && (
                                  <div>
                                    <div className="row-10">
                                      <div className="fixed-column-30">
                                        <img src={(this.state.pictureURL) ? this.state.pictureURL : checkmarkIcon} alt="GC" className="profile-thumbnail-20" />
                                      </div>
                                      <div className="calc-column-offset-30">
                                        <p className="description-text-3 top-padding-3">You're logged in as {this.state.emailId}. If this isn't you, <button className="background-button cta-color bold-text" onClick={() => this.logout()} disabled={this.state.isSavingModal}>log out</button>.</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="spacer" />
                                  </div>
                                )}

                                <div className="row-10">
                                  <div className="standard-border padding-20">
                                    <p className="row-10 heading-text-6">Tag profile items you'd like to share:</p>
                                    <div className="half-spacer" />

                                    {this.state.profileItems.map((value, index) =>
                                      <div className="row-10">
                                        <div className="relative-column-75">
                                          <div className="fixed-column-25">
                                            <p>{index + 1}.</p>
                                          </div>
                                          <div className="calc-column-offset-25">
                                            <p>{value.name}</p>
                                          </div>
                                          <div className="clear" />

                                          {(value.name === 'LinkedIn Profile' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.linkedInURL) ? (
                                                <a className="cta-color description-text-3 bold-text wrap-text" href={this.state.linkedInURL} target="_blank" rel="noopener noreferrer">{this.state.linkedInURL}</a>
                                              ) : (
                                                <p className="error-color description-text-3 bold-text">Your LinkedIn has not been added to your profile yet.</p>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Resume' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.resumeURL) ? (
                                                <a className="cta-color description-text-3 bold-text wrap-text" href={this.state.resumeURL} target="_blank" rel="noopener noreferrer">{this.state.resumeURL}</a>
                                              ) : (
                                                <p className="error-color description-text-3 bold-text">Your resume has not been added to your profile yet.</p>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'SMART Goals' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.goals) ? (
                                                <div>
                                                  {this.state.goals.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">{value2.title}</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="error-color description-text-3 bold-text">No goals added to your profile yet.</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Career Plan' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.careerPlanFileNames && this.state.careerPlanFileNames.length > 0) ? (
                                                <div>
                                                  {this.state.careerPlanFileNames.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">{value2}</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <p className="error-color description-text-3 bold-text">Your career plan has not been added to your profile yet.</p>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Career Assessments' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.careerAssessments) ? (
                                                <div>
                                                  {this.state.careerAssessments.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">{value2}</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <p className="error-color description-text-3 bold-text">You have not taken career assessments yet.</p>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Endorsements' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.endorsements) ? (
                                                <div>
                                                  {this.state.endorsements.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">From {value2.senderFirstName} {value2.senderLastName} on {convertDateToString(value2.createdAt,"date")}</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="error-color description-text-3 bold-text">No endorsements added to your profile yet.</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Favorited Items' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.favorites && this.state.favorites.length > 0) ? (
                                                <div>
                                                  {this.state.favorites.map((value2, index2) =>
                                                    <span>
                                                      {(value2.title || value2.name) && (
                                                        <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                          <label className="description-text-3">{(value2.title) ? value2.title : value2.name}{(value2.employerName) && " @ " + value2.employerName}<label className="description-text-1">({this.state.types[index2].toUpperCase()})</label></label>
                                                        </span>
                                                      )}
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="error-color description-text-3 bold-text">No favorites added to your profile yet.</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Prior Advising Sessions' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.advisingSessions) ? (
                                                <div>
                                                  {this.state.advisingSessions.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">{value2.advisorFirstName} {value2.advisorLastName} on {convertDateToString(value2.createdAt,"date")}({value2.category})</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="error-color description-text-3 bold-text">No prior advising sesssions</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                          {(value.name === 'Passions' && value.include) && (
                                            <div className="top-padding-5">
                                              {(this.state.passions) ? (
                                                <div>
                                                  {this.state.passions.map((value2, index2) =>
                                                    <span className="float-left right-margin light-background top-margin tag-container-basic">
                                                      <label className="description-text-3">{value2.passionTitle}</label>
                                                    </span>
                                                  )}
                                                  <div className="clear" />
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="error-color description-text-3 bold-text">No passions logged to your profile yet.</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>

                                        <div className="relative-column-25 right-text">
                                          <Switch
                                            onChange={(change) => this.switchPreference('app','include', index, change)}
                                            checked={value.include}
                                            id="normal-switch"
                                            disabled={value.disabled}
                                          />
                                        </div>
                                        <div className="clear" />

                                      </div>
                                    )}

                                    <div className="spacer" />
                                    <hr />
                                    <div className="spacer" /><div className="spacer" />

                                    <p className="description-text-3">You can edit your profile <Link to={"/app/edit-profile"} target="_blank">here</Link>. You can view the publicized portions of your profile <Link to={"/" + this.state.username + "/profile"} target="_blank">here</Link>.</p>
                                  </div>

                                  <div className="spacer" />
                                </div>


                              </div>
                            ) : (
                              <div>
                                {(this.state.loginType === 'SignUp') ? (
                                  <div>
                                    <div className="row-10">
                                      <p className="description-text-1 top-padding-5">Sign Up to Book</p>
                                    </div>

                                  </div>
                                ) : (
                                  <div>
                                    <div className="row-10">
                                      <p className="description-text-1 top-padding-5">Login to Book</p>
                                    </div>
                                  </div>
                                )}

                                <SubLogInFields history={this.props.navigate}
                                  formChangeHandler={this.formChangeHandler} passData={this.passData}
                                  onlyFields={true} logout={this.logout} type={this.state.loginType}
                                  loggedIn={this.state.loggedIn} emailId={this.state.emailId}
                                  orgCode={this.state.activeOrg} closeModal={this.props.closeModal}
                                  skipCloseModal={true}
                                />

                                {(this.state.loginType === 'SignUp') ? (
                                  <div className="row-10">
                                    <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignIn' })} disabled={this.state.isSavingModal} >Log In</button> to import your information</p>
                                  </div>
                                ) : (
                                  <div className="row-10">
                                    <p className="description-text-3 top-padding-5">Don't have an account? <button className="background-button cta-color bold-text" onClick={() => this.setState({ loginType: 'SignUp' })} disabled={this.state.isSavingModal} >Sign up</button></p>
                                  </div>
                                )}

                                {/*
                                {(this.state.showSignUp) ? (
                                  <div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="row-10">
                                      <p className="description-text-1 top-padding-5">Login to import your information</p>
                                    </div>

                                    <div className="row-10">
                                      <div className="container-left">
                                        <p className="description-text-2 bottom-margin-5">Email</p>
                                        <input type="text" className="text-field" placeholder="(e.g., joeschmoe@gmail.com)..." name="emailId" value={this.state.emailId} onChange={this.formChangeHandler}></input>
                                      </div>
                                      <div className="container-right">
                                        <p className="description-text-2 bottom-margin-5">Password</p>
                                        <input type="text" className="text-field" placeholder="(e.g., joeschmoe@gmail.com)..." name="password" value={this.state.password} onChange={this.formChangeHandler}></input>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    <div className="row-10">
                                      <button className="btn btn-squarish" onClick={() => console.log('')}>Log In</button>
                                    </div>

                                    <p className="description-text-3 top-padding-5">Don't have an account? Toggle <button className="background-button cta-color bold-text" onClick={() => this.setState({ showSignUp: true })} disabled={this.state.isSavingModal}>here</button> to sign up!</p>
                                  </div>
                                )}*/}

                              </div>
                            )}

                            <div className="row-20">
                              <hr />
                            </div>

                          </div>
                        )}

                        {(this.state.recipientType === 'Someone Else') && (
                          <div>
                            <div className="row-10">
                              <div className="container-left">
                                <p className="description-text-2 bottom-margin-5">Your First Name</p>
                                <input type="text" className="text-field" placeholder="(e.g., Jane)..." name="cuFirstName" value={this.state.cuFirstName} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="container-right">
                                <p className="description-text-2 bottom-margin-5">Your Last Name</p>
                                <input type="text" className="text-field" placeholder="(e.g., Doe..." name="cuLastName" value={this.state.cuLastName} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            {(this.state.recipientType === 'Someone Else') && (
                              <div className="row-10">
                                <div className="container-left">
                                  <p className="description-text-2 bottom-margin-5">Your Email</p>
                                  <input type="text" className="text-field" placeholder="(e.g., joedoe@gmail.com)..." name="emailId" value={this.state.emailId} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />

                                <div className="spacer" /><div className="spacer" />
                                <hr />
                              </div>
                            )}
                          </div>
                        )}

                        {((this.state.recipientType === 'Me' && this.state.emailId || this.state.recipientType === 'Someone Else')) && (
                          <div>
                            {(this.state.recipientType) && (
                              <div className="row-10">
                                {/*
                                {(window.location.pathname.includes('/app/')) ? (
                                  <InlineWidget url="https://calendly.com/creighton-taylor/guided-compass-conversation" />
                                ) : (
                                  <span>
                                    <div className="container-left">
                                      <p className="description-text-2 bottom-margin-5">What time do you prefer?</p>
                                      <input type="datetime-local" min={convertDateToString(new Date(),"rawDateForInput")} className="date-picker" placeholder=" date" name="bookingDate" value={this.state.bookingDate} onChange={this.formChangeHandler}></input>
                                    </div>
                                    <div className="clear" />
                                  </span>
                                )}*/}
                                <span>
                                  <div className="container-left">
                                    <p className="description-text-2 bottom-margin-5">What time do you prefer?</p>
                                    <input type="datetime-local" min={convertDateToString(new Date(),"rawDateForInput")} className="date-picker" placeholder=" date" name="bookingDate" value={this.state.bookingDate} onChange={this.formChangeHandler}></input>
                                  </div>
                                  <div className="clear" />
                                </span>
                              </div>
                            )}
                            {/*
                            <div className="row-10">
                              <div className="fixed-column-80">
                                <p className="description-text-2 bottom-margin-5">Qty</p>
                                <select name="quantity" value={this.state.quantity} onChange={this.formChangeHandler} className="dropdown-thin">
                                  {this.state.quantityOptions.map(value =>
                                    <option key={value} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="calc-column-offset-160 left-text">
                                <div className="relative-column-20 min-width-100 center-text top-margin-20">
                                  <p>X</p>
                                </div>
                                <div className="relative-column-80 ">
                                  <p className="description-text-2 bottom-margin-5">Price</p>
                                  <p className="cta-color heading-text-6">{this.state.price}</p>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="fixed-column-80">
                                <p className="description-text-2 right-text bottom-margin-5">Total</p>
                                <p className="cta-color heading-text-6 full-width right-text underline-text">{this.state.total}</p>
                              </div>
                              <div className="clear" />
                            </div>*/}
                            {/*
                            <div className="row-10">
                              <div className="container-left">
                                <div className="calc-column-offset-70 right-padding">
                                  <p className="description-text-2 bottom-margin-5">Discount Code</p>
                                  <input type="text" className="text-field" placeholder="Enter discount Code..." name="discountCode" value={this.state.discountCode} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="fixed-column-70 top-margin-20">
                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                  <button className="btn btn-squarish" onClick={() => console.log('')}>Redeem</button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>*/}

                            <div className="spacer" /><div className="spacer" />

                            <hr />

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                            {(this.state.price) ? (
                              <div className="row-20">
                                <PreCheckoutBooking
                                  history={this.props.navigate} passProductData={this.passProductData} pageSource="landingPage"
                                  price={Number(this.state.itemSelected.price.replace('$',''))} productName={this.state.itemSelected.name}
                                  product={this.state.itemSelected} orgCode={this.state.activeOrg}
                                  firstName={this.state.cuFirstName} lastName={this.state.cuLastName}
                                  email={this.state.emailId} orgName={this.state.orgName}
                                  accountCode={this.state.accountCode} employerName={this.state.employerName}
                                  promoCode={this.state.promoCode} bookingDate={this.state.bookingDate}
                                  profileItems={this.state.profileItems} recipientType={this.state.recipientType}
                                  recipientFirstName={this.state.recipientFirstName}
                                  recipientLastName={this.state.recipientLastName}
                                  recipientEmail={this.state.recipientEmail} profileItemsChanged={this.state.profileItemsChanged}
                                />
                              </div>
                            ) : (
                              <div>
                                {(!window.location.pathname.includes('/app/')) && (
                                  <div className="row-20">
                                    <button className="btn btn-primary" onClick={() => this.bookSession()}>Confirm Booking</button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

              </div>
            )}
            {/*
            <div className="top-margin-20">
              <button className={((this.state.recipientType === 'Me' && this.state.emailId) || this.state.recipientType === 'Someone Else') ? "btn btn-primary right-margin-5" : "btn btn-primary medium-background standard-border right-margin-5"} disabled={((this.state.recipientType === 'Me' && this.state.emailId) || this.state.recipientType === 'Someone Else') ? false : true} onClick={() => console.log('')}>Book</button>
              <button className="btn btn-secondary left-margin-5" onClick={() => this.props.closeModal()}>Close View</button>
            </div>*/}
          </div>

      )
    }
}

export default ConfigureCartItem;
