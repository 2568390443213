import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubEditLog from '../Subcomponents/EditLog';
import withRouter from '../Functions/WithRouter';

const logIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/log-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';

class Logs extends Component {
    constructor(props) {
        super(props)

        this.state = {
          ignoreWorkLogs: true,
          prefix: '/app',
          fullPathPrefix: '/app/logs',
          sessions: [],
          logs: [],
          subNavCategories: ['All','Goals','Advising Sessions','Applications','Interviews','Offers','Passions'],
          subNavSelected: 'All',

          sessionErrorMessage: '',
          serverPostSuccess: false,
          serverErrorMessage: '',
          serverSuccessMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderLogs = this.renderLogs.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.segueToLink = this.segueToLink.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.reloadScreen = this.reloadScreen.bind(this)
    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.passedGoal !== prevProps.passedGoal) {
        this.retrieveData()
      }
    }

    retrieveData() {

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      let orgName = localStorage.getItem('orgName');
      let accountCode = localStorage.getItem('accountCode');

      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else {
        accountCode = null
      }

      let subNavCategories = ['All','Goals','Advising Sessions','Applications','Interviews','Offers','Passions']
      if (this.state.ignoreWorkLogs) {
        subNavCategories = ['All','Goals','Advising Sessions','Passions','Notes']
      }
      if (employerAccount) {
        subNavCategories = ['All','Goals','Advising Sessions','Passions']
      }

      if (email && activeOrg) {

        let prefix = '/app'
        let fullPathPrefix = '/app/logs'
        if (window.location.pathname.includes('/advisor')) {
          prefix = '/advisor'
          fullPathPrefix = '/advisor/edit-logs'
        }

        this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgName, orgFocus,
          logs: [], employerAccount, subNavCategories, prefix, fullPathPrefix
        });

        const pullOneUnifiedQuery = true

        if (pullOneUnifiedQuery) {
          Axios.get('/api/logs', { params: { emailId: email } })
          .then((response) => {
            if (response.data.success) {
              console.log('Goals received query worked', response.data);

              let logs = []

              let goals = []
              let meetings = []
              let applications = []
              let interviews = []
              let offers = []
              let passions = []

              for (let i = 1; i <= response.data.logs.length; i++) {
                if ((this.props.logType === 'Goal' && response.data.logs[i - 1].logType === 'Goal') || (this.props.logType !== 'Goal')) {
                  logs.push(response.data.logs[i - 1])
                }
              }

              this.setState({ logs })

            } else {
              console.log('no goal data found', response.data.message)
            }

          }).catch((error) => {
              console.log('Goal query did not work', error);
          });
        } else {

          if (this.props.logType !== 'Goal') {
            let sessionParams = { emailId: email, type: 'Advisee', hiddenToCareerSeeker: false }
            if (window.location.pathname.includes('/advisor')) {
              sessionParams = { emailId: email, type: 'Advisor', hiddenToCareerSeeker: false }
            }
            Axios.get('/api/counseling/session', { params: sessionParams })
            .then((response) => {
                if (response.data.success) {
                  console.log('Session query worked', response.data);

                  let logs = this.state.logs

                  let sessions = response.data.sessions
                  if (sessions.length > 0) {
                    let logType = 'Session'
                    if (activeOrg === 'any') {
                      logType = 'Check In'
                    }
                    for (let i = 1; i <= sessions.length; i++) {
                      sessions[i - 1]["logType"] = logType
                    }
                    logs = logs.concat(sessions)
                  }

                  let filteredLogs = logs
                  this.setState({ sessions, logs, filteredLogs })

                  // this.sortSessions(response.data.sessions)

                } else {
                  console.log('no session data found', response.data.message)
                  this.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }

            }).catch((error) => {
                console.log('Resource query did not work', error);
            });
          }

          Axios.get('/api/logs/goals', { params: { emailId: email } })
          .then((response) => {

              if (response.data.success) {
                console.log('Goals received query worked', response.data);

                let goals = response.data.goals
                let logs = this.state.logs

                // for (let i = 1; i <= goals.length; i++) {
                //   goals[i - 1]["logType"] = "Goal"
                // }

                logs = logs.concat(goals)
                let filteredLogs = logs

                this.setState({ goals, logs, filteredLogs })

              } else {
                console.log('no goal data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Goal query did not work', error);
          });

          if (this.props.logType !== 'Goal') {
            Axios.get('/api/logs/meetings', { params: { emailId: email } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Applications received query worked', response.data);

                  let meetings = response.data.meetings
                  let logs = this.state.logs

                  for (let i = 1; i <= meetings.length; i++) {
                    meetings[i - 1]["logType"] = "Meeting"
                  }

                  logs = logs.concat(meetings)
                  let filteredLogs = logs

                  this.setState({ meetings, logs, filteredLogs })

                } else {
                  console.log('no application data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Application query did not work', error);
            });


            Axios.get('/api/logs/applications', { params: { emailId: email } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Applications received query worked', response.data);

                  let applications = response.data.applications
                  let logs = this.state.logs

                  for (let i = 1; i <= applications.length; i++) {
                    applications[i - 1]["logType"] = "Application"
                  }

                  logs = logs.concat(applications)
                  let filteredLogs = logs

                  this.setState({ applications, logs, filteredLogs })

                } else {
                  console.log('no application data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Application query did not work', error);
            });

            Axios.get('/api/logs/interviews', { params: { emailId: email } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Interviews received query worked', response.data);

                  let interviews = response.data.interviews
                  let logs = this.state.logs

                  for (let i = 1; i <= interviews.length; i++) {
                    interviews[i - 1]["logType"] = "Interview"
                  }

                  logs = logs.concat(interviews)
                  let filteredLogs = logs

                  this.setState({ interviews, logs, filteredLogs })

                } else {
                  console.log('no interview data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Interview query did not work', error);
            });

            Axios.get('/api/logs/offers', { params: { emailId: email } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Offers received query worked', response.data);

                  let offers = response.data.offers
                  let logs = this.state.logs

                  for (let i = 1; i <= offers.length; i++) {
                    offers[i - 1]["logType"] = "Offer"
                  }

                  logs = logs.concat(offers)
                  let filteredLogs = logs

                  this.setState({ offers, logs, filteredLogs })

                } else {
                  console.log('no offer data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Offer query did not work', error);
            });

            Axios.get('/api/logs/passions', { params: { emailId: email } })
            .then((response) => {

                if (response.data.success) {
                  console.log('Passions received query worked', response.data);

                  let passions = response.data.passions
                  let logs = this.state.logs

                  for (let i = 1; i <= passions.length; i++) {
                    passions[i - 1]["logType"] = "Passion"
                  }

                  logs = logs.concat(passions)
                  let filteredLogs = logs

                  this.setState({ passions, logs, filteredLogs })

                } else {
                  console.log('no passion data found', response.data.message)
                }

            }).catch((error) => {
                console.log('Passion query did not work', error);
            });

            // Axios.get('/api/logs/notes', { params: { emailId: email } })
            // .then((response) => {
            //
            //     if (response.data.success && response.data.notes) {
            //       console.log('Notes received query worked', response.data);
            //
            //       let notesEntries = response.data.notes
            //       let logs = this.state.logs
            //
            //       for (let i = 1; i <= notesEntries.length; i++) {
            //         notesEntries[i - 1]["logType"] = "Notes"
            //       }
            //
            //       logs = logs.concat(notesEntries)
            //       let filteredLogs = logs
            //
            //       this.setState({ notesEntries, logs, filteredLogs })
            //
            //     } else {
            //       console.log('no notes entriesdata found', response.data.message)
            //     }
            //
            // }).catch((error) => {
            //     console.log('Notes query did not work', error);
            // });
          }
        }
      }
    }

    renderLogs() {
      console.log('renderLogs called')

      let counter = 0

      let rows = [];
      if ( this.state.logs.length !== 0 ) {

        for (let i = 1; i <= this.state.logs.length; i++) {
          console.log(i);

          const index = i - 1

          let createdAtString = ''
          if (this.state.logs[i - 1] && this.state.logs[i - 1].createdAt) {
            createdAtString = " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"date-2")
          }

          //starting assuming a session
          let title = this.state.logs[i - 1].advisorFirstName
          let subtitle = this.state.logs[i - 1].category + " " + this.state.logs[i - 1].logType + createdAtString
          let extratitle = null
          let logLink = this.state.fullPathPrefix + '/' + this.state.logs[i - 1]._id

          if (this.state.logs[i - 1].logType === 'Session' || this.state.logs[i - 1].logType === 'Check In') {
            if (this.state.logs[i - 1].advisorLastName) {
              title = this.state.logs[i - 1].advisorFirstName + " " + this.state.logs[i - 1].advisorLastName
            }
            subtitle = this.state.logs[i - 1].category + " " + this.state.logs[i - 1].logType + " " + convertDateToString(new Date(this.state.logs[i - 1].sessionDate),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Meeting') {
            title = convertDateToString(new Date(this.state.logs[i - 1].startTime),"datetime-2") + ' Meeting in ' + this.state.logs[i - 1].groupName
            subtitle = this.state.logs[i - 1].location
          } else if (this.state.logs[i - 1].logType === 'Goal') {
            title = this.state.cuFirstName + ' ' + this.state.cuLastName + ' Goal'
            if (this.state.logs[i - 1].title) {
              title = this.state.logs[i - 1].title
            } else if (this.state.logs[i - 1].description) {
              title = this.state.logs[i - 1].description
            }

            subtitle = this.state.logs[i - 1].logType + " | Created on " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2") + " | Deadline: " + convertDateToString(new Date(this.state.logs[i - 1].deadline),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Application') {
            title = this.state.logs[i - 1].positionTitle
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].employerName + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Interview') {
            title = this.state.logs[i - 1].associatedApplicationPositionTitle
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].associatedApplicationEmployerName + " | " + this.state.logs[i - 1].fitRating
          } else if (this.state.logs[i - 1].logType === 'Offer') {
            title = this.state.logs[i - 1].associatedApplicationPositionTitle
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].associatedApplicationEmployerName + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Passion') {
            title = this.state.logs[i - 1].passionTitle
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].passionReason + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Notes') {

            if (this.state.logs[i - 1].generalNotes.length < 50) {
              title = this.state.logs[i - 1].generalNotes
              subtitle = convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
            } else {
              let lineBreakRanges = [30, 50, 175]
              let lineBreakIndex1 = 0
              let lineBreakIndex2 = 0
              if (this.state.logs[i - 1].generalNotes.length < lineBreakRanges[2]) {
                lineBreakIndex2 = this.state.logs[i - 1].generalNotes.length - 1
              }
              for (let j = 1; j <= lineBreakRanges[2]; j++) {
                if (this.state.logs[i - 1].generalNotes[j - 1]) {
                  if (j > lineBreakRanges[0] && j < lineBreakRanges[1] && (this.state.logs[i - 1].generalNotes[j - 1] === '\n' || this.state.logs[i - 1].generalNotes[j - 1] === ' ' || this.state.logs[i - 1].generalNotes[j - 1] === '  ')) {
                    lineBreakIndex1 = j - 1
                  }
                  if (j > lineBreakRanges[1]) {

                    if (j > lineBreakRanges[1] && j < lineBreakRanges[2] && (this.state.logs[i - 1].generalNotes[j - 1] === '\n' || this.state.logs[i - 1].generalNotes[j - 1] === ' ' || this.state.logs[i - 1].generalNotes[j - 1] === /\s/g)) {
                      lineBreakIndex2 = j - 1
                    }
                  }
                }
              }

              title = this.state.logs[i - 1].generalNotes.substring(0, lineBreakIndex1)
              subtitle = this.state.logs[i - 1].generalNotes.substring(lineBreakIndex1, lineBreakIndex2)
              if (this.state.logs[i - 1].generalNotes.length > lineBreakRanges[2]) {
                subtitle = subtitle + "..."
              }
              extratitle = convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
            }

            // title = this.state.logs[i - 1].generalNotes
            // subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].passionReason + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Native Application') {
            title = this.state.logs[i - 1].postingTitle
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].postingEmployerName + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          } else if (this.state.logs[i - 1].logType === 'Native Offer') {
            title = this.state.logs[i - 1].title
            subtitle = this.state.logs[i - 1].logType + " | " + this.state.logs[i - 1].employerName + " | " + convertDateToString(new Date(this.state.logs[i - 1].createdAt),"datetime-2")
          }

          let showLog = false
          if (this.state.subNavSelected === 'All') {
            showLog = true
          } else if (this.state.subNavSelected === 'Meetings') {
            showLog = true
          } else if (this.state.subNavSelected === 'Advising Sessions' && this.state.logs[i - 1].logType === 'Session') {
            showLog = true
          } else if (this.state.subNavSelected === 'Goals' && this.state.logs[i - 1].logType === 'Goal') {
            showLog = true
          } else if (this.state.subNavSelected === 'Applications' && this.state.logs[i - 1].logType === 'Application') {
            showLog = true
          } else if (this.state.subNavSelected === 'Interviews' && this.state.logs[i - 1].logType === 'Interview') {
            showLog = true
          } else if (this.state.subNavSelected === 'Offers' && this.state.logs[i - 1].logType === 'Offer') {
            showLog = true
          } else if (this.state.subNavSelected === 'Passions' && this.state.logs[i - 1].logType === 'Passion') {
            showLog = true
          } else if (this.state.subNavSelected === 'Notes' && this.state.logs[i - 1].logType === 'Notes') {
            showLog = true
          }

          if (showLog) {
            counter = counter + 1

            if (this.state.subNavSelected === 'Goals' && this.state.logs[i - 1].logType === 'Passion') {
              console.log('show log status: ', showLog, this.state.subNavSelected, this.state.logs[i - 1])
            }
            rows.push(
              <div key={this.state.logs[i - 1]._id + '|' + this.state.subNavSelected} className="full-width row-10">
                <button className="background-button full-width cta-color left-text" onClick={() => this.segueToLink(logLink, index)}>
                  <div className="fixed-column-40">
                    <p>{counter}. </p>
                  </div>
                  <span className="calc-column-offset-90">
                    <div className="heading-text-6">
                        <p>{title}</p>
                    </div>
                    <div className="clear" />
                    <p className="description-text-2 standard-color top-margin-5">{subtitle}</p>
                    {(extratitle) && (
                      <p className="description-text-2 standard-color top-margin-5">{extratitle}</p>
                    )}
                  </span>
                  <div className="fixed-column-50">
                    <div className="action-icon-container">
                      <img src={arrowIndicatorIcon} alt="GC" className="arrow-indicator-icon"/>
                    </div>
                  </div>
                </button>
                <div className="clear" />
              </div>
            )
          }

        }
      } else {

        if (!window.location.pathname.includes(this.state.prefix + '/walkthrough')) {
          rows.push(
            <div key={1} className="flex-container flex-center center-text padding-30">
              <div className="padding-5-percent" >
                <img src={logIconDark} alt="Compass large endorsements icon" className="empty-state-icon"/>
                <h3 className="empty-state-title"><strong>No Logs Yet</strong></h3>
                <p className="empty-state-text full-width">Log information (e.g. goals, advising sessions, work applications) so that mentors and {this.state.orgName} staff can better help.</p>

                <Link to={this.state.fullPathPrefix + '/new'} state={{ editExisting: false, logs: this.state.logs }} className="btn btn-primary cta-background-color white-text top-margin-30">Add New Log</Link>
              </div>
            </div>
          )
        }
      }

      return rows;
    }

    segueToLink(logLink, index) {
      console.log('segueToLink called')

      if (window.location.pathname.includes(this.state.prefix + '/walkthrough')) {
        this.setState({ modalIsOpen: true, showGoal: true, logLink, selectedIndex: index })
      } else {
        this.props.navigate(logLink, { state: { editExisting: true, logs: this.state.logs, log: this.state.logs[index] }})
      }
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      this.setState({ subNavSelected: pageName })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showGoal: false })

    }

    reloadScreen() {
      console.log('reloadScreen called')

      this.retrieveData()
    }

    render() {

        return (
            <div>
              <div>

                  {(!window.location.pathname.includes(this.state.prefix + '/walkthrough')) && (
                    <div>
                      <div className="row-20">
                        <div className="calc-column-offset-50">
                          <p className="heading-text-2">Logs</p>
                        </div>
                        <div className="fixed-column-50 top-padding-5">
                          <Link to={this.state.fullPathPrefix + '/new'} state={{ editExisting: false, logs: this.state.logs }}>
                            <img src={addIcon} alt="Compass add icon" className="add-icon"/>
                          </Link>
                        </div>

                        <div className="clear" />
                      </div>

                      {(this.state.logs && this.state.logs.length > 0) && (
                        <div>
                          <div className="carousel-3 light-background" onScroll={this.handleScroll}>
                            {this.state.subNavCategories.map((value, index) =>
                              <div className="carousel-item-container">
                                {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                  <div className="selected-carousel-item">
                                    <label key={value}>{value}</label>
                                  </div>
                                ) : (
                                  <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                                    <label key={value}>{value}</label>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="spacer"/>
                  {this.renderLogs()}

                  <div className="clear" />
              </div>

              {(this.state.showGoal) && (
                <div>
                  <SubEditLog modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} history={this.props.navigate} editExisting={true} log={this.state.logs[this.state.selectedIndex]} logs={this.state.logs} passedLogType="Goal" selectedAdvisor={this.state.selectedAdvisor} logId={this.state.logs[this.state.selectedIndex]._id} reloadScreen={this.reloadScreen} modalView={this.props.modalView} />
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(Logs);
