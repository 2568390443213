import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import SubEditBusinessIdea from '../components/Subcomponents/EditBusinessIdea';
import withRouter from '../components/Functions/WithRouter';

class EditBusinessIdeaPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
        //see if user is logged in
        console.log('problemPlatformProfilePage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        let emailId = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');

        console.log('show rolename: ', roleName, activeOrg)

        this.setState({ emailId, activeOrg, orgFocus, roleName })

        if (this.props.location && this.props.location.state) {
          // show welcome message
          const { newUser } = this.props.location.state;
          console.log('just signed up: ', newUser)
          this.setState({ newUser })
        }
    }

    render() {

      return (
        <div>
          <TopNavigation currentPage={"EditBusinessIdeaPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

          <div className="standard-container-2">
            <SubEditBusinessIdea activeOrg={this.state.activeOrg} history={this.props.navigate} />
          </div>

        </div>
      )
    }
}

export default withRouter(EditBusinessIdeaPage);
