import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {requestAccessToWorkspace} from '../Services/ProfileRoutes';
import withRouter from '../Functions/WithRouter';

const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const fullStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-star.png';
const emptyStar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/empty-star.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';

class OrgDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          inModal: false
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.submitReview = this.submitReview.bind(this)

        this.renderSignUpFields = this.renderSignUpFields.bind(this)
        this.submitSignUpFields = this.submitSignUpFields.bind(this)
        this.optionClicked = this.optionClicked.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.openSignUpFieldsModal = this.openSignUpFieldsModal.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('orgDetailsDidMount')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in orgDetails ')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.orgId !== prevProps.orgId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within orgDetails sub space', this.props)

      let accountCode = this.props.accountCode
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      // let activeOrg = localStorage.getItem('activeOrg');
      // let orgFocus = localStorage.getItem('orgFocus');

      this.setState({ emailId: email, cuFirstName, cuLastName, username, accountCode, isAnimating: true })

      Axios.get('/api/org', { params: { orgCode: this.props.orgId } })
      .then((response) => {
        console.log('Org info query attempted in SubOrgDetails', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let orgSelected = response.data.orgInfo
            let cta = 'Request Access'
            if (orgSelected.waitlist) {
              cta = 'Join Waitlist'
            } else if (orgSelected.isOpen) {
              cta = 'Join Workspace'
            }
            orgSelected['cta'] = cta
            this.setState({ orgSelected });

            let ratingSelected = null
            let reviewSelected = null
            if (window.location.pathname.includes('/employers')) {
              if (orgSelected.employerReviews && orgSelected.employerReviews.length > 0) {
                for (let i = 1; i <= orgSelected.employerReviews.length; i++) {
                  if (orgSelected.employerReviews[i - 1].email === this.state.emailId) {
                    ratingSelected = orgSelected.employerReviews[i - 1].rating
                    reviewSelected = orgSelected.employerReviews[i - 1].review
                  }
                }
              }
            } else {
              if (orgSelected.careerSeekerReviews && orgSelected.careerSeekerReviews.length > 0) {
                for (let i = 1; i <= orgSelected.careerSeekerReviews.length; i++) {
                  if (orgSelected.careerSeekerReviews[i - 1].email === this.state.emailId) {
                    ratingSelected = orgSelected.careerSeekerReviews[i - 1].rating
                    reviewSelected = orgSelected.careerSeekerReviews[i - 1].review
                  }
                }
              }
            }
            this.setState({ ratingSelected, reviewSelected })

            Axios.get('/api/org/members/count', { params: { orgCode: orgSelected.orgCode }})
            .then((response) => {
               console.log('Org member count query attempted', response.data);

               if (response.data.success) {
                 console.log('successfully retrieved org member count')

                 let stats = orgSelected['stats']
                 if (stats) {
                   stats['memberCount'] = response.data.memberCount
                 } else {
                   stats = { memberCount: response.data.memberCount }
                 }

                 orgSelected['stats'] = stats
                 this.setState({ orgSelected })

               } else {
                 console.log('no org member count found', response.data.message)
               }

            }).catch((error) => {
                 console.log('Org member account query did not work', error);
            });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
      console.log('ac?', accountCode)

      if (accountCode) {
        Axios.get('/api/account', { params: { accountCode } })
        .then((response) => {
          console.log('Account info query attempted in sub org details', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            console.log('show the breakdown 1: ', response.data.accountInfo, response.data.accountInfo.sharePartners)

            const employerName = response.data.accountInfo.employerName
            const employerLogo = response.data.accountInfo.employerLogoURI
            const sharePartners = response.data.accountInfo.sharePartners

            console.log('show the breakdown 2: ', response.data.accountInfo, response.data.accountInfo.sharePartners, employerName)

            this.setState({ employerName, employerLogo, sharePartners });

          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }

      const fetchDetailsURL = '/api/users/profile/details/' + email
      Axios.get(fetchDetailsURL)
      .then((response) => {
        if (response.data) {

          let myOrgs = response.data.user.myOrgs
          const joinRequests = response.data.user.joinRequests

          const workAuthorization = response.data.user.workAuthorization
          const zipcode = response.data.user.zipcode
          const dateOfBirth = response.data.user.dateOfBirth
          const gender = response.data.user.gender
          const race = response.data.user.race
          const races = response.data.user.races
          const selfDescribedRace = response.data.user.selfDescribedRace
          const address = response.data.user.address
          const phoneNumber = response.data.user.phoneNumber
          const alternativePhoneNumber = response.data.user.alternativePhoneNumber
          const alternativeEmail = response.data.user.alternativeEmail
          const numberOfMembers = response.data.user.numberOfMembers
          const householdIncome = response.data.user.householdIncome
          const fosterYouth = response.data.user.fosterYouth
          const homeless = response.data.user.homeless
          const incarcerated = response.data.user.incarcerated
          const adversityList = response.data.user.adversityList

          const pictureURL = response.data.user.pictureURL
          const education = response.data.user.education
          const educationStatus = response.data.user.educationStatus

          const referrerName = response.data.user.referrerName
          const referrerEmail = response.data.user.referrerEmail
          const referrerOrg = response.data.user.referrerOrg

          this.setState({ myOrgs, joinRequests, workAuthorization, zipcode, dateOfBirth, gender, race, races, selfDescribedRace,
            address, phoneNumber, alternativePhoneNumber, alternativeEmail, numberOfMembers,
            householdIncome, fosterYouth, homeless, incarcerated, adversityList, pictureURL, education, educationStatus,
            referrerName, referrerEmail, referrerOrg
          })

        }
      }).catch((error) => {
          console.log('Profile pic fetch did not work', error);
      });

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name.includes('education|')) {
        const name = event.target.name.split("|")[1]

        let education = this.state.education
        if (education && education[0]) {
          education[0][name] = event.target.value
        } else {
          education = [{}]
          education[0][name] = event.target.value
        }

        this.setState({ education })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    submitSignUpFields() {
      console.log('submitSignUpFields called')

      if (this.state.orgSelected.signUpFieldsRequired && this.state.orgSelected.signUpFieldsRequired.length > 0) {
        for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
          console.log('l1')
          // if (this.state.orgSelected.signUpFieldsRequired[i - 1].required) {
          //   console.log('l2', this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand, this.state.education, this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand])
          //   // multiple answer is array
          //   if (this.state.orgSelected.signUpFieldsRequired[i - 1].questionType === 'Multiple Answer' && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] || this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand].length === 0)) {
          //     return this.setState({ errorMessage: 'Please add answer(s) for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
          //   } else if (!this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.includes("|") && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] || !this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand] === '')) {
          //     return this.setState({ errorMessage: 'Please add an answer for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
          //   } else if (this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.includes("|") && (!this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.split("|")[0]] || this.state[this.state.orgSelected.signUpFieldsRequired[i - 1].shorthand.split("|")[0]].length === 0)) {
          //     return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
          //     // return this.setState({ errorMessage: 'Please add answer(s) for the ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name + ' field' })
          //   }
          // }

          const item = this.state.orgSelected.signUpFieldsRequired[i - 1]
          if (item.required) {
            console.log('l2')

            // multiple answer is arr ay
            if (item.questionType === 'Multiple Answer' && (!this.state[item.shorthand] || this.state[item.shorthand].length === 0)) {
              return this.setState({ errorMessage: 'Please add answer(s) for ' + this.state.orgSelected.signUpFieldsRequired[i - 1].name })
            } else if (!item.shorthand.includes("|") && (!this.state[item.shorthand] || !this.state[item.shorthand] === '')) {
              return this.setState({ errorMessage: 'Please add an answer for ' + item.name })
            } else if (item.shorthand.includes("|") && (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0)) {
              return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
            } else if (item.shorthand.includes("|")) {
              console.log('l3', this.state[item.shorthand.split("|")[0]])
              if (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0) {
                return this.setState({ errorMessage: 'Please add answer(s) for the education fields' })
              } else if (!this.state[item.shorthand.split("|")[0]][0][item.shorthand.split("|")[1]]) {
                return this.setState({ errorMessage: 'Please add answer(s) for each of the education fields' })
              }
              console.log('l4')
            }
          }
        }
      }

      const dateOfBirth = this.state.dateOfBirth
      const gender = this.state.gender
      const race = this.state.race
      const races = this.state.races
      const selfDescribedRace = this.state.selfDescribedRace
      const address = this.state.address
      const zipcode = this.state.zipcode
      const phoneNumber = this.state.phoneNumber
      const alternativePhoneNumber = this.state.alternativePhoneNumber
      const alternativeEmail = this.state.alternativeEmail
      const numberOfMembers = this.state.numberOfMembers
      const householdIncome = this.state.householdIncome
      const fosterYouth = this.state.fosterYouth
      const homeless = this.state.homeless
      const incarcerated = this.state.incarcerated
      const workAuthorization = this.state.workAuthorization
      const adversityList = this.state.adversityList
      const educationStatus = this.state.educationStatus

      let education = this.state.education
      // if (this.state.education && this.state.education[0]) {
      //   education[0]['name'] = this.state.education.name
      //   education[0]['endDate'] = this.state.education.endDate
      //   education[0]['major'] = this.state.education.major
      // }
      const referrerName = this.state.referrerName
      const referrerEmail = this.state.referrerEmail
      const referrerOrg = this.state.referrerOrg

      const signUpFields = {
        dateOfBirth, gender, race, races, selfDescribedRace, address, zipcode, phoneNumber, alternativePhoneNumber,
        alternativeEmail, numberOfMembers, householdIncome, workAuthorization,
        fosterYouth, homeless, incarcerated, adversityList, education, educationStatus,
        referrerName, referrerEmail, referrerOrg
      }

      console.log('request submitted', education)
      this.submitRequest(null, this.state.orgSelected, signUpFields, false)
    }

    // submitSignUpFields() {
    //   console.log('submitSignUpFields called')
    //
    //   if (!this.state.dateOfBirth || this.state.dateOfBirth === '') {
    //     this.setState({ errorMessage: 'Please add your date of birth' })
    //   } else if (!this.state.gender || this.state.gender === '') {
    //     this.setState({ errorMessage: 'Please add your gender' })
    //   } else if (!this.state.race || this.state.race === '') {
    //     this.setState({ errorMessage: 'Please add your race' })
    //   } else if (!this.state.address || this.state.address === '') {
    //     this.setState({ errorMessage: 'Please add your address' })
    //   } else if (!this.state.phoneNumber || this.state.phoneNumber === '') {
    //     this.setState({ errorMessage: 'Please add your phone number' })
    //   } else if (!this.state.numberOfMembers || this.state.numberOfMembers === '') {
    //     this.setState({ errorMessage: 'Please add the number of members in your household' })
    //   } else if (!this.state.householdIncome || this.state.householdIncome === '') {
    //     this.setState({ errorMessage: 'Please add your household income' })
    //   } else if (!this.state.fosterYouth || this.state.fosterYouth === '') {
    //     this.setState({ errorMessage: 'Please indicate whether you have been a foster youth' })
    //   } else if (!this.state.homeless || this.state.homeless === '') {
    //     this.setState({ errorMessage: 'Please indicate whether you have been homeless' })
    //   } else if (!this.state.incarcerated || this.state.incarcerated === '') {
    //     this.setState({ errorMessage: 'Please indicate whether you have been incarcerated' })
    //   } else if (!this.state.adversityList || this.state.adversityList === '') {
    //     this.setState({ errorMessage: 'Please add all that applies' })
    //   } else {
    //
    //     const dateOfBirth = this.state.dateOfBirth
    //     const gender = this.state.gender
    //     const race = this.state.race
    //     const address = this.state.address
    //     const phoneNumber = this.state.phoneNumber
    //     const numberOfMembers = this.state.numberOfMembers
    //     const householdIncome = this.state.householdIncome
    //     const fosterYouth = this.state.fosterYouth
    //     const homeless = this.state.homeless
    //     const incarcerated = this.state.incarcerated
    //     const adversityList = this.state.adversityList
    //
    //     const signUpFields = {
    //       dateOfBirth, gender, race, address, phoneNumber, numberOfMembers, householdIncome,
    //       fosterYouth, homeless, incarcerated, adversityList
    //     }
    //
    //     this.submitRequest(null, this.state.orgSelected, signUpFields, false)
    //
    //   }
    // }

    async submitRequest(e, value, passedSignUpFields, fromButton) {
      console.log('submitRequest called')

      if (fromButton) {
        e.stopPropagation()
        e.preventDefault()
      }

      let cta = 'Join Workspace'
      if (!value.isOpen) {
        cta = 'Request Access'
      }

      if (window.location.pathname.includes('/employers')) {
        const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields, this.state.accountCode, this.state.employerName, this.state.sharePartners)
        // console.log('returnedValue: ', returnedValue)
        if (returnedValue.success) {
          let sharePartners = this.state.sharePartners
          if (returnedValue.success) {
            if (sharePartners) {
              sharePartners.push(value.orgCode)
            } else {
              sharePartners = [value.orgCode]
            }
          }

          // localStorage.setItem('activeOrg', value.orgCode)
          localStorage.setItem('sharePartners', JSON.stringify(sharePartners))

          this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, sharePartners })
          this.closeModal()

        } else {
          let errorMessage = ''
          if (returnedValue) {
            errorMessage = returnedValue.errorMessage
          }
          this.setState({ errorMessage: 'There was an error: ' + errorMessage })
        }
      } else if (cta === 'Join Workspace') {
        if (value.signUpFieldsRequired && value.signUpFieldsRequired.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let myOrgs = this.state.myOrgs
              if (returnedValue.success) {
                if (myOrgs) {
                  myOrgs.push(value.orgCode)
                } else {
                  myOrgs = [value.orgCode]
                }
              }
              console.log('show myOrgs on required: ', myOrgs)
              localStorage.setItem('activeOrg', value.orgCode)
              localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
              localStorage.setItem('orgName', value.orgName)
              localStorage.setItem('orgLogo', value.webLogoURIColor)

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs, modalIsOpen: false, showSignUpFields: false })
              this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)

              // orgFocus, orgName, orgLogo

              if (this.props.opportunityId) {
                this.props.navigate('/app/opportunities/' + this.props.opportunityId)
              }
            } else {
              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }
              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            // this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: true, showOrgDetails: false })
            this.openSignUpFieldsModal(value)
          }

        } else {
          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue on not required: ', returnedValue)
          if (returnedValue.success) {
            let myOrgs = this.state.myOrgs
            if (returnedValue.success) {
              if (myOrgs) {
                myOrgs.push(value.orgCode)
              } else {
                myOrgs = [value.orgCode]
              }
            }
            console.log('show myOrgs on not required: ', myOrgs)
            localStorage.setItem('activeOrg', value.orgCode)
            localStorage.setItem('myOrgs', JSON.stringify(myOrgs))
            localStorage.setItem('orgName', value.orgName)
            localStorage.setItem('orgLogo', value.webLogoURIColor)

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, myOrgs })
            this.props.passOrgs(value.orgCode, myOrgs, value.orgFocus, value.orgName, value.webLogoURIColor)

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }

      } else {
        console.log('request access')

        if (value.signUpFields && value.signUpFields.length > 0) {
          if (passedSignUpFields) {
            const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, passedSignUpFields)
            // console.log('returnedValue: ', returnedValue)
            if (returnedValue.success) {
              let joinRequests = this.state.joinRequests
              if (returnedValue.success) {
                if (joinRequests) {
                  joinRequests.push(value.orgCode)
                } else {
                  joinRequests = [value.orgCode]
                }
              }

              this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests, modalIsOpen: false, showSignUpFields: false })
            } else {
              let errorMessage = ''
              if (returnedValue) {
                errorMessage = returnedValue.errorMessage
              }
              this.setState({ errorMessage: 'There was an error: ' + errorMessage })
            }
          } else {
            this.setState({ modalIsOpen: true, orgSelected: value, showSignUpFields: false })
          }

        } else {

          const returnedValue = await requestAccessToWorkspace(this.state.emailId, value.orgCode, value.orgName, cta, value.contactFirstName, value.contactLastName, value.contactEmail, null)
          console.log('returnedValue: ', returnedValue)
          if (returnedValue.success) {
            let joinRequests = this.state.joinRequests
            if (returnedValue.success) {
              if (joinRequests) {
                joinRequests.push(value.orgCode)
              } else {
                joinRequests = [value.orgCode]
              }
            }

            this.setState({ errorMessage: returnedValue.errorMessage, successMessage: returnedValue.successMessage, joinRequests })

          } else {
            let errorMessage = ''
            if (returnedValue) {
              errorMessage = returnedValue.errorMessage
            }
            this.setState({ errorMessage: 'There was an error: ' + errorMessage })
          }
        }
      }
    }

    submitReview() {
      console.log('submitReview called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.ratingSelected || this.state.ratingSelected === '') {
        this.setState({ isSaving: false, errorMessage: 'Please select a rating'})
      } else {

        const orgCode = this.state.orgSelected.orgCode
        const orgName = this.state.orgSelected.orgName
        const orgContactEmail = this.state.orgSelected.contactEmail
        let pictureURL = this.state.pictureURL
        if (window.location.pathname.includes('/employers')) {
          pictureURL = this.state.employerLogo
        }
        const emailId = this.state.emailId
        const cuFirstName = this.state.cuFirstName
        const cuLastName = this.state.cuLastName
        const jobTitle = this.state.jobTitle
        const accountCode = this.state.accountCode
        const employerName = this.state.employerName
        let headline = null

        let fromCareerSeeker = true
        if (window.location.pathname.includes('/employers')) {
          fromCareerSeeker = false
        } else {
          console.log('we have education? ', this.state.education)
          if (this.state.education && this.state.education.length > 0 && this.state.education[0].name) {
            let edName = this.state.education[0].name
            let gradDate = ''
            if (this.state.education[0].endDate && !this.state.education[0].isContinual) {
              gradDate = " '" + this.state.education[0].endDate.split(" ")[1].substring(2,4)
            }
            headline = edName + gradDate
          }
        }

        const rating = this.state.ratingSelected
        const review = this.state.reviewSelected

        Axios.post('/api/org/review', { orgCode, orgName, orgContactEmail, pictureURL, emailId, cuFirstName, cuLastName, jobTitle, accountCode, employerName, headline, fromCareerSeeker, rating, review })
        .then((response) => {
          console.log('Org review query attempted')
          if (response.data.success) {
            //save values
            console.log('Org review save worked: ', response.data);

            this.setState({ isSaving: false, successMessage: 'Successfully submitted the review', showReviewPanel: false })

          } else {
            console.log('org review did not work', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message, ratingSelected: '', reviewSelected: '' })
          }
        }).catch((error) => {
            console.log('Org review did not work for some reason', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });

      }
    }

    renderSignUpFields() {
      console.log('renderSignUpFields called')

      let rows = []
      let spaceCounter = 0
      for (let i = 1; i <= this.state.orgSelected.signUpFieldsRequired.length; i++) {
        const value = this.state.orgSelected.signUpFieldsRequired[i - 1]
        const index = i - 1

        let showQuestion = true
        if (value.questionType !== 'Multiple Answer') {
          // console.log('in it: ', value.name, spaceCounter)
          if (value.rule) {
            if (value.rule.name === 'races' && this.state.races && this.state.races.includes(value.rule.value)) {
              spaceCounter = spaceCounter + 1
            } else {
              showQuestion = false
            }
          } else {
            spaceCounter = spaceCounter + 1
          }
        }

        rows.push(
          <div key={'renderSignUpFields' + index}>
            {(showQuestion) && (
              <div>
                <div className={(value.questionType === 'Multiple Answer') ? "full-width" : "container-left"}>
                  <div>
                    <label className="profile-label">{value.name}{(value.required) && <label className="error-color bold-text">*</label>}</label>
                    {(value.questionType === 'Date') && (
                      <input type="date" className="date-picker white-background" name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
                    )}
                    {(value.questionType === 'Short Answer') && (
                      <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
                    )}
                    {(value.questionType === 'Multiple Choice') && (
                      <select name={value.shorthand} className="dropdown" value={this.state[value.shorthand]} onChange={this.formChangeHandler}>
                          {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    )}
                    {(value.questionType === 'Multiple Answer') && (
                      <div>
                        {value.answerChoices.map((value2, optionIndex) =>
                          <div key={value2 + optionIndex}>
                            <div className="top-margin-5 right-padding">
                              {(this.state[value.shorthand] && this.state[value.shorthand].includes(value2)) ? (
                                <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value2}</p>
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value2}</p>
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}


                  </div>
                </div>
                {(value.questionType === 'Multiple Answer' || spaceCounter % 2 === 0) && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
                {(this.state.orgSelected.signUpFieldsRequired[i] && this.state.orgSelected.signUpFieldsRequired[i].questionType === 'Multiple Answer') && (
                  <div>
                    <div className="clear" />
                    <div className="spacer" />
                  </div>
                )}
              </div>
            )}
          </div>
        )
      }

      return rows
    }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let items = this.state[value.shorthand]
      if (items) {
        if (items.includes(value2)) {
          const index = items.indexOf(value2)
          items.splice(index, 1)
        } else {
          items.push(value2)
        }

      } else {
        items = [value2]
      }

      this.setState({ [value.shorthand]: items })

    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showSignUpFields: false, orgSelected: null })
    }

    openSignUpFieldsModal(orgSelected) {
      console.log('openSignUpFieldsModal called')

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried', response.data);

        if (response.data.success) {
          console.log('Work options query succeeded')

          const degreeOptions = response.data.workOptions[0].degreeOptions.slice(1,response.data.workOptions[0].degreeOptions.lengh)

          const educationStatusOptions = degreeOptions.concat(['Not currently enrolled in school'])

          let educationDateOptions = []

          const currentMonth = new Date().getMonth()
          const currentYear = new Date().getFullYear()

          let numberOfYears = 25
          let educationBump = 5
          let month = ''
          let year = currentYear - numberOfYears

          // console.log('show me current stuff', currentMonth, currentYear)
          for (let i = 1; i <= ((numberOfYears + educationBump) * 12); i++) {
            // console.log('show me stuff', i, (i + currentMonth + 1) % 12)
            if ((i + currentMonth + 1) % 12 === 2) {
              month = 'January'
            } else if ((i + currentMonth + 1) % 12 === 3) {
              month = 'February'
            } else if ((i + currentMonth + 1) % 12 === 4) {
              month = 'March'
            } else if ((i + currentMonth + 1) % 12 === 5) {
              month = 'April'
            } else if ((i + currentMonth + 1) % 12 === 6) {
              month = 'May'
            } else if ((i + currentMonth + 1) % 12 === 7) {
              month = 'June'
            } else if ((i + currentMonth + 1) % 12 === 8) {
              month = 'July'
            } else if ((i + currentMonth + 1) % 12 === 9) {
              month = 'August'
            } else if ((i + currentMonth + 1) % 12 === 10) {
              month = 'September'
            } else if ((i + currentMonth + 1) % 12 === 11) {
              month = 'October'
            } else if ((i + currentMonth + 1) % 12 === 0) {
              month = 'November'
            } else if ((i + currentMonth + 1) % 12 === 1) {
              month = 'December'
            }

            if (month === 'January') {
              year = year + 1
            }

            // dateOptions.push({ value: month + ' ' + year})
            if (i <= (numberOfYears * 12)) {
              // dateOptions.push({ value: month + ' ' + year})
            }
            educationDateOptions.push(month + ' ' + year)

          }

          const raceOptions = response.data.workOptions[0].raceOptions
          const genderOptions = response.data.workOptions[0].genderOptions

          const householdIncomeOptions = response.data.workOptions[0].lowIncomeOptions
          const adversityListOptions = response.data.workOptions[0].adversityListOptions
          const numberOfMembersOptions = response.data.workOptions[0].numberOfMembersOptions
          const workAuthorizationOptions = response.data.workOptions[0].workAuthorizationOptions
          // console.log('show educationDateOptions: ', educationDateOptions)
          const workOptions = {
            raceOptions, genderOptions, degreeOptions, householdIncomeOptions, adversityListOptions,
            numberOfMembersOptions, workAuthorizationOptions,
            educationStatusOptions, educationDateOptions
          }

          if (orgSelected.signUpFieldsRequired && orgSelected.signUpFieldsRequired.length > 0) {
            let signUpFieldsRequired = orgSelected.signUpFieldsRequired
            for (let i = 1; i <= signUpFieldsRequired.length; i++) {
              if (signUpFieldsRequired[i - 1].answerChoices && signUpFieldsRequired[i - 1].answerChoices.length === 1) {
                signUpFieldsRequired[i - 1].answerChoices = workOptions[signUpFieldsRequired[i - 1].answerChoices[0]]
              }
            }
          }

          this.setState({ modalIsOpen: true, orgSelected, showSignUpFields: true, showOrgDetails: false })

        } else {
          console.log('no workOptions found')

        }
      }).catch((error) => {
          console.log('query for work options did not work', error);
      })
    }

    render() {

        return (
            <div>
              {(this.state.orgSelected) && (
                <div className="width-70-percent center-horizontally max-width-1400 row-20">
                  <div className="card">
                    <div>
                      <div className="fixed-column-110">
                        <img src={(this.state.orgSelected.webLogoURIColor) ? this.state.orgSelected.webLogoURIColor : industryIconDark} alt="GC" className="image-80-fit" />
                      </div>
                      <div className="calc-column-offset-140">
                        <p className="heading-text-2">{this.state.orgSelected.orgName}</p>
                        <a href={this.state.orgSelected.orgURL} target="_blank" rel="noopener noreferrer">{this.state.orgSelected.orgURL}</a>
                      </div>
                      {(this.state.inModal) && (
                        <div className="fixed-column-30">
                          <button className="background-button" onClick={() => this.closeModal()}>
                            <img src={closeIcon} alt="GC" className="image-auto-15" />
                          </button>
                        </div>
                      )}

                      <div className="clear" />
                    </div>

                    {(!window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/pages')) && (
                      <div className="row-10">
                        {(this.state.orgSelected.isOpen || window.location.pathname.includes('/employers')) ? (
                          <div className="float-left bottom-margin-10">
                            {console.log('yeah?', this.state.sharePartners)}
                            {(window.location.pathname.includes('/employers')) ? (
                              <button className={(this.state.sharePartners && this.state.sharePartners.includes(this.state.orgSelected.orgCode)) ? "btn btn-squarish-white" : "btn btn-squarish"} disabled={(this.state.sharePartners && this.state.sharePartners.includes(this.state.orgSelected.orgCode)) ? true : false} onClick={(e) => this.submitRequest(e, this.state.orgSelected, null, true)}>
                                <div>
                                  <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                    <img src={(this.state.sharePartners && this.state.sharePartners.includes(this.state.orgSelected.orgCode)) ? checkmarkIcon : addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                  </div>
                                  <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                    {(this.state.sharePartners && this.state.sharePartners.includes(this.state.orgSelected.orgCode)) ? "Added" : "Add" }
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            ) : (
                              <button className={(this.state.myOrgs && this.state.myOrgs.includes(this.state.orgSelected.orgCode)) ? "btn btn-squarish-white" : "btn btn-squarish"} disabled={(this.state.myOrgs && this.state.myOrgs.includes(this.state.orgSelected.orgCode)) ? true : false} onClick={(e) => this.submitRequest(e, this.state.orgSelected, null, true)}>
                                <div>
                                  <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                    <img src={(this.state.myOrgs && this.state.myOrgs.includes(this.state.orgSelected.orgCode)) ? checkmarkIcon : addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                  </div>
                                  <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                    {(this.state.myOrgs && this.state.myOrgs.includes(this.state.orgSelected.orgCode)) ? "Joined" : "Join" }
                                  </div>
                                  <div className="clear" />
                                </div>
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="float-left bottom-margin-10">
                            <button className={(this.state.joinRequests && this.state.joinRequests.includes(this.state.orgSelected.orgCode)) ? "btn btn-squarish-white" : "btn btn-squarish"} disabled={(this.state.joinRequests && this.state.joinRequests.includes(this.state.orgSelected.orgCode)) ? true : false} onClick={(e) => this.submitRequest(e, this.state.orgSelected, null, true)}>
                              <div>
                                <div className={(window.innerWidth > 768) ? "float-left right-padding top-margin-5" : "fixed-column-30 right-padding top-margin-5 left-text"}>
                                  <img src={(this.state.joinRequests && this.state.joinRequests.includes(this.state.orgSelected.orgCode)) ? timeIconBlue : addIconWhite} alt="GC" className="image-auto-12 center-horizontally"/>
                                </div>
                                <div className={(window.innerWidth > 768) ? "float-left" : "calc-column-offset-30 left-text"}>
                                  {(this.state.joinRequests && this.state.joinRequests.includes(this.state.orgSelected.orgCode)) ? "Requested Access" : "Request Access" }
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        )}
                        {(!window.location.pathname.includes('/advisor')) && (
                          <div className="float-left left-padding bottom-margin-10">
                            <button className={(this.state.showReviewPanel) ? "btn btn-squarish error-color white-background error-border" : "btn btn-squarish error-background-color clear-border"} onClick={(this.state.showReviewPanel) ? () => this.setState({ showReviewPanel: false }) : () => this.setState({ showReviewPanel: true })}>{(this.state.showReviewPanel) ? "Close Review Panel" : "Give Review"}</button>
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    )}

                    {(this.state.showReviewPanel) ? (
                      <div>
                        <div className="error-border padding-20 medium-shadow top-margin-20">
                          <div className="row-10">
                            <div className="fixed-column-40">
                              <button className="background-button" onClick={() => this.setState({ showReviewPanel: false })}>
                                <img src={closeIcon} alt="GC" className="image-auto-12" />
                              </button>
                            </div>
                            <div className="calc-column-offset-80">
                              <p className="heading-text-4 full-width center-text">Add a Review</p>
                            </div>
                            <div className="fixed-column-40 height-40" />
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <p className="profile-label">Your Rating (5 is best) <label className="error-color bold-text">*</label></p>
                              <select name="ratingSelected" className="dropdown" value={this.state.ratingSelected} onChange={this.formChangeHandler}>
                                {['','1','2','3','4','5'].map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <p className="profile-label">Your Review</p>
                            <textarea type="text" className="text-field" placeholder="What do you think of them..." name="reviewSelected" value={this.state.reviewSelected} onChange={this.formChangeHandler}></textarea>
                          </div>

                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                          <div className="row-10">
                            <button className="btn btn-primary" onClick={() => this.submitReview()}>Submit</button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className="row-10">{this.state.orgSelected.orgDescription}</p>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                        <div className="row-10">
                          <div className="relative-column-20 standard-border padding-10">
                            <div className="full-width center-text height-75">
                              <div className="flex-container column-direction flex-center full-space">
                                {(window.location.pathname.includes('/employers') || window.location.pathname.includes('/organizations') || window.location.pathname.includes('/pages')) && (
                                  <div>
                                    <ul className="no-bullets flex-container row-direction">
                                      <li><img src={(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.avgRating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.avgRating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.avgRating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.avgRating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.avgRating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    </ul>

                                    <div className="flex-container">
                                      <p className="full-width description-text-3 center-text"><label className="bold-text">{(this.state.orgSelected.employerRating && this.state.orgSelected.employerRating.ratingCount) ? this.state.orgSelected.employerRating.ratingCount : 0}</label> employer ratings</p>
                                    </div>
                                  </div>
                                )}
                                {(window.location.pathname.includes('/app') || window.location.pathname.includes('/organizations') || window.location.pathname.includes('/pages')) && (
                                  <div>
                                    <ul className="no-bullets flex-container row-direction">
                                      <li><img src={(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.avgRating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.avgRating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.avgRating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.avgRating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      <li><img src={(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.avgRating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                    </ul>

                                    <div className="flex-container">
                                      <p className="full-width description-text-3 center-text"><label className="bold-text">{(this.state.orgSelected.careerSeekerRating && this.state.orgSelected.careerSeekerRating.ratingCount) ? this.state.orgSelected.careerSeekerRating.ratingCount : 0}</label> career-seeker ratings</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="relative-column-20 standard-border padding-10">
                            <div className="full-width center-text height-75">
                              <div className="flex-container column-direction flex-center full-space">
                                {(window.location.pathname.includes('/employers')) && (
                                  <div>
                                    <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.employerReviews && this.state.orgSelected.employerReviews.length > 0) ? this.state.orgSelected.employerReviews.length : '0'}</p>
                                    <p className="full-width description-text-3 center-text">employer reviews</p>
                                  </div>
                                )}
                                {(window.location.pathname.includes('/app')) && (
                                  <div>
                                    <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.careerSeekerReviews && this.state.orgSelected.careerSeekerReviews.length > 0) ? this.state.orgSelected.careerSeekerReviews.length : '0'}</p>
                                    <p className="full-width description-text-3 center-text">career-seeker reviews</p>
                                  </div>
                                )}
                                {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/pages')) && (
                                  <div>
                                    <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.careerSeekerReviews && this.state.orgSelected.employerReviews) ? (this.state.orgSelected.careerSeekerReviews.length + this.state.orgSelected.employerReviews.length) : '0'}</p>
                                    <p className="full-width description-text-3 center-text">reviews</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="relative-column-20 standard-border padding-10">
                            <div className="full-width center-text height-75">
                              <div className="flex-container column-direction flex-center full-space">
                                <div>
                                  <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.stats && this.state.orgSelected.stats.memberCount) ? this.state.orgSelected.stats.memberCount : 0}</p>
                                  <p className="full-width description-text-3 center-text">members</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative-column-20 standard-border padding-10">
                            <div className="full-width center-text height-75">
                              <div className="flex-container column-direction flex-center full-space">
                                <div>
                                  <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.stats && this.state.orgSelected.stats.ageRange) ? this.state.orgSelected.stats.ageRange : "N/A"}</p>
                                  <p className="full-width description-text-3 center-text">{(this.state.orgSelected.stats && this.state.orgSelected.stats.ageRangeInterval) ? this.state.orgSelected.stats.ageRangeInterval : "N/A"} fall within this age range</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative-column-20 standard-border padding-10">
                            <div className="full-width center-text height-75">
                              <div className="flex-container column-direction flex-center full-space">
                                <div>
                                  <p className="full-width heading-text-2 bold-text center-text">{(this.state.orgSelected.stats && this.state.orgSelected.stats.minorities) ? this.state.orgSelected.stats.minorities : "N/A"}</p>
                                  <p className="full-width description-text-3 center-text">are low-income and/or minorities</p>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="clear" />
                        </div>

                        {(window.location.pathname.includes('/employers') || window.location.pathname.includes('/organizations')) && (
                          <div>
                            {(this.state.orgSelected.employerReviews && this.state.orgSelected.employerReviews.length > 0) && (
                              <div className="row-10">
                                <p>Employer Reviews</p>
                                <div className="spacer" />
                                {this.state.orgSelected.employerReviews.map((value2, optionIndex) =>
                                  <div key={value2 + optionIndex} className="row-10">
                                    <div>
                                      <div className="fixed-column-60">
                                        <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-50" />
                                      </div>
                                      <div className="calc-column-offset-160">
                                        <p>{value2.firstName} {value2.lastName}</p>
                                        {(value2.jobTitle) ? (
                                          <p className="description-text-3">{value2.jobTitle} @ {value2.employerName}</p>
                                        ) : (
                                          <p className="description-text-3">{value2.employerName}</p>
                                        )}

                                      </div>
                                      <div className="fixed-column-100">
                                        <p className="description-text-3 full-width right-text">{convertDateToString(value2.updatedAt,"daysAgo")}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    <div className="row-10">
                                      <ul className="no-bullets flex-container row-direction">
                                        <li><img src={(value2.rating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      </ul>
                                    </div>

                                    <div>
                                      <p className="description-text-2">{value2.review}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {(window.location.pathname.includes('/app') || window.location.pathname.includes('/organizations') || window.location.pathname.includes('/pages')) &&  (
                          <div>
                            {(this.state.orgSelected.careerSeekerReviews && this.state.orgSelected.careerSeekerReviews.length > 0) && (
                              <div className="row-10">
                                <p>Career-Seeker Reviews</p>
                                <div className="spacer" />
                                {this.state.orgSelected.careerSeekerReviews.map((value2, optionIndex) =>
                                  <div key={value2 + optionIndex} className="row-10">
                                    <div>
                                      <div className="fixed-column-60">
                                        <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-50" />
                                      </div>
                                      <div className="calc-column-offset-160">
                                        <p>{value2.firstName} {value2.lastName}</p>
                                        {(value2.headline) && (
                                          <p className="description-text-3">{value2.headline}</p>
                                        )}

                                      </div>
                                      <div className="fixed-column-100">
                                        <p className="description-text-3 full-width right-text">{convertDateToString(value2.updatedAt,"daysAgo")}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>

                                    <div className="row-10">
                                      <ul className="no-bullets flex-container row-direction">
                                        <li><img src={(value2.rating >= 0.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 1.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 2.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 3.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                        <li><img src={(value2.rating >= 4.5) ? fullStar : emptyStar} alt="GC" className="image-auto-15 horizontal-margin-tiny"/></li>
                                      </ul>
                                    </div>

                                    <div>
                                      <p className="description-text-2">{value2.review}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                      </div>
                    )}
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color full-width center-text row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color full-width center-text row-5">{this.state.successMessage}</p>}

                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

               <div key="skillAssessment" className="full-width padding-20">
                {(this.state.showSignUpFields && this.state.orgSelected) && (
                  <div>
                    <p className="heading-text-2">Sign Up Fields for {this.state.orgSelected.orgName}</p>

                    <p className="row-10">{this.state.orgSelected.orgName} requires the following fields before joining their workspace</p>

                    {(this.props.opportunityId) && (
                      <p className="row-10">After you sign up to the {this.state.orgSelected.orgName} workspace, you will be able to access this opportunity.</p>
                    )}
                    <div className="spacer" />

                    {this.renderSignUpFields()}

                    <div className="clear" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color">{this.state.successMessage}</p>}

                    <div className="top-padding-20">
                      <button className="btn btn-primary" onClick={() => this.submitSignUpFields()}>Submit</button>
                    </div>
                  </div>
                )}

               </div>
             </Modal>

            </div>

        )
    }
}


export default withRouter(OrgDetails);
