import React, {Component} from 'react';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import SubApply from '../components/Subcomponents/Apply';
import withRouter from '../components/Functions/WithRouter';

class ApplyPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          postType: 'Problem',
          problemName: '',
          problemType: '',
          jobTitle: '',
          employerName: '',
          employerType: '',
          employerSize: '',
          difficultyLevel: '',
          industry: '',
          workFunctions: [],
          videoLink: '',
          summary: '',
          employerInfo: '',
          guidelines: '',
          solution: '',

          selectedProject: {},

          sections: [],
          checked: [],

          exhibits: [],
          eChecked: [],
          sectionTemplate: 'No',
          functions: [],

          favorites: [],

          difficultyOptions: ['','Very Easy','Easy','Medium','Hard','Challenger'],
          industryOptions: [],
          functionOptions: [],
          sectionTemplateOptions: [],
          employerSizeOptions: [],
          employerTypeOptions: [],
          problemTypeOptions: [],
          postTypeOptions: [],
          evaluationMethodOptions: [],
          prizeOptions: [],

          viewIndex: 0,
          subviewIndex: 0,
          selectedIndex: 0,

          comments: [],
          submissionComments: [],
          mySubmissionComments: [],

          clientErrorMessage: '',
          serverPostSuccess: true,
          serverSuccessMessage: '',
          serverErrorMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount(){
        console.log('problemPlatformPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        if (window.innerWidth > 768) {
        } else {
          //mobile
          this.setState({ onMobile: true })
        }

        if (this.props.location && this.props.location.state && this.props.location.state.selectedOpportunity) {
          //send this logged out user out of the app
          const { selectedOpportunity } = this.props.location.state;

          this.setState({ selectedOpportunity });
          this.retrieveData(selectedOpportunity)

        } else {
          console.log('went directly to the link')

          const { opportunityId } = this.props.params
          console.log('show item 2: ', this.props.params)
          Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
          .then((response) => {
             console.log('Postings by id query attempted', response.data);

             if (response.data.success) {
               console.log('successfully retrieved posting')

               const selectedOpportunity = response.data.posting
               this.setState({ selectedOpportunity })
               this.retrieveData(selectedOpportunity)

             } else {
               console.log('no posting data found', response.data.message)
             }

          }).catch((error) => {
               console.log('Posting query did not work', error);
          });

        }
    }

    retrieveData(selectedOpportunity) {
      console.log('retrieveData called in probDeets')

      const { orgCode } = this.props.params
      this.setState({ orgCode })

    }

    render() {


      return (
          <div>
            <TopNavigation currentPage={"ApplyPage"} darkBackground={true} activeOrg={this.state.orgCode}/>
            <div className="standard-container-2">
              <SubApply
                selectedPosting={this.state.selectedOpportunity} activeOrg={this.state.orgCode} pageSource="landingPage"
                path={this.state.path} history={this.props.navigate} passedTasks={this.state.passedTasks}
                customAssessmentResponses={this.state.customAssessmentResponses} caQuestions={this.state.caQuestions}
                application={this.state.application}
              />
            </div>

          </div>
      )
    }
}

export default withRouter(ApplyPage);
