import React, {Component} from 'react';
import Axios from 'axios';
import YouTube from 'react-youtube';
import Switch from 'react-switch';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';

const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const pathsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-dark.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';

class EditProgram extends Component {
    constructor(props) {
        super(props)

        this.state = {
          hideIcon: true,
          benchmarkOptions: [],
          employerOptions: [],
          locationOptions: [],
          hoursPerWeekOptions: [],
          players: [],
          selectedEmployer: { employerName: ''},
          selectedBenchmark: { title: ''},
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.addItem = this.addItem.bind(this)
        this.videoCallback = this.videoCallback.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.configureVideo = this.configureVideo.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.saveFile = this.saveFile.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ')

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.selectedTemplate || this.props.benchmarkId) {
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEditBenchmark', this.props)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode
      // const selectedTemplate = this.props.selectedTemplate

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let placementPartners = localStorage.getItem('placementPartners');

      this.setState({ emailId: email, username, cuFirstName, cuLastName, org, accountCode })

      if (org) {
        const self = this
        function unloadObject(selectedProgram) {
          console.log('unloadObject called')

          if (self.props.duplicate) {
            delete selectedProgram._id
            selectedProgram['orgCode'] = org
            selectedProgram['title'] = selectedProgram.title + " (Duplicate)"
          }

          const _id = selectedProgram._id
          const programIconURI = selectedProgram.imageURL
          const title = selectedProgram.title
          const description = selectedProgram.description
          const location = selectedProgram.location
          const zipcode = selectedProgram.zipcode
          const hoursPerWeek = selectedProgram.hoursPerWeek
          let selectedEmployer = self.state.selectedEmployer
          if (selectedProgram.selectedTrainer) {
            selectedEmployer = selectedProgram.selectedTrainer
          }
          const benchmarkId = selectedProgram.benchmarkId
          const cohorts = selectedProgram.cohorts
          const videos = selectedProgram.videos
          const isActive = selectedProgram.isActive

          self.setState({ _id, programIconURI, title, description,
            location, zipcode, hoursPerWeek, selectedEmployer,
            benchmarkId, videos, cohorts, isActive
          })

          if (benchmarkId) {
            Axios.get('/api/benchmarks/byid', { params: { _id: benchmarkId } })
            .then((response) => {
              console.log('Benchmark query attempted', response.data);

                if (response.data.success) {
                  console.log('benchmark query worked')

                  const selectedBenchmark = response.data.benchmark
                  self.setState({ selectedBenchmark })

                } else {
                  console.log('benchmark query did not work', response.data.message)

                }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);

            });
          }
        }

        if (this.props.programId) {
          // console.log('in programId: ', this.props.programId)
          Axios.get('/api/programs/byid', { params: { _id: this.props.programId } })
          .then((response) => {
            console.log('Program query attempted', response.data);

              if (response.data.success) {
                console.log('program query worked')

                unloadObject(response.data.program)

              } else {
                console.log('program query did not work', response.data.message)

              }

          }).catch((error) => {
              console.log('Program query did not work for some reason', error);

          });
        } else if (this.props.selectedTemplate) {
          console.log('passed an existing program')
          unloadObject(this.props.selectedTemplate)
        }

        Axios.get('/api/benchmarks', { params: { orgCodes: [org, 'general'], accountCode } })
        .then((response) => {
          console.log('Benchmarks query attempted no 1', response.data);

          if (response.data.success) {
            console.log('benchmark query worked no 1')

            if (response.data.benchmarks.length !== 0) {

              let benchmarkOptions = response.data.benchmarks
              benchmarkOptions.unshift({ title: 'No Benchmark Added'})
              this.setState({ benchmarkOptions });
            }

          } else {
            console.log('benchmark query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Benchmark query did not work for some reason', error);
        });

        Axios.get('/api/account/partners', { params: { org, placementPartners } })
        .then((response) => {
          console.log('Posted employer query attempted!', response.data);

          if (response.data.success) {
            console.log('posted employer query worked')

            if (response.data.employers.length !== 0) {

              let employerOptions = response.data.employers
              employerOptions.sort(function(a, b){
                if(a.employerName < b.employerName) { return -1; }
                if(a.employerName > b.employerName) { return 1; }
                return 0;
              })

              employerOptions.unshift({ employerName: ''})

              // let selectedEmployer = { employerName: ''}
              // if (selectedOpportunity) {
              //   if (selectedOpportunity.accountCode) {
              //     for (let i = 1; i <= employerOptions.length; i++) {
              //       console.log('did we get in here for sure: ', selectedOpportunity.accountCode)
              //       if (selectedOpportunity.accountCode === employerOptions[i - 1].accountCode) {
              //         console.log('testers', employerOptions[i - 1])
              //         selectedEmployer = employerOptions[i - 1]
              //       }
              //     }
              //   }
              // }
              // console.log('show accountCode: ', selectedEmployer, selectedOpportunity)
              this.setState({ employerOptions });
            }

          } else {
            console.log('query for employers query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Employer query did not work for some reason', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let locationOptions = response.data.workOptions[0].locationOptions
            let hoursPerWeekOptions = response.data.workOptions[0].hoursPerWeekOptions

            this.setState({ locationOptions, hoursPerWeekOptions })

          }
        });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name.includes('video')) {
        const index = Number(event.target.name.split("|")[1])
        let videos = this.state.videos
        videos[index] = event.target.value
        this.setState({ videos })
      } else if (event.target.name.includes('cohort')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let value = event.target.value
        if (name === 'startDate' || name === 'endDate') {
          value = event.target.value + "T12:00:00.980+00:00"
          console.log('value: ', value, new Date(value))
        }

        console.log('values: ', index, name)
        let cohorts = this.state.cohorts
        cohorts[index][name] = value
        this.setState({ cohorts })
      } else if (event.target.name === 'programIcon') {
        console.log('programIcon SelectedHandler changed')

        if (event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ programIconImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({ programIconFile: event.target.files[0], formHasChanged: true })
            // this.saveFile(event.target.name, event.target.files[0])
        }
      } else if (event.target.name.includes('selectedEmployer')) {
        let selectedEmployer = this.state.selectedEmployer
        if (this.state.employerOptions) {
          for (let i = 1; i <= this.state.employerOptions.length; i++) {
            // console.log('compare the options: ', this.state.benchmarkOptions[i - 1].title, this.state.selectedBenchmark)
            if (this.state.employerOptions[i - 1].employerName === event.target.value) {
              selectedEmployer = {
                id: this.state.employerOptions[i - 1]._id,
                accountCode: this.state.employerOptions[i - 1].accountCode,
                employerName: this.state.employerOptions[i - 1].employerName,
                name: this.state.employerOptions[i - 1].employerName,
                url: this.state.employerOptions[i - 1].employerURL,
                contactFirstName: this.state.employerOptions[i - 1].contactFirstName,
                contactLastName: this.state.employerOptions[i - 1].contactLastName,
                contactEmail: this.state.employerOptions[i - 1].contactEmail,
                id: this.state.employerOptions[i - 1]._id,
              }

            }
          }
        }
        this.setState({ selectedEmployer })
      } else if (event.target.name.includes('selectedBenchmark')) {
        let selectedBenchmark = this.state.selectedBenchmark
        if (this.state.benchmarkOptions) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            // console.log('compare the options: ', this.state.benchmarkOptions[i - 1].title, this.state.selectedBenchmark)
            if (this.state.benchmarkOptions[i - 1].title === event.target.value) {
              selectedBenchmark = this.state.benchmarkOptions[i - 1]
            }
          }
        }
        this.setState({ selectedBenchmark })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'video') {
        let videos = this.state.videos
        if (videos) {
          videos.push('')
        } else {
          videos = ['']
        }
        this.setState({ videos })
      } else if (type === 'cohort') {
        let cohorts = this.state.cohorts
        console.log('wtf 1: ', cohorts)
        if (cohorts) {
          cohorts.push({ number: cohorts.length + 1, startDate: '', endDate: '' })
        } else {
          cohorts = [{ number: 1, startDate: '', endDate: '' }]
        }
        console.log('wtf 2: ', cohorts)
        this.setState({ cohorts })
      }
    }

    videoCallback(event, callback) {
      console.log('videoCallback called', event, callback)

      if (callback === 'onReady') {
        const players = this.state.players;
        players.push(event.target);
        this.setState({ players });
        console.log('players: ', event.target.id)
      } else if (callback === 'onPlay') {
        this.state.players.forEach((player) => {
          if (player.id !== event.target.id) {
            player.pauseVideo();
          }
        });
      }
    }

    removeItem(type, index) {
      console.log('removeItem called', type, index)

      if (type === 'video') {
        let videos = this.state.videos
        videos.splice(index,1)
        this.setState({ videos })
      } else if (type === 'cohort') {
        let cohorts = this.state.cohorts
        cohorts.splice(index,1)
        this.setState({ cohorts })
      }
    }

    configureVideo(videoLink) {
      console.log('configureVideo called', videoLink)

      if (videoLink) {
        if (videoLink.includes("v=")) {
          videoLink = videoLink.split("v=")[1]
        } else if (videoLink.includes("/embed")) {
          videoLink = videoLink.split("/embed/")[1]
        }

        return videoLink
      }
    }

    saveChanges() {
      console.log('saveChanges called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })
      if (!this.state.title || this.state.title === '') {
        this.setState({ errorMessage: 'Please add a program title', isSaving: false })
      } else if (!this.state.description || this.state.description === '') {
        this.setState({ errorMessage: 'Please add a description', isSaving: false })
      } else if (!this.state.selectedBenchmark || this.state.selectedBenchmark.title === '' || this.state.selectedBenchmark.title === 'No Benchmark Added') {
        this.setState({ errorMessage: 'Please add a benchmark', isSaving: false })
      } else {
        // console.log('show me selectedBenchmark: ', this.state.selectedBenchmark)
        const _id = this.state._id
        const title = this.state.title
        const description = this.state.description
        let benchmarkId = null
        if (this.state.benchmarkOptions) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            if (this.state.benchmarkOptions[i - 1].title === this.state.selectedBenchmark.title) {
              benchmarkId = this.state.benchmarkOptions[i - 1]._id
            }
          }
        }

        const location = this.state.location
        const zipcode = this.state.zipcode
        const hoursPerWeek = this.state.hoursPerWeek
        const selectedTrainer = this.state.selectedEmployer
        // let employerContactFirstName = ''
        // let employerContactLastName = ""
        // let employerContactEmail = ""
        // if (selectedEmployer) {
        //   employerContactFirstName = selectedEmployer.contactFirstName
        //   employerContactLastName = selectedEmployer.contactLastName
        //   employerContactEmail = selectedEmployer.contactEmail
        // }

        const videos = this.state.videos
        const cohorts = this.state.cohorts
        const orgCode = this.state.org
        let isActive = this.state.isActive
        if (!isActive) {
          isActive = true
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        const saveObject = {
          _id, title, description, benchmarkId, videos, cohorts,
          location, hoursPerWeek, selectedTrainer, zipcode,
          // employerContactFirstName, employerContactLastName, employerContactEmail,
          orgCode, isActive,
          createdAt, updatedAt
        }

        Axios.post('/api/programs', saveObject)
        .then((response) => {
          console.log('Attempting to save program')
          if (response.data.success) {
            //save values
            console.log('Program save worked in individual');

            this.setState({ successMessage: 'Successfully saved', isSaving: false })

          } else {
            console.log('Program save did not work', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Program save did not work for some reason', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    deleteItem() {
      console.log('deletePost')

      const _id = this.state._id
      Axios.delete('/api/programs/' + _id)
      .then((response) => {
        console.log('tried to delete program', response.data)
        if (response.data.success) {
          //save values
          console.log('Program delete worked');

          this.props.navigate(-1)

        } else {
          console.error('there was an error deleting the program');
          this.setState({ errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ errorMessage: error.toString() })
      });
    }

    saveFile(category, passedFile, programId) {
      console.log('saveFile called', category, passedFile, programId)

      const fileName = passedFile.name
      let originalName = category + '|' + programId + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the logo response');
        if (res.ok) {

          const serverPostSuccess = true
          const serverSuccessMessage = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'programIcon') {
            this.setState({ serverPostSuccess, serverSuccessMessage, programIconFile: passedFile })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'programIcon') {
              existingFilePath = self.state.programIconPath
            }

            // remove existing file
            if (existingFilePath) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'programIcon') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', programIconPath: newFilePath
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverPostSuccess: false,
              serverErrorMessage: 'There was an error saving logo: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverPostSuccess: false,
            serverErrorMessage: 'There was an error saving logo:' + e
        })
      }.bind(this));
    }

    render() {

      return (
          <div>
              <div className={(window.location.pathname.includes('/walkthrough')) ? "" : "standard-container-2"}>
                <div>
                  <div className="spacer"/><div className="spacer"/>

                  <div>
                    <div className="calc-column-offset-50">
                      <p className="heading-text-2">{(this.state._id) ? "Edit Program" : "Create a Program"}</p>
                    </div>
                    <div className="fixed-column-50">
                      {(this.state.orgLogo && !window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                        <img src={this.state.orgLogo} alt="GC" className="image-auto-40" />
                      )}
                    </div>
                    <div className="clear" />
                    {(!window.location.pathname.includes('/organizations') && this.state.org !== 'guidedcompass') && (
                      <div>
                        {(this.state.orgName) && (
                          <p className="description-text-2">for {this.state.orgName}</p>
                        )}
                      </div>
                    )}
                  </div>

                  {(window.location.pathname.includes('/organizations')) ? (
                  <p className="profile-descriptor">Design and save programs for career-seekers.</p>
                  ) : (
                    <div className="row-15">
                      <p className="description-text-1">Design and save programs for career-seekers.</p>
                    </div>
                  )}

                  {(this.props.duplicate) && (
                    <div className="row-15">
                      <p className="description-text-2 error-color bold-text">This is a duplicated posting. Saving this will create a new posting.</p>
                    </div>
                  )}

                </div>

                {(!this.state.hideIcon) && (
                  <div className="row-15">
                    <div className="container-left">
                      <p className="profile-label">Add an icon for this program (Optional)</p>
                      <div className="relative-position">
                        <label for="programIcon" className="profile-pic-button">
                          <img src={ this.state.programIconImage ? ( this.state.programIconImage )
                            : this.state.programIconURI ? ( this.state.programIconURI )
                            : ( pathsIconDark)}
                          alt="GC" for="programIcon" className="logo-image-largish"/>
                          <div className="bottom-right-overlay">
                            <div className="bottom-right-subcontainer">
                              <img src={addIconBlue} alt="GC" className="image-auto-18"/>
                            </div>
                            <div className="clear" />
                          </div>
                        </label>
                        <input type="file" id="programIcon" name="programIcon" onChange={this.formChangeHandler} accept="image/*" />
                      </div>

                      <div className="clear" />

                      <div className="row-10 description-text-4 full-width">
                        <p>Preferred Dimensions: 150 x 150</p>
                      </div>

                      { (this.state.serverPostSuccess) ? (
                        <p className="success-message">{this.state.serverSuccessMessage}</p>
                      ) : (
                        <p className="error-message">{this.state.serverErrorMessage}</p>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                )}

                <div className="row-10">
                  <div className="row-15">
                    <div className="container-left">
                      <label className="profile-label">Program Name <label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Name your program..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <p className="profile-label">Description<label className="error-color">*</label></p>
                    <textarea className="text-field calc-height-half-view" type="text" placeholder="Description..." name={"description"} value={this.state.description} onChange={this.formChangeHandler} />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <p className="profile-label">Add a Training Provider <label className="error-color">*</label></p>
                      <select name="selectedEmployer" value={this.state.selectedEmployer.employerName} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.employerOptions.map(value =>
                          <option key={value.employerName} value={value.employerName}>{value.employerName}</option>
                        )}
                      </select>
                    </div>
                    <div className="container-right">
                      <p className="profile-label">Ideal Candidate Profile (Benchmark) <label className="error-color">*</label></p>
                      <select name="selectedBenchmark" value={this.state.selectedBenchmark.title} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.benchmarkOptions.map(value =>
                          <option key={value.title} value={value.title}>{value.title}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <label className="profile-label">Location<label className="error-color bold-text">*</label></label>
                      <select name="location" value={this.state.location} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.locationOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>

                    {(this.state.location === 'One Location') && (
                      <div>
                        <div className="container-right">
                          <label className="profile-label">Position Zip Code<label className="error-color bold-text">*</label></label>
                          <input type="number" className="number-field" max="99999" placeholder="90210" name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler}></input>
                        </div>
                      </div>
                    )}

                    {(this.state.location === 'One Location') ? (
                      <div>
                        <div className="clear" />
                        <div className="container-left row-10">
                          <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                          <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.hoursPerWeekOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="container-right">
                        <label className="profile-label">Hours per Week{(!this.state.disableSomeRequirements) && <label className="error-color bold-text">*</label>}</label>
                        <select name="hoursPerWeek" value={this.state.hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.hoursPerWeekOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                    )}
                    <div className="clear"/>
                  </div>

                  <div className="row-15">
                    <div className="float-left">
                      <p className="profile-label">Videos</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('video')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Day in the life of this profession/industry, interview tips, and relevant skills.</p>

                    <div className="spacer" />

                    {(this.state.videos && this.state.videos.length > 0) && (
                      <div>
                        {this.state.videos.map((value, optionIndex) =>
                          <div key={"video|" + optionIndex} className="top-margin bottom-margin">

                            <div className="top-margin">
                              <div className="fixed-column-30 top-padding">
                                <button className="background-button" onClick={() => this.removeItem('video', optionIndex)}>
                                  <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                </button>
                              </div>
                              <div className="calc-column-offset-30">
                                <input type="text" className="adjacent-text-field" placeholder="Http..." name={"video|" + optionIndex} value={value} onChange={this.formChangeHandler}></input>
                              </div>
                              <div className="clear" />
                            </div>

                            {(value && value !== '' && !value.startsWith('http')) && (
                              <div>
                                <p className="error-message">Please start your link with http</p>
                              </div>
                            )}

                            <div>
                              <div className="video-container-4">
                                <YouTube
                                  videoId={this.configureVideo(value)}
                                  id={value._id}
                                  className="video-iframe-4"
                                  containerClassName={""}
                                  opts={(e) => this.videoCallback(e,'opts')}
                                  onReady={(e) => this.videoCallback(e,'onReady')}
                                  onPlay={(e) => this.videoCallback(e,'onPlay')}
                                  onPause={(e) => this.videoCallback(e,'onPause')}
                                  onEnd={(e) => this.videoCallback(e,'onEnd')}
                                  onError={(e) => this.videoCallback(e,'onError')}
                                  onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                                  onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                                  onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                                />
                              </div>
                              <div className="clear"/>

                            </div>
                            <div className="clear"/>
                            <div className="super-spacer" /><div className="super-spacer" />
                            <div className="super-spacer" /><div className="super-spacer" />
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="row-15">
                    <div className="float-left">
                      <p className="profile-label">Cohorts</p>
                    </div>
                    <div className="float-left left-padding top-padding">
                      <button className="background-button" onClick={() => this.addItem('cohort')}>
                        <div className="circle-radius cta-border padding-5">
                          <img src={addIcon} alt="GC" className="image-auto-10" />
                        </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-1">Specify the cohorts of the program</p>

                    <div className="spacer" />

                    {(this.state.cohorts && this.state.cohorts.length > 0) && (
                      <div>
                        {this.state.cohorts.map((value, optionIndex) =>
                          <div key={"cohort|" + optionIndex} className="top-margin bottom-margin">
                            <div className="top-margin error-border padding-20">
                              <div className="">
                                <div className="calc-column-offset-30">
                                  <p>Cohort {optionIndex + 1}</p>
                                </div>
                                <div className="fixed-column-30">
                                  <button className="background-button" onClick={() => this.removeItem('cohort', optionIndex)}>
                                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="row-5 top-margin">
                                <div className="container-left">
                                  <label className="profile-label">Start Date <label className="error-color bold-text">*</label></label>
                                  <input type="date" className="date-picker white-background" name={"cohort|startDate|" + optionIndex} value={(value.startDate) ? convertDateToString(new Date(value.startDate),"rawDateForInput") : ""} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="container-right">
                                  <label className="profile-label">End Date <label className="error-color bold-text">*</label></label>
                                  <input type="date" className="date-picker white-background" name={"cohort|endDate|" + optionIndex} value={(value.endDate) ? convertDateToString(new Date(value.endDate),"rawDateForInput") : ""} onChange={this.formChangeHandler}></input>
                                </div>
                                <div className="clear" />
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    )}
                  </div>

                  <div className="row-15">
                    <div className="container-left">
                      <label className="profile-label clear-padding">Is Visible?</label>
                      <label className="profile-descriptor">Make this program {(this.state.isActive) ? "invisible" : "visible"} to career-seekers</label>
                      <div className="clear" />
                      <div className="spacer" /><div className="half-spacer"/>
                      <Switch
                        onChange={(change) => this.setState({ isActive: change, formHasChanged: true })}
                        checked={this.state.isActive}
                        id="normal-switch"
                      />
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="clear" />

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                  {(this.state.confirmDelete === true) ? (
                    <div className="row-15">
                      <div>
                        <p className="error-message">Are you sure you want to delete this program?</p>
                      </div>
                      <div className="clear" />
                      <div className="float-left right-padding-20">
                        <button className="btn btn-quaternary" onClick={() => this.deleteItem()}>Yes, Delete</button>
                      </div>
                      <div className="float-left right-padding-20">
                        <button className="btn btn-secondary" onClick={() => this.setState({ confirmDelete: false })}>Cancel</button>
                      </div>
                      <div className="clear" />
                    </div>
                  ) : (
                    <div className="row-15">
                      <div className="float-left right-padding-20">
                        <button className="btn btn-primary"  disabled={this.state.isSaving} onClick={() => this.saveChanges()}>{(this.state._id) ? "Save Changes" : "Create Program"}</button>
                      </div>
                      {(this.state._id) && (
                        <div className="float-left right-padding-20">
                          <button className="btn btn-quaternary"  disabled={this.state.isSaving} onClick={() => this.setState({ confirmDelete: true })}>Delete Program</button>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  )}

                </div>

              </div>

          </div>

      )
    }
}

export default withRouter(EditProgram);
