import React, {Component} from 'react';
import AppFooter from './AppFooter';
import SubWalkthrough from './Subcomponents/Walkthrough';
import withRouter from '../components/Functions/WithRouter';

class Walkthrough extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }
    }

    componentDidMount() {
      console.log('walkthrough has mounted')

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      let opportunityId = null
      let courseId = null
      if (this.props.location && this.props.location.state) {
        opportunityId = this.props.location.state.opportunityId
        courseId = this.props.location.state.courseId
      }

      this.setState({ emailId, username, activeOrg, orgLogo, opportunityId, courseId })

    }

    render() {

      return (
        <div>
          <SubWalkthrough  history={this.props.navigate} opportunityId={this.state.opportunityId} courseId={this.state.courseId}/>
          {(this.state.activeOrg) && (
            <div>
              {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
            </div>
          )}
        </div>
      )
    }
}

export default withRouter(Walkthrough);
