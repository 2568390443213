import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';;
import SubAssistantWidget from '../Common/AssistantWidget';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const industryIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/industry-icon-dark.png';
const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';
const favoriteIconSelected  = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const appliedIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/applied-icon-blue.png';
const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const benchmarksIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-dark.png';

class EmployerDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showV2App: false,
          subNavSelected: 'Home',
          subNavCategories: [],
          favorites: [],
          newFavorites: [],
          showSubFunction: true,
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.commandClicked = this.commandClicked.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      console.log('componentDidMount called in employerDetails')

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in employerDetails ', this.props, prevProps)

      if (prevProps.employerId !== this.props.employerId) {
        this.retrieveData()
      } else if (prevProps.accountCode !== this.props.accountCode){
        this.retrieveData()
      } else if (prevProps.subNavSelected !== this.props.subNavSelected){
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in employeerDetails', this.props)

      // benchmark caregories: product management
      // add resume and cover letter

      const employerId = this.props.employerId

      if (this.props.employerId || this.props.accountCode) {

        let email = localStorage.getItem('email');
        let username = localStorage.getItem('username');
        let cuFirstName = localStorage.getItem('firstName');
        let cuLastName = localStorage.getItem('lastName');
        let roleName = localStorage.getItem('roleName');
        let activeOrg = localStorage.getItem('activeOrg');

        let showV2App = false
        if (window.location.pathname.includes('/employers/pages/')) {
          showV2App = true

        }

        let subNavCategories = ['Home','Videos','About','Posts','Events','Projects','Work']
        if (showV2App) {
          subNavCategories = ['Home','Videos','About','Posts','Events','Projects','Work','Benchmarks']
        }

        let subNavSelected = this.state.subNavSelected
        if (this.props.subNavSelected) {
          subNavSelected = this.props.subNavSelected
        }

        this.setState({ emailId: email, username, cuFirstName, cuLastName, firstName: cuFirstName, lastName: cuLastName,
          roleName, activeOrg, accountCode: this.props.accountCode, subNavCategories, subNavSelected, showV2App
        })

        let accountQuery = { _id: employerId }
        if (this.props.accountCode) {
          accountQuery = { accountCode: this.props.accountCode }
        }
        console.log('show accountQuery: ', accountQuery)

        Axios.get('/api/account', { params: accountQuery })
        .then((response) => {
          console.log('Account info query attempted within employer details', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employer = response.data.accountInfo

            let employerCommands = [
              "What is the culture like at " + employer.employerName + "?",
              "What are the three most common job titles at " + employer.employerName + "?",
              "What are the departments at " + employer.employerName + "?",
              "What is a day in the life as an employee at " + employer.employerName + "?",
              "What are the positives and negatives about the CEO of " + employer.employerName + "?",
              "How many people are they projected to hire at " + employer.employerName + " over the next 3 years?",
              "What is the average pay of employees at " + employer.employerName + "? Is that increasing or decreasing?",
              "What are some common interview questions asked if you work at " + employer.employerName + "?",
              "What are the goals and key performance indicators at " + employer.employerName + "? How does " + employer.employerName + " expect employees to help achieve those metrics?",
              "What are other companies similar to " + employer.employerName + "? How does " + employer.employerName + " differentiate itself from those competitors? How has it performed compared to competitors?",
              "What problems is " + employer.employerName + " trying to solve for its customers?",
              "What is " + employer.employerName + "'s product and distribution strategy?",
              "What is a brief summary of " + employer.employerName + "'s income statement and balance sheet? What does this say about the financial health of the organization?",
              "What is impressive about the team at " + employer.employerName + "? And what does the current team say about who they like to hire?",
              "How difficult is it to land a job at " + employer.employerName + "?",
            ]

            this.setState({ employer, employerCommands })

            Axios.get('/api/get-followers', { params: { _id: employer._id } })
            .then((response) => {
              console.log('Followers query attempted', response.data);

              if (response.data.success) {
                console.log('followers query worked in sub settings')

                const followers = response.data.followers
                this.setState({ followers })
              }

            }).catch((error) => {
              console.log('Followers query did not work for some reason', error);
            });

            let orgCode = activeOrg
            if (this.props.pageSource === 'landingPage') {
              orgCode = null
            }
            const accountCode = employer.accountCode
            const recent = true
            const active = true

            Axios.get('/api/postings/user', { params: { accountCode, orgCode, recent, active } })
            .then((response) => {
              console.log('Sub postings query attempted now', response.data);

              if (response.data.success) {
                console.log('posted postings query worked')

                if (response.data.postings && response.data.postings.length !== 0) {

                  const postings = response.data.postings
                  let events = []
                  let projects = []
                  let work = []
                  for (let i = 1; i <= postings.length; i++) {
                    if (postings[i - 1].postType === 'Event') {
                      events.push(postings[i - 1])
                    } else if (postings[i - 1].postType === 'Individual' || postings[i - 1].postType === 'Internship' || postings[i - 1].postType === 'Work') {
                      work.push(postings[i - 1])
                    } else {
                      projects.push(postings[i - 1])
                    }
                  }

                  this.setState({ postings, events, projects, work });
                }

              } else {
                console.log('posted postings query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('Posted postings query did not work for some reason', error);
            });

            if (showV2App) {
              Axios.get('/api/benchmarks', { params: { accountCode, pathwayLevel: true } })
              .then((response) => {
                console.log('Benchmarks query within employerDetails attempted', response.data);

                if (response.data.success) {
                  console.log('benchmark query worked')

                  if (response.data.benchmarks.length !== 0) {
                    //jobs = response.data.postings
                    console.log('set the benchmark to state')

                    let benchmarks = response.data.benchmarks
                    this.setState({ benchmarks });
                  }

                }

              }).catch((error) => {
                  console.log('Benchmark query did not work for some reason', error);
              });
            }
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
           console.log('Favorites query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved favorites')

             const favorites = response.data.favorites

             let newFavorites = []
             if (response.data.newFavorites) {
               newFavorites = response.data.newFavorites
             }

             this.setState({ favorites, newFavorites })

           } else {
             console.log('no favorites data found', response.data.message)
           }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });

       Axios.get('/api/org', { params: { orgCode: activeOrg } })
       .then((response) => {
         console.log('Org info query attempted', response.data);

         if (response.data.success) {
           console.log('org info query worked in editLog')

           const disableChatbotPrompts = response.data.orgInfo.disableChatbotPrompts
           const showCompassAssistant = true
           this.setState({ disableChatbotPrompts, showCompassAssistant });

         } else {
           console.log('org info query did not work', response.data.message)
         }

       }).catch((error) => {
           console.log('Org info query did not work for some reason', error);
       });
      }
    }

    subNavClicked(subNavSelected) {
      console.log('subNavClicked called', subNavSelected)

      this.setState({ subNavSelected })
    }

    async favoriteItem(item) {
      console.log('favoriteItem called', item)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.showSubFunction) {

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,'employer')

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let itemId = item._id

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(itemId)){
          let index = favoritesArray.indexOf(itemId)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }
        } else {
          // console.log('adding item: ', favoritesArray, itemId)
          favoritesArray.push(itemId)
        }

        console.log('favorites', favoritesArray)
        this.setState({ favorites: favoritesArray })

        Axios.post('/api/favorites/save', {
          favoritesArray, emailId: this.state.emailId
        })
        .then((response) => {
          console.log('attempting to save favorites')
          if (response.data.success) {
            console.log('saved successfully', response.data)
            //clear values
            this.setState({ successMessage: 'Favorite saved!', isSaving: false })
          } else {
            console.log('did not save successfully')
            this.setState({ errorMessage: 'error:' + response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'there was an error saving favorites', isSaving: false })
        });
      }
    }

    closeModal() {
      console.log('closeModal in SubEmployerDetails')

      this.props.closeModal()

    }

    commandClicked(command) {
      console.log('commandClicked called')

      this.props.commandClicked(command)

    }

    render() {

      let linkPrefix = '/app'
      if (window.location.pathname.includes('/advisor')) {
        linkPrefix = '/advisor'
      } else if (window.location.pathname.includes('/organizations')) {
        linkPrefix = '/organizations/' + this.state.activeOrg
      }

      let containerClass = ""
      let mainClass = "card padding-40 max-width-1400 center-horizontally width-70-percent vertical-margin-30"
      let mainClassPadding = ""
      let mainClass2 = "standard-container-2 clear-padding clear-background"
      let mainClass3 = "card padding-40 max-width-1400 center-horizontally width-70-percent vertical-margin-30"
      if (window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && !this.props.inModal) {
        containerClass = "relative-column-70 padding-five-percent-left"
        mainClass = "card-clear-padding vertical-margin-30"
        mainClassPadding = "padding-30"
        mainClass2 = "top-margin-2-percent medium-shadow"
        mainClass3 = "card vertical-margin-30"
      } else if (this.props.inModal) {
        mainClass = ""
        mainClassPadding = ""
        mainClass2 = ""
        mainClass3 = "card top-margin bottom-margin"
      }

      return (
          <div>
            {(!this.state.employer) ? (
              <div className="card padding-40 max-width-1400 center-horizontally width-70-percent vertical-margin-30">
                <p>No employer found</p>
              </div>
            ) : (
              <div>
                <div className={containerClass}>
                  <div className={mainClass}>
                    {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts) && (
                      <div className="full-width height-5 primary-background" />
                    )}
                    <div className={mainClassPadding}>
                      <div>
                        <div className="fixed-column-90">
                          <img src={(this.state.employer.employerLogoURI) ? this.state.employer.employerLogoURI : industryIconDark} alt="GC" className="image-80-fit" />
                        </div>
                        <div className="calc-column-offset-90 left-padding top-padding-5">
                          <p className="heading-text-2">{this.state.employer.employerName}</p>

                          <div className="description-text-color heading-text-6 top-padding-5">
                            <label>{this.state.employer.employerIndustry}</label>
                            {(this.state.employer.employerLocation) && (
                              <span>
                                <label className="left-padding-5">|</label>
                                <label className="left-padding-5">{this.state.employer.employerLocation}</label>
                              </span>
                            )}
                            {(this.state.followers && this.state.followers.length > 0) && (
                              <span>
                                <label className="left-padding-5">|</label>
                                <label className="left-padding-5">{this.state.followers.length} Followers</label>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="top-padding-20">
                        {(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? (
                          <Link to="/join" className="background-button"><button className="btn btn-squarish">Follow</button></Link>
                        ) :(
                          <button className={(this.state.favorites.includes(this.state.employer._id)) ? "btn btn-squarish medium-background white-text clear-border" : "btn btn-squarish"} onClick={() => this.favoriteItem(this.state.employer)}>{(this.state.favorites.includes(this.state.employer._id)) ? "Following" : "+ Follow"}</button>
                        )}

                        {(this.state.employer.employerURL) && (
                          <span className="left-padding">
                            <a href={this.state.employer.employerURL} target="_blank" className="background-button"><button className="btn btn-squarish white-background cta-color cta-border">Visit website</button></a>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={mainClass2}>
                    <div className="full-width white-background">
                      <div className="clear-float">
                        <div className="carousel-3" onScroll={this.handleScroll}>
                          {this.state.subNavCategories.map((value, index) =>
                            <div className="carousel-item-container">
                              {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                                <div className="selected-carousel-item">
                                  <label key={value}>{value}</label>
                                </div>
                              ) : (
                                <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                                  <label key={value}>{value}</label>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Videos') && (
                    <div className={mainClass3}>

                      <div className="full-width">
                        <p className="heading-text-3">Videos</p>
                        <p className="description-text-1">Watch a day in the life at this company, get interview tips, and learn relevant skills.</p>
                      </div>

                      <div className="spacer" />

                      {(this.state.employer.videos && this.state.employer.videos.length > 0) ? (
                        <div>
                          {this.state.employer.videos.map((value, index) =>
                            <div key={value}>
                              <div>
                                <div className="top-margin-20">
                                  <div className={(index % 2 === 0) ? "container-left" : "container-right"}>
                                    <div className="video-container-4">
                                      <iframe
                                        title="videoLink"
                                        className="video-iframe"
                                        src={value}
                                        frameBorder="0"
                                      />
                                    </div>
                                    <div className="clear"/>
                                  </div>

                                  {(index % 2 === 1) && (
                                    <div className="clear" />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      ) : (
                        <div>
                          <p className="description-text-color">No Videos Yet</p>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'About') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">About</p>

                      <div className="spacer" />

                      {(this.state.employer.description) && (
                        <p className="description-text-color row-10">{this.state.employer.description}</p>
                      )}

                      {(this.state.employer.employerCulture) && (
                        <p className="description-text-color">{this.state.employer.employerCulture}</p>
                      )}

                      {(this.state.employer.employerType) && (
                        <p className="row-10"><label className="bold-text">Type:</label> {this.state.employer.employerType}</p>
                      )}

                      {(this.state.employer.employeeCount) && (
                        <p className="row-10"><label className="bold-text">Employees:</label> {this.state.employer.employeeCount}</p>
                      )}

                      {(this.state.employer.employeeGrowth) && (
                        <p className="row-10"><label className="bold-text">Growth:</label> {this.state.employer.employeeGrowth}</p>
                      )}

                      {(this.state.employer.employerLocation) && (
                        <p className="row-10"><label className="bold-text">Location:</label> {this.state.employer.employerLocation}</p>
                      )}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Posts') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">Posts</p>

                      <div className="spacer" />

                      <p className="description-text-color">No Posts Yet</p>
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Events') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">Events</p>

                      <div className="spacer" />

                      {(!this.state.events || this.state.events.length === 0) ? (
                        <p className="description-text-color">No active events available</p>
                      ) : (
                        <div>
                          {this.state.events.map((item, index) =>
                            <div key={item}>
                              <div className="spacer" />
                              <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                <div className="fixed-column-70">
                                  {(item.matchScore) ? (
                                    <div className="padding-10">
                                      <CircularProgressbar
                                        value={item.matchScore}
                                        text={`${item.matchScore}%`}
                                        styles={{
                                          path: { stroke: `${getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${item.matchScore / 100}` },
                                          text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '26px' },
                                          trail: { stroke: 'transparent' }
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img src={eventIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                  )}
                                  {(item.createdAt) && (
                                    <div className="top-padding horizontal-padding-7">
                                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="calc-column-offset-150">
                                  <p className="heading-text-5">{item.title}</p>
                                  <p className="description-text-1 standard-color">{item.employerName}</p>

                                  {(item.eventType) && (
                                    <p className="description-text-2 standard-color">Event Type: {item.eventType}</p>
                                  )}

                                  {(item.submissionDeadline) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.submissionDeadline,"date")}</label>
                                    </span>
                                  )}
                                  {(item.startDate) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.startDate,"date")}</label>
                                    </span>
                                  )}

                                  {(item.location) && (
                                    <p className="description-text-2 standard-color">Location: {item.location}</p>
                                  )}

                                </div>
                              </Link>
                              <div className="float-left left-padding">
                                <span className="float-right">
                                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                                  <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </Link>
                                </span>
                                {(this.state.path && this.state.path.includes('/app')) && (
                                  <span className="float-right right-padding-15">
                                    {(this.state.applications && this.state.applications.some(app => app.postingId === item._id)) && (
                                      <div className="top-margin">
                                        <img src={appliedIconBlue} alt="Compass arrow indicator icon" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already applied!"/>
                                      </div>
                                    )}
                                    <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                      <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                    </button>
                                  </span>
                                )}
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Projects') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">Project Opportunities</p>

                      <div className="spacer" />

                      {(!this.state.projects || this.state.projects.length === 0) ? (
                        <p className="description-text-color">No active project opportunities available</p>
                      ) : (
                        <div>
                          {this.state.projects.map((item, index) =>
                            <div key={item}>
                              <div className="spacer" />
                              <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                <div className="fixed-column-70">
                                  {(item.matchScore) ? (
                                    <div className="padding-10">
                                      <CircularProgressbar
                                        value={item.matchScore}
                                        text={`${item.matchScore}%`}
                                        styles={{
                                          path: { stroke: `${getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${item.matchScore / 100}` },
                                          text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '26px' },
                                          trail: { stroke: 'transparent' }
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img src={projectsIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                  )}
                                  {(item.createdAt) && (
                                    <div className="top-padding horizontal-padding-7">
                                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="calc-column-offset-150">
                                  <p className="heading-text-5">{item.name}</p>
                                  <p className="description-text-1 standard-color">{item.employerName}</p>

                                  {(item.industry) && (
                                    <label className="description-text-2 standard-color">{item.industry}</label>
                                  )}
                                  {(item.submissionDeadline) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.submissionDeadline,"date")}</label>
                                    </span>
                                  )}
                                  {(item.startDate) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.startDate,"date")}</label>
                                    </span>
                                  )}
                                </div>
                              </Link>
                              <div className="float-left left-padding">
                                <span className="float-right">
                                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                                  <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </Link>
                                </span>
                                {(this.state.path && this.state.path.includes('/app')) && (
                                  <span className="float-right right-padding-15">
                                    {(this.state.applications && this.state.applications.some(app => app.postingId === item._id)) && (
                                      <div className="top-margin">
                                        <img src={appliedIconBlue} alt="Compass arrow indicator icon" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already applied!"/>
                                      </div>
                                    )}
                                    <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                      <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                    </button>
                                  </span>
                                )}
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Work') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">Work Opportunities</p>

                      <div className="spacer" />

                      {(!this.state.work || this.state.work.length === 0) ? (
                        <p className="description-text-color">No active work opportunities available</p>
                      ) : (
                        <div>
                          {this.state.work.map((item, index) =>
                            <div key={item}>

                              <div className="spacer" />
                              <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                <div className="fixed-column-70">
                                  {(item.matchScore) ? (
                                    <div className="padding-10">
                                      <CircularProgressbar
                                        value={item.matchScore}
                                        text={`${item.matchScore}%`}
                                        styles={{
                                          path: { stroke: `${getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${item.matchScore / 100}` },
                                          text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '26px' },
                                          trail: { stroke: 'transparent' }
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img src={opportunitiesIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                  )}
                                  {(item.createdAt) && (
                                    <div className="top-padding horizontal-padding-7">
                                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="calc-column-offset-150">
                                  <p className="heading-text-5">{item.title}</p>
                                  <p className="description-text-1 standard-color">{item.employerName}</p>

                                  {(item.field) && (
                                    <label className="description-text-2 standard-color">{item.field.split("|")[0].trim()}</label>
                                  )}
                                  {(item.submissionDeadline) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.submissionDeadline,"date")}</label>
                                    </span>
                                  )}
                                  {(item.startDate) && (
                                    <span>
                                      <label className="description-text-2 standard-color left-padding">|</label>
                                      <label className="description-text-2 standard-color left-padding">{convertDateToString(item.startDate,"date")}</label>
                                    </span>
                                  )}

                                  {(item.payRange && (item.subPostType === 'Full-Time' || item.subPostType === 'Part-Time')) && (
                                    <p className="description-text-3 cta-color bold-text top-padding-5">{item.payRange}</p>
                                  )}

                                </div>
                              </Link>
                              <div className="float-left left-padding">
                                <span className="float-right">
                                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                                  <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/opportunities/organizations/guidedcompass/' + item._id : linkPrefix + '/opportunities/' + item._id} state={{ selectedOpportunity: item, selectedPosting: item, postings: this.state.work, source: 'Student' }} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </Link>
                                </span>
                                {(this.state.path && this.state.path.includes('/app')) && (
                                  <span className="float-right right-padding-15">
                                    {(this.state.applications && this.state.applications.some(app => app.postingId === item._id)) && (
                                      <div className="top-margin">
                                        <img src={appliedIconBlue} alt="Compass arrow indicator icon" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You've already applied!"/>
                                      </div>
                                    )}
                                    <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                      <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                    </button>
                                  </span>
                                )}
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.subNavSelected === 'Home' || this.state.subNavSelected === 'Benchmarks') && (
                    <div className={mainClass3}>
                      <p className="heading-text-3">Benchmarks</p>

                      <div className="spacer" />

                      {(!this.state.benchmarks || this.state.benchmarks.length === 0) ? (
                        <p className="description-text-color">No benchmarks available</p>
                      ) : (
                        <div>
                          {this.state.benchmarks.map((item, index) =>
                            <div key={item}>
                              <div className="spacer" />
                              <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/employers/pages/' + this.state.accountCode + '/benchmarks/' + item._id : linkPrefix + '/employers/' + this.state.accountCode + '/benchmarks/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                <div className="fixed-column-70">
                                  <img src={benchmarksIconDark} alt="Compass arrow indicator icon" className="image-auto-50 center-item"/>
                                  {(item.createdAt) && (
                                    <div className="top-padding horizontal-padding-7">
                                      <p className="description-text-4 description-text-color bold-text full-width center-text">{convertDateToString(item.createdAt,"daysAgo")}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="calc-column-offset-150">
                                  <p className="heading-text-5">{item.title}</p>
                                  <p className="description-text-1 standard-color">{item.jobFunction} | {item.jobType}</p>

                                  {(item.maxPay) && (
                                    <p className="description-text-3 cta-color bold-text top-padding-5">{item.maxPay}</p>
                                  )}

                                </div>
                              </Link>
                              <div className="float-left left-padding">
                                <span className="float-right">
                                  <div className="spacer"/><div className="half-spacer"/><div className="half-spacer"/>
                                  <Link to={(window.location.pathname === '/employers/pages/' + this.state.accountCode) ? '/employers/pages/' + this.state.accountCode + '/benchmarks/' + item._id : linkPrefix + '/employers/' + this.state.accountCode + '/benchmarks/' + item._id} state={{ selectedBenchmark: item }} className="background-link">
                                    <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                  </Link>
                                </span>
                                {(this.state.path && this.state.path.includes('/app')) && (
                                  <span className="float-right right-padding-15">
                                    <button className="btn background-button" onClick={() => this.favoriteItem(item) }>
                                      <img src={(this.state.favorites.includes(item._id)) ? favoriteIconSelected : favoritesIconDark} alt="Compass arrow indicator icon" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Favorite"/>
                                    </button>
                                  </span>
                                )}
                              </div>
                              <div className="clear"/>

                              <div className="spacer" /><div className="spacer" />
                              <hr />
                              <div className="clear"/>
                              <div className="spacer" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {(window.innerWidth > 768 && this.state.showCompassAssistant && !this.state.disableChatbotPrompts && !this.props.inModal) && (
                  <div className="relative-column-30 horizontal-padding top-margin-30">
                    <div className="left-padding-20">
                      {(this.state.showCompassAssistant && !this.state.disableChatbotPrompts && this.state.employerCommands && this.state.employerCommands.length > 0) && (
                        <div>
                          <SubAssistantWidget commands={this.state.employerCommands} commandClicked={this.commandClicked} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(EmployerDetails);
