import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import EditMembers from '../Subcomponents/EditMembers';
import withRouter from '../Functions/WithRouter';

class OrgEditEducator extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let userId = undefined
        if (id && id !== 'add') {
          userId = id
        }

        this.setState({ emailId: email, username, cuFirstName, cuLastName,
          org, orgLogo, userId
        });
      }
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>
            <EditMembers activeOrg={this.state.org} roleName="Admin" path={this.state.path} type="Educators" userId={this.state.userId} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgEditEducator)
