import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import withRouter from '../Functions/WithRouter';

// const helpfulFounder = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/helpful-founder.png';
const priceChart = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/price_chart.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';

class ContactPage extends Component {
    constructor(props) {
        super(props)

        this.state = {

          reason: 'General Query',
          firstName: '',
          lastName: '',
          email: '',
          orgName: '',
          body: '',
          isReferringSchool: false,
          schoolName: '',
          employeeName: '',
          employeeEmail: '',

          reasonOptions: [],
          roleNameOptions: ['','Student / Career-Seeker','Educator','Counselor','Work-Based Learning Coordinator','Workforce Development Professional','Mentor','Employer Representative','Other'],

          serverSuccess: false,
          serverSuccessMessage: '',
          serverErrorMessage: '',

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.reason !== prevProps.reason) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subContact')

      const reason = this.props.reason
      const reasonOptions = this.props.reasonOptions
      const body = this.props.body

      const email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let orgName = localStorage.getItem('orgName');
      let roleName = localStorage.getItem('roleName');
      let employerName = localStorage.getItem('employerName');
      if (window.location.pathname.includes('/employers/')) {
        orgName = employerName
      }

      if (this.props.roleName) {
        roleName = this.props.roleName
      }

      if (roleName) {
        if (roleName === 'Student') {
          roleName = 'Student / Career-Seeker'
        } else if (roleName === 'Teacher') {
          roleName = 'Educator'
        } else if (roleName === 'Employer') {
          roleName = 'Employer Representative'
        }
      }

      if (reason !== '') {
        this.setState({ reason, reasonOptions, body, email, firstName: cuFirstName, lastName: cuLastName, username, roleName, orgName })
      } else {
        this.setState({ reasonOptions, email, body, firstName: cuFirstName, lastName: cuLastName, username, roleName, orgName })
      }
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "#fff";
    }

    formChangeHandler = event => {

        if (event.target.name === 'firstName') {
          this.setState({ firstName: event.target.value })
        } else if (event.target.name === 'lastName') {
          this.setState({ lastName: event.target.value })
        } else if (event.target.name === 'email') {
          this.setState({ email: event.target.value })
        } else if (event.target.name === 'reason') {
          this.setState({ reason: event.target.value })
        } else if (event.target.name === 'orgName') {
          this.setState({ orgName: event.target.value })
        } else if (event.target.name === 'body') {
          this.setState({ body: event.target.value })
        } else if (event.target.name === 'referringSchool') {
          const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
          this.setState({ isReferringSchool: value });
        } else if (event.target.name === 'schoolName') {
          this.setState({ schoolName: event.target.value })
        } else if (event.target.name === 'employeeName') {
          this.setState({ employeeName: event.target.value })
        } else if (event.target.name === 'employeeEmail') {
          this.setState({ employeeEmail: event.target.value })
        } else if (event.target.name === 'roleName') {
          this.setState({ roleName: event.target.value })
        } else if (event.target.name === 'openingTitle') {
          this.setState({ openingTitle: event.target.value })
        } else if (event.target.name === 'region') {
          this.setState({ region: event.target.value })
        } else {
          this.setState({ [event.target.name]: event.target.value })
        }
    }

    handleSubmit(event) {
        console.log('handleSubmit called')

        this.setState({
          serverSuccess: false,
          serverErrorMessage: '',
          serverSuccessMessage: '',
        })

        const reason = this.state.reason
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const email = this.state.email
        let orgName = ''
        if (this.state.orgName !== '') {
          orgName = this.state.orgName
        }
        const roleName = this.state.roleName
        const openingTitle = this.state.openingTitle
        const region = this.state.region

        let body = ''
        if (this.state.body !== '') {
          body = this.state.body
        }
        const isReferringSchool = this.state.isReferringSchool
        const numberOfLicenses = this.state.numberOfLicenses

        let createdAt = new Date();
        let updatedAt = new Date();

        if (this.state.reason === '') {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please enter a reason for your message'})
        } else if (this.state.email === '') {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please enter your email'})
        } else if (!this.state.email.includes('@')) {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please enter a valid email'})
        } else if (this.state.firstName === '') {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please enter your first name'})
        } else if (this.state.lastName === '') {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please enter your last name'})
        } else if (!this.state.roleName || this.state.roleName === '') {
          this.setState({ serverSuccess: false, serverErrorMessage: 'please select a role name that best desribes you'})
        } else {
          console.log('used has changed the plan form!!!')

          Axios.post('/api/inquiry', {
            firstName, lastName, email, roleName, body, reason, orgName,
            openingTitle, region, isReferringSchool, numberOfLicenses,
            createdAt, updatedAt })
          .then((response) => {

            if (response.data.success) {
              this.setState({

                firstName: '',
                lastName: '',
                email: '',
                orgName: '',
                roleName: '',
                body: '',
                numberOfLicenses: 0,

                serverSuccess: true,
                serverSuccessMessage: 'Message sent successfully!'
              })
            } else {
              this.setState({
                serverSuccess: false,
                serverErrorMessage: 'error:' + response.data.message,
              })
            }
          }).catch((error) => {
            console.log('save did not work', error);
            this.setState({
              serverSuccess: false,
              serverErrorMessage: 'there was an error sending your message',
            })
          });
        }
    }

    closeModal() {
      console.log('closeModal claled')

      this.setState({ modalIsOpen: false, showPriceChart: false })
    }

    render() {
        return (

          <div>


            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">What Would You Like? <label className="error-color">*</label></label>
                <div>
                  {(this.state.reasonOptions) ? (
                    <select name="reason" className="dropdown" value={this.state.reason} onChange={this.formChangeHandler}>
                      {this.state.reasonOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  ) : (
                    <input type="text" className="text-field" name="reason" placeholder="Add your reason..." value={this.state.reason} onChange={this.formChangeHandler}/>
                  )}
                </div>
              </div>
              <div className="container-right">
                  <label className="profile-label">Your Email <label className="error-color">*</label></label>
                  <input type="text" className="text-field" name="email" placeholder="Add your email..." value={this.state.email} onChange={this.formChangeHandler}/>
              </div>

              <div className="clear"/>
            </div>

            <div className="edit-profile-row">
                <div className="container-left">
                    <label className="profile-label">First Name <label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                </div>
                <div className="container-right">
                    <label className="profile-label">Last Name <label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
            </div>

            {(this.state.reason !== 'I Forgot My Password') && (
              <div>
                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Which best describes you? <label className="error-color">*</label></label>
                    <select name="roleName" className="dropdown" value={this.state.roleName} onChange={this.formChangeHandler}>
                      {this.state.roleNameOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Organization Name</label>
                    <input type="text" className="text-field" name="orgName" placeholder="Add the name of your associated organization..." value={this.state.orgName} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>

                {(this.state.reason === 'Post a Work Opportunity') && (
                  <div>
                    <div className="edit-profile-row">
                      <div className="container-left">
                        <label className="profile-label">Job Title of Open Position</label>
                        <input type="text" className="text-field" name="openingTitle" placeholder="E.g Software Developer Intern..." value={this.state.openingTitle} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="container-right">
                        <label className="profile-label">City / Region of Open Position</label>
                        <input type="text" className="text-field" name="region" placeholder="If remote, type remote.." value={this.state.region} onChange={this.formChangeHandler}/>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.reason === 'Upgrade My Account') && (
                  <div>
                    <div className="row-10">
                      <div className="container-left">

                        <div>
                          <div className="calc-column-offset-40">
                            <label className="profile-label">How many licenses? <label className="error-color">*</label></label>
                          </div>
                          <div>
                            <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <div className="float-left left-margin noti-bubble-info-7-9">
                              <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showPriceChart: true })}>
                                <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                              </a>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>

                        <div>
                          <input type="number" className="number-field cta-color" name="numberOfLicenses" placeholder="0" value={this.state.numberOfLicenses} onChange={this.formChangeHandler}/>
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                <div className="edit-profile-row">
                    {(this.state.reason === "Guided Compass to Work with My Org / School") ? (
                      <label className="profile-label">How can Guided Compass help?</label>
                    ) : (
                      <label className="profile-label">Message</label>
                    )}

                    <textarea className="text-field" name="body" placeholder="Write your message here..." value={this.state.body} onChange={this.formChangeHandler}/>
                </div>
              </div>
            )}

            <br/>
            <button onClick={this.handleSubmit} className="btn btn-primary">Send</button>
            {(this.props.inModal) && (
              <button onClick={() => this.props.closeModal()} className="btn btn-secondary left-margin">Cancel</button>
            )}
            { (this.state.serverSuccess) ? (
              <p className="success-message">{this.state.serverSuccessMessage}</p>
            ) : (
              <p className="error-message">{this.state.serverErrorMessage}</p>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
            >
              <div key="showPriceChart" className="full-width padding-20">
                {(this.state.showPriceChart) && (
                  <div key="showPricingChart" className="full-width padding-20">
                    <p className="heading-text-2">Price Chart</p>
                    <div className="spacer" />

                    <div className="row-10">
                      <img src={priceChart} alt="GC" className="image-full-auto"/>
                    </div>

                    <div className="row-10">
                      <p>Members are defined here as career-seekers or students. Staff and employer representatives may be represented free of charge</p>
                    </div>

                    <div className="spacer" />

                    <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>

                  </div>
                )}
              </div>
            </Modal>
          </div>

        )
    }
}

export default withRouter(ContactPage);
