import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSendEndorsement from './Subcomponents/SendEndorsement';
import withRouter from './Functions/WithRouter';

class AdvSendEndorsement extends Component {
    constructor(props) {
      super(props)

      this.state = {

      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let endorsementId = null
      let requestId = null
      if (this.props.params && this.props.location.state) {
        endorsementId = this.props.params.endorsementId
        requestId = this.props.params.requestId
      }

      let selectedEndorsement = null
      let objectId = null
      let benchmark = null
      if (this.props.location && this.props.location.state) {
        selectedEndorsement = this.props.location.state.selectedEndorsement
        objectId = this.props.location.state.objectId
        benchmark = this.props.location.state.benchmark
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let orgCode = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ endorsementId, requestId, selectedEndorsement, objectId, benchmark, orgCode,
        activeOrg: orgCode, email, username, orgFocus, roleName, orgLogo
      })
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate}/>

              <div className="standard-container-2 clear-padding">
                <div className="row-20">
                  <SubSendEndorsement history={this.props.navigate} requestId={this.state.requestId} endorsementId={this.state.endorsementId} selectedEndorsement={this.state.selectedEndorsement} objectId={this.state.objectId} benchmark={this.state.benchmark} orgCode={this.state.orgCode}/>
                </div>
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(AdvSendEndorsement)
