import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubSideNav from './Common/SideNav';
import SubCreatePost from './Common/CreatePost';
import SubRenderPosts from './Common/RenderPosts';
import withRouter from './Functions/WithRouter';

const newsFeedIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/news-feed-icon-dark.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class EmpSocialPosts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            renderSideNav: false,

            templates: [],
        }

        this.closeModal = this.closeModal.bind(this)
        this.passPosts = this.passPosts.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      const { emp } = this.props.params

      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        let orgMode = true
        if (!activeOrg || activeOrg === '' || activeOrg === 'guidedcompass') {
          orgMode = false
        }

        this.setState({ emp, orgMode, activeOrg, emailId: email,
          cuFirstName, cuLastName, username, orgLogo })

        Axios.get('/api/account', { params: { accountCode: emp } })
        .then((response) => {
          console.log('Account info query attempted within sub settings', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const employerName = response.data.accountInfo.employerName
            const employerLogo = response.data.accountInfo.employerLogoURI
            const accountCode = response.data.accountInfo.accountCode

            this.setState({ employerName, employerLogo, accountCode });
          }

        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });

        Axios.get('/api/group-posts', { params: { groupId: null, accountCode: emp } })
        .then((response) => {
           console.log('Group posts query attempted in newsfeed', response.data);

           if (response.data.success) {
             console.log('successfully retrieved group posts in newsfeed')

             if (response.data.groupPosts) {
               let socialPosts = response.data.groupPosts
               this.setState({ socialPosts })
             }

           }
        }).catch((error) => {
            console.log('Group posts query did not work', error);
        });

        Axios.get('/api/users/profile/details/' + email, { params: { emailId: email } })
         .then((response) => {
           console.log('query for profile data worked in newsfeed');

           if (response.data.success) {

             console.log('profile data received', response.data)

             const pictureURL = response.data.user.pictureURL
             const jobTitle = response.data.user.jobTitle
             const location = response.data.user.location

             this.setState({ pictureURL, jobTitle, location })


           } else {
             console.log('error response', response.data)
           }

       }).catch((error) => {
           console.log('query for profile info did not work', error);
       })
      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    passPosts(socialPosts) {
      console.log('passPosts called', socialPosts)

      this.setState({ socialPosts })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showPost: false })
    }

    loadWorkspace(org) {
      console.log('loadWorkspace called', org)
      this.setState({ org })
    }

    render() {
      return (
        <div>
            <AppNavigation fromEmployer={true} emp={this.state.emp} orgMode={this.state.orgMode} org={this.state.activeOrg} history={this.props.navigate} loadWorkspace={this.loadWorkspace} />

            {(this.state.renderSideNav) && (
              <SubSideNav selectedComponent={"EmpSocialPosts"} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} accountCode={this.state.emp} workMode={this.state.workMode} finishedQuery={this.state.finishedQuery} />
            )}

            {(this.state.socialPosts && this.state.socialPosts.length > 0) ? (
              <div>
                <div className="center-horizontally width-70-percent max-width-1400 vertical-margin-2-percent">
                  <div className="row-20">
                    <div className="calc-column-offset-140">
                      <p className="heading-text-2">Your Social Posts</p>
                    </div>
                    <div className="fixed-column-140">
                      <button className="btn btn-primary" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>+ Create</button>
                    </div>
                    <div className="spacer" />

                  </div>

                  <div className="row-20">
                    <SubRenderPosts posts={this.state.socialPosts} passedGroupPost={null} accountCode={this.state.accountCode} employerLogo={this.state.employerLogo} employerName={this.state.employerName} jobTitle={this.state.jobTitle} />
                    <div className="clear" />
                  </div>
                </div>

              </div>
            ) : (
              <div>
                <div className={(this.state.renderSideNav) ? "floating-container card top-margin-2-percent right-margin-2-percent"  : "standard-container-2"} style={(this.state.renderSideNav) ? {...styles, opacity: this.state.opacity, transform: this.state.transform} : {}}>
                  <div className="padding-15">
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />

                    <div className="horizontal-padding-4 bottom-padding">
                      <p className="heading-text-2 full-width center-text">Your Social Posts</p>
                    </div>

                    <div className="row-30">
                      <div className="top-padding-20 full-width">
                        <img src={newsFeedIconDark} alt="GC" className="image-auto-100 center-horizontally" />
                      </div>
                      <div className="row-10 full-width horizontal-padding">
                        <div className="row-5 heading-text-4">
                          <p className="full-width center-text">Create Posts for your Followers</p>
                        </div>
                        <div className="row-5">
                          <p className="full-width center-text">Posts are a great way to engage people who follow you on Guided Compass.</p>
                        </div>
                      </div>

                      <div className="top-padding center-text">
                        <button className="btn btn-primary" onClick={() => this.setState({ modalIsOpen: true, showPost: true })}>Create a Post</button>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="clear"/>
              </div>
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

            {(this.state.showPost) && (
              <div key="showPost" className="full-width padding-20">
                 <SubCreatePost sharePosting={this.state.sharePosting} originalPost={this.state.originalPost} posts={this.state.socialPosts} passPosts={this.passPosts} closeModal={this.closeModal} pictureURL={this.state.employerLogo} accountCode={this.state.accountCode} jobTitle={this.state.jobTitle} />
               </div>
            )}
           </Modal>
        </div>
      )
    }
}

export default withRouter(EmpSocialPosts)
