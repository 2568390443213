import React, { useState } from 'react';
// import { useSpeechRecognition } from 'react-speech-kit';

const voiceIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/voice-icon-dark.png';

function SpeechToText(props) {

  // const [value, setValue] = useState('');
  // const { listen, listening, stop } = useSpeechRecognition({
  //   onResult: (result) => {
  //     setValue(result);
  //     props.passData(result)
  //   },
  // });
  //
  // const testMode = false

  return (
    <div>
      {/*
      {(testMode) && (
        <textarea
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
      )}

      <button onMouseDown={listen} onMouseUp={stop} className={(listening) ? "background-button padding-5 slightly-rounded-corners primary-background-light" : "background-button padding-5 slightly-rounded-corners light-background"}>🎤</button>
      {(listening && testMode) && <div>Go ahead I'm listening</div>}*/}

      {/*<button className="background-button padding-5 slightly-rounded-corners light-background">🎤</button>*/}

      <button className="background-button">
        <div className="cta-border padding-5 circle-radius">
          <img src={voiceIconDark} alt="GC" className="image-15-fit" />
        </div>
      </button>
    </div>
  );
}

export default SpeechToText;
