import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Axios from 'axios';
import SubEducatorBenefits from './Common/EducatorBenefits';
import withRouter from '../components/Functions/WithRouter';

const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const logoImgDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';

class PartnerEducatorPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
          questions: []
        }

        this.renderQuestions = this.renderQuestions.bind(this);
        this.questionClicked = this.questionClicked.bind(this);

    }

    componentDidMount() {
      console.log('partner educator page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      const { partner } = this.props.params

      if (partner) {

        let activeOrg = partner
        if (activeOrg === 'detroitk12') {
          activeOrg = 'dpscd'
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        if (partner === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
        } else if (partner === 'detroitk12') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        }

        this.setState({ backgroundImage, partner, activeOrg })

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgSelected = response.data.orgInfo.orgSelected
            const orgName = response.data.orgInfo.orgName
            const partnerName = orgName
            const orgProgramName = response.data.orgInfo.orgProgramName
            const orgDescription = response.data.orgInfo.orgDescription
            const contactEmail = response.data.orgInfo.contactEmail
            const studentAlias = response.data.orgInfo.studentAlias

            if (response.data.orgInfo.backgroundImageURL) {
              backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(' + response.data.orgInfo.backgroundImageURL + ')'
            }

            let educatorBenefitsText = "Assign lessons, source projects, lead workshops, endorse career-seekers, and export grades."
            if (response.data.orgInfo.educatorBenefitsText) {
              educatorBenefitsText = response.data.orgInfo.educatorBenefitsText
            }

            const questions = [
              { question: 'What is the benefit of working with ' + orgName + '?', answer: '...', isSelected: false},
              { question: 'Why use Guided Compass for teaching and counseling?', answer: '...', isSelected: false},
            ]

            const deactivated = response.data.orgInfo.deactivated
            let redirectLink = response.data.orgInfo.educatorRedirectLink
            if (redirectLink && !redirectLink.startsWith('http')) {
              redirectLink = 'http://' + redirectLink
            }

            const orgLogo = response.data.orgInfo.webLogoURI

            this.setState({
                questions, educatorBenefitsText, backgroundImage,
                orgSelected, orgName, partnerName, orgProgramName, orgDescription, contactEmail, studentAlias,
                deactivated, redirectLink, orgLogo
            });

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }


    renderQuestions() {
      console.log('renderQuestions called')

      let rows = []
      for (let i = 1; i <= this.state.questions.length; i++) {

        const index = i - 1

        rows.push(
          <div key={i} className="horizontal-padding-3">
            <div className="spacer" />
            <a onClick={() => this.questionClicked(index)} className="background-link">
            <div className="float-left">
              <div className="half-spacer" />
                <img src={arrowIcon} alt="Employer icon" className={this.state.questions[i - 1].isSelected ? "image-auto-20 rotate-90" : "image-auto-20"} />
              </div>
              <div className="float-left left-padding-15">
                <p className="heading-text-6">{this.state.questions[i - 1].question}</p>
              </div>
              <div className="clear" />
            </a>
            {this.state.questions[i - 1].isSelected && (
              <div className="left-padding-30">
                <div className="spacer" />
                <div className="half-spacer" />
                {this.renderAnswers(index)}
              </div>
            )}
            <div className="spacer" />
          </div>
        )
      }

      return rows
    }

    questionClicked(index) {
      console.log('questionClicked called')

      let questions = this.state.questions
      if (this.state.questions[index].isSelected) {
        questions[index]['isSelected'] = false
      } else {
        questions[index]['isSelected'] = true
      }

      this.setState({ questions })

    }

    renderAnswers(index) {
      console.log('renderAnswers called')

      let rows = []

      for (let i = 1; i <= this.state.questions[index].answer.split('\n').length; i++) {
        rows.push(
          <div key={"answer" + i.toString()}>
            <p className="description-text-2 bold-text cta-color">{this.state.questions[index].answer.split('\n')[i - 1]}</p>
          </div>
        )
      }

      return rows
    }

    render() {

      return (
          <div>
              <header className="front-page-header" style={{ backgroundImage: this.state.backgroundImage }}>
                  <div className="row top-margin">
                    <Link to={'/partners/' + this.state.partner}><img src={logoImgDark} alt="Compass logo" className="image-auto-50 float-left top-margin-25" /></Link>
                    <div className="partner-container">
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={addIcon} alt="Guided Compass add logo" className="partner-add-icon" /></Link>
                      </div>
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" className="image-auto-40 float-left top-margin-15 left-margin" /></Link>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="hero-text-box">
                    {(this.state.deactivated) ? (
                      <div>
                        <h1 className="light-text">{this.state.partnerName} Is No Longer a Guided Compass Partner</h1>
                        <p className="two-thirds-width">Click the link below to redirect to {this.state.partnerName}.</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <a href={this.state.redirectLink} target="_blank" className="btn btn-primary cta-background-color white-text">Redirect to {this.state.partnerName}</a>
                      </div>
                    ) : (
                      <div>
                        <h1 className="light-text">Educate Career-Seekers</h1>

                        <p className="two-thirds-width">{this.state.educatorBenefitsText}</p>

                        <div className="spacer" />
                        <div className="spacer" />
                        <div className="spacer" />

                        <div className="buttonContainer">
                          <Link className="clear-border" to={'/notification-subscriptions/opportunities/' + this.state.activeOrg + '/subscribe'} state={{ orgCode: this.state.activeOrg }}><button className="btn btn-primary">Subscribe to Weekly Opportunities List</button></Link>
                        </div>
                        <div className="buttonContainer left-padding">
                          <Link className="btn btn-secondary white-background"  to={'/contact'} state={{ reason: "I'd like to bulk upload my programs & courses", body: "I'd like to bulk upload programs and courses relevant to career pathways. In the CSV file, I'll specify the following: {{ title, course provider, career pathway, prices, link, degreeType, whether its solely online etc.. }}" }}>Add Training Programs & Courses to Pathways</Link>
                        </div>
                      </div>
                    )}

                  </div>
                  <div className="clear"/>
              </header>

              {(!this.state.deactivated) && (
                <div>
                  <SubEducatorBenefits history={this.props.navigate} orgSelected={this.state.orgSelected}/>
                  {/*
                  <section className="section-features">
                    <div className="row">
                        <h2>Why Work with {this.state.orgName}</h2>

                        <p className="two-thirds-width center-text center-horizontally">
                            Guided Compass and {this.state.orgName} make educating easy. We allow you to match to {(this.state.studentAlias && this.state.studentAlias !== '') ? this.state.studentAlias.toLowerCase() + 's' : "students"}, work with {(this.state.studentAlias && this.state.studentAlias !== '') ? this.state.studentAlias.toLowerCase() + 's' : "students"}, endorse {(this.state.studentAlias && this.state.studentAlias !== '') ? this.state.studentAlias.toLowerCase() + 's' : "students"}, and refer {(this.state.studentAlias && this.state.studentAlias !== '') ? this.state.studentAlias.toLowerCase() + 's' : "students"} for internship opportunities.
                        </p>
                    </div>
                  </section>

                  <section className="section-demo">
                    <div className="row">
                      <h2>Frequently Asked Questions</h2>
                    </div>

                    <div className="spacer" />
                    <div className="spacer" />

                    {this.renderQuestions()}

                  </section>*/}

                  <section className="section-features contrasting-background">
                    {(window.innerWidth < 768) ? (
                      <div className="full-width center-text">
                        <p className="full-width heading-text-2">Are you a Career-Seeker?</p>
                        <div className="top-margin">
                          <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/career-seekers'} state={{ fromExternal: true }}>Switch to Career-Seeker Page</Link>
                        </div>
                      </div>
                    ) : (
                      <div className="full-width">
                          <div className="container-left right-text">
                              <div className="full-width float-right">
                                <p className="join-text">Are you a</p>
                                <p className="join-title">Career-Seeker?</p>
                              </div>
                          </div>
                          <div className="container-right left-padding center-item">
                            <div className="padding-20">
                              <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/career-seekers'} state={{ fromExternal: true }}>Switch to Career-Seeker Page</Link>
                            </div>
                          </div>
                          <div className="clear" />
                      </div>
                    )}
                  </section>
                </div>
              )}

              <Footer history={this.props.navigate} />
          </div>

      )
    }
}

export default withRouter(PartnerEducatorPage);
