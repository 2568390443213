import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, ArcElement, LinearScale, CategoryScale, Title } from 'chart.js';
ChartJS.register(LineElement, PointElement, ArcElement, LinearScale, CategoryScale, Title);

import AppNavigation from '../AppNavigation';
import SubSideNav from '../Common/SideNav';
import AppFooter from '../AppFooter';
import SubDashboard from '../Subcomponents/Dashboard';
import SubChangePassword from '../Common/ChangePassword';
import withRouter from '../Functions/WithRouter';
import {toggleVars} from '../Functions/ToggleVars';
import {convertDateToString} from '../Functions/ConvertDateToString';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
// const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const dropdownArrow = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png";
// const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";
const checkboxEmptyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png";
const checkboxChecked = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png";
const xIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png";
const infoIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/info-icon-dark.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class OrgDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule1: true,
          showModule2: true,
          showDemo: false,
          showLayoutToggle: false,
          subNavSelected: 'Educator',

          opacity: 0,
          transform: 'translate(90%)',

          dashboardLayout: 'Placement',

          viewIndex: 0,
          calculateImmediately: true,

          stageOptions: [],
          employerStageOptions: [],

          timeframeOptions: ['1 mo','3 mo','6 mo','12 mo','all time'],
          reportOptions: ['Career-Seeker Funnel','Employer Funnel'],

          timeframe: 'all time',
          reportSelected: 'Career-Seeker Funnel',

          members: [],
          filteredMembers: [],
          sessions: [],
          goals: [],
          applications: [],
          interviews: [],
          offers: [],
          projects: [],
          placements: 0,
          phase: '',

          employers: [],
          postings: [],
          students: [],

          usersToDelete: [],
          users: [],

          signupsCount: 0,
          aResumeCount: 0,
          aProjectCount: 0,
          anAssessmentCount: 0,
          allAssessmentsCount: 0,
          aGoalCount: 0,
          anEndorsementCount: 0,
          anEventCount: 0,
          anApplicationCount: 0,
          anApprovalCount: 0,
          aReferralCount: 0,
          aConnectionCount: 0,
          anOfferCount: 0,
          aFeedbackCount: 0,
          hiredCount: 0,

          showEmails: [false, false, false, false, false, false, false, false, false, false, false, false],
          isLoading: false,

          data: null

        }

        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.calculateDashboard = this.calculateDashboard.bind(this)

        this.deleteApps = this.deleteApps.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.addItem = this.addItem.bind(this)
        this.translateText = this.translateText.bind(this)

        this.filterValues = this.filterValues.bind(this)
        this.sortValues = this.sortValues.bind(this)
        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)

        this.commandClicked = this.commandClicked.bind(this)
        this.openAssistant = this.openAssistant.bind(this)

        this.toggleNavMode = this.toggleNavMode.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      //obtain email from localStorage
      // let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      // let orgFocus = localStorage.getItem('orgFocus');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgLogo = localStorage.getItem('orgLogo');
      let studentAliasSingular = localStorage.getItem('studentAliasSingular');
      let mentorAliasSingular = localStorage.getItem('mentorAliasSingular');
      let temporaryPassword = localStorage.getItem('temporaryPassword');
      let newAdminLayout = localStorage.getItem('newAdminLayout')

      let showModule2 = this.state.showModule2
      if (newAdminLayout) {
        showModule2 = true
        toggleVars['hideAdminSideNav'] = true
      }

      let calculateImmediately = this.state.calculateImmediately
      if (org === 'unite-la' || org === 'guidedcompass') {
        calculateImmediately = false
      }

      let modalIsOpen = null
      let showChangePassword = null
      if (temporaryPassword) {
        modalIsOpen = true
        showChangePassword = true
      }

      let funnelList = null
      if (this.state.showDemo) {
        funnelList = [
          { name: 'Active Users', count: 432734 },
          { name: '# of Messages Sent', count: 1539703 },
          { name: '# of Assignments Created', count: 38929 },
          { name: '# of Quizzes Completed', count: 29834 },
          { name: '# of Grades Completed', count: 12398 },
          { name: '# of Projects Completed', count: 348934 },
          { name: '# of Career Assessments Taken', count: 38434 },
          { name: '# of Goals Created', count: 69327 },
          { name: '# of Applications Created', count: 19383 },
          { name: '# of Placements Created', count: 9789 },
          { name: '# of Hours Used', count: 131289 },
        ]
      }
      // if (this.state.funnelList) {
      // }
      console.log('od1')
      this.setState({ org, emailId: email, cuFirstName, cuLastName, username, orgLogo,
        studentAliasSingular, mentorAliasSingular, calculateImmediately,
        modalIsOpen, showChangePassword, funnelList, showModule2
      })

      if (!this.state.showModule1) {
        console.log('od2')
        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let dashboardLayout = this.state.dashboardLayout
            if (response.data.orgInfo.dashboardLayout) {
              dashboardLayout = response.data.orgInfo.dashboardLayout
            }

            orgLogo = response.data.orgInfo.webLogoURIColor

            this.setState({ dashboardLayout, orgLogo })

            if (dashboardLayout === 'Placement') {
              let stageOptions = [
                { name: 'Sign Ups', description: 'The sign ups stage encompasses all career seekers who have added first name, last name, email, and password.', count: 0 },
                { name: 'Submitted Project', description: 'The submitted project stage encompasses all career seekers who have submitted a project to a prompt (i.e. assignment, problem, challenge).', count: 0 },
                { name: 'Applied', description: 'The applied stage encompasses all career seekers that have applied to at least one internship / work posting.', count: 0 },
                { name: 'Referred', description: 'The referred stage encompasses all career seekers who have been referred to at least one role.', count: 0 },
                { name: 'Connected', description: 'The connected stage encompasses all career seekers who have been connected to at least one employer for an interview.', count: 0 },
                { name: 'Hired', description: 'The hired stage encompasses all career seekers who have been hired.', count: 0 }
              ]

              if (org === 'unite-la') {
                stageOptions.splice(3, 0, { name: 'Approved', description: 'The approved stage encompasses all career seekers who have been interviewed and approved for at least one career track', count: 0 })
              }

              let employerStageOptions = [
                { name: 'Sign Ups', description: 'The sign ups stage encompasses all employers that have signed up to Guided Compass.', count: 0 },
                { name: 'Posted Project', description: 'The posted project stage encompasses all employers that have posted a prompt for career seekers submit to.', count: 0 },
                { name: 'Posted Work', description: 'The posted work stage encompasses all employers that have posted a work opportunities for careers to submit to.', count: 0 },
                { name: 'Applied To', description: 'The applied to stage encompasses all employers that have at least one application for their work posting.', count: 0 },
                { name: 'Interviewed', description: 'The interviewed stage encompasses all employers that have interviewed at least one career seeker for their work opening.', count: 0 },
                { name: 'Hired One', description: 'The hired one stage encompasses all employers that have interviewed at least one career seeker for their work opening.', count: 0 },
                { name: 'Hired Five', description: 'The hired five stage encompasses all employers that have interviewed at least five career seeker for their work opening.', count: 0 }
              ]

              const orgFocus2 = response.data.orgInfo.orgFocus
              const orgName = response.data.orgInfo.orgName
              const requestToInterview = response.data.orgInfo.requestToInterview

              this.setState({ orgName, orgFocus: orgFocus2, requestToInterview,
                stageOptions, employerStageOptions
              })

              if (calculateImmediately) {
                this.calculateDashboard(dashboardLayout, org, stageOptions, requestToInterview, 'all time')
              }
            } else if (dashboardLayout === 'Mentor') {
              // console.log('in mentor!!!')

              let dashFilters = [
                { name: 'Approved', checked: false },
                { name: 'Active', checked: false },
                { name: 'Inactive', checked: false },
                { name: 'Alumni', checked: false },
                { name: 'Paired', checked: false },
                { name: 'Unpaired', checked: false }
              ]
              if (studentAliasSingular) {
                dashFilters.push({ name: studentAliasSingular, checked: false })
              } else {
                dashFilters.push({ name: 'Career-Seeker', checked: false })
              }
              if (mentorAliasSingular) {
                dashFilters.push({ name: mentorAliasSingular, checked: false })
              } else {
                dashFilters.push({ name: 'Mentor', checked: false })
              }

              this.setState({ dashFilters })

              if (calculateImmediately) {
                this.calculateDashboard(dashboardLayout, org, null, null, 'all time')
              }
            }

          } else {
            console.log('org info query did not work', response.data.message)

          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

      // if side tab is there
      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)'
              }));
        }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      this.setState({ [event.target.name]: event.target.value })
    }

    calculateDashboard(dashboardLayout, org, stageOptions, interviewRequested, timeframe) {

      this.setState({ isLoading: true, timeframe, showChangeFunnelTimeframe: false })

      if (dashboardLayout === 'Mentor') {
        if (!this.state.showModule2) {
          Axios.get('/api/org/members', { params: { orgCode: org } })
          .then((response) => {
            if (response.data.success) {
              console.log('Member query worked', response.data);

              if (response.data.members && response.data.members.length > 0) {

                let members = response.data.members

                const self = this
                // this.setState({ members })
                function synthesizeData(partners, submissions) {
                  console.log('synthesizeData callled', partners, submissions)

                  for (let i = 1; i <= members.length; i++) {
                    const member = members[i - 1]
                    if (partners) {
                      for (let j = 1; j <= partners.length; j++) {
                        const partner = partners[j - 1]
                        if (partner.advisorEmail === member.email && partner.active) {
                          members[i - 1]['pair'] = {
                            firstName: partner.adviseeFirstName,
                            lastName: partner.adviseeLastName,
                            email: partner.adviseeEmail,
                            username: partner.adviseeUsername,
                            pictureURL: partner.adviseePictureURL,
                            headline: partner.adviseeHeadline,
                            roleName: "Career-Seeker",
                          }
                        }
                        if (partner.adviseeEmail === member.email && partner.active) {
                          members[i - 1]['pair'] = {
                            firstName: partner.advisorFirstName,
                            lastName: partner.advisorLastName,
                            email: partner.advisorEmail,
                            username: partner.advisorUsername,
                            pictureURL: partner.advisorPictureURL,
                            headline: partner.advisorHeadline,
                            roleName: "Mentor",
                          }
                        }
                      }
                    }
                    let submissionCount = 0
                    let avgRating = null
                    if (submissions) {
                      let memberSubs = []
                      for (let j = 1; j <= submissions.length; j++) {
                        const sub = submissions[j - 1]
                        if (sub.emailId === member.email) {
                          memberSubs.push(sub)
                          submissionCount = submissionCount + 1

                          if (sub.answers && sub.answers.length > 0) {
                            for (let k = 1; k <= sub.answers.length; k++) {
                              const answer = sub.answers[k - 1]
                              if (answer && answer.question && answer.question.startsWith('How effective')) {
                                let rating = 0
                                if (answer.answer && answer.answer.trim() === 'Very Effective') {
                                  rating = 5
                                } else if (answer.answer && answer.answer.trim() === 'Moderately Effective') {
                                  rating = 4
                                } else if (answer.answer && answer.answer.trim() === 'Somewhat Effective') {
                                  rating = 3
                                } else if (answer.answer && answer.answer.trim() === 'Moderately Ineffective') {
                                  rating = 2
                                } else if (answer.answer && answer.answer.trim() === 'Very Ineffective') {
                                  rating = 1
                                }

                                if (avgRating === 'N/A') {
                                  avgRating = rating
                                } else {
                                  avgRating = (((submissionCount - 1) * avgRating) + (rating)) / submissionCount
                                }
                              }
                            }
                          }
                        }
                      }

                      members[i - 1]['submissions'] = memberSubs
                    }

                    // console.log('gimme vaalues', member.email, submissionCount, avgRating)
                    members[i - 1]['submissionCount'] = submissionCount
                    if (avgRating) {
                      members[i - 1]['avgRating'] = avgRating.toFixed(2)
                    }
                  }

                  let filteredMembers = members
                  self.setState({ members, filteredMembers })

                }

                function pullSubmissions(partners) {
                  console.log('pullSubmissions called', partners)

                  Axios.get('/api/submissions', { params: { orgCode: org, sortDescending: true }})
                  .then((response) => {
                    if (response.data.success) {
                      console.log('Submissions query worked', response.data);

                      const submissions = response.data.submissions
                      synthesizeData(partners, submissions)

                    } else {
                      console.log('no submissions found', response.data.message)
                      synthesizeData(partners, null)
                    }

                  }).catch((error) => {
                      console.log('Submissions query did not work', error);
                      synthesizeData(partners, null)
                  });
                }

                // partners, submissions
                Axios.get('/api/partners', { params: { orgCode: org }})
                .then((response) => {
                  if (response.data.success) {
                    console.log('Partners query worked', response.data);

                    const partners = response.data.partners
                    pullSubmissions(partners)

                  } else {
                    console.log('no partners found', response.data.message)
                    pullSubmissions()
                  }

                }).catch((error) => {
                    console.log('Partners query did not work', error);
                    pullSubmissions()
                });

              }

            } else {
              console.log('no members found', response.data.message)
            }

          }).catch((error) => {
              console.log('Members query did not work', error);
          });
        }
      } else if (dashboardLayout === 'Placement') {
        const chartOptions = {
           tooltips: {
              callbacks: {
                 label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                 },
                 title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                 }
              }
           }
        }

        Axios.get('/api/funnel/career-seeker', { params: { orgCode: org, timeframe } })
        .then((response) => {

            if (response.data.success) {
              console.log('Stage query worked', response.data);

              let signupsCount = 0
              let aResumeCount = 0
              let aProjectCount = 0
              let anAssessmentCount = 0
              let aGoalCount = 0
              let anEventCount = 0
              let anApplicationCount = 0
              let aReferralCount = 0
              let aConnectionCount = 0
              let aFeedbackCount = 0
              let hiredCount = 0

              if (response.data.funnel) {
                signupsCount = response.data.funnel.signupsCount
                aResumeCount = response.data.funnel.aResumeCount
                aProjectCount = response.data.funnel.aProjectCount
                anAssessmentCount = response.data.funnel.anAssessmentCount
                aGoalCount = response.data.funnel.aGoalCount
                anEventCount = response.data.funnel.anEventCount
                anApplicationCount = response.data.funnel.anApplicationCount
                aReferralCount = response.data.funnel.aReferralCount
                aConnectionCount = response.data.funnel.aConnectionCount
                aFeedbackCount = response.data.funnel.aFeedbackCount
                hiredCount = response.data.funnel.hiredCount

              }

              let ethnicityData = null
              let opportunityYouthData = null
              let genderData = null
              let ageData = null
              let educationData = null
              let majorData = null
              let sourceData = null
              let topReferrers = null

              if (response.data.demographicData) {

                if (response.data.demographicData.ethnicity && response.data.demographicData.ethnicity.length > 0) {

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.ethnicity.length; i++) {
                    if (response.data.demographicData.ethnicity[i - 1] && response.data.demographicData.ethnicity[i - 1].name) {
                      data.push(response.data.demographicData.ethnicity[i - 1].eCount)
                      labels.push(response.data.demographicData.ethnicity[i - 1].name)
                    }
                  }
                  console.log('data and label: ', data, labels)
                  ethnicityData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.opportunityYouth && response.data.demographicData.opportunityYouth.yesCount) {
                  console.log('t5')
                  let yesCount = 0
                  if (response.data.demographicData.opportunityYouth.yesCount) {
                    yesCount = response.data.demographicData.opportunityYouth.yesCount
                  }
                  let noCount = 0
                  if (response.data.demographicData.opportunityYouth.noCount) {
                    noCount = response.data.demographicData.opportunityYouth.noCount
                  }

                  let data = [yesCount, noCount]
                  let labels = ['Opportunity Youth','Not Opportunity Youth']
                  opportunityYouthData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.gender && response.data.demographicData.gender.length > 0) {

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.gender.length; i++) {
                    if (response.data.demographicData.gender[i - 1] && response.data.demographicData.gender[i - 1].name) {
                      data.push(response.data.demographicData.gender[i - 1].eCount)
                      labels.push(response.data.demographicData.gender[i - 1].name)
                    }
                  }
                  console.log('data and label: ', data, labels)
                  genderData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.age && response.data.demographicData.age.length > 0) {

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.age.length; i++) {
                    if (response.data.demographicData.age[i - 1] && response.data.demographicData.age[i - 1].name) {
                      data.push(response.data.demographicData.age[i - 1].eCount)
                      labels.push(response.data.demographicData.age[i - 1].name)
                    }
                  }
                  // console.log('data and label: ', data, labels)
                  ageData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.education && response.data.demographicData.education.length > 0) {

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.education.length; i++) {
                    if (response.data.demographicData.education[i - 1] && response.data.demographicData.education[i - 1].name) {
                      data.push(response.data.demographicData.education[i - 1].eCount)
                      labels.push(response.data.demographicData.education[i - 1].name)
                    }
                  }
                  // console.log('data and label: ', data, labels)
                  educationData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.majors && response.data.demographicData.majors.length > 0) {

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.majors.length; i++) {
                    if (response.data.demographicData.majors[i - 1] && response.data.demographicData.majors[i - 1].name) {
                      data.push(response.data.demographicData.majors[i - 1].eCount)
                      labels.push(response.data.demographicData.majors[i - 1].name)
                    }
                  }
                  // console.log('data and label: ', data, labels)
                  majorData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }

                if (response.data.demographicData.referrerOrgs && response.data.demographicData.referrerOrgs.length > 0) {
                  topReferrers = response.data.demographicData.referrerOrgs

                  let data = []
                  let labels = []
                  for (let i = 1; i <= response.data.demographicData.referrerOrgs.length; i++) {
                    if (response.data.demographicData.referrerOrgs[i - 1] && response.data.demographicData.referrerOrgs[i - 1].name) {
                      data.push(response.data.demographicData.referrerOrgs[i - 1].eCount)
                      labels.push(response.data.demographicData.referrerOrgs[i - 1].name)
                    }
                  }
                  // console.log('data and label: ', data, labels)
                  sourceData = {
                    datasets: [{
                      data,
                      backgroundColor: [
                        "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                        "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                      ]
                    }],
                    labels
                  }
                }
              }

              this.setState({ isLoading: false, hasCalculated: true,
                signupsCount, aResumeCount, aProjectCount, anAssessmentCount, aGoalCount, anEventCount,
                anApplicationCount, aReferralCount, aConnectionCount, aFeedbackCount, hiredCount,
                ethnicityData, opportunityYouthData, genderData, ageData, educationData, majorData,
                topReferrers, sourceData, chartOptions
              })

              Axios.get('/api/assessment/insights', { params: { orgCode: org, timeframe } })
              .then((response) => {

                  if (response.data.success) {
                    console.log('Career assessment insights query worked', response.data);

                    if (response.data.assessmentInsights) {
                      console.log('a0')
                      let skillsData = null
                      let interestsData = null
                      let personalityData = null
                      let valuesData = null
                      let functionsOfInterestData = null
                      let industriesOfInterestData = null

                      if (response.data.assessmentInsights.skills && response.data.assessmentInsights.skills.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.skills.length; i++) {
                          if (response.data.assessmentInsights.skills[i - 1] && response.data.assessmentInsights.skills[i - 1].name) {
                            data.push(response.data.assessmentInsights.skills[i - 1].weightedScore)
                            labels.push(response.data.assessmentInsights.skills[i - 1].name)
                          }
                        }
                        console.log('data and label: ', data, labels)
                        skillsData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.assessmentInsights.interests && response.data.assessmentInsights.interests.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.interests.length; i++) {
                          if (response.data.assessmentInsights.interests[i - 1] && response.data.assessmentInsights.interests[i - 1].name) {
                            data.push(response.data.assessmentInsights.interests[i - 1].score)
                            labels.push(response.data.assessmentInsights.interests[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        interestsData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.assessmentInsights.traits && response.data.assessmentInsights.traits.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.traits.length; i++) {
                          if (response.data.assessmentInsights.traits[i - 1] && response.data.assessmentInsights.traits[i - 1].name) {
                            data.push(response.data.assessmentInsights.traits[i - 1].score)
                            labels.push(response.data.assessmentInsights.traits[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        personalityData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.assessmentInsights.values && response.data.assessmentInsights.values.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.values.length; i++) {
                          if (response.data.assessmentInsights.values[i - 1] && response.data.assessmentInsights.values[i - 1].name) {
                            data.push(response.data.assessmentInsights.values[i - 1].eCount)
                            labels.push(response.data.assessmentInsights.values[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        valuesData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.assessmentInsights.functionsOfInterest && response.data.assessmentInsights.functionsOfInterest.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.functionsOfInterest.length; i++) {
                          if (response.data.assessmentInsights.functionsOfInterest[i - 1] && response.data.assessmentInsights.functionsOfInterest[i - 1].name) {
                            data.push(response.data.assessmentInsights.functionsOfInterest[i - 1].eCount)
                            labels.push(response.data.assessmentInsights.functionsOfInterest[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        functionsOfInterestData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.assessmentInsights.industriesOfInterest && response.data.assessmentInsights.industriesOfInterest.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.assessmentInsights.industriesOfInterest.length; i++) {
                          if (response.data.assessmentInsights.industriesOfInterest[i - 1] && response.data.assessmentInsights.industriesOfInterest[i - 1].name) {
                            data.push(response.data.assessmentInsights.industriesOfInterest[i - 1].eCount)
                            labels.push(response.data.assessmentInsights.industriesOfInterest[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        industriesOfInterestData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      this.setState({ skillsData, interestsData, personalityData, valuesData, functionsOfInterestData, industriesOfInterestData })
                    }

                  } else {
                    console.log('no career assessment insights info found', response.data.message)
                    this.setState({ isLoading: false })
                  }

              }).catch((error) => {
                  console.log('Career assessment insights query did not work', error);

              });

              Axios.get('/api/postings/insights', { params: { orgCode: org, timeframe } })
              .then((response) => {

                  if (response.data.success) {
                    console.log('Posting insights query worked', response.data);

                    if (response.data.postingInsights) {

                      let functionsData = null
                      let industriesData = null

                      if (response.data.postingInsights.functions && response.data.postingInsights.functions.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.postingInsights.functions.length; i++) {
                          if (response.data.postingInsights.functions[i - 1] && response.data.postingInsights.functions[i - 1].name) {
                            data.push(response.data.postingInsights.functions[i - 1].eCount)
                            labels.push(response.data.postingInsights.functions[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        functionsData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      if (response.data.postingInsights.industries && response.data.postingInsights.industries.length > 0) {

                        let data = []
                        let labels = []
                        for (let i = 1; i <= response.data.postingInsights.industries.length; i++) {
                          if (response.data.postingInsights.industries[i - 1] && response.data.postingInsights.industries[i - 1].name) {
                            data.push(response.data.postingInsights.industries[i - 1].eCount)
                            labels.push(response.data.postingInsights.industries[i - 1].name)
                          }
                        }
                        // console.log('data and label: ', data, labels)
                        industriesData = {
                          datasets: [{
                            data,
                            backgroundColor: [
                              "rgba(255,0,0,0.75)","rgba(255,255,10,0.75)","rgba(0,0,255,0.75)",
                              "rgba(255,165,0,0.75)","rgba(128,0,128,0.75)","rgba(0,255,0,0.75)",
                            ]
                          }],
                          labels
                        }
                      }

                      this.setState({ functionsData, industriesData })

                    }

                  } else {
                    console.log('no posting insights info found', response.data.message)

                  }

              }).catch((error) => {
                  console.log('Career assessment insights query did not work', error);

              });

            } else {
              console.log('no stage info found', response.data.message)
              this.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('Stage info query did not work', error);
            this.setState({ isLoading: false })
        });

        Axios.get('/api/top-employers', { params: { orgCode: org, timeframe } })
        .then((response) => {

            if (response.data.success) {
              console.log('Top employers query worked', response.data);

              if (response.data.topEmployers) {
                const topEmployers = response.data.topEmployers
                this.setState({ isLoading: false, topEmployers })
              }

            } else {
              console.log('no stage info found', response.data.message)
              this.setState({ isLoading: false })
            }

        }).catch((error) => {
            console.log('Tp employers query did not work', error);
            this.setState({ isLoading: false })
        });
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showSubmissions: false, selectedMember: null, showChangePassword: false });
    }

    deleteApps() {
      console.log('deleteApps called', this.state.users.length)

      let usersToDelete = this.state.usersToDelete
      let deleteCount = usersToDelete.length

      if (this.state.viewIndex <= 1) {
        // archiving the student account
        console.log('archive the student account')

        Axios.put('/api/users/archive', { emails: usersToDelete })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('User archive worked ', response.data);

            usersToDelete = []
            let users = []
            for (let i = 1; i <= this.state.users.length; i++) {
              if (!this.state.users[i - 1]['isChecked']) {
                users.push(this.state.users[i - 1])
              }
            }

            // change applied count
            let stageOptions = this.state.stageOptions
            stageOptions[0]['count'] = stageOptions[0].count - deleteCount

            this.setState({
              serverPostSuccess: true,
              serverSuccessMessage: 'Application was deleted successfully! Once you exit, you will no longer have access to the posting.',
              hasRemoved: true, usersToDelete, users, stageOptions
            })

          } else {
            console.log('user archive did not save successfully')
            this.setState({
              serverSuccessText: false,
              serverErrorMessageText: response.data.message
            })
          }

        }).catch((error) => {
            console.log('User archive save did not work', error);
        });

      } else {

        // deleting the application
        console.log('usersToDelete log', usersToDelete)

        Axios.get('/api/applications', { params: { emails: usersToDelete, orgCode: this.state.org } })
        .then((response) => {
          console.log('Applications query worked', response.data);

          if (response.data.success) {

            let appsToDelete = response.data.applicationIds
            console.log('show appsToDelete: ', appsToDelete)
            Axios.delete('/api/applications/1', { data: { appsToDelete } })
            .then((response) => {
              console.log('tried to delete', response.data)
              if (response.data.success) {
                //save values
                console.log('App delete worked');

                //reset appsToDelete, removeApplications
                usersToDelete = []
                let users = []
                for (let i = 1; i <= this.state.users.length; i++) {
                  if (!this.state.users[i - 1]['isChecked']) {
                    users.push(this.state.users[i - 1])
                  }
                }

                // change applied count
                let stageOptions = this.state.stageOptions
                stageOptions[2]['count'] = stageOptions[2].count - deleteCount

                this.setState({
                  serverPostSuccess: true,
                  serverSuccessMessage: 'Application was deleted successfully! Once you exit, you will no longer have access to the posting.',
                  hasRemoved: true, usersToDelete, users, stageOptions
                })

              } else {
                console.error('there was an error deleting the application');
                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: response.data.message,
                })
              }
            }).catch((error) => {
                console.log('The deleting did not work', error);
                this.setState({
                  serverPostSuccess: false,
                  serverErrorMessage: error,
                })
            });

          } else {
            console.log('no pathways data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Pathways query did not work', error);
        });
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      // if (type === 'dashFilter') {
      //   let dashFilters = this.state.dashFilters
      //   if (dashFilters) {
      //     dashFilters.push({ name: '' })
      //   } else {
      //     dashFilters = [{ name: '' }]
      //   }
      //   this.setState({ dashFilters })
      // }
    }

    translateText(type,roleName) {
      console.log('translateText called', type, roleName)

      let returnedText = ''

      if (type === 'roleName') {
        if ((roleName === 'Student' || roleName === 'Career-Seeker') && this.state.studentAliasSingular) {
          returnedText = this.state.studentAliasSingular
        } else if (roleName === 'Mentor' && this.state.mentorAliasSingular) {
          returnedText = this.state.mentorAliasSingular
        } else {
          returnedText = roleName
        }
      }
      return returnedText
    }

    filterValues(type) {
      console.log('filterValues called', type)

      let dashFilters = this.state.dashFilters
      let filteredMembers = []
      if (type === "Clear") {
        for (let i = 1; i <= dashFilters.length; i++) {
          dashFilters[i - 1]['checked'] = false
        }
        filteredMembers = this.state.members
      } else {
        let oneChecked = false
        for (let i = 1; i <= dashFilters.length; i++) {
          if (type === dashFilters[i - 1].name) {
            dashFilters[i - 1]['checked'] = !dashFilters[i - 1].checked
          }
          if (dashFilters[i - 1].checked) {
            oneChecked = true
          }
        }

        if (!oneChecked) {
          filteredMembers = this.state.members
        } else {
          if (this.state.members) {
            for (let i = 1; i <= this.state.members.length; i++) {
              const item = this.state.members[i - 1]
              for (let j = 1; j <= dashFilters.length; j++) {
                const filter = dashFilters[j - 1]
                if (filter.checked) {
                  if (filter.name === 'Approved' && item.enrollmentStatus === 'Approved') {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Active' && item.enrollmentStatus === 'Active') {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Inactive' && item.enrollmentStatus === 'Inactive') {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Alumni' && item.enrollmentStatus === 'Alumni') {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Paired' && item.pair) {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Unpaired' && !item.pair) {
                    filteredMembers.push(item)
                  }
                  if (filter.name === 'Career-Seeker' && item.roleName === 'Career-Seeker') {
                    filteredMembers.push(item)
                  }
                  if (filter.name === this.state.studentAliasSingular && (item.roleName === 'Student' || item.roleName === 'Career-Seeker')) {
                    filteredMembers.push(item)
                  }
                  if (filter.name === this.state.mentorAliasSingular && item.roleName === 'Mentor') {
                    filteredMembers.push(item)
                  }
                }
              }
            }
          }
        }
      }

      this.setState({ dashFilters, filteredMembers })

    }

    sortValues(type, values) {
      console.log('sortValues called', type, values)

      let sortValue = type

      let members = values
      let sortDescending = this.state.sortDescending

      if (type === 'pair') {

        if (sortDescending) {
          members.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.pair) {
              textA = a.pair
            }
            if (b.pair) {
              textB = b.pair
            }
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        } else {
          members.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.pair) {
              textA = a.pair
            }
            if (b.pair) {
              textB = b.pair
            }
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }
      } else if (type === 'enrollmentStatus') {
        if (sortDescending) {
          members.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.enrollmentStatus) {
              textA = a.enrollmentStatus
            }
            if (b.enrollmentStatus) {
              textB = b.enrollmentStatus
            }
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        } else {
          members.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.enrollmentStatus) {
              textA = a.enrollmentStatus
            }
            if (b.enrollmentStatus) {
              textB = b.enrollmentStatus
            }
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }
      } else {

        if (sortDescending) {
          members.sort(function(a, b){
              if(a[sortValue] > b[sortValue]) { return -1; }
              if(a[sortValue] < b[sortValue]) { return 1; }
              return 0;
          })
        } else {
          members.sort(function(a, b){
              if(a[sortValue] < b[sortValue]) { return -1; }
              if(a[sortValue] > b[sortValue]) { return 1; }
              return 0;
          })
          console.log('?!')
        }
      }

      sortDescending = !sortDescending
      this.setState({ members, sortDescending })
    }

    toggleConfirmDelete(index,deleteItem) {
      console.log('toggleConfirmDelete called', index, deleteItem)

      let selectedMember = this.state.selectedMember

      if (deleteItem) {

        Axios.delete('/api/submissions/' + selectedMember['submissions'][index]._id)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Submission delete worked');

            selectedMember['submissions'].splice(index,1)
            this.setState({ selectedMember })

          } else {
            console.error('there was an error deleting the submission');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error.toString() })
        });
      } else {
        selectedMember['submissions'][index]['confirmDelete'] = !selectedMember.submissions[index].confirmDelete
        this.setState({ selectedMember })
      }
    }

    commandClicked(item, demo) {
      console.log('commandClicked called')

      if (demo) {
        // a demo for educators

        const goal = "starting a business"
        const salary = "$100K"
        const jobTitle = "Software Engineer"
        const employerName = "Google"
        const businessIdea = "healthy meal app"

        const field = "health & wellness"
        const offerAmount = "$100K"
        const targetCustomer = "Vegan yoga instructors"
        const productName = "Healthispan"
        const productDescription = "healthy meal planning for health conscious individuals"
        const businessName = "Helathispan"
        const businessDescription = "healthy meal planning for health conscious individuals"
        const interest1 = "basketball"
        const interest2 = "music"
        const interest3 = "cooking"
        const skill1 = "communicating with people"
        const skill2 = "collaborating with people"
        const skill3 = "staying positive"
        const myersBriggsScore = "ESFP"
        const interviewQuestion = "How do you prioritize product features and make trade-off decisions?"
        const interviewResponse = "I score features on a scale of 1-10 for both their impact and their effort. Then I calculate the impact divided by effort. Then I sort the results, prioritizing those with the highest ratios."

        const name = item.name.replace(/{{goal}}/g,goal).replace(/{{salary}}/g,salary).replace(/{{jobTitle}}/g,jobTitle).replace(/{{employerName}}/g,employerName).replace(/{{businessIdea}}/g,businessIdea).replace(/{{field}}/g,field).replace(/{{offerAmount}}/g,offerAmount).replace(/{{targetCustomer}}/g,targetCustomer).replace(/{{productName}}/g,productName).replace(/{{productDescription}}/g,productDescription).replace(/{{businessName}}/g,businessName).replace(/{{businessDescription}}/g,businessDescription).replace(/{{interest1}}/g,interest1).replace(/{{interest2}}/g,interest2).replace(/{{interest3}}/g,interest3).replace(/{{skill1}}/g,skill1).replace(/{{skill2}}/g,skill2).replace(/{{skill3}}/g,skill3).replace(/{{myersBriggsScore}}/g,myersBriggsScore).replace(/{{interviewQuestion}}/g,interviewQuestion).replace(/{{interviewResponse}}/g,interviewResponse)

        // this.child.current.passCommand(name);
        this.setState({ passedCommand: name })
      } else {
        // for mentors
        this.setState({ passedCommand: item })
      }
    }

    openAssistant() {
      console.log('openAssistant called')
      this.setState({ openUpAssistant: true })
    }

    toggleNavMode(change) {
      console.log('toggleNavMode called')

      this.setState({ showModule2: change })
      toggleVars['hideAdminSideNav'] = change
      if (change) {
        localStorage.setItem('newAdminLayout', 'true')
      } else {
        localStorage.removeItem('newAdminLayout')
      }
    }

    render() {

      return (
          <div>
              <AppNavigation fromOrganization={true} org={this.state.org} orgFocus={this.state.orgFocus} history={this.props.navigate}/>

              {(!this.state.showModule2) && (
                <SubSideNav selectedComponent={"OrgDashboard"} activeOrg={this.state.org} orgFocus={this.state.orgFocus}  workMode={this.state.workMode} roleName={this.state.roleName} finishedQuery={this.state.finishedQuery} />
              )}

              <div style={(this.state.showModule2) ? {} : { ...styles, float: 'left', width: 'calc(91% - 260px)', height: 'auto', margin: '2% 2% 0% 0.5%', opacity: this.state.opacity, transform: this.state.transform }}>
                  {(this.state.org === 'defaultsandbox') ? (
                    <div className={(this.state.showModule2) ? "width-70-percent center-horizontally max-width-1400 top-margin-2-percent" : ""}>
                      <div className="card top-margin flex-container flex-center">
                        <div className="padding-20">
                          <img src={infoIconDark} alt="GC" className="image-auto-40 center-item top-margin bottom-margin" />
                          <p className="center-text top-margin-20 heading-text-6">This is a Sandbox Account</p>
                          <p className="center-text top-margin-20 description-text-1">To create a customized, dedicated community, email <label className="cta-color">help@guidedcompass.com</label>. A dedicated community includes portals for career-seekers, administrators, parents, employers, and mentors.</p>

                          <p className="center-text row-20 description-text-1">Pricing is dependent on the number of members in the community.</p>
                        </div>
                    </div>
                    </div>
                  ) : (
                    <div>
                      {(this.state.showModule2) ? (
                        <div>
                          <SubDashboard commandClicked={this.commandClicked} openAssistant={this.openAssistant} activeOrg={this.state.org} toggleNavMode={this.toggleNavMode} showLayoutToggle={this.state.showLayoutToggle} />
                        </div>
                      ) : (
                        <div className={(this.state.showModule2) ? "width-70-percent center-horizontally max-width-1400 top-margin-2-percent" : ""}>
                          {(this.state.dashboardLayout === "Placement") && (
                            <div>
                              <div className="row-10">
                                <div className="calc-column-offset-90">
                                  <div className="float-left">
                                    <p className="heading-text-2">Dashboard</p>
                                  </div>

                                  {(!this.state.calculateImmediately) && (
                                    <div className="float-left row-5 left-padding-20">
                                      <button className="btn btn-squarish medium-shadow" onClick={() => this.calculateDashboard(this.state.dashboardLayout, this.state.org, this.state.stageOptions,null,'all time')}>Calculate</button>
                                    </div>
                                  )}
                                </div>
                                {(this.state.showLayoutToggle) && (
                                  <div className="fixed-column-90">
                                    <p className="description-text-3 bottom-margin right-text">{(this.state.showModule2) ? "Toggle layout" : "Toggle layout"}</p>
                                    <div className="float-right">
                                      <Switch
                                        onChange={(change) => this.toggleNavMode(change)}
                                        checked={this.state.showModule2}
                                        disabled={this.state.isSaving}
                                        id="normal-switch"
                                        className="pin-right"
                                      />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}

                                <div className="clear" />
                              </div>

                              {(this.state.isLoading) ? (
                                <div className="flex-container flex-center full-space">
                                  <div>
                                    <div className="super-spacer" />

                                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                    <p className="center-text cta-color bold-text">Pulling results...</p>

                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="row-10">
                                    <div className={"container-left"}>
                                      <div className="card">
                                        <div>
                                          <div className="calc-column-offset-80">
                                            <p className="heading-text-4">Career-Seeker Funnel</p>
                                          </div>
                                          <div className="fixed-column-80">
                                            <button className="background-button full-width" onClick={(this.state.showChangeFunnelTimeframe) ? () => this.setState({ showChangeFunnelTimeframe: false }) : () => this.setState({ showChangeFunnelTimeframe: true })}>
                                              <div className="float-right top-padding-5">
                                                <div className="mini-spacer" /><div className="mini-spacer" />
                                                <img src={dropdownArrow} alt="GC" className="image-auto-6"/>
                                              </div>
                                              <div className="float-right right-padding">
                                                <p className="full-width right-text bold-text cta-color description-text-2">{this.state.timeframe}</p>
                                              </div>
                                              <div className="clear" />
                                            </button>
                                            {(this.state.showChangeFunnelTimeframe) && (
                                              <div className="menu-bottom-3 z-index-1">
                                                {this.state.timeframeOptions.map(value =>
                                                  <div key={value}>
                                                    <button className="background-button full-width right-text row-5" onClick={() => this.calculateDashboard(this.state.dashboardLayout,this.state.org, null, null, value)}>
                                                      <p className="bold-text description-text-2">{value}</p>
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div className="clear" />
                                        </div>

                                        <div className="spacer" />

                                        {(this.state.showDemo) ? (
                                          <div>
                                            {(this.state.funnelList) && (
                                              <div>
                                                {this.state.funnelList.map((value, index) =>
                                                  <div key={"funnelList|" + index} className="row-10">
                                                    <div className="row-5">
                                                      <div className="container-left">
                                                        <p className="description-text-2">{value.name}</p>
                                                      </div>
                                                      <div className="container-right">
                                                        <p className="full-width right-text description-text-2">{value.count}</p>
                                                      </div>
                                                      <div className="clear" />
                                                    </div>

                                                    <div className="progress-bar-thin" >
                                                      <div className="filler" style={{ width: Math.round((value.count / (value.count * 2)) * 100) + '%' }} />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Sign Ups</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.signupsCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.signupsCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Added a Resume</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aResumeCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aResumeCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Took an Assessment</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.anAssessmentCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.anAssessmentCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Created a Goal</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aGoalCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aGoalCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">RSVPd to an Event</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.anEventCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={(this.state.anEventCount > this.state.signupsCount) ? { width: '100%' } : { width: Math.round((this.state.anEventCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Submitted a Project</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aProjectCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aProjectCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Applied</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.anApplicationCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.anApplicationCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Feedback on Application</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aFeedbackCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aFeedbackCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Referred to Employer</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aReferralCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aReferralCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Connected for Interview</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.aConnectionCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={{ width: Math.round((this.state.aConnectionCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>

                                            <div className="row-10">
                                              <div className="row-5">
                                                <div className="container-left">
                                                  <p className="description-text-2">Hired</p>
                                                </div>
                                                <div className="container-right">
                                                  <p className="full-width right-text description-text-2">{this.state.hiredCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>

                                              <div className="progress-bar-thin" >
                                                <div className="filler" style={(this.state.hiredCount > this.state.signupsCount) ? { width: '100%'} : { width: Math.round((this.state.hiredCount / this.state.signupsCount) * 100) + '%' }} />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Top Employers by Placement</p>
                                        <div className="spacer" />
                                        {(this.state.topEmployers) && (
                                          <div>
                                            {this.state.topEmployers.map((value, index) =>
                                              <div key={value.name} className="row-7">
                                                <div className="calc-column-offset-60">
                                                  <p className="heading-text-6">{index + 1}. {value.employerName}</p>
                                                </div>
                                                <div className="fixed-column-60">
                                                  <p className="full-width right-text heading-text-6">{value.placementCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            )}
                                          </div>
                                        )}

                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Ethnicity</p>
                                        <div className="spacer" />
                                        {(this.state.ethnicityData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.ethnicityData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}

                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Opp Youth</p>
                                        <div className="spacer" />
                                        {(this.state.opportunityYouthData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.opportunityYouthData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Gender</p>
                                        <div className="spacer" />
                                        {(this.state.genderData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.genderData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Age</p>
                                        <div className="spacer" />
                                        {(this.state.ageData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.ageData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Education Type</p>
                                        <div className="spacer" />
                                        {(this.state.educationData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.educationData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Majors</p>
                                        <div className="spacer" />
                                        {(this.state.majorData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.majorData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Skills - Highest Avg Score</p>
                                        <div className="spacer" />
                                        {(this.state.skillsData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.skillsData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Top Personalities</p>
                                        <div className="spacer" />
                                        {(this.state.personalityData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.personalityData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Interests - Highest Scores</p>
                                        <div className="spacer" />
                                        {(this.state.interestsData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.interestsData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Top Values</p>
                                        <div className="spacer" />
                                        {(this.state.valuesData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.valuesData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Top Functions of Interests</p>
                                        <div className="spacer" />
                                        {(this.state.functionsOfInterestData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.functionsOfInterestData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Top Industries of Interest</p>
                                        <div className="spacer" />
                                        {(this.state.industriesOfInterestData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.industriesOfInterestData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Top Job Posting Functions</p>
                                        <div className="spacer" />
                                        {(this.state.functionsData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.functionsData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Top Job Posting Industries</p>
                                        <div className="spacer" />
                                        {(this.state.industriesData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.industriesData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Career-Seeker Net Promoter Score (NPS)</p>
                                      </div>
                                    </div>
                                    <div className="container-right">
                                      <div className="card">
                                        <p className="heading-text-4">Employer Net Promoter Score (NPS)</p>
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="row-10">
                                    <div className="container-left">
                                      <div className="card">
                                        <p className="heading-text-4">Top Career-Seeker Referrers</p>
                                        <div className="spacer" />
                                        {/*
                                        {(this.state.sourceData) && (
                                          <div className="full-width">
                                            <Doughnut
                                              data={this.state.sourceData}
                                              options={this.state.chartOptions}
                                            />
                                          </div>
                                        )}*/}

                                        {(this.state.topReferrers) && (
                                          <div>
                                            {this.state.topReferrers.map((value, index) =>
                                              <div key={value.name} className="row-7">
                                                <div className="calc-column-offset-60">
                                                  <p className="heading-text-6">{index + 1}. {value.name}</p>
                                                </div>
                                                <div className="fixed-column-60">
                                                  <p className="full-width right-text heading-text-6">{value.eCount}</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.dashboardLayout === "Mentor") && (
                            <div>
                              <div>
                                <div className="row-10 relative-position">
                                  <div className="calc-column-offset-180">
                                    <p className="heading-text-2">{(this.state.filteredMembers) ? this.state.filteredMembers.length : "0"} Members</p>
                                    {/*
                                    {(this.state.dashFilters) && (
                                      <div>
                                        {this.state.dashFilters.map((value, index) =>
                                          <div key={"filter" + index} className="top-margin-5">
                                            <button className={(value.checked) ? "float-left tag-container-basic right-margin description-text-3 left-text primary-background" : "float-left tag-container-basic right-margin description-text-3 left-text"} onClick={() => this.filterValues(value.name)}>
                                              <p>{value.name}</p>
                                            </button>
                                          </div>
                                        )}
                                        <div className="clear" />
                                      </div>
                                    )}*/}
                                  </div>

                                  {(this.state.showLayoutToggle) ? (
                                    <div className="fixed-column-90">
                                      <p className="description-text-3 bottom-margin right-text">{(this.state.showModule2) ? "Toggle layout" : "Toggle layout"}</p>
                                      <div className="float-right">
                                        <Switch
                                          onChange={(change) => this.toggleNavMode(change)}
                                          checked={this.state.showModule2}
                                          disabled={this.state.isSaving}
                                          id="normal-switch"
                                          className="pin-right"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="fixed-column-90 height-40" />
                                  )}

                                  <div className="fixed-column-90 flex-container align-end">
                                    <button className={(this.state.showFilters) ? "btn btn-small medium-shadow pin-right" : "btn btn-small white-background cta-color medium-shadow pin-right"} onClick={(this.state.showFilters) ? () => this.setState({ showFilters: false }) : () => this.setState({ showFilters: true })}>Filter</button>
                                  </div>
                                  <div className="clear" />

                                  {(this.state.showFilters) && (
                                    <div className="absolute-position right-0 top-0 card-clear-padding padding-10 top-margin-50 description-text-3">
                                      {/*
                                      <div>
                                        <div className="calc-column-offset-20">
                                          <p className="heading-text-5">Add Filters</p>
                                        </div>

                                        <div className="fixed-column-20">
                                          <button className="background-button full-width" onClick={() => this.addItem('filter')}>
                                            <img className="image-auto-15 pin-right" alt="GC" src={addIconBlue} />
                                          </button>
                                        </div>
                                        <div className="clear" />
                                      </div>*/}

                                      {(this.state.dashFilters) && (
                                        <div>
                                          <button className="background-button full-width right-text row-5 cta-color" onClick={() => this.filterValues("Clear")}>
                                            <p>Clear</p>
                                          </button>

                                          {this.state.dashFilters.map((value, index) =>
                                            <div key={"filter" + index} className="row-5">
                                              {/*
                                              <div className="fixed-column-30">
                                                <button className="background-button" onClick={() => console.log('')}>
                                                  <img className="" alt="GC" src={deniedIcon} />
                                                </button>
                                              </div>*/}
                                              <div className="calc-column-offset-30">
                                                <p>{value.name}</p>
                                              </div>
                                              <div className="fixed-column-30">
                                                <button className="background-button float-right" onClick={() => this.filterValues(value.name)}>
                                                  <img className="image-auto-15" alt="GC" src={(value.checked) ? checkboxChecked : checkboxEmptyIcon} />
                                                </button>
                                                <div className="clear" />
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>

                                <div className="card-clear-padding">
                                  <div className="selected-background-light description-text-3 bold-text horizontal-padding-4">
                                    <div className="calc-column-offset-165">
                                      <div className="relative-column-25">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('firstName',this.state.members)}>
                                          <p>Name</p>
                                        </button>
                                      </div>
                                      <div className="relative-column-30">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('email',this.state.members)}>
                                          <p>Email</p>
                                        </button>
                                      </div>
                                      <div className="relative-column-20">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('roleName',this.state.members)}>
                                          <p>Role Name</p>
                                        </button>
                                      </div>
                                      <div className="relative-column-25">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('pair',this.state.members)}>
                                          <p>Pair</p>
                                        </button>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                    <div className="fixed-column-165">
                                      <div className="fixed-column-55">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('enrollmentStatus',this.state.members)}>
                                          <p>Status</p>
                                        </button>
                                      </div>
                                      <div className="fixed-column-55">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('submissionCount',this.state.members)}>
                                          <p># Sub</p>
                                        </button>
                                      </div>
                                      <div className="fixed-column-55">
                                        <button className="background-button full-width left-text row-10" onClick={() => this.sortValues('avgRating',this.state.members)}>
                                          <p>Rating</p>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <hr />

                                  {(this.state.filteredMembers) && (
                                    <div>
                                      {this.state.filteredMembers.map((value, index) =>
                                        <div key={value.email}>
                                          <div className="padding-10 description-text-3">
                                            <div className="calc-column-offset-165">
                                              <div className="relative-column-25">
                                                <Link to={'/organizations/' + this.state.org + '/members/' + value.username} className="background-button">
                                                  <p>{value.firstName} {value.lastName}</p>
                                                </Link>
                                              </div>
                                              <div className="relative-column-30">
                                                <p>{value.email}</p>
                                              </div>
                                              <div className="relative-column-20">
                                                <p>{this.translateText('roleName',value.roleName)}</p>
                                              </div>
                                              <div className="relative-column-25">
                                                {(value.pair) ? (
                                                  <Link to={'/organizations/' + this.state.org + '/members/' + value.pair.username} className="background-button">
                                                    <p>{value.pair.firstName + " " + value.pair.lastName}</p>
                                                  </Link>
                                                ) : (
                                                  <p>-</p>
                                                )}
                                              </div>
                                              <div className="clear" />
                                            </div>
                                            <div className="fixed-column-165">
                                              <div className="fixed-column-55">
                                                <p>{(value.enrollmentStatus) ? value.enrollmentStatus : "-"}</p>
                                              </div>
                                              <div className="fixed-column-55">
                                                {(value.submissionCount) ? (
                                                  <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showSubmissions: true, selectedMember: value })}>
                                                    <p>{value.submissionCount}</p>
                                                  </button>
                                                ) : (
                                                  <p>-</p>
                                                )}
                                              </div>
                                              <div className="fixed-column-55">
                                                {(value.avgRating) ? (
                                                  <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showSubmissions: true, selectedMember: value })}>
                                                    <p>{value.avgRating}</p>
                                                  </button>
                                                ) : (
                                                  <p>-</p>
                                                )}
                                              </div>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
              </div>

              <div className="clear" />

              {(this.state.org) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo,this.state.passedCommand,null,this.state.openUpAssistant)}
                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
               <div key="showModalInDashboard" className="full-width padding-40">

                 {(this.state.showSubmissions) && (
                   <div>
                     <div className="calc-column-offset-30">
                       <p className="heading-text-2">{this.state.selectedMember.firstName} {this.state.selectedMember.lastName} Submissions</p>
                     </div>
                     <div className="fixed-column-30 top-margin">
                       <button className="background-button" onClick={() => this.closeModal()}>
                         <img src={closeIcon} alt="GC" className="image-auto-20" />
                       </button>
                     </div>
                     <div className="clear" />

                    <div className="spacer" /><div className="spacer" />

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                    {this.state.selectedMember.submissions.map((value, index) =>
                      <div key={value.name} className="row-3">
                        <div className="card standard-border">
                          <div className="row-10">
                            <div className="calc-column-offset-30">
                              <p className="heading-text-5">{index + 1}. {(value.assessmentName) ? value.assessmentName : "Submission"}</p>
                              <p className="description-text-2 top-margin">Submitted {convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                            </div>
                            <div className="fixed-column-30 top-margin-5">
                              <button className="background-button" onClick={() => this.toggleConfirmDelete(index, false)}>
                                <img src={xIcon} alt="GC" className="image-auto-20" />
                              </button>
                            </div>
                            <div className="clear" />
                          </div>

                          {(value.confirmDelete) && (
                            <div className="row-10">
                              <p className='error-color'>Are you sure you want to delete?</p>
                              <div className="row-5">
                                <button className="btn btn-squarish error-background-color error-border-color right-margin" onClick={() => this.toggleConfirmDelete(index, true)}>Delete Permanently</button>
                                <button className="btn btn-squarish white-background cta-color" onClick={() => this.toggleConfirmDelete(index, false)}>Cancel</button>
                              </div>
                            </div>
                          )}

                          {(value.answers) && (
                            <div>
                              {value.answers.map((value2, index2) =>
                                <div key={value2.question} className="top-margin">
                                  <div className="row-5">
                                    <p className="description-text-3 description-text-color bold-text">{index2 + 1}. {value2.question}</p>
                                  </div>
                                  <div className="row-5">
                                    <p className="description-text-1">{value2.answer}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="spacer" /><div className="spacer" />
                        {/*
                        <hr />*/}
                        <div className="spacer" />
                      </div>
                    )}
                   </div>
                 )}

                 {(this.state.showChangePassword) && (
                   <div key="showChangePassword" className="full-width padding-20">
                      <SubChangePassword history={this.props.navigate} email={this.state.emailId}
                        modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}
                      />
                    </div>
                 )}
               </div>
             </Modal>

          </div>

      )
    }
}

export default withRouter(OrgDashboard)
