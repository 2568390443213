import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
// import { Link } from 'react-router-dom';
import Axios from 'axios';
import SubProjects from '../components/Subcomponents/Projects';
import withRouter from '../components/Functions/WithRouter';

class ProjectsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        orgCode: null
      }
    }

    componentDidMount() {
      //see if user is logged in
      console.log('projects page just mounted')
      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "#fff";

      let { orgCode, accountCode, id } = this.props.params

      let noOrgCode = false
      if (!orgCode) {
        noOrgCode = true
      }

      this.setState({ orgCode, noOrgCode, accountCode, id, ready: true })

      if (orgCode) {
        Axios.get('/api/org', { params: { orgCode } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const orgLogo = response.data.orgInfo.webLogoURIColor
              this.setState({ orgName, orgLogo });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    render() {

        return (
          <div>
            <TopNavigation currentPage={"projectsPage"} darkBackground={true} activeOrg={this.state.orgCode}/>

            <section className="section-features">
              <div className="row">
                <SubProjects history={this.props.navigate} activeOrg={this.state.orgCode} noOrgCode={this.state.noOrgCode} pageSource="landingPage" passedId={this.state.id} />
              </div>
            </section>

            <Footer history={this.props.navigate} />
          </div>
        )
    }
}

export default withRouter(ProjectsPage);
