import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from 'react-modal';

import {convertDateToString} from '../Functions/ConvertDateToString';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const thumbsUpIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-icon.png';
const thumbsUpBlueIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-up-blue-icon.png';
const thumbsDownIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-down-icon.png';
const thumbsDownOrangeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/thumbs-down-orange-icon.png';
// const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const feedbackIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/feedback-icon-dark.png';
const feedbackIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/feedback-icon-blue.png';

class RenderSuggestion extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.renderSuggestion = this.renderSuggestion.bind(this)
      this.saveSuggestion = this.saveSuggestion.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.formChangeHandler = this.formChangeHandler.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderSuggestion', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderSuggestion')

      const suggestions = this.props.suggestions
      const selectedSuggestion = this.props.selectedSuggestion

      this.setState({ suggestions, selectedSuggestion })

    }

    saveSuggestion(selectedSuggestion,closeTheModal) {
      console.log('saveSuggestion called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const _id = selectedSuggestion._id
      const liked = selectedSuggestion.liked
      const disliked = selectedSuggestion.disliked
      const feedback = selectedSuggestion.feedback

      Axios.post('/api/suggestions', {
        _id, liked, disliked, feedback
      })
      .then((response) => {
        console.log('attempting to save addition to suggestion')
        if (response.data.success) {
          console.log('saved suggestion', response.data)

          this.setState({ isSaving: false, successMessage: 'Successfully saved feedback'})
          if (closeTheModal) {
            this.props.closeModal()
          }

        } else {
          console.log('did not save successfully')
          this.setState({ isSaving: false, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'there was an error saving suggestion', isSaving: false})
      });
    }

    itemClicked(item, optionIndex,direction) {
      console.log('itemClicked called')

      let suggestions = this.state.suggestions
      if (direction === 'up') {
        if (suggestions[optionIndex].liked) {
          suggestions[optionIndex]['liked'] = false
        } else {
          suggestions[optionIndex]['liked'] = true
          suggestions[optionIndex]['disliked'] = false
        }
      } else {
        if (suggestions[optionIndex].disliked) {
          suggestions[optionIndex]['disliked'] = false
        } else {
          suggestions[optionIndex]['disliked'] = true
          suggestions[optionIndex]['liked'] = false
        }
      }

      this.setState({ suggestions })
      this.saveSuggestion(item,false)

    }

    closeModal() {
      console.log('closeModal called in SubEditLog')

      this.setState({ modalIsOpen: false,
        showFeedback: false, selectedSuggestion: null, selectedIndex: null,showSuggestions: false
      })
      if (this.props.closeModal) {
        this.props.closeModal()
      }
    }


    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'feedback') {
        let selectedSuggestion = this.state.selectedSuggestion
        selectedSuggestion['feedback'] = event.target.value
        this.setState({ selectedSuggestion })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    renderSuggestion(item,optionIndex,inModal,sideNav) {
      console.log('renderSuggestion called', item, inModal)

      let cardClass = "relative-column-50 bottom-margin-50 standard-border padding-30"
      if (inModal) {
        cardClass = "full-width bottom-margin standard-border padding-30"
      } else if (sideNav) {
        cardClass = "full-width bottom-margin"
      }

      return (
        <div key="renderSuggestion">
          <div className={cardClass}>
            <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showSuggestions: true, selectedSuggestion: item })}>
              <div>
                <div className="fixed-column-50">
                  <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                </div>
                <div className="calc-column-offset-50">
                  <p className="heading-text-5">{item.senderFirstName} {item.senderLastName}</p>
                  <p className="description-text-3 description-text-color">{convertDateToString(item.createdAt,"daysAgo")}</p>
                </div>

                <div className="clear" />

                <ReactTooltip id="tooltip-placeholder-id" />

                <p className="top-margin description-text-1"><label className="description-text-color">{item.senderFirstName} says:</label> {item.message}</p>
              </div>
            </button>

            {(!sideNav) && (
              <div>
                {(item.selectedPeople && item.selectedPeople.length > 0) && (
                  <div className="top-margin-20">
                    <p className="heading-text-6 row-10">Suggested Contacts</p>

                    {item.selectedPeople.map((item2, optionIndex2) =>
                      <div key={item2}>
                        <p className="description-text-2 bold-text">{optionIndex2 + 1}. {item2.firstName} {item2.lastName} ({item2.email}) [{item2.relationship}] - {item2.reason}</p>
                      </div>
                    )}
                  </div>
                )}

                {(item.selectedLinks && item.selectedLinks.length > 0) && (
                  <div className="top-margin-20">
                    <p className="heading-text-6 row-10">Links to Resources</p>

                    {item.selectedLinks.map((item2, optionIndex2) =>
                      <div key={item2}>
                        <p className="description-text-2 bold-text">{optionIndex2 + 1}. <a href={item2.url} target="_blank" rel="noopener noreferrer">{item2.url}</a> ({item2.category})</p>
                      </div>
                    )}
                  </div>
                )}

                {(item.selectedTimes && item.selectedTimes.length > 0) && (
                  <div className="top-margin-20">
                    <p className="heading-text-6 row-10">Times to Chat / Connect</p>

                    {item.selectedTimes.map((item2, optionIndex2) =>
                      <div key={item2}>
                        <p className="description-text-2 bold-text">{optionIndex2 + 1}. {convertDateToString(new Date(item2.time),"datetime-2")}</p>
                      </div>
                    )}
                  </div>
                )}

                {(item.selectedProjects && item.selectedProjects.length > 0) && (
                  <div className="top-margin-20">
                    <p className="heading-text-6 row-10">Suggested Projects to Work On</p>

                    {item.selectedProjects.map((item2, optionIndex2) =>
                      <div key={item2}>
                        <p className="description-text-2 bold-text">{optionIndex2 + 1}. {item2.name}: {item2.description}</p>
                      </div>
                    )}
                  </div>
                )}

                {(item.selectedCareers && item.selectedCareers.length > 0) && (
                  <div className="top-margin-20">
                    <p className="heading-text-6 row-10">Suggested Careers to Pursue</p>

                    {item.selectedCareers.map((item2, optionIndex2) =>
                      <div key={item2}>
                        <p className="description-text-2 bold-text">{optionIndex2 + 1}. {item2}</p>
                      </div>
                    )}
                  </div>
                )}

                <div className="top-margin-20">
                  <hr />

                  <div className="top-margin display-inline">
                    <button className="background-button float-left" onClick={() => this.itemClicked(item,optionIndex,'up')}>
                      <img src={(item.liked) ? thumbsUpBlueIcon : thumbsUpIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Like this suggestion" />
                    </button>
                    <button className="background-button left-margin-15 float-left" onClick={() => this.itemClicked(item,optionIndex,'down')}>
                      <img src={(item.disliked) ? thumbsDownOrangeIcon : thumbsDownIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Dislike this suggestion" />
                    </button>

                    <button className="background-button left-margin-15 float-left" onClick={(this.state.showFeedback) ? () => this.setState({ showFeedback: false, selectedSuggestion: null, selectedIndex: null }) : () => this.setState({ showFeedback: true, selectedSuggestion: item, selectedIndex: optionIndex })}>
                      <img src={(item.feedback) ? feedbackIconBlue : feedbackIconDark} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Give feedback on this suggestion" />
                    </button>
                    <div className="clear" />
                  </div>
                </div>

                {(this.state.showFeedback) && (
                  <div>
                    <div className="row-10">
                      <textarea className="text-field" type="text" placeholder="How were you able to use their suggstion(s)/resource(s)?" name="feedback" value={this.state.selectedSuggestion.feedback} onChange={this.formChangeHandler} />
                    </div>

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                    {(this.state.selectedSuggestion.feedback) && (
                      <div className="top-margin-20">
                        <button className="btn btn-primary" onClick={() => this.saveSuggestion(this.state.selectedSuggestion,true)}>Send Feedback</button>
                      </div>
                    )}
                  </div>
                )}

              </div>
            )}

          </div>

          {(sideNav) ? (
            <div>
              <div className="clear" />
            </div>
          ) : (
            <div>
              {(optionIndex % 3 === 2) && (
                <div className="clear" />
              )}
            </div>
          )}

        </div>
      )
    }

    render() {

      return (
        <div>
          {this.renderSuggestion(this.props.suggestion,this.props.index,this.props.inModal,this.props.sideNav)}

          <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

              {(this.state.showSuggestions) && (
                <div key="showPost" className="full-width padding-20">
                  {(this.state.selectedSuggestion) ? (
                    <div>
                      {this.renderSuggestion(this.state.selectedSuggestion,null,true,null)}
                    </div>
                  ) : (
                    <div>
                      {this.state.suggestions.map((item, optionIndex) =>
                        <div key={item}>
                          {this.renderSuggestion(item,optionIndex,true,null)}
                        </div>
                      )}
                    </div>
                  )}

                  <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>
              )}

          </Modal>
        </div>
      )
    }
}

export default RenderSuggestion;
