import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubEditPipeline from '../Common/EditPipeline';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const pipelineIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pipeline-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Pipelines extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            pipelines: [],
            filteredPipelines: [],
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchPipelines = this.searchPipelines.bind(this)
        this.renderPipelines = this.renderPipelines.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.deleteClicked = this.deleteClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.accountCode)

      const org = this.props.activeOrg
      let accountCode = this.props.accountCode

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');

      this.setState({ org, accountCode, emailId: email, username, orgFocus, isLoading: true })

      const pipelineQueryParams = { accountCode }
      Axios.get('/api/pipelines', { params: pipelineQueryParams })
      .then((response) => {
        console.log('Pipelines query within sub attempted', response.data);

        if (response.data.success) {
          console.log('pipeline query worked')

          if (response.data.pipelines.length > 0) {
            //jobs = response.data.postings
            // console.log('set the benchmark to state')
            const pipelines = response.data.pipelines
            const filteredPipelines = pipelines

            this.setState({ pipelines, filteredPipelines, isLoading: false });
          } else {
            this.setState({ isLoading: false })
          }

        } else {
          console.log('pipeline query did not work', response.data.message)
          this.setState({ isLoading: false })
        }

      }).catch((error) => {
          console.log('Pipeline query did not work for some reason', error);
          this.setState({ isLoading: false })
      });

      Axios.get('/api/account', { params: { accountCode } })
      .then((response) => {
        console.log('Account info query attempted in employer dashboard', response.data);

        if (response.data.success) {
          console.log('account info query worked in sub settings')

          const employerName = response.data.accountInfo.employerName
          const employerLogo = response.data.accountInfo.employerLogoURI
          const accountCode = response.data.accountInfo.accountCode
          const rating = response.data.accountInfo.rating
          const departments = response.data.accountInfo.departments
          const tempJobFunction = 'Marketing'

          this.setState({ employerName, employerLogo, accountCode, jobFunction: tempJobFunction, rating, departments });

        }

      }).catch((error) => {
        console.log('Account info query did not work for some reason', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchString') {
        this.setState({ [event.target.name]: event.target.value, isLoading: true })
        this.searchPipelines(event.target.value)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchPipelines(searchString) {
      console.log('searchPipelines called ', searchString)

      let pipelineQueryParams = { searchString, accountCode: this.state.accountCode }

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        Axios.get('/api/pipelines/search', { params: pipelineQueryParams })
        .then((response) => {
          console.log('Pipelines search query attempted', response.data);

            if (response.data.success) {
              console.log('pipeline search query worked')

              const filteredPipelines = response.data.pipelines
              self.setState({ filteredPipelines, isLoading: false });

            } else {
              console.log('pipeline search query did not work', response.data.message)
              self.setState({ filteredPipelines: this.state.pipelines, isLoading: false })
            }

        }).catch((error) => {
            console.log('Pipelines search query did not work for some reason', error);
            self.setState({ isLoading: false, errorMessage: error.toString() })
        });

      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    renderPipelines() {
      console.log('renderPipelines called')

      let rows = [];

      if (this.state.isLoading) {
        console.log('it is loading')

        rows.push(
          <div key="loadingPipelines">
            <div>
              <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
              <div className="spacer" /><div className="spacer" /><div className="spacer" />
              <p className="center-text cta-color bold-text">Pulling results...</p>

            </div>
          </div>
        )
      } else {
        if ( this.state.filteredPipelines && this.state.filteredPipelines.length > 0 ) {

          for (let i = 1; i <= this.state.filteredPipelines.length; i++) {
            // console.log(i);
            const index = i - 1
            const title = this.state.filteredPipelines[i - 1].name
            const subtitle = this.state.filteredPipelines[i - 1].pathway

            let pathname = '/employers/' + this.props.accountCode + '/pipelines/' + this.state.filteredPipelines[i - 1]._id

            rows.push(
              <div key={i} >
                <Link className="secret-link" to={pathname} state={{ selectedTemplate: this.state.filteredPipelines[i - 1] }}>
                  <span className="fixed-column-60">
                    <div className="spacer" />
                    <img src={(this.state.employerLogo) ? this.state.employerLogo : pipelineIconDark} alt="GC" className="image-auto-50 center-horizontally"/>
                  </span>
                  <span className="calc-column-offset-120 left-padding-20">
                    <div className="spacer" />
                    <p className="heading-text-5">{title}</p>
                    <p className="description-text-2 top-padding-5">{subtitle}</p>
                  </span>

                  <span className="fixed-column-30 top-margin right-text">
                    <div className="mini-spacer" /><div className="mini-spacer" />
                    <button className="background-button" onClick={(e) => this.deleteClicked(e, index)}>
                      <img src={deleteIconDark} alt="GC" className="image-auto-18 pin-right"/>
                    </button>
                  </span>
                  <span className="fixed-column-30 top-margin">
                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right"/>
                  </span>
                  <div className="clear" />
                </Link>

                {(this.state.confirmDelete && this.state.confirmDeleteIndex === index) && (
                  <div>
                    <div className="full-width right-text">
                      <p className="bottom-margin error-color description-text-3">Are you sure you want to delete this pipeline?</p>
                      <button className="btn-squarish error-background-color clear-border" onClick={() => this.deleteItem(index)}>
                        Confirm
                      </button>
                      <button className="btn-squarish white-background cta-color left-margin" onClick={() => this.setState({ confirmDelete: false })}>
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                <hr />
                <div className="spacer" /><div className="spacer" />
              </div>
            )

          }

        } else {
          //show empty state screen

        }

      }

      return rows;
    }

    deleteClicked(e, index) {
      console.log('deleteClicked called', e, index)

      e.stopPropagation()
      e.preventDefault()

      this.setState({ confirmDelete: true, confirmDeleteIndex: index})
    }

    deleteItem(index) {
      console.log('deleteItem clicked', index)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      const _id = this.state.filteredPipelines[index]._id

      // delete endorsements attached to profile
      Axios.delete('/api/pipelines/' + _id)
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Pipeline delete worked');

          let pipelines = []
          for (let i = 1; i <= this.state.pipelines.length; i++) {
            const tempIndex = i - 1
            if (index !== tempIndex) {
              pipelines.push(this.state.pipelines[i - 1])
            }
          }

          const filteredPipelines = pipelines
          const successMessage = response.data.message

          this.setState({ isLoading: false, pipelines, filteredPipelines, successMessage, confirmDelete: false })

        } else {
          console.error('there was an error deleting the endorsement');
          this.setState({ isLoading: false, errorMessage: response.data.message })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({ isLoading: false, errorMessage: error })
      });
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showAddPipeline: false })
    }

    render() {

      let cardClass = "white-background width-70-percent vertical-margin-2-percent padding-40 medium-shadow max-width-1400 center-horizontally"
      let cardStyle = {}
      let searchClass = "max-width-1400 width-70-percent center-horizontally"
      let searchStyle = {}
      if (window.location.pathname.includes('/walkthrough')) {
        cardClass = ""
      }

      let mainWidth = "calc-column-offset-80"

      return (
          <div>
            {(!window.location.pathname.includes('/walkthrough')) && (
              <div>
                <div className={cardClass} style={cardStyle}>
                  {(window.location.pathname === '/benchmarks') ? (
                    <div>
                      {/*
                      <p className="heading-text-2">Benchmarks</p>
                      <p className="description-text-1">Benchmarks show the ideal candidate profile specific employers and programs desire. When career-seekers apply through Guided Compass, or if a workforce/education professional recommends a candidate through Guided Compass, our algorithm will score candidates according to these benchmarks (i.e., criteria). Inversely, benchmarks are used to recommend work opportunities, events, projects, and career paths to career-seekers. Employers are not required to use this criteria when scoring candidates internally. <Link to="/help/what-are-benchmarks" target="_blank" className="bold-text">This video</Link> explains benchmarks.</p>*/}
                    </div>
                  ) : (
                    <div>
                      <div className={mainWidth}>
                        <p className="heading-text-2">{(this.state.filteredPipelines && this.state.filteredPipelines.length > 0) && this.state.filteredPipelines.length + " "}Talent Pipelines</p>
                      </div>

                      <div className="fixed-column-40 top-padding-5">
                        <button className="background-button" onClick={(this.state.showSearchBar) ? () => this.setState({ showSearchBar: false }) : () => this.setState({ showSearchBar: true })}>
                          <img src={searchIcon} alt="Compass add icon" className="image-auto-25"/>
                        </button>
                      </div>
                      <div className="fixed-column-40 top-padding-5">
                        <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showAddPipeline: true })}>
                          <img src={addIcon} alt="Compass add icon" className="add-icon"/>
                        </button>
                      </div>

                      <div className="clear" />

                      <div>
                        <p className="description-text-color top-padding">Talent pipelines allows you to nurture talent across each of your departments.</p>
                      </div>

                    </div>
                  )}
                </div>
              </div>
            )}

            {(this.state.showSearchBar) && (
              <div className="bottom-padding-40">
                <div className={searchClass} style={searchStyle}>
                  <div className="filter-field-search full-width white-background clear-margin">
                    <div className="search-icon-container">
                      <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5" />
                    </div>
                    <div className="filter-search-container calc-column-offset-100-static">
                      <input type="text" className="text-field clear-border" placeholder="Search..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color row-5 max-width-1400 width-70-percent center-horizontally">{this.state.successMessage}</p>}
            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color row-5 max-width-1400 width-70-percent center-horizontall">{this.state.errorMessage}</p>}

            <div className={cardClass} style={cardStyle}>
              {this.renderPipelines()}
            </div>
            <div className="clear"/>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >

            {(this.state.showAddPipeline) && (
              <div key="showAddPipeline" className="full-width padding-20">
                 <SubEditPipeline selectedPipeline={null} history={this.props.navigate} closeModal={this.closeModal}
                   accountCode={this.state.accountCode} employerLogoURI={this.state.employerLogo}
                   employerName={this.state.employerName} jobFunction={this.state.jobFunction}
                   pathway={this.state.pathway} departmentOptions={this.state.departments}
                 />
               </div>
            )}

           </Modal>
          </div>

      )
    }
}

export default withRouter(Pipelines);
