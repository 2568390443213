import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';

class WorkshopsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
        // this.ref1 = React.createRef()
    }

    componentDidMount() {
        //see if user is logged in
        console.log('jobs page just mounted')
        window.scrollTo(0, 0)

    }

    scrollToSection(index) {
      console.log('scrollToSection called', index)

      const id = 'ref' + index.toString();
      const yOffset = -100;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"workshopsPage"} darkBackground={true} changeSubNavSelected={this.changeSubNavSelected}/>

            <section className="section-features white-background">
              <div className="row">
                <h2>Monthly Guided Compass Workshops</h2>
                <div className="spacer" /><div className="spacer" />
              </div>

              <div className="row">
                <p className="heading-text-6 center-text">Monthly Guided Compass Workshops are meant to provide helpful tips to career-seekers as well show career-seekers how to utilize the Guided Compass platform. Workshops will take place on the <label className="bold-text">first Tuesday of each month at 5pm PST</label>, and will be moderated by the founder of Guided Compass, <a href="https://www.linkedin.com/in/creightontaylor/" target="_blank" rel="noopener noreferrer">Creighton Taylor</a></p>
              </div>

              <div className="row top-margin-30">
                <div className="container-left primary-background-light padding-30">
                  <p className="heading-text-3">Fall</p>
                  <div className="top-padding-20">
                    <button className="background-button left-text" onClick={() => this.scrollToSection(0)}>
                      <p className="heading-text-6"><label className="bold-text">September:</label> Building Knowledge Through Guided Compass</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(1)}>
                      <p className="heading-text-6"><label className="bold-text">October:</label> Build and Showcase Projects Like a Bootstrapped Entrepreneur</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(2)}>
                      <p className="heading-text-6"><label className="bold-text">November:</label> Future-Proofing Skills and Analyzing Labor Market Trends</p>
                    </button>
                  </div>
                </div>
                <div className="container-right secondary-background-light padding-30">
                  <p className="heading-text-3">Winter</p>
                  <div className="top-padding-20">
                    <button className="background-button left-text" onClick={() => this.scrollToSection(4)}>
                      <p className="heading-text-6"><label className="bold-text">December:</label> How to Impress Employers with Entrepreneurial Projects</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(5)}>
                      <p className="heading-text-6"><label className="bold-text">January:</label> How Guided Compass Helps You Build Personalized Career Roadmaps</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(6)}>
                      <p className="heading-text-6"><label className="bold-text">February:</label> Preparing for and Applying to Work Opportunities</p>
                    </button>
                  </div>
                </div>
                <div className="clear" />

                <div className="container-left senary-background-light padding-30">
                  <p className="heading-text-3">Spring</p>
                  <div className="top-padding-20">
                    <button className="background-button left-text" onClick={() => this.scrollToSection(7)}>
                      <p className="heading-text-6"><label className="bold-text">March:</label> Exploring and Spotlighting Employers</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(8)}>
                      <p className="heading-text-6"><label className="bold-text">April:</label> Social Capital: Leveraging your Guided Compass Community</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(9)}>
                      <p className="heading-text-6"><label className="bold-text">May:</label> Nailing Interviews and Negotiating Offers</p>
                    </button>
                  </div>
                </div>
                <div className="container-right septary-background-light padding-30">
                  <p className="heading-text-3">Summer</p>
                  <div className="top-padding-20">
                    <button className="background-button left-text" onClick={() => this.scrollToSection(10)}>
                      <p className="heading-text-6"><label className="bold-text">June:</label> Impressing Employers on the Job</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(11)}>
                      <p className="heading-text-6"><label className="bold-text">July:</label> Exploring Career Paths</p>
                    </button>
                    <button className="background-button left-text top-margin" onClick={() => this.scrollToSection(12)}>
                      <p className="heading-text-6"><label className="bold-text">August:</label> Setting Goals, Making Plans, and Avoiding FOMO</p>
                    </button>
                  </div>
                </div>
                <div className="clear" />
              </div>

              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref1'><label className="bold-text">September:</label> Building Knowledge Through Guided Compass</p>
                <p className="top-padding-5 heading-text-6 description-text-color">September 6, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">Given your goals and profile, Guided Compass and programs can recommend courses and projects to fill your skill gaps. Need to get better at wireframing? Bam! Wireframed! Need up the collaboration points? Whoosh! Collaborated! We’ve got you covered. Guided Compass integrated with Udemy and Coursera, covering more than 12,000 competency-based courses. Track your progress and knowledge in Guided Compass so that you get extra points when you apply to opportunities.</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/616643dbf69bcfcb8c5187a7/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref2'><label className="bold-text">October:</label> Build and Showcase Projects Like a Bootstrapped Entrepreneur</p>
                <p className="top-padding-5 heading-text-6 description-text-color">October 4, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This workshop is for people looking to build out projects like a business. If you act like every project is your “bootstrapped” business, you will need to provide its value by selling it to customers / users. People will not utilize / watch / purchase your product / service unless it provides value to them. “Bootstrapping” refers to when entrepreneurs elect not to raise outside funding to fund their business, so they need to acquire their customers from the ground up. This is a great mindset because it allows you to define the right metrics around your projects, build the right team around your projects, and make progress effectively. When you do seek employment, consider pitching the project like you’re finally looking to raise funding from them. For instance: “After 1 year of building this sports game for Gen Z, we finally got 1,000 people playing the games for a collective 10,000 hours last month.” Through this entrepreneurial lens, you will be attractive to employers when you no longer need them. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61664408f69bcfcb8c5187a8/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref3'><label className="bold-text">November:</label> Future-Proofing Skills and Analyzing Labor Market Trends</p>
                <p className="top-padding-5 heading-text-6 description-text-color">November 2, 2021 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This 1-hour session will go over how to think about the Future of Work. How to glean trend insights from <a href="https://blog.wealthfront.com/career-launching-companies-list/" target="_blank" rel="noopener noreferrer">Wealthfront’s Career Launching Companies List</a>, <a href="https://www.mckinsey.com/featured-insights/future-of-work/skill-shift-automation-and-the-future-of-the-workforce" target="_blank" rel="noopener noreferrer">McKinsey’s Skill Shift Report</a>, <a href="https://www.weforum.org/reports/the-future-of-jobs-report-2020" target="_blank" rel="noopener noreferrer">WEF’s Future of Jobs Report</a>, and <a href="https://www.linkedin.com/pulse/linkedin-top-startups-2021-50-us-companies-rise-linkedin-news/" target="_blank" rel="noopener noreferrer">LinkedIn’s Top Startups List</a>. Additionally, we will look at some of the largest social problems via <a href="https://www.weforum.org/reports/the-global-risks-report-2021" target="_blank" rel="noopener noreferrer">The WEF’s Global Risks Report</a>. We also look into how to think about businesses as a solution to human needs and wants, and how this typically evolves over time. These trends should inform how you build your skills, which employers you target, and what kinds of businesses you create. In Guided Compass, there is a trends area that you can subscribe to. We will be constantly looking for ways to bring simple, concise insights to you. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/616639455f46880294c116b5/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref4'><label className="bold-text">December:</label> How to Impress Employers with Entrepreneurial Projects</p>
                <p className="top-padding-5 heading-text-6 description-text-color">December 7, 2021 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">You need experience to get experience. In this workshop, we’ll cover how to showcase projects that simulate entry-level experience. Anyone else notice that working from home is kind of like being an entrepreneur? You need to take initiative, be resourceful, and manage your time effectively. In addition, most teams are project-based. You need to treat your projects like a business. How many people tested and loved that design prototype you created? How many views did that science project get on YouTube? How much money did you earn selling Lemonade at that Lemonade Stand? Think about the role you want, determine how success is defined in that role, and then back into a project that delivers on those metrics. Picking the right project is also important, so we’ll cover how to determine what project to work on. Since you typically want to be a specialist, team up with others that have complementary skills. If you have enough success, you can convert your project into a business and become your own employer! You can use Guided Compass to discover project prompts, add projects, and team up with people. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61663d81f69bcfcb8c51879e/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref5'><label className="bold-text">January:</label> How Guided Compass Helps You Build Personalized Career Roadmaps</p>
                <p className="top-padding-5 heading-text-6 description-text-color">January 4, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">Ever wonder what the ideal entry-level design candidate is for Nike? How does that compare to Google? How do you compare? How do you prepare? These are questions we’re answering with <Link to="/benefits/transparent-pathways">Guided Compass Benchmarks</Link>. In fact, benchmarks are the backbone of Guided Compass, powering many of its other features. Programs and employers can define their ideal candidate in terms of interests, values, personality, skills, knowledge, projects, interview scores, and more! Imagine seeing this up front and being able to prepare for years? Benchmarks are defined on three levels: career pathways, employers, and specific opportunities. And Guided Compass algorithms are tied to these benchmarks, recommending the best options for you based on your profile. Not only that, since your profile tracks your progress, once you set a goal, the algorithm and advisers can recommend personalized courses, resources, projects, events, and people to achieve your goal. Attend this workshop to learn the ins and outs of Guided Compass benchmarks and how to map a plan based on your career goals. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61664182f69bcfcb8c51879f/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref6'><label className="bold-text">February:</label> Preparing for and Applying to Work Opportunities</p>
                <p className="top-padding-5 heading-text-6 description-text-color">February 1, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This workshop will provide an overview of preparing and applying for internships and work opportunities, and going over what employer representatives want to see for entry-level roles. We will cover tailoring a resume, a cover letter, and a pitch for a specific role. One of the largest flaws with submitted materials is that they don’t seem tailored to the opportunity. Another one of the largest flaws is that the candidate didn’t know the employer’s expectations. We will also go over the power of networking and playing the long game. We recommend Stacey Gordon’s LinkedIn Resume course for a thorough overview. After we cover the basics, we’ll walk through the Guided Compass Resume Builder, and the application process on Guided Compass. Applying through Guided Compass often includes importing career assessments and endorsements to better showcase fit for the role, so we’ll walk through that as well. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/6166424ef69bcfcb8c5187a0/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref7'><label className="bold-text">March:</label> Exploring and Spotlighting Employers</p>
                <p className="top-padding-5 heading-text-6 description-text-color">March 1, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This workshop will spotlight 5 employers in 5 separate industries, including what a “Day in the Life” looks like working there, what their ideal candidates look like, and some of their upcoming opportunities. We will bring in representatives for each of the employers to serve on a panel where Guided Compass serves as moderator. Afterwards, there will be a Q&A period.</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61664283f69bcfcb8c5187a1/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref8'><label className="bold-text">April:</label> Social Capital: Leveraging your Guided Compass Community</p>
                <p className="top-padding-5 heading-text-6 description-text-color">April 5, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This workshop will cover the importance of social capital, forming teams to tackle problems, and the social features within Guided Compass. In the world of work, we’ll all have some form of managers, colleagues, remembers, and subordinates that we need to manage. How do we navigate this effectively? How can we form teams when we’re younger for projects? Within Guided Compass, you can connect with others, post updates to the news feed, interact with projects, and interact with employers. We really encourage people to use these social features to give/get resources and feedback for projects/goals. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/616642bbf69bcfcb8c5187a2/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref9'><label className="bold-text">May:</label> Nailing Interviews and Negotiating Offers</p>
                <p className="top-padding-5 heading-text-6 description-text-color">May 3, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">This workshop will cover tips for nailing the interview, negotiating offers, and utilizing Guided Compass to support you, particularly for entry-level roles, internships, and apprenticeships. For the interview, candidates often don’t realize how important your story is. Think of your life like a movie. What was the inciting incident that brought you here today? How does this fit into your life story? What parts of your past are relevant for this role? These are things to consider during the interview stages. When you get an offer, most of the time there should be no surprises. Do what you can to understand what that offer may look like up front. If the offer is not within the expected range, we’ll cover a few ways to address this. Finally, use Guided Compass to add mock interviews, real interviews, and offers. Share insights from these with your network and collect feedback. They may be able to help. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/616642fcf69bcfcb8c5187a3/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref10'><label className="bold-text">June:</label> Impressing Employers on the Job</p>
                <p className="top-padding-5 heading-text-6 description-text-color">June 7, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">The first weeks of any job are typically very important. This workshop will cover how to put your best foot forward. What do high performers have in common? What do low performers have in common? And what can you do before you even start? We recommend asking these questions to your supervisor right after you accept the offer. If you’re not able to get clarity, we’ll share what we’ve been able to find on this topic.</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61664329f69bcfcb8c5187a4/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref11'><label className="bold-text">July:</label> Exploring Career Paths</p>
                <p className="top-padding-5 heading-text-6 description-text-color">July 5, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">What do you want to major in? We’ve all heard it. This workshop will cover which career path is right for you. Use Guided Compass to take career assessments and get matched to over 1,100+ career paths. You can then favorite a few, watch some of their “Day in the Life” videos, and compare their expected income. Doing this not only can help you directly, but it can also allow you to get help from others by sharing your profile. Use the financial planner to map out how your chosen career path could work with your lifestyle. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/61664361f69bcfcb8c5187a5/rsvp">RSVP Now</Link>
              </div>
              <div className="row top-margin-80">
                <p className="heading-text-3" id='ref12'><label className="bold-text">August:</label> Setting Goals, Making Plans, and Avoiding FOMO</p>
                <p className="top-padding-5 heading-text-6 description-text-color">August 2, 2022 @ 5pm PST</p>

                <p className="top-padding-20 heading-text-6">The best way to avoid the Fear of Missing Out (FOMO), is to be directed in your career search. There are a ton of career paths and information available. It is up to you to consume that information efficiently and make informed decisions on how to spend your time. Eventually, you need to set goals even if those goals will (likey) change. It’s the only way to become good at getting stuff done (another crucial skill). In Guided Compass, you can set goals and allow friends and programs staff to recommend resources for you to achieve them. Goals should be SMART (Specific, Measurable, Achievable, Relevant, and Time-Bound). Only SMART people avoid FOMO.  You can also create more thorough career plans in Guided Compass using the Career Plan Builder. Lastly, we recommend following the Purpose Framework, so that your goals and plans are purpose-driven. As you can see, setting goals and making career plans are easier said than done. We’ll walk you through it. There will be a Q&A, so come ready with questions!</p>

                <Link className="btn btn-full heading-text-6 top-margin-30" to="/organizations/guidedcompass/events/616643b1f69bcfcb8c5187a6/rsvp">RSVP Now</Link>
              </div>

            </section>

            <div className="light-background padding-40">
                <div className={(window.innerWidth > 768) ? "container-left right-text" : "full-width center-text container-left"}>
                  <p className="heading-text-2">Can Guided Compass help</p>
                  <p className="heading-text-1">You?</p>
                </div>
                <div className={(window.innerWidth > 768) ? "container-right top-margin-20" : "container-right top-margin-20 center-text"}>
                  <Link className="btn btn-full heading-text-4" to={"/join"}>Get Started</Link>
                </div>
                <div className="clear" />
            </div>

            {/*Footer()*/}
            <Footer history={this.props.navigate} />
          </div>
      )
    }
}

export default WorkshopsPage;
