import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubArrangeLessons from '../Common/ArrangeLessons';
import SubEditLesson from '../Common/EditLesson';
import SubExchange from '../Subcomponents/Exchange';
import EditCourse from './EditCourse';
import SubMembers from './Members';
import SubKeyMetrics from './KeyMetrics';
import ReactGA from 'react-ga4';
import SubChangePassword from '../Common/ChangePassword';

import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const problemIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-blue.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const priceChart = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/price_chart.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const downloadIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-blue.png';
const invisibleIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon-blue.png";
const visibleIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/unhide-icon-blue.png";
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const linkIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-blue.png';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Dashboard extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showModule: true,
        showModule2: true,
        showMetrics: true,

        resLimit: 1000,

        opacity: 0,
        transform: 'translate(90%)',

        stepsTypeOptions: ["Use Course","Don't Use Course"],
        stepsType: 'Use Course',

        navCategories: ['Members','Key Metrics','Guided Courses'],
        navSelected: 'Members',

        subNavCategories: [],
        subNavSelected: {},

        primaryCourses: []
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.calculateCheckIns = this.calculateCheckIns.bind(this)
      this.renderFollows = this.renderFollows.bind(this)
      this.removeItem = this.removeItem.bind(this)
      this.toggleVisiblity = this.toggleVisiblity.bind(this)
      this.passLesson = this.passLesson.bind(this)
      this.passItem = this.passItem.bind(this)
      this.closeModal = this.closeModal.bind(this)
      this.toggleSteps = this.toggleSteps.bind(this)
      this.addNewCourse = this.addNewCourse.bind(this)
      this.updateCourse = this.updateCourse.bind(this)

      this.openCurriculumExchange = this.openCurriculumExchange.bind(this)
      this.configureHiddenProfile = this.configureHiddenProfile.bind(this)
      this.determineWhetherImported = this.determineWhetherImported.bind(this)
      this.renderCourses = this.renderCourses.bind(this)
      this.navClicked = this.navClicked.bind(this)
      this.subNavClicked = this.subNavClicked.bind(this)
      this.renderMetrics = this.renderMetrics.bind(this)
      this.configureSubNavCategories = this.configureSubNavCategories.bind(this)
      this.pullMetrics = this.pullMetrics.bind(this)
      this.renderStages = this.renderStages.bind(this)

      this.closeHeader = this.closeHeader.bind(this)

    }

    componentDidMount(){
      console.log('componentDidMount called in subdashboard')
      const userId = localStorage.getItem('email');
      const category = localStorage.getItem('activeOrg');
      const action = 'opened_dashboard'

      if (userId && category) {
        // ReactGA.initialize('UA-67259774-5', { debug: true, titleCase: false, gaOptions: { userId }});
        // ReactGA.event({ category, action, label: action });

        ReactGA.initialize([
          {
            trackingId: "376007762",
            gaOptions: { userId }, // optional
            // gtagOptions: {...}, // optional
          },
          // {
          //   trackingId: "your second GA measurement id",
          // },
        ]);

        ReactGA.event({
          category,
          action,
          label: action , // optional
          // value: 99, // optional, must be a number
          // nonInteraction: true, // optional, true/false
          // transport: "xhr", // optional, beacon/xhr/image
        });
      }

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubDashboard', this.props, prevProps)

      if (prevProps.activeOrg !== this.props.activeOrg) {
        console.log('dash1')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subdashboard')

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');

      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');
      let authType = localStorage.getItem('authType');
      const orgLogo = localStorage.getItem('orgLogo');
      const orgName = localStorage.getItem('orgName');
      const dashboardNavSelected = localStorage.getItem('dashboardNavSelected');

      let temporaryPassword = localStorage.getItem('temporaryPassword');

      if (orgFocus === 'Placement' && roleName === 'Teacher') {
        roleName = 'Mentor'
      }

      const bookmarkedPages = [
        { icon:  deleteIconDark, name: 'Courses' },
        { icon:  deleteIconDark, name: 'Learning Modules' },
        { icon:  deleteIconDark, name: 'Resources' },
        { icon:  deleteIconDark, name: 'Curriculum Exchange' },
        { icon:  deleteIconDark, name: 'General Pathways' },
        { icon:  deleteIconDark, name: 'Labor Market Trends' },
        { icon:  deleteIconDark, name: 'Advising Sessions' },
        { icon:  deleteIconDark, name: 'Reporting' },
      ]

      let prefix = '/advisor'
      if (window.location.pathname.startsWith('/organizations')) {
        prefix = '/organizations/' + this.state.activeOrg
      }

      let navSelected = 'Members'
      if (dashboardNavSelected) {
        navSelected = dashboardNavSelected
      }
      if (window.location.pathname.startsWith('/advisor')) {
        navSelected = 'Guided Courses'
      }

      const returnedSubNavObject = this.configureSubNavCategories(orgName)
      const subNavCategories = returnedSubNavObject.subNavCategories
      let subNavSelected = returnedSubNavObject.subNavSelected

      let modalIsOpen = null
      let showChangePassword = null
      if (temporaryPassword) {
        modalIsOpen = true
        showChangePassword = true
      }

      this.setState({ emailId: email, roleName, cuFirstName, cuLastName, username, activeOrg, orgName,
        bookmarkedPages, prefix, navSelected, subNavCategories, subNavSelected, modalIsOpen,
        showChangePassword
      })

      if (activeOrg) {

        this.pullMetrics(activeOrg, subNavSelected, subNavCategories)

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const showSurveyHeader = response.data.workOptions[0].showSurveyHeader
            this.setState({ showSurveyHeader })

          } else {
            console.log('no jobFamilies data found', response.data.message)

          }
        }).catch((error) => {
            console.log('query for work options did not work', error);
        })

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted in subdashboard', response.data);

          if (response.data.success) {
            console.log('org info query worked in subdashboard')

            // let orgName = response.data.orgInfo.orgName
            let orgFocus = response.data.orgInfo.orgFocus
            const contactEmail = response.data.orgInfo.contactEmail
            const dashboardLayout = response.data.orgInfo.dashboardLayout
            const loadedDashboardLayout = true

            this.setState({ orgFocus, contactEmail, dashboardLayout, loadedDashboardLayout });

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        Axios.get('/api/courses', { params: { orgCode: 'guidedcompass', sortByRecency: true } })
        .then((response) => {
          console.log('general course query attempted')
          if (response.data.success && response.data.courses) {
            console.log('General course details query worked', response.data);

            const generalCourses = response.data.courses
            // let explorationCourses = []
            let primaryCourse = null
            let primaryCourses = []

            for (let i = 1; i <= response.data.courses.length; i++) {
              let tempCourse = response.data.courses[i - 1]

              if (response.data.courses[i - 1].curriculumItemId === subNavSelected.primaryCID) {
                primaryCourse = tempCourse
              }

              if (response.data.courses[i - 1].curriculumItemId === '6424e1b1470d9a1af4738910') {
                tempCourse['rank'] = 1
                primaryCourses.push(tempCourse)
              }
              if (response.data.courses[i - 1].curriculumItemId === '6424e1b1470d9a1af4738911') {
                tempCourse['rank'] = 2
                primaryCourses.push(tempCourse)
              }
              if (response.data.courses[i - 1].curriculumItemId === '6424e1b1470d9a1af4738914') {
                tempCourse['rank'] = 3
                primaryCourses.push(tempCourse)
              }
              if (response.data.courses[i - 1].curriculumItemId === '6424e1b1470d9a1af4738913') {
                tempCourse['rank'] = 4
                primaryCourses.push(tempCourse)
              }
              if (response.data.courses[i - 1].curriculumItemId === '6424e1b1470d9a1af4738915') {
                tempCourse['rank'] = 5
                primaryCourses.push(tempCourse)
              }
            }
            // console.log('try 1', primaryCourses)
            primaryCourses.sort(function(a,b) {
              return a.rank - b.rank;
            })
            // console.log('try 2', primaryCourses)
            // employerOptions.sort(function(a, b){
            //     if(a.employerName < b.employerName) { return -1; }
            //     if(a.employerName > b.employerName) { return 1; }
            //     return 0;
            // })

            this.setState({ generalCourses, primaryCourse, primaryCourses })

          } else {
            console.log('no courses found', response.data.message)
          }

        }).catch((error) => {
            console.log('Course query did not work', error);
        });

        Axios.get('/api/courses', { params: { orgCode: activeOrg } })
        .then((response) => {
          if (response.data.success && response.data.courses) {
            console.log('Course details query worked', response.data);

            let courses = response.data.courses
            this.setState({ courses })

            Axios.get('/api/courses/byid', { params: { _id: '6469694ddd408c1c0c2057e1' } })
            .then((response) => {

                if (response.data.success && response.data.course) {
                  // console.log('Course details query worked', response.data);

                  let introToAICourse = response.data.course
                  this.setState({ introToAICourse })

                } else {
                  console.log('no courses found', response.data.message)
                }

            }).catch((error) => {
                console.log('Course query did not work', error);
            });

          } else {
            console.log('no courses found', response.data.message)
          }

        }).catch((error) => {
            console.log('Course query did not work', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
        .then((response) => {
          console.log('Favorites query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved favorites', response.data.favorites.length)

              if (response.data.favorites.length > 0) {
                console.log('the array is greater than 0', response.data.favorites.length)
                //query info on those favorites
                Axios.get('/api/favorites/advisor/detail', { params: { favorites: response.data.favorites } })
                .then((response2) => {
                  console.log('Favorites detail query attempted', response2.data);

                    if (response2.data.success) {
                      console.log('successfully retrieved favorites detail', response2.data.favorites)

                      const types = response2.data.types
                      const favorites = response2.data.favorites

                      this.setState({ types, favorites })

                    } else {
                      console.log('no favorites detail data found', response2.data.message)
                    }

                  }).catch((error) => {
                      console.log('Favorites detail query did not work', error);
                  });
              }

            } else {
              console.log('no favorites data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Favorites query did not work', error);
        });

        Axios.get('/api/lessons', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Lessons query attempted', response.data);

          if (response.data.success) {
            console.log('lessons query worked')

            const lessons = response.data.lessons
            this.setState({ lessons })

          } else {
            console.log('lessons query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Lessons query did not work for some reason', error);
        });

        Axios.get('/api/partners', { params: { partnerEmail: email } })
        .then((response) => {
          console.log('Partner query attempted', response.data);

          if (response.data.success) {
            console.log('partner query worked')

            if (response.data.partners && response.data.partners.length !== 0) {
              let partners = []

              // assume multiple advisees
              if (response.data.partners && response.data.partners[0] && response.data.partners[0].advisees && response.data.partners[0].advisees.length > 0) {
                partners = response.data.partners[0].advisees

                for (let i = 1; i <= partners.length; i++) {

                  const index = i - 1

                  Axios.get('/api/users/profile/details', { params: { email: partners[i - 1].email } })
                  .then((response) => {
                    console.log('Profile query attempted', response.data);

                    if (response.data.success) {
                      console.log('profile query worked')

                      let partner = partners[index]
                      console.log('show data: ', partner, response.data)

                      partner['pictureURL'] = response.data.user.pictureURL
                      partner['username'] = response.data.user.username
                      partner['school'] = response.data.user.school
                      partner['gradYear'] = response.data.user.gradYear
                      partner['education'] = response.data.user.education

                      partners[index] = partner
                      this.setState({ partners })

                    } else {
                      console.log('query for profile query did not work', response.data.message)
                    }

                  }).catch((error) => {
                      console.log('Profile query did not work for some reason', error);
                  });

                }
              }
            }

          } else {
            console.log('query for partners query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Partners query did not work for some reason', error);
        });

        const roleNames = ['Student','Career-Seeker']
        Axios.get('/api/org/members', { params: { orgCode: activeOrg, roleNames, resLimit: this.state.resLimit } })
        .then((response) => {

            if (response.data.success) {
              console.log('Members query worked', response.data);

              // const members = response.data.members
              const students = response.data.members
              // let students = []
              // let teachers = []
              //
              // for (let i = 1; i <= members.length; i++) {
              //
              //   if (!members[i - 1].email.includes('creightontaylor')) {
              //     if (members[i - 1].roleName === 'Student' || members[i - 1].roleName === 'Career-Seeker') {
              //       students.push(members[i - 1])
              //     } else if (members[i - 1].roleName === 'Teacher' || members[i - 1].roleName === 'School Support' || members[i - 1].roleName === 'Counselor' || members[i - 1].roleName === 'Staff (Non-Classroom)') {
              //       teachers.push(members[i - 1])
              //     }
              //   }
              // }

              this.setState({ students })

            } else {
              console.log('no classmates found', response.data.message)
            }

        }).catch((error) => {
            console.log('Classmates query did not work', error);
        });

        Axios.get('/api/submissions', { params: { orgCode: activeOrg }})
        .then((response) => {
          console.log('Submissions query attempted', response.data);

          if (response.data.success) {
            console.log('submissions query worked')

            const submissions = response.data.submissions
            this.setState({ submissions })

            Axios.get('/api/submissions', { params: { orgCode: activeOrg, includesGrade: true }})
            .then((response) => {
              console.log('Grades query attempted', response.data);

                if (response.data.success) {
                  console.log('grades query worked')

                  const grades = response.data.submissions
                  this.setState({ grades })

                } else {
                  console.log('grades query did not work', response.data.message)
                }

            }).catch((error) => {
                console.log('grades query did not work for some reason', error);
            });

          } else {
            console.log('grades query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('grades query did not work for some reason', error);
        });

        Axios.get('/api/counseling/session', { params: { orgCode: activeOrg }})
        .then((response) => {

            if (response.data.success) {
              console.log('Session query worked', response.data);

              let sessions = response.data.sessions
              this.setState({ sessions })

            } else {
              console.log('no session data found', response.data.message)
              this.setState({
                serverPostSuccess: false,
                serverErrorMessage: response.data.message,
              })
            }

        }).catch((error) => {
            console.log('Resource query did not work', error);
        });

        Axios.get('/api/postings/user', { params: { orgCode: activeOrg, isSchool: true } })
        .then((response) => {
          console.log('Posted postings query attempted', response.data);

          if (response.data.success) {
            console.log('posted postings query worked')

            if (response.data.postings.length !== 0) {
              let postings = response.data.postings
              //jobs.push(response.data.postings[0])
              this.setState({ postings });
            }

          } else {
            console.log('posted postings query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posted postings query did not work for some reason', error);
        });

        Axios.get('/api/account/partners', { params: { org: activeOrg } })
        .then((response) => {
          console.log('Posted employer query attempted', response.data);

          if (response.data.success) {
            console.log('posted employer query worked')

            if (response.data.employers.length !== 0) {
              let employers = response.data.employers
              //jobs.push(response.data.postings[0])
              this.setState({ employers });
            }

          } else {
            console.log('query for employers query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Employer query did not work for some reason', error);
        });

        //pull sessions
        Axios.get('/api/counseling/session', { params: { emailId: email, type: 'Advisor'  } })
        .then((response) => {

          if (response.data.success) {
            console.log('Session query worked', response.data);

            const sessions = response.data.sessions
            this.setState({ sessions })

            this.calculateCheckIns(sessions, 'creightontaylor+40@gmail.com')

          } else {
            console.log('no session data found', response.data.message)
            this.setState({
              serverPostSuccess: false,
              serverErrorMessage: response.data.message,
            })
          }

        }).catch((error) => {
            console.log('Session query did not work', error);
        });

        Axios.get('/api/mentors', { params: { activeOrg } })
        .then((response) => {
          console.log('Posted mentor query attempted', response.data);

          if (response.data.success) {
            console.log('mentor query worked')

            if (response.data.mentors.length !== 0) {
              let mentors = response.data.mentors
              //jobs.push(response.data.postings[0])
              this.setState({ mentors });
            }

          } else {
            console.log('query for mentors query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Mentor query did not work for some reason', error);
        });

        const commandQueryObject = { onlyAI: true, onlyTopTier: true }
        Axios.get('/api/commands', { params: commandQueryObject })
        .then((response) => {
          console.log('Commands query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved commands')

             const popularCommands = response.data.commands
             this.setState({ popularCommands })

           } else {
             console.log('no commands found', response.data.message)
           }
        }).catch((error) => {
           console.log('Commands query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)',
              }));
        }, 0.3)

      } else {

        setTimeout(() => {
            this.setState(state => ({
                opacity: 1,
                transform: 'translate(2%)',
              }));
        }, 0.3)

      }
    }

    pullMetrics(activeOrg, subNavSelected, subNavCategories) {
      console.log('pullMetrics called', activeOrg, subNavSelected, subNavCategories)

      // activeOrg = 'guidedcompass'

      const roleNames = ['Student','Career-Seeker']

      // const mainQueryParams = { orgCode: activeOrg, roleNames }
      const mainQueryParams = { orgCode: activeOrg }

      const self = this
      function pullStudentsWithDocuments() {
        console.log('pullStudentsWithDocuments called')

        let studentsWithCareerPlans = []
        let studentsWithFinancialPlans = []
        let studentsWithResumes = []
        let studentsWithCoverLetters = []
        let studentsWithMockInterviews = []
        let studentsWithBusinessPlans = []

        Axios.get('/api/documents/users', { params: mainQueryParams })
        .then((response) => {
          console.log('Users with documents query attempted', response.data);

          if (response.data.success) {
            console.log('Users with a document query worked')

            studentsWithCareerPlans = response.data.usersWithCareerPlans
            studentsWithFinancialPlans = response.data.usersWithFinancialPlans
            studentsWithResumes = response.data.usersWithResumes
            studentsWithCoverLetters = response.data.usersWithCoverLetters
            studentsWithMockInterviews = response.data.usersWithMockInterviewDocs
            studentsWithBusinessPlans = response.data.usersWithBusinessPlans

            // for (let i = 1; i <= response.data.users.length; i++) {
            //   if (response.data.users[i - 1].docType === 'Career Plan') {
            //     studentsWithCareerPlans.push(response.data.users[i - 1])
            //   } else if (response.data.users[i - 1].docType === 'Financial Plan') {
            //     studentsWithFinancialPlans.push(response.data.users[i - 1])
            //   } else if (response.data.users[i - 1].docType === 'Resume') {
            //     studentsWithResumes.push(response.data.users[i - 1])
            //   } else if (response.data.users[i - 1].docType === 'Cover Letter') {
            //     studentsWithCoverLetters.push(response.data.users[i - 1])
            //   } else if (response.data.users[i - 1].docType === 'Mock Interview') {
            //     studentsWithMockInterviews.push(response.data.users[i - 1])
            //   } else if (response.data.users[i - 1].docType === 'Business Plan') {
            //     studentsWithBusinessPlans.push(response.data.users[i - 1])
            //   }
            // }

            self.setState({ studentsWithCareerPlans, studentsWithFinancialPlans,
              studentsWithResumes, studentsWithCoverLetters, studentsWithMockInterviews,
              studentsWithBusinessPlans
            });

          } else {
            console.log('Users with a document query did not work', response.data.message)

            self.setState({ studentsWithCareerPlans, studentsWithFinancialPlans,
              studentsWithResumes, studentsWithCoverLetters, studentsWithMockInterviews,
              studentsWithBusinessPlans
            });
          }

        }).catch((error) => {
            console.log('Users with a document did not work for some reason', error);
        });
      }

      if (subNavSelected) {
        if (subNavSelected.title === 'All' || subNavSelected.title === 'Exploration') {
          console.log('in exploration')

          if (!this.state.studentsWithFavorites) {
            Axios.get('/api/favorites/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with a favorite query attempted', response.data);

              if (response.data.success) {
                console.log('sers with a favorite  query worked')

                let studentsWithFavorites = response.data.users
                //jobs.push(response.data.postings[0])
                this.setState({ studentsWithFavorites });

              } else {
                console.log('sers with a favorite  query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('sers with a favorite did not work for some reason', error);
            });
          }

          if (!this.state.studentsWithAssessments) {
            Axios.get('/api/assessment/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with assessment results query attempted', response.data);

              if (response.data.success) {
                console.log('users with an assessment query worked')

                let studentsWithAssessments = response.data.users
                //jobs.push(response.data.postings[0])
                this.setState({ studentsWithAssessments });

              } else {
                console.log('users with an assessment query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with an assessment did not work for some reason', error);
            });
          }
        }

        if (subNavSelected.title === 'All' || subNavSelected.title === 'Planning') {

          if (!this.state.studentsWithGoals) {
            // goals

            Axios.get('/api/goals/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with goals query attempted', response.data);

              if (response.data.success) {
                console.log('users with a goal query worked')

                let studentsWithGoals = response.data.users
                this.setState({ studentsWithGoals });

              } else {
                console.log('users with a goal query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with a goal did not work for some reason', error);
            });
          }

          if (!this.state.studentsWithCareerPlans || !this.state.studentsWithFinancialPlans) {
            // careerPlans, financialPlans
            pullStudentsWithDocuments()
          }

        }

        if (subNavSelected.title === 'All' || subNavSelected.title === 'Preparation') {
          if (!this.state.studentsWithResumes || !this.state.studentsWithCoverLetters || !this.state.studentsWithMockInterviews) {
            // resumes, coverLetters, mock interviews
            pullStudentsWithDocuments()
          }

        }

        if (subNavSelected.title === 'All' || subNavSelected.title === 'Training') {

          if (!this.state.studentsWithPlacements) {
            // placements

            Axios.get('/api/placements/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with placements query attempted', response.data);

              if (response.data.success) {
                console.log('users with a placements query worked')

                let studentsWithPlacements = response.data.users
                this.setState({ studentsWithPlacements });

              } else {
                console.log('users with a placements query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with a placements did not work for some reason', error);
            });
          }

          if (!this.state.studentsWithEndorsements) {
            // endorsements

            Axios.get('/api/story/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with endorsements query attempted', response.data);

              if (response.data.success) {
                console.log('users with a endorsements query worked')

                let studentsWithEndorsements = response.data.users
                this.setState({ studentsWithEndorsements });

              } else {
                console.log('users with a endorsements query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with a endorsements did not work for some reason', error);
            });
          }

          if (!this.state.studentsWithProjects) {
            // projects

            Axios.get('/api/projects/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with projects query attempted', response.data);

              if (response.data.success) {
                console.log('users with a project query worked')

                let studentsWithProjects = response.data.users
                this.setState({ studentsWithProjects });

              } else {
                console.log('users with a project query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with a project did not work for some reason', error);
            });
          }
        }

        if (subNavSelected.title === 'All' || subNavSelected.title === 'Entrepreneurship') {

          if (!this.state.studentsWithProjects) {
            // projects

            Axios.get('/api/projects/users', { params: mainQueryParams })
            .then((response) => {
              console.log('Users with projects query attempted', response.data);

              if (response.data.success) {
                console.log('users with a prject query worked')

                let studentsWithProjects = response.data.users
                this.setState({ studentsWithProjects });

              } else {
                console.log('users with a project query did not work', response.data.message)
              }

            }).catch((error) => {
                console.log('users with a project did not work for some reason', error);
            });
          }

          if (!this.state.studentsWithBusinessPlans) {
            // projects, businessPlans
            pullStudentsWithDocuments()
          }
        }
      }

      if (!this.state.explorationEnrollments || !this.state.planningEnrollments || !this.state.preparationEnrollments || !this.state.trainingEnrollmentss || !this.state.entrepreneurshipEnrollments) {

        let explorationEnrollments = []
        let explorationCompletions = []
        let explorationIndex = 1
        let planningEnrollments = []
        let planningCompletions = []
        let planningIndex = 2
        let preparationEnrollments = []
        let preparationCompletions = []
        let preparationIndex = 3
        let trainingEnrollments = []
        let trainingCompletions = []
        let trainingIndex = 4
        let entrepreneurshipEnrollments = []
        let entrepreneurshipCompletions = []
        let entrepreneurshipIndex = 5

        Axios.get('/api/courses/enrollments', { params: { orgCode: activeOrg } })
       .then((response) => {
         console.log('Enrollments query attempted', response.data);

         if (response.data.success && response.data.enrollments && response.data.enrollments.length > 0) {
           console.log('successfully retrieved enrollments')

           for (let i = 1; i <= response.data.enrollments.length; i++) {
             // console.log('how?!', response.data.enrollments[i - 1].courseCurriculumItemId, subNavCategories[explorationIndex].primaryCID, response.data.enrollments[i - 1].courseName.toLowerCase(), subNavCategories[0].title.toLowerCase())
             if (response.data.enrollments[i - 1].courseCurriculumItemId === subNavCategories[explorationIndex].primaryCID || response.data.enrollments[i - 1].courseName.toLowerCase().includes(subNavCategories[explorationIndex].title.toLowerCase())) {
               explorationEnrollments.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 explorationCompletions.push(response.data.enrollments[i - 1])
               }
             } else if (response.data.enrollments[i - 1].courseCurriculumItemId === subNavCategories[planningIndex].primaryCID || response.data.enrollments[i - 1].courseName.toLowerCase().includes(subNavCategories[planningIndex].title.toLowerCase())) {
               planningEnrollments.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 planningCompletions.push(response.data.enrollments[i - 1])
               }
             } else if (response.data.enrollments[i - 1].courseCurriculumItemId === subNavCategories[preparationIndex].primaryCID || response.data.enrollments[i - 1].courseName.toLowerCase().includes(subNavCategories[preparationIndex].title.toLowerCase())) {
               preparationEnrollments.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 preparationCompletions.push(response.data.enrollments[i - 1])
               }
             } else if (response.data.enrollments[i - 1].courseCurriculumItemId === subNavCategories[trainingIndex].primaryCID || response.data.enrollments[i - 1].courseName.toLowerCase().includes(subNavCategories[trainingIndex].title.toLowerCase())) {
               trainingEnrollments.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 trainingCompletions.push(response.data.enrollments[i - 1])
               }
             } else if (response.data.enrollments[i - 1].courseCurriculumItemId === subNavCategories[entrepreneurshipIndex].primaryCID || response.data.enrollments[i - 1].courseName.toLowerCase().includes(subNavCategories[entrepreneurshipIndex].title.toLowerCase())) {
               entrepreneurshipEnrollments.push(response.data.enrollments[i - 1])
               if (response.data.enrollments[i - 1].isCompleted) {
                 entrepreneurshipCompletions.push(response.data.enrollments[i - 1])
               }
             }
           }

           this.setState({ explorationEnrollments, explorationCompletions, planningEnrollments,
             planningCompletions, preparationEnrollments, preparationCompletions, trainingEnrollments,
             trainingCompletions, entrepreneurshipEnrollments, entrepreneurshipCompletions
           })

         } else {
           console.log('no enrollments data found', response.data.message)

           this.setState({ explorationEnrollments, explorationCompletions, planningEnrollments,
             planningCompletions, preparationEnrollments, preparationCompletions, trainingEnrollments,
             trainingCompletions, entrepreneurshipEnrollments, entrepreneurshipCompletions
           })
         }

       }).catch((error) => {
           console.log('Enrollments query did not work', error);
       });
      }
    }

    configureSubNavCategories(orgName) {
      console.log('configureSubNavCategories called', orgName)

      const explorationSubtitle = 'Guide students to learn about themselves and their most suitable careers. This includes general information about people’s match to career paths and the career paths themselves. Optional: A.I. can be used to help guide students and grade assignments.'
      const explorationObjectives = [
        'Build self-awareness (i.e., learning interests, passions, personality, skills, and values)',
        'Build social awareness of others and how one compares to others',
        'Identify and learn about different career paths',
        'Gain a vivid understanding of specific fields that are a potential strong match'
      ]

      const explorationCourseSteps = [
        { title: '[Optional] ChatGPT and Generative A.I. 101 Course', body: ' - import this course into your community and make sure it is visible to students. Students can then take this course from the "Learning" tab. Guided Compass is integrated with ChatGPT, so this course is crucial for successfully navigating the career exploration course. (60 mins)'},
        { title: 'Career Awareness & Exploration Course', body: ' - import this course into your ' + orgName + ' community and make sure it is visible to students. Students can then take this course from the "Learning" tab. (60 mins)'},
        { title: null, body: 'Educators (i.e., you) review and grade student submissions from the course. (60 mins)'},
      ]

      const explorationIndependentSteps = [
        { title: 'Career Assessments:', body: ' students take interests, personality, values, and skill self-assessments (60 mins)'},
        { title: 'Discuss Results:', body: ' students form groups of 3 to discuss the accuracy of assessment results (30 mins)'},
        { title: 'Favorite Careers:', body: ' students calculate their top career paths, view career profiles, and favorite careers of interest. (30 mins)'},
        { title: 'Mentors (optional):', body: ' - educators invite mentors who have passed background checks to join mentor portal to take career assessments and be matched to student(s). (180 mins)'},
      ]

      let explorationIndependentInstrux = '<p>Open the Guided Compass app. You should be directed to a home page that has a social feed and a blue navigation bar across the top. To independently explore careers, you will primarily be visiting two pages: 1) the Career Assessments Page and 2) The Career Exploration Page.</p>'
      explorationIndependentInstrux = explorationIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Career Assessments Page</label>: The career assessments page can be accessed by clicking on the "Profile" dropdown found in the top navigation bar. Once you click on career assessments, you should see a list of career assessments (e.g., interest, personality, values, skills). Click on the career assessment to take the assessment and/or view your results.</p>'
      explorationIndependentInstrux = explorationIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Career Exploration Page</label>: The career exploration page can be accessed by clicking on the "Exploration" page found on the top navigation bar. Once you click on exploration, you should see the "Careers", "Financials", and "Trends" tabs. In the "Careers" tab, you can view, search, filter, favorite, and click on over 1,000 career paths. If you have already taken the career assessments, clicking the match icon shows which careers are the closest match to you based on your profile. The "Financials" tab allows you to compare the income of the career paths you have favorited, as well as add custom entries for your projected income, expenses, assets, and liabilities. The "Trends" tabs allows you to browse general labor market trends and contemplate how they may affect your career path decisions.</p>'

      const explorationCourseMetrics = [
        { name: 'Course Enrollments', label: 'Exploration Course Enrollments', shortname: 'explorationEnrollments', subtitle: 'Students who have either been enrolled or who have begun taking the career exploration course'},
        { name: 'Completed Career Assessments', label: 'w/ Career Assessment Results',  shortname: 'studentsWithAssessments', subtitle: 'Students who have completed at least one interest, personality, values, or skill assessment'},
        { name: 'Favorited Career Paths', label: 'w/ Favorited Career Paths',  shortname: 'studentsWithFavorites', subtitle: 'Students who have favorited a career path'},
        { name: 'Course Completions', label: 'Exploration Course Completions',  shortname: 'explorationCompletions', subtitle: 'Students who have completed the career exploration course'},
      ]

      const explorationIndependentMetrics = [
        { name: 'Completed Career Assessments', label: 'w/ Career Assessment Results',  shortname: 'studentsWithAssessments', subtitle: 'Students who have completed at least one interest, personality, values, or skill assessment'},
        { name: 'Favorited Career Paths', label: 'w/ Favorited Career Paths',  shortname: 'studentsWithFavorites', subtitle: 'Students who have favorited a career path'},
      ]

      const explorationPrimaryCID = "6424e1b1470d9a1af4738910"
      const explorationCIDs = ["648a624d70d49c0bf65dfdb5",explorationPrimaryCID]

      const planningSubtitle = 'Once students have an idea of what they want to do, guide students through planning for their careers and budgeting/generating a financial plan. Optional: A.I. can be used to help guide students, auto-create documents, and grade assignments.'
      const planningObjectives = [
        'Reflect on interests, personality, skills, and values to think about future plans',
        'Learn about SMART goals and career plans best practices',
        'Create SMART goals and career plans',
        'Identify median and distribution of income careers of interest',
        'Project income, expenses, assets, liabilities, and net worth over 3, 5, and 10 years based on a selected career path and lifestyle',
      ]

      const planningCourseSteps = [
        { title: '[Optional] ChatGPT and Generative A.I. 101 Course', body: ' - import this course into your community and make sure it is visible to students. Students can then take this course from the "Learning" tab. Guided Compass is integrated with ChatGPT, so this course is crucial for successfully navigating the career exploration course. (60 mins)'},
        { title: 'Career & Financial Planning Course', body: ' - import this course into your ' + orgName + ' community and make sure it is visible to students. Students can then take this course from the "Learning" tab. (60 mins)'},
        { title: null, body: 'Educators (i.e., you) review and grade student submissions from the course. (60 mins)'},
      ]

      const planningIndependentSteps = [
        { title: 'SMART Goal (Students):', body: 'Students create a SMART goal related to the students’ selected career path from home screen of their student portal (20 mins)'},
        { title: 'SMART Goal (Educators):', body: 'Educators grade, provide feedback, and provide resources for SMART goals by clicking on the each SMART goal in this educator portal (30 mins)'},
        { title: 'Career Plan (Students):', body: 'Students create a career plan using the "Document Builder" on their profile. Students may use A.I. to auto-generate career plan if educator allows it. (90 mins)'},
        { title: 'Career Plan (Educators):', body: 'Educators grade and provide feedback on each career plan from within educator portal. Educators may use A.I. to auto-grade career plan. (30 mins)'},
        { title: 'Financial Plan (Students):', body: 'Students create a financial plan using the "Document Builder" on their profile. Students may use A.I. to auto-generate financial plan if educator allows it. (90 mins)'},
        { title: 'Financial Plan (Educators):', body: 'Educators grade and provide feedback on each financial plan from within educator portal. Educators may use A.I. to auto-grade financial plan. (30 mins)'},
        { title: 'Mentors (Optional):', body: 'Educators invite mentors who have passed background checks to join mentor portal to provide career plan feedback. (120 mins)'},
      ]

      let planningIndependentInstrux = '<p>Open the Guided Compass app. You should be directed to a home page that has a social feed and a blue navigation bar across the top. To independently use the planning tools, you will primarily visit two pages: 1) the Edit SMART Goals Page and 2) The Document Builder.</p>'
      planningIndependentInstrux = planningIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Edit SMART Goals Page</label>: From the home screen, there should be a button to create SMART goals on the left-hand side of the screen. Click it to create a SMART goal. Fill out the form and save the goal.</p>'
      planningIndependentInstrux = planningIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Document Builder Page</label>: The document builder page can be accessed by clicking on the "Profile" dropdown found in the top navigation bar. Once you click on document builder, you should see a list of documents that you can can create. Navigate to the "Career Plan" and "Financial Plan" tabs to edit and save your plans respectively.</p>'

      const planningCourseMetrics = [
        { name: 'Course Enrollments', label: 'Planning Course Enrollments', shortname: 'planningEnrollments', subtitle: 'Students who have either been enrolled or who have begun taking the career & financial planning course'},
        { name: 'SMART Goals Created', label: 'w/ SMART Goals Created',  shortname: 'studentsWithGoals', subtitle: 'Students who have completed at least one goal'},
        { name: 'Career Plans Created', label: 'w/ Career Plans Created',  shortname: 'studentsWithCareerPlans', subtitle: 'Students who have at least one career plan'},
        { name: 'Financial Plans Created', label: 'w/ Financial Plans Created',  shortname: 'studentsWithFinancialPlans', subtitle: 'Students who have at least one financial plan'},
        { name: 'Course Completions', label: 'Planning Course Completions',  shortname: 'planningCompletions', subtitle: 'Students who have completed the career & financial planning course'},
      ]

      const planningIndependentMetrics = [
        { name: 'SMART Goals Created', label: 'w/ SMART Goals Created',  shortname: 'studentsWithGoals', subtitle: 'Students who have completed at least one goal'},
        { name: 'Career Plans Created', label: 'w/ Career Plans Created',  shortname: 'studentsWithCareerPlans', subtitle: 'Students who have at least one career plan'},
        { name: 'Financial Plans Created', label: 'w/ Financial Plans Created',  shortname: 'studentsWithFinancialPlans', subtitle: 'Students who have at least one financial plan'},
      ]

      const planningPrimaryCID = "6424e1b1470d9a1af4738911"
      const planningCIDs = ["648a624d70d49c0bf65dfdb5", planningPrimaryCID]

      const prepSubtitle = 'Once students have a goal and plan, guide students through general career readiness preparation, building a resume, building a cover letter, building a portfolio, creating a LinkedIn account, completing a mock application, and completing a mock internship. Optional: A.I. can be used to guide students, auto-create documents, and grade assignments.'

      const prepObjectives = [
        'Identify basic skills that the vast majority of employers desire',
        'Generate a resume for a specific role at a specific company',
        'Generate a cover letter for a specific role at a specific company',
        'Complete a mock application',
        'Complete a mock interview',
      ]

      const prepCourseSteps = [
        { title: '[Optional] ChatGPT and Generative A.I. 101 Course', body: ' - import this course into your community and make sure it is visible to students. Students can then take this course from the "Learning" tab. Guided Compass is integrated with ChatGPT, so this course is crucial for successfully navigating the career preparation course. (60 mins)'},
        { title: 'Career Preparation Course', body: ' - import this course into your ' + orgName + ' community and make sure it is visible to students. Students can then take this course from the "Learning" tab. (60 mins)'},
        { title: null, body: 'Educators (i.e., you) review and grade student submissions from the course. (60 mins)'},
      ]

      const prepIndependentSteps = [
        { title: 'Edit Basic Info (Students):', body: ' students may edit their basic profile information, including their education, experience, projects, extracurriculars, and other information that can be imported into the student resumes, cover letters, and mock interview documents. (90 mins)'},
        { title: 'Edit Basic Info (Educators):', body: ' educators can review the basic information by clicking on their profile here in the educator portal. (90 mins)'},
        { title: 'Resume (Students):', body: ' students may navigate to "Document Builder" under the "Profile" dropdown in the top navigation bar. Students can import their profile, make changes, and save changes. If A.I. is enabled (from settings), A.I. can use to auto-generate the resume based on the target role. (60 mins)'},
        { title: 'Resume (Educators):', body: ' Educators can review the resume by clicking on their profile here in the educator portal. You can then export the resume to PDF, send messages to the student regarding the resume, and use A.I. to automatically grade the resume with comments. (60 mins)'},
        { title: 'Cover Letter (Students):', body: ' students may navigate to "Document Builder" under the "Profile" dropdown in the top navigation bar. Students can import their profile, make changes, and save changes. If A.I. is enabled (from settings), A.I. can use to auto-generate the cover letter based on the target role. (60 mins)'},
        { title: 'Cover Letter (Educators):', body: ' Educators can review the resume by clicking on their profile here in the educator portal. You can then export the cover letter to PDF, send messages to the student regarding the cover letter, and use A.I. to automatically grade the cover letter with comments. (60 mins)'},
        { title: 'Mock Interview (Students):', body: ' students may navigate to "Document Builder" under the "Profile" dropdown in the top navigation bar. Students can import their profile, make changes, and save changes. If A.I. is enabled (from settings), A.I. can use to auto-generate the mock interview questions based on the target role. (60 mins)'},
        { title: 'Mock Interview (Educators):', body: ' Educators can review the mock interview doc by clicking on their profile here in the educator portal. You can then export the mock interview doc to PDF, send messages to the student regarding the mock interview, and use A.I. to automatically grade the mock interview with comments. (60 mins)'},
        { title: 'Mentors (Optional):', body: ' Educators invite mentors who have passed background checks to join mentor portal to provide resume, cover letter, and mock interview feedback. (120 mins)'},
      ]

      let prepIndependentInstrux = '<p>Open the Guided Compass app. You should be directed to a home page that has a social feed and a blue navigation bar across the top. To independently explore careers, you will primarily be visiting two pages: 1) the Edit Profile Info page and 2) The Document Builder page.</p><p><label style="bold-text">Career Assessments Page</label>:</p>'
      prepIndependentInstrux = prepIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Edit Basic Profile Info Page</label>: students may navigate to "Edit Profile Info" under the "Profile" dropdown in the top navigation bar. Students can enter education, experience, projects, extracurriculars, and other information that can be imported into the student resumes, cover letters, and mock interview documents.</p>'
      prepIndependentInstrux = prepIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Document Builder Page</label>: students may navigate to "Document Builder" under the "Profile" dropdown in the top navigation bar. Students can import their profile, make changes, and save changes to resumes, cover letters, and mock interview docs.</p>'

      const prepCourseMetrics = [
        { name: 'Course Enrollments', label: 'Preparation Course Enrollments', shortname: 'preparationEnrollments', subtitle: 'Students who have either been enrolled or who have begun taking the career preparation course'},
        { name: 'Resumes Completed', label: 'w/ Resumes Completed',  shortname: 'studentsWithResumes', subtitle: 'Students who have completed at least one resume'},
        { name: 'Cover Letters Completed', label: 'w/ Cover Letters Completed',  shortname: 'studentsWithCoverLetters', subtitle: 'Students who have completed at least one cover letter'},
        { name: 'Mock Interviews Completed', label: 'w/ Mock Interviews Completed',  shortname: 'studentsWithMockInterviews', subtitle: 'Students who have completed at least one mock interview'},
        { name: 'Course Completions', label: 'Preparation Course Completions',  shortname: 'preparationCompletions', subtitle: 'Students who have completed the career preparation course'},
      ]

      const prepIndependentMetrics = [
        { name: 'Resumes Completed', label: 'w/ Resumes Completed',  shortname: 'studentsWithResumes', subtitle: 'Students who have completed at least one resume'},
        { name: 'Cover Letters Completed', label: 'w/ Cover Letters Completed',  shortname: 'studentsWithCoverLetters', subtitle: 'Students who have completed at least one cover letter'},
        { name: 'Mock Interviews Completed', label: 'w/ Mock Interviews Completed',  shortname: 'studentsWithMockInterviews', subtitle: 'Students who have completed at least one mock interview'},
      ]

      const prepPrimaryCID = "6424e1b1470d9a1af4738914"
      const prepCIDs = ["648a624d70d49c0bf65dfdb5",prepPrimaryCID]

      const trainingSubtitle = 'Once students are properly prepared for their career, guide students through working on projects in a real or simulated environment. Optional: A.I. can be used to help guide students, auto-create documents, and grade assignments.'

      const trainingObjectives = [
        'Provide hours of practice building relevant skills in a career field of interest',
        'Learn how to navigate different workplace environments',
        'Learn how to the time-management skills necessary to complete multi-week projects',
        'Identify and understand metrics for success that will be used in performance evaluations',
        'Learn about relevant job performance evaluations and how to excel in them',
        'Gain competency endorsements on relevant skills',
      ]

      const trainingCourseSteps = [
        { title: '[Optional] ChatGPT and Generative A.I. 101 Course', body: ' - import this course into your community and make sure it is visible to students. Students can then take this course from the "Learning" tab. Guided Compass is integrated with ChatGPT, so this course is crucial for successfully navigating the career training course. (60 mins)'},
        { title: 'Career Training & Advancement Course', body: ' - import this course into your ' + orgName + ' community and make sure it is visible to students. Students can then take this course from the "Learning" tab. (60 mins)'},
        { title: null, body: 'Educators (i.e., you) review and grade student submissions from the course. (60 mins)'},
      ]

      const trainingIndependentSteps = [
        { title: 'Create Placements:', body: ' educators create a "placement", assign an employer, and assign students to each placement. An example placement could be 5 software engineering interns at Google. A Google representative, educator, or mentor could serve as the hiring manager. (120 mins)'},
        { title: 'Attach Assignments:', body: ' educators can create an assignment posting for students to submit projects. A.I. can be used to automatically create assignments / lesson plans for simulate real work. (60 mins)'},
        { title: 'Enable Timesheets:', body: ' if a placement has timesheets enabled, a timesheet is automatically created and sent to the assigned students on a weekly basis. Students can submit their hours, relevant projects, and feedback. Hiring and/or educators/coordinators can approve submitted timesheets and provide feedback. (120 mins)'},
        { title: 'Send Competency Endorsements:', body: ' hiring managers and/or educators/coordinators can provide competency endorsements after a placement. The endorsement can be confidential or transparent to the student, and can be reused for future work applications. (60 mins)'},
      ]

      let trainingIndependentInstrux = '<p>Open the Guided Compass app. Await confirmation from your teacher that you have been placed in a role. Once you have been placed, you can navigate to settings and toggle the "Work Mode" option to "on".</p>'
      trainingIndependentInstrux = trainingIndependentInstrux + '<p style="margin-top: 20px;">When you navigate back to the home screen, it should now be a simple screen where all the timesheets and assignments are visible. A timesheet will be generated every week for you to submit, and assignments will be posted at the leisure of the hiring manager.</p>'

      const trainingCourseMetrics = [
        { name: 'Course Enrollments', label: 'Training Course Enrollments', shortname: 'trainingEnrollments', subtitle: 'Students who have either been enrolled or who have begun taking the career training course'},
        { name: 'Projects', label: 'Added a Project',  shortname: 'studentsWithProjects', subtitle: 'Students who have completed at least one project'},
        { name: 'Placements', label: 'Placed',  shortname: 'studentsWithPlacements', subtitle: 'Students who have been placed into at least one volunteer, internship, apprenticehip, or work opportunity'},
        { name: 'Timesheets', label: 'Submitted a Timesheet',  shortname: 'studentsWithTimesheets', subtitle: 'Students who have submitted at least one timesheet'},
        { name: 'Endorsements', label: 'Received an Endorsement',  shortname: 'studentsWithEndorsements', subtitle: 'Students who have received at least one competency endorsement'},
        { name: 'Course Completions', label: 'Training Course Completions',  shortname: 'trainingCompletions', subtitle: 'Students who have completed the career training course'},
      ]

      const trainingIndependentMetrics = [
        { name: 'Projects', label: 'Added a Project',  shortname: 'studentsWithProjects', subtitle: 'Students who have completed at least one project'},
        { name: 'Placements', label: 'Placed',  shortname: 'studentsWithPlacements', subtitle: 'Students who have been placed into at least one volunteer, internship, apprenticehip, or work opportunity'},
        { name: 'Timesheets', label: 'Submitted a Timesheet',  shortname: 'studentsWithTimesheets', subtitle: 'Students who have submitted at least one timesheet'},
        { name: 'Endorsements', label: 'w/ Endorsements',  shortname: 'studentsWithEndorsements', subtitle: 'Students who have received at least one competency endorsement'},
      ]

      const trainingPrimaryCID = "6424e1b1470d9a1af4738913"
      const trainingCIDs = ["648a624d70d49c0bf65dfdb5",trainingPrimaryCID]

      const entrepreneurshipSubtitle = 'Guide students to learn about and practice entrepreneurship. This includes ideating, starting a business, growing a business, and optimizing side hustles. Optional: A.I. can be used to help guide students, auto-create documents, and grade assignments.'

      const entrepreneurshipObjectives = [
        'Assess and identify readiness/ability/aptitude for entrepreneurship',
        'Identify the value proposition of an entrepreneurial venture',
        'Identify how entrepreneurship can impact their lives and society',
        'Identify how to generate ideas',
        'Identify how to evaluate, validate, and select ideas',
        'Learn how to flesh out your business idea and generate a comprehensive business plan',
        'Identify key strategies for growing businesses',
      ]

      const entrepreneurshipCourseSteps = [
        { title: '[Optional] ChatGPT and Generative A.I. 101 Course', body: ' - import this course into your community and make sure it is visible to students. Students can then take this course from the "Learning" tab. Guided Compass is integrated with ChatGPT, so this course is crucial for successfully navigating the entrepreneurship course. (60 mins)'},
        { title: 'Entrepreneurship Course', body: ' - import this course into your ' + orgName + ' community and make sure it is visible to students. Students can then take this course from the "Learning" tab. (60 mins)'},
        { title: null, body: 'Educators (i.e., you) review and grade student submissions from the course. (60 mins)'},
      ]

      const entrepreneurshipIndependentSteps = [
        { title: 'Project (Students):', body: ' if students already have an existing business or project, they can add the project to their profile. (30 mins)'},
        { title: 'Business Plan (Students):', body: ' students each generate a business plan. If A.I. is enabled, A.I. can be used to think of a business idea and auto-generate a business plan given a chosen idea. (120 mins)'},
        { title: 'Business Plan (Educators):', body: ' educators review and grade business plans. Educators can use A.I. to auto-grade business plans with comments. (120 mins)'},
        { title: 'Mentors (Optional):', body: ' educators invite mentors who have passed background checks to join mentor portal to provide business plan feedback. (120 mins)'},
      ]

      let entrepreneurshipIndependentInstrux = '<p>Open the Guided Compass app. You should be directed to a home page that has a social feed and a blue navigation bar across the top.independently explore careers, you will primarily be visiting two pages: 1) the Edit Profile Page and 2) the Document Builder Page.</p>'
      entrepreneurshipIndependentInstrux = entrepreneurshipIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Edit Basic Profile Info Page</label>: students may navigate to "Edit Profile Info" under the "Profile" dropdown in the top navigation bar. Students can add projects in this section, which may include details of existing ventures.</p>'
      entrepreneurshipIndependentInstrux = entrepreneurshipIndependentInstrux + '<p style="margin-top: 20px;"><label style="font-weight: bold;">Document Builder Page</label>: students may navigate to "Document Builder" under the "Profile" dropdown in the top navigation bar. Students can then edit and save the "Business Plan" section of the page.</p>'

      const entrepreneurshipCourseMetrics = [
        { name: 'Course Enrollments', label: 'Entrepreneurship Course Enrollments', shortname: 'entrepreneurshipEnrollments', subtitle: 'Students who have either been enrolled or who have begun taking the entrepreneurship course'},
        { name: 'Projects Completed', label: 'w/ Projects',  shortname: 'studentsWithProjects', subtitle: 'Students who have completed at least one project'},
        { name: 'Business Plans Completed', label: 'w/ Business Plans',  shortname: 'studentsWithBusinessPlans', subtitle: 'Students who have completed at least one business plan'},
        { name: 'Course Completions', label: 'Entrepreneurship Course Completions',  shortname: 'explorationCompletions', subtitle: 'Students who have completed the entrepreneurship course'},
      ]

      const entrepreneurshipIndependentMetrics = [
        { name: 'Projects Completed', label: 'w/ Projects',  shortname: 'studentsWithProjects', subtitle: 'Students who have completed at least one project'},
        { name: 'Business Plans Completed', label: 'w/ Business Plans',  shortname: 'studentsWithBusinessPlans', subtitle: 'Students who have completed at least one business plan'},
      ]

      const entrepreneurshipPrimaryCID = "6424e1b1470d9a1af4738915"
      const entrepreneurshipCIDs = ["648a624d70d49c0bf65dfdb5",entrepreneurshipPrimaryCID]

      let subNavCategories = [
        { title: 'Exploration', fullTitle: 'Career Awareness & Exploration', subtitle: explorationSubtitle, objectives: explorationObjectives, courseSteps: explorationCourseSteps, independentSteps: explorationIndependentSteps, primaryCID: explorationPrimaryCID, curriculumItemIds: explorationCIDs, courseMetrics: explorationCourseMetrics, independentMetrics: explorationIndependentMetrics, independentInstrux: explorationIndependentInstrux },
        { title: 'Planning', fullTitle: 'Career & Financial Planning', subtitle: planningSubtitle, objectives: planningObjectives, courseSteps: planningCourseSteps, independentSteps: planningIndependentSteps, primaryCID: planningPrimaryCID, curriculumItemIds: planningCIDs, courseMetrics: planningCourseMetrics, independentMetrics: planningIndependentMetrics, independentInstrux: planningIndependentInstrux },
        { title: 'Preparation', fullTitle: 'Career Preparation', subtitle: prepSubtitle, objectives: prepObjectives, courseSteps: prepCourseSteps, independentSteps: prepIndependentSteps, primaryCID: prepPrimaryCID, curriculumItemIds: prepCIDs, courseMetrics: prepCourseMetrics, independentMetrics: prepIndependentMetrics, independentInstrux: prepIndependentInstrux },
        { title: 'Training', fullTitle: 'Career Training & Advancement', subtitle: trainingSubtitle, objectives: trainingObjectives, courseSteps: trainingCourseSteps, independentSteps: trainingIndependentSteps, primaryCID: trainingPrimaryCID, curriculumItemIds: trainingCIDs, courseMetrics: trainingCourseMetrics, independentMetrics: trainingIndependentMetrics, independentInstrux: trainingIndependentInstrux },
        { title: 'Entrepreneurship', fullTitle: 'Entrepreneurship', subtitle: entrepreneurshipSubtitle, objectives: entrepreneurshipObjectives, courseSteps: entrepreneurshipCourseSteps, independentSteps: entrepreneurshipIndependentSteps, primaryCID: entrepreneurshipPrimaryCID, curriculumItemIds: entrepreneurshipCIDs, courseMetrics: entrepreneurshipCourseMetrics, independentMetrics: entrepreneurshipIndependentMetrics, independentInstrux: entrepreneurshipIndependentInstrux }
      ]

      if (this.state.showModule2) {
        subNavCategories.unshift({ title: 'All' })
      }

      // const subNavSelected = { title: 'Exploration', fullTitle: 'Career Awareness & Exploration', subtitle: 'Guide students to learn about themselves and their most suitable careers. This includes general information about people’s match to career paths and the career paths themselves. Optional: A.I. can be used to help guide students and grade assignments.'}
      let subNavSelected = subNavCategories[0]

      return { subNavCategories, subNavSelected }

    }

    calculateCheckIns(sessions, scholarEmail) {
      console.log('calculateCheckIns called', sessions, scholarEmail)

      let completionCount = 0

      for (let i = 1; i <= sessions.length; i++) {
        if (sessions[i - 1].adviseeEmail === scholarEmail) {

          let sessionMonth = new Date(sessions[i - 1].createdAt).getMonth()

          let currentMonth = new Date().getMonth()
          if (sessionMonth === currentMonth) {
            completionCount = completionCount + 1
          }
        }
      }

      const checkInStatus = completionCount.toString() + ' / 2'
      this.setState({ checkInStatus })

    }

    renderFollows(type) {
      console.log('renderFollows ', type)

      let rows = []

      if (this.state.types.includes(type) && this.state.favorites) {

        let hasFollows = false
        let items = this.state.favorites

        for (let i = 1; i <= items.length; i++) {

          const index = i - 1

          if (this.state.types[i - 1]) {
            if (this.state.types[i - 1] === type) {
              hasFollows = true

              if (type === 'student') {

                let subtitle = items[index].school
                if (items[index].gradYear && items[index].gradYear !== '') {
                  subtitle = items[index].gradYear + ' Grad Year'
                }

                rows.push(
                  <div key={type + i.toString()}>
                    <div>
                      <div className="spacer"/><div className="half-spacer"/>

                      <Link to={'/advisor/advisees/' + items[index].email} state={{ advisee: items[index], favorites: this.state.favorites }} className="background-link">
                        <span className="category-icon-container">
                          <img src={items[index].pictureURL ? items[index].pictureURL : profileIconGrey} alt="Compass profile icon icon" className="row-icon"/>
                        </span>
                        <span className="relative-column-65 left-padding-20">
                          <div className="table-row-content-title-2">
                              <p>{items[index].firstName} {items[index].lastName}</p>
                          </div>
                          <p className="table-row-content-text-2 curtail-text">{subtitle}</p>
                        </span>
                      </Link>
                      <div className="float-right left-padding">
                        <span className="float-right">
                          <div className="spacer"/><div className="half-spacer"/>
                          <Link to={'/advisor/advisees/' + items[index].email} state={{ advisee: items[index], favorites: this.state.favorites }} className="background-link">
                            <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="clear" />

                    <div className="spacer" /><div className="half-spacer" />

                    <hr />

                  </div>
                )
              } else if (type === 'problem') {

                rows.push(
                  <div key={type + i.toString()}>
                    <div className="spacer"/>
                    <Link to={'/advisor/opportunities/' + items[index]._id} state={{ selectedProblem: items[index], source: 'Student' }} className="background-link">
                      <div className="fixed-column-70">
                        <img src={problemIconBlue} alt="Compass arrow indicator icon" className="image-auto-48"/>
                      </div>
                      <div className="calc-column-offset-102">
                        <p className="heading-text-5">{items[i - 1].name}</p>
                        <p className="description-text-2 curtail-text">{items[i - 1].firstName} {items[i - 1].contributorTitle} @ {items[i - 1].employerName} | {items[i - 1].industry} Industry</p>
                        <p className="description-text-2 curtail-text">{items[i - 1].industry} Industry | {items[i - 1].difficultyLevel}</p>
                      </div>
                    </Link>
                    <div className="float-left left-padding right-text">
                      <div className="spacer"/><div className="half-spacer"/>
                      <Link to={'/advisor/opportunities/'  + items[index]._id} state={{ selectedProblem: items[index] }} className="background-link">
                        <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22 pin-right"/>
                      </Link>
                    </div>
                    <div className="clear"/>
                    <div className="spacer" /><div className="spacer" />
                    <hr s/>
                    <div className="clear"/>
                    <div className="spacer"/>
                  </div>
                )
              }
            }
          }
        }

        if (!hasFollows) {
          rows.push(
            <div key={type}>
              <p>You do not follow any {type}s yet</p>
            </div>
          )
        }
      } else {
        rows.push(
          <div key={type}>
            <p>You do not follow any {type}s yet</p>
          </div>
        )
      }

      return rows
    }

    removeItem(type,index){
      console.log('removeItem called', type)

      if (type === 'lesson') {
        let lessons = this.state.lessons
        const deleteId = lessons[index]._id

        Axios.delete('/api/lessons/' + deleteId)
        .then((response) => {
          console.log('tried to delete', response.data)
          if (response.data.success) {
            //save values
            console.log('Lessons delete worked');

            lessons.splice(index,1)
            this.setState({ lessons })

          } else {
            console.error('there was an error deleting the log');
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The deleting did not work', error);
            this.setState({ errorMessage: error })
        });
      }
    }

    toggleVisiblity(index,change, type) {
      console.log('toggleVisiblity called', index, change, type)

      if (type === 'course') {

        let courses = this.state.courses
        const courseObject = { _id: courses[index]._id, isActive: change }
        // console.log('show the courseObject: ', courseObject)
        Axios.post('/api/courses', courseObject).then((response) => {
          console.log('attempting to save course')
          if (response.data.success) {
            console.log('saved course', this.props)

            courses[index]['isActive'] = change
            this.setState({ courses })

          } else {
            console.log('did not save successfully', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'Course save did not work' })
        });

      } else {

        let lessons = this.state.lessons
        const lessonObject = { _id: lessons[index]._id, isVisible: change }

        Axios.post('/api/lessons', lessonObject).then((response) => {
          console.log('attempting to save lesson')
          if (response.data.success) {
            console.log('saved lesson', this.props)

            lessons[index]['isVisible'] = change
            let updateArrangeLessons = this.state.updateArrangeLessons
            if (updateArrangeLessons) {
              updateArrangeLessons = false
            } else {
              updateArrangeLessons = true
            }
            console.log('lessons: ', lessons)
            this.setState({ lessons, updateArrangeLessons })

          } else {
            console.log('did not save successfully', response.data.message)
            this.setState({ errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ errorMessage: 'Lesson save did not work' })
        });
      }
    }

    closeModal() {
      console.log('closeModal claled')

      this.setState({ modalIsOpen: false, showPriceChart: false, showLessons: false,
        showLessonDetails: false, selectedLesson: null, selectedIndex: null,
        showLMSInstrux: false, showEditCourse: false, exchangeType: null, showPortalInfo: false,
        showAddStudentInstrux: false, showSubmissionInstrux: false,
        showGradeInstrux: false, showStudents: false,
        metricTitle: false, metricSubtitle: false, metricObjects: null,
        showChangePassword: false,
      })
    }

    passLesson(lesson, addLesson, index, newlyCreated,preexistingId, synced) {
      console.log('passLesson called: ', lesson, addLesson, index, newlyCreated,preexistingId)

      let lessons = this.state.lessons
      if (preexistingId) {
        lessons[index] = lesson
      } else if (addLesson) {
        if (lessons) {
          // console.log('compare one: ', this.state.lessons)
          if (lessons.findIndex(l => l.curriculumItem === lesson.curriculumItemId) > -1) {

            const deleteIndex = lessons.findIndex(l => l.curriculumItem === lesson.curriculumItemId)
            console.log('removing in addLesson', deleteIndex)
            lessons.splice(deleteIndex,1)
            addLesson = false
          } else {

            if (newlyCreated) {
              lessons.push(lesson)
            }

            console.log('compare two: ', lessons.length)
          }
        } else {
          lessons = [lesson]
        }
      } else {
        console.log('about to delete', index)
        if (!synced) {
          lessons.splice(index,1)
        }
      }
      console.log('compare three: ', lessons.length)

      // componentDidUpdate not working in SubArrangeLessons
      let updateArrangeLessons = this.state.updateArrangeLessons
      if (updateArrangeLessons) {
        updateArrangeLessons = false
      } else {
        updateArrangeLessons = true
      }
      this.setState({ lessons, successMessage: 'Changes saved!', updateArrangeLessons   })
    }

    passItem(course, addCourse, index, newlyCreated,preexistingId, synced) {
      console.log('passItem called: ', course, addCourse, index, newlyCreated,preexistingId)

      let courses = this.state.courses
      if (preexistingId) {
        courses[index] = course
      } else if (addCourse) {
        if (courses) {
          if (courses.findIndex(l => l.curriculumItem === course.curriculumItemId) > -1) {

            const deleteIndex = courses.findIndex(l => l.curriculumItem === course.curriculumItemId)
            courses.splice(deleteIndex,1)
            addCourse = false
          } else {

            if (newlyCreated) {
              courses.push(course)
            }
          }
        } else {
          courses = [course]
        }
      } else {
        console.log('about to delete', index)
        if (!synced) {
          courses.splice(index,1)
        }
      }

      this.setState({ courses, successMessage: 'Changes saved!', })
    }

    toggleSteps(index) {

      let hideSteps = this.state.hideSteps
      if (!hideSteps) {
        hideSteps = []
        hideSteps[index] = true
      } else {
        if (hideSteps[index]) {
          hideSteps[index] = false
        } else {
          hideSteps[index] = true
        }
      }

      this.setState({ hideSteps })
    }

    addNewCourse(courseObject) {
      console.log('addNewCourse called', courseObject)

      let courses = this.state.courses
      courses.splice(courses.length - 2,0,courseObject)
      this.closeModal()
      // const showEditCourse = false
      // const modalIsOpen = false
      // this.setState({ courses, showEditCourse })
    }

    updateCourse(courseObject, deleteCourse) {
      console.log('updateCourse called', courseObject, deleteCourse)

      const selectedIndex = this.state.selectedIndex
      let courses = this.state.courses

      if (deleteCourse) {
        courses.splice(selectedIndex,1)
      } else {
        courses[selectedIndex] = courseObject
      }

      this.closeModal()
      // const showEditCourse = false
      // this.setState({ courses, showEditCourse })

    }

    openCurriculumExchange(exchangeType) {
      console.log('openCurriculumExchange called')

      this.setState({ modalIsOpen: true, showLessons: true, exchangeType })

    }

    configureHiddenProfile(type, item) {
      console.log('configureHiddenProfile called')

      let returnedIcon = invisibleIconBlue
      let returnedText = "This course is hidden from students. Click to make visible."
      if (this.state.courses && this.state.courses.some(c => c.curriculumItemId === item.curriculumItemId)) {
        const index = this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)
        if (item.curriculumItemId === this.state.courses[index].curriculumItemId) {
          if (this.state.courses[index].isActive) {
            returnedIcon = visibleIconBlue
            returnedText = "This course is visible to students. Click to hide."
          }
        }
      }

      if (type === 'text') {
        return returnedText
      } else {
        return returnedIcon
      }
    }

    determineWhetherImported(item) {
      console.log('determineWhetherImported')

      let isImported = false
      if (item) {
        if (this.state.courses && this.state.courses.some(c => c.curriculumItemId === item.curriculumItemId)) {
          const index = this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)

          if (item.curriculumItemId === this.state.courses[index].curriculumItemId) {
            isImported = true
          }
        }
      }

      return isImported

    }

    importCourse(value) {
      console.log('importCourse called', value)

      let course = {
        curriculumItemId: value.curriculumItemId, description: value.description, educationalInstitution: this.state.educationalInstitution,
        email: this.state.emailId, firstName: this.state.cuFirstName, lastName: this.state.cuLastName,
        gradeLevels: value.gradeLevels, name: value.name,minutes: value.minutes, pictureURL: value.pictureURL,
        learningModules: value.learningModules, learningObjectives: value.learningObjectives, prerequisites: value.prerequisites,
        syllabus: value.syllabus, announcements: value.announcements, assignments: value.assignments,
        quizzes: value.quizzes, imageURL: value.imageURL, isActive: true,
        orgCode: this.state.activeOrg, availableToEveryone: true,
        startDate: new Date(), endDate: new Date(),
        createdAt: new Date(),updatedAt: new Date(),
      }

      Axios.post('/api/courses', course).then((response) => {
        console.log('attempting to save course')
        if (response.data.success) {
          console.log('saved course', response.data)

          course['_id'] = response.data._id

          let courses = this.state.courses
          if (!courses) {
            courses = [{course}]
          } else {
            courses.push(course)
          }

          this.setState({ courses, successMessage: 'Course saved!' })
          // if (this.props.passItem) {
          //   this.props.passItem(course, addCourse, deleteIndex, false, null)
          // }

        } else {
          console.log('did not save successfully', response.data.message)
          this.setState({ errorMessage: 'error:' + response.data.message })
        }
      }).catch((error) => {
          console.log('save did not work', error);
          this.setState({ errorMessage: 'Curriculum item save did not work' })
      });
    }

    renderCourses(passedItem) {
      console.log('renderCourses called', passedItem)

      let rows = []

      if (this.state.generalCourses) {
        let counter = 0
        for (let i = 1; i <= this.state.generalCourses.length; i++) {
          const item = this.state.generalCourses[i - 1]
          const index = i - 1


          if (passedItem.curriculumItemIds.includes(item.curriculumItemId)) {
            counter = counter + 1
            rows.push(
              <div key={"generalCourses|" + item.title + "|" + item._id} className="top-margin">
                <div>
                  <button onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + this.state.courses[this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)]._id)} disabled={(this.determineWhetherImported(item)) ? false : true} className={(this.determineWhetherImported(item)) ? "background-button left-text row-10 calc-column-offset-25" : "background-button left-text row-10 calc-column-offset-70 no-pointers"}>
                    <div className="fixed-column-40">
                      <p>{counter}.</p>
                    </div>
                    <div className="fixed-column-80 right-padding top-margin-3">
                      <img src={item.imageURL} alt="GC" className="full-width contain-object-fit pin-left"/>
                    </div>
                    <span className="calc-column-offset-120">
                      <p className={(this.determineWhetherImported(item)) ? "bold-text cta-color" : "bold-text"}>{item.name}</p>
                      <div className="half-spacer" />

                      <div className="float-left description-text-3 left-text full-width">
                        <p>{item.minutes} Minutes</p>
                      </div>
                      <div className="clear" />
                    </span>

                    <div className="clear" />
                  </button>

                  <div className={(this.determineWhetherImported(item)) ? "fixed-column-25" : "fixed-column-70"}>
                    {(this.state.courses && this.state.courses.some(c => c.curriculumItemId === item.curriculumItemId)) ? (
                      <span className="float-left">
                        {/*<img src={checkmarkIcon} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"This course is part of your community"} />*/}
                      </span>
                    ) : (
                      <button className="background-button right-margin-20 float-left" onClick={() => this.importCourse(item)}>
                        <img src={downloadIconBlue} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Import course to " + this.state.orgName + " community"} />
                      </button>
                    )}

                    {(this.determineWhetherImported(item)) ? (
                      <button className="background-button float-left" onClick={() => this.toggleVisiblity(this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId), !this.state.courses[this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)].isActive, 'course')}>
                        <img src={this.configureHiddenProfile('icon',item)} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={this.configureHiddenProfile('text',item)} />
                      </button>
                    ) : (
                      <button className="background-button float-left" onClick={() => window.open('/courses/' + item._id)}>
                        <img src={linkIconBlue} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View a preview of the course content" />
                      </button>
                    )}
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>

                <ReactTooltip id="tooltip-placeholder-id" />
              </div>
            )
          }
        }
      }

      return rows

      // {this.state.explorationCourses.map((item, index) =>
      //   <div className="full-width top-margin">
      //     {(item.name.includes('Exploration') || item.name.startsWith('ChatGPT')) && (
      //       <div>
      //         <button onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + this.state.courses[this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)]._id)} disabled={(this.determineWhetherImported(item)) ? false : true} className={(this.determineWhetherImported(item)) ? "background-button left-text row-10 calc-column-offset-25" : "background-button left-text row-10 calc-column-offset-70 no-pointers"}>
      //           <div className="fixed-column-40">
      //             <p>{index + 1}.</p>
      //           </div>
      //           <div className="fixed-column-80 right-padding top-margin-3">
      //             <img src={item.imageURL} alt="GC" className="full-width contain-object-fit pin-left"/>
      //           </div>
      //           <span className="calc-column-offset-120">
      //             <p className={(this.determineWhetherImported(item)) ? "bold-text cta-color" : "bold-text"}>{item.name}</p>
      //             <div className="half-spacer" />
      //
      //             <div className="float-left description-text-3 left-text full-width">
      //               <p>{item.minutes} Minutes</p>
      //             </div>
      //             <div className="clear" />
      //           </span>
      //
      //           <div className="clear" />
      //         </button>
      //
      //         <div className={(this.determineWhetherImported(item)) ? "fixed-column-25" : "fixed-column-70"}>
      //           {(this.state.courses && this.state.courses.some(c => c.curriculumItemId === item.curriculumItemId)) ? (
      //             <span className="float-left">
      //               {/*<img src={checkmarkIcon} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"This course is part of your community"} />*/}
      //             </span>
      //           ) : (
      //             <button className="background-button right-margin-20 float-left" onClick={() => this.importCourse(item)}>
      //               <img src={downloadIconBlue} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Import course to " + this.state.orgName + " community"} />
      //             </button>
      //           )}
      //
      //           {(this.determineWhetherImported(item)) ? (
      //             <button className="background-button float-left" onClick={() => this.toggleVisiblity(this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId), !this.state.courses[this.state.courses.findIndex(c => c.curriculumItemId === item.curriculumItemId)].isActive, 'course')}>
      //               <img src={this.configureHiddenProfile('icon',item)} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={this.configureHiddenProfile('text',item)} />
      //             </button>
      //           ) : (
      //             <button className="background-button float-left" onClick={() => window.open('/courses/' + item._id)}>
      //               <img src={linkIconBlue} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="View a preview of the course content" />
      //             </button>
      //           )}
      //           <div className="clear" />
      //         </div>
      //         <div className="clear" />
      //       </div>
      //     )}
      //   </div>
      // )}
    }

    navClicked(navSelected) {
      console.log('navClicked called')

      this.setState({ navSelected })
      localStorage.setItem('dashboardNavSelected', navSelected)

    }

    subNavClicked(subNavSelected) {
      console.log('subNavClicked called', subNavSelected)

      // select primary course
      let primaryCourse = null
      if (this.state.generalCourses) {
        for (let i = 1; i <= this.state.generalCourses.length; i++) {
          if (this.state.generalCourses[i - 1].curriculumItemId === subNavSelected.primaryCID) {
            primaryCourse = this.state.generalCourses[i - 1]
          }
        }
      }

      this.setState({ subNavSelected, primaryCourse })

      // update metrics
      this.pullMetrics(this.state.activeOrg, subNavSelected, this.state.subNavCategories)
    }

    renderMetrics(item) {
      console.log('renderMetrics called')

      let metrics = item.courseMetrics
      if (this.state.stepsType === "Don't Use Course") {
        metrics = item.independentMetrics
      }

      let rows = []

      if (metrics && metrics.length > 0) {
        for (let i = 1; i <= metrics.length; i++) {
          const index = i - 1

          let variablePadding = "right-padding"
          if (index % 3 === 1) {
            variablePadding = "left-padding-5 right-padding-5"
          } else if (index % 3 === 2) {
            variablePadding = "left-padding"
          }

          rows.push(
            <div key={item.title + "|metric|" + this.state.stepsType + "|" + index}>
              <div className={"relative-column-33 top-margin " + variablePadding}>
                <div className="card-clear-padding">
                  <button className="background-button full-width center-text padding-20" onClick={() => this.setState({ modalIsOpen: true, showStudents: true, metricObjects: this.state[metrics[index].shortname], metricTitle: metrics[index].name, metricSubtitle: metrics[index].subtitle })}>
                    <p className="heading-text-4 cta-color">{(this.state[metrics[index].shortname]) ? this.state[metrics[index].shortname].length : "0"}</p>
                    <p className="description-text-2 top-margin">{metrics[index].label}</p>
                  </button>
                </div>
              </div>
              {(index % 3 === 2) && (
                <div className="clear" />
              )}
            </div>
          )
        }

        rows.push(
          <div key={item.title + "|metric|" + this.state.stepsType + "|bookend"} className="clear" />
        )
      }

      return rows

    }

    renderStages() {
      console.log('renderStages called')

      let rows = []
      for (let i = 1; i <= this.state.subNavCategories.length; i++) {

        if (this.state.subNavSelected.title === 'All' || (this.state.subNavCategories[i - 1].title === this.state.subNavSelected.title)) {

          let item = this.state.subNavCategories[i - 1]
          if (this.state.subNavSelected.title === 'All') {
            if (this.state.subNavCategories[i]) {
              item = this.state.subNavCategories[i]
            } else {
              item = null
            }
          }
          const index = i - 1

          if (item) {
            rows.push(
              <div key={"stage|" + index}>
                <div className={"card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"}>
                  {(window.innerWidth > 768) && (
                    <div className="full-width height-5 primary-background" />
                  )}

                  <div className={(window.innerWidth > 768) ? "padding-30" : ""}>
                    <div className="full-width">

                      <div>
                        <p className="heading-text-2 full-width">{item.fullTitle}</p>
                        <p className="description-text-color full-width top-margin">{item.subtitle}</p>
                      </div>

                      <div className="top-margin">
                        <div className="top-margin row-20">
                          <p className="heading-text-4 full-width">Learning Objectives</p>

                          {(item.objectives) && (
                            <ul className="left-padding-20 top-margin">
                              {item.objectives.map((item, index) =>
                                <li key={item.title + "|o|" + index} className="row-3">{item}</li>
                              )}
                            </ul>
                          )}
                        </div>

                        <div className="row-20">
                          <p className="heading-text-4 full-width">Steps to Implement <label className="description-text-3 description-text-color">(Suggested)</label></p>

                          <div className="row-15">
                            {this.state.stepsTypeOptions.map((value, index) =>
                              <div className="display-inline description-text-2 bold-text">
                                {(this.state.stepsTypeOptions[index] === this.state.stepsType) ? (
                                  <div className="selected-carousel-item-2">
                                    <label key={value}>{value}</label>
                                  </div>
                                ) : (
                                  <button className="menu-button-2" onClick={() => this.setState({ stepsType: value })}>
                                    <label key={value}>{value}</label>
                                  </button>
                                )}
                              </div>
                            )}
                            <hr />
                          </div>

                          {(this.state.stepsType === 'Use Course') ? (
                            <div>
                              {(item.courseSteps) && (
                                <ol className="left-padding-20 top-margin">
                                  {item.courseSteps.map((item, index) =>
                                    <li key={item.title + "|" + this.state.stepsType + "|" + index} className="row-3">{(item.title) && <label className="bold-text">{item.title}</label>}{item.body}</li>
                                  )}
                                </ol>
                              )}
                            </div>
                          ) : (
                            <div>
                              {(item.independentSteps) && (
                                <ol className="left-padding-20 top-margin">
                                  {item.independentSteps.map((item, index) =>
                                    <li key={item.title + "|" + this.state.stepsType + "|" + index} className="row-3">{(item.title) && <label className="bold-text">{item.title}</label>}{item.body}</li>
                                  )}
                                </ol>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {(this.state.stepsType === 'Use Course') && (
                        <div className="row-20">
                          <p className="heading-text-4 full-width">Course(s)</p>

                          {this.renderCourses(item)}
                        </div>
                      )}

                      <div className="row-20">
                        <p className="heading-text-4 full-width">Instructions to Share With Students</p>

                        <div className="top-margin-20 standard-border padding-20">
                          {(this.state.stepsType === 'Use Course') ? (
                            <div>
                              <p>Students can navigate to the courses section of the "Learning" tab at the top of their navigation bar to take all active / visible courses. In this case, the "{item.fullTitle}" course should be .</p>
                              <p className="top-margin-20">Preview the student experience <Link to="/app/learning" target="_blank" rel="noopener noreferrer">here</Link>.</p>

                              {(!this.determineWhetherImported(this.state.primaryCourses[index])) && (
                                <div className="top-margin-20">
                                  <p className="error-color description-text-2 bold-text">Note: {(this.state.primaryColor) ? this.state.primaryCourse.name + " " : " Primary course "}has not yet been imported into your community. Import the course in the courses section above and make it visible.</p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {(item.independentInstrux) && (
                                <div dangerouslySetInnerHTML={{__html: item.independentInstrux}}></div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row-20">
                        <p className="heading-text-4 full-width">Deliverables & Metrics</p>

                        <div className="top-margin-20 bottom-margin-5">
                          {this.renderMetrics(item)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        }
      }

      return rows
    }

    closeHeader() {
      console.log('closeHeader called')
      this.setState({ showSurveyHeader: false })
    }

    render() {

      return (
          <div>
            {(window.location.pathname.startsWith('/organizations')) && (
              <div className="top-margin-40 padding-five-percent-left padding-five-percent-right">
                <div className="bottom-margin-20">
                  <div className={(this.props.showLayoutToggle) ? "calc-column-offset-90" : "full-width carousel-3"}>
                    <p className="heading-text-2">{this.state.orgName} Admin Dashboard</p>
                  </div>
                  {(this.props.showLayoutToggle) && (
                    <div className="fixed-column-90">
                      <p className="description-text-3 bottom-margin right-text">{(this.state.showModule2) ? "Toggle layout" : "Toggle layout"}</p>
                      <div className="float-right">
                        <Switch
                          onChange={(change) => this.props.toggleNavMode(change)}
                          checked={this.state.showModule2}
                          disabled={this.state.isSaving}
                          id="normal-switch"
                          className="pin-right"
                        />
                      </div>
                      <div className="clear" />
                    </div>
                  )}
                  <div className="clear" />
                </div>

                <div className="carousel-3">
                  {this.state.navCategories.map((value, index) =>
                    <div className="display-inline">
                      {(this.state.navCategories[index] === this.state.navSelected) ? (
                        <div className="selected-carousel-item-2">
                          <label key={value}>{value}</label>
                        </div>
                      ) : (
                        <button className="menu-button-2" onClick={() => this.navClicked(value)}>
                          <label key={value}>{value}</label>
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <hr />
              </div>
            )}

            {(this.state.navSelected === 'Members') && (
              <div className="padding-five-percent-left padding-five-percent-right">
                {(this.state.loadedDashboardLayout) && (
                  <SubMembers activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} dashboardLayout={this.state.dashboardLayout} roleName="Admin" type="All" />
                )}
              </div>
            )}
            {(this.state.navSelected === 'Key Metrics') && (
              <div>
                {(this.state.loadedDashboardLayout) && (
                  <SubKeyMetrics activeOrg={this.state.activeOrg} dashboardLayout={this.state.dashboardLayout}  />
                )}

              </div>
            )}
            {(this.state.navSelected === 'Guided Courses') && (
              <div>
                {(this.state.showSurveyHeader) && (
                  <div className="washed-error-background-color row-30">
                    <div className="relative-column-70 top-margin-5">
                      <p className="full-width right-text heading-text-6">5-Minute Anonymous Survey About Using Generative A.I.</p>
                      <p className="full-width right-text description-text-3 top-margin-5">We're writing a Generative A.I. in Education Research Paper and Guide. See a summary of all survey results after you add your responses!</p>
                    </div>
                    <div className="relative-column-30 top-margin-5">
                      <div className="calc-column-offset-40 left-padding-30">
                        <a className="btn btn-squarish-dark white-text" target="_blank" rel="noopener noreferrer" href="https://forms.gle/L2X3QgZgV5kHfm1C6">Take It Now</a>
                      </div>
                      <div className="fixed-column-40 top-margin">
                        <a onClick={() => this.closeHeader()}>
                          <img src={closeIcon} alt="GC" className="image-auto-15" />
                        </a>
                      </div>
                      <div className="clear"/>
                    </div>
                    <div className="clear" />
                  </div>
                )}
                <div className={(window.innerWidth > 768 && toggleVars.hideSideNav) && "relative-column-70 padding-five-percent-left"}>
                  {(this.state.showModule) ? (
                    <div>
                      <div className="card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right">
                        {(window.innerWidth > 768 && toggleVars.hideSideNav) && (
                          <div className="full-width height-5 dark-background" />
                        )}

                        <div className={(window.innerWidth > 768) ? "padding-30" : ""}>
                          <div className="full-width">
                            <div>
                              <p className="heading-text-2 full-width">Hi {this.state.cuFirstName},</p>
                              <p className="description-text-color description-text-1 full-width top-margin">Welcome to the {this.state.orgName} Educator Portal. Learn about other portals <button className="background-button cta-color bold-text underline-text offset-underline" onClick={() => this.setState({ modalIsOpen: true, showPortalInfo: true })}>here</button>.</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={"full-width max-width-1400 top-margin-40 pin-right standard-border medium-shadow white-background carousel-3"}>
                        {this.state.subNavCategories.map((value, index) =>
                          <div className="display-inline">
                            {(this.state.subNavCategories[index].title === this.state.subNavSelected.title) ? (
                              <div className="selected-carousel-item-2">
                                <label key={value.title}>{value.title}</label>
                              </div>
                            ) : (
                              <button className="menu-button-2" onClick={() => this.subNavClicked(value)}>
                                <label key={value.title}>{value.title}</label>
                              </button>
                            )}
                          </div>
                        )}
                        <hr />
                      </div>

                      {(this.state.showModule2) ? (
                        <div>
                          {this.renderStages()}
                        </div>
                      ) : (
                        <div>
                          <div className={"card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"}>
                            {(window.innerWidth > 768) && (
                              <div className="full-width height-5 primary-background" />
                            )}

                            <div className={(window.innerWidth > 768) ? "padding-30" : ""}>
                              <div className="full-width">

                                <div>
                                  <p className="heading-text-2 full-width">{this.state.subNavSelected.fullTitle}</p>
                                  <p className="description-text-color full-width top-margin">{this.state.subNavSelected.subtitle}</p>
                                </div>

                                <div className="top-margin">
                                  <div className="top-margin row-20">
                                    <p className="heading-text-4 full-width">Learning Objectives</p>

                                    {(this.state.subNavSelected.objectives) && (
                                      <ul className="left-padding-20 top-margin">
                                        {this.state.subNavSelected.objectives.map((item, index) =>
                                          <li key={this.state.subNavSelected.title + "|o|" + index} className="row-3">{item}</li>
                                        )}
                                      </ul>
                                    )}
                                  </div>

                                  <div className="row-20">
                                    <p className="heading-text-4 full-width">Steps to Implement <label className="description-text-3 description-text-color">(Suggested)</label></p>

                                    <div className="row-15">
                                      {this.state.stepsTypeOptions.map((value, index) =>
                                        <div className="display-inline description-text-2 bold-text">
                                          {(this.state.stepsTypeOptions[index] === this.state.stepsType) ? (
                                            <div className="selected-carousel-item-2">
                                              <label key={value}>{value}</label>
                                            </div>
                                          ) : (
                                            <button className="menu-button-2" onClick={() => this.setState({ stepsType: value })}>
                                              <label key={value}>{value}</label>
                                            </button>
                                          )}
                                        </div>
                                      )}
                                      <hr />
                                    </div>

                                    {(this.state.stepsType === 'Use Course') ? (
                                      <div>
                                        {(this.state.subNavSelected.courseSteps) && (
                                          <ol className="left-padding-20 top-margin">
                                            {this.state.subNavSelected.courseSteps.map((item, index) =>
                                              <li key={this.state.subNavSelected.title + "|" + this.state.stepsType + "|" + index} className="row-3">{(item.title) && <label className="bold-text">{item.title}</label>}{item.body}</li>
                                            )}
                                          </ol>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        {(this.state.subNavSelected.independentSteps) && (
                                          <ol className="left-padding-20 top-margin">
                                            {this.state.subNavSelected.independentSteps.map((item, index) =>
                                              <li key={this.state.subNavSelected.title + "|" + this.state.stepsType + "|" + index} className="row-3">{(item.title) && <label className="bold-text">{item.title}</label>}{item.body}</li>
                                            )}
                                          </ol>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {(this.state.stepsType === 'Use Course') && (
                                  <div className="row-20">
                                    <p className="heading-text-4 full-width">Course(s)</p>

                                    {this.renderCourses(this.state.subNavSelected)}
                                  </div>
                                )}

                                <div className="row-20">
                                  <p className="heading-text-4 full-width">Instructions to Share With Students</p>

                                  <div className="top-margin-20 standard-border padding-20">
                                    {(this.state.stepsType === 'Use Course') ? (
                                      <div>
                                        <p>Students can navigate to the courses section of the "Learning" tab at the top of their navigation bar to take all active / visible courses. In this case, the "{this.state.subNavSelected.fullTitle}" course should be .</p>
                                        <p className="top-margin-20">Preview the student experience <Link to="/app/learning" target="_blank" rel="noopener noreferrer">here</Link>.</p>

                                        {(!this.determineWhetherImported(this.state.primaryCourse)) && (
                                          <div className="top-margin-20">
                                            <p className="error-color description-text-2 bold-text">Note: {(this.state.primaryColor) ? this.state.primaryCourse.name + " " : " Primary course "}has not yet been imported into your community. Import the course in the courses section above and make it visible.</p>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        {(this.state.subNavSelected.independentInstrux) && (
                                          <div dangerouslySetInnerHTML={{__html: this.state.subNavSelected.independentInstrux}}></div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="row-20">
                                  <p className="heading-text-4 full-width">Deliverables & Metrics</p>

                                  <div className="top-margin-20 bottom-margin-5">
                                    {this.renderMetrics(this.state.subNavSelected)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={(toggleVars.hideSideNav) && "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right"}>
                      {(window.innerWidth > 768 && toggleVars.hideSideNav) && (
                        <div className="full-width height-5 dark-background" />
                      )}

                      <div className={(window.innerWidth > 768 && toggleVars.hideSideNav) ? "padding-40" : ""}>
                        <div className={(toggleVars.hideSideNav) ? "full-width" : "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"} style={(toggleVars.hideSideNav) ? {} : {...styles, margin: '2% 2% 0 0', opacity: this.state.opacity, transform: this.state.transform }}>

                          <div className="top-padding">

                            <p className="heading-text-2 full-width">{this.state.orgName} Dashboard</p>

                            {(this.state.hideSteps && this.state.hideSteps[0]) ? (
                              <div className="top-margin-30">
                                <p className="description-text-2 full-width">Step 1 is hidden. <button className="background-button clear-padding clear-margin cta-color half-bold-text" onClick={() => this.toggleSteps(0)}>Click here</button> to unhide</p>
                              </div>
                            ) : (
                              <div className="thick-border padding-40 top-margin-30">
                                <div>
                                  <div className="calc-column-offset-60">
                                    <p className="heading-text-4 bold-text">Step 1: Add Curriculum</p>
                                  </div>
                                  <div className="fixed-column-60 top-padding-5 display-inline">
                                    <span className="right-margin-20">
                                      <button className="background-button clear-padding clear-margin" onClick={() => this.setState({ modalIsOpen: true, showLessons: true })}>
                                        <img src={addIconBlue} alt="GC" className="image-auto-18 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add Curriculum" />
                                      </button>
                                    </span>

                                    <button className="background-button clear-padding clear-margin" onClick={() => this.toggleSteps(0)}>
                                      <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Hide step temporarily" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div>
                                  {(this.state.courses && this.state.courses.length > 0) && (
                                    <div>
                                      <p className="heading-text-6 row-10 top-margin-20">Courses</p>

                                      <div className="description-text-1 standard-border padding-10">
                                      {/*
                                        <div className="fixed-column-40">
                                          <p className="full-width left-padding-5">D</p>
                                        </div>
                                        <div className="fixed-column-40">
                                          <p className="full-width left-padding-5">T</p>
                                        </div>*/}
                                        <div className="fixed-column-40">
                                          <p>#</p>
                                        </div>
                                        <div className="calc-column-offset-40">
                                          <div className="relative-column-90">
                                            <div className="relative-column-70">
                                              <p>Name</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="relative-column-10">
                                            <p>Visible</p>
                                          </div>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      {this.state.courses.map((course, index) =>
                                        <div className="cta-border padding-10">
                                          <div>
                                            <button onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + course._id)} className="background-button left-text row-10 calc-column-offset-60">
                                              {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}

                                              <div className="fixed-column-40">
                                                <p>{index + 1}.</p>
                                              </div>
                                              <span className="calc-column-offset-40">
                                                {/*
                                                {(course.imageURL) && (
                                                  <div className="full-width">
                                                    <img src={course.imageURL} alt="GC" className="full-width height-60 contain-object-fit pin-left"/>
                                                  </div>
                                                )}*/}

                                                <p className="half-bold-text cta-color">{course.name}</p>
                                                <div className="half-spacer" />

                                                <div className="float-left description-text-3 left-text full-width">
                                                  <p>{course.minutes} Minutes | {convertDateToString(new Date(course.startDate),"date-2")} - {(course.isPerpetual) ? "Continual" : convertDateToString(new Date(course.endDate),"datetime-2")}</p>
                                                  {(course.schoolName) && (
                                                    <div>
                                                      <label>{course.schoolName}</label>
                                                      <div className="clear" />
                                                      <div className="half-spacer" />
                                                    </div>
                                                  )}
                                                  {(course.gradeLevel) && (
                                                    <div>
                                                      <label>{course.gradeLevel} | {course.knowledgeLevel}</label>
                                                      <div className="clear" />
                                                    </div>
                                                  )}

                                                  {(course.functions && course.functions.length > 0) && (
                                                    <div>
                                                      <div className="half-spacer" />
                                                      <label className="description-text-color">Occupations: {course.functions.join()}</label>
                                                      <div className="clear" />
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="clear" />
                                              </span>

                                              <div className="clear" />
                                            </button>

                                            <div className="fixed-column-60">
                                              <Switch
                                                onChange={(change) => this.toggleVisiblity(index, change, 'course')}
                                                checked={course.isActive}
                                                id="normal-switch"
                                              />
                                            </div>
                                            <div className="clear" />
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(this.state.lessons && this.state.lessons.length > 0) && (
                                    <div>
                                      <p className="heading-text-6 row-10 top-margin-20">Independent Lessons</p>
                                      <SubArrangeLessons lessons={this.state.lessons} passLesson={this.passLesson} toggleVisiblity={this.toggleVisiblity} updateArrangeLessons={this.state.updateArrangeLessons}/>
                                    </div>
                                  )}

                                  <div className="clear" />
                                </div>

                                <p className="description-text-1 full-width top-margin-20"><button className="background-button half-bold-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showLessons: true })}>Click here</button> to import curriculum. Courses and lessons will appear in the student portal <Link to="/app/learning" target="_blank" rel="noopener noreferrer" className="half-bold-text cta-color">here</Link>.</p>

                              </div>
                            )}

                            {(this.state.hideSteps && this.state.hideSteps[1]) ? (
                              <div className="top-margin-30">
                                <p className="description-text-2 full-width">Step 2 is hidden. <button className="background-button clear-padding clear-margin cta-color half-bold-text" onClick={() => this.toggleSteps(1)}>Click here</button> to unhide</p>
                              </div>
                            ) : (
                              <div className="thick-border padding-40 top-margin-30">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4 bold-text">Step 2: Invite Students & Assign Courses/Lessons</p>
                                  </div>
                                  <div className="fixed-column-30 top-padding-5">
                                    <button className="background-button clear-padding clear-margin full-width" onClick={() => this.toggleSteps(1)}>
                                      <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Hide step temporarily" />
                                    </button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="spacer" /><div className="spacer" />

                                {(this.state.authType === "Clever") ? (
                                  <div className="description-text-1">
                                    <p className="full-width">Clever should automatically make an account available for your students after you install the app. The Clever website provides more details <a href="https://support.clever.com/hc/s/articles/360013488672?language=en_US#Header4" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                    <p className="full-width top-padding-20">To see what students see in their student portal, <Link to={'/app'}>click here</Link>.</p>
                                  </div>

                                ) : (
                                  <div className="description-text-1">
                                    <p className="full-width">When you installed the app from the Schoology LMS App Center, make sure you clicked the courses and groups that represent the students who you want to access. When the students sign in and go to the course or group within Schoology, they should be able to access the app.</p>
                                    <p className="full-width top-padding-20">To see what students see in their student portal, <Link to={'/app'}>click here</Link>.</p>
                                  </div>
                                )}
                              </div>
                            )}

                            {(this.state.hideSteps && this.state.hideSteps[2]) ? (
                              <div className="top-margin-30">
                                <p className="description-text-2 full-width">Step 3 is hidden. <button className="background-button clear-padding clear-margin cta-color half-bold-text" onClick={() => this.toggleSteps(2)}>Click here</button> to unhide</p>
                              </div>
                            ) : (
                              <div className="thick-border padding-30 top-margin-30">
                                <div>
                                  <div>
                                    <div className="calc-column-offset-30">
                                      <p className="heading-text-4 bold-text">Step 3: Learn About ChatGPT and Generative A.I.</p>
                                    </div>
                                    <div className="fixed-column-30 top-padding-5">
                                      <button className="background-button clear-padding clear-margin full-width" onClick={() => this.toggleSteps(2)}>
                                        <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Hide step temporarily" />
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer" /><div className="spacer" />

                                  <p className="description-text-1">ChatGPT is the fastest growing app ever. Some schools banned it, others embraced it. Guided Compass has integrated with it to assist educators. Learn best practices taken straight from OpenAI (creator of ChatGPT) in the below course.</p>

                                  {(this.state.introToAICourse) && (
                                    <button onClick={() => this.props.navigate(this.state.prefix + '/courses/' + this.state.introToAICourse._id + '/take')} className="background-button left-text full-width cta-border padding-20 top-margin-20">
                                      {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                      <span className="fixed-column-70">
                                        <img src={(this.state.introToAICourse.imageURL) ? this.state.introToAICourse.imageURL : courseIconBlue} alt="GC" className="image-60-auto"/>
                                      </span>
                                      <span className="calc-column-offset-100 left-padding-20">
                                        <p className="heading-text-5">{this.state.introToAICourse.name}</p>
                                        <div className="half-spacer" />

                                        <div className="description-text-2 left-text full-width">
                                          <p>~ 30 minutes{(this.state.introToAICourse.promoText) && <label className="cta-color"> | {this.state.introToAICourse.promoText}</label>}</p>
                                        </div>
                                        <div className="clear" />
                                      </span>
                                      <div className="fixed-column-30">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22 pin-right top-margin-5"/>
                                      </div>
                                      <div className="clear" />
                                    </button>
                                  )}

                                </div>
                              </div>
                            )}

                            {(this.state.hideSteps && this.state.hideSteps[3]) ? (
                              <div className="top-margin-30">
                                <p className="description-text-2 full-width">Step 4 is hidden. <button className="background-button clear-padding clear-margin cta-color half-bold-text" onClick={() => this.toggleSteps(3)}>Click here</button> to unhide</p>
                              </div>
                            ) : (
                              <div className="thick-border padding-30 top-margin-30">
                                <div>
                                  <div>
                                    <div className="calc-column-offset-30">
                                      <p className="heading-text-4 bold-text">Step 4: Use A.I. to Auto-Generate Lesson Plans and Auto-Grade</p>
                                    </div>
                                    <div className="fixed-column-30 top-padding-5">
                                      <button className="background-button clear-padding clear-margin full-width" onClick={() => this.toggleSteps(3)}>
                                        <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Hide step temporarily" />
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer" /><div className="spacer" />

                                  <p className="description-text-1">Make Compass Assistant, powered by ChatGPT and other tech, work for you. Some example commands:</p>

                                  <div className="row-20">
                                    <ul className="left-padding-25">
                                      <li>Generate a comprehensive lesson plan for the first four weeks of my 10th grade Business 101 class. We meet for 1.5 hours on Tuesday and Thursdays.</li>
                                      <li>Generate an answer to the following question, and then determine if the student's answer is correct. The question is "What is 2 + 2?". The student answered "3".</li>
                                      <li>Grade the student's response on a scale of 0% to 100%. Give the student a 0% if you determine that it is over 90% likely that the student plagiarized. The student is an above average ninth grader. The question is "What are three reasons why the advancement of A.I. is a negative for mankind?" The student wrote "1) A.I. will turn on mankind; 2) Students no longer need to learn; 3) Jobs are automated away". Generate your own answer before grading the student's response.</li>
                                    </ul>
                                  </div>

                                  <div className="row-10">
                                    <button className="btn btn-squarish" onClick={() => this.props.openAssistant()}>Try It</button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {(this.state.hideSteps && this.state.hideSteps[4]) ? (
                              <div className="top-margin-30">
                                <p className="description-text-2 full-width">Step 5 is hidden. <button className="background-button clear-padding clear-margin cta-color half-bold-text" onClick={() => this.toggleSteps(4)}>Click here</button> to unhide</p>
                              </div>
                            ) : (
                              <div className="thick-border padding-30 top-margin-30">
                                <div>
                                  <div>
                                    <div className="calc-column-offset-30">
                                      <p className="heading-text-4 bold-text">Step 5: Set Up a Dedicated School Community</p>
                                    </div>
                                    <div className="fixed-column-30 top-padding-5">
                                      <button className="background-button clear-padding clear-margin full-width" onClick={() => this.toggleSteps(4)}>
                                        <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Hide step temporarily" />
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <div className="spacer" /><div className="spacer" />

                                  <p className="description-text-1">A Guided Compass dedicated community is like a personalizable "LinkedIn for Youth". When students graduate, with parent permission, they can transfer their accounts to an alumni accounts, continue to upskill, and get referred for relevant opportunities.  Alumni accounts are free to schools forever with the purchase of a site license. In addition, a dedicated community includes portals for administrators, parents, employers, and mentors. Our goal is to help schools increase funding through donations, increased enrollment, and improved outcomes.</p>

                                  <p className="description-text-1 top-margin-20">Pricing is dependent on the number of students. View the price chart <button className="background-button cta-color" onClick={() => this.setState({ modalIsOpen: true, showPriceChart: true})}>here</button>.</p>

                                  <div className="row-10 top-margin">
                                    <p className="description-text-1 top-margin-20">Email <label className="cta-color">help@guidedcompass.com</label> to schedule a consultation.</p>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="clear" />
                            <div className="spacer" />
                          </div>
                        </div>

                        {(this.state.favorites && this.state.favorites.length > 0) && (
                          <div className="floating-container top-margin-2-percent right-margin-2-percent card" style={{...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '20px'}}>
                            <div className="card-title-container">
                              <p className="advisor-title">Students You Follow</p>
                            </div>
                            <div className="relative-column-30">
                              <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                              <Link to={'/advisor/advisees'} className="background-link">
                                <img src={addIcon} alt="Compass add icon" className="image-auto-22 pin-right"/>
                              </Link>
                            </div>

                            <div className="clear" />
                            <div className="spacer"/>

                            {this.renderFollows('student')}
                          </div>
                        )}

                        <ReactTooltip id="tooltip-placeholder-id" />

                      </div>
                    </div>
                  )}
                </div>

                {(window.innerWidth > 768) && (
                  <div className="relative-column-30 horizontal-padding top-margin-40">
                    <div className="left-padding-20">
                      {/*
                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">Updates</p>

                          <div className="row-20">
                            <p className="error-color description-text-2">No updates available</p>
                          </div>

                        </div>
                      </div>

                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">Bookmarked Pages</p>

                          <div>
                            {(this.state.bookmarkedPages && this.state.bookmarkedPages.length > 0) ? (
                              <div className="top-padding bottom-padding-20">
                                {this.state.bookmarkedPages.map((item,optionIndex) =>
                                  <div key={item.name} className="top-margin-15">
                                    <div className="fixed-column-30">
                                      <img src={item.icon} alt="GC" className="image-20-fit" />
                                    </div>
                                    <div className="calc-column-offset-60">
                                      <p>{item.name}</p>
                                    </div>
                                    <div className="fixed-column-30">
                                      <img src={deleteIconDark} alt="GC" className="image-20-fit" />
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="row-20">
                                <p className="error-color description-text-2">Courses, lessons, resources, embers, curriculum exchange, general pathways, labor market trends, advising sessions, reporting</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>*/}

                      {(this.state.showModule) ? (
                        <div>
                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">{(this.state.students && this.state.students.length > 0) ? (this.state.students.length === this.state.resLimit) ? this.state.students.length + "+" : this.state.students.length : "0"} Student{(this.state.students && this.state.students.length === 1) ? "" : "s"}</p>
                                </div>
                                <div className="fixed-column-30">
                                  <button onClick={() => this.setState({ modalIsOpen: true, showAddStudentInstrux: true })} className="background-button full-width clear-margin">
                                    <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>

                              {(this.state.students && this.state.students.length > 0) && (
                                <div className="top-margin-20">
                                  {this.state.students.map((value, index) =>
                                    <div className="top-margin">
                                      {(index < 3) && (
                                        <div>
                                          <Link to={this.state.prefix + '/members/' + value.username} className="background-button left-text row-10 full-width">
                                            {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                            <div className="fixed-column-50">
                                              <img className="profile-thumbnail-1" alt="GC" src={(value.pictureURL) ? value.pictureURL : profileIconDark} />
                                            </div>
                                            <span className="calc-column-offset-80">
                                              <p>{value.firstName} {value.lastName}</p>
                                              <div className="half-spacer" />
                                              <p className="description-text-2">Joined {convertDateToString(new Date(value.createdAt),"date-2")}</p>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                          <div className="clear" />
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.students && this.state.students.length > 3) && (
                                    <div className="full-width top-margin">
                                      <Link className="full-width right-text description-text-1" to={this.state.prefix + "/members"}>
                                        <p>See All ></p>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">{(this.state.submissions && this.state.submissions.length > 0) ? this.state.submissions.length : "0"} Submission{(this.state.submissions && this.state.submissions.length === 1) ? "" : "s"}</p>
                                </div>
                                <div className="fixed-column-30">
                                  <button onClick={() => this.setState({ modalIsOpen: true, showSubmissionInstrux: true })} className="background-button full-width clear-margin">
                                    <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>

                              {(this.state.submissions && this.state.submissions.length > 0) && (
                                <div className="top-margin-20">
                                  {this.state.submissions.map((item, index) =>
                                    <div className="top-margin">
                                      {(index < 3) && (
                                        <div>
                                          <Link to={this.state.prefix + '/submissions/' + item._id} className="background-button left-text row-10 full-width">
                                            {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                            <div className="fixed-column-50">
                                              <img className="profile-thumbnail-1" alt="GC" src={(item.pictureURL) ? item.pictureURL : profileIconDark} />
                                            </div>
                                            <span className="calc-column-offset-80">
                                              <p>{item.firstName} {item.lastName}</p>
                                              <div className="half-spacer" />
                                              <p className="description-text-2">Submitted {convertDateToString(new Date(item.createdAt),"date-2")} to {(item.courseName) ? item.courseName : (item.lessonName) ? item.lessonName : item.assessmentName}</p>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                          <div className="clear" />
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.submissions && this.state.submissions.length > 0) && (
                                    <div className="full-width top-margin">
                                      <Link className="full-width right-text description-text-1" to={this.state.prefix + "/submissions"}>
                                        <p>See All ></p>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">{(this.state.grades && this.state.grades.length > 0) ? this.state.grades.length : "0"} Grade{(this.state.grades && this.state.grades.length === 1) ? "" : "s"}</p>
                                </div>
                                <div className="fixed-column-30">
                                  <button onClick={() => this.setState({ modalIsOpen: true, showGradeInstrux: true })} className="background-button full-width clear-margin">
                                    <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                  </button>
                                </div>
                                <div className="clear" />
                              </div>

                              {(this.state.grades && this.state.grades.length > 0) && (
                                <div className="top-margin-20">
                                  {this.state.grades.map((item, index) =>
                                    <div className="top-margin">
                                      {(index < 3) && (
                                        <div>
                                          <Link to={this.state.prefix + '/submissions/' + item._id} className="background-button left-text row-10 full-width">
                                            {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                            <div className="fixed-column-70">
                                              <p className="heading-text-4">{(item.grade) ? item.grade + '%' : "0%"}</p>
                                            </div>
                                            <span className="calc-column-offset-100">
                                              <p>{item.firstName} {item.lastName}</p>
                                              <div className="half-spacer" />
                                              <p className="description-text-2">Submitted {convertDateToString(new Date(item.createdAt),"date-2")} to {(item.courseName) ? item.courseName : (item.lessonName) ? item.lessonName : item.assessmentName}</p>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                          <div className="clear" />
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.grades && this.state.grades.length > 0) && (
                                    <div className="full-width top-margin">
                                      <Link className="full-width right-text description-text-1" to={this.state.prefix + "/grades"}>
                                        <p>See All ></p>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">{(this.state.sessions && this.state.sessions.length > 0) ? this.state.sessions.length : "0"} Advising Session{(this.state.advisingSessions && this.state.advisingSessions.length === 1) ? "" : "s"}</p>
                                </div>
                                <div className="fixed-column-30">
                                  <Link to={this.state.prefix + '/sessions/new'} className="background-button full-width clear-margin">
                                    <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                  </Link>
                                </div>
                                <div className="clear" />
                              </div>

                              {(this.state.sessions && this.state.sessions.length > 0) && (
                                <div className="top-margin-20">
                                  {this.state.sessions.map((item, index) =>
                                    <div className="top-margin">
                                      {(index < 3) && (
                                        <div>
                                          <Link to={this.state.prefix + '/edit-logs/' + item._id} className="background-button left-text row-10 full-width" to={{ editExisting: true, logs: this.state.sessions, log: item }}>
                                            {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                            <div className="fixed-column-50">
                                              <img className="profile-thumbnail-1" alt="GC" src={(item.adviseePictureURL) ? item.adviseePictureURL : profileIconDark} />
                                            </div>
                                            <span className="calc-column-offset-80">
                                              <p>{item.adviseeFirstName} {item.adviseeLastName} (Advisee) with {item.adviseeFirstName} {item.adviseeLastName} (Advisor)</p>
                                              <div className="half-spacer" />
                                              <p className="description-text-2">Created {convertDateToString(new Date(item.createdAt),"date-2")}</p>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </Link>
                                          <div className="clear" />
                                        </div>
                                      )}

                                      <div className="clear" />
                                    </div>
                                  )}

                                  {(this.state.sessions && this.state.sessions.length > 3) && (
                                    <div className="full-width top-margin">
                                      <Link className="full-width right-text description-text-1" to={this.state.prefix + "/sessions"}>
                                        <p>See All ></p>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}

                            </div>
                          </div>

                          {(window.location.pathname.startsWith('/advisor')) && (
                            <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                              <div className="full-width height-5 senary-background" />
                              <div className="padding-20">
                                <div>
                                  <div className="calc-column-offset-30">
                                    <p className="heading-text-4">{(this.state.alumni && this.state.alumni.length > 0) ? this.state.alumni.length : "0"} {(this.state.alumni && this.state.alumni.length === 1) ? "Alumnus" : "Alumni"}</p>
                                  </div>
                                  <div className="fixed-column-30">
                                    {/*
                                    <button onClick={() => this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: true, selectedCourse: null })} className="background-button full-width clear-margin">
                                      <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                    </button>*/}
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-20">
                                  <p className="error-color description-text-2">A school site license is required to establish alumni accounts.</p>
                                  <p className="error-color description-text-2 top-margin-20">Alumni can help source work-based learning opportunities and raise donation money.</p>
                                  <p className="description-text-2 top-margin-20">Email help@guidedcompass.com to set up a site license for $4.5K/yr.</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">Courses</p>
                                </div>
                                {(toggleVars.showLMSConfiguration) && (
                                  <div className="fixed-column-30">
                                    <button onClick={() => this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: true, selectedCourse: null })} className="background-button full-width clear-margin">
                                      <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                    </button>
                                  </div>
                                )}
                                <div className="clear" />
                              </div>

                              <div className="row-20">
                                {(this.state.courses && this.state.courses.length > 0 && toggleVars.showLMSConfiguration) ? (
                                  <div>
                                    {this.state.courses.map((course, index) =>
                                      <div>
                                        <div>
                                          <button onClick={() => this.props.navigate(this.state.prefix + '/edit-courses/' + course._id)} className="background-button left-text row-10 full-width">
                                            {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}

                                            <span className="calc-column-offset-30">
                                              {/*
                                              {(course.imageURL) && (
                                                <div className="full-width">
                                                  <img src={course.imageURL} alt="GC" className="full-width height-60 contain-object-fit pin-left"/>
                                                </div>
                                              )}*/}

                                              <p>{course.name}</p>
                                              <div className="half-spacer" />

                                              <div className="float-left description-text-3 left-text full-width">
                                                <p>{course.minutes} Minutes | {convertDateToString(new Date(course.startDate),"date-2")} - {(course.isPerpetual) ? "Continual" : convertDateToString(new Date(course.endDate),"datetime-2")}</p>
                                                {(course.schoolName) && (
                                                  <div>
                                                    <label>{course.schoolName}</label>
                                                    <div className="clear" />
                                                    <div className="half-spacer" />
                                                  </div>
                                                )}
                                                {(course.gradeLevel) && (
                                                  <div>
                                                    <label>{course.gradeLevel} | {course.knowledgeLevel}</label>
                                                    <div className="clear" />
                                                  </div>
                                                )}

                                                {(course.functions && course.functions.length > 0) && (
                                                  <div>
                                                    <div className="half-spacer" />
                                                    <label className="description-text-color">Occupations: {course.functions.join()}</label>
                                                    <div className="clear" />
                                                  </div>
                                                )}
                                              </div>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </button>
                                          <div className="clear" />
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <p className="error-color description-text-2">No courses yet. Integrate with your LMS to sync courses.</p>
                                  </div>
                                )}
                              </div>

                              <div className="row-20">
                                <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showLMSInstrux: true })}>Request LMS Integration</button>
                              </div>
                            </div>
                          </div>

                          <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                            <div className="full-width height-5 senary-background" />
                            <div className="padding-20">
                              <div>
                                <div className="calc-column-offset-30">
                                  <p className="heading-text-4">Independent Lessons</p>
                                </div>
                                {(toggleVars.showLMSConfiguration) && (
                                  <div className="fixed-column-30">
                                    <button onClick={() => this.setState({ modalIsOpen: true, showLessonDetails: true })} className="background-button full-width clear-margin">
                                      <img alt="GC" src={addIconBlue} className="image-auto-18 pin-right" />
                                    </button>
                                  </div>
                                )}
                                <div className="clear" />
                              </div>

                              <div className="row-20">
                                {(this.state.lessons && this.state.lessons.length > 0 && toggleVars.showLMSConfiguration) ? (
                                  <div>
                                    {this.state.lessons.map((lesson, index) =>
                                      <div>
                                        <div>
                                          <button onClick={() => this.setState({ modalIsOpen: true, showLessonDetails: true, selectedLesson: lesson })} className="background-button left-text row-10 full-width">

                                            <span className="calc-column-offset-30">

                                              <p>{lesson.name}</p>
                                              <p className="description-text-3 top-margin-5">{lesson.minutes} Minutes</p>
                                              <div className="clear" />
                                            </span>
                                            <div className="fixed-column-30">
                                              <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                            </div>
                                            <div className="clear" />
                                          </button>
                                          <div className="clear" />
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <p className="error-color description-text-2">No courses yet. Integrate with your LMS to sync courses.</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(this.state.showEditCourse) ? (
                  <div>
                    <EditCourse closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen}
                      fromModal={true} selectedCourse={this.state.selectedCourse}
                      orgCode={this.state.activeOrg} orgName={this.state.orgName} gradeLevelOptions={this.state.gradeLevelOptions}
                      knowledgeLevelOptions={this.state.knowledgeLevelOptions} industryOptions={this.state.industryOptions}
                      functionOptions={this.state.functionOptions} addNewCourse={this.addNewCourse} updateCourse={this.updateCourse}
                      courseOptions={this.state.courses} openCurriculumExchange={this.openCurriculumExchange}
                    />
                  </div>
                ) : (
                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                   ariaHideApp={false}
                  >
                    <div key="showPriceChart" className="full-width padding-20">
                      {(this.state.showPriceChart) && (
                        <div key="showPricingChart" className="full-width padding-20">
                          <p className="heading-text-2">Price Chart</p>
                          <div className="spacer" />

                          <div className="row-10">
                            <img src={priceChart} alt="GC" className="image-full-auto"/>
                          </div>

                          <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>

                        </div>
                      )}

                      {(this.state.showLessons) && (
                        <div key="showLessons" className="full-width padding-20">
                          <SubExchange passedId={null} closeModal={this.closeModal} passLesson={this.passLesson} passItem={this.passItem} lessons={this.state.lessons} courses={this.state.courses} editLesson={true} exchangeType={this.state.exchangeType}/>
                        </div>
                      )}

                      {(this.state.showLessonDetails) && (
                        <div key="showPricingChart" className="full-width padding-20">
                          {/*
                          <p className="heading-text-2">{this.state.selectedLesson.name}</p>
                          <div className="spacer" />

                          <div className="row-10">

                          </div>*/}
                          <SubEditLesson history={this.props.navigate} passLesson={this.passLesson} closeModal={this.closeModal} selectedLesson={this.state.selectedLesson} editLesson={true} selectedIndex={this.state.selectedIndex} openCurriculumExchange={this.openCurriculumExchange} />


                        </div>
                      )}

                      {(this.state.showLMSInstrux) && (
                        <div key="showPricingChart" className="full-width padding-20">
                          <p className="heading-text-2">Integrate Your Learning Management System (LMS)</p>
                          <div className="spacer" />

                          <div className="row-20">
                            <p>Integrate with your LMS to sync courses, assignments, grades, rubrics, and learning objectives.</p>
                            <p className="top-margin-20">Connect your school district LMS administrator to help@guidedcompass.com to get the process started.</p>
                          </div>

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showPortalInfo) && (
                        <div key="showPortalInfo" className="full-width padding-20">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="heading-text-2">Five Portals for Five Users</p>
                              <p className="description-text-1 description-text-color top-margin">Guided Compass Communities (i.e., {this.state.orgName}) include 5 portals.</p>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button" onClick={() => this.closeModal()}>
                                <img className="image-auto-20" alt="GC" src={closeIcon} />
                              </button>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-20">
                            <div>
                              <p><label className="bold-text">1) Student Portal</label>: where students engage in college and career readiness activities, and with the broader community. Click <Link to="/app" target="_blank" rel="noopener noreferrer">here</Link> to view the student portal. Students can transfer their profile to alumni communities upon graduation with parent permission.</p>
                            </div>
                            <div className="top-margin-25">
                              <p><label className="bold-text top-margin-20">2) Educator & Counselor Portal</label>: where educators and counselors can guide students through the career development and work-based learning continuum. You can use A.I. to generate lesson plans, write letters of recommendation, and grade assignments. <label className="bold-text italicize-text">You are currently in this portal!</label></p>
                            </div>
                            <div className="top-margin-25">
                              <p><label className="bold-text top-margin-20">3) Administrator Portal</label>: where work-based learning coordinators and administrators can manage all members, view metrics, pull reports, post work opportunities, refer candidates, and raise money. A subscription license is required to access this portal. School site subscription licenses are $4,500. Email help@guidedcompass.com to set up a subscription license.</p>
                            </div>
                            <div className="top-margin-25">
                              <p><label className="bold-text top-margin-20">4) Mentor Portal</label>: where mentors can be matched to students, log advising sessions, give feedback on career documents, endorse skills, and refer to job opportunities. After completing a background check, mentors can join <Link to={"/organizations/" + this.state.activeOrg + "/mentors/join"} target="_blank" rel="noopener noreferrer">here</Link>. Mentors may also donate to your program.</p>
                            </div>
                            <div className="top-margin-25">
                              <p><label className="bold-text top-margin-20">5) Employer Portal</label>: where employers can post career events, potential student projects, and work opportunities <Link to={"/employers/post/" + this.state.activeOrg} target="_blank" rel="noopener noreferrer">here</Link>. They also view referred candidates, provide feedback on candidates, approve timesheets during internships/apprenticeships, provide feedback on coursework, and donate to your program.</p>
                            </div>
                          </div>

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showAddStudentInstrux) && (
                        <div key="showAddStudentInstrux" className="full-width padding-20">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="heading-text-2">How to Add Students</p>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button" onClick={() => this.closeModal()}>
                                <img className="image-auto-20" alt="GC" src={closeIcon} />
                              </button>
                            </div>
                            <div className="clear" />
                            <p className="description-text-1 description-text-color top-margin-5">How to add students to the {this.state.orgName} community.</p>
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          {(this.state.authType) ? (
                            <div>
                              {(this.state.authType === "Clever") ? (
                                <div className="description-text-1">
                                  <p className="full-width">Clever should automatically make an account available for your students after you install the app. The Clever website provides more details <a href="https://support.clever.com/hc/s/articles/360013488672?language=en_US#Header4" target="_blank" rel="noopener noreferrer">here</a>.</p>
                                </div>
                              ) : (
                                <div className="description-text-1">
                                  <p className="full-width">When you installed the app from the Schoology LMS App Center, make sure you clicked the courses and groups that represent the students who you want to access. When the students sign in and go to the course or group within Schoology, they should be able to access the app.</p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="row-20">
                              <div className="description-text-1">
                                <p className="full-width">Invite students to join <Link to={"/organizations/" + this.state.activeOrg + '/student/join'} target="_blank" rel="noopener noreferrer">here</Link>.</p>
                              </div>
                            </div>
                          )}

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showSubmissionInstrux) && (
                        <div key="showSubmissionInstrux" className="full-width padding-20">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="heading-text-2">Adding Submissions</p>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button" onClick={() => this.closeModal()}>
                                <img className="image-auto-20" alt="GC" src={closeIcon} />
                              </button>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-20">
                            <p className="full-width">Submissions include surveys, quizzes, and assignments submitted by students to courses and/or lessons made available to them. View {this.state.orgName} courses <Link to={this.state.prefix + "/courses"}>here</Link>.</p>
                            <p className="full-width top-margin-20">Make sure courses and learning modules are marked as visible to students. Test visibility by toggling to the student portal <Link to={"/app/learning"}>here</Link>.</p>
                          </div>

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showGradeInstrux) && (
                        <div key="showGradeInstrux" className="full-width padding-20">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="heading-text-2">Grading Submissions</p>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button" onClick={() => this.closeModal()}>
                                <img className="image-auto-20" alt="GC" src={closeIcon} />
                              </button>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-20">
                            <p className="full-width">You can grade student submissions. Submissions include surveys, quizzes, and assignments submitted by students to courses and/or lessons made available to them.</p>
                            <p className="full-width top-margin-20">Once student submissions have been added, you can grade them <Link to={this.state.prefix + "/submissions"}>here</Link>.</p>
                          </div>

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showStudents) && (
                        <div key="showStudents" className="full-width padding-20">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="heading-text-2">{(this.state.metricObjects && this.state.metricObjects.length > 0) ? this.state.metricObjects.length : "0"} {this.state.metricTitle}</p>
                            </div>
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button" onClick={() => this.closeModal()}>
                                <img className="image-auto-20" alt="GC" src={closeIcon} />
                              </button>
                            </div>
                            <div className="clear" />

                            <p className="description-text-1 description-text-color top-margin">{this.state.metricSubtitle}</p>
                          </div>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-20">
                            {(this.state.metricObjects && this.state.metricObjects.length > 0) && (
                              <div>
                                {this.state.metricObjects.map((value, index) =>
                                  <div className="top-margin">
                                    <Link to={this.state.prefix + '/members/' + value.username} className="background-button left-text row-10 full-width">
                                      {/*this.setState({ showEditCourse: true, modalIsOpen: true, addNewCourse: false, selectedCourse: course, selectedIndex: index })*/}
                                      <div className="fixed-column-70">
                                        <img className="profile-thumbnail-2" alt="GC" src={(value.pictureURL) ? value.pictureURL : profileIconDark} />
                                      </div>
                                      <span className="calc-column-offset-100">
                                        <p>{value.firstName} {value.lastName}</p>
                                        <div className="half-spacer" />
                                        <p className="description-text-2">Joined {convertDateToString(new Date(value.createdAt),"date-2")}</p>
                                        <div className="clear" />
                                      </span>
                                      <div className="fixed-column-30">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right"/>
                                      </div>
                                      <div className="clear" />
                                    </Link>

                                    <div className="row-10">
                                      <hr />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="row-20">
                            <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close Modal</button>
                          </div>

                        </div>
                      )}

                      {(this.state.showChangePassword) && (
                        <div key="showChangePassword" className="full-width padding-20">
                           <SubChangePassword history={this.props.navigate} email={this.state.emailId}
                             modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}
                           />
                         </div>
                      )}
                    </div>
                  </Modal>
                )}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Dashboard);
