import React, {Component} from 'react';
import Axios from 'axios';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import SubOpportunityDetails from '../components/Subcomponents/OpportunityDetails';
import withRouter from '../components/Functions/WithRouter';

class OpportunityDetailsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.commandClicked = this.commandClicked.bind(this)
      this.passActiveOrg = this.passActiveOrg.bind(this)

      this.child = React.createRef();
    }

    componentDidMount(){
      console.log('problemPlatformPage just mounted')
      document.body.style.backgroundColor = "#fff";
      window.scrollTo(0, 0)

      if (window.innerWidth > 768) {
      } else {
        //mobile
        this.setState({ onMobile: true })
      }

      // console.log('show item 1: ', this.props.location.state)
      if (this.props.params) {
        console.log('went directly to the link')

        let { opportunityId, referralCode } = this.props.params

        Axios.get('/api/postings/byid', { params: { _id: opportunityId } })
        .then((response) => {
           console.log('Postings by id query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved posting')

             const selectedOpportunity = response.data.posting
             this.setState({ selectedOpportunity, referralCode })
             this.retrieveData(selectedOpportunity)

           } else {
             console.log('no posting data found', response.data.message)
           }

        }).catch((error) => {
            console.log('Posting query did not work', error);
        });
      } else if (this.props.location && this.props.location.state && this.props.location.state.selectedOpportunity) {

        //send this logged out user out of the app
        const { selectedOpportunity } = this.props.location.state;

        this.setState({ selectedOpportunity });
        this.retrieveData(selectedOpportunity)

      }
    }

    retrieveData(selectedOpportunity) {
      console.log('retrieveData called in probDeets')

      const activeOrg = localStorage.getItem('activeOrg');

      let { orgCode, roleName } = this.props.params

      let finalOrgCode = 'guidedcompass'
      if (orgCode) {
        finalOrgCode = orgCode
      } else if (activeOrg && activeOrg !== 'undefined') {
        finalOrgCode = activeOrg
      }

      this.setState({ orgCode: finalOrgCode, roleName })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentOpportunityDetails ', this.props, prevProps)

      if (this.props.location && this.props.location.state && this.props.location.state.selectedOpportunity !== prevProps.location.state.selectedOpportunity) {
        console.log('new career selected in parent')
        this.setState({ selectedOpportunity: this.props.location.state.selectedOpportunity })
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      // this.setState({ passedCommand })
      this.child.current.passCommand(passedCommand);

    }

    passActiveOrg(orgCode) {
      console.log('passActiveOrg called', orgCode)

      this.setState({ orgCode })

    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"opportunityDetailsPage"} emailId={this.state.emailId} activeOrg={this.state.orgCode} roleName={this.state.roleName} darkBackground={true} />
            {(this.state.orgCode) && (
              <div>
                <SubOpportunityDetails selectedOpportunity={this.state.selectedOpportunity} activeOrg={this.state.orgCode} pageSource="landingPage" path={this.state.path} history={this.props.navigate} roleName={this.state.roleName} referralCode={this.state.referralCode} commandClicked={this.commandClicked} />
              </div>
            )}
            <Footer history={this.props.navigate} ref={this.child} />
          </div>
      )
    }
}

export default withRouter(OpportunityDetailsPage);
