import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import withRouter from '../components/Functions/WithRouter';

class ErrorPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    componentDidMount() {
      //document.body.style.backgroundColor = "#F5F5F5";

      if (this.props.params) {
        const { errorString } = this.props.params
        this.setState({ errorString })
      }
    }


  render() {
      return (
        <div>
          <TopNavigation currentPage={"errorPage"} darkBackground={true} />
          <div className="contact-container">
            <div className="spacer" />
            <div className="spacer" />

            <p className="description-text-2">There was an error:</p>

            <div className="spacer" />

            <p className="error-message">{this.state.errorString}</p>
          </div>

          <Footer history={this.props.navigate} />
        </div>
      )
  }
}

export default withRouter(ErrorPage);
