import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditAssessment from '../Subcomponents/EditAssessment';
import withRouter from '../Functions/WithRouter';

class OrgAddAssessment extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let selectedTemplate = null
      let duplicate = null
      if (this.props.location && this.props.location.state) {
        selectedTemplate = this.props.location.state.selectedTemplate
        duplicate = this.props.location.state.duplicate
      }

      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ org, selectedTemplate, duplicate, orgLogo })
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />
            <SubEditAssessment activeOrg={this.state.org} history={this.props.navigate} selectedTemplate={this.state.selectedTemplate} duplicate={this.state.duplicate} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgAddAssessment)
