import React, {Component} from 'react';
import Switch from 'react-switch';
import Modal from 'react-modal';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import withRouter from '../Functions/WithRouter';

const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const textIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/text-icon.png';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class EditResource extends Component {
    constructor(props) {
        super(props)

        this.state = {
          hideTypeOnScript: false,
          _id: '',
          title: '',
          questions: [],
          titles: [],

          ogTypeOptions: ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Email','Date','Birthdate'],
          typeOptions: ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Email','Date','Birthdate'],
          resourceTypeOptions: ['','Assignment','Learning Objective','Page','Quiz','Script','Survey','Video',],
          viewCorrectAnswerOptions: ['','Upon Completion',"Don't Show Correct Answers"],
          shortnameOptions: [],
          responseTypeOptions: [],

          formHasChanged: false,

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.addPrompt = this.addPrompt.bind(this)
        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.saveResource = this.saveResource.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.switchClicked = this.switchClicked.bind(this)
        this.calculateConditionNumberOptions = this.calculateConditionNumberOptions.bind(this)
        this.toggleCorrect = this.toggleCorrect.bind(this)
        this.configureVideoSource = this.configureVideoSource.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    static defaultProps = { selectedTemplate: null, activeOrg: null }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in EditResource', this.props, prevProps)

      if (prevProps.activeOrg !== this.props.activeOrg) {
        console.log('t0')
        this.retrieveData()
      } else if (this.props.resourceId && !this.props.resourceId) {
        console.log('t1')
        this.retrieveData()
      } else if (this.props.selectedTemplate && !this.props.selectedTemplate) {
        console.log('t1')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      //obtain email id from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      let selectedTemplate = this.props.selectedTemplate
      let _id = null
      let title = null
      let resourceType = null
      let responseType = null
      let timeLimit = null
      let viewCorrectAnswers = null
      let canChangeAnswers = null
      let htmlMode = null
      let questions = []
      let maxPoints = null
      let description = null
      let videoURL = null

      if (this.props.passedType) {
        resourceType = this.props.passedType
      }

      const org = this.props.activeOrg

      if (selectedTemplate) {
        if (this.props.duplicate) {
          delete selectedTemplate._id
          selectedTemplate['orgCode'] = org
          selectedTemplate['title'] = selectedTemplate.title + " (Duplicate)"
        }
        _id = selectedTemplate._id
        title = selectedTemplate.title
        if (selectedTemplate.resourceType) {
          resourceType = selectedTemplate.resourceType
        } else if (selectedTemplate.type) {
          resourceType = selectedTemplate.type
        }
        responseType = selectedTemplate.responseType
        timeLimit = selectedTemplate.timeLimit
        viewCorrectAnswers = selectedTemplate.viewCorrectAnswers
        canChangeAnswers = selectedTemplate.canChangeAnswers
        htmlMode = selectedTemplate.htmlMode
        questions = selectedTemplate.questions
        maxPoints = selectedTemplate.maxPoints
        description = selectedTemplate.description
        videoURL = selectedTemplate.videoURL
      }

      if (email) {
        console.log('show me template', selectedTemplate)

        let readOnly = false
        if (selectedTemplate && selectedTemplate.orgCode === 'general' && org !== 'guidedcompass') {
          readOnly = true
        }

        const shortnameOptions = [
          '','dateOfBirth','gender','race','races','selfDescribedRace','address','zipcode','phoneNumber',
          'alternativePhoneNumber', 'alternativeEmail','numberOfMembers','householdIncome','workAuthorization',
          'adversityList','educationStatus','education|name','education|degree','education|endDate',
          'education|major','referrerName','referrerEmail','referrerOrg'
        ]
        shortnameOptions.sort()

        let typeOptions = this.state.ogTypeOptions
        let responseTypeOptions = []
        if (resourceType === 'Script') {
          // const additionalTypeOptions = ['Chat with Human','Chat with Casper','Search Organizations','Reset Welcome Prompt']
          typeOptions = ['','Dictated','Multiple Choice','Freeform']
          responseTypeOptions = ['','A.I.-Generated Response','Admin-Generated Response']
        } else if (resourceType === 'Survey') {
          typeOptions = this.state.ogTypeOptions
        } else if (resourceType === 'Quiz') {
          typeOptions = ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking']
        }

        this.setState({
            emailId: email, username, cuFirstName, cuLastName, org,
            _id, selectedTemplate, title, resourceType, timeLimit, viewCorrectAnswers, canChangeAnswers, htmlMode,
            questions, maxPoints, description, videoURL,
            readOnly, shortnameOptions, typeOptions, responseTypeOptions
        });
        // console.log('to1 ', typeOptions)
        if (this.props.resourceId && this.props.resourceId !== 'add') {

          const self = this
          function pullAssessment(resourceId) {
            console.log('pullAssessment called')

            Axios.get('/api/customassessments/byid', { params: { _id: resourceId } })
            .then((response) => {
              console.log('Custom assessment by id query attempted', response.data);

              if (response.data.success) {
                console.log('custom assessment query worked')

                let selectedTemplate = response.data.assessment
                let _id = selectedTemplate._id
                let title = selectedTemplate.title
                let questions = selectedTemplate.questions

                // let typeOptions = this.state.typeOptions
                // if (resourceType === 'Script') {
                //   const additionalTypeOptions = ['Chat with Human','Chat with Casper','Search Organizations','Reset Welcome Prompt']
                //   typeOptions = typeOptions.concat(additionalTypeOptions)
                // }

                self.setState({ selectedTemplate, _id, title, questions })

              } else {
                console.log('custom assessment query did not work', response.data.message)
              }
            }).catch((error) => {
              console.log('Posted jobs query did not work for some reason', error);
            });
          }

          Axios.get('/api/resources/byid', { params: { _id: this.props.resourceId } })
          .then((response) => {
            console.log('Resource by id query attempted', response.data);

            if (response.data.success) {
              console.log('resource query worked')

              let selectedTemplate = response.data.resource
              let _id = selectedTemplate._id
              let title = selectedTemplate.title
              let questions = selectedTemplate.questions
              let maxPoints = selectedTemplate.maxPoints
              let description = selectedTemplate.description
              let videoURL = selectedTemplate.videoURL
              let preparedResponse = selectedTemplate.preparedResponse

              this.setState({ selectedTemplate, _id, title, questions,
                maxPoints, description, videoURL, preparedResponse
              })

            } else {
              console.log('resource query did not work', response.data.message)
              pullAssessment(this.props.resourceId)
            }
          }).catch((error) => {
            console.log('Resource query did not work for some reason', error);
          });
        }
      }

    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked')

      if (event.target.name === 'title') {
        this.setState({ title: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'resourceType') {
        let resourceType = event.target.value
        let typeOptions = this.state.ogTypeOptions
        let responseTypeOptions = []
        if (resourceType === 'Script') {
          typeOptions = ['','Dictated','Multiple Choice','Freeform']
          responseTypeOptions = ['','A.I.-Generated Response','Admin-Generated Response']
        } else if (resourceType === 'Survey') {
          typeOptions = this.state.ogTypeOptions
        } else if (resourceType === 'Quiz') {
          typeOptions = ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking']
        }

        this.setState({ resourceType, typeOptions, responseTypeOptions, formHasChanged: true })

      } else if (event.target.name === 'timeLimit') {
        this.setState({ timeLimit: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'viewCorrectAnswers') {
        this.setState({ viewCorrectAnswers: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'canChangeAnswers') {
        this.setState({ canChangeAnswers: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'htmlMode') {
        this.setState({ htmlMode: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'maxPoints') {
        this.setState({ maxPoints: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'videoURL') {
        this.setState({ videoURL: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'preparedResponse') {
        this.setState({ preparedResponse: event.target.value, formHasChanged: true })
      } else if (event.target.name === 'description') {
        this.setState({ description: event.target.value, formHasChanged: true })
      } else {
        for (let i = 1; i <= this.state.questions.length; i++) {

          let eventName = 'question' + i.toString()
          let typeName = 'type' + i.toString()
          let responseTypeName = 'responseType' + i.toString()
          let promptName = 'prompt' + i.toString()
          let conditionNumber = 'conditionNumber' + i.toString()
          let conditionValue = 'conditionValue' + i.toString()
          let answerChoiceName = 'answerChoice' + i.toString()
          let placeholderName = "placeholder" + i.toString()
          let shortnameName = "shortname" + i.toString()
          let preparedResponseName = "preparedResponse" + i.toString()

          console.log(i, 'updating questions', event.target.name, this.state.questions);

          if ( event.target.name === eventName ) {
            let questions = this.state.questions
            questions[i - 1]['name'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === typeName ) {
            let questions = this.state.questions
            questions[i - 1]['questionType'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === responseTypeName ) {
            let questions = this.state.questions
            questions[i - 1]['responseType'] = event.target.value
            this.setState({ questions, formHasChanged: true })
            console.log('got in responseType: ', questions)
          } else if ( event.target.name === promptName ) {
            let questions = this.state.questions
            questions[i - 1]['prompt'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === conditionNumber ) {
            let questions = this.state.questions
            questions[i - 1]['conditionNumber'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === conditionValue ) {
            let questions = this.state.questions
            questions[i - 1]['conditionValue'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === promptName ) {
            let questions = this.state.questions
            questions[i - 1]['prompt'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === preparedResponseName ) {
            let questions = this.state.questions
            questions[i - 1]['preparedResponse'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name.startsWith(answerChoiceName) ) {
            console.log('got in answer choices')

            let answerChoices = this.state.questions[i - 1].answerChoices
            console.log('gimme answerChoices', answerChoices)

            let stringArray = event.target.name.split("|")

            //let stringEnd = event.target.name.length

            //let choiceIndex = Number(event.target.name.substring(13,stringEnd)) - 1
            let choiceIndex = Number(stringArray[1]) - 1

            console.log('gimme choiceIndex', choiceIndex)
            answerChoices[choiceIndex] = event.target.value
            console.log('gimme answerChoices one mo gain', answerChoices)

            // questions[parentIndex]['correctAnswer'] = questions[parentIndex]['answerChoices'][index]
            // questions[parentIndex]['correctParentIndex'] = parentIndex
            // questions[parentIndex]['correctIndex'] = index

            let questions = this.state.questions
            questions[i - 1]['answerChoices'] = answerChoices
            if (questions[i - 1].correctAnswer && questions[i - 1].correctIndex === choiceIndex) {
              questions[i - 1]['correctAnswer'] = event.target.value
            }

            this.setState({ questions, formHasChanged: true })

          } else if ( event.target.name === placeholderName ) {
            let questions = this.state.questions
            questions[i - 1]['placeholder'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          } else if ( event.target.name === shortnameName ) {
            let questions = this.state.questions
            questions[i - 1]['shortname'] = event.target.value
            this.setState({ questions, formHasChanged: true })
          }
        }
      }
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const questions = reorder(
        this.state.questions,
        result.source.index,
        result.destination.index
      );

      this.setState({ questions, formHasChanged: true });

    }

    saveResource() {
      console.log('saveResource clicked');

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.formHasChanged === false) {
        this.setState({ isSaving: true, errorMessage: 'no changes detected. please update and then save.' })
      } else if (this.state.title === '') {
        this.setState({ isSaving: true, errorMessage: 'please add a title' })
      } else if ((this.state.resourceType === 'Survey' || this.state.resourceType === 'Quiz' || this.state.resourceType === 'Script') && this.state.questions.length === 0) {
        this.setState({ isSaving: true, errorMessage: 'please add a question to your reset' })
      } else if ((this.state.resourceType === 'Survey' || this.state.resourceType === 'Quiz') && this.state.questions[0].name === '') {
        this.setState({ isSaving: true, errorMessage: 'please add at least one question to your resource' })
      } else {
        console.log('about to save', this.state.questions)

        const _id = this.state._id
        const title = this.state.title
        const type = this.state.resourceType
        const responseType = this.state.responseType
        const timeLimit = this.state.timeLimit
        const viewCorrectAnswers = this.state.viewCorrectAnswers
        const canChangeAnswers = this.state.canChangeAnswers
        const htmlMode = this.state.htmlMode
        const questions = this.state.questions

        const maxPoints = this.state.maxPoints
        const description = this.state.description
        const videoURL = this.state.videoURL

        const orgCode = this.state.org

        const createdAt = new Date()
        const updatedAt = new Date()

        const resourceObject = {
          _id, title, type, responseType, timeLimit, viewCorrectAnswers, canChangeAnswers, htmlMode,
          questions, maxPoints, description, videoURL,
          orgCode, createdAt, updatedAt
        }

        if (type === 'Quiz' || type === 'Script' || type === 'Survey') {
          Axios.post('/api/customassessments', resourceObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Assessment save worked', response.data);
              this.setState({ isSaving: false, successMessage: 'Successfully saved assessment'  })

              if ((this.props.duplicate && this.props.navigate.goBack) || (window.location.pathname.endsWith('/add'))) {
                this.props.navigate(-1)
              }

            } else {
              console.error('there was an error saving the assessment template');
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('Assessment save did not work', error);
              this.setState({ isSaving: false, errorMessage: error.toString() })
          });

        } else {
          Axios.post('/api/resources', resourceObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('Resource save worked', response.data);
              this.setState({ isSaving: false, successMessage: 'Successfully saved resource' })

              if ((this.props.duplicate && this.props.navigate.goBack) || (window.location.pathname.endsWith('/add'))) {
                this.props.navigate(-1)
              }

            } else {
              console.error('there was an error saving the resource');
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }
          }).catch((error) => {
              console.log('Resource save did not work', error);
              this.setState({ isSaving: false, errorMessage: error.toString() })
          });
        }
      }
    }

    renderQuestions() {
      console.log('renderQuestions called')

      let rows = [];

      if (this.state.questions && this.state.questions.length > 0) {

        let tryDnd = true
        if (tryDnd) {
          rows.push(
            <div key="questions" className="row-5">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.questions.map((item, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <div className="padding-5 standard-color">
                                  {(item.prompt || item.prompt === '') && (
                                    <div className="bottom-padding-20">
                                      <div className="close-button-container-2" >
                                        <button type="button" onClick={() => this.deleteItem('prompt', index)} className="background-button">
                                          <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                        </button>
                                      </div>
                                      <div className="left-padding top-padding">
                                        <div className="highlighted-container">
                                          <p>Section Description - Add a Prompt for the Next Section</p>
                                          <textarea type="text" className="text-field" placeholder="Add a description for the next section..." name={"prompt" + (index + 1).toString()} value={item.prompt} onChange={this.formChangeHandler}></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="close-button-container-2" >
                                    <button type="button" onClick={() => this.deleteItem('question', index)} className="background-button">
                                      <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                                    </button>
                                  </div>

                                  <div className="left-padding">
                                    <div className="highlighted-container">
                                      <div className="row-10">
                                        <div className="calc-column-offset-100">
                                          <p className="heading-text-6">Question #{index + 1}</p>
                                        </div>
                                        <div className="fixed-column-100">
                                          <div>
                                            <img src={dragIcon} alt="GC" className="image-auto-15 pin-right" />
                                          </div>
                                        </div>
                                        <div className="clear" />
                                      </div>

                                      {(this.state.resourceType === 'Script') && (
                                        <div className="row-10">
                                          <p className="bottom-margin description-text-color description-text-2">Prompt Type</p>
                                          <select name={"type" + (index + 1).toString()} value={this.state.questions[index].questionType} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.typeOptions.map(value =>
                                              <option key={value.key} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>
                                      )}

                                      {((this.state.resourceType === 'Script' && (!this.state.questions[index].questionType || this.state.questions[index].questionType === 'Short Answer' || this.state.questions[index].questionType === 'Dictated')) || this.state.resourceType !== 'Script') && (
                                        <div className="row-10">
                                          {(this.state.resourceType === 'Script') && <p className="bottom-margin description-text-color description-text-2">Prompt Text</p>}
                                          {(this.state.resourceType === 'Script') ? (
                                            <textarea type="text" className="text-field" placeholder="Create a question..." name={"question" + (index + 1).toString()} value={this.state.questions[index].name} onChange={this.formChangeHandler}></textarea>
                                          ) : (
                                            <input type="text" className="text-field" placeholder="Create a question..." name={"question" + (index + 1).toString()} value={this.state.questions[index].name} onChange={this.formChangeHandler}></input>
                                          )}
                                        </div>
                                      )}
                                      {(this.state.resourceType === 'Script' && this.state.questions[index].questionType === 'Freeform') && (
                                        <div className="row-10">
                                          <p className="fake-text-area">Students write their own prompt and submit it to the A.I...</p>
                                        </div>
                                      )}

                                      {(this.state.resourceType !== 'Script') && (
                                        <div className="row-10">
                                          <select name={"type" + (index + 1).toString()} value={this.state.questions[index].questionType} onChange={this.formChangeHandler} className="dropdown">
                                            {this.state.typeOptions.map(value =>
                                              <option key={value.key} value={value}>{value}</option>
                                            )}
                                          </select>
                                        </div>
                                      )}

                                      {(this.state.questions[index].questionType === 'Multiple Choice'  || this.state.questions[index].questionType === 'Checkbox' || this.state.questions[index].questionType === 'Ranking') ? (
                                        <div>
                                          <div className="spacer" />
                                          {this.renderAnswerChoices(index + 1, this.state.questions[index].answerChoices)}

                                          <div className="top-padding-20">
                                            <hr />
                                            <a className="background-button" onClick={() => this.addAnswerChoice(index)}>
                                              <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                                            </a>
                                          </div>

                                        </div>
                                      ) : (
                                        <div>
                                          {((this.state.resourceType === 'Script') || (this.state.questions[index].questionType === 'Short Answer' || this.state.questions[index].questionType === 'Long Answer' || this.state.questions[index].questionType === 'Chat with Human' || this.state.questions[index].questionType === 'Chat with Casper' || this.state.questions[index].questionType === 'Search Organizations')) && (
                                            <div className="row-10 bottom-margin">
                                              {(this.state.resourceType === 'Script') && <p className="bottom-margin description-text-color description-text-2">Prompt Helper</p>}
                                              <input type="text" className="text-field" placeholder={(this.state.resourceType === 'Script') ? "Add a helper, explainer, or instructions for the student..." : "Add placeholder text for the answer..."} name={"placeholder" + (index + 1)} value={this.state.questions[index].placeholder} onChange={this.formChangeHandler}></input>
                                            </div>
                                          )}

                                          {(this.state.resourceType !== 'Script') && (
                                            <div>
                                              {(this.state.questions[index].questionType === 'Long Answer') ? (
                                                <p className="fake-text-area">{(this.state.questions[index].placeholder) ? this.state.questions[index].placeholder : "Candidates will write long answer here..."}</p>
                                              ) : (
                                                <div>
                                                  <p className="fake-text-field">{(this.state.questions[index].placeholder) ? this.state.questions[index].placeholder : "Candidates will write answer here..."}</p>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {(this.state.resourceType === 'Script') && (
                                        <div>
                                          <div className="row-10">
                                            <hr />
                                          </div>

                                          <div className="row-10">
                                            <p className="bottom-margin description-text-color description-text-2">Response Type</p>
                                            <select name={"responseType" + (index + 1).toString()} value={this.state.questions[index].responseType} onChange={this.formChangeHandler} className="dropdown">
                                              {this.state.responseTypeOptions.map(value =>
                                                <option key={value.key} value={value}>{value}</option>
                                              )}
                                            </select>
                                          </div>

                                          {(this.state.questions[index].responseType === 'Admin-Generated Response') ? (
                                            <div className="row-10">
                                              <p className="bottom-margin description-text-color description-text-2">Response Revealed Upon Student Submission of the Prompt</p>
                                              <textarea type="text" className="text-field" placeholder="Write an automatic response to the prompt submitted to the student as if you were the A.I. or teacher..." name={"preparedResponse" + (index + 1)} value={this.state.questions[index].preparedResponse} onChange={this.formChangeHandler}></textarea>
                                            </div>
                                          ) : (
                                            <div className="row-10">
                                              <p className="fake-text-area">The A.I. responds to the prompt here...</p>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {(this.state.resourceType !== 'Script') && (
                                        <div className="top-padding-20">
                                          <hr />

                                          <div className="top-padding-20">
                                            <div className="flex-container justify-end">
                                              <div className="right-padding-5">
                                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <p>Required</p>
                                              </div>
                                              <div className="right-padding-20">
                                                <Switch
                                                  onChange={(change) => this.switchClicked('required',index, change)}
                                                  checked={this.state.questions[index].required}
                                                  id="normal-switch"
                                                />
                                              </div>
                                              <div className="right-padding-5">
                                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <p>Conditional</p>
                                              </div>
                                              <div className="right-padding-20">
                                                <Switch
                                                  onChange={(change) => this.switchClicked('conditional',index, change)}
                                                  checked={this.state.questions[index].conditional}
                                                  id="normal-switch"
                                                />
                                              </div>
                                              <div className="right-padding-5">
                                                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                                <p>Map to Profile</p>
                                              </div>
                                              <div>
                                                <Switch
                                                  onChange={(change) => this.switchClicked('mapToProfile',index, change)}
                                                  checked={this.state.questions[index].mapToProfile}
                                                  id="normal-switch"
                                                />
                                              </div>
                                            </div>

                                            {(this.state.questions[index].conditional) && (
                                              <div className="top-padding">
                                                <div className="top-padding flex-container row-direction">
                                                  <div className="flex-50">
                                                    <p>Condition Question Number:</p>
                                                  </div>
                                                  <div className="flex-50">
                                                    <select name={"conditionNumber" + (index + 1)} value={this.state.questions[index].conditionNumber} onChange={this.formChangeHandler} className="dropdown">
                                                      {this.calculateConditionNumberOptions().map(value =>
                                                        <option key={value.key} value={value}>{value}</option>
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="top-padding flex-container row-direction">
                                                  <div className="flex-50">
                                                    <p>Condition Value:</p>
                                                  </div>
                                                  <div className="flex-50">
                                                    <input type="text" className="text-field" placeholder="Value required to render question..." name={"conditionValue" + (index + 1)} value={this.state.questions[index].conditionValue} onChange={this.formChangeHandler}></input>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            {(this.state.questions[index].mapToProfile) && (
                                              <div className="top-padding">
                                                <div className="top-padding flex-container row-direction">
                                                  <div className="flex-50">
                                                    <p>Select the Profile Field:</p>
                                                    <p className="description-text-2 description-text-color top-padding-5">(Answers will be saved to the user's profile)</p>
                                                  </div>
                                                  <div className="flex-50">
                                                    <select name={"shortname" + (index + 1)} value={this.state.questions[index].shortname} onChange={this.formChangeHandler} className="dropdown">
                                                      {this.state.shortnameOptions.map(value =>
                                                        <option key={value} value={value}>{value}</option>
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>

                                        </div>
                                      )}

                                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </div>
          )
        } else {
          for (let i = 1; i <= this.state.questions.length; i++) {
            console.log(i);

            const index = i - 1

            let eventName = "question" + i.toString()
            let typeName = "type" + i.toString()

            rows.push(
              <div key={i} className="row-5">
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('question', index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Question #{i}</p>
                    </div>

                    <div className="row-10">
                      <div className="name-container">
                        <input type="text" className="text-field" placeholder="Create a question..." name={eventName} value={this.state.questions[i - 1].name} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="name-container">
                        <select name={typeName} value={this.state.questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.typeOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      </div>
                      <div className="clear"/>
                    </div>

                    {(this.state.questions[i - 1].questionType === 'Multiple Choice'  || this.state.questions[i - 1].questionType === 'Checkbox' || this.state.questions[i - 1].questionType === 'Ranking') ? (
                      <div>
                        <div className="spacer" />
                        {this.renderAnswerChoices(i, this.state.questions[i - 1].answerChoices)}

                        <div className="top-padding-20">
                          <hr />
                          <a className="background-button" onClick={() => this.addAnswerChoice(i - 1)}>
                            <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                          </a>
                        </div>

                      </div>
                    ) : (
                      <div>
                        {(this.state.questions[i - 1].questionType === 'Long Answer') ? (
                          <p className="fake-text-area">Candidates will write long answer here...</p>
                        ) : (
                          <div>
                            <p className="fake-text-field">Candidates will write {this.state.questions[i - 1].questionType.toLowerCase()} here...</p>
                          </div>
                        )}
                      </div>
                    )}
                    {/*
                    <div>
                      <div className="float-left right-padding">
                        <p>Would you like to add a prompt to introduce the next section?</p>
                      </div>
                      <div className="float-left">
                        <Switch
                          onChange={(change) => this.editItem('prompt',index)}
                          checked={this.state.questions[i - 1].addPrompt}
                          id="normal-switch"
                        />
                      </div>
                      <div className="clear" />
                    </div>*/}
                  </div>
                </div>

              </div>
            )
          }
        }
      }

      return rows;

    }

    deleteItem(itemType, index, parentIndex) {
      console.log('deleteItem called', itemType, index, parentIndex)

      if (itemType === 'question') {
        let questions = this.state.questions
        questions.splice(index, 1)
        this.setState({ questions, formHasChanged: true })
      } else if (itemType === 'answerChoice') {
        let questions = this.state.questions
        let answerChoices = questions[parentIndex].answerChoices

        answerChoices.splice(index,1)

        questions[parentIndex]['answerChoices'] = answerChoices
        this.setState({ questions, formHasChanged: true })

      } else if (itemType === 'prompt') {
        let questions = this.state.questions
        delete questions[index].prompt
        this.setState({ questions, formHasChanged: true })
      }
    }

    renderAnswerChoices(iCount, answerChoices) {
      console.log('renderAnswerChoices', iCount)
      const parentIndex = iCount - 1

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        const index = i - 1

        let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()

        rows.push(
          <div key={key}>
            {(this.state.readOnly) ? (
              <div>
                <p className="full-width row-5">{i}. {answerChoices[i - 1]}</p>
              </div>
            ) : (
              <div>
                <div className="fixed-column-40 bottom-margin-15" >
                  <button type="button" onClick={() => this.deleteItem('answerChoice', index, parentIndex)} className="background-button">
                    <img src={deniedIcon} className="image-auto-30 padding-5 top-margin-5" alt="GC" />
                  </button>
                </div>

                <div className={(this.state.resourceType === 'Quiz') ? "calc-column-offset-90 bottom-margin-15" : "calc-column-offset-40 bottom-margin-15"}>
                  <input type="text" className="adjacent-text-field" placeholder="Create an answer choice..." name={eventName} value={answerChoices[i - 1]} onChange={this.formChangeHandler}></input>
                </div>
                {(this.state.resourceType === 'Quiz') && (
                  <div className="fixed-column-50 bottom-margin-15 left-padding" >
                    <button type="button" onClick={() => this.toggleCorrect('answerChoice', index, parentIndex)} className="background-button top-margin left-margin">
                      {(this.state.questions[parentIndex].correctAnswer === answerChoices[i - 1]) ? (
                        <img src={checkmarkIcon} className="image-auto-20" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Undo mark as correct answer"/>
                      ) : (
                        <div className="width-20 height-20 thick-error-border" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark answer as correct"/>
                      )}

                    </button>
                  </div>
                )}
                <div className="clear" />
                <ReactTooltip id="tooltip-placeholder-id" />

              </div>
            )}
          </div>
        )
      }

      return rows
    }

    addQuestion() {
      console.log('addQuestion called')

      let questions = this.state.questions

      let questionType = 'Short Answer'
      if (this.state.resourceType === 'Quiz') {
        questionType = 'Multiple Choice'
      }
      questions.push({ name: '', questionType, answerChoices: []})

      this.setState({ questions })

      window.scrollTo(0,document.body.scrollHeight);
    }

    addPrompt() {
      console.log('addPrompt called')

      let questions = this.state.questions

      let questionType = 'Short Answer'
      if (this.state.resourceType === 'Quiz') {
        questionType = 'Multiple Choice'
      }

      questions.push({ name: '', questionType, answerChoices: [], prompt: ''})
      this.setState({ questions })
    }

    addAnswerChoice(index) {
      console.log('addAnswerChoice called', index)

      let answerChoices = this.state.questions[index].answerChoices
      answerChoices.push('')

      let questions = this.state.questions
      questions[index]['answerChoices'] = answerChoices


      this.setState({ questions })
    }

    switchClicked(name, index, value) {
      console.log('switchClicked called', name, index, value)

      let questions = this.state.questions
      questions[index][name] = value
      this.setState({ questions, formHasChanged: true })

    }

    calculateConditionNumberOptions() {
      console.log('calculateConditionNumberOptions called')

      let conditionNumberOptions = ['']
      for (let i = 1; i <= this.state.questions.length; i++) {
        conditionNumberOptions.push(i)
      }

      return conditionNumberOptions
    }

    toggleCorrect(type,index,parentIndex) {
      console.log('toggleCorrect called', type, index, parentIndex)

      this.setState({ errorMessage: null })

      let questions = this.state.questions
      if (questions[parentIndex]['answerChoices'][index] === undefined || questions[parentIndex]['answerChoices'][index] === null || questions[parentIndex]['answerChoices'][index] === "") {
        console.log('error: write an answer')
        this.setState({ errorMessage: "Write an answer choice before selecting it as the correct answer" })
      } else {
        if (questions[parentIndex]['correctAnswer'] === questions[parentIndex]['answerChoices'][index]) {
          questions[parentIndex]['correctAnswer'] = null
          questions[parentIndex]['correctParentIndex'] = null
          questions[parentIndex]['correctIndex'] = null
        } else {
          questions[parentIndex]['correctAnswer'] = questions[parentIndex]['answerChoices'][index]
          questions[parentIndex]['correctParentIndex'] = parentIndex
          questions[parentIndex]['correctIndex'] = index
        }
        console.log('gimme da value: ', questions[parentIndex].correctAnswer, questions[parentIndex]['answerChoices'][index])

        this.setState({ questions, formHasChanged: true })
      }

      // this.state.questions[parentIndex].correctAnswer === answerChoices[i - 1]
    }

    configureVideoSource(videoURL) {
      console.log('configureVideoSource called', videoURL)

      if (videoURL && videoURL.includes('/watch?v=')) {
        videoURL = videoURL.split('/watch?v=')[0] + '/embed/' + videoURL.split('/watch?v=')[1]
      }

      return videoURL
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showDefinitions: false })

    }

    render() {
      // console.log('show the typeOptions: ', this.state.typeOptions)
      return (
          <div>
              <div className="standard-container-2">
                <div>
                  <div className="spacer" /><div className="spacer" />
                  <p className="heading-text-2">{(this.state.selectedTemplate) ? "Edit" : "New"} Resource</p>
                  <p className="top-margin">Create and save resources for courses, learning modules, and job postings.</p>

                  {(this.state.selectedTemplate) && (
                    <div>
                      {(this.state.readOnly) ? (
                        <p className="error-color description-text-2 half-bold-text top-margin">This resource is read-only. Contact help@guidedcompass.com to request changes.</p>
                      ) : (
                        <p className="error-color description-text-2 half-bold-text top-margin">Warning: After the resource goes live, any changes will create a descrepancy in candidate answers for reporting purposes.</p>
                      )}
                    </div>
                  )}

                </div>

                <div className="row-10 top-margin">
                  <div className="container-left">
                    <label className="profile-label">Title</label>
                    {(this.state.readOnly) ? (
                      <p className="description-text-1">{this.state.title}</p>
                    ) : (
                      <div>
                        <input type="text" className="text-field" placeholder="Add a title..." name="title" value={this.state.title} onChange={this.formChangeHandler}></input>
                      </div>
                    )}
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Type</label>
                    {(this.state.readOnly) ? (
                      <p className="description-text-1">{this.state.resourceType}</p>
                    ) : (
                      <select name="resourceType" value={this.state.resourceType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.resourceTypeOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    )}
                    {(this.state.resourceType) && (
                      <p className="description-text-2 description-text-color row-10">Learn more about {this.state.resourceType.toLowerCase()}{(this.state.resourceType === 'Quiz') ? "zes" : "s"} <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, showDefinitions: true })}>here</button>.</p>
                    )}
                  </div>
                  <div className="clear" />
                </div>

                {((this.state.readOnly && this.state.resourceType) || !this.state.readOnly) && (
                  <div className="row-10">
                    {(this.state.resourceType === 'Quiz') && (
                      <div className="container-left">
                        <label className="profile-label">Time Limit (in Minutes)</label>
                        {(this.state.readOnly) ? (
                          <p className="description-text-1">{this.state.timeLimit}</p>
                        ) : (
                          <input type="number" className="number-field" placeholder="0" name="timeLimit" value={this.state.timeLimit} onChange={this.formChangeHandler}></input>
                        )}
                      </div>
                    )}
                    {(this.state.resourceType === 'Script') && (
                      <div className="container-left">
                        <label className="profile-label">Questions Written in HTML?</label>
                        <Switch
                          onChange={(change) => this.setState({ htmlMode: change, formHasChanged: true })}
                          checked={this.state.htmlMode}
                          id="normal-switch"
                          disabled={(this.state.readOnly) ? true : false}
                        />
                      </div>
                    )}
                    {(this.state.resourceType === 'Quiz' || this.state.resourceType === 'Assignment') && (
                      <div className={(this.state.resourceType === 'Quiz') ? "container-right" : "container-left"}>
                        <label className="profile-label">Max Points</label>
                        {(this.state.readOnly) ? (
                          <p className="description-text-1">{this.state.maxPoints}</p>
                        ) : (
                          <input type="number" className="number-field" placeholder="0" name="maxPoints" value={this.state.maxPoints} onChange={this.formChangeHandler}></input>
                        )}
                      </div>
                    )}
                    {/*
                    {(this.state.resourceType === 'Assignment') && (
                      <div className={"container-right"}>
                        <label className="profile-label">Require Attachment</label>
                        {(this.state.readOnly) ? (
                          <p className="description-text-1">{this.state.attachmentRequired}</p>
                        ) : (
                          <input type="text" className="text-field" placeholder="0" name="attachmentRequired" value={this.state.attachmentRequired} onChange={this.formChangeHandler}></input>
                        )}
                      </div>
                    )}*/}
                    <div className="clear" />
                  </div>
                )}

                {(this.state.resourceType === 'Quiz') && (
                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">View Correct Answers</label>
                      {(this.state.readOnly) ? (
                        <p className="description-text-1">{this.state.viewCorrectAnswers}</p>
                      ) : (
                        <select name="viewCorrectAnswers" value={this.state.viewCorrectAnswers} onChange={this.formChangeHandler} className="dropdown">
                          {this.state.viewCorrectAnswerOptions.map(value =>
                            <option key={value.key} value={value}>{value}</option>
                          )}
                        </select>
                      )}
                    </div>
                    <div className="container-left">
                      <label className="profile-label">Can Change Answers After Submission</label>
                      <Switch
                        onChange={(change) => this.setState({ canChangeAnswers: change, formHasChanged: true })}
                        checked={this.state.canChangeAnswers}
                        id="normal-switch"
                        disabled={(this.state.readOnly) ? true : false}
                      />
                    </div>
                    <div className="clear" />
                  </div>
                )}

                {(this.state.resourceType === 'Quiz' || this.state.resourceType === 'Script' || this.state.resourceType === 'Survey') && (
                  <div>
                    <div className="row-10">
                      <p className="heading-text-4">Questions</p>
                    </div>

                    {(this.state.readOnly) ? (
                      <div>
                        {(this.state.questions) && (
                          <div>
                            {this.state.questions.map((value,index) =>
                              <div>
                                <div className="padding-5 standard-color">
                                  <div className="left-padding">
                                    <div className="highlighted-container">
                                      <div className="row-5">
                                        <p>Question #{index + 1}</p>
                                      </div>

                                      <div className="row-10">
                                        <div className="container-left">
                                          <p>{value.name}</p>
                                        </div>
                                        <div className="container-right">
                                          <p>{value.questionType}</p>
                                        </div>
                                        <div className="clear"/>
                                      </div>

                                      {(this.state.questions[index].questionType === 'Multiple Choice'  || this.state.questions[index].questionType === 'Checkbox' || this.state.questions[index].questionType === 'Ranking') ? (
                                        <div>
                                          <div className="spacer" />
                                          {this.renderAnswerChoices(index + 1, this.state.questions[index].answerChoices)}

                                          {(!this.state.readOnly) && (
                                            <div className="top-padding-20">
                                              <hr />
                                              <a className="background-button" onClick={() => this.addAnswerChoice(index)}>
                                                <img src={addCircleOutlineIcon} alt="GC" className="image-auto-30 left-margin-5 top-margin-negative-37" />
                                              </a>
                                            </div>
                                          )}

                                        </div>
                                      ) : (
                                        <div>
                                          {(this.state.questions[index].questionType === 'Long Answer') ? (
                                            <p className="fake-text-area">Candidates will write long answer here...</p>
                                          ) : (
                                            <div>
                                              <p className="fake-text-field">Candidates will write {this.state.questions[index].questionType.toLowerCase()} here...</p>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      <div className="top-padding-20">
                                        <hr />

                                        <div className="top-padding-20">
                                          <div className="flex-container justify-end">
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Required</p>
                                            </div>
                                            <div className="right-padding-20">
                                              <Switch
                                                onChange={(change) => this.switchClicked('required',index, change)}
                                                checked={this.state.questions[index].required}
                                                id="normal-switch"
                                                disabled={true}
                                              />
                                            </div>
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Conditional</p>
                                            </div>
                                            <div className="right-padding-20">
                                              <Switch
                                                onChange={(change) => this.switchClicked('conditional',index, change)}
                                                checked={this.state.questions[index].conditional}
                                                id="normal-switch"
                                                disabled={true}
                                              />
                                            </div>
                                            <div className="right-padding-5">
                                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                              <p>Map to Profile</p>
                                            </div>
                                            <div>
                                              <Switch
                                                onChange={(change) => this.switchClicked('mapToProfile',index, change)}
                                                checked={this.state.questions[index].mapToProfile}
                                                id="normal-switch"
                                                disabled={true}
                                              />
                                            </div>
                                          </div>

                                          {(this.state.questions[index].conditional) && (
                                            <div className="top-padding">
                                              <div className="top-padding flex-container row-direction">
                                                <div className="flex-50">
                                                  <p>Condition Question Number:</p>
                                                </div>
                                                <div className="flex-50">
                                                  <select name={"conditionNumber" + (index + 1)} value={this.state.questions[index].conditionNumber} onChange={this.formChangeHandler} className="dropdown">
                                                    {this.calculateConditionNumberOptions().map(value =>
                                                      <option key={value.key} value={value}>{value}</option>
                                                    )}
                                                  </select>
                                                </div>
                                              </div>
                                              <div className="top-padding flex-container row-direction">
                                                <div className="flex-50">
                                                  <p>Condition Value:</p>
                                                </div>
                                                <div className="flex-50">
                                                  <input type="text" className="text-field" placeholder="Value required to render question..." name={"conditionValue" + (index + 1)} value={this.state.questions[index].conditionValue} onChange={this.formChangeHandler}></input>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.renderQuestions()}

                        {(this.state.questions && this.state.questions.length > 0) ? (
                          <div>
                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                            <button className="btn btn-primary" onClick={() => this.saveResource()}>Save Resource</button>
                          </div>
                        ) : (
                          <div>
                            <p className="error-color">Click the plus button to the right to add a question</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {(this.state.resourceType === 'Assignment' || this.state.resourceType === 'Learning Objective' || this.state.resourceType === 'Page') && (
                  <div className="row-10">
                    <label className="profile-label">Description</label>
                    <textarea type="text" className="text-field" placeholder="Add a description..." name={"description"} value={this.state.description} onChange={this.formChangeHandler}></textarea>
                  </div>
                )}

                {(this.state.resourceType === 'Video') && (
                  <div className="row-10">
                    <label className="profile-label clear-padding top-margin">Video URL [YouTube Only]</label>
                    <p className="row-5 bottom-margin description-text-2 description-text-color">Tip: YouTube videos can be uploaded and marked as unlisted to avoid public access.</p>
                    <input type="text" className="text-field" placeholder="Link to YouTube video..." name={"videoURL"} value={this.state.videoURL} onChange={this.formChangeHandler}></input>

                    {(this.state.videoURL) && (
                      <div className="top-margin-20">
                        <iframe
                          title="videoTitle"
                          className="full-width calc-height-half-view"
                          src={this.configureVideoSource(this.state.videoURL)}
                          frameBorder="0"
                        />
                      </div>
                    )}
                  </div>
                )}

                {(this.state.resourceType && this.state.resourceType !== 'Quiz' && this.state.resourceType !== 'Script' && this.state.resourceType !== 'Survey') && (
                  <div className="top-margin-30">
                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                    <button className="btn btn-primary" onClick={() => this.saveResource()}>Save Resource</button>
                  </div>
                )}

              </div>

              {(!this.state.readOnly && this.state.resourceType === 'Quiz' || this.state.resourceType === 'Script' || this.state.resourceType === 'Survey') && (
                <div className="fixed-side-bar">
                  <div className="row-10">
                    <button className="background-button add-task-link-container" onClick={() => this.addQuestion()}>
                      <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a question" />
                    </button>
                  </div>

                  <div className="row-10">
                    <button className="background-button add-task-link-container" onClick={() => this.addPrompt()}>
                      <img src={textIcon} alt="Compass add circle outline icon" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a short description"/>
                    </button>
                  </div>

                </div>
              )}

              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
               ariaHideApp={false}
             >

              {(this.state.showDefinitions) && (
                <div key="fromDefinitions" className="full-width padding-20">
                  <div className="calc-column-offset-30">
                    <p className="heading-text-2">Resource Types</p>
                  </div>
                  <div className="fixed-column-30">
                    <button className="background-button" onClick={() => this.closeModal()}>
                      <img src={deniedIcon} alt="GC" className="image-auto-20 pin-right" />
                    </button>
                  </div>
                  <div className="clear" />

                  <div className="spacer" /><div className="spacer" />

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Assignment') ? "bold-text" : ""}>Assignments are typically qualitative assignments (as opposed to quizzes) given to students for grades.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Learning Objective') ? "bold-text" : ""}>Learning Objectives outline goals for courses and lessons.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Page') ? "bold-text" : ""}>Pages are custom text you can share with students and educators.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Quiz') ? "bold-text" : ""}>Quizzes are multiple choice style questionnaires assigned to students that have right or wrong answers.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Script') ? "bold-text" : ""}>Scripts are a series of questions, commands, and prompts designed for students to edit and submit to our A.I. chatbot.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Survey') ? "bold-text" : ""}>Surveys are questionnaires assigned to students where there isn't assigned right or wrong answers.</p>
                  </div>

                  <div className="row-15">
                    <p className={(this.state.resourceType === 'Video') ? "bold-text" : ""}>Videos are videos you can share with students and educators!</p>
                  </div>

                </div>
              )}
              </Modal>
          </div>

      )
    }
}

export default withRouter(EditResource);
