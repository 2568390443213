import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import SubHelpPrompt from '../components/Common/HelpPrompt';

import {toggleVars} from '../components/Functions/ToggleVars';

const logoWithWordsImg = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png";
const appleStoreBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/AppleStoreBadge.png';
const googlePlayBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/GooglePlayBadge.png';
const facebookIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/facebook-icon-white.png';
const twitterIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/twitter-icon-white.png';
const instagramIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/instagram-icon-white.png';
const youtubeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/youtube-icon-white.png';

class Footer extends Component {
    constructor(props) {
      super(props)

      this.state = {
        hideFreeTools: true,
        showIntermediaries: true
      }

      this.retrieveData = this.retrieveData.bind(this)
    }

    componentDidMount() {
      console.log('footer called');

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in footer ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData in footer')

      // let emailId = localStorage.getItem('email');
      // let cuFirstName = localStorage.getItem('firstName');
      // let cuLastName = localStorage.getItem('lastName');
      // let accountCode = localStorage.getItem('emp');
      // let roleName = localStorage.getItem('roleName');
      // let orgFocus = localStorage.getItem('orgFocus');
    }

    passCommand(command) {
      console.log('passCommand called', command)

      this.setState({ passedCommand: command })

    }

    render() {

      return (
          <div>
            <footer>
              <div className="bottom-padding-20 heading-text-4">
                <div className={(this.state.hideFreeTools) ? "relative-column-33" : "relative-column-25"}>
                  <p className="bold-text white-text">Beneficiaries</p>
                  <div className="spacer" /><div className="half-spacer" />

                  {(this.state.showIntermediaries) ? (
                    <ul className="footer-nav">
                      <li><Link to={'/employers'}>Employers</Link></li>
                      <li><Link to={'/intermediaries'}>Intermediaries</Link></li>
                      <li><Link to={'/career-seekers'}>Career-Seekers</Link></li>
                    </ul>
                  ) : (
                    <ul className="footer-nav">
                      <li><Link to={'/employers'}>Employers</Link></li>
                      <li><Link to={'/mentors'}>Mentors</Link></li>
                      <li><Link to={'/workforce'}>Workforce</Link></li>
                      <li><Link to={'/educators'}>Educators</Link></li>
                      <li><Link to={'/career-seekers'}>Career-Seekers</Link></li>
                    </ul>
                  )}
                </div>
                {(!this.state.hideFreeTools) && (
                  <div className="relative-column-25">
                    <p className="bold-text white-text">Free Tools & Features</p>
                    <div className="spacer" /><div className="half-spacer" />
                    <ul className="footer-nav">
                      <li><Link to={'/trends'}>Labor Market Trends</Link></li>
                      <li><Link to={'/careers'}>Careers</Link></li>
                      <li><Link to={'/financials'}>Financial Planning</Link></li>
                      <li><Link to={'/resume-builder'}>Resume Builder</Link></li>
                      <li><Link to={'/events'}>Career Events</Link></li>
                      <li><Link to={'/project-opportunities'}>Project Opportunities</Link></li>
                      <li><Link to={'/work-opportunities'}>Work Opportunities</Link></li>
                      <li><Link to={'/courses'}>Programs & Courses</Link></li>
                    </ul>
                  </div>
                )}
                <div className={(this.state.hideFreeTools) ? "relative-column-33" : "relative-column-25"}>
                  <p className="bold-text white-text">Company</p>
                  <div className="spacer" /><div className="half-spacer" />
                  <ul className="footer-nav">
                    <li><Link to={'/about'}>About</Link></li>
                    <li><Link to={'/blog'}>Blog</Link></li>
                    <li><Link to={'/jobs'}>Careers @ GC</Link></li>
                    {/*<li><Link to={'/partners'}>Dedicated Partners</Link></li>*/}
                    <li><Link to={'/integrations'}>Integrations</Link></li>
                    {/*<li><Link to={'/help'}>Help & Support</Link></li>*/}
                  </ul>
                </div>
                <div className={(this.state.hideFreeTools) ? "relative-column-33" : "relative-column-25"}>
                  <p className="bold-text white-text">Getting Started</p>
                  <div className="spacer" /><div className="half-spacer" />
                  <ul className="footer-nav">
                    <li><Link to={'/join'}>Sign Up</Link></li>
                    <li><Link to={'/signin'}>Sign In</Link></li>
                    {/*<li><Link to={'/marketplace'}>Marketplace</Link></li>*/}
                    <li><Link to={'/pricing'}>Pricing</Link></li>
                    {/*<li><Link to={'/newsletter'}>Join Newsletter</Link></li>*/}
                    <li><Link to={'/contact'}>Contact</Link></li>
                  </ul>
                </div>
                <div className="clear" />
              </div>
              <div className="clear" />

              <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />

              <hr />

              <div className="spacer" />

              {(toggleVars.showMobileApps) && (
                <div className="row top-margin-20">
                  <ul className="store-list">
                    <div className="store-list-item"><li>
                      <a className="background-button-white" href="https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8" target="_blank" rel="noopener noreferrer">
                        <img src={appleStoreBadge} alt="Compass apple store badge icon" className="apple-store-badge" />
                      </a>
                    </li></div>
                    <div className="store-list-item"><li>
                      <a className="background-button-white" href="https://play.google.com/store/apps/details?id=com.guidedcompass" target="_blank" rel="noopener noreferrer">
                        <img src={googlePlayBadge} alt="Compass google play badge icon" className="google-play-badge" />
                      </a>
                    </li></div>
                  </ul>
                </div>
              )}

              <div className="top-padding-20">
                <Link to={'/'}><img src={logoWithWordsImg} alt="Compass logo" className="logo-footer" /></Link>
                <ul className="social-links-2 center-text top-padding-15">
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/guidedcompassapp/"><img src={facebookIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/guidedcompass1/"><img src={twitterIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/guided_compass/"><img src={instagramIconWhite} alt="GC" className="image-23-fit" /></a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCHO9ddn2kc_8hG8KFJZUH4w"><img src={youtubeIconWhite} alt="GC" className="image-23-fit" /></a></li>
                </ul>
                <div className="clear" />
              </div>

              <div className="row-15">
                <p className="copyright-text white-text">Copyright {new Date().getFullYear()} by Guided Compass. All rights reserved.</p>
              </div>

              <div className="row-15">
                <div className="container-left flex-container justify-end right-text">
                  <Link className="background-button" to="/privacy-policy">
                    <p className="white-text description-text-2">Privacy Policy</p>
                  </Link>
                </div>
                <div className="container-right">
                  <Link className="background-button" to="/terms">
                    <p className="white-text description-text-2">Terms</p>
                  </Link>
                </div>
                <div className="clear" />
              </div>

              <div className="spacer" />

            </footer>

            <SubHelpPrompt history={this.props.navigate} orgCode={null} passedCommand={this.state.passedCommand} />
          </div>
      )
    }
}

export default Footer;
