import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Axios from 'axios';
import Modal from 'react-modal';
import SubReferSomeone from '../Common/ReferSomeone';
import {convertDateToString} from '../Functions/ConvertDateToString';
import SubPurchaseIdea from '../Common/PurchaseIdea';
import SubIdeaSummary from '../Common/IdeaSummary';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import withRouter from '../Functions/WithRouter';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const upvoteIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-blue.png';
const upvoteIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/upvote-icon-grey.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';

const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const modalIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/modal-icon-dark.png';
// const referIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png';
const shareIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png';

class BusinessIdeaDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
          includedItems: [],
          stageOptions: [],
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.voteOnItem = this.voteOnItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.calculateStageColor = this.calculateStageColor.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)
        this.passProductData = this.passProductData.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.id !== prevProps.id) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubBusinessIdeaDetails')

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');

      const stageOptions = [
        { name: 'Idea', description: 'This idea is has not begun the validation process.'},
        { name: 'Pre-Launch Tested', description: 'This idea has been somewhat validated, product/service created, but not launched.'},
        { name: 'Launched Product/Service', description: 'This idea has launched the product/service and gotten feedback from customers.'},
        { name: 'Revenue-Generating', description: 'People have paid money for this product/service and fulfilled the agreement by providing the product/service.'},
        { name: 'Other', description: 'This idea does not fall nicely into the other stages.'}
      ]

      this.setState({ emailId: email, username, cuFirstName, cuLastName, roleName, stageOptions })

      const _id = this.props.id

      if (_id) {

        Axios.get('/api/business-ideas/byid', { params: { _id }})
        .then((response) => {
          console.log('Business idea query attempted');

          if (response.data.success) {
            console.log('business idea query worked')

            // const businessIdea = response.data.businessIdea

            const selectedIdea = response.data.businessIdea

            let includedItems = []
            if (selectedIdea.problem) {
              includedItems.push({ name: "Problem Definition" })
            }
            if (selectedIdea.solution) {
              includedItems.push({ name: "Solution Definition" })
            }
            if (selectedIdea.marketSize) {
              includedItems.push({ name: "Market Size & Growth" })
            }
            if (selectedIdea.acquisitionStrategy) {
              includedItems.push({ name: "Acquisition Strategy" })
            }
            if (selectedIdea.competitors && selectedIdea.competitors.length > 0) {
              includedItems.push({ name: "Competitors" })
            }
            if (selectedIdea.competitiveAdvantage && selectedIdea.competitiveAdvantage) {
              includedItems.push({ name: "Competitive Advantage" })
            }
            if (selectedIdea.businessModel && selectedIdea.businessModel) {
              includedItems.push({ name: "Business Model" })
            }
            if (selectedIdea.personas && selectedIdea.personas) {
              includedItems.push({ name: "User Personas" })
            }
            if (selectedIdea.progress && selectedIdea.progress) {
              includedItems.push({ name: "Progress" })
            }
            if (selectedIdea.team && selectedIdea.team) {
              includedItems.push({ name: "Team" })
            }
            if (selectedIdea.interviews && selectedIdea.interviews.length > 0) {
              includedItems.push({ name: "User Interviews" })
            }
            if (selectedIdea.demoURL) {
              includedItems.push({ name: "Demo Video" })
            }
            if (selectedIdea.pitchDeckURL) {
              includedItems.push({ name: "Pitch Deck" })
            }
            if (selectedIdea.promoURL) {
              includedItems.push({ name: "Promo Video" })
            }
            if (selectedIdea.metrics && selectedIdea.metrics.length > 0) {
              includedItems.push({ name: "Performance Metrics" })
            }

            this.setState({ selectedIdea, includedItems })
          } else {
            console.log('custom assessment query did not work', response.data.message)
          }
        }).catch((error) => {
          console.log('Posted jobs query did not work for some reason', error);
        });

        Axios.get('/api/business-ideas-purchased/byid', { params: { _id, email }})
        .then((response) => {
          console.log('Business idea purchased query attempted', response.data);

          if (response.data.success) {
            console.log('business idea purchased query worked')

            // const businessIdea = response.data.businessIdea
            const purchased = true
            this.setState({ purchased })

          } else {
            console.log('business idea purchased query did not work', response.data.message)
          }
        }).catch((error) => {
          console.log('Business idea purchased query did not work for some reason', error);
        });

        let queryObject = { isActive: true, status: "Accepted" }
        Axios.get('/api/business-ideas', { params: queryObject })
        .then((response) => {
          console.log('Ideas query attempted', response.data);

          if (response.data.success) {
            console.log('ideas query worked')

            const recommendedIdeas = response.data.businessIdeas
            this.setState({ recommendedIdeas })

          } else {
            console.log('something went wrong: ', response.data.message)
          }

        }).catch((error) => {
          console.log('Ideas query did not work for some reason', error);
        });
      }
    }

    voteOnItem(value) {
      console.log('voteOnItem called', value)

      this.setState({ isSaving: true, successMessage: null, errorMessage: null })

      if (!this.state.emailId) {
        this.setState({ isSaving: true, errorMessage: 'You must sign in to vote on business ideas' })
      } else {

        const _id = value._id
        const emailId = this.state.emailId
        let changeUpvote = true
        const updatedAt = new Date()

        Axios.post('/api/business-ideas', { _id, emailId, changeUpvote, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Business idea save worked', response.data);

            const successMessage = 'Business idea successfully updated!'

            const selectedIdea = response.data.businessIdea
            this.setState({ selectedIdea, isSaving: false, successMessage })

          } else {
            console.error('there was an error posting the business idea');
            const errorMessage = response.data.message
            this.setState({ errorMessage, isSaving: false })
          }
        }).catch((error) => {
            console.log('The business idea post did not work', error);
            this.setState({ errorMessage: error.toString(), isSaving: false })
        });
      }
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showReferSomeone: false, showBuyItem: false, showStageInfo: false,
        showShareWidget: false
      });
    }

    calculateStageColor(stage,type) {
      console.log('calculateStageColor called', stage,type)

      if (stage === 'Idea') {
        return "secondary-" + type
      } else if (stage === 'Pre-Launch Tested') {
        return "twelve-" + type
      } else if (stage === 'Launched Product/Service') {
        return "senary-" + type
      } else if (stage === 'Revenue-Generating') {
        return "nonary-" + type
      } else {
        return "denary-" + type
      }
    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      const shareURL = window.location.protocol + "//" + window.location.host + "/business-ideas/" + this.state.selectedIdea._id
      const shareTitle = 'Check Out ' + this.state.selectedIdea.name + ' On Guided Compass!'
      const shareBody = this.state.selectedIdea.name  + " looks amazing! Check it out!"

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={shareURL} subject={shareTitle} body={shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={shareURL} title={shareTitle} summary={(shareBody) ? shareBody : ''} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={shareURL} description={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    passProductData(passedData) {
      console.log('passProductData called in superview', passedData)

      if (passedData) {
        const purchased = passedData.purchased
        const success = passedData.success
        const successMessage = passedData.successMessage
        this.setState({ purchased, success, successMessage })
      }
    }

    render() {

      return (
          <div>
            <div>
              <div className={(window.innerWidth > 768) ? "relative-column-70 padding-five-percent-left" : "full-width"}>
                {(this.state.selectedIdea) ? (
                  <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                    <div className={"full-width height-5 " + this.calculateStageColor(this.state.selectedIdea.stage,'background')} />
                    <div className="padding-40">
                      <div className="spacer"/><div className="spacer"/>

                      <div>
                        <div className="calc-column-offset-60">
                          <p className="heading-text-2">{this.state.selectedIdea.name}</p>

                          <p className="description-text-2 half-bold-text description-text-color top-padding">Posted {convertDateToString(this.state.selectedIdea.updatedAt,"daysAgo")}</p>
                        </div>
                        <div className="fixed-column-60">
                          <button className="background-button" onClick={() => this.voteOnItem(this.state.selectedIdea) }>
                            <div className="standard-border rounded-corners" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Match Score">
                              <div className="float-left padding-7">
                                <img src={(this.state.selectedIdea.upvotes && this.state.selectedIdea.upvotes.includes(this.state.emailId)) ? upvoteIconBlue : upvoteIconGrey} alt="GC" className="image-auto-15"/>
                              </div>
                              <div className="vertical-separator-4" />
                              <div className="float-left horizontal-padding-10">
                                <div className="half-spacer" />
                                <p className="description-text-2 half-bold-text">{(this.state.selectedIdea.upvotes) ? this.state.selectedIdea.upvotes.length : 0}</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>

                          <p className="full-width right-text cta-color top-padding-20 heading-text-2">${this.state.selectedIdea.price}</p>
                        </div>
                        <div className="clear" />
                      </div>

                      <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showStageInfo: true })}>
                        <div className="row-10 full-width">
                          <div className="fixed-column-30">
                            <img src={careerMatchesIconDark} alt="GC" className="image-auto-20"/>
                          </div>
                          <div className="calc-column-offset-30">
                            <p className={this.calculateStageColor(this.state.selectedIdea.stage,'text')}>{this.state.selectedIdea.stage}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </button>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      <div className="spacer" />

                      <SubIdeaSummary selectedIdea={this.state.selectedIdea} includedItems={this.state.includedItems} history={this.props.navigate} />

                      <div className="row-10">
                        <hr />
                      </div>

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      {(this.state.purchased) ? (
                        <div className="top-margin-15">
                          <div className="row-15">
                            <p className="heading-text-4">Problem</p>
                            <p className="keep-line-breaks">{this.state.selectedIdea.problem}</p>
                          </div>

                          <div className="row-15">
                            <p className="heading-text-4">Solution</p>
                            <p className="keep-line-breaks">{this.state.selectedIdea.solution}</p>
                          </div>

                          {(this.state.selectedIdea.story) && (
                            <div className="row-15">
                              <p className="heading-text-4">Story</p>
                              <p className="keep-line-breaks">{this.state.selectedIdea.story}</p>
                            </div>
                          )}

                          <div className="row-15">
                            <p className="heading-text-4">Market Size and Growth</p>
                            <p className="keep-line-breaks">{this.state.selectedIdea.marketSize}</p>
                          </div>

                          {(this.state.selectedIdea.businessIdea) && (
                            <div className="row-15">
                              <p className="heading-text-4">Business Model</p>
                              <p className="keep-line-breaks">{this.state.selectedIdea.businessModel}</p>
                            </div>
                          )}

                          {(this.state.selectedIdea.personas) && (
                            <div className="row-15">
                              <p className="heading-text-4">User/Customer Personas</p>
                              <p className="keep-line-breaks">{this.state.selectedIdea.personas}</p>
                            </div>
                          )}

                          <div className="row-15">
                            <p className="heading-text-4">Acquisition Strategy</p>
                            <p className="keep-line-breaks">{this.state.selectedIdea.acquisitionStrategy}</p>
                          </div>

                          {(this.state.selectedIdea.competitors && this.state.selectedIdea.competitors.length > 0) && (
                            <div className="row-15">
                              <p className="heading-text-4">Competitors</p>
                              {this.state.selectedIdea.competitors.map((value, optionIndex) =>
                                <div key={value.name}>
                                  <div className="bottom-margin">
                                    <div className="fixed-column-15 top-margin">
                                      <div className="height-5 width-5 circle-radius dark-background" />
                                    </div>
                                    <div className="calc-column-offset-15">
                                      <a className="description-text-2" href={value.url} target="_blank" rel="noopener noreferrer">{value.name}</a>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="row-15">
                            <p className="heading-text-4">Competitive Advantage</p>
                            <p className="keep-line-breaks">{this.state.selectedIdea.competitiveAdvantage}</p>
                          </div>

                          {(this.state.selectedIdea.progress) && (
                            <div className="row-15">
                              <p className="heading-text-4">Progress</p>
                              <p className="keep-line-breaks">{this.state.selectedIdea.progress}</p>
                            </div>
                          )}

                          {(this.state.selectedIdea.team) && (
                            <div className="row-15">
                              <p className="heading-text-4">Team</p>
                              <p className="keep-line-breaks">{this.state.selectedIdea.team}</p>
                            </div>
                          )}

                          {(this.state.selectedIdea.interviews && this.state.selectedIdea.interviews.length > 0) && (
                            <div className="row-15">
                              <p className="heading-text-4">Interviews</p>
                              {this.state.selectedIdea.interviews.map((value, optionIndex) =>
                                <div key={value.name}>
                                  <div className="bottom-margin">
                                    <div className="fixed-column-15 top-margin-7">
                                      <div className="height-5 width-5 circle-radius dark-background" />
                                    </div>
                                    <div className="calc-column-offset-15">
                                      <p className="description-text-2"><label className="bold-text">Interviewee Profile:</label> {value.interviewee} | <label className="bold-text">Insights:</label> {value.insights}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.selectedIdea.funding && this.state.selectedIdea.funding.length > 0) && (
                            <div className="row-15">
                              <p className="heading-text-4">Funding</p>
                              {this.state.selectedIdea.funding.map((value, optionIndex) =>
                                <div key={value.fundingDate}>
                                  <div className="bottom-margin">
                                    <div className="fixed-column-15 top-margin-7">
                                      <div className="height-5 width-5 circle-radius dark-background" />
                                    </div>
                                    <div className="calc-column-offset-15">
                                      <p className="description-text-2">${value.amount} ({value.type}) on {value.fundingDate}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.selectedIdea.demoURL) && (
                            <div className="row-15">
                              <p className="heading-text-4">Link to Product Demo</p>
                              <a href={this.state.selectedIdea.demoURL} target="_blank" rel="noopener noreferrer">{this.state.selectedIdea.demoURL}</a>
                            </div>
                          )}

                          {(this.state.selectedIdea.pitchDeckURL) && (
                            <div className="row-15">
                              <p className="heading-text-4">Link to Pitch Deck</p>
                              <a href={this.state.selectedIdea.pitchDeckURL} target="_blank" rel="noopener noreferrer">{this.state.selectedIdea.pitchDeckURL}</a>
                            </div>
                          )}

                          {(this.state.selectedIdea.promoURL) && (
                            <div className="row-15">
                              <p className="heading-text-4">Link to Promo Video</p>
                              <a href={this.state.selectedIdea.promoURL} target="_blank" rel="noopener noreferrer">{this.state.selectedIdea.promoURL}</a>
                            </div>
                          )}

                          {(this.state.selectedIdea.applicableTech && this.state.selectedIdea.applicableTech.length > 0) && (
                            <div className="row-15">
                              <p className="heading-text-4">Applicable Tech</p>
                              {this.state.selectedIdea.applicableTech.map((value, optionIndex) =>
                                <div key={value}>
                                  <div className="bottom-margin">
                                    <div className="fixed-column-15 top-margin-7">
                                      <div className="height-5 width-5 circle-radius dark-background" />
                                    </div>
                                    <div className="calc-column-offset-15">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.selectedIdea.metrics && this.state.selectedIdea.metrics.length > 0) && (
                            <div className="row-15">
                              <p className="heading-text-4">Link to Metirc</p>
                              {this.state.selectedIdea.metrics.map((value, optionIndex) =>
                                <div key={value.name}>
                                  <div className="bottom-margin">
                                    <div className="fixed-column-15 top-margin-7">
                                      <div className="height-5 width-5 circle-radius dark-background" />
                                    </div>
                                    <div className="calc-column-offset-15">
                                      <p className="description-text-2"><label className="bold-text">{value.name} {(value.type && value.type !== value.name) && "(" + value.type + ")"}:</label> {value.value}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.selectedIdea.industry) && (
                            <div className="row-15">
                              <p className="heading-text-4">Industry</p>
                              <p>{this.state.selectedIdea.industry}</p>
                            </div>
                          )}

                          {(this.state.selectedIdea.orientation) && (
                            <div className="row-15">
                              <p className="heading-text-4">Customer Orientation</p>
                              <p>{this.state.selectedIdea.orientation}</p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="top-margin-20">
                          <button className="btn btn-primary right-margin-20" onClick={() => this.setState({ modalIsOpen: true, showBuyItem: true })}>Buy</button>
                          <button className="btn btn-secondary" onClick={() => this.setState({ modalIsOpen: true, showShareWidget: true })}>Share</button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={(window.innerWidth > 768) ? "card-clear-padding slightly-rounded-corners full-width max-width-1400 top-margin-40 pin-right" : "card-clear-padding slightly-rounded-corners width-94-percent max-width-1400 center-horizontally top-margin-40"}>
                    <div className="full-width height-5 primary-background" />
                    <div className="padding-40">

                      <p className="error-color">No business idea found with that ID</p>
                    </div>
                  </div>
                )}
              </div>

              {(window.innerWidth > 768) && (
                <div className="relative-column-30 horizontal-padding top-margin-40">
                  <div className="left-padding-20">
                    {(!this.state.purchased) && (
                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">Actions</p>

                          <div className="spacer" /><div className="spacer" />

                          <div className="row-10">
                            <button onClick={() => this.setState({ modalIsOpen: true, showBuyItem: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={moneyIconDark} alt="GC" className="image-auto-25" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>Buy</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                </span>
                              </div>
                            </button>

                            <div className="clear"/>

                            <div className="spacer" /><div className="spacer" />
                            <hr />
                            <div className="clear"/>
                            <div className="spacer" />
                          </div>

                          <div className="bottom-padding">
                            <button onClick={() => this.setState({ modalIsOpen: true, showShareWidget: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={shareIconDark} alt="GC" className="image-auto-20" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>Share this Idea</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                </span>
                              </div>
                            </button>
                            {/*
                            <button onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true })} className="background-button full-width left-text cta-color">
                              <div className="fixed-column-35">
                                <img src={referIconDark} alt="GC" className="image-auto-25" />
                              </div>
                              <div className="calc-column-offset-70 left-padding-5">
                                <p>Refer Someone</p>
                              </div>
                              <div className="float-left left-padding">
                                <span className="float-right top-margin-5">
                                  <img src={modalIconDark} alt="GC" className="image-auto-15"/>
                                </span>
                              </div>
                            </button>*/}
                            <div className="clear"/>
                          </div>

                          <div className="spacer" />
                        </div>
                      </div>
                    )}

                    {(this.state.recommendedIdeas) && (
                      <div className="card-clear-padding min-width-100 max-width-310 bottom-margin-20">
                        <div className="full-width height-5 senary-background" />
                        <div className="padding-20">
                          <p className="heading-text-5">Recommended Ideas</p>

                          <div className="spacer" /><div className="spacer" />

                          {this.state.recommendedIdeas.map((item, index) =>
                            <div key={item}>
                              {(index < 10) && (
                                <div className="bottom-padding">
                                  <div className="spacer" />
                                  <Link to={(this.props.pageSource === 'landingPage') ? '/business-ideas/' + item._id : '/app/business-ideas/' + item._id} className="background-link">
                                    <div className="fixed-column-50">
                                      <img src={(item.pictureURL) ? item.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                                    </div>
                                    <div className="calc-column-offset-90 left-padding-5">
                                      <p>{item.name}</p>
                                      <p className="description-text-2 standard-color top-margin-3">by {item.firstName} {item.lastName}</p>
                                      <p className="description-text-2 description-text-color curtail-text top-margin-3">{item.teaser}</p>
                                    </div>
                                  </Link>
                                  <div className="float-left left-padding">
                                    <span className="float-right">
                                      <div className="half-spacer"/>
                                      <Link to={(this.props.pageSource === 'landingPage') ? '/business-ideas/' + item._id : '/app/business-ideas/' + item._id} className="background-link">
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20"/>
                                      </Link>
                                    </span>
                                  </div>
                                  <div className="clear"/>

                                  <div className="spacer" /><div className="spacer" />
                                  <hr />
                                  <div className="clear"/>
                                  <div className="spacer" />
                                </div>
                              )}
                            </div>
                          )}

                          {(this.state.recommendedIdeas.length > 10) && (
                            <div>
                              <Link to={(this.props.pageSource === 'landingPage') ? '/business-ideas' : '/app/business-ideas'} className="background-link">
                                <p className="description-text-2">See More <label className="description-text-2 left-padding-5">></label></p>
                              </Link>
                            </div>
                          )}

                          <div className="spacer" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
            >
              {(this.state.showReferSomeone) && (
                <div key="showReferSomeone" className="full-width padding-20">
                  <SubReferSomeone history={this.props.navigate} selectedOpportunity={this.state.selectedOpportunity}
                    referralBonus={this.state.referralBonus} pageSource={this.props.pageSource} linkedInURL={this.state.linkedInURL}
                    benchmark={this.state.benchmark} relationshipOptions={this.state.relationshipOptions}
                    jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                    orgCode={this.state.activeOrg} orgName={this.state.postingOrgName}
                    orgProgramName={this.state.orgName} orgContactEmail={this.state.postingOrgContactEmail}
                    closeModal={this.closeModal} headerImageURL={this.state.headerImageURL}
                    partnerLandingPage={this.state.partnerLandingPage}
                  />
                </div>
              )}

              {(this.state.showBuyItem) && (
                <div key="showBuyItem" className="full-width padding-40">
                   <SubPurchaseIdea history={this.props.navigate} selectedIdea={this.state.selectedIdea} includedItems={this.state.includedItems} closeModal={this.closeModal} passProductData={this.passProductData} />
                 </div>
              )}

              {(this.state.showShareWidget) && (
                <div key="showShareWidget" className="full-width center-text">
                   <p className="heading-text-2">Share {this.state.selectedIdea.name}</p>

                   <div className="spacer" /><div className="spacer" /><div className="spacer" />

                   {this.renderShareButtons()}

                   <div className="spacer" /><div className="spacer" /><div className="spacer" />

                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>
              )}

              {(this.state.showStageInfo) && (
                <div key="showStageInfo" className="full-width">
                   <p className="heading-text-2">Stage</p>
                   <div className="spacer" /><div className="spacer" />

                   <p>Business Ideas can be classified into {this.state.stageOptions.length} stages. This idea is classified as <label className={this.calculateStageColor(this.state.selectedIdea.stage,"text")}>{this.state.selectedIdea.stage}</label>.</p>

                   <div className="spacer" />

                   {this.state.stageOptions.map((value, optionIndex) =>
                     <div key={value.name} className="top-margin">
                       <p className={this.calculateStageColor(value.name,"text")}><label className="bold-text">{value.name}:</label> {value.description}</p>
                     </div>
                   )}

                   <div className="spacer" /><div className="spacer" /><div className="spacer" />

                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>
              )}


            </Modal>
          </div>
      )
    }
}

export default withRouter(BusinessIdeaDetails);
