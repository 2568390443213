import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubCustomDropdown from '../Common/CustomDropdown';
import {subExportStandardCSV} from '../Functions/ExportStandardCSV';
import {subExportCSV} from '../Functions/ExportCSV';
import {calculateAge} from '../Functions/CalculateAge';
import {configureDefaultReports} from '../Functions/ConfigureDefaultReports';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon-blue.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const profileIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-grey.png';
const favoriteIconUnselected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoriteIconSelected = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorite-icon-selected.png';
const interviewIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/interview-icon-blue.png";
const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const trashIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trashIconWhite.png';
const assigneeIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignee-icon-white.png';
const pathsIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/paths-icon-white.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const downloadIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/download-icon-blue.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";
const deleteIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png";
const referIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png";
const stageIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stage-icon-dark.png";
const stageIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/stage-icon-white.png";
const closeIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png";

// let updateApplication = false

let individualFilters = []
let trackFilters = []

class AdminApplications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModule: true,
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            checkCount: 0,
            staffMember: {},
            assignedStaff: {},
            scorer: {},
            scorers: [],
            laterStages: ['Connected','Referred','Interview Requested','Offer','Hired'],

            applications: [],
            filteredApplications: [],
            benchmark: null,
            benchmarkOptions: [],
            staffMemberOptions: [],
            postingOptions: [],
            selectedPostings: [],

            searchString: '',
            selectedJob: null,
            appsToDelete: [],

            trackOptions: [],
            trackSelected: '',

            defaultFilterOption: 'All',
            filterOptions: [],
            menuOptions: [],

            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: '',
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderApplications = this.renderApplications.bind(this)
        this.renderMatchScores = this.renderMatchScores.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.filterResults = this.filterResults.bind(this)
        this.renderSearchResults = this.renderSearchResults.bind(this)
        this.favoriteApplicant = this.favoriteApplicant.bind(this)
        this.renderManipulators = this.renderManipulators.bind(this)

        this.checkApplication = this.checkApplication.bind(this)
        this.checkAllApplications = this.checkAllApplications.bind(this)
        this.deleteApps = this.deleteApps.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.assign = this.assign.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.sortValues = this.sortValues.bind(this)
        this.exportInfo = this.exportInfo.bind(this)
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentWillUnmount() {
      console.log('componentWillUnmount called on applications')

      // updateApplication = true

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedJob !== prevProps.selectedJob || this.props.applications !== prevProps.applications) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in AdminApplications', this.props)

      let accountCode = this.props.accountCode
      let org = this.props.org
      let selectedJob = this.props.selectedJob
      let applications = this.props.applications
      let benchmark = this.props.benchmark
      let benchmarkOptions = this.props.benchmarkOptions

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');

      if (email && selectedJob) {

        let trackOptions = ['']
        if (selectedJob.postType === "Track" || selectedJob.subPostType === 'Track') {
          trackOptions = trackOptions.concat(selectedJob.tracks)
        }

        const defaultFilterOption = this.state.defaultFilterOption

        let scorerOptions = []
        for (let i = 1; i <= applications.length; i++) {
          if (applications[i - 1].scorers) {
            for (let j = 1; j <= applications[i - 1].scorers.length; j++) {
              console.log('show scorer: ', applications[i - 1].scores[j - 1])
              if (applications[i - 1].scorers[j - 1].email) {
                if (!scorerOptions.includes(applications[i - 1].scorers[j - 1].email)) {
                  scorerOptions.push(applications[i - 1].scorers[j - 1].email)
                }
              }
            }
          }
        }

        // individual: subsidy, stage, update
        let existingFilters = false

        if (!individualFilters || individualFilters.length === 0) {
          individualFilters = [
            { name: 'Stage', value: defaultFilterOption, options: [defaultFilterOption,'Referred','Connected','Hired']},
            { name: 'Subsidy', value: defaultFilterOption, options: [defaultFilterOption,'Is Subsidy Eligible']},
            { name: 'Update At', value: defaultFilterOption, options: [defaultFilterOption,'Updated Within the Last Month','Updated Within the Last Six Months']},
          ]

          if (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) {
            individualFilters.push({ name: 'Assigned Scorer', value: defaultFilterOption, options: [defaultFilterOption].concat(scorerOptions)})
            individualFilters.push({ name: 'Assigned Staff', value: defaultFilterOption, options: [defaultFilterOption]})
          }

        } else {
          existingFilters = true
        }

        if (!trackFilters || trackFilters.length === 0) {
          trackFilters = [
            { name: 'Stage', value: defaultFilterOption, options: [defaultFilterOption,'Approved','Referred','Connected','Hired']},
            { name: 'Subsidy', value: defaultFilterOption, options: [defaultFilterOption,'Is Subsidy Eligible']},
            { name: 'Update At', value: defaultFilterOption, options: [defaultFilterOption,'Updated Within the Last Month','Updated Within the Last Six Months']},
            { name: 'Interview', value: defaultFilterOption, options: [defaultFilterOption,'Has Been Interviewed','Has Not Been Interviewed']},
            { name: 'Workforce Ready', value: defaultFilterOption, options: [defaultFilterOption,'Has Completed Workforce Ready','Has Not Completed Workforce Ready']},
            { name: 'Assigned Scorer', value: defaultFilterOption, options: [defaultFilterOption]},
            { name: 'Assigned Staff', value: defaultFilterOption, options: [defaultFilterOption]},
          ]

        } else {
          existingFilters = true
        }

        let onMobile = false
        if (window.innerWidth <= 768) {
          onMobile = true
        }

        let menuOptions = [
          { icon: stageIconDark, title: 'Change Stage / Status', shorthand: 'stage' },
          { icon: deleteIconDark, title: 'Delete', shorthand: 'delete' },
        ]

        if ((window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (selectedJob && selectedJob.postType !== 'Program')) {
          menuOptions.unshift({ icon: referIconDark, title: 'Refer to Employer', shorthand: 'refer' })
        }

        let pathPrefix = '/organizations/' + org
        if (window.location.pathname.includes('/employers')) {
          pathPrefix = '/employers/' + accountCode
        } else if (window.location.pathname.includes('/advisor')) {
          pathPrefix = '/advisor'
        }

        this.setState({ accountCode, emailId: email, username, cuFirstName, cuLastName, org,
          benchmark, benchmarkOptions, trackOptions, individualFilters, trackFilters,
          selectedJob, applications, filteredApplications: applications, onMobile, menuOptions,
          pathPrefix
        })

        Axios.get('/api/postings/byid', { params: { _id: selectedJob._id } })
        .then((response) => {
          console.log('Posted job by id query attempted', response.data);

          if (response.data.success) {
            console.log('posted jobs by id query worked')

            if (response.data.posting) {
              const newPosting = response.data.posting
              selectedJob['tracks'] = newPosting.tracks

              this.setState({ selectedJob })

            }

          } else {
            console.log('posted jobs query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
        });

        Axios.get('/api/postings/user', { params: { orgCode: org, accountCode, onlyProjects: false, pullPartnerPosts: false } })
        .then((response) => {
          console.log('Sub postings query attempted now', response.data);

          if (response.data.success) {
            console.log('posted postings query worked')

            if (response.data.postings && response.data.postings.length !== 0) {

              let postingOptions = [{ title: ''}].concat(response.data.postings)
              this.setState({ postingOptions });
            }

          } else {
            console.log('posted postings query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posted postings query did not work for some reason', error);
        });

        Axios.get('/api/applications/bypost', { params: { postingId: selectedJob._id } })
        .then((response) => {
          console.log('Applications query update attempted', response.data);

          if (response.data.success) {
            console.log('applications query update worked')

            if (response.data.applications.length !== 0) {
              if(selectedJob.postType !== 'Track' && selectedJob.subPostType !== 'Track') {

                let newApplications = response.data.applications
                for (let i = 1; i <= newApplications.length; i++) {
                  console.log('t0')

                  for (let j = 1; j <= applications.length; j++) {
                    console.log('t1')


                    if (applications[j - 1] && newApplications[i - 1]) {
                      console.log('t2')

                      if (newApplications.find(app => applications[j - 1]._id === app._id)) {
                        // console.log('found app: ', applications[j - 1].email)
                        const tempIndex = newApplications.findIndex(app => applications[j - 1]._id === app._id)
                        // console.log('found app 1: ', tempIndex, newApplications[tempIndex].email)
                      } else {
                        // console.log('found app 2: ', applications[j - 1].email)
                        applications.splice(j - 1,1)
                      }
                      if (applications[j - 1].email === newApplications[i - 1].email) {
                        console.log('t3', applications[j - 1].email)
                        // courseIndex = favoritedCourseDetails.findIndex(x => x.url === item.url);

                        if (newApplications[i - 1].match) {
                          applications[j - 1]['match'] = newApplications[i - 1].match
                        }

                        applications[j - 1]['interview'] = newApplications[i - 1].interview
                        applications[j - 1]['interviewDetails'] = newApplications[i - 1].interviewDetails
                        applications[j - 1]['interviews'] = newApplications[i - 1].interviews
                        applications[j - 1]['interviewDetailsArray'] = newApplications[i - 1].interviewDetailsArray
                        applications[j - 1]['stage'] = newApplications[i - 1].stage
                        applications[j - 1]['notes'] = newApplications[i - 1].notes
                        applications[j - 1]['feedback'] = newApplications[i - 1].feedback
                        applications[j - 1]['isSubsidyEligible'] = newApplications[i - 1].isSubsidyEligible
                        applications[j - 1]['isWorkforceReady'] = newApplications[i - 1].isWorkforceReady
                        applications[j - 1]['rank'] = newApplications[i - 1].rank
                        applications[j - 1]['liked'] = newApplications[i - 1].liked
                        applications[j - 1]['stage'] = newApplications[i - 1].stage
                      }
                    }
                  }
                  // console.log("doesn't exist? ", newApplications[i - 1], exists)
                  // if (!exists) {
                  //   // go back and delete old applications
                  //
                  //   for (let j = 1; j <= applications.length; j++) {
                  //     console.log('t31')
                  //     if (applications[j - 1] && newApplications[i - 1]) {
                  //       console.log('t32')
                  //       if (applications[j - 1].email === newApplications[i - 1].email) {
                  //         console.log('t33', newApplications[i - 1].matchScore)
                  //         applications.splice(j - 1,1)
                  //       }
                  //     }
                  //   }
                  // }
                }
              } else {
                applications = response.data.applications
              }

              // console.log('show applications: ', applications)
              this.setState({ applications, filteredApplications: applications })

              if (selectedJob.postType === 'Track' || selectedJob.subPostType === 'Individual') {
                if (existingFilters) {
                  this.filterResults(individualFilters)
                }
              } else {
                // track
                if (existingFilters) {
                  this.filterResults(trackFilters)
                }
              }
              // console.log('show filter: ', filter)
              // if (filter) {
              //   this.setState({ filter })
              //   this.filterResults(filter)
              // }
            }

          } else {
            console.log('applications query did not work', response.data.message)

          }
        }).catch((error) => {
            console.log('Application query did not work for some reason', error);
        });

        const roleNames = ['Admin']
        Axios.get('/api/org/members', { params: { orgCode: org, roleNames } })
        .then((response) => {

            if (response.data.success) {
              console.log('Member query worked', response.data);

              if (response.data.members && response.data.members.length > 0) {

                let staffMemberOptions = response.data.members
                if (staffMemberOptions) {
                  staffMemberOptions.unshift({})

                  if (individualFilters[individualFilters.length - 1].name === 'Assigned Staff') {
                    for (let j = 1; j <= staffMemberOptions.length; j++) {
                      if (staffMemberOptions[j - 1].email && staffMemberOptions[j - 1].email !== '') {
                        console.log('show staff member: ', staffMemberOptions[j - 1].email, individualFilters[individualFilters.length - 1]['options'])
                        individualFilters[individualFilters.length - 1]['options'].push(staffMemberOptions[j - 1].email)
                      }
                    }
                  }

                  this.setState({ staffMemberOptions, individualFilters })
                }
              }

            } else {
              console.log('no members found', response.data.message)

            }

        }).catch((error) => {
            console.log('Members query did not work', error);
        });

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            const stageOptions = response.data.workOptions[0].applicationStageOptions
            for (let i = 1; i <= individualFilters.length; i++) {
              if (individualFilters[i - 1].name === 'Stage') {
                individualFilters[i - 1]['options'] = [defaultFilterOption].concat(stageOptions)
              }
            }

            this.setState({ stageOptions, individualFilters })
          }
        }).catch((error) => {
            console.log('O*NET did not work', error);
        });

        let reportOptions = configureDefaultReports(true)

        Axios.get('/api/reports', { params: { orgCode: org } })
        .then((response) => {
           console.log('Reports query attempted', response.data);
            if (response.data.success && response.data.reports) {
              console.log('reports query worked')

              reportOptions.shift()
              reportOptions = response.data.reports.concat(reportOptions)
              reportOptions.unshift({ name: '' })
              // reportOptions = reportOptions.splice(1,0,response.data.reports)
              this.setState({ reportOptions })

            } else {
              console.log('reports query did not work', response.data.message)
              this.setState({ reportOptions })
            }
        }).catch((error) => {
            console.log('Reports query did not work for some reason', error);
            this.setState({ reportOptions })
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const placementPartners = response.data.orgInfo.placementPartners
            this.setState({ placementPartners })

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        let caQueryObject = { orgCode: org }
        if (accountCode) {
          caQueryObject = { accountCode }
        }

        Axios.get('/api/customassessments', { params: caQueryObject })
        .then((response) => {
          console.log('Custom assessment query attempted', response.data);

          if (response.data.success) {
            console.log('custom assessment query worked')

            if (response.data.assessments.length !== 0) {

              let assessments = response.data.assessments
              let assessmentOptions = [{ title: '' }].concat(response.data.assessments)
              let adversityListOptions = null
              for (let i = 1; i <= assessments.length; i++) {
                if (assessments[i - 1].questions && assessments[i - 1].questions.length > 0) {
                  for (let j = 1; j <= assessments[i - 1].questions.length; j++) {
                    if (assessments[i - 1].questions[j - 1].shortname === 'adversityList') {
                      adversityListOptions = assessments[i - 1].questions[j - 1].answerChoices
                    }
                  }
                }
              }

              this.setState({ assessments, assessmentOptions, adversityListOptions });
            }

          } else {
            console.log('custom assessment query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
        });

      }

    }

    favoriteApplicant(filteredIndex, segueLink) {
      console.log('favoriteApplicant tapped', filteredIndex, segueLink)

      if (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) {
        let applications = this.state.applications
        let filteredApplications = this.state.filteredApplications

        let rankCount = 0
        let index = null

        for (let i = 1; i <= applications.length; i++) {
          console.log(i, this.state.selectedJob.placementPartners, this.state.org);

          if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {
            console.log('in this org 1', applications[i - 1].partnerEvaluations)
            if (applications[i - 1].partnerEvaluations && applications[i - 1].partnerEvaluations.some(pe => pe.orgCode === this.state.org)) {
              console.log('in this org 2')
              const evalIndex = applications[i - 1].partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
              if (applications[i - 1].partnerEvaluations[evalIndex].rank) {
                rankCount = rankCount + 1
              }
            }

          } else if (this.state.applications[i - 1].rank) {
            rankCount = rankCount + 1
          }

          if (this.state.applications[i - 1]._id === this.state.filteredApplications[filteredIndex]._id) {
            index = i - 1
          }
        }

        if ((!this.state.filteredApplications[filteredIndex].rank && segueLink) || !segueLink) {
          if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {

            if (this.state.filteredApplications[filteredIndex].partnerEvaluations && this.state.filteredApplications[filteredIndex].partnerEvaluations.some(pe => pe.orgCode === this.state.org)) {
              console.log('in this org 2')
              const evalIndex = this.state.filteredApplications[filteredIndex].partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org);
              if (this.state.filteredApplications[filteredIndex].partnerEvaluations[evalIndex].rank) {
                applications[index].partnerEvaluations[evalIndex]['rank'] = null
                filteredApplications[filteredIndex].partnerEvaluations[evalIndex]['rank'] = null
              } else {
                applications[index].partnerEvaluations[evalIndex].rank = rankCount + 1
                filteredApplications[filteredIndex].partnerEvaluations[evalIndex].rank = rankCount + 1
              }
            } else {

              if (this.state.filteredApplications[filteredIndex].partnerEvaluations && this.state.filteredApplications[filteredIndex].partnerEvaluations.length > 0) {
                applications[index].partnerEvaluations[0].rank = rankCount + 1
                filteredApplications[filteredIndex].partnerEvaluations[0].rank = rankCount + 1
              } else {
                let partnerEvaluations = [{ orgCode: this.state.org, rank: rankCount + 1 }]
                applications[index]['partnerEvaluations'] = partnerEvaluations
                filteredApplications[filteredIndex]['partnerEvaluations'] = partnerEvaluations
              }
            }

          } else {

            if (this.state.filteredApplications[filteredIndex].rank) {
              console.log('up in it')
              applications[index]['rank'] = null
              filteredApplications[filteredIndex]['rank'] = null

            } else {

              applications[index]['rank'] = rankCount + 1
              filteredApplications[filteredIndex]['rank'] = rankCount + 1

            }
          }
        }

        // this.props.navigate(this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates')
        this.setState({ applications, filteredApplications })
        this.saveApplication(applications[index], segueLink, true, null)

      } else {

        const index = filteredIndex

        // employers
        let applications = this.state.applications
        if (applications[index].liked) {
          applications[index]['liked'] = false
        } else {
          applications[index]['liked'] = true
          applications[index]['disliked'] = false
        }

        // no dislike option here

        this.setState({ applications })

        const _id = this.state.applications[index]._id
        const liked = this.state.applications[index].liked
        // const disliked = this.state.applications[index].disliked
        const updatedAt = new Date()

        Axios.post('/api/applications/update-by-id', { //sessions: sessions
          _id, liked, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Your changes have been saved', response.data);
            this.setState({ serverSuccessMessage: 'Your changes have been saved' })

          } else {
            console.error('there was an error saving the application');
            this.setState({ serverErrorMessage: 'there was an error saving ' + response.data.message })
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
            this.setState({ serverErrorMessage: 'there was an error saving ' + error })
        });
      }
    }

    saveApplication(application, segueLink, updateRank, fromModal) {
      console.log('saveApplications called')

      const _id = application._id
      // const updateRank = true
      const rank = application.rank
      let partnerEvaluations = null
      if (this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) {
        partnerEvaluations = application.partnerEvaluations
      }
      const stage = application.stage

      const updatedAt = new Date()

      Axios.post('/api/applications/update-by-id', { //sessions: sessions
        _id, updateRank, rank, partnerEvaluations, stage, updatedAt })
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('Application save worked', response.data);
          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Application saved successfully!'
          })

          if (fromModal) {
            this.closeModal()
          }

          if (segueLink) {

            let applications = this.state.applications
            if (applications) {
              for (let i = 1; i <= applications.length; i++) {
                if (applications[i - 1]._id === _id) {
                  applications[i - 1]['rank'] = rank
                }
              }
            }

            const passedState = { selectedJob: this.state.selectedJob, applications, selectedBenchmark: this.state.benchmark }
            this.props.navigate(segueLink, {state: passedState})
          }

        } else {
          console.error('there was an error saving the application');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('Application save did not work', error);
      });
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.value, event.target.name)

      let name = event.target.name

      if (event.target.name === 'track') {

        let trackSelected = event.target.value
        let trackIndex = 0
        for (let i = 1; i <= this.state.trackOptions.length; i++) {
          console.log(i);
          if (trackSelected === this.state.trackOptions[i - 1].name) {
            trackIndex = i - 1
          }
        }

        let applications = this.state.applications
        console.log('initial applications', applications)
        applications.sort(function(a,b) {
          console.log('value test', a.matches[2], b.matches[2])
          return b.matches[trackIndex] - a.matches[trackIndex];
        })
        console.log('after applications', applications)

        this.setState({ trackSelected, applications, filteredApplications: applications })
      } else if (name.includes('filter')) {

        if (this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') {
          for (let i = 1; i <= trackFilters.length; i++) {
            if (trackFilters[i - 1].name === name.split('|')[1]) {
              trackFilters[i - 1]['value'] = event.target.value
            }
          }
          this.setState({ trackFilters })
          this.filterResults(trackFilters)
        } else {

          for (let i = 1; i <= individualFilters.length; i++) {
            if (individualFilters[i - 1].name === name.split('|')[1]) {
              individualFilters[i - 1]['value'] = event.target.value
            }
          }
          this.setState({ individualFilters })
          this.filterResults(individualFilters)
        }
      } else if (event.target.name === 'filter') {
        this.filterResults(event.target.value)
      } else if (name === 'search') {
        this.renderSearchResults(event.target.value)
      } else if (event.target.name === 'staffMember') {
        let staffMember = this.state.staffMember
        for (let i = 1; i <= this.state.staffMemberOptions.length; i++) {
          const fullName = this.state.staffMemberOptions[i - 1].firstName + ' ' + this.state.staffMemberOptions[i - 1].lastName
          if (fullName === event.target.value) {
            staffMember = this.state.staffMemberOptions[i - 1]
          }
        }
        this.setState({ staffMember })
      } else if (event.target.name === 'scorerEmail') {
        this.setState({ scorerEmail: event.target.value })
      } else if (event.target.name === 'scorerFirstName') {
        this.setState({ scorerFirstName: event.target.value })
      } else if (event.target.name === 'scorerLastName') {
        this.setState({ scorerLastName: event.target.value })
      } else if (event.target.name === 'scorerJobTitle') {
        this.setState({ scorerJobTitle: event.target.value })
      } else if (event.target.name === 'scorerEmployerName') {
        this.setState({ scorerEmployerName: event.target.value })
      } else if (event.target.name === 'posting') {
        let selectedPosting = null
        for (let i = 1; i <= this.state.postingOptions.length; i++) {
          const name = this.state.postingOptions[i - 1].title + " | " + this.state.postingOptions[i - 1].employerName
          if (name === event.target.value) {
            selectedPosting = this.state.postingOptions[i - 1]
          }
        }
        this.setState({ selectedPosting })
      } else if (event.target.name.startsWith("applications|")) {
        let filteredApplications = this.state.filteredApplications
        console.log('----app', event.target.name)
        const index = Number(event.target.name.split("|")[1])
        const name = event.target.name.split("|")[2]

        filteredApplications[index][name] = event.target.value
        this.setState({ filteredApplications })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    filterResults(filters) {
      console.log('filterResults called', filters)

      let filteredApplications = [];
      for (let i = 1; i <= this.state.applications.length; i++) {
        console.log(i);

        //stage
        let pushStage = false
        if (filters[0].value === this.state.defaultFilterOption) {
          pushStage = true
        } else if (filters[0].value === 'Approved' && this.state.selectedJob.tracks && this.state.selectedJob.tracks.length > 0) {
          //only applies to tracks
          //has one or more track approval
          let approved = false
          for (let j = 1; j <= this.state.selectedJob.tracks.length; j++) {
            if (this.state.selectedJob.tracks[j - 1].approvedApplicants.includes(this.state.applications[i - 1].email)) {
              approved = true
            }
          }

          if (approved) {
            pushStage = true
          }
        } else if (this.state.applications[i - 1].stage === filters[0].value) {
          // if (this.state.applications[i - 1].stage === filters[0].value) {
          //   pushStage = true
          // }
          pushStage = true
        }

        let pushSubsidy = false
        //subsidy
        if (filters[1].value === this.state.defaultFilterOption) {
          pushSubsidy = true
        } else {
          let isSubsidyEligible = false
          if (this.state.applications[i - 1].isSubsidyEligible) {
            isSubsidyEligible = true
          }

          if (isSubsidyEligible) {
            pushSubsidy = true
          }
        }

        let pushUpdatedAt = false
        if (filters[2].value === this.state.defaultFilterOption) {
          pushUpdatedAt = true
        } else {
          let updatedAt = this.state.applications[i - 1].updatedAt
          if (updatedAt) {
            console.log('show updated at plz 1: ', updatedAt)
            const currentTime = new Date()

            let comparedTime  = new Date()
            if (filters[2].value === 'Updated Within the Last Month') {
              comparedTime = currentTime.setMonth(currentTime.getMonth() - 1)
            } else if ('Updated Within the Last Six Months') {
              comparedTime = currentTime.setMonth(currentTime.getMonth() - 6)
            }

            const updatedAtTime = new Date(updatedAt).getTime()
            console.log('show updated at plz 2: ', updatedAtTime, comparedTime)
            if (updatedAtTime > comparedTime) {
              pushUpdatedAt = true
            }
          }
        }

        //interview
        let pushInterview = false
        if (filters[3]) {
          if (filters[3].value === this.state.defaultFilterOption) {
            pushInterview = true
          } else {

            let interviewed = false
            for (let j = 1; j <= this.state.applications[i - 1].interviews.length; j++) {
              if (Number(this.state.applications[i - 1].interviews[j - 1].score) > 0) {
                interviewed = true
              }
            }

            if (interviewed && filters[3].value === 'Has Been Interviewed') {
              pushInterview = true
            } else if (!interviewed && filters[3].value === 'Has Not Been Interviewed') {
              pushInterview = true
            }
          }
        }

        //workforce ready
        let pushWorkforceReady = false
        if (filters[4]) {
          if (filters[4].value === this.state.defaultFilterOption) {
            pushWorkforceReady = true
          } else {
            let isWorkforceReady = false
            if (this.state.applications[i - 1].isWorkforceReady) {
              isWorkforceReady = true
            }

            if (isWorkforceReady && filters[4].value === 'Has Completed Workforce Ready') {
              pushWorkforceReady = true
            } else if (!isWorkforceReady && filters[4].value === 'Has Not Completed Workforce Ready') {
              pushWorkforceReady = true
            }
          }
        }

        // assign scorer
        let pushScorer = false
        console.log('show filter value: ', filters[3].value)

        if (filters[3]) {
          if (filters[3].value === this.state.defaultFilterOption) {
            pushScorer = true
          } else {

            // let includesScore = true
            if (this.state.applications[i - 1].scorers && this.state.applications[i - 1].scorers.length > 0) {
              for (let j = 1; j <= this.state.applications[i - 1].scorers.length; j++) {
                if (this.state.applications[i - 1].scorers[j - 1]) {
                  if (this.state.applications[i - 1].scorers[j - 1].email === filters[3].value) {
                    pushScorer = true
                  }
                }
              }
            }
          }
        }

        // assign staff
        let pushStaff = false
        if (filters[4]) {
          if (filters[4].value === this.state.defaultFilterOption) {
            pushStaff = true
          } else {

            if (this.state.applications[i - 1].assignedStaff) {
              if (this.state.applications[i - 1].assignedStaff.email === filters[4].value) {
                pushStaff = true
              }
            }
          }
        }

        if (this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') {
          if (pushStage && pushSubsidy && pushUpdatedAt && pushInterview && pushWorkforceReady) {
            filteredApplications.push(this.state.applications[i - 1])
          }
        } else {
          console.log('show all: ', pushStage, pushSubsidy, pushUpdatedAt, pushScorer, pushStaff, this.state.applications[i - 1])
          if (pushStage && pushSubsidy && pushUpdatedAt && pushScorer && pushStaff) {
            filteredApplications.push(this.state.applications[i - 1])
          }
        }

      }

      this.setState({ filteredApplications })

      // if (this.state.selectedJob.postType === 'Individual' || this.state.selectedJob.subPostType === 'Individual') {
      //   individualFilters
      // } else {
      //
      // }

    }

    renderSearchResults(searchString) {
      console.log('renderSearchResults called')

      let filteredApplications = [];

      for (let i = 1; i <= this.state.applications.length; i++) {
        console.log(i);

        let name = ''
        if (this.state.applications[i - 1].firstName) {
          name = name + this.state.applications[i - 1].firstName.toLowerCase()
        } else {
          console.log('could not get name: ', this.state.applications[i - 1])
        }

        if (this.state.applications[i - 1].firstName) {
          name = name + this.state.applications[i - 1].lastName.toLowerCase()
        }

        if (this.state.applications[i - 1].email) {
          name = name + this.state.applications[i - 1].email.toLowerCase()
        }

        if (name.includes(searchString.toLowerCase())) {
            console.log('included', i)

            filteredApplications.push(this.state.applications[i - 1])
        }
      }

      this.setState({ searchString, filteredApplications })

    }

    renderApplications() {
      console.log('renderApplications called', this.state.filteredApplications.length)

      let rows = [];

      rows.push(
        <div key="0">
          {(this.state.checkCount > 0) && (
            <div className="description-text-3 dark-background height-50 left-padding half-bold-text white-text">
              {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (
                <div>
                  <div className="float-left row-5 top-margin-5">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showDeleteApplications: false, showAssignScorers: true, showAssignStaff: false })}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={assigneeIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Assign Scorers</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-5 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showDeleteApplications: false, showAssignScorers: false, showAssignStaff: true })}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={assigneeIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Assign Staff</p>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="float-left row-5 left-padding top-margin-5">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showBulkChangeStage: true })}>
                      <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                        <div className="fixed-column-40">
                          <img src={stageIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                        </div>
                        <div className="float-left white-text right-padding">
                          <p>Bulk-Change Stage</p>
                        </div>
                      </div>
                    </a>
                  </div>


                  {(this.state.selectedJob.workflowType === 'Common App') && (
                    <div className="float-left row-5 left-padding top-margin-5">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showDuplicateApplication: true })}>
                        <div className="float-left horizontal-padding-4 darkish-background rounded-corners row-5">
                          <div className="fixed-column-40">
                            <img src={pathsIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                          </div>
                          <div className="float-left white-text right-padding">
                            <p>Duplicate to Other Postings</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}

                  {(this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) ? (
                    <div />
                  ) : (
                    <div className="float-left row-5 left-padding top-margin-5">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showDeleteApplications: true, showAssignScorers: false, showAssignStaff: false })}>
                        <div className="fixed-column-110 error-background-color rounded-corners row-5">
                          <div className="fixed-column-40">
                            <img src={trashIconWhite} alt="Guided Compass checkbox icon" className="image-auto-16 left-margin" />
                          </div>
                          <div className="fixed-column-70 white-text">
                            <p>Delete</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="description-text-3 selected-background-light height-40 bold-text">
            <div className="calc-column-offset-100">
              <div className="fixed-column-40 padding-10">
                <a className="background-link" onClick={() => this.checkAllApplications()}>
                  <img src={(this.state.allChecked) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-18" />
                </a>
              </div>
              <div className="fixed-column-40 padding-10">
                <p>#</p>
              </div>
              <div className="calc-column-offset-690">
                <div className="relative-column-40">
                  <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('firstName',this.state.filteredApplications)}>
                    <p>Name</p>
                  </button>
                </div>
                <div className="relative-column-60">
                  <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('email',this.state.filteredApplications)}>
                    <p>Email</p>
                  </button>
                </div>
              </div>
              <div className="fixed-column-70">
                <button className="background-button full-space left-text padding-10" onClick={(this.state.org === 'unite-la') ? () => this.sortValues('dateOfBirth',this.state.filteredApplications) : () => this.sortValues('pictureURL',this.state.filteredApplications)}>
                  <p>{(this.state.org === 'unite-la') ? "Age" : "Pic"}</p>
                </button>
              </div>
              <div className="fixed-column-130">
                <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('phoneNumber',this.state.filteredApplications)}>
                  <p>Phone Number</p>
                </button>
              </div>
              <div className="fixed-column-70">
                <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('resumeURL',this.state.filteredApplications)}>
                  <p>Resume</p>
                </button>
              </div>
              <div className="fixed-column-100">
                <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('stage',this.state.filteredApplications)}>
                  <p>Stage</p>
                </button>
              </div>
              {/* pic, name, email, phoneNumber, resumeURL, educationStatus, projectHours, totalHours, match, stage, date
                decisions: hire, favorite/refer, mark ineligible, delete
              <div className="fixed-column-150 top-padding">
                <p>Name</p>
              </div>
              <div className="fixed-column-230 top-padding">
                <p>Email</p>
              </div>*/}
              <div className="fixed-column-80">
                <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('match',this.state.filteredApplications)}>
                  <p>Match</p>
                </button>
              </div>
              <div className="fixed-column-100">
                <button className="background-button full-space left-text padding-10" onClick={() => this.sortValues('createdAt',this.state.filteredApplications)}>
                  <p>Submitted</p>
                </button>
              </div>
              {/*
              {(this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') && (
                <div className="float-left">
                  <div className="fixed-column-50 top-padding">
                    <p>Int</p>
                  </div>
                  <div className="fixed-column-50 top-padding">
                    <p>App</p>
                  </div>
                </div>
              )}*/}
            </div>
            {/*
            {(this.state.appsToDelete && this.state.appsToDelete.length > 0) && (
              <div className="float-left">
                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true })}>
                  <div className="fixed-column-110 error-background-color rounded-corners">
                    <div className="fixed-column-40">
                      <div className="spacer" />
                      <img src={trashIconWhite} alt="Guided Compass checkbox icon" className="image-auto-18 left-margin" />
                    </div>
                    <div className="fixed-column-70 white-text">
                      <p>Delete</p>
                    </div>
                  </div>
                </a>
              </div>
            )}*/}
            <div className="clear" />
          </div>
        </div>
      )

      for (let i = 1; i <= this.state.filteredApplications.length; i++) {
        console.log('show fa: ', i, this.state.filteredApplications[i - 1].email, this.state.filteredApplications[i - 1].rank);

        const item = this.state.filteredApplications[i - 1]
        const index = i - 1

        let interviewed = false
        if (item.interviews) {
          for (let j = 1; j <= item.interviews.length; j++) {
            if(item.interviews[j - 1]) {
              if (Number(item.interviews[j - 1].score) > 0) {
                interviewed = true
              }
            } else {
              console.log('filteredApp: ', this.state.filteredApplications[i - 1])
            }
          }
        } else {
          // console.log('filteredApp: ', this.state.filteredApplications[i - 1])
        }

        let approved = false
        for (let j = 1; j <= this.state.selectedJob.tracks.length; j++) {
          if (this.state.selectedJob.tracks[j - 1].approvedApplicants.includes(item.email)) {
            approved = true
          }
        }

        let match = ''
        if (item.match) {
          match = item.match + '%'
        }

        // let createdAt = ''
        // if (item.createdAt) {
        //   createdAt = 'Applied: ' + item.createdAt.substring(0,10) + ' | '
        // }

        let pathPrefix = '/organizations/' + this.state.org
        if (window.location.pathname.includes('/employers')) {
          pathPrefix = '/employers/' + this.state.accountCode
        } else if (window.location.pathname.includes('/advisor')) {
          pathPrefix = '/advisor'
        }

        rows.push(
          <div key={i}>
            <div className="clear" />

            <div className="row-5 description-text-2">
              <div className="calc-column-offset-100">
                <div className="fixed-column-40 padding-10">
                  <a className="background-link" onClick={() => this.checkApplication(index)}>
                    <img src={(this.state.filteredApplications[index].isChecked) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-18" />
                  </a>
                </div>
                <div className="fixed-column-40 padding-10">
                  <p>{index + 1}.</p>
                </div>
                <div className="calc-column-offset-690">
                  <div className="relative-column-40 padding-10">
                    <Link className="background-button cta-color" to={pathPrefix + '/postings/' + this.state.selectedJob._id + '/applications/' + item._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: this.state.filteredApplications[i - 1], applications: this.state.applications, benchmark: this.state.benchmark }}>
                      <p className="underline-text offset-underline">{item.firstName} {item.lastName}</p>
                    </Link>
                  </div>
                  <div className="relative-column-60 padding-10">
                    <p>{item.email}</p>
                  </div>
                </div>
                <div className="fixed-column-70 padding-10">
                  {(this.state.org === 'unite-la') ? (
                    <div>
                      {(item.dateOfBirth) ? <p>{calculateAge(item.dateOfBirth)}</p> : <p> - </p>}
                    </div>
                  ) : (
                    <div>
                      {(item.pictureURL) ? <a href={item.pictureURL} target="_blank" rel="noopener noreferrer">Link</a> : <p> - </p>}
                    </div>
                  )}
                </div>
                <div className="fixed-column-130 padding-10">
                  <p>{item.phoneNumber}</p>
                </div>
                <div className="fixed-column-70 padding-10">
                  {(item.resumeURL) ? <a href={item.resumeURL} target="_blank" rel="noopener noreferrer">Link</a> : <p> - </p>}
                </div>
                <div className="fixed-column-100 padding-10">
                  <p>{item.stage}</p>
                </div>
                {/* pic, name, email, phoneNumber, resumeURL, educationStatus, projectHours, totalHours, match, stage, date
                  decisions: hire, favorite/refer, mark ineligible, delete
                <div className="fixed-column-150 top-padding">
                  <p>Name</p>
                </div>
                <div className="fixed-column-230 top-padding">
                  <p>Email</p>
                </div>*/}
                <div className="fixed-column-80 padding-10">
                  <p>{match}</p>
                </div>
                <div className="fixed-column-100 padding-10">
                  <p>{(item.createdAt) && item.createdAt.substring(0,10)}</p>
                </div>
              </div>
              <div className="fixed-column-100">
                <div className="float-right top-margin">
                  <Link className="secret-link" to={pathPrefix + '/postings/' + this.state.selectedJob._id + '/applications/' + item._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: this.state.filteredApplications[i - 1], applications: this.state.applications, benchmark: this.state.benchmark }}>
                    <div className="float-right horizontal-padding-4">
                      <img src={arrowIndicatorIconBlue} alt="GC" className="image-auto-18"/>
                    </div>
                  </Link>
                </div>
                <div className="float-right right-padding-20 top-margin-5 relative-position">
                  <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                    <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                  </button>

                  {(item.showMenu) && (
                    <div className="menu-bottom-right-clear-padding padding-10 top-margin">
                      {this.state.menuOptions.map((item2,optionIndex) =>
                        <div key={item2.title}>
                          {(item2.title === 'Delete' && item.confirmDelete) ? (
                            <div>
                              <p className="description-text-1 error-color bold-text row-10">You sure?</p>
                              <div className="container-left">
                                <button className="btn btn-squarish error-background-color full-width clear-border description-text-3" onClick={() => this.deleteApps([item._id])}>Confirm</button>
                              </div>
                              <div className="container-right">
                                <button className="btn btn-squarish white-background full-width cta-color description-text-3" onClick={() => this.menuButtonClicked(item2.shorthand,index)}>Cancel</button>
                              </div>
                              <div className="clear" />
                            </div>
                          ) : (
                            <button className="background-button row-10 clear-margin left-text full-width" onClick={() => this.menuButtonClicked(item2.shorthand,index)}>
                              <div className="fixed-column-25">
                                <img src={item2.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                              </div>
                              <div className="calc-column-offset-25">
                                <p className={(item2.title === 'Delete') ? "error-color bold-text" : "description-text-"}>{item2.title}</p>
                              </div>
                              <div className="clear" />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="clear" />



              {/*
              <Link className="background-button calc-column-offset-130 description-text-2" to={pathPrefix + '/postings/' + this.state.selectedJob._id + '/applications/' + item._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: this.state.filteredApplications[i - 1], benchmark: this.state.benchmark, benchmarkOptions: this.state.benchmarkOptions }}>
                <span className="fixed-column-50">
                  <div className="half-spacer" />
                  <img src={(this.state.applications[i - 1].pictureURL) ? this.state.applications[i - 1].pictureURL : profileIconGrey} alt="GC icon" className="profile-thumbnail-5 " />
                </span>
                <div className="calc-column-offset-220 top-padding">
                  <div className="relative-column-40">
                    <p>{item.firstName} {item.lastName}</p>
                  </div>
                  <div className="relative-column-60">
                    <p>{item.email}</p>
                  </div>
                </div>
                <div className="fixed-column-80 left-padding top-margin-negative-5 top-padding">
                  {(this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') ? (
                    <div>
                      <p> </p>
                    </div>
                  ) : (
                    <p className="heading-text-3 cta-color">{match}</p>
                  )}
                </div>
                <div className="fixed-column-90 left-padding top-padding">
                  <p>{(item.createdAt) && item.createdAt.substring(0,10)}</p>
                </div>
                {(this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') && (
                  <div className="float-left">
                    {(interviewed) ? (
                      <div className="fixed-column-60">
                        <div className="spacer"/>
                        <img src={interviewIcon} alt="Compass arrow indicator icon" className="image-auto-20"/>
                      </div>
                    ) : (
                      <div className="fixed-column-60">
                        <div className="spacer"/>
                        <p> </p>
                      </div>
                    )}
                    {(approved) ? (
                      <div className="fixed-column-60">
                        <div className="spacer"/>
                        <img src={checkmarkIcon} alt="Compass arrow indicator icon" className="image-auto-20"/>
                      </div>
                    ) : (
                      <div className="fixed-column-60">
                        <div className="spacer"/>
                        <p> </p>
                      </div>
                    )}
                  </div>
                )}

                {(item.employerRanking || item.liked || item.disliked) && (
                  <div className="left-padding-40 top-margin">
                    <div className="clear" />
                    {(item.employerRanking) && (
                      <label className="standard-color right-padding-5">Employer Ranking: {item.employerRanking}.</label>
                    )}

                    {(item.liked) && (
                      <label className="standard-color">Employer <label className="cta-color">likes</label> this candidate.</label>
                    )}
                    {(item.disliked) && (
                      <label className="standard-color">Employer <label className="error-color">dislikes</label> this candidate.</label>
                    )}
                    <div className="clear" />
                  </div>
                )}
              </Link>

              <div className="float-right top-margin">
                <Link className="secret-link" to={pathPrefix + '/postings/' + this.state.selectedJob._id + '/applications/' + item._id} state={{ selectedJob: this.state.selectedJob, selectedApplication: this.state.filteredApplications[i - 1], benchmark: this.state.benchmark }}>
                  <div className="float-right right-margin">
                    <img src={arrowIndicatorIcon} alt="GC" className="arrow-indicator-icon"/>
                  </div>
                </Link>

                <button className="background-button" onClick={() => this.favoriteApplicant(i - 1)}>
                  <div className="float-right width-50">
                    {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) ? (
                      <div>
                        {(this.state.selectedJob.placementPartners && this.state.selectedJob.placementPartners.includes(this.state.org)) ? (
                          <div>
                            {console.log('show 1: ', item.partnerEvaluations)}
                            {(item.partnerEvaluations && item.partnerEvaluations.some(pe => pe.orgCode === this.state.org)) ? (
                              <div>
                                {(item.partnerEvaluations[item.partnerEvaluations.findIndex(pe => pe.orgCode === this.state.org)].rank) ? (
                                  <img src={favoriteIconSelected} alt="Compass arrow indicator icon" className="image-auto-20 top-margin-negative-4" />
                                ) : (
                                  <img src={favoriteIconUnselected} alt="Compass arrow indicator icon" className="image-auto-20 top-margin-negative-10 right-margin" />
                                )}
                              </div>
                            ) : (
                              <img src={favoriteIconUnselected} alt="Compass arrow indicator icon" className="image-auto-20 top-margin-negative-10 right-margin" />
                            )}
                          </div>
                        ) : (
                          <img src={(item.rank) ? favoriteIconSelected : favoriteIconUnselected} alt="Compass arrow indicator icon" className={(item.rank) ? "image-auto-20 top-margin-negative-4" : "image-auto-20 top-margin-negative-10 right-margin"} />
                        )}
                      </div>
                    ) : (
                      <div>
                        <img src={(item.liked) ? favoriteIconSelected : favoriteIconUnselected} alt="Compass arrow indicator icon" className={(item.liked) ? "image-auto-20 top-margin-negative-4" : "image-auto-20 top-margin-negative-10 right-margin"} />
                      </div>
                    )}
                  </div>
                </button>
              </div>*/}
            </div>
            <div className="clear" />
            {/*
            {(this.state.laterStages.includes(this.state.filteredApplications[i - 1].stage)) && (
              <div className="left-padding-50">
                <label className="error-color description-text-2 clear-margin clear-padding">Application Stage: {this.state.filteredApplications[i - 1].stage}</label>
                {(this.state.filteredApplications[i - 1].employerRanking) && (
                  <label className="error-color description-text-2 clear-margin clear-padding"> | Employer Ranking: {this.state.filteredApplications[i - 1].employerRanking}</label>
                )}
              </div>
            )}*/}
            {(this.state.filteredApplications[i - 1].employerRanking) && (
              <div className="left-padding-50 row-10">
                <label className="error-color description-text-2 clear-margin clear-padding"> | Employer Ranking: {this.state.filteredApplications[i - 1].employerRanking}</label>
              </div>
            )}
            <div className="clear" />

            <div className="left-padding-50">
              {this.state.individualFilters.map((value, optionIndex) =>
                <span>
                  {(value.value !== 'All') && (
                    <label key={optionIndex} className="description-text-3 error-color right-padding">{value.name}: {value.value}</label>
                  )}
                </span>
              )}
            </div>

            <hr />
          </div>
        )
      }

      return rows;
    }

    checkApplication(index) {
      console.log('checkApplication called')

      let filteredApplications = this.state.filteredApplications
      let appsToDelete = this.state.appsToDelete

      if (filteredApplications[index].isChecked) {
        filteredApplications[index]['isChecked'] = false
        let deleteIndex = appsToDelete.indexOf(filteredApplications[index]._id)
        appsToDelete.splice(deleteIndex, 1)
        const checkCount = this.state.checkCount - 1
        this.setState({ filteredApplications, appsToDelete, checkCount })
      } else {
        filteredApplications[index]['isChecked'] = true
        appsToDelete.push(filteredApplications[index]._id)
        const checkCount = this.state.checkCount + 1
        this.setState({ filteredApplications, appsToDelete, checkCount })
      }
    }

    checkAllApplications() {
      console.log('checkAllApplications called')

      let filteredApplications = this.state.filteredApplications
      let appsToDelete = []
      let allChecked = this.state.allChecked
      let checkCount = filteredApplications.length
      if (!allChecked) {
        allChecked = true
        for (let i = 1; i <= this.state.filteredApplications.length; i++) {
          filteredApplications[i - 1]['isChecked'] = true
          appsToDelete.push(filteredApplications[i - 1]._id)
        }
      } else {
        allChecked = false
        checkCount = 0
        for (let i = 1; i <= this.state.filteredApplications.length; i++) {
          filteredApplications[i - 1]['isChecked'] = false
        }
      }

      this.setState({ allChecked, filteredApplications, appsToDelete, checkCount })
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showDeleteApplications: false, showAssignScorers: false,
        showAssignStaff: false, showDuplicateApplication: false,
        showChangeStage: false, selectedApplication: null, selectedIndex: null, showBulkChangeStage: false
      });
    }

    deleteApps(appsToDelete) {
      console.log('deleteApps called')

      if (!appsToDelete) {
        appsToDelete = this.state.appsToDelete
      }

      Axios.delete('/api/applications/1', { data: { appsToDelete } })
      .then((response) => {
        console.log('tried to delete', response.data)
        if (response.data.success) {
          //save values
          console.log('App delete worked');

          //reset appsToDelete, removeApplications

          let applications = []
          for (let i = 1; i <= this.state.applications.length; i++) {
            // if (!this.state.applications[i - 1]['isChecked']) {
            //   applications.push(this.state.applications[i - 1])
            // }
            console.log("a1", appsToDelete, this.state.applications[i - 1])
            if (!appsToDelete.includes(this.state.applications[i - 1]._id)) {
              console.log("a2", appsToDelete, this.state.applications[i - 1])
              applications.push(this.state.applications[i - 1])
            }
          }
          console.log('a3', applications)
          let filteredApplications = applications
          appsToDelete = []

          this.setState({
            serverPostSuccess: true,
            serverSuccessMessage: 'Application was deleted successfully! Once you exit, you will no longer have access to the posting.',
            hasRemoved: true, appsToDelete, applications, filteredApplications
          })

        } else {
          console.error('there was an error deleting the application');
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: response.data.message,
          })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          this.setState({
            serverPostSuccess: false,
            serverErrorMessage: error,
          })
      });
    }

    addItem(type) {
      console.log('addItem called', type)

      this.setState({ errorMessage: null })

      if (type === 'scorer') {
        if (!this.state.scorerEmail) {
          this.setState({ errorMessage: 'Please add a valid email'})
        } else if (!this.state.scorerEmail.includes('@')) {
          this.setState({ errorMessage: 'Please add a valid email'})
        } else {
          let scorers = this.state.scorers
          scorers.push({
            email: this.state.scorerEmail, firstName: this.state.scorerFirstName, lastName: this.state.scorerLastName,
            jobTitle: this.state.scorerJobTitle, employerName: this.state.scorerEmployerName
          })
          this.setState({ scorers })
        }

      } else if (type === 'staff') {
        if (this.state.staffMember) {
          let assignedStaff = this.state.staffMember
          let staffMember = {}

          this.setState({ assignedStaff, staffMember })

        } else {
          // please add a staff member
          const errorMessage = 'please select a staff member'
          console.log(errorMessage)
          this.setState({ errorMessage })
        }
      } else if (type === 'posting') {
        if (this.state.selectedPosting) {
          let selectedPostings = this.state.selectedPostings
          selectedPostings.push(this.state.selectedPosting)
          let selectedPosting = null

          this.setState({ selectedPostings, selectedPosting })

        } else {
          // please add a staff member
          const errorMessage = 'please select a posting'
          console.log(errorMessage)
          this.setState({ errorMessage })
        }
      }
    }

    renderMatchScores(index) {
      console.log('renderMatchScores called', index)

      let rows = [];
      for (let i = 1; i <= this.state.filteredApplications[index].matches.length; i++) {
        console.log(i, this.state.filteredApplications[index], this.state.filteredApplications[index].matches[i - 1])

        if (this.state.filteredApplications[index].matches[i - 1]) {
          let matchValue = this.state.filteredApplications[index].matches[i - 1].toString() + "%"
          if (rows.length === 0) {
            rows.push(matchValue)
          } else {
            rows.push("| " + matchValue)
          }
        }
      }
      return rows
    }

    renderManipulators(type) {
      console.log('renderManipulators called', type)

      if (type === 'filter') {
        let filters = []
        // if (this.state.selectedJob.postType === 'Track' || this.state.selectedJob.subPostType === 'Track') {
        //   // Track
        //   filters = this.state.trackFilters
        // } else {
        //   // individual
        //   filters = this.state.individualFilters
        // }
        filters = this.state.individualFilters
        if (filters) {

          let rows = []
          for (let i = 1; i <= filters.length; i++) {
            rows.push(
              <div key={filters[i - 1] + i.toString()} className="float-left right-padding bottom-margin">
                <SubCustomDropdown dropdownTitle={filters[i - 1].name} name={"filter|" + filters[i - 1].name} value={filters[i - 1].value} options={filters[i - 1].options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} clearPadding={true} />
              </div>
            )
          }

          return rows

        }
      } else if (type === 'sort') {

      }
    }

    removeTag(type, index) {
      console.log('removeTag called', type, index)

      if (type === 'scorer') {
        let scorers = this.state.scorers
        scorers.splice(index, 1)
        this.setState({ scorers })
      } else if (type === 'staff') {
        let assignedStaff = {}
        this.setState({ assignedStaff })
      } else if (type === 'posting') {
        let selectedPostings = this.state.selectedPostings
        selectedPostings.splice(index,1)
        this.setState({ selectedPostings })
      }
    }

    assign(type) {
      console.log('assign called', type)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (type === 'scorer') {
        // configure scorer
        const scorers = this.state.scorers
        const appIds = this.state.appsToDelete

        // save assignments
        Axios.post('/api/applications/assign-people', { //sessions: sessions
          type, scorers, appIds })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application saves worked', response.data);
            this.setState({ successMessage: 'Application saved successfully!', isSaving: false })

          } else {
            console.error('there was an error saving the application');
            this.setState({ errorMessage: response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
            this.setState({ errorMessage: error, isSaving: false })
        });

      } else if (type === 'staff'){
        // configure staff

        const assignedStaff = this.state.assignedStaff
        const appIds = this.state.appsToDelete

        // save assignments
        Axios.post('/api/applications/assign-people', { //sessions: sessions
          type, assignedStaff, appIds })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Application saves worked', response.data);
            this.setState({ successMessage: 'Application saved successfully!', isSaving: false })

          } else {
            console.error('there was an error saving the application');
            this.setState({ errorMessage: response.data.message, isSaving: false })
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
            this.setState({ errorMessage: error, isSaving: false })
        });
      } else if (type === 'stage'){
        console.log('bulk change stage')

        let filteredApplications = this.state.filteredApplications
        let appIds = []
        const stage = this.state.selectedStage

        for (let i = 1; i <= filteredApplications.length; i++) {
          if (filteredApplications[i - 1].isChecked) {
            appIds.push(filteredApplications[i - 1]._id)
            filteredApplications[i - 1]['stage'] = this.state.selectedStage
            filteredApplications[i - 1]['isChecked'] = false
          }
        }

        const putObject = { appIds, stage }
        Axios.put('/api/applications/update', putObject)
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('You have updated applications', response.data);

            this.setState({ filteredApplications, serverSuccessMessage: response.data.message })
            this.closeModal()

          } else {
            console.error('there was an error updating the application');
            this.setState({ serverErrorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Application save did not work', error);
            this.setState({ serverErrorMessage: 'there was an error saving ' + error.toString() })
        });

      } else if (type === 'application'){

        if (!this.state.selectedPostings || this.state.selectedPostings.length === 0) {
          this.setState({ isSaving: false, errorMessage: 'Please select at least one posting' })
        } else {

          // need applicationObjects to duplicate, postingIds, postingTitle, postingEmployerName
          // loop through posting Ids and save new application - change postingId, postingTitle, postingEmployerName
          const orgCode = this.state.org

          let selectedApplications = []
          for (let i = 1; i <= this.state.filteredApplications.length; i++) {
            if (this.state.filteredApplications[i - 1].isChecked) {
              selectedApplications.push(this.state.filteredApplications[i - 1])
            }
          }

          let selectedPostings = []
          for (let i = 1; i <= this.state.selectedPostings.length; i++) {
            selectedPostings.push({
              _id: this.state.selectedPostings[i - 1]._id,
              title: this.state.selectedPostings[i - 1].title,
              employerName: this.state.selectedPostings[i - 1].employerName,
              location: this.state.selectedPostings[i - 1].location,
            })
          }

          console.log('about to save application')

          Axios.put('/api/applications/duplicate', { orgCode, selectedApplications, selectedPostings })
          .then((response) => {
            console.log('Duplicate applications update attempted', response.data);

              if (response.data.success) {
                console.log('duplicate applications notification worked')
                this.setState({
                  sSaving: false, selectedPostings: [],
                  successMessage: 'Successfully duplicated application to the selected postings'
                })

              } else {
                console.log('duplicate applications did not work', response.data.message)
                this.setState({ isSaving: false, errorMessage: 'There was an issue duplicating the postings: ' + response.data.message })
              }

          }).catch((error) => {
              console.log('duplicate applications did not work for some reason', error);
          })
        }
      }
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      let filteredApplications = this.state.filteredApplications
      if (this.state.filteredApplications[index].showMenu) {
        filteredApplications[index]['showMenu'] = false
      } else {
        filteredApplications[index]['showMenu'] = true
      }

      this.setState({ filteredApplications })

    }

    menuButtonClicked(action, index) {
      console.log('menuButtonClicked called', action, index)

      let filteredApplications = this.state.filteredApplications
      if (action === 'refer') {
        // this.props.navigate(this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates')
        const segueLink = this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates'
        this.favoriteApplicant(index, segueLink)
      } else if (action === 'stage') {
        filteredApplications[index]['showMenu'] = false
        this.setState({ modalIsOpen: true, showChangeStage: true, filteredApplications, selectedApplication: filteredApplications[index], selectedIndex: index })
      } else if (action === 'delete') {
        if (filteredApplications[index].confirmDelete) {
          filteredApplications[index]['confirmDelete'] = false
        } else {
          filteredApplications[index]['confirmDelete'] = true
        }

        this.setState({ filteredApplications })

      }
    }

    sortValues(type, values) {
      console.log('sortValues called', type, values)

      let sortValue = type

      let filteredApplications = values
      let sortDescending = this.state.sortDescending

      if (type === 'pictureURL') {

        if (sortDescending) {
          filteredApplications.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.pictureURL) {
              textA = a.pictureURL
            }
            if (b.pictureURL) {
              textB = b.pictureURL
            }
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        } else {
          filteredApplications.sort(function(a, b) {
            let textA = ''
            let textB = ''
            if (a.pictureURL) {
              textA = a.pictureURL
            }
            if (b.pictureURL) {
              textB = b.pictureURL
            }
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }
      // } else if (type === 'email') {
      //   if (sortDescending) {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.enrollmentStatus) {
      //         textA = a.enrollmentStatus
      //       }
      //       if (b.enrollmentStatus) {
      //         textB = b.enrollmentStatus
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.enrollmentStatus) {
      //         textA = a.enrollmentStatus
      //       }
      //       if (b.enrollmentStatus) {
      //         textB = b.enrollmentStatus
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else if (type === 'phoneNumber') {
      //   if (sortDescending) {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.phoneNumber) {
      //         textA = a.phoneNumber
      //       }
      //       if (b.phoneNumber) {
      //         textB = b.phoneNumber
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.phoneNumber) {
      //         textA = a.phoneNumber
      //       }
      //       if (b.phoneNumber) {
      //         textB = b.phoneNumber
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      // } else if (type === 'numeric') {
      //   if (sortDescending) {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.createdAt) {
      //         textA = a.createdAt
      //       }
      //       if (b.createdAt) {
      //         textB = b.createdAt
      //       }
      //       return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      //     });
      //   } else {
      //     filteredApplications.sort(function(a, b) {
      //       let textA = ''
      //       let textB = ''
      //       if (a.createdAt) {
      //         textA = a.createdAt
      //       }
      //       if (b.createdAt) {
      //         textB = b.createdAt
      //       }
      //       return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //     });
      //   }
      } else {

        if (sortDescending) {
          filteredApplications.sort(function(a, b){
              if(a[sortValue] > b[sortValue]) { return -1; }
              if(a[sortValue] < b[sortValue]) { return 1; }
              return 0;
          })
        } else {
          filteredApplications.sort(function(a, b){
              if(a[sortValue] < b[sortValue]) { return -1; }
              if(a[sortValue] > b[sortValue]) { return 1; }
              return 0;
          })
          console.log('?!')
        }
      }

      sortDescending = !sortDescending
      this.setState({ filteredApplications, sortDescending })

      // 'numeric','pic','name','email','roleName','pair','phoneNumber','enrollmentStatus','submissionCount','avgRating','grade'
    }

    exportInfo = async() => {
    // exportInfo() {
      console.log('exportInfo called')

      if (this.state.showModule) {

        const reportOptions = this.state.reportOptions
        const reportName = "Applications Report"
        const passedName = null
        const passedValue = null
        const fields = null
        const reportType = "applications"
        const orgCode = this.state.org
        const placementPartners = this.state.placementPartners
        const surveyName = null
        const lessonName = null
        const selectedEvent = null
        const selectedProject = null
        const selectedWork = this.state.selectedJob
        const approved = null
        const logType = null
        const assignedStaffName = null
        const assignedStaffEmail = null
        const olderThan = null
        const youngerThan = null
        const department = null
        const pathway = null
        const accountCode = null
        const afterDate = null
        const beforeDate = null
        const reportFilters = null
        const sortCriteria = null
        const resLimit = 10000
        const csvData = []
        const componentState = this.state

        const payload = { reportOptions, reportName, passedName, passedValue, fields, reportType, orgCode, placementPartners, surveyName, lessonName, selectedEvent, selectedProject, selectedWork, approved, logType, assignedStaffName, assignedStaffEmail, olderThan, youngerThan, department, pathway, accountCode, afterDate, beforeDate, reportFilters, sortCriteria, resLimit, csvData, componentState }
        const returnedData = await subExportStandardCSV(payload)
        console.log('tester')
        if (!returnedData.success) {
          this.setState(returnedData.stateObject)
        } else {

          let reportTitle = "applications_for_" + this.state.selectedJob.title
          if (this.state.selectedJob.employerName) {
            reportTitle = reportTitle + " @ " + this.state.selectedJob.employerName
          }

          const returnedData2 = subExportCSV(reportTitle,returnedData.csvData)

        }

      } else {
        let csvData = []

        csvData.push(
          [ "Email","First Name","Last Name","Posting ID","Posting Title","Posting Employer","Posting Location","Picture URL","Cell Number","Alternative Email","Alternative Phone Number","Education Status", "School Name","School Degree","School Major","School Grad Year","School End Date","Zip Code","Previously Interned","Stage","Resume URL","Cover Letter URL","Linked In URL","Portfolio URL","Identification URL","Letter of Recommendation URL","Transcript URL","Date of Birth","Pathway","Address","City","State","Country","Language","Race","Races","Self Described Race","Gender","Preferred Pronoun","Veteran","Work Authorization","Number of Members in Household","Household Income","Foster Youth","Homeless","Incarcerated","Adversity List","Completed Training","Interviewed","Referred","Referral Code","Interest Scores","Trait Scores","Endorsement Count","Experience Hours","Project Hours","Volunteer Hours","Notes","Match Score","Referral Rank","Org Feedback","Is Subsidy Eligible","Referral Message to Employer","Employer Ranking","Employer Feedback","Liked by Employer","Disliked by Employer","Offer Decision by Candidate", "Departments","Pathways","Is Vaccinated","Created At", "Updated At" ]
        )

        const applications = this.state.applications
        if (applications && applications.length > 0) {

          let commaStartingValue = /,/g
          let commaReplacementValue = ";"

          for (let i = 1; i <= applications.length; i++) {

            const email = applications[i - 1].email
            const firstName = applications[i - 1].firstName
            const lastName = applications[i - 1].lastName
            const postingId = applications[i - 1].postingId

            let postingTitle = ''
            if (applications[i - 1].postingTitle) {
              postingTitle = applications[i - 1].postingTitle.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let postingEmployerName = ''
            if (applications[i - 1].postingEmployerName) {
              postingEmployerName = applications[i - 1].postingEmployerName.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let postingLocation = ''
            if (applications[i - 1].postingLocation) {
              postingLocation = applications[i - 1].postingLocation.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let pictureURL = ''
            if (applications[i - 1].pictureURL) {
              pictureURL = applications[i - 1].pictureURL
            }

            let phoneNumber = ''
            if (applications[i - 1].phoneNumber) {
              phoneNumber = applications[i - 1].phoneNumber
            }

            let alternativeEmail = ''
            if (applications[i - 1].alternativeEmail) {
              alternativeEmail = applications[i - 1].alternativeEmail
            }

            let alternativePhoneNumber = ''
            if (applications[i - 1].alternativePhoneNumber) {
              alternativePhoneNumber = applications[i - 1].alternativePhoneNumber
            }

            let educationStatus = ''
            if (applications[i - 1].educationStatus) {
              educationStatus = applications[i - 1].educationStatus
            }

            let schoolName = applications[i - 1].schoolName
            if (applications[i - 1].schoolName) {
              schoolName = applications[i - 1].schoolName.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let degree = applications[i - 1].degree
            if (applications[i - 1].degree) {
              degree = applications[i - 1].degree.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let major = ''
            if (applications[i - 1].major) {
              // console.log('mic check 2: ', applications[i - 1].email)
              major = applications[i - 1].major.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }


            let gradYear = ''
            if (applications[i - 1].gradYear) {
              gradYear = applications[i - 1].gradYear.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let endDate = ''
            if (applications[i - 1].endDate) {
              endDate = applications[i - 1].endDate.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let zipcode = ''
            if (applications[i - 1].zipcode) {
              zipcode = applications[i - 1].zipcode.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let previouslyInterned = ''
            if (applications[i - 1].previouslyInterned) {
              previouslyInterned = applications[i - 1].previouslyInterned.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let stage = ''
            if (applications[i - 1].stage) {
              stage = applications[i - 1].stage.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let resumeURL = ''
            if (applications[i - 1].resumeURL) {
              resumeURL = applications[i - 1].resumeURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let coverLetterURL = ''
            if (applications[i - 1].coverLetterURL) {
              coverLetterURL = applications[i - 1].coverLetterURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let linkedInURL = ''
            if (applications[i - 1].linkedInURL) {
              linkedInURL = applications[i - 1].linkedInURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let customWebsiteURL = ''
            if (applications[i - 1].customWebsiteURL) {
              //this is not right
              customWebsiteURL = applications[i - 1].customWebsiteURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let identificationURL = ''
            if (applications[i - 1].identificationURL) {
              identificationURL = applications[i - 1].identificationURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let letterOfRecommendationURL = ''
            if (applications[i - 1].letterOfRecommendationURL) {
              letterOfRecommendationURL = applications[i - 1].letterOfRecommendationURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let transcriptURL = ''
            if (applications[i - 1].transcriptURL) {
              transcriptURL = applications[i - 1].transcriptURL.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let dateOfBirth = ''
            if (applications[i - 1].dateOfBirth) {
              dateOfBirth = applications[i - 1].dateOfBirth.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let pathway = ''
            if (applications[i - 1].pathway) {
              pathway = applications[i - 1].pathway.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let address = ''
            if (applications[i - 1].address) {
              address = applications[i - 1].address.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let city = ''
            if (applications[i - 1].city) {
              city = applications[i - 1].city.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let state = ''
            if (applications[i - 1].state) {
              state = applications[i - 1].state.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let country = ''
            if (applications[i - 1].country) {
              country = applications[i - 1].country.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let language = ''
            if (applications[i - 1].language) {
              language = applications[i - 1].language.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let race = ''
            if (applications[i - 1].race) {
              race = applications[i - 1].race.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let races = ''
            if (applications[i - 1].races && applications[i - 1].races.length > 0) {
              races = applications[i - 1].races.toString().replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let selfDescribedRace = ''
            if (applications[i - 1].selfDescribedRace) {
              selfDescribedRace = applications[i - 1].selfDescribedRace.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let gender = ''
            if (applications[i - 1].gender) {
              gender = applications[i - 1].gender.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let preferredPronoun = ''
            if (applications[i - 1].preferredPronoun) {
              preferredPronoun = applications[i - 1].preferredPronoun.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let veteran = ''
            if (applications[i - 1].veteran) {
              veteran = applications[i - 1].veteran.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let workAuthorization = ''
            if (applications[i - 1].workAuthorization) {
              workAuthorization = applications[i - 1].workAuthorization.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let numberOfMembers = ''
            if (applications[i - 1].numberOfMembers) {
              numberOfMembers = applications[i - 1].numberOfMembers.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let householdIncome = ''
            if (applications[i - 1].householdIncome) {
              householdIncome = applications[i - 1].householdIncome.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let fosterYouth = ''
            if (applications[i - 1].fosterYouth) {
              fosterYouth = applications[i - 1].fosterYouth.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let homeless = ''
            if (applications[i - 1].homeless) {
              homeless = applications[i - 1].homeless.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let incarcerated = ''
            if (applications[i - 1].incarcerated) {
              incarcerated = applications[i - 1].incarcerated.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let adversityList = ''
            if (applications[i - 1].adversityList && applications[i - 1].adversityList.length > 0) {
              adversityList = applications[i - 1].adversityList.join(',').replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            if (applications[i - 1].isWorkforceReady) {
              console.log('show workforce: ', applications[i - 1].isWorkforceReady)
            }

            let completedWorkforceReady = 'NO'
            if (applications[i - 1].isWorkforceReady) {
              completedWorkforceReady = 'YES'
            }

            let interviewed = 'NO'
            if (applications[i - 1].interviews.length && applications[i - 1].interviews.length > 0) {
              interviewed = 'YES'
            }

            let referred = 'NO'
            if (applications[i - 1].rank) {
              referred = 'YES'
            }

            let referralCode = ''
            if (applications[i - 1].referralCode) {
              referralCode = applications[i - 1].referralCode.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let interestScoreArray = []
            let interestScores = ''
            if (applications[i - 1].interestResults) {
              if (applications[i - 1].interestResults.length !== 0) {
                for (let j = 1; j <= applications[i - 1].interestResults.length; j++) {
                  console.log(i)

                  interestScoreArray.push(applications[i - 1].interestResults[j - 1].name + "-" + applications[i - 1].interestResults[j - 1].score)
                }
                interestScores = interestScoreArray.join(commaReplacementValue)
              }
            }

            let traitScores = ''
            if (applications[i - 1].personalityResults) {
              let fiveFactors = applications[i - 1].personalityResults.fiveFactors
              traitScores = 'Openness-' + fiveFactors.opennessScore + '; Conscientiousness-' + fiveFactors.conscientiousnessScore + '; Extraversion-' + fiveFactors.extraversionScore + '; Agreeableness-' + fiveFactors.agreeablenessScore + '; Neuroticism' + fiveFactors.neuroticismScore
            }

            let endorsementCount = 0
            if (applications[i - 1].endorsements) {
              endorsementCount = applications[i - 1].endorsements.length
            }

            let experienceHours = ''
            if (applications[i - 1].experienceHours) {
              experienceHours = applications[i - 1].experienceHours
            }

            let projectHours = ''
            if (applications[i - 1].projectHours) {
              projectHours = applications[i - 1].projectHours
            }

            let volunteerHours = ''
            if (applications[i - 1].volunteerHours) {
              volunteerHours = applications[i - 1].volunteerHours
            }

            let notes = ''
            if (applications[i - 1].notes) {
              notes = applications[i - 1].notes.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let match = ''
            if (applications[i - 1].match) {
              match = applications[i - 1].match
            }

            let rank = ''
            if (applications[i - 1].rank) {
              rank = applications[i - 1].rank
            }

            let feedback = ''
            if (applications[i - 1].feedback) {
              feedback = applications[i - 1].feedback
            }

            let isSubsidyEligible = ''
            if (applications[i - 1].isSubsidyEligible) {
              isSubsidyEligible = 'YES'
            }

            let employerMessage = ''
            if (applications[i - 1].employerMessage) {
              employerMessage = applications[i - 1].employerMessage.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let employerRanking = ''
            if (applications[i - 1].employerRanking) {
              employerRanking = applications[i - 1].employerRanking
            }

            let employerFeedback = ''
            if (applications[i - 1].employerFeedback) {
              employerFeedback = applications[i - 1].employerFeedback.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
            }

            let liked = ''
            if (applications[i - 1].liked) {
              liked = 'YES'
            }

            let disliked = ''
            if (applications[i - 1].disliked) {
              disliked = 'YES'
            }

            let decision = ''
            if (applications[i - 1].decision) {
              decision = applications[i - 1].decision
            }

            let departments = ''
            if (applications[i - 1].departments) {
              for (let j = 1; j <= applications[i - 1].departments.length; j++) {
                if (applications[i - 1].departments[j - 1] && applications[i - 1].departments[j - 1].name) {
                  if (departments === '') {
                    departments = applications[i - 1].departments[j - 1].name.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                  } else {
                    departments = departments + '; ' + applications[i - 1].departments[j - 1].name.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                  }
                }
              }
            }

            let pathways = ''
            if (applications[i - 1].pathways) {
              for (let j = 1; j <= applications[i - 1].pathways.length; j++) {
                if (applications[i - 1].pathways[j - 1] && applications[i - 1].pathways[j - 1].title) {
                  if (pathways === '') {
                    pathways = applications[i - 1].pathways[j - 1].title.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                  } else {
                    pathways = pathways + '; ' + applications[i - 1].pathways[j - 1].title.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                  }
                }
              }
            }

            let isVaccinated = ''
            if (applications[i - 1].isVaccinated) {
              isVaccinated = applications[i - 1].isVaccinated
            }

            const createdAt = applications[i - 1].createdAt
            const updatedAt = applications[i - 1].updatedAt

            csvData.push([
              email, firstName, lastName, postingId, postingTitle, postingEmployerName, postingLocation, pictureURL,
              phoneNumber, alternativeEmail, alternativePhoneNumber, educationStatus,
              schoolName, degree, major, gradYear, endDate,
              zipcode, previouslyInterned, stage, resumeURL, coverLetterURL, linkedInURL, customWebsiteURL,
              identificationURL, letterOfRecommendationURL, transcriptURL,
              dateOfBirth, pathway, address, city, state, country, language,
              race, races, selfDescribedRace, gender, preferredPronoun,
              veteran, workAuthorization, numberOfMembers, householdIncome,
              fosterYouth, homeless, incarcerated, adversityList,
              completedWorkforceReady,interviewed,referred, referralCode,
              interestScores, traitScores, endorsementCount,
              experienceHours, projectHours, volunteerHours, notes, match, rank,
              feedback,isSubsidyEligible, employerMessage, employerRanking, employerFeedback, liked, disliked, decision,
              departments, pathways, isVaccinated,
              createdAt, updatedAt
            ])

            if (applications[i - 1].customAssessmentResults && applications[i - 1].customAssessmentResults.length > 0) {
              console.log('Z0')
              for (let j = 1; j <= applications[i - 1].customAssessmentResults.length; j++) {
                if (applications[i - 1].customAssessmentResults[j - 1]) {
                  if (typeof applications[i - 1].customAssessmentResults[j - 1] === 'string') {
                    console.log('Z1')

                    const result = applications[i - 1].customAssessmentResults[j - 1].replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    csvData[csvData.length - 1].push(result)

                    let questionName = 'Custom Question ' + j
                    let showNewCustomResponsesHack = true
                    if (showNewCustomResponsesHack && applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1]) {
                      if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                        questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                      }
                    }

                    if (!csvData[0].includes(questionName)) {
                      csvData[0].push(questionName)
                    }
                  } else {
                    console.log('Z2')
                    const result = applications[i - 1].customAssessmentResults[j - 1].join().replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    csvData[csvData.length - 1].push(result)
                    if (result === undefined || result === null) {
                      console.log('result is undefined or null 1')
                    }

                    const questionName = 'Question ' + j
                    if (i === 1 && !csvData[0].includes(questionName)) {
                      csvData[0].push(questionName)
                    }
                  }
                } else {
                  console.log('Z3')

                  csvData[csvData.length - 1].push('N/A')

                  let questionName = 'Custom Question ' + j
                  let showNewCustomResponsesHack = true
                  if (showNewCustomResponsesHack) {
                    if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                      questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    }
                  }

                  if (!csvData[0].includes(questionName)) {
                    csvData[0].push(questionName)
                  }

                }
              }
            } else if (applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults.length > 0) {

              for (let j = 1; j <= applications[i - 1].newCustomAssessmentResults.length; j++) {
                if (applications[i - 1].newCustomAssessmentResults[j - 1]) {
                  if (typeof applications[i - 1].newCustomAssessmentResults[j - 1].answer === 'string') {
                    console.log('Z1')

                    const result = applications[i - 1].newCustomAssessmentResults[j - 1].answer.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    csvData[csvData.length - 1].push(result)

                    let questionName = 'Custom Question ' + j
                    let showNewCustomResponsesHack = true
                    if (showNewCustomResponsesHack && applications[i - 1].newCustomAssessmentResults && applications[i - 1].newCustomAssessmentResults[j - 1]) {
                      if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                        questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                      }
                    }

                    if (!csvData[0].includes(questionName)) {
                      csvData[0].push(questionName)
                    }
                  } else {
                    console.log('Z2')
                    const result = applications[i - 1].newCustomAssessmentResults[j - 1].answer.join().replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    csvData[csvData.length - 1].push(result)
                    if (result === undefined || result === null) {
                      console.log('result is undefined or null 1')
                    }

                    const questionName = 'Question ' + j
                    if (i === 1 && !csvData[0].includes(questionName)) {
                      csvData[0].push(questionName)
                    }
                  }
                } else {
                  console.log('Z3')

                  csvData[csvData.length - 1].push('N/A')

                  let questionName = 'Custom Question ' + j
                  let showNewCustomResponsesHack = true
                  if (showNewCustomResponsesHack) {
                    if (applications[i - 1].newCustomAssessmentResults[j - 1] && applications[i - 1].newCustomAssessmentResults[j - 1].question) {
                      questionName = 'Q-' + applications[i - 1].newCustomAssessmentResults[j - 1].question.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                    }
                  }

                  if (!csvData[0].includes(questionName)) {
                    csvData[0].push(questionName)
                  }

                }
              }
            }

            // 3rd party scores - issues with looping
            let showScores = false
            if (showScores) {
              if (applications[i - 1].scores && applications[i - 1].scores.length > 0) {
                for (let j = 1; j <= applications[i - 1].scores.length; j++) {
                  if (applications[i - 1].scores[j - 1] && applications[i - 1].scores[j - 1].scorer) {

                    // tally by suppScores
                    if (applications[i - 1].scores[j - 1].suppScores) {
                      for (let k = 1; k <= applications[i - 1].scores[j - 1].suppScores.length; k++) {
                        // scores[0].scorer.email firstName, lastName, jobTitle, employerName
                        // scores[0].suppScores[0].category score

                        const result = applications[i - 1].scores[j - 1].suppScores[k - 1].score
                        csvData[csvData.length - 1].push(result)

                        const category = applications[i - 1].scores[j - 1].suppScores[k - 1].category.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                        const scorerFirstName = applications[i - 1].scores[j - 1].scorer.firstName.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                        const scorerLastName = applications[i - 1].scores[j - 1].scorer.lastName.replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"").replace(/"/g,"")
                        const categoryName = category + 'Score by ' + scorerFirstName + ' ' + scorerLastName

                        if (!csvData[0].includes(categoryName)) {
                          csvData[0].push(categoryName)
                        }
                      }
                    }

                  } else {
                    console.log('log nothing 4')

                  }
                }
              }
            }
          }

        } else {
          this.setState({ clientErrorMessage: 'There is no data to export', isLoading: false})
        }

        // let headerArray = ["Date"]
        // if (this.state.metricDefinitions && this.state.metricDefinitions.length > 0) {
        //   for (let i = 1; i <= this.state.metricDefinitions.length; i++) {
        //     headerArray.push(this.state.metricDefinitions[i - 1].title)
        //   }
        // }
        //
        // csvData.push(headerArray)
        //
        // let commaStartingValue = /,/g
        // let commaReplacementValue = ";"
        //
        // if (this.state.metrics && this.state.metrics.length > 0) {
        //   for (let i = 1; i <= this.state.metrics.length; i++) {
        //     const item = this.state.metrics[i - 1]
        //
        //     if (item) {
        //       const mDate = convertDateToString(new Date(item.createdAt).getMonth(),'formatMonth') + (new Date(item.createdAt).getFullYear())
        //       let dataArray = [mDate]
        //
        //       for (let j = 1; j <= this.state.metricDefinitions.length; j++) {
        //         if (this.state.metricDefinitions[j - 1].shortname && item[this.state.metricDefinitions[j - 1].shortname]) {
        //           dataArray.push(item[this.state.metricDefinitions[j - 1].shortname])
        //         } else {
        //           dataArray.push('0')
        //         }
        //       }
        //
        //       csvData.push(dataArray)
        //     } else {
        //       console.log('====', item)
        //     }
        //   }
        // }
        //
        // if (this.state.messages && this.state.messages.length > 0) {
        //   for (let i = 1; i <= this.state.messages.length; i++) {
        //     const item = this.state.messages[i - 1]
        //
        //     const firstName = selectedMember.firstName
        //     const lastName = selectedMember.lastName
        //     let email = selectedMember.email
        //
        //     let course = 'N/A'
        //     if (item.courseName) {
        //       course = item.courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
        //     }
        //
        //     const reportType = 'A.I. Conversation'
        //
        //     let title = ''
        //     let assignment = ''
        //     let grade = ''
        //     let gradeExplanation = ''
        //
        //     let direction = 'Received'
        //     if (item.senderEmail === email) {
        //       direction = 'Sent'
        //     }
        //
        //     let message = ''
        //     if (item.message) {
        //       message = item.message.replace(commaStartingValue,commaReplacementValue).replace(/\r\n/g," ").replace(/\n/g," ").replace(/#/g,"")
        //     }
        //
        //     const createdAt = item.createdAt
        //     const updatedAt = item.updatedAt
        //
        //     csvData.push([
        //       firstName, lastName, email, course, reportType, title, assignment, grade, gradeExplanation,
        //       direction, message, createdAt, updatedAt
        //     ])
        //   }
        // }

        let reportTitle = "applications_for_" + this.state.selectedJob.title
        if (this.state.selectedJob.employerName) {
          reportTitle = reportTitle + " @ " + this.state.selectedJob.employerName
        }

        const returnedData = subExportCSV(reportTitle,csvData)

      }
    }

    render() {
      console.log('show filteredApplications count: ', this.state.filteredApplications.length)

      return (
          <div className="padding-five-percent-left padding-five-percent-right">
            <div className="card top-margin-2-percent center-horizontally max-width-1400">
              <div className="calc-column-offset-120">
                <p className="heading-text-2">{(this.state.filteredApplications) ? this.state.filteredApplications.length : "0"} Applications{(this.state.selectedJob) ? (this.state.selectedJob.employerName && this.state.selectedJob.employerName !== 'No Employer Selected') ? " for " + this.state.selectedJob.title + " @ " + this.state.selectedJob.employerName : " for " + this.state.selectedJob.title : ""}</p>
              </div>
              <div className="fixed-column-40">
                <button className="background-button float-right" onClick={() => this.setState({ showSearchBar: !this.state.showSearchBar })}>
                  <img src={searchIcon} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Search and/or filter applications"} />
                </button>
                <div className="clear" />
              </div>
              {(this.state.selectedJob && this.state.selectedJob.postType !== 'Program') && (
                <div className="fixed-column-40">
                  {(this.state.selectedJob && (window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor'))) && (
                    <Link className="background-button float-right" to={this.state.pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates'} state={{ selectedJob: this.state.selectedJob, applications: this.state.applications, selectedBenchmark: this.state.benchmark }}>
                      <img src={referIconDark} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Configure referrals to employers"} />
                    </Link>
                  )}
                  <div className="clear" />
                </div>
              )}
              <div className="fixed-column-40">
                <button className="background-button float-right" onClick={() => this.exportInfo()}>
                  <img src={downloadIconBlue} alt="GC" className="image-23-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={"Export applications to CSV"} />
                </button>
                <div className="clear" />
              </div>
              <div className="clear" />
            </div>

            {(this.state.showSearchBar) && (
              <div className="card top-margin-2-percent center-horizontally max-width-1400">
                <div className="standard-border full-width row-5 bottom-margin">
                  <div className="fixed-column-35 padding-3 top-margin-5">
                    <img src={searchIcon} alt="GC" className="image-20-fit left-margin"/>
                  </div>
                  <div className="calc-column-offset-35">
                    <input type="text" className="text-field clear-border" placeholder="Search by name..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>


                <div className="row-10">
                  <hr className="clear-margin clear-padding" />
                </div>

                <div className="float-left right-padding bottom-margin">
                  <div className="bottom-margin">
                    <label className="description-text-3 description-text-color row-5">FILTER BY</label>
                  </div>

                  {(this.renderManipulators('filter'))}
                  <div className="clear"/>

                </div>

                <div className="clear"/>
                <hr className="clear-margin clear-padding"/>
                <div className="clear"/>
              </div>
            )}

            <div className="card-clear-padding top-margin-2-percent center-horizontally max-width-1400">
              {/*
              <div className="spacer" /><div className="spacer" />
              <div>
                {(this.state.selectedJob) ? (
                  <div>
                    {(this.state.applications.length > 0) ? (
                      <div>
                        <p className="heading-text-2">{this.state.filteredApplications.length} Applications for {this.state.selectedJob.title}{(this.state.selectedJob.employerName) && " @ " + this.state.selectedJob.employerName}</p>
                      </div>
                    ) : (
                      <div>
                        <p className="heading-text-2">Applications for {this.state.selectedJob.title}</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className="heading-text-2">Job Applications</p>
                )}
              </div>
              <div className="half-spacer" />*/}

              { (this.state.applications.length !== 0) ? (
                <div>
                  {/*
                    <hr className="clear-margin clear-padding"/>
                    <div className="filter-field-search-2">
                          <div className="search-icon-container">
                            <img src={searchIcon} alt="Compass search icon"/>
                          </div>
                          <div className="filter-search-container-2">
                            <input type="text" className="text-field clear-border" placeholder="Search by name..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                          </div>
                    </div>
                    <div className="advisor-clearing-container" />

                    <div className="clear"/>

                    <div className="spacer"/>
                    <hr className="clear-margin clear-padding" />
                    <div className="spacer"/>

                    <div className="float-left right-padding bottom-margin">
                      <div className="bottom-margin">
                        <label className="description-text-3 description-text-color row-5">FILTER BY</label>
                      </div>

                      {(this.renderManipulators('filter'))}
                      <div className="clear"/>

                    </div>

                    <div className="clear"/>
                    <hr className="clear-margin clear-padding"/>
                    <div className="clear"/>*/}

                    {this.renderApplications()}

                    {/*
                    {(!window.location.pathname.includes('/employers')) && (
                      <div>
                        {(this.state.selectedJob.postType !== 'Track' || this.state.selectedJob.subPostType !== 'Track') && (
                          <Link className="btn btn-tertiary top-margin-20" to={pathPrefix + '/postings/' + this.state.selectedJob._id + '/top-candidates'} state={{ selectedJob: this.state.selectedJob, applications: this.state.applications, selectedBenchmark: this.state.benchmark }}>
                            View and Edit Top Candidates
                          </Link>
                        )}
                      </div>
                    )}*/}
                </div>
              ) : (
                <div className="padding-30">
                    <p>There are no applications yet</p>
                </div>
              )}
              <Modal
               isOpen={this.state.modalIsOpen}
               onAfterOpen={this.afterOpenModal}
               onRequestClose={this.closeModal}
               className="modal"
               overlayClassName="modal-overlay"
               contentLabel="Example Modal"
             >
              {(this.state.showDeleteApplications) && (
                <div key="confirm-deletion" className="full-width padding-40">
                  {(this.state.hasRemoved) ? (
                    <div className="center-text">
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <img src={checkmarkIcon} alt="Compass checkmark icon" className="image-auto-70 center-horizontally"/>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <p className="heading-text-2 cta-color">Selected Applications Have Been Successfully Deleted!</p>
                      <div className="spacer" /><div className="spacer" />
                      <p>Please message help@guidedcompass.com immediately if this was done by mistake.</p>
                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                      <button onClick={() => this.setState({ modalIsOpen: false })} className="btn btn-secondary left-margin">Close</button>
                      <div className="spacer" /><div className="spacer" />
                    </div>
                  ) : (
                    <div>
                      <p className="heading-text-2">Are you sure?</p>
                      <div className="spacer" />
                      <p>Deleting the selected applications will remove the data from our database for those applications. It will be very difficult (though not impossible) to retrieve the data after you have deleted.</p>

                      <div className="super-spacer" />

                      <button className="btn btn-primary error-background-color error-border-color" onClick={() => this.deleteApps(null)}>Delete Apps</button>
                      <button className="btn btn-secondary" onClick={() => this.setState({ modalIsOpen: false })}>Cancel</button>
                    </div>
                  )}
                </div>
              )}
              {(this.state.showAssignScorers) && (
                <div key="assign-scorers" className="full-width padding-40">
                  <div className="row-10">
                    <div className="calc-column-offset-20 right-padding">
                      <p className="heading-text-2">Specify the Scorer to Assign</p>
                    </div>
                    <div className="fixed-column-20 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="edit-profile-row">
                    <label className="profile-label">Email<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="Email" name="scorerEmail" value={this.state.scorerEmail} onChange={this.formChangeHandler} />
                  </div>
                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="profile-label">First Name</label>
                      <input className="text-field" type="text" placeholder="First Name" name="scorerFirstName" value={this.state.scorerFirstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Last Name</label>
                      <input className="text-field" type="text" placeholder="Last Name" name="scorerLastName" value={this.state.scorerLastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="profile-label">Job Title</label>
                      <input className="text-field" type="text" placeholder="Job Title" name="scorerJobTitle" value={this.state.scorerJobTitle} onChange={this.formChangeHandler} />
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Employer Name</label>
                      <input className="text-field" type="text" placeholder="Employer Name" name="scorerEmployerName" value={this.state.scorerEmployerName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="row-10">
                    <div className="float-left">
                      <button className="btn btn-squarish cta-background-color cta-border-color" onClick={() => this.addItem('scorer')}>Add Scorer</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                  <div className="row-20">
                    <hr />
                  </div>

                  {(this.state.scorers && this.state.scorers.length > 0) && (
                    <div>
                      {this.state.scorers.map((value, optionIndex) =>
                        <div key={optionIndex} className="float-left">
                          <div className="close-button-container-1" >
                            <button className="background-button" onClick={() => this.removeTag('scorer', optionIndex)}>
                              <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="float-left right-padding-5">
                            <div className="half-spacer" />
                            <div className="tag-container-inactive">
                              {(value.firstName) ? (
                                <p className="description-text-2">{value.email} - {value.firstName} {value.lastName}</p>
                              ) : (
                                <p className="description-text-2">{value.email}</p>
                              )}
                            </div>
                            <div className="half-spacer" />
                          </div>
                        </div>
                      )}
                      <div className="clear" />
                    </div>
                  )}

                  <div className="row-10">
                    <div className="float-left">
                      <button className="btn btn-primary cta-background-color cta-border-color" onClick={() => this.assign('scorer')}>Save</button>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              )}
              {(this.state.showAssignStaff) && (
                <div key="assign-staff" className="full-width padding-40">
                  <div className="row-10">
                    <div className="calc-column-offset-20 right-padding">
                      <p className="heading-text-2">Specify the Staff to Assign</p>
                    </div>
                    <div className="fixed-column-20 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Staff Member</label>
                      <select name="staffMember" value={this.state.staffMember.firstName + ' ' + this.state.staffMember.lastName} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.staffMemberOptions.map(value =>
                          <option key={value.email} value={value.firstName + ' ' + value.lastName}>{value.firstName} {value.lastName}</option>
                        )}
                      </select>
                    </div>
                    <div className="container-right">
                      {(!this.state.onMobile) && (
                        <div>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <div className="mini-spacer" /><div className="mini-spacer" />
                        </div>
                      )}

                      <button className="btn btn-squarish cta-background-color cta-border-color" onClick={() => this.addItem('staff')}>Add Staff Member</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                  {(this.state.assignedStaff && this.state.assignedStaff.email) && (
                    <div>
                      <div key={"staff"} className="float-left">
                        <div className="close-button-container-1" >
                          <button className="background-button" onClick={() => this.removeTag('staff', null)}>
                            <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                          </button>
                        </div>
                        <div className="float-left right-padding-5">
                          <div className="half-spacer" />
                          <div className="tag-container-inactive">
                            <p className="description-text-2">{this.state.assignedStaff.firstName} {this.state.assignedStaff.lastName}</p>
                          </div>
                          <div className="half-spacer" />
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  )}

                  <div className="row-10">
                    <button className="btn btn-primary cta-background-color cta-border-color" onClick={() => this.assign('staff')}>Assign to Staff</button>
                  </div>
                </div>
              )}

              {(this.state.showBulkChangeStage) && (
                <div key="assign-staff" className="full-width padding-40">
                  <div className="row-10">
                    <div className="calc-column-offset-20 right-padding">
                      <p className="heading-text-2">Change the Stage for Selected Applicants</p>
                    </div>
                    <div className="fixed-column-20 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Select a Stage</label>
                      <select name="selectedStage" value={this.state.selectedStage} onChange={this.formChangeHandler} className="dropdown">
                        {[''].concat(this.state.stageOptions).map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="container-right">
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                  <div className="row-10 top-margin">
                    <button className="btn btn-primary cta-background-color cta-border-color" onClick={() => this.assign('stage')}>Apply Changes</button>
                  </div>
                </div>
              )}

              {(this.state.showDuplicateApplication) && (
                <div key="assign-staff" className="full-width padding-40">
                  <div className="row-10">
                    <p className="heading-text-2">Duplicate Applications to Other Postings</p>
                    <p className="row-10 description-text-2">Duplicating {this.state.checkCount} Applications to {this.state.selectedJob.title}</p>
                  </div>

                  <div className="row-10">
                    <div className="calc-column-offset-140">
                      <label className="profile-label">Select a Posting</label>
                      <select name="posting" value={(this.state.selectedPosting && this.state.selectedPosting.title !== '') && this.state.selectedPosting.title + ' | ' + this.state.selectedPosting.employerName} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.postingOptions.map(value =>
                          <option key={value._id} value={(value && value.title !== '') && value.title + ' | ' + value.employerName}>{(value && value.title !== '') && value.title + ' | ' + value.employerName}</option>
                        )}
                      </select>
                    </div>
                    <div className="fixed-column-140 left-padding">
                      <div className="mini-spacer" /><div className="mini-spacer" />
                      {(!this.state.onMobile) && (
                        <div>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="spacer" />
                        </div>
                      )}

                      <button className="btn btn-squarish cta-background-color cta-border-color" onClick={() => this.addItem('posting')}>Add Posting</button>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                  {(this.state.selectedPostings && this.state.selectedPostings.length > 0) && (
                    <div>
                      {this.state.selectedPostings.map((value, optionIndex) =>
                        <div key={"selectedPostings"} className="float-left">
                          <div className="close-button-container-1" >
                            <button className="background-button" onClick={() => this.removeTag('posting', null)}>
                              <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                            </button>
                          </div>
                          <div className="float-left right-padding-5">
                            <div className="half-spacer" />
                            <div className="tag-container-inactive">
                              <p className="description-text-2">{value.title} | {value.employerName}</p>
                            </div>
                            <div className="half-spacer" />
                          </div>
                        </div>
                      )}

                      <div className="clear" />
                    </div>
                  )}

                  <div className="row-10">
                    <button className="btn btn-primary cta-background-color cta-border-color" onClick={() => this.assign('application')}>Assign Applications</button>
                  </div>
                </div>
              )}

              {(this.state.showChangeStage) && (
                <div key="showChangeStage" className="full-width padding-40">
                  <div className="row-10">
                    <div className="calc-column-offset-40">
                      <p className="heading-text-2">Change Status / Stage</p>
                    </div>
                    <div className="fixed-column-40 top-margin">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-20" />
                      </button>
                    </div>
                    <div className="clear" />

                    <p className="row-10 description-text-2">Change the status / stage of {(this.state.selectedApplication) && this.state.selectedApplication.firstName + " " + this.state.selectedApplication.lastName}.</p>
                  </div>

                  <div className="row-10">
                    <label className="profile-label">Application Stage</label>
                    {(this.state.stageOptions) && (
                      <select name={"applications|" + this.state.selectedIndex + "|stage"} value={(this.state.selectedApplication) ? this.state.selectedApplication.stage : ""} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.stageOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    )}
                  </div>

                  <div className="spacer" /><div className="spacer" />

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 row-5 error-color">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 row-5 cta-color">{this.state.successMessage}</p>}

                  <div className="row-10">
                    <button className="btn btn-primary cta-background-color cta-border-color" onClick={() => this.saveApplication(this.state.selectedApplication, null, null, true)}>Save Changes</button>
                  </div>
                </div>
              )}

             </Modal>
            </div>
          </div>

      )
    }
}

export default withRouter(AdminApplications);
