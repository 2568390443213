import React, {Component} from 'react';
import Axios from 'axios';

// const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";

class JoinNewsletter extends Component {
    constructor(props) {
      super(props)
      this.state = {
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.subscribeToNewsletter = this.subscribeToNewsletter.bind(this)

    }

    componentDidMount() {
      console.log('componentDidMount called')

      const emailId = localStorage.getItem('email')
      this.setState({ emailId })

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubJoinNewsletter', this.props, prevProps)
    }
    formChangeHandler = event => {

      this.setState({ [event.target.name]: event.target.value })

    }

    subscribeToNewsletter() {
      console.log('subscribeToNewsletter called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.emailId || this.state.emailId === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your email address' })
      } else if (!this.state.emailId.includes('@') || this.state.emailId.length < 3) {
        this.setState({ isSaving: false, errorMessage: 'Please add a valid email address' })
      } else {

        const emailId = this.state.emailId

        Axios.post('/api/newsletter/subscribe', { emailId })
        .then((response) => {
          console.log('Newsletter subscribe worked', response.data);

          if (response.data.success) {

            this.setState({ isSaving: false, emailId: '', successMessage: response.data.message })

          } else {
            console.log('there was an error subscribing to the newsletter', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }

        }).catch((error) => {
            console.log('Newsletter subscribe did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    render() {

      return (
        <div>
          <div className="row">
            <h2>Join Our Monthly Newsletter</h2>
            <div className="spacer" /><div className="spacer" />
          </div>

          <div className="row">
            <p className="full-width center-text heading-text-4">Get monthly news, updates, and shoutouts. If you have an account, get resources relevant to your goals.</p>
          </div>

          <div className="row center-text flex-container row-direction flex-center top-margin-50">
            <input className={(window.innerWidth < 768) ? "text-field height-50 right-margin" : "text-field height-50 min-width-200 max-width-350 right-margin"} type="text" placeholder="jonschmoe@gmail.com" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler} onKeyPress={(e) => (e.key === 'Enter') ? this.subscribeToNewsletter() : console.log('key clicked')}/>
            <button className="btn btn-primary left-margin" disabled={(this.state.isSaving) ? true : false} onClick={() => this.subscribeToNewsletter()}>Subscribe</button>
            <div className="clear" />
          </div>

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5 top-margin center-text">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5 top-margin center-text">{this.state.successMessage}</p>}
        </div>
      )
    }
}

export default JoinNewsletter
