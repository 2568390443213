import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import RequestMaterials from './RequestMaterials';

// const clientLogo2 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_2.png";
const clientLogo7 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_7.png";
// const clientLogo10 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_10.jpg";
const clientLogo12 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_12.png";
const clientLogo1 = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/client_logo_1.png";
const courseIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png";
const wfBackgroundImage = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/workforce_background_image.png"

class EmployerBenefits extends Component {
    constructor(props) {
      super(props)
      this.state = {
        caseStudies: [],
        panelSelected: 1
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
      console.log('componentDidMount called')

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubEmployerBenefits', this.props, prevProps)

      if (this.props.orgSelected !== prevProps.orgSelected) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubEmployerBenefits')

      const orgSelected = this.props.orgSelected

      let caseStudies = [
        { type: 'Case Study', image: clientLogo12, title: "Helping UNITE-LA Facilitate a Co-Op Model with Local Schools", url: "https://docs.google.com/document/d/1YYGLTBjvsMhDbakDUX-VJWmMO-mj18Zc1ZpHC0g0AEw/edit", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
        // { type: 'Case Study', image: clientLogo2, title: "2-Year Partnership with Bixel Exchange LA Tech Talent Pipeline", url: "https://docs.google.com/document/d/1zAV4ra-ONZQBjjV-TSwMt59K_kCZol2vIFy1ORgWN7U/edit", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
        // { type: 'Case Study', image: clientLogo10, title: "Preparing Low-Income College Students for Internships on Capitol Hill", url: "https://docs.google.com/document/d/1k9OK7lm-VftRXNa9R9d9lWHVzlsnAmyAotru-kdysic/edit?usp=sharing", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
        { type: 'Case Study', image: clientLogo7, title: "Helping Grover Cleveland High Explore Career Paths", url: "https://docs.google.com/document/d/1KsexiK3JQXGMMu3hO_17wmwfVswVppFK-_zjVBndvwI/edit#", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
        { type: 'Case Study', image: clientLogo1, title: "Helping Narbonne High School Create a Mentorship Program", url: "https://docs.google.com/document/d/1OsNBMETAVglGMuBQntsIWqnFMQaL6Vbfhc8oCwPRmeU/edit?usp=sharing", description: "This case study examines our 2-year pilot with the Bixel Exchange LA Tech Talent Pipeline program. This was our second workforce development customer and the customer where we introduced many of the innovative features that make Guided Compass what it is today."},
      ]

      const panel1Points = [
        { title: 'SMART Goal Setting & Career Plan Builder', subtitle: 'Have students set S.M.A.R.T. goals and get feedback/resources to achieve them. Students can also import their profile into a career plan builder.'},
        { title: 'Financial Planning Tool', subtitle: "Have students use the financial planning tool to analyze how their income, expenses, and net worth change based on different career paths and assumptions."},
        { title: 'Career Exploration', subtitle: 'Assign students career exploration lessons, where they analuze labor market data and explore 1,100+ career pathways.'},
        { title: 'Advise with Full Profile', subtitle: "When advising students, view students' career assessments, projects, experience, resume reviews, mock interviews, career matches, favorites, competency endorsements, and more in one place!"}
      ]

      const panel2Points = [
        { title: 'Source Project Ideas From Employers and Alumni', subtitle: "Employers and alumni can post project ideas directly for your program to adopt. Just share the link in your organizaton's educator portal, then accept what they post."},
        { title: 'Adopt Projects Ideas From Curriculum Exchange', subtitle: 'The curriculum exchange allows you to post lesson plans, resources, and project ideas with fellow career educators. Adopt whenever you like!'},
        { title: 'Assign Projects Directly to Students', subtitle: 'Adopt project ideas from others or post them yourself. Then assign them to students. Students can submit project solutions in the portal, and you can grade them!'},
        { title: 'Get Feedback from Employers and Alumni', subtitle: 'Not only can educators grade submitted projects, employers/alumni can also provide grades + feedback. All feedback can be exported or integrated into your LMS.'}
      ]

      const panel3Points = [
        { title: 'Career Events', subtitle: 'Post events (e.g., job shadows, workshops) for students to RSVP to and comment on. Educators can easily check off which students are in attendance. Students can use their profiles at the events.'},
        { title: 'Mentorship', subtitle: 'Match students to mentors. Students / mentors can log career exploration sessions, resume reviews, mock interviews, and project feedback.'},
        { title: 'Projects', subtitle: 'Students can submit projects for project-based learning as well as be paid for working on projects as part of internships. They would submit each directly in the portal.'},
        { title: 'Work', subtitle: 'Students can import their culumulative educational / work profile directly to work applications. Use timesheets and assignments during the internships and freelance work.'}
      ]

      const panel4Points = [
        { title: 'Lesson Plans', subtitle: 'Contribute to and adopt lesson plans that you can assign to your students in the portal. Lesson plans are tagged by grade level and career pathway so that you can easily search and filter for the best ones for your class.'},
        { title: 'Resources', subtitle: "Contribute to and adopt videos that you can assign to your students in the portal. The duration is tagged, so this is great for independent 'self-study'"},
        { title: 'Project Ideas', subtitle: "Contribute to and adopt project ideas that you can assign to your students in the portal. Often project ideas are difficult to satisfy diverse students, so this would be a great resource, particularly when potential project solutions are included."},
      ]

      this.setState({ caseStudies, panel1Points, panel2Points, panel3Points, panel4Points, orgSelected })

    }

    closeModal() {
      console.log('closeModal called')
      this.setState({ modalIsOpen: false })
    }

    render() {

      let panel1ClassName = "white-background standard-border padding-20 center-text full-width"
      let panel2ClassName = "white-background standard-border padding-20 center-text full-width"
      let panel3ClassName = "white-background standard-border padding-20 center-text full-width"
      // let panel4ClassName = "white-background standard-border padding-20 center-text full-width"

      if (window.innerWidth > 768) {
        if (this.state.panelSelected === 1) {
          panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
        }
        // if (this.state.panelSelected === 4) {
        //   panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
        // }
      } else {
        // redefine for mobile

        panel1ClassName = "white-background standard-border padding-20 center-text full-width"
        panel2ClassName = "white-background standard-border padding-20 center-text full-width"
        panel3ClassName = "white-background standard-border padding-20 center-text full-width"
        // panel4ClassName = "white-background standard-border padding-20 center-text full-width"
        if (this.state.panelSelected === 1) {
          panel1ClassName = "primary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 2) {
          panel2ClassName = "secondary-background standard-border padding-20 center-text full-width"
        }
        if (this.state.panelSelected === 3) {
          panel3ClassName = "senary-background standard-border padding-20 center-text full-width"
        }
        // if (this.state.panelSelected === 4) {
        //   panel4ClassName = "septary-background standard-border padding-20 center-text full-width"
        // }
      }

      // console.log('panel1 points: ', this.state.panel1Points)

      return (
        <div>
          <section className="section-features white-background horizontal-padding-3">
              <div className="row">
                <h2>How Guided Compass Helps</h2>
              </div>
              <div>
                {(window.innerWidth > 768) && (
                  <div className="relative-column-40 right-padding top-padding">
                    <img src={wfBackgroundImage} alt="GC" className="image-full-auto" />
                  </div>
                )}

                <div className={(window.innerWidth > 768) ? "relative-column-60" : "full-width"}>
                  <div className="row-10">
                    <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 1 })}>
                        <div className={panel1ClassName}>
                          <p className="heading-text-2">1</p>
                          <p className="heading-text-4">Career & Life Planning</p>
                        </div>
                      </button>
                    </div>

                    <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 2 })}>
                        <div className={panel2ClassName}>
                          <p className="heading-text-2">2</p>
                          <p className="heading-text-4">Project-Based Learning</p>
                        </div>
                      </button>
                    </div>

                    <div className={(window.innerWidth > 768) ? "relative-column-33" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 3 })}>
                        <div className={panel3ClassName}>
                          <p className="heading-text-2">3</p>
                          <p className="heading-text-4">Work-Based Learning</p>
                        </div>
                      </button>
                    </div>
                    {/*
                    <div className={(window.innerWidth > 768) ? "relative-column-25" : "full-width"}>
                      <button className={(window.innerWidth > 768) ? "background-button full-width" : "background-button full-width"} onClick={() => this.setState({ panelSelected: 4 })}>
                        <div className={panel4ClassName}>
                          <p className="heading-text-2">4</p>
                          <p className="heading-text-4">Curriculum Exchange</p>
                        </div>
                      </button>
                    </div>*/}
                    <div className="clear" />
                  </div>

                  {(this.state.panelSelected === 1 && this.state.panel1Points) && (
                    <div className="bottom-padding">
                      {this.state.panel1Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={courseIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 2 && this.state.panel2Points) && (
                    <div className="bottom-padding">
                      {this.state.panel2Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={courseIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 3 && this.state.panel3Points) && (
                    <div className="bottom-padding">
                      {this.state.panel3Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={courseIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.panelSelected === 4 && this.state.panel4Points) && (
                    <div className="bottom-padding">
                      {this.state.panel4Points.map((value, index) =>
                        <div key={value.title} className="top-margin-20">
                          <div className="fixed-column-60 top-margin-5">
                            <img src={courseIconDark} alt="GC" className="image-auto-40" />
                          </div>
                          <div className="calc-column-offset-60">
                            <p className="heading-text-3">{value.title}</p>
                            <p className="top-padding-5">{value.subtitle}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>
          </section>

          <section className="section-workforce">
            <div className="container-left">
              <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>Not sure where to start?</p>
            </div>
            <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
              <Link to={"/help"} state={{ selectedFilters: ["Employer"] }} className="btn white-background heading-text-5">View Help Articles</Link>
            </div>
            <div className="clear" />
          </section>

          <section className="section-features">
            <div className="row">
              <h2>Customer Success Case Studies</h2>
              {this.state.caseStudies.map((value, index) =>
                <div key={value} className="relative-column-33 horizontal-padding top-padding-20">
                  <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, requestMaterials: true, material: value })}>
                    <div className="card full-width">
                      <div className="row-10">
                        <img src={value.image ? value.image : ""} alt="GC" className="image-full-auto standard-border" />
                      </div>
                      <div className="row-10">
                        <p className="heading-text-6">{value.title}</p>
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </section>


          <section className="section-workforce">
            <div className="container-left">
              <p className={(window.innerWidth < 768) ? "heading-text-2 full-width center-text" : "heading-text-2 full-width right-text"}>K12 Teachers Can Sign On Using Clever!</p>
            </div>
            <div className={(window.innerWidth < 768) ? "container-right center-text top-margin" : "container-right"}>
              <a href="https://clever.com/library/app/guided-compass" target="_blank" rel="noopener noreferrer" className="btn white-background stanard-border heading-text-5">Install on Clever</a>
            </div>
            <div className="clear" />
          </section>

          {(this.state.requestMaterials) && (
            <div>
              <RequestMaterials modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} material={this.state.material}/>
            </div>
          )}
        </div>
      )
    }
}

export default EmployerBenefits
