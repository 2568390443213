import React, {Component} from 'react';
import AppNavigation from '../AppNavigation';
import AppFooter from '../AppFooter';
import SubEditAssessment from '../Subcomponents/EditAssessment';
import withRouter from '../Functions/WithRouter';

class OrgEditAssessment extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { org, id } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');
      const { selectedTemplate, duplicate } = this.props.location.state;

      let orgLogo = localStorage.getItem('orgLogo');

      this.setState({ org, selectedTemplate, duplicate, assessmentId: id, orgLogo })
    }

    render() {

      return (
          <div>
            <AppNavigation fromOrganization={true} org={this.state.org} history={this.props.navigate} />

            <SubEditAssessment activeOrg={this.state.org} assessmentId={this.state.assessmentId} selectedTemplate={this.state.selectedTemplate} duplicate={this.state.duplicate} />

            {(this.state.org) && (
              <div>
                {AppFooter(this.props.navigate,this.state.org,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(OrgEditAssessment)
