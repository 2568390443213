import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubOrgDetails from './Subcomponents/OrgDetails';
import withRouter from './Functions/WithRouter';

class OrgDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.passOrgs = this.passOrgs.bind(this)
    }

    componentDidMount() {
      const { orgSelected } = this.props.params

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, emailId, username, orgFocus, orgLogo, orgSelected })
    }

    passOrgs(activeOrg, myOrgs) {
      console.log('passOrgs called', activeOrg, myOrgs )

      this.setState({ activeOrg, myOrgs })
    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} loadWorkspace={this.loadWorkspace} history={this.props.navigate} />
            <SubOrgDetails history={this.props.navigate} orgId={this.state.orgSelected} passOrgs={this.passOrgs} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(OrgDetails);
