import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Axios from 'axios';
import SubCareerSeekerBenefits from './Common/CareerSeekerBenefits';
import SubRenderOpportunities from '../components/Common/RenderOpportunities';
import withRouter from '../components/Functions/WithRouter';

// const benchmarksIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/benchmarks-icon-blue.png";
const arrowIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png";
const workforceReadyLogo = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/workforceReadyLogo.png";
const logoImgDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';

class PartnerStudentPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
        questions: []
      }

      this.renderQuestions = this.renderQuestions.bind(this);
      this.questionClicked = this.questionClicked.bind(this);
    }

    componentDidMount() {
      console.log('partner student page just mounted')
      document.body.style.backgroundColor = "#fff";

      window.scrollTo(0, 0)

      // help articles, faqs, opportunities

      const { partner } = this.props.params

      if (partner) {

        let activeOrg = partner
        if (activeOrg === 'detroitk12') {
          activeOrg = 'dpscd'
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        let extraSection = false
        if (partner === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
          extraSection = true
        } else if (partner === 'detroitk12') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        }

        this.setState({ backgroundImage, partner, activeOrg, extraSection })

        Axios.get('/api/org', { params: { orgCode: activeOrg } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            const partnerName = orgName
            const orgProgramName = response.data.orgInfo.orgProgramName
            const orgDescription = response.data.orgInfo.orgDescription
            const contactEmail = response.data.orgInfo.contactEmail

            let studentBenefitsText = 'Let ' + partnerName + ' Work For You!'
            if (response.data.orgInfo.studentBenefitsText) {
              studentBenefitsText = response.data.orgInfo.studentBenefitsText
            }

            if (response.data.orgInfo.backgroundImageURL) {
              backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(' + response.data.orgInfo.backgroundImageURL + ')'
            }

            let questions = [
              { question: 'What is the benefit of applying through Guided Compass?', answer: 'There are three benefits we see to applying through Guided Compass: improved internship recommendations, gaining feedback, and reusing your application.\nThe first is that we improve the matching process by taking into account your career assessments, endorsements, projects, and more. In fact, we take into account 30+ criteria to match you to the right role. We even take your own feedback into account on previous projects and experience. If you don’t like your previous work experience, adjust your fit rating to reflect this and we will avoid recommending that type of work. If you favorite an internship posting, we’ll recommend roles similar to that posting.\nThe second benefit is that Guided Compass automates and organizes feedback from ' + partnerName + ' interviews and employers. Whenever you receive feedback, you will see an automatic email notification with that feedback.\nThe last benefit is that your profile is saved so that you can reuse your application for future opportunities. Guided Compass makes it easy to edit your existing information before re-submitting. This will save you tons of time!', isSelected: false},
            ]

            if (partner === 'unite-la') {
              questions.unshift({ question: 'Why should I apply through ' + partnerName + ' rather than directly to the company?', answer: "We're not encouraging you to skip applying directly to a company. We're encouraging you to apply through both mechanisms. When you apply through Guided Compass, you're application is 'referred' for special consideration."})
            }

            const deactivated = response.data.orgInfo.deactivated
            let redirectLink = response.data.orgInfo.studentRedirectLink
            if (redirectLink && !redirectLink.startsWith('http')) {
              redirectLink = 'http://' + redirectLink
            }

            const placementPartners = response.data.orgInfo.placementPartners

            console.log('show questions: ', questions)

            const orgLogo = response.data.orgInfo.webLogoURI

            const studentAliasSingular = response.data.orgInfo.studentAliasSingular
            const studentAliasPlural = response.data.orgInfo.studentAliasPlural
            const mentorAliasSingular = response.data.orgInfo.mentorAliasSingular
            const mentorAliasPlural = response.data.orgInfo.mentorAliasPlural

            this.setState({
                questions, studentBenefitsText, backgroundImage,
                orgName, partnerName, orgProgramName, orgDescription, contactEmail, deactivated,
                redirectLink, orgLogo,
                studentAliasSingular, studentAliasPlural, mentorAliasSingular, mentorAliasPlural
            });

            Axios.get('/api/postings/user', { params: { orgCode: activeOrg, placementPartners,
              recent: true, active: true,
              pullPartnerPosts: true, csWorkflow: true, resLimit: 3
            }})
            .then((response) => {
              console.log('Posted postings query attempted within subcomponent', response.data);

              if (response.data.success) {
                console.log('posted postings query worked')

                const opportunities = response.data.postings
                this.setState({ opportunities })

              } else {
                console.log('posted postings query did not work', response.data.message)
                this.setState({ opportunities: [] })
              }

            }).catch((error) => {
                console.log('Posted postings query did not work for some reason', error);
            });

          } else {
            console.log('org info query did not work', response.data.message)

          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

    }

    renderQuestions() {
      console.log('renderQuestions called', this.state.questions)

      let rows = []
      for (let i = 1; i <= this.state.questions.length; i++) {
        console.log('show ', this.state.questions[i - 1].answer.split('\n')[1])
        const index = i - 1

        rows.push(
          <div key={i} className="horizontal-padding-3">
            <div className="spacer" />
            <a onClick={() => this.questionClicked(index)} className="background-link">
            <div className="float-left">
              <div className="half-spacer" />
                <img src={arrowIcon} alt="Employer icon" className={this.state.questions[i - 1].isSelected ? "image-auto-20 rotate-90" : "image-auto-20"} />
              </div>
              <div className="float-left left-padding-15">
                <p className="heading-text-6">{this.state.questions[i - 1].question}</p>
              </div>
              <div className="clear" />
            </a>
            {this.state.questions[i - 1].isSelected && (
              <div className="left-padding-30">
                <div className="spacer" />
                <div className="half-spacer" />
                {this.renderAnswers(index)}
              </div>
            )}
          </div>
        )
      }

      return rows
    }

    questionClicked(index) {
      console.log('questionClicked called')

      let questions = this.state.questions
      if (this.state.questions[index].isSelected) {
        questions[index]['isSelected'] = false
      } else {
        questions[index]['isSelected'] = true
      }

      this.setState({ questions })

    }

    renderAnswers(index) {
      console.log('renderAnswers called')

      let rows = []

      for (let i = 1; i <= this.state.questions[index].answer.split('\n').length; i++) {
        rows.push(
          <div key={"answer" + i.toString()}>
            <p className="description-text-2 bold-text cta-color">{this.state.questions[index].answer.split('\n')[i - 1]}</p>
          </div>
        )
      }

      return rows
    }

    render() {

      return (
          <div>
              <header className="front-page-header" onload={console.log('loading img')} style={{ backgroundImage: this.state.backgroundImage }}>

                  <div className="row top-margin">
                    <Link to={'/partners/' + this.state.partner}><img src={logoImgDark} alt="Compass logo" className="image-auto-50 float-left top-margin-25" /></Link>
                    <div className="partner-container">
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={addIcon} alt="Guided Compass add logo" className="partner-add-icon" /></Link>
                      </div>
                      <div className="float-left">
                        <Link to={'/partners/' + this.state.partner}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" className="image-auto-40 float-left top-margin-15 left-margin" /></Link>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="hero-text-box">

                    {(this.state.deactivated) ? (
                      <div>
                        <h1 className="light-text">{this.state.partnerName} Is No Longer a Guided Compass Partner</h1>
                        <p className="two-thirds-width">Click the link below to redirect to {this.state.partnerName}.</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <a href={this.state.redirectLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary cta-background-color white-text">Redirect to {this.state.partnerName}</a>
                      </div>
                    ) : (
                      <div>
                        <h1 className="light-text">{(this.state.studentAliasSingular) ? this.state.studentAliasSingular : "Career-Seeker"} Portal</h1>

                        <p className="two-thirds-width">{this.state.studentBenefitsText}</p>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <Link className="clear-border" to={'/organizations/' + this.state.activeOrg + '/career-seeker/join'} state={{ orgCode: this.state.activeOrg }}><button className="btn btn-primary">Get Started</button></Link>

                      </div>
                    )}

                  </div>
                  <div className="clear"/>
              </header>

              {(!this.state.deactivated) && (
                <div>
                  <section className="section-features">
                    <div className="row">
                      <p className="two-thirds-width center-text center-horizontally heading-text-4">
                          Guided Compass is a free resource designed to work for you throughout your life. It's a one stop shop from career exploration to career planning to working full-time.
                      </p>

                      <div className="spacer" />

                    </div>


                    {/*
                    <div className="row">
                      <div className="horizontal-padding-3">
                        <div className="col span-1-of-2 box">
                            <img src={benchmarksIcon} alt="GC" className="icon-big" />
                            <h3 className="center-text bold-text">Greater Chances</h3>
                            <p className="center-text">
                                Applying through {this.state.partnerName} gives you a greater chance of hire not only because we are a trusted source of quality candidates.
                            </p>
                            <div className="clear" />

                        </div>
                        <div className="col span-1-of-2 box">
                            <img src={benchmarksIcon} alt="GC" className="icon-big" />
                            <h3 className="center-text bold-text">Common App</h3>
                            <p className="center-text">
                                Applying through {this.state.partnerName} allows you to apply to 100+ internships across 5+ career tracks with a single submission. This saves you tremendous time and headache,
                            </p>
                            <div className="clear" />
                        </div>
                        <div className="clear"/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="horizontal-padding-3">
                        <div className="col span-1-of-2 box">
                            <img src={benchmarksIcon} alt="Employer icon" className="icon-big" />
                            <h3 className="center-text bold-text">Reuse Information</h3>
                            <p className="center-text">
                                Once you fill out your profile, your saved information can easily be edited and used to apply for additional internships; whether they start in 3 months or 12.
                            </p>
                            <div className="clear" />

                        </div>
                        <div className="col span-1-of-2 box">
                            <img src={benchmarksIcon} alt="GC" className="icon-big" />
                            <h3 className="center-text bold-text">Central Portal</h3>
                            <p className="center-text">
                                Guided Compass also acts as a central career portal for feedback, career assessment results, and endorsements. Use it to improve your application and explore alternative paths.
                            </p>
                            <div className="clear" />

                        </div>
                        <div className="clear"/>
                      </div>
                    </div>
                    {(this.state.partner === 'bixel-exchange' || this.state.partner === 'unite-la') && (
                      <div className="row">
                        <div className="horizontal-padding-3">
                          <div className="col span-1-of-2 box">
                              <img src={benchmarksIcon} alt="Employer icon" className="icon-big" />
                              <h3 className="center-text bold-text">Earn a FREE Certificate</h3>
                              <p className="center-text">
                                After applying through {this.state.partnerName}, you gain access to a FREE 3-part WorkforceReady course, learning soft skills and earning a certificate you can reuse for any future job interview.
                              </p>
                              <div className="clear" />

                          </div>
                          <div className="col span-1-of-2 box">
                              <img src={benchmarksIcon} alt="GC" className="icon-big" />
                              <h3 className="center-text bold-text">Network with Professionals</h3>
                              <p className="center-text">
                                  After approved for track(s), gain access to exclusive events for that track and enjoy greater chance of gaining work opportunities for anything within that track.
                              </p>
                              <div className="clear" />
                          </div>
                          <div className="clear"/>
                        </div>
                      </div>
                    )}*/}
                  </section>

                  <div className="row">
                    <hr className="cta-border" />
                  </div>

                  <SubCareerSeekerBenefits history={this.props.navigate} />

                  {(this.state.extraSection) && (
                    <section className="section-demo">
                      <div className="row">
                        <h2>Complete WorkforceReady Courses for FREE Skills Certificate</h2>

                        <div className="full-width">
                          <img src={workforceReadyLogo} alt="GC" className="center-horizontally"/>
                        </div>

                        <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        <p className="two-thirds-width center-text center-horizontally">
                          Cornerstone OnDemand has created WorkforceReady Modules to help you develop the soft skills necessary for the future of work. These are free lessons that must be completed before UNITE-LA staff refers you to internship / work opportunities.
                        </p>
                      </div>

                      <div className="spacer"/><div className="spacer"/><div className="spacer"/>

                      <div className="row-10 full-width center-text">
                        <a href="https://workforceready.csod.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary center-horizontally cta-background-color white-text">Get Started</a>
                      </div>

                    </section>
                  )}
                  {/*
                  {(this.state.partner !== 'detroitk12') && (
                    <section className={(this.state.extraSection) ? "section-features" : "section-demo"}>
                      <div className="row">
                        {(this.state.partner === 'exp') ? (
                          <h2>Internship FAQ</h2>
                        ) : (
                          <h2>Frequently Asked Questions</h2>
                        )}

                      </div>

                      <div className="spacer" />
                      <div className="spacer" />

                      {this.renderQuestions()}

                    </section>
                  )}*/}
                  {/*
                  <div className="row">
                    <hr className="cta-border" />
                  </div>*/}

                  <section className="section-features">
                    <div className="row">
                      <h2>Most Recently Posted Opportunities</h2>

                      {(this.state.opportunities && this.state.opportunities.length > 0) ? (
                        <div>
                          <SubRenderOpportunities
                            oppType="all" postings={this.state.opportunities} history={this.props.navigate}
                            activeOrg={this.state.activeOrg} pageSource="landingPage"
                          />
                        </div>
                      ) : (
                        <div>
                          <p className="full-width center-text">There aren't any recent, active opportunities.</p>
                        </div>
                      )}

                      <div className="top-margin-20 center-text">
                        <Link className="btn btn-primary" to={"/opportunities/organizations/" + this.state.activeOrg}>See All Opportunities</Link>
                      </div>
                    </div>
                  </section>
                </div>
              )}

              <section className="section-features contrasting-background">
                {(window.innerWidth < 768) ? (
                  <div className="full-width center-text">
                    <p className="full-width heading-text-2">Are you an Employer?</p>
                    <div className="top-margin">
                      <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/employers'} state={{ fromExternal: true }}>Switch to Employer Page</Link>
                    </div>
                  </div>
                ) : (
                  <div className="full-width">
                      <div className="container-left right-text">
                          <div className="full-width float-right">
                            <p className="join-text">Are you an</p>
                            <p className="join-title">Employer?</p>
                          </div>
                      </div>
                      <div className="container-right left-padding center-item">
                        <div className="padding-20">
                          <Link className="btn btn-big" to={'/partners/' + this.state.partner + '/employers'} state={{ fromExternal: true }}>Switch to Employer Page</Link>
                        </div>
                      </div>
                      <div className="clear" />
                  </div>
                )}
              </section>

              <Footer history={this.props.navigate} />
          </div>

      )
    }
}

export default withRouter(PartnerStudentPage);
