import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import SubMentorBenefits from './Common/MentorBenefits';
import withRouter from '../components/Functions/WithRouter';

class MentorsPage extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.clickedPricing = this.clickedPricing.bind(this)
    }

    handleChange(event) {
        console.log('change in checkbox handled')
    }

    clickedPricing(index) {
      console.log('clickedPricing clicked', index)

      this.props.navigate({
        pathname: '/contact',
        state: {
          reason: 'Upgrade Career Advisor Plan'
        }
      })
    }

    componentDidMount() {
        //see if user is logged in
        console.log('counselors page just mounted')
        window.scrollTo(0, 0)

    }

    componentWillUnmount(){
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"mentorsPage"}/>

                <header className="story-guide-header">
                    <div className="hero-text-box">
                    <h1 className="light-text">Mentors</h1>
                    <p>Provide resources, track progress, review resumes, mock interview, and refer career-seekers.</p>
                    <Link className="btn btn-full" to={'/join'} state={{ roleName: "Mentor" }}>Join the Mentor Portal</Link>
                    </div>
                </header>

                <SubMentorBenefits history={this.props.navigate} orgSelected="guidedcompass" />

                <section className="section-join light-background">
                    <div className="join-container">
                        <div className="join-text-box">
                            <p className="join-text">Ready to make a</p>
                            <p className="join-title">Difference?</p>
                        </div>
                        <Link className="btn btn-full join-btn" to={'/join'} state={{ roleName: "Mentor" }}>Join</Link>
                    </div>
                </section>

                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default withRouter(MentorsPage);
