import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {subExportCSV} from '../Functions/ExportCSV';
import withRouter from '../Functions/WithRouter';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const profileIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png";
const exportIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/export-icon-blue.png";

class Grades extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.retrieveData = this.retrieveData.bind(this)
      this.exportFile = this.exportFile.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in goals', this.props)

      if (this.props.orgCode !== prevProps.orgCode || this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in goals', this.props)

      const emailId = localStorage.getItem('email');
      const username = localStorage.getItem('username');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      if (this.props.orgCode) {
        activeOrg = this.props.orgCode
      }

      const orgFocus = localStorage.getItem('orgFocus');

      let prefix = '/organizations/' + activeOrg
      if (window.location.pathname.startsWith('/advisor')) {
        prefix = '/advisor'
      }

      // console.log('show me activeOrg: ', activeOrg)
      this.setState({ emailId, activeOrg, username, cuFirstName, cuLastName, orgFocus, prefix, loadingItems: true })

      if (activeOrg || this.props.noOrgCode) {

        const self = this
        const subParams = { includesGrade: true, orgCode: activeOrg }
        function pullDocGrades(grades) {
          console.log('pullDocGrades called')

          Axios.get('/api/documents', { params: subParams })
          .then((response) => {
            console.log('documents query attempted', response.data);

            if (response.data.success) {
              console.log('documents query worked')

              // grades = grades.concat(response.data.documents)
              for (let i = 1; i <= response.data.documents.length; i++) {
                let doc = response.data.documents[i - 1]
                doc['gradeType'] = 'Document'
                grades.push(doc)
              }
              self.setState({ grades, loadingItems: false  })

            } else {
              console.log('documents query did not work', response.data.message)
              self.setState({ grades, loadingItems: false })
            }

          }).catch((error) => {
              console.log('documents query did not work for some reason', error);
          });
        }

        Axios.get('/api/submissions', { params: subParams })
        .then((response) => {
          console.log('Submissions query attempted', response.data);

          if (response.data.success) {
            console.log('submissions query worked')

            // const grades = response.data.submissions
            // this.setState({ grades, loadingItems: false  })
            pullDocGrades(response.data.submissions)

          } else {
            console.log('submissions query did not work', response.data.message)
            this.setState({ loadingItems: false })
            pullDocGrades([])
          }

        }).catch((error) => {
            console.log('submissions query did not work for some reason', error);
        });
      }
    }

    exportFile() {
      console.log('exportFile called')

      let csvData = []
      csvData.push(
        [ "First Name","Last Name","Email","Title","Course","Assignment", "Grade","Grade Explanation","Created At", "Updated At" ]
      )

      const grades = this.state.grades

      if (grades && grades.length > 0) {

        let commaStartingValue = /,/g
        let commaReplacementValue = ";"

        for (let i = 1; i <= grades.length; i++) {

          const firstName = grades[i - 1].firstName
          const lastName = grades[i - 1].lastName
          let email = grades[i - 1].email
          if (!email) {
            email = grades[i - 1].emailId
          }

          let title = ''
          if (grades[i - 1].title) {
            title = grades[i - 1].title.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          }

          let course = 'N/A'
          if (grades[i - 1].courseName) {
            course = grades[i - 1].courseName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          }

          let assignment = ''
          if (grades[i - 1].assessmentName) {
            assignment = grades[i - 1].assessmentName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          } else if (grades[i - 1].lessonName) {
            assignment = grades[i - 1].lessonName.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          }

          let grade = ''
          if (grades[i - 1].grade) {
            grade = grades[i - 1].grade
          }

          let gradeExplanation = ''
          if (grades[i - 1].gradeExplanation) {
            gradeExplanation = grades[i - 1].gradeExplanation.replace(commaStartingValue,commaReplacementValue).replace(/#/g,"")
          }

          const createdAt = grades[i - 1].createdAt
          const updatedAt = grades[i - 1].updatedAt

          csvData.push([
            firstName, lastName, email, title, course, assignment, grade, gradeExplanation,
            createdAt, updatedAt
          ])
        }

        const returnedData = subExportCSV('grades',csvData)
        console.log('returnedData response: ', returnedData)

      } else {

        // return { clientErrorMessage: 'There is no data to export', isLoading: false}
      }
    }

    render() {

      return (
          <div>
            <div className={"width-70-percent max-width-1400 top-margin-2-percent center-horizontally white-background padding-20 medium-shadow"}>
              <div className="calc-column-offset-50">
                <p className="heading-text-2">{(this.state.grades) && this.state.grades.length + " "}Grades</p>
              </div>
              <div className="fixed-column-50">
                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <div className="row-5 right-padding-20">
                  <button onClick={() => this.exportFile()} className="background-button">
                    <img src={exportIconBlue} alt="GC" className="image-25-fit" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Export grades to CSV file" />
                  </button>
                </div>
              </div>
              <div className="clear" />
              <ReactTooltip id="tooltip-placeholder-id" />
            </div>

            {(this.state.loadingItems) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Loading results...</p>

                </div>
              </div>
            ) : (
              <div className={"width-70-percent max-width-1400 top-margin-2-percent center-horizontally white-background padding-20 medium-shadow"}>
                {(this.state.grades) && (
                  <div>
                    {this.state.grades.map((value, index) =>
                      <div key={"submissions|" + index} className="top-margin-5">
                        <Link className="background-button full-width left-text row-10" to={(value.gradeType === 'Document') ? this.state.prefix + "/documents/" + value._id : this.state.prefix + "/submissions/" + value._id}>
                          <div className="fixed-column-40">
                            <p className="heading-text-5 cta-color full-width">{index + 1}.</p>
                          </div>
                          <div className="fixed-column-50 top-margin-3">
                            <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-1" />
                          </div>
                          <div className="calc-column-offset-210">
                            <p>{value.firstName} {value.lastName}</p>
                            <p className="description-text-2 description-text-color top-margin-5">Submitted to {(value.assessmentName) ? value.assessmentName : (value.courseName) ? value.courseName : (value.lessonName) ? value.lessonName : "Assignment"}</p>
                          </div>
                          {(value.grade) ? (
                            <div className="fixed-column-40">
                              <p className="heading-text-5 cta-color full-width right-text">{value.grade}%</p>
                            </div>
                          ) : (
                            <div className="fixed-column-40 height-30" />
                          )}
                          <div className="fixed-column-80">
                            <p className="description-text-3 full-width right-text">{convertDateToString(new Date(value.createdAt),"datetime-2")}</p>
                          </div>
                          <div className="clear" />
                        </Link>

                        <div className="spacer" />
                        <hr />
                        <div className="half-spacer" />

                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Grades);
