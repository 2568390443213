import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const checkmarkDarkGreyIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-dark-grey.png";
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const ratingsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/ratings-icon-blue.png';
const profileIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-blue.png';
const difficultyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/difficulty-icon-blue.png';
const timeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/time-icon-blue.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const eventIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';

class Completions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            completions: [],
            subNavCategories: [],
            subNavSelected: 'All',

            sessionErrorMessage: '',
            serverPostSuccess: false,
            serverErrorMessage: '',
            serverSuccessMessage: ''

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.subNavClicked = this.subNavClicked.bind(this)
        this.renderCompletions = this.renderCompletions.bind(this)
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in subcompletions')

      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      let subNavCategories = ['All','RSVPs','Project Submissions','Applications','Offers','Internships / Work','Courses']
      if (this.props.pageSource === 'courses') {
        subNavCategories = ['All', 'Courses']
      }

      if (email) {
        this.setState({ emailId: email, cuFirstName, cuLastName, username, activeOrg, orgFocus, subNavCategories });
      }

      console.log('show email value:', email)

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            const orgName = response.data.orgInfo.orgName
            this.setState({ orgName });

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      // submissions, rsvps, work
      const orgCode = activeOrg
      Axios.get('/api/users/rsvps', { params: { emailId: email, orgCode } })
      .then((response) => {

          if (response.data.success) {
            console.log('Rsvps query worked', response.data);

            let rsvps = response.data.rsvps
            let completions = this.state.completions

            for (let i = 1; i <= rsvps.length; i++) {
              rsvps[i - 1]["completionType"] = "RSVP"
            }

            completions = completions.concat(rsvps)
            let filteredCompletions = completions

            this.setState({ rsvps, completions, filteredCompletions })

          } else {
            console.log('no rsvp data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Passion query did not work', error);
      });

      Axios.get('/api/users/submissions', { params: { emailId: email, orgCode } })
      .then((response) => {

          if (response.data.success) {
            console.log('Submissions query worked', response.data);

            let postings = response.data.postings
            let completions = this.state.completions

            for (let i = 1; i <= postings.length; i++) {
              postings[i - 1]["completionType"] = "Submission"
            }

            completions = completions.concat(postings)
            let filteredCompletions = completions

            this.setState({ postings, completions, filteredCompletions })

          } else {
            console.log('no posting data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Submissions query did not work', error);
      });

      Axios.get('/api/applications', { params: { emailId: email, orgCode } })
      .then((response) => {
          if (response.data.success) {
            console.log('Applications submitted query worked', response.data);

            let applications = response.data.applications
            let completions = this.state.completions

            for (let i = 1; i <= applications.length; i++) {
              applications[i - 1]["completionType"] = "Application"
            }

            completions = completions.concat(applications)
            let filteredCompletions = completions

            this.setState({ applications, completions, filteredCompletions })

          } else {
            console.log('no application data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Application query did not work', error);
      });

      Axios.get('/api/offers/byuser', { params: { emailId: email, orgCode } })
      .then((response) => {

          if (response.data.success) {
            console.log('Offers received query worked', response.data);

            let offers = response.data.offers
            let completions = this.state.completions

            for (let i = 1; i <= offers.length; i++) {
              offers[i - 1]["completionType"] = "Offer"
            }

            completions = completions.concat(offers)
            let filteredCompletions = completions

            this.setState({ offers, completions, filteredCompletions })

          } else {
            console.log('no offer data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Passion query did not work', error);
      });

      Axios.get('/api/work', { params: { workerEmail: email, orgCode } })
      .then((response) => {

          if (response.data.success) {
            console.log('Work received query worked', response.data);

            let workArray = response.data.workArray
            let completions = this.state.completions

            for (let i = 1; i <= workArray.length; i++) {
              workArray[i - 1]["completionType"] = "Work"
            }

            completions = completions.concat(workArray)
            let filteredCompletions = completions

            this.setState({ workArray, completions, filteredCompletions })

          } else {
            console.log('no offer data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Passion query did not work', error);
      });

      Axios.get('/api/completions', { params: { emailId: email } })
     .then((response) => {
       console.log('Completions query attempted', response.data);

       if (response.data.success) {
         console.log('successfully retrieved completions')

         let completions = response.data.completions

         if (completions && completions.length > 0) {
           Axios.get('/api/completions/detail', { params: { completions, orgCode } })
           .then((response2) => {
             console.log('Completions detail query attempted', response2.data);

             if (response2.data.success) {
               console.log('successfully retrieved completions detail', response2.data.completions)

               let newCompletions = []
               for (let i = 1; i <= response2.data.completions.length; i++) {
                 let newCompletion = response2.data.completions[i - 1]
                 newCompletion["completionType"] = "Course"
                 newCompletions.push(newCompletion)
               }

               newCompletions = this.state.completions.concat(newCompletions)
               const filteredCompletions = newCompletions

               this.setState({ completions: newCompletions, filteredCompletions })

             } else {
               console.log('no completions detail data found', response2.data.message)
             }

           }).catch((error) => {
               console.log('Completions detail query did not work', error);
           });
         }

       } else {
         console.log('no completions data found', response.data.message)
       }

     }).catch((error) => {
         console.log('Favorites query did not work', error);
     });

       Axios.get('/api/courses/enrollments', { params: { emailId: email } })
      .then((response) => {
        console.log('Enrollments query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved enrollments')

          let enrollments = response.data.enrollments
          let completions = this.state.completions

          for (let i = 1; i <= enrollments.length; i++) {
            enrollments[i - 1]["completionType"] = "Course"
          }

          completions = completions.concat(enrollments)
          let filteredCompletions = completions

          this.setState({ enrollments, completions, filteredCompletions })

        } else {
          console.log('no enrollments data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Enrollments query did not work', error);
      });

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      }
    }

    renderCompletions() {
      console.log('renderCompletions called', this.props.pageSource)

      let rows = [];
      if ( this.state.completions.length !== 0 ) {

        for (let i = 1; i <= this.state.completions.length; i++) {
          console.log(i);

          const index = i - 1
          // console.log('show pageSource: ', this.props.pageSource)
          if (this.props.pageSource === 'courses' && this.state.completions[i - 1].completionType === 'Course') {
            console.log('up in courses')

            const value = this.state.completions[index]

            rows.push(
              <div key={index}>
                <div className="spacer" />
                <a href={'https://www.udemy.com' + value.url} className={(this.props.pageSource === 'landingPage') ? "background-button calc-column-offset-25" : "background-button calc-column-offset-65"} target="_blank">
                  <div className="fixed-column-70">
                    <img src={(value.image_125_H) ? value.image_125_H : courseIconDark} alt="GC" className="image-60-auto"/>
                  </div>
                  <div className="calc-column-offset-70">
                    <p className="heading-text-5">{value.title}</p>
                    <p className="description-text-1 description-text-color">{value.headline}</p>
                    <div className="half-spacer" />
                  </div>
                </a>

                {(this.props.pageSource !== 'landingPage') && (
                  <span className="fixed-column-40 top-margin center-text" >
                    <div>
                      <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.markCompleted(value, 'course')}>
                        <img src={(this.state.completions.includes(value._id)) ? checkboxChecked : checkmarkDarkGreyIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark Course Completed"/>
                      </button>
                    </div>
                    <ReactTooltip id="tooltip-placeholder-id" />
                  </span>
                )}

                <div className="fixed-column-25 left-padding" >
                  <span>
                    <div className="spacer"/><div className="half-spacer"/>
                    <a href={'https://www.udemy.com' + value.url} className="background-button full-width" target="_blank">
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                    </a>
                  </span>
                </div>

                <div className="clear"/>

                <div className="spacer" /><div className="spacer" />
                <hr className="clear-margin clear-padding" />
                <div className="clear"/>
                <div className="spacer" />
              </div>
            )
          } else if (this.props.pageSource !== 'courses') {
            //starting assuming an application

            const item = this.state.completions[i - 1]

            let itemIcon = opportunitiesIconDark
            let title = this.state.completions[i - 1].postingTitle
            let subtitle = this.state.completions[i - 1].completionType + " | " + this.state.completions[i - 1].postingEmployerName + " | " + convertDateToString(new Date(this.state.completions[i - 1].createdAt),"datetime-2")
            let completionLink = '/app/opportunities/' + this.state.completions[i - 1].postingId
            let passedState = null
            let disabledLink = false

            if (this.state.completions[i - 1].completionType === 'Offer') {
              itemIcon = opportunitiesIconDark
              title = this.state.completions[i - 1].title
              subtitle = this.state.completions[i - 1].completionType + " | " + this.state.completions[i - 1].employerName + " | " + convertDateToString(new Date(this.state.completions[i - 1].createdAt),"datetime-2")
              completionLink = '/app/logs/' + this.state.completions[i - 1]._id
              passedState = { objectId: this.state.completions[i - 1]._id }
              disabledLink = false
            } else if (this.state.completions[i - 1].completionType === 'RSVP') {
              itemIcon = eventIconDark
              title = this.state.completions[i - 1].postingTitle
              subtitle = this.state.completions[i - 1].completionType + " | " + convertDateToString(new Date(this.state.completions[i - 1].createdAt),"datetime-2")
              completionLink = '/app/opportunities/' + this.state.completions[i - 1].postingId
              passedState = null
              disabledLink = false
            } else if (this.state.completions[i - 1].completionType === 'Submission') {
              itemIcon = projectsIconDark
              title = ''
              // suboptimal
              for (let j = 1; j <= this.state.completions[i - 1].submissions.length; j++) {
                if (this.state.completions[i - 1].submissions[j - 1].userEmail === this.state.emailId) {
                  title = this.state.completions[i - 1].submissions[j - 1].name
                }
              }
              subtitle = this.state.completions[i - 1].completionType + " | Submitted to: " + this.state.completions[i - 1].name + ' on ' + convertDateToString(new Date(this.state.completions[i - 1].createdAt),"datetime-2")
              completionLink = '/app/opportunities/' + this.state.completions[i - 1]._id
              passedState = null
              disabledLink = false
            } else if (this.state.completions[i - 1].completionType === 'Work') {
              itemIcon = opportunitiesIconDark
              title = this.state.completions[i - 1].title + ' @ ' + this.state.completions[i - 1].employerName
              subtitle = this.state.completions[i - 1].completionType + " | " + convertDateToString(new Date(this.state.completions[i - 1].createdAt),"datetime-2")
              completionLink = '/app/work/'
              passedState = null
              disabledLink = true
            }

            let showCompletion = false
            if (this.state.subNavSelected === 'All') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'RSVPs' && this.state.completions[i - 1].completionType === 'RSVP') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'Project Submissions' && this.state.completions[i - 1].completionType === 'Submission') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'Applications' && this.state.completions[i - 1].completionType === 'Application') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'Offers' && this.state.completions[i - 1].completionType === 'Offer') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'Internships / Work' && this.state.completions[i - 1].completionType === 'Work') {
              showCompletion = true
            } else if (this.state.subNavSelected === 'Courses' && this.state.completions[i - 1].completionType === 'Course') {
              showCompletion = true
            }

            if (showCompletion) {
              console.log('show completion: ')
              if (item.completionType === 'Course' || item.completionType === 'Enrollment') {

                rows.push(
                  <div key={index}>
                    <div className="spacer" />
                    <Link className="background-button calc-column-offset-60" to={"/app/courses/" + item.courseId}>
                      <div className="fixed-column-70">
                        <img src={(item.courseImageURL) ? item.courseImageURL : (item.image_125_H) ? item.image_125_H : courseIconDark} alt="GC" className="image-60-auto"/>
                      </div>
                      <div className="calc-column-offset-70">
                        <p className="heading-text-5">{(item.courseName) ? item.courseName : item.title}</p>
                        {(item.headline) && (
                          <p className="description-text-1 description-text-color">{item.headline}</p>
                        )}
                        {(item.courseSchoolName) && (
                          <p className="description-text-3 bottom-margin top-margin-5 standard-color">by {item.courseSchoolName}</p>
                        )}
                        {(item.courseDescription) && (
                          <p className="description-text-1 description-text-color top-margin-15">{item.courseDescription}</p>
                        )}
                        <div className="top-margin-15">
                          {(item.courseCategory) && (
                            <div className="description-text-3">
                              <div className="float-left right-margin">
                                <img src={courseIconBlue} alt="GC" className="image-auto-15 center-item"/>
                              </div>
                              <div className="float-left right-margin">
                                <label className="standard-color">{item.courseCategory}</label>
                              </div>
                            </div>
                          )}

                          {(item.courseEstimatedHours) && (
                            <div className="description-text-3">
                              <div className="float-left right-margin">
                                <img src={timeIconBlue} alt="GC" className="image-auto-15 center-item"/>
                              </div>
                              <div className="float-left right-margin">
                                <label className="standard-color">{item.courseEstimatedHours} Hours</label>
                              </div>
                            </div>
                          )}

                          {(item.courseDegreeType) && (
                            <div className="description-text-3">
                              <div className="float-left right-margin">
                                <img src={ratingsIconBlue} alt="GC" className="image-auto-15 center-item"/>
                              </div>
                              <div className="float-left right-margin">
                                <label className="standard-color">{item.courseDegreeType}</label>
                              </div>
                            </div>
                          )}

                          {(item.courseProgramMethod) && (
                            <div className="description-text-3">
                              <div className="float-left right-margin">
                                <img src={profileIconBlue} alt="GC" className="image-auto-15 center-item"/>
                              </div>
                              <div className="float-left right-margin">
                                <label className="standard-color">{item.courseProgramMethod}</label>
                              </div>
                            </div>
                          )}

                          {(item.courseDifficultyLevel) && (
                            <div className="description-text-3">
                              <div className="float-left right-margin">
                                <img src={difficultyIconBlue} alt="GC" className="image-auto-15 center-item"/>
                              </div>
                              <div className="float-left right-margin">
                                <label className="standard-color">{item.courseDifficultyLevel ? item.courseDifficultyLevel : "Beginner"}</label>
                              </div>
                            </div>
                          )}
                          <div className="clear" />
                        </div>

                        <div className="half-spacer" />
                      </div>
                    </Link>
                    {/*
                    {(this.props.pageSource !== 'landingPage') && (
                      <span className="fixed-column-40 top-margin center-text" >
                        <div>
                          <button className="background-button clear-padding clear-margin center-item padding-5" onClick={() => this.markCompleted(item, 'course')}>
                            <img src={(this.state.completions.includes(this.state.completions[i - 1]._id)) ? checkboxChecked : checkmarkDarkGreyIcon} alt="GC" className="image-auto-15" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Mark Course Completed"/>
                          </button>
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </span>
                    )}*/}

                    <div className="fixed-column-25 left-padding" >
                      <span>
                        <div className="spacer"/><div className="half-spacer"/>
                        <Link className="background-button calc-column-offset-60" to={"/app/courses/" + item.courseId}>
                          <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                        </Link>
                      </span>
                    </div>

                    <div className="clear"/>

                    <div className="spacer" /><div className="spacer" />

                  </div>
                )
              } else {
                rows.push(
                  <div key={this.state.completions[i - 1]._id} className="full-width row-10">
                    <Link to={completionLink} state={passedState} disabled={disabledLink} className={(disabledLink) && "no-pointers"}>
                      <div className="fixed-column-70">
                        <img src={itemIcon} alt="GC" className="image-auto-50"/>
                      </div>
                      <span className="calc-column-offset-120">
                        <div className="heading-text-6">
                            <p>{title}</p>
                        </div>
                        <div className="clear" />
                        <p className="description-text-2 standard-color">{subtitle}</p>
                      </span>
                      <div className="fixed-column-50">
                          <div className="action-icon-container">
                            <img src={arrowIndicatorIcon} alt="GC" className="arrow-indicator-icon"/>
                          </div>
                      </div>
                    </Link>
                    <div className="clear" />
                  </div>
                )
              }
            }
          }
        }
      } else {

        rows.push(
          <div key={1} className="flex-container flex-center center-text padding-30">
            <div className="padding-5-percent" >
              <img src={checkmarkDarkGreyIcon} alt="Compass large endorsements icon" className="empty-state-icon"/>
              <h3 className="empty-state-title"><strong>No Completed Items Yet</strong></h3>
              <p className="empty-state-text full-width">This section contains all things you have completed, including RSVPs to events, submissions to projects, applications for work, offers, work experience, and other things you can accomplish through the platform.</p>
            </div>
          </div>
        )
      }

      return rows;
    }

    subNavClicked(pageName) {
      console.log('subNavClicked called', pageName)

      this.setState({ subNavSelected: pageName })
    }

    render() {

        return (
            <div>
              <div className="row-20">
                <p className="heading-text-2">Enrollments & Completed Items</p>
              </div>

              {(this.state.completions && this.state.completions.length > 0) && (
                <div>
                  <div className="carousel-3 light-background" onScroll={this.handleScroll}>
                    {this.state.subNavCategories.map((value, index) =>
                      <div className="carousel-item-container">
                        {(this.state.subNavCategories[index] === this.state.subNavSelected) ? (
                          <div className="selected-carousel-item">
                            <label key={value}>{value}</label>
                          </div>
                        ) : (
                          <button className="menu-button" onClick={() => this.subNavClicked(value)}>
                            <label key={value}>{value}</label>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="spacer"/>
              {this.renderCompletions()}

              <div className="clear" />
            </div>

        )
    }
}

export default withRouter(Completions);
