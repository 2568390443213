import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubBusinessIdeaDetails from './Subcomponents/BusinessIdeaDetails';
import withRouter from './Functions/WithRouter';

class BusinessIdeaDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      const { id } = this.props.params
      // console.log('careerSelected:', careerSelected);

      let email = localStorage.getItem('email');
      const orgLogo = localStorage.getItem('orgLogo');
      this.setState({ id, emailId: email, orgLogo });

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentBusinessIdeaDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.id !== prevProps.params.id) {
        // console.log('new id in parent', this.props.params, prevProps.params)
        this.setState({ id: this.props.params.id})
      }
    }

    render() {

        return (
            <div>
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus}  history={this.props.navigate}/>
              <div>
                <SubBusinessIdeaDetails id={this.state.id} history={this.props.navigate}  />
              </div>

              {(this.state.activeOrg) && (
                <div>
                  {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
                </div>
              )}
            </div>

        )
    }
}

export default withRouter(BusinessIdeaDetails);
