import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'react-modal';
// import Axios from 'axios';

import SubExperienceDetails from './ExperienceDetails';

// const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';

class RenderExperience extends Component {
    constructor(props) {
      super(props)

      this.state = {
        favorites: []
      }

      this.renderItems = this.renderItems.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.closeModal = this.closeModal.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in renderExperience', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg) {
        this.retrieveData()
      } else if (this.props.experience !== prevProps.experience) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in renderExperience')

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let roleName = localStorage.getItem('roleName');
      // let roleName = localStorage.getItem('roleName');
      // console.log('show cuFirstName: ', cuFirstName)

      const org = this.props.org
      // const experience = this.props.experience

      this.setState({ emailId, cuFirstName, cuLastName, username, roleName, org })
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name === 'grade') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['grade'] = event.target.value
        this.setState({ projects })
      } else if (event.target.name === 'feedback') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['feedback'] = event.target.value
        this.setState({ projects })
      } else if (event.target.name === 'isTransparent') {
        let projects = this.props.projects
        projects[this.state.selectedIndex1]['grades'][this.state.selectedIndex2]['isTransparent'] = event.target.value
        this.setState({ projects })
      }
    }

    renderItems() {
      console.log('renderItems called', this.props.experience)

      let rows = []

      // jobTitle, employerName, startDate, endDate, isContinual,
      // jobFunction, industry, wasPaid, hoursPerWeek,
      // skillTags, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorEmail,
      // createdAt, updatedAt


      // description,
      // workInterest, workSkill, teamInterest, employerInterest, payInterest, overallFit,


      if (this.props.experience) {
        const experience = this.props.experience
        for (let i = 1; i <= experience.length; i++) {

          const item = experience[i - 1]
          const index = i - 1
          console.log('looping experience: ', item)
          rows.push(
            <div key={index}>
              <div>
                <button onClick={() => this.setState({ modalIsOpen: true, showExperienceDetails: true, selectedExperience: item })} className="background-button full-width left-text">
                  <div className="calc-column-offset-200">
                    <p className="heading-text-5 cta-color">{item.jobTitle} @ {item.employerName}</p>
                    <p className="description-text-2 standard-color curtail-text top-padding-5">{item.hoursPerWeek} Per Week{(item.wasPaid) ? " | Paid Opportunity" : " | Volunteer Opportunity"}{(item.jobFunction) && " | " + item.jobFunction}{(item.industry) && " | " + item.industry}</p>
                    {(item.overallFit) && (
                      <p className="description-text-2 standard-color curtail-text top-padding-5">Overall Fit: {item.overallFit}{(item.workSkill) && " | Skill Fit: " + item.workSkill}{(item.teamInterest) && " | Team Interest: " + item.teamInterest}{(item.employerInterest) && " | Employer Interest: " + item.employerInterest}{(item.workInterest) && " | Work Interest: " + item.workInterest}{(item.payInterest) && " | Pay Interest: " + item.payInterest}</p>
                    )}
                  </div>
                  <div className="fixed-column-170">
                    <p className="cta-color right-text">{item.startDate} - {(item.isContinual) ? "Present" : item.endDate}</p>
                  </div>
                  <div className="fixed-column-30">
                    <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 pin-right" />
                  </div>
                  <div className="clear" />
                  {(item.description) && (
                    <p className="description-text-2 standard-color curtail-text top-padding-5">{item.description}</p>
                  )}
                </button>

                <div className="row-10">
                  <hr />
                </div>
              </div>

              <ReactTooltip id="tooltip-placeholder-id" />

            </div>
          )
        }
      }
      console.log('in rows????', rows)
      return rows
    }

    itemClicked(e, type, value) {
      console.log('itemClicked called', e, type, value)

      e.preventDefault()
      e.stopPropagation()

      let profileData = {
        firstName: value.userFirstName,
        lastName: value.userLastName,
        email: value.emailId,
        pictureURL: value.userPictureURL,
        username: value.userUsername
      }

      this.setState({ modalIsOpen: true, showHelpOutWidget: true, selectedProject: value, profileData })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showExperienceDetails: true, selectedExperience: null })
    }

    render() {

      return (
        <div>
          {this.renderItems()}

          <Modal
           isOpen={this.state.modalIsOpen}
           onAfterOpen={this.afterOpenModal}
           onRequestClose={this.closeModal}
           className="modal"
           overlayClassName="modal-overlay"
           contentLabel="Example Modal"
           ariaHideApp={false}
         >

          {(this.state.showExperienceDetails) && (
            <div key="gradeProject">
              <SubExperienceDetails history={this.props.navigate} selectedExperience={this.state.selectedExperience} />

              <div className="row-20 center-text">
               <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
              </div>
            </div>
          )}


         </Modal>
        </div>
      )
    }
}

export default RenderExperience;
