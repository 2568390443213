import React, {Component} from 'react';
import Footer from './Footer';
import TopNavigation from './TopNavigation';

const appleStoreBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/AppleStoreBadge.png';
const googlePlayBadge = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/GooglePlayBadge.png';
const cleverLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/clever-logo.png';
const schoologyLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/schoology-logo.jpeg';
const udemyLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udemy-logo.png';
const courseraLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/coursera-logo.png';
const udacityLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/udacity-logo.png';
const blsLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/bls-logo.png';
const onetLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/o-net-logo.png';
const greenhouseLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/greenhouse-logo.png';
const leverLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/lever-logo.png';
const jobDivaLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/jobdiva-logo.png';
const applicantProLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/applicantPro-logo.jpeg';
const youTubeLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/YouTube_Logo.png';
const openAILogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/openai-logo.png';

class IntegrationsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          subNavCategories: [],
          subNavSelected: 'All',
          integrations: []
        }
    }
    componentDidMount() {
      //see if user is logged in
      console.log('jobs page just mounted')
      window.scrollTo(0, 0)

      // assessments, backgroundChecks, calendarIntegrations, hrisIntegrations, productivity
      // SSO, App Stores, LMS, Course Platforms, Labor Market Data, applicant tracking systems
      const subNavCategories = [
        'All','Applicant Tracking Systems','App Stores','Single Sign-On','Learning Management Systems','Course Platforms','Labor Market Data','Videos','Chatbots'
      ]
      const integrations = [
        { name: 'Greenhouse', category: 'Applicant Tracking Systems', imageURL: greenhouseLogo, description: "Greenhouse provides a recruiting software as a service", url: 'https://support.greenhouse.io/hc/en-us/articles/4403475645723-Guided-Compass-' },
        { name: 'Lever', category: 'Applicant Tracking Systems', imageURL: leverLogo, description: "Lever provides a recruiting software as a service", url: 'https://leverpartner.com/integrations/guided-compass' },
        { name: 'JobDiva', category: 'Applicant Tracking Systems', imageURL: jobDivaLogo, description: "JobDiva provides a recruiting software as a service", url: 'https://www.jobdiva.com' },
        { name: 'ApplicantPro', category: 'Applicant Tracking Systems', imageURL: applicantProLogo, description: "ApplicantPro provides a recruiting software as a service", url: 'https://www.applicantpro.com' },
        { name: 'Apple App Store', category: 'App Stores', imageURL: appleStoreBadge, description: "Apple App Store allows people to download apps on iOS phones and tablets", url: 'https://itunes.apple.com/us/app/guided-compass/id1436670877?ls=1&mt=8' },
        { name: 'Google Play Store', category: 'App Stores', imageURL: googlePlayBadge, description: "Google Play Store allows people to download apps on Android phones and tablets", url: 'https://play.google.com/store/apps/details?id=com.guidedcompass' },
        { name: 'Clever', category: 'App Stores', imageURL: cleverLogo, description: "Clever App Store allows K12 teachers and district administrators to install apps for educating students", url: 'https://clever.com/library/app/guided-compass' },
        { name: 'Schoology', category: 'Learning Management Systems', imageURL: schoologyLogo, description: "We've integrated with the Schoology App Store", url: 'https://www.schoology.com' },
        { name: 'Udemy', category: 'Course Platforms', imageURL: udemyLogo, description: "Udemy is a for-profit Massive Open Online Course (MOOC) provider aimed at professional adults and students", url: 'https://www.udemy.com' },
        { name: 'Coursera', category: 'Course Platforms', imageURL: courseraLogo, description: "Coursera is a U.S.-based Massive Open Online Course (MOOC)", url: 'https://www.coursera.org' },
        { name: 'Udacity', category: 'Course Platforms', imageURL: udacityLogo, description: "Udacity is a U.S.-based Massive Open Online Course (MOOC)", url: 'https://www.udacity.com' },
        { name: 'U.S. Bureau of Statistics', category: 'Labor Market Data', imageURL: blsLogo, description: "The Bureau of Labor Statistics (BLS) is a unit of the United States Department of Labor", url: 'https://www.bls.gov' },
        { name: 'O-Net', category: 'Labor Market Data', imageURL: onetLogo, description: "The Occupational Information Network (O-Net) is a free online database that contains hundreds of job definitions to help students, job seekers, businesses and workforce development professionals to understand today's world of work in the United States.", url: 'https://www.onetonline.org' },
        { name: 'YouTube', category: 'Videos', imageURL: youTubeLogo, description: "Provides the ability to pull relevant YouTube videos.", url: 'https://www.youtube.com' },
        { name: 'ChatGPT', category: 'Chatbots', imageURL: openAILogo, description: "ChatGPT (Chat Generative Pre-trained Transformer) is an advanced chatbot launched by OpenAI in November 2022.", url: 'https://openai.com/blog/chatgpt/' },
      ]

      this.setState({ subNavCategories, integrations })
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"integrationsPage"} darkBackground={true} />

                <section className="section-features light-background">
                  <div className="row">
                    <h2>Integrations</h2>

                    <div className="row-10">
                      <p className="center-text">Guided Compass integrates with a number of third-party software to provide a seamless experience</p>
                    </div>
                    <div className="spacer" />
                  </div>
                </section>

                <section className="section-features half-bold-text">
                  <div className="row horizontal-padding-4">
                    <div className={(window.innerWidth > 768) ? "fixed-column-250 description-text-2" : "full-width description-text-2"}>
                      {(window.innerWidth > 768) ? (
                        <div>
                          {this.state.subNavCategories.map((value, index) =>
                            <div>
                              <button className="background-button" onClick={() => this.setState({ subNavSelected: value })}><p className={(this.state.subNavSelected === value) ? "row-10 selected-item-container-1 cta-color" : "row-10"}>{value}</p></button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="full-width white-background bottom-padding-20">
                            <div className="center-horizontally clear-float width-70-percent">
                              <div className="carousel-3" onScroll={this.handleScroll}>
                                <div className="carousel-item-container">
                                  {this.state.subNavCategories.map((value, index) =>
                                    <button className="background-button" onClick={() => this.setState({ subNavSelected: value })}><p className={(this.state.subNavSelected === value) ? "selected-carousel-item" : "menu-button"}>{value}</p></button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </div>
                    <div className={(window.innerWidth > 768) ? "calc-column-offset-250" : "full-width calc-column-offset-250"}>
                      {this.state.integrations.map((item, index) =>
                        <div key={item}>
                          {(this.state.subNavSelected === 'All' || item.category === this.state.subNavSelected) && (
                            <div className="row">
                              <a className="background-button left-text full-width" href={item.url} target="_blank" rel="noopener noreferrer">
                                <div className="standard-border padding-20">
                                  <div className="row-10">
                                    <div className="fixed-column-80">
                                      <img src={item.imageURL} alt="GC" className="image-70-fit" />
                                    </div>
                                    <div className="calc-column-offset-80">
                                      <p className="heading-text-3 standard-color">{item.name}</p>
                                      <p className="top-padding">{item.description}</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </section>

                {/*Footer()*/}
                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default IntegrationsPage;
