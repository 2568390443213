import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubExternalProfile from './Subcomponents/ExternalProfile';
import withRouter from './Functions/WithRouter';

class AdvProfile extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      // console.log('log them params: ', this.props)
      const { username } = this.props.params

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let isAdvisor = localStorage.getItem('isAdvisor');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      if (isAdvisor === 'false') {
        isAdvisor = false
      }

      let accept = false
      if (window.location.pathname.includes('/accept')) {
        accept = true
      }

      let matchScore = null
      if (this.props.location && this.props.location.state) {
        matchScore = this.props.location.state.matchScore
      }

      if (email) {

        this.setState({ profileUsername: username, emailId: email, isAdvisor, activeOrg, orgFocus,
          roleName, accept, matchScore, orgLogo
        });

      }
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentProfile ', this.props, prevProps)
      console.log('go deeper: ', this.props.params.username, prevProps.params.username, this.state.profileUsernme)
      if (this.props.params && this.props.params.username !== this.state.profileUsername) {
        console.log('new username selected')
        this.setState({ profileUsername: this.props.params.username })
      }
    }

    render() {

      return (
          <div>
            {(this.state.source === 'Student' || window.location.pathname.includes('/app')) ? (
              <AppNavigation username={this.state.username} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} history={this.props.navigate} />
            ) : (
              <AppNavigation username={this.state.username} fromAdvisor={this.state.isAdvisor} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
            )}

            <SubExternalProfile username={this.state.profileUsername} history={this.props.navigate} roleName={this.state.roleName} pageSource="portal" matchScore={this.state.matchScore} accept={this.state.accept} />

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>
      )
    }
}

export default withRouter(AdvProfile)
