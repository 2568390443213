import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import SubEditPosting from './EditPosting';
import SubEditPlacement from './EditPlacement';
import SubCustomDropdown from '../Common/CustomDropdown';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const noEditIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/no-edit-icon.png';
const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const linkIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/link-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const eventIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/event-icon-blue.png';
const assignmentsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assignments-icon-blue.png';
const problemIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/problem-icon-blue.png';
const challengeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/challenge-icon-blue.png';
const internIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/intern-icon-blue.png';
const opportunitiesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-blue.png';
const programIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
// const moneyIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-blue.png';
const hideIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon.png";
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Postings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            postLimit: 100,

            pageNumber: 1,
            resLimit: 100,
            pageCountStart: 0,
            pageCountEnd: 100,
            postingCount: 0,

            emailId: null,
            username: '',
            org: '',

            postTypeOptions: ['All','Event','Project','Work'],
            functionOptions: [],
            industryOptions: [],
            pathwayOptions: [],
            applicationOptions: ['All','At Least One'],
            readyForNewRoundOptions: ['All','Yes'],
            locationOptions: [],
            departmentOptions: [],
            employerFeedbackOptions: ['All','Employer Feedback Has Been Given','Employer Feedback Has Not Been Given'],
            submissionDeadlineOptions: ['All','Upcoming','Passed'],
            sortOptions: ['','Ascending','Descending'],
            postedByOptions: ['Anyone','Me'],
            statusOptions: ['All','Active','Inactive','Archived'],

            postings: [],
            filteredPostings: [],

            searchString: '',
            postedBy: 'Anyone',
            postType: 'All',
            status: 'All',
            workFunction: 'All',
            industry: 'All',
            applications: 'All',
            readyForNewRound: 'All',
            location: 'All',
            department: 'All',
            submissionDeadline: 'All'

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullPostings = this.pullPostings.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.renderPostings = this.renderPostings.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderSearchResults = this.renderSearchResults.bind(this)
        this.filterPostings = this.filterPostings.bind(this)
        this.sortPostings = this.sortPostings.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

        this.movePage = this.movePage.bind(this)
    }

    static defaultProps = { source: null, path: null, activeOrg: null, accountCode: null }

    componentDidMount() {

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t1')
        this.retrieveData()
      } else {
        console.log('t0')
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      let placementPartners = localStorage.getItem('placementPartners');

      if (email) {

        let postTypeOptions = ['All','Event','Project','Work']
        // if (org === 'c2c' || org === 'exp') {
        //   postTypeOptions.push('Scholarship')
        // } else if (!org || org === '' || org === 'guidedcompass') {
        //   postTypeOptions = ['All','Event','Assignment','Problem','Challenge','Work']
        // }

        const menuOptions = [
          { icon: exchangeIconDark, title: 'Duplicate posting', shorthand: 'duplicate' },
          { icon: linkIconDark, title: 'Copy link for CS', shorthand: 'copy' },
          { icon: editIconDark, title: 'Edit posting', shorthand: 'edit' },
          { icon: hideIcon, title: 'Hide posting', shorthand: 'hide', altTitle: 'Unhide posting' },
          { icon: careerMatchesIconDark, title: 'Mark placed', shorthand: 'place' },
          { icon: deleteIconDark, title: 'Delete posting', shorthand: 'delete' },
        ]

        this.setState({ org, emailId: email, username, orgFocus, postTypeOptions, roleName, placementPartners, accountCode, menuOptions })

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let locationOptions = []
            if (response.data.orgInfo.locationOptions) {
              locationOptions = response.data.orgInfo.locationOptions
            }

            let departmentOptions = []
            if (response.data.orgInfo.departments) {
              for (let i = 1; i <= response.data.orgInfo.departments.length; i++) {
                departmentOptions.push(response.data.orgInfo.departments[i - 1].name)
              }
            }

            locationOptions.unshift('All')
            departmentOptions.unshift('All')

            this.setState({ locationOptions, departmentOptions })

          } else {
            console.log('org info query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });

        let onlyProjects = false
        if (window.location.pathname.includes('/problem-platform')) {
          onlyProjects = true
        }
        console.log('about to query: ', org, accountCode, onlyProjects)
        //org + problem platform

        if (org || window.location.pathname.includes('/problem-platform')) {

          const pullPartnerPosts = true
          let postTypes = null
          if (this.props.postTypes) {
            postTypes = this.props.postTypes
          }

          Axios.get('/api/postings/count', { params: { orgCode: org, placementPartners, accountCode, onlyProjects, pullPartnerPosts, postTypes, roleName }})
          .then((response) => {
            console.log('Sub postings query attempted now', response.data);

            if (response.data.success) {
              console.log('posted postings query worked')

              this.setState({ postingCount: response.data.postingCount });

            } else {
              console.log('posted postings query did not work', response.data.message)
            }

          }).catch((error) => {
              console.log('Posted postings query did not work for some reason', error);
          });

          this.pullPostings(org, placementPartners, accountCode, onlyProjects, roleName,this.state.pageNumber)

        }

        if (!org && !window.location.pathname.includes('/problem-platform')) {
          console.log('show values: ', org, window.location.pathname.includes('/problem-platform'))

        }

        Axios.get('/api/workoptions')
        .then((response) => {
          console.log('Work options query tried', response.data);

          if (response.data.success) {
            console.log('Work options query succeeded')

            let functionOptions = response.data.workOptions[0].functionOptions
            functionOptions[0] = 'All'

            let industryOptions = response.data.workOptions[0].industryOptions
            industryOptions[0] = 'All'

            this.setState({
              functionOptions, industryOptions,
              hoursPerWeekOptions: response.data.workOptions[0].hoursPerWeekOptions,
              hourlyPayRangeOptions: response.data.workOptions[0].hourlyPayOptions,
              annualPayRangeOptions: response.data.workOptions[0].annualPayOptions,
              countOptions: response.data.workOptions[0].employeeCountOptions,
              growthOptions: response.data.workOptions[0].employeeGrowthOptions,
            })

            if (org === 'c2c') {
              //pull general benchmarks as backup
              Axios.get('/api/benchmarks', { params: { orgCode: org } })
              .then((response) => {
                console.log('Benchmarks query attempted', response.data);

                  if (response.data.success) {
                    console.log('benchmark query worked')

                    if (response.data.benchmarks.length !== 0) {

                      let benchmarkOptions = response.data.benchmarks
                      if (org === 'c2c') {
                        functionOptions = ['All']
                        for (let i = 1; i <= benchmarkOptions.length; i++) {
                          if (!benchmarkOptions[i - 1].title.includes('Scholarship') && !benchmarkOptions[i - 1].title.includes('C2C')) {
                            if (!functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                              functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                            }

                          }
                        }
                      }

                      this.setState({ functionOptions })
                    }
                  }
              })
            }
          }
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    movePage(forward) {
      console.log('movePage called', forward, this.state.pageNumber)

      let pageNumber = this.state.pageNumber
      if (forward) {
        pageNumber = pageNumber + 1
      } else {
        pageNumber = pageNumber - 1
      }

      console.log('my pagenumber: ', pageNumber)
      let onlyProjects = false
      if (window.location.pathname.includes('/problem-platform')) {
        onlyProjects = true
      }

      this.pullPostings(this.state.org, this.state.placementPartners, this.state.accountCode, onlyProjects, this.state.roleName,pageNumber)

    }

    pullPostings(orgCode, placementPartners, accountCode, onlyProjects, roleName, pageNumber) {
      console.log('pullPostings called: ', orgCode, placementPartners, accountCode, onlyProjects, roleName, pageNumber)

      const pullPartnerPosts = true
      let postTypes = null
      if (this.props.postTypes) {
        postTypes = this.props.postTypes
      }

      const resLimit = this.state.postLimit
      const skip = (pageNumber - 1) * this.state.postLimit

      console.log('show me skip: ', skip, resLimit, pageNumber)

      Axios.get('/api/postings/user', { params: { orgCode, placementPartners, accountCode, onlyProjects, pullPartnerPosts, postTypes, roleName, resLimit, skip }})
      .then((response) => {
        console.log('Sub postings query attempted now', response.data);

        if (response.data.success) {
          console.log('posted postings query worked')

          if (response.data.postings && response.data.postings.length !== 0) {

            let postings = response.data.postings
            const filteredPostings = postings

            let pageCountStart = ((pageNumber - 1) * resLimit) + 1

            let pageCountEnd = postings.length
            if (pageNumber > 1) {
              pageCountEnd = ((pageNumber - 1) * resLimit) + postings.length
            }
            console.log('show pages: ', pageCountStart, pageCountEnd, skip, resLimit, pageNumber)

            this.setState({ postings, filteredPostings, pageCountStart, pageCountEnd, pageNumber });
          }

        } else {
          console.log('posted postings query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Posted postings query did not work for some reason', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler clicked');

      if (event.target.name === 'eventDate') {

        const sortCriteria = { name: event.target.name, shortname: event.target.value }
        this.setState({ [event.target.name]: event.target.value, sortCriteria })
        this.sortPostings(event.target.name, event.target.value)

      } else if (event.target.name === 'sortSubmissionDeadline') {

        const sortCriteria = { name: 'submissionDeadline', shortname: event.target.value }
        this.setState({ submissionDeadline: event.target.value, sortCriteria })
        this.sortPostings('submissionDeadline', event.target.value)

      } else {

        const filterCriteria = { name: event.target.name, shortname: event.target.value }
        this.setState({ [event.target.name]: event.target.value, filterCriteria })

        if (event.target.name === 'searchString') {
          this.renderSearchResults(event.target.value)
        } else {
          // filter
          this.filterPostings(event.target.name, event.target.value)
        }
      }
    }

    filterPostings(name, value) {
      console.log('filterPostings called', name, value)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      const searchString = null
      const filterString = null
      // const filters = [{ name: 'Employer Feedback', value: value, options: this.state.employerFeedbackOptions }]
      const filters = [{ name: name, value: value, options: null }]

      const defaultFilterOption = 'All'
      const index = 0
      const search = null
      const postings = this.state.postings
      const type = null
      const emailId = this.state.emailId
      const orgCode = this.state.org
      const roleName = this.state.roleName

      Axios.put('/api/postings/filter', {  searchString, filterString, filters, defaultFilterOption, index, search, postings, type, emailId, orgCode, roleName })
      .then((response) => {
        console.log('Posting filter query attempted', response.data);

          if (response.data.success) {
            console.log('posting filter query worked')

            // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)

            const filteredPostings = response.data.postings
            let filtered = true
            if (filteredPostings.length === this.state.postingCount) {
              filtered = false
            }
            this.setState({ filteredPostings, filtered, isLoading: false })

          } else {
            console.log('posting filter query did not work', response.data.message)
            this.setState({ isLoading: false, errorMessage: response.data.message, filtered: false, filteredPostings: [] })

          }

      }).catch((error) => {
          console.log('Posting filter query did not work for some reason', error);
          this.setState({ isLoading: false, errorMessage: error.toString() })

      });
    }

    sortPostings(name, value) {
      console.log('sortPostings called', name, value)

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      let sortName = 'No Sort Applied'
      let sortString = null
      if (value) {
        sortName = name
        sortString = value
      }

      const defaultFilterOption = 'All'
      const index = 0
      const search = null
      const postings = this.state.postings
      const type = null
      const emailId = this.state.emailId
      const orgCode = this.state.org
      const roleName = this.state.roleName

      Axios.put('/api/postings/sort', {  sortName, sortString, defaultFilterOption, index, search, postings, type, emailId, orgCode, roleName })
      .then((response) => {
        console.log('Posting sort query attempted', response.data);

          if (response.data.success) {
            console.log('posting sort query worked')

            // console.log('second matchScore: ', response.data.postings[0].matchScore, response.data.postings[0].title, response.data.postings[0].name)

            const filteredPostings = response.data.postings
            let filtered = true
            if (filteredPostings.length === this.state.postingCount) {
              filtered = false
            }
            this.setState({ isLoading: false, filtered, filteredPostings })

          } else {
            console.log('posting sort query did not work', response.data.message)
            this.setState({ isLoading: false, errorMessage: response.data.message, filtered: false, filteredPostings: [] })
          }

      }).catch((error) => {
          console.log('Posting sort query did not work for some reason', error);
          this.setState({ isLoading: false, errorMessage: error.toString() })
      });
    }

    renderSearchResults(searchString) {
      console.log('renderSearchResults called')

      this.setState({ isLoading: true, errorMessage: null, successMessage: null })

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.org
        const placementPartners = self.state.placementPartners
        const accountCode = self.state.accountCode
        const search = true
        let postTypes = null
        if (self.props.postTypes) {
          postTypes = self.props.postTypes
        }

        const resLimit = self.state.postLimit
        const pullPartnerPosts = true

        Axios.get('/api/postings/search', { params: { searchString, orgCode, placementPartners, accountCode, search, postTypes, resLimit, pullPartnerPosts } })
        .then((response) => {
          console.log('Opportunity search query attempted', response.data);

            if (response.data.success && response.data.postings) {
              console.log('opportunity search query worked')

              let filteredPostings = response.data.postings
              let filtered = true
              if (filteredPostings.length === self.state.postingCount) {
                filtered = false
              }
              self.setState({ filteredPostings, filtered, isLoading: false })

            } else {
              console.log('opportunity search query did not work', response.data.message)

              let filteredPostings = []
              self.setState({ filteredPostings, filtered: false, isLoading: false })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ isLoading: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    renderPostings() {
      console.log('renderPostings called')

      let rows = [];
      for (let i = 1; i <= this.state.filteredPostings.length; i++) {
        // console.log(i);

        const index = i - 1

        let postType = this.state.filteredPostings[i - 1].postType
        let imgSrc = internIconBlue
        let headerText = ''
        let endingText = ''

        if (this.state.filteredPostings[i - 1].postType === 'Internship' || this.state.filteredPostings[i - 1].postType === 'Individual' || this.state.filteredPostings[i - 1].postType === 'Track' || this.state.filteredPostings[i - 1].postType === 'Work') {
          headerText = this.state.filteredPostings[i - 1].title
          if (this.state.filteredPostings[i - 1].employerName && this.state.filteredPostings[i - 1].employerName !== 'No Employers Saved') {
            headerText = headerText + " | " + this.state.filteredPostings[i - 1].employerName
          }

          if (this.state.filteredPostings[i - 1].postType === 'Work') {
            postType = 'Work'
            imgSrc = opportunitiesIconBlue
          } else {
            postType = 'Internship'
          }

          if (!window.location.pathname.includes('/employers')) {
            if (this.state.filteredPostings[i - 1].applicants) {
              endingText = endingText + ' | ' + this.state.filteredPostings[i - 1].applicants.length + ' Applications'
            }
          }

          if (this.state.filteredPostings[i - 1].submissionDeadline) {
            endingText = endingText + ' | Due ' + convertDateToString(new Date(this.state.filteredPostings[i - 1].submissionDeadline),'date-2')
          }

        } else if (this.state.filteredPostings[i - 1].postType === 'Program') {
          headerText = this.state.filteredPostings[i - 1].title
          if (this.state.filteredPostings[i - 1].employerName && this.state.filteredPostings[i - 1].employerName !== 'No Employers Saved' && this.state.filteredPostings[i - 1].employerName !== 'No Employer Selected') {
            headerText = headerText + " | " + this.state.filteredPostings[i - 1].employerName
          }

          postType = this.state.filteredPostings[i - 1].postType
          imgSrc = programIconBlue

          if (!window.location.pathname.includes('/employers')) {
            if (this.state.filteredPostings[i - 1].applicants) {
              endingText = endingText + ' | ' + this.state.filteredPostings[i - 1].applicants.length + ' Applications'
            }
          }

          if (this.state.filteredPostings[i - 1].submissionDeadline) {
            endingText = endingText + ' | Due ' + convertDateToString(new Date(this.state.filteredPostings[i - 1].submissionDeadline),'date-2')
          }
        } else if (this.state.filteredPostings[i - 1].postType === 'Event') {
          headerText = this.state.filteredPostings[i - 1].title
          if (this.state.filteredPostings[i - 1].employerName && this.state.filteredPostings[i - 1].employerName !== 'No Employers Saved') {
            headerText = headerText + " | " + this.state.filteredPostings[i - 1].employerName
          }

          imgSrc = eventIconBlue

          if (this.state.filteredPostings[i - 1].rsvps) {
            endingText = endingText + ' | ' + this.state.filteredPostings[i - 1].rsvps.length + ' RSVPs'
          }
          if (this.state.filteredPostings[i - 1].startDate) {

            const startYear = new Date(this.state.filteredPostings[i - 1].startDate).getFullYear()
            const startMonth = new Date(this.state.filteredPostings[i - 1].startDate).getMonth()
            const startDay = new Date(this.state.filteredPostings[i - 1].startDate).getDate()

            const formattedStartMonth = convertDateToString(startMonth,'formatMonth')
            console.log('show formattedStartMonth: ', formattedStartMonth)
            endingText = endingText + ' |  On ' + formattedStartMonth + ' ' + startDay + ', ' + startYear
          }

        } else if (this.state.filteredPostings[i - 1].postType === 'Project' || this.state.filteredPostings[i - 1].postType === 'Assignment' || this.state.filteredPostings[i - 1].postType === 'Problem' || this.state.filteredPostings[i - 1].postType === 'Challenge') {


          headerText = this.state.filteredPostings[i - 1].name
          if (this.state.filteredPostings[i - 1].employerName && this.state.filteredPostings[i - 1].employerName !== 'No Employers Saved' && this.state.filteredPostings[i - 1].employerName !== 'No Employer Selected') {
            headerText = headerText + " | " + this.state.filteredPostings[i - 1].employerName
          }

          endingText = ' | ' +this.state.filteredPostings[i - 1].submissions.length + ' Submissons'

          if (this.state.filteredPostings[i - 1].postType === 'Assignment' || this.state.filteredPostings[i - 1].projectPromptType === 'Assignment') {
            imgSrc = assignmentsIconBlue
          } else if (this.state.filteredPostings[i - 1].postType === 'Problem' || this.state.filteredPostings[i - 1].projectPromptType === 'Problem') {
            imgSrc = problemIconBlue
          } else if (this.state.filteredPostings[i - 1].postType === 'Challenge' || this.state.filteredPostings[i - 1].projectPromptType === 'Challenge') {
            imgSrc = challengeIconBlue
          }
        }

        if (this.state.filteredPostings[i - 1].imageURL) {
          imgSrc = this.state.filteredPostings[i - 1].imageURL
        }

        //this works for both admin and employers
        let pathname = this.props.path + '/' + this.state.filteredPostings[i - 1]._id
        // console.log('show me props path: ', this.props.path, pathname)

        let widthStartingValue = 160
        let offsetStartingValue = widthStartingValue - 10

        let midClass = "calc-column-offset-" + offsetStartingValue

        if (this.state.filteredPostings[i - 1].isActive === false || this.state.filteredPostings[i - 1].orgCode !== this.state.org) {
          widthStartingValue = widthStartingValue + 40
          offsetStartingValue = offsetStartingValue + 40

          midClass = "calc-column-offset-" + (offsetStartingValue + 10)

          // if (this.state.filteredPostings[i - 1].placementPartners && this.state.filteredPostings[i - 1].placementPartners.includes(this.state.org) && this.state.filteredPostings[i - 1].isActive !== false) {
          //   widthStartingValue = widthStartingValue + 40
          //   offsetStartingValue = offsetStartingValue + 40
          //
          //   midClass = "calc-column-offset-" + (offsetStartingValue + 10)
          // }
        }

        // if (this.state.filteredPostings[i - 1].placementPartners && this.state.filteredPostings[i - 1].placementPartners.includes(this.state.org) && this.state.filteredPostings[i - 1].isActive !== false) {
        //   widthStartingValue = widthStartingValue + 40
        //   offsetStartingValue = offsetStartingValue + 40
        //
        //   midClass = "calc-column-offset-" + (offsetStartingValue + 10)
        // }

        rows.push(
          <div key={i}>
              <div>
                {(window.location.pathname.includes('/problem-platform')) ? (
                  <button type="button" className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showEditPosting: true, selectedOpportunity: this.state.filteredPostings[i - 1] })}>
                    <span className="fixed-column-60">
                      <img src={imgSrc} alt="GC" className="image-50-fit top-margin-5 center-horizontally"/>
                    </span>
                    <span className={midClass + " left-padding"}>
                      <div className="full-width">
                        <p className="heading-text-5">{headerText}</p>
                      </div>

                      <div className="half-spacer"/>
                      <p className="description-text-color">Created {convertDateToString(new Date(this.state.filteredPostings[i - 1].createdAt),'date-2')} | {postType}{endingText}</p>
                    </span>
                    {(this.state.filteredPostings[i - 1].isActive === false) ? (
                      <div className="fixed-column-30 right-margin">
                        <div className="spacer" />
                        <img src={hideIcon} alt="Compass arrow indicator icon" className="image-auto-25"/>

                        {(this.state.filteredPostings[i - 1].orgCode !== this.state.org) && (
                          <div className="top-margin">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        )}

                      </div>
                    ) : (
                      <div>
                        {console.log('compare them: ', this.state.filteredPostings[i - 1].orgCOde, this.state.org)}
                        {(this.state.filteredPostings[i - 1].orgCode !== this.state.org) && (
                          <div className="fixed-column-30 right-margin">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="fixed-column-22">
                      <div className="spacer" />
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="arrow-indicator-icon"/>
                    </div>
                  </button>
                ) : (
                  <Link className="secret-link full-width hover-container-left" to={pathname} state={{ selectedOpportunity: this.state.filteredPostings[i - 1], jobs: this.state.postings }}>
                    <span className="fixed-column-70">
                      <img src={imgSrc} alt="GC" className="image-50-fit top-margin-5 center-horizontally"/>
                    </span>
                    <span className={midClass + " left-padding"}>
                      <div className="full-width">
                        <p className="heading-text-5">{headerText}</p>
                      </div>

                      <div className="half-spacer"/>
                      <p className="description-text-1 description-text-color">Created {convertDateToString(new Date(this.state.filteredPostings[i - 1].createdAt),'date-2')} | {postType}{endingText}</p>
                    </span>

                    {(this.state.filteredPostings[i - 1].isActive === false) ? (
                      <div className="fixed-column-30 right-margin">
                        <div className="spacer" />
                        <img src={hideIcon} alt="Compass arrow indicator icon" className="image-auto-25"/>

                        {(this.state.filteredPostings[i - 1].orgCode !== this.state.org) && (
                          <div className="top-margin">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        )}

                      </div>
                    ) : (
                      <div>
                        {console.log('compare them: ', this.state.filteredPostings[i - 1].orgCOde, this.state.org)}
                        {(this.state.filteredPostings[i - 1].orgCode !== this.state.org) && (
                          <div className="fixed-column-30 right-margin">
                            <img src={noEditIcon} alt="GC" className="image-auto-22" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="This entry can't be edited because it is being shared from a different organization"/>
                          </div>
                        )}
                      </div>
                    )}
                    {/*
                    {(this.state.filteredPostings[i - 1].placementPartners && this.state.filteredPostings[i - 1].placementPartners.includes(this.state.org)) && (
                      <div className="fixed-column-30 right-margin">
                        <p className="description-text-6 wrap-word">{this.state.filteredPostings[i - 1].orgCode}</p>
                        <img src={partnerIconDark} alt="Compass arrow indicator icon" className="image-auto-25"/>
                      </div>
                    )}*/}

                    {(window.location.pathname.includes('/employers')) ? (
                      <div className="fixed-column-50 height-40" />
                    ) : (
                      <div className="fixed-column-50 left-padding top-margin-5 relative-position">
                        <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                          <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                        </button>

                        {(this.state.filteredPostings[i - 1].showMenu) && (
                          <div className="menu-bottom-right top-margin">
                            {this.state.menuOptions.map((item,optionIndex) =>
                              <div key={item.title}>
                                {((item.title.includes('Delete') && this.state.filteredPostings[i - 1].orgCode === this.state.org) || !item.title.includes('Delete')) && (
                                  <div>
                                    {((item.title === 'Mark placed' && !this.state.filteredPostings[i - 1].placed && (this.state.filteredPostings[i - 1].postType === 'Work' || this.state.filteredPostings[i - 1].postType === 'Internship')) || item.title !== 'Mark placed') && (
                                      <div>
                                        {(item.title === 'Delete posting' && this.state.filteredPostings[i - 1].confirmDelete) ? (
                                          <div>
                                            <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                            <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(i - 1)}>Confirm</button>
                                            <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>Cancel</button>
                                          </div>
                                        ) : (
                                          <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>
                                            <div className="fixed-column-25">
                                              <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                            </div>
                                            <div className="calc-column-offset-25">
                                              {(item.title === 'Hide posting' && !this.state.filteredPostings[i - 1].isActive) ? (
                                                <p className="description-text-2">{item.altTitle}</p>
                                              ) : (
                                                <p className={(item.title === 'Delete posting') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                              )}
                                            </div>
                                            <div className="clear" />
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="fixed-column-22">
                      <div className="spacer" />
                      <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="arrow-indicator-icon"/>
                    </div>
                    <div className="clear" />

                  </Link>
                )}

                {(this.state.filteredPostings[i - 1].successMessage) && (
                  <div className="row-5 left-padding">
                    <p className="description-text-2 cta-color full-width">{this.state.filteredPostings[i - 1].successMessage}</p>
                  </div>
                )}

                {(this.state.sortCriteria) && (
                  <div className="row-5 left-padding">
                    {/*<p className="description-text-2 error-color full-width">{this.state.sortCriteria.name}: {this.state.filteredPostings[i - 1][this.state.sortCriteria.shortname]}</p>*/}
                    <p className="description-text-2 error-color full-width">{this.state.sortCriteria.name}: {this.state.sortCriteria.shortname}</p>
                  </div>
                )}

                {(this.state.filterCriteria) && (
                  <div className="row-5 left-padding">
                    {/*<p className="description-text-2 error-color full-width">{this.state.filterCriteria.name}: {(typeof this.state.filteredPostings[i - 1][this.state.filterCriteria.shortname] === 'object') ? JSON.stringify(this.state.filteredPostings[i - 1][this.state.filterCriteria.shortname]) : this.state.filteredPostings[i - 1][this.state.filterCriteria.shortname]}</p>*/}
                    <p className="description-text-2 error-color full-width">{this.state.filterCriteria.name}: {this.state.filterCriteria.shortname}</p>
                  </div>
                )}

              </div>

              <div className="row-15">
                <hr />
              </div>

          </div>
        )
      }

      return rows;
    }

    closeModal() {
      this.setState({ modalIsOpen: false, showEditPosting: false, confirmFill: false });
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      e.stopPropagation()
      e.preventDefault()

      let filteredPostings = this.state.filteredPostings
      if (this.state.filteredPostings[index].showMenu) {
        filteredPostings[index]['showMenu'] = false
      } else {
        filteredPostings[index]['showMenu'] = true
      }

      this.setState({ filteredPostings })

    }

    menuButtonClicked(e, action, index) {
      console.log('menuButtonClicked called', action, index)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'duplicate') {
        let selectedOpportunity = this.state.filteredPostings[index]
        this.props.navigate(this.props.path + '/add', { state: { jobs: this.state.postings, selectedOpportunity, duplicate: true }})
      } else if (action === 'copy') {
        navigator.clipboard.writeText('https://www.guidedcompass.com/app/opportunities/' + this.state.filteredPostings[index]._id)
        let filteredPostings = this.state.filteredPostings
        filteredPostings[index]['showMenu'] = false
        filteredPostings[index]['successMessage'] = 'Link has been successfully copied. Send to it to career-seekers!'
        this.setState({ filteredPostings })
      } else if (action === 'edit') {
        this.props.navigate(this.props.path + '/add', { state: { jobs: this.state.postings, selectedOpportunity: this.state.filteredPostings[index] }})
      } else if (action === 'hide') {

        const _id = this.state.filteredPostings[index]._id
        let isActive = null
        if (this.state.filteredPostings[index].isActive) {
          isActive = false
        } else {
          isActive = true
        }

        const updatedAt = new Date()

        Axios.post('/api/postings', {
          _id, isActive, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Post save worked', response.data);

            let filteredPostings = this.state.filteredPostings
            filteredPostings[index]['successMessage'] = 'Posting updated successfully!'
            filteredPostings[index]['isActive'] = isActive
            filteredPostings[index]['showMenu'] = false
            this.setState({ filteredPostings, isSaving: false })

          } else {
            console.error('there was an error posting the posting');

            let filteredPostings = this.state.filteredPostings
            filteredPostings[index]['errorMessage'] = response.data.message
            this.setState({ filteredPostings, isSaving: false })
          }
        }).catch((error) => {
            console.log('The posting save did not work', error);
            let filteredPostings = this.state.filteredPostings
            filteredPostings[index]['errorMessage'] = error.toString()
            this.setState({ filteredPostings, isSaving: false })
        });
      } else if (action === 'place') {
        this.setState({ modalIsOpen: true, confirmFill: true, selectedPosting: this.state.filteredPostings[index] })
      } else if (action === 'delete') {
        let filteredPostings = this.state.filteredPostings
        if (filteredPostings[index].confirmDelete) {
          filteredPostings[index]['confirmDelete'] = false
        } else {
          filteredPostings[index]['confirmDelete'] = true
        }
        this.setState({ filteredPostings })

      }
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      let filteredPostings = this.state.filteredPostings
      const _id = filteredPostings[index]._id
      const posting = filteredPostings[index]

      Axios.delete('/api/postings/' + _id, { data: { posting }})
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Post delete worked')

          filteredPostings.splice(index,1)
          this.setState({ filteredPostings, isSaving: false })

        } else {
          console.error('there was an error deleting the posting');

          filteredPostings[index]['errorMessage'] = response.data.message
          this.setState({ filteredPostings, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          filteredPostings[index]['errorMessage'] = error.toString()
          this.setState({ filteredPostings, isSaving: false })
      });
    }

    render() {

      let defaultClassName = "floating-container card top-margin-2-percent right-margin-2-percent"
      let defaultStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform}
      let paginationClassName = "floating-container left-margin-2-percent"
      let topPaddingClass = ""
      if (window.location.pathname.includes('/walkthrough')) {
        defaultClassName = {}
        defaultStyle = {}
      } else if (window.location.pathname.includes('/employers') || (toggleVars.hideSideNav && window.location.pathname.includes('/advisor'))) {
        defaultClassName = "standard-container-2"
        defaultStyle = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        topPaddingClass = "top-padding-20"
      } else if (window.location.pathname.includes('/organizations') && toggleVars.hideAdminSideNav) {
        defaultClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card"
        defaultStyle = {}
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
        topPaddingClass = ""
      }

      return (
          <div>
            {(this.state.postings) ? (
              <div>
                {(!window.location.pathname.includes('/walkthrough')) && (
                  <div>
                    <div className={defaultClassName} style={defaultStyle}>
                      <div className={topPaddingClass}>
                        <div className="calc-column-offset-65 right-padding">
                          {(window.location.pathname.includes('/problem-platform')) ? (
                            <p className="heading-text-3">{this.state.postingCount} Project Postings</p>
                          ) : (
                            <div>
                              <p className="heading-text-2">{this.state.postingCount} Postings</p>
                            </div>
                          )}
                        </div>

                        <div className="fixed-column-40">
                          {(window.location.pathname.includes('/problem-platform')) ? (
                            <button type="button" className="background-button clear-padding clear-margin" onClick={() => this.setState({ modalIsOpen: true, showEditPosting: true, selectedOpportunity: null })}>
                              <img src={addIcon} alt="GC" className="image-25-fit"/>
                            </button>
                          ) : (
                            <Link to={this.props.path + '/add'} state={{ jobs: this.state.postings }}>
                              <img src={addIcon} alt="GC" className="image-25-fit"/>
                            </Link>
                          )}
                        </div>
                        <div className={"fixed-column-25"}>
                          {(this.state.showFilters) ? (
                            <button type="button" className="background-button clear-padding clear-margin" onClick={() => this.setState({ showFilters: false })}>
                              <img src={hideIcon} alt="GC" className="image-25-fit"/>
                            </button>
                          ) : (
                            <button type="button" className="background-button clear-padding clear-margin" onClick={() => this.setState({ showFilters: true })}>
                              <img src={searchIcon} alt="GC" className="image-25-fit"/>
                            </button>
                          )}
                        </div>
                        <div className="clear" />
                      </div>

                      <p className="description-text-2 row-5 standard-color">Create and edit event, project, and work opportunity postings from staff and employer partners</p>
                    </div>
                    {/*
                    <hr className="cell-separator-advisor"/>
                    */}
                    {(this.state.showFilters) && (
                      <div className={defaultClassName} style={defaultStyle}>
                        {(!window.location.pathname.includes('/problem-platform')) && (
                          <div>
                            <div className="standard-border display-inline full-width float-left row-3 left-padding-5 right-padding-5">
                              <div className="search-icon-container">
                                <img src={searchIcon} alt="GC" className="image-auto-20"/>
                              </div>
                              <div className="filter-search-container-2">
                                <input type="text" className="text-field clear-border" placeholder="Search by name..." name="searchString" value={this.state.searchString} onChange={this.formChangeHandler}/>
                              </div>
                            </div>
                            <div className="advisor-clearing-container" />
                            <div className="spacer" />
                            <hr />

                            <div className="top-padding-15">
                              <p>Filter</p>
                            </div>

                            <SubCustomDropdown dropdownTitle="Posted by" name="postedBy" value={this.state.postedBy} options={this.state.postedByOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                            <SubCustomDropdown dropdownTitle="Post Type" name="postType" value={this.state.postType} options={this.state.postTypeOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                            <SubCustomDropdown dropdownTitle="Status" name="status" value={this.state.status} options={this.state.statusOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                            <SubCustomDropdown dropdownTitle="Work Function" name="workFunction" value={this.state.workFunction} options={this.state.functionOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>

                            {(this.props.source !== 'Employer') && (
                              <div>
                                <SubCustomDropdown dropdownTitle="Industry" name="industry" value={this.state.industry} options={this.state.industryOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                <SubCustomDropdown dropdownTitle="Applications" name="applications" value={this.state.applications} options={this.state.applicationOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                <SubCustomDropdown dropdownTitle="Ready for New Round" name="readyForNewRound" value={this.state.readyForNewRound} options={this.state.readyForNewRoundOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                <SubCustomDropdown dropdownTitle="Department" name="department" value={this.state.department} options={this.state.departmentOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                                {/*<SubCustomDropdown dropdownTitle="Employer Feedback" name="employerFeedback" value={this.state.employerFeedback} options={this.state.employerFeedbackOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>*/}
                                <SubCustomDropdown dropdownTitle="Application Deadline" name="submissionDeadline" value={this.state.submissionDeadline} options={this.state.submissionDeadlineOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                              </div>
                            )}

                            <div className="spacer" />

                            <br/>
                            <hr className="cell-separator-advisor"/>

                            <div className="top-padding-15">
                              <p>Sort</p>
                            </div>

                            <div className="spacer" />

                            <SubCustomDropdown dropdownTitle="Event Date" name="eventDate" value={this.state.eventDate} options={this.state.sortOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>
                            <SubCustomDropdown dropdownTitle="Application Deadline" name="sortSubmissionDeadline" value={this.state.submissionDeadline} options={this.state.sortOptions} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler}/>

                            <div className="spacer" />

                            <hr className="cell-separator-advisor"/>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {(this.state.filteredPostings.length > 0 && !this.state.filtered && !window.location.pathname.includes('/walkthrough')) && (
                  <div className={paginationClassName}>
                    <div className="top-margin-2-percent">
                      <div className="relative-column-20">
                        <button className={(this.state.pageNumber === 1) ? "background-button full-width left-text wash-out-2" : "background-button full-width left-text"} disabled={(this.state.pageNumber === 1) ? true : false} onClick={() => this.movePage(false)}>
                          <div className="fixed-column-20">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 rotate-180" />
                          </div>
                          <div className="calc-column-offset-20">
                            <p className="full-width left-text">Previous</p>
                          </div>
                          <div className="clear" />
                        </button>
                      </div>
                      <div className="relative-column-60">
                        <p className="full-width center-text">{this.state.pageCountStart} - {(this.state.pageCountEnd < this.state.postingCount) ? this.state.pageCountEnd : this.state.postingCount} of {this.state.postingCount}</p>
                      </div>
                      <div className="relative-column-20">
                        <button className={(this.state.pageCountEnd === this.state.postingCount) ? "background-button full-width right-text wash-out-2" : "background-button full-width right-text"} disabled={(this.state.pageCountEnd === this.state.postingCount) ? true : false} onClick={() => this.movePage(true)}>
                          <div className="calc-column-offset-20">
                            <p className="full-width right-text">Next</p>
                          </div>
                          <div className="fixed-column-20 right-text">
                            <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                            <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 pin-right" />
                          </div>
                          <div className="clear" />
                        </button>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}

                {(this.state.isLoading) ? (
                  <div className={defaultClassName} style={defaultStyle}>
                    <div className="full-width center-text">
                      <img src={loadingGIF} alt="GC" className="image-auto-80 center-horizontally"/>
                      <div className="spacer" /><div className="spacer"/><div className="spacer"/>
                      <p className="full-width center-text cta-color bold-text">Retrieving info...</p>
                    </div>
                  </div>
                ) : (
                  <div className={defaultClassName} style={defaultStyle}>
                    {this.renderPostings()}
                  </div>
                )}

                <Modal
                 isOpen={this.state.modalIsOpen}
                 onAfterOpen={this.afterOpenModal}
                 onRequestClose={this.closeModal}
                 className="modal"
                 overlayClassName="modal-overlay"
                 contentLabel="Example Modal"
                 ariaHideApp={false}
               >
                  {(this.state.showEditPosting) && (
                    <div key="editPosting" className="full-width padding-20">
                       <SubEditPosting org={this.props.activeOrg} selectedOpportunity={this.state.selectedOpportunity} editMode={true} jobs={this.state.postings} roleName="Admin" path={window.location.pathname} />
                    </div>
                  )}

                 {(this.state.confirmFill) && (
                   <div className="padding-40">
                     <div>
                       <SubEditPlacement
                         closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} inModal={true}
                         activeOrg={this.state.org} accountCode={this.state.accountCode} history={this.props.navigate} fillRole={(this.state.selectedPosting.placed) ? false : true}
                         title={this.state.selectedPosting.title} workType={this.state.selectedPosting.postType} postingId={this.state.selectedPosting._id}

                         description={this.state.selectedPosting.description}
                         hiringManagerFirstName={this.state.selectedPosting.employerContactFirstName}
                         hiringManagerLastName={this.state.selectedPosting.employerContactLastName}
                         hiringManagerEmail={this.state.selectedPosting.employerContactEmail}
                         orgContactEmail={this.state.selectedPosting.orgContactEmail}
                         payType={this.state.selectedPosting.payType} startDate={this.state.selectedPosting.startDate} endDate={this.state.selectedPosting.endDate}
                         isPerpetual={this.state.selectedPosting.isPerpetual} location={this.state.selectedPosting.location}
                         workFunction={this.state.selectedPosting.function} industry={this.state.selectedPosting.industry}
                         pathways={this.state.selectedPosting.pathways} departments={this.state.selectedPosting.departments}
                         hoursPerWeek={this.state.selectedPosting.hoursPerWeek}
                         employerName={this.state.selectedPosting.employerName} employerURL={this.state.selectedPosting.employerURL}
                         placementPartners={this.state.selectedPosting.placementPartners}
                       />
                     </div>

                   </div>
                 )}
               </Modal>
              </div>
            ) : (
              <div>
                  <h2>Welcome to Guided Compass Postings!</h2>
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(Postings);
