import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import TopNavigation from './TopNavigation';

const exampleInternMatch = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/example-intern-match.png";
const purposeDiagram = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/purpose-diagram.png";

class SolutionsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

        this.changeSubNavSelected = this.changeSubNavSelected.bind(this)
        this.renderContent = this.renderContent.bind(this)

    }

    componentDidMount() {
        //see if user is logged in
        console.log('solutions page just mounted')
        window.scrollTo(0, 0)

        let solutionName = ''
        if (window.location.pathname.includes('/transparent-pathways')) {
          solutionName = 'Transparent Career Pathways'
        } else if (window.location.pathname.includes('/self-awareness')) {
          solutionName = 'Self-Awareness'
        } else if (window.location.pathname.includes('/career-exploration')) {
          solutionName = 'Career Awareness & Exploration'
        } else if (window.location.pathname.includes('/career-planning')) {
          solutionName = 'Career Planning'
        } else if (window.location.pathname.includes('/project-based-learning')) {
          solutionName = 'Project-Based Learning'
        } else if (window.location.pathname.includes('/work')) {
          solutionName = 'Work and Work-Based Learning'
        } else if (window.location.pathname.includes('/community')) {
          solutionName = 'Community, Alumni, & Mentorship'
        } else if (window.location.pathname.includes('/learning')) {
          solutionName = 'Lessons, Courses, and Resources'
        } else if (window.location.pathname.includes('/financial-planning')) {
          solutionName = 'Financial Planning'
        } else if (window.location.pathname.includes('/entrepreneurship')) {
          solutionName = 'Entrepreneurship'
        }
        // console.log('asdfsd: ', solutionName)
        this.setState({ solutionName })

    }

    changeSubNavSelected(solutionName) {
      console.log('changeSubNavSelected called')

      this.setState({ solutionName })
    }

    renderContent() {
      console.log('renderContent called ')

      if (this.state.solutionName === 'Transparent Career Pathways') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <p>Benchmarks are the backbone of the Guided Compass platform. Basically, a benchmark defines the ideal candidate profile for a pathway or opportunity. As a career-seeker, benchmarks allow you to develop clear roadmaps to bridget the gap between your profile and the “benchmark” candidate. For example, if you want to become a software developer at Riot Games, a benchmark may allow you to clearly see that you need to be skilled in HTML, CSS, Javascript, and C++ and that it helps to have a passion for video game development.</p>

            <p className="top-padding-30">The initial use case for benchmarks was to help the LA Area Chamber of Commerce match thousands of community college and high school students to a wide variety of local tech internships with limited staff. The challenge was how do we match people at scale who have little to no experience? Today, Guided Compass has default benchmarks for most career pathways and many employers. Workforce, educational programs, and employers can use Guided Compass to create their own custom ideal candidates at the pathway and opportunity levels. When an employer or organization posts an opportunity (e.g., internship) to an organization, a copy of the pathway-level benchmark is made for the specific opportunity so that it can be tweaked for that opportunity.</p>

            <p className="top-padding-30">Learn more about benchmarks in <Link to="/help/what-are-benchmarks">Our Benchmarks Explained Video</Link>. Here’s a screenshot of what it looks like to create a benchmark:</p>

            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OrgEditBenchmarkSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Career-seekers build their profile to match against benchmarks, whether they are general or program-specific. Here’s an example of the matched profile card of a UX Design candidate for example:</p>

            <img src={exampleInternMatch} alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">In fact, in addition to work opportunities, benchmarks can be used to recommend the following to career-seekers:</p>
            <ul className="left-padding-25">
              <li>Program-specific career pathways</li>
              <li>Competency-based courses</li>
              <li>Career events (e.g., job shadows, workshops)</li>
              <li>Projects</li>
              <li>Employers</li>
            </ul>

            <p className="top-padding-30">As a career-seeker, you can also view all of your top matches at once, by clicking “My Top Matches” in the dropdown under your profile:</p>

            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/ClickMyTopMatchesSS.png" alt="GC" className="image-full-auto top-margin" />
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/MyTopMatchesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">As a career-seeker, while exploring opportunities, career paths, peers, mentors, etc... , you can calculate your matches by clicking the “Match Icon” on the top-left of the screen. Below a screenshot of the top opportunity matches, for example:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OpportunityMatchesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Ultimately, the goal of benchmarks is to allow career-seekers and the people that help them to have a common language and goal post. A candidate can prepare their profile over years of schooling and projects, and then import their profile to apply to opportunities. Intermediary training organizations can view scored candidates, add internally-conducted interview scores, and refer candidates to work opportunities. Employers can review candidates, provide feedback, and hire candidates all in one system. Guided Compass even has timesheet functionality to capture weekly feedback once hired. Feedback collected from all parties informs everyone of their performance, further clarifies the benchmarks, and strengthens the Guided Compass recommendation algorithm.</p>

            <p className="top-padding-30">Career-seekers benefit from the continuously improved default benchmarks by joining for free <Link to="/join">here</Link>. Organizations may create custom benchmarks for pathways after purchasing more than 100 career-seeker licenses. <Link to="/pricing">Click here</Link> for pricing information.</p>


          </div>
        )
      } else if (this.state.solutionName === 'Self-Awareness') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <div>
              <p>Zen out with Guided Compass. Guided Compass provides a number of tools to “Know Thyself”. Most notably to help:</p>
              <ol className="left-padding-25">
                <li>Career assessments</li>
                <li>Competency endorsements</li>
                <li>Favoriting</li>
                <li>Career logs</li>
                <li>Counseling Detail Page</li>
              </ol>
            </div>

            <p className="top-padding-30 heading-text-5">Career Assessments</p>
            <p>For career assessments, Guided Compass provides interest, values, personality, work preferences, and skill assessments in-house. You will receive your score and detailed information as soon as you are done.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CareerAssessmentResultsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30 heading-text-5">Competency Endorsements</p>
            <p>Career-seekers can request competency endorsements from teachers, mentors, and other professionals. These endorsements allow you to get specific ratings on the competencies relevant to your career path, as well as examples of how you demonstrated those competencies.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EndorsementExampleSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30 heading-text-5">Favoriting</p>
            <p>After you’ve taken a few career assessments, you’re ready to start viewing your top matches and favoriting some of the results. The process of favoriting career paths, employers, and opportunities ultimately allows you to build a self-awareness around what types of things you are drawn to.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/FavoritesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30 heading-text-5">Career Logs</p>
            <p>Navigate to “Goals & Other Logs” to add career logs and view logs that others have tagged you in.  The former allows you to add goals, passions, and offers for employment. The latter allows counselors and mentors to add notes from their career exploration, mock interview, or resume review sessions with you. Each of these things give you insight into who you are, what you want, and what you’re good at.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/AddALog.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30 heading-text-5">Counseling Detail Page</p>
            <p>Lastly, all of this information can be made available to counselors, teachers, and mentors to drive more informative conversations about you. If you are part of a school or program, they should be able to view your profile from their portal.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OrgCounselorView.png" alt="GC" className="image-full-auto top-margin" />
          </div>
        )
      } else if (this.state.solutionName === 'Career Awareness & Exploration') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <div>
              <p>This section will cover:</p>
              <ol className="left-padding-25">
                <li>Our Career Path Matching Feature</li>
                <li>Viewing Career Path Profiles</li>
                <li>Viewing Employer Profiles</li>
                <li>Viewing Labor Market Trends</li>
                <li>Using The Purpose Framework</li>
                <li>Using for Career Tracks Within Programs</li>
                <li>Favoriting Stuff</li>
                <li>Comparing Career Path Financials</li>
              </ol>
            </div>

            <p className="top-padding-30">Guided Compass has 1,100+ career paths in its database. Most of those career paths are pulled from the U.S. Bureau of Labor Statistics. After a student takes a career assessment, they can calculate their career matches by clicking the match icon to the left of the search bar. The matches allow students to discover careers they may have not even thought about. Here’s what the top career matches look like:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OpportunityMatchesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Students can click into a career path to view videos, view relevant data, and ask questions:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CareerPathDetailsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Additionally, students can view employer profiles and look at a “Day in the Life” videos:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerDetailsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">If students wants a birds-eye view of future opportunities, they can view labor market trends within the “Explore Paths” tab to discover career paths worth exploring:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/TrendsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">When considering career paths to recommend career-seekers, Guided Compass loosely uses the following framework:</p>
            <img src={purposeDiagram} alt="GC" className="image-half-auto top-margin center-horizontally" />

            <p className="top-padding-30">In addition, admins can create apps that act as application for career tracks for specific pathways. This helps when determining if a group of people are likely a good fit for a career pathway or a set of opportunities.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CommonAppDetailSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Students can favorite career paths, allowing them to return to them later.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/FavoritesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Lastly, career-seekers can use the financial planning tool to compare the financial implications of various career paths against their projected lifestyle(s).</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/BallparkModeSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>

          </div>
        )
      } else if (this.state.solutionName === 'Career Planning') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <p>After career-seekers have explored careers, they can set goals and make a career plan. At the very least, career plans should include short-term, medium-term, and long-term goals that are Specific, Measurable, Achievable, Relevant, and Time-Bound (SMART). Goals should include strategies and tactics for reaching them.</p>

            <p className="top-padding-30">While not required, we recommend career plans cover all quadrants of the purpose diagram:</p>
            <img src={purposeDiagram} alt="GC" className="image-half-auto top-margin center-horizontally" />

            <p className="top-padding-30">This means, it should cover your passions, top skills, problems you’re interested in solving, and projected financials. We also recommend that career plans include your “fuel”, or reason for pursuing the path.</p>

            <p className="top-padding-30">We’ve created a “Career Plan Builder” that allows you to import your profile and work from there. Career plans can be exported to PDF from the career plan builder.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CareerPlanBuilderSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">When you’ve completed a draft of your career plan, it should be shared with counselors, mentors, and parents for feedback and resources.</p>

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>

          </div>
        )
      } else if (this.state.solutionName === 'Project-Based Learning') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <p>Guided Compass launched the <Link to="/problem-platform">Problem Platform</Link> to allow educators, workforce, and employers to create prompts for career-seekers to submit projects to. These postings (i.e., assignments, problems, and challenges) show up as opportunities, and can either provide payment or be pro-bono. Career-seekers can submit their projects to the opportunities directly, get feedback on their submissions, provide feedback on other submissions, and comment on the posting.</p>

            <p className="top-padding-30">Whenever career-seekers add a project, it is saved to their profile. Feedback is saved to their profile as well. This allows career-seekers to get additional feedback from connections and mentors whenever they like, and allows them to import their projects into work applications. Those who have previously worked on projects with an employer are given extra points when they submit an application to work for that employer.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/ProjectOppsDetailsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      } else if (this.state.solutionName === 'Work and Work-Based Learning') {
        return (
          <div key={this.state.solutionName} className="heading-text-4">
            <div>
              <p>Guided Compass supports postings for internships, apprenticeships, part-time, and full-time work opportunities. We support the entire lifecycle:</p>
              <ol className="left-padding-25">
                <li>Intermediaries and/or employers defining benchmarks</li>
                <li>Intermediaries and/or employers posting opportunities</li>
                <li>Career-seekers preparing for opportunities, and advisors helping them prepare</li>
                <li>Career-seekers applying to opportunities</li>
                <li>Intermediaries scoring and referring career-seekers to employers after they’ve applied</li>
                <li>Employers providing feedback, requesting interviews, and extending offers to candidates</li>
                <li>Employers approving timesheets and creating assignments for hires</li>
                <li>Collecting feedback after placements, managing reporting, and updating profiles for future applications</li>
              </ol>
            </div>

            <p className="top-padding-30">The first step is to define benchmarks. To understand what benchmarks are, check out our <Link to="/help/what-are-benchmarks">Video Explaining Benchmarks</Link>. Guided Compass has standard benchmarks, so this step isn’t necessary. For programs that would like custom pathways though, this would be a helpful step. Once pathway-level benchmarks are created, whenever an employer posts a new role, a copy of the benchmark is created so that the employer or organization can customize their ideal candidate. Spending time defining benchmarks also gives career-seekers an industry-validated goal post to work towards, and allows staff to help career-seekers adequately reach that goal post.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OrgEditBenchmarkSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Secondly, employers and organizations can post opportunities to their communities. View this video on <Link to="/help/how-to-manage-postings">How to Manage Postings</Link>. For each posting, admins can toggle on/of various application components, including resume, career assessments, competency endorsements, transcript, custom surveys, letters of recommendation, and more.</p>

            <p className="top-padding-30">Thirdly, career-seekers can prepare for opportunities by viewing the benchmark, taking career assessments, creating a resume using our Resume Builder tool, requesting competency endorsements, taking courses, adding projects, and whatever else lets them put their best foot forward. Advisors can view opportunities from their portals and assist in candidates’ preparation.</p>

            <p className="top-padding-30">Fourthly, career-seekers can apply directly to opportunities. To do this, they can easily import their profile into their applications. Ideally, this is the culmination of months of preparation.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OpportunityDetailsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Fifth, Guided Compass helps intermediaries score and refer candidates. If there are many applications, the match score calculated by Guided Compass allows staff to easily shortlist top candidates.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OrgApplicationsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Intermediaries can add scores for internal-conducted interviews and provide feedback to candidates. Then, intermediaries can favorite the top applicants and forward them to the employer.</p>

            <p className="top-padding-30">Sixth, employers receive top candidates as an email notification. Below is an example:</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/ExampleEmployerEmailSS.png" alt="GC" className="image-full-auto top-margin" />

            <div>
              <p className="top-padding-30">Feedback from employers can be collected in four ways:</p>
              <ul className="left-padding-25">
                <li>Employers can respond to email notification or tell the staff. The staff can then update the status of each applicant in the system.</li>
                <li>Employers can integrate Guided Compass with their Applicant Tracking System (ATS), which not only allows referred candidates to automatically show up in their system, it also allows feedback to be automatically updated within Guided Compass.</li>
                <li>Employers can log in to Guided Compass and provide feedback on candidates within the portal.</li>
              </ul>
            </div>

            <p className="top-padding-30">Seventh, after candidates have been placed, employers can enable timesheets. These timesheets will automatically be created weekly, would be submitted by hires, and would be approved by their supervisors. Embedded in these timesheets would be the ability to share what projects/assignments the hires worked on and the ability to share feedback.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/SubmitTimesheetSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Lastly, Guided Compass will take care of collecting feedback and organize everything to be exported in the reporting section of the admin portal.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/OrgReportingSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      } else if (this.state.solutionName === 'Community, Alumni, & Mentorship') {
        return (
          <div key={this.state.solutionName} className="heading-text-5">
            <p>Guided Compass allows career-seekers to connect with one another to form teams, provide feedback, share resources, and see post updates. Additionally, administrators can post updates to the entire community. Administrators can also invite alumni to post opportunities. Lastly, career-seekers can add goals and use the news feed to collect resources from their connections as well as organization staff.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/NewsFeedSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Career-seekers can match amongst one another and join dedicated groups.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/PeerMatchesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Career-seekers can browse the profiles of your employer partners, including “Day in the Life” videos of employers that the organization has a particular connection to.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/EmployerDetailsSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">After career-seekers complete the program, they can continue to gain the benefits of the Guided Compass platform. If the career-seeker is a student under 18, a parent/guardian needs to sign a form to transfer the students’ profile information to a personal account.</p>

            <p className="top-padding-30">Program leads can also invite alumni to participate in their Guided Compass community. If you would like to have a formal mentorship program, you can pair mentor-mentees directly in the portal. Mentors can take the same career assessments as the career-seekers, which allows Guided Compass to provide admins with a match score to facilitate the pairing. Additionally, some programs may prefer a more open mentorship arrangement where mentor profiles are visible. In this case, career-seekers will be able to see which mentors are closest to their profile in terms of interests, personality, values, departmental interests, and industry interests.</p>

            <p className="top-padding-30">Many mentor programs require background checks and explicit approvals. While we do not conduct background checks, we can recommend some services. That said, admin can deactivate and reactivate mentor profiles at any time to manage this process.</p>

            <p className="top-padding-30">When mentors are activated and connected with mentees, they will see updates from those mentees in their newsfeed. Mentees may want feedback on choosing a career path, strategies to pursue a career path, preparing for an opportunity, comparing offers, strategies for furthering a project, identifying which skills to learn, identifying how to gauge their current skill levels, and more!</p>

            <p className="top-padding-30">Lastly, when mentors and mentees meet, they can use the career logs section to keep a record of their meetings. Counselors should use the same logs, providing a historical record of advising sessions. Advising sessions typically are 30-60 minute meetings covering all the aforementioned post areas (e.g., feedback on choosing a career path, strategies) in a private manner. It may also cover mock interviews, resume reviews, and personal fuel stories because those are more personal and private.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/NewAdvisingSessionSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      } else if (this.state.solutionName === 'Lessons, Courses, and Resources') {
        return (
          <div key={this.state.solutionName} className="heading-text-5">
            <p>After benchmarks are created and career-seekers indicate which career paths they are interested in via goals, Guided Compass can recommend courses, resources, and lessons to career-seekers. For courses, Guided Compass integrates with Udemy, Udacity, and Coursera to recommend over 10,000 courses based on goals and skill gaps. As career-seekers add to their skill assessments and receive competency endorsements, Guided Compass can provide more accurate recommendations for courses that fill their skill gaps in order for them to reach their goal.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CoursesSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      } else if (this.state.solutionName === 'Financial Planning') {
        return (
          <div key={this.state.solutionName} className="heading-text-5">
            <p>Guided Compass provides a financial planning tool that can be used for financial literacy workshops. The financial planning tool has two modes: “Ballpark Mode” and “Custom Entries Mode”.</p>

            <p className="top-padding-30">For “Ballpark Mode”, career-seekers can search from 1,100+ career paths to import the average income of that career path over different time horizons. They can then change assumptions to see how their income, expenses, and net worth fluctuate.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/BallparkModeSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">For “Custom Entries Mode”, career-seekers can add individual entries for income, expenses, assets, and liabilities. This is a great option for more advanced career-seekers who want to visualize their financial future. This would also be a great follow up to a financial literacy workshop where career-seekers capture their net income and net assets at a snapshot in time. They could then add their entries to see how their numbers change over time. This exercise  will illustrate the power of compounding.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/CustomEntriesModeSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      } else if (this.state.solutionName === 'Entrepreneurship') {
        return (
          <div key={this.state.solutionName} className="heading-text-5">
            <p>The <Link to="/problem-platform">Guided Compass Problem Platform</Link> is a great tool for entrepreneurship. You can set up a challenge for groups of career-seekers to solve a business or societal problem during a specified period. All teams would need to submit a project solution by the deadline. The winning team wins a prize. Alternatively, if money is tight and career-seekers aren’t competitive, educators can create case studies highlighting problems and have career-seekers submit without necessarily winning a prize. Lastly, entrepreneurial mentors can provide direct feedback on their projects and follow progress.</p>
            <img src="https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/screenshots/ProjectFeedbackSS.png" alt="GC" className="image-full-auto top-margin" />

            <p className="top-padding-30">Social distancing has brought a new normal of working from home. This means employees must have initiative, be resourceful, and manage their time better than ever before. These skills are coincidentally the same skills that entrepreneurs must have to be successful. In addition to this, there are more and more career freelancers. Thus, teaching entrepreneurship is no longer just about starting a business, it’s also now about getting a job and advancing as an employee. For this reason, we recommend all career paths take advantage of the Problem Platform and teach entrepreneurial skills.</p>

            <p className="top-padding-30">Interested in establishing a dedicated Guided Compass community for your organization? We charge for student / career-seeker licenses, with discounts based on volume. <Link to="/pricing">Click here</Link> for pricing information. Career-seekers can always join the general platform for free <Link to="/join">here</Link>.</p>
          </div>
        )
      }
    }

    render() {

        return (
            <div>
                <TopNavigation currentPage={"solutionsPage"} darkBackground={true} changeSubNavSelected={this.changeSubNavSelected}/>

                <section className="section-features white-background">
                  <div className="row">
                    <h2>{this.state.solutionName}</h2>
                    <div className="spacer" /><div className="spacer" />
                  </div>

                  <div className="row">
                    {this.renderContent()}
                  </div>

                </section>

                <div className="light-background padding-40">
                    <div className={(window.innerWidth > 768) ? "container-left right-text" : "full-width center-text container-left"}>
                      <p className="heading-text-2">Can Guided Compass help</p>
                      <p className="heading-text-1">You?</p>
                    </div>
                    <div className={(window.innerWidth > 768) ? "container-right top-margin-20" : "container-right top-margin-20 center-text"}>
                      <Link className="btn btn-full heading-text-4" to={"/join"}>Get Started</Link>
                    </div>
                    <div className="clear" />
                </div>

                {/*Footer()*/}
                <Footer history={this.props.navigate} />
            </div>
        )
    }
}

export default SolutionsPage;
