import React, {Component} from 'react';
// import {convertDateToString} from '../Functions/ConvertDateToString';
// import SubReferralDetails from '../Common/ReferralDetails';
import withRouter from '../Functions/WithRouter';

// const favoritesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png';

class RenderFinancialPlan extends Component {
    constructor(props) {
      super(props)

      this.state = {
        showModule: true,

        maxDocHeight: 1100,
        docPadding: 40,

      }

      this.retrieveData = this.retrieveData.bind(this)
      this.calculateNet = this.calculateNet.bind(this)

    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in RenderCareerPlan', this.props, prevProps)

      // if (this.props.incomeArray && prevProps.incomeArray && this.props.incomeArray.length !== prevProps.incomeArray.length) {
      //
      // }
    }

    retrieveData() {
      // console.log('retrieveData called in RenderReferrals')
    }

    calculateNet(firstArray, secondArray, index, type) {
      console.log('calculateNet called', firstArray, secondArray, index, type)

      let firstNumber = 0
      if (firstArray && firstArray[index]) {
        firstNumber = firstArray[index]
      }

      let secondNumber = 0
      if (secondArray && secondArray[index]) {
        secondNumber = secondArray[index]
      }

      const netNumber = firstNumber - secondNumber

      return netNumber
    }

    render() {

      const financialPlanContent = this.props.financialPlanContent
      const counterArray = this.props.counterArray
      const incomeArray = this.props.incomeArray
      const expenseArray = this.props.expenseArray
      const assetArray = this.props.assetArray
      const liabilityArray = this.props.liabilityArray

      // calculateNet

      return (
        <div>
          <div>
            <div>
              <div className="card-clear-padding carousel-3" id="printPage1">
                <div className="fixed-column-150 standard-border bold-text">
                  <div className="height-53 horizontal-padding selected-background-light" />

                  <div className="standard-border row-15 horizontal-padding">
                    <p>Income</p>
                  </div>
                  <div className="standard-border row-15 horizontal-padding">
                    <p>Expenses</p>
                  </div>
                  <div className="standard-border row-15 horizontal-padding faint-highlighted-background">
                    <p className="underline-text offset-underline">Net Income</p>
                  </div>

                  <div className="standard-border row-15 horizontal-padding">
                    <p>Assets</p>
                  </div>
                  <div className="standard-border row-15 horizontal-padding">
                    <p>Liabilities</p>
                  </div>
                  <div className="standard-border row-15 horizontal-padding faint-highlighted-background">
                    <p className="underline-text offset-underline">Net Worth</p>
                  </div>
                </div>
                <div className="calc-column-offset-150">
                  <div className="flex-container row-direction">
                    {counterArray.map((value, optionIndex) =>
                      <div key={"incomeArray|" + optionIndex}>
                        <div className="standard-border row-15 horizontal-padding bold-text selected-background-light">
                          <p>{new Date().getFullYear() + optionIndex}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding">
                          <p>{(incomeArray && incomeArray[optionIndex]) ? "$" + Number(incomeArray[optionIndex]).toLocaleString() : "0"}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding">
                          <p>{(expenseArray && expenseArray[optionIndex]) ? "$" + Number(expenseArray[optionIndex]).toLocaleString() : "0"}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding faint-highlighted-background">
                          <p className="underline-text offset-underline">${this.calculateNet(incomeArray, expenseArray, optionIndex, 'net income').toLocaleString()}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding">
                          <p>{(assetArray && assetArray[optionIndex]) ? "$" + Number(assetArray[optionIndex]).toLocaleString() : "0"}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding">
                          <p>{(liabilityArray && liabilityArray[optionIndex]) ? "$" + Number(liabilityArray[optionIndex]).toLocaleString() : "0"}</p>
                        </div>
                        <div className="standard-border row-15 horizontal-padding faint-highlighted-background">
                          <p className="underline-text offset-underline">${this.calculateNet(assetArray, liabilityArray, optionIndex, 'net worth').toLocaleString()}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="clear" />

              </div>

              <div className="clear" />
            </div>
          </div>

          <div className="clear" />
        </div>
      )
    }
}

export default withRouter(RenderFinancialPlan);
