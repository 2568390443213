import React, {Component} from 'react';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import AddMembers from './Subcomponents/AddMembers';
import withRouter from './Functions/WithRouter';

class AdvAddMentor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emailId: null,
            username: '',
            cuFirstName: '',
            cuLastName: '',

            activeOrg: '',

            automaticallyAttach: false,
            postOptions: [],

            employerName: '',
            employerURL: '',
            employerType: '',
            employerIndustry: '',
            employeeCount: '',
            employeeGrowth: '',
            employerLocation: '',
            employerCode: '',
            projectedHires: '',
            subsidyApproved: false,
            subsidyAward: '',

            contactFirstName: '',
            contactLastName: '',
            contactTitle: '',
            contactEmail: '',
            contactPhone: '',

            typeOptions: ['','For-Profit','Non-Profit','Public Sector'],
            industryOptions: [],
            countOptions: [],
            growthOptions: [],
            hireOptions: [],

            includeHighSchool: true,
            includeAssociates: true,
            includeBachelors: true,

            volunteerOptions: ['Resume Building','Public Speaking','Leadership Skills','Job Shadowing','Company Tour + Q&A'],
            volunteerPreferences: [],

            successOptions: ['Conversion to jobs','Knowledge gained as a result of internship','Achieve hiring and diversity needs','Fulfill contingent workforce','Employee exposure to non-traditional student backgrounds'],
            successMetrics: [],
            employerCulture: '',

            clientErrorMessage: '',
            serverPostSuccess: true,
            serverSuccessMessage: '',
            serverErrorMessage: ''
        }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let orgFocus = localStorage.getItem('orgFocus');
      let activeOrg = localStorage.getItem('activeOrg');
      const orgLogo = localStorage.getItem('orgLogo');

      if (email) {

        this.setState({ emailId: email, username, cuFirstName, cuLastName,
          activeOrg, orgFocus, roleName, orgLogo
        });
      }
    }

    render() {

      return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />
            <AddMembers activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} roleName={this.state.roleName} path={this.state.path} type="Mentors" />
            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo)}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(AdvAddMentor);
