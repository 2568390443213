import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import SubPathwayDetails from '../components/Subcomponents/PathwayDetails';
import withRouter from '../components/Functions/WithRouter';

class PathwayDetailsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount(){
        console.log('pathwayDetailsPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        const { pathwayId } = this.props.params

        this.setState({ pathwayId });
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within parentCareerDetails ', this.props, prevProps)

      if (this.props.params && this.props.params.pathwayId !== prevProps.params.pathwayId) {
        console.log('change pathway id')
        this.setState({ pathwayId: this.props.params.pathwayId })
      }
    }

    render() {

      return (
          <div>
            <TopNavigation currentPage={"pathwayDetailsPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>
            <SubPathwayDetails history={this.props.navigate} accountCode={this.state.accountCode} pathwayId={this.state.pathwayId} pageSource="landingPage" />

          </div>
      )
    }
}

export default withRouter(PathwayDetailsPage);
