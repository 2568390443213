import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import SubCareerDetails from '../components/Subcomponents/CareerDetails';
import withRouter from '../components/Functions/WithRouter';

class CareerDetailsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)

      this.child = React.createRef()
    }

    componentDidMount(){
        console.log('careerDetailsPage just mounted')
        document.body.style.backgroundColor = "#fff";
        window.scrollTo(0, 0)

        if (window.innerWidth > 768) {
        } else {
          //mobile
          this.setState({ onMobile: true })
        }

        let emailId = localStorage.getItem('email');
        let activeOrg = localStorage.getItem('activeOrg');
        let orgFocus = localStorage.getItem('orgFocus');
        let roleName = localStorage.getItem('roleName');

        if (this.props.location && this.props.location.state && this.props.location.state.selectedItem) {
          //send this logged out user out of the app
          const { careerSelected } = this.props.location.state;
          this.setState({ careerSelected, emailId, activeOrg, orgFocus, roleName });

        } else {
          console.log('went directly to the link')

          const { careerSelected } = this.props.params
          this.setState({ careerSelected, emailId, activeOrg, orgFocus, roleName });

        }
    }

    componentWillReceiveProps(newProps) {
      console.log('componentWillReceiveProps called')

      if (newProps.params.careerSelected !== this.state.careerSelected) {
        console.log('reload the page');
        const careerSelected = newProps.params.careerSelected
        console.log('show careerSelected: ', careerSelected)
        this.setState({ careerSelected })
      } else {
        console.log('do not reload the page');
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      // this.setState({ passedCommand })
      this.child.current.passCommand(passedCommand);

    }


    render() {

      return (
          <div>
            <TopNavigation currentPage={"careerDetailsPage"} emailId={this.state.emailId} activeOrg={this.state.activeOrg} roleName={this.state.roleName} darkBackground={true}/>

            <div>
              <SubCareerDetails careerSelected={this.state.careerSelected} history={this.props.navigate} pageSource="landingPage" commandClicked={this.commandClicked}/>
            </div>

            <Footer history={this.props.navigate} ref={this.child} />
          </div>
      )
    }
}

export default withRouter(CareerDetailsPage);
