import React, {Component} from 'react';
import TopNavigation from './TopNavigation';
import Footer from './Footer';

class TermsPage extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }
    }

    componentDidMount() {

      window.scrollTo(0, 0)
      document.body.style.backgroundColor = "white";

    }

    mobileNav (event) {
        console.log('mobile nav clicked')
        if (this.state.mobileNavClass === "side-nav") {
            this.setState({
                mobileNavClass: 'side-nav-mobile',
                isVisible: false
            })
        } else {
            this.setState({
                mobileNavClass: 'side-nav',
                isVisible: true
            })
        }
    }

    render() {
        return (

          <div>
              <TopNavigation currentPage={"termsPage"} darkBackground={true} />

              <section className="section-features">
                <div className="row">
                  <h2>Guided Compass Terms of Use</h2>

                  <div className="row-10">
                    <p>Guided Compass provides a personalized subscription service that allows our members to be recommended, access, and interact with educational and career-related opportunities ("Guided Compass Opportunities") provided over the Internet to certain Internet-connected computers and other devices ("Guided Compass ready devices").</p>
                  </div>

                  <div className="row-10">
                    <p>You have accepted these Terms of Use, which govern your use of our service.</p>
                  </div>

                  <div className="row-10">
                    <p>As used in these Terms of Use, "Guided Compass service", "our service" or "the service" means the personalized service provided by Guided Compass for discovering, accessing, and interacting with Guided Compass Opportunities, including all features and functionalities, recommendations, the website, and user interfaces, as well as all content and software associated with our service.</p>
                  </div>

                  <div className="row-10">
                    <p>YOU AGREE TO THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER DESCRIBED IN SECTION 7 TO RESOLVE ANY DISPUTES WITH GUIDED COMPASS (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT).</p>
                  </div>

                  <ol className="left-padding-30 row-10">
                    <li className="bold-text row-10">Membership</li>
                      <ul className="left-padding-30 row-10 no-bullets">
                        <li className="row-10">1.1. Your Guided Compass membership will continue and automatically renew until terminated. To use the Guided Compass service you must have Internet access and a Guided Compass ready device and provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted method of payment, as may be updated from time to time and which may include payment through your account with a third party. You must cancel your membership before it renews in order to avoid billing of the membership fees for the next billing cycle to your Payment Method (see "Cancellation" below).</li>
                        <li className="row-10">1.2. We may offer a number of membership plans, including memberships offered by third parties in conjunction with the provision of their own products and services. We are not responsible for the products and services provided by such third parties. Some membership plans may have differing conditions and limitations, which will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your Guided Compass membership by signing into your portal and clicking on the "Account" link available from Settings, which can be accessed at the top-right section of the page.</li>
                      </ul>

                    <li className="bold-text row-10">Promotional Offers</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">We may from time to time offer special promotional offers, plans or memberships (“Offers”). Offer eligibility is determined by Guided Compass at its sole discretion and we reserve the right to revoke an Offer and put your account on hold in the event that we determine you are not eligible. Members with an existing or recent Guided Compass membership may not be eligible for certain introductory Offers. We may use information such as device ID, method of payment or an account email address used with an existing or recent Guided Compass membership to determine Offer eligibility. The eligibility requirements and other limitations and conditions will be disclosed when you sign-up for the Offer or in other communications made available to you.</li>
                    </ul>

                    <li className="bold-text row-10">Billing and Cancellation</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10"><label className="underline-text">Billing Cycle.</label> The membership fee for the Guided Compass service and any other charges you may incur in connection with your use of the service, such as taxes and possible transaction fees, will be charged to your Payment Method on the specific payment date indicated on the "Account" page. The length of your billing cycle will depend on the type of subscription that you choose when you signed up for the service. Membership fees are fully earned upon payment. In some cases your payment date may change, for example if your Payment Method has not successfully settled, when you change your subscription plan or if your paid membership began on a day not contained in a given month. Visit our website and click on the "Account" page to see your next payment date. We may authorize your Payment Method in anticipation of membership or service-related charges through various methods, including authorizing it up to approximately one month of service as soon as you register. If you signed up for Guided Compass using your account with a third party as a Payment Method, you can find the billing information about your Guided Compass membership by visiting your account with the applicable third party.</li>

                      <li className="row-10"><label className="underline-text">Payment Methods.</label> To use the Guided Compass service you must provide one or more Payment Methods. You authorize us to charge any Payment Method associated to your account in case your primary Payment Method is declined or no longer available to us for payment of your subscription fee. You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the service until we have successfully charged a valid Payment Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Check with your Payment Method service provider for details.</li>

                      <li className="row-10"><label className="underline-text">Updating your Payment Methods.</label> You can update your Payment Methods by going to the "Account" page. We may also update your Payment Methods using information provided by the payment service providers. Following any update, you authorize us to continue to charge the applicable Payment Method(s).</li>

                      <li className="row-10"><label className="underline-text">Cancellation.</label> You can cancel your Guided Compass membership at any time, and you will continue to have access to the Guided Compass service through the end of your billing period. To cancel, go to the "Account" page on our website and follow the instructions for cancellation. If you cancel your membership, your account will automatically close at the end of your current billing period. To see when your account will close, click "Account" within your portal. If you signed up for Guided Compass using your account with a third party as a Payment Method and wish to cancel your Guided Compass membership, you may need to do so through that third party, for example by visiting your account with the applicable third party and turning off auto-renew, or unsubscribing from the Guided Compass service through that third party.</li>

                      <li className="row-10"><label className="underline-text">Changes to the Price and Subscription Plans.</label> We reserve the right to change our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any price changes or changes to your subscription plan will take effect following notice to you.</li>

                      <li className="row-10"><label className="underline-text">No Refunds.</label> Payments are nonrefundable and there are no refunds or credits for partially used membership periods. Following any cancellation, however, you will continue to have access to the service through the end of your current billing period. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members ("credits"). The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The provision of credits in one instance does not entitle you to credits in the future for similar instances, nor does it obligate us to provide credits in the future, under any circumstance. </li>
                    </ul>

                    <li className="bold-text row-10">Guided Compass Service</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">4.1. You must be at least 18 years of age to become a member or sponsor (i.e., pay for) a member of the Guided Compass service. Individuals under the age of 18 may only utilize the service with the involvement of a parent or legal guardian, under such person's account and otherwise subject to these Terms of Use.</li>
                    </ul>

                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">4.2. The Guided Compass service is for your personal and non-commercial use only and may not be shared with individuals beyond your household. During your Guided Compass membership, we grant you a limited, non-exclusive, non-transferable right to access the Guided Compass service. Except for the foregoing, no right, title or interest shall be transferred to you. You agree not to use the service for public performances.</li>
                    </ul>

                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">4.3. The Guided Compass software is developed by, or for, Guided Compass. This software may vary by device and medium, and functionalities may also differ between devices. By using our service, you agree to receive, without further notice or prompting, updated versions of the Guided Compass and related third-party software. If you do not accept the foregoing terms, do not use our service.</li>
                    </ul>

                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">4.4. By using our service, you agree to look solely to the entity that manufactured and/or sold you the Guided Compass ready device for any issues related to the device and its compatibility with the Guided Compass service. We do not take responsibility or otherwise warrant the performance of Guided Compass ready devices, including the continued compatibility with our service.</li>
                    </ul>

                    <li className="bold-text row-10">Passwords and Account Access</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">The member who created the Guided Compass account and whose Payment Method is charged (the "Account Owner") is responsible for any activity that occurs through the Guided Compass account. To maintain control over the account and prevent anyone from accessing the account (which could include information on viewing history for the account), the Account Owner should maintain control over the Guided Compass ready devices that are used to access the service and not reveal the password or details of the Payment Method associated to the account to anyone. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account. We can terminate your account or place your account on hold in order to protect you, Guided Compass or our partners from identity theft or other fraudulent activity. Guided Compass is not obligated to credit or discount a membership for holds placed on the account by either a representative of Guided Compass or by the automated processes of Guided Compass. If your Guided Compass ready device is sold, lost or stolen, please deactivate the Guided Compass ready device. If you fail to log out or deactivate your device, subsequent users may access the Guided Compass service through your account and may be able to access certain of your account information. To deactivate a device, follow instructions on the "Account" page of our website.</li>
                    </ul>

                    <li className="bold-text row-10">Disclaimers of Warranties and Limitations on Liability</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">6.1. THE GUIDED COMPASS SERVICE AND ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE GUIDED COMPASS SERVICE, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. GUIDED COMPASS DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE GUIDED COMPASS SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. GUIDED COMPASS SPECIFICALLY DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS, GUIDED COMPASS READY DEVICES, AND GUIDED COMPASS SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).</li>

                      <li className="row-10">6.2. TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL GUIDED COMPASS, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.</li>

                      <li className="row-10">6.3. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</li>

                      <li className="row-10">6.4. NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU.</li>
                    </ul>

                    <li className="bold-text row-10">Arbitration Agreement</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10">7.1. You and Guided Compass agree that any dispute, claim or controversy arising out of or relating in any way to the Guided Compass service, these Terms of Use and this Arbitration Agreement, shall be determined by binding arbitration or in small claims court. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award and nothing in this Arbitration Agreement shall be interpreted as limiting any non-waivable statutory rights. You agree that, by agreeing to these Terms of Use, the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that you and Guided Compass are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of this Agreement and the termination of your Guided Compass membership. </li>

                      <li className="row-10">7.2. If you elect to seek arbitration or file a small claim court action, you must first send to Guided Compass, by certified mail, a written Notice of your claim ("Notice"). The Notice to Guided Compass must be addressed to: General Counsel, Guided Compass, 4041 S. Harvard Blvd ("Notice Address"). If Guided Compass initiates arbitration, it will send a written Notice to the email address used for your membership account. A Notice, whether sent by you or by Guided Compass, must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought ("Demand"). If Guided Compass and you do not reach an agreement to resolve the claim within 30 days after the Notice is received, you or Guided Compass may commence an arbitration proceeding or file a claim in small claims court. </li>

                      <li className="row-10">7.3. You may download or copy a form Notice and a form to initiate arbitration at www.adr.org.</li>

                      <li className="row-10">7.4. The arbitration will be governed by the Commercial Arbitration Rules (the "AAA Rules") of the American Arbitration Association ("AAA"), as modified by this Agreement, and will be administered by the AAA. The AAA Rules and Forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by writing to the Notice Address. The arbitrator is bound by the terms of this Agreement. All issues are for the arbitrator to decide, including issues relating to the scope and enforceability of this arbitration agreement. Unless Guided Compass and you agree otherwise, any arbitration hearings will take place in the county (or parish) of your residence. The arbitrator's award shall be final and binding on all parties, except (1) for judicial review expressly permitted by law or (2) if the arbitrator's award includes an award of injunctive relief against a party, in which case that party shall have the right to seek judicial review of the injunctive relief in a court of competent jurisdiction that shall not be bound by the arbitrator's application or conclusions of law. </li>

                      <li className="row-10">7.5. If your claim is for US$10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds US$10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision explaining the essential findings and conclusions on which the award is based. If the arbitrator issues you an award that is greater than the value of Guided Compass's last written settlement offer made before an arbitrator was selected (or if Guided Compass did not make a settlement offer before an arbitrator was selected), then Guided Compass will pay you the amount of the award or US$5,000, whichever is greater. Except as expressly set forth herein, the payment of all filing, administration and arbitrator fees will be governed by the AAA Rules.</li>

                      <li className="row-10">7.6. YOU AND GUIDED COMPASS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Guided Compass agree otherwise, the arbitrator may not consolidate more than one person's claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void. </li>
                    </ul>

                    <li className="bold-text row-10">Miscellaneous</li>
                    <ul className="left-padding-30 row-10 no-bullets">
                      <li className="row-10"><label className="underline-text">8.1. Governing Law.</label> These Terms of Use shall be governed by and construed in accordance with the laws of the state of Delaware, U.S.A. without regard to conflict of laws provisions. These terms will not limit any consumer protection rights that you may be entitled to under the mandatory laws of your state of residence.</li>

                      <li className="row-10"><label className="underline-text">8.2. Feedback.</label> Guided Compass is free to use any comments, information, ideas, concepts, reviews, or techniques or any other material contained in any communication you may send to us ("Feedback"), including responses to questionnaires or through postings to the Guided Compass service, including the Guided Compass website and user interfaces, worldwide and in perpetuity without further compensation, acknowledgement or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the Guided Compass service. In addition, you agree not to enforce any "moral rights" in and to the Feedback, to the extent permitted by applicable law.</li>

                      <li className="row-10"><label className="underline-text">8.3. Customer Support.</label> To find more information about our service and its features, or if you need assistance with your account, please visit the Guided Compass Contact Page on our website. In certain instances, Customer Service may best be able to assist you by using a remote access support tool through which we have full access to your computer. If you do not want us to have this access, you should not consent to support through the remote access tool, and we will assist you through other means. In the event of any conflict between these Terms of Use and information provided by Customer Service or other portions of our website, these Terms of Use will control.</li>

                      <li className="row-10"><label className="underline-text">8.4. Survival.</label> If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</li>

                      <li className="row-10"><label className="underline-text">8.5. Changes to Terms of Use and Assignment.</label> Guided Compass may, from time to time, change these Terms of Use. Such revisions shall be effective immediately; provided however, for existing members, such revisions shall, unless otherwise stated, be effective 30 days after posting. We may assign our agreement with you to any affiliated company or to any entity that succeeds to all or substantially all of our business or assets related to the applicable Guided Compass service.</li>

                      <li className="row-10"><label className="underline-text">8.6. Communication Preferences.</label> We will send you information relating to your account (e.g. payment authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) in electronic form only, for example via emails to your email address provided during registration. You agree that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.</li>
                    </ul>

                    <p><label className="bold-text">Last Updated:</label> January 2, 2024</p>

                  </ol>

                </div>
              </section>

              <Footer history={this.props.navigate} />
          </div>

        )
    }
}

export default TermsPage;
