import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const exchangeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/exchange-icon-dark.png';
const editIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-dark.png';
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const placementsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const partnerIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/partner-icon-dark.png';
// const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const menuIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class Placements extends Component {
    constructor(props) {
        super(props)

        this.state = {
            opacity: 0,
            transform: 'translate(90%)',

            newFormat: true,
            placements: [],
            individualPlacementsCount: 0,
            placementCount: 0,

            postLimit: 100,

            pageNumber: 1,
            resLimit: 100,
            pageCountStart: 0,
            pageCountEnd: 100,

            partyOptions: ['','Workers','Employers'],

            selectedPlacements: [],
            selectedPlacementDetails: [],
            selectedEntities: [],
            selectedEntityDetails: [],

            emails: []

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.pullPlacements = this.pullPlacements.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.searchItems = this.searchItems.bind(this)
        this.searchItemClicked = this.searchItemClicked.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.sendMessage = this.sendMessage.bind(this)
        this.configureEmails = this.configureEmails.bind(this)

        this.toggleMenu = this.toggleMenu.bind(this)
        this.menuButtonClicked = this.menuButtonClicked.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.movePage = this.movePage.bind(this)
    }

    static defaultProps = { activeOrg: null, accountCode: null, source: '' }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.accountCode)

      const org = this.props.activeOrg
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');

      if (org) {

        // year up matching email template
        let employerEmailMessage = '<p>Hi {{employerContactFirstName}},</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">On behalf of the entire {{orgProgramName}} team, I would like to congratulate you on being matched to our first cohort of trainees.</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">After much thought and consideration, we are pleased to share the name(s) of the participant(s) who will join {{employerName}} as a(n) {{positionTitle}} beginning Tuesday, January 18th for our the {{orgProgramName}} work immersion journey.</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">All of our participants are excited to grow their skills, knowledge, and experience. You have been matched with the following participants:</p>'
        employerEmailMessage = employerEmailMessage + '{{participantList}}'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">As an immediate action item, we ask each employer partner to reach out directly to your matched participant(s) to begin the onboarding process.</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">We greatly appreciate your partnership as we work together during the work immersion phase of our program to provide meaningful learning experiences and wrap around support to all of our {{orgProgramName}} participants.</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">Please reach out directly to me at any time with questions as we approach the start date.</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">Best regards,</p>'
        employerEmailMessage = employerEmailMessage + '<p style="padding-top: 10px;">{{orgContactFirstName}} {{orgContactLastName}}</p>'
        employerEmailMessage = employerEmailMessage + '<p>{{orgContactTitle}}, {{orgProgramName}}</p>'
        employerEmailMessage = employerEmailMessage + '<p>{{orgContactEmail}} | {{orgContactPhone}}</p>'

        const employerEmailTemplate = {
          title: '{{orgProgramName}}: {{positionTitle}} Matches for {{employerName}}',
          message: employerEmailMessage
        }

        const menuOptions = [
          { icon: exchangeIconDark, title: 'Duplicate placement', shorthand: 'duplicate' },
          { icon: editIconDark, title: 'Edit placement', shorthand: 'edit' },
          { icon: deleteIconDark, title: 'Delete placement', shorthand: 'delete' },
        ]

        this.setState({ org, emailId: email, username, orgFocus, accountCode, employerEmailTemplate, menuOptions })

        const pullPartnerPlacements = true
        const isSimulation = false

        Axios.get('/api/placements/count', { params: { orgCode: org, accountCode, pullPartnerPlacements, isSimulation } })
        .then((response) => {
          console.log('Placements query worked', response.data);

          if (response.data.success) {

            const placementCount = response.data.placementCount
            // let individualPlacementsCount = 0
            // for (let i = 1; i <= placements.length; i++) {
            //   if (placements[i - 1].workers) {
            //     for (let j = 1; j <= placements[i - 1].workers.length; j++) {
            //       individualPlacementsCount++
            //     }
            //   }
            // }

            this.setState({ placementCount })
            this.pullPlacements(org,accountCode,this.state.pageNumber)

          } else {
            console.log('no placements data found', response.data.message)
            this.pullPlacements(org,accountCode,this.state.pageNumber)
          }

        }).catch((error) => {
            console.log('Placements query did not work', error);
        });

        Axios.get('/api/org', { params: { orgCode: org } })
        .then((response) => {
          console.log('Org info query attempted', response.data);

            if (response.data.success) {
              console.log('org info query worked')

              const orgName = response.data.orgInfo.orgName
              const orgProgramName = response.data.orgInfo.orgProgramName
              const orgContactTitle = response.data.orgInfo.contactTitle
              const orgContactFirstName = response.data.orgInfo.contactFirstName
              const orgContactLastName = response.data.orgInfo.contactLastName
              const orgContactEmail = response.data.orgInfo.contactEmail
              const orgContactPhone = response.data.orgInfo.contactPhone

              this.setState({ orgName, orgProgramName, orgContactTitle, orgContactFirstName, orgContactLastName,
                orgContactEmail, orgContactPhone
              });

            } else {
              console.log('org info query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    pullPlacements(org, accountCode, pageNumber) {
      console.log('pullPlacements called: ', org, accountCode, pageNumber)

      const pullPartnerPlacements = true
      let postTypes = null
      if (this.props.postTypes) {
        postTypes = this.props.postTypes
      }
      const isSimulation = false
      const resLimit = this.state.postLimit
      const skip = (pageNumber - 1) * this.state.postLimit

      console.log('show me skip: ', skip, resLimit, pageNumber)

      Axios.get('/api/placements', { params: { orgCode: org, accountCode, pullPartnerPlacements, postTypes, isSimulation, resLimit, skip } })
      .then((response) => {
        console.log('Placements query worked', response.data);

        if (response.data.success) {

          const placements = response.data.placements
          // let individualPlacementsCount = 0
          // for (let i = 1; i <= placements.length; i++) {
          //   if (placements[i - 1].workers) {
          //     for (let j = 1; j <= placements[i - 1].workers.length; j++) {
          //       individualPlacementsCount++
          //     }
          //   }
          // }

          let pageCountStart = ((pageNumber - 1) * resLimit) + 1

          let pageCountEnd = placements.length
          if (pageNumber > 1) {
            pageCountEnd = ((pageNumber - 1) * resLimit) + placements.length
          }

          this.setState({ placements, pageCountStart, pageCountEnd, pageNumber })

        } else {
          console.log('no placements data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Placements query did not work', error);
      });
    }

    movePage(forward) {
      console.log('movePage called', forward, this.state.pageNumber)

      let pageNumber = this.state.pageNumber
      if (forward) {
        pageNumber = pageNumber + 1
      } else {
        pageNumber = pageNumber - 1
      }

      this.pullPlacements(this.state.org,this.state.accountCode,pageNumber)

    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'searchEntities') {
        this.searchItems(event.target.value, event.target.name)
      } else if (event.target.name === 'party') {
        this.setState({ [event.target.name] : event.target.value })
        this.configureEmails(event.target.value, this.state.selectedPlacementDetails)
      } else {
        this.setState({ [event.target.name] : event.target.value })
      }
    }

    searchItems(searchString, type) {
      console.log('searchItems called', type)

      if (type === 'searchEntities') {
        if (!searchString || searchString === '') {
          this.setState({ searchEntities: searchString, searchIsAnimating: false, entityOptions: null })
        } else {
          this.setState({ searchEntities: searchString, searchIsAnimating: true })

          const search = true

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const emailId = self.state.emailId
            const excludeCurrentUser = true
            const orgCode = self.state.orgCode

            Axios.get('/api/entities/search', {  params: { searchString, search, emailId, excludeCurrentUser, orgCode }})
            .then((response) => {
              console.log('Entities query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved entities')

                  if (response.data) {

                    let entityOptions = []
                    if (response.data.entities && response.data.entities.length > 0) {
                      entityOptions = response.data.entities
                    }

                    self.setState({ entityOptions, searchIsAnimating: false })
                  }

                } else {
                  console.log('no entity data found', response.data.message)
                  self.setState({ searchIsAnimating: false })
                }

            }).catch((error) => {
                console.log('Entity query did not work', error);
                self.setState({ searchIsAnimating: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      } else if (type === 'searchOpportunities') {
        if (!searchString || searchString === '') {
          this.setState({ searchOpportunities: searchString, searchIsAnimatingOpportunities: false, careerOptions: null })
        } else {
          this.setState({ searchOpportunities: searchString, searchIsAnimatingOpportunities: true })

          const self = this
          function officiallyFilter() {
            console.log('officiallyFilter called')

            const orgCode = self.state.orgCode
            const placementPartners = self.state.placementPartners
            const accountCode = self.state.accountCode
            const search = true
            const postTypes = ['Internship','Work','Assignment','Problem','Challenge','Event']

            Axios.get('/api/postings/search', { params: { searchString, accountCode, search, postTypes, orgCode, placementPartners } })
            .then((response) => {
              console.log('Opportunity search query attempted', response.data);

                if (response.data.success) {
                  console.log('opportunity search query worked')

                  let opportunityOptions = response.data.postings
                  self.setState({ opportunityOptions, searchIsAnimatingOpportunities: false })

                } else {
                  console.log('opportunity search query did not work', response.data.message)

                  let opportunityOptions = []
                  self.setState({ opportunityOptions, searchIsAnimatingOpportunities: false })

                }

            }).catch((error) => {
                console.log('Search query did not work for some reason', error);
                self.setState({ searchIsAnimatingOpportunities: false })
            });
          }

          const delayFilter = () => {
            console.log('delayFilter called: ')
            clearTimeout(self.state.timerId)
            self.state.timerId = setTimeout(officiallyFilter, 1000)
          }

          delayFilter();
        }
      }
    }

    searchItemClicked(passedItem, type) {
      console.log('searchItemClicked called', passedItem, type)

      if (type === 'entity') {
        const searchObject = passedItem
        let searchEntities = passedItem.firstName + ' ' + passedItem.lastName
        // if (passedItem.name) {
        //   searchEntities = passedItem.name
        // } else if (passedItem.employerName) {
        //   searchEntities = passedItem.employerName
        // }
        const unready = false
        const entityOptions = null
        console.log('show entity values: ', searchEntities, passedItem)

        this.setState({ searchObject, searchEntities, unready, entityOptions })
      } else if (type === 'opportunity') {
        const searchObject = passedItem
        let searchOpportunities = passedItem.name
        if (passedItem.title) {
          searchOpportunities = passedItem.title
        }
        const unready = false
        const opportunityOptions = null

        this.setState({ searchObject, searchOpportunities, unready, opportunityOptions })
      }
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'entity') {

        const searchEntities = ''
        const searchObject = null
        const unready = true

        let selectedEntities = this.state.selectedEntities
        selectedEntities.unshift(this.state.searchEntities)

        let selectedEntityDetails = this.state.selectedEntityDetails
        selectedEntityDetails.unshift(this.state.searchObject)

        this.setState({ searchEntities, searchObject, unready, selectedEntities, errorMessage: null })
      } else if (type === 'placement') {

        if (!this.state.placement || this.state.placement === '') {
          this.setState({ errorMessage: 'Please select a placement' })
        } else {
          let selectedPlacements = this.state.selectedPlacements
          selectedPlacements.push(this.state.placement)

          let selectedPlacementDetails = []
          for (let i = 1; i <= this.state.placements.length; i++) {
            if (this.state.placements[i - 1].title + ' @ ' + this.state.placements[i - 1].employerName === this.state.placement) {
              selectedPlacementDetails.push(this.state.placements[i - 1])
            }
          }

          console.log('got the placement? ', selectedPlacementDetails)
          let placement = ''
          this.setState({ selectedPlacements, selectedPlacementDetails, placement, errorMessage: null })
          this.configureEmails(this.state.party, selectedPlacementDetails)
        }

      } else if (type === 'benchmark') {
        let selectedBenchmarks = this.state.selectedBenchmarks
        selectedBenchmarks.push(this.state.tempBenchmark)

        let selectedBenchmarkDetails = []
        for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
          if (this.state.benchmarkOptions[i - 1].title === this.state.tempBenchmark) {
            selectedBenchmarkDetails.push(this.state.benchmarkOptions[i - 1])
          }
        }
        let tempBenchmark = ''
        this.setState({ selectedBenchmarks, selectedBenchmarkDetails, tempBenchmark })
      }
    }

    renderTags(type) {
      console.log('renderTags ', type, this.state.selectedCareer)

      if (type === 'entity') {
        const selectedEntities = this.state.selectedEntities
        if (selectedEntities && selectedEntities.length > 0) {

          return (
            <div key={"selectedEntities"}>
              <div className="spacer" />
              {selectedEntities.map((value, optionIndex) =>
                <div key={"entity|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className={(this.state.selectedEntity === value) ? "tag-container-active" : "tag-container-inactive"}>
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      } else if (type === 'placement') {
        const selectedPlacements = this.state.selectedPlacements
        if (selectedPlacements && selectedPlacements.length > 0) {

          return (
            <div key={"placement"}>
              <div className="spacer" />
              {selectedPlacements.map((value, optionIndex) =>
                <div key={"career|" + optionIndex} className="float-left">

                  <div className="close-button-container-1" >
                    <button className="background-button" onClick={() => this.removeTag(optionIndex,type)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className="background-button float-left right-padding-5">
                    <div className="half-spacer" />
                    <div className="tag-container-inactive">
                      <p className="description-text-2">{value}</p>
                    </div>
                    <div className="half-spacer" />
                  </div>

                </div>
              )}
            </div>
          )
        }
      }
    }

    removeTag(index, type) {
      console.log('removeTag called', index, type)

      if (type === 'entity') {
        let selectedEntities = this.state.selectedEntities
        selectedEntities.splice(index, 1)

        let selectedEntityDetails = this.state.selectedEntityDetails
        // const entityToRemove = selectedEntityDetails[index]
        selectedEntityDetails.splice(index, 1)
        this.setState({ selectedEntities, selectedEntityDetails })
      } else if (type === 'placement') {
        let selectedPlacements = this.state.selectedPlacements
        selectedPlacements.splice(index, 1)

        let selectedPlacementDetails = this.state.selectedPlacementDetails

        selectedPlacementDetails.splice(index, 1)
        this.setState({ selectedPlacements, selectedPlacementDetails })
        this.configureEmails(this.state.party, selectedPlacementDetails)
      }
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showMessageWidget: false })
    }

    sendMessage() {
      console.log('sendMessage called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.selectedPlacements || this.state.selectedPlacements.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'Please add at least one placement'})
      } else if (!this.state.party || this.state.party === '') {
        this.setState({ isSaving: false, errorMessage: 'Please select a party'})
      } else if (!this.state.orgProgramName || this.state.orgProgramName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your program name'})
      } else if (!this.state.orgContactTitle || this.state.orgContactTitle === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your title (or that of the main contact person'})
      } else if (!this.state.orgContactFirstName || this.state.orgContactFirstName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your first name (or that of the main contact person'})
      } else if (!this.state.orgContactLastName || this.state.orgContactLastName === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your last name (or that of the main contact person'})
      } else if (!this.state.orgContactEmail || this.state.orgContactEmail === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your email (or that of the main contact person'})
      } else if (!this.state.orgContactPhone || this.state.orgContactPhone === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your phone number (or that of the main contact person'})
      } else if (!this.state.emails || this.state.emails.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'No workers found in placement'})
      } else {

        const selectedPlacementDetails = this.state.selectedPlacementDetails
        const party = this.state.party
        const orgProgramName = this.state.orgProgramName
        const orgContactTitle = this.state.orgContactTitle
        const orgContactFirstName = this.state.orgContactFirstName
        const orgContactLastName = this.state.orgContactLastName
        const orgContactEmail = this.state.orgContactEmail
        const orgContactPhone = this.state.orgContactPhone
        let emails = this.state.emails

        let uniqueEmployerEmails = []
        let employerContactCounts = []
        for (let i = 1; i <= emails.length; i++) {
          if (!uniqueEmployerEmails.includes(emails[i - 1].to.email)) {
            uniqueEmployerEmails.push(emails[i - 1].to.email)
            employerContactCounts.push({ email: emails[i - 1].to.email, count: 1, indices: [i - 1]})
          } else {
            const tempIndex = employerContactCounts.findIndex(x => x.email === emails[i - 1].to.email);

            employerContactCounts[tempIndex]['count'] = employerContactCounts[tempIndex].count + 1
            employerContactCounts[tempIndex]['indices'].push(i - 1)
          }
        }
        for (let i = 1; i <= emails.length; i++) {
          const tempIndex = employerContactCounts.findIndex(x => x.email === emails[i - 1].to.email);
          if (employerContactCounts[tempIndex].count > 1) {

            const counter = employerContactCounts[tempIndex].indices.indexOf(i - 1)
            emails[i - 1]['title'] = emails[i - 1].title + ' (' + (counter + 1).toString() + '/' + employerContactCounts[tempIndex].count + ')'
            // console.log('compare the two: ', i - 1, counter, employerContactCounts[tempIndex].indices)
          }
        }

        console.log('show all the emails: ', emails)

        Axios.post('/api/notifications/pre-constructed/message', {
          selectedPlacementDetails, party, orgProgramName, orgContactTitle, orgContactFirstName, orgContactLastName,
          orgContactEmail, orgContactPhone, emails,
          createdAt: new Date(), updatedAt: new Date() })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Message send worked', response.data);

            this.setState({ isSaving: false, successMessage: 'Message(s) sent successfully!',
              party: '', selectedPlacements: [], selectedPlacementDetails: []
            })
          } else {
            console.error('there was an error sending the message');
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('Message send did not work', error);
        });
      }
    }

    configureEmails(party, placements) {
      console.log('configureEmails called', party, placements)

      let emails = this.state.emails
      if (placements && placements.length > 0) {
        if (party === 'Employers') {
          for (let i = 1; i <= placements.length; i++) {

            let participantList = ''
            for (let j = 1; j <= placements[i - 1].workers.length; j++) {
              participantList = participantList + '<div style="padding: 15px 0px 5px 0px;"><ul style="padding-left: 15px; list-style: none;">'
              participantList = participantList + '<li><label style="font-weight: bold">Participant:</label> ' + placements[i - 1].workers[j - 1].firstName + ' ' + placements[i - 1].workers[j - 1].lastName + '</li>'
              participantList = participantList + '<li><label style="font-weight: bold">Participant Contact Information:</label> ' + placements[i - 1].workers[j - 1].email + '</li>'
              participantList = participantList + '<li><label style="font-weight: bold">Participant Portfolio:</label> <a href="https://www.guidedcompass.com/employers/' + placements[i - 1].accountCode + '/postings/' + placements[i - 1].postingId + '/applications/' + placements[i - 1].workers[j - 1].applicationId + '">https://www.guidedcompass.com/employers/' + placements[i - 1].accountCode + '/postings/' + placements[i - 1].postingId + '/applications/' + placements[i - 1].workers[j - 1].applicationId + '</a></li>'
              participantList = participantList + '<li><label style="font-weight: bold">Title:</label> ' + placements[i - 1].title + '</li>'
              participantList = participantList + '</ul></div>'
            }

            let employerEmailTitle = this.state.employerEmailTemplate.title
            employerEmailTitle = employerEmailTitle.replace(/{{orgProgramName}}/g,this.state.orgProgramName)
            employerEmailTitle = employerEmailTitle.replace(/{{positionTitle}}/g,placements[i - 1].title)
            employerEmailTitle = employerEmailTitle.replace(/{{employerName}}/g,placements[i - 1].employerName)

            let employerEmailMessage = this.state.employerEmailTemplate.message
            employerEmailMessage = employerEmailMessage.replace(/{{employerContactFirstName}}/g,placements[i - 1].hiringManagerFirstName)
            employerEmailMessage = employerEmailMessage.replace(/{{orgProgramName}}/g,this.state.orgProgramName)
            employerEmailMessage = employerEmailMessage.replace(/{{employerName}}/g,placements[i - 1].employerName)
            employerEmailMessage = employerEmailMessage.replace(/{{participantList}}/g, participantList)
            employerEmailMessage = employerEmailMessage.replace(/{{orgContactFirstName}}/g,this.state.orgContactFirstName)
            employerEmailMessage = employerEmailMessage.replace(/{{orgContactLastName}}/g,this.state.orgContactLastName)
            employerEmailMessage = employerEmailMessage.replace(/{{orgContactTitle}}/g,this.state.orgContactTitle)
            employerEmailMessage = employerEmailMessage.replace(/{{orgContactEmail}}/g,this.state.orgContactEmail)
            employerEmailMessage = employerEmailMessage.replace(/{{orgContactPhone}}/g,this.state.orgContactPhone)
            employerEmailMessage = employerEmailMessage.replace(/{{positionTitle}}/g,placements[i - 1].title)

            emails.push({
              from: { name: this.state.orgContactFirstName + ' ' + this.state.orgContactLastName + ' via Guided Compass', email: this.state.orgContactEmail, display: this.state.orgContactEmail + ' (' + this.state.orgContactFirstName + ' ' + this.state.orgContactLastName + ' via Guided Compass)' },
              to: { email: placements[i - 1].hiringManagerEmail, display: placements[i - 1].hiringManagerEmail + ' (' + placements[i - 1].hiringManagerFirstName + ' ' + placements[i - 1].hiringManagerLastName + ')'},
              cc: [placements[i - 1].orgContactEmail],
              bcc: '',
              title: employerEmailTitle,
              message: employerEmailMessage
            })
          }
        }
      }
    }

    toggleMenu(e, index) {
      console.log('toggleMenu called')

      e.stopPropagation()
      e.preventDefault()

      let placements = this.state.placements
      if (this.state.placements[index].showMenu) {
        placements[index]['showMenu'] = false
      } else {
        placements[index]['showMenu'] = true
      }

      this.setState({ placements })

    }

    menuButtonClicked(e, action, index) {
      console.log('menuButtonClicked called', action, index)

      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (action === 'duplicate') {
        let pathname = '/organizations/' + this.state.org + '/placements/add'
        if (window.location.pathname.includes('/employers')) {
          pathname = '/employers/' + this.state.accountCode + '/hires/add'
        } else if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/placements/add'
        }

        let selectedPlacement = this.state.placements[index]
        this.props.navigate(pathname, { state: { selectedPlacement, duplicate: true }})

      } else if (action === 'edit') {
        let pathname = '/organizations/' + this.state.org + '/placements/' + this.state.placements[index]._id
        if (window.location.pathname.includes('/employers')) {
          pathname = '/employers/' + this.state.accountCode + '/hires/' + this.state.placements[index]._id
        } else if (window.location.pathname.includes('/advisor')) {
          pathname = '/advisor/placements/' + this.state.placements[index]._id
        }

        this.props.navigate(pathname, { state: { selectedPlacement: this.state.placements[index] }})

      } else if (action === 'delete') {
        let placements = this.state.placements
        if (placements[index].confirmDelete) {
          placements[index]['confirmDelete'] = false
        } else {
          placements[index]['confirmDelete'] = true
        }
        this.setState({ placements })
      }
    }

    deleteItem(index) {
      console.log('deleteItem called', index)

      let placements = this.state.placements
      const _id = placements[index]._id

      Axios.delete('/api/placements/' + _id)
      .then((response) => {
        console.log('tried to  delete', response.data)
        if (response.data.success) {
          //save values
          console.log('Placement delete worked')

          placements.splice(index,1)
          this.setState({ placements, isSaving: false })

        } else {
          console.error('there was an error deleting the placement');

          let individualPlacementsCount = this.state.individualPlacementsCount
          let placementCount = this.state.placementCount
          if (placements[index].workers && placements[index].workers.length) {
            individualPlacementsCount = individualPlacementsCount - placements[index].workers.length
            placementCount = placementCount - 1
          }

          placements[index]['errorMessage'] = response.data.message
          this.setState({ placements, individualPlacementsCount, placementCount, isSaving: false })
        }
      }).catch((error) => {
          console.log('The deleting did not work', error);
          placements[index]['errorMessage'] = error.toString()
          this.setState({ placements, isSaving: false })
      });
    }

    render() {

      let editPlacementLinkPrefix = '/organizations/' + this.state.org + '/placements/'
      if (window.location.pathname.includes('/employers')) {
        editPlacementLinkPrefix = '/employers/' + this.state.accountCode + '/hires/'
      } else if (window.location.pathname.includes('/advisor')) {
        editPlacementLinkPrefix = '/advisor/placements/'
      }

      let mainClassName = "floating-container card top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"
      let mainStyle = {...styles, opacity: this.state.opacity, transform: this.state.transform, marginBottom: '0', padding: '15px 20px'}
      if (this.props.renderSideNav === false || (toggleVars.hideSideNav && window.location.pathname.includes('/advisor'))) {
        mainClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card"
        mainStyle = {}
      } else if (window.location.pathname.startsWith('/organizations') && toggleVars.hideAdminSideNav) {
        mainClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally card"
        mainStyle = {}
      }

      let paginationClassName = "floating-container left-margin-2-percent"
      if (window.location.pathname.includes('/employers')) {
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
      } else if (window.location.pathname.includes('/organizations') && toggleVars.hideAdminSideNav) {
        paginationClassName = "width-70-percent top-margin-2-percent max-width-1400 center-horizontally"
      }

      return (
          <div>
            <div className={mainClassName} style={mainStyle}>
              <div className="row-10">
                <div className="calc-column-offset-30 right-padding-20">
                  <p className="heading-text-2">{this.state.placementCount} {(window.location.pathname.includes('/employers')) ? "Hires" : "Placements"}</p>
                </div>
                {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (
                  <div>
                    {/*
                    <div className="fixed-column-45 right-padding-20">
                      <div className="half-spacer" />
                      <button className="background-button float-left" onClick={() => this.setState({ modalIsOpen: true, showMessageWidget: true })}>
                        <img src={sendIconDark} alt="GC" className="add-icon" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Notify career-seekers and employers of placement" />
                      </button>
                    </div>*/}
                    <div className="fixed-column-30">
                      <div className="half-spacer" />
                      <Link className="background-button float-left" to={editPlacementLinkPrefix + 'add'}>
                        <img src={addIcon} alt="GC" className="image-auto-25" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Add a placement" />
                      </Link>
                    </div>
                  </div>
                )}
                <ReactTooltip id="tooltip-placeholder-id" />
                <div className="clear" />

                <p className="description-text-2 row-5 standard-color">Create and edit placements for internships and work opportunities; approve timesheets.</p>
              </div>

            </div>

            {(this.state.placements.length > 0) && (
              <div className={paginationClassName}>
                <div className="top-margin-2-percent">
                  <div className="relative-column-20">
                    <button className={(this.state.pageNumber === 1) ? "background-button full-width left-text wash-out-2" : "background-button full-width left-text"} disabled={(this.state.pageNumber === 1) ? true : false} onClick={() => this.movePage(false)}>
                      <div className="fixed-column-20">
                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 rotate-180" />
                      </div>
                      <div className="calc-column-offset-20">
                        <p className="full-width left-text">Previous</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>
                  <div className="relative-column-60">
                    <p className="full-width center-text">{this.state.pageCountStart} - {(this.state.pageCountEnd < this.state.placementCount) ? this.state.pageCountEnd : this.state.placementCount} of {this.state.placementCount}</p>
                  </div>
                  <div className="relative-column-20">
                    <button className={(this.state.pageCountEnd === this.state.placementCount) ? "background-button full-width right-text wash-out-2" : "background-button full-width right-text"} disabled={(this.state.pageCountEnd === this.state.placementCount) ? true : false} onClick={() => this.movePage(true)}>
                      <div className="calc-column-offset-20">
                        <p className="full-width right-text">Next</p>
                      </div>
                      <div className="fixed-column-20 right-text">
                        <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-15 pin-right" />
                      </div>
                      <div className="clear" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  {(this.state.orgFocus === 'Placement') ? (
                    <p className="center-text cta-color bold-text">Calculating results...</p>
                  ) : (
                    <p className="center-text cta-color bold-text">Calculating results and work matches...</p>
                  )}

                </div>
              </div>
            ) : (
              <div className={mainClassName} style={mainStyle}>
                {(this.state.placements && this.state.placements.map((value, index) =>
                  <div key={value}>
                    {(this.state.newFormat) ? (
                      <div className="row-10">
                        <div>
                          <Link to={editPlacementLinkPrefix + value._id} state={{ selectedPlacement: value }} className="background-link">
                            <span className="fixed-column-50">
                              <div className="half-spacer"/>
                              <img src={placementsIconBlue} alt="Guided Compass project icon" className="image-auto-50"/>
                            </span>
                            <span className={(value.placementPartners && value.placementPartners.includes(this.state.org)) ? "calc-column-offset-190 left-padding-20" : "calc-column-offset-140 left-padding-20"}>
                              <div className="full-width">
                                {(window.location.pathname.includes('/employers')) ? (
                                  <p className="heading-text-4 standard-color">{value.title}</p>
                                ) : (
                                  <p className="heading-text-4 standard-color">{value.title} @ {value.employerName}</p>
                                )}
                              </div>
                              <p className="full-width description-text-2 standard-color top-padding">{value.workType} | {value.startDate && value.startDate.substring(0,10)} - {value.endDate ? value.endDate.substring(0,10) : "Continual"} | {value.hoursPerWeek}</p>
                              <p className="full-width description-text-2 cta-color top-padding">{(value.workers && value.workers.length === 1) ? "1 Person" : value.workers.length + " People"} Placed</p>
                            </span>

                            {(value.placementPartners && value.placementPartners.includes(this.state.org)) && (
                              <div className="fixed-column-30 right-margin">
                                <p className="description-text-6 wrap-word">{value.orgCode}</p>
                                <img src={partnerIconDark} alt="Compass arrow indicator icon" className="image-auto-25"/>
                              </div>
                            )}

                            <div className="fixed-column-50 left-padding top-margin-5 relative-position">
                              <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                                <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                              </button>

                              {(value.showMenu) && (
                                <div className="menu-bottom-right top-margin">
                                  {this.state.menuOptions.map((item,optionIndex) =>
                                    <div key={item.title}>
                                      {((item.title.includes('Delete') && value.orgCode === this.state.org) || !item.title.includes('Delete')) && (
                                        <div>
                                          {(item.title === 'Delete placement' && value.confirmDelete) ? (
                                            <div>
                                              <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                              <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(index)}>Confirm</button>
                                              <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>Cancel</button>
                                            </div>
                                          ) : (
                                            <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>
                                              <div className="fixed-column-25">
                                                <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                              </div>
                                              <div className="calc-column-offset-25">
                                                <p className={(item.title === 'Delete benchmark') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                              </div>
                                              <div className="clear" />
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="fixed-column-33 left-padding">
                              <span className="float-left">
                                <div className="spacer"/>
                                <Link to={editPlacementLinkPrefix + value._id} state={{ selectedPlacement: value }} className="background-link">
                                  <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                </Link>
                              </span>
                            </div>
                            <div className="clear" />
                          </Link>

                          {(value.successMessage) && (
                            <div className="row-5 left-padding">
                              <p className="description-text-2 cta-color full-width">{this.state.filteredBenchmarks[index].successMessage}</p>
                            </div>
                          )}
                        </div>
                        <div className="clear" />


                        <hr className="cell-separator-advisor"/>
                        <div className="spacer"/>
                      </div>
                    ) : (
                      <div>
                        {(value.workers) && (
                          <div>
                            {value.workers && value.workers.map((value2, index2) =>
                              <div key={value2}>
                                <div>
                                  <div className="spacer"/>

                                  <Link to={editPlacementLinkPrefix + value._id} state={{ selectedPlacement: value }} className="background-link">
                                    <span className="fixed-column-50">
                                      <div className="half-spacer"/>
                                      <img src={placementsIconBlue} alt="Guided Compass project icon" className="image-auto-50"/>
                                    </span>
                                    <span className={(value.placementPartners && value.placementPartners.includes(this.state.org)) ? "calc-column-offset-190 left-padding-20" : "calc-column-offset-140 left-padding-20"}>
                                      <div className="full-width">
                                        {(window.location.pathname.includes('/employers')) ? (
                                          <p className="heading-text-4">{value2.firstName} {value2.lastName} as {value.title}</p>
                                        ) : (
                                          <p className="heading-text-4">{value2.firstName} {value2.lastName} as {value.title} @ {value.employerName}</p>
                                        )}
                                      </div>
                                      <p className="full-width description-text-2 description-text-color">{value.workType} | {value.startDate && value.startDate.substring(0,10)} - {value.endDate ? value.endDate.substring(0,10) : "Continual"} | {value.hoursPerWeek}</p>
                                    </span>

                                    {(value.placementPartners && value.placementPartners.includes(this.state.org)) && (
                                      <div className="fixed-column-30 right-margin">
                                        <p className="description-text-6 wrap-word">{value.orgCode}</p>
                                        <img src={partnerIconDark} alt="Compass arrow indicator icon" className="image-auto-25"/>
                                      </div>
                                    )}

                                    <div className="fixed-column-50 left-padding top-margin-5">
                                      <button className="background-button clear-margin clear-padding light-background row-10 horizontal-padding-7 standard-border slightly-rounded-corners" onClick={(e) => this.toggleMenu(e,index)}>
                                        <img src={menuIconDark} alt="GC" className="image-15-auto"/>
                                      </button>
                                    </div>

                                    <div className="fixed-column-33 left-padding">
                                      <span className="float-left">
                                        <div className="spacer"/>
                                        <Link to={editPlacementLinkPrefix + value._id} state={{ selectedPlacement: value }} className="background-link">
                                          <img src={arrowIndicatorIcon} alt="Compass arrow indicator icon" className="image-auto-22"/>
                                        </Link>
                                      </span>
                                    </div>
                                    <div className="clear" />
                                    <div className="half-spacer" />
                                  </Link>

                                  {(value.showMenu) && (
                                    <div className="menu-bottom-4">
                                      {this.state.menuOptions.map((item,optionIndex) =>
                                        <div key={item.title}>
                                          <div>
                                            {(item.title === 'Delete placement' && value.confirmDelete) ? (
                                              <div>
                                                <p className="description-text-2 error-color bold-text top-margin">You sure?</p>
                                                <button className="btn btn-squarish error-background-color right-margin clear-border description-text-3" onClick={() => this.deleteItem(index)}>Confirm</button>
                                                <button className="btn btn-squarish white-background cta-color description-text-3" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>Cancel</button>
                                              </div>
                                            ) : (
                                              <button className="background-button row-10 clear-margin left-text full-width" onClick={(e) => this.menuButtonClicked(e, item.shorthand,index)}>
                                                <div className="fixed-column-25">
                                                  <img src={item.icon} alt="GC" className="image-auto-14 top-margin-3 contain-object-fit"/>
                                                </div>
                                                <div className="calc-column-offset-25">
                                                  <p className={(item.title === 'Delete benchmark') ? "description-text-2 error-color bold-text" : "description-text-2"}>{item.title}</p>
                                                </div>
                                                <div className="clear" />
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {(value.successMessage) && (
                                    <div className="row-5 left-padding">
                                      <p className="description-text-2 cta-color full-width">{this.state.filteredBenchmarks[index].successMessage}</p>
                                    </div>
                                  )}
                                </div>
                                <div className="clear" />


                                <hr className="cell-separator-advisor"/>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}

              </div>
            )}

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
             ariaHideApp={false}
           >

              {(this.state.showMessageWidget) && (
                <div key="showMatchingCriteria" className="full-width padding-20">
                  <p className="heading-text-2">Notify Matched Parties</p>

                  <div className="spacer" />

                  {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="description-text-2 error-color row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage && this.state.successMessage !== '') && <p className="description-text-2 cta-color row-5">{this.state.successMessage}</p>}

                  <div className="row-10">
                    <label className="profile-label">Which party would you like to send to?</label>
                    <select name="party" value={this.state.party} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.partyOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>

                  {(this.state.party && this.state.party !== '') && (
                    <div>
                      <div className="row-10">
                        <label className="profile-label">Add the placements you want to notify</label>
                        <div className="calc-column-offset-70">
                          <select name="placement" value={this.state.placement} onChange={this.formChangeHandler} className="dropdown">
                            {[{ title: ''}].concat(this.state.placements).map(value =>
                              <option key={(value.employerName) && value.title + ' @ ' + value.employerName} value={(value.employerName) && value.title + ' @ ' + value.employerName}>{(value.employerName) && value.title + ' @ ' + value.employerName}</option>
                            )}
                          </select>
                        </div>
                        <div className="fixed-column-70 left-padding">
                          <button className="btn btn-squarish" onClick={() => this.addItem('placement')}>Add</button>
                        </div>
                        <div className="clear" />

                        <div>
                          {this.renderTags('placement')}
                          <div className="clear" />
                        </div>
                      </div>

                      {(this.state.selectedPlacements && this.state.selectedPlacements.length > 0) && (
                        <div>

                          {(this.state.party === 'Employers') && (
                            <div>

                              <hr />

                              <div>
                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">Org Program Name</label>
                                    <input type="text" className="text-field" placeholder="Your first name..." name="orgProgramName" value={this.state.orgProgramName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">{this.state.orgProgramName} Contact Title</label>
                                    <input type="text" className="text-field" placeholder="Your title..." name="orgContactLastName" value={this.state.orgContactTitle} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">{this.state.orgProgramName} Contact First Name</label>
                                    <input type="text" className="text-field" placeholder="Your first name..." name="orgContactFirstName" value={this.state.orgContactFirstName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">{this.state.orgProgramName} Contact Last Name</label>
                                    <input type="text" className="text-field" placeholder="Your last name..." name="orgContactLastName" value={this.state.orgContactLastName} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <div className="row-10">
                                  <div className="container-left">
                                    <label className="profile-label">{this.state.orgProgramName} Contact Email</label>
                                    <input type="text" className="text-field" placeholder="Your email..." name="orgContactEmail" value={this.state.orgContactEmail} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="container-right">
                                    <label className="profile-label">{this.state.orgProgramName} Contact Phone</label>
                                    <input type="text" className="text-field" placeholder="Your phone number..." name="orgContactLastName" value={this.state.orgContactPhone} onChange={this.formChangeHandler}/>
                                  </div>
                                  <div className="clear" />
                                </div>

                              </div>

                              <hr />

                              <div className="row-10">
                                <label className="profile-label">Show email previews</label>
                                <Switch
                                  onChange={(change) => this.setState({ showEmailPreviews: change })}
                                  checked={this.state.showEmailPreviews}
                                  disabled={this.state.isSaving}
                                  id="normal-switch"
                                />
                              </div>

                              {(this.state.showEmailPreviews) && (
                                <div className="row-10">
                                  <p className="heading-text-5">Emails To Be Sent</p>
                                  <div className="spacer" />

                                  {(this.state.emails && this.state.emails.length > 0) && (
                                    <div>
                                      {this.state.emails.map((value, optionIndex) =>
                                        <div key={value._id} className="row-10">
                                          <label className="profile-label">Email #{optionIndex + 1}</label>
                                          <div className="description-text-2">
                                            <p><label className="bold-text">From: </label>{value.from.display}</p>
                                            <p><label className="bold-text">To: </label>{value.to.display}</p>
                                            <p><label className="bold-text">CC: </label>{value.cc}</p>
                                            <p><label className="bold-text">BCC: </label>{value.bcc}</p>
                                            <p><label className="bold-text">Title: </label>{value.title}</p>
                                            <div className="spacer" />
                                            <div dangerouslySetInnerHTML={{ __html: value.message }} />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                </div>
                              )}

                            </div>
                          )}

                          {(this.state.party === 'Workers') && (
                            <div className="row-10">
                              <p className="error-color">Want to send a email to the matched workers / participants? Send your preferred email template to help@guidedcompass.com.</p>
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  )}

                  <div className="row-10">
                    <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.sendMessage()}>Send Notifications</button>
                  </div>

                </div>
              )}

           </Modal>
          </div>

      )
    }
}

export default withRouter(Placements);
