import React, {Component} from 'react';
import Axios from 'axios';
// import { Link } from 'react-router-dom';
// const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
import Switch from 'react-switch';

const addIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png';
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';

class MentorMatchingAlgorithm extends Component {
    constructor(props) {
      super(props)

      this.state = {
        sections: [],
        fieldOptions: [],
        conditionalFieldOptions: [],
        roleNameOptions: ['','Career-Seeker','Mentor'],
        operatorOptions: ['','Equal To','Less Than','Greater Than']
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.addItem = this.addItem.bind(this)
      this.itemClicked = this.itemClicked.bind(this)
      this.saveChanges = this.saveChanges.bind(this)
      this.removeItem = this.removeItem.bind(this)
    }

    componentDidMount() {
      //see if user is logged in

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in commonMentorMatchingAlgorithm', this.props, prevProps)

      if (this.props.selectedAlgorithm !== prevProps.selectedAlgorithm) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called i commonMentorMatchingAlgorithm')

      const selectedAlgorithm = this.props.selectedAlgorithm

      let emailId = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let roleName = localStorage.getItem('roleName');
      let activeOrg = localStorage.getItem('activeOrg');

      let _id = null
      let name = null
      let sections = []
      let passedOrgCode = null
      if (selectedAlgorithm) {
        _id = selectedAlgorithm._id
        name = selectedAlgorithm.name
        sections = selectedAlgorithm.sections
        passedOrgCode = selectedAlgorithm.orgCode
      } else {
        passedOrgCode = activeOrg
      }
      // console.log('was an algo gotten: ', _id)
      this.setState({
        emailId, username, cuFirstName, cuLastName, roleName, activeOrg,
        _id, name, sections, passedOrgCode
      })

      Axios.get('/api/workoptions')
      .then((response) => {
        console.log('Work options query tried');

        if (response.data.success) {
          console.log('Work options query succeeded')

          const fieldOptions = [''].concat(response.data.workOptions[0].profileValueOptions)
          const conditionalFieldOptions = [''].concat(response.data.workOptions[0].conditionalFieldOptions)
          this.setState({ fieldOptions, conditionalFieldOptions  });

        }
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.name, event.target.value)

      if (event.target.name.startsWith('section|')) {
        let sections = this.state.sections
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        if (name === 'conditions') {
          const name2 = event.target.name.split("|")[3]
          const index2 = Number(event.target.name.split("|")[4])
          sections[index][name][index2][name2] = event.target.value
        } else {
          sections[index][name] = event.target.value
        }

        this.setState({ sections })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    saveChanges() {
      console.log('saveChanges called', this.state.passedOrgCode, this.state.activeOrg)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (this.state.name === 'Default') {
        this.setState({ isSaving: false, errorMessage: 'You cannot edit the default algorithm' })
      } else if (this.state.passedOrgCode !== this.state.activeOrg) {
        this.setState({ isSaving: false, errorMessage: 'You cannot edit the algorithm of another organization' })
      } else if (!this.state.name || this.state.name === '') {
        this.setState({ isSaving: false, errorMessage: 'Please name your algorithm' })
      } else if (!this.state.sections || this.state.sections.length === 0) {
        this.setState({ isSaving: false, errorMessage: 'Please add a section' })
      } else {
        console.log('officially save')

        for (let i = 1; i <= this.state.sections.length; i++) {
          if (!this.state.sections[i - 1].name || this.state.sections[i - 1].name === '') {
            return this.setState({ isSaving: false, errorMessage: 'Please name your section(s)' })
          } else if (!this.state.sections[i - 1].careerSeekerField || this.state.sections[i - 1].careerSeekerField === '') {
            return this.setState({ isSaving: false, errorMessage: 'Please name your career-seeker field(s)' })
          } else if (!this.state.sections[i - 1].operator || this.state.sections[i - 1].operator === '') {
            return this.setState({ isSaving: false, errorMessage: 'Please add your operator(s)' })
          } else if (!this.state.sections[i - 1].mentorField || this.state.sections[i - 1].mentorField === '') {
            return this.setState({ isSaving: false, errorMessage: 'Please name your mentor field(s)' })
          } else if (this.state.sections[i - 1].required !== true && this.state.sections[i - 1].required !== false && (!this.state.sections[i - 1].weight || this.state.sections[i - 1].weight === '')) {
            return this.setState({ isSaving: false, errorMessage: 'Please indicate that this is required or add a weight' })
          }
        }

        let _id = this.state._id
        const name = this.state.name
        const sections = this.state.sections
        const algorithmType = 'Mentor Matching'
        const orgCode = this.state.activeOrg
        const emailId = this.state.emailId

        const createdAt = new Date()
        const updatedAt = new Date()

        const algoData = { _id, name, sections, algorithmType, orgCode, emailId, createdAt, updatedAt }

        Axios.post('/api/algorithms', algoData)
        .then((response) => {
          console.log('Algorithm query worked', response.data);

          if (response.data.success) {
            console.log('successfully retrieved matches')

            this.setState({ isSaving: false, _id: response.data._id, successMessage: response.data.message })

          } else {
            console.log('there was an error saving the algorithm', response.data.message)
            this.setState({ isSaving: false, errorMessage: response.data.message })
          }

        }).catch((error) => {
            console.log('Algorithm save did not work', error);
            this.setState({ isSaving: false, errorMessage: error.toString() })
        });
      }
    }

    addItem(type, index) {
      console.log('addItem called')

      if (type === 'section') {
        let sections = this.state.sections
        sections.push({ name: '', required: true })
        this.setState({ sections })
      } else if (type === 'condition') {
        let sections = this.state.sections
        let conditions = this.state.sections[index].conditions
        if (conditions) {
          conditions.push({ name: '', value: '' })
        } else {
          conditions = [{ name: '', value: '' }]
        }
        sections[index]['conditions'] = conditions
        this.setState({ sections })
      }
    }

    removeItem(type, index, index2) {
      console.log('removeItem called')

      if (type === 'section') {
        let sections = this.state.sections
        sections.splice(index,1)
        this.setState({ sections })
      } else if (type === 'condition') {
        let sections = this.state.sections
        let conditions = this.state.sections[index].conditions

        conditions.splice(index2,1)
        sections[index]['conditions'] = conditions
        this.setState({ sections })
      }
    }

    itemClicked(type,index, change) {
      console.log('itemClicked called')

      // if (type === 'required') {
      //
      // }
      let sections = this.state.sections
      sections[index][type] = change
      this.setState({ sections })
    }

    render() {

      return (
        <div>
          <div>
            <div className="calc-column-offset-35">
              <p className="heading-text-2">Mentor Matching Algorithm</p>
            </div>
            <div className="fixed-column-35 top-margin left-padding">
              <button className="background-button full-width" onClick={() => this.addItem('section')}>
                <img src={addIconBlue} alt="GC" className="image-auto-25" />
              </button>
            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">Algorithm Name <label className="error-color">*</label></label>
              <input type="text" className="text-field" placeholder="Algorithm name..." name={"name"} value={this.state.name} onChange={this.formChangeHandler}/>
            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            {this.state.sections.map((item,optionIndex) =>
              <div key={'sectionkey' + optionIndex} className="card-clear-padding relative-position top-margin-15">
                <button className="background-button absolute-position left-0 top-0 top-margin-negative-5 left-margin-negative-5" onClick={() => this.removeItem('section',optionIndex)}>
                  <img className="image-auto-20" alt="GC" src={deniedIcon} />
                </button>
                <div className="padding-20">
                  <div className="row-10">
                    <div className="calc-column-offset-30">
                      <p className="heading-text-4">{(item.name) ? item.name : "Untitled Section"}</p>
                    </div>
                    {(item.weight) && (
                      <div className="fixed-column-30">
                        <p className="cta-color heading-text-5">{item.weight}%</p>
                      </div>
                    )}
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Section Name <label className="error-color">*</label></label>
                      <input type="text" className="text-field" placeholder="Section name..." name={"section|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler}/>
                    </div>
                    {/*
                    <div className="container-right">
                      <label className="profile-label">Section Field <label className="error-color">*</label></label>
                      <select name={"section|field|" + optionIndex} value={item.field} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.fieldOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>*/}
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="relative-column-33">
                      <label className="profile-label">Career Seeker Field <label className="error-color">*</label></label>
                      <select name={"section|careerSeekerField|" + optionIndex} value={item.careerSeekerField} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.fieldOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="relative-column-33 left-padding right-padding">
                      <label className="profile-label">Operator<label className="error-color">*</label></label>
                      <select name={"section|operator|" + optionIndex} value={item.operator} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.operatorOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="relative-column-33">
                      <label className="profile-label">Mentor Field <label className="error-color">*</label></label>
                      <select name={"section|mentorField|" + optionIndex} value={item.mentorField} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.fieldOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Required? <label className="error-color">*</label></label>
                      <Switch
                        onChange={(change) => this.itemClicked('required',optionIndex, change)}
                        checked={item.required}
                        id="normal-switch"
                      />
                    </div>
                    {(item.required === false) && (
                      <div className="container-right">
                        <label className="profile-label">Section Weight <label className="error-color">*</label></label>
                        <input type="number" className="number-field" min="0" max="100" placeholder="Section weight..." name={"section|weight|" + optionIndex} value={item.weight} onChange={this.formChangeHandler}/>
                      </div>
                    )}
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="float-left right-padding">
                      <label className="profile-label">Conditions</label>
                    </div>
                    <div className="float-left top-margin-12">
                      <button className="background-button full-width" onClick={() => this.addItem('condition', optionIndex)}>
                        <img src={addIconBlue} alt="GC" className="image-auto-15" />
                      </button>
                    </div>
                    <div className="clear" />
                    {(item.conditions) && (
                      <div className="bottom-margin">
                        {item.conditions.map((item2,optionIndex2) =>
                          <div key={'condition' + optionIndex2} className="top-margin description-text-1">
                            <div className="fixed-column-30 top-margin-50">
                              <button className="background-button" onClick={() => this.removeItem('condition',optionIndex,optionIndex2)}>
                                <img className="image-auto-20" alt="GC" src={deniedIcon} />
                              </button>
                            </div>
                            <div className="calc-column-offset-30">
                              <div className="relative-column-33 right-padding">
                                <label className="profile-label">Role Name for Value</label>
                                <select name={"section|conditions|" + optionIndex + "|roleName|" + optionIndex2} value={item2.roleName} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.roleNameOptions.map(value =>
                                    <option key={value.key} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="relative-column-33">
                                <label className="profile-label">Condition Field</label>
                                <select name={"section|conditions|" + optionIndex + "|field|" + optionIndex2} value={item2.field} onChange={this.formChangeHandler} className="dropdown">
                                  {this.state.conditionalFieldOptions.map(value =>
                                    <option key={value.key} value={value}>{value}</option>
                                  )}
                                </select>
                              </div>
                              <div className="relative-column-33 left-padding">
                                <label className="profile-label">Condition Value</label>
                                <input type="text" className="text-field" placeholder="Value..." name={"section|conditions|" + optionIndex + "|value|" + optionIndex2} value={item2.value} onChange={this.formChangeHandler}/>
                              </div>
                              <div className="clear" />
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row-20">
            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

            <button className="btn btn-primary" disabled={(this.state.isSaving) ? true : false} onClick={() => this.saveChanges()}>Save Changes</button>
            <button className="btn btn-secondary left-margin" onClick={() => this.props.closeModal()}>Close View</button>
          </div>
        </div>
      )
    }
}

export default MentorMatchingAlgorithm;
