import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import YouTube from 'react-youtube';
import SubComments from '../Common/Comments';
import SubRenderProfiles from '../Common/RenderProfiles';
import SubTakeBasicAssessment from '../Common/TakeBasicAssessment';
// import SubSpeechToText from '../Vendors/SpeechToText';
// import SubTextToSpeech from '../Vendors/TextToSpeech';
import SubAvatar from '../Common/Avatar';
import SubHelpPrompt from '../Common/HelpPrompt';
import SubWorkModeWidget from '../Common/WorkModeWidget';

import {convertDateToString} from '../Functions/ConvertDateToString';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon-white.png';
const arrowIndicatorIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon-dark.png';
const checkmarkIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon-white.png';
const expandIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/expand-icon.png';
const minimizeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/minimize-icon.png';
const addIconWhite = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const reloadIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reload-icon-dark.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
// const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const blackAvatar = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/black_avatar.png';
const blackAvatarGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/black_avatar_gif.gif';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class TakeCourse extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showModule: true,
          showScriptPreviews: true,
          showAvatar: false,
          // testSpeechKit: true,
          showScriptRedesign: true,
          enableFullView: true,
          subNavSelected: 'All',
          subNavCategories: [],
          keepBrackets: true,
          progress: [],
          variablesObject: {}
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.pullCourseDetails = this.pullCourseDetails.bind(this)
        this.toggleModule = this.toggleModule.bind(this)
        this.setLesson = this.setLesson.bind(this)
        this.loadContent = this.loadContent.bind(this)
        this.videoCallback = this.videoCallback.bind(this)
        this.submitPrompt = this.submitPrompt.bind(this)
        this.commandClicked = this.commandClicked.bind(this)
        this.showEditVariables = this.showEditVariables.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.replaceVariables = this.replaceVariables.bind(this)
        this.passResponse = this.passResponse.bind(this)
        this.submitAssignment = this.submitAssignment.bind(this)
        this.toggleCompletion = this.toggleCompletion.bind(this)
        this.calculateCompletions = this.calculateCompletions.bind(this)
        this.testCompletion = this.testCompletion.bind(this)
        this.saveProgress = this.saveProgress.bind(this)
        this.calculateAdjacentLesson = this.calculateAdjacentLesson.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.passData = this.passData.bind(this)

        this.renderCourseTitle = this.renderCourseTitle.bind(this)
        this.renderModules = this.renderModules.bind(this)
        this.renderMessages = this.renderMessages.bind(this)
        this.moveScript = this.moveScript.bind(this)

        this.configureModal = this.configureModal.bind(this)
        this.addItem = this.addItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

        this.configureIndexTracker = this.configureIndexTracker.bind(this)
        this.clearProgress = this.clearProgress.bind(this)
        // this.pullWorkDetails = this.pullWorkDetails.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubTakeCourse', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.courseId !== prevProps.courseId) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubCourseDetails', this.props)

      let email = localStorage.getItem('email');
      let org = localStorage.getItem('activeOrg');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let pictureURL = localStorage.getItem('pictureURL');
      let roleName = localStorage.getItem('roleName');
      let orgName = localStorage.getItem('orgName');
      let orgContactEmail = localStorage.getItem('orgContactEmail');
      const orgLogo = localStorage.getItem('orgLogo')

      let isLoading = true

      let subNavCategories = this.state.subNavCategories
      let subNavSelected = 'All'

      this.setState({ isLoading, orgCode: org, emailId: email, email, username,
        cuFirstName, cuLastName, pictureURL, roleName, subNavCategories, subNavSelected,
        orgName, orgContactEmail, orgLogo
      })

      if (this.props.courseId) {

        Axios.get('/api/courses/byid', { params: { _id: this.props.courseId } })
        .then((response) => {
          console.log('Course query attempted with native database', response.data);

          if (response.data.success) {
            console.log('course query worked natively')

            this.pullCourseDetails(response.data.course, org, email)

          } else {
            console.log('course query did not work', response.data.message)
          }

        }).catch((error) => {
            console.log('Course query in native database did not work for some reason', error);
        });

        Axios.get('/api/submissions', { params: { emailId: email }})
        .then((response) => {
          console.log('Submissions query attempted', response.data);

            if (response.data.success) {
              console.log('submissions query worked')

              const submissions = response.data.submissions
              this.setState({ submissions })

            } else {
              console.log('submissions query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('submissions query did not work for some reason', error);
        });

        Axios.get('/api/favorites', { params: { emailId: email } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites

           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           this.setState({ favorites, newFavorites })

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });
      }
    }

    formChangeHandler = (event) => {
      console.log('formChangeHandler clicked', event.target.name, event.target.value)

      if (event.target.name.startsWith('replacements|')) {
        console.log('in replacements')

        const index = Number(event.target.name.split("|")[1])

        let replacements = this.state.replacements
        if (!replacements) {
          replacements = []
        }

        replacements[index] = event.target.value

        this.setState({ replacements })
      } else if (event.target.name.startsWith("content|")) {
        const contentIndex = Number(event.target.name.split("|")[1])
        const questionIndex = Number(event.target.name.split("|")[3])
        console.log('gimme the indices: ', contentIndex, questionIndex)
        let selectedCourse = this.state.selectedCourse
        selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['content'][contentIndex]['assessment']['questions'][questionIndex]['commandDraft'] = event.target.value
        this.setState({ selectedCourse })
      } else if (event.target.name === 'attachment') {

        let selectedAttachment = null
        if (this.state.attachmentOptions) {
          for (let i = 1; i <= this.state.attachmentOptions.length; i++) {
            if (this.state.attachmentOptions[i - 1].name === event.target.value) {
              selectedAttachment = this.state.attachmentOptions[i - 1]
            }
          }
        }
        this.setState({ selectedAttachment })
      } else {
        this.setState({ [event.target.name] : event.target.value })
      }
    }

    pullCourseDetails(selectedCourse, org, email) {
      console.log('pullCourseDetails called', selectedCourse)

      if (selectedCourse.learningModules && selectedCourse.learningModules.length > 0) {
        for (let i = 1; i <= selectedCourse.learningModules.length; i++) {
          selectedCourse.learningModules[i - 1]['opened'] = true
        }
      }

      let courseId = selectedCourse._id

      let shareURL = window.location.protocol + "//" + window.location.host + "/app/courses/" + courseId
      if (window.location.pathname.includes('/pages/')) {
        shareURL = window.location.protocol + "//" + window.location.host + "/courses/" + courseId
      }
      const shareTitle = 'Check Out ' + selectedCourse.title + ' Course On Guided Compass!'
      let shareBody = 'Check out the ' + selectedCourse.title + ' course on Guided Compass'

      let subNavCategories = ['All','About','Objectives','Syllabus','Announcements','Discussion','Members']
      let subNavSelected = 'All'
      if (window.innerWidth < 768) {
        subNavCategories[0] = 'Modules'
        subNavSelected = 'Modules'
      }

      this.setState({ selectedCourse, isLoading: false, shareURL, shareTitle, shareBody, subNavCategories, subNavSelected })

      Axios.get('/api/comments', { params: { parentPostId: selectedCourse._id, resLimit: 50 } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           if (response.data.comments && response.data.comments.length > 0) {

             this.setState({ comments: response.data.comments })
           }
         } else {
           console.log('no comments data found', response.data.message)
         }

      }).catch((error) => {
         console.log('Comments query did not work', error);
      });

      let firstLessonExists = null

      let progress = []
      if (selectedCourse.learningModules && selectedCourse.learningModules.length > 0) {
        for (let i = 1; i <= selectedCourse.learningModules.length; i++) {
          let lessons = selectedCourse.learningModules[i - 1].lessons
          if (lessons && lessons.length > 0) {
            let pLessons = []
            for (let j = 1; j <= lessons.length; j++) {
              if (!firstLessonExists) {
                firstLessonExists = true
                this.setLesson(lessons[j - 1],j - 1, i - 1)
              }
              pLessons.push({ lessonId: lessons[j - 1].lessonId, name: lessons[j - 1].name, isCompleted: false, indexTracker: 0 })
            }

            progress.push({ moduleId: selectedCourse.learningModules[i - 1].moduleId, name: selectedCourse.learningModules[i - 1].name, isCompleted: false, lessons: pLessons })

          } else {
            progress.push({ moduleId: selectedCourse.learningModules[i - 1].moduleId, name: selectedCourse.learningModules[i - 1].name, isCompleted: false })
          }
        }
      }

      const self = this
      function pullOtherEnrollments() {
        console.log('pullOtherEnrollments called')

        Axios.get('/api/courses/enrollments', { params: { courseId: selectedCourse._id, resLimit: 108 } })
       .then((response) => {
         console.log('Other enrollments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved other enrollments')

           let students = []
           let alumni = []

           for (let i = 1; i <= response.data.enrollments.length; i++) {
             if (response.data.enrollments[i - 1].isCompleted) {
               alumni.push(response.data.enrollments[i - 1])
             } else {
               students.push(response.data.enrollments[i - 1])
             }
           }

           self.setState({ students, alumni })

         } else {
           console.log('no other enrollments data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Other enrollments query did not work', error);
       });
      }

      Axios.get('/api/courses/enrollments', { params: { courseId: selectedCourse._id, email } })
     .then((response) => {
       console.log('Enrollments query attempted', response.data);

       if (response.data.success && response.data.enrollments && response.data.enrollments.length > 0) {
         console.log('successfully retrieved enrollments')

         let courseEnrollment = response.data.enrollments[0]
         // for (let j = 1; j <= response.data.enrollments.length; j++) {
         //   if (response.data.enrollments[j - 1].course)
         // }

         if (courseEnrollment.progress && courseEnrollment.progress.length > 0) {
           for (let j = 1; j <= courseEnrollment.progress.length; j++) {
             const tempProgress = courseEnrollment.progress[j - 1]

             if (progress.some(p => p.moduleId === tempProgress.moduleId)) {
               const tempIndex = progress.findIndex(p => p.moduleId === tempProgress.moduleId)
               // console.log('j: --', tempProgress, tempIndex)
               progress[tempIndex]['isCompleted'] = tempProgress.isCompleted

               if (tempProgress.lessons && tempProgress.lessons.length > 0) {
                 for (let k = 1; k <= tempProgress.lessons.length; k++) {

                   const tempLesson = tempProgress.lessons[k - 1]
                   // console.log('k: --', tempLesson)
                   if (progress[tempIndex].lessons.some(l => l.lessonId === tempLesson.lessonId)) {
                     const lessonIndex = progress[tempIndex].lessons.findIndex(p => p.lessonId === tempLesson.lessonId)
                     progress[tempIndex]['lessons'][lessonIndex]['isCompleted'] = tempLesson.isCompleted
                     progress[tempIndex]['lessons'][lessonIndex]['indexTracker'] = tempLesson.indexTracker

                     if (selectedCourse.learningModules && selectedCourse.learningModules[tempIndex].lessons && selectedCourse.learningModules[tempIndex].lessons[lessonIndex]) {
                        selectedCourse['learningModules'][tempIndex]['lessons'][lessonIndex]['scriptPreviewIndex'] = tempLesson.indexTracker
                     }
                   }
                 }
               }
             }
           }
         }

         this.setState({ courseEnrollment, progress, selectedCourse })

       } else {
         console.log('no enrollments data found', response.data.message)
         this.setState({ progress })
         this.saveProgress(progress)
       }

     }).catch((error) => {
         console.log('Enrollments query did not work', error);
         this.setState({ progress })
     });

     // this.pullWorkDetails(email)

    }

    // pullWorkDetails(emailId) {
    //   console.log('pullWorkDetails called')
    //
    //   Axios.get('/api/users/profile/details/' + emailId, { params: { emailId } })
    //    .then((response) => {
    //      console.log('query for profile data worked');
    //
    //      if (response.data.success) {
    //
    //        console.log('profile data received', response.data)
    //        const workIds = response.data.user.workIds
    //
    //        Axios.get('/api/work', { params: { workIds, onlyCurrent: true } })
    //        .then((response) => {
    //          console.log('Work query attempted within employer dashboard', response.data);
    //
    //          if (response.data.success) {
    //            console.log('account info query worked in sub settings')
    //
    //            const trainings = response.data.workArray
    //            this.setState({ trainings });
    //
    //            Axios.get('/api/timesheets', { params: { emailId, roleName: 'Worker', isSubmitted: false } })
    //            .then((response) => {
    //              console.log('Timesheets query attempted within employer dashboard', response.data);
    //
    //              if (response.data.success) {
    //                console.log('Timesheets query worked in sub settings')
    //
    //                const timesheets = response.data.timesheets
    //                if (timesheets) {
    //                  this.setState({ timesheets });
    //                }
    //              }
    //
    //            }).catch((error) => {
    //              console.log('Timesheets query did not work for some reason', error);
    //            });
    //
    //            Axios.get('/api/assignments', { params: { emailId, postType: 'Assignment', assigneeGroup: 'Private' } })
    //            .then((response) => {
    //              console.log('Assignments query attempted', response.data);
    //
    //              if (response.data.success) {
    //                console.log('assignments query worked')
    //
    //                const assignments = response.data.assignments
    //                if (response.data.assignments) {
    //                  this.setState({ assignments })
    //                }
    //
    //              } else {
    //                console.log('found no assignments', response.data.message)
    //
    //              }
    //
    //            }).catch((error) => {
    //                console.log('Org info query did not work for some reason', error);
    //            });
    //
    //            //pull projects for timesheet project options
    //            Axios.get('/api/projects', { params: { emailId: email } })
    //            .then((response) => {
    //              console.log('Projects query attempted', response.data);
    //
    //                if (response.data.success) {
    //                  console.log('successfully retrieved projects')
    //
    //                  if (response.data.projects) {
    //
    //                    const projects = response.data.projects
    //                    if (projects.length > 0) {
    //                      this.setState({ projects, finishedQuery })
    //                    }
    //                  }
    //
    //                } else {
    //                  console.log('no project data found', response.data.message)
    //                  this.setState({ finishedQuery })
    //                }
    //            }).catch((error) => {
    //                console.log('Project query did not work', error);
    //                this.setState({ finishedQuery })
    //            });
    //          }
    //
    //        }).catch((error) => {
    //          console.log('Account info query did not work for some reason', error);
    //        });
    //
    //      } else {
    //        console.log('error response', response.data)
    //      }
    //
    //  }).catch((error) => {
    //      console.log('query for assessment results did not work', error);
    //  })
    // }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, editVariable: false, selectedIndex: null, selectedIndex2: null,
        showSubmitWidget: false, showFullView: false, attachments: null, selectedSubmission: null,
        showClearProgress: false
      })

      if (this.props.toggleNav) {
        this.props.toggleNav(false,'fromCloseModal')
      }
    }

    toggleModule(index) {
      console.log('toggleModule called', index)

      let selectedCourse = this.state.selectedCourse
      selectedCourse['learningModules'][index]['opened'] = !selectedCourse.learningModules[index].opened
      this.setState({ selectedCourse })

    }

    setLesson(selectedLesson, lessonIndex, moduleIndex) {
      console.log('setLesson called', selectedLesson, lessonIndex, moduleIndex)

      const self = this
      function pullPromptVariables(passedSelectedLesson) {
        console.log('pullPromptVariables called')

        Axios.get('/api/prompt-variables', { params: { email: self.state.emailId }})
       .then((response) => {
         console.log('Pull prompt variables query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrived prompt variables')

           let variablesObject = response.data.pv[0].variables

           if (passedSelectedLesson.content && passedSelectedLesson.content.length > 0) {

             for (let l = 1; l <= passedSelectedLesson.content.length; l++) {
               console.log(".............", passedSelectedLesson.content[l - 1])
               if (passedSelectedLesson.content[l - 1] && passedSelectedLesson.content[l - 1].assessment && passedSelectedLesson.content[l - 1].assessment.questions && passedSelectedLesson.content[l - 1].assessment.questions.length > 0) {
                 console.log("??????", passedSelectedLesson.content[l - 1].assessment.questions)

                  for (let m = 1; m <= passedSelectedLesson.content[l - 1].assessment.questions.length; m++) {
                    console.log("*****")
                    const tempQuestion = passedSelectedLesson.content[l - 1].assessment.questions[m - 1]

                    if (tempQuestion && tempQuestion.name) {
                      if (tempQuestion.name) {
                        // console.log("======name", tempQuestion.name, tempQuestion.name.includes("{{"), variablesObject)

                        if (tempQuestion.name.includes("{{") && variablesObject) {
                          console.log('did we?!')

                          let commandVariables = []
                          let commandArray1 = tempQuestion.name.split("{{")
                          for (let n = 1; n <= commandArray1.length; n++) {
                            console.log('looping commandArray: ', n)
                            if (n > 1 && commandArray1[n - 1].split("}}")) {
                              commandVariables.push(commandArray1[n - 1].split("}}")[0].trim())

                            }
                          }

                          const variableArray = Object.keys(variablesObject)
                          console.log('mmttt', commandVariables, variablesObject, variableArray)
                          for (let n = 1; n <= variableArray.length; n++) {
                            console.log('variable: ', variableArray[n - 1], commandVariables)
                            if (variableArray[n - 1] && commandVariables.includes(variableArray[n - 1].trim())) {
                              for (let o = 1; o <= commandVariables.length; o++) {
                                const existingVariable = commandVariables[o - 1]
                                const replacementVariable = Object.values(variablesObject)[n - 1].trim()

                                console.log('variable match!: ', existingVariable, replacementVariable)

                                if (existingVariable === variableArray[n - 1].trim()) {
                                  console.log('switch them!', existingVariable, replacementVariable)

                                  // if (!selectedLesson.content[this.state.selectedIndex].assessment.questions[this.state.selectedIndex2].editedVariablesSubmitted) {

                                  let name = tempQuestion.name

                                  const cvWithSpaces = "{{ " + existingVariable + " }}"
                                  let regexWithSpaces = new RegExp(cvWithSpaces, "g");
                                  name = name.replace(regexWithSpaces, "{{" + replacementVariable + "}}")

                                  const cv = "{{" + existingVariable + "}}"
                                  let regex = new RegExp(cv, "g");
                                  name = name.replace(regex, "{{" + replacementVariable + "}}")

                                  if (!self.state.keepBrackets) {
                                    name = name.replace(/{{/g, "")
                                    name = name.replace(/}}/g, "")
                                  }

                                  selectedLesson['content'][l - 1]['assessment']['questions'][m - 1]['name'] = name
                                  selectedLesson['content'][l - 1]['assessment']['questions'][m - 1]['editedVariablesSubmitted'] = true
                                  selectedLesson['content'][l - 1]['assessment']['questions'][m - 1]['editedVariables'] = true
                                  // selectedLesson['content'][selectedIndex]['assessment']['questions'][selectedIndex2]['editedVariables'] = true

                                  // console.log("!akdfhskdfh", name)

                                }
                              }
                            }
                          }
                        }
                      }

                    }
                  }
               }
             }
           }

           // if (passedSelectedCourse.learningModules && passedSelectedCourse.learningModules.length > 0) {
           //   for (let j = 1; j <= passedSelectedCourse.learningModules.length; j++) {
           //     // const tempItem = courseEnrollment.learningModules[j - 1]
           //
           //     if (passedSelectedCourse.learningModules[j - 1].lessons && passedSelectedCourse.learningModules[j - 1].lessons.length > 0) {
           //       for (let k = 1; k <= passedSelectedCourse.learningModules[j - 1].lessons.length; k++) {
           //
           //         const tempLesson = passedSelectedCourse.learningModules[j - 1].lessons[k - 1]
           //
           //         if (tempLesson.content && tempLesson.content.length > 0) {
           //
           //           for (let l = 1; l <= tempLesson.content.length; l++) {
           //             console.log(".............", tempLesson.content[l - 1])
           //             if (tempLesson.content[l - 1] && tempLesson.content[l - 1].assessment && tempLesson.content[l - 1].assessment.questions && tempLesson.content[l - 1].assessment.questions.length > 0) {
           //
           //                for (let l = 1; l <= tempLesson.content[l - 1].assessment.questions.length; l++) {
           //                  const tempQuestion = tempLesson.content[l - 1].assessment.questions
           //
           //                  if (tempQuestion && tempQuestion.name) {
           //                    if (tempQuestion.name) {
           //                      console.log("======name", tempQuestion.name)
           //
           //                    }
           //                    // let commandVariables = []
           //                    // let commandArray1 = tempQuestion.name.split("{{")
           //                    // for (let i = 1; i <= commandArray1.length; i++) {
           //                    //   if (i > 1 && commandArray1[i - 1].split("}}")) {
           //                    //     commandVariables.push(commandArray1[i - 1].split("}}")[0])
           //                    //   }
           //                    // }
           //
           //                  }
           //                  // if (!selectedLesson.content[this.state.selectedIndex].assessment.questions[this.state.selectedIndex2].editedVariablesSubmitted) {
           //                }
           //             }
           //           }
           //         }
           //         // if (selectedCourse.learningModules && selectedCourse.learningModules[tempIndex].lessons && selectedCourse.learningModules[tempIndex].lessons[lessonIndex]) {
           //         //    selectedCourse['learningModules'][tempIndex]['lessons'][lessonIndex]['scriptPreviewIndex'] = tempLesson.indexTracker
           //         // }
           //       }
           //     }
           //   }
           // }

           if (!variablesObject) {
             variablesObject = {}
           }

           self.setState({ variablesObject, selectedLesson: passedSelectedLesson })

         } else {
           console.log('no prompt variables found', response.data.message)
           // self.setState({ selectedLesson: passedSelectedLesson })
         }

       }).catch((error) => {
           console.log('Prompt variables query did not work', error);
       });
      }

      if (selectedLesson && selectedLesson.content && selectedLesson.content[0] && (selectedLesson.content[0].category === 'A.I. Script' || selectedLesson.content[0].category === 'Quiz' || selectedLesson.content[0].category === 'Survey')) {
        console.log('load custom assessment')

        let contentId = selectedLesson.content[0].objectId
        if (!contentId) {
          contentId = selectedLesson.content[0].contentId
        }
        // console.log('got an id? ', contentId)
        if (contentId) {
          // console.log('got an id')
          Axios.get('/api/customassessments/byid', { params: { _id: contentId }})
          .then((response) => {
            console.log('Custom assessment by id query attempted', response.data);

            if (response.data.success) {
              console.log('custom assessment query worked')

              selectedLesson['content'][0]['assessment'] = response.data.assessment

              let selectedSubmission = null
              let selectedIndex = null
              if ((selectedLesson.content[0].category === 'Quiz' || selectedLesson.content[0].category === 'Survey') && this.state.submissions && this.state.submissions.length > 0) {
                for (let i = 1; i <= this.state.submissions.length; i++) {
                  console.log('submissions: ', this.state.submissions[i - 1])
                  if (this.state.submissions[i - 1].assessmentId === response.data.assessment._id) {
                    selectedSubmission = this.state.submissions[i - 1]
                    selectedIndex = i - 1
                  }
                }
              }
              console.log('what is the selectedSub: ', selectedSubmission)
              this.setState({ selectedLesson, selectedSubmission, selectedIndex, lessonIndex, moduleIndex })

              pullPromptVariables(selectedLesson)

            } else {
              console.log('custom assessment query did not work', response.data.message)
            }
          }).catch((error) => {
            console.log('Posted jobs query did not work for some reason', error);
          });
        } else {
          this.setState({ selectedLesson, lessonIndex, moduleIndex })
        }
      } else if (selectedLesson.content && selectedLesson.content[0] && selectedLesson.content[0].contentId) {
        console.log('load resource')

        const contentId = selectedLesson.content[0].contentId

        Axios.get('/api/resources/byid', { params: { _id: contentId }})
        .then((response) => {
          console.log('Resource by id query attempted', response.data);

          if (response.data.success) {
            console.log('resource query worked')

            selectedLesson['content'][0]['title'] = response.data.resource.title
            selectedLesson['content'][0]['value'] = response.data.resource.description
            selectedLesson['content'][0]['maxPoints'] = response.data.resource.maxPoints
            if (response.data.resource.type === 'Video') {
              selectedLesson['content'][0]['value'] = response.data.resource.videoURL
            }

            let selectedSubmission = null
            let selectedIndex = null
            if (selectedLesson.content[0].category === 'Assignment' && this.state.submissions && this.state.submissions.length > 0) {
              for (let i = 1; i <= this.state.submissions.length; i++) {
                if (this.state.submissions[i - 1].assessmentId === response.data.resource._id) {
                  selectedSubmission = this.state.submissions[i - 1]
                  selectedIndex = i - 1
                }
              }
            }

            this.setState({ selectedLesson, selectedSubmission, selectedIndex, lessonIndex, moduleIndex })
            pullPromptVariables(selectedLesson)

          } else {
            console.log('resource query did not work', response.data.message)
            this.setState({ selectedLesson, lessonIndex, moduleIndex })
            pullPromptVariables(selectedLesson)
          }
        }).catch((error) => {
          console.log('Resource query did not work for some reason', error);
        });

      } else {
        console.log('load lesson')
        this.setState({ selectedLesson, lessonIndex, moduleIndex })
      }
    }

    loadContent(inModal) {
      // console.log('loadContent called', this.state.selectedLesson)

      if (!this.state.selectedLesson) {
        return (
          <div key="loadContent" className="calc-height-half-view darker-background flex-container flex-center white-text">
            <p className="center-text">Select content to load here</p>
          </div>
        )
      } else {
        let content = null
        if (this.state.selectedLesson.content && this.state.selectedLesson.content.length > 0) {
          content = this.state.selectedLesson.content
        }

        if (content) {
          for (let i = 1; i <= content.length; i++) {
            if (content[i - 1].category) {
              if (content[i - 1].category === 'Video') {

                let videoLink = content[i - 1].value
                let videoId = content[i - 1].value
                if (videoId.includes('/watch?v=')) {
                  videoLink = videoLink.split('/watch?v=')[0] + '/embed/' + videoLink.split('/watch?v=')[1]
                  videoId = videoId.split('/watch?v=')[1]
                } else if (videoId.includes('/embed/')) {
                  videoId = videoId.split('/embed/')[1]
                } else if (videoId.includes('youtu.be/')) {
                  videoId = videoId.split('youtu.be/')[1]
                  videoLink = 'https://www.youtube.com/embed/' + videoId
                } else {
                  // https://youtu.be/a4mosXPdrX8
                }
                // "https://www.youtube.com/embed/a4mosXPdrX8"

                // https://youtu.be/a4mosXPdrX8
                // https://www.youtube.com/watch?v=a4mosXPdrX8
                return (
                  <div key="loadContent">
                    {(this.state.useYouTubeAPI) ? (
                      <YouTube
                        videoId={videoId}
                        id={this.state.selectedLesson._id}
                        className="full-width calc-height-half-view"
                        containerClassName={"full-width calc-height-half-view"}
                        opts={(e) => this.videoCallback(e,'opts')}
                        onReady={(e) => this.videoCallback(e,'onReady')}
                        onPlay={(e) => this.videoCallback(e,'onPlay')}
                        onPause={(e) => this.videoCallback(e,'onPause')}
                        onEnd={(e) => this.videoCallback(e,'onEnd')}
                        onError={(e) => this.videoCallback(e,'onError')}
                        onStateChange={(e) => this.videoCallback(e,'onStateChange')}
                        onPlaybackRateChange={(e) => this.videoCallback(e,'onPlaybackRateChange')}
                        onPlaybackQualityChange={(e) => this.videoCallback(e,'onPlaybackQualityChange')}
                      />
                    ) : (
                      <div>
                        <iframe
                          title="videoTitle"
                          className="full-width calc-height-half-view"
                          src={videoLink}
                          frameBorder="0"
                        />
                      </div>
                    )}
                  </div>
                )
              } else if (content[i - 1].category === 'A.I. Script') {

                let disablePrompt = false

                let indexTracker = 0
                if (this.state.progress[this.state.moduleIndex] && this.state.progress[this.state.moduleIndex].lessons && this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker) {
                  indexTracker = this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker
                }

                const progressIndexTracker = indexTracker
                if (this.state.showScriptPreviews) {
                  disablePrompt = true

                  if (this.state.selectedCourse.learningModules[this.state.moduleIndex] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons && (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex || this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex === 0)) {
                    indexTracker = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex
                  }
                  // console.log('a2', indexTracker)

                  // 0 progress means indextracker must be equal to 0 to be enabled
                  // 0 indexTracker means progress must be equal to 0

                  if (progressIndexTracker && indexTracker && progressIndexTracker === indexTracker) {
                    disablePrompt = false
                  } else if (progressIndexTracker === 0 && indexTracker === 0) {
                    disablePrompt = false
                  }
                }
                console.log('show indexTracker deets: ', indexTracker, this.state.progress, this.state.selectedCourse.learningModules, content[i - 1].assessment.questions, content[i - 1].assessment.questions[indexTracker])

                return (
                  <div key="loadContent" className="padding-40">

                    {(content[i - 1].assessment && content[i - 1].assessment.questions && content[i - 1].assessment.questions.length > 0) && (
                      <div>
                        {(this.state.showScriptRedesign) ? (
                          <div>
                            <div className="bottom-margin-20">
                              <div className="calc-column-offset-40">
                                <p className="heading-text-2 keep-line-breaks">{content[i - 1].assessment.title}</p>
                              </div>
                              {(this.state.enableFullView && !inModal) ? (
                                <div className="fixed-column-40">
                                  <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromAIScript', null)}>
                                    <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                                  </button>
                                </div>
                              ) : (
                                <div className="fixed-column-40 height-30" />
                              )}
                              <div className="clear" />
                            </div>

                            <div className="row-10">

                              <div className="full-width">
                                {(this.state.showAvatar) ? (
                                  <div>
                                    <div className={(window.innerWidth < 768) ? "full-width" : (inModal) ? "relative-column-40 right-padding-8-percent" : "relative-column-40 right-padding"}>
                                      <img src={(this.state.isTalking) ? blackAvatarGIF : blackAvatar} alt="GC" className="image-full-auto" />
                                      <p className="top-margin-15 center-text description-text-4">A.I. AVATAR</p>
                                    </div>
                                    {(window.innerWidth > 768) && (
                                      <div className="relative-column-60">
                                        {this.renderMessages(inModal)}
                                      </div>
                                    )}

                                    <div className="clear" />
                                  </div>
                                ) : (
                                  <div>
                                    {this.renderMessages(inModal)}
                                  </div>
                                )}
                              </div>
                            </div>

                            {(window.innerWidth < 768) && (
                              <div className="full-width row-10">
                                {this.renderMessages(inModal)}
                              </div>
                            )}

                            {(content[i - 1].assessment.questions[indexTracker]) ? (
                              <div>
                                <div className="row-10">
                                  <p className="center-text description-text-1 top-margin-20">Prompt {indexTracker + 1} of {content[i - 1].assessment.questions.length}</p>
                                  <div>
                                    <div className="fixed-column-50 height-30">
                                      {(this.state.showScriptPreviews && indexTracker) ? (
                                        <div>
                                          <button className="background-button top-margin" onClick={() => this.moveScript('previous')}>
                                            <div className="standard-border-dark row-15 horizontal-padding-4 slightly-rounded-corners">
                                              <img src={arrowIndicatorIconDark} alt="GC" className="image-auto-15 rotate-180" />
                                            </div>
                                          </button>
                                          {/*
                                          <div className="previous-arrow-tooltip position-fixed left-0 left-margin-65 vertical-center black-background white-text row-20 horizontal-padding-15">
                                            <p>{this.moveScript('previous')}</p>
                                          </div>*/}
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                    </div>
                                    <div className="calc-column-offset-100 horizontal-padding">
                                      {(!content[i - 1].assessment.questions[indexTracker].questionType || content[i - 1].assessment.questions[indexTracker].questionType === 'Short Answer' || content[i - 1].assessment.questions[indexTracker].questionType === 'Dictated') && (
                                        <div>
                                          <p className="center-text top-margin heading-text-5">{content[i - 1].assessment.questions[indexTracker].name}</p>
                                        </div>
                                      )}
                                      {(content[i - 1].assessment.questions[indexTracker].questionType === 'Multiple Choice') && (
                                        <div>
                                          {(content[i - 1].assessment.questions[indexTracker].answerChoices) && (
                                            <div className="flex-container row-direction center-text flex-center flex-wrap">
                                              {content[i - 1].assessment.questions[indexTracker].answerChoices.map((item, optionIndex) =>
                                                <div key={"content|answerChoices|" + optionIndex} className="top-margin right-margin left-margin">
                                                  <button className="background-button" onClick={() => this.itemClicked('commandDraft', null, i - 1,indexTracker, item)}>
                                                    <div className={(content[i - 1].assessment.questions[indexTracker].commandDraft && content[i - 1].assessment.questions[indexTracker].commandDraft === item) ? "primary-background rounded-corners row-10 horizontal-padding" : "dark-background rounded-corners row-10 horizontal-padding"}>
                                                      <p className="white-text">{item}</p>
                                                    </div>
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {(content[i - 1].assessment.questions[indexTracker].questionType === 'Freeform') && (
                                        <div className="row-10">
                                          <textarea type="text" className="text-field" placeholder="e.g., Write a prompt for the A.I..." name={"content|" + (i - 1) + "|questions|" + indexTracker} value={content[i - 1].assessment.questions[indexTracker].commandDraft} onChange={this.formChangeHandler}></textarea>
                                        </div>
                                      )}

                                      {(content[i - 1].assessment.questions[indexTracker].placeholder) && (
                                        <p className="center-text description-text-2 description-text-color row-10">Pointer: {content[i - 1].assessment.questions[indexTracker].placeholder}</p>
                                      )}
                                    </div>
                                    <div className="fixed-column-50 height-30">

                                      {(this.state.showScriptPreviews && ((indexTracker + 1) !== content[i - 1].assessment.questions.length)) ? (
                                        <div>
                                          <button className="background-button top-margin" onClick={() => this.moveScript('next')}>
                                            <div className="standard-border-dark row-15 horizontal-padding-4 slightly-rounded-corners">
                                              <img src={arrowIndicatorIconDark} alt="GC" className="image-auto-15" />
                                            </div>
                                          </button>
                                          {/*
                                          <div className="next-arrow-tooltip position-fixed right-0 right-margin-65 vertical-center black-background white-text row-20 horizontal-padding-15">
                                            <p>{this.moveScript('next')}</p>
                                          </div>*/}
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>

                                {(this.state.showScriptPreviews && indexTracker !== progressIndexTracker) && (
                                  <div className="row-5">
                                    <p className="error-color center-text">Buttons are disabled because you are currently at question {progressIndexTracker + 1}.</p>
                                  </div>
                                )}

                                <div className="row-10 flex-container row-direction flex-center top-margin">
                                  {(content[i - 1].assessment.questions[indexTracker].name.includes("{{")) && (
                                    <div className={(window.innerWidth < 768) ? "right-margin top-margin-negative-5 bottom-margin-20" : "right-margin top-margin-negative-5"}>
                                      <button className={(disablePrompt) ? "btn btn-squarish light-background standard-border standard-color" : "btn btn-squarish dark-background standard-border"} disabled={disablePrompt} onClick={() => this.showEditVariables(content[i - 1].assessment.questions[indexTracker], i - 1, indexTracker)}>Edit Variables</button>
                                    </div>
                                  )}
                                  <div className={(window.innerWidth < 768) ? "left-margin top-margin-negative-5 bottom-margin-20" : "left-margin top-margin-negative-5"}>
                                    <div className={((content[i - 1].assessment.questions[indexTracker].editedVariables && content[i - 1].assessment.questions[indexTracker].name.includes("{{")) || (!content[i - 1].assessment.questions[indexTracker].editedVariables && !content[i - 1].assessment.questions[indexTracker].name.includes("{{"))) ? "" : "wash-out-2"}>
                                      <button className={(disablePrompt) ? "btn btn-squarish light-background standard-border standard-color" : "btn btn-squarish"} disabled={disablePrompt} onClick={() => this.submitPrompt(content, indexTracker, i - 1)}>Submit</button>
                                    </div>
                                  </div>
                                </div>

                                {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10 center-text">{this.state.errorMessage}</p>}
                                {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10 center-text">{this.state.successMessage}</p>}

                                {(!inModal) && (
                                  <div>
                                    <div className="spacer" /><div className="spacer" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="top-margin-20 row-30">
                                <p className="heading-text-4 center-text">You have completed all prompts for {this.state.moduleIndex + 1}.{this.state.lessonIndex + 1}. {this.state.selectedLesson.name}.</p>
                                <p className="heading-text-6 center-text top-margin-30">Review the prompts again <button className="background-button bold-text cta-color" onClick={() => this.moveScript('reset')}>here</button> and the responses in your chat history.</p>
                              </div>
                            )}

                          </div>
                        ) : (
                          <div>
                            <div>
                              <div className="calc-column-offset-40">
                                <p className="heading-text-2">{content[i - 1].assessment.title}</p>
                                <p className="top-margin-5">This guided script walks through customizable prompts to Compass Assistant, an A.I. chatbot. Click "Submit" to submit the prompt and advance to the next prompt.</p>
                              </div>
                              {(this.state.enableFullView && !inModal) ? (
                                <div className="fixed-column-40">
                                  <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromAIScript', null)}>
                                    <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                                  </button>
                                </div>
                              ) : (
                                <div className="fixed-column-40 height-30" />
                              )}
                              <div className="clear" />
                            </div>

                            <div className="spacer" /><div className="spacer" /><div className="spacer" />

                            {content[i - 1].assessment.questions.map((item, optionIndex) =>
                              <div key={"commands|" + optionIndex} className="top-margin-30">

                                <div className={((!this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker && optionIndex > 0) || optionIndex > this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker) ? "wash-out-2" : ""}>
                                  <div className={(window.innerWidth < 768) ? "calc-column-offset-90 right-padding-20 bottom-margin-20" : "float-left right-padding-20 bottom-margin-20"}>
                                    <p>{optionIndex + 1}. {item.name}</p>
                                    {(item.placeholder) && (
                                      <p className="description-text-2 description-text-color row-10">Pointer: {item.placeholder}</p>
                                    )}
                                  </div>
                                  {(item.name.includes("{{")) && (
                                    <div className={(window.innerWidth < 768) ? "fixed-column-90 top-margin-negative-5 bottom-margin-20" : "float-left right-margin-20 top-margin-negative-5 bottom-margin-20"}>
                                      <button className="btn btn-squarish dark-background standard-border" disabled={((!this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker && optionIndex > 0) || optionIndex > this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker) ? true : false} onClick={() => this.showEditVariables(item, i - 1, optionIndex)}>Edit Variables</button>
                                    </div>
                                  )}
                                  <div className={(window.innerWidth < 768) ? "fixed-column-90 top-margin-negative-5 bottom-margin-20" : "float-left top-margin-negative-5 bottom-margin-20"}>
                                    <div className={((item.editedVariables && item.name.includes("{{")) || (!item.editedVariables && !item.name.includes("{{"))) ? "" : "wash-out-2"}>
                                      <button className="btn btn-squarish" disabled={((!item.editedVariables && item.name.includes("{{") || (!this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker && optionIndex > 0) || optionIndex > this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker)) ? true : false} onClick={() => this.commandClicked(item, i - 1, optionIndex)}>Submit</button>
                                    </div>
                                  </div>
                                  <div className="clear" />
                                </div>

                                <hr />

                              </div>
                            )}

                            <div className="spacer" />

                            <p className="heading-text-5 row-40">-----  END OF SCRIPT -----</p>
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                )
              } else if (content[i - 1].category === 'Text') {

                return (
                  <div key="loadContent" className="padding-40">
                    <div>
                      <div className="calc-column-offset-40">
                        <p className="heading-text-2 keep-line-breaks">{content[i - 1].title}</p>
                      </div>
                      {(this.state.enableFullView && !inModal) ? (
                        <div className="fixed-column-40">
                          <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromRenderText', null)}>
                            <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                          </button>
                        </div>
                      ) : (
                        <div className="fixed-column-40 height-30" />
                      )}
                      <div className="clear" />
                    </div>

                    {(content[i - 1].htmlMode) ? (
                      <div className="heading-text-6 keep-line-breaks top-margin-30">
                        <div dangerouslySetInnerHTML={{__html: content[i - 1].value }}></div>
                      </div>
                    ) : (
                      <p className="heading-text-6 keep-line-breaks top-margin-30">{content[i - 1].value}</p>
                    )}

                  </div>
                )
              } else if (content[i - 1].category === 'Assignment') {

                return (
                  <div key="loadContent" className="padding-40">
                    <div>
                      <div className="calc-column-offset-40">
                        <p className="heading-text-2 keep-line-breaks">{content[i - 1].title}</p>
                        <p className="heading-text-6 keep-line-breaks top-margin-30">{content[i - 1].value}</p>
                      </div>
                      {(this.state.enableFullView && !inModal) ? (
                        <div className="fixed-column-40">
                          <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromRenderAssignment', null)}>
                            <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                          </button>
                        </div>
                      ) : (
                        <div className="fixed-column-40 height-30" />
                      )}
                      <div className="clear" />
                    </div>

                    <div className="spacer" /><div className="spacer" />

                    <div className="row-30">
                      {(this.testCompletion(this.state.selectedCourse,this.state.selectedLesson,this.state.moduleIndex, this.state.lessonIndex)) && <p className="cta-color bottom-margin-20">You have already submitted for this assignment.</p>}

                      <button className="btn btn-primary" disabled={(this.state.isLoading) ? true : false} onClick={() => this.configureModal(content, i - 1)}>{(this.testCompletion(this.state.selectedCourse,this.state.selectedLesson,this.state.moduleIndex, this.state.lessonIndex)) ? "Update Assignment" : "Submit Assignment"}</button>
                    </div>

                  </div>
                )
              } else if (content[i - 1].category === 'Quiz' || content[i - 1].category === 'Survey') {
                // console.log('do we have the course: ', this.state.selectedCourse)
                return (
                  <div key="loadContent" className="padding-40">
                    <div>
                      <div className="calc-column-offset-40 height-20" />
                      {(this.state.enableFullView && !inModal) ? (
                        <div className="fixed-column-40">
                          <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromRenderSurvey', null)}>
                            <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                          </button>
                        </div>
                      ) : (
                        <div className="fixed-column-40 height-20" />
                      )}
                      <div className="clear" />
                    </div>

                    <SubTakeBasicAssessment questions={content[i - 1].assessment.questions} selectedCourse={this.state.selectedCourse} selectedLesson={null} selectedCustomAssessment={content[i - 1].assessment} selectedSubmission={this.state.selectedSubmission} submissions={this.state.submissions} orgCode={this.state.orgCode} modalIsOpen={this.state.modalIsOpen} closeModal={null} passResponse={this.passResponse} />
                    {/*
                    {(this.testCompletion(this.state.selectedCourse,this.state.selectedLesson,this.state.moduleIndex, this.state.lessonIndex)) ? (
                      <div className="row-30">
                        <p className="error-color">You have already submitted for this {content[i - 1].category}.</p>
                      </div>
                    ) : (
                      <SubTakeBasicAssessment questions={content[i - 1].assessment.questions} selectedCourse={this.state.selectedCourse} selectedLesson={null} selectedCustomAssessment={content[i - 1].assessment} selectedSubmission={this.state.selectedSubmission} submissions={this.state.submissions} orgCode={this.state.orgCode} modalIsOpen={this.state.modalIsOpen} closeModal={null} passResponse={this.passResponse} />
                    )}*/}
                  </div>
                )
              } else if (content[i - 1].category === 'Training') {
                return (
                  <div key="loadContent" className="padding-40">
                    <div>
                      <div className="calc-column-offset-25 padding-right">
                        <p className="heading-text-2 keep-line-breaks">Manage Your Trainings</p>
                      </div>
                      {(this.state.enableFullView && !inModal) ? (
                        <div className="fixed-column-25">
                          <button className="background-button full-width" onClick={() => this.itemClicked('toggleView','fromRenderText', null)}>
                            <img src={expandIcon} alt="GC" className="image-auto-25 pin-right" />
                          </button>
                        </div>
                      ) : (
                        <div className="fixed-column-25 height-30" />
                      )}
                      <div className="clear" />
                    </div>
                    {/*
                    {(content[i - 1].htmlMode) ? (
                      <div className="heading-text-6 keep-line-breaks top-margin-30">
                        <div dangerouslySetInnerHTML={{__html: content[i - 1].value }}></div>
                      </div>
                    ) : (
                      <p className="heading-text-6 keep-line-breaks top-margin-30">{content[i - 1].value}</p>
                    )}*/}
                    {(this.state.emailId) && (
                      <SubWorkModeWidget attachmentOptions={this.state.attachmentOptions} selectedCourse={this.state.selectedCourse} selectedLesson={this.state.selectedLesson} moduleIndex={this.state.moduleIndex} lessonIndex={this.state.lessonIndex} />
                    )}
                  </div>
                )
              }
            }
          }
        }
      }
    }

    renderMessages(inModal) {
      console.log('renderMessages called')

      return (
        <div key="renderMessages">
          <div className={(inModal) ? "thick-border-dark full-space height-350 slightly-rounded-corner overflow-y-auto" : "anchor-scroll-container-take-course thick-border-dark full-space height-350 slightly-rounded-corner overflow-y-auto"}>
            <SubHelpPrompt orgCode={this.state.org} orgLogo={this.state.orgLogo} passedCommand={this.state.passedCommand} preparedResponse={this.state.preparedResponse} passData={this.passData} chatOnly={true} courseId={this.props.courseId} questionId={this.state.questionId} scrollToBottom={this.scrollToBottom} showPromptResponse={this.state.showPromptResponse} scriptQuestionId={this.state.scriptQuestionId}/>

            {(inModal) && <div ref={(el) => { this.messagesEnd = el; }} />}
            {/*<div ref="anchor" className="anchor">anchor</div>*/}
          </div>
        </div>
      )
    }

    passResponse(submission, id) {
      console.log('passResponse called', submission)

      let submissions = this.state.submissions
      let selectedSubmission = submission

      if (id) {
        submissions[this.state.selectedIndex] = submission
      } else {
        if (submissions) {
          submissions.push(submission)
        } else {
          submissions = [submission]
        }
      }

      let progress = this.state.progress
      progress[this.state.moduleIndex]['lessons'][this.state.lessonIndex]['isCompleted'] = true

      this.setState({ submissions, selectedSubmission, progress })
      this.saveProgress(progress)

    }

    videoCallback(event, callback) {
      console.log('videoCallback called', event, callback)

      if (callback === 'onReady') {
        const players = this.state.players;
        players.push(event.target);
        this.setState({ players });
        console.log('players: ', event.target.id)
      } else if (callback === 'onPlay') {
        this.state.players.forEach((player) => {
          if (player.id !== event.target.id) {
            player.pauseVideo();
          }
        });
      }
    }

    submitPrompt(content, questionIndex, contentIndex) {
      console.log('submitPrompt called')

      let item = content[contentIndex].assessment.questions[questionIndex]
      let errorMessage = null
      if (content[contentIndex].assessment.questions[questionIndex].questionType === 'Multiple Choice') {
        item['name'] = content[contentIndex].assessment.questions[questionIndex].commandDraft
        errorMessage = 'Please select a prompt'
      } else if (content[contentIndex].assessment.questions[questionIndex].questionType === 'Freeform') {
        item['name'] = content[contentIndex].assessment.questions[questionIndex].commandDraft
        errorMessage = 'Please write a prompt'
      }

      if (item && item.name) {
        let selectedCourse = this.state.selectedCourse
        selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['content'][contentIndex]['assessment']['questions'][questionIndex]['commandDraft'] = ''
        if (selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].content[contentIndex].assessment.questions[questionIndex + 1]) {
          selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['content'][contentIndex]['assessment']['questions'][questionIndex + 1]['commandDraft'] = ''
        }

        let questionId = selectedCourse.learningModules[this.state.moduleIndex]['lessons'][this.state.lessonIndex].content[contentIndex].assessment.questions[questionIndex]._id

        this.setState({ selectedCourse, questionId, replacements: null })

        this.commandClicked(item, contentIndex, questionIndex)
      } else {
        this.setState({ errorMessage })
      }
    }

    commandClicked(item, index, index2) {
      console.log('commandClicked called', item, index, index2)

      let progress = this.state.progress

      let indexTracker = this.state.progress[this.state.moduleIndex]['lessons'][this.state.lessonIndex].indexTracker

      if (indexTracker) {
        indexTracker = indexTracker + 1
      } else {
        indexTracker = 1
      }

      progress[this.state.moduleIndex]['lessons'][this.state.lessonIndex]['indexTracker'] = indexTracker

      if (this.state.showScriptRedesign) {
        console.log('about to set passedCommand')

        let preparedResponse = null
        if (item.responseType === "Admin-Generated Response") {
          preparedResponse = item.preparedResponse
        }

        let selectedCourse = this.state.selectedCourse
        selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['scriptPreviewIndex'] = indexTracker

        // console.log('what is happening: ', preparedResponse, item.responseType)
        this.setState({ passedCommand: item.name, preparedResponse, selectedCourse,
          isTalking: true, errorMessage: null, successMessage: null
        })
      } else {
        this.props.commandClicked(item)
      }

      this.saveProgress(progress)

    }

    saveProgress(progress) {
      console.log('saveProgress called')

      let _id = null
      if (this.state.courseEnrollment) {
        _id = this.state.courseEnrollment._id
      }

      const firstName = this.state.cuFirstName
      const lastName = this.state.cuLastName
      const email = this.state.emailId
      const username = this.state.username
      const pictureURL = this.state.pictureURL
      const roleName = this.state.roleName

      const courseId = this.state.selectedCourse._id
      const courseName = this.state.selectedCourse.name
      const courseImageURL = this.state.selectedCourse.imageURL
      const courseCategory = this.state.selectedCourse.category
      const courseDescription = this.state.selectedCourse.description
      const courseCurriculumItemId = this.state.selectedCourse.curriculumItemId

      let isCompleted = false
      if (progress && progress.length > 0) {
        isCompleted = true

        for (let i = 1; i <= progress.length; i++) {
          if (!progress[i - 1].isCompleted) {
            isCompleted = false
          }

          if (progress[i - 1].lessons && progress[i - 1].lessons.length > 0) {
            for (let j = 1; j <= progress[i - 1].lessons.length; j++) {
              if (!progress[i - 1].lessons[j - 1].isCompleted) {
                isCompleted = false
              }
            }
          }
        }
      }

      const orgCode = this.state.orgCode
      const orgName = this.state.orgName

      const createdAt = new Date()
      const updatedAt = new Date()

      const subObject = { _id, progress,
        firstName, lastName, email, username, pictureURL, roleName,
        courseId, courseName, courseImageURL, courseCategory, courseDescription, courseCurriculumItemId,
        isCompleted,
        orgCode, orgName, createdAt, updatedAt
      }

      Axios.post('/api/courses/enrollments', subObject)
      .then((response) => {
        console.log('attempting to save progress in enrollments')
        if (response.data.success) {
          console.log('saved progress in enrollments', response.data)

          // save prompt variables
          const variables = this.state.variablesObject

          const promptObject = {
            firstName, lastName, email, username, pictureURL,
            variables, orgCode, orgName, createdAt, updatedAt
          }

          Axios.post('/api/prompt-variables', promptObject)
          .then((response) => {
            console.log('attempting to save prompt variables')
            if (response.data.success) {
              console.log('saved prompt variables', response.data)
            } else {
              console.log('did not save prompt variables')
            }
          }).catch((error) => {
              console.log('saving prompt variables', error);
          });
        } else {
          console.log('did not save prompt variables')
        }
      }).catch((error) => {
          console.log('saving progress in enrollments did not work', error);
      });
    }

    showEditVariables(item,selectedIndex, selectedIndex2) {
      console.log('showEditVariables called', item, selectedIndex, selectedIndex2)

      // this.closeModal()

      let commandVariables = []

      let commandArray1 = item.name.split("{{")
      for (let i = 1; i <= commandArray1.length; i++) {
        if (i > 1 && commandArray1[i - 1].split("}}")) {
          commandVariables.push(commandArray1[i - 1].split("}}")[0])
        }
      }

      let selectedLesson = this.state.selectedLesson
      selectedLesson['content'][selectedIndex]['assessment']['questions'][selectedIndex2]['editedVariables'] = true

      let previousView = null
      if (this.state.modalIsOpen && this.state.showFullView) {
        previousView = 'Full View'
      }

      let modalIsOpen = true
      let editVariable = true
      let showFullView = null

      console.log('i do not get it', previousView)
      this.setState({ modalIsOpen, editVariable, showFullView, previousView,
        selectedIndex, selectedIndex2, commandVariables, selectedLesson
      })

      // this.setState({ modalIsOpen: true })

    }

    replaceVariables() {
      console.log('replaceVariables called')

      let variablesObject = this.state.variablesObject

      let selectedLesson = this.state.selectedLesson
      let name = this.state.selectedLesson.content[this.state.selectedIndex].assessment.questions[this.state.selectedIndex2].name

      for (let i = 1; i <= this.state.commandVariables.length; i++) {
        const cv = "{{" + this.state.commandVariables[i - 1] + "}}"
        let regex = new RegExp(cv, "g");
        name = name.replace(regex, "{{" + this.state.replacements[i - 1] + "}}")

        const cvTrimSpaces = "{{" + this.state.commandVariables[i - 1].trim() + "}}"
        let regexTrimSpaces = new RegExp(cvTrimSpaces, "g");
        name = name.replace(regexTrimSpaces, "{{" + this.state.replacements[i - 1] + "}}")

        const cvWithSpaces = "{{ " + this.state.commandVariables[i - 1] + " }}"
        let regexWithSpaces = new RegExp(cvWithSpaces, "g");
        name = name.replace(regexWithSpaces, "{{" + this.state.replacements[i - 1] + "}}")

        if (!this.state.keepBrackets) {
          name = name.replace(/{{/g, "")
          name = name.replace(/}}/g, "")
        }
        console.log('a1:')
        if (!selectedLesson.content[this.state.selectedIndex].assessment.questions[this.state.selectedIndex2].editedVariablesSubmitted) {
          console.log('a2: ', variablesObject, i - 1, this.state.commandVariables[i - 1], this.state.replacements[i - 1])
          variablesObject[this.state.commandVariables[i - 1].trim()] = this.state.replacements[i - 1]
        }
      }

      selectedLesson['content'][this.state.selectedIndex]['assessment']['questions'][this.state.selectedIndex2]['name'] = name
      selectedLesson['content'][this.state.selectedIndex]['assessment']['questions'][this.state.selectedIndex2]['editedVariablesSubmitted'] = true

      let showFullView = false
      if (this.state.previousView === 'Full View') {
        showFullView = true
      } else {
        this.closeModal()
      }

      this.setState({ selectedLesson, showFullView, editVariable: false })

    }

    submitAssignment() {
      console.log('submitAssignment called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.submissionTitle || this.state.submissionTitle === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add a submission title' })
      } else if (!this.state.submissionDetails || this.state.submissionDetails === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add submission details' })
      } else {

        let _id = null
        if (this.state.selectedSubmission) {
          _id = this.state.selectedSubmission._id
        }

        const title = this.state.submissionTitle
        const details = this.state.submissionDetails
        const attachments = this.state.attachments
        const externalURL = this.state.externalURL

        const subType = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].content[0].category
        // if (selectedLesson.content[0].category === 'Assignment') {
        //
        // }

        const courseId = this.state.selectedCourse._id
        const courseName = this.state.selectedCourse.name

        // const lessonId = this.state.selectedLesson._id
        const lessonName = this.state.selectedLesson.name

        const assessmentId = this.state.selectedLesson.content[0].contentId
        const assessmentName = this.state.selectedLesson.content[0].title

        const firstName = this.state.cuFirstName
        const lastName = this.state.cuLastName
        const emailId = this.state.email
        const username = this.state.username
        const pictureURL = this.state.pictureURL
        const roleName = this.state.roleName

        const orgCode = this.state.orgCode
        const orgName = this.state.orgName

        const createdAt = new Date()
        const updatedAt = new Date()

        const subObject = {
          _id, title, details, attachments, externalURL, subType,
          courseId, courseName, lessonName, assessmentId, assessmentName,
          firstName, lastName, emailId, username, pictureURL, roleName,
          orgCode, orgName, createdAt, updatedAt
        }

        Axios.post('/api/submissions', subObject)
        .then((response) => {
          console.log('attempting to save submission')
          if (response.data.success) {
            console.log('saved submission', response.data)

            // let selectedLesson = this.state.selectedLesson
            // selectedLesson['isCompleted'] = true
            let submissions = this.state.submissions
            if (submissions) {
              submissions.push(response.data.submission)
            } else {
              submissions = [response.data.submission]
            }

            let successMessage = 'You have successfully submitted your answers!'
            let errorMessage = null

            let progress = this.state.progress
            progress[this.state.moduleIndex]['lessons'][this.state.lessonIndex]['isCompleted'] = true
            this.setState({ isSaving: false, errorMessage, successMessage, progress })
            this.closeModal()

            this.saveProgress(progress)

          } else {
            console.log('did not save successfully')
            this.setState({ isSaving: false, errorMessage: 'error:' + response.data.message })
          }
        }).catch((error) => {
            console.log('save did not work', error);
            this.setState({ isSaving: false, errorMessage: 'there was an error saving submission' })
        });
      }
    }

    toggleCompletion(e,index,index2) {
      console.log('toggleCompletion called', this.state.progress, index, index2)

      e.stopPropagation()

      let progress = this.state.progress
      progress[index]['lessons'][index2]['isCompleted'] = !progress[index].lessons[index2].isCompleted
      this.setState({ progress })
      this.saveProgress(progress)
      // selectedCourse['learningModules'][index]['lessons'][index2] = !selectedCourse['learningModules'][index].lessons[index2].isCompleted
    }

    calculateCompletions(index) {
      // console.log('calculateCompletions called')

      let completionCount = 0

      if (this.state.progress && this.state.progress[index] && this.state.progress[index].lessons) {
        for (let i = 1; i <= this.state.progress[index].lessons.length; i++) {
          if (this.state.progress[index].lessons[i - 1] && this.state.progress[index].lessons[i - 1].isCompleted) {
            completionCount = completionCount + 1
          }
        }
      }

      return completionCount

    }

    testCompletion(course, lesson, index, index2) {
      // console.log('testCompletion called', course, lesson, index, index2, this.state.progress)

      let completed = false

      let useProgress = true
      if (useProgress) {
        if (this.state.progress && this.state.progress.length > 0) {
          completed = this.state.progress[index]['lessons'][index2]['isCompleted']
        }
      } else {
        if (this.state.submissions && this.state.submissions.length > 0) {
          const subIndex1 = this.state.submissions.findIndex(sub => sub.lessonName === lesson.name)
          if (subIndex1 > -1) {
            completed = true
          }

          if (lesson.content) {
            const subIndex2 = this.state.submissions.findIndex(sub => sub.assessmentId === lesson.content[0].contentId)
            if (subIndex2 > -1) {
              completed = true
            }
          }
        }
      }

      return completed

    }

    passData(data, speakingStatus) {
      console.log('passData called: ', data, speakingStatus)

      if (speakingStatus) {
        // toggle avatar animation
        let animateAvatar = false
        if (speakingStatus === 'start') {
          animateAvatar = true
        } else if (speakingStatus === 'end') {
          animateAvatar = false
        }
        this.setState({ animateAvatar })
      } else {
        // pass speech to text values
        this.setState({ isTalking: false })
      }
    }

    calculateAdjacentLesson(direction, setText) {
      // console.log('calculateAdjacentLesson called', direction, setText)

      let lessonText = 'No page found'
      let selectedLesson = this.state.selectedLesson
      let lessonIndex = this.state.lessonIndex
      let moduleIndex = this.state.moduleIndex

      if (direction === 'previous') {
        if (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex - 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex - 1].name) {
          lessonText = (this.state.moduleIndex + 1).toString() + '.' + (this.state.lessonIndex).toString() + '. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex - 1].name
          selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex - 1]
          lessonIndex = this.state.lessonIndex - 1
          // moduleIndex
        } else if (this.state.selectedCourse.learningModules[this.state.moduleIndex - 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons && this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length - 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length - 1].name) {
          lessonText = (this.state.moduleIndex).toString() + '.' + this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length + '. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length - 1].name
          selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length - 1]
          lessonIndex = this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons.length - 1
          moduleIndex = this.state.moduleIndex - 1
        } else if (this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1] && this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].name !== this.state.selectedCourse.learningModules[this.state.moduleIndex].name && this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].lessons && this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].lessons[0] && this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].lessons[0].name) {
          lessonText = (this.state.selectedCourse.learningModules.length).toString() + '.1. ' + this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].lessons[0].name
          selectedLesson = this.state.selectedCourse.learningModules[this.state.selectedCourse.learningModules.length - 1].lessons[0]
          lessonIndex = 0
          moduleIndex = this.state.selectedCourse.learningModules.length - 1

        } else if (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length > 1 && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length - 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length - 1].name) {
          lessonText = (this.state.moduleIndex + 1).toString() + '.' + (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length).toString() + '. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length - 1].name
          // this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[0]
          selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex - 1].lessons[this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length - 1]
          lessonIndex = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length - 1
          // moduleIndex = this.state.moduleIndex - 1
        }
      } else {
        if (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex + 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex + 1].name) {
          lessonText = (this.state.moduleIndex + 1).toString() + '.' + (this.state.lessonIndex + 2).toString() + '. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex + 1].name
          selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex + 1]
          lessonIndex = this.state.lessonIndex + 1
          // moduleIndex = this.state.moduleIndex - 1
        } else if (this.state.selectedCourse.learningModules[this.state.moduleIndex + 1] && this.state.selectedCourse.learningModules[this.state.moduleIndex + 1].lessons && this.state.selectedCourse.learningModules[this.state.moduleIndex + 1].lessons[0] && this.state.selectedCourse.learningModules[this.state.moduleIndex + 1].lessons[0].name) {
          lessonText = (this.state.moduleIndex + 2).toString() + '.1. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex + 1].lessons[0].name
          selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex + 1].lessons[0]
          lessonIndex = 0
          moduleIndex = this.state.moduleIndex + 1
        } else if (this.state.selectedCourse.learningModules[0] && this.state.selectedCourse.learningModules[0].name !== this.state.selectedCourse.learningModules[this.state.moduleIndex].name && this.state.selectedCourse.learningModules[0].lessons && this.state.selectedCourse.learningModules[0].lessons[0] && this.state.selectedCourse.learningModules[0].lessons[0].name) {
          lessonText = '1.1. ' + this.state.selectedCourse.learningModules[0].lessons[0].name
          selectedLesson = this.state.selectedCourse.learningModules[0].lessons[0]
          lessonIndex = 0
          moduleIndex = 0
        } else if (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons.length > 1 && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[0] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[0].name) {
           lessonText = (this.state.moduleIndex + 1).toString() + '.1. ' + this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[0].name

           selectedLesson = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[0]
           lessonIndex = 0
           // moduleIndex = 0
        }
      }

      if (setText) {
        return lessonText
      } else {
        this.setLesson(selectedLesson,lessonIndex, moduleIndex)
      }

    }

    itemClicked(type, source, contentIndex, questionIndex, ac) {
      console.log('itemClicked called', type, source, contentIndex, questionIndex, ac)

      if (type === 'commandDraft') {
        let selectedCourse = this.state.selectedCourse
        selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['content'][contentIndex]['assessment']['questions'][questionIndex]['commandDraft'] = ac
        this.setState({ selectedCourse })
      } else {
        this.setState({ modalIsOpen: true, showFullView: true })
        this.props.toggleNav(true,source)
      }
    }

    scrollToBottom = () => {
      console.log('scrollToBottom called in parent')

      if (document.querySelector(".anchor-scroll-container-take-course")) {
        // console.log('we are in tho')

        if (this.messagesEnd && this.state.showFullView) {
          console.log('in full view')
          this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        } else {
          // const element = document.querySelector(".anchor")
          const container = document.querySelector(".anchor-scroll-container-take-course")
          // element.scrollTo(0,element.scrollHeight)
          // element.scrollTo({ top: element.scrollHeight, behavior: "smooth" })
          // document.body.style.overflow = 'hidden';
          // window.scrollTo(0,0)
          // element.scrollIntoView({ behavior: 'smooth' });
          // window.scrollTo(0,0)
          // container.scrollTo(0,2000)
          container.scrollTo({ top: container.scrollHeight, behavior: "smooth" })
        }


      } else if (this.messagesEnd) {
        console.log('in messagesEnd in parent')

        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        // document.getElementById('anchor').scrollIntoView({ behavior: 'smooth' });


        // const self = this
        // function officiallyTranslate() {
        //   console.log('officiallyTranslate called')
        //   // self.setState({ transform: 'translate(0%)', opacity: 1 })
        //   self.messagesEnd.scrollIntoView({ behavior: "smooth" });
        // }
        //
        // const delayTranslate = () => {
        //   console.log('delayTranslate called')
        //   clearTimeout(self.state.timerId)
        //   self.state.timerId = setTimeout(officiallyTranslate, 1000)
        // }
        //
        // delayTranslate();

      }
    }

    renderCourseTitle() {
      console.log('renderCourseTitle called')

      return (
        <div key="renderCourseTitle">
          {(window.innerWidth > 768) ? (
            <div className="full-width padding-20 dark-border primary-background-extra-light">
              <div className="calc-column-offset-15 right-padding">
                <p className="description-text-2">{this.state.selectedCourse.name}{(this.state.selectedCourse.orgName) && " by " + this.state.selectedCourse.orgName}</p>
              </div>
              <div className="fixed-column-15">
                <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showClearProgress: true })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Clear course progress">
                  <img src={reloadIconDark} alt="GC" className="image-15-fit" />
                </button>
              </div>
              <div className="clear" />
            </div>
          ) : (
            <div className="full-width padding-20 dark-border white-background">
              <div className="calc-column-offset-15 right-padding">
                <p className="description-text-1">{this.state.selectedCourse.name}</p>
                {(this.state.selectedCourse.orgName) && (
                  <p className="description-text-3">by {this.state.selectedCourse.orgName}</p>
                )}
              </div>
              <div className="fixed-column-15">
                <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showClearProgress: true })} data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Clear course progress">
                  <img src={reloadIconDark} alt="GC" className="image-15-fit" />
                </button>
              </div>
              <div className="clear" />

            </div>
          )}
          <ReactTooltip id="tooltip-placeholder-id" />
        </div>
      )
    }

    renderModules() {
      console.log('renderModules called')

      return (
        <div key="renderModules">
          <div>
            <div className="darker-background white-text min-width-100 full-width bottom-margin-20 dark-border">
              {/*
              <div className="padding-20 white-border">
                <p className="description-text-2">Course Content</p>
              </div>*/}

              {(this.state.selectedCourse.learningModules && this.state.selectedCourse.learningModules.length > 0) && (
                <div>
                  {this.state.selectedCourse.learningModules.map((value, index) =>
                    <div key={"learningModules|" + index} className="white-border">
                      {(value.isVisible) && (
                        <div>
                          <button className="background-button-better full-width left-text padding-20" onClick={() => this.toggleModule(index)}>
                            <div className="calc-column-offset-30">
                              <p>{index + 1}. {value.name}</p>
                            </div>
                            <div className="fixed-column-30 top-margin-5">
                              <img src={arrowIndicatorIconWhite} alt="GC" className={(value.opened) ? "image-15-fit pin-right rotate-270" : "image-15-fit pin-right rotate-90"} />
                            </div>
                            <div className="clear" />
                            {(value.lessons && value.lessons.length > 0) && (
                              <div className="top-margin-5">
                                <p className="description-text-4">{this.calculateCompletions(index)} / {value.lessons.length} Lessons Completed</p>
                              </div>
                            )}
                          </button>

                          {(value.opened && value.lessons && value.lessons.length > 0) && (
                            <div>
                              {value.lessons.map((value2, index2) =>
                                <div key={"learningModules|" + index + "|lessons|" + index2} className="white-border">
                                  {(value2.isVisible) && (
                                    <button className={(this.state.selectedLesson && this.state.selectedLesson.name === value2.name) ? "background-button-better full-width left-text black-background" : "background-button-better full-width left-text"} onClick={() => this.setLesson(value2,index2, index)}>
                                      <div className="padding-20">
                                        {(this.testCompletion(value, value2, index, index2)) ? (
                                          <button className="background-button clear-padding clear-margin fixed-column-40" onClick={(e) => this.toggleCompletion(e,index,index2)}>
                                            <div className="width-15 height-15 white-border top-margin-2 left-padding-2">
                                              <img src={checkmarkIconWhite} alt="GC" className="image-auto-11" />
                                            </div>
                                          </button>
                                        ) : (
                                          <button className="background-button clear-padding clear-margin fixed-column-40" onClick={(e) => this.toggleCompletion(e,index,index2)}>
                                            <div className="width-15 height-15 white-border top-margin-2" />
                                          </button>
                                        )}
                                        <div className="calc-column-offset-120">
                                          <p className="description-text-2">{index + 1}.{index2 + 1}. {value2.name}</p>
                                        </div>
                                        <div className="fixed-column-80">
                                          <p className="description-text-2 right-text">{(value2.content && value2.content[0] && value2.content[0].category) ? (value2.content[0].category === 'A.I. Script') ? "[A.I.]" : "[" + value2.content[0].category + "]" : ""}</p>
                                        </div>
                                        <div className="clear" />
                                      </div>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    moveScript(direction) {
      console.log('moveScript called', direction)

      let selectedCourse = this.state.selectedCourse
      let scriptPreviewIndex = this.state.selectedCourse.learningModules[this.state.moduleIndex]['lessons'][this.state.lessonIndex].scriptPreviewIndex
      console.log('log the scriptPreviewIndex 1: ', scriptPreviewIndex)

      if (direction === 'reset') {
        scriptPreviewIndex = 0
      } else if (direction === 'next') {
        if (scriptPreviewIndex) {
          scriptPreviewIndex = scriptPreviewIndex + 1
        } else {
          scriptPreviewIndex = 1
        }
      } else {
        if (scriptPreviewIndex) {
          scriptPreviewIndex = scriptPreviewIndex - 1
        } else {
          // scriptPreviewIndex = 1
        }
      }
      console.log('log the scriptPreviewIndex 2: ', scriptPreviewIndex)

      let showPromptResponse = false
      let scriptQuestionId = null

      let indexTracker = 0
      if (this.state.progress[this.state.moduleIndex] && this.state.progress[this.state.moduleIndex].lessons && this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker) {
        indexTracker = this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker
      }

      if (this.state.showScriptPreviews) {
        showPromptResponse = true

        // if (this.state.selectedCourse.learningModules[this.state.moduleIndex] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons && (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex === this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex)) {
        //   indexTracker = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex
        // }
        // console.log('a2', indexTracker)

        // 0 progress means indextracker must be equal to 0 to be enabled
        // 0 indexTracker means progress must be equal to 0
        console.log('compare the twoL ', scriptPreviewIndex, indexTracker)
        if (scriptPreviewIndex && indexTracker && scriptPreviewIndex === indexTracker) {
          showPromptResponse = false
        } else if (scriptPreviewIndex === 0 && indexTracker === 0) {
          showPromptResponse = false
        }

        if (showPromptResponse) {
          scriptQuestionId = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].content[0].assessment.questions[scriptPreviewIndex]._id
        }
      }

      selectedCourse['learningModules'][this.state.moduleIndex]['lessons'][this.state.lessonIndex]['scriptPreviewIndex'] = scriptPreviewIndex
      this.setState({ selectedCourse, showPromptResponse, scriptQuestionId })

    }

    configureModal(content, contentIndex) {
      console.log('configureModal called')

      this.setState({ isLoading: true })

      let submissionTitle = content[contentIndex].title + ' - ' + this.state.cuFirstName + " " + this.state.cuLastName
      let selectedSubmission = null
      let submissionDetails = null
      let attachments = null
      let externalURL = null

      if (this.state.submissions && this.state.submissions.some(sub => sub.assessmentId === content[0].contentId)) {
        const subIndex = this.state.submissions.findIndex(sub => sub.assessmentId === content[0].contentId)
        // console.log('----: ', subIndex, this.state.submissions, content[0], subIndex)
        selectedSubmission = this.state.submissions[subIndex]
        submissionTitle = selectedSubmission.title
        submissionDetails = selectedSubmission.details
        attachments = selectedSubmission.attachments
        externalURL = selectedSubmission.externalURL

      }

      // console.log('sub values: ', selectedSubmission, submissionTitle, submissionDetails, attachments, externalURL)
      // 649c820e482ae98333fca88a
      const self = this
      const emailId = this.state.emailId

      function pullGoals(attachmentOptions) {
        console.log('pullGoals called')

        Axios.get('/api/logs/goals', { params: { emailId } })
        .then((response) => {

          if (response.data.success && response.data.goals) {
            console.log('Goals received query worked', response.data);

            for (let i = 0; i < response.data.goals.length; i++) {
              const goalId = response.data.goals[i]._id
              const goalName = response.data.goals[i].title
              const createdAt = response.data.goals[i].createdAt
              attachmentOptions.push({ id: goalId, name: goalName, source: 'Native', url: null, type: 'Goal', createdAt })
            }

            self.setState({ modalIsOpen: true, showSubmitWidget: true, contentIndex, showFullView: false,
              attachmentOptions, isLoading: false,
              selectedSubmission, submissionTitle, submissionDetails, attachments, externalURL
            })

          } else {
            console.log('no goal data found', response.data.message)

            self.setState({ modalIsOpen: true, showSubmitWidget: true, contentIndex, showFullView: false,
              attachmentOptions, selectedSubmission, isLoading: false,
              selectedSubmission, submissionTitle, submissionDetails, attachments, externalURL
            })
          }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });
      }

      Axios.get('/api/documents', { params: { emailId} })
     .then((response) => {
       console.log('Documents query attempted', response.data);

       if (response.data.success && response.data.documents) {
         console.log('successfully retrieved documents')

         let attachmentOptions = [{ name: '' }]
         for (let i = 0; i < response.data.documents.length; i++) {
           const docId = response.data.documents[i]._id
           const docName = response.data.documents[i].fileName
           const docType = response.data.documents[i].docType
           const docURL = window.location.protocol + "//" + window.location.host + "/documents/preview/" + docId
           const createdAt = response.data.documents[i].createdAt
           attachmentOptions.push({ id: docId, name: docName, source: 'Native', url: docURL, type: docType, createdAt })
         }

          pullGoals(attachmentOptions)

       } else {
         console.log('no documents data found', response.data.message)
         pullGoals([{ name: '' }])
       }

     }).catch((error) => {
         console.log('Documents query did not work', error);
     });
    }

    addItem(type) {
      console.log('addItem called', type)

      if (type === 'attachment') {
        let attachments = this.state.attachments
        if (attachments) {
          if (attachments.some(att => att.id === this.state.selectedAttachment.id)) {
            this.setState({ errorMessage: "You have already added this attachment" })
          } else {
            attachments.push(this.state.selectedAttachment)
            this.setState({ attachments, selectedAttachment: null, errorMessage: null })
          }

        } else {
          attachments = [this.state.selectedAttachment]
          this.setState({ attachments, selectedAttachment: null, errorMessage: null })
        }
      } else if (type === 'trainings') {
        let trainings = this.state.trainings
        trainings.push({ title: '' })
        this.setState({ trainings })
      }
    }

    deleteItem(type, index) {
      console.log('deleteItem called')

      let attachments = this.state.attachments
      attachments.splice(index,1)
      this.setState({ attachments })
    }

    configureIndexTracker() {
      console.log('configureIndexTracker called')

      let indexTracker = 0
      let disablePrompt = false
      if (this.state.progress[this.state.moduleIndex] && this.state.progress[this.state.moduleIndex].lessons && this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker) {
        indexTracker = this.state.progress[this.state.moduleIndex].lessons[this.state.lessonIndex].indexTracker
      }

      const progressIndexTracker = indexTracker
      if (this.state.showScriptPreviews) {
        disablePrompt = true

        if (this.state.selectedCourse.learningModules[this.state.moduleIndex] && this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons && (this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex === this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex)) {
          indexTracker = this.state.selectedCourse.learningModules[this.state.moduleIndex].lessons[this.state.lessonIndex].scriptPreviewIndex
        }
        // console.log('a2', indexTracker)

        // 0 progress means indextracker must be equal to 0 to be enabled
        // 0 indexTracker means progress must be equal to 0

        if (progressIndexTracker && indexTracker && progressIndexTracker === indexTracker) {
          disablePrompt = false
        } else if (progressIndexTracker === 0 && indexTracker === 0) {
          disablePrompt = false
        }
      }

      return { indexTracker, disablePrompt }
    }

    clearProgress() {
      console.log('clearProgress called')

      let selectedCourse = this.state.selectedCourse

      let progress = []
      if (selectedCourse.learningModules && selectedCourse.learningModules.length > 0) {
        for (let i = 1; i <= selectedCourse.learningModules.length; i++) {
          let lessons = selectedCourse.learningModules[i - 1].lessons
          if (lessons && lessons.length > 0) {
            let pLessons = []
            for (let j = 1; j <= lessons.length; j++) {
              pLessons.push({ lessonId: lessons[j - 1].lessonId, name: lessons[j - 1].name, isCompleted: false, indexTracker: 0 })
              selectedCourse['learningModules'][i - 1].lessons[j - 1]['scriptPreviewIndex'] = 0
            }

            progress.push({ moduleId: selectedCourse.learningModules[i - 1].moduleId, name: selectedCourse.learningModules[i - 1].name, isCompleted: false, lessons: pLessons })

          } else {
            progress.push({ moduleId: selectedCourse.learningModules[i - 1].moduleId, name: selectedCourse.learningModules[i - 1].name, isCompleted: false })
          }
        }
      }

      this.saveProgress(progress)
      this.setState({ progress, selectedCourse })
      this.closeModal()

      let selectedLesson = null
      let lms = selectedCourse.learningModules
      if (lms && lms[0] && lms[0].lessons && lms[0].lessons[0]) {
        selectedLesson = lms[0].lessons[0]
      }
      this.setLesson(selectedLesson,0,0)

    }

    render() {
      return (
          <div>
            {(this.state.testSpeechKit) ? (
              <div>
                {/*
                <SubSpeechToText passData={this.passData} />
                <SubTextToSpeech passedText={'speak the truth'} passData={this.passData} />*/}

                <div className="relative-column-40 height-40">
                </div>
                <div className="relative-column-20 height-40">
                </div>
                <div className="relative-column-40">
                  <SubAvatar animateAvatar={this.state.animateAvatar} />
                </div>
                <div className="clear" />

              </div>
            ) : (
              <div>
                {(this.state.selectedCourse) && (
                  <div>
                    {(this.state.isLoading) ? (
                      <div className="flex-container flex-center full-space">
                        <div>
                          <div className="super-spacer" />

                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Loading...</p>

                        </div>
                      </div>
                    ) : (
                      <div>
                        {(window.innerWidth > 768) && (
                          <div>
                            {this.renderCourseTitle()}
                          </div>
                        )}

                        {(window.innerWidth > 768) && (
                          <div className="relative-column-30 full-height">
                            {this.renderModules()}
                          </div>
                        )}

                        <div className={(window.innerWidth > 768 && !this.props.inModal) ? "relative-column-70" : "full-width"}>
                          <div>
                            <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 dark-border pin-left min-height-60-percent" : "card-clear-padding full-width dark-border min-height-60-percent"}>
                              {this.loadContent()}
                            </div>

                            {(window.innerWidth < 768) && (
                              <div>
                                {this.renderCourseTitle()}
                              </div>
                            )}

                            <div>
                              {(window.location.pathname !== '/courses/' + this.state.selectedCourse._id) && (
                                <div className={(window.innerWidth > 768) ? "dark-border full-width max-width-1400" : "dark-border full-width"}>
                                  <div className="carousel-3 white-background" onScroll={this.handleScroll}>
                                    {this.state.subNavCategories.map((value, index) =>
                                      <div className="display-inline">
                                        {(value === this.state.subNavSelected) ? (
                                          <div className="selected-carousel-item">
                                            <label key={value}>{value}</label>
                                          </div>
                                        ) : (
                                          <button className="menu-button" onClick={() => this.setState({ subNavSelected: value })}>
                                            <label key={value}>{value}</label>
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {((this.state.subNavSelected === 'Modules')) && (
                                <div>
                                  {this.renderModules()}
                                </div>
                              )}

                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'About')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">About</p>

                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                  <p className="heading-text-6">{this.state.selectedCourse.name}</p>
                                  <p className="description-text-color description-text-1 top-margin">{this.state.selectedCourse.description}</p>

                                </div>
                              )}
                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Objectives')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">Objectives</p>

                                  <div className="spacer" />

                                  {(this.state.selectedCourse.learningObjectives && this.state.selectedCourse.learningObjectives.length > 0) ? (
                                    <div>
                                      {this.state.selectedCourse.learningObjectives.map((value, index) =>
                                        <div key={"learningObjectives|" + index} className="top-margin-20">
                                          <p>{index + 1}. {value.name}</p>
                                          {(value.description) && (
                                            <p className="description-text-color top-margin-5">{index + 1}. {value.description}</p>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="spacer" /><div className="spacer" />
                                      <p className="description-text-color">No learning objectives have been added to this course.</p>
                                    </div>
                                  )}
                                </div>
                              )}
                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Syllabus')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">Syllabus</p>

                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                  <p className="description-text-color">No syllabus has been posted yet.</p>

                                </div>
                              )}
                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Announcements')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">Announcements</p>

                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                  <p className="description-text-color">No announcements have been posted yet.</p>

                                </div>
                              )}
                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Discussion')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">Discussion</p>

                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                  <SubComments selectedCourse={this.state.selectedCourse} activeOrg={this.state.orgCode} accountCode={this.state.accountCode} comments={this.state.comments} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} pageSource={this.state.pageSource}/>
                                  <div className="clear" />

                                </div>
                              )}
                              {((this.state.subNavSelected === 'All' || this.state.subNavSelected === 'Members')) && (
                                <div className={(window.innerWidth > 768) ? "card-clear-padding full-width max-width-1400 padding-40 dark-border" : "card-clear-padding full-width padding-40 dark-border"}>
                                  <p className="heading-text-3">Members</p>

                                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                                  {(this.state.students && this.state.students.length > 0) ? (
                                    <div>
                                      <SubRenderProfiles
                                        favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                        members={this.state.students} friends={this.state.friends}
                                        pageSource={"Profiles"} userType="Peers"
                                      />
                                      <div className="clear" />
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="description-text-color">No other students have been added yet.</p>
                                    </div>
                                  )}

                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="clear" />

                      </div>
                    )}

                    <Modal
                     isOpen={this.state.modalIsOpen}
                     onAfterOpen={this.afterOpenModal}
                     onRequestClose={this.closeModal}
                     className={(this.state.showFullView) ? "modal-full-space-2" : "modal"}
                     overlayClassName="modal-overlay"
                     contentLabel="Example Modal"
                     ariaHideApp={false}
                   >

                    {(this.state.showFullView) && (
                      <div className="card min-height-94-percent">

                        {this.loadContent(true)}
                        <div className="clear" />

                        <button className="background-button position-fixed left-0 left-margin-40 vertical-center previous-arrow" onClick={() => this.calculateAdjacentLesson('previous', false)}>
                          <div className="black-background row-20 horizontal-padding-15">
                            <img src={arrowIndicatorIconWhite} alt="GC" className="image-auto-22 rotate-180" />
                          </div>
                        </button>

                        <div className="previous-arrow-tooltip position-fixed left-0 left-margin-65 vertical-center black-background white-text row-20 horizontal-padding-15">
                          <p>{this.calculateAdjacentLesson('previous', true)}</p>
                        </div>

                        <button className="background-button position-fixed right-0 left-margin-40 vertical-center next-arrow" onClick={() => this.calculateAdjacentLesson('next', false)}>
                          <div className="black-background row-20 horizontal-padding-15">
                            <img src={arrowIndicatorIconWhite} alt="GC" className="image-auto-22" />
                          </div>
                        </button>

                        <div className="next-arrow-tooltip position-fixed right-0 right-margin-65 vertical-center black-background white-text row-20 horizontal-padding-15">
                          <p>{this.calculateAdjacentLesson('next', true)}</p>
                        </div>

                        <button className="background-button position-fixed bottom-0 right-0 right-margin-40 bottom-margin-40" onClick={() => this.closeModal()}>
                          <img src={minimizeIcon} alt="GC" className="image-auto-25" />
                        </button>
                      </div>
                    )}

                    {(this.state.editVariable) && (
                      <div className="padding-20">
                        <p className="heading-text-2">Replace the Following Variables to Submit Prompt</p>
                        <div className="spacer" />

                        {(this.state.commandVariables && this.state.commandVariables.length > 0) && (
                          <div>
                            {this.state.commandVariables.map((value, index) =>
                              <div className="top-margin-25">
                                <label className="profile-label">Replace <label className="bold-text">"{value.trim()}"</label> with:</label>
                                <input type="text" className="text-field" placeholder="(e.g., Write your replacement..." name={"replacements|" + index} value={(this.state.replacements) ? this.state.replacements[index] : ""} onChange={this.formChangeHandler}></input>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        <button className="btn btn-primary" onClick={() => this.replaceVariables()}>Save Changes</button>
                        <button className="btn btn-secondary left-margin" onClick={(this.state.previousView === 'Full View') ? () => this.setState({ editVariable: false, showFullView: true }) : () => this.closeModal()}>Close View</button>
                      </div>
                    )}

                    {(this.state.showSubmitWidget) && (
                      <div className="padding-20">
                        <p className="heading-text-2">Submit to {(this.state.selectedIndex || this.state.selectedIndex === 0) ? this.state.selectedLesson.content[0].title : "Course"}</p>
                        <div className="spacer" />

                        <div className="row-10">
                          <label className="profile-label">Title <label className="error-color bold-text">*</label></label>
                          <input type="text" className="text-field" placeholder="e.g., My Awesome Title..." name={"submissionTitle"} value={this.state.submissionTitle} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="row-10">
                          <label className="profile-label">Summary <label className="error-color bold-text">*</label></label>
                          <textarea type="text" className="text-field" placeholder="(e.g., Write details, comments, feedback, etc ...)" name={"submissionDetails"} value={this.state.submissionDetails} onChange={this.formChangeHandler}></textarea>
                        </div>

                        <div className="row-10">
                          <div>
                            <div className="calc-column-offset-30 right-padding">
                              <label className="profile-label">Add Attachments from your Guided Compass Profile</label>
                            </div>
                            {/*
                            <div className="fixed-column-30 top-margin">
                              <button className="background-button width-30 height-30 circle-radius cta-background-color padding-5" onClick={() => this.addItem('attachment')}>
                                <img src={addIconWhite} alt="GC" className="image-20-fit" />
                              </button>
                            </div>*/}
                            <div className="clear" />
                          </div>

                          <div>
                            <div className="calc-column-offset-70 right-padding">
                              <select name="attachment" value={(this.state.selectedAttachment) ? this.state.selectedAttachment.name : ""} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.attachmentOptions.map(value =>
                                  <option key={value.name} value={value.name}>{(value.id) ? value.name + ' (created ' + convertDateToString(new Date(value.createdAt),"date-2") + ') [' + value.type + ']' : ""}</option>
                                )}
                              </select>
                            </div>
                            <div className="fixed-column-70">
                              <button className={(this.state.selectedAttachment) ? "btn btn-squarish full-width" : "btn btn-squarish medium-background standard-border full-width"} disabled={(this.state.selectedAttachment) ? false : true} onClick={() => this.addItem('attachment')}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.attachments) && (
                            <div>
                              {this.state.attachments.map((item, optionIndex) =>
                                <div key={"attachment|" + optionIndex} className="top-margin-15">
                                  <div className="fixed-column-40 top-margin">
                                    <button className="background-button z-index-1" onClick={() => this.deleteItem('attachment', optionIndex)}>
                                      <img src={deniedIcon} alt="GC" className="image-auto-22 pin-right" />
                                    </button>
                                  </div>
                                  <div className="tag-container-basic light-background calc-column-offset-40">
                                    <div className="fixed-column-40">
                                      <p>{optionIndex + 1}.</p>
                                    </div>
                                    <div className="calc-column-offset-80">
                                      <p>{item.name} (created {convertDateToString(new Date(item.createdAt),"date-2")}) [{item.type}]</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}
                            </div>
                          )}

                        </div>

                        <div className="row-10">
                          <label className="profile-label">Link to External Document</label>
                          <input type="text" className="text-field" placeholder="(e.g., https://drive.google.com/12345679...)" name={"externalURL"} value={this.state.externalURL} onChange={this.formChangeHandler}></input>
                          {(this.state.externalURL && !this.state.externalURL.startsWith('http')) && (
                            <div className="top-margin-5">
                              <p className="error-color description-text-2">Please start your link with http</p>
                            </div>
                          )}
                        </div>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                        <button className="btn btn-primary" onClick={() => this.submitAssignment()}>{(this.state.selectedSubmission) ? "Update Assignment" : "Submit Assignment"}</button>
                        <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    )}

                    {(this.state.showClearProgress) && (
                      <div className="padding-20">
                        <p className="heading-text-2">Clear Course Progress</p>
                        <div className="spacer" />

                        <div className="row-30">
                          <p>Would you like to clear your progress across all learning modules so that you can start this course from the beginning?</p>
                        </div>

                        <div className="spacer" /><div className="spacer" /><div className="spacer" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-10">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-10">{this.state.successMessage}</p>}

                        <button className="btn btn-primary" onClick={() => this.clearProgress()}>Clear Progress</button>
                        <button className="btn btn-secondary left-margin" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    )}

                   </Modal>

                  </div>
                )}
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(TakeCourse);
