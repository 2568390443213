import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SubCustomDropdown from '../Common/CustomDropdown';

import {convertDateToString} from '../Functions/ConvertDateToString';
import {toggleVars} from '../Functions/ToggleVars';
import withRouter from '../Functions/WithRouter';

const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const emailIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/email-icon.png';
const chatbubblesIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/chatbubbles-icon.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const searchIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/search-icon.png";
const filterIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon.png";
const filterIconSelected = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/filter-icon-selected.png";

const styles = {
    transition: 'all 0.5s ease-out',
    position: 'relative',
    transform: 'translate(90%)'
};

class ScheduledMessages extends Component {
    constructor(props) {
        super(props)

        this.state = {
          resLimit: 500,
          opacity: 0,
          transform: 'translate(90%)',

          messages: [],

          hideFilter: false,
          itemFilters: [],
          itemSorters: [],
          defaultFilterOption: 'All',
          defaultSortOption: 'No Sort Applied',

        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.toggleSearchBar = this.toggleSearchBar.bind(this)
        this.filterResults = this.filterResults.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.org !== prevProps.org || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called', this.props.accountCode)

      const org = this.props.org
      const accountCode = this.props.accountCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let orgFocus = localStorage.getItem('orgFocus');

      if (org) {

        //filters
        const defaultFilterOption = this.state.defaultFilterOption

        const scheduledFilterOptions = [defaultFilterOption,'Yes','No']
        const repeatsFilterOptions = [defaultFilterOption,'Yes','No']
        const messageTypeFilterOptions = [defaultFilterOption,'Email','Text']
        // const createdAtFilterOptions = [defaultFilterOption,'Has None','At Least One']

        const itemFilters = [
          { name: 'Scheduled to be Sent', value: defaultFilterOption, options: scheduledFilterOptions},
          { name: 'Repeats', value: defaultFilterOption, options: repeatsFilterOptions },
          { name: 'Message Type', value: defaultFilterOption, options: messageTypeFilterOptions },
          // { name: 'Created At', value: defaultFilterOption, options: functionFilterOptions },
        ]

        // Filter by whether it is scheduled
        // Filter by whether it repeats
        // Filter by message type (email or text)
        // Filter by when it was created
        // Filter by role name
        // Filter by cohort


        this.setState({ org, emailId: email, username, orgFocus, accountCode, itemFilters })

        // const onlyUpcoming = true
        const sortByMessageDate = true
        const repeats = null
        const resLimit = this.state.resLimit
        const mParams = { orgCode: org, sortByMessageDate, repeats, resLimit }

        Axios.get('/api/scheduled-messages', { params: mParams })
        .then((response) => {
          console.log('Scheduled messages query worked', response.data);

          if (response.data.success) {

            const messages = response.data.messages
            this.setState({ messages })

          } else {
            console.log('no message data found', response.data.message)
          }

        }).catch((error) => {
            console.log('Placements query did not work', error);
        });
      }

      if (window.innerWidth > 768) {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)

      } else {

          setTimeout(() => {
              this.setState(state => ({
                  opacity: 1,
                  transform: 'translate(2%)'
                }));
          }, 0.3)
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'search') {
        console.log('in search')
        const searchString = event.target.value
        this.setState({ searchString, animating: true })
        this.filterResults(event.target.value, null, null, true)

      } else if (event.target.name.includes('filter|')) {

        const nameArray = event.target.name.split("|")

        let itemFilters = this.state.itemFilters
        const eventName = nameArray[1]
        const index = Number(nameArray[2])

        itemFilters[index]['value'] = event.target.value
        console.log('show the item values: ', itemFilters, eventName, index, event.target.value)

        this.setState({ itemFilters, animating: true })
        this.filterResults(this.state.searchString, eventName, event.target.value, false, itemFilters)

      } else {
        this.setState({ [event.target.name] : event.target.value })
      }
    }

    filterResults(searchString, filterName, filterValue, search, filters) {
      console.log('filterResults called', searchString, filterName, filterValue, search, filters)

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.org
        let isActive = null
        if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
          isActive = true
        }

        if (search) {
          Axios.get('/api/scheduled-messages/search', { params: { searchString, orgCode, isActive } })
          .then((response) => {
            console.log('Scheduled message search query attempted', response.data);

              if (response.data.success) {
                console.log('message search query worked')

                let messages = response.data.messages
                self.setState({ messages, filteredResults: true, animating: false })

              } else {
                console.log('message search query did not work', response.data.message)

                // let memberOptions = []
                self.setState({ animating: false })

              }

          }).catch((error) => {
              console.log('Search query did not work for some reason', error);
              self.setState({ animating: false })
          });
        } else {
          console.log('filter results: ', searchString, orgCode, filterName, filterValue)

          let messages = self.state.messages
          // let filters = self.state.itemFilters
          // if (self.state.groups && self.state.groups[0] && self.state.groups[0].matchScore) {
          //   users = self.state.groups
          // }

          // eventually query groups from back-end
          Axios.put('/api/scheduled-messages/filter', { searchString, orgCode, filterName, filterValue, filters, messages, isActive })
          .then((response) => {
            console.log('Messages filter query attempted', response.data);

              if (response.data.success) {
                console.log('message filter query worked')

                let messages = response.data.messages
                const filterCriteriaArray = response.data.filterCriteriaArray
                // console.log('show filterCriteriaArray: ', filterCriteriaArray)
                self.setState({ messages, filterCriteriaArray, filteredResults: true, animating: false })

              } else {
                console.log('message filter query did not work', response.data.message)

                self.setState({ animating: false })

              }

          }).catch((error) => {
              console.log('Message filter did not work for some reason', error);
              self.setState({ animating: false })
          });
        }
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    sortResults(searchString, sortName, sortValue, search) {
      console.log('filterResults called', searchString, sortName, sortValue, search)

      let roleNames = ['Student','Career-Seeker']

      const self = this
      function officiallyFilter() {
        console.log('officiallyFilter called')

        const orgCode = self.state.activeOrg
        const excludeCurrentUser = true
        const emailId = self.state.emailId

        let groups = self.state.groups
        // if (self.state.groups && self.state.groups[0] && self.state.groups[0].matchScore) {
        //   users = self.state.groups
        // }

        let isActive = null
        if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/advisor')) {
          isActive = true
        }

        // eventually query groups from back-end
        Axios.put('/api/scheduled-messages/sort', { searchString, orgCode, sortName, sortValue, roleNames, groups, excludeCurrentUser, emailId, isActive })
        .then((response) => {
          console.log('Groups sort query attempted', response.data);

            if (response.data.success) {
              console.log('sort query worked')

              let groups = response.data.groups
              self.setState({ groups, sortedResults: true, animating: false })

            } else {
              console.log('sorted query did not work', response.data.message)

              self.setState({ animating: false })

            }

        }).catch((error) => {
            console.log('Search query did not work for some reason', error);
            self.setState({ animating: false })
        });
      }

      const delayFilter = () => {
        console.log('delayFilter called: ')
        clearTimeout(self.state.timerId)
        self.state.timerId = setTimeout(officiallyFilter, 1000)
      }

      delayFilter();
    }

    toggleSearchBar(action) {
      console.log('toggleSearchBar called ', action)

      let showingSearchBar = this.state.showingSearchBar
      if (showingSearchBar) {
        showingSearchBar = false
      } else {
        showingSearchBar = true
      }

      this.setState({ showingSearchBar })
    }

    render() {

      let editPlacementLinkPrefix = '/organizations/' + this.state.org + '/message-blasts/'
      if (window.location.pathname.includes('/employers')) {
        editPlacementLinkPrefix = '/employers/' + this.state.accountCode + '/message-blasts/'
      } else if (window.location.pathname.includes('/advisor')) {
        editPlacementLinkPrefix = '/advisor/message-blasts/'
      }

      return (
          <div>
            <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent max-width-1400 center-horizontally top-margin-2-percent card" : "floating-container card-clear-padding top-margin-2-percent right-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              <div className={(toggleVars.hideAdminSideNav) ? "" : "row-20 horizontal-padding"}>
                <div className="calc-column-offset-25">
                  <p className="heading-text-2">{(this.state.messages) ? (this.state.messages.length === this.state.resLimit) ? this.state.messages.length + "+ " : this.state.messages.length + " " : ""}Message Blasts</p>
                </div>

                {(window.location.pathname.includes('/organizations') || window.location.pathname.includes('/advisor')) && (
                  <div>
                    <div className="fixed-column-25">
                      <div className="spacer" />
                      <Link className="background-button float-left" to={editPlacementLinkPrefix + 'add'}>
                        <img src={addIcon} alt="GC" className="image-auto-25" />
                      </Link>
                    </div>
                  </div>
                )}
                <ReactTooltip id="tooltip-placeholder-id" />
                <div className="clear" />

                <p className="description-text-2 row-5 standard-color">Schedule text and email messages to members.</p>
              </div>
            </div>

            <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent max-width-1400 center-horizontally" : "floating-container right-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
              <div className="full-width row-5 white-background standard-border medium-shadow top-margin-20">
                <div className="filter-field-search clear-margin calc-column-offset-50 white-background clear-border">
                  <div className="search-icon-container">
                    <img src={searchIcon} alt="Compass search icon" className="image-auto-28 padding-5"/>
                  </div>
                  <div className="filter-search-container calc-column-offset-100-static top-padding-5">
                    <input type="text" className="text-field clear-border" placeholder="Search..." name="search" value={this.state.searchString} onChange={this.formChangeHandler}/>
                  </div>
                </div>
                {(!this.state.hideFilter) && (
                  <div className="fixed-column-50 center-item">
                    <button type="button" className="background-button full-space bottom-padding" onClick={() => this.toggleSearchBar('show')}>
                      <img src={(this.state.showingSearchBar) ? filterIconSelected : filterIcon} alt="GC" className="image-auto-25 center-item top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Filter Results"/>
                    </button>
                  </div>
                )}

                <div className="clear" />
              </div>
            </div>

            {(this.state.showingSearchBar) && (
              <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent max-width-1400 center-horizontally" : "floating-container right-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                <div className="card top-margin-20">
                  <label className="profile-label">Filter Options</label>

                  <div>
                    {this.state.itemFilters.map((item, index) =>
                      <div key={index}>
                        <SubCustomDropdown dropdownTitle={item.name} name={"filter|" + item.name + '|' + index} value={item.value} options={item.options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />

                        {(index % 3 === 2) && (
                          <div>
                            <div className="clear" />
                            <div className="spacer" />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="clear" />
                  </div>

                  {(this.state.sortOptions && this.state.sortOptions.length > 0) && (
                    <div>
                      <div className="spacer" />

                      <label className="profile-label">Sort Options</label>

                      <div>
                        {this.state.itemSorters.map((item, index) =>
                          <div>
                            <SubCustomDropdown dropdownTitle={item.name} name={"sort|" + item.name + '|' + index} value={item.value} options={item.options} optionSubKey={null} optionClass="float-left curtail-text relative-position z-index-1" formChangeHandler={this.formChangeHandler} />

                            {(index % 3 === 2) && (
                              <div>
                                <div className="clear" />
                                <div className="spacer" />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="clear" />
                      </div>
                    </div>
                  )}

                </div>

              </div>
            )}

            {(this.state.animating) ? (
              <div className="flex-container flex-center full-space">
                <div>
                  <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <p className="center-text cta-color bold-text">Calculating results...</p>

                </div>
              </div>
            ) : (
              <div className={(toggleVars.hideAdminSideNav) ? "width-70-percent max-width-1400 center-horizontally top-margin-2-percent card" : "floating-container card-clear-padding top-margin-2-percent right-margin-2-percent bottom-margin-2-percent"} style={(toggleVars.hideAdminSideNav) ? {} : {...styles, opacity: this.state.opacity, transform: this.state.transform}}>
                <div className={(toggleVars.hideAdminSideNav) ? "" : "row-20 horizontal-padding"}>
                  {(this.state.messages && this.state.messages.map((value, index) =>
                    <div key={value._id} className="bottom-margin-20">
                      <Link className="secret-link" to={'/organizations/' + this.state.org + '/message-blasts/' + value._id} state={{ selectedScheduledMessage: value }}>
                        <span className="fixed-column-60">
                          <img src={(value.messageType === 'Email') ? emailIcon : chatbubblesIcon} alt="GC" className="image-40-fit top-margin-5 center-horizontally"/>
                        </span>
                        <span className={"calc-column-offset-140 left-padding"}>
                          <div className="full-width">
                            {(value.postingTitle && value.postType) ? (
                              <p className="heading-text-5 standard-color">{value.postingTitle} RSVPs</p>
                            ) : (
                              <div>
                                {(value.recipients) && (
                                  <p className="heading-text-5 standard-color">{value.recipients.length} Recipients</p>
                                )}
                              </div>
                            )}

                          </div>

                          <div className="half-spacer"/>
                          <p className="standard-color">{value.messageType} | {convertDateToString(new Date(value.messageDate),"datetime-2")} | {(new Date(value.messageDate) > new Date()) ? "SCHEDULED" : "SENT"}{(value.repeats) && " | Repeats: " + value.repeats}</p>
                          {(value.messageTitle) && (
                            <p className="standard-color description-text-2 top-padding">{value.messageTitle}</p>
                          )}
                          <p className="description-text-color description-text-2 top-padding wrap-text">{value.messageText}</p>
                        </span>

                        {(new Date(value.messageDate) < new Date()) ? (
                          <div className="fixed-column-40 top-margin">
                            <img src={checkmarkIcon} alt="GC" className="image-20-fit pin-right"/>
                          </div>
                        ) : (
                          <div className="fixed-column-40 height-40" />
                        )}

                        <div className="fixed-column-40 top-margin">
                          <img src={arrowIndicatorIcon} alt="GC" className="image-20-fit pin-right"/>
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                        <div className="clear" />
                      </Link>

                      <div className="spacer" /><div className="spacer" />
                      <hr />
                    </div>
                  ))}

                </div>
              </div>
            )}
          </div>

      )
    }
}

export default withRouter(ScheduledMessages);
