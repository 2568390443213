import React, {Component} from 'react';

import { Link } from 'react-router-dom';

const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const linkedInIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/linkedin-icon-dark.png';
const compassOnlyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/compass-only-icon-dark.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const moneyIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/money-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';

class IdeaSummary extends Component {
    constructor(props) {
        super(props)

        this.state = {
          includedItems: [],
        }

        this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props, prevProps)

      if (this.props.selectedIdea !== prevProps.selectedIdea) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in SubIdeaSummary')

      const selectedIdea = this.props.selectedIdea
      const includedItems = this.props.includedItems

      this.setState({ selectedIdea, includedItems })
    }

    render() {

      return (
          <div>
            {(this.state.selectedIdea) && (
              <div>

                <div className="row-15">
                  <p className="heading-text-4">Teaser Description</p>
                  <p className="keep-line-breaks">{this.state.selectedIdea.teaser}</p>
                </div>

                <div className="bottom-margin-15">
                  <div className="container-left top-margin-15">
                    <p className="heading-text-4">About the Author</p>
                    <div className="spacer" />

                    <div>
                      <div className="fixed-column-80">
                        <img src={(this.state.selectedIdea.pictureURL) ? this.state.selectedIdea.pictureURL : profileIconDark} alt="GC" className="profile-thumbnail-4"/>
                      </div>
                      <div className="calc-column-offset-80">
                        <p>{this.state.selectedIdea.firstName} {this.state.selectedIdea.lastName}</p>
                        {(this.state.selectedIdea.yearsOfWorkExperience) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={opportunitiesIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.yearsOfWorkExperience} Years of Work Experience</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.linkedInURL) && (
                          <div className="top-margin">
                            <div className="fixed-column-30 top-margin-3">
                              <img src={linkedInIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <a className="description-text-2 wrap-text" target="_blank" rel="noopener noreferrer" href={this.state.selectedIdea.linkedInURL}>{this.state.selectedIdea.linkedInURL}</a>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.username) && (
                          <div className="top-margin">
                            <div className="fixed-column-30 top-margin-3">
                              <img src={compassOnlyIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                            {/*
                              <Link className="description-text-2" target="_blank" to={window.location.protocol + "//" + window.location.host + "/" + this.state.selectedIdea.username + "/profile"}>{window.location.protocol + "//" + window.location.host + "/" + this.state.selectedIdea.username + "/profile"}</Link>*/}
                              <Link className="description-text-2 wrap-text" target="_blank" to={"/" + this.state.selectedIdea.username + "/profile"}>{window.location.protocol + "//" + window.location.host + "/" + this.state.selectedIdea.username + "/profile"}</Link>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.showContactInfo) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={sendIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.email}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.race) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={profileIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.race}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.gender) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={profileIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.gender}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.veteran) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={profileIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.veteran}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.zipcode) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={profileIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2">{this.state.selectedIdea.zipcode}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.collaborators && this.state.selectedIdea.collaborators.length > 0) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={socialIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              {this.state.selectedIdea.collaborators.map((value, optionIndex) =>
                                <div key={value.name}>
                                  <p className="description-text-2">{value.name}</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                        {(this.state.selectedIdea.consultingRate) && (
                          <div className="top-margin">
                            <div className="fixed-column-30">
                              <img src={moneyIconDark} alt="GC" className="image-auto-20"/>
                            </div>
                            <div className="calc-column-offset-30">
                              <p className="description-text-2"><label className="cta-color">${this.state.selectedIdea.consultingRate}</label> Consulting Rate</p>
                            </div>
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="container-right top-margin-15">
                    <p className="heading-text-4">What's Included</p>
                    <div className="spacer" />

                    {this.state.includedItems.map((value, optionIndex) =>
                      <div key={value.name}>
                        <div className="container-left bottom-margin-20">
                          <div className="fixed-column-25">
                            <img src={checkmarkIcon} alt="GC" className="image-auto-15"/>
                          </div>
                          <div className="calc-column-offset-25">
                            <p className="description-text-2">{value.name}</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </div>
                    )}
                    <div className="clear" />
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-15">
                  <p className="heading-text-4">What the Creator(s) Wants</p>
                  <p className="cta-color heading-text-2">${this.state.selectedIdea.price}</p>
                  {(this.state.selectedIdea.benefits && this.state.selectedIdea.benefits.length > 0) ? (
                    <div className="row-10">
                      {this.state.selectedIdea.benefits.map((value, optionIndex) =>
                        <div key={value}>
                          <div className="bottom-margin">
                            <div className="fixed-column-15 top-margin-7">
                              <div className="height-5 width-5 circle-radius dark-background" />
                            </div>
                            <div className="calc-column-offset-15">
                              <p className="description-text-2">{value}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="row-10">
                      <p className="description-text-2">The author has not specified other benefits (e.g., board seat, equity, credit on the website, etc...).</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
      )
    }
}

export default IdeaSummary;
