import React, {Component} from 'react';
import Axios from 'axios';
import withRouter from '../components/Functions/WithRouter';

const gcFrontImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/gc-front-image.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const fullGuidedCompassLogo = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/full-guided-compass-logo.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class RemoteAuthConfig extends Component {
    constructor(props) {
      super(props)
      this.state = {
        retrievedEmail: false,
        roleName: 'Student'
      }

      this.formChangeHandler = this.formChangeHandler.bind(this)
      this.setLocalStorage = this.setLocalStorage.bind(this)
      this.saveProfile = this.saveProfile.bind(this)

      this.searchDistricts = this.searchDistricts.bind(this)
      this.searchSchools = this.searchSchools.bind(this)
      this.optionClicked = this.optionClicked.bind(this)

    }

    componentDidMount() {
      console.log('remote auth config called')

      const { oauthUid } = this.props.params
      if (oauthUid) {
        // 60ba41d74ef4ae0116d87835
        Axios.get('/api/oauth/profile', { params: { oauthUid } })
        .then((response) => {
          console.log('Oauth uid query attempted', response.data);

          if (response.data.success) {
            console.log('ouathuid profile info query worked')

            let username = response.data.user.username
            const emailId = response.data.user.email
            const school = response.data.user.school
            const firstName = response.data.user.firstName
            let userObject = response.data.user
            const roleName = response.data.user.roleName
            // console.log('show school: ', school)
            const successDefined = response.data.user.successDefined

            if (response.data.user.uniqueUsername) {
              if (!school || school === '' || school === 'Sandbox') {
                this.setState({ collectSchoolInfo: true, emailId, firstName, userObject, oauthUid, roleName, successDefined })
              } else {
                this.setLocalStorage(userObject)
              }
            } else {
              // check for unique username
              Axios.get('/api/profile/confirm-unique-username', { params: { emailId, username } })
              .then((response2) => {
                console.log('Confirm unique username query attempted', response2.data);

                  if (response2.data.success) {
                    console.log('unique username query worked')

                    const username = response2.data.username

                    if (!school || school === '' || school === 'Sandbox') {
                      userObject['username'] = username
                      this.setState({ collectSchoolInfo: true, emailId, firstName, userObject, oauthUid, roleName, successDefined })
                    } else {
                      userObject['username'] = username
                      this.setLocalStorage(userObject)
                    }

                  } else {
                    console.log('there was an error')
                    if (!school || school === '' || school === 'Sandbox') {
                      this.setState({ collectSchoolInfo: true, emailId, firstName, userObject, oauthUid, roleName, successDefined })
                    } else {
                      this.setLocalStorage(userObject)
                    }
                  }
              })
            }

          } else {
            console.log('org info query did not work', response.data.message)
            //this.setState({ retrievedEmail: true })
          }
        }).catch((error) => {
            console.log('Org info query did not work for some reason', error);
        });
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called')

      if (event.target.name === 'schoolDistrict') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchDistricts(event.target.value )
      } else if (event.target.name === 'school') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchSchools(event.target.value )
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    setLocalStorage(user) {
      console.log('setLocalStorage called', user)

      let roleName = user.roleName
      if (user.roleName.toLowerCase() === 'parent') {
        roleName = 'Mentor'
      } else if (user.roleName.toLowerCase() === 'system admin' || user.roleName.toLowerCase() === 'system administrator') {
        roleName = 'Admin'
      } else if (user.roleName.toLowerCase() === 'principal') {
        roleName = 'Admin'
      } else if (user.roleName.toLowerCase() === 'school support') {
        roleName = 'Teacher'
      } else if (user.roleName.toLowerCase() === 'help desk') {
        roleName = 'Teacher'
      } else if (user.roleName.toLowerCase() === 'staff (non-classroom)') {
        roleName = 'Teacher'
      } else if (user.roleName.toLowerCase() === 'staff (classroom)') {
        roleName = 'Teacher'
      } else if (user.roleName.toLowerCase() === 'help desk 2') {
        roleName = 'Teacher'
      } else if (user.roleName.toLowerCase() === 'academy lead') {
        roleName = 'Teacher'
      }

      console.log('new rolename: ', roleName)

      Axios.get('/api/org', { params: { orgCode: user.activeOrg }})
      .then((response) => {
        console.log('Org info query attempted', response.data);

          if (response.data.success) {
            console.log('org info query worked', roleName)

            const orgName = response.data.orgInfo.orgName
            const placementPartners = response.data.orgInfo.placementPartners
            // const orgLogoURI = response.data.orgInfo.webLogoURI
            // const orgFocus = response.data.orgInfo.orgFocus

            localStorage.setItem('email', user.email)
            localStorage.setItem('username', user.username)
            localStorage.setItem('firstName', user.firstName)
            localStorage.setItem('lastName', user.lastName)
            localStorage.setItem('activeOrg', user.activeOrg)
            localStorage.setItem('orgName', orgName)
            localStorage.setItem('placementPartners', placementPartners)
            localStorage.setItem('roleName', roleName)
            localStorage.setItem('orgFocus', 'School')
            localStorage.setItem('remoteAuth', true)
            localStorage.setItem('authType', user.authType)
            localStorage.setItem('promptWelcomeMessage','true')

            this.setState({ retrievedEmail: true, roleName: user.roleName, activeOrg: user.activeOrg })

          } else {
            console.log('org info query did not work', response.data.message)

            localStorage.setItem('email', user.email)
            localStorage.setItem('username', user.username)
            localStorage.setItem('firstName', user.firstName)
            localStorage.setItem('lastName', user.lastName)
            localStorage.setItem('activeOrg', user.activeOrg)
            localStorage.setItem('roleName', roleName)
            localStorage.setItem('orgFocus', 'School')
            localStorage.setItem('remoteAuth', true)
            localStorage.setItem('authType', user.authType)
            localStorage.setItem('promptWelcomeMessage','true')

            this.setState({ retrievedEmail: true, roleName: user.roleName })
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });
    }

    searchDistricts(searchString) {
      console.log('searchDistricts ', searchString)

      this.setState({ districtsAreLoading: true })

      if (searchString === '') {
        const districtOptions = []
        this.setState({ districtOptions, districtsAreLoading: false })
      } else {

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter districts')

          Axios.get('/api/districts/search', { params: { searchString } })
          .then((response) => {
            console.log('Districts search query attempted', response.data);

              if (response.data.success) {
                console.log('districts search query worked')

                const districtOptions = response.data.districts
                self.setState({ districtOptions, districtsAreLoading: false });

              } else {
                console.log('district search query did not work', response.data.message)
                self.setState({ districtsAreLoading: false })
              }

          }).catch((error) => {
              console.log('District search query did not work for some reason', error);
              self.setState({ districtsAreLoading: false })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();

      }
    }

    searchSchools(searchString) {
      console.log('searchSchools ', searchString)

      this.setState({ schoolsAreLoading: true })

      if (searchString === '') {
        const schoolOptions = []
        this.setState({ schoolOptions, schoolsAreLoading: false })
      } else {

        const self = this
        function officiallyFilter() {
          console.log('officiallyFilter districts')

          Axios.get('/api/schools/search', { params: { searchString } })
          .then((response) => {
            console.log('Schools search query attempted', response.data);

              if (response.data.success) {
                console.log('schools search query worked')

                const schoolOptions = response.data.schools
                self.setState({ schoolOptions, schoolsAreLoading: false });

              } else {
                console.log('school search query did not work', response.data.message)
                self.setState({ schoolsAreLoading: false })
              }

          }).catch((error) => {
              console.log('School search query did not work for some reason', error);
              self.setState({ schoolsAreLoading: false })
          });
        }

        const delayFilter = () => {
          console.log('delayFilter called: ')
          clearTimeout(self.state.timerId)
          self.state.timerId = setTimeout(officiallyFilter, 1000)
        }

        delayFilter();

      }
    }

    optionClicked(index, type) {
      console.log('optionClicked', index, type)

      if (type === 'district') {
        const districtOptions = []
        let schoolDistrict = this.state.districtOptions[index]

        this.setState({ districtOptions, schoolDistrict, schoolDistrictClicked: true })
      } else if (type === 'school') {
        const schoolOptions = []
        let school = this.state.schoolOptions[index]

        this.setState({ schoolOptions, school, schoolClicked: true })
      }
    }

    saveProfile() {
      console.log('saveProfile called', this.state.schoolDistrict)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.schoolDistrict || this.state.schoolDistrict === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your school district'})
      } else if (!this.state.school || this.state.school === '') {
        this.setState({ isSaving: false, errorMessage: 'Please add your school'})
      } else if (!this.state.emailId || this.state.emailId === '' ) {
        this.setState({ isSaving: false, errorMessage: 'Please add your email'})
      // } else if (!this.state.emailId.includes('@') || this.state.emailId.includes('@placeholder')) {
      //   this.setState({ isSaving: false, errorMessage: 'Please add a valid email'})
      } else {
        console.log('about to save')

        if (this.state.roleName === 'Student' && (!this.state.schoolDistrictClicked || !this.state.schoolClicked)) {
          return this.setState({ isSaving: false, errorMessage: 'Please select one of the available school districts and schools that pop up once you start typing in the related field.'})
        }

        const useOauthKey = true
        const oauthUid = this.state.oauthUid
        const emailId = this.state.emailId
        const schoolDistrict = this.state.schoolDistrict
        const school = this.state.school
        const activeOrg = school.toLowerCase().replace(/ - /g,"-").replace(/ /g,"-")
        const myOrgs = [activeOrg]
        const successDefined = this.state.successDefined

        Axios.post('/api/users/profile/details', { useOauthKey, oauthUid, emailId, schoolDistrict, school, activeOrg, myOrgs, successDefined })
        .then((response) => {
          console.log('Updated profile attempted', response.data);

            if (response.data.success) {
              console.log('Update profile attempt worked')

              let userObject = this.state.userObject
              userObject['schoolDistrict'] = schoolDistrict
              userObject['school'] = school
              userObject['activeOrg'] = activeOrg
              userObject['myOrgs'] = myOrgs

              // create org
              const orgObject = {
                orgName: school, school, district: schoolDistrict,
                orgCode: activeOrg, orgFocus: 'School',
                contactFirstName: userObject.firstName, contactLastName: userObject.lastName,
                contactEmail: userObject.email, placementPartners: ['sandbox'], wblPreferences: 'Projects',
                sections: userObject.sections, isActive: true
              }

              Axios.post('/api/org/create', orgObject)
              .then((response) => {
                console.log('Create org attempted', response.data);

                  if (response.data.success) {
                    console.log('Org creation worked')

                    this.setLocalStorage(userObject)

                  } else {
                    console.log('org creation did not work', response.data.message)
                    this.setLocalStorage(userObject)
                  }

              }).catch((error) => {
                  console.log('Org creation did not work', error);
                  this.setLocalStorage(userObject)
              });

            } else {
              console.log('work mode query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Work mode query did not work for some reason', error);
        });
      }

    }

    render() {

        let destinationMatches = false
        let destinationString = '/app'
        let activeOrg = 'lausd'
        if (this.state.activeOrg) {
          activeOrg = this.state.activeOrg
        }

        if (this.state.roleName.toLowerCase() === 'student') {
          destinationMatches = true
          destinationString = '/app'
        } else if (this.state.roleName.toLowerCase() === 'teacher') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        } else if (this.state.roleName.toLowerCase() === 'parent') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        } else if (this.state.roleName.toLowerCase() === 'system admin' || this.state.roleName.toLowerCase() === 'system administrator') {
          // destinationMatches = true
          // destinationString = '/app'
          destinationMatches = true
          destinationString = '/organizations/' + activeOrg + '/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        } else if (this.state.roleName.toLowerCase() === 'principal') {
          destinationMatches = true
          destinationString = '/organizations/' + activeOrg + '/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        } else if (this.state.roleName.toLowerCase() === 'school support') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        } else if (this.state.roleName.toLowerCase() === 'help desk') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
        } else if (this.state.roleName.toLowerCase() === 'staff (non-classroom)') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
        } else if (this.state.roleName.toLowerCase() === 'staff (classroom)') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
        } else if (this.state.roleName.toLowerCase() === 'help desk 2') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
        } else if (this.state.roleName.toLowerCase() === 'academy lead') {
          destinationMatches = true
          destinationString = '/advisor/dashboard'
          localStorage.setItem('isAdvisor', 'true')
        }
        
        console.log('show me: ', activeOrg, destinationString)

        return (
            <div>
              <div>
                {this.state.retrievedEmail ? (
                  <div>
                    {destinationMatches ? (
                      <div>
                        {this.props.navigate(destinationString)}
                      </div>
                    ) : (
                      <div>
                        <p>Your role is currently not supported. We found you have the following role:</p>
                        <div className="spacer" /><div className="half-spacer" />
                        <p>{this.state.roleName}</p>
                        <div className="spacer" /><div className="half-spacer" />
                        <p>Please email help@guidedcompass.com for technical support.</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="top-padding-40 light-background full-view-space">

                    {(this.state.collectSchoolInfo) ? (
                      <div className="card medium-shadow center-horizontally max-width-1400 width-70-percent">

                        <div>
                          <div className="relative-column-60">
                            <p className="heading-text-2">Hi {this.state.firstName},</p>

                            <div className="row-10">
                              <p>To better serve you, please add some basic information to log in:</p>
                            </div>

                            <div className="row-10">
                              <label className="profile-label">School District Name<label className="error-color bold-text">*</label></label>
                              <input className="text-field" type="text" placeholder="School District Name..." name="schoolDistrict" value={this.state.schoolDistrict} onChange={this.formChangeHandler} />

                              <div className="clear" />
                              <div className="spacer"/>

                              {(this.state.districtsAreLoading) ? (
                                <div className="flex-container flex-center full-space">
                                  <div>
                                    <div className="super-spacer" />

                                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                    <p className="center-text cta-color bold-text">Searching...</p>

                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {(this.state.districtOptions && this.state.districtOptions.length > 0) && (
                                    <div className="full-width">
                                      <div className="spacer"/>
                                      {this.state.districtOptions.map((value, optionIndex) =>
                                        <div key={value._id} className="bottom-padding">
                                          {(value && value !== '') && (
                                            <div className="left-text bottom-margin-5 full-width standard-color">
                                              <button className="background-button" onClick={() => this.optionClicked(optionIndex, 'district')}>
                                                <div className="full-width">
                                                  <div className="float-left right-padding">
                                                    <img src={courseIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                                  </div>
                                                  <div className="float-left">
                                                    <p className="cta-color">{value}</p>
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="row-10">
                              <label className="profile-label">School Name<label className="error-color bold-text">*</label></label>
                              <input className="text-field" type="text" placeholder="School Name..." name="school" value={this.state.school} onChange={this.formChangeHandler} />

                              <div className="clear" />
                              <div className="spacer"/>

                              {(this.state.schoolsAreLoading) ? (
                                <div className="flex-container flex-center full-space">
                                  <div>
                                    <div className="super-spacer" />

                                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                    <p className="center-text cta-color bold-text">Searching...</p>

                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {(this.state.schoolOptions && this.state.schoolOptions.length > 0) && (
                                    <div className="full-width">
                                      <div className="spacer"/>
                                      {this.state.schoolOptions.map((value, optionIndex) =>
                                        <div key={value._id} className="bottom-padding">
                                          {(value && value !== '') && (
                                            <div className="left-text bottom-margin-5 full-width standard-color">
                                              <button className="background-button" onClick={() => this.optionClicked(optionIndex, 'school')}>
                                                <div className="full-width">
                                                  <div className="float-left right-padding">
                                                    <img src={courseIconDark} alt="Compass employer icon icon" className="image-auto-22" />
                                                  </div>
                                                  <div className="float-left">
                                                    <p className="cta-color">{value}</p>
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {/*
                            {(this.state.emailId && this.state.emailId.includes('@placeholder')) && (
                              <div className="row-10">
                                <label className="profile-label">Email (Optional)</label>
                                <input className="text-field" type="text" placeholder="E.g. jondoe@gmail.com" name="emailId" value={this.state.emailId} onChange={this.formChangeHandler} />
                              </div>
                            )}*/}

                            {(this.state.roleName !== 'Student') && (
                              <div className="row-10">
                                <label className="profile-label">Define Success with Guided Compass (Optional)</label>
                                <textarea className="text-field" type="text" placeholder="How would you define success using Guided Compass? What do you want out of it? What should students get out of it?" name="successDefined" value={this.state.successDefined} onChange={this.formChangeHandler} />
                              </div>
                            )}

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                            <div className="row-10">
                              <button className="btn btn-primary" onClick={() => this.saveProfile()}>Save & Login</button>
                            </div>
                          </div>
                          <div className="relative-column-40 horizontal-padding-6">
                            {(this.state.roleName !== 'Student') && (
                              <div>
                                <div className="spacer" />
                                <div className="super-spacer" />
                                <div className="super-spacer" />
                              </div>
                            )}
                            <img src={gcFrontImage} alt="Compass employer icon icon" className="image-full-fit" />
                          </div>
                          <div className="clear" />
                        </div>

                      </div>
                    ) : (
                      <div className="standard-container-2">
                        <p>The page is loading. If the page fails to load after a few seconds, please email help@guidedcompass.com for technical support.</p>
                      </div>
                    )}

                    <div className="full-width pin-bottom bottom-padding-20 position-fixed">
                      <img src={fullGuidedCompassLogo} alt="Compass logo" className="image-full-30-fit center-horizontally"/>
                    </div>
                  </div>
                )}

              </div>
            </div>
        )
    }
}

export default withRouter(RemoteAuthConfig);
