import React, {Component} from 'react';
import Axios from 'axios';
import AppNavigation from './AppNavigation';
import AppFooter from './AppFooter';
import SubEmployerDetails from './Subcomponents/EmployerDetails';
import SubEditEmployer from './Subcomponents/EditEmployer';
import withRouter from './Functions/WithRouter';

class AdvEmployerDetails extends Component {
    constructor(props) {
      super(props)

      this.state = {
      }

      this.commandClicked = this.commandClicked.bind(this)
    }

    componentDidMount() {

      let employerSelected = null
      let postNew = true
      if (this.props.params && this.props.params.employerSelected !== 'add') {
        employerSelected = this.props.params.employerSelected
        postNew = false
      }

      let email = localStorage.getItem('email');
      let roleName = localStorage.getItem('roleName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      const orgLogo = localStorage.getItem('orgLogo');

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName,
        orgLogo, postNew
      })

      if (employerSelected) {
        let accountQuery = { _id: employerSelected }
        Axios.get('/api/account', { params: accountQuery })
        .then((response) => {
          console.log('Account info query attempted within employer details', response.data);

          if (response.data.success) {
            console.log('account info query worked in sub settings')

            const selectedEmployer = response.data.accountInfo
            this.setState({ selectedEmployer })
          }
        }).catch((error) => {
          console.log('Account info query did not work for some reason', error);
        });
      }
    }

    commandClicked(passedCommand) {
      console.log('commandClicked called')

      this.setState({ passedCommand })

    }

    render() {

        return (
          <div>
            <AppNavigation username={this.state.username} fromAdvisor={true} activeOrg={this.state.activeOrg} orgFocus={this.state.orgFocus} roleName={this.state.roleName} history={this.props.navigate} />

            {(this.state.selectedEmployer) ? (
              <div>
                {(this.state.selectedEmployer.sharePartners && this.state.selectedEmployer.sharePartners.includes(this.state.activeOrg) && this.state.roleName !== 'Mentor') ? (
                  <SubEditEmployer history={this.props.navigate} org={this.state.activeOrg} selectedEmployer={this.state.selectedEmployer} />
                ) : (
                  <SubEmployerDetails history={this.props.navigate} employerId={this.state.selectedEmployer._id} commandClicked={this.commandClicked} />
                )}
              </div>
            ) : (
              <div>
                {(this.state.postNew) && (
                  <SubEditEmployer history={this.props.navigate} org={this.state.activeOrg} selectedEmployer={this.state.selectedEmployer} />
                )}
              </div>
            )}

            {(this.state.activeOrg) && (
              <div>
                {AppFooter(this.props.navigate,this.state.activeOrg,this.state.orgLogo,this.state.passedCommand)}
              </div>
            )}
          </div>
        )
    }
}

export default withRouter(AdvEmployerDetails);
