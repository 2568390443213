import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import withRouter from '../Functions/WithRouter';
import {subFavoriteItem} from '../Functions/FavoriteItem';

const favoritesIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-grey.png';
const favoritesIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-blue.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const careerIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-blue.png';
const employerIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const courseIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-blue.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class Matches extends Component {
    constructor(props) {
        super(props)

        this.state = {
          favorites: [],
          newFavorites: [],
          showSubFunction: true,
          matchingCriteria: [
            { name: 'Work Function',  description: 'Scores careers / opportunities the highest that match your preferred work functions indicated in your work preferences assessment', value: 20 },
            { name: 'Industry',  description: 'Scores careers / opportunities the highest that match your preferred industries indicated in your work preferences assessment', value: 10 },
            { name: 'Location',  description: 'Scores careers / opportunities the highest that match your preferred location preferences indicated in your work preferences assessment', value: 0 },
            { name: 'Pay',  description: 'Scores high paying careers / opportunities highest', value: 0 },
            { name: 'Stability',  description: 'Scores careers / opportunities the highest with little variability in pay and job growth', value: 0 },
            { name: 'Interests',  description: 'Scores careers / opportunities the highest that match your interest assessment', value: 15 },
            { name: 'Personality',  description: 'Scores careers / opportunities the highest that match your personality assessment', value: 15 },
            { name: 'Values',  description: 'Scores careers / opportunities that the highest match your values assessment', value: 10 },
            { name: 'Skills',  description: 'Scores careers / opportunities that the highest match your skills assessment', value: 20 },
            { name: 'Education',  description: 'Scores careers / opportunities the highest that match your target education level indicated in work preferences', value: 0 },
            { name: 'Projects',  description: 'Scores careers / opportunities the highest that will likely value your project work the highest', value: 5 },
            { name: 'Experience',  description: 'Scores careers / opportunities the highest that will likely value your experience (that you like)', value: 5 },
          ]
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.renderMatchesView = this.renderMatchesView.bind(this)
        this.renderMatches = this.renderMatches.bind(this)
        this.calculateMatches = this.calculateMatches.bind(this)
        this.favoriteItem = this.favoriteItem.bind(this)
    }

    componentDidMount(){
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.passedGoal !== prevProps.passedGoal) {
        this.retrieveData()
      } else if (this.props.member !== prevProps.member) {
        this.retrieveData()
      }
    }

    retrieveData() {

      let emailId = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');

      if (emailId) {
        this.setState({ emailId, cuFirstName, cuLastName, username, activeOrg, orgFocus });

        let profileEmail = emailId
        if (this.props.member) {
          profileEmail = this.props.member.email
        }

        Axios.get('/api/users/profile/details', { params: { email: profileEmail } })
        .then((response) => {
         console.log('User details query 1 attempted', response.data);

         if (response.data.success) {
            console.log('successfully retrieved user details')

            let favorites = []
            if (response.data.user.favoritesArray) {
              favorites = response.data.user.favoritesArray
            }

            let newFavorites = []
            if (response.data.user.newFavorites) {
              newFavorites = response.data.user.newFavorites
            }

            this.setState({ favorites, newFavorites })

            let profile = response.data.user
            let matchingCriteria = this.state.matchingCriteria
            let useCases = this.state.useCases
            if (response.data.user.matchingPreferences && response.data.user.matchingPreferences.length > 0) {
              matchingCriteria = response.data.user.matchingPreferences
            }
            if (response.data.user.matchingUseCases && response.data.user.matchingUseCases.length > 0) {
              useCases = response.data.user.matchingUseCases
            }

            const careerPathMatches = response.data.user.topCareerMatches
            const employerMatches = response.data.user.topEmployerMatches
            const courseMatches = response.data.user.topCourseMatches
            const eventMatches = response.data.user.topEventMatches
            const projectMatches = response.data.user.topProjectMatches
            const workMatches = response.data.user.topWorkMatches
            const peerMatches = response.data.user.topPeerMatches
            const mentorMatches = response.data.user.topMentorMatches
            const groupMatches = response.data.user.topGroupMatches

            this.setState({ profile, matchingCriteria, useCases,
              careerPathMatches, employerMatches, courseMatches, eventMatches,
              projectMatches, workMatches, peerMatches, mentorMatches, groupMatches
            })

            const self = this
            function pullMoreData(type,profile) {
              console.log('pullMoreData called')

              if (type === 'projects') {
                Axios.get('/api/projects', { params: { emailId: profileEmail, includeCollaborations: true } })
                .then((response2) => {
                  console.log('Projects query attempted', response2.data);

                    if (response2.data.success && response2.data.projects) {
                      console.log('successfully retrieved projects')

                      profile['projects'] = response2.data.projects
                      pullMoreData('experience',profile)

                    } else {
                      console.log('no project data found', response2.data.message)
                      pullMoreData('experience',profile)
                    }

                }).catch((error) => {
                    console.log('Project query did not work', error);
                });
              }

              if (type === 'experience') {
                Axios.get('/api/experience', { params: { emailId: profileEmail } })
                .then((response2) => {
                  console.log('Experience query attempted', response2.data);

                    if (response2.data.success && response2.data.experience) {
                      console.log('successfully retrieved experience')

                      profile['experience'] = response2.data.experience
                      pullMoreData('endorsements',profile)

                    } else {
                      console.log('no experience data found', response2.data.message)
                      pullMoreData('endorsements',profile)
                    }

                }).catch((error) => {
                    console.log('Experience query did not work', error);
                });
              }

              if (type === 'endorsements') {
                Axios.get('/api/story', { params: { emailId: profileEmail } })
                .then((response2) => {
                    console.log('Endorsement query worked', response2.data);

                    if (response2.data.success) {

                      if (response2.data.stories) {
                        profile['endorsements'] = response2.data.stories
                        pullMoreData('goals',profile)
                      }
                    } else {
                      console.log('no endorsements found: ', response2.data.message)
                      pullMoreData('goals',profile)
                    }

                }).catch((error) => {
                    console.log('Story query did not work', error);
                });
              }

              if (type === 'goals') {
                Axios.get('/api/logs/goals', { params: { emailId: profileEmail } })
                .then((response) => {
                   console.log('log query attempted')
                    if (response.data.success) {
                      console.log('Logs received query worked', response.data);

                      profile['logs'] = response.data.goals
                      self.setState({ profile })

                    } else {
                      console.log('no log data found', response.data.message)
                      self.setState({ profile })
                    }

                }).catch((error) => {
                    console.log('Log query did not work', error);
                });
              }
            }

            Axios.get('/api/assessment/results', { params: { emailId: profileEmail } })
             .then((response2) => {
               console.log('query for assessment results worked');

               if (response2.data.success) {

                 console.log('actual assessment results', response2.data)

                 profile['workPreferences'] = response2.data.results.workPreferenceAnswers
                 profile['interests'] = response2.data.results.interestScores
                 profile['personality'] = response2.data.results.personalityScores
                 profile['skills'] = response2.data.results.newSkillAnswers
                 profile['gravitateValues'] = response2.data.results.topGravitateValues
                 profile['employerValues'] = response2.data.results.topEmployerValues
                 pullMoreData('projects',profile)

               } else {
                 console.log('no assessment results', response2.data)
                 pullMoreData('projects',profile)
               }

           }).catch((error) => {
               console.log('query for assessment results did not work', error);
           })

         } else {
          console.log('no user details data found', response.data.message)
         }

       }).catch((error) => {
          console.log('User details query did not work', error);
       });

       Axios.get('/api/workoptions')
       .then((response) => {
         console.log('Work options query tried', response.data);

         if (response.data.success) {
           console.log('Work options query succeeded')

           const hourOptions = response.data.workOptions[0].hourOptions
           const hourlyPayOptions = response.data.workOptions[0].hourlyPayOptions
           const annualPayOptions = response.data.workOptions[0].annualPayOptions
           const employeeCountOptions = response.data.workOptions[0].employeeCountOptions

           this.setState({ hourOptions, hourlyPayOptions, annualPayOptions, employeeCountOptions });
         }
       });
      }
    }

    calculateMatches(type) {
      console.log('calculateMatches called')

      this.setState({ successMessage: null, errorMessage: null })
      // console.log('got profile? ', this.state.profile)
      if (!this.state.profile) {
        this.setState({ errorMessage: 'Please take career assessments before receiving personalized matches' })
      } else {

        const profile = this.state.profile
        const matchingCriteria = this.state.matchingCriteria
        const useCases = null
        const hourOptions = this.state.hourOptions
        const hourlyPayOptions = this.state.hourlyPayOptions
        const annualPayOptions = this.state.annualPayOptions
        const employeeCountOptions = this.state.employeeCountOptions

        const orgCode = this.state.activeOrg
        const placementPartners = this.state.placementPartners
        const pathway = null
        const resLimit = 500

        const self = this

        if (type === 'careerPaths') {
          this.setState({ careerPathsAnimating: true, careerPathErrorMessage: null })

          const excludeMissingOutlookData = true
          const excludeMissingJobZone = true

          // query postings on back-end
          Axios.put('/api/careers/matches', { profile, matchingCriteria, useCases, excludeMissingOutlookData, excludeMissingJobZone, hourOptions })
          .then((response) => {
            console.log('Career matches attempted', response.data);

              if (response.data.success) {
                console.log('career match query worked')

                let matchScores = response.data.matchScores
                let careerPathMatches = []
                if (response.data.careers && response.data.careers.length > 0) {
                  for (let i = 1; i <= 3; i++) {
                    if (response.data.careers[i - 1] && matchScores && matchScores.length > 0) {
                      let career = response.data.careers[i - 1]
                      career['matchScore'] = matchScores[i - 1]
                      careerPathMatches.push(career)
                    }
                  }
                }

                this.setState({ careerPathsAnimating: false, careerPathMatches })

              } else {
                console.log('Career match did not work', response.data.message)
                this.setState({ careerPathsAnimating: false, careerPathErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Career match did not work for some reason', error);
              this.setState({ careerPathsAnimating: false, careerPathErrorMessage: { message: 'there was an error' }})
          });
        } else if (type === 'employers') {
          this.setState({ employersAnimating: true, employerErrorMessage: null })

          // query postings on back-end
          Axios.put('/api/employers/matches', { profile, matchingCriteria, useCases, resLimit: 1000, hourOptions, orgCode })
          .then((response) => {
            console.log('Employer matches attempted', response.data);

              if (response.data.success) {
                console.log('employer match query worked')

                let matchScores = response.data.matchScores
                let employerMatches = []
                if (response.data.employers && response.data.employers.length > 0) {
                  for (let i = 1; i <= 3; i++) {
                    if (response.data.employers[i - 1] && matchScores && matchScores.length > 0) {
                      let employer = response.data.employers[i - 1]
                      employer['originalId'] = response.data.employers[i - 1].originalId
                      employer['matchScore'] = matchScores[i - 1]
                      employerMatches.push(employer)
                    }
                  }
                  this.setState({ employersAnimating: false, employerMatches })
                } else {
                  this.setState({ employersAnimating: false, employerMatches, errorEmployerMessage: 'Error: we could not find employer matches for you.' })
                }
              } else {
                console.log('employer did not work', response.data.message)
                this.setState({ employersAnimating: false, employerErrorMessage: { message: 'there was an error: ' + response.data.message } })
              }

          }).catch((error) => {
              console.log('Employer match did not work for some reason', error);
              this.setState({ employersAnimating: false, employerErrorMessage: { message: 'there was an unknown error'} })
          });
        } else if (type === 'courses') {
          this.setState({ coursesAnimating: true, courseErrorMessage: null })

          const learningObjectives = [{ name: 'Marketing'}]
          const orgCode = this.state.activeOrg

          // query postings on back-end
          Axios.put('/api/courses/matches', { profile, matchingCriteria, useCases, learningObjectives, orgCode })
          .then((response) => {
            console.log('Course matches attempted', response.data);

              if (response.data.success) {
                console.log('employer match query worked')

                if (response.data.courses && response.data.courses.length > 0) {

                  let courseMatches = []
                  // let matchScores = response.data.matchScores
                  // for (let i = 1; i <= 3; i++) {
                  //   if (response.data.courses[i - 1] && matchScores && matchScores.length > 0) {
                  //     let course = response.data.courses[i - 1]
                  //     course['matchScore'] = matchScores[i - 1]
                  //     courseMatches.push(course)
                  //   }
                  // }

                  for (let i = 1; i <= 3; i++) {
                    if (response.data.courses[i - 1]) {
                      courseMatches.push(response.data.courses[i - 1])
                    }
                  }

                  this.setState({ coursesAnimating: false, courseMatches })
                } else {
                  this.setState({ coursesAnimating: false, courseErrorMessage: { message: 'Error: we could not find course matches for you.' }})
                }

              } else {
                console.log('course matches did not work', response.data.message)
                this.setState({ coursesAnimating: false, courseErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Employer match did not work for some reason', error);
              this.setState({ careerPathsAnimating: false, courseErrorMessage: { message: 'there was an unknown error'} })
          });
        } else if (type === 'events') {
          this.setState({ eventsAnimating: true, eventErrorMessage: null })

          const postType = null
          const postTypes = ['Event']

          // query postings on back-end
          Axios.put('/api/opportunities/matches', {
            profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
            annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions })
          .then((response) => {
            console.log('Opportunity matches attempted', response.data);

              if (response.data.success) {
                console.log('opportunity match query worked')

                // let matchScores = response.data.matchScores
                // let eventMatches = []
                // if (response.data.postings && response.data.postings.length > 0) {
                //   for (let i = 1; i <= 3; i++) {
                //     if (response.data.postings[i - 1] && matchScores && matchScores.length > 0) {
                //       let posting = response.data.postings[i - 1]
                //       posting['matchScore'] = matchScores[i - 1]
                //       eventMatches.push(posting)
                //     }
                //   }
                // }

                const eventMatches = response.data.topEventMatches

                this.setState({ eventsAnimating: false, eventMatches })

              } else {
                console.log('Opportunity match did not work', response.data.message)
                this.setState({ eventsAnimating: false, eventErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Opportunity match did not work for some reason', error);
              this.setState({ eventsAnimating: false, eventErrorMessage: { message: 'there was an error'} })
          });

        } else if (type === 'projects') {
          this.setState({ projectsAnimating: true, projectErrorMessage: null })

          const postType = null
          const postTypes = ['Assignment','Problem','Challenge']

          // query postings on back-end
          Axios.put('/api/opportunities/matches', {
            profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
            annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions })
          .then((response) => {
            console.log('Project matches attempted', response.data);

              if (response.data.success) {
                console.log('project match query worked')

                // let matchScores = response.data.matchScores
                // let projectMatches = []
                // if (response.data.postings && response.data.postings.length > 0) {
                //   for (let i = 1; i <= 3; i++) {
                //     if (response.data.postings[i - 1] && matchScores && matchScores.length > 0) {
                //       let posting = response.data.postings[i - 1]
                //       posting['matchScore'] = matchScores[i - 1]
                //       projectMatches.push(posting)
                //     }
                //   }
                // }

                const projectMatches = response.data.topProjectMatches
                this.setState({ projectsAnimating: false, projectMatches })

              } else {
                console.log('Project match did not work', response.data.message)
                this.setState({ projectsAnimating: false, projectErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Project match did not work for some reason', error);
              this.setState({ projectsAnimating: false, projectErrorMessage: { message: 'there was an error' }})
          });
        } else if (type === 'work') {
          this.setState({ workAnimating: true, workErrorMessage: null })

          const postType = null
          const postTypes = ['Individual','Internship','Work']

          // query postings on back-end
          Axios.put('/api/opportunities/matches', {
            profile, orgCode, placementPartners, postType, postTypes, pathway, matchingCriteria, useCases,
            annualPayOptions, hourlyPayOptions, employeeCountOptions, hourOptions })
          .then((response) => {
            console.log('Opportunity matches attempted', response.data);

              if (response.data.success) {
                console.log('opportunity match query worked')

                // let matchScores = response.data.matchScores
                // let workMatches = []
                // if (response.data.postings && response.data.postings.length > 0) {
                //   for (let i = 1; i <= 3; i++) {
                //     if (response.data.postings[i - 1] && matchScores[i - 1]) {
                //       let posting = response.data.postings[i - 1]
                //       posting['originalId'] = response.data.postings[i - 1].originalId
                //       posting['matchScore'] = matchScores[i - 1]
                //       workMatches.push(posting)
                //     }
                //   }
                // }

                const workMatches = response.data.topWorkMatches
                this.setState({ workAnimating: false, workMatches })

              } else {
                console.log('Opportunity match did not work', response.data.message)
                this.setState({ workAnimating: false, workErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Opportunity match did not work for some reason', error);
              this.setState({ workAnimating: false, workErrorMessage: { message: 'there was an error' }})
          });
        } else if (type === 'peers') {

          this.setState({ peersAnimating: true, peerErrorMessage: null })

          let roleNames = ['Student','Career-Seeker']
          const onlyPics = true

          console.log('show our profile: ', this.state.profile)

          // query postings on back-end
          Axios.put('/api/members/matches', { profile, matchingCriteria, similarityCriteria: null, roleNames, orgCode, onlyPics, specificCriteria: null, resLimit })
          .then((response) => {
            console.log('Peer matches attempted', response.data);

              if (response.data.success) {
                console.log('peer match query worked')


                let peerMatches = response.data.topPeerMatches
                // let matchScores = response.data.matchScores
                self.setState({ peersAnimating: false, peerMatches })

              } else {
                console.log('Peer match did not work', response.data.message)
                self.setState({ peersAnimating: false, peerErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Peer match did not work for some reason', error);
              self.setState({ peersAnimating: false, peerErrorMessage: { message: 'there was an error' }})
          });
        } else if (type === 'mentors') {

          this.setState({ mentorsAnimating: true, mentorErrorMessage: null })

          let roleNames = ['Mentor']

          const onlyPics = true

          console.log('show our profile: ', this.state.profile)

          // query postings on back-end
          Axios.put('/api/members/matches', { profile, matchingCriteria, similarityCriteria: null, roleNames, orgCode, onlyPics, specificCriteria: null, resLimit })
          .then((response) => {
            console.log('Mentor matches attempted', response.data);

              if (response.data.success) {
                console.log('mentor match query worked')


                let mentorMatches = response.data.topMentorMatches
                // let matchScores = response.data.matchScores
                self.setState({ mentorsAnimating: false, mentorMatches })

              } else {
                console.log('Mentor match did not work', response.data.message)
                self.setState({ mentorsAnimating: false, mentorErrorMessage: { message: 'there was an error: ' + response.data.message }})
              }

          }).catch((error) => {
              console.log('Member match did not work for some reason', error);
              self.setState({ mentorsAnimating: false, mentorErrorMessage: { message: 'there was an error'}})
          });
        } else if (type === 'groups') {

          this.setState({ groupsAnimating: true, groupErrorMessage: null })

          let roleNames = ['Student','Career-Seeker']

          Axios.put('/api/groups/matches', { profile, matchingCriteria, roleNames, orgCode, resLimit })
          .then((response) => {
            console.log('Group matches attempted', response.data);

              if (response.data.success) {
                console.log('group match query worked')

                let groupMatches = response.data.topGroupMatches
                // let matchScores = response.data.matchScores
                self.setState({ groupsAnimating: false, groupMatches })

              } else {
                console.log('Group match did not work', response.data.message)
                self.setState({ groupsAnimating: false, groupErrorMessage: { message: 'there was an error: ' + response.data.message } })
              }

          }).catch((error) => {
              console.log('Group match did not work for some reason', error);
              self.setState({ groupsAnimating: false, groupErrorMessage: { message: 'there was an unknown error' }})
          });
        }
      }
    }

    async favoriteItem(item, type) {
      console.log('favoriteItem called', item, type)

      if (this.state.showSubFunction) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        if (type === 'careerPaths') {
          type = 'career'
        } else if (type === 'employers') {
          type = 'employer'
        } else if (type === 'courses') {
          type = 'course'
        } else if (type === 'events') {
          type = 'event'
        } else if (type === 'projects') {
          type = 'project'
        } else if (type === 'work') {
          type = 'work'
        } else if (type === 'peers') {
          type = 'person'
        } else if (type === 'mentors') {
          type = 'person'
        } else if (type === 'groups') {
          type = 'group'
        }

        const returnedObject = await subFavoriteItem(item, this.state.favorites, this.state.emailId, this.state.newFavorites,type)

        if (returnedObject.success) {
          this.setState({ successMessage: returnedObject.message, favorites: returnedObject.favoritesArray, newFavorites: returnedObject.newFavorites, isSaving: false })
        } else {
          this.setState({ errorMessage: returnedObject.message, isSaving: false })
        }

      } else {

        let favoritesArray = this.state.favorites

        if (favoritesArray.includes(item._id)) {

          let index = favoritesArray.indexOf(item._id)
          if (index > -1) {
            favoritesArray.splice(index, 1);
          }

          // console.log('favorites', favoritesArray)
          this.setState({ favorites: favoritesArray, errorMessage: null, successMessage: null })

          Axios.post('/api/favorites/save', {
            favoritesArray, emailId: this.state.emailId
          })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({ successMessage: 'Favorite saved!'
              })
            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites' })
          });

        } else {

          console.log('adding item: ', favoritesArray, item._id)
          favoritesArray.push(item._id)

          this.setState({ favorites: favoritesArray, errorMessage: null, successMessage: null })

          Axios.post('/api/favorites/save', { favoritesArray, emailId: this.state.emailId })
          .then((response) => {
            console.log('attempting to save favorites')
            if (response.data.success) {
              console.log('saved successfully', response.data)
              //clear values
              this.setState({ successMessage: 'Favorite saved!' })
            } else {
              console.log('did not save successfully')
              this.setState({ errorMessage: 'error:' + response.data.message })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              this.setState({ errorMessage: 'there was an error saving favorites' })
          });
        }
      }
    }

    renderMatchesView() {
      console.log('renderMatchesView called')

      return (
        <div key="matchesView">
          <div className="spacer" /><div className="spacer" />
          <div className="row-10">
            <h2 className="full-width center-text">{(this.props.member) ? "" : "My "}Top Matches</h2>
            <p className="full-width center-text bottom-padding">Matches are calculated based on {(this.props.member) ? "" : "your "}<label className="bold-text">career assessments, endorsements, goals, favorites, projects, work experience, education, and completed items.</label></p>
          </div>

          <div className="spacer" /><div className="spacer" />

          <div className="row-20">
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Career Paths</p>
              {this.renderMatches('careerPaths')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Employers</p>
              {this.renderMatches('employers')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Courses</p>
              {this.renderMatches('courses')}
            </div>
            <div className="clear" />
          </div>

          <div className="row-20">
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Events</p>
              {this.renderMatches('events')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Projects</p>
              {this.renderMatches('projects')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Work</p>
              {this.renderMatches('work')}
            </div>
            <div className="clear" />
          </div>

          <div className="row-20">
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Career-Seekers</p>
              {this.renderMatches('peers')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Mentors</p>
              {this.renderMatches('mentors')}
            </div>
            <div className="relative-column-33">
              <p className="full-width center-text heading-text-4">Groups</p>
              {this.renderMatches('groups')}
            </div>
            <div className="clear" />
          </div>
        </div>
      )
    }

    renderMatches(type) {
      console.log('renderMatches called', type)

      let animating = false
      if (type === 'careerPaths') {
        animating = this.state.careerPathsAnimating
      } else if (type === 'employers') {
        animating = this.state.employersAnimating
      } else if (type === 'courses') {
        animating = this.state.coursesAnimating
      } else if (type === 'events') {
        animating = this.state.eventsAnimating
      } else if (type === 'projects') {
        animating = this.state.projectsAnimating
      } else if (type === 'work') {
        animating = this.state.workAnimating
      } else if (type === 'peers') {
        animating = this.state.peersAnimating
      } else if (type === 'mentors') {
        animating = this.state.mentorsAnimating
      } else if (type === 'groups') {
        animating = this.state.groupsAnimating
      }

      if (animating) {
        return (
          <div key="animating" className="flex-container flex-center full-space">
            <div className="top-padding">
              <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
              <div className="spacer" /><div className="spacer" /><div className="spacer" />
              <p className="center-text cta-color bold-text">Pulling matches...</p>

            </div>
          </div>
        )
      } else {
        let matches = []
        let errorMessage = { message: 'No matches saved', action: 'Calculate' }
        let itemIcon = careerIcon
        if (type === 'careerPaths') {
          matches = this.state.careerPathMatches
          if (this.state.careerPathErrorMessage) {
            errorMessage = this.state.careerPathErrorMessage
          }
        } else if (type === 'employers') {
          matches = this.state.employerMatches
          if (this.state.employerErrorMessage) {
            errorMessage = this.state.employerErrorMessage
          }
          itemIcon = employerIcon
        } else if (type === 'courses') {
          // console.log('got courseMatches? ', this.state.courseMatches)
          matches = this.state.courseMatches
          if (this.state.courseErrorMessage) {
            errorMessage = this.state.courseErrorMessage
          }
          itemIcon = courseIconBlue
        } else if (type === 'events') {
          matches = this.state.eventMatches
          if (this.state.eventErrorMessage) {
            errorMessage = this.state.eventErrorMessage
          }
        } else if (type === 'projects') {
          matches = this.state.projectMatches
          if (this.state.projectErrorMessage) {
            errorMessage = this.state.projectErrorMessage
          }
        } else if (type === 'work') {
          matches = this.state.workMatches
          if (this.state.workErrorMessage) {
            errorMessage = this.state.workErrorMessage
          }
        } else if (type === 'peers') {
          matches = this.state.peerMatches
          if (this.state.peerErrorMessage) {
            errorMessage = this.state.peerErrorMessage
          }
        } else if (type === 'mentors') {
          matches = this.state.mentorMatches
          if (this.state.mentorErrorMessage) {
            errorMessage = this.state.mentorErrorMessage
          }
        } else if (type === 'groups') {
          matches = this.state.groupMatches
          if (this.state.groupErrorMessage) {
            errorMessage = this.state.groupErrorMessage
          }
        }

        if (matches && matches.length > 0 && errorMessage.action === 'Calculate') {
          errorMessage['action'] = "Refresh"
        }

        if (matches && matches.length > 0) {

          let rows = []

          for (let i = 1; i <= matches.length; i++) {
            const value = matches[i - 1]
            const index = i - 1

            let pathname = ''
            let passedState = {}
            let title = ''
            let subtitle = ''
            if (type === 'careerPaths') {
              pathname = '/app/careers/' + value.name
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/careers/' + value.name
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/careers/' + value.name
              }
              title = value.name
              subtitle = value.jobFunction
              if (value.jobFunction && value.industry) {
                subtitle = subtitle + ' | ' + value.industry
              } else if (!value.jobFunction && value.industry) {
                subtitle = value.industry
              }

              if (value.jobFamily) {
                subtitle = subtitle + ' | ' + value.jobFamily
              }
              passedState = { careerSelected: value }
            } else if (type === 'employers') {
              pathname = '/app/employers/' + value.originalId
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/employer-details/' + value.originalId
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/employers/' + value.originalId
              }
              title = value.employerName
              subtitle = value.employerIndustry
            } else if (type === 'courses') {
              let id = value._id
              if (value.originalId) {
                id = value.originalId
              }
              pathname = '/app/courses/' + id
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/courses/' + id
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/courses/' + id
              }
              title = value.title
              if (value.name) {
                title = value.name
              }
              // subtitle = value.price + " | " + value.headline
              subtitle = value.price
              if (value.price && !value.price.includes("$")) {
                subtitle = '$' + value.price
              } else if (!value.price && value.industry) {
                subtitle = value.industry
              }
            } else if (type === 'events') {
              pathname = '/app/opportunities/' + value.originalId
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/postings/' + value.originalId
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/opportunities/' + value.originalId
              }
              title = value.title
              if (value.employerName) {
                subtitle = value.eventType + ' @ ' +  value.employerName
              } else if (value.orgName) {
                subtitle = value.eventType + ' from ' +  value.orgName
              } else {
                subtitle = value.eventType
              }
              // passedState = { selectedOpportunity: value, source: 'Student' }
            } else if (type === 'projects') {
              pathname = '/app/opportunities/' + value.originalId
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/postings/' + value.originalId
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/opportunities/' + value.originalId
              }
              title = value.name
              if (value.employerName) {
                subtitle = value.postType + ' from ' +  value.employerName
              } else if (value.orgName) {
                subtitle = value.postType + ' from ' +  value.orgName
              } else {
                subtitle = value.postType
              }

              if (value.duration) {
                subtitle = subtitle + ' | ' + value.duration + ' hours'
              }
            } else if (type === 'work') {
              pathname = '/app/opportunities/' + value.originalId

              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/postings/' + value.originalId
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/opportunities/' + value.originalId
              }

              title = value.title
              subtitle = value.employerName
              subtitle = subtitle + ' - ' + value.field
              if (value.payRange && (value.subPostType === 'Full-Time' || value.subPostType === 'Part-Time')) {
                subtitle = subtitle + ' ' + value.payRange
              }

              // passedState = { selectedOpportunity: value, source: 'Student' }
            } else if (type === 'peers') {
              pathname = '/app/profile/' + value.username
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/members/' + value.username
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/profile/' + value.username
              }
              title = value.firstName + ' ' + value.lastName
              if (value.school) {
                subtitle = value.school
                if (value.gradYear) {
                  subtitle = subtitle + " " + value.gradYear
                }
              } else {
                subtitle = value.employerName
              }

            } else if (type === 'mentors') {
              pathname = '/app/profile/' + value.username
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/members/' + value.username
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/profile/' + value.username
              }
              title = value.firstName + ' ' + value.lastName
              if (value.school) {
                subtitle = value.school
                if (value.gradYear) {
                  subtitle = subtitle + " " + value.gradYear
                }
              } else {
                subtitle = value.employerName
              }

            } else if (type === 'groups') {
              console.log('got the group: ', value)
              pathname = '/app/groups/' + value.originalId
              if (window.location.pathname.includes('/organizations/')) {
                pathname = '/organizations/' + this.state.activeOrg + '/groups/' + value.originalId
              } else if (window.location.pathname.includes('/advisor/')) {
                pathname = '/advisor/groups/' + value.originalId
              }
              // passedState = { selectedGroup: value }
              title = value.name
              subtitle = value.category
            }

            const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');

            rows.push(
              <div key={"matches" + type + index}>
                <div className="spacer" />
                <Link to={pathname} state={passedState} className="background-link">
                  <div className="fixed-column-70">
                    {(value.matchScore || value.matchScore === 0) ? (
                      <div className="right-padding">
                        <CircularProgressbar
                          value={value.matchScore}
                          text={`${value.matchScore}%`}
                          styles={{
                            path: { stroke: `${primaryColor}, ${value.matchScore / 100}` },
                            text: { fill: primaryColor, fontSize: '26px' },
                            trail: { stroke: 'transparent' }
                          }}
                        />
                      </div>
                    ) : (
                      <img src={itemIcon} alt="GC" className="image-auto-50 top-margin-5 center-item"/>
                    )}
                  </div>
                  <div className="calc-column-offset-140-static">
                    <p className="heading-text-5">{title}</p>
                    <p className="description-text-1 description-text-color">{subtitle}</p>
                  </div>
                </Link>

                {(window.location.pathname.includes('/app/') || window.location.pathname.includes('/advisor/')) ? (
                  <span className="fixed-column-40 top-margin-15">
                    <button className="btn background-button" onClick={() => this.favoriteItem(value, type) }>
                      <img src={(this.state.favorites.includes(value._id)) ? favoritesIconBlue : favoritesIconGrey} alt="Compass arrow indicator icon" className="image-auto-20"/>
                    </button>
                  </span>
                ) : (
                  <span className="fixed-column-40 height-30" />
                )}

                <div className="fixed-column-25 left-padding">
                  <span className="float-right">
                    <div className="spacer"/><div className="half-spacer"/>
                    <Link to={pathname} state={passedState} className="background-link">
                      <img src={arrowIndicatorIcon} alt="GC" className="image-auto-22"/>
                    </Link>
                  </span>
                </div>
                <div className="clear"/>

                {(this.state.sortCriteriaArray && this.state.sortCriteriaArray[index] && this.state.sortCriteriaArray[index].name) && (
                  <div className="left-padding-70">
                    <div className="half-spacer" />
                    <p className="description-text-2 error-color row-5">{this.state.sortCriteriaArray[index].name}: {this.standardizeValue('sort',index, this.state.sortCriteriaArray[index].criteria)}</p>
                  </div>
                )}
                {(this.state.filterCriteriaArray && this.state.filterCriteriaArray[index] && this.state.filterCriteriaArray[index].name) && (
                  <div className="left-padding-70">
                    <div className="half-spacer" />
                    <p className="description-text-2 error-color row-5">{this.state.filterCriteriaArray[index].name}: {this.state.filterCriteriaArray[index].criteria}</p>
                  </div>
                )}
                <div className="spacer" /><div className="spacer" />
                <hr className="clear-margin clear-padding" />
                <div className="clear"/>
                <div className="spacer" />
              </div>
            )
          }

          rows.push(
            <div key="calculateMatches" className="center-text row-10">
              <button className="btn btn-squarish" onClick={() => this.calculateMatches(type) }>{errorMessage.action}</button>
            </div>
          )

          return rows

        } else {
          return (
            <div key={"matches" + type}>
              {(errorMessage) && (
                <div>
                  <p className="top-padding-20 error-color center-text full-width">{errorMessage.message}</p>
                  {(errorMessage.action === 'Calculate') && (
                    <div className="center-text row-10">
                      <button className="btn btn-squarish" onClick={() => this.calculateMatches(type) }>{errorMessage.action}</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }
    }

    render() {

        return (
            <div>
              {this.renderMatchesView()}
            </div>

        )
    }
}

export default withRouter(Matches);
