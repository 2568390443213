import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Switch from 'react-switch';
import Modal from 'react-modal';
import DeactivatedModal from './Common/DeactivatedModal';
import {convertStringToDate} from './Functions/ConvertStringToDate';
import {convertDateToString} from './Functions/ConvertDateToString';
import {signIn} from '../components/Services/AuthRoutes';
import withRouter from './Functions/WithRouter';

const employersIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/employer-icon.png';
const logoImg = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/Compass-logo-words.png';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-white.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const addCircleOutlineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-circle-outline-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const addIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon-blue.png";
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EmpPost extends Component {
    constructor(props) {
        super(props)
        this.state = {

          currentPage: 1,
          createCustomBenchmark: true,
          hideProfileCard: true,

          showMentorships: false,
          accountCode: '',
          isSaving: false,
          wantsInterns: 'true',

          hostedEvents: [],
          problems: [],
          selectedJob: { postType: 'Work', subPostType: 'Individual', title: '', workFunction: ''},

          orgProgramName: '',
          introSummary: '',

          contactFirstName: '',
          contactLastName: '',
          employerName: '',
          contactTitle: '',

          employerURL: '',
          employerType: '',
          employeeCount: '',
          employerIndustry: '',
          employerDescription: '',
          industries: [],

          supplementalPayArray: [],
          benefits: [],

          contactEmail: '',
          contactPhone: '',
          password: '',

          projectedHires: '',
          hasFunds: '',
          hostUndocumented: '',
          postCount: '',
          postings: [],
          employers: [],

          postOptions: [],
          tracks: [],
          trackFunctions: [],

          includeHighSchool: true,
          includeAssociates: true,
          includeBachelors: true,

          typeOptions: ['','For-Profit','Non-Profit','Public'],
          industryOptions: [],
          countOptions: [],
          projectedInternOptions: [],
          postCountOptions: [],
          binaryOptions: ['','Yes','No'],
          maybeOptions: ['','Yes','Maybe','No'],
          functionOptions: [],
          hoursPerWeekOptions: [],
          officeTypeOptions: [],
          politicalAlignmentOptions: [],
          registrationOptions: [],
          locationOptions: [],
          applicationMethodOptions: [],
          eventTypeOptions: ["","Talk","Workshop","Field Trip","Job Shadow","Conference","Other"],
          projectPromptTypeOptions: ["","Assignment","Problem","Challenge"],
          durationOptions: [],
          knowledgeLevelOptions: [],
          atsOptions: [],
          timeframeOptions: [],
          referrerOptions: [],
          workTypeOptions: ['','Full-Time','Part-Time','Internship','Apprenticeship'],
          tagTypeOptions: ['','Hard Skill','Soft Skill','Knowledge'],
          payTypeOptions: ['','Hourly','Salary'],
          hourlyPayOptions: [],
          annualPayOptions: [],
          supplementalPayOptions: [],
          benefitOptions: [],
          questionTypeOptions: ['Short Answer','Long Answer','Multiple Choice','Checkbox','Ranking','Email','Birthdate'],

          infoCollected: [],

          successAnswerChoices: [],
          successSelected: [],
          successMetrics: [],
          hireTimeframes: [],
          volunteerAnswerChoices: [],
          volunteerSelected: [],
          volunteerPreferences: [],

          title: '',
          location: '',
          function: '',
          startDate: '',
          isPerpetual: false,
          endDate: '',
          hoursPerWeek: '',
          hireCount: '',
          description: '',
          employerContactFirstName: '',
          employerContactLastName: '',
          employerContactEmail: '',
          subjectLine: '',
          appRequirements: '',
          submissionDeadline: '',
          applicationMethod: '',
          jobLink: '',

          payType: 'Hourly',
          payRange: "Unpaid",

          officeType: '',
          politicalAlignment: '',
          stateRegistration: '',
          congressionalDistrict: '',

          sharePartners: [],

          formComplete: false,
          confirmationMessage: '',

          benchmarkOptions: [],

          animate: false,
          opacity: 0,
          isWaiting: false,

        }

        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.renderPage = this.renderPage.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.renderConfirmationPage = this.renderConfirmationPage.bind(this)
        this.renderQuestions = this.renderQuestions.bind(this)

        this.addAnswerChoice = this.addAnswerChoice.bind(this)
        this.postOpportunities = this.postOpportunities.bind(this)
        this.searchEmployers = this.searchEmployers.bind(this)
        this.employerClicked = this.employerClicked.bind(this)
        this.addItem = this.addItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.renderItems = this.renderItems.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.addTag = this.addTag.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.searchCompetencies = this.searchCompetencies.bind(this)
        this.switchTapped = this.switchTapped.bind(this)

        this.closeModal = this.closeModal.bind(this)
        this.importInfo = this.importInfo.bind(this)
        this.queryInfo = this.queryInfo.bind(this)
        this.authenticate = this.authenticate.bind(this)

        this.child = React.createRef();

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      window.scrollTo(0, 0)

      console.log('we up in there')

      //user navigated from a landing page or from the app
      let { org } = this.props.params
      console.log('show org: ', org)

      const volunteerAnswerChoices = ['Company Tours','Guest Speaker','Career Fairs','Job Shadowing','Immersive Workshops','Professional & Curriculum Development','Mentorships','Mock Interviews']
      const volunteerSelected = [false,false,false,false,false]

      const isActive = true

      Axios.get('/api/org/all', { params: { isActive } })
      .then((response) => {
         console.log('All orgs query attempted', response.data);

         if (response.data.success && response.data.orgs) {
           console.log('successfully retrieved orgs')

           let referrerOptions = response.data.orgs
           referrerOptions.unshift({ orgName: 'Other'})
           referrerOptions.unshift({ orgName: ''})

           this.setState({ referrerOptions })

         } else {
           console.log('no orgs found', response.data.message)
         }

      }).catch((error) => {
           console.log('Orgs query did not work', error);
      });

      if (!org) {
        org = 'guidedcompass'
      }

      Axios.get('/api/org', { params: { orgCode: org } })
      .then((response) => {
        console.log('Org info query attempted', response.data);

        if (response.data.success) {
          console.log('org info query worked')

          const orgName = response.data.orgInfo.orgName
          const orgURL = response.data.orgInfo.orgURL
          const orgMission = response.data.orgInfo.orgMission

          const orgContactFirstName = response.data.orgInfo.contactFirstName
          const orgContactLastName = response.data.orgInfo.contactLastName
          const orgContactEmail = response.data.orgInfo.contactEmail
          const orgContactTitle = response.data.orgInfo.contactTitle
          const orgProgramName = response.data.orgInfo.orgProgramName
          const orgCCEmails = response.data.orgInfo.ccEmails

          const automaticallyAttach = response.data.orgInfo.automaticallyAttach
          const parentPostId = response.data.orgInfo.parentPostId

          const introSummary = response.data.orgInfo.introSummary

          let confirmationMessage = "Thank you for submitting! You will receive weekly emails of top candidates on Mondays and ad-hoc referrals from our partners that you can opt out of at any time!"

          const orgLogo = response.data.orgInfo.webLogoURI
          const orgPartnerLogo = response.data.orgInfo.partnerLogoURI
          const employerFormTitle = response.data.orgInfo.employerFormTitle
          let officeTypeOptions = response.data.orgInfo.officeTypeOptions
          if (officeTypeOptions) {
            officeTypeOptions.unshift('')
          }

          let isMobileView = false
          if (window.innerWidth < 767) {
            isMobileView = true
          }

          let employerType = ''
          let employerIndustry = ''
          let employeeCount = ''

          let wantsInterns = 'true'
          if (org === 'unite-la' || org === 'block') {
            wantsInterns = null
          }

          const deactivated = response.data.orgInfo.deactivated
          let redirectLink = response.data.orgInfo.employerRedirectLink
          if (redirectLink && !redirectLink.startsWith('http')) {
            redirectLink = 'http://' + redirectLink
          }

          let modalIsOpen = false
          if (deactivated) {
            modalIsOpen = true
          }

          const customVirtualTerm = response.data.orgInfo.customVirtualTerm

          let preSetStartDate = undefined
          if (response.data.orgInfo.preSetStartDate) {
            preSetStartDate = convertDateToString(response.data.orgInfo.preSetStartDate,"first10")
          }

          let preSetEndDate = undefined
          if (response.data.orgInfo.preSetEndDate) {
            preSetEndDate = convertDateToString(response.data.orgInfo.preSetEndDate,"first10")
          }

          let preSetSubmissionDeadline = undefined
          if (response.data.orgInfo.preSetSubmissionDeadline) {
            preSetSubmissionDeadline = convertDateToString(response.data.orgInfo.preSetSubmissionDeadline,"first10")
          }

          let hoursPerWeekOptions = ['']
          if (response.data.orgInfo.hoursPerWeekOptions) {
            hoursPerWeekOptions = hoursPerWeekOptions.concat(response.data.orgInfo.hoursPerWeekOptions)
          }

          const infoCollected = ['Resume','Basic Info','Interests','Personality','Values','Skills','Knowledge','Work Style','Diversity Info','Awards']

          let backgroundImageURL = null
          if (response.data.orgInfo.backgroundImageURL) {
            backgroundImageURL = response.data.orgInfo.backgroundImageURL
          }

          this.setState({

              org, orgName, orgURL, orgMission, orgLogo, orgPartnerLogo,

              orgProgramName, orgContactFirstName, orgContactLastName, orgContactTitle, orgContactEmail, orgCCEmails,

              introSummary, confirmationMessage, automaticallyAttach, parentPostId, employerFormTitle, officeTypeOptions,

              volunteerAnswerChoices, volunteerSelected, isMobileView,

              employerType, employerIndustry, employeeCount, wantsInterns,

              deactivated, redirectLink, modalIsOpen,

              customVirtualTerm, preSetStartDate, preSetEndDate, preSetSubmissionDeadline, infoCollected, backgroundImageURL

          });

          Axios.get('/api/workoptions')
          .then((response2) => {
            console.log('Work options query tried', response2.data);

            if (response2.data.success) {
              console.log('Work options query succeeded')

              let postCountOptions = ['']

              let startingPoint = 1
              if (org === 'unite-la' || org === 'block') {
                startingPoint = 0
              }
              for (let i = startingPoint; i <= 6; i++) {
                postCountOptions.push(i.toString())
              }

              let politicalAlignmentOptions = response2.data.workOptions[0].politicalAlignmentOptions
              if (politicalAlignmentOptions) {
                politicalAlignmentOptions.unshift('')
              }

              let registrationOptions = response2.data.workOptions[0].unitedStateOptions
              if (registrationOptions) {
                registrationOptions.unshift('')
              }

              let locationOptions = response2.data.workOptions[0].locationOptions

              let functionOptions = response2.data.workOptions[0].functionOptions
              // functionOptions.push('Other')

              let industryOptions = response2.data.workOptions[0].industryOptions

              // const hoursPerWeekOptions = response2.data.workOptions[0].hoursPerWeekOptions
              if (!response.data.orgInfo.hoursPerWeekOptions || response.data.orgInfo.hoursPerWeekOptions.length === 0) {
                hoursPerWeekOptions = response2.data.workOptions[0].hoursPerWeekOptions
              }
              // console.log('compare hoursPerWeekOptions: ', hoursPerWeekOptions, response.data.orgInfo.hoursPerWeekOptions, response2.data.workOptions[0].hoursPerWeekOptions)
              const countOptions = response2.data.workOptions[0].employeeCountOptions
              const projectedInternOptions = response2.data.workOptions[0].projectedInternOptions
              // let applicationMethodOptions = response.data.workOptions[0].applicationMethodOptions
              // applicationMethodOptions.unshift('')

              const applicationMethodOptions = [
                '',
                'Only receive candidates who are referred via Guided Compass',
                'Applicants may be referred, but they must also apply via email',
                'Applicants may be referred, but they must also apply via our website posting'
              ]

              const workTypeOptions = response2.data.workOptions[0].workTypeOptions
              const hourlyPayOptions = response2.data.workOptions[0].hourlyPayOptions
              const annualPayOptions = response2.data.workOptions[0].annualPayOptions
              const supplementalPayOptions = response2.data.workOptions[0].supplementalPayOptions
              const benefitOptions = response2.data.workOptions[0].benefitOptions

              const durationOptions = response2.data.workOptions[0].durationOptions
              const knowledgeLevelOptions = ['','Beginner','1-2 Years of Familiarity','3+ Years of Familiarity']

              let atsOptions = ['','Avature','Workable','JazzHR','Lever','Greenhouse','Breezy HR', 'iCIMS','RecruiterBox','Pinpoint','ClearCompany','Workday','Other',]
              atsOptions.sort()
              atsOptions.push('No ATS',"I'm Not Sure")

              let timeframeOptions = []
              const currentMonth = new Date().getMonth()
              const currentYear = new Date().getFullYear()
              const seasons = ['Spring','Summer','Fall','Winter']
              let currentSeason = ''
              if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
                currentSeason = seasons[0]
              } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
                currentSeason = seasons[1]
              } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
                currentSeason = seasons[2]
              } else if (currentMonth === 9 || currentMonth === 10 || currentMonth === 11) {
                currentSeason = seasons[3]
              }

              for (let i = 1; i <= 4; i++) {
                if (i === 1) {
                  timeframeOptions.push(currentSeason + ' ' + currentYear)
                } else {
                  // not currentSeason
                  const index = seasons.indexOf(currentSeason)
                  if (index + i - 1 > 3) {
                    const nextYear = currentYear + 1
                    const newIndex = (index + i - 1) - 4
                    timeframeOptions.push(seasons[newIndex] + ' ' +  nextYear)
                  } else {
                    timeframeOptions.push(seasons[index + i - 1] + ' ' + currentYear)
                  }
                }
              }

              timeframeOptions.push("Ad-Hoc / As-Needed")
              console.log('show annualPayOptions: ', annualPayOptions)

              this.setState({
                functionOptions,industryOptions, hoursPerWeekOptions, countOptions, projectedInternOptions,
                postCountOptions, politicalAlignmentOptions, registrationOptions, locationOptions, applicationMethodOptions,
                durationOptions, knowledgeLevelOptions, atsOptions, timeframeOptions, workTypeOptions,
                hourlyPayOptions, annualPayOptions, supplementalPayOptions, benefitOptions
              })

              let orgCode = 'general'

              //pull general benchmarks as backup
              Axios.get('/api/benchmarks', { params: { orgCode } })
              .then((response2) => {
                console.log('Benchmarks query attempted', response2.data);

                  if (response2.data.success) {
                    console.log('benchmark query worked')

                    if (response2.data.benchmarks.length !== 0) {
                      //jobs = response.data.postings
                      let benchmarkOptions = response2.data.benchmarks
                      functionOptions = ['']
                      for (let i = 1; i <= benchmarkOptions.length; i++) {
                        if (!benchmarkOptions[i - 1].title.includes('Scholarship')) {
                          if (!functionOptions.includes(benchmarkOptions[i - 1].jobFunction)) {
                            functionOptions.push(benchmarkOptions[i - 1].jobFunction)
                          }
                        }
                      }

                      functionOptions.sort()
                      if (org === 'dpscd' && !functionOptions.includes('Other')) {
                        functionOptions.push('Other')
                      }

                      this.setState({ benchmarkOptions, functionOptions })
                    }
                  }
              })
            }
          });

        } else {
          console.log('org info query did not work', response.data.message)
          this.setState({ volunteerAnswerChoices, volunteerSelected })
        }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      const fromExternal = this.props.location.state
      if (fromExternal) {
          setTimeout(() => {
              this.setState({animate: true, opacity: 1})
          }, 0.7)
      } else {
          this.setState({animate: true, opacity: 1})
      }
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event.target.value, event.target.name)

      //validate strings
      if (event.target.name === 'contactFirstName') {
        let contactFirstName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        const employerContactFirstName = contactFirstName
        this.setState({ contactFirstName , employerContactFirstName })
      } else if (event.target.name === 'contactLastName') {
        let contactLastName = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        const employerContactLastName = contactLastName
        this.setState({ contactLastName, employerContactLastName })
      } else if (event.target.name === 'employerName') {

        const employerName = event.target.value

        let accountCode = ''
        if (employerName && employerName !== '') {
          let trimmedName = employerName.trim()
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        }

        this.setState({ employerName, accountCode })

        this.searchEmployers(employerName)
      } else if (event.target.name === 'employerLocation') {
        this.setState({ employerLocation: event.target.value })
      } else if (event.target.name === 'employerURL') {
        this.setState({ employerURL: event.target.value })
      } else if (event.target.name === 'employerType') {
        this.setState({ employerType: event.target.value })
      } else if (event.target.name === 'employeeCount') {
        this.setState({ employeeCount: event.target.value })
      } else if (event.target.name === 'employerIndustry') {
        this.setState({ employerIndustry: event.target.value })
      } else if (event.target.name === 'employerDescription') {
        this.setState({ employerDescription: event.target.value })
      } else if (event.target.name === 'contactTitle') {
        this.setState({ contactTitle: event.target.value })
      } else if (event.target.name === 'referrerName') {
        let referrerName = event.target.value
        // if (referrerName === 'Other ') {
        //   referrerName = 'Other'
        // }
        this.setState({ referrerName })
      } else if (event.target.name === 'referralExplanation') {
        this.setState({ referralExplanation: event.target.value })
      } else if (event.target.name === 'contactEmail') {
        const contactEmail = event.target.value
        const employerContactEmail = contactEmail
        this.setState({ contactEmail, employerContactEmail })
      } else if (event.target.name === 'contactPhone') {
        this.setState({ contactPhone: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name === 'wantsInterns') {
        this.setState({ wantsInterns: event.target.value })
      } else if (event.target.name === 'projectedHires') {
        this.setState({ projectedHires: event.target.value })
      } else if (event.target.name === 'hasFunds') {
        this.setState({ hasFunds: event.target.value })
      } else if (event.target.name === 'canHost') {
        this.setState({ canHost: event.target.value })
      } else if (event.target.name === 'hostUndocumented') {
        this.setState({ hostUndocumented: event.target.value })
      } else if (event.target.name.includes("success")) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1]) - 1

        let successSelected = this.state.successSelected
        let successMetrics = this.state.successMetrics
        if (successSelected[index]) {
          successSelected[index] = false
          successMetrics.indexOf(this.state.successAnswerChoices[index])
          if (index > -1) {
            successMetrics.splice(index, 1);
          }
        } else {
          successSelected[index] = true
          successMetrics.push(this.state.successAnswerChoices[index])
        }

        this.setState({ successSelected, successMetrics })

      } else if (event.target.name.includes("volunteer")) {
        let nameArray = event.target.name.split("|")
        let index = Number(nameArray[1]) - 1

        let volunteerSelected = this.state.volunteerSelected
        let volunteerPreferences = this.state.volunteerPreferences
        if (volunteerSelected[index]) {
          volunteerSelected[index] = false
          volunteerPreferences.indexOf(this.state.volunteerAnswerChoices[index])
          if (index > -1) {
            volunteerPreferences.splice(index, 1);
          }
        } else {
          volunteerSelected[index] = true
          volunteerPreferences.push(this.state.volunteerAnswerChoices[index])
        }

        this.setState({ volunteerSelected, volunteerPreferences })
      } else if (event.target.name === 'title') {
        const title = event.target.value
        let selectedJob = this.state.selectedJob
        selectedJob['title'] = event.target.value
        this.setState({ title, selectedJob })
      } else if (event.target.name.includes('work|')) {

        const nameArray = event.target.name.split("|")
        const name = nameArray[1]
        const index = nameArray[2]
        let postings = this.state.postings

        if (name !== 'function') {
          if (name === 'isPerpetual') {

            const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            postings[index][name] = value
            this.setState({ postings })
          } else if (name === 'subPostType') {
            let isPerpetual = false
            let hoursPerWeek = ''
            if (event.target.value === 'Part-Time' || event.target.value === 'Full-Time') {
              isPerpetual = true
              if (event.target.value === 'Full-Time') {
                hoursPerWeek = '~ 40 Hours'
              } else {
                hoursPerWeek = '~ 20 Hours'
              }
            } else {
              isPerpetual = false
            }

            postings[index][name] = event.target.value
            postings[index]['isPerpetual'] = isPerpetual
            postings[index]['hoursPerWeek'] = hoursPerWeek

            this.setState({ postings })
          } else {
            console.log('added to postings: ', index, name, event.target.value)
            postings[index][name] = event.target.value
            this.setState({ postings })
          }
        } else {

          postings[index][name] = event.target.value

          let benchmark = null
          let competencyTags = []
          if (this.state.benchmarkOptions && this.state.benchmarkOptions.length > 0) {
            for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
              const jobFunction = this.state.benchmarkOptions[i - 1].jobFunction
              console.log('comparison: ', i, jobFunction, event.target.value)
              if (jobFunction === event.target.value) {
                if (jobFunction === 'Engineering') {
                  // cybersecurity is a temporary problem
                  if (!this.state.benchmarkOptions[i - 1].title.includes('Cybersecurity')) {
                    benchmark = this.state.benchmarkOptions[i - 1]
                  }
                } else {
                  benchmark = this.state.benchmarkOptions[i - 1]
                }
              } else if (event.target.value === 'Other') {
                if (this.state.benchmarkOptions[i - 1].title.includes('Basic')) {
                   benchmark = this.state.benchmarkOptions[i - 1]
                } else if (this.state.benchmarkOptions[i - 1].jobFunction === 'Marketing') {
                  benchmark = this.state.benchmarkOptions[i - 1]
                }
              }
            }
          }

          if (benchmark.skills && benchmark.skills.length > 0) {
            for (let i = 1; i <= benchmark.skills.length; i++) {
              let selectedSkill = benchmark.skills[i - 1]
              competencyTags.push({
                name: selectedSkill.title, category: selectedSkill.skillType, description: selectedSkill.description,
                score: selectedSkill.score, weight: selectedSkill.weight
              })
            }
          }
          if (benchmark.abilities && benchmark.abilities.length > 0) {
            for (let i = 1; i <= benchmark.abilities.length; i++) {
              let selectedItem = benchmark.abilities[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: 'Ability', description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }
          if (benchmark.knowledge && benchmark.knowledge.length > 0) {
            for (let i = 1; i <= benchmark.knowledge.length; i++) {
              let selectedItem = benchmark.knowledge[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: 'Knowledge', description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }

          if (benchmark) {
            // create new benchmark by deleting id
            delete benchmark._id
          }

          postings[index]['benchmark'] = benchmark
          postings[index]['competencyTags'] = competencyTags
          console.log('show benchmark: ', benchmark)
          this.setState({ postings })

        }

      } else if (event.target.name === 'location') {
        this.setState({ location: event.target.value })
      } else if (event.target.name === 'function') {
        let selectedJob = this.state.selectedJob
        selectedJob['workFunction'] = event.target.value

        let benchmark = null
        let competencyTags = []
        if (this.state.benchmarkOptions && this.state.benchmarkOptions.length > 0) {
          for (let i = 1; i <= this.state.benchmarkOptions.length; i++) {
            const jobFunction = this.state.benchmarkOptions[i - 1].jobFunction
            console.log('comparison: ', i, jobFunction, event.target.value)
            if (jobFunction === event.target.value) {
              if (jobFunction === 'Engineering') {
                // cybersecurity is a temporary problem
                if (!this.state.benchmarkOptions[i - 1].title.includes('Cybersecurity')) {
                  benchmark = this.state.benchmarkOptions[i - 1]
                }
              } else {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            } else if (event.target.value === 'Other') {
              if (this.state.benchmarkOptions[i - 1].title.includes('Basic')) {
                 benchmark = this.state.benchmarkOptions[i - 1]
              } else if (this.state.benchmarkOptions[i - 1].jobFunction === 'Marketing') {
                benchmark = this.state.benchmarkOptions[i - 1]
              }
            }
          }
        }
        // console.log('got the benchie?', benchmark)
        let workPreferenceResults = null
        let interestResults = null
        let personalityResults = null
        let skillScores = null
        let skillTraits = []

        let intangiblesText = ''
        if (benchmark) {
          //workPreferenceResults
          workPreferenceResults = ["['Design']","['Retail','Music', 'Information','Food Services','Fashion']","['Work for a startup or small-sized company']","Graphic Design Intern, Design Intern","n/a","Yes","15 miles","['~ 20 Miles']"]
          console.log('benchmark: ', benchmark)
          interestResults = []
          for (let i = 1; i <= benchmark.interests.length; i++) {
            const name = benchmark.interests[i - 1].title
            const description = benchmark.interests[i - 1].description
            const tempScore = Number(benchmark.interests[i - 1].score)
            // const score = Math.ceil(tempScore/5)*5
            const score = tempScore * 20 * 0.4

            interestResults.push({ name, description, score })
          }

          let tempOpennessScore = benchmark.traits[0].score
          let opennessScore = tempOpennessScore * 20 * (16/100)
          console.log('show trait: ', benchmark.traits[0].score, tempOpennessScore, opennessScore)

          const conscientiousnessScore = benchmark.traits[1].score * 20 * (16/100)
          const extraversionScore = benchmark.traits[2].score * 20 * (16/100)
          const agreeablenessScore = benchmark.traits[3].score * 20 * (16/100)
          const neuroticismScore = benchmark.traits[4].score * 20 * (16/100)

          personalityResults = {
            myersBriggs: 'ENTJ', fiveFactors: {
              opennessScore, conscientiousnessScore, extraversionScore, agreeablenessScore, neuroticismScore
            }
          }
          for (let i = 1; i <= benchmark.traits.length; i++) {

          }

          intangiblesText = 'This person is most interested in engineering careers and least interested in design careers. Their personality is high in openness and low in emotional stability.'

          if (benchmark.skills && benchmark.skills.length > 0) {
            skillScores = []
            let hardSkillCounter = 0
            let softSkillCounter = 0
            for (let i = 1; i <= benchmark.skills.length; i++) {
              let selectedSkill = benchmark.skills[i - 1]
              console.log('show selectedSkill: ', selectedSkill)
              skillScores.push({ title: selectedSkill.title, skillType: selectedSkill.skillType, score: 5 })
              if (selectedSkill.skillType === 'Hard Skill') {
                hardSkillCounter = hardSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              } else {
                softSkillCounter = softSkillCounter + 1
                if (hardSkillCounter < 7) {
                  skillTraits.push({ name: selectedSkill.title, skillType: selectedSkill.skillType, rating: 5 })
                }
              }

              competencyTags.push({
                name: selectedSkill.title, category: selectedSkill.skillType, description: selectedSkill.description,
                score: selectedSkill.score, weight: selectedSkill.weight
              })
            }
          }

          if (benchmark.abilities && benchmark.abilities.length > 0) {
            for (let i = 1; i <= benchmark.abilities.length; i++) {
              let selectedItem = benchmark.abilities[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: selectedItem.category, description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }

          if (benchmark.knowledge && benchmark.knowledge.length > 0) {
            for (let i = 1; i <= benchmark.knowledge.length; i++) {
              let selectedItem = benchmark.knowledge[i - 1]
              competencyTags.push({
                name: selectedItem.title, category: selectedItem.category, description: selectedItem.description,
                score: selectedItem.score, weight: selectedItem.weight
              })
            }
          }
        }

        const createdAt = new Date()
        const updatedAt = new Date()

        let endorsements = []
        endorsements.push({
          senderFirstName: 'Jane', senderLastName: 'Doe', senderEmail: 'janedoe@gmail.com', overallRecommendation: '5',
          recommendationExplanation: '', isTransparent: true, relationship: 'Mentor', type: 'Provide', recipientFirstName: 'Example',
          recipientLastName: 'Candidate', recipientEmail: 'jondoe@gmail.com', pathway: event.target.value,
          skillTraits,
          examples: [{ skillTrait: 'Empathy', example: 'When Jon was working for me, many customers said that Jon really empathized with their situation and that he went above and beyond to make sure I felt comfortable.'}],
          createdAt, updatedAt
        })

        let interview = { interviewerName: 'Mary Doe', date: createdAt, score: '5' }
        let interviewDetails = {
          sections: [
            { title: 'Knowledge, Skills, Abilities', questions: [{ question: 'How has your education helped prepare you for a job in Design, Digital Media, Production, or Marketing/Communications? Which path would like most?', helper: 'What to look for: Communication Skills, applicable courses/projects.' }], sectionScore: 5},
            { title: 'Communication, Collaboration, and Problem Solving', questions: [{ question: 'How would you respond if a client gives you harsh criticism?', helper: 'What to look for: Empathy, Curiosity, Problem-solving skills, Analytical/ Research-Oriented.' }], sectionScore: 5},
            { title: 'Passion for Technology', questions: [{ question: 'Why is working in and with technology important to you?', helper: 'What to look for: Passionate, Driven, Dedicated to the field.' }], sectionScore: 5},
            { title: 'True, Accurate, Error-Free and Complete Resume', questions: [{ question: 'Is there anything that you want us to know that is not mentioned on your resume?', helper: 'What to look for: Missing information, informal education and skills.' }], sectionScore: 5},
            { title: 'Demonstrable Interview Skills/Commitment/Professionalism Needed in Internship Program', questions: [{ question: 'Tell me about your job experience[s] and/or school activities as they relate to your future goals in an Design, Digital Media, Production or Marketing career.', helper: 'What to look for: Communication skills, ability to tell their story.' }], sectionScore: 5}
          ],
          glows: 'Jon has great communication skills, and shows real passion for making a difference in the world', grows: 'Jon can come across as over-confident or nieve when it comes to implementing ideas or change.'
        }
        let interviewQuestions = [
          { question: 'How have your experiences helped prepare you for work in this field?', guidance: 'What to look for: communication skills, conviction, demonstrated fit, relevant accomplishments, motivation for accomplishments.'},
        ]

        let paidExperienceHours = 500
        let volunteerHours = 100
        let experienceHours = paidExperienceHours + volunteerHours
        let projectHours = 900

        let pictureURL = 'https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB'

        const degreeRequirements = 'High School'
        const gpaRange = "GPA Doesn't Matter"
        const gradYearRange = '2020 - 2025'
        const courseHours = '1,000 - 5,000 Hours'

        const politicalAlignment = 'Republican'
        const hometown = 'Los Angeles, CA'
        const homeCongressionalDistrict = '4'

        const selectedApplication = {
          firstName: 'Example Ideal', lastName: 'Candidate', email: 'fakeemail@email.com', schoolName: 'Sample School', gradYear: '2022',
          pictureURL, major: 'Business', interview, interviewDetails, interviewQuestions,
          workPreferenceResults, personalityResults, intangiblesText, experienceHours, projectHours, degreeRequirements,
          gpaRange, gradYearRange, courseHours, politicalAlignment, hometown, homeCongressionalDistrict,
          match: 100, createdAt, updatedAt

        }

        this.setState({ function: event.target.value, selectedJob, benchmark, selectedApplication, competencyTags })
      } else if (event.target.name === 'startDate') {
        console.log('lets make sure value', this.state.startDate)
        this.setState({ startDate: event.target.value })
      } else if (event.target.name === 'isPerpetual') {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ isPerpetual: value, formHasChanged: true })
      } else if (event.target.name === 'endDate') {
        this.setState({ endDate: event.target.value })
      } else if (event.target.name === 'hoursPerWeek') {
        this.setState({ hoursPerWeek: event.target.value })
      } else if (event.target.name === 'hireCount') {
        this.setState({ hireCount: event.target.value })
      } else if (event.target.name === 'description') {
        this.setState({ description: event.target.value })
      } else if (event.target.name === 'postCount') {
        const postCount = event.target.value

        let postings = this.state.postings
        let newPostings = []
        for (let i = 1; i <= Number(postCount); i++) {
          if (postings[i - 1]) {
            newPostings.push(postings[i - 1])
          } else {
            newPostings.push({ postType: 'Work', title: '' })
          }
        }
        // console.log('show postings: ', postings)
        this.setState({ postCount, postings: newPostings })
      } else if (event.target.name === 'ats') {
        this.setState({ ats: event.target.value })
      } else if (event.target.name === 'isLATechReferral') {
        this.setState({ isLATechReferral: event.target.value })
      } else if (event.target.name === 'officeType') {
        this.setState({ officeType: event.target.value })
      } else if (event.target.name === 'politicalAlignment') {
        const politicalAlignment = event.target.value
        const postPoliticalParty = event.target.value
        this.setState({ politicalAlignment, postPoliticalParty })
      } else if (event.target.name === 'stateRegistration') {
        this.setState({ stateRegistration: event.target.value })
      } else if (event.target.name === 'congressionalDistrict') {
        this.setState({ congressionalDistrict: event.target.value })
      } else if (event.target.name === 'employerContactFirstName') {
        this.setState({ employerContactFirstName: event.target.value })
      } else if (event.target.name === 'employerContactLastName') {
        this.setState({ employerContactLastName: event.target.value })
      } else if (event.target.name === 'employerContactEmail') {
        this.setState({ employerContactEmail: event.target.value })
      } else if (event.target.name === 'subjectLine') {
        this.setState({ subjectLine: event.target.value })
      } else if (event.target.name === 'appRequirements') {
        this.setState({ appRequirements: event.target.value })
      } else if (event.target.name === 'submissionDeadline') {
        this.setState({ submissionDeadline: event.target.value })
      } else if (event.target.name === 'postPoliticalParty') {
        this.setState({ postPoliticalParty: event.target.value })
      } else if (event.target.name === 'applicationMethod') {
        this.setState({ applicationMethod: event.target.value })
      } else if (event.target.name === 'jobLink') {
        this.setState({ jobLink: event.target.value })
      } else if (event.target.name === 'additionalInformation') {
        this.setState({ additionalInformation: event.target.value })
      } else if (event.target.name === 'willHostEvents') {
        this.setState({ willHostEvents: event.target.value })
      } else if (event.target.name === 'willAttendEvents') {
        this.setState({ willAttendEvents: event.target.value })
        console.log('plz show')
      } else if (event.target.name === 'willPostProblem') {
        this.setState({ willPostProblem: event.target.value })
      } else if (event.target.name.includes('eventTitle')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['title'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventType')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['eventType'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventLocation')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['location'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventStartDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['startDate'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('eventSummary')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let hostedEvents = this.state.hostedEvents
        hostedEvents[index]['description'] = event.target.value
        this.setState({ hostedEvents })
      } else if (event.target.name.includes('problemName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['name'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('projectPromptType')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['projectPromptType'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDuration')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['duration'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemTags')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['tags'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDeadline')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['submissionDeadline'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemKnowledgeLevel')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['knowledgeLevel'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name.includes('problemDescription')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        let problems = this.state.problems
        problems[index]['description'] = event.target.value
        this.setState({ problems })
      } else if (event.target.name === 'zipcode') {
        this.setState({ zipcode: event.target.value })
      } else if (event.target.name === 'subPostType') {
        this.setState({ subPostType: event.target.value })
      } else if (event.target.name === 'tagName') {
        this.setState({ tagName: event.target.value })
        this.searchCompetencies(event.target.value, 'All', null)
      } else if (event.target.name === 'tagType') {
        this.setState({ tagType: event.target.value })
      } else if (event.target.name === 'payType') {
        this.setState({ payType: event.target.value })
      } else if (event.target.name === 'payRange') {
        this.setState({ payRange: event.target.value })
      } else if (event.target.name === 'supplementalPay') {
        this.setState({ supplementalPay: event.target.value })
      } else if (event.target.name === 'benefit') {
        this.setState({ benefit: event.target.value })
      } else if (event.target.name === 'email') {
        this.setState({ email: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name.includes('screeningQuestion|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const parentIndex = Number(nameArray[2])
        const index = Number(nameArray[3])

        let postings = this.state.postings
        let screeningQuestions = this.state.postings[parentIndex].screeningQuestions
        screeningQuestions[index][element] = event.target.value
        // console.log('show values: ', category, element, parentIndex, index, screeningQuestions, nameArray)
        postings[parentIndex]['screeningQuestions'] = screeningQuestions
        this.setState({ postings })
      } else if (event.target.name.includes('answerChoice|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const parentIndex = Number(nameArray[2])
        const questionIndex = Number(nameArray[3])
        const answerChoiceIndex = Number(nameArray[4])

        let postings = this.state.postings
        // let screeningQuestions = this.state.postings[parentIndex].screeningQuestions
        let screeningAnswerChoices = this.state.postings[parentIndex].screeningQuestions[questionIndex].screeningAnswerChoices
        screeningAnswerChoices[answerChoiceIndex][element] = event.target.value

        postings[parentIndex]['screeningQuestions'][questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ postings })
      } else if (event.target.name.includes('candidatesOfInterest|')) {
        const nameArray = event.target.name.split("|")
        // const category = nameArray[0]
        const element = nameArray[1]
        const parentIndex = Number(nameArray[2])
        const index = Number(nameArray[3])

        let postings = this.state.postings
        let candidatesOfInterest = this.state.postings[parentIndex].candidatesOfInterest
        candidatesOfInterest[index][element] = event.target.value
        // console.log('show values: ', category, element, parentIndex, index, screeningQuestions, nameArray)
        postings[parentIndex]['candidatesOfInterest'] = candidatesOfInterest
        this.setState({ postings })

      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchEmployers(employerName) {
      console.log('searchEmployers ', employerName)

      Axios.get('/api/account/search', { params: { employerName } })
      .then((response) => {
        console.log('Employer search query attempted', response.data);

          if (response.data.success) {
            console.log('employer search query worked')

            const employers = response.data.employers
            this.setState({ employers });
            // if (employers && employers.length > 0) {
            //   this.setState({ employers });
            // }

          } else {
            console.log('employer search query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Employer search query did not work for some reason', error);
      });
    }

    employerClicked(employer) {
      console.log('employerClicked called ', employer)

      const employerName = employer.employerName
      const employers = []
      const employerLocation = employer.employerLocation
      const employerURL = employer.employerURL
      const employerType = employer.employerType
      const employerIndustry = employer.employerIndustry
      const industries = employer.industries
      const employeeCount = employer.employeeCount
      const accountCode = employer.accountCode
      const sharePartners = employer.sharePartners
      const contactEmail = employer.contactEmail
      const contactPhone = employer.contactPhone
      const orgContacts = employer.orgContacts
      const referrerName = employer.referrerName
      const employerContactEmail = contactEmail
      const employerDescription = employer.description
      const employerCulture = employer.employerCulture
      const employerLogoURL = employer.employerLogoURI

      this.setState({ employerName, employers, employerLocation, employerURL, employerType, employerIndustry,
        industries,
        employeeCount, accountCode, sharePartners, contactEmail, contactPhone, orgContacts, referrerName,
        employerContactEmail, employerDescription, employerCulture, employerLogoURL })
    }

    postOpportunities() {
      console.log('postOpportunities called')

      this.setState({ errorMessage: null, isSaving: true })

      // work backwards by section
      if (this.state.password === '') {
        this.setState({ errorMessage: 'Please enter your password', isSaving: false })
      } else if (!this.state.contactFirstName || this.state.contactFirstName === '') {
        this.setState({ errorMessage: 'Please add your first name', isSaving: false })
      } else if (!this.state.contactLastName || this.state.contactLastName === '') {
        this.setState({ errorMessage: 'Please add your last name', isSaving: false })
      } else if (!this.state.employerName || this.state.employerName === '') {
        this.setState({ errorMessage: 'Please add your employer name', isSaving: false })
      } else if (!this.state.contactTitle || this.state.contactTitle === '') {
        this.setState({ errorMessage: 'Please add your title', isSaving: false })
      } else if (!this.state.employerLocation || this.state.employerLocation === '') {
        this.setState({ errorMessage: "Please add your employer's address", isSaving: false })
      } else if (!this.state.employerURL || this.state.employerURL === '') {
        this.setState({ errorMessage: "Please add your employer's website url", isSaving: false })
      } else if (!this.state.employerType || this.state.employerType === '') {
        this.setState({ errorMessage: "Please add your employer's type", isSaving: false })
      } else if (!this.state.employeeCount || this.state.employeeCount === '') {
        this.setState({ errorMessage: "Please add the number of employees", isSaving: false })
      } else if (!this.state.industries || this.state.industries.length === 0) {
        this.setState({ errorMessage: "Please add the select the industries your employer belongs in", isSaving: false })
      } else if (!this.state.employerDescription || this.state.employerDescription === '') {
        this.setState({ errorMessage: "Please add a description of your employer", isSaving: false })
      } else if (!this.state.employerDescription || this.state.employerDescription === '') {
        this.setState({ errorMessage: "Please add a description of your employer", isSaving: false })
      } else if (!this.state.contactEmail || this.state.contactEmail === '') {
        this.setState({ errorMessage: 'Please add your email', isSaving: false })
      } else if (!this.state.contactPhone || this.state.contactPhone === '') {
        this.setState({ errorMessage: 'Please add your phone number', isSaving: false })
      } else {
        console.log('did we get in here tho?')

        if (this.state.showEvents && this.state.hostedEvents && this.state.hostedEvents.length > 0) {
          for (let i = 1; i <= this.state.hostedEvents.length; i++) {
            if (!this.state.hostedEvents[i - 1].title || this.state.hostedEvents[i - 1].title === '') {
              return this.setState({ errorMessage: 'Please add a title to your event', isSaving: false })
            } else if (!this.state.hostedEvents[i - 1].eventType || this.state.hostedEvents[i - 1].eventType === '') {
              return this.setState({ errorMessage: 'Please add a type to your event', isSaving: false })
            } else if (!this.state.hostedEvents[i - 1].location || this.state.hostedEvents[i - 1].location === '') {
              return this.setState({ errorMessage: 'Please add a location to your event', isSaving: false })
            } else if (!this.state.hostedEvents[i - 1].startDate || this.state.hostedEvents[i - 1].startDate === '') {
              return this.setState({ errorMessage: 'Please add a start date to your event', isSaving: false })
            }
          }
        }
        //add problems
        if (this.state.showProjects && this.state.problems && this.state.problems.length > 0) {
          for (let i = 1; i <= this.state.problems.length; i++) {

            if (!this.state.problems[i - 1].name || this.state.problems[i - 1].name === '') {
              return this.setState({ errorMessage: 'Please add a name to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].postType || this.state.problems[i - 1].postType === '') {
              return this.setState({ errorMessage: 'Please add a type to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].duration || this.state.problems[i - 1].duration === '') {
              return this.setState({ errorMessage: 'Please add a duration to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].tags || this.state.problems[i - 1].tags === '') {
              return this.setState({ errorMessage: 'Please add tags to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].submissionDeadline || this.state.problems[i - 1].submissionDeadline === '') {
              return this.setState({ errorMessage: 'Please add a deadline to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].knowledgeLevel || this.state.problems[i - 1].knowledgeLevel === '') {
              return this.setState({ errorMessage: 'Please add a knowledge level to your project opportunity', isSaving: false })
            } else if (!this.state.problems[i - 1].description || this.state.problems[i - 1].description === '') {
              return this.setState({ errorMessage: 'Please add a description to your project opportunity', isSaving: false })
            }
          }
        }
        if (this.state.showWork && this.state.postings && this.state.postings.length > 0) {
          for (let i = 1; i <= this.state.postings.length; i++) {

            if (!this.state.postings[i - 1].title || this.state.postings[i - 1].title === '') {
              return this.setState({ errorMessage: 'Please add a title to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].subPostType || this.state.postings[i - 1].subPostType === '') {
              return this.setState({ errorMessage: 'Please add a type to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].function || this.state.postings[i - 1].function === '') {
              return this.setState({ errorMessage: 'Please add a function to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].hoursPerWeek || this.state.postings[i - 1].hoursPerWeek === '') {
              return this.setState({ errorMessage: 'Please add the hours per week to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].location || this.state.postings[i - 1].location === '') {
              return this.setState({ errorMessage: 'Please add the location type to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].startDate || this.state.postings[i - 1].startDate === '') {
              return this.setState({ errorMessage: 'Please add the start date to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].isPerpetual && !this.state.postings[i - 1].endDate) {
              return this.setState({ errorMessage: 'Please add the end date to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].description || this.state.postings[i - 1].description === '') {
              return this.setState({ errorMessage: 'Please add a description to your work opportunity', isSaving: false })
            } else if ((this.state.postings[i - 1].subPostType === 'Part-Time' || this.state.postings[i - 1].subPostType === 'Full-Time') && (!this.state.postings[i - 1].payType || this.state.postings[i - 1].payType === '')) {
              return this.setState({ errorMessage: 'Please add a pay type', isSaving: false })
            } else if ((this.state.postings[i - 1].subPostType === 'Part-Time' || this.state.postings[i - 1].subPostType === 'Full-Time') && (!this.state.postings[i - 1].payRange || this.state.postings[i - 1].payRange === '')) {
              return this.setState({ errorMessage: 'Please add a pay range', isSaving: false })
            } else if (!this.state.postings[i - 1].hireCount || this.state.postings[i - 1].hireCount === '') {
              return this.setState({ errorMessage: 'Please add how many people you will hire', isSaving: false })
            } else if (!this.state.postings[i - 1].submissionDeadline || this.state.postings[i - 1].submissionDeadline === '') {
              return this.setState({ errorMessage: 'Please add a submission deadline to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].industry || this.state.postings[i - 1].industry === '') {
              return this.setState({ errorMessage: 'Please add an industry to your work opportunity', isSaving: false })
            } else if (!this.state.postings[i - 1].hostUndocumented || this.state.postings[i - 1].hostUndocumented === '') {
              return this.setState({ errorMessage: 'Please indicate whether you will host undocumented career seekers', isSaving: false })
            } else if (this.state.postings[i - 1] && this.state.postings[i - 1].screeningQuestions && this.state.postings[i - 1].screeningQuestions.length > 0) {
              let screeningQuestions = this.state.postings[i - 1].screeningQuestions
              for (let j = 1; j <= screeningQuestions.length; j++) {
                console.log('screeningQuestion: ', j, screeningQuestions[j - 1].name)
                if (!screeningQuestions[j - 1].name || screeningQuestions[j - 1].name === '') {
                  return this.setState({ errorMessage: 'Please add a name for all screening questions', isSaving: false })
                } else if (screeningQuestions[j - 1].questionType === 'Multiple Choice' || screeningQuestions[j - 1].questionType === 'Checkbox' || screeningQuestions[j - 1].questionType === 'Ranking') {
                  let screeningAnswerChoices = screeningQuestions[j - 1].screeningAnswerChoices
                  console.log('show screeningAnswerChoices pre-save: ', screeningAnswerChoices)
                  if (!screeningAnswerChoices || screeningAnswerChoices.length === 0 || screeningAnswerChoices.length === 1) {
                    return this.setState({ errorMessage: 'Please add at least two answer choicess', isSaving: false })
                  } else if (screeningQuestions[j - 1].screeningAnswerChoices && screeningQuestions[j - 1].screeningAnswerChoices.length > 0) {
                    for (let k = 1; k <= screeningAnswerChoices.length; k++) {
                      if (!screeningAnswerChoices[k - 1].name || screeningAnswerChoices[k - 1].name === '') {
                        return this.setState({ errorMessage: 'Please add an answer choice to all your answer choices', isSaving: false })
                      } else if (!screeningAnswerChoices[k - 1].score || screeningAnswerChoices[k - 1].score === '') {
                        return this.setState({ errorMessage: 'Please add a point value 1 - 5 to all your answer choices', isSaving: false })
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.setState({ formComplete: true })

        let postings = this.state.postings
        //save us
        const firstName = this.state.contactFirstName
        const lastName = this.state.contactLastName
        const username = this.state.username
        const email = this.state.contactEmail
        const password = this.state.password

        const platform = 'web'

        const orgMode = true
        const activeOrg = this.state.org
        const orgName = this.state.orgName
        let orgContactEmail = this.state.orgContactEmail
        let orgContactFirstName = this.state.orgContactFirstName
        let orgContactLastName = this.state.orgContactLastName
        const orgContactTitle = this.state.orgContactTitle
        const orgProgramName = this.state.orgProgramName
        const orgCCEmails = this.state.orgCCEmails

        const createdAt = new Date()
        const updatedAt = new Date()

        let trimmedName = this.state.employerName.trim()
        let accountCode = ''
        if (!this.state.accountCode || this.state.accountCode === '') {
          accountCode = trimmedName.replace('"','').replace("<","").replace(">","").replace("%","").replace("{","").replace("}","").replace("|","").replace("^","").replace("~","").replace("[","").replace("]","").replace("`","").replace(/ /g,"").replace(/,/g,"").toLowerCase()
        } else {
          accountCode = this.state.accountCode
        }

        this.setState({ accountCode })

        Axios.post('/api/employer/org/register', {
          firstName,lastName, username, email, password,
          createdAt, updatedAt, platform, isEmployer: true, roleName: 'Employer',
          jobTitle: this.state.contactTitle, employerName: this.state.employerName,
          orgMode, activeOrg, orgName, orgContactEmail, orgCCEmails,
          orgContactFirstName, orgContactLastName, orgContactTitle, orgProgramName, accountCode })
        .then((response) => {

          localStorage.setItem('email', email)//this.props.auth.email
          localStorage.setItem('username', username)
          localStorage.setItem('firstName', firstName)
          localStorage.setItem('lastName', lastName)
          localStorage.setItem('isEmployer', 'true')

          if (response.data.success) {
            //save values
            console.log('Employer register worked', response.data);

            this.setState({ serverSuccessMessage: 'Employer user account saved successfully!' })

            //save account
            const employerName = this.state.employerName
            const employerURL = this.state.employerURL
            const employerType = this.state.employerType
            const employerIndustry = this.state.employerIndustry
            const industries = this.state.industries
            const employeeCount = this.state.employeeCount
            const employerLocation = this.state.employerLocation
            const projectedHires = this.state.projectedHires
            const contactFirstName = this.state.contactFirstName
            const contactLastName = this.state.contactLastName
            const contactTitle = this.state.contactTitle
            const contactEmail = this.state.contactEmail
            const contactPhone = this.state.contactPhone
            //
            // if (this.state.referrerName && this.state.referrerOptions) {
            //   for (let i = 1; i <= this.state.referrerOptions.length; i++) {
            //     const tempName = this.state.referrerOptions[i - 1].contactFirstName + ' ' + this.state.referrerOptions[i - 1].contactLastName
            //     console.log('tempName to referrer', tempName, this.state.referrerName)
            //     if (tempName === this.state.referrerName) {
            //       orgContactFirstName = this.state.referrerOptions[i - 1].contactFirstName
            //       orgContactLastName = this.state.referrerOptions[i - 1].contactLastName
            //       orgContactEmail = this.state.referrerOptions[i - 1].contactEmail
            //     }
            //   }
            // }

            // adjusting array on the back-end, in real-time, if there is an existing account
            let sharePartners = this.state.sharePartners
            if (sharePartners && !sharePartners.includes(this.state.org)) {
              sharePartners.push(this.state.org)
            } else if (!sharePartners) {
              sharePartners = [this.state.org]
            }

            let orgContacts = this.state.orgContacts
            console.log('email already exists? ', this.state.orgContacts)
            if (orgContacts && orgContacts.length) {
              let alreadyExists = false
              for (let i = 1; i <= orgContacts.length; i++) {
                if (orgContacts[i - 1].contactEmail === this.state.contactEmail) {
                  alreadyExists = true
                }
              }

              console.log('email already exists 2? ', alreadyExists, orgContacts, this.state.contactEmail)

              if (!alreadyExists) {
                const orgContactObject = { orgCode: activeOrg, contactFirstName, contactLastName,
                  contactTitle, contactEmail, contactPhone, orgContactFirstName, orgContactLastName, orgContactEmail }
                orgContacts.push(orgContactObject)
              }

            } else {
              const orgContactObject = { orgCode: activeOrg, contactFirstName, contactLastName,
                contactTitle, contactEmail, contactPhone, orgContactFirstName, orgContactLastName, orgContactEmail }
              orgContacts = [orgContactObject]
            }
            // const orgContacts = { orgCode: activeOrg, contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone }
            console.log('show orgContacts: ', orgContacts)

            const hasFunds = this.state.hasFunds
            const canHost = this.state.canHost
            const hostUndocumented = this.state.hostUndocumented
            const employerDescription = this.state.employerDescription
            const officeType = this.state.officeType
            const politicalAlignment = this.state.politicalAlignment
            const stateRegistration = this.state.stateRegistration
            const congressionalDistrict = this.state.congressionalDistrict
            const additionalInformation = this.state.additionalInformation

            const includeHighSchool = this.state.includeHighSchool
            const includeAssociates = this.state.includeAssociates
            const includeBachelors = this.state.includeBachelors

            const successMetrics = this.state.successMetrics
            const volunteerPreferences = this.state.volunteerPreferences

            const isLATechReferral = this.state.isLATechReferral
            const ats = this.state.ats
            const wantsInterns = this.state.wantsInterns
            const hireTimeframes = this.state.hireTimeframes

            const referrerName = this.state.referrerName
            const referralExplanation = this.state.referralExplanation

            Axios.post('/api/account/update', {
              employerName, employerURL, employerType, employerIndustry, industries, employeeCount,
              employerLocation, accountCode, projectedHires, orgContacts,
              contactFirstName, contactLastName, contactTitle, contactEmail, contactPhone,
              hasFunds, canHost, hostUndocumented, description: employerDescription, successMetrics, volunteerPreferences,
              officeType, politicalAlignment, stateRegistration, congressionalDistrict, additionalInformation,
              includeHighSchool, includeAssociates, includeBachelors,
              isLATechReferral, ats, wantsInterns, hireTimeframes,
              orgContactFirstName, orgContactLastName, orgContactEmail,
              referrerName, referralExplanation,
              sharePartners, activeOrg, createdAt, updatedAt })
            .then((response) => {

              if (response.data.success) {
                //save values
                console.log('Account update save worked', response.data);

                localStorage.setItem('employerName', employerName)
                localStorage.setItem('activeOrg', this.state.org)
                localStorage.setItem('sharePartners', JSON.stringify(sharePartners))

                this.setState({ serverSuccessMessage: 'Employer account saved successfully!' })

                console.log('after account update', accountCode)

                const saveCustomAssessment = async(item, index) => {
                  console.log('saveCustomAssessment ', item, index)

                  // const newIndex = index

                  //save postings
                  if (item.postType === 'Internship' || item.postType === 'Work') {
                    //work posting
                    console.log('got in work posting')

                    let customAssessment = {}
                    customAssessment['title'] = item.title
                    customAssessment['type'] = 'Screening'
                    customAssessment['questions'] = item.screeningQuestions
                    customAssessment['accountCode'] = accountCode
                    customAssessment['orgCode'] = activeOrg
                    customAssessment['createdAt'] = new Date()
                    customAssessment['updatedAt'] = new Date()

                    return Axios.post('/api/customassessments', customAssessment)
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Custom assessment save worked', response.data);

                        this.setState({ serverSuccessMessage: 'Saved Custom Assessment!' })

                        const customAssessmentId = response.data._id
                        return customAssessmentId

                      } else {
                        console.error('there was an error posting the custom assessment');
                        this.setState({ errorMessage: response.data.message })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The custom assessment save did not work', error);
                        return false
                    });
                  } else if (item.postType === 'Event') {
                    console.log('got in event posting')

                    return null

                  } else if (item.postType === 'Project' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenge') {
                    //request to post project opportunity
                    console.log('got in problem posting')

                    return null

                  } else {
                    console.log('got in nothing')
                  }
                }

                const saveCustomBenchmark = async(item, index) => {
                  console.log('saveCustomBenchmark ', item, index)

                  // const newIndex = index

                  //save postings
                  if (item.postType === 'Internship' || item.postType === 'Work') {
                    //work posting
                    console.log('got in work posting')

                    let benchmark = item.benchmark
                    benchmark['title'] = item.title
                    benchmark['jobTitle'] = item.title
                    benchmark['jobType'] = item.subPostType
                    benchmark['industry'] = item.industry
                    benchmark['proximityRequirements'] = item.location
                    benchmark['location'] = item.zipcode
                    benchmark['payType'] = item.payType
                    benchmark['payRange'] = item.payRange
                    benchmark['maxPay'] = item.payRange
                    benchmark['accountCode'] = accountCode
                    benchmark['orgCode'] = activeOrg

                    // account for competency tags
                    const competencyTags = item.competencyTags
                    let skills = []
                    let knowledge = []
                    let abilities = []
                    if (competencyTags) {
                      for (let i = 1; i <= competencyTags.length; i++) {
                        if (competencyTags[i - 1]) {
                          const title = competencyTags[i - 1].name
                          const category = competencyTags[i - 1].category
                          const description = competencyTags[i - 1].description
                          const score = competencyTags[i - 1].score
                          const weight = competencyTags[i - 1].weight
                          if (competencyTags[i - 1].category.includes('Skill')) {
                            skills.push({ title, skillType: category, description, score, weight })
                          } else if (competencyTags[i - 1].category.includes('Knowledge')){
                            // knowledge - add later
                            knowledge.push({ title, category, description, score, weight })
                          } else if (competencyTags[i - 1].category.includes('Ability')){
                            // ability - add later
                            abilities.push({ title, category, description, score, weight })
                          }
                        }
                      }
                    }

                    benchmark['skills'] = skills
                    benchmark['knowledge'] = knowledge
                    benchmark['abilities'] = abilities
                    benchmark['createdAt'] = new Date()
                    benchmark['updatedAt'] = new Date()

                    console.log('show skills before save: ', skills, knowledge)

                    return Axios.post('/api/benchmarks', benchmark)
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Benchmark save worked', response.data);

                        this.setState({ serverSuccessMessage: 'Saved benchmark!' })

                        const benchmarkId = response.data._id
                        return benchmarkId

                      } else {
                        console.error('there was an error posting the benchmark');
                        this.setState({ errorMessage: response.data.message })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The benchmark save did not work', error);
                        return false
                    });
                  } else if (item.postType === 'Event') {
                    console.log('got in event posting')

                    return null

                  } else if (item.postType === 'Project' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenge') {
                    //request to post project opportunity
                    console.log('got in problem posting')

                    return null

                  } else {
                    console.log('got in nothing')
                  }
                }

                const postIndividualPost = async(item, index, passedStateArray, passedBenchmarkId, passedCustomAssessmentId) => {
                  console.log('postIndividualPost ', item, index, passedBenchmarkId, passedCustomAssessmentId)

                  // const newIndex = index

                  //save postings
                  if (item.postType === 'Internship' || item.postType === 'Work') {
                    //work posting
                    console.log('got in work posting')

                    // const postType = 'Individual'
                    const postType = item.postType
                    const subPostType = item.subPostType
                    const payType = item.payType
                    const payRange = item.payRange
                    const supplementalPayArray = item.supplementalPayArray
                    const benefits = item.benefits
                    const prioritizeSubsidized = item.prioritizeSubsidized
                    const prioritizeSchoolCredit = item.prioritizeSchoolCredit
                    const hostUndocumented = item.hostUndocumented

                    const title = item.title
                    const location = item.location
                    const zipcode = item.zipcode
                    const type = "Internship"
                    const field = item.function + " | " + item.industry
                    const jobFunction = item.function
                    const industry = item.industry
                    const competencyTags = item.competencyTags

                    let startDate = undefined
                    if (item.startDate) {
                      startDate = convertStringToDate(item.startDate,"dateOnly")
                    }

                    const isPerpetual = item.isPerpetual
                    let endDate = undefined
                    if (item.endDate) {
                      endDate = convertStringToDate(item.endDate,"dateOnly")
                    }

                    const hoursPerWeek = item.hoursPerWeek
                    const hireCount = item.hireCount

                    const description = item.description

                    let employerEmployees = employeeCount
                    let employerGrowth = 'Undislosed'
                    const imageURL = this.state.employerLogoURI

                    let customAssessmentId = ''
                    if (passedCustomAssessmentId) {
                      customAssessmentId = passedCustomAssessmentId
                    }

                    let applicationComponents = ['basicInfo','resume','interests','skills','personality','endorsements']
                    const appComponents = [
                      { name: 'Basic Profile Info', include: true, required: true, disabled: true },
                      { name: 'Profile Details', include: true, required: false, disabled: true },
                      { name: 'Resume', include: true, required: true, disabled: false },
                      { name: 'Cover Letter', include: false, required: false, disabled: false },
                      { name: 'Academic Transcript', include: false, required: true, disabled: false },
                      { name: 'Letter of Recommendation', include: false, required: true, disabled: false },
                      { name: 'Identification', include: false, required: true, disabled: false },
                      { name: 'Career Assessments', include: true, required: true, disabled: false },
                      { name: 'Endorsements', include: true, required: false, disabled: false },
                      { name: 'Custom Assessment', include: false, required: true, disabled: false },
                    ]

                    if (customAssessmentId !== '') {
                      appComponents.push({ name: 'Custom Assessment', include: true, required: true, disabled: false })
                    } else {
                      appComponents.push({ name: 'Custom Assessment', include: false, required: true, disabled: false })
                    }

                    const subjectLine = item.subjectLine
                    const appRequirements = item.appRequirements

                    let submissionDeadline = undefined
                    if (item.submissionDeadline) {
                      submissionDeadline = convertStringToDate(item.submissionDeadline,"dateOnly")
                    }

                    const postPoliticalParty = item.politicalParty
                    const applicationMethod = item.applicationMethod
                    const jobLink = item.jobLink

                    let interests = ['Realistic']
                    let skills = ['Wireframing']
                    let traits = ['Resilience']

                    let benchmarkId = ''
                    let useCustomBenchmark = false
                    let isChild = false
                    let parentPostId = null
                    let trackIndex = 0

                    if (this.state.automaticallyAttach && this.state.parentPost) {
                      isChild = true
                      parentPostId = this.state.parentPostId

                      let tracks = this.state.tracks
                      for (let j = 1; j <= tracks.length; j++) {
                        console.log(j)

                        if (tracks[j - 1].benchmark.jobFunction === item.function) {
                          benchmarkId = tracks[j - 1].benchmark._id
                          useCustomBenchmark = true
                          trackIndex = j - 1
                        }
                      }
                    }

                    let standardBenchmark = null
                    if (useCustomBenchmark === false) {
                      //if there is no saved benchmark for this function
                      for (let j = 1; j <= this.state.benchmarkOptions.length; j++) {
                        console.log(j)

                        if (this.state.benchmarkOptions[j - 1].jobFunction === item.function) {
                          benchmarkId = this.state.benchmarkOptions[j - 1]._id
                          standardBenchmark = this.state.benchmarkOptions[j - 1]
                        }
                      }
                    }
                    console.log('benchmarkId 1: ', benchmarkId)
                    if (benchmarkId === '' && this.state.benchmarkOptions[1]) {
                      benchmarkId = this.state.benchmarkOptions[1]._id
                    }
                    console.log('benchmarkId 2: ', benchmarkId)
                    if (this.state.customBenchmarkId && this.state.customBenchmarkId !== '') {
                      benchmarkId = this.state.customBenchmarkId
                    }

                    if (passedBenchmarkId && passedBenchmarkId !== '') {
                      benchmarkId = passedBenchmarkId
                    }
                    console.log('benchmarkId 3: ', benchmarkId)
                    const usePrimaryEmployerContact = true
                    let employerContactFirstName = contactFirstName
                    let employerContactLastName = contactLastName
                    let employerContactEmail = contactEmail
                    if (item.employerContactEmail) {
                      employerContactFirstName = item.employerContactFirstName
                      employerContactLastName = item.employerContactLastName
                      employerContactEmail = item.employerContactEmail
                    }

                    const employerContactPhone = contactPhone
                    const usePrimaryOrgContact = true

                    const direct = false

                    const screeningQuestions = item.screeningQuestions
                    const candidatesOfInterest = item.candidatesOfInterest

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail

                    const applications = []
                    const applicants = []
                    const orgCode = this.state.org
                    let placementPartners = null
                    if (this.state.sharePartners && this.state.sharePartners.length > 0) {
                      placementPartners = []
                      for (let i = 1; i <= this.state.sharePartners.length; i++) {
                        if (this.state.sharePartners[i - 1] !== orgCode) {
                          placementPartners.push(this.state.sharePartners[i - 1])
                        }
                      }
                    }

                    let orgName = ''
                    if (this.state.orgName) {
                      orgName = this.state.orgName
                    }

                    let orgURL = ''
                    if (this.state.orgURL) {
                      orgURL = this.state.orgURL
                    }

                    let orgMission = ''
                    if (this.state.orgMission) {
                      orgMission = this.state.orgMission
                    }

                    const posterEmail = this.state.contactEmail
                    const isActive = true

                    const createdAt = new Date()
                    const updatedAt = new Date()

                    return Axios.post('/api/postings', {
                      postType, subPostType, title, location, zipcode, type, field, jobFunction, industry, competencyTags, payType, payRange, description,
                      employerName, employerURL, employerType, employerEmployees, employerGrowth, imageURL,
                      applicationComponents, appComponents, applications, applicants, hostUndocumented,
                      supplementalPayArray, benefits, prioritizeSubsidized, prioritizeSchoolCredit,
                      orgCode, placementPartners, interests, skills, traits, customAssessmentId, benchmarkId,
                      hoursPerWeek, hireCount, startDate, isPerpetual, isChild, parentPostId, posterEmail,
                      subjectLine, appRequirements, submissionDeadline, politicalParty: postPoliticalParty, applicationMethod, jobLink,
                      accountCode, usePrimaryEmployerContact, employerContactFirstName, employerContactLastName,
                      employerContactEmail, employerContactPhone, usePrimaryOrgContact,
                      orgContactFirstName, orgContactLastName, orgContactEmail, referrerName, referralExplanation, direct,
                      screeningQuestions, candidatesOfInterest,
                      orgName, orgURL, orgMission, endDate, isActive, createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        const postingId = response.data._id

                        postings[index]['_id'] = postingId
                        this.setState({ postings })

                        // const passedState = { isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName,
                        //   orgName, orgContactEmail, firstName, postingId }
                        passedStateArray.push({ isChild, useCustomBenchmark, trackIndex, standardBenchmark, title, employerName, postType,
                          orgName, orgContactEmail, firstName, postingId })

                        // console.log('show passedState 1: ', newIndex, passedStateArray)
                        // if (newIndex === this.state.postings.length - 1) {
                        //   loopThroughIndividuals(passedStateArray)
                        // }

                        // return passedState
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({ errorMessage: response.data.message })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });
                  } else if (item.postType === 'Event') {
                    console.log('got in event posting')

                    const postType = item.postType
                    const title = item.title
                    const eventType = item.eventType
                    const location = item.location
                    const startDate = item.startDate

                    const surveyId = '5e41f8bcd03e055d7c6143ae'

                    const willEmployerLead = 'One Employer Will Lead'

                    const employerContactFirstName = contactFirstName
                    const employerContactLastName = contactLastName
                    const employerContactEmail = contactEmail

                    // const accountCode = this.state.selectedEmployer.accountCode

                    // const summary = this.state.summary
                    // const endDate = this.state.endDate
                    // const locationType = this.state.locationType
                    // const transportationDetails = this.state.transportationDetails
                    // const links = this.state.links
                    // const featured = this.state.featured

                    const status = 'Undecided'
                    const isActive = true

                    let orgCode = this.state.org
                    let placementPartners = null
                    if (this.state.sharePartners && this.state.sharePartners.length > 0) {
                      placementPartners = []
                      for (let i = 1; i <= this.state.sharePartners.length; i++) {
                        if (this.state.sharePartners[i - 1] !== orgCode) {
                          placementPartners.push(this.state.sharePartners[i - 1])
                        }
                      }
                    }

                    let posterEmail = this.state.contactEmail

                    let contributorFirstName = firstName
                    let contributorLastName = lastName
                    let contributorEmail = email
                    let contributorTitle = this.state.contactTitle
                    let contributorRoleName = 'Employer'

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail
                    let orgName = this.state.orgName
                    const emailId = this.state.contactEmail

                    const requestMode = false
                    const createdAt = new Date()
                    const updatedAt = new Date()

                    //request to post event
                    return Axios.post('/api/postings', {
                      postType, title, eventType, location, startDate, surveyId, willEmployerLead,
                      accountCode, employerName, employerURL, employerType,
                      employerContactFirstName, employerContactLastName, employerContactEmail,
                      status, isActive, orgCode, placementPartners, posterEmail, emailId,
                      contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorRoleName,
                      orgContactFirstName, orgContactLastName, orgContactEmail, orgName, requestMode,
                      createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        let hostedEvents = this.state.hostedEvents
                        hostedEvents[index]['_id'] = response.data._id
                        this.setState({ hostedEvents })

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        // return passedState
                        const passedStateArray = { postType }
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({ errorMessage: response.data.message })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });

                  } else if (item.postType === 'Project' || item.postType === 'Assignment' || item.postType === 'Problem' || item.postType === 'Challenge') {
                    //request to post project opportunity
                    console.log('got in problem posting')

                    const postType = item.postType
                    const projectPromptType = item.projectPromptType
                    // console.log('show the types: ', postType, projectPromptType)
                    const name = item.name
                    const duration = item.duration
                    const tags = item.tags
                    const submissionDeadline = item.submissionDeadline
                    const knowledgeLevel = item.knowledgeLevel
                    const description = item.description

                    const employerContactFirstName = contactFirstName
                    const employerContactLastName = contactLastName
                    const employerContactEmail = contactEmail

                    const status = 'Undecided'
                    const isActive = true
                    let orgCode = this.state.org
                    let placementPartners = null
                    if (this.state.sharePartners && this.state.sharePartners.length > 0) {
                      placementPartners = []
                      for (let i = 1; i <= this.state.sharePartners.length; i++) {
                        if (this.state.sharePartners[i - 1] !== orgCode) {
                          placementPartners.push(this.state.sharePartners[i - 1])
                        }
                      }
                    }

                    let contributorFirstName = firstName
                    let contributorLastName = lastName
                    let contributorEmail = email
                    let contributorTitle = this.state.contactTitle
                    let contributorRoleName = 'Employer'

                    let orgContactFirstName = this.state.orgContactFirstName
                    let orgContactLastName = this.state.orgContactLastName
                    let orgContactEmail = this.state.orgContactEmail
                    let orgName = this.state.orgName
                    const emailId = this.state.contactEmail

                    const requestMode = false
                    const createdAt = new Date()
                    const updatedAt = new Date()

                    const upvotes = []
                    const downvotes = []
                    const difficultyLevel = 'Medium'

                    const isExternal = false
                    const isExternalPost = false

                    let pointValue = 0
                    if (difficultyLevel === 'Very Easy') {
                      pointValue = 1
                    } else if (difficultyLevel === 'Easy') {
                      pointValue = 3
                    } else if (difficultyLevel === 'Medium') {
                      pointValue = 4
                    } else if (difficultyLevel === 'Hard') {
                      pointValue = 7
                    } else if (difficultyLevel === 'Challenger') {
                      pointValue = 10
                    } else {
                      pointValue = 5
                    }

                    //request to post event
                    return Axios.post('/api/postings', {
                      postType, projectPromptType, name, duration, tags,
                      employerName, employerURL, employerType, difficultyLevel,
                      employerContactFirstName, employerContactLastName, employerContactEmail,
                      contributorFirstName, contributorLastName, contributorEmail, contributorTitle, contributorRoleName,
                      pointValue, upvotes, downvotes,
                      submissionDeadline, knowledgeLevel, description,
                      isExternal, emailId,
                      orgContactEmail, orgContactFirstName, orgContactLastName,
                      accountCode, orgCode, placementPartners, orgName, requestMode, isExternalPost, status, isActive,
                      createdAt, updatedAt })
                    .then((response) => {

                      if (response.data.success) {
                        //save values
                        console.log('Post save worked', response.data);

                        this.setState({
                          serverPostSuccess: true,
                          serverSuccessMessage: 'Job posted successfully!'
                        })

                        let problems = this.state.problems
                        problems[index]['_id'] = response.data._id
                        this.setState({ problems })

                        // return passedState
                        const passedStateArray = { postType }
                        return passedStateArray

                      } else {
                        console.error('there was an error posting the job');
                        this.setState({ errorMessage: response.data.message })
                        return false
                      }
                    }).catch((error) => {
                        console.log('The job post did not work', error);
                        return false
                    });

                  } else {
                    console.log('got in nothing')
                  }
                }

                // const editParentPost = async(index, passedState) => {
                //   console.log('editParentPost ', index, passedState)
                //
                //   const postingId = passedState.postingId
                //   const title = passedState.title
                //   const employerName = passedState.employerName
                //
                //
                //   const useCustomBenchmark = passedState.useCustomBenchmark
                //   const standardBenchmark = passedState.standardBenchmark
                //   const trackIndex = passedState.trackIndex
                //   let trackFunctions = this.state.trackFunctions
                //
                //   const orgName = passedState.orgName
                //   const orgContactEmail = passedState.orgContactEmail
                //   const firstName = passedState.firstName
                //
                //   const employerContactFirstName = passedState.employerContactFirstName
                //   const employerContactLastName = passedState.employerContactLastName
                //   const employerContactEmail = passedState.employerContactEmail
                //
                //   const postToAttach = { _id: postingId, title, employerName }
                //
                //   return Axios.post('/api/postings/attach-to-parent', {
                //     _id: this.state.parentPost._id, postToAttach,
                //     useCustomBenchmark, standardBenchmark, trackIndex, trackFunctions,
                //     employerContactFirstName, employerContactLastName, employerContactEmail,
                //     orgName, orgContactEmail, firstName })
                //   .then((response) => {
                //
                //     if (response.data.success) {
                //       //save values
                //       console.log('Attach to parent worked', response.data);
                //
                //       const tracks = response.data.tracks
                //       trackFunctions = response.data.trackFunctions
                //
                //       this.setState({ tracks, trackFunctions })
                //       return response.data.success
                //     } else {
                //       console.error('there was an error attaching the posting to parent');
                //       return response.data.success
                //     }
                //   }).catch((error) => {
                //       console.log('The job attach to parent did not work', error);
                //       return false
                //   });
                // }

                let parentPostEdits = []
                let passedStateArray = []

                //add events
                if (this.state.hostedEvents) {
                  postings = postings.concat(this.state.hostedEvents)
                }
                //add problems
                if (this.state.problems) {
                  postings = postings.concat(this.state.problems)
                }

                console.log('show postings: ', postings, this.state.hostedEvents)

                if (postings.length === 0) {
                  this.setState({ isSaving: false })
                  window.scrollTo(0, 0)
                } else {
                  postings.forEach(async(item, index) => {
                    console.log('log it once: ', item, index)

                    let passedCustomAssessmentId = null
                    if (item.screeningQuestions && item.screeningQuestions.length > 0) {
                      passedCustomAssessmentId = await saveCustomAssessment(item, index)
                    }

                    let passedBenchmarkId = null
                    if (this.state.createCustomBenchmark) {
                      passedBenchmarkId = await saveCustomBenchmark(item, index)
                    }
                    console.log('show passedBenchmarkId: ', passedBenchmarkId)

                    const passedState = await postIndividualPost(item, index, passedStateArray, passedBenchmarkId, passedCustomAssessmentId)
                    passedStateArray = passedState

                    console.log('show passedState 2', passedState)
                    if (index === postings.length - 1) {
                      this.setState({ isSaving: false })
                      window.scrollTo(0, 0)
                    }

                    if (passedState) {
                      if (passedState.isChild) {
                        parentPostEdits.push(passedState)
                      }
                    }
                  })
                }

                console.log('end save')

              } else {
                console.error('there was an error saving the employer ', response.data.message);
                this.setState({ errorMessage: response.data.message })
              }
            }).catch((error) => {
                console.log('The employer save did not work', error);
            });

          } else {
            console.error('there was an error saving the user account ', response.data.message);
            this.setState({ errorMessage: response.data.message })
          }
        }).catch((error) => {
            console.log('The employer save did not work', error);
        });
      }
    }

    renderPage(pageNumber) {
      console.log('renderPage', pageNumber)

      // const totalPages = 4 + Number(this.state.postCount)

      // const introSummary = 'first second third\n\nfourth'
      // console.log('compare: ', introSummary, this.state.introSummary)

      return (
        <div key={0} className="prewrap">

          {/*
          {(this.state.org !== 'guidedcompass') && (
            <div className="row-10">
              <p className="description-text-2 full-width center-text">{this.state.introSummary}</p>
            </div>
          )}*/}

          <div className="row-20">
            <p className="description-text-2 full-width center-text">Post an opportunity below, or email <label className="bold-text cta-color">help@guidedcompass.com</label> to integrate your Applicant Tracking System (ATS) to automatically distribute all of your postings, and receive referrals for free.</p>
          </div>

          <div className="row-20">
            <div>
              <div>
                <div className="float-left">
                  <p className="heading-text-3">1. Add Postings</p>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="row-10">
                  <p className="center-text">What would you like to post?<label className="error-color bold-text">*</label></p>
                </div>

                <div className="top-padding-5">
                  <div className="relative-column-33 horizontal-padding-4 center-text">
                    <p className="bottom-padding">Career Events</p>
                    <Switch
                      onChange={(change) => this.setState({ showEvents: change, formHasChanged: true })}
                      checked={this.state.showEvents}
                      id="normal-switch"
                    />
                  </div>

                  <div className="relative-column-33 horizontal-padding-4 center-text">
                    <p className="bottom-padding">Projects</p>
                    <Switch
                      onChange={(change) => this.setState({ showProjects: change, formHasChanged: true })}
                      checked={this.state.showProjects}
                      id="normal-switch"
                    />
                  </div>

                  <div className="relative-column-33 horizontal-padding-4 center-text">
                    <p className="bottom-padding">Internships & Work</p>
                    <Switch
                      onChange={(change) => this.setState({ showWork: change, formHasChanged: true })}
                      checked={this.state.showWork}
                      id="normal-switch"
                    />
                  </div>
                  <div className="clear"/>
                </div>
              </div>

              {(this.state.showEvents) && (
                <div className="row-20">
                  <p className="heading-text-4">Post Career Events<label className="error-color bold-text">*</label></p>

                  <div className="row-10">
                    <p className="description-text-2 bottom-padding">Please list any career events (e.g. company tours, workshops, job shadows) you can host that career-seekers may attend?<label className="error-color bold-text">*</label></p>
                  </div>

                  <div>
                    <div>
                      <p className="description-text-2 cta-color">Click the "add" button below to add events.</p>
                      <div className="spacer"/><div className="half-spacer"/>
                    </div>

                    {this.renderItems('hostEvents')}

                    <div className="add-item-line-button">
                      <hr />
                      <a className="background-button" onClick={() => this.addItem('hostEvents')}>
                        <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {(this.state.showProjects) && (
                <div className="row-20">
                  <p className="heading-text-4">Post Projects<label className="error-color bold-text">*</label></p>

                  <div className="row-10">
                    <p className="description-text-2 bottom-padding">Are you interested in posting on our Problem Platform to crowdsource project-based solutions to problems you’re having at the company? <a href="https://www.guidedcompass.com/problem-platform" target="_blank" rel="noopener noreferrer">[Learn more here]</a><label className="error-color bold-text">*</label></p>
                  </div>

                  <div>
                    <div>
                      <p className="description-text-2 cta-color">Please add preliminary ideas for projects below.</p>
                      <div className="spacer" /><div className="half-spacer" />
                    </div>

                    {this.renderItems('problems')}

                    <div className="add-item-line-button">
                      <hr />
                      <a className="background-button" onClick={() => this.addItem('problems')}>
                        <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" />
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {(this.state.showWork) && (
                <div className="row-20">
                  <div className="float-left">
                    <p className="heading-text-4">Post Internships & Work<label className="error-color bold-text">*</label></p>
                  </div>
                  <div className="clear" />

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">How many positions will you post?<label className="error-color bold-text">*</label></label>
                      <select name="postCount" value={this.state.postCount} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.postCountOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.postCount && this.state.postCount > 0) && (
                    <div>
                      {this.renderItems('work')}
                    </div>
                  )}

                </div>
              )}
              {/*
              {(this.state.showMentorships === true) && (
                <div>
                  <p className="heading-text-3">#4: Mentorships<label className="error-color bold-text">*</label></p>
                  <div className="spacer"/>

                  <div className="edit-profile-row">
                    <label className="profile-label">Are there employees willing to mentor students? Our mentor portal allows those mentors to post to the Problem Platform (and ”grade” top submissions), match to students at events, and refer students for roles. <a href="https://www.guidedcompass.com/mentors" target="_blank" rel="noopener noreferrer">[Learn more here]</a><label className="error-color bold-text">*</label></label>
                  </div>

                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                </div>
              )}*/}
            </div>
          </div>

          <hr />

          <div className="row-20">
            <div className="row-10">
              <p className="heading-text-3">2. Add Employer Details</p>
              <p className="description-text-3 top-padding-5">Already have an account? <button className="background-button cta-color bold-text" onClick={() => this.importInfo()} disabled={this.state.isSavingModal} >Import your information</button></p>
            </div>

            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

            {/*
            <div className="spacer" />
            <div className="row-10">
              <div className="standard-border">
                <div className="container-left">
                  <div className="upload-image">
                    <div className="row-10 description-text-2 full-width center-text">
                      <p>Add your logo for light backgrounds</p>
                    </div>

                    <div className="relative-position">
                      <label for="employerLogo" className="profile-pic-button">
                        <img src={ this.state.employerLogoImage ? ( this.state.employerLogoImage )
                          : this.state.employerLogoPath ? ( this.state.employerLogoPath )
                          : ( exampleLogoLight)}
                        alt="Compass add logo" for="employerLogo" className="logo-image-largish center-item"/>
                        <div className="bottom-right-overlay">
                          <div className="bottom-right-subcontainer-2">
                            <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                          </div>
                          <div className="clear" />
                        </div>
                      </label>
                      <input type="file" id="employerLogo" name="employerLogo" onChange={this.formChangeHandler} accept="image/*" />
                    </div>

                    <div className="clear" />

                    <div className="row-10 description-text-4 full-width center-text">
                      <p>Preferred Dimensions: 150 x 150</p>
                    </div>

                    <p className="success-message">{this.state.successMessage}</p>
                    <p className="error-message">{this.state.errorMessage}</p>

                  </div>
                </div>
                <div className="container-right dark-background white-text horizontal-padding-5">
                  <div className="upload-image">
                    <div className="row-10 description-text-2 full-width center-text">
                      <p>Add your logo for dark backgrounds</p>
                    </div>

                    <div className="relative-position">
                      <label for="employerLogoWhite" className="profile-pic-button">
                        <img src={ this.state.employerLogoWhiteImage ? ( this.state.employerLogoWhiteImage )
                          : this.state.employerLogoWhitePath ? ( this.state.employerLogoWhitePath )
                          : ( exampleLogoDark)}
                        alt="Compass add logo" for="employerLogoWhite" className="logo-image-largish center-item" />
                        <div className="bottom-right-overlay">
                          <div className="bottom-right-subcontainer-2">
                            <img src={addIconBlue} alt="Compass add icon" className="image-auto-18 center-item"/>
                          </div>
                          <div className="clear" />
                        </div>
                      </label>
                      <input type="file" id="employerLogoWhite" name="employerLogoWhite" onChange={this.formChangeHandler} accept="image/*" />
                    </div>

                    <div className="clear" />

                    <div className="row-10 description-text-4 full-width center-text">
                      <p>Preferred Dimensions: 150 x 150</p>
                    </div>

                    <p className="success-message">{this.state.successMessage}</p>
                    <p className="error-message">{this.state.errorMessage}</p>

                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
            */}

            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
                <input className="text-field" type="text" name="contactFirstName"
                ref="firstName" placeholder="First Name" value={this.state.contactFirstName}
                onChange={this.formChangeHandler}
                />
              </div>
              <div className="container-right">
                <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
                <input className="text-field" type="text" name="contactLastName"
                  ref="lastName" placeholder="Last Name" value={this.state.contactLastName}
                  onChange={this.formChangeHandler}
                />
              </div>
              <div className="clear"/>
            </div>

            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">Company Name<label className="error-color bold-text">*</label></label>
                <input type="text" className="text-field" placeholder="Add the company name..." name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}></input>
                {(this.state.employers.length > 0) && (
                  <div>
                    <div className="spacer"/>
                    {this.state.employers.map(value =>
                      <div key={value._id} className="left-text bottom-margin-5">
                        <button className="background-button" onClick={() => this.employerClicked(value)}>
                          <div>
                            <div className="float-left right-padding">
                              <img src={employersIconBlue} alt="Compass employer icon icon" className="image-auto-22" />
                            </div>
                            <div className="float-left">
                              <p className="cta-color">{value.employerName}</p>
                            </div>
                          </div>
                        </button>
                      </div>)}
                  </div>
                )}
              </div>
              <div className="container-right">
                <div>
                  <label className="profile-label">Your Position<label className="error-color bold-text">*</label></label>
                  <input type="text" className="text-field" placeholder="Add your title..." name="contactTitle" value={this.state.contactTitle} onChange={this.formChangeHandler}></input>
                </div>

              </div>

              <div className="clear" />
            </div>
            <div>

              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Employer Office Address<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="employerLocation"
                    placeholder="E.g. 123 Fun Rd. Los Angeles, CA 90062" value={this.state.employerLocation}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="container-right">
                  <label className="profile-label">Website<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="employerURL"
                    placeholder="https://..." value={this.state.employerURL}
                    onChange={this.formChangeHandler}
                  />
                  {(this.state.employerURL && this.state.employerURL !== '' && !this.state.employerURL.startsWith('http')) && (
                    <div>
                      <p className="error-message">Please start your link with http</p>
                    </div>
                  )}
                </div>
                <div className="clear" />

              </div>

              <div>
                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Employer Type<label className="error-color bold-text">*</label></label>
                    <select name="employerType" value={this.state.employerType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.typeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Employee Count<label className="error-color bold-text">*</label></label>
                    <select name="employeeCount" value={this.state.employeeCount} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.countOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="row-10">
                  <div className="calc-column-offset-60 right-padding">
                    <label className="profile-label">Employer Industries<label className="error-color bold-text">*</label></label>
                    <select name="employerIndustry" value={this.state.employerIndustry} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.industryOptions.map(value =>
                        <option key={value.key} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="fixed-column-60 top-padding-40">
                    <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <button className="btn btn-squarish" onClick={() => this.addTag('industry')}>Add</button>
                  </div>
                  <div className="clear" />
                </div>

                {this.renderTags('industry')}
                <div className="clear" />

              </div>

              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

              <div className="edit-profile-row">
                <label className="profile-label">Describe the Company</label>
                <textarea type="text" className="text-field" placeholder="…describe your company does and stands for..." name="employerDescription" value={this.state.employerDescription} onChange={this.formChangeHandler}></textarea>
              </div>

            </div>

            <div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Your Email<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="email" name="contactEmail"
                    ref="email" placeholder="Email" value={this.state.contactEmail}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="name-container">
                  <label className="profile-label">Your Phone Number<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" name="contactPhone"
                    placeholder="Phone Number" value={this.state.contactPhone}
                    onChange={this.formChangeHandler}
                  />
                </div>
                <div className="clear" />
              </div>
            </div>

            <div className="row-10">
              <div>
                <label className="profile-label">If someone referred you, indicate who it was below:</label>
                <input type="text" className="text-field" placeholder="Org name..." name={"referrerName"} value={this.state.referrerName} onChange={this.formChangeHandler}></input>
                {/*
                <select name="referrerName" value={this.state.referrerName} onChange={this.formChangeHandler} className="dropdown">
                  {this.state.referrerOptions.map(value =>
                    <option key={value.orgName} value={value.orgName}>{value.orgName}</option>
                  )}
                </select>*/}
              </div>
              <div className="clear" />
            </div>

            <div className="spacer" /><div className="spacer" />
          </div>

          <hr />

          <div className="row-20">

            <div className="row-20">
              <p className="heading-text-3">3. Create a Password</p>

              <div className="row-10">
                <p className="description-text-2">Please create a password so that you can edit your postings and view candidates {this.state.orgName} refers to you.</p>
              </div>

              <div className="row-10">
                <label className="profile-label">Password<label className="error-color bold-text">*</label></label>
                <input className="text-field full-width height-38" type="Password" name="password"
                  ref="password" placeholder="password" value={this.state.password}
                  onChange={this.formChangeHandler}
                />
              </div>
            </div>

            <div className="half-spacer"/>

            {(this.state.successMessage && this.state.successMessage !== '') && (<p className="full-width center-text cta-color description-text-2 bottom-padding">{this.state.successMessage}</p>)}
            {(this.state.errorMessage && this.state.errorMessage !== '') && (<p className="full-width center-text error-color description-text-2 bottom-padding">{this.state.errorMessage}</p>)}

            <div>
              <div className="full-width center-text">
                <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.postOpportunities()}>Submit Responses</button>
              </div>
            </div>

          </div>

        </div>
      )
    }

    addItem(type, value, index) {
      console.log('addItem called', type, value, index)

      if (type === 'hostEvents') {
        console.log('test 1')
        let hostedEvents = this.state.hostedEvents
        hostedEvents.push({ postType: 'Event', title: '', eventType: '', location: '', startDate: '' })
        console.log('test 2', hostedEvents)
        this.setState({ hostedEvents })
      } else if (type === 'problems') {
        let problems = this.state.problems
        problems.push({ name: '', postType: 'Project', duration: '', tags: '' })
        console.log('test 2', problems)
        this.setState({ problems })
      } else if (type === 'success') {
        let successMetrics = this.state.successMetrics
        if (successMetrics.includes(value)) {
          const index = successMetrics.indexOf(value)
          if (index > -1) {
            successMetrics.splice(index, 1);
          }
        } else {
          successMetrics.push(value)
        }

        this.setState({ successMetrics })
      } else if (type === 'timeframe') {
        let hireTimeframes = this.state.hireTimeframes
        if (hireTimeframes.includes(value)) {
          const index = hireTimeframes.indexOf(value)
          if (index > -1) {
            hireTimeframes.splice(index, 1);
          }
        } else {
          hireTimeframes.push(value)
        }

        this.setState({ hireTimeframes })
      } else if (type === 'screeningQuestion') {
        let postings = this.state.postings
        let screeningQuestions = postings[index].screeningQuestions
        if (screeningQuestions) {
          screeningQuestions.push({ name: '', questionType: 'Multiple Choice', screeningAnswerChoices: []})
        } else {
          screeningQuestions = [{ name: '', questionType: 'Multiple Choice', screeningAnswerChoices: []}]
        }

        postings[index]['screeningQuestions'] = screeningQuestions
        this.setState({ postings })

      } else if (type === 'candidatesOfInterest') {
        let postings = this.state.postings
        let candidatesOfInterest = postings[index].candidatesOfInterest
        if (candidatesOfInterest) {
          candidatesOfInterest.push({ firstName: '', lastName: '', email: '' })
        } else {
          candidatesOfInterest = [{ firstName: '', lastName: '', email: '' }]
        }

        postings[index]['candidatesOfInterest'] = candidatesOfInterest
        this.setState({ postings })

      }
    }

    renderItems(type) {
      console.log('renderItems called' , type)

      let rows = []
      if (type === 'hostEvents') {
        console.log('how about those events', this.state.hostedEvents)
        for (let i = 1; i <= this.state.hostedEvents.length; i++) {
          console.log('show event: ', this.state.hostedEvents[i - 1])

          if (this.state.hostedEvents[i - 1]) {
            console.log('again?')
            const index = i - 1

            rows.push(
              <div key={i.toString() + 'hostEvents'} className="padding-20 standard-border bottom-margin">
                <div className="bottom-padding-20">
                  {(this.state.hostedEvents[i - 1].title === '') ? (
                    <p className="heading-text-4">Hosted Event #{i}</p>
                  ) : (
                    <p className="heading-text-4">Hosted Event #{i}: {this.state.hostedEvents[i - 1].title}</p>
                  )}
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Name of Event<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Event title..." name={"eventTitle|" + index} value={this.state.hostedEvents[i - 1].title} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Event Type<label className="error-color bold-text">*</label></label>
                    <select name={"eventType|" + index} value={this.state.hostedEvents[i - 1].eventType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.eventTypeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Event Location<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Full address. or web link.." name={"eventLocation|" + index} value={this.state.hostedEvents[i - 1].location} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Event Start Date<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Add the start date..." name={"eventStartDate|" + index} value={this.state.hostedEvents[i - 1].startDate} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                <label className="profile-label">Event Summary<label className="error-color bold-text">*</label></label>
                <textarea type="text" className="text-field" placeholder="Add a brief summary of what you intend to post..." name={"eventSummary|" + index} value={this.state.hostedEvents[i - 1].description} onChange={this.formChangeHandler}></textarea>
                </div>
              </div>
            )
          }
        }
      } else if (type === 'attendEvents') {

        if (!this.state.eventsToAttend || this.state.eventsToAttend.length === 0) {
          rows.push(
            <div key="attendEvents">
              <p className="error-message">Great, there are currently no upcoming events for you to attend. We will notify you when we are hosting an event that may be of interest!</p>
            </div>
          )
        }
      } else if (type === 'problems') {
        for (let i = 1; i <= this.state.problems.length; i++) {
          console.log('show event: ', this.state.problems[i - 1])

          if (this.state.problems[i - 1]) {
            console.log('again?')
            const index = i - 1

            rows.push(
              <div key={i.toString() + 'problem'} className="padding-20 standard-border bottom-margin">
                <div className="bottom-padding-20">
                  {(this.state.problems[i - 1].name === '') ? (
                    <p className="heading-text-4">Post #{i}</p>
                  ) : (
                    <p className="heading-text-4">Post #{i}: {this.state.problems[i - 1].name}</p>
                  )}
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Name of Project Prompt<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Problem name..." name={"problemName|" + index} value={this.state.problems[i - 1].name} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Type of Prompt<label className="error-color bold-text">*</label></label>
                    <select name={"projectPromptType|" + index} value={this.state.problems[i - 1].projectPromptType} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.projectPromptTypeOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Duration (e.g., Hours to Solve)<label className="error-color bold-text">*</label></label>
                    <select name={"problemDuration|" + index} value={this.state.problems[i - 1].duration} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.durationOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Tags (e.g., Skills)<label className="error-color bold-text">*</label></label>
                    <input type="text" className="text-field" placeholder="Add tags..." name={"problemTags|" + index} value={this.state.problems[i - 1].tags} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <div className="name-container">
                    <label className="profile-label">Submission Deadline<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker" placeholder="Add the submission deadline..." min={convertDateToString(new Date(),"rawDateForInput")} name={"problemDeadline|" + index} value={this.state.problems[i - 1].submissionDeadline} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Knowledge Level<label className="error-color bold-text">*</label></label>
                    <select name={"problemKnowledgeLevel|" + index} value={this.state.problems[i - 1].knowledgeLevel} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.knowledgeLevelOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div>
                  <label className="profile-label">Summary<label className="error-color bold-text">*</label></label>
                  <textarea type="text" className="text-field" placeholder="Add a brief summary of what you intend to post..." name={"problemDescription|" + index} value={this.state.problems[i - 1].description} onChange={this.formChangeHandler}></textarea>
                </div>
              </div>
            )
          }
        }
      } else if (type === 'work') {
        for (let i = 1; i <= this.state.postCount; i++) {

          const index = i - 1
          console.log('see index: ', index)

          rows.push(
            <div key={"work|" + i}>
              <div className="padding-20 standard-border bottom-margin">
                <div className="row-20">
                  <p className="heading-text-3">Position #{i} of {Number(this.state.postCount)}</p>
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Position Title<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Add the job title..." name={"work|title|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].title} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="container-right">
                      <label className="profile-label">Position Type<label className="error-color bold-text">*</label></label>
                      <select name={"work|subPostType|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].subPostType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.workTypeOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <div className="float-left">
                        <label className="profile-label">Work Function<label className="error-color bold-text">*</label></label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: true, showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: false, login: false, showInfoCollected: false })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />

                      <select name={"work|function|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].function} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.functionOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                      {(!this.state.hideProfileCard && this.state.function && this.state.function !== '') && (
                        <div className="top-padding-5">
                          <button className="background-button description-text-1 bold-text cta-color" onClick={() => window.scrollTo(0,document.body.scrollHeight)}>Click Here to Edit Your Ideal Candidate Below</button>
                        </div>
                      )}
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Hours per Week<label className="error-color bold-text">*</label></label>
                      <select name={"work|hoursPerWeek|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].hoursPerWeek} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.hoursPerWeekOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.postings && this.state.postings[i - 1].competencyTags) && (
                    <div className="row-10">
                      <div className="row-10">
                        <div className="float-left right-padding">
                          <p className="description-text-4">SKILL & KNOWLEDGE TAGS</p>
                        </div>
                        <div className="float-left right-padding top-margin-negative-4">
                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: true, showSubsidyInfo: false, showSchoolCreditInfo: false, selectedIndex: index, login: false, showInfoCollected: false })}>
                              <div className="padding-5 cta-border circle-radius">
                                <img src={addIconBlue} alt="GC" className="image-auto-10" />
                              </div>
                          </button>
                        </div>
                        <div className="clear" />
                      </div>

                      {this.renderTags('competency', index)}

                      <div className="clear" />
                    </div>
                  )}

                  <div className="container-left">
                    <label className="profile-label">Position Location Type<label className="error-color bold-text">*</label></label>
                    <select name={"work|location|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].location} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.locationOptions.map(value =>
                        <option key={value.key} value={value}>{value}</option>
                      )}
                    </select>
                  </div>

                  {(this.state.postings && this.state.postings[i - 1].location === 'One Location') && (
                    <div>
                      <div className="container-right">
                        <label className="profile-label">Position Zip Code<label className="error-color bold-text">*</label></label>
                        <input type="number" className="number-field" max="99999" placeholder="90210" name={"work|zipcode|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].zipcode} onChange={this.formChangeHandler}></input>
                      </div>
                    </div>
                  )}

                  <div className="clear" />

                  <div>
                    <div className="row-5">
                      <div className="anonymous-checkmark">
                        <input type="checkbox" name={"work|isPerpetual|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].isPerpetual} checked={(this.state.postings[i - 1]) && this.state.postings[i - 1].isPerpetual} onChange={this.formChangeHandler} />
                      </div>
                      <div className="anonymous-descriptor">
                        <label className="description-text-1">This role is continual</label>
                      </div>
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Ideal Start Date<label className="error-color bold-text">*</label></label>
                      {(this.state.preSetStartDate) ? (
                        <p>{this.state.preSetStartDate}</p>
                      ) : (
                        <input type="date" className="date-picker" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Add the start date..." name={"work|startDate|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].startDate} onChange={this.formChangeHandler}></input>
                      )}

                    </div>

                    <div className="container-right">
                      {(this.state.postings[index] && this.state.postings[index].isPerpetual) ? (
                        <div>
                          <label className="profile-label">Ideal End Date</label>
                          <label className="heading-text-6">Continual</label>
                        </div>
                      ) : (
                        <div>
                          <label className="profile-label">Ideal End Date<label className="error-color bold-text">*</label></label>
                          {(this.state.preSetEndDate) ? (
                            <p>{this.state.preSetEndDate}</p>
                          ) : (
                            <input type="date" className="date-field" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Add the end date..." name={"work|endDate|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].endDate} onChange={this.formChangeHandler}></input>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <label className="profile-label">Position Description<label className="error-color bold-text">*</label></label>
                    <textarea type="text" className="text-field" placeholder="Copy and paste a position description..." name={"work|description|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].description} onChange={this.formChangeHandler}></textarea>
                  </div>

                  {(this.state.postings[index] && this.state.postings[index].subPostType && this.state.postings[index].subPostType !== '' && this.state.postings[index].subPostType !== 'Volunteer') && (
                    <div>
                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Pay Type<label className="error-color bold-text">*</label></label>
                          <select name={"work|payType|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].payType} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.payTypeOptions.map(value =>
                              <option key={value.key} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        {((this.state.postings[index]) && (this.state.postings[index].payType === 'Hourly' || this.state.postings[index].payType === 'Salary')) && (
                          <div className="container-right">
                            <label className="profile-label">Pay Range<label className="error-color bold-text">*</label></label>
                            {(this.state.postings[index].payType === 'Hourly') ? (
                              <select name={"work|payRange|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].payRange} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.hourlyPayOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            ) : (
                              <select name={"work|payRange|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].payRange} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.annualPayOptions.map(value =>
                                  <option key={value.key} value={value}>{value}</option>
                                )}
                              </select>
                            )}
                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      {(this.state.postings[index] && this.state.postings[index].subPostType === 'Internship') && (
                        <div>
                          <div className="row-10">
                            <div className="container-left">
                              <div className="calc-column-offset-33">
                                <label className="profile-label">Prioritize Subsidized Candidates</label>
                              </div>
                              <div className="fixed-column-33">
                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <div className="float-left left-margin noti-bubble-info-7-9">
                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false, showSubsidyInfo: true, showSchoolCreditInfo: false, login: false, showInfoCollected: false })}>
                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                  </a>
                                </div>

                              </div>
                              <div className="clear" />
                              <Switch
                                onChange={(change) => this.switchTapped("prioritizeSubsidized", change, index)}
                                checked={this.state.postings[index] && this.state.postings[index].prioritizeSubsidized}
                                id="normal-switch"
                              />
                            </div>

                            <div className="container-right">
                              <div className="calc-column-offset-33">
                                <label className="profile-label">Prioritize School Credit Candidates</label>
                              </div>
                              <div className="fixed-column-33">
                                <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                <div className="float-left left-margin noti-bubble-info-7-9">
                                  <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: true, login: false, showInfoCollected: false })}>
                                    <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                                  </a>
                                </div>

                              </div>
                              <div className="clear" />
                              <Switch
                                onChange={(change) => this.switchTapped("prioritizeSchoolCredit", change, index)}
                                checked={this.state.postings[index] && this.state.postings[index].prioritizeSchoolCredit}
                                id="normal-switch"
                              />
                            </div>

                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {(this.state.postings[index]) && (
                        <div>
                          {(this.state.postings[index].subPostType === 'Part-Time' || this.state.postings[index].subPostType === 'Full-Time') && (
                            <div>
                              <div>
                                <div className="row-10">
                                  <div className="calc-column-offset-60 right-padding">
                                    <label className="profile-label">Add any supplemental pay (Optional):</label>
                                    <select name={"work|supplementalPay|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].supplementalPay} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.supplementalPayOptions.map(value =>
                                        <option key={value.key} value={value}>{value}</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="fixed-column-60 top-padding-40">
                                    <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <button className="btn btn-squarish" onClick={() => this.addTag('supplementalPay', index)}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {this.renderTags('supplementalPay', index)}
                                <div className="clear" />
                              </div>

                              <div>
                                <div className="row-10">
                                  <div className="calc-column-offset-60 right-padding">
                                    <label className="profile-label">Add any benefits (Optional):</label>
                                    <select name={"work|benefit|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].benefit} onChange={this.formChangeHandler} className="dropdown">
                                      {this.state.benefitOptions.map(value =>
                                        <option key={value.key} value={value}>{value}</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="fixed-column-60 top-padding-40">
                                    <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                                    <button className="btn btn-squarish" onClick={() => this.addTag('benefit', index)}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>

                                {this.renderTags('benefit', index)}
                                <div className="clear" />
                              </div>

                              {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                              {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}
                            </div>
                          )}

                        </div>
                      )}

                    </div>
                  )}

                  <div className="edit-profile-row">
                    <div className="container-left">
                      <label className="profile-label">How many people will you hire?<label className="error-color bold-text">*</label></label>
                      <select name={"work|hireCount|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].hirecount} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.postCountOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>

                    <div className="container-right">
                      <div className="float-left">
                        <label className="profile-label">Application Deadline<label className="error-color bold-text">*</label></label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: true, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: false, login: false, showInfoCollected: false })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>
                      </div>

                      <div className="clear" />

                      <input type="date" className="date-field" min={convertDateToString(new Date(),"rawDateForInput")} placeholder="Application deadline..." name={"work|submissionDeadline|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].submissionDeadline} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />
                  </div>

                  <div>
                    <div className="container-left">
                      <label className="profile-label">Primary Industry<label className="error-color bold-text">*</label></label>
                      <select name={"work|industry|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].industry} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.industryOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">U.S. documented workers only?<label className="error-color bold-text">*</label></label>
                      <select name={"work|hostUndocumented|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].hostUndocumented} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.binaryOptions.map(value =>
                          <option key={value} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className={(window.innerWidth < 767) ? "calc-column-offset-40" : "float-left right-padding"}>
                      <label className="profile-label">Add Screening Questions (Optional)</label>
                    </div>
                    <div className={(window.innerWidth < 767) ? "fixed-column-40 top-padding" : "float-left top-padding"}>
                      <button className="background-button" onClick={() => this.addItem('screeningQuestion',null,index)}>
                          <div className="padding-5 cta-border circle-radius">
                            <img src={addIconBlue} alt="GC" className="image-auto-15" />
                          </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-2">Add up to 10 screening questions. <button className="background-button cta-color bold-text" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: false, login: false, showInfoCollected: true })}>Click Here</button> for information we already collect.</p>
                  </div>

                  <div className="spacer" />

                  {this.renderQuestions('screeningQuestions',index)}

                  <div className="spacer" />

                  <div className="row-10">
                    <div className={(window.innerWidth < 767) ? "calc-column-offset-40" : "float-left right-padding"}>
                      <label className="profile-label">Tag Candidates of Interest (Optional)</label>
                    </div>
                    <div className={(window.innerWidth < 767) ? "fixed-column-40 top-padding" : "float-left top-padding"}>
                      <button className="background-button" onClick={() => this.addItem('candidatesOfInterest',null,index)}>
                          <div className="padding-5 cta-border circle-radius">
                            <img src={addIconBlue} alt="GC" className="image-auto-15" />
                          </div>
                      </button>
                    </div>
                    <div className="clear" />
                    <p className="description-text-2">Add up to 10 candidates you would like to invite to apply to this posting.</p>
                  </div>

                  <div className="spacer" />

                  {this.renderQuestions('candidatesOfInterest',index)}

                  <div className="spacer" />

                  <div className="spacer" /><div className="spacer" />

                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />

                  <p className="heading-text-4">Application Method Details<label className="error-color bold-text">*</label></p>
                  <div className="spacer"/>

                  <div className="edit-profile-row">
                    <label className="profile-label">What is your preferred method for receiving applications?</label>
                    <select name={"work|applicationMethod|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].applicationMethod} onChange={this.formChangeHandler} className="dropdown">
                      {this.state.applicationMethodOptions.map(value =>
                        <option key={value} value={value}>{value}</option>
                      )}
                    </select>
                  </div>

                  <div className="edit-profile-row bottom-padding-0">
                    <div className="name-container">
                      <label className="profile-label">Contact First Name:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="First name..." name={"work|employerContactFirstName|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].employerContactFirstName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Contact Last Name:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Last name..." name={"work|employerContactLastName|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].employerContactLastName} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="edit-profile-row bottom-padding-0">
                    <div className="name-container">
                      <label className="profile-label">Contact Email:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Email address..." name={"work|employerContactEmail|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].employerContactEmail} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="name-container">
                      <div className="float-left">
                        <label className="profile-label">Application components:</label>
                      </div>
                      <div>
                        <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                        <div className="float-left left-margin noti-bubble-info-7-9">
                          <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: true, politicalPartyInfo: false, functionInfo: false, showTags: false })}>
                            <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                          </a>
                        </div>

                      </div>
                      <div className="clear" />
                      <input type="text" className="text-field" placeholder="e.g. resume, cover letter, writing samples, letters of recommendation..." name={"work|appRequirements|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].appRequirements} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="clear" />
                  </div>

                  {(this.state.postings[i - 1] && this.state.postings[i - 1].applicationMethod === 'Applicants may be referred, but they must also apply via email') && (
                    <div className="edit-profile-row bottom-padding-0">
                      <label className="profile-label">Subject line:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Please write the desired subject line..." name={"work|subjectLine|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].subjectLine} onChange={this.formChangeHandler}></input>
                    </div>
                  )}
                  {(this.state.postings[i - 1] && this.state.postings[i - 1].applicationMethod === 'Applicants may be referred, but they must also apply via our website posting') && (
                    <div className="edit-profile-row bottom-padding-0">
                      <label className="profile-label">Web link to posting:<label className="error-color bold-text">*</label></label>
                      <input type="text" className="text-field" placeholder="Please add the link to the opening..." name={"work|jobLink|" + index} value={(this.state.postings[i - 1]) && this.state.postings[i - 1].jobLink} onChange={this.formChangeHandler}></input>
                      {(this.state.jobLink && this.state.jobLink !== '' && !this.state.jobLink.startsWith('http')) && (
                        <div>
                          <p className="error-message">Please start your link with http</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }
      }
      console.log('show rows: ', rows)
      return rows
    }

    renderQuestions(type, parentIndex) {
      console.log('renderQuestions called', type, parentIndex)

      if (type === 'screeningQuestions') {
        let rows = [];
        console.log('show the postings: ', this.state.postings)

        if (this.state.postings && this.state.postings[parentIndex] && this.state.postings[parentIndex].screeningQuestions) {
          const questions = this.state.postings[parentIndex].screeningQuestions
          for (let i = 1; i <= questions.length; i++) {
            console.log(i);

            const index = i - 1

            // let eventName = "question" + i.toString()
            // let typeName = "type" + i.toString()

            rows.push(
              <div key={i}>
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('question', parentIndex, index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding-5">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Question #{i}</p>
                    </div>

                    <div className="calc-column-offset-140 right-padding row-5">
                      <input type="text" className="text-field" placeholder="Write a question..." name={"screeningQuestion|name|" + parentIndex + "|" + index} value={questions[i - 1].name} onChange={this.formChangeHandler}></input>
                    </div>
                    <div className="fixed-column-140 row-5">
                      <select name={"screeningQuestion|questionType|" + parentIndex + "|" + index} value={questions[i - 1].questionType} onChange={this.formChangeHandler} className="dropdown">
                        {this.state.questionTypeOptions.map(value =>
                          <option key={value.key} value={value}>{value}</option>
                        )}
                      </select>
                    </div>
                    <div className="clear"/>

                    {(questions[i - 1].questionType === 'Multiple Choice'  || questions[i - 1].questionType === 'Checkbox' || questions[i - 1].questionType === 'Ranking') ? (
                      <div className="top-padding">

                        {(questions[i - 1].screeningAnswerChoices && questions[i - 1].screeningAnswerChoices.length > 0) && (
                          <div>
                            <div className="row-5 description-text-5 bold-text">
                              <div className="fixed-column-30">
                                <p> </p>
                              </div>
                              <div className="calc-column-offset-80 right-padding">
                                <p>ANSWER CHOICE</p>
                              </div>
                              <div className="fixed-column-50">
                                <p className="full-width center-text">POINTS</p>
                              </div>
                              <div className="clear" />
                            </div>

                            {this.renderAnswerChoices(i - 1, questions[i - 1].screeningAnswerChoices, parentIndex)}
                          </div>
                        )}

                        <div>
                          <div className="add-item-line-button">
                            <hr />
                            <a className="background-button add-task-link-container" onClick={() => this.addAnswerChoice(parentIndex, i - 1)}>
                              <img src={addCircleOutlineIcon} alt="Compass add circle outline icon" className="add-task" />
                            </a>
                          </div>
                          <div className="clear" />
                        </div>

                      </div>
                    ) : (
                      <div>
                        {(questions[i - 1].questionType === 'Long Answer') ? (
                          <p className="fake-text-area">Candidates will write long answer here...</p>
                        ) : (
                          <p className="fake-text-field">Candidates will write {questions[i - 1].questionType.toLowerCase()} here...</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            )
          }
        }

        return rows;

      } else if (type === 'candidatesOfInterest') {

        let rows = [];
        console.log('show the postings: ', this.state.postings)

        if (this.state.postings && this.state.postings[parentIndex] && this.state.postings[parentIndex].candidatesOfInterest) {
          const candidatesOfInterest = this.state.postings[parentIndex].candidatesOfInterest
          for (let i = 1; i <= candidatesOfInterest.length; i++) {
            console.log(i);

            const index = i - 1

            // let eventName = "candidatesOfInterest" + i.toString()
            // let typeName = "type" + i.toString()

            rows.push(
              <div key={i}>
                <div className="close-button-container-2" >
                  <button type="button" onClick={() => this.deleteItem('candidatesOfInterest', parentIndex, index)} className="background-button">
                    <img src={deniedIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>

                <div className="left-padding-5">
                  <div className="highlighted-container">
                    <div className="row-5">
                      <p>Candidate #{i}</p>
                    </div>

                    <div className="row-5">
                      <div className="container-left">
                        <input type="text" className="text-field" placeholder="First name..." name={"candidatesOfInterest|firstName|" + parentIndex + "|" + index} value={candidatesOfInterest[i - 1].firstName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="container-right">
                        <input type="text" className="text-field" placeholder="Last name..." name={"candidatesOfInterest|lastName|" + parentIndex + "|" + index} value={candidatesOfInterest[i - 1].lastName} onChange={this.formChangeHandler}></input>
                      </div>
                      <div className="clear" />
                    </div>

                    <div className="row-5">
                      <input type="text" className="text-field" placeholder="Email..." name={"candidatesOfInterest|email|" + parentIndex + "|" + index} value={candidatesOfInterest[i - 1].email} onChange={this.formChangeHandler}></input>
                    </div>

                    <div className="clear" />

                  </div>
                </div>



              </div>
            )
          }
        }

        return rows;
      }
    }

    switchTapped(name, value, index) {
      console.log('switchTapped called', name, value, index)

      let postings = this.state.postings
      postings[index][name] = value
      this.setState({ postings })
    }

    renderAnswerChoices(questionIndex, answerChoices, parentIndex) {

      let rows = []
      for (let i = 1; i <= answerChoices.length; i++) {
        console.log(i);

        // let key = "answerChoice" + iCount.toString() + "|" + i.toString()
        // let eventName = "answerChoice" + iCount.toString() +  "|" +  i.toString()
        const answerChoiceIndex = i - 1

        rows.push(
          <div key={"screeningAnswerChoice|" + i} className="row-5">
            <div className="fixed-column-30">
              <button type="button" onClick={() => this.deleteItem('answerChoice', parentIndex, questionIndex, answerChoiceIndex)} className="background-button">
                <img src={deniedIcon} className="answer-bubble" alt="Compass close icon icon" />
              </button>
            </div>
            <div className="calc-column-offset-80 right-padding">
              <input type="text" className="text-field height-40" placeholder="Write an answer choice..." name={"answerChoice|name|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].name} onChange={this.formChangeHandler}></input>
            </div>
            <div className="fixed-column-50">
              <input type="number" className="number-field cta-color" min="0" max="5" placeholder="0" name={"answerChoice|score|" + parentIndex + "|" + questionIndex + "|" + answerChoiceIndex} value={answerChoices[i - 1].score} onChange={this.formChangeHandler}></input>
            </div>
            <div className="clear" />
          </div>
        )
      }

      return rows
    }

    addAnswerChoice(parentIndex, index) {
      console.log('addAnswerChoice called', parentIndex, index)

      let screeningAnswerChoices = this.state.postings[parentIndex].screeningQuestions[index].screeningAnswerChoices
      screeningAnswerChoices.push({ name: '', score: '' })

      let screeningQuestions = this.state.postings[parentIndex].screeningQuestions
      screeningQuestions[index] = { name: this.state.postings[parentIndex].screeningQuestions[index].name, questionType:this.state.postings[parentIndex].screeningQuestions[index].questionType, screeningAnswerChoices }

      let postings = this.state.postings
      postings[parentIndex]['screeningQuestions'] = screeningQuestions

      this.setState({ postings })
    }

    deleteItem(itemType, parentIndex, questionIndex, answerChoiceIndex) {
      console.log('deleteItem called', itemType, parentIndex, questionIndex, answerChoiceIndex)

      if (itemType === 'question') {
        let postings = this.state.postings
        let screeningQuestions = this.state.postings[parentIndex].screeningQuestions
        screeningQuestions.splice(questionIndex, 1)
        postings[parentIndex]['screeningQuestions'] = screeningQuestions
        this.setState({ postings })
      } else if (itemType === 'answerChoice') {
        let postings = this.state.postings
        let screeningAnswerChoices = this.state.postings[parentIndex].screeningQuestions[questionIndex].screeningAnswerChoices
        screeningAnswerChoices.splice(answerChoiceIndex,1)

        postings[parentIndex]['screeningQuestions'][questionIndex]['screeningAnswerChoices'] = screeningAnswerChoices
        this.setState({ postings })

      } else if (itemType === 'prompt') {
        // let questions = this.state.questions
        // delete questions[index].prompt
        // this.setState({ questions, formHasChanged: true })
      } else if (itemType === 'candidatesOfInterest') {
        let postings = this.state.postings
        let candidatesOfInterest = this.state.postings[parentIndex].candidatesOfInterest
        candidatesOfInterest.splice(questionIndex, 1)
        postings[parentIndex]['candidatesOfInterest'] = candidatesOfInterest
        this.setState({ postings })
      }
    }

    renderConfirmationPage() {
      console.log('renderConfirmationPage called', this.state.accountCode)

      let ac = ''
      if (this.state.accountCode && this.state.accountCode !== '') {
        ac = this.state.accountCode.toLowerCase()
      }
      const dashboardLink = "/employers/" + ac + "/dashboard"

      let postings = []
      if (this.state.postings && this.state.postings.length > 0) {
        postings = this.state.postings
      }
      //add events
      if (this.state.hostedEvents) {
        postings = postings.concat(this.state.hostedEvents)
      }
      //add problems
      if (this.state.problems) {
        postings = postings.concat(this.state.problems)
      }
      console.log('show the postings: ', this.state.postings)

      return (
        <div key="confirmationPage">
          <p className="row-5">{this.state.confirmationMessage}</p>

          <div className="row-20">
            <p className="heading-text-3">1. Share</p>
            <div className="row-5 description-text-1">
              <p>Have candidates in mind? Share links to your postings:</p>
            </div>

            {(postings) && (
              <ul className="left-padding-20 description-text-1">
                {postings.map((value, optionIndex) =>
                  <div key={"posting" + optionIndex}>
                    <li><label className="bold-text">{(value.title) ? value.title : value.name}</label>: <a href={"https://www.guidedcompass.com/opportunities/" + value._id} target="_blank" rel="noopener noreferrer" className="wrap-word">https://www.guidedcompass.com/opportunities/{value._id}</a></li>
                  </div>
                )}
              </ul>
            )}

            <div className="top-padding-20 description-text-1">
              <label className="bold-text">Share all your postings</label>: <a href={"https://www.guidedcompass.com/opportunities/employers/" + this.state.accountCode} target="_blank" rel="noopener noreferrer" className="wrap-word">https://www.guidedcompass.com/opportunities/employers/{this.state.accountCode}</a>
            </div>

          </div>

          <div className="row-20">
            <p className="heading-text-3">2. Edit & Evaluate</p>
            <div className="row-5 description-text-1">
              <p>If you need to edit posts, evaluate candidates, or build talent pipelines, you can do so in your portal:</p>
            </div>
            <a href={"https://www.guidedcompass.com/employers/" + this.state.accountCode + "/dashboard"} target="_blank" rel="noopener noreferrer" className="description-text-1 wrap-word">https://www.guidedcompass.com/employers/{this.state.accountCode}/dashboard</a>
          </div>

          <div className="row-15 description-text-1">
            <p>Feel free to reach out to <label className="bold-text">help@guidedcompass.com</label> with any questions.</p>
          </div>


          <div className="row-20 center-text">
            <Link to={dashboardLink} className="btn btn-primary cta-background-color white-text wrap-word">View Dashboard</Link>
          </div>

        </div>
      )
    }

    saveCustomBenchmark = () => {
      console.log('saveCustomBenchmark called')

      this.child.current.saveCustomBenchmark()

    };

    closeModal() {
      this.setState({ modalIsOpen: false });
    }

    passBenchmark = (customBenchmarkId) => {
      console.log('passBenchmark called ', customBenchmarkId)
      this.setState({ customBenchmarkId })
    }

    renderTags(type, index) {
      console.log('renderTags called', type, index)

      if (type === 'competency') {

        let competencyTags = []
        if (this.state.postings[index] && this.state.postings[index].competencyTags) {
          competencyTags = this.state.postings[index].competencyTags
        }

        return (
          <div key="tags">
            {competencyTags.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type, index)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  {(value.category === 'Ability') && (
                    <div className="tag-container-8 primary-background primary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Knowledge') && (
                    <div className="tag-container-8 secondary-background secondary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Soft Skill') && (
                    <div className="tag-container-8 tertiary-background tertiary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  {(value.category === 'Hard Skill') && (
                    <div className="tag-container-8 quaternary-background quaternary-border white-text">
                      <p className="description-text-2">{value.name}{(value.description) && " (" + value.description + ")"}</p>
                    </div>
                  )}
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      } else if (type === 'industry') {
        return (
          <div key="tags">
            {this.state.industries.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  <div className="tag-container-8 primary-background primary-border white-text">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      } else if (type === 'supplementalPay') {

        let supplementalPayArray = []
        if (this.state.postings[index] && this.state.postings[index].supplementalPayArray) {
          supplementalPayArray = this.state.postings[index].supplementalPayArray
        }

        return (
          <div key="tags">
            {supplementalPayArray.map((value, optionIndex) =>
              <div key={"tag" + index} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(index, type, optionIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  <div className="tag-container-8 quaternary-background quaternary-border white-text">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      } else if (type === 'benefit') {

        let benefits = []
        if (this.state.postings[index] && this.state.postings[index].benefits) {
          benefits = this.state.postings[index].benefits
        }

        return (
          <div key="tags">
            {benefits.map((value, optionIndex) =>
              <div key={"tag" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" onClick={() => this.removeTag(optionIndex, type, index)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div>
                  <div className="half-spacer" />
                  <div className="tag-container-8 tertiary-background tertiary-border white-text">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}
          </div>
        )
      }
    }

    addTag(type, index) {
      console.log('addTag called')

      this.setState({ errorMessage: null, successMessage: null })

      if (type === 'competency') {
        let postings = this.state.postings
        let competencyTags = postings[index].competencyTags
        let tagName = postings[index].tagName
        let tagType = postings[index].tagType

        if (!tagName || tagName === '') {
          this.setState({ errorMessage: 'Please add a tag name'})
        } else if (!tagType || tagType === '') {
          this.setState({ errorMessage: 'Please add a tag type'})
        } else if (competencyTags && competencyTags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase())) {
          this.setState({ errorMessage: 'This tag has already been added'})
        } else {

          let score = 5
          let weight = 0

          if (competencyTags) {
            for (let i = 1; i <= competencyTags.length; i++) {
              if (competencyTags[i - 1].score) {
                score = competencyTags[i - 1].score
              }
              if (competencyTags[i - 1].weight) {
                weight = competencyTags[i - 1].weight
              }
            }
            competencyTags.push({ name: postings[index].tagName, category: postings[index].tagType, description: '', score, weight  })
          } else {
            competencyTags = [{ name: postings[index].tagName, category: postings[index].tagType, description: '', score, weight }]
          }

          postings[index]['competencyTags'] = competencyTags
          postings[index]['tagName'] = ''
          postings[index]['tagType'] = ''
          this.setState({ postings })
        }
      } else if (type === 'industry') {
        let industries = this.state.industries
        if (!this.state.employerIndustry || this.state.employerIndustry === '') {
          this.setState({ errorMessage: 'Please add an industry name'})
        } else if (industries.includes(this.state.employerIndustry)) {
          this.setState({ errorMessage: 'This industry has already been added'})
        } else {

          industries.push(this.state.employerIndustry)
          this.setState({ industries, employerIndustry: '' })
        }
      } else if (type === 'supplementalPay') {
        let postings = this.state.postings
        let supplementalPayArray = postings[index].supplementalPayArray
        let supplementalPay = ''
        if (postings[index].supplementalPay) {
          supplementalPay = postings[index].supplementalPay
        }

        if (!supplementalPay || supplementalPay === '') {
          this.setState({ errorMessage: 'Please add the supplemental pay type'})
        } else if (supplementalPayArray && supplementalPayArray.includes(supplementalPay)) {
          this.setState({ errorMessage: 'This pay has already been added'})
        } else {

          if (supplementalPayArray) {
            supplementalPayArray.push(postings[index].supplementalPay)
          } else {
            supplementalPayArray = [postings[index].supplementalPay]
          }

          postings[index]['supplementalPayArray'] = supplementalPayArray
          postings[index]['supplementalPay'] = ''
          this.setState({ postings })
        }
      } else if (type === 'benefit') {
        let postings = this.state.postings
        let benefits = this.state.postings[index].benefits
        let benefit = this.state.postings[index].benefit
        if (!benefit || benefit === '') {
          this.setState({ errorMessage: 'Please add a benefit'})
        } else if (benefits && benefits.includes(benefit)) {
          this.setState({ errorMessage: 'This benefit has already been added'})
        } else {

          if (benefits) {
            benefits.push(postings[index].benefit)
          } else {
            benefits = [postings[index].benefit]
          }

          postings[index]['benefits'] = benefits
          postings[index]['benefit'] = ''
          this.setState({ postings })
        }
      }
    }

    removeTag(index, type, parentIndex) {
      console.log('removeTag called', index, type, parentIndex)

      if (type === 'competency') {
        let postings = this.state.postings

        let competencyTags =  postings[parentIndex].competencyTags
        competencyTags.splice(index, 1)
        postings[parentIndex]['competencyTags'] = competencyTags
        this.setState({ postings })
      } else if (type === 'industry') {
        let industries = this.state.industries
        industries.splice(index, 1)
        this.setState({ industries })
      } else if (type === 'supplementalPay') {
        let postings = this.state.postings
        let supplementalPayArray = postings[parentIndex].supplementalPayArray
        supplementalPayArray.splice(index, 1)
        postings[parentIndex]['supplementalPayArray'] = supplementalPayArray
        this.setState({ postings })
      } else if (type === 'benefit') {
        let postings = this.state.postings
        let benefits = postings[parentIndex].benefits
        benefits.splice(index, 1)
        postings[parentIndex]['benefits'] = benefits
        this.setState({ postings })
      }
    }

    searchCompetencies(competency, type, index) {
      console.log('searchCompetencies ', competency, type, index)

      if (type === 'Knowledge') {
        console.log('this is an error. We are not turning on this functionality now')
      } else {
        if (competency === '') {
          const competencyOptions = []
          this.setState({ competencyOptions })
        } else {
          Axios.get('/api/competency/search', { params: { searchString: competency, type } })
          .then((response) => {
            console.log('Competency search query attempted', response.data);

              if (response.data.success) {
                console.log('competency search query worked')

                const competencyOptions = response.data.competencies
                this.setState({ competencyOptions });

              } else {
                console.log('competency search query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Skilltrait search query did not work for some reason', error);
          });
        }
      }
    }

    competencyClicked(index, optionIndex, type) {
      console.log('competencyClicked', index, optionIndex, type, this.state.competencyOptions[optionIndex])

      let postings = this.state.postings
      postings[index]['tagName'] = this.state.competencyOptions[optionIndex].name
      postings[index]['tagType'] = this.state.competencyOptions[optionIndex].type
      const competencyOptions = []
      this.setState({ postings, competencyOptions })

    }

    importInfo() {
      console.log('importInfo called')

      let email = localStorage.getItem('email');
      if (email) {
        // user is logged in
        this.queryInfo(email)
      } else {
        // prompt user sign in
        this.setState({ modalIsOpen: true, paymentOptionsInfo: false, applicationDeadlineInfo: false, applicationComponentsInfo: false, politicalPartyInfo: false, functionInfo: false, showTags: false, showSubsidyInfo: false, showSchoolCreditInfo: false, login: true, showInfoCollected: false })
      }
    }

    async authenticate() {
      console.log('authenticate called')

      this.setState({ isSavingModal: true, errorMessage: null, successMessage: null })

      if (!this.state.email || this.state.email === '') {
        console.log('inhere')
        this.setState({ errorMessage: 'Please enter an email', isSavingModal: false })
      } else if (!this.state.password || this.state.password === '') {
        this.setState({ errorMessage: 'Please enter your password', isSavingModal: false })
      } else {

        const email = this.state.email
        const password = this.state.password
        const orgFocus = null

        const returnedValue = await signIn(email, password, orgFocus)
        if (returnedValue && returnedValue.success) {
          console.log('show returnedValue 1: ', returnedValue)
          // this.queryInfo(this.state.email)

          const accountCode = returnedValue.user.accountCode
          console.log('accountCode: ', accountCode)
          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted', response.data);

            if (response.data.success) {
              console.log('account info query worked')

              this.setState({ modalIsOpen: false })
              this.populateInfo(returnedValue.user.email, returnedValue.user, response.data.accountInfo)

            } else {
              this.setState({ isSavingModal: false, errorMessage: 'We found no employer attached to your account' })
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
            this.setState({ isSavingModal: false, errorMessage: 'We found no employer attached to your account' })
          });
          // this.populateInfo(returnedValue.user.email, returnedValue.user, returnedValue.accountInfo)
        } else if (returnedValue && returnedValue.message) {
          this.setState({ isSavingModal: false, errorMessage: returnedValue.message })
        } else {
          console.log('show returnedValue 2: ', returnedValue)
        }
      }
    }

    queryInfo(emailId) {
      console.log('queryInfo called', emailId)

      Axios.get('/api/users/profile/details/' + emailId)
      .then((response) => {
        if (response.data.success) {

          const responseData = response.data

          const accountCode = responseData.user.accountCode
          console.log('accountCode: ', accountCode)
          Axios.get('/api/account', { params: { accountCode } })
          .then((response) => {
            console.log('Account info query attempted', response.data);

            if (response.data.success) {
              console.log('account info query worked')

              this.populateInfo(emailId, responseData.user, response.data.accountInfo)

            } else {
              this.setState({ isSavingModal: false, errorMessage: 'We found no employer attached to your account' })
            }

          }).catch((error) => {
            console.log('Account info query did not work for some reason', error);
            this.setState({ isSavingModal: false, errorMessage: 'We found no employer attached to your account' })
          });

        } else {
        }
      }).catch((error) => {
          console.log('Profile details fetch did not work', error);
      });
    }

    populateInfo(emailId, user, account) {
      console.log('populateInfo called', emailId, user, account)

      if (emailId && user && account) {
        localStorage.setItem('email', emailId)
        localStorage.setItem('username', user.username)
        localStorage.setItem('firstName', user.firstName)
        localStorage.setItem('lastName', user.lastName)
        localStorage.setItem('roleName', user.roleName)
        localStorage.setItem('isEmployer', 'true')
        localStorage.setItem('emp', account.accountCode)
        localStorage.setItem('activeOrg', account.activeOrg)
        localStorage.setItem('sharePartners', JSON.stringify([account.sharePartners]))
        // localStorage.setItem('orgFocus', orgFocus)

        const contactFirstName = user.firstName
        const contactLastName = user.lastName
        const contactTitle = user.jobTitle
        const employerName = account.employerName
        const employerLocation = account.employerLocation
        const employerURL = account.employerURL
        const employerType = account.employerType
        const employeeCount = account.employeeCount
        const industries = account.industries
        const employerDescription = account.description
        const employerLogoURI = account.employerLogoURI
        const contactEmail = emailId
        const referrerName = account.referrerName
        let orgContacts = account.orgContacts
        let contactPhone = ''
        if (orgContacts) {
          for (let i = 1; i <= orgContacts.length; i++) {
            if (orgContacts[i - 1].contactEmail === emailId) {
              contactPhone = orgContacts[i - 1].contactPhone
            }
          }
        }
        const sharePartners = account.sharePartners

        this.setState({ isSavingModal: false,
          contactFirstName, contactLastName, contactTitle, employerName, employerLocation, employerURL,
          employerType, employeeCount, industries, employerDescription, employerLogoURI,
          contactEmail, contactPhone, referrerName, sharePartners
        })
      }
    }

    render() {

        let logoHeight = '40px'
        let addHeight = '20px'
        let spacing = '15px'
        // let partnerLogoHeight = '23px'
        if (this.state.isMobileView) {
          console.log('isMobileView?', this.state.isMobileView)
          logoHeight = '24px'
          addHeight = '12px'
          spacing = '7px'
          // partnerLogoHeight = '13px'
        }

        let backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(../images/background-image-compass.png);'
        if (this.state.org === 'unite-la') {
          backgroundImage ='linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://drive.google.com/uc?export=view&id=1qoNlfQPcxOYRu2dmsgmWidO2LDbaLqwk)'
        } else if (this.state.org === 'dpscd') {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(https://guidedcompass-bucket.s3-us-west-2.amazonaws.com/partnerCoverImages/Girl.jpg)'
        } else if (this.state.backgroundImageURL) {
          backgroundImage = 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(' + this.state.backgroundImageURL + ')'
        }

        return (
          <div>
            {(this.state.deactivated) ? (
              <div>
                <DeactivatedModal orgName={this.state.orgName} accountCode={this.state.emp} roleName={this.state.roleName} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} redirectLink={this.state.redirectLink} isLandingPage={true}/>
              </div>
            ) : (
              <div>
                <div className="light-background bottom-padding-50-percent">
                  <header className="front-page-header" style={{ backgroundImage }}>
                    <div className="relative-column-20 row-30 right-padding-3-percent" />
                    <div className="relative-column-60 center-horizontally row-30">
                      {(this.state.orgLogo) ? (
                        <ul className="horizontal-list center-text flex-center">
                          <li><Link to={'/'}><img src={logoImg} alt="Compass logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                          <li><div style={{ width: spacing}}/></li>
                          <li><Link to={'/'}><img src={addIcon} alt="Guided Compass add logo" style={{ height: addHeight, width: 'auto' }} /></Link></li>
                          <li><div style={{ width: spacing }}/></li>
                          <li><Link to={'/'}><img src={this.state.orgLogo} alt="Guided Compass org partner logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                        </ul>
                      ) : (
                        <ul className="horizontal-list center-text flex-center">
                          <li><Link to={'/'}><img src={logoImg} alt="Compass logo" style={{ height: logoHeight, width: 'auto'}} /></Link></li>
                        </ul>
                      )}
                    </div>
                    <div className="relative-column-20 row-30 right-padding-3-percent">
                      <Link className="clear-border" to={'/employers/signin'}>
                        <p className="white-text float-right">Sign In</p>
                      </Link>
                    </div>
                    <div className="clear" />

                    {(this.state.isSaving) ? (
                      <div className="flex-container flex-center full-space sticky-raw">
                        <div>
                          <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                          <div className="spacer" /><div className="spacer" /><div className="spacer" />
                          <p className="center-text cta-color bold-text">Posting opportunities...</p>

                        </div>
                      </div>
                    ) : (
                      <div className="employer-signup-form" style={{ transition: 'all 1.2s ease-in', opacity: this.state.opacity, transform: 'translate(0%, 1%)'}}>
                        {(this.state.formComplete) ? (
                          <div>
                            <h2 className="full-width center-text">Thank You and Next Steps!</h2>
                            {this.renderConfirmationPage()}
                          </div>
                        ) : (
                          <div>
                            <h2 className="full-width center-text">Employer Post Form</h2>
                            {this.renderPage(this.state.currentPage)}
                          </div>
                        )}
                      </div>
                    )}

                  </header>

                  <Modal
                   isOpen={this.state.modalIsOpen}
                   onAfterOpen={this.afterOpenModal}
                   onRequestClose={this.closeModal}
                   className="modal"
                   overlayClassName="modal-overlay"
                   contentLabel="Example Modal"
                  >
                   <div key="info" className="full-width padding-40">
                    {(this.state.paymentOptionsInfo) && (
                      <div>
                        <p className="heading-text-2">Internship Types Info</p>
                        <div className="spacer"/>
                        <p>We define <label className="half-bold-text cta-color">internships</label> as a period of work experience greater than <label className="half-bold-text cta-color">120 hours</label>. Internships may be company-paid, subsidized, or unpaid:</p>

                        <div className="spacer"/>

                        <p className="row-10"><label className="half-bold-text">Company-paid</label> internships are paid out-of-pocket by the employer at a wage at least equal to minimum wage.</p>

                        <p className="row-10"><label className="half-bold-text">Subsidized</label> internships may allow employers to have no out-of-pocket expenses. Government or non-profit organizations sponsor internships up to a certain number of hours. Every year, a certain number of subsidized slots are awarded based on the financial need of the employer.</p>

                        <p className="row-10"><label className="half-bold-text">Unpaid</label> internships must provide educational credit. If your posting aligns with the needs of one of our educational partners, the internship can be deemed upaid.</p>
                      </div>
                    )}
                    {(this.state.applicationDeadlineInfo) && (
                      <div>
                        <p className="heading-text-2">Application Deadline Info</p>
                        <div className="spacer"/>
                        <p>The <label className="half-bold-text cta-color">application deadline</label> is the date that candidates should apply or be presented by for first round consideration.</p>
                      </div>
                    )}
                    {(this.state.applicationComponentsInfo) && (
                      <div>
                        <p className="heading-text-2">Application Components Info</p>
                        <div className="spacer"/>
                        <p>The <label className="half-bold-text cta-color">application components</label> section is optional, allowing you to list things you would like candidates to include as part of their application. If there is nothing special beyond their resume, you may leave blank.</p>
                      </div>
                    )}
                    {(this.state.politicalPartyInfo) && (
                      <div>
                        <p className="heading-text-2">Political Affiliation Info</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>Choosing the <label className="half-bold-text cta-color">political affiliation</label> that most closely relates to this position will allow candidates to search for your positions by political party.</p>
                      </div>
                    )}

                    {(this.state.functionInfo) && (
                      <div>
                        <p className="heading-text-2">Function Info</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>We define <label className="half-bold-text cta-color">work functions</label> as a category of work that requires similar skills. It can be thought of as synonymous with "departments" within a company. Functions can be the same across different industries.</p>
                      </div>
                    )}

                    {(this.state.showTags) && (
                      <div>
                        <p className="heading-text-2">Edit Competency Tags</p>

                        <div className="row-10">
                          <div className="container-left">
                            <label className="profile-label">Tag Name</label>
                            <input type="text" className="text-field" placeholder="Name of tag..." name={"work|tagName|" + this.state.selectedIndex} value={(this.state.postings[this.state.selectedIndex]) && this.state.postings[this.state.selectedIndex].tagName} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <div className="calc-column-offset-60 right-padding">
                              <label className="profile-label">Tag Type</label>
                              <select name={"work|tagType|" + this.state.selectedIndex} value={(this.state.postings[this.state.selectedIndex]) && this.state.postings[this.state.selectedIndex].tagType} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.tagTypeOptions.map(value =>
                                  <option key={value} value={value}>{value}</option>
                                )}
                              </select>
                            </div>
                            <div className="fixed-column-60 top-padding-40">
                              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                              <button className="btn btn-squarish" onClick={() => this.addTag('competency', this.state.selectedIndex)}>Add</button>
                            </div>
                            <div className="clear" />
                          </div>
                          <div className="clear" />

                          {(this.state.competencyOptions) && (
                            <div>
                              <div className="spacer" />

                              {this.state.competencyOptions.map((value, optionIndex) =>
                                <div key={value._id} className="left-text row-5 full-width">
                                  <button className="background-button" onClick={() => this.competencyClicked(this.state.selectedIndex, optionIndex, null)}>
                                    <div className="full-width">
                                      <div className="float-left right-padding">
                                        <img src={skillsIcon} alt="Compass employer icon icon" className="image-auto-22" />
                                      </div>
                                      <div className="float-left">
                                        <p className="cta-color">{value.name}</p>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}

                        </div>

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2">{this.state.successMessage}</p>}

                        <div className="spacer" />

                        {this.renderTags('competency', this.state.selectedIndex)}
                        <div className="clear" />

                        <div className="spacer" />

                        <div className="row-10">
                          <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                        </div>
                      </div>
                    )}

                    {(this.state.showSubsidyInfo) && (
                      <div>
                        <p className="heading-text-2">Prioritize Subsidized Candidates</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>In some cases, through our partners, interns and entry-level workers can be <label className="half-bold-text cta-color">subsidized</label> by the city, county, or other program. This typically means candidates are paid (usually around minimum wage) for up to 120 hours of work.</p>
                      </div>
                    )}

                    {(this.state.showSchoolCreditInfo) && (
                      <div>
                        <p className="heading-text-2">Prioritize School Credit Candidates</p>
                        <div className="spacer" /><div className="spacer" />
                        <p>In some cases, through our educational partners, interns and apprentices can receive <label className="half-bold-text cta-color">school credit</label> for working with you as a client partner. This typically means student teams will work on projects for 1-10 hours each week. Some classes are dedicated to "Work Experience" and "Independent Projects".</p>
                      </div>
                    )}

                    {(this.state.login) && (
                      <div>
                        <p className="heading-text-2">Login</p>

                        {(this.state.isSavingModal) ? (
                          <div>
                            <div className="flex-container flex-center full-space">
                              <div>
                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Authenticating...</p>

                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="row-20">
                              <div className="row-10">
                                <input className="text-field standard-border standard-color" type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.formChangeHandler} />
                              </div>

                              <div className="row-10">
                                <input className="text-field height-40 full-width standard-border standard-color" type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.formChangeHandler} />
                              </div>
                            </div>

                            {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                            {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                            <div>
                              <div className="float-left right-padding">
                                <button className="btn btn-primary" disabled={this.state.isSavingModal} onClick={() => this.authenticate()}>Sign In & Import</button>
                              </div>
                              <div className="float-left">
                                <button className="btn btn-secondary" disabled={this.state.isSavingModal} onClick={() => this.closeModal()}>Close View</button>
                              </div>
                              <div className="clear" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.showInfoCollected) && (
                      <div>
                        <p className="heading-text-2 full-width center-text">Information We Typically Collect from Applicants</p>
                        <div className="spacer" /><div className="spacer" />

                        {this.state.infoCollected.map((value, index) =>
                          <div key={value}>
                            <div className="relative-column-33 cta-color bold-text description-text-1 top-margin-20">
                              <p className="full-width center-text">{value}</p>
                            </div>
                            {(index % 3 === 2) && (
                              <div className="clear" />
                            )}
                          </div>
                        )}

                        <div className="clear" />
                        <div className="spacer" /><div className="spacer" />
                      </div>
                    )}
                   </div>
                 </Modal>

                </div>
              </div>
            )}

          </div>
        )
    }
}

export default withRouter(EmpPost)
