import React, {Component} from 'react';
import SubPeopleMatching from '../Subcomponents/PeopleMatching';
import withRouter from '../Functions/WithRouter';

class Profiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.retrieveData = this.retrieveData.bind(this)

    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";

      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in profiles', this.props, prevProps)

      if (this.props.orgCode !== prevProps.orgCode) {
        this.retrieveData()
      } else if (this.props.noOrgCode !== prevProps.noOrgCode) {
        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called in profiles:  ', this.props)

      const emailId = localStorage.getItem('email');
      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgName = localStorage.getItem('orgName');

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgName })

    }

    render() {

      return (
          <div>
            <div className="standard-container-2 clear-padding clear-background clear-shadow">
              <SubPeopleMatching history={this.props.navigate} userType="Peers" pageSource="Profiles" noOrgCode={this.props.noOrgCode} />
            </div>
          </div>
      )
    }
}


export default withRouter(Profiles);
